import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"rust-root", url:"#", title:"Rust", subtitle:""},

        {id:"rust-hello-world", url:"/rust/hello-world", title:"Hello World", subtitle:"", parent:"rust-root"},
        {id:"rust-variables", url:"/rust/variables", title:"Variabile", subtitle:"", parent:"rust-root"},
        {id:"rust-functions", url:"/rust/functions", title:"Functii", subtitle:"", parent:"rust-root"},
        {id:"rust-module", url:"/rust/module", title:"Module", subtitle:"", parent:"rust-root"},
        {id:"rust-types", url:"/rust/types", title:"Tipuri de date", subtitle:"", parent:"rust-root"},
        {id:"rust-control-flow", url:"/rust/control-flow", title:"Instructiuni de control", subtitle:"", parent:"rust-root"},
        {id:"rust-strings", url:"/rust/strings", title:"Siruri de caractere: Strings", subtitle:"", parent:"rust-root"},
        {id:"rust-ownership", url:"/rust/ownership", title:"Ownership: intelegerea proprietatii", subtitle:"", parent:"rust-root"},
        {id:"rust-structs", url:"/rust/structs", title:"Structs", subtitle:"", parent:"rust-root"},
        {id:"rust-traits", url:"/rust/traits", title:"Traits", subtitle:"", parent:"rust-root"},
        {id:"rust-collections", url:"/rust/collections", title:"Colectii", subtitle:"", parent:"rust-root"},
        {id:"rust-enums", url:"/rust/enums", title:"Enums", subtitle:"", parent:"rust-root"},
        {id:"rust-closures", url:"/rust/closures", title:"Closures", subtitle:"", parent:"rust-root"},
        {id:"rust-threads", url:"/rust/threads", title:"Threads", subtitle:"", parent:"rust-root"},
    ]

    static indexUrl = "/rust/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Rust
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;