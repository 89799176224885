import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SetsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-sets", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un <b>Set</b> este o colectie de obiecte care:
                    <ul>
                        <li>nu contine duplicate</li>
                        <li>nu este ordonat (deci nu poate fi folosita metoda: <i>set(index, value)</i> sau <i>remove(index)</i> )</li>
                        <li>metoda <i>add()</i> returneaza true daca s-a efectuat adaugare si false daca nu s-a putut adauga (daca de exemplu, exista deja in set)</li>
                    </ul>

                    Implementari:
                    <ul>
                        <li><b>HashSet</b></li>
                        <li><b>TreeSet</b>: mentine set-ul sortat</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Set<String> set1 = new HashSet<>();\n' +
                        'set1.add("unu");\n' +
                        'set1.add("doi");\n' +
                        'set1.add("unu");\n' +
                        '\n' +
                        'System.out.println(set1);  // [unu, doi]\n' +
                        '\n' +
                        'Set<String> set2 = new TreeSet<>();\n' +
                        'set2.add("unu");\n' +
                        'set2.add("doi");\n' +
                        '\n' +
                        'System.out.println(set2); // [doi, unu]'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SetsJavaContent;