import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex5CleanAWSDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex5-clean-aws", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 5 (complex) - Curatare AWS</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd complex'}
                    </SyntaxHighlighter>

                    Din AWS, stergere mediu Elastic Beanstalk:
                    <ul>
                        <li>din dashboard Elastic Beanstalk</li>
                        <li><b>Applications</b> {"->"} se selecteaza mediul care se doreste sters (multi-docker, de exemplu) {"->"} <b>Actions</b> {"->"} <b>Delete application</b></li>
                        <li>se introduce numele aplicatie care se vrea sters {"->"} <b>Delete</b></li>
                    </ul>

                    Din AWS, stergere RDS:
                    <ul>
                        <li>din dashboard Amazon RDS</li>
                        <li><b>Databases</b> {"->"} se selecteaza baza de date (multi-docker-postgres, de exemplu) {"->"} <b>Actions</b> {"->"} <b>Delete</b> </li>
                        <li>se debifeaza:
                            <ul>
                                <li>Create final snapshot?</li>
                                <li>Retain automated backups</li>
                            </ul>
                        </li>
                        <li>
                            se bifeaza:
                            <ul>
                                <li>I acknowledge that upon instance deletion, automated backups, including system snapshots and point-in-time recovery, will no longer be available.</li>
                            </ul>
                        </li>
                        <li>se introduce <i>delete me</i> {"->"} <b>Delete</b></li>
                    </ul>

                    Din AWS, stergere Amazon ElastiCache:
                    <ul>
                        <li>din dashboard Amazon ElastiCache</li>
                        <li><b>Redis clusters</b> {"->"} se selecteaza cluster-ul (multi-docker-redis, de exemplu) {"->"} <b>Actions</b> {"->"} <b>Delete</b></li>
                        <li>se bifeaza <b>No</b>, ca nu se doreste Backup</li>
                        <li>se introduce numele clusterului (multi-docker-redis, de exemplu) {"->"} <b>Delete</b></li>
                    </ul>

                    Din AWS, stergere VPC (grupul de securitate) - (daca nu se sterg, e ok, nu se plateste pentru aceste configurari):
                    <ul>
                        <li>din dashboard VPC</li>
                        <li><b>Security Groups</b> {"->"} se selecteaza group de secutitate dorit (multi-docker, de exemplu) {"->"} <b>Actions</b> {"->"} <b>Delete security groups</b></li>
                        <li>
                            se poate afisa un mesaj ca nu se pot sterge:
                                <br/>
                                <i>The following security groups can't be deleted. They are either default security groups, referenced by other security groups, or they are associated with instances or network interfaces.</i>
                                <br/>
                                <br/>
                                se poate reveni, mai tarziu, cand se pot sterge (nu se plateste pentru aceste configurari)
                        </li>
                    </ul>

                    Din AWS, sterge IAM:
                    <ul>
                        <li>din dashboard Identity and Access Management (IAM)</li>
                        <li>
                            <b>Access management</b> {"->"} <b>Users</b>
                        </li>
                        <li>
                            se selecreaza user-ul dorit {"->"} <b>Delete</b>
                        </li>
                        <li>
                            se introduce <i>delete</i> pentru campul <i>To confirm deletion, enter delete in the text input field.</i> {"->"} <b>Delete</b>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex5CleanAWSDockerContent;