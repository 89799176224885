import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class CmdDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "cmd-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Comenzi utile:

                    <br/>
                    <br/>

                    <b>1. Informatii</b>
                    <br/>
                    <br/>

                    Afisare informatii despre Docker
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info'}
                    </SyntaxHighlighter>

                    Afisare versiune Docker
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker version'}
                    </SyntaxHighlighter>

                    Afisare versiune Docker Compose
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose version'}
                    </SyntaxHighlighter>

                    Afisare imagini:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image ls'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images'}
                    </SyntaxHighlighter>
                    (se afiseaza si dimensiunea fiecarei imaginii)

                    Afisare containere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>

                    Afisare containere (care au rulat):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls -a'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -a'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Gestionare containere</b>
                    <br/>
                    <br/>

                    Rulare container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run <nume-imagine>'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run <nume-imagine>'}
                    </SyntaxHighlighter>
                    (ex: docker run nginx)
                    <hr/>
                    Pentru a iesi din container se foloseste comanda: <b>exit</b>

                    <hr/>
                    Asignare nume unui container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --name CONTAINER_NAME IMAGE'}
                    </SyntaxHighlighter>
                    (ex: docker run --name web nginx)

                    <hr/>
                    Mapare port:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p HOSTPORT:CONTAINERPORT IMAGE '}
                    </SyntaxHighlighter>
                    (ex: docker run -p 8680:80 nginx)
                    <hr/>
                    Mapare toate porturile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -P IMAGE'}
                    </SyntaxHighlighter>
                    (ex: docker run -P nginx)

                    <hr/>
                    Pentru a rula in background:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -d <nume-imagine> '}
                    </SyntaxHighlighter>
                    <hr/>

                    Asignare hostname:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --hostname HOSTNAME IMAGE'}
                    </SyntaxHighlighter>
                    (ex: docker run --hostname myhost nginx)


                    <hr/>
                    Stergere container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm CONTAINER_NAME1 CONTAINER_NAME2'}
                    </SyntaxHighlighter>
                    (ex: docker rm web)

                    <hr/>
                    Stergere container care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm -f CONTAINER_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker rm -f web)

                    <hr/>
                    Stergere toate containerele oprite:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container prune'}
                    </SyntaxHighlighter>

                    <hr/>
                    Conectare container (ca la un Remote Desktop):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run --interactive --tty <nume-imagine> '}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it <nume-imagine> '}
                    </SyntaxHighlighter>

                    Oprire container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop <nume-container-generat>'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop <id-container-generat>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Oprire fortata container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker kill <nume-container-generat>'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker kill <id-container-generat>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Afisare log-uri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker logs <id-container-generat>'}
                    </SyntaxHighlighter>
                    (ex: docker logs 4ff)

                    <hr/>

                    Pornire container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker start CONTAINER_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker start web)

                    <hr/>
                    A pune pe pauza un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pause CONTAINER_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker pause web)

                    <hr/>
                    Restartare container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop CONTAINER_NAME\n' +
                            'docker restart CONTAINER_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker restart web)

                    <hr/>
                    Redenumire container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop old_name new_name\n' +
                            'docker rename myweb web'}
                    </SyntaxHighlighter>
                    (ex: docker rename myweb web)

                    <hr/>
                    Copiere fisier in container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker cp TARGET CONTAINER_NAME:HOST'}
                    </SyntaxHighlighter>
                    (ex: docker cp index.html web:/index.html)

                    <hr/>
                    Exemple:
                    <ul>
                        <li>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -p 8008:80 -v /user/kj/site:/usr/share/nginx/html nginx'}
                            </SyntaxHighlighter>
                            sau (pentru Windows):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -p 8008:80 -v ${PWD}:/usr/share/nginx/html nginx'}
                            </SyntaxHighlighter>
                            sau (pentru Linux):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -p 8008:80 -v $PWD:/usr/share/nginx/html nginx'}
                            </SyntaxHighlighter>

                            <hr/>
                            rulare Python:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -it -v $PWD:/app -w /app python python3 hello-world.py'}
                            </SyntaxHighlighter>

                            <hr/>
                            rulare Node.js:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -v $PWD:/app -w /app node node hello-world.js'}
                            </SyntaxHighlighter>

                            <hr/>
                            initializare proiect Node.js-Express:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -v $PWD:/app -w /app -it node npm init'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -v $PWD:/app -w /app -it node npm install express'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -v $PWD:/app -w /app -it -p 3000:3000 node node index.js'}
                            </SyntaxHighlighter>
                            Pentru a opri containerul, trebuie ca aplicatia sa fie capabila ca gestioneze semnalele de proces:
                            <ul>
                                <li>SIGINT (cand containerul este oprit prin CTRL+C)</li>
                                <li>SIGTERM (cand containerul este oprit prin comanda docker stop)</li>
                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>3. Gestionare imagini</b>
                    <br/>
                    <br/>

                    Aducere local imagine de pe repository-ul remote:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull <nume-imagine>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare istoria rularii unei imagini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker history <nume-imagine>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare imagini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images'}
                    </SyntaxHighlighter>

                    <hr/>
                    Rulare imagine:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run IMAGE_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker run nginx)

                    <hr/>
                    Upload imagine in repository:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker push IMAGE_NAME:TAG'}
                    </SyntaxHighlighter>
                    (ex: docker push my_image:1.0)

                    <hr/>
                    Stergere imagine:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rmi IMAGE_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker rmi nginx)

                    <hr/>
                    Stergere imagini nefolosite:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image prune -a'}
                    </SyntaxHighlighter>

                    <hr/>
                    Build imagine dintr-un Dockerfile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t IMAGE_NAME DIRECTORY'}
                    </SyntaxHighlighter>
                    (ex: docker build -t my_image .)

                    <hr/>
                    Salvare imagine intr-un .tar file:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker save IMAGE_NAME > File'}
                    </SyntaxHighlighter>
                    (ex: docker save nginx {">"} nginx.tar)

                    <hr/>
                    Incarcare imagine intr-un .tar file:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker load -i TAR_FILE_NAME'}
                    </SyntaxHighlighter>
                    (ex: docker load -i nginx.tar)

                    <hr/>
                    Logare Registry:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker logindocker login localhost:8080'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>4. Curatare</b>
                    <br/>
                    <br/>

                    Cleanup containere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container rm -f $(docker container ls -aq)'}
                    </SyntaxHighlighter>

                    Cleanup imagini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image rm -f $(docker image ls -f reference=\'imagine/*\' -q)'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                Learn Docker in a month of lunches - Elton Stoneman, Manning, 2020
                            </div>
                        </li>

                        <li>
                            <div>
                                Docker Demystified - Saibal Ghosh, BPB, 2021
                            </div>
                        </li>

                        <li>
                            <div>

                                <a href={"https://ocw.cs.pub.ro/courses/idp/laboratoare/01"}>Laboratorul 01 - Docker</a>

                            </div>
                        </li>
                        <li>
                            <a href={"https://blog.devgenius.io/most-useful-docker-commands-cheat-sheet-f5115e10c047"}>
                                Most useful Docker commands cheat sheet
                            </a>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CmdDockerContent;