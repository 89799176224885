import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";

class WebScopesSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-web-scopes", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    Scopuri web:
                    <ul>
                        <li><b>request</b></li>
                        <li><b>session</b></li>
                        <li><b>application</b></li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default WebScopesSpringContent;