import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class HeadersExpiresNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-headers-expires", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Header-ul Expires</b>

                    <br/>
                    <br/>

                    Header-ul / Antentul <b>Expires</b> specifică data/ora după care răspunsul este considerat expirat.

                    <br/>
                    <br/>
                    <b>Notă</b>: Dacă există un header <b>Cache-Control</b> cu directiva <i>max-age</i> sau <i>s-maxage</i> în răspuns, header-ul Expires  este ignorat.

                    <br/>
                    <br/>

                    Sintaxa pentru <b>Expires Header</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'Expires: <http-date>'}
                    </SyntaxHighlighter>

                    <i>Exemplu</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'Expires: Wed, 21 Oct 2015 07:28:00 GMT'}
                    </SyntaxHighlighter>

                    <i>Exemplu</i>:
                    <br/>
                    Sa prespunem ca avem o poza. Ii putem spune browser-ul cat timp sa tina poza in cache, evitand astfel request-uri in viitor pentru aceasta poza.
                    <br/>
                    Pentru specifica sa cache-uiasca si alte resurse, de exemplu: css-uri.

                    <br/>
                    <br/>
                    In <b>nginx.conf</b>, pentru o anumita resursa, se foloseste <b>expires</b> in combinatie cu <b>Cache-Control public</b>, <b>Pragma public</b>, <b>Vary Accept-Encoding</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'location = /thumb.png {\n' +
                            '\t\n' +
                            '\tadd_header Cache-Control public;\n' +
                            '\tadd_header Pragma public;\n' +
                            '\tadd_header Vary Accept-Encoding;\n' +
                            '\texpires 1M; # o luna\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a testa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -I http://IP/thumb.png'}
                    </SyntaxHighlighter>
                    Se va afisa:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'Expires: Fri, 15 Jun 2022 08:22:40 GMT\n' +
                            'Cache-Control: max-age-2592000\n' +
                            'Cache-Control: public\n' +
                            'Pragma: public\n' +
                            'Vary: Accept-Encoding'
                            }
                    </SyntaxHighlighter>
                    Observatie: 2592000 este numarul de secunde in 30 de zile, adica o luna (60 x 60 x 24 x 30).

                    <br/>
                    Poza va fi pastra in cache, pe masina clientului si va fi luata de acolo atata timp cat nu e expirata.

                    <br/>
                    <br/>
                    Cache-uire mai multe resurse, folosind regex:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}> {
                        ' location ~* \\.(css|js|jpg|png)$ {\n' +
                        '      access_log off;\n' +
                        '      add_header Cache-Control public;\n' +
                        '      add_header Pragma public;\n' +
                        '      add_header Vary Accept-Encoding;\n' +
                        '      expires 1M;\n' +
                        '    }'
                    }</SyntaxHighlighter>

                    <hr/>
                    <b>2. Adugare headere noi</b>

                    <br/>
                    <br/>

                    Sintaxa pentru a adauga un header:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'add_header nume_header "valoare";'}
                    </SyntaxHighlighter>

                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\tlocation = /thumb.png {\n' +
                            '\t\n' +
                            '\t\tadd_header my_header "Hello";\n' +
                            '\t\t\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Pentru a testa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -I http://IP/thumb.png'}
                    </SyntaxHighlighter>
                    Se va afisa in response header si header-ul nostru: <i>my_header: Hello</i>

                    <br/>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HeadersExpiresNginxContent;