import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class EnvNextReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-env", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Variabile de mediu </b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1. .env</b>
                    <br/>
                    <br/>

                    Variabile de mediu sunt puse in fisierul:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'.env.local'}
                    </SyntaxHighlighter>

                    Variabilele de mediu care sunt plasate în fișierul .env sunt disponibile numai pe partea serverului.
                    <br/>
                    Dacă se vrea ca variabilele sa fie disponibile pe partea clientului trebuie utilizat <b>next.config.js</b>!

                    <br/>
                    <br/>
                    Observatie:
                    <ul>
                        <li>La fiecare modificare in fisierul .env trebuie restartat serverul de dev!</li>
                    </ul>
                    <hr/>
                    <b>2. Exportare variabile de mediu: NEXT_PUBLIC_</b>
                    <br/>
                    <br/>

                    In mod implicit, variabile de mediu sunt disponibile doar in mediul Node.js, deci nu sunt expuse in browser.
                    <br/>
                    Pentru a expune in browser, o variabila de mediu trebuie prefixata cu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'NEXT_PUBLIC_'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'NEXT_PUBLIC_BACKEND_URL'}
                    </SyntaxHighlighter>
                    si poate fi accesat:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'process.env.NEXT_PUBLIC_BACKEND_URL'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Ordine incarcare fisiere .env</b>
                    <br/>
                    <br/>

                    In mod implicit ordinea de incarcare a variabile de mediu:
                    <ul>
                        <li>
                            process.env
                        </li>
                        <li>
                            .env.$(NODE_ENV).local
                        </li>
                        <li>
                            .env.local
                        </li>
                        <li>
                            .env.$(NODE_ENV)
                        </li>
                        <li>
                            .env
                        </li>
                    </ul>
                    unde NODE_ENV poate lua una din valorile:
                    <ul>
                        <li>
                            development
                        </li>
                        <li>
                            production
                        </li>
                        <li>
                            test
                        </li>
                    </ul>

                    <hr/>
                    <b>4. next.config.js</b>
                    <br/>
                    <br/>

                    Variabile de mediu pot fi specificate si in fisierul <b>next.config.js</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'module.exports = {\n' +
                            '  env: {\n' +
                            '    customKey: \'my-value\',\n' +
                            '  },\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Accesare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function Page() {\n' +
                            '  return <h1>The value of customKey is: {process.env.customKey}</h1>\n' +
                            '}\n' +
                            '\n' +
                            'export default Page'}
                    </SyntaxHighlighter>

                    Variabilele de mediu specificate în acest fel vor fi întotdeauna incluse în pachetul JavaScript,
                    prefixarea numelui variabilei de mediu are NEXT_PUBLIC_ efect numai atunci când le specificați prin fișierele de mediu sau .env

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EnvNextReactContent;