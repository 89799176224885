import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class KeycloakIntegrationNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-keycloak-integration", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    Pornim de la repository care găzduiește codul sursă al unui plugin Keycloak pentru platforma Nuxeo: <a href={"https://github.com/nuxeo/nuxeo/tree/10.10/nuxeo-services/login/nuxeo-platform-login-keycloak"}>Nuxeo Platform Login Keycloak</a>.
                    <br/>
                    <br/>
                    <b>Pasi de urmat</b>:
                    <ul>
                        <li>Creare structura template pentru <b>Keycloak</b>:
                            <ul>

                                <li>se creeaza in <b>[nuxeo-server-10.10-tomcat]\templates</b> urmatoarea structura de directoare:<br/>
                                    <ul>
                                        <li>
                                            <b>\keycloak\nxserver\plugins</b>
                                        </li>
                                        <li>
                                            <b>\keycloak\nxserver\config</b>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <br/>
                        </li>

                        <li>Copiere <b>Client Adapter de Keycloak pentru Tomcat 8/9</b>:
                            <ul>
                                {/*<li>se descarca de <a href={"https://www.keycloak.org/downloads.html"}>aici</a> adaptarul pentru client (<b>Tomcat 8/9</b>): <br/>*/}
                                {/*    <b>keycloak-oidc-tomcat-adapter-15.0.2.zip</b>*/}
                                {/*</li>*/}

                                <li>se descarca de <a href={"https://www.keycloak.org/archive/downloads-10.0.0.html"}>aici</a> adaptarul pentru client (<b>Tomcat 8/9</b>): <br/>
                                    <b>keycloak-tomcat-adapter-dist-10.0.0.zip</b>
                                </li>


                                <li>se dezarhiveaza fisierul descarcat si JAR-urile se copiaza in:<br/>
                                    <b>\keycloak\nxserver\plugins</b>
                                    <br/> <br/>
                                    <b>Observatie:</b>
                                    <br/>
                                    Trebuie sterse jar-urile/librariile duplicate care sunt deja in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib</b> sau <b>[nuxeo-server-10.10-tomcat]\lib</b>:
                                    <ul>
                                        <li>commons-logging-1.2.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\lib</b>)</li>
                                        {/*<li>bcprov-jdk15on-1.68.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib\bcprov-jdk15on-1.60.jar</b>)</li>*/}
                                        <li>commons-codec-1.11.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib</b>)</li>
                                        <li>httpclient-4.5.11.jar / httpclient-4.5.13.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib\httpclient-4.5.6.jar</b>)</li>
                                        <li>httpcore-4.4.13.jar / httpcore-4.4.14.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib\httpcore-4.4.10.jar</b>)</li>
                                        <li>jackson-annotations-2.10.1.jar / jackson-annotations-2.12.1.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib\jackson-annotations-2.9.8.jar</b>)</li>
                                        <li>jackson-core-2.10.1.jar / jackson-core-2.12.1.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib\jackson-core-2.9.8.jar</b>)</li>
                                        <li>jackson-databind-2.10.1.jar / jackson-databind-2.12.1.jar (se mai gaseste si in <b>[nuxeo-server-10.10-tomcat]\nxserver\lib\jackson-databind-2.9.8.jar</b>)</li>
                                    </ul>
                                    <br/>
                                </li>

                            </ul>
                        </li>

                        <li>
                            Build proiect:
                            <ul>
                                <li>
                                    se face build la <b>nuxeo</b> versiunea <b>release-10.10-HF37</b> (vezi: <a href={"/nuxeo/build-nuxeo-version"}>Build versiune Nuxeo</a>)
                                </li>
                            </ul>
                        </li>

                        <li>
                            Copiere <b>nuxeo-platform-login-keycloak</b>:
                            <ul>
                                <li>
                                    se copiaza jar-ul (<b>nuxeo-platform-login-keycloak-10.10-HF37</b>):
                                    <ul>
                                        <li>din <b>\nuxeo\nuxeo-services\login\nuxeo-platform-login-keycloak\target</b></li>
                                        <li>in <b>[nuxeo-server-10.10-tomcat]\templates\keycloak\nxserver\nxserver\plugins\</b></li>
                                    </ul>

                                </li>
                            </ul>
                        </li>

                        <li>
                            Copiere <b>nuxeo-usermapper</b>:
                            <ul>
                                <li>
                                    se copiaza jar-ul (<b>nuxeo-usermapper-10.10-HF37</b>):
                                    <ul>
                                        <li>din <b>\nuxeo\nuxeo-services\nuxeo-usermapper\target</b></li>
                                        <li>in <b>[nuxeo-server-10.10-tomcat]\templates\keycloak\nxserver\nxserver\plugins\</b></li>
                                    </ul>

                                </li>
                            </ul>
                        </li>

                        <li>
                            se copiaza / creaza fisierul <b>nuxeo.defaults</b> in directorul <b>[nuxeo-server-10.10-tomcat]\templates\keycloak</b>
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'## Edit this file to customize your configuration ##\n' +
                                '## See http://doc.nuxeo.com/x/LANc\n' +
                                'keycloak.target=.\n' +
                                '\n' +
                                '# Set list of templates to include, comma separated values.\n' +
                                'nuxeo.template.includes=common\n' +
                                '\n' +
                                '# Customize default values associated with this template'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se copiaza / creaza fisierul <b>keycloak.json</b> in directorul <b>[nuxeo-server-10.10-tomcat]\templates\keycloak/nxserver/config/</b>
                            <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                {'{\n' +
                                '  "realm": "vanilla-realm",\n' +
                                '  "auth-server-url": "http://localhost:8180/auth/",\n' +
                                '  "ssl-required": "external",\n' +
                                '  "resource": "liferay-portal-client",\n' +
                                '  "credentials": {\n' +
                                '    "secret": "0c778028-f03e-4bdd-b835-8ecb33653ea0"\n' +
                                '  },\n' +
                                '  "confidential-port": 0\n' +
                                '}'}
                            </SyntaxHighlighter>

                            <b>Observatie:</b>
                            <br/>Acest fisier se poate genera din <b>Keycloack</b>: <b>Clients</b> {"=>"} [ales clientul, de exemplu: Liferay-portal-client] {"=>"} <b>Tab Installation</b> {"=>"} <b>Format Options: OIDC Keycloak JSON</b>
                            <br/>  <br/>
                        </li>

                        <li>
                            se copiaza / creaza fisierul <b>keycloak-descriptor-bundle.xml</b> in directorul <b>[nuxeo-server-10.10-tomcat]\templates\keycloak/nxserver/config/</b>
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<component name="org.nuxeo.ecm.platform.login.keycloak.descriptor">\n' +
                                '  <require>org.nuxeo.ecm.platform.ui.web.auth.defaultConfig</require>\n' +
                                '  <require>org.nuxeo.ecm.platform.ui.web.auth.WebEngineConfig</require>\n' +
                                '  <require>org.nuxeo.ecm.automation.server.auth.config</require>\n' +
                                '  <require>org.nuxeo.ecm.platform.login.keycloak</require>\n' +
                                '\n' +
                                '  <!-- Override Keycloak Auth into authentication chain -->\n' +
                                '  <extension\n' +
                                '      target="org.nuxeo.ecm.platform.ui.web.auth.service.PluggableAuthenticationService"\n' +
                                '      point="chain">\n' +
                                '    <authenticationChain>\n' +
                                '      <plugins>\n' +
                                '        <plugin>KEYCLOAK_AUTH</plugin>\n' +
                                '        <plugin>BASIC_AUTH</plugin>\n' +
                                '        <plugin>FORM_AUTH</plugin>\n' +
                                '        <plugin>WEBENGINE_FORM_AUTH</plugin>\n' +
                                '        <plugin>ANONYMOUS_AUTH</plugin>\n' +
                                '      </plugins>\n' +
                                '    </authenticationChain>\n' +
                                '  </extension>\n' +
                                '\n' +
                                '  <extension\n' +
                                '      target="org.nuxeo.ecm.platform.ui.web.auth.service.PluggableAuthenticationService"\n' +
                                '      point="specificChains">\n' +
                                '\n' +
                                '    <specificAuthenticationChain name="RestAPI">\n' +
                                '      <urlPatterns>\n' +
                                '        <url>(.*)/api/v.*</url>\n' +
                                '      </urlPatterns>\n' +
                                '\n' +
                                '      <replacementChain>\n' +
                                '        <plugin>KEYCLOAK_AUTH</plugin>\n' +
                                '        <plugin>AUTOMATION_BASIC_AUTH</plugin>\n' +
                                '      </replacementChain>\n' +
                                '    </specificAuthenticationChain>\n' +
                                '\n' +
                                '  </extension>\n' +
                                '\n' +
                                '  <extension\n' +
                                '      target="org.nuxeo.ecm.platform.ui.web.auth.service.PluggableAuthenticationService"\n' +
                                '      point="specificChains">\n' +
                                '\n' +
                                '    <specificAuthenticationChain name="Automation">\n' +
                                '      <urlPatterns>\n' +
                                '        <url>(.*)/automation.*</url>\n' +
                                '      </urlPatterns>\n' +
                                '\n' +
                                '      <replacementChain>\n' +
                                '        <plugin>KEYCLOAK_AUTH</plugin>\n' +
                                '        <plugin>AUTOMATION_BASIC_AUTH</plugin>\n' +
                                '      </replacementChain>\n' +
                                '    </specificAuthenticationChain>\n' +
                                '\n' +
                                '  </extension>\n' +
                                '\n' +
                                '</component>'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            modificare fisier <b>[nuxeo-server-10.10-tomcat]\bin\nuxeo.conf</b>, adaugand la <b>nuxeo.templates=</b> si templata de <b>keycloak</b>:
                            <br/>
                            nuxeo.templates=postgresql,keycloak
                        </li>
                        <li>
                            restart server nuxeo:<br/>
                            <b>nuxeoctl restart -gui=false</b>
                        </li>
                    </ul>
                    <hr/>
                    <b>Probleme</b>
                    <br/>
                    <b>1</b>.  references missing [target=org.nuxeo.usermapper.service.UserMapperComponent;point=mapper]
                    <SyntaxHighlighter language="text" style={androidstudio}>
                        {'======================================================================\n' +
                        '= Component Loading Status: Pending: 0 / Missing: 1 / Unstarted: 0 / Total: 501\n' +
                        '  * service:org.nuxeo.ecm.platform.login.keycloak references missing [target=org.nuxeo.usermapper.service.UserMapperComponent;point=mapper]\n' +
                        '======================================================================'}
                    </SyntaxHighlighter>
                    <b>Cauza</b>: lispeste <b>nuxeo-usermapper-10.10.jar</b>
                    <br/><br/>
                    <b>2</b>.  The document cannot be updated because its id is missing
                    <SyntaxHighlighter language="text" style={androidstudio}>
                        {'The document cannot be updated because its id is missing\n' +
                        '                org.nuxeo.ecm.directory.DirectoryException: The document cannot be updated because its id is missing\n' +
                        '\tat org.nuxeo.ecm.directory.BaseSession.updateEntry(BaseSession.java:406)\n' +
                        '\tat org.nuxeo.ecm.platform.usermanager.UserManagerImpl.updateUser(UserManagerImpl.java:1384)\n' +
                        '\tat org.nuxeo.ecm.platform.usermanager.UserManagerImpl.updateUser(UserManagerImpl.java:1024)\n' +
                        '\tat org.nuxeo.ecm.platform.ui.web.keycloak.KeycloakUserMapper.updateUser(KeycloakUserMapper.java:151)\n' +
                        '\tat org.nuxeo.ecm.platform.ui.web.keycloak.KeycloakUserMapper.lambda$getOrCreateAndUpdateNuxeoPrincipal$0(KeycloakUserMapper.java:73)\n' +
                        '\tat org.nuxeo.runtime.api.Framework.doPrivileged(Framework.java:293)\n' +
                        '\tat org.nuxeo.ecm.platform.ui.web.keycloak.KeycloakUserMapper.getOrCreateAndUpdateNuxeoPrincipal(KeycloakUserMapper.java:61)\n' +
                        '\tat org.nuxeo.usermapper.service.UserMapperComponent.getOrCreateAndUpdateNuxeoPrincipal(UserMapperComponent.java:110)\n' +
                        '\tat org.nuxeo.usermapper.service.UserMapperComponent.getOrCreateAndUpdateNuxeoPrincipal(UserMapperComponent.java:104)\n' +
                        '\tat org.nuxeo.ecm.platform.ui.web.keycloak.KeycloakAuthenticationPlugin.handleRetrieveIdentity(KeycloakAuthenticationPlugin.java:128)'}
                    </SyntaxHighlighter>
                    <b>Cauza / Rezolvare</b>:
                    <ul>
                        <li><a href={"https://jira.nuxeo.com/browse/NXP-29170"}>Fix user creation during the first Keycloak authentication</a></li>
                        <li><a href={"https://github.com/nuxeo/nuxeo/pull/4085/commits/8d8f353e466c6b0f94b767e8deb3f30e642fdecc"}>Commit pe github</a></li>
                        <li>

                                <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                    {'git checkout 8d8f353\n' +
                                     'HEAD is now at 8d8f353e466 NXP-29170: return the newly created user document model'}
                                </SyntaxHighlighter>

                                Dar pe <b>release-10.10-HF37</b> problema trebuie sa se fi rezolvat!
                        </li>
                    </ul>

                    <hr/>
                    <b>Testare</b>
                    <br/>
                    <ul>
                        <li>http://localhost:8080/nuxeo</li>
                        <ul>
                            <li>
                                username:Administrator
                            </li>
                            <li>
                                password:Administrator
                            </li>
                        </ul>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://answers.nuxeo.com/general/q/45aba928735f4214869a54d79a4c3b91/nuxeo-authentication-using-keycloak"}>
                                    Nuxeo authentication using keycloak
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://jira.nuxeo.com/browse/NXP-29082"}>
                                    JIRA: Update README.md for nuxeo-platform-login-keycloak
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://jira.nuxeo.com/browse/NXP-29170"}>
                                    JIRA: Fix user creation during the first Keycloak authentication
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://github.com/nuxeo/nuxeo/tree/10.10/nuxeo-services/login/nuxeo-platform-login-keycloak"}>
                                    Nuxeo Platform Login Keycloak
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KeycloakIntegrationNuxeoContent;