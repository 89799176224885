import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlRunContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-run", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl run</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectrl run</b> este folosita pentru creea un pod (simular cu <b>docker run</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run  <nume-pod> --image=<nume-imagine>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'pod/nginx created'}
                    </SyntaxHighlighter>
                    Se va crea un container pe baza imaginii <i>nginx</i> si va rula in interiorul podului cu numele <i>nginx</i>.

                    <br/>
                    <br/>
                    Acum daca vrem sa afisam pod-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'nginx                                  0/1     ContainerCreating   0          38s'}
                    </SyntaxHighlighter>

                    Pentru a afisa detalii despre pod:
                    Acum daca vrem sa afisam pod-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe pod nginx'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Name:             nginx\n' +
                            'Namespace:        default\n' +
                            'Priority:         0\n' +
                            'Service Account:  default\n' +
                            'Node:             minikube/192.168.49.2\n' +
                            'Start Time:       Fri, 16 Dec 2022 12:50:33 +0200\n' +
                            'Labels:           run=nginx\n' +
                            'Annotations:      <none>\n' +
                            'Status:           Pending\n' +
                            'IP:\n' +
                            'IPs:              <none>\n' +
                            'Containers:\n' +
                            '  nginx:\n' +
                            '    Container ID:\n' +
                            '    Image:          nginx\n' +
                            '    Image ID:\n' +
                            '    Port:           <none>\n' +
                            '    Host Port:      <none>\n' +
                            '    State:          Waiting\n' +
                            '      Reason:       ContainerCreating\n' +
                            '    Ready:          False\n' +
                            '    Restart Count:  0\n' +
                            '    Environment:    <none>\n' +
                            '    Mounts:\n' +
                            '      /var/run/secrets/kubernetes.io/serviceaccount from kube-api-access-w6fs2 (ro)\n' +
                            'Conditions:\n' +
                            '  Type              Status\n' +
                            '  Initialized       True\n' +
                            '  Ready             False\n' +
                            '  ContainersReady   False\n' +
                            '  PodScheduled      True\n' +
                            'Volumes:\n' +
                            '  kube-api-access-w6fs2:\n' +
                            '    Type:                    Projected (a volume that contains injected data from multiple sources)\n' +
                            '    TokenExpirationSeconds:  3607\n' +
                            '    ConfigMapName:           kube-root-ca.crt\n' +
                            '    ConfigMapOptional:       <nil>\n' +
                            '    DownwardAPI:             true\n' +
                            'QoS Class:                   BestEffort\n' +
                            'Node-Selectors:              <none>\n' +
                            'Tolerations:                 node.kubernetes.io/not-ready:NoExecute op=Exists for 300s\n' +
                            '                             node.kubernetes.io/unreachable:NoExecute op=Exists for 300s\n' +
                            'Events:\n' +
                            '  Type    Reason     Age   From               Message\n' +
                            '  ----    ------     ----  ----               -------\n' +
                            '  Normal  Scheduled  103s  default-scheduler  Successfully assigned default/nginx to minikube\n' +
                            '  Normal  Pulling    82s   kubelet            Pulling image "nginx"\n' +
                            '  Normal  Pulled     15s   kubelet            Successfully pulled image "nginx" in 1m6.729115247s\n' +
                            '  Normal  Created    6s    kubelet            Created container nginx\n' +
                            '  Normal  Started    0s    kubelet            Started container nginx\n'}
                    </SyntaxHighlighter>

                    Pentru a sterge pod-ul cu numele <i>nginx</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete pod nginx'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare YAML "generat" la pornirea unui pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  creationTimestamp: null\n' +
                            '  labels:\n' +
                            '    run: nginx\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - image: nginx\n' +
                            '    name: nginx\n' +
                            '    resources: {}\n' +
                            '  dnsPolicy: ClusterFirst\n' +
                            '  restartPolicy: Always\n' +
                            'status: {}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlRunContent;