import {useRoutes} from "react-router-dom";
import IndexContent from "./IndexContent";
import React from "react";
import MyRobotsContent from "./MyRobotsContent";
import RuxRobotContent from "./RuxRobotContent";
import UtilRobotContent from "./UtilRobotContent";

// {path: "/robots/my", element: <PrivateRoute element={<MyRobotsContent/>}/>},
export default function Router() {
    let element = useRoutes([
        {path: "/robots/index", element: <IndexContent/>},
        {path: "/robots/my", element: <MyRobotsContent/>},
        {path: "/robots/rux", element: <RuxRobotContent/>},
        {path: "/robots/util", element: <UtilRobotContent/>},
    ]);

    return element;
}