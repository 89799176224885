import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ArrayListJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-arraylist", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Definire</b>
                    <br/>
                    <br/>

                    Un ArrayList este o colectie care isi poate schimba dimensiunea. Constructori:
                    <ul>
                        <li>ArrayList()</li>
                        <li>ArrayList(dimensiune)</li>
                        <li>ArrayList(alta_lista)</li>
                    </ul>

                    Incepand cu versiunea Java 5, s-au introdus <b>genericile</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ArrayList<Tip> lista = new ArrayList<Tip>()'}
                    </SyntaxHighlighter>

                    Incepand cu versiunea Java 7, s-a introdus <b>operatorul diamanat</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ArrayList<Tip> lista = new ArrayList<>()'}
                    </SyntaxHighlighter>

                    Incepand cu versiunea Java 10, s-a introdus <b>var</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'var lista = new ArrayList<>(); // ~ new ArrayList<Object>()'}
                    </SyntaxHighlighter>

                    <b>Moduri de initializari</b>:
                    <br/>

                    Lista partial-imutabila (nu se pot adauga, sterge elemente; dar se pot modifica)
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Arrays.asList(element1,..,elementN)'}
                    </SyntaxHighlighter>

                    Lista imutabila (nu se pot adauga, sterge, modifica elemente)
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'List.of(element1,..,elementN)'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'List<String> lista1 = Arrays.asList("unu","doi");\n' +
                        'List<String> lista2 = List.of("unu","doi");\n' +
                        '\n' +
                        'lista1.set(1,"trei"); // OK\n' +
                        'lista2.set(1,"trei"); // UnsupportedOperationException\n' +
                        '\n' +
                        '//lista1.add("a"); // UnsupportedOperationException\n' +
                        '//lista2.add("a"); // UnsupportedOperationException\n' +
                        '\n' +
                        '//lista1.remove(1); // UnsupportedOperationException\n' +
                        '//lista2.remove(1); // UnsupportedOperationException'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Operatii</b>
                    <br/>
                    <br/>

                    <b>2.1.</b> Adaugare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'boolean add(E element)\n' +
                        'void add(int index, E element)'}
                    </SyntaxHighlighter>

                    <b>2.2.</b> Stergere element:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'boolean remove(E element)\n' +
                        'void remove(int index'}
                    </SyntaxHighlighter>

                    <b>2.3.</b> Actualizare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'E set(int index, E element)'}
                    </SyntaxHighlighter>

                    <b>2.4.</b> Stergere toate elementele:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'void clear()'}
                    </SyntaxHighlighter>

                    <b>2.5.</b> Verificare daca este lista goala:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'boolean isEmpty()'}
                    </SyntaxHighlighter>

                    <b>2.6.</b> Obtinere dimensiune lista:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int size()'}
                    </SyntaxHighlighter>

                    <b>2.7.</b> Verificare daca exista un element in lista:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'boolean contains(Object object)'}
                    </SyntaxHighlighter>

                    <b>2.8.</b> Verificare daca lista este egala cu alta lista:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'boolean equals(Object object)'}
                    </SyntaxHighlighter>

                    <b>2.9.</b> Sortare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Collections.sort(lista)'}
                    </SyntaxHighlighter>

                    <b>2.10.</b> Conversie ArrayList la un vector (array):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Object[] toArray()\n' +
                        'T[] toArray(new T[dimensiune])'}
                    </SyntaxHighlighter>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'List<String> arrayList = new ArrayList();\n' +
                        'arrayList.add("unu");\n' +
                        'arrayList.add("doi");\n' +
                        '\n' +
                        'Object[] lista = arrayList.toArray();\n' +
                        '\n' +
                        'System.out.println(Arrays.toString(lista)); // [unu, doi]'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'List<String> arrayList = new ArrayList();\n' +
                        'arrayList.add("unu");\n' +
                        'arrayList.add("doi");\n' +
                        '\n' +
                        'String[] lista1 = arrayList.toArray(new String[0]);\n' +
                        'System.out.println(Arrays.toString(lista1)); // [unu, doi]\n' +
                        '\n' +
                        'String[] lista2 = arrayList.toArray(new String[3]);\n' +
                        'System.out.println(Arrays.toString(lista2)); // [unu, doi, null]'}
                    </SyntaxHighlighter>

                    <b>2.11.</b> Conversie vector (array) la un List, folosind metoda <b>of</b> a clasei <b>List</b>; metoda <b>of</b> returneaza o lista imutabila, adica nu se poate modifica:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'List.of(vector)'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String[] vector = new String[]{"unu","doi"};\n' +
                        'List<String> lista = List.of(vector);\n' +
                        'System.out.println(lista); // [unu, doi]\n' +
                        'vector[0] = "trei";\n' +
                        'System.out.println(lista); // [unu, doi]\n' +
                        'lista.set(1,"trei"); // java.lang.UnsupportedOperationException (lista este imutabila)'}
                    </SyntaxHighlighter>

                    <b>2.12.</b> Conversie vector (array) la un List, folosind metoda <b>asList</b> a clasei <b>Array</b>;
                    <br/>
                    metoda <b>asList</b> creaza un link catre vectorul orginal; deci daca se modifica ceva in vector, acest lucru se reflecta si in lista;
                    se pot modifica elemente, dar nu se pot adauga sau sterge prin intermediul listei:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Arrays.asList(vector)'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String[] vector = new String[]{"unu","doi"};\n' +
                        'List<String> lista = Arrays.asList(vector);\n' +
                        'System.out.println(lista); // [unu, doi]\n' +
                        'vector[0] = "trei";\n' +
                        'System.out.println(lista); // [unu, doi]\n' +
                        'lista.set(1,"patru");\n' +
                        'System.out.println(Arrays.toString(vector)); // [trei, patru]\n' +
                        '\n' +
                        'lista.remove("trei"); // UnsupportedOperationException (nu se poate sterge)\n' +
                        'lista.add("cinci"); // UnsupportedOperationException (nu se poate adauga)'}
                    </SyntaxHighlighter>

                    <hr/>
                    Clasele wrapper (Boolean, Byte, Short, Integer, Long, Float, Double, Character) au metode de conversie:
                    <ul>
                        <li>catre un String, de forma:
                            <br/>
                            Long.<b>parse</b>Long("valoare") sau Boolean.<b>parse</b>Boolean("valoare)
                        </li>
                        <li>de la String, de forma:
                            <br/>
                            Long.<b>valueOf</b>("valoare") sau Boolean.<b>valueOf</b>("valoare)
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ArrayListJavaContent;