import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class SerializingFormsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-serializing-form", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Serializarea <b>valorilor unui form</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'private String _serialize(DDMFormValues ddmFormValues) {\n' +
                        '\tDDMFormValuesSerializer ddmFormValuesSerializer =\n' +
                        '\t\t_ddmFormValuesSerializerTracker.getDDMFormValuesSerializer("json");\n' +
                        '\n' +
                        '\tDDMFormValuesSerializerSerializeRequest.Builder builder =\n' +
                        '\t\tDDMFormValuesSerializerSerializeRequest.Builder.newBuilder(\n' +
                        '\t\t\tddmFormValues);\n' +
                        '\n' +
                        '\tDDMFormValuesSerializerSerializeResponse\n' +
                        '\t\tddmFormValuesSerializerSerializeResponse =\n' +
                        '\t\t\tddmFormValuesSerializer.serialize(builder.build());\n' +
                        '\n' +
                        '\treturn ddmFormValuesSerializerSerializeResponse.getContent();\n' +
                        '}'}
                    </SyntaxHighlighter>
                    avand:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference\n' +
                        'private DDMContentLocalService _ddmContentLocalService;\n' +
                        '\n' +
                        '@Reference\n' +
                        'private DDMFormValuesSerializerTracker _ddmFormValuesSerializerTracker;'}
                    </SyntaxHighlighter>
                    cu importurile:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.dynamic.data.mapping.service.DDMContentLocalService;\n' +
                        'import com.liferay.dynamic.data.mapping.io.DDMFormValuesSerializerTracker;'}
                    </SyntaxHighlighter>

                    test:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'DDMFormInstance ddmFormInstance = getDDMFormInstance(nume_forma);\n' +
                        'DDMForm form = ddmFormInstance.getDDMForm();\n' +
                        'DDMFormValues ddmFormValues = ddmFormValuesFactory.create(resourceRequest, form);\n' +
                        '\n' +
                        'String serializeJSONDDMForm = _serialize(ddmFormValues);'}
                    </SyntaxHighlighter>

                    <hr/>
                    Avand un DDMForm aceasta poate fi serializat la un String:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'DDMFormSerializer ddmFormSerializer = ddmFormSerializerTracker.getDDMFormSerializer("json"); // sau yaml\n' +
                        '\n' +
                        'DDMFormSerializerSerializeRequest.Builder builder = DDMFormSerializerSerializeRequest.Builder.newBuilder(ddmForm);\n' +
                        '\n' +
                        'DDMFormSerializerSerializeResponse ddmFormSerializerSerializeResponse = ddmFormSerializer.serialize(builder.build());\n' +
                        '\n' +
                        'ddmFormSerializerSerializeResponse.getContent();'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360028726752-Serializing-Forms"}>
                                    Serializing Forms
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/process-automation/forms/developer-guide/writing-a-form-storage-adapter.html"}>
                                    Writing a Form Storage Adapter
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SerializingFormsLiferayContent;