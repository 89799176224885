import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ImportImageDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-image-import", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker image import</b>
                    <br/>
                    <br/>

                    Pentru a importa conținutul dintr-un tarball pentru a crea o imagine a sistemului de fișiere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image import [optiuni] file | URL | -[repository[:tag]] '}
                    </SyntaxHighlighter>
                    unde [optiuni]:
                    <ul>
                        <li>
                            --change | -c {"=>"} suprascriere/aplicare instructiuni Dokcerfile la crearea imaginii
                        </li>
                        <li>
                            --message | -m {"=>"} seteaza un mesaj de comit pentru imaginea importata
                        </li>
                        <l>
                            --platform {"=>"} seteaza platforma daca server este multi-platforma
                        </l>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker import --message "mesaj" --change "CMD bash" busybox-save.tar busybox-save:v1'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image import --message "mesaj" --change "CMD bash" busybox-save.tar busybox-save:v1'}
                    </SyntaxHighlighter>

                    Exemplu de export:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker export --output="busybox-save.tar" busybox-save:latest'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImportImageDockerContent;