import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CommitGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-commit", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git commit</b>
                    <br/>
                    <br/>

                    Fisierele urmarite pot fi commise (modificarile sunt salvate in repository-ul local).
                    Si mai exista o conditie pentru ca un fisier sa fie comis: sa fie <b>staged</b> (un fisier ajunge in starea <b>staged</b> dupa un <i>git add</i>;
                    daca fisierul se modifica pe urma, acest fisierul nu mai este in starea <i>staged</i> si este necesara o noua comanda de <i>git add</i>).

                    <br/>
                    <br/>

                    Sa presupunem ca vrem sa comitem fisierele urmatarite (in cazul nostru doar fisierul <i>citeste.txt</i>).
                    Atunci executam comanda <b>git commit -m <i>mesaj_comentariu</i></b> :
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git commit -m "Primul commit"'}
                    </SyntaxHighlighter>

                    Rezultatul comenzii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[master (root-commit) 14055f8] Primul commit\n' +
                        ' 1 file changed, 0 insertions(+), 0 deletions(-)\n' +
                        ' create mode 100644 citeste.txt'}
                    </SyntaxHighlighter>

                    Sirul de caractere <i>14055f8</i> reprezinta un identificator unic al comitului, generat automat de Git.

                    <br/>
                    <br/>
                    Daca executam acum comanda <b>git status</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'On branch master\n' +
                        'nothing to commit, working tree clean'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CommitGitContent;