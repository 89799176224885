import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CreateComponentAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-create-component", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare componenta</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng generate component hero-detail'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng g c hero-detail'}
                    </SyntaxHighlighter>

                    pentru a nu genera clasa componenta care se ocupa cu testele (<b>.component.spec.ts</b>),
                    in versiuni mai vechi de Angular:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng g c hero-detail --spec false'}
                    </SyntaxHighlighter>
                    In noile versiuni de Angular, comanda <b>--spec false</b> a fost inlocuita cu <b>--skiptests</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng g c hero-detail --skiptests'}
                    </SyntaxHighlighter>


                    va genera si modifica:
                    <SyntaxHighlighter>
                        {'CREATE src/main/webapp/app/pages/main/hero-detail/hero-detail.component.html (26 bytes)\n' +
                            'CREATE src/main/webapp/app/pages/main/hero-detail/hero-detail.component.spec.ts (628 bytes)\n' +
                            'CREATE src/main/webapp/app/pages/main/hero-detail/hero-detail.component.ts (295 bytes)\n' +
                            'CREATE src/main/webapp/app/pages/main/hero-detail/hero-detail.component.scss (0 bytes)\n' +
                            'UPDATE src/main/webapp/app/app.module.ts (2900 bytes)'}
                    </SyntaxHighlighter>
                    In fisierul <i>hero-detail.component.ts</i> se va genera clasa de tip componenta <i>HeroDetailComponent</i>
                    <br/>

                    Dupa ce se creaza, pentru a putea fi folosit trebuie declarat si in fisierul de definire a modului in adnotarea <b>@NgModule</b> sectiunea <b>declarations</b>
                    <br/>
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { BrowserModule } from \'@angular/platform-browser\';\n' +
                            'import { NgModule } from \'@angular/core\';\n' +
                            '\n' +
                            'import { AppRoutingModule } from \'./app-routing.module\';\n' +
                            'import { AppComponent } from \'./app.component\';\n' +
                            'import { HeroDetailComponent } from \'./pages/main/hero-detail/hero-detail.component\';\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  declarations: [\n' +
                            '    AppComponent\n' +
                            '    HeroDetailComponent\n' +
                            '  ],\n' +
                            '  imports: [\n' +
                            '    BrowserModule,\n' +
                            '    AppRoutingModule\n' +
                            '  ],\n' +
                            '  providers: [],\n' +
                            '  bootstrap: [AppComponent]\n' +
                            '})\n' +
                            'export class AppModule { }\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    React, in mod implicit nu are! (pentru ca e prea simplu 🤯 ).
                    <br/>
                    Dar, se poate folosi de exemplu: <a target={"_blank"} href={"https://www.npmjs.com/package/generate-react-cli"}>Generate React CLI</a>!

                    <br/>
                    <br/>

                    Instalare <b>generate-react-cli</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm i generate-react-cli'}
                    </SyntaxHighlighter>

                    Generare componenta:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npx generate-react-cli component Box'}
                    </SyntaxHighlighter>

                    Structura generata:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'|-- /src\n' +
                            '    |-- /components\n' +
                            '        |-- /Box\n' +
                            '            |-- Box.js\n' +
                            '            |-- Box.css\n' +
                            '            |-- Box.test.js'}
                    </SyntaxHighlighter>

                    <br/>
                    Acesta poate genera componente folosind IA!!!
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npx generate-react-cli@alpha c Counter -d "Create a counter component that increments by one when I click on the increment button"'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateComponentAngularContent;