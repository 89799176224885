import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class VirtualHostsTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-loadbalancing-nginx", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LoadBalancing cu NGinx in Vertical Cluster</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>se downloadeza NGinx de <a target={"_blank"} href={"http://nginx.org/en/download.html"}>aici</a> (versiunea nginx/Windows-1.22.0)
                            <br/>
                            (pe linux: <b>wget http://nginx.org/download/nginx-1.22.0.tar.gz</b>)
                        </li>
                        <li>se dezarhiveaza si se pune de exemplu in: <i>D:\servers\nginx-1.22.0</i>
                            <br/>
                            (pe linux: <b>tar -zxvf nginx-1.22.0.tar.gz</b>)
                            <br/>
                            (pe linux: se ruleaza apoi: <b>./configure</b>)
                            <br/>
                            (pe linux: se ruleaza apoi: <b>make install</b>)
                            <br/>
                            (pe linux: in mod implicit, se instaleaza in : <b>/usr/local/nginx/sbin</b>)
                            <br/>
                            (pe linux: pentru a-l porni:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'cd /usr/local/nginx/sbin\n' +
                                    './nginx '}
                            </SyntaxHighlighter>)
                        </li>
                        <li>inainte sa se porneasca NGinx se editeaza fisierul <b>nginx.conf</b> din <i>[nginx]\conf</i>
                            <br/>
                            in sectiunea <b>http</b> se adauga (se pun porturile pe care sunt pornite instantele de Tomcat):

                            <br/>
                            <b>Observatie</b>:
                            <br/>
                            Numele dat nu trebuie sa continea caracterul "_" (ex: tomcat_servers), altfel la pornirea serverului se va optine eroarea:
                            <br/>
                            <i>The character [_] is never valid in a domain name</i>.
                                <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                    {'http {\n' +
                                        '\n' +
                                        '\tupstream tomcatservers{\n' +
                                        '\t\n' +
                                        '\t\tserver 127.0.0.1:8880;\n' +
                                        '\t\tserver 127.0.0.1:8780;\n' +
                                        '\t\tserver 127.0.0.1:8680;\n' +
                                        '\t\t\n' +
                                        '\t}...'}
                                </SyntaxHighlighter>

                            in sectiunea <b>http/server/location</b> (se pune numele declarat in sectiune <b>http</b> la <b>upstream</b>, adica <i>tomcat_servers</i> la <b>proxy_pass</b>)

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {' location / {\n' +
                                    '\tproxy_pass http://tomcatservers;'}
                            </SyntaxHighlighter>

                            pe scurt, <b>proxy_pass http://tomcat_servers</b> leaga IP-ul de <b>oricare</b> din porturile declarate.
                        </li>
                        <li>
                            <br/>
                            in mod implicit NGinx porneste pe portul 80; pentru a schimba acest lucru:

                            <br/>
                            din
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {' server {\n' +
                                    '\t\tlisten       80;'}
                            </SyntaxHighlighter>
                            in
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {' server {\n' +
                                    '\t\tlisten       8088;'}
                            </SyntaxHighlighter>
                        </li>

                        <li>se porneste NGinx:[nginx]\<b>nginx.exe</b> (avand pornite cele 3 instante de Tomcat sau pornindu-le dupa pornirea serverului de Nginx)

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'start .\\nginx.exe'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            verificare: <i>http://localhost:8088</i>
                        </li>

                        <li>
                            pentru a opri serverul NGinx:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'.\\nginx.exe -s stop'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>


                    <hr/>
                    <b>2. LoadBalancing cu NGinx in Horizontal Cluster</b>
                    <br/>
                    <br/>

                    Vertical Cluster are printre dezavantaje faptul ca fiind pe aceasi masina foloseste aceleasi resurse (CPU, memorie, etc).
                    <br/>
                    O idee mai buna, este a se folosi masini diferite (servere fizice). Aici intervine Horizontal Cluster.
                    <br/>
                    Sa presupunem ca avem instalat 2 instante de Tomcat pe 2 masini diferite:
                    <ul>
                        <li>192.168.0.10</li>
                        <li>192.168.0.11</li>
                    </ul>
                    Ambele pornite pe portul 8080.
                    <br/>
                    Sa prespunem ca am instalat GNinx pe masina <i>192.168.0.5</i>, port 80.
                    <br/>
                    Inainte sa se porneasca NGinx se editeaza fisierul <b>nginx.conf</b> din <i>[nginx]\conf</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'http {\n' +
                            '\n' +
                            '\tupstream tomcatservers{\n' +
                            '\t\n' +
                            '\t\tserver 192.168.0.10:8080;\n' +
                            '\t\tserver 192.168.0.10:8080;\n' +
                            '\t\t\n' +
                            '\t}...'}
                    </SyntaxHighlighter>
                    Fata de Vertical Cluster, la configurarea de Horizontal Cluster, se schimba IP-ul si se mentine (eventual) portul.
                    <br/>

                    Identic, ca in primul capitol, in sectiunea <b>http/server/location</b> (se pune numele declarat in sectiune <b>http</b> la <b>upstream</b>, adica <i>tomcat_servers</i> la <b>proxy_pass</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {' location / {\n' +
                            '\tproxy_pass http://tomcatservers;'}
                    </SyntaxHighlighter>

                    Verificare: <i>http://192.168.0.5</i> (daca portul e 80, se poate omite).

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>
                               https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VirtualHostsTomcatContent;