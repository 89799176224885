import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class WLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-w", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa ce utilizatori sunt conectati la sistemul de operare, de cat timp si ce procese ruleaza:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'w'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {' 22:50:09 up 47 days,  1:51,  2 users,  load average: 1,80, 1,65, 1,69\n' +
                            'USER     TTY      FROM             LOGIN@   IDLE   JCPU   PCPU WHAT\n' +
                            'kj       pts/0    192.168.1.4      20:21    0.00s  0.08s  0.01s w\n' +
                            'kj       pts/1    192.168.1.4      20:28    5:43   0.03s  0.03s -bash'}
                    </SyntaxHighlighter>

                    Pentru a afisa help-ul pentru comanda <i>w</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uptime --help'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Usage:\n' +
                            ' w [options]\n' +
                            '\n' +
                            'Options:\n' +
                            ' -h, --no-header     do not print header\n' +
                            ' -u, --no-current    ignore current process username\n' +
                            ' -s, --short         short format\n' +
                            ' -f, --from          show remote hostname field\n' +
                            ' -o, --old-style     old style output\n' +
                            ' -i, --ip-addr       display IP address instead of hostname (if possible)\n' +
                            '\n' +
                            '     --help     display this help and exit\n' +
                            ' -V, --version  output version information and exit\n' +
                            '\n' +
                            'For more details see w(1).'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WLinuxContent;