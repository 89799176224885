import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"nuxeo", url:"#", title:"Introducere în Nuxeo", subtitle:""},

        {id:"nuxeo-install", url:"/nuxeo/install", title:"Instalare Nuxeo cu o distributie ZIP", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-config", url:"/nuxeo/config", title:"Configurare", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-build-nuxeo-version", url:"/nuxeo/build-nuxeo-version", title:"Build versiune Nuxeo", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-keycloak-integration", url:"/nuxeo/keycloak-integration", title:"Integrare cu Keycloak", subtitle:"", parent:"nuxeo"},

        {id:"nuxeo-nxql", url:"/nuxeo/nxql", title:"NXQL", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-coresession", url:"/nuxeo/coresession", title:"CoreSession", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-adobe-cc-connector", url:"/nuxeo/adobe-cc-connector", title:"Adobe CC Connector", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-google-storage", url:"/nuxeo/google-storage", title:"Google Storage", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-security", url:"/nuxeo/security", title:"Securitate", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-two-factor-authentication", url:"/nuxeo/two-factor-authentication", title:"Two Factor Authentication", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-nuxeo-cli", url:"/nuxeo/nuxeo-cli", title:"Nuxeo CLI", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-service", url:"/nuxeo/service", title:"Service", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-unit-testing", url:"/nuxeo/unit-testing", title:"Testare unitara", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-documents", url:"/nuxeo/documents", title:"Manipulare documente", subtitle:"", parent:"nuxeo"},
        {id:"nuxeo-service-extension-points", url:"/nuxeo/service-extension-points", title:"Puncte de extensie a serviciilor", subtitle:"", parent:"nuxeo"},

        {id:"nuxeo-directory-entries-to-custom-object", url:"/nuxeo/directory-entries-to-custom-object", title:"Mapare DirectoryEntries la un obiect Java", subtitle:"", parent:"nuxeo"},

        {id:"nuxeo-anexa", url:"#", title:"Anexe", subtitle:""},
        {id:"nuxeo-anexa-tips1", url:"/nuxeo/anexa-tips1", title:"Tips 1", subtitle:"", parent:"nuxeo-anexa"},


    ]

    static indexUrl = "/nuxeo/index";

    render() {
        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Nuxeo
                </div>

                {TocHelper.display(IndexContent.content)}
            </div>
        );
    }
}

export default IndexContent;