import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class RunDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-run", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker run / docker container run</b>

                    <br/>
                    <br/>

                    Creaza si ruleaza un container dintr-o imagine:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run nume_imagine'}
                    </SyntaxHighlighter>

                    Este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run nume_imagine'}
                    </SyntaxHighlighter>

                    Creaza si ruleaza un container dintr-o imagine si ruleaza o comanda (comanda care este definita in imagine):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run nume_imagine comanda'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Exemple</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run hello-world'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run busybox ls'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. docker run = docker create + docker start</b>
                    <br/>
                    <br/>

                    De fapt, <b>docker run</b> ruleaza 2 comenzi:
                    <ul>
                        <li><b>docker create</b> nume_container {'->'} care creaza containerul
                            <br/>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker create hello-world'}
                            </SyntaxHighlighter>

                            se va afisa id-ul container-ului creat:
                            <SyntaxHighlighter>
                                {'64dd23a269a48ed42f48669ea100e5bbc05f3d99494bf16a6d5c93d9577b068f'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>docker start -a</b> nume_contianer {'->'} care ruleaza containerul (<b>-a</b> specifica sa afiseze output-ul in consola)
                            <br/>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker start -a 64dd23a269a48ed42f48669ea100e5bbc05f3d99494bf16a6d5c93d9577b068f'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    La rularea unui container (creare + pornire) se pun adauga acelasi optiuni ca si la crearea acestuia:
                    <ul>
                        <li>se adauga un layer in care se poate scrie, dimensiunea acestuia depinzand de resurusele setate pentru container</li>
                        <li>se pot face ajustari legate de anumite layere:
                            <ul>
                                <li>
                                    se pot inlocui CMD, ENTRYPOINT sau WORKDIR;
                                </li>
                                <li>
                                    activa sau dezactiva HEALTH CHECKS
                                </li>
                                <li>
                                    adauga metadate
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run create -it \\ \n' +
                            '--name ubuntu-create \\ \n' +
                            '--cpus="0.5" \\ \n' +
                            '--cap-add=ALL \\ \n' +
                            '--env="LOGNAME=kj" \\ \n' +
                            '--memory=400M \\ \n' +
                            '--memory-reservation=200M \\ \n' +
                            '--pid=host \\ \n' +
                            '--privileged \\ \n' +
                            '--restart=always \\ \n' +
                            'ubuntu:18.04'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            --cpus {"=>"} se refera la resursele CPU
                        </li>
                        <li>
                            --cap-add {"=>"} se refera la capabilitati, control asupra drepturilor proceselor (de exemplu, anumite procese sa nu aiba acces la fisiere)
                            <br/>
                            Exemple capabilitati granulare: <b>--cap=CAP_SYS_ADMIN CAP_NET_ADMIN</b>
                        </li>
                        <li>
                            --pid=host {"=>"} pentru a permite containerului sa vada procesele host
                        </li>
                        <li>
                            --privileged {"=>"} nu este recomandat, ofera toate privilegiile, capabilitatile
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RunDockerContent;