import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class SelectorK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-selector", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Etichete (labels)</b>
                    <br/>
                    <br/>
                    O eticheta este o proprietate de tipul <b>cheie: valoare</b> atasata unui obiect (un obiect poate fi pod, serviciu, replicaset, deployment, etv).
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'culoare: verde'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'app: App1'}
                    </SyntaxHighlighter>

                    O eticheta se specifica in sectiunea <b>metadata / labels</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'metadata: \n' +
                            '  labels: \n' +
                            '    app: App1\n' +
                            '    function: Front-end'}
                    </SyntaxHighlighter>

                    Pentru a afisa pod-urile care au eticheta <i>app: App1</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pod --selector app=kj'}
                    </SyntaxHighlighter>

                    <b>2. Selector</b>
                    <br/>
                    <br/>

                    Un <b>selector</b> identifica/seleteaza resurse (exemplu: pod) care vor fi controlate sau conectate cu obiectul definit, pe baza etichetelor

                    <hr/>

                    Un selector poate fi:
                    <ul>
                        <li>
                            <b>simplu</b>, de exemplu in cazul obiectelor de tip Service:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'  selector:\n' +
                                    '    [cheie]: [valoare]'}
                            </SyntaxHighlighter>

                            exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'  selector:\n' +
                                    '    component: web'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>matchLabels</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {' selector:\n' +
                                    '    matchLabels:\n' +
                                    '      component: web\n' +
                                    '      # tier: backend'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>matchExpression</b>: trebuie indeplinite toate conditiile:
                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {' selector:\n' +
                                    '    matchExpression:\n' +
                                    '      - {key: app, operator: In, values: [second-app, first-app]}'}
                            </SyntaxHighlighter>
                            operatorii pot fi:
                            <ul>
                                <li>
                                    <b>In</b>: expresia este valida daca cel putin una din valori este indeplinita
                                </li>
                                <li>
                                    <b>NotIn</b>: expresia este valida daca nu e intre valori
                                </li>
                                <li>
                                    <b>Exists</b>
                                </li>
                                <li>
                                    <b>DoesNotExist</b>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Adnotari</b>
                    <br/>
                    <br/>

                    O adnotare pentru a specifica diverse informatii legate de un obiect.
                    <br/>
                    <br/>
                    O adnotare se specifica in sectiunea <b>metadata / annoations</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'metadata: \n' +
                            '  annotations: \n' +
                            '    buildVersion: 1.34' +
                            '    email: kj@k8s.ro'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>4. Folosire etichete/labels</b>
                    <br/>
                    <br/>
                    Se creaza un nou deployment (kj):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment kj --image=nginx'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'deployment.apps/kj created'}
                    </SyntaxHighlighter>
                    pentru a afisa deployment-urile:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deploy'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME   READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'kj     1/1     1            1           24s\n'}
                    </SyntaxHighlighter>

                    pentru a afisa mai multe detalii legate deployment-urile (mai apar 3 coloane: CONTAINERS, IMAGES si SELECTOR):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deploy -o wide'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME   READY   UP-TO-DATE   AVAILABLE   AGE    CONTAINERS   IMAGES   SELECTOR\n' +
                            'kj     1/1     1            1           30s    nginx        nginx    app=kj\n'}
                    </SyntaxHighlighter>
                    Pentru a afisa filtrat dupa un label (<b>-l</b> sau <b>--selector</b>):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deploy -o wide -l app=kj'}
                    </SyntaxHighlighter>

                    Pentru a afisa descrierea deploymentului:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pod -o yaml --selector app=kj'}
                    </SyntaxHighlighter>
                    poate afisa (fragment):
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'items:\n' +
                            '- apiVersion: v1\n' +
                            '  kind: Pod\n' +
                            '  metadata:\n' +
                            '    annotations:\n' +
                            '      cni.projectcalico.org/containerID: 95874c1215d665d62c544f68e4d0df5fa3d7ef35d7022513fb506ad6d00d62dd\n' +
                            '      cni.projectcalico.org/podIP: 192.168.65.91/32\n' +
                            '      cni.projectcalico.org/podIPs: 192.168.65.91/32\n' +
                            '    creationTimestamp: "2023-02-11T20:07:39Z"\n' +
                            '    generateName: kj-556ff96998-\n' +
                            '    labels:\n' +
                            '      app: kj\n' +
                            '      pod-template-hash: 556ff96998\n'}
                    </SyntaxHighlighter>

                    Daca se editeaza un pod (ex: <i>kubectl edit pod</i>), adaugandu-se o noua eticheta, aceasta nu va avea efect (trebuie sters si recreat deployment-ul)

                    <hr/>
                    <b>5. Exemple</b>
                    <br/>
                    <br/>
                    Exemple:
                    <SyntaxHighlighter>
                        {'kubectl get pod -l env=dev\n' +
                            'kubectl get pod --selector bu=finance\n' +
                            'kubectl get all -l env=prod\n' +
                            'kubectl get all --selector env=prod,bu=finance,tier=frontend\n' +
                            'kubectl get all -l env=prod,bu=finance,tier=frontend\n' +
                            'kubectl get pod -l env=dev --no-headers | wc -l\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SelectorK8sContent;