import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class RedirectOtherPortletLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-redirect-other-portlet", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    Vrem sa facem redirect catre o pagina (<i>PAGE</i>) care este un portlet (<i>PORTLET_NAME</i>) trimitand parametrul <i>PARAMETER_ID</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'String PAGE = "/web/guest/alta_pagina";\n' +
                        'String PORTLET_NAME = "ro_MyPortlet";\n' +
                        'String PARAMETER_ID = "id";\n' +
                        '   \n' +
                        'PortletURL portletURL = getUrlToPortletOnPage(portletRequest, PAGE, PORTLET_NAME, PortletRequest.RENDER_PHASE);\n' +
                        'portletURL.setParameter(UrlUtil.PARAMETER_ID, String.valueOf(id));\n' +
                        'portletURL.toString();'
                        }
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'public static PortletURL getUrlToPortletOnPage(PortletRequest portletRequest, String pageFriendlyUrl, String portletId, String lifecycle) {\n' +
                        '        HttpServletRequest request = PortalUtil.getHttpServletRequest(portletRequest);\n' +
                        '        ThemeDisplay themeDisplay = (ThemeDisplay) request.getAttribute(WebKeys.THEME_DISPLAY);\n' +
                        '        long plid = PortalUtil.getPlidFromFriendlyURL(themeDisplay.getCompanyId(), pageFriendlyUrl);\n' +
                        '        String portletName = getPortletName(pageFriendlyUrl, portletId, plid);\n' +
                        '\n' +
                        '        PortletURL portletURL = PortletURLFactoryUtil.create(request, portletName, plid, lifecycle);\n' +
                        '\n' +
                        '        try {\n' +
                        '            portletURL.setWindowState(WindowState.NORMAL);\n' +
                        '        } catch (WindowStateException e) {\n' +
                        '            log.error(e);\n' +
                        '        }\n' +
                        '\n' +
                        '        return portletURL;\n' +
                        '}'}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' private static String getPortletName(String pageFriendlyUrl, String portletId, Long plid) {\n' +
                        '        String portletName = "";\n' +
                        '        try {\n' +
                        '            LayoutTypePortlet portletFactory = LayoutTypePortletFactoryUtil.create(LayoutUtil.findByPrimaryKey(plid));\n' +
                        '            if (portletFactory != null) {\n' +
                        '                List<String> portletIds = portletFactory.getPortletIds();\n' +
                        '                for (String id : portletIds) {\n' +
                        '                    if (id.contains(portletId + StringPool.UNDERLINE)) {\n' +
                        '                        return id; // portlet name\n' +
                        '                    }\n' +
                        '                    if (id.equals(portletId)) {\n' +
                        '                        return id; // portlet name\n' +
                        '                    }\n' +
                        '                }\n' +
                        '            }\n' +
                        '        } catch (Exception e) {\n' +
                        '            log.error("Error obtaining portlet with id " + portletId + " from the page " + pageFriendlyUrl, e);\n' +
                        '        }\n' +
                        '        return null;\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RedirectOtherPortletLiferayContent;