import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class TimeoutLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-timeout-session", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    1. Se adauga/modifica in fisierul <b>portal-ext.properties</b>:
                    <br/>

                    Setare 30 de minute
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'session.timeout=30'}
                    </SyntaxHighlighter>

                    2. Se adauga/modifica in fisierul <b>web.xml</b> (tomcat/webapps/ROOT/WEB-INF/web.xml):
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<session-config>\n' +
                        '   <session-timeout>30</session-timeout>\n' +
                        '</session-config>'}
                    </SyntaxHighlighter>
                    <hr/>

                    Setare sesiune la 2 ore si extindere automata:
                    <SyntaxHighlighter>
                        {'session.timeout=120\n' +
                            'session.timeout.auto.extend=true'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://liferay.dev/es/blogs/-/blogs/how-to-configure-user-session-timeout-in-liferay-portal"} target={"_blank"}>
                                    How to configure user session timeout in Liferay Portal
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.javasavvy.com/liferay-session-timeout/"} target={"_blank"}>
                                    Liferay session timeout
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"http://liferay-article.blogspot.com/2017/11/session-timeout-in-liferay.html"} target={"_blank"}>
                                    Session timeout in liferay
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TimeoutLiferayContent;