import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class HelloWorldGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-hello-world", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Hello World - Go</b>
                    <br/>
                    <br/>

                    Go nu este un limbaj OOP!
                    <br/>
                    In schimb se declara tipuri de date noi si se ataseaza functionalitati pentru acest tip!
                    <hr/>

                    Creare modul <b>go mod init nume_modul</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'go mod init hello'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'go: creating new go.mod: module example/hello'}
                    </SyntaxHighlighter>

                    si se genereaza fisierul <i>go.mod</i> cu urmatorul continut
                    <SyntaxHighlighter>
                        {'module example/hello\n' +
                            '\n' +
                            'go 1.21.0'}
                    </SyntaxHighlighter>

                    Cream un fisier <i>main.go</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="go" style={androidstudio}>
                        {'package main\n' +
                            '\n' +
                            'import "fmt"\n' +
                            '\n' +
                            'func main() {\n' +
                            '    fmt.Println("Hello, World!")\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Compilare: go build</b>
                    <br/>
                    <br/>
                    Compilare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'go build main.go'}
                    </SyntaxHighlighter>
                    Pe Windows, se va crea un fisier executabil <i>hello.exe</i> (nume modul)

                    <hr/>
                    <b> Compilare si rulare: go run</b>
                    <br/>
                    <br/>

                    Compilare si rulare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'go run main.go'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'go run .'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Hello, World!'}
                    </SyntaxHighlighter>

                    Daca sunt 2 fisiere: main.go si kj.go:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'go run main.go kj.go'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Dezactivare module</b>
                    <br/>
                    <br/>

                    Daca nu ar exista fisierul <i>go.mod</i>, s-ar obtine eroarea:
                    <br/>
                    <i>go.mod file not found in current directory or any parent directory</i>.
                    <br/>
                    Se pot dezactiva module cu urmatoarea comanda:
                    <SyntaxHighlighter>
                        {'go env -w GO111MODULE=off'}
                    </SyntaxHighlighter>
                    Daca module sunt deactivate, pe Windows se va creea la <b>go build</b> un fisier executabil cu numele directorului! (exemplu: test-go.exe)

                    <hr/>
                    <b>Pachete</b>
                    <br/>
                    <br/>

                    Pachetele pot fi:
                    <ul>
                        <li>
                            <b>pachete executabile</b>:
                            <br/>
                            Daca numele pachetului este <b>main</b> (nume special) se va creea un executabil:
                            defineste un pachet care poate fi compilat si executat.
                            <br/>
                            In plus, <b>trebuie sa aiba functia numita 'main'</b>:
                            <SyntaxHighlighter>
                                {'func main(){..}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>pachete refolosibile</b>:
                            <br/>
                            Daca numele pachetului nu este <b>main</b>, la <b>go build</b> nu se va creea un executabil:
                            defineste un pachete care poate fi folosit ca dependenta (helper)

                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HelloWorldGoContent;