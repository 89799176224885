import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerfileDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dockerfile", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare Dockerfile</b>

                    <br/>
                    <br/>

                    Se creaza un fisier <b>Dockerfile</b>.
                    <br/>
                    Acest fisier contine:
                    <ul>
                        <li>specificare imagine de baza (base image):

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM [nume_imagine][:tag]'}
                            </SyntaxHighlighter>
                            daca [:tag] lipseste atunci in mod implicit se considera tag-ul cu numele <b>latest</b>

                            <br/>
                            <br/>

                            de exemplu ex:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM alpine'}
                            </SyntaxHighlighter>
                        </li>
                        <li>rulare comenzi pentru instalare programe; ex:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'RUN apk add --update redis'}
                            </SyntaxHighlighter>
                        </li>
                        <li>specificare comanda care se va executa la pornirea containerului; de exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'CMD ["redis-server"]'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Un fisier Dockerfile <b>trebuie sa incepa cu instructiunea FROM</b>.

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM alpine\n' +
                            '\n' +
                            'RUN apk add --update redis\n' +
                            '\n' +
                            'CMD ["redis-server"]'}
                    </SyntaxHighlighter>

                    Creare imagine din Dockerfile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    <hr/>
                    A scrie un fisier Dockerfile e ca si cum cineva cumpara un calculator nou, fara nici un sistem de operare si scopul lui este sa porneasca browser-ul Chrome.
                    <br/>
                    Pentru a realiza acest lucru, este nevoie sa treaca prin urmatorii pasi:
                    <ul>
                        <li>instalare sistem de operare</li>
                        <li>descarcare si intalare chrome</li>
                        <li>executare Chrome</li>
                    </ul>

                    Acum vom echivala:
                    <ul>
                        <li>instalare sistem de operare (echivalent cu specificare unei imagini de baza, folosind intructiune <b>FROM</b> <i>alpine</i> );
                            <br/>
                            <ul>
                                <li>
                                    alpine este un sistem de operare bazat pe linux (mic si eficient in utilizarea resurselor)
                                </li>
                                <li>
                                    <i>alpine</i> vine cu un set de programe preinstalare (de exemplu <i>apk</i>, folosit in instructiunea <b>RUM</b>;
                                    <br/>
                                    APK == Alpine Package Keeper)
                                </li>
                            </ul>

                        </li>
                        <li>descarcare si intalare chrome (echivalent cu: RUN apk add --update redis)</li>
                        <li>executare Chrome (echivalent cu: CMD ["redis-server"])</li>
                    </ul>

                    <hr/>
                    <b>Exercitiu:</b>
                    <br/>
                    <br/>
                    creare director:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir first-image'}
                    </SyntaxHighlighter>

                    schimbare director:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd first-image/'}
                    </SyntaxHighlighter>

                    creare fisier:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch Dockerfile'}
                    </SyntaxHighlighter>

                    editare fisier:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    se editeaza cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM alpine\n' +
                            '\n' +
                            'RUN apk add --update redis\n' +
                            '\n' +
                            'CMD ["redis-server"]'}
                    </SyntaxHighlighter>
                    Apoi CTRL+O - pentru a salva
                    <br/>
                    Apoi CTRL+X - pentru a iesi din nano

                    <br/>
                    <br/>
                    Pentru a fi siguri ca am scris ce trebuie:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cat Dockerfile'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'FROM alpine\n' +
                            '\n' +
                            'RUN apk add --update redis\n' +
                            '\n' +
                            'CMD ["redis-server"]'}
                    </SyntaxHighlighter>

                    creare imagine din Dockerfile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  2.048kB\n' +
                            'Step 1/3 : FROM alpine\n' +
                            'latest: Pulling from library/alpine\n' +
                            '213ec9aee27d: Pull complete\n' +
                            'Digest: sha256:bc41182d7ef5ffc53a40b044e725193bc10142a1243f395ee852a8d9730fc2ad\n' +
                            'Status: Downloaded newer image for alpine:latest\n' +
                            ' ---> 9c6f07244728\n' +
                            'Step 2/3 : RUN apk add --update redis\n' +
                            ' ---> Running in ddedc0be1ae2\n' +
                            'fetch https://dl-cdn.alpinelinux.org/alpine/v3.16/main/x86_64/APKINDEX.tar.gz\n' +
                            'fetch https://dl-cdn.alpinelinux.org/alpine/v3.16/community/x86_64/APKINDEX.tar.gz\n' +
                            '(1/1) Installing redis (7.0.5-r0)\n' +
                            'Executing redis-7.0.5-r0.pre-install\n' +
                            'Executing redis-7.0.5-r0.post-install\n' +
                            'Executing busybox-1.35.0-r17.trigger\n' +
                            'OK: 9 MiB in 15 packages\n' +
                            'Removing intermediate container ddedc0be1ae2\n' +
                            ' ---> 69133d5fb34e\n' +
                            'Step 3/3 : CMD ["redis-server"]\n' +
                            ' ---> Running in 4ebdf6f46b55\n' +
                            'Removing intermediate container 4ebdf6f46b55\n' +
                            ' ---> 4d2b4a167b0f\n' +
                            'Successfully built 4d2b4a167b0f'}
                    </SyntaxHighlighter>

                    explicatii
                    <ul>
                        <li>
                            (1/3):
                            <SyntaxHighlighter>
                                {'Sending build context to Docker daemon  2.048kB\n' +
                                    'Step 1/3 : FROM alpine\n' +
                                    'latest: Pulling from library/alpine\n' +
                                    '213ec9aee27d: Pull complete\n' +
                                    'Digest: sha256:bc41182d7ef5ffc53a40b044e725193bc10142a1243f395ee852a8d9730fc2ad\n' +
                                    'Status: Downloaded newer image for alpine:latest\n' +
                                    ' ---> 9c6f07244728'}
                            </SyntaxHighlighter>
                            <ul>
                                <li> se descarca imaginea <i>alpine</i></li>
                                <li> {"=>"} rezulta imaginea cu id-ul <i>9c6f07244728</i></li>
                            </ul>
                            <br/>
                        </li>
                        <li>
                            (2/3)
                            <SyntaxHighlighter>
                                {'Step 2/3 : RUN apk add --update redis\n' +
                                    ' ---> Running in ddedc0be1ae2\n' +
                                    'fetch https://dl-cdn.alpinelinux.org/alpine/v3.16/main/x86_64/APKINDEX.tar.gz\n' +
                                    'fetch https://dl-cdn.alpinelinux.org/alpine/v3.16/community/x86_64/APKINDEX.tar.gz\n' +
                                    '(1/1) Installing redis (7.0.5-r0)\n' +
                                    'Executing redis-7.0.5-r0.pre-install\n' +
                                    'Executing redis-7.0.5-r0.post-install\n' +
                                    'Executing busybox-1.35.0-r17.trigger\n' +
                                    'OK: 9 MiB in 15 packages\n' +
                                    'Removing intermediate container ddedc0be1ae2\n' +
                                    ' ---> 69133d5fb34e'}
                            </SyntaxHighlighter>
                            pornind de la imaginea cu id-ul <i>9c6f07244728</i>:
                            <ul>
                                <li>se porneste un container cu id-ul <i>ddedc0be1ae2</i></li>
                                <li>se executa instructiunea <i>RUN apk add --update redis</i></li>
                                <li>se instaleaza redis</li>
                                <li>se sterge containerul intermediar cu id-ul <i>ddedc0be1ae2</i></li>
                                <li> {"=>"} rezulta imaginea cu id-ul <i>69133d5fb34e</i>
                                    <br/>
                                    (<b>dar</b> inainte de a sterge containerul intermediar, se face o copie a <b>FS (file system)</b> in imaginea <i>9c6f07244728</i>;
                                    <br/>
                                    deci imaginea <i>9c6f07244728</i> va contine si <i>redis</i> )
                                </li>
                            </ul>

                        </li>
                        <li>
                            (3/3)
                            <SyntaxHighlighter>
                                {'Step 3/3 : CMD ["redis-server"]\n' +
                                    ' ---> Running in 4ebdf6f46b55\n' +
                                    'Removing intermediate container 4ebdf6f46b55\n' +
                                    ' ---> 4d2b4a167b0f\n' +
                                    'Successfully built 4d2b4a167b0f'}
                            </SyntaxHighlighter>

                            pornind de la imaginea cu id-ul <i>9c6f07244728</i>:
                            <ul>
                                <li>se porneste un container cu id-ul <i>4ebdf6f46b55</i></li>
                                <li>se executa instructiunea <i>CMD ["redis-server"]</i></li>
                                <li>se sterge containerul intermediar cu id-ul <i>4ebdf6f46b55</i></li>
                                <li> {"=>"} rezulta imaginea cu id-ul <i>4d2b4a167b0f</i>
                                    <br/>
                                    (<b>dar</b> inainte de a sterge containerul intermediar, se face o copie a <b>Startup Command</b> in imaginea <i>4d2b4a167b0f</i>;
                                    <br/>
                                    deci imaginea <i>4d2b4a167b0f</i> va contine si <i>redis</i>  + comanda de startup: <i>redis-server</i>)
                                </li>
                            </ul>
                        </li>
                        <li>
                            imaginea finala: <i>4d2b4a167b0f</i>
                        </li>
                    </ul>

                    <hr/>
                    <b>Cache pentru imagini</b>
                    <br/>
                    <br/>

                    In urma executarii unei comenzii <b>docker build .</b> rezulta:
                    <ul>
                        <li>containere intermediare {"=>"} care se sterg</li>
                        <li>imagini intermediere {"=>"} care se cache-uiesc</li>
                    </ul>
                    Deci, daca modificam in fisierul <b>Dockerfile</b>:
                    <SyntaxHighlighter>
                        {'FROM alpine\n' +
                            '\n' +
                            'RUN apk add --update redis\n' +
                            '\n' +
                            'RUN apk add --update gcc\n' +
                            '\n' +
                            'CMD ["redis-server"]'}
                    </SyntaxHighlighter>
                    Si executam:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  2.048kB\n' +
                            'Step 1/4 : FROM alpine\n' +
                            ' ---> 9c6f07244728\n' +
                            'Step 2/4 : RUN apk add --update redis\n' +
                            ' ---> Using cache\n' +
                            ' ---> 69133d5fb34e\n' +
                            'Step 3/4 : RUN apk add --update gcc\n' +
                            ' ---> Running in 3a836ecb536d\n' +
                            'fetch https://dl-cdn.alpinelinux.org/alpine/v3.16/main/x86_64/APKINDEX.tar.gz\n' +
                            'fetch https://dl-cdn.alpinelinux.org/alpine/v3.16/community/x86_64/APKINDEX.tar.gz\n' +
                            '(1/10) Installing libgcc (11.2.1_git20220219-r2)\n' +
                            '(2/10) Installing libstdc++ (11.2.1_git20220219-r2)\n' +
                            '(3/10) Installing binutils (2.38-r3)\n' +
                            '(4/10) Installing libgomp (11.2.1_git20220219-r2)\n' +
                            '(5/10) Installing libatomic (11.2.1_git20220219-r2)\n' +
                            '(6/10) Installing gmp (6.2.1-r2)\n' +
                            '(7/10) Installing isl22 (0.22-r0)\n' +
                            '(8/10) Installing mpfr4 (4.1.0-r0)\n' +
                            '(9/10) Installing mpc1 (1.2.1-r0)\n' +
                            '(10/10) Installing gcc (11.2.1_git20220219-r2)\n' +
                            'Executing busybox-1.35.0-r17.trigger\n' +
                            'OK: 112 MiB in 25 packages\n' +
                            'Removing intermediate container 3a836ecb536d\n' +
                            ' ---> 895c3853ce52\n' +
                            'Step 4/4 : CMD ["redis-server"]\n' +
                            ' ---> Running in e07c90a4bba2\n' +
                            'Removing intermediate container e07c90a4bba2\n' +
                            ' ---> 2ca7526999e8\n' +
                            'Successfully built 2ca7526999e8'}
                    </SyntaxHighlighter>
                    ceea ce este de remarcat este:
                    <SyntaxHighlighter>
                        {'Step 2/4 : RUN apk add --update redis\n' +
                            ' ---> Using cache\n' +
                            ' ---> 69133d5fb34e'}
                    </SyntaxHighlighter>
                    adica se foloseste o imagine cache-uita: <b>69133d5fb34e</b>! (rezultata in urma instatarii <i>redis</i>)

                    <br/>
                    <br/>
                    Daca mai rulam o data:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  2.048kB\n' +
                            'Step 1/4 : FROM alpine\n' +
                            ' ---> 9c6f07244728\n' +
                            'Step 2/4 : RUN apk add --update redis\n' +
                            ' ---> Using cache\n' +
                            ' ---> 69133d5fb34e\n' +
                            'Step 3/4 : RUN apk add --update gcc\n' +
                            ' ---> Using cache\n' +
                            ' ---> 895c3853ce52\n' +
                            'Step 4/4 : CMD ["redis-server"]\n' +
                            ' ---> Using cache\n' +
                            ' ---> 2ca7526999e8\n' +
                            'Successfully built 2ca7526999e8'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerfileDockerContent;