import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class SignalsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-signals", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Introducere in Signals </b>
                    <br/>
                    <br/>

                    Un <b>signal</b> / semnal este un obiect care are o valoare și poate fi observat pentru modificări.
                    <br/>
                    Este similar cu o stare, dar nu este legat de o componentă.
                    <br/>
                    Poate fi folosit pentru a partaja date între componente.
                    <br/>
                    Actualizează componentele atunci când semnalul se schimbă și actualizează interfața de utilizare fără a reda întreaga componentă.

                    <hr/>
                    <b>2. Instalare</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm i @preact/signals-react'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Creare signal</b>
                    <br/>
                    <br/>

                    Un signal se creaza folosind functia <b>signal</b> care poate lua o valoare implicita ca parametru si returneza un obiect de tip <b>Proxy</b>.
                    <br/>
                    Valoarea semnalului poate fi accesată folosind proprietatea: <b>signal.value</b>.
                    <br/>
                    Putem seta valoarea semnalului folosind <b>signal.value = newValue</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { signal } from "@preact/signals-react";\n' +
                            'const count = signal(0);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Effect</b>
                    <br/>
                    <br/>

                    Metoda <b>effect</b> se va apela in mod automat la modificarea variabilei de tip <b>signal</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React from "react";\n' +
                            'import { signal, effect } from "@preact/signals-react";\n' +
                            '\n' +
                            'const count = signal(0);\n' +
                            'const Counter = () => {\n' +
                            '  effect(() => console.log(count.value));\n' +
                            '  return <button onClick={() => count.value++}>{count}</button>;\n' +
                            '};'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.builder.io/blog/usesignal-is-the-future-of-web-frameworks"}>
                               useSignal() is the Future of Web Frameworks
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SignalsReactContent;