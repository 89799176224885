import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Tips1DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tips1", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Tips</b>

                    1. Crearea Swarm Service:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name webserver --replicas 1 nginx'}
                    </SyntaxHighlighter>

                    <hr/>
                    2. Diferenta dintre replicared service si global service:
                    <ul>
                        <li>
                            replicated service {"=>"} va avea N containere (--replicas N)
                        </li>
                        <li>
                            global service {"=>"} va crea 1 container in fiecare nod al clusterului (exemplu: antivirus)
                        </li>
                    </ul>

                    <hr/>
                    3. Moduri de scalare Swarm service:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service scale mywebserver=5'}
                    </SyntaxHighlighter>
                    (permite specificarea mai multor servicii in aceasi comanda)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service update --replicas 5 mywebserver'}
                    </SyntaxHighlighter>
                    (permmite spercificarea unui singur servciu per comanda)

                    <hr/>

                    4. Draining Swarm Node:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node update --availability drain swarm-node'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node update --availability active swarm-node'}
                    </SyntaxHighlighter>

                    <hr/>
                    5. Docker Stack CLI:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack deploy --compose-file docker-compose.yml'}
                    </SyntaxHighlighter>

                    <hr/>
                    6. Constrangeri de plasare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name webserver --constraint node.label.region==blr  nginx'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name webserver --constraint node.label.region!=blr  nginx'}
                    </SyntaxHighlighter>

                    <hr/>
                    7. Adaugare label la nod:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node update --label-add region=mumbai worker-node-id'}
                    </SyntaxHighlighter>

                    <hr/>
                    8. Retele Overlay si Securitate:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --opt-encrypted --driver overlay my-overlay-secure-network'}
                    </SyntaxHighlighter>
                    Retelele overlay permit containerelor raspandite pe servere diferite sa comunice.
                    <br/>
                    Comunicarea intre containere se face securizat cu tunele IPSec.

                    <hr/>
                    9. Aspecte pentru investigarea problemelor:
                    <ul>
                        <li><b>docker system events</b> {"=>"} ofera in real-time informatii de la server</li>
                        <li>Service Deployment va fi in starea <i>pending</i> daca nodul este <i>drain</i></li>
                        <li>Service Deployment va fi in starea <i>pending</i> din cauza contrangerilor de plasamant</li>
                        <li>se poate face inspect pe task-uri pentru a vedea mai multe informatii</li>
                    </ul>

                    <hr/>
                    10. Quorum:
                    <ul>
                        <li>
                            Fault Tolerance = [(Cluster size - 1)/2]
                        </li>
                        <li>
                            Cluster size = 2 * Fault Tolerance + 1
                            <br/>
                            Cluster size = 2 * Fault Tolerance
                        </li>
                    </ul>

                    <hr/>
                    11. Comenzi Docker Stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack deploy'}
                    </SyntaxHighlighter>

                    Listare stack-uri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack ls'}
                    </SyntaxHighlighter>

                    Listare task-uri dintr-un stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack ps'}
                    </SyntaxHighlighter>

                    Lstare servicii dintr-un stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack services'}
                    </SyntaxHighlighter>

                    <hr/>
                    12. Comenzi Docker Service
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create'}
                    </SyntaxHighlighter>

                    Afiseaza detalii despre unul sau mai mule servicii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service inspect'}
                    </SyntaxHighlighter>

                    Listare task-uri pentru unu sau mai multe servicii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service ps'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service scale'}
                    </SyntaxHighlighter>

                    <hr/>
                    13. Swarm Routing Mesh {"=>"} permite fiecarui nod sa accepte conexiuni pe portul publicat pentru fiecare serviciu care ruleaza in swarm,
                    chiar daca pe acel nod nu ruleaza nici un nod:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name my_web --replica 3 --publish published=8080, target=80 nginx'}
                    </SyntaxHighlighter>

                    <hr/>

                    14. Actualziare imagine in swarm pentru un serviciu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --image [my-image][:tag] [service-name]'}
                    </SyntaxHighlighter>

                    <hr/>
                    15. Manager / Worker Node:
                    <ul>
                        <li>pentru a preveni planificatorul sa plaseze sarcini pe un nod manager, trebuie setata disponibilitatea pentru nodul managet la Drain</li>
                        <li>scopul nodurilor worker este de executa containere</li>
                        <li>implementarea Raft este utilizata pentru a mentine starea interna conseventa</li>
                        <li>
                            nodurile manager se ocupa cu sarcinile de gestionare a clusterelor, cum ar fi serviciile de planificare (scheduling)
                        </li>
                    </ul>

                    <hr/>
                    16. Join Token in Swarm:
                    <br/>
                    Join token sunt secrete care permit unui nod sa se alature in swarm.
                    <br/>
                    Exista 2 tipuri de join token: unul pentru rolul de worker si unul pentur rolul de manager.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join-token worker'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join-token manager'}
                    </SyntaxHighlighter>

                    <hr/>
                    17. Initializare Swarm:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm init'}
                    </SyntaxHighlighter>
                    (Docker Engine targetat prin aceasta comanda devine manager intr-un nou creat singur-nod swarm)

                    <hr/>
                    18. Parasire Swarm:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm leave'}
                    </SyntaxHighlighter>
                    (se poate folosi optiunea <b>--force</b> pe un manger de a fi sters dintr-un swarm;
                    comanda se poate folosi doar in situatia in care swarm nu va mai fi folosit dupa plecare managerului, cum ar fi un swarm cu un singur nod)

                    <hr/>
                    19. Service Discovery in Swarm
                    <br/>
                    Docker foloseste DNS incorporat pentru a oferi  servicii de descoperire (service discovery) pentru containerele care ruleaza pe un singur Docker engine si task-uri care ruleaza intr-un docker.


                    <hr/>
                    20. Comenzi Docker system:
                    <b/>
                    Afiseaza informatii legate de spatiu pe disc folosit de Docker Daemon:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker system df'}
                    </SyntaxHighlighter>

                    Afiseaza informatii real-time despre evenimentele de pe server; se pot filtra;
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker system events'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker system events --filter "event=stop"'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker system events --filter "image=alpine"'}
                    </SyntaxHighlighter>

                    <hr/>
                    21. Dockerd:
                    <b/>

                    <ul>
                        <li>este procesul persistent care gestioneaza containerele</li>
                        <li>docker foloseste fisiere binare diferite pentru daemon si client</li>
                        <li>pentru a rula daemonul, se tasteaza: <b>dockerd</b></li>
                        <li>pentru a specifica optiuni de configurare, se foloseste fisierul <b>daemon.json</b></li>
                        <li>locatia implicita pentru <b>daemon.json</b> pe linux: <b>/etc/docker/daemon.json</b></li>
                        <li>locatia implicita pentru <b>daemon.json</b> pe Windows: %programdata%\docker\config\daemon.json</li>
                    </ul>

                    <hr/>
                    22. Docker container inspect:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect'}
                    </SyntaxHighlighter>

                    <hr/>
                    23. Diferente intre Containere si Masini virtuale:
                    <ul>
                        <li>masini virtuale {"=>"} contine intre sistemul de operare (OS)</li>
                        <li>containerele {"=>"} folosesc resursele sistemului de operare gazda (containerele sunt izolate, dar impart OS)</li>
                    </ul>

                    <hr/>
                    24. Audit Logging in UCP:
                    <br/>
                    Exista 3 modele:
                    <ul>
                        <li>none</li>
                        <li>Metadata</li>
                        <li>Request</li>
                    </ul>

                    <hr/>
                    25. Storage Class Set to Empty Strong
                    <br/>
                    Daca storageClassName:"" in PVC, atunci dynamic provisioning is disabled, si nu se foloseste nici un storage class

                    <hr/>
                    26. Stergere imagine din DTR
                    <br/>
                    Pentru a sterge o imagine (toate versiunile) din DTR se poate sterge intre repository-ul

                    <hr/>
                    27.  Dockerfile:
                    <ul>
                        <li>ADD</li>
                        <li>COPY</li>
                        <li>RUN</li>
                        <li>ENTRYPOINT (nu poate fi suprascris)</li>
                        <li>ENV (seteaza variaible de mediu);</li>
                        <li>VOLUMES</li>
                        <li>CMD (poate fi suprascris)</li>
                        <li>HEALTHCHECK ( ne permite sa testam daca aplicatia este sanatoasa)

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'HEALTHCHECK CMD curl --fail http://localhost || exit 1'}
                            </SyntaxHighlighter>
                            (daca se iese cu 0, e sanatos, daca se iese 1 este nesanatos)

                        </li>
                        <li>WORKDIR (seteaza directorul de lucru pentru: RUN, CMD, ENTRYPOINT, COPY, ADD)</li>
                        <li>EXPOSE (informeaza Docker ca containerul asculta pe un port de retea specificat, la runtime; nu publica portul;
                            este un tip de documentatie intre cel care construieste imaginea si persoana care ruleaza containerul;
                            sa stie ce porturi pot fi publicate)</li>
                    </ul>

                    <hr/>
                    28.  Diferente dintre ADD si COPY:
                    <ul>
                        <li>COPY permite copierea fisierelor de pe local la destinatie</li>
                        <li>ADD:
                            <ul>
                                <li>
                                    permite copierea fisierelor de pe local la destinatie
                                </li>
                                <li>
                                    permite copierea fisierelor de pe un URL la destinatie
                                </li>
                                <li>
                                    extractie fisiere TAR
                                </li>
                            </ul>
                            este recomandata folosirea wget/curl
                        </li>
                    </ul>

                    <hr/>
                    29.  Optiunea de <b>--format</b>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images --format "{{.ID}}"'}
                    </SyntaxHighlighter>

                    <hr/>
                    30. Optiune de filtrare
                    <br/>
                    Optiunea de filtrare permite sa filtram output-ul baza pe o conditie;
                    <br/>
                    exemple:
                    <ul>
                        <li>sa se afiseze toate imaginile dangling</li>
                        <li>sa se afiseze toate imaginile create dupa imaginea N</li>
                        <li>sa se afiseze toate containerele care sunt in stadiul de existed</li>
                    </ul>

                    <hr/>
                    31. Acesare in-secure docker registries
                    <br/>
                    In mod implicit, docker nu permite efectuarea de operatii cu un registru insecure.
                    <br/>
                    Dar se poate adauga o exceptie in fisierul: <b>/etc/docker/daemon.json</b>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'{"insecure-reistries":["myregistrydomain.ro:5000"]}'}
                    </SyntaxHighlighter>

                    <hr/>
                    32. Push o imagine pe un repository privat:
                    <br/>
                    Imaginea trebuie sa aiba un tag care incepe cu numele DNS; de exemplu daca DNS / Registry Name: example.com:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker docker ubuntu:latest example.com/myrepo:ubuntu'}
                    </SyntaxHighlighter>


                    <hr/>
                    33. Login pe un repository privat:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker login example.com'}
                    </SyntaxHighlighter>

                    <hr/>
                    34. Cautari in registru:
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search nginx --filter "is-official=true"'}
                    </SyntaxHighlighter>


                    <hr/>
                    35. Salvare imagine intr-o arhiva tar:
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker save busybox > busybox.tar'}
                    </SyntaxHighlighter>

                    <hr/>
                    36. Incarcare imagine dintr-o arhiva tar:
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker load < busybox.tar'}
                    </SyntaxHighlighter>

                    <hr/>
                    37. Salvare stare curent a unui container intr-o imagine:
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker commit ete454543 container-image:v2'}
                    </SyntaxHighlighter>

                    <hr/>
                    38. Export un container catre alt system ca imagine in format tar:
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker export my-container > container.tar'}
                    </SyntaxHighlighter>
                    Cand se exporta un container, rezulta o imagine flattened.

                    <hr/>
                    39. Variabile de mediu
                    <br/>
                    Se pot folosi optiunile <b>--env</b>, <b>-e</b>, <b>--env-file</b> pentru a seta variabile de mediu intr-un container care ruleaaza,
                    sau suprascrie variabile definite in Dockerfile.
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --env VAR1=value1 --env VAR2=value2 ubuntu env | grep VAR'}
                    </SyntaxHighlighter>


                    <hr/>
                    40. Tag-uire imagini:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag SOURCE_IMAGE[:TAG] TARGET_IMAGE[:TAG]'}
                    </SyntaxHighlighter>
                    exemplu:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag httpd fedora/httpd:version1.0'}
                    </SyntaxHighlighter>


                    <hr/>
                    41. Docker image prune
                    <br/>
                    Sterge imaginile nefolosite; in mod implicit sterge doar imaginile dangling;
                    <br/>
                    O imagine dangling = imagine fara tag si care nu este referita de nici un container;
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image prune'}
                    </SyntaxHighlighter>

                    <hr/>
                    42. Descarcare imagini din registrul privat
                    <br/>
                    Daca o imagine este disponibila pe registru privat care cere logare, se foloseste optiunea <b>--with-registry-auth</b>
                    cu <b>docker service create</b>, dupa logare:


                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker login registry.example.com'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --with-registry-auth --name my-service registry.example.com/acme/my_image:latest'}
                    </SyntaxHighlighter>

                    Acesta transmite login token de pe clientul local la nodurile swarm unde serviciul este deployat folosind log-uri criptate WAL.
                    Cu aceasta informatie, nodurile pot sa se autentifice la registru si sa faca <b>pull</b> la imagine.

                    <hr/>
                    43. Build cache
                    <br/>
                    Docker creaza imagini pentru containere folosind layere.
                    <br/>
                    Fiecare comanda care se gaseste in Dockerfile creaza un nou layer.
                    <br/>
                    Docker foloseste un layer cache pentru a optimiza procesul de buildare a imaginilor si al face mai rapid.

                    <hr/>
                    44. Optiuni pentru HEALTHCHECK:
                    <br/>
                    Optiuni:
                    <ul>
                        <li>
                            --interval=30s, interval intre doua verificari, implicit este 30 de secunde; deci face verificari din 30 in 30 de secunde;
                        </li>
                        <li>
                            --timeout=30s, comanda de verificare a starii ruleaza pana cand nu expira timpul setat; valoare implicita este 30 de secunde
                        </li>
                        <li>
                            --start-period=0s, perioada de timp data containerul pentru a se initializa;
                            <br/>
                            daca verificarea starii de sanatate nu reuseste in aceea perioada, nu se ia in considerare pentru numarul maxim de incercari;
                            <br/>
                            daca verificarea starii de sanatate reuseste in aceea perioada, containerul este considerat pornit si toate  eșecurile consecutive vor fi luate în considerare pentru numărul maxim de reîncercări
                            {/*<br/>*/}
                            {/*optiunea este adaugate in formatul de fisier 3.4.*/}
                        </li>
                        <li>
                            -retries=3, numarul de erori consecutive;
                            <br/>
                            La fel ca CMD, ENTRYPOINT, HEALTHCHECK poate apărea o singură dată. Dacă sunt scrise mai multe, doar ultima va avea efect
                        </li>
                    </ul>

                    <hr/>
                    45. Multi-Stage Builds {"=>"} permite user-ilor sa foloseasca artefacte dintr-o imagine in alta
                    <br/>

                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips1DockerContent;