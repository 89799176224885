import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class MultipodK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-multipod", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Multipod</b>

                    <br/>
                    <br/>

                    Design patterns:
                    <ul>
                        <li>sidecar (ex: log-agent)</li>
                        <li>adapter (ex: aplicatie care proceseaza informatia, intr-un format comun, de exemplu, inainte sa fie trimisa mai departe);
                            <hr/>
                            Prometheus este o aplicație de monitorizare foarte cunoscută care verifică nu numai dacă o aplicație funcționează, ci și dacă funcționează „cum era de așteptat” sau „perfect”.
                            <br/>

                            Prometheus funcționează interogând un punct final expus de aplicația țintă.
                            Punctul final trebuie să returneze datele de diagnosticare într-un format la care se așteaptă Prometheus.
                            <br/>
                            O posibilă soluție este configurarea fiecărei aplicații pentru a-și scoate datele de sănătate într-un mod prietenos cu Prometheus.
                            <br/>
                            Cu toate acestea, poate fi necesar să comutați soluția de monitorizare la un alt instrument care se așteaptă la un alt format.
                            <br/>
                            Schimbarea codului aplicației de fiecare dată când aveți nevoie de un nou format de stare de sănătate este în mare măsură ineficientă.
                            <br/>
                            Urmând modelul adaptorului, putem avea un container sidecar în același Pod ca și containerul aplicației.
                            <br/>
                            Singurul scop al sidecar-ului (containerul adaptorului) este de a „traduce” rezultatul de la punctul final al aplicației într-un format pe care Prometheus (sau instrumentul client) îl acceptă și îl înțelege.
                            <hr/>
                            Folosind modelul adaptorului, stabilim o interfață unificată pentru containerul nostru de aplicații care poate fi utilizată de un serviciu terță parte.
                        </li>
                        <li>ambassador (ex: se ocupă de transferarea conexiunilor de la containerul de aplicație la alte servicii; proxy pentru client)

                            <hr/>
                            De exemplu, aproape toate aplicațiile au nevoie de o conexiune la baza de date într-o anumită fază.
                            <br/>
                            Într-un loc cu mai multe medii, ar exista o bază de date de testare, o bază de date intermediară și o bază de date de producție.
                            <br/>
                            Când se scrie definiția Pod pentru containerul aplicației lor, dezvoltatorii trebuie să acorde atenție bazei de date la care se vor conecta.
                            <br/>
                            Un șir de conexiune la bază de date poate fi schimbat cu ușurință printr-o variabilă de mediu sau o configMap.
                            De asemenea, am putea folosi un model sidecar care trimite conexiunile DB către serverul corespunzător, în funcție de locul în care rulează.
                            <br/>
                            Dezvoltatorii nu trebuie să schimbe șirul de conexiune, ar putea lăsa serverul DB la localhost ca de obicei.
                            Când este implementat într-un mediu diferit, containerul Ambassador detectează în ce mediu rulează  și se conectează la serverul corect.
                            <hr/>
                            Containerul Ambassador este, de asemenea, o formă de container sidecar. Este folosit ca proxy între containerul aplicației și unul sau mai multe servicii externe.
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultipodK8sContent;