import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../design-patterns/IndexContent";

class ProbabilityHiddenContent extends BaseContentPage{

    constructor(props) {
        super(props, "simple-factory", IndexContent);
    }

    render() {
        return (
            <div className="home article">
                <div className={"title"}>
                    Probabilitatea sau Consolidarea încrederii
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <MathComponent tex={String.raw`P(x)={{n_x} \over {n}}, n \to \infty `}/>
                </div>
                <div className={"text-justify"}>
                    adică, <b>probabilitatea sau șansa să apară x</b> (scris P(x)) este egală cu <b>numărul de apariți a lui x</b> (notat n<sub>x</sub>)
                    în <b>n</b> încercări, unde n este un număr foarte mare, care tinde spre infinit.
                    <br/><br/>
                    Practic, probabilitatea <i>invață</i> din experimentele trecute și se <i>ajustează</i>, <i>stabilizându-se</i> după un număr mare de încercări.
                </div>
                <div className={"text-justify"}>
                    <br/>
                    să presupunem<br/>
                    că ai o monedă<br/>
                    și ești curios<br/>
                    care față<br/>
                    cap sau pajură<br/>
                    va rămâne vizibilă<br/>
                    după<br/>
                    ce o arunci<br/>
                    și<br/>
                    nu mai cade<br/>
                </div>
                <br/>
                <div className={"text-justify"}>
                    dacă<br/>
                    după prima aruncare<br/>
                    rămâne pajura<br/>
                    vei putea presupune<br/>
                    că numai pajura<br/>
                    poate exista<br/>
                    drept sansă<br/>
                </div>
                <br/>
                <div className={"text-justify"}>
                    dacă <br/>
                    si după a doua încercare<br/>
                    rămane pajura<br/>
                    atunci<br/>
                    iti vei <br/>
                    <i>consolida increderea</i><br/>
                </div>
                <br/>
                <div className={"text-justify"}>
                    dar dacă vei obtine<br/>
                    un cap<br/>
                    atunci<br/>
                    va apare <i>indoiala</i><br/>
                </div>
            </div>
        );
    }
}

export default ProbabilityHiddenContent;