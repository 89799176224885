import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../raspberrypi/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FastWhisperRpiContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rpi-fast-whisper", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# Faster Whisper transcription with CTranslate2\n' +
                            '\n' +
                            '# https://pypi.org/project/faster-whisper/\n' +
                            '\n' +
                            '# GIT:\n' +
                            '#   https://github.com/SYSTRAN/faster-whisper\n' +
                            '\n' +
                            '# Instalare modul:\n' +
                            '#   pip install faster-whisper\n' +
                            '#   sau:\n' +
                            '#   pip install faster-whisper --break-system-package\n' +
                            '\n' +
                            '# vezi si:\n' +
                            '#   https://github.com/mldljyh/whisper_real_time_translation\n' +
                            '\n' +
                            'from faster_whisper import WhisperModel\n' +
                            '\n' +
                            '#model_size = "large-v3"\n' +
                            '#model_size = "large-v2"\n' +
                            'model_size = "tiny"\n' +
                            '\n' +
                            '# Run on GPU with FP16\n' +
                            '#model = WhisperModel(model_size, device="cuda", compute_type="float16")\n' +
                            '\n' +
                            '# or run on GPU with INT8\n' +
                            '# model = WhisperModel(model_size, device="cuda", compute_type="int8_float16")\n' +
                            '# or run on CPU with INT8\n' +
                            'model = WhisperModel(model_size, device="cpu", compute_type="int8")\n' +
                            '\n' +
                            'segments, info = model.transcribe("speech-gtts.mp3", beam_size=5)\n' +
                            '\n' +
                            'print("Detected language \'%s\' with probability %f" % (info.language, info.language_probability))\n' +
                            '\n' +
                            'for segment in segments:\n' +
                            '    print("[%.2fs -> %.2fs] %s" % (segment.start, segment.end, segment.text))'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FastWhisperRpiContent;