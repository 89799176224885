import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlScaleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-scale", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl scale</b>
                    <br/>
                    <br/>

                    Scalare deployment cu numele <i>nginx-deployment</i> la 5 replici:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl scale deployment nginx-deployment --replicas=5'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'deployment.apps/nginx-deployment scaled'}
                    </SyntaxHighlighter>

                    Afisam pod-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'nginx-deployment-5fbdf85c67-2xjxn      0/1     ContainerCreating   0          17s\n' +
                            'nginx-deployment-5fbdf85c67-7dffb      0/1     ContainerCreating   0          18s\n' +
                            'nginx-deployment-5fbdf85c67-88dkg      1/1     Running             0          51m\n' +
                            'nginx-deployment-5fbdf85c67-tbc7p      0/1     ContainerCreating   0          18s\n' +
                            'nginx-deployment-5fbdf85c67-tq4vs      0/1     ContainerCreating   0          18s\n'}
                    </SyntaxHighlighter>

                    Afisam pod-urile cu ip-urile lor:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -o wide'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'nginx-deployment-5fbdf85c67-2xjxn      1/1     Running            0          6m3s   172.17.0.20   minikube   <none>           <none>\n' +
                            'nginx-deployment-5fbdf85c67-7dffb      1/1     Running            0          6m4s   172.17.0.18   minikube   <none>           <none>\n' +
                            'nginx-deployment-5fbdf85c67-88dkg      1/1     Running            0          56m    172.17.0.17   minikube   <none>           <none>\n' +
                            'nginx-deployment-5fbdf85c67-tbc7p      1/1     Running            0          6m4s   172.17.0.21   minikube   <none>           <none>\n' +
                            'nginx-deployment-5fbdf85c67-tq4vs      1/1     Running            0          6m4s   172.17.0.19   minikube   <none>           <none>\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Dintr-un node ne putem conecta la un pod pe baza IP-ului, dar nu si din afara node-ului, in mod default.
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlScaleContent;