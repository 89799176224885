import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class UseraddLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-useradd", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Creare utilizator nou:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'useradd iulian'}
                    </SyntaxHighlighter>

                    Ne putem conecta cu acest utilizator:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'su iulian'}
                    </SyntaxHighlighter>

                    Pentru a stabili o parola pentru utilizator (il lipsa acesteia nu ne vom putea conecta remote cu acest utilizator)
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'passwd iulian'}
                    </SyntaxHighlighter>

                    Daca vom executa urmatoarea comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd ~'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'sh: 2: cd: can\'t cd to /home/iulian'}
                    </SyntaxHighlighter>
                    pentru ca <i>/home/iulian</i> nu a fost creat.

                    <hr/>
                    Pentru a iesi/deloga:
                    <SyntaxHighlighter>
                        {'exit'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a creea un utilizator cu directorul <i>home</i>, se foloseste flag-ul <b>-m</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'useradd -m iulian'}
                    </SyntaxHighlighter>

                    Afisare fisiere ascunde in /home/iulian:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -la /home/iulian'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'total 20\n' +
                            'drwxr-x--- 2 iulian iulian 4096 dec 13 16:41 .\n' +
                            'drwxr-xr-x 4 root   root   4096 dec 13 16:41 ..\n' +
                            '-rw-r--r-- 1 iulian iulian  220 ian  6  2022 .bash_logout\n' +
                            '-rw-r--r-- 1 iulian iulian 3771 ian  6  2022 .bashrc\n' +
                            '-rw-r--r-- 1 iulian iulian  807 ian  6  2022 .profile\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Shell-ul implicit pentru un nou utilizator este setat in fisierul: <b>/etc/default/useradd</b>, proprietatea: <b>SHELL=/bin/sh</b>.
                    <br/>
                    Se poate modifica, (cu <i>nano</i> de exemplu) ca shell-ul sa fie /bin/bash!
                    <hr/>
                    In mod implicit, Linux creeaza un group cu numele user-ului creat si asigneaza acest nou utilizator la grup.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseraddLinuxContent;