import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class WarPluginGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-war-plugin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Se foloseste plugin-ul <b>war</b> pentru a genera un artefact <b>WAR</b> (Web Application Arhive):

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins{\n' +
                        '   id "java"\n'+
                        '   id "war"\n'+
                        '}\n'}
                    </SyntaxHighlighter>

                    Pentru a configura acest plugin, de exemplu numele war-ului generat:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'war{\n' +
                        '\tarchiveName = "nume.war" \n' +
                        '}'}
                    </SyntaxHighlighter>

                    Deploy in Tomcat:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task deployYoTomcat(type: Copy){\n' +
                        '\tfrom war.archivePath\n' +
                        '\tinto \'c:/tomcat/webapps\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Putem sa adaugam sa depinda de task-ul war:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task deployToTomcat(type: Copy, dependsOn: \'war\'){\n' +
                        '\tfrom war.archivePath\n' +
                        '\tinto \'c:/tomcat/webapps\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    O aplicatie Java Web, exista si <b>src/main/webapp</b> (pe langa /src/main/java si /src/main/resources), unde poate pune de exemplu <i>index.html</i>.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WarPluginGradleContent;