import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class ReverseProxyNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-reverse-proxy", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Reverse proxy</b>

                    Un <b>Reverse proxy</b> este un server care se află în fața serverelor web și transmite cererile clientului (de exemplu, browser web) către acele servere web.
                    Proxy-urile inverse sunt de obicei implementate pentru a ajuta la creșterea securității , performanței și fiabilității.

                    <br/>
                    <br/>
                    <b>1.1. Configurare nginx</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -v\n' +
                            'nginx version: nginx/1.22.0'}
                    </SyntaxHighlighter>

                    Creare fisier de configurare, cu numele <i>nginx-kj.conf</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {"events {}\n" +
                            "\n" +
                            "http {\n" +
                            "\n" +
                            "   \n" +
                            "   server {\n" +
                            "   \n" +
                            "\t\tlisten 8888; \t\n" +
                            "\t\t\n" +
                            "\t\tlocation / {\n" +
                            "\t\t\treturn 200 \"hello,kj!\";\n" +
                            "\t\t}\n" +
                            "   \n" +
                            "   }\n" +
                            "   \n" +
                            "}  "}
                    </SyntaxHighlighter>

                    Pornire nginx specificand fisierul de configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -c nginx-kj.conf'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://localhost:8888\n' +
                            'hello,kj!'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Configurare php</b>
                    <br/>
                    <br/>

                    Pornire server PHP pe portul 8000:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'php -S 127.0.0.1:8000'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Listening on http://127.0.0.1:8000\n' +
                            'Document root is C:\\Users\\buzdu\n' +
                            'Press Ctrl-C to quit.'}
                    </SyntaxHighlighter>

                    Mai multe informatii <a href={"https://gist.github.com/willurd/5720255"}>aici!</a>.

                    Daca cream un fisier numit <i>raspuns-php.txt</i> cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'Raspuns PHP'}
                    </SyntaxHighlighter>

                    Apoi repornim serverul de PHP specificand fisierul:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'php -S 127.0.0.1:8000 raspuns-php.txt'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://localhost:8000\n' +
                            'Raspuns PHP'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Configurare Reverse Proxy</b>
                    <br/>
                    <br/>

                    Se foloseste directiva <b>proxy_pass</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'proxy_pass \'http://localhost:8000/\';'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '   server {\n' +
                            '\t\tlisten 8888; \t\n' +
                            '\t\t\n' +
                            '\t\tlocation / {\n' +
                            '\t\t\treturn 200 "hello,kj!";\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /php {\n' +
                            '\t\t\tproxy_pass \'http://localhost:8000/\';\n' +
                            '\t\t}\n' +
                            '   \n' +
                            '   }\n' +
                            '}  '}
                    </SyntaxHighlighter>

                    Acum daca vom face reload la nginx si vom scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://localhost:8888/php\n' +
                            'Raspuns PHP'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '   server {\n' +
                            '\t\tlisten 8888; \t\n' +
                            '\t\t\n' +
                            '\t\tlocation / {\n' +
                            '\t\t\treturn 200 "hello,kj!";\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /php {\n' +
                            '\t\t\tproxy_pass http://localhost:9999/; # PHP\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /nginxorg{\n' +
                            '\t\t\tproxy_pass https://nginx.org/; # net\n' +
                            '\t\t}\n' +
                            '   \n' +
                            '   }\n' +
                            '}  '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Importanta lui / din proxy_pass</b>
                    <br/>
                    <br/>

                    In cadrul directivei <b>proxy_pass</b>, daca nu se pune <b>/</b> la URL, atunci se presupune calea requestului original, adica <i>/php</i> (este inconsitent si nerecomandat).
                    <br/>
                    <br/>
                    In cadrul directivei <b>proxy_pass</b>, daca se pune <b>/</b> la URL/, atunci se ignora calea requestului original, adica <i>/php</i>, si va ajunge pe server-ul destinatie (de exemplu PHP, doar / in $_SERVER['REQUST_URI'] )


                    <hr/>
                    <b>4. Directiva proxy_set_header</b>
                    <br/>
                    <br/>

                    Daca scriem asa intr-un bloc <b>location</b>
                    <SyntaxHighlighter>
                        {'add_header proxied nginx;'}
                    </SyntaxHighlighter>
                    Nu se va trimite catre server-ul destinatie, dar e adaugat de nginx cand se trimite informatia catre <b>client</b>
                    (deci o va vedea doar clientul, nu si server-ul tinta).

                    <br/>
                    <br/>

                    Pentru a face ca un antent/header sa fie trimis mai departe catre server-ul destinatie:
                    <SyntaxHighlighter>
                        {'proxy_set_header proxied nginx;'}
                    </SyntaxHighlighter>
                    (dar nu se va mai trimite catre client, daca scriem doar asa, trebuie sa adaugam directiva <b>add_header</b> pentru a ajunge si pe client)
                    Deci, <b>proxy_set_header</b> permite redefinirea sau adăugarea de câmpuri la antetul cererii transmise serverului proxy.
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReverseProxyNginxContent;