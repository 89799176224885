import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class NgRxEffectsNewAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-ngrx-effects-new", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. NgRx Effects</b>
                    <br/>
                    <br/>

                    Efectele NgRx ne permit să ascultăm anumite tipuri de acțiuni și să „facem ceva” atunci când se întâmplă acțiunea respectivă.
                    Orice efect este, de asemenea, un Observabil.
                    <br/>
                    Un efect este un Observabil care folosește Fluxul de acțiune ca sursă și, de asemenea, ca destinație.

                    <br/>

                    Un effect face <b>subscribe</b> (se aboneaza) la fluxul de actiuni, si poate <b>publish</b> (publica) in fluxul de actiune.

                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng add @ngrx/effects'}
                    </SyntaxHighlighter>

                    Pentru module normale, in <i>app.module.ts</i>, in sectiunea <b>imports</b>, va genera <b>EffectsModule.forRoot([])</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [EffectsModule.forRoot([])]'}
                    </SyntaxHighlighter>

                    Pentru module aplicatii standdalone, in <i>main.ts</i> va genera <b>provideEffects()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent, { providers: [ provideEffects()  ]} );'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm install --save @ngrx/effects'}
                    </SyntaxHighlighter>

                    Pachetul <b>@ngrx/effects</b> oferă o modalitate de a izola efectele secundare în modelul său.

                    <br/>
                    De obicei efectele, se scriu in fisiere cu extensia: <i>.effects.ts</i> (de exemplu: counter.effects.ts).

                    <hr/>
                    <b>2. Definire efect</b>
                    <br/>
                    <br/>

                    Definirea unui efect se face intr-o clasa, adnotata cu <b>@Injectable()</b> si folosind <b>createEffect</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { Actions, createEffect, ofType } from \'@ngrx/effects\';\n' +
                            'import { Injectable } from \'@angular/core\';\n' +
                            'import { tap } from \'rxjs\';\n' +
                            '\n' +
                            'import { decrement, increment } from \'./counter.actions\';\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'export class CounterEffects {\n' +
                            '  saveCount = createEffect(\n' +
                            '    () =>\n' +
                            '      this.actions$.pipe(\n' +
                            '        ofType(increment, decrement),\n' +
                            '        tap((action) => {\n' +
                            '          console.log(action);\n' +
                            '          localStorage.setItem(\'count\', action.value.toString());\n' +
                            '        })\n' +
                            '      ),\n' +
                            '    { dispatch: false }\n' +
                            '  );\n' +
                            '\n' +
                            '  constructor(private actions$: Actions) {}\n' +
                            '}'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            in constructor, trebuie sa injectam <b>Actions</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'constructor(private actions$: Actions) {}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <i>saveCount</i>: numele efectului
                        </li>
                        <li>
                            <b>createEffect</b> are 2 parametrii:
                            <ul>
                                <li>
                                    o functie, prin intermediul caruia selectam actiunea observata (cu <b>ofType</b>),
                                    si ce sa facem cu ea (se poate folosi: tap(), withLatestFrom(), switchMap(), etc )
                                </li>
                                <li>
                                    obiect de configurare (ex: <i>dispatch: false</i> );
                                    <br/>
                                    optiunea <i>dispatch: false</i> specifica ca nu arunca o noua actiunea dupa terminare
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>ofType</b>: selecteaza actiunea (increment, decrement) observata
                        </li>
                    </ul>

                    Observatie:
                    <ul>
                        <li>
                            pe versiunile mai vechi, in loc de <b>createEffect</b> se folosea adnotarea <b>@Effect</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Inregistrarea unui efect</b>
                    <br/>
                    <br/>

                    Pentru module normale, in <i>app.module.ts</i>, in sectiunea <b>imports</b>, va genera <b>EffectsModule.forRoot([])</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [EffectsModule.forRoot([CounterEffects])]'}
                    </SyntaxHighlighter>

                    Pentru module aplicatii standdalone, in <i>main.ts</i> va genera <b>provideEffects()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent, { providers: [ provideEffects([CounterEffects]) ]} );'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Obtinerea unui valori din store folosind withLatestFrom</b>
                    <br/>
                    <br/>

                    In constructor adaugam:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'private store: Store<{ counter: number }>'}
                    </SyntaxHighlighter>
                    Apoi, dupa selectia actiunii cu <b>ofType</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'withLatestFrom(this.store.select(selectCount))'}
                    </SyntaxHighlighter>
                    Acum, se modifica <b>tap</b>, sa avem acces atat la actiune cat si la valoarea starii:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'tap(([action, counter]) '}
                    </SyntaxHighlighter>
                    <br/>

                    Ca extra-exemplu, se mai creeaza un efect (<i>loadCount</i>) care pe actiunea de <i>init</i> preia valoarea din <i>localStorage</i>.
                    <br/>
                    Si, apoi folosind o alta actiune <i>set</i> seteaza valoare din <i>localStorage </i> in store.
                    <br/>
                    Pentru ca functia <b>switchMap()</b> trebuie sa returneze un observabil, se foloseste <b>of()</b> care poate transforma o valoare intr-un observer
                    <br/>
                    (switchMap - se opreste din lucrat la ce are de facut si incepe sa lucreze la noua comanda' doar ultima comanda va fi finalizata)
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'loadCount = createEffect(() =>\n' +
                            '    this.actions$.pipe(\n' +
                            '      ofType(init),\n' +
                            '      switchMap(() => {\n' +
                            '        const storedCounter = localStorage.getItem(\'count\');\n' +
                            '        if (storedCounter) {\n' +
                            '          return of(set({ value: +storedCounter }));\n' +
                            '        }\n' +
                            '        return of(set({value: 0}));\n' +
                            '      })\n' +
                            '    )\n' +
                            '  );'}
                    </SyntaxHighlighter>
                    unde actiunile sunt:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export const init = createAction(\n' +
                            '  \'[Counter] Init\'\n' +
                            ');\n' +
                            '\n' +
                            'export const set = createAction(\n' +
                            '  \'[Counter] Set\',\n' +
                            '  props<{value: number}>(),\n' +
                            ');'}
                    </SyntaxHighlighter>

                    si reducer:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export const counterReducer = createReducer(\n' +
                            '  initialState,\n' +
                            '  on(increment, (state, action) => state + action.value),\n' +
                            '  on(decrement, (state, action) => state - action.value),\n' +
                            '  on(set, (state, action) => action.value),\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Exemplu complet:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Actions, createEffect, ofType } from \'@ngrx/effects\';\n' +
                            'import { Injectable } from \'@angular/core\';\n' +
                            'import { Store } from \'@ngrx/store\';\n' +
                            'import { of, switchMap, tap, withLatestFrom } from \'rxjs\';\n' +
                            '\n' +
                            'import { decrement, increment, init, set } from \'./counter.actions\';\n' +
                            'import { selectCount } from \'./counter.selectors\';\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'export class CounterEffects {\n' +
                            '  loadCount = createEffect(() =>\n' +
                            '    this.actions$.pipe(\n' +
                            '      ofType(init),\n' +
                            '      switchMap(() => {\n' +
                            '        const storedCounter = localStorage.getItem(\'count\');\n' +
                            '        if (storedCounter) {\n' +
                            '          return of(set({ value: +storedCounter }));\n' +
                            '        }\n' +
                            '        return of(set({value: 0}));\n' +
                            '      })\n' +
                            '    )\n' +
                            '  );\n' +
                            '\n' +
                            '  saveCount = createEffect(\n' +
                            '    () =>\n' +
                            '      this.actions$.pipe(\n' +
                            '        ofType(increment, decrement),\n' +
                            '        withLatestFrom(this.store.select(selectCount)),\n' +
                            '        tap(([action, counter]) => {\n' +
                            '          console.log(action);\n' +
                            '          localStorage.setItem(\'count\', counter.toString());\n' +
                            '        })\n' +
                            '      ),\n' +
                            '    { dispatch: false }\n' +
                            '  );\n' +
                            '\n' +
                            '  constructor(\n' +
                            '    private actions$: Actions,\n' +
                            '    private store: Store<{ counter: number }>\n' +
                            '  ) {}\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NgRxEffectsNewAngularContent;