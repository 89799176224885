import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class OtherPluginGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-other-plugins", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                   Alte plugin-uri utile:
                    <ul>
                        <li><b>findbugs</b>: deprecated, se foloseste <b>spotbugs</b></li>
                        <li><b>spotbugs</b>: efectuează verificări de calitate asupra fișierelor sursă Java și generează rapoarte din aceste verificări

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '  id "com.github.spotbugs" version "5.0.6"\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Mai multe detalii: <a target={"_blank"} href={"https://github.com/spotbugs/spotbugs-gradle-plugin"}>aici</a>
                        </li>

                        <li>
                            <b>PMD</b>: fectuează verificări de calitate asupra fișierelor sursă Java generează rapoarte din aceste verificări

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '    id \'pmd\'\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Verificările se executa rulând <b>gradle check</b>.
                            <br/>
                            Configurare:
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'pmd {\n' +
                                '    toolVersion = "6.23.0"\n' +
                                '    ruleSets = ["rulesets/java/quickstart.xml"]\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Build-ul va eșua dacă există încălcări ale regulilor. Un raport va fi disponibil în directorul <b>/build/reports/pmd</b>.
                            <br/>
                            Se poate configra setul de reguli. Pentru a face acest lucru, se creeaza un fisier <b>pmd.xml</b> in :<b>[root-project]/config/pmd</b>.

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<?xml version= "1.0" ?> \n' +
                                '<ruleset name= "Custom ruleset" \n' +
                                '         xmlns= "http://pmd.sourceforge.net/ruleset/2.0.0" \n' +
                                '         xmlns:xsi= "http://www.w3. org/2001/XMLSchema-instance" \n' +
                                '         xsi:schemaLocation= "http://pmd.sourceforge.net/ruleset/2.0.0\n' +
                                '         http://pmd.sourceforge.net/ruleset_2_0_0.xsd" > \n' +
                                '    <descriere>\n' +
                                '        Configurație de bază PMD\n' +
                                '    </description>\n' +
                                '\n' +
                                '    <rule ref= "category/java/bestpractices.xml"  />\n' +
                                '\n' +
                                '    <rule ref= "category/java/codestyle.xml"  />\n' +
                                '\n' +
                                '    <rule ref= "category/java/design.xml"  />\n' +
                                '\n' +
                                '    <rule ref= "category/java/documentation.xml"  />\n' +
                                '\n' +
                                '    <rule ref= "category/java/multithreading.xml"  />\n' +
                                '\n' +
                                '    <rule ref= "category/java/performance.xml"  />\n' +
                                '\n' +
                                '    <rule ref= "category/java/security.xml" />\n' +
                                '\n' +
                                '    <rule ref= "category/java/errorprone.xml"  />\n' +
                                '\n' +
                                '</ruleset>'}
                            </SyntaxHighlighter>

                            Modificare configurarii
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'pmd  { \n' +
                                '    toolVersion = "6.23.0" \n' +
                                '    ignoreFailures = true \n' +
                                '    ruleSetFiles = fișiere ( "config/pmd/pmd.xml" ) \n' +
                                '    ruleSets = [] \n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>org.gretty</b>: plugin pentru rularea unui aplicatii web pe tomcat sau jetty

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '    id \'org.gretty\' version \'4.0.1\' \n' +
                                '}'}
                            </SyntaxHighlighter>
                            Acest plugin vine cu taskuri de tipul: <i>tomcatStart</i>, <i>tomcatStop</i>, <i>jettyStart</i>, <i>jettyStop</i>, <i>appRun</i>.
                            <br/>
                            Daca se ruleaza comanda <b>gradleRun</b>, se va face build la aplicatie, se va deploya si apoi se va porni serverul Jetty.
                            <br/>
                            Daca se ruleaza comanda <b>tomcatRun</b>, se va face build la aplicatie, se va deploya si apoi se va porni serverul Tomcat.

                            <br/>
                            Daca se dorecte schimbarea portului implicit (8080):
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gretty.httpPort=8082'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>jacoco</b>: plugin care ofera metrici de acoperire a codului prin teste pentru codul scris in Java;

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '    id \'jacoco\' \n' +
                                '}'}
                            </SyntaxHighlighter>

                            exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradle clean build jacocoTestReport'}
                            </SyntaxHighlighter>

                            În mod implicit, un raport HTML este generat la build/reports/jacoco/test.

                            <br/>
                            <br/>
                            pentru a specifica ca se doreste generarea raportului, dupa rularea testelor:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'test {\n' +
                                '    finalizedBy jacocoTestReport \n' +
                                '}'}
                            </SyntaxHighlighter>

                            testele trebuie rulate inainte de generarea raportului:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'jacocoTestReport {\n' +
                                '    dependsOn test \n' +
                                '}'}
                            </SyntaxHighlighter>


                        </li>
                        <li><b>org.sonarqube</b>: plugin pentru analiza proiectelor
                            <br/>
                            se poate downloada de: <a href={"https://www.sonarqube.org/"} target={"_blank"}>aici</a>
                            <br/>
                            <br/>
                            instalare si pornire server:
                            <ul>
                                <li>se descarca</li>
                                <li>se dezarhiveaza (ex: C:\tools\sonarqube-9.3.0.51899)</li>
                                <li>se modifica fisierul <b>wrapper.conf</b> (din  <i> C:\tools\sonarqube-9.3.0.51899\conf</i>) pentru a folosi Java 11:
                                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                        {'wrapper.java.command=C:\\tools\\jdk-11\\bin\\java'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>se merge in directorul <i>bin</i>
                                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                        {'cd C:\\tools\\sonarqube-9.3.0.51899\\bin\\windows-x86-64'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>se porneste serverul:
                                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                        {'StartSonar.bat'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>se verifica ca a pornit serveru: <b>http://localhost:9000/</b></li>
                                <li>credentiale: admin/admin</li>
                            </ul>

                            <br/>
                            configurare din gradle:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '  id "org.sonarqube" version "3.3"\n' +
                                '}'}
                            </SyntaxHighlighter>

                            se ruleaza urmatoarea comanda (se scaneaza proiectul pentru a gasi vulnerabilitati si alte probleme):
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradle sonarqube'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OtherPluginGradleContent;