import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MethodsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-methods", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Declararea unei metode</b>
                    <br/>
                    <br/>

                    Declararea unei metode are urmatoarea forma:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'[modificator de access] [specificator] {tip returnat} {nume metoda}([lista de parametrii]) [throws] [tip exceptie] { \n'+
                        '   // corp metoda\n'+
                        '}'}
                    </SyntaxHighlighter>

                    Signatura metodei = <b>nume metoda([lista de parametrii])</b>.

                    <br/>
                    <br/>

                    Modificatori de acces:
                    <ul>
                        <li><b>private</b>: metoda poate fi apeleata (este vizibila) doar din interiorul clasei unde a fost declarata</li>
                        <li><i>(default)</i>: metoda poate fi apeleata doar de clase din acelasi pachet cu metoda</li>
                        <li><b>protected</b>: metoda poate fi apelata doar de clase din acelasi pachet cu metoda si subclase </li>
                        <li><b>public</b>: metoda poate fi apelata de orice clasa</li>
                    </ul>

                    Specificatori:
                    <ul>
                        <li><b>static</b>: folosit pentru a indica ca metoda este o metoda statica/de clasa</li>
                        <li><b>abstract</b>: folosit pentru a indica ca metoda este abstracta</li>
                        <li><b>final</b>: folosit pentru a indica ca metoda sa nu mai poatea fi supracrisa de o subclasa</li>
                        <li><b>synchronized</b>: folosit in cod cu mai multe fire de executie</li>
                        <li><b>native</b>: folosit cand codul interactioneaza cu cod scris in alt limbaj</li>
                        <li><b>strictfp</b>: folosit pentru a face caculele floating-point portabile</li>
                    </ul>

                    Numele metodei:
                    <ul>
                        <li>poate contine: litere, numere, $, _</li>
                        <li>nu poate fi un cuvant cheie</li>
                        <li>nu poate incepe cu un numar</li>
                        <li>_ este un nume invalid </li>
                    </ul>

                    <hr/>
                    <b>2. Parametri variabili</b>
                    <br/>
                    <br/>

                    Parametri variabili / varargs (...):
                    <ul>
                        <li>trebuie sa fie ultimul parametru din lista de parametrii ai metodei</li>
                        <li>poate exista un sigur parametru variabil (si asta trebuie sa fie ultimul)

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'void metoda1(int... numere )'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'void metoda2(int x, int... numere )'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            o metoda cu parametrii varibili poate fi apelata trimitand (efectul fiind acelasi):
                            <ul>
                                <li>
                                    array:
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'metoda1(new int[]{1,2,3}) // numere va fi un array cu 3 elemente'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    elementele unui array:
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'metoda1(1,2,3) // numere va fi un array cu 3 elemente'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Modificator de access: protected</b>
                    <br/>
                    <br/>

                    Reguli de access la un membru protected:
                    <ul>
                        <li>este permis, daca sunt in acelasi pachet</li>
                        <li>este permis, daca se acceseaza dintr-o subclasa sau clasa insasi</li>
                        <li>este permis, daca avem subclasa B (sau o subclasa a lui B) si o variabila de tipul B (sau de tipul unei subclase a lui B) in clasa B;
                            <br/>
                            altfel spus, daca avem subclasa B, si in interior clasei B avem o variabila sau un atribut de tipul B, atunci aceasta variabila poate accesa membri protected.
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.packt1;\n' +
                        '\n' +
                        'public class Base {\n' +
                        '    protected int x = 1;\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.packt2;\n' +
                        '\n' +
                        'import ro.letyournailsgrow.jvanilla.packt1.Base;\n' +
                        '\n' +
                        'public class Child extends Base {\n' +
                        '\n' +
                        '    private Child y;\n' +
                        '\n' +
                        '    protected void showX(){\n' +
                        '\n' +
                        '        System.out.println(x); // OK\n' +
                        '\n' +
                        '        System.out.println(y.x); // OK\n' +
                        '\n' +
                        '        Child child = new Child();\n' +
                        '        System.out.println(child.x); // OK\n' +
                        '\n' +
                        '        ChildOfChild childOfChild = new ChildOfChild();\n' +
                        '        System.out.println(childOfChild.x); // OK\n' +
                        '\n' +
                        '        ChildTwo childTwo = new ChildTwo();\n' +
                        '        //System.out.println(childTwo.x); // ERROR\n' +
                        '\n' +
                        '        Base base = new Base();\n' +
                        '        //System.out.println(base.x); // ERROR\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.packt2;\n' +
                        '\n' +
                        'import ro.letyournailsgrow.jvanilla.packt1.Base;\n' +
                        '\n' +
                        'public class ChildTwo extends Base {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.packt2;\n' +
                        '\n' +
                        'import ro.letyournailsgrow.jvanilla.packt1.Base;\n' +
                        '\n' +
                        'public class ChildOfChild extends Child {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Specificator: static</b>
                    <br/>
                    <br/>

                    Un camp sau o metoda statica este share-uita de toate clasele si instantele.
                    Accesarea se face prin intermediul:
                    <ul>
                        <li>numelui de clasa ( <i>exemplu</i>: Cer.nori)</li>
                        <li>unei instante ( <i>exemplu</i>: new Cer().nori)</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.statictest;\n' +
                        '\n' +
                        'public class Cer {\n' +
                        '\n' +
                        '    public static int nori = 0;\n' +
                        '\n' +
                        '    public static void main(String []args){\n' +
                        '\n' +
                        '        System.out.println(Cer.nori); // 0\n' +
                        '\n' +
                        '        Cer cer = new Cer();\n' +
                        '        System.out.println(cer.nori); // 0\n' +
                        '\n' +
                        '        cer = null;\n' +
                        '\n' +
                        '        System.out.println(cer.nori); // 0, CIUDAT, dar merge!\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <b>Observatii:</b>
                    <br/>
                    <ul>
                        <li>un membru static poate accesa doar membri statici sau prin intermediul unei instante</li>
                        <li>un membru nestatic poate accesa si membri statici si membri nestatici</li>
                    </ul>

                    <hr/>
                    <b>5. Initializare statica</b>
                    <br/>
                    <br/>

                    Initializare statica se refera la initilizarea intr-un bloc static.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.statictest;\n' +
                        '\n' +
                        'public class InitStatic {\n' +
                        '    \n' +
                        '    private static final int x;\n' +
                        '    \n' +
                        '    static {\n' +
                        '        x = 1;\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Eroare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.statictest;\n' +
                        '\n' +
                        'public class InitStatic {\n' +
                        '    \n' +
                        '    public static final int x=2;\n' +
                        '    \n' +
                        '    static {\n' +
                        '        x = 1; // x fiind final si initializat mai sus, nu se poate initializa inca o data\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    <ul>
                        <li>de evitat initializarile in blocuri statice</li>
                    </ul>

                    <hr/>
                    <b>6. Importul static</b>
                    <br/>
                    <br/>

                    Importul static se refera la importul membrilor statici a unei clase.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.statictest;\n' +
                        '\n' +
                        'import static ro.letyournailsgrow.jvanilla.statictest.InitStatic.*;\n' +
                        'import static ro.letyournailsgrow.jvanilla.statictest.InitStatic.x;\n' +
                        '\n' +
                        'public class ImportStatic {\n' +
                        '\n' +
                        '    public static void main(String arg[]){\n' +
                        '        System.out.println(x);\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Eroare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.statictest;\n' +
                        '\n' +
                        'import static java.util.Arrays.asList;\n' +
                        '\n' +
                        'public class ImportStatic2 {\n' +
                        '\n' +
                        '    public static void main(String arg[]){\n' +
                        '        asList(new int[]{1,2,3}); // E OK\n' +
                        '       // Arrays.asList(new int[]{1,2,3}); // EROARE, pentru ca nu-i importata clasa java.util.Arrays\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Trimitere parametrii prin valoare</b>
                    <br/>
                    <br/>

                    Java trimite parametrii prin valoare <b>(pass-by-value)</b>.

                    <hr/>
                    <b>8. Supraincarcare metode</b>
                    <br/>
                    <br/>

                    Superincercarea (<b>overloading</b>) se refera la posibilitatea de a avea metode cu acelasi nume, dar cu lista de parametrii diferita (acelasi nume, dar signatura diferita).

                    Ordinea in care Java alege metodele supraincercate:
                    <ul>
                        <li>potrivire de tip ( <i>exemplu</i>: void metoda(int i, int j) )</li>
                        <li>primitive mai largi ( <i>exemplu</i>: void metoda(long i, long j) )</li>
                        <li>autobox ( <i>exemplu</i>: void metoda(Integer i, Integer j) )</li>
                        <li>varargs ( <i>exemplu</i>: void metoda(int... nr) )</li>
                    </ul>

                    Eroare de compilare 1:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'void metoda(List<String> list){..}\n' +
                        'void metoda(List<Integer> list){..}'}
                    </SyntaxHighlighter>
                    Explicatie : tipul la generice este folosit doar la compilare, apoi este sters; deci se ajunge la:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'void metoda(List list){..}\n' +
                        'void metoda(List list){..}'}
                    </SyntaxHighlighter>

                    Eroare de compilare 2:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'void metoda(int[] lista){..}\n' +
                        'void metoda(int... lista){..}'}
                    </SyntaxHighlighter>
                    Explicatie: varargs este tratat ca un array (nu sunt permise doua metode cu acelasi signatura)
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MethodsJavaContent;