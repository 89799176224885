import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"gradle", url:"#", title:"Gradle", subtitle:""},

        {id:"gradle-intro", url:"/gradle/intro", title:"Introducere", subtitle:"", parent:"gradle"},
        {id:"gradle-lifecycle", url:"/gradle/lifecycle", title:"Ciclul de viata", subtitle:"", parent:"gradle"},
        {id:"gradle-properties-file", url:"/gradle/properties-file", title:"Fisiere de proprietati", subtitle:"", parent:"gradle"},
        {id:"gradle-projects", url:"/gradle/projects", title:"Proiecte", subtitle:"", parent:"gradle"},
        {id:"gradle-tasks", url:"/gradle/tasks", title:"Task-uri", subtitle:"", parent:"gradle"},
        {id:"gradle-plugins", url:"/gradle/plugins", title:"Plugin-uri", subtitle:"", parent:"gradle"},
        {id:"gradle-java-plugin", url:"/gradle/java-plugin", title:"Plugin-ul java", subtitle:"", parent:"gradle"},
        {id:"gradle-java-application-plugin", url:"/gradle/java-application-plugin", title:"Plugin-ul application", subtitle:"", parent:"gradle"},
        {id:"gradle-war-plugin", url:"/gradle/war-plugin", title:"Plugin-ul war", subtitle:"", parent:"gradle"},
        {id:"gradle-custom-plugin", url:"/gradle/custom-plugin", title:"Creare Plugin de la 0", subtitle:"", parent:"gradle"},
        {id:"gradle-dependency-management", url:"/gradle/dependency-management", title:"Gestionarea dependentelor", subtitle:"", parent:"gradle"},
        {id:"gradle-multi-projects", url:"/gradle/multi-projects", title:"Multi proiecte", subtitle:"", parent:"gradle"},
        {id:"gradle-wrapper", url:"/gradle/wrapper", title:"Gradle Wrapper", subtitle:"", parent:"gradle"},
        {id:"gradle-daemon", url:"/gradle/daemon", title:"Gradle Daemon", subtitle:"", parent:"gradle"},
        {id:"gradle-gradle-init", url:"/gradle/gradle-init", title:"Generare aplicatie Java", subtitle:"", parent:"gradle"},
        {id:"gradle-groovy", url:"/gradle/groovy", title:"Groovy", subtitle:"", parent:"gradle"},
        {id:"gradle-project-object", url:"/gradle/project-object", title:"Obiectul de tip Project (demistificare)", subtitle:"", parent:"gradle"},
        {id:"gradle-other-plugins", url:"/gradle/other-plugins", title:"Alte plugin-uri utile", subtitle:"", parent:"gradle"},
        {id:"gradle-spring-boot", url:"/gradle/spring-boot", title:"Plugin-ul de Spring Boot", subtitle:"", parent:"gradle"},
        {id:"gradle-jenkins", url:"/gradle/jenkins", title:"Integrare cu Jenkins", subtitle:"", parent:"gradle"}
    ]

    static indexUrl = "/gradle/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Gradle
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;