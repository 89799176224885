import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NetworkingK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-networking", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Networking</b>
                    <br/>
                    <br/>

                    Comunicarea intre containere/pod-uri se face prin intermediul obiectelor de tip <b>Service</b>.
                    <br/>
                    Tipuri de servicii:
                    <ul>
                        <li>ClusterIP: expunere doar in interiorul cluster-ului</li>
                        <li>NodePort: expunere si in afara cluster-ului</li>
                        <li>LoadBalancer: expunere si in afara cluster-ului; conteaza la scalare</li>
                    </ul>

                    <hr/>

                    <b>Container - Container (acelasi Pod)</b>
                    <br/>
                    <br/>

                    Containerele care sunt in <i>acelasi pod</i> pot comunica intre ele folosind ca nume de domeniu: <b>localhost</b> (e de ajuns: ClusterIP)

                    <hr/>
                    <b>Container - Container (Pod-uri diferite)</b>
                    <br/>
                    <br/>

                    Daca containere sunt in <i>pod-uri diferite</i> aceste pot comunica intre ele, folosind ca nume de domeniu:
                    <ul>
                        <li>
                            Cluster-IP, (ex: 10.98.103.111) se obtine cu comanda: <b>kubectl get services</b>
                        </li>
                        <li>
                            Kubernate genereaza in mod automat variabile de mediu pe baza numelui de serviciu.
                            <br/>
                            de exemplu daca avem, ca nume de serviciu <i>auth-service</i> va genera: <i>AUTH_SERVICE</i>_<b>SERVICE_HOST</b>.
                            <br/>
                            Si <i>AUTH_SERVICE</i>_<b>SERVICE_HOST</b> va retine adresa IP.
                        </li>
                        <li>
                            numele de serviciu + "." + namespaces (pentru a lista toate namespace-urile: <b>kubectl get namespaces</b>)
                            <br/>
                            Containerele care nu sunt in <i>acelasi pod</i> pot comunica intre ele folosind ca nume de domeniu: <b>[numele de serviciu].[namespace]</b>
                            <br/>
                            de exemplu daca avem, ca nume de serviciu <i>auth-service</i> si e in namespace-ul <i>default</i>, putem folosi ca nume de domeniu: <i>auth-service.default</i>
                        </li>
                    </ul>
                    (e de ajuns: ClusterIP)
                    <br/>
                    <br/>
                    Containerele care nu sunt in <i>acelasi pod</i> pot comunica intre ele folosind ca nume de domeniu: <b>numele de serviciu</b> (similar cu Docker Compose)

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NetworkingK8sContent;