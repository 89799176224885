import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class EmailConfigPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-email-config", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Pasi de urmat:
                    <ul>
                        <li>1. Control Panel {">"} System {">"} Server Administration {"->"} tab <b>Email</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/e_2.png'}/>
                            </div>

                        </li>
                        <li>2. Setari configurare:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'Incoming POP Server: pop.gmail.com (default: localhost)\n' +
                                'Incoming Port: 995 (default: 110)\n' +
                                'Use a Secure Network Connection: yes (default: debifat)\n' +
                                'User Name: ibedaria13@gmail.com\n' +
                                'Password: IBeDARIA13!\n' +
                                'Outgoing SMTP Server: smtp.gmail.com\n' +
                                'Outgoing Port: 465 (default: 25)\n' +
                                'Use a Secure Network Connection: yes (default: debidat)\n' +
                                'User Name: ibedaria13@gmail.com\n' +
                                'Password: IBeDARIA13'}
                            </SyntaxHighlighter>
                            In sectiunea "Manually specify additional JavaMail properties to override the above configuration":
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'mail.smtp.socketFactory.port: 465\n' +
                                'mail.smtp.socketFactory.class:javax.net.ssl.SSLSocketFactory\n' +
                                'mail.smtp.socketFactory.fallback:false\n' +
                                'mail.smtp.auth: true'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            3. Configurare adresa gmail (contact in contul de gmail):
                            <ul>
                                <li><a href={"https://accounts.google.com/b/0/DisplayUnlockCaptcha"} target={"_blank"}>Activare DisplayUnlockCaptcha</a>

                                    <div style={{padding:10}}>
                                        <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                                              src={process.env.PUBLIC_URL + '/img/liferay/liferay-email-3.png'}/>
                                    </div>

                                </li>
                                <li><a href={"https://myaccount.google.com/u/1/security"} target={"_blank"}>Activare AllowLessSecureApps / Allow less secure apps</a>
                                    <div style={{padding:10}}>
                                        <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                                              src={process.env.PUBLIC_URL + '/img/liferay/liferay-email-2.png'}/>
                                    </div>
                                </li>
                                <li><a href={"https://myaccount.google.com/u/1/security"} target={"_blank"}> Dezactivare TwoStepVerification / 2 Steps Verification</a>

                                    <div style={{padding:10}}>
                                        <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                                              src={process.env.PUBLIC_URL + '/img/liferay/liferay-email.png'}/>
                                    </div>

                                </li>
                            </ul>

                        </li>
                        <li>4. Testare:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'MailMessage mailMessage = new MailMessage(sender, receiver,mailSubject, mailBody, true);\n' +
                                'MailServiceUtil.sendEmail(mailMessage);'}
                            </SyntaxHighlighter>

                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'MailMessage mailMessage = new MailMessage(\n' +
                                '                new InternetAddress("ibedaria13@gmail.com") ,\n' +
                                '                new InternetAddress("ibedaria13@gmail.com"),\n' +
                                '                "test", "test email", true);\n' +
                                'MailServiceUtil.sendEmail(mailMessage);'}
                            </SyntaxHighlighter>

                            In fisierul <b>build.gradle</b>, trebuie sa existe dependinta catre <b>javax.mail</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'dependencies {\n' +
                                '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                                '\n' +
                                '\tcompile \'javax.mail:mail:1.4\'\n' +
                                '\n' +
                                '\tcssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.0.2"\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public void sendEmail(String emailFromAddress, String emailFromName, String recipientAddress, String recipientName,\n' +
                            '                      String subject, String body) {\n' +
                            '    try {\n' +
                            '        MailMessage mailMessage = new MailMessage(\n' +
                            '                new InternetAddress(emailFromAddress, emailFromName),\n' +
                            '                new InternetAddress(recipientAddress, recipientName),\n' +
                            '                subject,\n' +
                            '                body,\n' +
                            '                true\n' +
                            '        );\n' +
                            '        mailService.sendEmail(mailMessage);\n' +
                            '    } catch (UnsupportedEncodingException e) {\n' +
                            '        _log.error("Failed to send the email to address: " + emailFromAddress, e);\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            '@Reference\n' +
                            'private MailService mailService;'}
                    </SyntaxHighlighter>

                    <hr/>
                    In <b>portal-setup-wizard.properties</b> urmatoarele proprietati:
                    <SyntaxHighlighter>
                        {'admin.email.from.address=admin@liferay.com\n' +
                            'admin.email.from.name=admin admin'}
                    </SyntaxHighlighter>
                    Pot fi suprascrise din <b>Control Panel</b> ~ <b>Instance Settings</b> ~ <b>Email</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/e_1.png'}/>
                    </div>

                    Tab <b>Email Sender</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/e_3.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"http://liferayplanet.blogspot.com/2020/01/gmail-smtp-configuration-in-liferay-72.html"}>
                                    Gmail SMTP configuration in Liferay 7.2
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EmailConfigPortletContent;