import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TkInterPythonGUIContent extends BaseContentPage  {

    constructor(props) {
        super(props, "python-gui-tkinter-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Prima fereastra</b>
                    <br/>
                    <br/>
                    Import TkInter:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter as tk'}
                    </SyntaxHighlighter>

                    Fereastra principală a aplicației este creată prin metoda din modulul <b>tkinter</b> numită <b>Tk()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter\n' +
                        '\n' +
                        'w = tkinter.Tk()'}
                    </SyntaxHighlighter>

                    Pentru a porni controlerul, trebuie să invocați metoda ferestrei principale, numită <b>mainloop()</b>.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter\n' +
                        '\n' +
                        'w = tkinter.Tk()\n' +
                        'w.mainloop()'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:200}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/tkinter-1.png'}/>
                    </div>

                    Inchiderea fereastrei se face fie cu ALT+F4 sau apasand pe butonul [X].

                    <hr/>
                    <b>2. Schimbare titlu</b>
                    <br/>
                    <br/>
                    Schimbarea titlului din bara de titlu se poate face cu ajutoru metodei: <b>title("titlu")</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter\n' +
                        '\n' +
                        'w = tkinter.Tk()\n' +
                        'w.title("Prima fereastra")\n' +
                        'w.mainloop()'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:300}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/tkinter-2.png'}/>
                    </div>

                    <hr/>
                    <b>3. Adaugare buton</b>
                    <br/>
                    <br/>
                    Adaugarea unui buton se face folosind clasa <b>Button</b>, care primeste ca parametrii:
                    <ul>
                        <li>un obiect care reprezinta fereastra pe care se intentioneaza sa se puna butonul</li>
                        <li>o eticheta /text pentru a descrie butonul</li>
                    </ul>
                    In plus, trebuie specificat si unde se pune butonul in fereastra. Acest lucru se face folosind metoda <b>place()</b> prin intemediul caruia se specifica coordonatele unde se va spune widget-ul.
                    <br/>
                    Coordonata (0,0) inseamna coltul stanga-sus al ferestrei.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter\n' +
                        '\n' +
                        'w = tkinter.Tk()\n' +
                        'w.title("Prima fereastra")\n' +
                        'button = tkinter.Button(w, text="Apasa!")\n' +
                        'button.place(x=15, y=15)\n' +
                        'w.mainloop()'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:300}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/tkinter-3.png'}/>
                    </div>

                    <hr/>
                    <b>4. Gestiune eveniment</b>
                    <br/>
                    <br/>
                    Un <b>handler de evenimente</b> este o bucată de cod responsabilă cu răspunsul la un eveniment pe un widget.
                    De fapt, e o simpla functie. Dar, invocarea propriului handler este strict interzisă, deoarece poate deruta controlerul de evenimente.
                    Aceasta functie este invocata trebuie invocata doar de <b>controller</b>.
                    <br/>

                    O funcție concepută pentru a fi invocată de altceva este adesea numită <b>callback</b>. Un handler este un callback.

                    <br/>
                    <br/>
                    Sa presupunem ca vrem ca atunci se apasa pe butonul din fereastra, fereastra se va inchide (distruge).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter\n' +
                        '\n' +
                        'def onClick():\n' +
                        '    w.destroy()\n' +
                        '\n' +
                        'w = tkinter.Tk()\n' +
                        'w.title("Prima fereastra")\n' +
                        'button = tkinter.Button(w, text="Apasa!", command=onClick) # cand se apasa pe buton se apeleaza metoda onClick() \n' +
                        'button.place(x=15, y=15)\n' +
                        'w.mainloop()\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Ferestre dialog (MessageBox)</b>
                    <br/>
                    <br/>

                    Exista un modul numit <b>messagebox</b>. Messagebox este capabil să creeze ferestre de dialog, fereastre modale, destinate:
                    <ul>
                        <li>să pună întrebări:
                                <br/>
                                <b>askquestion(titlu, intrebare)</b>
                                <br/>
                                returnaza string-ul <i>yes</i>, daca s-a apasat pe butonul de confirmare
                        </li>
                        <li>să afișeze mesaje</li>
                        <li>să primească răspunsul unui utilizator</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import tkinter\n' +
                        'from tkinter import messagebox\n' +
                        '\n' +
                        'def onClick():\n' +
                        '    raspuns = messagebox.askquestion("Intrebare","Sigur vrei sa inchizi aplicatia?")\n' +
                        '    if raspuns == \'yes\':\n' +
                        '        w.destroy()\n' +
                        '\n' +
                        'w = tkinter.Tk()\n' +
                        'w.title("Prima fereastra")\n' +
                        'button = tkinter.Button(w, text="Apasa!", command=onClick)\n' +
                        'button.place(x=15, y=15)\n' +
                        'w.mainloop()\n'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:300}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/tkinter-4.png'}/>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                                <a href={"https://edube.org/"}>PCPP1 | Introduction to GUI Programming in Python: TkInter (Professional 3/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TkInterPythonGUIContent;