import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SpringMVCContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-mvc", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Controller</b>
                    <br/>
                    <br/>
                    Spring MVC raspunde la cererile venite de la client si raspunde prin intermediul unor clase de tip Controller.
                    <br/>

                    O clasa se tip Controller este adnotata cu <b>@Controller</b>.
                    Este adnotare <i>stereotipa</i>, deci se creeaza un bean si se adauga in context la initializarea aplicatiei.

                    <br/>
                    Modul in care gestineaza Spring MVC legatura dintre url-ul resursei si logica care resurneaza resursa (fisier html, json, xml, etc),
                    se realizeaza prin intermediul adnotarii <b>@RequestMapping(<i>url</i>)</b>.
                    <br/>
                    Implicit, metoda care gestioneaza returneaza o resursa statica trebuie sa returneze nume fisierului (exemplu: index.html), dar acest comportament se poate schimbat.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.boot.mvc.controller;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Controller;\n' +
                        'import org.springframework.web.bind.annotation.RequestMapping;\n' +
                        '\n' +
                        '@Controller\n' +
                        'public class IndexController {\n' +
                        '\n' +
                        '    @RequestMapping("/index")\n' +
                        '    public String index(){\n' +
                        '        return "index.html";\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Resurse statice</b>
                    <br/>
                    <br/>

                    Fisierul <b>index.html</b> (referit in clasa de tip Controller) trebuie sa existe in <b>resources</b>/<b>static</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<html>\n' +
                        '    <body>\n' +
                        '        <h1>Salut!</h1>\n' +
                        '    </body>\n' +
                        '</html>'}
                    </SyntaxHighlighter>

                    In consola va exista urmatoarea informatie:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'Adding welcome page: class path resource [static/index.html]'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Resurse dinamice</b>
                    <br/>
                    <br/>

                    O resursa dinamica e construita de obicei pe baza unui sablon (template) de catre un <b>template engine</b>. Un exemplu de template engine este <b>Thymeleaf</b>.
                    Pentru a folosi <b>Thymeleaf</b> este nevoie de a adauga in <b>pom.xml</b> urmatorul initiator de dependenta:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<dependency>\n' +
                        '   <groupId>org.springframework.boot</groupId>\n' +
                        '   <artifactId>spring-boot-starter-thymeleaf</artifactId>\n' +
                        '</dependency>'}
                    </SyntaxHighlighter>

                    Modificam controller-ul, adaugand parametrul <i>model</i> de tip <b>Model</b>.
                    Acest obiect stocheaza <i>datele</i> pe care vrem sa le trimitem catre <b>view</b>.
                    Pentru a adauga o <i>valoare</i> se foloseste metoda <b>addAtrribute(cheie, valoare)</b>, unde <i>cheie</i> este un nume pentru identificarea valorii.

                    <SyntaxHighlighter language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.boot.mvc.thymeleaf.controller;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Controller;\n' +
                        'import org.springframework.ui.Model;\n' +
                        'import org.springframework.web.bind.annotation.RequestMapping;\n' +
                        '\n' +
                        '@Controller\n' +
                        'public class IndexController {\n' +
                        '\n' +
                        '    @RequestMapping("/index")\n' +
                        '    public String index(Model model){\n' +
                        '        model.addAttribute("nume", "KJ");\n' +
                        '        return "index.html";\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    La pornirea aplicatiei, in consola va exista urmatoarea informatie:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'Adding welcome page template: index'}
                    </SyntaxHighlighter>

                    Resursele dinamice (templatele) vor fi puse in directorul <b>resources</b>/<b>templates</b>.
                    <br/>
                    De exemplu, fisierul <i>index.html</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                        '<html lang="en" xmlns:th="http://www.thymeleaf.org">\n' +
                        '    <body>\n' +
                        '    <h1>Salut, <span th:text="${nume}"></span>!</h1>\n' +
                        '    </body>\n' +
                        '</html>'}
                    </SyntaxHighlighter>

                    Atributul <b>xmlns</b> cu valoarea <b>http://www.thymeleaf.org</b> este echivalent cu import-ul din Java.
                    <br/>
                    In plus, se poate accesa valoarea unui atribut trimis din controller (si care se gaseste in model):

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'${atribut}'}
                    </SyntaxHighlighter>

                    ca in exemplul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<span th:text="${nume}">'}
                    </SyntaxHighlighter>

                    In Thymeleaf, iterarea unei colectii se poate face in urmaoturul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<tr th:each="item: ${colectie}">\n' +
                        '   <td th:text="${item}">\n' +
                        '   </td>\n'+
                        '</tr>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Fluxul unei cereri HTML</b>
                    <br/>
                    <br/>

                    Fluxul unei cereri HTML:
                    <ul>
                        <li>clientul face o cerere HTML</li>
                        <li>cererea ajunge in <b>Tomcat</b>; Tomcat apeleaza o <b>componenta servlet</b>; pentru Spring MVC, Tomcat apeleaza un servlet configurat de Spring <b>dispatch servlet</b>;

                            responsabilitea acestuia este de a gestiona cererea in interiorul aplicatiei Spring:
                            <ul>
                                <li>
                                    de a gasi ce controller trebuie apelat pentru cererea facuta, pentru asta:
                                    <br/>
                                    deleaga aceasta responsabilitea unei componente numite <b>handler mapping</b>, care cauta <i>actiunea din controller</i> asociata cu cererea, folosind adnotarea <b>@RequestMapping</b>;
                                    <br/>
                                    si daca o gaseste:
                                    <ul>
                                        <li>
                                            <i>dispatch servlet</i> apeleaza <i>actiunea din controller</i>
                                        </li>
                                        <li>
                                            <i>actiunea din controller</i> returneaza la <i>dispatch servlet</i> numele paginii (<b>view-ul</b>) <i>ce trebuie randat</i> pentru raspuns catre client
                                        </li>

                                        <li>
                                            <i>dispatch servlet</i> returneaza <i>view-ul randat</i> in raspunsul HTTP;
                                            <br/>
                                            pentru asta <i>dispatch servlet</i> deleaga responsabilitatea unei componente enumite <b>view resolver</b>
                                        </li>
                                    </ul>

                                    si daca nu o gaseste, clientul va primi un raspuns cu 404
                                </li>
                            </ul>
                            acest servlet mai este numit si <i>front controller</i>
                        </li>

                    </ul>

                    <hr/>
                    <b>5. Trimitere date catre view</b>
                    <br/>
                    <br/>

                    Pentru a trimite date <b>view</b> se foloseste clasa <b>Model</b>. Un obiect de tip <b>Model</b> stocheaza <i>datele</i> pe care vrem sa le trimitem catre <b>view</b>.
                    Pentru a adauga o <i>valoare</i> se foloseste metoda <b>addAtrribute(cheie, valoare)</b>, unde <i>cheie</i> este un nume pentru identificarea valorii.

                    <hr/>
                    <b>6. Preluare parametrii din request</b>
                    <br/>
                    <br/>

                    Clientul poate trimite parametrii in mai multe feluri:
                    <ul>
                        <li>in parametrii cererii HTTP (<b>query</b>):
                            <br/>
                            exemplu: http://site/cauta?carte=Spring&an=2021
                            <br/>
                            din Spring, se foloseste adnotarea: <b>@RequestParam()</b>:
                            <ul>
                                <li>pentru a face un parametru <b>optional</b>, se foloseste atributul <b>required = false</b></li>
                                <li>pentru a oferi o valoare implicita, se foloseste atributul <b>defaultValue = </b>"valoare_default"</li>
                                <li>numele parametrului din cerere trebuie sa coincida cu numele parametrului din metoda</li>
                            </ul>


                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.boot.mvc.thymeleaf.controller;\n' +
                                '\n' +
                                'import org.springframework.stereotype.Controller;\n' +
                                'import org.springframework.ui.Model;\n' +
                                'import org.springframework.web.bind.annotation.RequestMapping;\n' +
                                'import org.springframework.web.bind.annotation.RequestParam;\n' +
                                '\n' +
                                '@Controller\n' +
                                'public class IndexController {\n' +
                                '\n' +
                                '    @RequestMapping("/index")\n' +
                                '    public String index(@RequestParam(required = false, defaultValue = "KJ") String nume, Model model){\n' +
                                '        model.addAttribute("nume", nume);\n' +
                                '        return "index.html";\n' +
                                '    }\n' +
                                '\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            in calea cererii HTTP (<b>path</b>):
                            <br/>
                            exemplu: http://site/cauta/Spring/2021
                            <br/>
                            din Spring, se foloseste adnotarea: <b>@PathVariable</b>:
                            <ul>
                                <li>pentru a defini o variabila de cale, trebuie asignat un nume intre acolade in calea data in <b>@RequestMapping</b></li>
                                <li>pentru a specifica ce variabila de cale sa fie asociat cu un parametru de parametru se foloseste adnotarea <b>@PathVariable(value = "nume_variabila_cale")</b></li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@RequestMapping("/index/{nume}")\n' +
                                'public String index(@PathVariable(value = "nume") String nume, Model model){\n' +
                                '    model.addAttribute("nume", nume);\n' +
                                '    return "index.html";\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            in antentul cererii HTTP (<b>header</b>):
                        </li>
                        <li>
                            in corpul cerereii HTTP (<b>body</b>):
                        </li>
                    </ul>

                    Rezumand, preluarea parametrilor se poate face cu adnotarea:
                    <ul>
                        <li><b>@RequestParam</b> Tip <b>nume</b> (pentru parametrii de tip query: http://site?nume=valoare)</li>
                        <li><b>@PathVariable(value="nume")</b> + <b>@RequestMapping(/&#123;nume&#125;)</b> (pentru parametrii dati prin variabile de cale: http://site/nume)</li>
                    </ul>

                    <b>Mapare automata, fara nici o adnotare</b>
                    <br/>
                    <br/>

                    O clasa <b>model</b> se poate folosi ca parametru a unei metode/actiuni dintr-un controller.
                    Spring stie sa <b>creeze automat</b> un obiect pe baza parametriilor cereri si sa populeze atributele obiectului model.
                    <br/>
                    Clasa model trebuie sa abia <b>constructorul implicit</b> pentru a permite Spring-ului sa creeze instanta inainte de apelarea metodei de actiune.
                    <br/>
                    <br/>
                    Sa presupunem ca avem urmatorul model:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.boot.mvc.thymeleaf.model;\n' +
                        '\n' +
                        'public class Poet {\n' +
                        '\n' +
                        '    private String nume;\n' +
                        '\n' +
                        '    private String prenume;\n' +
                        '\n' +
                        '    public String getNume() {\n' +
                        '        return nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setNume(String nume) {\n' +
                        '        this.nume = nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public String getPrenume() {\n' +
                        '        return prenume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setPrenume(String prenume) {\n' +
                        '        this.prenume = prenume;\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Atunci, putem scrie asa:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@RequestMapping("/index/poet")\n' +
                        'public String index(Poet poet, Model model) { // mapare automata\n' +
                        '    model.addAttribute("nume", poet.getNume());\n' +
                        '    return "index.html";\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Iar daca facem urmatoarea cerere:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'http://localhost:8080/index/poet?nume=Mihai&prenume=Eminescu'}
                    </SyntaxHighlighter>

                    Vom obtine obiectul <i>poet</i> cu nume='Mihai', iar prenume='Eminescu'.

                    <hr/>
                    <b>7. Metode HTTP</b>
                    <br/>
                    <br/>

                    Metodele HTTP sunt folosite pentru a indica intentia clientului sau ce actiune trebuie efectuata pe o resursa (data):
                    <ul>
                        <li><b>GET</b>: cererea clientului este pentru a primi date</li>
                        <li><b>POST</b>: cererea clientului este de a trimite date si a fi adaugate de server</li>
                        <li><b>PUT</b>: cererea clientului este de schimba date</li>
                        <li><b>PATCH</b>: cererea clientului este de schimba partial date</li>
                        <li><b>DELETE</b>: cererea clientului este de a sterge date</li>
                    </ul>

                    Implicit metoda este <b>GET</b>.
                    <br/>
                    Se poate seta prin intermediul:
                    <ul>
                        <li>
                             atributului <b>method</b> a adnotarii <b>@RequestMapping</b>:

                            <SyntaxHighlighter showLineNumbers={true}  language="java" style={androidstudio}>
                                {'@RequestMapping(value = "/index/{nume}", method = RequestMethod.GET)\n' +
                                'public String index2(@PathVariable(value = "nume") String nume, Model model){\n' +
                                '    model.addAttribute("nume", nume);\n' +
                                '    return "index.html";\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            variantelor simplificate: <b>@GetMapping, @PostMapping, @DeleteMapping, @PutMapping</b>:

                            <SyntaxHighlighter showLineNumbers={true}  language="java" style={androidstudio}>
                                {'@GetMapping("/index/{nume}")\n' +
                                'public String index2(@PathVariable(value = "nume") String nume, Model model){\n' +
                                '    model.addAttribute("nume", nume);\n' +
                                '    return "index.html";\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default SpringMVCContent;