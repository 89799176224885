import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class FiltreVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-filtre", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Filtrele</b> sunt accesori montate pe partea exterioara a obiectivului foto, pentru a:
                    <ul>
                        <li>compesa expunerea (filtru ND)</li>
                        <li>schimba temperatura de culoare (filtru de conversie culoare)</li>
                        <li>schimba distanta minima de focalizare (filtru Close-Up)</li>
                        <li>mari constrastrul (filtru de polarizare liniar)</li>
                        <li>proteja obiectivul (filtru UV)</li>
                        <li>elimina reflexiile (filtru de polarizare)</li>
                    </ul>

                    Tipuri de filtre:
                    <ul>
                        <li>
                            <b>filtru UV</b>
                            <ul>
                                <li>protejeaza obiecticul de zgarieturi</li>
                                <li>protejeaza senzorul de razele UV</li>
                            </ul>
                        </li>
                        <li>
                            <b>filtru ND (neutral density)</b>
                            <ul>
                                <li>compenseaza expunerea, putand scadea cu 1 - 15 stopuri de lumina (de exemplu, e util cand se vrea utilizarea unui obiectiv luminos in lumina puternica sau pentru a face o expunere lunga)</li>
                            </ul>
                            Categorii:
                            <ul>
                                <li>filtre graduale colorate</li>
                                <li>filtre graduale neutre</li>
                            </ul>
                        </li>

                        <li>
                            <b>filtru de polarizare</b>
                            <ul>
                                <li>elimina reflexiile</li>
                            </ul>
                            Categorii:
                            <ul>
                                <li>filtru de polarizare liniara (influenta asupra suprafetelor nemetalice)
                                    <br/>
                                    <ul>
                                        <li>intensifica contrastul</li>
                                        <li>intensifica saturatia culorilor</li>
                                        <li>poate avea <i>efecte asupra sistemului de focalizare</i>, pentru ca modifica nivelul contrastului si saturatiei</li>
                                    </ul>
                                </li>
                                <li>filtre de polarizare circulara (influenta asupra zonelor lucioase cum ar fi apa)</li>
                            </ul>
                        </li>
                        <li>
                            <b>filtru Close-Up</b>
                            <ul>
                                <li>permite micsorarea distantei minime de fotografiere (schimba distanta minima de focalizare)</li>
                                <li>influenteaza profunzimea de camp</li>
                            </ul>
                        </li>
                        <li>
                            <b>filtru de conversie culoare</b>
                            <ul>
                                <li>schimba temperatura de culoare</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FiltreVideografieContent;