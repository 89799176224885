import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SecurityNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-security", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    În mod implicit, securitatea este întotdeauna activată în interiorul depozitului Nuxeo:
                    de fiecare dată când se accesează un document sau se emite o căutare, securitatea este verificată.

                    <br/>
                    <br/>

                    Securitatea depozitului Nuxeo se bazează pe o listă de permisiuni unitare care sunt utilizate în depozit pentru a acorda sau a refuza accesul.
                    <br/>
                    Aceste permisiuni atomice (Read_Children, Write_Properties ...) sunt grupate în <b>Permissions Groups</b> (Read, Edit, Everything ...) pentru ca securitatea să poată fi gestionată mai ușor.

                    <br/>
                    <br/>

                    Principalul model de management al securității se bazează pe un sistem <b>ACL (Access Control List)</b>.
                    <br/>
                    <br/>
                    Fiecare document poate fi asociat cu un <b>ACP (Access Control Policy / Politica de control al accesului)</b>.
                    <br/>
                    Acest ACP este compus dintr-o listă de ACL-uri care ea însăși este compusă din <b>ACE (Access Control Entry)</b>.

                    <br/>
                    <br/>
                    Fiecare ACE este un triplet:
                    <ul>
                        <li>utilizator sau grup</li>
                        <li>permisiune sau grup de permisiuni</li>
                        <li>Grant sau deny</li>
                    </ul>

                    ACP-urile sunt moștenite în mod implicit: verificarea de securitate se va face față de ACP-ul fuzionat din documentul curent și din toate părțile sale.
                    Moștenirea poate fi blocată în orice moment, dacă este necesar.

                    <br/>
                    <br/>

                    Fiecărui document i se pot atribui mai multe ACL-uri (un ACP) pentru a gestiona mai bine separarea preocupărilor între regulile care definesc securitatea:
                    <ul>
                        <li>documentul are un ACL implicit: cel care poate fi gestionat prin interfața de utilizare back-office</li>
                        <li>documentul poate avea mai multe fluxuri de lucru ACL-uri: ACL-uri care sunt setate de fluxuri de lucru, inclusiv documentul</li>
                    </ul>

                    <hr/>

                    O politică de securitate este o clasă care implementează interfata <b>org.nuxeo.ecm.core.security.SecurityPolicy</b>;
                    se recomandă insistent să se extindă <b>org.nuxeo.ecm.core.security.AbstractSecurityPolicy</b> pentru compatibilitate viitoare.

                    <br/>
                    <br/>
                    Clasa trebuie înregistrată prin policiespunctul de extensie al componentei <b>org.nuxeo.ecm.core.security.SecurityService</b>.

                    <br/>
                    <br/>
                    O politică de securitate are două aspecte importante, concretizate prin diferite metode ale interfeței:

                    <ul>
                        <li>cum este verificată securitatea pe un document dat (metoda <b>public Access checkPermission(...)</b>)</li>
                        <li>cum se aplică securitatea căutărilor NXQL, CMISQL și Elasticsearch Passthrough (metode <b>getQueryTransformer()</b>)</li>
                    </ul>

                    Valori posibile pentru <b>Access</b>:
                    <ul>
                        <li>Access.DENY (accesul la document este refuzat)</li>
                        <li>Access.GRANT (accesul la document este acordat imediat)</li>
                        <li>Access.UNKNOWN (alte politici de securitate vor fi verificate)</li>
                    </ul>

                    <hr/>
                    <b>Exemplu:</b>

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<component name="com.example.myproject.securitypolicy">\n' +
                        '  <extension target="org.nuxeo.ecm.core.security.SecurityService"\n' +
                        '    point="policies">\n' +
                        '    <policy name="myPolicy"\n' +
                        '      class="com.example.myproject.NoFileSecurityPolicy" order="0" />\n' +
                        '  </extension>\n' +
                        '</component>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import static org.nuxeo.ecm.core.query.sql.model.Predicates.noteq;\n' +
                        'import static org.nuxeo.ecm.core.query.sql.model.Predicates.and;\n' +
                        '\n' +
                        'import org.nuxeo.ecm.core.api.NuxeoPrincipal;\n' +
                        'import org.nuxeo.ecm.core.api.security.ACP;\n' +
                        'import org.nuxeo.ecm.core.api.security.Access;\n' +
                        'import org.nuxeo.ecm.core.model.Document;\n' +
                        'import org.nuxeo.ecm.core.query.sql.NXQL;\n' +
                        'import org.nuxeo.ecm.core.query.sql.model.Predicate;\n' +
                        'import org.nuxeo.ecm.core.query.sql.model.SQLQuery;\n' +
                        'import org.nuxeo.ecm.core.query.sql.model.WhereClause;\n' +
                        'import org.nuxeo.ecm.core.security.AbstractSecurityPolicy;\n' +
                        'import org.nuxeo.ecm.core.security.SecurityPolicy;\n' +
                        '\n' +
                        '/**\n' +
                        ' * Sample policy that denies access to File objects.\n' +
                        ' */\n' +
                        'public class NoFileSecurityPolicy extends AbstractSecurityPolicy implements SecurityPolicy {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public Access checkPermission(Document doc, ACP mergedAcp,\n' +
                        '                                  NuxeoPrincipal principal, String permission,\n' +
                        '                                  String[] resolvedPermissions, String[] additionalPrincipals) {\n' +
                        '        // Note that doc is NOT a DocumentModel\n' +
                        '        if ("File".equals(doc.getType().getName())) {\n' +
                        '            return Access.DENY;\n' +
                        '        }\n' +
                        '        // if (!"KJ".equals(doc.getValue("documentul_meu:proprietate") {\n' +
                        '        //     return Access.DENY;\n' +
                        '        // }\n' +
                        '        // NuxeoPrincipal p = (NuxeoPrincipal)principal; \n'+
                        '        // p.isMemberOf("KJ_GROUP")'+
                        '        return Access.UNKNOWN;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public boolean isRestrictingPermission(String permission) {\n' +
                        '        // could only restrict Browse permission, or others\n' +
                        '        return true;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public boolean isExpressibleInQuery(String repositoryName) {\n' +
                        '        return true;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public SQLQuery.Transformer getQueryTransformer(String repositoryName) {\n' +
                        '        return NO_FILE_TRANSFORMER;\n' +
                        '    }\n' +
                        '\n' +
                        '    public static final SQLQuery.Transformer NO_FILE_TRANSFORMER = new NoFileTransformer();\n' +
                        '\n' +
                        '    /**\n' +
                        '     * Sample Transformer that adds {@code AND ecm:primaryType <> \'File\'} to the query.\n' +
                        '     */\n' +
                        '    public static class NoFileTransformer implements SQLQuery.Transformer {\n' +
                        '\n' +
                        '        /* {@code ecm:primaryType <> \'File\'} */\n' +
                        '        public static final Predicate NO_FILE = noteq(NXQL.ECM_PRIMARYTYPE, "File");\n' +
                        '\n' +
                        '        @Override\n' +
                        '        public SQLQuery transform(NuxeoPrincipal principal, SQLQuery query) {\n' +
                        '            if (!principal.isAdministrator()) {\n' +
                        '                WhereClause where = query.where;\n' +
                        '                Predicate predicate;\n' +
                        '                if (where == null || where.predicate == null) {\n' +
                        '                    predicate = NO_FILE;\n' +
                        '                } else {\n' +
                        '                    // adds an AND ecm:primaryType <> \'File\' to the WHERE clause\n' +
                        '                    predicate = and(NO_FILE, where.predicate);\n' +
                        '                }\n' +
                        '                // return query with updated WHERE clause\n' +
                        '                return new SQLQuery(query.select, query.from, new WhereClause(predicate),\n' +
                        '                        query.groupBy, query.having, query.orderBy, query.limit, query.offset);\n' +
                        '            }\n' +
                        '            return query;\n' +
                        '        }\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://doc.nuxeo.com/nxdoc/acls/"}>
                                ACLs
                            </a>
                        </div>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SecurityNuxeoContent;