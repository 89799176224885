import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerfileHealthcheckDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dockerfile-healthcheck", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Dockerfile: HEALTHCHECK</b>

                    <br/>
                    <br/>

                    <b>HEALTHCHECK</b>:  îi spune lui Docker cum să determine dacă starea containerului este normală

                    <br/>
                    Sintaxa arată astfel:
                    <SyntaxHighlighter>
                        {
                            'HEALTHCHECK [options] CMD <command>'
                        }
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter>
                        {'HEALTHCHECK NONE '}
                    </SyntaxHighlighter>
                    ultima forma a instructiunii HEALTHCHECK dezactiveaza orice HEALTHCHECK moștenită din imaginea de bază.

                    Mod de functionare:
                    <br/>
                    Când o instrucțiune HEALTHCHECK este specificată într-o imagine, containerul este pornit cu ea, starea inițială va începe și va deveni ok după ce instrucțiunea HEALTHCHECK este verificată cu succes.
                    Dacă eșuează de un anumit număr de ori, va deveni 'nesănătos'.
                    <br/>
                    <br/>
                    Când un container are o verificare de sănătate specificată, acesta are o  stare de sănătate  în plus față de starea sa normală.
                    Această stare este inițial <i>starting</i>.
                    <br/>
                    Ori de câte ori trece un control de sănătate, acesta devine  <i>healthy</i> (indiferent de starea în care se afla anterior).
                    După un anumit număr de eșecuri consecutive, devine <i>unhealthy</i>.
                    <br/>
                    <br/>
                    Optiuni:
                    <ul>
                        <li>
                            --interval=30s, interval intre doua verificari, implicit este 30 de secunde; deci face verificari din 30 in 30 de secunde;
                        </li>
                        <li>
                            --timeout=30s, comanda de verificare a starii ruleaza pana cand nu expira timpul setat; valoare implicita este 30 de secunde
                        </li>
                        <li>
                            --start-period=0s, perioada de timp data containerul pentru a se initializa;
                            <br/>
                            daca verificarea starii de sanatate nu reuseste in aceea perioada, nu se ia in considerare pentru numarul maxim de incercari;
                            <br/>
                            daca verificarea starii de sanatate reuseste in aceea perioada, containerul este considerat pornit si toate  eșecurile consecutive vor fi luate în considerare pentru numărul maxim de reîncercări
                            {/*<br/>*/}
                            {/*optiunea este adaugate in formatul de fisier 3.4.*/}
                        </li>
                        <li>
                            -retries=3, numarul de erori consecutive;
                            <br/>
                            La fel ca CMD, ENTRYPOINT, HEALTHCHECK poate apărea o singură dată. Dacă sunt scrise mai multe, doar ultima va avea efect
                        </li>
                    </ul>
                    Exemplu:
                    <SyntaxHighlighter>
                        {'FROM nginx:1.13\n' +
                            'HEALTHCHECK --interval=30s --timeout=3s \\\n' +
                            '  CMD curl -f http://localhost/ || exit 1\n' +
                            'EXPOSE 80'}
                    </SyntaxHighlighter>
                    Daca se iese pe ramura de <i>exit 1</i>, atunci starea containerul nu e ok (e nesanatoasa)!

                    <br/>
                    <br/>
                    Valorile posibile de iesire (indica starea de sanatate a containerului):
                    <ul>
                        <li>0: success {"=>"} conatainerul este ok si pregatit sa fie utilizat</li>
                        <li>1: failure {"=>"} containerul nu functioneaza cum trebuie</li>
                        <li>2: reservate {"=>"} a nu se folosi acest code de iesire</li>
                    </ul>

                    Nu poate exista decât o singură  HEALTHCHECK instrucțiune într-un Dockerfile. Dacă enumerați mai multe, atunci numai ultima  HEALTHCHECK va avea efect.

                    <br/>
                    <br/>
                    Verificarea sănătății va rula mai întâi la intervale de secunde specificate după pornirea containerului și apoi din nou  la intervale de  secunde după finalizarea fiecărei verificări anterioare.

                    <br/>
                    <br/>
                    Pentru a ajuta la depanarea probelor eșuate, orice text de ieșire (codificat UTF-8) pe care comanda îl scrie pe stdout sau stderr va fi stocat în starea de sănătate și poate fi interogat cu <b>docker inspect</b>.
                    O astfel de ieșire ar trebui să fie scurtă (în prezent sunt stocați doar primii 4096 de octeți)
                    <hr/>
                    Caracteristica  HEALTHCHECK a fost adăugată în Docker 1.12
                    <hr/>
                    La rularea unei container se poate specifica urmatoarele optiune privind starea de sanatate (HEALTHCHECK):
                    <ul>
                        <li>
                            --health-cmd {"=>"} Comandă pentru a rula pentru a verifica starea de sănătate
                        </li>
                        <li>
                            --health-interval {"=>"} Timp între efectuarea verificării
                        </li>
                        <li>
                            --health-retries {"=>"} Eșecuri consecutive necesare pentru raportarea nesănătoasă
                        </li>
                        <li>
                            --health-timeout {"=>"} Timp maxim pentru a permite executarea unei verificări
                        </li>
                        <li>
                            --health-start-period {"=>"} Perioada de început pentru inițializarea containerului înainte de a începe numărătoarea inversă pentru reîncercări de sănătate
                        </li>
                        <li>
                            --no-healthcheck {"=>"} Dezactivare orice HEALTHCHECK specificat de container
                        </li>
                    </ul>

                    <hr/>
                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {
                            'docker run --name=test -d \\\n' +
                            '    --health-cmd=\'stat /etc/passwd || exit 1\' \\\n' +
                            '    --health-interval=2s \\\n' +
                            '    busybox sleep 1d'
                        }
                    </SyntaxHighlighter>

                    Verificare stare de sanatate:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect --format=\'{{.State.Health.Status}}\' test'}
                    </SyntaxHighlighter>
                    {"=>"} healthy
                    <br/>
                    <br/>
                    Stergere fisier:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec test rm /etc/passwd'}
                    </SyntaxHighlighter>

                    Verificare, din nou, stare de sanatate:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect --format=\'{{.State.Health.Status}}\' test'}
                    </SyntaxHighlighter>
                    {"=>"} unhealthy

                    Verificare, din nou, stare de sanatate, cu mai multe detalii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect --format=\'{{json .State.Health}}\' test'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {
                            'docker run --name=test-curl -d \\\n' +
                            '    --health-cmd=\'curl http://localhost\' \\\n' +
                            '    --health-interval=10s \\\n' +
                            'busybox'
                        }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'78ed81d8c43caa2a520ce6e05984583d768b0d8eb4a55804678450c246ae1777'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE             NAMES                    COMMAND                  CREATED          STATUS                            PORTS                                                                                                  \n' +
                            '0fcb7d5f224b   busybox           test-curl                "sh"                     20 seconds ago   Up 2 seconds (health: starting)                                                                                                          \n' +
                            '                                '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect --format=\'{{.State.Health.Status}}\' test-curl'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'unhealthy'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>

                    Verificare, din nou, stare de sanatate, cu mai multe detalii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect --format=\'{{json .State.Health}}\' test-curl'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'{"Status":"unhealthy","FailingStreak":0,"Log":[]}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerfileHealthcheckDockerContent;