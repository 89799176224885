import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WebScopeIoCSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-ioc", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <i>Inima</i> framework-ului Spring o constituie componenta <b>IoC</b> (Inversion of Constrol / Inversarea Controlului).
                    <br/>
                    <br/>
                    Pentru a construi o aplicatie, Spring se foloseste de <i>componente</i> numite <b>bean</b>-uri (care sunt de fapt instante de clase). Acestea sunt:
                    <ul>
                        <li>create</li>
                        <li>asamblate (adica, se injecteaza dependintele)</li>
                        <li>initializate</li>
                        <li>gestionate</li>
                    </ul>
                    de <b>containerul Spring IoC</b>. Acesta, pe baza configurarilor (scrise in fisiere Java sau fisiere XML), are responsabilitatea de a conecta <i>bean</i>-urile laolalta pentru a construi aplicatia.
                    <br/>
                    Procesul de injectare se intampla la <b>runtime</b> (la pornirea aplicatiei, cand se ridica <i>contextul aplicatiei Spring</i> - <i>containerul Spring IoC</i> creaza contextul aplicatiei Spring).

                    <hr/>
                    <i>Containerul Spring IoC</i> este reprezentat de interfetele:
                    <ul>
                        <li><a href={"https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/beans/factory/BeanFactory.html"}>BeanFactory</a> </li>
                        <li><a href={"https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/context/ApplicationContext.html"}>ApplicationContext</a> </li>
                    </ul>

                    <b>Interfata BeanFactory</b>
                    <br/>
                    Interfata <i>org.springframework.context.</i><b>BeanFactory</b> este interfața rădăcină pentru accesarea containerului Spring. Acesta:
                    <ul>
                        <li>este un registru central al componentelor aplicației (centralizează configurarea componentelor aplicației)</li>
                        <li>oferă funcționalități de bază pentru gestionarea bean-urilor</li>
                    </ul>


                    <b>Interfata ApplicationContext</b>
                    <br/>
                    Interfata <i>org.springframework.context.</i><b>ApplicationContext</b> implementeaza interfata <i>org.springframework.context.</i><b>BeanFactory</b>.
                    Implementarile interfetei <b>ApplicationContext</b> gestioneaza definitiile bean-urilor in mod unic printr-un <i>nume</i>. De asemenea ofera:
                    <ul>
                        <li>acces la bean-uri, folosind metode <i>bean factory</i></li>
                        <li>posibilitatea de a incarca fisiere resursa, folosind cai relative sau absolute sau URL-uri; mostenire din interfata <b>ResourceLoader</b></li>
                        <li>posibilitatea de a publica evenimente (publish events) la ascultatori inregistrati (registered listeners)</li>
                        <li>posibilitatea de a rezolva mesaje si suport pentru internationalizare (i18n)</li>
                    </ul>

                    Un context de aplicatie este creat de containerul Spring IoC si initializat cu o configurare oferita de o <i>resursa</i>:
                    <ul>
                        <li>fisier XML (sau mai multe)</li>
                        <li>clase de configurare (sau mai multe)</li>
                        <li>fisiere XML si clase de configurare</li>
                    </ul>

                    Un obiect de tip <b>ApplicationContext</b> are metoda (mostenita din <i>ResourceLoader</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Resource getResource(String location)'}
                    </SyntaxHighlighter>
                    Aceasta incerca sa returneaza o resursa, tinand cont de prefixul din <i>location</i>:
                    <table>
                        <thead>
                            <tr>
                                <th>prefix</th>
                                <th>exemplu</th>
                                <th>unde cauta</th>
                                <th>tip returnat</th>
                                <th>instanta <b>ApplicationContext</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>fara prefix</td>
                                <td>config.xml</td>
                                <td>directorul radacina unde este executata clasa care creaza contexul (directorul <i>main</i> sau <i>test</i>)</td>
                                <td>depinde de instanta <b>ApplicationContext</b></td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td><b>classpath:</b></td>
                                <td>classpath:config.xml</td>
                                <td>in classpath; directorul <i>resources</i></td>
                                <td><b>ClassPathResource</b></td>
                                <td><b>ClassPathXmlApplicationContext</b></td>
                            </tr>
                            <tr>
                                <td><b>file:</b></td>
                                <td>file:/data/config.xml</td>
                                <td>calea absoluta de dupa prefix; resursa este incarcata ca un URL, de tip UrlResource</td>
                                <td><b>FleSystemResource</b></td>
                                <td><b>FileSystemXmlApplicationContext</b></td>
                            </tr>
                            <tr>
                                <td><b>http:</b></td>
                                <td>http://localhost/config.xml</td>
                                <td>URL-ul de dupa prefix;  resursa este incarcata ca un URL, de tip UrlResource</td>
                                <td><b>ServletContextResource</b></td>
                                <td><b>WebApplicationContext</b></td>
                            </tr>
                        </tbody>
                    </table>


                    <hr/>
                    Inainte de a se putea face configurari folosind limbajul Java ca suport, Spring se folosea de fisiere XML, puse in directorul: <b>src/main/resources</b>
                    <br/>
                    Iar contextul Spring era initializat folosind implementarea <b>ClassPathXmlApplicationContext</b> care citea fisierul XML
                    <br/>

                    <i>Exemplu</i> (presupunem ca fisierul de configurare XML se numea <i>application.xml</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ApplicationContext context = new ClassPathXmlApplicationContext("classpath:spring/application.xml")'}
                    </SyntaxHighlighter>

                    <hr/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.baeldung.com/spring-application-context"}>The Spring ApplicationContext</a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/context/ApplicationContext.html"}>Interface ApplicationContext</a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/beans/factory/BeanFactory.html"}>Interface BeanFactory</a>
                            </div>
                        </li>
                        <li>
                            <di>Pivotal Certified Professional Core Spring 5 Develper Exam - Iuliana Cosmina (Apress, 2020)</di>
                        </li>
                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default WebScopeIoCSpringContent;