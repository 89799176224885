import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FunctionsIsValuesJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-functions-is-values", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    In JavaScript, funcțiile sunt considerate „valori de primă clasă” („first-class citizens”),
                    ceea ce înseamnă că pot fi tratate ca și alte valori, cum ar fi obiectele, numerele sau șirurile de caractere.
                    Ele pot fi stocate în variabile, pot fi transmise ca argumente altor funcții și pot fi returnate de către alte funcții.

                    <br/>
                    <br/>
                    1. Stocarea unei funcții într-o variabilă
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// Definim o funcție\n' +
                            'function sayHello() {\n' +
                            '    console.log("Hello, world!");\n' +
                            '}\n' +
                            '\n' +
                            '// Stocăm funcția într-o variabilă\n' +
                            'let greet = sayHello;\n' +
                            '\n' +
                            '// Apelăm funcția stocată\n' +
                            'greet(); // Output: "Hello, world!"'}
                    </SyntaxHighlighter>

                    2. Transmiterea unei funcții ca argument
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// O funcție care primește o altă funcție ca argument\n' +
                            'function executeFunction(fn) {\n' +
                            '    fn(); // Execută funcția primită ca argument\n' +
                            '}\n' +
                            '\n' +
                            'function sayGoodbye() {\n' +
                            '    console.log("Goodbye, world!");\n' +
                            '}\n' +
                            '\n' +
                            '// Transmitem funcția `sayGoodbye` ca argument\n' +
                            'executeFunction(sayGoodbye); // Output: "Goodbye, world!"'}
                    </SyntaxHighlighter>

                    3. Returnarea unei funcții din altă funcție
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// O funcție care returnează o altă funcție\n' +
                            'function createMultiplier(multiplier) {\n' +
                            '    return function(x) {\n' +
                            '        return x * multiplier;\n' +
                            '    };\n' +
                            '}\n' +
                            '\n' +
                            '// Stocăm funcția returnată într-o variabilă\n' +
                            'let double = createMultiplier(2);\n' +
                            'let triple = createMultiplier(3);\n' +
                            '\n' +
                            'console.log(double(5)); // Output: 10\n' +
                            'console.log(triple(5)); // Output: 15'}
                    </SyntaxHighlighter>

                    4. Funcțiile anonime și expresiile funcționale
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// Definim o funcție anonimă și o stocăm într-o variabilă\n' +
                            'let greet = function() {\n' +
                            '    console.log("Hi there!");\n' +
                            '};\n' +
                            '\n' +
                            '// Apelăm funcția anonimă stocată în variabilă\n' +
                            'greet(); // Output: "Hi there!"'}
                    </SyntaxHighlighter>

                    5. Funcții ca metode ale obiectelor
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// Definim un obiect cu o funcție ca valoare a unui atribut\n' +
                            'let person = {\n' +
                            '    name: "Alice",\n' +
                            '    greet: function() {\n' +
                            '        console.log("Hello, my name is " + this.name);\n' +
                            '    }\n' +
                            '};\n' +
                            '\n' +
                            '// Apelăm funcția (metoda) din obiect\n' +
                            'person.greet(); // Output: "Hello, my name is Alice"'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var whatToSay = {\n' +
                            '    greeting() {\n' +
                            '        console.log("Hello!");\n' +
                            '    },\n' +
                            '    question() {\n' +
                            '        console.log("What\'s your name?");\n' +
                            '    },\n' +
                            '    answer() {\n' +
                            '        console.log("My name is Kyle.");\n' +
                            '    }\n' +
                            '};\n' +
                            '\n' +
                            'whatToSay.greeting();\n' +
                            '// Hello!'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionsIsValuesJavaScriptContent;