import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CalendarWidgetLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-widget-calendar", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Widget-ul <b>Calendar</b> poate fi adaugat din:
                    <br/>
                    <b>Fragments and Widgets {"->"} Widgets {"->"} Collaboration {"->"} Calendar</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/calendar-liferay-1.png'}/>
                    </div>

                    Afisare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/calendar-liferay-2.png'}/>
                    </div>

                    Se pot:
                    <ul>
                        <li>adaugare calendare</li>
                        <li>stergere calendare (care nu sunt marcate ca fiind implicite)</li>
                        <li>ascundere calendar (sectiunea <i>Other Calendars</i>)</li>
                        <li>schimba culoare marcher calendar</li>
                        <li>adaugare evenimente</li>
                        <li>sterge evenimente</li>
                        <li>modificare evenimente</li>
                    </ul>
                    Modalitati de vizualizare calendar:
                    <ul>
                        <li>pe luna (month)</li>
                        <li>pe zi (day)</li>
                        <li>pe saptamana (week)</li>
                        <li>agenda</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CalendarWidgetLiferayContent;