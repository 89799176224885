import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ExceptionsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-modules", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Module</b>
                    <br/> <br/>

                    <b>1. Importul unui modul (cu toate entitatile sale)</b>
                    <br/>
                    Pentru importul unui modul sau a mai multe module se foloseste cuvantul cheie <b>import</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import nume_modul'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import nume_modul_1, nume_modul_2,..., nume_modul_N'}
                    </SyntaxHighlighter>

                    dar, se prefera importul unui modul pe cate o linie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import nume_modul_1\n' +
                        'import nume_modul_2\n' +
                        '...\n' +
                        'import nume_modul_N'}
                    </SyntaxHighlighter>

                    Instrucțiunea de <b>import</b> poate fi scrisa <b>oriunde în codul</b>, dar trebuie <b>înainte de prima utilizare</b> a oricăreia dintre <b>entitățile (variabila, constanta, functie, clasa)</b> modulului.

                    <hr/>

                    Un <b>spațiu de nume (namespace)</b> este un spațiu unde există nume și numele lor nu intră în conflict între ele (adică nu există două obiecte diferite cu același nume).
                    In cadul unui spariu de nume, un nume este <b>unic</b>.

                    <br/> <br/>

                    Un modul este un fișier sursă Python (cu definitii si instructiuni). Dacă modulul cu un nume specificat există și este accesibil, se importă conținutul, adică toate numele definite în modul devin cunoscute, dar nu intră în spațiul de nume al codului tău.
                    Aceasta pentru ca, pentru a accesa o entitate dintr-un modul trebuie specificat si modul, in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume_modul.nume_entitate'}
                    </SyntaxHighlighter>

                    <i>Exemplu:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import math\n' +
                        'print(math.pi) # 3.141592653589793'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Importul unui modul (selectand entitatile dorite - varianta nerecomandata)</b>
                    <br/>
                    Pentru a importa dintr-un modul doar entitatile dorite:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from nume_modul import nume_entiate_1, nume_entitate_2,...,nume_entitate_N'}
                    </SyntaxHighlighter>
                    Accesarea entiatilor se face calificare, adica in mod direct:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume_entiate_1'}
                    </SyntaxHighlighter>

                    <i>Exemplu:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import pi\n' +
                        'print(pi) # 3.141592653589793'}
                    </SyntaxHighlighter>

                    <i>Observatie 1:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import pi\n' +
                        'print(math.pi) # eroare: NameError: name \'math\' is not defined'}
                    </SyntaxHighlighter>

                    <i>Observatie 2</i>:
                    <br/>
                    Entitatile importate înlocuiesc/suprascriu definițiile lor anterioare în spațiul de nume:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'pi=2\n' +
                        'print(pi) # 2\n' +
                        'from math import pi\n' +
                        'print(pi) # 3.141592653589793'}
                    </SyntaxHighlighter>

                    O nou definire inlocuieste o definire dintr-un import:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import pi\n' +
                        'print(pi) # 3.141592653589793\n' +
                        'pi=2\n' +
                        'print(pi) # 2'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Importul unui modul (selectand toate entitatile dorite - varianta nerecomandata)</b>
                    <br/>
                    Pentru a selecta toate entitatile unui modul se poate scrie in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from nume_modul import *'}
                    </SyntaxHighlighter>

                    <b>4. Importul unui modul (folosind un alias)</b>
                    <br/>
                    Pentru folosi un alt nume decat numele modului, se poate folosi un alias. Pentru a face acest lucru se foloseste cuvantul cheie <b>as</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import nume_modul as nume_alias'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from nume_modul import nume_entitate as nume_alias'}
                    </SyntaxHighlighter>

                    <i>Exemplu 1</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import math as M\n' +
                        'print(M.pi) # 3.141592653589793'}
                    </SyntaxHighlighter>
                    Importand folosind aliasuri, numele originale, nu mai sunt accesibile, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import math as M\n' +
                        'print(math.pi) # NameError: name \'math\' is not defined'}
                    </SyntaxHighlighter>

                    <i>Exemplu 2</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import pi as PI\n' +
                        'print(PI) # 3.141592653589793'}
                    </SyntaxHighlighter>
                    Importand folosind aliasuri, numele originale, nu mai sunt accesibile, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import pi as PI\n' +
                        'print(pi) # NameError: name \'pi\' is not defined'}
                    </SyntaxHighlighter>

                    <hr/>
                    Functii utile:
                    <ul>
                        <li>dir(nume_modul) - listeaza numele entitatilor dintr-un modul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import math\n' +
                                'print(dir(math))'}
                            </SyntaxHighlighter>
                            Rezulta:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'[\'__doc__\', \'__file__\', \'__loader__\', \'__name__\', \'__package__\', \'__spec__\', \'acos\', \'acosh\', \'asin\', \'asinh\', \'atan\', \'atan2\', \'atanh\', \'ceil\', \'copysign\', \'cos\', \'cosh\', \'degrees\', \'e\', \'erf\', \'erfc\', \'exp\', \'expm1\', \'fabs\', \'factorial\', \'floor\', \'fmod\', \'frexp\', \'fsum\', \'gamma\', \'gcd\', \'hypot\', \'inf\', \'isclose\', \'isfinite\', \'isinf\', \'isnan\', \'ldexp\', \'lgamma\', \'log\', \'log10\', \'log1p\', \'log2\', \'modf\', \'nan\', \'pi\', \'pow\', \'radians\', \'remainder\', \'sin\', \'sinh\', \'sqrt\', \'tan\', \'tanh\', \'tau\', \'trunc\']'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Metode din modul <b>math</b>:
                    <ul>
                        <li>sin(x): sinus</li>
                        <li>cos(x): cosinus</li>
                        <li>tan(x): tangenta</li>
                        <li>asin(x): arcsinus</li>
                        <li>acos(x): arcosinus</li>
                        <li>atan(x): arctangent</li>
                        <li>radians(x): converteste din grade in radiani</li>
                        <li>grades(x): converteste din radiani in grade</li>
                        <li>pi: constanta pi</li>
                        <li>sinh(x): sinus hiperbolic</li>
                        <li>cosh(x): cosinus hiperbolic</li>
                        <li>tanh(x): tangenta hiperbolic</li>
                        <li>asinh(x): arcsinus hiperbolic</li>
                        <li>acosh(x): arcosinus hiperbolic</li>
                        <li>atanh(x):arctangent hiperbolic</li>
                        <li>e: constanta cu valoare egala aproximativ cu numarul lui Euler</li>
                        <li>exp(x): e^x</li>
                        <li>log(x): logaritm natural</li>
                        <li>log(x,b): logaritm lui x in baza b</li>
                        <li>log10(x) = log(x,10)</li>
                        <li>log2(x) = log(x,2)</li>
                        <li>ceil(x): cel mai mic număr întreg mai mare sau egal cu x
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from math import ceil\n' +
                                '\n' +
                                'print(ceil(1.1)) # 2\n' +
                                'print(ceil(-1.1)) # -1'}
                            </SyntaxHighlighter>
                        </li>
                        <li>floor(x): cel mai mare număr întreg mai mic sau egal cu x
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from math import floor\n' +
                                '\n' +
                                'print(floor(1.1)) # 1\n' +
                                'print(floor(-1.1)) # -2'}
                            </SyntaxHighlighter>
                        </li>
                        <li>trunc(x): trunchiată la un număr întreg
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from math import trunc\n' +
                                '\n' +
                                'print(trunc(1.1)) # 1\n' +
                                'print(trunc(-1.1)) # -1'}
                            </SyntaxHighlighter>
                        </li>
                        <li>factorial(x): caculeaza factorial de x, adica x!</li>
                        <li>hypot(x,y): returnează lungimea ipotenuzei unui triunghi dreptunghic cu lungimea catetelor egale cu x și y (la fel ca sqrt(pow(x, 2) + pow(y, 2)) dar mai precis)</li>
                    </ul>

                    Exemplul 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import pi, radians, degrees, sin, cos, tan, asin, acos\n' +
                        '\n' +
                        'grade = 90\n' +
                        'radian = radians(grade)\n' +
                        'grade = degrees(radian)\n' +
                        '\n' +
                        'print(radian) # 1.5707963267948966\n' +
                        'print(grade) # 90\n' +
                        'print(radian == pi / 2.) # True\n' +
                        'print(sin(radian) / cos(radian) == tan(radian)) # True\n' +
                        'print(acos(cos(radian))==radian) # True'}
                    </SyntaxHighlighter>

                    Exemplul 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from math import e, exp, log\n' +
                        '\n' +
                        'print(exp(0)) # 1.0\n' +
                        'print(pow(2,3)) # 8\n' +
                        'print(log(e,e)) # 1.0'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>pow(x, y) : este functie build-in, x^y</li>
                    </ul>

                    <hr/>
                    Metode din modulul <b>random</b>:
                    <ul>
                        <li>random(): produce un numar aleator in intervalul [0.0, 1.0) </li>
                        <li>seed(): seteaza 'samanta' cu timpul curent</li>
                        <li>seed(valoare): seteaza 'samanta' cu valoare, unde valoare este de tip integer (in acest fel ai un oarecare control al valorilor generate, in sensul ca vor fi aceleasi, in functie de valoarea samantei setate - acceasi samanta, aceleasi valori aleatoare generate)</li>

                        <li>
                            randrange(de_la=0, pana_la, incrementare=1): genereaza un numar intreg intre [de_la, pana_la) si incrementand cu 'incrementare'

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from random import randrange, seed\n' +
                                '\n' +
                                'seed(1)\n' +
                                '\n' +
                                'print(randrange(5)) #1\n' +
                                'print(randrange(2,5)) # 4 \n' +
                                'print(randrange(2,5,2)) # 2'}
                            </SyntaxHighlighter>

                        </li>

                        <li>randint(de_la, pana_la): genereaza un numar intreg intre [de_la, pana_la] (este un alias pentru metoda: randrange(de_la, pana_la+1))</li>
                        <li>choice(secventa): alege in mod aleator un element dintr-o secventa
                        </li>
                        <li>sample(secventa, numar_elemente_alese): alege in mod aleator nu numar de 'numar_elemente_alese' elemente dintr-o secventa
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from random import choice, sample \n' +
                        '\n' +
                        'lista = [3,52,53,1,35,12]\n' +
                        '\n' +
                        'print(choice(lista)) #12\n' +
                        'print(sample(lista, 2)) # [35,12]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Metode din modulul <b>platform</b>:
                    <ul>
                        <li>platform(aliased = False, terse = False):
                            <ul>
                                <li>
                                    daca e True (sau valoare diferita de 0), funcția va folosi aliasuri pentru diferite platforme care raportează nume de sistem care diferă de numele lor comune, de exemplu SunOS va fi raportat ca Solaris
                                </li>
                                <li>
                                    daca e True (sau o valoare diferita de 0), functia va returna doar informațiile minime absolut necesare pentru a identifica platforma.
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import platform\n' +
                                '\n' +
                                'print(platform.platform()) # Windows-10-10.0.19041-SP0\n' +
                                'print(platform.platform(True)) # Windows-10-10.0.19041-SP0\n' +
                                'print(platform.platform(True, True)) # Windows-10'}
                            </SyntaxHighlighter>

                        </li>
                        <li>machine(): numele generic al procesorului care rulează sistemul de operare împreună cu Python</li>
                        <li>processor(): numele real al procesorului</li>
                        <li>system(): numele generic al sistemului de operare (OS)</li>
                        <li>version(): versiunea sistemului de operare</li>
                        <li>python_implementation(): returneaza implementarea de Python</li>
                        <li>python_version_tuple(): returneaza versiunea (ca tuplu: versiunea majora, versiunea minora, nivel patch/corectie level) de Python</li>
                    </ul>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from platform import *\n' +
                        '\n' +
                        'print(machine()) #AMD64\n' +
                        'print(processor()) # Intel64 Family 6 Model 158 Stepping 10, GenuineIntel\n' +
                        'print(system()) # Windows\n' +
                        'print(version()) # 10.0.19041\n' +
                        'print(python_implementation()) # CPython\n' +
                        'print(python_version_tuple()) # (\'3\', \'9\', \'0\')'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExceptionsPythonContent;