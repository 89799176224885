import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"spring-boot-root", url:"#", title:"Introducere in Spring Boot", subtitle:""},
        {id:"spring-boot-externalize-application-properties-tomcat", url:"/spring-boot/externalize-application-properties-tomcat", title:"Externalizare fisier application.properties in Tomcat", subtitle:"",parent:"spring-boot-root"},

        {id:"spring-boot-specification-exists", url:"/spring-boot/specification-exists", title:"Specification: exists", subtitle:"", parent:"spring-boot-root"},
        {id:"spring-boot-repository-query", url:"/spring-boot/repository-query", title:"@Query : group by, sum, count, case when then else end", subtitle:"", parent:"spring-boot-root"},
        {id:"spring-boot-jasypt", url:"/spring-boot/jasypt", title:"Criptarea parolelor folosind Jasypt", subtitle:"", parent:"spring-boot-root"},

        {id:"spring-boot-auth-root", url:"#", title:"Autorizatie in Spring Boot", subtitle:""},
        {id:"spring-boot-ldap", url:"/spring-boot/ldap", title:"LDAP", subtitle:"", parent:"spring-boot-auth-root"},
        {id:"spring-boot-naming-strategy", url:"/spring-boot/naming-strategy", title:"Tabele: Naming strategy", subtitle:"", parent:"spring-boot-auth-root"},

        {id:"spring-boot-iterating-data", url:"/spring-boot/iterating-data", title:"Iterare date in Spring Data JPA", subtitle:"", parent:"spring-boot-auth-root"},

        {id:"spring-boot-github-provider-auth", url:"/spring-boot/github-provider-auth", title:"GitHub Provider", subtitle:"", parent:"spring-boot-auth-root"},
        {id:"spring-boot-google-provider-auth", url:"/spring-boot/google-provider-auth", title:"Google Provider", subtitle:"", parent:"spring-boot-auth-root"},

    ]

    static indexUrl = "/spring-boot/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Spring Boot
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;