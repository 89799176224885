import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class LdapAuthentificationHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-ldap-auth", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>LDAP Authentication</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1. Adaugare dependente</b>
                    <br/>
                    <br/>

                    Adaugare dependente <b>spring-ldap-core</b> si <b>spring-security-ldap</b> (gradle):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' compile group: \'org.springframework.security\', name: \'spring-security-ldap\', version: spring_security_version'}
                    </SyntaxHighlighter>

                    sau (maven):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<dependency>\n' +
                            '    <groupId>org.springframework.ldap</groupId>\n' +
                            '    <artifactId>spring-ldap-core</artifactId>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<dependency>\n' +
                            '    <groupId>org.springframework.security</groupId>\n' +
                            '    <artifactId>spring-security-ldap</artifactId>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Modificare SecurityConfiguration.java</b>
                    <br/>
                    <br/>

                    Se modifica <b>SecurityConfiguration.java</b> adaugand metodele <b>configureGlobal(AuthenticationManagerBuilder auth)</b> si <b>getContextSource()</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@Inject\n' +
                            '    public void configureGlobal(AuthenticationManagerBuilder auth) throws Exception {\n' +
                            '        auth.ldapAuthentication()\n' +
                            '        \t.userSearchBase("o=myO,ou=myOu") //don\'t add the base\n' +
                            '        \t.userSearchFilter("(uid={0})")\n' +
                            '        \t.groupSearchBase("ou=Groups") //don\'t add the base\n' +
                            '        \t.groupSearchFilter("member={0}")\n' +
                            '        \t.contextSource(getContextSource());\n' +
                            '    }\n' +
                            '    @Bean\n' +
                            '    public LdapContextSource getContextSource() {\n' +
                            '        LdapContextSource contextSource = new LdapContextSource();\n' +
                            '        contextSource.setUrl("ldap://[IP goes here]:[port goes here]");\n' +
                            '        contextSource.setBase("dc=mycompany,dc=com");\n' +
                            '        contextSource.setUserDn("cn=aUserUid,dc=mycompany,dc=com");\n' +
                            '        contextSource.setPassword("hisPassword");\n' +
                            '        contextSource.afterPropertiesSet(); //needed otherwise you will have a NullPointerException in spring\n' +
                            '\n' +
                            '        return contextSource;\n' +
                            '    }\n'}
                    </SyntaxHighlighter>

                    Daca scoatem in fisierul de proprietati:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'    @Value("${app.ldap.active}")\n' +
                            '    private boolean ldapActive;\n' +
                            '\n' +
                            '    @Value("${app.ldap.url}")\n' +
                            '    private String ldapUrls;\n' +
                            '\n' +
                            '    @Value("${app.ldap.base_dn}")\n' +
                            '    private String ldapBaseDn;\n' +
                            '\n' +
                            '    @Value("${app.ldap.username}")\n' +
                            '    private String ldapSecurityPrincipal;\n' +
                            '\n' +
                            '    @Value("${app.ldap.password}")\n' +
                            '    private String ldapPrincipalPassword;\n' +
                            '\n' +
                            '    @Value("${app.ldap.user_dn_pattern}")\n' +
                            '    private String ldapUserDnPattern;'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'    @Bean\n' +
                            '    @ConditionalOnProperty(value = "app.ldap.active", havingValue = "true")\n' +
                            '    public AuthenticationManager ldapAuthenticationManager(BaseLdapPathContextSource contextSource) {\n' +
                            '        LdapBindAuthenticationManagerFactory managerFactory = new LdapBindAuthenticationManagerFactory(contextSource);\n' +
                            '        managerFactory.setUserDnPatterns(ldapUserDnPattern);\n' +
                            '        if (ldapActive) {\n' +
                            '            managerFactory.setUserDetailsContextMapper(ldapUserDetailsContextMapper);\n' +
                            '        }\n' +
                            '        managerFactory.setUserSearchFilter("sAMAccountName={0}");\n' +
                            '\n' +
                            '        return managerFactory.createAuthenticationManager();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @ConditionalOnProperty(value = "app.ldap.active", havingValue = "true")\n' +
                            '    public LdapContextSource contextSource() {\n' +
                            '        LdapContextSource ldapContextSource = new LdapContextSource();\n' +
                            '        ldapContextSource.setUrl(ldapUrls);\n' +
                            '        ldapContextSource.setBase(ldapBaseDn);\n' +
                            '        ldapContextSource.setUserDn(ldapSecurityPrincipal);\n' +
                            '        ldapContextSource.setPassword(ldapPrincipalPassword);\n' +
                            '        return ldapContextSource;\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    in plus, in metoda <b>public SecurityFilterChain filterChain(HttpSecurity http)</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'if(ldapActive) {\n' +
                            '    http.authenticationManager(ldapAuthenticationManager(contextSource()));\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Implementare AuthenticationManager</b>
                    <br/>
                    <br/>

                    Se creaza o nouă clasă <b>CustomAuthenticationManager</b> care implementează interfața <b>AuthenticationManager</b>
                    și suprascrie metoda de autentificare pentru a forța procesul de autentificare să autentifice utilizatorul prin LDAP.

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@Component\n' +
                            'public class CustomAuthenticationManager implements AuthenticationManager {\n' +
                            '\n' +
                            '    LdapAuthenticationProvider provider = null;\n' +
                            '\n' +
                            '    private static final Logger log = LoggerFactory.getLogger(CustomAuthenticationManager.class);\n' +
                            '\n' +
                            '    private final UserRepository userRepository;\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    private final LdapContextSource ldapContextSource;\n' +
                            '\n' +
                            '    public CustomAuthenticationManager(UserRepository userRepository, LdapContextSource ldapContextSource) {\n' +
                            '        this.userRepository = userRepository;\n' +
                            '        this.ldapContextSource = ldapContextSource;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Authentication authenticate(Authentication authentication) {\n' +
                            '        log.debug("AUTHENTICATION Login" + authentication.getName());\n' +
                            '        log.debug("AUTHENTICATION Password" + authentication.getCredentials().toString());\n' +
                            '\n' +
                            '        BindAuthenticator bindAuth = new BindAuthenticator(ldapContextSource);\n' +
                            '        FilterBasedLdapUserSearch userSearch = new FilterBasedLdapUserSearch(\n' +
                            '                "", "(uid={0})",\n' +
                            '                ldapContextSource);\n' +
                            '        try{\n' +
                            '            bindAuth.setUserSearch(userSearch);\n' +
                            '            bindAuth.afterPropertiesSet();\n' +
                            '        } catch (Exception ex) {\n' +
                            '            java.util.logging.Logger.getLogger(CustomAuthenticationManager.class.getName()).log(Level.SEVERE, null, ex);\n' +
                            '        }\n' +
                            '        provider = new LdapAuthenticationProvider(bindAuth);\n' +
                            '        provider.setUserDetailsContextMapper(new UserDetailsContextMapper() {\n' +
                            '            @Override\n' +
                            '            public UserDetails mapUserFromContext(DirContextOperations ctx, String username, Collection<? extends GrantedAuthority> clctn) {\n' +
                            '                Optional<User> isUser = userRepository.findOneWithAuthoritiesByLogin(username);\n' +
                            '                final User user = isUser.get();\n' +
                            '                Set<Authority> userAuthorities = user.getAuthorities();\n' +
                            '                Collection<GrantedAuthority> grantedAuthorities = new ArrayList<>();\n' +
                            '                for(Authority a: userAuthorities){\n' +
                            '                    GrantedAuthority grantedAuthority = new SimpleGrantedAuthority(\n' +
                            '                            a.getName());\n' +
                            '                    grantedAuthorities.add(grantedAuthority);\n' +
                            '                }\n' +
                            '                  return new org.springframework.security.core.userdetails.User(\n' +
                            '                    username, "1" , grantedAuthorities);    \n' +
                            '            }\n' +
                            '\n' +
                            '            @Override\n' +
                            '            public void mapUserToContext(UserDetails ud, DirContextAdapter dca) {\n' +
                            '\n' +
                            '            }\n' +
                            '        });\n' +
                            '        return provider.authenticate(authentication);\n' +
                            '    }\n'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@Component\n' +
                            'public class LdapUserDetailsContextMapper extends LdapUserDetailsMapper {\n' +
                            '\n' +
                            '    private final UserRepository userRepository;\n' +
                            '\n' +
                            '    private final AuthorityRepository authorityRepository;\n' +
                            '\n' +
                            '    public LdapUserDetailsContextMapper(AuthorityRepository authorityRepository, UserRepository userRepository) {\n' +
                            '        this.userRepository = userRepository;\n' +
                            '        this.authorityRepository = authorityRepository;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public UserDetails mapUserFromContext(DirContextOperations ctx, String username, Collection<? extends GrantedAuthority> authorities) {\n' +
                            '        LdapUserDetails ldapUserDetails = (LdapUserDetails) super.mapUserFromContext(ctx, username, authorities);\n' +
                            '        LdapUser ldapUser = new LdapUser(ldapUserDetails);\n' +
                            '        String commonName = ctx.getStringAttribute("cn");\n' +
                            '        String email = ctx.getStringAttribute("mail");\n' +
                            '        String lastName = ctx.getStringAttribute("sn");\n' +
                            '        String firstName;\n' +
                            '        if (commonName != null) {\n' +
                            '            if (lastName == null) {\n' +
                            '                firstName = commonName;\n' +
                            '            } else {\n' +
                            '                firstName = ctx.getStringAttribute("cn").replace(lastName, "").trim();\n' +
                            '            }\n' +
                            '            ldapUser.setFirstName(firstName);\n' +
                            '            ldapUser.setLastName(lastName);\n' +
                            '        }\n' +
                            '        ldapUser.setEmail(email);\n' +
                            '\n' +
                            '        Optional<User> optionalUser = userRepository.findOneWithAuthoritiesByLogin(ldapUser.getUsername());\n' +
                            '        User user;\n' +
                            '        if (optionalUser.isEmpty()) {\n' +
                            '            AdminUserDTO userDTO = ldapUser.getAdminUserDto();\n' +
                            '            if (userDTO.getLogin().contains("kj")) {\n' +
                            '                userDTO.getAuthorities().add("ROLE_ADMIN");\n' +
                            '            }\n' +
                            '            userDTO.getAuthorities().add("ROLE_USER");\n' +
                            '            user = createUser(userDTO);\n' +
                            '        } else {\n' +
                            '            user = optionalUser.get();\n' +
                            '        }\n' +
                            '\n' +
                            '        Set<Authority> userAuthorities = user.getAuthorities();\n' +
                            '\n' +
                            '        Collection<GrantedAuthority> grantedAuthorities = new ArrayList<>();\n' +
                            '        for (Authority a : userAuthorities) {\n' +
                            '            GrantedAuthority grantedAuthority = new SimpleGrantedAuthority(a.getName());\n' +
                            '            grantedAuthorities.add(grantedAuthority);\n' +
                            '        }\n' +
                            '        return new org.springframework.security.core.userdetails.User(username, "1", grantedAuthorities);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void mapUserToContext(UserDetails user, DirContextAdapter ctx) {}\n' +
                            '\n' +
                            '    private User createUser(AdminUserDTO userDTO) {\n' +
                            '        User user = new User();\n' +
                            '        user.setLogin(userDTO.getLogin().toLowerCase());\n' +
                            '        user.setFirstName(userDTO.getFirstName());\n' +
                            '        user.setLastName(userDTO.getLastName());\n' +
                            '        if (userDTO.getEmail() != null) {\n' +
                            '            user.setEmail(userDTO.getEmail().toLowerCase());\n' +
                            '        }\n' +
                            '        user.setImageUrl(userDTO.getImageUrl());\n' +
                            '        if (userDTO.getLangKey() == null) {\n' +
                            '            user.setLangKey(Constants.DEFAULT_LANGUAGE); // default language\n' +
                            '        } else {\n' +
                            '            user.setLangKey(userDTO.getLangKey());\n' +
                            '        }\n' +
                            '        user.setPassword("$2a$10$VEjxo0jq2YG9Rbk2HmX9S.k1uZBGYUHdUcid3g/vfiEl7lwWgOH/K"); // parola: user\n' +
                            '        user.setResetKey(RandomUtil.generateResetKey());\n' +
                            '        user.setResetDate(Instant.now());\n' +
                            '        user.setActivated(true);\n' +
                            '        if (userDTO.getAuthorities() != null) {\n' +
                            '            Set<Authority> authorities = userDTO\n' +
                            '                .getAuthorities()\n' +
                            '                .stream()\n' +
                            '                .map(authorityRepository::findById)\n' +
                            '                .filter(Optional::isPresent)\n' +
                            '                .map(Optional::get)\n' +
                            '                .collect(Collectors.toSet());\n' +
                            '            user.setAuthorities(authorities);\n' +
                            '        }\n' +
                            '        userRepository.save(user);\n' +
                            '        return user;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'public class LdapUser implements LdapUserDetails {\n' +
                            '\n' +
                            '    private String firstName;\n' +
                            '    private String lastName;\n' +
                            '    private String email;\n' +
                            '\n' +
                            '    private String dn;\n' +
                            '\n' +
                            '    private String password;\n' +
                            '\n' +
                            '    private String username;\n' +
                            '\n' +
                            '    private Collection<GrantedAuthority> authorities = AuthorityUtils.NO_AUTHORITIES;\n' +
                            '\n' +
                            '    private boolean accountNonExpired = true;\n' +
                            '\n' +
                            '    private boolean accountNonLocked = true;\n' +
                            '\n' +
                            '    private boolean credentialsNonExpired = true;\n' +
                            '\n' +
                            '    private boolean enabled = true;\n' +
                            '\n' +
                            '    // Policy data\n' +
                            '    private int timeBeforeExpiration = Integer.MAX_VALUE;\n' +
                            '\n' +
                            '    private int graceLoginsRemaining = Integer.MAX_VALUE;\n' +
                            '\n' +
                            '    public LdapUser(LdapUserDetails ldapUserDetails) {\n' +
                            '        this.dn = ldapUserDetails.getDn();\n' +
                            '        this.password = ldapUserDetails.getPassword();\n' +
                            '        this.username = ldapUserDetails.getUsername();\n' +
                            '        this.authorities = (Collection<GrantedAuthority>) ldapUserDetails.getAuthorities();\n' +
                            '        this.accountNonExpired = ldapUserDetails.isAccountNonExpired();\n' +
                            '        this.credentialsNonExpired = ldapUserDetails.isCredentialsNonExpired();\n' +
                            '        this.enabled = ldapUserDetails.isEnabled();\n' +
                            '        this.accountNonLocked = ldapUserDetails.isAccountNonLocked();\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getFirstName() {\n' +
                            '        return firstName;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setFirstName(String firstName) {\n' +
                            '        this.firstName = firstName;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getLastName() {\n' +
                            '        return lastName;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setLastName(String lastName) {\n' +
                            '        this.lastName = lastName;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getEmail() {\n' +
                            '        return email;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setEmail(String email) {\n' +
                            '        this.email = email;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getDn() {\n' +
                            '        return dn;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setDn(String dn) {\n' +
                            '        this.dn = dn;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getPassword() {\n' +
                            '        return password;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setPassword(String password) {\n' +
                            '        this.password = password;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getUsername() {\n' +
                            '        return username;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setUsername(String username) {\n' +
                            '        this.username = username;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Collection<GrantedAuthority> getAuthorities() {\n' +
                            '        return authorities;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setAuthorities(Collection<GrantedAuthority> authorities) {\n' +
                            '        this.authorities = authorities;\n' +
                            '    }\n' +
                            '\n' +
                            '    public boolean isAccountNonExpired() {\n' +
                            '        return accountNonExpired;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setAccountNonExpired(boolean accountNonExpired) {\n' +
                            '        this.accountNonExpired = accountNonExpired;\n' +
                            '    }\n' +
                            '\n' +
                            '    public boolean isAccountNonLocked() {\n' +
                            '        return accountNonLocked;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setAccountNonLocked(boolean accountNonLocked) {\n' +
                            '        this.accountNonLocked = accountNonLocked;\n' +
                            '    }\n' +
                            '\n' +
                            '    public boolean isCredentialsNonExpired() {\n' +
                            '        return credentialsNonExpired;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setCredentialsNonExpired(boolean credentialsNonExpired) {\n' +
                            '        this.credentialsNonExpired = credentialsNonExpired;\n' +
                            '    }\n' +
                            '\n' +
                            '    public boolean isEnabled() {\n' +
                            '        return enabled;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setEnabled(boolean enabled) {\n' +
                            '        this.enabled = enabled;\n' +
                            '    }\n' +
                            '\n' +
                            '    public int getTimeBeforeExpiration() {\n' +
                            '        return timeBeforeExpiration;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setTimeBeforeExpiration(int timeBeforeExpiration) {\n' +
                            '        this.timeBeforeExpiration = timeBeforeExpiration;\n' +
                            '    }\n' +
                            '\n' +
                            '    public int getGraceLoginsRemaining() {\n' +
                            '        return graceLoginsRemaining;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setGraceLoginsRemaining(int graceLoginsRemaining) {\n' +
                            '        this.graceLoginsRemaining = graceLoginsRemaining;\n' +
                            '    }\n' +
                            '\n' +
                            '    public AdminUserDTO getAdminUserDto() {\n' +
                            '        AdminUserDTO userDTO = new AdminUserDTO();\n' +
                            '        userDTO.setEmail(getEmail());\n' +
                            '        userDTO.setLogin(getUsername());\n' +
                            '        userDTO.setAuthorities(getAuthorities().stream().map(GrantedAuthority::getAuthority).collect(Collectors.toSet()));\n' +
                            '        userDTO.setActivated(true);\n' +
                            '        userDTO.setFirstName(getFirstName());\n' +
                            '        userDTO.setLastName(getLastName());\n' +
                            '        return userDTO;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void eraseCredentials() {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Modificare SecurityUtils.java</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'  } else if (authentication.getPrincipal() instanceof LdapUserDetails) {\n' +
                            '    \tLdapUserDetails ldapUser = (LdapUserDetails) authentication.getPrincipal();\n' +
                            '    \treturn ldapUser.getUsername();\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'private static String extractPrincipal(Authentication authentication) {\n' +
                            '    if (authentication == null) {\n' +
                            '        return null;\n' +
                            '    } else if (authentication.getPrincipal() instanceof LdapUserDetails) {\n' +
                            '        LdapUserDetails ldapUser = (LdapUserDetails) authentication.getPrincipal();\n' +
                            '        return ldapUser.getUsername();\n' +
                            '    } else if (authentication.getPrincipal() instanceof UserDetails) {\n' +
                            '        UserDetails springSecurityUser = (UserDetails) authentication.getPrincipal();\n' +
                            '        return springSecurityUser.getUsername();\n' +
                            '    } else if (authentication.getPrincipal() instanceof String) {\n' +
                            '        return (String) authentication.getPrincipal();\n' +
                            '    }\n' +
                            '    return null;\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.jhipster.tech/tips/016_tip_ldap_authentication.html"}>LDAP Authentication</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LdapAuthentificationHipsterContent;