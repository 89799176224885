import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"docker", url:"#", title:"Docker", subtitle:""},

        {id:"intro-docker", url:"/docker/intro", title:"Introducere", subtitle:"", parent:"docker"},
        {id:"cleanup-cmd-docker", url:"/docker/cleanup-cmd", title:"Comenzi cleanup", subtitle:"", parent:"docker"},
        {id:"connect-docker", url:"/docker/connect", title:"Conectare la container", subtitle:"", parent:"docker"},
        {id:"cmd-docker", url:"/docker/cmd", title:"Comenzi utile", subtitle:"", parent:"docker"},

        {id:"docker-ubuntu", url:"#", title:"Docker pe Ubuntu", subtitle:""},

        {id:"ubuntu-docker", url:"/docker/ubuntu", title:"Instalare pe Ubuntu", subtitle:"", parent:"docker-ubuntu"},
        {id:"systemctl-docker", url:"/docker/systemctl", title:"Configurare Docker pentru a porni la pornire (systemctl)", subtitle:"", parent:"docker-ubuntu"},
        {id:"compose-docker", url:"/docker/compose", title:"Instalare Docker Compose (Ubuntu)", subtitle:"", parent:"docker-ubuntu"},
        {id:"hello-world-docker", url:"/docker/hello-world", title:"Hello World", subtitle:"", parent:"docker-ubuntu"},

        {id:"docker-root-client", url:"#", title:"Comenzi Docker Client", subtitle:""},
        {id:"version-docker", url:"/docker/version", title:"docker version", subtitle:"", parent:"docker-root-client"},
        {id:"docker-help", url:"/docker/help", title:"docker --help", subtitle:"", parent:"docker-root-client"},
        {id:"docker-info", url:"/docker/info", title:"docker system info (docker info)", subtitle:"", parent:"docker-root-client"},
        {id:"docker-system-events", url:"/docker/system-events", title:"docker system events", subtitle:"", parent:"docker-root-client"},
        {id:"docker-system-prune", url:"/docker/system-prune", title:"docker system prune", subtitle:"", parent:"docker-root-client"},
        {id:"docker-system-df", url:"/docker/system-df", title:"docker system df", subtitle:"", parent:"docker-root-client"},

        {id:"docker-hub-root", url:"#", title:"Comenzi Docker Hub", subtitle:""},
        {id:"docker-search", url:"/docker/search", title:"docker search", subtitle:"", parent:"docker-hub-root"},
        {id:"login-docker", url:"/docker/login", title:"docker login", subtitle:"", parent:"docker-hub-root"},

        {id:"docker-client-images", url:"#", title:"Comenzi Docker Client: Imagini", subtitle:""},
        {id:"docker-images", url:"/docker/images", title:"docker image ls (docker images)", subtitle:"", parent:"docker-client-images"},
        {id:"docker-images-build", url:"/docker/images-build", title:"docker image build", subtitle:"", parent:"docker-client-images"},
        {id:"docker-rmi", url:"/docker/rmi", title:"docker image rm (docker rmi)", subtitle:"", parent:"docker-client-images"},
        {id:"docker-history", url:"/docker/history", title:"docker image history (docker history)", subtitle:"", parent:"docker-client-images"},
        {id:"docker-image-prune", url:"/docker/image-prune", title:"docker image prune", subtitle:"", parent:"docker-client-images"},
        {id:"docker-images-inspect", url:"/docker/images-inspect", title:"docker image inspect", subtitle:"", parent:"docker-client-images"},
        {id:"docker-tag", url:"/docker/tag", title:"docker image tag (docker tag)", subtitle:"", parent:"docker-client-images"},
        {id:"docker-image-save", url:"/docker/image-save", title:"docker image save", subtitle:"", parent:"docker-client-images"},
        {id:"docker-image-load", url:"/docker/image-load", title:"docker image load", subtitle:"", parent:"docker-client-images"},
        {id:"docker-pull", url:"/docker/pull", title:"docker image pull (docker pull)", subtitle:"", parent:"docker-client-images"},
        {id:"docker-push", url:"/docker/push", title:"docker image push (docker push)", subtitle:"", parent:"docker-client-images"},
        {id:"docker-image-import", url:"/docker/image-import", title:"docker image import", subtitle:"", parent:"docker-client-images"},

        {id:"docker-multi-step-builds", url:"/docker/multi-steps-builds", title:"Multi-Stage Build / Multi-Step Builds", subtitle:"", parent:"docker-client-images"},
        {id:"docker-registry", url:"/docker/registry", title:"Docker Registry", subtitle:"", parent:"docker-client-images"},
        {id:"docker-host-image-registry", url:"/docker/host-image-registry", title:"Gazduire propriul Docker Image Registry", subtitle:"", parent:"docker-client-images"},

        {id:"docker-client", url:"#", title:"Comenzi Docker Client: Containere", subtitle:""},
        {id:"docker-ps", url:"/docker/ps", title:"docker ps / docker container ls", subtitle:"", parent:"docker-client"},
        {id:"docker-create", url:"/docker/create", title:"docker create / docker container create", subtitle:"", parent:"docker-client"},
        {id:"docker-run", url:"/docker/run", title:"docker run / docker container run", subtitle:"", parent:"docker-client"},
        {id:"docker-start", url:"/docker/start", title:"docker start / docker container start", subtitle:"", parent:"docker-client"},
        {id:"docker-pause", url:"/docker/pause", title:"docker pause / docker container pause", subtitle:"", parent:"docker-client"},
        {id:"docker-unpause", url:"/docker/unpause", title:"docker unpause / docker container unpause", subtitle:"", parent:"docker-client"},
        {id:"docker-stop", url:"/docker/stop", title:"docker stop / docker container stop", subtitle:"", parent:"docker-client"},
        {id:"docker-kill", url:"/docker/kill", title:"docker kill / docker container kill", subtitle:"", parent:"docker-client"},
        {id:"docker-container-prune", url:"/docker/container-prune", title:"docker container prune", subtitle:"", parent:"docker-client"},
        {id:"docker-rm", url:"/docker/rm", title:"docker rm / docker container rm", subtitle:"", parent:"docker-client"},
        {id:"docker-restart", url:"/docker/restart", title:"docker restart / docker container restart", subtitle:"", parent:"docker-client"},
        {id:"docker-rename", url:"/docker/rename", title:"docker container rename", subtitle:"", parent:"docker-client"},
        {id:"docker-attach", url:"/docker/attach", title:"docker container attach", subtitle:"", parent:"docker-client"},
        {id:"docker-exec", url:"/docker/exec", title:"docker exec / docker container exec", subtitle:"", parent:"docker-client"},
        {id:"docker-logs", url:"/docker/logs", title:"docker logs / docker container logs", subtitle:"", parent:"docker-client"},
        {id:"docker-container-cp", url:"/docker/cp", title:"docker container cp", subtitle:"", parent:"docker-client"},
        {id:"docker-container-export", url:"/docker/container-export", title:"docker container export", subtitle:"", parent:"docker-client"},
        // {id:"docker-container-import", url:"/docker/container-import", title:"docker container import", subtitle:"", parent:"docker-client"},
        {id:"docker-inspect", url:"/docker/inspect", title:"docker inspect / docker container inspect", subtitle:"", parent:"docker-client"},
        {id:"docker-commit", url:"/docker/commit", title:"docker commit / docker container commit", subtitle:"", parent:"docker-client"},
        {id:"docker-container-stats", url:"/docker/stats", title:"docker container stats", subtitle:"", parent:"docker-client"},
        {id:"docker-container-top", url:"/docker/top", title:"docker container top", subtitle:"", parent:"docker-client"},
        {id:"docker-container-diff", url:"/docker/diff", title:"docker container diff", subtitle:"", parent:"docker-client"},
        {id:"docker-container-wait", url:"/docker/wait", title:"docker container wait", subtitle:"", parent:"docker-client"},
        {id:"docker-it-flags", url:"/docker/it-flags", title:"docker run/exec: flagurile -it", subtitle:"", parent:"docker-client"},
        {id:"docker-d-flag", url:"/docker/d-flag", title:"docker run: flag -d (detach/background)", subtitle:"", parent:"docker-client"},
        {id:"docker-port-mapping-flag", url:"/docker/port-mapping-flag", title:"docker run: flag -p (port mapping)", subtitle:"", parent:"docker-client"},
        {id:"docker-w-flag", url:"/docker/w-flag", title:"docker run: flag -w (working directory)", subtitle:"", parent:"docker-client"},
        {id:"docker-e-flag", url:"/docker/e-flag", title:"docker run: flag -e (env)", subtitle:"", parent:"docker-client"},


        {id:"docker-networking-root", url:"#", title:"Networking", subtitle:""},
        {id:"docker-networking", url:"/docker/networking", title:"Introducere in Networking", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-ls", url:"/docker/network-ls", title:"Listare retele: docker network ls", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-connect", url:"/docker/network-connect", title:"Conectare de la retea: docker network connect", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-disconnect", url:"/docker/network-disconnect", title:"Deconectare de la retea: docker network disconnect", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-create", url:"/docker/network-create", title:"Creare retea: docker network create", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-inspect", url:"/docker/network-inspect", title:"Inspectare retea: docker network inspect", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-bridge", url:"/docker/network-bridge", title:"Utilizare retea de tip bridge", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-user-defined-bridge", url:"/docker/network-user-defined-bridge", title:"Utilizare retea de tip user-defined bridge", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-host", url:"/docker/network-host", title:"Utilizare retea de tip host", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-none", url:"/docker/network-none", title:"Utilizare retea de tip none", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-macvlan", url:"/docker/network-macvlan", title:"Utilizare retea de tip MacVLAN", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-overlay", url:"/docker/network-overlay", title:"Utilizare retea de tip overlay", subtitle:"", parent:"docker-networking-root"},
        {id:"docker-network-dns", url:"/docker/network-dns", title:"Configurare DNS", subtitle:"", parent:"docker-networking-root"},

        {id:"docker-custom-image", url:"#", title:"Creare imagine personalizata (Dockerfile)", subtitle:""},
        {id:"docker-dockerfile", url:"/docker/dockerfile", title:"Dockerfile (FROM, RUN, CMD)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-dockerfile-copy", url:"/docker/dockerfile-copy", title:"Dockerfile (COPY)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-dockerfile-workdir", url:"/docker/dockerfile-workdir", title:"Dockerfile (WORKDIR)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-dockerfile-entrypoint", url:"/docker/dockerfile-entrypoint", title:"Dockerfile (ENTRYPOINT)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-dockerfile-healthcheck", url:"/docker/dockerfile-healthcheck", title:"Dockerfile (HEALTHCHECK)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-tag-image", url:"/docker/tag-image", title:"Nume/Tag pentru imagine (docker build -t)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-dockerignore", url:"/docker/dockerignore", title:"Fisierul .dockerignore", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-build-t", url:"/docker/build-t", title:"Build cu specificare nume fisier de tip Dockerfile (-f)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-v-flag", url:"/docker/v-flag", title:"docker run: flag -v (volume)", subtitle:"", parent:"docker-custom-image"},
        {id:"docker-ex1", url:"/docker/ex1", title:"Exercitiu 1 (simpleweb)", subtitle:"", parent:"docker-custom-image"},

        {id:"docker-compose-root", url:"#", title:"Introducere in Docker compose", subtitle:""},
        {id:"docker-compose-yml", url:"/docker/compose-yml", title:"Docker compose (docker-compose.yml)", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-compose-volumes", url:"/docker/compose-volumes", title:"docker compose -volumes", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-compose-up", url:"/docker/compose-up", title:"docker compose up [-d]", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-compose-down", url:"/docker/compose-down", title:"docker compose down", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-restart-policies", url:"/docker/restart-policies", title:"Politici de restart", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-compose-environment", url:"/docker/compose-environment", title:"docker compose - environment", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-compose-dependson", url:"/docker/compose-dependson", title:"docker compose - depends_on", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-compose-ps", url:"/docker/compose-ps", title:"docker compose ps", subtitle:"", parent:"docker-compose-root"},
        {id:"docker-ex2", url:"/docker/ex2", title:"Exercitiu 2 (visits)", subtitle:"", parent:"docker-compose-root"},

        {id:"docker-production-root", url:"#", title:"Drumul spre productie", subtitle:""},
        {id:"docker-ex3", url:"/docker/ex3", title:"Exercitiu 3 (frontend)", subtitle:"", parent:"docker-production-root"},

        {id:"docker-production-ci", url:"#", title:"CI: Github, Travis CI/GitHub Actions, AWS", subtitle:""},
        {id:"docker-github", url:"/docker/github", title:"GitHub", subtitle:"", parent:"docker-production-ci"},
        {id:"docker-travis", url:"/docker/travis", title:"Travis", subtitle:"", parent:"docker-production-ci"},
        {id:"docker-github-actions", url:"/docker/github-actions", title:"GitHub Actions", subtitle:"", parent:"docker-production-ci"},
        {id:"docker-aws", url:"/docker/aws", title:"AWS", subtitle:"", parent:"docker-production-ci"},
        {id:"docker-ex4", url:"/docker/ex4", title:"Exercitiu 4 (frontend v2)", subtitle:"", parent:"docker-production-ci"},

        {id:"docker-multi-services", url:"#", title:"Servicii multiple", subtitle:""},
        {id:"docker-ex5", url:"/docker/ex5", title:"Exercitiu 5 (complex) - dev", subtitle:"", parent:"docker-multi-services"},
        {id:"docker-ex5-prod", url:"/docker/ex5-prod", title:"Exercitiu 5 (complex) - prod: Trevis/GitHub Actions", subtitle:"", parent:"docker-multi-services"},
        {id:"docker-ex5-aws", url:"/docker/ex5-aws", title:"Exercitiu 5 (complex) - prod: AWS", subtitle:"", parent:"docker-multi-services"},
        {id:"docker-ex5-clean-aws", url:"/docker/ex5-clean-aws", title:"Exercitiu 5 (complex) - Curatare AWS", subtitle:"", parent:"docker-multi-services"},

        {id:"docker-ucp-root", url:"#", title:"UCP - Universal Control Plane / Mirantis Kubernetes Engine", subtitle:""},
        {id:"docker-ucp", url:"/docker/ucp", title:"Docker Universal Control Plane (UCP) / Mirantis Kubernetes Engine (MKE)", subtitle:"", parent:"docker-ucp-root"},
        {id:"docker-dtr", url:"/docker/dtr", title:"Docker Trusted Registry (DTR)/ Mirantis Secure Registry (MSR)", subtitle:"", parent:"docker-ucp-root"},
        {id:"docker-security", url:"/docker/security", title:"Securitate", subtitle:"", parent:"docker-ucp-root"},
        {id:"docker-k8s", url:"/docker/k8s", title:"Kubernetes", subtitle:"", parent:"docker-ucp-root"},
        {id:"docker-volume", url:"/docker/volume", title:"Stocare si volume", subtitle:"", parent:"docker-ucp-root"},

        {id:"docker-resume", url:"#", title:"Rezumat", subtitle:""},
        {id:"docker-dockerfile-resume", url:"/docker/dockerfile-resume", title:"Dockerfile", subtitle:"", parent:"docker-resume"},
        {id:"docker-volume-resume", url:"/docker/volume-resume", title:"Volume", subtitle:"", parent:"docker-resume"},
        {id:"docker-networking-resume", url:"/docker/networking-resume", title:"Networking", subtitle:"", parent:"docker-resume"},
        {id:"docker-compose-resume", url:"/docker/compose-resume", title:"Docker Compose", subtitle:"", parent:"docker-resume"},
        {id:"docker-swarm-resume", url:"/docker/swarm-resume", title:"Docker Swarm", subtitle:"", parent:"docker-resume"},

        {id:"docker-mock-root", url:"#", title:"Mock & Tips", subtitle:""},
        {id:"docker-mock", url:"/docker/mock", title:"Mock 1", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-mock2", url:"/docker/mock2", title:"Mock 2", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-tips1", url:"/docker/tips1", title:"Tips - Image, Management, Registry", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-tips3", url:"/docker/tips3", title:"Tips - Storage & Volumes", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-tips4", url:"/docker/tips4", title:"Tips - Instalare si configurare", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-tips5", url:"/docker/tips5", title:"Tips - Networking", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-tips6", url:"/docker/tips6", title:"Tips - Securitate", subtitle:"", parent:"docker-mock-root"},
        {id:"docker-tips2", url:"/docker/tips2", title:"Tips - K8s", subtitle:"", parent:"docker-mock-root"}
    ]

    static indexUrl = "/docker/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Docker
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;