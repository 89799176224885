import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class TipsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-tips", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    1. Liferay Developer Studio este bazat pe Eclipse.

                    <hr/>
                    2. Liferay Developer Studio nu este necesar pentru a dezvolta aplicatii Liferay (se poate folosi de exemplu IntelliJ).

                    <hr/>
                    3. Liferay Workspaces poate fi generat/folosit si fara Liferay Developer Studio.

                    <hr/>
                    4. Liferay Workspace generat de gradle contine:
                    <ul>
                        <li>directorul <i>bundles</i></li>
                        <li>directorul <i>configs</i></li>
                        <li>fisierul <i>settings.gradle</i></li>
                    </ul>

                    <hr/>
                    5. BLADE CLI poate fi folosit pentru a creea:
                    <ul>
                        <li>proiecte compatibile cu orice IDE sau mediul de dezvoltare.</li>
                        <li>module independente de <i>workspace</i> </li>
                    </ul>

                    Blade CLI este un set de comenzii (in linie de comanda) care permite să creăm proiecte pe baza unor șabloane, (construirea unui anumit tip de aplicație Liferay sau un anumit tip de aplicație în lumea Liferay).
                    <br/>
                    Blade CLI vine cu un spațiu de lucru Liferay, nu e nevoie de spațiul de lucru Liferay pentru a utiliza Blade CLI, se poate folosi independent; vine cu un spațiu de lucru.
                    <br/>
                    Workspace oferă o structură pentru a ne găzdui proiectele și Blade CLI împreună cu Liferay.


                    <hr/>
                    6. Service Builder se foloseste de fisierul <b>service.xml</b> pentru a genera cod.

                    <hr/>
                    7. Service Builder nu genereaza:
                    <ul>
                        <li>service.xml</li>
                        <li>portlet-module-hints.xml (atentie: genereaza <b>portlet-model-hints.xml</b>)</li>
                    </ul>

                    <hr/>
                    8. Daca se modifica Local service nu trebuie facure rebuild folosind Service Builder.

                    <hr/>
                    9. Service Context este obiectul care agregă informatiile necesare pentru carecteristicile folosite de portleti si servicii.

                    <hr/>
                    10. Service Builder genereaza implementari de clase pentru fiecare entitate Model si Entity Finders.

                    <hr/>
                    11. Liferay Developer Studio este:
                    <ul>
                        <li>comercial</li>
                        <li>contine toate intrumentele necesare dezoltarii folosind Liferay</li>
                        <li>permite instalarea automanta de bundle Liferay DXP</li>
                    </ul>

                    <hr/>
                    12. OSGi este o specificație și un set de standarde pentru dezvoltarea software modulară Java.

                    <hr/>
                    13. Fiecare bundle are un identificator unic care are două anteturi (<b>Bundle-SymbolicName</b> și <b>Bundle-version</b>).

                    <hr/>
                    14. Toate aplicațiile Liferay sunt aplicații OSGi care rulează în containerul OSGi.

                    <hr/>
                    15. Intervalul de la <b>Bundle-version</b>:
                    <ul>
                        <li>parantezele rotunde ( si ) indica exclusivitatea</li>
                        <li>parentezele patrate [ si ] indica inclusivitatea (exemplu: [1.1, 2.0) - include de la versiunea 1.1 pana 2.0, dar excluzand 2.0)</li>
                        <li>directiva de import poate definii un interval de versiuni (version range)
                            <SyntaxHighlighter showLineNumbers={true} language="properties" style={androidstudio}>
                                {'Import-Package: com.liferay.docs.test; version="[1.0.0,2.0.0)"'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    16. Daca un <i>bundle</i> este in starea <b>installed</b>, atunci inseamna ca acestui <i>bundle</i> ii lipseste dependinte.
                    <br/>
                    Un <i>bundele</i> ajunge prin comanda:
                    <ul>
                        <li><b>install</b>  {'->'}  ajunge in starea <b>installed</b></li>
                        <li><b>uninstall</b>  {'->'}
                            <ul>
                                <li>din <b>installed</b> ajunge in starea <b>uninstalled</b></li>
                                <li>din <b>resolved</b> ajunge in starea <b>installed</b></li>
                            </ul>
                        </li>
                        <li><b>resolve</b>  {'->'}  ajugne in starea <b>resolved</b> </li>
                        <li>
                            <b>update refresh</b> {"->"}
                            <ul>
                                <li>din <b>installed</b> ajunge in starea <b>installed</b></li>
                                <li>din <b>resolved</b> ajunge in starea <b>installed</b></li>
                            </ul>
                        </li>
                        <li>
                            <b>start</b>  {'->'}  ajunge in starea <b>starting</b>, ajungand apoi in starea <b>active</b> (tinand cond de <b>Lazy Activation</b>)
                        </li>
                        <li>
                            <b>stop</b>  {'->'}  ajunge in starea <b>stopping</b> (din starea <b>active</b>), ajungand apoi in starea <b>resolved</b>
                        </li>
                    </ul>
                    Mai multe: <a href={" https://help.liferay.com/hc/en-us/articles/360035467552-Module-Lifecycle"} target={"_blank"}>aici</a>

                    <hr/>
                    17. Ceea ce face registrul de servicii (<b>service registry</b>) este că oferă o modalitate prin care toate componentele care se află în containerul OSGi să fie publicate.
                    <br/>
                    Registrul de service este cel care ține evidența tuturor componentelor.
                    <br/>
                    Putem avea un anumit pachet sau o anumită cerere de clasă java pentru a folosi o anumită componentă.
                    Ceea ce va face registrul de servicii este să accepte acea cerere, să caute în registrul de servicii, apoi să caute componenta care este solicitată și să transmită o referință la acea componentă.
                    <br/>
                    Oferă o cuplare foarte slabă între implementare, sau cea care solicită componenta, și implementarea în sine.
                    <br/>
                    Registrul de servicii ne permite să avem mai multe componente sau mai multe versiuni, așa că, dacă avem nevoie de mai multe versiuni, cadrul OSGi se poate ocupa de asta.
                    <br/>
                    Putem folosi anumite politici și filtre pentru a perfecționa sau alege o anumită componentă, dacă am o versiune trei a componentei, putem folosi politici sau filtre specifice pentru a spune că vreau versiunea unu sau versiunea a doua, sau poate vreau versiunea trei.
                    <br/>
                    Deci, registrul de servicii ține evidența tuturor componentelor noastre, preia cererile pentru o anumită componentă și furnizează referința sau implementarea solicitantului sau clientului.
                    <br/>
                    Rezumat caracteristici registrul de servicii (<b>service registry</b>):
                    <ul>
                        <li>ofera un mecanism de publicare a serviciilor in containeruk OSGi</li>
                        <li>ofera un mecanism de cautare si legare/referi serviicle publicate in mod dinamic</li>
                        <li>ofera cuplare slaba prin ascunderea detaliilor de implementarea, clientului</li>
                        <li>permite sa exista mai multe servicii implementate disponibile</li>
                        <li>clientii cosumatori pot avea politici si filtre pentru alegerea unei anumite implementari</li>
                    </ul>
                    <hr/>
                    18. Declarative Services (DS) este un serviciu care se ocupă de injectarea dependenței OSGi și vă permite să publicați, să găsiți și să legați servicii bazate pe metadate XML și adnotări.
                    <br/>
                    Declarative Services (DS) este un cadru care permite să utilizați servicii de adnotare și înregistrare <b>@Component</b>.

                    <hr/>
                    19. <b>Export-Package – Import-Package</b> este o metodă de partajare a pachetelor între pachete (spunând containerului OSGi, am un pachet Java specific pe care vreau să-l expun containerului OSGi; Deci, când spunem Export-Package, expunem literalmente acest întreg pachet întregului container OSGi.)

                    <hr/>
                    20. Liferay Workspace este:
                    <ul>
                        <li>un mediue generatat / wrapper pentru proiectele Liferay</li>
                        <li>ofera instrumente si o structura de directorarea pentru proiecte</li>
                        <li>poate fi folosit separat de un IDE</li>
                        <li>optional, nu este obligatriu pentru dezvoltarea in Liferay</li>
                    </ul>

                    <hr/>
                    21. Pentru a închide conexiunea la Gogo Shell fără a închide containerul OSGi trebuie rulata comanda: <b>shutdown</b>

                    <hr/>
                    22. Un portlet este:
                    <ul>
                        <li>o component/aplicatie web care produce un fragment de html pentru o pagina</li>
                        <li>guvernat de specificatiile de portlet: JSR-168, JSR-286, JSR-362</li>
                        <li>
                            o aplicatie care ruleaza intr-un mediul runtime portlet (numit portlet container)
                        </li>
                        <li>In Liferay, o aplicatie care are o interfata de utilizator</li>
                    </ul>

                    Liferay DXP a început ca un server de portal, conceput pentru a servi aplicatii web bazate pe Java numite portleturi (JSR 168, JSR-286 și JSR-362).
                    <br/>
                    Portleturile procesează cererile și generează răspunsuri ca orice altă aplicație web. Diferență principala dintre portleturi și alte aplicații web este că portleturile rulează într-o porțiune a paginii web.
                    <br/>
                    Când se scrie o aplicație portlet, trebuie doar să vă faceți griji pentru acea aplicație:
                    restul paginii — navigarea, bannerul de sus și orice alte componente globale ale interfeței — este gestionat de alte componente.
                    <br/>
                    Portleturile rulează numai pe un server portal.
                    Portleturile folosesc suportul existent al portalului pentru gestionarea utilizatorilor, autentificare, permisiuni, gestionarea paginilor și multe altele.
                    Acest lucru vă scuteste de a vă concentra pe dezvoltarea de funcționalități de bază a portlet-ului.
                    În multe feluri, scrierea aplicației ca portlet este mai ușoară decât scrierea unei aplicații autonome.

                    <hr/>
                    23. Exista 2 fisiere descriptor pentru construirea unui portlet standard Java:
                    <ul>
                        <li>web.xml</li>
                        <li>portlet.xml</li>
                    </ul>

                    Mai multe aici: <a href={"https://help.liferay.com/hc/en-us/articles/360018150691-Anatomy-of-a-Portlet"}>Anatomy of a Portlet</a>.

                    <hr/>
                    24. Faza de redare MVC (MVC Render) <i>NU</i> este întotdeauna urmată de faza de acțiune.

                    <hr/>
                    25. O comanda MVC Render poate fi utilizata pentru a obtine o lista care va fi afisata in interfata.

                    <hr/>
                    26. O comanda MVC Action poate fi utilizata pentru:
                    <ul>
                        <li>upload documente</li>
                        <li>adaugare/editare/stergere inregistrari</li>
                    </ul>
                    Nu poate fi utilizata, de exemplu, pentru:
                    <ul>
                        <li>apeluri AJAX</li>
                        <li>incarcare continua de continut</li>
                    </ul>

                    <hr/>
                    27. Se poate seta starea ferestrei portlet-ului în JSP (ca in clasa portlet).
                    <br/>
                    JSP:
                    <SyntaxHighlighter showLineNumbers={true} language="properties" style={androidstudio}>
                        {'<portlet:renderURL windowState="<%= WindowState.NORMAL.toString() %>" var="renderURL" >'}
                    </SyntaxHighlighter>
                    Clasa Java:
                    <SyntaxHighlighter showLineNumbers={true} language="properties" style={androidstudio}>
                        {'actionResponse.setWindowState(WindowState.NORMAL)'}
                    </SyntaxHighlighter>

                    <hr/>
                    28. Parametrul de randare publică (Public render parameter) este:
                    <ul>
                        <li>o metodă de partajare a parametrilor între diferite portleturi</li>
                        <li>este disponibil în toate fazele ciclului de viață care pot fi definite în portlet.xml</li>
                    </ul>

                    <hr/>
                    29. Metode de partajare parametrii intre portleti:
                    <ul>
                        <li>parametri de randare publică (public render parameters)</li>
                        <li>evenimente</li>
                        <li>Client-Side IPC</li>
                        <li>sesiuni portlet</li>
                    </ul>

                    <hr/>
                    30. Se poate crea un portlet fără a utiliza configurația XML, iar configurarea portlet-ului se face în proprietățile componente ale portlet-ului.

                    <hr/>
                    31. Toate serviciile de bază Liferay sunt construite folosind Service Builder.

                    <br/>
                    Service Builder este un instrument de mapare obiect-relațională care vă poate genera modelul, persistența și straturile de serviciu dintr-un singur fișier xml.
                    <br/>
                    Odată generat, codul este complet personalizabil: se poate scrie propriul cod de persistență împreună cu SQL personalizat dacă este necesar.
                    <br/>
                    Indiferent de modul în care se produce codul de persistență, se poate utiliza Service Builder pentru a implementa logica de business a aplicatie.

                    <hr/>
                    32. Service Builder generează un fișier <b>portlet-model-hints.xml</b> care conține maparea coloanelor Hibernate SQL pentru entități și oferă o modalitate de a personaliza tipurile, dimensiunea și validarea câmpurilor.

                    <hr/>
                    33. Obiectul care adună informațiile necesare pentru caracteristicile utilizate în portleturile și serviciile Liferay DXP este <b>Service Context</b>

                    <hr/>
                    34. Serviciile locale NU au aceleași performanțe cu servicile de la distanță (acestea din urma putand contine verificari de permisiune).

                    <hr/>
                    35. Moduri de a invoca un serviciu web:
                    <ul>
                        <li>dintr-un client non-browser, folosind <i>basic authentication</i></li>
                        <li>dintr-un browser, adaugand la URL ,parametrul <b>p_auth</b> cu token de autentificare valid</li>
                        <li>folosind pagina Liferay <b>/api/jsonws</b> (JSON web Service API)</li>
                        <li>din JavaScript, folosind <i>Liferay.service()</i>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'Liferay.Service(\n' +
                                '    \'/user/get-user-by-email-address\',\n' +
                                '    {\n' +
                                '        companyId: Liferay.ThemeDisplay.getCompanyId(),\n' +
                                '        emailAddress: \'test@example.com`\n' +
                                '    },\n' +
                                '    function(obj) {\n' +
                                '        console.log(obj);\n' +
                                '    }\n' +
                                ');'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    Mai multe aici: <a href={"https://help.liferay.com/hc/en-us/articles/360017882152-JSON-Web-Services-Invocation-Examples"}>JSON Web Services Invocation Examples</a>

                    <hr/>
                    36. Module de sabloane pe care Liferay DXP le oferă pentru construirea stratului de prezentare:
                    <ul>
                        <li>mvc portlet</li>
                        <li>freemarket-portlet</li>
                        <li>spring-mvc-portlet</li>
                    </ul>

                    <hr/>
                    37. Obiectul JavaScript Liferay conține instrumente utile:
                    <ul>
                        <li>Liferay.Browser</li>
                        <li>Liferay.Language</li>
                        <li>Liferay.Service</li>
                        <li>Liferay.ThemeDisplay</li>
                    </ul>

                    <hr/>
                    38. Cheia pentru a afisa maparea termenilor este facuta in fisierul: <b>Language.properties</b>

                    <hr/>
                    39. Concepte de bază al controlului accesului în Liferay DXP:
                    <ul>
                        <li>Roles</li>
                        <li>Resources</li>
                        <li>Assets</li>
                    </ul>

                    40. Liferay este construit pe mai multe tehnologii:
                    <ul>
                        <li>OSGi</li>
                        <li>Spring</li>
                        <li>ElasticSearch</li>
                        <li>Java EE</li>
                    </ul>
                    Nu este construit pe Hypersonic!

                    <hr/>
                    41. Instrumente pentru crearea de servicii web:
                    <ul>
                        <li>JAX-WS: servicii web XML</li>
                        <li>JAX-RS: servicii RESTful</li>
                    </ul>

                    <hr/>
                    42. Un filtru Portlet este o metoda bazata pe un API de suprascriere / overriding a unei aplicatii JSP.

                    <br/>
                    Filtrele portlet sunt utilizate în mod obișnuit pentru:
                    <ul>
                        <li>Transformare conținut</li>
                        <li>Adăugare sau modificare atributele de cerere și răspuns</li>
                        <li>Suspendare fază de portlet pentru a obține intrarea utilizatorului</li>
                        <li>Auditarea activității portletului</li>
                    </ul>

                    Exemplu (Ascundere email):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "javax.portlet.name=" + MembersListPortlet.MEMBERSLIST_PORTLET_NAME,\n' +
                        '                "service.ranking:Integer=1" \n' +
                        '        },\n' +
                        '        service = PortletFilter.class\n' +
                        ')\n' +
                        'public class EncodingPersonEmailsRenderFilter implements RenderFilter {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void doFilter(RenderRequest request, RenderResponse response, FilterChain chain)\n' +
                        '            throws IOException, PortletException {\n' +
                        '\n' +
                        '        //Aceasta este executată înainte de randarea portlet-ului\n' +
                        '        Optional.ofNullable((List<Person>)request.getAttribute(MembersListPortlet.MEMBERLIST_ATTRIBUTE))\n' +
                        '                .ifPresent(personList ->\n' +
                        '                        request.setAttribute(MembersListPortlet.MEMBERLIST_ATTRIBUTE, ofuscateEmails(personList)));\n' +
                        '\n' +
                        '        // Invoke the rest of the filters in the chain\n' +
                        '        //  (it also invokes the Portlet render method if this is the last filter in the chain\n' +
                        '        chain.doFilter(request, response);\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    private List<Person> ofuscateEmails(List<Person> list) {\n' +
                        '        return list.stream()\n' +
                        '                .map(this::ofuscatePersonEmail)\n' +
                        '                .collect(Collectors.toList());\n' +
                        '    }\n' +
                        '\n' +
                        '    private Person ofuscatePersonEmail(Person person) {\n' +
                        '        return new Person(person.getName(),\n' +
                        '                          person.getEmail().replaceFirst("(.+)(...)@(...)(.*)", "$1...@...$4"));\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void init(FilterConfig filterConfig) throws PortletException {\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void destroy() {\n' +
                        '\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Mai multe aici: <a href={"https://help.liferay.com/hc/en-us/articles/360035828271-Portlet-Filters"}>Portlet Filters</a>

                    <hr/>
                    43. Liferay Assets care permit adăugare de noi câmpuri la formularele lor de editare:
                    <ul>
                        <li>Blogs Entry</li>
                        <li>Calendar Events</li>
                        <li>Document</li>
                        <li>Document Folder</li>
                        <li>Page</li>
                        <li>Role</li>
                        <li>Site</li>
                        <li>Organization</li>
                        <li>User</li>
                        <li>User Group</li>
                        <li>Wiki Page</li>
                    </ul>
                    Observatie: <b>Form</b> NU permite!
                    <br/>
                    Mai multe aici: <a href={"https://help.liferay.com/hc/en-us/articles/360028818872-Custom-Fields#adding-custom-fields"}>Adding Custom Fields</a>

                    <hr/>
                    44. Concepte legate de integrarea cu Asset Framework:
                    <ul>
                        <li>Asset</li>
                        <li>Asset Renders</li>
                        <li>Asset Render Factories</li>
                    </ul>

                    <hr/>
                    45. Politicile de referință (atributul <b>policy</b>):
                    <ul>
                        <li>STATIC (implicit) (ReferencePolicy.STATIC)
                            <br/>
                            Componenta va fi pornită numai când există o referință atribuită și nu va fi notificată cu privire la serviciile alternative pe măsură ce acestea devin disponibile.
                        </li>
                        <li>DYNAMIC (ReferencePolicy.DYNAMIC):
                            <br/>
                            Componenta va porni atunci când există referințe sau nu, iar componenta va accepta referințe noi pe măsură ce acestea devin disponibile.</li>
                    </ul>

                    Politica de referință controlează ce se întâmplă după ce componenta dvs. pornește când devin disponibile noi opțiuni de referință.
                    Pentru STATIC, noile opțiuni de referință sunt ignorate și pentru DYNAMIC componenta este dispusă să se schimbe.

                    <br/>
                    Implicit, serviciul devine activ numai atunci când această referință poate fi rezolvată:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Referinţă\n' +
                        'MyRepository depozit;'}
                    </SyntaxHighlighter>

                    Serviciul ar putea deveni activ înainte ca serviciul MyRepository să fie disponibil:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference(policy=ReferencePolicy.DYNAMIC)\n' +
                        'MyRepository depozit;'}
                    </SyntaxHighlighter>

                    <hr/>
                    46. Comenziile MVC Resource pot fi folosite pentru:
                    <ul>
                        <li>autocompletare</li>
                        <li>apeluri AJAX</li>
                        <li>verificare Capcha</li>
                        <li>actualizare lista fara refresh la pagina</li>
                    </ul>

                    <hr/>
                    47. Scopuri de configurare:
                    <ul>
                        <li>System</li>
                        <li>Site</li>
                        <li>Portlet Instance</li>
                    </ul>

                    <hr/>
                    48. Acțiune de serviciu Events NU sunt procesate numai înainte (nu după) procesarea cererii.

                    <hr/>
                    49. Model Listeners:
                    <ul>
                        <li>asculta evenimente de persistare pe modele si asocierile acestora</li>
                        <li>componenta OSGi care implementeaza interfata <b>ModelListener</b></li>
                        <li>disponibile pentru servicii core si custom, servicii generate</li>
                    </ul>

                    <hr/>
                    50. Model Listeners:
                    <ul>
                        <li>sunt apelați înainte ca o tranzacție de bază de date să fie finalizată</li>
                        <li>ordinea în care sunt invocați ascultătorii nu poate fi setată</li>
                    </ul>

                    <hr/>
                    51. Listeners registration:
                    <ul>
                        i o foloseste in mod direct pentru a inregistra listener-ul cu acesta destinatie
                        <li>publica listener-ul in registrul OSGi ca o componenta Declarative Service (DS) care specifica o destinatie;
                            Message Bus raspandeste listener-ul automat catre destinatie;
                        </li>
                        <li>obtine o referinta catre Message Bus si o foloseste in mod direct pentru a inregistra listener-ul cu acesta destinatie</li>
                        <li>obtine o referinta catre o destinatie si o foloseste in mod direct pentru a inregistra listener-ul cu acesta destinatie</li>
                    </ul>

                    <hr/>
                    52. În Asynchronous, expeditorul trimite mesajul către Message Bus, iar mesajul NU blochează procesarea pentru restul solicitării expeditorului.

                    <hr/>
                    53. Este recomandat să utilizați căutarea folosind ElasticSearch NU numai în seturi mari de date.

                    <hr/>
                    54. Indexarea peste procesul de transformare a unui portal asset la un document de tip index a unui motor de cautare, care este tipul model de stocare pentru motoarele de cautare

                    <hr/>
                    55. Căutarea înseamnă trimiterea unei interogări de căutare și obținerea de rezultate, numite și hit-uri, din motorul de căutare.

                    <hr/>
                    56. Procesoarele de hit-uri (hits processors) permit efectuarea de acțiuni asupra hit-urilor returnate.

                    <hr/>
                    57.  Liferay Developer Studio:
                    <ul>
                        <li>permite dezvoltatorilor folosirea deploy de tip drag and drip pentru runtimes</li>
                        <li>este inclus ca partea a substriptiei Liferay DXP</li>
                        <li>vine impachetat cu instrumente cu Blade (command-line)</li>
                    </ul>

                    Liferay Developer Studio NU este un instrument neaparat necesar pentru a dezvolva cu Liferay.

                    <hr/>
                    58. Serverul Liferay vine cu: Hypersonic database.
                    <br/>
                    <i>Observatie</i>: Driverele JDBC ale bazelor de date comerciale NU sunt livrate cu pachetele de server Liferay!
                    <br/>
                    De asemenea, nu vine cu:
                    <ul>
                        <li>Oracle Java 11</li>
                        <li>Liferay Developer Studio</li>
                    </ul>

                    <hr/>
                    59. Pot exista multiple versiuni de bundle-uri in containerul OSGi.

                    <hr/>
                    60. O componentă este orice clasă java dintr-un pachet care are un ciclu de viață (poate fi pornita sau oprita).

                    <hr/>
                    61. Evenimentele de pornire (Startup events) sunt apelate:
                    <ul>
                        <li>o dată când portalul (prefixul <b>global</b>)  pornește sau se oprește</li>
                        <li>o data cand instanța portalului (prefixul <b>application</b> ) pornește sau se oprește</li>
                    </ul>

                    Evenimentele de pornire (Startup events) Liferay este un grup de chei care ar putea fi adăugate în fișierul <b>portal-ext.properties</b>:
                    <ul>
                        <li><b>global.startup.events</b>: ruleaza o dată când portalul se inițializează</li>
                        <li><b>global.shutdown.events</b>: ruleaza odata când portalul se oprește</li>

                        <li><b>application.startup.events</b>: ruleaza o dată pentru fiecare instanță de portal care se inițializează</li>
                        <li><b>application.shutdown.events</b>: ruleaza odata pentru fiecare instanță de portal care se oprește</li>

                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'global.startup.events=com.liferay.portal.events.GlobalStartupAction'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'application.startup.events=com.liferay.portal.events.AppStartupAction'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package com.liferay.portal.events;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.events.SimpleAction;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author Brian Wing Shun Chan\n' +
                        ' */\n' +
                        'public class AppStartupAction extends SimpleAction {\n' +
                        '\n' +
                        '\t@Override\n' +
                        '\tpublic void run(String[] ids) {\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    62. O componenta OSGi este o clasa Jaca intr-un bundle care este declarat ca o componenta
                    <br/>
                    In serviciu (service) este o componenta OSGi inregistrata in registrul de servicii din containerul OSGi
                    <br/>
                    Un DS (Declarative Service) este un serviciu care:
                    <ul>
                        <li>gestioneaza injectarea de dependinte OSGi</li>
                        <li>permite publicarea, gasirea si legarea serviciilor</li>
                        <li>definirea si consumarea serviciilor</li>
                    </ul>
                    pe baza metadatelor XML si adnotarilor.

                    <hr/>
                    63. Bndtools:
                    <ul>
                        <li>citeste informatiile din adnotari si fisierul <b>bnd</b></li>
                        <li>creaza fisiere XML service declaration si creaza in fisierul manifers al bundle-ului OSGi headere</li>
                        <li>creaza automat header-ele OSGi feature-sharing</li>
                    </ul>
                    In mare:
                    <ul>
                        <li>ajuta la crearea pachetelor</li>
                        <li>gestionarea fisierului manifest</li>
                    </ul>

                    <hr/>
                    64. Pentru a partaja caracteristici intre bundle:
                    <ul>
                        <li>Export Package - Import Package:
                            <br/>
                            In fisierul <b>bnd.bnd</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'Export-Package: com.liferay.petra.io,com.liferay.petra.io.unsync'}
                            </SyntaxHighlighter>

                            Bnd propagă anteturile OSGi din fișierul <b>bnd.bnd</b> în fișierul JAR {'->'}  fisierl <b>META-INF/MANIFEST.MF</b>!

                        </li>
                        <li>Provide Capability - Required Capability</li>
                        <li>Required Bundle</li>
                    </ul>

                    Un pachet OSGi este un fișier JAR care conține clase și resurse.
                    Metadatele din antetul manifestului descriu ce pachete sunt exportate și ce pachete sunt importate.
                    Pachetele neexportate sunt private pentru pachet; alte pachete nu le pot importa.

                    <hr/>
                    65. Comanda Blade CLI care execută comanzi Gradle folosind Gradle Wrapper este: <b>gw</b>.
                    <br/>
                    Alte comenzi:
                    <ul>
                        <li><b>init</b>:intializeaza un nou Liferay Workspace</li>
                        <li><b>server</b>: pornese/opreste serverul</li>
                        <li><b>sh</b>: se conecteaza la Liferay DXP, executa comenzi Gogo, retureaza rezultatul</li>
                    </ul>
                    BLADE CLI poate fi folosit pentru a crea proiecte care sunt compatibile cu orice IDE sau mediu de dezvoltare!
                    <br/>
                    Mai multe: <a href={"https://help.liferay.com/hc/en-us/articles/360018164591-Blade-CLI"}>aici</a>!

                    <hr/>
                    66. Gogo shell poate fi folosit si fara Liferay Developer Studio.

                    <hr/>
                    67. Comanda MVC Serve Resource NU este urmată de comanda MVC Render!
                    <br/>
                    Comanda MVC Action este cea care este întotdeauna urmată de comanda MVC Render.
                    <br/>
                    Comanda MVC Serve Resource este independentă de restul ciclului de viață al celorlalte comenzi, nu declanșează faza de randare sau orice altă fază; este folosita pentru apelarea cererilor Ajax.


                    <hr/>
                    68. Faza MVC Render:
                    <ul>
                        <li>genereaza fragment HTML</li>
                        <li>metoda standard: <b>render()</b></li>
                        <li>obiecte wrapper: RenderRequest - RenderResponse</li>
                        <li>fiecare portlet a unui pagini trece prin aceasta faza initial</li>
                    </ul>

                    Când un portlet este plasat pe o pagină și portletul trebuie să se afișeze, va fi apelată faza de randare.
                    <br/>
                    În timpul fazei de randare, din punct de vedere tehnic, ceea ce se întâmplă este fragmentul HTML care va fi utilizat pentru a afișa portletul pe pagina care este generată.
                    <br/>
                    Dacă vedem un portlet în pagină, acesta este un indiciu puternic că portletul a trecut prin faza de randare.
                    <br/>
                    Faza de randare va avea un obiect RenderRequest și RenderResponse, putem avea parametri și obiecte în acele obiecte RenderRequest și RenderResponse, care pot fi trecute între JSP și pe partea Java.
                    <br/>
                    La fel cum faza init are o metodă init(), faza de randare are metoda render().

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class BlogsViewMVCRenderCommand implements MVCRenderCommand {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) {\n' +
                        '        return "/blogs/view.jsp";\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    69. MVCPortlet copiaza toți parametrii ActionRequest în faza de randare și acest comportament poate fi dezactivat în configurația portlet-ului.

                    <hr/>
                    70. JSR-268 a stabilit două metode de comunicare inter-portlet: evenimente și parametrii de randare publică.

                    <br/>
                    Se foloseste in adnotarea <b>@Component</b>: <b>javax.portlet.supported-public-render-parameter=<i>nume_parametru</i></b>

                    <br/>
                    Vezi si: <a href={"https://liferay.dev/es/blogs/-/blogs/making-portlet-support-public-render-parameter-in-dxp"} target={"_blank"}>Making portlet support public render parameter in DXP</a>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                        '\n' +
                        'import javax.portlet.Portlet;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        '@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=IPC Message Sender",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view_sender.jsp",\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user",\n' +
                        '\t\t"javax.portlet.supported-public-render-parameter=message"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class IpcSenderPortlet extends MVCPortlet {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'\n' +
                        'import javax.portlet.Portlet;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                        '\n' +
                        '@Component(\n' +
                        '\t\timmediate = true,\n' +
                        '\t\tproperty = {\n' +
                        '\t\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t\t"javax.portlet.display-name=IPC Message Receiver",\n' +
                        '\t\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t\t"javax.portlet.init-param.view-template=/view_receiver.jsp",\n' +
                        '\t\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t\t"javax.portlet.security-role-ref=power-user,user",\n' +
                        '\t\t\t"javax.portlet.supported-public-render-parameter=message"\n' +
                        '\t\t},\n' +
                        '\t\tservice = Portlet.class\n' +
                        '\t)\n' +
                        'public class IpcReceiverPortlet extends MVCPortlet {\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                        '\n' +
                        '<p>\n' +
                        '\t<b>This is message sender portlet.</b>\n' +
                        '</p>\n' +
                        '<portlet:renderURL var="senderURL">\n' +
                        '\t<portlet:param name="message" value="Prove you can receive me" />\n' +
                        '</portlet:renderURL>\n' +
                        '<a href="<%=senderURL %>">Send Message</a>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                        '\n' +
                        '<p>\n' +
                        '\t<b>This is message receiver portlet</b>\n' +
                        '</p>\n' +
                        '<%\n' +
                        '\tString message =  (String)renderRequest.getParameter("message");\n' +
                        '%>\n' +
                        '\n' +
                        'Message is <%=message %>'}
                    </SyntaxHighlighter>

                    <hr/>
                    71. Service builder poate:
                    <ul>
                        <li>poate genera două variante de serviciu, serviciu local și serviciu la distanță</li>
                        <li>fi folosit fără persistență pentru a crea servicii web</li>
                        <li>poate genera documente WSDL (Web Services Description Language) </li>
                    </ul>

                    <hr/>
                    72. Există două tipuri de Finder:
                    <ul>
                        <li>Regular Finder: fără verificări de permisiune</li>
                        <li>Filtered Finder: cu verificări de permisiune</li>
                    </ul>
                    Un Finder este o metoda de interogare a bazei de date definite în fișierul service.xml care este memorat automat în cache.
                    <br/>
                    Filtered Finder sunt generate numai dacă permisiunea este activată.

                    <hr/>
                    73. Un portlet conține atât controlerul, cât și layer-ul de vizualizare ale modelului MVC.

                    <hr/>
                    74. Pentru a face MVCActionCommand ar trebui fie:
                    <ul>
                        <li>implementata interfata <b>MVCActionCommand</b></li>
                        <li>extinsa clasa <b>BaseMVCActionCommand</b></li>
                    </ul>

                    <hr/>
                    75. Librarii de tag-uri oferite de Liferay:
                    <ul>
                        <li>Liferay-theme</li>
                        <li>liferay-portlet</li>
                        <li>liferay-ui</li>
                        <li>liferay-util</li>
                        <li>aui</li>
                    </ul>
                    Observatie: liferay-aui, nu este!

                    <hr/>
                    76. Utilitarul de validare pe server Liferay este implementat în <b>com.liferay.kernal.util.Validator</b>.

                    <hr/>
                    77. Permisiunea în Liferay este definită ca o acțiune asupra unei resurse, deci atunci când actualizăm o postare pe blog, aceasta este permisiunea.
                    <br/>
                    Putem defini permisiunea ca o combinație între o <b>resursă</b> și o <b>acțiune</b> (ex: com.liferay.blogs.BlogsEntry + update),
                    iar permisiunea are un domeniu de aplicare care poate fi aplicat la nivel:
                    <ul>
                        <li>Company</li>
                        <li>Group</li>
                        <li>Grup Template</li>
                        <li>Individual</li>
                    </ul>

                    <hr/>
                    78. Asset Framework gestioneaza obiecte si caracteristici cum ar fi comentarii si rating.

                    <hr/>
                    79. Metoda care redă JSP-ul într-o clasă care extinde BaseJSPAssetRenderer este: <b>getJspPath</b>.

                    <hr/>
                    80. Liferay acceptă cea de-a 6-a ediție a specificației ECMAScript și oferă posibilitatea de a o utiliza în toate browserele moderne cu integrarea transpilerului Babel JS.

                    <hr/>

                    81. Liferay suporta urmatoarele standarde:
                    <ul>
                        <li>poate rula orice portlet care implementeaza urmatoarele specificatii de portlet: JSR-168, JSR-286, JSR-362</li>
                        <li>este un contributor activ la specficatiile JSF-Portlet Bridge </li>
                        <li>JAX-WS (Java API pentru XML Web Services) </li>
                        <li>JAX-RS (Java API pentru RESTful Services) </li>
                        <li>suporta standardele OSGi prin implementari proprii si implementari Apache Felix si Eclipse Equinox</li>
                    </ul>

                    <hr/>
                    82. Filtre de geolocalizare acceptate de Liferay:
                    <ul>
                        <ul>GeoDistanceFilter</ul>
                        <ul>GeoDistanceRangeFilter</ul>
                        <ul>GeoBoundingBoxFilter</ul>
                        <ul>GeoPolygonFilter</ul>
                    </ul>

                    <hr/>
                    83. Expando API vă permite să creați tabele virtuale care nu au legătură cu nicio clasă de model.

                    <hr/>
                    84. Service Wrapper oferă o modalitate sigură de a personaliza serviciile de bază ale Liferay.

                    <hr/>
                    85. Cardinalitatea de referință (Reference Cardinality ) definește dacă o referință trebuie să fie satisfăcută pentru ca componenta să poată fi activată.

                    <hr/>
                    86. Liferay Assets si resursele permit adaugare de noi campuri:
                    <ul>
                        <li>Knowledge Base Article</li>
                        <li>Knowledge Base Folder</li>
                        <li>Message Boards Category</li>
                        <li>Message Boards Message</li>
                    </ul>

                    <hr/>
                    87. Numele fișierelor de limbă urmează urmatoarela sintaxa de denumire: <b>Language_en_US.properties</b>.

                    <hr/>
                    88. Suprasrierea este posibilă chiar și cu o politică de referință statică și reticentă (reluctant).

                    <hr/>
                    89. Comenzi MVC:
                    <ul>
                        <li>MVC Action</li>
                        <li>MVC Render</li>
                        <li>MVC Resource</li>
                    </ul>

                    <hr/>
                    90. Concepte de baza struts:
                    <ul>
                        <li>Action Servlet</li>
                        <li>Action Controller</li>
                        <li>Action Form</li>
                        <li>View Layer</li>
                    </ul>

                    <hr/>
                    91. Suprascrierea acțiunilor Struts în contextul Liferay înseamnă suprascrierea claselor de controler de acțiune.

                    <hr/>
                    92. Un model este identificat si referit prin numele fully qualified al entitatii.


                    <hr/>
                    93. Puteți face un ascultător de evenimente care să ruleze o dată pentru fiecare instanță de portal care este inițializată (cu ajutorul key de proprietate: <b>application.startup.events</b>)

                    <hr/>
                    94. FALS: La redarea unui Asset, portletul Asset Publisher găsește mai întâi un Asset Renderer pentru tipul de model și apoi solicită un serviciu Asset Renderer Factory.

                    <hr/>

                    95. Liferay foloseste ElasticSearch:
                    <ul>
                        <li>datele structurate de RDBMS nu sunt optimziate pentru cautare</li>
                        <li>ElasticSearch foloseste queries pe baza de <i>inverted index</i> pentru viteza </li>
                    </ul>

                    <hr/>
                    96. FALS: JAX-WS pot fi deployate si accesate din JAX endpoint-uri.

                    <hr/>
                    97. Există două moduri de operare disponibile pentru Elasticsearch: încorporat și la distanță.

                    <hr/>
                    98. Fazele de analiză:
                    <ul>
                        <li>fitrarea caracterelor</li>
                        <li>tokenizing</li>
                        <li>filtrare token</li>
                    </ul>

                    <hr/>
                    99. Metodele doSearch din ElasticsearchIndexSearcher de la Liferay returnează <b>SearchResonse</b>

                    <hr/>
                    100. Procesoarele Hit pot procesa rezultatele căutării din motorul de căutare înainte de a o trimite către front-end.
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TipsLiferayContent;