import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class CypressReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-cypress", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Testare E2E: Cypress</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install cypress --save-dev'}
                    </SyntaxHighlighter>

                    Pornire:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npx cypress open'}
                    </SyntaxHighlighter>
                    La prima pornire se poate alege:
                    <ul>
                        <li>E2E Testing (*)</li>
                        <li>Component Testing</li>
                    </ul>
                    Se genereaza fisiere de configurare:
                    <ul>
                        <li>cypress.config.ts</li>
                        <li>cypress/support/e2e.ts</li>
                        <li>cypress/support/commands.ts</li>
                        <li>cypress/fixtures/example.json</li>
                    </ul>
                    Se alege browser-ul:
                    <ul>
                        <li>
                            Chrome
                        </li>
                        <li>
                            Edge
                        </li>
                        <li>
                            Electron
                        </li>
                    </ul>

                    Testare (<i>cypress\e2e\App.cy.ts</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'describe(\'template spec\', () => {\n' +
                            '  it(\'passes\', () => {\n' +
                            '    cy.visit("http://localhost:3000")\n' +
                            '    cy.contains("dopamina") // verific daca in pagina exista textul "dopamina"\n' +
                            '    cy.wait(2000) // astept 2 secunde\n' +
                            '    cy.get(".card-title").contains("Java").click() // selectez un element cu clasa "card-title" care contine "Java" si simulez un clic\n' +
                            '  })\n' +
                            '})'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://duncanhunter.gitbook.io/testing-angular/"}>*/}
                    {/*           Testing Angular*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://codecraft.tv/courses/angular/unit-testing/asynchronous/"}>*/}
                    {/*            Testing Asynchronous Code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CypressReactContent;