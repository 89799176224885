import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class NanoLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-nano", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install nano'}
                    </SyntaxHighlighter>

                    Deschidere fisier (daca nu exista se creeaza):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nano <nume-fisier>'}
                    </SyntaxHighlighter>

                    Pentru salva si iesi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+X'}
                    </SyntaxHighlighter>

                    <hr/>
                    Stergere rand:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+K'}
                    </SyntaxHighlighter>

                    Afisare help:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+G'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'F1'}
                    </SyntaxHighlighter>

                    Cautare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+W'}
                    </SyntaxHighlighter>

                    Replace:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+\\'}
                    </SyntaxHighlighter>

                    Salvare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+O'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CTRL+S'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NanoLinuxContent;