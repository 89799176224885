import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class RmDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-rm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker rm / docker container rm</b>

                    <br/>
                    <br/>

                    Pentru a sterge un container (unul sau mai multe):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm nume_container'}
                    </SyntaxHighlighter>

                    Este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container rm nume_container'}
                    </SyntaxHighlighter>

                    Se pot sterge si mai multe containere dintr-o singura comanda folosind id-urile de container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm container_id_1 container_id_2 container_id_3'}
                    </SyntaxHighlighter>

                    Pentru a forta stergerea unui container, se foloseste optiunea <b>-f</b> (un container se poate sterge daca este oprit, optiune -f fortea oprirea containerului):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container rm nume_container -f'}
                    </SyntaxHighlighter>

                    Se poate specifica la rularea unui container sa se stearga in mod automat cand se termina, folosind optiunea <b>--rm</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -rm nume_container'}
                    </SyntaxHighlighter>

                    Pentru a sterge toate containere oprite (dangling):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container prune'}
                    </SyntaxHighlighter>

                    Pentru a sterge toate containerele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm $(docker ps -a -q) --force'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>-a : all / toate</li>
                        <li>
                            -q : quiet output; afiseaza doar ID-ul containerului
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RmDockerContent;