import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ThisJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-this", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    Daca o functie nested (care nu e <b>functie arrow</b>) este invocata ca functie,
                    atunci valoarea <b>this</b> din interior este:
                    <ul>
                        <li>obiectul global (pentru modul non-strict)</li>
                        <li>undefined (pentru modul strict)</li>
                    </ul>
                    Adica, this nu mai face referire la this din funcția părinte.
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'const obj = {\n' +
                            '  nume: \'Andrei\',\n' +
                            '  salut: function() {\n' +
                            '    console.log(this.nume); // Corect, `this` face referire la `obj`\n' +
                            '    \n' +
                            '    function funcNested() {\n' +
                            '      console.log(this.nume); // Problema, `this` acum referă obiectul global\n' +
                            '    }\n' +
                            '    \n' +
                            '    funcNested();\n' +
                            '  }\n' +
                            '};\n' +
                            '\n' +
                            'obj.salut();\n' +
                            '// Output:\n' +
                            '// Andrei\n' +
                            '// undefined (sau eroare în modul strict)'
                        }
                    </SyntaxHighlighter>

                    Modalități de a rezolva această problemă:
                    <ul>
                        <li>
                            1. Stocarea valorii lui this într-o variabilă:
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'const obj = {\n' +
                                    '  nume: \'Andrei\',\n' +
                                    '  salut: function() {\n' +
                                    '    const self = this; // Salvează `this` într-o variabilă\n' +
                                    '    \n' +
                                    '    function funcNested() {\n' +
                                    '      console.log(self.nume); // Folosește variabila `self` pentru a accesa `this` din funcția exterioară\n' +
                                    '    }\n' +
                                    '    \n' +
                                    '    funcNested();\n' +
                                    '  }\n' +
                                    '};\n' +
                                    '\n' +
                                    'obj.salut(); // Output: Andrei'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            2. Utilizarea funcțiilor săgeată (arrow functions):
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'const obj = {\n' +
                                    '  nume: \'Andrei\',\n' +
                                    '  salut: function() {\n' +
                                    '    const funcArrow = () => {\n' +
                                    '      console.log(this.nume); // `this` este moștenit din funcția `salut`\n' +
                                    '    };\n' +
                                    '    \n' +
                                    '    funcArrow();\n' +
                                    '  }\n' +
                                    '};\n' +
                                    '\n' +
                                    'obj.salut(); // Output: Andrei'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            3. Utilizarea metodei bind():
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'const obj = {\n' +
                                    '  nume: \'Andrei\',\n' +
                                    '  salut: function() {\n' +
                                    '    function funcNested() {\n' +
                                    '      console.log(this.nume); // `this` este legat de `obj`\n' +
                                    '    }\n' +
                                    '    \n' +
                                    '    const funcLegata = funcNested.bind(this);\n' +
                                    '    funcLegata();\n' +
                                    '  }\n' +
                                    '};\n' +
                                    '\n' +
                                    'obj.salut(); // Output: Andrei'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ThisJavaScriptContent;