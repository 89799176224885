import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class RedirectLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-save-action", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Apelere actiune</b>
                    <br/>
                    <br/>

                    Problema: vrem din <i>edit.jsp</i> la apasarea unui buton/link sa se apeleze o actiune dintr-o componenta.

                    <hr/>
                    Fie in <i>edit.jsp</i>, cu actiunea <b>saveForm</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<portlet:actionURL name="saveForm" var="saveFormURL">\n' +
                            '\n' +
                            '</portlet:actionURL>\n' +
                            '\n' +
                            '<div class="d-flex container toolbar">\n' +
                            '    <div class="p-2">\n' +
                            '        <a class="btn btn-primary btn-sm" href="${saveFormURL}">\n' +
                            '            <clay:icon symbol="disk" />\n' +
                            '        </a>\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>



                    Fie <b>BaseMVCActionCommand</b>, cu <b>mvc.command.name=saveForm</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.nomenclator.portlet;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.nomenclator.constants.IbridNomenclatorPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.*;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + IbridNomenclatorPortletKeys.IBRIDNOMENCLATOR,\n' +
                            '                "mvc.command.name=saveForm"\n' +
                            '        },\n' +
                            '        service = MVCActionCommand.class\n' +
                            ')\n' +
                            'public class SaveActionMvcCommand extends BaseMVCActionCommand {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                            '        System.out.println("save doProcessAction");\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<portlet:actionURL name="saveForm" var="saveFormURL">\n' +
                            '\n' +
                            '</portlet:actionURL>\n' +
                            '\n' +
                            '<div class="ibrid-nomenclator">\n' +
                            '    <div class="d-flex container toolbar">\n' +
                            '        <div class="p-2 font-weight-bold">\n' +
                            '            Create/Update Form\n' +
                            '        </div>\n' +
                            '    </div>\n' +
                            '\n' +
                            '    <aui:form name="fm" action="${saveFormURL}">\n' +
                            '        <aui:input name="code"></aui:input>\n' +
                            '        <aui:input name="name"></aui:input>\n' +
                            '        <aui:input name="description"></aui:input>\n' +
                            '        <aui:input name="collector" label="form.collector"></aui:input>\n' +
                            '        <aui:input name="store" label="form.store"></aui:input>\n' +
                            '\n' +
                            '        <div class="d-flex justify-content-end container toolbar p-2">\n' +
                            '            <aui:button-row>\n' +
                            '                <aui:button class="btn btn-primary btn-sm" type="submit"/>\n' +
                            '            </aui:button-row>\n' +
                            '        </div>\n' +
                            '\n' +
                            '    </aui:form>\n' +
                            '\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.nomenclator.portlet;\n' +
                            '\n' +
                            'import com.liferay.counter.kernel.service.CounterLocalServiceUtil;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.forms.servicebuilder.model.Form;\n' +
                            'import ro.ibrid.forms.servicebuilder.service.FormLocalServiceUtil;\n' +
                            'import ro.ibrid.forms.servicebuilder.service.FormService;\n' +
                            'import ro.ibrid.nomenclator.constants.IbridNomenclatorPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.ActionRequest;\n' +
                            'import javax.portlet.ActionResponse;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + IbridNomenclatorPortletKeys.IBRIDNOMENCLATOR,\n' +
                            '                "mvc.command.name=saveForm"\n' +
                            '        },\n' +
                            '        service = MVCActionCommand.class\n' +
                            ')\n' +
                            'public class SaveActionMvcCommand extends BaseMVCActionCommand {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                            '\n' +
                            '        String code = ParamUtil.get(actionRequest, "code", "");\n' +
                            '        String name = ParamUtil.get(actionRequest, "name", "");\n' +
                            '        String description = ParamUtil.get(actionRequest, "description", "");\n' +
                            '        String collector = ParamUtil.get(actionRequest, "collector", "");\n' +
                            '        String store = ParamUtil.get(actionRequest, "store", "");\n' +
                            '\n' +
                            '        Form form = FormLocalServiceUtil.createForm(CounterLocalServiceUtil.increment(Form.class.getName()));\n' +
                            '\n' +
                            '        form.setCode(code);\n' +
                            '        form.setName(name);\n' +
                            '        form.setDescription(description);\n' +
                            '        form.setCollector(collector);\n' +
                            '        form.setStore(store);\n' +
                            '\n' +
                            '        FormLocalServiceUtil.updateForm(form);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RedirectLiferayContent;