import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DecoratorsIIPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-decorators-ii", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    O clasa poate juca rolul unui decorator. Pentru a face acest lucru, trebuie sa avem 2 metode:
                    <ul>
                        <li><b>__init__()</b>: folosita pentru a atribui o referință catre funcția decorată la atributul <b>self.atribut</b> pentru o utilizare ulterioară</li>
                        <li><b>__call__()</b>: folosita pentru decorarea propriu-zisa, apeland functia decorata prin intemediul atributului <b>self.atribut</b></li>
                    </ul>

                    Reluam exemplul cu decoratorul pe baza de functie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(functie):\n' +
                        '\n' +
                        '    def internal_wrapper(*args, **kwargs):\n' +
                        '        print(\'== START===\')\n' +
                        '        print(\'functia {} este apelata cu argumentele\'.format(functie.__name__))\n' +
                        '        print("\\t in decorator:",args, kwargs)\n' +
                        '        functie(*args, **kwargs)\n' +
                        '        print(\'== STOP===\')\n' +
                        '\n' +
                        '    return internal_wrapper\n' +
                        '\n' +
                        '\n' +
                        '@decorator\n' +
                        'def metoda(*args, **kwargs):\n' +
                        '    print("\\t in metoda:", args, kwargs)\n' +
                        '\n' +
                        'metoda(\'a\', \'b\', c=\'c\')'}
                    </SyntaxHighlighter>

                    Acesta poate fi rescris cu clasa, in felul urmator:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Decorator:\n' +
                        '\n' +
                        '    def __init__(self, functie):\n' +
                        '        self.functie=functie\n' +
                        '\n' +
                        '    def __call__(self, *args, **kwargs):\n' +
                        '        print(\'== START===\')\n' +
                        '        print(\'functia {} este apelata cu argumentele\'.format(self.functie.__name__))\n' +
                        '        print("\\t in decorator:",args, kwargs)\n' +
                        '        self.functie(*args, **kwargs)\n' +
                        '        print(\'== STOP===\')\n' +
                        '\n' +
                        '\n' +
                        '@Decorator\n' +
                        'def metoda(*args, **kwargs):\n' +
                        '    print("\\t in metoda:", args, kwargs)\n' +
                        '\n' +
                        'metoda(\'a\', \'b\', c=\'c\')\n'}
                    </SyntaxHighlighter>

                    In ambele exemple, rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'== START===\n' +
                        'functia metoda este apelata cu argumentele\n' +
                        '\t in decorator: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '\t in metoda: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '== STOP==='}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Decoratori pe baza de clase cu argumente</b>
                    <br/>
                    <br/>

                    Când se transmite argumente decoratorului pe baza unei clase, atunci:
                    <ul>
                        <li>referința la funcția de decorat este trecută la metoda <b>__call__()</b> care este apelată o singură dată în timpul procesului de decorare</li>
                        <li>argumentele decoratorului sunt transmise metodei <b>__init__()</b></li>
                    </ul>

                    Reluam exemplul cu decoratorul pe baza de functie cu argumente:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(culoare):\n' +
                        '    \n' +
                        '    def wrapper(functie):\n' +
                        '    \n' +
                        '        def internal_wrapper(*args, **kwargs):\n' +
                        '            print(\'== START===\')\n' +
                        '            print(\'culoare=\',culoare)\n' +
                        '            print(\'functia {} este apelata cu argumentele\'.format(functie.__name__))\n' +
                        '            print("\\t in decorator:",args, kwargs)\n' +
                        '            functie(*args, **kwargs)\n' +
                        '            print(\'== STOP===\')\n' +
                        '    \n' +
                        '        return internal_wrapper\n' +
                        '\n' +
                        '    return wrapper\n' +
                        '\n' +
                        '@decorator("verde")\n' +
                        'def metoda_1(*args, **kwargs):\n' +
                        '    print("\\t in metoda_1:", args, kwargs)\n' +
                        '    \n' +
                        '\n' +
                        '@decorator("albastru")\n' +
                        'def metoda_2(*args, **kwargs):\n' +
                        '    print("\\t in metoda_2:", args, kwargs)\n' +
                        '    \n' +
                        '\n' +
                        'metoda_1(\'a\', \'b\', c=\'c\')\n' +
                        'metoda_2(\'a\', \'b\', c=\'c\')'}
                    </SyntaxHighlighter>

                    Acesta poate fi rescris cu clasa, in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Decorator:\n' +
                        '    \n' +
                        '    def __init__(self, culoare):\n' +
                        '        self.culoare=culoare\n' +
                        '        \n' +
                        '    def __call__(self, functie):\n' +
                        '    \n' +
                        '        def internal_wrapper(*args, **kwargs):\n' +
                        '            print(\'== START===\')\n' +
                        '            print(\'culoare=\',self.culoare)\n' +
                        '            print(\'functia {} este apelata cu argumentele\'.format(functie.__name__))\n' +
                        '            print("\\t in decorator:",args, kwargs)\n' +
                        '            functie(*args, **kwargs)\n' +
                        '            print(\'== STOP===\')\n' +
                        '    \n' +
                        '        return internal_wrapper\n' +
                        '\n' +
                        '\n' +
                        '@Decorator("verde")\n' +
                        'def metoda_1(*args, **kwargs):\n' +
                        '    print("\\t in metoda_1:", args, kwargs)\n' +
                        '    \n' +
                        '\n' +
                        '@Decorator("albastru")\n' +
                        'def metoda_2(*args, **kwargs):\n' +
                        '    print("\\t in metoda_2:", args, kwargs)\n' +
                        '    \n' +
                        '\n' +
                        'metoda_1(\'a\', \'b\', c=\'c\')\n' +
                        'metoda_2(\'a\', \'b\', c=\'c\')'}
                    </SyntaxHighlighter>

                    In ambele exemple, rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'== START===\n' +
                        'culoare= verde\n' +
                        'functia metoda_1 este apelata cu argumentele\n' +
                        '\t in decorator: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '\t in metoda_1: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '== STOP===\n' +
                        '== START===\n' +
                        'culoare= albastru\n' +
                        'functia metoda_2 este apelata cu argumentele\n' +
                        '\t in decorator: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '\t in metoda_2: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '== STOP==='}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DecoratorsIIPythonContent;