import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers6LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-6", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Continuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Definire Master Pages</b>
                    <br/>
                    <br/>

                    Master Pages în Liferay sunt șabloane utilizate pentru a controla structura și aspectul global al paginilor într-un portal Liferay.
                    Acestea furnizează un cadru pentru a defini aspectul comun și structura comună a tuturor paginilor din portalul Liferay,
                    astfel încât să se mențină o coerență vizuală în întregul portal.
                    <br/>
                    <br/>

                    Avem 2 Master pages
                    <ul>
                        <li>IBRID_DEFAULT (pentru pagina de welcome si pagina de search)</li>
                        <li>IBIRD_LEFT_NAV (pentru alte pagini care au navigare în stânga)</li>
                    </ul>

                    Vom avea urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-initializer\n' +
                            '└── layout-page-templates\n' +
                            '    └── master-pages\n' +
                            '         ├── ibrid-default\n' +
                            '         │   ├── master-page.json\n' +
                            '         │   ├── page-definition.json\n' +
                            '         │   └── thumbnail.png\n' +
                            '         └── ibrid-left-nav\n' +
                            '             ├── master-page.json\n' +
                            '             ├── page-definition.json\n' +
                            '             └── thumbnail.png'}
                    </SyntaxHighlighter>

                    In fisierul <b>master-page.json</b> se defineste numele pentru Master Page
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '   "name": "IBRID_DEFAULT"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In fisierul <b>page-definition.json</b> se defineste structura pentru Master page (mai multe in sectiunea <b>Page Content</b>).
                    <br/>
                    Deocamdata, este de ajuns ca Master page sa contina doar element de tip <i>DropZone</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "pageElement": {\n' +
                            '    "pageElements": [\n' +
                            '      {\n' +
                            '        "definition": {\n' +
                            '          "fragmentSettings": {\n' +
                            '            "unallowedFragments": []\n' +
                            '          }\n' +
                            '        },\n' +
                            '        "type": "DropZone"\n' +
                            '      }\n' +
                            '    ],\n' +
                            '    "type": "Root"\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_30.png'}/>
                    </div>

                    <hr/>
                    <b>2. Definire Pages Templates</b>
                    <br/>

                    Se poate creea template pentru pagini (<b>Page Template</b>) pentru a simplifica crearea de pagini individuale pe site.
                    <br/>
                    Page Templates (Șabloane de Pagină) în Liferay sunt utilizate pentru a defini structura și dispunerea conținutului pe o pagină web.
                    <br/>

                    Definițiile șabloanelor de pagină (<b>Page Template</b>)  sunt  plasate tot in directorul <b>layout-page-templates</b> (ca si paginile master), dar în directorul <b>page-templates</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-initializer\n' +
                            '└── layout-page-templates\n' +
                            '    └── page-templates\n' +
                            '         ├── ibrid-chapter-template\n' +
                            '         │   ├── page-definition.json\n' +
                            '         │   └── page-template.json\n' +
                            '         ├── ibrid-content-template\n' +
                            '         │   ├── page-definition.json\n' +
                            '         │   └── page-template.json\n' +
                            '         ├── ...'}
                    </SyntaxHighlighter>

                    Fisierul <b>page-template.json</b> defineste numele paginii template:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "name": "IBRID Chapter"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>page-definition.json</b> defineste structura pentru Page Templates (mai multe in sectiunea <b>Page Content</b>)

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_31.png'}/>
                    </div>

                    <hr/>
                    <b>3. Definirea paginilor / Pages</b>
                    <br/>
                    <br/>

                    Pentru a defini paginile (Aspecte/Layout) trebuie să creăm descriptori de pagină în interiorul directorului <b>site-initializer/layouts</b>:

                    <br/>
                    Numele folderelor (1_welcome, 2_home, etc.) nu contează, dar definesc ordinea și imbricarea paginilor site-ului.
                    <br/>
                    Fisierul <b>page.json</b> defineste informatii despre o pagina:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "name": "Home",\n' +
                            '  "private": false,\n' +
                            '  "type": "Content"\n' +
                            '}'}
                    </SyntaxHighlighter>
                    (versiunea de mai jos nu mi-a mers)
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{ \n' +
                            '   "hidden": false, \n' +
                            '   "private": false, \n' +
                            '   "name_i18n": { \n' +
                            '      "en_US": "Welcome" \n' +
                            '   }, \n' +
                            '   "friendlyURL_i18n": { \n' +
                            '      "en_US": "/welcome" \n' +
                            '   }, \n' +
                            '   "system": false, \n' +
                            '   "type ": "conținut", \n' +
                            '   "typeSettings": [ \n' +
                            '      { \n' +
                            '         "key": "collectionType", \n' +
                            '         "value": "com.liferay.item.selector.criteria.InfoListItemSelectorReturnType" \n' +
                            '      } \n' +
                            '   ] \n' +
                            '}'}
                    </SyntaxHighlighter>
                    Putem defini:
                    <ul>
                        <li>
                            dacă o pagină este ascunsă sau nu
                        </li>
                        <li>
                            dacă este publică sau privată
                        </li>
                        <li>
                            tipul paginii
                        </li>
                        <li>
                            numele localizate
                        </li>
                        <li>
                            URL-urile prietenoase
                        </li>
                    </ul>

                    Fisierul <b>page-definition.json</b> definește structura șablonului de pagină (mai multe in sectiunea <b>Page Content</b>

                    <hr/>
                    <b>4. Definire Page Content / Continut pagina</b>
                    <br/>
                    <br/>

                    Definirea conținutului paginii este cea mai dificilă parte. Aceste se definesc in <b>page-definition.json</b>.
                    <br/>
                    Si nu se definesc de mână, pentru că ar fi un coșmar.
                    <br/>
                    Din fericire, există un <i>truc magic</i>, pe care îl putem folosi pentru definirea conținutului paginii.
                    Trebuie să urmăm următorii pași:
                    <ul>
                        <li>1. creare conținut de pagină in UI
                            <br/>
                            Prima dată trebuie să creăm conținutul manual
                        </li>
                        <li>
                            2. se salveaza compoziția fragmentului

                            <br/>
                            <b>Save Composition</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_32.png'}/>
                            </div>

                            Se bifeaza <b>Save Inline Content</b> si <b>Save Mapping Configuration and Link</b> si se apasa pe butonul <b>Save</b>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_34.png'}/>
                            </div>
                            Sa prespunem ca am salvat cu numele <i>IBRID_TEST</i>. Se sa vedea in sectiune de fragmente:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_35.png'}/>
                            </div>
                        </li>
                        <li>
                            3. se exporta fragment-ul
                            <br/>
                            se extrag fișierele din arhivă și se deschide fișierul  <b>fragment-composition-definition.json</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_36.png'}/>
                            </div>
                        </li>
                        <li>
                            4. actualizare definiția paginii
                            <br/>
                            Conținutul din fișierul exportat  <b>fragment-composition-definition.json</b> se pune în fișierul corespunzător <b>page-definition.json</b> din modulul <b>site-initialzer</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_37.png'}/>
                            </div>

                            <SyntaxHighlighter>
                                {'{\n' +
                                    '  "pageElement": {\n' +
                                    '    "pageElements": [\n' +
                                    '      // AICI !!! \n' +
                                    '    ],\n' +
                                    '    "type": "Root"\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers6LiferayContent;