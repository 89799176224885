import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class BasicAuthNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-basic-auth", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Basic Auth</b>
                    <br/>
                    <br/>

                    Prerechizite:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get install apache2-utils'}
                    </SyntaxHighlighter>
                    sau pe CentOS:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum install httpd-tools'}
                    </SyntaxHighlighter>

                    Generare parola (parola pentru utilizatorul <i>user1</i>; parola impreuna cu numele utilzatorului se vor scrie in fisierul <i>/etc/nginx/.htpasswd</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'htpasswd -c /etc/nginx/.htpasswd user1'}
                    </SyntaxHighlighter>

                    Pentru a proteja o anumita <i>location</i> cu parola:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'location / {\n' +
                            '\tauth_basic "Secure Area";\n' +
                            '\tauth_basic_user_file /etc/ngnix/.htpasswd\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                    Cand vom incerca sa intra pe <i>http://IP/</i> se va afisa un mesaj intr-o fereastra dialog,
                    care va contine textul: <b>Secure Area</b> (specificat in <i>auth_basic "Secure Area"</i>)


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BasicAuthNginxContent;