import {useRoutes} from "react-router-dom";
import React from "react";
import VideografieHomeContent from "./IndexContent";
import PrivateRoute from "../../security/PrivateRoute";
import ContrastVideografieContent from "./ContrastVideografieContent";
import ExpunereVideografieContent from "./ExpunereVideografieContent";
import ObiectiveVideografieContent from "./ObiectiveVideografieContent";
import IncadrariVideografieContent from "./IncadrariVideografieContent";
import PunctStatieVideografieContent from "./PunctStatieVideografieContent";
import FiltreVideografieContent from "./FiltreVideografieContent";
import HistogramaVideografieContent from "./HistogramaVideografieContent";
import ZebraVideografieContent from "./ZebraVideografieContent";
import FocusVideografieContent from "./FocusVideografieContent";
import MiscareCameraVideografieContent from "./MiscareCameraVideografieContent";
import PunctuatieVideografieContent from "./PunctuatieVideografieContent";
import IluminareVideografieContent from "./IluminareVideografieContent";
import ZoneSystemVideografieContent from "./ZoneSystemVideografieContent";
import NLogVideografieContent from "./NLogVideografieContent";
import SpatiuDeCuloareVideografieContent from "./SpatiuDeCuloareVideografieContent";
import LuminantaIluminareVideografieContent from "./LuminantaIluminareVideografieContent";
import ColorSamplingVideografieContent from "./ColorSamplingVideografieContent";
import IstorieVideografieContent from "./IstorieVideografieContent";
import UtilsVideografieContent from "./UtilsVideografieContent";
import EchipamentVideografieContent from "./EchipamentVideografieContent";
import Z6SettingsVideografieContent from "./Z6SettingsVideografieContent";
import RecapitulareVideografieContent from "./RecapitulareVideografieContent";
import EditareVideoIntroducereContent from "./EditareVideoIntroducereContent";
import BinPremiereProContent from "./BinPremiereProContent";
import ShortcutPremireVideografieContent from "./ShortcutPremireVideografieContent";
import AddSubtitlePremierProContent from "./AddSubtitlePremierProContent";
import EditareVideoRecapitulareContent from "./EditareVideoRecapitulareContent";

{/*videografie*/}
/*
<Route path="/videografie/index" element={<VideografieHomeContent/>} exact/>
<Route element={<PrivateRoutes/>}>
    <Route path="/videografie/contrast" element={<ContrastVideografieContent/>} exact/>
    <Route path="/videografie/expunere" element={<ExpunereVideografieContent/>} exact/>
    <Route path="/videografie/obiective" element={<ObiectiveVideografieContent/>} exact/>
    <Route path="/videografie/incadrari" element={<IncadrariVideografieContent/>} exact/>
    <Route path="/videografie/puncte-de-statie" element={<PunctStatieVideografieContent/>}
           exact/>
    <Route path="/videografie/filtre" element={<FiltreVideografieContent/>} exact/>
    <Route path="/videografie/histograma" element={<HistogramaVideografieContent/>} exact/>
    <Route path="/videografie/zebra" element={<ZebraVideografieContent/>} exact/>
    <Route path="/videografie/focus" element={<FocusVideografieContent/>} exact/>
    <Route path="/videografie/move-camera" element={<MiscareCameraVideografieContent/>}
           exact/>
    <Route path="/videografie/punctuatie" element={<PunctuatieVideografieContent/>} exact/>
    <Route path="/videografie/iluminare" element={<IluminareVideografieContent/>} exact/>
    <Route path="/videografie/zone-system" element={<ZoneSystemVideografieContent/>} exact/>
    <Route path="/videografie/nlog" element={<NLogVideografieContent/>} exact/>
    <Route path="/videografie/spatiu-culoare" element={<SpatiuDeCuloareVideografieContent/>}
           exact/>
    <Route path="/videografie/comparatie-luminanta-vs-iluminare"
           element={<LuminantaIluminareVideografieContent/>} exact/>
    <Route path="/videografie/color-sampling" element={<ColorSamplingVideografieContent/>}
           exact/>

    <Route path="/videografie/istorie" element={<IstorieVideografieContent/>} exact/>
    <Route path="/videografie/utils" element={<UtilsVideografieContent/>} exact/>
    <Route path="/videografie/echipament" element={<EchipamentVideografieContent/>} exact/>
    <Route path="/videografie/z6-settings" element={<Z6SettingsVideografieContent/>} exact/>
    <Route path="/videografie/recapitulare" element={<RecapitulareVideografieContent/>}
           exact/>

    <Route path="/videografie/premiere-pro/introducere"
           element={<EditareVideoIntroducereContent/>} exact/>
    <Route path="/videografie/premiere-pro/organizer" element={<BinPremiereProContent/>}
           exact/>
    <Route path="/videografie/premiere-pro/shortcut"
           element={<ShortcutPremireVideografieContent/>} exact/>
    <Route path="/videografie/premiere-pro/add-subtitle"
           element={<AddSubtitlePremierProContent/>} exact/>
    <Route path="/videografie/premiere-pro/recapitulare"
           element={<EditareVideoRecapitulareContent/>} exact/>

</Route>
*/
export default function Router() {
    let element = useRoutes([
        {path: "/videografie/index", element: <VideografieHomeContent/>},
        {path: "/videografie/contrast", element: <PrivateRoute element={<ContrastVideografieContent/>}/>},
        {path: "/videografie/expunere", element: <PrivateRoute element={<ExpunereVideografieContent/>}/>},
        {path: "/videografie/obiective", element: <PrivateRoute element={<ObiectiveVideografieContent/>}/>},
        {path: "videografie/incadrari", element: <PrivateRoute element={<IncadrariVideografieContent/>}/>},
        {path: "videografie/puncte-de-statie", element: <PrivateRoute element={<PunctStatieVideografieContent/>}/>},
        {path: "videografie/filtre", element: <PrivateRoute element={<FiltreVideografieContent/>} exact/>},
        {path: "videografie/histograma", element: <PrivateRoute element={<HistogramaVideografieContent/>} exact/>},
        {path: "videografie/zebra", element: <PrivateRoute element={<ZebraVideografieContent/>} exact/>},
        {path: "videografie/focus", element: <PrivateRoute element={<FocusVideografieContent/>} exact/>},
        {path: "videografie/move-camera", element: <PrivateRoute element={<MiscareCameraVideografieContent/>}/>},
        {path: "videografie/punctuatie", element: <PrivateRoute element={<PunctuatieVideografieContent/>}/>},
        {path: "videografie/iluminare", element: <PrivateRoute element={<IluminareVideografieContent/>}/>},
        {path: "videografie/zone-system", element: <PrivateRoute element={<ZoneSystemVideografieContent/>}/>},
        {path: "videografie/nlog", element: <PrivateRoute element={<NLogVideografieContent/>}/>},
        {path: "videografie/spatiu-culoare", element: <PrivateRoute element={<SpatiuDeCuloareVideografieContent/>}/>},
        {path: "videografie/comparatie-luminanta-vs-iluminare", element: <PrivateRoute element={<LuminantaIluminareVideografieContent/>}/>},
        {path: "videografie/color-sampling", element: <PrivateRoute element={<ColorSamplingVideografieContent/>}/>},
        {path: "videografie/istorie", element: <PrivateRoute element={<IstorieVideografieContent/>}/>},
        {path: "videografie/utils", element: <PrivateRoute element={<UtilsVideografieContent/>}/>},
        {path: "videografie/echipament", element: <PrivateRoute element={<EchipamentVideografieContent/>}/>},
        {path: "videografie/z6-settings", element: <PrivateRoute element={<Z6SettingsVideografieContent/>}/>},
        {path: "videografie/recapitulare", element: <PrivateRoute element={<RecapitulareVideografieContent/>}/>},
        {path: "videografie/premiere-pro/introducere", element: <PrivateRoute element={<EditareVideoIntroducereContent/>}/>},
        {path: "videografie/premiere-pro/organizer", element: <PrivateRoute element={<BinPremiereProContent/>}/>},
        {path: "videografie/premiere-pro/shortcut", element: <PrivateRoute element={<ShortcutPremireVideografieContent/>}/>},
        {path: "videografie/premiere-pro/add-subtitle", element: <PrivateRoute element={<AddSubtitlePremierProContent/>}/>},
        {path: "videografie/premiere-pro/recapitulare", element: <PrivateRoute element={<EditareVideoRecapitulareContent/>}/>},
    ]);

    return element;
}