import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class TestsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-tests", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere in testare</b>
                    <br/>
                    <br/>

                    Observatii:
                    <ul>
                        <li>fiecate bloc de test <b>it</b>  ruleza in mod independent</li>
                        <li>inainte de fiecare bloc de <b>it</b> se ruleaza blocul <b>beforeEach</b></li>
                    </ul>

                    Testare platforma de testare, folosind <b>expect</b> si <b>toBeTruthy</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'describe(\'App Component\', () => {\n' +
                            '\tit(\'ar trebui sa treaca\', ()=>{\n' +
                            '\t  expect(true).toBeTruthy();\n' +
                            '\t});\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Test (ca 1 + 1 = 2), folosinf <b>toEqual</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'describe(`Component: App Component`, () => {\n' +
                            '  it(\'add 1+1 - PASS\', () => {\n' +
                            '    expect(1 + 1).toEqual(2);\n' +
                            '  });\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        2. Testare proprietate
                    </b>
                    <br/>
                    <br/>

                    Fie componenta <i>AppComponent</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class AppComponent implements OnInit {\n' +
                            '  title = \'testing\';\n' +
                            '  ...\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Testare <i>propriete</i> pentru componeta de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' it(`title equals \'Angular Superpowers\'`, () => {\n' +
                            '    const component = new AppComponent();\n' +
                            '    expect(component.title).toEqual(\'testing\');\n' +
                            '  });'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Testare cu TestBed si Fixture</b>
                    <br/>
                    <br/>

                    Testare componenta:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {TestBed, ComponentFixture} from \'@anguar/core/testing\';\n' +
                            '\n' +
                            'import { AboutComponent } from \'./about.component\';\n' +
                            '\n' +
                            '\n' +
                            'describe(\'About Component\', ()=>{\n' +
                            '\n' +
                            '\tlet fixture: ComponentFixture<AboutComponent>;\n' +
                            '\tlet component: AboutComponent;\n' +
                            '\n' +
                            '\tbeforeEach( aync()=> {\n' +
                            '\t\n' +
                            '\t\tTestBed.configureTestingModule({\n' +
                            '\t\t\n' +
                            '\t\t\tdeclerations: [AboutComponent],\n' +
                            '\t\t\t\n' +
                            '\t\t}).compileComponents();\n' +
                            '\t\n' +
                            '\t})\n' +
                            '\t\n' +
                            '\tbeforeEach(()=> {\n' +
                            '\t\tfixture = TestBed.createComponent(AboutComponent);\n' +
                            '\t\tcomponent = fixture.componentInstance;\n' +
                            '\t\tfixture.detectChanges();\n' +
                            '\t\n' +
                            '\t});\n' +
                            '\t\n' +
                            '\tit(\'testare - instanta AboutComponent\', ()=>{\n' +
                            '\t\texpect(component).toBeTruthy();\n' +
                            '\t})\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        4. Testare logica componenta folosind un un serviciu simulat (mocking)
                    </b>
                    <br/>

                    <br/>
                    <b>Mocking</b> = procesul prin care se creaza dependinte false/fake.
                    <br/>

                    Fie interfata:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export interface Name {\n' +
                            '  name: string;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fie serviciul:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Injectable } from \'@angular/core\';\n' +
                            'import { of, Observable } from \'rxjs\';\n' +
                            '\n' +
                            'import { Name } from \'./app.models\';\n' +
                            '\n' +
                            '@Injectable({\n' +
                            '  providedIn: \'root\'\n' +
                            '})\n' +
                            'export class AppService {\n' +
                            '  constructor() {}\n' +
                            '\n' +
                            '  getCompanies(): Observable<Name[]> {\n' +
                            '    return of([\n' +
                            '      { name: \'Duncan\' },\n' +
                            '      { name: \'Sarah\' }\n' +
                            '    ]);\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fie componenta care foloseste serviciul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, OnInit } from \'@angular/core\';\n' +
                            'import { Observable } from \'rxjs\';\n' +
                            '\n' +
                            'import { AppService } from \'./app.service\';\n' +
                            'import { Name } from \'./app.models\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'app-root\',\n' +
                            '  templateUrl: \'./app.component.html\',\n' +
                            '  styleUrls: [\'./app.component.css\']\n' +
                            '})\n' +
                            'export class AppComponent implements OnInit{\n' +
                            '  title = \'testing\';\n' +
                            '  names$: Observable<Name[]>;\n' +
                            '\n' +
                            '  constructor(private appService: AppService) {}\n' +
                            '\n' +
                            '  ngOnInit(): void {\n' +
                            '    this.getNames();\n' +
                            '  }\n' +
                            '\n' +
                            '  getNames() {\n' +
                            '    this.names$ = this.appService.getCompanies();\n' +
                            '  }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { of } from \'rxjs\';\n' +
                            '\n' +
                            'import { AppComponent } from \'./app.component\';\n' +
                            'import { AppService } from \'./app.service\';\n' +
                            'import { Name } from \'./app.models\';\n' +
                            '\n' +
                            'describe(`Component: App Component`, () => {\n' +
                            '  let appService: AppService;\n' +
                            '  let component: AppComponent;\n' +
                            '\n' +
                            '  beforeEach(() => {\n' +
                            '    appService = { getCompanies: () => of([{ name: \'FAKE_NAME\' }]) };// fake/mock service\n' +
                            '    component = new AppComponent(appService);\n' +
                            '  });\n' +
                            '\n' +
                            '  it(`companyCount = 1`, () => {\n' +
                            '    component.ngOnInit();\n' +
                            '    component.names$.subscribe((names: Name[]) => {\n' +
                            '      expect(names).toEqual([{ name: \'FAKE_NAME\' }]);\n' +
                            '    });\n' +
                            '  });\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Testare logica componenta folosind SpyOn</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { of } from \'rxjs\';\n' +
                            '\n' +
                            'import { AppComponent } from \'./app.component\';\n' +
                            'import { AppService } from \'./app.service\';\n' +
                            'import { Name } from \'./app.models\';\n' +
                            '\n' +
                            'describe(`Component: App Component`, () => {\n' +
                            '  let appService: AppService;\n' +
                            '  let component: AppComponent;\n' +
                            '\n' +
                            '  beforeEach(() => {\n' +
                            '    appService = { getCompanies: () => of([]) };\n' +
                            '    component = new AppComponent(appService);\n' +
                            '  });\n' +
                            '\n' +
                            '  it(`Expect service to return a single name`, () => {\n' +
                            '    component.ngOnInit();\n' +
                            '    component.names$.subscribe((names: Name[]) => {\n' +
                            '      expect(names).toEqual([]);\n' +
                            '    });\n' +
                            '  });\n' +
                            '\n' +
                            '  it(`Expect service to return a single name`, () => {\n' +
                            '    const fakeNames = [{ name: \'FAKE_NAME\' }];\n' +
                            '    spyOn(appService, \'getCompanies\').and.returnValue(of(fakeNames));\n' +
                            '    component.ngOnInit();\n' +
                            '\n' +
                            '    component.names$.subscribe((names: Name[]) => {\n' +
                            '      expect(names).toEqual(fakeNames);\n' +
                            '    });\n' +
                            '  });\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alt exemplu de mock, folosind <i>jasmine</i>:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const mockAuthService = jasmine.createSpyObj("AuthService",["createUser", "logout"], {isAuthenticated$: of(true)})'}
                    </SyntaxHighlighter>
                    apoi in <b>beforeEach</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'beforeEach( aync()=> {\n' +
                            '\n' +
                            '\tTestBed.configureTestingModule({\n' +
                            '\t\n' +
                            '\t\tdeclerations: [AboutComponent],\n' +
                            '\t\tproviders: [{ provide: AuthService, useValue: mockAuthService}] // <!-- AICI\n' +
                            '\t\t\n' +
                            '\t}).compileComponents();\n' +
                            '\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Pentru a evita erori in consola din cauza routing, trebuie sa se importe <b>RouterTestingModule</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'beforeEach( aync()=> {\n' +
                            '\n' +
                            '\tTestBed.configureTestingModule({\n' +
                            '\t\n' +
                            '\t\tdeclerations: [AboutComponent],\n' +
                            '\t\tproviders: [{ provide: AuthService, useValue: mockAuthService}],\n' +
                            '\t\timports: [RouterTestingModule]\n // AICI' +
                            '\t\t\n' +
                            '\t}).compileComponents();\n' +
                            '\n' +
                            '})'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Testare cod asincron cu: Async si whenStable</b>
                    <br/>
                    <br/>

                    Metoda <b>whenStable</b> permite testarea de metode asincrone (atunci cand vine raspunsul)
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'it(\'Button label via async() and whenStable()\', async(() => { // <-- async\n' +
                            '  fixture.detectChanges();\n' +
                            '  expect(el.nativeElement.textContent.trim()).toBe(\'Login\');\n' +
                            '  spyOn(authService, \'isAuthenticated\').and.returnValue(Promise.resolve(true));\n' +
                            '  fixture.whenStable().then(() => { // <-- whenStable\n' +
                            '    fixture.detectChanges();\n' +
                            '    expect(el.nativeElement.textContent.trim()).toBe(\'Logout\');\n' +
                            '  });\n' +
                            '  component.ngOnInit();\n' +
                            '}));'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Testare cod asincron cu: fakeAsync si tick</b>
                    <br/>
                    <br/>

                    Metoda <b>whenStable</b> permite testarea de metode asincrone (atunci cand vine raspunsul)
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'it(\'Button label via async() and whenStable()\', fakeAsync(() => { //<--- fakeAsync\n' +
                            '  fixture.detectChanges();\n' +
                            '  expect(el.nativeElement.textContent.trim()).toBe(\'Login\');\n' +
                            '  spyOn(authService, \'isAuthenticated\').and.returnValue(Promise.resolve(true));\n' +

                            '  fixture.detectChanges();\n' +
                            '  tick(); //<-- tick\n' +
                            '  expect(el.nativeElement.textContent.trim()).toBe(\'Logout\');\n' +

                            '  component.ngOnInit();\n' +
                            '}));'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Simulare evenimente DOM</b>
                    <br/>
                    <br/>
                    Se foloseste <b>triggerEventHandler</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const logoutLink = fixture.debugElement.query(By.css("li:nth-child(3) a"));\n' +
                            'logoutLink.triggerEventHandler("click");\n' +
                            '\n' +
                            'const service = TestBed.inject(AuthService);\n' +
                            'expect(service.logout).toHaveBeenCalledTimes(1);'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>Anexa</b>
                    <br/>
                    <br/>
                    Selectare element dupa clasa css:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const element1 = fixture.debugElement.query(By.css(".hidden")); // varianta recomandata\n' +
                            'const element2 = fixture.nativeElement.querySelector(".hidden");\n' +
                            'const element3 = document.querySelector(".hidden")'}
                    </SyntaxHighlighter>
                    Selectare elemente (<i>li</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                         {'const element = fixture.debugElement.queryAll(By.css("li"));'}
                    </SyntaxHighlighter>
                    sau (By.directive):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const element = fixture.debugElement.query(By.directive(TabComponent));\n' +
                            'const tabsProps = element.componentInstance.tabs'}
                    </SyntaxHighlighter>

                    Verificari:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'expect(element1).toBeTruthy()'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'expect(element1).toBeFalsy()'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'expect(element1).not.toBeFalsy()'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'expect(1+1).toBe(2)'}
                    </SyntaxHighlighter>

                    Adaugare mesaj in caz de eroare ():
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'expect(1+1).withContext("ceva ai gresit!").toBe(2)'}
                    </SyntaxHighlighter>

                    Daca modificam ceva in componenta trebuie:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'fixture.detectChanges()'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'component.active=true' +
                            'fixture.detectChanges()'}
                    </SyntaxHighlighter>

                    {/*Rulare teste:*/}
                    {/*<SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>*/}
                    {/*    {'npm run test'}*/}
                    {/*</SyntaxHighlighter>*/}

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://duncanhunter.gitbook.io/testing-angular/"}>
                               Testing Angular
                           </a>
                       </li>
                        <li>
                            <a target={"_blank"} href={"https://codecraft.tv/courses/angular/unit-testing/asynchronous/"}>
                                Testing Asynchronous Code
                            </a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TestsAngularContent;