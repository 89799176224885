import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GoRootGoPathGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-goroot-gopath", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. GOROOT</b>
                    <br/>
                    <br/>

                    <b>GOROOT</b> =  variabilă care definește locul în care se află SDK-ul de Go

                    <hr/>
                    <b>2. GOPATH</b>
                    <br/>
                    <br/>

                    <b>GOPATH</b> =  variabilă care definește rădăcina spațiului de lucru;
                    <br/>
                    În mod implicit, directorul spațiului de lucru este un director care este numit <b>go</b> în directorul de pornire al utilizatorului:
                    <ul>
                        <li>
                            ~go - Linux / MacOS
                        </li>
                        <li>
                            %USERPROFILES%/go - Windows
                        </li>
                    </ul>

                    Dar poate fi si alta locatie, de exemplu: C:\work\go

                    <br/>
                    Aceasta locatia, va conține următoarele foldere:
                    <ul>
                        <li><b>/src</b>: locatia codului sursa Go (de exemplu: .go)</li>
                        <li><b>/pkg</b>: locatia codului pachetelor compilate (de exemplu: .a)</li>
                        <li>
                            <b>/bin</b>: locatia programelor executabile compilate si construite cu Go
                        </li>
                    </ul>

                    Se poate verifica cu urmatoarea comanda, ce variabile de mediu folosete <b>go</b>:
                    <SyntaxHighlighter>
                        {'go env'}
                    </SyntaxHighlighter>
                    poate afisa (fragment):
                    <SyntaxHighlighter>
                        {'set GOROOT=D:\\Program Files\\Go\n' +
                            'set GOPATH=C:\\Users\\buzdu\\go'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GoRootGoPathGoContent;