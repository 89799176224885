import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FooterThemeContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-footer-theme", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Vom modifica <b>footer-ul</b> aplicatiei, tinand cont si de variabila <b>show_footer</b>, in felul urmator:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<#if show_footer>\n' +
                        '\n' +
                        '   <footer id="footer" class="mt-auto" role="contentinfo">\n' +
                        '      <div class="container">        \n' +
                        '         <div class="d-flex justify-content-between">\n' +
                        '           Vanilla\n' +
                        '         </div>\n' +
                        '      </div>\n' +
                        '   </footer>\n' +
                        '\n' +
                        '</#if>'}
                    </SyntaxHighlighter>

                    Pentru a pastra footer-ul in josul paginii adaugam la elementul cu id-ul <b>wrapper</b> urmatoarele clase CSS:<br/>
                    <b>d-flex flex-column min-vh-100</b>
                    <br/> <br/>
                    Codul SCSS folosit pentru footer:
                    <SyntaxHighlighter  showLineNumbers={true} language="css" style={androidstudio}>
                        {'$base-color: #2d3142;\n' +
                        '\n' +
                        'footer{\n' +
                        '  background: $base-color;\n' +
                        '  color:white;\n' +
                        '  margin-right: -15px;\n' +
                        '  margin-left:-15px;\n' +
                        '  padding: 10px;\n' +
                        '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360028833812-Theme-Anatomy-Reference-Guide"}>
                                    Theme Anatomy Reference Guide
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://radu.link/make-footer-stay-bottom-page-bootstrap/"}>
                                    How to Make Footer Stay at Bottom of Page with Bootstrap
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FooterThemeContent;