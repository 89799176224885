import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class Ex1k8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-ex1", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 1: simplek8s (partea I-a : Local)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir simplek8s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd simplek8s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano client-pod.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: client-pod\n' +
                            '  labels:\n' +
                            '    component: web\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '    - name: client\n' +
                            '      image: iulianbuzdugan/multi-client\n' +
                            '      ports:\n' +
                            '        - containerPort: 3000'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano client-node-port.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: client-node-port\n' +
                            'spec:\n' +
                            '  type: NodePort\n' +
                            '  ports:\n' +
                            '    - port: 3050\n' +
                            '      targetPort: 3000\n' +
                            '      nodePort: 31515\n' +
                            '  selector:\n' +
                            '    component: web'}
                    </SyntaxHighlighter>

                    Rulare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f client-pod.yaml'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'pod/client-pod created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f client-node-port.yaml'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'service/client-node-port created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'NAME         READY   STATUS              RESTARTS   AGE\n' +
                            'client-pod   0/1     ContainerCreating   0          75s'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter>
                        {'NAME         READY   STATUS    RESTARTS   AGE\n' +
                            'client-pod   1/1     Running   0          94s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get services'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'NAME               TYPE        CLUSTER-IP      EXTERNAL-IP   PORT(S)          AGE\n' +
                            'client-node-port   NodePort    10.110.64.209   <none>        3050:31515/TCP   59s\n' +
                            'kubernetes         ClusterIP   10.96.0.1       <none>        443/TCP          2d10h'}
                    </SyntaxHighlighter>

                    Pentru a afla IP-ul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube ip'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'192.168.49.2'}
                    </SyntaxHighlighter>

                    Testare in browser:
                    <SyntaxHighlighter>
                        {'http://192.168.49.2:31515'}
                    </SyntaxHighlighter>
                    Testare curl:
                    <SyntaxHighlighter>
                        {'curl http://192.168.49.2:31515'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Trecere de la Pod la Deployment</b>
                    <br/>
                    <br/>

                    In momentul in care se modifica in cadrul unui fisier de configurare care defineste un obiect de tip <i>Pod</i> atributul <i>image</i>,
                    si apoi se executa <i>kubectl apply</i>, in mod automat se va relecta schimbarea.
                    <br/>
                    Dar, daca schimbam, se exemplu, atributul <i>containerPort</i>, atunci se va obtine eroare, pentru ca nu toate atributele pot fi schimbate folosind acest tip de obiect.
                    <br/>
                    In schimb, se poate folosi un alt tip de obiect: <b>Deployment</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano client-deployment.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: web\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000\n'}
                    </SyntaxHighlighter>

                    Stergere pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete -f client-pod.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f client-deployment.yaml'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployments'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'NAME                READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'client-deployment   0/1     1            0           20s'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Actualizare imagine</b>
                    <br/>
                    <br/>
                    Pentru acest lucru este nevoie sa tag-uim imaginea pusa pe dockerhub.
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker build -t iulianbuzdugan/multi-client:v5 .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker push iulianbuzdugan/multi-client:v5'}
                    </SyntaxHighlighter>

                    Apoi, trebuie rulata comanda <b>kubectl set</b> pentru actualizare imagine:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set image deployment/client-deployment client=iulianbuzdugan/multi-client:v5'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex1k8sContent;