import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class ErrorPageTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-error-page", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Coduri de eroare</b>
                    <br/>
                    <br/>

                    Coduri de eroare:
                    <ul>
                        <li>200: ok</li>
                        <li>301: rediect catre alt URL</li>
                        <li>403: probleme legate de contextul de securitate</li>
                        <li>404: pagina nu a fost gasita (Not Found)</li>
                        <li>400: invalid request (Bad Request)</li>
                        <li>400: indică faptul că serverul înțelege cererea, dar refuză să o autorizeze (403 Forbidden)</li>
                        <li>500: eroare in cod pe server (Internal Server Error)</li>
                    </ul>

                    <b>2. Pagini de eroare customizate</b>
                    <br/>
                    <br/>

                    Pentru a schimba afisarea paginilor de eroare implicite, se modifica fisierul <b>web.xml</b> din <b>[tomcat]\conf</b>.
                    <br/>
                    Se adauga urmatoarele linii:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<error-page>\n' +
                            '\t<error-code>404</error-code>\n' +
                            '\t<location>/404.html</location>\n' +
                            '</error-page>\n'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>
                    <br/>
                    Daca serverul este pornit, si se face o modificare in <b>web.xml</b> (de exemplu in sectiunea <b>error-code</b>,
                    atunci modificarea este luata automat, fara a mai fi nevoie restartarea serverului)

                    <br/>
                    <br/>

                    In aplicatia web, in <i>[tomcat]\webapps\myapp</i> avem 2 fisiere:
                    <ul>
                        <li>
                            index.html:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<html>\n' +
                                    '<body>\n' +
                                    'Aplicatia mea!\n' +
                                    '</body>\n' +
                                    '</html>'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            404.html:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<html>\n' +
                                    '<body>\n' +
                                    'Scuze, eroare!\n' +
                                    '</body>\n' +
                                    '</html>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    Verificare in browser: <i>http://localhost:8580/myapp/x</i> {"=>"} se va afisa <i>Scuze, eroare!</i>


                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ErrorPageTomcatContent;