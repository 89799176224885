import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class VirtualHostNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-virtual-host", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare Virtual host</b>

                    <br/>
                    <br/>

                    Pe server se poate crea direct in root directorul <b>/sites/demo</b> in care se va pun site-ul propriu zis (cu html-uri, imagini, css-uri, etc).

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx/'}
                    </SyntaxHighlighter>

                    Editam /etc/nginx/<b>nginx.conf</b>.
                    Pormin de la un minim:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            'http {}'}
                    </SyntaxHighlighter>
                    Si completam:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            'http {\n' +
                            '   server {\n' +
                            '       listen 80;\n' +
                            '       server_name 168.172.0.1;\n' +
                            '       root /sites/demo;' +
                            '   }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a lua noile configurari, trebuie reincarcat nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemcrl reload nginx'}
                    </SyntaxHighlighter>
                    daca crapa, nu opreste server-ul (fata de <b>systemcrl restart nginx</b> care opreste si incerca sa porneasca server-ul din nou; daca e vreo problema server-ul ramane oprit)

                    <br/>
                    <br/>

                    Pentru a verifica/testa ca sintaxa e ok in fisierul <b>nginx.conf</b> si ar trebuie sa fie primul test care trebuie facut dupa o modificare in acest fisier.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -t'}
                    </SyntaxHighlighter>

                    Dupa pornirea server-ului, verificam in browser incarcarea site-ului.
                    <br/>
                    Dar, fisiere css nu se aplica cu configurarea facuta.
                    <br/>
                    Problema e ca Nginx trimite minetype-ul gresit pentru fisiere css.
                    Acest lucru poate confirmat prin:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -I http://168.172.0.1/style.css'}
                    </SyntaxHighlighter>
                    Si vom observa ca la <b>Content-Type: text/plan</b>.

                    <br/>
                    Pentru a corecta acest lucru, trebuie sa specificam content-type pentru fiecare extensie:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            'http {\n' +
                            '   types {\n' +
                            '       text/html html;\n' +
                            '       text/css css;\n' +
                            '   }\n' +
                            '   server {\n' +
                            '       listen 80;\n' +
                            '       server_name 168.172.0.1;\n' +
                            '       root /sites/demo;\n' +
                            '   }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Dar exista fisierul <b>/etc/nginx/mine.types</b>  pe care il putem referi pentru a ne scuti de treaba de a defini asocieri intre mine-type si extensie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            'http {\n' +
                            '   include mine.types;\n' +
                            '   server {\n' +
                            '       listen 80;\n' +
                            '       server_name 168.172.0.1;\n' +
                            '       root /sites/demo;\n' +
                            '   }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Verificari:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -I http://168.172.0.1/style.css'}
                    </SyntaxHighlighter>
                    Si vom observa ca la <b>Content-Type: text/css</b>.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VirtualHostNginxContent;