import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ArraysJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-arrays", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Definirea unui vector</b>
                    <br/>
                    <br/>

                    Un vector (array) este o zona de memorie in <b>heap</b> cu un spatiu destinat pentru a pastra un numar definit de elemente.
                    <br/>
                    Un vector este o lista ordonata.

                    <br/> <br/>
                    <b>Definire 1</b> (declarare numar elemente, initializare cu valoare implicita bazata pe tip):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'tip [] nume_vector = new tip[numar_elemente]'}
                    </SyntaxHighlighter>
                    Definirea de mai sus, creaza un numar de <i>numar_elemente</i> obiecte/elemente de tipul <i>tip</i>, care vor fi initializate cu valoare implicita a tipului.

                    <br/>
                    <br/>
                    <i>Exemplu:</i>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int[] vector = new int[3];\n' +
                        'for(int element:vector){\n' +
                        '    System.out.println(element); // 0 0 0\n' +
                        '}\n' +
                        '//sau\n' +
                        'System.out.println(Arrays.toString(vector)); // [0, 0, 0]'}
                    </SyntaxHighlighter>

                    <b>Definire 2</b> (fara declarare numar elemente, initializare cu valorile dorite):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'tip [] nume_vector = new tip[]{element1, element2,...,elementN}'}
                    </SyntaxHighlighter>

                    <i>Exemplu:</i>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int[] vector = new int[]{4,3,32};'}
                    </SyntaxHighlighter>

                    <b>Definire 3</b> (vector anonim: nu se specifica nici tipul, nici numarul de elemente; <i>aceasta forma se foloseste doar la initializarea vectorului</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'tip [] nume_vector = {element1, element2,...,elementN}'}
                    </SyntaxHighlighter>

                    <i>Exemplu:</i>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int[] vector = {4,3,32};'}
                    </SyntaxHighlighter>

                    <i>Exemplu:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String[] lista1 = {"a","bc"};\n' +
                        'Object[] lista2 = lista1;\n' +
                        '\n' +
                        ' lista2[1] = 1; // EROARE java.lang.ArrayStoreException: java.lang.Integer'}
                    </SyntaxHighlighter>
                    Explicatie: <i>lista2</i> refera <i>lista1</i> care permite doar elemente de tip String; deci lina 4 va arunca exceptia <i>ArrayStoreException</i>, pentru ca inserca setarea unui element de tip Integer.

                    <br/>
                    <br/>

                    <b>2. Operatii</b>
                    <br/>
                    <br/>

                    <b>length</b>: pentru a obtine lungimea unui vector se foloseste atributul <b>length</b> (nu e metoda):

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String[] lista = {"a","bc"};\n' +
                        'System.out.println(lista.length);// 2'}
                    </SyntaxHighlighter>

                    <b>transformare intr-un String</b>: pentru a transforma un vector intr-un sir de caractere se poate folosi metoda <b>toString(vector)</b> al clasei <b>java.util.Arrays</b>.
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Integer[] lista = {44,13,131};\n' +
                        'System.out.println(Arrays.toString(lista)); // [44, 13, 131]'}
                    </SyntaxHighlighter>

                    <b>sortare</b>: pentru a sorta un vector se foloseste metoda <b>sort(vector)</b> al clasei <b>java.util.Arrays</b> (sortare interna)

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Integer[] lista = {44,13,131};\n' +
                        'Arrays.sort(lista);\n' +
                        'System.out.println(Arrays.toString(lista)); // [13, 44, 131]'}
                    </SyntaxHighlighter>

                    <b>cautare binara</b>: pentru a cauta intr-o lista ordonata se poate folosi metoda <b>binarySearch(lista, element)</b> al clasei <b>java.util.Arrays</b>; returneaza:
                    <ul>
                        <li>indexul elementului, daca exista in lista</li>
                        <li>-i-1, unde i e indexul elementului unde ar trebuie inserat pentru a mentine ordinea listei, daca nu exista in lista</li>
                        <li>valoare nepredictibila, daca lista nu este sortata</li>
                    </ul>

                    <b>comparare</b>: se foloseste metoda  <b>compare(vector1, vector2)</b> al clasei <b>java.util.Arrays</b>; returneaza:
                    <ul>
                        <li>numar pozitiv, daca:
                            <ul>
                                <li>vector1.length {">"} vector1.length</li>
                                <li>vector1.length = vector1.length si exista un prim element vector1[i] {">"} vector2[i]</li>
                            </ul>
                        </li>
                        <li>numar negativ, daca:
                            <ul>
                                <li>vector1.length &lt; vector1.length</li>
                                <li>vector1.length = vector1.length si exista un prim element vector1[i] &lt; vector2[i]</li>
                            </ul>
                        </li>
                        <li>0, daca vector1.length = vector2.length si vector1[i]==vector2[i], i = 0 .. vector1.length</li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>null este mai mic decat orice valoare</li>
                        <li>numerele sunt mai mici decat literele</li>
                        <li>caractrele mari sunt mai mici decat caracterele mici</li>
                        <li>un prefix este mai mic ("a" &lt; "ab")</li>
                    </ul>

                    <b>mismatch</b> returneaza:
                    <ul>
                        <li>-1, daca vectorii sunt egali</li>
                        <li>primul index unde difera, daca vectorii nu sunt egali</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'System.out.println(Arrays.mismatch( new int[]{1,2}, new int[]{1,2} )); // -1\n' +
                        'System.out.println(Arrays.mismatch( new int[]{1,2}, new int[]{2,2} )); // 0\n' +
                        'System.out.println(Arrays.mismatch( new int[]{1,2}, new int[]{1,3,18} )); // 1'}
                    </SyntaxHighlighter>

                    <b>vectorii multidimensionali</b> sunt vectorii care contin contin ca element alt vectori; un exemplu de vector multidimensional este maticea;
                    <br/>definire matrice (vector 2D):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'tip[][] nume_matrice = new tip[nr_linii][nr_coloane]'}
                    </SyntaxHighlighter>
                    definire vector 3D:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'tip[][][] nume_vector_3D = new tip[dimensiune_1][dimensiune_2][dimensiune_3]'}
                    </SyntaxHighlighter>

                    exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int[][] a; // matrice\n' +
                        'int b[][];// matrice\n' +
                        'int[] c []; // matrice\n' +
                        'int[] d [], e[][];// d este matrice, e este vector 3D'}
                    </SyntaxHighlighter>

                    Forma unui vector multidimensional, nu trebuie sa fie neaparat rectangular, ci poate fi asimetric:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int [][] vector = {{1, 3}, {6}, {12, 31, 7}}'}
                    </SyntaxHighlighter>

                    O alta cale de a defini un vector multidimensional asimetric:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'int [][] vector = new int[3][];\n' +
                        'vector[0]=new int[2];\n' +
                        'vector[1]=new int[1];\n' +
                        '// vector[2]={12, 31}; // eroare, pentru ca vectorii de intializare se pot folosi doar la initializare;\n' +
                        'vector[3]=new int[3]'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ArraysJavaContent;