import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DisableChangePasswordFirstLoginLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-disable-change-password-first-login", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    In <b>portal-ext.properties</b>:
                    <SyntaxHighlighter>
                        {'passwords.default.policy.change.required=false'}
                    </SyntaxHighlighter>

                    <hr/>
                    Din UI:
                    <SyntaxHighlighter>
                        {'Control Panel -> Password Policies (sectiunea: Security) -> Default Password Policy .-> Change Required (debifare)'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_1.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_2.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://liferayiseasy.blogspot.com/2022/06/how-to-enable-jquery-in-liferay-dxp-73.html"}>*/}
                        {/*            How to enable jQuery in Liferay DXP (7.3)*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DisableChangePasswordFirstLoginLiferayContent;