import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Auth2FALiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-2fa", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>
                        Definitii:
                    </b>
                    <ul>
                        <li>
                            OTP = one-time password
                        </li>
                        <li>
                            TOTP = OTP bazat pe timp
                        </li>
                    </ul>

                    <hr/>

                    Pentru fiecare utilizator, se selecteaza utilizatorul:
                    <ul>
                        <li>General {"->"} Password screen</li>
                        <li>se apasa pe <b>Generate 2FA Secret Key</b></li> pentru a genera <i>Secret Key</i> si a afisa <i>QR Code</i> pe ecran
                        <li>in Google Authenticator:
                            <ul>
                                <li>se apasa pe iconita (+)</li>
                                <li>se selecteaza <i>Scan barcode</i></li>
                                <li>se scaneaza codul QR de pe ecran pentru a adăuga utilizatorul la aplicația Google Authenticator (Aplicația Google Authenticator va avea nevoie de acces la camera dvs.)</li>
                            </ul>

                        </li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>La autentificare, <i>Cod de autentificare</i> apare sub câmpul <i>Parolă</i></li>
                        <li>Utilizatorii cu rolul de administrator Liferay vor ocoli întotdeauna TOTP 2FA la autentificare și pot lăsa câmpul Cod de autentificare gol</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://liferay.dev/blogs/-/blogs/adding-2fa-to-liferay-dxp-7-1"}>
                                    Adding 2FA to Liferay DXP 7.1
                                </a>
                            </div>
                        </li>

                        <li>

                            <div>
                                <a href={"https://github.com/michael-wall/liferay-2fa"}>
                                    GIT: liferay-2fa
                                </a>
                            </div>

                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Auth2FALiferayContent;