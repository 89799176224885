import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class StringPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-string", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Cateva observatii de caractere</b>
                    <br/>
                    <br/>

                    Calculatoarele stocheaza caracterele ca <b>numere</b>.  Există mai multe moduri posibile de codificare a caracterelor. Exemple:
                    <ul>
                        <li><b>ASCII</b>: (American Standard Code for Information Interchange) codifica alfabetul latin și unele dintre derivatele acestuia (are spartiu pentru 256 de caractere)</li>
                        <li><b>UNICODE</b>: codifica toate alfabetele, folosind diferite moduri de codare:
                            <ul>
                                <li><b>UCS-4</b>: (Universal Character Set):
                                    <br/>
                                    UCS-4 folosește 32 de biți (patru octeți) pentru a stoca fiecare caracter, iar codul este doar numărul unic al punctelor de cod Unicode. <br/>
                                    Un fișier care conține text codificat UCS-4 poate începe cu un <b>BOM</b> (byte order mark), o combinație de biți care nu poate fi imprimată care anunță natura conținutului fișierului.
                                </li>
                                <li><b>UTF-8</b>: (Unicode Transformation Format) foloseste mai putin memorie, pentru ca folosește doar cati biti are nevoie pentru  a reprezenta un punct de cod (8 biti pentru caracterele latine, 16 biti pentru non-latine, 24 biti idiograme (China, Japonia, Korea )  )</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    Tipul <b>String</b> este o <b>secventa imutabila</b>.

                    <br/><br/>
                    <i>Definire pe o linie (cu ' sau ")</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'"sir_de_caractere"'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'\'sir_de_caractere\''}
                    </SyntaxHighlighter>

                    <i>Definire pe mai multe linie (cu ''' sau de """)</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'\'\'\'Linia #1\n' +
                        'Linia #2\'\'\''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'linii = \'\'\'Linia #1\n' +
                        'Linia #2\'\'\'\n' +
                        '\n' +
                        'print(len(linii)) # 17 - pentru ca contine si un caracter alb (whitespace) invizibil - new line: \\n '}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Operatii pe tipul String</b>

                    <br/>
                    <br/>
                    Operatori pe tipul String:
                    <ul>
                        <li>+ (concatenare) / (valabila si varianta: +=)</li>
                        <li>* (multiplicare) (valabila si varianta: *=)</li>
                    </ul>
                    Un numar poate convertit la String folosind functia <b>str()</b>.

                    <hr/>
                    <i>Exemplul 1:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("A"+"B")'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'AB'}
                    </SyntaxHighlighter>

                    <hr/>
                    <i>Exemplul 2:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("A"*5)'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'AAAAA'}
                    </SyntaxHighlighter>

                    <hr/>
                    <i>Exemplul 3:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(2*"A"*5)'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'AAAAAAAAAA'}
                    </SyntaxHighlighter>

                    <hr/>
                    <i>Exemplul 4:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print((\'*\'*10+"\\n")*5)'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'**********\n' +
                        '**********\n' +
                        '**********\n' +
                        '**********\n' +
                        '**********'}
                    </SyntaxHighlighter>

                    <hr/>
                    Functii:
                    <ul>
                        <li>ord(caracter): pentru a obtine reprezentarea numerica a caracterului (valoarea <b>code point</b>)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(ord(\'A\')) # 65\n' +
                                'print(ord(\' \')) # 32'}
                            </SyntaxHighlighter>

                            Functia accepta doar un caracter ca argument, asa ca va aruca exceptie, in caz contrar:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(ord(\'kj\')) # TypeError: ord() expected a character, but string of length 2 found'}
                            </SyntaxHighlighter>
                        </li>
                        <li>chr(code_point): pentru a obtine caracterului, pe baza reprezentarii numerice a caracterului (valoariii <b>code point</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(chr(65)) # A\n' +
                                'print(chr(944646546565)) # OverflowError: signed integer is greater than maximum\n' +
                                'print(chr(-1)) # chr() arg not in range(0x110000)'}
                            </SyntaxHighlighter>
                            Daca <i>code_point</i> este negativ sau prea mare se va arunca exceptie.

                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringPythonContent;