import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";


class ServicesAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-services", IndexContent);
    }

    isOk = true;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Servicii</b>
                    <br/>
                    <br/>

                    Serviciile sunt instantiate de Angular.

                    <br/>
                    <br/>

                    Serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class LoggingService {\n' +
                            '  log(message: string) {\n' +
                            '    console.log(message);\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Declarare in componenta/modul/injectable, prin <b>providers</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'@Component({\n' +
                            '  selector: \'jhi-hero-detail\',\n' +
                            '  templateUrl: \'./hero-detail.component.html\',\n' +
                            '  styleUrls: [\'./hero-detail.component.scss\'],\n' +
                            '  providers: [LoggingService]\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Injectare instanta, in constructor:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'constructor(private loggingService: LoggingService) {\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.loggingService.log(\'Test\');'}
                    </SyntaxHighlighter>

                    <hr/>
                    Injectare manuala, folosind functia <b>inject()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, Input, Output, inject } from \'@angular/core\'; // <- Add inject import\n' +
                            ' \n' +
                            '@Component(...)\n' +
                            'export class AccountComponent {\n' +
                            '  private loggingService?: LoggingService; // <- must be added\n' +
                            ' \n' +
                            '  constructor() {\n' +
                            '    this.loggingService = inject(LoggingService);\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un serviciu trebuie <i>inregistrat</i> cel putin intr-un <b>provider</b>!

                    <hr/>

                    Avem trei moduri de a crea instanțele unui serviciu:
                    <ul>
                        <li>
                            instanta singleton:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'@Injectable({ providedIn: \'root\' })\n' +
                                    'class MySingletonService {}'}
                            </SyntaxHighlighter>
                            Proprietata <b>providedIn</b>, care îi va spune lui Angular să-l furnizeze în rădăcina aplicației,
                            așa că vom avea o instanță a serviciului în aplicație.
                        </li>
                        <li>
                            instanta per componenta:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'@Injectable()\n' +
                                    'class MyService {}\n' +
                                    '\n' +
                                    '@Component({\n' +
                                    ' providers: [MyService]\n' +
                                    '})\n' +
                                    'class MyComponent {\n' +
                                    ' constructor(private service: MyService) {}\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            instanță per modul:
                            <br/>
                            Pentru ca acest lucru să funcționeze, putem folosi funcția de <b>Încărcare leneră</b>.
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'@Injectable()\n' +
                                    'class MyService {}\n' +
                                    '\n' +
                                    '@NgModule({providers: [MyService]})\n' +
                                    'class FirstModule{}\n' +
                                    '\n' +
                                    '@NgModule({providers: [MyService]})\n' +
                                    'class SecondModule{}\n' +
                                    '\n' +
                                    '@NgModule({providers: [MyService]})\n' +
                                    'class ThirdModule{}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'const routes: Routes = [\n' +
                                    '  {\n' +
                                    '    path: \'first-module\',\n' +
                                    '    loadChildren: () => import(\'./first/first.module\').then(m => m.FirstModule)\n' +
                                    '  },\n' +
                                    '  {\n' +
                                    '    path: \'second-module\',\n' +
                                    '    loadChildren: () => import(\'./second/second.module\').then(m => m.SecondModule)\n' +
                                    '  },\n' +
                                    '  {\n' +
                                    '    path: \'third-module\',\n' +
                                    '    loadChildren: () => import(\'./third/third.module\').then(m => m.ThirdModule)\n' +
                                    '  }\n' +
                                    '];\n' +
                                    '\n' +
                                    '@NgModule({imports: [RouterModule.forRoot(routes)]})\n' +
                                    'class AppModule {}'}
                            </SyntaxHighlighter>

                            Fiecare componentă care este declarată în modulul respectiv poate injecta acel serviciu în siguranță și să se asigure că orice date stocate în acele servicii (instanțe) sunt unice.

                        </li>
                    </ul>

                    <hr/>
                    Daca avem:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'@Injectable()\n' +
                            'class MyService {}\n' +
                            '\n' +
                            '@Component()\n' +
                            'class MyComponent {\n' +
                            ' constructor(private service: MyService) {}\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Dacă rulăm aplicația noastră în acest fel,
                    ne vom confrunta cu o eroare cu titlul Null Injector care spune că serviciul nu este furnizat nicăieri,
                    deci nu este utilizabil!
                    <ul>
                        <li>
                            când se injecteaza un serviciu într-o componentă, Angular va începe mai întâi să caute dependențe
                            în lista de provider la nivel de componentă.
                            <br/>
                            Dacă nu găsește un provider, se va muta în sus în ierarhia componentelor.
                            <br/>
                            Acest proces va continua până când atinge <i>AppComponent</i>.
                            <br/>
                            Dacă Angular încă nu poate găsi furnizorul de dependență, vă va afișa un mesaj de eroare.
                        </li>
                    </ul>

                    <hr/>
                    Daca vrem sa injectam un serviciu A in alt serviciu B  trebuie sa adnotam serviciul B cu <b>@Injectable()</b>!

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'//@Injectable() <- aici nu e nevoie; dar e recomandabil sa fie \n' +
                            'class MyService {}\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'class MyService2 {\n' +
                            ' constructor(private service: MyService) {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServicesAngularContent;