import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../books/IndexContent";

class ITBooksContent extends BaseContentPage  {

    constructor(props) {
        super(props, "books-it", IndexContent);

        this.state = {
            ...this.state,
          //  filtredBooks: [],
            search:''
        };
    }

    static books = [
        {
            id:1,
            title:"Jump Start Git",
            publisher:"SitePoint Pty Ltd",
            year:2020,
            authors:["Shaumik Daityari"],
            pages:172,
            language: "engleza",
            ISBN: "978-1-925836-34-9",
            img:"jump-start-git.jpg"
        },
        {
            id:2,
            title: "Introducing Gradle",
            publisher: "Apress",
            year: 2015,
            authors: ["Balaji Varanasi"],
            language: "engleza",
            pages: 130,
            ISBN: "978-1-4842-1032-1",
            img: "introducing-gradle.jpg"
        },
        {
            id:3,
            title: "Learn Git in a Month of Lunches",
            publisher: "Manning Publications",
            year: 2015,
            authors: ["Rick Umali"],
            language: "engleza",
            pages: 375,
            ISBN: "978-1617292415",
            img: "learn-git-in-a-month-of-lunches.jpg"
        },
        {
            id:4,
            title: "JavaScript – The Definitive Guide, 7e",
            publisher: "O'Reilly",
            year: 2020,
            authors: ["David Flanagan"],
            language: "engleza",
            pages: 690,
            ISBN: "9781491952023",
            img: "javascript-the-definitive-guide-7e.jpg"
        },
        {
            id:5,
            title: "The Joy of Kotlin",
            publisher: "Manning Publications",
            year: 2019,
            authors: ["Pierre-Yves Saumont"],
            language: "engleza",
            pages: 480,
            ISBN: "9781617295362",
            img: "the-joy-of-kotlin.jpg"
        },
        {
            id:6,
            title: "Introducing Maven",
            publisher: "Apress",
            year: 2019,
            authors: ["Balaji Varanasi"],
            language: "engleza",
            pages: 140,
            ISBN: "9781484254097",
            img: "introducing-maven.jpg"
        },
        {
            id:7,
            title: "Joy of JavaScript",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["Luis Atencio"],
            language: "engleza",
            pages: 325,
            ISBN: "978-1617295867",
            img: "the-joy-of-javascript.jpg"
        },
        {
            id:8,
            title: "Docker Demystified: Learn How to Develop and Deploy Applications Using Docker",
            publisher: "BPB PUBN",
            year: 2020,
            authors: ["Saibal Ghosh"],
            language: "engleza",
            pages: 238,
            ISBN: "9389845874",
            img: "docker_d.jpg"
        },
        {
            id:9,
            title: "Learn Docker in a Month of Lunches",
            publisher: "Manning Publications",
            year: 2020,
            authors: ["Elton Stoneman"],
            language: "engleza",
            pages: 464,
            ISBN: "1617297054",
            img: "learn-docker-in-a-month-of-lunches.jpg"
        },

        {
            id:10,
            title: "Learning Elasticsearch 7.x",
            publisher: "BPB PUBN",
            year: 2020,
            authors: ["Anurag Srivastava"],
            language: "engleza",
            pages: 310,
            ISBN: "9389898307",
            img: "elasticsearch.jpg"
        },

        {
            id:11,
            title: "Jakarta EE Recipes: A Problem-Solution Approach",
            publisher: "Apress",
            year: 2020,
            authors: ["Josh Juneau"],
            language: "engleza",
            pages: 885,
            ISBN: "978-1484255865",
            img: "9781484255865.png"
        },

        {
            id:12,
            title: "Jakarta EE Recipes: A Problem-Solution Approach",
            publisher: "Apress",
            year: 2021,
            authors: ["Lukas Jungmann","Mike Keith","Merrick Schincariol","Massimo Nardone"],
            language: "engleza",
            pages: 756,
            ISBN: "1484274423",
            img: "pro-jakarta-persistence-in-jakarta-ee-10.jpg"
        },

        {
            id:13,
            title: "Definitive Guide to Security in Jakarta EE",
            publisher: "Apress",
            year: 2022,
            authors: ["Arjan Tijms","Werner Keil","Teo Bais"],
            language: "engleza",
            pages: 638,
            ISBN: "1484279441",
            img: "definitive-guide-to-security-in-jakarta-ee.jpg"
        },


        {
            id:14,
            title: "Definitive Guide to Jakarta Faces in Jakarta EE 10",
            publisher: "Apress",
            year: 2022,
            authors: ["Bauke Scholtz","Arjan Tijms"],
            language: "engleza",
            pages: 527,
            ISBN: "1484273095",
            img: "the-definitive-guide-to-jakarta-faces-in-jakarta-ee-10-using-eclipse-jakarta-ee-for-enterprise-java-development.jpg"
        },

        {
            id:15,
            title: "Beginning Jakarta EE",
            publisher: "Apress",
            year: 2022,
            authors: ["Peter Spath"],
            language: "engleza",
            pages: 444,
            ISBN: "1484250788",
            img: "beginning-jakarta-ee.jpg"
        },

        {
            id:16,
            title: "Beginning Jakarta EE Web Development",
            publisher: "Apress",
            year: 2020,
            authors: ["Luciano Manelli","Giulio Zambon"],
            language: "engleza",
            pages: 407,
            ISBN: "9781484258651",
            img: "beginning-jakarta-ee-web-development.jpg"
        },

        {
            id:17,
            title: "Jakarta EE Dependency Injection for Java Developers: A Practical Guide to Dependency Injection on the Jakarta EE (formerly Java EE) Platform",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Luqman Saeed"],
            language: "engleza",
            pages: 84,
            ISBN: "107023544X",
            img: "jakarta-ee-dependency-injection-for-java-developers-a-practical-guide-to-dependency-injection-on-the-jakarta-ee-formerly-java-ee-platform.jpg"
        },

        {
            id:18,
            title: "Building Modern Web Applications With Jakarta EE, NoSQL Databases and Microservices",
            publisher: "BPB Publications",
            year: 2020,
            authors: ["Aristides Villarreal Bravo","Geovanny Mendoza González","Otávio Gonçalves de Santana"],
            language: "engleza",
            pages: 388,
            ISBN: "9389423341",
            img: "33289604o.jpg"
        },

        {
            id:19,
            title: "Jakarta EE for Java Developers",
            publisher: "BPB Publications",
            year: 2022,
            authors: ["Rhuan Rocha"],
            language: "engleza",
            pages: 310,
            ISBN: "935551008X",
            img: "38449193o.jpg"
        },

        {
            id:20,
            title: "Head First Git",
            publisher: "O'Reilly Media",
            year: 2022,
            authors: ["Raju Ghandi"],
            language: "engleza",
            pages: 470,
            ISBN: "1492092517",
            img: "36272654o.jpg"
        },

        {
            id:21,
            title: "Spring Security in Action",
            publisher: "Manning Publications",
            year: 2020,
            authors: ["Laurențiu Spilcă"],
            language: "engleza",
            pages: 450,
            ISBN: "1617297739",
            img: "32688150o.jpg"
        },

        {
            id:22,
            title: "TypeScript Quickly",
            publisher: "Manning Publications",
            year: 2020,
            authors: ["Yakov Fain","Anton Moiseev"],
            language: "engleza",
            pages: 350,
            ISBN: "1617295949",
            img: "22471927_typescript-quickly.jpg"
        },

        {
            id:23,
            title: "Angular Development with TypeScript",
            publisher: "Manning Publications",
            year: 2019,
            authors: ["Yakov Fain","Anton Moiseev"],
            language: "engleza",
            pages: 534,
            ISBN: "9781617295348",
            img: "18878138_angular-development-with-typescript-p1.jpg"
        },

        {
            id:24,
            title: "Spring Start Here",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["Laurențiu Spilcă"],
            language: "engleza",
            pages: 392,
            ISBN: "1617298697",
            img: "9781617298691.png"
        },

        {
            id:25,
            title: "Learn Kubernetes in a Month of Lunches",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["Elton Stoneman"],
            language: "engleza",
            pages: 592,
            ISBN: "1617297984",
            img: "33105728o.jpg"
        },

        {
            id:26,
            title: "Getting MEAN with Mongo, Express, Angular, and Node, Second Edition",
            publisher: "Manning Publications",
            year: 2019,
            authors: ["Simon Holmes","Clive Harber"],
            language: "engleza",
            pages: 478,
            ISBN: "1617294756",
            img: "17638576_getting-mean-with-mongo-express-angular-and-node.jpg"
        },


        {
            id:27,
            title: "JUnit in Action, Third Edition",
            publisher: "Manning Publications",
            year: 2020,
            authors: ["Cătălin Tudose"],
            language: "engleza",
            pages: 560,
            ISBN: "9781617297045",
            img: "Tudose-JUnit-HI.png"
        },

        {
            id:28,
            title: "The Java Module System",
            publisher: "Manning Publications",
            year: 2019,
            authors: ["Nicolai Parlog"],
            language: "engleza",
            pages: 440,
            ISBN: "9781617294280",
            img: "18667885_the-java-module-system.jpg"
        },

        {
            id:29,
            title: "Deep Learning for Vision System",
            publisher: "Manning Publications",
            year: 2020  ,
            authors: ["Mohamed Elgendy"],
            language: "engleza",
            pages: 410,
            ISBN: "1617296198",
            img: "25293081_deep-learning-for-vision-systems.jpg"
        },

        {
            id:30,
            title: "Spring Microservices in Action, Second Edition",
            publisher: "Manning Publications",
            year: 2021  ,
            authors: ["John Carnell","Illary Huaylupo Sánchez"],
            language: "engleza",
            pages: 448,
            ISBN: "1617296953",
            img: "33105725o.jpg"
        },

        {
            id:31,
            title: "Spark in Action, Second Edition",
            publisher: "Manning Publications",
            year: 2020,
            authors: ["Jean-Georges Perrin"],
            language: "engleza",
            pages: 576,
            ISBN: "1617295523",
            img: "24512180_spark-in-action.jpg"
        },

        {
            id:32,
            title: "API Security in Action",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["Neil Madden"],
            language: "engleza",
            pages: 400,
            ISBN: "1617296023",
            img: "28204862o.jpg"
        },

        {
            id:33,
            title: "Bootstrapping Microservices with Docker, Kubernetes, and Terraform",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["Ashley Davis"],
            language: "engleza",
            pages: 325,
            ISBN: "1617297216",
            img: "32688148o.jpg"
        },

        {
            id:34,
            title: "API Design Patterns",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["JJ Geewax"],
            language: "engleza",
            pages: 480,
            ISBN: "161729585X",
            img: "35341350o.jpg"
        },

        {
            id:35,
            title: "Pandas In Action",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["Boris Paskhaver"],
            language: "engleza",
            pages: 525,
            ISBN: "1617297437",
            img: "33298873o.jpg"
        },

        {
            id:36,
            title: "Grokking Deep Learning",
            publisher: "Manning Publications",
            year: 2019,
            authors: ["Andrew W. Trask"],
            language: "engleza",
            pages: 336,
            ISBN: "1617293709",
            img: "14131983_grokking-deep-learning.jpg"
        },

        {
            id:37,
            title: "React Hooks in Action",
            publisher: "Manning Publications",
            year: 2021,
            authors: ["John Larsen"],
            language: "engleza",
            pages: 336,
            ISBN: "1617297631",
            img: "32688149o.jpg"
        },

        {
            id:38,
            title: "Pipeline as Code",
            publisher: "Manning Publications",
            year: 2022,
            authors: ["Mohamed Labouardy"],
            language: "engleza",
            pages: 325,
            ISBN: "1617297542",
            img: "35341352o.jpg"
        },

        {
            id:39,
            title: "Object-oriented Python",
            publisher: "No Starch Press",
            year: 2022,
            authors: ["Irv Kalb"],
            language: "engleza",
            pages: 450,
            ISBN: "1718502060",
            img: "36513849o.jpg"
        },

        {
            id:40,
            title: "Pivotal Certified Professional Core Spring 5 Developer Exam",
            publisher: "Apress",
            year: 2020,
            authors: ["Iuliana Cosmina"],
            language: "engleza",
            pages: 1007,
            ISBN: "1484251350",
            img: "22609057_pivotal-certified-professional-core-spring-5-developer-exam-a-study-guide-using-spring-framework-5.jpg"
        },

        {
            id:41,
            title: "Beginning Java 17 Fundamentals",
            publisher: "Apress",
            year: 2022,
            authors: ["Kishori Sharan","Adam L. Davis"],
            language: "engleza",
            pages: 1036,
            ISBN: "1484273060",
            img: "36624615_beginning-java-17-fundamentals-object-oriented-programming-in-java-17.jpg"
        },

        {
            id:42,
            title: "Java 17 for Absolute Beginners",
            publisher: "Apress",
            year: 2021,
            authors: ["Iuliana Cosmina"],
            language: "engleza",
            pages: 801,
            ISBN: "1484270797",
            img: "35575865_java-17-for-absolute-beginners-learn-the-fundamentals-of-java-programming.jpg"
        },

        {
            id:43,
            title: "More Java 17",
            publisher: "Apress",
            year: 2021,
            authors: ["Kishori Sharan","Peter Späth"],
            language: "engleza",
            pages: 951,
            ISBN: "1484271343",
            img: "35713555_more-java-17-an-in-depth-exploration-of-the-java-language-its-features.jpg"
        },

        {
            id:44,
            title: "Pro Spring Boot 2",
            publisher: "Apress",
            year: 2019,
            authors: ["Felipe Gutierrez"],
            language: "engleza",
            pages: 511,
            ISBN: "1484236750",
            img: "19297281_pro-spring-boot-2.jpg"
        },

        {
            id:45,
            title: "Java 17 Quick Syntax Reference",
            publisher: "Apress",
            year: 2021,
            authors: ["Mikael Olsson"],
            language: "engleza",
            pages: 100,
            ISBN: "1484273702",
            img: "36723861_java-17-quick-syntax-reference-a-pocket-guide-to-the-java-se-language-apis-and-library.jpg"
        },


        {
            id:46,
            title: "Introducing Blockchain with Java",
            publisher: "Apress",
            year: 2022,
            authors: ["Spiro Buzharovski"],
            language: "engleza",
            pages: 173,
            ISBN: "1484279263",
            img: "38318609_introducing-blockchain-with-java.jpg"
        },

        {
            id:47,
            title: "Web Security For Developers",
            publisher: "No Starch Press",
            year: 2020,
            authors: ["Malcolm McDonald"],
            language: "engleza",
            pages: 270,
            ISBN: "1593279949",
            img: "22419896o.jpg"
        },



        {
            id:48,
            title: "Deep Learning",
            publisher: "No Starch Press",
            year: 2021,
            authors: ["Andrew Glassner"],
            language: "engleza",
            pages: 1200,
            ISBN: "1718500726",
            img: "32278502o.jpg"
        },

        {
            id:49,
            title: "Automate The Boring Stuff With Python, 2nd Edition",
            publisher: "No Starch Press",
            year: 2019,
            authors: ["Al Sweigart"],
            language: "engleza",
            pages: 500,
            ISBN: "1593279922",
            img: "22639538_automate-the-boring-stuff-with-python.jpg"
        },

        {
            id:50,
            title: "Python Crash Course",
            publisher: "No Starch Press",
            year: 2019,
            authors: ["Eric Matthes"],
            language: "engleza",
            pages: 544,
            ISBN: "1593279280",
            img: "19381677_python-crash-course.jpg"
        },

        {
            id:51,
            title: "Make Python Talk",
            publisher: "No Starch Press",
            year: 2021,
            authors: ["Mark Liu"],
            language: "engleza",
            pages: 384,
            ISBN: "1718501560",
            img: "35600938o.jpg"
        },

        {
            id:52,
            title: "Learn Python Visually",
            publisher: "No Starch Press",
            year: 2021,
            authors: ["Tristan Bunn"],
            language: "engleza",
            pages: 240,
            ISBN: "1718500963",
            img: "33282825o.jpg"
        },

        {
            id:53,
            title: "Real-world Python",
            publisher: "No Starch Press",
            year: 2020,
            authors: [" Lee Vaughan"],
            language: "engleza",
            pages: 370,
            ISBN: "1718500629",
            img: "32278501o.jpg"
        },

        {
            id:54,
            title: "Beyond The Basic Stuff With Python",
            publisher: "No Starch Press",
            year: 2020,
            authors: ["Al Sweigart"],
            language: "engleza",
            pages: 448,
            ISBN: "1593279663",
            img: "20917651o.jpg"
        },

        {
            id:55,
            title: "Math for Deep Learning",
            publisher: "No Starch Press",
            year: 2021,
            authors: ["Ronald T. Kneusel"],
            language: "engleza",
            pages: 344,
            ISBN: "1718501900",
            img: "36537261o.jpg"
        },

        {
            id:56,
            title: "Bayesian Statistics The Fun Way",
            publisher: "No Starch Press",
            year: 2019,
            authors: ["Will Kurt"],
            language: "engleza",
            pages: 268,
            ISBN: "1593279566",
            img: "20546379_bayesian-statistics-the-fun-way.jpg"
        },

        {
            id:57,
            title: "Practice Of Network Security Monitoring",
            publisher: "No Starch Press",
            year: 2013,
            authors: ["Richard Bejtlich"],
            language: "engleza",
            pages: 376,
            ISBN: "1593275099",
            img: "01348938_practice-of-network-security-monitoring-understanding-incid.jpg"
        },

        {
            id:58,
            title: "Practical Packet Analysis, 3e",
            publisher: "No Starch Press",
            year: 2017,
            authors: ["Chris Sanders"],
            language: "engleza",
            pages: 368,
            ISBN: "1593278020",
            img: "15357589_practical-packet-analysis.jpg"
        },

        {
            id:59,
            title: "Rust Programming Language",
            publisher: "No Starch Press",
            year: 2019,
            authors: ["Steve Klabnik","Carol Nichols"],
            language: "engleza",
            pages: 552,
            ISBN: "1718500440",
            img: "22575074_rust-programming-language-covers-rust-2018.jpg"
        },

        {
            id:60,
            title: "Node.js 8 the Right Way",
            publisher: "The Pragmatic Programmers",
            year: 2018,
            authors: ["Jim Wilson"],
            language: "engleza",
            pages: 334,
            ISBN: "168050195X",
            img: "16097917_node-js-8-the-right-way.jpg"
        },

        {
            id:61,
            title: "A network defender's guide to threat detection: Using Zeek, Elasticsearch, Logstash, Kibana, Tor, and more.",
            publisher: "Independently Published",
            year: 2020,
            authors: ["Richard K. Medlin"],
            language: "engleza",
            pages: 202,
            ISBN: "9798649104074",
            img: "9798649104074-fr.jpg"
        },

        {
            id:62,
            title: "Hadoop Practice Guide: SQOOP, PIG, HIVE, HBASE for Beginners",
            publisher: "HARPERCOLLINS 360",
            year: 2019,
            authors: ["Jisha Mariam Jose"],
            language: "engleza",
            pages: 236,
            ISBN: "9781645877530",
            img: "23890016_hadoop-practice-guide-sqoop-pig-hive-hbase-for-beginners.jpg"
        },

        {
            id:63,
            title: "Wireshark Fundamentals",
            publisher: "Apress",
            year: 2021,
            authors: ["Vinit Jain"],
            language: "engleza",
            pages: 260,
            ISBN: "978-1484280010",
            img: "38500839_wireshark-fundamentals.jpg"
        },

        {
            id:64,
            title: "High-Performance Java Persistence",
            publisher: "Vlad Mihalcea",
            year: 2016,
            authors: ["Vlad Mihalcea"],
            language: "engleza",
            pages: 448,
            ISBN: "973022823X",
            img: "18043709_high-performance-java-persistence.jpg"
        },

        {
            id:65,
            title: "Make Your Own Neural Network",
            publisher: "Createspace Independent Pub",
            year: 2016,
            authors: ["Tariq Rashid"],
            language: "engleza",
            pages: 222,
            ISBN: "1530826608",
            img: "13503539_make-your-own-neural-network.jpg"
        },

        {
            id:66,
            title: "Make Your First GAN With PyTorch",
            publisher: "Independently Published",
            year: 2020,
            authors: ["Tariq Rashid"],
            language: "engleza",
            pages: 208,
            ISBN: "9798624728158",
            img: "32587609_make-your-first-gan-with-pytorch.jpg"
        },

        {
            id:67,
            title: "Teoria rețelelor neuronale artificiale",
            publisher: "Editura Universitatii din Bucuresti",
            year: 2009,
            authors: ["Dumitru Popescu, Maria Luiza Flonta"],
            language: "romana",
            pages: 206,
            ISBN: "978-973-737-682-4",
            img: "teoria-retelelor-neuronale.jpg"
        },

        {
            id:68,
            title: "Head First Design Patterns",
            publisher: "O'Reilly",
            year: 2020,
            authors: ["Elisabeth Robson"],
            language: "engleza",
            pages: 694,
            ISBN: "149207800X",
            img: "32850198o.jpg"
        },

        {
            id:69,
            title: "OCP Oracle Certified Professional Java SE 11 Developer Complete Study Guide: Exam 1Z0–815, Exam 1Z0–816, and Exam 1Z0–817",
            publisher: "Sybex",
            year: 2020,
            authors: ["Jeanne Boyarsky", "Scott Selikoff"],
            language: "engleza",
            pages: 1296,
            ISBN: "9781119619130",
            img: "51X+6RFvoDL._SX397_BO1,204,203,200_.jpg"
        },

        {
            id:70,
            title: "Writing Compilers and Interpreters",
            publisher: "John Wiley & Sons Inc",
            year: 2009,
            authors: ["Ronald Mak"],
            language: "engleza",
            pages: 864,
            ISBN: "0470177071",
            img: "04379833_writing-compilers-and-interpreters.jpg"
        },

        {
            id:71,
            title: "Cloud Native Microservices with Spring and Kubernetes",
            publisher: "BPB Publications",
            year: 2021,
            authors: ["Rajiv Srivastava"],
            language: "engleza",
            pages: 454,
            ISBN: "9390684315",
            img: "51Q-BZHcrWS._SX373_BO1,204,203,200_.jpg"
        },

        {
            id:72,
            title: "Machine Learning for Beginners: Learn to Build Machine Learning Systems Using Python",
            publisher: "BPB Publications",
            year: 2020,
            authors: ["Harsh Bhasin"],
            language: "engleza",
            pages: 244,
            ISBN: "978-9389845426",
            img: "machine-learning-for-beginners.jpg"
        },

        {
            id:73,
            title: "Discrete Structure and Automata Theory for Learners: Learn Discrete Structure Concepts and Automata Theory with JFLAP",
            publisher: "BPB Publications",
            year: 2020,
            authors: ["Umesh Sehgal","Sukhpreet Kaur Gill"],
            language: "engleza",
            pages: 370,
            ISBN: "9389845386",
            img: "33222674o.jpg"
        },

        {
            id:74,
            title: "Object Oriented Programming with Angular: Build and Deploy Your Web Application Using Angular with Ease",
            publisher: "BPB Publications",
            year: 2020,
            authors: ["Balram Chavan"],
            language: "engleza",
            pages: 384,
            ISBN: "9389328365",
            img: "33222634o.jpg"
        },

        {
            id:75,
            title: "Machine Learning",
            publisher: "MIT Press Ltd",
            year: 2020,
            authors: ["Ethem Alpaydin"],
            language: "engleza",
            pages: 224,
            ISBN: "9780262529518",
            img: "01006012_machine-learning.jpg"
        },

        {
            id:76,
            title: "Deep Learning",
            publisher: "MIT Press Ltd",
            year: 2019,
            authors: ["John D. Kelleher"],
            language: "engleza",
            pages: 296,
            ISBN: "0262537559",
            img: "22121083_deep-learning.jpg"
        },

        {
            id:77,
            title: "Deep Learning for Beginners",
            publisher: "Data Science",
            year: 2019,
            authors: ["Steven Cooper"],
            language: "engleza",
            pages: 188,
            ISBN: "3903331465",
            img: "9783903331075.jpg"
        },

        {
            id:78,
            title: "Neural Networks",
            publisher: "Data Science",
            year: 2019,
            authors: ["Steven Cooper"],
            language: "engleza",
            pages: 172,
            ISBN: "3903331570",
            img: "25338771_neural-networks-a-practical-guide-for-u.jpg"
        },

        {
            id:79,
            title: "Deep Learning",
            publisher: "MIT Press Ltd",
            year: 2016,
            authors: ["Ian Goodfellow","Yoshua Bengio","Aaron Courville"],
            language: "engleza",
            pages: 800,
            ISBN: "0262035618",
            img: "13523318_deep-learning.jpg"
        },

        {
            id:80,
            title: "Neural Network Design (2nd Edition)",
            publisher: "Martin Hagan",
            year: 2014,
            authors: ["Martin T Hagan","Howard B Demuth","Mark H Beale"],
            language: "engleza",
            pages: 802,
            ISBN: "0971732116",
            img: "16870778_neural-network-design-2nd-edition.jpg"
        },

        {
            id:81,
            title: "Machine Learning",
            publisher: "John Wiley & Sons Inc",
            year: 2020,
            authors: ["Jason Bell"],
            language: "engleza",
            pages: 432,
            ISBN: "1119642140",
            img: "23362702_machine-learning-hands-on-for-developers-and-technical-professionals.jpg"
        },

        {
            id:82,
            title: "Machine Learning in Java",
            publisher: "Packt Publishing Limited",
            year: 2018,
            authors: ["AshishSingh Bhatia","Bostjan Kaluza"],
            language: "engleza",
            pages: 300,
            ISBN: "1788474392",
            img: "21028265_machine-learning-in-java.jpg"
        },

        {
            id:83,
            title: "Mastering Java Machine Learning",
            publisher: "Packt Publishing Limited",
            year: 2017,
            authors: ["Dr. Uday Kamath","Krishna Choppella"],
            language: "engleza",
            pages: 556,
            ISBN: "1785880519",
            img: "16625959_mastering-java-machine-learning.jpg"
        },

        {
            id:84,
            title: "Practical Java Machine Learning",
            publisher: "Apress",
            year: 2018,
            authors: ["Mark Wickham"],
            language: "engleza",
            pages: 392,
            ISBN: "1484239504",
            img: "20000020_practical-java-machine-learning.jpg"
        },

        {
            id:85,
            title: "Functional Programming in Java",
            publisher: "The Pragmatic Programmers",
            year: 2014,
            authors: ["Venkat Subramaniam"],
            language: "engleza",
            pages: 160,
            ISBN: "1937785467",
            img: "02319382_functional-programming-in-java.jpg"
        },

        {
            id:86,
            title: "Reactive Programming with RxJS",
            publisher: "The Pragmatic Programmers",
            year: 2015,
            authors: ["Sergi Mansilla"],
            language: "engleza",
            pages: 126,
            ISBN: "9781680501292",
            img: "02814150_reactive-programming-with-rxjs.jpg"
        },

        {
            id:87,
            title: "Java Deep Learning Essentials",
            publisher: "Packt Publishing Limited",
            year: 2016,
            authors: ["Yusuke Sugomori"],
            language: "engleza",
            pages: 254,
            ISBN: "1785282190",
            img: "12460392_java-deep-learning-essentials.jpg"
        },

        {
            id:88,
            title: "Java Deep Learning Projects",
            publisher: "Packt Publishing Limited",
            year: 2018,
            authors: ["Md. Rezaul Karim"],
            language: "engleza",
            pages: 436,
            ISBN: "9781788997454",
            img: "19783973_java-deep-learning-projects.jpg"
        },

        {
            id:89,
            title: "Hands-On Java Deep Learning for Computer Vision",
            publisher: "Packt Publishing Limited",
            year: 2019,
            authors: ["Klevis Ramo"],
            language: "engleza",
            pages: 260,
            ISBN: "9781789613964",
            img: "22013253_hands-on-java-deep-learning-for-computer-vision.jpg"
        },

        {
            id:90,
            title: "Neural Network Programming with Java",
            publisher: "Packt Publishing Limited",
            year: 2017,
            authors: ["Alan M. F. Souza","Fabio M. Soares"],
            language: "engleza",
            pages: 270,
            ISBN: "9781787126053",
            img: "16085852_neural-network-programming-with-java.jpg"
        },

        {
            id:91,
            title: "PyTorch Pocket Reference",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Joe Papa"],
            language: "engleza",
            pages: 265,
            ISBN: "9781492090007",
            img: "pytorch-pocket-reference.jpg"
        },

        {
            id:92,
            title: "TensorFlow 2 Pocket Reference",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["KC Tung"],
            language: "engleza",
            pages: 300,
            ISBN: "9781492089186",
            img: "tensorflow-2-pocket-reference.jpg"
        },

        {
            id:93,
            title: "Hands-on Machine Learning with Scikit-Learn, Keras, and TensorFlow",
            publisher: "O'Reilly",
            year: 2019,
            authors: ["Aurelien Geron"],
            language: "engleza",
            pages: 600,
            ISBN: "9781492032649",
            img: "19700386_hands-on-machine-learning-with-scikit-learn-keras-and-tensorflow.jpg"
        },

        {
            id:94,
            title: "Reactive Systems in Java",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Clement Escoffier","Ken Finnigan"],
            language: "engleza",
            pages: 296,
            ISBN: "1492091723",
            img: "reactive-systems-in-java.jpg"
        },

        {
            id:95,
            title: "Robust Python",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Patrick Viafore"],
            language: "engleza",
            pages: 380,
            ISBN: "9781098100667",
            img: "robust-python.jpg"
        },

        {
            id:96,
            title: "Scala Cookbook, 2E",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Alvin Alexander"],
            language: "engleza",
            pages: 750,
            ISBN: "9781492051541",
            img: "scala-cookbook-2e.jpg"
        },

        {
            id:97,
            title: "Programming Scala, 3e",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Dean Wampler"],
            language: "engleza",
            pages: 550,
            ISBN: "9781492077893",
            img: "programming-scala-3e.jpg"
        },

        {
            id:98,
            title: "Programming Scala, 3e",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Dean Wampler"],
            language: "engleza",
            pages: 550,
            ISBN: "9781492077893",
            img: "programming-scala-3e.jpg"
        },

        {
            id:99,
            title: "Learning Spark 2e",
            publisher: "O'Reilly",
            year: 2020,
            authors: ["Jules Damji","Brooke Wenig","Tathagata Das","Denny Lee"],
            language: "engleza",
            pages: 300,
            ISBN: "9781492050049",
            img: "learning-spark-2e.jpg"
        },

        {
            id:100,
            title: "Mastering Kafka Streams and ksqlDB",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Mitch Seymour"],
            language: "engleza",
            pages: 400,
            ISBN: "9781492062493",
            img: "mastering-kafka-streams-and-ksqldb.jpg"
        },

        {
            id:101,
            title: "React Cookbook",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["David Griffiths","Dawn Griffiths"],
            language: "engleza",
            pages: 500,
            ISBN: "9781492085843",
            img: "react-cookbook.jpg"
        },

        {
            id:102,
            title: "Kafka: The Definitive Guide, 2nd Edition",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Gwen Shapira","Todd Palino","Rajini Sivaram","Krit Perry"],
            language: "engleza",
            pages: 425,
            ISBN: "9781492043089",
            img: "kafka-the-definitive-guide-2nd-edition.jpg"
        },

        {
            id:103,
            title: "Java to Kotlin",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Duncan Mcgregor","Nat Pryce"],
            language: "engleza",
            pages: 300,
            ISBN: "9781492082279",
            img: "java-to-kotlin.jpg"
        },

        {
            id:104,
            title: "Learning Spring Boot 2.0 - Second Edition",
            publisher: "Packt Publishing",
            year: 2017,
            authors: ["Greg L. Turnquist"],
            language: "engleza",
            pages: 370,
            ISBN: "9781786463784",
            img: "learning-spring-boot-2-0-second-edition.jpg"
        },

        {
            id:105,
            title: "Hacking with Spring Boot 2.3: Reactive Edition",
            publisher: "Independently Published",
            year: 2020,
            authors: ["Greg L. Turnquist"],
            language: "engleza",
            pages: 394,
            ISBN: "9798643893974",
            img: "51wd2SKn0LL.jpg"
        },

        {
            id:106,
            title: "Pandas Brain Teasers",
            publisher: "The Pragmatic Programmers",
            year: 2021,
            authors: ["Miki Tebeka"],
            language: "engleza",
            pages: 100,
            ISBN: "1680509012",
            img: "37057248o.jpg"
        },

        {
            id:107,
            title: "Python Brain Teasers",
            publisher: "The Pragmatic Programmers",
            year: 2021,
            authors: ["Miki Tebeka"],
            language: "engleza",
            pages: 100,
            ISBN: "1680509004",
            img: "37026591o.jpg"
        },

        {
            id:108,
            title: "Learn Microservices with Spring Boot",
            publisher: "APress",
            year: 2017,
            authors: ["Moises Macero"],
            language: "engleza",
            pages: 330,
            ISBN: "1484231643",
            img: "18136473_learn-microservices-with-spring-boot.jpg"
        },

        {
            id:109,
            title: "Introduction to Compiler Design",
            publisher: "Softmoore Consulting",
            year: 2020,
            authors: ["John I. Moore, Jr."],
            language: "engleza",
            pages: 302,
            ISBN: "1734139110",
            img: "33301840o.jpg"
        },

        {
            id:110,
            title: "Spring Boot 2: How To Get Started and Build a Microservice - Third Edition",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Jens Boje"],
            language: "engleza",
            pages: 110,
            ISBN: "1694462838",
            img: "27271589_spring-boot-2-how-to-get-started-and-build-a-microservice-third-edition.jpg"
        },

        {
            id:111,
            title: "Introduction to Java Spring Boot: Learning By Coding",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Dave Wolf","Afua Ankomah","Jennifer Lee","AJ Henley Jr","Victor Phimphachanh"],
            language: "engleza",
            pages: 134,
            ISBN: "1088797245",
            img: "26046996_introduction-to-java-spring-boot-learning-by-coding.jpg"
        },

        {
            id:112,
            title: "Python Basics: A Practical Introduction to Python 3",
            publisher: "RON HOLLAND DESIGNS",
            year: 2021,
            authors: ["David Amos","Dan Bader","Joanna Jablonski","Fletcher Heisler"],
            language: "engleza",
            pages: 636,
            ISBN: "9781775093329",
            img: "python-basics-a-practical-introduction-to-python-3.jpg"
        },

        {
            id:113,
            title: "OCA: Oracle Certified Associate Java SE 8 Programmer I Study Guide",
            publisher: "John Wiley & Sons Inc",
            year: 2015,
            authors: ["Jeanne Boyarsky","Scott Selikoff"],
            language: "engleza",
            pages: 432,
            ISBN: "1118957407",
            img: "05172994_oca-oracle-certified-associate-java-se-8-programmer-i-study-guide.jpg"
        },

        {
            id:114,
            title: "OCP: Oracle Certified Professional Java SE 8 Programmer II Study Guide",
            publisher: "John Wiley & Sons Inc",
            year: 2016,
            authors: ["Jeanne Boyarsky","Scott Selikoff"],
            language: "engleza",
            pages: 720,
            ISBN: "9781119067900",
            img: "03025884_ocp-oracle-certified-professional-java-se-8-programmer-ii-study-guide-exam-xxx.jpg"
        },

        {
            id:115,
            title: "OCP Oracle Certified Professional Java SE 11 Developer Practice Tests: Exam 1Z0–819 and Upgrade Exam 1Z0–817",
            publisher: "John Wiley & Sons Inc",
            year: 2021,
            authors: ["Jeanne Boyarsky","Scott Selikoff"],
            language: "engleza",
            pages: 608,
            ISBN: "9781119696131",
            img: "ocp-oracle-certified-professional-java-se-11-developer-practice-tests.jpg"
        },

        {
            id:116,
            title: "GO Programming in easy steps",
            publisher: "In Easy Steps Limited",
            year: 2020,
            authors: ["Mike McGrath"],
            language: "engleza",
            pages: 192,
            ISBN: "1840789190",
            img: "33209533o.jpg"
        },

        {
            id:117,
            title: "Python in easy steps",
            publisher: "In Easy Steps Limited",
            year: 2018,
            authors: ["Mike McGrath"],
            language: "engleza",
            pages: 192,
            ISBN: "9781840788129",
            img: "18879142_python-in-easy-steps.jpg"
        },

        {
            id:118,
            title: "Java in easy steps",
            publisher: "In Easy Steps Limited",
            year: 2019,
            authors: ["Mike McGrath"],
            language: "engleza",
            pages: 192,
            ISBN: "1840788739",
            img: "22300470_java-in-easy-steps.jpg"
        },

        {
            id:119,
            title: "Machine Learning For Dummies",
            publisher: "John Wiley & Sons Inc",
            year: 2021,
            authors: ["John Paul Mueller","Luca Massaron"],
            language: "engleza",
            pages: 464,
            ISBN: "1119724015",
            img: "32848824o.jpg"
        },

        {
            id:120,
            title: "Clean Code: A Handbook of Agile Software Craftmanship",
            publisher: "Prentice Hall PTR",
            year: 2009,
            authors: ["Robert C. Martin"],
            language: "engleza",
            pages: 431,
            ISBN: "9780132350884",
            img: "clean-code.jpg"
        },

        {
            id:121,
            title: "Compilers: Principles, Techniques, and Tools: International Edition",
            publisher: "Pearson",
            year: 2006,
            authors: ["Alfred V. Aho","Monica S. Lam","Ravi Sethi","Jeffrey D. Ullman"],
            language: "engleza",
            pages: 1000,
            ISBN: "0321491696",
            img: "51ItQhzp9JL._SX344_BO1,204,203,200_.jpg"
        },


        {
            id:122,
            title: "Kubernetes Vs. Docker: A Step-by-Step Guide to Learn and Master Kubernetes and Docker",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Brayden Smith"],
            language: "engleza",
            pages: 146,
            ISBN: "1713120496",
            img: "28066874_kubernetes-vs-docker-a-step-by-step-guide-to-learn-and-master-kubernetes-and-docker.jpg"
        },

        {
            id:123,
            title: "Docker: A Step-by-Step Guide to Learn and Master Docker",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Brayden Smith"],
            language: "engleza",
            pages: 58,
            ISBN: "1083161709",
            img: "25931787_docker-a-step-by-step-guide-to-learn-and-master-docker.jpg"
        },

        {
            id:124,
            title: "Spring Boot and Single-Page Applications: Securing Your API with a Single-Page Application Frontend second edition",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Jens Boje"],
            language: "engleza",
            pages: 66,
            ISBN: "1694463559",
            img: "27271618_spring-boot-and-single-page-applications-securing-your-api-with-a-single-page-application-frontend-second-edition.jpg"
        },

        {
            id:125,
            title: "Docker & Kubernetes Fundamentals",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Ajay Kumar"],
            language: "engleza",
            pages: 90,
            ISBN: "1691356549",
            img: "611x7r4gmFL.jpg"
        },

        {
            id:126,
            title: "Docker: A Deep Dive, Step - By - Step Guide for Beginners to Learn and Master Docker",
            publisher: "Independently Published",
            year: 2019,
            authors: ["Michael Brian"],
            language: "engleza",
            pages: 134,
            ISBN: "1675270244",
            img: "26740109_docker-a-deep-dive-step-by-step-guide-for-beginners-to-learn-and-master-docker.jpg"
        },

        {
            id:127,
            title: "Make Your Own Neural Network: An In-Depth Visual Introduction for Beginners",
            publisher: "LIGHTNING SOURCE INC",
            year: 2017,
            authors: ["Michael Taylor"],
            language: "engleza",
            pages: 250,
            ISBN: "9781549869136",
            img: "20296683o.jpg"
        },

        {
            id:128,
            title: "The Math of Neural Networks",
            publisher: "LIGHTNING SOURCE INC",
            year: 2017,
            authors: ["Michael Taylor"],
            language: "engleza",
            pages: 168,
            ISBN: "9781549893643",
            img: "20296684_the-math-of-neural-networks.jpg"
        },

        {
            id:129,
            title: "OCP Oracle Certified Professional Java SE 17 Developer Study Guide",
            publisher: "John Wiley & Sons Inc",
            year: 2022,
            authors: ["Scott Selikoff","Jeanne Boyarsky"],
            language: "engleza",
            pages: 976,
            ISBN: "1119864585",
            img: "37087045o.jpg"
        },

        {
            id:130,
            title: "Generative Art: Algorithms as Artistic Tool",
            publisher: "Durvile",
            year: 2019,
            authors: ["James R. Parker"],
            language: "engleza",
            pages: 272,
            ISBN: "1988824389",
            img: "21203260_generative-art-randomness-as-artistic-tool.jpg"
        },

        {
            id:131,
            title: "Istoria informaticii românești. Apariție, dezvoltare și impact. Oameni, instituții, concepte, teorii și tehnologii - vol I: Computing - Contextul international",
            publisher: "Matrix Rom",
            year: 2019,
            authors: ["Marin Vlada"],
            language: "romana",
            pages: 436,
            ISBN: "978-606-25-0495-3",
            img: "ii5-1.jpg"
        },

        {
            id:132,
            title: "Istoria informaticii romanesti. Aparitie, dezvoltare si impact. Oameni, institutii, concepte, teorii si tehnologii - vol II: Computing - Contexul national",
            publisher: "Matrix Rom",
            year: 2019,
            authors: ["Marin Vlada"],
            language: "romana",
            pages: 488,
            ISBN: "978-606-25-0498-4",
            img: "ii6.jpg"
        },

        {
            id:133,
            title: "Istoria informaticii romanesti. Aparitie, dezvoltare si impact. Oameni, institutii, concepte, teorii si tehnologii - vol II: Computing - Aparitie si dezvoltare",
            publisher: "Matrix Rom",
            year: 2020,
            authors: ["Marin Vlada"],
            language: "romana",
            pages: 546,
            ISBN: "978-606-25-0552-3",
            img: "II7_VOL3.jpg"
        },

        {
            id:134,
            title: "Istoria informaticii romanesti. Aparitie, dezvoltare si impact. Oameni, institutii, concepte, teorii si tehnologii - vol II: Computing - Dezvoltare si impact",
            publisher: "Matrix Rom",
            year: 2020,
            authors: ["Marin Vlada"],
            language: "romana",
            pages: 512,
            ISBN: "978-606-25-0553-0",
            img: "II7_VOL3.jpg"
        },

        {
            id:135,
            title: "Algoritmi de optimizare in grafuri",
            publisher: "Editura Cadrelor Didactice",
            year: 2015,
            authors: ["Ciprian Ghise"],
            language: "romana",
            pages: 77,
            ISBN: "978-606-8426-60-0",
            img: "res_b3415d607c97c8ea2b98684f62381213.jpg"
        },

        {
            id:136,
            title: "Algoritmi probabilisti",
            publisher: "Editura Cadrelor Didactice",
            year: 2015,
            authors: ["Ciprian Ghise"],
            language: "romana",
            pages: 97,
            ISBN: "978-606-583-576-4",
            img: "res_7f112d2de0f118ac15ca9548f65578c4.jpg"
        },

        {
            id:137,
            title: "Inteligenta artificiala. Masini care gandesc si roboti inteligenti - cu activitati stiintifice pentru copii",
            publisher: "Paralela 45",
            year: 2020,
            authors: ["Angie Smibert"],
            language: "romana",
            pages: 128,
            ISBN: "9789734731961",
            img: "857189496-0-240.jpeg"
        },

        {
            id:138,
            title: "Curs de programare pentru copii. De la limbajele Scratch si Python la conceperea de jocuri",
            publisher: "Litera",
            year: 2015,
            authors: ["Carol Vorderman"],
            language: "romana",
            pages: 160,
            ISBN: "9786067413977",
            img: "9786067413977-2818973.jpg"
        },

        {
            id:139,
            title: "Proiectarea algoritmilor in limbajul Python",
            publisher: "Sitech",
            year: 2020,
            authors: ["Laviniu Aurelian Badulescu"],
            language: "romana",
            pages: 264,
            ISBN: "9786061175635",
            img: "python-sitech.jpg"
        },

        {
            id:140,
            title: "Limbajul Python un curs practic",
            publisher: "Sitech",
            year: 2020,
            authors: ["Laviniu Aurelian Badulescu"],
            language: "romana",
            pages: 255,
            ISBN: "9786061174478",
            img: "sitechlimbajulpythonunucrspractic.jpg"
        },

        {
            id:141,
            title: "Programarea placii Arduino",
            publisher: "Paralela 45",
            year: 2016,
            authors: ["Traian Anghel"],
            language: "romana",
            pages: 352,
            ISBN: "9789734724024",
            img: "13319431.jpg"
        },

        {
            id:142,
            title: "Creating Apps with React Native: Deliver Cross-Platform 0 Crash, 5 Star Apps",
            publisher: "Apress",
            year: 2022,
            authors: ["Holmes He"],
            language: "engleza",
            pages: 436,
            ISBN: "9781484280416",
            img: "creating-apps-with-react-native.jpg"
        },

        {
            id:143,
            title: "Cryptography and Cryptanalysis in Java: Creating and Programming Advanced Algorithms with Java SE 17 LTS and Jakarta EE 10",
            publisher: "Apress",
            year: 2022,
            authors: ["Stefania Loredana Nita","Marius Iulian Mihailescu"],
            language: "engleza",
            pages: 227,
            ISBN: "9781484281048",
            img: "cryptography-and-cryptanalysis-in-java.jpg"
        },

        {
            id:144,
            title: "A Complete Guide to Docker for Operations and Development : Test-Prep for the Docker Certified Associate (DCA) Exam",
            publisher: "Apress",
            year: 2022,
            authors: ["Engy Fouda"],
            language: "engleza",
            pages: 201,
            ISBN: "9781484281161",
            img: "a-complete-guide-to-docker-for-operations-and-development.jpg"
        },

        {
            id:145,
            title: "The Programmer's Brain: What Every Programmer Needs to Know about Cognition",
            publisher: "MANNING PUBN",
            year: 2021,
            authors: ["Felienne Hermans"],
            language: "engleza",
            pages: 256,
            ISBN: "9781617298677",
            img: "35544327o.jpg"
        },

        {
            id:146,
            title: "Jump Start Vue.js 2e",
            publisher: "Sitepoint",
            year: 2021,
            authors: ["Nilson Jacques"],
            language: "engleza",
            pages: 170,
            ISBN: "9781925836448",
            img: "jump-start-vue-js-2e.jpg"
        },

        {
            id:147,
            title: "Just React!",
            publisher: "APress",
            year: 2022,
            authors: ["Hari Narayn"],
            language: "engleza",
            pages: 369,
            ISBN: "9781484282939",
            img: "39202333_just-react.jpg"
        },

        {
            id:148,
            title: "Hands- On Liferay DXP",
            publisher: "APress",
            year: 2022,
            authors: ["Apoorva Prakash","Shaik Inthiyaz Basha"],
            language: "engleza",
            pages: 251,
            ISBN: "9781484285626",
            img: "39240077_hands-on-liferay-dxp.jpg"
        },

        {
            id:149,
            title: "CI/CD Pipeline Using Jenkins Unleashed",
            publisher: "APress",
            year: 2022,
            authors: ["Pranoday Dingare"],
            language: "engleza",
            pages: 420,
            ISBN: "9781484275078",
            img: "37151281_ci-cd-pipeline-using-jenkins-unleashed.jpg"
        },

        {
            id:150,
            title: "Book Of Kubernetes",
            publisher: "No Starch Press",
            year: 2022,
            authors: ["Alan Hohn"],
            language: "engleza",
            pages: 304,
            ISBN: "1718502648",
            img: "39023902_the-book-of-kubernetes-a-hands-on-deep-dive-into-container-technology.jpg"
        },

        {
            id:151,
            title: "The Linux Command Line, 2nd Edition",
            publisher: "No Starch Press",
            year: 2019,
            authors: ["William Shotts"],
            language: "engleza",
            pages: 480,
            ISBN: "9781593279523",
            img: "20296719_linux-command-line.jpg"
        },

        {
            id:152,
            title: "DevOps for the Desperate",
            publisher: "No Starch Press",
            year: 2022,
            authors: ["Bradley Smith"],
            language: "engleza",
            pages: 176,
            ISBN: "1718502486",
            img: "38474243o.jpg"
        },

        {
            id:153,
            title: "Ansible - Up and Running",
            publisher: "O'Reilly",
            year: 2022,
            authors: ["Bas Meijer","Lorin Hochstein","René Moser"],
            language: "engleza",
            pages: 468,
            ISBN: "1098109155",
            img: "38818437o.jpg"
        },

        {
            id:154,
            title: "Beginning Ansible Concepts and Application",
            publisher: "APress",
            year: 2022,
            authors: ["Shaun Smith","Peter Membrey"],
            language: "engleza",
            pages: 283,
            ISBN: "9781484281727",
            img: "38806236_beginning-ansible-concepts-and-application.jpg"
        },

        {
            id:155,
            title: "Practical Ansible",
            publisher: "APress",
            year: 2021,
            authors: ["Vincent Sesto"],
            language: "engleza",
            pages: 352,
            ISBN: "9781484286425",
            img: "39438424_practical-ansible.jpg"
        },

        {
            id:156,
            title: "Pro Vagrante",
            publisher: "APress",
            year: 2015,
            authors: ["Wlodzimierz Gajda"],
            language: "engleza",
            pages: 256,
            ISBN: "1484200748",
            img: "02688185_pro-vagrant.jpg"
        },

        {
            id:157,
            title: "Kubernetes Native Development",
            publisher: "APress",
            year: 2022,
            authors: ["Benjamin Schmeling","Maximilian Dargatz"],
            language: "engleza",
            pages: 398,
            ISBN: "1484279417",
            img: "38401113_kubernetes-native-development.jpg"
        },

        {
            id:158,
            title: "Kubernetes",
            publisher: "APress",
            year: 2020,
            authors: ["Philippe Martin"],
            language: "engleza",
            pages: 231,
            ISBN: "1484264932",
            img: "33196247_kubernetes-preparing-for-the-cka-and-ckad-certifications.jpg"
        },

        {
            id:159,
            title: "Kubernetes Application Developer",
            publisher: "APress",
            year: 2022,
            authors: ["Prateek Khushalani"],
            language: "engleza",
            pages: 164,
            ISBN: "1484280318",
            img: "39287314_kubernetes-application-developer.jpg"
        },

        {
            id:160,
            title: "Advanced Platform Development with Kubernetes",
            publisher: "APress",
            year: 2020,
            authors: ["Craig Johnston"],
            language: "engleza",
            pages: 511,
            ISBN: "9781484256107",
            img: "25260025_advanced-platform-development-with-kubernetes.jpg"
        },

        {
            id:161,
            title: "Beginning Kubernetes on the Google Cloud Platform",
            publisher: "APress",
            year: 2019,
            authors: ["Ernesto Garbarino"],
            language: "engleza",
            pages: 324,
            ISBN: "1484254902",
            img: "24805936_beginning-kubernetes-on-the-google-cloud-platform-a-guide-to-automating-application-deployment-scaling-and-management.jpg"
        },

        {
            id:162,
            title: "Pro Google Kubernetes Engine",
            publisher: "APress",
            year: 2020,
            authors: ["Piyush Pandey"],
            language: "engleza",
            pages: 418,
            ISBN: "1484262425",
            img: "33030422_pro-google-kubernetes-engine-network-security-monitoring-and-automation-configuration.jpg"
        },

        {
            id:163,
            title: "Kubernetes Management Design Patterns",
            publisher: "APress",
            year: 2017,
            authors: ["Deepak Vohra"],
            language: "engleza",
            pages: 399,
            ISBN: "148422597X",
            img: "15447463_kubernetes-management-design-patterns.jpg"
        },

        {
            id:164,
            title: "Extending Kubernetes",
            publisher: "APress",
            year: 2021,
            authors: ["Onur Yilmaz"],
            language: "engleza",
            pages: 247,
            ISBN: "1484270940",
            img: "35543826_extending-kubernetes-elevate-kubernetes-with-extension-patterns-operators-and-plugins.jpg"
        },

        {
            id:165,
            title: "Spring in Action",
            publisher: "Manning Publications",
            year: 2022,
            authors: ["Craig Walls"],
            language: "engleza",
            pages: 520,
            ISBN: "9781617297571",
            img: "35544321o.jpg"
        },

        {
            id:166,
            title: "Certified Kubernetes Application Developer (CKAD) Study Guide",
            publisher: "O'Reilly",
            year: 2021,
            authors: ["Benjamin Muschko"],
            language: "engleza",
            pages: 200,
            ISBN: "9781492083733",
            img: "33450735o.jpg"
        },

        {
            id:167,
            title: "Cloud Native Spring in Action: With Spring Boot and Kubernetes [pe drum]",
            publisher: "Manning Publications",
            year: 2023,
            authors: ["Thomas Vitale"],
            language: "engleza",
            pages: 475,
            ISBN: "9781617298424",
            img: "36512614o.jpg"
        },

        {
            id:168,
            title: "Infrastructure Automation with Terraform [pe drum]",
            publisher: "BPB Publications",
            year: 2022,
            authors: ["Mitesh Soni"],
            language: "engleza",
            pages: 468,
            ISBN: "935551090X",
            img: "39229197o.jpg"
        },

        {
            id:169,
            title: "Testing Java Microservices [pe drum]",
            publisher: "Manning Publication",
            year: 2018,
            authors: ["Alex Soto Bueno","Jason Porter","Andy Gumbrecht"],
            language: "engleza",
            pages: 325,
            ISBN: "1617292893",
            img: "14131982_testing-java-microservices.jpg"
        },

        {
            id:171,
            title: "Terraform - Up and Running [pe drum]",
            publisher: "O'Reilly Media",
            year: 2022,
            authors: ["Yevgeniy Brikman"],
            language: "engleza",
            pages: 459,
            ISBN: "1098116747",
            img: "38926425o.jpg"
        },

        {
            id:172,
            title: "Terraform in Action [pe drum]",
            publisher: "MANNING PUBN",
            year: 2021,
            authors: ["Scott Winkler"],
            language: "engleza",
            pages: 408,
            ISBN: "1617296899",
            img: "25382718_terraform-in-action.jpg"
        },

        {
            id:173,
            title: "You Don't Know JS Yet: Get Started",
            publisher: "Independently Published",
            year: 2020,
            authors: ["Kyle Simpson"],
            language: "engleza",
            pages: 1,
            ISBN: "9798602477429",
            img: "9798602477429-us.jpg"
        },

        {
            id:174,
            title: "You Don't Know JS Yet: Scope & Closures",
            publisher: "Independently Published",
            year: 2020,
            authors: ["Kyle Simpson"],
            language: "engleza",
            pages: 1,
            ISBN: "9798621536459",
            img: "9798621536459-us.jpg"
        },

        {
            id:175,
            title: "CSS: The Definitive Guide: Web Layout and Presentation - 5th Edition",
            publisher: "O'Reilly Media",
            year: 2023,
            authors: ["Eric Meyer", "Estelle Weyl"],
            language: "engleza",
            pages: 1126,
            ISBN: "978-1098117610",
            img: "42655801o.jpg"
        },




    ]

    handleSearch(event){
        let search = event.target.value;

        // let filtredBooks = ITBooksContent.books.filter((item) =>
        //     item.title.toLowerCase().includes(search.toLowerCase())
        // );
        //
        // this.setState({filtredBooks: filtredBooks});

        this.setState({search: search});
    }

    render() {

        this.state.filtredBooks = ITBooksContent.books;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)} />
                    </label>
                    &nbsp;
                    <span>Numar total carti: {ITBooksContent.books.length}</span>
                </div>

                <hr/>

                <div className={"text-justify important"}>

                    {this.state.filtredBooks
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a=>a.toLowerCase().includes(s));
                                return okTitle || okPublisher || okAuthors;
                            }
                        )
                        .map(function(item, index){
                        return <div className="row">

                            {/*<div className="col-sm-4">*/}
                            {/*    <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}*/}
                            {/*         src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>*/}

                            {/*</div>*/}

                            <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index+1}</span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250} rowSpan={"7"}>
                                            <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}
                                                    src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>
                                        </td>

                                        <td width={250}>
                                            <b>Titlu</b>
                                        </td>
                                        <td>
                                            <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Editura</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.publisher}}/></td>
                                    </tr>
                                    <tr>
                                        <td><b>An</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Autori</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Numar pagini</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.pages}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Limba</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>ISBN</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.ISBN}}/></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>;

                    })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ITBooksContent;