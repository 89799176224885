import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TestingGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-testing", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Testare</b>
                    <br/>
                    <br/>

                    Pentru a face un test, se creaza un fisier care se termina cu <b>_test.go</b>
                    <br/>
                    Pentru a rula toate testele dintr-un pachet se ruleaza comanda:
                    <SyntaxHighlighter>
                        {'go test'}
                    </SyntaxHighlighter>

                    In testare se foloseste functia: <b>Errorf</b> de tip <b>*testing.T</b>.

                    <hr/>
                    Exemplu:
                    <SyntaxHighlighter>
                        {'package main\n\nimport "testing"\n\nfunc TestCeva(t *testing.T) {\n\n	x := 20\n\n	t.Errorf("Astept x sa fie 21, dar este=%v", x)\n\n}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TestingGoContent;