import React from "react";
import {MathComponent} from "mathjax-react";
import IndexContent from "./IndexContent";
import BaseContentPage from "../BaseContentPage";

class AnexaSoftmaxContent extends BaseContentPage {

    constructor(props) {
        super(props, "anexa-softmax", IndexContent);
    }

    sumExp(data){
        let sum = 0;
        for(let i = 0;i<data.length;i++){
            sum = sum + Math.exp(data[i])
        }
        return sum;
    }

    softmax(value, sum){
        return Math.exp(value)/sum;
    }

    sigmoid(value){
        return 1/(1+Math.exp(-value));
    }

    render() {

        let z1 = [2.33,-1.46,0.56];

        let sum1 = this.sumExp(z1);

        let p11 = this.softmax(z1[0],sum1);
        let p12 = this.softmax(z1[1],sum1);
        let p13 = this.softmax(z1[2],sum1);

        let z2 = [0.4, 0.6];
        let sum2 = this.sumExp(z2);
        let p21 = this.softmax(z2[0],sum2);
        let p22 = this.softmax(z2[1],sum2);

        //let s21 = this.sigmoid(z2[0]);
        //let s22 = this.sigmoid(z2[1]);

        //let z3 = [0.59, 0.64];
        //let sum3 = this.sumExp(z3);
        //let p31 = this.softmax(z3[0],sum3);
        //let p32 = this.softmax(z3[1],sum3);

        return (

            <div className="home boltzmann">
                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    <div className={"text-justify important"}>
                        Funcția de activare este o parte integrantă a unei rețele neuronale. <b>Fără o funcție de activare</b>, o rețea neuronală este un <b>model de regresie liniar simplu</b>.
                        Aceasta înseamnă că funcția de activare conferă <b>neliniaritate</b> rețelei neuronale.
                    </div>
                    <br/>

                    <div className={"text-justify important"}>

                        Funcția de activare Softmax calculează <b>probabilitățile relative</b>.
                        Asta înseamnă că folosește valorile ajunse in output layer pentru a determina valoarea probabilității finale.

                        Functia de activare Softmax:
                            <MathComponent tex={String.raw`softmax(z_i)={{exp(z_i)} \over {\sum_j exp(z_j)}}`}/>

                        <br/>
                        <b>exemplu 1:</b>
                        <br/>
                        Sa presupunem că output layer are 3 neuroni, cu valorile:
                            <MathComponent tex={String.raw`z_1=2.33, z_2=-1.46, z_3=0.56`}/>

                        Calculam probabilitatile folosind functia de activare Softmax:
                            <MathComponent tex={String.raw`P(z_1)={{exp(2.33)} \over {exp(2.33)+exp(1.46)+exp(0.56)}}=0.83`}/> <p style={{fontSize:'10px'}}>(P(z1) = {p11})</p>
                            <MathComponent tex={String.raw`P(z_2)={{exp(1.46)} \over {exp(1.46)+exp(1.46)+exp(0.56)}}=0.01`}/> <p style={{fontSize:'10px'}}>(P(z2) = {p12})</p>
                            <MathComponent tex={String.raw`P(z_3)={{exp(0.56)} \over {exp(0.56)+exp(1.46)+exp(0.56)}}=0.14`}/> <p style={{fontSize:'10px'}}>(P(z3) = {p13})</p>

                        Suma propabailitatilor este 1.

                        <br/>
                        <br/>
                        <b>exemplu 2:</b>
                        <br/>
                        Sa presupunem că output layer are 2 neuroni, cu valorile:
                        <MathComponent tex={String.raw`z_1=0.4, z_2=0.6`}/>
                        Calculam probabilitatile folosind functia de activare Softmax:
                        <p>P(z_1) = {p21}</p>
                        <p>P(z_2) = {p22}</p>
                        <br/>
                        {/*Calculam, folsoind functia de activare Sigmoida:*/}
                        {/*<p>S(z_1) = {s21}</p>*/}
                        {/*<p>S(z_2) = {s22}</p>*/}
                        {/*<br/>*/}


                        <b>Observatie:</b> <br/>
                        Cand numărul de clase este 2, functia de activare <b>Softmax</b> devine același cu funcția de activare <b>Sigmoidă</b>.
                        Cu alte cuvinte, sigmoidul este pur și simplu o variantă a funcției Softmax.

                    </div>
                    <br/>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>

                        <li>
                            <a href={"https://www.analyticsvidhya.com/blog/2021/04/introduction-to-softmax-for-neural-network/"} target={"_blank"}>
                                https://www.analyticsvidhya.com/blog/2021/04/introduction-to-softmax-for-neural-network/
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AnexaSoftmaxContent;