import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateThemesContent  extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-first-theme", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    Pentru a genera o tema trebuie instalat mai intai <b>Liferay Theme Generator</b>.

                    Pentru a instala <b>Liferay Theme Generator</b> trebuie urmati urmatorii pasi:

                    <div>
                        <br/>
                        <b>1. Instalare <a href={"https://nodejs.org/"}>NodeJS</a></b>
                        &nbsp;
                        (recomandata este versiune <a href={"https://nodejs.org/download/release/v8.10.0/"}>v8.10.0</a>, aceasta fiind suportata de  Liferay Portal 7.1)

                        <br/>Verificare versiune:
                        <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                            {'node -v\n' +
                            'v14.4.0'}
                        </SyntaxHighlighter>

                        O data cu instalarea NodeJs se instaleaza si <b>Node Package Manager (npm)</b>. Npm va fi folosit pentru instalarea dependintelor si generatorului de teme.
                        <br/>
                        Inainte de a merge mai departe, trebuie configurat mediul <b>npm</b> pentru a evita potențialele probleme de permisiuni.
                        <br/>
                        Urmați urmatorii pași pentru a vă configura mediul npm:
                        <ul>
                          <li>Se creeaza fisierul  <b>.npmrc</b> in <b>C:\Users\[username]</b> cu urmatorul continut:
                              <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                                  {'prefix=c:/Users/[username]/.npm-packages'}
                              </SyntaxHighlighter>
                          </li>

                            <li>Se creeaza in <b>variabile de mediu de sistem (ENV)</b>, variabila <b>NPM_PACKAGES</b> avand ca valoare aceeasi valoare cu <b>prefix</b> definit mai sus in fisierul  <b>.npmrc</b>:

                                <SyntaxHighlighter showLineNumbers={false} language="http" style={androidstudio}>
                                    {'NPM_PACKAGES=c:/Users/[username]/.npm-packages'}
                                </SyntaxHighlighter>
                                Valoarea definita in <b>NPM_PACKAGES</b> specifica unde se instalează pachetele globale <b>npm</b>.
                                <br/><br/>

                                Apoi, tot in <b>variabile de mediu de sistem (ENV)</b>, se adauga in <b>PATH</b>:
                                <SyntaxHighlighter showLineNumbers={false} language="http" style={androidstudio}>
                                    {'%NPM_PACKAGES%/bin'}
                                </SyntaxHighlighter>

                                Apoi, in <b>variabilele de mediu ale utilizatorului (ENV: User variable)</b>, trebuie adaugata in <b>PATH</b>:
                                <SyntaxHighlighter showLineNumbers={false} language="http" style={androidstudio}>
                                    {'c:/Users/[username]/.npm-packages'}
                                </SyntaxHighlighter>

                          </li>

                        </ul>


                        <br/>
                        <b>2.</b> Folosind <b>npm</b> se instaleaza <b><a href={"https://yeoman.io/"}> Yeoman </a></b>:

                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'npm install -g yo'}
                        </SyntaxHighlighter>

                        Rezultatul instructiunii:
                        <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                            {'PS D:\\work\\liferay-vanilla> npm install -g yo\n' +
                            'npm WARN deprecated request@2.88.2: request has been deprecated, see https://github.com/request/request/issues/3142\n' +
                            'npm WARN deprecated har-validator@5.1.5: this library is no longer supported\n' +
                            'c:\\Users\\buzdu\\.npm-packages\\yo -> c:\\Users\\buzdu\\.npm-packages\\node_modules\\yo\\lib\\cli.js\n' +
                            'c:\\Users\\buzdu\\.npm-packages\\yo-complete -> c:\\Users\\buzdu\\.npm-packages\\node_modules\\yo\\lib\\completion\\index.js\n' +
                            '\n' +
                            '> yo@4.3.0 postinstall c:\\Users\\buzdu\\.npm-packages\\node_modules\\yo\n' +
                            '> yodoctor\n' +
                            '\n' +
                            '\n' +
                            'Yeoman Doctor\n' +
                            'Running sanity checks on your system\n' +
                            '\n' +
                            '√ No .bowerrc file in home directory\n' +
                            '√ Global configuration file is valid\n' +
                            '√ NODE_PATH matches the npm root\n' +
                            '√ No .yo-rc.json file in home directory\n' +
                            '√ Node.js version\n' +
                            '√ npm version\n' +
                            '√ yo version\n' +
                            '\n' +
                            'Everything looks all right!\n' +
                            '+ yo@4.3.0\n' +
                            'updated 1 package in 97.271s\n'}
                        </SyntaxHighlighter>

                        <br/>
                        <b>3.</b> Instalare <b>Liferay Theme Generator</b>

                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'npm install -g generator-liferay-theme'}
                        </SyntaxHighlighter>

                        <b>4.</b> Pentru ca se foloseste <b>node-sass</b>, trebuie instalat &nbsp;
                         <a href={"https://github.com/nodejs/node-gyp#installation"}><b>node-gyp</b> si <b>Python</b></a>

                        <br/>
                        Instalare <b>node-gyp</b>
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'npm install -g node-gyp'}
                        </SyntaxHighlighter>

                        <b>5.</b> Instalare <b>gulp</b>:
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'npm install -g gulp'}
                        </SyntaxHighlighter>


                        <b>6. Generare tema:</b>
                        <br/>
                        Se ruleaza din Command Prompt, si nu din Window PowerShell (din cauza masurilor de securitate cu privirea la rularea scripturilor ).
                        <br/>
                        Se ruleaza din directorul proiectului <b>\themes</b>
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'cd themes'}
                        </SyntaxHighlighter>
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'yo liferay-theme'}
                        </SyntaxHighlighter>

                        Rezultatele rularii:
                        <SyntaxHighlighter language="cmd" style={androidstudio}>
                            {'D:\\work\\liferay-vanilla>yo liferay-theme\n' +
                            '\n' +
                            '\n' +
                            'Welcome to the splendid Themes SDK generator!\n' +
                            '\n' +
                            '\n' +
                            'ℹ️ This version of the Themes SDK (10.1.0) supports Liferay DXP \n' +
                            'and Portal CE from 7.2 to 7.4.\n' +
                            '\n' +
                            'For older versions, please use v8 of the toolkit:\n' +
                            '\n' +
                            '> npm install -g generator-liferay-theme@^8.0.0 ↩\n' +
                            '> yo liferay-theme:app ↩\n' +
                            '\n' +
                            '\n' +
                            'ℹ️ For detailed information regarding which version numbers of:\n' +
                            '\n' +
                            '  · unstyled, styled, classic and admin themes \n' +
                            '  · liferay-frontend-css-common and @clayui/css npm packages\n' +
                            '  · Bootstrap framework\n' +
                            '\n' +
                            'are used by each release of Liferay DXP and Portal CE see the table at:\n' +
                            '\n' +
                            'https://github.com/liferay/clay/wiki/Liferay-Portal-@clayui-css-Versions\n' +
                            '\n' +
                            '\n' +
                            '? What would you like to call your theme? (My Liferay Theme)\n'}
                        </SyntaxHighlighter>

                        Adaugam detalii legate de tema:
                        <SyntaxHighlighter language="cmd" style={androidstudio}>
                            {'? What would you like to call your theme? Vanilla\n' +
                            '? What id would you like to give to your theme? vanilla\n' +
                            '? Which version of Liferay is this theme for? 7.3\n' +
                            '? Would you like to add Font Awesome to your theme? Yes\n' +
                            '   create .gitignore\n' +
                            '   create gulpfile.js\n' +
                            '   create package.json\n' +
                            '   create src\\images\\thumbnail.png\n' +
                            '   create src\\WEB-INF\\liferay-look-and-feel.xml\n' +
                            '   create src\\WEB-INF\\liferay-plugin-package.properties\n' +
                            '   create src\\css\\_custom.scss\n'}
                        </SyntaxHighlighter>

                        Se instaleaza dependinta si se steaza ultimele detalii legate de deploy pe server a temei:
                        <SyntaxHighlighter language="cmd" style={androidstudio}>
                            {'I\'m all done. Running npm install for you to install the required dependencies. If this fails, try running the command yourself.\n' +
                            '\n' +
                            '\n' +
                            'npm WARN deprecated fsevents@1.2.13: fsevents 1 will break on node v14+ and could be using insecure binaries. Upgrade to fsevents 2.\n' +
                            'npm WARN deprecated gulp-util@3.0.8: gulp-util is deprecated - replace it, following the guidelines at https://medium.com/gulpjs/gulp-util-ca3b1f9f9ac5\n' +
                            'npm WARN deprecated cross-spawn-async@2.2.5: cross-spawn no longer requires a build toolchain, use it instead\n' +
                            'npm WARN deprecated request@2.88.2: request has been deprecated, see https://github.com/request/request/issues/3142\n' +
                            'npm WARN deprecated har-validator@5.1.5: this library is no longer supported\n' +
                            'npm WARN deprecated mkdirp@0.3.5: Legacy versions of mkdirp are no longer supported. Please update to mkdirp 1.x. (Note that the API surface has changed to use Promises in 1.x.)\n' +
                            'npm WARN deprecated coffee-script@1.12.7: CoffeeScript on NPM has moved to "coffeescript" (no hyphen)\n' +
                            'npm WARN deprecated core-js@2.6.12: core-js@<3 is no longer maintained and not recommended for usage due to the number of issues. Please, upgrade your dependencies to the actual version of core-js@3.\n' +
                            '\n' +
                            '> node-sass@4.14.1 install D:\\work\\liferay-vanilla\\vanilla-theme\\node_modules\\node-sass\n' +
                            '> node scripts/install.js\n' +
                            '\n' +
                            'Cached binary found at C:\\Users\\buzdu\\AppData\\Roaming\\npm-cache\\node-sass\\4.14.1\\win32-x64-83_binding.node\n' +
                            '\n' +
                            '> core-js@2.6.12 postinstall D:\\work\\liferay-vanilla\\vanilla-theme\\node_modules\\core-js\n' +
                            '> node -e "try{require(\'./postinstall\')}catch(e){}"\n' +
                            '\n' +
                            'Thank you for using core-js ( https://github.com/zloirock/core-js ) for polyfilling JavaScript standard library!\n' +
                            '\n' +
                            'The project needs your help! Please consider supporting of core-js on Open Collective or Patreon: \n' +
                            '> https://opencollective.com/core-js \n' +
                            '> https://www.patreon.com/zloirock \n' +
                            '\n' +
                            'Also, the author of core-js ( https://github.com/zloirock ) is looking for a good job -)\n' +
                            '\n' +
                            '\n' +
                            '> spawn-sync@1.0.15 postinstall D:\\work\\liferay-vanilla\\vanilla-theme\\node_modules\\spawn-sync\n' +
                            '> node postinstall\n' +
                            '\n' +
                            '\n' +
                            '> node-sass@4.14.1 postinstall D:\\work\\liferay-vanilla\\vanilla-theme\\node_modules\\node-sass\n' +
                            '> node scripts/build.js\n' +
                            '\n' +
                            'Binary found at D:\\work\\liferay-vanilla\\vanilla-theme\\node_modules\\node-sass\\vendor\\win32-x64-83\\binding.node\n' +
                            'Testing binary\n' +
                            'Binary is fine\n' +
                            'npm notice created a lockfile as package-lock.json. You should commit this file.\n' +
                            'npm WARN optional SKIPPING OPTIONAL DEPENDENCY: fsevents@^1.2.7 (node_modules\\chokidar\\node_modules\\fsevents):\n' +
                            'npm WARN notsup SKIPPING OPTIONAL DEPENDENCY: Unsupported platform for fsevents@1.2.13: wanted {"os":"darwin","arch":"any"} (current: {"os":"win32","arch":"x64"})\n' +
                            'npm WARN optional SKIPPING OPTIONAL DEPENDENCY: fsevents@~2.3.2 (node_modules\\sass\\node_modules\\chokidar\\node_modules\\fsevents):\n' +
                            'npm WARN notsup SKIPPING OPTIONAL DEPENDENCY: Unsupported platform for fsevents@2.3.2: wanted {"os":"darwin","arch":"any"} (current: {"os":"win32","arch":"x64"})\n' +
                            'npm WARN vanilla-theme@1.0.0 No description\n' +
                            'npm WARN vanilla-theme@1.0.0 No repository field.\n' +
                            'npm WARN vanilla-theme@1.0.0 No license field.\n' +
                            '\n' +
                            'added 857 packages from 509 contributors and audited 859 packages in 34.31s\n' +
                            '\n' +
                            '19 packages are looking for funding\n' +
                            '  run `npm fund` for details\n' +
                            '\n' +
                            'found 28 vulnerabilities (2 low, 5 moderate, 18 high, 3 critical)\n' +
                            '  run `npm audit fix` to fix them, or `npm audit` for details\n' +
                            '\n' +
                            '✔️ The project has been created successfully.\n' +
                            '\n' +
                            'ℹ️ Now we will invoke gulp init for you, to configure your deployment\n' +
                            'strategy. \n' +
                            '\n' +
                            'Remember, that you can change your answers whenever you want by \n' +
                            'running gulp init again.\n' +
                            '\n' +
                            '? Select your deployment strategy Local App Server\n' +
                            '? Enter the path to your app server directory: D:\\servers\\liferay-ce-portal-7.3.6-ga7\\tomcat-9.0.40\n' +
                            '? Enter the url to your production or development site: http://localhost:8080\n'}
                        </SyntaxHighlighter>

                        Dupa rularea comenzii de mai sus, in directorul <b>themes</b> vom avea directorul care contine tema: <b>vanilla-theme</b>

                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'   themes\n' +
                            '           vanilla-theme\n' +
                            '                 node_modules\n' +
                            '                 src\n' +
                            '                       css\n' +
                            '                       images\n' +
                            '                       WEB-INF\n' +
                            '                 .gitignore\n' +
                            '                 gulpfile.js\n' +
                            '                 liferay-theme.json\n' +
                            '                 package.json\n' +
                            '                 package-lock.json'}
                        </SyntaxHighlighter>

                        <b>7.</b> Navigare catre directorul care contine tema si facut <b>build</b>:
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'cd themes\\vanilla-theme'}
                        </SyntaxHighlighter>
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'gulp build'}
                        </SyntaxHighlighter>

                        Dupa build, vor apare directorele <b>build</b> si <b>dist</b> cu fisierul <b>vanilla-theme.war</b>
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'  vanilla-theme\n' +
                            '          build\n' +
                            '          dist\n' +
                            '              vanilla-theme.war'}
                        </SyntaxHighlighter>

                        Daca se obtin <b>erori la generarea temei</b>, atunci puteti lua in considerare urmatoarele instalari:
                        <ul>
                            <li>
                                Instalare <a href={"https://rubyinstaller.org/downloads/"}>Ruby</a>
                            </li>
                            <li>
                                <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                                    {'gem install sass -v "=3.4.0"\n' +
                                    'gem install compass\n' +
                                    'npm i -g gulp-ruby-sass'}
                                </SyntaxHighlighter>
                            </li>
                       </ul>

                        <b>8.</b> Navigare catre directorul care contine tema si facut <b>deploy</b> pe server:
                        <SyntaxHighlighter showLineNumbers={false} language="npm" style={androidstudio}>
                            {'gulp deploy'}
                        </SyntaxHighlighter>

                        <b>9. Schimbare tema din portal</b>
                        <br/>
                        Logat ca administrator in Liferay:

                        <ul>
                            <li>
                                [SITE] {"->"} <b>Site Builder {"->"} Pages</b>
                            </li>
                            <li>
                                sectiune: <b>Public Pages</b>
                            </li>
                            <li>
                                buton: <b>Configuration</b>
                            </li>
                            <li>
                               <b>Look and Feel</b>
                            </li>
                            <li>
                                <b>Change Curent Theme</b>
                            </li>
                            <li>
                                <b>Change</b>
                            </li>
                        </ul>

                    </div>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018166591-Creating-Themes"}>
                                    Creating Themes
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateThemesContent;