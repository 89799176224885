import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class GeneratedCodeRepositoryJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-repository-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: Repository</b>
                    <br/>
                    <br/>

                    In directorul <b>src/main/java</b> pachetul <i>ro.ibrid.lapretbun.repository</i> s-au generat repository-urile Java:

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>ProductRepository.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.repository;\n' +
                            '\n' +
                            'import org.springframework.data.jpa.repository.*;\n' +
                            'import org.springframework.stereotype.Repository;\n' +
                            'import ro.ibrid.lapretbun.domain.Product;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Spring Data JPA repository for the Product entity.\n' +
                            ' */\n' +
                            '@SuppressWarnings("unused")\n' +
                            '@Repository\n' +
                            'public interface ProductRepository extends JpaRepository<Product, Long> {}\n'}
                    </SyntaxHighlighter>

                    De exemplu, continutul fisierului <i>ProductCategoryRepository.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.repository;\n' +
                            '\n' +
                            'import org.springframework.data.jpa.repository.*;\n' +
                            'import org.springframework.stereotype.Repository;\n' +
                            'import ro.ibrid.lapretbun.domain.ProductCategory;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Spring Data JPA repository for the ProductCategory entity.\n' +
                            ' */\n' +
                            '@SuppressWarnings("unused")\n' +
                            '@Repository\n' +
                            'public interface ProductCategoryRepository extends JpaRepository<ProductCategory, Long> {}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratedCodeRepositoryJdlJHipsterContent;