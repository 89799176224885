import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class FindLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-find", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru gasi fisiere si directoare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find <cale> <parametru1> <valoare-parametru1> .. <parametruN> <valoare-parametruN>'}
                    </SyntaxHighlighter>

                    Exemplu 1: afisare toate fisiere/directoare care se termina in ".txt"
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find ../ -name "*.txt"'}
                    </SyntaxHighlighter>

                    Exemplu 2: afisare toate fisierele:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -type f'}
                    </SyntaxHighlighter>
                    unde type poate:
                    <ul>
                        <li>f - pentru fisiere</li>
                        <li>d - pentru directoare</li>
                        <li>l - pentru link</li>
                    </ul>

                    Exemplu 3: afisare toate directoarele sub forma tabelara (<b>-ls</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -type d -ls'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'6042714      4 drwxrwxr-x   3 kj       kj           4096 dec 13 08:06 .\n' +
                            '6042720      4 drwxrwxr-x   2 kj       kj           4096 dec 13 08:06 ./test'}
                    </SyntaxHighlighter>

                    Exemplu 4: afisare toate fisierele goale (<b>-empty</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -type f -empty'}
                    </SyntaxHighlighter>

                    Exemplu 5: cautare fisierele dupa dimensiune, de exemlu, mai mari de 10k (<b>-size</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -type f -size +10k'}
                    </SyntaxHighlighter>

                    Exemplu 6: cautare fisierele dupa permisiuni (<b>-perm</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -type f -perm 777'}
                    </SyntaxHighlighter>

                    Exemplu 7: afisare concatenata a fisiereler care au extensia <i>*.txt</i> (<b>-exec</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -name "*.txt" -exec cat {} \\;'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>cat - este comanda ce va fi executata dupa comanda find; se va executa pentru fiecare rezultat a lui find</li>
                        <li>{} - va fi inlocuit cu rezultatul lui find (numele de fisier)</li>
                        <li>; - trebuie prefixat cu \ pentru ca este caracter de sistem</li>
                    </ul>

                    Exemplu 8: afisare a fisiereler, colorat, folosind <b>-exec</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -exec ls -la --color {} \\;'}
                    </SyntaxHighlighter>

                    Exemplu 9: afisare a fisierelor stabilind formatul (<b>-printf</b>)
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find . -name "*.txt" -printf "%p %k KB\\n"'}
                    </SyntaxHighlighter>
                    unde formatul:
                    <ul>
                        <li>%p - calea</li>
                        <li>%k - dimensiunea</li>
                        <li>KB - e un text, poate fi orice</li>
                        <li>\n - linie noua</li>
                    </ul>

                    Exemplu 10: afisare continut fisiere/directoare pentru directoarele cu numele <i>kernel</i>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find / -name kernel -type d -exec ls -l --color \\;'}
                    </SyntaxHighlighter>
                    unde formatul:
                    <ul>
                        <li>%p - calea</li>
                        <li>%k - dimensiunea</li>
                        <li>KB - e un text, poate fi orice</li>
                        <li>\n - linie noua</li>
                    </ul>

                    Exemplu 11: afisare continut fisiere/directoare pentru directoarele cu numele <i>kernel</i>, folosind <b>xargs</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find / -name kernel -type d | xargs ls -l --color'}
                    </SyntaxHighlighter>
                    unde formatul:
                    <ul>
                        <li>%p - calea</li>
                        <li>%k - dimensiunea</li>
                        <li>KB - e un text, poate fi orice</li>
                        <li>\n - linie noua</li>
                    </ul>

                    Diferenta dintre exemplul 10 si exemplul 11 consta in faptul ca folosind <b>xargs</b> comanda <b>ls</b> se executa pentru fiecare argument primit.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FindLinuxContent;