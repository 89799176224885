import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ImmediatelyInvokedFunctionExpressionJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-immediately-invoked-function-expression", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    În JavaScript, un Immediately-Invoked Function Expression (IIFE) (în română: „Expresie de Funcție Invocată Imediat”) este o funcție care este definită și invocată imediat după ce este creată.
                    Aceasta este o modalitate de a executa codul imediat și de a izola variabilele de restul programului, pentru a evita poluarea spațiului global de nume.

                    <br/>
                    <br/>
                    <b>Sintaxa unui IIFE:</b>
                    <br/>
                    Un IIFE este format din două părți:
                    <ul>
                        <li>
                            Funcția anonimă (sau declarată), care este încapsulată într-o pereche de paranteze
                        </li>
                        <li>
                            Perechea de paranteze suplimentare la final, care invocă imediat funcția.
                        </li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'(function() {\n' +
                            '    console.log("This is an IIFE!");\n' +
                            '})();'}
                    </SyntaxHighlighter>

                    Funcția anonimă:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'(function() { ... })'}
                    </SyntaxHighlighter>

                    definește o funcție anonimă.
                    Aceasta este <b>închisă în paranteze pentru a transforma funcția într-o expresie (nu o declarație de funcție)</b>,
                    deoarece JavaScript are reguli stricte cu privire la modul în care interpretează funcțiile anonime.
                    <hr/>
                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let result = (function() {\n' +
                            '    return 2 + 3;\n' +
                            '})();\n' +
                            'console.log(result);'}
                    </SyntaxHighlighter>
                    <hr/>
                    Motive pentru folosire IIFE:
                    <ul>
                        <li>
                            Encapsularea și evitarea poluării spațiului global de nume
                            <br/>
                            Când variabilele sunt declarate într-o funcție, ele sunt locale funcției respective. Într-un IIFE, toate variabilele definite în interiorul funcției nu interferează cu alte variabile din afara acesteia.
                        </li>
                        <li>
                            Izolarea codului
                            <br/>
                            În aplicații mari, IIFE sunt utile pentru a organiza codul și pentru a evita conflictele dintre variabile. De exemplu, dacă mai multe scripturi definesc variabile cu același nume, IIFE poate ajuta la izolarea lor.
                        </li>
                        <li>
                            Executarea codului imediat
                            <br/>
                            Dacă vrei să rulezi o bucată de cod imediat după încărcarea paginii, fără a fi necesar să apelezi o funcție separat, IIFE este o soluție excelentă. Practic, codul este rulat imediat la definire.
                        </li>
                        <li>
                            Simulare pentru modulul de patterning
                            <br/>
                            Înainte de introducerea modulelor ES6, IIFE era o modalitate comună de a simula comportamentul modulului pentru a organiza și izola codul în părți mai mici. Fiecare IIFE putea reprezenta un „modul” care nu interferează cu alte părți ale aplicației.
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let myModule = (function() {\n' +
                                    '    let privateVar = "This is private";\n' +
                                    '\n' +
                                    '    return {\n' +
                                    '        publicMethod: function() {\n' +
                                    '            console.log(privateVar);\n' +
                                    '        }\n' +
                                    '    };\n' +
                                    '})();\n' +
                                    '\n' +
                                    'myModule.publicMethod(); // Output: This is private\n' +
                                    'console.log(myModule.privateVar); // Error: privateVar is not accessible'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>
                            Toate funcțiile ar trebui să aibă nume.  Aceasta include IIFE-urile
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'(function(){\n' +
                                    '    // don\'t do this!\n' +
                                    '})();\n' +
                                    '\n' +
                                    '(function doThisInstead(){\n' +
                                    '    // ..\n' +
                                    '})();'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            IIFE-urile sunt de obicei definite prin plasarea ( .. ) în jurul expresiei <i>function</i>, așa cum se arată în acele fragmente anterioare.
                            Dar aceasta nu este singura modalitate de a defini un IIFE.
                            Din punct de vedere tehnic, singurul motiv pentru care folosim ( .. ) este doar acela că cuvantul cheie<i>function</i>
                            nu este în măsură să se califice ca o declaratie <i>function</i> pentru analizatorul JS.
                            Dar există și alte moduri sintactice de a evita să fie analizat ca declarație:

                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'!function thisIsAnIIFE(){\n' +
                                    '    // ..\n' +
                                    '}();\n' +
                                    '\n' +
                                    '+function soIsThisOne(){\n' +
                                    '    // ..\n' +
                                    '}();\n' +
                                    '\n' +
                                    '~function andThisOneToo(){\n' +
                                    '    // ..\n' +
                                    '}();'}
                            </SyntaxHighlighter>
                            !, +, ~ și alți câțiva operatori unari (operatori cu un singur operand) pot fi plasați cu toții în fața function pentru a-l transforma într-o expresie.
                            Apoi apelul final () este valid, ceea ce îl face un IIFE.
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'void function yepItsAnIIFE() {\n' +
                                    '    // ..\n' +
                                    '}();'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImmediatelyInvokedFunctionExpressionJavaScriptContent;