import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class JdlStudioJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-jdl-studio", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Modelare entitati cu JDL Studio</b>
                    <br/>
                    <br/>

                    Modelarea entatilor se poate face de aici:
                    <ul>
                        <li>
                            <a target={"_blank"} hre={"https://start.jhipster.tech/jdl-studio/"}>https://start.jhipster.tech/jdl-studio/</a>
                        </li>
                    </ul>

                    Dupa ce se definesc entitatile, se downloadeaza fisierul rezultat <i>jhipster-jdl.jdl</i> si se ruleaza
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'jhipster jdl jhipster-jdl.jdl'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'jhipster import-jdl jhipster-jdl.jdl'}
                    </SyntaxHighlighter>

                    <hr/>

                    Exemplu (fie fisierul <i>jdl/store.jdl</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'/** Product sold by the Online store */\n' +
                            'entity Product {\n' +
                            '    name String required\n' +
                            '    description String\n' +
                            '    price BigDecimal required min(0)\n' +
                            '    sizeProduct Size required\n' +
                            '    image ImageBlob\n' +
                            '}\n' +
                            '\n' +
                            'enum Size {\n' +
                            '    S, M, L, XL, XXL\n' +
                            '}\n' +
                            '\n' +
                            'entity ProductCategory {\n' +
                            '    name String required\n' +
                            '    description String\n' +
                            '}\n' +
                            '\n' +
                            'entity Customer {\n' +
                            '    firstName String required\n' +
                            '    lastName String required\n' +
                            '    gender Gender required\n' +
                            '    email String required pattern(/^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$/)\n' +
                            '    phone String required\n' +
                            '    addressLine1 String required\n' +
                            '    addressLine2 String\n' +
                            '    city String required\n' +
                            '    country String required\n' +
                            '}\n' +
                            '\n' +
                            'enum Gender {\n' +
                            '    MALE, FEMALE, OTHER\n' +
                            '}\n' +
                            '\n' +
                            'entity ProductOrder {\n' +
                            '    placedDate Instant required\n' +
                            '    status OrderStatus required\n' +
                            '    code String required\n' +
                            '}\n' +
                            '\n' +
                            'enum OrderStatus {\n' +
                            '    COMPLETED, PENDING, CANCELLED\n' +
                            '}\n' +
                            '\n' +
                            'entity OrderItem {\n' +
                            '    quantity Integer required min(0)\n' +
                            '    totalPrice BigDecimal required min(0)\n' +
                            '    status OrderItemStatus required\n' +
                            '}\n' +
                            '\n' +
                            'enum OrderItemStatus {\n' +
                            '    AVAILABLE, OUT_OF_STOCK, BACK_ORDER\n' +
                            '}\n' +
                            '\n' +
                            'entity Invoice {\n' +
                            '    date Instant required\n' +
                            '    details String\n' +
                            '    status InvoiceStatus required\n' +
                            '    paymentMethod PaymentMethod required\n' +
                            '    paymentDate Instant required\n' +
                            '    paymentAmount BigDecimal required\n' +
                            '}\n' +
                            '\n' +
                            'enum InvoiceStatus {\n' +
                            '    PAID, ISSUED, CANCELLED\n' +
                            '}\n' +
                            '\n' +
                            'enum PaymentMethod {\n' +
                            '    CREDIT_CARD, CASH_ON_DELIVERY, PAYPAL\n' +
                            '}\n' +
                            '\n' +
                            'entity Shipment {\n' +
                            '    trackingCode String\n' +
                            '    date Instant required\n' +
                            '    details String\n' +
                            '}\n' +
                            '\n' +
                            '// Customer (1) -----> (1) User\n' +
                            'relationship OneToOne {\n' +
                            '    Customer{user} to User\n' +
                            '}\n' +
                            '\n' +
                            '//OrderItem (*) -----> (1) Product\n' +
                            'relationship ManyToOne {\n' +
                            '    OrderItem{product} to Product\n' +
                            '}\n' +
                            '\n' +
                            '/*\n' +
                            'Customer (1) <-----> (*) ProductOrder\n' +
                            'ProductOrder (1) <-----> (*) OrderItem\n' +
                            'ProductOrder (1) <-----> (*) Invoice\n' +
                            'Invoice (1) <-----> (*) Shipment\n' +
                            'ProductCategory (1) <-----> (*) Product\n' +
                            '*/\n' +
                            '\n' +
                            'relationship OneToMany {\n' +
                            '    Customer{order} to ProductOrder{customer},\n' +
                            '    ProductOrder{orderItem} to OrderItem{order},\n' +
                            '    ProductOrder{invoice} to Invoice{order},\n' +
                            '    Invoice{shipment} to Shipment{invoice},\n' +
                            '    ProductCategory{product} to Product{productCategory}\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    rulare generare entitati, etc:
                    <SyntaxHighlighter showLineNumbers={true} language="jdl" style={androidstudio}>
                        {'jhipster jdl jdl/store.jdl'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'INFO! Switching to JHipster installed locally in current project\'s node repository (node_modules)\n' +
                            '(node:1604) [DEP0148] DeprecationWarning: Use of deprecated folder mapping "./lib/util/" in the "exports" field module resolution of the package at D:\\work\\jhipster\\jhipster-app2\\node_modules\\yeoman-environment\\package.json.\n' +
                            'Update this package.json to use a subpath pattern like "./lib/util/*".\n' +
                            '(Use `node --trace-deprecation ...` to show where the warning was created)\n' +
                            '\n' +
                            '        ██╗ ██╗   ██╗ ████████╗ ███████╗   ██████╗ ████████╗ ████████╗ ███████╗\n' +
                            '        ██║ ██║   ██║ ╚══██╔══╝ ██╔═══██╗ ██╔════╝ ╚══██╔══╝ ██╔═════╝ ██╔═══██╗\n' +
                            '        ██║ ████████║    ██║    ███████╔╝ ╚█████╗     ██║    ██████╗   ███████╔╝\n' +
                            '  ██╗   ██║ ██╔═══██║    ██║    ██╔════╝   ╚═══██╗    ██║    ██╔═══╝   ██╔══██║\n' +
                            '  ╚██████╔╝ ██║   ██║ ████████╗ ██║       ██████╔╝    ██║    ████████╗ ██║  ╚██╗\n' +
                            '   ╚═════╝  ╚═╝   ╚═╝ ╚═══════╝ ╚═╝       ╚═════╝     ╚═╝    ╚═══════╝ ╚═╝   ╚═╝\n' +
                            '                            https://www.jhipster.tech\n' +
                            'Welcome to JHipster v7.9.3\n' +
                            '\n' +
                            'INFO! Executing import-jdl jdl/store.jdl\n' +
                            'INFO! The JDL is being parsed.\n' +
                            'warn: An Entity name \'User\' was used: \'User\' is an entity created by default by JHipster. All relationships toward it will be kept but any attributes and relationships from it will be disregarded.\n' +
                            'INFO! Found entities: Product, ProductCategory, Customer, ProductOrder, OrderItem, Invoice, Shipment.\n' +
                            'INFO! The JDL has been successfully parsed\n' +
                            'INFO! Generating 0 applications.\n' +
                            'INFO! Generating 7 entities.\n' +
                            'INFO! Generating entities for application undefined in a new parallel process\n' +
                            '\n' +
                            'Found the .jhipster\\Product.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '\n' +
                            'Found the .jhipster\\ProductCategory.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '\n' +
                            'Found the .jhipster\\Customer.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '\n' +
                            'Found the .jhipster\\ProductOrder.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '\n' +
                            'Found the .jhipster\\OrderItem.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '\n' +
                            'Found the .jhipster\\Invoice.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '\n' +
                            'Found the .jhipster\\Shipment.json configuration file, entity can be automatically generated!\n' +
                            '\n' +
                            '     info Creating changelog for entities Product,ProductCategory,Customer,ProductOrder,OrderItem,Invoice,Shipment\n' +
                            '    force .yo-rc.json\n' +
                            '    force .jhipster\\Product.json\n' +
                            '    force .jhipster\\Shipment.json\n' +
                            '    force .jhipster\\ProductCategory.json\n' +
                            '    force .jhipster\\Customer.json\n' +
                            '    force .jhipster\\ProductOrder.json\n' +
                            '    force .jhipster\\OrderItem.json\n' +
                            '    force .jhipster\\Invoice.json\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\ProductTest.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ProductResourceIT.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\ProductCategoryTest.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\product.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\product.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\update\\product-update.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\enumerations\\size.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\product.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\delete\\product-delete-dialog.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\update\\product-update.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\size.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\route\\product-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\delete\\product-delete-dialog.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\size.json\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\ProductRepository.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ProductResource.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ProductCategoryResourceIT.java\n' +
                            '   create src\\main\\webapp\\i18n\\en\\product.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\detail\\product-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\route\\product-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\list\\product.component.spec.ts\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\ProductCategoryRepository.java\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\product.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\list\\product.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\list\\product.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\product-category.model.ts\n' +
                            ' conflict src\\main\\webapp\\i18n\\en\\global.json\n' +
                            '? Overwrite src\\main\\webapp\\i18n\\en\\global.json? overwrite\n' +
                            '    force src\\main\\webapp\\i18n\\en\\global.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\route\\product-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\detail\\product-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\product-category.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\service\\product.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\service\\product.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\list\\product-category.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\detail\\product-detail.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\update\\product-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\update\\product-update.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\detail\\product-category-detail.component.html\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\enumeration\\Size.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\update\\product-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product\\delete\\product-delete-dialog.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\product-category.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\route\\product-category-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\detail\\product-category-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\route\\product-category-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\list\\product-category.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\list\\product-category.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\route\\product-category-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ProductCategoryResource.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\detail\\product-category-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\service\\product-category.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\update\\product-category-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\update\\product-category-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\update\\product-category-update.component.html\n' +
                            '   create src\\main\\webapp\\i18n\\en\\productCategory.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\delete\\product-category-delete-dialog.component.html\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\productCategory.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\update\\product-category-update.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\delete\\product-category-delete-dialog.component.ts\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\CustomerTest.java\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\product.cy.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\service\\product-category.service.spec.ts\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\CustomerResource.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\update\\product-category-update.component.spec.ts\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\CustomerResourceIT.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-category\\delete\\product-category-delete-dialog.component.spec.ts\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\CustomerRepository.java\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\product-category.cy.ts\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\enumeration\\Gender.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\customer.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\customer.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\customer.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\list\\customer.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\detail\\customer-detail.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\route\\customer-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\route\\customer-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\list\\customer.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\detail\\customer-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\detail\\customer-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\list\\customer.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\service\\customer.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\route\\customer-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\update\\customer-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\service\\customer.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\update\\customer-update.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\update\\customer-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\update\\customer-update.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\enumerations\\gender.model.ts\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\customer.cy.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\delete\\customer-delete-dialog.component.html\n' +
                            '   create src\\main\\webapp\\i18n\\en\\gender.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\update\\customer-update.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\gender.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\delete\\customer-delete-dialog.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\customer.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\customer.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\customer\\delete\\customer-delete-dialog.component.spec.ts\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\ProductOrderTest.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ProductOrderResource.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ProductOrderResourceIT.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\ProductOrderRepository.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\enumeration\\OrderStatus.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\product-order.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\product-order.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\product-order.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\list\\product-order.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\list\\product-order.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\detail\\product-order-detail.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\route\\product-order-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\route\\product-order-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\detail\\product-order-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\service\\product-order.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\update\\product-order-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\detail\\product-order-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\update\\product-order-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\list\\product-order.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\route\\product-order-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\update\\product-order-update.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\service\\product-order.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\delete\\product-order-delete-dialog.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\update\\product-order-update.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\delete\\product-order-delete-dialog.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\enumerations\\order-status.model.ts\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\product-order.cy.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\update\\product-order-update.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\orderStatus.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\orderStatus.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\product-order\\delete\\product-order-delete-dialog.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\productOrder.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\productOrder.json\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\OrderItemTest.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\OrderItemResource.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\OrderItemResourceIT.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\OrderItemRepository.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\enumeration\\OrderItemStatus.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\order-item.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\order-item.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\list\\order-item.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\list\\order-item.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\detail\\order-item-detail.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\detail\\order-item-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\order-item.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\route\\order-item-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\route\\order-item-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\service\\order-item.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\update\\order-item-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\detail\\order-item-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\list\\order-item.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\update\\order-item-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\route\\order-item-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\update\\order-item-update.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\service\\order-item.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\delete\\order-item-delete-dialog.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\update\\order-item-update.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\update\\order-item-update.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\delete\\order-item-delete-dialog.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\enumerations\\order-item-status.model.ts\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\order-item.cy.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\order-item\\delete\\order-item-delete-dialog.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\orderItemStatus.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\orderItemStatus.json\n' +
                            '   create src\\main\\webapp\\i18n\\en\\orderItem.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\orderItem.json\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\InvoiceTest.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\InvoiceResource.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\InvoiceResourceIT.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\InvoiceRepository.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\enumeration\\InvoiceStatus.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\enumeration\\PaymentMethod.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\invoice.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\invoice.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\list\\invoice.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\detail\\invoice-detail.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\invoice.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\route\\invoice-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\route\\invoice-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\list\\invoice.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\detail\\invoice-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\detail\\invoice-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\list\\invoice.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\route\\invoice-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\service\\invoice.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\service\\invoice.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\update\\invoice-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\update\\invoice-update.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\update\\invoice-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\enumerations\\invoice-status.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\update\\invoice-update.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\enumerations\\payment-method.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\delete\\invoice-delete-dialog.component.html\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\invoice.cy.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\invoiceStatus.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\update\\invoice-update.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\delete\\invoice-delete-dialog.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\invoiceStatus.json\n' +
                            '   create src\\main\\webapp\\i18n\\en\\paymentMethod.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\paymentMethod.json\n' +
                            '   create src\\main\\webapp\\i18n\\en\\invoice.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\invoice.json\n' +
                            '   create src\\main\\webapp\\app\\entities\\invoice\\delete\\invoice-delete-dialog.component.spec.ts\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\domain\\ShipmentTest.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ShipmentResource.java\n' +
                            '   create src\\test\\java\\ro\\ibrid\\lapretbun\\web\\rest\\ShipmentResourceIT.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\repository\\ShipmentRepository.java\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\shipment.model.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\shipment.test-samples.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\shipment.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\list\\shipment.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\detail\\shipment-detail.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\list\\shipment.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\detail\\shipment-detail.component.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\route\\shipment-routing.module.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\route\\shipment-routing-resolve.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\service\\shipment.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\update\\shipment-form.service.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\update\\shipment-form.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\detail\\shipment-detail.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\update\\shipment-update.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\list\\shipment.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\route\\shipment-routing-resolve.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\delete\\shipment-delete-dialog.component.html\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\service\\shipment.service.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\update\\shipment-update.component.spec.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\delete\\shipment-delete-dialog.component.spec.ts\n' +
                            '   create src\\test\\javascript\\cypress\\e2e\\entity\\shipment.cy.ts\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\update\\shipment-update.component.ts\n' +
                            '   create src\\main\\webapp\\i18n\\en\\shipment.json\n' +
                            '   create src\\main\\webapp\\i18n\\ro\\shipment.json\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\blob\\hipster.png\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210803_added_entity_Product.xml\n' +
                            '   create src\\main\\webapp\\app\\entities\\shipment\\delete\\shipment-delete-dialog.component.ts\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210803_added_entity_constraints_Product.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\product.csv\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210804_added_entity_ProductCategory.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\product_category.csv\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210805_added_entity_Customer.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210805_added_entity_constraints_Customer.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\customer.csv\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210806_added_entity_ProductOrder.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210806_added_entity_constraints_ProductOrder.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\product_order.csv\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210807_added_entity_OrderItem.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210807_added_entity_constraints_OrderItem.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\order_item.csv\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210808_added_entity_Invoice.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210808_added_entity_constraints_Invoice.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\invoice.csv\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210809_added_entity_Shipment.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\changelog\\20230506210809_added_entity_constraints_Shipment.xml\n' +
                            '   create src\\main\\resources\\config\\liquibase\\fake-data\\shipment.csv\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\Product.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\ProductCategory.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\Customer.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\ProductOrder.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\OrderItem.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\Invoice.java\n' +
                            '   create src\\main\\java\\ro\\ibrid\\lapretbun\\domain\\Shipment.java\n' +
                            ' conflict src\\main\\webapp\\i18n\\ro\\global.json\n' +
                            '? Overwrite src\\main\\webapp\\i18n\\ro\\global.json? overwrite\n' +
                            '    force src\\main\\webapp\\i18n\\ro\\global.json\n' +
                            ' conflict src\\main\\webapp\\app\\entities\\entity-routing.module.ts\n' +
                            '? Overwrite src\\main\\webapp\\app\\entities\\entity-routing.module.ts? overwrite\n' +
                            '    force src\\main\\webapp\\app\\entities\\entity-routing.module.ts\n' +
                            ' conflict src\\main\\resources\\config\\liquibase\\master.xml\n' +
                            '? Overwrite src\\main\\resources\\config\\liquibase\\master.xml? overwrite\n' +
                            '    force src\\main\\resources\\config\\liquibase\\master.xml\n' +
                            ' conflict src\\main\\java\\ro\\ibrid\\lapretbun\\config\\CacheConfiguration.java\n' +
                            '? Overwrite src\\main\\java\\ro\\ibrid\\lapretbun\\config\\CacheConfiguration.java? overwrite\n' +
                            '    force src\\main\\java\\ro\\ibrid\\lapretbun\\config\\CacheConfiguration.java\n' +
                            ' conflict src\\main\\webapp\\app\\layouts\\navbar\\navbar.component.html\n' +
                            '? Overwrite src\\main\\webapp\\app\\layouts\\navbar\\navbar.component.html? overwrite\n' +
                            '    force src\\main\\webapp\\app\\layouts\\navbar\\navbar.component.html\n' +
                            '    force .yo-rc.json\n' +
                            '    force .jhipster\\Product.json\n' +
                            '    force .jhipster\\ProductCategory.json\n' +
                            '    force .jhipster\\Customer.json\n' +
                            '    force .jhipster\\ProductOrder.json\n' +
                            '    force .jhipster\\OrderItem.json\n' +
                            '    force .jhipster\\Invoice.json\n' +
                            '    force .jhipster\\Shipment.json\n' +
                            '\n' +
                            'No change to package.json was detected. No package manager install will be executed.\n' +
                            'Entity Product generated successfully.\n' +
                            'Entity ProductCategory generated successfully.\n' +
                            'Entity Customer generated successfully.\n' +
                            'Entity ProductOrder generated successfully.\n' +
                            'Entity OrderItem generated successfully.\n' +
                            'Entity Invoice generated successfully.\n' +
                            'Entity Shipment generated successfully.\n' +
                            '\n' +
                            'Running `webapp:build` to update client app\n' +
                            '\n' +
                            '\n' +
                            '> lapretbun@0.0.1-SNAPSHOT webapp:build D:\\work\\jhipster\\jhipster-app2\n' +
                            '> npm run clean-www && npm run webapp:build:dev\n' +
                            '\n' +
                            'npm WARN config cache-max This option has been deprecated in favor of `--prefer-online`\n' +
                            'npm WARN config cache-min This option has been deprecated in favor of `--prefer-offline`.\n' +
                            'npm WARN config optional Use `--omit=optional` to exclude optional dependencies, or\n' +
                            'npm WARN config `--include=optional` to include them.\n' +
                            'npm WARN config\n' +
                            'npm WARN config     Default value does install optional deps unless otherwise omitted.\n' +
                            'npm WARN config shrinkwrap Use the --package-lock setting instead.\n' +
                            'npm WARN config sso-poll-frequency The --auth-type method of SSO/SAML/OAuth will be removed in a future\n' +
                            'npm WARN config version of npm in favor of web-based login.\n' +
                            'npm WARN config sso-type The --auth-type method of SSO/SAML/OAuth will be removed in a future\n' +
                            'npm WARN config version of npm in favor of web-based login.\n' +
                            'npm WARN config tmp This setting is no longer used.  npm stores temporary files in a special\n' +
                            'npm WARN config location in the cache, and they are managed by\n' +
                            'npm WARN config     [`cacache`](http://npm.im/cacache).\n' +
                            '\n' +
                            '> lapretbun@0.0.1-SNAPSHOT clean-www D:\\work\\jhipster\\jhipster-app2\n' +
                            '> rimraf target/classes/static/app/{src,target/}\n' +
                            '\n' +
                            'npm WARN config cache-max This option has been deprecated in favor of `--prefer-online`\n' +
                            'npm WARN config cache-min This option has been deprecated in favor of `--prefer-offline`.\n' +
                            'npm WARN config optional Use `--omit=optional` to exclude optional dependencies, or\n' +
                            'npm WARN config `--include=optional` to include them.\n' +
                            'npm WARN config\n' +
                            'npm WARN config     Default value does install optional deps unless otherwise omitted.\n' +
                            'npm WARN config shrinkwrap Use the --package-lock setting instead.\n' +
                            'npm WARN config sso-poll-frequency The --auth-type method of SSO/SAML/OAuth will be removed in a future\n' +
                            'npm WARN config version of npm in favor of web-based login.\n' +
                            'npm WARN config sso-type The --auth-type method of SSO/SAML/OAuth will be removed in a future\n' +
                            'npm WARN config version of npm in favor of web-based login.\n' +
                            'npm WARN config tmp This setting is no longer used.  npm stores temporary files in a special\n' +
                            'npm WARN config location in the cache, and they are managed by\n' +
                            'npm WARN config     [`cacache`](http://npm.im/cacache).\n' +
                            '\n' +
                            '> lapretbun@0.0.1-SNAPSHOT webapp:build:dev D:\\work\\jhipster\\jhipster-app2\n' +
                            '> ng build --configuration development\n' +
                            '\n' +
                            '| Generating browser application bundles (phase: setup)...DeprecationWarning: \'getMutableClone\' has been deprecated since v4.0.0. Use an appropriate `factory.update...` method instead, use `setCommentRange` or `setSourceMapRange`, and avoid setting `parent`.\n' +
                            '√ Browser application bundle generation complete.\n' +
                            '√ Copying assets complete.\n' +
                            '√ Index html generation complete.\n' +
                            '\n' +
                            'Initial Chunk Files                                                         | Names                                    |  Raw Size\n' +
                            'polyfills.js                                                                | polyfills                                | 956.02 kB | \n' +
                            'styles.css                                                                  | styles                                   | 235.77 kB | \n' +
                            'runtime.js                                                                  | runtime                                  |  12.46 kB | \n' +
                            'main.js                                                                     | main                                     | 784 bytes | \n' +
                            '\n' +
                            '                                                                            | Initial Total                            |   1.18 MB\n' +
                            '\n' +
                            'Lazy Chunk Files                                                            | Names                                    |  Raw Size\n' +
                            'src_main_webapp_bootstrap_ts.js                                             | bootstrap                                |   4.83 MB | \n' +
                            'src_main_webapp_app_account_account_module_ts.js                            | account-account-module                   | 182.24 kB | \n' +
                            'src_main_webapp_app_admin_metrics_metrics_module_ts.js                      | metrics-metrics-module                   | 174.57 kB | \n' +
                            'src_main_webapp_app_entities_customer_customer_module_ts.js                 | customer-customer-module                 | 134.83 kB | \n' +
                            'src_main_webapp_app_entities_product_product_module_ts.js                   | product-product-module                   | 127.16 kB | \n' +
                            'src_main_webapp_app_entities_invoice_invoice_module_ts.js                   | invoice-invoice-module                   | 124.49 kB | \n' +
                            'src_main_webapp_app_entities_order-item_order-item_module_ts.js             | order-item-order-item-module             | 117.53 kB | \n' +
                            'src_main_webapp_app_admin_user-management_user-management_module_ts.js      | user-management-user-management-module   | 115.78 kB | \n' +
                            'src_main_webapp_app_entities_product-order_product-order_module_ts.js       | product-order-product-order-module       | 104.76 kB | \n' +
                            'src_main_webapp_app_entities_shipment_shipment_module_ts.js                 | shipment-shipment-module                 | 101.29 kB | \n' +
                            'src_main_webapp_app_entities_product-category_product-category_module_ts.js | product-category-product-category-module |  81.46 kB | \n' +
                            'src_main_webapp_app_admin_health_health_module_ts.js                        | health-health-module                     |  30.62 kB | \n' +
                            'common.js                                                                   | common                                   |  29.42 kB | \n' +
                            'src_main_webapp_app_admin_configuration_configuration_module_ts.js          | configuration-configuration-module       |  26.35 kB | \n' +
                            'src_main_webapp_app_admin_logs_logs_module_ts.js                            | logs-logs-module                         |  24.96 kB | \n' +
                            'src_main_webapp_app_login_login_module_ts.js                                | login-login-module                       |  17.97 kB | \n' +
                            'src_main_webapp_app_entities_entity-routing_module_ts.js                    | entities-entity-routing-module           |   4.91 kB | \n' +
                            'src_main_webapp_app_admin_docs_docs_module_ts.js                            | docs-docs-module                         |   4.74 kB | \n' +
                            'src_main_webapp_app_admin_admin-routing_module_ts.js                        | admin-admin-routing-module               |   4.02 kB | \n' +
                            '\n' +
                            'Build at: 2023-05-06T21:09:30.250Z - Hash: def93400cd5aa95c - Time: 20402ms\n' +
                            'INFO! Generator entities succeed\n' +
                            'Congratulations, JHipster execution is complete!\n' +
                            'Sponsored with ❤️  by @oktadev.\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JdlStudioJHipsterContent;