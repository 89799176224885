import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class KeycloakSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-keycloak-spring-boot", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare Keycloak cu Spring Boot</b>
                    <br/>
                    <br/>

                    In <b>build.gradle</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'// trebuie sa fie in acord cu versiunea de keycloak folosit\n' +
                            'implementation group: \'org.keycloak\', name: \'keycloak-spring-boot-starter\', version: \'15.0.2\''}
                    </SyntaxHighlighter>

                    In <b>application.properties</b> se adauga:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'keycloak.auth-server-url=http://localhost:9091/auth\n' +
                            'keycloak.realm=ibrid\n' +
                            'keycloak.resource=student-app\n' +
                            'keycloak.credentials.secret=f6d32d5c-2013-42f1-8642-290fc8600516\n' +
                            '\n' +
                            '# pentru a lua username si nu id-ul utilizatorului\n' +
                            'keycloak.principal-attribute=preferred_username'}
                    </SyntaxHighlighter>

                    Se creaza clasa <b>KeycloakConfig</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.security;\n' +
                            '\n' +
                            'import org.keycloak.adapters.KeycloakConfigResolver;\n' +
                            'import org.keycloak.adapters.springboot.KeycloakSpringBootConfigResolver;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class KeycloakConfig {\n' +
                            '\n' +
                            '    // pentru a se uita in configurarile facute in application.properties\n' +
                            '    @Bean\n' +
                            '    KeycloakConfigResolver keycloakConfigResolver() {\n' +
                            '        return new KeycloakSpringBootConfigResolver();\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            '\n'}
                    </SyntaxHighlighter>

                    Se creaza clasa <b>KeycloakConfig</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.security;\n' +
                            '\n' +
                            'import org.keycloak.adapters.springsecurity.KeycloakConfiguration;\n' +
                            'import org.keycloak.adapters.springsecurity.config.KeycloakWebSecurityConfigurerAdapter;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.security.config.annotation.authentication.builders.AuthenticationManagerBuilder;\n' +
                            'import org.springframework.security.config.annotation.web.builders.HttpSecurity;\n' +
                            'import org.springframework.security.core.session.SessionRegistryImpl;\n' +
                            'import org.springframework.security.web.authentication.session.RegisterSessionAuthenticationStrategy;\n' +
                            'import org.springframework.security.web.authentication.session.SessionAuthenticationStrategy;\n' +
                            '\n' +
                            '@KeycloakConfiguration\n' +
                            'public class KeycloakSecurityConfig extends KeycloakWebSecurityConfigurerAdapter {\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    public void configureGlobal(AuthenticationManagerBuilder builder){\n' +
                            '        builder.authenticationProvider(keycloakAuthenticationProvider());\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void configure(HttpSecurity http) throws Exception {\n' +
                            '        super.configure(http);\n' +
                            '        http.authorizeRequests().antMatchers("/contact-us").permitAll()\n' +
                            '                .anyRequest().authenticated()\n' +
                            '                .and().exceptionHandling().accessDeniedPage("/access-denied");\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected SessionAuthenticationStrategy sessionAuthenticationStrategy() {\n' +
                            '        return new RegisterSessionAuthenticationStrategy(new SessionRegistryImpl());\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Nu va mai fi nevoie de:
                    <ul>
                        <li>SecurityConfiguration</li>
                        <li>UserDetailsServiceImpl</li>
                        <li>Roles</li>
                        <li>User</li>
                        <li>UserService</li>
                        <li>UserRepository</li>
                        <li>RolesRepository</li>

                    </ul>

                    Pentru testare se adauga metoda <b>StudentController</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'    @GetMapping(value = { "/contact-us"})\n' +
                            '    public ModelAndView contactUs() {\n' +
                            '        ModelAndView modelAndView = new ModelAndView("contact-us");\n' +
                            '        return modelAndView;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Si se sterge urmatoarea metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'  @GetMapping(value = {"/", "/login"})\n' +
                            '    public ModelAndView login() {\n' +
                            '        ModelAndView modelAndView = new ModelAndView("login");\n' +
                            '        return modelAndView;\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Si o pagina html (resources\tempaltes\contact-us.html).

                    <br/>
                    <br/>
                    Apoi se schimba in <i>home.html</i> din:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {' Welcome <span th:text="${#authentication.getPrincipal().getUsername()}"></span>'}
                    </SyntaxHighlighter>
                    in
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {' Welcome <span th:text="${#authentication.getPrincipal()}"></span>'}
                    </SyntaxHighlighter>
                    Daca nu se face schimbare de mai sus se va obtine urmatoarea eroare:
                    <br/>
                    <i>Caused by: org.springframework.expression.spel.SpelEvaluationException: EL1004E: Method call: Method getUsername() cannot be found on type org.keycloak.KeycloakPrincipal</i>
                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://medium.com/keycloak/running-keycloak-using-postgresql-database-d8edc8d22a16"}>*/}
                    {/*                Running Keycloak using PostgreSQL database*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default KeycloakSpringContent;