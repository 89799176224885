import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OverviewGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-overview", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Generalitati</b>

                    <hr/>
                    <b>Clone branch</b>
                    <br/>

                    Pentru a clona direct un branch:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git clone -b <nume_branch> <URL>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Dezactivare limita dimensiune nume fisier</b>
                    <br/>
                    Git are o limită de 4096 de caractere pentru un numele de fișiere (sau 260 de caractere pe Windows când Git este compilat cu <i>msys</i>  pentru ca foloseste o versiune mai veche de API a Windows-ului)
                    <br/>
                    Pentru a dezactiva aceasta limitare:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git config --system core.longpaths true'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Adaugare in comit toate fisierele</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git commit -am "mesaj"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Adaugare in comit doar un fisier</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git add index.html'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Git Push</b>
                    <br/>
                    Trimtere catre repository remote:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Git Pull</b>
                    <br/>
                    Luat ultimele modificari de pe repository remote:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git pull'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Git Status</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git status'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Revert la un fisier modificat, dar necomis (git checkout .)</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout .'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Git Branch</b>
                    <br/>
                    Afisare branch-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Schimbare branch</b>
                    <br/>
                    Afisare branch-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout nume_branch'}
                    </SyntaxHighlighter>
                    In loc de comanda de mai sus, se poate folosi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git switch nume_branch'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Merge</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout master'}
                    </SyntaxHighlighter>
                    Se aduc modificarile pe master de pe <i>nume_branch</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git merge nume_branch'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Stash</b>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git stash save "denumire_stash"'}
                    </SyntaxHighlighter>

                    Cand se salveaza intr-un stash modificarea se salveaza pe stash, dar se reseteaza de pe branch/master!
                    <br/>
                    Observatii legate de un stash:
                    <ul>
                        <li>sunt asemanatoare branch-urile, dar:
                            <ul>
                                <li>nu se poate face push</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>Afisare lista de stash-uri</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git stash list'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OverviewGitContent;