import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SpringContextContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-context", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>Contextul</b> de Spring sau contenxtul aplicatiei poate fi vazuta ca o zona in memoria aplicatiei unde se adauga toate instante de obiecte pe care le vrem gestionate de catre framework-ul Spring.
                    Aceste instante de obiecte se numesc <b>bean</b>-uri.
                    <br/>
                    Pentru a adauga bean-uri in contextul aplicatiei se pot folosi urmatoarele 3 abordari:
                    <ul>
                        <li>folosind adnotarea <b>@Bean</b></li>
                        <li>folosind adnotari de stereotip</li>
                        <li>programatic</li>
                    </ul>

                    <hr/>
                    <b>1. Adaugare bean-uri folosind adnotarea @Bean</b>
                    <br/>
                    <br/>

                    Sa presupunem ca vrem sa adaugam in context un obiect de tipul <i>Melc</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.model;\n' +
                        '\n' +
                        'public class Melc {\n' +
                        '\n' +
                        '    private String nume;\n' +
                        '\n' +
                        '    public String getNume() {\n' +
                        '        return nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setNume(String nume) {\n' +
                        '        this.nume = nume;\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a face acest lucru trebuie:
                    <ul>
                        <li>In <b>pom.xml</b> sa avem dependinta:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<dependency>\n' +
                                '   <groupId>org.springframework</groupId>\n' +
                                '   <artifactId>spring-context</artifactId>\n' +
                                '   <version>5.3.10</version>\n' +
                                '</dependency>'}
                            </SyntaxHighlighter>

                        </li>

                        <li>Definita o clasa de configurare (adnotata cu <b>@Configuration)</b> si o metoda adnotata cu <b>@Bean</b> care va returna obiectul pe care vrem sa il adaugam in context (in exemplul de mai jos un obiect de tipul <i>Melc</i>).
                            <br/>
                            Numele metodei va devenii numele bean-ului in contextul aplicatiei (in exemplul de mai jos <i>melc</i>):

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.context.config;\n' +
                                '\n' +
                                'import org.springframework.context.annotation.Bean;\n' +
                                'import org.springframework.context.annotation.Configuration;\n' +
                                'import ro.ibedaria.spring.context.model.Melc;\n' +
                                '\n' +
                                '@Configuration\n' +
                                'public class AppConfig {\n' +
                                '\n' +
                                '    @Bean\n' +
                                '    public Melc melc(){\n' +
                                '        Melc melc = new Melc();\n' +
                                '        melc.setNume("Codobelc");\n' +
                                '        return melc;\n' +
                                '    }\n' +
                                '\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>Initializare context cu clasa de configurare:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(AppConfig.class);'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Pentru accesarea unui obiect din context se foloseste metoda <b>getBean(<i>TipObiect.class</i>)</b>.
                    <br/>
                    Exemplu de test:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.AnnotationConfigApplicationContext;\n' +
                        'import ro.ibedaria.spring.context.model.Melc;\n' +
                        'import ro.ibedaria.spring.context.config.AppConfig;\n' +
                        '\n' +
                        'public class SpringContextApp {\n' +
                        '\n' +
                        '    public static void main(String args[]){\n' +
                        '\n' +
                        '        AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(AppConfig.class);\n' +
                        '\n' +
                        '        Melc melc = context.getBean(Melc.class);\n' +
                        '\n' +
                        '        System.out.println(melc.getNume()); // Codobelc \n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <b>Observatii:</b>
                    <br/>
                    <ul>
                        <li>in context se pot adauga si obiecte de tip String sau Integer

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Bean\n' +
                                'public Integer numarCoarne(){\n' +
                                '   return 2;\n' +
                                '}'}
                            </SyntaxHighlighter>

                            aceesate:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'Integer numarCoarne = context.getBean(Integer.class);\n' +
                                'System.out.println(numarCoarne); // 2'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            in context se pot adauga mai multe de obiecte de acelasi tip; in acest caz, trebuie accesate folosind si numele bean-ului.
                            <br/>
                            Deci, daca mai adaugam un bean:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Bean\n' +
                                'public Melc zeulMelc(){\n' +
                                '    Melc melc = new Melc();\n' +
                                '    melc.setNume("Marele Codobelc");\n' +
                                '    return melc;\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Si incercam sa il accesam in exemple de mai sus, vom obtine:
                            <SyntaxHighlighter>
                                {'No qualifying bean of type \'ro.ibedaria.spring.context.model.Melc\' available: expected single matching bean but found 2: melc,zeulMelc'}
                            </SyntaxHighlighter>

                            Prin urmare trebuie calificat si prin nume:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'Melc melc = context.getBean("zeulMelc", Melc.class);\n' +
                                'System.out.println(melc.getNume()); //Marele Codobelc'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            numele unui bean, in mod implicit este dat de numele metodei, dar se poate stabili si prin intermediul adnotarii <b>@Bean</b>:
                            <ul>
                                <li>@Bean("nume_bean")</li>
                                <li>@Bean(name="nume_bean")</li>
                                <li>@Bean(value="nume_bean")</li>
                            </ul>
                        </li>

                        <li>
                            daca se adauga mai multe obiecte de acelasi tip, se poate stabilii unul primar dintre ele, folosind anotarea <b>Primary</b>.
                            Deci, daca completam bean-ul <i>zeulMelc</i>:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Bean\n' +
                                '@Primary\n' +
                                'public Melc zeulMelc(){\n' +
                                '    Melc melc = new Melc();\n' +
                                '    melc.setNume("Marele Codobelc");\n' +
                                '    return melc;\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Atunci, fiind adnotat cu <b>Primary</b> se poate accesa si fara calificarea prin nume:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'Melc melc = context.getBean(Melc.class);\n' +
                                'System.out.println(melc.getNume()); //Marele Codobelc'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>


                    <hr/>
                    <b>2. Adaugare bean-uri folosind adnotari de stereotip</b>
                    <br/>
                    <br/>

                    Sa presupunem ca vrem sa adaugam in context un obiect de tipul <i>Cocos</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.model;\n' +
                        '\n' +
                        'public class Cocos {\n' +
                        '\n' +
                        '    private String nume;\n' +
                        '\n' +
                        '    public String getNume() {\n' +
                        '        return nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setNume(String nume) {\n' +
                        '        this.nume = nume;\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a face acest lucru trebuie:
                    <ul>
                        <li>In <b>pom.xml</b> sa avem dependinta:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<dependency>\n' +
                                '   <groupId>org.springframework</groupId>\n' +
                                '   <artifactId>spring-context</artifactId>\n' +
                                '   <version>5.3.10</version>\n' +
                                '</dependency>'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            Adnotam clasa <i>Cocos</i> cu adnotarea stereotip <b>@Component</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.context.model;\n' +
                                '\n' +
                                'import org.springframework.stereotype.Component;\n' +
                                '\n' +
                                '@Component\n' +
                                'public class Cocos {\n' +
                                '\n' +
                                '    private String nume;\n' +
                                '\n' +
                                '    public String getNume() {\n' +
                                '        return nume;\n' +
                                '    }\n' +
                                '\n' +
                                '    public void setNume(String nume) {\n' +
                                '        this.nume = nume;\n' +
                                '    }\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>Definita o clasa de configurare (adnotata cu <b>@Configuration)</b>. Acesta clasa o vom adnota si cu <b>@ComponentScan</b> pentru a specifica unde sa caute clasele sterotip:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.context.config;\n' +
                                '\n' +
                                'import org.springframework.context.annotation.ComponentScan;\n' +
                                'import org.springframework.context.annotation.Configuration;\n' +
                                '\n' +
                                '@Configuration\n' +
                                '@ComponentScan(basePackages = "ro.ibedaria")\n' +
                                'public class AppConfig {\n' +
                                '    \n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>Initializare context cu clasa de configurare:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(AppConfig.class);'}
                            </SyntaxHighlighter>

                        </li>

                    </ul>

                    Exemplu de test:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.AnnotationConfigApplicationContext;\n' +
                        'import ro.ibedaria.spring.context.config.AppConfig;\n' +
                        'import ro.ibedaria.spring.context.model.Cocos;\n' +
                        '\n' +
                        'public class SpringContextApp {\n' +
                        '\n' +
                        '    public static void main(String args[]){\n' +
                        '\n' +
                        '        AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(AppConfig.class);\n' +
                        '\n' +
                        '        Cocos cocos = context.getBean(Cocos.class);\n' +
                        '        System.out.println(cocos.getNume()); // null\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Comparatie intre adaugare bean-uri folosind adnotarea <b>@Bean</b> versus folosind adnotari stereotip:
                    <table>
                        <thead>
                            <tr>
                                <th>adnotare @Bean</th>
                                <th>adnotari stereotip</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>control total la crearea instantelor ce vor fi adaugate in context</td>
                                <td>control la instante, dupa crerea acestora de catre framework</td>
                            </tr>
                            <tr>
                                <td>se pot adauga mai multe instante de acelasi tip in context</td>
                                <td>se poate adauga doar o singura instanta de un anumit tip in context</td>
                            </tr>
                            <tr>
                                <td>se poate adauga orice tip de obiect (String, Integer)</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>(dezavantaj) trebuie scrisa cate o metoda pentru fiecare bean</td>
                                <td>(avantaj) e suficienta adnotarea de stereotip</td>
                            </tr>
                        </tbody>
                    </table>

                    Pentru a gestiona o instanta dupa crerea acesteia, este nevoie de:
                    <ul>
                        <li>in <b>pom.xml</b> trebuie sa avem dependinta;
                            <br/>
                            Adnotarile <b>@PostConstruct</b> și <b>@PreDestroy</b> fac parte din Java EE.
                            Și deoarece Java EE a fost depreciat în Java 9 și eliminat în Java 11, trebuie să adăugăm o dependență suplimentară pentru a folosi aceste adnotări.

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<dependency>\n' +
                                '   <groupId>javax.annotation</groupId>\n' +
                                '   <artifactId>javax.annotation-api</artifactId>\n' +
                                '   <version>1.3.2</version>\n' +
                                '</dependency>'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            adnotarea <b>@PostConstruct</b> (metoda se executa dupa ce obiectul a fost creat; dupa ce si-a terminat constructorul clasei executia):

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@PostConstruct\n' +
                                'public void init(){\n' +
                                '    this.nume = "Cotcodac";\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Cu metoda adnotata ca mai sus, numele cocosului va fi:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'System.out.println(cocos.getNume()); // Cotcodac'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            adnotarea <b>@PreDestroy</b> (nu este recomandata - metoda se executa inainte sa se curata si inchide contextul
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@PreDestroy\n' +
                                'public void destroy(){\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>3. Adaugare bean-uri programatic</b>
                    <br/>
                    <br/>

                    Un bean poate fi adauga programatic in context folosind una din formele metodei <b>registerBean</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<T> void registerBean(String beanName, Class<T> beanClass)'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<T> void registerBean(String beanName, Class<T> beanClass, Supplier<T> supplier)'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<T> void registerBean(String beanName, Class<T> beanClass, Supplier<T> supplier, BeanDefinitionCustomizer... customizer)'}
                    </SyntaxHighlighter>
                    <b>BeanDefinitionCustomizer</b> este o interfata prin intermediul caruia se pot configura diverse caracteristici ale bean-ului (de exemplu ca este <i>primar</i>)

                    <br/>
                    <br/>

                    Exemplul 1:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'context.registerBean(null, Melc.class)'}
                    </SyntaxHighlighter>

                    Exemplul 2:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Melc melc = new Melc();\n' +
                        'melc.setNume("Rapana");\n' +
                        '\n' +
                        'Supplier<Melc> melcSupplier = () -> melc;\n' +
                        '\n' +
                        'context.registerBean("melcRapana", Melc.class, melcSupplier);\n'}
                    </SyntaxHighlighter>

                    Exemplul 3:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Melc melc = new Melc();\n' +
                        'melc.setNume("Rapana");\n' +
                        '\n' +
                        'Supplier<Melc> melcSupplier = () -> melc;\n' +
                        '\n' +
                        'context.registerBean("melcRapana", Melc.class, melcSupplier, bc -> bc.setPrimary(true));'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default SpringContextContent;