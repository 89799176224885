import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class OperatorsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-operators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Operatori aritmetici</b>

                    <br/>
                    <br/>
                    <ul>
                        <li>+ (adunare)</li>
                        <li>- (scadere)</li>
                        <li>* (inmultire) (<b>rezultatul este un float, daca cel putin unul din argumente este float</b>)</li>
                        <li>/ (impartire cu zecimale), ex: 7/2 = 2.3333333333333335 (<b>rezultatul este intotdeauna un float</b>)</li>
                        <li>// (impartire intreaga, ex: 7//3 = 2)
                            (<b>rezultatul este un float, daca cel putin unul din argumente este float</b> si <b>rotunjirea merge întotdeauna la numărul întreg mai mic </b>, de exemplu -6 // 4 = -2, pentru ca -6 / 4 = -1.5, iar rotunjit de la cel mai intreg rezulta -2)
                        </li>
                        <li>% (modulo / restul impartirii)
                            <ul> 12 % 4.5 = 3.0, pentru ca:
                                <li>12 // 4.5  = 2.0</li>
                                <li>2.0 * 4.5  = 9.0</li>
                                <li>12 - 9.0  = 3.0</li>
                            </ul>
                        </li>
                        <li>** (ridicare la putere, ex: 2**4 = 16)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(2+3)\n' +
                        'print(2-3)\n' +
                        'print(2*3)\n' +
                        'print(7/3)\n' +
                        'print(7//3)\n' +
                        'print(7%3)\n' +
                        'print(2**4)'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'5\n' +
                        '-1\n' +
                        '6\n' +
                        '2.3333333333333335\n' +
                        '2\n' +
                        '1\n' +
                        '16'}
                    </SyntaxHighlighter>

                    Ordinea operatiilor:
                        <ul>
                            <li>
                                <b>de la stanga la drepta (left-sided binding) pentru operatorul modulo</b>, de exemplu: (9 % 6 % 2 = 1, adica ((9 % 6) % 2))
                            </li>
                            <li>
                                <b>de la drepta la stanga (right-sided binding) pentru operatorul de ridicare la putere</b>, de exemplu: 2 ** 2 ** 3 = 256, adica (2 ** (2 ** 3))
                            </li>
                        </ul>

                    Prioritatea operatiilor (de la cele mai mari (1) la cele mai mici (4) priorități):
                    <table className={"table"}>
                        <thead>
                            <tr>
                                <th>
                                    prioritate
                                </th>
                                <th>
                                    operatori
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    1
                                </th>
                                <th>
                                    +,- (operatori binari)
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    2
                                </th>
                                <th>
                                    **
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    3
                                </th>
                                <th>
                                    *,/,//,%
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    4
                                </th>
                                <th>
                                    +,- (operatori unari)
                                </th>
                            </tr>
                        </tbody>
                    </table>

                    Conform tabelului de mai sus <b>multiplicarea/inmultirea precede (dex: <i>a exista, a se produce înainte de altceva în timp</i>) adunarea</b>, adica se intampla inaintea adunarii (exemplu: 2+3*4 este echivalent cu 2+(3*4))

                </div>



                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OperatorsPythonContent;