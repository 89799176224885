import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CompanyLoginPrepopulateDomainLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-company-login-prepopulate-domain", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Câmpul de autentificare este prepopulat cu numele de domeniu al companiei dacă autentificarea este nepopulată și autentificarea utilizatorului se bazează pe adrese de e-mail. Setați acest lucru la false pentru a dezactiva comportamentul.

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'company.login.prepopulate.domain=false'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://docs.liferay.com/portal/7.0/propertiesdoc/portal.properties.html"}>
                                    https://docs.liferay.com/portal/7.0/propertiesdoc/portal.properties.html
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CompanyLoginPrepopulateDomainLiferayContent;