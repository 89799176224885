import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LocalizationLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-localization-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    In portlet, resursa bundle responsabila cu localizarea/i18n se defineste prin intermediul proprietatii:
                    <br/>
                    <b>javax.portlet.resource-bundle</b>

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t....\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t....\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')'}
                    </SyntaxHighlighter>

                    Fisierele care contin traducerile pentru fiecare cheie se gasesc, de obicei, in urmatoarea locatie:
                    <br/>
                    <b>/src/main/resources/content</b>
                    <br/>
                    <br/>

                    In JSP:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:message key="save-nok-key" />'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:message key="animale-preferate" arguments="<%= new String[]{"caine","pisica"} %>"/>'}
                    </SyntaxHighlighter>

                    Presupunem ca in fisierul <i>Language.properties</i> din  <b>/src/main/resources/content</b>, avem urmatorul continut:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'save-nok-key = Entitatea s-a salvat!\n' +
                        'animale-preferate = Animalele preferate: {0} si {1}'}
                    </SyntaxHighlighter>

                    In backend:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference\n' +
                        '\tprivate Language language;\n' +
                        '\n' +
                        '\t@Reference\n' +
                        '\tprivate ResourceBundleLoader resourceBundleLoader;\n' +
                        '\n' +
                        '\tprivate String getLocalization(String key, Locale locale, Object...args){\n' +
                        '\t\tResourceBundle resourceBundle = resourceBundleLoader.loadResourceBundle(locale);\n' +
                        '\t\tString value = ResourceBundleUtil.getString(resourceBundle, key, args);\n' +
                        '\n' +
                        '\t\treturn value==null? language.format(locale, key, args): value;\n' +
                        '\n' +
                        '\t}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru share-urire intre module se foloseste:
                    <ul>
                        <li>

                            intructiunea <b>-liferay-aggregate-resource-bundles: [simbolicName1], [simbolicNameN]</b> in <b>bnd.bnd</b>.

                            <br/>
                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'-liferay-aggregate-resource-bundles: ro.letyournailsgrow.liferay.vanilla.common.i18n'}
                            </SyntaxHighlighter>
                            Si exista un modul cu urmatorul simbolic name:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'Bundle-Name: Common i18n\n' +
                                'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.common.i18n\n' +
                                'Bundle-Version: 1.0.0\n' +
                                '-sources: true'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            proprietatea <b>target</b> in adnotarea <b>@Reference</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Reference(\n' +
                                '\t\ttarget = "(bundle.symbolic.name=ro.letyournailsgrow.liferay.vanilla.common.i18n)"\n' +
                                ')\n' +
                                'private ResourceBundleLoader resourceBundleLoader;'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Daca vrem ca un camp din <b>service.xml</b> sa fie localizat, trebuie adaugat atributul <b>localized="true"</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<column name="title" type="String" localized="true" />'}
                    </SyntaxHighlighter>

                    Si in service-urile generate, se va folosi in loc de <i>String title</i> cu <i>{"Map<Locale,String> titleMap"}</i>
                    <br/>
                    Si in jsp-uri se va folosi: <i>.getTitle(locale)</i>.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LocalizationLiferayContent;