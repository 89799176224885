import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class NetStatLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-netstat", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a verifica porturile:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'netstat -ntlp'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'(Not all processes could be identified, non-owned process info\n' +
                            ' will not be shown, you would have to be root to see it all.)\n' +
                            'Active Internet connections (only servers)\n' +
                            'Proto Recv-Q Send-Q Local Address           Foreign Address         State       PID/Program name\n' +
                            'tcp        0      0 127.0.0.1:631           0.0.0.0:*               LISTEN      -\n' +
                            'tcp        0      0 0.0.0.0:22              0.0.0.0:*               LISTEN      -\n' +
                            'tcp        0      0 127.0.0.53:53           0.0.0.0:*               LISTEN      -\n' +
                            'tcp6       0      0 :::7946                 :::*                    LISTEN      -\n' +
                            'tcp6       0      0 :::8080                 :::*                    LISTEN      -\n' +
                            'tcp6       0      0 :::22                   :::*                    LISTEN      -\n' +
                            'tcp6       0      0 ::1:631                 :::*                    LISTEN      -\n' +
                            'tcp6       0      0 :::2377                 :::*                    LISTEN      -\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NetStatLinuxContent;