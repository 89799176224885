import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NodeSelectorsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-node-selectors", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Selectare noduri</b>
                    <br/>
                    <br/>

                    Se poate specifica ca un pod sa ruleze pe un anumit nod (se foloseste <b>nodeSelector</b> si o eticheta/label, de exemplu: <b>kubernetes.io/hostname</b> [cheie predefinita]: nume-nod)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: nginx\n' +
                            '    image: nginx\n' +
                            '  nodeSelector:\n' +
                            '    kubernetes.io/hostname: worker'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: nginx\n' +
                            '    image: nginx\n' +
                            '  nodeSelector:\n' +
                            '    size: Large'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>size: Large {"=>"} este de fapt o eticheta/label de tip cheie: valoare</li>
                    </ul>

                    Pentru a eticheta un nod (pentru ca trebuie etichetat inainte sa fie creat pod-ul):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes <nume-nod> <cheie>=<valoare>'}
                    </SyntaxHighlighter>

                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes node-kj size=Large'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare etichetele unui nod:
                    <SyntaxHighlighter>
                        {"kubectl get node node01 --show-labels"}
                    </SyntaxHighlighter>
                    <hr/>

                    Selectarea unui nod folosind aceasta metoda, este limitata la o selectie simpla bazata pe o eticheta (nu se pot folosi operatori de tip OR sau NOT).
                    Pentru o selectie mai complicata se foloseste <b>Node Affinity</b>.

                    <hr/>
                    Se pot adauga label-uri si la pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pod [nume-pod] [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare pod-uri in functie de label-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -l [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -l [cheie]!=[valoare]'}
                    </SyntaxHighlighter>

                    Afisare pod-uri cu label-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --show-labels'}
                    </SyntaxHighlighter>

                    Stergere label la un pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pod [nume-pod] [cheie]-'}
                    </SyntaxHighlighter>

                    Adaugare/actualizare pentru toate podurile o eticheta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pods --all [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NodeSelectorsK8sContent;