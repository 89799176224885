import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class CreateDatabaseDbContent extends BaseContentPage  {

    constructor(props) {
        super(props, "db-create-database", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Creare baza de date</b>
                    <br/>
                    <br/>

                    Pentru MySQL:
                    <SyntaxHighlighter>
                        {'CREATE DATABASE db'}
                    </SyntaxHighlighter>

                    Pentru PostgreSQL:
                    <SyntaxHighlighter>
                        {'CREATE DATABASE db'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateDatabaseDbContent;