import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class OptimizationTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-optimization", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Monitorizare</b>
                    <br/>
                    <br/>

                    In fisierul <b>catalina.bat</b> (din [tomcat]\bin) se adauga urmatoare linie:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'set "CATALINA_OPTS=-Dcom.sun.management.jmxremote.port=8086 -Dcom.sun.management.jmxremote.ssl=false -Dcom.sun.management.jmxremote.authenticate=false"'}
                    </SyntaxHighlighter>

                    Pentru monitorizare se poate folosi <b>Java VisualVM (jvisualvm.exe)</b>.
                    Java VisualVM este un instrument care oferă informații despre codul care rulează pe o mașină virtuală Java.
                    <br/>
                    Instrumentul jvisualvm fost furnizat cu JDK 6, JDK 7 și JDK 8.

                    Java VisualVM nu mai este livrat cu JDK, dar îl puteți obține de pe <a target={"_blank"} href={"https://visualvm.github.io/"}>site-ul proiectului open source VisualVM</a>

                    <br/>
                    O alternativa este: <b>FusionReactor</b>

                    <br/>
                    <br/>
                    Monitorizare/legarea se face pe baza portului definit mai sus: <b>8086</b> (din Java VisualVM se da <b>Add JMX Connection</b> si la Connection: <b>localhost:8086</b>)

                    <hr/>
                    <b>2. Conetori si executori</b>
                    <br/>
                    <br/>
                    Implicit Tomcat porneste 10 executori (deci putem avea 10 cereri concurente).
                    Pentru a mari numarul de fire de executie de tip executori, se editeaza [tomcat]\conf\server.xml, adaugand atributul <b>tomcatThreadPool</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Connector executor="tomcatThreadPool"\n' +
                            '               port="8080" protocol="HTTP/1.1"\n' +
                            '               connectionTimeout="20000"\n' +
                            '               redirectPort="8443" />'}
                    </SyntaxHighlighter>

                    Apoi definim un <b>Executor</b> cu numele <i>tomcatThreadPool</i> in sectiunea Server\Service:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Executor name="tomcatThreadPool" namePrefix="catalina-exec-"\n' +
                            '        maxThreads="200" minSpareThreads="150"/>'}
                    </SyntaxHighlighter>
                    Deci, Tomcat va porni cu 150 de executori si pot creste pana la maxim 200.


                    <hr/>
                    <b>3. Optimizarea memoriei</b>
                    <br/>
                    <br/>

                    Parametri pentru dimesiunea JVM Heap:
                    <ul>
                        <li>XMS - Default Size</li>
                        <li>XMX - Limita maxim a Heap-ului</li>
                    </ul>

                    Pentru a seta acesti parametri la pornirea Tomcat-ului, se creeaza un fisier in <i>[tomcat]\bin</i>, cu numele <b>setenv.bat</b>, avand urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'set "JAVA_OPTS=%JAVA_OPTS% -Xms1023m -Xmx2048m -server" '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Compresia raspunsului</b>
                    <br/>
                    <br/>

                    In mod implicit, compresia nu este activata.
                    <br/>
                    Ar trebuie folosita pentru fisiere de tip text (html, text, json) si nu pe fisiere binare (imagini, video, etc).

                    <br/>
                    Pentru a activa compresia, se adauga in fisierul <i>[tomcat]\conf\server.xml</i>, in <b>Connector</b>, atributele:
                    <ul>
                        <li><b>compression</b>: specifica daca este activ sau nu</li>
                        <li><b>compressionMinSize</b>: specifica dimensiunea minim a fisierului pentru care se face compresia; daca este mai mic, compresia nu se mai efectueaza</li>
                        <li><b>compressionMimeType</b>: specifica tipul de continut</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Connector executor="tomcatThreadPool"\n' +
                            '               port="8080" protocol="HTTP/1.1"\n' +
                            '               connectionTimeout="20000"\n' +
                            '               compression="on"\n' +
                            '               compressionMinSize="1024"\n' +
                            '               compressionMimeType="text/html, text/xml"\n' +
                            '               redirectPort="8443" />'}
                    </SyntaxHighlighter>
                    Dca ne vom uita la Header-ele cerere, in <i>Response Header</i>, va apare <i>Content-Encoding: gzip</i>
                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OptimizationTomcatContent;