import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class UsingStateCorrectlyContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-using-state-correctly", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Folosire corecta state</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            starea nu se modifica direct
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'// Gresit\n' +
                                    'this.state.comment = \'Hello\';'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'// Corect \n' +
                                    'this.state.comment = \'Hello\';'}
                            </SyntaxHighlighter>
                            Singurul loc unde puteți aloca this.stateeste constructorul.

                        </li>

                        <li>
                            Actualizările de stare pot fi asincrone
                            <br/>
                            React poate grupa mai multe apeluri <b>setState()</b> într-o singură actualizare pentru performanță.
                            <br/>

                            Deoarece <b>this.props</b> și <b>this.state</b> pot fi actualizat asincron, nu ar trebui să vă bazați pe valorile lor pentru calcularea stării următoare.

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'// Gresit\n' +
                                    'this.setState({\n' +
                                    '  counter: this.state.counter + this.props.increment,\n' +
                                    '});'}
                            </SyntaxHighlighter>

                            Pentru a o remedia, utilizați o a doua formă setState()care acceptă o funcție mai degrabă decât un obiect. Această funcție va primi starea anterioară ca prim argument, iar elementele de recuzită în momentul în care actualizarea este aplicată ca al doilea argument:

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'// Corect\n' +
                                    'this.setState((state, props) => ({\n' +
                                    '  counter: state.counter + props.increment\n' +
                                    '}));'}
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'// Corect\n' +
                                    'this.setState(function(state, props) {\n' +
                                    '  return {\n' +
                                    '    counter: state.counter + props.increment\n' +
                                    '  };\n' +
                                    '});'}
                            </SyntaxHighlighter>



                        </li>

                        <li>
                            Actualizarea starii pentru un obiect se face prin <b>merge</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {' constructor(props) {\n' +
                                    '    super(props);\n' +
                                    '    this.state = {\n' +
                                    '      posts: [],\n' +
                                    '      comments: []\n' +
                                    '    };\n' +
                                    '  }'}
                            </SyntaxHighlighter>

                            atunci:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {' this.setState({\n' +
                                    '        comments: response.comments\n' +
                                    '      });'}
                            </SyntaxHighlighter>

                            va pastra <i>posts</i> asa cum erau si modifica doar <i>comments</i>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UsingStateCorrectlyContent;