import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ReduxToolkitContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-redux-toolkit", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Introducere</b>
                    <br/>
                    <br/>

                    <b>Redux</b> este o bibliotecă pentru gestionarea stării globale a aplicației.
                    <br/>
                    <br/>

                    <b>Redux Toolkit</b> este abordarea oficială recomandată pentru scrierea logicii Redux.
                    <br/>
                    Acesta cuprinde nucleul Redux și conține pachete și funcții esențiale pentru construirea unei aplicații Redux.
                    <br/>
                    Redux Toolkit integrează cele mai bune practici, simplifică majoritatea sarcinilor Redux, previne greșelile obișnuite și facilitează scrierea aplicațiilor Redux.

                    <br/>
                    <br/>
                    Modul recomandat de a porni aplicații noi cu React și Redux este utilizarea șablonului oficial Redux+JS sau șablonului Redux+TS pentru Create React App , care profită de integrarea Redux Toolkit și React Redux cu componentele React.
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'# Redux + Plain JS template\n' +
                            'npx create-react-app my-app --template redux\n' +
                            '\n' +
                            '# Redux + TypeScript template\n' +
                            'npx create-react-app my-app --template redux-typescript'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>2. Instalare</b>
                    <br/>
                    <br/>

                    Instalare Redux in cadrul unui proiect React:
                    <ul>
                        <li><b>redux</b></li>
                        <li><b>react-redux</b></li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install redux react-redux'}
                    </SyntaxHighlighter>

                    Instalare: <b>@reduxjs/toolkit</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install @reduxjs/toolkit'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3.  Mod de utilizare cu redux-toolkit (migrare de la redux)</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>combinare <i>reducer</i> si <i>actiuni</i> intr-un <b>slice</b></li>
                        <li><b>createStore</b> se inlocuite cu <b>configureStore</b></li>
                        <li>actualizare <b>useSelector()</b> si <b>useDispatch()</b> pe componente</li>
                    </ul>

                    <hr/>
                    3.1. combinare <i>reducer</i> si <i>actiuni</i> intr-un <b>slice</b>
                    <br/>
                    <br/>

                    In directorul <i>store</i>, creem alt director <i>slice</i> si aici fisierul <i>counterSlice.js</i> cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {createSlice} from "@reduxjs/toolkit";\n' +
                            '\n' +
                            'const counterSlice = createSlice({\n' +
                            '    name: "counter",\n' +
                            '    initialState: {\n' +
                            '        count: 0 // initializare stare (simular cu state= 0, de mai jos)\n' +
                            '    },\n' +
                            '    reducer: { // reducer (combinare "reducer" si "actiuni" intr-un "slice") \n' +
                            '        // actions\n' +
                            '        ADD: (state) => {\n' +
                            '            state.count = state.count + 1;\n' +
                            '        },\n' +
                            '        SUBSTRACT: (state) => {\n' +
                            '            state.count = state.count - 1;\n' +
                            '        },\n' +
                            '        RESET: (state) => {\n' +
                            '            state.count = 0;\n' +
                            '        },\n' +
                            '        ADD_NUMBER: (state, action) => {\n' +
                            '            state.count = state.count + action.payload;\n' +
                            '        }\n' +
                            '    }\n' +
                            '});\n' +
                            '\n' +
                            'const {ADD, ADD_NUMBER, SUBSTRACT, RESET} = counterSlice.actions;\n' +
                            '\n' +
                            'export default counterSlice.reducer;'}

                    </SyntaxHighlighter>

                    ca si comparatie, presentam si codul in varianta <i>redux</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { createStore } from \'redux\'\n\n' +
                            '// create reducer\n' +
                            'const countReducer = (state = 0, action) => {\n' +
                            '   if (action.type==="SUBSTRACT"){\n' +
                            '       return state - 1;\n' +
                            '   }\n' +
                            '   if (action.type==="ADD"){\n' +
                            '       return state + 1;\n' +
                            '   }\n' +
                            '   if (action.type==="RESET"){\n' +
                            '       return state = 0;\n' +
                            '   }\n' +
                            '   return state;\n' +
                            '}\n' +
                            '\n' +
                            '// creare store\n' +
                            'const store = createStore(countReducer);\n' +
                            '\n' +
                            'export default store;'}
                    </SyntaxHighlighter>

                    <hr/>
                    3.2. <b>createStore</b> se inlocuite cu <b>configureStore</b>
                    <br/>
                    <br/>

                    Apoi se creaza un fisier, de exemplu, <i>store.js</i> cu urmatorul continut (aici, <b>createStore</b> se inlocuite cu <b>configureStore</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {configureStore} from "@reduxjs/toolkit";\n' +
                            '\n' +
                            'import countReducer from "./slice/counterSlice";\n' +
                            '//import authReducer from "./slice/authSlice";\n' +
                            '\n' +
                            '// create a store\n' +
                            'const store = configureStore({\n' +
                            '    reducer: {\n' +
                            '        counter: countReducer,\n' +
                            '       // auth: authReducer\n' +
                            '    }\n' +
                            '});\n' +
                            '\n' +
                            'export default store;'}
                    </SyntaxHighlighter>

                    <hr/>
                    3.2. actualizare <b>useSelector()</b> si <b>useDispatch()</b> pe componente
                    <hr/>
                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const count = useSelector(state=>state.count);'}
                    </SyntaxHighlighter>
                    devine:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const count = useSelector(state=>state.counter.count);'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {useSelector, useDispatch} from "react-redux";\n' +
                            '\n' +
                            'import {ADD, ADD_NUMBER, SUBSTRACT, RESET} from "./slice/counterSlice"\n' +
                            '\n' +
                            '\n' +
                            'const Example = () => {\n' +
                            '    const count = useSelector((state) => state.counter.count);\n' +
                            '\n' +
                            '    useDispatch(ADD());\n' +
                            '    useDispatch(ADD_NUMBER(5));\n' +
                            '    useDispatch(SUBSTRACT());\n' +
                            '    useDispatch(RESET());\n' +
                            '\n' +
                            '}\n' +
                            '\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Provider</b> pentru aplicatie:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Provider } from \'react-redux\';'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { store } from \'./store/store\';'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Provider store={store}>\n' +
                            '  <App/>\n' +
                            '</Provider>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReduxToolkitContent;