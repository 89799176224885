import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class Ex3k8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-ex3", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 3: webk8s (linie de comanda)</b>
                    <br/>
                    <br/>

                    Sa presupunem ca avem imaginea pe dockerhub: <i>iulianbuzdugan/webk8s</i>
                    <br/>

                    Se creeaza deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment k8s-web --image=iulianbuzdugan/webk8s'}
                    </SyntaxHighlighter>

                    Se creeaza serviciul ClusterIP (pentru a putea fi accesat din interiorul cluster-ului Kubernetes):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment k8s-web --port=3000'}
                    </SyntaxHighlighter>

                    Scalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl scale deployment k8s-web --replicas=5'}
                    </SyntaxHighlighter>

                    <hr/> <hr/>
                    <b>2. Serviciul NodePort: Expunere in exterior</b>
                    <br/>
                    <br/>

                    Se sterge serviciul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete service k8s-web'}
                    </SyntaxHighlighter>
                    (daca se incerca sa se creeze un alt serviciu, va da eroare)

                    Se creeaza serviciul NodePort (pentru a putea fi accesat din afara cluster-ului Kubernetes):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment k8s-web --type=NodePort --port=3000'}
                    </SyntaxHighlighter>

                    Ip-ul cluster-ului Kubernates:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' minikube ip'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'192.168.49.2'}
                    </SyntaxHighlighter>

                    Accesare din afara:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment k8s-web --type=NodePort --port=3000'}
                    </SyntaxHighlighter>
                    (
                    pentru un server nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment nginx-deployment --port=8080 --target-port=80 --type=NodePort'}
                    </SyntaxHighlighter>
                    )

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get svc'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'k8s-web              NodePort    10.102.183.118   <none>        3000:30490/TCP   6s'}
                    </SyntaxHighlighter>
                    Portul cu care poate fi accesata aplicatia din afara este <b>30490</b>.

                    <hr/>
                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'curl 192.168.49.2:30490'}
                    </SyntaxHighlighter>
                    sau pentru a porni automat in browser:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube service k8s-web'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'|-----------|------------------|-------------|---------------------------|\n' +
                            '| NAMESPACE |       NAME       | TARGET PORT |            URL            |\n' +
                            '|-----------|------------------|-------------|---------------------------|\n' +
                            '| default   | k8s-web          |        8080 | http://192.168.49.2:30490 |\n' +
                            '|-----------|------------------|-------------|---------------------------|\n' +
                            '🎉  Opening service default/k8s-web in default browser...\n' +
                            '/usr/bin/xdg-open: 882: www-browser: not found\n' +
                            '/usr/bin/xdg-open: 882: links2: not found\n' +
                            '/usr/bin/xdg-open: 882: elinks: not found\n' +
                            '/usr/bin/xdg-open: 882: links: not found\n' +
                            '/usr/bin/xdg-open: 882: lynx: not found\n' +
                            '/usr/bin/xdg-open: 882: w3m: not found\n' +
                            'xdg-open: no method available for opening \'http://192.168.49.2:30490\'\n' +
                            '\n' +
                            '❌  Exiting due to HOST_BROWSER: exit status 3\n' +
                            '\n' +
                            '╭───────────────────────────────────────────────────────────────────────────────────────────╮\n' +
                            '│                                                                                           │\n' +
                            '│    😿  If the above advice does not help, please let us know:                             │\n' +
                            '│    👉  https://github.com/kubernetes/minikube/issues/new/choose                           │\n' +
                            '│                                                                                           │\n' +
                            '│    Please run `minikube logs --file=logs.txt` and attach logs.txt to the GitHub issue.    │\n' +
                            '│    Please also attach the following file to the GitHub issue:                             │\n' +
                            '│    - /tmp/minikube_service_6a9f64fee8e47351cf5ad677d9c24ed42e89572e_0.log                 │\n' +
                            '│                                                                                           │\n' +
                            '╰───────────────────────────────────────────────────────────────────────────────────────────╯\n'}
                    </SyntaxHighlighter>

                    Pentru a obtine doar URL-ul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube service k8s-web --url'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'http://192.168.49.2:30490'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Load Balancer</b>
                    <br/>
                    <br/>
                    Se sterge serviciul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete service k8s-web'}
                    </SyntaxHighlighter>
                    (daca se incerca sa se creeze un alt serviciu, va da eroare)

                    Se creeaza serviciul de tipul <b>LoadBalancer</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment k8s-web --type=LoadBalancer --port=3000'}
                    </SyntaxHighlighter>
                    (in minikube <b>LoadBalancer</b> e similar cu <b>PortNode</b>)

                    <hr/>
                    <b>4. Actualizare</b>
                    <br/>
                    <br/>

                    Sa presupunem ca a aparut o noua imagine <i>webk8s:2.0.0</i>. Actualizarea se face in urmatorul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set image deployment k8s-web k8s-web=ulianbuzdugan/webk8s:2.0.0'}
                    </SyntaxHighlighter>

                    Status-ul actiunii de actualizare (rollout):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout status deploy webk8s'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex3k8sContent;