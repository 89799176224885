import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlLabelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-label", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl label</b>
                    <br/>
                    <br/>


                    Pentru a eticheta un nod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes <nume-nod> <cheie>=<valoare>'}
                    </SyntaxHighlighter>

                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes node-kj disk=ssd'}
                    </SyntaxHighlighter>

                    Cand se defineste un pod se poate specifica, de exemplu, sa fie pun intr-un nod cu o anumita eticheta, folosind sectiunea <b>nodeSelector</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: nginx\n' +
                            '    image: nginx\n' +
                            '  nodeSelector:\n' +
                            '    disk: ssd'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: nginx\n' +
                            '    image: nginx\n' +
                            '  nodeSelector:\n' +
                            '    kubernetes.io/hostname: worker'}
                    </SyntaxHighlighter>

                    <hr/>
                    Se pot adauga label-uri si la pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pod [nume-pod] [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    In fisier YAML:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-pod # nume pod\n' +
                            '  labels:\n' +
                            '    app: my-app-kj\n' +
                            '\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kj-container\n' +
                            '    image: busybox\n' +
                            '    command: [\'sh\', \'-c\',\'echo this is busybox && sleep 360\']'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare pod-uri in functie de label-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -l [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -l [cheie]!=[valoare]'}
                    </SyntaxHighlighter>

                    Afisare pod-uri cu label-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --show-labels'}
                    </SyntaxHighlighter>

                    Stergere label la un pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pod [nume-pod] [cheie]-'}
                    </SyntaxHighlighter>

                    Adaugare/actualizare pentru toate podurile o eticheta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pods --all [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlLabelContent;