import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class TipsTricksTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-tips-tricks", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Fisierul catalina.properties</b>
                    <br/>
                    <br/>

                    In fisierul <b>catalina.properties</b> (din <i>[tomcat]\conf</i>) se steaza diversi parametri, ca de exemplu:
                    <ul>
                        <li>
                            calea catre directorul de librarii <b>common.loader</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'common.loader="${catalina.base}/lib","${catalina.base}/lib/*.jar","${catalina.home}/lib","${catalina.home}/lib/*.jar"'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            ce jar-uri sa nu se incarce la pornirea Tomcat-ului <b>tomcat.util.scan.StandardJarScanFilter.jarsToSkip</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'tomcat.util.scan.StandardJarScanFilter.jarsToSkip=\\\n' +
                                    'annotations-api.jar,\\'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    <hr/>
                    <b>2. Fortare HTTPS</b>
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            Pentru a forta traficul care vine pe portul HTTP să treacă întotdeauna prin HTTPS

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<security-constraint>\n' +
                                    '\t\t<web-resource-collection>\n' +
                                    '\t\t\t<web-resource-name>Wildcard means whole app requires authentication</web-resource-name>\n' +
                                    '\t\t\t<url-pattern>/*</url-pattern>\n' +
                                    '\t\t\t<http-method>GET</http-method>\n' +
                                    '\t\t\t<http-method>POST</http-method>\n' +
                                    '\t\t</web-resource-collection>\n' +
                                    '\t\t<auth-constraint>\n' +
                                    '\t\t\t<role-name>supportkj</role-name>\n' +
                                    '\t\t</auth-constraint>\n' +
                                    '\n' +
                                    '\t\t<user-data-constraint>\n' +
                                    '\t\t\t<!-- transport-guarantee can be CONFIDENTIAL, INTEGRAL, or NONE -->\n' +
                                    '\t\t\t<transport-guarantee>CONFIDENTIAL</transport-guarantee>\n' +
                                    '\t\t</user-data-constraint>\n' +
                                    '\t</security-constraint>'}
                            </SyntaxHighlighter>

                            Atentie, transport-guarantee = CONFIDENTIAL:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<transport-guarantee>CONFIDENTIAL</transport-guarantee>'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>3. Web Server & Application Server</b>
                    <br/>
                    <br/>

                    Web Server (Tomcat):
                    <ul>
                        <li>container, care poate executa doar JSP si servleti</li>
                        <li>suporta protocoale ca HTTP, HTTPS, AJP</li>
                    </ul>

                    Application Server (JBoss, WebSphere, WebLogic):
                    <ul>
                        <li>suporta in plus, protocoale ca JMS, Corba, RMI, etc</li>
                        <li>suport pentru EJB</li>
                    </ul>

                    <hr/>
                    <b>3. Detalii logate in Catalina.log</b>
                    <br/>
                    <br/>

                    Afiseaza detalalii legate de:
                    <ul>
                        <li>parametri de logare la runtime</li>
                        <li>bootstraping connectori</li>
                        <li>incarcare fisierele de configurare si proprietati</li>
                        <li>initializare porturi pentru connectori</li>
                        <li>initializari SSL</li>
                        <li>pornire server</li>
                        <li>incarcarea si initializare aplicatiilor</li>
                        <li>exceptii care apar in procesul de pornire</li>
                        <li>erori si exceptii</li>
                    </ul>

                    <hr/>
                    <b>4. Hot deploy / Cold deploy</b>
                    <br/>
                    <br/>

                    La hot deploy nu nevoie de pornirea serverului (atributul <b>autoDeploy="true"</b> pentru tag-ul <b>Host</b>).
                    <br/>
                    La cold deploy este nevoie de pornirea serverului.

                    <hr/>
                    <b>5. Horizontal Clustering</b>
                    <br/>
                    <br/>

                    Servere identice pe masini diferite (servere fizice).

                    <hr/>
                    <b>6. Rulare aplicatii pe HTTPS / SSL</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>este nevoie de KeyStoreFile sau Certificat SSL</li>
                        <li>
                            configurare keyStore in <b>server.xml</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>7. Protocol AJP</b>
                    <br/>
                    <br/>

                    AJP este un protocol optimizat pentru comunicarea Apache Products.
                    <br/>
                    Este echivalentul binar a lui HTTP (Apache HTTP Server comunica prin AJP cu Apache Tomcat Server).

                    <hr/>
                    <b>8. High Availability</b>
                    <br/>
                    <br/>

                    Aplicatia trebuie sa functioneze (24/7) indiferenta daca se intampla:
                    <ul>
                        <li>o defectiune hardware</li>
                        <li>o defectiune de retea</li>
                        <li>upgrade server</li>
                        <li>update OS</li>
                        <li>update aplicatie</li>
                        <li>crapa aplicatia</li>
                    </ul>

                    O partea din rezolvarea problemelor de mai sus este prin clustering.

                    <hr/>
                    <b>8. JNDI</b>
                    <br/>
                    <br/>

                    Seamana cu un fisier de proprietati: key = value (DBServer = 192.168.0.20)
                    <br/>
                    Avantaje:
                    <ul>
                        <li>parametrii sunt gestionati de server admin, si nu de dezvoltatori</li>
                        <li>nu e nevoie de redeploy aplicatie, in cazul in care apare o schimbare</li>
                    </ul>

                    <hr/>
                    <b>8. Virtual Host / Share hosting</b>
                    <br/>
                    <br/>

                    Acesta permite sa ai mai multe domenii, iar IP va bate pe aceasi masina.
                    <br/>
                    Deci, mai multe domenii pe aceeasi masina (acelasi IP).

                    <br/>
                    <br/>
                    Cum stie un <i>server</i> sa identifice ce <i>aplicatie</i> sa fie invocata in <i>virtual host</i>?
                    <br/>
                    Server-ul:
                    <ul>
                        <li>analizeaza <b>Headers</b> {"->"} continutul de <b>Host</b></li>
                        <li>setare CATALINA_BASE catre locatia aplicatiei in virtual host:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    'set CATALINA_BASE=D:\\servers\\apache-instances\\instance1\n' +
                                    '\n' +
                                    'cd %CATALINA_HOME%\\bin\n' +
                                    'startup.bat'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>


                    <hr/>
                    <b>9. Stickey Sessions. Session Replication</b>
                    <br/>
                    <br/>

                    Stickey Session este folosit cand serverul este parte a unui cluster si exista un load balancer.
                    <br/>
                    Load balancer asigura ca un anumit nod va raspunde doar unui anumit user.
                    <br/>

                    <hr/>
                    <b>10. File log retension</b>
                    <br/>
                    <br/>

                    Fisierele log sunt pastrate in mod implicit 90 de zile.
                    <br/>
                    Pot fi configurate din <b>[tomcat]\conf\logging.properties</b>.


                    <hr/>
                    <b>10. Connectori. Executori</b>
                    <br/>
                    <br/>

                    <b>Conector</b> (job) = este un listener pentru cererile care vin de la un end user
                    <br/>
                    {"=>"} deleaga reguestul catre executori
                    <br/>
                    (dupa ce conexiunea este stabilita de catre conector, trimite cererea catre executor)
                    <br/>
                    (conector se ocupa de conexiune)

                    <br/>
                    <br/>
                    <b>Executor</b> = executa cererile; (numarul de executori pot fi mariti sau miscorati, pentru ca sunt fire de executie;
                    marind numarul de executori crestere concurenta)


                    <hr/>
                    <b>10. Catalina_Home. Catalina_Base</b>
                    <br/>
                    <br/>

                    <b>Catalina_Home</b> = pointeaza intotdeuna catre directorul unde instalat Tomcat
                    <br/>
                    <br/>
                    <b>Catalina_Base</b> =
                    <ul>
                        <li>in mod implicit Catalina_Base = Catalina_Home</li>
                        <li>poate fi schimbat pentru virtual hosting

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    'set CATALINA_BASE=D:\\servers\\apache-instances\\instance1\n' +
                                    '\n' +
                                    'cd %CATALINA_HOME%\\bin\n' +
                                    'startup.bat'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>11. Procese Tomcat care ruleaza in Linux</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'ps - ef | grep tomcat'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>12. Opriere proces Tomcat care ruleaza in Linux</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'kill 09 <process_ID>'}
                    </SyntaxHighlighter>

                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TipsTricksTomcatContent;