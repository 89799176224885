import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ContrastVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-contrast", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Contrast</b>
                    <br/>
                    <br/>
                    <b>Contrastul</b> se referă la valorile (extremele) luminoase-întunecate ale unei imagini, fiind  diferenta / distanta dintre cele doua valori (extreme) ale luminozitatii.

                    <br/>
                    Contrast maxim = este de la negru (lipsa lumina) la alb (lipsa detalii)

                    <hr/>
                    <b>2. Tonalitate</b>
                    <br/>
                    <br/>

                    <b>Ton</b> este un alt cuvânt pentru <b>valoare</b> si se refera la luminozitatea sau întunericul unei culori.
                    <br/>
                    Tonurile pot lua valori intre 0% (negru total) si 100% (alb total).
                    <br/>
                    <br/>
                    Zone:
                    <ul>
                        <li><b>zonele de umbre (shodaws)</b>: valori intre 0% - 40%</li>
                        <li><b>zonele de mijloc</b>: valori intre 40%-60% (in acest interval se gasesc si tonurile de piele)</li>
                        <li><b>zonele cele mai luminoase (highlights)</b>: valori intree 60% - 80% </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ContrastVideografieContent;