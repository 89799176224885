import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DebugJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-debug", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Debug - remote</b>
                    <br/>
                    <br/>


                    Atasare agent pentru a permite debug remote:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java -jar -agentlib:jdwp=transport=dt_socket,server=y,suspend=n,address=*:5005 app.jar'}
                    </SyntaxHighlighter>
                    Unde:
                    <ul>
                        <li>
                            <b>transport=dt_socket</b>:  configurează modul în care instrumentul de depanare comunică cu agentul de depanare;
                            <br/>
                            <b>dt_socket</b> inseamna ca se foloseste TCP/IP pentru a stabili comunicaea prin retea;
                            <br/>
                            acestea este intotdeauna modul in care se stabilietes comunicaea dintre agent si instrumentul de depanare;
                        </li>
                        <li>
                            <b>server=y</b>: înseamnă că agentul acționează ca un server după ce se atașează la execuția aplicației;
                            <br/>
                            Agentul așteaptă ca un instrument de depanare să se conecteze la acesta și să controleze execuția aplicației prin intermediul acestuia;
                            <br/>
                            se foloseste configuratia:
                            <br/>
                            <b>server=n</b>
                            <br/>
                            pentru a vă conecta la un agent de depanare, în loc să porniți unul
                        </li>
                        <li>
                            <b>suspend=n</b>: spune aplicației să pornească fără a aștepta conectarea unui instrument de depanare;
                            <br/>
                            Dacă doriți să împiedicați pornirea aplicației până când nu se conecteaza un depanator, trebuie să utilizat
                            <b>suspend=y</b>
                        </li>
                        <b>
                            <b>address=*:5005</b>:
                            îi spune agentului să deschidă portul 5005 pe sistem, portul la care instrumentul de depanare se va conecta pentru a comunica cu agentul
                        </b>
                    </ul>

                    Din Intellij: <b> Edit Configurations, Remote JVM Debug</b>!

                    <br/>
                    Observatie:
                    <ul>
                        <li>dezvoltatorul trebuie sa se asigure ca are acceasi versiune de cod sursa ca ceea utilizata pentru a genera aplicatia din mediul remote</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>*/}
                    {/*            Writing Hello World in Java byte code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DebugJavaContent;