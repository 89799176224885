import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class HistoryDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-history", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker history / docker image history</b>

                    <br/>
                    <br/>

                    Afisare layerele intermediare a unei imagini (trebuie citite invers):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker history nume_imagine'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image history nume_imagine'}
                    </SyntaxHighlighter>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker history alpine'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'IMAGE          CREATED        CREATED BY                                      SIZE      COMMENT\n' +
                            '9c6f07244728   3 months ago   /bin/sh -c #(nop)  CMD ["/bin/sh"]              0B\n' +
                            '<missing>      3 months ago   /bin/sh -c #(nop) ADD file:2a949686d9886ac7c…   5.54MB'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image history  nginx:latest'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'IMAGE          CREATED      CREATED BY                                      SIZE      COMMENT\n' +
                            '1403e55ab369   3 days ago   /bin/sh -c #(nop)  CMD ["nginx" "-g" "daemon…   0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  STOPSIGNAL SIGQUIT           0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  EXPOSE 80                    0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  ENTRYPOINT ["/docker-entr…   0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop) COPY file:e57eef017a414ca7…   4.62kB\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop) COPY file:abbcbf84dc17ee44…   1.27kB\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop) COPY file:5c18272734349488…   2.12kB\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop) COPY file:7b307b62e82255f0…   1.62kB\n' +
                            '<missing>      3 days ago   /bin/sh -c set -x     && addgroup --system -…   61.3MB\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  ENV PKG_RELEASE=1~bullseye   0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  ENV NJS_VERSION=0.7.9        0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  ENV NGINX_VERSION=1.23.3     0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  LABEL maintainer=NGINX Do…   0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop)  CMD ["bash"]                 0B\n' +
                            '<missing>      3 days ago   /bin/sh -c #(nop) ADD file:73e68ae6852c9afbb…   80.5MB\n'}
                    </SyntaxHighlighter>

                    Exemplu 3 (afisare formatata):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image history --format "table {{.ID}}\\t{{.CreatedBy}}" nginx:latest'}
                    </SyntaxHighlighter>

                    <hr/>
                    Urmatoarele instructiuni din Dockerfile vor rezulta in imagini intermediare cu "No Operation":
                    <ul>
                        <li>CMD</li>
                        <li>EXPOSE</li>
                        <li>WORKDIR</li>
                        <li>
                            ENTRYPOINT
                        </li>
                    </ul>
                    Instructiunea <b>RUN</b> nu este "No Operation"!

                    <hr/>

                    Pentru fiecare instructiune din Dockerfile se face cate un layer la build.
                    <br/>
                    Instructiunea <b>FROM [imagine]</b> se transforma in toate instructiunile din [imagine]
                    <br/>
                    De exemplu, fie fisierul <b>Dockerfile</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM ubuntu\n' +
                            '\n' +
                            'EXPOSE 80'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t demo-history .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image history demo-history'}
                    </SyntaxHighlighter>

                    va afisa:
                    <SyntaxHighlighter>
                        {'IMAGE          CREATED          CREATED BY                                      SIZE      COMMENT\n' +
                            'bdee0bee87ab   22 seconds ago   /bin/sh -c #(nop)  EXPOSE 80                    0B\n' +
                            '6b7dfa7e8fdb   3 weeks ago      /bin/sh -c #(nop)  CMD ["bash"]                 0B\n' +
                            '<missing>      3 weeks ago      /bin/sh -c #(nop) ADD file:481dd2da6de715252…   77.8MB\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {' docker image history ubuntu'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'IMAGE          CREATED       CREATED BY                                      SIZE      COMMENT\n' +
                            '6b7dfa7e8fdb   3 weeks ago   /bin/sh -c #(nop)  CMD ["bash"]                 0B\n' +
                            '<missing>      3 weeks ago   /bin/sh -c #(nop) ADD file:481dd2da6de715252…   77.8MB\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HistoryDockerContent;