import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class M2EclipsePluginMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-m2-eclipse-plugin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare proiect Maven folosind M2E</b>:
                    <br/>
                    <br/>

                    Acest plugin instalat pe Eclipse permite crearea de proiecte Maven din UI:
                    <ul>
                        <li>
                            File {'->'} New {'->'} Other {'->'} Maven {'->'} Maven Project {'->'} bifat Create a simple project {'->'} se urmeaza pasii din Wizard
                        </li>
                        <li>
                            File {'->'} New {'->'} Other {'->'} Maven {'->'} Maven Project {'->'} debifat Create a simple project {'->'}  se urmeaza pasii din Wizard, avand posiblitatea de a se alege arhetipul
                        </li>
                    </ul>


                    <b>Observatie 1</b>
                    <br/>
                    Daca apare vreo problema de compilare, ar fi bine sa existe urmatoarele linii in <b>pom.xml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<properties>\n' +
                            '    <maven.compiler.source>6</maven.compiler.source>\n' +
                            '    <maven.compiler.target>6</maven.compiler.target>\n' +
                            '    <project.build.sourceEncoding>UTF-8</project.build.sourceEncoding>\n' +
                            '</properties>'}
                    </SyntaxHighlighter>

                    {/*<b>Observatie 2</b>*/}
                    {/*<br/>*/}
                    {/*Nu este permisa rularea de goal-uri folosind M2E.*/}

                    {/*<br/>*/}
                    {/*<br/>*/}

                    <b>Observatie 2</b>
                    <br/>
                    <br/>

                    Pentru a executa, de exemplu <b>release:update-versions</b> (care incrementeaza in mod automat <b>version</b> din <b>pom.xml</b>)  este nevoie de anumite configurari:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<!-- release:update-versions -->\n' +
                            '  <distributionManagement>\n' +
                            '    <repository>\n' +
                            '      <id>local-repository</id>\n' +
                            '      <url>file://Users/kj/repo/</url>\n' +
                            '    </repository>\n' +
                            '  </distributionManagement>\n' +
                            '\n' +
                            '  <scm>\n' +
                            '    <url>https://bitbucket.org/kj/my-repo/src</url>\n' +
                            '    <connection>scm:git:ssh://git@bitbucket.org/kj/my-repo.git</connection>\n' +
                            '    <developerConnection>scm:git:ssh://git@bitbucket.org/kj/my-repo.git</developerConnection>\n' +
                            '    <tag>HEAD</tag>\n' +
                            '  </scm>'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default M2EclipsePluginMavenContent;