import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class FormReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-form", IndexContent);
    }

    render() {

        // const [person, setPerson] = useState({name: "", job: ""})
        //
        // const handleChange = (e) =>{
        //     const name = e.target.name;
        //     const job = e.target.job;
        //
        //     setPerson({ ...person, [name]:value});
        // }
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Forme</b>
                    <br/>
                    <br/>

                    Starea unei forme poate fi tinuta intr-un <i>state</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const [person, setPerson] = useState({name: "", job: ""})'}
                    </SyntaxHighlighter>

                    Actualizarea unei valori dintr-un state:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const handleChange = (e) =>{\n' +
                            '   const name = e.target.name;\n' +
                            '   const job = e.target.job;\n' +
                            '            \n' +
                            '   setPerson({ ...person, [name]:value});\n' +
                            '}'}
                    </SyntaxHighlighter>

                    De retinut e ca fiecare componenta de tip <b>input</b> trebuie sa aiba ca <b>name</b> numele proprietatii declarate in <i>state</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<input name="name" value={person.name}/>\n' +
                            '<input name="job" value={person.job}/>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru construirea mai faciala a formelor se poate folosi:
                    <ul>
                        <li>
                            <a target={"_blank"} href={"https://formik.org/"}>Formik</a>

                        </li>
                        <li>
                            <a target={"_blank"} href={"https://react-hook-form.com/"}>React Hook Form</a>
                        </li>
                    </ul>

                    <hr/>
                    Pentru validarea formelor se poate folosi:
                    <ul>
                        <li>
                            <a target={"_blank"} href={"https://www.npmjs.com/package/yup"}>Yup</a>

                        </li>
                        <li>
                            <a target={"_blank"} href={"https://zod.dev/"}>Zod</a>
                        </li>
                    </ul>

                    <hr/>
                    Exemplu <b>React Hook Form + Yup</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {useForm} from "react-hook-form";\n' +
                            'import {yupResolver} from "@hookform/resolvers/yup";\n' +
                            'import * as yup from "yup";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const SignupSchema = yup.object({\n' +
                            '    email: yup.string()\n' +
                            '        .required(emailRequired)\n' +
                            '        .email(emailInvalid),\n' +
                            '    password: yup.string()\n' +
                            '        .required(passwordInvalid)\n' +
                            '}).required();'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const {register, handleSubmit, formState: {errors}} = useForm({\n' +
                            '    resolver: yupResolver(SignupSchema)\n' +
                            '});\n' +
                            'const onSubmit = data => console.log(data);\n' +
                            '\n' +
                            'const onError = (errors) => {\n' +
                            '    console.log(errors);\n' +
                            '};'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<form onSubmit={handleSubmit(onSubmit, onError)}>\n' +
                            '\n' +
                            '\t<input name="email" type="text" register={register} errors={errors}/>\n' +
                            '\t<input name="password" type="password" register={register} errors={errors}/>\n' +
                            '\n' +
                            '\t<input type="submit" value="Conecteaza-te" />\n' +
                            '\n' +
                            '</form>'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FormReactContent;