import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class OpenFeignSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-open-feign", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Context</b>

                    <br/>
                    <br/>

                    Fie 2 servicii:
                    <ul>
                        <li>student-service (aplicatie Spring Boot, pornita pe portul 9091)</li>
                        <li>address-service (aplicatie Spring Boot, pornita pe portul 9092)</li>
                    </ul>

                    (pt mai multe detalii vezi partea legata de <b>WebClient</b>)

                    <hr/>
                    <b>2. Configurare Open Feign</b>
                    <br/>
                    <br/>

                    In Gradle <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'ext{\n' +
                            '    spring_cloud_version = "3.1.4"\n' +
                            '}'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'implementation group: \'org.springframework.cloud\', name: \'spring-cloud-starter-openfeign\', version: "${spring_cloud_version}"'}
                    </SyntaxHighlighter>

                    Se creeaza pachetul <i>ro.ibrid.learn.microservices.spring.feignclients</i>.
                    <br/>
                    <br/>
                    In <i>StudentServiceApplication</i> se adauga adnotarea:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@EnableFeignClients("ro.ibrid.learn.microservices.spring.feignclients")'}
                    </SyntaxHighlighter>
                    si importul
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import org.springframework.cloud.openfeign.EnableFeignClients;'}
                    </SyntaxHighlighter>

                    In <b>application.properties</b>
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'address.service.url = http://localhost:9091/api/address'}
                    </SyntaxHighlighter>
                    sau (explicatie mai jos):
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'address.service.url = http://localhost:9091'}
                    </SyntaxHighlighter>

                    Se creeaza intrefata <i>AddressFeignClient</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.feignclients;\n' +
                            '\n' +
                            'import org.springframework.cloud.openfeign.FeignClient;\n' +
                            'import org.springframework.web.bind.annotation.GetMapping;\n' +
                            'import org.springframework.web.bind.annotation.PathVariable;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.AddressResponse;\n' +
                            '\n' +
                            '/*\n' +
                            'address.service.url => e definit in application.properties\n' +
                            'address-feign-client => e numele acestului client\n' +
                            '/api/address => se concateneaza la "address.service.url" (optional, se poate scrie toata calea in "url" )\n' +
                            '\n' +
                            ' */\n' +
                            '@FeignClient(url="${address.service.url}", value = "address-feign-client", path = "/api/address")\n' +
                            'public interface AddressFeignClient {\n' +
                            '\n' +
                            '    // signatura copiata din "AddressController"\n' +
                            '    @GetMapping("/getById/{id}")\n' +
                            '    AddressResponse getById(@PathVariable long id);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    In <i>StudentService</i> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Autowired\n' +
                            'private AddressFeignClient addressFeignClient;'}
                    </SyntaxHighlighter>
                    si se modifica metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public AddressResponse getAddressById(long addressId){\n' +
                            '//\t\tMono<AddressResponse> addressResponseMono = webClient.get().uri("/getById/"+addressId).retrieve().bodyToMono(AddressResponse.class);\n' +
                            '//\t\treturn addressResponseMono.block();\n' +
                            '\n' +
                            '\t\treturn addressFeignClient.getById(addressId);\n' +
                            '\t}'}
                    </SyntaxHighlighter>
                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default OpenFeignSpringContent;