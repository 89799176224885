import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class GoogleProviderSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-google-provider-auth", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Google Provider</b>

                    <hr/>
                    Documentatie:
                    <br/>
                    <a target={"_blank"} href="https://developers.google.com/identity/protocols/oauth2">Using OAuth 2.0 to Access Google APIs</a>

                    <hr/>

                    Configurare:
                    <br/>
                    <a target={"_blank"} href="https://console.developers.google.com/apis/credentials">Google Apps</a>

                    <br/>
                    <br/>

                    Creez un nou proiect <b>lapretbun-localhost-springboot</b>:
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_5.png'}/>
                    </div>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_6.png'}/>
                    </div>

                    Apoi se selecteaza proiectul, din meniul din stanga se apasa pe <b>Credentials</b>, apoi pe butonul <b>+ Create Credentials</b>
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_7.png'}/>
                    </div>

                    Se alege <b>OAuth Client ID</b>
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_8.png'}/>
                    </div>

                    Se completeaza mai intai <b>OAuth consent screen</b>
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:500}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_9.png'}/>
                    </div>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_10.png'}/>
                    </div>

                    Domenii de care avem nevoie sunt:
                    <ul>
                        <li>email</li>
                        <li>profile</li>
                        <li>
                            openid
                        </li>
                    </ul>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_11.png'}/>
                    </div>

                    Se adauga utilizatorii care pot testa aplicatia:
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_12.png'}/>
                    </div>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_13.png'}/>
                    </div>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_14.png'}/>
                    </div>

                    Se retin:
                    <ul>
                        <li>Client ID: 295698100757-lbdv2fnurul1qie39h97gvgponcvqk5g.apps.googleusercontent.com</li>
                        <li>Client secret: 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</li>
                    </ul>

                    <hr/>
                    Daca este <b>aplicatie web</b>, sectiunea de consimtapant se poate completa asa:

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_9.png'}/>
                    </div>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_15.png'}/>
                    </div>

                    Si apoi doar <b>Save and continue</b>.

                    <br/>
                    <br/>
                    La adaugare credentialelor:
                    <ul>
                        <li>Authorized JavaScript origins: http://localhost:3000</li>
                        <li>Authorized redirect URIs: http://localhost:3000/api/auth/callback/google</li>
                    </ul>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_16.png'}/>
                    </div>

                    <ul>
                        <li>
                            Client ID:
                            657209058530-6qbi3klv7rnoqsb9ekchak0q2edhjjfe.apps.googleusercontent.com
                        </li>
                        <li>
                            Client secret:
                            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
                        </li>
                    </ul>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:700}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_17.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GoogleProviderSpringBootContent;