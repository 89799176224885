import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class Pep8PythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-pep-8", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Codul va fi mult mai des citit decât scris.
                    Un ghid de stil este despre <b>consecvență</b>.

                    <br/>
                    PEP 8 poate fi ignorat daca:
                    <ul>
                        <li>nu este compatibil cu codul mostenit</li>
                        <li>efect negativ asupra lizibilității codului</li>
                        <li>provoca inconsecvență cu restul codului</li>
                    </ul>

                    Exista verificatoare/instrumente care sa verifice codul scris este valid în raport cu convențiile stilului PEP 8:
                    <ul>
                        <li><b>pycodestyle</b>
                            <br/>
                            instalare:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'pip install pycodestyle'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <a href={"http://pep8online.com/about"}><b>PEP 8 online</b></a>
                        </li>
                    </ul>
                    Exista autoformatoare:
                    <ul>
                        <li><b>autopep8</b>: are nevoie de <b>pycodestyle</b></li>
                    </ul>

                    <hr/>
                    <b>1. Indentare</b>
                    <br/>
                    Nivelul de indentare, înțeles ca spațiul alb principal (adică, spații și tabulaturi) la începutul fiecărei linii logice, este folosit pentru a grupa instrucțiunile.

                    Reguli:
                    <ul>
                        <li>folosiți 4 spații pe nivel de indentare</li>
                        <li>folosiți mai degrabă spații decât tab</li>
                    </ul>

                    Notă: Amestecarea tabulatorilor și a spațiilor pentru indentare nu este permisă în Python 3. Acest lucru va genera o excepție <b>TabError</b>: „TabError: utilizarea inconsecventă a tabulatorilor și a spațiilor în indentare”.

                    <hr/>
                    <b>2. Linii de continuare</b>
                    <br/>
                    Liniile de continuare (adică, liniile logice de cod pe care doriți să le împărțiți pe alte linii pentru că sunt prea lungi sau pentru că doriți să îmbunătățiți lizibilitatea) sunt permise dacă utilizați paranteze/paranteze/acolade.


                    <hr/>
                    <b>3. Lungimea maximă a liniei și întreruperile de linie</b>
                    <br/>

                    Lungimea maxim este de <i>79 de caractere</i> (este posibil să se mărească lungimea liniei la 99 de caractere).
                    În cazul doctring-urilor și comentariilor, lungimea rândului nu trebuie să depășească <i>72 de caractere</i>.

                    <hr/>
                    <b>4. Intreruperi de linie si operatori</b>
                    <br/>

                    Chiar dacă în Python aveți permisiunea de a întrerupe linii de cod înainte sau după operatorii binari (cu condiția să faceți acest lucru în mod consecvent și ca această convenție să fi fost folosită în codul dvs. înainte), este recomandat să urmați sugestiile de stil ale lui Donald Knuth și să întrerupeți <i>înainte de operatorii binari</i>, deoarece acest lucru are ca rezultat un cod mai ușor de citit.

                    <hr/>
                    <b>5. Linii goale</b>
                    <br/>

                    Liniile goale, numite spații albe verticale, îmbunătățesc lizibilitatea codului.
                    <br/>
                    Se recomanda:
                    <ul>
                        <li>două linii goale pentru a delimita clasele sau functiile</li>
                        <li>o linie goala pentru a delimita metodele din interiorul unei clase</li>
                        <li>o linie goala în funcții pentru a sepera secțiuni logice</li>
                    </ul>

                    <hr/>
                    <b>6. Codări implicite</b>
                    <br/>

                    Se recomandă să utilizați codurile implicite ale lui Python (Python 3 -- UTF-8, Python 2 -- ASCII).

                    <br/>

                    Toți identificatorii din biblioteca standard Python <b>trebuie</b> să folosească identificatori numai ASCII și <b>trebuie</b> să folosească cuvinte în limba engleză ori de câte ori este posibil.

                    <hr/>
                    <b>7. Importurile</b>
                    <br/>

                    Ar trebui puse întotdeauna la <b>începutul scriptului</b>, între comentariile/docstrings-ul modulelor și globalele și constantele modulelor, respectând următoarea ordine:

                    <ul>
                        <li>importuri standard de biblioteci</li>
                        <li>importuri conexe de la terți</li>
                        <li>importuri specifice aplicației locale/bibliotecii</li>
                    </ul>

                    Se introduce <i>o linie goală</i> pentru a separa fiecare dintre grupurile de importuri de mai sus.

                    <br/>
                    Alte recomandari PEP 8:
                    <ul>
                        <li>importurile să fie pe linii separate ( si nu strânse pe o singură linie)</li>
                        <li>dacă este posibil, utilizați importuri absolute (adică importuri care utilizează căi absolute separate prin puncte)</li>
                        <li>evitarea importurilor folosind * (from pasari import * )</li>
                    </ul>

                    <hr/>
                    <b>8. Siruri de caractere</b>
                    <br/>

                    Python permite folosirea șirurilor de caractere cu ghilimele simple ' sau cu ghilimele duble ".
                    Nu exista nicio recomandare specială în PEP care să vă spună ce stil ar trebui să adoptați în scrierea codului (dar se impune regula consecventei).

                    <br/>
                    <br/>

                    Pentru a îmbunătăți lizibilitatea, se recomandă evitarea utilizarea barelor oblice inverse (caractere de escape) în șiruri. Deci:
                    <ul>
                        <li>dacă șirul conține caractere cu ghilimele simple, este recomandata utilizarea șirurilor cu ghilimele duble</li>
                        <li>dacă șirul conține caractere cu ghilimele duble, este recomandata utilizarea șirurilor cu ghilimele simple.</li>
                    </ul>

                    În cazul șirurilor cu ghilimele triple, se recomanda folosirea caracterelor cu ghilimele duble pentru a fi in concordanta cu convenția de documentare detaliată în PEP 257.


                    <hr/>
                    <b>9. Spații albe în expresii și enunțuri</b>
                    <br/>

                    Ar trebui să evitați să folosiți prea multe spații albe, deoarece vă face codul dificil de urmărit.

                    Deci, nu folosiți spații albe excesive:
                    <ul>
                        <li>dupa paranteze rotunde/paranteze patrate/acolade</li>
                        <li>înainte de virgulă/punct si virgulă/doua puncte</li>
                        <li>în cazul unui <b>slice (:)</b>, două puncte ar trebui să aibă cantități egale de spațiu pe ambele părți (ar trebui să acționeze ca un operator binar), cu excepția cazului în care un parametru de felie este omis, caz în care spațiul ar trebui omis</li>
                        <li>dupa virugla finala urmata de o paranteze de inchidere (ex: (1,2,) )</li>
                        <li>înaintea unei paranteze rotunde de deschidere care marchează începutul listei de argumente a unei invocări de funcție</li>
                        <li>înaintea unei paranteze patrate de deschidere care marchează începutul indexării/slicului</li>
                        <li>nu mai mult de un spațiu înainte și după operatori</li>
                        <li>
                            un singur spartiu alb pe ambele parti pentru operatorii binari
                            (dacă în cod există operatori care au priorități diferite, se poate adăuga de spații în jurul operatorilor cu prioritate scăzută (cel mai mic), de exemplu: x = x*4 - 3, si nu: x = x * 4 - 3)
                        </li>
                        <li>fara spatii pentru operatorul (=) dacă este folosit pentru a indica un argument al cuvântului cheie/valoare implicită, de exemplu: def functie(a, b=1)</li>
                    </ul>

                    <hr/>
                    <b>10. Comentarii</b>
                    <br/>

                    Cand se scrie un comentariu ar fi bine ca:
                    <ul>
                        <li>comentariile sa nu contrazica codul</li>
                        <li>sa se actualizeze pe masura ce codul este actualizat</li>
                        <li>fie propozitii complete (incepe cu litera mare si se termina cu .)</li>
                        <li>sa nu depaseaza 72 de caractere pe linie</li>
                        <li>in limba engleza</li>
                        <li>comentariile bloc cu mai multe propoztiie trebuie sa fie delimitate cu 2 spartii albe dupa fiecare punct (in afara de ultima propozitie)</li>
                    </ul>

                    Comentariile de bloc ar trebuie:
                    <ul>
                        <li>folosite pentru a aplica bucati de cod mai degraba decat linii de cod</li>
                        <li>să se refere la codul care le urmează</li>
                        <li>să fie indentate la același nivel cu codul pe care îl descriu</li>
                        <li>începeți fiecare rând cu # urmat de un singur spațiu și separați paragrafele printr-o linie care conține doar simbolul #</li>
                    </ul>

                    Comentariile inline sunt comentarii care sunt scrise pe aceeași linie cu declarațiile si ar trebui:
                    <ul>
                        <ul>sa ofere explicații suplimentare pentru o singură linie de cod sau o singură declarație</ul>
                        <ul>sa nu se foloseasca in mod excesiv</ul>
                        <ul>evitate pentru a explica lucruri evidente sau inutile</ul>
                        <ul>codul se se auto-comenteze</ul>
                    </ul>

                    Comentariile de documentatie (docstring-urile) permit să oferiți descrieri și explicații pentru toate modulele publice, fișierele, funcțiile, clasele și metodele pe care le utilizați în codul si ar trebui:
                    <ul>
                        <li>folosite in acest context</li>
                        <li>sa începa și sa se termine cu trei ghilimele duble</li>
                    </ul>

                    <hr/>
                    <b>11. Conventii de nume</b>
                    <br/>

                    Moduri de a scrie un nume:
                    <ul>
                        <li>lowercase: variabilamea</li>
                        <li>snake_case: variabila_mea</li>
                        <li>uppercase: VARIABILAMEA</li>
                        <li>SNAKE_CASE: VARIABILA_MEA</li>
                        <li>CamelCase: VasiabilaMea (pentru acronime: HTTPConection)</li>
                        <li>camelCase: variabilaMea</li>
                        <li>Capitalize_With_Underscores: Variabila_Mea (urat rau)</li>
                    </ul>

                    Recomandari:
                    <ul>
                        <li>trebuie evitate utilizare de nume cu o singură literă, cum ar fi l (litera mică el), I (ochiul literei mari) și O (litera mare oh), deoarece acestea pot fi ușor confundate cu numerele 1 și 0</li>
                        <li>un nume care incepe cu _ indica o folosire interna (ex: _variabila_mea); instructiunea de import nu va importa obiecte care incep cu _</li>
                        <li>un nume care se termina cu _ indica o folosire (conventie) pentru a evita un conflict cu un cuvant cheie (ex: class_)</li>
                        <li>un nume care incepe cu __ este folosit pentru atribute de clase</li>
                        <li>un nume care incepe cu __ si se termina cu __ este folosit pentru obiecte magice si atribute/functii aflate în spațiile de nume controlate de utilizator (ex: __init__, __file__, __import__)</li>
                        <li>nume variabila / functii / metoda / module: snake_case (pentru metode, primul argument ar trebuie sa aiba numele <i>self</i>)</li>
                        <li>nume clasa: CamelCase</li>
                        <li>nume constanta: SNAKE_CASE</li>
                        <li>nume pachet: lowercase</li>
                    </ul>

                    <hr/>
                    <b>12. Alte recomandari</b>
                    <br/>

                    Recomandari:
                    <ul>
                        <li>pentru comparatii cu obiectul <b>None</b> ar trebui utilizati operatorii <b>is</b> sau <b>is not</b> (si nu operatorii = și != )</li>
                        <li>pentru compatatii cu obiectul <b>Boolean</b> ar trebuie utilizati operatorii <b>is</b> sau <b>is not</b>

                            <SyntaxHighlighter language="python" style={androidstudio}>
                                {'mai_mare = 1 > 2\n' +
                                'if mai_mare is True:'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            pentru lizibilitate, ar trebui folosit operatorul <b>is not</b> în loc de <b>not … is</b>
                        </li>

                        <li>
                            a de evita utilizarea if x: pentru a exprima dacă x is not None
                        </li>

                        <li>
                            cand se prinde o excepție ar trebui sa se faca referire la exceptii mai specifice
                        </li>

                        <li>
                            când se verifica prefixele sau sufixele, utilizați metodele .startswith() și .endswith() (si nu: [:3] == "Ada")
                        </li>
                    </ul>

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP2 | Best practices and Standardization (Professional 2/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Pep8PythonContent;