import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubeadmDockerK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubeadm-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Configurare cluster <b>Kubernetes 1.26</b> folosind <b>kubeadm</b> cu <b>Docker</b> ca Runtime Container.
                    <br/>
                    Se construieste:
                    <ul>
                        <li>1 control plane node</li>
                        <li>1 worker node</li>
                    </ul>

                    <hr/>

                    <b>1. Afisare sistem de operare linux</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' cat /etc/os-release'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'PRETTY_NAME="Ubuntu 22.04.1 LTS"\n' +
                            'NAME="Ubuntu"\n' +
                            'VERSION_ID="22.04"\n' +
                            'VERSION="22.04.1 LTS (Jammy Jellyfish)"\n' +
                            'VERSION_CODENAME=jammy\n' +
                            'ID=ubuntu\n' +
                            'ID_LIKE=debian\n' +
                            'HOME_URL="https://www.ubuntu.com/"\n' +
                            'SUPPORT_URL="https://help.ubuntu.com/"\n' +
                            'BUG_REPORT_URL="https://bugs.launchpad.net/ubuntu/"\n' +
                            'PRIVACY_POLICY_URL="https://www.ubuntu.com/legal/terms-and-policies/privacy-policy"\n' +
                            'UBUNTU_CODENAME=jammy\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Instalare Kubeadm | kubectl | kubelet (pe toate masinile)</b>
                    <br/>
                    <br/>

                    Actualizare index pachete <b>apt</b> și instalare pachete necesare pentru a utiliza aptdepozitul Kubernetes:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get update\n' +
                            'sudo apt-get install -y apt-transport-https ca-certificates curl'}
                    </SyntaxHighlighter>

                    Descărcați cheia publică de semnare Google Cloud:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo curl -fsSLo /etc/apt/keyrings/kubernetes-archive-keyring.gpg https://packages.cloud.google.com/apt/doc/apt-key.gpg'}
                    </SyntaxHighlighter>
                    {/*sudo curl -fsSLo /usr/share/keyrings/kubernetes-archive-keyring.gpg https://packages.cloud.google.com/apt/doc/apt-key.gpg*/}

                    Adaugare depozit <i>apt</i> Kubernetes:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo "deb [signed-by=/etc/apt/keyrings/kubernetes-archive-keyring.gpg] https://apt.kubernetes.io/ kubernetes-xenial main" | sudo tee /etc/apt/sources.list.d/kubernetes.list'}
                    </SyntaxHighlighter>
                    {/*echo "deb [signed-by=/usr/share/keyrings/kubernetes-archive-keyring.gpg] https://apt.kubernetes.io/ kubernetes-xenial main" | sudo tee /etc/apt/sources.list.d/kubernetes.list*/}

                    Actualizare index pachete <b>apt</b>, instalare <b>kubelet</b>, <b>kubeadm</b> și <b>kubectl</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get update\n' +
                            'sudo apt-get install -y kubelet kubeadm kubectl\n' +
                            'sudo apt-mark hold kubelet kubeadm kubectl'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Dezactivare swap</b>
                    <br/>
                    <br/>
                    Swap ar trebui să fie dezactivat pentru ca <b>kubelet</b> să funcționeze corect.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo sed -i \'/ swap / s/^\\(.*\\)$/#\\1/g\' /etc/fstab\n' +
                            'sudo swapoff -a'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'free -h'}
                    </SyntaxHighlighter>
                    Daca swap NU este dezactivat se va afisa ceva de genul:
                    <SyntaxHighlighter>
                        {'          total        used        free      shared  buff/cache   available\n' +
                            'Mem:           5,8Gi       1,4Gi       233Mi       293Mi       4,1Gi       3,8Gi\n' +
                            'Swap:          5,9Gi       337Mi       5,6Gi\n'}
                    </SyntaxHighlighter>

                    Daca swap este dezactivat se va afisa ceva de genul:
                    <SyntaxHighlighter>
                        {'             total        used        free      shared  buff/cache   available\n' +
                            'Mem:           5,8Gi       1,7Gi       113Mi       312Mi       3,9Gi       3,5Gi\n' +
                            'Swap:             0B          0B          0B\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Încărcare modulul <b>br_netfilter</b> și lăsare <b>iptables</b> să vadă traficul</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo modprobe overlay\n' +
                            'sudo modprobe br_netfilter\n' +
                            'sudo tee /etc/sysctl.d/kubernetes.conf<<EOF\n' +
                            'net.bridge.bridge-nf-call-ip6tables = 1\n' +
                            'net.bridge.bridge-nf-call-iptables = 1\n' +
                            'net.ipv4.ip_forward = 1\n' +
                            'EOF\n' +
                            'sudo sysctl --system'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Configurare Dockerd</b>
                    <br/>
                    <br/>

                    <b>Dockershim</b> a fost depreciat și eliminat în <b>Kubernetes 1.24</b>, dar <b>Mirantis</b> menține asta, prin <b>cri-dockerd</b>.

                    <br/>
                    <br/>
                    Instalare <b>Docker</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apt install docker.io -y\n' +
                            'systemctl start docker\n' +
                            'systemctl enable docker'}
                    </SyntaxHighlighter>

                    Obtinere versiunea  <b>cri-dockerd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'VER=$(curl -s https://api.github.com/repos/Mirantis/cri-dockerd/releases/latest|grep tag_name | cut -d \'"\' -f 4|sed \'s/v//g\')'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo $VER'}
                    </SyntaxHighlighter>
                    0.3.1
                    <br/>
                    <br/>

                    Instalare <b>cri-dockerd</b>:
                    <SyntaxHighlighter>
                        {'wget https://github.com/Mirantis/cri-dockerd/releases/download/v${VER}/cri-dockerd-${VER}.amd64.tgz\n' +
                            'tar -xvf cri-dockerd-${VER}.amd64.tgz\n' +
                            'cd cri-dockerd/\n' +
                            'mkdir -p /usr/local/bin\n' +
                            'sudo install -o root -g root -m 0755 ./cri-dockerd /usr/local/bin/cri-dockerd'}
                    </SyntaxHighlighter>

                    Afisare versiune:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cri-dockerd --version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'cri-dockerd 0.3.1 (7e528b98)'}
                    </SyntaxHighlighter>

                    Se adauga fisierele <b>cri-docker.socker</b> si <b>cri-docker.service</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo tee /etc/systemd/system/cri-docker.service << EOF\n' +
                            '[Unit]\n' +
                            'Description=CRI Interface for Docker Application Container Engine\n' +
                            'Documentation=https://docs.mirantis.com\n' +
                            'After=network-online.target firewalld.service docker.service\n' +
                            'Wants=network-online.target\n' +
                            'Requires=cri-docker.socket\n' +
                            '[Service]\n' +
                            'Type=notify\n' +
                            'ExecStart=/usr/local/bin/cri-dockerd --container-runtime-endpoint fd:// --network-plugin=\n' +
                            'ExecReload=/bin/kill -s HUP $MAINPID\n' +
                            'TimeoutSec=0\n' +
                            'RestartSec=2\n' +
                            'Restart=always\n' +
                            'StartLimitBurst=3\n' +
                            'StartLimitInterval=60s\n' +
                            'LimitNOFILE=infinity\n' +
                            'LimitNPROC=infinity\n' +
                            'LimitCORE=infinity\n' +
                            'TasksMax=infinity\n' +
                            'Delegate=yes\n' +
                            'KillMode=process\n' +
                            '[Install]\n' +
                            'WantedBy=multi-user.target\n' +
                            'EOF\n' +
                            '\n' +
                            'sudo tee /etc/systemd/system/cri-docker.socket << EOF\n' +
                            '[Unit]\n' +
                            'Description=CRI Docker Socket for the API\n' +
                            'PartOf=cri-docker.service\n' +
                            '[Socket]\n' +
                            'ListenStream=%t/cri-dockerd.sock\n' +
                            'SocketMode=0660\n' +
                            'SocketUser=root\n' +
                            'SocketGroup=docker\n' +
                            '[Install]\n' +
                            'WantedBy=sockets.target\n' +
                            'EOF'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'#Daemon reload\n' +
                            'sudo systemctl daemon-reload\n' +
                            'sudo systemctl enable cri-docker.service\n' +
                            'sudo systemctl enable --now cri-docker.socket\n' +
                            '\n' +
                            '# Setup required sysctl params, these persist across reboots.\n' +
                            'cat <<EOF | sudo tee /etc/sysctl.d/99-kubernetes-cri.conf\n' +
                            'net.bridge.bridge-nf-call-iptables  = 1\n' +
                            'net.ipv4.ip_forward                 = 1\n' +
                            'net.bridge.bridge-nf-call-ip6tables = 1\n' +
                            'EOF\n' +
                            '\n' +
                            '# Apply sysctl params without reboot\n' +
                            'sudo sysctl --system'}
                    </SyntaxHighlighter>

                    <hr/>
                    Verificari:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' systemctl status cri-docker.socket'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'● cri-docker.socket - CRI Docker Socket for the API\n' +
                            '     Loaded: loaded (/etc/systemd/system/cri-docker.socket; enabled; vendor preset: enabled)\n' +
                            '     Active: active (running) since Sun 2023-02-05 11:38:25 EET; 2h 0min ago\n' +
                            '   Triggers: ● cri-docker.service\n' +
                            '     Listen: /run/cri-dockerd.sock (Stream)\n' +
                            '      Tasks: 0 (limit: 4626)\n' +
                            '     Memory: 0B\n' +
                            '        CPU: 1ms\n' +
                            '     CGroup: /system.slice/cri-docker.socket\n' +
                            '\n' +
                            'feb 05 11:38:25 nas-ubuntu-manager systemd[1]: Starting CRI Docker Socket for the API...\n' +
                            'feb 05 11:38:25 nas-ubuntu-manager systemd[1]: Listening on CRI Docker Socket for the API.\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'systemctl status docker'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'docker.service - Docker Application Container Engine\n' +
                            '     Loaded: loaded (/lib/systemd/system/docker.service; enabled; vendor preset: enabled)\n' +
                            '     Active: active (running) since Sun 2023-02-05 12:31:06 EET; 1h 10min ago\n' +
                            'TriggeredBy: ● docker.socket\n' +
                            '       Docs: https://docs.docker.com\n' +
                            '   Main PID: 1732593 (dockerd)\n' +
                            '      Tasks: 25\n' +
                            '     Memory: 72.6M\n' +
                            '        CPU: 28.919s\n' +
                            '     CGroup: /system.slice/docker.service\n' +
                            '             └─1732593 /usr/bin/dockerd -H fd:// --containerd=/run/containerd/containerd.sock\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Dezactivare swap</b>
                    <br/>
                    <br/>

                    De la versiunea Kubernetes 1.22 dacă nu setați campul <b>cgroupDriver</b> in <i>KubeletConfiguration</i>, <b>kubeadm</b> implicit il considera <b>systemd</b>, deci nu mai trebuie facut nimic!

                    <hr/>
                    <b>7. Tragere imagini</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubeadm config images pull --cri-socket unix:///var/run/cri-dockerd.sock --kubernetes-version v1.26.0'}
                    </SyntaxHighlighter>

                    Daca se obtine urmatoarea eroare:
                    <SyntaxHighlighter>
                        {'failed to pull image "registry.k8s.io/kube-apiserver:v1.25.1": output: time="2023-02-05T11:52:32+02:00" level=fatal msg="validate service connection: CRI v1 image API is not implemented for endpoint \\"unix:///var/run/cri-dockerd.sock\\": rpc error: code = Unimplemented desc = unknown service runtime.v1.ImageService"\n' +
                            ', error: exit status 1\n' +
                            'To see the stack trace of this error execute with --v=5 or higher\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://blog.kubesimplify.com/kubernetes-125-dockerd"}>Kubernetes 1.25 + Dockerd</a>
                        </li>
                    </ol>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://computingforgeeks.com/install-mirantis-cri-dockerd-as-docker-engine-shim-for-kubernetes/"}>Install Mirantis cri-dockerd as Docker Engine shim for Kubernetes</a>
                        </li>
                    </ol>
                    
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubeadmDockerK8sContent;