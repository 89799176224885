import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ComponentsKubernetesContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-components", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Componente Kubernetes</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            API server {"=>"} frontend pentru k8s (utilizatori, interfete in linie de comanda, aplicatii de management, comunica prin intermediul API Server)
                        </li>
                        <li>
                            etcd {"=>"} baza de date (cheie-valoare); se pastreaza toate datele pentru gestionarea clusterului
                        </li>
                        <li>
                            Scheduler {"=>"} distribuirea containerelor pe mai multe noduri
                        </li>
                        <li>
                            Controller {"=>"} este creierul din spatele orchestrarii; este reponsabil pentru a observa si a raspunde atunci cand nodurile, containere cad;
                            <br/>
                            pot lua decizi de a aduce noi containere, daca e nevoie
                        </li>
                        <li>
                            Container Runtime {"=>"} software folosit pentru a rula containere (de exemplu: Docker, rkt, CRI-O, etc)
                        </li>
                        <li>
                            Kubelet {"=>"} agentul care ruleaza pe fiecare nod din cluster; agentul este responsabil pentru asigurarea faptului ca containere ruleaza pe noduri conform asteptarilor;
                        </li>
                    </ul>

                    Pe nodurile master exista:
                    <ul>
                        <li>kube-apiserver</li>
                        <li>etcd</li>
                        <li>controller</li>
                        <li>scheduler</li>
                    </ul>
                    Pe nodurile worker, exista:
                    <ul>
                        <li>kubelet</li>
                        <li>container runtime</li>
                    </ul>

                    <b>kube-apiserver</b> comunica cu <b>kubelet</b>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ComponentsKubernetesContent;