import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class FunctionInjectAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-function-inject", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Injectare prin functia inject()</b>
                    <br/>
                    <br/>

                    Incepand cu Angular 14, acesta a introdus functia <b>inject()</b>.
                    <br/>
                    Acesta functie ia ca parametru un <i>InjectionToken</i> si returneaza valoarea/instanta pentru acel <i>InjectionToken</i> din contextul curent.
                    <br/>
                    <br/>
                    Practic, este o altă modalitate de a obține o dependență, alta decât utilizarea injecției de constructor:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class MyComponent{\n' +
                            '\tconstructor(private myService: MyService){}\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    este echivalent cu:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class MyComponent{\n' +
                            '\tprivate myService = inject(MyService);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Restrictii:
                    <ul>
                        <li>
                            nu se se poate apela functia <b>inject()</b>  in interiorul unui functii setter <b>@Input</b>
                            <br/>
                            incorect:
                            <br/>
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export class MyComponent{\n' +
                                    '\t@Input() public set title(title: String){\n' +
                                    '\t\tinject(ChangeDetectorRef).detectChange()\n' +
                                    '\t}\n' +
                                    '}\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            nu se se poate apela functioa <b>inject()</b>  in interiorul unui functii de <b>lifecycle</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>
                        2. UTIL: Mostenire mai usoara: eliminare apeluri super()
                    </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class ParentClass {\n' +
                            '    constructor(\n' +
                            '        private router: Router,\n' +
                            '    ) {\n' +
                            '        // ...\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            '@Component({\n' +
                            '    // component metadata\n' +
                            '})\n' +
                            'export class ChildComponent extends ParentClass {\n' +
                            '    constructor(\n' +
                            '        private router: Router,\n' +
                            '        private http: HttpClient,\n' +
                            '    ) {\n' +
                            '        super(router); // trebuie trimis la ParentClass\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Cu functia <b>inject</b> se poate scrie:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class ParentClass {\n' +
                            '    private router = inject(Router);\n' +
                            '}\n' +
                            '\n' +
                            '@Component({\n' +
                            '    // component metadata\n' +
                            '})\n' +
                            'export class ChildComponent extends ParentClass {\n' +
                            '    private http = inject(HttpClient);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        3. UTIL: Reducerea complexitatii
                    </b>
                    <br/>
                    <br/>

                    De exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'\n' +
                            'export class MyComponent{\n' +
                            '\n' +
                            '\tconstructor(\n' +
                            '\t\tprivate route: ActivateRoute,\n' +
                            '\t\tprivate http: HttpClient\n' +
                            '\t){\n' +
                            '\t\tthis.entity$ = route.params.pipe(\n' +
                            '\t\t\tmap((params: Params) => params["id"]),\n' +
                            '\t\t\tfilter(id => !!id),\n' +
                            '\t\t\tmergeMap(entityId: string) => http.get(`api/entity/${entityId}`)\n' +
                            '\t\t)\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>
                    se poate sparge in 2 fisiere:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const const useEntity = () => Observable<Entity> => {\n' +
                            '\n' +
                            '\tconst http = inject(HttpClient);\n' +
                            '\tconst route = inject(ActivateRoute);\t\n' +
                            '\t\n' +
                            '\treturn route.params.pipe(\n' +
                            '\t\t\tmap((params: Params) => params["id"]),\n' +
                            '\t\t\tfilter(id => !!id),\n' +
                            '\t\t\tmergeMap(entityId: string) => http.get(`api/entity/${entityId}`)\n' +
                            '\t'}
                    </SyntaxHighlighter>
                    si:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class MyComponent{\n' +
                            '\n' +
                            '\tthis.entity$ = useEntity();\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a  target={"_blank"} href={"https://codereacter.medium.com/why-angular-14s-new-inject-function-is-so-amazing-ac281e7148d1"}>
                               Why Angular 14’s new inject() function is so amazing?
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionInjectAngularContent;