import React from "react";
import {MathComponent} from "mathjax-react";

import {all, create} from "mathjs"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class HopfieldContent extends BaseContentPage {

    constructor(props) {
        super(props, "hopfield", IndexContent);
    }

    render() {

        const setA = [];
        setA.push([1, 0, 0, 1, 0, 0, 1, 1, 1]);
        setA.push([1, 1, 1, 0, 1, 0, 0, 1, 0]);
        setA.push([0, 1, 0, 1, 1, 1, 0, 1, 0]);

        const labelA = ['L', 'T', '+'];

        const dim = Math.sqrt(setA[0].length);
        const len = setA[0].length;

        const setT = [];
        setT.push([1, 0, 1, 1, 0, 0, 0, 1, 1]);

        // construct weight matrix
        let weight = [];
        for (let i = 0; i < len; i++) {
            weight[i] = new Array(len);
            for (let j = 0; j < len; j++) {
                weight[i][j] = 0;
                for (let p = 0; p < setA.length; p++) {
                    weight[i][j] += (2 * setA[p][i] - 1) * (2 * setA[p][j] - 1);
                }
            }
        }
        for (let i = 0; i < len; i++) {
            weight[i][i] = 0;
        }

        let orderNeuronFire = [1,2,3,4,5,6,7,8,9,1,2];

        let dynamicTable = [];

        let input = setT[0].slice();

        for(let k = 0; k<orderNeuronFire.length; k++) {
            let iterateResult = [];
            // j
            let neuron = orderNeuronFire[k] - 1;
            // sj
            let sum = 0;
            for (let i = 0; i < len; i++) {
                sum += weight[neuron][i] * input[i];
            }
            // aj
            let a = 0;
            if (sum > a) {
                a = 1;
            } else if (sum < 0) {
                a = 0;
            } else {
                a = setT[0][neuron];
            }
            // yj=aj
            let change = false;
            if (input[neuron] != a) {
                input[neuron] = a;
                change = true;
            }

            iterateResult.push((k + 1));
            iterateResult.push((neuron + 1));
            iterateResult.push(sum);
            iterateResult.push(a);
            iterateResult.push(input.slice());
            iterateResult.push(change);

            dynamicTable.push(iterateResult);
        }

        let formulaString = "A^{p}=[";
        setA[0].map((value, index) => {
            formulaString = formulaString + "a_" + (index + 1) + "^p";
        });
        formulaString = formulaString + "]";

        const config = {};
        const math = create(all, config);

        return (
            <div className="home hopfield">
                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify"}>
                    Retele Hopfield sunt un tip de retele neuronale recurente (RNN) popularizate de John Hopfield în
                    1982. <br/>Aceste retele sunt capabile să <b><i>reconstruiască</i></b> informația alterată.
                </div>
                <br/>

                <div className={"text-justify"}>
                    <b>1. Etapa de instruire</b>
                </div>

                <div className={"text-justify"}>
                    Să presupunem că vrem ca rețeaua noastră să învețe următoarele {labelA.length}
                    <b>imagini</b> / <b>simboluri</b>&nbsp;
                    ({
                    labelA.map((value, index) => {
                        return value + (index === (labelA.length - 1) ? " " : ",");
                    })
                })
                </div>

                <div className="d-flex flex-row">
                    {
                        setA.map((vector, index) => {

                            return <table>
                                {
                                    math.reshape(vector, [dim, dim]).map((row, index) => {
                                        return <tr>
                                            {
                                                row.map(value => {
                                                    return <td className={"content-" + value}>{value}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </table>
                        })
                    }

                </div>
                <div className={"text-justify"}>
                    <div className={"text-justify"}>
                        Aceste imagini/simboluri pot fi văzute ca niște vectori cu lungimea {setA[0].length}:
                    </div>

                    <div className={"d-flex flex-row"} >
                        <div className={"d-flex justify-content-start align-self-center"}>
                            <table style={{margin: 50}}>

                                {
                                    math.reshape(setA[0], [dim, dim]).map((row, index) => {
                                        return <tr>
                                            {
                                                row.map((value, indexCol) => {
                                                    return <td>a<sub>{index * dim + indexCol + 1}</sub></td>
                                                })
                                            }
                                        </tr>
                                    })
                                }

                            </table>
                        </div>
                        <div className={"d-flex justify-content-start"}>
                            <table className={"text-center"}>
                                <tr>
                                    <th>simbol</th>
                                    <th>vector cu 9 elemente</th>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold"></td>
                                    <td className="">
                                        <MathComponent tex={formulaString}/>
                                    </td>
                                </tr>

                                {
                                    setA.map((vector, index) => {

                                        return <tr>
                                            <td className="font-weight-bold">{labelA[index]}</td>
                                            <td className="">A<sup>{index}</sup> =
                                                [{
                                                    vector.map((value, index) => {
                                                        return value + " ";
                                                    })
                                                }]
                                            </td>
                                        </tr>

                                    })
                                }

                            </table>
                        </div>
                    </div>
                </div>

                <div className={"text-justify important"}>
                    <div className={"text-justify"}>
                        Pentru un vector A, definim functia <b>len(A) = numărul de elemente din vectorul A</b>. De
                        exemplu, len (A<sup>1</sup>) = {setA[0].length}.
                    </div>
                </div>

                <div className={"text-justify important"}>
                    <div className={"text-justify"}>
                        Conform metodei de calcul a lui Hopfield, <b>matricea ponderilor</b> se poate construi după
                        următoarea formulă:
                        <MathComponent tex={String.raw`w_{ij}=\sum_{p=1}^m (2a_i^p-1)(2a_j^p-1),  i<>j`}/>
                        <MathComponent tex={String.raw`w_{ij}=0,  i=j`}/>
                        unde <i><b>m</b></i> este numărul de imagini/simboluri, iar <i><b>i</b></i>, <i><b>j</b></i> au
                        valori valori intre 1 si len(A<sup>1</sup>).
                        Fie <b>n</b> = len(A<sup>1</sup>).
                        <br/> <br/>
                        Matricea ponderilor va fi o matrice:<br/>
                        - patratică de dimensiune <b>n x n</b> <br/>
                        - simetrică <br/>
                        - cu 0 pe diagonala principala
                    </div>
                </div>
                <br/>
                <div className={"text-justify"}>
                    <div className={"text-justify"}>
                        Pentru exemplul de mai sus <i><b>m</b></i> = {labelA.length}.
                    </div>
                </div>

                <div className={"text-justify"}>
                    <div className={"text-justify"}>
                        Matricea ponderilor, construită după metoda lui Hopfield:

                        <table>
                            {
                                math.reshape(weight, [len, len]).map((row, index) => {
                                    return <tr>
                                        {
                                            row.map((value, indexCol) => {
                                                if (index == indexCol) {
                                                    return <td className={"content-dp"}>{value}</td>
                                                }
                                                if (index < indexCol) {
                                                    return <td className={"content-dpup"}>{value}</td>
                                                }
                                                return <td>{value}</td>
                                            })
                                        }
                                    </tr>
                                })
                            }
                        </table>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>2. Etapa de utilizare</b>
                </div>

                <div className={"text-justify"}>
                    Să presupunem că se prezintă rețelei o imagine alterată și vrem reconstuirea acesteia.
                </div>

                <div className="d-flex flex-row">
                    {
                        setT.map((vector, index) => {

                            return <table>
                                {
                                    math.reshape(vector, [dim, dim]).map((row, index) => {
                                        return <tr>
                                            {
                                                row.map(value => {
                                                    return <td className={"content-" + value}>{value}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </table>
                        })
                    }

                </div>

                <div className={"text-justify"}>
                    Imaginea de mai sus are următorul vector echivalent:
                </div>

                <table>
                    <tr className="">
                        {
                            setT[0].map((value, index) => {
                                return <td>{value}</td>;
                            })
                        }
                    </tr>
                </table>

                <div className={"text-justify important"}>
                    Pentru o intrare alterată Y=[y<sub>1</sub>,..., y<sub>n</sub>] cu <b>n</b> elemente, ce trebuie să
                    fie reconstruită, activăm un neuron <b>j</b> (ales aleator sau datorită unei ordini predefinite) si
                    calculăm:<br/>
                    - funcția de sumarizare<br/>
                    <MathComponent tex={String.raw`s_{j}=\sum_{i=1}^n (w_{ji}y_i)`}/>
                    - funcția de activare<br/>

                    <MathComponent
                        tex={String.raw`a_j = \Bigg\{\begin{eqnarray} 1, s_j>0  \\ 0, s_j<0 \\ y_j, s_j=0  \end{eqnarray}`}/>

                    Actualizăm intrarea alterată:<br/>
                    <MathComponent tex={String.raw`y_{j}=a_j`}/>

                    Dacă s-a ajuns la o stare stabilă (la o configurație sau imagine memorată in pasul de antrenare) ne
                    oprim. <br/>
                    Altfel, pornind de la Y actualizat, se activeaza un alt neuron și se urmează pașii descriși mai sus
                    (calcul functie de sumarizare, calcul functie de activare, actualizare intrare)
                    <br/>

                    <div>
                        <img className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/hopfield_1.png'}/>
                    </div>
                </div>

                <div className={"text-justify"}>
                    Dinamica retelei:
                </div>

                <table className={"text-center"}>
                    <tr>
                        <th>Iteratie</th>
                        <th>Neuron activat</th>
                        <th>Rezultat sumarizare</th>
                        <th>Rezultat functie de activare</th>
                        <th>Noul vector</th>
                    </tr>

                    {
                        dynamicTable.map((rowTable, index)=>{
                            return <tr>
                                {
                                    rowTable.map((value,index)=>{
                                        if (index == rowTable.length-1){
                                            return "";
                                        }
                                        if (index == rowTable.length-2){
                                           return <td>
                                               {
                                                   value.map((v,i)=>{
                                                       let neuron = rowTable[1]-1;
                                                       if (rowTable[rowTable.length-1]===true && neuron!=i){
                                                           return <td className={"content-evident"}>{v}</td>
                                                       }
                                                       if (rowTable[rowTable.length-1]===true && neuron==i){
                                                           return <td className={"content-change"}>{v}</td>
                                                       }
                                                       return <td className={"content-ordinal"}>{v}</td>;
                                                   })
                                               }
                                           </td>
                                        }
                                        return <td>{value}</td>
                                    })
                                }
                            </tr>
                        })
                    }

                </table>


                <div className={"text-justify"}>
                    Dupa rularea aloritmului imaginea alterată este reconstuită:
                </div>

                <div className="d-flex flex-row">
                    {
                        setT.map((vector, index) => {

                            return <table>
                                {
                                    math.reshape(vector, [dim, dim]).map((row, index) => {
                                        return <tr>
                                            {
                                                row.map(value => {
                                                    return <td className={"content-" + value}>{value}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </table>
                        })
                    }

                    <div className={"align-middle align-self-center p-2"}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>

                    <table>
                    {
                        math.reshape(input, [dim, dim]).map((row, index) => {
                            return <tr>
                                {
                                    row.map(value => {
                                        return <td className={"content-" + value}>{value}</td>
                                    })
                                }
                            </tr>
                        })
                    }
                    </table>


                </div>

                <br/>
                <div className={"text-justify"}>
                    Rezumând, algoritmul învață cateva modele/imagini și apoi, primind o imagine noua, încercă să o transforme către un model sau imagine cunoscută.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <div><b>Observatii:</b></div>
                    Retele Hopfield au însemnat un pas important în domeniul rețelor neuronale articifiale, în special prin introducerea <b>functiei energeie</b>.<br/>
                    Rețele Hopfield evoluează întotdeauna în sensul descreșterii energiei, către o valoare minimă care corespunde unei stări de stabilitate.<br/>
                    Probleme cu o rețea Hopfield:<br/>
                        - se poate opri într-un minim local<br/>
                        - nu rezolvă problema parității, conectivități și simetriei
                </div>

                <br/>
                <div className={"text-justify"}>
                    În 1986 Georffy Hinton și Terry Sejnowsky au propus <b>Mașina Boltzmann</b> (un model de rețea neuronală în care neuronii au proprietăți probabilistice)
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Dumitru Popescu, Maria Luiza Flonta. 2009. Teoria rețelelor neuronale artificiale, Editura Universității din București</div>
                        </li>
                        <li>
                            <a href={"http://www.ene.unb.br/adolfo/Lectures/ICIN/SC84_103.pdf"}
                                   target="_blank">http://www.ene.unb.br/adolfo/Lectures/ICIN/SC84_103.pdf</a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default HopfieldContent;