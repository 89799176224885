import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class ScriptLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-script", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Un script este un fisier executabil. Vom construi ca exemplu un script:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nano script.sh'}
                    </SyntaxHighlighter>
                    Cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'#!/bin/bash\n' +
                            '\n' +
                            'echo "TEST"'}
                    </SyntaxHighlighter>

                    Apoi, il facem executabil:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod u+x script.sh'}
                    </SyntaxHighlighter>

                    Rulam:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./script.sh'}
                    </SyntaxHighlighter>
                    Trebuie calea completa! Nu merge cu <i>script.sh</i> (decat daca calea este in $PATH)
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ScriptLinuxContent;