import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Tips4DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tips4", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Tips - Instalare si configurare</b>

                    <br/>
                    <br/>

                    <b>1. DTR Backup Process</b>
                    <br/>
                    Se face backup la:
                    <ul>
                        <li>
                            raft keys {"=>"} folosite pentru criptarea comunicarii intre nodurile Swarm; pentru criptare/decriptarea log-urilor Raft
                        </li>
                        <li>
                            membership {"=>"} lista de noduri din cluster
                        </li>
                        <li>
                            services {"=>"} stacks and servicii pastrate in swarm-mode
                        </li>
                        <li>
                            (overlay) networks {"=>"} retele overlay create in cluster
                        </li>
                        <li>
                            config {"=>"} configuratii create in cluster
                        </li>
                        <li>
                            secrets {"=>"} secrete salvate in cluster
                        </li>
                        <li>
                            swarm unlock key
                        </li>
                    </ul>
                    Backup la utilizatori/organizatii se face din UCP.

                    <hr/>
                    <b>2. Namespaces</b> {"=>"} ofera un layer de izolare; (user namespace nu este activat implicit)
                    <hr/>

                    <hr/>
                    <b>3. Cgroups (control groups)</b> {"=>"} caracteristica a kernel-ului Linux care liminteaza, tine cont si izoleaza utilizarea resurselor (CPU, memorie, disc I/O, retea, etc) a unei colectii de procese.
                    <br/>
                    Pentru CPU:
                    <ul>
                        <li>
                            <b>--cpus</b>=[valoare] {"=>"} daca masina gazda are 2 CPU (procesoare), si se seteaza <b>--cpus=</b>1,
                            atunci containerul este garantat la maxim 1 CPU (se poate specifica si --cpus=0.5)
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker container run -dt --name constraint01 --cpus=1.5 busybox sh'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>--cpuset-cpus</b> {"=>"} liminteaza CPU si nuclee (core) pe care un container le poate utiliza;
                            <br/>
                            Se poate specifica ca o lista separata prin virgula sau interval de CPU separate prin cratima;
                            <br/>
                            primul CPU este numerotat cu 0, al doilea CPU este numeroatat cu 1;
                            <br/>
                            exemple:
                            <ul>
                                <li>
                                    0-3 inseamna ca un container poate folosi CPU-ul 1,2,3 si 4
                                </li>
                                <li>
                                    1,3 insemana ca un container poate folosi CPU-ul 2 si 4
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker container run -dt --name constraint02 --cpuset-cpus=0,1 busybox sh'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Rezervare si limitare
                    <ul>
                        <li>
                            Limit (hard limit){"=>"} impune o limita superioasa a cantitatii de memorie care poate fi utilizata de un container Docker.
                            <br/>
                            Se foloseste optiunea: <b>--memory</b> sau <b>-m</b>
                        </li>
                        <li>
                            Rezervarile (soft limit) {"=>"} sunt mai putin rigide.
                            <br/>
                            Cand sistemul epuizeaza memoria, rezervarea incerca sa aduca consumul de memorie al containerului egala sau sub limita de rezervare.
                            <br/>
                            Se foloseste optiunea: <b>--memory-reservation</b>
                        </li>
                    </ul>

                    <hr/>

                    <b>4. DTR - Imutabile Tag</b>
                    <br/>
                    In mod implicit, utilizatorii cu drept de scriere si scriere pot suprascrie tag-uri.
                    <br/>
                    Pentru a preveni ca un tag sa fie suprascris, se poate configura ca un repository sa fie imutabil.

                    <hr/>
                    <b>5. DTR Cache</b>
                    <br/>

                    Pentru a reduce timpul de pull la o imagine, se poate implementa cache DTR mai aproape de utilizatori din punct de vedere geografic.
                    <br/>
                    Cache-urile sunt transparente pentru utilizator (utilizatorii facand push si pull de pe andresa URL a DTR-ului).
                    DTR verifica daca utilizatorii sunt autorizati pentru a face pull la imagine si face redirect la cerere catre cache.

                    <br/>
                    <b>UCP agent</b> nu este necesar sa fie instalat pe nodul pe care este <b>DTR cache</b>.

                    <hr/>
                    <b>6. DTR Garbage Collection</b>
                    <br/>
                    Se poate configura DTR (Docker Trusted Registry) sa stearga in mod automat image layere nefolosite, salvand spatiu pe disc.
                    Acest proces se numeste <i>garbage collection</i>

                    <hr/>
                    <b>7. DTR High Availability</b>
                    <br/>

                    DTR este proiectat sa se scaleze orizontal pe masura ce creste utilizarea.
                    Se pot adauga noi replici pentru a face DTM scalabil, la cerere, pentru o disponibilitate ridicata.

                    <br/>
                    Toate replicile DTR ruleaza acelasi set de servicii, iar modificarile configuratiei sunt propagate automat replicilor.
                    <br/>
                    Daca exista o singura replica DTR, se poate folosi local filesystem pentru a stoca imaginile Docker.
                    <br/>
                    Daca exista mai multe replicit DTR, pentru disponibilitate ridicata, este nevoie ca toate replicile sa foloseasca acelasi backend de stocare.

                    <hr/>
                    Pentru a avea High Availability (HA) / Disponibilitate ridicata pe UCP si DTR, este nevoie de minim:
                    <ul>
                        <li>3 noduri dedicate pentru a installa UCP cu HA</li>
                        <li>3 noduri dedicate pentru a installa DTR cu HA</li>
                    </ul>
                    Se poate monitoriza statusul UCP folosind:
                    <ul>
                        <li>UI web</li>
                        <li>
                            CLI
                        </li>
                        <li>
                            /_ping (pentru monitorizare automata)
                        </li>
                    </ul>

                    <hr/>
                    <b>8. Tipuri de orchestratori in UCP</b>
                    <br/>
                    UPC suporta urmatorii orchestratori:
                    <ul>
                        <li>Swarm</li>
                        <li>Kubernetes</li>
                    </ul>
                    Cand se adauga un nod in cluster, workload-urile nodului sunt gestionati in mod implicit de un orchestrator: fie Docker Swarm, fie Kubernates.
                    <br/>
                    Cand se instaleaza Docker Enterprise, noile noduri sunt gestionate de Docker Swarm, dar acest lucru se poate schimba la Kubernetes din setari:
                    <b>Admin Settings {'->'} Orchestration  {'->'}  Scheduler  {'->'}  Set Orchestrator Type for new nodes {"->"} [x] Swarm / [-] Kubernetes</b>

                    <br/>
                    <br/>
                    Se poate schimba orchestratorul curent pentru orice nod care este intr-un cluster. Tipul de orchestrator:
                    <ul>
                        <li>Kubernetes</li>
                        <li>Swarm</li>
                        <li>Mixed {"=>"} permite workload-uri sa fie programate de Kubernetes si Swarm pe acelasi nod
                            <br/>
                            Nu este recomandat pentru mediul de productie
                        </li>
                    </ul>
                    Cand se schimba tipyl de orchestrator pentru un node, workload-urile existente sunt evacuate/evicted, si nu sunt migrate catre noul orchestrator in mod automat.

                    <br/>
                    Pentru a schimba tipul de orchestrator de la Swarm la Kubernestes:
                    <SyntaxHighlighter>
                        {'docker node update --label-add com.docker.ucp.orchestrator.kuberneste=true [node-id]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9.  DTR - Storage Driver</b>
                    <br/>

                    In mod implicit, DTR stocheaza imaginile pe sistemul de fisiere (filesystem) la nodului pe care ruleaza, dar poate fi configurat sa se utilizeze un  backend centralizat de stocare.
                    <br/>
                    Se poate configura DTR sa foloseasca un backend extern de stocare, pentru a imbunatatii performanta sau disponibilitatea (high availability)


                    <hr/>
                    <b>10. Storage System suportate</b>
                    <br/>
                    Cateva din sistemele suportate in DTR:
                    <ul>
                        <li>
                            Local
                            <ul>
                                <li>NFS</li>
                                <li>Bind Mount</li>
                                <li>Volume</li>
                            </ul>
                        </li>
                        <li>
                            Cloud Storage Provider:
                            <ul>
                                <li>AWS S3</li>
                                <li>Azure</li>
                                <li>Cloud Cloud</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>11. Webhooks (DTR)</b>
                    <br/>
                    <br/>

                    DTR poate fi configurat sa posteze notifcari de evenimente la o adresa URL webhook (aleasa).
                    <br/>
                    De exemplu, dupa ce s-a scanat o imagine (DTR), se va declansa webhook pentru teste unitare (Jenkins).

                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips4DockerContent;