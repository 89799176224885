import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class AdmissionK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-admission", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Admission</b>

                    <br/>
                    <br/>
                    Uneori e nevoie de anumite restrictii, de exemplu:
                    <ul>
                        <li>
                            sa se permita crearea de pod-uri DOAR din imagini care sunt doar in anumite registre
                        </li>
                        <li>
                            sa NU se permita runAs ca root user
                        </li>
                        <li>
                            sa se permita DOAR anumite capabilitati
                        </li>
                        <li>
                            pod-urile sa abia INTOTDEAUNA label-uri
                        </li>
                    </ul>

                    Admission Controllers:
                    <ul>
                        <li>
                            AlwaysPullImages
                        </li>
                        <li>
                            DefaultStorageClass
                        </li>
                        <li>
                            EventRateLimit
                        </li>
                        <li>
                            NamespaceAutoProvision (nu este activat in mod implicit; creaza un namespace daca nu exista)
                        </li>
                        <li>
                            NamespaceExists (va crapa de exemplu se vrea crearea unui pod intr-un anumit namespace care nu exista)
                        </li>
                    </ul>

                    Vizualizare Admission Controllere active:
                    <SyntaxHighlighter>
                        {'kube-apiserver -h | grep enable-admission-plugins'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter>
                        {'kubectl exec kube-apiserver-controlplane -n kube-system -- kube-apiserver -h | grep enable-admission-plugins'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter>
                        {'kubectl exec kube-apiserver-minikube -n kube-system -- kube-apiserver -h | grep enable-admission-plugins'}
                    </SyntaxHighlighter>

                    sau
                    <br/>
                    Verificare <b>enable-admission-plugins</b> in <b>/etc/kubernetes/manifests/kube-apiserver.yaml</b>
                    <br/>
                    <SyntaxHighlighter>
                        {'ps -ef | grep kube-apiserver | grep admission-plugins'}
                    </SyntaxHighlighter>
                    sau

                    <hr/>

                    Pentru a activa un plugin trebuie modificat in (prin intermdiul flag-ului <b>enable-admission-plugins</b>):
                    <ul>
                        <li>
                            (daca ruleaza prin intermediul unui serviciu)
                            <br/>
                            kube-apiserver.service
                        </li>
                        <li>
                            (daca ruleaza pe un pod)
                            <br/>
                            /etc/kubernetes/manifests/kube-apiserver.yaml

                            <SyntaxHighlighter>
                                {' - command:\n' +
                                    '    - kube-apiserver\n' +
                                    '    - --advertise-address=192.168.1.10\n' +
                                    '    - --allow-privileged=true\n' +
                                    '    - --authorization-mode=Node,RBAC\n' +
                                    '    - --client-ca-file=/etc/kubernetes/pki/ca.crt\n' +
                                    '    - --enable-admission-plugins=NodeRestriction\n'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Pentru a dezactiva un plugin se foloseste flag-ul <b>disable-admission-plugins</b>

                    <hr/>
                    <b>Tipuri de Adminission Controller</b>
                    <br/>
                    <br/>
                    Tipuri:
                    <ul>
                        <li>validare/validating:
                            <br/>
                            controlerii de validare pot doar valida cererea/solicitare
                        </li>
                        <li>modificare/mutating:
                            <br/>
                            controlerii mutanți pot modifica o solicitare înainte de a le transmite mai departe
                            <br/>
                            (ex: NameSpaceAutoProvision, pentru ca creeaza un namespace daca nu exista)
                        </li>
                    </ul>
                    Ordinea in care se executa:
                    <ul>
                        <li>
                            mutating
                        </li>
                        <li>
                            validating
                        </li>
                    </ul>
                    Un punct important despre aceste tipuri este ordinea în care serverul API le execută:
                    controlorii mutanți vin pe primul loc, apoi controlorii de validare.
                    Acest lucru are sens, deoarece validarea va avea loc numai odată ce avem cererea finală, eventual modificată de oricare dintre controlorii mutanți.
                    <br/>

                    Se pot creea noi admission controller prin:
                    <ul>
                        <li>
                            MutatingAdmission Webhook
                        </li>
                        <li>
                            ValidatingAdmission Webhook
                        </li>
                    </ul>

                    Pasi:
                    <ul>
                        <li>Deploy Webhook Server (poate fi scris in Go sau Java, de exemplu)

                            <br/>
                            Acesta aplicatie va primi un <i>request</i> in format JSON:
                            <SyntaxHighlighter>
                                {'{\n' +
                                    '  "kind": "AdmissionReview",\n' +
                                    '  "apiVersion": "admission.k8s.io/v1",\n' +
                                    '  "request": {\n' +
                                    '    "uid": "c46a6607-129d-425b-af2f-c6f87a0756da",\n' +
                                    '    "kind": {\n' +
                                    '      "group": "apps",\n' +
                                    '      "version": "v1",\n' +
                                    '      "kind": "Deployment"\n' +
                                    '    },\n' +
                                    '    "resource": {\n' +
                                    '      "group": "apps",\n' +
                                    '      "version": "v1",\n' +
                                    '      "resource": "deployments"\n' +
                                    '    },\n' +
                                    '    "requestKind": {\n' +
                                    '      "group": "apps",\n' +
                                    '      "version": "v1",\n' +
                                    '      "kind": "Deployment"\n' +
                                    '    },\n' +
                                    '    "requestResource": {\n' +
                                    '      "group": "apps",\n' +
                                    '      "version": "v1",\n' +
                                    '      "resource": "deployments"\n' +
                                    '    },\n' +
                                    '    "name": "test-deployment",\n' +
                                    '    "namespace": "test-namespace",\n' +
                                    '    "operation": "CREATE",\n' +
                                    '    "object": {\n' +
                                    '      "kind": "Deployment",\n' +
                                    '      ... deployment fields omitted\n' +
                                    '    },\n' +
                                    '    "oldObject": null,\n' +
                                    '    "dryRun": false,\n' +
                                    '    "options": {\n' +
                                    '      "kind": "CreateOptions",\n' +
                                    '      "apiVersion": "meta.k8s.io/v1"\n' +
                                    '    }\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            si va raspunde cu un obiect <i>response</i>:
                            <SyntaxHighlighter>
                                {'{\n' +
                                    '  "apiVersion": "admission.k8s.io/v1",\n' +
                                    '  "kind": "AdmissionReview",\n' +
                                    '  "response": {\n' +
                                    '    "uid": "c46a6607-129d-425b-af2f-c6f87a0756da",\n' +
                                    '    "allowed": true,\n' +
                                    '    "patchType": "JSONPatch",\n' +
                                    '    "patch": "W3sib3A ... Base64 patch data omitted"\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>
                                    uid - trebuie să corespundă cu câmpul corespunzător prezent în câmpul de request primit
                                </li>
                                <li>
                                    allowed - true, inseamna ca procesarea apelului API poate trece la pasul urmator
                                </li>
                                <li>
                                    patchType - valabil doar pentru controlerii de admitere mutanti
                                </li>
                                <li>
                                    patch - corectii de aplicat in obiectul primit; aceste corectii sunt codificate in Base64
                                    <br/>
                                    ex:
                                    <SyntaxHighlighter>{'[\n' +
                                        '  {\n' +
                                        '    "op": "add",\n' +
                                        '    "path": "/spec/template/spec/volumes",\n' +
                                        '    "value": [{\n' +
                                        '      "name": "migration-data",\n' +
                                        '      "emptyDir": {}\n' +
                                        '    }]\n' +
                                        '  }\n' +
                                        ']'}</SyntaxHighlighter>
                                    unde:
                                    <ul>
                                        <li>op - operatie (add, remove, replace, move, copy, test)</li>
                                    </ul>
                                </li>
                            </ul>

                            <hr/>
                            vezi: <a target={"_blank"} href={"https://www.baeldung.com/java-kubernetes-admission-controller"}>Creating a Kubernetes Admission Controller in Java</a>
                        </li>
                        <li>
                            Configurare Admission Webhook (ValidatingWebhookConfiguration | MutatingWebhookConfiguration)
                            <SyntaxHighlighter>
                                {'apiVersion: admissionregistration.k8s.io/v1\n' +
                                    'kind: ValidatingWebhookConfiguration\n' +
                                    'metadata:\n' +
                                    '  name: "pod-policy.example.com"\n' +
                                    'webhooks:\n' +
                                    '- name: "pod-policy.example.com"\n' +
                                    '  clientConfig:\n' +
                                    '    url: "https://external-server.example.com # daca este pusa extern, unde este deployata aplicatia\n' +
                                    '    service: # daca aplicatia este in propriul cluster\n' +
                                    '      namespace: "webhook-namespace"\n' +
                                    '      name: "webhook-service"\n' +
                                    '    caBundle:"Ci53534resfs3"\n' +
                                    '  rules:\n' +
                                    '    - apiGroup: [""]\n' +
                                    '      apiVersions: ["v1"]\n' +
                                    '      operations: ["CREATE"]\n' +
                                    '      resources: ["pods]\n' +
                                    '      scope: "Namespaced"\n'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Poate fi o aplicatie Spring Boot
                    <SyntaxHighlighter>
                        {
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            'import org.springframework.boot.context.properties.EnableConfigurationProperties;\n' +
                            '\n' +
                            'import ibrid.kubernetes.admission.config.AdmissionControllerProperties;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            '@EnableConfigurationProperties(AdmissionControllerProperties.class)\n' +
                            'public class Application {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        SpringApplication.run(Application.class, args);\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionControllerProperties:
                    <SyntaxHighlighter>
                        {'package ibrid.kubernetes.admission.config;\n' +
                            '\n' +
                            'import org.springframework.boot.context.properties.ConfigurationProperties;\n' +
                            '\n' +
                            'import lombok.Data;\n' +
                            '\n' +
                            '@ConfigurationProperties(prefix = "admission-controller")\n' +
                            '@Data\n' +
                            'public class AdmissionControllerProperties {\n' +
                            '\n' +
                            '    private boolean disabled;\n' +
                            '    private String annotation = "com.baeldung/wait-for-it";\n' +
                            '    private String waitForItImage = "willwill/wait-for-it";\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionReviewController:
                    <SyntaxHighlighter>
                        {'package ibrid.kubernetes.admission.controller;\n' +
                            '\n' +
                            'import org.springframework.web.bind.annotation.PostMapping;\n' +
                            'import org.springframework.web.bind.annotation.RequestBody;\n' +
                            'import org.springframework.web.bind.annotation.RestController;\n' +
                            '\n' +
                            'import ibrid.kubernetes.admission.dto.AdmissionReviewResponse;\n' +
                            'import ibrid.kubernetes.admission.service.AdmissionService;\n' +
                            'import com.fasterxml.jackson.databind.node.ObjectNode;\n' +
                            '\n' +
                            'import lombok.RequiredArgsConstructor;\n' +
                            'import reactor.core.publisher.Mono;\n' +
                            '\n' +
                            '@RestController\n' +
                            '@RequiredArgsConstructor\n' +
                            'public class AdmissionReviewController {\n' +
                            '\n' +
                            '    private final AdmissionService admissionService;\n' +
                            '\n' +
                            '    @PostMapping(path = "/mutate")\n' +
                            '    public Mono<AdmissionReviewResponse> processAdmissionReviewRequest(@RequestBody Mono<ObjectNode> request) {\n' +
                            '        return request.map((body) -> admissionService.processAdmission(body));\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionReviewData:
                    <SyntaxHighlighter>
                        {'package ibrid.kubernetes.admission.dto;\n' +
                            '\n' +
                            'import com.fasterxml.jackson.annotation.JsonInclude;\n' +
                            'import com.fasterxml.jackson.annotation.JsonInclude.Include;\n' +
                            '\n' +
                            'import lombok.Builder;\n' +
                            'import lombok.Data;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Result sent to the API server after reviewing and, possibly\n' +
                            ' * modifying the incoming request\n' +
                            ' */\n' +
                            '@Builder\n' +
                            '@Data\n' +
                            'public class AdmissionReviewData {\n' +
                            '\n' +
                            '    final String uid;\n' +
                            '    final boolean allowed;\n' +
                            '\n' +
                            '    @JsonInclude(Include.NON_NULL)\n' +
                            '    final String patchType;\n' +
                            '\n' +
                            '    @JsonInclude(Include.NON_NULL)\n' +
                            '    final String patch;\n' +
                            '\n' +
                            '    @JsonInclude(Include.NON_NULL)\n' +
                            '    final AdmissionStatus status;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionReviewException:
                    <SyntaxHighlighter>
                        {'package ibrid.kubernetes.admission.dto;\n' +
                            '\n' +
                            'public class AdmissionReviewException extends RuntimeException {\n' +
                            '\n' +
                            '    private static final long serialVersionUID = 1L;\n' +
                            '    private final int code;\n' +
                            '\n' +
                            '    public AdmissionReviewException(int code, String message) {\n' +
                            '        super(message);\n' +
                            '        this.code = code;\n' +
                            '    }\n' +
                            '\n' +
                            '    public AdmissionReviewException(String message) {\n' +
                            '        super(message);\n' +
                            '        this.code = 400;\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    public int getCode() {\n' +
                            '        return code;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionReviewResponse:
                    <SyntaxHighlighter>
                        {'/**\n' +
                            ' * \n' +
                            ' */\n' +
                            'package ibrid.kubernetes.admission.dto;\n' +
                            '\n' +
                            'import lombok.Builder;\n' +
                            'import lombok.Builder.Default;\n' +
                            'import lombok.Data;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Response "envelope" sent back to the API Server\n' +
                            ' */\n' +
                            '@Builder\n' +
                            '@Data\n' +
                            'public class AdmissionReviewResponse {\n' +
                            '\n' +
                            '    @Default\n' +
                            '    final String apiVersion = "admission.k8s.io/v1";\n' +
                            '\n' +
                            '    @Default\n' +
                            '    final String kind = "AdmissionReview";\n' +
                            '\n' +
                            '    final AdmissionReviewData response;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionStatus:
                    <SyntaxHighlighter>
                        {'package ibrid.kubernetes.admission.dto;\n' +
                            '\n' +
                            'import lombok.Builder;\n' +
                            'import lombok.Data;\n' +
                            '\n' +
                            '@Builder\n' +
                            '@Data\n' +
                            'public class AdmissionStatus {\n' +
                            '\n' +
                            '    int code;\n' +
                            '    String message;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AdmissionService:
                    <SyntaxHighlighter>
                        {'/**\n' +
                            ' * \n' +
                            ' */\n' +
                            'package ibrid.kubernetes.admission.service;\n' +
                            '\n' +
                            'import java.util.Base64;\n' +
                            'import java.util.UUID;\n' +
                            '\n' +
                            'import org.springframework.stereotype.Component;\n' +
                            '\n' +
                            'import ibrid.kubernetes.admission.config.AdmissionControllerProperties;\n' +
                            'import ibrid.kubernetes.admission.dto.AdmissionReviewData;\n' +
                            'import ibrid.kubernetes.admission.dto.AdmissionReviewException;\n' +
                            'import ibrid.kubernetes.admission.dto.AdmissionReviewResponse;\n' +
                            'import ibrid.kubernetes.admission.dto.AdmissionStatus;\n' +
                            'import com.fasterxml.jackson.databind.JsonNode;\n' +
                            'import com.fasterxml.jackson.databind.ObjectMapper;\n' +
                            'import com.fasterxml.jackson.databind.node.ArrayNode;\n' +
                            'import com.fasterxml.jackson.databind.node.ObjectNode;\n' +
                            '\n' +
                            'import lombok.RequiredArgsConstructor;\n' +
                            'import lombok.extern.slf4j.Slf4j;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Process an incoming admission request and add the "wait-for-it" init container\n' +
                            ' * if there\'s an appropriate annotation\n' +
                            ' */\n' +
                            '@Component\n' +
                            '@RequiredArgsConstructor\n' +
                            '@Slf4j\n' +
                            'public class AdmissionService {\n' +
                            '\n' +
                            '    private final AdmissionControllerProperties admissionControllerProperties;\n' +
                            '    private final ObjectMapper om;\n' +
                            '\n' +
                            '    public AdmissionReviewResponse processAdmission(ObjectNode body) {\n' +
                            '\n' +
                            '        String uid = body.path("request")\n' +
                            '          .required("uid")\n' +
                            '          .asText();\n' +
                            '\n' +
                            '        log.info("[I42] processAdmission: uid={}",uid);\n' +
                            '        if ( log.isDebugEnabled()) {\n' +
                            '            log.debug("processAdmission: body={}", body.toPrettyString());\n' +
                            '        }\n' +
                            '\n' +
                            '        // Get request annotations\n' +
                            '        JsonNode annotations = body.path("request")\n' +
                            '          .path("object")\n' +
                            '          .path("metadata")\n' +
                            '          .path("annotations");\n' +
                            '        log.info("processAdmision: annotations={}", annotations.toString());\n' +
                            '\n' +
                            '        AdmissionReviewData data;\n' +
                            '        try {\n' +
                            '            if (admissionControllerProperties.isDisabled()) {\n' +
                            '                log.info("[I58] \'disabled\' option in effect. No changes to current request will be made");\n' +
                            '                data = createSimpleAllowedReview(body);\n' +
                            '            } else if (annotations.isMissingNode()) {\n' +
                            '                log.info("[I68] No annotations found in request. No changes will be made");\n' +
                            '                data = createSimpleAllowedReview(body);\n' +
                            '            } else {\n' +
                            '                data = processAnnotations(body, annotations);\n' +
                            '            }\n' +
                            '\n' +
                            '            log.info("[I65] Review result: isAllowed=" + data.isAllowed());\n' +
                            '            log.info("[I64] AdmissionReviewData= {}", data);\n' +
                            '\n' +
                            '            return AdmissionReviewResponse.builder()\n' +
                            '              .apiVersion(body.required("apiVersion").asText())\n' +
                            '              .kind(body.required("kind").asText())\n' +
                            '              .response(data)\n' +
                            '              .build();\n' +
                            '        } catch (AdmissionReviewException ex) {\n' +
                            '            log.error("[E72] Error processing AdmissionRequest: code={}, message={}", ex.getCode(), ex.getMessage());\n' +
                            '            data = createRejectedAdmissionReview(body, ex.getCode(), ex.getMessage());\n' +
                            '\n' +
                            '            return AdmissionReviewResponse.builder()\n' +
                            '              .apiVersion(body.required("apiVersion").asText())\n' +
                            '              .kind(body.required("kind").asText())\n' +
                            '              .response(data)\n' +
                            '              .build();\n' +
                            '        } catch (Exception ex) {\n' +
                            '            log.error("[E72] Unable to process AdmissionRequest: " + ex.getMessage(), ex);\n' +
                            '            data = createRejectedAdmissionReview(body, 500, ex.getMessage());\n' +
                            '            return AdmissionReviewResponse.builder()\n' +
                            '              .apiVersion(body.required("apiVersion").asText())\n' +
                            '              .kind(body.required("kind").asText())\n' +
                            '              .response(data)\n' +
                            '              .build();\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * @param body\n' +
                            '     * @return\n' +
                            '     */\n' +
                            '    protected AdmissionReviewData createSimpleAllowedReview(ObjectNode body) {\n' +
                            '        AdmissionReviewData data;\n' +
                            '        String requestId = body.path("request")\n' +
                            '          .required("uid")\n' +
                            '          .asText();\n' +
                            '\n' +
                            '        data = AdmissionReviewData.builder()\n' +
                            '          .allowed(true)\n' +
                            '          .uid(requestId)\n' +
                            '          .build();\n' +
                            '\n' +
                            '        return data;\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * @param body\n' +
                            '     * @return\n' +
                            '     */\n' +
                            '    protected AdmissionReviewData createRejectedAdmissionReview(ObjectNode body, int code, String message) {\n' +
                            '        AdmissionReviewData data;\n' +
                            '        String requestId = body.path("request")\n' +
                            '          .required("uid")\n' +
                            '          .asText();\n' +
                            '\n' +
                            '        AdmissionStatus status = AdmissionStatus.builder()\n' +
                            '          .code(code)\n' +
                            '          .message(message)\n' +
                            '          .build();\n' +
                            '\n' +
                            '        data = AdmissionReviewData.builder()\n' +
                            '          .allowed(false)\n' +
                            '          .uid(requestId)\n' +
                            '          .status(status)\n' +
                            '          .build();\n' +
                            '\n' +
                            '        return data;\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Processa anotações incluídas no deployment\n' +
                            '     * @param annotations\n' +
                            '     * @return\n' +
                            '     */\n' +
                            '    protected AdmissionReviewData processAnnotations(ObjectNode body, JsonNode annotations) {\n' +
                            '\n' +
                            '        if (annotations.path(admissionControllerProperties.getAnnotation())\n' +
                            '            .isMissingNode()) {\n' +
                            '            log.info("[I78] processAnnotations: Annotation {} not found in deployment deployment.", admissionControllerProperties.getAnnotation());\n' +
                            '            return createSimpleAllowedReview(body);\n' +
                            '        }\n' +
                            '        else {\n' +
                            '            log.info("[I163] annotation found: {}", annotations.path(admissionControllerProperties.getAnnotation()));\n' +
                            '        }\n' +
                            '\n' +
                            '        // Get wait-for-it arguments from the annotation\n' +
                            '        String waitForArgs = annotations.path(admissionControllerProperties.getAnnotation())\n' +
                            '          .asText();\n' +
                            '\n' +
                            '        log.info("[I169] waitForArgs={}", waitForArgs);\n' +
                            '        // Create a PATCH object\n' +
                            '        String patch = injectInitContainer(body, waitForArgs);\n' +
                            '\n' +
                            '        return AdmissionReviewData.builder()\n' +
                            '          .allowed(true)\n' +
                            '          .uid(body.path("request")\n' +
                            '            .required("uid")\n' +
                            '            .asText())\n' +
                            '          .patch(Base64.getEncoder()\n' +
                            '            .encodeToString(patch.getBytes()))\n' +
                            '          .patchType("JSONPatch")\n' +
                            '          .build();\n' +
                            '\n' +
                            '    }\n' +
                            '    \n' +
                            '    /**\n' +
                            '     * Creates the JSONPatch to be included in the admission response \n' +
                            '     * @param body\n' +
                            '     * @param waitForArgs \n' +
                            '     * @return JSONPatch string\n' +
                            '     */\n' +
                            '    protected String injectInitContainer(ObjectNode body, String waitForArgs) {\n' +
                            '\n' +
                            '        // Recover original init containers from the request\n' +
                            '        JsonNode originalSpec = body.path("request")\n' +
                            '          .path("object")\n' +
                            '          .path("spec")\n' +
                            '          .path("template")\n' +
                            '          .path("spec")\n' +
                            '          .require();\n' +
                            '\n' +
                            '        JsonNode maybeInitContainers = originalSpec.path("initContainers");\n' +
                            '        ArrayNode initContainers = \n' +
                            '        maybeInitContainers.isMissingNode()?\n' +
                            '          om.createArrayNode():(ArrayNode) maybeInitContainers;\n' +
                            '\n' +
                            '        // Create the patch array\n' +
                            '        ArrayNode patchArray = om.createArrayNode();\n' +
                            '        ObjectNode addNode = patchArray.addObject();\n' +
                            '\n' +
                            '        addNode.put("op", "add");\n' +
                            '        addNode.put("path", "/spec/template/spec/initContainers");\n' +
                            '        ArrayNode values = addNode.putArray("value");\n' +
                            '\n' +
                            '        // Preserve original init containers\n' +
                            '        values.addAll(initContainers);\n' +
                            '\n' +
                            '        // append the "wait-for-it" container\n' +
                            '        ObjectNode wfi = values.addObject();\n' +
                            '        wfi.put("name", "wait-for-it-" + UUID.randomUUID()); // Create an unique name, JIC\n' +
                            '        wfi.put("image", admissionControllerProperties.getWaitForItImage());\n' +
                            '\n' +
                            '        ArrayNode args = wfi.putArray("args");\n' +
                            '        for (String s : waitForArgs.split("\\\\s")) {\n' +
                            '            args.add(s);\n' +
                            '        }\n' +
                            '\n' +
                            '        return patchArray.toString();\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Execitii</b>
                    <br/>
                    <br/>

                    Creare namespace:
                    <SyntaxHighlighter>
                        {'kubectl create namespace webhook-demo'}
                    </SyntaxHighlighter>

                    Afisare namespaces:
                    <SyntaxHighlighter>
                        {'kubectl get ns'}
                    </SyntaxHighlighter>

                    Creare secret TLS:
                    <SyntaxHighlighter>
                        {'kubectl -n webhook-demo create secret tls webhook-server-tls --cert "/root/keys/webhook-server-tls.crt" --key "/root/keys/webhook-server-tls.key"'}
                    </SyntaxHighlighter>

                    Aplicare deployment:
                    <SyntaxHighlighter>
                        {'kubectl apply -f webhook-deployment.yaml'}
                    </SyntaxHighlighter>

                    Aplicare service:
                    <SyntaxHighlighter>
                        {'kubectl apply -f webhook-service.yaml'}
                    </SyntaxHighlighter>

                    Aplicare webhook:
                    <SyntaxHighlighter>
                        {'kubectl apply -f webhook-configuration.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get pod pod-with-defaults -o yaml'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://kubernetes.io/docs/reference/access-authn-authz/admission-controllers/"}>Admission Controllers Reference</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AdmissionK8sContent;