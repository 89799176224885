import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CommentsJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-comments", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un comentariu este un text ignorat de interpretor. Acesta poate fi:
                    <ul>
                        <li>pe un singur rand: se marcheza incepand cu <b>//</b> si pana la sfarsitul liniei; (scurtatura: CTRL+/ pe majoritatea editoarelor)</li>
                        <li>pe mai multe randuri: se marcheaza incapand cu <b>/*</b> si pana la <b>*/</b> </li>
                    </ul>

                    Exista intrumente pentru a genera documentatie din comentarii: <b>JSDoc</b>.
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CommentsJavaScriptContent;