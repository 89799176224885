import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InputFunctionPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-input-function", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Python: functia input()</b>

                    <br/>
                    <br/>

                    Functia <b>input()</b> permite citirea de la tastatura. Rezultatul returnat este de tip String.
                    De multe ori, avem nevoie sa convertim rezultatul dat de functia input() catre alt tip, de exemplu float sau int.
                    Pentru acesta putem folosi urmatoarele functii:
                    <ul>
                        <li>
                            functia <b>float()</b>
                        </li>
                        <li>
                            functia <b>int()</b>
                        </li>
                    </ul>

                    <hr/>
                    <i>Exemplul 1:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("Zi-mi numele tau, amestec de faina cu apa...")\n' +
                        'covrig = input()\n' +
                        'print("Covrig=", covrig)'}
                    </SyntaxHighlighter>

                    Rezultatul, dupa ce se introduce de la tastatura <i>matei</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Zi-mi numele tau, amestec de faina cu apa...\n' +
                        'matei\n' +
                        'Covrig= matei'}
                    </SyntaxHighlighter>

                    <hr/>
                    Functia input() <b>permite ca argument si un text de tip String, evitand astfel folosirea functiei print()</b>.
                    <br/>
                    <br/>

                    <i>Exemplul 2:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'covrig = input("Zi-mi numele tau, amestec de faina cu apa...")\n' +
                        'print("Covrig=", covrig)'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Zi-mi numele tau, amestec de faina cu apa...matei\n' +
                        'Covrig= matei'}
                    </SyntaxHighlighter>

                    <hr/>
                    <i>Exemplul 3:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ani = float(input("Cati ani ai? "))\n' +
                        'print("Deci",ani,"ani")'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Cati ani ai? 30\n' +
                        'Deci 30.0 ani'}
                    </SyntaxHighlighter>

                    <hr/>
                    <i>Exemplul 4:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'timp_ora = int(input("Ora de inceput (ora): "))\n' +
                        'timp_minute = int(input("Minutele de inceput (minute): "))\n' +
                        'durata = int(input("Durata (minute): "))\n' +
                        '\n' +
                        'finalizare = timp_ora*60+timp_minute + durata\n' +
                        'print(int((finalizare/60)%24),":",finalizare%60,sep="")'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Ora de inceput (ora): 23\n' +
                        'Minutele de inceput (minute): 58\n' +
                        'Durata (minute): 642\n' +
                        '10:40'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InputFunctionPythonContent;