import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class BuildArchetypeMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-build-archetype", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare Archetype personalizat</b>:
                    <br/>
                    <br/>

                    Executam (alegand <i>maven-archetype-quickstart</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate'}
                    </SyntaxHighlighter>

                    Putem modifica noul proiect, adauga de exemplu clase noi.
                    <br/>
                    Apoi, pe baza proiectului (pe care il putem vedea ca un prototip),  putem creea un <i>arhietip</i> din proiect:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:create-from-project'}
                    </SyntaxHighlighter>

                    In <i>\target\generated-source\</i> se genereaza folder-ul <b>archetype</b>, care va contine un fisier <b>pom.xml</b>.
                    <br/>
                    Mergem in directorul <i>\target\generated-source\archetype</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'cd \\target\\generated-source\\archetype'}
                    </SyntaxHighlighter>
                    si executam comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn install'}
                    </SyntaxHighlighter>
                    Astfel, nou arhetip ajunge in repository-ul local (<b>.m2</b>).

                    <hr/>

                    <b>2. Folosire Archetype personalizat</b>:
                    <br/>
                    <br/>

                    La executia urmatoarei comenzi, se va alege numarul care corespunde cu arhetipul creat mai devreme:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BuildArchetypeMavenContent;