import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ShowGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-show", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git show</b>
                    <br/>
                    <br/>

                    Pentru a afisa detalii legate de un comit, putem folosi comanda: <b>git show <i>hash</i></b>, unde <i>hash</i> este identificatorul unic al comitului:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git show 72f509'}
                    </SyntaxHighlighter>

                    De unde am luat <i>72f509</i>? Din rularea comenzii <b>git log</b>!
                    <br/>
                    <br/>

                    Rezultatul afisat:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'commit 72f50991845c23a8196dc4f567bc3f191c1cfa03 (HEAD -> master)\n' +
                        'Author: letyournailsgrow <buzdugan.iulian@gmail.com>\n' +
                        'Date:   Thu Mar 24 09:21:03 2022 +0200\n' +
                        '\n' +
                        '    Al doilea commit\n' +
                        '\n' +
                        'diff --git a/citeste.txt b/citeste.txt\n' +
                        'index e69de29..b9afc42 100644\n' +
                        '--- a/citeste.txt\n' +
                        '+++ b/citeste.txt\n' +
                        '@@ -0,0 +1 @@\n' +
                        '+kj a fost aici\n' +
                        '\\ No newline at end of file'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ShowGitContent;