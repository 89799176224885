import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class StandardsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-standards", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Standarde suportate de Liferay:
                    <ul>
                        <li><a href={"https://jcp.org/en/jsr/detail?id=168"} target={"_blank"}> Portleturi 1.0</a> (<b>JSR-168</b>) și
                            <a href={"https://jcp.org/en/jsr/detail?id=286"} target={"_blank"}> Portleturi 2.0</a> (<b>JSR-286</b>).
                            <br/>
                            Liferay DXP poate rula orice portlet care urmează aceste două versiuni ale specificației.
                            <br/>
                            Liferay este implicat în viitoarea specificație Portlets 3.0.
                        </li>

                        <li>
                            <a href={"https://jcp.org/en/jsr/detail?id=168"} target={"_blank"}> <b>JSF</b> </a>  <b>(JSR-127, JSR-314, JSR-344)</b>
                            <br/>
                            Standardul Java pentru construirea de aplicații web bazate pe componente.
                            <br/>
                            Liferay contribuie activ la standardul și liderul specificației JSF-Portlet Bridge.
                        </li>
                        <li>
                            <b>JAX-WS</b> și <b>JAX-RS</b>: incorporate începând cu Liferay 7 ca instrument pentru a crea servicii web.
                        </li>
                        <li>
                            <b>OSGi r6</b> : Liferay suportă o gamă largă de standarde OSGi prin implementări proprii și integrează implementările de înaltă calitate ale proiectelor Apache Felix și Eclipse Equinox
                        </li>
                        <li>
                            <b>SAML</b> și <b>OAuth 1.1</b> : protocoale de securitate pentru SSO și conectarea la aplicații,
                        </li>
                        <li>
                            <b>EcmaScript 2015</b> : standardul JavaScript.
                            <br/>
                            Instrumentele Liferay oferă posibilitatea de a-l utiliza în toate browserele moderne datorită integrării <b>Babel JS</b>.
                        </li>
                        <li>
                            <b>WebDAV</b> (WebDAV înseamnă „Web-based Distributed Authoring and Versioning”. Este un set de extensii la protocolul HTTP care permite utilizatorilor să editeze și să gestioneze în colaborare fișierele de pe servere web la distanță)
                            <br/>
                            Orice folder Documents & Media poate fi montat oriunde este acceptat WebDAV, cum ar fi Windows Explorer sau clienți specifici WebDAV.
                        </li>
                        <li>
                            <b>Content Management Interoperability Services (CMIS)</b>:  Documentele și media Liferay se pot comporta ca o interfață pentru orice depozit de documente extern care acceptă acest standard.
                        </li>
                        <li>
                            <a href={"https://jcp.org/en/jsr/detail?id=170"} target={"_blank"}>  Java Content Repository </a> (JSR-170)
                            <br/>
                            Fișierele stocate în <a href={"https://help.liferay.com/hc/en-us/articles/360018176091-Document-Repository-Configuration-"} target={"_blank"}> depozitul intern al documentelor și mediilor Liferay pot fi configurate</a> pentru a fi stocate într-un depozit compatibil JSR-170, dacă se dorește.

                            <br/>
                            Stocarea fișierelor se poate face in mai multe moduri.
                            <br/>
                            Fiecare optiune este un <b>store</b> care poate fi configura prin intemediul fisierului <b>portal-ext.properties</b> prin starea proprietatii:
                            <br/>
                            <a href={"https://docs.liferay.com/dxp/portal/7.1-latest/propertiesdoc/portal.properties.html#Document%20Library%20Service"} target={"_blank"}>dl.store.impl= property.</a>
                            <hr/>
                            Store-ul implicit se numeste <b>Simple File Store</b>. Acesta stocheaza documentele si fisierele media pe un sistem de fisiere local sau montat.
                            <br/>
                            Calea implicita catre directorul radacina este:
                            <br/>
                            <b>[Liferay Home]/data/document_library</b>
                            Se poate specifica si alta cale din setarile sistemului:
                            <br/>
                            <b>Control Panel {"=>"} Configuration {"=>"} System Settings {"=>"} sectiune Platform {"=>"} File Storage </b>
                            <br/>
                            Apoi:
                            <br/>
                            <b>System Scope {"=>"} Simple File System Store {"=>"} Root Directory</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/standards-liferay-1.png'}/>
                            </div>
                            Se poate actualiza cu noua calea si apasa pe butonul <b>Update</b>.
                            <hr/>

                            Metode de stare ca documentelor si fisierelor media:
                            <ul>
                                <li>
                                    <b>Simple File System Store</b> : folosește sistemul de fișiere (local sau o partajare montată)
                                </li>
                                <li>
                                    <b>Advanced File System Store</b> : stocheaza fișierele în foldere în funcție de versiune, pentru o performanță mai rapidă și pentru a stoca mai multe fișiere.
                                </li>
                                <li>
                                    <b>S3 Store (Amazon Simple Storage)</b>: folosește soluția de stocare bazată pe cloud Amazon
                                </li>
                                <li>
                                    <b>CMIS Store (Content Management Interoperability Services): </b> se conectează la un server compatibil CMIS pentru a stoca fișiere
                                </li>
                                <li>
                                    <b>DBStore (Database Storage)</b>: stochează fișierele în baza de date. Dimensiunea fișierului DBStore (stocat ca blob) este de 1 gigaoctet; pentru a stoca fisiere mai mari se utilizeaza <b>Simple File System Store</b> sau <b>Advanced File System Store</b>
                                </li>
                            </ul>

                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018163311-Fundamentals"}>
                                    Fundamentals
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StandardsLiferayContent;