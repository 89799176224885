import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class EnumsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-enums", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Enums</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'enum Color{\n' +
                            '    Red,\n' +
                            '    Green,\n' +
                            '    Blue,\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'let color = Color::Red;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'enum DispenserItem {\n' +
                            '    Empty,\n' +
                            '    Ammo(u8),\n' +
                            '    Things(String, i32),\n' +
                            '    Place { x: i32, y: i32 },\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Un enum poate avea implementare:
                    <SyntaxHighlighter>
                        {'impl DispenserItem {\n' +
                            '    fn display(&self) {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'enum Option<T>{\n' +
                            '    Some(T),\n' +
                            '    None,\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Match</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {' let mut v: Option<i32> = None;\n' +
                            '    match v {\n' +
                            '        Some(x) => {\n' +
                            '            println!("{}", x);\n' +
                            '        }\n' +
                            '        None => {\n' +
                            '            println!("None");\n' +
                            '        },\n' +
                            '    }\n' +
                            '\n' +
                            '    let y = match v {\n' +
                            '        Some(x) => 1,\n' +
                            '        None=> 0\n' +
                            '    };'}
                    </SyntaxHighlighter>

                    Simplificat se poate scrie:
                    <SyntaxHighlighter>
                        {'let mut v = None'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'x = Some(5);\n' +
                            'x.is_some();\n' +
                            'x.is_none();\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EnumsRustContent;