import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";
import APIVersionDeprecationsK8sContent from "./APIVersionDeprecationsK8sContent";

class ServiceNetworkPolicyK8sContent extends BaseContentPage {

    constructor(props) {
        super(props, "kubernetes-service-netpol", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}


                <div className={"text-justify important"}>


                    <b>Info 1: Servicii</b>
                    <br/>
                    <br/>

                    Creare serviciu independent de pot sau deployment (ClusterIP):
                    <SyntaxHighlighter>
                        {'k create service clusterip nginx-service -tcp=80:80'}
                    </SyntaxHighlighter>

                    Creare serviciu pentru deployment-ul <i>my-deploy</i>
                    <SyntaxHighlighter>
                        {'k expose deploy/my-deploy --port=80 --target-port=80'}
                    </SyntaxHighlighter>

                    Creare serviciu o data cu crearea pod-ului:
                    <SyntaxHighlighter>
                        {'k run nginx --image=nginx --restart=Never --port=80 --expose'}
                    </SyntaxHighlighter>

                    Comanda <i>expose</i> si flag-ul <i>--expose</i> sunt shortcut-uri pentru crearea unui serviciu.

                    Exemplu 1:
                    <SyntaxHighlighter>
                        {' k create service clusterip nginx-service-cluster-ip --tcp=80:80 --dry-run=client -o yaml > nsci.yaml'}
                    </SyntaxHighlighter>
                    va genera:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  creationTimestamp: null\n' +
                            '  labels:\n' +
                            '    app: nginx-service-cluster-ip\n' +
                            '  name: nginx-service-cluster-ip\n' +
                            'spec:\n' +
                            '  ports:\n' +
                            '  - name: 80-80\n' +
                            '    port: 80\n' +
                            '    protocol: TCP\n' +
                            '    targetPort: 80\n' +
                            '  selector:\n' +
                            '    app: nginx-service-cluster-ip\n' +
                            '  type: ClusterIP\n' +
                            'status:\n' +
                            '  loadBalancer: {}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu2:
                    <SyntaxHighlighter>
                        {'k create deploy nginx --image=nginx'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'k expose deploy/nginx  --port=80 --target-port=80'}
                    </SyntaxHighlighter>

                    Va genera:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  creationTimestamp: "2024-01-10T14:35:22Z"\n' +
                            '  labels:\n' +
                            '    app: nginx\n' +
                            '  name: nginx\n' +
                            '  namespace: default\n' +
                            '  resourceVersion: "2166"\n' +
                            '  uid: 12930b9e-92cf-4768-8438-8d8b344703c0\n' +
                            'spec:\n' +
                            '  clusterIP: 10.111.138.44\n' +
                            '  clusterIPs:\n' +
                            '  - 10.111.138.44\n' +
                            '  internalTrafficPolicy: Cluster\n' +
                            '  ipFamilies:\n' +
                            '  - IPv4\n' +
                            '  ipFamilyPolicy: SingleStack\n' +
                            '  ports:\n' +
                            '  - port: 80\n' +
                            '    protocol: TCP\n' +
                            '    targetPort: 80\n' +
                            '  selector:\n' +
                            '    app: nginx\n' +
                            '  sessionAffinity: None\n' +
                            '  type: ClusterIP\n' +
                            'status:\n' +
                            '  loadBalancer: {}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu 3 :
                    <SyntaxHighlighter>
                        {'k run nginx2 --image=nginx --port=80 --expose --dry-run=client -o yaml > n.yaml'}
                    </SyntaxHighlighter>
                    va genera:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  creationTimestamp: null\n' +
                            '  name: nginx2\n' +
                            'spec:\n' +
                            '  ports:\n' +
                            '  - port: 80\n' +
                            '    protocol: TCP\n' +
                            '    targetPort: 80\n' +
                            '  selector:\n' +
                            '    run: nginx2\n' +
                            'status:\n' +
                            '  loadBalancer: {}\n' +
                            '---\n' +
                            '---\n' +
                            'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  creationTimestamp: null\n' +
                            '  labels:\n' +
                            '    run: nginx2\n' +
                            '  name: nginx2\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - image: nginx\n' +
                            '    name: nginx2\n' +
                            '    ports:\n' +
                            '    - containerPort: 80\n' +
                            '    resources: {}\n' +
                            '  dnsPolicy: ClusterFirst\n' +
                            '  restartPolicy: Always\n' +
                            'status: {}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {' k get svc\n' +
                            'NAME                       TYPE        CLUSTER-IP       EXTERNAL-IP   PORT(S)   AGE\n' +
                            'kubernetes                 ClusterIP   10.96.0.1        <none>        443/TCP   43m\n' +
                            'nginx                      ClusterIP   10.111.138.44    <none>        80/TCP    6m48s\n' +
                            'nginx-service-cluster-ip   ClusterIP   10.107.100.148   <none>        80/TCP    10m'}
                    </SyntaxHighlighter>

                    Testarea se face folosind un pod temporal <i>busybox</i> (pentru e un serviciu de tip ClusterIP):
                    <SyntaxHighlighter>
                        {' k run busybox --image=busybox --restart=Never -it -- /bin/sh'}
                    </SyntaxHighlighter>
                    ruland comanda:
                    <SyntaxHighlighter>
                        {'wget -O- 10.111.138.44:80'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Connecting to 10.111.138.44:80 (10.111.138.44:80)\n' +
                            'writing to stdout\n' +
                            '<!DOCTYPE html>\n' +
                            '<html>\n' +
                            '<head>\n' +
                            '<title>Welcome to nginx!</title>\n' +
                            '<style>\n' +
                            'html { color-scheme: light dark; }\n' +
                            'body { width: 35em; margin: 0 auto;\n' +
                            'font-family: Tahoma, Verdana, Arial, sans-serif; }\n' +
                            '</style>\n' +
                            '</head>\n' +
                            '<body>\n' +
                            '<h1>Welcome to nginx!</h1>\n' +
                            '<p>If you see this page, the nginx web server is successfully installed and\n' +
                            'working. Further configuration is required.</p>\n' +
                            '\n' +
                            '<p>For online documentation and support please refer to\n' +
                            '<a href="http://nginx.org/">nginx.org</a>.<br/>\n' +
                            'Commercial support is available at\n' +
                            '<a href="http://nginx.com/">nginx.com</a>.</p>\n' +
                            '\n' +
                            '<p><em>Thank you for using nginx.</em></p>\n' +
                            '</body>\n' +
                            '</html>\n' +
                            '-                    100% |************************************************************************|   615  0:00:00 ETA\n' +
                            'written to stdout'}
                    </SyntaxHighlighter>

                    Dar daca se va apela serviciul <i>nginx-service-cluster-ip</i> atunci:
                    <SyntaxHighlighter>
                        {' wget -O- 10.107.100.148:80\n' +
                            'Connecting to 10.107.100.148:80 (10.107.100.148:80)\n' +
                            'wget: can\'t connect to remote host (10.107.100.148): Connection refused'}
                    </SyntaxHighlighter>
                    (pentru ca este un serviciu independent, trebuie legat de un pod sau de un deploy; si acest lucru se realizeaza prin intermediul label-urilor)

                    <br/>
                    In serviciu:
                    <SyntaxHighlighter>
                        {'Selector:          run=nginx-pod'}
                    </SyntaxHighlighter>
                    In pod:
                    <SyntaxHighlighter>
                        {'k get pod --show-labels\n' +
                            'NAME                     READY   STATUS      RESTARTS   AGE     LABELS\n' +
                            'nginx-pod                1/1     Running     0          9m26s   run=nginx-pod'}
                    </SyntaxHighlighter>

                    <hr/>
                    Se poate crea un serviciu de tip <i>nodeport</i> pentru ca un pod/deploy sa poata fi accesat prin intermediul IP-ului de nod.
                    <br/>
                    Pentru a obtine IP-ul de nod:
                    <SyntaxHighlighter>
                        {'minikube ip'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'192.168.59.100'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {' k get node -o wide\n' +
                            'NAME       STATUS   ROLES           AGE   VERSION   INTERNAL-IP      EXTERNAL-IP   OS-IMAGE               KERNEL-VERSION   CONTAINER-RUNTIME\n' +
                            'minikube   Ready    control-plane   93m   v1.28.3   192.168.59.100   <none>        Buildroot 2021.02.12   5.10.57          docker://24.0.7'}
                    </SyntaxHighlighter>

                    Cream un serviciu de tip <b>NodePort</b> pentru pod-ul: nginx-pod
                    <SyntaxHighlighter>
                        {'k expose pod/nginx-pod --name=svc-node --type=NodePort --port=80 --target-port=80'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'k get svc\n' +
                            'NAME                       TYPE        CLUSTER-IP       EXTERNAL-IP   PORT(S)        AGE\n' +
                            'kubernetes                 ClusterIP   10.96.0.1        <none>        443/TCP        91m\n' +
                            'nginx                      ClusterIP   10.111.138.44    <none>        80/TCP         55m\n' +
                            'nginx-service-cluster-ip   ClusterIP   10.107.100.148   <none>        80/TCP         58m\n' +
                            'svc-node                   NodePort    10.106.42.150    <none>        80:32262/TCP   51s'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter>
                        {'wget http://192.168.59.100:32262 -o index.html'}
                    </SyntaxHighlighter>

                    <hr/>
                    <hr/>
                    <b>Info 2: Politici de retea: NetworkPolicy</b>
                    <br/>
                    <br/>

                    <b>Ingress</b> si <b>egress</b> sunt termeni folosiți în rețele pentru a descrie direcția traficului:
                    <ul>
                        <li>Ingress: se referă la traficul de rețea care este transmis într-un pod</li>
                        <li>Egress:  se referă la traficul de rețea care este emis de un pod</li>
                    </ul>
                    <b>[Ingress] => (Pod) => [Egress]</b>

                    <br/>

                    Observatii:
                    <ul>
                        <li>
                            <b>podSelector</b>: Câmpul selectează podurile pe baza etichetelor și determină la ce poduri se aplică politica.
                        </li>
                    </ul>

                    <br/>
                    Intrare / Exemplu 1: pod (cu label: <i>app: backend</i>) care nu permite sa primeasca trafic (ingress), dar poate emite trafic:
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: backend-ingress-deny\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: backend\n' +
                            '  policyTypes:\n' +
                            '  - Ingress'}
                    </SyntaxHighlighter>
                    Explicatie:
                    <br/>
                    1. Prima parte a politicii va fi comună între toate politicile, descrie tipul de obiect Kubernetes și numele politicii:
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: backend-ingress-deny'}
                    </SyntaxHighlighter>
                    2. Următoarea parte descrie <b>podSelector</b> care este Selectorul pentru a <b>descrie la ce pod-uri ar trebui să se aplice această politică</b>:
                    <SyntaxHighlighter>
                        {'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: backend'}
                    </SyntaxHighlighter>
                    Observatie: <b>O politică va avea efect numai în spațiul de nume în care a fost creată politica</b>
                    <br/>
                    Partea finală descrie reguli care specifică ceea ce este permis. <br/>
                    <SyntaxHighlighter>
                        {'spec:\n' +
                            '  policyTypes:\n' +
                            '  - Ingress'}
                    </SyntaxHighlighter>

                    <hr/>
                    Intrare / Exemplu 2 (permite în mod special frontendpod-ului să ajungă la backend):
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: backend-ingress-allow-frontend\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: backend\n' +
                            '  ingress:\n' +
                            '    - from:\n' +
                            '      - podSelector:\n' +
                            '          matchLabels:\n' +
                            '            app: frontend'}
                    </SyntaxHighlighter>

                    <hr/>
                    Intrare / Exemplu 3: (va aplica tuturor podurilor din spațiul de nume și va permite de la orice alt pod din același spațiu de nume)
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: all-pods-ingress-allow-within-namespace\n' +
                            'spec:\n' +
                            '  podSelector: {}\n' +
                            '  ingress:\n' +
                            '    - from:\n' +
                            '      - podSelector: {}'}
                    </SyntaxHighlighter>
                    <hr/>
                    Intrare / Exemplu 4: (permite comunicarea în rețea între spațiile de nume)
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: backend-ingress-allow-other-namespace\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: backend\n' +
                            '  ingress:\n' +
                            '    - from:\n' +
                            '        - namespaceSelector:\n' +
                            '            matchLabels:\n' +
                            '              type: monitoring'}
                    </SyntaxHighlighter>
                    <hr/>
                    Intrare / Exemplu 5: ( namespaceSelectorpoate fi combinat cu o podSelectorpentru a permite numai anumite poduri din spațiul de nume)
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: backend-ingress-allow-monitoring-namespace\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: backend\n' +
                            '  ingress:\n' +
                            '    - from:\n' +
                            '        - namespaceSelector:\n' +
                            '            matchLabels:\n' +
                            '              type: monitoring\n' +
                            '          podSelector:\n' +
                            '            matchLabels:\n' +
                            '              app: monitoring'}
                    </SyntaxHighlighter>

                    Iesire / Exemplu 6: nu poate emite trafic
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: frontend-egress-deny\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: frontend\n' +
                            '  policyTypes:\n' +
                            '  - Egress'}
                    </SyntaxHighlighter>

                    Iesire / Exemplu 7: permite sa emitere trafic de la app:frontend la (to) app: backend
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: frontend-egress-allow-to-backend\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: frontend\n' +
                            '  policyTypes:\n' +
                            '  - Egress\n' +
                            '  egress:\n' +
                            '    - to:\n' +
                            '      - podSelector:\n' +
                            '          matchLabels:\n' +
                            '            app: backend'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alte exemple (pod2 permite sa primeasca trafic de la pod-uri cu eticheta  app: pod1 si sa emite trafic catre poduri etichetate cu app: pod1):
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: network-policy\n' +
                            '  namespace: default\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: pod2\n' +
                            '  policyTypes:\n' +
                            '    - Ingress\n' +
                            '    - Egress\n' +
                            '  ingress:\n' +
                            '    - from:\n' +
                            '      - podSelector:\n' +
                            '          matchLabels:\n' +
                            '            app: pod1\n' +
                            '  egress:\n' +
                            '    - to:\n' +
                            '      - podSelector:\n' +
                            '          matchLabels:\n' +
                            '            app: pod1'}
                    </SyntaxHighlighter>

                    Nu permite trafic (intrare/iesire) la 1 pod (app: demo):
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: network-policy\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: demo\n' +
                            '  policyTypes:\n' +
                            '    - Ingress\n' +
                            '    - Egress'}
                    </SyntaxHighlighter>
                    Nu permite trafic (intrare/iesire) la toate podurile:
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: network-policy\n' +
                            'spec:\n' +
                            '  podSelector: {}\n' +
                            '  policyTypes:\n' +
                            '    - Ingress\n' +
                            '    - Egress'}
                    </SyntaxHighlighter>

                    Permite trafic (intrare/iesire) la 1 pod:
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: network-policy\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: demo\n' +
                            '  policyTypes:\n' +
                            '    - Ingress\n' +
                            '    - Egress\n' +
                            '  ingress:\n' +
                            '    - {}\n' +
                            '  egress:\n' +
                            '    - {}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <hr/>
                    <b>Exemplu aplicat - A (fara politica de retea)</b>
                    <br/>
                    <br/>

                    1. Creez namespace-ul test:
                    <SyntaxHighlighter>
                        {'kubectl create namespace test'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'namespace/test created'}
                    </SyntaxHighlighter>

                    2. Creez pod (nume=nginx, imagine=nginx, eticheta=app=nginx, namespace=test, port=80 + serviciu):
                    <SyntaxHighlighter>
                        {'kubectl run nginx --image=nginx --labels app=nginx --namespace test --expose --port 80'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'service/nginx created\n' +
                            'pod/nginx created'}
                    </SyntaxHighlighter>

                    3. Afisez obiectele din namespace-ul test:
                    <SyntaxHighlighter>
                        {'kubectl get all -n test'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME        READY   STATUS    RESTARTS   AGE\n' +
                            'pod/nginx   1/1     Running   0          2m7s\n' +
                            '\n' +
                            'NAME            TYPE        CLUSTER-IP     EXTERNAL-IP   PORT(S)   AGE\n' +
                            'service/nginx   ClusterIP   10.110.5.181   <none>        80/TCP    2m7s'}
                    </SyntaxHighlighter>

                    4. Testez podul, folosind un pod temporal:
                    <SyntaxHighlighter>
                        {'kubectl run busybox-temp --rm -ti --image=alpine -- /bin/sh'}
                    </SyntaxHighlighter>
                    poate afisa (daca nu e instalat curl: apk add curl):
                    <SyntaxHighlighter>
                        {'curl 10.110.5.181:80'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'<!DOCTYPE html>\n' +
                            '<html>\n' +
                            '<head>\n' +
                            '<title>Welcome to nginx!</title>\n' +
                            '<style>\n' +
                            'html { color-scheme: light dark; }\n' +
                            'body { width: 35em; margin: 0 auto;\n' +
                            'font-family: Tahoma, Verdana, Arial, sans-serif; }\n' +
                            '</style>\n' +
                            '</head>\n' +
                            '<body>\n' +
                            '<h1>Welcome to nginx!</h1>\n' +
                            '<p>If you see this page, the nginx web server is successfully installed and\n' +
                            'working. Further configuration is required.</p>\n' +
                            '\n' +
                            '<p>For online documentation and support please refer to\n' +
                            '<a href="http://nginx.org/">nginx.org</a>.<br/>\n' +
                            'Commercial support is available at\n' +
                            '<a href="http://nginx.com/">nginx.com</a>.</p>\n' +
                            '\n' +
                            '<p><em>Thank you for using nginx.</em></p>\n' +
                            '</body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Cu politica de retea</b>
                    <br/>
                    <br/>

                    In mod implicit, minikube nu suporta network policies.
                    <br/>
                    Pentru aceasta, daca se foloseste minikube, trebuie pornit cu parametrii <b>--network-plugin=cni --cni=calico</b>:
                    <SyntaxHighlighter>
                        {'minikube start --vm-driver=virtualbox --memory=2200 --network-plugin=cni --cni=calico'}
                    </SyntaxHighlighter>
                    probabil va trebui oprit si sters inainte:
                    <SyntaxHighlighter>
                        {'minikube stop'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'minikube delete'}
                    </SyntaxHighlighter>

                    1. Setam alias:
                    <SyntaxHighlighter>
                        {'Set-Alias k kubectl'}
                    </SyntaxHighlighter>

                    2. Cream ns:
                    <SyntaxHighlighter>
                        {'k create ns test'}
                    </SyntaxHighlighter>

                    3. Cream pod + serviciu:
                    <SyntaxHighlighter>
                        {' k run nginx --image=nginx --labels app=nginx --namespace test --expose --port 80'}
                    </SyntaxHighlighter>

                    4. Creeam deny-all-policy.yaml (politica care nu permite apelarea (ingress) pod-ului (app=nginx))
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: deny-all-policy\n' +
                            '  namespace: test\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: nginx\n' +
                            '  ingress: []'}
                    </SyntaxHighlighter>

                    5. Aplic:
                    <SyntaxHighlighter>
                        {'k apply -f .\\deny-all-policy.yaml'}
                    </SyntaxHighlighter>

                    6. Afisez obiectele:
                    <SyntaxHighlighter>
                        {'kubectl get all -n test'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME        READY   STATUS    RESTARTS   AGE\n' +
                            'pod/nginx   1/1     Running   0          3m41s\n' +
                            '\n' +
                            'NAME            TYPE        CLUSTER-IP      EXTERNAL-IP   PORT(S)   AGE\n' +
                            'service/nginx   ClusterIP   10.96.249.245   <none>        80/TCP    3m41s'}
                    </SyntaxHighlighter>

                    7. Testez:
                    <SyntaxHighlighter>
                        {'kubectl run busybox-temp --rm -ti --image=alpine -- /bin/sh'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {' apk add curl'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {' curl -m 1 10.96.249.245:80\n' +
                            'curl: (28) Connection timed out after 1001 milliseconds'}
                    </SyntaxHighlighter>

                    <hr/>
                    Fie <i>allow-policy.yaml</i> (alta politica; care permite apelarea pod-ului (app: nginx) sa fie apelat de alt pod cu eticheta: app: nginx)
                    <SyntaxHighlighter>
                        {'kind: NetworkPolicy\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: allow-policy\n' +
                            '  namespace: test\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      app: nginx\n' +
                            '  ingress:\n' +
                            '  - from:\n' +
                            '      - podSelector:\n' +
                            '          matchLabels:\n' +
                            '            app: nginx'}
                    </SyntaxHighlighter>

                    Sterg vechea politica (pentru a merge, a trebuit sa sters si serviciul+pod si sa-l recreez):
                    <SyntaxHighlighter>
                        {'k apply -f deny-all-policy.yaml'}
                    </SyntaxHighlighter>
                    Aplic noua politica:
                    <SyntaxHighlighter>
                        {'k apply -f allow-policy.yaml'}
                    </SyntaxHighlighter>

                    Testare (creem un pod temporal cu eticheta: app=nginx):
                    <SyntaxHighlighter>
                        {'kubectl run busybox --rm -ti --image=alpine --labels app=nginx --namespace test -- /bin/sh'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceNetworkPolicyK8sContent;