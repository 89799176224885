import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class LDAPSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-ldap", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Spring LDAP</b>
                    <br/>
                    <br/>

                    Adaugare dependinte <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'java\'\n' +
                            '    id \'org.springframework.boot\' version \'3.0.0\'\n' +
                            '    id \'io.spring.dependency-management\' version \'1.1.0\'\n' +
                            '}\n' +
                            '\n' +
                            'group = \'ro.ibrid\'\n' +
                            'version = \'0.0.1-SNAPSHOT\'\n' +
                            'sourceCompatibility = \'17\'\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-web\'\n' +
                            '\n' +
                            '    implementation("org.springframework.boot:spring-boot-starter-security")\n' +
                            '    implementation("org.springframework.ldap:spring-ldap-core")\n' +
                            '    implementation("org.springframework.security:spring-security-ldap")\n' +
                            '    implementation("com.unboundid:unboundid-ldapsdk")\n' +
                            '\n' +
                            '    testImplementation \'org.springframework.boot:spring-boot-starter-test\'\n' +
                            '    testImplementation \'org.springframework.security:spring-security-test\'\n' +
                            '\n' +
                            '    developmentOnly \'org.springframework.boot:spring-boot-devtools\'\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\') {\n' +
                            '    useJUnitPlatform()\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru <b>Maven</b> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'<dependency>\n' +
                            '    <groupId>org.springframework.ldap</groupId>\n' +
                            '    <artifactId>spring-ldap-core</artifactId>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'<dependency>\n' +
                            '    <groupId>org.springframework.security</groupId>\n' +
                            '    <artifactId>spring-security-ldap</artifactId>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Fie <b>/resources/application.properties</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'spring.ldap.embedded.ldif=classpath:ldap-data.ldif\n' +
                            'spring.ldap.embedded.base-dn=dc=springframework,dc=org\n' +
                            'spring.ldap.embedded.port=8389\n' +
                            '\n' +
                            'server.port=8871\n'}
                    </SyntaxHighlighter>

                    Continutul fisierului <b>resources/ldap-data.ldif</b> se poate lua de aici:
                    <br/>
                    <a target={"_blank"} href={"https://spring.io/guides/gs/authenticating-ldap/"}>Authenticating a User with LDAP</a>
                    <br/>

                    <hr/>

                    Fie:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'package ro.ibrid.springbootldap;\n' +
                            '\n' +
                            'import org.springframework.web.bind.annotation.GetMapping;\n' +
                            'import org.springframework.web.bind.annotation.RestController;\n' +
                            '\n' +
                            '@RestController\n' +
                            'public class HomeController {\n' +
                            '\n' +
                            '    @GetMapping("/")\n' +
                            '    public String index() {\n' +
                            '        return "Welcome to the home page!";\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Fie:
                    <SyntaxHighlighter  showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'package ro.ibrid.springbootldap;\n' +
                            '\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.security.config.annotation.authentication.builders.AuthenticationManagerBuilder;\n' +
                            'import org.springframework.security.config.annotation.web.builders.HttpSecurity;\n' +
                            'import org.springframework.security.config.Customizer;\n' +
                            'import org.springframework.security.crypto.bcrypt.BCryptPasswordEncoder;\n' +
                            'import org.springframework.security.web.SecurityFilterChain;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            '\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class WebSecurityConfig {\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    public SecurityFilterChain securityFilterChain(HttpSecurity http) throws Exception {\n' +
                            '        http\n' +
                            '                .authorizeRequests()\n' +
                            '                .anyRequest().fullyAuthenticated()\n' +
                            '                .and()\n' +
                            '                .formLogin();\n' +
                            '\n' +
                            '        return http.build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    public void configure(AuthenticationManagerBuilder auth) throws Exception {\n' +
                            '        /*\n' +
                            '            # fragment din ldap-dat.ldif:\n' +
                            '\n' +
                            '            dn: uid=bob,ou=people,dc=springframework,dc=org\n' +
                            '            objectclass: top\n' +
                            '            objectclass: person\n' +
                            '            objectclass: organizationalPerson\n' +
                            '            objectclass: inetOrgPerson\n' +
                            '            cn: Bob Hamilton\n' +
                            '            sn: Hamilton\n' +
                            '            uid: bob\n' +
                            '            userPassword: bobspassword\n' +
                            '         */\n' +
                            '        auth\n' +
                            '                .ldapAuthentication()\n' +
                            '                .userDnPatterns("uid={0},ou=people")\n' +
                            '                .groupSearchBase("ou=groups")\n' +
                            '                .contextSource()\n' +
                            '                .url("ldap://localhost:8389/dc=springframework,dc=org")\n' +
                            '                .and()\n' +
                            '                .passwordCompare()\n' +
                            '                .passwordEncoder(new BCryptPasswordEncoder())\n' +
                            '                .passwordAttribute("userPassword");\n' +
                            '    }\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            '//@Configuration\n' +
                            '//public class WebSecurityConfig {\n' +
                            '\n' +
                            '//  @Bean\n' +
                            '//  public SecurityFilterChain configure(HttpSecurity http) throws Exception {\n' +
                            '//    return http\n' +
                            '//      .authorizeRequests()\n' +
                            '//      .anyRequest().authenticated()\n' +
                            '//      .and()\n' +
                            '//      .formLogin(Customizer.withDefaults())\n' +
                            '//      .build();\n' +
                            '//  }\n' +
                            '//}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Test: http://localhost:8871/login
                    <br/>
                    Cu urmatoarele credentiale:
                    <ul>
                        <li>
                            username:ben
                        </li>
                        <li>
                            password:benspassword
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://spring.io/guides/gs/authenticating-ldap/"}>Authenticating a User with LDAP</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LDAPSpringBootContent;