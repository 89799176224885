import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class CustomHooksReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-custom-hooks", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Creare Hook-uri personalizate </b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            Un hook este o <b>functie reutilizabila</b>
                        </li>
                        <li>
                            daca exista o functie care este utilizata in mai multe componentea, atunci aceasta se poate extrage intr-un hook personalizat
                        </li>
                        <li>
                            hook-urile personalizate incep cu <b>use</b>
                        </li>
                    </ul>


                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {''}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomHooksReactContent;