import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

//import RxJxOperatorsTestComponent from "./rxjs.component";

class RxJsOperatorsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-rxjs-operators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>0. Operatori RxJs </b>
                    <br/>
                    <br/>

                    <i>Cadru de test</i>:
                    <br/>
                    Imaginați-vă că imprimați tricouri.
                    Doamna <i>Color</i> deține informatiile despre culoare, iar domnul <i>Logo</i> deține informatiile despre logo.
                    <br/>
                    Ambi vor alege culoarea și logo in mod spontan.
                    <br/>
                    Va trebui să așteptați și să combinați aceste două informații în mod continuu pentru a imprima tricouri.
                    <br/>
                    Doamna Color și Documnul Logo reprezintă doi observabili în test: <i>color$</i> și <i>logo$</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 0. Import operatori Rxjs\n' +
                            'import { forkJoin, zip, combineLatest, Subject } from \'rxjs\';\n' +
                            'import { withLatestFrom, take, first } from \'rxjs/operators\';\n' +
                            '\n' +
                            '// 1. Definire culoare tricou si optiuni de logo\n' +
                            'type Color = \'white\' | \'green\' | \'red\' | \'blue\';\n' +
                            'type Logo = \'fish\' | \'dog\' | \'bird\' | \'cow\';\n' +
                            '\n' +
                            '// 2. Creare 2 persoane - color si logo de tip observables,\n' +
                            'const color$ = new Subject<Color>();\n' +
                            'const logo$ = new Subject<Logo>();\n' +
                            '\n' +
                            '// 3. Suntem pregătiți să începem imprimarea cămășilor. Trebuie să vă abonați la observabile de culoare și logo pentru a produce tricouri.\n'+
                            '// Vom scrie acest cod aici mai târziu.\n' +
                            '//...\n' +
                            '\n' +
                            '// 4. Cele două persoane (observabile) își fac treaba, alegând culoarea și logo-ul\n' +
                            'color$.next(\'white\');\n' +
                            'logo$.next(\'fish\');\n' +
                            '\n' +
                            'color$.next(\'green\');\n' +
                            'logo$.next(\'dog\');\n' +
                            '\n' +
                            'color$.next(\'red\');\n' +
                            'logo$.next(\'bird\');\n' +
                            '\n' +
                            'color$.next(\'blue\');\n' +
                            '\n' +
                            '// 5. Cand cele 2 persoane (observables) nu mai au nimic de zic, isi vor spune pa. \n' +
                            '// Vom scrie acest cod aici mai târziu.\n' +
                            '//...'}
                    </SyntaxHighlighter>

                    Secvența informațiilor (partea 4 din codul nostru):
                    <ul>
                        <li>
                            1. Doamna Color alege WHITE<br/>
                            2. Domnul Logo alege FISH<br/>
                            3. Doamna Color alege GREEN<br/>
                            4. Domnul Logo alege DOG<br/>
                            5. Doamna Color alege RED<br/>
                            6. Domnul Logo alege BIRD<br/>
                            7. Doamna Color alege BLUE<br/>
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Zip</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 3. Suntem gata să începem imprimarea cămășii...\n' +
                            'zip(color$, logo$)\n' +
                            '    .subscribe(([color, logo]) => console.log(`tricou ${color} cu ${logo}`));'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 3. Suntem gata să începem imprimarea cămășii...\n' +
                            'zip(color$, logo$)\n' +
                            '    .subscribe((data) => console.log(`tricou ${data[0]} cu ${data[1]}`));'}
                    </SyntaxHighlighter>

                    unde:
                    <ul>
                        <li>[color, logo] - destructurare</li>
                        <li>
                            Când zipăm color$ și logo$, ne așteptăm să primim o matrice de 2 articole în timpul subscribe, primul articol este color și al doilea este logo
                        </li>
                    </ul>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {'1. tricou white cu fish\n' +
                            '2. tricou green cu dog\n' +
                            '3. tricou red cu bird'}
                    </SyntaxHighlighter>

                    <ul>
                        <li>
                            1. Doamna Color alege WHITE<br/>
                            2. Domnul Logo alege FISH<br/>
                            ==={"=>"} 1. tricou white cu fish
                            3. Doamna Color alege GREEN<br/>
                            4. Domnul Logo alege DOG<br/>
                            ==={"=>"} 2. tricou green cu dog
                            5. Doamna Color alege RED<br/>
                            6. Domnul Logo alege BIRD<br/>
                            ==={"=>"} 3. tricou red cu bird
                            7. Doamna Color alege BLUE<br/>
                        </li>
                    </ul>

                    Cum functioneaza zip?
                    <ul>
                        <li>
                            color va aștepta logo ori de câte ori există valori noi
                        </li>
                        <li>
                            operatorul zip poate accepta mai mult de 2 observabile - indiferent câte observabile, toți trebuie să se aștepte unul pe celălalt, niciun om lăsat în urmă!
                        </li>
                    </ul>


                    <hr/>
                    <b>2. combineLatest</b>
                    <br/>
                    <br/>

                    In acest caz observarii nu se asteapta unul pe altul.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 3. Suntem gata să începem imprimarea cămășii...\n' +
                            'combineLatest(color$, logo$)\n' +
                            '    .subscribe(([color, logo]) => console.log(`${color} shirt with ${logo}`));'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {'1. white shirt with fish\n' +
                            '2. green shirt with fish\n' +
                            '3. green shirt with dog\n' +
                            '4. red shirt with dog\n' +
                            '5. red shirt with bird\n' +
                            '6. blue shirt with bird'}
                    </SyntaxHighlighter>

                    Cum functioneaza combineLatest?
                    <ul>
                        <li>
                            prima funcție este declanșată după ce ambele valorile color și logo se modifică.
                            <br/>
                            Apoi, fie modificarea color sau logo va declanșa jurnalul.
                        </li>
                        <li>
                            (daca ai 2 observari, se asteapta ca ambi sa emita ceva, si apoi se declanseaza la fiecare modificare a acelor 2 observari, oricare din ei)
                        </li>
                    </ul>

                    Secvența informațiilor (partea 4 din codul nostru):
                    <ul>
                        <li>
                            1. Doamna Color alege WHITE<br/>
                            2. Domnul Logo alege FISH<br/>
                            ===={"=>"} [1] white shirt with fish<br/>
                            3. Doamna Color alege GREEN<br/>
                            ===={"=>"} [2] green shirt with fish<br/>
                            4. Domnul Logo alege DOG<br/>
                            ===={"=>"} [3] green shirt with dog<br/>
                            5. Doamna Color alege RED<br/>
                            ===={"=>"} [4] red shirt with dog<br/>
                            6. Domnul Logo alege BIRD<br/>
                            ===={"=>"} [5] red shirt with bird<br/>
                            7. Doamna Color alege BLUE<br/>
                            ===={"=>"} [6] blue shirt with bird<br/>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. withLatestFrom</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 3. Suntem gata să începem imprimarea cămășii...\n' +
                            'color$.pipe(withLatestFrom(logo$))\n' +
                            '    .subscribe(([color, logo]) => console.log(`${color} shirt with ${logo}`));'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {'1. green shirt with fish\n' +
                            '2. red shirt with dog\n' +
                            '3. blue shirt with bird'}
                    </SyntaxHighlighter>

                    Cum functioneaza withLatestFrom?
                    <ul>
                        <li>
                            <i>color</i> este primar în timp ce logo este <i>secundar</i>.
                            <br/>
                            La început (o singură dată), <i>color (primar)</i> va astepta <i>logo(secundar)</i>.
                            <br/>
                            Odată ce <i>logo(secundarul)</i> a răspuns, <i>color(primarul)</i> va prelua conducerea.
                            <br/>
                            Jurnalul va fi declanșat ori de câte ori următoarea valoare <i>color (primară)</i> este schimbată.
                            <br/>
                            Modificările valorii <i>logo (secundare)</i> nu vor declanșa jurnalul consolei.
                        </li>
                    </ul>

                    <SyntaxHighlighter>
                        {'1. Ms. Color picks WHITE <- nu se intampla nimic, se asteapta logo (secondary)\n' +
                            '2. Mr. Logo picks FISH <- s-a gasit logo (secondary), se astepata comanda primary\n' +
                            '3. Ms. Color picks GREEN\n' +
                            '====> [1], primary says GREEN! So, GREEN + FISH\n' +
                            '4. Mr. Logo picks DOG <- se ignora logarea, fiind seconday\n' +
                            '5. Ms. Color picks RED\n' +
                            '===> [2], primary says RED! So, RED + DOG\n' +
                            '6. Mr. Logo picks BIRD <- se ignora logarea, fiind seconday\n' +
                            '7. Ms. Color picks BLUE\n' +
                            '===> [3] primary says BLUE! So, BLUE + BIRD'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. forkJoin</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 3. Suntem gata să începem imprimarea cămășii...\n' +
                            'forkJoin(color$, logo$)\n' +
                            '    .subscribe(([color, logo]) => console.log(`${color} shirt with ${logo}`));'}
                    </SyntaxHighlighter>

                    Nu se va afisa nimic in consola, pentru ca ambele observabilele color și logo nu sunt complete.
                    <br/>
                    Pentru a afisa, trebuie sa marcam ca finalizat/completa observabilele color și logo:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'color$.complete();\n' +
                            'logo$.complete();'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {'blue shirt with bird'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'1. Ms. Color picks WHITE\n' +
                            '2. Mr. Logo picks FISH\n' +
                            '3. Ms. Color picks GREEN\n' +
                            '4. Mr. Logo picks DOG\n' +
                            '5. Ms. Color picks RED\n' +
                            '6. Mr. Logo picks BIRD\n' +
                            '7. Ms. Color picks BLUE\n' +
                            '8. Ms. Color completed <-- color este completed!\n' +
                            '9. Mr. Logo completed <--- color este completed' +
                            '====> [1]  blue shirt with bird / Final destination!'}
                    </SyntaxHighlighter>

                    Există mai multe moduri de a completa observabile.
                    <br/>
                    Există operatori care vă permit să completați automat observabile atunci când sunt îndeplinite condițiile, de exemplu:
                    <ul>
                        <li>take</li>
                        <li>takeUntil</li>
                        <li>first</li>
                    </ul>

                    Să presupunem că vrei să faci doar o cămașă; trebuie să știi doar primul color și logo. <br/>
                    În acest caz, nu vă pasă de restul informațiilor oferite de Ms. Color & Mr. Logo.
                    <br/>
                    Puteți utiliza operatorul <b>take</b> sau <b>first</b> pentru a realiza observabile de auto-completare odată ce se emite prima color și logo.
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// 3. Suntem gata să începem imprimarea cămășii...\n' +
                            'const firstColor$ = color$.pipe(take(1));\n' +
                            'const firstLogo$ = logo$.pipe(first());\n' +
                            '\n' +
                            'forkJoin(firstColor$, firstLogo$)\n' +
                            '    .subscribe(([color, logo]) => console.log(`${color} shirt with ${logo}`));'}
                    </SyntaxHighlighter>

                    Nu avem nevoie de cele două linii <b>.complete()</b>, deoarece <b>take</b> și <b>first</b> va completa automat observabilul atunci când condiția este îndeplinită.


                    {/*<RxJxOperatorsTestComponent></RxJxOperatorsTestComponent>*/}
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/rxjs-operators-forkjoin-zip-combinelatest-withlatestfrom"}>
                               Înțelegerea operatorilor RxJS: forkJoin, zip, combineLatest și withLatestFrom
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RxJsOperatorsReactContent;