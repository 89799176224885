import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../books/IndexContent";

class ArtBooksContent extends BaseContentPage  {

    constructor(props) {
        super(props, "books-art", IndexContent);

        this.state = {
            ...this.state,
            //  filtredBooks: [],
            search:''
        };
    }

    static books = [
        {
            id:1,
            title:"Arta si perceptia vizuala: o psihologie a vazului creator",
            publisher:"Polirom",
            year:2011,
            authors:["Rudolf Arnheim"],
            pages:504,
            language: "romana",
            ISBN: "9789734619788",
            img:"9789734619788-1864292.jpg"
        },

        {
            id:2,
            title:"O istorie a artei moderne",
            publisher:"Polirom",
            year:2014,
            authors:["Will Gompertz"],
            pages:384,
            language: "romana",
            ISBN: "9789734641480",
            img:"392783-0.jpg"
        },

        {
            id:3,
            title:"Arta. O istorie ilustrata",
            publisher:"Litera",
            year:2017,
            authors:["-"],
            pages:512,
            language: "romana",
            ISBN: "9786063319303",
            img:"2323461-0.png"
        },

        {
            id:4,
            title:"Zapping prin istoria artelor",
            publisher:"Rao",
            year:2018,
            authors:["Gérard Denizeau"],
            pages:512,
            language: "romana",
            ISBN: "9786060061496",
            img:"214308531-0.jpeg"
        },

        {
            id:5,
            title:"Istoria artei",
            publisher:"Rao",
            year:2020,
            authors:["-"],
            pages:576,
            language: "romana",
            ISBN: "9786060063926",
            img:"istoria_artei_-_2d.jpg"
        },

        {
            id:6,
            title:"Arta. O istorie ilustrata",
            publisher:"Litera",
            year:2020,
            authors:["-"],
            pages:512,
            language: "romana",
            ISBN: "9786063338748",
            img:"915950388-0-240.png"
        },

        {
            id:7,
            title:"Istoria artei",
            publisher:"Art",
            year:2016,
            authors:["E.H. Gombrich"],
            pages:688,
            language: "romana",
            ISBN: "2000000646305",
            img:"284641-0-240.jpeg"
        },

        {
            id:8,
            title:"Art Escapes: Hidden Art Experiences Outside the Museums",
            publisher:"Die Gestalten Verlag",
            year:2022,
            authors:["Grace Banks"],
            pages:304,
            language: "engleza",
            ISBN: "9783967040524",
            img:"128Gestalten_AK.jpg"
        },

        {
            id:9,
            title:"The Anatomy Sketchbook",
            publisher:"Octopus Publishing Group",
            year:2018,
            authors:["E.H. Gombrich"],
            pages:128,
            language: "engleza",
            ISBN: "9781781575055",
            img:"81637062-0-240.jpeg"
        },

        {
            id:10,
            title:"Drawing For the Absolute and Utter Beginner, Revised",
            publisher:"Watson-Guptill Publications",
            year:2018,
            authors:["Claire Watson Garcia"],
            pages:192,
            language: "engleza",
            ISBN: "9780399580512",
            img:"251102811-0-240.jpeg"
        },

        {
            id:11,
            title:"Learn to Draw",
            publisher:"Arcturus Publishing Ltd",
            year:2017,
            authors:["Barrington Barber"],
            pages:144,
            language: "engleza",
            ISBN: "9781784283605",
            img:"66819783-0.jpeg"
        },

        {
            id:12,
            title:"Scoala de desen. Portrete",
            publisher:"Aquila",
            year:2020,
            authors:["Andras Szunyoghy"],
            pages:208,
            language: "romana",
            ISBN: "9789737148612",
            img:"886265328-0.jpeg"
        },

        {
            id:13,
            title:"Scoala de desen",
            publisher:"Aquila",
            year:2019,
            authors:["Andras Szunyoghy"],
            pages:407,
            language: "romana",
            ISBN: "9789737148360",
            img:"436958614-0.jpeg"
        },

        {
            id:14,
            title:"Impotriva interpretarii",
            publisher:"Vellant",
            year:2016,
            authors:["Susan Sontag"],
            pages:360,
            language: "romana",
            ISBN: "9786068642222",
            img:"447807-0.jpg"
        },


    ]

    handleSearch(event){
        let search = event.target.value;

        // let filtredBooks = ITBooksContent.books.filter((item) =>
        //     item.title.toLowerCase().includes(search.toLowerCase())
        // );
        //
        // this.setState({filtredBooks: filtredBooks});

        this.setState({search: search});
    }

    render() {

        this.state.filtredBooks = ArtBooksContent.books;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)} />
                    </label>
                    &nbsp;
                    <span>Numar total carti: {ArtBooksContent.books.length}</span>
                </div>

                <hr/>

                <br/>
                <div className={"text-justify important"}>

                    {ArtBooksContent.books
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a=>a.toLowerCase().includes(s));
                                return okTitle || okPublisher;
                            }
                        )
                        .map(function(item, index){
                        return <div className="row">

                            {/*<div className="col-sm-4">*/}
                            {/*    <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}*/}
                            {/*         src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>*/}

                            {/*</div>*/}

                            <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index+1}</span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250} rowSpan={"7"}>
                                            <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}
                                                    src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>
                                        </td>

                                        <td width={250}>
                                            <b>Titlu</b>
                                        </td>
                                        <td>
                                            <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Editura</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.publisher}}/></td>
                                    </tr>
                                    <tr>
                                        <td><b>An</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Autori</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Numar pagini</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.pages}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Limba</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>ISBN</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.ISBN}}/></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>;

                    })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ArtBooksContent;