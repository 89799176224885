import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class StringBuilderJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-stringbuilder", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un obiect de tip StringBuilder:
                    <ul>
                        <li>
                            stocheaza un sir de caractere
                        </li>
                        <li>
                            este mutabil (se poate modifica)
                        </li>
                    </ul>

                    Constructori:
                    <ul>
                        <li>StringBuilder()</li>
                        <li> StringBuilder(String str)</li>
                        <li> StringBuilder(int capacitate)</li>
                    </ul>

                    Metode:
                    <ul>
                        <li><b>append()</b>: adauga un substring</li>

                        <li><b>length()</b> : returneaza lungimea sirului de caractere</li>
                        <li><b>charAt(index)</b>: returneaza caracterul de pe pozitia <i>index</i>; daca indexul este in afara intervalului permis se arunca exceptia <b>java.lang.StringIndexOutOfBoundsExceptions</b></li>
                        <li><b>indexOf()</b>: returneaza pozitia de la care incepe un caracter sau un subsir de caractere in cadrul unui sir de caractere; returneaza -1, daca nu il gaseste;
                            <br/>
                            Signaturi:
                            <ul>
                                <li>
                                    int indexOf(int ch)
                                </li>
                                <li>
                                    int indexOf(int ch, int fromIndex)
                                </li>
                                <li>
                                    int indexOf(String str)
                                </li>
                                <li>
                                    int indexOf(String str, int fromIndex)
                                </li>
                            </ul>
                        </li>
                        <li><b>substring()</b>: returneaza un substring incepand cu pozitia data de primul parametrul; si poate fi limit de al doilea parametru
                            <br/>
                            Signaturi:
                            <ul>
                                <li>
                                    String substring(int beginIndex)
                                </li>
                                <li>
                                    String substring(int beginIndex, int endIndex)
                                </li>

                            </ul>
                        </li>

                        <li>
                            <b>insert()</b>: adauga un substring dat de al doilea parametru, in pozitia data de primul parametru
                            <br/>
                            Signatura:
                            <ul>
                                <li>
                                    StringBuilder insert(int offset, String str)
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>delete()</b>: sterge caracterele cuprinse intre pozitia data de primul parametru si pozitia-1, data de al doilea parametru;
                            <br/>se poate arunca exceptia <b>java.lang.StringIndexOutOfBoundsExceptions</b>, daca indecsi dati sunt afara celor permisi;
                            <br/>
                            Signatura:
                            <ul>
                                <li>
                                    StringBuilder delete(int startIndex, String endIndex)
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>deleteCharAt()</b>: sterge caracterul de la pozitia/indexul dat ca parametru
                            <br/>se poate arunca exceptia <b>java.lang.StringIndexOutOfBoundsExceptions</b>, daca indexul dat e in afara celor permisi;
                            <br/>
                            Signatura:
                            <ul>
                                <li>
                                    StringBuilder deleteCharAt(int index)
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>replace()</b>: sterge subsirul din intervalul dat de primii doi parametri si pune in loc sirul de caractere dat ca al treia parametru
                            Signatura:
                            <ul>
                                <li>StringBuilder replace(int startIndex, int endIndex, String newString)</li>

                            </ul>
                        </li>

                        <li>
                            <b>reverse()</b>: inverseaza sirusl de caractere
                            Signatura:
                            <ul>
                                <li>StringBuilder reverse()</li>
                            </ul>
                        </li>

                        <li>
                            <b>toString()</b>: converteste sirul de caractere de tip StringBuilder la unul de tip String;

                            Signatura:
                            <ul>
                                <li>String toString()</li>
                            </ul>
                        </li>


                    </ul>

                    <b>Observatie</b>
                    <br/>
                    Operatorul == verifica daca doua obiect au aceeasi referinta (sau refera acelasi obiect).
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringBuilderJavaContent;