import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring-security/IndexContent";


class JavaSpringSecurityEx01Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-security-ex-01", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify content important"}>

                    <i>Aplicatie:</i> <b>security-01</b>
                    <br/>
                    <i>Descriere aplicatie:</i> Aceasta aplicatie bazata pe framework-ul <i>Spring Boot</i> nu va avea nici un fel de securitate.
                    <br/>
                    <i>IDE folosit</i>: Intellij IDEA
                    <hr/>

                    Continut fisier <b>pom.xml</b> (in radacina proiectului):

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                        '<project xmlns="http://maven.apache.org/POM/4.0.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                        '         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 https://maven.apache.org/xsd/maven-4.0.0.xsd">\n' +
                        '    <modelVersion>4.0.0</modelVersion>\n' +
                        '    <parent>\n' +
                        '        <groupId>org.springframework.boot</groupId>\n' +
                        '        <artifactId>spring-boot-starter-parent</artifactId>\n' +
                        '        <version>2.5.5</version>\n' +
                        '        <relativePath/> <!-- lookup parent from repository -->\n' +
                        '    </parent>\n' +
                        '    <groupId>ro.letyournailsgrow.security</groupId>\n' +
                        '    <artifactId>security-01</artifactId>\n' +
                        '    <version>0.0.1-SNAPSHOT</version>\n' +
                        '    <name>security-01</name>\n' +
                        '    <description>security-01</description>\n\n' +
                        '    <properties>\n' +
                        '        <java.version>1.8</java.version>\n' +
                        '    </properties>\n\n' +
                        '    <dependencies>\n' +
                        '        <dependency>\n' +
                        '            <groupId>org.springframework.boot</groupId>\n' +
                        '            <artifactId>spring-boot-starter-web</artifactId>\n' +
                        '        </dependency>\n' +
                        '    </dependencies>\n' +
                        '\n' +
                        '    <build>\n' +
                        '        <plugins>\n' +
                        '            <plugin>\n' +
                        '                <groupId>org.springframework.boot</groupId>\n' +
                        '                <artifactId>spring-boot-maven-plugin</artifactId>\n' +
                        '            </plugin>\n' +
                        '        </plugins>\n' +
                        '    </build>\n' +
                        '\n' +
                        '</project>'}

                    </SyntaxHighlighter>


                    <br/>


                    Structura proiectului respecta structura clasica a unui proiect <i>Spring Boot</i>.
                    Prin urmare vom avea urmatoarea structura de directoare:

                    <div className={"padding50"}>
                        <b>\src\main\java</b>
                    </div>

                    <br/>
                    Aici vom creea pachetul:
                    <div className={"padding50"}>
                        <b>ro.letyournailsgrow.security.exemplu01</b>
                    </div>
                    <br/>
                    In acest pachet vom creea clasa Java <b>Security01Application.java</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.security.exemplu01;\n' +
                        '\n' +
                        'import org.springframework.boot.SpringApplication;\n' +
                        'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                        '\n' +
                        '@SpringBootApplication\n' +
                        'public class Security01Application {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '        SpringApplication.run(Security01Application.class, args);\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Apoi, vom creea pachetul:

                    <div className={"padding50"}>
                        <b>ro.letyournailsgrow.security.exemplu01.controller</b>
                    </div>

                    <br/>
                    In acest pachet vom creea clasa Java <b>HelloController.java</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.security.exemplu01.controller;\n' +
                        '\n' +
                        'import org.springframework.web.bind.annotation.GetMapping;\n' +
                        'import org.springframework.web.bind.annotation.RestController;\n' +
                        '\n' +
                        '@RestController\n' +
                        'public class HelloController {\n' +
                        '\n' +
                        '    @GetMapping("/hello")\n' +
                        '    public String hello(){\n' +
                        '        return "Hello";\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru testarea proiectului, vom creea in radacina proiectului, fisierul <b>test.http</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        http://localhost:8080/hello
                    </SyntaxHighlighter>

                    Rularea liniei de mai sus va apela metoda <b>hello()</b> din <b>HelloController</b> va genera urmatorul raspuns:

                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'GET http://localhost:8080/hello\n' +
                        '\n' +
                        'HTTP/1.1 200 \n' +
                        'Content-Type: text/plain;charset=UTF-8\n' +
                        'Content-Length: 5\n' +
                        'Date: Thu, 21 Oct 2021 18:30:49 GMT\n' +
                        'Keep-Alive: timeout=60\n' +
                        'Connection: keep-alive\n' +
                        '\n' +
                        'Hello\n' +
                        '\n' +
                        'Response code: 200; Time: 215ms; Content length: 5 bytes'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>STOP</b>
                </div>

                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default JavaSpringSecurityEx01Content;