import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InitFromExistingProjectGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-create-repository-existing-project", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git init (proiect care exista)</b>
                    <br/>
                    <br/>

                    Sa prespunem ca exista deja proiectul facut. Si vrem sa fie gestionat de catre git.
                    <br/>
                    Pentru a realiza lucru acesta, schimbam directul curent astfel incat sa fie directorul radacina al proiectul existent.
                    <br/>
                    Sa prespunem ca avem un proiect gradle in: <i>{"D:\\work>cd java-email-test"}</i>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd D:\\work\\cd java-email-test'}
                    </SyntaxHighlighter>

                    Aici, vom crea un fisier numit <b>.gitignore</b>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>{
                        '.gradle\n' +
                        '**/build/\n' +
                        '!src/**/build/\n' +
                        '\n' +
                        '# Ignore Gradle GUI config\n' +
                        'gradle-app.setting\n' +
                        '\n' +
                        '# Avoid ignoring Gradle wrapper jar file (.jar files are usually ignored)\n' +
                        '!gradle-wrapper.jar\n' +
                        '\n' +
                        '# Avoid ignore Gradle wrappper properties\n' +
                        '!gradle-wrapper.properties\n' +
                        '\n' +
                        '# Cache of project\n' +
                        '.gradletasknamecache\n' +
                        '\n' +
                        '# Eclipse Gradle plugin generated files\n' +
                        '# Eclipse Core\n' +
                        '.project\n' +
                        '# JDT-specific (Eclipse Java Development Tools)\n' +
                        '.classpath\n' +
                        '\n' +
                        '.idea'
                    }</SyntaxHighlighter>

                    Pentru ca acest director sa fie gestionat de GIT, executam comanda <b>git init</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git init'}
                    </SyntaxHighlighter>
                    Se va creea un director ascuns numit <b>.git</b>

                    <br/>
                    <br/>

                    Pentru a adauga fisierele ce vrem sa fie gestionate de git:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git add . '}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InitFromExistingProjectGitContent;