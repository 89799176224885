import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java/IndexContent";

class AbstractVsInterfacesJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-abstract-vs-interfaces", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Clase abstracte vs interfete</b>
                    <br/>
                    <br/>


                    Diferente intre clasa abstracta si o interfata (<i>de citit optional, daca Java inseamna ceva</i>):
                    <ul>
                        <li>
                            o clasă abstractă poate suprascrie metodele clasei Object.
                            <br/>
                            o interfață nu poate suprascrie metodele clasei Object
                        </li>
                        <li>
                            o clasă abstractă poate declara variabile de instanță, cu toți modificatorii de acces posibili și pot fi accesate în clasele copil.
                            <br/>
                            o interfață poate avea numai variabile publice, statice și finale și nu poate avea variabile de instanță.
                        </li>
                        <li>
                            o clasă abstractă poate declara instanțe și blocuri statice
                            <br/>
                            o intrfata nu poate declara instante sau blocuri statice
                        </li>
                        <li>
                            clasă abstractă nu se poate referi la o expresie lambda
                            <br/>
                            interfața poate avea o singură metodă abstractă care se poate referi la o expresie lambda
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AbstractVsInterfacesJavaContent;