import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Tips2DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tips2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Tips - K8s</b>


                    1. Un <b>pod</b> in k8s este un grup de unul sau mai multe containere de aplicatii  si unele resurse partajeate pentru aceste containere.

                    <hr/>

                    2. <b>Deployments</b> / Implementarile ofera functionalitatea de replicare cu ajutorul <b>ReplicaSets</b>, impreuna cu diverse capabilitati
                    cum ar fi rolling out al modificarilor, rollback la modificari, daca e nevoie.
                    <br/>
                    Deployments sunt peste ReplicaSets.

                    <hr/>

                    3. <b>Rolling update</b>:
                    <br/>
                    Deployments actualizeaza Pod-urile intr-un mod rolling update.
                    <br/>
                    (de exemplu, daca se face update la imaginea unui deployment de la nginx la apache, atunci nu toate pod-urile din depoyment vor fi jos toate)
                    <br/>
                    Se poate specifica <b>maxUnavailable</b> si <b>maxSurge</b> pentru a control procesul de rolling update.

                    <hr/>

                    4. <b>ConfigMap</b>: permite decuplarea configurarilor de imagine pentru a mentine aplicatia portabila.

                    <hr/>

                    5. <b>Secret</b>: obiect care contine un continut sensibil de date de mici dinimensiuni cum ar fi parole, token, key:
                    <ul>
                        <li>
                            pastrate in baza de date <b>etcd</b>
                        </li>
                        <li>
                            permite clientilor pastrarea secretelor centralizat reducand riscul de expunere
                        </li>
                    </ul>

                    <hr/>

                    6. <b>Serviciu</b>: este o abstractizare care defineste un set logic de poduri si o polica prin care acestea se pot accesa.

                    <hr/>
                    7. Tipuri de servicii:
                    <ul>
                        <li>
                            ClusterIP {"=>"} serviciul default; daca serviciul de este tipul ClusterIP, serviciului i se atribuie o adresa IP interna de cluster;
                            <br/>
                            acesta este accesibil doar din interiorul clusterului;
                        </li>
                        <li>
                            NodePort {"=>"} K8s aloca un port (intre 30000-32767) pe fiecare worker node;
                            <br/>
                            fiecare nod va face proxy la acel port in serviciu
                        </li>
                    </ul>

                    <hr/>
                    8. Unul din beneficiile K8s esteca suporta diverse tipuri de volume.

                    <hr/>
                    9. <b>Persistent Volume (PV)</b> = piesa de strocare intr-un cluster care este furnizat de un administrator sau dinamic folosind Storage Clasess.

                    <hr/>

                    10. Dynamic Volume Provisioning {"=>"} permite crearea la cerere la volumelor de stocare.
                    <br/>
                    Aceasta caracteristica elimina nevoia de administrator de cluster; asigurand automat stocarea atunci cand este solicitat;

                    <hr/>
                    11. <b>Persistent Volume Claim (PVC)</b> {"=>"} cerere de stocare de la un user.
                    <br/>
                    In cadrul revendicarii (claim), user-ul trebuie sa specifice dimensiunea volumului dorit impreuna cu modul de acces.

                    <hr/>

                    12. <b>Requests</b> / <b>Limits</b>

                    <ul>
                        <li>requests {"=>"} garantat</li>
                        <li>limits {"=>"} containerul nu trebuie sa ia din resursele nodului peste valoare specificata</li>
                    </ul>

                    K8s Scheduler decide pe ce nod sa ruleze un pod in functie de requests si limits.

                    <hr/>
                    13. <b>Labels</b>
                    <br/>
                    Etichetele sunt perechi de cheie/valoare atasate la obiecte/cum ar fi pod-urile.

                    <br/>
                    Afisare noduri cu etichetele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get nodes --show-labels'}
                    </SyntaxHighlighter>

                    <hr/>
                    14. <b>Selectors</b>
                    <br/>
                    Selectori permit filtrarea obiectelor pe baza etichetelor.
                    <br/>
                    Afisare poduri care au eticheta <i>env=production</i>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get pods -l env=production'}
                    </SyntaxHighlighter>

                    <hr/>
                    15. <b>DaemonSet</b> {"=>"} se asigura ca toate nodurile ruleaza o copie a unui pod.
                    <br/>
                    Cand un nod este adaugat la cluster, Pod-urile sunt adaugate in acest nod.
                    <br/>
                    Este similar cu <b>global</b> din <b>Swarm</b>

                    <hr/>
                    16. <b>DNS pentru Servicii si Pod-uri</b>.
                    <br/>
                    In k8s comunicarea poate avea loc prin DNS.
                    <br/>
                    Un pod P din namespace-ul n1 poate comunica cu un serviciul S din namespace-ul n2, folosind: A.ns1.
                    <br/>
                    Un pod P din namespace-ul n1 poate comunica cu un serviciul S din namespace-ul n2, folosind: A.
                    <br/>

                    <hr/>
                    17. <b>Taints</b> si <b>Tolerations</b>.
                    <br/>
                    taints {"=>"} sunt folosite pentru a respinde pod-uri pe anumite noduri.
                    <br/>
                    Pentru a avea acces la un nod worker taint, este nevoie de un permis special.
                    <br/>
                    Acest permis special se numeste <b>tolerations</b>.
                    <br/>
                    taints:
                    <ul>
                        <li>NoSchedule {"=>"} nici un pod nu se poate programa pe nod, decat daca are o <i>toleranta</i> potrivita;
                        <br/>
                            podurile care deja ruleaza continua sa ruleze;
                        </li>
                        <li>NoExecute {"=>"} podul este evacuat din nod, daca ruleaza deja pe nod</li>
                    </ul>

                    <hr/>
                    18. Probe <b>Readiness</b>.
                    <br/>
                    Uneori, se intampla ca aplicatia sa ruleze, dar temporal sa nu fie capabila sa serveasca trafic.
                    <br/>
                    De exemplu, aplicatia ruleaza, dar inca incarca un fisier de configurare de dimensiuni mari de la vendor extern.
                    <br/>
                    In acest caz, nu vrem sa omoram containerul, si nici nu vrem sa serveasca trafic.

                    <hr/>
                    19. <b>Reclaim Policy / Politica de revendicare</b>
                    <br/>

                    Politica de revendicare este responsabilă pentru ceea ce se întâmplă cu datele în volum persistent atunci când (PVC) a fost ștearsă.
                    <br/>
                    Tipul de politici de revendicare disponibile:
                    <ul>
                        <li>
                            <b>Retain {"=>"} Când PVC-ul este șters, PersistentVolume (PV) încă există și volumul este considerat „released”.
                                <br/>
                                Nu este încă disponibil pentru o altă revendicare, deoarece datele solicitantului anterior rămân pe volum</b>
                        </li>
                        <li>
                            Delete {"=>"} Cand PVC-ul este sters, PV este sters
                        </li>
                        <li>
                            Recycle (<b>deprecated</b>) {"=>"} daca este suportata de plugin-ul de volum de baza, politica de reciclare, efecutaza o curatare: <b>rm -rf /volume</b> pe volum
                            si il face din nou disponibil pentru o noua revendicare
                        </li>
                    </ul>

                    <hr/>
                    20. Expanding Persistent Volume Claim
                    <br/>
                    Un PVC poate fi extins doar daca  <b>allowVolumeExpansion</b> este setat la true.
                    Pentru a cere un volum mai mare pentru PVC, se editeaza PVC si se specifica o diminesiune mai mare.
                    Acest lucru declanseaza extinderea volului care sustine PV-ul.

                    <hr/>
                    <b>21. Storage Classes / Clase de stocare</b>
                    <br/>
                    <br/>
                    Un StorageClass oferă administratorilor o modalitate de a descrie „clasele” de stocare pe care le oferă.
                    <br/>

                    <b>Storage Class Resource</b> {"=>"} Fiecare StorageClass conține câmpurile:
                    <ul>
                        <li><b>provisioner</b> {"=>"} determină ce plugin de volum este utilizat pentru furnizarea de PV;</li>
                        <li><b>parameters</b></li>
                        <li><b>reclaimPolicy</b></li>
                        <li><b>allowVolumeExpansion</b>(optional)</li>
                    </ul>
                    care sunt utilizate atunci când un <b>PersistentVolume</b> aparținând clasei trebuie să fie furnizat dinamic.

                    <hr/>
                    22. Pasi pentru extinderea unui volum:
                    <ul>
                        <li>
                            activare Volume Expansion in Storage Class (allowVolumeExpansion: true)
                        </li>
                        <li>
                            redimensionare PVC
                        </li>
                        <li>
                            Reporniți POD-ul {"=>"} Odată ce obiectul PVC este modificat, va trebui să reporniți POD-ul.
                        </li>
                    </ul>

                    <hr/>
                    <b>23. Volume vs Persistent Volume</b>
                    <br/>
                    <br/>

                    Exista 2 tipuri primare de stocare:
                    <ul>
                        <li>volume {"=>"} exista cat exista si pod-ul; cand pod-ul este sters se sterge si volumul asociat; folosit pentur stocarea datelor temporare</li>
                        <li>persistent volume {"=>"} ramane disponibil si in fara ciclului de viata a unui pod; PV va ramane si dupa ce un pod este sters</li>
                    </ul>

                    <hr/>
                    <b>24. Namespaces</b>
                    <br/>
                    <br/>

                    In mod implicit, se afiseaza doar pod-uri din namespace-ul <i>default</i>; pentru a afisa toate podurile, indiferent de namespace se foloste optiunea <b>--all-namespaces</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --all-namespaces'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips2DockerContent;