import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CommentsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-comments", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Comentarii pot fi folosite pentru a explica cod.
                    <br/>
                    <br/>
                    Tipuri de comentarii:
                    <ul>
                        <li>
                            <b>comentariu pe un sigur rand</b>:

                            <ul>
                                <li>
                                   <b>încep cu //</b>
                                </li>
                                <li>
                                    orice text între // și sfârșitul liniei este ignorat de Java (nu va fi executat)
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'// acesta este un comentariu pe o singura linie'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>comentariu pe mai multe randuri</b>:

                            <ul>
                                <li>
                                    încep cu <b>/* si se termină cu */</b>
                                </li>
                                <li>
                                    orice text între /* și */va fi ignorat de Java.
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'/* acesta este un comentariu \n' +
                                'pe mai multe linii */'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>comentariu de documentare</b>:

                            <br/>
                            Aceste comentarii sunt folosite in momentul in care se genereaza documentatia pentru aplicatie/program/API.
                            <br/>
                            Aceste comentarii pot contiune tag-uri, de exemplu: @author.
                            <ul>
                                <li>
                                    <b>încep cu /** si se termină cu */</b>
                                </li>
                                <li>
                                    orice text între /*și */va fi ignorat de Java.
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'/** acesta este un comentariu \n' +
                                'de cocumentare \n' +
                                '\n' +
                                '@author Kj \n'+
                                '\n' +
                                '*/'}
                            </SyntaxHighlighter>

                            Pentru a crea documentație API, trebuie să folosim instrumentul <b>javadoc</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'javadoc Numeclasa.java'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.w3schools.com/java/java_comments.asp"}>Java Comments (w3schools)</a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.javatpoint.com/java-comments"}>Java Comments (JavaPoint)</a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CommentsJavaContent;