import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JSPLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-jsp", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Customizarea JSP-ului unui portlet existent (de exemplu BLOGS) se poate face in mai multe moduri:
                    <ul>
                        <li>RenderFilter</li>
                        <li>Dynamic Include</li>
                        <li>OSGi Fragments</li>
                    </ul>

                    <hr/>
                    <b>1. RenderFilter</b>
                    <br/>
                    <br/>

                    Pentru a creea o clasa de tip <b>RenderFilter</b> clasa trebuie:
                    <ul>
                        <li>sa fie de tip <b>@Component(service = PortletFilter.class)</b></li>
                        <li>sa extinda <b>RenderFilter</b></li>
                        <li>sa suprascrie metoda <b>public void doFilter(RenderRequest request, RenderResponse response, FilterChain chain)</b> prin intermediul careia se poate manipula DOM-ul</li>
                    </ul>

                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package com.portlet.filter.customization;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.util.PortletKeys;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import javax.portlet.Portlet;\n' +
                        'import javax.portlet.PortletException;\n' +
                        'import javax.portlet.RenderRequest;\n' +
                        'import javax.portlet.RenderResponse;\n' +
                        'import javax.portlet.filter.*;\n' +
                        'import java.io.IOException;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "javax.portlet.name=" + PortletKeys.BLOGS\n' +
                        '        },\n' +
                        '        service = PortletFilter.class\n' +
                        ')\n' +
                        'public class BlogRenderFilter implements RenderFilter {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void doFilter(RenderRequest request, RenderResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                        '\n' +
                        '        chain.doFilter(request, response);\n' +
                        '        \n' +
                        '        response.getWriter().write("modificat de kj");\n' +
                        '      \n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void init(FilterConfig filterConfig) throws PortletException {\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void destroy() {\n' +
                        '\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: jsp-custom\n' +
                        'Bundle-SymbolicName: jsp.custom\n' +
                        'Bundle-Version: 1.0.0'}
                    </SyntaxHighlighter>

                    Fisierul <b>build.gradle</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "javax.portlet", name: "portlet-api"\n' +
                        '\tcompileOnly group: "javax.servlet", name: "javax.servlet-api"\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "com.liferay.portal.kernel"\n' +
                        '\tcompileOnly group: "org.osgi", name: "osgi.cmpn"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Dupa ce se face deploy, si se se adauga in pagina un widget de tip Blog se va afisa dupa acest portlet textul <i>modificat de kj</i>.

                    <hr/>
                    <b>2. Dynamic Include</b>
                    <br/>
                    <br/>

                    Pentru a creea o clasa de tip <b>DynamicInclude</b> clasa trebuie:
                    <ul>
                        <li>sa fie de tip <b>@Component(service = DynamicInclude.class)</b></li>
                        <li>sa extinda <b>DynamicInclude</b></li>
                        <li>sa suprascrie metodele <b>public void include(HttpServletRequest httpServletRequest, HttpServletResponse httpServletResponse, String key)</b>  si <b>public void register(DynamicIncludeRegistry dynamicIncludeRegistry)</b>
                            prin intermediul careia se poate manipula DOM-ul</li>
                    </ul>

                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package com.dynamic.include.customization;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.servlet.taglib.DynamicInclude;\n' +
                        'import com.liferay.portal.kernel.util.PortletKeys;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import javax.servlet.http.HttpServletRequest;\n' +
                        'import javax.servlet.http.HttpServletResponse;\n' +
                        'import java.io.IOException;\n' +
                        'import java.io.PrintWriter;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        service = DynamicInclude.class\n' +
                        ')\n' +
                        'public class BlocDynamicInclude implements DynamicInclude {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void include(HttpServletRequest httpServletRequest, HttpServletResponse httpServletResponse, String key) throws IOException {\n' +
                        '\n' +
                        '        PrintWriter writer = httpServletResponse.getWriter();\n' +
                        '        writer.println("kj a fost aici!");\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void register(DynamicIncludeRegistry dynamicIncludeRegistry) {\n' +
                        '        dynamicIncludeRegistry.register("com.liferay.blogs.web#/blogs/view_entry.jsp#pre");\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>


                    Fisierul <b>bnd.bnd</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: jsp-custom\n' +
                        'Bundle-SymbolicName: jsp.custom\n' +
                        'Bundle-Version: 1.0.0'}
                    </SyntaxHighlighter>

                    Fisierul <b>build.gradle</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "javax.portlet", name: "portlet-api"\n' +
                        '\tcompileOnly group: "javax.servlet", name: "javax.servlet-api"\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "com.liferay.portal.kernel"\n' +
                        '\tcompileOnly group: "org.osgi", name: "osgi.cmpn"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Dupa ce se face deploy, si se se adauga in pagina un widget de tip Blog, apoi se adauga o intrare/articol in blog, atunci cand se va intra pe un articol se va afisa inainte de articol, textul <i>kj a fost aici!</i>.

                    <hr/>
                    <b>3. OSGi Fragments</b>
                    <br/>
                    <br/>

                    Este o metoda nerecomandata; aceasta presupune inlocuirea unui intreg JSP; ceea ce face codul dependent de o anumita versiune de Liferay.

                    <br/>
                    <br/>

                    Sa presupunem ca vrem sa inlocuim LoginPortlet (Liferay Login Web) cu o varianta proprie.
                    <br/>
                    Pentru a afla detalii legate de un bundle, putem folosi <b>Gogo Shell</b>:
                    <ul>
                        <li><b>lb</b> - pentru a afisa toate bundle-urile</li>
                        <li><b>b <i>ID</i></b> - pentru a afisa detalii despre un anumit bundle cu id-ul <i>ID</i></li>
                    </ul>

                    Daca vom vrea sa gasim detalii legate de <i>Liferay Login Web</i> vom afla ca acesta se afla in: <i>com.liferay.login.web_5.0.26</i> (numele bundle-ului si versiunea acestuia).


                    In fisierul <b>bnd.bnd</b> se adauga linia:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Fragment-Host: com.liferay.login.web;bundle-version="5.0.26"'}
                    </SyntaxHighlighter>

                    In <b>src/main/resources/META-INF/resources</b> se copiaza din sursele Liferay fisierul <i>login.jsp</i> si se modifica dupa bunul plac.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JSPLiferayContent;