import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class NgRxNewAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-ngrx-new", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. NgRx: instalare</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @ngrx/store'}
                    </SyntaxHighlighter>
                    Va genera cod diferit pentru componentele standalone si pentru cele normale.
                    <br/>
                    <br/>
                    Pentru module normale, in <i>app.module.ts</i>, in sectiunea <b>imports</b>, va genera <b>StoreModule.forRoot({},{})</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [StoreModule.forRoot({},{})]'}
                    </SyntaxHighlighter>

                    Pentru module aplicatii standdalone, in <i>main.ts</i> va genera <b>provideStore()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent, { providers: [ provideStore() ]} );'}
                    </SyntaxHighlighter>

                    <hr/>
                    sau (alternativ, dar fara de adaugare/completare de cod automata):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install --save @ngrx/store'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. NgRx: structura</b>
                    <br/>
                    <br/>

                    De obicei, avem urmatoarea structura:
                    <ul>
                        <li>/store</li>
                        <li>/store/*.actions.ts (contine actiunile posibile; ex: counter.actions.ts sau auth.actions.ts)</li>
                        <li>/store/*.reducer.ts (contine functia reducer; ex: counter.reducer.ts sau auth.reducer.ts)</li>
                    </ul>

                    <hr/>
                    <b>3. NgRx: Creare reducer si adaugare in store</b>
                    <br/>
                    <br/>

                    Pentru a creea un <i>reducer</i> se foloseste functia <b>createReducer()</b>,
                    care primeste ca parametru o valoare initiala pentru stare pe care vrem s-o pastram.
                    <br/>
                    Aceasta valoare initiala poate fi:
                    <ul>
                        <li>numar</li>
                        <li>string</li>
                        <li>boolean</li>
                        <li>obiect</li>
                        <li>array</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createReducer} from \'@ngrx/store\'\n' +
                            '\n' +
                            'const initialState = 0;\n' +
                            'export const counterReducer = createReducer(initialState);'}
                    </SyntaxHighlighter>

                    Apoi, adaugam acest reducer in <i>app.module.ts</i>, in sectiunea <b>imports</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [StoreModule.forRoot({\n' +
                            '  counter: counterReducer,\n' +
                            '  //auth: authReducer\n' +
                            '})]'}
                    </SyntaxHighlighter>
                    (<i>counter</i> e doar o cheie pentru store, care <i>bate</i> in reducer-ul folosit de store)
                    <br/>
                    <br/>
                    Pentru module aplicatii standdalone, in <i>main.ts</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent, { providers: [ provideStore(\n' +
                            '  counter: counterReducer,\n' +
                            '  //auth: authReducer\n' +
                            ') ]} );'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alternativ, dar <i>invechit</i>, crearea unui reducer se poate face si in urmatorul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const initialState = 0;\n' +
                            'export function counterReducer(state = initialState){\n' +
                            '  return state\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. NgRx: Citire date din store</b>
                    <br/>
                    <br/>

                    Injectare <b>Store</b> in constructor:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'constructor (private store: Store){}'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'count$: Observable<number>;\n\n' +
                            'constructor (private store: Store<{counter: number}>){ //<-- {counter: number} e pentru typescript \n' +
                            '   this.count$=store.select(\'counter\'); // <-- se selecteaza store-ul pe baza cheii; si se returneaza un Observable \n' +
                            '}'}
                    </SyntaxHighlighter>

                    In template html:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{{ count$ | async }}'}
                    </SyntaxHighlighter>

                    In aplicatia standalone, e nevoie si de urmatorul import:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [AsyncPipe]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. NgRx: Actiuni si schimbare stare</b>
                    <br/>
                    <br/>

                    O actiune se adauga folosind functia <b>createAction</b>.
                    <br/>
                    In directorul <i>store</i>, putem creea fisierul <i>counter.actions.ts</i> cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createAction} from \'@ngrx/store\'\n' +
                            '\n' +
                            'export const increment = createAction(\n' +
                            '  \'[Counter] Increment\' // <!-- nume actiune, trebuie sa fie un idetificator unic in aplicatie\n' +
                            ')'}
                    </SyntaxHighlighter>

                    Modificam reducer-ul, pentru a reactiona la actiuni. O stare se poate modifica doar prin intermediul unei actiuni.
                    <br/>
                    Pentru a face acest lucru, vom folosi functia <b>on</b> care preia ca parametrii:
                    <ul>
                        <li>
                            <i>o actiune</i>: definita in fisierele .actions.ts
                        </li>
                        <li>
                            <i>o functie</i>: care are ca parametru <b>starea curenta</b> si returneaza <b>starea actualizata</b>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createReducer, on} from \'@ngrx/store\'\n' +
                            '\n' +
                            'import {increment} from \'./counter.actions\';\n\n' +
                            'const initialState = 0;\n' +
                            'export const counterReducer = createReducer(\n' +
                            '  initialState,\n' +
                            '  on(increment, (state)=> state + 1)\n' +
                            ');'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. NgRx: Dispatch</b>
                    <br/>
                    <br/>

                    Se foloseste metoda <b>dispatch</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.store.dispatch(increment())'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. NgRx: Adaugare date la actiuni</b>
                    <br/>
                    <br/>

                    Se foloseste <b>props</b> pentru a defini structura datelor ce pot fi trimise prin actiune:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createAction, props} from \'@ngrx/store\'\n' +
                            '\n' +
                            'export const increment = createAction(\n' +
                            '  \'[Counter] Increment\', // <-- nume actiune [type], trebuie sa fie un idetificator unic in aplicatie\n' +
                            '  props<{value: number}>() // <-- se defineste tipul de date ce poate fi adaugat la o actiune\n' +
                            ')'}
                    </SyntaxHighlighter>

                    Apoi, in functia <b>on</b> putem accesa aceste date prin intermediul unui al doilea argument a functiei necesare pentru <b>on</b> (pe langa state):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createReducer, on} from \'@ngrx/store\'\n' +
                            '\n' +
                            'import {increment} from \'./counter.actions\';\n\n' +
                            'const initialState = 0;\n' +
                            'export const counterReducer = createReducer(\n' +
                            '  initialState,\n' +
                            '  on(increment, (state, action)=> state + action.value)\n' +
                            ');'}
                    </SyntaxHighlighter>
                    Deci, extragerea datelor se face, in cazul de mai sus: <i>action.value</i>.

                    <br/>
                    <br/>
                    Apoi:
                    Se foloseste metoda <b>dispatch</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.store.dispatch(increment({value: 5}))'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. NgRx: Selector</b>
                    <br/>
                    <br/>

                    Un selector se poate definii in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export const selectCount = (state: {counter: number}) => state.counter'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            counter: cheia pentru store
                        </li>
                        <li>
                            number: tipul de date pe care il retine store-ul
                        </li>
                    </ul>

                    Acum in loc de:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.count$=store.select(\'counter\');'}
                    </SyntaxHighlighter>
                    putem scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.count$=store.select(selectCount);'}
                    </SyntaxHighlighter>

                    In plus, se poate crea un selector, folosind <b>createSelector</b>, pe baza altui selector:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export const selectDoubleCount = createSelector(\n' +
                            '   selectCount,\n' +
                            '   (state) => state * 2\n' +
                            ')'}
                    </SyntaxHighlighter>
                    si se poate folosi:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.doubleCount$=store.select(selectDoubleCount);'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alternativ, dar <i>invechit</i>, crearea unui reducer cu actiune, se poate face si in urmatorul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const initialState = 0;\n' +
                            'export function counterReducer(state = initialState, action: any){\n' +
                            '  if (action.type === \'[Counter] Increment\'){\n' +
                            '     return state + action.value;' +
                            '  }\n' +
                            '  return state\n' +
                            '}'}
                    </SyntaxHighlighter>
                    (in loc de <i>any</i> se poate specifica un tip CounterActions, definit: export type CounterActions = IncrementAction - vezi mai jos)

                    <br/>
                    <br/>

                    Alternativ, dar <i>invechit</i>, crearea unei actiuni, se poate face si in urmatorul mod:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Action} from \'@ngrx/store\'\n' +
                            '\n' +
                            'export class IncrementAction implements Action{\n' +
                            '  readonly type =\'[Counter] Increment\'; \n' +
                            '  constructor(public value: number}){}' +
                            '}'}
                    </SyntaxHighlighter>

                    Prezint mai jos si crearea unui actiuni folosind <b>createAction</b> pentru a compara mai usor:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createAction, props} from \'@ngrx/store\'\n' +
                            '\n' +
                            'export const increment = createAction(\n' +
                            '  \'[Counter] Increment\', // <-- nume actiune [type], trebuie sa fie un idetificator unic in aplicatie\n' +
                            '  props<{value: number}>() // <-- se defineste tipul de date ce poate fi adaugat la o actiune \n' +
                            ')'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.store.dispatch(new IncrementAction(5))'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NgRxNewAngularContent;