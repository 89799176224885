import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class DefaultPropsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-default-props", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Valori implicite pentru props</b>
                    <br/>
                    <br/>

                    Se foloseste <b>defaultProps</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'\n' +
                            'import React, { Component } from \'react\';\n' +
                            '  \n' +
                            'class App extends Component {\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <div >\n' +
                            '        <Person name="kapil" eyeColor="blue" age="23"></Person>\n' +
                            '        <Person name="Sachin" eyeColor="blue" ></Person>\n' +
                            '        <Person name="Nikhil" age="23"></Person>\n' +
                            '        <Person eyeColor="green" age="23"></Person>\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '  }\n' +
                            '}\n' +
                            '  \n' +
                            'class Person extends Component {\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <div>\n' +
                            '        <p>Name: {this.props.name} </p>\n' +
                            '        <p>EyeColor: {this.props.eyeColor}</p>\n' +
                            '        <p>Age : {this.props.age} </p>\n' +
                            '      </div>\n' +
                            '    )\n' +
                            '  }\n' +
                            '}\n' +
                            '  \n' +
                            'Person.defaultProps = {\n' +
                            '  name: "KJ",\n' +
                            '  eyeColor: "negri",\n' +
                            '  age: "50"\n' +
                            '}\n' +
                            '  \n' +
                            'export default App;'}
                    </SyntaxHighlighter>

                    desi se poate scrie si asa:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'\n' +
                            'import React, { Component } from \'react\';\n' +
                            '  \n' +
                            'class App extends Component {\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <div >\n' +
                            '        <Person name="kapil" eyeColor="blue" age="23"></Person>\n' +
                            '        <Person name="Sachin" eyeColor="blue" ></Person>\n' +
                            '        <Person name="Nikhil" age="23"></Person>\n' +
                            '        <Person eyeColor="green" age="23"></Person>\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '  }\n' +
                            '}\n' +
                            '  \n' +
                            'class Person extends Component {\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <div>\n' +
                            '        <p>Name: {this.props.name || "KJ"} </p>\n' +
                            '        <p>EyeColor: {this.props.eyeColor || "negri"}</p>\n' +
                            '        <p>Age : {this.props.age || "50"} </p>\n' +
                            '      </div>\n' +
                            '    )\n' +
                            '  }\n' +
                            '}\n' +
                            '  \n' +
                            'export default App;'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DefaultPropsReactContent;