import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerNetworkResumeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-networking-resume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Networking</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1.1. Container {"->"} WWW</b>
                    <br/>
                    <br/>

                    In mod implicit, un container poate accesa/comunica cu Web (WWW)!

                    <hr/>
                    <b>1.2. Container {"->"} Masina Gazda (Host)</b>
                    <br/>
                    <br/>
                    Pentru ca un container sa comunice cu masina gazda (localhost),
                    trebuie inlocuit <i>localhost</i> cu <b>host.docker.internal</b> (ca adresa, domeniu)

                    <br/>
                    <br/>
                    De exemplu, in loc de:
                    <br/>
                    <SyntaxHighlighter>
                        {'http://localhost:27916/test'}
                    </SyntaxHighlighter>
                    se va scrie
                    <SyntaxHighlighter>
                        {'http://host.docker.internal:27916/test'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. Container {"->"} Container B</b>
                    <br/>
                    <br/>
                    <b>
                        1.3.1 Abordarea cu adresa IP
                    </b>
                    <br/>
                    <br/>
                    Se poate obtine IP-ul celuilalt container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect [container]'}
                    </SyntaxHighlighter>
                    din sectiunea: <i>NetworkSettings / IPAddress</i>
                    <br/>
                    <br/>
                    De exemplu, in loc de:
                    <br/>
                    <SyntaxHighlighter>
                        {'http://localhost:27916/test'}
                    </SyntaxHighlighter>
                    se va scrie
                    <SyntaxHighlighter>
                        {'http://172.17.0.2:27916/test'}
                    </SyntaxHighlighter>
                    Problema e ca in aceasta abordare trebuie sa gasim adresa IP.

                    <br/>
                    <br/>
                    Pe scurt:
                    <ul>
                        <li>se foloseste <b>host.docker.internal</b> ca adresa</li>
                    </ul>

                    <hr/>
                    <b>
                        1.3.2 Creare retea
                    </b>
                    <br/>
                    <br/>
                    Mai multe containere putem sa le punem sa ruleze in acceasi retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --network nume_retea ...'}
                    </SyntaxHighlighter>

                    Comparativ cu volumele, Docker nu creeaza o retea daca nu exista, asa ca trebuie creata:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create nume_retea'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        1.3.2.1 Creare retea de tip bridge
                    </b>
                    <br/>
                    <br/>

                    Creare retea de tip bridge:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --driver bridge nume_retea'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --driver bridge kj-net'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect kj-net'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "kj-net",\n' +
                            '        "Id": "1a096f16a145118a054353acc9e8a0536544e461d567ee72a08a55643ac472fd",\n' +
                            '        "Created": "2022-11-28T20:25:21.403415797+02:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": {},\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.22.0.0/16",\n' +
                            '                    "Gateway": "172.22.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {},\n' +
                            '        "Options": {},\n' +
                            '        "Labels": {}\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>
                    sau creare retea cu mai multe optiuni:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create ' +
                            '--driver bridge \\\n' +
                            '--subnet=172.25.0.0/16 \\\n' +
                            '--ip-range=172.25.1.1/24 \\\n' +
                            '--gateway=172.25.0.1 \\\n' +
                            '--label=trial-bridge \\\n' +
                            '--opt com.docker.network.driver.mtu=9000 \\\n' +
                            'kj-net-3'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect kj-net-3'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "kj-net-3",\n' +
                            '        "Id": "7463e050550a3e1d0293d0549ad8679558daa745ebf29a53b3ffa610ccea4646",\n' +
                            '        "Created": "2022-12-27T18:41:05.82754504+02:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": {},\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.25.0.0/16",\n' +
                            '                    "IPRange": "172.25.1.1/24",\n' +
                            '                    "Gateway": "172.25.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {},\n' +
                            '        "Options": {\n' +
                            '            "com.docker.network.driver.mtu": "9000"\n' +
                            '        },\n' +
                            '        "Labels": {\n' +
                            '            "trial-bridge": ""\n' +
                            '        }\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>

                    Folosind aceasta abordare - retea Docker -, toate containerele pot comunica intre ele si IP-urile sunt rezolvate in mod automat.
                    Se va folosi <b>numele containerului</b> in loc de IP.
                    <br/>
                    <br/>
                    De exemplu, in loc de:
                    <br/>
                    <SyntaxHighlighter>
                        {'http://172.17.0.2:27916/test'}
                    </SyntaxHighlighter>
                    se va scrie
                    <SyntaxHighlighter>
                        {'http://mongodb:27916/test'}
                    </SyntaxHighlighter>
                    unde <i>mongodb</i> este numele container B! (<i>mongodb</i> se va traduce in <i>172.17.0.2</i> - IP-ul containerului B )
                    <br/>
                    Acest mecanism functioneaza doar daca ambele containere fac parte din acceasi retea (si nu cea <i>default</i>)

                    <br/>
                    <br/>
                    Listare retele
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NETWORK ID     NAME               DRIVER    SCOPE\n' +
                            '2be808aad381   bridge             bridge    local\n' +
                            '7c8e7915edf6   complex_default    bridge    local\n' +
                            'b486cab2c9bb   elasticsearch      bridge    local\n' +
                            '14875372bd61   frontend_default   bridge    local\n' +
                            '8472c2b25bc0   host               host      local\n' +
                            '1a096f16a145   kj-net             bridge    local\n' +
                            '4d4450cdfa4d   minikube           bridge    local\n' +
                            '7138c1675165   none               null      local\n' +
                            '5d4729895d48   visits_default     bridge    local\n'}
                    </SyntaxHighlighter>
                    In mod implicit exista 3 retele (sunt create de Docker, dupa instalare):
                    <ul>
                        <li>
                            bridge (retea default)
                        </li>
                        <li>
                            host
                        </li>
                        <li>
                            none (driver: null)
                        </li>
                    </ul>
                    <SyntaxHighlighter>
                        {'NETWORK ID     NAME               DRIVER    SCOPE\n' +
                            '2be808aad381   bridge             bridge    local\n' +
                            '8472c2b25bc0   host               host      local\n' +
                            '7138c1675165   none               null      local'
                           }
                    </SyntaxHighlighter>
                    In mod implcit, la pornirea unui container, acesta este adaugat/conectat in reteua <i>bridge</i>.
                    <br/>
                    Pe langa cele enumerate mai sus, mai exista in Docker EE:
                    <ul>
                        <li>overlay</li>
                        <li>macvlan</li>
                    </ul>
                    
                    Un container poate fi conectat la mai multe retele.

                    <hr/>
                    Listare retele cu filtrare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls --filter driver=bridge'}
                    </SyntaxHighlighter>

                    Pe scurt:
                    <ul>
                        <li>este nevoie de o retea de container</li>
                        <li>se foloseste <b>numele container-ului</b> ca adresa</li>
                    </ul>

                    <hr/>
                    Docker nu va inlocui in codul sursa, ci detecteaza request-urile si resolva IP-ul!

                    <hr/>
                    <b>
                        1.3.2.2 Creare retea de tip macvlan
                    </b>
                    <br/>
                    <br/>
                    Creare a unei retele, de tip <i>macvlan</i>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create ' +
                            '--driver macvlan \\\n' +
                            '--subnet=172.16.0.0/16 \\\n' +
                            '--gateway=172.16.0.1 \\\n' +
                            '-o parent=ens \\\n' +
                            'kj-macvlan'}
                    </SyntaxHighlighter>

                    Aplicatiile containerizate de tip <i>Network Traffic Monitor</i> sunt potrivite pentru retea <b>MacVLAN</b>

                    <hr/>
                    <b>
                        1.3.2.3 Creare retea de tip none
                    </b>
                    <br/>
                    <br/>

                    Pentru a face o aplicatie/container complet detasata de retea aceasta trebuie conectata la pornirea la reteaua <b>none</b>:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-none --network=none busybox'}
                    </SyntaxHighlighter>

                    Pentru a verifica:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect busybox-none'}
                    </SyntaxHighlighter>
                    in sectiunea de <i>Networks</i> se va afisa (se observa ca nu are IP, IPAddress: ""):
                    <SyntaxHighlighter>
                        {'"Networks": {\n' +
                            '                "none": {\n' +
                            '                    "IPAMConfig": null,\n' +
                            '                    "Links": null,\n' +
                            '                    "Aliases": null,\n' +
                            '                    "NetworkID": "7138c16751652325a18d99ef0a3cbc719d991d0b19c8adb3a1410d20e481ecf6",\n' +
                            '                    "EndpointID": "53404cedee52324e0208fb7b845770238c93a78df28a7b4a7d7b337567e5c9be",\n' +
                            '                    "Gateway": "",\n' +
                            '                    "IPAddress": "",\n' +
                            '                    "IPPrefixLen": 0,\n' +
                            '                    "IPv6Gateway": "",\n' +
                            '                    "GlobalIPv6Address": "",\n' +
                            '                    "GlobalIPv6PrefixLen": 0,\n' +
                            '                    "MacAddress": "",\n' +
                            '                    "DriverOpts": null\n' +
                            '                }\n' +
                            '            }\n'}
                    </SyntaxHighlighter>

                    Un container pornit ca fiind conectat la reteau <i>none</i> nu se poate conecta la alta retea, de exemplu, la o retea de tip  <i>bridge</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network connect bridge busybox-none'}
                    </SyntaxHighlighter>
                    se va obtine urmatoarea eroare:
                    <SyntaxHighlighter>
                        {'Error response from daemon: container cannot be connected to multiple networks with one of the networks in private (none) mode\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        1.3.3. Conectare container la o retea
                    </b>
                    <br/>
                    <br/>

                    Conectare container la o retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network connect [nume-retea] [container]'}
                    </SyntaxHighlighter>
                    Un container poate fi conectat la mai multe retele.
                    <br/>
                    Putem conecta la retea toate containerele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network connect [nume-retea] $(docker ps -a -q)'}
                    </SyntaxHighlighter>

                    Pentru a verifica (in sectiunea Networks):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect [container]'}
                    </SyntaxHighlighter>

                    Conectare la retea la pornirea containerului:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -itd --network [nume-retea] [imagine]'}
                    </SyntaxHighlighter>

                    Afisare informatii despre port:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container port [container]'}
                    </SyntaxHighlighter>

                    exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container port loving_pasteur'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'80/tcp -> 0.0.0.0:49153\n' +
                            '80/tcp -> :::49153\n'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-2 --network kj-net-3 busybox'}
                    </SyntaxHighlighter>

                    acum, daca inspectam din nou reteau <i>kj-net-3</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect kj-net-3'}
                    </SyntaxHighlighter>
                    vom vedea ca avem containerul <i>busybox-2</i> conectat la el:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "kj-net-3",\n' +
                            '        "Id": "7463e050550a3e1d0293d0549ad8679558daa745ebf29a53b3ffa610ccea4646",\n' +
                            '        "Created": "2022-12-27T18:41:05.82754504+02:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": {},\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.25.0.0/16",\n' +
                            '                    "IPRange": "172.25.1.1/24",\n' +
                            '                    "Gateway": "172.25.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {\n' +
                            '            "35ede6e3a3d0a65d5ce25d8bf35bb579ce1dd36dd442fc6d0daadf9bdb6648bf": {\n' +
                            '                "Name": "busybox-2",\n' +
                            '                "EndpointID": "d1e5142abb22354be6e88e29692b562641b63fafb941ab63e10cd2e4035bce41",\n' +
                            '                "MacAddress": "02:42:ac:19:01:00",\n' +
                            '                "IPv4Address": "172.25.1.0/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n' +
                            '        "Options": {\n' +
                            '            "com.docker.network.driver.mtu": "9000"\n' +
                            '        },\n' +
                            '        "Labels": {\n' +
                            '            "trial-bridge": ""\n' +
                            '        }\n' +
                            '    }\n' +
                            ']\n'
                        }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        1.3.4 Inspectare retea
                    </b>
                    <br/>
                    <br/>

                    Inspectare retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect bridge'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "bridge",\n' +
                            '        "Id": "2be808aad381d0071099ea4d164d3874171cbac8b74cbd50404b4266acac5dc9",\n' +
                            '        "Created": "2022-10-12T22:02:25.426814866+03:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": null,\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.17.0.0/16",\n' +
                            '                    "Gateway": "172.17.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {\n' +
                            '            "213e8cf4e499e2207e29bc9940ed8d328b86ae53517983ba1de70a89ae858a36": {\n' +
                            '                "Name": "fervent_driscoll",\n' +
                            '                "EndpointID": "7ec8390f7e5a7d4db5adaa2b319bf7c8e3f607bbdc1677a7173813dc85c374d8",\n' +
                            '                "MacAddress": "02:42:ac:11:00:03",\n' +
                            '                "IPv4Address": "172.17.0.3/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "4ff5b2b1004427cb772fbaac644715a60a4789e89158856076f66ac77f6f858c": {\n' +
                            '                "Name": "wizardly_kowalevski",\n' +
                            '                "EndpointID": "114386d324456aca68405b12ff23e757d32c044559f0088c4db4aaeb6af3c6ba",\n' +
                            '                "MacAddress": "02:42:ac:11:00:02",\n' +
                            '                "IPv4Address": "172.17.0.2/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "5a4cdd28b1fe32ae9e455c516daecd776c98ecb4cf7afaec11f1b7b8793de874": {\n' +
                            '                "Name": "loving_pasteur",\n' +
                            '                "EndpointID": "486c556cfab4717aec34e79e783c5eab8c31c98ff26f6cefb778b91230fcd436",\n' +
                            '                "MacAddress": "02:42:ac:11:00:04",\n' +
                            '                "IPv4Address": "172.17.0.4/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n' +
                            '        "Options": {\n' +
                            '            "com.docker.network.bridge.default_bridge": "true",\n' +
                            '            "com.docker.network.bridge.enable_icc": "true",\n' +
                            '            "com.docker.network.bridge.enable_ip_masquerade": "true",\n' +
                            '            "com.docker.network.bridge.host_binding_ipv4": "0.0.0.0",\n' +
                            '            "com.docker.network.bridge.name": "docker0",\n' +
                            '            "com.docker.network.driver.mtu": "1500"\n' +
                            '        },\n' +
                            '        "Labels": {}\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>

                    Inspectare retea, cu filtrare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect --format "{{.Scope}}" bridge'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'local'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect --format "{{.ID}}:{{.Name}}" bridge'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'2be808aad381d0071099ea4d164d3874171cbac8b74cbd50404b4266acac5dc9:bridge'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        1.3.5 Deconectare de la retea
                    </b>
                    <br/>
                    <br/>
                    Deconectare de la retea
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network disconnect [nume-retea] [container]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        1.3.6 Afisare IP pentru un container:
                    </b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect --format="{{range .NetworkSettings}}{{.IPAddress}}{{end}}" [container]'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'172.23.0.3'}
                    </SyntaxHighlighter>

                    Alta metoda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it a3bef0b0cc6e sh'}
                    </SyntaxHighlighter>
                    Si din container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'hostname -i'}
                    </SyntaxHighlighter>

                    Containere in acceasi retea pot comunica intre ele (se poate verifica folosind comanda <b>ping</b>).

                    <hr/>
                    <b>
                        1.3.7. Tipuri de relele
                    </b>
                    <br/>
                    <br/>

                    Tipuri de retele:
                    <ul>
                        <li>
                            <b>Host Network (eth0)</b> {"=>"} containere au acelasi IP ca si masina host;
                            <br/>
                            disponibil doar pe linux si nu este recomandat
                        </li>
                        <li>
                            <b>Bridge Network (veth {"=>"} docker0 )</b> {"=>"} containere  au IP diferite de IP-ul masinii host
                            <br/>
                            retea implicita pentru containerele docker;
                        </li>
                        <li>
                            <b>Macvlan</b> {"=>"} ofera MAC addresses in loc de adresa IP containerelor, aparand ca masini fizice
                        </li>
                        <li>
                            <b>Overlay</b> {"=>"} exista multiple host-uri, multiple containere; aici exista 2 layere:
                            <ul>
                                <li>
                                    <b>Underlay network</b> : contine informatii despre sursa si destinatia IP a masinii host
                                </li>
                                <li>
                                    <b>Overlay network</b>:  contine informatii despre sursa si festinatia IP a containerelor
                                </li>
                            </ul>
                            Docker creeaza o retea Overlay numita <b>Ingress</b> (se vede doar in mod swarm)
                        </li>
                    </ul>

                    <hr/>
                    <b>
                        2. DNS
                    </b>
                    <br/>
                    <br/>

                    Docker se foloseste de fisierul <b>/etc/resolv.conf</b> pentru a rezolva DNS.

                    <br/>
                    Pe local exista fisierul: <b>cat /run/systemd/resolve/resolv.conf</b>, care poate avea urmatorul continut:
                    <SyntaxHighlighter>
                        {'# This is /run/systemd/resolve/resolv.conf managed by man:systemd-resolved(8).\n' +
                            '# Do not edit.\n' +
                            '#\n' +
                            '# This file might be symlinked as /etc/resolv.conf. If you\'re looking at\n' +
                            '# /etc/resolv.conf and seeing this text, you have followed the symlink.\n' +
                            '#\n' +
                            '# This is a dynamic resolv.conf file for connecting local clients directly to\n' +
                            '# all known uplink DNS servers. This file lists all configured search domains.\n' +
                            '#\n' +
                            '# Third party programs should typically not access this file directly, but only\n' +
                            '# through the symlink at /etc/resolv.conf. To manage man:resolv.conf(5) in a\n' +
                            '# different way, replace this symlink by a static file or a different symlink.\n' +
                            '#\n' +
                            '# See man:systemd-resolved.service(8) for details about the supported modes of\n' +
                            '# operation for /etc/resolv.conf.\n' +
                            '\n' +
                            'nameserver 192.168.1.1\n' +
                            'nameserver 2a02:2f0c:8000:3::1\n' +
                            'nameserver 2a02:2f0c:8000:8::1\n' +
                            'search local\n'}
                    </SyntaxHighlighter>

                    Intr-un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it busybox cat /etc/resolv.conf'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'# This is /run/systemd/resolve/resolv.conf managed by man:systemd-resolved(8).\n' +
                            '# Do not edit.\n' +
                            '#\n' +
                            '# This file might be symlinked as /etc/resolv.conf. If you\'re looking at\n' +
                            '# /etc/resolv.conf and seeing this text, you have followed the symlink.\n' +
                            '#\n' +
                            '# This is a dynamic resolv.conf file for connecting local clients directly to\n' +
                            '# all known uplink DNS servers. This file lists all configured search domains.\n' +
                            '#\n' +
                            '# Third party programs should typically not access this file directly, but only\n' +
                            '# through the symlink at /etc/resolv.conf. To manage man:resolv.conf(5) in a\n' +
                            '# different way, replace this symlink by a static file or a different symlink.\n' +
                            '#\n' +
                            '# See man:systemd-resolved.service(8) for details about the supported modes of\n' +
                            '# operation for /etc/resolv.conf.\n' +
                            '\n' +
                            'nameserver 192.168.1.1\n' +
                            'search local\n'}
                    </SyntaxHighlighter>

                    La pornirea containerului se poate seta DNS prin optiunea <b>--dns</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it --dns 1.1.1.1  busybox cat /etc/resolv.conf'}
                    </SyntaxHighlighter>
                    acum va afisa:
                    <SyntaxHighlighter>
                        {'search local\n' +
                            'nameserver 1.1.1.1\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Daca un container este conectat la o retea de tip bridge, si apoi vrem sa ne conectam si la reteaua de tip host nu vom putea.
                    <br/>
                    Dar ne putem conecta direct la reteaua de tip host la rularea containerului.
                    <hr/>

                    <b>IP Address Management (IPAM)</b> este o suită integrată de instrumente pentru a permite planificarea, implementarea, gestionarea și monitorizarea de la capăt la capăt a infrastructurii adresei IP,
                    cu o experiență bogată de utilizator.
                    <br/>
                    IPAM descoperă automat serverele de infrastructură de adrese IP și serverele DNS (Domain Name System) din rețeaua dvs. și vă permite să le gestionați dintr-o interfață centrală.

                    <br/>
                    <br/>
                    <b>ICC</b> = Inter Container Communication

                    <hr/>
                    <b>Modelul rețelei de containere / Container Network Model / CNM</b>
                    <br/>
                    <br/>

                    Modelul de rețea de containere (CNM) este un standard propus de Docker.
                    A fost adoptat de multe proiecte, cum ar fi libnetwork, și oferă integrări la diverse produse,
                    inclusiv Project Calico (Calico Open Source), Cisco Contiv, Open Virtual Networking (OVN), VMware, Weave și Kuryr.

                    <br/>
                    <br/>
                    Iată caracteristicile cheie ale <b>libnetwork</b> făcute posibile prin implementarea CNM:
                    <ul>
                        <li>
                            <b>Sandbox</b> de rețea – Un mediu izolat care conține configurația de rețea a containerului.
                            <br/>
                            Funcționează ca o stivă de rețea în interiorul containerului.
                            <br/>
                            Aceasta include gestionarea interfețelor containerului, a tabelului de rutare și a setărilor DNS.
                            <br/>
                            The sandbox perfectly isolates a container from the outside world
                        </li>
                        <li>
                            <b>Endpoint</b> / puncte terminale – O interfață de rețea construită în perechi.
                            <br/>
                            Vă permite să situați un capăt al interfeței în sandbox-ul rețelei și celălalt într-o rețea desemnată.
                            Se asigură că punctele finale se alătură unei singure rețele, în timp ce mai multe puncte finale există într-un sandbox de rețea.
                            <br/>
                            Un <b>Endpoint</b> conectează un Sandbox la o rețea.
                            <br/>
                            O implementare a unui Endpoint poate fi:
                            <ul>
                                <li>
                                    o <b>veth</b> pereche
                                </li>
                                <li>
                                    un port intern Open <b>vSwitch</b> sau similar
                                </li>
                            </ul>
                            Un punct final poate aparține unei singure rețele, dar poate aparține doar unui Sandbox.
                        </li>
                        <li>
                            <b>Rețea / Network</b>  – O colecție identificabilă în mod unic de puncte terminale/endpoint-uri care au permis să comunice între ele.
                        </li>
                        <li>
                            <b>User-defined labels / Etichete definite de utilizator</b> – CNM vă permite să definiți etichete folosind label flag.
                            Aceste etichete sunt transmise ca metadate între drivere și libnetwork. Etichetele permit la rularea informare despre comportamentul driver-ului.
                        </li>
                    </ul>

                    <hr/>
                    <b>
                        Diferente in retea default-bridge si user-defined bridge networks
                    </b>
                    <br/>
                    <br/>
                    Rețelele bridge se aplică containerelor care rulează pe aceeași gazdă demon Docker.
                    <br/>
                    Când porniți Docker, o rețea de bridge implicită (numită și bridge) este creată automat și containerele nou pornite se conectează la ea, dacă nu se specifică altfel.
                    <br/>
                    De asemenea, puteți crea rețele de bridge personalizate definite de utilizator (user-defined bridge networks).
                    <br/>
                    <b>Rețelele bridge definite de utilizator sunt superioare rețelei de punte implicite</b> din următoarele motive:
                    <ul>
                        <li>
                            oferă rezoluție DNS automată între containere
                            <br/>
                            Containerele din rețeaua de punte implicită se pot accesa între ele numai prin adrese IP, cu excepția cazului în care utilizați optiunea <b>--link</b> , care este considerată moștenită.
                            <br/>
                            Într-o rețea de punte definită de utilizator, containerele se pot rezolva reciproc după nume sau alias.
                        </li>
                        <li>
                            oferă o mai bună izolare
                            <br/>
                            Folosirea unei rețele definite de utilizator oferă o rețea delimitată în care numai containerele atașate la acea rețea pot comunica.
                        </li>
                        <li>
                            containerele pot fi atașate și detașate din rețelele definite de utilizator din mers
                            <br/>
                            Pe durata de viață a unui container, îl puteți conecta sau deconecta din rețelele definite de utilizator din mers.
                            <br/>
                            Pentru a elimina un container din rețeaua de bridge implicită, trebuie să opriți containerul și să-l recreați cu diferite opțiuni de rețea.
                        </li>
                        <li>
                            fiecare rețea definită de utilizator creează o bridge configurabil
                        </li>
                        <li>
                            Containerele conectate în rețeaua bridge definite de utilizator NU partajează variabile de mediu.
                        </li>
                    </ul>
                    Link:
                    <a target={"_blank"} href={"https://docs.docker.com/network/bridge/"}>Use bridge networks</a>

                    <ul>
                        <li>
                            Containerele conectate în rețeaua bridge implicită partajează variabile de mediu.
                            <br/>
                            Inițial, singura modalitate de a partaja variabilele de mediu între două containere era să le legați folosind optiunea <b>--link</b>.
                            <br/>
                            Acest tip de partajare a variabilelor nu este posibil cu rețelele definite de utilizator. Cu toate acestea, există modalități superioare de a partaja variabilele de mediu. Câteva idei:
                            <ul>
                                <li>Mai multe containere pot monta un fișier sau un director care conține informațiile partajate, folosind un volum Docker.</li>
                                <li>Mai multe containere pot fi pornite împreună folosind docker-composeși fișierul de compunere poate defini variabilele partajate.</li>
                                <li>Puteți folosi serviciile swarm în loc de containere independente și puteți profita de secretele și configurațiile partajate .</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    Ce rețea Docker este utilizată pentru a conecta demonul Docker individual la ceilalți demoni care participă la Swarm?
                    <br/>
                    Raspuns: <b>docker_gwbridge</b>
                    <br/>
                    Explicatii:
                    <br/>
                    Când se inițializeaza un Swarm sau se adaugă o gazdă Docker la un Swarm existent, sunt create două rețele noi pe acea gazdă Docker:
                    <ul>
                        <li><b>overlay network</b> {"=>"} numita <b>ingress</b> (de intrare)
                            <br/>
                            care se ocupă de controlul și traficul de date legat de <b>serviciile Swarm</b>;
                            <br/>
                            Când creați un serviciu swarm și nu îl conectați la o rețea overlay definită de utilizator, acesta se conectează la rețeaua ingress (de intrare) în mod implicit.
                        </li>
                        <li>
                            <b>bridge network</b> {"=>"} numita <b>docker_gwbridge</b>
                            <br/>
                            conectează demonul Docker individual la ceilalți demoni care participă la roi
                        </li>
                    </ul>

                    <hr/>
                    Care opțiune pentru expunerea unui serviciu este cea mai bună dacă serviciul dvs. este accesat în mod normal prin HTTP/HTTPS?
                    <br/>
                    Raspuns: Ingress (este un obiect API Kubernetes care gestionează accesul extern la serviciile dintr-un cluster, de obicei HTTP/HTTPS)
                    <br/>
                    Explicatii:
                    <ul>
                        <li>
                            Tipul de serviciu NodePort este potrivit pentru serviciile de aplicații care necesită acces direct TCP/UDP, cum ar fi RabbitMQ, de exemplu.
                        </li>
                        <li>
                            Dacă serviciul dvs. poate fi accesat utilizând HTTP/HTTP, atunci se recomandă Ingress.
                            Ingress este un obiect API Kubernetes care gestionează accesul extern la serviciile dintr-un cluster, de obicei HTTP/HTTPS.
                        </li>
                    </ul>

                    <hr/>
                    În Docker Swarm, traficul poate fi direcționat către servicii pe baza numelui de gazdă. - DA
                    <br/>
                    The swarm mode routing mesh is great for transport-layer routing. It routes to services using the service's published ports.
                    <br/>
                    If you want to route traffic to services based on hostname instead you have to use the "Swarm Layer 7 Routing (Interlock)".
                    <br/>
                    This is a feature that enables service discovery on the application layer (L7). This Layer 7 Routing extends upon the swarm mode routing mesh by adding application layer capabilities such as inspecting the HTTP header.
                    <br/>
                    The Interlock Proxy works by using the HTTP/1.1 header field definition. Every HTTP/1.1 TCP request contains a Host: header.

                    <hr/>
                    Driverul de rețea <b>host</b> funcționează numai pe gazdele Linux și NU este acceptat pe:
                    <ul>
                        <li>
                            Docker Desktop pentru Mac
                        </li>
                        <li>
                            Docker Desktop pentru Windows
                        </li>
                        <li>
                            Docker EE pentru Windows Server
                        </li>
                    </ul>

                    <hr/>

                    Când se creeaza o rețea macvlan aceasta poate fi fie în modul <b>bridge</b>, fie în modul <b>trunk bridge 802.1q</b>:
                    <ul>
                        <li>
                            În modul bridge, traficul macvlan trece printr-un dispozitiv fizic de pe gazdă
                        </li>
                        <i>
                            În modul trunk bridge 802.1q, traficul trece printr-o sub-interfață 802.1q pe care Docker o creează din mers.
                            <br/>
                            Acest lucru vă permite să controlați rutarea și filtrarea la un nivel mai granular.
                        </i>
                    </ul>

                    <hr/>
                    Ce <b>tip de <b>driver</b> de rețea</b> este cel mai bun atunci când aveți nevoie de containere care rulează pe diferite gazde Docker pentru a comunica sau când mai multe aplicații funcționează împreună folosind serviciile swarm?
                    <br/>
                    Diferite tipuri și cazuri de utilizare pentru driverele de rețea încorporate:
                    <ul>
                        <li>
                            <b>Rețelele bridge definite de utilizator</b> sunt cele mai bune atunci când aveți nevoie de mai multe containere pentru a comunica pe aceeași gazdă Docker.
                        </li>
                        <li>
                            <b>Rețelele host</b> sunt cele mai bune atunci când stiva de rețea nu ar trebui să fie izolată de gazda Docker, dar doriți ca alte aspecte ale containerului să fie izolate.
                        </li>
                        <li>
                            <b>Rețelele de overlay</b> (scop: Swarm) sunt cele mai bune atunci când aveți nevoie de containere care rulează pe diferite gazde Docker pentru a comunica sau când mai multe aplicații funcționează împreună folosind serviciile swarm.
                        </li>
                        <li>
                            <b>Rețelele Macvlan</b> sunt cele mai bune atunci când migrați dintr-o configurație de VM sau aveți nevoie ca containerele dvs. să arate ca gazde fizice în rețea, fiecare cu o adresă MAC unică.
                        </li>
                        <li>
                            Pluginurile de <b>rețea terță</b> parte vă permit să integrați Docker cu stive de rețea specializate.
                        </li>
                    </ul>

                    <hr/>
                    Care dintre următoarele rețele este folosită ca rețea implicită pentru Kubernetes de Universal Control Plane?
                    <br/>
                    Raspuns:
                    <br/>
                    Docker Universal Control Plane (UCP) folosește <b>Calico</b> ca soluție implicită de rețea Kubernetes.
                    Calico este configurat să creeze o rețea Border Gateway Protocol (BGP) între toate nodurile din cluster.

                    <hr/>
                    Rețeaua implicită pentru serviciile swarm este <b>Ingress</b>

                    Rețeaua de Ingreess este creată fără optiunea <b>--attachable</b> ceea ce înseamnă că numai serviciile swarm o pot folosi, și nu containerele independente.

                    <br/>
                    Puteți conecta containere autonome la rețele de suprapunere definite de utilizator, care sunt create cu marcajul <b>--attachable</b>.

                    <br/>
                    <br/>
                    Mesh-ul de rutare permite tuturor nodurilor Swarm să accepte conexiuni pe porturile de servicii publicate.
                    Când orice nod din Swarm primește trafic destinat portului TCP/UDP publicat al unui serviciu care rulează,
                    acesta redirecționează traficul către VIP-ul serviciului folosind o rețea de overlay predefinită numită Ingress.
                    <br/>
                    Rețeaua de Ingreess se comportă similar cu alte rețele overlay, dar unicul său scop este de a transporta traficul de rutare mesh de la clienții externi la serviciile cluster.
                    Utilizează aceeași echilibrare internă bazată pe VIP ca cea descrisă în secțiunea anterioară.

                    <hr/>
                    What is the scope of the <b>overlay network driver</b> in Docker? Raspuns: Swarm!

                    <hr/>
                    <b> Network Scope</b>
                    <br/>
                    <br/>

                    Driverele de rețea Docker au un concept de domeniu de aplicare. Sfera de aplicare a rețelei este domeniul driverului, care poate fi domeniul:
                    <ul>
                        <li><b>locale</b>: Driverele locale oferă conectivitate și servicii de rețea (cum ar fi DNS sau IPAM) în domeniul gazdei.
                            <br/>
                            <b>Rețelele cu scopul Swarm au același ID de rețea în întregul cluster</b>
                            <b>Rețelele cu scopul locale au un ID de rețea unic pe fiecare gazdă</b></li>
                        <li><b>swarm</b>: oferă conectivitate și servicii de rețea într-un cluster de roi;
                            <br/>
                            <b>Rețelele cu scopul Swarm au același ID de rețea în întregul cluster</b>
                            <b>Rețelele cu scopul locale au un ID de rețea unic pe fiecare gazdă</b>
                        </li>
                    </ul>

                    <table className="table table-dark">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">nume</th>
                            <th scope="col">driver</th>
                            <th scope="col">scop</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>bridge</td>
                            <td>bridge</td>
                            <td>local</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>docker_gwbrige</td>
                            <td>brige</td>
                            <td>local</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>host</td>
                            <td>host</td>
                            <td>local</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>ingress</td>
                            <td>overlay</td>
                            <td>swarm</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>none</td>
                            <td>null</td>
                            <td>local</td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>
                    What network port is used on Docker host to allow overlay network traffic in swarm mode?
                    <br/>
                    Când creați rețele suprapuse, sunt câteva cerințe preliminare:
                    <ul>
                        <li>
                            Configurați regulile firewall
                            <br/>
                            Aveți nevoie de următoarele porturi deschise pentru trafic către și dinspre fiecare gazdă Docker care participă la o rețea suprapusă:
                            <ul>
                                <li>
                                    Portul TCP 2377 pentru <b>comunicațiile de gestionare a clusterelor</b>
                                </li>
                                <li>
                                    Portul TCP și UDP 7946 pentru <b>comunicarea între noduri</b>
                                </li>
                                <li>
                                    Portul UDP 4789 pentru <b>trafic</b> de rețea overlay
                                </li>
                            </ul>
                            UDP = User Datagram Protocol;
                            <ul>
                                <li>
                                    este un protocol de comunicații nesigur care transmite date de la o sursă către una sau mai multe destinații
                                </li>
                            </ul>

                            <br/>
                            TCP = Transmission Control Protocol:
                            <ul>
                                <li>
                                    asigură comunicații fiabile între două gazde
                                </li>
                            </ul>

                        </li>
                        <li>
                            Înainte de a putea crea o rețea overlay, trebuie fie să inițializați demonul Docker ca manager de swarm folosind docker swarm init,
                            fie să îl alăturați unui swarm existent folosind docker swarm join.
                            Oricare dintre acestea creează rețeaua implicită de overlay ingress, care este utilizată implicit de serviciile swarm.
                            Trebuie să faceți acest lucru chiar dacă nu intenționați să utilizați niciodată serviciile swarm.
                            Ulterior, puteți crea rețele de overlay suplimentare definite de utilizator.
                        </li>
                    </ul>

                    <hr/>

                    Driverul <b>overlay</b>:
                    <ul>
                        <li>
                            creează o rețea distribuită între mai multe gazde demon Docker
                        </li>

                        <li>
                            Această rețea se află deasupra (suprapune) rețelelor specifice gazdei, permițând containerelor conectate la ea (inclusiv containerelor de servicii swarm) să comunice în siguranță atunci când criptarea este activată
                        </li>

                        <li>
                            Docker gestionează în mod transparent rutarea fiecărui pachet către și de la gazda demonului Docker corectă și containerul de destinație corect.
                        </li>
                    </ul>

                    <hr/>
                    Which is the default method of exposing a service internally in Docker Kubernetes Service? <b>ClusterIP</b>

                    <hr/>
                    Docker containers connect by default to which of the following network drivers? <b>bridge</b>

                    <hr/>
                    Which type of network driver is best when you need multiple containers to communicate on the same Docker host? <b>Rețelele bridge definite de utilizator</b>

                    <hr/>

                    Diferite tipuri și cazuri de utilizare pentru driverele de rețea încorporate:
                    <ul>
                        <li>
                            Rețelele bridge definite de utilizator sunt cele mai bune atunci când aveți nevoie de mai multe containere pentru a comunica pe aceeași gazdă Docker.
                        </li>
                        <li>
                            Rețelele host sunt cele mai bune atunci când stiva de rețea nu ar trebui să fie izolată de gazda Docker, dar doriți ca alte aspecte ale containerului să fie izolate.
                        </li>
                        <li>
                            Rețelele de overlay sunt cele mai bune atunci când aveți nevoie de containere care rulează pe diferite gazde Docker pentru a comunica sau când mai multe aplicații funcționează împreună folosind serviciile swarm.
                        </li>
                        <li>
                            Rețelele Macvlan sunt cele mai bune atunci când migrați dintr-o configurație de VM sau aveți nevoie ca containerele dvs. să arate ca gazde fizice în rețea, fiecare cu o adresă MAC unică.
                        </li>
                        <li>
                            Pluginurile de rețea terță parte vă permit să integrați Docker cu stive de rețea specializate.
                        </li>
                    </ul>

                    <hr/>
                    Which of the following network drivers uses VXLAN data plane to decouple docker network from the underlay physical network? <b>ovelay</b>

                    <hr/>
                    Which of the following network construct aim to provide isolation for a container? <b>Sandbox</b>

                    <hr/>
                    Which docker command can be used to find all the ports mapped?
                    <ul>
                        <li>docker inspect</li>
                        <li>docker ps</li>
                    </ul>
                    Gresit: docker port {"->"} pentru ca afiseaza doar portul publicat extern
                    <hr/>
                    You have created a Docker bridge network on a host with three containers attached.
                    How can you make these containers accessible outside of the host?
                    <br/>
                    Raspuns:
                    <ul>
                        EXPOSE +  --publish-all
                    </ul>
                    <hr/>

                    Which are the primary modes that Kubernetes uses to provide service discovery?
                    <ul>
                        <li>DNS</li>
                        <li>variabile de mediu</li>
                    </ul>

                    Kubernetes acceptă două moduri principale de găsire a unui serviciu, variabilele de mediu (injectate de <b>kubelet</b> atunci când sunt create poduri) și DNS.
                    Ambele sunt opțiuni valide, totuși, cea mai comună metodă de a descoperi și a ajunge la un serviciu din cadrul Serviciului Docker Kubernetes este utilizarea serviciului DNS încorporat.

                    <hr/>
                    By default, in which mode are services running when using the routing mesh?  virtual IP (VIP)

                    <hr/>
                    <b>Când serviciile sunt create într-un cluster roi Docker, li se atribuie automat un IP virtual (VIP) care face parte din rețeaua serviciului</b>.
                    <br/>
                    VIP-ul este returnat la rezolvarea numelui serviciului: <b>la rezolvarea serviciului, este returnat VIP</b>
                    <br/>
                    <b>Traficul către VIP este trimis automat către toate sarcinile sănătoase ale serviciului respectiv în rețeaua de overlay.</b>
                    <br/>
                    Această abordare evită orice echilibrare a încărcăturii pe partea clientului, deoarece doar un singur IP este returnat clientului.
                    Docker se ocupă de rutare și distribuie în mod egal traficul între sarcinile de serviciu sănătoase.

                    <hr/>

                    When you publish a port of a service in swarm mode it is published on every node in the cluster by default? TRUE

                    <hr/>
                    Which address pool is used by Docker Swarm by default? 10.0.0.0/8

                    <hr/>
                    Which of the following commands can you use to create an overlay network for standalone containers?

                    <br/>
                    Pentru a crea o rețea de suprapunere care poate fi utilizată de serviciile swarm sau de containere autonome pentru a comunica cu alte containere autonome care rulează pe alți demoni Docker, adăugați <b>--attachable</b>:
                    <br/>
                    $ docker network create -d overlay --attachable my-attachable-overlay
                    <br/>

                    <hr/>
                    Which option is true about using the -P flag when creating a new container?
                    <br/>
                    To publish all ports, use the -P flag.
                    For example, the following command starts a container (in detached mode) and the -P flag publishes all exposed ports of the container to random ports on the host interfaces.
                    <br/>
                    $ docker run -d -P --name webserver nginx

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerNetworkResumeContent;