import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class LifecycleReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-lifecycle", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <hr/>
                    <b>1. React (componente bazate pe clase)</b>
                    <br/>
                    <br/>

                    In <b>React</b>, lifecycle pentru componente bazate pe clase:
                    <br/>
                    Faza de initializare:
                    <ul>
                        <li>
                            <b>constructor</b>:  apelat o dată când componenta este creată.
                            <br/>
                            Daca constructorul este apelat cu argumentul <b>props</b>, trebuie apelat <b>super(props);</b>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'constructor(props) {\n' +
                                    '    super(props);\n' +
                                    '    this.state = {color: "red"};\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>getDerivedStateFromProps</b> această metodă este apelată înainte ca elementele să fie redate în DOM.
                            <br/>
                            Acesta metoda ia <b>state</b> drept argument și <b>returnează un obiect cu modificări la state</b>.
                            <br/>
                            Acesta este locul potrivit pentru a seta obiectul <b>state</b> pe baza <b>props</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {' static getDerivedStateFromProps(props, state) {\n' +
                                    '    return {color: props.culoareFavorita };\n' +
                                    '  }'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>render</b>:  această metodă este chemată pentru a reda elemente în DOM.
                            <br/>
                            <b>este metoda care trimite de fapt codul HTML către DOM</b>
                        </li>
                        <li>
                            <b>componentDidMount</b>: această metodă este apelată după ce componenta este redată în DOM.
                            <br/>
                            in aceasta metoda se ruleaza instrucțiuni care necesită ca componenta să fie deja plasată în DOM.
                        </li>
                    </ul>
                    Faza de actualizare (când starea sau elementele de recuzită/props-urile se schimbă).
                    O componentă este actualizată ori de câte ori există o modificare a componentei <b>state</b> sau <b>props</b>:
                    <ul>
                        <li>
                            <b>getDerivedStateFromProps</b>: această metodă este apelată când componenta este actualizată, dar înainte ca DOM-ul să fie actualizat.
                        </li>
                        <li>
                            <b>shouldComponentUpdate</b>: această metodă vă permite să rulați logica de comparație pentru a verifica dacă ar trebui să vă actualizați DOM.
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'shouldComponentUpdate() {\n' +
                                    '    return false;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>render</b> această metodă este chemată pentru a actualiza elementele din DOM.
                        </li>
                        <li>
                            <b>getSnapshotBeforeUpdate</b>:
                            această metodă vă permite să accesați starea și elementele de recuzită înainte de actualizarea acestui ciclu.
                            <br/>
                            Această metodă trebuie să meargă mână în mână cu <b>componentDidUpdate</b>.
                            <br/>
                            În getSnapshotBeforeUpdate()metodă aveți acces la props și state înainte de actualizare, adică și după actualizare,
                            puteți verifica care au fost valorile înainte de actualizare.
                            <br/>
                            Dacă metoda <b>getSnapshotBeforeUpdate()</b> este prezentă, ar trebui să includeți și me <b>acomponentDidUpdate()</b>, altfel veți primi o eroare.

                            <br/>
                            Exemplu:
                            <SyntaxHighlighter> showLineNumbers={true} language="javascript" style={androidstudio}
                                {'class Header extends React.Component {\n' +
                                    '  constructor(props) {\n' +
                                    '    super(props);\n' +
                                    '    this.state = {favoritecolor: "red"};\n' +
                                    '  }\n' +
                                    '  componentDidMount() {\n' +
                                    '    setTimeout(() => {\n' +
                                    '      this.setState({favoritecolor: "yellow"})\n' +
                                    '    }, 1000)\n' +
                                    '  }\n' +
                                    '  getSnapshotBeforeUpdate(prevProps, prevState) {\n' +
                                    '    document.getElementById("div1").innerHTML =\n' +
                                    '    "Inainte de update, culoare a fost = " + prevState.favoritecolor;\n' +
                                    '  }\n' +
                                    '  componentDidUpdate() {\n' +
                                    '    document.getElementById("div2").innerHTML =\n' +
                                    '    "Culoare acum este = " + this.state.favoritecolor;\n' +
                                    '  }\n' +
                                    '  render() {\n' +
                                    '    return (\n' +
                                    '      <div>\n' +
                                    '        <h1>Culoare = {this.state.favoritecolor}</h1>\n' +
                                    '        <div id="div1"></div>\n' +
                                    '        <div id="div2"></div>\n' +
                                    '      </div>\n' +
                                    '    );\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>componentDidUpdate</b>: această metodă este apelată după ce componenta este actualizată în DOM.
                        </li>
                    </ul>

                    Faza de demontare:
                    <ul>
                        <li>
                            <b>componentWillUnmount</b>: această metodă este apelată o dată înainte ca React să elimine această componentă din DOM
                        </li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>
                            Componentele React de tip clasa au un obiect build-in numit <b>state</b>.
                            <br/>
                            In acest obiect se <b>stocheaza valorile proprietăților care aparțin componentei</b>.
                            <br/>
                            <b>Când obiectul state se schimbă, componenta se face render din nou.</b>
                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'class Masina extends React.Component {\n' +
                                    '  constructor(props) {\n' +
                                    '    super(props);\n' +
                                    '    this.state = {brand: "Dacie"};\n' +
                                    '  }\n' +
                                    '  render() {\n' +
                                    '    return (\n' +
                                    '      <div>\n' +
                                    '        <h1>Masina mea</h1>\n' +
                                    '      </div>\n' +
                                    '    );\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            Pentru a face referire la o proprietate din obiectul <b>state</b> se foloseste sintaxa:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'this.state.propertyname'}
                            </SyntaxHighlighter>
                            Pentru a schimbare o valore a unui proprietati de stare se foloseste functia <b>setState()</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'this.setState({brand: "Volvo"});'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. React (componente bazate pe functii)</b>
                    <br/>
                    <br/>

                    In <b>React</b>, lifecycle bazate pe functii, are 2 functii hook (<b>useState()</b> si <b>useEffect</b>):
                    <ul>
                        <li>
                            <b>useState</b> :
                            această metodă are 2 părți, primul element al matricei aparține atribuirii de inițializare
                            și al doilea element al matricei aparține actualizării primului element al matricei.
                        </li>
                        <li>
                            <b>a) useEffect()</b>: implementarea cu paranteze drepte la sfârșit, [],  va fi apelată o singură dată la inițializare:

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'class ClassComponent extends React.Component{\n' +
                                    '    componentDidMount() {\n' +
                                    '        // implementare logica\n' +
                                    '    }\n' +
                                    '    render() {\n' +
                                    '        return <h1>Hello World</h1>\n' +
                                    '    }\n' +
                                    '}\n' +
                                    '\n' +
                                    'const FunComponent = () => {\n' +
                                    '    useEffect (()=>{\n' +
                                    '        // implementare logica\n' +
                                    '    },[])\n' +
                                    '    return <h1>Hello World</h1>\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            <b>b) useEffect()</b>: implementarea fără paranteze pătrate la sfârșit va rula codul:
                            <ul>
                                <li>
                                    ori de câte ori vreo stare sau elemente de recuzită (props) se schimbă în componentă.
                                </li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'class ClassComponent extends React.Component{\n' +
                                    '    componentDidMount() {\n' +
                                    '        // implementare logica\n' +
                                    '    }\n' +
                                    '    render() {\n' +
                                    '        return <h1>Hello World</h1>\n' +
                                    '    }\n' +
                                    '}\n' +
                                    '\n' +
                                    'const FunComponent = () => {\n' +
                                    '    useEffect (()=>{\n' +
                                    '        // implementare logica\n' +
                                    '    })\n' +
                                    '    return <h1>Hello World</h1>\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            <b>c) useEffect()</b>: implementarea cu variabila între paranteze pătrate se va rula numai atunci când variabila a fost modificată sau actualizată.

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'class ClassComponent extends React.Component{\n' +
                                    '    componentDidMount() {\n' +
                                    '        if (this.props.john != prevProps.john){\n' +
                                    '            // implementare logica de update cand se modifica props\n' +
                                    '        }\n' +
                                    '    }\n' +
                                    '    render() {\n' +
                                    '        return <h1>Hello World</h1>\n' +
                                    '    }\n' +
                                    '}\n' +
                                    '\n' +
                                    'const FunComponent = ({props}) => {\n' +
                                    '    useEffect (()=>{\n' +
                                    '        // implementare logica\n' +
                                    '    },[john])\n' +
                                    '    return <h1>Hello World</h1>\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <b>d) useEffect() </b>:  funcția return callback va rula codul de curățare înainte ca componenta să fie eliminată din DOM.
                        <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                            {'class ClassComponent extends React.Component{\n' +
                                '    componentWillUnmount() {\n' +
                                '        // implementare logica de autodistrugere: muhahaha\n' +
                                '    }\n' +
                                '    render() {\n' +
                                '        return <h1>Hello World</h1>\n' +
                                '    }\n' +
                                '}\n' +
                                '\n' +
                                'const FunComponent = () => {\n' +
                                '    useEffect (()=>{\n' +
                                '        \n' +
                                '        return () => {\n' +
                                '            // implementare logica de autodistrugere: muhahaha\n' +
                                '        }\n' +
                                '    },[])\n' +
                                '    return <h1>Hello World</h1>\n' +
                                '}'}
                        </SyntaxHighlighter>
                    </ul>

                    <hr/>
                    Pentru mai multe detalii:
                    <a target={"_blank"} href={"https://javascript.plainenglish.io/angular-v-s-react-lifecycle-methods-4a9cd3b58891"}>
                        Angular vs. React — Lifecycle Methods
                    </a>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LifecycleReactContent;