import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class ExportExcelJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-export-excel", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Export Excel</b>
                    <br/>
                    <br/>

                    <hr/>

                    <b>1. Backend</b>
                    <br/>
                    <br/>

                    In POM:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<!-- https://mvnrepository.com/artifact/org.dhatim/fastexcel -->\n' +
                            '<dependency>\n' +
                            '    <groupId>org.dhatim</groupId>\n' +
                            '    <artifactId>fastexcel</artifactId>\n' +
                            '    <version>0.15.3</version>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    In Resources/REST:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@GetMapping("/data/export")\n' +
                            'public ResponseEntity<byte[]> exportData() throws IOException {\n' +
                            '    log.debug("REST request to export data : {}");\n' +
                            '\n' +
                            '    String title = "export.xlsx";\n' +
                            '\n' +
                            '    List<ExportData> allExportDataList = getExportData();\n' +
                            '\n' +
                            '    byte[] content = null;\n' +
                            '\n' +
                            '    try (ByteArrayOutputStream os = new ByteArrayOutputStream()) {\n' +
                            '        Workbook wb = new Workbook(os, "KJ - Export", "1.0");\n' +
                            '        Worksheet ws = wb.newWorksheet("Export");\n' +
                            '        ws.value(0, 0, "Tip date");\n' +
                            '        ws.value(0, 1, "Continut");\n' +
                            '\n' +
                            '        int k = 1;\n' +
                            '        for(ExportData exportData: allExportDataList){\n' +
                            '            ws.value(k, 0, exportData.getDatatype());\n' +
                            '            ws.value(k, 1, exportData.getContent());\n' +
                            '            k++;\n' +
                            '        }\n' +
                            '        wb.finish();\n' +
                            '        content = os.toByteArray();\n' +
                            '    }\n' +
                            '\n' +
                            '    return ResponseEntity.ok()\n' +
                            '        .contentType(MediaType.TEXT_XML)\n' +
                            '        .header(HttpHeaders.CONTENT_DISPOSITION, "attachment; filename=\\"" + title + "\\"")\n' +
                            '        .body(content);\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>



                    <hr/>
                    <b>2. Frontend</b>
                    <br/>
                    <br/>
                    Instalare dependinte:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm install file-saver --save\n' +
                            'npm install @types/file-saver --save-dev'}
                    </SyntaxHighlighter>

                    In HTML template:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'<button class="btn btn-dark" type="button" id="exportFile"  (click)="export()">Export</button>'}
                    </SyntaxHighlighter>

                    In TS:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'export() {\n' +
                            '  console.log("export...");\n' +
                            '  this.exportDataService.export().subscribe(file => {\n' +
                            '    FileSaver.saveAs(file, "export.xlsx");\n' +
                            '  });\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In Service:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'export(): Observable<Blob> {\n' +
                            '  return this.http.get(`${this.resourceUrl}/export`, { responseType: \'blob\' });\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://blog.ippon.tech/create-a-simple-file-storage-through-database-with-jhipster/"}>
                               Create a simple file storage through database with JHipster
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExportExcelJHipsterContent;