import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ResteasyVsHttpcomponentsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-resteasy-httpcomponents", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Resteasy Vs Httpcomponents</b>
                    <br/>
                    <br/>

                    <b>Resteasy</b>
                    <br/>
                    <br/>
                    Librarii:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'testImplementation \'org.jboss.resteasy:resteasy-netty4:4.0.0.CR2\'\n' +
                            'testImplementation \'org.jboss.resteasy:resteasy-jackson2-provider:4.0.0.CR2\''}
                    </SyntaxHighlighter>

                    Client:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'   private Client getClient()\n' +
                            '           throws NoSuchAlgorithmException, KeyManagementException {\n' +
                            '\n' +
                            '       SSLContext sc = SSLContext.getInstance("SSL");\n' +
                            '       TrustManager[] trustAllCerts = {new InsecureTrustManager()};\n' +
                            '       sc.init(null, trustAllCerts, new java.security.SecureRandom());\n' +
                            '       HostnameVerifier allHostsValid = new InsecureHostnameVerifier();\n' +
                            '\n' +
                            '       Client client = ClientBuilder.newBuilder()\n' +
                            '               .sslContext(sc)\n' +
                            '               .hostnameVerifier(allHostsValid)\n' +
                            '               .build();\n' +
                            '\n' +
                            '       return client;\n' +
                            '\n' +
                            '   }'}
                    </SyntaxHighlighter>

                    Test:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'    private <T> T get(String action, Class<T> clazz) {\n' +
                            '        try {\n' +
                            '            Response response = getClient()\n' +
                            '                    .target(getUrl(action))\n' +
                            '                    .request(MediaType.APPLICATION_JSON)\n' +
                            '                    .get();\n' +
                            '\n' +
                            '\n' +
                            '            T result = response.readEntity(clazz);\n' +
                            '            return result;\n' +
                            '\n' +
                            '        } catch (NoSuchAlgorithmException | KeyManagementException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Httpcomponents</b>
                    <br/>
                    <br/>
                    Librarii:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'implementation group: \'org.apache.httpcomponents\', name: \'httpcore\', version: \'4.4.1\'\n' +
                            'implementation group: \'org.apache.httpcomponents\', name: \'httpclient\', version: \'4.5\'\n' +
                            'implementation group: \'com.fasterxml.jackson.core\', name: \'jackson-databind\', version: \'2.9.8\''}
                    </SyntaxHighlighter>
                    Client:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'    public CloseableHttpClient getHttpClient() throws NoSuchAlgorithmException, KeyManagementException {\n' +
                            '        SSLContext sc = SSLContext.getInstance("SSL");\n' +
                            '        TrustManager[] trustAllCerts = {new InsecureTrustManager()};\n' +
                            '        sc.init(null, trustAllCerts, new java.security.SecureRandom());\n' +
                            '        SSLConnectionSocketFactory sslsf = new SSLConnectionSocketFactory(sc,\n' +
                            '                SSLConnectionSocketFactory.ALLOW_ALL_HOSTNAME_VERIFIER);\n' +
                            '        CloseableHttpClient httpclient = HttpClients.custom().setSSLSocketFactory(sslsf).build();\n' +
                            '        return httpclient;\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    Test:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private <T> T action(String action, Credentials credentials, Class<T> clazz) {\n' +
                            '\n' +
                            '    String url = getUrl(action, credentials);\n' +
                            '\n' +
                            '    HttpGet httpGet = new HttpGet(url);\n' +
                            '\n' +
                            '    boolean httpUnsecureCertificates = false;\n' +
                            '    try (CloseableHttpClient httpClient = httpUnsecureCertificates ? getHttpClient() : HttpClients.createDefault();\n' +
                            '         CloseableHttpResponse response = httpClient.execute(httpGet)) {\n' +
                            '\n' +
                            '        HttpEntity entity = response.getEntity();\n' +
                            '\n' +
                            '        if (entity != null) {\n' +
                            '            String result = EntityUtils.toString(entity);\n' +
                            '\n' +
                            '            ObjectMapper om = new ObjectMapper();\n' +
                            '            om.configure(DeserializationFeature.FAIL_ON_UNKNOWN_PROPERTIES, false);\n' +
                            '            return om.readValue(result, clazz);\n' +
                            '        }\n' +
                            '\n' +
                            '    } catch (Exception e) {\n' +
                            '        LOGGER.error("ReadFromRfidReader : " + e.getMessage());\n' +
                            '    }\n' +
                            '\n' +
                            '    return null;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu adaugare header (<b>HttpGet</b>):
                    <SyntaxHighlighter>
                        {'httpGet.addHeader(HttpHeaders.CONTENT_TYPE, MediaType.APPLICATION_JSON);\n' +
                            'httpGet.setHeader(HttpHeaders.USER_AGENT, "ApacheHttpClient");'}
                    </SyntaxHighlighter>

                    Exemplu adaugare header/parametri (<b>HttpPost</b>):
                    <SyntaxHighlighter>
                        {'StringEntity stringEntity = new StringEntity(toJson(obiectRequest), HTTP.UTF_8);\n' +
                            'HttpPost httpPost = new HttpPost(url);\n' +
                            '\n' +
                            'httpPost.addHeader(HttpHeaders.CONTENT_TYPE, MediaType.APPLICATION_JSON);\n' +
                            'httpPost.setHeader(HttpHeaders.USER_AGENT, "ApacheHttpClient");\n' +
                            'httpPost.setEntity(stringEntity);'}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter>
                        {'private String toJson(Object object) {\n' +
                            '    ObjectWriter ow = new ObjectMapper().writer().withDefaultPrettyPrinter();\n' +
                            '    String json = null;\n' +
                            '    try {\n' +
                            '        json = ow.writeValueAsString(object);\n' +
                            '    } catch (JsonProcessingException e) {\n' +
                            '        e.printStackTrace();\n' +
                            '    }\n' +
                            '    return json;\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ResteasyVsHttpcomponentsLiferayContent;