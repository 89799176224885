import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class StopDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-stop", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker stop / docker container stop</b>

                    <br/>
                    <br/>

                    Opreste un container care ruleaza (trimite un semnal pentru oprire (SIGTERM); daca nu se opreste in 10 secunde se apeleaza <b>docker kill</b> care opreste containerul imediat)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop [container]'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container stop [container]'}
                    </SyntaxHighlighter>
                    Recomandat este executare <b>docker stop</b>, in loc de <b>docker kill</b> pentru ca da posibilitatea containerului timp de 10 secunde sa elibereze de exemplu resurse.

                    <hr/>
                    Daca containerul a fost pornit cu optiunea <b>-rm</b>, atunci, nu doar ca opreste container-ul, dar il si sterge!

                    <hr/>

                    O optiune este <b>-t</b> care indica cat timp sa astepte pana trimite SIGKILL (implicit e 10 secunde):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop -t [time] [container]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop -t 30 [container]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afisa doar ID-urile (pentru continere oprite):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -aq'}
                    </SyntaxHighlighter>
                    sau
                    Pentru a afisa doar ID-urile (pentru continere oprite):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls -aq'}
                    </SyntaxHighlighter>

                    Pentru a opri toate containerele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container stop $(docker container ls -aq)'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StopDockerContent;