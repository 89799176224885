import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class KeycloakLiferayContent extends BaseContentPage{

    constructor(props) {
        super(props, "liferay-keycloak", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare Keycloak:</b>
                    <ul>
                        <li>Se descarca <a href={" https://github.com/keycloak/keycloak/releases/download/15.0.2/keycloak-15.0.2.zip"}><b>Keycloak</b> versiunea 15.0.2</a></li>
                        <li>Se dezarhiveaza (de exemplu in: cd D:\servers\keycloak-15.0.2)</li>
                        <li>Se porneste, cu comanda:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd D:\\servers\\keycloak-15.0.2\\bin\n' +
                                'standalone.bat'}
                            </SyntaxHighlighter>

                            Daca vrem sa pornim (Liferay porneste default pe portul 8080) pe alt port:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'standalone.bat -Djboss.socket.binding.port-offset=100'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Verificare: <b>http://localhost:8180/auth</b>
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-1.png'}/>
                    </div>

                </div>
                <br/>
                <div className={"text-justify important"}>
                    <b>2. Configurare Keycloak:</b>
                    <ul>
                        <li>
                            <b>Crearea utilizator de tip administrator</b>
                            <br/>
                            Keycloak nu vine cu un utilizator de administrare implicit, ceea ce înseamnă că înainte de a putea începe să utilizați Keycloak trebuie să creați un utilizator de administrare.
                            Pentru a face acest lucru, deschideți <b>http://localhost:8180/auth</b>, apoi completați formularul cu numele de utilizator și parola preferata.

                            <div style={{padding:10}}>
                                <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-2.png'}/>
                            </div>

                            Si se apasa pe butonul <b>Create</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-3.png'}/>
                            </div>
                        </li>
                        <hr/>
                        <li>
                            <b>Conectare la consola de administrare</b>
                            <br/>
                            Se apasa pe link-ul <b>Administration Console</b>. Apoi va conectati cu numele de utilizator și parola pe care le-ați creat mai devreme:

                            <div style={{padding:10}}>
                                <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-4.png'}/>
                            </div>

                            Dupa conectare:
                            <div style={{padding:10}}>
                                <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-5.png'}/>
                            </div>
                        </li>
                        <hr/>
                        <li>
                            <b>Crere realm (domeniu)</b>
                            <br/>
                            Un realm/domeniu în Keycloak permite crearea de grupuri izolate de aplicații și utilizatori.
                            <br/>
                            În mod implicit, există un singur domeniu în Keycloak numit <b>master</b>.
                            Acesta este dedicat gestionării Keycloak și <b>nu</b> ar trebui să fie folosit pentru propriile aplicații.
                            <br/>
                            Pentru crearea unui nou realm:
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se trece cu mouse-ul peste meniul drop-down din colțul din stânga sus, unde scrie <b>Master</b>, apoi faceți clic pe <b>Add realm</b>:
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-6.png'}/>
                                    </div>
                                </li>
                                <li>se completeaza formularul:
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-7.png'}/>
                                    </div>
                                    si se apasa butonul <b>Create</b>:

                                    <div style={{padding:10}}>
                                        <img  alt={""}  style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-8.png'}/>
                                    </div>
                                </li>
                            </ul>

                            Pentru Keycloak instalat pe <b>http://localhost:8180</b> si realm-ul cu numele <b>vanilla-realm</b> vom avea urmatorul URL configurare:
                            <br/> <br/>
                            <b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration
                            <br/> <br/>
                            Serviciul de mai sus returneaza urmatorul raspuns in format JSON:
                            <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                {'{\n' +
                                '   "issuer":"http://localhost:8180/auth/realms/vanilla-realm",\n' +
                                '   "authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth",\n' +
                                '   "token_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token",\n' +
                                '   "introspection_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token/introspect",\n' +
                                '   "userinfo_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/userinfo",\n' +
                                '   "end_session_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/logout",\n' +
                                '   "jwks_uri":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/certs",\n' +
                                '   "check_session_iframe":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/login-status-iframe.html",\n' +
                                '   "grant_types_supported":[\n' +
                                '      "authorization_code",\n' +
                                '      "implicit",\n' +
                                '      "refresh_token",\n' +
                                '      "password",\n' +
                                '      "client_credentials",\n' +
                                '      "urn:ietf:params:oauth:grant-type:device_code",\n' +
                                '      "urn:openid:params:grant-type:ciba"\n' +
                                '   ],\n' +
                                '   "response_types_supported":[\n' +
                                '      "code",\n' +
                                '      "none",\n' +
                                '      "id_token",\n' +
                                '      "token",\n' +
                                '      "id_token token",\n' +
                                '      "code id_token",\n' +
                                '      "code token",\n' +
                                '      "code id_token token"\n' +
                                '   ],\n' +
                                '   "subject_types_supported":[\n' +
                                '      "public",\n' +
                                '      "pairwise"\n' +
                                '   ],\n' +
                                '   "id_token_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "id_token_encryption_alg_values_supported":[\n' +
                                '      "RSA-OAEP",\n' +
                                '      "RSA-OAEP-256",\n' +
                                '      "RSA1_5"\n' +
                                '   ],\n' +
                                '   "id_token_encryption_enc_values_supported":[\n' +
                                '      "A256GCM",\n' +
                                '      "A192GCM",\n' +
                                '      "A128GCM",\n' +
                                '      "A128CBC-HS256",\n' +
                                '      "A192CBC-HS384",\n' +
                                '      "A256CBC-HS512"\n' +
                                '   ],\n' +
                                '   "userinfo_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512",\n' +
                                '      "none"\n' +
                                '   ],\n' +
                                '   "request_object_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512",\n' +
                                '      "none"\n' +
                                '   ],\n' +
                                '   "request_object_encryption_alg_values_supported":[\n' +
                                '      "RSA-OAEP",\n' +
                                '      "RSA-OAEP-256",\n' +
                                '      "RSA1_5"\n' +
                                '   ],\n' +
                                '   "request_object_encryption_enc_values_supported":[\n' +
                                '      "A256GCM",\n' +
                                '      "A192GCM",\n' +
                                '      "A128GCM",\n' +
                                '      "A128CBC-HS256",\n' +
                                '      "A192CBC-HS384",\n' +
                                '      "A256CBC-HS512"\n' +
                                '   ],\n' +
                                '   "response_modes_supported":[\n' +
                                '      "query",\n' +
                                '      "fragment",\n' +
                                '      "form_post",\n' +
                                '      "query.jwt",\n' +
                                '      "fragment.jwt",\n' +
                                '      "form_post.jwt",\n' +
                                '      "jwt"\n' +
                                '   ],\n' +
                                '   "registration_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/clients-registrations/openid-connect",\n' +
                                '   "token_endpoint_auth_methods_supported":[\n' +
                                '      "private_key_jwt",\n' +
                                '      "client_secret_basic",\n' +
                                '      "client_secret_post",\n' +
                                '      "tls_client_auth",\n' +
                                '      "client_secret_jwt"\n' +
                                '   ],\n' +
                                '   "token_endpoint_auth_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "introspection_endpoint_auth_methods_supported":[\n' +
                                '      "private_key_jwt",\n' +
                                '      "client_secret_basic",\n' +
                                '      "client_secret_post",\n' +
                                '      "tls_client_auth",\n' +
                                '      "client_secret_jwt"\n' +
                                '   ],\n' +
                                '   "introspection_endpoint_auth_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "authorization_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "authorization_encryption_alg_values_supported":[\n' +
                                '      "RSA-OAEP",\n' +
                                '      "RSA-OAEP-256",\n' +
                                '      "RSA1_5"\n' +
                                '   ],\n' +
                                '   "authorization_encryption_enc_values_supported":[\n' +
                                '      "A256GCM",\n' +
                                '      "A192GCM",\n' +
                                '      "A128GCM",\n' +
                                '      "A128CBC-HS256",\n' +
                                '      "A192CBC-HS384",\n' +
                                '      "A256CBC-HS512"\n' +
                                '   ],\n' +
                                '   "claims_supported":[\n' +
                                '      "aud",\n' +
                                '      "sub",\n' +
                                '      "iss",\n' +
                                '      "auth_time",\n' +
                                '      "name",\n' +
                                '      "given_name",\n' +
                                '      "family_name",\n' +
                                '      "preferred_username",\n' +
                                '      "email",\n' +
                                '      "acr"\n' +
                                '   ],\n' +
                                '   "claim_types_supported":[\n' +
                                '      "normal"\n' +
                                '   ],\n' +
                                '   "claims_parameter_supported":true,\n' +
                                '   "scopes_supported":[\n' +
                                '      "openid",\n' +
                                '      "phone",\n' +
                                '      "profile",\n' +
                                '      "web-origins",\n' +
                                '      "offline_access",\n' +
                                '      "email",\n' +
                                '      "roles",\n' +
                                '      "microprofile-jwt",\n' +
                                '      "address"\n' +
                                '   ],\n' +
                                '   "request_parameter_supported":true,\n' +
                                '   "request_uri_parameter_supported":true,\n' +
                                '   "require_request_uri_registration":true,\n' +
                                '   "code_challenge_methods_supported":[\n' +
                                '      "plain",\n' +
                                '      "S256"\n' +
                                '   ],\n' +
                                '   "tls_client_certificate_bound_access_tokens":true,\n' +
                                '   "revocation_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/revoke",\n' +
                                '   "revocation_endpoint_auth_methods_supported":[\n' +
                                '      "private_key_jwt",\n' +
                                '      "client_secret_basic",\n' +
                                '      "client_secret_post",\n' +
                                '      "tls_client_auth",\n' +
                                '      "client_secret_jwt"\n' +
                                '   ],\n' +
                                '   "revocation_endpoint_auth_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "backchannel_logout_supported":true,\n' +
                                '   "backchannel_logout_session_supported":true,\n' +
                                '   "device_authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth/device",\n' +
                                '   "backchannel_token_delivery_modes_supported":[\n' +
                                '      "poll",\n' +
                                '      "ping"\n' +
                                '   ],\n' +
                                '   "backchannel_authentication_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/ciba/auth",\n' +
                                '   "backchannel_authentication_request_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "require_pushed_authorization_requests":false,\n' +
                                '   "pushed_authorization_request_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/par/request",\n' +
                                '   "mtls_endpoint_aliases":{\n' +
                                '      "token_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token",\n' +
                                '      "revocation_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/revoke",\n' +
                                '      "introspection_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token/introspect",\n' +
                                '      "device_authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth/device",\n' +
                                '      "registration_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/clients-registrations/openid-connect",\n' +
                                '      "userinfo_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/userinfo",\n' +
                                '      "pushed_authorization_request_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/par/request",\n' +
                                '      "backchannel_authentication_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/ciba/auth"\n' +
                                '   }\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Serviciul de mai sus contine toate metadatele de configurare referitoare la protocol, cum ar fi:
                            <ul>
                                <li>emitent (<b>issuer</b>: http://localhost:8180/auth/realms/vanilla-realm)</li>
                                <li>URL token (<b>token_endpoint</b>: http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token)</li>
                                <li>URL de autorizare (<b>authorization_endpoint</b>: http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth)</li>
                            </ul>

                            Aceste informații vor fi utile pentru activitățile de configurare ulterioare, atât pe Keycloak, cât și pe Liferay.
                            <br/><br/>
                            <b>Observatie:</b>
                            <br/>
                            Adresa URL pe care Liferay o va folosi pentru a obține metadatele configurației (<b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration)
                            &nbsp; este important să fie <b>accesibilă</b> și ca <b>configurația certificatului SSL/TLS să fie corectă</b>.

                        </li>
                        <hr/>
                        <li>
                            <b>Creare utilizator nou</b>
                            <br/>
                            Inițial, nu există utilizatori într-un realm nou, așa că haideți să creăm unul:
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Users</b> (meniul din stanga):
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-9.png'}/>
                                    </div>
                                </li>
                                <li>se face click pe butonul <b>Add user</b> (colțul din dreapta sus al tabelului) </li>
                                <li>se completeaza formularul:
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-10.png'}/>
                                    </div>
                                    Se apasa butonul <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-11.png'}/>
                                    </div>
                                </li>

                                <li>
                                    Utilizatorul va avea nevoie de o parolă inițială setată pentru a se putea autentifica. Pentru a face acest lucru:
                                    <ul>
                                        <li>se apasa click pe <b>Credentials</b> (în partea de sus a paginii)</li>
                                        <li>se completeaza formulatul <b>Set Password</b> cu o parola
                                            <div style={{padding:10}}>
                                                <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-12.png'}/>
                                            </div>
                                        </li>
                                        <li>se seteaza pe <b>OFF</b> <b>Temporary</b> pentru a preveni actualizarea parolei la prima conectare</li>
                                        <li>se apasa <b>Set password</b>:
                                            <div style={{padding:10}}>
                                                <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-13.png'}/>
                                            </div>
                                        </li>

                                    </ul>

                                </li>

                                <li>
                                    Testare (dupa logarea cu utilizatorul creat mai sus):
                                    <br/>
                                    Logare: <b>http://localhost:8180/auth/realms/vanilla-realm/account/#/</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""}  style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-14.png'}/>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <hr/>
                        <li>
                            <b>Creare client</b>
                            <br/>
                            Clienții sunt aplicatii sau servicii care:
                            <ul>
                                <li>solicita Keycloak să <b>autentifice</b> un utilizator</li>
                                <li>doresc o solutie de tip <b>single sign-on</b> (conectare unică)</li>
                                <li>doresc doar să solicite <b>informații de identitate</b> sau un <b>token de acces</b>, astfel încât să poată invoca în siguranță alte servicii din rețea care sunt securizate de Keycloak</li>
                            </ul>
                            Pasii pentru crearea unui client nou:
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Clients</b> (meniul din stanga):
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-15.png'}/>
                                    </div>
                                </li>
                                <li>se face click pe butonul <b>Create</b> (colțul din dreapta sus al tabelului) </li>
                                <li>se completeaza formularul:
                                    <ul>
                                        <li><b>Client ID</b>: liferay-portal-client (identificatorul unic al clientului)</li>
                                        <li><b>Client Protocol</b>: openid-connect</li>
                                    </ul>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-16.png'}/>
                                    </div>
                                    Se apasa butonul <b>Save</b>. Apoi se completeaza urmatorul formular:
                                    <ul>
                                        <li><b>Client ID</b>: liferay-portal-client (identificatorul unic al clientului)</li>
                                        <li><b>Name</b>: Client OpenID Connect per Liferay Portal (descrierea clientului)</li>
                                        <li><b>Access Type</b>: <i>confidential</i></li>
                                        <li><b>Valid Redirect URIs</b>: http://localhost:8080/* (listă de URI-uri de redirecționare care trebuie considerate valide de Keycloak; sa presupunem ca pe <b>http://localhost:8080</b> este instanta de Liferay)</li>
                                    </ul>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-17.png'}/>
                                    </div>
                                    Se apasa butonul <b>Save</b>
                                </li>

                                <li> Apoi:
                                    <ul>
                                        <li>
                                            se merge in tab-ul <b>Credentials</b>
                                        </li>
                                        <li>
                                            se apasa pe butonul <b>Regenerate Secret</b> din dreptul campului <b>Secret</b>
                                        </li>
                                        <li>
                                            keycloak va generala, de exemplu, urmatorul <b>Client Secret</b> <i>0c778028-f03e-4bdd-b835-8ecb33653ea0</i>
                                        </li>
                                    </ul>

                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-18.png'}/>
                                    </div>

                                </li>

                                <li>
                                   Printre lista de clienți înregistrați pe Keycloak este disponibil si cel creat pentru integrare cu portalul Liferay (<b>liferay-portal-client</b>):
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-19.png'}/>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <hr/>
                        <li>
                            <b>Creare roluri de client</b>
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Clients</b> (meniul din stanga):</li>
                                <li>se alege un client (de exemplu, clientul <b>liferay-portal-client</b> ) din lista cu clienti (tab <b>Lookup</b>)</li>
                                <li>se alege tab-ul <b>Roles</b> si apoi se apasa butonul dreapta <b>Add Role</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-23.png'}/>
                                    </div>
                                </li>
                                <li>se completeaza formularul de adaugare rol si se apasa apoi butonul <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-24.png'}/>
                                    </div>
                                </li>
                                <li>putem adauga oricate roluri; pentru testare mai adaugam un rol <b>administrator</b>; in final vom aveam 2 roluri:
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-25.png'}/>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <hr/>
                        <li>
                            <b>Asignare roluri de client la un utilizator</b>
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Users</b> (meniul din stanga):</li>
                                <li>se alege un utilizator din lista cu utilizatori (tab <b>Lookup</b>)</li>
                                <li>se alege tab-ul <b>Roles Mappings</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-26.png'}/>
                                    </div>
                                </li>
                                <li>se selecteaza din <b>Client Roles</b>: <b>liferay-portal-client</b></li>
                                <li>se poate selecta rolul <b>administrator</b> se apasa pe butonul <b>Add selected</b></li>
                                <li>daca vrem sa stergem un rol de la utilizator, selectem rolul pe care vrem sa il retragem si apasam pe butonul <b>Remove selected</b></li>
                            </ul>
                        </li>
                        <hr/>
                        <li>
                            <b>Creare roluri de realm</b>
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Roles</b> (meniul din stanga) si fiind pe tab-ul <b>Realm roles</b> se apasa pe butonul <b>Add Role</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-27.png'}/>
                                    </div>
                                </li>
                                <li>se completeaza formularul de adaugare rol si se apasa apoi butonul <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-28.png'}/>
                                    </div>
                                </li>
                                <li>dupa salvare, din tabul <b>Details</b> se activeaza (ON) <b>Composite Roles</b>, apoi
                                    din <b>Client Roles</b> se selecteaza <b>liferay-portal-client</b>, apoi se selecteaza rolul <b>administator</b> si se apasa butonul <b>Add selected</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-29.png'}/>
                                    </div>
                                </li>
                                <li>similar, vom face si rolul de realm <b>utilizator-realm</b>; ca in final in tabelul de <b>Realm Roles</b> sa existe si cele doua roluri de realm create mai sus: <b>administrator-realm</b> si <b>utilizator-realm</b>
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-30.png'}/>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <hr/>
                        <li>
                            <b>Asignare roluri de realm la un utilizator</b>
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Users</b> (meniul din stanga):</li>
                                <li>se alege un utilizator din lista cu utilizatori (tab <b>Lookup</b>)</li>
                                <li>se alege tab-ul <b>Roles Mappings</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-31.png'}/>
                                    </div>
                                </li>
                                <li>se selecteaza din sectiunea de <b>Realms Roles</b> selectam un rol de realm, de exemplu <b>administrator-realm</b> si se apasa butonul <b>Add selected</b></li>
                                <li>daca vrem sa stergem un rol de realm de la utilizator, selectem rolul de realm pe care vrem sa il retragem si apasam pe butonul <b>Remove selected</b></li>
                            </ul>
                        </li>
                        <hr/>
                        <li>
                            <b>Configurare Identity Provider</b>
                            <br/>
                            <ul>

                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Identity Providers</b> (meniul din stanga):
                                    <div style={{padding:10}}>
                                        <img  alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-20.png'}/>
                                    </div>
                                </li>
                                <li>se selecteaza <b>Keycloak OpenID Connect</b> la <b>Add provider...</b></li>
                                <li>se completeaza formularul <b>Add identity provider</b>
                                    <ul>
                                        <li><b>Alias</b>:vanilla-keycloak-oidc</li>
                                        <li><b>Display Name</b>:Keycloak OpenID Connect Identity Provider
                                            <div style={{padding:10}}>
                                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-21.png'}/>
                                            </div>
                                        </li>
                                        <li>
                                           se continua completarea formularului si pentru sectiunea <b>OpenID Connect Config</b> cu informatii obtinute:
                                            <ul>
                                                <li>pe baza serviciului <b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration </li>
                                            </ul>
                                           astfel:
                                           <ul>
                                               <li>
                                                   <b>Authorization URL</b>: <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth</i>
                                               </li>
                                               <li>
                                                   <b>Token URL</b>: <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token</i>
                                               </li>
                                               <li>
                                                   <b>Logout URL</b>:<i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/logout</i>
                                               </li>
                                               <li>
                                                   <b>Client Authentication</b>:<i>Client secret sent as post</i>
                                               </li>
                                               <li>
                                                   <b>Client ID</b>:<i>liferay-portal-client</i>
                                               </li>
                                               <li>
                                                   <b>Client Secret</b>:<i>0c778028-f03e-4bdd-b835-8ecb33653ea0</i>

                                                   <div style={{padding:10}}>
                                                       <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                                            src={process.env.PUBLIC_URL + '/img/liferay/keycloak-22.png'}/>
                                                   </div>
                                               </li>
                                               <li>se apasa butonul <b>Save</b></li>
                                           </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KeycloakLiferayContent;