import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class InstallKubernetesContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare Kubernetes (k8s)</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1.1. Instalare Minikube pe Linux</b>
                    <br/>
                    <br/>

                    Minikube creeaza un <b>cluster</b> Kubernates.

                    <br/>
                    Pentru a folosi <b>minikube</b> este nevoie de o masina virtuala sau container manager sau hipervizor, de exemplu:
                    <ul>
                        <li>VirtualBox (recomand pentru MacOS)</li>
                        <li>VMware</li>
                        <li>Docker (recomandat pentru Linux)</li>
                        <li>Hyper-V (recomandat pentru Windows)</li>
                        <li>Parallels</li>
                    </ul>

                    Descarcare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'curl -LO https://storage.googleapis.com/minikube/releases/latest/minikube-linux-amd64'}
                    </SyntaxHighlighter>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo install minikube-linux-amd64 /usr/local/bin/minikube'}
                    </SyntaxHighlighter>

                    Pornire (se creeaza o masina virtuala pe calculator; ne vom referi la aceasta masina virtuala care ruleaza pe calculator ca fiind un <b>node</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>
                    Comanda de mai sus creeaza un <b>cluster</b>!
                    <br/>
                    La pornire se poate specifica masina virtuala sau container manager-ul, folosind <b>--driver</b>, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start --driver=hyperv'}
                    </SyntaxHighlighter>
                    sau (trebuie avut pe masina instalat VirtualBox)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start --driver=virtualbox'}
                    </SyntaxHighlighter>
                    (cluster-ul se va creea in masina virtuala VirtualBox; in cluster sa va instala master node si tot ce e nevoie pentru worker nodes )
                    <br/>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start --driver=docker'}
                    </SyntaxHighlighter>
                    (cluster-ul se va creea intr-un container Docker)

                    <br/>
                    <br/>
                    Pentru a porni <i>minikube</i> cu alt <i>container-runtime</i> (adica, nu Docker, ci CRI-O sau containerd):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start --driver=virtualbox --container-runtime=cri-o'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start --driver=virtualbox --container-runtime=containerd'}
                    </SyntaxHighlighter>

                    <hr/>
                    Verificare <b>minikube status</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>

                    va afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Running\n' +
                            'kubelet: Running\n' +
                            'apiserver: Running\n' +
                            'kubeconfig: Configured'}
                    </SyntaxHighlighter>

                    <hr/>
                    Help <b>minikube</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube --help'}
                    </SyntaxHighlighter>

                    <hr/>
                    Versiune <b>minikube</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube version: v1.27.1\n' +
                            'commit: fe869b5d4da11ba318eb84a3ac00f336411de7ba'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare detalii <b>minikube</b> (driver, ip, port, versiune, status, noduri):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube profile list'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| Profile  | VM Driver | Runtime |      IP      | Port | Version | Status  | Nodes | Active |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| minikube | docker    | docker  | 192.168.49.2 | 8443 | v1.25.2 | Running |     1 | *      |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a afla IP-ul (<b>minikube ip</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube ip'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'192.168.49.2'}
                    </SyntaxHighlighter>

                    <hr/>
                    Ne putem conecta la masina <b>192.168.49.2</b> prin <b>ssh</b> cu utilizatorul <b>docker</b>, parola <b>tcuser</b>:
                    <SyntaxHighlighter>
                        {'ssh docker@192.168.49.2'}
                    </SyntaxHighlighter>
                    Daca <b>--driver=docker</b>, atunci conectarea la masina <b>192.168.49.2</b> prin <b>ssh</b> se face cu urmatoarea comanda:
                    <SyntaxHighlighter>
                        {'minikube ssh'}
                    </SyntaxHighlighter>
                    pentru ca comanda <b>ssh docker@IP_minikube</b> nu functioneaza!kub

                    <hr/>

                    Pentru a afisa informatii legate de mediul docker:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube docker-env'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'export DOCKER_TLS_VERIFY="1"\n' +
                            'export DOCKER_HOST="tcp://192.168.49.2:2376"\n' +
                            'export DOCKER_CERT_PATH="/home/kj/.minikube/certs"\n' +
                            'export MINIKUBE_ACTIVE_DOCKERD="minikube"\n' +
                            '\n' +
                            '# To point your shell to minikube\'s docker-daemon, run:\n' +
                            '# eval $(minikube -p minikube docker-env)'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a seta in terminal ca docker sa se contecteze la docker-ul din kubernates node:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'eval $(minikube -p minikube docker-env)'}
                    </SyntaxHighlighter>
                    acum daca vom scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    se vor afisa informatii din masina virtuala/nodul kubernates:
                    <SyntaxHighlighter>
                        {'d65ab6430a31   iulianbuzdugan/multi-client   "/docker-entrypoint.…"   2 hours ago   Up 2 hours             k8s_client_client-deployment-8d9b8cdd6-crc4m_default_8f1b153b-5d6e-43c8-8a58-9fbfe3ec092e_0\n' +
                            'aef026a96417   k8s.gcr.io/pause:3.6          "/pause"                 2 hours ago   Up 2 hours             k8s_POD_client-deployment-8d9b8cdd6-crc4m_default_8f1b153b-5d6e-43c8-8a58-9fbfe3ec092e_0\n' +
                            '1658436bc19d   5185b96f0bec                  "/coredns -conf /etc…"   2 days ago    Up 2 days              k8s_coredns_coredns-565d847f94-nbtz6_kube-system_1554c8bf-326b-40ee-a930-9c826e20f943_1\n' +
                            '0c3312ca3c9f   6e38f40d628d                  "/storage-provisioner"   2 days ago    Up 2 days              k8s_storage-provisioner_storage-provisioner_kube-system_93b1914e-0478-4e94-9d9f-d164f7b0edea_2\n' +
                            '0cbc93e856cb   1c7d8c51823b                  "/usr/local/bin/kube…"   2 days ago    Up 2 days              k8s_kube-proxy_kube-proxy-q5f5f_kube-system_50000b2b-9f35-477b-ac12-4858b9d0499a_1\n' +
                            'a48e4920cfac   dbfceb93c69b                  "kube-controller-man…"   2 days ago    Up 2 days              k8s_kube-controller-manager_kube-controller-manager-minikube_kube-system_2e75927e0fd852156c6691ad3046836e_7\n' +
                            '5ed9fb6de7df   97801f839490                  "kube-apiserver --ad…"   2 days ago    Up 2 days              k8s_kube-apiserver_kube-apiserver-minikube_kube-system_0043f0e06f8033573472a91b2322f830_3\n' +
                            'a34cd0cbaf0b   a8a176a5d5d6                  "etcd --advertise-cl…"   2 days ago    Up 2 days              k8s_etcd_etcd-minikube_kube-system_bd495b7643dfc9d3194bd002e968bc3d_2\n' +
                            '95ea7c0c99a4   ca0ea1ee3cfd                  "kube-scheduler --au…"   2 days ago    Up 2 days              k8s_kube-scheduler_kube-scheduler-minikube_kube-system_d9e39786b2e03d044abb6c68064d5241_2\n' +
                            'd4235e7fc688   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_etcd-minikube_kube-system_bd495b7643dfc9d3194bd002e968bc3d_3\n' +
                            'f4a9fe845388   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_kube-scheduler-minikube_kube-system_d9e39786b2e03d044abb6c68064d5241_3\n' +
                            '6f13a7ab6c6b   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_kube-controller-manager-minikube_kube-system_2e75927e0fd852156c6691ad3046836e_3\n' +
                            '6c39b88b9a10   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_kube-apiserver-minikube_kube-system_0043f0e06f8033573472a91b2322f830_2\n' +
                            'f4f529b2fdc0   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_kube-proxy-q5f5f_kube-system_50000b2b-9f35-477b-ac12-4858b9d0499a_1\n' +
                            '5c3f6388c45a   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_storage-provisioner_kube-system_93b1914e-0478-4e94-9d9f-d164f7b0edea_1\n' +
                            'e53e26180a09   k8s.gcr.io/pause:3.6          "/pause"                 2 days ago    Up 2 days              k8s_POD_coredns-565d847f94-nbtz6_kube-system_1554c8bf-326b-40ee-a930-9c826e20f943_1'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a opri <i>minikube</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube stop'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge <i>minikube</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube delete'}
                    </SyntaxHighlighter>

                    <hr/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube dashboard'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Instalare kubectl</b>
                    <br/>
                    <br/>

                    <b>kubectl</b> este un tool prin intermediul caruia se poate poate transmite comezi catre clusterul / master node k8s.

                    <br/>
                    <br/>
                    Descarcare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'curl -LO "https://dl.k8s.io/release/$(curl -L -s https://dl.k8s.io/release/stable.txt)/bin/linux/amd64/kubectl"'}
                    </SyntaxHighlighter>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo install -o root -g root -m 0755 kubectl /usr/local/bin/kubectl'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl version'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'WARNING: This version information is deprecated and will be replaced with the output from kubectl version --short.  Use --output=yaml|json to get the full version.\n' +
                            'Client Version: version.Info{Major:"1", Minor:"25", GitVersion:"v1.25.3", GitCommit:"434bfd82814af038ad94d62ebe59b133fcb50506", GitTreeState:"clean", BuildDate:"2022-10-12T10:57:26Z", GoVersion:"go1.19.2", Compiler:"gc", Platform:"linux/amd64"}\n' +
                            'Kustomize Version: v4.5.7\n' +
                            'Server Version: version.Info{Major:"1", Minor:"25", GitVersion:"v1.25.2", GitCommit:"5835544ca568b757a8ecae5c153f317e5736700e", GitTreeState:"clean", BuildDate:"2022-09-21T14:27:13Z", GoVersion:"go1.19.1", Compiler:"gc", Platform:"linux/amd64"}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afla informatii despre cluster:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl cluster-info'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Kubernetes control plane is running at https://192.168.49.2:8443\n' +
                            'CoreDNS is running at https://192.168.49.2:8443/api/v1/namespaces/kube-system/services/kube-dns:dns/proxy\n' +
                            '\n' +
                            'To further debug and diagnose cluster problems, use \'kubectl cluster-info dump\'.\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa nodurile:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get nodes'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       STATUS   ROLES           AGE   VERSION\n' +
                            'minikube   Ready    control-plane   41d   v1.25.2\n'}
                    </SyntaxHighlighter>
                    Deci, exista un singur nod, numit <i>minikube</i>


                    <hr/>
                    <b>1.3 Instalare kubectl + Minikube pe Windows</b>
                    <br/>
                    <br/>

                    Pentru a verifica daca pe Windows se poate instala <b>minikube</b> se ruleaza comada <b>systeminfo</b>; daca se afiseaza ceva similar, atunci virtualizarea este suportata pe Windows si se poate instala <b>minikube</b>
                    <SyntaxHighlighter>
                        {'Hyper-V Requirements:      VM Monitor Mode Extensions: Yes\n' +
                            '                           Virtualization Enabled In Firmware: Yes\n' +
                            '                           Second Level Address Translation: Yes\n' +
                            '                           Data Execution Prevention Available: Yes'}
                    </SyntaxHighlighter>

                    Pentru putem instala <b>Chocolatery</b>
                    <SyntaxHighlighter>
                        {'https://chocolatey.org/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'https://chocolatey.org/install'}
                    </SyntaxHighlighter>

                    Ca administrator, din Powershell, se ruleaza comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Set-ExecutionPolicy Bypass -Scope Process -Force; [System.Net.ServicePointManager]::SecurityProtocol = [System.Net.ServicePointManager]::SecurityProtocol -bor 3072; iex ((New-Object System.Net.WebClient).DownloadString(\'https://community.chocolatey.org/install.ps1\'))'}
                    </SyntaxHighlighter>

                    Verificare instalare <b>choco</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'choco'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Chocolatey v1.2.1\n' +
                            'Please run \'choco -?\' or \'choco <command> -?\' for help menu.'}
                    </SyntaxHighlighter>

                    Instalare <b>kubectl</b> pe Windows:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'choco install kubernetes-cli'}
                    </SyntaxHighlighter>

                    Verificare instalare <b>kubectl</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl version --client'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Client Version: version.Info{Major:"1", Minor:"22", GitVersion:"v1.22.5", GitCommit:"5c99e2ac2ff9a3c549d9ca665e7bc05a3e18f07e", GitTreeState:"clean", BuildDate:"2021-12-16T08:38:33Z", GoVersion:"go1.16.12", Compiler:"gc", Platform:"windows/amd64"}'}
                    </SyntaxHighlighter>

                    Apoi instalare :
                    <ul>
                        <li>
                            navigare in directorul home (din cmd):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'cd %USERPROFILE%'}
                            </SyntaxHighlighter>
                            sau (din powershell)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'cd ~'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            creare director <b>.kube</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'mkdir .kube'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            schimbare director in <b>.kube</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'cd .kube'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se creaza un fisier cu numele <b>config</b>, pentru configurare  <b>kubectl</b> pentru a utiliza un cluster Kubernetes la distanta (din powershell):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'New-Item config -type file'}
                            </SyntaxHighlighter>
                            sau pur si simplu se creeaza un fisier cu numele <b>config</b>
                        </li>
                    </ul>
                    La executia unei comenzi kubectl se poate specifica fisierul de configurare - generat de Digital Ocean, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubctl "digial-ocean-k8s-kubecongig.yaml" get nodes'}
                    </SyntaxHighlighter>
                    alternativ, ca mai sus:
                    <ul>
                        <li>se copiaza fisierul <i>digial-ocean-k8s-kubecongig.yaml</i> in directorul: <i>[directorul-home-al-utilizatorului]\<b>.kube</b></i></li>
                        <li>se redenumeste in <b>config</b> (fara extensie)</li>
                    </ul>

                    <hr/>

                    Pentru a instala <b>minikube</b>:
                    <ul>
                        <li>
                            se instaleaza <b>VirtualBox</b>
                        </li>
                        <li>
                            se instaleaza <b>minikube</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'choco install minikube'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se porneste <b>minikube</b>, folosind ca driver <b>VirtualBox</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'minikube start --driver=virtualbox'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            verificare <b>minikube</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'minikube status'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>1.4. Cateva cuvinte despre kubernetes</b>
                    <br/>
                    <br/>
                    Kubernates:
                    <ul>
                        <li>este un sistem de deploy pentru aplicatii containerizate</li>
                        <li>nu build-eaza imagini</li>
                        <li>kubernates (master) decide unde va rula fiecare container - fiecare nod poate rula o multime de containere
                            <br/>
                            (nu se poate specifica. in mod implicit, pe ce nod sa ruleze un anumit container; acest lucru este gestionat de kubernates)
                        </li>
                    </ul>
                    <b>Nodurile</b> sunt <i>masini individuale sau masini virtuale</i> care ruleaza <b>containere</b>.
                    <br/>
                    <b>Masters</b> sunt <i>masini sau masini virtuale</i> cu <b>set de programe care gestioneaza nodurile</b>.
                    <br/>
                    Pentru a face <i>deploy</i> la ceva, se actualizeaza starea de pe <i>master</i> cu ajutorul unui fisier de condigurare.
                    <br/>
                    Master ruleaza in mod contant pentru a mentine starea dorita (daca un container crapa, va incerca sa il reporneasca).

                    <hr/>
                    Moduri de deployment:
                    <ul>
                        <li>imperativ
                            <br/>
                            de exemplu, avem 4 containere si vrem sa stergem unu dintre ele
                        </li>
                        <li>declarativ
                            <br/>
                            prin folosirea de fisiere de configurare
                        </li>
                    </ul>

                    <hr/>
                    <b>1.5. Observatii</b>
                    <br/>
                    <br/>

                    Pentru a porni cu mai putin memorie:
                    <SyntaxHighlighter>
                        {'minikube start --driver=hyperv --memory 2200'}
                    </SyntaxHighlighter>
                    sau:

                    <SyntaxHighlighter>
                        {'Disable-WindowsOptionalFeature -Online -FeatureName Microsoft-Hyper-V-Hypervisor'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'minikube start --vm-driver=virtualbox --memory=2200'}
                    </SyntaxHighlighter>

                    Crearea alias in PowerShell:
                    <SyntaxHighlighter>
                        {'Set-Alias k kubectl'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://minikube.sigs.k8s.io/docs/start/"}>minikube start</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallKubernetesContent;