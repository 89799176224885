import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ServicesBySingletonReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-service-by-singleton", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Servicii folosind Content</b>
                    <br/>
                    <br/>


                    Exemplu singleton:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, { useState } from \'react\';\n' +
                            '\n' +
                            'const CounterService = (function () {\n' +
                            '  let instance;\n' +
                            '\n' +
                            '  function createInstance() {\n' +
                            '    const [count, setCount] = useState(0);\n' +
                            '\n' +
                            '    return {\n' +
                            '      count,\n' +
                            '      setCount,\n' +
                            '      increment: () => setCount(count + 1),\n' +
                            '      decrement: () => setCount(count - 1),\n' +
                            '    };\n' +
                            '  }\n' +
                            '\n' +
                            '  return {\n' +
                            '    getInstance: function () {\n' +
                            '      if (!instance) {\n' +
                            '        instance = createInstance();\n' +
                            '      }\n' +
                            '      return instance;\n' +
                            '    },\n' +
                            '  };\n' +
                            '})();\n' +
                            '\n' +
                            'export default CounterService;'}
                    </SyntaxHighlighter>

                    Exemplu non-Singleton:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, { useState } from \'react\';\n' +
                            '\n' +
                            'function CounterService() {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '\n' +
                            '  return {\n' +
                            '    count,\n' +
                            '    setCount,\n' +
                            '    increment: () => setCount(count + 1),\n' +
                            '    decrement: () => setCount(count - 1),\n' +
                            '  };\n' +
                            '}\n' +
                            '\n' +
                            'export default CounterService;'}
                    </SyntaxHighlighter>

                    Mod de a folosi serviciul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, { useState, useEffect } from \'react\';\n' +
                            'import counterService from \'./counterService\';\n' +
                            '\n' +
                            'const Counter = () => {\n' +
                            '    const [count, setCount] = useState(0);\n' +
                            '    const service = counterService();\n' +
                            '    \n' +
                            '    useEffect(() => {\n' +
                            '        setCount(service.getCount());\n' +
                            '    }, [service]);\n' +
                            '    \n' +
                            '    return (\n' +
                            '        <div>\n' +
                            '            <h1>Counter: {count}</h1>\n' +
                            '            <button onClick={() => {\n' +
                            '                service.increment();\n' +
                            '                setCount(service.getCount());\n' +
                            '            }}>\n' +
                            '                Increment\n' +
                            '            </button>\n' +
                            '            <button onClick={() => {\n' +
                            '                service.decrement();\n' +
                            '                setCount(service.getCount());\n' +
                            '            }}>\n' +
                            '                Decrement\n' +
                            '            </button>\n' +
                            '            <button onClick={() => {\n' +
                            '                service.reset();\n' +
                            '                setCount(service.getCount());\n' +
                            '            }}>\n' +
                            '                Reset\n' +
                            '            </button>\n' +
                            '        </div>\n' +
                            '    );\n' +
                            '};\n' +
                            '\n' +
                            'export default Counter;'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.freakyjolly.com/react-how-to-create-services-and-consume-in-components-with-examples/"}>
                               React : How to Create Services and Consume in Components with Examples
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServicesBySingletonReactContent;