import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class StateReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-state", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. State</b>
                    <br/>
                    <br/>

                    Initializarea starilor se poate face in constructor:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {
                            'this.state = {\n' +
                            '  prop1: val1\n' +
                            '  prop2: val2\n' +
                            ' ...\n' +
                            '  propN: valN\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {
                            ' constructor(props) {\n' +
                            '        super(props);\n' +
                            '        this.state = {\n' +
                            '            robots: robots,\n' +
                            '            searchField: \'\'\n' +
                            '        }\n' +
                            '    }'
                        }
                    </SyntaxHighlighter>

                    Accesarea unui stari:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {
                            'this.state.prop1'
                        }
                    </SyntaxHighlighter>

                    Schimbarea unui stari se face cu ajutorul functiei <b>setState()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'this.setState({\'searchField\': value});'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, { Component } from \'react\';\n' +
                            'import CardList from "./CardList";\n' +
                            '\n' +
                            'import SearchBox from "./SearchBox";\n' +
                            '\n' +
                            'import {robots} from "../mocks/robots";\n' +
                            '\n' +
                            'class RoboApp extends Component{\n' +
                            '\n' +
                            '    constructor(props) {\n' +
                            '        super(props);\n' +
                            '        this.state = {\n' +
                            '            robots: robots,\n' +
                            '            searchField: \'\'\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    onSearchChange = (event) => {\n' +
                            '        let value = event.target.value;\n' +
                            '        this.setState({\'searchField\': value}); // asa se schimba starea\n' +
                            '    }\n' +
                            '\n' +
                            '    render() {\n' +
                            '\n' +
                            '        const filteredRobots = this.state.robots.filter(robots =>{\n' +
                            '            return robots.name.toLowerCase().includes(this.state.searchField.toLowerCase())\n' +
                            '        })\n' +
                            '\n' +
                            '        return(\n' +
                            '            <div className="tc">\n' +
                            '                <h1>Prietenii Roboti</h1>\n' +
                            '                <SearchBox searchChange={this.onSearchChange}/>\n' +
                            '                <CardList robots={filteredRobots}/>\n' +
                            '            </div>\n' +
                            '        )\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'export default RoboApp;'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StateReactContent;