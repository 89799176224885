import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class CloudApiGatewaySpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-cloud-api-gateway", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare Spring Cloud API Gateway</b>

                    <br/>
                    <br/>


                    Sa presupunem ca avem 2 servicii:
                    <ul>
                        <li>
                            <i>student-service</i>
                        </li>
                        <li>
                            <i>address-service</i>
                        </li>
                    </ul>
                    Si un <i>consumer</i> care poate apela servicii.
                    Pentru a putea apela trebuie sa gestionam pentru fiecare serviciu de exemplu autentificare.
                    <br/>
                    Acest lucru, pentru a nu duplica in fiecare serviciu, se face prin intermediul lui <b>Spring Cloud API Gateway</b>.

                    <hr/>

                    Se va creea un nou proiect <i>Spring Boot</i>: <b>api-gateway</b>.

                    <br/>
                    <br/>

                    Fisierul <b>build.gradle</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'org.springframework.boot\' version \'2.7.4\'\n' +
                            '    id \'io.spring.dependency-management\' version \'1.0.14.RELEASE\'\n' +
                            '    id \'java\'\n' +
                            '}\n' +
                            '\n' +
                            'group = \'ro.ibrid.learn.microservices.spring\'\n' +
                            'version = \'0.0.1-SNAPSHOT\'\n' +
                            'sourceCompatibility = \'11\'\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '}\n' +
                            '\n' +
                            'ext {\n' +
                            '    set(\'springCloudVersion\', "2021.0.4")\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '    implementation \'org.springframework.cloud:spring-cloud-starter-gateway\'\n' +
                            '    implementation \'org.springframework.cloud:spring-cloud-starter-netflix-eureka-client\'\n' +
                            '    testImplementation \'org.springframework.boot:spring-boot-starter-test\'\n' +
                            '}\n' +
                            '\n' +
                            'dependencyManagement {\n' +
                            '    imports {\n' +
                            '        mavenBom "org.springframework.cloud:spring-cloud-dependencies:${springCloudVersion}"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\') {\n' +
                            '    useJUnitPlatform()\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Fisierul <i>application.properties</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'server.port=9090\n' +
                            '\n' +
                            'spring.application.name=api-gateway\n' +
                            '\n' +
                            'eureka.client.service-url.defaultZone = http://localhost:8761/eureka\n' +
                            '\n' +
                            '#activare DiscoveryClient gateway\n' +
                            'spring.cloud.gateway.discovery.locator.enabled=true\n' +
                            '\n' +
                            '#ingora daca numele de servicii sunt scrise cu litere mari\n' +
                            'spring.cloud.gateway.discovery.locator.lower-case-service-id=true'}
                    </SyntaxHighlighter>

                    Clasa <i>ApiGatewayApplication</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>{
                        'package ro.ibrid.learn.microservices.spring.app;\n' +
                        '\n' +
                        'import org.springframework.boot.SpringApplication;\n' +
                        'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                        'import org.springframework.cloud.netflix.eureka.EnableEurekaClient;\n' +
                        '\n' +
                        '@SpringBootApplication\n' +
                        '@EnableEurekaClient\n' +
                        'public class ApiGatewayApplication {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '        SpringApplication.run(ApiGatewayApplication.class, args);\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'
                    }</SyntaxHighlighter>

                    <hr/>

                    Testare:
                    <ul>
                        <li>se porneste eureka-server</li>
                        <li>se porneste api-gateway</li>
                        <li>se porneste address-service</li>
                        <li>se porneste student-service</li>
                        <li>
                            in loc de:
                            <SyntaxHighlighter>
                                {'GET http://localhost:9092/api/student/getById/1'}
                            </SyntaxHighlighter>
                            se poate folosi (pentru a trece prin api-gateway):
                            <SyntaxHighlighter>
                                {'GET http://localhost:9090/student-service/api/student/getById/1'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Dacă apare o eroare precum „java.net.UnknownHostException: failed to resolve....” atunci trebuie adăugat
                            in <i>application.properties</i> pentru <i>address-service</i> si <i>student-service</i> proprietatea
                            mai jos proprietatea în student și adresați microservicii.

                            <SyntaxHighlighter>
                                {'eureka.instance.hostname=localhost'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Prefiltru</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.app;\n' +
                            '\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.cloud.gateway.filter.GatewayFilterChain;\n' +
                            'import org.springframework.cloud.gateway.filter.GlobalFilter;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.http.server.reactive.ServerHttpRequest;\n' +
                            'import org.springframework.web.server.ServerWebExchange;\n' +
                            'import reactor.core.publisher.Mono;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class CustomFilter implements GlobalFilter {\n' +
                            '\n' +
                            '    Logger logger = LoggerFactory.getLogger(CustomFilter.class);\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Mono<Void> filter(ServerWebExchange exchange, GatewayFilterChain chain) {\n' +
                            '\n' +
                            '        ServerHttpRequest request = exchange.getRequest();\n' +
                            '\n' +
                            '        logger.info("AuthKJ = " + request.getHeaders().getFirst("AuthKJ"));\n' +
                            '\n' +
                            '        return chain.filter(exchange);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter>
                        {'GET http://localhost:9090/student-service/api/student/getById/1\n' +
                            'AuthKJ: 123'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Postfiltru</b>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.app;\n' +
                            '\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.cloud.gateway.filter.GatewayFilterChain;\n' +
                            'import org.springframework.cloud.gateway.filter.GlobalFilter;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.http.server.reactive.ServerHttpRequest;\n' +
                            'import org.springframework.http.server.reactive.ServerHttpResponse;\n' +
                            'import org.springframework.web.server.ServerWebExchange;\n' +
                            'import reactor.core.publisher.Mono;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class CustomFilter implements GlobalFilter {\n' +
                            '\n' +
                            '    Logger logger = LoggerFactory.getLogger(CustomFilter.class);\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Mono<Void> filter(ServerWebExchange exchange, GatewayFilterChain chain) {\n' +
                            '\n' +
                            '        ServerHttpRequest request = exchange.getRequest();\n' +
                            '\n' +
                            '        logger.info("AuthKJ = " + request.getHeaders().getFirst("AuthKJ"));\n' +
                            '\n' +
                            '        // prefilter\n' +
                            '        // return chain.filter(exchange);\n' +
                            '\n' +
                            '        // postfilter\n' +
                            '\n' +
                            '        return chain.filter(exchange).then(Mono.fromRunnable(()->{\n' +
                            '\n' +
                            '            ServerHttpResponse response = exchange.getResponse();\n' +
                            '\n' +
                            '            logger.info("Post Filter = " + response.getStatusCode());\n' +
                            '\n' +
                            '        }));\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se poate restarta doar <b>api-gateway</b>!

                    In urma testari se va afisa:
                    <SyntaxHighlighter>
                        {'2022-09-29 12:03:14.389  INFO 26912 --- [ctor-http-nio-2] r.i.l.m.spring.app.CustomFilter          : AuthKJ = 1234\n' +
                            '2022-09-29 12:03:14.911  INFO 26912 --- [ctor-http-nio-2] r.i.l.m.spring.app.CustomFilter          : Post Filter = 200 OK'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Feign Client cu Api Gateway</b>
                    <br/>
                    <br/>
                    Se modifica <i>AddressFeignClient</i> - va trece prin API Gateway, si nu se apleaza direct (am lasat si vechiul cod pentru comparatie):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.feignclients;\n' +
                            '\n' +
                            'import org.springframework.cloud.openfeign.FeignClient;\n' +
                            'import org.springframework.web.bind.annotation.GetMapping;\n' +
                            'import org.springframework.web.bind.annotation.PathVariable;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.AddressResponse;\n' +
                            '\n' +
                            '/*\n' +
                            '    address.service.url => e definit in application.properties\n' +
                            '    address-feign-client => e numele acestului client\n' +
                            '    /api/address => se concateneaza la "address.service.url" (optional, se poate scrie toata calea in "url" )\n' +
                            '*/\n' +
                            '\n' +
                            '//@FeignClient(url="${address.service.url}", value = "address-feign-client", path = "/api/address")\n' +
                            '//@FeignClient(value = "address-service", path = "/api/address") // eureka\n' +
                            '@FeignClient(value = "api-gateway") // api-gateway\n' +
                            'public interface AddressFeignClient {\n' +
                            '\n' +
                            '    // signatura copiata din "AddressController"\n' +
                            '    //@GetMapping("/getById/{id}") // Eureka\n' +
                            '    @GetMapping("/address-service//api/address/getById/{id}") // Api Gateway\n' +
                            '    AddressResponse getById(@PathVariable long id);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. LoadBalacing cu Api Gateway</b>
                    <br/>
                    <br/>

                    Api Gateway se ocupa in mod automat de LoadBalancing, asa ca nu mai este nevoie de clasa <i>AdrSerLoadBalConfig</i>.



                    <hr/>
                    <b>Observatie</b>:
                    <br/>
                    <b>Zuul API Gateway</b> - este deprecated. Se foloseste Spring Cloud API Gateqway.
                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default CloudApiGatewaySpringContent;