import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ImagesDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-images", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker images / docker image ls</b>

                    <br/>
                    <br/>

                    Pentru a lista/afisa imaginile de repository-ul local:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image ls'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images'}
                    </SyntaxHighlighter>

                    se poate afisa:
                    <SyntaxHighlighter>
                        {'REPOSITORY                    TAG         IMAGE ID       CREATED         SIZE\n' +
                            'iulianbuzdugan/multi-client   latest      fea2af59624a   5 weeks ago     142MB\n' +
                            '<none>                        <none>      697c68fa19a0   5 weeks ago     744MB\n' +
                            '<none>                        <none>      9cec28ac5ba2   5 weeks ago     380MB\n' +
                            '<none>                        <none>      c791e4f69f19   5 weeks ago     744MB'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.1. Filtrare </b>
                    <br/>
                    <br/>

                    Pentru a filtra:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images node'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'REPOSITORY   TAG         IMAGE ID       CREATED        SIZE\n' +
                            'node         14-alpine   33deb4de1d99   2 months ago   119MB\n' +
                            'node         16-alpine   f7ef5856dc1f   2 months ago   115MB\n' +
                            'node         alpine      867dce98a500   2 months ago   167MB\n'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images node:alpine'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'REPOSITORY   TAG       IMAGE ID       CREATED        SIZE\n' +
                            'node         alpine    867dce98a500   2 months ago   167MB\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa tot IMAGE ID (pentru a trunca):
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images --no-trunc node:alpine'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'REPOSITORY   TAG       IMAGE ID                                                                  CREATED        SIZE\n' +
                            'node         alpine    sha256:867dce98a500f1c860f9f7b7e1c9df555cdfba8f58a3c65dcae232f1a1bba309   2 months ago   167MB\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImagesDockerContent;