import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ConfigMapK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-config-map", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. ConfigMap</b>
                    <br/>
                    <br/>

                    Un obiect de tip <b>ConfigMap</b> este folosit pentru a retine variabile de mediu.
                    <br/>
                    Putem creea un fisier: <i>environment.yaml</i> (poate fi orice nume), cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: ConfigMap\n' +
                            'metadata:\n' +
                            '  name: data-store-env\n' +
                            'data:\n' +
                            '  folder: \'story\'\n' +
                            '  # key: value..'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:2\n' +
                            '          env:\n' +
                            '            - name: STORY_FOLDER\n' +
                            '              # value: \'story\'\n' +
                            '              valueFrom: \n' +
                            '                configMapKeyRef:\n' +
                            '                  name: data-store-env\n' +
                            '                 key: folder'}
                    </SyntaxHighlighter>

                    Afisare ConfigMap:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get configmap'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ConfigMapK8sContent;