import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class ResourcesLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-resources", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Transformare date: php artisan make:resource</b>
                    <br/>
                    <br/>
                    Pentru a genera o resursa, se da comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:resource CategoryResource'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Http\\Resources;\n' +
                            '\n' +
                            'use Illuminate\\Http\\Resources\\Json\\JsonResource;\n' +
                            '\n' +
                            'class CategoryResource extends JsonResource\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Transform the resource into an array.\n' +
                            '     *\n' +
                            '     * @param  \\Illuminate\\Http\\Request  $request\n' +
                            '     * @return array|\\Illuminate\\Contracts\\Support\\Arrayable|\\JsonSerializable\n' +
                            '     */\n' +
                            '    public function toArray($request)\n' +
                            '    {\n' +
                            '//        return parent::toArray($request);\n' +
                            '\n' +
                            '        /* trebuie sa returneze o structura de tipul:\n' +
                            '\n' +
                            '        {\n' +
                            '            "id": 1,\n' +
                            '            "parent": 0,\n' +
                            '            "droppable": true,\n' +
                            '            "text": "Fashion Femei",\n' +
                            '            "data": {\n' +
                            '              "visible": false,\n' +
                            '              "pictogram": "BiGift"\n' +
                            '            }\n' +
                            '\n' +
                            '         */\n' +
                            '\n' +
                            '        return [\n' +
                            '            \'id\' => $this->id,\n' +
                            '            \'text\' => $this->name,\n' +
                            '            \'parent\' => $this->parent_id==null?0:$this->parent_id,\n' +
                            '            \'droppable\' => true,\n' +
                            '            \'data\' => [\n' +
                            '                "visible" => $this->visible,\n' +
                            '                "pictogram"=> $this->pictogram,\n' +
                            '                "position"=>$this->position\n' +
                            '            ]\n' +
                            '        ];\n' +
                            '        // se poate folosi\n' +
                            '        // \'order_items\' => AltResource::collection($this->>whenLoaded(\'orderItems\'))\n' +
                            '        // in Controller:\n' +
                            '        // CategoryResource::colection(Category::with(\'orderItems)->get())\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ResourcesLaravelContent;