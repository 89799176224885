import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class PhpNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-php", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare PHP</b>

                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get install php-fpm'}
                    </SyntaxHighlighter>

                    Verificare ca <i>php-fpm</i> se instaleaza automat si ca serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl list-units | grep php'}
                    </SyntaxHighlighter>

                    Verificare status
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl status php8.1-fpm'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Configurare PHP in nginx.conf</b>

                    <br/>
                    <br/>

                    Cautare fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'find /run/php -name *fpm.sock'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd /etc/nginx/'}
                    </SyntaxHighlighter>

                    Exemplu de configurare nginx.conf:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\tindex index.php index.html\n' +
                            '\t\t\n' +
                            '\t\tlocation / {\n' +
                            '\t\t\n' +
                            '\t\t\ttry_files $uri $uri/ =404;\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation ~\\.php# {\n' +
                            '\t\t\n' +
                            '\t\t\tinclude fastcgi.conf;\n' +
                            '\t\t\tfastcgi_pass unix:/run/php/php8.1-fpm.sock;\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Verificare log-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nano /var/log/nginx/error.log'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tail -n 1 /var/log/nginx/error.log'}
                    </SyntaxHighlighter>

                    Eroare posibila:
                    <br/>
                    <i>2022/09/19 22:41:16 [crit] 11062#0: *63 connect() to unix:/run/php/php8.1-fpm.sock failed (13: Permission denied) while connecting to upstream, client: XXX.XXX.XXX.XXX, server: localhost, request: "GET / HTTP/1.1", upstream: "fastcgi://unix:/run/php/php8.1-fpm.sock:", host: "162.172.1.1"
                    </i>

                    <br/>
                    Rezolvare:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <br/>
                    <i>nobody     11062  0.0  0.2  10660  4172 ?        S    22:39   0:00 nginx: worker process</i>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep php'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <br/>
                    <i>www-data   10848  0.0  0.3 217292  7368 ?        S    22:15   0:00 php-fpm: pool www</i>

                    <br/>
                    <br/>
                    Adauga in fisierul <n>nginx.conf</n> linia:
                    <br/>
                    <b>user www-data</b>

                    <br/>
                    <br/>
                    Reincarcare nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                    Verificam din nou:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <br/>
                    <i>www-data   11155  0.0  0.1  10772  3528 ?        S    22:55   0:00 nginx: worker process</i>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PhpNginxContent;