import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"ann-intro", url:"#", title:"Introducere în rețele neuronale", subtitle:""},

        {id:"ann", url:"/ann", title:"Primii pasi", subtitle:"", parent:"ann-intro"},
        {id:"mcculloch-pitts", url:"/mcculloch-pitts#mcculloch-pitts", title:"Neuronul McCulloch-Pitts", subtitle:"", parent:"ann-intro"},
        {id:"perceptron-theory", url:"/perceptron-theory", title:"Perceptronul", subtitle:"", parent:"ann-intro"},
        {id:"adaline", url:"/adaline", title:"Adaline", subtitle:"", parent:"ann-intro"},
        {id:"backpropagation", url:"/backpropagation", title:"Backpropagation", subtitle:"", parent:"ann-intro"},

        {id:"learning-rules-parent", url:"#", title:"Reguli de învățare", subtitle:""},
        {id:"learning-rules#", url:"/learning-rules", title:"Reguli de învățare", subtitle:"", parent: "learning-rules-parent"},

        {id:"app-ann", url:"#", title:"Aplicatii la retelele neuronale", subtitle:""},
        {id:"perceptron", url:"/perceptron", title:"Perceptron 2D", subtitle:"", parent: "app-ann"},
        {id:"handwritten", url:"/handwritten", title:"Cifre scrise de mână", subtitle:"", parent: "app-ann"},

        {id:"retele-competitive", url:"#", title:"Rețele competitive", subtitle:""},
        {id:"kohonen", url:"/kohonen", title:"Retele Kohonen", subtitle:"", parent: "retele-competitive"},

        {id:"retele-autoorganizare", url:"#", title:"Rețele cu autoorganizare", subtitle:""},
        {id:"hopfield", url:"/hopfield", title:"Retele Hopfield", subtitle:"", parent: "retele-autoorganizare"},

        {id:"retele-art", url:"#", title:"Rețele Carpenter-Grossberg (ART)", subtitle:""},
        {id:"art", url:"/art", title:"Introducere in retele ART", subtitle:"", parent: "retele-art"},
        {id:"art1", url:"/art1", title:"ART1", subtitle:"", parent: "retele-art"},
        {id:"art1ex1", url:"/art1ex1", title:"ART1: Exemplu", subtitle:"", parent: "retele-art"},

        {id:"masini-boltzmann", url:"#", title:"Masini Boltzmann", subtitle:""},
        {id:"boltzmann", url:"/boltzmann", title:"Masini Boltzmann", subtitle:"", parent: "masini-boltzmann"},

        {id:"retele-radiale", url:"#", title:"Retele radiale", subtitle:""},
        {id:"rbnn", url:"/rbnn", title:"Retele neurale cu functii de baza radiale (RBF - Radial Basis Function Neural Network)", subtitle:"", parent: "retele-radiale"},

        {id:"retele-dbn", url:"#", title:"Reţele de convingeri profunde (DBN - Deep Belief Networks)", subtitle:""},

        {id:"retele-cnn", url:"#", title:"Reţele neuronal convolutionale (CNN - Convolutional Neural Network )", subtitle:""},
        {id:"cnn-intro", url:"/cnn-intro", title:"Istoria CNN", subtitle:"", parent: "retele-cnn"},
        {id:"neocognitron", url:"/neocognitron", title:"Neocognitron", subtitle:"", parent: "retele-cnn"},
        {id:"cnn", url:"/cnn", title:"CNN", subtitle:"", parent: "retele-cnn"},

        {id:"retele-gan", url:"#", title:"Rețele generativ-adverse (GAN - Generative Adversarial Network )", subtitle:""},
        {id:"gans", url:"/gans", title:"GANs", subtitle:"", parent: "retele-gan"},

        {id:"anexa", url:"#", title:"Anexe", subtitle:""},
        {id:"anexa1-probability", url:"/anexa1-probability", title:"Anexa 1: Probabilitati", subtitle:"", parent: "anexa"},
        {id:"anexa2-markov", url:"/anexa2-markov", title:"Anexa 2: Lanturi Markov", subtitle:"", parent: "anexa"},
        {id:"anexa3-monte-carlo", url:"/anexa3-monte-carlo", title:"Anexa 3: Simulare Monte Carlo", subtitle:"", parent: "anexa"},
        {id:"anexa4-genetic-algorithms", url:"/anexa4-genetic-algorithms", title:"Anexa 4: Algoritmi genetici", subtitle:"", parent: "anexa"},
        {id:"anexa-gibbs-sampling", url:"/anexa-gibbs-sampling", title:"Anexa 5: Gibbs Sampling", subtitle:"", parent: "anexa"},
        {id:"anexa-softmax", url:"/anexa-softmax", title:"Anexa 6: Softmax", subtitle:"", parent: "anexa"},

        {id:"articol", url:"#", title:"Articole", subtitle:""},
        {id:"articol1", url:"/articol1", title:"IA ar trebui sa conduca omenirea?", subtitle:"", parent: "articol"},

    ];

    static indexUrl = "/ann/index";

    render() {
        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Rețele neuronale artificiale
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;