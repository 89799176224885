import {useRoutes} from "react-router-dom";
import React from "react";
import TypescriptHomeContent from "./IndexContent";
import PickTypescriptContent from "./PickTypescriptContent";
import OmitTypescriptContent from "./OmitTypescriptContent";
import InstallTypescriptContent from "./InstallTypescriptContent";
import PrivateRoute from "../../security/PrivateRoute";
import TypesTypescriptContent from "./TypesTypescriptContent";
import ObjectsTypescriptContent from "./ObjectsTypescriptContent";
import ClassesTypescriptContent from "./ClassesTypescriptContent";
import GenericsTypescriptContent from "./GenericsTypescriptContent";
import DecoratorsTypescriptContent from "./DecoratorsTypescriptContent";

// {/*  Typescript */}
// <Route path="/typescript/index" element={<TypescriptHomeContent/>} exact/>
// <Route element={<PrivateRoutes/>}>
//     <Route path="/typescript/pick" element={<PickTypescriptContent/>} exact/>
//     <Route path="/typescript/omit" element={<OmitTypescriptContent/>} exact/>
//     <Route path="/typescript/install" element={<InstallTypescriptContent/>} exact/>
// </Route>

export default function Router() {
    let element = useRoutes([
        {path: "/typescript/index", element: <TypescriptHomeContent/>},
        {path: "/typescript/pick", element: <PrivateRoute element={<PickTypescriptContent/>}/>},
        {path: "/typescript/omit", element: <PrivateRoute element={<OmitTypescriptContent/>}/>},
        {path: "/typescript/install", element: <PrivateRoute element={<InstallTypescriptContent/>}/>},
        {path: "/typescript/types", element: <PrivateRoute element={<TypesTypescriptContent/>}/>},
        {path: "/typescript/objects", element: <PrivateRoute element={<ObjectsTypescriptContent/>}/>},
        {path: "/typescript/classes", element: <PrivateRoute element={<ClassesTypescriptContent/>}/>},
        {path: "/typescript/generics", element: <PrivateRoute element={<GenericsTypescriptContent/>}/>},
        {path: "/typescript/decorators", element: <PrivateRoute element={<DecoratorsTypescriptContent/>}/>},
    ]);

    return element;
}