import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class RoutingReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-routing", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Routing</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install react-router-dom'}
                    </SyntaxHighlighter>

                    In index.js:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {BrowserRouter} from "react-router-dom";'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import * as React from "react";\n' +
                            'import { createRoot } from "react-dom/client";\n' +
                            'import { BrowserRouter } from "react-router-dom";\n' +
                            '\n' +
                            'const root = createRoot(document.getElementById("root"));\n' +
                            '\n' +
                            'root.render(\n' +
                            '  <BrowserRouter>\n' +
                            '    {/* The rest of your app goes here */}\n' +
                            '  </BrowserRouter>\n' +
                            ');'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById("root"))'}
                    </SyntaxHighlighter>
                    in loc de:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ReactDOM.render(<App/>, document.getElementById("root"))'}
                    </SyntaxHighlighter>

                    Apoi in <b>App.js</b>, folosind folosim tag-ul <b>Route</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Route } from \'react-router-dom\';  \n' +
                            'import Home from \'./component/home\';  \n' +
                            'import Contact from \'./component/contact\';  \n' +
                            'import About from \'./component/about\';  \n' +
                            '   \n' +
                            'function App {  \n' +
                            '  render() {  \n' +
                            '    return (  \n' +
                            '       <div>  \n' +
                            '           <Route path=\'/\'> \n' +
                            '               <Home /> \n' +
                            '           </Route>  \n' +
                            '           <Route path=\'/about\'> \n' +
                            '               <About /> \n' +
                            '           </Route>  \n' +
                            '           <Route path=\'/contact\'> \n' +
                            '               <Contact /> \n' +
                            '           </Route>  \n' +
                            '       </div>  \n' +
                            '   );  \n' +
                            '  }  \n' +
                            '}  \n' +
                            'export default App;  '}
                    </SyntaxHighlighter>


                    Daca vrem ca doar o componenta sa fie randata, folosim tag-ul <b>Switch</b> si atributul <b>exact</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Route, Switch } from \'react-router-dom\';  \n' +
                            'import Home from \'./component/home\';  \n' +
                            'import Contact from \'./component/contact\';  \n' +
                            'import About from \'./component/about\';  \n' +
                            '   \n' +
                            'function App {  \n' +
                            '  render() {  \n' +
                            '    return (  \n' +
                            '       <div>  \n' +
                            '           <Switch>\n' +
                            '               <Route exact path=\'/\'> \n' +
                            '                   <Home /> \n' +
                            '               </Route>  \n' +
                            '               <Route path=\'/about\'> \n' +
                            '                   <About /> \n' +
                            '               </Route>  \n' +
                            '               <Route path=\'/contact\'> \n' +
                            '                   <Contact /> \n' +
                            '               </Route>  \n' +
                            '           </Switch>\n' +
                            '       </div>  \n' +
                            '   );  \n' +
                            '  }  \n' +
                            '}  \n' +
                            'export default App;  '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Route</b> poate fi scris si asa:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<Switch>\n' +
                            '  <Route path="/typescript/index" component={TypescriptHomeContent} exact />\n' +
                            '  <Route path="/typescript/pick" component={PickTypescriptContent} exact />\n' +
                            '</Switch>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RoutingReactContent;