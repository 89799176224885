import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class OSReleaseLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-os-release", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa detalii legate de sistemul de operare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cat /etc/os-release'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'PRETTY_NAME="Ubuntu 22.04.1 LTS"\n' +
                            'NAME="Ubuntu"\n' +
                            'VERSION_ID="22.04"\n' +
                            'VERSION="22.04.1 LTS (Jammy Jellyfish)"\n' +
                            'VERSION_CODENAME=jammy\n' +
                            'ID=ubuntu\n' +
                            'ID_LIKE=debian\n' +
                            'HOME_URL="https://www.ubuntu.com/"\n' +
                            'SUPPORT_URL="https://help.ubuntu.com/"\n' +
                            'BUG_REPORT_URL="https://bugs.launchpad.net/ubuntu/"\n' +
                            'PRIVACY_POLICY_URL="https://www.ubuntu.com/legal/terms-and-policies/privacy-policy"\n' +
                            'UBUNTU_CODENAME=jammy'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OSReleaseLinuxContent;