import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class LogGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-log", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git log</b>
                    <br/>
                    <br/>


                    Pentru a afisa istoricul comiturilor efecutate:

                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git log'}
                    </SyntaxHighlighter>

                    Rezultatul afisat:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'commit 72f50991845c23a8196dc4f567bc3f191c1cfa03 (HEAD -> master)\n' +
                        'Author: letyournailsgrow <buzdugan.iulian@gmail.com>\n' +
                        'Date:   Thu Mar 24 09:21:03 2022 +0200\n' +
                        '\n' +
                        '    Al doilea commit\n' +
                        '\n' +
                        'commit 14055f8578b202347a9994575e416d73789692bf\n' +
                        'Author: letyournailsgrow <buzdugan.iulian@gmail.com>\n' +
                        'Date:   Thu Mar 24 08:53:11 2022 +0200\n' +
                        '\n' +
                        '    Primul commit'}
                    </SyntaxHighlighter>

                    Pentru a afisa informatiile legate de commit-uri pe cate o linie se foloseste optiunea <b>--oneline</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git log --oneline'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'git log --oneline\n' +
                        '72f5099 (HEAD -> test_branch_2, origin/master, test_branch_1, master) Al doilea commit\n' +
                        '14055f8 Primul commit'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LogGitContent;