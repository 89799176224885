import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ObjectPersistencePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-object-persistence", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Functia id()</b>
                    <br/>
                    <br/>
                    Funcția build-in <b>id()</b> returnează „identitatea” unui obiect.
                    Acesta este un număr întreg care este garantat a fi unic și constant pentru acest obiect pe durata de viață.
                    Două obiecte cu durate de viață care nu se suprapun pot avea aceeași valoare id().

                    <br/>
                    In impleementarea CPython, valoarea returnata de functia id() este adresa obiectului in memorie.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a = \'floare\'\n' +
                        'b = \'casalot\'\n' +
                        '\n' +
                        'print(\'a id:\', id(a)) # a id: 139731469210224\n' +
                        'print(\'b id:\', id(b)) # b id: 139731469210096'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Operatorul ==</b>
                    <br/>
                    <br/>
                    Operatorul == returneaza <b>True</b>, daca:
                    <ul>
                        <li>doua obiecte (chiar si diferite) au aceeasi valoare (compratie pe valoari)</li>
                        <li>doua variabile refera acelasi obiect (pentru a verifica daca refera acelasi obiect, ar trebui folosit operatorul <b>is</b>)</li>
                    </ul>

                    <hr/>
                    <b>3. Operatorul is</b>
                    <br/>
                    <br/>
                    Operatorul <b>is</b> returneaza <b>True</b>, daca:
                    <ul>
                        <li>doua obiecte (chiar si diferite) au aceeasi valoare (compratie pe valoari)</li>
                        <li>doua variabile refera acelasi obiect (pentru a verifica daca refera acelasi obiect, ar trebui folosit operatorul <b>is</b>)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a = [1,2,3]\n' +
                        'b = a\n' +
                        ' \n' +
                        'print(id(a)) # 139847480918576\n' +
                        'print(id(b)) # 139847480918576\n' +
                        'print(a == b) # True\n' +
                        'print(a is b) # True\n' +
                        '\n' +
                        'a = [1,2,3]\n' +
                        'b = [1,2,3]\n' +
                        '\n' +
                        'print(id(a)) # 139847480918576\n' +
                        'print(id(b)) # 139847479679568\n' +
                        'print(a == b) # True\n' +
                        'print(a is b) # False'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Copiere superficiala/shallow</b>
                    <br/>
                    <br/>

                    Daca avem o lista <i>a</i> si vrem sa creeam o copie a acesteia in lista <i>b</i>, vom folosi <b>b = a[:]</b>.
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a = [1, 2, [3,4]]\n' +
                        'b = a[:]\n' +
                        'b[0]=8\n' +
                        '\n' +
                        'print(a) # [1, 2, [3, 4]]\n' +
                        'print(b) # [8, 2, [3, 4]]\n' +
                        'print(a is b) # False\n' +
                        '\n' +
                        '\n' +
                        'b[2][0] = 7\n' +
                        'print(a) # [1, 2, [7, 4]]\n' +
                        'print(b) # [8, 2, [7, 4]]'}
                    </SyntaxHighlighter>
                    Din exemplul de mai sus, se observa ca <b>s-a realizat copierea, dar nu in adancime</b>. Cand s-a modificat sublista <i>[7,4]</i> s-a reflectat si in <i>a</i> si <i>b</i>.
                    Obiectul <i>a</i> este un obiect compus, iar a[:] realizeaza o <b>copie superficiala/shallow (adica un singur nivel)</b>, deci nu creeaza copii si obiectelor copii, ci se foloseste referinte.
                    <br/>
                    Deci, a[:] = [copie(1), copie(2), referinta la [3,4] ]

                    <hr/>
                    <b>5. Copiere in adancime</b>
                    <br/>
                    <br/>

                    Pentru a realiza o copie independentă a unui obiect compus (listă, dicționar, instanță de clasă personalizată - nu apeleaza constructorul), trebuie utilizata copierea profundă, care:

                    <ul>
                        <li>
                            construiește un nou obiect compus și apoi, recursiv, inserează în el copii ale obiectelor găsite în original
                        </li>
                        <li>
                            durează mai mult timp pentru finalizare, deoarece sunt mult mai multe operații de efectuat
                        </li>
                        <li>
                            este implementat de funcția <b>deepcopy()</b>, furnizată de modulul <b>copy</b>
                        </li>
                    </ul>

                    <b>Observatie</b>
                    <br/>
                    Modulul <b>copy</b> are o funcție pentru copierea superficială: <b>copy()</b> (pentru copierea listelor există deja notația [:] sau a=list(b), iar pentru dicționare ați putea folosi a = dict(b)).

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import copy\n' +
                        '\n' +
                        'a = [1, 2, [3,4]]\n' +
                        'b = copy.deepcopy(a)\n' +
                        'b[0]=8\n' +
                        '\n' +
                        'print(a) # [1, 2, [3, 4]]\n' +
                        'print(b) # [8, 2, [3, 4]]\n' +
                        'print(a is b) # False\n' +
                        '\n' +
                        '\n' +
                        'b[2][0] = 7\n' +
                        'print(a) # [1, 2, [3, 4]], deci [3, 4]\n' +
                        'print(b) # [8, 2, [7, 4]]'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import copy\n' +
                        '\n' +
                        'class Iepure:\n' +
                        '    \n' +
                        '    def __init__(self, name):\n' +
                        '        self.name=name\n' +
                        '     \n' +
                        'i1 = [ Iepure("Iepurila") ]\n' +
                        '# i2 = copy.copy(i1)\n' +
                        'i2 = copy.deepcopy(i1)\n' +
                        '\n' +
                        'i2[0].name="Rila"\n' +
                        '\n' +
                        'print(i1[0].name) # Iepurila (cu deepcopy() ) /  Rila (cu copy() )\n' +
                        'print(i2[0].name) # Rila'}
                    </SyntaxHighlighter>

                    <hr/>

                    Deci metoda <b>deepcopy()</b>:

                    <ul>
                        <li>
                            creează și persistă instanțe noi ale obiectelor sursă ( în timp ce operația de copiere superficială stochează doar referințe la adresa de memorie originală)
                        </li>
                        <li>
                            durează mult mai mult timp decât orice operațiune de copiere superficială
                        </li>
                        <li>
                            copiază întregul obiect, inclusiv toate obiectele imbricate (recursiv)
                        </li>
                        <li>
                            copierea profundă poate cauza probleme atunci când există referințe ciclice în structura de copiat
                        </li>
                    </ul>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ObjectPersistencePythonContent;