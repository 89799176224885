import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlTaintContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-taint", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl taint</b>
                    <br/>
                    <br/>

                    Pentru adauga o conditie (daca nodul <i>nume-nod</i> are <i>eticheta</i> atunci efectuaza <i>actiune</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes <nume-nod> <eticheta>:<actiune>'}
                    </SyntaxHighlighter>

                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes node-kj disk=pd:NoSchedule'}
                    </SyntaxHighlighter>

                    Pentru a sterge o conditie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes <nume-nod> <cheie-eticheta>:<actiune>-'}
                    </SyntaxHighlighter>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes node-kj disk:NoSchedule-'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlTaintContent;