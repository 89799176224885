import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ExecDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-exec", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker exec / docker container exec</b>

                    <br/>
                    <br/>

                    Aceasta comanda este utila pentru a executa comenzi intr-un container care ruleaza.
                    <br/>
                    Sintaxa (se poate trimite o singura comanda, eventual cu 1 sau mai multe argumente):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it container_id comanda'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container exec -it [container] comanda'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>flag-ul <b>-it</b>: permite de a scrie input (instructuni, comenzii, etc) in container</li>
                    </ul>

                    Exemplu:
                    <br/>
                    Sa presupunem ca pornim redis (creare + pornire):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run redis'}
                    </SyntaxHighlighter>

                    Daca vom rula in alta consola:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE     COMMAND                  CREATED          STATUS          PORTS      NAMES\n' +
                            '49058fc16cb6   redis     "docker-entrypoint.s…"   49 seconds ago   Up 38 seconds   6379/tcp   mystifying_bhabha'}
                    </SyntaxHighlighter>
                    acum vrem sa executam comanda <i>redis-cli</i> in containerul in care se gaseste redis (49058fc16cb6):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it 49058fc16cb6 redis-cli'}
                    </SyntaxHighlighter>
                    acum vom intra in consola redis-cli si vom putea executa comenzi specifice redis-cli; de exemplu:
                    <SyntaxHighlighter>
                        {'127.0.0.1:6379> set unu 1\n' +
                            'OK'}
                    </SyntaxHighlighter>
                    Pentru a iesi din container si a reveni in terminalul initial: CTRL+C

                    <hr/>
                    <b>2. docker exec cu un shell (sh, bash, powershell, zsh)</b>
                    <br/>
                    <br/>

                    Avand pornit un container, putem intra in linie de comanda, pe acel container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it 49058fc16cb6 sh'}
                    </SyntaxHighlighter>
                    si putem rula comenzi gen: ls, dir, ...


                    <hr/>
                    <b>3. Observatie</b>
                    <br/>
                    <br/>

                    Daca nu avem containerul pornit, putem ajunge <i>direct</i> la linia de comanda si folosind <b>docker run</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it busybox sh'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExecDockerContent;