import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ItFlagsDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-it-flags", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker run/exec: flagurile -it</b>

                    <br/>
                    <br/>

                    Pentru fiecare proces avem asociate 3 canale de comunicare:
                    <ul>
                        <li>stdin: pentru ca procesul sa permita input din partea terminalului nostru (sa scriem)</li>
                        <li>stdout: pentru ca procesul sa trimita catre terminalul nostru ouput (sa se afiseze text din partea procesului)</li>
                        <li>stderr: pentru ca procesul sa trimita catre terminalul nostru erori</li>
                    </ul>
                    In realitate sunt doua flaguri: -i -t, dar se poate scrie mai scurt: -it.

                    <br/>
                    <br/>
                    Flagurile:
                    <ul>
                        <li>
                            -i {"=>"} ne conectam la stdin (pentru a scrie informatie catre proces/aplicatie/terminal); tot ce scriem se trimite catre stdin
                        </li>
                        <li>
                            -t {"=>"} ne conecta la stdout (pentru a primi informatie de la proces); are rolul de formata textul afisat
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ItFlagsDockerContent;