import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavaSpringSecurityEx04Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-security-ex-04", IndexContent);
    }

    render() {

        return (
            <div className="home index">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify content important"}>
                    <i>Aplicatie:</i> <b>security</b>
                    <br/>
                    <i>Modul:</i> <b>security-03</b>
                    <br/>
                    <i>Descriere actiune:</i> Construirea unui proiect cu securitate pe baza implementarilor <b>InMemoryUserDetailsManager</b>
                    si <b>NoOpPasswordEncoder</b> pornind de la modulul <b>security-02</b>
                    <br/>
                    <i>IDE folosit</i>: Intellij IDEA
                    <hr/>

                    1. In fisierul <b>pom.xml</b> (in radacina proiectului) se adauga in cadrul sectiunii <b>project\modules</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<module>security-03</module>'}
                    </SyntaxHighlighter>

                    2. Clasele <b>HelloController</b> si <b>Security03Application</b> (continut identic cu Security02Application) se pot copia, pastrand aceeasi structura a organizarii de directoare si de pachete
                    (redenumind <i>exemplu02</i> cu <i>exemplu03</i> in numele de pachete).
                    <br/>
                    <br/>
                    3.   Apoi, vom creea pachetul:
                    <div className={"padding50"}>
                        <b>ro.letyournailsgrow.security.exemplu03.config</b>
                    </div>

                    <br/>
                    4. Se va creea clasa <b>SecurityConfig</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.security.exemplu03.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.Bean;\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        'import org.springframework.security.core.userdetails.User;\n' +
                        'import org.springframework.security.core.userdetails.UserDetails;\n' +
                        'import org.springframework.security.core.userdetails.UserDetailsService;\n' +
                        'import org.springframework.security.crypto.password.NoOpPasswordEncoder;\n' +
                        'import org.springframework.security.crypto.password.PasswordEncoder;\n' +
                        'import org.springframework.security.provisioning.InMemoryUserDetailsManager;\n' +
                        '\n' +
                        '@Configuration\n' +
                        'public class SecurityConfig {\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public UserDetailsService userDetailsService(){\n' +
                        '        InMemoryUserDetailsManager userDetailsService = new InMemoryUserDetailsManager();\n' +
                        '\n' +
                        '        UserDetails user = User.withUsername("iulian")\n' +
                        '                                .password("123")\n' +
                        '                                .authorities("read")\n' +
                        '                                .build();\n' +
                        '\n' +
                        '        userDetailsService.createUser(user);\n' +
                        '        return userDetailsService;\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public PasswordEncoder passwordEncoder(){\n' +
                        '        return NoOpPasswordEncoder.getInstance();\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    5. Pentru a putea apelata metoda <i>hello()</i>  din clasa <i>HelloController</i>,

                    Comanda care trebuie executata este:
                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8080/hello\n' +
                        'Authorization: Basic iulian 123'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b> De remarcat ca se poate pune orice parola, pentru este acceptata orice parola datorita implementarii folosite <b>NoOpPasswordEncoder</b>.

                    <hr/>
                    <b>STOP</b>

                    </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default JavaSpringSecurityEx04Content;