import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class AuthenticationK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-authentication", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Autentificare</b>
                    <br/>
                    <br/>

                    Se poate folosi:
                    <ul>
                        <li>
                            --basic-auth-file
                        </li>
                        <li>
                            --token-auth-file
                        </li>
                    </ul>

                    <hr/>
                    <b>1. basic-auth-file</b>
                    <br/>
                    <br/>

                    Serviciul <b>kube-apiserver</b> poate fi pornit cu urmatorul parametru:
                    <ul>
                        <li>
                            <b>--basic-auth-file=user-detail.csv</b>
                            <br/>
                            fisierul <i>user-detail.csv</i> este un fiser CSV cu 3 coloane:
                            <ul>
                                <li>parola</li>
                                <li>username</li>
                                <li>user id</li>
                                <li>grup (optional)</li>
                            </ul>
                        </li>
                    </ul>
                    kube-apiserver.service:
                    <SyntaxHighlighter>
                        {'ExecStart=/usr/local/bin/kube-apiserver -basic-auth-file=user-detail.csv'}
                    </SyntaxHighlighter>
                    sau se poate modifica <b>/etc/kubernetes/manifests/kube-apiserver.yaml</b> adaugand parametrul de mai sus.

                    <br/>
                    Apoi, trebuie restartat serverul!
                    <hr/>

                    <SyntaxHighlighter>
                        {'curl -v -k https://master-node-ip:6443/api/v1/pods -u "user1:password123"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. token-auth-file</b>
                    <br/>
                    <br/>

                    Diferenta dintre basic-auth-file si token-auth-file este ca in CSV, pentru token-auth-file parola este un token.

                    <SyntaxHighlighter>
                        {'curl -v -k https://master-node-ip:6443/api/v1/pods --header "Authorization: Bearer token"'}
                    </SyntaxHighlighter>

                    <b>Notă:</b>
                    <br/>
                    Acest lucru nu este recomandat într-un mediu de producție.
                    Acest lucru este doar în scopuri de învățare.
                    Această abordare este depreciată în Kubernetes versiunea 1.19 și nu mai este disponibilă în versiunile ulterioare.

                    <br/>
                    Urmați instrucțiunile de mai jos pentru a configura autentificarea de bază într-o configurare kubeadm:
                    <ul>
                        <li>
                            Creați un fișier cu detaliile utilizatorului local la/tmp/users/user-details.csv:
                            <SyntaxHighlighter>
                                {'# User File Contents\n' +
                                    'password123,user1,u0001\n' +
                                    'password123,user2,u0002\n' +
                                    'password123,user3,u0003\n' +
                                    'password123,user4,u0004\n' +
                                    'password123,user5,u0005'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Editați podul static kube-apiserver configurat de kubeadm pentru a transmite detaliile utilizatorului.
                            Fișierul se află in: <b>/etc/kubernetes/manifests/kube-apiserver.yaml</b>
                            <SyntaxHighlighter>
                                {'apiVersion: v1\n' +
                                    'kind: Pod\n' +
                                    'metadata:\n' +
                                    '  name: kube-apiserver\n' +
                                    '  namespace: kube-system\n' +
                                    'spec:\n' +
                                    '  containers:\n' +
                                    '  - command:\n' +
                                    '    - kube-apiserver\n' +
                                    '      <content-hidden>\n' +
                                    '    image: k8s.gcr.io/kube-apiserver-amd64:v1.11.3\n' +
                                    '    name: kube-apiserver\n' +
                                    '    volumeMounts:\n' +
                                    '    - mountPath: /tmp/users\n' +
                                    '      name: usr-details\n' +
                                    '      readOnly: true\n' +
                                    '  volumes:\n' +
                                    '  - hostPath:\n' +
                                    '      path: /tmp/users\n' +
                                    '      type: DirectoryOrCreate\n' +
                                    '    name: usr-details'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Modificați opțiunile de pornire kube-apiserver pentru a include fișierul de autenticare de bază:
                            <SyntaxHighlighter>
                                {'apiVersion: v1\n' +
                                    'kind: Pod\n' +
                                    'metadata:\n' +
                                    '  creationTimestamp: null\n' +
                                    '  name: kube-apiserver\n' +
                                    '  namespace: kube-system\n' +
                                    'spec:\n' +
                                    '  containers:\n' +
                                    '  - command:\n' +
                                    '    - kube-apiserver\n' +
                                    '    - --authorization-mode=Node,RBAC\n' +
                                    '      <content-hidden>\n' +
                                    '    - --basic-auth-file=/tmp/users/user-details.csv'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Creați rolurile și legăturile de roluri necesare pentru acești utilizatori:
                            <SyntaxHighlighter>
                                {'---\n' +
                                    'kind: Role\n' +
                                    'apiVersion: rbac.authorization.k8s.io/v1\n' +
                                    'metadata:\n' +
                                    '  namespace: default\n' +
                                    '  name: pod-reader\n' +
                                    'rules:\n' +
                                    '- apiGroups: [""] # "" indicates the core API group\n' +
                                    '  resources: ["pods"]\n' +
                                    '  verbs: ["get", "watch", "list"]\n' +
                                    ' \n' +
                                    '---\n' +
                                    '# This role binding allows "jane" to read pods in the "default" namespace.\n' +
                                    'kind: RoleBinding\n' +
                                    'apiVersion: rbac.authorization.k8s.io/v1\n' +
                                    'metadata:\n' +
                                    '  name: read-pods\n' +
                                    '  namespace: default\n' +
                                    'subjects:\n' +
                                    '- kind: User\n' +
                                    '  name: user1 # Name is case sensitive\n' +
                                    '  apiGroup: rbac.authorization.k8s.io\n' +
                                    'roleRef:\n' +
                                    '  kind: Role #this must be Role or ClusterRole\n' +
                                    '  name: pod-reader # this must match the name of the Role or ClusterRole you wish to bind to\n' +
                                    '  apiGroup: rbac.authorization.k8s.io'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Odată creat, vă puteți autentifica pe serverul kube-api folosind credentialele utilizatorilor:
                            <SyntaxHighlighter>
                                {'curl -v -k https://localhost:6443/api/v1/pods -u "user1:password123"'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AuthenticationK8sContent;