import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"util-windows", url:"#", title:"Windows", subtitle:""},

        {id:"windows-kill-process", url:"/util/windows/kill-process", title:"Oprire process dupa PORT", subtitle:"", parent:"util-windows"},
        {id:"windows-virtualbox", url:"/util/windows/virtualbox", title:"Virtual Box", subtitle:"", parent:"util-windows"},
        {id:"windows-curl", url:"/util/windows/curl", title:"curl", subtitle:"", parent:"util-windows"},

        {id:"util-linux", url:"#", title:"Linux", subtitle:""},

        {id:"linux-systemctl", url:"/util/linux/systemctl", title:"Gestionarea serviciilor folosind Systemctl", subtitle:"", parent:"util-linux"},
        {id:"linux-nano", url:"/util/linux/nano", title:"nano", subtitle:"", parent:"util-linux"},
        {id:"linux-sftp", url:"/util/linux/sftp", title:"sftp", subtitle:"", parent:"util-linux"},
        {id:"linux-install-java-11-ubuntu-20", url:"/util/linux/install-java-11-ubuntu-20", title:"Instalare Oracle JDK 11 pe Ubuntu 20", subtitle:"", parent:"util-linux"},
        {id:"linux-install-postgresql-15-ubuntu-20", url:"/util/linux/install-postgresql-15-ubuntu-20", title:"Instalare PostgreSQL 15 pe Ubuntu 20", subtitle:"", parent:"util-linux"},
        {id:"linux-install-liferay-74-ubuntu-20", url:"/util/linux/install-liferay-74-ubuntu-20", title:"Instalare Liferay 7.4 pe Ubuntu 20", subtitle:"", parent:"util-linux"},
        {id:"linux-install-liferay-74-centos", url:"/util/linux/install-liferay-74-centos", title:"Instalare Liferay 7.4 pe Centos", subtitle:"", parent:"util-linux"},
        {id:"linux-service-liferay-74-ubuntu-20", url:"/util/linux/service-liferay-74-ubuntu-20", title:"Service Liferay 7.4 pe Ubuntu 20", subtitle:"", parent:"util-linux"},

        {id:"linux-install-grafana-10-ubuntu-20", url:"/util/linux/install-grafana-10-ubuntu-20", title:"Instalare Grafana 10 pe Ubuntu 20", subtitle:"", parent:"util-linux"},

        {id:"util-links-root", url:"#", title:"Link-uri", subtitle:""},
        {id:"util-links", url:"/util/links", title:"Link-uri utile", subtitle:"", parent:"util-links-root"},

        {id:"db-root", url:"#", title:"Baze de date", subtitle:""},
        {id:"db-create-database", url:"/db/create-database", title:"Creare baza de date", subtitle:"", parent:"db-root"},
        {id:"db-create-user", url:"/db/create-user", title:"Creare utilizator si acordare drepturi", subtitle:"", parent:"db-root"},
        {id:"db-backup-restore", url:"/db/backup-restore", title:"Backup & Restore", subtitle:"", parent:"db-root"},

        {id:"article-root", url:"#", title:"Articole", subtitle:""},
        {id:"article-back", url:"/article/back", title:"Reintoarceri", subtitle:"", parent:"article-root"},

        {id:"others-root", url:"#", title:"Altele", subtitle:""},
        {id:"util-chrome", url:"/util/chrome", title:"Pornire Chrome cu un anumit user-agent si o pagina web implicita", subtitle:"", parent:"others-root"},
    ]

    static indexUrl = "/util/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Tips & Tricks
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;