import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Art1Content extends BaseContentPage {

    constructor(props) {
        super(props, "art1", IndexContent);
    }

    render() {
        return (
            <div className="home art">
                {this.title()}
                {this.navigator()}

                <br/>

                <div id={"art1"} className={"text-justify"}>
                    <b>2. ART1</b>

                    <br/>
                    <br/>
                    Un vector binar <b>s</b> este prezentat rețelei ART1. Acesta ajunge la layerul <b>F<sub>1</sub>(a)</b> si apoi
                    este trimis către neuronii corespunzători din layerul <b>F<sub>1</sub>(b)</b>.
                    Neuronii din layerul <b>F<sub>1</sub>(b)</b> difuzează aceasta informatie
                    layerului <b>F<sub>2</sub></b>.
                    {/*prin intermediul conexiunilor <b>b<sub>ij</sub></b>.*/}
                    Fiecare neuron din <b>F<sub>2</sub></b> calculeaza intrarea netă (adică face sumarizarea) și devine activ.
                    Neuronul cu cea mai mare sumarizare este considerat <b>castigator</b> si i se setează activarea egala cu 1.
                    Ceilalti neuroni necastigatori li se va seta activarea egala cu 0.
                    Vom nota cu J indexul neuronului castigator. Acest neuron castigator devine <b>candidat</b> pentru a invata exemplul <b>s</b>.
                    Un semnal este trimis de la layerul <b>F<sub>2</sub></b> la <b>F<sub>1</sub>(b)</b> (mutiplicat de ponderea corespunzatoare <b>t<sub>Ji</sub></b>).
                    Neuronii din layerul <b>F<sub>1</sub>(b)</b> raman activi doar daca primesc semnale diferite de 0 de la ambelele layere:  <b>F<sub>1</sub>(a)</b> si <b>F<sub>2</sub></b>.
                    <br/>
                    <br/>

                    <div className={"important"}>
                    Definim norma unui vector binar y si o vom nota cu ||y||:
                        <MathComponent tex={String.raw`||y||=\sum_i y_i`}/>
                    </div>
                    <br/>

                    Norma vectorului <b>||x||</b> (adica activarea vectorului <b>s</b> din <b>F<sub>1</sub>(b)</b>) =
                    numarul de componente in care
                    vectorul pondere <b>t<sub>j</sub></b> pentru neuronul caștigator din <b>F<sub>2</sub></b> și vectorul <b>s</b> sunt ambele egale cu 1.
                    Această normă se mai numeste <b>potrivire</b>. Altfel spus:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`||x||=\sum_i x_i`}/>
                    unde
                        <MathComponent tex={String.raw`x_i = s_i t_{Ji}`}/>
                    </div>
                    <br/>
                    De exemplu, daca avem s=(1,0,0,1,1) si t<sub>j</sub> = (1,1,0,0,1), atunci norma vectorului ||x||=2, norma lui ||s||=3 si norma lui ||t<sub>j</sub>||=3
                    <br/>
                    <br/>
                    Daca ||x|| / ||s|| {">"}= parametrul de vigilenta atunci:
                    <ul>
                        <li>ponderile din ambele sensurile asociate cu neuronul castigator sunt ajustate</li>
                    </ul>
                    Altfel:
                    <ul>
                        <li>neuronul candidat este respins si inhibat</li>
                        <li>se alege alt neuron drept candidat</li>
                        <li>activarile neuronilor din <b>F<sub>1</sub></b> sunt resetate la 0</li>
                        <li>procesul de reia, prezentând-se retelei acelasi vector <b>s</b> (fara participarea neuronilor inhibati)</li>
                    </ul>

                    Acest proces continuă până când o potrivire satisfacatoare este gasită (un candidat este acceptat) sau toți neuronii sunt inhibați.
                    Daca toti neuronii sunt inhibați, atunci trebuie aleasă una din următoarele variante:
                    <ul>
                        <li>reducerea valorii parametrului de vigilentă (pentru a permite ca si exemplele mai putin similare sa fie plasate in acelasi cluster)</li>
                        <li>cresterea numarului din neuroni de tip cluster (cluster units)</li>
                        <li>desemnarea exeplului ca fiind anormal si nu poate fi clusterizat</li>
                    </ul>

                    În procesul ART, invatarea are loc în faze de rezonanta în care semnalele continuă să circule prin rețea.
                    Trebuie acordata atenție setării valorilor parametrilor, astfel încât:
                    <ul>
                        <li>resetarea să nu poată fi realizată brusc pe măsură ce greutățile se schimbă</li>
                        <li>sa nu se alegă un nou neuron castigator după ce un neuronul candidat este acceptat pentru a incerca sa învățe exemplul dat</li>
                    </ul>
                    <br/>

                    La sfarsitul fiecarei prezentari unui exemplu (normal, dupa ajustarea ponderilor) toti neuronii din F<sub>2</sub> (unitati cluster) devin invactivi
                    (adica activarea=0, dar pot participa la urmatoarea competitie)
                </div>
                <br/>
                <div className={"text-justify important"}>
                    <b>Algoritmul ART1</b>
                    <br/>
                    <br/>
                    Fie <b>n</b> numărul de componente pentru un exemplu. De fapt, <b>n</b> reprezintă și numărul de neuroni din statul <b>F<sub>1</sub>(a)</b>.
                    <br/>
                    <i>Initializare parametrii:</i>
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`L>1, (ex:L=2)`}/>
                        <MathComponent tex={String.raw`0<p\le 1, (ex:p=0.9)`}/>
                        <i>m - numărul de neuroni din statul de iesire (campul reprezentarii categoriilor)</i><br/>
                        <i>maxEpoch = 1000 (sau alta valoare)</i><br/>
                        <i>epoch = 0 </i><br/>
                        <i>schimbarePonderi = false </i>
                    </div>
                    <i>Initializare ponderi:</i>
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`0<b_{ij}(0) < {{L} \over {L-1+n}}`}/>
                        <MathComponent tex={String.raw`t_{ji}=1`}/>
                    </div>
                    <i>Algoritm:</i>
                    <ul>
                        <li>conditiaDeOprire=false</li>
                        <li>epoch = 0</li>
                        <li>cat timp conditiaDeOprire=false
                            <ul>
                                <li>pentru fiecare exemplu <b>s</b> din setul de antrenament
                                    <ul>
                                        <li>schimbarePonderi = false</li>
                                        <li>
                                            seteaza activarile neuronilor din F<sub>2</sub> la 0 (reseteaza inhibarile de la invercarile anterioare de invatare)
                                        </li>
                                        <li>
                                            seteaza activarile neuronilor din F<sub>1</sub>(a) la <b>s</b>
                                        </li>
                                        <li>
                                            calculeaza norma lui s:
                                            <MathComponent tex={String.raw`||s||=\sum_i s_i`}/>
                                        </li>
                                        <li>
                                            trimite semnalul de intrare de la F<sub>1</sub>(a) la F<sub>1</sub>(b):
                                            <MathComponent tex={String.raw`x_i=s_i`}/>
                                        </li>
                                        <li>
                                            pentru fiecare neuron din F<sub>2</sub> care nu este inhibat ( y<sub>j</sub> &#8800; -1 ), adica se <b>calculeaza iesirea retelei</b>:
                                            <MathComponent tex={String.raw`y_j=\sum_i b_{ij}x_i`}/>
                                        </li>
                                        <li>reset=true</li>
                                        <li>
                                            cat timp reset = true
                                            <ul>
                                                <li>
                                                    <b>se determina neuronul de iesire castigator y<sub>J</sub></b>:
                                                    <MathComponent tex={String.raw`y_J=max(y_j), 0 \le j \le m`}/>
                                                    (<i>daca y<sub>j</sub>=-1, atunci toti neuronii sunt inhibati si exemplul nu poate fi clusterizat</i>)
                                                </li>
                                                <li>
                                                    recompune activarea neuronilor din F<sub>1</sub>(b):
                                                    <MathComponent tex={String.raw`x_i = s_i t_{Ji}`}/>
                                                </li>
                                                <li>
                                                    calculeaza normala lui x:
                                                    <MathComponent tex={String.raw`||x||=\sum_i x_i`}/>
                                                </li>
                                                <li>
                                                    <b>se efectuează testul de vigilenta (rezontanta)/ testul de validare a neuronului castigator:</b>
                                                    <ul>
                                                        <li>
                                                            <b>daca</b>
                                                            <MathComponent tex={String.raw`{||x|| \over ||s||} \lt p`}/>
                                                            <b>se inhiba neuronul Y<sub>J</sub></b>:
                                                            <MathComponent tex={String.raw`j_J=-1`}/>
                                                        </li>
                                                        <li><b>altfel</b>, <b>actualizeaza ponderile neuronului J</b> (invatarea rapida):
                                                            <MathComponent tex={String.raw`b_{iJ}={x_i L \over L-1 + ||x||}`}/>
                                                            <MathComponent tex={String.raw`t_{jI}=x_i`}/>
                                                            reset = false<br/>
                                                            schimbarePonderi = true
                                                        </li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>epoch = epoch + 1</li>
                                <li>conditiaDeOprire=true, daca se indemplineste una din conditiile:
                                    <ul>
                                        <li>schimbarePonderi = false (nu s-au modificat ponderi)</li>
                                        <li>nici o unitate nu s-a resetat</li>
                                        <li>maxEpoch {">"} epoch (numarul de epoci sa fost depasit)</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                    </ul>
                    <i>Observatie 1:</i><br/>
                    Valorile ponderilor t<sub>ji</sub> pot fi 0 sau 1, si dupa ce a fost setat pe 0 in timpul invatarii,
                    nu mai poate fi setat apoi la 1 (ceea ce confera retelei stabilitate)<br/><br/>
                    <i>Observatie 2:</i><br/>
                    Testul de vigilenta cauta sa detemine daca vectorul de intrare <b>s</b> rezoneaza sau nu cu neuronul castigator.
                    Testul reprezinta de fapt <b>conditia de validare</b> a neuronului castigator<br/><br/>
                    <i>Observatie 3:</i><br/>
                    Daca <b>p</b> (parametrul de vigilenta) este prea mic, reteaua este prea rigida, se creeaza un numar mic de clustere (clase),
                    iar daca este prea mare se creeaza un numar mare de clustere. De aceea, in timpul procesului de instruire se alege un
                    <b>p</b> mic, ulterior urmând sa fie crescut<br/><br/>
                    <i>Observatie 4:</i><br/>
                    Se poate considera ca cele 2 seturi de ponderi, realizeaza o filtrare:
                    <ul>
                        <li>
                            ponderile b<sub>ij</sub> au functia de a trece de la spatiu de intrare (al caracteristicilor) la spatiul de iesire (al clusterelor/claselor)
                        </li>
                        <li>
                            ponderile t<sub>ji</sub> intervin in procesul de rezonanta si au rolul de a asigura stabilitatea retelei
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson</div>
                        </li>
                        <li>
                            <div>Victor Neagoe. 2018. Retele neurale pentru exploarea datelor, Matrixrom</div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Art1Content;