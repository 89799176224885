import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FirstPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-first-portlet", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    <div><b>Creare portlet folosind plugin Liferay IntelliJ</b></div>
                    <ol>
                        <li>Din IntelliJ, navigare: <i>File → New → Liferay Module</i></li>
                        <li>Din fereatra dialog, se seteaza:
                            <ul>
                                <li>Package Name (de exemplu: ro.letyournailsgrow.liferay.vanilla.apps.helloworld.portlet)</li>
                                <li>Class Name (de exemplu: HelloWorldPorlet)</li>
                                <li>Contributor Type: mvc-porlet</li>
                            </ul>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-1.png'}/>
                            </div>

                        </li>
                        <li>Next</li>
                        <li>Din fereatra dialog, se seteaza:
                            <ul>
                                <li>Module Name (de exemplu: hello-world)</li>
                            </ul>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-2.png'}/>
                            </div>

                        </li>
                        <li>Finish</li>
                    </ol>

                    Se va genera un modul de tip portlet cu urmatoare structura:
                    <div style={{padding:10}}>
                        <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-3.png'}/>
                    </div>

                    <hr/>
                    De exemplu, clasa <i>HelloWorldPortlet</i> generata, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img  alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-4.png'}/>
                    </div>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.helloworld.portlet;\n' +
                        '\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants.HelloWorldPortletKeys;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                        '\n' +
                        'import javax.portlet.Portlet;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=HelloWorld",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class HelloWorldPortlet extends MVCPortlet {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, clasa <i>HelloWorldPortletKeys</i> generata, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img  alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-5.png'}/>
                    </div>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        'public class HelloWorldPortletKeys {\n' +
                        '\n' +
                        '\tpublic static final String HELLOWORLD =\n' +
                        '\t\t"ro_letyournailsgrow_liferay_vanilla_apps_helloworld_HelloWorldPortlet";\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, fisierul <i>bnd.bnd</i> generat, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img  alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-6.png'}/>
                    </div>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: hello-world\n' +
                        'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.apps.helloworld\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Export-Package: ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants\n' +
                        '-liferay-aggregate-resource-bundles: ro.letyournailsgrow.liferay.vanilla.common.i18n'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, fisierul <i>build.gradle</i> generat, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img  alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-7.png'}/>
                    </div>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '\n' +
                        '\tcssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.0.2"\n' +
                        '}'}
                    </SyntaxHighlighter>


                    <hr/>
                    De exemplu, fisierul <i>init.jsp</i> generat, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img  alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-8.png'}/>
                    </div>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                        '\n' +
                        '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                        '\n' +
                        '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %><%@\n' +
                        'taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %><%@\n' +
                        'taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %><%@\n' +
                        'taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                        '\n' +
                        '<liferay-theme:defineObjects />\n' +
                        '\n' +
                        '<portlet:defineObjects />'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, fisierul <i>view.jsp</i> generat, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-9.png'}/>
                    </div>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<p>\n' +
                        '\t<b><liferay-ui:message key="helloworld.caption"/></b>\n' +
                        '\t//\n' +
                        '\t<b><liferay-ui:message key="theme.vanilla.author"/></b>\n' +
                        '</p>'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, fisierul <i>Language.properties</i> generat, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-10.png'}/>
                    </div>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javax.portlet.title.ro_letyournailsgrow_liferay_vanilla_apps_helloworld_HelloWorldPortlet=HelloWorld\n' +
                        'helloworld.caption=Salutari din HelloWorld!'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, fisierul <i>main.scss</i> generat, va arata in modul urmator:
                    <div style={{padding:10}}>
                        <img alt={""}  style={{width:250}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/first-portlet-11.png'}/>
                    </div>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="css" style={androidstudio}>
                        {'.hello-world {\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360017885612-IntelliJ-IDEA"}>
                                    https://help.liferay.com/hc/en-us/articles/360017885612-IntelliJ-IDEA
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FirstPortletContent;