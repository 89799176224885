import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class BackpropagationContent extends BaseContentPage {

    constructor(props) {
        super(props, "backpropagation", IndexContent);
    }

    render() {
        return (
            <div className="home backpropagation">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    <b><a id={"adaline"}>1.6. Backpropagation (Regula de învățare delta generalizata)</a></b>
                    <br/>
                    <br/>
                    O rețea neuronală e formată din mai multe layere:
                    <ul>
                        <li>input layer (il vom nota cu I)</li>
                        <li>1 sau multe multe hidden layere; notând cu <b>n</b> numărul de hidden layere, vom avem: H<sub>1</sub>, H<sub>2</sub>,..., H<sub>n</sub> </li>
                        <li>output layer (il vom nota cu O)</li>
                    </ul>
                    În plus, layere de mai sus sunt complet conectate, iar fiecare conexiune are asociată o pondere.
                    <br/>
                    Notăm cu:
                        <ul>
                            <li>W<sub>1</sub> - matricea ponderilor dintre I și H<sub>1</sub></li>
                            <li>W<sub>i</sub> - matricea ponderilor dintre H<sub>i-1</sub> și H<sub>i</sub>(in general)</li>
                            <li>W<sub>n</sub> - matricea ponderilor dintre H<sub>n</sub> și O</li>
                            <li>f<sub>i</sub> - functia de activare pentru layer-ul i</li>
                            <li>T - vectorul target</li>
                            <li>q - rata de invatare</li>
                        </ul>
                    Aloritmul de backpropation presupune 2 etape:
                    <ul>
                        <li>
                            feedforward - calculeaza iesirea rețelei
                        </li>

                        <li>
                            backpropagation
                            <ul>
                                    <li>distribuie eroarea prin toata reteaua pentru a minimiza eroarea totala a retelei</li>
                                    <li>actualizeaza ponderile</li>
                            </ul>
                        </li>

                    </ul>

                    <div className={"text-justify important"}>
                        <b>1. Etapa feedfoward:</b>
                        <MathComponent tex={String.raw`O_0=I`}/>
                        <MathComponent tex={String.raw`for \space k=1..n+1:`}/>
                        <div className={"padding-50"}>
                            <MathComponent tex={String.raw`S_k=W_k O_{k-1}`}/>
                            <MathComponent tex={String.raw`O_k=f_k(S_k)`}/>
                        </div>
                    </div>

                    <div className={"text-justify important"}>
                        <b>2. Etapa backpropagation:</b>
                        <MathComponent tex={String.raw`E=T-O_{n+1}`}/>
                        <MathComponent tex={String.raw`for \space k=n+1..1:`}/>
                        <div className={"padding-50"}>
                            <MathComponent tex={String.raw`\Delta W_k = q [E f'(O_k)] \times O_{k-1}^T`}/>
                            <MathComponent tex={String.raw`E=W_k^T \times E`}/>
                            <MathComponent tex={String.raw`W_k=W_k+\Delta W_k`}/>
                        </div>
                    </div>

                </div>
                <br/>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Dumitru Popescu, Maria Luiza Flonta. 2009. Teoria rețelelor neuronale artificiale, Editura Universității din București</div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BackpropagationContent;