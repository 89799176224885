import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";
// import TestComponent from "./test.component"

class InstallTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-install", IndexContent);
    }

    isActive = true;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Instalare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npm install typescript'}
                    </SyntaxHighlighter>

                    Compilatorul se instaleaza in <b> node_modules</b> se poate fie executat cu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npx tsc --version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Version 5.0.4'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Configurare compilator</b>
                    <br/>
                    <br/>

                    Compilatorul poate fi configurat in fisierul <b>tsconfig.json</b>.
                    Acest fisier se poate creea folosind comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npx tsc --init'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Created a new tsconfig.json with:                                                                                       \n' +
                            '                                                                                                                     TS \n' +
                            '  target: es2016\n' +
                            '  module: commonjs\n' +
                            '  strict: true\n' +
                            '  esModuleInterop: true\n' +
                            '  skipLibCheck: true\n' +
                            '  forceConsistentCasingInFileNames: true\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Rulare fisier TypeScript</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npx tsc numefisier.ts'}
                    </SyntaxHighlighter>

                    <hr/>
                    De exemplu, daca avem fisierul <i>test.ts</i> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const add = (a,b)=>{\n' +
                            '    return a+b;\n' +
                            '}\n' +
                            '\n' +
                            'const c = add(1,2);\n' +
                            'console.log(c);'}
                    </SyntaxHighlighter>
                    si dam comanda
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npx tsc test.ts'}
                    </SyntaxHighlighter>
                    se va genera un fisier <i>test.js</i> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'var add = function (a, b) {\n' +
                            '    return a + b;\n' +
                            '};\n' +
                            'var c = add(1, 2);\n' +
                            'console.log(c);'}
                    </SyntaxHighlighter>

                    De exemplu, daca avem fisierul <i>test.ts</i> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const add = (a:any,b:string)=>{\n' +
                            '    return a+b;\n' +
                            '}\n' +
                            '\n' +
                            'const c = add(1,\'2\');\n' +
                            'console.log(c);'}
                    </SyntaxHighlighter>
                    si dam comanda
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npx tsc test.ts'}
                    </SyntaxHighlighter>
                    se va genera un fisier <i>test.js</i> cu urmatorul continut (identic cu cel de mai sus):
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'var add = function (a, b) {\n' +
                            '    return a + b;\n' +
                            '};\n' +
                            'var c = add(1, 2);\n' +
                            'console.log(c);'}
                    </SyntaxHighlighter>

                    <hr/>

                    Daca modificam in <i>test.ts</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'add(1,2)'}
                    </SyntaxHighlighter>
                    vom obtine urmatoare eroare:
                    <SyntaxHighlighter>
                        {'test.ts:5:17 - error TS2345: Argument of type \'number\' is not assignable to parameter of type \'string\'.\n' +
                            '\n' +
                            '5 const c = add(1,2);\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Adaugare TypeScript la un proiect React</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npm install --save typescript @types/node @types/react @types/react-dom @types/jest'}
                    </SyntaxHighlighter>

                    Adaugare in <b>tsconfig.json</b> in sectiunea <b>compilerOptions</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'"jsx": "react-jsx"'}
                    </SyntaxHighlighter>

                    Apoi schimbare extensie la orice fisier ce se doreste TypeScript de la <b>.js</b> la <b>.tsx</b>
                    <br/>
                    Apoi restartare server!

                    {/*<TestComponent/>*/}

                    In Intelij pentru a se face verificarea fisierelor de catre editor trebuie configurat TSLint:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'npm install --save-dev eslint'}
                    </SyntaxHighlighter>
                    Apoi <b>Ctrl+Alt+S</b>:
                    <ul>
                        <li>Languages & Frameworks | JavaScript | Code Quality Tools | ESLint {"=>"}  Automatic ESLint configuration option.</li>
                    </ul>

                    {/*{this.isActive || "A0"}*/}
                    {/*{this.isActive ?? "A1"}*/}
                    {/*{this.isActive ? "A2":'B2'}*/}


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallTypescriptContent;