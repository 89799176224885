import {useRoutes} from "react-router-dom";
import JavaSpringSecurityHomeContent from "./IndexContent";
import JavaSpringSecurityEx01Content from "./JavaSpringSecurityEx01Content";
import JavaSpringSecurityEx02Content from "./JavaSpringSecurityEx02Content";
import JavaSpringSecurityEx03Content from "./JavaSpringSecurityEx03Content";
import JavaSpringSecurityEx04Content from "./JavaSpringSecurityEx04Content";
import JavaSpringSecurityEx05Content from "./JavaSpringSecurityEx05Content";
import JavaSpringSecurityEx06Content from "./JavaSpringSecurityEx06Content";
import React from "react";

//
// <Route path="/java-spring-security/index" element={<JavaSpringSecurityHomeContent/>} exact/>
// <Route path="/java-spring-security/ex-01" element={<JavaSpringSecurityEx01Content/>} exact/>
// <Route path="/java-spring-security/ex-02" element={<JavaSpringSecurityEx02Content/>} exact/>
// <Route path="/java-spring-security/ex-03" element={<JavaSpringSecurityEx03Content/>} exact/>
// <Route path="/java-spring-security/ex-04" element={<JavaSpringSecurityEx04Content/>} exact/>
// <Route path="/java-spring-security/ex-05" element={<JavaSpringSecurityEx05Content/>} exact/>
// <Route path="/java-spring-security/ex-06" element={<JavaSpringSecurityEx06Content/>} exact/>


export default function Router() {
    let element = useRoutes([
        { path: "/java-spring-security/index", element: <JavaSpringSecurityHomeContent /> },
        { path: "/java-spring-security/ex-01", element: <JavaSpringSecurityEx01Content /> },
        { path: "/java-spring-security/ex-02", element: <JavaSpringSecurityEx02Content /> },
        { path: "/java-spring-security/ex-03", element: <JavaSpringSecurityEx03Content /> },
        { path: "/java-spring-security/ex-04", element: <JavaSpringSecurityEx04Content /> },
        { path: "/java-spring-security/ex-05", element: <JavaSpringSecurityEx05Content /> },
        { path: "/java-spring-security/ex-06", element: <JavaSpringSecurityEx06Content /> },
    ]);

    return element;
}