import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ReactBabelAppContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-babel-react-app", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. React de la 0: Noțiuni introductive</b>
                    <br/>
                    <br/>

                    Pentru a utiliza React în proiectul dvs., puteți încărca două scripturi React de pe un site web extern numit unpkg.com:
                    <ul>
                        <li><b>react</b>: este biblioteca de bază React</li>
                        <li><b>react-dom</b>: oferă metode specifice DOM care vă permit să utilizați React cu DOM</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<script src="https://unpkg.com/react@17/umd/react.development.js"></script>\n' +
                            '<script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<html>\n' +
                            '  <body>\n' +
                            '    <div id="app"></div>\n' +
                            '    <script src="https://unpkg.com/react@17/umd/react.development.js"></script>\n' +
                            '    <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>\n' +
                            '    <!-- Babel Script -->\n' +
                            '    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>\n' +
                            '    <script type="text/jsx">\n' +
                            '      const app = document.getElementById(\'app\');\n' +
                            '      ReactDOM.render(<h1>Develop. Preview. Ship. 🚀</h1>, app);\n' +
                            '    </script>\n' +
                            '  </body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    În loc să manipulați direct DOM-ul cu JavaScript simplu,
                    puteți utiliza metoda <b>ReactDOM.render()</b> de la <b>react-dom</b> pentru a spune <i>React</i> să ne randeze <i>h1</i> titlul în interiorul elementului nostru <i>#app</i>.

                    <br/>
                    Daca se omite linia 7:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>'}
                    </SyntaxHighlighter>
                    se va obține o eroare de sintaxă. Acest lucru se datorează faptului ca:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {"<script type=\"text/jsx\">\n" +
                            "      const app = document.getElementById('app');\n" +
                            "      ReactDOM.render(<h1>Develop. Preview. Ship. 🚀</h1>, app);\n" +
                            "</script>"}
                    </SyntaxHighlighter>
                    această bucată de cod este <b>JSX</b> si nu Javascript.

                    <hr/>

                    <b>2. JSX. Babel</b>
                    <br/>
                    <br/>
                    JSX este o extensie de sintaxă pentru JavaScript care vă permite să vă descrieți interfața de utilizare într-o sintaxă familiară asemănătoare HTML.
                    <br/>
                    Browserele nu înțeleg JSX de la început, așa că veți avea nevoie de un compilator JavaScript, cum ar fi un Babel , pentru a vă transforma codul JSX în JavaScript obișnuit.
                    <br/>
                    De aceea este nevoie de:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>'}
                    </SyntaxHighlighter>
                    În plus, va trebui să informați Babel ce cod să transforme schimbând tipul de script în <b>type=text/jsx</b>.

                    <hr/>
                    <b>3. Concepte</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            Componentele
                        </li>
                        <li>
                            Props
                        </li>
                        <li>
                            State
                        </li>
                    </ul>

                    <hr/>
                    <b>3.1. Componente</b>
                    <br/>
                    <br/>
                    Componentele React <b>ar trebui să fie scrise cu majuscule</b> pentru a le distinge de HTML simplu și JavaScript:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function Header() {\n' +
                            '  return <h1>Develop. Preview. Ship. 🚀</h1>;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Componentele React <b>se folosesc în același mod în care ați folosi etichetele HTML obișnuite, cu paranteze unghiulare</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'ReactDOM.render(<Header />, app);'}
                    </SyntaxHighlighter>

                    Exemplu de mai sus, rescris, folosind componenta <i>Header</i>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<html>\n' +
                            '  <body>\n' +
                            '    <div id="app"></div>\n' +
                            '    <script src="https://unpkg.com/react@17/umd/react.development.js"></script>\n' +
                            '    <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>\n' +
                            '    <!-- Babel Script -->\n' +
                            '    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>\n' +
                            '    <script type="text/jsx">\n' +
                            '      const app = document.getElementById(\'app\');\n' +
                            '\t  function Header() {\n' +
                            '\t\treturn <h1>Develop. Preview. Ship - v2 🚀</h1>;\n' +
                            '\t  }\n' +
                            '\n' +
                            '      ReactDOM.render(<Header />, app);\n' +
                            '    \n' +
                            '    </script>\n' +
                            '  </body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3.1. Props</b>
                    <br/>
                    <br/>

                    In consola se va afisa continutul variabilei <i>proprietati</i>:
                    <SyntaxHighlighter>
                        {' {"title": "kj"}'}
                    </SyntaxHighlighter>
                    Aceasta va contine toate valorile atributelor date componentei, sub forma:
                    <SyntaxHighlighter>
                        {' {"nume-atribut": "valoare-atribut"}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'    <script type="text/jsx">\n' +
                            '      const app = document.getElementById(\'app\');\n' +
                            '\t  function Header(proprietati) {\n' +
                            '\t    console.log(proprietati);\n' +
                            '\t\treturn <h1>Develop. Preview. Ship - v2 🚀</h1>;\n' +
                            '\t  }\n' +
                            '\n' +
                            '      ReactDOM.render(<Header title="kj" />, app);\n' +
                            '    \n' +
                            '    </script>'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<script type="text/jsx">\n' +
                            '      const app = document.getElementById(\'app\');\n' +
                            '\t  function Header(proprietati) {\n' +
                            '\t    console.log(proprietati);\n' +
                            '\t\treturn <h1>Develop. Preview. Ship - v2 🚀</h1>;\n' +
                            '\t  }\n' +
                            '\n' +
                            '      ReactDOM.render(<Header title="kj" subtitle="om-masina" />, app);\n' +
                            '    \n' +
                            '</script>'}
                    </SyntaxHighlighter>

                    va afisa in consola:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '    "title": "kj",\n' +
                            '    "subtitle": "om-masina"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Destructurarea obiectelor</b>
                    <br/>
                    <br/>

                    Pentru ca variabila <i>proprietati</i> este un obiect, se poate utiliza <b>destructurarea obiectelor</b> pentru a denumi în mod explicit valorile elementelor de <b>props</b> din parametrii funcției:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function Header({subtitle}) {\n' +
                            '\tconsole.log(subtitle);\n' +
                            '\treturn <h1>Develop. Preview. Ship - v2 🚀</h1>;\n' +
                            '}\n' +
                            'ReactDOM.render(<Header title="kj" subtitle="om-masina" />, app);'}
                    </SyntaxHighlighter>
                    Se va afisa: om-masina

                    <hr/>
                    <b>Utilizarea variabilelor în JSX</b>
                    <br/>
                    <br/>
                    Pentru a utiliza variabila pe care ați definit-o, puteți utiliza acolade {} , o sintaxă JSX specială care vă permite să scrieți JavaScript obișnuit direct în marcajul dvs. JSX.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function Header({subtitle}) {\n' +
                            '\t return <h1>{subtitle}</h1>;\n' +
                            '}\n' +
                            '\n' +
                            'ReactDOM.render(<Header title="kj" subtitle="om-masina" />, app);'}
                    </SyntaxHighlighter>

                    <b>Se poate adăuga orice expresie JavaScript (ceva care se evaluează la o singură valoare) în interiorul acoladelor</b>.
                    <br/>
                    De exemplu:
                    <ul>
                        <li>
                            O proprietate de obiect cu notație punct:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'function Header(props) {\n' +
                                    '  return <h1>{props.title}</h1>;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Un șablon literal:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'function Header({ title }) {\n' +
                                    '  return <h1>{`Cool ${title}`}</h1>;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Valoarea returnată a unei funcții:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'function createTitle(title) {\n' +
                                    '  if (title) {\n' +
                                    '    return title;\n' +
                                    '  } else {\n' +
                                    '    return \'Default title\';\n' +
                                    '  }\n' +
                                    '}\n' +
                                    '\n' +
                                    'function Header({ title }) {\n' +
                                    '  return <h1>{createTitle(title)}</h1>;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <li>
                                operatori ternari:
                                <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                    {'function Header({ title }) {\n' +
                                        '  return <h1>{title ? title : \'Default Title\'}</h1>;\n' +
                                        '}'}
                                </SyntaxHighlighter>
                            </li>
                        </li>
                    </ul>

                    <hr/>
                    <b>Iterarea prin liste</b>
                    <br/>
                    <br/>

                    Se foloseste metoda <b>array.map()</b> pentru a itera peste matrice și a utiliza o funcție săgeată pentru a mapa un nume la un element din listă
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'function HomePage() {\n' +
                            '  const names = [\'Ada\', \'Aka\', \'Mariuka\'];\n' +
                            '\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <ul>\n' +
                            '        {names.map((name) => (\n' +
                            '          <li>{name}</li>\n' +
                            '        ))}\n' +
                            '      </ul>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Dacă rulați acest cod, React  va da un avertisment cu privire la o <b>key</b> lipsă.
                    Acest lucru se datorează faptului că React are nevoie de ceva pentru a identifica în mod unic elementele dintr-o matrice, astfel încât să știe ce elemente să actualizeze în DOM.
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'function HomePage() {\n' +
                            '  const names = [\'Ada\', \'Aka\', \'Mariuka\'];\n' +
                            '\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <ul>\n' +
                            '        {names.map((name) => (\n' +
                            '          <li key="{name}">{name}</li>\n' +
                            '        ))}\n' +
                            '      </ul>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        State - Gestionare stări și evenimente
                    </b>
                    <br/>
                    <br/>

                    Pentru ca un butonul să facă ceva atunci când este făcut clic pe acesta, se folosete evenimentul <b>onClick</b>.
                    <br/>
                    In React, numele evenimentelor sunt <b>camelCased</b>. Evenimentul <i>onClick</i> este unul dintre multele evenimente posibile pe care le puteți utiliza pentru a răspunde la interacțiunea utilizatorului.
                    <br/>
                    De exemplu, puteți utiliza <i>onChange</i> pentru câmpuri de introducere sau <i>onSubmit</i> pentru formulare.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<html>\n' +
                            '  <body>\n' +
                            '    <div id="app"></div>\n' +
                            '    <script src="https://unpkg.com/react@17/umd/react.development.js"></script>\n' +
                            '    <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>\n' +
                            '    <!-- Babel Script -->\n' +
                            '    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>\n' +
                            '    <script type="text/jsx">\n' +
                            '      const app = document.getElementById(\'app\');\n' +
                            '\t  \n' +
                            '\t  function Header({subtitle}) {\n' +
                            '\t\treturn <h1>{subtitle}</h1>;\n' +
                            '\t  }\n' +
                            '\t  \n' +
                            '\t  function HomePage() {\n' +
                            '\n' +
                            '\t\t  function handleClick() {\n' +
                            '\t\t\talert(\'ai apasat pe buton\');\n' +
                            '\t\t  }\n' +
                            '\n' +
                            '\t\t  return (\n' +
                            '\t\t\t<div>\n' +
                            '\t\t\t  <Header title="kj" subtitle="om-masina" />\n' +
                            '\t\t\t  <button onClick={handleClick}>Apasa</button>\n' +
                            '\t\t\t</div>\n' +
                            '\t\t  );\n' +
                            '\t  }\n' +
                            '\t  \n' +
                            '      ReactDOM.render(<HomePage />, app);\n' +
                            '    \n' +
                            '    </script>\n' +
                            '  </body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    React are un set de funcții numite <b>hook</b>-uri. Hook-urile permit adăugarea de logica suplimentara componentelor cum ar fi <b>starea</b>.
                    <br/>
                    Stare reprezinta orice informație din interfața de utilizare care se modifică în timp, de obicei declanșată de interacțiunea utilizatorului.
                    <br/>
                    <br/>
                    Hook-ul React care gestioneaza starea se numeste: <b>useState()</b>.
                    <br/>
                    Functia <b>useState()</b> returnează o matrice și se poate accesa și utiliza acele valori ale matricei în interiorul componentei utilizând destructurarea matricei.
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function HomePage() {\n' +
                            '  const [] = React.useState();\n' +
                            '\n' +
                            '  // ...\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Primul element din matrice este <b>starea value</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function HomePage() {\n' +
                            '  const [likes] = React.useState();\n' +
                            '\n' +
                            '  // ...\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Al doilea element din matrice este o <b>funcția de update a valorii</b>.
                    <br/>
                    Aceasta funcție se poate numi oricum, dar este obișnuit să o prefixați cu <b>set</b> urmat de numele variabilei de stare pe care o actualizați:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function HomePage() {\n' +
                            '  const [likes, setLikes] = React.useState();\n' +
                            '\n' +
                            '  // ...\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se poate initializa <i>likes</i> cu valoarea 0:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function HomePage() {\n' +
                            '  const [likes, setLikes] = React.useState(0);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu complet (se initializeaza <i>likes</i> cu valoarea 5; apoi la fiecare apasare a butonui se incrementeaza valoarea variaible <i>likes</i> , pe apelul metodei <i>setLikes()</i>
                    ;in plus, Textul de pe buton se afiseaza in functie de valoarea variabilei <i>likes</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {
                            '<html>\n' +
                            '  <body>\n' +
                            '    <div id="app"></div>\n' +
                            '    <script src="https://unpkg.com/react@17/umd/react.development.js"></script>\n' +
                            '    <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>\n' +
                            '    <!-- Babel Script -->\n' +
                            '    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>\n' +
                            '    <script type="text/jsx">\n' +
                            '      const app = document.getElementById(\'app\');\n' +
                            '\t  \n' +
                            '   \n' +
                            '\t  function Header({subtitle}) {\n' +
                            '\t\treturn <h1>{subtitle}</h1>;\n' +
                            '\t  }\n' +
                            '\t  \n' +
                            '\t  function HomePage() {\n' +
                            '\t  \n' +
                            '\t      const [likes, setLikes] = React.useState(5);\n' +
                            '\n' +
                            '\t\t  function handleClick() {\n' +
                            '\t\t\talert(\'ai apasat pe buton\');\n' +
                            '\t\t\tsetLikes(likes + 1);\n' +
                            '\t\t  }\n' +
                            '\n' +
                            '\t\t  return (\n' +
                            '\t\t\t<div>\n' +
                            '\t\t\t  <Header title="kj" subtitle="om-masina" />\n' +
                            '\t\t\t  <button onClick={handleClick}>Apasat de {likes} ori</button>\n' +
                            '\t\t\t</div>\n' +
                            '\t\t  );\n' +
                            '\t  }\n' +
                            '\t  \n' +
                            '      ReactDOM.render(<HomePage />, app);\n' +
                            '    \n' +
                            '    </script>\n' +
                            '  </body>\n' +
                            '</html>'
                        }
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>:
                    Spre deosebire de <b>props</b> care sunt transmise componentelor ca prim parametru de funcție, <i>starea</i> este inițiată și stocată într-o componentă.
                    Puteți transmite informațiile despre starea componentelor copii ca <b>props</b>-uri,
                    dar logica pentru actualizarea stării ar trebui să fie păstrată în componenta în care a fost creată inițial starea.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReactBabelAppContent;