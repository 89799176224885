import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class CloudBalancerSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-cloud-balancer", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare Spring Cloud Balancer</b>

                    <br/>
                    <br/>


                    Sa presupunem ca avem 2 servicii:
                    <ul>
                        <li>
                            <i>student-service</i>
                        </li>
                        <li>
                            <i>address-service</i>
                        </li>
                    </ul>
                    Si  <i>student-service</i> apleaza servicii de pe  <i>address-service</i>.
                    <br/>
                    In plus, <i>address-service</i> este pornit pe mai multe instante, pe <i>porturi diferite</i>.
                    <br/>
                    Pentru a gestiona LoadBalancer-ul intre instantele de <i>address-service</i> se poate folosi: <b>Spring Cloud Balancer</b>.

                    <hr/>

                    Adaugare dependinte in  <i>student-service</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'// https://mvnrepository.com/artifact/org.springframework.cloud/spring-cloud-starter-loadbalancer\n' +
                            'implementation group: \'org.springframework.cloud\', name: \'spring-cloud-starter-loadbalancer\', version: \'3.1.4\'\n'}
                    </SyntaxHighlighter>


                    Adaugam clasa adnotata cu <b>@LoadBalancer</b> in  <i>student-service</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.feignclients;\n' +
                            '\n' +
                            'import org.springframework.cloud.client.loadbalancer.LoadBalanced;\n' +
                            'import org.springframework.cloud.loadbalancer.annotation.LoadBalancerClient;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            '\n' +
                            'import feign.Feign;\n' +
                            '\n' +
                            '@LoadBalancerClient(value = "address-service")\n' +
                            'public class AdrSerLoadBalConfig {\n' +
                            '\n' +
                            '\t@LoadBalanced\n' +
                            '\t@Bean\n' +
                            '\tpublic Feign.Builder feignBuilder () {\n' +
                            '\t\treturn Feign.builder();\n' +
                            '\t}\n' +
                            '\t\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Se poate testa avand 3 servere pornite:
                    <ul>
                        <li>
                            ADDRESS-SERVICE	n/a (2)	(2)	UP (2) - desktop-lenovo-y540.mshome.net:address-service:<b>9091</b> , desktop-lenovo-y540.mshome.net:address-service:<b>9094</b>
                        </li>
                        <li>
                            STUDENT-SERVICE	n/a (1)	(1)	UP (1) - desktop-lenovo-y540.mshome.net:student-service:<b>9092</b>
                        </li>
                    </ul>
                    Si in urma executarii:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'GET http://localhost:9092/api/student/getById/1'}
                    </SyntaxHighlighter>
                    se poate urmarii care instanta de <i>ADDRESS-SERVICE</i> a raspuns.

                    <hr/>
                    <b>Observatie</b>:
                    <br/>
                    <b>Spring Cloud Netflix Ribbon</b> - este deprecated. Se foloseste Spring Cloud Load Balancer.
                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default CloudBalancerSpringContent;