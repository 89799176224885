import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OopIntroductionPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-oop-introduction", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> OOP: Introducere</b>
                    <hr/>

                    <b>Definitii:</b>
                    <ul>
                        <li><b>clasa</b>: o idee, un plan (eng. blueprint), o reteta (eng. recipe), un model pentru o instanta</li>
                        <li><b>instanta</b>: o instantiere a unui clase (adesea se mai foloseste si termenul de <b>obiect</b>, deoarece <b>obiect se referă la o anumită instanță a unei clase</b>; dar, <b>termenul obiect este mai general decât instanță</b>)
                            <br/>
                            O instanta este o instanțiere fizică particulară a unei clase care ocupă memorie și are informatie/date.
                            <br/>
                            La asta se referă <b>self</b> atunci când avem de-a face cu instanțe de clasă.
                        </li>
                        <li><b>obiect</b>: reprezentarea datelor si metodelor; obiectele pot fi agresari de instante

                        </li>
                        <li><b>atribut</b>: obiect sau trăsătură de clasă; ar putea fi o <b>variabilă</b> sau o <b>metodă</b>
                            <br/>
                            Atributele clasei pot fi accesate:
                            <ul>
                                <li>
                                    notația „.”, adică <b>clasă</b>.<b>atribut</b>
                                </li>
                                <li>
                                    prin intermediul functiilor: <b>getattr()</b> si <b>setattr()</b>
                                </li>
                            </ul>

                        </li>
                        <li><b>metoda</b>: o funcție într-o clasă care este executată în numele clasei sau al obiectulu</li>
                        <li><b>tip</b>: clasa cu care a fost instantiat un obiect (clasa care a fost model pentru un obiect)</li>
                    </ul>

                    In Python orice este obiect, iar clasele sunt instante.

                    <hr/>

                    Deci, in Python orice este obiect. Deci si <b>clasele sunt tot obiecte</b>.
                    <br/>
                    Avand in vedere ca o clasa este un obiect, atunci o clasa are un tip, iar acel tip este <b>type</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    pass\n' +
                        '\n' +
                        'a = A()\n' +
                        '\n' +
                        'print(type(a)) # <class \'__main__.A\'>\n' +
                        'print(type(A)) # <class \'type\'>\n'}
                    </SyntaxHighlighter>

                    <b>Observatii:</b>
                    <ul>
                        <li>
                            <b>type</b> este o <b>metaclasă</b>, din care clasele sunt instanțe (incepand cuPython 3, <b>orice clasă este o instanță a metaclasei type</b>)
                        </li>

                        <li>
                            <b>type</b> se referă la clasa folosită pentru a instanția obiectul; este un termen general care descrie tipul oricărui obiect
                        </li>

                        <li>
                            functia <b>type(clasa_sau_obiect)</b>:  returnează informații despre clasă

                        </li>

                        <li>
                            functia <b>type(nume_clasa, tuplu_clase_de_baza, dictionar)</b>: returneaza un obiect de tip clasa nou
                        </li>
                    </ul>

                    In exemplul de mai sus:
                    <ul>
                        <li>a este o instanta a clasei A</li>
                        <li>A este o instanta a metaclasei type</li>
                        <li>type este o instanta a metaclasei type (deci o instanta a ei insasi)</li>
                    </ul>

                    Folosind functia <b>type(nume_clasa, tuplu_clase_de_baza, dictionar)</b> se pot <b>creea in mod dinamic clase!</b> Poate fi apelata cu cele 3 argumente, creand un obiect de tip clasa tip nou:
                    <ul>
                        <li>
                            nume_clasa: specifica numele clasei (atributul clasei <b>__name__</b> va prelua aceasta valoare)
                        </li>
                        <li>
                            tuplu_clase_de_baza: specifică un tuplu cu clasele de bază  ( atributul clasei <b>__bases__</b> va prelua aceasta valoare)
                        </li>
                        <li>
                            dictionar: specifică un dicționar de spațiu de nume care conține definiții pentru corpul clasei ( atributul clasei <b>__dict__</b> va prelua aceasta valoare)
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'A = type(\'A\', (), {})\n' +
                        'a = A()\n' +
                        'print(type(a)) # <class \'__main__.A\'>\n' +
                        'print(type(A)) # <class \'type\'>'}
                    </SyntaxHighlighter>

                    Continuam exemplul de mai sus, construid dinamic o clasa B, care mosteneste A, are un atribut si o metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'A = type(\'A\', (), {})\n' +
                        'a = A()\n' +
                        'print(type(a)) # <class \'__main__.A\'>\n' +
                        'print(type(A)) # <class \'type\'>\n' +
                        '\n' +
                        'def metoda(self):\n' +
                        '    print(self.atribut)\n' +
                        '    \n' +
                        'B = type(\'B\',(A,),{\n' +
                        '    \'atribut\':7,\n' +
                        '    \'metoda\':metoda\n' +
                        '})\n' +
                        '\n' +
                        'b = B()\n' +
                        'b.metoda() # 7'}
                    </SyntaxHighlighter>

                    <hr/>

                    Python vine cu o serie de tipuri încorporate (build-in), cum ar fi numere, șiruri, liste etc., care sunt folosite pentru a construi tipuri mai complexe.

                    <br/>Crearea unei clase noi creează un nou tip de obiect, permițând crearea de noi instanțe de acest tip.

                    <br/>

                    Informațiile despre clasa unui obiect sunt conținute în atributul <b>__class__</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    x="x"\n' +
                        '    def metoda(self):\n' +
                        '        pass\n' +
                        '\n' +
                        'a = A()\n' +
                        '\n' +
                        'print(type(a)) # <class \'__main__.A\'>\n' +
                        'print(type(A)) # <class \'type\'>\n' +
                        '\n' +
                        'print(a.__class__) # <class \'__main__.A\'>\n' +
                        'print(A.__class__) # <class \'type\'>\n' +
                        '\n' +
                        'print(type(a.x)) # <class \'str\'>\n' +
                        'print(type(a.metoda)) # <class \'method\'>\n' +
                        '\n' +
                        'print(a.x.__class__) # <class \'str\'>\n' +
                        'print(a.metoda.__class__) # <class \'method\'>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://realpython.com/python-metaclasses/"}>Metaclase Python</a>
                            </div>
                        </li>
                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OopIntroductionPythonContent;