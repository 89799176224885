import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../grafana/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class ChangePortGrafanaContent extends BaseContentPage  {

    constructor(props) {
        super(props, "grafana-change-port", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana - Schimbare port</b>
                    <br/>
                    <br/>

                    Setarile implicite pentru Grafana sunt pastrate in fisierul <b>/conf/default.ini</b>.
                    <br/>
                    Aceste fisier nu trebuie modificat.

                    <br/>
                    <br/>
                    Pe Windows, fișierul <b>sample.ini</b> se află în același director cu fisierul <b>defaults.ini</b>.
                    Conține toate setările comentate.
                    <br/>
                    Se face o copie a fisierului <b>sample.ini</b> numita <b>custom.ini</b>.
                    <br/>
                    Se modifica in <b>custom.ini</b> din:
                    <SyntaxHighlighter>
                        {'# The HTTP port  to use\n' +
                            ';http_port = 3000'}
                    </SyntaxHighlighter>

                    in
                    <SyntaxHighlighter>
                        {'# The HTTP port  to use\n' +
                            'http_port = 3005'}
                    </SyntaxHighlighter>

                    Test:
                    <SyntaxHighlighter>
                        {'http://localhost:3005/'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChangePortGrafanaContent;