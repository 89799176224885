import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class Resilience4jSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-resilience4j", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare Resilience4j</b>

                    <br/>
                    <br/>

                    Stari Circuit Breaker:
                    <ul>
                        <li>open: nici un apel nu este permis</li>
                        <li>closed: toate apelurile sunt permise</li>
                        <li>half-open: o partea din apeluri sunt permise</li>
                    </ul>

                    Proprietati Circuit Breaker:
                    <ul>
                        <li>slidingWindowSize: valoare numerica (=200), reprezentand numarul de apeluri luate in considerare pentru a lua decizia de a
                            trece din starea <i>closed</i> in starea <i>open</i>
                        </li>
                        <li>failureRateThreshold: prag pentru rata de esec</li>
                        <li>waithDurationInOpenState (=30s): cat timp sa in starea <i>open</i></li>
                        <li>automatic from open to half-open: daca trece automat din starea <i>open</i> in starea <i>half-open</i></li>
                        <li>permittedNumberOfCallsInHalfOpenState (=5): numarul de apeluri permise in starea <i>half-open</i></li>
                    </ul>
                    Din starea <i>half-open</i> se trece:
                    <ul>
                        <li>
                            in starea <i>open</i> daca failure {">="} failureRateThreshold
                        </li>
                        <li>
                            in starea <i>closed</i> daca failure mai mic failureRateThreshold
                        </li>
                    </ul>

                    Din starea <i>closed</i> se trece:
                    <ul>
                        <li>
                            in starea <i>open</i> daca failure rate {">="} failureRateThreshold
                        </li>
                    </ul>


                    <hr/>
                    In <i>build.gradle</i> in proiectul <i>student-service</i>, adaugam:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'  // https://mvnrepository.com/artifact/io.github.resilience4j/resilience4j-spring-boot2\n' +
                            '    implementation group: \'io.github.resilience4j\', name: \'resilience4j-spring-boot2\', version: \'1.7.1\'\n' +
                            '\n' +
                            '    // https://mvnrepository.com/artifact/org.springframework.boot/spring-boot-starter-aop\n' +
                            '    implementation group: \'org.springframework.boot\', name: \'spring-boot-starter-aop\', version: \'2.7.4\'\n' +
                            '\n' +
                            '    // https://mvnrepository.com/artifact/org.springframework.boot/spring-boot-starter-actuator\n' +
                            '    implementation group: \'org.springframework.boot\', name: \'spring-boot-starter-actuator\', version: \'2.7.4\''}
                    </SyntaxHighlighter>

                    In <i>application.properties</i> in proiectul <i>student-service</i>, adaugam:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'resilience4j.circuitbreaker.instances.addressService.sliding-window-size=10\n' +
                            'resilience4j.circuitbreaker.instances.addressService.failure-rate-threshold=50\n' +
                            'resilience4j.circuitbreaker.instances.addressService.wait-duration-in-open-state=30000\n' +
                            'resilience4j.circuitbreaker.instances.addressService.automatic-transition-from-open-to-half-open-enabled=true\n' +
                            'resilience4j.circuitbreaker.instances.addressService.permitted-number-of-calls-in-half-open-state=5\n' +
                            '\n' +
                            'resilience4j.circuitbreaker.instances.addressService.allow-health-indicator-to-fail=true\n' +
                            'resilience4j.circuitbreaker.instances.addressService.register-health-indicator=true\n' +
                            '\n' +
                            'management.health.circuitbreakers.enabled=true\n' +
                            'management.endpoints.web.exposure.include=health\n' +
                            'management.endpoint.health.show-details=always\n' +
                            '\n' +
                            '# closed - UP\n' +
                            '# open - DOWN\n' +
                            '# half-open - UNKNOWN'}
                    </SyntaxHighlighter>

                    In clasa <i>StudentService</i> se adnoteaza metoda <i>getAddressById()</i> cu <b>@CircuitBreaker</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@CircuitBreaker(name = "addressService")\n' +
                            '\tpublic AddressResponse getAddressById(long addressId){\n' +
                            '//\t\tMono<AddressResponse> addressResponseMono = webClient.get().uri("/getById/"+addressId).retrieve().bodyToMono(AddressResponse.class);\n' +
                            '//\t\treturn addressResponseMono.block();\n' +
                            '\n' +
                            '\t\treturn addressFeignClient.getById(addressId);\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Fallback Method</b>
                    <br/>
                    <br/>

                    Adnotarea <b>@CircuitBreaker</b> permite definirea unei metode de <i>fallback</i>, care se va apela
                    daca nu se poate apela serviciul <i>address-service</i>.
                    <br/>
                    Metoda <i>fallback</i>:
                    <ul>
                        <li>va returna un obiect <i>dummy</i></li>
                        <li>trebuie sa aiba acceasi signatura ca metoda adnotara (evetual de poate adauga parametrul de tip <i>Throwable</i>)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@CircuitBreaker(name = "addressService",\n' +
                            '\t\t\tfallbackMethod = "fallbackGetAddressById")\n' +
                            '\tpublic AddressResponse getAddressById (long addressId) {\n' +
                            '\t\tAddressResponse addressResponse = \n' +
                            '\t\t\t\taddressFeignClient.getById(addressId);\n' +
                            '\t\t\n' +
                            '\t\treturn addressResponse;\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tpublic AddressResponse fallbackGetAddressById(long addressId, Throwable th) {\n' +
                            '\t\treturn new AddressResponse();\n' +
                            '\t}'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. AOP</b>
                    <br/>
                    <br/>

                    Pentru a folosi AOP, pentru ca trebuie facut un proxy, se vor muta cele 2 metode de mai sus intr-o clasa adnotata cu <b>@Service</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {
                            'package ro.ibrid.learn.microservices.spring.service;\n' +
                            '\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            '\n' +
                            'import io.github.resilience4j.circuitbreaker.annotation.CircuitBreaker;\n' +
                            'import ro.ibrid.learn.microservices.spring.feignclients.AddressFeignClient;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.AddressResponse;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class CommonService {\n' +
                            '\n' +
                            '    Logger logger = LoggerFactory.getLogger(CommonService.class);\n' +
                            '\n' +
                            '    long count = 1;\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    AddressFeignClient addressFeignClient;\n' +
                            '\n' +
                            '    @CircuitBreaker(name = "addressService",\n' +
                            '            fallbackMethod = "fallbackGetAddressById")\n' +
                            '    public AddressResponse getAddressById (long addressId) {\n' +
                            '        logger.info("count = " + count);\n' +
                            '        count++;\n' +
                            '        AddressResponse addressResponse = addressFeignClient.getById(addressId);\n' +
                            '\n' +
                            '        return addressResponse;\n' +
                            '    }\n' +
                            '\n' +
                            '    public AddressResponse fallbackGetAddressById (long addressId, Throwable th) {\n' +
                            '        logger.error("Error = " + th);\n' +
                            '        return new AddressResponse();\n' +
                            '    }\n' +
                            '\n' +
                            '}\n' +
                            '\n'
                        }
                    </SyntaxHighlighter>

                    Refactorizam clasa <i>StudentService</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.service;\n' +
                            '\n' +
                            'import io.github.resilience4j.circuitbreaker.annotation.CircuitBreaker;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import ro.ibrid.learn.microservices.spring.entity.Student;\n' +
                            'import ro.ibrid.learn.microservices.spring.feignclients.AddressFeignClient;\n' +
                            'import ro.ibrid.learn.microservices.spring.repository.StudentRepository;\n' +
                            'import ro.ibrid.learn.microservices.spring.request.CreateStudentRequest;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.AddressResponse;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.StudentResponse;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class StudentService {\n' +
                            '\n' +
                            '\tprivate StudentRepository studentRepository;\n' +
                            '\n' +
                            '//\t@Autowired\n' +
                            '//\tprivate WebClient webClient;\n' +
                            '\n' +
                            '//\t@Autowired\n' +
                            '//\tprivate AddressFeignClient addressFeignClient;\n' +
                            '\n' +
                            '\t@Autowired\n' +
                            '\tCommonService commonService;\n' +
                            '\n' +
                            '\tpublic StudentService(StudentRepository studentRepository){\n' +
                            '\t\tthis.studentRepository = studentRepository;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic StudentResponse createStudent(CreateStudentRequest createStudentRequest) {\n' +
                            '\n' +
                            '\t\tStudent student = new Student();\n' +
                            '\t\tstudent.setFirstName(createStudentRequest.getFirstName());\n' +
                            '\t\tstudent.setLastName(createStudentRequest.getLastName());\n' +
                            '\t\tstudent.setEmail(createStudentRequest.getEmail());\n' +
                            '\t\t\n' +
                            '\t\tstudent.setAddressId(createStudentRequest.getAddressId());\n' +
                            '\t\tstudent = studentRepository.save(student);\n' +
                            '\n' +
                            '\t\tStudentResponse studentResponse = new StudentResponse(student);\n' +
                            '\t\tstudentResponse.setAddressResponse(commonService.getAddressById(student.getAddressId()));\n' +
                            '\n' +
                            '\t\treturn studentResponse;\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tpublic StudentResponse getById (long id) {\n' +
                            '\n' +
                            '\t\tStudent student = studentRepository.findById(id).get();\n' +
                            '\t\tStudentResponse studentResponse = new StudentResponse(student);\n' +
                            '\t\tstudentResponse.setAddressResponse(commonService.getAddressById(student.getAddressId()));\n' +
                            '\t\treturn studentResponse;\n' +
                            '\t}\n' +
                            '//\n' +
                            '//\t@CircuitBreaker(name = "addressService", fallbackMethod = "fallbackGetAddressById")\n' +
                            '//\tpublic AddressResponse getAddressById(long addressId){\n' +
                            '////\t\tMono<AddressResponse> addressResponseMono = webClient.get().uri("/getById/"+addressId).retrieve().bodyToMono(AddressResponse.class);\n' +
                            '////\t\treturn addressResponseMono.block();\n' +
                            '//\n' +
                            '//\t\treturn addressFeignClient.getById(addressId);\n' +
                            '//\t}\n' +
                            '//\n' +
                            '//\tpublic AddressResponse fallbackGetAddressById(long addressId, Throwable th) {\n' +
                            '//\t\treturn new AddressResponse();\n' +
                            '//\t}\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>
                    <br/>
                    Pentru operatii critice (plati electronice de exemplu) nu trebuie folosit <i>Circuit Breaker</i> pentru acesta returneaza obiecte <i>dummy</i>

                    <hr/>
                    <b>Observatie</b>:
                    <br/>
                    <b>Spring Cloud Hystrix</b> - este deprecated. Se foloseste Resilience4j.
                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default Resilience4jSpringContent;