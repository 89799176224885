import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CssLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-css", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CssLiferayContent;