import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerVolumeResumeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-volume-resume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Volume</b>
                    <br/>
                    <br/>
                    Volumele sunt directoare pe hard disc-ul masinii host (in afara container-ului) care sunt <i>montate</i> (facute disponibile, mapate) in container.

                    <br/>
                    In fisierul <b>Dokerfile</b> se foloseste instructiunea <b>VOLUME</b>.
                    <br/>
                    De exemplu pentru configura volum pentru directorul <i>app/director</i> din container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'VOLUME ["app/director"]'}
                    </SyntaxHighlighter>

                    Exista 2 tipuri de stocare a datelor extern:
                    <ul>
                        <li>
                            <b>volumes</b> - gestionate de Docker; utile pentru persistarea datelor
                            <br/>
                            volumele pot fi:
                            <ul>
                                <li>anonime:
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'VOLUME ["app/director"]'}
                                    </SyntaxHighlighter>
                                    se afiseaza folosind comanda <b>docker volume ls</b>

                                    <br/>
                                    Detalii:
                                    <ul>
                                        <li>nu stim unde salveaza pe masina gazda;</li>
                                        <li>
                                            <b>volumele anomime exista cat containerele exista!</b> (sunt sterse cand containerul este sters; se creeaza cand containerul este creat)
                                        </li>
                                        <li>
                                            volumele anonime sunt atasate unui singur container si au aceeasi durata de viata ca si container-ul de care sunt atasate.
                                        </li>
                                        <li>
                                            Volumele anonime sunt eliminate automat atunci când un container este sters doar daca containerul a fost pornit cu optiunea -rm
                                        </li>
                                        <li>
                                            nu pot fi share-uite intre containere
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    denumite, se specifica la crearea container-ului, folosind flag-ul <b>-v</b>:
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v nume_volum:/app/director [imagine]'}
                                    </SyntaxHighlighter>

                                    directorul <i>/app/director</i> din interiorul container-ului are numele <i>nume_volum</i>

                                    <br/>
                                    Detalii:
                                    <ul>
                                        <li> nu stim unde salveaza pe masina gazda; nu pot editate; (pe linux, e pe masina gazda in: /var/lib/docker/volumes)
                                            <br/>
                                            doar ca <i>root</i> se poate accesa <i>/var/lib/docker/volumes</i>
                                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                                {'sudo su -'}
                                            </SyntaxHighlighter>
                                        </li>
                                        <li> <b>volumele denumite nu se sterg cand containerele sunt sterse!</b></li>
                                        <li> volumele denumite nu sunt atasate unui container.</li>
                                        <li>pot fi share-uite intre containere</li>
                                    </ul>


                                </li>
                            </ul>
                            se gestioneaza utilizand comanda:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker volume '}
                            </SyntaxHighlighter>

                            pentru mai multe detalii:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker volume --help'}
                            </SyntaxHighlighter>
                            pentru a lista volumele:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker volume ls'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>bind mounts</b> - gestionate de utilizator
                            <br/>
                            Detalii:
                            <ul>
                                <li>
                                    utilizatorul <b>defineste unde este directorul pe masina gazda</b>.
                                </li>
                                <li>
                                    nu se specifica in fisierul <i>Dockerfile</i> ci la crearea container-ului:

                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v ./User/proiect/src:/app [imagine]'}
                                    </SyntaxHighlighter>
                                    {/*(--mount type=bind, source="/User/proiect/src", target=/app)*/}
                                    sau (pt linux, macOs):
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v $(pwd):/app [imagine]'}
                                    </SyntaxHighlighter>
                                    sau (windows)
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v "%cd%":/app [imagine]'}
                                    </SyntaxHighlighter>
                                    in acest mod supracriem tot ce e in container in directorul /app cu ce e pe masina gazda;

                                </li>
                                <li>
                                    in mod implicit volumele sunt <b>read-write</b>:
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v ./User/proiect/src:/app [imagine]'}
                                    </SyntaxHighlighter>
                                    adica:
                                    <ul>
                                        <li>
                                            <i>/User/proiect/src</i> este rw
                                        </li>
                                        <li>
                                            <i>/app</i> (din container) este rw
                                        </li>
                                    </ul>
                                    dar putem specifica ca <i>/app</i> (din container) este fie doar <b>read only</b> (<b>ro</b>):
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v ./User/proiect/src:/app:ro [imagine]'}
                                    </SyntaxHighlighter>

                                    daca in /app avem un subdirector (de exemplu: /app/temp) care trebuie sa fie rw,
                                    atunci mai creeam un volum care sa suprascrie pentru acest subdidrector comportamentul de ro:

                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v ./User/proiect/src:/app:ro -v /app/temp [imagine]'}
                                    </SyntaxHighlighter>

                                    o alta optiune/configurare este <b>delegated</b>:
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v ./User/proiect/src:/var/www/html:delegated -v /app/temp [imagine]'}
                                    </SyntaxHighlighter>
                                    actiunea de scriere efectuata de container s-ar putea sa nu se reflecte imediat pe sistemul de fisiere a masinii gazde (si uneori nici nu conteaza acest lucru)
                                    <br/>
                                    <br/>

                                    o alta optiune/configurare este <b>cached</b>:
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker run -v ./User/proiect/src:/var/www/html:cached -v /app/temp [imagine]'}
                                    </SyntaxHighlighter>
                                    este o optimizare daca se fac multe scrieri pe  sistemul de fisiere a masinii gazde, iar cotainerul doar citeste

                                    Rezumat optiuni:
                                    <ul>
                                        <li>
                                            <b>ro</b>
                                        </li>
                                        <li>
                                            <b>delegated</b>
                                        </li>
                                        <li>
                                            <b>cached</b>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    util pentru: hot reload, in faza de dezvoltare.
                                </li>
                            </ul>
                            <br/>

                            Folosind volume, unele directoare din container sunt conectate, link-eaza catre directoare de pe masina gazda.
                            <br/>
                            pentru a specfica Docker/container ca in sistemului de fisiere intern al container-ului exista directoare pe care nu le vrem sa fie supracrise,
                            atunci se pot folosi <b>volumele anonime</b>:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -v /app/node_modules [imagine]'}
                            </SyntaxHighlighter>
                            este anonim, pentru ca nu are un nume inainte (ex: -v nume:/app/node_modules);
                            <br/>
                            este echivalent cu declararea in fisierul <i>Dockerfile</i>:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'VOLUME ["/app/node_modules"]'}
                            </SyntaxHighlighter>
                            <b>Regula: calea cea mai specifica castiga!</b> in declararea mai multor volume.

                            <br/>
                            Diferenta intre <i>volum numit</i> si <i>bind mounts</i> este ca la bind mounts calea este absoluta (si nu relativa)
                            <br/>
                            Trebuie sa ne asiguram ca containerul poate aceesa directorul de pe masina gazda (se poate defini in Docker Desktop {"->"} Resources).

                        </li>
                    </ul>

                    <hr/>
                    <b>2. Gestionare volume</b>
                    <br/>
                    <br/>

                    Help:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume --help'}
                    </SyntaxHighlighter>

                    Listare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume ls'}
                    </SyntaxHighlighter>

                    Listare, cu filtrare (afisare volumele care nu sunt atasate la nici un container):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume ls --filter "dangling=true"'}
                    </SyntaxHighlighter>

                    Creare volum manual:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume create [nume-volum]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exercitiu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume create kj-volume'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'kj-volume'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo su -'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /var/lib/docker/volumes'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls kj-volume'}
                    </SyntaxHighlighter>

                    va afisa
                    <SyntaxHighlighter>
                        {'_data'}
                    </SyntaxHighlighter>
                    <hr/>

                    Inspectare volum:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume inspect [nume-volum]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume inspect kj-volume'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "CreatedAt": "2022-12-19T00:24:54+02:00",\n' +
                            '        "Driver": "local",\n' +
                            '        "Labels": {},\n' +
                            '        "Mountpoint": "/var/lib/docker/volumes/kj-volume/_data",\n' +
                            '        "Name": "kj-volume",\n' +
                            '        "Options": {},\n' +
                            '        "Scope": "local"\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            "Mountpoint": "/var/lib/docker/volumes/kj-volume/_data" {"=>"} indica calea pe masina gazda unde e volumul
                        </li>
                    </ul>

                    <hr/>
                    Daca montam acest volum la un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -rm itd --name alpha-ubuntu -v kj-volume:/etc ubuntu:latest'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            atunci pe masina gazda, in directorul <i>var/lib/docker/volumes/kj-volume/_data</i>
                            vom regasi fisierele si directoarele din dirctorul <i>/etc/</i> din container.
                        </li>
                        <li>
                            daca mergem in directorul <i>/var/lib/docker/volumes/kj-volume/_data</i> si creem un nou fisier,
                            aceste se va vedea si in container
                        </li>
                        <li>
                            daca vrem ca volumnul sa fie readonly (adica din container sa nu se poata scrie in volum):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run --rm -itd --name alpha-ubuntu --mount source=kj-volume,destination=/etc,readonly ubuntu:latest'}
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -rm itd --name alpha-ubuntu -v kj-volume:/etc:ro ubuntu:latest'}
                            </SyntaxHighlighter>
                            daca vom incerca sa scriem in directorul /etc/:
                            <SyntaxHighlighter>
                                {'touch: cannot touch \'test.txt\': Read-only file system'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>

                    Stergere volum (daca nu este folosit; in caz contrar se va afisa un mesaj de eroare):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume rm [nume-volum]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume rm kj-volume'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'kj-volume'}
                    </SyntaxHighlighter>

                    Volume prune (sterge toate volumele nefolosite):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume prune'}
                    </SyntaxHighlighter>

                    Pentru a afisa volumele atasate la un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect --format "{{json .Mounts}}" [container]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Observatie</b>:
                    <ul>
                        <li>
                            Volumele anonime sunt eliminate automat atunci când un container este sters doar daca containerul a fost pornit cu optiunea <b>-rm</b>.
                            <br/>
                            Daca containerul nu a fost pornit cu optiunea <b>-rm</b>, atunci volumul anonim nu este eliminat, chiar daca se sterge containerul.
                            <br/>
                            Dacă apoi recreați și rulați din nou containerul (adică rulați docker run ...din nou), va fi creat un nou volum anonim.
                            Deci, chiar dacă volumul anonim nu a fost eliminat automat, nici nu va fi util,
                            deoarece un alt volum anonim este atașat data viitoare când containerul pornește.
                            <br/>
                            In acest mod, se pot aduna o grămadă de volume anonime neutilizate. Aceste se pot sterge prin:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker volume rm [nume_volum]'}
                            </SyntaxHighlighter>
                            Uun volum care nu este folosit de nici un container este <i>dangling</i>; iar acestea pot fi sterse toate folosind comanda de mai jos:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker volume prune'}
                            </SyntaxHighlighter>
                            daca volumnul este folosit de un container, nu se poate sterge (chiar si daca se pune optiunea <b>--force</b>);
                            se va da eroare, in care se va specifica ce containere (id-urile) foloseste volumul respectiv.
                        </li>
                    </ul>

                    <hr/>
                    <b>Tmpfs</b>
                    <br/>
                    <br/>
                    Tmpfs sau <i>named pipes</i> sunt obiecte de tip storage nepersistente in memorie.

                    <hr/>
                    <b>Plugin-uri</b>
                    <br/>
                    <br/>

                    Afisare plugin-uri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker plugin ls'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID        NAME      DESCRIPTION   ENABLED\n'}
                    </SyntaxHighlighter>

                    Plugin-urile se gasesc pe Docker Hub.

                    <br/>
                    Pentru a instala un plugin:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker plugin install [nume-plugin]'}
                    </SyntaxHighlighter>

                    Dupa instalare se poate creea un volum, folosind acest plugin, folosind optiunea <b>driver</b> la creare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume create --driver [nume-plugin] --name [nume-volum]'}
                    </SyntaxHighlighter>

                    Atasare volum la container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --rm -itd --name alpha-ubuntu --mount type=volume, volume-driver=[nume-plugin], source=[nume-volum],destination=[destinatie-din-container] ubuntu:latest'}
                    </SyntaxHighlighter>
                    Daca vom crea un fisier <i>text.txt</i> in [destinatie-din-container], il vom regasi pe masina gazda in:
                    <ul>
                        <li>
                            /var/lib/docker/plugins/[ID-PLUGIN]/propagated-mount/[nume-volum]/text.txt
                        </li>
                    </ul>

                    <hr/>
                    <b>Volume share-uite intre containere</b>
                    <br/>
                    <br/>

                    La crearea unui container putem specifica ca vrem sa folosim un volum folosit de alt container (<i>alpha-busybox</i>),
                    folosind optiunea <b>--volume-from</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -itd --name beta busybox --volume-from alpha-busybox:ro busybox'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>ro {"=>"} inseamna read-only</li>
                    </ul>

                    {/*<hr/>*/}
                    {/*Copy-On-Write => copiere layere read-only la carearea unui container.*/}
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerVolumeResumeContent;