import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializersLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Se creaza un modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    Cu <b>build.gradle</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '    compileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '    cssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.1.0"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Cu <b>bnd.bnd</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: Ibrid Site Initializer\n' +
                            'Bundle-SymbolicName: ro.ibrid.site.initializer\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Liferay-Site-Initializer-Name: Ibrid\n' +
                            'Provide-Capability: liferay.site.initializer\n' +
                            'Web-ContextPath: /ibrid-site-initializer'}
                    </SyntaxHighlighter>

                    In bnd.bnd trebuie:
                    <ul>
                        <li>
                            declaratia <b>Provide-Capability: liferay.site.initializer</b>
                        </li>
                        <li>
                            declaratia <b>Liferay-Site-Initializer-Name</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Definirea rolurilor</b>
                    <br/>
                    <br/>
                    Pentru a crea roluri în Liferay, se creaza fișierul <b>roles.json</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'[\n' +
                            '  {\n' +
                            '    "actions": [\n' +
                            '    ],\n' +
                            '    "name": "Ibrid Zeu",\n' +
                            '    "scope": 1,\n' +
                            '    "type": 1\n' +
                            '  }\n' +
                            ']'}
                    </SyntaxHighlighter>
                    Acest lucru va crea un rol obișnuit <i>Ibrid Zeu</i>.

                    <hr/>
                    <b>2. Definirea fragmentelor</b>
                    <br/>
                    <br/>

                    Definim 2 fragmente:
                    <ul>
                        <li>
                            ibrid-header
                        </li>
                        <li>
                            ibrid-footer
                        </li>
                    </ul>

                    Vom avea urmatoarea structura:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-initializer\n' +
                            '└── fragments\n' +
                            '    └── group\n' +
                            '        └── ibrid\n' +
                            '            ├── fragment   \n' +
                            '            │    ├── ibrid-footer   \n' +
                            '            │    │   ├── fragment.json   \n' +
                            '            │    │   ├── index.css         \n' +
                            '            │    │   ├── index.html\n' +
                            '            │    │   ├── index.js\n' +
                            '            │    │   ├── index.json\n' +
                            '            │    │   └── thumbnail.png\n' +
                            '            │    │  \n' +
                            '            │    └── ibrid-header   \n' +
                            '            │        └── ...\n' +
                            '            ├── resources   \n' +
                            '            │   └── logo.svg\n' +
                            '            └── collection.json'}
                    </SyntaxHighlighter>

                    Fisierul <b>collection.json</b> defineste numele si descrierea colectieri de fragmente:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "description": "Ibrid Initializer Fragments",\n' +
                            '  "name": "Ibrid"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierele fragment se pun in directorul <b>fragment</b>.
                    <br/>
                    Fisierul <b>fragment.json</b> defineste setarile pentru un fragment:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "configurationPath": "index.json",\n' +
                            '  "cssPath": "index.css",\n' +
                            '  "htmlPath": "index.html",\n' +
                            '  "jsPath": "index.js",\n' +
                            '  "name": "Ibrid Footer",\n' +
                            '  "thumbnailPath": "thumbnail.png",\n' +
                            '  "type": "component"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Opțional, se pot adăuga resurse fragment în directorul <b>resources</b>  și be putem referi în codul fragmentului, ca in exemplul de mai jos:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<img alt="Sigla Liferay" class="h-50" \n' +
                            '    src="[ resources:logo.svg ]" \n' +
                            '    data-lfr-editable-type="image" \n' +
                            '    data-lfr-editable-id="liferay- logo" \n' +
                            '/>'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>La fiecare sincronizare, se duplica resursele!
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/d_4.png'}/>
                            </div>
                        </li>
                    </ul>

                    <i>fragmentul ibrid-footer</i>:
                    <br/>
                    <br/>

                    index.css:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'#footer .page-editor__no-fragments-state {\n' +
                            '    padding: 1vh !important;\n' +
                            '}\n' +
                            '\n' +
                            '#footer a {\n' +
                            '    font-weight: normal;\n' +
                            '    color: inherit;\n' +
                            '    text-decoration: none;\n' +
                            '}\n' +
                            '\n' +
                            '#footer a:hover {\n' +
                            '    font-weight: bold;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    index.html:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<footer id="footer" role="contentinfo" class="container-fluid py-2">\n' +
                            '    <div class="row">\n' +
                            '        <div class="col-md-2">\n' +
                            '            <!-- Logo and Header-->\n' +
                            '            <a href="/web/ibrid">\n' +
                            '                <img alt="Liferay Logo" class="h-50"\n' +
                            '                     src="[resources:logo.svg]"\n' +
                            '                     data-lfr-editable-type="image"\n' +
                            '                     data-lfr-editable-id="liferay-logo"\n' +
                            '                />\n' +
                            '                <h2 data-lfr-editable-type="rich-text"\n' +
                            '                    data-lfr-editable-id="liferay-header">\n' +
                            '                    Liferay\n' +
                            '                </h2>\n' +
                            '            </a>\n' +
                            '        </div>\n' +
                            '        <div class="col-md-8">\n' +
                            '            <!-- Navigation -->\n' +
                            '            <lfr-drop-zone></lfr-drop-zone>\n' +
                            '        </div>\n' +
                            '        <div class="col-md-2">\n' +
                            '            <!-- Social Links -->\n' +
                            '            <lfr-drop-zone></lfr-drop-zone>\n' +
                            '        </div>\n' +
                            '    </div>\n' +
                            '</footer>'}
                    </SyntaxHighlighter>

                    index.js:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "fieldSets": [\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <hr/>
                    <i>fragmentul ibrid-footer</i>:
                    <br/>
                    <br/>

                    fragment.json:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "configurationPath": "index.json",\n' +
                            '  "cssPath": "index.css",\n' +
                            '  "htmlPath": "index.html",\n' +
                            '  "jsPath": "index.js",\n' +
                            '  "name": "Ibrid Header",\n' +
                            '  "thumbnailPath": "thumbnail.png",\n' +
                            '  "type": "component"\n' +
                            '}'}
                    </SyntaxHighlighter>
                    index.css:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'#header .page-editor__no-fragments-state {\n' +
                            '    padding: 1vh !important;\n' +
                            '}\n' +
                            '\n' +
                            '#header a {\n' +
                            '    color: inherit;\n' +
                            '    text-decoration: none;\n' +
                            '    display: flex;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    index.html:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<div id="header" class="d-flex justify-content-between py-2 mx-2 mx-2">\n' +
                            '    <div class="d-flex justify-content-start">\n' +
                            '        <!-- Logo and Header-->\n' +
                            '        <a href="/web/ibrid">\n' +
                            '            <img alt="LC Logo" class="h-75"\n' +
                            '                 src="[resources:logo.svg]"\n' +
                            '                 data-lfr-editable-type="image"\n' +
                            '                 data-lfr-editable-id="ibrid-logo"\n' +
                            '            />\n' +
                            '            <h2 data-lfr-editable-type="rich-text" class="px-2"\n' +
                            '                data-lfr-editable-id="ibrid-header">\n' +
                            '                Learning Center\n' +
                            '            </h2>\n' +
                            '        </a>\n' +
                            '    </div>\n' +
                            '    <div class="d-flex justify-content-end">\n' +
                            '        <div class="px-2">\n' +
                            '            <!-- Navigation and Search -->\n' +
                            '            <lfr-drop-zone></lfr-drop-zone>\n' +
                            '        </div>\n' +
                            '        <div class="px-2">\n' +
                            '            <!-- Language Switch -->\n' +
                            '            [#if configuration.showLanguage]\n' +
                            '            [@liferay_portlet["runtime"]\n' +
                            '            portletName="com_liferay_site_navigation_language_web_portlet_SiteNavigationLanguagePortlet"/]\n' +
                            '            [/#if]\n' +
                            '        </div>\n' +
                            '        <div class="px-2">\n' +
                            '            <!-- User Account -->\n' +
                            '            [#if configuration.showPersonalBar]\n' +
                            '            [@liferay_portlet["runtime"]\n' +
                            '            portletName="com_liferay_product_navigation_user_personal_bar_web_portlet_ProductNavigationUserPersonalBarPortlet"/]\n' +
                            '            [/#if]\n' +
                            '        </div>\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    index.js:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "fieldSets": [\n' +
                            '    {\n' +
                            '      "fields": [\n' +
                            '        {\n' +
                            '          "name": "showLanguage",\n' +
                            '          "label": "Show Language",\n' +
                            '          "description": "Show Language Widget",\n' +
                            '          "type": "checkbox",\n' +
                            '          "defaultValue": true\n' +
                            '        },\n' +
                            '        {\n' +
                            '          "name": "showPersonalBar",\n' +
                            '          "label": "Show Personal Bar",\n' +
                            '          "description": "Show Personal Bar Widget",\n' +
                            '          "type": "checkbox",\n' +
                            '          "defaultValue": true\n' +
                            '        }\n' +
                            '      ]\n' +
                            '    }\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Crearea unui site pe baza unui Site Initializer</b>
                    <br/>
                    <br/>

                    Dupa ce se face deploy la modul <b>ibrid-site-initializer</b>, se urmeaza urmatorii pasii:

                    <ul>
                        <li>
                            Control Panel ~ Sites
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si-1.png'}/>
                            </div>
                        </li>
                        <li>
                            Se apasa pe butonul cu +
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si-2.png'}/>
                            </div>
                        </li>

                        <li>
                            Din <b>Select Template</b> se alege <i>Ibrid</i>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_3.png'}/>
                            </div>
                        </li>

                        <li>
                            Se alege un nume pentru site (de exemplu: <i>Ibrid</i>)
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_5.png'}/>
                            </div>
                        </li>

                        <li>
                            Pentru a vedea fragmentele definite in <b>Site Initializer</b>:
                            <ul>
                                <li>ibrid-header</li>
                                <li>ibrid-footer</li>
                            </ul>
                            Din meniul din stanga: Design ~ Fragments:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_7.png'}/>
                            </div>
                        </li>

                        <li>
                            Pentru a vedea rolul definit in <b>Site Initializer</b>:
                            <ul>
                                <li>Ibrid Zeu</li>
                            </ul>
                            Control Panel ~ Users ~ Roles:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_8.png'}/>
                            </div>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_9.png'}/>
                            </div>
                        </li>

                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializersLiferayContent;