import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InstallNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <div><b>1. Creare cont sau autentificare:</b></div>

                    <ul>
                        <li><a rel="noreferrer"  target={"_blank"} href={"https://auth.nuxeo.com"}>https://auth.nuxeo.com</a></li>

                    </ul>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>2. Creare token, de pe urmatorul link:</b></div>
                    https://connect.nuxeo.com/nuxeo/site/connect/tokens#
                    <ol>
                        <li><a rel="noreferrer"  target={"_blank"} href={"https://connect.nuxeo.com/nuxeo/site/connect/tokens#"}>https://connect.nuxeo.com/nuxeo/site/connect/tokens#</a> </li>
                    </ol>
                    Exemplu:
                    <ol>
                        <li>Token name: nuxeo-vanilla</li>
                        <li>Token: 15fa66febb8b7c3e53d873b3dbdecc5523eb1928af1d427bb152dbef493effbf</li>
                    </ol>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-install-02.png'}/>
                    </div>
                </div>

                <br/>
                <div className={"text-justify important"}>
                    <div><b>3. Download "nuxeo-server-10.10-tomcat.zip" de pe urmatorul link:</b></div>

                    <ul>
                        <li><a rel="noreferrer"  target={"_blank"} href={" https://connect.nuxeo.com/nuxeo/site/connect/distributions"}> https://connect.nuxeo.com/nuxeo/site/connect/distributions</a></li>

                    </ul>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-install-01.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-install-03.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify important"}>
                    <div><b>4. Dezarhivat in:</b></div>

                    <ul>
                        <li>D:\servers\nuxeo-server-10.10-tomcat</li>

                    </ul>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>5. Navigat in directorul bin:</b></div>

                    <ul>
                        <li>cd D:\servers\nuxeo-server-10.10-tomcat</li>
                        <li>cd bin</li>
                    </ul>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>6. Pornit Server Nuxeo:</b></div>

                    <ul>
                        <li>nuxeoctl start</li>
                        sau
                        <li>nuxeoctl restart -gui=false</li>
                    </ul>

                    <b>Posibila problema:</b>
                    <div>Could not run configuration: Address already in use: JVM_Bind: /127.0.0.1:8005M</div>
                    <br/>
                    <b>Rezolvare:</b>
                    <div>Schimbat port pentru Tomcat. Adaugat in <b>nuxeo.conf</b> urmatoarea linie:</div>
                    <ul>
                        <li>nuxeo.server.tomcat_admin.port=8055</li>
                    </ul>

                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>7. Verificare:</b></div>

                    <ul>
                        <li> http://localhost:8080/nuxeo</li>

                    </ul>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallNuxeoContent;