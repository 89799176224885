import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class SecurityContextK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-security-context", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Security Context</b>
                    <br/>
                    <br/>


                    Poate fi la nivel de:
                    <ul>
                        <li>pod</li>
                        <li>container</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: security-context-demo\n' +
                            'spec:\n' +
                            '  securityContext:\n' +
                            '    runAsUser: 1000\n' +
                            '    runAsGroup: 3000\n' +
                            '    fsGroup: 2000\n' +
                            '    capabilities: \n' +
                            '      add: ["MAC_ADMIN"]' +
                            '  containers:\n' +
                            '  - name: sec-ctx-demo\n' +
                            '    image: busybox:1.28\n' +
                            '    command: [ "sh", "-c", "sleep 1h" ]\n' +
                            '    securityContext:\n' +
                            '      allowPrivilegeEscalation: false'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SecurityContextK8sContent;