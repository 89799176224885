import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class HelmK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-helm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Helm</b>
                    <br/>
                    <br/>

                    Helm poate fi vazut ca un package manager pentru K8s.

                    <hr/>
                    <b>1. Instalare</b>
                    <br/>
                    <br/>

                    Pe ubuntu/debian:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'curl https://baltocdn.com/helm/signing.asc | gpg --dearmor | sudo tee /usr/share/keyrings/helm.gpg > /dev/null\n' +
                            'sudo apt-get install apt-transport-https --yes\n' +
                            'echo "deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/helm.gpg] https://baltocdn.com/helm/stable/debian/ all main" | sudo tee /etc/apt/sources.list.d/helm-stable-debian.list\n' +
                            'sudo apt-get update\n' +
                            'sudo apt-get install helm'}
                    </SyntaxHighlighter>

                    Pentru FreeBSD:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'pkg install helm'}
                    </SyntaxHighlighter>

                    Cu Snap:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo snap install helm --classic'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Help</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm --help'}
                    </SyntaxHighlighter>

                    Pentru a obtine informatii legate de mediul clientului:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm env'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'HELM_BIN="helm"\n' +
                            'HELM_BURST_LIMIT="100"\n' +
                            'HELM_CACHE_HOME="/home/kj/.cache/helm"\n' +
                            'HELM_CONFIG_HOME="/home/kj/.config/helm"\n' +
                            'HELM_DATA_HOME="/home/kj/.local/share/helm"\n' +
                            'HELM_DEBUG="false"\n' +
                            'HELM_KUBEAPISERVER=""\n' +
                            'HELM_KUBEASGROUPS=""\n' +
                            'HELM_KUBEASUSER=""\n' +
                            'HELM_KUBECAFILE=""\n' +
                            'HELM_KUBECONTEXT=""\n' +
                            'HELM_KUBEINSECURE_SKIP_TLS_VERIFY="false"\n' +
                            'HELM_KUBETLS_SERVER_NAME=""\n' +
                            'HELM_KUBETOKEN=""\n' +
                            'HELM_MAX_HISTORY="10"\n' +
                            'HELM_NAMESPACE="default"\n' +
                            'HELM_PLUGINS="/home/kj/.local/share/helm/plugins"\n' +
                            'HELM_REGISTRY_CONFIG="/home/kj/.config/helm/registry/config.json"\n' +
                            'HELM_REPOSITORY_CACHE="/home/kj/.cache/helm/repository"\n' +
                            'HELM_REPOSITORY_CONFIG="/home/kj/.config/helm/repositories.yaml"\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a obtine versiunea Helm:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'version.BuildInfo{Version:"v3.13.3", GitCommit:"c8b948945e52abba22ff885446a1486cb5fd3474", GitTreeState:"clean", GoVersion:"go1.20.11"}'}
                    </SyntaxHighlighter>

                    Pentru a se afisa mai detaliat (verbose) output-ul, se foloseste flag-ul <b>--debug</b>

                    <hr/>
                    <b>3. Charts</b>
                    <br/>
                    <br/>

                    Helm foloseste pasteaza valorile parametrilor intr-un fisier YAML numit <b>values.yaml</b>:
                    <SyntaxHighlighter>
                        {'numeParametru: valoareParametru'}
                    </SyntaxHighlighter>
                    De exemplu:
                    <SyntaxHighlighter>
                        {
                            'image: wordpress:4.5\n' +
                            'name: kj'
                        }
                    </SyntaxHighlighter>
                    Aceste valori se pot folosi in fisierele K8s, folosind sintaxa <b></b>:
                    <SyntaxHighlighter>
                        {'{{ .Values.numeParametru }}'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter>
                        {'-image: {{ .Values.image }}'}
                    </SyntaxHighlighter>

                    <b>Charts = templatele K8s + values.yaml</b>

                    <br/>
                    Poate exista si fisierul <b>Chart.yaml</b> care contine informatii legate de Chart.
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter>
                        {'apiVersion: v2\n' +
                            'name: liferay-k8s-helm\n' +
                            'description: Helm umbrella repo for deploying Liferay, MySQL & ElasticSearch to Kubernetes\n' +
                            '\n' +
                            '# A chart can be either an \'application\' or a \'library\' chart.\n' +
                            '#\n' +
                            '# Application charts are a collection of templates that can be packaged into versioned archives\n' +
                            '# to be deployed.\n' +
                            '#\n' +
                            '# Library charts provide useful utilities or functions for the chart developer. They\'re included as\n' +
                            '# a dependency of application charts to inject those utilities and functions into the rendering\n' +
                            '# pipeline. Library charts do not define any templates and therefore cannot be deployed.\n' +
                            'type: application\n' +
                            '\n' +
                            '# This is the chart version. This version number should be incremented each time you make changes\n' +
                            '# to the chart and its templates, including the app version.\n' +
                            'version: 0.1.0\n' +
                            '\n' +
                            '# This is the version number of the application being deployed. This version number should be\n' +
                            '# incremented each time you make changes to the application.\n' +
                            'appVersion: 1.16.0'}
                    </SyntaxHighlighter>

                    Exemplu chart pentru Lifeay: <a target={"_blank"} href={"https://github.com/triberay/liferay-k8s-helm/blob/master/Chart.yaml"}>Liferay Chart</a>

                    <hr/>
                    Exista un Hub-uri unde se pastreaza packete Kubernetes: https://artifacthub.io/  sau https://bitnami.com/

                    <br/>
                    Implicit este https://artifacthub.io/, dar se pot adauga noi repository:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm repo add bitnami https://charts.bitnami.com/bitnami'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'https://charts.bitnami.com/bitnami'}
                    </SyntaxHighlighter>

                    Se pot lista repository-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm repo list'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'NAME            URL                                                 \n' +
                            'bitnami         https://charts.bitnami.com/bitnami                  \n' +
                            'puppet          https://puppetlabs.github.io/puppetserver-helm-chart\n' +
                            'hashicorp       https://helm.releases.hashicorp.com  '}
                    </SyntaxHighlighter>

                    Se poate cauta si din linie de comanda (din hub) <b>helm search hub</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm search hub liferay'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm search hub wordpress'}
                    </SyntaxHighlighter>

                    Se poate cauta si din linie de comanda (din repo) <b>helm search repo</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm search repo jombla'}
                    </SyntaxHighlighter>
                    Se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME            CHART VERSION   APP VERSION     DESCRIPTION                                       \n' +
                            'bitnami/joomla  17.1.3          5.0.1           Joomla! is an award winning open source CMS pla...'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Comenzi</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm install [release-name] [chart-name]'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'helm install release-1 bitnami/wordpress\n' +
                            'helm install release-2 bitnami/wordpress\n' +
                            'helm install release-3 bitnami/wordpress'}
                    </SyntaxHighlighter>

                    Listare pachete instalate:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm list'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME    NAMESPACE       REVISION        UPDATED                                 STATUS          CHART           APP VERSION\n' +
                            'bravo   default         1               2023-12-17 08:22:09.701119173 -0500 EST deployed        drupal-16.1.6   10.1.7  '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm upgrade'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm rollback'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm uninstall'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter>
                        {'helm uninstall release-1'}
                    </SyntaxHighlighter>

                    Downloadare pachet, fara instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'helm pull --untar [chart-name]'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter>
                        {'helm pull --untar bitnami/wordpress'}
                    </SyntaxHighlighter>
                    (instalare dintr-un director specificat)
                    <SyntaxHighlighter>
                        {'helm install release-4 ./wordpress'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Util</b>
                    <br/>
                    <br/>
                    Verificare sistem de operare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cat /etc/*release*'}
                    </SyntaxHighlighter>

                    Poate afisa:
                    <SyntaxHighlighter>
                        {'DISTRIB_ID=Ubuntu\n' +
                            'DISTRIB_RELEASE=22.04\n' +
                            'DISTRIB_CODENAME=jammy\n' +
                            'DISTRIB_DESCRIPTION="Ubuntu 22.04.1 LTS"\n' +
                            'PRETTY_NAME="Ubuntu 22.04.1 LTS"\n' +
                            'NAME="Ubuntu"\n' +
                            'VERSION_ID="22.04"\n' +
                            'VERSION="22.04.1 LTS (Jammy Jellyfish)"\n' +
                            'VERSION_CODENAME=jammy\n' +
                            'ID=ubuntu\n' +
                            'ID_LIKE=debian\n' +
                            'HOME_URL="https://www.ubuntu.com/"\n' +
                            'SUPPORT_URL="https://help.ubuntu.com/"\n' +
                            'BUG_REPORT_URL="https://bugs.launchpad.net/ubuntu/"\n' +
                            'PRIVACY_POLICY_URL="https://www.ubuntu.com/legal/terms-and-policies/privacy-policy"\n' +
                            'UBUNTU_CODENAME=jammy\n'}
                    </SyntaxHighlighter>

                    Instalare:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'curl https://baltocdn.com/helm/signing.asc | gpg --dearmor | sudo tee /usr/share/keyrings/helm.gpg > /dev/null'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'  % Total    % Received % Xferd  Average Speed   Time    Time     Time  Current\n' +
                            '                                 Dload  Upload   Total   Spent    Left  Speed\n' +
                            '100  1699  100  1699    0     0    494      0  0:00:03  0:00:03 --:--:--   494\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apt-get install apt-transport-https --yes'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'Reading package lists... Done\n' +
                            'Building dependency tree... Done\n' +
                            'Reading state information... Done\n' +
                            'The following packages will be upgraded:\n' +
                            '  apt-transport-https\n' +
                            '1 upgraded, 0 newly installed, 0 to remove and 226 not upgraded.\n' +
                            'Need to get 1.510 B of archives.\n' +
                            'After this operation, 1.024 B of additional disk space will be used.\n' +
                            'Get:1 http://ro.archive.ubuntu.com/ubuntu jammy-updates/universe amd64 apt-transport-https all 2.4.11 [1.510 B]\n' +
                            'Fetched 1.510 B in 3s (488 B/s)              \n' +
                            '(Reading database ... 220267 files and directories currently installed.)\n' +
                            'Preparing to unpack .../apt-transport-https_2.4.11_all.deb ...\n' +
                            'Unpacking apt-transport-https (2.4.11) over (2.4.8) ...\n' +
                            'Setting up apt-transport-https (2.4.11) ...\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo "deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/helm.gpg] https://baltocdn.com/helm/stable/debian/ all main" | sudo tee /etc/apt/sources.list.d/helm-stable-debian.list'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'deb [arch=amd64 signed-by=/usr/share/keyrings/helm.gpg] https://baltocdn.com/helm/stable/debian/ all main'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get install helm'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HelmK8sContent;