import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class HelloWorldRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-hello-world", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Hello World - Rust</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cargo new hello_world'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'Created binary (application) `hello_world` package'}
                    </SyntaxHighlighter>

                    Se va creea cu director <i>hello_world</i> cu urmatoarea structura:
                    <SyntaxHighlighter>
                        {'.\n' +
                            '├── Cargo.toml\n' +
                            '└── src\n' +
                            '    └── main.rs'}
                    </SyntaxHighlighter>

                    Fisierul <i>Cargo.toml</i>:
                    <SyntaxHighlighter>
                        {'[package]\n' +
                            'name = "hello_world"\n' +
                            'version = "0.1.0"\n' +
                            'edition = "2021"\n' +
                            '\n' +
                            '[dependencies]'}
                    </SyntaxHighlighter>

                    Fisierul <i>src/main.rs</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="rust" style={androidstudio}>
                        {'fn main() {\n' +
                            '    println!("Hello, world!");\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Compilare <b>cargo build</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd hello_world'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cargo build'}
                    </SyntaxHighlighter>
                    Se poate afisa:
                    <SyntaxHighlighter>
                        {'    Finished dev [unoptimized + debuginfo] target(s) in 0.01s'}
                    </SyntaxHighlighter>

                    Compilare si rulare <b>cargo run</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cargo run'}
                    </SyntaxHighlighter>

                    Se poate afisa:
                    <SyntaxHighlighter>
                        {'   Compiling hello_world v0.1.0 (D:\\work-resources\\rust\\hello_world)\n' +
                            '    Finished dev [unoptimized + debuginfo] target(s) in 1.78s\n' +
                            '     Running `target\\debug\\hello_world.exe`\n' +
                            'Hello, world!'}
                    </SyntaxHighlighter>

                    Se poate rula si astfel:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./target/debug/hello_world'}
                    </SyntaxHighlighter>
                    Sau pe Windows:
                    <SyntaxHighlighter>
                        {'\\hello_world\\target\\debug>hello_world.exe'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HelloWorldRustContent;