import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class AddSubtitlePremierProContent extends BaseContentPage  {

    constructor(props) {
        super(props, "premiere-pro-add-subtitle", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Se selecteaza zona de lucru <b>Captions and Graphics</b>.
                    Apoi din fereastra <i>Source</i> se selecteaza tab-ul <b>Text</b>.

                    <br/>
                    <br/>
                    <b>1. Varianta manuala</b>
                    <br/>
                    <br/>
                    Se apasa pe butonul:
                    <br/>
                    <b>Create new caption track</b> {"->"} din fereastra care apare {"->"} la <b>Format</b> se alege <b>Subtitle</b> {"->"} se apasa pe butonul OK
                    <br/>
                    In timeline, in partea de sus, va apare sub marcarea timpului, o bara destinata subtitrari.
                    <br/>
                    Se da play, se asculta, se retine bucata de text spusa se subiect si mergem in tab-ul <b>Text</b>, subtab-ul <b>Caption</b>, apasam +, scriem textul.
                    <br/>
                    In bara destinata subtitrarilor va apare textul si se poate ajusta limitele afisari (cand sa incepa, cand sa dispara).

                    <br/>
                    <br/>
                    <b>2. Varianta automata</b>
                    <br/>
                    <br/>
                    Din tab-ul <b>Text</b>, subtab-ul <b>Transcript</b> se apasa pe butonul <b>Create transcript</b> {"->"} din fereastra care apare se apasa <b>Transcript</b>.
                    <br/>
                    Apoi incepe procesul de <b>Creating auto transcription</b>, care poate dura, in functie de durata clipului, cateva minute.
                    <br/>
                    Apoi, dupa ce procesul de <b>Creating auto transcription</b> s-a terminat, din subtab-ul transpript va apare subtitrarea.
                    <br/>
                    Se apasa pe butonul <b>CC</b> {"->"} <b>Create</b> si se pune pe bara destinata subtitrari, subtitrarile generate.
                    <br/>
                    Se pot face corectii, daca autogenerarea nu a functionat tocmai ok.

                    <br/>
                    <br/>
                    <b>2.1. Export</b>
                    <br/>
                    <br/>

                    Din tab-ul <b>Text</b>, subtab-ul <b>Transcript</b>, de la cele 3 puncte din coltul dreapta-sus se poate exporta subtitrarea:
                    <br/>
                    <b>Export to SRT file</b>,  <b>Export to text file</b>, <b>Export to CSV file</b>

                    <br/>
                    <br/>
                    <b>2.2. Export video cu subtitrare</b>
                    <br/>
                    <br/>
                    La export video (<b>Export Settings</b>), la sectiune <b>Captions</b> se poate alege la <b>Export Options</b> ca:
                    <ul>
                        <li>subtitrarea sa fie integrata in video (<b>Burn Captions into Video</b>)</li>
                        <li>subtitrarea sa fie intr-un fisier .srt (<b>Create Slidecar File</b>)</li>
                        <li>subtitrarea sa fie ignorata (<b>None</b>)</li>
                    </ul>

                    <br/>
                    <br/>
                    <b>3. Traducere automata din engleza in romana</b>
                    <br/>
                    <br/>

                    Pentru acest lucru se poate folosi:
                    <a href={"https://www.veed.io/subtitle-tools/translate"}> https://www.veed.io/subtitle-tools/translate</a>

                    sau
                    <a href={"https://subtitlestranslator.com/en/"}> https://subtitlestranslator.com/en/</a>

                    Sa face upload la un fisier <b>.srt</b> in limba engleza.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://photography.tutsplus.com/articles/how-to-premiere-speech-to-text--cms-38709"}>
                                   How to Caption Videos Automatically in Premiere Pro Using Speech-to-Text
                               </a><br/>
                           </div>
                       </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AddSubtitlePremierProContent;