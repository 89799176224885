import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class LocalReferencesAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-local-references", IndexContent);
    }

    number = 7;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Injectare continut</b>
                    <br/>
                    <br/>

                    Se poate seta o referinta la un element cu <b>#</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" #refInput/>\n' +
                            '<button (click)="onClick(refInput)">Apasa</button>'}
                    </SyntaxHighlighter>

                    In TS:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'onClick(input:HTMLInputElement){\n' +
                            '    alert(input.value);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Se poate folosi din <b>@ViewChild</b> pentru a accesa valoare referita:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" #refInput2/>\n' +
                            '<button (click)="onClick2()">Apasa</button>'}
                    </SyntaxHighlighter>

                    In TS:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@ViewChild(\'refInput2\') refInput2!: ElementRef;\n' +
                            '  onClick2(){\n' +
                            '    alert(this.refInput2.nativeElement.value);\n' +
                            '  }'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React se foloseste un Hook <b>useRef()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function TextInputWithFocusButton() {\n' +
                            '  const inputEl = useRef(null);\n' +
                            '  const onClick = () => {\n' +
                            '    inputEl.current.focus();\n' +
                            '  };\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <input ref={inputEl} type="text" />\n' +
                            '      <button onClick={onClick}>Focus</button>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LocalReferencesAngularContent;