import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [
        {id:"keycloak", url:"#", title:"Instalare", subtitle:""},
        {id:"keycloak-install", url:"/keycloak/install", title:"Instalare", subtitle:"", parent: "keycloak"},

        {id:"keycloak-config", url:"#", title:"Configurare", subtitle:""},
        {id:"keycloak-create-user-admin", url:"/keycloak/create-user-admin", title:"Creare administator", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-realm", url:"/keycloak/create-realm", title:"Creare realm (domeniu)", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-user", url:"/keycloak/create-user", title:"Creare utilizator", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-client", url:"/keycloak/create-client", title:"Creare client", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-client-roles", url:"/keycloak/create-client-roles", title:"Creare roluri de client", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-assign-client-roles-to-user", url:"/keycloak/assign-client-roles-to-user", title:"Asignare roluri de client la un utilizator", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-realm-roles", url:"/keycloak/create-realm-roles", title:"Creare roluri de realm", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-assign-realm-roles-to-user", url:"/keycloak/assign-realm-roles-to-user", title:"Asignare roluri de realm la un utilizator", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-config-identity-provider", url:"/keycloak/config-identity-provider", title:"Configurare Identity Provider", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-client-scopes", url:"/keycloak/create-client-scopes", title:"Creare scopuri de client", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-assign-client-scopes-to-client", url:"/keycloak/assign-client-scopes-to-client", title:"Asignare scopuri de client la un client", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-client-scopes-mappers", url:"/keycloak/client-scopes-mappers", title:"Mapari pentru scopuri de client", subtitle:"", parent: "keycloak-config"},
        {id:"keycloak-create-user-attributes", url:"/keycloak/create-user-attributes", title:"Atribute custom pentru utilizatori", subtitle:"", parent: "keycloak-config"},

        {id:"keycloak-testing", url:"#", title:"Testare", subtitle:""},
        {id:"keycloak-generate-token", url:"/keycloak/generate-token", title:"Generare token", subtitle:"", parent: "keycloak-testing"},

        {id:"keycloak-integrations", url:"#", title:"Integrari", subtitle:""},
        {id:"liferay-keycloak-integration", url:"/liferay/keycloak-integration", title:"Integrare cu Liferay", subtitle:"", parent: "keycloak-integrations"},
        {id:"nuxeo-keycloak-integration", url:"/nuxeo/keycloak-integration", title:"Integrare cu Nuxeo", subtitle:"", parent: "keycloak-integrations"},

    ]

    static indexUrl = "/keycloak/index";

    render() {
        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Keycloak
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;