import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CleaningUpLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-cleaning-up", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    <b>Curatarea unui instante de Liferay</b>
                    <br/>
                    <br/>

                    Uneori, Liferay trebuie curățat.
                    <br/>
                    Când există unele erori neașteptate, care sunt reproductibile numai într-un mediu specific - motivul poate fi în unele module memorate în cache sau vechi, conflicte, probleme de implementare etc.
                    <br/>
                    Și, în cele mai multe cazuri, doar curățarea și re-implementarea „complet” ajută.

                    <hr/>
                    Curățarea folderelor Liferay:
                    <ul>
                        <li>
                            oprire server Liferay
                        </li>
                        <li>
                            sterger directoare:
                            <ul>
                                <li>
                                    [Liferay]/osgi/modules
                                </li>
                                <li>
                                    <b>[Liferay]/osgi/state</b>
                                    <SyntaxHighlighter>
                                        {'cd [Liferay]/osgi/'}
                                    </SyntaxHighlighter>
                                    <SyntaxHighlighter>
                                        {'sudo rm state -r'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    <b>[Liferay]/work</b>
                                    <SyntaxHighlighter>
                                        {'cd [Liferay]'}
                                    </SyntaxHighlighter>
                                    <SyntaxHighlighter>
                                        {'sudo rm work -r'}
                                    </SyntaxHighlighter>
                                </li>

                                <li>
                                    [Liferay][Tomcat]/logs
                                </li>
                                <li>
                                    [Liferay][Tomcat]/temp
                                </li>
                                <li>
                                    [Liferay][Tomcat]/work
                                </li>
                            </ul>
                        </li>
                        <li>
                            pornire server Liferay
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://lifedev-solutions.blogspot.com/2019/09/cleaning-up-liferay-dxp-instance.html"}>
                                    Cleaning up a Liferay DXP instance
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CleaningUpLiferayContent;