import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionPythonGUIContent extends BaseContentPage  {

    constructor(props) {
        super(props, "python-gui-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>GUI</b> este un acronim: Interfata Grafica Utilizator.
                    <br/>
                    <br/>

                    Ecranele nu au fost considerate ca fiind parte dintr-un calculator. Pentru a controla calculatorul era nevoie de dispozitiv specializat și complet separat numit <b>terminal</b>.
                    Aceste terminal era monocron si capabil sa afiseze litere, cifre si alte cateva caractere.
                    Nu exista mouse, dar exista tastatura si uneori un <b>light pen</b> (folosit pentru a indica diferite zone ale ecranului și a acționat într-un mod foarte asemănător cu un mouse).
                    Se opera in mod textual (formatat, uneori colorat, 80 de coloane, 25 de randuri).

                    <br/>
                    <br/>

                    Crearea de aplicații capabile să utilizeze caracteristicile GUI este numită <b>programare vizuală</b>.
                    <br/>
                    Interfața grafică si utilizarea mouse-ului a creat posibilități complet noi, utilizatorul interactionand cu GUI prin gesturi:
                    <ul>
                        <li>mișcări ale mouse-ului</li>
                        <li>clickuri pe elemente</li>
                        <li>drag-and-drop elemente</li>
                    </ul>
                    Ecranele tactile au adus alte gesturi:
                    <ul>
                        <li>atingere (simplă sau dublă sau chiar mai complexă)</li>
                        <li>glisare(swiping)</li>
                        <li>ciupire (pinching)</li>
                    </ul>

                    Elementele GUI concepute pentru a primi astfel de gesturi se numesc <b>controale</b> sau <b>widget-uri</b>.

                    <br/>
                    <br/>
                    Ideea GUI a fost inspirată de <b>panoul de control electric</b>.
                    <br/>
                    O aplicatie GUI este reprezentata de o fereastra (sau ferestre) vizibile pe ecran.
                    O fereastra are:
                    <ul>
                        <li>o bara de titlu:
                            <ul>
                                <li>titlul (titlul ferestrei, care uneori poate lipsi)</li>
                                <li>un set de butoane (in functie de sistemul de operare, de exemplu: butonul de inchidere fereasta)</li>
                            </ul>
                        </li>

                        <li>continutul ferestrei, reprezentat de widget-uri (unul dintre widget-urile din interiorul unei ferestre poate deține <b>focalizarea (focus)</b>. Focus-ul se poate schimba intre widget-uri prin apasarea tastei <b>TAB</b>)
                            <br/>
                            elemente:
                            <ul>
                                <li>iconite</li>
                                <li>etichete: bucată de text în interiorul unei ferestre</li>
                                <li>butoane</li>
                            </ul>
                        </li>
                    </ul>

                    Programarea vizuală vine cu o noua paradigma de programare: <b>programare bazata pe evenimente (EDP)</b>:
                    <ul>
                        <li>există o componentă numită <b>controler de evenimente</b> care se ocupă de detectarea, înregistrarea și clasificarea tuturor acțiunilor unui utilizator (care sunt în afara controlului programatorului)</li>
                        <li>controlerul de evenimente trebuie <i>informat</i> ce se vrea sa se intample pe un anumit <b>eveniment</b> (de exemplu: click pe un buton), prin intermediul unor funcții specializate numite <b>handlere de evenimente</b></li>
                    </ul>

                    Fiecare sistem de operare oferă propriul set de servicii concepute pentru a funcționa cu GUI-ul său nativ. Deci, pentru a construi aplicații GUI portabile este nevoie de un <b>adaptor</b> (widget toolkit /  GUI toolkit / librarie UX).
                    Un astfel de adaptor este <b>Tk</b>. Modulul care aduce Tk în lumea Python se numește <b>TkInter</b>.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                                <a href={"https://edube.org/"}>PCPP1 | Introduction to GUI Programming in Python: TkInter (Professional 3/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroductionPythonGUIContent;