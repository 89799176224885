import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

const Componenta = () => {

    const getColor = () => {
        if (Math.random()>0.5){
            return 'red';
        }else{
            return "green";
        }
    }
    return(
        <>
            <p style={{backgroundColor: getColor()}}>a</p>
            <p style={{backgroundColor: getColor()}}>b</p>
        </>
    )
}

class CssReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-css", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Import CSS</b>
                    <br/>
                    <br/>

                    Import fisier CSS:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import \'./App.css\';'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Inline CSS</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<div className="main" style={{color:"red"}}>'}
                    </SyntaxHighlighter>

                    Reguli:
                    <ul>
                        <li>
                            Proprietatile sunt scrise <b>camelCased</b>; daca o proprietate contine "-", de exemplu <i>background-color</i> vor deveni <i>backgroundColor</i>.
                        </li>
                        <li>
                            Declarratiile sunt despartite de , (si nu ;)
                        </li>
                    </ul>
                    <SyntaxHighlighter>
                        {
                            '<div style={{color:"red", backgroundColor:"blue"}}>\n' +
                            '                text\n' +
                            '</div>'
                        }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Inline CSS, folosind obiecte</b>
                    <br/>
                    <br/>

                    Inline, folosind obiecte:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { React } from "react";\n' +
                            'function App() {\n' +
                            '  const styles = {\n' +
                            '    main: {\n' +
                            '      backgroundColor: "#f1f1f1",\n' +
                            '      width: "100%",\n' +
                            '    },\n' +
                            '    inputText: {\n' +
                            '      padding: "10px",\n' +
                            '      color: "red",\n' +
                            '    },\n' +
                            '  };\n' +
                            '  return (\n' +
                            '    <div className="main" style={styles.main}>\n' +
                            '      <input type="text" style={styles.inputText}></input>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '}\n' +
                            'export default App;'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4.  Module CSS</b>
                    <br/>
                    <br/>
                    Module CSS:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'/* styles.module.css */\n' +
                            '.font {\n' +
                            '  color: #f00;\n' +
                            '  font-size: 20px;\n' +
                            '}\n' +
                            '\n' +
                            'import { React } from "react";\n' +
                            'import styles from "./styles.module.css";\n' +
                            'function App() {\n' +
                            '  return (\n' +
                            '    <h1 className={styles.heading}>Hello World</h1>\n' +
                            '  );\n' +
                            '}\n' +
                            'export default App;'}
                    </SyntaxHighlighter>
                    Un modul CSS este simular cu un CSS obisnuit doar ca are extensia <b>.module.css</b> (de exemplu: styles.module.css)

                    <hr/>
                    <b>4.  Exemple</b>
                    <br/>
                    <br/>
                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const Componenta = () => {\n' +
                            '\n' +
                            '    const getColor = () => {\n' +
                            '        if (Math.random()>0.5){\n' +
                            '            return \'red\';\n' +
                            '        }else{\n' +
                            '            return "green";\n' +
                            '        }\n' +
                            '    }\n' +
                            '    return(\n' +
                            '        <>\n' +
                            '            <p style={{backgroundColor: getColor()}}>a</p>\n' +
                            '            <p style={{backgroundColor: getColor()}}>b</p>\n' +
                            '        </>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <Componenta/>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CssReactContent;