import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers9LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-9", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    In fisierul html se folosesc tag-uri:
                    <ul>
                        <li>
                            <b>data-lfr-editable-type</b>
                        </li>
                        <li>
                            <b>data-lfr-editable-id</b>
                        </li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<span data-lfr-editable-type="text"\n' +
                            '      data-lfr-editable-id="user-profile-text">User Profile</span>'}
                    </SyntaxHighlighter>


                    Fisierul index.json:
                    <SyntaxHighlighter>
                        {'"fragmentFields" : [ {\n' +
                            '  "id" : "user-profile-text",\n' +
                            '  "value" : {\n' +
                            '    "fragmentLink" : {\n' +
                            '      "value" : {\n' +
                            '        "href" : {\n' +
                            '          "value_i18n" : {\n' +
                            '            "en_US" : ""\n' +
                            '          }\n' +
                            '        }\n' +
                            '      }\n' +
                            '    },\n' +
                            '    "text" : {\n' +
                            '      "value_i18n" : {\n' +
                            '        "ro_RO" : "PROFIL UTILIZATOR",\n' +
                            '        "en_US" : "USER PROFILE"\n' +
                            '      }\n' +
                            '    }\n' +
                            '  }\n' +
                            '} ],'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu:
                    <br/>
                    Fisieurl ibrid-setup\ibrid-site-initializer\src\main\resources\site-initializer\fragments.group.ibrid\fragment\ibrid-header\index.html:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'  <h2 data-lfr-editable-type="rich-text" class="px-2"\n' +
                            '                data-lfr-editable-id="ibrid-header">\n' +
                            '                KJ Center\n' +
                            '            </h2>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alternativ, se poate folosi si:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<span class="taglib-icon-label">${languageUtil.get(request, "CHEIE-DIN-FISIER-PROPRIETATI")}</span>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<span class="taglib-icon-label">${languageUtil.get(request, "sign-out")}</span>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"}href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/embedding-portlets-and-setting-preferences-in-a-liferay-7-2-theme/maximized"}>
                                Embedding portlets and setting preferences in a Liferay 7.2 theme
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers9LiferayContent;