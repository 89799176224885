import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ServiceExtensionPointsNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-service-extension-points", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Pasi:
                    <ul>
                        <li>1. Scriere contributiei (sample contribution):
                            <br/><br/>
                            e un fisier XML:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'<?xml version="1.0"?>\n' +
                                '<component name="ro.dopamina.my-service-contrib">\n' +
                                '\t....\n' +
                                '</component>'}
                            </SyntaxHighlighter>

                            Definim extensia:

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'<?xml version="1.0"?>\n' +
                                '<component name="ro.dopamina.my-service-contrib">\n' +
                                '\t<extension target="ro.dopamina.MyService" point="retentionPolicies">\n' +
                                '\t</extension>\n' +
                                '</component>'}
                            </SyntaxHighlighter>

                            Contributia:

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'<?xml version="1.0"?>\n' +
                                '<component name="ro.dopamina.my-service-contrib">\n' +
                                '\t<extension target="ro.dopamina.MyService" point="retentionPolicies">\n' +
                                '\t\t<retentionPolicy id="short-finance-it">\n' +
                                '\t\t\t<archiving>90</archiving>\n' +
                                '\t\t\t<deletion number="190"/>\n' +
                                '\t\t\t<industry id="hr"/>\n' +
                                '\t\t\t<industry id="it"/>\n' +
                                '\t\t</retentionPolicy>\n' +
                                '\t</extension>\n' +
                                '</component>'}
                            </SyntaxHighlighter>

                        </li>
                        <li>2. Construirea descriptorului:
                            <br/><br/>
                            e o clasa Java / Descriptorul:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.dopamina\n' +
                                'import org.nuxeo.common.xmap.annotation.XNode;\n' +
                                'import org.nuxeo.comoon.xmap.annotation.XModeList;\n' +
                                'import org.nuxeo.comoon.xmap.annotation.XObject\n\n' +
                                '@XObject("RententionPolicy")\n' +
                                'public class RententionPolicyDescriptor{\n' +
                                '\t@XNode("@id") // pentru atribute\n' +
                                '\tpublic String retentionPolicyId;\n\n' +
                                '\t@XNode("archiving") // pentru tag-uri\n' +
                                '\tpublic Integer archiving;\n\n' +
                                '\t@XNode(value="deletion@number")// pentru atributul numberdin tagul deletion\n\n' +
                                '\tpublic Integer deletion;\n\n' +
                                '\t@XNodeList(value="industry@id", type=String[].class, componentType=String.class)\n' +
                                '\tpublic String[] industries;\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>3. Adaugare punct de extensie:
                            <br/><br/>

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<?xml version="1.0"?>\n' +
                                '<component name="org.mycompany.myproject.my-big-contract-service">\n' +
                                '\n' +
                                '  <require>org.mycompany.myproject.my-contract-service</require>\n' +
                                '  <implementation class="org.mycompany.myproject.MyBigContractServiceImpl" />\n' +
                                '  <service>\n' +
                                '    <provide interface="org.mycompany.myproject.api.MyBigContractService" />\n' +
                                '  </service>\n' +
                                '  <extension-point name="retention">\n' +
                                '       <object class="ro.dopamina.RententionPolicyDescriptor"/>\n' +
                                '  </extension-point>\n' +
                                '\n' +
                                '</component>'}
                            </SyntaxHighlighter>

                        </li>

                        <li>3. Actualizare logica service
                            <br/><br/>
                            implementare metode:
                            <ul>
                                <li>public void <b>activate(ComponentContent context)</b></li>
                                <li>public void <b>deactivate(ComponentContent context)</b></li>
                                <li>public void <b>registerContribution(Object contribution, String extensionPoint, ComponentInstence constributor)</b>
                                <br/>
                                    <i>Object contribution</i>  este de tip <i>ro.dopamina.RententionPolicyDescriptor</i>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                {/*<br/>*/}
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*        <div>*/}
                {/*            <a href={"https://doc.nuxeo.com/nxdoc/how-to-create-a-service/#declare-bundle-contribution"}>*/}
                {/*                HOWTO: Create a Service*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceExtensionPointsNuxeoContent;