import React from "react";
import BaseContentPage from "../BaseContentPage";

import IndexContent from "../robots/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class RuxRobotContent extends BaseContentPage {

    constructor(props) {
        super(props, "robots-rux", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>SDK pentru Rux</b>
                    <br/>
                    <br/>
                    Link: https://global.letianpai.com/all/?p=1519&v=8528837ceeea
                    <br/>
                    De aici se poate descarca un fisier AAR (Android Archive):
                    <ul>
                        <li>
                            RobotSdk-release.2.0.aar:<br/>
                            https://cdn.file.letianpai.com/b765d7f0250777afe5745ddc46d0c234/RobotSDK/RobotSdk-release.aar.2.0.zip
                        </li>
                        <li>
                            RobotSdk-release.1.0.aar: <br/>
                            https://cdn.file.letianpai.com/b765d7f0250777afe5745ddc46d0c234/RobotSDK/RobotSdk-release.aar.1.0.zip
                        </li>
                    </ul>

                    1. build.gradle:
                    <SyntaxHighlighter>
                        {'dependencies {\n' +
                            '      implementation(fileTree("libs"))\n' +
                            '}'}
                    </SyntaxHighlighter>

                    2. obtinere instanta RobotService:
                    <SyntaxHighlighter>
                        {'// Before executing an action, the servo power needs to be turned on. Do not execute it in the main thread. Only needs to be executed once.\n' +
                            'mRobotService.robotOpenMotor();\n' +
                            '\n' +
                            '// Turn off the servo power.\n' +
                            'mRobotService.robotCloseMotor();\n' +
                            '\n' +
                            'private ActionMessage mMessage = new ActionMessage();\n' +
                            'mMessage.set(\n' +
                            '    63, // number: Robot action number, refer to the robot action number.\n' +
                            '    2, // speed: Robot execution speed.\n' +
                            '    3); // stepNum: Robot step count.\n' +
                            '\n' +
                            '// Start executing the action.\n' +
                            'mRobotService.robotActionCommand(mMessage);\n' +
                            '\n' +
                            '// Unbind the RobotService.\n' +
                            'mRobotService.unbindService();'}
                    </SyntaxHighlighter>

                    3. Robot Sensor event response:
                    <SyntaxHighlighter>
                        {'// Define a SensorCallback\n' +
                            'SensorCallback callback = new SensorCallback() {\n' +
                            '@Override\n' +
                            'public void onTapResponse() {\n' +
                            '    // Callback interface triggered by a single tap on the head.\n' +
                            '}\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onDoubleTapResponse() {\n' +
                            '        // Callback interface triggered by two consecutive taps on the head\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onLongPressResponse() {\n' +
                            '        // Callback interface triggered by a long press on the head.\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onFallBackend() {\n' +
                            '        // Triggered when a cliff is detected in front.\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onFallForward() {\n' +
                            '        // Triggered when a cliff is detected behind.\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onFallRight() {\n' +
                            '         // Triggered when a cliff is detected on the left side.\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onFallLeft() {\n' +
                            '         // Triggered when a cliff is detected on the right side.\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onTof() {\n' +
                            '        // Triggered when an obstacle is detected.\n' +
                            '    }\n' +
                            '};\n' +
                            '// Turn on the sensor power\n' +
                            'mRobotService.robotOpenSensor();\n' +
                            '// Turn off the sensor power\n' +
                            'mRobotService.robotCloseSensor();\n' +
                            '// Register the callback to the sensor service\n' +
                            'mRobotService.robotRegisterSensorCallback(callback);'}
                    </SyntaxHighlighter>

                    4. Robot antenna rotation control
                    <SyntaxHighlighter>
                        {'AntennaMessage antennaMessage = new AntennaMessage();\n' +
                            'antennaMessage.set(\n' +
                            '    3,        // cmd：1 for both ears turn left, 2 for both ears turn right, 3 for both ears turn left\n' +
                            '    2,        // step: indicates the number of times the action is executed\n' +
                            '    300,      // speed: indicates the time interval for the continuous action in milliseconds\n' +
                            '    60);      // angle: indicates the amplitude of the action in degrees (0°~90°, >90° default to 90°)\n' +
                            '    \n' +
                            ' mRobotService.robotAntennaMotion(antennaMessage);'}
                    </SyntaxHighlighter>

                    5. Robot antenna light color control
                    <SyntaxHighlighter>
                        {'AntennaLightMessage alMessage = new AntennaLightMessage();\n' +
                            '// Set the antenna light color\n' +
                            'alMessage.set(Light.RED); \n' +
                            '// Turn on the antenna light and set the color\n' +
                            'mRobotService.robotAntennaLight(alMessage);\n' +
                            '// Turn off the light\n' +
                            'mRobotService.robotCloseAntennaLight（）'}
                    </SyntaxHighlighter>

                    6. Robot sound control
                    <SyntaxHighlighter>
                        {'// The robot can play pre-existing sound effects\n' +
                            'mRobotService.robotControlSound("a0003");  //Refer to the robot sound effect number'}
                    </SyntaxHighlighter>

                    7. Robot text to sound effect
                    <SyntaxHighlighter>
                        {'/ Convert text to speech and play it\n' +
                            'mRobotService.robotPlayTTs("Hello");'}
                    </SyntaxHighlighter>

                    8. Control robot status bar display（Deprecated）
                    <SyntaxHighlighter>
                        {'mRobotService.robotControlStatusBar(StatusBarCmd.COMMAND_SHOW_CHARGING);\n' +
                            '\n' +
                            'COMMAND_SHOW_CHARGING    Show the charging icon on the status bar.\n' +
                            'COMMAND_HIDE_CHARGING    Hide the charging icon on the status bar.'}
                    </SyntaxHighlighter>

                    9. Robot expression control
                    <SyntaxHighlighter>
                        {'// Enter expression mode, only needs to be executed once. The parameter represents the expression to be displayed in the mode.\n' +
                            'mRobotService.robotStartExpression("h0003"); \n' +
                            '// Switch expressions within the expression mode.\n' +
                            'mRobotService.sendExpressionCommand("h003");\n' +
                            '// Exit the expression mode.\n' +
                            'mRobotService.robotStopExpression();'}
                    </SyntaxHighlighter>

                    10. Robot action
                    <SyntaxHighlighter>
                        {'1 - walking forward (default parameters: step=n, speed=3).\n' +
                            '2 - walking backward (default parameters: step=n, speed=3).\n' +
                            '3 - turning left (default parameters: step=n, speed=3).\n' +
                            '4 - turning right (default parameters: step=n, speed=3).\n' +
                            '5 - crab walk to the left (default parameters: step=n, speed=3).\n' +
                            '6 - crab walk to the right (default parameters: step=n, speed=3).\n' +
                            '7 - shaking the left leg (default parameters: step=1, delay=2).\n' +
                            '8 - shaking the right leg (default parameters: step=1, delay=2).\n' +
                            '9 - shaking the left foot (default parameters: step=2, delay=3).\n' +
                            '10 - shaking the right foot (default parameters: step=2, delay=3).\n' +
                            '11 - lifting the left foot (default parameters: step=1, delay=1).\n' +
                            '12 - lifting the right foot (default parameters: step=1, delay=1).\n' +
                            '13 - leaning left (default parameters: step=1, delay=6).\n' +
                            '14 - leaning right (default parameters: step=1, delay=6).\n' +
                            '15 - stomping the left foot (default parameters: step=1, delay=3).\n' +
                            '16 - stomping the right foot (default parameters: step=1, delay=3).\n' +
                            '17 - swinging the body up and down (default parameters: step=1, delay=1).\n' +
                            '18 - swinging the body left and right (default parameters: step=1, delay=1).\n' +
                            '19 - shaking the head left and right (default parameters: step=2, delay=1).\n' +
                            '20 - standing at ease (default parameters: step=1, delay=1).\n' +
                            '21 - spinning in place to the left (default parameters: step=1 (≈20°), delay=3).\n' +
                            '22 - spinning in place to the right (default parameters: step=1 (≈20°), delay=3).\n' +
                            '23 - double foot shaking (default parameters: step=3, delay=1).\n' +
                            '24 - slight shaking (default parameters: step=1, delay=3).\n' +
                            '25 - slight left turn (default parameters: step=1 (≈5°), delay=3).\n' +
                            '26 - slight right turn (default parameters: step=1 (≈5°), delay=3).\n' +
                            '27 - swaying (default parameters: step=1, speed=3).\n' +
                            '28 - head shaking (default parameters: step=1, speed=3).\n' +
                            '29 - random action 29 (default parameters: step=1, speed=3).\n' +
                            '30 - random action 30 (default parameters: step=1, speed=3).\n' +
                            '31 - random action 31 (default parameters: step=1, speed=3).\n' +
                            '32 - random action 32 (default parameters: step=1, speed=3).\n' +
                            '33 - random action 33 (default parameters: minimum count=3, speed=3).\n' +
                            '34 - slight foot rotation (default parameters: minimum count=3, speed=3).\n' +
                            '35 - random action 35 (default parameters: step=1, speed=3).\n' +
                            '36 - random action 36 (default parameters: step=1, speed=3).\n' +
                            '37 - random action 37 (default parameters: step=1, speed=3).\n' +
                            '38 - slight left foot shaking (default parameters: step=1, speed=3).\n' +
                            '39 - slight right foot shaking (default parameters: step=1, speed=3).\n' +
                            '40 - random action 40 (default parameters: step=1, speed=3).\n' +
                            '41 - random action 41 (default parameters: step=1, speed=3).\n' +
                            '42 - random action 42 (default parameters: step=1, speed=3).\n' +
                            '43 - slight head shaking (default parameters: step=1, speed=3).\n' +
                            '44 - left dodge (default parameters: step=1, speed=3).\n' +
                            '45 - right dodge (default parameters: step=1, speed=3).\n' +
                            '46 - slight left dodge (default parameters: step=1, speed=3).\n' +
                            '47 - slight right dodge (default parameters: step=1, speed=3).\n' +
                            '48 - rapid shaking of both feet (default parameters: step=1, fixed speed).\n' +
                            '49 - rapid foot shaking (default parameters: step=1, fixed speed).\n' +
                            '50 - twisting back and forth (default parameters: step=1, speed=3).\n' +
                            '51 - slight left foot shaking (default parameters: step=1, speed=3).\n' +
                            '52 - slight right foot shaking (default parameters: step=1, speed=3).\n' +
                            '53 - left foot outward turn (default parameters: step=1, speed=3).\n' +
                            '54 - right foot outward turn (default parameters: step=1, speed=3).\n' +
                            '55 - left turn -3 degrees (default parameters: step=1, speed=3).\n' +
                            '56 - right turn -3 degrees (default parameters: step=1, speed=3).\n' +
                            '57: slight turn of the right foot (Default parameters: step = 1, speed = 3)\n' +
                            '58: large evasion to the right (Default parameters: step = 1, fixed speed)\n' +
                            '59: foot rubbing - rubbing in front, middle, and back (Default parameters: step = 1, speed = 3)\n' +
                            '60: foot rubbing - rubbing in front and back (Default parameters: step = 1, speed = 3)\n' +
                            '61: suspended action - lifting the leg (Default parameters: step = 1, fixed speed)\n' +
                            '62: lifting the leg and shaking the foot (Default parameters: step = 1, fixed speed)\n' +
                            '63: forward movement 2 (Default parameters: step = 1, speed = 3)\n' +
                            '64: backward movement 2 (Default parameters: step = 1, speed = 3)\n' +
                            '65: quick left foot rotation (Default parameters: minimum count = 3, fixed speed)\n' +
                            '66: quick right foot rotation (Default parameters: minimum count = 3, fixed speed)\n' +
                            '67: quick foot shaking (Default parameters: step = 1, fixed speed)\n' +
                            '68: quick foot shaking outward (Default parameters: step = 1, fixed speed)\n' +
                            '69: second method of standing on a stake (Default parameters: step = 1, speed = 1)\n' +
                            '70: third method of standing on a stake (Default parameters: step = 1, speed = 1)\n' +
                            '71: first rotation on the stake (Default parameters: step = 1, speed = 2)\n' +
                            '72: second rotation on the stake (Default parameters: step = 1, speed = 2)\n' +
                            '73: third rotation on the stake (Default parameters: step = 1, speed = 2)\n' +
                            '74: fourth rotation on the stake (Default parameters: step = 1, speed = 2)\n' +
                            '75: fifth rotation on the stake (Default parameters: step = 1, speed = 2)\n' +
                            '76: nodding (Default parameters: step = 1, speed = 3)\n' +
                            '77: saying "yeah" (Default parameters: step = 1, speed = 3)\n' +
                            '78: quick twisting (Default parameters: step = 1, speed = 1)\n' +
                            '79: body shaking (Default parameters: step = 1, speed = 1)\n' +
                            '80: dancing twisting (Default parameters: step = 2, speed = 6)'}
                    </SyntaxHighlighter>

                    11. Robot sound effect
                    <SyntaxHighlighter>
                        {'{\n' +
                            '"cmd_tag":"a0001",\n' +
                            '"cmd_tag_name":"Beep"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0005",\n' +
                            '"cmd_tag_name":"Hammering sound"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0007",\n' +
                            '"cmd_tag_name":"Laser"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0010",\n' +
                            '"cmd_tag_name":"Pulse"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0011",\n' +
                            '"cmd_tag_name":"Electromagnetic"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0012",\n' +
                            '"cmd_tag_name":"Electromagnetic 2"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0014",\n' +
                            '"cmd_tag_name":"Submachine gun"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0019",\n' +
                            '"cmd_tag_name":"Gudong"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0020",\n' +
                            '"cmd_tag_name":"Angry"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0021",\n' +
                            '"cmd_tag_name":"Buzz"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0024",\n' +
                            '"cmd_tag_name":"Obstacle avoidance"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0025",\n' +
                            '"cmd_tag_name":"Charging"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0026",\n' +
                            '"cmd_tag_name":"Charging 2"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0027",\n' +
                            '"cmd_tag_name":"Charging 3"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0028",\n' +
                            '"cmd_tag_name":"Click"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0029",\n' +
                            '"cmd_tag_name":"Alarm"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0030",\n' +
                            '"cmd_tag_name":"Comfortable"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0031",\n' +
                            '"cmd_tag_name":"Confused"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0032",\n' +
                            '"cmd_tag_name":"Hahaha"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0033",\n' +
                            '"cmd_tag_name":"Disgusted"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0034",\n' +
                            '"cmd_tag_name":"Dizziness"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0035",\n' +
                            '"cmd_tag_name":"Dizziness 2"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0036",\n' +
                            '"cmd_tag_name":"Fatigue"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0037",\n' +
                            '"cmd_tag_name":"Fear"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0038",\n' +
                            '"cmd_tag_name":"Search"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0039",\n' +
                            '"cmd_tag_name":"Surprised"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0040",\n' +
                            '"cmd_tag_name":"Complete"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0041",\n' +
                            '"cmd_tag_name":"Duplicate of 29"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0053",\n' +
                            '"cmd_tag_name":"Annoying"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0054",\n' +
                            '"cmd_tag_name":"I\'m an optimist"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0055",\n' +
                            '"cmd_tag_name":"Low battery"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0056",\n' +
                            '"cmd_tag_name":"Alarm"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0057",\n' +
                            '"cmd_tag_name":"Out of power"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0058",\n' +
                            '"cmd_tag_name":"Main jump"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0059",\n' +
                            '"cmd_tag_name":"Error"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0064",\n' +
                            '"cmd_tag_name":"Panting"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0067",\n' +
                            '"cmd_tag_name":"Ah-woo"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0069",\n' +
                            '"cmd_tag_name":"Dengdengdeng~"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0070",\n' +
                            '"cmd_tag_name":"Panicked"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0071",\n' +
                            '"cmd_tag_name":"Duplicate of 51"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0074",\n' +
                            '"cmd_tag_name":"Duplicate of 31"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0077",\n' +
                            '"cmd_tag_name":"Snoring"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0078",\n' +
                            '"cmd_tag_name":"Duplicate of 36"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0082",\n' +
                            '"cmd_tag_name":"Duplicate of 70"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag":"a0083",\n' +
                            '"cmd_tag_name":"Duplicate of 64"\n' +
                            '},\n' +
                            '    {\n' +
                            '"cmd_tag": "a0084",\n' +
                            '"cmd_tag_name": "Response"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0086",\n' +
                            '"cmd_tag_name": "Sad"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0088",\n' +
                            '"cmd_tag_name": "Power off"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0089",\n' +
                            '"cmd_tag_name": "Chirp~~"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0090",\n' +
                            '"cmd_tag_name": "Shy"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0091",\n' +
                            '"cmd_tag_name": "Repeat with 77"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0092",\n' +
                            '"cmd_tag_name": "Peek"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0093",\n' +
                            '"cmd_tag_name": "Power on"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0094",\n' +
                            '"cmd_tag_name": "Search person 2"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0095",\n' +
                            '"cmd_tag_name": "Surprised"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0097",\n' +
                            '"cmd_tag_name": "Same as 95"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0098",\n' +
                            '"cmd_tag_name": "Tired"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0099",\n' +
                            '"cmd_tag_name": "Wake up"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0100",\n' +
                            '"cmd_tag_name": "Happy birthday"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0104",\n' +
                            '"cmd_tag_name": "Ding ding ding~"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0106",\n' +
                            '"cmd_tag_name": "Play Ula"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0107",\n' +
                            '"cmd_tag_name": "Awkward"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0108",\n' +
                            '"cmd_tag_name": "Ding Ling Ling"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0112",\n' +
                            '"cmd_tag_name": "Huh?"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0113",\n' +
                            '"cmd_tag_name": "Bite and crush"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0114",\n' +
                            '"cmd_tag_name": "Drumming"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0115",\n' +
                            '"cmd_tag_name": "Proud"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0117",\n' +
                            '"cmd_tag_name": "Same as 107"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0118",\n' +
                            '"cmd_tag_name": "Honk the horn"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0120",\n' +
                            '"cmd_tag_name": "Cicada chirping"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0121",\n' +
                            '"cmd_tag_name": "Doorbell"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0124",\n' +
                            '"cmd_tag_name": "Gurgling"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0125",\n' +
                            '"cmd_tag_name": "Typing"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0126",\n' +
                            '"cmd_tag_name": "New message"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0127",\n' +
                            '"cmd_tag_name": "New message 2"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0128",\n' +
                            '"cmd_tag_name": "New message 3"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0132",\n' +
                            '"cmd_tag_name": "Phone sound 2"\n' +
                            '},\n' +
                            '{\n' +
                            '"cmd_tag": "a0133",\n' +
                            '"cmd_tag_name": "Lost"\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RuxRobotContent;