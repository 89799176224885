import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex1DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex1", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 1 (simpleweb)</b>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir simpleweb'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd simpleweb'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.1. Creare package.json</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch package.json'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano package.json'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'{\n' +
                            '  "dependencies": {\n' +
                            '    "express": "*"\n' +
                            '  },\n' +
                            '  "scripts": {\n' +
                            '    "start": "node index.js"\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Creare index.js</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch index.js'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano index.js'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'const express = require(\'express\');\n' +
                            '\n' +
                            'const app = express();\n' +
                            '\n' +
                            'app.get(\'/\', (req, res) => {\n' +
                            '  res.send(\'Hi there\');\n' +
                            '});\n' +
                            '\n' +
                            'app.listen(8080, () => {\n' +
                            '  console.log(\'Listening on port 8080\');\n' +
                            '});\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. Creare Dockerfile</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch Dockerfile'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# Specify a base image\n' +
                            'FROM alpine\n' +
                            '\n' +
                            '# Install some depenendencies\n' +
                            'RUN npm install\n' +
                            '\n' +
                            '# Default command\n' +
                            'CMD ["npm", "start"]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.4. Creare imagine - cu eroare (schimbare imaginea de baza)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    se va afisa eroare (/bin/sh: npm: not found):
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  4.096kB\n' +
                            'Step 1/3 : FROM alpine\n' +
                            ' ---> 9c6f07244728\n' +
                            'Step 2/3 : RUN npm install\n' +
                            ' ---> Running in 9a55ec01c529\n' +
                            '/bin/sh: npm: not found\n' +
                            'The command \'/bin/sh -c npm install\' returned a non-zero code: 127'}
                    </SyntaxHighlighter>
                    ceea ce inseamna ca <i>alpine</i> cu vine in mod implicit cu <i>npm</i>.

                    <br/>
                    <br/>
                    Pentru a rezolva problema exista mai multe posibilitati:
                    <ul>
                        <li>schimbam imaginea de baza (<i>alpine</i> cu una care are deja preinstalat <i>node si npm</i>)</li>
                        <li>instalam noi <i>nodejs</i>, folosind instructiunea <b>RUN</b> in <i>Dockerfile</i></li>
                    </ul>

                    Pentru a gasit o imagine potrivita cu nevoile noaste, o putem cauta pe: <b>https://hub.docker.com</b>.
                    Apoi apasam pe butonul <b>Explore</b>.
                    <br/>
                    Gasim <b>node:alpine</b> (node este numele imaginii, alpine este versiunea - alpine se refera de obicei la o versiune cu doar strictul necesat).
                    <br/>
                    Modificam <i>Dockerfile</i>. Si rulam din nou:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    se va afisa eroare (npm ERR! Tracker "idealTree" already exists):
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  4.096kB\n' +
                            'Step 1/3 : FROM node:alpine\n' +
                            'alpine: Pulling from library/node\n' +
                            '213ec9aee27d: Already exists\n' +
                            'f379b689aea3: Pull complete\n' +
                            'fe299d5780c0: Pull complete\n' +
                            'c34a027bbf26: Pull complete\n' +
                            'Digest: sha256:f829c27f4f7059609e650023586726a126db25aded0c401e836cb81ab63475ff\n' +
                            'Status: Downloaded newer image for node:alpine\n' +
                            ' ---> 867dce98a500\n' +
                            'Step 2/3 : RUN npm install\n' +
                            ' ---> Running in f107d99e5165\n' +
                            'npm ERR! Tracker "idealTree" already exists\n' +
                            '\n' +
                            'npm ERR! A complete log of this run can be found in:\n' +
                            'npm ERR!     /root/.npm/_logs/2022-10-07T21_03_04_780Z-debug-0.log\n' +
                            'The command \'/bin/sh -c npm install\' returned a non-zero code: 1'}
                    </SyntaxHighlighter>
                    deci, alta eroare.
                    <br/>
                    Pentru a rezolva problema, schimbam imaginea de baza: <b>node:14-alpine</b>
                    <br/>
                    Modificam <i>Dockerfile</i>. Si rulam din nou:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    se va afisa eroare (no such file or directory, open '/package.json'):
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  4.096kB\n' +
                            'Step 1/3 : FROM node:14-alpine\n' +
                            '14-alpine: Pulling from library/node\n' +
                            '213ec9aee27d: Already exists\n' +
                            '9bbf040c9c7e: Pull complete\n' +
                            'bdc748662e87: Pull complete\n' +
                            '1bc265bd9f07: Pull complete\n' +
                            'Digest: sha256:50808db1777e35ca156d06cfd31fa377febe82df6f26b8413a47c12dcbf5e6c5\n' +
                            'Status: Downloaded newer image for node:14-alpine\n' +
                            ' ---> 33deb4de1d99\n' +
                            'Step 2/3 : RUN npm install\n' +
                            ' ---> Running in 5a81c2c1ec82\n' +
                            'npm WARN saveError ENOENT: no such file or directory, open \'/package.json\'\n' +
                            'npm notice created a lockfile as package-lock.json. You should commit this file.\n' +
                            'npm WARN enoent ENOENT: no such file or directory, open \'/package.json\'\n' +
                            'npm WARN !invalid#2 No description\n' +
                            'npm WARN !invalid#2 No repository field.\n' +
                            'npm WARN !invalid#2 No README data\n' +
                            'npm WARN !invalid#2 No license field.\n' +
                            '\n' +
                            'up to date in 0.788s\n' +
                            'found 0 vulnerabilities\n' +
                            '\n' +
                            'Removing intermediate container 5a81c2c1ec82\n' +
                            ' ---> 871d12895c71\n' +
                            'Step 3/3 : CMD ["npm", "start"]\n' +
                            ' ---> Running in edf34030d4f9\n' +
                            'Removing intermediate container edf34030d4f9\n' +
                            ' ---> 4d4debf57b51\n' +
                            'Successfully built 4d4debf57b51'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.5. Creare imagine - cu eroare (adaugare resurse)</b>
                    <br/>
                    <br/>

                    Adaugam in fisierul Dockerfile: <b>COPY ./ ./</b> pentru a copia in container cele 2 fisiere: index.js si package.json
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# Specify a base image\n' +
                            'FROM node:14-alpine\n' +
                            '\n' +
                            'COPY ./ ./ \n' +
                            '# Install some depenendencies\n' +
                            'RUN npm install\n' +
                            '\n' +
                            '# Default command\n' +
                            'CMD ["npm", "start"]'}
                    </SyntaxHighlighter>

                    Si rulam din nou:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  4.096kB\n' +
                            'Step 1/4 : FROM node:14-alpine\n' +
                            ' ---> 33deb4de1d99\n' +
                            'Step 2/4 : COPY ./ ./\n' +
                            ' ---> 20dc410ce32f\n' +
                            'Step 3/4 : RUN npm install\n' +
                            ' ---> Running in 5a2283b8b3dc\n' +
                            'npm notice created a lockfile as package-lock.json. You should commit this file.\n' +
                            'npm WARN !invalid#2 No description\n' +
                            'npm WARN !invalid#2 No repository field.\n' +
                            'npm WARN !invalid#2 No license field.\n' +
                            '\n' +
                            'added 57 packages from 42 contributors and audited 57 packages in 4.542s\n' +
                            '\n' +
                            '7 packages are looking for funding\n' +
                            '  run `npm fund` for details\n' +
                            '\n' +
                            'found 0 vulnerabilities\n' +
                            '\n' +
                            'Removing intermediate container 5a2283b8b3dc\n' +
                            ' ---> 241e4ba19d74\n' +
                            'Step 4/4 : CMD ["npm", "start"]\n' +
                            ' ---> Running in 06ae6c414d51\n' +
                            'Removing intermediate container 06ae6c414d51\n' +
                            ' ---> 1bac8a667f87\n' +
                            'Successfully built 1bac8a667f87'}
                    </SyntaxHighlighter>

                    sa ii dam un nume/tag
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t iulianbuzdugan/websimple .'}
                    </SyntaxHighlighter>

                    rulam imaginea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run iulianbuzdugan/websimple'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run iulianbuzdugan/websimple'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'\n' +
                            '> @ start /\n' +
                            '> node index.js\n' +
                            '\n' +
                            'Listening on port 8080'}
                    </SyntaxHighlighter>

                    Dar nu va fi accesibil din browser. Pentru aceasta trebuie sa expunem portul 8080.

                    <br/>

                    Pentru aceasta cand pornim contaierul trebuie sa specificam ca toate cererile care vin de pe masina locala pe portul 8080 (sau orice alt port)
                    sa fie redirectate catre portul 8080 (sau portul pe care se asculta in container) in container
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p 8080:8080 iulianbuzdugan/websimple'}
                    </SyntaxHighlighter>

                    testare din browser (192.168.1.8 este masina virtuala de pe NAS pe care exista docker-ul):
                    <SyntaxHighlighter>
                        {'http://192.168.1.8:8080/'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.6. Specificare director de lucru</b>
                    <br/>
                    <br/>

                    Daca intram in container pentru a vedea structura de fisiere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it 7ae43e92a2b0 sh'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'/ # ls\n' +
                            'Dockerfile         dev                home               lib                mnt                opt                package.json       root               sbin               sys                usr\n' +
                            'bin                etc                index.js           media              node_modules       package-lock.json  proc               run                srv                tmp                var'}
                    </SyntaxHighlighter>
                    ceea ce nu e tocmai ok;
                    <br/>
                    Vom modifica Dockerfile pentru a specifica directorul de lucru:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# Specify a base image\n' +
                            'FROM node:14-alpine\n' +
                            '\n' +
                            'WORKDIR /usr/app\n' +
                            '\n' +
                            'COPY ./ ./\n' +
                            '\n' +
                            '# Install some depenendencies\n' +
                            'RUN npm install\n' +
                            '\n' +
                            '# Default command\n' +
                            'CMD ["npm", "start"]\n'}
                    </SyntaxHighlighter>

                    Build din nou:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {' docker build -t iulianbuzdugan/websimple .'}
                    </SyntaxHighlighter>

                    Repornire:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p 8080:8080 iulianbuzdugan/websimple'}
                    </SyntaxHighlighter>

                    Daca o sa intram iar pe container:
                    <SyntaxHighlighter>
                        {'docker exec -it 8c sh\n' +
                            '/usr/app # ls\n' +
                            'Dockerfile         index.js           node_modules       package-lock.json  package.json'}
                    </SyntaxHighlighter>
                    vom observa ca vom intra direct in directorul de lucru <i>/usr/app</i> si proiectul este mult mai organizat asa.

                    <hr/>
                    <b>1.7. Modificare cod sursa proiect</b>
                    <br/>
                    <br/>

                    Daca modificam in codul sursa al proiectului, de exemplu, <i>index.js</i> va trebuie sa reconstruim imaginea.
                    <br/>
                    Pentru a optimiza acest proces, vom copia la inceput doar package.json (COPY ./package.json) - pentru ca de acest fisier are nevoie <i>npm</i> si se modifica mai rar.
                    <br/>
                    Si restul fisierelor (sau toate) le punem dupa ce se intaleaza <i>npm</i>.
                    <br/>
                    Astfel, daca modicam doar in codul sursa, la rebuild imagine, nu va mai fi nevoie de a se executa de fiecare data instalarea <i>npm</i> (RUN npm install) - care este costisitoare ca timp - si se va lua din cache.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# Specify a base image\n' +
                            'FROM node:14-alpine\n' +
                            '\n' +
                            'WORKDIR /usr/app\n' +
                            '\n' +
                            'COPY ./package.json ./\n' +
                            '\n' +
                            '# Install some depenendencies\n' +
                            'RUN npm install\n\n' +
                            'COPY ./ ./\n' +
                            '\n' +
                            '# Default command\n' +
                            'CMD ["npm", "start"]\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex1DockerContent;