import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ImagePruneDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-image-prune", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker container prune</b>

                    <br/>
                    <br/>

                    Pentru a sterge toate imaginile nefolosite/neutilizate (adica cele care nu sunt referite de nici un container - oprit sau care ruleaza):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image prune'}
                    </SyntaxHighlighter>

                    În mod implicit, docker image prune curăță doar imaginile <b>dangling</b>.
                    O imagine <b>dangling</b> este una care:
                    <ul>
                        <li>1. nu este etichetată (TAG = [none])</li>
                        <li>
                            2. nu este referită de niciun container
                        </li>
                    </ul>
                    <SyntaxHighlighter>
                        {'docker image prune\n' +
                            '\n' +
                            'WARNING! This will remove all dangling images.\n' +
                            'Are you sure you want to continue? [y/N] y'}
                    </SyntaxHighlighter>

                    <br/>
                    <br/>


                    Pentru a sterge toate imaginile nereferite de nici un container (deci, doar conditia 2; deci, poate avea tag):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image prune -a'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'docker image prune -a\n' +
                            '\n' +
                            'WARNING! This will remove all images without at least one container associated to them.\n' +
                            'Are you sure you want to continue? [y/N] y'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImagePruneDockerContent;