import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class TransactionsLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-transactions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Tranzactii</b>
                    <br/>
                    <br/>
                    Pentru a creea o tranzactie:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'try{\n' +
                            '   DB::beginTransaction();\n' +
                            '   // operatii pe baza\n' +
                            '   DB::commit();\n' +
                            '}catch (Throwable $e){\n' +
                            '   DB::rollBack();\n' +
                            '}'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'DB::transaction(function() use($data) {\n' +
                            '// operatii pe baza\n' +
                            '});'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TransactionsLaravelContent;