import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class EditareVideoRecapitulareContent extends BaseContentPage  {

    constructor(props) {
        super(props, "premiere-pro-recapitulare", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    <b>1. Rezolutia</b>
                    <br/>
                    Rezolutii standard:
                    <ul>
                        <li>HD (High Definition): 1280 x 720</li>
                        <li>FHD (Full HD): 1920 x 1080</li>
                        <li>UHD (Ultra HD): 3840 x 2160</li>
                        <li>4K: 4096 x 2160</li>
                    </ul>

                    <hr/>
                    <b>2. Organizarea unui proiect </b>
                    <br/>
                    Fiecare tip de material ar trebuie sa fie in propriul bin:
                    <ul>
                        <li>video (brut (camera1 (card1, card2), camera2 (card,...)), vfx)</li>
                        <li>audio (dialog, muzica, sfx (efecte se sunete))</li>
                        <li>imagini (fotografi, grafica, logo)</li>
                        <li>text (generic, lista numele persoanelor care apar in clip)</li>
                    </ul>


                    <hr/>
                    <b>3. Taiere clip in Fereastra Source</b>
                    <br/>

                    Un clip poate fi taiat in fereastra <i>Source</i> marcand inceputul si sfarsitul:
                    <ul>
                        <li><b>Mark In (I)</b></li>
                        <li><b>Mark Out (O)</b></li>
                    </ul>

                    <hr/>
                    <b>4. Trim tool </b>
                    <br/>

                    Un clip din fereastra Timeline poate fi marit sau micsorat, mergand la unul din capete (apare un marker ca o paranteza patrata rosie) si tragand in sensul dorit.

                    <br/>
                    Pentru a folosi trim tool independent de partea video/audio, trebuie tinuta apasata tasta <b>ALT</b>.

                    <hr/>
                    <b>5. Track Select Forward Tool (A)</b>
                    <br/>
                    <b>Track Select Forward Tool (A)</b>: are rolul de a selecta tot ce este in partea dreapta de pe track-ul cu care sunt cu mouse-ul in momentul respectiv
                    <br/>
                    in combinatie cu <b>Shift + click</b> va selecta totul din partea dreapta doar de pe track-ul pe care sunt cu mouse-ul in momentul respectiv

                    <hr/>
                    <b>6. Creare subclipuri </b>
                    <br/>

                    In fereastra Source, putem marca cu in (I) si out (O) in cadrul unui clip. Pentru al face subclip: click dreapta pe clip {"->"} din meniul contextul {"->"} <b>Make Subclip...</b>.
                    <br/>
                    Se va deschide o fereastra in care i se va da un nume: <i>numar_cadru</i>_<i>numar_dubla</i> (ex: 1_1, 1_2, etc)
                    <br/>
                    In fereastra Bin, va apare noul subclip (ca un clip de sine statator).
                    <br/>
                    Ca mod de organizare, se va creea un nou bin (ex: 1) cu secventa orginala si subclipurile ei.

                    <hr/>
                    <b>7. Effect Controls </b>
                    <br/>
                    <br/>

                    Tab-ul <b>Effect Controls</b> ne permite sa alteram proprietati ale clipurilor din timeline:
                    <ul>
                        <li>Motion (Position, Scale (permite Zoom in / Zoom Out), Rotation, etc)</li>
                        <li>Opacity (Opacity, Blend Mode)</li>
                    </ul>

                    <hr/>
                    <b>8. Efecte Video </b>
                    <br/>

                    Din tab-ul de materiale:
                    <br/>
                    <b>Effects</b> {"->"} <b>Video Effects</b>.
                    <br/>
                    Efecte:
                    <ul>
                        <li>Blur & Sharpen {"->"} Gaussian Blur </li>
                    </ul>

                    <hr/>
                    <b>9. Tranzitii </b>
                    <br/>

                    Tranzitiile se aplica la inceputul si finalul clipurilor sau intre 2 clipuri consecutive.

                    <hr/>
                    <b>10. Text / forme </b>
                    <br/>

                    Pentru a adauga text sau forme se foloseste:
                    <ul>
                        <li>instrumentul <b>Text</b>.
                            <br/>
                            Cu textul selectat din <b>Effect Controls</b> putem modifica proprietatile.
                        </li>
                        <li>
                            Modificarea proprietatilor se poate face si din <b>Essential Graphics</b> (activarea ecranului de Essential Graphics: Window {"->"} Essential Graphics).
                            <br/>
                            Sau selectat spatiul de lucru <b>Graphics</b>.
                        </li>
                    </ul>

                    <hr/>
                    <b>11. Animatie, keyframe-uri </b>
                    <br/>

                    <ul>
                        <li><b>Effect Controls</b>

                            <br/>

                            Keyframe-urile sunt puncte folosite cand se activeaza <b>Toogle animation</b>.
                            <br/>
                            Pentru a adauga un nou punct (Keyframe) se apasa pe punctul dintre navigarea intre keyframe-uri.
                            <br/>
                            Pentru a dubla un punc: <b>Alt + click stanga mouse</b> pe un keyframe

                        </li>
                        <li>
                            <b>direct pe clip video din timeline</b>
                            <br/>
                            click dreapta pe <b>fx</b> {"->"} din meniul contextual {"->"} Opacity {"->"} Opacity
                            <br/>
                            folosind CTRL + click stanga se pot adauga keyframe-uri pe bara de pe clip si se poate modifica opacitatea intre puncte
                        </li>

                        <li>
                            <b>direct pe audio din timeline</b>
                            <br/>
                            folosind CTRL + click stanga se pot adauga keyframe-uri pe bara de pe clip si se poate modifica volumul intre puncte.
                        </li>
                    </ul>

                    <hr/>
                    <b>12. Export</b>
                    <br/>

                    <ul>
                        <li>Salvare proiect</li>
                        <li>Fereastra de timeline trebuie selectata</li>
                        <li>Marcare In si Out</li>
                        <li>File {"->"} Export {"->"} Media (CTRL+M)</li>
                        <li>Export Settings
                            <ul>
                                <li>Format (H.264, H.265, MPEG2,... (pt video), MP3, AIFF... (pt audio), GIF, PNG... (pt imagini) etc): indica codecul video pe care vrem sa il folosim la export</li>
                                <li>Preset: YouTube 1080p Full HD,....: preia setarile din preset</li>
                                <li>Frame rate (cadenta): 25fps, 24fps..etc</li>
                                <li>Bit rate:
                                <br/>
                                    de exemplu youtube recomanda la o rezolutie de 1080p - 8Mbps (24fps,25fps,30fps) si 12Mbps (48fps,50fps,60fps)
                                    <br/>
                                    de exemplu vimeo recomanda la o rezolutie de 1080p - 10-20Mbps
                                </li>
                                <li>Bitrate Settings: Bitrate Encoding: VBR, 2pass (pentru a scoate cel mai mic fisier posibil - exportul va dura mai mult)</li>
                                <li>Export</li>
                            </ul>
                        </li>
                    </ul>
                    Cel mai folosit codec pentru export: <b>H.264</b>

                    <hr/>
                    <b>13. Sincron audio-video</b>
                    <br/>

                    Pentru a putea face sincron este nevoie de:
                    <ul>
                        <li>2 clipuri <i>comune</i> cu sunet</li>
                        <li>clacheta (daca clipul nu are sunet, exista sunet doar pe lavaliera)</li>
                    </ul>


                    <hr/>
                    <b>14. Secventa multicamera</b>
                    <br/>
                    <br/>

                    Seventele multicamera ne ajuta la a monta un material care a fost inregistrat cu mai multe camere, pozitionate in diverse unchiuri.
                    Meterialul il vrem montat, alternand intre camerele cu care a fost inregistrat.


                    <hr/>
                    <b>15. Slow-motion </b>

                    <br/>

                    Daca avem un clip filmat la 60fps (slow-motion)..vrem sa il redam la 24fps, aceea ce rezulta ca il putem incetini de 2.5x ori (miscarea naturala)

                    <br/>
                    Pentru a avea efect de slow-motion e nevoie ca filmarea sa se faca la un numar dublu de fps (ex: minim 50fps, pentru a fi adus la 25fps, cu o incetinire de 2 ori)

                    <br/>
                    <br/>
                    Moduri de a realiza slow-motion:
                    <ul>
                        <li>Bara de intrumente: <b>Rate Stretch Tool (R)</b> (lungeste materialul/secventa din clip si <b>nu aduce material in plus</b>)

                        </li>

                        <li>click dreapta pe clip {"->"}<b>Speed/Duration</b>

                        </li>

                        <li>
                            pentru a modifica viteza de redare in interiorul unui clip:
                            <br/>
                            click-dreapta pe <b>fx</b> {"->"} din meniul contextual {"->"} <b>Time Remapping {"->"} Speed</b>
                            <br/>
                            apoi cu CTRL sau din bara de intrumente se selecteaza Pen Tool (P) se pune, de exemplu, 2 puncte, pe linia care reprezinta timpul (time remapping);
                            <br/>
                            sectiunea dintre puncte poate fi incetinita (tragand in jos bara intre puncte) sau poate fi redata intr-u mod mai alert (tragand in sus bara intre puncte)
                        </li>
                    </ul>


                    <hr/>
                    <b>16. Crop / Decuparea </b>
                    <br/>
                    <b>Effects {"->"} Video Effects {"->"} Transform {"->"} Crop</b>
                    <br/>
                    Apoi din Effect Controls {"->"} Crop (se pot modificat Left, Top, Right, Bottom, Edge Feather si utiliza keyframe-uri)

                    <hr/>
                    <b>17. Clonare, masti </b>

                    <br/>
                    <br/>

                    Clonarea se poate face in 2 moduri (cele 2 clipuri trebuie filmate avand acelasi punct de statie - nemiscat):
                    <ul>
                        <li>crop (Effects {"->"} Video Effects {"->"} Transform {"->"} Crop; apoi cu Left - daca clipurile sunt destul de bine delimitate)</li>
                        <li>masca (<b>Effect Controls- {"->"} Opacity {"->"} creare masca (Free draw bezier)</b> si apoi folosind <b>Pen Tool</b> desena masca)</li>
                    </ul>

                    <hr/>
                    <b>18. Chroma </b>
                    <br/>
                    <br/>

                    Se trage urmatorul efect peste clipul nostru:
                    <ul>
                        <li>
                            <b>Effects {"->"} Video Effects {"->"} Keying {"->"} Ultra Key</b>
                        </li>
                    </ul>

                    Se poate elimina din partile chromei folosind:
                    <ul>
                        <li>Efectul Crop</li>
                        <li>Masca din Opacity</li>
                    </ul>
                    Efectul de Ultra Key va trebui mutat dupa Efectul de Crop.

                    <hr/>
                    <b>19. Nest </b>
                    <br/>

                    Pentru a grupa mai multe clipuri si folosi ca un tot unitar (sau a selecta un segment de clipuri care sunt pe track-uri diferite):
                    <ul>
                        <li>se selecteaza segmentul sau clipurile</li>
                        <li>click dreapta {"->"} <b>Nest</b></li>
                        <li>din fereastra care va apare, se poate da un nume acestei grupari</li>
                        <li>si va apare ca un singur clip</li>
                        <li>mai mult decat atat se va regasi printre bin-urile noastre ca o <i>secventa</i></li>
                    </ul>

                    La dublu click pe secventa (sau pe clipul nested) se va deschide secventa si putem face modificari in interior ei.
                    <br/>
                    Daca se efectueaza modificari si ne intoarcem in secventa nest acestea se vor reflecta si in secventa nest
                    (de exemplu, daca scurtam clipul de la sfarsit, secventa nest va marca acest lucru prin hasurare la sfarsit).

                    <hr/>
                    <b>20. Marker, label</b>
                    <br/>
                    <br/>

                    Instrumente utile in organizare:
                    <ul>
                        <li>Marker</li>
                        <li>Label</li>
                    </ul>


                    <hr/>
                    <b>21. Editare audio, dialog, muzica</b>
                    <br/>
                    <br/>
                    <b>Vocea trebuie sa bata pana la -6db</b>
                    <br/>
                    <b>Muzica de fundal trebuie sa bata pana la -12db</b>
                    <br/>
                    <b>Diferenta dintre voce si muzica de fundal trebuie sa fie de -6db</b>

                    <br/>
                    <br/>

                    <br/>
                    Pentru a face modificari audio: <b>Window {"->"} Essential Sound</b>.

                    <hr/>
                    <b>22. Crop/scale pe 4k</b>
                    <br/>
                    Daca clipul final va fi Full HD, atunci avand un clip filmat 4k, se poate face <i>scale</i> din <i>Effect Controls</i>, fara pericolul de a pierde din claritate (4k are destul informatie)

                    <hr/>
                    <b>23. Colorizare Premiere Pro (lumetri color)</b>
                    <br/>
                    <br/>

                    La colorizarea, e important ca compresia sa fie mica (fisier e mai mare)
                    <b>Window {"->"}Lumetri Color</b>

                    <hr/>
                    <b>24. Spatiu de culoare</b>
                    <br/>
                    <br/>
                    Spatiul de culoare pentru dispozitive digitale: <b>Reg.709</b> (pe foto echivalentul este sRGB).
                    <br/>
                    (alte spatii de culoare: DPI-P3, Adobe RGB, Rec.2020).

                    <hr/>
                    <b>24. Faze principale la editare video</b>
                    <br/>
                    <br/>
                    <ul>
                        <li>import</li>
                        <li>editare</li>
                        <li>export</li>
                    </ul>

                    <hr/>
                    <b>25. Scurtaturi</b>
                    <br/>
                    <br/>
                    <ul>
                        <li><b>Ctrl+K</b> - taie in doua clipul selectat, in dreptul capul de redare</li> (<i>cut in place</i>)
                        <li>subselectie, in momentul in care nu vrem sa selectam tot clipul ci doar o parte din acesta:
                            <ul>
                                <li><b>I</b> - mark in; marcam punctul de unde vrem sa inceapa selectia</li>
                                <li><b>O</b> - mark out; marcam punctul unde vrem sa se termine selectia</li>
                            </ul>
                        </li>
                        <li>Razor Tool (<b>C</b>): desparte un clip in doua</li>
                        <li>Ripple Edit Tool (<b>B</b>)</li>
                        <li>Cursorul (<b>V</b>)</li>
                        <li>
                            <b>Track Select Forward Tool (A)</b>
                        </li>
                        <li>
                                <b>Track Select Backward Tool (Shift+A)</b>
                        </li>
                        <li>
                            <b>Ripple Edit Tool (B)</b>
                            <br/>
                            Seamana cu Trim Tool (paranteza patrata rosie), dar acesta este o paranteza patrata <i>galbena</i>,
                            dar cu acesta cand mergem la marginea unui clip sa il marim sau microram acesta:
                            <ul>
                                <li>nu va fi contrans de ce in stanga sau in dreapta, impindand restul clipurilor in stanga sau in dreapta</li>
                                <li>nu va lasa spatii libere</li>
                            </ul>
                        </li>

                        <li>
                            <b>Rolling Edit Tool (N)</b>
                            <br/>
                            Se foloseste doar la intalnirea dintre 2 clipuri, avand rolul de <i>alungi sfarsitul clipului din stanga</i> si <i>a scurta inceputul clipului din dreapta</i>
                            sau a <i>scurta sfarsitul clipului din stanga</i> si <i>a lungi inceputul clipului din dreapta</i>
                        </li>

                        <li>
                            <b>Rate Stretch  Tool (R)</b>
                            <br/>
                            Se ocupa cu accelerarea sau decelerarea timpului (slow motion) unui clip.
                            Se foloseste ca <b>Trim tool</b>. Dar nu aduce si nici nu scoate informatie, ci doar grabeste sau decelereaza informatia.
                        </li>

                        <li>
                            <b>Sleep Tool (Y)</b>
                            <br/>
                            Se foloseste doar in cadrul unui singur clip si numai daca are una din margini (din stanga sau din dreapta) taiate.
                            <br/>
                            De exemplu, daca taiem 3 secunde din inceputul si 3 secunde din sfarsitul unui clip, atunci Sleep Tool:
                            <ul>
                                <li>va pastra selectia de timp pe care am facut-o</li>
                                <li>va schimba inceputul si sfarsitul clipului in limita informatie care a ramas in afara selectiei</li>
                            </ul>
                            Cumva, acest tool <i>aluneca</i> cu totul informatia spre stanga sau spre dreapta, pastrand durata de timp a clipului.
                        </li>

                        <li>
                            <b>Slide Tool (U)</b>
                            <br/>
                            Acest tool, desi selecteza un clip, va avea efecte asupra clipului din stanga si clipului din dreapta.
                            Astfel, mutand un clip spre dreapta, se va lungi clipul din stanga (se modifica sfarsitul clipului din stanga, deci avem informatie noua)
                            si se va micsora clipul din dreapta (se modifica inceptul clipului din dreapta, deci va pierde informatie),
                            fara a modifica durata totala a secventei.
                        </li>

                        <li>
                            <b>Hand Tool (H)</b>
                            <br/>
                            Are rolul de a ne plimba pe parcursul timeline-ului, dar fara a selecta nici un clip.
                        </li>

                        <li>
                            <b>Zoom Tool (z)</b>
                            <br/>
                            Are rolul de a:
                            <ul>
                                <li>mari timeline-ul</li>
                                <li>microra timeline-ul (tinand apasata tasta ALT)</li>
                                <li>face selectii si de face zoom fix pe selectia facuta</li>
                            </ul>
                        </li>

                        <li>
                            <b>Type Tool (T)</b>
                            <br/>
                            Permite scrierea de titluri, texte.
                            <br/>
                            Creaza un layer nou, deasupra clipului activ.
                        </li>

                        <li><b>J</b> - play inainte</li>
                        <li><b>K</b> - stop</li>
                        <li><b>L</b> - play inapoi</li>


                        <li><b>Shift + L</b> - play inapoi mai fin</li>
                        <li><b>Shift + J</b> - play inainte mai fin</li>

                        <li>
                            <b>Q</b> - taie clipul curent de la capul de redare si pastreaza doar ce e in partea dreapta ([sterge]|[pastreaza]);
                            <br/>
                            taie inceputul
                            <br/>
                            avantajul e ca nu lasa <i>spatii goale</i>
                            <br/>
                            (se sterge si audio daca e selectat)
                            <br/>
                            <a href={"https://www.youtube.com/watch?v=vCgqT6eHa0w&ab_channel=Olufemii"}>Fast Editing Tip in Premiere Pro | Q AND W Keys</a>
                        </li>
                        <li>
                            <b>W</b> - taie clipul curent de la capul de redare si pastreaza doar ce e in partea stanga ([pasteaza]|[sterge]);
                            <br/>
                            taie sfarsitul
                            <br/>
                            avantajul e ca nu lasa <i>spatii goale</i>
                            <br/>
                            (se sterge si audio daca e selectat)
                            <br/>
                            <a href={"https://www.youtube.com/watch?v=vCgqT6eHa0w&ab_channel=Olufemii"}>Fast Editing Tip in Premiere Pro | Q AND W Keys</a>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <div>*/}
                    {/*          */}
                    {/*       </div>*/}
                    {/*   </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EditareVideoRecapitulareContent;