import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class GetStaticPathsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-getstaticpaths", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Preluarea datelor: getStaticPaths() </b>
                    <br/>
                    <br/>


                    Dacă o pagină are Rute dinamice (de exemplu /pages/[id].js) și folosește <b>getStaticProps</b>, trebuie să definească o listă de căi care să fie generate static.

                    <br/>
                    Când exportați o funcție numită <b>getStaticPaths</b> (Static Site Generation) dintr-o pagină care utilizează rute dinamice,
                    Next.js va pre-rada static toate căile specificate de getStaticPaths.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// pages/posts/[id].js\n' +
                            '\n' +
                            '// Generates `/posts/1` and `/posts/2`\n' +
                            'export async function getStaticPaths() {\n' +
                            '  return {\n' +
                            '    paths: [{ params: { id: \'1\' } }, { params: { id: \'2\' } }],\n' +
                            '    fallback: false, // can also be true or \'blocking\'\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            '// `getStaticPaths` are nevoie de `getStaticProps`\n' +
                            'export async function getStaticProps(context) {\n' +
                            '  return {\n' +
                            '    // Passed to the page component as props\n' +
                            '    props: { post: {} },\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            'export default function Post({ post }) {\n' +
                            '  // Render post...\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        Observatii:
                    </b>
                    <ul>
                        <li>
                            <b>getStaticPaths</b> rulează întotdeauna pe server și niciodată pe client.
                        </li>
                        <li>
                            <b>getStaticPaths</b> poate fi exportat doar dintr-o pagină.
                            <br/>
                            Nu îl puteți exporta din fișiere non-pagină, _app, _document sau _error.
                        </li>

                        <li>
                            <b>getStaticPaths</b> ruleaza doar la <i>build time</i>
                        </li>

                        <li>nu este inclus in client bundle
                            <br/>
                            Se poate verifica aici, ca <b>getStaticProps</b> este eliminat din client bundle: <a href={"https://next-code-elimination.vercel.app/"} target={"_blank"}>aici</a>
                        </li>

                    </ul>

                    <hr/>
                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {robotsData} from "../mocks/robots"\n\n' +
                            'export async function getStaticProps(staticProps) { // aici suntem pe server \n' +
                            '  const params = staticProps.params\n' +
                            '  return {\n' +
                            '    props: {\n' +
                            '       robots:robotsData.find(robo => {\n' +
                            '           return robo.id.toString()===params.id // dynamic id \n' +
                            '       }\n' +
                            '    }\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    (fara fragmentul de cod de mai jos va crapa, pentru ca nu exista metoda <b>getStaticPaths()</b>)
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export function getStaticPaths(){\n' +
                            '\n' +
                            '    return {\n' +
                            '        paths: [\n' +
                            '            {params: { id:"0"} },\n' +
                            '            {params: { id:"1"} }\n' +
                            '        ]\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    (fara fragmentul de cod de mai jos va crapa, pentru ca nu exista setat parametrul <b>fallback</b>)
                    <br/>
                    Daca:
                    <ul>
                        <li>
                            fallback = false, si nu exista definita ruta (de exemplu, mai sus exista doar 0 si 1), atunci va da eroarea 404
                        </li>
                        <li>
                            fallback = true, si nu exista definita ruta (de exemplu, 300), se incerca sa se descarce contintul de pe aceea <i>ruta</i>
                            si sa-l puna in cache pentru un eventual nou apel pentru calea respectiva.
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export function getStaticPaths(){\n' +
                            '\n' +
                            '    return {\n' +
                            '        paths: [\n' +
                            '            {params: { id:"0"} },\n' +
                            '            {params: { id:"1"} }\n' +
                            '        ],\n' +
                            '        fallback: false\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a genera cai pentru toate id-urile din robotsData:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export function getStaticPaths(){\n' +
                            '\n' +
                            '    const path = robotsData.map((robo)=> {\n' +
                            '        return { \n' +
                            '            params: {\n' +
                            '                 id: robo.id.toString()\n' +
                            '            } \n' +
                            '        }\n' +
                            '    })\n' +
                            '    return {\n' +
                            '        paths,\n' +
                            '        fallback: true\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Fallback</b>
                    <br/>
                    <br/>
                    Parametrul/atributul <b>fallback</b> poate avea valoarile:
                    <ul>
                        <li>false: daca nu gaseste pagina, va arunca eroare</li>
                        <li>true: daca nu gaseste pagina, incerca s-o genereze pe server;
                            <br/>
                            este nevoie de:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'if (!loaded){\n' +
                                    '   return <p>Loading</p>\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            pentru ca poate arunca exceptie, fiindca nu asteapta sa fie generata pagina pe server;
                            <br/>
                            <br/>
                            daca totusi nu se gaseste informatie pentru a genera pagina se poate specifica acest lucru prin <b>notFound: true</b> in <b>getStaticPaths()</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'if (!product){\n' +
                                    '   return {notFound: true}\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>blocking</b> (fallback: 'blocking') - este similar cu fallback: true, doar ca asteapta sa se genereze pe server pagina</li>
                    </ul>



                    <hr/>
                    <b>Utilizarea <b>"{"=>"}()</b></b>
                    <br/>
                    <br/>

                    Echivalenta:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'=>() este echivalent cu => { return ()}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {   'const path = robotsData.map((robo)=> {\n' +
                            '        return { \n' +
                            '            params: {\n' +
                            '                 id: robo.id.toString()\n' +
                            '            }\n' +
                            '        } \n' +
                            '}'}
                    </SyntaxHighlighter>
                    este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {   'const path = robotsData.map((robo)=> (\n' +
                            '        { \n' +
                            '            params: {\n' +
                            '                 id: robo.id.toString()\n' +
                            '            }\n' +
                            '        } \n' +
                            ')'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GetStaticPathsReactContent;