import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class NgRxEffectsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-ngrx-effects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. NgRx Effects</b>
                    <br/>
                    <br/>

                    NgRx ofera un singur flux/stream de actiuni in care putem face:
                    <ul>
                        <li>
                            <b>dispatch</b>
                        </li>
                        <li>
                            <b>subscribe</b>
                        </li>
                    </ul>
                    din intreaga aplicatie. Acest flux de actiuni este un <b>observabil</b>

                    <br/>
                    Efectele NgRx ne permit să ascultăm anumite tipuri de acțiuni și să „facem ceva” atunci când se întâmplă acțiunea respectivă.
                    Orice efect este, de asemenea, un Observabil.
                    <br/>
                    Un efect este un Observabil care folosește Fluxul de acțiune ca sursă și, de asemenea, ca destinație.

                    <br/>

                    Un effect face <b>subscribe</b> (se aboneaza) la fluxul de actiuni, si poate <b>publish</b> (publica) in fluxul de actiune.

                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng add @ngrx/effects'}
                    </SyntaxHighlighter>

                    Pentru module normale, in <i>app.module.ts</i>, in sectiunea <b>imports</b>, va genera <b>EffectsModule.forRoot([])</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [EffectsModule.forRoot({},{})]'}
                    </SyntaxHighlighter>

                    Pentru module aplicatii standdalone, in <i>main.ts</i> va genera <b>provideEffects()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent, { providers: [ provideEffects()  ]} );'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm install --save @ngrx/effects'}
                    </SyntaxHighlighter>

                    Pachetul <b>@ngrx/effects</b> oferă o modalitate de a izola efectele secundare în modelul său.

                    <br/>
                    De obicei efectele, se scriu in fisiere cu extensia: <i>.effects.ts</i> (de exemplu: counter.effects.ts).

                    <hr/>
                    <b>2. Configurare (daca s-a instalat cu npm)</b>
                    <br/>
                    <br/>
                    Configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'/* Other imports */\n' +
                            'import { EffectsModule } from \'@ngrx/effects\';\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  declarations: [\n' +
                            '    AppComponent\n' +
                            '  ],\n' +
                            '  imports: [\n' +
                            '    /* Other imported modules */\n' +
                            '    EffectsModule.forRoot([]),\n' +
                            '  ],\n' +
                            '  bootstrap: [AppComponent]\n' +
                            '})\n' +
                            'export class AppModule {\n' +
                            '}'}
                    </SyntaxHighlighter>

                    În NgRx, Efectele sunt încapsulate într-o clasa <b>Injectable</b> obișnuită Angular.
                    <br/>
                    Pentru a-i anunța pe NgRx să folosească clasa noastră ca efecte,
                    trebuie să adăugăm o <b>EffectsModule.forRoot([])</b> în importurile AppModule:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/store/photo.effects.ts\n' +
                            '\n' +
                            'import {Actions} from \'@ngrx/effects\';\n' +
                            'import {PhotoService} from \'../api/photo.service\';\n' +
                            'import {Injectable} from \'@angular/core\';\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'export class PhotoEffects {\n' +
                            '  constructor(\n' +
                            '    private actions$: Actions, // this is an RxJS stream of all actions\n' +
                            '    private photoService: PhotoService // we will need this service for API calls\n' +
                            '  ) {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/app.module.ts\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  declarations: [\n' +
                            '    AppComponent\n' +
                            '  ],\n' +
                            '  imports: [\n' +
                            '    / * other imports */\n' +
                            '    EffectsModule.forRoot([PhotoEffects]),\n' +
                            '    // this is necessary for `PhotoService` to have access to the HttpClient\n' +
                            '    HttpClientModule\n' +
                            '  ],\n' +
                            '  providers: [],\n' +
                            '  bootstrap: [AppComponent]\n' +
                            '})\n' +
                            'export class AppModule {}'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Mod de folosire</b>
                    <br/>
                    <br/>

                    Fie actiunile:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/store/photo.actions.ts\n' +
                            'import {createAction, props} from \'@ngrx/store\';\n' +
                            'import {Photo} from \'../photo/photo\';\n' +
                            '\n' +
                            'export const loadPhotos = createAction(\'[Photo List] Load Photos\');\n' +
                            'export const loadPhotosSuccess = createAction(\'[Photo List] Load Photos Success\', props<{photos: Photo[]}>());\n' +
                            'export const loadPhotosError = createAction(\'[Photo List] Load Photos Error\');'}
                    </SyntaxHighlighter>

                    si:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/store/photo.actions.ts\n' +
                            '\n' +
                            'import {createAction, props} from \'@ngrx/store\';\n' +
                            'import {Photo} from \'../photo/photo\';\n' +
                            '\n' +
                            '/* other actions */\n' +
                            '\n' +
                            'export const updatePhotoSuccess = createAction(\'[Photo List] Update Photo Success\', props<{photo: Photo}>());\n' +
                            'export const updatePhotoError = createAction(\'[Photo List] Update Photo Error\');'}
                    </SyntaxHighlighter>

                    Fie reducer:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/store/photo.reducer.ts\n' +
                            '\n' +
                            '/* other imports */\n' +
                            'import {loadPhotosSuccess} from \'./photo.actions\';\n' +
                            '\n' +
                            'const initialState: PhotoState = {};\n\n' +
                            'export const photoReducer = createReducer(\n' +
                            '  initialState,\n\n' +
                            '  on(updatePhotoSuccess, (state, {photo}) => ({\n' +
                            '    ...state,\n' +
                            '    [photo.id]: photo\n' +
                            '  })),\n\n' +
                            '  on(loadPhotosSuccess, (state, {photos}) => photos.reduce((acc, photo) => ({\n' +
                            '    ...acc,\n' +
                            '    [photo.id]: photo\n' +
                            '  }),' +
                            '{}))\n' +
                            ');'}
                    </SyntaxHighlighter>

                    <hr/>
                    Ca idee:
                    <ul>
                        <li>switchMap - se opreste din lucrat la ce are de facut si incepe sa lucreze la noua comanda' doar ultima comanda va fi finalizata</li>
                        <li> concatMap - noua comanda este pusa in coada; dupa ce se termina comanda curenta, se incepe sa se lucreze la urmatoarea comanda din coada</li>
                        <li>
                            mergeMap - se lucreza la toate comenziile in acelasi timp pe masura ce vin
                        </li>
                        <li>
                            exhaustMap - ignora noile comenzi cat timp se lucreaza o comanda; dupa ce se termina poate primi noi comenzi
                        </li>
                        <li>
                            of (este sincron, fiind un observabil care emite sincron)
                        </li>
                    </ul>
                    <hr/>

                    Actualizare clasa de efecte (folosind <b>createEffect</b>) [1] (pentru <i>loadPhotos</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/store/photo.effects.ts\n' +
                            '\n' +
                            'import {Actions, createEffect, Effect, ofType} from \'@ngrx/effects\';\n' +
                            'import {PhotoService} from \'../api/photo.service\';\n' +
                            'import {Injectable} from \'@angular/core\';\n' +
                            'import {loadPhotos, loadPhotosError, loadPhotosSuccess} from \'./photo.actions\';\n' +
                            'import {catchError, map, switchMap} from \'rxjs/operators\';\n' +
                            'import {of} from \'rxjs\';\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'export class PhotoEffects {\n' +
                            '  loadPhotos$ = createEffect(() =>\n' +
                            '    this.actions$.pipe(\n' +
                            '      ofType(loadPhotos),\n' +
                            '      switchMap(() => this.photoService.getPhotos().pipe(\n' +
                            '        map(photos => loadPhotosSuccess({photos})), // loadPhotosSuccess e o actiune, deci trimite catre o nou actiune \n' +
                            '        catchError(() => of(loadPhotosError()))\n' +
                            '      ))\n' +
                            '    )\n' +
                            '  );\n' +
                            '\n' +
                            '  constructor(\n' +
                            '    private actions$: Actions,\n' +
                            '    private photoService: PhotoService\n' +
                            '  ) {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Actualizare clasa de efecte [2]  (pentru <i>likePhoto</i> si <i>dislikePhoto</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@Injectable()\n' +
                            'export class PhotoEffects {\n' +
                            '  / * loadPhotos$ effect */\n' +
                            '\n' +
                            '  likePhoto$ = createEffect(() => this.actions$.pipe(\n' +
                            '    ofType(likePhoto),\n' +
                            '    mergeMap(({id}) => this.photoService.likePhoto(id).pipe(\n' +
                            '      map(photo => updatePhotoSuccess({photo})),\n' +
                            '      catchError(() => [updatePhotoError()])\n' +
                            '    ))\n' +
                            '  ));\n' +
                            '\n' +
                            '  dislikePhoto$ = createEffect(() => this.actions$.pipe(\n' +
                            '    ofType(dislikePhoto),\n' +
                            '    mergeMap(({id}) => this.photoService.dislikePhoto(id).pipe(\n' +
                            '      map(photo => updatePhotoSuccess({photo})),\n' +
                            '      catchError(() => [updatePhotoError()])\n' +
                            '    ))\n' +
                            '  ));\n' +
                            '\n' +
                            '  /* constructor */\n' +
                            '}'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>Anexa 1: NgRx</b>
                    <br/>
                    <br/>

                    Concepte:
                    <ul>
                        <li>
                            <b>store</b>:  locul în care va fi stocată <b>starea</b> aplicației.
                        </li>
                        <li>
                            <b>actiuni</b>: descriu toate evenimentele posibile și unice care pot apărea în cadrul aplicației.
                            Ele pot apărea de ex. prin interacțiunea utilizatorului, comunicarea cu serverul sau poate fi rezultatul altor acțiuni.
                        </li>
                        <li>
                            <b>reductori</b>: leaga <b>starea</b> de <b>actiune</b>.
                            <br/>
                            Toate schimbările de stare trebuie să apară ca urmare a unei acțiuni.
                            <br/>
                            Aceste modificări sunt gestionate de funcții numite <b>reductoare</b>.
                            <br/>
                            Aceatea preiau starea curentă și cea mai recentă acțiune și calculează noua valoare a stării pe baza acesteia.
                            <br/>
                            (stare curenta, actiune) {"->"} (noua stare)
                        </li>
                    </ul>

                    <hr/>
                    <b>Definire actiuni:</b>
                    <br/>
                    <br/>
                    Pentru definirea actiunilor se foloseste functia <b>createAction(<i>nume_actiune</i>)</b>.
                    <br/>
                    Exemple, definire actiuni:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// src/app/store/photo.actions.ts\n' +
                            'import {createAction, props} from \'@ngrx/store\';\n' +
                            'import {Photo} from \'../photo/photo\';\n' +
                            '\n' +
                            'export const loadPhotos = createAction(\'[Photo List] Load Photos\');\n' +
                            'export const loadPhotosSuccess = createAction(\'[Photo List] Load Photos Success\', props<{photos: Photo[]}>());\n' +
                            'export const loadPhotosError = createAction(\'[Photo List] Load Photos Error\');'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Definire reductori:</b>
                    <br/>
                    <br/>
                    Pentru definirea actiunilor se foloseste functia <b>createReducer</b>.

                    <br/>
                    Exemple, definire actiuni:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {createReducer, on} from \'@ngrx/store\';\n' +
                            'import {dislikePhoto, likePhoto} from \'./photo.actions\';\n' +
                            '\n' +
                            'export type PhotoState = number;\n' +
                            '\n' +
                            'const initialState: PhotoState = 0;\n' +
                            '\n' +
                            'export const photoReducer = createReducer(\n' +
                            '  initialState,\n' +
                            '  on(likePhoto, state => state + 1),\n' +
                            '  on(dislikePhoto, state => state - 1)\n' +
                            ');'}
                    </SyntaxHighlighter>

                    similar cu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'export function photoReducer(state = initialState, action: Action): PhotoState {\n' +
                            '  switch (action.type) {\n' +
                            '    case likePhoto.type:\n' +
                            '      return state + 1;\n' +
                            '    case dislikePhoto.type:\n' +
                            '      return state - 1;\n' +
                            '    default:\n' +
                            '        return state;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Mod de folosire</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {select, Store} from \'@ngrx/store\';\n' +
                            'import {dislikePhoto, likePhoto} from \'./store/photo.actions\';\n' +
                            '\n' +
                            '@Component({/* ... */})\n' +
                            'export class AppComponent {\n' +
                            '\n' +
                            '  constructor(private store: Store<AppState>) {\n' +
                            '  }\n' +
                            '\n' +
                            '  onLike(): void {\n' +
                            '    this.store.dispatch(likePhoto());\n' +
                            '  }\n' +
                            '\n' +
                            '  onDislike(): void {\n' +
                            '    this.store.dispatch(dislikePhoto());\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Anexa 2: Cazuri de efecte</b>
                    <br/>
                    <br/>

                    <b>1 input {"->"} 1 output</b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'public firstAction$: Observable<Action> = this.actions$.pipe(\n' +
                            '  ofType( \'FIRST_ACTION\' ),\n' +
                            '  mapTo( new SecondAction() )\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Acest efect face urmatoarele:
                    <ul>
                        <li>urmareste fluxul de actiune, filtrand dupa <i>FirstAction</i> </li>
                        <li>trimite/dispatch un nou <i>SecondAction</i> in fluxul de actiune</li>
                    </ul>

                    <hr/>
                    <b>2 input {"->"} 1 output</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'public dashboardLoadRequired$: Observable<Action> = \n' +
                            '  this.actions$.pipe(\n' +
                            '    ofType( \'SIGN_IN_SUCCESS\', \'OPEN_DASHBOARD\'),\n' +
                            '    mapTo( new LoadDashboardData() )\n' +
                            '  );'}
                    </SyntaxHighlighter>

                    Acest efect face urmatoarele:
                    <ul>
                        <li>urmareste fluxul de actiune, filtrand dupa <i>SignInSuccess </i> sau <i>OpenDashboard</i></li>
                        <li>trimite/dispatch un nou <i>LoadDashboardData</i> in fluxul de actiune</li>
                    </ul>

                    <hr/>
                    <b>1 input {"->"} 2 output</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'public signInSuccess$: Observable<Action> = this.actions$.pipe(\n' +
                            '  ofType( \'SIGN_IN_SUCCESS\' ),\n' +
                            '  concatMapTo([\n' +
                            '    new LoadDashboardData(),\n' +
                            '    new LoadChatHistory()\n' +
                            '  ])\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Acest efect face urmatoarele:
                    <ul>
                        <li>urmareste fluxul de actiune, filtrand dupa <i>SignInSuccess</i></li>
                        <li>trimite/dispatch un nou <i>LoadDashboardData </i> in fluxul de actiune, apoi <i>LoadChatHistory</i></li>
                    </ul>

                    <hr/>
                    <b>1 input {"->"} 0 output</b>

                    <br/>
                    Este posibil să scrieți un efect care nu trimite o acțiune, dar trebuie să fiți explicit în privința acestuia.
                    <br/>
                    Dacă nu trimiteți o acțiune, acțiunea de intrare va fi trimisă automat.
                    Acest lucru va bloca browserul dvs., deoarece efectul pe care l-ați scris este atât abonamentul (<i>subscribing</i>),
                    cât și expedierea (<i>dispatching</i>) exactă aceeași acțiune, provocând o buclă infinită.

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'public signInSuccess$: Observable<Action> = this.actions$.pipe(\n' +
                            '  ofType( \'SIGN_IN_SUCCESS\' ),\n' +
                            '  tap( () => this.router.navigate([\'dashboard\']) )\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Acest efect face urmatoarele:
                    <ul>
                        <li>urmareste fluxul de actiune, filtrand dupa <i>SignInSuccess</i></li>
                        <li>navigati in 'dashboard'</li>
                        <li>
                            nu trimiteti alte actiuni
                        </li>
                    </ul>

                    <hr/>
                    <b>Trecerea sarcinii utile de intrare la ieșire</b>
                    <br/>
                    Exemplele de mai sus au folosit operatorii <b>mapTo</b> și <b>concatMapTo</b>.
                    Acești operatori se mapează la valori statice.
                    Uneori doriți să mapați la valori dinamice, cum ar fi utilizarea unei valori transmise prin <i>payload</i> proprietatea unei acțiuni.
                    <br/>
                    Pentru valori dinamice, utilizați operatorii de potrivire <b>map</b> sau <b>concatMap</b> care așteaptă o funcție mai degrabă decât o valoare statică.
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'public signInSuccess$: Observable<Action> = this.actions$.pipe( \n' +
                            '  ofType( \'SIGN_IN_SUCCESS\' ), \n' +
                            '  map( action => action.payload ), \n' +
                            '  concatMap( payload => [ \n' +
                            '    new LoadDashboardData( payload.companyId ) , \n' +
                            '    nou LoadChatData( payload.userId ) \n' +
                            '  ]) \n' +
                            ');'}
                    </SyntaxHighlighter>

                    Acest efect face urmatoarele:
                    <ul>
                        <li>urmareste fluxul de actiune, filtrand dupa <i>SignInSuccess</i></li>
                        <li>obtineti <i>payload</i> din 'action' (SignInSuccess)</li>
                        <li>
                            dispatch (trimitere catre) <i>LoadDashboardData</i> cu <i>companyId</i>
                        </li>
                        <li>
                            dispatch (trimitere catre) <i>LoadChatData</i> cu <i>userId</i>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NgRxEffectsAngularContent;