import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class UseStateReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-usestate", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hooks: useState </b>
                    <br/>
                    <br/>

                    Hooks sunt functii care gestioneaza starea si ciclul de viata o unei componente pe baza unei functii si nu a unei clase.
                    <br/>
                    Hooks nu functionează in interiorul unei clase.

                    Observatii:
                    <ul>
                        <li>
                            un hook nu se poate apela din interiorul unui loop, conditii sau functii incuibarite; trebuie folosite doar la nivelul functiei</li>
                        <li>
                            un hook nu trebuie aplelat din functii obisnuite JavaScript, ci doar din componente React bazate pe functii;
                        </li>
                        <li>
                            un hook poate fi apelat dintr-un custom hook
                        </li>
                    </ul>

                    <hr/>

                    Functia <b>useState()</b> primeste ca parametru o <i>valoare initiala</i> pentru <i>variabila de stare</i>.
                    <br/>
                    Si returneaza <i>variabila de starea</i> si functia prin care se poate modifica <i>variabila de stare</i>.

                    <br/>
                    <br/>

                    Conversie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'this.state = {\n' +
                            '  robots: [],\n' +
                            '  searchField: \'\'\n' +
                            '}'}
                    </SyntaxHighlighter>
                    devine:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const [robots, setRobots] = useState([]);\n' +
                            'const [searchField, setSearchField] = useState(\'\');'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            <b>Paranteze pătrate</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const [fruit, setFruit] = useState(\'banana\');'}
                            </SyntaxHighlighter>
                            Această sintaxă JavaScript se numește „destructurare matrice”.
                            Înseamnă că facem două variabile noi fruitși setFruit, unde fruiteste setat la prima valoare returnată de useState, și setFruiteste a doua.
                            Este echivalent cu acest cod:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'var fruitStateVariable = useState(\'banana\'); // returneaza o pereche\n' +
                                    'var fruit = fruitStateVariable[0]; // primul element din pereche\n' +
                                    'var setFruit = fruitStateVariable[1]; // al doilea element din pereche'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, { useState } from \'react\';\n' +
                            '\n' +
                            'function Example() {\n' +
                            '    const [count, setCount] = useState(0);\n' +
                            '\n' +
                            '    return (\n' +
                            '       <div>\n' +
                            '        <p>You clicked {count} times</p>\n' +
                            '        <button onClick={() => setCount(count + 1)}>\n' +
                            '         Click me\n' +
                            '        </button>\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Functie Updater</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, { useState } from \'react\';\n' +
                            '\n' +
                            'function Example() {\n' +
                            '    const [count, setCount] = useState(0);\n\n' +
                            '    clickHandler = () => {\n' +
                            '       setCount(count + 1);// 0 + 1\n' +
                            '       setCount(count + 1);// 0 + 1\n' +
                            '       setCount(count + 1);// 0 + 1\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '       <div>\n' +
                            '        <p>Ai apasat de {count} ori!</p>\n' +
                            '        <button onClick={clickHandler}>\n' +
                            '         Apasa-ma!\n' +
                            '        </button>\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Dupa ce se apasa pe buton count = 1!

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, { useState } from \'react\';\n' +
                            '\n' +
                            'function Example() {\n' +
                            '    const [count, setCount] = useState(0);\n\n' +
                            '    clickHandler = () => {\n' +
                            '       setCount(c => c + 1);\n' +
                            '       setCount(c => c + 1);\n' +
                            '       setCount(c => c + 1;\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '       <div>\n' +
                            '        <p>Ai apasat de {count} ori!</p>\n' +
                            '        <button onClick={clickHandler}>\n' +
                            '         Apasa-ma!\n' +
                            '        </button>\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Dupa ce se apasa pe butonm count = 3! Se trimite in variabila c, valoare de variabila count actualizata!

                    <hr/>
                    <b>Actualizare stare obiecte</b>
                    <br/>
                    <br/>
                    Daca avem starea:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' const [masina, setMasina] = useState({\n' +
                            '    marca: "Ford",\n' +
                            '    model: "Mustang",\n' +
                            '    an: "1964",\n' +
                            '    culoare: "red"\n' +
                            '  });'}
                    </SyntaxHighlighter>
                    si:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'setMasina({culoare: "blue"})'}
                    </SyntaxHighlighter>
                    cest lucru ar elimina marca, modelul și anul din statul nostru.

                    <br/>
                    Corect este:
                    <SyntaxHighlighter>
                        {' setMasina(previousState => {\n' +
                            '      return { ...previousState, culoare: "blue" }\n' +
                            '    });'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseStateReactContent;