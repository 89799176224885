import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class VolumeFlagDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-v-flag", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker run: flag -v (volume)</b>

                    <br/>
                    <br/>

                    Mapeaza sau mai degraba redirecteaza sau mai degraba creaza referinte catre locatii din afara containarului.
                    <br/>
                    Adica un anumit <i>director din container</i> de fapt sa fie doar o referinta catre un <i>director din afara containerului</i>
                    {/*<br/>*/}
                    {/*De fapt e: <i>TOATE subdirectoarele din directorul specificat din container</i> a fie doar o referinta catre <i>TOATE subdirectoarele din directorul specificat din container</i>.*/}

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'-p <director din afara containerului>:<director din container>'}
                    </SyntaxHighlighter>

                    Adica daca avem urmatoare structura de directoare in afara containerului:
                    <ul>
                        <li>
                            [public]<br/>
                            [src]<br/>
                            [node_modules]<br/>
                            Dockerfile.dev<br/>
                            package.json<br/>
                            package-lock.json<br/>
                            README.md<br/>
                        </li>
                    </ul>
                    si de aici rulam comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p 3000:3000 -v ${pwd}:/app imagine'}
                    </SyntaxHighlighter>

                    avand:
                    <ul>
                        <li>
                            WORKDIR '/app' {"->"} adica calea unde vor copia fisiere in container
                        </li>
                        <li>
                            COPY . . {"->"} adica copiaza orice din directorul curent (din afara containerului) in '/app'
                        </li>
                    </ul>
                    flag-ul <b>-p</b> <i>$pwd:/app</i> va crea referinte pentru fiecare fisier/firector din '/app' catre <i>$pwd</i>

                    <br/>
                    <br/>

                    <b>Observatie</b>:
                    <br/>
                    Comanda: <b>pwd</b> afiseaza calea care directorul curent in care ne aflam; de exemplu: <br/>
                    /home/kj/learn/frontend


                    <hr/>
                    Se poate intampla ca directorul <i> [node_modules]</i> sa il stergem de pe masina din afara containerului,
                    pentru ca oricum se genereaza in interiorul containerului la rulare.
                    <br/>
                    Si atunci pentru ca sa nu se mai intample <i>referentierea</i>, punem un fel de exceptie:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'-v <cale_director_exceptat_din_container>'}
                    </SyntaxHighlighter>

                    exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'-v /app/node_modules'}
                    </SyntaxHighlighter>

                    ajungem sa scriem:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p 3000:3000 -v /app/node_modules -v ${pwd}:/app imagine'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VolumeFlagDockerContent;