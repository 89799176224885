import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class IngressContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-ingress", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare Ingress</b>
                    <br/>
                    <br/>

                    De pe link-ul: <a target={"_blank"} href={"https://github.com/kubernetes/ingress-nginx"}>https://github.com/kubernetes/ingress-nginx</a>,
                    vom ajunge pe <a target={"_blank"} href={"https://kubernetes.github.io/ingress-nginx/deploy/"}>https://kubernetes.github.io/ingress-nginx/deploy/</a>.

                    <br/>
                    Putem instala folosind:
                    <ul>
                        <li><b>helm</b>: <a target={"_blank"} href={"https://helm.sh/"}>https://helm.sh/</a>
                            <br/>
                            instalare helm pe ubtunu:
                            <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                                {
                                    'curl https://baltocdn.com/helm/signing.asc | gpg --dearmor | sudo tee /usr/share/keyrings/helm.gpg > /dev/null\n' +
                                    'sudo apt-get install apt-transport-https --yes\n' +
                                    'echo "deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/helm.gpg] https://baltocdn.com/helm/stable/debian/ all main" | sudo tee /etc/apt/sources.list.d/helm-stable-debian.list\n' +
                                    'sudo apt-get update\n' +
                                    'sudo apt-get install helm'
                                }
                            </SyntaxHighlighter>

                            verificare:
                            <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                                {' helm version\n' +
                                    'version.BuildInfo{Version:"v3.10.1", GitCommit:"9f88ccb6aee40b9a0535fcc7efea6055e1ef72c9", GitTreeState:"clean", GoVersion:"go1.18.7"}'}
                            </SyntaxHighlighter>

                            instalare ingress:
                            <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                                {'helm upgrade --install ingress-nginx ingress-nginx \\\n' +
                                    '  --repo https://kubernetes.github.io/ingress-nginx \\\n' +
                                    '  --namespace ingress-nginx --create-namespace'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'>   --repo https://kubernetes.github.io/ingress-nginx \\\n' +
                                    '>   --namespace ingress-nginx --create-namespace\n' +
                                    'Release "ingress-nginx" does not exist. Installing it now.\n' +
                                    'NAME: ingress-nginx\n' +
                                    'LAST DEPLOYED: Fri Nov  4 23:28:12 2022\n' +
                                    'NAMESPACE: ingress-nginx\n' +
                                    'STATUS: deployed\n' +
                                    'REVISION: 1\n' +
                                    'TEST SUITE: None\n' +
                                    'NOTES:\n' +
                                    'The ingress-nginx controller has been installed.\n' +
                                    'It may take a few minutes for the LoadBalancer IP to be available.\n' +
                                    'You can watch the status by running \'kubectl --namespace ingress-nginx get services -o wide -w ingress-nginx-controller\'\n' +
                                    '\n' +
                                    'An example Ingress that makes use of the controller:\n' +
                                    '  apiVersion: networking.k8s.io/v1\n' +
                                    '  kind: Ingress\n' +
                                    '  metadata:\n' +
                                    '    name: example\n' +
                                    '    namespace: foo\n' +
                                    '  spec:\n' +
                                    '    ingressClassName: nginx\n' +
                                    '    rules:\n' +
                                    '      - host: www.example.com\n' +
                                    '        http:\n' +
                                    '          paths:\n' +
                                    '            - pathType: Prefix\n' +
                                    '              backend:\n' +
                                    '                service:\n' +
                                    '                  name: exampleService\n' +
                                    '                  port:\n' +
                                    '                    number: 80\n' +
                                    '              path: /\n' +
                                    '    # This section is only required if TLS is to be enabled for the Ingress\n' +
                                    '    tls:\n' +
                                    '      - hosts:\n' +
                                    '        - www.example.com\n' +
                                    '        secretName: example-tls\n' +
                                    '\n' +
                                    'If TLS is enabled for the Ingress, a Secret containing the certificate and key must also be provided:\n' +
                                    '\n' +
                                    '  apiVersion: v1\n' +
                                    '  kind: Secret\n' +
                                    '  metadata:\n' +
                                    '    name: example-tls\n' +
                                    '    namespace: foo\n' +
                                    '  data:\n' +
                                    '    tls.crt: <base64 encoded cert>\n' +
                                    '    tls.key: <base64 encoded key>\n' +
                                    '  type: kubernetes.io/tls'}
                            </SyntaxHighlighter>

                            activare:
                            <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                                {'minikube addons enable ingress'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'💡  ingress is an addon maintained by Kubernetes. For any concerns contact minikube on GitHub.\n' +
                                    'You can view the list of minikube maintainers at: https://github.com/kubernetes/minikube/blob/master/OWNERS\n' +
                                    '    ▪ Using image k8s.gcr.io/ingress-nginx/controller:v1.2.1\n' +
                                    '    ▪ Using image k8s.gcr.io/ingress-nginx/kube-webhook-certgen:v1.1.1\n' +
                                    '    ▪ Using image k8s.gcr.io/ingress-nginx/kube-webhook-certgen:v1.1.1\n' +
                                    '🔎  Verifying ingress addon...\n' +
                                    '🌟  The \'ingress\' addon is enabled'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>kubectl apply</b>
                            <br/>
                            folosind aceasta strategie, am obtinut erori si am renuntat; am instalat helm;
                        </li>
                    </ul>

                    <hr/>
                    Exercitii :
                    <SyntaxHighlighter>
                        {'Ingress\n' +
                            '\n' +
                            '# --all-namespaces == -A\n' +
                            'k get nodes\n' +
                            'k get deploy\n' +
                            'k get deploy -A\n' +
                            'k get pods\n' +
                            'k get pods -A / k get pods --all-namespaces\n' +
                            'k get deploy -n ingress-nginx\n' +
                            'k describe ingress ingress-wear-watch -n app-space\n' +
                            '\n' +
                            'kubectl get all -A\n' +
                            'kubectl get ingress --all-namespaces\n' +
                            'kubectl describe ingress --namespace app-space\n' +
                            'kubectl describe ingress -n app-space\n' +
                            '\n' +
                            'kubectl edit ingress --namespace app-space\n' +
                            'kubectl edit ingress -n app-space\n' +
                            'kubectl get deploy -n app-space\n' +
                            '\n' +
                            'kubectl describe ingress --namespace app-space\n' +
                            '\n' +
                            'kubectl get deploy --all-namespaces\n' +
                            '\n' +
                            'kubectl get svc -n critical-space\n' +
                            'kubectl edit svc pay-service -n critical-space\n' +
                            '\n' +
                            'kubectl create ingress -h\n' +
                            '\n' +
                            'kubectl create ingress ingress-pay -n critical-space --rule="/pay=pay-service:8282"\n' +
                            '---\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'kind: Ingress\n' +
                            'metadata:\n' +
                            '  creationTimestamp: null\n' +
                            '  name: pay-ingress\n' +
                            '  namespace: critical-space\n' +
                            'spec:\n' +
                            '  rules:\n' +
                            '  - http:\n' +
                            '      paths:\n' +
                            '      - backend:\n' +
                            '          service:\n' +
                            '            name: pay-service\n' +
                            '            port:\n' +
                            '              number: 8282\n' +
                            '        path: /pay\n' +
                            '        pathType: Exact\n' +
                            'status:\n' +
                            '  loadBalancer: {}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'apiVersion: extensions/v1beta1\n' +
                            'kind: Ingress\n' +
                            'metadata:\n' +
                            '  name: test-ingress\n' +
                            '  namespace: critical-space\n' +
                            '  annotations:\n' +
                            '    nginx.ingress.kubernetes.io/rewrite-target: /\n' +
                            'spec:\n' +
                            '  rules:\n' +
                            '  - http:\n' +
                            '      paths:\n' +
                            '      - path: /pay\n' +
                            '        backend:\n' +
                            '          serviceName: pay-service\n' +
                            '          servicePort: 8282\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <SyntaxHighlighter>
                        {'alias k=kubectl # alias pentru comanda kubectl\n' +
                            'k get pod -A # afisare toate podurile din toate namespace-urile\n' +
                            'k create namespace ingress-nginx # creare namespace cu numele ingress-nginx\n' +
                            'k create configmap ingress-nginx-controller -n ingress-nginx # se creaza un configmap cu numele ingress-nginx-controller in namespace-ul cu numele ingress-nginx\n' +
                            'k create serviceaccount ingress-nginx -n ingress-nginx # se creaza un serviceaccount cu numele ingress-nginx\n' +
                            'k create serviceaccount ingress-nginx-admission -n ingress-nginx\n' +
                            'k get roles -n ingress-nginx # verificare roluri\n' +
                            'k describe role ingress-role -n ingress-nginx\n' +
                            'k get rolebindings -n ingress-nginx\n' +
                            'k get clusterroles -n ingress-nginx\n' +
                            'k get clusterrolebindings -n ingress-nginx\n' +
                            'k create -f ingress-controller.yaml\n' +
                            'k expose deploy ingress-controller -n ingress-nginx --name ingress --port=80 --target-port=80 --type NodePort\n' +
                            'k get svc ingress -n ingress-space\n' +
                            'k delete svc [nume-serviciu]\n' +
                            'k create ingress ingress-wear-watch -n app-space --rule="/wear=wear-service:8080" --rule="/watch=video-service:8080"\n' +
                            'k get ingress -n app-space\n' +
                            'k edit ingress ingress-wear-watch -n app-space\n' +
                            '---\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'kind: Ingress\n' +
                            'metadata:\n' +
                            '  name: ingress-wear-watch\n' +
                            '  namespace: app-space\n' +
                            '  annotations:\n' +
                            '    nginx.ingress.kubernetes.io/rewrite-target: /\n' +
                            '    nginx.ingress.kubernetes.io/ssl-redirect: "false"\n' +
                            'spec:\n' +
                            '  rules:\n' +
                            '  - http:\n' +
                            '      paths:\n' +
                            '      - path: /wear\n' +
                            '        pathType: Prefix\n' +
                            '        backend:\n' +
                            '          service:\n' +
                            '           name: wear-service\n' +
                            '           port: \n' +
                            '            number: 8080\n' +
                            '      - path: /watch\n' +
                            '        pathType: Prefix\n' +
                            '        backend:\n' +
                            '          service:\n' +
                            '           name: video-service\n' +
                            '           port:\n' +
                            '            number: 8080'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IngressContent;