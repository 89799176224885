import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class IfJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-if", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'if (conditie1){\n' +
                        '   bloc_instructiuni_1;\n' +
                        '}else if(conditie2){\n' +
                        '   bloc_instructiuni_2;\n' +
                        '}else{\n' +
                        '   bloc_instructiuni_else;\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Daca un bloc de instructiuni e format doar dintr-o instructiune se pot omite acoladele (nerecomandat).
                    <br/>
                    Partile <i>else if</i> si <i>else</i> sunt optionale.
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IfJavaScriptContent;