import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DeserializingFormsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-deserializing-form", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Deserializarea <b>valorilor unui form</b> (<i>content</i> sunt valorile unui form in format json salvat in baza de date):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'protected DDMFormValues deserialize(String content, DDMForm ddmForm) {\n' +
                        '    DDMFormValuesDeserializer ddmFormValuesDeserializer =\n' +
                        '            ddmFormValuesDeserializerTracker.getDDMFormValuesDeserializer(\n' +
                        '                    "json");\n' +
                        '\n' +
                        '    DDMFormValuesDeserializerDeserializeRequest.Builder builder =\n' +
                        '            DDMFormValuesDeserializerDeserializeRequest.Builder.newBuilder(\n' +
                        '                    content, ddmForm);\n' +
                        '\n' +
                        '    DDMFormValuesDeserializerDeserializeResponse\n' +
                        '            ddmFormValuesDeserializerDeserializeResponse =\n' +
                        '            ddmFormValuesDeserializer.deserialize(builder.build());\n' +
                        '\n' +
                        '    return ddmFormValuesDeserializerDeserializeResponse.getDDMFormValues();\n' +
                        '}'}
                    </SyntaxHighlighter>

                    avand:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference\n' +
                        'private DDMFormValuesDeserializerTracker ddmFormValuesDeserializerTracker;'}
                    </SyntaxHighlighter>
                    cu importurile:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.dynamic.data.mapping.io.DDMFormValuesDeserializerTracker'}
                    </SyntaxHighlighter>

                    test:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'DDMFormRenderingContext ddmFormRenderingContext = new DDMFormRenderingContext();\n' +
                        'ddmFormRenderingContext.setContainerId("ddmForm".concat(StringUtil.randomString()));\n' +
                        '\n' +
                        'ddmFormRenderingContext.setDDMFormValues(ddmFormValues);'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://liferay.dev/es/blogs/-/blogs/liferay-form-in-custom-module/maximized"}>
                                    Liferay Form in Custom Module
                                </a>
                            </div>
                        </li>


                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DeserializingFormsLiferayContent;