import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"jhipster-root", url:"#", title:"JHipster", subtitle:""},

        {id:"jhipster-install", url:"/jhipster/install", title:"Instalare", subtitle:"", parent:"jhipster-root"},

        {id:"jhipster-jdl-studio", url:"/jhipster/jdl-studio", title:"JDL Studio", subtitle:"", parent:"jhipster-root"},
        {id:"jhipster-entity-jdl", url:"/jhipster/entity-jdl", title:"Modelarea entitatilor", subtitle:"", parent:"jhipster-root"},
        {id:"jhipster-relationship-jdl", url:"/jhipster/relationship-jdl", title:"Gestionarea relatiilor", subtitle:"", parent:"jhipster-root"},
        {id:"jhipster-options-jdl", url:"/jhipster/options-jdl", title:"Optiuni JDL", subtitle:"", parent:"jhipster-root"},

        {id:"jhipster-generated-code-jdl-root", url:"#", title:"Cod generat JDL", subtitle:""},
        {id:"jhipster-generated-code-jhipster-folder-jdl", url:"/jhipster/generated-code-jhipster-folder-jdl", title:"Directorul .jhipster", subtitle:"", parent:"jhipster-generated-code-jdl-root"},
        {id:"jhipster-generated-code-domain-jdl", url:"/jhipster/generated-code-domain-jdl", title:"Entitati Java / (domain)", subtitle:"", parent:"jhipster-generated-code-jdl-root"},
        {id:"jhipster-generated-code-liquidbase-jdl", url:"/jhipster/generated-code-liquidbase-jdl", title:"Liquidbase", subtitle:"", parent:"jhipster-generated-code-jdl-root"},
        {id:"jhipster-generated-code-repository-jdl", url:"/jhipster/generated-code-repository-jdl", title:"Repository", subtitle:"", parent:"jhipster-generated-code-jdl-root"},
        {id:"jhipster-generated-code-service-jdl", url:"/jhipster/generated-code-service-jdl", title:"Service", subtitle:"", parent:"jhipster-generated-code-jdl-root"},
        {id:"jhipster-generated-code-resource-jdl", url:"/jhipster/generated-code-resource-jdl", title:"Resource/REST", subtitle:"", parent:"jhipster-generated-code-jdl-root"},

        {id:"jhipster-generated-code-interfaces-jdl", url:"/jhipster/generated-code-interfaces-jdl", title:"Interfete TypeScript pentru entitati", subtitle:"", parent:"jhipster-generated-code-jdl-root"},

        {id:"jhipster-util-root", url:"#", title:"JHipster: Utile", subtitle:""},
        {id:"jhipster-export-excel", url:"/jhipster/export-excel", title:"Export Excel", subtitle:"", parent:"jhipster-util-root"},
        {id:"jhipster-import-excel", url:"/jhipster/import-excel", title:"Import Excel", subtitle:"", parent:"jhipster-util-root"},
        {id:"jhipster-ldap-auth", url:"/jhipster/ldap-auth", title:"LDAP Auth", subtitle:"", parent:"jhipster-util-root"},
        {id:"jhipster-multi-datasources", url:"/jhipster/multi-datasources", title:"Multi DataSources", subtitle:"", parent:"jhipster-util-root"},
    ]

    static indexUrl = "/jhipster/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    JHipster
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;