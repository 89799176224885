import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlCreateContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-create", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl create</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectrl create</b> este folosita pentru a creea obiecte.

                    <hr/>
                    <b>Creare secret</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic <secret-name> --from-literal key=value'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            create {"=>"} comanda imperativa de a creea un obiect
                        </li>
                        <li>
                            secret {"=>"} tipul de obiect ce se va creea
                        </li>
                        <li>
                            <b>generic</b>{"=>"} tipul de secret (alte tipuri: <b>docker-registry</b>+<b>tls</b>)
                        </li>
                        <li>
                            secret-name {"=>"} numele secret-ului, care va fi referit apoi in fisierul de configurare pod
                        </li>
                        <li>
                            -- from-literal {"=>"} pentru a adauga informatii legate de sectet in comanda direct, cu cheie=valoare (in opozitie cu citirea dintr-un fisier <i>from</i> .)
                        </li>
                        <li>
                            key = value {"=>"} pereche chei valoare pentru informatiile legate de secret
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic pgpassword --from-literal PGPASSWORD=1234password'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'secret/pgpassword created'}
                    </SyntaxHighlighter>

                    Pentru a afisa secretele:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME         TYPE     DATA   AGE\n' +
                            'pgpassword   Opaque   1      30s'}
                    </SyntaxHighlighter>

                    Un secret poate fi doar creat sau sters, nu si actualizat!

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete secret pgpassword'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Creare deployment</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment nginx-deployment --image=nginx'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'deployment.apps/nginx-deployment created'}
                    </SyntaxHighlighter>

                    Pentru a afisa deployment-ele:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployments'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME                  READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'client-deployment     3/3     3            3           41d\n' +
                            'nginx-deployment      1/1     1            1           67s\n' +
                            'postgres-deployment   1/1     1            1           41d\n' +
                            'redis-deployment      1/1     1            1           41d\n' +
                            'server-deployment     3/3     3            3           41d\n' +
                            'worker-deployment     1/1     1            1           41d\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa pod-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'nginx-deployment-5fbdf85c67-88dkg      1/1     Running            0          105s'}
                    </SyntaxHighlighter>

                    Scalare la 5 replici:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl scale deployment nginx-deployment --replicas=5'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlCreateContent;