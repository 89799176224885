import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SpringBootContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-boot", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare</b>
                    <br/>
                    <br/>

                    Pentru a configura Spring Boot:

                    <ul>
                       <li>
                           in <b>pom.xml</b>:
                           <ul>
                               <li>specificare parinte:
                                   <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                       {'<parent>\n' +
                                       '    <groupId>org.springframework.boot</groupId>\n' +
                                       '    <artifactId>spring-boot-starter-parent</artifactId>\n' +
                                       '    <version>2.5.5</version>\n' +
                                       '    <relativePath/> <!-- lookup parent from repository -->\n' +
                                       '</parent>\n'}
                                   </SyntaxHighlighter>
                               </li>

                               <li> specificare plugin (pentru adaugarea configurarilor implicite):
                                   <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                       {'<build>\n' +
                                       '        <plugins>\n' +
                                       '            <plugin>\n' +
                                       '                <groupId>org.springframework.boot</groupId>\n' +
                                       '                <artifactId>spring-boot-maven-plugin</artifactId>\n' +
                                       '            </plugin>\n' +
                                       '        </plugins>\n' +
                                       '</build>'}
                                   </SyntaxHighlighter>
                               </li>

                               <li> adaugare dependinta de <b>Spring Web</b>:
                                   <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                       {'<dependency>\n' +
                                       '    <groupId>org.springframework.boot</groupId>\n' +
                                       '    <artifactId>spring-boot-starter-web</artifactId>\n' +
                                       '</dependency>'}
                                   </SyntaxHighlighter>
                               </li>
                           </ul>
                       </li>

                        <li>
                            fisierul <b>application.properties</b> in directorul de resurse <b>resources</b>.
                            <br/>
                            <br/>
                        </li>

                        <li>
                            clasa de pornirea a aplicatiei (clasa main):
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.boot.mvc;\n' +
                                '\n' +
                                'import org.springframework.boot.SpringApplication;\n' +
                                'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                                '\n' +
                                '@SpringBootApplication\n // aceasta adnotare defineste clasa Main a unei aplicatii Spring Boot' +
                                'public class SpringBootMvcApp {\n' +
                                '    public static void main(String[] args) {\n' +
                                '        SpringApplication.run(SpringBootMvcApp.class, args);\n' +
                                '    }\n' +
                                '}'}
                            </SyntaxHighlighter>
                            Adnotarea unui clase cu <b>@SpringBootApplication</b> marcheaza ca aceasta clasa este clasa Main a unei aplicatii Spring Boot.
                            <br/>
                            Metoda <b>run()</b> a clasei <b>SpringApplication</b> lanseaza / porneste aplicatia.
                            <br/>
                            <br/>
                        </li>

                        <li>
                            la pornirea aplicatiei (Spring Boot) se va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'  .   ____          _            __ _ _\n' +
                                ' /\\\\ / ___\'_ __ _ _(_)_ __  __ _ \\ \\ \\ \\\n' +
                                '( ( )\\___ | \'_ | \'_| | \'_ \\/ _` | \\ \\ \\ \\\n' +
                                ' \\\\/  ___)| |_)| | | | | || (_| |  ) ) ) )\n' +
                                '  \'  |____| .__|_| |_|_| |_\\__, | / / / /\n' +
                                ' =========|_|==============|___/=/_/_/_/\n' +
                                ' :: Spring Boot ::                (v2.5.5)\n' +
                                '\n' +
                                '2021-12-30 12:43:13.672  INFO 11200 --- [           main] r.i.spring.boot.mvc.SpringBootMvcApp     : Starting SpringBootMvcApp using Java 14.0.1 on desktop-lenovo-y540 with PID 11200 (...)\n' +
                                '2021-12-30 12:43:13.674  INFO 11200 --- [           main] r.i.spring.boot.mvc.SpringBootMvcApp     : No active profile set, falling back to default profiles: default\n' +
                                '2021-12-30 12:43:14.167  INFO 11200 --- [           main] o.s.b.w.embedded.tomcat.TomcatWebServer  : Tomcat initialized with port(s): 8080 (http)\n' +
                                '2021-12-30 12:43:14.178  INFO 11200 --- [           main] o.apache.catalina.core.StandardService   : Starting service [Tomcat]\n' +
                                '2021-12-30 12:43:14.179  INFO 11200 --- [           main] org.apache.catalina.core.StandardEngine  : Starting Servlet engine: [Apache Tomcat/9.0.53]\n' +
                                '2021-12-30 12:43:14.230  INFO 11200 --- [           main] o.a.c.c.C.[Tomcat].[localhost].[/]       : Initializing Spring embedded WebApplicationContext\n' +
                                '2021-12-30 12:43:14.230  INFO 11200 --- [           main] w.s.c.ServletWebServerApplicationContext : Root WebApplicationContext: initialization completed in 531 ms\n' +
                                '2021-12-30 12:43:14.446  INFO 11200 --- [           main] o.s.b.w.embedded.tomcat.TomcatWebServer  : Tomcat started on port(s): 8080 (http) with context path \'\'\n' +
                                '2021-12-30 12:43:14.452  INFO 11200 --- [           main] r.i.spring.boot.mvc.SpringBootMvcApp     : Started SpringBootMvcApp in 1.049 seconds (JVM running for 1.612)'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Sprinb Boot se bazeaza pe:
                    <ul>
                        <li>conventii peste configurari</li>
                        <li>initiatori de dependenta</li>
                    </ul>

                    <hr/>

                    <b>2. Conventii peste configurari</b>
                    <br/>
                    <br/>

                    Spring Boot aplica principiul <b>convention-over-configuration</b> (conventii peste configurari).
                    <br/>
                    Pentru adaugarea configurarilor implicite, in <i>pom.xml</i> se aspecifica plugin-ul <i>pring-boot-maven-plugin</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<build>\n' +
                        '        <plugins>\n' +
                        '            <plugin>\n' +
                        '                <groupId>org.springframework.boot</groupId>\n' +
                        '                <artifactId>spring-boot-maven-plugin</artifactId>\n' +
                        '            </plugin>\n' +
                        '        </plugins>\n' +
                        '</build>'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Initiatori de dependenta</b>
                    <br/>
                    <br/>

                    Spring Boot vine cu <b>dependency starters</b> (inițiatori de dependență).
                    Un initiator de dependenta (ex: <i>spring-boot-starter-web</i>) este un grup de dependente adaugat pentru a configura aplicatia pentru un scop specific.
                    <br/>
                    Folosind initiatori de dependenta nu mai e nevoie de adauga <i>dependentele directe</i>.
                    Spring se asigura ca se adauga dependentele corecte si cu versiunele compatibile.


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default SpringBootContent;