import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AppConfigReactLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-app-config-react", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    ro.ibrid.app.config.application.list.ConfigurationPanelApp
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.application.list;\n' +
                            '\n' +
                            'import com.liferay.application.list.BasePanelApp;\n' +
                            'import com.liferay.application.list.PanelApp;\n' +
                            'import com.liferay.application.list.constants.PanelCategoryKeys;\n' +
                            'import com.liferay.portal.kernel.model.Portlet;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.ibrid.app.config.constants.AppConfigPortletKeys;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "panel.app.order:Integer=100",\n' +
                            '                "panel.category.key=" + PanelCategoryKeys.CONTROL_PANEL_CONFIGURATION\n' +
                            '        },\n' +
                            '        service = PanelApp.class\n' +
                            ')\n' +
                            'public class AppConfigPanelApp extends BasePanelApp {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Portlet getPortlet() {\n' +
                            '        return _portlet;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getPortletId() {\n' +
                            '        return AppConfigPortletKeys.PORTLET_NAME;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference(\n' +
                            '            target = "(javax.portlet.name=" + AppConfigPortletKeys.PORTLET_NAME + ")"\n' +
                            '    )\n' +
                            '    private Portlet _portlet;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alte valori posibile pentru <b>panel.category.key</b>:
                    <ul>
                        <li>
                            <b>PanelCategoryKeys.CONTROL_PANEL_CONFIGURATION</b>
                        </li>
                        <li>
                            <b>PanelCategoryKeys.CONTROL_PANEL_USERS</b>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.constants;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author iulianb\n' +
                            ' */\n' +
                            'public class AppConfigPortletKeys {\n' +
                            '\n' +
                            '\tpublic static final String PORTLET_NAME = "ro_ibrid_app_config_portlet_AppConfigPortlet";\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    ConfigParamDto:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.dto;\n' +
                            '\n' +
                            'import java.io.Serializable;\n' +
                            '\n' +
                            'public class ConfigParamDto implements Serializable {\n' +
                            '\n' +
                            '    private Long id;\n' +
                            '\n' +
                            '    private String code;\n' +
                            '\n' +
                            '    private String value;\n' +
                            '\n' +
                            '    private String description;\n' +
                            '\n' +
                            '    private String type;\n' +
                            '\n' +
                            '    public ConfigParamDto(long id, String value, String code, String description, String type) {\n' +
                            '        this.id = id;\n' +
                            '        this.value = value;\n' +
                            '        this.code = code;\n' +
                            '        this.description = description;\n' +
                            '        this.type = type;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Long getId() {\n' +
                            '        return id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setId(Long id) {\n' +
                            '        this.id = id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getCode() {\n' +
                            '        return code;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setCode(String code) {\n' +
                            '        this.code = code;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getValue() {\n' +
                            '        return value;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setValue(String value) {\n' +
                            '        this.value = value;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getDescription() {\n' +
                            '        return description;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setDescription(String description) {\n' +
                            '        this.description = description;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getType() {\n' +
                            '        return type;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setType(String type) {\n' +
                            '        this.type = type;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    AppConfigPortlet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.portlet;\n' +
                            '\n' +
                            'import ro.ibrid.app.config.constants.AppConfigPortletKeys;\n' +
                            '\n' +
                            'import com.liferay.frontend.js.loader.modules.extender.npm.NPMResolver;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                            '\n' +
                            'import java.io.IOException;\n' +
                            '\n' +
                            'import javax.portlet.Portlet;\n' +
                            'import javax.portlet.PortletException;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.portlet.RenderResponse;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author iulianb\n' +
                            ' */\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                "com.liferay.portlet.display-category=category.hidden",\n' +
                            '                "com.liferay.portlet.header-portlet-css=/css/index.css",\n' +
                            '                "com.liferay.portlet.scopeable=true",\n' +
                            '                "javax.portlet.init-param.template-path=/",\n' +
                            '                "javax.portlet.init-param.view-template=/view.jsp",\n' +
                            '                "javax.portlet.name=" + AppConfigPortletKeys.PORTLET_NAME,\n' +
                            '                "javax.portlet.resource-bundle=content.Language",\n' +
                            '                "javax.portlet.security-role-ref=administrator,power-user,user"\n' +
                            '        },\n' +
                            '        service = Portlet.class\n' +
                            ')\n' +
                            'public class AppConfigPortlet extends MVCPortlet {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void render(\n' +
                            '            RenderRequest renderRequest, RenderResponse renderResponse)\n' +
                            '            throws IOException, PortletException {\n' +
                            '\n' +
                            '        renderRequest.setAttribute(\n' +
                            '                "mainRequire",\n' +
                            '                _npmResolver.resolveModuleName("ibrid-app-config") + " as main");\n' +
                            '\n' +
                            '        super.render(renderRequest, renderResponse);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private NPMResolver _npmResolver;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    AppConfigRest:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.rest;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.exception.PortalException;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import org.osgi.service.jaxrs.whiteboard.JaxrsWhiteboardConstants;\n' +
                            'import ro.ibrid.app.config.dto.ConfigParamDto;\n' +
                            'import ro.ibrid.app.servicebuilder.model.ConfigParam;\n' +
                            'import ro.ibrid.app.servicebuilder.service.ConfigParamLocalService;\n' +
                            '\n' +
                            'import javax.ws.rs.*;\n' +
                            'import javax.ws.rs.core.Application;\n' +
                            'import javax.ws.rs.core.MediaType;\n' +
                            'import java.util.Collections;\n' +
                            'import java.util.List;\n' +
                            'import java.util.Set;\n' +
                            'import java.util.stream.Collectors;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                JaxrsWhiteboardConstants.JAX_RS_APPLICATION_BASE + "=/ibridappconfig",\n' +
                            '                JaxrsWhiteboardConstants.JAX_RS_NAME + "=IbridAppConfig.Rest"\n' +
                            '        }, service = Application.class)\n' +
                            'public class AppConfigRest extends Application {\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private ConfigParamLocalService configParamLocalService;\n' +
                            '\n' +
                            '    public Set<Object> getSingletons() {\n' +
                            '        return Collections.<Object>singleton(this);\n' +
                            '    }\n' +
                            '\n' +
                            '    @GET\n' +
                            '    @Path("/get-all-config-params")\n' +
                            '    @Produces(MediaType.APPLICATION_JSON)\n' +
                            '    public List<ConfigParamDto> getAllAppConfig() {\n' +
                            '        List<ConfigParam> list = configParamLocalService.getConfigParams(0,1000);\n' +
                            '\n' +
                            '        List<ConfigParamDto> returnList = list.stream().map(configParam -> {\n' +
                            '            return new ConfigParamDto(\n' +
                            '                    configParam.getId(),\n' +
                            '                    configParam.getValue(),\n' +
                            '                    configParam.getCode(),\n' +
                            '                    configParam.getDescription(),\n' +
                            '                    configParam.getParamType());\n' +
                            '        }).collect(Collectors.toList());\n' +
                            '\n' +
                            '        return returnList;\n' +
                            '    }\n' +
                            '\n' +
                            '    @POST\n' +
                            '    @Path("/update/{id}/{newValue}")\n' +
                            '    public boolean update(@PathParam("id") Long id, @PathParam("newValue") String newValue) throws PortalException {\n' +
                            '\n' +
                            '        ConfigParam configParam = configParamLocalService.getConfigParam(id);\n' +
                            '\n' +
                            '        configParam.setValue(newValue);\n' +
                            '\n' +
                            '        configParamLocalService.updateConfigParam(configParam);\n' +
                            '\n' +
                            '        return true;\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Language.properties:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javax.portlet.title.ro_ibrid_app_config_portlet_AppConfigPortlet = App Config\n' +
                            'javax.portlet.display-name.ro_ibrid_app_config_portlet_AppConfigPortlet=App Config\n' +
                            'javax.portlet.keywords.ro_ibrid_app_config_portlet_AppConfigPortlet=App Config\n' +
                            'javax.portlet.short-title.ro_ibrid_app_config_portlet_AppConfigPortlet=App Config\n' +
                            '\n' +
                            'app.config.title = App Configuration'}
                    </SyntaxHighlighter>

                    index.css:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'.table-dark th, .table-dark td, .table-dark thead th {\n' +
                            '    border-color: #f1f1f1;\n' +
                            '    color: black;\n' +
                            '}\n' +
                            '\n' +
                            '.table-dark {\n' +
                            '    background-color: #393a4a;\n' +
                            '    color: black;\n' +
                            '}\n' +
                            '\n' +
                            '\n' +
                            '.form-control {\n' +
                            '    background-color: white;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    /lib/index.es.js
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import React, {useEffect, useState} from \'react\';\n' +
                            'import ReactDOM from \'react-dom\';\n' +
                            '\n' +
                            'const Liferay = window.Liferay;\n' +
                            '\n' +
                            'const AppConfig = (props) => {\n' +
                            '\n' +
                            '    const [data, setData] = useState([]);\n' +
                            '    const [isLoading, setIsLoading] = useState(true);\n' +
                            '    const [query, setQuery] = useState(\'\')\n' +
                            '\n' +
                            '    const GET_ALL_CONFIG_PARAMS_URL = "/o/ibridappconfig/get-all-config-params";\n' +
                            '    const UPDATE_CONFIG_PARAM_URL = "/o/ibridappconfig/update";\n' +
                            '\n' +
                            '    useEffect(() => {\n' +
                            '        setIsLoading(true);\n' +
                            '\n' +
                            '        Liferay.Util.fetch(GET_ALL_CONFIG_PARAMS_URL, {\n' +
                            '            method: \'GET\'\n' +
                            '        }).then(function (response) {\n' +
                            '            console.log(response);\n' +
                            '            return response.json();\n' +
                            '        }).then(function (response) {\n' +
                            '            setData(response)\n' +
                            '            setIsLoading(false)\n' +
                            '            console.log(response);\n' +
                            '        }).catch(function (error) {\n' +
                            '            setIsLoading(false)\n' +
                            '            console.log(error);\n' +
                            '        });\n' +
                            '    }, []);\n' +
                            '\n' +
                            '    if (!data) {\n' +
                            '        return \'loading\';\n' +
                            '    }\n' +
                            '\n' +
                            '    const onBlur = (id, value) => {\n' +
                            '\n' +
                            '        Liferay.Util.fetch(UPDATE_CONFIG_PARAM_URL+"/"+id+"/"+value, {\n' +
                            '            method: \'POST\',\n' +
                            '        }).then(function (response) {\n' +
                            '            return response.json();\n' +
                            '        }).then(function (response) {\n' +
                            '            console.log(response);\n' +
                            '        }).catch(function (error) {\n' +
                            '            console.log(error);\n' +
                            '        });\n' +
                            '\n' +
                            '        const newData = data.map((c)=>{\n' +
                            '            if (c.id === id){\n' +
                            '                c.value = value;\n' +
                            '            }\n' +
                            '            return c;\n' +
                            '        })\n' +
                            '        setData([...newData]);\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    const handleChange = (e) => {\n' +
                            '        setQuery(e.target.value)\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '        <div className="container">\n' +
                            '            {/*<div>*/}\n' +
                            '            {/*    <div>*/}\n' +
                            '            {/*        <span>{Liferay.Language.get(\'app.config.title\')}</span>*/}\n' +
                            '            {/*    </div>*/}\n' +
                            '            {/*</div>*/}\n' +
                            '\n' +
                            '            <br/>\n' +
                            '\n' +
                            '            <div>\n' +
                            '                <div className="input-group mb-3">\n' +
                            '                    <div className="input-group-prepend">\n' +
                            '                        <span className="input-group-text" id="inputGroup-sizing-default">Cauta</span>\n' +
                            '                    </div>\n' +
                            '                    <input type="search" value={query} onChange={handleChange} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>\n' +
                            '                </div>\n' +
                            '            </div>\n' +
                            '\n' +
                            '            <table className="table table-hover table-dark">\n' +
                            '                <thead>\n' +
                            '                <tr>\n' +
                            '                    <th scope="col">Cod</th>\n' +
                            '                    <th scope="col">Valoare</th>\n' +
                            '                    <th scope="col">Descriere</th>\n' +
                            '                </tr>\n' +
                            '                </thead>\n' +
                            '                <tbody>\n' +
                            '                    {\n' +
                            '                        data.filter(config =>{\n' +
                            '                                if (query==""){\n' +
                            '                                    return config;\n' +
                            '                                }else{\n' +
                            '                                   return config.code.toLowerCase().includes(query.toLowerCase());\n' +
                            '                                }\n' +
                            '                            })\n' +
                            '                            .map(config =>\n' +
                            '                            <tr  key={config.id}>\n' +
                            '                                <td>{config.code}</td>\n' +
                            '                                <td>\n' +
                            '                                    <input  className="form-control"\n' +
                            '                                            type="text"\n' +
                            '                                            defaultValue={config.value}\n' +
                            '                                            onBlur={e => onBlur(config.id, e.target.value)}/>\n' +
                            '                                </td>\n' +
                            '                                <td>{config.description}</td>\n' +
                            '                            </tr>\n' +
                            '                        )\n' +
                            '                    }\n' +
                            '\n' +
                            '                </tbody>\n' +
                            '            </table>\n' +
                            '\n' +
                            '        </div>\n' +
                            '    )\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'export default function (elementId) {\n' +
                            '    ReactDOM.render(\n' +
                            '        <AppConfig/>,\n' +
                            '        document.getElementById(elementId)\n' +
                            '    );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    init.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                            '\n' +
                            '<liferay-theme:defineObjects />\n' +
                            '\n' +
                            '<portlet:defineObjects />\n' +
                            '\n' +
                            '<%\n' +
                            'String mainRequire = (String)renderRequest.getAttribute("mainRequire");\n' +
                            '%>'}
                    </SyntaxHighlighter>

                    view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<div id="<portlet:namespace />-root"></div>\n' +
                            '\n' +
                            '<aui:script require="<%= mainRequire %>">\n' +
                            '\tmain.default(\'<portlet:namespace />-root\');\n' +
                            '</aui:script>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '\t"presets": ["@babel/preset-env", "@babel/preset-react"]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: ibrid-app-config\n' +
                            'Bundle-SymbolicName: ro.ibrid.app.config\n' +
                            'Bundle-Version: 1.0.1\n' +
                            'Export-Package: ro.ibrid.app.config.constants\n' +
                            'Web-ContextPath: /app-config\n'}
                    </SyntaxHighlighter>

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\n' +
                            '\tcompile project(":modules:ibrid-app-servicebuilder:ibrid-app-servicebuilder-api")\n' +
                            '\n' +
                            '\tcompileOnly group: "javax.ws.rs", name: "javax.ws.rs-api", version: "2.1"\n' +
                            '\tcompileOnly group: "org.osgi", name: "org.osgi.service.component.annotations", version: "1.3.0"\n' +
                            '\tcompileOnly group: "org.osgi", name: "org.osgi.service.jaxrs", version: "1.0.0"\n' +
                            '\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    package.json:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '\t"dependencies": {\n' +
                            '\t\t"react": "17.0.0",\n' +
                            '\t\t"react-dom": "17.0.0"\n' +
                            '\t},\n' +
                            '\t"devDependencies": {\n' +
                            '\t\t"@babel/cli": "^7.0.0",\n' +
                            '\t\t"@babel/core": "^7.0.0",\n' +
                            '\t\t"@babel/preset-env": "^7.0.0",\n' +
                            '\t\t"@babel/preset-react": "^7.0.0",\n' +
                            '\t\t"liferay-npm-bundler": "2.18.2"\n' +
                            '\t},\n' +
                            '\t"main": "lib/index.es.js",\n' +
                            '\t"name": "ibrid-app-config",\n' +
                            '\t"scripts": {\n' +
                            '\t\t"build": "babel --source-maps -d build/resources/main/META-INF/resources src/main/resources/META-INF/resources && liferay-npm-bundler"\n' +
                            '\t},\n' +
                            '\t"version": "1.0.0"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    service.xml:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                            '<!DOCTYPE service-builder PUBLIC "-//Liferay//DTD Service Builder 7.4.0//EN" "http://www.liferay.com/dtd/liferay-service-builder_7_4_0.dtd">\n' +
                            '\n' +
                            '<service-builder dependency-injector="ds" package-path="ro.ibrid.app.servicebuilder">\n' +
                            '\n' +
                            '\t<author>IBRID</author>\n' +
                            '\t<namespace>ibrid_portal</namespace>\n' +
                            '\n' +
                            '\t<entity name="ConfigParam" local-service="true" remote-service="false">\n' +
                            '\n' +
                            '\t\t<!-- PK fields -->\n' +
                            '\t\t<column name="id" primary="true" type="long" />\n' +
                            '\n' +
                            '\t\t<column name="code" type="String" />\n' +
                            '\t\t<column name="value" type="String" />\n' +
                            '\t\t<column name="description" type="String" />\n' +
                            '\t\t<column name="paramType" type="String" />\n' +
                            '\n' +
                            '\t</entity>\n' +
                            '</service-builder>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AppConfigReactLiferayContent;