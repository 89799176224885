import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class APIVersionDeprecationsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-api-version-deprecations", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>API Version</b>
                    <br/>
                    <br/>

                    Pot exista versiuni:
                    <ul>
                        <li>alpha (ex: v1alpha2)</li>
                        <li>beta (ex: v1beta2)</li>
                        <li>GA (ex: v1)</li>
                    </ul>

                    <hr/>
                    <b>API Deprecations</b>
                    <br/>
                    <br/>
                    Reguli:
                    <ul>
                        <li>
                            regula 1: Elementele API pot fi sterse doar prin incrementarea versiunii din API group!
                        </li>
                        <li>
                            regula 2: obiectele API trebuie sa fie capabile de "round-trip" intre versiuni API pentru a da release fara pierderi de informatii,
                            exceptand cazul in care care intreaga resursa REST nu exista in unele versiuni
                        </li>
                        <li>
                            regula 4a:
                            <ul>
                                <li>
                                    GA trebuie sa fie suportata 12 luni sau 3 release-uri
                                </li>
                                <li>
                                    Beta: 9 luni / 3 release-uri
                                </li>
                                <li>
                                    Alpha : 0 release-uri
                                </li>
                            </ul>
                        </li>
                        <li>
                            regula 3:
                            un API version nu va fi deprecared pana cand o noua versiune API cel putin la fel de stabila nu este lasanta.
                        </li>
                        <li>
                            regula 4b:
                            Versiunea API preferata si "storage version" pentru un grup poate sa nu avanseze decat dupa ce un release este suportat de ambele: noua versiune si versiunea de dinainte!
                            <br/>
                            deci, putem avea:
                            <ul>
                                <li>
                                    v1beta1 (deprecated, dar preferata in storage version) + v1beta2
                                </li>
                                <li>
                                    abia cand apare un nou release, v1beta2 devine preferata si v1beta1, devine deprecated
                                </li>
                                <li>
                                    cand apare v1, v1beta2 => prefered, dar devine si ea deprecared
                                </li>
                                <li>
                                    la un nou release, v1 devine prefered
                                </li>
                                <li>
                                    la un nou release, v1 devine prefered
                                </li>
                                <li>
                                    la un nou release, v1 devine prefered, dar  v1beta2 este sters
                                </li>
                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>Kubectl Convert</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'kubectl convert -f old-file --output-version new-api'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl convert -f nginx.yaml --output-version apps/v1'}
                    </SyntaxHighlighter>

                    In mod implicit, plugin-ul kubectl convers s-ar putea sa nu fie instalat in mod implicit, deci trebuie instalat!

                    <hr/>
                    <b>Exercitii</b>
                    <br/>
                    <br/>
                    Afisare shortname pentru obiecte:
                    <SyntaxHighlighter>
                        {'kubectl api-resources'}
                    </SyntaxHighlighter>

                    Descriere obiect:
                    <SyntaxHighlighter>
                        {'kubectl explain job'}
                    </SyntaxHighlighter>

                    Aflare versiune preferata:
                    <SyntaxHighlighter>
                        {'kubectl proxy 8001&'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'curl localhost:8001/apis/authorization.k8s.io'}
                    </SyntaxHighlighter>

                    Activare versiunea <i>v1alpha1</i> pentru grupul API <i>rbac.authorization.k8s.io</i> pe nodul controlplane:
                    <br/>
                    (intai facem o copie)
                    <SyntaxHighlighter>
                        {'cp -v /etc/kubernetes/manifests/kube-apiserver.yaml /root/kube-apiserver.yaml.backup'}
                    </SyntaxHighlighter>
                    (in caz de vreo problema)
                    <SyntaxHighlighter>
                        {'cp -v /root/kube-apiserver.yaml.backup /etc/kubernetes/manifests/kube-apiserver.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'vi /etc/kubernetes/manifests/kube-apiserver.yaml'}
                    </SyntaxHighlighter>
                    Se adauga in <b>--runtime-config</b>:
                    <SyntaxHighlighter>
                        {' - command:\n' +
                            '    - kube-apiserver\n' +
                            '    - --advertise-address=10.18.17.8\n' +
                            '    - --allow-privileged=true\n' +
                            '    - --authorization-mode=Node,RBAC\n' +
                            '    - --client-ca-file=/etc/kubernetes/pki/ca.crt\n' +
                            '    - --enable-admission-plugins=NodeRestriction\n' +
                            '    - --enable-bootstrap-token-auth=true\n' +
                            '    - --runtime-config=rbac.authorization.k8s.io/v1alpha1 --> This one '}
                    </SyntaxHighlighter>
                    (dupa aceasta modificate se recreaza pod-ul)
                    <br/>
                    Verificare status:
                    <SyntaxHighlighter>
                        {'kubectl get po -n kube-system'}
                    </SyntaxHighlighter>

                    <hr/>
                    Instalare Kubectl Convert:
                    <SyntaxHighlighter>
                        {'curl -LO https://dl.k8s.io/release/$(curl -L -s https://dl.k8s.io/release/stable.txt)/bin/linux/amd64/kubectl-convert'}
                    </SyntaxHighlighter>
                    schimbare permisiuni:
                    <SyntaxHighlighter>
                        {'chmod +x kubectl-convert '}
                    </SyntaxHighlighter>
                    mutat in /usr/local/bin:
                    <SyntaxHighlighter>
                        {'mv kubectl-convert /usr/local/bin/kubectl-convert'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter>
                        {'kubectl-convert --help'}
                    </SyntaxHighlighter>

                    Exemplu de conversie (schimbare in versiunea networking.k8s.io/v1):
                    <SyntaxHighlighter>
                        {'kubectl-convert -f ingress-old.yaml --output-version networking.k8s.io/v1'}
                    </SyntaxHighlighter>
                    Testare convesie:
                    <SyntaxHighlighter>
                        {'kubectl-convert -f ingress-old.yaml --output-version networking.k8s.io/v1 > ingress-new.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl create -f ingress-new.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl get ing ingress-space -oyaml | grep apiVersion'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default APIVersionDeprecationsK8sContent;