import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"cv-index", url:"#", title:"CV", subtitle:""},

        {id:"certificates-cv", url:"/cv/certificates", title:"Certificari", subtitle:"", parent:"cv-index"},

    ]

    static indexUrl = "/cv/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    CV
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;