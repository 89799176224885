import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ClosuresPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-closures", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Closures</b>
                    <br/> <br/>

                    <b>Closures este o tehnică care permite stocarea valorilor desi contextul în care au fost create nu mai există.</b>
                    <br/>
                    Altfel spus, <b>closure este o funcție care are acces la domeniul părinte, chiar și după ce funcția părinte <i>s-a închis</i></b>.

                    <br/>
                    <br/>
                    Sa pornim de la un exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(x):\n' +
                        '    y=x\n' +
                        '    \n' +
                        'print(x) # NameError: name \'x\' is not defined\n' +
                        'print(y) # NameError: name \'y\' is not defined'}
                    </SyntaxHighlighter>

                    Cele doua variabile <i>x</i> si <i>y</i> nu sunt accesibile/vizibile in afara clasei. Normal!
                    <br/>
                    <br/>
                    Acum sa creem o functie, in interiorul functiei <i>functie_exterioara()</i> si s-a numim <i>functie_interioara()</i>. Apoi functia <i>functie_exterioara()</i> va returna referinta la functia <i>functie_interioara()</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie_exterioara(x):\n' +
                        '    y=x\n' +
                        '    \n' +
                        '    def functie_interioara():\n' +
                        '        return (x,y)\n' +
                        '    \n' +
                        '    return functie_interioara    \n' +
                        '   \n' +
                        'r1 = functie(1)\n' +
                        'r2 = functie(2)\n' +
                        '\n' +
                        'print(functie_exterioara) # <function functie at 0x7f31935f7050>\n' +
                        'print(r1) # <function functie.<locals>.functie_interioara at 0x7fe6947380e0>\n' +
                        'print(r2) # <function functie.<locals>.functie_interioara at 0x7fe694738290>\n' +
                        '\n' +
                        'print(r1()) # (1, 1)\n' +
                        'print(r2()) # (2, 2)'}
                    </SyntaxHighlighter>

                    Funcția returnată în timpul apelarii functiei <i>functie</i> este o closure (închidere).
                    <br/>
                    <br/>
                    O functie closure poate returna valorile ale variabilelor accesibile in interiorul documeniului sau, deoarece poate folosi oricare dintre entitățile de care dispune functia care o gazduieste, adica functia parinte.
                    <br/>
                    <br/>
                    In exemplul de mai sus, functia <i>functie_exterioara()</i> returnează însăși funcția <i>functie_interioara()</i>;
                    mai exact, returnează o copie a funcției <i>functie_interioara()</i>, cea care a fost <b>înghețată în momentul invocării</b> lui  <i>functie_exterioara()</i>;
                    funcția înghețată <b>conține mediul său complet, inclusiv starea tuturor variabilelor locale</b>.

                    <br/>
                    <br/>
                    Observatii pe codul de mai sus:
                    <ul>
                        <li>
                            se pot creea oricate closure folosind una și aceeași bucată de cod; in exemplul de mai sus:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'r1 = functie(1)\n' +
                                'r2 = functie(2)'}
                            </SyntaxHighlighter>
                        </li>
                        <li>o functie closure nu numai că folosește mediul înghețat, dar își poate modifica și comportamentul utilizând valori luate din exterior;
                            <br/>
                            adica poate returna valori diferite:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(r1()) # (1, 1)\n' +
                                'print(r2()) # (2, 2)'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <br/>

                    O functie closure trebuie invocată exact în același mod în care a fost declarată (de exemplu, daca are 2 parametri, trebuie apelata cu 2 argumente).


                    <hr/>

                    <b>Filosofie</b>
                    <br/>
                    <br/>
                    <b>O functie care acceseaza entitatile parintelui este o functie closure.</b>

                    <br/>
                    O functie <i>pura</i> nu are nevoie de alta informatie din afara, exceptie fiind informatia primita prin intermediul parametrilor.
                    <br/>
                    <br/>
                    Exemplu de functie pura:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functiePura(a,b):\n' +
                        '    return a+b'}
                    </SyntaxHighlighter>

                    Exemplu de functie impura:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'constanta = 5\n' +
                        '\n' +
                        'def functieImpura(a,b):\n' +
                        '    return a+b + constanta\n' +
                        '\n' +
                        'print(functieImpura(2,3))'}
                    </SyntaxHighlighter>

                    Aceasta functie impura este un closure, de fapt.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClosuresPythonContent;