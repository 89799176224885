import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerfileEntrypointDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dockerfile-entrypoint", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Dockerfile: ENTRYPOINT</b>

                    <br/>
                    <br/>

                    <b>ENTRYPOINT</b> -  specificare comanda care se va executa la pornirea containerului;
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ENTRYPOINT ["executable", "param1", "param2"]'}
                    </SyntaxHighlighter>

                    exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ENTRYPOINT ["npm"]'}
                    </SyntaxHighlighter>
                    parametrii se adauga la <i>npm</i>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM node\n' +
                            'WORKDIR /app\n' +
                            'ENTRYPOINT ["npm"]'}
                    </SyntaxHighlighter>
                    daca executam: docker run [container] init {"=>"} docker run [container] npm init;
                    <br/>
                    Instructiunea <b>ENTRYPOINT</b> <i>nu poate fi suprascrisa</i>, argumentele se adauga la <i>executabilul</i> specificat;
                    <hr/>
                    Exemplu, daca avem urmatorul Dockerfile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM busybox\n' +
                            'CMD ["sh"]'}
                    </SyntaxHighlighter>
                    si facem build:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t b1 .'}
                    </SyntaxHighlighter>
                    putem apoi rula:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it b1'}
                    </SyntaxHighlighter>
                    sau chiar suprascrie comanda <i>sh</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it b1 ping -c 2 google.com'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'PING google.com (142.251.39.14): 56 data bytes\n' +
                            '64 bytes from 142.251.39.14: seq=0 ttl=57 time=16.217 ms\n' +
                            '64 bytes from 142.251.39.14: seq=1 ttl=57 time=16.151 ms\n' +
                            '\n' +
                            '--- google.com ping statistics ---\n' +
                            '2 packets transmitted, 2 packets received, 0% packet loss\n' +
                            'round-trip min/avg/max = 16.151/16.184/16.217 ms\n'}
                    </SyntaxHighlighter>

                    Daca am avea Dockerfile cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM busybox\n' +
                            'ENTRYPOINT ["/bin/ping"]'}
                    </SyntaxHighlighter>
                    atunci comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it b1 ping -c 2 google.com'}
                    </SyntaxHighlighter>
                    s-ar traduce in: <b>/bin/ping ping -c 2 google.com</b> {"=>"} deci s-ar obtine eroare;
                    <br/>
                    corect ar fi (pentru ca <b>ENTRYPOINT</b> <i>nu poate fi suprascrisa</i>, argumentele se adauga la <i>executabilul</i> specificat, la /bin/ping, in cazul de fata):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it b1 -c 2 google.com'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerfileEntrypointDockerContent;