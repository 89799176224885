import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../raspberrypi/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AudioRpiContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rpi-audio", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    1. Afisare device-uri de inregistrare (card 2, device 0, in cazul de mai jos)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'arecord -l'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {  '**** List of CAPTURE Hardware Devices ****\n' +
                            'card 2: LX3000 [Microsoft LifeChat LX-3000], device 0: USB Audio [USB Audio]\n' +
                            '  Subdevices: 1/1\n' +
                            '  Subdevice #0: subdevice #0'}
                    </SyntaxHighlighter>

                    2. Inregistrare 5 secunde:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'arecord -D plughw:2,0 --format=S16_LE --duration=5 --file-type=raw --rate=16000 myrecording.wav'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Recording raw data \'myrecording.wav\' : Signed 16 bit Little Endian, Rate 16000 Hz, Mono'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>plughw:2,0 = plughw:[card],[device]</li>
                    </ul>

                    3. Redare inregistrare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'aplay --format=S16_LE  --rate=16000 myrecording.wav'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Playing raw data \'myrecording.wav\' : Signed 16 bit Little Endian, Rate 16000 Hz, Mono'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AudioRpiContent;