import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"nginx-root", url:"#", title:"nginx", subtitle:""},

        {id:"nginx-install", url:"/nginx/install", title:"Instalare nginx", subtitle:"", parent:"nginx-root"},
        {id:"nginx-virtual-host", url:"/nginx/virtual-host", title:"Creare Virtual Host", subtitle:"", parent:"nginx-root"},
        {id:"nginx-location-blocks", url:"/nginx/location-blocks", title:"Blocuri location", subtitle:"", parent:"nginx-root"},
        {id:"nginx-variables", url:"/nginx/variables", title:"Variabile", subtitle:"", parent:"nginx-root"},
        {id:"nginx-rewrites-redirects", url:"/nginx/rewrites-redirects", title:"Rescriere. Redirect", subtitle:"", parent:"nginx-root"},
        {id:"nginx-try-files-named-locations", url:"/nginx/try-files-named-locations", title:"Directiva try_files. Nume locatii", subtitle:"", parent:"nginx-root"},
        {id:"nginx-logging", url:"/nginx/logging", title:"Logare", subtitle:"", parent:"nginx-root"},
        {id:"nginx-inheritince-directive-types", url:"/nginx/inheritince-directive-types", title:"Mostenire si tipuri de directive", subtitle:"", parent:"nginx-root"},
        {id:"nginx-php", url:"/nginx/php", title:"PHP", subtitle:"", parent:"nginx-root"},
        {id:"nginx-worker-processes", url:"/nginx/worker-processes", title:"Worker Processes", subtitle:"", parent:"nginx-root"},
        {id:"nginx-buffers-timeouts", url:"/nginx/buffers-timeouts", title:"Buffers. Timeouts", subtitle:"", parent:"nginx-root"},
        {id:"nginx-adding-dynamic-modules", url:"/nginx/adding-dynamic-modules", title:"Adaugare dinamica de module", subtitle:"", parent:"nginx-root"},
        {id:"nginx-headers-expires", url:"/nginx/headers-expires", title:"Headers. Expires", subtitle:"", parent:"nginx-root"},
        {id:"nginx-compressed-responses", url:"/nginx/compressed-responses", title:"Compresia raspunsurilor", subtitle:"", parent:"nginx-root"},
        {id:"nginx-fastcgi-cache", url:"/nginx/fastcgi-cache", title:"FastCGI Cache", subtitle:"", parent:"nginx-root"},
        {id:"nginx-http2", url:"/nginx/http2", title:"HTTP2", subtitle:"", parent:"nginx-root"},
        {id:"nginx-server-push", url:"/nginx/server-push", title:"Server Push", subtitle:"", parent:"nginx-root"},

        {id:"nginx-https", url:"/nginx/https", title:"Https", subtitle:"", parent:"nginx-root"},
        {id:"nginx-rate-limiting", url:"/nginx/rate-limiting", title:"Rate limiting", subtitle:"", parent:"nginx-root"},
        {id:"nginx-basic-auth", url:"/nginx/basic-auth", title:"Basic Auth", subtitle:"", parent:"nginx-root"},
        {id:"nginx-hardening", url:"/nginx/hardening", title:"Hardening", subtitle:"", parent:"nginx-root"},
        {id:"nginx-ssl-certificates", url:"/nginx/ssl-certificates", title:"Certificate SSL (Let's Encrypt)", subtitle:"", parent:"nginx-root"},

        {id:"nginx-reverse-proxy", url:"/nginx/reverse-proxy", title:"Reverse Proxy", subtitle:"", parent:"nginx-root"},
        {id:"nginx-load-balancer", url:"/nginx/load-balancer", title:"Load Balancer", subtitle:"", parent:"nginx-root"},

        {id:"nginx-links", url:"/nginx/links", title:"Link-uri si resurse utile", subtitle:"", parent:"nginx-root"},
    ]

    static indexUrl = "/nginx/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Nginx
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;