import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class BuildServiceContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-build-service", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Generare schelet pentru Service Builder</b>
                    <br/>
                    <br/>
                    Din Intellij, avand instalat plugin de Liferay:
                    <ul>
                        <li>File {"=>"} New {"=>"} Module</li>
                        <li>din fereastra care apare:
                            <ul>
                                <li>se selecteaza din stanga: Liferay</li>
                                <li>se selecteaza din dreapta: Liferay Modules</li>
                                <li>Next</li>
                            </ul>
                        </li>
                        <li>
                            din ferestra care apare:
                            <ul>
                                <li>Module name: data</li>
                                <li>Please select a project type: <b>service-builder</b></li>
                                <li>Next</li>
                            </ul>
                        </li>

                        <li>
                            din ferestra care apare:
                            <ul>
                                <li>Package Name: ro.ibedaria.liferay.vanilla.data</li>
                                <li>Finish</li>
                            </ul>
                        </li>

                    </ul>

                    In modules/data se va genera o structura de modul, care contine alte 2 module Gradle:
                    <ul>
                        <li>data-api</li>
                        <li>data-service</li>
                    </ul>

                    De exemplu, <b>build.gradle</b> din <b>data-service</b> are urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompile project(":modules:data:data-api")\n' +
                        '\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '}\n' +
                        '\n' +
                        'buildService {\n' +
                        '\tapiDir = "../data-api/src/main/java"\n' +
                        '}\n' +
                        '\n' +
                        'group = "ro.ibedaria.liferay.vanilla.data"'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b> din <b>data-service</b>, are urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Bundle-Name: data-service\n' +
                        'Bundle-SymbolicName: ro.ibedaria.liferay.vanilla.data.service\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Liferay-Require-SchemaVersion: 1.0.0\n' +
                        'Liferay-Service: true\n' +
                        '-dsannotations-options: inherit'}
                    </SyntaxHighlighter>

                    Iar <b>build.gradle</b> din <b>data-api</b> are urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Fisierul <b>bnd.bnd</b> din <b>data-api</b>, are urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Bundle-Name: data-api\n' +
                        'Bundle-SymbolicName: ro.ibedaria.liferay.vanilla.data.api\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Export-Package:\\\n' +
                        '\tro.ibedaria.liferay.vanilla.data.exception,\\\n' +
                        '\tro.ibedaria.liferay.vanilla.data.model,\\\n' +
                        '\tro.ibedaria.liferay.vanilla.data.service,\\\n' +
                        '\tro.ibedaria.liferay.vanilla.data.service.persistence\n' +
                        '-check: EXPORTS\n' +
                        '-includeresource: META-INF/service.xml=../data-service/service.xml'}
                    </SyntaxHighlighter>

                    Exemplu de <b>service.xml</b>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<!DOCTYPE service-builder PUBLIC "-//Liferay//DTD Service Builder 7.3.0//EN" "http://www.liferay.com/dtd/liferay-service-builder_7_3_0.dtd">\n' +
                        '\n' +
                        '<service-builder dependency-injector="ds" package-path="ro.ibedaria.liferay.vanilla.data">\n' +
                        '\t<namespace>ibedaria</namespace>\n' +
                        '\t<!--<entity data-source="sampleDataSource" local-service="true" name="Foo" remote-service="false" session-factory="sampleSessionFactory" table="foo" tx-manager="sampleTransactionManager uuid="true"">-->\n' +
                        '\t<entity local-service="true" name="ConfigIB" remote-service="true" uuid="true">\n' +
                        '\n' +
                        '\t\t<!-- PK fields -->\n' +
                        '\n' +
                        '\t\t<column name="id" primary="true" type="long" />\n' +
                        '\n' +
                        '\t\t<!-- Group instance -->\n' +
                        '\n' +
                        '\t\t<column name="groupId" type="long" />\n' +
                        '\n' +
                        '\t\t<!-- Audit fields -->\n' +
                        '\n' +
                        '\t\t<column name="companyId" type="long" />\n' +
                        '\t\t<column name="userId" type="long" />\n' +
                        '\t\t<column name="userName" type="String" />\n' +
                        '\t\t<column name="createDate" type="Date" />\n' +
                        '\t\t<column name="modifiedDate" type="Date" />\n' +
                        '\n' +
                        '\t\t<!-- Other fields -->\n' +
                        '\n' +
                        '\t\t<column name="code" type="String" />\n' +
                        '\t\t<column name="name" type="String" />\n' +
                        '\t\t<column name="value" type="String" />\n' +
                        '\t\t<column name="typeId" type="String" />\n' +
                        '\n' +
                        '\t\t<!-- Order -->\n' +
                        '\n' +
                        '\t\t<order by="asc">\n' +
                        '\t\t\t<order-column name="code" />\n' +
                        '\t\t</order>\n' +
                        '\n' +
                        '\t</entity>\n' +
                        '</service-builder>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Generare servicii folosind Service Builder</b>

                    <br/>
                    <br/>

                    Pe baza fisierului <b>service.xml</b> se genereaza sau construiesc serviciile.
                    <br/>
                    <br/>

                    Se urmeaza urmatorii pasi:
                    <ul>
                        <li>din linie de comanda, se navigheaza in folderul care conține modulele <i>*-api</i> și <i>*-service</i></li>
                        <li>se executa urmatoarea comanda (in functie daca se foloseste gradele sau maven):
                            <ul>
                                <li>
                                    cu gradle: <b>gradlew buildService</b> sau <b>gradle buildService</b>
                                </li>
                                <li>
                                    cu maven: <b>mvn service-builder:build</b> (functioneaza doar cu plugin-ul <i>com.liferay.portal.tools.service.builder</i>, versiunea <i>1.0.145+</i>)
                                </li>
                            </ul>
                        </li>
                        <li>pentru a face deploy: <b>gradlew deploy</b></li>
                    </ul>

                    Exemplu (se ruleaza din \modules\data\data-service):
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'PS D:\\work\\liferay-vanilla\\modules\\data\\data-service> ..\\..\\..\\gradlew buildService'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Metode custom pe local si remote service</b>

                    <br/>
                    <br/>
                    Se pot adauga metode noi in service-ul local.
                    <br/>

                    <br/>.
                    Pornind de la exemplul de mai sus, metodele noi se vor adauga in clasa <b>ConfigIBLocalServiceImpl</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.liferay.vanilla.data.service.impl;\n' +
                        '\n' +
                        'import com.liferay.portal.aop.AopService;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.base.ConfigIBLocalServiceBaseImpl;\n' +
                        '\n' +
                        '@Component(\n' +
                        '\tproperty = "model.class.name=ro.ibedaria.liferay.vanilla.data.model.ConfigIB",\n' +
                        '\tservice = AopService.class\n' +
                        ')\n' +
                        'public class ConfigIBLocalServiceImpl extends ConfigIBLocalServiceBaseImpl {\n' +
                            '   // metode noi'+
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.liferay.vanilla.data.service.impl;\n' +
                        '\n' +
                        'import com.liferay.portal.aop.AopService;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import ro.ibedaria.liferay.vanilla.data.model.ConfigIB;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.model.impl.ConfigIBImpl;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.base.ConfigIBLocalServiceBaseImpl;\n' +
                        '\n' +
                        '@Component(\n' +
                        '\tproperty = "model.class.name=ro.ibedaria.liferay.vanilla.data.model.ConfigIB",\n' +
                        '\tservice = AopService.class\n' +
                        ')\n' +
                        'public class ConfigIBLocalServiceImpl extends ConfigIBLocalServiceBaseImpl {\n' +
                        '\n' +
                        '\tpublic ConfigIB addConfigIB(String code, String name, String value){\n' +
                        '\n' +
                        '\t\tConfigIB configIB = new ConfigIBImpl();\n' +
                        '\t\tconfigIB.setCode(code);\n' +
                        '\t\tconfigIB.setName(name);\n' +
                        '\t\tconfigIB.setValue(value);\n' +
                        '\t\t// se genereaza urmatorul id\n' +
                        '\t\tconfigIB.setId(counterLocalService.increment(ConfigIB.class.getName()));\n' +
                        '\n' +
                        '\t\tconfigIBLocalService.addConfigIB(configIB);\n' +
                        '\n' +
                        '\t\treturn configIB;\n' +
                        '\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    De fiecare data dupa ce se adauga o metoda noua, trebuie rulat din nou <b>Service Builder</b>, pentru a completa clasele generate!

                    <br/>
                    <br/>
                    Similar, putem adauga metode noi si in remote service:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.liferay.vanilla.data.service.impl;\n' +
                        '\n' +
                        'import com.liferay.portal.aop.AopService;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import ro.ibedaria.liferay.vanilla.data.model.ConfigIB;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.ConfigIBLocalServiceUtil;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.base.ConfigIBServiceBaseImpl;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author Brian Wing Shun Chan\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '\tproperty = {\n' +
                        '\t\t"json.web.service.context.name=ibedaria",\n' +
                        '\t\t"json.web.service.context.path=ConfigIB"\n' +
                        '\t},\n' +
                        '\tservice = AopService.class\n' +
                        ')\n' +
                        'public class ConfigIBServiceImpl extends ConfigIBServiceBaseImpl {\n' +
                        '\n' +
                        '\tpublic ConfigIB addConfigIB(String code, String name, String value){\n' +
                        '\t\t\n' +
                        '\t\t// todo de verificat permisiuni\n' +
                        '\n' +
                            '\t\t return configIBLocalService.addConfigIB(code, name, value);\n' +
                        '\t\t//return ConfigIBLocalServiceUtil.addConfigIB(code,name, value);\n' +
                        '\t\t\n' +
                        '\t}\n' +
                        '\t\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Service Builder</b> trebuie rulat din nou, atunci cand:
                    <ul>
                        <li>fisierul <b>service.xml</b> a fost modificat</li>
                        <li>signatura unei clase din implementare s-a schimbat</li>
                    </ul>

                    Pentru un CRUD custom, Liferay recomanda urmatoarul pattern in nume si signatura metodelor:
                    <ul>
                        <li><i>entity</i><b>ServiceImpl.add</b><i>entity</i>(userId, groupId, toateCampurileEntitatii, serviceContext)</li>
                        <li><i>entity</i><b>ServiceImpl.update</b><i>entity</i>(cheiaPrimara, toateCampurileEntitatii, serviceContext)</li>
                        <li><i>entity</i><b>ServiceImpl.delete</b><i>entity</i>(cheiaPrimara)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://help.liferay.com/hc/en-us/articles/360018165991-Running-Service-Builder"}>
                                Running Service Builder
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BuildServiceContent;