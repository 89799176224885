import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class InheritinceDirectiveTypesNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-inheritince-directive-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Mostenirea si tipuri de directive</b>

                    <br/>
                    <br/>

                    Fiecare bloc creeaza un context.
                    Un bloc incuibarit mosteneste contextul parintelui.

                    <br/>
                    <br/>
                    Tipuri de directive:
                    <ul>
                        <li>standard</li>
                        <li>array: se poate specifica de mai multe ori fara a se suprascrie; daca un copil il redeclara este suprascris cu totul
                        </li>
                        <li>action</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            '######################\n' +
                            '# (1) Array Directive\n' +
                            '######################\n' +
                            '# Poate fi specificat de mai multe ori fara a supracrie setarile precedente\n' +
                            '# Este moștenit de toate contextele copil\n' +
                            '# Contextul copil poate suprascrie moștenirea prin re-declararea directivei\n' +
                            'access_log /var/log/nginx/access.log;\n' +
                            'access_log /var/log/nginx/custom.log.gz custom_format;\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  # Include declarație - non-directive\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  server {\n' +
                            '    listen 80;\n' +
                            '    server_name site1.com;\n' +
                            '\n' +
                            '    # Moștenește access_log din contextul părinte(1)\n' +
                            '  }\n' +
                            '\n' +
                            '  server {\n' +
                            '    listen 80;\n' +
                            '    server_name site2.com;\n' +
                            '\n' +
                            '    #########################\n' +
                            '    # (2) Standard Directive\n' +
                            '    #########################\n' +
                            '    # Poate fi declarată o singură dată. O a doua declarație o înlocuiește pe prima\n' +
                            '    # Este moștenit de toate contextele copil\n' +
                            '    # Contextul copil poate suprascrie moștenirea prin re-declararea directivei\n' +
                            '    root /sites/site2;\n' +
                            '\n' +
                            '    # Ignoră complet moștenirea de la (1)\n' +
                            '    access_log off;\n' +
                            '\n' +
                            '    location /images {\n' +
                            '\n' +
                            '      # Utilizează directiva rădăcină moștenită de la (2)\n' +
                            '      try_files $uri /stock.png;\n' +
                            '    }\n' +
                            '\n' +
                            '    location /secret {\n' +
                            '      #######################\n' +
                            '      # (3) Action Directive\n' +
                            '      #######################\n' +
                            '      # Invocă o acțiune, cum ar fi o rescriere sau o redirecționare\n' +
                            '      # Inheritance does not apply as the request is either stopped (redirect/response) or re-evaluated (rewrite)\n' +
                            '      return 403 "Nu aveți permisiunea de a vizualiza acest lucru.";\n' +
                            '    }\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InheritinceDirectiveTypesNginxContent;