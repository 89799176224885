import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class DeployAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-deploy", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Deploy</b>
                    <br/>
                    <br/>

                    Deploy pentru prod:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng build --prod'}
                    </SyntaxHighlighter>

                    sau (--prod nu mai e necesar)
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng build'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Variabile de mediu</b>
                    <br/>
                    <br/>

                    Pentru a creea <i>fisiere de mediu</i> (de exemplu pentru dev si prod).
                    <br/>
                    De exemplu, putem creea directorul <b>/src/environments</b>, cu fisierele:
                    <ul>
                        <li>
                            src/environments/environment.ts

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'export const environment = {\n' +
                                    '  production: false,\n' +
                                    '  title: \'Local Environment Heading\',\n' +
                                    '  apiURL: \'http://localhost:8000\'\n' +
                                    '};'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            src/environments/environment.prod.ts
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'export const environment = {\n' +
                                    '  production: true,\n' +
                                    '  title: \'Production Environment Heading\',\n' +
                                    '  apiURL: \'https://apiexample.com\'\n' +
                                    '};'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            src/environments/environment.dev.ts
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'export const environment = {\n' +
                                    '  production: false,\n' +
                                    '  title: \'Dev Environment Heading\',\n' +
                                    '  apiURL: \'http://dev.example.com\'\n' +
                                    '};'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Dupa crearea fisierelor de mediu, trebuie să configuram fișierul <b>angular.json</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'"configurations": {\n' +
                            '    "production": {\n' +
                            '      "fileReplacements": [\n' +
                            '        {\n' +
                            '          "replace": "src/environments/environment.ts",\n' +
                            '          "with": "src/environments/environment.prod.ts"\n' +
                            '        }\n' +
                            '      ],\n' +
                            '      ....\n' +
                            '    },\n' +
                            '    "dev": {\n' +
                            '      "fileReplacements": [\n' +
                            '        {\n' +
                            '          "replace": "src/environments/environment.ts",\n' +
                            '          "with": "src/environments/environment.dev.ts"\n' +
                            '        }\n' +
                            '      ],\n' +
                            '      "optimization": true,\n' +
                            '      "outputHashing": "all",\n' +
                            '      "sourceMap": false,\n' +
                            '      "extractCss": true,\n' +
                            '      "namedChunks": false,\n' +
                            '      "extractLicenses": true,\n' +
                            '      "vendorChunk": false,\n' +
                            '      "buildOptimizer": true,\n' +
                            '      "budgets": [\n' +
                            '        {\n' +
                            '          "type": "initial",\n' +
                            '          "maximumWarning": "2mb",\n' +
                            '          "maximumError": "5mb"\n' +
                            '        },\n' +
                            '        {\n' +
                            '          "type": "anyComponentStyle",\n' +
                            '          "maximumWarning": "6kb",\n' +
                            '          "maximumError": "10kb"\n' +
                            '        }\n' +
                            '      ]\n' +
                            '    }\n' +
                            '  }\n' +
                            '....\n' +
                            '....\n' +
                            '"serve": {\n' +
                            '      "builder": "@angular-devkit/build-angular:dev-server",\n' +
                            '      "options": {\n' +
                            '        "browserTarget": "appEnv:build"\n' +
                            '      },\n' +
                            '      "configurations": {\n' +
                            '        "production": {\n' +
                            '          "browserTarget": "appEnv:build:production"\n' +
                            '        },\n' +
                            '        "dev": {\n' +
                            '          "browserTarget": "appEnv:build:dev"\n' +
                            '        }\n' +
                            '      }\n' +
                            '    }\n' +
                            '....\n' +
                            '....\n' +
                            '"e2e": {\n' +
                            '      "builder": "@angular-devkit/build-angular:protractor",\n' +
                            '      "options": {\n' +
                            '        "protractorConfig": "e2e/protractor.conf.js",\n' +
                            '        "devServerTarget": "appEnv:serve"\n' +
                            '      },\n' +
                            '      "configurations": {\n' +
                            '        "production": {\n' +
                            '          "devServerTarget": "appEnv:serve:production"\n' +
                            '        },\n' +
                            '        "dev": {\n' +
                            '          "devServerTarget": "appEnv:serve:dev"\n' +
                            '        }\n' +
                            '      }\n' +
                            '    }\n' +
                            '....\n' +
                            '....'}
                    </SyntaxHighlighter>

                    <hr/>

                    Mod de a folosi variabile de mediu configurate mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component } from \'@angular/core\';\n' +
                            'import { environment } from \'./../environments/environment\';\n' +
                            '  \n' +
                            '@Component({\n' +
                            '  selector: \'app-root\',\n' +
                            '  templateUrl: \'./app.component.html\',\n' +
                            '  styleUrls: [\'./app.component.css\']\n' +
                            '})\n' +
                            'export class AppComponent {\n' +
                            '  title = environment.title;\n' +
                            '  apiURL = environment.apiURL;\n' +
                            '}'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<h1>{{ title }}</h1>\n' +
                            '<p>{{ apiURL }}</p>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Rulare cu configurarile implicite:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng serve'}
                    </SyntaxHighlighter>

                    Rulare pentru dev:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng serve --configuration=dev'}
                    </SyntaxHighlighter>

                    Rulare pentru production:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng serve --configuration=production'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.itsolutionstuff.com/post/how-to-use-environment-variable-in-angularexample.html"}>
                               How to Use Environment Variable in Angular?
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DeployAngularContent;