import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class PiperWindowsTtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-piper-windows", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pasi:
                    <ul>
                        <li>
                            Download: <a target={"_blank"} href={"https://github.com/rhasspy/piper/releases"}>piper_windows_amd64.zip</a>
                        </li>
                        <li>
                            De descarca, de exemplu in c:\tools\piper
                        </li>
                        <li>
                            Un model/voce se poate descarca de aici: <a target={"_blank"} href={"https://huggingface.co/rhasspy/piper-voices/tree/v1.0.0"}>voci</a>
                        </li>
                        <li>
                            Descarcam pe <i>mihai</i> de aici: <a target={"_blank"} href={"https://huggingface.co/rhasspy/piper-voices/tree/v1.0.0/ro/ro_RO/mihai/medium"}>vocea mihai</a>:
                            <ul>
                                <li>
                                    ro_RO-mihai-medium.onnx.json
                                </li>
                                <li>
                                    ro_RO-mihai-medium.onnx
                                </li>
                            </ul>
                            Si il punem intr-un director, de exemplu: c:\tools\piper\models\ro\mihai
                        </li>
                        <li>
                            Creeam un director <i>tests</i> unde se vor salva testele sub forma de fisiere audio de tip <i>wav</i>.
                            <br/>
                            Parametrul <b>-m</b> specifica ce model vrem sa folosim pentru generea fisierului audio:
                            <SyntaxHighlighter>
                                {'echo "Buna Akautzu" | .\\piper -m models\\ro\\mihai\\ro_RO-mihai-medium.onnx -f .\\tests\\test1.wav'}
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter>
                                {'cat tests\\poem.txt | .\\piper -m models\\ro\\mihai\\ro_RO-mihai-medium.onnx -f .\\tests\\test2.wav'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>

                    Link-uri utile Piper:
                    <ul>
                        Git:
                        <li>
                            https://github.com/rhasspy/piper
                        </li>

                        Demo online:
                        <li>
                            https://rhasspy.github.io/piper-samples/
                        </li>

                        Download zip pentru Windows/Linux:
                        <li>
                            https://github.com/rhasspy/piper/releases
                        </li>

                        Modele:
                        <li>
                            https://huggingface.co/rhasspy
                            <ul>
                                <li>
                                    RO / Mihai: https://huggingface.co/rhasspy/piper-voices/tree/main/ro/ro_RO/mihai/medium
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PiperWindowsTtsContent;