import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class BookStylesDesignLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-design-book-styles", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Book Styles</b>
                    <br/>
                    <br/>

                    Style Book în Liferay este un set de reguli vizuale care se aplică unui site pentru a oferi o experiență consistentă pe toate paginile sale. Acestea determină diverse setări vizuale pe site, inclusiv spațiul dintre elemente (cum ar fi widget-urile), culorile și fonturile.

                    <br/>
                    <br/>
                    Style Book-urile pot fi folosite pentru a standardiza aspectul și funcționalitatea paginilor web dintr-un site. Ele pot fi, de asemenea, folosite pentru a crea teme vizuale diferite pentru un site.
                    <br/>
                    <br/>
                    Style Book-urile sunt create și editate folosind editorul de Style Book. Editorul de Style Book vă permite să definiți token-uri CSS și să creați reguli vizuale pentru site-ul dvs.
                    <br/>
                    <br/>
                    Accesare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_5.png'}/>
                    </div>

                    Creare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_6.png'}/>
                    </div>

                    Exemplu:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_7.png'}/>
                    </div>

                    Aplicare stil:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_9.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_8.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://learn.liferay.com/w/dxp/site-building/developer-guide/developing-page-fragments/using-the-fragments-toolkit#setting-up-the-toolkit"}>*/}
                    {/*            Using the Fragments Toolkit*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default BookStylesDesignLiferayContent;