import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PrintFunctionPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-print-function", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Python: functia print()</b>

                    <br/>
                    <br/>
                    O functie poate avea:
                    <ul>
                        <li>un efect (de exemplu: reda o melodie, deseneaza un crocodil, afiseaza un text)</li>
                        <li>un rezultat (de exemplu: returneaza resultatul ridicarii la putere o valoare, returneaza lungimea unui text)</li>
                    </ul>

                    <b>Observatie: Conventie de scriere</b>
                    <br/>
                    Pentru a distinge cuvintele obișnuite de numele funcțiilor, vom pune o pereche de paranteze goale după numele lor, chiar dacă funcția corespunzătoare necesită unul sau mai multe argumente (de exemplu, vom scrie <b>print()</b>)

                    <br/>
                    <br/>
                    Functiile pot fi:
                    <ul>
                        <li><b>incorporate (build-in)</b> (de exemplu, functia <b>print()</b> sau <b>min()</b> sau <b>max()</b>)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(max(1,2,3))'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(min(1,2,3))'}
                            </SyntaxHighlighter>
                        </li>
                        <li>din <b>module</b> cu care vine Python sau module instalate separat</li>
                        <li><b>proprii</b> - scrise de tine (numele date funcției ar trebui să fie semnificativ, sa insemne ceva)</li>
                    </ul>

                    O functie poate avea <b>0 sau mai multe argumente</b>.
                    <br/>
                    <br/>
                    Pentru functia <b>print()</b> singurul argument este un <b>String</b> (sir de caractere) - tipul de date String <b>este delimitat cu ghilimele</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("Buna!")'}
                    </SyntaxHighlighter>
                    Numele functiei, impreuna cu parenteze si agrumentele sale reprezinta <b>invoarea functiei</b>. La invocarea unei functii, Python:
                    <ul>
                        <li>verifică dacă numele functiei este legal (adica este definita/exista undeva; daca nu exista, se arunca eroare)</li>
                        <li>verifică dacă se trimit argumentele necesare (tipul si numarul de argumente; daca nu se respecta signatura, se arunca eroare)</li>
                        <li>sare in codul functiei invocata cu argumentele date si ele transmite functiei</li>
                        <li>executa codul functiei (provoaca efectul dorit sau evalueaza rezultatele) si isi incheie sarcina</li>
                        <li>revine în locul imediat după invocare și își reia execuția</li>
                    </ul>

                    Efectul urmatorului fragement de cod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print()'}
                    </SyntaxHighlighter>
                    este ca adauga la consola o linie noua. Practic este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("\\n")'}
                    </SyntaxHighlighter>
                    Functia print() poate fi invocata si mai multe argumente:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("nimeni nu scrie","poezii","cu ochii deschisi")'}
                    </SyntaxHighlighter>
                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nimeni nu scrie poezii cu ochii deschisi'}
                    </SyntaxHighlighter>
                    Rezulta ca functia print() cu mai multe argumente:
                    <ul>
                        <li>le afiseaza pe o singura linie</li>
                        <li>adauga un <b>spatiu</b> intre argumente (ca asa vrea el)</li>
                    </ul>

                    Mecanismul <b>cheie de argument</b> (keyword arguments) permite schimbarea comportamentului unei functii. Forma pentru o <b>cheie de argument</b> (cheie de argument) este:
                    <ul>
                        <li>cuvantul cheie care identifica argumentul (ex: <i>end</i>)</li>
                        <li>=</li>
                        <li>valoare (ex: <i>" si "</i>)</li>
                    </ul>

                    <b>Cheie de argument: end</b>
                    <br/><br/>
                    De exemplu, pentru functia print() putem specifica sa nu mai puna \n (line noua) dupa afisarea la consola a argumentelor.
                    In exemplul de mai jos am inlocuit "\n" cu " si ":
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("Afara este", "noapte", end=" si ")\n' +
                        'print("pustiu.")'}
                    </SyntaxHighlighter>

                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Afara este noapte si pustiu.'}
                    </SyntaxHighlighter>

                    <b>Cheie de argument: sep</b>
                    <br/><br/>
                    Alta cheie de argument pentru functia print() este <b>sep</b> (separator)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' print("nu", "mai", "pune", "spatii", "intre noi", sep="-")'}
                    </SyntaxHighlighter>

                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nu-mai-pune-spatii-intre noi'}
                    </SyntaxHighlighter>

                    <hr/>
                    Deci, in Python, argumentul /parametrul unei functii poate fi:
                    <ul>
                        <li>pozitional (<b>positional parameter</b>)
                            <br/>
                            Argumentele poziționale sunt argumente care trebuie incluse în poziția sau ordinea corespunzătoare.
                            Primul argument pozițional trebuie întotdeauna să fie listat mai întâi atunci când funcția este apelată.
                            Al doilea argument pozițional trebuie să fie enumerat al doilea, iar al treilea argument pozițional al treilea etc.
                        </li>
                        <li>cheie (<b>keyword parameter</b>)
                            <br/>
                            De exemplu, pentru functia print(), <b>sep</b> sau <b>end</b> sunt chei de argument.
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PrintFunctionPythonContent;