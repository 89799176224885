import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class LocationBlocksNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-location-blocks", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Sintaxa blocuri Location</b>

                    <br/>
                    <br/>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'server{\n' +
                            '\tlocation URI{\n' +
                            '\t\t// gestionare raspuns\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'server{\n' +
                            '\tlocation /buna {\n' +
                            '\t\treturn 200 \'Buna de la Nginx\';\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Tipuri de potriviri URI pentru blocuri Location</b>

                    <br/>
                    <br/>

                    Tipuri de potrivire URI:
                    <ul>
                        <li>prefix / incepe cu

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'server{\n' +
                                    '\tlocation /buna {\n' +
                                    '\t\treturn 200 \'Buna de la Nginx\';\n' +
                                    '\t}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            se raspunde ok:
                            <ul>
                                <li>http://IP/<i>buna</i></li>
                                <li>http://IP/<i>buna</i>ziua</li>
                                <li>http://IP/<i>buna</i>/ziua</li>
                            </ul>


                        </li>

                        <li>exact / egal cu (=):
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'server{\n' +
                                    '\tlocation = /buna {\n' +
                                    '\t\treturn 200 \'Buna de la Nginx\';\n' +
                                    '\t}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            se raspunde ok doar la :
                            <ul>
                                <li>http://IP/<i>buna</i></li>
                            </ul>

                        </li>

                        <li>regex (expresii regulate) (~) - case sensitive
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'server{\n' +
                                    '\tlocation ~ /buna[0-3] {\n' +
                                    '\t\treturn 200 \'Buna de la Nginx\';\n' +
                                    '\t}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            se raspunde ok doar la :
                            <ul>
                                <li>http://IP/<i>buna0</i></li>
                                <li>http://IP/<i>buna1</i></li>
                                <li>http://IP/<i>buna2</i></li>
                                <li>http://IP/<i>buna3</i></li>
                            </ul>

                        </li>

                        <li>regex (expresii regulate) (~*) - case insensitive
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'server{\n' +
                                    '\tlocation ~* /buna[0-3] {\n' +
                                    '\t\treturn 200 \'Buna de la Nginx\';\n' +
                                    '\t}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            se raspunde ok doar la :
                            <ul>
                                <li>http://IP/<i>buNa0</i></li>
                                <li>http://IP/<i>Buna1</i></li>
                                <li>http://IP/<i>buna2</i></li>
                                <li>http://IP/<i>Buna3</i></li>
                            </ul>

                        </li>

                        <li>preferential prefix <b>^~</b> - adica are prioritate mai mare decat o expresie regex

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'server{\n' +
                                    '\tlocation ^~ /buna {\n' +
                                    '\t\treturn 200 \'Buna de la Nginx\';\n' +
                                    '\t}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            se raspunde ok:
                            <ul>
                                <li>http://IP/<i>buna</i></li>
                                <li>http://IP/<i>buna</i>ziua</li>
                                <li>http://IP/<i>buna</i>/ziua</li>
                            </ul>


                        </li>

                    </ul>

                    <hr/>
                    <b>3. Prioritatea</b>

                    <br/>
                    <br/>

                    Expresiile regulate au prioritate! (asa cum am vazut mai sus, se poate acorda o priortate mai mare un potriviri de tip prefix, folosind <b>^~</b>)
                    <br/>
                    Deci daca avem:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\tlocation /buna1 {\n' +
                            '\t\treturn 200 \'Buna de la Nginx\';\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tlocation /buna[0-1] {\n' +
                            '\t\treturn 200 \'Buna regex de la Nginx\';\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    Si scriem <i>http://IP/buna1</i> se va afisa: <i>Buna regex de la Nginx</i>.

                    <br/>
                    <br/>
                    Ordinea:
                    <ul>
                        <li>1. exact (= uri)</li>
                        <li>2. preferential prefix (^~ uri)</li>
                        <li>3. regex (~* uri)</li>
                        <li>4. prefix (uri)</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LocationBlocksNginxContent;