import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class NamingStrategySpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-naming-strategy", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Tabele: Naming strategy</b>
                    <br/>
                    <br/>

                    Se extinde <b>PhysicalNamingStrategyStandardImpl</b>
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'import org.hibernate.boot.model.naming.Identifier;\n' +
                            'import org.hibernate.boot.model.naming.PhysicalNamingStrategyStandardImpl;\n' +
                            'import org.hibernate.engine.jdbc.env.spi.JdbcEnvironment;\n' +
                            'import org.springframework.stereotype.Component;\n' +
                            '\n' +
                            'import java.util.HashMap;\n' +
                            '\n' +
                            '@Component\n' +
                            'public class PhysicalNamingImpl extends PhysicalNamingStrategyStandardImpl {\n' +
                            '\n' +
                            '    private HashMap<String, String> map = new HashMap<>();;\n' +
                            '\n' +
                            '    public PhysicalNamingImpl(){\n' +
                            '        map.put("service_desk_view", "z_uddt_workstation_to_user_info");\n' +
                            '    }\n' +
                            '\n' +
                            '    public Identifier toPhysicalTableName(Identifier name, JdbcEnvironment context) {\n' +
                            '        String prod = map.get(name.getText());\n' +
                            '        if (prod!=null){\n' +
                            '            return Identifier.toIdentifier(prod);\n' +
                            '        }\n' +
                            '        return name;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.physical_naming_strategy", "ro.config.PhysicalNamingImpl");'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Configuration\n' +
                            '@EnableTransactionManagement\n' +
                            '@EntityScan(basePackages = "ro.servicedesk.domain")\n' +
                            '@EnableJpaRepositories(\n' +
                            '    transactionManagerRef = "serviceDeskTransactionManager",\n' +
                            '    entityManagerFactoryRef = "serviceDeskEntityManagerFactory",\n' +
                            '    basePackages = "ro.servicedesk.repository"\n' +
                            ')\n' +
                            'public class ServiceDeskDatabaseConfig {\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @ConfigurationProperties("uddt.servicedesk.datasource")\n' +
                            '    public DataSourceProperties serviceDeskDataSourceProperties() {\n' +
                            '        return new DataSourceProperties();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @ConfigurationProperties("uddt.servicedesk.datasource.hikari")\n' +
                            '    public DataSource serviceDeskDataSource() {\n' +
                            '        return serviceDeskDataSourceProperties().initializeDataSourceBuilder().type(HikariDataSource.class).build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "serviceDeskEntityManagerFactory")\n' +
                            '    public LocalContainerEntityManagerFactoryBean serviceDeskEntityManagerFactory(EntityManagerFactoryBuilder builder) {\n' +
                            '        Map<String, String> properties = new HashMap<>();\n' +
                            '        properties.put("hibernate.connection.provider_disables_autocommit", "true");\n' +
                            '        properties.put("spring.datasource.hikari.auto-commit", "false");\n' +
                            '        properties.put("hibernate.dialect", "org.hibernate.dialect.SQLServer2012Dialect");\n' +
                            '        properties.put("hibernate.physical_naming_strategy", "ro.config.PhysicalNamingImpl");\n' +
                            '        return builder\n' +
                            '            .dataSource(serviceDeskDataSource())\n' +
                            '            .packages("ro.servicedesk.domain")\n' +
                            '            .persistenceUnit("serviceDesk")\n' +
                            '            .properties(properties)\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "serviceDeskTransactionManager")\n' +
                            '    public JpaTransactionManager serviceDeskTransactionManager(\n' +
                            '        @Qualifier("serviceDeskEntityManagerFactory") final EntityManagerFactory emf\n' +
                            '    ) {\n' +
                            '        JpaTransactionManager transactionManager = new JpaTransactionManager();\n' +
                            '        transactionManager.setEntityManagerFactory(emf);\n' +
                            '        return transactionManager;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    si
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Configuration\n' +
                            'public class DefaultDatabaseConfig {\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Primary\n' +
                            '    @ConfigurationProperties("spring.datasource")\n' +
                            '    public DataSourceProperties defaultDataSourceProperties() {\n' +
                            '        return new DataSourceProperties();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Primary\n' +
                            '    @ConfigurationProperties("spring.datasource.hikari")\n' +
                            '    public DataSource defaultDataSource() {\n' +
                            '        return defaultDataSourceProperties().initializeDataSourceBuilder().type(HikariDataSource.class).build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "entityManagerFactory")\n' +
                            '    @Primary\n' +
                            '    public LocalContainerEntityManagerFactoryBean defaultEntityManagerFactory(EntityManagerFactoryBuilder builder) {\n' +
                            '        Map<String, String> properties = new HashMap<>();\n' +
                            '        properties.put("hibernate.connection.provider_disables_autocommit", "true");\n' +
                            '        properties.put("spring.datasource.hikari.auto-commit", "false");\n' +
                            '        properties.put("spring.jpa.database-platform", "tech.jhipster.domain.util.FixedPostgreSQL10Dialect");\n' +
                            '        properties.put("hibernate.dialect", "org.hibernate.dialect.PostgreSQLDialect");\n' +
                            '\n' +
                            '        return builder\n' +
                            '            .dataSource(defaultDataSource())\n' +
                            '            .packages("ro.siveco.sivadoc.domain")\n' +
                            '            .persistenceUnit("default")\n' +
                            '            .properties(properties)\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "transactionManager")\n' +
                            '    @Primary\n' +
                            '    public JpaTransactionManager defaultTransactionManager(@Qualifier("entityManagerFactory") final EntityManagerFactory emf) {\n' +
                            '        JpaTransactionManager transactionManager = new JpaTransactionManager();\n' +
                            '        transactionManager.setEntityManagerFactory(emf);\n' +
                            '        return transactionManager;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'servicedesk:\n' +
                            '    datasource:\n' +
                            '      type: com.zaxxer.hikari.HikariDataSource\n' +
                            '      url: jdbc:postgresql://localhost:5432/app\n' +
                            '      username: uddt\n' +
                            '      password: uddt\n' +
                            '#      driver: com.microsoft.sqlserver.jdbc.SQLServerDriver\n' +
                            '#      url: jdbc:sqlserver://localhost:1433;databaseName=app-2;selectMethod=cursor;encrypt=false;\n' +
                            '#      username: portal\n' +
                            '#      password: portal\n' +
                            '      hikari:\n' +
                            '        poolName: Hikari\n' +
                            '        auto-commit: false'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Rezolvare probleme</b>
                    <br/>
                    <br/>

                    Rezolvare problema strategie de naming:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.physical_naming_strategy", "org.hibernate.boot.model.naming.CamelCaseToUnderscoresNamingStrategy");'}
                    </SyntaxHighlighter>

                    Rezolvare problema de dialect pe SQLServer:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.dialect", "org.hibernate.dialect.SQLServer2012Dialect");'}
                    </SyntaxHighlighter>

                    Rezolvare problema de dialect pe PostgreSQL:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.dialect", "org.hibernate.dialect.PostgreSQLDialect");'}
                    </SyntaxHighlighter>

                    Rezolvare probema de autocomit pe PostreSQL:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.connection.provider_disables_autocommit", "true");\n' +
                            'properties.put("spring.datasource.hikari.auto-commit", "false");'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://spring.io/guides/gs/authenticating-ldap/"}>Authenticating a User with LDAP</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NamingStrategySpringBootContent;