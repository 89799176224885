import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class CoreKubernetesContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-core", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Concepte Kubernetes</b>
                    <br/>
                    <br/>

                    Kubernetes creeaza obiecte si le gestioneaza.
                    <br/>
                    Nu creaza cluster, dar se foloseste de unul! Nu gestioneaza infrastructura (de asta se pot ocupa alte toole, de exemplu: <b>kubermatic</b>)!
                    <ul>
                        <li>
                            <b>Pod</b> {"=>"} Unitate care contine 1 sau mai multe containere (care pot fi containere Docker);
                            <br/>
                            este creeat si gestionat de K8s.
                            <br/>
                            poate avea nevoie de resurse (volume)
                        </li>
                        <li>
                            <b>Worker Node</b> {"=>"} ruleaza containerele aplicatiei;
                            <br/>
                            contine 1 sau multe <b>Pod</b>-uri
                            <br/>
                            sunt instante de masini virtuale sau fizice
                            <br/>
                            sunt gestionate de un <b>Master Node</b>
                            <br/>
                            contine:
                            <ul>
                                <li>kubelet (pentru comunicarea intre master node si worker node)</li>
                                <li>docker (daca e nevoie)</li>
                                <li>kube-proxy (pentru gestionarea traficului/ comunicarea prin retea intre nod-uri si pod-uri)</li>
                            </ul>
                            poate avea nevoie de resurse (volume)
                        </li>
                        <li>
                            <b>Master Node</b> {"=>"} gestioneaza toate nodurile de tip worker (worker nodes)
                            <br/>
                            contine:
                            <ul>
                                <li>API Server (API pentru kubelet-i pentru a comunica)</li>
                                <li>Scheduler (urmareste pod-uri, selecteaza workers nodes pentru a le rula)</li>
                                <li>Kube-Controller-Manager (urmareste si controleaza workers nodes, corecteaza numarul de pod-uri, etc)</li>
                                <li>Cloud-Controller-Manager</li>
                            </ul>
                        </li>
                        {/*<li>*/}
                        {/*    <b>Proxy / Config</b>*/}
                        {/*</li>*/}
                        <li>
                            <b>Cluster</b> {"=>"} colectia de noduri (worker/master) masini; pe care ruleaza:
                            <ul>
                                <li>
                                    aplicatia containerizata (worker nodes)
                                </li>
                                <li>
                                    controlul asupra nodurilor (master nodes)
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>Servicii</b> {"=>"} colectie de poduri cu un unic pod si container adresa IP;
                            <br/>
                            utile pentru expunerea pod-urilor in lumea de afara pe baza unui domeniu sau adresa IP
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CoreKubernetesContent;