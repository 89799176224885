import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";


class ConditionalOnPropertyAnnotationContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-conditionalonproperty-annotation", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>


                </div>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>
                                    The Spring @ConditionalOnProperty Annotation
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default ConditionalOnPropertyAnnotationContent;