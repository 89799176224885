import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class LocalRepoToRemoteRepoContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-local-repo-to-remote-repo", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>De la repository-ul local la repository-ul remote</b>
                    <br/>
                    <br/>

                    Pe server (NAS, github, etc) se creaza un repository de tip <b>bare</b>.
                    <br/>
                    Sa prespunem ca repository-urile proiectelor le tinem in: <i>/volume1/git</i>/:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd /volume1/git'}
                    </SyntaxHighlighter>

                    Initializare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git init --bare java-email-test.git'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pe masina noastra locala, sa prespunem ca avem un proiect gradle in: <i>{"D:\\work>cd java-email-test"}</i>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd D:\\work\\cd java-email-test'}
                    </SyntaxHighlighter>

                    Acest proiect NU este gestionat de git.

                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd D:\\work\\cd java-email-test'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git init \n' +
                            'git remote add origin ssh://admin@192.168.1.255:/volume1/git/java-email-test.git\n' +
                            'git add .\n' +
                            'git commit -m "primul comit"\n' +
                            'git push -u origin master'}
                    </SyntaxHighlighter>

                    Rezolvare probleme:
                    <ul>
                        <li>
                            Problema: refusing to merge unrelated histories
                            Rezolvare:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'git pull origin master --allow-unrelated-histories'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Problema: Updates were rejected because the tip of your current branch is behind
                            Rezolvare: (-f)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'git push -u origin master -f'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                <a target={"_blank"} href={"https://jackgruber.github.io/2018-08-25-synology-gitserver/"}>Git Server on Synology NAS</a>

                            </div>
                        </li>
                        <li>
                            <div>
                                 <a target={"_blank"} href={"https://kbroman.org/github_tutorial/pages/init.html"}>Start a new git repository</a>

                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://cloud.google.com/looker/docs/best-practices/how-to-migrate-to-a-new-git-repo"}>Migrating to a new Git repository</a>

                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://blog.netgloo.com/2015/04/20/git-server-on-synology-ds115j-installation-and-configurations/"}>GIT SERVER ON SYNOLOGY NAS: INSTALLATION AND CONFIGURATIONS</a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://medium.com/future-vision/setting-up-a-git-server-on-a-network-access-storage-nas-ce1505228521"}>Setting Up a Git Server On a Network Access Storage (NAS)</a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LocalRepoToRemoteRepoContent;