import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class GetStaticPropsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-getstaticprops", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Preluarea datelor: getStaticProps() </b>
                    <br/>
                    <br/>


                    Dacă se exporta o funcție numită <b>getStaticProps</b> (Generarea site-ului static) intr-o pagină,
                    Next.js va pre-randa această pagină în momentul construirii folosind elementele de recuzită returnate de getStaticProps.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export async function getStaticProps(context) {\n' +
                            '  return {\n' +
                            '    props: {}, // will be passed to the page component as props\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Orice props va fi transmis la componenta paginii și poate fi vizualizat pe partea clientului în HTML inițial.
                    <br/>
                    Acest lucru este pentru a permite paginii să fie hidratată corect.
                    <hr/>
                    <b>
                        Observatii:
                    </b>
                    <ul>
                        <li>
                            <b>getStaticProps</b> rulează întotdeauna pe server și niciodată pe client.
                        </li>
                        <li>
                            <b>getStaticProps</b> poate fi exportat doar dintr-o pagină.
                            <br/>
                            Nu îl puteți exporta din fișiere non-pagină, _app, _document sau _error.
                        </li>

                        <li>
                            Datele necesare pentru a reda pagina sunt disponibile la momentul construirii înainte de solicitarea unui utilizator
                        </li>

                        <li>
                            <b>getStaticProps</b> ruleaza si la <i>build time</i>
                        </li>

                        <li>nu este inclus in client bundle
                            <br/>
                            Se poate verifica aici, ca <b>getStaticProps</b> este eliminat din client bundle: <a href={"https://next-code-elimination.vercel.app/"} target={"_blank"}>aici</a>
                        </li>

                        <li>
                            <b>getStaticProps</b> rulează la cerere în fundal când se utilizează <b>revalidate()</b>
                        </li>
                    </ul>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {robots} from "../mocks/robots"\n\n' +
                            'export async function getStaticProps(context) {\n' +
                            '  return {\n' +
                            '    props: {robots}\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {robotsData} from "../mocks/robots"\n\n' +
                            'export async function getStaticProps(context) {\n' +
                            '  return {\n' +
                            '    props: {robots:robotsData}\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Iar in pagina se vor folosi cu: <b>props.robots</b>

                    <hr/>
                    Sa prespunem ca luam datele pe baza URL a unui API:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export async function getStaticProps(context) {\n' +
                            '  const response = await fetch(url);\n' +
                            '  const data = await response.json();\n\n' +
                            '  return {\n' +
                            '    props: {robots:data}\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Observatie:
                    <ul>
                        <li>Daca o componenta are doar continut static, acesta este vine pre-generata!</li>
                    </ul>

                    <hr/>
                    La build se apleaza <b>getStaticProps()</b> pentru a pregenera pagina, dar exista posilititatea de a fi regenerata dupa un numar de secunde.
                    <br/>
                    Next.js permite crearea sau actualizarea de pagini statice după crearea site-ului.
                    <br/>
                    Regenerarea statică incrementală (ISR) permite să utilizați generarea statică pe pagină, fără a fi nevoie de reconstruirea întregul site.
                    <br/>
                    <span>Pentru a utiliza ISR, trebuie adaugat <b>revalidate</b> la getStaticProps:</span>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export async function getStaticProps(context) {\n' +
                            '  const response = await fetch(url);\n' +
                            '  const data = await response.json();\n\n' +
                            '  return {\n' +
                            '    props: {robots:data},' +
                            '    revalidate: 10, // in secunde\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Când se face o solicitare către o pagină care a fost redată în prealabil la momentul construirii / build, va afișa inițial pagina stocată în cache.

                    <ul>
                        <li>
                            Orice solicitări către pagină după solicitarea inițială și înainte de 10 secunde sunt, de asemenea, stocate în cache și instantanee.
                        </li>
                        <li>
                            După fereastra de 10 secunde, următoarea solicitare va afișa în continuare pagina stocată în cache (învechită).
                        </li>
                        <li>
                            Next.js declanșează o regenerare a paginii în fundal.
                        </li>
                        <li>
                            Odată ce pagina se generează cu succes, Next.js va invalida memoria cache și va afișa pagina actualizată.
                            Dacă regenerarea fundalului eșuează, pagina veche va fi în continuare nemodificată.
                        </li>
                    </ul>
                    <hr/>
                    Alte props-uri utile, pe langa <b>revalidate</b>:
                    <ul>
                        <li>
                            <b>redirect</b>: redirecteaza catre o alta pagina
                        </li>
                        <li>
                            <b>notFound</b>: variabila boolean, care specifica redirectare catre pagina 404, daca notFound:true.
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GetStaticPropsReactContent;