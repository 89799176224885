import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class LsNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-ls", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Listare retele: docker network ls</b>
                    <br/>
                    <br/>

                    Conectare de la retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'NETWORK ID     NAME               DRIVER    SCOPE\n' +
                            '2be808aad381   bridge             bridge    local\n' +
                            '7c8e7915edf6   complex_default    bridge    local\n' +
                            'b486cab2c9bb   elasticsearch      bridge    local\n' +
                            '14875372bd61   frontend_default   bridge    local\n' +
                            '8472c2b25bc0   host               host      local\n' +
                            '1a096f16a145   kj-net             bridge    local\n' +
                            '4d4450cdfa4d   minikube           bridge    local\n' +
                            '7138c1675165   none               null      local\n' +
                            '5d4729895d48   visits_default     bridge    local\n'}
                    </SyntaxHighlighter>
                    In mod implicit exista 3 retele (sunt create de Docker, dupa instalare):
                    <ul>
                        <li>
                            bridge (retea default)
                        </li>
                        <li>
                            host
                        </li>
                        <li>
                            none (driver: null)
                        </li>
                    </ul>
                    In mod implcit, la pornirea unui container, acesta este adaugat/conectat in reteua <i>bridge</i>.



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LsNetworkDockerContent;