import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GTTSRi5TtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-gtts-pi5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. gTTS - Python cu PI 5</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# trebuie instalat gTTS si mpf123\n' +
                            '\n' +
                            '# 1. se instaleaza aplicatie linux:\n' +
                            '# sudo apt-get install mpg123\n' +
                            '\n' +
                            '# 2. se instaleaza librarie python\n' +
                            '# pip install gTTS --break-system-packages\n' +
                            '\n' +
                            '# 3. se verifica daca libraria a fost instalata\n' +
                            '# pip list\n' +
                            '\n' +
                            'from gtts import gTTS\n' +
                            'import os\n' +
                            'print("TTS - Text to Speech - cu Google: gTTS..")\n' +
                            '\n' +
                            '# definire variabile\n' +
                            'text = "Tranzistoare, tranzistoare ce ai facut la rezistoare?"\n' +
                            'file = "speech-gtts.mp3"\n' +
                            '\n' +
                            'tts = gTTS(text, lang=\'ro\')\n' +
                            'tts.save(file)\n' +
                            'os.system(\'mpg123 \'+ file)'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GTTSRi5TtsContent;