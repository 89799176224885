import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class GithubDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-github", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. GitHub </b>
                    <br/>
                    <br/>

                    <ul>
                        <li>ne conectam la <a target={"_blank"} href={"https://github.com/"}>https://github.com/</a> </li>
                        <li>se creaza un nou repository: <b>[+] {"=>"} New Repository</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img.png'}/>
                            </div>

                            se da un nume, de exemplu: <i>docker-react</i>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_1.png'}/>
                            </div>
                            si se lasa <b>Public</b>

                            <br/>
                            <br/>
                            se apasa pe butonul <b>Create repository</b>

                        </li>
                        <li>
                            avand noul repository creat, copiez link-ul HTTP/SSH:
                            <ul>
                                <li><i>https://github.com/letyournailsgrownow/docker-react.git</i></li>
                                <li><i>git@github.com:letyournailsgrownow/docker-react.git</i></li>
                            </ul>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_2.png'}/>
                            </div>
                        </li>
                        <li>
                            ne conectam pe masina unde este proiectul: <i>ssh kj@192.168.1.8 -p 22</i>
                        </li>
                        <li>
                            schimbam directorul catre proiectul <i>frontend</i>:
                            <SyntaxHighlighter>
                                {':~/learn/frontend$ ls\n' +
                                    'docker-compose.yml  Dockerfile  Dockerfile.dev  node_modules  package.json  package-lock.json  public  README.md  src'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            executam comenzile (pentru a pune proiectul sub urmarire si versionare git):
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'git init'}
                            </SyntaxHighlighter>
                            se poate afisa:
                            <SyntaxHighlighter>
                                {'Initialized empty Git repository in /home/numeutilizator/learn/frontend/.git/'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'git add . '}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'git commit -m "first commit" '}
                            </SyntaxHighlighter>
                            (s-ar putea sa fie nevoie sa se ruleza, inainte:
                            <ul>
                                <li>
                                    git config --global user.email "you@example.com"
                                </li>
                                <li>
                                    git config --global user.name "Your Name"
                                </li>
                            </ul>
                            )

                            <br/>
                            adaugare remote (cu numele <i>origin</i>):
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'git remote add origin git@github.com:letyournailsgrownow/docker-react.git'}
                            </SyntaxHighlighter>

                            urcare modificari pe server (in <i>origin</i>) pe branch-ul <i>master</i>
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'git push origin master'}
                            </SyntaxHighlighter>

                            se va afisa:
                            <SyntaxHighlighter>
                                {'Enumerating objects: 26, done.\n' +
                                    'Counting objects: 100% (26/26), done.\n' +
                                    'Delta compression using up to 2 threads\n' +
                                    'Compressing objects: 100% (25/25), done.\n' +
                                    'Writing objects: 100% (26/26), 302.54 KiB | 4.38 MiB/s, done.\n' +
                                    'Total 26 (delta 0), reused 0 (delta 0), pack-reused 0\n' +
                                    'To github.com:letyournailsgrownow/docker-react.git\n' +
                                    ' * [new branch]      master -> master'}
                            </SyntaxHighlighter>
                            <hr/>
                            daca obtinem eroare la <i>push</i>, exemplu: <i>git@github.com: Permission denied (publickey).</i>,
                            <ul>
                                <li>
                                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'cd ~/.ssh && ssh-keygen'}
                                    </SyntaxHighlighter>

                                    se va afisa:
                                    <SyntaxHighlighter>
                                        {'Generating public/private rsa key pair.\n' +
                                            'Enter file in which to save the key (/home/kj/.ssh/id_rsa):\n' +
                                            'Enter passphrase (empty for no passphrase):\n' +
                                            'Enter same passphrase again:\n' +
                                            'Your identification has been saved in /home/kj/.ssh/id_rsa\n' +
                                            'Your public key has been saved in /home/kj/.ssh/id_rsa.pub\n' +
                                            'The key fingerprint is:\n' +
                                            'SHA256:ScqjrzRrLtbC3D/HIys64daayfhDdDKFGpqmfwfRe5o kj@kj-Standard-PC-i440FX-PIIX-1996\n' +
                                            'The key\'s randomart image is:\n' +
                                            '+---[RSA 3072]----+\n' +
                                            '|   .             |\n' +
                                            '|. . .            |\n' +
                                            '|.+ . .  .        |\n' +
                                            '|+.+ o..o .       |\n' +
                                            '|o. + .+.S        |\n' +
                                            '|. o .....        |\n' +
                                            '| * =+. =         |\n' +
                                            '| o%+B+E +        |\n' +
                                            '|.+B@+=+= .       |\n' +
                                            '+----[SHA256]-----+'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    verific ca s-a generat cheia publica: <b>nano id_rsa.pub</b>
                                </li>
                                <li>
                                    copiez cheia publica din fisier
                                    (se poate utiliza si urmatoarea comanda pentru afisare cheii: <b>cat id_rsa.pub</b>)
                                </li>
                                <li>
                                    apoi din github: <b>Settings</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/docker/img_3.png'}/>
                                    </div>

                                    apoi <b>New SSH key</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/docker/img_4.png'}/>
                                    </div>

                                </li>


                            </ul>
                            {/*pentru a verifica ca exista cheie publica:*/}

                            {/*<ul>*/}
                            {/*    <li>*/}
                            {/*        se porneste agentul SSH:*/}
                            {/*        <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>*/}
                            {/*            {'ssh-agent -s'}*/}
                            {/*        </SyntaxHighlighter>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        se gaseste si noteaza cheia publica:*/}
                            {/*        <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>*/}
                            {/*            {'ssh-add -l -E sha256'}*/}
                            {/*        </SyntaxHighlighter>*/}

                            {/*    </li>*/}
                            {/*</ul>*/}

                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GithubDockerContent;