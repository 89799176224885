import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class GetServerSidePropsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-getserversideprops", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Preluarea datelor: getServerSideProps() </b>
                    <br/>
                    <br/>


                    Când se exporta o funcție numită <b>getServerSideProps</b> (Server-Side Rendering) dintr-o pagină,
                    Next.js va pre-renda această pagină la fiecare solicitare folosind datele returnate de getServerSideProps():

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export async function getServerSideProps(context) {\n' +
                            '  return {\n' +
                            '    props: {}, // will be passed to the page component as props\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se pot importa module în domeniul de aplicare de backend pentru a fi utilizate în getServerSideProps.
                    <br/>
                    Importurile utilizate nu vor fi grupate pentru partea client.
                    <br/>
                    Aceasta înseamnă că puteți scrie cod de pe server direct în getServerSideProps, inclusiv preluarea datelor din baza de date.

                    <br/>
                    <br/>
                    Funcția getServerSidePropsar trebui să returneze un obiect cu oricare dintre următoarele proprietăți:
                    <ul>
                        <li>
                            props
                        </li>
                        <li>
                            notFound
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export async function getServerSideProps(context) {\n' +
                                    '  const res = await fetch(`https://.../data`)\n' +
                                    '  const data = await res.json()\n' +
                                    '\n' +
                                    '  if (!data) {\n' +
                                    '    return {\n' +
                                    '      notFound: true,\n' +
                                    '    }\n' +
                                    '  }\n' +
                                    '\n' +
                                    '  return {\n' +
                                    '    props: { data }, // will be passed to the page component as props\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            redirect
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export async function getServerSideProps(context) {\n' +
                                    '  const res = await fetch(`https://.../data`)\n' +
                                    '  const data = await res.json()\n' +
                                    '\n' +
                                    '  if (!data) {\n' +
                                    '    return {\n' +
                                    '      redirect: {\n' +
                                    '        destination: \'/\',\n' +
                                    '        permanent: false,\n' +
                                    '      },\n' +
                                    '    }\n' +
                                    '  }\n' +
                                    '\n' +
                                    '  return {\n' +
                                    '    props: {}, // will be passed to the page component as props\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GetServerSidePropsReactContent;