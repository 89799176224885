import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class GradleInitGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-gradle-init", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Un schelet de aplicatie Java se poate genera folosind comanda:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle init'}
                    </SyntaxHighlighter>

                    Pentru aceasta sectiune, vom folosi <b>Gradle 7.4</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle -v\n' +
                        '\n' +
                        'Welcome to Gradle 7.4!\n' +
                        '\n' +
                        'Here are the highlights of this release:\n' +
                        ' - Aggregated test and JaCoCo reports\n' +
                        ' - Marking additional test source directories as tests in IntelliJ\n' +
                        ' - Support for Adoptium JDKs in Java toolchains\n' +
                        '\n' +
                        'For more details see https://docs.gradle.org/7.4/release-notes.html\n' +
                        '\n' +
                        '\n' +
                        '------------------------------------------------------------\n' +
                        'Gradle 7.4\n' +
                        '------------------------------------------------------------\n' +
                        '\n' +
                        'Build time:   2022-02-08 09:58:38 UTC\n' +
                        'Revision:     f0d9291c04b90b59445041eaa75b2ee744162586\n' +
                        '\n' +
                        'Kotlin:       1.5.31\n' +
                        'Groovy:       3.0.9\n' +
                        'Ant:          Apache Ant(TM) version 1.10.11 compiled on July 10 2021\n' +
                        'JVM:          11 (Oracle Corporation 11+28)\n' +
                        'OS:           Windows 10 10.0 amd64'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>1. Creare director pentru proiect</b>
                    <br/>
                    <br/>

                    Vom creea un director pentru noul proiect si vom schimba directorul curent sa fie nou director creat:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'mkdir proiect-java\n' +
                        'cd proiect-java'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Rulare task init</b>
                    <br/>
                    <br/>

                    Pentru generarea aplicatiei folosim comanda: <b>gradle init</b>.
                    Aceasta ne permite sa alegem:
                    <ul>
                        <li>tipul de proiect</li>
                        <li>limbajul de programare</li>
                        <li>daca proiectul este multi-proiect (cu mai multe module)</li>
                        <li>limbajul de script</li>
                        <li>framework-ul pentru testare</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle init\n' +
                        '\n' +
                        'Select type of project to generate:\n' +
                        '  1: basic\n' +
                        '  2: application\n' +
                        '  3: library\n' +
                        '  4: Gradle plugin\n' +
                        'Enter selection (default: basic) [1..4] 2\n' +
                        '\n' +
                        'Select implementation language:\n' +
                        '  1: C++\n' +
                        '  2: Groovy\n' +
                        '  3: Java\n' +
                        '  4: Kotlin\n' +
                        '  5: Scala\n' +
                        '  6: Swift\n' +
                        'Enter selection (default: Java) [1..6]\n' +
                        '\n' +
                        'Split functionality across multiple subprojects?:\n' +
                        '  1: no - only one application project\n' +
                        '  2: yes - application and library projects\n' +
                        'Enter selection (default: no - only one application project) [1..2] 1\n' +
                        '\n' +
                        'Select build script DSL:\n' +
                        '  1: Groovy\n' +
                        '  2: Kotlin\n' +
                        'Enter selection (default: Groovy) [1..2] 1\n' +
                        '\n' +
                        'Generate build using new APIs and behavior (some features may change in the next minor release)? (default: no) [yes, no] no\n' +
                        'Select test framework:\n' +
                        '  1: JUnit 4\n' +
                        '  2: TestNG\n' +
                        '  3: Spock\n' +
                        '  4: JUnit Jupiter\n' +
                        'Enter selection (default: JUnit Jupiter) [1..4] 1\n' +
                        '\n' +
                        'Project name (default: proiect-java): java-gradle-vanilla\n' +
                        'Source package (default: java.gradle.vanilla): demo\n' +
                        '\n' +
                        '> Task :init\n' +
                        'Get more help with your project: https://docs.gradle.org/7.4/samples/sample_building_java_applications.html\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 56s\n' +
                        '2 actionable tasks: 2 executed'}
                    </SyntaxHighlighter>

                    Se va genera urmatoarea structura :
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'├── gradle \n' +
                        '│   └── wrapper\n' +
                        '│       ├── gradle-wrapper.jar\n' +
                        '│       └── gradle-wrapper.properties\n' +
                        '├── gradlew \n' +
                        '├── gradlew.bat \n' +
                        '├── settings.gradle \n' +
                        '└── app\n' +
                        '    ├── build.gradle \n' +
                        '    └── src\n' +
                        '        ├── main\n' +
                        '        │   └── java \n' +
                        '        │       └── demo\n' +
                        '        │           └── App.java\n' +
                        '        └── test\n' +
                        '            └── java \n' +
                        '                └── demo\n' +
                        '                    └── AppTest.java'}
                    </SyntaxHighlighter>
                    
                    Fisierul generat <b>settings.gradle</b>, va avea urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'/*\n' +
                        ' * This file was generated by the Gradle \'init\' task.\n' +
                        ' *\n' +
                        ' * The settings file is used to specify which projects to include in your build.\n' +
                        ' *\n' +
                        ' * Detailed information about configuring a multi-project build in Gradle can be found\n' +
                        ' * in the user manual at https://docs.gradle.org/7.4/userguide/multi_project_builds.html\n' +
                        ' */\n' +
                        '\n' +
                        'rootProject.name = \'java-gradle-vanilla\'\n' +
                        'include(\'app\')\n'}
                    </SyntaxHighlighter>

                    Fisierul generat app/<b>build.gradle</b>, va avea urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'/*\n' +
                        ' * This file was generated by the Gradle \'init\' task.\n' +
                        ' *\n' +
                        ' * This generated file contains a sample Java application project to get you started.\n' +
                        ' * For more details take a look at the \'Building Java & JVM projects\' chapter in the Gradle\n' +
                        ' * User Manual available at https://docs.gradle.org/7.4/userguide/building_java_projects.html\n' +
                        ' */\n' +
                        '\n' +
                        'plugins {\n' +
                        '    // Apply the application plugin to add support for building a CLI application in Java.\n' +
                        '    id \'application\'\n' +
                        '}\n' +
                        '\n' +
                        'repositories {\n' +
                        '    // Use Maven Central for resolving dependencies.\n' +
                        '    mavenCentral()\n' +
                        '}\n' +
                        '\n' +
                        'dependencies {\n' +
                        '    // Use JUnit test framework.\n' +
                        '    testImplementation \'junit:junit:4.13.2\'\n' +
                        '\n' +
                        '    // This dependency is used by the application.\n' +
                        '    implementation \'com.google.guava:guava:30.1.1-jre\'\n' +
                        '}\n' +
                        '\n' +
                        'application {\n' +
                        '    // Define the main class for the application.\n' +
                        '    mainClass = \'demo.App\'\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Build</b>
                    <br/>
                    <br/>

                    Pentru a face build: <b>gradlew.bat build</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradlew.bat build\n' +
                        'Downloading https://services.gradle.org/distributions/gradle-7.4-bin.zip\n' +
                        '...........10%...........20%...........30%...........40%...........50%...........60%...........70%...........80%...........90%...........100%\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 22s\n' +
                        '7 actionable tasks: 7 executed'}
                    </SyntaxHighlighter>

                    Gradle va genera doua arhive în două formate:
                    <ul>
                        <li>app/build/distributions/app.tar</li>
                        <li>app/build/distributions/app.zip</li>
                    </ul>
                    Dar si JAR-ul aplicatiei:
                    <ul>
                        <li>app/build/libs/app.jar</li>
                    </ul>

                    <hr/>
                    <b>5. Rulare aplicatie</b>
                    <br/>
                    <br/>

                    Pentru a rula aplicatia: <b>gradlew.bat run</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradlew.bat run\n' +
                        '\n' +
                        '> Task :app:run\n' +
                        'Hello World!\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '2 actionable tasks: 1 executed, 1 up-to-date'}
                    </SyntaxHighlighter>

                    Datorită plugin-ului <b>application</b>, se poate rula aplicația direct din linia de comandă.
                    Task-ul <b>run</b> executa metoda principală din clasa atribuită proprietății <b>mainClass</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'application {\n' +
                        '    // Define the main class for the application.\n' +
                        '    mainClass = \'demo.App\'\n' +
                        '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a href={"https://docs.gradle.org/current/samples/sample_building_java_applications.html"}>Building Java Applications Sample</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GradleInitGradleContent;