import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class NoneNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-none", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>
                        1. Creare retea de tip none
                    </b>
                    <br/>
                    <br/>

                    Pentru a face o aplicatie/container complet detasata de retea aceasta trebuie conectata la pornirea la reteaua <b>none</b>:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-none --network=none busybox'}
                    </SyntaxHighlighter>

                    Pentru a verifica:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect busybox-none'}
                    </SyntaxHighlighter>
                    in sectiunea de <i>Networks</i> se va afisa (se observa ca nu are IP, IPAddress: ""):
                    <SyntaxHighlighter>
                        {'"Networks": {\n' +
                            '                "none": {\n' +
                            '                    "IPAMConfig": null,\n' +
                            '                    "Links": null,\n' +
                            '                    "Aliases": null,\n' +
                            '                    "NetworkID": "7138c16751652325a18d99ef0a3cbc719d991d0b19c8adb3a1410d20e481ecf6",\n' +
                            '                    "EndpointID": "53404cedee52324e0208fb7b845770238c93a78df28a7b4a7d7b337567e5c9be",\n' +
                            '                    "Gateway": "",\n' +
                            '                    "IPAddress": "",\n' +
                            '                    "IPPrefixLen": 0,\n' +
                            '                    "IPv6Gateway": "",\n' +
                            '                    "GlobalIPv6Address": "",\n' +
                            '                    "GlobalIPv6PrefixLen": 0,\n' +
                            '                    "MacAddress": "",\n' +
                            '                    "DriverOpts": null\n' +
                            '                }\n' +
                            '            }\n'}
                    </SyntaxHighlighter>

                    Un container pornit ca fiind conectat la reteau <i>none</i> nu se poate conecta la alta retea, de exemplu, la o retea de tip  <i>bridge</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network connect bridge busybox-none'}
                    </SyntaxHighlighter>
                    se va obtine urmatoarea eroare:
                    <SyntaxHighlighter>
                        {'Error response from daemon: container cannot be connected to multiple networks with one of the networks in private (none) mode\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un container in reteau <i>none</i> nu are acces nici la internet.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it busybox-none sh'}
                    </SyntaxHighlighter>
                    apoi:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ping google.com'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'ping: bad address \'google.com\''}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NoneNetworkDockerContent;