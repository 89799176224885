import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class IntroTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere</b>:
                    <br/>
                    <br/>
                    Componente Tomcat
                    <ul>
                        <li>Catalina:
                            <ul>
                                <li>container care implementeaza specificatiile JSP/Serlet);</li>
                                <li>gestionaza ciclul de viata al metodelor servletului (init(), service(), destroy())</li>
                            </ul>
                        </li>
                        <li>Jasper
                            <ul>
                                <li>parser JSP: converteste JSP in Servlet</li>
                                <li>are responsabilitatea de a verifica daca fisierul JSP a fost modificat, pentru a stii daca e nevoie de o noua conversie in Servlet</li>
                            </ul>
                        </li>
                        <li>Coyote:
                            <ul>
                                <li>componenta Connector</li>
                                <li>asculta porturile TCP/IP pentru a transmite cererile web de la container la end user</li>
                                <li>joaca un rol important in integrarea cu alte servere web in oferirea de continut static</li>
                            </ul>
                        </li>
                        <li>Cluster
                            <ul>
                                <li>joaca un rol important daca Tomcat ruleaza intr-un mod distribuit (load balancing)</li>
                            </ul>
                        </li>
                    </ul>

                    <b>2. Instalare</b>:
                    <br/>
                    <br/>
                    Instalarea inseamna dezarhivarea archivei downloadate de pe <a target={"_blank"} href={"https://tomcat.apache.org/download-10.cgi"}>site</a>.
                    <br/>
                    Tomcat are nevoie de JDK; Tomcat 10.1.x are nevoie de cel putin JDK 11.

                    <br/>
                    <br/>
                    <b>3. Pornire server</b>:
                    <br/>
                    <br/>
                    Din locatia unde a fost instalat [tomcat]\<b>bin\startup.bat</b>.

                    <br/>
                    Verificare: http://localhost:8080!

                    <br/>
                    <br/>
                    <b>4. Schimbare port</b>:
                    <br/>
                    <br/>
                    Din locatia unde a fost instalalt [tomcat]\<b>conf</b> se editeaza fisierul<b>server.xml</b>:

                    din:
                    <SyntaxHighlighter style={androidstudio}>
                        {'<connector port="8080" protocol="HTTP/1.1" ...'}
                    </SyntaxHighlighter>

                    in:
                    <SyntaxHighlighter style={androidstudio}>
                        {'<connector port="8580" protocol="HTTP/1.1" ...'}
                    </SyntaxHighlighter>

                    +
                    <br/>
                    din:
                    <SyntaxHighlighter style={androidstudio}>
                        {'<Server port="8005" shutdown="SHUTDOWN">'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter style={androidstudio}>
                        {'<Server port="8505" shutdown="SHUTDOWN">'}
                    </SyntaxHighlighter>

                    Verificare: http://localhost:8580

                    <br/>
                    <br/>
                    <b>5. Deploy aplicatie</b>:
                    <br/>
                    <br/>

                    O aplicatie (dezarhivata sau WAR; daca este WAR in mod implicit este dezarhivata automat, si tot in mod implicit se face automat si deploy)
                    trebuie pusa in [tomcat]\<b>webapps</b>.
                    <br/>
                    Acest comportament implicit poate fi schimbat din [tomcat]\<b>conf\server.xml</b>

                    <SyntaxHighlighter style={androidstudio}>
                        {' <Host name="localhost"  appBase="webapps"\n' +
                            '            unpackWARs="true" autoDeploy="true">'}
                    </SyntaxHighlighter>

                    <b>Observatii</b>
                    <ul>
                        <li>Tomcat este capabil sa deployeze <b>war</b>-uri (nu si <b>ear</b>-uri)</li>
                        <li>Enterprise archive(<b>.ear</b>) pot fi deploy-ate doar pe servere de aplicatii / application servers, ca JBoss, Weblogic</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroTomcatContent;