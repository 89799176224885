import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LifecyclePortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-lifecycle", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Dupa fiecare metoda de action ( <b>doProcessAction()</b> ) se apeleaza metoda de render ( <b>doView()</b> ).

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>
                                    MVC Render Command
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LifecyclePortletContent;