import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class LsbReleaseLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-lsb-release", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa informatii LSB (Linux Standard Base) despre distributia linux, se folosete comanda
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'lsb_release'}
                    </SyntaxHighlighter>

                    Pentru a afisa <b>codename</b> pentru sistemul de operare, se foloseste flag-ul <b>-c</b> sau <b>--codename</b>:
                    se va afisa:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'lsb_release -c'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Codename:       jammy'}
                    </SyntaxHighlighter>

                    Pentru a omite header-ul se folosste flag-ul <b>-s</b> sau <b>--short</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'lsb_release -cs'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'jammy'}
                    </SyntaxHighlighter>

                    Alte flag-uri utile:
                    <ul>
                        <li>
                            -d / --description (afiseaza o descriere a distributiei linux):
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'lsb_release -ds'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'Ubuntu 22.04.1 LTS'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            -r / --release (afiseaza release numberul pentru sistemul de operare):
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'lsb_release -rs'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'22.04'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LsbReleaseLinuxContent;