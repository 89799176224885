import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class HeadDumpJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-head-dump", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Head dump</b>
                    <br/>
                    <br/>

                    Pe baza unui head dump se pot obtine informatii/detalii legate de heap (memoria obiectelor partajate) a unui proces Java.
                    <br/>
                    Heap dump este ca o „imagine” a memoriei din moment in care a fost generată dump-ul.
                    <br/>
                    Conține toate datele pe care aplicația le-a avut în heap, ceea ce înseamnă că le puteți folosi pentru a examina datele și modul în care au fost structurate.
                    <br/>
                    În acest fel, puteți determina ce obiecte au ocupat o mare parte din memoria alocată și puteți înțelege de ce aplicația nu le-a putut dezaloca.

                    <hr/>

                    <b>2. Configurare aplicatie pentru a genera un head dump daca crapa cu OutOfMemory</b>
                    <br/>
                    <br/>

                    Pentru a configura o aplicatie sa genereze un head dump daca crapa cu OutOfMemory, trebuie puse urmatoare argumente JVM când pornește aplicația
                    <SyntaxHighlighter>
                        {'-XX:+HeapDumpOnOutOfMemoryError    \n' +
                            '-XX:HeapDumpPath=heapdump.bin'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'java -jar -XX:+HeapDumpOnOutOfMemoryError \n' +
                            ' -XX:HeapDumpPath=heapdump.bin app.jar'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Obținerea unui dump heap cu linia de comandă</b>
                    <br/>
                    <br/>

                    Se gaseste ID-ul procesului:
                    <SyntaxHighlighter>
                        {'jps -l'}
                    </SyntaxHighlighter>

                    Se folosese comanda <b>jmap</b>
                    <SyntaxHighlighter>
                        {'jmap -dump:format=b,file=C:/DA/heapdump.bin 25320'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li><b>format=b</b>: specifica ca rezultatul este va fi un fișier binar</li>
                        <li>25320: e PID-ul</li>
                    </ul>

                    <hr/>
                    <b>3. Citirea unui heap dump</b>
                    <br/>
                    <br/>

                    Spre deosebire de un thread dump, nu se poate analiza un head dump ca text simplu.
                    <br/>
                    Trebuie să utilizați VisualVM (sau orice instrument de profilare)

                    <hr/>
                    <b>4. Limbajul  OQL</b>
                    <br/>
                    <br/>

                    Limbajul  OQL este un limbaj similar cu SQL pentru a prelua detaliile din memoria heap.
                    <br/>
                    <br/>

                    Pentru a interoga toate instantele unui obiect de tip model.Product dintr-un dump heap folosind OQL:
                    <SyntaxHighlighter>
                        {'select p from model.Product p'}
                    </SyntaxHighlighter>

                    Daca se doreste obtinerea numele produselor în loc de instanțe de produs:
                    <SyntaxHighlighter>
                        {'select p.name from model.Product p'}
                    </SyntaxHighlighter>

                    Se pot extrage mai multe valori în același timp, folosind sintaxa JSON:
                    <SyntaxHighlighter>
                        {'select \n' +
                            ' \n' +
                            '{\n' +
                            '   name: p.name,\n' +
                            '   name_length: p.name.value.length\n' +
                            '} \n' +
                            ' \n' +
                            'from model.Product p'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter>
                        {'select { name: p.name, name_length: p.name.value.length} \n' +
                            'from model.Product p \n' +
                            'where p.name.value.length > 15'}
                    </SyntaxHighlighter>

                    Metoda <b>referrers()</b> se foloseste pentru a obține obiectele care referă instanțe de un anumit tip:
                    <SyntaxHighlighter>
                        {'select unique(referrers(p)) from model.Product p'}
                    </SyntaxHighlighter>

                    Selectarea tuturor referințelor unice pentru instanțe de un tip vă arată dacă există un obiect împiedică GC să elimine instanțele din memorie.
                    <br/>
                    Aceasta poate fi o modalitate rapidă de a identifica o scurgere de memorie.
                    <br/>
                    Atunci când exista un număr mare de instanțe, dar un număr mic de referinte, este un semn al unei scurgeri de memorie!

                    <SyntaxHighlighter>
                        {'select { product: p.name, count: count(referrers(p))} from model.Product p'}
                    </SyntaxHighlighter>

                    <hr/>
                    Observatie:
                    <ul>
                        <li>
                            <b>jstack</b> : afiseaza stiva firelor de execuție Java pentru un anumit proces Java
                        </li>
                        <li>
                            <b>jmap</b> : afiseaza detalii legate de heap (memoria obiectelor partajate) a unui proces Java
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>*/}
                    {/*            Writing Hello World in Java byte code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HeadDumpJavaContent;