import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DecoratorsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-decorators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un <b>decorator</b> este o functie sau o clasa care decoreaza/infasoara o alta functie sau o alta clasa.
                    Decoratorul:
                    <ul>
                        <li>primeste ca parametru functia orginala, ca sa o poata apela</li>
                        <li>returneaza o functie</li>
                        <li>prelua parametrii funcției originale/decorate si poate efectua acțiuni suplimentare</li>
                    </ul>

                    Decoratorii sunt folosiți pentru:
                    <ul>
                        <li>a efectua operațiuni <b>înainte</b> și <b>după</b> un apel la un obiect decorat</li>
                        <li>a <b>preveni executarea</b> unui apel la un decorat decorat, în funcție de circumstanțe</li>
                        <li>a schimba funcționarea obiectului decorat fără a-l modifica direct</li>
                    </ul>

                    Decoratorii sunt utilizați în:
                    <ul>
                        <li>validarea argumentelor</li>
                        <li>modificarea argumentelor</li>
                        <li>modificarea obiectelor returnate</li>
                        <li>măsurarea timpului de execuție</li>
                        <li>înregistrarea mesajelor</li>
                        <li>sincronizare firelor de executie</li>
                        <li>refactorizarea codului</li>
                        <li>stocarea în cache</li>
                    </ul>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(met):\n' +
                        '    print("decorator")\n' +
                        '    return met\n' +
                        '    \n' +
                        'def metoda():\n' +
                        '    print("metoda")\n' +
                        '\n' +
                        'd = decorator(metoda)\n' +
                        'd() # decorator / metoda'}
                    </SyntaxHighlighter>

                    Acum vom rescrie codul de mai sus, folosind sintaxa specifica pentru decoratori (<b>@nume_functie_decorator</b>):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(met):\n' +
                        '    print("decorator")\n' +
                        '    return met\n' +
                        '    \n' +
                        '@decorator    \n' +
                        'def metoda():\n' +
                        '    print("metoda")\n' +
                        '\n' +
                        'metoda() # decorator / metoda\n'}
                    </SyntaxHighlighter>

                    Deci:
                    <ul>
                        <li>
                            se efectuează operațiuni pe nume de obiecte
                        </li>
                        <li>
                            numele <i>metoda</i> inceteaza sa faca referire la functia <i>metoda()</i> si (pentru ca exista adnotarea de decorator) va face referire la obiectul returnat de decorator <i>decorator</i>
                        </li>
                    </ul>

                    Deci daca avem:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(f):\n' +
                        '    print("#",end=\'\')\n' +
                        '    return f\n' +
                        '\n' +
                        '@decorator\n' +
                        'def functie():\n' +
                        '    print("F",end=\'\')\n' +
                        '    \n' +
                        'functie()'}
                    </SyntaxHighlighter>
                    Este chivalent cu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'d = decorator(functie)\n' +
                        'd()'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'decorator(functie)()'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Decoratori universali</b>
                    <br/>
                    <br/>

                    Decoratorii pot fi universali, adica  pot fi aplicati pe orice funcție, indiferent de numărul și tipul de argumente transmise.
                    <br/>
                    In acest caz, se pot folosi:
                    <ul>
                        <li><b>*args și **kwargs</b></li>
                        <li>tehnica <b>closure</b> pentru a persista argumentele</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(functie):\n' +
                        '\n' +
                        '    def internal_wrapper(*args, **kwargs):\n' +
                        '        print(\'== START===\')\n' +
                        '        print(\'functia {} este apelata cu argumentele\'.format(functie.__name__))\n' +
                        '        print("\\t in decorator:",args, kwargs)\n' +
                        '        functie(*args, **kwargs)\n' +
                        '        print(\'== STOP===\')\n' +
                        '\n' +
                        '    return internal_wrapper\n' +
                        '\n' +
                        '\n' +
                        '@decorator\n' +
                        'def metoda(*args, **kwargs):\n' +
                        '    print("\\t in metoda:", args, kwargs)\n' +
                        '\n' +
                        'metoda(\'a\', \'b\', c=\'c\')\n'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'== START===\n' +
                        'functia metoda este apelata cu argumentele\n' +
                        '\t in decorator: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '\t in metoda: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '== STOP==='}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Decorator cu argumente</b>
                    <br/>
                    <br/>

                    Pentru a realiza acest lucru, mai este nevoie de un nivel, care sa preia argumentele.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(culoare):\n' +
                        '    \n' +
                        '    def wrapper(functie):\n' +
                        '    \n' +
                        '        def internal_wrapper(*args, **kwargs):\n' +
                        '            print(\'== START===\')\n' +
                        '            print(\'culoare=\',culoare)\n' +
                        '            print(\'functia {} este apelata cu argumentele\'.format(functie.__name__))\n' +
                        '            print("\\t in decorator:",args, kwargs)\n' +
                        '            functie(*args, **kwargs)\n' +
                        '            print(\'== STOP===\')\n' +
                        '    \n' +
                        '        return internal_wrapper\n' +
                        '\n' +
                        '    return wrapper\n' +
                        '\n' +
                        '@decorator("verde")\n' +
                        'def metoda_1(*args, **kwargs):\n' +
                        '    print("\\t in metoda_1:", args, kwargs)\n' +
                        '    \n' +
                        '\n' +
                        '@decorator("albastru")\n' +
                        'def metoda_2(*args, **kwargs):\n' +
                        '    print("\\t in metoda_2:", args, kwargs)\n' +
                        '    \n' +
                        '\n' +
                        'metoda_1(\'a\', \'b\', c=\'c\')\n' +
                        'metoda_2(\'a\', \'b\', c=\'c\')'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'== START===\n' +
                        'culoare= verde\n' +
                        'functia metoda_1 este apelata cu argumentele\n' +
                        '\t in decorator: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '\t in metoda_1: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '== STOP===\n' +
                        '== START===\n' +
                        'culoare= albastru\n' +
                        'functia metoda_2 este apelata cu argumentele\n' +
                        '\t in decorator: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '\t in metoda_2: (\'a\', \'b\') {\'c\': \'c\'}\n' +
                        '== STOP==='}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Stiva de decoratori</b>
                    <br/>
                    <br/>

                    Ordinea în care decoratorii sunt listați determină ordinea in care decoratorii sunt executați.

                    De exemplu, daca avem:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator_1(f):\n' +
                        '    print("1",end= \' \')\n' +
                        '    return f\n' +
                        '\n' +
                        'def decorator_2(f):\n' +
                        '    print("2",end= \' \')\n' +
                        '    return f\n' +
                        '\n' +
                        'def decorator_3(f):\n' +
                        '    print("3",end= \' \')\n' +
                        '    return f\n' +
                        '\n' +
                        '\n' +
                        '@decorator_1\n' +
                        '@decorator_2\n' +
                        '@decorator_3\n' +
                        'def functie():\n' +
                        '    print("X")\n' +
                        '    pass\n' +
                        '\n' +
                        'functie()  # 3 2 1 X'}
                    </SyntaxHighlighter>

                    Este echivalent cu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator_1(f):\n' +
                        '    print("1",end= \' \')\n' +
                        '    return f\n' +
                        '\n' +
                        'def decorator_2(f):\n' +
                        '    print("2",end= \' \')\n' +
                        '    return f\n' +
                        '\n' +
                        'def decorator_3(f):\n' +
                        '    print("3",end= \' \')\n' +
                        '    return f\n' +
                        '\n' +
                        '\n' +
                        '#@decorator_1\n' +
                        '#@decorator_2\n' +
                        '#@decorator_3\n' +
                        'def functie():\n' +
                        '    print("X")\n' +
                        '    pass\n' +
                        '\n' +
                        '#functie() # 3 2 1 X\n' +
                        '\n' +
                        'd3 = decorator_3(functie) # se afiseaza 3, se returneaza functie, SE RETURNEAZA, NU SE APELEAZA! (tine minte afisarea lui X)\n' +
                        'd2 = decorator_2(d3) # se afiseaza 2, se returneaza iar functie\n' +
                        'd1 = decorator_1(d2) # se afiseaza 1, se returneaza iar functie\n' +
                        'd1() # se apeleaza functie, si se afiseaza X'}
                    </SyntaxHighlighter>

                    Exemplul 1:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def outer_decorator(f):\n' +
                        '    def inner(*args):\n' +
                        '        f()\n' +
                        '        print("O",end=\'-\')\n' +
                        '    return inner\n' +
                        '\n' +
                        'def inner_decorator(f):\n' +
                        '    print("I",end=\'-\')\n' +
                        '    return f\n' +
                        '\n' +
                        '@outer_decorator\n' +
                        '@inner_decorator\n' +
                        'def functie():\n' +
                        '    print("A",end=\'-\')\n' +
                        '    pass\n' +
                        '\n' +
                        'functie() # I-A-O-'}
                    </SyntaxHighlighter>

                    Deci initial se apeleaza inner_decorator(functie), care  afiseaza <i>I</i> si returneaza <i>functie</i>.
                    <br/>
                    Apoi, se apeleaza outer_decorator(inner_decorator(functie)) = outer_decorator(functie), care retuneaza functia <i>inner</i>
                    <br/>
                    Functia <i>inner</i> apeleaza functie {"=>"} deci, afiseaza <i>A</i>, si apoi afiseaza <i>O</i>
                    <br/>
                    <br/>

                    Exemplul 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def outer_decorator(f):\n' +
                        '    def inner(*args):\n' +
                        '        f()\n' +
                        '        print("O",end=\'-\')\n' +
                        '    return inner\n' +
                        '\n' +
                        'def inner_decorator(f):\n' +
                        '    \n' +
                        '    def inner(*args):\n' +
                        '        f()\n' +
                        '        print("I",end=\'-\')\n' +
                        '    return inner\n' +
                        '\n' +
                        '@outer_decorator\n' +
                        '@inner_decorator\n' +
                        'def functie():\n' +
                        '    print("A",end=\'-\')\n' +
                        '    pass\n' +
                        '\n' +
                        'functie() # A-I-O-'}
                    </SyntaxHighlighter>

                    Deci initial se apeleaza inner_decorator(functie), care  returneaza functia <i>inner_decorator.inner</i>
                    <br/>
                    Apoi, se apeleaza outer_decorator(inner_decorator(functie)) = outer_decorator(inner_decorator.inner), care retuneaza functia <i>outer_decorator.inner</i>
                    <br/>
                    Functia <i>outer_decorator.inner</i>:
                    <ul>
                        <li>
                            apeleaza <i>inner_decorator.inner</i>
                            <br/>
                            care apeleaza:
                            <ul>
                                <li>
                                    <i>functie</i>
                                    <br/>
                                    care afiseaza <i>A</i>
                                </li>
                            </ul>
                            apoi afiseaza <i>I</i>
                        </li>

                    </ul>
                    apoi afiseaza <i>O</i>


                    <br/>
                    <br/>

                    Exemplul 3:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def outer_decorator(f):\n' +
                        '    def inner(*args):\n' +
                        '        print("[",end=\' \')\n' +
                        '        f()\n' +
                        '        print("]",end=\' \')\n' +
                        '    return inner\n' +
                        '\n' +
                        'def inner_decorator(f):\n' +
                        '    \n' +
                        '    def inner(*args):\n' +
                        '        print("(",end=\' \')\n' +
                        '        f()\n' +
                        '        print(")",end=\' \')\n' +
                        '    return inner\n' +
                        '\n' +
                        '@outer_decorator\n' +
                        '@inner_decorator\n' +
                        'def functie():\n' +
                        '    print("X",end=\' \')\n' +
                        '    pass\n' +
                        '\n' +
                        'functie() # [ ( X ) ] '}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DecoratorsPythonContent;