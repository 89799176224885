import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class EntityJdlJHipsterContent extends BaseContentPage {

    constructor(props) {
        super(props, "jhipster-entity-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Modelarea entitatilor JDL</b>
                    <br/>
                    <br/>

                    Entitatile se pot defini folosind urmatoarea sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'[<entity javadoc>]\n' +
                            '[<entity annotation>*]\n' +
                            'entity <entity-name> [(<table-name>)] {\n' +
                            '  [<field javadoc>]\n' +
                            '  [<field annotation>*]\n' +
                            '  <field-name> <field-type> [<validation>*]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    unde:
                    <ul>
                        <li>
                            <i>entity-name</i>: numele entitatii
                        </li>
                        <li>
                            <i>table-name</i>: numele tabelei
                        </li>
                        <li>
                            <i>field-name</i>: numele campului
                        </li>
                        <li>
                            <i>field-type</i>: tip camp
                        </li>
                        <li>
                            <i>validation</i>: validari
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'/**\n' +
                            ' * Javadoc comment\n' +
                            ' * @author Kj\n' +
                            ' */\n' +
                            '@dto(mapstruct)\n' +
                            'entity Carte {\n' +
                            '  /** Name field */\n' +
                            '  titlu String required,\n' +
                            '  anAparitie Integer,\n' +
                            '  editura String maxlength(100) pattern(/[a-Z0-9]+/)\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Enumerările pot fi, de asemenea, declarate folosind următoarea sintaxă:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'enum [<enum name>] {\n' +
                            '  <ENUM KEY> ([<enum value>])\n' +
                            '}'}
                    </SyntaxHighlighter>

                    exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'enum Language {\n' +
                            '  ENGLISH, DUTCH, FRENCH\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Este posibil să se definească constante numerice în JDL.
                    <br/>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'DEFAULT_MIN_LENGTH = 1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemple</b>
                    <br/>
                    <br/>

                    exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'/** Product sold by the Online store */\n' +
                            'entity Product {\n' +
                            '    name String required\n' +
                            '    description String\n' +
                            '    price BigDecimal required min(0)\n' +
                            '    sizeProduct Size required\n' +
                            '    image ImageBlob\n' +
                            '}\n' +
                            '\n' +
                            'enum Size {\n' +
                            '    S, M, L, XL, XXL\n' +
                            '}\n' +
                            '\n' +
                            'entity ProductCategory {\n' +
                            '    name String required\n' +
                            '    description String\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>cheia primara <i>id</i> se genereaza in mod automat si nu trebuie specificat in JDL</li>
                    </ul>

                    Se va genera tabela <i>product_category</i>, cu urmatorul DDL:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CREATE TABLE public.product_category\n' +
                            '(\n' +
                            '    id bigint NOT NULL,\n' +
                            '    name character varying(255) COLLATE pg_catalog."default" NOT NULL,\n' +
                            '    description character varying(255) COLLATE pg_catalog."default",\n' +
                            '    CONSTRAINT product_category_pkey PRIMARY KEY (id)\n' +
                            ')'}
                    </SyntaxHighlighter>
                    Se va genera tabela <i>product</i>, cu urmatorul DDL:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CREATE TABLE public.product\n' +
                            '(\n' +
                            '    id bigint NOT NULL,\n' +
                            '    name character varying(255) COLLATE pg_catalog."default" NOT NULL,\n' +
                            '    description character varying(255) COLLATE pg_catalog."default",\n' +
                            '    price numeric(21,2) NOT NULL,\n' +
                            '    size_product character varying(255) COLLATE pg_catalog."default" NOT NULL,\n' +
                            '    image bytea,\n' +
                            '    image_content_type character varying(255) COLLATE pg_catalog."default",\n' +
                            '    product_category_id bigint,\n' +
                            '    CONSTRAINT product_pkey PRIMARY KEY (id),\n' +
                            '    CONSTRAINT fk_product__product_category_id FOREIGN KEY (product_category_id)\n' +
                            '        REFERENCES public.product_category (id) MATCH SIMPLE\n' +
                            '        ON UPDATE NO ACTION\n' +
                            '        ON DELETE NO ACTION\n' +
                            ')'}
                    </SyntaxHighlighter>
                    tabela de mai sus, se va genera tinand cont si de relatia dintre Product si ProductCategory:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'relationship OneToMany {\n' +
                            '   ProductCategory{product} to Product{productCategory}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <i>image <b>ImageBlob</b></i> devine:
                    <ul>
                        <li>
                            image bytea
                        </li>
                        <li>
                            image_content_type character varying(255)
                        </li>
                    </ul>

                    <li>
                        <i>product_category_id</i> este cheie straina (FK) catre tabela <i>product</i>

                        <SyntaxHighlighter>
                            {'   CONSTRAINT fk_product__product_category_id FOREIGN KEY (product_category_id)\n' +
                                '        REFERENCES public.product_category (id) MATCH SIMPLE\n' +
                                '        ON UPDATE NO ACTION\n' +
                                '        ON DELETE NO ACTION'}
                        </SyntaxHighlighter>
                    </li>

                    <hr/>

                    exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'entity Customer {\n' +
                            '    firstName String required\n' +
                            '    lastName String required\n' +
                            '    gender Gender required\n' +
                            '    email String required pattern(/^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$/)\n' +
                            '    phone String required\n' +
                            '    addressLine1 String required\n' +
                            '    addressLine2 String\n' +
                            '    city String required\n' +
                            '    country String required\n' +
                            '}\n' +
                            '\n' +
                            'enum Gender {\n' +
                            '    MALE, FEMALE, OTHER\n' +
                            '}'}
                    </SyntaxHighlighter>

                    exemplu 3:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'entity ProductOrder {\n' +
                            '    placedDate Instant required\n' +
                            '    status OrderStatus required\n' +
                            '    code String required\n' +
                            '}\n' +
                            '\n' +
                            'enum OrderStatus {\n' +
                            '    COMPLETED, PENDING, CANCELLED\n' +
                            '}\n' +
                            '\n' +
                            'entity OrderItem {\n' +
                            '    quantity Integer required min(0)\n' +
                            '    totalPrice BigDecimal required min(0)\n' +
                            '    status OrderItemStatus required\n' +
                            '}\n' +
                            '\n' +
                            'enum OrderItemStatus {\n' +
                            '    AVAILABLE, OUT_OF_STOCK, BACK_ORDER\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>placedDate Instant {"=>"} placedDate Instant de tip <i>timestamp without time zone</i></li>
                    </ul>

                    exemplu 4:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'entity Invoice {\n' +
                            '    date Instant required\n' +
                            '    details String\n' +
                            '    status InvoiceStatus required\n' +
                            '    paymentMethod PaymentMethod required\n' +
                            '    paymentDate Instant required\n' +
                            '    paymentAmount BigDecimal required\n' +
                            '}\n' +
                            '\n' +
                            'enum InvoiceStatus {\n' +
                            '    PAID, ISSUED, CANCELLED\n' +
                            '}\n' +
                            '\n' +
                            'enum PaymentMethod {\n' +
                            '    CREDIT_CARD, CASH_ON_DELIVERY, PAYPAL\n' +
                            '}\n' +
                            '\n' +
                            'entity Shipment {\n' +
                            '    trackingCode String\n' +
                            '    date Instant required\n' +
                            '    details String\n' +
                            '}' +
                            '' }
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EntityJdlJHipsterContent;