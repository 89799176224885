import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class PostgresqlK8sContent extends BaseContentPage {

    constructor(props) {
        super(props, "kubernetes-postgresql", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}


                <div className={"text-justify important"}>

                    <b>PostgreSQL</b>
                    <br/>
                    <br/>

                    <b>0. Mediu</b>
                    <br/>
                    <br/>

                    Ne conectam pe masina:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'ssh kj@192.168.1.10 -p 22'}
                    </SyntaxHighlighter>

                    Se creaza directorul <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'mkdir liferay'}
                    </SyntaxHighlighter>

                    Navigam in directorul nou creat <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'cd liferay'}
                    </SyntaxHighlighter>

                    Verificare minikube:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Stopped\n' +
                            'kubelet: Stopped\n' +
                            'apiserver: Stopped\n' +
                            'kubeconfig: Stopped\n'}
                    </SyntaxHighlighter>
                    Daca este oprit, se poate porni:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>

                    Verificare din nou status:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Running\n' +
                            'kubelet: Running\n' +
                            'apiserver: Running\n' +
                            'kubeconfig: Configured\n'}
                    </SyntaxHighlighter>

                    Afisare detalii:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube profile list'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| Profile  | VM Driver | Runtime |      IP      | Port | Version | Status  | Nodes | Active |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| minikube | docker    | docker  | 192.168.49.2 | 8443 | v1.25.2 | Running |     1 | *      |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Crearea namespace</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'kubectl create namespace liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'namespace/liferay created'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Creare Secret (parola)</b>
                    <br/>
                    <br/>

                    <b>Observatie</b>:
                    <ul>
                        <li>
                            <b>Secrets</b> este pentru date confidențiale (secretele sunt obscucate cu o codificare Base64)
                        </li>
                        <li>
                            <b>ConfigMaps</b> este pentru date neconfidențiale
                        </li>
                    </ul>
                    Encodere base64 cuvantul <i>lportal</i>, care va reprezenta parola de conectare la baza de date:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'echo \'lportal\' | base64'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'bHBvcnRhbAo='}
                    </SyntaxHighlighter>

                    Se creaza fisierul <i>postgres-secrets.yml</i> cu urmatorul continut:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'nano database-secrets.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  name: database-secret-config\n' +
                            'type: Opaque\n' +
                            'data:\n' +
                            '  password: bHBvcnRhbAo='}
                    </SyntaxHighlighter>

                    Aplicare:
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl apply -f database-secrets.yml -n=liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'secret/database-secret-config created'}
                    </SyntaxHighlighter>

                    Pentru a sterge un secret:
                    <SyntaxHighlighter>
                        {' kubectl delete secret database-secret-config'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl get secret database-secret-config -n=liferay -o yaml'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'data:\n' +
                            '  password: bHBvcnRhbAo=\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  annotations:\n' +
                            '    kubectl.kubernetes.io/last-applied-configuration: |\n' +
                            '      {"apiVersion":"v1","data":{"password":"bHBvcnRhbAo="},"kind":"Secret","metadata":{"annotations":{},"name":"database-secret-config","namespace":"liferay"},"type":"Opaque"}\n' +
                            '  creationTimestamp: "2023-08-18T21:31:04Z"\n' +
                            '  name: database-secret-config\n' +
                            '  namespace: liferay\n' +
                            '  resourceVersion: "6268083"\n' +
                            '  uid: e423c915-b8ba-4d54-ab0b-0b65f08fddcd\n' +
                            'type: Opaque'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Creare PersistentVolume și PersistentVolumeClaim</b>
                    <br/>
                    <br/>

                    Creare PV (PersistentVolume):
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'nano database-pv.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolume # Create a PV\n' +
                            'metadata:\n' +
                            '  name: database-data # Sets PV\'s name\n' +
                            '  labels:\n' +
                            '    type: local # Sets PV\'s type to local\n' +
                            'spec:\n' +
                            '  storageClassName: manual\n' +
                            '  capacity:\n' +
                            '    storage: 2Gi # Sets PV Volume\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  hostPath:\n' +
                            '    path: "/mnt/data" # Sets the volume\'s path'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl apply -f database-pv.yml -n=liferay'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'persistentvolume/database-data created'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl get pv'}
                    </SyntaxHighlighter>

                    <hr/>

                    Creare PVC (PersistentVolumeClaim):
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'nano database-pvc.yml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim # Create PVC\n' +
                            'metadata:\n' +
                            '  name: database-data-claim # Sets name of PV\n' +
                            'spec:\n' +
                            '  storageClassName: manual\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce # Sets read and write access\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 2Gi # Sets volume size'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl apply -f database-pvc.yml -n=liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'persistentvolumeclaim/database-data-claim created'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl get pvc'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Creare Deployment</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'nano postgres-deployment.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment # Create a deployment\n' +
                            'metadata:\n' +
                            '  name: postgres # Set the name of the deployment\n' +
                            'spec:\n' +
                            '  replicas: 1 # Set 1 deployment replicas\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: postgres\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: postgres\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: postgres\n' +
                            '          image: postgres:15.4 # Docker image\n' +
                            '          imagePullPolicy: "IfNotPresent"\n' +
                            '          ports:\n' +
                            '            - containerPort: 5432 # Exposing the container port 5432 for PostgreSQL client connections.\n' +
                            '          env:\n' +
                            '            - name: POSTGRES_USER\n' +
                            '              value: lportal\n' +
                            '            - name: POSTGRES_DB\n' +
                            '              value: lportal\n' +
                            '            - name: POSTGRES_PASSWORD\n' +
                            '              valueFrom:\n' +
                            '                secretKeyRef:\n' +
                            '                  name: database-secret-config\n' +
                            '                  key: password\n' +
                            '            - name: PGDATA\n' +
                            '              value: /var/lib/postgresql/data/pgdata\n' +
                            '          volumeMounts:\n' +
                            '            - mountPath: /var/lib/postgresql/data\n' +
                            '              name: postgresdata\n' +
                            '      volumes:\n' +
                            '        - name: postgresdata\n' +
                            '          persistentVolumeClaim:\n' +
                            '            claimName: database-data-claim'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  language="k8s" style={androidstudio}>
                        {'kubectl apply -f postgres-deployment.yml -n=liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'deployment.apps/postgres created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  language="k8s" style={androidstudio}>
                        {'kubectl get deployments -n=liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'postgres   1/1     1            1           68s\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>5. Creare serviciu</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'nano postgres-service.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service # Create service\n' +
                            'metadata:\n' +
                            '  name: postgres # Sets the service name\n' +
                            '  labels:\n' +
                            '    app: postgres # Defines app to create service for\n' +
                            'spec:\n' +
                            '  type: NodePort # Sets the service type\n' +
                            '  ports:\n' +
                            '    - port: 5432 # Sets the port to run the postgres application\n' +
                            '  selector:\n' +
                            '    app: postgres'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl apply -f postgres-service.yml -n=liferay'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'service/postgres created'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl get service postgres -n=liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       TYPE       CLUSTER-IP      EXTERNAL-IP   PORT(S)          AGE\n' +
                            'postgres   NodePort   10.96.169.244   <none>        5432:30152/TCP   67s\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Testare conectare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl get pods -n=liferay'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                        READY   STATUS    RESTARTS   AGE\n' +
                            'postgres-6d745c4876-hjbhq   1/1     Running   0          8m47s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'kubectl exec -it postgres-6d745c4876-hjbhq -n=liferay -- psql -U lportal'}
                    </SyntaxHighlighter>

                    va afisa:
                    <SyntaxHighlighter>
                        {'postgres=#'}
                    </SyntaxHighlighter>

                    Daca se executa comanda de afisare baze de date <b>\l</b>, poate afisa:
                    <SyntaxHighlighter>
                        {'                                              List of databases\n' +
                            '   Name    |  Owner  | Encoding |  Collate   |   Ctype    | ICU Locale | Locale Provider |  Access privileges\n' +
                            '-----------+---------+----------+------------+------------+------------+-----------------+---------------------\n' +
                            ' lportal   | lportal | UTF8     | en_US.utf8 | en_US.utf8 |            | libc            |\n' +
                            ' postgres  | lportal | UTF8     | en_US.utf8 | en_US.utf8 |            | libc            |\n' +
                            ' template0 | lportal | UTF8     | en_US.utf8 | en_US.utf8 |            | libc            | =c/lportal         +\n' +
                            '           |         |          |            |            |            |                 | lportal=CTc/lportal\n' +
                            ' template1 | lportal | UTF8     | en_US.utf8 | en_US.utf8 |            | libc            | =c/lportal         +\n' +
                            '           |         |          |            |            |            |                 | lportal=CTc/lportal\n' +
                            '(4 rows)\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <hr/>

                    Exemplu pentru MySQL, intr-un singur fisier (<i>database-deployment.yaml</i>):
                    <SyntaxHighlighter language="k8s" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: database\n' +
                            'spec:\n' +
                            '  ports:\n' +
                            '  - port: 3306\n' +
                            '  selector:\n' +
                            '    app: database\n' +
                            '  \n' +
                            '  \n' +
                            '---\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: database-data\n' +
                            '  name: database-data\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '  - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 100Mi\n' +
                            '      \n' +
                            '      \n' +
                            '---\n' +
                            '\n' +
                            '\n' +
                            'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: database\n' +
                            '  name: database\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: database\n' +
                            '  strategy:\n' +
                            '    type: Recreate\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: database\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - args:\n' +
                            '        - mysqld\n' +
                            '        - --character-set-server=utf8\n' +
                            '        - --collation-server=utf8_general_ci\n' +
                            '        - --character-set-filesystem=utf8\n' +
                            '        env:\n' +
                            '        - name: MYSQL_DATABASE\n' +
                            '          value: lportal\n' +
                            '        - name: MYSQL_PASSWORD\n' +
                            '          value: roto2\n' +
                            '        - name: MYSQL_ROOT_PASSWORD\n' +
                            '          value: root\n' +
                            '        - name: MYSQL_USER\n' +
                            '          value: roto2\n' +
                            '        image: mysql:5.7\n' +
                            '        resources:\n' +
                            '          limits:\n' +
                            '            memory: "512Mi"\n' +
                            '            cpu: "0.5"\n' +
                            '        ports:\n' +
                            '        - containerPort: 3306\n' +
                            '        name: database\n' +
                            '        volumeMounts:\n' +
                            '        - mountPath: /var/lib/mysql\n' +
                            '          name: database-data\n' +
                            '      restartPolicy: Always\n' +
                            '      volumes:\n' +
                            '      - name: database-data\n' +
                            '        persistentVolumeClaim:\n' +
                            '          claimName: database-data'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PostgresqlK8sContent;