import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OsModulePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-os-module", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Modulul os</b>
                    <br/> <br/>

                    Metode din modulul <b>os</b>:
                    <ul>
                        <li><b>uname()</b>: stocheaza informatii despre OSprin intermediul urmatoarelor proprietati:
                            <ul>
                                <li><b>systemname / sysname</b> — stochează numele sistemului de operare, de exemplu: Windows</li>
                                <li><b>nodename</b> — stochează numele mașinii în rețea, de exemplu: desktop-lenovo-y540</li>
                                <li><b>release</b> — stochează release-ul sistemului de operare, de exemplu: 10</li>
                                <li><b>version</b> — stochează versiunea sistemului de operare, de exemplu: 10.0.19041</li>
                                <li><b>machine</b> — stochează identificatorul hardware, de exemplu: AMD64</li>
                            </ul>

                            <b>Observatie:</b>
                            <br/>

                            Funcția uname() funcționează numai pe unele sisteme Unix. <br/>
                            Dacă utilizați Windows, puteți utiliza funcția <b>uname()</b> din modulul <b>platform</b>, care returnează un rezultat similar:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import platform as p\n' +
                                'print(p.uname()) # uname_result(system=\'Windows\', node=\'desktop-lenovo-y540\', release=\'10\', version=\'10.0.19041\', machine=\'AMD64\')'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>name</b>: folosit pentru a distinge sistemul de operare si poate lua urmatoarele valori:

                            <ul>
                                <li><b>posix</b>, pentru cod rulat pe Unix</li>
                                <li><b>nt</b>, pentru cod rulat pe Windows</li>
                                <li><b>java</b>, pentru cod scris in Jython</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import os as o\n' +
                                'print(o.name) # nt'}
                            </SyntaxHighlighter>

                            <b>Observatie:</b>
                            <br/>
                            Pe sistemele Unix, există o comandă numită <b>uname</b> care returnează aceleași informații (dacă o rulați cu opțiunea -a) ca și funcția uname().

                            <br/>
                            <br/>
                        </li>
                        <li><b>mkdir(nume_director)</b>: creaza un director cu numele <i>nume_director</i>
                            <br/>
                            <i>nume_director</i> poate fi o cale:
                            <ul>
                                <li>relativa: daca nu incepe cu / <br/>
                                    de exemplu:
                                    <ul>
                                        <li>nume_director (directorul se creeaza in directorul curent)</li>
                                        <li>./nume_director (similar cu nume_director)</li>
                                        <li>../nume_director (directorul se creeaza in directorul parinte)</li>
                                    </ul>
                                </li>
                                <li>absoluta: daca incepe cu / <br/>de exemplu: /nume_director</li>
                            </ul>
                            <b>Observatii</b>:
                            <ul>
                                <li>daca se incerca creearea unui director care deja exista in calea specificata, se va arunca o exceptie: <b>FileExistsError</b></li>
                                <li>poate lua opțional și argumentul <i>mode</i>, care specifică permisiunile de director (pe unele sisteme, argumentul mode este ignorat)
                                    <br/>
                                    pentru a schimba permisiunile unui director, se poate folosi funcția <b>chmod</b>, care funcționează similar cu comanda chmod pe sistemele Unix.
                                </li>
                                <li>pe Windows si Unix, există comandă numita <b>mkdir</b>, care necesită o cale de director</li>
                            </ul>
                            <br/>

                        </li>

                        <li>
                            <b>makedirs(<i>cale</i>)</b>: permite crearea de directoare recursive, ceea ce înseamnă că toate directoarele din <i>cale</i> vor fi create
                            <br/>
                            Echivalente:
                            <ul>
                                <li>Unix: mkdir -p cale</li>
                                <li>Windows: mkdir cale</li>
                            </ul>
                            <br/>
                        </li>

                        <li>
                            <b>chdir(<i>cale</i>)</b>: schimbă directorul de lucru curent în calea specificată
                            <br/>
                            <br/>
                        </li>

                        <li>
                            <b>rmdir(cale)</b>: sterge directorul primit ca argument (daca directorul nu exista sau nu este gol se va arunca o exceptie)
                            <br/>
                            <br/>
                        </li>

                        <li>
                            <b>removedirs (cale)</b>: sterge directorul primit ca argument si subdirectoarele lui (daca unul din directoare nu exista sau nu este gol se va arunca o exceptie)
                            <br/>
                            Echivalente:
                            <ul>
                                <li>Unix: rmdir cale</li>
                                <li>Windows: rm -r cale</li>
                            </ul>
                            <br/>
                        </li>

                        <li>
                            <b>getcwd()</b>: afiseaza directorul de lucru curent (<b>calea absolută</b>)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import os\n' +
                                'print(os.getcwd()) # D:\\work\\python-vanilla\\src\\main\\python'}
                            </SyntaxHighlighter>

                            Echivalente:
                            <ul>
                                <li>Unix: pwd</li>
                                <li>Windows: cd (fara parametri)</li>
                            </ul>

                            <br/>
                        </li>

                        <li>
                            <b>listdir()</b>: returneaza o lista cu numele directoarelor si fisierelor din directorul curent

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'os.mkdir("director")\n' +
                                'print(os.listdir()) # ex: [\'comment.py\', \'director\', \'fisier.txt\']'}
                            </SyntaxHighlighter>

                            rezultatul funcției <b>listdir()</b> va omite intrările '.' și „..”, care sunt afișate, de exemplu, când se utilizează comanda <b>ls -a</b> pe sistemele Unix

                            <br/>
                            <br/>
                        </li>

                        <li>
                            <b>system(comanda): executa comenzi specifice sistemului de operare si returneaza diferite rezultate (in functie de sistemul de operare)</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import os\n' +
                                'resultat = os.system("cd") # D:\\work\\python-vanilla\\src\\main\\python\n' +
                                'print(resultat) #  0'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>

                    <b>Exercitiu:</b>
                    <br/>
                    Sa da o <i>cale</i> si un <i>nume_de_director</i>.<br/>
                    Sa se afiseze toate caile (cautand in directorul dat ca si <i>cale</i> si subdirectoarele lui) care au un director cu numele <i>nume_de_director</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import os\n' +
                        '\n' +
                        '\n' +
                        'def find(cale, nume_de_director):\n' +
                        '\n' +
                        '    os.chdir(cale) # se seteaza directorul curent ca fiind "cale"\n' +
                        '    cd = os.getcwd() # se obtine calea absoluta pentru directorul curent\n' +
                        '    paths = os.listdir(".") # se obtin toate subdirectoarele directorului curent\n' +
                        '\n' +
                        '    for p in paths:\n' +
                        '        if dir == p:\n' +
                        '            print(cd + "/" + nume_de_director)\n' +
                        '\n' +
                        '        find(cd + "/" + p, nume_de_director)\n' +
                        '\n' +
                        '\n' +
                        'print(find("./director", "python"))\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OsModulePythonContent;