import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StringPoolJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-string-pool", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    JVM colecteaza string-uile intr-o zona numita <b>string pool</b> (sau <b>intern pool</b>).
                    Zona string pool contine, pentru un program Java:
                    <ul>
                        <li>valorile literale (exemplu: "name" este un literal si va ajunge in zona string pool)</li>
                        <li>constantele</li>
                    </ul>

                    Cand se initializeaza un String cu o valoare literala, JVM verifica colectia din zona de momeorie String pool pentru a vedea daca valoarea literala exista deja acolo.
                    <ul>
                        <li>daca nu exista, atunci o creeaza si returneaza referinta la aceasta valoare</li>
                        <li>daca exista, atunci se returneaza referinta la valoarea gasita</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String a = "abc";\n' +
                        'String b = "abc";\n' +
                        '\n' +
                        'System.out.println(a==b); //true, a si b pointeza catre aceeazi zona de memorie (string pool)'}
                    </SyntaxHighlighter>

                    Pentru a plasa valoarea literal in <b>zona Heap</b>, si nu in zona String Poll, atunci trebuie folosit operatorul <b>new</b>:
                    <ul>
                        <li>new String(<i>valoare_literala</i>)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String a = "abc";\n' +
                        'String b = new String("abc");\n' +
                        '\n' +
                        'System.out.println(a==b); // false, a este in zona String Pool, iar b in zona Heap'}
                    </SyntaxHighlighter>

                    Pentru a plasa o valoare literal care in mod normal s-ar pune zona <b>Heap</b> (prin folosirea operatorului <b>new</b>) in zona <b>String Pool</b> se foloseste metoda:
                    <ul>
                        <li>intern()</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String a = "abc";\n' +
                        'String b = new String("abc").intern();\n' +
                        '\n' +
                        'System.out.println(a==b); // true, a este in zona String Pool, iar b, datorita metodei intern() se pune si ea tot in zona String Pool'}
                    </SyntaxHighlighter>

                    Alt lucru de care trebuie tinut seama este <b>valoarea în momentul compilării</b>.
                    <br/>
                    <br/>
                    <i>Exemplu 1</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String a = "abc";\n' +
                        'String b = "a"+"bc"; // in momentul compilarii se face concatenarea ("a" este o constanta; "bc" este o constanta)\n' +
                        '\n' +
                        'System.out.println(a==b); // true'}
                    </SyntaxHighlighter>

                    <i>Exemplu 2</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String a = "abc";\n' +
                        'String b = "a";\n' +
                        'b = b + "bc"; // in momentul compilarii NU se face concatenarea; (b nu este o constanta)\n' +
                        '\n' +
                        'System.out.println(a==b); // false'}
                    </SyntaxHighlighter>

                    <i>Exemplu 3</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'final String a = "abc";\n' +
                        'String b = "a";\n' +
                        'String c = b + "bc"; // in momentul compilarii NU se face concatenarea; (b nu este constanta)\n' +
                        '\n' +
                        'System.out.println(a==c); // false'}
                    </SyntaxHighlighter>

                    <i>Exemplu 4</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'final String a = "abc";\n' +
                        'final String b = "a";\n' +
                        'String c = b + "bc"; // in momentul compilarii se face concatenarea pentru ca b este constanta (prin marcarea cu "final")\n' +
                        '\n' +
                        'System.out.println(a==c); // true'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringPoolJavaContent;