import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Tips6DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tips6", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Tips - Securitate</b>

                    1. UCP Client Bundle
                    <br/>
                    <ul>
                        <li>client bundle sunt un grup de certificate si fisiere care pot downloada de pe UCP</li>
                        <li>depinzand de permisiunile asociate cu un user, se poat executa comenzi <i>docker swarm</i> de pe masina remote care vor avea efect pe cluster-ul remote</li>
                    </ul>

                    <hr/>
                    2. Docker Content Trust (DCT)
                    <br/>
                    Folosit pentru a interactiona doar un imagini de incredere (imagini semnate).
                    <br/>
                    Activare:
                    <SyntaxHighlighter>
                        {'export DOCKER_CONTENT_TRUST=1'}
                    </SyntaxHighlighter>
                    Engine Signature Verification previne:
                    <ul>
                        <li>docker container run {"->"} a unei imagini nesemnate sau alterate</li>
                        <li>docker pull {"->"} a unii imagini nesemante sau alterate</li>
                        <li>docker build {"->"} unde imaginea definita in FROM nu este semnata sau nu este <b>scratch</b></li>
                    </ul>

                    Puteți folosi imaginea minimă rezervată a lui Docker, <b>scratch</b>, ca punct de plecare pentru construirea de containere.
                    <br/>
                    Utilizarea imaginii scratch semnalează procesului de construire că doriți ca următoarea comandă din Dockerfile să fie primul strat al sistemului de fișiere din imaginea dvs.
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM scratch\n' +
                            'ADD hello /\n' +
                            'CMD ["/hello"]'}
                    </SyntaxHighlighter>

                    <hr/>
                    3. Docker Secrets
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker secret create'}
                    </SyntaxHighlighter>
                    Aceasta comanda este o comanda gestionarea clusterului, deci <i>trebuie executata pe un nod manager swarm</i>.
                    <br/>
                    Nu se poate actualiza sau redenumi un secret.
                    <br/>
                    Optiuni pentru <b>docker service update</b>:
                    <ul>
                        <li>--secret-add</li>
                        <li>--secret-rm</li>
                    </ul>
                    Cand se acorda acces la un secret unui serviciu nou creat sau care ruleaza, secretul decriptat este montat in container in urmatoarea cale:
                    <SyntaxHighlighter>
                        {'/run/secrets/[nume-secret]'}
                    </SyntaxHighlighter>
                    Se poate specifica si o cale personalizata pentru cale:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name redis --secret source=mysecret, target=/root/secret/data'}
                    </SyntaxHighlighter>

                    <hr/>
                    4. Swarm Auto-Lock
                    <br/>
                    Daca Swarm este compromis, atunci un atacator poate obtine informatii sensibile.
                    <br/>
                    Pentru a avea control asupra acestui aspect:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --autolock=true'}
                    </SyntaxHighlighter>

                    <hr/>
                    5. Mutual TLS Authentication
                    <br/>
                    Cand se creaza un swarm ruland <b>docker swarm init</b>, docker se desemneaza ca nod manger;
                    <br/>
                    In mod implicit, nodul manager genereaza un nou CA (Certificate Authority) root, impreuna cu o pereche de chei, care sunt folosite pentru a securiza comunicatiile.
                    <br/>
                    De fiecare data, cand un nod se alatura la swarm, mangerul elibereaza un certificat pentru nod.
                    <br/>
                    In mod implicit, fiecare nod din swarm, isi reinoieste certificatul la fiecare 3 luni.

                    <hr/>
                    6. Container privilegiat
                    <br/>
                    In mod implicit, un container nu este privilegiat.
                    <br/>
                    Cand un se executa <b>docker run --privileged</b> {"=>"} Docker va permite accesul la toate device-urile/dispozitivele de pe masina host,
                    pentru a permite containerului aproape acelasi acces la gazda ca si procesele care ruleaza in afara containerelor de pe gazda/host.

                    <hr/>
                    7. Roluri in UCP
                    <br/>
                    <ul>
                        <li>
                            None {"=>"} utilizatorii nu au acces la resursele K8s sau Swarm (<i>No Access</i> in UCP 2.1.x)
                        </li>
                        <li>
                            View Only {"=>"} utilizatorii pot vizualiza resursele dar nu poate creea
                        </li>
                        <li>
                            Restricted Control {"=>"} utilizatorii pot vizualiza si edita resurse, dar nu pot rula un serviciu sau un container intr-un mod care sa afecteze nodul in care ruleaza.
                            <br/>
                            Utilizatorii nu pot:
                            <ul>
                                monta un director nod
                            </ul>
                            <li>
                                nu pot executa: <b>docker exec</b>
                            </li>
                            <li>
                                nu pot rula containere in mod privilegiat
                            </li>
                            <li>
                                nu pot rula containere cu capabilitati suplimentare ale kernerului
                            </li>
                        </li>
                        <li>
                            Scheduler {"=>"}
                            <ul>
                                <li>
                                    utilizatorii pot vedea nodurile (worker si mananger);
                                </li>
                                <li>
                                    pot programa (nu vizualiza) workload-ari pe aceste noduri
                                </li>
                                <li>
                                    in mod implicit, toti utilizatorii au rolul de planificator (against) pe colectia /Shared
                                </li>
                            </ul>

                        </li>
                        <li>
                            Full Controll {"=>"}
                            <ul>
                                <li>utilizatorii pot vizualiza si edita toate resursele acordate</li>
                                <li>pot cree containere fara nici o restrictie</li>
                                <li>nu pot vedea containerele altora</li>

                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips6DockerContent;