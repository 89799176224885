import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class ValvesTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-jndi", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Interceptarea request-urilor folosind Valves</b>
                    <br/>
                    <br/>

                    Moduri de folosire valve:
                    <ul>
                        <li>filtrarea request-urilor</li>
                        <li>logging</li>
                        <li>blocare IP pentru securitate</li>
                    </ul>

                    Valvele pot folosite la nivel de:
                    <ul>
                        <li>Engine</li>
                        <li>Host</li>
                        <li>Context (contextul unei aplicatii)</li>
                    </ul>

                    Tipuri de valve:
                    <ul>
                        <li>AccessLogValve (accesibil la nivel de aplicatie):

                            <br/>
                            Exemplu, din <b>server.xml</b>, sectiunea <b>Server/Service/Engine/Host</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {' <Valve className="org.apache.catalina.valves.AccessLogValve" directory="logs"\n' +
                                    '               prefix="localhost_access_log" suffix=".txt"\n' +
                                    '               pattern="%h %l %u %t &quot;%r&quot; %s %b" />'}
                            </SyntaxHighlighter>

                        </li>
                        <li>SingleSignOn
                            <br/>
                            Daca avem 2 sau mai multe aplicatii deploy-ate si vre vem sa avem autentificare comuna,
                        </li>
                        <li>RemoteAddrValve
                            <br/>
                            pentru filtrarea si blocarea adreselor de IP si hostname
                        </li>
                        <li>RequestDumperValve
                            <br/>
                            pentru procesul de debug; scrie cererile HTTP intr-un fisier; rareori folosit
                        </li>
                    </ul>
                    Se pot creea si valve custom.

                    <hr/>

                    <b>2. Interceptarea requesturilor in layer-ul de aplicatie (sau context)</b>
                    <br/>
                    <br/>

                    In aplicatie, <b>[tomcat]/webapps/[aplicatie]/META-INF</b> se creeaza un fisier numit <b>context.xml</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '\n' +
                            '<Context antiResourceLocking="false" privileged="true" >\n' +
                            '\n' +
                            ' <Valve className="org.apache.catalina.valves.RemoteAddrValve"\n' +
                            '         allow="192.168.1.10" />\n' +
                            '\t\t \n' +
                            '<!--\n' +
                            '  <Valve className="org.apache.catalina.valves.RemoteAddrValve"\n' +
                            '         allow="127\\.\\d+\\.\\d+\\.\\d+|::1|0:0:0:0:0:0:0:1" />\n' +
                            ' -->\n' +
                            ' \n' +
                            '</Context>\n'}
                    </SyntaxHighlighter>
                    Configurarea de mai sus, specifica ca se permite request-uri doar de pe <i>192.168.1.10</i>.
                    <br/>
                    Daca se incerca de exemplu de pe <i>192.168.1.11</i> se va returna eroare (HTTP Status 403 - Forbidden).

                    <hr/>
                    <b>3. Interceptarea requesturilor in layer-ul host</b>
                    <br/>
                    <br/>

                    E util atunci cand vrem ca regulile de filtrare sa se aplice pentru toate aplicatiile la nivel de host.
                    <br/>

                    Se modifica fisierul <b>[tomcat]/conf/server.xml</b>, adaugand in sectiunea <i>Service/Engine/Host</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' <Valve className="org.apache.catalina.valves.RemoteAddrValve"\n' +
                            '         allow="192.168.1.10" />'}
                    </SyntaxHighlighter>
                    Modificarea se face pentru fiecare instanta de Tomcat din acel host si  ta.

                    <hr/>
                    <b>4. Interceptarea requesturilor in layer-ul engine</b>
                    <br/>
                    <br/>

                    E util atunci cand vrem ca regulile de filtrare sa se aplice pentru toate host-urile.
                    <br/>
                    <br/>

                    Pe masina <b>192.168.1.10</b>:
                    <br/>

                    Se pot creea 2 virtual host-uri in [tomcat]\webapps:
                    <ul>
                        <li>app1-webapps\ROOT\[applicatia-propriu-zisa]</li>
                        <li>app2-webapps\ROOT\[applicatia-propriu-zisa]</li>
                    </ul>

                    Se modifica fisierul <b>[tomcat]/conf/server.xml</b>, definind hostuile virtuale in sectiunea <i>Service/Engine</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Host name="www.app1.ro"  appBase="webapps/app1-webapps"\n' +
                            '            unpackWARs="true" autoDeploy="true"/>\n' +
                            '\t\t\n' +
                            '<Host name="www.app2.ro"  appBase="webapps/app2-webapps"\n' +
                            '            unpackWARs="true" autoDeploy="true"/>\n'}
                    </SyntaxHighlighter>

                    Se modifica fisierul <b>[tomcat]/conf/server.xml</b> adaugand in sectiunea <i>Service/Engine</i>:,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' <Valve className="org.apache.catalina.valves.RemoteAddrValve"\n' +
                            '         allow="192.168.1.5" />'}
                    </SyntaxHighlighter>

                    Inainte ca browser-ul sa caute in DNS-ul extern, cauta in DNS-ul local:
                    <ul>
                        <li>pe Windows: <b>C:\Windows\System32\drivers\etc\host</b></li>
                        <li>pe Linux: <b>/etc/host</b></li>
                    </ul>

                    Pe masina <b>192.168.1.5</b>:
                    <br/>
                    Se editeaza ca administator urmatoarul fisier din Windows <b>C:\Windows\System32\drivers\etc\host</b>, adaugand urmatoarele linii:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'\t192.168.1.10       www.app1.ro\n' +
                            '\t192.168.1.10       www.app2.ro\n'}
                    </SyntaxHighlighter>

                    Cand in browser se va scrie de exemplu <b>www.app1.ro</b>, acesta va fi tradus in <b>127.0.0.1</b> (masina locala).
                    <br/>
                    Apoi, Tomcat pe baza host-ului <b>www.kj1.ro</b> va rula aplicatia care se gaseste in <b>webapps/kj1-webapps</b>.

                    <br/>
                    <br/>
                    De pe masina <b>192.168.1.5</b> cele 2 site-uri vor functiona ok.
                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ValvesTomcatContent;