import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ReplicaSetsObjectK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-replicasets-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiectul ReplicaSet</b>
                    <br/>
                    <br/>

                    Acest tip de obiect:
                    <ul>
                        <li>este folosit pentru a creea si a mentine un numar de replicii a unui pod</li>
                        <li>foloseste labels si selectori pentru a identifica ca pod-uri trebuie controlate/gestionate</li>
                    </ul>

                    Exemplu de ReplicaSet, pentru a creea Pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: ReplicaSet\n' +
                            'metadata:\n' +
                            '  name: replica-demo\n' +
                            '  labels:\n' +
                            '    component: replicaset-demo\n' +
                            '    tier: frontend' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector: \n' +
                            '    matchLabels:\n' +
                            '      component: replicaset-demo\n' +
                            '    matchExpressions:\n' +
                            '      - {key: component, operator: In, values: [replicaset-demo]}\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:' +
                            '        component: replicaset-demo\n' +
                            '        tier: frontend\n' +
                            '    spec: \n' +
                            '      containers: \n' +
                            '      - name: php-redis \n' +
                            '        image: gcr.io/google_sample/gb-frontend:v3\n' +
                            '        ports:\n' +
                            '        - containerPort: 80' +
                            ''}
                    </SyntaxHighlighter>

                    Afisare lista de replica sets:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get rs'}
                    </SyntaxHighlighter>

                    Descriere replicaset <i>replica-demo</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe rs replica-demo'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReplicaSetsObjectK8sContent;