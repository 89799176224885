import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class Ex2k8s2Content extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-ex2-2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 1: simplek8s (partea a II-a: Google Cloud)</b>
                    <br/>
                    <br/>

                    <hr/>

                    <b>1.1. GitHub</b>
                    <br/>
                    <br/>

                    Pe GitHub se creeaza un nou repository: <b>multi-k8s</b>.

                    <br/>
                    <br/>
                    Ne asiguram ca nu exista in directorul proiectului: <b>.git</b> (daca exista trebuie sters).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ls -a'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'.  ..  client  .github  k8s  server  worker'}
                    </SyntaxHighlighter>
                    se executa urmatoarele comenzi:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git init'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git commit -m "init commit"'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add origin git@github.com:letyournailsgrownow/multi-k8s.git'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex2k8s2Content;