import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FirstClassValuesJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-first-class-values", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    În JavaScript, valori de primă clasă (sau „first-class citizens”) sunt acele entități din limbaj care pot fi tratate ca și alte valori obișnuite,
                    cum ar fi numerele sau șirurile de caractere.
                    Aceste entități pot fi:
                    <ul>
                        <li>
                            stocate într-o variabilă

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let myValue = 42; // Stocăm un număr într-o variabilă\n' +
                                    'let myFunction = function() { return "Hello!"; }; // Stocăm o funcție într-o variabilă'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            transmise ca argument unei funcții
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'function execute(value) {\n' +
                                    '    console.log(value());\n' +
                                    '}\n' +
                                    '\n' +
                                    'execute(function() { return "Hello from a function!"; });'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            returnate de către o funcție
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'function getFunction() {\n' +
                                    '    return function() {\n' +
                                    '        return "This is a returned function!";\n' +
                                    '    };\n' +
                                    '}\n' +
                                    '\n' +
                                    'let myFunc = getFunction();\n' +
                                    'console.log(myFunc()); // Output: "This is a returned function!"'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            stocate într-o structură de date, cum ar fi un obiect sau un array.
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let array = [function() { return "Hello from array!"; }, 42, "test"];\n' +
                                    'console.log(array[0]()); // Output: "Hello from array!"'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Acest concept este esențial deoarece permite un stil de programare funcțional, flexibil și modular.
                    În JavaScript, în special, funcțiile fiind valori de primă clasă face posibilă utilizarea unor tehnici avansate precum:
                    <ul>
                        <li>
                            <b>Funcții de ordin superior (higher-order functions)</b>: Funcții care pot primi alte funcții ca argumente sau pot returna funcții.

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'// O funcție care primește o altă funcție ca argument\n' +
                                    'function executeFunction(fn) {\n' +
                                    '    fn(); // Execută funcția primită\n' +
                                    '}\n' +
                                    '\n' +
                                    '// O funcție simplă\n' +
                                    'function sayHello() {\n' +
                                    '    console.log("Hello, world!");\n' +
                                    '}\n' +
                                    '\n' +
                                    '// Transmitem funcția ca argument\n' +
                                    'executeFunction(sayHello); // Output: "Hello, world!"'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Callback-uri: Funcții transmise altor funcții care sunt apelate după ce anumite acțiuni au fost efectuate.
                        </li>
                        <li>
                            Compoziția funcțiilor: Capacitatea de a combina mai multe funcții pentru a crea comportamente mai complexe.
                        </li>
                    </ul>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FirstClassValuesJavaScriptContent;