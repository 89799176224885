import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class UseEffectReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-useeffect", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hooks: useEffect </b>
                    <br/>
                    <br/>

                    Hooks sunt functii care gestioneaza starea si ciclul de viata o unei componente pe baza unei functii si nu a unei clase.
                    <br/>
                    Hooks nu functionează in interiorul clase.

                    <hr/>

                    Functia <b>useEffect()</b> permite să efectuați efecte secundare în componentele funcției.
                    <br/>
                    Exemplu:
                    <ul>
                        <li>
                            preluarea datelor
                        </li>
                        <li>
                            actualizarea directă a DOM-ului
                        </li>
                        <li>
                            cronometre
                        </li>
                    </ul>

                    Functie <b>useEffect()</b> poate fi vazuta ca o unificare a <b>componentDidMount</b>, <b>componentDidUpdate</b>, și <b>componentWillUnmount</b>.
                    <br/>
                    Există două tipuri comune de efecte secundare în componentele React: cele care nu necesită curățare și cele care o fac.

                    <br/>
                    <br/>
                    În mod implicit, <b>useEffect()</b> rulează atât după prima randare, cât și după fiecare actualizare.
                    <br/>
                    <br/>
                    Se ruleaza doar daca variabila <i>count</i> s-a modificat:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'useEffect(() => {\n' +
                            '  document.title = `You clicked ${count} times`;\n' +
                            '}, [count]); // Only re-run the effect if count changes'}
                    </SyntaxHighlighter>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, { useState, useEffect } from \'react\';\n' +
                            '\n' +
                            'function Example() {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '\n' +
                            '  // Similar to componentDidMount and componentDidUpdate:\n' +
                            '  useEffect(() => {\n' +
                            '    // Update the document title using the browser API\n' +
                            '    document.title = `You clicked ${count} times`;\n' +
                            '  });\n' +
                            '\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <p>You clicked {count} times</p>\n' +
                            '      <button onClick={() => setCount(count + 1)}>\n' +
                            '        Click me\n' +
                            '      </button>\n' +
                            '    </div>\n' +
                            '  '}
                    </SyntaxHighlighter>

                    <hr/>

                    Functia <i>useEffect</i> se va rula la infinit, pentru ca <i>robots</i> se modifica tot timpul:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, {useEffect, useState} from \'react\';\n' +
                            'import CardList from "./CardList";\n' +
                            '\n' +
                            'import SearchBox from "./SearchBox";\n' +
                            'import Scroll from "./Scroll";\n' +
                            '\n' +
                            'function RoboApp3() {\n' +
                            '\n' +
                            '    const [robots, setRobots] = useState([]);\n' +
                            '    const [searchField, setSearchField] = useState(\'\');\n' +
                            '\n' +
                            '    const onSearchChange = (event) => {\n' +
                            '        setSearchField(event.target.value);\n' +
                            '    }\n' +
                            '\n' +
                            '    useEffect(()=>{\n' +
                            '        console.log("useEffect");\n' +
                            '        fetch(\'https://jsonplaceholder.typicode.com/users\')\n' +
                            '            .then(response => response.json())\n' +
                            '            .then(users => setRobots(users))\n' +
                            '    })\n' +
                            '\n' +
                            '    const filteredRobots = robots.filter(robots => {\n' +
                            '        return robots.name.toLowerCase().includes(searchField.toLowerCase())\n' +
                            '    })\n' +
                            '\n' +
                            '    return (\n' +
                            '        <div className="tc container">\n' +
                            '            <SearchBox searchChange={onSearchChange}/>\n' +
                            '            <Scroll>\n' +
                            '                <CardList robots={filteredRobots}/>\n' +
                            '            </Scroll>\n' +
                            '        </div>\n' +
                            '    )\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'export default RoboApp3;'}
                    </SyntaxHighlighter>

                    De asemenea, se apela la infinit si daca adaugam conditia:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {' useEffect(()=>{\n' +
                            '        console.log("useEffect");\n' +
                            '        fetch(\'https://jsonplaceholder.typicode.com/users\')\n' +
                            '            .then(response => response.json())\n' +
                            '            .then(users => setRobots(users))\n' +
                            '    },[robots])'}
                    </SyntaxHighlighter>

                    O posibila rezolvare ar fi (doar la modificarea <i>searchField</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {' useEffect(()=>{\n' +
                            '        console.log("useEffect");\n' +
                            '        fetch(\'https://jsonplaceholder.typicode.com/users\')\n' +
                            '            .then(response => response.json())\n' +
                            '            .then(users => setRobots(users))\n' +
                            '    },[searchField])'}
                    </SyntaxHighlighter>

                    Dar, se poate scrie si asa (daca punem [], este echivalent cu a simula <b>componentDidMount()</b> si <b>componentWillUnmount()</b> ):
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {' useEffect(()=>{\n' +
                            '        console.log("useEffect");\n' +
                            '        fetch(\'https://jsonplaceholder.typicode.com/users\')\n' +
                            '            .then(response => response.json())\n' +
                            '            .then(users => setRobots(users))\n' +
                            '    },[])'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Dacă doriți să rulați un efect și să-l curățați o singură dată (la montare și la demontare), puteți trece o matrice goală ( []) ca al doilea argument.
                            <br/>
                            Acest lucru îi spune lui React că efectul dvs. nu depinde de nicio valoare din elemente de recuzită sau din stare, deci nu trebuie să fie reluat niciodată.
                            <br/>
                            Acest lucru nu este tratat ca un caz special - rezultă direct din modul în care funcționează întotdeauna matricea de dependențe.

                            <br/>
                            <br/>
                            Dacă treceți o matrice goală ( []), elementele de recuzită și starea din interiorul efectului vor avea întotdeauna valorile lor inițiale.
                            <br/>
                            În timp ce trecerea [] drept al doilea argument este mai aproape de modelul familiar componentDidMount() și componentWillUnmount()
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseEffectReactContent;