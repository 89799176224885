import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ForPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-for-statement", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>   13. Python: Instructiunea for</b>

                    <br/>
                    <br/>

                    Instructiunea <b>for</b> poate avea forma:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for variabila_control in colectie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for variabila_control in colectie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn\n' +
                        'else: \n' +
                        '    instructiune_S1\n' +
                        '    instructiune_S2\n' +
                        '    ...\n' +
                        '    instructiune_Sn'}
                    </SyntaxHighlighter>
                    Instructiunile instructiune_T1,instructiune_T1,...instructiune_Tn formeaza <b>corpul buclei</b> (loop's body).
                    <br/>
                    Variabila <b>variabila_control</b> va lua pe rand toate valoarile din <b>colectie</b>.

                    <br/><br/>
                    Daca exista blocul de instructiuni <b>else</b> acestea se vor executa dupa terminarea executiei <b>for</b>-ului (daca nu se iese cu <b>break</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for i in range(1,2):\n' +
                        '    print(i)\n' +
                        'else: print("stop")  '}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1\n' +
                        'stop'}
                    </SyntaxHighlighter>

                    Daca se iese cu break, se sare si de blocul <b>else</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for i in range(1,2):\n' +
                        '    print(i)\n' +
                        '    break\n' +
                        'else: print("stop")  '}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Observatie</b>
                    <br/>
                    Urmatorul fragment de cod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for i in range(2,1):\n' +
                        '    print(i)\n' +
                        'print(i)'}
                    </SyntaxHighlighter>
                    va avea ca rezultat
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Traceback (most recent call last):\n' +
                        '  File "main.py", line 3, in <module>\n' +
                        '    print(i)\n' +
                        'NameError: name \'i\' is not defined'}
                    </SyntaxHighlighter>
                    pentru ca <i>i</i> <b>nu este definit, nefiindu-i atributa niciodata o valoare</b>.
                    <hr/>

                    1. Vom introduce functia <b>range()</b> (accepta doar numere intregi ca argumente) care va genera o colectie:
                    <ul>
                        <li>range(pana_la) : genereza o colectie de numere de la <b>0</b> pana la <b>pana_la-1</b> (de exemplu, range(5) va genera o colectie cu numerele: 0,1,2,3,4)</li>
                        <li>range(de_la, pana_la) : genereza o colectie de numere de la <b>de_la</b> pana la <b>pana_la-1</b>  si <b>de_la</b> &lt; <b>pana_la</b> (de exemplu, range(2,5) va genera o colectie cu numerele: 2,3,4)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'for i in range(3,2):print(i,end="")'}
                            </SyntaxHighlighter>
                            Nu va afisa nimic!

                        </li>
                        <li>range(de_la, pana_la, pas) : genereza o colectie de numere de la <b>de_la</b> pana la <b>pana_la-1</b> cu pasul <b>pas</b> (de exemplu, range(2,5,2) va genera o colectie cu numerele: 2,4)</li>
                    </ul>

                    <hr/>
                    2. Vom introduce cuvantul cheie <b>pass</b>, care reprezinta <b>instructiunea vida</b>.

                    <hr/>
                    3. Vom introduce urmatoarele 2 instructiuni (care sunt si <b>cuvinte cheie</b>):
                    <ul>
                        <li><b>break</b> : se sare din bucla; programul începe să execute cea mai apropiată instrucțiune după corpul buclei
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'for i in range(5):\n' +
                                '    if i==2:\n' +
                                '        break\n' +
                                '    print(i)\n' +
                                'print(\'stop\')'}
                            </SyntaxHighlighter>
                            Se va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'0\n' +
                                '1\n' +
                                'stop'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>continue</b>: se sare la sfarsitul buclei, igorand restul de instructiuni
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'for i in range(5):\n' +
                                '    if i==2:\n' +
                                '        break\n' +
                                '    print(i)\n' +
                                'print(\'stop\')'}
                            </SyntaxHighlighter>
                            Se va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'0\n' +
                                '1\n' +
                                '3\n' +
                                '4\n' +
                                'stop'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>Observatii / Reguli (ca la instructiunea <b>if</b> sau <b>while</b>)</b>
                    <ul>
                        <li>trebuie ca ":" sa fie urmat de o linie noua
                            <br/>
                            exista totusi o exceptie, daca urmeaza o singura instructiune:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'for i in range(3):print(i,end="")'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'012'}
                            </SyntaxHighlighter>
                        </li>
                        <li>trebuie sa existe cel putin o instructiune</li>
                        <li>trebuie sa se pastreze aceeasi identare pentru toate instructiunile (recomandare: 4 spatii sau un tab)</li>
                    </ul>

                    <hr/>
                    4. Vom introduce metoda <b>sleep() </b>, care accepta ca parametru numarul de secunde care suspenda executia codului. Pentru a o folosi, este nevoie importarea pachetului <b>time</b>. <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import time\n' +
                        '\n' +
                        'print("inceput")\n' +
                        'time.sleep(1)\n' +
                        'print("sfarsit")'}
                    </SyntaxHighlighter>
                    Se va afisa textul <i>inceput</i>, urmat de textul <i>sfarsit</i> la o secunda.

                    <hr/>
                    5. Vom introduce functia <b>upper()</b> pentru un obiect de tip String.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'text = "iulian"\n' +
                        'print(text.upper())'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'IULIAN'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ForPythonContent;