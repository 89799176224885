import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class TasksGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-tasks", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Un task este reprezentat un obiect de tip <b>Task</b> (de exemplu task-ul <i>compile</i>).
                    <br/>
                    Fiecare obiect de acest tip este adauga la proiect, care este reprezentat de un obiect de tip <b>Project</b>.
                    <br/>
                    Schematic, ar fi cam asa:
                    <ul>
                        <li>Task task = new compile()</li>
                        <li>project.addTask(task)</li>
                    </ul>

                    Creare task:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task numeTask {\n' +
                        '   println "kj"\n' +
                        '}'}
                    </SyntaxHighlighter>
                    e simular cu:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'project.task (numeTask) {\n' +
                        '   println "kj"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    In interiorul unui task se pot definii variabile:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'project.task (numeTask) {\n' +
                        '   def a=1'+
                        '   println a\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un task fara configurari si actiuni poate arata si asa:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task buna'}
                    </SyntaxHighlighter>

                    <hr/>
                    Structura unui task:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task numeTask{\n'+
                            '   //zona configurare\n'+
                            '   //zona actiuni: doFirst() + doLast(); pot exista mai multe metode/actiuni doFirst() sau doLast() \n'+
                        '}'
                        }
                    </SyntaxHighlighter>

                    Pot exista mai multe metode/actiuni doFirst() si/sau doLast();
                    <ul>
                        <li>metodele doLast() se vor apela in ordinea in care au fost scrise; fiecare metoda doLast() este pusa la sfarsitul unei liste de executie a metodelor doLast()</li>
                        <li>metodele doFirst() se vor apela in ordinea inversa in care au fost scrise; fiecare metoda doFirst() este pusa la inceptul unei liste de executie a metodelor doFirst()</li>
                    </ul>


                    <hr/>

                    <b>1. Tipuri de task-uri</b>
                    <br/>
                    <br/>

                    Task-urile pot fi:
                    <ul>
                        <li><b>ad-hoc</b>:
                            <ul>
                                <li>extind in mod automat <b>DefaultTask</b></li>
                                <li>actiunile sunt definite prin implementarea metodelor <b>doFirst</b> sau <b>doLast</b></li>
                            </ul>
                            exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'task primulTask {\n' +
                                '    doLast {\n' +
                                '        ...\n' +
                                '    }\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>pe baza de tipuri</b>
                            <ul>
                                <li>extind un tip; tipul este declarat explicit prin intermediul cuvantului <b>type</b> (de exemplu: copy)</li>
                                <li>nu este necesare definirea de actiuni; acestea sunt definite de tip (de exemplu: from, into)</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'task copyTask (type: Copy){ // tip explicit\n' +
                                    '   from "src" // metoda din API-ul Copy\n'+
                                    '   into "build/docs" // metoda din API-ul Copy\n'+
                                    '   include "**/**txt" // metoda din API-ul Copy\n'+
                                    '   includeEmptyDirs = false // metoda din API-ul Copy\n'+
                                '}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'task zipTask (type: Zip){\n' +
                                '   from "build/docs"\n'+
                                '   archiveFileName = "docs.zip"\n'+
                                '   destinationDirectory = file("build/dist")\n'+
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Alte tipuri de task-uri:
                    <ul>
                        <li>Exec
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'task cmdTask (type: Exec){\n' +
                                '   commandLine "cmd","ver"\n'+
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>Delete
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'task deleteTask (type: Delete){\n' +
                                '   delete "build/docs","build/dist"\n'+
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>
                    <b>2. Dependinte intre task-uri: dependsOn</b>
                    <br/>
                    <br/>

                    Pentru a specifica ca un task depinde de alt task se foloseste <b>dependsOn</b>.
                    <br/>
                    <br/>
                    De exemplu, putem specifica ca <i>zipTask</i> depinde de <i>copyTask</i> (adica  <i>zipTask</i> se va executa dupa ce se termina de <i>copyTask</i>):

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task zipTask (type: Zip){\n' +
                        '   from "build/docs"\n'+
                        '   archiveFileName = "docs.zip"\n'+
                        '   destinationDirectory = file("build/dist")\n'+
                        '   dependsOn copyTask\n'+
                        '}'}
                    </SyntaxHighlighter>

                    Putem scrie cele 2 task-uri intr-un fisier numit <i>build-task.gradle</i> si rula comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle zipTask -b build-task.gradle -q'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Ordinea de executie a task-urilor</b>
                    <br/>
                    <br/>

                    Daca avem task-ul A care depinde de B si C (A dependsOn B, A dependsOn C) atunci task-urile B si C se vor executa inainte de A, dar nu este garanta ordinea lui B si C.
                    Deci vom avea: B, C, A sau C, B, A
                    <br/>
                    <br/>
                    Pentru a specifica lucrul aceste se foloseste <b>mustRunAfter</b>.
                    <br/>
                    <br/>
                    Continuand exemplu de mai sus si specificand B mustRunAfter C, vom avea intotdeauna urmatoarea ordine: C, B, A.

                    <br/>
                    <br/>
                    Exemplu 1 (dependsOn):
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'task A{\n' +
                        '\tdoLast{\n' +
                        '\t\tprint "A"\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        '\n' +
                        'task B{\n' +
                        '\tdoLast{\n' +
                        '\t\tprint "B"\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'B.dependsOn  A'}
                    </SyntaxHighlighter>

                    Daca se executa <b>gradle B</b>, se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'> Task :app:A\n' +
                    'A\n' +
                    '> Task :app:B\n' +
                    'B'}
                    </SyntaxHighlighter>

                    Exemplu 2 (finalizedBy - specifica task-ul care se va rula dupa ce se va termina task-ul dat ca parametru):
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'task A{\n' +
                        '\tdoLast{\n' +
                        '\t\tprint "A"\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        '\n' +
                        'task B{\n' +
                        '\tdoLast{\n' +
                        '\t\tprint "B"\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        '\n' +
                        'task F{\n' +
                        '\tdoLast{\n' +
                        '\t\tprint "F"\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'B.dependsOn  A\n' +
                        'B.finalizedBy F'}
                    </SyntaxHighlighter>

                    Daca se executa <b>gradle B</b>, se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'> Task :app:A\n' +
                        'A\n' +
                        '> Task :app:B\n' +
                        'B\n' +
                        '> Task :app:F\n' +
                        'F'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sa presupunem ca avem:
                    <ul>
                        <li>Taskul A care depinde de task-urile X si Y</li>
                        <li>Taskul B care depinde de task-urile A, Z si Y</li>
                    </ul>
                    Atunci ordinea va fi: X, Y (se executa o singura data si nu de 2 ori!), A, Z, B!

                    <hr/>

                    Daca vrem ca un task sa depinda de anumite task-uri care repecta un anumit pattern (sa incepa cu 'do' de exemplu):
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'task buna\n' +
                        'task doCeva\n' +
                        '\n' +
                        'println project.tasks.findAll{ task -> task.name.startsWith(\'do\')}\n' +
                        '\n' +
                        'buna.dependsOn project.tasks.findAll{ task -> task.name.startsWith(\'do\')}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Daca vrem, de exemplu, ca un task sa depinda de anumite task-uri depinzand de o conditie:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'if (Math.random() <0.5){\n' +
                        '\tbuna.dependsOn project.tasks.findAll{ task -> task.name.startsWith(\'do\')}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. DAG (directed acyclic graph)</b>
                    <br/>
                    <br/>
                    Task-urile sunt reprezentate de noduri, iar dependintele (dependsOn, mustRunAfter) definesc muchiile (legurile) grafului.

                    <br/>

                    In <b>taskGraph</b> se vor pune toate task-urile. Metoda <b>whenReady()</b> se poate folosi pentru a afisa toate task-urile, dupa ce <b>taskGraph</b> a fost populat.
                    <br/>
                    Daca se apeleaza inainte sa se populeze, se va arunca o exceptie.

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\n' +
                        'project.gradle.taskGraph.whenReady{\n' +
                        '\n' +
                        '\tprintln "$project.gradle.taskGraph.allTasks"\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    De exemplu, se poate seta versiunea proiectului, in functie de prezenta unui task in graf:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'project.gradle.taskGraph.whenReady{ taskGraph ->\n' +
                        '\n' +
                        '\tif (taskGraph.hasTask(doCeva)){\n' +
                        '\t\tproject.version="1.0"\n' +
                        '\t}else{\n' +
                        '\t\tproject.version="2.0"\n' +
                        '\t}\t\n' +
                        '\t\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alte 2 metode callback utile sunt:
                    <ul>
                        <li><b>beforeTask()</b>: se apeleaza inainte de executarea unui task</li>
                        <li><b>afterTask()</b>: se apeleaza dupa executarea unui task, indiferent dacă excutarea s-a finalizat cu succes sau cu o excepție.</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'project.gradle.taskGraph.beforeTask{ task ->\n' +
                        '\n' +
                        '\tprintln "before: $task.name"\n' +
                        '\n' +
                        '}\n' +
                        '\n' +
                        'project.gradle.taskGraph.afterTask{ task ->\n' +
                        '\n' +
                        '\tprintln "after: $task.name"\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>5. Afisare toate task-urile</b>
                    <br/>
                    <br/>

                    Pentru a afisa toate task-urile: gradle <b>tasks --all</b> -b build-task.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle tasks --all -b build-task.gradle\n' +
                        '\n' +
                        '> Task :tasks\n' +
                        '\n' +
                        '------------------------------------------------------------\n' +
                        'Tasks runnable from root project\n' +
                        '------------------------------------------------------------\n' +
                        '\n' +
                        'Build Setup tasks\n' +
                        '-----------------\n' +
                        'init - Initializes a new Gradle build.\n' +
                        'wrapper - Generates Gradle wrapper files.\n' +
                        '\n' +
                        'Help tasks\n' +
                        '----------\n' +
                        'buildEnvironment - Displays all buildscript dependencies declared in root project \'gradle\'.\n' +
                        'components - Displays the components produced by root project \'gradle\'. [incubating]\n' +
                        'dependencies - Displays all dependencies declared in root project \'gradle\'.\n' +
                        'dependencyInsight - Displays the insight into a specific dependency in root project \'gradle\'.\n' +
                        'dependentComponents - Displays the dependent components of components in root project \'gradle\'. [incubating]\n' +
                        'help - Displays a help message.\n' +
                        'model - Displays the configuration model of root project \'gradle\'. [incubating]\n' +
                        'outgoingVariants - Displays the outgoing variants of root project \'gradle\'.\n' +
                        'projects - Displays the sub-projects of root project \'gradle\'.\n' +
                        'properties - Displays the properties of root project \'gradle\'.\n' +
                        'tasks - Displays the tasks runnable from root project \'gradle\'.\n' +
                        '\n' +
                        'Other tasks\n' +
                        '-----------\n' +
                        'copyTask\n' +
                        'prepareKotlinBuildScriptModel\n' +
                        'zipTask'}
                    </SyntaxHighlighter>

                    Dupa cum se vede <i>copyTask</i> si <i>zipTask</i> se regasesc in grupul <i>Other tasks</i>. Acest lucru se poate modifica prin
                    utilizarea proprietatilor <b>group</b> si <b>description</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task copyTask (type: Copy){ // tip explicit\n' +
                        '   group = "kj group" \t\n' +
                        '   description = "kj copiaza"\n' +
                        '   \n' +
                        '   from "src" // metoda din API-ul Copy\n' +
                        '   into "build/docs"  // metoda din API-ul Copy\n' +
                        '   include "**/**txt" // metoda din API-ul Copy\n' +
                        '   includeEmptyDirs = false // metoda din API-ul Copy\n' +
                        '}\n' +
                        '\n' +
                        'task zipTask (type: Zip){\n' +
                        '   group = "kj group" \t\n' +
                        '   description = "kj zipuieste"\n' +
                        '   \n' +
                        '   from "build/docs"\n' +
                        '   archiveFileName = "docs.zip"\n' +
                        '   destinationDirectory = file("build/dist")\n' +
                        '   dependsOn copyTask\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Daca se ruleaza din nou comanda de afisare a tuturor taskurilor, task-urile <i>copyTask</i> si <i>zipTask</i>  vor fi puse in grupul <i>kj group</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle tasks --all --b build-task.gradle\n' +
                        '\n' +
                        '> Task :tasks\n' +
                        '\n' +
                        '------------------------------------------------------------\n' +
                        'Tasks runnable from root project\n' +
                        '------------------------------------------------------------\n' +
                        '\n' +
                        'Build Setup tasks\n' +
                        '-----------------\n' +
                        'init - Initializes a new Gradle build.\n' +
                        'wrapper - Generates Gradle wrapper files.\n' +
                        '\n' +
                        'Help tasks\n' +
                        '----------\n' +
                        'buildEnvironment - Displays all buildscript dependencies declared in root project \'gradle\'.\n' +
                        'components - Displays the components produced by root project \'gradle\'. [incubating]\n' +
                        'dependencies - Displays all dependencies declared in root project \'gradle\'.\n' +
                        'dependencyInsight - Displays the insight into a specific dependency in root project \'gradle\'.\n' +
                        'dependentComponents - Displays the dependent components of components in root project \'gradle\'. [incubating]\n' +
                        'help - Displays a help message.\n' +
                        'model - Displays the configuration model of root project \'gradle\'. [incubating]\n' +
                        'outgoingVariants - Displays the outgoing variants of root project \'gradle\'.\n' +
                        'projects - Displays the sub-projects of root project \'gradle\'.\n' +
                        'properties - Displays the properties of root project \'gradle\'.\n' +
                        'tasks - Displays the tasks runnable from root project \'gradle\'.\n' +
                        '\n' +
                        'Kj group tasks\n' +
                        '--------------\n' +
                        'copyTask - kj copiaza\n' +
                        'zipTask - kj zipuieste\n' +
                        '\n' +
                        'Other tasks\n' +
                        '-----------\n' +
                        'prepareKotlinBuildScriptModel\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Fazele ciclului de viata pentru un build (Build Lifecycle Phases)</b>
                    <br/>
                    <br/>
                    Lifecycle-ul este:
                    <ul>
                        <li>faza de initializare
                            <ul>
                                <li>evalueaza fisierul settings</li>
                                <li>setarile sunt pastrate pentru a fi folosite in urmatoarele faze</li>
                                <li>identifica toate fisierele <i>build.gradle</i> din proiect (in multi-proiecte pot fi mai multe)</li>
                                <li>se creeaza o instanta <i>org.gradle.api.Project</i> pentru fiecare proiect</li>
                            </ul>
                        </li>
                        <li>faza de configurare
                            <ul>
                                <li>evalueaza scriptul de build</li>
                                <li>construieste DAG pentru fiecare proiect</li>
                                <li>se executa instructiunile de configurare/sectiunile de configurare (inclusiv pentru task-urile dezactivate);
                                    <br/>
                                    sectiunile de configurare pentru un task sunt reprezentate de instructiunile care nu sunt cuprinse in metode
                                </li>

                                Fie fisierul <i>build-task-config.gradle</i> cu urmatorul continut:
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {'task configATask { \n' +
                                    '\tprintln name // zona de configurare\n' +
                                    '\tdoFirst{ // zona/metoda de executie\n' +
                                    '\t\tprintln "inceput task:"+name\n' +
                                    '\t}\n' +
                                    '\tdoLast{ // zona/metoda de executie\n' +
                                    '\t\tprintln "sfarsit task:"+name\n' +
                                    '\t}\n' +
                                    '\tonlyIf{\n' +
                                    '\t\treturn true;\n' +
                                    '\t}\n' +
                                    '}\n' +
                                    '\n' +
                                    'task configBTask { \n' +
                                    '\tprintln name  // zona de configurare\n' +
                                    '\tdoFirst{ // zona/metoda de executie\n' +
                                    '\t\tprintln "inceput task:"+name\n' +
                                    '\t}\n' +
                                    '\tdoLast{ // zona/metoda de executie\n' +
                                    '\t\tprintln "sfarsit task:"+name\n' +
                                    '\t}\n' +
                                    '\tonlyIf{\n' +
                                    '\t\treturn false;\n' +
                                    '\t}\n' +
                                    '}\n'}
                                </SyntaxHighlighter>
                                Daca se executa:
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {'gradle -b build-task-config.gradle  -q\n' +
                                    'configATask\n' +
                                    'configBTask'}
                                </SyntaxHighlighter>

                                Daca se executa:
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {'gradle configATask -b build-task-config.gradle  -q\n' +
                                    'configATask\n' +
                                    'configBTask\n' +
                                    'inceput task:configATask\n' +
                                    'sfarsit task:configATask'}
                                </SyntaxHighlighter>

                                Daca se executa (task-ul configBTask nu este activ):
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {'gradle configBTask -b build-task-config.gradle  -q\n' +
                                    'configATask\n' +
                                    'configBTask'}
                                </SyntaxHighlighter>

                            </ul>
                        </li>
                        <li>faza de executie
                            <ul>
                                <li>se executa actiunile task-urilor in ordinea corecta</li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println "start"\n' +
                        '\n' +
                        'task exemplu{\n' +
                        '\tprintln "configurare"\n' +
                        '\t\n' +
                        '\tdoFirst{\n' +
                        '\t\tprintln "doFirst"\n' +
                        '\t}\n' +
                        '\t\n' +
                        '\tdoLast{\n' +
                        '\t\tprintln "doLast"\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'println "sfarsit"'}
                    </SyntaxHighlighter>

                    Daca rulam scriptul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'> Configure project :\n' +
                        'start\n' +
                        'configurare\n' +
                        'sfarsit\n' +
                        '\n' +
                        '> Task :exemplu\n' +
                        'doFirst\n' +
                        'doLast'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>

                    <hr/>
                    <b>7. Metode si proprietati pentru DefaultTask</b>
                    <br/>
                    <br/>

                    Proprietati:
                    <table>
                        <thead>
                        <tr>
                            <th>proprietate</th>
                            <th>descriere</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>name</td>
                            <td>numele task-ului</td>
                        </tr>
                        <tr>
                            <td>description</td>
                            <td>descrierea task-ului</td>
                        </tr>
                        <tr>
                            <td>group</td>
                            <td>numele grupului din care face parte task-ul; grupul reprezinta o modalitate de a grupa logic niste task-uri</td>
                        </tr>
                        <tr>
                            <td>enabled</td>
                            <td>stabileste daca un task este activat sau dezactivat</td>
                        </tr>
                        <tr>
                            <td>dependsOn</td>
                            <td>configureaza dependintele task-ului (de ce task-uri depinde)</td>
                        </tr>
                        </tbody>
                    </table>

                    Metode:

                    <table>
                        <thead>
                        <tr>
                            <th>metoda</th>
                            <th>descriere</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>doFirst</td>
                            <td>adauga o actiune la inceputul listei de actiunii a task-ului</td>
                        </tr>

                        <tr>
                            <td>doLast</td>
                            <td>adauga o actiune la sfarsitul listei de actiunii a task-ului</td>
                        </tr>

                        <tr>
                            <td>onlyIf</td>
                            <td>metoda care stabileste in ce conditii va rula task-ul; se va executa doar daca metoda returnaza true</td>
                        </tr>

                        <tr>
                            <td>ext</td>
                            <td>metoda care permite adaugarea de noi proprietati la task</td>
                        </tr>

                        </tbody>
                    </table>

                    Sa presupunem ca avem urmatorul script <i>build-test-methods</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task testTask { \n' +
                        '\tprintln "testTask"\n' +
                        '\tdoFirst{\n' +
                        '\t\tprintln "inceput task:"+name\n' +
                        '\t}\n' +
                        '\tdoLast{\n' +
                        '\t\tprintln "sfarsit task:"+name\n' +
                        '\t}\n' +
                        '\tonlyIf{\n' +
                        '\t\treturn true;\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Daca rulam scriptul:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'>gradle testTask -b build-task-methods.gradle -q\n' +
                        'testTask\n' +
                        'inceput task:testTask\n' +
                        'sfarsit task:testTask'}
                    </SyntaxHighlighter>

                    Daca modficam scriptul de mai sus <i>build-test-methods</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task testTask { \n' +
                        '\tprintln "testTask"\n' +
                        '\tdoFirst{\n' +
                        '\t\tprintln "inceput task:"+name\n' +
                        '\t}\n' +
                        '\tdoLast{\n' +
                        '\t\tprintln "sfarsit task:"+name\n' +
                        '\t}\n' +
                        '\tonlyIf{\n' +
                        '\t\treturn false; // AICI E MODIFICAREA\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Vom obtine urmatorul rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'>gradle testTask -b build-task-methods.gradle -q\n'+
                        'testTask'}
                    </SyntaxHighlighter>
                    Se afiseaza 'testTask' pentru ca faza de configurare se executa toate instructiunile de configurare, chiar daca task-ul este dezactivat.

                    <hr/>
                    <b>8. Setare task-uri implicite</b>
                    <br/>
                    <br/>

                    Pentru a seta task-uri implicite se foloseste instructiunea: <b>defaultTasks</b> 'nume-task-1','nume-task-2',...,'nume-task-N'

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'defaultTasks \'clean\', \'run\''}
                    </SyntaxHighlighter>

                    Instructiunea <b>defaultTasks</b> defineste, de asemenea, si ordinea in care se executa task-urile.

                    <hr/>
                    <b>9. Optimizari</b>
                    <br/>
                    <br/>
                    9.1. In loc de:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task myTask(type: MyTask) {}'}
                    </SyntaxHighlighter>
                    ar trebui sa se foloseasca urmatoarea forma:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'tasks.register("myTask", MyTask) {}'}
                    </SyntaxHighlighter>
                    <hr/>
                    9.2. In loc de:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task (\'myTask\') {\n' +
                            '   // cod de configurare \n'+
                        '}'}
                    </SyntaxHighlighter>
                    ar trebui sa se foloseasca urmatoarea forma:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'tasks.register("myTask") { \n' +
                            '   // cod de configurare \n'+
                        '}'}
                    </SyntaxHighlighter>

                    Cum functionaza:
                    <ul>
                        <li>in varianta veche, se creaza o instanta a clasei <b>DefaultTask</b> pentru <i>myTask</i>, iar
                            <b>codul de configurare va fi executat</b> indiferent daca se foloseste task-ul sau nu (iar acest lucru poate fi costisitor)
                        </li>
                        <li>in varianta noua, nu se mai creeaza un obiect de tipul <b>DefaultTask</b> pentru <i>myTask</i>
                            <br/>
                            TaskContainer.register() doar va înregistra task-ul astfel încât să poată fi referit de catre alte task-uri.
                            <br/>
                            Crearea și configurarea task-ului va fi amânata și se va întâmpla numai atunci când task-ul este executat sau este executat de un task dependentă.
                        </li>
                    </ul>

                    Când apelează se apelaza <b>TaskContainer.register()</b>, Gradle va crea un obiect de tip <b>TaskProvider</b>.
                    Acesta este folosit ca o referință la task-ul care nu a fost creat inca, putand fi folosit si de alte metode cum ar fi <b>TaskContainer.named()</b>.
                    <b>TaskProvider</b> expune o singura metoda: <b>configure()</b> care permite înregistrarea blocurilor de configurare:

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'tasks.named("myTask").configure { \n' +
                        '   // cod de configurare \n'+
                        '}'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task mySlowTask { \n' +
                        '    sleep 2000 \n' +
                        '    doLast { \n' +
                        '        println „Aceast task va adăuga 2 secunde fazei de configurare de fiecare dată când executați orice task” \n' +
                        '    } \n' +
                        '}\n' +
                        'tasks.register("mySlowTask") { \n' +
                        '    sleep 2000 \n' +
                        '    doLast { \n' +
                        '        println "Aceast task va adăuga 2 sedunde fazei de configurare decât dacă o executați în mod special sau daca executati un task care depind de ea" \n' +
                        '    } \n' +
                        '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a href={"https://pspdfkit.com/blog/2019/gradle-task-configuration-avoidance-in-android-builds/"}>Gradle Task Configuration Avoidance in Android Builds</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TasksGradleContent;