import {useRoutes} from "react-router-dom";
import MusicsHomeContent from "./IndexContent";
import React from "react";
import VinylMusicContent from "./VinylMusicContent";

// <Route path="/music/index" element={<MusicsHomeContent/>} exact/>
// <Route path="/music/vinyl" element={<VinylMusicContent/>} exact/>

export default function Router() {
    let element = useRoutes([
        { path: "/music/index", element: <MusicsHomeContent /> },
        { path: "/music/vinyl", element: <VinylMusicContent /> },
    ]);

    return element;
}