import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WhileJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-while", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'while (conditie){\n' +
                        '    bloc_instructiuni\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Blocul de instructiuni se executa cat timp <i>conditie</i> este evaluata la valoarea <i>true</i>

                    Exista si urmatoarea varianta:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'do {\n' +
                        '    bloc_instructiuni\n' +
                        '} while(conditie);'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <ul>
                        <li>dupa acesta structura repetitiva; se pune ';'</li>
                    </ul>

                    <hr/>
                    <b>Extra</b>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let x = 1;\n' +
                        'console.log(`${x++}`); // 1\n' +
                        'console.log(x); // 2'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WhileJavaScriptContent;