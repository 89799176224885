import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class DeployWebApplicationsMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-deploy-web-applications", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare Tomcat</b>:
                    <br/>
                    <br/>

                    Se modifica fisierul [tomcat]\conf\<b>tomcat-users.xml</b>, astfel incat:
                    <ul>
                        <li>sa existe rolurile: manager, manager-script, manager-gui</li>
                        <li>utilizatorul <i>tomcat</i> sa aiba rolurile <i>manager-gui,manager-script</i></li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<role rolename="manager-script"/>\n' +
                            '<role rolename="manager-gui"/>\n' +
                            '<!--\n' +
                            '<user username="tomcat" password="tomcat" roles="manager-script"/>-->\n' +
                            '<user username="tomcat" password="tomcat" roles="manager-gui,manager-script"/>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Configurare Maven</b>:
                    <br/>
                    <br/>

                    Se modifica fisierul [maven]\conf\<b>settings.xml</b>, adaugandu-se urmatoarele linii, in sectiunea <b>servers</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<server>\n' +
                            '      <id>TomcatServer</id>\n' +
                            '      <username>tomcat</username>\n' +
                            '      <password>tomcat</password>\n' +
                            '</server>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Generare aplicatie web folosind Maven</b>:
                    <br/>
                    <br/>

                    Se executa urmatoare comanda, alegand un arhetip: <i>org.graphast:servlet31-archetype-webapp (Webapp Archetype for Servlet 3.1)</i>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate'}
                    </SyntaxHighlighter>
                    Apoi setam:
                    <ul>
                        <li>groupId: ro.learn.maven</li>
                        <li>artefactId: WebAppMavenTomcat</li>
                    </ul>

                    <hr/>
                    <b>3. Deploy pe Tomcat</b>:
                    <br/>
                    <br/>

                    Se adauga in sectiunea <b>build\plugins</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<plugin>\n' +
                            '        <groupId>org.apache.tomcat.maven</groupId>\n' +
                            '        <artifactId>tomcat7-maven-plugin</artifactId>\n' +
                            '        <version>2.1</version>\n' +
                            '        <configuration>\n' +
                            '          <url>http://localhost:8580/manager/text</url>\n' +
                            '          <server>TomcatServer</server>\n' +
                            '        </configuration>\n' +
                            '</plugin>'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>8580 - e portul pe care e pornit Tomcat</li>
                    </ul>

                    Deploy cu maven:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn tomcat7:deploy'}
                    </SyntaxHighlighter>
                    in consola se va afisa urmatorul mesaj:
                    <i>Deploying war to http://localhost:8580/WebAppMavenTomcat</i>

                    Pentru redeploy cu maven:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn tomcat7:redeploy'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean tomcat7:redeploy\n'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>
                    Pentru versiunea Tomcat 10 este nevoie sa se puna in <b>lib</b>-ul serverului:
                    <ul>
                        <li>jakarta.servlet.jsp.jstl-2.0.0.jar</li>
                        <li>jakarta.servlet.jsp.jstl-api-2.0.0.jar</li>
                    </ul>

                    sau declarate in <b>pom.xml</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {' <!-- https://mvnrepository.com/artifact/org.glassfish.web/jakarta.servlet.jsp.jstl -->\n' +
                            '    <dependency>\n' +
                            '      <groupId>org.glassfish.web</groupId>\n' +
                            '      <artifactId>jakarta.servlet.jsp.jstl</artifactId>\n' +
                            '      <version>2.0.0</version>\n' +
                            '    </dependency>\n' +
                            '\n' +
                            '    <!-- https://mvnrepository.com/artifact/jakarta.servlet.jsp.jstl/jakarta.servlet.jsp.jstl-api -->\n' +
                            '    <dependency>\n' +
                            '      <groupId>jakarta.servlet.jsp.jstl</groupId>\n' +
                            '      <artifactId>jakarta.servlet.jsp.jstl-api</artifactId>\n' +
                            '      <version>2.0.0</version>\n' +
                            '    </dependency>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Criptarea parolelor</b>
                    <br/>
                    <br/>

                    4.1. Crearea unei parole <b>master</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn --encrypt-master-password  <parola>'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -emp <parola>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -emp masterPassword'}
                    </SyntaxHighlighter>
                    Se va genera ceva de genul:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'{q5bWR4EX+pUHg0sij6vej5xf948229vMDvDQTLPI/lM=}'}
                    </SyntaxHighlighter>

                    Apoi in directorul [users]\<b>.m2</b> se creaza un fisier xml numit <b>settings-security.xml</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<settingsSecurity>\n' +
                            '  <master>{q5bWR4EX+pUHg0sij6vej5xf948229vMDvDQTLPI/lM=}</master>\n' +
                            '</settingsSecurity>'}
                    </SyntaxHighlighter>

                    4.21. Crearea unei parole criptate:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn --encrypt-password  <parola>'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -ep <parola>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -ep tomcat'}
                    </SyntaxHighlighter>
                    Se va genera ceva de genul:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'{Q\/yM3q9Rp8UHdWlJGiF1xwwDmav/PLEyHoh+mtKBYxU=}'}
                    </SyntaxHighlighter>

                    Se modifica fisierul [maven]\conf\<b>settings.xml</b>, modificand parola <i>tomcat</i> cu varianta cripata:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<server>\n' +
                            '      <id>TomcatServer</id>\n' +
                            '      <username>tomcat</username>\n' +
                            '      <password>{Q/yM3q9Rp8UHdWlJGiF1xwwDmav/PLEyHoh+mtKBYxU=}</password>\n' +
                            '</server>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Proprietati</b>
                    <br/>
                    <br/>

                    Exemplu de proprietati implicite:
                    <ul>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'${project.artifactId}'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'${project.version}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'${env.JAVA_HOME}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Se pot defini proprietati noi in sectiunea <b>properties</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<properties>\n' +
                            '    <tomcat.url>http://localhost:8580</tomcat.url>\n' +
                            '</properties>'}
                    </SyntaxHighlighter>
                    si apoi se poate folosi in urmatorul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'${tomcat.url}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<configuration>\n' +
                            '          <url>${tomcat.url}/manager/text</url>\n' +
                            '          <server>TomcatServer</server>\n' +
                            '</configuration>'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>6. Comenzi utile:</b>
                    <br/>
                    <br/>

                    Afisare help pentru comanda <i>mvn</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help'}
                    </SyntaxHighlighter>

                    Afisare versiunea lui <i>maven</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -v'}
                    </SyntaxHighlighter>

                    Afisare help pentru plugin-ul <i>compiler</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:help'}
                    </SyntaxHighlighter>

                    Sterge directorul <i>target</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean'}
                    </SyntaxHighlighter>

                    Eliminare afisare mesaje in consola in urma rularii comenzii (parametrul <b>-q</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -q compile'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -q clean'}
                    </SyntaxHighlighter>

                    Afisare mai multe detalii in consola in urma rularii comenzii (parametrul <b>-x</b>) - mod debug
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -x tomcat7:redeploy'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DeployWebApplicationsMavenContent;