import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"tts-root", url:"#", title:"Text-to-Speech (TTS)", subtitle:""},
        {id:"tts-piper-windows", url:"/tts/piper-windows", title:"Piper - Windows", subtitle:"", parent:"tts-root"},
        {id:"tts-piper-pi5", url:"/tts/piper-pi5", title:"Piper - Raspberry Pi 5", subtitle:"", parent:"tts-root"},
        {id:"tts-piper-python-pi5", url:"/tts/piper-python-pi5", title:"Piper - Python - Raspberry Pi 5", subtitle:"", parent:"tts-root"},
        {id:"tts-espeak-pi5", url:"/tts/espeak-pi5", title:"espeak si espeak-ng - Raspberry Pi 5", subtitle:"", parent:"tts-root"},
        {id:"tts-gtts-pi5", url:"/tts/gtts-pi5", title:"gTTS - Python - Raspberry Pi 5", subtitle:"", parent:"tts-root"},
        {id:"tts-pyttsx3-pi5", url:"/tts/pyttsx3-pi5", title:"pyttsx3 - Python - Raspberry Pi 5", subtitle:"", parent:"tts-root"},
        {id:"tts-util", url:"/tts/util", title:"Util", subtitle:"", parent:"tts-root"},

    ]

    static indexUrl = "/tts/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Text-to-Speech (TTS)
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;