import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class GeneratedCodeDomainJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-domain-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: domain/entitati Java</b>
                    <br/>
                    <br/>

                    In directorul <b>src/main/java</b> pachetul <i>ro.ibrid.lapretbun.domain</i> s-au generat entitatile Java:

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>Product.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.domain;\n' +
                            '\n' +
                            'import com.fasterxml.jackson.annotation.JsonIgnoreProperties;\n' +
                            'import io.swagger.v3.oas.annotations.media.Schema;\n' +
                            'import java.io.Serializable;\n' +
                            'import java.math.BigDecimal;\n' +
                            'import javax.persistence.*;\n' +
                            'import javax.validation.constraints.*;\n' +
                            'import org.hibernate.annotations.Cache;\n' +
                            'import org.hibernate.annotations.CacheConcurrencyStrategy;\n' +
                            'import ro.ibrid.lapretbun.domain.enumeration.Size;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Product sold by the Online store\n' +
                            ' */\n' +
                            '@Schema(description = "Product sold by the Online store")\n' +
                            '@Entity\n' +
                            '@Table(name = "product")\n' +
                            '@Cache(usage = CacheConcurrencyStrategy.READ_WRITE)\n' +
                            '@SuppressWarnings("common-java:DuplicatedBlocks")\n' +
                            'public class Product implements Serializable {\n' +
                            '\n' +
                            '    private static final long serialVersionUID = 1L;\n' +
                            '\n' +
                            '    @Id\n' +
                            '    @GeneratedValue(strategy = GenerationType.SEQUENCE, generator = "sequenceGenerator")\n' +
                            '    @SequenceGenerator(name = "sequenceGenerator")\n' +
                            '    @Column(name = "id")\n' +
                            '    private Long id;\n' +
                            '\n' +
                            '    @NotNull\n' +
                            '    @Column(name = "name", nullable = false)\n' +
                            '    private String name;\n' +
                            '\n' +
                            '    @Column(name = "description")\n' +
                            '    private String description;\n' +
                            '\n' +
                            '    @NotNull\n' +
                            '    @DecimalMin(value = "0")\n' +
                            '    @Column(name = "price", precision = 21, scale = 2, nullable = false)\n' +
                            '    private BigDecimal price;\n' +
                            '\n' +
                            '    @NotNull\n' +
                            '    @Enumerated(EnumType.STRING)\n' +
                            '    @Column(name = "size_product", nullable = false)\n' +
                            '    private Size sizeProduct;\n' +
                            '\n' +
                            '    @Lob\n' +
                            '    @Column(name = "image")\n' +
                            '    private byte[] image;\n' +
                            '\n' +
                            '    @Column(name = "image_content_type")\n' +
                            '    private String imageContentType;\n' +
                            '\n' +
                            '    @ManyToOne\n' +
                            '    @JsonIgnoreProperties(value = { "products" }, allowSetters = true)\n' +
                            '    private ProductCategory productCategory;\n' +
                            '\n' +
                            '    // jhipster-needle-entity-add-field - JHipster will add fields here\n' +
                            '\n' +
                            '    public Long getId() {\n' +
                            '        return this.id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product id(Long id) {\n' +
                            '        this.setId(id);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setId(Long id) {\n' +
                            '        this.id = id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getName() {\n' +
                            '        return this.name;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product name(String name) {\n' +
                            '        this.setName(name);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setName(String name) {\n' +
                            '        this.name = name;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getDescription() {\n' +
                            '        return this.description;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product description(String description) {\n' +
                            '        this.setDescription(description);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setDescription(String description) {\n' +
                            '        this.description = description;\n' +
                            '    }\n' +
                            '\n' +
                            '    public BigDecimal getPrice() {\n' +
                            '        return this.price;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product price(BigDecimal price) {\n' +
                            '        this.setPrice(price);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setPrice(BigDecimal price) {\n' +
                            '        this.price = price;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Size getSizeProduct() {\n' +
                            '        return this.sizeProduct;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product sizeProduct(Size sizeProduct) {\n' +
                            '        this.setSizeProduct(sizeProduct);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setSizeProduct(Size sizeProduct) {\n' +
                            '        this.sizeProduct = sizeProduct;\n' +
                            '    }\n' +
                            '\n' +
                            '    public byte[] getImage() {\n' +
                            '        return this.image;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product image(byte[] image) {\n' +
                            '        this.setImage(image);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setImage(byte[] image) {\n' +
                            '        this.image = image;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getImageContentType() {\n' +
                            '        return this.imageContentType;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product imageContentType(String imageContentType) {\n' +
                            '        this.imageContentType = imageContentType;\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setImageContentType(String imageContentType) {\n' +
                            '        this.imageContentType = imageContentType;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory getProductCategory() {\n' +
                            '        return this.productCategory;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setProductCategory(ProductCategory productCategory) {\n' +
                            '        this.productCategory = productCategory;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Product productCategory(ProductCategory productCategory) {\n' +
                            '        this.setProductCategory(productCategory);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    // jhipster-needle-entity-add-getters-setters - JHipster will add getters and setters here\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public boolean equals(Object o) {\n' +
                            '        if (this == o) {\n' +
                            '            return true;\n' +
                            '        }\n' +
                            '        if (!(o instanceof Product)) {\n' +
                            '            return false;\n' +
                            '        }\n' +
                            '        return id != null && id.equals(((Product) o).id);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public int hashCode() {\n' +
                            '        // see https://vladmihalcea.com/how-to-implement-equals-and-hashcode-using-the-jpa-entity-identifier/\n' +
                            '        return getClass().hashCode();\n' +
                            '    }\n' +
                            '\n' +
                            '    // prettier-ignore\n' +
                            '    @Override\n' +
                            '    public String toString() {\n' +
                            '        return "Product{" +\n' +
                            '            "id=" + getId() +\n' +
                            '            ", name=\'" + getName() + "\'" +\n' +
                            '            ", description=\'" + getDescription() + "\'" +\n' +
                            '            ", price=" + getPrice() +\n' +
                            '            ", sizeProduct=\'" + getSizeProduct() + "\'" +\n' +
                            '            ", image=\'" + getImage() + "\'" +\n' +
                            '            ", imageContentType=\'" + getImageContentType() + "\'" +\n' +
                            '            "}";\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            adnotarea <i>@Schema</i> este folosita de Swagger pentru documentatie
                        </li>
                        <li>
                            adnotarea <b>@Enumerated</b> este utilizată pentru a mapa enumcâmpuri. Acestea sunt stocate ca varchar
                        </li>
                        <li>
                            Câmpul imagine este un blob și este marcat ca <b>@Lob</b>, deoarece folosim MySQL.
                            <br/>
                            Are, de asemenea, un câmp suplimentar pentru a păstra informațiile despre tipul de conținut
                            <SyntaxHighlighter>
                                {'@Column(name = "image_content_type")\n' +
                                    'private String imageContentType;'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            relatia cu categoria produsului:
                            <SyntaxHighlighter>
                                {'@ManyToOne\n' +
                                    '@JsonIgnoreProperties(value = { "products" }, allowSetters = true)\n' +
                                    'private ProductCategory productCategory;'}
                            </SyntaxHighlighter>
                            de cealalta partea a relatiei, in categorie:
                            <SyntaxHighlighter>
                                {'@OneToMany(mappedBy = "productCategory")\n' +
                                    '@Cache(usage = CacheConcurrencyStrategy.NONSTRICT_READ_WRITE)\n' +
                                    'private Set<Product> products = new HashSet<>();'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            setter fluent:
                            <SyntaxHighlighter>
                                {'public Product name(String name) {\n' +
                                    '    this.name = name;\n' +
                                    '    return this;\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            Metodele fluente pot fi dezactivate prin specificarea <b>noFluentMethod</b> pentru entitate în JDL.
                            Metodele fluente sunt utile, deoarece ne permit să folosim de exemplu:
                            <SyntaxHighlighter>
                                new Product().name("myProduct").price(10);
                            </SyntaxHighlighter>


                        </li>
                    </ul>

                    De exemplu, continutul fisierului <i>ProductCategory.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.domain;\n' +
                            '\n' +
                            'import com.fasterxml.jackson.annotation.JsonIgnoreProperties;\n' +
                            'import java.io.Serializable;\n' +
                            'import java.util.HashSet;\n' +
                            'import java.util.Set;\n' +
                            'import javax.persistence.*;\n' +
                            'import javax.validation.constraints.*;\n' +
                            'import org.hibernate.annotations.Cache;\n' +
                            'import org.hibernate.annotations.CacheConcurrencyStrategy;\n' +
                            '\n' +
                            '/**\n' +
                            ' * A ProductCategory.\n' +
                            ' */\n' +
                            '@Entity\n' +
                            '@Table(name = "product_category")\n' +
                            '@Cache(usage = CacheConcurrencyStrategy.READ_WRITE)\n' +
                            '@SuppressWarnings("common-java:DuplicatedBlocks")\n' +
                            'public class ProductCategory implements Serializable {\n' +
                            '\n' +
                            '    private static final long serialVersionUID = 1L;\n' +
                            '\n' +
                            '    @Id\n' +
                            '    @GeneratedValue(strategy = GenerationType.SEQUENCE, generator = "sequenceGenerator")\n' +
                            '    @SequenceGenerator(name = "sequenceGenerator")\n' +
                            '    @Column(name = "id")\n' +
                            '    private Long id;\n' +
                            '\n' +
                            '    @NotNull\n' +
                            '    @Column(name = "name", nullable = false)\n' +
                            '    private String name;\n' +
                            '\n' +
                            '    @Column(name = "description")\n' +
                            '    private String description;\n' +
                            '\n' +
                            '    @OneToMany(mappedBy = "productCategory")\n' +
                            '    @Cache(usage = CacheConcurrencyStrategy.READ_WRITE)\n' +
                            '    @JsonIgnoreProperties(value = { "productCategory" }, allowSetters = true)\n' +
                            '    private Set<Product> products = new HashSet<>();\n' +
                            '\n' +
                            '    // jhipster-needle-entity-add-field - JHipster will add fields here\n' +
                            '\n' +
                            '    public Long getId() {\n' +
                            '        return this.id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory id(Long id) {\n' +
                            '        this.setId(id);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setId(Long id) {\n' +
                            '        this.id = id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getName() {\n' +
                            '        return this.name;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory name(String name) {\n' +
                            '        this.setName(name);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setName(String name) {\n' +
                            '        this.name = name;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getDescription() {\n' +
                            '        return this.description;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory description(String description) {\n' +
                            '        this.setDescription(description);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setDescription(String description) {\n' +
                            '        this.description = description;\n' +
                            '    }\n' +
                            '\n' +
                            '    public Set<Product> getProducts() {\n' +
                            '        return this.products;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setProducts(Set<Product> products) {\n' +
                            '        if (this.products != null) {\n' +
                            '            this.products.forEach(i -> i.setProductCategory(null));\n' +
                            '        }\n' +
                            '        if (products != null) {\n' +
                            '            products.forEach(i -> i.setProductCategory(this));\n' +
                            '        }\n' +
                            '        this.products = products;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory products(Set<Product> products) {\n' +
                            '        this.setProducts(products);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory addProduct(Product product) {\n' +
                            '        this.products.add(product);\n' +
                            '        product.setProductCategory(this);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    public ProductCategory removeProduct(Product product) {\n' +
                            '        this.products.remove(product);\n' +
                            '        product.setProductCategory(null);\n' +
                            '        return this;\n' +
                            '    }\n' +
                            '\n' +
                            '    // jhipster-needle-entity-add-getters-setters - JHipster will add getters and setters here\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public boolean equals(Object o) {\n' +
                            '        if (this == o) {\n' +
                            '            return true;\n' +
                            '        }\n' +
                            '        if (!(o instanceof ProductCategory)) {\n' +
                            '            return false;\n' +
                            '        }\n' +
                            '        return id != null && id.equals(((ProductCategory) o).id);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public int hashCode() {\n' +
                            '        // see https://vladmihalcea.com/how-to-implement-equals-and-hashcode-using-the-jpa-entity-identifier/\n' +
                            '        return getClass().hashCode();\n' +
                            '    }\n' +
                            '\n' +
                            '    // prettier-ignore\n' +
                            '    @Override\n' +
                            '    public String toString() {\n' +
                            '        return "ProductCategory{" +\n' +
                            '            "id=" + getId() +\n' +
                            '            ", name=\'" + getName() + "\'" +\n' +
                            '            ", description=\'" + getDescription() + "\'" +\n' +
                            '            "}";\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratedCodeDomainJdlJHipsterContent;