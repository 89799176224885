import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class RealmsTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-realms", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Realms in fisier XML</b>
                    <br/>
                    <br/>

                    Tipuri de realm:
                    <ul>
                        <li>JDBCRealm (baza de date)</li>
                        <li>UserDatabaseRealm (fisier Excel, de exemplu)</li>
                        <li>MemoryRealm (simular cu baza de date, dar doar pentru testare)</li>
                        <li>LockoutRealm</li>
                        <li>JNDIRealm</li>
                    </ul>

                    Exemplu (din [tomcat]\conf\server.xml, sectiunea Engine):

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'  <Realm className="org.apache.catalina.realm.LockOutRealm">\n' +
                            '        <!-- This Realm uses the UserDatabase configured in the global JNDI\n' +
                            '             resources under the key "UserDatabase".  Any edits\n' +
                            '             that are performed against this UserDatabase are immediately\n' +
                            '             available for use by the Realm.  -->\n' +
                            '        <Realm className="org.apache.catalina.realm.UserDatabaseRealm"\n' +
                            '               resourceName="UserDatabase"/>\n' +
                            '      </Realm>'}
                    </SyntaxHighlighter>

                    Definirea JNDI cu numele <i>UserDatabase</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<GlobalNamingResources>\n' +
                            '    <!-- Editable user database that can also be used by\n' +
                            '         UserDatabaseRealm to authenticate users\n' +
                            '    -->\n' +
                            '    <Resource name="UserDatabase" auth="Container"\n' +
                            '              type="org.apache.catalina.UserDatabase"\n' +
                            '              description="User database that can be updated and saved"\n' +
                            '              factory="org.apache.catalina.users.MemoryUserDatabaseFactory"\n' +
                            '              pathname="conf/tomcat-users.xml" />\n' +
                            '  </GlobalNamingResources>\n'}
                    </SyntaxHighlighter>

                    In fisierul [tomcat]\conf\tomcat-users.xml, se definesc rolurile si utilizatorii.
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '\n' +
                            '<tomcat-users xmlns="http://tomcat.apache.org/xml"\n' +
                            '              xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '              xsi:schemaLocation="http://tomcat.apache.org/xml tomcat-users.xsd"\n' +
                            '              version="1.0">\n' +
                            '\n' +
                            '  <role rolename="tomcat"/>\n' +
                            '  <role rolename="role1"/>\n' +
                            '  <role rolename="supportkj"/>\n' +
                            '  <user username="kj" password="kj" roles="supportkj"/>\n' +
                            '  <user username="tomcat" password="admin" roles="tomcat"/>\n' +
                            '  <user username="both" password="admin" roles="tomcat,role1"/>\n' +
                            '  <user username="role1" password="admin" roles="role1"/>\n' +
                            '\n' +
                            '</tomcat-users>'}
                    </SyntaxHighlighter>

                    In aplicatia noastra, modificam [tomcat]\webapps\[aplicatie]\WEB-INF\web.xml:

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<web-app id="tomcat-demo" version="2.4"\n' +
                            '\txmlns="http://java.sun.com/xml/ns/j2ee"\n' +
                            '\txmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '\txsi:schemaLocation="http://java.sun.com/xml/ns/j2ee http://java.sun.com/xml/ns/j2ee/web-app_2_4.xsd">\n' +
                            '\n' +
                            '\n' +
                            '\t<security-constraint>\n' +
                            '\t\t<web-resource-collection>\n' +
                            '\t\t\t<web-resource-name>Wildcard means whole app requires authentication</web-resource-name>\n' +
                            '\t\t\t<url-pattern>/*</url-pattern>\n' +
                            '\t\t\t<http-method>GET</http-method>\n' +
                            '\t\t\t<http-method>POST</http-method>\n' +
                            '\t\t</web-resource-collection>\n' +
                            '\t\t<auth-constraint>\n' +
                            '\t\t\t<role-name>supportkj</role-name>\n' +
                            '\t\t</auth-constraint>\n' +
                            '\n' +
                            '\t\t<user-data-constraint>\n' +
                            '\t\t\t<!-- transport-guarantee can be CONFIDENTIAL, INTEGRAL, or NONE -->\n' +
                            '\t\t\t<transport-guarantee>NONE</transport-guarantee>\n' +
                            '\t\t</user-data-constraint>\n' +
                            '\t</security-constraint>\n' +
                            '\t\n' +
                            '\t  <security-role>\n' +
                            '    <description>\n' +
                            '      The role that is required to access kj app \n' +
                            '    </description>\n' +
                            '    <role-name>supportkj</role-name>\n' +
                            '  </security-role>\n' +
                            '\n' +
                            '\t<login-config>\n' +
                            '\t\t<auth-method>BASIC</auth-method>\n' +
                            '\t</login-config>\n' +
                            '\n' +
                            '</web-app>'}
                    </SyntaxHighlighter>

                    unde:
                    <ul>
                        <li>url-pattern: specifica url (/* - insemna ori url) pentru care se aplica regula de securitate</li>
                        <li>http-method: specifica pe ce metoda http sa se aplice regula se secuitate</li>
                        <li>role-name: specifica rolul pe care trebuie sa il aiba utilizatorul pentru a accesa resursa de pe url definit mai sus</li>
                        <li>auth-method: specifica modul de autentificare (<b>BASIC</b> printr-un popup; <b>FORM</b> printr-un ecran)</li>
                    </ul>

                    <b>2. Realms in baza de date</b>
                    <br/>
                    <br/>

                    Definire realm din [tomcat]\conf\server.xml, sectiunea Engine:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<JDBCRealm  className="org.apache.catalina.realm.JDBCRealm" \n' +
                            'driverName="com.mysql.jdbc.mysql.Driver" \n' +
                            'connectionURL="jdbc:mysql://localhost/authority" \n' +
                            'connectionName="root" \n' +
                            'connectionPassword="" \n' +
                            'userTable="users" \n' +
                            'userNameCol="user_name" \n' +
                            'userCredCol="user_pass" \n' +
                            'userRoleTable="user_roles" \n'  +
                            'roleNameCol="role_name" />'}
                    </SyntaxHighlighter>

                    In baza de date vom avem 2 tabele:
                    <SyntaxHighlighter  showLineNumbers={true} language="sql" style={androidstudio}>
                        {'create table users\n' +
                            '(\n' +
                            '  user_name varchar(15) not null primary key,\n' +
                            '  user_pass varchar(15) not null\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'create table user_roles\n' +
                            '(\n' +
                            '  user_name varchar(15) not null,\n' +
                            '  role_name varchar(15) not null,\n' +
                            '  primary key( user_name, role_name )\n' +
                            ');'}
                    </SyntaxHighlighter>

                    In [tomcat]\bin\ adaugam libraria cu conectorul de MySQL: <b>mysql-connector-java-8.0.25</b>

                    <br/>
                    <br/>

                    In aplicatia noastra, modificam [tomcat]\webapps\[aplicatie]\WEB-INF\web.xml (ramane la fel ca in sectiunea precedenta):

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<web-app id="tomcat-demo" version="2.4"\n' +
                            '\txmlns="http://java.sun.com/xml/ns/j2ee"\n' +
                            '\txmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '\txsi:schemaLocation="http://java.sun.com/xml/ns/j2ee http://java.sun.com/xml/ns/j2ee/web-app_2_4.xsd">\n' +
                            '\n' +
                            '\n' +
                            '\t<security-constraint>\n' +
                            '\t\t<web-resource-collection>\n' +
                            '\t\t\t<web-resource-name>Wildcard means whole app requires authentication</web-resource-name>\n' +
                            '\t\t\t<url-pattern>/*</url-pattern>\n' +
                            '\t\t\t<http-method>GET</http-method>\n' +
                            '\t\t\t<http-method>POST</http-method>\n' +
                            '\t\t</web-resource-collection>\n' +
                            '\t\t<auth-constraint>\n' +
                            '\t\t\t<role-name>supportkj</role-name>\n' +
                            '\t\t</auth-constraint>\n' +
                            '\n' +
                            '\t\t<user-data-constraint>\n' +
                            '\t\t\t<!-- transport-guarantee can be CONFIDENTIAL, INTEGRAL, or NONE -->\n' +
                            '\t\t\t<transport-guarantee>NONE</transport-guarantee>\n' +
                            '\t\t</user-data-constraint>\n' +
                            '\t</security-constraint>\n' +
                            '\t\n' +
                            '\t  <security-role>\n' +
                            '    <description>\n' +
                            '      The role that is required to access kj app \n' +
                            '    </description>\n' +
                            '    <role-name>supportkj</role-name>\n' +
                            '  </security-role>\n' +
                            '\n' +
                            '\t<login-config>\n' +
                            '\t\t<auth-method>BASIC</auth-method>\n' +
                            '\t</login-config>\n' +
                            '\n' +
                            '</web-app>'}
                    </SyntaxHighlighter>
                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RealmsTomcatContent;