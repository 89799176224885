import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlUncordonContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-uncordon", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl cordon</b>
                    <br/>
                    <br/>

                    De exemplu, avem 3 noduri workeri:
                    <ul>
                        <li>
                            worker1
                        </li>
                        <li>
                            worker2 (este marcat ca fiind <i>cordon</i>)
                        </li>
                        <li>
                            worker3
                        </li>
                    </ul>

                    Daca adauga un pod replicat de 3 ori, se va pune:
                    <ul>
                        <li>
                            2 pod pe worker1
                        </li>
                        <li>
                            1 pod pe worker3
                        </li>
                    </ul>
                    Practic se evita, sa se puna pe worker2

                    Pentru a marca nod ca este <i>programabil</i> (se pot adauga pod-uri in el):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl uncordon <nume-nod>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlUncordonContent;