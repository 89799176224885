import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class CdLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-cd", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a schimba directorul curent:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd <cale>'}
                    </SyntaxHighlighter>

                    exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd ./learn'}
                    </SyntaxHighlighter>

                    pentru a ne deplasa in directorul parinte:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd ..'}
                    </SyntaxHighlighter>

                    Scurtaturi:
                    <ul>
                        <li>
                            schimbare director curent la directorul <i>home</i>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            schimbare director curent la directorul anterior de lucru
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd - '}
                            </SyntaxHighlighter>
                            exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'kj@kj:~/learn$ pwd\n' +
                                    '/home/kj/learn\n' +
                                    'kj@kj:~/learn$ cd\n' +
                                    'kj@kj:~$ pwd\n' +
                                    '/home/kj\n' +
                                    'kj@kj:~$ cd -\n' +
                                    '/home/kj/learn'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            schimbare director curent la directorul anterior de lucru
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd ~<nume_utilizator>'}
                            </SyntaxHighlighter>
                            de exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd ~kj'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CdLinuxContent;