import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ClassesPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-classes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Clase</b>
                    <br/>
                    <br/>
                    <b>1. Definire si instantiere clase</b>
                    <br/>
                    <br/>
                    O <b>clasa</b> e un concept pe baza careia se poate da nastere unui obiect.
                    <br/>
                    O clasa poate deriva din alta alta clasa pe baza unei relatii numite <b>mosternire</b>.
                    Clasa parinte se numeste <b>superclasa</b>, iar copilul <b>subclasa</b>
                    (relatia de mosternire este reprezentata prin intermediul unui sageti, săgeți care sunt îndreptate de la subclasă către superclasa acesteia, ex: Caine {"->"} Animal)

                    <br/>
                    <br/>
                    Obiectele au:
                    <ul>
                        <li>nume</li>
                        <li>set de proprietati/atribute (optional)</li>
                        <li>set de metode (optional)</li>
                    </ul>

                    Pentru a <i>defini</i> o clasa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class NumeClasa:\n' +
                        '   pass'}
                    </SyntaxHighlighter>

                    Pentru a <i>instantia</i> o clasa, deci pentru a creea un obiect (se procedeaza ca si cum am apela o functie):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'obiect = NumeClasa()'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Construtori</b>
                    <br/>
                    <br/>
                    La instantierea unui clase/crearea unui obiect se apeleaza implicit o metoda, numita <b>constructor</b>:
                    <ul>
                        <li>numele ei este <b>__init__()</b></li>
                        <li>are cel putin un parametru, numit de obicei <b>self</b> (acest nume este o conventie), reprezentand noul obiectul care este creat</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Bau: \n' +
                        '    def __init__(self): \n' +
                        '        print("bau!")\n' +
                        '\n' +
                        '\n' +
                        'obiect = Bau() # bau! \n'}
                    </SyntaxHighlighter>

                    Constructorul:
                    <ul>
                        <li>este obligat să aibă parametrul <b>self</b></li>
                        <li>poate (dar nu este obligatoriu) să aibă mai mulți parametri</li>
                        <li>poate fi folosit pentru a configura obiectul (initializa stare interna, creea variabile de instanta, a instanția orice alte obiecte dacă este necesară existența lor, etc)</li>
                        <li>nu poate returna o valoare, deoarece este concepută pentru a returna obiectul nou creat și nimic altceva

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'class Rochie:\n' +
                                '    \n' +
                                '    def __init__(self):\n' +
                                '        return 1\n' +
                                '        \n' +
                                'r = Rochie()  # __init__() should return None, not \'int\''}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            nu trebuie sa fie invocat direct nici din obiect, nici din interiorul clasei, (desi se poate, vezi exemplu de mai jos); <br/>
                            puteți invoca un constructor doar din oricare dintre subclasele obiectului

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'class Rochie:\n' +
                                ' \n' +
                                '    def __init__(self):\n' +
                                '        print("__init__")\n' +
                                '        pass\n' +
                                '    \n' +
                                '    def metoda(self):\n' +
                                '        self.__init__()\n' +
                                '  \n' +
                                'r = Rochie() # __init__\n' +
                                'r.__init__() # __init__\n' +
                                'r.metoda()  # __init__'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>3. Proprietati</b>
                    <br/>
                    <br/>
                    <b>3.1. Proprietati publice (de instanta)</b>
                    <br/>
                    <br/>

                    <b>Orice modificare care se face în interiorul constructorului care modifică starea parametrului self va fi reflectată în obiectul nou creat.</b>&nbsp;
                    Aceasta înseamnă că se poate <b>adăuga orice proprietate la obiect</b> și proprietatea va rămâne acolo până când obiectul:
                    <ul>
                        <li>își termină ciclul de viața</li>
                        <li>proprietatea este eliminată în mod explicit</li>
                    </ul>
                    Prin urmare, <b>se pot creea sau sterge dinamic proprietati, iar un obiect de un anumit tip poate avea proprietati diferite</b>.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from random import randrange\n' +
                        '\n' +
                        'class Salut:\n' +
                        '    def __init__(self):\n' +
                        '        if randrange(0,2) % 2==0:\n' +
                        '            self.salut = "Buna!"\n' +
                        '        else:\n' +
                        '            self.fara_salut = "Nu te salut!"\n' +
                        '\n' +
                        '\n' +
                        'salut = Salut()\n' +
                        'try:\n' +
                        '    print(salut.salut)\n' +
                        '    print(salut.fara_salut)\n' +
                        'except AttributeError:\n' +
                        '    print("Una din proprietati nu exista!") # se arunca o exceptie de tip AttributeError '}
                    </SyntaxHighlighter>

                    Dar <b>proprietatile se pot creea</b> nu doar in constructori, ci <b>in orice moment al vietii unui obiect</b>. &nbsp;
                    Chiar si in afara clasei (vezi proprietatea <i>ignor</i>):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    def __init__(self):\n' +
                        '        self.salut = "Buna!"\n' +
                        '      \n' +
                        'salut = Salut()\n' +
                        'salut.ignor = "seen"'}
                    </SyntaxHighlighter>

                    <br/>
                    Genul de proprietati prezentate mai sus se numesc <b>variabile de instanta</b>.
                    Avand in vedere, caracterul dinamic al proprietatilor pe un obiect, Python ofera un mecanism de gestionarea/verificare a ce proprietati are un obiect in orice moment.
                    <br/>
                    Obiectele Python, atunci când sunt create, sunt dotate cu un set de proprietăți și metode predefinite:
                    <ul>
                        <li>variabila <b>__dict__</b> de tip dictionar: conține numele și valorile tuturor proprietăților (variabilelor) pe care obiectul le are la un moment dat

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from random import randrange\n' +
                                '\n' +
                                'class Salut:\n' +
                                '    def __init__(self):\n' +
                                '        if randrange(0,2) % 2==0:\n' +
                                '            self.salut = "Buna!"\n' +
                                '        else:\n' +
                                '            self.fara_salut = "Nu te salut!"\n' +
                                '\n' +
                                '\n' +
                                'salut = Salut()\n' +
                                'print(salut.__dict__) # la rulari diferite, poate afisa fie: {\'fara_salut\': \'Nu te salut!\'}, fie: {\'salut\': \'Buna!\'}'}
                            </SyntaxHighlighter>

                            O sa vedem, mai jos, ca in cazul variabilelor de instanta private, numele este afisat sub forma: <b>_NumeClasaNumeVariabila</b> (ex: _Salut__salut).

                        </li>
                    </ul>

                    Python, vine cu functia <b>hasattr()</b>, care verifica daca un obiect/clasa are sau contine o proprietate si primeste 2 parametri:
                    <ul>
                        <li>obiect/clasa</li>
                        <li>numele atributului (proprietatii) ca string</li>
                    </ul>
                    Functia returneaza True sau False (in fucntie de rezultat)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    contor = 0\n' +
                        '    def __init__(self):\n' +
                        '        self.a=1\n' +
                        '\n' +
                        's = Salut()\n' +
                        '\n' +
                        'print(hasattr(s,\'a\')) # True\n' +
                        'print(hasattr(s,\'b\')) # False\n' +
                        'print(hasattr(s,\'contor\')) # True (deci variabila de clasa este proprietatea obiectului - mai multe detalii mai jos, legate de variabile de clasa)\n' +
                        '\n' +
                        'print(hasattr(Salut,\'a\')) # False\n' +
                        'print(hasattr(Salut,\'b\')) # False\n' +
                        'print(hasattr(Salut,\'contor\')) # True'}
                    </SyntaxHighlighter>


                    Variabilele de instanță sunt <b>izolate</b> de la un obiect la altul.

                    <hr/>

                    <b>3.2. Proprietati private (de instanta)</b>
                    <br/>
                    <br/>
                    Pentru ca marca ca o proprietate este <b>privata</b> (este valabil si pentru metode) aceasta trebuie sa inceapa cu <b>cu două litere de subliniere (__)</b>.
                    Aceasta inseamna ca poate fi accesata numai din interiorul clasei (nu si din afara clasei). Asta este modul in care Python implementeaza conceptul de incapsulare.
                    <br/>
                    <br/>
                    <b>Defintie:</b>
                    <br/>
                    <b>încapsulare</b> = capacitatea de a ascunde (proteja) valorile selectate împotriva accesului neautorizat; valorile încapsulate nu pot fi nici accesate, nici modificate dacă doriți să le folosiți exclusiv;

                    <br/>
                    <br/>
                    Daca se incearca accesarea proprietatii private din afara clasei se va arunca o exceptie de tipul <b>AttributeError</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    def __init__(self):\n' +
                        '        self.__salut = "Buna!"\n' +
                        '       \n' +
                        'salut = Salut()\n' +
                        'print(salut.salut) # AttributeError: \'Salut\' object has no attribute \'salut\''}
                    </SyntaxHighlighter>

                    Dar putem sa o vizualizam orice proprietate privata prin intemediul variabilei <b>__dict__</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    def __init__(self):\n' +
                        '        self.__salut = "Buna!"\n' +
                        '       \n' +
                        'salut = Salut()\n' +
                        '\n' +
                        'print(salut.__dict__) # {\'_Salut__salut\': \'Buna!\'}\n'}
                    </SyntaxHighlighter>
                    Precum se vede o variabila privata are prefixat numele cu: <b>"_"+NumeClasa</b>.
                    <br/>
                    <br/>
                    Daca incercam sa accesam, <b>variabila privata</b>, prefixand numele cu <b>"_"+NumeClasa</b>, atunci aceasta <b>devine accesibila si din afara clasei! (fara erori sau exceptii)</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    def __init__(self):\n' +
                        '        self.__salut = "Buna!"\n' +
                        '       \n' +
                        'salut = Salut()\n' +
                        '\n' +
                        'print(salut.__dict__) # {\'_Salut__salut\': \'Buna!\'}\n' +
                        'print(salut._Salut__salut) # Buna!'}
                    </SyntaxHighlighter>

                    <b>Adaugarea de proprietati din afara clasei este limitata: nu se pot adauga propritati de instanta private</b> (chiar daca inceape cu <b>cu două litere de subliniere (__)</b> - se va comporta ca orice alta proprietate obișnuită).

                    <br/><br/>
                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    pass\n' +
                        '      \n' +
                        'salut = Salut()\n' +
                        'salut.__salut = "seen"\n' +
                        'print(salut.__salut) # seen\n' +
                        'print(salut.__dict__) # {\'__salut\': \'seen\'} / de aici vedem ca de fapt __salut nu este proprietate privata'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3.3. Proprietati/variabile de clasa</b>
                    <br/>
                    <br/>
                    O <b>variabilă de clasă</b> este o proprietate care :
                    <ul>
                        <li>există într-o singură copie</li>
                        <li>este stocată în afara oricărui obiect (daca o variabila de instanta nu poate exista daca nu exista un obiect a unei clase, o variabila de clasa exista chiar daca nu exista obiecte ale clasei)</li>
                        <li>este <b>share-uita de toate instantele</b></li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    contor = 0\n' +
                        '    def __init__(self):\n' +
                        '        Salut.contor=Salut.contor+1\n' +
                        '\n' +
                        's1 = Salut()\n' +
                        's2 = Salut()\n' +
                        '\n' +
                        'print(s1.__dict__) # {}\n' +
                        'print(Salut.contor) # 2'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:

                    <ul>
                        <li> o <b>variabila de clasa</b>, <b>nu exista</b> in dictionarul <b>__dict__</b> (deoarece variabilele de clasă nu sunt părți ale unui obiect)</li>
                        <li>are aceeași valoare în toate instanțele de clasă (obiecte) (deoarece este share-uita de toate instantele)</li>
                    </ul>

                    <b>O variabila de clasa</b> poate fi <b>privata</b> daca numele ei inceapa cu <b>cu două litere de subliniere (__)</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    __contor = 0\n' +
                        '    def __init__(self):\n' +
                        '        Salut.__contor=Salut.__contor+1\n' +
                        '\n' +
                        's1 = Salut()\n' +
                        's2 = Salut()\n' +
                        '\n' +
                        'print(s1.__dict__) # {}\n' +
                        'print(Salut.contor) # AttributeError: type object \'Salut\' has no attribute \'contor\''}
                    </SyntaxHighlighter>
                    Desigur, poate fi accesata asa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    __contor = 0\n' +
                        '    def __init__(self):\n' +
                        '        Salut.__contor=Salut.__contor+1\n' +
                        '\n' +
                        's1 = Salut()\n' +
                        's2 = Salut()\n' +
                        '\n' +
                        'print(s1.__dict__) # {}\n' +
                        'print(Salut._Salut__contor) # 2'}
                    </SyntaxHighlighter>

                    Exista o variabila de clasa implicita __dict__ (diferia de cea de instanta cu acelasi nume):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    __contor = 1\n' +
                        '\n' +
                        's = Salut()\n' +
                        'print(s.__dict__) # {}\n' +
                        'print(Salut.__dict__) # {\'__module__\': \'__main__\', \'_Salut__contor\': 1, \'__dict__\': <attribute \'__dict__\' of \'Salut\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'Salut\' objects>, \'__doc__\': None}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3.4. Variabile locale</b>
                    <br/>
                    <br/>

                    Variable locale sunt variabile definite in metode, dar care nu sunt variabile de instanta (deci nu vor apare in dictionarul <b>__dict__</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Salut:\n' +
                        '    vc=3\n' +
                        '    def __init__(self):\n' +
                        '        self.vi = 1\n' +
                        '        vi=2\n' +
                        '      \n' +
                        's1 = Salut()\n' +
                        's2 = Salut()\n' +
                        '\n' +
                        'print(s1.__dict__) # {\'vi\': 1}\n' +
                        'print(Salut.__dict__) # e gol: <method \'__dir__\' of \'object\' objects>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Metode</b>
                    <br/>
                    <br/>
                    Metodele sunt functii din interiorul clasei. O <b>metoda publica</b> trebuie sa indeplineasca urmatoarele reguli:
                    <ul>
                        <li>numele nu trebuie sa inceapa cu două (sau mai multe) liniuțe de subliniere</li>
                        <li>numele nu trebuie să aibă mai mult de o liniuță de subliniere finală</li>
                        <li>au un parametru numit <b>self</b><i> (conventie)</i> în prima poziție a listei de parametri;
                            <br/>
                            acest parametru permite metodei să acceseze entități (proprietăți si metode) ale obiectului propriu-zis; <br/>
                            <b>nu se poate omite</b>  (de fiecare dată când Python invocă o metodă, trimite implicit obiectul curent ca prim argument)<br/>
                            <br/>
                            deci, <b>orice metodă este obligată să aibă cel puțin un parametru, care este folosit de Python însuși</b>&nbsp;
                            (chiar dacă metoda nu are nevoie de parametri, acesta trebuie specificat oricum)
                        </li>
                    </ul>

                    <b>Metodele private</b> incep cu două (sau mai multe) liniuțe de subliniere.

                    <b>Observatii</b>:
                    <br/>
                    <ul>
                        <li>invocarea unei metode (inclusiv constructori) <b>din afara clasei <i>nu</i> necesită niciodată să puneți argumentul <i>self</i> în lista de argumente ( ex: object.metoda())</b></li>
                        <li>invocarea unei metode <b>din interiorul clasei necesită utilizarea explicită a argumentului <i>self</i> ( ex: self.metoda()) </b> </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def __init__(self):\n' +
                        '        self.x=1    \n' +
                        '        pass\n' +
                        '    \n' +
                        '    def test_1(self):\n' +
                        '        print("test_1")\n' +
                        '        \n' +
                        '    def test_2(self):\n' +
                        '        # test_1() # NameError: name \'test_1\' is not defined\n' +
                        '        # self.test_1(self) # TypeError: test_1() takes 1 positional argument but 2 were given\n' +
                        '        self.test_1()\n' +
                        '        print("test_2")\n' +
                        '   \n' +
                        '   \n' +
                        'a = A()\n' +
                        'a.test_2()'}
                    </SyntaxHighlighter>

                    Parametrul <b>self</b> este utilizat pentru a obține acces la:
                    <ul>
                        <li>variabile de instanță</li>
                        <li>variabile de clasa</li>
                        <li>invoca alte metode obiect/clasă din interiorul clasei</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Rochie:\n' +
                        '    culoare = "alba"\n' +
                        '   \n' +
                        '    def __init__(self):\n' +
                        '        self.material="stofa"\n' +
                        '        \n' +
                        '    def defileaza(self):\n' +
                        '        self.printeaza()\n' +
                        '\n' +
                        '    def printeaza(self):\n' +
                        '        print("Ea are o rochie",self.culoare,"din", self.material) # Ea are o rochie alba din stofa\n' +
                        '        \n' +
                        'r = Rochie()\n' +
                        'r.defileaza()'}
                    </SyntaxHighlighter>

                    Folosind variabila <b>__dict__</b> pe clasa se pot obtine informatii legate de metodele clasei.
                    <br/> De exemplu, pentru clasa de mai sus (Rochie)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(Rochie.__dict__)'}
                    </SyntaxHighlighter>

                    va afisa (afisarea este formata pentru a fi citit mai usor):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'{\n' +
                        '   "__module__":"__main__",\n' +
                        '   "culoare":"alba",\n' +
                        '   "__init__":<function Rochie.__init__ at 0x7fb2f8202320>,\n' +
                        '   "defileaza":<function Rochie.defileaza at 0x7fb2f82023b0>,\n' +
                        '   "printeaza":<function Rochie.printeaza at 0x7fb2f8202440>,\n' +
                        '   "__dict__":"<attribute""__dict__""of""Rochie""objects>",\n' +
                        '   "__weakref__":"<attribute""__weakref__""of""Rochie""objects>",\n' +
                        '   "__doc__":"None"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <b>Metodele private</b> pot fi invocate din afara clasei:
                    <ul>
                        <li>direct, si se obtine <b>eroare</b></li>
                        <li>folosind <b>deformarea numelui (name mangling )</b>, similar ca la proprietati (<b>_NumeClasa__numeMetoda()</b>)</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Gand:\n' +
                        '    def verbalizat(self):\n' +
                        '        print("verbalizat")\n' +
                        '    \n' +
                        '    def __ascuns(self):\n' +
                        '        print("ascuns")\n' +
                        '\n' +
                        '\n' +
                        'g = Gand()\n' +
                        'g.verbalizat()\n' +
                        '\n' +
                        'try:\n' +
                        '    g.__ascuns()\n' +
                        'except:\n' +
                        '    print("eroare")\n' +
                        '\n' +
                        'g._Gand__ascuns()'}
                    </SyntaxHighlighter>



                    <hr/>
                    <b>5. Mosternire</b>
                    <br/>
                    O clasa B poate mosternii o clasa A. Alfel spus, subclasa B poate mosternii supraclasa A.
                    Acest lucru se poate face folosind urmatoarea sintaxa:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class B(A):'}
                    </SyntaxHighlighter>

                    Clasa B, chiar daca nu definiste nici o entitate (proprietate sau metoda), <b>va mosternii entitatile</b> de la supraclasa A.

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def __init__(self):\n' +
                        '       pass\n' +
                        '    \n' +
                        '    def test(self):\n' +
                        '        print("test")\n' +
                        '   \n' +
                        '   \n' +
                        'class B(A):\n' +
                        '    pass\n' +
                        '\n' +
                        'b = B()\n' +
                        'b.test() # test'}
                    </SyntaxHighlighter>

                    Spre deosebire de multe alte limbaje, in Python <b>este obligatoriu invocarea în mod explicit a constructorului unei superclase</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'SupraClasa.__init__(self)'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def __init__(self):\n' +
                        '       pass\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    def __init__(self):\n' +
                        '        A.__init__(self)\n' +
                        '        pass'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    In general, o practică recomandată să <b>invocați constructorul superclasei înainte de orice alte inițializări</b> pe care doriți să le efectuați în interiorul subclasei.

                    <br/>
                    <br/>
                    <b>Daca se omite, atunci in subclasa nu vor exista entitatile definite in suprasclasa</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def __init__(self):\n' +
                        '        self.x=1    \n' +
                        '        pass\n' +
                        '    \n' +
                        '    def test(self):\n' +
                        '        print("test")\n' +
                        '   \n' +
                        '   \n' +
                        'class B(A):\n' +
                        '    def __init__(self):\n' +
                        '        # A.__init__(self)\n' +
                        '        pass\n' +
                        '        \n' +
                        '    def afiseazaX(self):\n' +
                        '        print(self.x)\n' +
                        '\n' +
                        'b = B()\n' +
                        'b.afiseazaX() # AttributeError: \'B\' object has no attribute \'x\''}
                    </SyntaxHighlighter>

                    <b>Observatii:</b>
                    <ul>
                        <li><i>Proprietatea de clasa</i> build-in <b>__name__</b> care retine <b>numele clasei</b>.</li>
                        <li>Funcția <b>type()</b> este capabilă să găsească clasa care a fost folosită pentru a instanția un obiect.</li>
                        <li><i>Proprietatea de clasa/obiect</i> build-in <b>__module__</b> stochează numele modulului care conține definiția clasei</li>
                        <li><i>Proprietatea de clasa</i> build-in <b>__bases__</b> stocheaza tuplul conține clase (nu nume de clasă) care sunt superclase directe pentru clasă
                            <ul>
                                <li>ordinea este aceeași cu cea folosită în definiția clasei.</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'class A:\n' +
                                '    pass\n' +
                                '\n' +
                                '\n' +
                                'class B:\n' +
                                '    pass\n' +
                                '\n' +
                                '\n' +
                                'class C(A, B):\n' +
                                '    pass\n' +
                                '\n' +
                                'print(A.__bases__) # (<class \'object\'>,)\n' +
                                'print(B.__bases__) # (<class \'object\'>,)\n' +
                                'print(C.__bases__) # (<class \'__main__.A\'>, <class \'__main__.B\'>)'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Urs:\n' +
                        '    pass\n' +
                        '\n' +
                        'print(Urs.__name__) # Urs\n' +
                        'urs = Urs() \n' +
                        'print(type(urs).__name__) # Urs\n' +
                        'print(Urs.__module__) # __main__, __main_ nu este de fapt un modul, ci fișierul aflat în curs de rulare.\n' +
                        'print(urs.__module__) # __main__, __main_ nu este de fapt un modul, ci fișierul aflat în curs de rulare.'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Suprascriere</b>
                    <br/>
                    <br/>
                    Subclasa poate sa <b>suprascrie</b> metode din supraclasa ( pentru a schimba functionalitatii, pastrand numele metodei).
                    <br/>
                    De asemenea se poate (ca si in cazul constructorului) apelarea metodei din supraclasa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'SupraClasa.metoda(self)'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '  \n' +
                        '    def test(self):\n' +
                        '        print("TEST din A")\n' +
                        '   \n' +
                        '   \n' +
                        'class B(A):\n' +
                        '   \n' +
                        '    def test(self):\n' +
                        '        A.test(self)\n' +
                        '        print("TEST din B")\n' +
                        '        A.test(self)\n' +
                        '\n' +
                        'b = B()\n' +
                        'b.test() '}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'TEST din A\n' +
                        'TEST din B\n' +
                        'TEST din A'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>Experiment 1</b> (cu aceleasi rezultate ca mai sus): cu trimitere clasa A ca parametru metodei test() din clasa B

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '  \n' +
                        '    def test(self):\n' +
                        '        print("TEST din A")\n' +
                        '   \n' +
                        '   \n' +
                        'class B():\n' +
                        '   \n' +
                        '    def test(self, A):\n' +
                        '        A.test(self)\n' +
                        '        print("TEST din B")\n' +
                        '        A.test(self)\n' +
                        '\n' +
                        'b = B()\n' +
                        'b.test(A) '}
                    </SyntaxHighlighter>

                    <br/>
                    <b>Experiment 2</b> (cu aceleasi rezultate ca mai sus): cu trimitere clasa A ca parametru contructorului clasei B test:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '  \n' +
                        '    def test(self):\n' +
                        '        print("TEST din A")\n' +
                        '   \n' +
                        '   \n' +
                        'class B():\n' +
                        '   \n' +
                        '    def __init__(self, A):\n' +
                        '        self.A=A\n' +
                        '        \n' +
                        '    def test(self):\n' +
                        '        A.test(self)\n' +
                        '        print("TEST din B")\n' +
                        '        A.test(self)\n' +
                        '\n' +
                        'b = B(A)\n' +
                        'b.test() '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Introspectie / Reflecie</b>
                    <br/>
                    <br/>

                    <b>Definitii</b>
                    <ul>
                        <li><b>introspecție</b> = capacitatea unui program de a examina tipul sau proprietățile unui obiect în timpul rulării</li>
                        <li><b>reflecția</b> = capacitatea unui program de a manipula valorile, proprietățile și/sau funcțiile unui obiect în timpul execuției.</li>
                    </ul>

                    In acest sens se pot folosi urmaorele functii:
                    <ul>
                        <li><b>getattr(obiect, proprietate)</b>: returneaza valoarea pentru o proprietate de pe un anumit obiect</li>
                        <li><b>isinstance(valoare, tip)</b>: verifica daca o valoare este de un anumit tip</li>
                        <li><b>setattr(obiect, proprietate, valoare)</b>: seteaza o valoare pentru o proprietate de pe un anumit obiect</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Urs:\n' +
                        '    ani=10\n' +
                        '    pass\n' +
                        '\n' +
                        'urs = Urs()\n' +
                        'print(urs.ani) # 10\n' +
                        'print(isinstance(urs.ani, int)) # True\n' +
                        'a = getattr(urs, \'ani\') \n' +
                        'setattr(urs, \'ani\',a*2) \n' +
                        'print(urs.ani) # 20'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClassesPythonContent;