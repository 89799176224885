import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class InitContainerK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-initcontainer", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. InitContainer</b>
                    <br/>
                    <br/>

                    Dacă dorim să avem un container/proces care să se execute înainte de aplicația principală (de exemplu:
                    un proces care așteaptă ca un serviciu extern sau o bază de date să fie activă) vom folosi <b>initContainers</b>.
                    <br/>
                    Containerul definit in <b>initContainers</b> va fi executat o singură dată când podul este creat pentru prima dată.

                    <br/>
                    <br/>
                    Un <b>initContainer</b> este configurat într-un pod ca toate celelalte containere, cu excepția faptului că este specificat în interiorul unei sectiuni <b>initContainers</b>, astfel:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: init-tester\n' +
                            '  labels:\n' +
                            '    app: inittest\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: webservice\n' +
                            '    image: nginx\n' +
                            '  initContainers:\n' +
                            '  - name: failed\n' +
                            '    image: busybox\n' +
                            '    command: [/bin/false]'}
                    </SyntaxHighlighter>

                    Când un POD este creat pentru prima dată, initContainer este rulat,
                    iar procesul din initContainer trebuie să ruleze până la finalizare înainte de a începe containerul real care găzduiește aplicația.
                    <br/>
                    Se pot configura mai multe astfel de initContainers. Fiecare container init este rulat pe rând, în ordine secvențială.
                    <br/>
                    Dacă oricare dintre initContainers nu se finalizează, Kubernetes repornește Pod-ul în mod repetat până când Init Container reușește.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: myapp-pod\n' +
                            '  labels:\n' +
                            '    app: myapp\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: myapp-container\n' +
                            '    image: busybox:1.28\n' +
                            '    command: [\'sh\', \'-c\', \'echo The app is running! && sleep 3600\']\n' +
                            '  initContainers:\n' +
                            '  - name: init-myservice\n' +
                            '    image: busybox:1.28\n' +
                            '    command: [\'sh\', \'-c\', \'until nslookup myservice; do echo waiting for myservice; sleep 2; done;\']\n' +
                            '  - name: init-mydb\n' +
                            '    image: busybox:1.28\n' +
                            '    command: [\'sh\', \'-c\', \'until nslookup mydb; do echo waiting for mydb; sleep 2; done;\']'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pod'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'AME                   READY   STATUS       RESTARTS      AGE\n' +
                            'init-tester            0/1     Init:Error   2 (19s ago)   24s\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe pod init-tester'}
                    </SyntaxHighlighter>
                    poate afisa (fragment):
                    <SyntaxHighlighter>
                        {'Containers:\n' +
                            '  webservice:\n' +
                            '    Container ID:\n' +
                            '    Image:          nginx\n' +
                            '    Image ID:\n' +
                            '    Port:           <none>\n' +
                            '    Host Port:      <none>\n' +
                            '    State:          Waiting\n' +
                            '      Reason:       PodInitializing\n' +
                            '    Ready:          False'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca se va modifica in pod:  command: [/bin/false] {"==>"} command: [/bin/true], atunci pod va porni cu succes!



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InitContainerK8sContent;