import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LiteralsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-literals", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Literali</b>

                    <br/>
                    <br/>

                    In Python, forma literalului determină reprezentarea (tipul) pe care Python o va folosi pentru a-l stoca în memorie.
                    Literali in Python:
                    <ul>
                        <li><b>Numere întregi:</b>
                            <ul>
                                <li>zecimale
                                    <ul>
                                        <li>12341 sau 12_341 (varianta cu _ (<b>underscore</b>) e incepand cu Python 3.6, si are ca scop imbunatatirea lizibilitatii</li>
                                    </ul>
                                    <ul>
                                        <li>-12341 sau -12_341</li>
                                    </ul>
                                    <ul>
                                        <li>+12341 sau +12_341</li>
                                    </ul>
                                </li>
                                <li>binare (precedat de <b>0s</b> sau <b>0S</b>, si se permise doar cifre intre 0 si 1)
                                    <ul>
                                        <li>0b1010, in zecimal e numarul 10</li>
                                    </ul>
                                </li>

                                <li>octal (precedat de <b>0o</b> sau <b>00</b>, si se permise doar cifre intre 0 si 7)
                                    <ul>
                                        <li>0o50, in zecimal e numarul 40</li>
                                    </ul>
                                </li>
                                <li>hexazecimal (precedat de <b>0x</b> sau <b>0X</b>, si se permise cifrele intre 0 si 9, plus a,b,c,d,e,f)
                                    <ul>
                                        <li>0x50, in zecimal e numarul 80</li>
                                        <li>0x5a, in zecimal e numarul 90</li>
                                    </ul>

                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>Numere reale (in virgula mobila / float)</b>
                            <ul>
                                <li>7.5</li>
                                <li>0.5 sau .5</li>
                                <li>7.0 sau 7.</li>
                                <li>
                                    <b>notatie stiintifica</b>:
                                    <br/>
                                    2E5 sau 2e5 (e este exponent, deci 2e5 = 2 x 10^5)
                                    <br/>
                                    <b>observatie</b>:
                                    <ul>
                                        <li>2 se numeste <b>baza</b>; <b>baza trebuie sa fie numar</b></li>
                                        <li>5 se numeste <b>exponent</b>; <b>exponentul trebuie sa fie numar</b></li>
                                    </ul>
                                    2E-5 sau 2e-5
                                    <br/>
                                    2.3E-5 sau 2.3e-5
                                </li>
                            </ul>

                            Python, va alege sa afiseze numere in virgula mobila, intr-o forma mai economica:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(0.00000000000000000001)\n' +
                                '1e-20'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>String</b> (sir de caractere): sir de caractere incadrat intre ghilimele sau apostroafe:
                            <ul>
                                <li>"Buna, aici KJ"</li>
                                <li>"KJ zice:\"Buna!\""</li>
                                <li>'KJ zice:"Buna!"'</li>
                                <li>"KJ zice:'Buna!'"</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("Buna, aici KJ")\n' +
                                'Buna, aici KJ'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("KJ zice:\\"Buna!\\"")\n' +
                                'KJ zice:"Buna"'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(\'KJ zice:"Buna!"\')\n' +
                                'KJ zice:"Buna"'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("KJ zice:\'Buna!\'")\n' +
                                'KJ zice:\'Buna!\''}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>Boolean</b> (cele 2 valori sunt <b>case-sensitive</b>)
                            <ul>
                                <li>True</li>
                                <li>False</li>
                            </ul>
                        </li>
                        <li>
                            <b>Absenta unei valori</b>
                            <ul>
                                <li>None</li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LiteralsPythonContent;