import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ServicesByContentReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-service-by-content", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Servicii folosind Content</b>
                    <br/>
                    <br/>

                    <b>Context</b> poate fi folosit pentru partaja o colectie de servicii, prin arborele de componente.

                    <br/>
                    Un <b>serviciu</b> este doar un grup de funcții de ajutor care se ocupă de ceva în mod special și pot fi reutilizate în aplicații, de exemplu:

                    <ul>
                        <li>
                            Serviciu de autentificare care include verificarea stării de autentificare, conectare, deconectare, obținerea de informații despre utilizator...
                        </li>
                        <li>
                            Serviciu HTTP care gestionează antetul solicitării, metoda solicitării, corpul solicitării...
                        </li>
                    </ul>

                    Un serviciu este o clasă obișnuită JavaScript, nimic legat de React.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default class ApiService {\n' +
                            '  constructor() {}\n' +
                            '  _setInterceptors() {}\n' +
                            '  _handleResponse_() {}\n' +
                            '  _handleError_() {}\n' +
                            '  get() {}\n' +
                            '  post() {}\n' +
                            '  put() {}\n' +
                            '  delete() {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default class AuthService {\n' +
                            '  constructor() {}\n' +
                            '  isAuthenticated() {}\n' +
                            '  getUserInfo() {}\n' +
                            '  signIn() {}\n' +
                            '  signOut() {}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Creare <b>Context</b> si transmiterea serviciilor prin <i>props</i>-uri:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'import { createContext } from \'react\';\n' +
                            'import { ApiService, AuthService } from \'@services\';\n' +
                            'const services = {\n' +
                            '  apiService: new ApiService(),\n' +
                            '  authService: new AuthService()\n' +
                            '};\n' +
                            'const AppContext = createContext();\n' +
                            'const { Provider } = AppContext;\n' +
                            'const AppProvider = ({ children }) => {\n' +
                            '  return <Provider value={{ services }}>{children}</Provider>;\n' +
                            '};\n' +
                            'export { AppContext, AppProvider }'
                        }
                    </SyntaxHighlighter>

                    Folosire context:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { AppProvider } from \'./context/AppContext\';\n' +
                            'import ComponentA from \'./components/ComponentA\'\n' +
                            'const App = () => {\n' +
                            '  return (\n' +
                            '    <AppProvider>\n' +
                            '      <div className="app">\n' +
                            '        <ComponentA />\n' +
                            '      </div>\n' +
                            '    </AppProvider>\n' +
                            '  );\n' +
                            '};\n' +
                            'export default App;'}
                    </SyntaxHighlighter>

                    În interiorul unei componente copil, se utilizeaza hook-ul <b>useContext</b> pentru a accesa servicii din context:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useContext, useEffect } from \'react\';\n' +
                            'import { AppContext } from \'../context/AppContext\';\n' +
                            'const ChildComponent = () => {\n' +
                            '  const { \n' +
                            '    services: { \n' +
                            '      apiService,\n' +
                            '      authService\n' +
                            '    } \n' +
                            '  } = useContext(AppContext);\n' +
                            '  useEffect(() => {\n' +
                            '    if (authService.isAuthenticated()) {\n' +
                            '      apiService.get();\n' +
                            '    }\n' +
                            '  }, []);\n' +
                            '  return <></>;\n' +
                            '}\n' +
                            'export default ComponentA;'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alternative la servicii in context:
                    <ul>
                        <li>hook</li>
                        <li>clase/functii singleton</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.supremetech.vn/a-better-way-to-use-services-in-react-application/"}>
                               A Better Way To Use Services In React Application
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServicesByContentReactContent;