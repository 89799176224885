import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class NuxeoCLINuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-nuxeo-cli", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Prerechizite</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'NodeJS'}
                    </SyntaxHighlighter>

                    <b>2. Instalare</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'npm install -g nuxeo-cli'}
                    </SyntaxHighlighter>

                    <b>3. Generare <i>sample</i> project</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo sample'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo sample\n' +
                        '\n' +
                        'dxxxxxxxxxxc    oxxo       lxxx lkkl       ;kkk\n' +
                        'dxxxxxxxxxxxd;  oxxo       lxxx lkkkx:.  ,dkkkx\n' +
                        'dxxc       lxxo oxxo       lxxx  "okkkkokkkkd,\n' +
                        'dxxc       lxxo oxxo       lxxx    .dkkkkkk.                  Welcome to\n' +
                        'dxxc       lxxo oxxo       lxxx   ,dkkkkkkkk,                     Nuxeo CLI\n' +
                        'dxxc       lxxo "oxxcccccccdxxx ,kkkkx" "okkkk,\n' +
                        'loo;       :ooc   "cooooooooool xkko       ckko\n' +
                        '\n' +
                        ':cc,       ;cc;                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        '"cxxoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '   xoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '\n' +
                        'lkkl       ;kkk oxxxxxxxxxxxxxo xooooooooooo,\n' +
                        'lkkkx:.  ,dkkkx oxxxxxxxxxxxxxo lxxxxxxxxxxxxb;\n' +
                        ' "okkkkokkkkd,  oxxo            lxxd       :xxx\n' +
                        '   .dkkkkkk.    oxxxxxxxxxxxxxo lxxd       :xxx\n' +
                        '  ,dkkkkkkkk,   oxxo            cxxd       :xxx\n' +
                        ',kkkkx" "okkkk, oxxxxxxxxxxxxxo  "oxxxxxxxxxxxx\n' +
                        'xkko       ckko oxxxxxxxxxxxxxo    :xxxxxxxxxxx\n' +
                        '\n' +
                        '\n' +
                        '[@octokit/rest] `const Octokit = require("@octokit/rest")` is deprecated. Use `const { Octokit } = require("@octokit/rest")` instead\n' +
                        '? Which sample do you want? Blob Provider\n' +
                        '? Which release do you want? master\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\pom.xml\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\src\\main\\java\\org\\nuxeo\\sample\\blobprovider\\SampleBlobProvider.java\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\src\\main\\resources\\META-INF\\MANIFEST.MF\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\src\\test\\java\\org\\nuxeo\\sample\\blobprovider\\TestSampleBlobProvider.java\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\src\\test\\resources\\blobprovider-test.xml\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\src\\test\\resources\\jndi.properties\n' +
                        '   create nuxeo-blobprovider-sample-master\\nuxeo-blobprovider-sample-core\\src\\test\\resources\\log4j.xml\n' +
                        '   create nuxeo-blobprovider-sample-master\\pom.xml\n' +
                        '   create nuxeo-blobprovider-sample-master\\README.md\n' +
                        '     info You can start running the sample in nuxeo-blobprovider-sample-master'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>4. Generare project multi-module</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo bootstrap multi-module'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo bootstrap multi-module\n' +
                        '\n' +
                        'dxxxxxxxxxxc    oxxo       lxxx lkkl       ;kkk\n' +
                        'dxxxxxxxxxxxd;  oxxo       lxxx lkkkx:.  ,dkkkx\n' +
                        'dxxc       lxxo oxxo       lxxx  "okkkkokkkkd,\n' +
                        'dxxc       lxxo oxxo       lxxx    .dkkkkkk.                  Welcome to\n' +
                        'dxxc       lxxo oxxo       lxxx   ,dkkkkkkkk,                     Nuxeo CLI\n' +
                        'dxxc       lxxo "oxxcccccccdxxx ,kkkkx" "okkkk,\n' +
                        'loo;       :ooc   "cooooooooool xkko       ckko\n' +
                        '\n' +
                        ':cc,       ;cc;                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        '"cxxoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '   xoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '\n' +
                        'lkkl       ;kkk oxxxxxxxxxxxxxo xooooooooooo,\n' +
                        'lkkkx:.  ,dkkkx oxxxxxxxxxxxxxo lxxxxxxxxxxxxb;\n' +
                        ' "okkkkokkkkd,  oxxo            lxxd       :xxx\n' +
                        '   .dkkkkkk.    oxxxxxxxxxxxxxo lxxd       :xxx\n' +
                        '  ,dkkkkkkkk,   oxxo            cxxd       :xxx\n' +
                        ',kkkkx" "okkkk, oxxxxxxxxxxxxxo  "oxxxxxxxxxxxx\n' +
                        'xkko       ckko oxxxxxxxxxxxxxo    :xxxxxxxxxxx\n' +
                        '\n' +
                        '\n' +
                        '     info You will be prompted for generation of:\n' +
                        '     info   nuxeo-test-root: single-module\n' +
                        '\n' +
                        '   create Generating Multi module (Your project parent POM)\n' +
                        '     info   Parameters: Nuxeo version, Use a parent artifact, Use the nuxeo distribution pom, Parent group, Parent artifact, Parent version, Import nuxeo in the `dependency management`, Project group, Project artifact, Project version, Project description\n' +
                        '? Nuxeo Version: 10.10\n' +
                        '? Use a parent artifact (for instance your company\'s BOM or the Nuxeo Distribution POM)? Yes\n' +
                        '? Use the Nuxeo Distribution POM? Yes\n' +
                        '? Project Group id: ro.dopamina\n' +
                        '? Project Artifact id: nuxeo-test-parent\n' +
                        '? Project Version: 1.0-SNAPSHOT\n' +
                        '? Project Description:\n' +
                        '   create Writing Multi module\n' +
                        '   create Configuration: multi\n' +
                        '   create pom.xml\n' +
                        '   create .gitignore\n' +
                        '   create Your project is ready!\n' +
                        '     info You can start editing code or you can continue with calling another generator (C:\\Users\\buzdu\\.npm-packages\\node_modules\\nuxeo-cli\\bin\\nuxeo-cli.js bootstrap [<generator>..])'}
                    </SyntaxHighlighter>

                    <b>5. Comanda <i>nuxeo hotreload configure</i></b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo hotreload configure'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo hotreload configure\n' +
                        '\n' +
                        'dxxxxxxxxxxc    oxxo       lxxx lkkl       ;kkk\n' +
                        'dxxxxxxxxxxxd;  oxxo       lxxx lkkkx:.  ,dkkkx\n' +
                        'dxxc       lxxo oxxo       lxxx  "okkkkokkkkd,\n' +
                        'dxxc       lxxo oxxo       lxxx    .dkkkkkk.                  Welcome to\n' +
                        'dxxc       lxxo oxxo       lxxx   ,dkkkkkkkk,                     Nuxeo CLI\n' +
                        'dxxc       lxxo "oxxcccccccdxxx ,kkkkx" "okkkk,\n' +
                        'loo;       :ooc   "cooooooooool xkko       ckko\n' +
                        '\n' +
                        ':cc,       ;cc;                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        '"cxxoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '   xoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '\n' +
                        'lkkl       ;kkk oxxxxxxxxxxxxxo xooooooooooo,\n' +
                        'lkkkx:.  ,dkkkx oxxxxxxxxxxxxxo lxxxxxxxxxxxxb;\n' +
                        ' "okkkkokkkkd,  oxxo            lxxd       :xxx\n' +
                        '   .dkkkkkk.    oxxxxxxxxxxxxxo lxxd       :xxx\n' +
                        '  ,dkkkkkkkk,   oxxo            cxxd       :xxx\n' +
                        ',kkkkx" "okkkk, oxxxxxxxxxxxxxo  "oxxxxxxxxxxxx\n' +
                        'xkko       ckko oxxxxxxxxxxxxxo    :xxxxxxxxxxx\n' +
                        '\n' +
                        '\n' +

                        '     info You\'ll be prompted for setting a target Nuxeo Server to trigger hot reload.\n' +
                        '? Nuxeo server deployment: Local Deployment\n' +
                        '? Nuxeo Server path: D:\\servers\\nuxeo-server-10.10-tomcat\n' +
                        '? Ignore selected modules:\n' +
                        '? Do you want to configure your nuxeo.conf? No\n' +
                        '× Nuxeo Server (D:\\servers\\nuxeo-server-10.10-tomcat) needs the sdk template.\n' +
                        '     info Thank you.'}
                    </SyntaxHighlighter>

                    <b>6. Comanda creare operatie <i>nuxeo bootstrap operation -n</i></b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeo bootstrap operation -n'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'     info Your target Nuxeo version is: 10.10\n' +
                        '     info You will be prompted for generation of:\n' +
                        '     info   nuxeo-test-core: single-module, operation\n' +
                        '\n' +
                        '   create Generate Module: nuxeo-test-core\n' +
                        '\n' +
                        '   create Generating Single module\n' +
                        '     info   Parameters: Parent group, Parent artifact, Parent version, Nuxeo version, Project group, Project artifact, Project version, Project description\n' +
                        '? Parent Group id (use white space to cancel default value.): ro.dopamina\n' +
                        '? Parent Artifact id: nuxeo-test-parent\n' +
                        '? Parent Version: 1.0-SNAPSHOT\n' +
                        '? Project Group id: ro.dopamina.operation\n' +
                        '? Project Artifact id: kj-operation\n' +
                        '? Project version: 1.0-SNAPSHOT\n' +
                        '? Project description: KJ Operatie\n' +
                        '\n' +
                        '   create Generating Operation\n' +
                        '     info   Parameters: Operation class name, Operation label\n' +
                        '? Operation class name: KJOperation\n' +
                        '? Operation label: KJ Operation\n' +
                        '   create Writing Single module\n' +
                        '   create Writing Operation\n' +
                        '     info Maven dependency: org.nuxeo.ecm.automation:nuxeo-automation-core\n' +
                        '     info Maven dependency: org.nuxeo.ecm.automation:nuxeo-automation-test:::test\n' +
                        '    force pom.xml\n' +
                        '   create nuxeo-test-core\\pom.xml\n' +
                        '   create nuxeo-test-core\\src\\main\\resources\\META-INF\\MANIFEST.MF\n' +
                        '   create nuxeo-test-core\\src\\main\\java\\ro\\dopamina\\operation\\package-info.java\n' +
                        '   create nuxeo-test-core\\src\\test\\resources\\jndi.properties\n' +
                        '   create nuxeo-test-core\\src\\test\\resources\\log4j2-test.xml\n' +
                        '   create nuxeo-test-core\\src\\main\\java\\ro\\dopamina\\operation\\KJOperation.java\n' +
                        '   create nuxeo-test-core\\src\\test\\java\\ro\\dopamina\\operation\\TestKJOperation.java\n' +
                        '   create nuxeo-test-core\\src\\main\\resources\\OSGI-INF\\kjoperation-operation-contrib.xml\n' +
                        '   create Your project is ready!\n' +
                        '     info You can start editing code or you can continue with calling another generator (C:\\Users\\buzdu\\.npm-packages\\node_modules\\nuxeo-cli\\bin\\nuxeo-cli.js bootstrap [<generator>..])'}
                    </SyntaxHighlighter>

                    Exemplu de clasa generata:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.dopamina.operation;\n' +
                        '\n' +
                        'import org.apache.commons.lang3.StringUtils;\n' +
                        'import org.nuxeo.ecm.automation.core.Constants;\n' +
                        'import org.nuxeo.ecm.automation.core.annotations.Context;\n' +
                        'import org.nuxeo.ecm.automation.core.annotations.Operation;\n' +
                        'import org.nuxeo.ecm.automation.core.annotations.OperationMethod;\n' +
                        'import org.nuxeo.ecm.automation.core.annotations.Param;\n' +
                        'import org.nuxeo.ecm.core.api.CoreSession;\n' +
                        'import org.nuxeo.ecm.core.api.DocumentModel;\n' +
                        'import org.nuxeo.ecm.core.api.PathRef;\n' +
                        '\n' +
                        '/**\n' +
                        ' *\n' +
                        ' */\n' +
                        '@Operation(id=KJOperation.ID, category=Constants.CAT_DOCUMENT, label="KJ Operation", description="Describe here what your operation does.")\n' +
                        'public class KJOperation {\n' +
                        '\n' +
                        '    public static final String ID = "Document.KJOperation";\n' +
                        '\n' +
                        '    @Context\n' +
                        '    protected CoreSession session;\n' +
                        '\n' +
                        '    @Param(name = "path", required = false)\n' +
                        '    protected String path;\n' +
                        '\n' +
                        '    @OperationMethod\n' +
                        '    public DocumentModel run() {\n' +
                        '        if (StringUtils.isBlank(path)) {\n' +
                        '            return session.getRootDocument();\n' +
                        '        } else {\n' +
                        '            return session.getDocument(new PathRef(path));\n' +
                        '        }\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <b>7. Comanda <i>mvn package</i></b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'mvn package'}
                    </SyntaxHighlighter>

                    Apoi se da comanda <i>nuxeo hotreload -n</i>

                    <br/>
                    <br/>
                    <b>8. Verificare existenta noua operatie</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'http://localhost:8080/nuxeo/site/operation/doc'}
                    </SyntaxHighlighter>
                </div>

                {/*<br/>*/}
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*        <div>*/}
                {/*            <a href={"https://doc.nuxeo.com/nxdoc/acls/"}>*/}
                {/*                ACLs*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NuxeoCLINuxeoContent;