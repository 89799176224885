import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../raspberrypi/IndexContent";

class UtilRpiContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rpi-util", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Site-uri straine:
                    <ul>
                        <li>
                            <a href={"https://botland.store/"}>botland (polonia) => gls</a>
                        </li>

                        <li>
                            <a href={"https://raspberrypi.dk/"}>raspberrypi.dk (danemarca) => posta</a>
                        </li>

                        <li>
                            <a href={"https://www.sunnysoft.ro/"}>sunnysoft (cehia) => easybox</a>
                        </li>

                        <li>
                            <a href={"https://www.robotshop.com/"}>robotshop (olanda/canada) => cargus</a>
                        </li>
                    </ul>

                    Site-uri romania:
                    <ul>
                        <li>
                            <a href={"https://www.optimusdigital.ro/"}>optimusdigital.ro (bucuresti)</a>
                        </li>

                        <li>
                            <a href={"https://cleste.ro/"}>cleste.ro (galati)</a>
                        </li>

                        <li>
                            <a href={"https://www.bitmi.ro/"}>bitmi.ro (iasi)</a>
                        </li>

                        <li>
                            <a href={"https://www.robofun.ro/"}>robofun.ro (bucuresti)</a>
                        </li>

                        <li>
                            <a href={"https://ardushop.ro/"}>ardushop.ro</a>
                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UtilRpiContent;