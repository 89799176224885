import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class RepositoryQuerySpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-repository-query", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>@Query : group by, sum, count, case when then else end</b>

                    <br/>
                    <br/>

                    Exemplu
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Query(\n' +
                            '    "SELECT new ro.Stats(" +\n' +
                            '        "  t.tara, " +\n' +
                            '        "  t.judet, " +\n' +
                            '        "  sum(case when t.judet=\'IASI\' then 1 else 0 end), " +     \n' +
                            '        "  sum(case when t.judet=\'CLUJ\' then 1 else 0 end), " +\n' +
                            '        "  count(t)" +\n' +
                            '        ") " +\n' +
                            '        "from Tara t join t.continent c group by t.tara, t.judet"\n' +
                            ')\n' +
                            'List<Stats> getStats();'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RepositoryQuerySpringBootContent;