import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"javascript-i", url:"#", title:"Introducere in Javascript", subtitle:""},

        {id:"javascript-overview", url:"/javascript/overview", title:"Generalitati", subtitle:"", parent: "javascript-i"},
        {id:"javascript-variables", url:"/javascript/variables", title:"Variabile", subtitle:"", parent: "javascript-i"},
        {id:"javascript-data-types", url:"/javascript/data-types", title:"Tipuri de date primitive", subtitle:"", parent: "javascript-i"},
        {id:"javascript-type-conversion", url:"/javascript/type-conversion", title:"Conversi de tip", subtitle:"", parent: "javascript-i"},
        {id:"javascript-complex-data-types", url:"/javascript/complex-data-types", title:"Tipuri de date complexe", subtitle:"", parent: "javascript-i"},
        {id:"javascript-comments", url:"/javascript/comments", title:"Comentarii", subtitle:"", parent: "javascript-i"},
        {id:"javascript-operators", url:"/javascript/operators", title:"Operatori", subtitle:"", parent: "javascript-i"},
        {id:"javascript-dialogs", url:"/javascript/dialogs", title:"Casete de dialog", subtitle:"", parent: "javascript-i"},
        {id:"javascript-if", url:"/javascript/if", title:"Instructiunea if", subtitle:"", parent: "javascript-i"},
        {id:"javascript-conditional-operator", url:"/javascript/conditional-operator", title:"Operarator conditional", subtitle:"", parent: "javascript-i"},
        {id:"javascript-switch", url:"/javascript/switch", title:"Instructiunea switch", subtitle:"", parent: "javascript-i"},
        {id:"javascript-while", url:"/javascript/while", title:"Instructiunea while", subtitle:"", parent: "javascript-i"},
        {id:"javascript-for", url:"/javascript/for", title:"Instructiunea for", subtitle:"", parent: "javascript-i"},
        {id:"javascript-functions", url:"/javascript/functions", title:"Functii", subtitle:"", parent: "javascript-i"},
        {id:"javascript-errors", url:"/javascript/errors", title:"Gestiunea erorilor si exceptiilor", subtitle:"", parent: "javascript-i"},
        {id:"javascript-debugging", url:"/javascript/debugging", title:"Depanare/debugging", subtitle:"", parent: "javascript-i"},

        {id:"javascript-oop", url:"#", title:"Functii, obiecte, clase", subtitle:""},
        {id:"javascript-functions-forms", url:"/javascript/functions-forms", title:"Moduri de declarare functii", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-immediately-invoked-function-expression", url:"/javascript/immediately-invoked-function-expression", title:"IIFE - Immediately invoked function expression", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-blocks", url:"/javascript/blocks", title:"Blocuri independente", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-bind", url:"/javascript/bind", title:"Metoda bind()", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-closures", url:"/javascript/closures", title:"Closures", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-this", url:"/javascript/this", title:"this", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-factory-functions", url:"/javascript/factory-functions", title:"Factory functions", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-first-class-values", url:"/javascript/first-class-values", title:"Valori de prima clasa", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-functions-is-values", url:"/javascript/functions-is-values", title:"Module clasice - Functiile sunt valori", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-ems", url:"/javascript/ems", title:"Module ES - EMS", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-var-let-const", url:"/javascript/var-let-const", title:"Comparatie: var, let, const", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-hoisting", url:"/javascript/hoisting", title:"Hoisting (ridicare)", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-namespace", url:"/javascript/namespace", title:"Namespace", subtitle:"", parent: "javascript-oop"},
        {id:"javascript-anexa-1", url:"/javascript/anexa-1", title:"La inceput au fost doua acolade - prima deschisa, iar a doua inchisa", subtitle:"", parent: "javascript-oop"},

        {id:"javascript-util", url:"#", title:"Util", subtitle:""},
        {id:"javascript-pushstate", url:"/javascript/pushstate", title:"history.pushState", subtitle:"", parent: "javascript-util"},

    ];

    static indexUrl = "/javascript/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere în Javascript
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;