import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class LogsTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-logs", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Loguri (catalina.log)</b>
                    <br/>
                    <br/>

                    Logurile se gasesc in urmatoarea locatie: [tomcat]<b>\logs</b>.
                    <br/>
                    <br/>
                    Directorul <b>logs</b> se poate sterge fara nici o grija. La pornirea serverului este creat automat.
                    <br/>
                    <bt/>
                    Fisierele log implicite:
                    <ul>
                        <li>catalina-<i>YYYY-MM-dd</i>.log (windows)/ catalina.out (linux)
                            <br/>
                            o line din acest fisier arata in felul urmator:
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'06-Sep-2022 18:58:01.780 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Server version name:   Apache Tomcat/10.0.23'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>INFO - nivel (level) log</li>
                                <li>[main] - firul de executie (thread)</li>
                                <li>org.apache.catalina.startup.VersionLoggerListener.log - metoda (log) din clasa (org.apache.catalina.startup.VersionLoggerListener) care a generat mesajul</li>
                                <li>Server version name:   Apache Tomcat/10.0.23  - mesajul</li>
                            </ul>

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'06-Sep-2022 19:06:21.120 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Server version name:   Apache Tomcat/10.0.23\n' +
                                    '06-Sep-2022 19:06:21.123 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Server built:          Jul 14 2022 08:16:11 UTC\n' +
                                    '06-Sep-2022 19:06:21.123 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Server version number: 10.0.23.0\n' +
                                    '06-Sep-2022 19:06:21.123 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log OS Name:               Windows 10\n' +
                                    '06-Sep-2022 19:06:21.124 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log OS Version:            10.0\n' +
                                    '06-Sep-2022 19:06:21.124 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Architecture:          amd64\n' +
                                    '06-Sep-2022 19:06:21.124 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Java Home:             C:\\tools\\jdk-11\n' +
                                    '06-Sep-2022 19:06:21.124 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log JVM Version:           11+28\n' +
                                    '06-Sep-2022 19:06:21.124 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log JVM Vendor:            Oracle Corporation\n' +
                                    '06-Sep-2022 19:06:21.125 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log CATALINA_BASE:         D:\\servers\\apache-tomcat-10.0.23\n' +
                                    '06-Sep-2022 19:06:21.125 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log CATALINA_HOME:         D:\\servers\\apache-tomcat-10.0.23\n' +
                                    '06-Sep-2022 19:06:21.132 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: --add-opens=java.base/java.lang=ALL-UNNAMED\n' +
                                    '06-Sep-2022 19:06:21.132 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: --add-opens=java.base/java.io=ALL-UNNAMED\n' +
                                    '06-Sep-2022 19:06:21.132 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: --add-opens=java.base/java.util=ALL-UNNAMED\n' +
                                    '06-Sep-2022 19:06:21.132 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: --add-opens=java.base/java.util.concurrent=ALL-UNNAMED\n' +
                                    '06-Sep-2022 19:06:21.133 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: --add-opens=java.rmi/sun.rmi.transport=ALL-UNNAMED\n' +
                                    '06-Sep-2022 19:06:21.133 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Djava.util.logging.config.file=D:\\servers\\apache-tomcat-10.0.23\\conf\\logging.properties\n' +
                                    '06-Sep-2022 19:06:21.134 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Djava.util.logging.manager=org.apache.juli.ClassLoaderLogManager\n' +
                                    '06-Sep-2022 19:06:21.134 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Djdk.tls.ephemeralDHKeySize=2048\n' +
                                    '06-Sep-2022 19:06:21.135 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Djava.protocol.handler.pkgs=org.apache.catalina.webresources\n' +
                                    '06-Sep-2022 19:06:21.135 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Dignore.endorsed.dirs=\n' +
                                    '06-Sep-2022 19:06:21.135 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Dcatalina.base=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    '06-Sep-2022 19:06:21.136 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Dcatalina.home=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    '06-Sep-2022 19:06:21.136 INFO [main] org.apache.catalina.startup.VersionLoggerListener.log Command line argument: -Djava.io.tmpdir=D:\\servers\\apache-tomcat-10.0.23\\temp\n' +
                                    '06-Sep-2022 19:06:21.140 INFO [main] org.apache.catalina.core.AprLifecycleListener.lifecycleEvent Loaded Apache Tomcat Native library [1.2.35] using APR version [1.7.0].\n' +
                                    '06-Sep-2022 19:06:21.140 INFO [main] org.apache.catalina.core.AprLifecycleListener.lifecycleEvent APR capabilities: IPv6 [true], sendfile [true], accept filters [false], random [true], UDS [true].\n' +
                                    '06-Sep-2022 19:06:21.144 INFO [main] org.apache.catalina.core.AprLifecycleListener.initializeSSL OpenSSL successfully initialized [OpenSSL 1.1.1q  5 Jul 2022]\n' +
                                    '06-Sep-2022 19:06:21.359 INFO [main] org.apache.coyote.AbstractProtocol.init Initializing ProtocolHandler ["http-nio-8580"]\n' +
                                    '06-Sep-2022 19:06:21.384 INFO [main] org.apache.catalina.startup.Catalina.load Server initialization in [399] milliseconds\n' +
                                    '06-Sep-2022 19:06:21.424 INFO [main] org.apache.catalina.core.StandardService.startInternal Starting service [Catalina]\n' +
                                    '06-Sep-2022 19:06:21.424 INFO [main] org.apache.catalina.core.StandardEngine.startInternal Starting Servlet engine: [Apache Tomcat/10.0.23]\n' +
                                    '06-Sep-2022 19:06:21.431 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deploying web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\docs]\n' +
                                    '06-Sep-2022 19:06:22.067 WARNING [main] org.apache.catalina.util.SessionIdGeneratorBase.createSecureRandom Creation of SecureRandom instance for session ID generation using [SHA1PRNG] took [458] milliseconds.\n' +
                                    '06-Sep-2022 19:06:22.087 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deployment of web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\docs] has finished in [656] ms\n' +
                                    '06-Sep-2022 19:06:22.087 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deploying web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\examples]\n' +
                                    '06-Sep-2022 19:06:22.262 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deployment of web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\examples] has finished in [174] ms\n' +
                                    '06-Sep-2022 19:06:22.262 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deploying web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\host-manager]\n' +
                                    '06-Sep-2022 19:06:22.283 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deployment of web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\host-manager] has finished in [20] ms\n' +
                                    '06-Sep-2022 19:06:22.283 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deploying web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\manager]\n' +
                                    '06-Sep-2022 19:06:22.299 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deployment of web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\manager] has finished in [16] ms\n' +
                                    '06-Sep-2022 19:06:22.300 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deploying web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\ROOT]\n' +
                                    '06-Sep-2022 19:06:22.313 INFO [main] org.apache.catalina.startup.HostConfig.deployDirectory Deployment of web application directory [D:\\servers\\apache-tomcat-10.0.23\\webapps\\ROOT] has finished in [12] ms\n' +
                                    '06-Sep-2022 19:06:22.319 INFO [main] org.apache.coyote.AbstractProtocol.start Starting ProtocolHandler ["http-nio-8580"]\n' +
                                    '06-Sep-2022 19:06:22.813 INFO [main] org.apache.catalina.startup.Catalina.start Server startup in [1429] milliseconds'}
                            </SyntaxHighlighter>

                            <b>Observatie 1:</b>
                            <br/>
                            Urmatorul text <i>["http-nio-8580"]</i> indica portul pe care a pornit Tomcat.

                            <br/>
                            <br/>
                            <b>Observatie 2:</b>
                            <br/>
                            Daca in fisierul JSP avem:
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'System.out.println("test");'}
                            </SyntaxHighlighter>
                            Acest text va fi afisat in consola serverului, dar <b>nu</b> va fi scris si in fisierul de loguri <i>catalina-X.log</i>.

                        </li>
                    </ul>

                    <hr/>

                    <b>2. Loguri (localhost_access_log.txt)</b>
                    <br/>
                    <br/>

                    O line din acest fisier arata in felul urmator:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /tomcat.svg HTTP/1.1" 200 68761'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>0:0:0:0:0:0:0:1 - adresa IP a clientului (0:0:0:0:0:0:0:1 inseamna ca cererea s-a facut de pe aceeasi masina cu serverul de tomcat)</li>
                        <li>[06/Sep/2022:19:06:58 +0300] - data cerereii</li>
                        <li>"GET /tomcat.svg HTTP/1.1" - metoda http (GET), urmat de url (/tomcat.svg), urmat de protocol (HTTP)</li>
                        <li>200 68761 - codul de raspuns al codului (200), urmat de numar de biti trimisi de pe server (68761)</li>
                    </ul>

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET / HTTP/1.1" 200 11437\n' +
                            '0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /tomcat.svg HTTP/1.1" 200 68761\n' +
                            '0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /tomcat.css HTTP/1.1" 200 5895\n' +
                            '0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /bg-nav.png HTTP/1.1" 200 1401\n' +
                            '0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /asf-logo-wide.svg HTTP/1.1" 200 27530\n' +
                            '0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /bg-button.png HTTP/1.1" 200 713\n' +
                            '0:0:0:0:0:0:0:1 - - [06/Sep/2022:19:06:58 +0300] "GET /bg-upper.png HTTP/1.1" 200 3103'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Loguri (localhost.txt)</b>
                    <br/>
                    <br/>

                    In acest fisier se scriu detalii legate de virtual host si logheaza exceptiile din aplicatii.

                    <hr/>

                    <b>4. Alte fisiere de log (host-manager.log, manager.log)</b>
                    <br/>
                    <br/>

                    Aceste fisiere de log sunt specifice aplicatiilor:
                    <ul>
                        <li>manager</li>
                        <li>host-manager</li>
                    </ul>
                    Aceste aplicatii sunt rareori lasate in productie.

                    <hr/>

                    <b>5. Logging Framework</b>
                    <br/>
                    <br/>

                    Tomcat foloseste java.util.logging (JULI): este un framework declarativ.

                    Handlere:
                    <ul>
                        <li>AsyncFileHandler: pentru a scrie in fisier</li>
                        <li>ConsoleHandler: pentru a scrie in consola</li>
                    </ul>

                    Configurarile se fac in <b>logging.properties</b> (din [tomcat]\conf).

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'handlers = 1catalina.org.apache.juli.AsyncFileHandler, 2localhost.org.apache.juli.AsyncFileHandler, 3manager.org.apache.juli.AsyncFileHandler, 4host-manager.org.apache.juli.AsyncFileHandler, java.util.logging.ConsoleHandler\n' +
                            '\n' +
                            '.handlers = 1catalina.org.apache.juli.AsyncFileHandler, java.util.logging.ConsoleHandler\n' +
                            '\n' +
                            '############################################################\n' +
                            '# Handler specific properties.\n' +
                            '# Describes specific configuration info for Handlers.\n' +
                            '############################################################\n' +
                            '\n' +
                            '1catalina.org.apache.juli.AsyncFileHandler.level = FINE\n' +
                            '1catalina.org.apache.juli.AsyncFileHandler.directory = ${catalina.base}/logs\n' +
                            '1catalina.org.apache.juli.AsyncFileHandler.prefix = catalina.\n' +
                            '1catalina.org.apache.juli.AsyncFileHandler.maxDays = 90\n' +
                            '1catalina.org.apache.juli.AsyncFileHandler.encoding = UTF-8\n' +
                            '\n' +
                            '2localhost.org.apache.juli.AsyncFileHandler.level = FINE\n' +
                            '2localhost.org.apache.juli.AsyncFileHandler.directory = ${catalina.base}/logs\n' +
                            '2localhost.org.apache.juli.AsyncFileHandler.prefix = localhost.\n' +
                            '2localhost.org.apache.juli.AsyncFileHandler.maxDays = 90\n' +
                            '2localhost.org.apache.juli.AsyncFileHandler.encoding = UTF-8\n' +
                            '\n' +
                            '3manager.org.apache.juli.AsyncFileHandler.level = FINE\n' +
                            '3manager.org.apache.juli.AsyncFileHandler.directory = ${catalina.base}/logs\n' +
                            '3manager.org.apache.juli.AsyncFileHandler.prefix = manager.\n' +
                            '3manager.org.apache.juli.AsyncFileHandler.maxDays = 90\n' +
                            '3manager.org.apache.juli.AsyncFileHandler.encoding = UTF-8\n' +
                            '\n' +
                            '4host-manager.org.apache.juli.AsyncFileHandler.level = FINE\n' +
                            '4host-manager.org.apache.juli.AsyncFileHandler.directory = ${catalina.base}/logs\n' +
                            '4host-manager.org.apache.juli.AsyncFileHandler.prefix = host-manager.\n' +
                            '4host-manager.org.apache.juli.AsyncFileHandler.maxDays = 90\n' +
                            '4host-manager.org.apache.juli.AsyncFileHandler.encoding = UTF-8\n' +
                            '\n' +
                            'java.util.logging.ConsoleHandler.level = FINE\n' +
                            'java.util.logging.ConsoleHandler.formatter = org.apache.juli.OneLineFormatter\n' +
                            'java.util.logging.ConsoleHandler.encoding = UTF-8\n' +
                            '\n' +
                            '\n' +
                            '############################################################\n' +
                            '# Facility specific properties.\n' +
                            '# Provides extra control for each logger.\n' +
                            '############################################################\n' +
                            '\n' +
                            'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].level = INFO\n' +
                            'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].handlers = 2localhost.org.apache.juli.AsyncFileHandler\n' +
                            '\n' +
                            'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].[/manager].level = INFO\n' +
                            'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].[/manager].handlers = 3manager.org.apache.juli.AsyncFileHandler\n' +
                            '\n' +
                            'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].[/host-manager].level = INFO\n' +
                            'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].[/host-manager].handlers = 4host-manager.org.apache.juli.AsyncFileHandler'}
                    </SyntaxHighlighter>

                    Prin proprietate <b>handlers</b> se definesc instante de handlere, urmand coventia:
                    <ul>
                        <li>[nume_instanta.]<b>org.apache.juli.AsyncFileHandler</b> (ex: 1catalina.org.apache.juli.AsyncFileHandler)
                            <br/>
                            Pentru a permite definirea mai multor handler ai unei singure clase,
                            prefixele handler constau dintr-un șir care începe cu o cifră și se termină cu un „.” (de exemplu, „1example”) sunt acceptate.
                        </li>
                        <li>[nume_instanta.]<b>java.util.logging.ConsoleHandler</b> (ex:)</li>
                    </ul>
                    Proprietatea <b>.handlers</b>  poate fi utilizată pentru a defini setul de gestionare a loggers-ului rădăcină.

                    <br/>
                    <br/>
                    Se pot defini <b>sub-nivele</b> de logare pentru anumite url-uri. Exemplu, din fisierul de mai sus:

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {' \'org.apache.catalina.core.ContainerBase.[Catalina].[localhost].[/manager].level = INFO\\n\' +'}
                    </SyntaxHighlighter>
                    Pentru <i>/manager</i> se seteaza nivelul de logare = INDO

                    <br/>
                    <br/>
                    Proprietati:
                    <ul>
                        <li><b>level</b> - nivel de logare:
                            <ul>
                                <li>SEVERE</li>
                                <li>WARNING</li>
                                <li>INFO</li>
                                <li>FINE</li>
                                <li>FINER</li>
                                <li>FINEST</li>
                            </ul>
                        </li>
                        <li><b>directory</b> - locatia este este salvat fisierul de log</li>
                        <li><b>prefix</b> - prefixul fisierului de logare</li>
                        <li><b>maxDays</b> - defineste numarul de zile cat e tinut un fisier de log; apoi este sters in mod automat.</li>
                        <li><b>encoding</b> - encodare fisierului de logare</li>
                        <li><b>formatter</b> - formatarea unui linii de log</li>
                    </ul>

                    <b>6. Logare din aplicatie / JSP</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="jsp" style={androidstudio}>
                        {'<%@ page imprt ="java.util.logging.*" %>\n\n' +
                            '<%\n' +
                            'Logger log = Logger("ro.kjapp");\n' +
                            'log.severe("severe")\n' +
                            'log.warning("warning")\n' +
                            'log.info("info")\n' +
                            '%>'}
                    </SyntaxHighlighter>

                    In <b>logging.properties</b> (din [tomcat]\conf), se adauga la <b>handlers</b>

                    <SyntaxHighlighter showLineNumbers={true} language="jsp" style={androidstudio}>
                        {'5kjapp.org.apache.juli.AsyncFileHandler'}
                    </SyntaxHighlighter>

                    Apoi, se adauga urmatoare linii:
                    <SyntaxHighlighter showLineNumbers={true} language="jsp" style={androidstudio}>
                        {'5kjapp.org.apache.juli.AsyncFileHandler.level = FINE\n' +
                            '5kjapp.org.apache.juli.AsyncFileHandler.directory = ${catalina.base}/logs\n' +
                            '5kjapp.org.apache.juli.AsyncFileHandler.prefix = kjapp.\n' +
                            '5kjapp.org.apache.juli.AsyncFileHandler.maxDays = 90\n' +
                            '5kjapp.org.apache.juli.AsyncFileHandler.encoding = UTF-8\n' +
                            '\n' +
                            'ro.kjapp.handlers = 5kjapp.org.apache.juli.AsyncFileHandler'}
                    </SyntaxHighlighter>

                    5kjapp.org.apache.juli.AsyncFileHandler

                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LogsTomcatContent;