import React from 'react';

import 'jquery';
import 'popper.js';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import reportWebVitals from './reportWebVitals';

import App from './app/App';
import {BrowserRouter as Router} from "react-router-dom";

//ReactDOM.render is no longer supported in React 18
// import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';

//ReactDOM.render is no longer supported in React 18
//ReactDOM.render( <BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Router><App /></Router>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
