import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class JobObjectK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-job-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiectul Job</b>
                    <br/>
                    <br/>

                    Acest tip de obiect:
                    <ul>
                        <li>este folosit pentru a creea pod-uri care se termina la un moment dat, dupa ce a terminat de rulat</li>
                        <li>elibereaza resursele dupa ce ce job-ul s-a terminat si apoi sters</li>
                        <li>ramane in starea COMPLETED dupa ce executia pod-ului s-a terminat cu succes</li>
                        <li>este folosit pentru procesare in paralel</li>
                        <li>cron jobs sunt folosite pentru programarea reiterarii/executiei job-ului</li>
                    </ul>

                    Exemplu de Job, pentru a creea Pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: batch/v1\n' +
                            'kind: Job\n' +
                            'metadata:\n' +
                            '  name: job-demo\n' +
                            'spec:\n' +
                            '  template:\n' +
                            '    spec: \n' +
                            '      containers: \n' +
                            '      - name: job-container \n' +
                            '        image: perl\n' +
                            '        command: ["perl", "-Mbignum=bpi", "-wle", "print bpi(2000)"] \n' +
                            '      restartPolicy: Never\n' +
                            '  backoffLimit: 4 # numar de incercari de a porni containerul daca nu s-a reusit din prima' +
                            ''}
                    </SyntaxHighlighter>
                    In mod implicit:
                    <ul>
                        <li>restartPolicy: Always</li>
                    </ul>
                    Ceea ce inseamna ca dupa ce isi termina treaba, se va restarta automat!
                    <br/>
                    <br/>
                    Dar pentru <b>Job</b> trebuie:
                    <ul>
                        <li>restartPolicy: Never</li>
                    </ul>
                    pentru ca k8s sa nu repornesca dupa ce isi termina treaba!

                    <hr/>

                    Afisare lista de job-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs'}
                    </SyntaxHighlighter>

                    Descriere job <i>job-demo</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe job job-demo'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'vim job.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: batch/v1\n' +
                            'kind: Job\n' +
                            'metadata:\n' +
                            '  name: sleepy\n' +
                            'spec:\n' +
                            '  template:\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - name: resting\n' +
                            '        image: busybox\n' +
                            '        command: ["/bin/sleep"]\n' +
                            '        args: ["3"]\n' +
                            '      restartPolicy: Never'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f job.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'job.batch/sleepy created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME                   READY   STATUS      RESTARTS   AGE\n' +
                            'sleepy-6jkbw           0/1     Completed   0          52s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'AME     COMPLETIONS   DURATION   AGE\n' +
                            'sleepy   1/1           13s        114s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe jobs.batch sleepy'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe job sleepy'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'Name:             sleepy\n' +
                            'Namespace:        default\n' +
                            'Selector:         controller-uid=db22b9f0-1919-41b9-8532-775b405f2a7b\n' +
                            'Labels:           controller-uid=db22b9f0-1919-41b9-8532-775b405f2a7b\n' +
                            '                  job-name=sleepy\n' +
                            'Annotations:      batch.kubernetes.io/job-tracking:\n' +
                            'Parallelism:      1\n' +
                            'Completions:      1\n' +
                            'Completion Mode:  NonIndexed\n' +
                            'Start Time:       Sat, 11 Feb 2023 09:35:39 +0200\n' +
                            'Completed At:     Sat, 11 Feb 2023 09:35:52 +0200\n' +
                            'Duration:         13s\n' +
                            'Pods Statuses:    0 Active (0 Ready) / 1 Succeeded / 0 Failed\n' +
                            'Pod Template:\n' +
                            '  Labels:  controller-uid=db22b9f0-1919-41b9-8532-775b405f2a7b\n' +
                            '           job-name=sleepy\n' +
                            '  Containers:\n' +
                            '   resting:\n' +
                            '    Image:      busybox\n' +
                            '    Port:       <none>\n' +
                            '    Host Port:  <none>\n' +
                            '    Command:\n' +
                            '      /bin/sleep\n' +
                            '    Args:\n' +
                            '      3\n' +
                            '    Environment:  <none>\n' +
                            '    Mounts:       <none>\n' +
                            '  Volumes:        <none>\n' +
                            'Events:           <none>\n'}
                    </SyntaxHighlighter>

                    Stergere:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete jobs.batch sleepy'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter>
                        {'job.batch "sleepy" deleted'}
                    </SyntaxHighlighter>

                    <hr/>
                    Parametrul <b>completions</b> specifica de cate ori sa se repete job-ul:
                    <SyntaxHighlighter>
                        {'apiVersion: batch/v1\n' +
                            'kind: Job\n' +
                            'metadata:\n' +
                            '  name: sleepy\n' +
                            'spec:\n' +
                            '  completions: 5\n' +
                            '  template:\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - name: resting\n' +
                            '        image: busybox\n' +
                            '        command: ["/bin/sleep"]\n' +
                            '        args: ["3"]\n' +
                            '      restartPolicy: Never\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f job.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'job.batch/sleepy created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs.batch'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME     COMPLETIONS   DURATION   AGE\n' +
                            'sleepy   2/5           20s        20s\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete jobs.batch sleepy'}
                    </SyntaxHighlighter>

                    <hr/>
                    Parametrul <b>parallelism</b> specifica pe cate pod-uri va fi deploy-at:
                    <SyntaxHighlighter>
                        {'apiVersion: batch/v1\n' +
                            'kind: Job\n' +
                            'metadata:\n' +
                            '  name: sleepy\n' +
                            'spec:\n' +
                            '  completions: 5\n' +
                            '  parallelism: 2\n' +
                            '  template:\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - name: resting\n' +
                            '        image: busybox\n' +
                            '        command: ["/bin/sleep"]\n' +
                            '        args: ["3"]\n' +
                            '      restartPolicy: Never\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Parametrul <b>activeDeadlineSeconds</b> opreste job-ul dupa un numar de secunde (job-ul si toate pod-urile se vor opri dupa 15 secunde)
                    <SyntaxHighlighter>
                        {'apiVersion: batch/v1\n' +
                            'kind: Job\n' +
                            'metadata:\n' +
                            '  name: sleepy\n' +
                            'spec:\n' +
                            '  completions: 5\n' +
                            '  parallelism: 2\n' +
                            '  activeDeadlineSeconds: 10\n' +
                            '  template:\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - name: resting\n' +
                            '        image: busybox\n' +
                            '        command: ["/bin/sleep"]\n' +
                            '        args: ["3"]\n' +
                            '      restartPolicy: Never\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f job.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'job.batch/sleepy created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs.batch'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME     COMPLETIONS   DURATION   AGE\n' +
                            'sleepy   1/5           46s        47s\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get job '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs -o yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'items:\n' +
                            '- apiVersion: batch/v1\n' +
                            '  kind: Job\n' +
                            '  metadata:\n' +
                            '    annotations:\n' +
                            '      batch.kubernetes.io/job-tracking: ""\n' +
                            '    creationTimestamp: "2023-02-11T10:23:44Z"\n' +
                            '    generation: 1\n' +
                            '    labels:\n' +
                            '      controller-uid: bb46bfa2-0b4d-4f1e-8a2a-8051f6f9edd9\n' +
                            '      job-name: sleepy\n' +
                            '    name: sleepy\n' +
                            '    namespace: default\n' +
                            '    resourceVersion: "741097"\n' +
                            '    uid: bb46bfa2-0b4d-4f1e-8a2a-8051f6f9edd9\n' +
                            '  spec:\n' +
                            '    activeDeadlineSeconds: 10\n' +
                            '    backoffLimit: 6\n' +
                            '    completionMode: NonIndexed\n' +
                            '    completions: 5\n' +
                            '    parallelism: 2\n' +
                            '    selector:\n' +
                            '      matchLabels:\n' +
                            '        controller-uid: bb46bfa2-0b4d-4f1e-8a2a-8051f6f9edd9\n' +
                            '    suspend: false\n' +
                            '    template:\n' +
                            '      metadata:\n' +
                            '        creationTimestamp: null\n' +
                            '        labels:\n' +
                            '          controller-uid: bb46bfa2-0b4d-4f1e-8a2a-8051f6f9edd9\n' +
                            '          job-name: sleepy\n' +
                            '      spec:\n' +
                            '        containers:\n' +
                            '        - args:\n' +
                            '          - "3"\n' +
                            '          command:\n' +
                            '          - /bin/sleep\n' +
                            '          image: busybox\n' +
                            '          imagePullPolicy: Always\n' +
                            '          name: resting\n' +
                            '          resources: {}\n' +
                            '          terminationMessagePath: /dev/termination-log\n' +
                            '          terminationMessagePolicy: File\n' +
                            '        dnsPolicy: ClusterFirst\n' +
                            '        restartPolicy: Never\n' +
                            '        schedulerName: default-scheduler\n' +
                            '        securityContext: {}\n' +
                            '        terminationGracePeriodSeconds: 30\n' +
                            '  status:\n' +
                            '    conditions:\n' +
                            '    - lastProbeTime: "2023-02-11T10:23:55Z"\n' +
                            '      lastTransitionTime: "2023-02-11T10:23:55Z"\n' +
                            '      message: Job was active longer than specified deadline\n' +
                            '      reason: DeadlineExceeded\n' +
                            '      status: "True"\n' +
                            '      type: Failed\n' +
                            '    failed: 1\n' +
                            '    ready: 0\n' +
                            '    startTime: "2023-02-11T10:23:45Z"\n' +
                            '    succeeded: 1\n' +
                            '    uncountedTerminatedPods: {}\n' +
                            'kind: List\n' +
                            'metadata:\n' +
                            '  resourceVersion: ""\n'}
                    </SyntaxHighlighter>

                    La status:
                    <ul>
                        <li>message: Job was active longer than specified deadline (Job a fost activ mai mult decât termenul specificat)</li>
                        <li>reason: DeadlineExceeded</li>
                        <li>type: Failed</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete jobs.batch sleepy'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JobObjectK8sContent;