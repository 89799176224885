import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CanActivateAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-can-activate", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Guard: CanActivate</b>
                    <br/>
                    <br/>


                    Guard-ul de tip <i>CanActivate</i> permite protejarea unor rute (de exemplu, trebuie sa fi autentificat pentru a putea accesa un anumit link/ruta)

                    <br/>
                    <br/>
                    Fie <i>can-activate.guard.ts</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Injectable } from \'@angular/core\';\n' +
                            'import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from \'@angular/router\';\n' +
                            'import { Observable } from \'rxjs/Observable\';\n' +
                            'import { AuthService } from \'./auth.service\';\n\n' +
                            '@Injectable({\n' +
                            '  providedIn: \'root\'\n' +
                            '})\n' +
                            'export class AuthGuard implements CanActivate {\n\n' +
                            '  constructor(private authService: AuthService, private router: Router){};\n\n' +
                            '  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolan> | boolean {\n' +
                            '      console.log(\'CanActivate called\');\n' +
                            '      let isLoggedIn = this.authService.isAuthenticated();\n' +
                            '      if (isLoggedIn){\n' +
                            '        return true\n' +
                            '      } else {\n' +
                            '        this.router.navigate([\'/login\']);\n' +
                            '      }\n' +
                            '  }\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>

                    cu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Injectable } from \'@angular/core\';\n' +
                            '@Injectable({\n' +
                            '  providedIn: \'root\'\n' +
                            '})\n' +
                            'export class AuthService {\n' +
                            '  isLoggedIn = false;\n' +
                            '  constructor() { }\n' +
                            'isAuthenticated(){\n' +
                            '    return this.isLoggedIn;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In definirea rutelor:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const routes: Routes = [\n' +
                            '  {path:\'about\', component: AboutComponent,canActivate:[AuthGuard]},\n' +
                            '  {path:\'contact\', component: ContactComponent}\n' +
                            '];'}
                    </SyntaxHighlighter>


                    In plus trebuie declarate in <i>module.ts</i>, in sectiunea <b>providers</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'providers:[AuthGuard, AuthService]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Protejarea rutelor copil</b>
                    <br/>
                    <br/>
                    AuthGuard trebuie sa implementeze si <b>CanActivateChild</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'@Injectable({\n' +
                            '  providedIn: \'root\'\n' +
                            '})\n' +
                            'export class AuthGuard implements CanActivate, CanActivateChild {\n' +
                            '\n' +
                            '  constructor(private authService: AuthService, private router: Router){};\n' +
                            '\n' +
                            '  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolan> | boolean {\n' +
                            '      console.log(\'CanActivate called\');\n' +
                            '      let isLoggedIn = this.authService.isAuthenticated();\n' +
                            '      if (isLoggedIn){\n' +
                            '        return true\n' +
                            '      } else {\n' +
                            '        this.router.navigate([\'/login\']);\n' +
                            '      }\n' +
                            '  }\n' +

                            '  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolan> | boolean {\n' +
                            '     return this.canActivate(next,state)\n'+
                            '  }\n' +

                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>

                    In definirea rutelor:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const routes: Routes = [\n' +
                            '  {path:\'user\',\n' +
                            '    component: UserComponent,\n' +
                            '    canActivateChild:[AuthGuard],\n' +
                            '    children:[{path:":id", component: UserComponent}],\n' +
                            '  }\n' +
                            '  {path:\'login\', component: ContactComponent}\n' +
                            '];'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CanActivateAngularContent;