import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class PodmanK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-podman", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>



                    <b>1. Instalare</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'curl -fsSL -o podman-linux-amd64.tar.gz \\\n' +
                            'https://github.com/mgoltzsche/podman-static/releases/latest/download/podman-linux-amd64.tar.gz'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tar -xf podman-linux-amd64.tar.gz'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo cp -r podman-linux-amd64/usr podman-linux-amd64/etc /'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Build</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo podman build -t [nume-aplicatie]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Afisare imagini</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo podman images'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Run</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo podman run localhost/[nume-aplicatie]'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PodmanK8sContent;