import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FunctionsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-functions", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Functii</b>
                    <br/> <br/>

                    1. <b>Definire functie:</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def nume_functie(parametri):\n' +
                        '    instructiune_1\n' +
                        '    ...\n' +
                        '    instructiune_N' +
                        ''}
                    </SyntaxHighlighter>

                    2. <b>Apelarea/invocarea functiei</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'functie()'}
                    </SyntaxHighlighter>

                    <hr/>
                    3. <b>Reguli functie:</b>
                    <ul>
                        <li>incepe cu cuvantul cheie <b>def</b></li>
                        <li>urmeaza numele functiei (regulile pentru denumirea funcțiilor sunt exact aceleași ca și pentru denumirea variabilelor)</li>
                        <li>pereche de paranteze (optional, poate contine o lista de parametrii)</li>
                        <li>definirea signaturii functiei se termina cu ":"</li>
                        <li>contine identat corpul functiei, care trebuie sa aibia cel putin o instructiune</li>
                        <li>functia de termina unde se termina corpul functiei (deci atentie la identare) </li>
                    </ul>
                    <hr/>
                    <i>Exemplu:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie():\n' +
                        '    print("Sunt in functie. Yupi! Bravo KJ! Spre Calea Lactee")\n' +
                        '    \n' +
                        'functie()'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>4. Observatii:</b>
                    <ul>
                        <li>o functie trebuie sa fie <b>definita inainte sa fie apelata</b>; deci e gresit:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'functie()\n' +
                                '\n' +
                                'def functie():\n' +
                                '    print("Sunt in functie. Yupi! Bravo KJ! Spre Calea Lactee")\n' +
                                '    \n' +
                                ''}
                            </SyntaxHighlighter>
                        </li>

                        <li>o functie <b>nu trebuie sa aiba acelasi nume cu o variabila, pentru ca o suprascrie</b>; deci e gresit:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie():\n' +
                                '    print("Sunt in functie. Yupi! Bravo KJ! Spre Calea Lactee")\n' +
                                '\n' +
                                'print(functie)\n' +
                                '\n' +
                                'functie=1\n' +
                                '\n' +
                                'print(functie)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'<function functie at 0x7fc8e5cd6200>\n' +
                                '1'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <hr/>
                    <b>5. Parametrii functiei:</b>
                    <ul>
                        <li>sunt variabile</li>
                        <li>există doar în interiorul funcțiilor în care au fost definiți</li>
                        <li>atribuirea unei valori parametrului se face in momentul invocarii functiei, prin specificarea argumentului corespunzator</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def afisare_nume(nume):\n' +
                        '    print("nume=",nume)\n' +
                        '    \n' +
                        'afisare_nume("KJ")    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume= KJ'}
                    </SyntaxHighlighter>

                    Deci, in exemplul de mai sus, <i>nume</i> este numele parametrului functiei <i>afisare_nume()</i>, iar <i>KJ</i> este argumentul.

                    <br/><br/>

                    Deci:
                    <ul>
                        <li><b>parametrii</b> exista in interiorul functiei (si nu sunt vizibili sau pot fi accesati din alta parte)</li>
                        <li><b>argumentele</b> exista in afara functiei (și sunt <b>purtători de valori</b> transmise parametrilor corespunzători)</li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>la invocare trebuie transmiti atâtea argumente câti parametri are functie
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def afisare_nume(nume):\n' +
                                '    print("nume=",nume)\n' +
                                '    \n' +
                                'afisare_nume()'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'Traceback (most recent call last):\n' +
                                '  File "main.py", line 4, in <module>\n' +
                                '    afisare_nume()    \n' +
                                'TypeError: afisare_nume() missing 1 required positional argument: \'nume\''}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>7. Ascunderea numelor (name hiding/shadowing)</b>
                    <br/>
                    Numele unei variabile poate fi acelasi cu numele unui parametru a unui functii. Dar numele variabilei este <i>umbrit</i> de numele parametrului in interiorul si doar in interiorul functiei.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def afisare_nume(nume):\n' +
                        '    print("nume=",nume)\n' +
                        '    \n' +
                        'nume = "AKA"    \n' +
                        'afisare_nume("KJ")   '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume= KJ'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>7. Parametrii poziționali / argumente poziționale</b>
                    <br/>

                    Prin trasmitarea <b>parametrilor pozitinali</b> se atribuie argumentul al i-lea, parametrului al i-lea declarat in functie.
                    Argumentele transmise în acest fel sunt numite <b>argumente poziționale</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def afisare_nume(nume,prenume):\n' +
                        '    print("nume=",nume, "prenume=",prenume)\n' +
                        '    \n' +
                        'afisare_nume("KJ","the King of Kings")    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume= KJ prenume= the King of Kings'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Argumente prin cuvinte cheie (Keyword argument )</b>
                    <br/>

                    Python oferă o altă convenție pentru transmiterea argumentelor, în care sensul ca argumentul este <b>dictat de numele său, nu de poziția sa</b>.
                    Acest lucru se numește trimitere de argumente prin cuvintelor cheie.
                    <br/>
                    Valorile transmise parametrilor sunt precedate de <b>numele parametrilor țintă, urmate de semnul =</b>.
                    <br/>
                    Poziția <b>nu contează aici</b> - valoarea fiecărui argument își cunoaște destinația pe baza numelui folosit.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def afisare_nume(nume,prenume):\n' +
                        '    print("nume=",nume, "prenume=",prenume)\n' +
                        '    \n' +
                        'afisare_nume(prenume="the King of Kings", nume="KJ")    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume= KJ prenume= the King of Kings'}
                    </SyntaxHighlighter>

                    Daca se foloseste un nume de parametru care nu exista, se va arunca exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def afisare_nume(nume,prenume):\n' +
                        '    print("nume=",nume, "prenume=",prenume)\n' +
                        '    \n' +
                        'afisare_nume(alias="the King of Kings", nume="KJ")    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Traceback (most recent call last):\n' +
                        '  File "main.py", line 4, in <module>\n' +
                        '    afisare_nume(alias="the King of Kings", nume="KJ")    \n' +
                        'TypeError: afisare_nume() got an unexpected keyword argument \'alias\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Amestecare argumente poziționale cu argumente prin cuvinte cheie</b>
                    <br/>

                    In acest sens, exista urmatoarele reguli:
                    <ul>
                        <li><b>argumente poziționale trebuie puse înaintea argumentelor prin cuvinte cheie</b></li>
                        <li><b>dacă se încerca să trimiterea de mai mult de o valoare unui singur argument, se obtine o eroare de rulare.</b></li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def adunare(a, b, c):\n' +
                        '    print(a, "+", b, "+", c, "=", a + b + c)\n' +
                        '\n' +
                        '# Call the adding function here.\n' +
                        'adunare(1,2,3) # ok\n' +
                        'adunare(1,c=3,b=2) # ok\n' +
                        'adunare(1,a=3,b=2) # nu e ok, argumentul a primeste valorile 1 si 3 '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1 + 2 + 3 = 6\n' +
                        '1 + 2 + 3 = 6\n' +
                        'Traceback (most recent call last):\n' +
                        '  File "main.py", line 7, in <module>\n' +
                        '    adunare(1,a=3,b=2) # ok\n' +
                        'TypeError: adunare() got multiple values for argument \'a\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Valori implicite parametrilor</b>
                    <br/>

                    Unui parametru i se poate stabili o valoare implicita:
                    <br/>
                    <b><i>nume_parametru</i>=<i>valoare_implicita</i></b>.
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def afisare_nume(nume,prenume="REGE"):\n' +
                        '    print("nume=",nume, "prenume=",prenume)\n' +
                        '    \n' +
                        'afisare_nume("KJ")    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nume= KJ prenume= REGE'}
                    </SyntaxHighlighter>

                    <b>Reguli:</b>
                    <ul>
                        <li><b>argumente implicite vin dupa argumentele ne-implicite</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def afisare_nume(nume="KJ",prenume): # nu e ok \n' +
                                '    print("nume=",nume, "prenume=",prenume)\n' +
                                '    \n' +
                                'afisare_nume(prenume="the King of Kings")  '}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'  File "main.py", line 1\n' +
                                '    def afisare_nume(nume="KJ",prenume):\n' +
                                '                    ^\n' +
                                'SyntaxError: non-default argument follows default argument'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>11. Functii care returneaza valori</b>
                    <br/>
                    Daca vrem ca o functie sa returneze valori, vom folosi cuvantul cheie <b>return</b>:
                    <br/>
                    <b>return <i>expresie</i></b>
                    <br/><br/>
                    Cand se intalneste instructiunea <b>return</b> se iese din  imediat si se returneaza valoarea <i>expresie</i>.
                    Daca nu se specifica <i>expresie</i>, atunci implicit este valoarea <b>None</b> (deci <b>return</b> (fara <i>expresie</i>)este echivalent cu <b>return None</b>).
                    <br/>
                    <br/>
                    <b>Observatii:</b>
                    <ul>
                        <li>dacă o funcție nu este destinată să producă un rezultat, utilizarea instrucțiunii return nu este obligatorie</li>
                        <li>se poate folosi pentru a încheia activitățile unei funcții la cerere, înainte ca controlul să ajungă la ultima linie a funcției</li>
                    </ul>

                    <i>Exemplu</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def adunare(a,b):\n' +
                        '   return a+b\n' +
                        '    \n' +
                        'print("a+b=",adunare(5,7))    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a+b= 12'}
                    </SyntaxHighlighter>
                    <hr/>
                    <i>Exemplu</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def adunare(a,b):\n' +
                        '   return\n' +
                        '    \n' +
                        'print("a+b=",adunare(5,7))    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a+b= None'}
                    </SyntaxHighlighter>

                    <hr/>
                    <i>Exemplu</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def adunare(a,b):\n' +
                        '   pass\n' +
                        '    \n' +
                        'print("a+b=",adunare(5,7))    '}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a+b= None'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Observatii despre None:</b>
                    <ul>
                        <li>este <b> cuvant cheie</b></li>
                        <li><b>nu reprezinta nici o valore</b> , deci <b>nu poate fi folosita intr-o expresie</b> (exemplu: None+3 {"=>"} TypeError: unsupported operand type(s) for +: 'NoneType' and 'int')</li>
                        <li>daca o functie nu returneaza o valoare, atunci implicit returneaza <b>None</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def foame(mancare):\n' +
                                '    if mancare==True:\n' +
                                '        return not mancare\n' +
                                '\n' +
                                'print(foame(True))\n' +
                                'print(foame(False))'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'False\n' +
                                'None'}
                            </SyntaxHighlighter>

                        </li>
                        <li>poate fi utilizat:
                        <ul>
                            <li>la atribuire unui variabile (de exemplu: a=None)</li>
                            <li>returnarea unui rezultat a unui functii (de exemplu: return None)</li>
                            <li>compararea cu o variabila, pentru a testa starea interna
                                <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                    {'a=2\n' +
                                    'print (a==None)\n' +
                                    'print (a is None)\n' +
                                    'print(None==None)'}
                                </SyntaxHighlighter>
                                Rezultat:
                                <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                    {'False\n' +
                                    'False\n' +
                                    'True'}
                                </SyntaxHighlighter>
                            </li>
                        </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>12. Domeniu de vizibilitate/aplicare a unei nume (scope of a name)</b>
                    <br/>

                    Domeniul de aplicare al unui nume (de exemplu, un nume de variabilă) este partea din cod în care numele este recunoscut sau vizibil.

                    <ul>
                        <li>domeniul de aplicare al parametrului unei funcții este funcția în sine, adica se vede doar in functie</li>
                        <li>o variabila existenta in afara unei functii (variabila externa) este vizibila in interiorul functiilor, adica se vede si in functii
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie():\n' +
                                '    print("in functie, x=",x)\n' +
                                '\n' +
                                'x = 1\n' +
                                'functie()\n' +
                                'print("in afara functiei, x=",x)\n'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'in functie, x= 1\n' +
                                'in afara functiei, x= 1'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            o variabilă externa este vizibila in interiorul functiilor, <b>dar</b> se poate folosi <b>numai pentru obținerea valorii acesteia (citire)</b>.
                            <br/>
                            <b>atribuirea unei valori forțează crearea propriei variabile a funcției</b>, care ascunde variabila externa.
                            <br/>
                            daca dorim, <b>sa extindem scopul</b> unei variabile externe si interiorul unei functii, si pentru citire si pentru  <b>sciere </b>, atunci folosim cuvantul cheie <b>global</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'global nume\n' +
                                'global nume1, nume2, ... ,numeN'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie():\n' +
                                '    x=3 # x nu mai x din afara functiei\n' +
                                '    print("in functie, x=",x)\n' +
                                '\n' +
                                'x = 1\n' +
                                'functie()\n' +
                                'print("in afara functiei, x=",x)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'in functie, x= 3\n' +
                                'in afara functiei, x= 1'}
                            </SyntaxHighlighter>

                            <hr/>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie():\n' +
                                '    global x # x e bun acum pentru scriere, si pentru citire\n' +
                                '    x=3\n' +
                                '    print("in functie, x=",x)\n' +
                                '\n' +
                                'x = 1\n' +
                                'functie()\n' +
                                'print("in afara functiei, x=",x)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'in functie, x= 3\n' +
                                'in afara functiei, x= 3'}
                            </SyntaxHighlighter>

                            <hr/>
                            Daca vom scrie: global x=3, vom obtine e eroare de sintaxa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie():\n' +
                                '    global x=3\n' +
                                '    print("in functie, x=",x)\n' +
                                '\n' +
                                'x = 1\n' +
                                'functie()\n' +
                                'print("in afara functiei, x=",x)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'File "main.py", line 2\n' +
                                '    global x=3 # x e bun acum pentru scriere, si pentru citire\n' +
                                '            ^\n' +
                                'SyntaxError: invalid syntax'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>
                    <hr/>
                    <b>Observatii</b>:
                    <ul>
                        <li>dacă se modifica o listă trimisa unei functii (adica se modifica doar continutul, de exemplu se sterge un element din lista), acest lucru se va reflecta si in afara functiei</li>
                    </ul>

                    <hr/>
                    <b>Extra</b>
                    <br/>
                    <b>Simbolul backslash (\)</b> utilizat în codul Python ca mod de a încheia o linie, îi va spune lui Python să continue linia de cod pe următoarea linie de cod.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("azi a \\\n' +
                        'fost frumos")'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'azi a fost frumos'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionsPythonContent;