import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ExpunereVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-expunere", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Expunere</b>
                    <br/>
                    <br/>
                    <b>Expunere</b> = cantitate totala de lumina care ajunge pe senzor.
                    <br/>
                    <i>Expunerea "corecta"</i>:
                    <ul>
                        <li>reda o imagine cu aceeasi intensitate luminioasa (valori) pe care ar percepe-o ochii nostri (in acelasi conditii de lumina)</li>
                        <li>se refera la <i>cantitatea de lumina ideala</i> catre ajunge pe senzor, astfel incat scena pe care o dorim surprinsa sa fie cat mai asemanatoare cu felul in care am vazut-o in acel moment</li>
                    </ul>

                    <i>Subexpunere</i> = o imagine care primeste mai putina lumina decat ar avea nevoie pentru a fi o expunere corecta.
                    <br/>
                    <i>Supraexpunere</i> = o imagine care primeste mai multa lumina decat ar avea nevoie pentru a fi o expuenre corecta.

                    <br/>
                    <br/>
                    <b>Stop</b> = diferenta dintre tonuri de gri (valori de lumina).
                    <br/>
                    <b>Stop</b> = <b>diafragma</b> (pe video).

                    <br/>
                    <br/>

                    Expunerea, si implicit si stop-urile, depinde de:
                    <ul>
                        <li><b>viteza obturatorului (shutter speed)</b>
                            <ul>
                                <li>in fotografie, cand se apasa pe declasator, cortina (obtureatorul) trece prin fata senzorului, controland <b>cat timp</b> permite luminii să ajunga pe senzor</li>
                                <li>in video, (datorita informatiei imense pe care aparatul trebuie s-o inregistreze) <b>cortina nu este folosita</b>, sensorul este expun in totalitate la lumina:
                                    <ul>
                                        <li>informatia se citeste pixel cu pixel, de sus in jos si de la stanga la dreapta, pe masura ce senzorul capteaza lumina</li>
                                        <li>valorile captate sunt transformate in imagini / <b>fotograme</b></li>
                                        <li>viteza opturatorului este legata de numarul de imagini / fotograme pe secunda (fps) la care se inregistraza video-ul;
                                            <br/>
                                            <b><i>regula</i>: viteza obturatorului = dublulul fotogramelor</b>
                                            <br/>
                                            Exemple:
                                            <ul>
                                                <li>faca filmam la 25fps, atunci viteza obturatorului va fi 1/50</li>
                                                <li>faca filmam la 30fps, atunci viteza obturatorului va fi 1/60</li>
                                                <li>faca filmam la 60fps, atunci viteza obturatorului va fi 1/125</li>
                                            </ul>
                                            dar, daca se filmeaza o actiune dinamica, se mareste viteza obturatorului (de exemplu, daca filmam la 60fps, putem mari la 1/180)
                                            <br/>
                                            Daca se vrea obtinerea de efecte:
                                            <ul>
                                                <li>sters, blutat: se microreaza viteza obturatorului (de exemplu: 1/4)</li>
                                                <li>mai clar: se mareste viteza obturatorului (de exemplu: 1/500)</li>
                                                <li>rolling shutter: imaginea se schimba mai repede decat viteza obturatorului (pe camere foto, nu exista acest efect, pentru ca imaginea este citita in totalitate, nu pixel cu pixel)</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <i>observatie</i>:
                                    <br/>
                                    pe camerele video (cine):
                                    <ul>
                                        <li>fiecare fotograma este citia in totalitate, si nu pixel cu pixel</li>
                                        <li>obturatorul poate fi de forma unui disc sicronizat cu fps-ul si:
                                            <br/>
                                            - pentru fiecare fotograma, discul o dat expune senzorul la lumina si o data il obtureaza
                                            <br/>
                                            - viteaza obturatorului se masoara in grade (datorita ratatiei discului); la 25fps avem: 1/50 ~ 180°, 1/100 ~ 90°
                                        </li>
                                    </ul>


                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>diafragma (aperture sau f/stop)</b>
                            <ul>
                                <li>in fotografie si in video, controleaza <b>cata</b> lumina ajunge pe senzor (in timp ce este este expus la lumina - timp controlat de viteza obturatorului) </li>
                                <li>
                                    Exemplu:
                                    <ul>
                                        <li>la diafragma deschisa f/1.4 intra mai multa lumina decat la o diagrafma mai inchisa f/16</li>
                                    </ul>
                                </li>
                                <li>determina profunzimea sau adancimea campului (DOF - depth of field)
                                    <br/>
                                    (observatie:
                                    <br/>
                                    dof-ul este determinat si de dimensiunea senzorului; un senzor mai mic are adâncime de câmp mai mare;
                                    <br/>
                                    de exemplu:
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>senzor</th>
                                                <th>factor</th>
                                                <th>distanța focală fizică</th>
                                                <th>distanta focala efectiva</th>
                                                <th>diagrama</th>
                                                <th>dof</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>full frame</td>
                                                <td>1</td>
                                                <td>120mm</td>
                                                <td>120mm</td>
                                                <td>f/9</td>
                                                <td>0,92m</td>
                                            </tr>
                                            <tr>
                                                <td>APS-C</td>
                                                <td>1.5</td>
                                                <td>80mm</td>
                                                <td>120mm</td>
                                                <td>f/9</td>
                                                <td>1,42m</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>ISO (gain)</b>
                            <ul>
                                <li>amplifica lumina imaginii</li>
                                <li>amplifica zgomotul</li>
                                <li>sensibilitate unui senzor este setata la crerea senzorului, iar orice amplificare (ISO 100, ISO 3200, ISO 128.000) este costituita dintr-un gain analog si gain digital, care impreuna cu un filtru care curata zgomotul (noise-ul digital) produs in urma unui aplificari, ridica luminozitatea din imagine;

                                    cu urmatoarele dezavantaje:
                                    <ul>
                                        <li>
                                            cresterea zgomotului
                                        </li>
                                        <li>
                                            pierderea detaliilor
                                        </li>
                                        <li>
                                            alerarea saturatiei
                                        </li>
                                        <li>
                                            alterarea gamei dinamice
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <i>observatie</i>:
                                    <br/>
                                    pe pelicula sensibilitatea este data de densitatea si marimea cristalelor care o compun (halogenura de argint):
                                    <br/>
                                    - o rola de ISO 50 este numita slow-film (densitate mare, cristale mici {"=>"} imagina cu mai putin zgomot)
                                    <br/>
                                    - o rola de ISO 500 este numita fast-film (densitate mic, cristale mare {"=>"} imagina cu mai mult zgomot)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>
                    <b>2. Timelapse</b>
                    <br/>
                    Daca vrem sa facem un clip de 10s la 25fps pentru un eveniment de 1h, atunci:
                    <ul>
                        <li>1h = 60 minute {"=>"} 3600 secunde</li>
                        <li>10s la 25fps {"=>"} 250 imagini</li>
                        <li>3600s / 250 imagini {"=>"} 14.4s intervalul la care trebuie facute pozele</li>
                    </ul>
                    <hr/>
                    <b>3. Dynamic Range (Gama dinamica)</b>
                    <br/>
                    <b>Dynamic range</b> = distanta in stop-uri de la cel mai intunecat obiect si pana la cel mai luminat;
                    <br/>
                    Aparatele foto pot surprinde o gama dinamica restransa, prin urmare uneori trebuie sa alegem ce vrem sa expunem corect si ce informatie suntem dispusi sa o pierdem.
                    <br/>
                    De exemplu:
                    <ul>
                        <li>
                            daca suntem mai interesati de pamant, atunci vom superaexpune cerul (va deveni alb)
                        </li>
                        <li>
                            daca suntem mai interesati de cer, atunci vom subexpune pamantul (va deveni negru)
                        </li>
                    </ul>

                    <hr/>
                    <b>4. Exercitii</b>
                    <br/>
                    Filmam cu 30fps, f5.6, ISO 1600, 1/60.
                    <ul>
                        <li>
                            adancime mai mica, fie f/2 (castigam 3 stopuri de lumina):
                            <br/>
                            1/60 (nu se poate schimba, pentru ca filmam la 30fps)
                            <br/>
                            {"=>"} putem modifica doar ISO; si pentru ca am castigat 3 stopuri de lumina, trebuie sa scadem 3 stopuri din ISO: 1600 {"->"} 800 {"->"} 400 {"->"} 200
                            <br/>
                            {"=>"} deci ISO 200.
                        </li>
                        <li>adancime mai mare, fie f/11 (pierdem 2 stopuri de lumina):
                            <br/>
                            1/60 (nu se poate schimba, pentru ca filmam la 30fps)
                            <br/>
                            {"=>"} putem modifica doar ISO; si pentru ca am pierdut 2 stopuri de lumina, trebuie sa aducem 2 stopuri din ISO: 1600 {"->"} 3200 {"->"} 6400
                            <br/>
                            {"=>"} deci ISO 6400.
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExpunereVideografieContent;