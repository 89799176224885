import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class IgnoreGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-ignore", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Fisierul .ignore</b>
                    <br/>
                    <br/>

                    Daca se doreste ca anumite fisiere sau directoare sa nu fie urmarite, atunci se poate creea un fisier <b>.gitignore</b> in care se vor specifica pe fiecare rand ce fisiere sau directoare trebuie ignorate.
                    <br/>
                    Acest fisier este creat in radacina proiectului.

                    <br/>
                    <br/>
                    Exemplu de continut a unui fisier <b>.gitignore</b>
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'*.exe\n' +
                        '/configuration\n' +
                        '/build'}
                    </SyntaxHighlighter>

                    Daca se specifica in fisier si <b>.gitignore</b>, atunci se va ignora si fisierul <b>.gitignore</b>.

                    <br/>
                    <br/>
                    <b>Observatie</b>
                    <br/>
                    <br/>
                    Daca un fisier este urmarit si apoi adaugat in fisierul <b>.gitignore</b>, atunci acesta va contiua sa fie urmarit!
                    Pentru a nu mai fi urmarit trebuie, in mod explict, executata o comanda de dezurmarire (untracked).

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IgnoreGitContent;