import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class OverrideMVCActionCommandPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-override-mvc-action-command", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Atunci cand se suprascrie o comanda MVC action, trebuie sa tinem cont de:
                    <ul>
                        <li>proprietatea <b>javax.portlet.name</b>: care va indica portletul suprascris</li>
                        <li>proprietatea <b>mvc.command.name</b>: care va indica numele comenzii suprascrise</li>
                        <li>proprietatea <b>service.ranking:Integer</b>: care va indica importanta portletului custom;
                            <br/>
                            daca exista 2 portleti cu acelasi nume si aceeasi actiune se va lua in considerarea doar cel cu <b>ranking</b>-ul cel mai mare
                        </li>
                        <li>
                            daca vream sa apelam vechea implementare, va trebuie sa obtinem o referinta catre aceasta:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Reference(target = "(component.name=com.liferay.blogs.web.internal.portlet.action.EditEntryMVCActionCommand)")\n' +
                                'protected MVCActionCommand mvcActionCommand;'}
                            </SyntaxHighlighter>
                            si apoi sa o apelam:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'mvcActionCommand.processAction(actionRequest, actionResponse);'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>


                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package mvc.commands.override;\n' +
                        '\n' +
                        'import com.liferay.blogs.constants.BlogsPortletKeys;\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        '\n' +
                        'import javax.portlet.ActionRequest;\n' +
                        'import javax.portlet.ActionResponse;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "javax.portlet.name=" + BlogsPortletKeys.BLOGS_ADMIN,\n' +
                        '                "mvc.command.name=/blogs/edit_entry",\n' +
                        '                "service.ranking:Integer=100"\n' +
                        '        },\n' +
                        '        service = MVCActionCommand.class\n' +
                        ')\n' +
                        'public class CustomBlogsMVCActionCommand extends BaseMVCActionCommand {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                        '\n' +
                        '        System.out.println("logica particulara...");\n' +
                        '\n' +
                        '        // apeleaza comanda MVC Action originala\n' +
                        '        mvcActionCommand.processAction(actionRequest, actionResponse);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Reference(target = "(component.name=com.liferay.blogs.web.internal.portlet.action.EditEntryMVCActionCommand)")\n' +
                        '    protected MVCActionCommand mvcActionCommand;\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>
                                    MVC Render Command
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OverrideMVCActionCommandPortletContent;