import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlApplyContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-apply", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl apply</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectl apply</b> este folosita pentru a schimba clusterul Kubernates:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f <filename>'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>apply: pentru a schimba configuratia curenta clusterului</li>
                        <li>f: pentru a specifica fisierul care contine schimbarile de configuratie</li>
                        <li>filename: calea catre fisierul de configurare</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f client-pod.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f client-node-port.yaml'}
                    </SyntaxHighlighter>

                    O alta forma a comenzii <b>kubectl apply</b> prespune existenta unui director care contine fisiere de configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f k8s'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'service/client-cluster-ip-service created\n' +
                            'deployment.apps/client-deployment created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {' kubectl get deployments\n' +
                            'NAME                READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'client-deployment   3/3     3            3           2m25s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get services\n' +
                            'NAME                        TYPE        CLUSTER-IP      EXTERNAL-IP   PORT(S)    AGE\n' +
                            'client-cluster-ip-service   ClusterIP   10.108.213.96   <none>        3000/TCP   3m3s\n' +
                            'kubernetes                  ClusterIP   10.96.0.1       <none>        443/TCP    3d21h'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get pods\n' +
                            'NAME                                 READY   STATUS    RESTARTS   AGE\n' +
                            'client-deployment-576bc76988-26sj4   1/1     Running   0          3m33s\n' +
                            'client-deployment-576bc76988-hpr94   1/1     Running   0          3m33s\n' +
                            'client-deployment-576bc76988-xdk45   1/1     Running   0          3m33s'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlApplyContent;