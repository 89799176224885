import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ProcessingFilesPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-processing-files", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Procesarea fisierelor</b>
                    <br/> <br/>

                    <b>1. Citirea fisierelor text</b>
                    <br/>
                    <br/>
                    Dupa deschiderea unui fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'flux = open("fisier.txt", "rt", encoding = "utf-8")'}
                    </SyntaxHighlighter>

                    Pentru urmatoarele exemple, vom folosi un fisier numit <i>fisier.txt</i> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Sunt KJ.\n' +
                        'Si am concediu de 4 saptamani.'}
                    </SyntaxHighlighter>

                    Putem sa folosim urmatoarele metode:
                    <ul>
                        <li><b>read()</b>: citeste tot continutul fisierului
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.read())\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>read(n)</b>: citeste <b>n</b> caractere
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.read(7)) # Sunt KJ\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>readline()</b>: citeste o linie
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.readline()) # Sunt KJ.\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>readline(n)</b>: citeste primele <b>n</b> caractere din linia curenta citita
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.readline(4)) # Sunt\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.readline(44)) # Sunt KJ.\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>readlines()</b>: returneaza o lista cu fiecare linie din fisier
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.readlines()) # [\'Sunt KJ.\\n\', \'Si am concediu de 4 saptamani.\']\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>readlines(hint)</b>: returneaza o lista cu linii din fisier, dar limitate de parametrul <b>hint</b> pentru a limita numărul de linii returnate.
                            <br/>Dacă numărul total de octeți (caractere) returnați <b>depășește numărul specificat prin parametrul hint</b>, <b>nu mai sunt returnate linii</b>.

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.readlines(9)) # [\'Sunt KJ.\\n\', \'Si am concediu de 4 saptamani.\']\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                                'print(flux.readlines(7)) # [\'Sunt KJ.\\n\']\n' +
                                'flux.close()'}
                            </SyntaxHighlighter>
                        </li>


                    </ul>

                    Pentru a verifica ca nu s-a ajuns la sfarsitul fisierului:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'linie != \'\''}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'caracter != \'\''}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'flux = open("fisier.txt", "rt", encoding="utf-8")\n' +
                        'caracter = flux.read(1)\n' +
                        'p = 1\n' +
                        'while caracter!=\'\':\n' +
                        '    print(caracter if (p%2==0) else "",end="")\n' +
                        '    p=p+1\n' +
                        '    caracter = flux.read(1)\n' +
                        'flux.close()\n' +
                        '\n' +
                        '# Se afiseaza doar caracterele de pozitie para: utK.S mcnei e4spaai'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    <br/>
                    <b>Functia open() returneaza un obiect iterabil</b>.
                    <br/>
                    Protocolul de iterație definit pe obiectul returnat de open() are metoda <b>__next__()</b> care returnează doar următoarea linie citită din fișier.
                    Cand se ajunge la sfarsitul fisierului, obiectul invoca automat metoda <b>close()</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for linie in open("fisier.txt", "rt"):\n' +
                        '    print(linie)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Scrierea in fisiere text</b>
                    <br/>
                    <br/>

                    Pentru a putea scrie intr-un fisier:
                    <ul>
                        <li>fisierul trebuie deschis in mod scriere (de exemplu: w sau w+), altfel se obtine eroare la incercarea de a scrie in el</li>
                        <li>folosita metoda <b>write()</b> care accepta un sir de caractere</li>
                    </ul>

                    Urmatorul fragment va creea un fisier numit <i>fisier2.txt</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'flux = open("fisier2.txt","w")\n' +
                        'flux.write("Aici tot KJ!\\n")\n' +
                        'flux.write("Stop!\\n")\n' +
                        'flux.close()'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Aici tot KJ!\n' +
                        'Stop!'}
                    </SyntaxHighlighter>

                    Daca se vrea trimiterea unui text catre fluxul de erori implicit:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import sys\n' +
                        'sys.stderr.write("text")'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Date amorfe</b>
                    <br/>
                    <br/>
                    Datele amorfe sunt date care nu au o formă sau formă specifică - sunt doar o serie de octeți (poate reprezenta de exemplu o imagine bitmap).
                    Una din clasele care pot fi folosite pentru a gestiona acest gen de date este <b>bytearray</b> - o matrice care conține octeți.
                    <br/>

                    Creare obiect care sa stocheze <i>n</i> octeti, poate fi construi in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' date = bytearray(n) # un astfel de constructor umple întregul tablou cu zerouri'}
                    </SyntaxHighlighter>

                    <b>Bytearray</b>-urile seamănă cu liste:
                    <ul>
                        <li>sunt mutabile</li>
                        <li>se poate folosi functia len()</li>
                        <li>se poate accesa oricare dintre elementele folosind indexarea</li>
                    </ul>

                    Diferente in raport cu listele:
                    <ul>
                        <li>toate elementele sunt <b>valori intregi</b> (încălcarea acestei reguli va provoca o excepție de tipul <b>TypeError</b>) </li>
                        <li>toate valorile trebuie sa fie <b>in intervalul [0,255]</b> (incalcarea acestei reguli ca provacaa o exeptie de tipul <b>ValueError</b>)</li>
                    </ul>

                    <hr/>
                    <b>4. Scrierea in fisiere binare</b>
                    <br/>
                    <br/>

                    Pentru a scrie intr-un fisier binar il vom deschide pentru scriere (exemplu: <b>wb</b>) si vom folosi metoda:
                    <ul>
                        <li><b>write(ba)</b>: care primeste ca argument un obiect de tip <b>bytearray</b> si <b>returneaza număr de octeți scriși cu succes</b> </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ba = bytearray(2)\n' +
                        '\n' +
                        'ba[0]=5\n' +
                        'ba[1]=8\n' +
                        '\n' +
                        'flux = open("fisier3.bin","wb")\n' +
                        'flux.write(ba)\n' +
                        'flux.close()'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Citirea din fisiere binare</b>
                    <br/>
                    <br/>

                    Pentru a citi dintr-un fisier binar il vom deschide pentru citire (exemplu: <b>rb</b>) si vom folosi metodale:
                    <ul>
                        <li><b>readinto(ba)</b>: încearcă să umple întregul spațiu disponibil pus la dispozitie de argumentul sau de tip <b>bytearray</b> si <b>returnează numărul de octeți citiți cu succes;</b>
                            <br/>
                            dacă există mai multe date în fișier decât spațiu în argument, <b>operația de citire se va opri înainte de sfârșitul fișierului</b>;
                            în caz contrar, rezultatul metodei poate indica ca obiectul <b>bytearray</b> dat ca argument, <b>este umplut doar partial</b>;
                        </li>
                        <li>
                            <b>read():</b>
                                <ul>
                                    <li>invocat fără argumente, încearcă să citească tot conținutul fișierului în memorie, returnand un obiect de tip <b>bytes</b> (similar cu <b>bytearray</b>, dar imutabil)

                                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                            {'flux = open("fisier3.bin","rb")\n' +
                                            'ba = flux.read()\n' +
                                            '\n' +
                                            'print(type(ba)) # <class \'bytes\'>\n' +
                                            'print(type(bytearray(ba))) # <class \'bytearray\'>\n' +
                                            '\n' +
                                            'for x in ba:\n' +
                                            '    print(x, end=",") # 5,\n' +
                                            'flux.close()'}
                                        </SyntaxHighlighter>

                                    </li>
                                    <li>invocată cu un argument, aceasta specifică numărul maxim de octeți care trebuie citiți. <br/>
                                        metoda încearcă să citească numărul dorit de octeți din fișier, iar lungimea obiectului returnat poate fi folosită pentru a determina numărul de octeți citiți efectiv.


                                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                            {'from os import strerror\n' +
                                            '\n' +
                                            'try:\n' +
                                            '    flux = open("fisier3.bin","rb")\n' +
                                            '    ba = flux.read(100)\n' +
                                            '\n' +
                                            '    print(len(ba))\n' +
                                            '\n' +
                                            '    flux.close()\n' +
                                            'except IOError as e:\n' +
                                            '    print("Eroare I/O:", strerror(e.errno))'}
                                        </SyntaxHighlighter>
                                    </li>
                                </ul>

                        </li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ba = bytearray(2)\n' +
                        '\n' +
                        'flux = open("fisier3.bin","rb")\n' +
                        'flux.readinto(ba)\n' +
                        '\n' +
                        'for x in ba:\n' +
                        '    print(x, end=",") # 5,8\n' +
                        'flux.close()'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ba = bytearray(5)\n' +
                        '\n' +
                        'flux = open("fisier3.bin","rb")\n' +
                        'flux.readinto(ba)\n' +
                        '\n' +
                        'for x in ba:\n' +
                        '    print(x, end=",") # 5,8,0,0,0,\n' +
                        'flux.close()'}
                    </SyntaxHighlighter>

                    Exemplu 3:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ba = bytearray(1)\n' +
                        '\n' +
                        'flux = open("fisier3.bin","rb")\n' +
                        'flux.readinto(ba)\n' +
                        '\n' +
                        'for x in ba:\n' +
                        '    print(x, end=",") # 5,\n' +
                        'flux.close()'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ProcessingFilesPythonContent;