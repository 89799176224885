import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class LogoutLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-logout", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Logout</b>
                    <br/>
                    <br/>


                    Pentru Logout se foloste metoda <b>Auth::logout()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'use Illuminate\\Http\\Request;\n' +
                            'use Illuminate\\Http\\RedirectResponse;\n' +
                            'use Illuminate\\Support\\Facades\\Auth;\n' +
                            ' \n' +
                            '/**\n' +
                            ' * Log the user out of the application.\n' +
                            ' */\n' +
                            'public function logout(Request $request): RedirectResponse\n' +
                            '{\n' +
                            '    Auth::logout();\n' +
                            ' \n' +
                            '    $request->session()->invalidate();\n' +
                            ' \n' +
                            '    $request->session()->regenerateToken();\n' +
                            ' \n' +
                            '    return redirect(\'/\');\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sau, daca autentificarea a fost pe baza de cookie, in <b>/app/Http/Controllers</b> in clasa AuthController.php, adaugam:
                    <SyntaxHighlighter  showLineNumbers={true} language="php" style={androidstudio}>
                        {'public function logout(){\n' +
                            '\t$cookie = \\Cookie::forget(\'jwt\');\n' +
                            '\t\n' +
                            '\treturn response([\n' +
                            '\t\t\'message\'=>\'succes\'   \n' +
                            '\t])->withCookie($cookie)\n' +
                            '}'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"Route::prefix('admin')->group(function(){\n" +
                            "  Route::post('register', [AuthController::class, 'register'])\n" +
                            "  Route::post('login', [AuthController::class, 'login'])\n" +
                            "  Route::middleware(['auth:sanctum'])->group(){\n" +
                            "       Route::get('user', [AuthController::class, 'user'])\n" +
                            "       Route::post('logout', [AuthController::class, 'logout'])\n" +
                            "  });\n" +
                            "})"}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LogoutLaravelContent;