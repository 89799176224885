import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../elasticsearch/IndexContent";

class MappingElasticsearchContent extends BaseContentPage  {

    constructor(props) {
        super(props, "elasticsearch-mapping", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Maparea: definirea unei scheme</b>
                    <br/>
                    <br/>

                    O mapare este o definirea unei scheme.

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XPUT 127.0.0.1:9200/movies -d \'{\n' +
                            '\t"mappings":{\n' +
                                '\t\t"properties":{\n'+
                                    '\t\t\t"year":{"type":"date"}\n'+
                                '\t\t}\n'+
                            '\t}\n' +
                            '}\''}
                    </SyntaxHighlighter>
                    unde,
                    <ul>
                        <li>movies = nume indexului</li>
                        <li>mappings = defineste structura/schema index-ului, ce proprietati are</li>
                    </ul>

                    <hr/>
                    <b>2. Definirea unui camp: type, index, analyzer</b>
                    <br/>
                    <br/>
                    Un camp:
                    <ul>
                        <li>are un tip, specificat prin subcampul <b>type</b></li>
                        <li>poate fi indexat, specificat prin subcampul <b>index</b></li>
                        <li>poate fi analizatorul, specificat prin subcampul <b>analyzer</b></li>
                    </ul>

                    Pentru a specifica tipul de date a unui camp, se folosest campul <b>type</b>, care poate lua urmatoarele valori:
                    <ul>
                        <li>String</li>
                        <li>byte</li>
                        <li>integer</li>
                        <li>long</li>
                        <li>float</li>
                        <li>double</li>
                        <li>boolean</li>
                        <li>date</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'"properties":{\n'+
                            '\t"year":{"type":"date"}\n'+
                            '}\n'+
                            ''}
                    </SyntaxHighlighter>

                    Pentru a specifica daca un camp sa fie indexat pentru cautare full-text, se foloseste campul <b>index</b>, care poate lua urmatoarele valori:
                    <ul>
                        <li>analyzed</li>
                        <li>not-analyzed</li>
                        <li>no</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'"properties":{\n'+
                            '\t"gendre":{"index":"not_analyzed"}\n'+
                            '}\n'+
                           ''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Analizatori</b>
                    <br/>
                    <br/>
                    Analizatorii pot face urmatoarele 3 lucruri (sau au rolul de):
                    <ul>
                        <li>filtru  de caractere: sterge HTML encoding, converteste '&' la 'si'/'and'</li>
                        <li>tokenizer: sparge un sir de caractere (string)  dupa caractere albe, punctuatie, orice ce nu este o litera</li>
                        <li>filtru token: lowercasing, stemming (normalizarea unui cuvant la radacina lui), sinonime, stopwords (si, sau, the);
                            <br/>
                            nu se foloseste stopwords daca se doreste cautarea unei expresii,  fraze (gen: to be or not to be)
                        </li>
                    </ul>
                    Analizatori:
                    <ul>
                        <li>standard: sparge dupa cuvinte, sterge punctualia, transforma catre litere mici (lowercases); recomandata daca limba nu este cunoscuta</li>
                        <li>simple: sparge dupa orice nu este litera + transforma catre litere mici</li>
                        <li>whitespace: page dupa spatii albe (da nu transforma catre litere mici); pastreaza punctuatia</li>
                        <li>language (engleza): stopwords+ stemming</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MappingElasticsearchContent;