import {useRoutes} from "react-router-dom";
import React from "react";
import DockerHomeContent from "./IndexContent";
import IntroDockerContent from "./IntroDockerContent";
import CleanupCmdDockerContent from "./CleanupCmdDockerContent";
import ConnectDockerContent from "./ConnectDockerContent";
import CmdDockerContent from "./CmdDockerContent";
import UbuntuDockerContent from "./UbuntuDockerContent";
import LoginDockerContent from "./LoginDockerContent";
import SystemCtrlDockerContent from "./SystemCtrlDockerContent";
import ComposeDockerContent from "./ComposeDockerContent";
import VersionDockerContent from "./VersionDockerContent";
import HelloWorldDockerContent from "./HelloWorldDockerContent";
import RunDockerContent from "./RunDockerContent";
import PsDockerContent from "./PsDockerContent";
import CreateDockerContent from "./CreateDockerContent";
import StartDockerContent from "./StartDockerContent";
import SystemPruneDockerContent from "./SystemPruneDockerContent";
import LogsDockerContent from "./LogsDockerContent";
import StopDockerContent from "./StopDockerContent";
import KillDockerContent from "./KillDockerContent";
import InfoDockerContent from "./InfoDockerContent";
import ExecDockerContent from "./ExecDockerContent";
import ItFlagsDockerContent from "./ItFlagsDockerContent";
import DockerfileDockerContent from "./DockerfileDockerContent";
import CommitDockerContent from "./CommitDockerContent";
import TagImageDockerContent from "./TagImageDockerContent";
import Ex1DockerContent from "./Ex1DockerContent";
import DockerfileCopyDockerContent from "./DockerfileCopyDockerContent";
import PortMappingFlagDockerContent from "./PortMappingFlagDockerContent";
import DockerfileWorkdirDockerContent from "./DockerfileWorkdirDockerContent";
import Ex2DockerContent from "./Ex2DockerContent";
import DockerComposeContent from "./DockerComposeContent";
import DFlagsDockerContent from "./DFlagsDockerContent";
import DockerComposeUpContent from "./DockerComposeUpContent";
import DockerComposeDownContent from "./DockerComposeDownContent";
import RestartPoliciesDockerContent from "./RestartPoliciesDockerContent";
import DockerComposePsContent from "./DockerComposePsContent";
import Ex3DockerContent from "./Ex3DockerContent";
import DockerIgnoreContent from "./DockerIgnoreContent";
import DockerBuildTContent from "./DockerBuildTContent";
import VolumeFlagDockerContent from "./VolumeFlagDockerContent";
import WFlagDockerContent from "./WFlagDockerContent";
import EFlagDockerContent from "./EFlagDockerContent";
import DockerComposeVolumesContent from "./DockerComposeVolumesContent";
import MultiStepDockerBuildsContent from "./MultiStepDockerBuildsContent";
import HistoryDockerContent from "./HistoryDockerContent";
import DockerComposeEnvironmentContent from "./DockerComposeEnvironmentContent";
import NetworkingDockerContent from "./NetworkingDockerContent";
import GithubDockerContent from "./GithubDockerContent";
import TravisDockerContent from "./TravisDockerContent";
import AWSDockerContent from "./AWSDockerContent";
import Ex4DockerContent from "./Ex4DockerContent";
import GitHubActionsDockerContent from "./GitHubActionsDockerContent";
import Ex5DockerContent from "./Ex5DockerContent";
import Ex5ProdDockerContent from "./Ex5ProdDockerContent";
import Ex5AWSDockerContent from "./Ex5AWSDockerContent";
import Ex5CleanAWSDockerContent from "./Ex5CleanAWSDockerContent";
import InspectDockerContent from "./InspectDockerContent";
import RmDockerContent from "./RmDockerContent";
import FlagHelpDockerContent from "./FlagHelpDockerContent";
import ContainerPruneDockerContent from "./ContainerPruneDockerContent";
import ImagesDockerContent from "./ImagesDockerContent";
import RmiDockerContent from "./RmiDockerContent";
import PullDockerContent from "./PullDockerContent";
import ImagePruneDockerContent from "./ImagePruneDockerContent";
import DockerfileDockerResumeContent from "./DockerfileDockerResumeContent";
import DockerVolumeResumeContent from "./DockerVolumeResumeContent";
import DockerNetworkResumeContent from "./DockerNetworkResumeContent";
import DockerComposeResumeContent from "./DockerComposeResumeContent";
import SearchDockerContent from "./SearchDockerContent";
import PushDockerContent from "./PushDockerContent";
import TagDockerContent from "./TagDockerContent";
import ImageInspectDockerContent from "./ImageInspectDockerContent";
import RestartDockerContent from "./RestartDockerContent";
import RenameDockerContent from "./RenameDockerContent";
import AttachDockerContent from "./AttachDockerContent";
import DockerSwarmResumeContent from "./DockerSwarmResumeContent";
import ImageBuildDockerContent from "./ImageBuildDockerContent";
import HostImageRegistryDockerContent from "./HostImageRegistryDockerContent";
import SaveImageDockerContent from "./SaveImageDockerContent";
import LoadImageDockerContent from "./LoadImageDockerContent";
import PauseDockerContent from "./PauseDockerContent";
import UnpauseDockerContent from "./UnpauseDockerContent";
import ExportContainerDockerContent from "./ExportContainerDockerContent";
import ImportContainerDockerContent from "./ImportContainerDockerContent";
import StatsContainerDockerContent from "./StatsContainerDockerContent";
import TopContainerDockerContent from "./TopContainerDockerContent";
import DiffContainerDockerContent from "./DiffContainerDockerContent";
import WaitContainerDockerContent from "./WaitContainerDockerContent";
import SystemDfDockerContent from "./SystemDfDockerContent";
import CpContainerDockerContent from "./CpContainerDockerContent";
import NoneNetworkDockerContent from "./NoneNetworkDockerContent";
import DisconnectNetworkDockerContent from "./DisconnectNetworkDockerContent";
import ConnectNetworkDockerContent from "./ConnectNetworkDockerContent";
import LsNetworkDockerContent from "./LsNetworkDockerContent";
import CreateNetworkDockerContent from "./CreateNetworkDockerContent";
import InspectNetworkDockerContent from "./InspectNetworkDockerContent";
import MacvlanNetworkDockerContent from "./MacvlanNetworkDockerContent";
import DnsNetworkDockerContent from "./DnsNetworkDockerContent";
import UCPDockerContent from "./UCPDockerContent";
import K8sDockerContent from "./K8sDockerContent";
import DtrMsrDockerContent from "./DtrMsrDockerContent";
import MockDockerContent from "./MockDockerContent";
import Mock2DockerContent from "./Mock2DockerContent";
import DockerfileHealthcheckDockerContent from "./DockerfileHealthcheckDockerContent";
import DockerfileEntrypointDockerContent from "./DockerfileEntrypointDockerContent";
import ImportImageDockerContent from "./ImportImageDockerContent";
import RegistryDockerContent from "./RegistryDockerContent";
import BridgeNetworkDockerContent from "./BridgeNetworkDockerContent";
import UserDefinedBridgeNetworkDockerContent from "./UserDefinedBridgeNetworkDockerContent";
import HostNetworkDockerContent from "./HostNetworkDockerContent";
import OverlayNetworkDockerContent from "./OverlayNetworkDockerContent";
import SystemEventsDockerContent from "./SystemEventsDockerContent";
import SecurityDockerContent from "./SecurityDockerContent";
import VolumeDockerContent from "./VolumeDockerContent";
import Tips1DockerContent from "./Tips1DockerContent";
import Tips2DockerContent from "./Tips2DockerContent";
import Tips3DockerContent from "./Tips3DockerContent";
import Tips4DockerContent from "./Tips4DockerContent";
import Tips5DockerContent from "./Tips5DockerContent";
import Tips6DockerContent from "./Tips6DockerContent";
import PrivateRoute from "../../security/PrivateRoute";

/*
<Route path="/docker/index" element={<DockerHomeContent/>} exact/>
<Route element={<PrivateRoutes/>}>
    <Route path="/docker/intro" element={<IntroDockerContent/>} exact/>
    <Route path="/docker/cleanup-cmd" element={<CleanupCmdDockerContent/>} exact/>
    <Route path="/docker/connect" element={<ConnectDockerContent/>} exact/>
    <Route path="/docker/cmd" element={<CmdDockerContent/>} exact/>
    <Route path="/docker/ubuntu" element={<UbuntuDockerContent/>} exact/>
    <Route path="/docker/login" element={<LoginDockerContent/>} exact/>
    <Route path="/docker/systemctl" element={<SystemCtrlDockerContent/>} exact/>
    <Route path="/docker/compose" element={<ComposeDockerContent/>} exact/>
    <Route path="/docker/version" element={<VersionDockerContent/>} exact/>
    <Route path="/docker/hello-world" element={<HelloWorldDockerContent/>} exact/>
    <Route path="/docker/run" element={<RunDockerContent/>} exact/>
    <Route path="/docker/ps" element={<PsDockerContent/>} exact/>
    <Route path="/docker/create" element={<CreateDockerContent/>} exact/>
    <Route path="/docker/start" element={<StartDockerContent/>} exact/>
    <Route path="/docker/system-prune" element={<SystemPruneDockerContent/>} exact/>
    <Route path="/docker/logs" element={<LogsDockerContent/>} exact/>
    <Route path="/docker/stop" element={<StopDockerContent/>} exact/>
    <Route path="/docker/kill" element={<KillDockerContent/>} exact/>
    <Route path="/docker/info" element={<InfoDockerContent/>} exact/>
    <Route path="/docker/exec" element={<ExecDockerContent/>} exact/>
    <Route path="/docker/it-flags" element={<ItFlagsDockerContent/>} exact/>
    <Route path="/docker/dockerfile" element={<DockerfileDockerContent/>} exact/>
    <Route path="/docker/commit" element={<CommitDockerContent/>} exact/>
    <Route path="/docker/tag-image" element={<TagImageDockerContent/>} exact/>
    <Route path="/docker/ex1" element={<Ex1DockerContent/>} exact/>
    <Route path="/docker/dockerfile-copy" element={<DockerfileCopyDockerContent/>} exact/>
    <Route path="/docker/port-mapping-flag" element={<PortMappingFlagDockerContent/>} exact/>
    <Route path="/docker/dockerfile-workdir" element={<DockerfileWorkdirDockerContent/>} exact/>
    <Route path="/docker/ex2" element={<Ex2DockerContent/>} exact/>
    <Route path="/docker/compose-yml" element={<DockerComposeContent/>} exact/>
    <Route path="/docker/d-flag" element={<DFlagsDockerContent/>} exact/>
    <Route path="/docker/compose-up" element={<DockerComposeUpContent/>} exact/>
    <Route path="/docker/compose-down" element={<DockerComposeDownContent/>} exact/>
    <Route path="/docker/restart-policies" element={<RestartPoliciesDockerContent/>} exact/>
    <Route path="/docker/compose-ps" element={<DockerComposePsContent/>} exact/>
    <Route path="/docker/ex3" element={<Ex3DockerContent/>} exact/>
    <Route path="/docker/dockerignore" element={<DockerIgnoreContent/>} exact/>
    <Route path="/docker/build-t" element={<DockerBuildTContent/>} exact/>
    <Route path="/docker/v-flag" element={<VolumeFlagDockerContent/>} exact/>
    <Route path="/docker/w-flag" element={<WFlagDockerContent/>} exact/>
    <Route path="/docker/e-flag" element={<EFlagDockerContent/>} exact/>
    <Route path="/docker/compose-volumes" element={<DockerComposeVolumesContent/>} exact/>
    <Route path="/docker/multi-steps-builds" element={<MultiStepDockerBuildsContent/>} exact/>
    <Route path="/docker/history" element={<HistoryDockerContent/>} exact/>
    <Route path="/docker/compose-environment" element={<DockerComposeEnvironmentContent/>} exact/>
    <Route path="/docker/compose-depends_on" element={<DockerComposeEnvironmentContent/>} exact/>
    <Route path="/docker/networking" element={<NetworkingDockerContent/>} exact/>
    <Route path="/docker/github" element={<GithubDockerContent/>} exact/>
    <Route path="/docker/travis" element={<TravisDockerContent/>} exact/>
    <Route path="/docker/aws" element={<AWSDockerContent/>} exact/>
    <Route path="/docker/ex4" element={<Ex4DockerContent/>} exact/>
    <Route path="/docker/github-actions" element={<GitHubActionsDockerContent/>} exact/>
    <Route path="/docker/ex5" element={<Ex5DockerContent/>} exact/>
    <Route path="/docker/ex5-prod" element={<Ex5ProdDockerContent/>} exact/>
    <Route path="/docker/ex5-aws" element={<Ex5AWSDockerContent/>} exact/>
    <Route path="/docker/ex5-clean-aws" element={<Ex5CleanAWSDockerContent/>} exact/>
    <Route path="/docker/inspect" element={<InspectDockerContent/>} exact/>
    <Route path="/docker/rm" element={<RmDockerContent/>} exact/>
    <Route path="/docker/help" element={<FlagHelpDockerContent/>} exact/>
    <Route path="/docker/container-prune" element={<ContainerPruneDockerContent/>} exact/>
    <Route path="/docker/images" element={<ImagesDockerContent/>} exact/>
    <Route path="/docker/rmi" element={<RmiDockerContent/>} exact/>
    <Route path="/docker/pull" element={<PullDockerContent/>} exact/>
    <Route path="/docker/image-prune" element={<ImagePruneDockerContent/>} exact/>
    <Route path="/docker/dockerfile-resume" element={<DockerfileDockerResumeContent/>} exact/>
    <Route path="/docker/volume-resume" element={<DockerVolumeResumeContent/>} exact/>
    <Route path="/docker/networking-resume" element={<DockerNetworkResumeContent/>} exact/>
    <Route path="/docker/compose-resume" element={<DockerComposeResumeContent/>} exact/>
    <Route path="/docker/search" element={<SearchDockerContent/>} exact/>
    <Route path="/docker/push" element={<PushDockerContent/>} exact/>
    <Route path="/docker/tag" element={<TagDockerContent/>} exact/>
    <Route path="/docker/images-inspect" element={<ImageInspectDockerContent/>} exact/>
    <Route path="/docker/restart" element={<RestartDockerContent/>} exact/>
    <Route path="/docker/rename" element={<RenameDockerContent/>} exact/>
    <Route path="/docker/attach" element={<AttachDockerContent/>} exact/>
    <Route path="/docker/swarm-resume" element={<DockerSwarmResumeContent/>} exact/>
    <Route path="/docker/images-build" element={<ImageBuildDockerContent/>} exact/>
    <Route path="/docker/host-image-registry" element={<HostImageRegistryDockerContent/>} exact/>
    <Route path="/docker/image-save" element={<SaveImageDockerContent/>} exact/>
    <Route path="/docker/image-load" element={<LoadImageDockerContent/>} exact/>
    <Route path="/docker/pause" element={<PauseDockerContent/>} exact/>
    <Route path="/docker/unpause" element={<UnpauseDockerContent/>} exact/>
    <Route path="/docker/container-export" element={<ExportContainerDockerContent/>} exact/>
    <Route path="/docker/container-import" element={<ImportContainerDockerContent/>} exact/>
    <Route path="/docker/stats" element={<StatsContainerDockerContent/>} exact/>
    <Route path="/docker/top" element={<TopContainerDockerContent/>} exact/>
    <Route path="/docker/diff" element={<DiffContainerDockerContent/>} exact/>
    <Route path="/docker/wait" element={<WaitContainerDockerContent/>} exact/>
    <Route path="/docker/system-df" element={<SystemDfDockerContent/>} exact/>
    <Route path="/docker/cp" element={<CpContainerDockerContent/>} exact/>
    <Route path="/docker/network-none" element={<NoneNetworkDockerContent/>} exact/>
    <Route path="/docker/network-disconnect" element={<DisconnectNetworkDockerContent/>} exact/>
    <Route path="/docker/network-connect" element={<ConnectNetworkDockerContent/>} exact/>
    <Route path="/docker/network-ls" element={<LsNetworkDockerContent/>} exact/>
    <Route path="/docker/network-create" element={<CreateNetworkDockerContent/>} exact/>
    <Route path="/docker/network-inspect" element={<InspectNetworkDockerContent/>} exact/>
    <Route path="/docker/network-macvlan" element={<MacvlanNetworkDockerContent/>} exact/>
    <Route path="/docker/network-dns" element={<DnsNetworkDockerContent/>} exact/>
    <Route path="/docker/ucp" element={<UCPDockerContent/>} exact/>
    <Route path="/docker/k8s" element={<K8sDockerContent/>} exact/>
    <Route path="/docker/dtr" element={<DtrMsrDockerContent/>} exact/>
    <Route path="/docker/mock" element={<MockDockerContent/>} exact/>
    <Route path="/docker/mock2" element={<Mock2DockerContent/>} exact/>
    <Route path="/docker/dockerfile-healthcheck" element={<DockerfileHealthcheckDockerContent/>} exact/>
    <Route path="/docker/dockerfile-entrypoint" element={<DockerfileEntrypointDockerContent/>} exact/>
    <Route path="/docker/image-import" element={<ImportImageDockerContent/>} exact/>
    <Route path="/docker/registry" element={<RegistryDockerContent/>} exact/>
    <Route path="/docker/network-bridge" element={<BridgeNetworkDockerContent/>} exact/>
    <Route path="/docker/network-user-defined-bridge" element={<UserDefinedBridgeNetworkDockerContent/>} exact/>
    <Route path="/docker/network-host" element={<HostNetworkDockerContent/>} exact/>
    <Route path="/docker/network-overlay" element={<OverlayNetworkDockerContent/>} exact/>
    <Route path="/docker/system-events" element={<SystemEventsDockerContent/>} exact/>
    <Route path="/docker/security" element={<SecurityDockerContent/>} exact/>
    <Route path="/docker/volume" element={<VolumeDockerContent/>} exact/>
    <Route path="/docker/tips1" element={<Tips1DockerContent/>} exact/>
    <Route path="/docker/tips2" element={<Tips2DockerContent/>} exact/>
    <Route path="/docker/tips3" element={<Tips3DockerContent/>} exact/>
    <Route path="/docker/tips4" element={<Tips4DockerContent/>} exact/>
    <Route path="/docker/tips5" element={<Tips5DockerContent/>} exact/>
    <Route path="/docker/tips6" element={<Tips6DockerContent/>} exact/>
</Route>
*/

export default function Router() {
    let element = useRoutes([
            {path: "/docker/index", element: <DockerHomeContent/>},

            {path: "/docker/intro", element: <PrivateRoute element={<IntroDockerContent/>}/>},
            {path: "/docker/cleanup-cmd", element: <PrivateRoute element={<CleanupCmdDockerContent/>}/>},
            {path: "/docker/connect", element: <PrivateRoute element={<ConnectDockerContent/>}/>},
            {path: "/docker/cmd", element: <PrivateRoute element={<CmdDockerContent/>}/>},
            {path: "/docker/ubuntu", element: <PrivateRoute element={<UbuntuDockerContent/>}/>},
            {path: "/docker/login", element: <PrivateRoute element={<LoginDockerContent/>}/>},
            {path: "/docker/systemctl", element: <PrivateRoute element={<SystemCtrlDockerContent/>}/>},
            {path: "/docker/compose", element: <PrivateRoute element={<ComposeDockerContent/>}/>},
            {path: "/docker/version", element: <PrivateRoute element={<VersionDockerContent/>}/>},
            {path: "/docker/hello-world", element: <PrivateRoute element={<HelloWorldDockerContent/>}/>},
            {path: "/docker/run", element: <PrivateRoute element={<RunDockerContent/>}/>},
            {path: "/docker/ps", element: <PrivateRoute element={<PsDockerContent/>}/>},
            {path: "/docker/create", element: <PrivateRoute element={<CreateDockerContent/>}/>},
            {path: "/docker/start", element: <PrivateRoute element={<StartDockerContent/>}/>},
            {path: "/docker/system-prune", element: <PrivateRoute element={<SystemPruneDockerContent/>}/>},
            {path: "/docker/logs", element: <PrivateRoute element={<LogsDockerContent/>}/>},
            {path: "/docker/stop", element: <PrivateRoute element={<StopDockerContent/>}/>},
            {path: "/docker/kill", element: <PrivateRoute element={<KillDockerContent/>}/>},
            {path: "/docker/info", element: <PrivateRoute element={<InfoDockerContent/>}/>},
            {path: "/docker/exec", element: <PrivateRoute element={<ExecDockerContent/>}/>},
            {path: "/docker/it-flags", element: <PrivateRoute element={<ItFlagsDockerContent/>}/>},
            {path: "/docker/dockerfile", element: <PrivateRoute element={<DockerfileDockerContent/>}/>},
            {path: "/docker/commit", element: <PrivateRoute element={<CommitDockerContent/>}/>},
            {path: "/docker/tag-image", element: <PrivateRoute element={<TagImageDockerContent/>}/>},
            {path: "/docker/ex1", element: <PrivateRoute element={<Ex1DockerContent/>}/>},
            {path: "/docker/dockerfile-copy", element: <PrivateRoute element={<DockerfileCopyDockerContent/>}/>},
            {path: "/docker/port-mapping-flag", element: <PrivateRoute element={<PortMappingFlagDockerContent/>}/>},
            {path: "/docker/dockerfile-workdir", element: <PrivateRoute element={<DockerfileWorkdirDockerContent/>}/>},
            {path: "/docker/ex2", element: <PrivateRoute element={<Ex2DockerContent/>}/>},
            {path: "/docker/compose-yml", element: <PrivateRoute element={<DockerComposeContent/>}/>},
            {path: "/docker/d-flag", element: <PrivateRoute element={<DFlagsDockerContent/>}/>},
            {path: "/docker/compose-up", element: <PrivateRoute element={<DockerComposeUpContent/>}/>},
            {path: "/docker/compose-down", element: <PrivateRoute element={<DockerComposeDownContent/>}/>},
            {path: "/docker/restart-policies", element: <PrivateRoute element={<RestartPoliciesDockerContent/>}/>},
            {path: "/docker/compose-ps", element: <PrivateRoute element={<DockerComposePsContent/>}/>},
            {path: "/docker/ex3", element: <PrivateRoute element={<Ex3DockerContent/>}/>},
            {path: "/docker/dockerignore", element: <PrivateRoute element={<DockerIgnoreContent/>}/>},
            {path: "/docker/build-t", element: <PrivateRoute element={<DockerBuildTContent/>}/>},
            {path: "/docker/v-flag", element: <PrivateRoute element={<VolumeFlagDockerContent/>}/>},
            {path: "/docker/w-flag", element: <PrivateRoute element={<WFlagDockerContent/>}/>},
            {path: "/docker/e-flag", element: <PrivateRoute element={<EFlagDockerContent/>}/>},
            {path: "/docker/compose-volumes", element: <PrivateRoute element={<DockerComposeVolumesContent/>}/>},
            {path: "/docker/multi-steps-builds", element: <PrivateRoute element={<MultiStepDockerBuildsContent/>}/>},
            {path: "/docker/history", element: <PrivateRoute element={<HistoryDockerContent/>}/>},
            {path: "/docker/compose-environment", element: <PrivateRoute element={<DockerComposeEnvironmentContent/>}/>},
            {path: "/docker/compose-depends_on", element: <PrivateRoute element={<DockerComposeEnvironmentContent/>}/>},
            {path: "/docker/networking", element: <PrivateRoute element={<NetworkingDockerContent/>}/>},
            {path: "/docker/github", element: <PrivateRoute element={<GithubDockerContent/>}/>},
            {path: "/docker/travis", element: <PrivateRoute element={<TravisDockerContent/>}/>},
            {path: "/docker/aws", element: <PrivateRoute element={<AWSDockerContent/>}/>},
            {path: "/docker/ex4", element: <PrivateRoute element={<Ex4DockerContent/>}/>},
            {path: "/docker/github-actions", element: <PrivateRoute element={<GitHubActionsDockerContent/>}/>},
            {path: "/docker/ex5", element: <PrivateRoute element={<Ex5DockerContent/>}/>},
            {path: "/docker/ex5-prod", element: <PrivateRoute element={<Ex5ProdDockerContent/>}/>},
            {path: "/docker/ex5-aws", element: <PrivateRoute element={<Ex5AWSDockerContent/>}/>},
            {path: "/docker/ex5-clean-aws", element: <PrivateRoute element={<Ex5CleanAWSDockerContent/>}/>},
            {path: "/docker/inspect", element: <PrivateRoute element={<InspectDockerContent/>}/>},
            {path: "/docker/rm", element: <PrivateRoute element={<RmDockerContent/>}/>},
            {path: "/docker/help", element: <PrivateRoute element={<FlagHelpDockerContent/>}/>},
            {path: "/docker/container-prune", element: <PrivateRoute element={<ContainerPruneDockerContent/>}/>},
            {path: "/docker/images", element: <PrivateRoute element={<ImagesDockerContent/>}/>},
            {path: "/docker/rmi", element: <PrivateRoute element={<RmiDockerContent/>}/>},
            {path: "/docker/pull", element: <PrivateRoute element={<PullDockerContent/>}/>},
            {path: "/docker/image-prune", element: <PrivateRoute element={<ImagePruneDockerContent/>}/>},
            {path: "/docker/dockerfile-resume", element: <PrivateRoute element={<DockerfileDockerResumeContent/>}/>},
            {path: "/docker/volume-resume", element: <PrivateRoute element={<DockerVolumeResumeContent/>}/>},
            {path: "/docker/networking-resume", element: <PrivateRoute element={<DockerNetworkResumeContent/>}/>},
            {path: "/docker/compose-resume", element: <PrivateRoute element={<DockerComposeResumeContent/>}/>},
            {path: "/docker/search", element: <PrivateRoute element={<SearchDockerContent/>}/>},
            {path: "/docker/push", element: <PrivateRoute element={<PushDockerContent/>}/>},
            {path: "/docker/tag", element: <PrivateRoute element={<TagDockerContent/>}/>},
            {path: "/docker/images-inspect", element: <PrivateRoute element={<ImageInspectDockerContent/>}/>},
            {path: "/docker/restart", element: <PrivateRoute element={<RestartDockerContent/>}/>},
            {path: "/docker/rename", element: <PrivateRoute element={<RenameDockerContent/>}/>},
            {path: "/docker/attach", element: <PrivateRoute element={<AttachDockerContent/>}/>},
            {path: "/docker/swarm-resume", element: <PrivateRoute element={<DockerSwarmResumeContent/>}/>},
            {path: "/docker/images-build", element: <PrivateRoute element={<ImageBuildDockerContent/>}/>},
            {path: "/docker/host-image-registry", element: <PrivateRoute element={<HostImageRegistryDockerContent/>}/>},
            {path: "/docker/image-save", element: <PrivateRoute element={<SaveImageDockerContent/>}/>},
            {path: "/docker/image-load", element: <PrivateRoute element={<LoadImageDockerContent/>}/>},
            {path: "/docker/pause", element: <PrivateRoute element={<PauseDockerContent/>}/>},
            {path: "/docker/unpause", element: <PrivateRoute element={<UnpauseDockerContent/>}/>},
            {path: "/docker/container-export", element: <PrivateRoute element={<ExportContainerDockerContent/>}/>},
            {path: "/docker/container-import", element: <PrivateRoute element={<ImportContainerDockerContent/>}/>},
            {path: "/docker/stats", element: <PrivateRoute element={<StatsContainerDockerContent/>}/>},
            {path: "/docker/top", element: <PrivateRoute element={<TopContainerDockerContent/>}/>},
            {path: "/docker/diff", element: <PrivateRoute element={<DiffContainerDockerContent/>}/>},
            {path: "/docker/wait", element: <PrivateRoute element={<WaitContainerDockerContent/>}/>},
            {path: "/docker/system-df", element: <PrivateRoute element={<SystemDfDockerContent/>}/>},
            {path: "/docker/cp", element: <PrivateRoute element={<CpContainerDockerContent/>}/>},
            {path: "/docker/network-none", element: <PrivateRoute element={<NoneNetworkDockerContent/>}/>},
            {path: "/docker/network-disconnect", element: <PrivateRoute element={<DisconnectNetworkDockerContent/>}/>},
            {path: "/docker/network-connect", element: <PrivateRoute element={<ConnectNetworkDockerContent/>}/>},
            {path: "/docker/network-ls", element: <PrivateRoute element={<LsNetworkDockerContent/>}/>},
            {path: "/docker/network-create", element: <PrivateRoute element={<CreateNetworkDockerContent/>}/>},
            {path: "/docker/network-inspect", element: <PrivateRoute element={<InspectNetworkDockerContent/>}/>},
            {path: "/docker/network-macvlan", element: <PrivateRoute element={<MacvlanNetworkDockerContent/>}/>},
            {path: "/docker/network-dns", element: <PrivateRoute element={<DnsNetworkDockerContent/>}/>},
            {path: "/docker/ucp", element: <PrivateRoute element={<UCPDockerContent/>}/>},
            {path: "/docker/k8s", element: <PrivateRoute element={<K8sDockerContent/>}/>},
            {path: "/docker/dtr", element: <PrivateRoute element={<DtrMsrDockerContent/>}/>},
            {path: "/docker/mock", element: <PrivateRoute element={<MockDockerContent/>}/>},
            {path: "/docker/mock2", element: <PrivateRoute element={<Mock2DockerContent/>}/>},
            {path: "/docker/dockerfile-healthcheck", element: <PrivateRoute element={<DockerfileHealthcheckDockerContent/>}/>},
            {path: "/docker/dockerfile-entrypoint", element: <PrivateRoute element={<DockerfileEntrypointDockerContent/>}/>},
            {path: "/docker/image-import", element: <PrivateRoute element={<ImportImageDockerContent/>}/>},
            {path: "/docker/registry", element: <PrivateRoute element={<RegistryDockerContent/>}/>},
            {path: "/docker/network-bridge", element: <PrivateRoute element={<BridgeNetworkDockerContent/>}/>},
            {path: "/docker/network-user-defined-bridge", element: <PrivateRoute element={<UserDefinedBridgeNetworkDockerContent/>}/>},
            {path: "/docker/network-host", element: <PrivateRoute element={<HostNetworkDockerContent/>}/>},
            {path: "/docker/network-overlay", element: <PrivateRoute element={<OverlayNetworkDockerContent/>}/>},
            {path: "/docker/system-events", element: <PrivateRoute element={<SystemEventsDockerContent/>}/>},
            {path: "/docker/security", element: <PrivateRoute element={<SecurityDockerContent/>}/>},
            {path: "/docker/volume", element: <PrivateRoute element={<VolumeDockerContent/>}/>},
            {path: "/docker/tips1", element: <PrivateRoute element={<Tips1DockerContent/>}/>},
            {path: "/docker/tips2", element: <PrivateRoute element={<Tips2DockerContent/>}/>},
            {path: "/docker/tips3", element: <PrivateRoute element={<Tips3DockerContent/>}/>},
            {path: "/docker/tips4", element: <PrivateRoute element={<Tips4DockerContent/>}/>},
            {path: "/docker/tips5", element: <PrivateRoute element={<Tips5DockerContent/>}/>},
            {path: "/docker/tips6", element: <PrivateRoute element={<Tips6DockerContent/>}/>},

        ])
    ;

    return element;
}