import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class APIGroupsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-api-groups", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>API Groups</b>

                    <br/>
                    <br/>

                    Penru a afisa API-urile disponibile:
                    <SyntaxHighlighter>
                        {
                            'curl http://localhost:6443 -k'
                        }
                    </SyntaxHighlighter>

                    Penru a afisa API group-urile disponibile:
                    <SyntaxHighlighter>
                        {
                            'curl http://localhost:6443 -k | grep "name" '
                        }
                    </SyntaxHighlighter>

                    dupa:
                    <SyntaxHighlighter>
                        {
                            'kubectl proxy'
                        }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {
                            'curl http://localhost:8001 -k'
                        }
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default APIGroupsK8sContent;