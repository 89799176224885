import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class LedArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-led", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LED (Light Emitted Diode / diodă emițătoare de lumină)</b>
                    <br/>
                    <br/>

                    LED-urile pot emite lumina de culoare:
                    <ul>
                        <li>rosie</li>
                        <li>verde</li>
                        <li>galbena</li>
                        <li>albastra</li>
                    </ul>
                    Un LED are 2 picioruse:
                    <ul>
                        <li>unul mai scurt (catod) - care se conecteaza la pinul GND al placii Arduino</li>
                        <li>unul mai lung (anodul) - care se conecteaza la unul dintre pinii digitali al placii Ardunino</li>
                    </ul>

                    Valorile tipice:
                    <ul>
                        <li>LED roșu: 2V, 15mA</li>
                        <li>LED verde: 2.1V, 20mA</li>
                        <li>LED albastru: 3.2V, 25mA</li>
                        <li>LED alb: 3.2V, 25mA</li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>
                            Un pin digital are 5V
                        </li>
                        <li>
                            Pin digital 5V, tensiune maxima pentru LED 2V => este nevoie de un rezistor, care sa scada 3V.
                            <br/>
                            Valoarea rezistentei se poate determina folosind <b>legea lui Ohm</b>:
                            <br/>
                            Legea lui Ohm spune că într-un circuit intensitatea curentului electric (I) este direct proporțională cu tensiunea aplicată (U) și invers proporțională
                            cu rezistența (R) din circuit.
                            <br/>
                            I = U / R =>
                            <br/>
                            R = U / I => 3V / 15mA => 3V / 0.015A => 200 (ohmi)
                        </li>
                        <li>
                            Dacă nu găsim rezistențe de 200 ohm, putem folosi rezistențe mai mari: am folosit o rezistenta 220R = 220 ohm
                        </li>
                    </ul>


                    Schema:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/led.png'}/>
                    </div>

                    Cod:
                    <SyntaxHighlighter>
                        {'void setup() {\n' +
                            '  pinMode(13, OUTPUT);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  digitalWrite(13, HIGH);\n' +
                            '  delay(1000);\n' +
                            '  digitalWrite(13, LOW);\n' +
                            '  delay(1000);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Observatii:
                    <ul>
                        <li>
                            Într-un circuit de <b>curent continuu</b>, <b>poziția rezistenței în serie</b> cu un LED <b>nu afectează funcționarea acestuia, indiferent dacă este plasată înainte sau după LED</b>.
                            <br/>
                            Acest lucru se datorează faptului că într-un circuit în serie, curentul care trece prin fiecare componentă este același, indiferent de ordinea componentelor.
                        </li>
                        <li>
                            Curentul este același în toate componentele unui circuit în serie datorită legii conservării sarcinii electrice.
                            Într-un circuit în serie, există un singur drum prin care electronii pot călători.
                            <br/>
                            Acest fapt are câteva implicații importante:
                            <ul>
                                <li>
                                    <b>Continuitatea Curentului</b>: Curentul care intră într-un component al circuitului este exact același cu cel care iese din acel component.
                                    Aceasta înseamnă că același curent trebuie să treacă prin fiecare componentă a circuitului în serie, indiferent de ordinea acestora.
                                </li>
                                <li>
                                    <b>Legea lui Kirchhoff pentru Curent</b>: Această lege, cunoscută și ca prima lege a lui Kirchhoff, spune că suma totală a curentului care intră într-un nod (sau punct de joncțiune) este egală cu suma totală a curentului care iese din acel nod.
                                    Într-un circuit în serie, nu există noduri unde curentul se împarte, deci curentul rămâne constant de-a lungul întregului circuit.
                                </li>
                                <li>
                                    <b>Conservarea Sarcinii</b>: Într-un circuit închis, sarcina electrică se conservă.
                                    Electronii nu sunt creați sau distruși în timp ce trec prin circuit; ei pur și simplu se mișcă de-a lungul circuitului.
                                    Prin urmare, numărul de electroni (sau cantitatea de curent) care trece printr-un punct dat al circuitului într-o unitate de timp este același pentru toate punctele circuitului.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Curentul este determinat de sursa de tensiune și de rezistența totală a circuitului.
                        </li>
                    </ul>

                    Dar de unde <i>stie</i> curentul ca exista o rezistenta pe circuit?
                    <br/>
                    <br/>
                    Conceptul că "curentul știe" despre rezistența într-un circuit poate fi înțeles mai bine prin aplicarea legilor fizicii, în special a Legii lui Ohm, în contextul circuitelor electrice.
                    Iată cum funcționează acest lucru:
                    <ul>
                        <li>
                            1. <b>Legea lui Ohm</b>: Această lege fundamentală în electrotehnică afirmă că curentul care trece printr-un conductor între două puncte este direct proporțional cu tensiunea între aceste două puncte și invers proporțional cu rezistența între ele.
                            <br/>
                            Matematic, aceasta se exprimă ca:
                            <br/>
                            I =  R / V
                            <br/>
                            ,unde I este curentul,  V este tensiunea, iar R este rezistența.
                        </li>
                        <li>
                            2. Interacțiunea dintre Tensiune, Curent și Rezistență: Când aplici o tensiune la un circuit, electronii încep să se deplaseze, creând un curent. Mărimea acestui curent depinde de rezistența totală a circuitului.
                            Dacă există o rezistență, aceasta se opune trecerii curentului, limitând astfel cantitatea de curent care poate curge prin circuit.
                        </li>
                        <li>
                            3. "Știința" Curentului: Nu este că curentul "știe" în mod activ despre rezistență;
                            mai degrabă, comportamentul curentului este o consecință directă a proprietăților fizice ale circuitului.
                            <br/>
                            Curentul este rezultatul mișcării electronilor, iar această mișcare este influențată de prezența și mărimea rezistențelor în circuit.
                            Prin urmare, curentul se ajustează conform Legii lui Ohm.
                        </li>
                        <li>
                            4. <b>Distribuția Tensiunii</b>: Într-un circuit, tensiunea se distribuie în funcție de rezistențele prezente.
                            Într-un circuit în serie, de exemplu, tensiunea totală se împarte între diferitele rezistențe din circuit. Fiecare componentă "resimte" o parte din tensiunea totală în funcție de valoarea sa de rezistență.
                        </li>
                    </ul>
                    Prin urmare, nu este că curentul are o cunoaștere sau o conștiință a circuitului, ci mai degrabă comportamentul său este determinat de legile fizice care guvernează mișcarea sarcinilor electrice într-un mediu cu rezistență.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.youtube.com/watch?v=FKekzzj5844&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=2&ab_channel=ScienceBuddies"}>
                               How to Blink an LED with Arduino (Lesson #2)
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LedArduinoContent;