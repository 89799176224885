import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ConfigNuxeoContent extends BaseContentPage{

    constructor(props) {
        super(props, "nuxeo-config", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                Dupa pornirea serverului Nuxeo, se urmeaza urmatori pasi:
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>1. Pas "Welcome"</b></div>
                    Se apasa "Start Configuration:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-01.png'}/>
                    </div>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>2. Pas "General"</b></div>
                    Se configureaza:
                    <ol>
                        <li>Adresa IP</li>
                        <li>Calea catre directorul de log-uri</li>
                        <li>Calea catre directorul unde isi pastreaza Nuxeo diverse date</li>
                        <li>Daca e mod dezvoltare (permite hot reload folosind Nuxeo Studio) sau productie</li>
                    </ol>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-02.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <div><b>3. Pas "Proxy"</b></div>
                    Se pastreaza:
                    <ol>
                        <li>HTTP Proxy Type = None</li>

                    </ol>
                    E nevoie de setarea proxy-ului daca se foloseste Nuxeo Marketplace sau Update Center (este nevoie de acces Internet)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-03.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <div><b> 4. Pas "Database"</b></div>
                    Se configureaza conectarea la baza de date:
                    <ol>
                        <li>Database Type:MySQL</li>
                        <li>Database Name:nuxeo</li>
                        <li>Database User:nuxeo</li>
                        <li>Database Password:nuxeo</li>
                        <li>Database Server Host Name:127.0.0.1</li>
                        <li>Database Server Port: 3306</li>
                    </ol>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-04.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>

                <br/>
                <div className={"text-justify important"}>
                    <div><b> 5. Pas "Users & Groups"</b></div>
                    Se seteaza:
                    <ol>
                        <li>Directory Type: Default (Database)</li>

                    </ol>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-06.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <div><b>6. Pas "Email":</b></div>
                    Se configureaza server-ul SMTP de email:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-07.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>7. Pas "Nuxeo Online Services"</b></div>
                    Se configureaza "Nuxeo Online Services":

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-08.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b> 8. Pas "Addons"</b></div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-09.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b> 9. Pas "Download"</b></div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-10.png'}/>
                    </div>
                    Se apasa "Next Step"
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b> 10. Pas "Summary"</b></div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-11.png'}/>
                    </div>
                    Start Nuxeo

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-12.png'}/>
                    </div>

                    Dupa restart:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-13.png'}/>
                    </div>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>10. Instalare Nuxeo Web UI (nuxeo-web-ui-2.4.0)</b></div>
                    <ol>
                        <li>nuxeoctl stop</li>
                        <li>nuxeoctl mp-install nuxeo-web-uip</li>
                        <li>nuxeoctl restart -gui=false</li>
                    </ol>

                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>11. Logare</b></div>
                    <ol>
                        <li>username:Administrator</li>
                        <li>password:Administrator</li>
                    </ol>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nuxeo/nuxeo-config-14.png'}/>
                    </div>
                </div>
                <br/>
                {this.navigator()}
                <br/>


            </div>
        );
    }
}

export default ConfigNuxeoContent;