import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StringsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-strings", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Siruri de caractere: String</b>
                    <br/>
                    <br/>
                    In Rust, exista 6 tipuri se string-uri:
                    <ul>
                        <li>&str (nu se poate modifica)
                            <SyntaxHighlighter>
                                {'let m = "Buna!";'}
                            </SyntaxHighlighter>
                            este un pointer catre un sir de caractere cu lungimea de 5
                        </li>
                        <li>
                            String (se poate modifica)
                            <SyntaxHighlighter>
                                {'let m = String::from("Buna!");'}
                            </SyntaxHighlighter>
                            este un pointer catre un sir de caractere cu lungimea de 5, dar cu o anumita <i>capacitate</i>
                        </li>
                    </ul>

                    Pentru a converti un &str in String:
                    <SyntaxHighlighter>
                        {'let m1 = "Buna!";\n' +
                            'let m2 = m1.to_string()'}
                    </SyntaxHighlighter>

                    Metode:
                    <ul>
                        <li>.bytes()</li>
                        <li>.chars()</li>
                        <li>graphemes(my_string, true)</li>
                        <li>.nth(3)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringsRustContent;