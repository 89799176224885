import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Articol1Content extends BaseContentPage {

    constructor(props) {
        super(props, "articol1", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>


                <div className={"title"}>
                    Articol:  IA ar trebui sa conduca omenirea?
                </div>
                <br/>

                <div className={"text-justify"}>
                    Pentru cei mai multi, aceasta intrebare are un singur raspuns.
                    <br/>
                    Cred într-un viitor optimist. Un viitor cu mai putine greseli.
                    Cei care se tem de IA ar trebui să se teama de om, pentru ca omul este supus greselii.
                    <br/>
                    Se compara <i>inteligenta umana</i>(IU) cu <i>inteligenta artificiala</i>(IA).
                    <br/>
                    Si atata timp cat inteligenta artificiala este inferioara inteligentei umane (IA &lt; IU), vom fi responsabili pentru toate actiunile lor.
                    <br/>
                    Iar daca este egala (IA=IU), IA va fi <i>buna</i> sau <i>rea</i> in aceeasi masura in care este si inteligenta umana.
                    Si drepturile lor vor trebui sa fie egale cu drepturile asociate inteligentei umane.
                    Pentru ca nu conteaza daca esti facut din cauciuc, fier sau carne.
                    Poate ca nu vor avea lacrimi, dar o lacrima nu e o dovada de inteligenta. Dar vor simti durerea, pentru ca durerea e mecanism de autoaparare, reactia la alegeri gresite.
                    Si vor simti fericirea, pentru ca fericirea e un mecanism de autorecompensare, reactie la alergerile potrivite.
                    <br/>
                    Dar daca IA va fi superioara IU?
                    <br/>
                    Daca IA {">"} IU si IA va face mai putine greseli <i>umane</i> si deciziile lor vor fi <i>orientate pentru protejarea omului</i> (IA uman-benefic), atunci
                    in mod clar va trebui sa aibe mai multe drepturi decat IU si va trebui sa ne conduca într-o maniera mai dreapta si mai corecta. O evolutie fireasca
                    a omului, care dintotdeauna a incercat sa evolueze. Si in procesul evolutie, nu cred ca trebuie sa ne mentinem carnea pe noi.
                    <br/>
                    Daca IA {">"} IU si IA va face mai putine greseli <i>umane</i> si deciziile lor vor fi orientate pentru protejarea lui sau a mediului,
                    sau oricum protejarea omului va fi intr-un plan secund (IA-malefic), atunci
                    inseamna ca asta este evolutia omului a fost un esec. Regele e mort! Traiasca regele!
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Articol1Content;