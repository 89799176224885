import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GoogleStorageNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-google-storage", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Instalare</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeoctl mp-install google-storage-1.0.0'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Verificare</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeoctl mp-list'}
                    </SyntaxHighlighter>

                    Datele/binarele sunt tinute in Google Cloud Storage.
                </div>


                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GoogleStorageNuxeoContent;