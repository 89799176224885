import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class OperatorsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-operators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Precedenta operatorilor:

                    <table>
                        <thead>
                            <tr>
                                <th>operator</th>
                                <th>simbol</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>operatori post-unari</td>
                                <td>expresie++, expresie--</td>
                            </tr>
                            <tr>
                                <td>operatori pre-unari</td>
                                <td>--expresie, ++expresie
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'int a = --7; // e ilegal, pentru ca asteapta o variabila'}
                                    </SyntaxHighlighter>
                                </td>
                            </tr>
                            <tr>
                                <td>operatori unari (schimbare semn, pastrare semn, negare, negare pe biti, cast)</td>
                                <td>-, +, !, ~, (type)
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'int a = -2147483648; // e ok \n' +
                                        'int b = -(2147483648) // e ilegal'}
                                    </SyntaxHighlighter>
                                </td>
                            </tr>
                            <tr>
                                <td>inmultire, impartire, modul</td>
                                <td>*, /, %</td>
                            </tr>
                            <tr>
                                <td>adunare, scadere</td>
                                <td>+, -</td>
                            </tr>
                            <tr>
                                <td>shift-are</td>
                                <td>&lt;&lt;, {">>"}, {">>>"} </td>
                            </tr>

                            <tr>
                                <td>operatori relationali</td>
                                <td> &lt;, {">"}, &lt;=, {">="}, instanceof
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'boolean x = null instanceof Object; // false '}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'boolean x = true;\n' +
                                        'if (x instanceof String){..} // nu va compila '}
                                    </SyntaxHighlighter>
                                </td>
                            </tr>

                            <tr>
                                <td>egal, diferit</td>
                                <td>==, !=</td>
                            </tr>

                            <tr>
                                <td>operatori logici</td>
                                <td>&, |, ^ </td>
                            </tr>

                            <tr>
                                <td>operatori logici (scurt circuit)</td>
                                <td>&&, || </td>
                            </tr>

                            <tr>
                                <td>operator ternal</td>
                                <td>expresie_booleana ? expresie_1 : expresie_2 </td>
                            </tr>

                            <tr>
                                <td>operatori de asignare</td>
                                <td>=, +=, -=, *=, /=, %=, &=, ^=, |=, &lt;&lt;=, {">>="}, {">>>="} </td>
                            </tr>

                        </tbody>
                    </table>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class OperatorPrecedence {\n' +
                        '\n' +
                        '    public static void main(String []args){\n' +
                        '\n' +
                        '        int a = 5;\n' +
                        '        int b = --a * a++;  // (se decrementeaza a, a = 4) 4 * 4 (a se incrementeaza, a = 5) = 16\n' +
                        '\n' +
                        '        // b = --a * a++\n' +
                        '        // --a => a = a-1; return a => a=4; return [4]\n' +
                        '        // a++ > return a; a = a + 1 => return [4]; a = 5\n' +
                        '        // b = 4 * 4 = 16\n' +
                        '        System.out.println(b+" "+ a);\n' +
                        '\n' +
                        '        a = 5;\n' +
                        '        b = ++a * a--; // (se incrementeaza a=6) 6 * 6 (a se decrementeaza, a = 5) = 36\n' +
                        '        System.out.println(b+" "+ a);\n' +
                        '\n' +
                        '        a = 5;\n' +
                        '        b = a++ * a--; // 5 (si apoi se incrementeaza => a=6) * 6 (a se decrementeaza => a=5) = 30\n' +
                        '        System.out.println(b+" "+ a);\n' +
                        '\n' +
                        '        a = 5;\n' +
                        '        b = a++ * --a; // 5 (si apoi se incrementeaza => a=6) * (a se decrementeaza => a=5 ) 5 = 25\n' +
                        '        System.out.println(b+" "+ a);\n' +
                        '\n' +
                        '        a = 3;\n' +
                        '        b = ++a * 5 / (a--);\n' +
                        '        // ++a * 5 / 3 [a=2] => 3 * 5 / 3 => 5\n' +
                        '        System.out.println(b+" "+ a); // 5 3\n' +
                        '\n' +
                        '        a = 3;\n' +
                        '        b = ++a * 5 + a--;\n' +
                        '        // 4 [a=4] * 5 + a-- => 20 + 4 = 24\n' +
                        '        System.out.println(b+" "+ a); // 24 3\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>

                    Intr-o expresie, <b>operatii se evalueaza de la stânga la dreapta. Regula de la stânga la dreapta are prioritate</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class OperatorPrecedence2 {\n' +
                        '\n' +
                        '    public static void main(String []args){\n' +
                        '\n' +
                        '        int a = 3;\n' +
                        '        int b = ++a * 5 + a--;\n' +
                        '        // 4 [a=4] * 5 + a-- => 20 + 4 = 24\n' +
                        '        System.out.println(b+" "+ a); // 24 3\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Faptul că postincrementare are o prioritate mai mare nu înseamnă că a-- este evaluat primul
                    (daca ar fi asa rezultatul lui b, ar avea valoarea 18, si nu 24, cum e corect)

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OperatorsJavaContent;