import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class UtilsVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-utils", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    <a href={"https://www.youtube.com/watch?v=uBJegtvnYUA&ab_channel=ExperiencePhotography"} target={"_blank"} rel="noreferrer">
                        How to shoot Smoke Photography and Video
                    </a>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <div>*/}
                    {/*          */}
                    {/*       </div>*/}
                    {/*   </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UtilsVideografieContent;