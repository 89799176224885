import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StructsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-structs", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Structs</b>
                    <br/>
                    <br/>

                    Un <b>struct</b> e similar cu o clasa in alte limbaje.

                    <SyntaxHighlighter>
                        {'struct Document {\n' +
                            '    id: i32,\n' +
                            '    isHTML: bool,\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Instantiere:
                    <SyntaxHighlighter>
                        {'let doc = Document{\n' +
                            '        id:1,\n' +
                            '        isHTML:true\n' +
                            '    };'}
                    </SyntaxHighlighter>

                    Implementare <i>struct</i>:
                    <SyntaxHighlighter>
                        {'impl Document{\n' +
                            '        fn new() -> Self{\n' +
                            '            Self{\n' +
                            '                id:5,\n' +
                            '                isHTML: false,\n' +
                            '            }\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter>
                        {'impl Document{\n' +
                            '        fn new() -> Document{\n' +
                            '            Document{\n' +
                            '                id:5,\n' +
                            '                isHTML: false,\n' +
                            '            }\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Instantiere:
                    <SyntaxHighlighter>
                        {'let doc = Document::new();'}
                    </SyntaxHighlighter>

                    Acesare campuri/metode:
                    <SyntaxHighlighter>
                        {'let id = doc.id;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'doc.some_method();'}
                    </SyntaxHighlighter>

                    Metoda intr-un struct (metodele iau ca parametru <b>self</b> sau <b>&self</b> sau <b>&mut self</b>):
                    <SyntaxHighlighter>
                        {'fn move(self){...}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'fn move2(&self){...}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'fn move3(&mut self){...}'}
                    </SyntaxHighlighter>

                    Nu existe mosternire pe struct!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StructsRustContent;