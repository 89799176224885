import Util from '../util/Util.js';

export default class Perceptron{
    #bias = 1;
    #weights=[];
    #learningRate = 0.01;

    constructor(n){
        // weights initialization
        for(let i=0;i<=n;i++){
            this.#weights.push(Util.random(-1,1));
        }
    }

    feedforward(input){
        let sum = 0;
        let n = input.length;
        for(let i=0;i<input.length;i++){
            sum += this.#weights[i]*input[i];
        }
        sum += this.#bias*this.#weights[n];

        return this.activate(sum);
    }

    activate(sum){
        if (sum>0){
            return 1;
        }
        return -1;
    }

    train(inputs, desired){
        let guess = this.feedforward(inputs);
        let error = desired - guess; // this can only be 0, -2, or 2
        for(let i=0;i<this.#weights.length;i++){
            let input = (i===inputs.length) ? this.#bias : inputs[i];
            this.#weights[i] += this.#learningRate * error * input;
        }
        return (error===0)?0:1;
    }

    get weights(){
        return this.#weights;
    }

    get learningRate(){
        return this.#learningRate;
    }

}