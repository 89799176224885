import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"grafana-root", url:"#", title:"Grafana", subtitle:""},
        {id:"grafana-change-port", url:"/grafana/change-port", title:"Schimbare port", subtitle:"", parent:"grafana-root"},
        {id:"grafana-data-sources", url:"/grafana/data-sources", title:"Surse de date (PostgreSQL)", subtitle:"", parent:"grafana-root"},
        {id:"grafana-dashboards", url:"/grafana/dashboards", title:"Dashboards", subtitle:"", parent:"grafana-root"},
        {id:"grafana-variables", url:"/grafana/variables", title:"Variabile", subtitle:"", parent:"grafana-root"},
        {id:"grafana-ssl", url:"/grafana/ssl", title:"SSL", subtitle:"", parent:"grafana-root"},

        {id:"grafana-build-from-source", url:"/grafana/build-from-source", title:"Build din surse", subtitle:"", parent:"grafana-root"},

    ]

    static indexUrl = "/grafana/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Grafana
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;