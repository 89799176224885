import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class LoadbancingApacheTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-loadbalancing-apache", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LoadBalancing cu Apache HTTP & MOD_JK</b>
                    <br/>
                    <br/>

                    Sa presupunem ca avem instalat 2 instante de Tomcat pe 2 masini diferite:
                    <ul>
                        <li>192.168.0.10</li>
                        <li>192.168.0.11</li>
                    </ul>
                    Ambele pornite pe portul 8080.
                    <br/>
                    <br/>
                    Pe masina <i>192.168.0.5</i> (aici instalam LoadBalancer-ul) instalam (in loc de GNinx): <b>Apache HTTP Server</b> de pe:
                    <br/>
                    <a target={"_blank"} href={"https://www.apachelounge.com/download/"}>https://www.apachelounge.com/download/</a> (Apache 2.4.54 Win64 - httpd-2.4.54-win64-VS16.zip)
                    <br/>
                    (e nevoie ca pe masina sa fie instalat Visual C++ Redistributable for Visual Studio 2015-2019: vc_redis_x64:
                    <br/>
                    <i>Be sure you installed latest 14.32.31332 Visual C++ Redistributable Visual Studio 2015-2022 : vc_redist_x64 or vc_redist_x86 see Redistributable</i>)

                    <br/>
                    <br/>
                    <ul>
                        <li>se descarca fisierul httpd-2.4.54-win64-VS16.zip</li>
                        <li>se dezarhiveaza (de exemplu, in D:\servers\Apache24)</li>
                        <li>se instaleaza ca serviciu din linie de comanda (din D:\servers\Apache24\bin):
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'httpd -k install'}
                            </SyntaxHighlighter>
                            apoi se pornesc serviciile <b>services.msc</b> si da <i>Start</i> pe serviciul <i>Apache2.4</i>.
                        </li>
                        <li>
                            implicit, porneste pe portul 80.
                            <br/>
                            verificare din browser: http://localhost (se va afisa un mesaj cu <i>It works!</i>)
                        </li>
                        <li>
                            se intaleaza un modul <b>MOD_JK</b> (vezi pe <i>https://archive.apache.org/dist/tomcat/tomcat-connectors/jk/binaries/windows/</i>)
                            <br/>
                            se descarca: <i>tomcat-connectors-1.2.40-windows-x86_64-httpd-2.4.x.zip</i>
                            <br/>
                            se dezarhiveaza si in arhiva se va gasi fisierul: <b>mod_jk.so</b>; acest fisier trebuie pus in <b>D:\servers\Apache24\modules</b>
                        </li>
                        <li>
                            trebuie specificat ca trebuie sa se incarce modulul <b>mod_jk.so</b>; pentru a face acest lucru editam fisierul <b>httpd.conf</b> din <b>[apache]\conf</b>;
                            <br/>
                            se adauga la sfarsitul fisierului:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'LoadModule jk_module modules/mod_jk.so'}
                            </SyntaxHighlighter>

                            apoi se specifica fisierul care configureaza LoadBalancer-ul (tot in fisierul <b>httpd.conf</b>):

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'JkWorkersFile conf/workers.properties'}
                            </SyntaxHighlighter>

                            in fisierul <i>workers.properties</i> vom specifica toate instantele de Tomcat (ip-urile si pe ce porturi sunt pornite).
                            <br/>
                            <br/>
                            apoi se specifica fisierul de log precum si nivelul:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'JkLogLevel info' +
                                    'JkLogFile logs/mod_jk.log'}
                            </SyntaxHighlighter>

                            apoi se specifica ca se doreste LoadBalancer pentru orice tip de trafic, fara a specifica vreo aplicatie
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'JkMount /* lb'}
                            </SyntaxHighlighter>

                            pe scurt:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'LoadModule jk_module modules/mod_jk.so\n' +
                                    'JkWorkersFile conf/workers.properties\n' +
                                    'JkLogLevel info\n' +
                                    'JkLogFile logs/mod_jk.log\n'+
                                    'JkMount /* lb'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            in <b>[apache]\conf</b> cream fisierul <b>workers.properties</b> cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'worker.list=lb\n' +
                                    '\n' +
                                'worker.tomcat1.type=ajp13\n' +
                                    'worker.tomcat1.host=192.168.0.10\n'+
                                    'worker.tomcat1.port=8009 #portul pt ajp' +
                                    '\n\n' +
                                'worker.tomcat2.type=ajp13\n' +
                                    'worker.tomcat2.host=192.168.0.11\n'+
                                    'worker.tomcat2.port=8009 #portul pt ajp\n' +
                                    '\n' +
                                'worker.lb.type=lb\n' +
                                    'worker.lb.balance_workers=tomcat1,tomcat2' }
                            </SyntaxHighlighter>

                            <b>Observatie</b>
                            <br/>
                            In declaratia <b>'JkMount /* lb'</b> din fisierul <b>httpd.conf</b>, <b>lb</b> trebuie sa corespunda cu:
                            <ul>
                                <li>worker.list=<b>lb</b></li>
                                <li>worker.<b>lb</b>.type=lb</li>
                                <li>worker.<b>lb</b>.balancer_workers=tomcat1,tomcat2</li>
                            </ul>

                        </li>

                    </ul>

                    Apoi mergem pe fiecare masina:
                    <ul>
                        <li>192.168.0.10</li>
                        <li>192.168.0.11</li>
                    </ul>
                    si o configuram:
                    <ul>
                        <li>editam <b>server.xml</b> din <b>[tomcat]\conf</b>:
                            <br/>
                            se decomenteaza, daca e comantata, sectiunea <b>Connector protocol="AJP/1.3"</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'' +
                                    '    <Connector protocol="AJP/1.3"\n' +
                                    '               address="::1"\n' +
                                    '               port="8009"\n' +
                                    '               redirectPort="8443" />\n' +
                                    '   '}
                            </SyntaxHighlighter>
                            apoi se schimba in:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'' +
                                    '    <Connector protocol="AJP/1.3"\n' +
                                    '               address="0.0.0.0" \n' +
                                    '               port="8009"\n' +
                                    '               redirectPort="8443"\n' +
                                    '               secretRequired="false"  />\n' +
                                    '   '}
                            </SyntaxHighlighter>

                            apoi modificam in sectiune <i>Engine</i>, adaugand atributul <b>jvmRoute</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Engine name="Catalina" defaultHost="localhost" jvmRoute="tomcat1">'}
                            </SyntaxHighlighter>
                            respectiv
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Engine name="Catalina" defaultHost="localhost" jvmRoute="tomcat2">'}
                            </SyntaxHighlighter>
                            nume atributului <b>jvmRoute="tomcat1"</b> trebuie sa se potriveasca cu ce se gaseste in fisierul <b>workers.properties</b>.
                        </li>
                    </ul>
                    <li>
                        se pornesc serverele de pe masinile:
                        <ul>
                            <li>192.168.0.10</li>
                            <li>192.168.0.11</li>
                        </ul>
                    </li>
                    <li>
                        se verifica ca au pornit serverele:
                        <ul>
                            <li>http://192.168.0.10:8080</li>
                            <li>http://192.168.0.11:8080</li>
                        </ul>
                    </li>
                    <li>
                        se porneste Tomcat-ul de pe masina <i>192.168.0.5</i> care va functiona ca LoadBalancer (cu configurarile facute mai sus)
                    </li>
                    <li>
                        se verifica ca functioneaza cum trebuie LoadBalancer-ul de pe masina <i>192.168.0.5</i>:
                        <b>http://localhost</b> sau <b>http://localhost/<i>nume_aplicatie</i></b>
                        <br/>
                        <br/>
                        S-ar putea din cauza unui bug de la implementare AJP ca IP-ul de masina tinta sa fie <i>null</i> (daca in JSP se apeleaza <i>request.getLocalAddr()</i>).
                    </li>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>
                               https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LoadbancingApacheTomcatContent;