import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers11LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-11", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Exemplu (fisierul page.json:):
                    <SyntaxHighlighter  showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "hidden": false,\n' +
                            '  "private": false,\n' +
                            '  "name_i18n": {\n' +
                            '    "en_US": "Urban",\n' +
                            '    "ro_RO": "Urbanism"\n' +
                            '  },\n' +
                            '  "friendlyURL": "/urban",\n' +
                            '  "system": false,\n' +
                            '  "type": "Content",\n' +
                            '  "permissions": [\n' +
                            '    {\n' +
                            '      "actionIds": [\n' +
                            '      ],\n' +
                            '      "roleName": "Guest",\n' +
                            '      "scope": 4\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "actionIds": [\n' +
                            '        "VIEW"\n' +
                            '      ],\n' +
                            '      "roleName": "User",\n' +
                            '      "scope": 4\n' +
                            '    }\n' +
                            '  ],\n' +
                            '  "typeSettings": [\n' +
                            '\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"}href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/embedding-portlets-and-setting-preferences-in-a-liferay-7-2-theme/maximized"}>
                                Embedding portlets and setting preferences in a Liferay 7.2 theme
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers11LiferayContent;