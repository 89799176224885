import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class WorkerProcessesNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-worker-processes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Workers</b>

                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl status nginx'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {'  CGroup: /system.slice/nginx.service\n' +
                            '             ├─ 1869 "nginx: master process /usr/bin/nginx"\n' +
                            '             └─11155 "nginx: worker process"\n'}
                    </SyntaxHighlighter>

                    In mod implicit pornese un 1 proces de tip <i>worker</i>.

                    <br/>
                    Pentru a mari numarul de workeri se foloseste directiva:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'worker_proceses 2'}
                    </SyntaxHighlighter>
                    Dar numarul de workeri trebuie sa tina cont de numarul de procesoare (CPU).
                    <br/>
                    Prin urmare putem sa scriem asa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'worker_proceses auto'}
                    </SyntaxHighlighter>

                    Pentru a afla numarul de procesoare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nproc'}
                    </SyntaxHighlighter>
                    Poate afisa:2
                    <br/>
                    <br/>
                    Pentru mai multe detalii legate de procesoare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'lscpu'}
                    </SyntaxHighlighter>

                    Daca avem 2 CPU-uri si configurarea <i>worker_proceses auto</i>, atunci la executarea comenzii <i>systemctl status nginx</i>:
                    <SyntaxHighlighter>
                        {' CGroup: /system.slice/nginx.service\n' +
                            '             ├─ 1869 "nginx: master process /usr/bin/nginx"\n' +
                            '             ├─11231 "nginx: worker process"\n' +
                            '             └─11232 "nginx: worker process"\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Conexiuni</b>

                    <br/>
                    <br/>

                    Seteaza numarul de conexiuni pentru fiecare proces de tip worker care poate fi acceptat:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {\n' +
                            '\tworker_connections 1024;\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Numarul de conexiuni se pune in functie de numarul de fisiere care pot fie deschise de CPU.
                    <br/>
                    Pentru a afla aceasta limita:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ulimit -n'}
                    </SyntaxHighlighter>
                    Daca raspunsul e 1024 atunci cu acest numarul se poate seta <i>worker_connections</i>.

                    <br/>
                    <b>
                        Numarul maxim de conexiuni = worker_processes x worker_connections
                    </b>


                    <hr/>
                    <b>3. PID</b>

                    <br/>
                    <br/>

                    La instalarea nginx s-a setat:
                    <br/>
                    <b>--pid-path=/var/run/nginx.pid</b>
                    <br/>

                    Directiva <b>pid</b> permite reconfigurarea acestui parametru din <b>nginx.conf</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'pid /var/run/new_nginx.pid;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WorkerProcessesNginxContent;