import {useRoutes} from "react-router-dom";
import React from "react";
import HelloWorldGoContent from "./HelloWorldGoContent";
import IndexContent from "../go/IndexContent";
import VariablesGoContent from "./VariablesGoContent";
import FunctionsGoContent from "./FunctionsGoContent";
import TypesGoContent from "./TypesGoContent";
import ControlFlowGoContent from "./ControlFlowGoContent";
import ImportGoContent from "./ImportGoContent";
import TestingGoContent from "./TestingGoContent";
import InterfacesGoContent from "./InterfacesGoContent";
import StructGoContent from "./StructGoContent";
import MapGoContent from "./MapGoContent";
import GoRootGoPathGoContent from "./GoRootGoPathGoContent";
import InstallGoContent from "./InstallGoContent";
import WorkspaceGoContent from "./WorkspaceGoContent";
import GoRoutinesGoContent from "./GoRoutinesGoContent";

export default function Router() {
    let element = useRoutes([

        {path: "/go/index", element: <IndexContent/>},

        {path: "/go/install", element: <InstallGoContent/>},
        {path: "/go/workspace", element: <WorkspaceGoContent/>},
        {path: "/go/hello-world", element: <HelloWorldGoContent/>},
        {path: "/go/variables", element: <VariablesGoContent/>},
        {path: "/go/functions", element: <FunctionsGoContent/>},
        {path: "/go/types", element: <TypesGoContent/>},
        {path: "/go/control-flow", element: <ControlFlowGoContent/>},
        {path: "/go/import", element: <ImportGoContent/>},
        {path: "/go/testing", element: <TestingGoContent/>},

        {path: "/go/struct", element: <StructGoContent/>},
        {path: "/go/map", element: <MapGoContent/>},
        {path: "/go/interfaces", element: <InterfacesGoContent/>},
        {path: "/go/go-routines", element: <GoRoutinesGoContent/>},
        {path: "/go/goroot-gopath", element: <GoRootGoPathGoContent/>},
    ]);

    return element;
}