import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TypesGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Tipuri de date</b>
                    <br/>
                    <br/>

                    Tipuri:
                    <ul>
                        <li>bool</li>
                        <li>string</li>
                        <li>int</li>
                        <li>float64</li>
                        <li>byte</li>
                    </ul>

                    <ul>
                        <li>
                            array - lungime fixa
                        </li>
                        <li>
                            slice - array cu lungime variabila (poate creste sau se poate micsora );
                            <br/>
                            fiecare element dintr-un slice trebuie sa fie de acelasi tip!
                            <SyntaxHighlighter>
                                {'s := []string{"a", "b", "c"}\n' +
                                    's = append(s, "d") // returneaza un nou slice'}
                            </SyntaxHighlighter>

                            acesare:
                            <SyntaxHighlighter>
                                {'s[0]'}
                            </SyntaxHighlighter>

                            subelemente:
                            <SyntaxHighlighter>
                                {'slice[startIndexInclus:endIndexExclus]'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                                {'s[0:2] // a, b'}
                            </SyntaxHighlighter>
                            este identic cu:
                            <SyntaxHighlighter>
                                {'s[:2] // a, b'}
                            </SyntaxHighlighter>
                            se poate scrie si:
                            <SyntaxHighlighter>
                                {'s[2:] // c'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    <hr/>
                    <b>2. Conversii</b>
                    <br/>
                    <br/>
                    Pentru a convertii un string la un byte[]:
                    <SyntaxHighlighter>
                        {'[]byte("Buna")'}
                    </SyntaxHighlighter>

                    Pentru a concatena un string[] la un string:
                    <SyntaxHighlighter>
                        {'	str := []string{"a", "b"}\n	strConcat := strings.Join(str, "-")\n\n	fmt.Println(strConcat) //a-b'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Declarare tip nou de date</b>
                    <br/>
                    <br/>
                    Se foloseste cuvantul cheie <b>type</b>:
                    <SyntaxHighlighter>
                        {'type lista []string'}
                    </SyntaxHighlighter>

                    O functie atasata la un tip este <b>receiver</b>:
                    <SyntaxHighlighter>
                        {'func (nume_variabila tip) nume_functie(){..})'}
                    </SyntaxHighlighter>
                    Orice variabila de tipul <i>tip</i> are acces la <i>nume_functie</i>!
                    <br/>
                    Variabila <i>nume_variabila</i> este o copie a variabilei/ o referinta (sa i zicem <i>v</i>) care "apeleaza" functia <i>nume_functie</i>
                    <SyntaxHighlighter>
                        {'v.nume_functie()'}
                    </SyntaxHighlighter>
                    Deci <i>nume_variabila</i> = <i>v</i>.
                    <br/>
                    In alte limbaje, <i>nume_variabila</i> ar fi: this sau self! (dar prin conventie, nu se folosesc)

                    <br/>
                    <br/>

                    Exemplu (fie fisierul group.go):
                    <SyntaxHighlighter>
                        {'package main\n\nimport "fmt"\n\ntype group []string\n\nfunc (g group) print() {\n	for i, item := range g {\n		fmt.Println(i, item)\n	}\n}'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter>
                        {'g := group{"kj-1", "kj-2"}\ng.print()'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TypesGoContent;