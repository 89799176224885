import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";
import RxJxLastTestComponent from "./rxjs2.component";

class RxJsLastReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-rxjs-last", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Last </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'color$.pipe()\n' +
                            '     .subscribe(value => {\n' +
                            '         console.log("=>",value);\n' +
                            '      });\n' +
                            '\n' +
                            'color$.pipe(last())\n' +
                            '     .subscribe(value => {\n' +
                            '         console.log("last =>",value);\n' +
                            '      });\n' +
                            '\n' +
                            'color$.next(\'white\');\n' +
                            'color$.next(\'green\');\n' +
                            'color$.next(\'red\');\n' +
                            'color$.next(\'blue\');\n' +
                            'color$.complete(); // fara complete() nu se mai afiseaza "last"'}
                    </SyntaxHighlighter>

                    se va afisa in log:
                    <SyntaxHighlighter>
                        {'=> white\n' +
                            '=> green\n' +
                            '=> red\n' +
                            '=> blue\n' +
                            'last => blue'}
                    </SyntaxHighlighter>

                    <RxJxLastTestComponent></RxJxLastTestComponent>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/rxjs-operators-forkjoin-zip-combinelatest-withlatestfrom"}>*/}
                    {/*           Înțelegerea operatorilor RxJS: forkJoin, zip, combineLatest și withLatestFrom*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RxJsLastReactContent;