import {useRoutes} from "react-router-dom";
import React from "react";
import KubernetesHomeContent from "./IndexContent";
import InstallKubernetesContent from "./InstallKubernetesContent";
import Ex1k8sContent from "./Ex1k8sContent";
import ConfigFilek8sContent from "./ConfigFilek8sContent";
import KubectlApplyContent from "./KubectlApplyContent";
import KubectlGetContent from "./KubectlGetContent";
import KubectlDescribeContent from "./KubectlDescribeContent";
import KubectlDeleteContent from "./KubectlDeleteContent";
import KubectlSetContent from "./KubectlSetContent";
import KubectlLogsContent from "./KubectlLogsContent";
import KubectlExecContent from "./KubectlExecContent";
import KubectlRunContent from "./KubectlRunContent";
import KubectlScaleContent from "./KubectlScaleContent";
import KubectlExposeContent from "./KubectlExposeContent";
import KubectlRolloutContent from "./KubectlRolloutContent";
import Ex2k8sContent from "./Ex2k8sContent";
import Ex3k8sContent from "./Ex3k8sContent";
import CombiningConfigContent from "./CombiningConfigContent";
import Volumek8sContent from "./Volumek8sContent";
import KubectlCreateContent from "./KubectlCreateContent";
import IngressContent from "./IngressContent";
import MinikubeDashboardContent from "./MinikubeDashboardContent";
import Ex2k8s2Content from "./Ex2k8s2Content";
import CoreKubernetesContent from "./CoreKubernetesContent";
import LinksK8sContent from "./LinksK8sContent";
import DeploymentObjectK8sContent from "./DeploymentObjectK8sContent";
import PodObjectK8sContent from "./PodObjectK8sContent";
import ServiceObjectK8sContent from "./ServiceObjectK8sContent";
import SelectorK8sContent from "./SelectorK8sContent";
import LivenessProbeK8sContent from "./LivenessProbeK8sContent";
import ImagePullPolicyK8sContent from "./ImagePullPolicyK8sContent";
import EnvK8sContent from "./EnvK8sContent";
import ConfigMapK8sContent from "./ConfigMapK8sContent";
import NetworkingK8sContent from "./NetworkingK8sContent";
import NamespaceK8sContent from "./NamespaceK8sContent";
import ReplicaSetsObjectK8sContent from "./ReplicaSetsObjectK8sContent";
import JobObjectK8sContent from "./JobObjectK8sContent";
import SecretK8sContent from "./SecretK8sContent";
import KubectlDrainContent from "./KubectlDrainContent";
import KubectlCordonContent from "./KubectlCordonContent";
import KubectlUncordonContent from "./KubectlUncordonContent";
import KubectlLabelContent from "./KubectlLabelContent";
import KubectlTaintContent from "./KubectlTaintContent";
import KubectlAutoscaleContent from "./KubectlAutoscaleContent";
import KubectlEditContent from "./KubectlEditContent";
import ComponentsKubernetesContent from "./ComponentsKubernetesContent";
import KubectlClusterInfoContent from "./KubectlClusterInfoContent";
import CmdResumeK8sContent from "./CmdResumeK8sContent";
import SecurityContextK8sContent from "./SecurityContextK8sContent";
import ServiceAccountK8sContent from "./ServiceAccountK8sContent";
import ResourcesK8sContent from "./ResourcesK8sContent";
import KubeadmK8sContent from "./KubeadmK8sContent";
import KubeadmDockerK8sContent from "./KubeadmDockerK8sContent";
import PodmanK8sContent from "./PodmanK8sContent";
import ReadinessProbeK8sContent from "./ReadinessProbeK8sContent";
import NetworkPluginsK8sContent from "./NetworkPluginsK8sContent";
import CronJobObjectK8sContent from "./CronJobObjectK8sContent";
import InitContainerK8sContent from "./InitContainerK8sContent";
import CustomResourceDefinitionsK8sContent from "./CustomResourceDefinitionsK8sContent";
import TaintsTolerationsK8sContent from "./TaintsTolerationsK8sContent";
import NodeSelectorsK8sContent from "./NodeSelectorsK8sContent";
import NodeAffinityK8sContent from "./NodeAffinityK8sContent";
import MultipodK8sContent from "./MultipodK8sContent";
import KubectlReplaceContent from "./KubectlReplaceContent";
import LoggingK8sContent from "./LoggingK8sContent";
import MonitorDebugK8sContent from "./MonitorDebugK8sContent";
import RollingRollbackK8sContent from "./RollingRollbackK8sContent";
import PrivateRoute from "../../security/PrivateRoute";
import PostgresqlK8sContent from "./PostgresqlK8sContent";
import ElasticsearchK8sContent from "./ElasticsearchK8sContent";
import NginxK8sContent from "./NginxK8sContent";
import LiferayK8sContent from "./LiferayK8sContent";
import NetworkPoliciesK8sContent from "./NetworkPoliciesK8sContent";
import StorageClassesK8sContent from "./StorageClassesK8sContent";
import StatefulSetsK8sContent from "./StatefulSetsK8sContent";
import AuthenticationK8sContent from "./AuthenticationK8sContent";
import AuthorizationK8sContent from "./AuthorizationK8sContent";
import AdmissionK8sContent from "./AdmissionK8sContent";
import HelmK8sContent from "./HelmK8sContent";
import KubeConfigK8sContent from "./KubeConfigK8sContent";
import APIGroupsK8sContent from "./APIGroupsK8sContent";
import APIVersionDeprecationsK8sContent from "./APIVersionDeprecationsK8sContent";
import Lab1K8sContent from "./Lab1K8sContent";
import Lab2K8sContent from "./Lab2K8sContent";
import Lab3K8sContent from "./Lab3K8sContent";
import Lab5K8sContent from "./Lab5K8sContent";
import Lab4K8sContent from "./Lab4K8sContent";
import Lab6K8sContent from "./Lab6K8sContent";
import Lab7K8sContent from "./Lab7K8sContent";
import Lab8K8sContent from "./Lab8K8sContent";
import LabK8sContent from "./LabK9sContent";
import CKadK8sContent from "./CKadK8sContent";
import CKadExK8sContent from "./CKadExK8sContent";
import CKadEx2K8sContent from "./CKadEx2K8sContent";
import CKadEx3K8sContent from "./CKadEx3K8sContent";
import CKadEx4K8sContent from "./CKadEx4K8sContent";
import CKadEx5K8sContent from "./CKadEx5K8sContent";
import CKadExRK8sContent from "./CKadExRK8sContent";
import CKadEx6K8sContent from "./CKadEx6K8sContent";
import CKadEx7K8sContent from "./CKadEx7K8sContent";
import ServiceNetworkPolicyK8sContent from "./ServiceNetworkPolicyK8sContent";

{/*kubernetes*/
}
/*
<Route path="/kubernetes/index" element={<KubernetesHomeContent/>} exact/>
<Route element={<PrivateRoutes/>}>
    <Route path="/kubernetes/index" element={<KubernetesHomeContent/>} exact/>
    <Route path="/kubernetes/install" element={<InstallKubernetesContent/>} exact/>
    <Route path="/kubernetes/ex1" element={<Ex1k8sContent/>} exact/>
    <Route path="/kubernetes/config-file" element={<ConfigFilek8sContent/>} exact/>
    <Route path="/kubernetes/kubectl-apply" element={<KubectlApplyContent/>} exact/>
    <Route path="/kubernetes/kubectl-get" element={<KubectlGetContent/>} exact/>
    <Route path="/kubernetes/kubectl-describe" element={<KubectlDescribeContent/>} exact/>
    <Route path="/kubernetes/kubectl-delete" element={<KubectlDeleteContent/>} exact/>
    <Route path="/kubernetes/kubectl-set" element={<KubectlSetContent/>} exact/>
    <Route path="/kubernetes/kubectl-logs" element={<KubectlLogsContent/>} exact/>
    <Route path="/kubernetes/kubectl-exec" element={<KubectlExecContent/>} exact/>
    <Route path="/kubernetes/kubectl-run" element={<KubectlRunContent/>} exact/>
    <Route path="/kubernetes/kubectl-scale" element={<KubectlScaleContent/>} exact/>
    <Route path="/kubernetes/kubectl-expose" element={<KubectlExposeContent/>} exact/>
    <Route path="/kubernetes/kubectl-rollout" element={<KubectlRolloutContent/>} exact/>
    <Route path="/kubernetes/ex2" element={<Ex2k8sContent/>} exact/>
    <Route path="/kubernetes/ex3" element={<Ex3k8sContent/>} exact/>
    <Route path="/kubernetes/combining-config" element={<CombiningConfigContent/>} exact/>
    <Route path="/kubernetes/volume" element={<Volumek8sContent/>} exact/>
    <Route path="/kubernetes/kubectl-create" element={<KubectlCreateContent/>} exact/>
    <Route path="/kubernetes/ingress" element={<IngressContent/>} exact/>
    <Route path="/kubernetes/minikube-dashboard" element={<MinikubeDashboardContent/>} exact/>
    <Route path="/kubernetes/ex2-2" element={<Ex2k8s2Content/>} exact/>
    <Route path="/kubernetes/core" element={<CoreKubernetesContent/>} exact/>
    <Route path="/kubernetes/links" element={<LinksK8sContent/>} exact/>
    <Route path="/kubernetes/deployment" element={<DeploymentObjectK8sContent/>} exact/>
    <Route path="/kubernetes/pod" element={<PodObjectK8sContent/>} exact/>
    <Route path="/kubernetes/service" element={<ServiceObjectK8sContent/>} exact/>
    <Route path="/kubernetes/selector" element={<SelectorK8sContent/>} exact/>
    <Route path="/kubernetes/liveness-probe" element={<LivenessProbeK8sContent/>} exact/>
    <Route path="/kubernetes/image-pull-policy" element={<ImagePullPolicyK8sContent/>} exact/>
    <Route path="/kubernetes/env" element={<EnvK8sContent/>} exact/>
    <Route path="/kubernetes/config-map" element={<ConfigMapK8sContent/>} exact/>
    <Route path="/kubernetes/networking" element={<NetworkingK8sContent/>} exact/>
    <Route path="/kubernetes/namespace" element={<NamespaceK8sContent/>} exact/>
    <Route path="/kubernetes/replicasets" element={<ReplicaSetsObjectK8sContent/>} exact/>
    <Route path="/kubernetes/job" element={<JobObjectK8sContent/>} exact/>
    <Route path="/kubernetes/secret" element={<SecretK8sContent/>} exact/>
    <Route path="/kubernetes/kubectl-drain" element={<KubectlDrainContent/>} exact/>
    <Route path="/kubernetes/kubectl-cordon" element={<KubectlCordonContent/>} exact/>
    <Route path="/kubernetes/kubectl-uncordon" element={<KubectlUncordonContent/>} exact/>
    <Route path="/kubernetes/kubectl-label" element={<KubectlLabelContent/>} exact/>
    <Route path="/kubernetes/kubectl-taint" element={<KubectlTaintContent/>} exact/>
    <Route path="/kubernetes/kubectl-autoscale" element={<KubectlAutoscaleContent/>} exact/>
    <Route path="/kubernetes/kubectl-edit" element={<KubectlEditContent/>} exact/>
    <Route path="/kubernetes/components" element={<ComponentsKubernetesContent/>} exact/>
    <Route path="/kubernetes/kubectl-cluster-info" element={<KubectlClusterInfoContent/>} exact/>
    <Route path="/kubernetes/cmd-resume" element={<CmdResumeK8sContent/>} exact/>
    <Route path="/kubernetes/security-context" element={<SecurityContextK8sContent/>} exact/>
    <Route path="/kubernetes/service-account" element={<ServiceAccountK8sContent/>} exact/>
    <Route path="/kubernetes/resources" element={<ResourcesK8sContent/>} exact/>
    <Route path="/kubernetes/kubeadm" element={<KubeadmK8sContent/>} exact/>
    <Route path="/kubernetes/kubeadm-docker" element={<KubeadmDockerK8sContent/>} exact/>
    <Route path="/kubernetes/podman" element={<PodmanK8sContent/>} exact/>
    <Route path="/kubernetes/readiness-probe" element={<ReadinessProbeK8sContent/>} exact/>
    <Route path="/kubernetes/network-plugins" element={<NetworkPluginsK8sContent/>} exact/>
    <Route path="/kubernetes/cronjob" element={<CronJobObjectK8sContent/>} exact/>
    <Route path="/kubernetes/initcontainer" element={<InitContainerK8sContent/>} exact/>
    <Route path="/kubernetes/crd" element={<CustomResourceDefinitionsK8sContent/>} exact/>
    <Route path="/kubernetes/taints-tolerations" element={<TaintsTolerationsK8sContent/>} exact/>
    <Route path="/kubernetes/node-selectors" element={<NodeSelectorsK8sContent/>} exact/>
    <Route path="/kubernetes/node-affinity" element={<NodeAffinityK8sContent/>} exact/>
    <Route path="/kubernetes/multipod" element={<MultipodK8sContent/>} exact/>
    <Route path="/kubernetes/kubectl-replace" element={<KubectlReplaceContent/>} exact/>
    <Route path="/kubernetes/logging" element={<LoggingK8sContent/>} exact/>
    <Route path="/kubernetes/monitor-debug" element={<MonitorDebugK8sContent/>} exact/>
    <Route path="/kubernetes/rolling-rollout" element={<RollingRollbackK8sContent/>} exact/>
</Route>
*/

function Lab8K9sContentK8sContent() {
    return null;
}

export default function Router() {
    let element = useRoutes([

        {path: "/kubernetes/index", element: <KubernetesHomeContent/>},
        {path: "/kubernetes/install", element: <PrivateRoute element={<InstallKubernetesContent/>}/>},
        {path: "/kubernetes/ex1", element: <PrivateRoute element={<Ex1k8sContent/>}/>},
        {path: "/kubernetes/config-file", element: <PrivateRoute element={<ConfigFilek8sContent/>}/>},
        {path: "/kubernetes/kubectl-apply", element: <PrivateRoute element={<KubectlApplyContent/>}/>},
        {path: "/kubernetes/kubectl-get", element: <PrivateRoute element={<KubectlGetContent/>}/>},
        {path: "/kubernetes/kubectl-describe", element: <PrivateRoute element={<KubectlDescribeContent/>}/>},
        {path: "/kubernetes/kubectl-delete", element: <PrivateRoute element={<KubectlDeleteContent/>}/>},
        {path: "/kubernetes/kubectl-set", element: <PrivateRoute element={<KubectlSetContent/>}/>},
        {path: "/kubernetes/kubectl-logs", element: <PrivateRoute element={<KubectlLogsContent/>}/>},
        {path: "/kubernetes/kubectl-exec", element: <PrivateRoute element={<KubectlExecContent/>}/>},
        {path: "/kubernetes/kubectl-run", element: <PrivateRoute element={<KubectlRunContent/>}/>},
        {path: "/kubernetes/kubectl-scale", element: <PrivateRoute element={<KubectlScaleContent/>}/>},
        {path: "/kubernetes/kubectl-expose", element: <PrivateRoute element={<KubectlExposeContent/>}/>},
        {path: "/kubernetes/kubectl-rollout", element: <PrivateRoute element={<KubectlRolloutContent/>}/>},
        {path: "/kubernetes/ex2", element: <PrivateRoute element={<Ex2k8sContent/>}/>},
        {path: "/kubernetes/ex3", element: <PrivateRoute element={<Ex3k8sContent/>}/>},
        {path: "/kubernetes/combining-config", element: <PrivateRoute element={<CombiningConfigContent/>}/>},
        {path: "/kubernetes/volume", element: <PrivateRoute element={<Volumek8sContent/>}/>},
        {path: "/kubernetes/kubectl-create", element: <PrivateRoute element={<KubectlCreateContent/>}/>},
        {path: "/kubernetes/ingress", element: <PrivateRoute element={<IngressContent/>}/>},
        {path: "/kubernetes/minikube-dashboard", element: <PrivateRoute element={<MinikubeDashboardContent/>}/>},
        {path: "/kubernetes/ex2-2", element: <PrivateRoute element={<Ex2k8s2Content/>}/>},
        {path: "/kubernetes/core", element: <PrivateRoute element={<CoreKubernetesContent/>}/>},
        {path: "/kubernetes/links", element: <PrivateRoute element={<LinksK8sContent/>}/>},
        {path: "/kubernetes/deployment", element: <PrivateRoute element={<DeploymentObjectK8sContent/>}/>},
        {path: "/kubernetes/pod", element: <PrivateRoute element={<PodObjectK8sContent/>}/>},
        {path: "/kubernetes/service", element: <PrivateRoute element={<ServiceObjectK8sContent/>}/>},
        {path: "/kubernetes/selector", element: <PrivateRoute element={<SelectorK8sContent/>}/>},
        {path: "/kubernetes/liveness-probe", element: <PrivateRoute element={<LivenessProbeK8sContent/>}/>},
        {path: "/kubernetes/image-pull-policy", element: <PrivateRoute element={<ImagePullPolicyK8sContent/>}/>},
        {path: "/kubernetes/env", element: <PrivateRoute element={<EnvK8sContent/>}/>},
        {path: "/kubernetes/config-map", element: <PrivateRoute element={<ConfigMapK8sContent/>}/>},
        {path: "/kubernetes/networking", element: <PrivateRoute element={<NetworkingK8sContent/>}/>},
        {path: "/kubernetes/namespace", element: <PrivateRoute element={<NamespaceK8sContent/>}/>},
        {path: "/kubernetes/replicasets", element: <PrivateRoute element={<ReplicaSetsObjectK8sContent/>}/>},
        {path: "/kubernetes/job", element: <PrivateRoute element={<JobObjectK8sContent/>}/>},
        {path: "/kubernetes/secret", element: <PrivateRoute element={<SecretK8sContent/>}/>},
        {path: "/kubernetes/kubectl-drain", element: <PrivateRoute element={<KubectlDrainContent/>}/>},
        {path: "/kubernetes/kubectl-cordon", element: <PrivateRoute element={<KubectlCordonContent/>}/>},
        {path: "/kubernetes/kubectl-uncordon", element: <PrivateRoute element={<KubectlUncordonContent/>}/>},
        {path: "/kubernetes/kubectl-label", element: <PrivateRoute element={<KubectlLabelContent/>}/>},
        {path: "/kubernetes/kubectl-taint", element: <PrivateRoute element={<KubectlTaintContent/>}/>},
        {path: "/kubernetes/kubectl-autoscale", element: <PrivateRoute element={<KubectlAutoscaleContent/>}/>},
        {path: "/kubernetes/kubectl-edit", element: <PrivateRoute element={<KubectlEditContent/>}/>},
        {path: "/kubernetes/components", element: <PrivateRoute element={<ComponentsKubernetesContent/>}/>},
        {path: "/kubernetes/kubectl-cluster-info", element: <PrivateRoute element={<KubectlClusterInfoContent/>}/>},
        {path: "/kubernetes/cmd-resume", element: <PrivateRoute element={<CmdResumeK8sContent/>}/>},
        {path: "/kubernetes/security-context", element: <PrivateRoute element={<SecurityContextK8sContent/>}/>},
        {path: "/kubernetes/service-account", element: <PrivateRoute element={<ServiceAccountK8sContent/>}/>},
        {path: "/kubernetes/resources", element: <PrivateRoute element={<ResourcesK8sContent/>}/>},
        {path: "/kubernetes/kubeadm", element: <PrivateRoute element={<KubeadmK8sContent/>}/>},
        {path: "/kubernetes/kubeadm-docker", element: <PrivateRoute element={<KubeadmDockerK8sContent/>}/>},
        {path: "/kubernetes/podman", element: <PrivateRoute element={<PodmanK8sContent/>}/>},
        {path: "/kubernetes/readiness-probe", element: <PrivateRoute element={<ReadinessProbeK8sContent/>}/>},
        {path: "/kubernetes/network-plugins", element: <PrivateRoute element={<NetworkPluginsK8sContent/>}/>},
        {path: "/kubernetes/cronjob", element: <PrivateRoute element={<CronJobObjectK8sContent/>}/>},
        {path: "/kubernetes/initcontainer", element: <PrivateRoute element={<InitContainerK8sContent/>}/>},
        {path: "/kubernetes/crd", element: <PrivateRoute element={<CustomResourceDefinitionsK8sContent/>}/>},
        {path: "/kubernetes/taints-tolerations", element: <PrivateRoute element={<TaintsTolerationsK8sContent/>}/>},
        {path: "/kubernetes/node-selectors", element: <PrivateRoute element={<NodeSelectorsK8sContent/>}/>},
        {path: "/kubernetes/node-affinity", element: <PrivateRoute element={<NodeAffinityK8sContent/>}/>},
        {path: "/kubernetes/multipod", element: <PrivateRoute element={<MultipodK8sContent/>}/>},
        {path: "/kubernetes/kubectl-replace", element: <PrivateRoute element={<KubectlReplaceContent/>}/>},
        {path: "/kubernetes/logging", element: <PrivateRoute element={<LoggingK8sContent/>}/>},
        {path: "/kubernetes/monitor-debug", element: <PrivateRoute element={<MonitorDebugK8sContent/>}/>},
        {path: "/kubernetes/rolling-rollout", element: <PrivateRoute element={<RollingRollbackK8sContent/>}/>},

        {path: "/kubernetes/postgresql", element: <PrivateRoute element={<PostgresqlK8sContent/>}/>},
        {path: "/kubernetes/elasticsearch", element: <PrivateRoute element={<ElasticsearchK8sContent/>}/>},
        {path: "/kubernetes/liferay", element: <PrivateRoute element={<LiferayK8sContent/>}/>},
        {path: "/kubernetes/nginx", element: <PrivateRoute element={<NginxK8sContent/>}/>},

        {path: "/kubernetes/network-policies", element: <PrivateRoute element={<NetworkPoliciesK8sContent/>}/>},

        {path: "/kubernetes/storage-classes", element: <PrivateRoute element={<StorageClassesK8sContent/>}/>},
        {path: "/kubernetes/stateful-sets", element: <PrivateRoute element={<StatefulSetsK8sContent/>}/>},

        {path: "/kubernetes/authentication", element: <PrivateRoute element={<AuthenticationK8sContent/>}/>},
        {path: "/kubernetes/authorization", element: <PrivateRoute element={<AuthorizationK8sContent/>}/>},
        {path: "/kubernetes/admission", element: <PrivateRoute element={<AdmissionK8sContent/>}/>},

        {path: "/kubernetes/helm", element: <PrivateRoute element={<HelmK8sContent/>}/>},
        {path: "/kubernetes/kubeconfig", element: <PrivateRoute element={<KubeConfigK8sContent/>}/>},
        {path: "/kubernetes/api-groups", element: <PrivateRoute element={<APIGroupsK8sContent/>}/>},
        {path: "/kubernetes/api-version-deprecations", element: <PrivateRoute element={<APIVersionDeprecationsK8sContent/>}/>},

        {path: "/kubernetes/lab/1", element: <PrivateRoute element={<Lab1K8sContent/>}/>},
        {path: "/kubernetes/lab/2", element: <PrivateRoute element={<Lab2K8sContent/>}/>},
        {path: "/kubernetes/lab/3", element: <PrivateRoute element={<Lab3K8sContent/>}/>},
        {path: "/kubernetes/lab/4", element: <PrivateRoute element={<Lab4K8sContent/>}/>},
        {path: "/kubernetes/lab/5", element: <PrivateRoute element={<Lab5K8sContent/>}/>},
        {path: "/kubernetes/lab/6", element: <PrivateRoute element={<Lab6K8sContent/>}/>},
        {path: "/kubernetes/lab/7", element: <PrivateRoute element={<Lab7K8sContent/>}/>},
        {path: "/kubernetes/lab/8", element: <PrivateRoute element={<Lab8K8sContent/>}/>},
        {path: "/kubernetes/lab", element: <PrivateRoute element={<LabK8sContent/>}/>},
        {path: "/kubernetes/ckad", element: <PrivateRoute element={<CKadK8sContent/>}/>},
        {path: "/kubernetes/ckad-ex", element: <PrivateRoute element={<CKadExK8sContent/>}/>},
        {path: "/kubernetes/ckad-ex2", element: <PrivateRoute element={<CKadEx2K8sContent/>}/>},
        {path: "/kubernetes/ckad-ex3", element: <PrivateRoute element={<CKadEx3K8sContent/>}/>},
        {path: "/kubernetes/ckad-ex4", element: <PrivateRoute element={<CKadEx4K8sContent/>}/>},
        {path: "/kubernetes/ckad-ex5", element: <PrivateRoute element={<CKadEx5K8sContent/>}/>},
        {path: "/kubernetes/ckad-ex6", element: <PrivateRoute element={<CKadEx6K8sContent/>}/>},
        {path: "/kubernetes/ckad-ex7", element: <PrivateRoute element={<CKadEx7K8sContent/>}/>},
        {path: "/kubernetes/ckad-exr", element: <PrivateRoute element={<CKadExRK8sContent/>}/>},
        {path: "/kubernetes/service-netpol", element: <PrivateRoute element={<ServiceNetworkPolicyK8sContent/>}/>},

    ]);

    return element;
}