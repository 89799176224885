import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";

class GenericsTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-generics", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Generice</b>
                    <br/>
                    <br/>

                    Exemplu (T inlocuieste in mod generic un tip; T este o conventie):
                    <SyntaxHighlighter  showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'class Queue<T>{\n' +
                            '    private data: T[] = [];\n' +
                            '\n' +
                            '    add(item:T){\n' +
                            '        this.data.push(item);\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'const q1 = new Queue<number>();\n' +
                            'q1.add(1);\n' +
                            '\n' +
                            'const q2 = new Queue<string>();\n' +
                            'q2.add("1");'}
                    </SyntaxHighlighter>
                    Genericele se pot folosi si la functii / metode:

                    <SyntaxHighlighter  showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'function fun<T>(x:T):T{\n' +
                            '    return x;\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GenericsTypescriptContent;