import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JQueryActivationLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-jquery-activation", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    În mod implicit, în Liferay DXP 7.3, jQuery este dezactivat.
                    <br/>
                    Dar il puteam activa folosind panoul de control.
                    <br/>

                    Trebuie doar să vă autentificați cu Admin:
                    <ul>
                        <li>Control Panel - System Settings - Third Party - JQuery</li>
                    </ul>

                    In felul acesta, vor functiona de exemplu modalele din Bootstrap
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://liferayiseasy.blogspot.com/2022/06/how-to-enable-jquery-in-liferay-dxp-73.html"}>
                                    How to enable jQuery in Liferay DXP (7.3)
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JQueryActivationLiferayContent;