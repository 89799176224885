import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OopInheritancePolymorphismPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-oop-inheritance-polymorphism", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Mostenirea</b>
                    <br/>
                    <br/>

                    Mostenirea:
                    <ul>
                        <li>exprima relatiile dintre clase: superclase (parinti) si sublase (copii, descendentii)</li>
                        <li>creeaza o ierarhie de clase</li>
                        <li>asigura ca un obiect pe un anumit nivel al ierarhivei de clase, mosteneste toate trasaturile (metodele si atributule) superclaselor lui</li>
                        <li>este o modalitate de a construi o nouă clasă, nu de la zero, ci prin utilizarea unor trăsături deja definite (de superclaselele lui)</li>
                        <li>permite, adaugare de noi trasaturi, pe langa cele mostenite</li>
                    </ul>

                    Fiecare subclasă este mai specializată (sau mai specifică) decât superclasa sa.
                    <br/>
                    Fiecare superclasă este mai generală (mai abstractă) decât oricare dintre subclasele sale.
                    <br/>
                    <br/>
                    Python permite mostenire multipla (dar nu este recomandata; problema diamantului).
                    <br/>
                    Pentru moștenirea multiplă, orice atribut specificat este cautat, pe baza algorimtului MRO :
                    <ul>
                        <li>
                            in clasa curentă
                        </li>
                        <li>
                            daca nu e gasit, in clasele părinte directe, de la stânga la dreapta, conform definitiei clasei
                        </li>
                        <li>
                            se poate intampla sa apara problme la cautare:<br/>
                            exemplu: <i>TypeError: Cannot create a consistent method resolution order (MRO) for bases A, C</i>
                        </li>
                    </ul>

                    <hr/>

                    <b>2. Polimorfism</b>
                    <br/>
                    <br/>

                    Polimorfismul:
                    <ul>
                        <li>
                            este furnizarea unei singure interfețe pentru obiecte de diferite tipuri
                        </li>
                        <li>
                            este capacitatea de a trata in mod uniform tipuri diferite (de exemplu: '+' poate concatena siruri si aduna numere)
                        </li>
                    </ul>

                    O modalitate de a realiza polimorfismul este moștenirea, atunci când subclasele folosesc metodele clasei de bază sau le suprascriu.

                    <br/>
                    <br/>

                    Testarea, tipizarea ratei (duck typing): <i>Dacă merge ca o rață și macane ca o rață, atunci trebuie să fie o rață”</i> se foloseste pentru a determina dacă un obiect poate fi folosit într-un anumit scop.
                    In mod normal, se apleaza o metoda(), stiind ca aceea metoda exista pe acel obiect de un anumit tip.
                    <br/>
                    In testarea de rata, se încearcă apelelarea metodei(), neavand garantia ca se se poate.
                    <br/>
                    <b>In testarea de rata, pentru un obiect e mai importanta prezenta unor metode si proprietati decat tipul acestuia.</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Rata:\n' +
                        '    def macane(self):\n' +
                        '        print("Mac mac")\n' +
                        '\n' +
                        'class Vrabioara:\n' +
                        '    def macane(self):\n' +
                        '        print("nu prea macane")\n' +
                        '        \n' +
                        'class Gugustiuc:\n' +
                        '    pass\n' +
                        '\n' +
                        'for pasare in Rata(), Vrabioara(), Gugustiuc():\n' +
                        '    try:\n' +
                        '        pasare.macane()\n' +
                        '    except AttributeError:\n' +
                        '        print(\'Nu are functie de macane()-ala!\')'}
                    </SyntaxHighlighter>

                    Tipizarea de rață este o altă modalitate de a obține polimorfismul și reprezintă o abordare mai generală decât polimorfismul obținut prin moștenire.

                    <br/>
                    In cazul moștenirii, toate subclasele au metode numite la fel ca metodele din superclasă.
                    <br/>
                    In cazul tipizarii de rata, credem că obiectele au metodele (intamplarea face ca in exemplul de mai sus clasa <i>Vrabioara</i> sa aiba metoda <i>macane()</i>).
                    Dacă nu le au, atunci ar trebui să gestionăm excepțiile (clasa <i>Gugustiuc</i> nu are metoda <i>macane()</i>).

                    <br/>
                    <br/>
                    Polimorfismul:
                    <ul>
                        <li>
                            este utilizat atunci când diferite obiecte de clasă împărtășesc metode similare din punct de vedere conceptual (dar nu sunt întotdeauna moștenite)
                        </li>
                        <li>
                            valorifică claritatea și expresivitatea proiectării și dezvoltării aplicației
                        </li>
                        <li>
                            presupune gestionarea excepțiile care ar putea apărea (cel putin in cazul tipizari ratei)
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OopInheritancePolymorphismPythonContent;