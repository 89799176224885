import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class SshLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-ssh", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Daca nu este instalat:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install openssh-server'}
                    </SyntaxHighlighter>
                    Pentru a verifica ca <b>sshd</b> e pornit:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'service ssh status'}
                    </SyntaxHighlighter>

                    Daca nu e pornit:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'service ssh start'}
                    </SyntaxHighlighter>

                    Pentru conectare remote la alta masina (fizica sau virtuala):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ssh <username>@<ip-sau-hostname> -p <port>'}
                    </SyntaxHighlighter>

                    SSH este peste TCP si portul implicit este 22.

                    <hr/>
                    In mod implicit, nu se permite conectarea cu utilizatorul <b>root</b>!
                    <br/>
                    Pentru a face acest lucru, totusi, trebuie modificat fisierul de configurare <b>/etc/ssh/ssh_config</b>.
                    <br/>
                    In loc de <b>#PermitRootLogin prohibit-password</b> trebuie <b>PermitRootLogin yes</b>.
                    <br/>
                    Dupa aceasta operatie trebuie restartat serviciul ssh:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'service ssh restart'}
                    </SyntaxHighlighter>
                    <hr/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SshLinuxContent;