import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex2DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 2 (visits)</b>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir visits'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd visits'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.1. Creare package.json</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch package.json'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano package.json'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'{\n' +
                            '  "dependencies": {\n' +
                            '    "express": "*",\n' +
                            '    "redis": "2.8.0"\n' +
                            '  },\n' +
                            '  "scripts": {\n' +
                            '    "start": "node index.js"\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Creare index.js</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch index.js'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano index.js'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'const express = require(\'express\');\n' +
                            'const redis = require(\'redis\');\n' +
                            '\n' +
                            'const app = express();\n' +
                            'const client = redis.createClient();\n' +
                            'client.set(\'visits\', 0);\n' +
                            '\n' +
                            'app.get(\'/\', (req, res) => {\n' +
                            '  client.get(\'visits\', (err, visits) => {\n' +
                            '    res.send(\'Number of visits \' + visits);\n' +
                            '    client.set(\'visits\', parseInt(visits) + 1);\n' +
                            '  });\n' +
                            '});\n' +
                            '\n' +
                            'app.listen(8081, () => {\n' +
                            '  console.log(\'listening on port 8081\');\n' +
                            '});\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. Creare Dockerfile</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch Dockerfile'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM node:alpine\n' +
                            '\n' +
                            'WORKDIR \'/app\'\n' +
                            '\n' +
                            'COPY package.json .\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            '\n' +
                            'CMD ["npm","start"]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.4. Creare imagine</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t iulianbuzdugan/visits .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run iulianbuzdugan/visits'}
                    </SyntaxHighlighter>

                    se va afisa urmatoare eroare, pentru ca nu e pornit server-ul redis:
                    <SyntaxHighlighter>
                        {'\n' +
                            '> start\n' +
                            '> node index.js\n' +
                            '\n' +
                            'listening on port 8081\n' +
                            'node:events:491\n' +
                            '      throw er; // Unhandled \'error\' event\n' +
                            '      ^\n' +
                            '\n' +
                            'Error: connect ECONNREFUSED 127.0.0.1:6379\n' +
                            '    at TCPConnectWrap.afterConnect [as oncomplete] (node:net:1300:16)\n' +
                            'Emitted \'error\' event on RedisClient instance at:\n' +
                            '    at RedisClient.on_error (/app/node_modules/redis/index.js:406:14)\n' +
                            '    at Socket.<anonymous> (/app/node_modules/redis/index.js:279:14)\n' +
                            '    at Socket.emit (node:events:513:28)\n' +
                            '    at emitErrorNT (node:internal/streams/destroy:151:8)\n' +
                            '    at emitErrorCloseNT (node:internal/streams/destroy:116:3)\n' +
                            '    at process.processTicksAndRejections (node:internal/process/task_queues:82:21) {\n' +
                            '  errno: -111,\n' +
                            '  code: \'ECONNREFUSED\',\n' +
                            '  syscall: \'connect\',\n' +
                            '  address: \'127.0.0.1\',\n' +
                            '  port: 6379\n' +
                            '}'}
                    </SyntaxHighlighter>
                    daca cream si pornim un container cu redis:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run redis'}
                    </SyntaxHighlighter>
                    vom obtine aceeasi eroare. Pentru ca cele 2 containere sunt izolate.
                    Pentru a face ca cele 2 containere sa comunice intre ele trebuie, avem 2 optiuni:
                    <ul>
                        <li>sa se foloseasca Docker CLI cu comenzi de retea (networking): se foloseste rar aceasta abordare</li>
                        <li>sa se foloseasca Docker Compose
                        <br/>
                            Docker Compose e un CLI, folosit pentru a porni mai multe containere Docker in acelasi timp.
                        </li>
                    </ul>

                    <hr/>
                    <b>1.5. Creare docker-compose.yml</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'touch docker-compose.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano docker-compose.yml'}
                    </SyntaxHighlighter>

                    cu continutul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  redis-server:\n' +
                            '    image: \'redis\'\n' +
                            '  node-app:\n' +
                            '    build: .\n' +
                            '    ports:\n' +
                            '      - \'4001:8081\'\n'}
                    </SyntaxHighlighter>

                    In plus, vom modifica un pic <i>index.js</i> adaugand host-ul si port-ul pentru conectarea la <i>redis</i>:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'const express = require(\'express\');\n' +
                            'const redis = require(\'redis\');\n' +
                            '\n' +
                            'const app = express();\n' +
                            'const client = redis.createClient({\n' +
                            '  host: \'redis-server\',\n' +
                            '  port: 6379,\n' +
                            '});\n' +
                            'client.set(\'visits\', 0);\n' +
                            '\n' +
                            'app.get(\'/\', (req, res) => {\n' +
                            '  client.get(\'visits\', (err, visits) => {\n' +
                            '    res.send(\'Number of visits \' + visits);\n' +
                            '    client.set(\'visits\', parseInt(visits) + 1);\n' +
                            '  });\n' +
                            '});\n' +
                            '\n' +
                            'app.listen(8081, () => {\n' +
                            '  console.log(\'listening on port 8081\');\n' +
                            '});\n'}
                    </SyntaxHighlighter>

                    in mod normal la crearea clientului de redis, <i>host</i> este ceva de genul: http://my-redis.ro.
                    <br/>
                    Scriind, <i>redis-server</i>, redis are incredere in ce ii scrii si prespune ca <i>redis-server</i>  este un URL valid.
                    Docker, va rezolva aceasta problema pe baza a ce s-a definit in <i>docker-compose.yml</i>,
                    legand acest URL <i>redis-server</i> de numele serviciului <i>redis-server</i>.

                    <hr/>
                    <b>1.6. docker compose up</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'[+] Building 100.2s (10/10) FINISHED\n' +
                            ' => [internal] load build definition from Dockerfile                                                                                                                                                                                   10.4s\n' +
                            ' => => transferring dockerfile: 137B                                                                                                                                                                                                    0.0s\n' +
                            ' => [internal] load .dockerignore                                                                                                                                                                                                      12.2s\n' +
                            ' => => transferring context: 2B                                                                                                                                                                                                         0.0s\n' +
                            ' => [internal] load metadata for docker.io/library/node:alpine                                                                                                                                                                          0.0s\n' +
                            ' => [1/5] FROM docker.io/library/node:alpine                                                                                                                                                                                           29.4s\n' +
                            ' => [internal] load build context                                                                                                                                                                                                      14.6s\n' +
                            ' => => transferring context: 938B                                                                                                                                                                                                       0.0s\n' +
                            ' => [2/5] WORKDIR /app                                                                                                                                                                                                                  6.5s\n' +
                            ' => [3/5] COPY package.json .                                                                                                                                                                                                           7.3s\n' +
                            ' => [4/5] RUN npm install                                                                                                                                                                                                              18.9s\n' +
                            ' => [5/5] COPY . .                                                                                                                                                                                                                      7.8s\n' +
                            ' => exporting to image                                                                                                                                                                                                                 13.1s\n' +
                            ' => => exporting layers                                                                                                                                                                                                                11.1s\n' +
                            ' => => writing image sha256:486ad694599e39124cee6a880fb235e9397c4ed4b10e0bffe66ab4ba6439bf56                                                                                                                                            0.5s\n' +
                            ' => => naming to docker.io/library/visits-node-app                                                                                                                                                                                      0.4s\n' +
                            '\n' +
                            'Use \'docker scan\' to run Snyk tests against images to find vulnerabilities and learn how to fix them\n' +
                            '[+] Running 3/3\n' +
                            ' ⠿ Network visits_default           Created                                                                                                                                                                                             2.7s\n' +
                            ' ⠿ Container visits-redis-server-1  Created                                                                                                                                                                                             9.1s\n' +
                            ' ⠿ Container visits-node-app-1      Created                                                                                                                                                                                             9.8s\n' +
                            'Attaching to visits-node-app-1, visits-redis-server-1\n' +
                            'visits-redis-server-1  | 1:C 08 Oct 2022 10:58:52.050 # oO0OoO0OoO0Oo Redis is starting oO0OoO0OoO0Oo\n' +
                            'visits-redis-server-1  | 1:C 08 Oct 2022 10:58:52.050 # Redis version=7.0.5, bits=64, commit=00000000, modified=0, pid=1, just started\n' +
                            'visits-redis-server-1  | 1:C 08 Oct 2022 10:58:52.050 # Warning: no config file specified, using the default config. In order to specify a config file use redis-server /path/to/redis.conf\n' +
                            'visits-redis-server-1  | 1:M 08 Oct 2022 10:58:52.051 * monotonic clock: POSIX clock_gettime\n' +
                            'visits-redis-server-1  | 1:M 08 Oct 2022 10:58:52.053 * Running mode=standalone, port=6379.\n' +
                            'visits-redis-server-1  | 1:M 08 Oct 2022 10:58:52.053 # Server initialized\n' +
                            'visits-redis-server-1  | 1:M 08 Oct 2022 10:58:52.053 # WARNING overcommit_memory is set to 0! Background save may fail under low memory condition. To fix this issue add \'vm.overcommit_memory = 1\' to /etc/sysctl.conf and then reboot or run the command \'sysctl vm.overcommit_memory=1\' for this to take effect.\n' +
                            'visits-redis-server-1  | 1:M 08 Oct 2022 10:58:52.054 * Ready to accept connections\n' +
                            'visits-node-app-1      |\n' +
                            'visits-node-app-1      | > start\n' +
                            'visits-node-app-1      | > node index.js\n' +
                            'visits-node-app-1      |\n' +
                            'visits-node-app-1      | listening on port 8081'}
                    </SyntaxHighlighter>

                    Ce e de remarcat este: <b> Network visits_default</b> (adica, se creaza o retea pentru cele 2 servici).

                    <br/>
                    <br/>
                    Testare: http://192.168.1.8:4001/

                    <hr/>

                    <b>1.6. docker compose down</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose down'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'[+] Running 3/3\n' +
                            ' ⠿ Container visits-node-app-1      Removed                                                                         10.6s\n' +
                            ' ⠿ Container visits-redis-server-1  Removed                                                                         12.3s\n' +
                            ' ⠿ Network visits_default           Removed                                                                          4.0s'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex2DockerContent;