import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"music-root", url:"#", title:"Albume", subtitle:""},
        {id:"music-vinyl", url:"/music/vinyl", title:"Vinyl", subtitle:"", parent:"music-root"}
    ]

    static indexUrl = "/music/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Tips & Tricks
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;