import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class KillDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-kill", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker kill / docker container kill</b>

                    <br/>
                    <br/>

                    Opreste un container care ruleaza (trimite un semnal de kill (SIGKILL) si opreste containerul imediat)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker kill [container]'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container kill [container]'}
                    </SyntaxHighlighter>

                    <hr/>

                    O optiune este <b>--signal</b> care schimba semnaul trimis pentru oprire container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker kill --signal [signal] [container]'}
                    </SyntaxHighlighter>
                    unde signal:
                    <ul>
                        <li>
                            SIGQUIT
                        </li>
                        <li>
                            SIGHUP
                        </li>
                    </ul>

                    
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KillDockerContent;