import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ZebraVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-focus", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Tipuri de focalizare:
                    <ul>
                        <li><b>deep focus</b>
                            <br/>

                            <ul>
                                <li>
                                   permite directorului de imagine să mențină totul în perspectivă, fără a favoriza primul plan, mijlocul sau fundalul; totul este luminat și vizibil.
                                </li>
                                <li>
                                    se poate porni de la f/8
                                </li>
                            </ul>

                        </li>
                        <li><b>shallow focus</b> / focus superficial:
                            <br/>

                            focalizarea superficială se realizează folosind o <b>diafragma deschisa</b> (f/1.4);<br/>
                            astfel, se creaza un camp de adancime sau profunzime a campului (deep of field) mic cu focalizare clara;<br/>
                            deci, subiectul este foarte clar, în timp ce restul este foarte neclară;

                            <br/>
                            <br/>
                            <i>Studiu</i>: filmele lui <i>Denis Villeneuve</i><br/>
                            În general, cu o adâncime de câmp mica, regizorul directioneaza atenția, ne spune unde sa ne uitam.<br/>
                            Dar, modul în care Villeneuve utilizează focalizarea superficială este destul de unic:
                            <ul>
                                <li>se concentrează adesea pe ceva semnificativ (sau chiar nesemnificativ) în prim-plan, <i>în timp ce ceva de importanță egală se desfășoară pe fundalul neclar</i></li>
                                <li>creează o deconectare incomodă - nu suntem siguri unde ar trebui să ne poziționăm ochii (iar această incertitudine este un element crucial pentru a crea tonul neliniștit și rece)</li>
                            </ul>

                        </li>
                        <li>
                            <b>rack focus</b>
                            <br/>
                            <ul>
                                <li>schimbarea focalizării în timpul unei filmarii continue</li>
                                <li>pe un platou profesionist există o slujbă numită <i>Focus Puller</i>; acesta lucreaza în tandem cu operatorul camerei pentru a regla inelul de focalizare în timpul filmarii;
                                    <i>operatorul</i> trebuie să acorde atenție încadrării și mișcării camerei, lăsând focalizarea efectivă pentru <i>focus puller</i>
                                </li>
                                <li>e nevoie de un obiectiv de cameră cu un inel de focalizare manuală:
                                    <ul>
                                        <li>la producțiile cu buget redus, inelul de focalizare se regleaza direct cu mâna</li>
                                        <li>la productiile cu buget mare, se foloseste un dispozitiv mecanic numit <i>follow focus</i> </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <b>Autofocus</b>-ul, sau focusul automat, este folosit mai rar in cinematografie.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.youtube.com/watch?v=lb2T3U36670&ab_channel=JacobT.Swinney"}>Denis Villeneuve: Shallow Focus</a><br/>
                           </div>
                       </li>
                        <li>
                            <div>
                                <a href={"https://www.studiobinder.com/blog/deep-focus-shot-camera-movement-angle/"}>Deep Focus Shot: Creative Examples of Camera Movements & Angles</a>
                            </div>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ZebraVideografieContent;