import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class UpdateAppReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-update-app", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Actualizare aplicatie React</b>
                    <br/>
                    <br/>

                    Ne asiguram ca sunt instalate toate dependintele:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install'}
                    </SyntaxHighlighter>

                    Actualizare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm update'}
                    </SyntaxHighlighter>


                    Vizualizare probleme:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm audit'}
                    </SyntaxHighlighter>

                    Fixare probleme:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm audit fix'}
                    </SyntaxHighlighter>

                    Fortare fixare probleme:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm audit fix --force'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UpdateAppReactContent;