import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class JndiTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-jndi", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare JNDI in context.xml</b>
                    <br/>
                    <br/>

                    Se editeaza fisierul <b>[tomcat]\conf\context.xml</b>, in sectiunea <b>Context</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Resource \n' +
                            '\t\tname="jdbc/UsersDB"\n' +
                            '\t\tauth="Container"\n' +
                            '\t\ttype="javax.sql.DataSource"\n' +
                            '\t\tmaxActive="100"\n' +
                            '\t\tmaxIdle="30"\n' +
                            '\t\tmaxWait="10000"\n' +
                            '\t\tdriverClassName="com.mysql.cj.jdbc.Driver"\n' +
                            '\t\turl=\"jdb:mysql://localhost:3306/usersDB\"\n'+
                            '\t\tusename="root"\n' +
                            '\t\tpassword=""\n' +
                            '\t/>'}
                    </SyntaxHighlighter>

                    Atributul <b>name</b> specifica cheia JNDI (adica 'jdbc/UsersDB'), iar restul atributelor sunt <i>proprietati</i> disponibile in contextul aplicatiei.
                    <br/>
                    <br/>
                    Prin intermediul atributului <b>maxActive</b> se poate specifica numarul maxim de conexiuni pe care le poate avea un user in <b>connection pool</b>.
                    <br/>
                    <br/>
                    Prin intermediul atributului <b>maxIdle</b> specifica numarul de conexiuni care care vor fi in memorie (cand exista inactivitate), dar care poate creste de la 30 la 100.
                    Deci cand porneste serverul vor exista cel putin 30 de conexiuni.
                    <br/>
                    <br/>
                    Prin intermediul atribului <b>maxWait</b> se specifica numarul de milisecunde pe care le astepata server-ul sa primeasca un raspuns.

                    <hr/>
                    <b>2. Referire JNDI in web.xml-ul aplicatiei</b>

                    <br/>
                    <br/>
                    Fisierul <b>web.xml</b> al aplicatiei din <b>WEB-INF</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio} >
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<web-app xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://java.sun.com/xml/ns/javaee" xsi:schemaLocation="http://java.sun.com/xml/ns/javaee http://java.sun.com/xml/ns/javaee/web-app_3_0.xsd" id="WebApp_ID" version="3.0">\n' +
                            '  <display-name>JNDIDemo</display-name>\n' +
                            '  <resource-ref>\n' +
                            '    <description>DB Connection</description>\n' +
                            '    <res-ref-name>jdbc/UsersDB</res-ref-name>\n' +
                            '    <res-type>javax.sql.DataSource</res-type>\n' +
                            '    <res-auth>Container</res-auth>\n' +
                            '  </resource-ref>\n' +
                            '</web-app>'}
                    </SyntaxHighlighter>

                    Din Java:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Context initContext = new InitialContext();\n' +
                            'Context envContext = (Context) initContext.lookup("java:comp/env");\n' +
                            'DataSource ds = (DataSource) envContext.lookup("jdbc/UsersDB");'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    In <i>lib</i>-ul aplicatie, trebuie sa fie si driverul de conectare la baza de date.
                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JndiTomcatContent;