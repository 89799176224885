import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class TagDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tag", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker tag</b>
                    <br/>
                    <br/>

                    Pentru a putea pune alt nume/tag unei imagini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag [nume-imagine-veche] [nume-imagine-noua]'}
                    </SyntaxHighlighter>
                    In acest caz, se mai creeaza o imagine cu numele si un tag-ul specificat, <b>dar</b> ramane si vechea imagine! De fapt se face un alias!

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag [id-imagine] [nume-imagine-noua]'}
                    </SyntaxHighlighter>
                    In acest caz imaginea va primi numele si un tag-ul specificat.

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag nginx:latest iulianbuzdugan/kj-nginx:1.0'}
                    </SyntaxHighlighter>
                    va exista si imaginea: <i>nginx:latest</i> ,dar si <i>iulianbuzdugan/kj-nginx:1.0</i>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag 4r3432twt5 iulianbuzdugan/kj-nginx:1.0'}
                    </SyntaxHighlighter>
                    va exista imaginea <i>iulianbuzdugan/kj-nginx:1.0</i> cu repository = iulianbuzdugan/kj-nginx:1.0, tag=1.0, image_id=4r3432twt5


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TagDockerContent;