import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class TestSpeedNetLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-test-speed-net", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a testa viteza retelei se poate utiliza <b>speedtest</b>; instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install speedtest-cli'}
                    </SyntaxHighlighter>

                    Test:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'speedtest'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Retrieving speedtest.net configuration...\n' +
                            'Testing from Digi Romania (XX.XXX.XXX.XXX)...\n' +
                            'Retrieving speedtest.net server list...\n' +
                            'Selecting best server based on ping...\n' +
                            'Hosted by NOVA TELEKOM (Silivri) [410.86 km]: 72.188 ms\n' +
                            'Testing download speed................................................................................\n' +
                            'Download: 53.10 Mbit/s\n' +
                            'Testing upload speed......................................................................................................\n' +
                            'Upload: 56.89 Mbit/s'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TestSpeedNetLinuxContent;