import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class SignalsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-signals", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Introducere in Signals</b>
                    <br/>
                    <br/>

                    Signals au fost introduse in Angular 16.
                    <br/>
                    Sunt o alternativa la <b>classic change deteaction (zone-based change detection)</b>,
                    care se bazeaza o o extra librarie <i>zone.js</i>.
                    <br/>
                    Signals:
                    <ul>
                        <li>nu mai foloseste o librarie externa (zone.js)</li>
                        <li>dimenziunea bundle este mai mica (nu mai foloseste o librarie externa)</li>
                        <li>permite sa nu se mai urmareasca fiecare schimbare in intreaga aplicatie, ceea ce duce la o performata mai buna</li>
                        <li>nu exista detectie automata a sschimbarii; in schimb i se spune Angularului cand datele au fost schimbate</li>
                        <li>Angular actualizeaza doar acele parti in UI unde datele (signal) sunt folosite</li>
                    </ul>

                    Un <b>signal</b> / semnal este un obiect care are o valoare și poate fi observat pentru modificări.
                    <br/>
                    Este similar cu o stare, dar nu este legat de o componentă.
                    <br/>
                    Poate fi folosit pentru a partaja date între componente.
                    <br/>
                    Actualizează componentele atunci când semnalul se schimbă și actualizează interfața de utilizare fără a reda întreaga componentă.


                    <hr/>
                    <b>2. Creare signal</b>
                    <br/>
                    <br/>


                    Un signal se creaza folosind functia <b>signal</b> care poate lua o valoare implicita ca parametru si returneza un obiect de tip <b>Proxy</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'name = signal(\'Kj\');'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'counter = signal(0);'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'actions = signal<string[]>([]);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Actualizare valoare variabila de tip signal</b>
                    <br/>
                    <br/>

                    Putem seta valoarea semnalului folosind:
                    <ul>
                        <li>metoda <b>set</b>:
                            <br/>
                            this.counter.set(1)
                            <br/>
                            sau:
                            <br/>
                            this.counter.set(this.counter()+1)
                        </li>
                        <li>metoda <b>update</b>:
                            <br/>
                            this.counter.update(oldValue {"=>"} oldValue + 1)
                            <br/>
                            sau:
                            this.actions.mutate(oldValue {"=>"} [...oldValue, "ADD"]);
                        </li>
                        <li>metoda <b>mulate</b>:
                            this.actions.mutate(oldValue {"=>"} oldValue.push("ADD"));
                        </li>
                    </ul>


                    <hr/>
                    <b>4. Accesare valoare variabila de tip signal</b>
                    <br/>
                    <br/>

                    Variabila de counter de tip Signal este o functie. Pentru a <i>accesa</i> valoare se apeleaza ca fi cu ar fi o functie: <i>counter()</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{{counter()}}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. computed</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'doubleCounter = computed( () => this.counter() * 2);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. effect</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'effect( () => console.log(this.counter()) ); '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Anexa: Astia tineri (Angular) descopera apa calda</b>
                    <br/>
                    <br/>
                    Signals-urile isi au radacinile de pe la sfarstiul anilor 1960.
                    <br/>
                    Dar, la fel ca multe alte lucruri din viață, momentul este important, nu doar inovația.

                    <br/>
                    Mai multe: <a target={"_blank"} href={"https://dev.to/this-is-learning/the-evolution-of-signals-in-javascript-8ob"}>aici</a>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SignalsAngularContent;