import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ServiceAccountK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-service-account", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Service account</b>
                    <br/>
                    <br/>

                    Creare service account:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create serviceaccount dashboard-sa'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'serviceaccount/dashboard-sa created'}
                    </SyntaxHighlighter>

                    Afisare lista de service account:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get serviceaccount'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get sa'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'NAME           SECRETS   AGE\n' +
                            'dashboard-sa   0         23s\n' +
                            'default        0         83d\n'}
                    </SyntaxHighlighter>

                    Afisare detalii despre un service account
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe serviceaccount dashboard-sa'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'Name:                dashboard-sa\n' +
                            'Namespace:           default\n' +
                            'Labels:              <none>\n' +
                            'Annotations:         <none>\n' +
                            'Image pull secrets:  <none>\n' +
                            'Mountable secrets:   <none>\n' +
                            'Tokens:              <none>\n' +
                            'Events:              <none>'}
                    </SyntaxHighlighter>
                    Incepand cu versiunea v1.24, K8s nu va mai genera automat secrete pentru ServiceAccounts. Pentru a creea un token (token-ul este temporal):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create token dashboard-sa'}
                    </SyntaxHighlighter>
                    Inainte de versiunea v1.24, K8s genera automat un secret pentru ServiceAccounts, cu un token care nu expira.
                    Pentru a face in continuare acest lucru, se creeaza:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  name: dashboard-sa-secret\n' +
                            '  annotations:\n' +
                            '    kubernetes.io/service-account.name: dashboard-sa\n' +
                            'type: kubernetes.io/service-account-token'}
                    </SyntaxHighlighter>

                    <hr/>

                    Versiunile Kubernetes înainte de v1.22 au creat automat acreditări pe termen lung pentru accesarea API-ului Kubernetes.
                    <br/>
                    Acest mecanism mai vechi s-a bazat pe crearea de token-uri secrete care puteau fi apoi montate în podurile care rulează.
                    <br/>
                    <br/>
                    În versiunile mai recente, inclusiv Kubernetes v1.26, acreditările API sunt obținute direct utilizând API-ul <b>TokenRequest</b>
                    și sunt montate în Pod-uri folosind un volum proiectat.
                    Tokenurile obținute folosind această metodă au durate de viață limitate și sunt automat invalidate atunci când Pod-ul în care sunt montate este șters.

                    <hr/>

                    In mod implicit, K8s in mod automat monteaza la pod service account-ul cu numele <i>default</i>.
                    <br/>
                    Daca nu se dorecte acest lucru se poate specifica prin (sectiunea <i>spec</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'automountServiceAccountToken: false'}
                    </SyntaxHighlighter>

                    Pentru a specifica in pod un service account (sectiunea <i>spec</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'serviceAccountName: dashboard-sa'}
                    </SyntaxHighlighter>

                    <hr/>

                    Utilizare ServiceAccountTokern intr-un pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - image: nginx\n' +
                            '    name: nginx\n' +
                            '    volumeMounts:\n' +
                            '    - mountPath: /var/run/secrets/tokens\n' +
                            '      name: vault-token\n' +
                            '  serviceAccountName: build-robot\n' +
                            '  volumes:\n' +
                            '  - name: vault-token\n' +
                            '    projected:\n' +
                            '      sources:\n' +
                            '      - serviceAccountToken:\n' +
                            '          path: vault-token\n' +
                            '          expirationSeconds: 7200\n' +
                            '          audience: vault'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceAccountK8sContent;