import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";

class TypesTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Tipuri (adnotari de tip)</b>
                    <br/>
                    <br/>

                    Tipuri:
                    <ul>
                        <li>number</li>
                        <li>string</li>
                        <li>boolean</li>
                        <li>null</li>
                        <li>undefined</li>
                    </ul>
                    Observatii:
                    <ul>
                        <li>
                            Tipul unei variabile nu se poate modifica.
                        </li>
                        <li>
                            daca nu specificam un tip la declarare, acesta poate fi inferat din valoarea atribuita
                        </li>
                        <li>
                            daca nu specificam un tip la declarare, si nu aribuim nici o valoarea, atunci tipul va fi <b>any</b>
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'let age'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se poate specifica ca o functie nu returneaza nimic cu ajutorul <b>void</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'function beep():void{}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            parametri optionali:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'function add(a: number, b: number, c?: number) {\n' +
                                    '  return a + b + (c || 0);\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            parametri cu valori implice:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'function pow(value: number, exponent: number = 10) {\n' +
                                    '  return value ** exponent;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            parametri Rest:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'function add(a: number, b: number, ...rest: number[]) {\n' +
                                    '  return a + b + rest.reduce((p, c) => p + c, 0);\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            parametri cu nume:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'function divide({ dividend, divisor }: { dividend: number, divisor: number }) {\n' +
                                    '  return dividend / divisor;\n' +
                                    '}\n' +
                                    '\n' +
                                    'console.log(divide({dividend: 10, divisor: 2}));'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            Type Alias
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'type Negate = (value: number) => number;\n' +
                                    '\n' +
                                    '// in this function, the parameter `value` automatically gets assigned the type `number` from the type `Negate`\n' +
                                    'const negateFunction: Negate = (value) => value * -1;'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>Union types</b>
                    <br/>
                    <br/>

                    Se poate specifica ca o variabila poate avea mai multe tipuri, folosind <b>|</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'let name = string | null = null'}
                    </SyntaxHighlighter>

                    <hr/>

                    Exemple:

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const name: string = "kj";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'let age: number = 17;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const names: string[] = ["kj-1","kj-2"];'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'let lista1: (string|number)[] = ["kj-1", 4]; // lista care poate contine si numere si string-uri\n' +
                            '\n' +
                            'let lista2: string[] | number[] = [2, 4]; // lista care poate contine fie numere, fie string-uri'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const add = (a:number,b:string)=>{\n' +
                            '    return a+b;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const add = (a:number,b:string):string=>{\n' +
                            '    return a+b;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const add = (a:number,b:string):string | number=>{\n' +
                            '    return a+b;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Casting</b>
                    <br/>
                    <br/>
                    Exista mai multe metode de casting:
                    <ul>
                        <li>
                            as (nu schimbă tipul datelor din variabilă):
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'let x: unknown = \'hello\';\n' +
                                    'console.log((x as string).length);'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            casting cu mai-mare, mai-mic (nu va functiona in React):
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'let x: unknown = \'hello\';\n' +
                                    'console.log((<string>x).length);'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TypesTypescriptContent;