import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DecoratorsIIIPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-decorators-iii", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Decoratorii de clase sunt decaratori pe baza de functii.

                    <br/>

                    Decoratorii de clasă sunt modalități de a gestiona clasele sau de a încheia apelurile de metode într-o logică suplimentară.

                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def decorator(C):\n' +
                        '    pass    \n' +
                        '\n' +
                        '@decorator\n' +
                        'class ClasaMea:\n' +
                        '    pass\n' +
                        '\n' +
                        '# folosire\n' +
                        'obj = ClasaMea()\n' +
                        '\n' +
                        '# echivalent cu:\n' +
                        'ClasaMea = decorator(ClasaMea())\n' +
                        'obj = ClasaMea()'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def gainar(c):\n' +
                        '    c.functie = c.__getattribute__ # metoda speciala de accesare atribut, ex: gaina.oua\n' +
                        '\n' +
                        '    def noua_functie(self, name):\n' +
                        '        if name == \'oua\':\n' +
                        '            print(\'Gainarul vede ouale!\')\n' +
                        '        return c.functie(self, name)\n' +
                        '\n' +
                        '    c.__getattribute__ = noua_functie\n' +
                        '    return c\n' +
                        '\n' +
                        '@gainar\n' +
                        'class Gaina:\n' +
                        '    def __init__(self, oua):\n' +
                        '        self.oua = oua\n' +
                        '\n' +
                        'gaina = Gaina(5)\n' +
                        'print(\'Numar oua=\', gaina.oua) # Gainarul vede ouale!\n' +
                        '                               # Numar oua= 5\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DecoratorsIIIPythonContent;