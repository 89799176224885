import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class TabLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-tab", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Tab</b>
                    <br/>
                    <br/>

                    In pachetul <i>.portlet.displaycontext</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.portal.kernel.display.context.helper.BaseRequestHelper;\n' +
                            'import com.liferay.portal.kernel.util.JavaConstants;\n' +
                            '\n' +
                            'import javax.portlet.PortletPreferences;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.servlet.http.HttpServletRequest;\n' +
                            '\n' +
                            'public class RequestHelper extends BaseRequestHelper{\n' +
                            '\n' +
                            '    public TaxesAndFeesRequestHelper(HttpServletRequest httpServletRequest) {\n' +
                            '        super(httpServletRequest);\n' +
                            '        \n' +
                            '        _renderRequest = (RenderRequest)httpServletRequest.getAttribute(JavaConstants.JAVAX_PORTLET_REQUEST);\n' +
                            '        _portletPreferences = _renderRequest.getPreferences();\n' +
                            '    }\n' +
                            '    \n' +
                            '    public PortletPreferences getPortletPreferences() {\n' +
                            '        return _portletPreferences;\n' +
                            '    }\n' +
                            '    \n' +
                            '    public RenderRequest getRenderRequest() {\n' +
                            '        return _renderRequest;\n' +
                            '    }\n' +
                            '    \n' +
                            '    private final PortletPreferences _portletPreferences;\n' +
                            '    private final RenderRequest _renderRequest;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.frontend.taglib.clay.servlet.taglib.util.NavigationItem;\n' +
                            'import com.liferay.frontend.taglib.clay.servlet.taglib.util.NavigationItemList;\n' +
                            'import com.liferay.petra.string.StringPool;\n' +
                            'import com.liferay.portal.kernel.language.LanguageUtil;\n' +
                            'import com.liferay.portal.kernel.portlet.url.builder.PortletURLBuilder;\n' +
                            'import com.liferay.portal.kernel.theme.ThemeDisplay;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import com.liferay.portal.kernel.util.PortalUtil;\n' +
                            'import com.liferay.portal.kernel.util.Validator;\n' +
                            '\n' +
                            'import javax.portlet.PortletURL;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.portlet.RenderResponse;\n' +
                            'import javax.servlet.http.HttpServletRequest;\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            'public class ToolbarDisplayContext {\n' +
                            '\n' +
                            '    public DisplayContext(RenderRequest renderRequest, RenderResponse renderResponse) {\n' +
                            '       _renderRequest = renderRequest;\n' +
                            '       _renderResponse = renderResponse;\n' +
                            '       requestHelper = new RequestHelper(PortalUtil.getHttpServletRequest(_renderRequest));\n' +
                            '    }\n' +
                            '\n' +
                            '    static public List<NavigationItem> getNavigationBarItems(HttpServletRequest request, PortletURL portletURL,\n' +
                            '          String currentView, ThemeDisplay themeDisplay) {\n' +
                            '       return new NavigationItemList() {\n' +
                            '          {\n' +
                            '             add(navigationItem -> {\n' +
                            '                portletURL.setParameter("view", "tab1");\n' +
                            '                navigationItem.setActive(currentView.equals("tab1"));\n' +
                            '                navigationItem.setHref(portletURL.toString());\n' +
                            '                navigationItem.setLabel(LanguageUtil.get(request, "nav.label.tab1"));\n' +
                            '             });\n' +
                            '\n' +
                            '             add(navigationItem -> {\n' +
                            '                portletURL.setParameter("view", "tab2");\n' +
                            '                navigationItem.setActive(currentView.equals("tab2"));\n' +
                            '                navigationItem.setHref("");\n' +
                            '                navigationItem.setHref(portletURL.toString());\n' +
                            '                navigationItem.setLabel(LanguageUtil.get(request, "nav.label.tab2"));\n' +
                            '\n' +
                            '             });\n' +
                            '\n' +
                            '             add(navigationItem -> {\n' +
                            '                portletURL.setParameter("view", "tab3");\n' +
                            '                navigationItem.setActive(currentView.equals("tab3"));\n' +
                            '                navigationItem.setHref(portletURL.toString());\n' +
                            '                navigationItem.setLabel(LanguageUtil.get(request, "nav.label.tab3"));\n' +
                            '             });\n' +
                            '\n' +
                            '             add(navigationItem -> {\n' +
                            '                portletURL.setParameter("view", "tab4");\n' +
                            '                navigationItem.setActive(currentView.equals("tab4"));\n' +
                            '                navigationItem.setHref("");\n' +
                            '                navigationItem.setHref(portletURL.toString());\n' +
                            '                navigationItem.setLabel(LanguageUtil.get(request, "nav.label.tab4"));\n' +
                            '             });\n' +
                            '             \n' +
                            '          }\n' +
                            '       };\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getClearResultsURL(String folderName) {\n' +
                            '       return PortletURLBuilder.create(getPortletURL(folderName)).setKeywords(StringPool.BLANK).buildString();\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getKeywords() {\n' +
                            '       if (Validator.isNull(_keywords)) {\n' +
                            '          _keywords = ParamUtil.getString(taxesAndFeesRequestHelper.getRequest(), "keywords");\n' +
                            '       }\n' +
                            '\n' +
                            '       return _keywords;\n' +
                            '    }\n' +
                            '\n' +
                            '    public PortletURL getPortletURL(String folderName) {\n' +
                            '       PortletURL portletURL = PortletURLBuilder.createRenderURL(_renderResponse).setMVCPath("/view.jsp")\n' +
                            '             .setKeywords(() -> {\n' +
                            '                if (Validator.isNotNull(getKeywords())) {\n' +
                            '                   return getKeywords();\n' +
                            '                }\n' +
                            '\n' +
                            '                return null;\n' +
                            '             }).setParameter("view", folderName).buildPortletURL();\n' +
                            '\n' +
                            '       return portletURL;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getSearchActionURL(String folderName) {\n' +
                            '       PortletURL searchActionURL = getPortletURL(folderName);\n' +
                            '\n' +
                            '       return searchActionURL.toString();\n' +
                            '    }\n' +
                            '\n' +
                            '    private String _keywords;\n' +
                            '    private final RenderRequest _renderRequest;\n' +
                            '    private final RenderResponse _renderResponse;\n' +
                            '    private RequestHelper requestHelper;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    // view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<div>\n' +
                            '    Exemplu tab:\n' +
                            '\n' +
                            '    <%@ include file="nav.jsp"%>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    // nav.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'// nav.jsp\n' +
                            '<%@ include file="init.jsp" %>\n' +
                            '<%@page import="javax.portlet.PortletURL"%>\n' +
                            '<%@page import="com.liferay.portal.kernel.portlet.PortletURLFactoryUtil"%>\n' +
                            '<%@page import="javax.portlet.PortletRequest"%>\n' +
                            '<%@page import="com.liferay.portal.kernel.util.ParamUtil"%>\n' +
                            '<%@ page import="ToolbarDisplayContext" %>\n' +
                            '\n' +
                            '<%\n' +
                            '    String view = ParamUtil.getString(request, "view", "prices");\n' +
                            '    PortletRequest portletRequest = (PortletRequest) request.getAttribute("javax.portlet.request");\n' +
                            '    PortletURL portletURL =  PortletURLFactoryUtil.create(request, themeDisplay.getPortletDisplay().getId(), themeDisplay.getPlid(), PortletRequest.RENDER_PHASE);\n' +
                            '%>\n' +
                            '\n' +
                            '<div class="module-nav">\n' +
                            '       \n' +
                            '    <clay:navigation-bar \n' +
                            '        navigationItems="<%=sToolbarDisplayContext.getNavigationBarItems(request, portletURL, view, themeDisplay)%>"\n' +
                            '        inverted="<%= false %>"\n' +
                            '    />\n' +
                            '</div>\n' +
                            '\n' +
                            '\n' +
                            '<div class="container-fluid container-fluid-max-xl selection">\n' +
                            '\n' +
                            '    <c:choose> \n' +
                            '       <c:when test = \'<%= view.equals("tab1") %>\'>\n' +
                            '          <jsp:include page="/tab1/view.jsp" flush="false" />\n' +
                            '       </c:when>\n' +
                            '       <c:when test = \'<%= view.equals("tab2") %>\'>\n' +
                            '          <jsp:include page="/tab2/view.jsp" flush="false" />\n' +
                            '       </c:when>\n' +
                            '       <c:when test = \'<%= view.equals("tab3") %>\'>\n' +
                            '          <jsp:include page="/tab3/view.jsp" flush="false" />\n' +
                            '       </c:when>\n' +
                            '       <c:when test = \'<%= view.equals("tab4") %>\'>\n' +
                            '          <jsp:include page="/tab4/view.jsp" flush="false" />\n' +
                            '       </c:when>         \n' +
                            '    </c:choose> \n' +
                            '\n' +
                            '</div>\n'}
                    </SyntaxHighlighter>

                    // tab1/view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'// tab1/view.jsp\n' +
                            '<%@page import="com.liferay.portal.kernel.dao.search.SearchContainer"%>\n' +
                            '\n' +
                            '<%@page import="com.liferay.portal.kernel.language.LanguageUtil"%>\n' +
                            '\n' +
                            '<%@page import="com.liferay.portal.kernel.util.GetterUtil"%>\n' +
                            '<%@page import="ro.simavi.primaria.galati.taxes.portlet.displaycontext.TaxesAndFeesToolbarDisplayContext"%>\n' +
                            '<%@page import="ro.Entitate"%>\n' +
                            '<%@ page import="javax.portlet.PortletURL" %>\n' +
                            '<%@ page import="java.util.List" %>\n' +
                            '<%@ include file="../init.jsp" %>\n' +
                            '\n' +
                            '<%\n' +
                            '    PortletURL portletURL = renderResponse.createRenderURL();\n' +
                            '    PortletURL iteratorURL = renderResponse.createActionURL();\n' +
                            '\n' +
                            '    iteratorURL.getRenderParameters().setValue("jspPage", "/tab1/view.jsp");\n' +
                            '    iteratorURL.getRenderParameters().setValue("view", "tab1");\n' +
                            '\n' +
                            '    ToolbarDisplayContext toolbarDisplayContext = new ToolbarDisplayContext(renderRequest, renderResponse);\n' +
                            '    \n' +
                            '    SearchContainer<Enitate> mySearchContainer = new SearchContainer<Entitate>(renderRequest, null, null, SearchContainer.DEFAULT_CUR_PARAM, SearchContainer.DEFAULT_DELTA, iteratorURL, null, "");\n' +
                            '\n' +
                            '    mySearchContainer.setEmptyResultsMessage(LanguageUtil.get(locale, "message.searchcontainer.noresults"));\n' +
                            '\n' +
                            '    List<Enitate> list = (List<Enitate>) request.getAttribute("entitateList");\n' +
                            '\n' +
                            '    if(list != null){\n' +
                            '       mySearchContainer.setResultsAndTotal(list);\n' +
                            '    }\n' +
                            '\n' +
                            '%>\n' +
                            '\n' +
                            '<aui:form action="<%=portletURL.toString()%>" cssClass="view-properties container-fluid container-fluid-max-xl container-form-view" \n' +
                            '       method="post" name="fm">\n' +
                            '       \n' +
                            '<%--    <h2>--%>\n' +
                            '<%--        <liferay-ui:message key="nav.label.tab1"></liferay-ui:message>--%>\n' +
                            '<%--    </h2>--%>\n' +
                            '    \n' +
                            '    <liferay-ui:search-container id="propertiesSearch" searchContainer="<%=mySearchContainer%>">\n' +
                            '       <liferay-ui:search-container-results results="<%=mySearchContainer.getResults()%>"/>  \n' +
                            '                                     \n' +
                            '       <liferay-ui:search-container-row \n' +
                            '          className="ro.Enitate"\n' +
                            '          modelVar="entry" \n' +
                            '       >\n' +
                            '\n' +
                            '          <liferay-ui:search-container-column-text\n' +
                            '                name="table.title.nume"\n' +
                            '                value="<%=GetterUtil.get(String.valueOf(entry.getNume()), "-") %>"\n' +
                            '          />\n' +
                            '\n' +
                            '\n' +
                            '          <liferay-ui:search-container-column-text\n' +
                            '                name="table.title.prenume"\n' +
                            '                value="<%=GetterUtil.get(String.valueOf(entry.getPrenume()), "-") %>"\n' +
                            '          />\n' +
                            '\n' +
                            '       </liferay-ui:search-container-row>\n' +
                            '       <liferay-ui:search-iterator searchResultCssClass="show-quick-actions-on-hover table table-autofit table-heading-nowrap table-list" />     \n' +
                            '    </liferay-ui:search-container>\n' +
                            '</aui:form>'}
                    </SyntaxHighlighter>

                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TabLiferayContent;