import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class PostgreSqlVmNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-postgresql-vm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obtinere IP masina virtuala NAS</b>
                    <br/>
                    <br/>
                    Ne conectam la sistemul de operarea: <i>DSM Synology</i>:
                    <br/>
                    <b>Virtual Machine Manager {"->"} Virtual Machine {"->"} IP</b> (poate fi de exemplu: 192.168.1.8)

                    <hr/>
                    <b>2. Conectare SSH la masina virtuala de pe NAS</b>
                    <br/>
                    <br/>

                    Dintr-un terminal <i>cmd</i> sau <i>putty</i>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.8 -p 22'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>kj - utilizatorul de pe masina virtuala</li>
                        <li>192.168.1.8 - IP masina virtuala</li>
                        <li>22 - port ssh masina virutala</li>
                    </ul>

                    <hr/>
                    <b>3. Instalare PostgreSQL</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get -y install postgresql-14'}
                    </SyntaxHighlighter>

                    Pentru a afla ce versiune de postgresql este instalata:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'psql -V'}
                    </SyntaxHighlighter>

                    În mod implicit, instalarea PostgreSQL creează un cont <b>postgres</b>, acest cont este un cont de super utilizator pentru baza de date.
                    <br/>

                    Pentru a trece in contul <i>postgres</i>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo -i -u postgres'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo su - postgres'}
                    </SyntaxHighlighter>

                    Pentru a intra a accesa prompterul de PostgreSQL:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'psql'}
                    </SyntaxHighlighter>

                    Pentru a schimba parola pentru <i>posgresql</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'postgres=# \\password'}
                    </SyntaxHighlighter>

                    Pentru a ieși din promptul PostgreSQL:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\\q'}
                    </SyntaxHighlighter>

                    Se poate intra si direct in <i>psql</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo -u postgres psql'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>4. Creare utilizator</b>
                    <br/>
                    <br/>

                    Pentru a creea un utilizator nou:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo -u postgres createuser --interactive'}
                    </SyntaxHighlighter>
                    {/*sau daca sunteti in <i>psql</i>:*/}
                    {/*<SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>*/}
                    {/*    {'createuser --interactive'}*/}
                    {/*</SyntaxHighlighter>*/}
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'Enter name of role to add: admindb\n' +
                            'Shall the new role be a superuser? (y/n) y'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Afisare utilizatori</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'psql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\\du'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {'                                   List of roles\n' +
                            ' Role name |                         Attributes                         | Member of\n' +
                            '-----------+------------------------------------------------------------+-----------\n' +
                            ' admindb   | Superuser, Create role, Create DB                          | {}\n' +
                            ' postgres  | Superuser, Create role, Create DB, Replication, Bypass RLS | {}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Creare baza de date</b>
                    <br/>
                    <br/>

                    Din <i>psql</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CREATE DATABASE university OWNER admindb '}
                    </SyntaxHighlighter>

                    Afisare baze de date:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\\l'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'    Name    |  Owner   | Encoding |   Collate   |    Ctype    |   Access privileges\n' +
                            '------------+----------+----------+-------------+-------------+-----------------------\n' +
                            ' postgres   | postgres | UTF8     | en_US.UTF-8 | en_US.UTF-8 |\n' +
                            ' template0  | postgres | UTF8     | en_US.UTF-8 | en_US.UTF-8 | =c/postgres          +\n' +
                            '            |          |          |             |             | postgres=CTc/postgres\n' +
                            ' template1  | postgres | UTF8     | en_US.UTF-8 | en_US.UTF-8 | =c/postgres          +\n' +
                            '            |          |          |             |             | postgres=CTc/postgres\n' +
                            ' university | postgres | UTF8     | en_US.UTF-8 | en_US.UTF-8 |'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Selectare schema/baza de date</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\\c \\university'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'You are now connected to database "university" as user "postgres".'}
                    </SyntaxHighlighter>

                    verificare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\\conninfo'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'You are connected to database "university" as user "postgres" via socket in "/var/run/postgresql" at port "5432".'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Creare tabela</b>
                    <br/>
                    <br/>
                    Creare tabela <i>address</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CREATE TABLE address ( id int PRIMARY KEY NOT NULL, street varchar(100) NOT NULL, city varchar(45) NOT NULL);'}
                    </SyntaxHighlighter>

                    Creare tabela <i>address</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'CREATE TABLE student (id int PRIMARY KEY NOT NULL,first_name varchar(50) NOT NULL,last_name varchar(50) DEFAULT NULL,email varchar(30) DEFAULT NULL,address_id int DEFAULT NULL);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Selectare schema/baza de date</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\\dt'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'          List of relations\n' +
                            ' Schema |  Name   | Type  |  Owner\n' +
                            '--------+---------+-------+----------\n' +
                            ' public | address | table | postgres\n' +
                            ' public | student | table | postgres'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Inserare in tabele</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'INSERT INTO address VALUES (1,\'Happy Street\',\'Delhi\'),(2,\'Down the town Street\',\'NY\');'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'INSERT INTO student VALUES (1,\'Raj\',\'Dave\',\'raj_dave@yahoo.com\',1),(2,\'John\',\'Smith\',\'john_smith@gmail.com\',2);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Afisare continut tabele</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'SELECT * FROM address;'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {' id |        street        | city\n' +
                            '----+----------------------+-------\n' +
                            '  1 | Happy Street         | Delhi\n' +
                            '  2 | Down the town Street | NY'}
                    </SyntaxHighlighter>

                    <hr/>
                    <hr/>
                    <b>11. Acceare baza de date de pe masina locala</b>
                    <br/>
                    <br/>

                    connectare ssh la masina virtuala de pe NAS
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'ssh kj@192.168.1.8 -p 22'}
                    </SyntaxHighlighter>

                    schimbare director:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'/etc/postgresql/14/main'}
                    </SyntaxHighlighter>

                    editare <b>pg_hba.conf</b>
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'sudo nano pg_hba.conf'}
                    </SyntaxHighlighter>
                    se adauga linia:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'host all all 192.168.1.8/24 trust'}
                    </SyntaxHighlighter>

                    editare <b>postgresql.conf</b>
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'sudo nano postgresql.conf'}
                    </SyntaxHighlighter>
                    se decomenteaza sau completeaza linia:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'listen_addresses = \'localhost, 192.168.1.8\''}
                    </SyntaxHighlighter>
                    se restarteaza serverul:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'systemctl restart postgresql'}
                    </SyntaxHighlighter>

                    Apoi, local, cu un <b>pgadmin</b> se creaza o conexiune:
                    <ul>
                        <li>host name/address:192.168.1.8</li>
                        <li>port:5432</li>
                        <li>username:postgres</li>
                        <li>password:postgres</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PostgreSqlVmNasContent;