import React from "react";
import BaseContentPage from "../BaseContentPage";

import IndexContent from "../robots/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class MyRobotsContent extends BaseContentPage {

    constructor(props) {
        super(props, "robots-my", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Loona</b>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/keyi-loona-robot-petbot-smarter-ki-haustier-roboter-mit-emotionen.jpg'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                https://keyirobot.com/products/loona
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Integrare ChatGPT
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>
                        <tr>
                            <td>
                                CPU
                            </td>
                            <td>
                                Quad-core Cortex A53, up to 1.5GHz,
                                <br/>
                                Dual-core BPU 5 TOPS(Tera Operations Per Second)
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Co-procesor
                            </td>
                            <td>
                                CortexM4,100MHz
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Camera
                            </td>
                            <td>
                                RGB Camera 720P
                            </td>
                        </tr>


                        <tr>
                            <td>
                                Wi-Fi
                            </td>
                            <td>
                                2.4G/5.8G dual-band, 802.11a/b/g/n
                            </td>
                        </tr>

                        <tr>
                            <td>
                                BLE
                            </td>
                            <td>
                                BT5.0
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Memorie
                            </td>
                            <td>
                                LPDDR4 2GB(RAM); eMMC5.0<br/>
                                8GB(ROM)
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Audio-processor
                            </td>
                            <td>
                                Dual-core DSP, 360MHz, HIFI Audio Engine
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Senzori
                            </td>
                            <td>
                                3D ToF(Time of Flight) Sensor; Touch Sensor; 3-axis accelerometer; 3-axis gyroscope
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Microfon
                            </td>
                            <td>
                                4-Microphone array
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Baterie
                            </td>
                            <td>
                                1350 mAh 11.1V Lithium-lon rechargeable battery
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Speaker
                            </td>
                            <td>
                                2W Speaker
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Charging
                            </td>
                            <td>
                                USB-type C port;
                                <br/>
                                Charging contacts for charging dock
                            </td>
                        </tr>



                        <tr>
                            <td>
                                Greutate
                            </td>
                            <td>
                                1.1kg
                            </td>
                        </tr>


                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                Grafica
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Altele
                            </td>
                            <td>
                                Face Recognition<br/>
                                Gesture Recognition<br/>
                                Object Recognition<br/>
                                Environment Detection<br/>
                                Trajectory Planning<br/>
                                3D Motion Capture<br/>
                                Emotion Recognition<br/>
                                Human Detection<br/>
                                Home monitoring<br/>
                                Self-charging<br/>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Jocuri interactive
                            </td>
                            <td>
                                Inner world (vezi ce gandeste: I seem to be alone at home; who just passed by; Nobody around?)
                                <br/>
                                Main game:
                                <ul>
                                    <li>
                                        Game ball (cauta o mingiutza)
                                    </li>
                                    <li>
                                        Bullflighting
                                    </li>
                                    <li>
                                        Laser chase
                                    </li>
                                    <li>
                                        Hand slap (fripta)
                                    </li>
                                    <li>

                                    </li>
                                </ul>
                                Mini game:
                                <ul>
                                    <li>
                                        Shell game (gaeste perla)
                                    </li>
                                    <li>
                                        Play & guess
                                    </li>
                                    <li>
                                        Slot machine
                                    </li>
                                    <li>
                                        Whack-a-Mole (nu trebuie sa lasi cartita sa iasa)
                                    </li>
                                    <li>
                                        Fisihing game
                                    </li>
                                </ul>

                                AR game:
                                <ul>
                                    <li>
                                        Feed (poti sa il hranesti)
                                    </li>
                                    <li>
                                        Slingshot (arunci virtual cu diverse obiecte in Loona: piatra, artificii, minge de tenis)
                                    </li>
                                    <li>
                                        Magic
                                    </li>
                                    <li>
                                        Delight
                                    </li>
                                    <li>
                                        Props (poti sa il gadili cu o pana)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ultima versiune Loona
                            </td>
                            <td>
                                1.2.95.MII
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Ultima versiune Aplicatie
                            </td>
                            <td>
                                v1.9.0
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Buton oprire/pornire
                            </td>
                            <td>
                                DA
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <hr/>
                    <b>2. Emo</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/product2.webp'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                https://living.ai/emo/
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Integrare ChatGPT
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>
                        <tr>
                            <td>
                                CPU
                            </td>
                            <td>
                                1.2 TOPS (conform https://www.hackster.io/news/living-ai-s-emo-pet-uses-1-2-tops-of-neural-compute-performance-to-liven-up-your-desk-cc42a5372c04)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Altele
                            </td>
                            <td>
                                Face Recognition<br/>
                                Emotion engine<br/>
                                Pet care<br/>
                                Voice integration<br/>
                                Go Home<br/>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>

                        <tr>
                            <td>
                                EMO firmware
                            </td>
                            <td>
                                2.5.0
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Homestation firmware
                            </td>
                            <td>
                                1.1.3
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <hr/>
                    <b>3. Rux</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/c7fc956bda5ce73112d334330447c3b3.png'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                https://global.letianpai.com/
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Integrare ChatGPT
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>

                        <tr>
                            <td>
                                CPU
                            </td>
                            <td>
                                4 Cortex-A55 core
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Memorie
                            </td>
                            <td>
                                4GB RAM, 32GB ROM
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Baterie
                            </td>
                            <td>
                                2650mAh batter
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Durata beterie
                            </td>
                            <td>
                                2h
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Wi-Fi
                            </td>
                            <td>
                                Wi-Fi 6
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Incarcare
                            </td>
                            <td>
                                Cablu USB<br/>
                                wireless charging
                            </td>
                        </tr>




                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>

                        </tbody>

                    </table>


                    Aplicatia mobila:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/rux0.jpg'}/>
                    </div>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/rux2.jpg'}/>
                    </div>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/rux3.jpg'}/>
                    </div>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/rux1.jpg'}/>
                    </div>

                    Moduri:
                    <ul>
                        <li><b>Pet</b></li>
                        <li><b>Time</b> : afiseaza ora si vremea; se poate personaliza modul in care se afiseaza ora si vremea<br/>
                            se poate modifica poza de background:
                            <ul>
                                <li>fie se ia aleator dintr-un album predefinit</li>
                                <li>fie se pune o singura poza, aleasa de utilizator</li>
                            </ul>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/t.jpg'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/t2.jpg'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/t3.jpg'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/t1.jpg'}/>
                            </div>

                        </li>
                        <li>
                            <b>Album</b>
                            <ul>
                                <li>se pot selecta imagini noi</li>
                                <li>se pot sterge imagini</li>
                                <li>imaginile se deruleaza pe ecran in acest mod</li>
                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>4. Ohbot</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/s226611715435091309_p22_i17_w500.jpeg'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                https://www.ohbot.co.uk/
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        Ohbot App (Windows)
                                    </li>
                                    <li>
                                        Scratch (Windows, macOS, Chromebook)
                                    </li>
                                    <li>
                                        Python (Windows, macOS, Raspberry Pi)
                                    </li>
                                </ul>


                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>
                    <b>5. Picoh</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/copy-of-1-shop-39.jpg'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                https://www.ohbot.co.uk/
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        Ohbot App (Windows)
                                    </li>
                                    <li>
                                        Scratch (Windows, macOS, Chromebook)
                                    </li>
                                    <li>
                                        Python (Windows, macOS, Raspberry Pi)
                                        <br/>
                                        GIT: https://github.com/ohbot/ohbot-python/
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>
                    <b>6. ELEGOO Penguin Bot</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/950x700.webp'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                <br/>
                                https://www.elegoo.com/blogs/arduino-projects/elegoo-samrt-robot-penguin-bot-tutorial
                                <br/>
                                https://eu.elegoo.com/collections/arduino-learning-sets
                                <br/>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                               C
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>
                    <b>7. ELEGOO OwlBot Smart Robot Tank</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/elegoo-owlbot-smart-robot-tank-tutorial-500714_1500x1500_crop_center.webp'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                <br/>
                                https://www.elegoo.com/blogs/arduino-projects/elegoo-owlbot-smart-robot-tank-tutorial
                                <br/>
                                https://eu.elegoo.com/collections/arduino-learning-sets
                                <br/>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                C
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>
                    <b>8. ELEGOO OwlBot Smart Robot Car</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/elegoo-owlbot-smart-robot-car-tutorial-845144_1200x1200_crop_center.jpg'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                <br/>
                                https://www.elegoo.com/blogs/arduino-projects/elegoo-owlbot-smart-robot-car-tutorial
                                <br/>
                                https://eu.elegoo.com/collections/arduino-learning-sets
                                <br/>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                C
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>
                    <b>9. ELEGOO OwlBot Smart Robot Car</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/elegoo-tumbller-self-balancing-robot-car-v11v10-tutorial-683433_1500x1500_crop_center.webp'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                <br/>
                                https://www.elegoo.com/blogs/arduino-projects/elegoo-tumbller-self-balancing-robot-car-tutorial
                                <br/>
                                https://eu.elegoo.com/collections/arduino-learning-sets
                                <br/>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                C
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <hr/>
                    <b>10. LAFVIN 4-DOF Panda Robot</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/img.png'}/>
                    </div>

                    <table className={"table table-sm table-hover table-dark"}>
                        <tbody>
                        <tr>
                            <td>
                                Site
                            </td>
                            <td>
                                <br/>
                                https://lafvintech.com/products/lafvin-diy-4-dof-panda-robot-kit-programmable-dancing-robot-kit-for-arduino-nano-electronic-toy-support-android-app-control

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Programare
                            </td>
                            <td>
                                C
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Aplicatie mobila
                            </td>
                            <td>
                                Da, dar prin APK
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <hr/>
                    <b>A. Alti roboti</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>

                            <b>Eilik</b>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/51DBd7O6GEL._AC_SL1500_.jpg'}/>
                            </div>
                            Site: https://store.energizelab.com/products/eilik
                            <hr/>
                        </li>
                        <li>
                            <b>ClicBot</b>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/starter_tag_700x.webp'}/>
                            </div>
                            Site: https://keyirobot.com/products/clicbot-kit-upgraded
                            <hr/>
                        </li>

                        <li>
                            <b>Ugot</b>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/UBT-UGOT.webp'}/>
                            </div>
                            Site: https://ugot-prelaunch.ubtrobot.com/
                            <hr/>
                        </li>
                        <li>
                            <b>Fable GO / Explorer</b>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/kit-robotic-educational-fable-go-427335.webp'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/robots/kit-robotic-educational-fable-explore-586055.webp'}/>
                            </div>

                            Site: https://www.robofun.ro/arduino-original/kit-robotic-educational-fable-go.html
                            <hr/>
                        </li>
                        <li>
                            <b>Doly</b>
                            <br/>
                            Site: https://www.kickstarter.com/projects/doly/doly-more-than-a-robot?ref=recommendation-projectpage-footer-1
                        </li>

                        <li>
                            <b>Looi</b>
                            <br/>
                            site: https://www.kickstarter.com/projects/tangiblefuture/looi-robot-turn-your-smartphone-into-a-desktop-robot?ref=62x8q1
                        </li>

                        <li>
                            <b>Ortomi</b>
                            <br/>
                            Site: https://ortomi.com/
                        </li>

                    </ul>

                    <hr/>
                    <b>B. Util pentru Loona (probabil)</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/robots/loona-charging-station.webp'}/>
                    </div>

                    <hr/>
                    <b>C. Observatii</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>LAFVIN sau ELEGOO ? => ELEGOO </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MyRobotsContent;