import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class NextConfigJsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-nextconfigjs", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Fisier de configurare: next.config.js </b>
                    <br/>
                    <br/>

                    Exemplu de configurare conditionata de faza:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const {PHASE_DEVELOPMENT_SERVER} = require(\'next/constants\')\n' +
                            '\n' +
                            'module.exports = (phase) => {\n' +
                            '    if (phase === PHASE_DEVELOPMENT_SERVER) {\n' +
                            '        return {\n' +
                            '            env: {\n' +
                            '                start_year: 2022\n' +
                            '            }\n' +
                            '        }\n' +
                            '    } else {\n' +
                            '        return {\n' +
                            '            env: {\n' +
                            '                start_year: 2023\n' +
                            '            }\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NextConfigJsReactContent;