import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class InstallAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare</b>
                    <br/>
                    <br/>


                    Instalare Angular CLI:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm install -g @angular/cli'}
                    </SyntaxHighlighter>

                    Creare proiect:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng new my-app'}
                    </SyntaxHighlighter>

                    Pornire aplicatie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd my-app\n' +
                            'ng serve --open'}
                    </SyntaxHighlighter>
                    sau (identic ca in React):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd my-app\n' +
                            'npm start'}
                    </SyntaxHighlighter>

                    Pentru a porni aplicatia pe alt port:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng serve --open --port 4211'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm start -- --port 4402'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>pentru a porni este nevoie de Node.js 16.20.0
                            <SyntaxHighlighter>
                                {'nvm use 16.20.0'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Aplicatia v-a putea fi accesata din browser:
                    <SyntaxHighlighter>
                        {'http://localhost:4200/'}
                    </SyntaxHighlighter>

                    Build pentru productie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng build --prod'}
                    </SyntaxHighlighter>

                    Adaugare Material:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng add @angular/material'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React:
                    <ul>
                        <li>
                            Creare proiect:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'npx create-react-app my-app'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Pornire aplicatie:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd my-app\n' +
                                    'npm start'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallAngularContent;