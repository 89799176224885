import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../raspberrypi/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SpeechRecognitionRpiContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rpi-speech-recognition", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# pip install SpeechRecognition --break-system-package\n' +
                            '\n' +
                            '# sudo apt install python3-pyaudio\n' +
                            '\n' +
                            '# sudo apt-get install flac\n' +
                            '\n' +
                            'import speech_recognition as sr\n' +
                            '\n' +
                            'speech = sr.Recognizer()\n' +
                            'print("Python asculta")\n' +
                            'with sr.Microphone() as source:\n' +
                            '    speech.adjust_for_ambient_noise(source)\n' +
                            '    audio = speech.listen(source)\n' +
                            '    input = speech.recognize_google(audio)\n' +
                            'print(f\'Ai zis: {input}.\')'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpeechRecognitionRpiContent;