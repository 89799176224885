import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavaSpringSecurityEx06Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-security-ex-06", IndexContent);
    }

    render() {

        return (
            <div className="home index">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify content important"}>
                    <i>Aplicatie:</i> <b>security</b>
                    <br/>
                    <i>Modul:</i> <b>security-05</b>
                    <br/>
                    <i>Descriere actiune:</i> Construirea unui proiect cu autorizare pe baza implementarilor <b>InMemoryUserDetailsManager</b>
                    si <b>NoOpPasswordEncoder</b> pornind de la modulul <b>security-04</b>, folosind <b>WebSecurityConfigurerAdapter</b>
                    <br/>
                    <i>IDE folosit</i>: Intellij IDEA
                    <hr/>

                    1. In fisierul <b>pom.xml</b> (in radacina proiectului) se adauga in cadrul sectiunii <b>project\modules</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<module>security-05</module>'}
                    </SyntaxHighlighter>

                    2. Clasele <b>HelloController</b> si <b>Security04Application</b> (continut identic cu Security04Application) se pot copia, pastrand aceeasi structura a organizarii de directoare si de pachete
                    (redenumind <i>exemplu02</i> cu <i>exemplu05</i> in numele de pachete).
                    <br/>
                    <br/>
                    3.   Apoi, vom creea pachetul:
                    <div className={"padding50"}>
                        <b>ro.letyournailsgrow.security.exemplu05.config</b>
                    </div>

                    <br/>
                    4. Adaugam metoda <b>pconfigure(HttpSecurity http)</b> in clasa <b>SecurityConfig</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.security.exemplu05.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        'import org.springframework.security.config.annotation.authentication.builders.AuthenticationManagerBuilder;\n' +
                        'import org.springframework.security.config.annotation.web.builders.HttpSecurity;\n' +
                        'import org.springframework.security.config.annotation.web.configuration.WebSecurityConfigurerAdapter;\n' +
                        'import org.springframework.security.core.userdetails.User;\n' +
                        'import org.springframework.security.core.userdetails.UserDetails;\n' +
                        'import org.springframework.security.crypto.password.NoOpPasswordEncoder;\n' +
                        'import org.springframework.security.provisioning.InMemoryUserDetailsManager;\n' +
                        '\n' +
                        '@Configuration\n' +
                        'public class SecurityConfig extends WebSecurityConfigurerAdapter {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected void configure(AuthenticationManagerBuilder auth) throws Exception {\n' +
                        '        InMemoryUserDetailsManager userDetailsService = new InMemoryUserDetailsManager();\n' +
                        '\n' +
                        '        UserDetails user = User.withUsername("iulian")\n' +
                        '                .password("123")\n' +
                        '                .authorities("read")\n' +
                        '                .build();\n' +
                        '\n' +
                        '        userDetailsService.createUser(user);\n' +
                        '\n' +
                        '        auth.userDetailsService(userDetailsService).passwordEncoder(NoOpPasswordEncoder.getInstance());\n' +
                        '    }\n' +
                        '\n' +
                        '    protected void configure(HttpSecurity http) throws Exception{\n' +
                        '        http.httpBasic();\n' +
                        '        http.authorizeRequests()\n' +
                        '                .anyRequest()\n' +
                        '                    //.permitAll();\n' +
                        '                    .authenticated();\n' +
                        '\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    5. Daca apelam metoda <i>hello()</i>  din clasa <i>HelloController</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8080/hello\n' +
                        'Authorization: Basic iulian 123'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b> Practic se comporta identic cu modulul <b>security-04</b>.

                    6. Daca apelam metoda <i>hello()</i>  din clasa <i>HelloController</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8080/hello'}
                    </SyntaxHighlighter>

                    Atunci raspunsul va fi:

                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8086/hello\n' +
                        '\n' +
                        'HTTP/1.1 401 \n' +
                        'Set-Cookie: JSESSIONID=6E2607C518AD7CABA37617272A8BE929; Path=/; HttpOnly\n' +
                        'WWW-Authenticate: Basic realm="Realm"\n' +
                        'X-Content-Type-Options: nosniff\n' +
                        'X-XSS-Protection: 1; mode=block\n' +
                        'Cache-Control: no-cache, no-store, max-age=0, must-revalidate\n' +
                        'Pragma: no-cache\n' +
                        'Expires: 0\n' +
                        'X-Frame-Options: DENY\n' +
                        'Content-Type: application/json\n' +
                        'Transfer-Encoding: chunked\n' +
                        'Date: Mon, 01 Nov 2021 19:06:43 GMT\n' +
                        'Keep-Alive: timeout=60\n' +
                        'Connection: keep-alive\n' +
                        '\n' +
                        '{\n' +
                        '  "timestamp": "2021-11-01T19:06:43.691+00:00",\n' +
                        '  "status": 401,\n' +
                        '  "error": "Unauthorized",\n' +
                        '  "message": "Unauthorized",\n' +
                        '  "path": "/hello"\n' +
                        '}\n' +
                        '\n' +
                        'Response code: 401; Time: 69ms; Content length: 122 bytes'}
                    </SyntaxHighlighter>

                    7. Daca comentam linia 34 ( <b>.authenticated();</b>) si decomentam linia 33 (<b>//.permitAll();</b> ) vom obtine:

                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8086/hello\n' +
                        '\n' +
                        'HTTP/1.1 200 \n' +
                        'X-Content-Type-Options: nosniff\n' +
                        'X-XSS-Protection: 1; mode=block\n' +
                        'Cache-Control: no-cache, no-store, max-age=0, must-revalidate\n' +
                        'Pragma: no-cache\n' +
                        'Expires: 0\n' +
                        'X-Frame-Options: DENY\n' +
                        'Content-Type: text/plain;charset=UTF-8\n' +
                        'Content-Length: 5\n' +
                        'Date: Mon, 01 Nov 2021 19:10:45 GMT\n' +
                        'Keep-Alive: timeout=60\n' +
                        'Connection: keep-alive\n' +
                        '\n' +
                        'Hello'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b> Practic se permite orice cerere, fara a fi nevoie de autentificare.

                    <hr/>
                    <b>STOP</b>

                    </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaSpringSecurityEx06Content;