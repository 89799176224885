import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class AppNextContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-app", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. App</b>
                    <br/>
                    <br/>

                    <b>_app.js</b> este o componentă specială care este folosită pentru a inițializa fiecare pagină.
                    Acesta este un loc în care ați face orice trebuie să afecteze toate paginile, de ex. încărcați o foaie de stil globală .

                    <br/>



                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// /pages/_app.js\n' +
                            'import \'../styles/tailwind.css\'\n' +
                            '\n' +
                            'function MyApp({ Component, pageProps }) {\n' +
                            '  return <Component {...pageProps} />\n' +
                            '}\n' +
                            '\n' +
                            'export default MyApp'}
                    </SyntaxHighlighter>

                    Next.js folosește componenta App pentru a inițializa paginile.
                    Pentru a suprascrie, se creeaza fisierul ./pages/_app.js și suprascrieți clasa App.
                    Nu includeți niciodată html, body, etc.
                    <br/>
                    Pentru a înlocui acest comportament implicit, trebuie să creați un fișier la ./pages/_document.js, unde puteți extinde clasa Document.
                    <br/>
                    _document.js este redat doar pe partea serverului și nu pe partea clientului, astfel încât gestionatorii de evenimente (ca onClick) nu vor funcționa.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AppNextContent;