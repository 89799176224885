import {useRoutes} from "react-router-dom";
import ArduinoHomeContent from "./IndexContent";
import React from "react";
import LedArduinoContent from "./LedArduinoContent";
import LedButtonArduinoContent from "./LedButtonArduinoContent";
import LedFadeArduinoContent from "./LedFadeArduinoContent";
import PotentiometerArduinoContent from "./PotentiometerArduinoContent";
import UltrasonicDistanceArduinoContent from "./UltrasonicDistanceArduinoContent";
import ServoMotorArduinoContent from "./ServoMotorArduinoContent";
import IntroArduinoContent from "./IntroArduinoContent";
import LedRGBArduinoContent from "./LedRGBArduinoContent";
import InterruptsArduinoContent from "./InterruptsArduinoContent";
import EepromArduinoContent from "./EepromArduinoContent";
import LCDArduinoContent from "./LCDArduinoContent";
import IRRemoteArduinoContent from "./IRRemoteArduinoContent";
import FotorezistorArduinoContent from "./FotorezistorArduinoContent";
import BluetoothHC06ArduinoContent from "./BluetoothHC06ArduinoContent";
import OtherSensorsArduinoContent from "./OtherSensorsArduinoContent";
import DHTArduinoContent from "./DHTArduinoContent";
import BuzzerArduinoContent from "./BuzzerArduinoContent";
import SoundSenzorArduinoContent from "./SoundSenzorArduinoContent";


export default function Router() {
    let element = useRoutes([
        {path: "/arduino/index", element: <ArduinoHomeContent/>},
        {path: "/arduino/intro", element: <IntroArduinoContent/>},
        {path: "/arduino/led", element: <LedArduinoContent/>},
        {path: "/arduino/led-button", element: <LedButtonArduinoContent/>},
        {path: "/arduino/led-fade", element: <LedFadeArduinoContent/>},
        {path: "/arduino/potentiometer", element: <PotentiometerArduinoContent/>},
        {path: "/arduino/ultrasonic-distance", element: <UltrasonicDistanceArduinoContent/>},
        {path: "/arduino/servo-motor", element: <ServoMotorArduinoContent/>},
        {path: "/arduino/led-rgb", element: <LedRGBArduinoContent/>},
        {path: "/arduino/interrupts", element: <InterruptsArduinoContent/>},
        {path: "/arduino/eeprom", element: <EepromArduinoContent/>},
        {path: "/arduino/lcd", element: <LCDArduinoContent/>},
        {path: "/arduino/ir-remote", element: <IRRemoteArduinoContent/>},
        {path: "/arduino/photoresistor", element: <FotorezistorArduinoContent/>},
        {path: "/arduino/bluetooth-hc06", element: <BluetoothHC06ArduinoContent/>},
        {path: "/arduino/other-sensors", element: <OtherSensorsArduinoContent/>},
        {path: "/arduino/dht", element: <DHTArduinoContent/>},
        {path: "/arduino/buzzer", element: <BuzzerArduinoContent/>},
        {path: "/arduino/sound-sensor", element: <SoundSenzorArduinoContent/>},

    ]);

    return element;
}