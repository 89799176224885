import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OopEncapsulationPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-oop-encapsulation", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Incapsularea</b> se refera la posibilitatea de:
                    <ul>
                        <li>ascundere a atributelor in interiorul unui clase - atribute private</li>
                        <li>împiedicarea accesului direct la atribute private din afara clasei</li>
                        <li>accesare a atributelor prin intemediul unor metode publice </li>
                        <li>modificare a atributelor prin intemediul unor metode publice </li>
                    </ul>

                    Python nu oferă confidențialitate (incapsulare) reală - nu exista un control complet al accesului, atributele private pot fi accesate în mod intenționat.

                    <br/>
                    <br/>

                    Controlul la accesarea unui atribut se poate realiza cu:
                    <ul>
                        <li>functia build-in <b>property()</b></li>
                        <li>decoratorul <b>@property</b>:
                            <ul>
                                <li>desemnează o metodă care va fi apelată automat atunci când un alt obiect dorește să citească valoarea atributului încapsulat (e un <b>getter</b>)</li>

                                <li>trebuie definit:
                                        <ul>
                                            <li>înainte de metoda responsabilă pentru setarea valorii atributului încapsulat (metoda decorata cu <b>@<i>numeMetodaGetter</i>.setter</b>)</li>
                                            <li>înainte de metoda responsabilă pentru ștergerea atributului încapsulat (metoda decorata cu <b>@<i>numeMetodaGetter</i>.deleter</b>)</li>
                                        </ul>
                                </li>
                                <li>numele metodei (prin intermediul caruia se acceseaza atributul privat) este folosit ca 'nume al atributului privat de instanta'</li>
                            </ul>
                        </li>
                        <li>decoratorul <b>@<i>numeMetodaGetter</i>.setter</b> marcheaza metoda apelată pentru setarea valorii atributului încapsulat
                        </li>
                        <li>decoratorul <b>@<i>numeMetodaGetter</i>.deleter</b> marcheaza metoda apelată cand se sterge atributul
                        </li>
                    </ul>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Carte:\n' +
                        '    \n' +
                        '    def __init__(self, pagini):\n' +
                        '        self.__pagini = pagini\n' +
                        '\n' +
                        '    @property\n' +
                        '    def pagini(self):\n' +
                        '        print("Accesare numar pagini")\n' +
                        '        return self.__pagini\n' +
                        '\n' +
                        '    @pagini.setter\n' +
                        '    def pagini(self, pagini):\n' +
                        '        if pagini < 1000:\n' +
                        '            print("Setare numar pagini")\n' +
                        '            self.__pagini=pagini\n' +
                        '\n' +
                        '    @pagini.deleter\n' +
                        '    def pagini(self):\n' +
                        '        print("Stergere atribut cu numar pagini")\n' +
                        '        self.__pagini = None\n' +
                        '\n' +
                        'c = Carte(4)\n' +
                        'c.pagini=100 # Setare numar pagini\n' +
                        'print(c.pagini) # Accesare numar pagini / 100\n' +
                        'del c.pagini # Stergere atribut cu numar pagini\n' +
                        'print(c._Carte__pagini) # None\n' +
                        'del c._Carte__pagini\n' +
                        'print(c._Carte__pagini) # AttributeError: \'Carte\' object has no attribute \'_Carte__pagini\'\n' +
                        '\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OopEncapsulationPythonContent;