import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OverviewJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-overview", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Generalitati</b>
                    <hr/>

                    La începutul anilor 90, toate paginile web erau statice.
                    In 1995,  corporația Netscape l-a angajat pe Brendan Eich să dezvolte un nou limbaj pentru browserul web Netscape Navigator.
                    Noul limbaj a fost numit <i>LiveScript</i>, dar la scurt timp după numele său a fost schimbat în <b>JavaScript</b>.
                    <br/>
                    <br/>

                    JavaScript este:
                    <ul>
                        <li>limbaj de nivel înalt</li>
                        <li>un <b>limbaj interpretat</b></li>
                    </ul>

                    Exista interpretor de Javacript in:
                    <ul>
                        <li>majoritatea <i>browserelor</i></li>
                        <li><i>node.js</i></li>
                    </ul>

                    Cele mai multe motoare JavaScript moderne folosesc tehnica de compilare Just In Time (Compilarea JIT).
                    Această tehnică constă în compilarea fragmentelor de cod în timpul execuției programului (mai mult decât o singură instrucțiune), realizand o crestere a performantei.

                    <br/>
                    <br/>

                    JavaScript poate fi folosit:
                    <ul>
                        <li>pe partea de client (browser/font-end)
                            <br/>
                            din motive de securitate, rulează cod de script într-un mediu sandbox (un mediu separat de lumea exterioară), care nu permite accesul la fișierele și resursele locale;
                        </li>
                        <li>pe partea de server (back-end)</li>
                    </ul>

                    <hr/>
                    Dezavantaje, fiind un limbaj interpretat:
                    <ul>
                        <li>este mai lent (nu e recomandat pentru aplicatii care necesită calcule matematice avansate sau performanțe foarte înalte)</li>
                        <li>codul este accesibil într-o formă ușor de citit și poate fi folosit fără permisiunea noastră (se poate ofusca codul, adica transformarea scriptului într-o formă puțin mai puțin lizibilă)</li>
                    </ul>

                    Avantaje:
                    <ul>
                        <li>
                            numar mare de framework-uri si librarii
                        </li>
                        <li>comunitate foarte activă</li>
                    </ul>

                    <hr/>
                    Medii online de dezvoltare:
                    <ul>
                        <li>
                            JSFiddle
                        </li>
                        <li>
                            CodePen
                        </li>
                        <li>
                            JsBin
                        </li>
                        <li>
                            Plunker
                        </li>
                    </ul>

                    Avantajele utilizării unui editor de cod este:
                    <ul>
                        <li>evidențierea sintaxei</li>
                        <li>completarea automată a textului</li>
                        <li>verificarea erorilor</li>
                    </ul>

                    Editare:
                    <ul>
                        <li>Visual Studio Code (comercial, sau gratuit pentru uz personal)</li>
                        <li>WebStorm (comercial)</li>
                        <li>Sublime Text (comercial)</li>
                        <li>Notepad++ (gratuit)</li>
                    </ul>

                    Pe langa <i>editor</i>, <i>interpretor</i>, <i>debugger (depanator)</i>, mai sunt si alte instrumente pentru dezvoltarea de aplicatii in JavaScript:
                    <ul>
                        <li><i>manageri de pachete (package managers)</i>: permit gestionarea bibliotecilor sau componente ale mediului de dezvoltare (de exemplu, npm sau yarn)</li>
                        <li><i>executori de task-uri si grupuri de module (task runners and module bundlers)</i>: utilizate pentru a automatiza procesul de dezvoltare a software-ului și pentru a îmbina codul rezultat din multe fișiere și biblioteci (de exemplu, Grunt sau Webpack)</li>
                        <li><i>framework-uri de testare (testing framework)</i>: permit testarea automată a corectitudinii programului în căutarea unor erori potențiale (de exemplu, Mocha, Jasmine sau Jest) </li>
                        <li><i>analizoare de securitate (security analyzers)</i>: utilizate pentru a controla securitatea (de exemplu, Snyk, RetireJS sau OWASP Dependency Check)</li>
                    </ul>

                    <hr/>
                    Un interpretor:
                    <ul>
                        <li>verifica daca sunt erori de sintaxa</li>
                        <li>executa programul instructiune cu instructiune</li>
                    </ul>

                    Un depanator este un instrument care permite:
                    <ul>
                        <li>oprirea din execuție unui program</li>
                        <li>intreruperea din execuție a codului într-un loc indicat (breakpoint)</li>
                        <li>rularea instrucțiunilor pas cu pas</li>
                        <li>analiza starii curente a programului</li>
                    </ul>
                    Majoritate browser-erelor vin cu:
                    <ul>
                        <li>inspector: permite să analizăm elementele HTML individuale ale unui site web deschis</li>
                        <li>consola:
                            <ul>
                                <li>afiseaza informații despre erori</li>
                                <li>permite rularea de comenzi JavaScript individuale în contextul paginii curente</li>
                            </ul>
                        </li>
                        <li>debugger</li>
                    </ul>
                    Activarea instrumentelor pentru dezvoltatori (inspector, consola, debugger) depinde de fiecare browser in parte (exemplu: F12 pentru Chrome).

                    <hr/>
                    JavaScript la nivelul clientului este un limbaj al web-ului și există numai în ecosistemul web.
                    În această configurare, JavaScript nu poate exista de la sine.
                    Codul JavaScript trebuie să fie încorporat într-un document <b>HTML</b> (HyperText Markup Language / HTML este un set de etichete folosite pentru a descrie structura unui site web):

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                        '<html>\n' +
                        '  <head>\n' +
                        '    <title>Prima pagina</title>\n' +
                        '  </head>\n' +
                        '  <body>\n' +
                        '  </body>\n' +
                        '</html>'}
                    </SyntaxHighlighter>

                    <b>CSS (Cascading Style Sheets)</b> este un limbaj folosit împreună cu HTML pentru a descrie aspectul unei pagini și elementele acesteia.
                    <br/>
                    <br/>

                    <b>Eticheta {"<script>"}</b>
                    <br/>
                    Codul JavaScript care urmează să fie executat de browser pe pagină trebuie să fie <i>atașat la HTML</i> folosind <b>eticheta {"<script>"}</b> și se poate face in urmatoarele 2 moduri:

                    <ul>
                        <li><i>script inline</i>, daca codul e scurt:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'<script> cod JavaScript </script>'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <i>script extern</i>: specificarea fisierului JavaScript (fisierele JavaScript au extensia <b>.js</b>) prin intermediul atributului <b>src</b> a etichetei {"<script>"}

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'<script src="app.js"></script>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Browser-ul citeste pagina HTML linie cu linie, iar etichetele de script sunt <b>executate în momentul în care este parsata eticheta {"<script>"} </b>
                    (parsarea înseamnă o analiză formală a codului pentru a înțelege structura acestuia).
                    <br/>
                    Etichetele {"<script>"} sunt inserate, de obicei, în antetul paginii între etichetele {"<head> și </head>"} și putem insera mai multe, pentru a include mai multe fisiere JavaScript.
                    <br/>

                    Acest comportament (adica, executia scriptului în momentul în care este parsata eticheta {"<script>"}) poate fi modificat pentru scripturile externe indicate de atributul <b>src</b> folosind atributele:
                    <ul>
                        <li><b>defer</b> – înseamnă că scriptul ar trebui să fie executat după ce întreaga pagină este încărcată</li>
                        <li><b>async</b> – înseamnă că scriptul va fi executat imediat, dar în paralel cu parsarea restului paginii</li>
                    </ul>

                    <hr/>

                    <b>Info 1: about:blank</b>
                    <br/>
                    Daca se deschide un tab nou intr-un browser si se tasteaza in bara de adrese: <b>about:blank</b> (este o pseudo-adresa) se va genera si incarca o pagină HTML necompletată:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<html>\n' +
                        '  <head></head>\n' +
                        '  <body></body>\n' +
                        '</html>'}
                    </SyntaxHighlighter>

                    <b>Info 2: console.log("Buna")</b>
                    <br/>

                    Pentru afisarea unui mesaj in consola se poate folosi metoda/functia <b>console.log()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'console.log("Buna")'}
                    </SyntaxHighlighter>

                    <b>Info 3: file://</b>
                    <br/>

                    In browser, atunci cand vrem sa deschidem un fisier local, in bara de adresa vom scrie calea precedata de <b>local://</b>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OverviewJavaScriptContent;