import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubeadmK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubeadm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare kubelet, kubeadm si kubectl</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt install curl apt-transport-https -y\n' +
                            'curl -fsSL  https://packages.cloud.google.com/apt/doc/apt-key.gpg|sudo gpg --dearmor -o /etc/apt/trusted.gpg.d/k8s.gpg\n' +
                            'curl -s https://packages.cloud.google.com/apt/doc/apt-key.gpg | sudo apt-key add -\n' +
                            'echo "deb https://apt.kubernetes.io/ kubernetes-xenial main" | sudo tee /etc/apt/sources.list.d/kubernetes.list'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt update\n' +
                            'sudo apt install wget curl vim git kubelet kubeadm kubectl -y\n' +
                            'sudo apt-mark hold kubelet kubeadm kubectl'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Dezactivare Swap Space</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo swapoff -a '}
                    </SyntaxHighlighter>

                    verificare:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'free -h'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter >
                        {'             total        used        free      shared  buff/cache   available\n' +
                            'Mem:           3,8Gi       829Mi       1,1Gi       4,0Mi       1,9Gi       2,8Gi\n' +
                            'Swap:             0B          0B          0B\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Instalare Container runtime</b>
                    <br/>
                    <br/>
                    Pentru a rula containere în poduri, Kubernetes folosește unul dintre urmatoarele container runtime:
                    <ul>
                        <li>
                            CRI-O
                        </li>
                        <li>
                            Containerd
                        </li>
                        <li>
                            Docker
                        </li>
                    </ul>

                    Pentru Docker:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# Add repo and Install packages\n' +
                            'sudo apt update\n' +
                            'sudo apt install -y curl gnupg2 software-properties-common apt-transport-https ca-certificates\n' +
                            'curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo apt-key add -\n' +
                            'sudo add-apt-repository "deb [arch=amd64] https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable"\n' +
                            'sudo apt update\n' +
                            'sudo apt install -y containerd.io docker-ce docker-ce-cli\n' +
                            '\n' +
                            '# Create required directories\n' +
                            'sudo mkdir -p /etc/systemd/system/docker.service.d\n' +
                            '\n' +
                            '# Create daemon json config file\n' +
                            'sudo tee /etc/docker/daemon.json <<EOF\n' +
                            '{\n' +
                            '  "exec-opts": ["native.cgroupdriver=systemd"],\n' +
                            '  "log-driver": "json-file",\n' +
                            '  "log-opts": {\n' +
                            '    "max-size": "100m"\n' +
                            '  },\n' +
                            '  "storage-driver": "overlay2"\n' +
                            '}\n' +
                            'EOF\n' +
                            '\n' +
                            '# Start and enable Services\n' +
                            'sudo systemctl daemon-reload \n' +
                            'sudo systemctl restart docker\n' +
                            'sudo systemctl enable docker\n' +
                            '\n' +
                            '# Configure persistent loading of modules\n' +
                            'sudo tee /etc/modules-load.d/k8s.conf <<EOF\n' +
                            'overlay\n' +
                            'br_netfilter\n' +
                            'EOF\n' +
                            '\n' +
                            '# Ensure you load modules\n' +
                            'sudo modprobe overlay\n' +
                            'sudo modprobe br_netfilter\n' +
                            '\n' +
                            '# Set up required sysctl params\n' +
                            'sudo tee /etc/sysctl.d/kubernetes.conf<<EOF\n' +
                            'net.bridge.bridge-nf-call-ip6tables = 1\n' +
                            'net.bridge.bridge-nf-call-iptables = 1\n' +
                            'net.ipv4.ip_forward = 1\n' +
                            'EOF'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Initializare control plane (rulare pe  master node)</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            conecctare la serverul folosit ca master.
                        </li>
                        <li>
                            verificare că modulul br_netfilter este încărcat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'lsmod | grep br_netfilter'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'br_netfilter           32768  0\n' +
                                    'bridge                307200  1 br_netfilter\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            activare serviciu kubelet:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'sudo systemctl enable kubelet'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Pull imaginii:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'sudo kubeadm config images pull'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'[config/images] Pulled registry.k8s.io/kube-apiserver:v1.26.1\n' +
                                    '[config/images] Pulled registry.k8s.io/kube-controller-manager:v1.26.1\n' +
                                    '[config/images] Pulled registry.k8s.io/kube-scheduler:v1.26.1\n' +
                                    '[config/images] Pulled registry.k8s.io/kube-proxy:v1.26.1\n' +
                                    '[config/images] Pulled registry.k8s.io/pause:3.9\n' +
                                    '[config/images] Pulled registry.k8s.io/etcd:3.5.6-0\n' +
                                    '[config/images] Pulled registry.k8s.io/coredns/coredns:v1.9.3\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            selectare docker ca Container Runtime:
                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {'sudo kubeadm config images pull --cri-socket /run/cri-dockerd.sock'}
                            </SyntaxHighlighter>
                            setare CRI-o ca Container runtime:
                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {'sudo kubeadm config images pull --cri-socket /var/run/crio/crio.sock'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubeadm init --skip-phases=addon/coredns --skip-phases=addon/kube-proxy --ignore-preflight-errors=NumCPU\n' +
                            '\n' +
                            'kubeadm init phase addon kube-proxy\n' +
                            'kubeadm init phase addon coredns\n'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'[init] Using Kubernetes version: v1.26.1\n' +
                            '[preflight] Running pre-flight checks\n' +
                            '        [WARNING NumCPU]: the number of available CPUs 1 is less than the required 2\n' +
                            '[preflight] Pulling images required for setting up a Kubernetes cluster\n' +
                            '[preflight] This might take a minute or two, depending on the speed of your internet connection\n' +
                            '[preflight] You can also perform this action in beforehand using \'kubeadm config images pull\'\n' +
                            '[certs] Using certificateDir folder "/etc/kubernetes/pki"\n' +
                            '[certs] Generating "ca" certificate and key\n' +
                            '[certs] Generating "apiserver" certificate and key\n' +
                            '[certs] apiserver serving cert is signed for DNS names [kubernetes kubernetes.default kubernetes.default.svc kubernetes.default.svc.cluster.local nas-ubuntu-manager] and IPs [10.96.0.1 192.168.1.10]\n' +
                            '[certs] Generating "apiserver-kubelet-client" certificate and key\n' +
                            '[certs] Generating "front-proxy-ca" certificate and key\n' +
                            '[certs] Generating "front-proxy-client" certificate and key\n' +
                            '[certs] Generating "etcd/ca" certificate and key\n' +
                            '[certs] Generating "etcd/server" certificate and key\n' +
                            '[certs] etcd/server serving cert is signed for DNS names [localhost nas-ubuntu-manager] and IPs [192.168.1.10 127.0.0.1 ::1]\n' +
                            '[certs] Generating "etcd/peer" certificate and key\n' +
                            '[certs] etcd/peer serving cert is signed for DNS names [localhost nas-ubuntu-manager] and IPs [192.168.1.10 127.0.0.1 ::1]\n' +
                            '[certs] Generating "etcd/healthcheck-client" certificate and key\n' +
                            '[certs] Generating "apiserver-etcd-client" certificate and key\n' +
                            '[certs] Generating "sa" key and public key\n' +
                            '[kubeconfig] Using kubeconfig folder "/etc/kubernetes"\n' +
                            '[kubeconfig] Writing "admin.conf" kubeconfig file\n' +
                            '[kubeconfig] Writing "kubelet.conf" kubeconfig file\n' +
                            '[kubeconfig] Writing "controller-manager.conf" kubeconfig file\n' +
                            '[kubeconfig] Writing "scheduler.conf" kubeconfig file\n' +
                            '[kubelet-start] Writing kubelet environment file with flags to file "/var/lib/kubelet/kubeadm-flags.env"\n' +
                            '[kubelet-start] Writing kubelet configuration to file "/var/lib/kubelet/config.yaml"\n' +
                            '[kubelet-start] Starting the kubelet\n' +
                            '[control-plane] Using manifest folder "/etc/kubernetes/manifests"\n' +
                            '[control-plane] Creating static Pod manifest for "kube-apiserver"\n' +
                            '[control-plane] Creating static Pod manifest for "kube-controller-manager"\n' +
                            '[control-plane] Creating static Pod manifest for "kube-scheduler"\n' +
                            '[etcd] Creating static Pod manifest for local etcd in "/etc/kubernetes/manifests"\n' +
                            '[wait-control-plane] Waiting for the kubelet to boot up the control plane as static Pods from directory "/etc/kubernetes/manifests". This can take up to 4m0s\n' +
                            '[apiclient] All control plane components are healthy after 17.002837 seconds\n' +
                            '[upload-config] Storing the configuration used in ConfigMap "kubeadm-config" in the "kube-system" Namespace\n' +
                            '[kubelet] Creating a ConfigMap "kubelet-config" in namespace kube-system with the configuration for the kubelets in the cluster\n' +
                            '[upload-certs] Skipping phase. Please see --upload-certs\n' +
                            '[mark-control-plane] Marking the node nas-ubuntu-manager as control-plane by adding the labels: [node-role.kubernetes.io/control-plane node.kubernetes.io/exclude-from-external-load-balancers]\n' +
                            '[mark-control-plane] Marking the node nas-ubuntu-manager as control-plane by adding the taints [node-role.kubernetes.io/control-plane:NoSchedule]\n' +
                            '[bootstrap-token] Using token: hj8464.9666gptcypm2gjuv\n' +
                            '[bootstrap-token] Configuring bootstrap tokens, cluster-info ConfigMap, RBAC Roles\n' +
                            '[bootstrap-token] Configured RBAC rules to allow Node Bootstrap tokens to get nodes\n' +
                            '[bootstrap-token] Configured RBAC rules to allow Node Bootstrap tokens to post CSRs in order for nodes to get long term certificate credentials\n' +
                            '[bootstrap-token] Configured RBAC rules to allow the csrapprover controller automatically approve CSRs from a Node Bootstrap Token\n' +
                            '[bootstrap-token] Configured RBAC rules to allow certificate rotation for all node client certificates in the cluster\n' +
                            '[bootstrap-token] Creating the "cluster-info" ConfigMap in the "kube-public" namespace\n' +
                            '[kubelet-finalize] Updating "/etc/kubernetes/kubelet.conf" to point to a rotatable kubelet client certificate and key\n' +
                            '\n' +
                            'Your Kubernetes control-plane has initialized successfully!\n' +
                            '\n' +
                            'To start using your cluster, you need to run the following as a regular user:\n' +
                            '\n' +
                            '  mkdir -p $HOME/.kube\n' +
                            '  sudo cp -i /etc/kubernetes/admin.conf $HOME/.kube/config\n' +
                            '  sudo chown $(id -u):$(id -g) $HOME/.kube/config\n' +
                            '\n' +
                            'Alternatively, if you are the root user, you can run:\n' +
                            '\n' +
                            '  export KUBECONFIG=/etc/kubernetes/admin.conf\n' +
                            '\n' +
                            'You should now deploy a pod network to the cluster.\n' +
                            'Run "kubectl apply -f [podnetwork].yaml" with one of the options listed at:\n' +
                            '  https://kubernetes.io/docs/concepts/cluster-administration/addons/\n' +
                            '\n' +
                            'Then you can join any number of worker nodes by running the following on each as root:\n' +
                            '\n' +
                            'kubeadm join 192.168.1.10:6443 --token hj8464.9666gptcypm2gjuv \\\n' +
                            '        --discovery-token-ca-cert-hash sha256:9c7e99956d8d0439243b73d8315030dc857cb257c0d11dd09a8fa9e134b8308e\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Optional comanda <b>kubeadm init </b> permite specificarea optiunii <b>--cri-socket</b> (pentru Docker este /run/containerd/containerd.sock):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubeadm init --skip-phases=addon/coredns --skip-phases=addon/kube-proxy --ignore-preflight-errors=NumCPU --cri-socket /run/cri-dockerd.sock'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>5. Configurare kubectl</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir -p $HOME/.kube\n' +
                            'sudo cp -f /etc/kubernetes/admin.conf $HOME/.kube/config\n' +
                            'sudo chown $(id -u):$(id -g) $HOME/.kube/config'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Verificare stare cluster</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl cluster-info'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Kubernetes control plane is running at https://192.168.1.10:6443\n' +
                            'CoreDNS is running at https://192.168.1.10:6443/api/v1/namespaces/kube-system/services/kube-dns:dns/proxy\n' +
                            '\n' +
                            'To further debug and diagnose cluster problems, use \'kubectl cluster-info dump\'.\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Afisare noduri</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'export KUBECONFIG=/etc/kubernetes/kubelet.conf'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubectl get nodes'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                 STATUS   ROLES           AGE    VERSION\n' +
                            'nas-ubuntu-manager   Ready    control-plane   3m6s   v1.26.1\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>1. common.sh (CRI-O)</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'#!/bin/bash\n' +
                            '#\n' +
                            '# Common setup for all servers (Control Plane and Nodes)\n' +
                            '\n' +
                            'set -euxo pipefail\n' +
                            '\n' +
                            '# Variable Declaration\n' +
                            '\n' +
                            'KUBERNETES_VERSION="1.26.1-00"\n' +
                            '\n' +
                            '# disable swap\n' +
                            'sudo swapoff -a\n' +
                            '\n' +
                            '# keeps the swaf off during reboot\n' +
                            '(crontab -l 2>/dev/null; echo "@reboot /sbin/swapoff -a") | crontab - || true\n' +
                            'sudo apt-get update -y\n' +
                            '\n' +
                            '\n' +
                            '# Install CRI-O Runtime\n' +
                            '\n' +
                            'OS="xUbuntu_20.04"\n' +
                            '\n' +
                            'VERSION="1.23"\n' +
                            '\n' +
                            '# Create the .conf file to load the modules at bootup\n' +
                            'cat <<EOF | sudo tee /etc/modules-load.d/crio.conf\n' +
                            'overlay\n' +
                            'br_netfilter\n' +
                            'EOF\n' +
                            '\n' +
                            'sudo modprobe overlay\n' +
                            'sudo modprobe br_netfilter\n' +
                            '\n' +
                            '# Set up required sysctl params, these persist across reboots.\n' +
                            'cat <<EOF | sudo tee /etc/sysctl.d/99-kubernetes-cri.conf\n' +
                            'net.bridge.bridge-nf-call-iptables  = 1\n' +
                            'net.ipv4.ip_forward                 = 1\n' +
                            'net.bridge.bridge-nf-call-ip6tables = 1\n' +
                            'EOF\n' +
                            '\n' +
                            'sudo sysctl --system\n' +
                            '\n' +
                            'cat <<EOF | sudo tee /etc/apt/sources.list.d/devel:kubic:libcontainers:stable.list\n' +
                            'deb https://download.opensuse.org/repositories/devel:/kubic:/libcontainers:/stable/$OS/ /\n' +
                            'EOF\n' +
                            'cat <<EOF | sudo tee /etc/apt/sources.list.d/devel:kubic:libcontainers:stable:cri-o:$VERSION.list\n' +
                            'deb http://download.opensuse.org/repositories/devel:/kubic:/libcontainers:/stable:/cri-o:/$VERSION/$OS/ /\n' +
                            'EOF\n' +
                            '\n' +
                            'curl -L https://download.opensuse.org/repositories/devel:kubic:libcontainers:stable:cri-o:$VERSION/$OS/Release.key | sudo apt-key --keyring /etc/apt/trusted.gpg.d/libcontainers.gpg add -\n' +
                            'curl -L https://download.opensuse.org/repositories/devel:/kubic:/libcontainers:/stable/$OS/Release.key | sudo apt-key --keyring /etc/apt/trusted.gpg.d/libcontainers.gpg add -\n' +
                            '\n' +
                            'sudo apt-get update\n' +
                            'sudo apt-get install cri-o cri-o-runc -y\n' +
                            '\n' +
                            'sudo systemctl daemon-reload\n' +
                            'sudo systemctl enable crio --now\n' +
                            '\n' +
                            'echo "CRI runtime installed susccessfully"\n' +
                            '\n' +
                            '# Install kubelet, kubectl and Kubeadm\n' +
                            '\n' +
                            'sudo apt-get update\n' +
                            'sudo apt-get install -y apt-transport-https ca-certificates curl\n' +
                            'sudo curl -fsSLo /usr/share/keyrings/kubernetes-archive-keyring.gpg https://packages.cloud.google.com/apt/doc/apt-key.gpg\n' +
                            '\n' +
                            'echo "deb [signed-by=/usr/share/keyrings/kubernetes-archive-keyring.gpg] https://apt.kubernetes.io/ kubernetes-xenial main" | sudo tee /etc/apt/sources.list.d/kubernetes.list\n' +
                            'sudo apt-get update -y\n' +
                            'sudo apt-get install -y kubelet="$KUBERNETES_VERSION" kubectl="$KUBERNETES_VERSION" kubeadm="$KUBERNETES_VERSION"\n' +
                            'sudo apt-get update -y\n' +
                            'sudo apt-get install -y jq\n' +
                            '\n' +
                            'local_ip="$(ip --json a s | jq -r \'.[] | if .ifname == "eth1" then .addr_info[] | if .family == "inet" then .local else empty end else empty end\')"\n' +
                            'cat > /etc/default/kubelet << EOF\n' +
                            'KUBELET_EXTRA_ARGS=--node-ip=$local_ip\n' +
                            'EOF'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru <b>containerd</b>:
                    <SyntaxHighlighter howLineNumbers={true} language="python" style={androidstudio}>
                        {'cat <<EOF | sudo tee /etc/modules-load.d/containerd.conf\n' +
                            'overlay\n' +
                            'br_netfilter\n' +
                            'EOF\n' +
                            '\n' +
                            'sudo modprobe overlay\n' +
                            'sudo modprobe br_netfilter\n' +
                            '\n' +
                            '# Setup required sysctl params, these persist across reboots.\n' +
                            'cat <<EOF | sudo tee /etc/sysctl.d/99-kubernetes-cri.conf\n' +
                            'net.bridge.bridge-nf-call-iptables  = 1\n' +
                            'net.ipv4.ip_forward                 = 1\n' +
                            'net.bridge.bridge-nf-call-ip6tables = 1\n' +
                            'EOF\n' +
                            '\n' +
                            '# Apply sysctl params without reboot\n' +
                            'sudo sysctl --system\n' +
                            '\n' +
                            '#Install and configure containerd \n' +
                            'curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo apt-key add -\n' +
                            'sudo add-apt-repository "deb [arch=amd64] https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable"\n' +
                            'sudo apt update -y\n' +
                            'sudo apt install -y containerd.io\n' +
                            'sudo mkdir -p /etc/containerd\n' +
                            'containerd config default | sudo tee /etc/containerd/config.toml\n' +
                            '\n' +
                            '#Start containerd\n' +
                            'sudo systemctl restart containerd\n' +
                            'sudo systemctl enable containerd'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter howLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubeadm config images pull --image-repository=registry.k8s.io --cri-socket unix:///run/containerd/containerd.sock --kubernetes-version v1.26.0'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>2. master.sh (CRI-O)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'#!/bin/bash\n' +
                            '#\n' +
                            '# Setup for Control Plane (Master) servers\n' +
                            '\n' +
                            'set -euxo pipefail\n' +
                            '\n' +
                            'MASTER_IP="192.168.1.10"\n' +
                            'NODENAME=$(hostname -s)\n' +
                            'POD_CIDR="192.168.0.0/16"\n' +
                            '\n' +
                            'sudo kubeadm config images pull  --cri-socket /var/run/crio/crio.sock\n'+
                        'echo "Preflight Check Passed: Downloaded All Required Images"\n' +
                            '\n' +
                            'sudo kubeadm init --apiserver-advertise-address=$MASTER_IP \\\n' +
                            '    --apiserver-cert-extra-sans=$MASTER_IP \\\n' +
                            '    --pod-network-cidr=$POD_CIDR \\\n' +
                            '    --node-name "$NODENAME" \\\n' +
                            '    --ignore-preflight-errors=NumCPU --cri-socket /var/run/crio/crio.sock\n' +
                            '\n' +
                            'mkdir -p "$HOME"/.kube\n' +
                            'sudo cp -i /etc/kubernetes/admin.conf "$HOME"/.kube/config\n' +
                            'sudo chown "$(id -u)":"$(id -g)" "$HOME"/.kube/config\n'+
                        '# Install Claico Network Plugin Network \n' +
                            '\n' +
                            'curl https://docs.projectcalico.org/manifests/calico.yaml -O\n' +
                            '\n' +
                            'kubectl apply -f calico.yaml\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Rezumat:
                    <ul>
                        <li>git clone https://github.com/techiescamp/kubeadm-scripts</li>
                        <li>sudo ./common.sh</li>
                        <li>sudo ./master.sh</li>
                        <li>export KUBECONFIG=/etc/kubernetes/kubelet.conf</li>
                        <li>
                            test:
                            <br/>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'sudo kubectl get nodes'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>Join noduri worker</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' sudo kubeadm join 192.168.1.10:6443 --token wpanby.4g1qfzu7oeqmjs63 --discovery-token-ca-cert-hash sha256:3fd4cdc6e3031ffa32cce861ceac2c9db81a00483fc1a98f32868cf509799eea --cri-socket unix:///var/run/containerd/containerd.sock'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubeadm join 192.168.1.10:6443 --token wpanby.4g1qfzu7oeqmjs63 --discovery-token-ca-cert-hash sha256:3fd4cdc6e3031ffa32cce861ceac2c9db81a00483fc1a98f32868cf509799eea --cri-socket unix:///var/run/crio/crio.sock'}
                    </SyntaxHighlighter>

                    se poate afisa:
                    <SyntaxHighlighter>
                        {'--cri-socket unix:///var/run/containerd/containerd.sock\n' +
                            '[preflight] Running pre-flight checks\n' +
                            '[preflight] Reading configuration from the cluster...\n' +
                            '[preflight] FYI: You can look at this config file with \'kubectl -n kube-system get cm kubeadm-config -o yaml\'\n' +
                            '[kubelet-start] Writing kubelet configuration to file "/var/lib/kubelet/config.yaml"\n' +
                            '[kubelet-start] Writing kubelet environment file with flags to file "/var/lib/kubelet/kubeadm-flags.env"\n' +
                            '[kubelet-start] Starting the kubelet\n' +
                            '[kubelet-start] Waiting for the kubelet to perform the TLS Bootstrap...\n' +
                            '\n' +
                            'This node has joined the cluster:\n' +
                            '* Certificate signing request was sent to apiserver and a response was received.\n' +
                            '* The Kubelet was informed of the new secure connection details.\n' +
                            '\n' +
                            'Run \'kubectl get nodes\' on the control-plane to see this node join the cluster.\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca crapa cu:
                    <SyntaxHighlighter>
                        {'--cri-socket unix:///var/run/containerd/containerd.sock\n' +
                            '[preflight] Running pre-flight checks\n' +
                            'error execution phase preflight: [preflight] Some fatal errors occurred:\n' +
                            '        [ERROR CRI]: container runtime is not running: output: time="2023-02-05T12:58:22+02:00" level=fatal msg="validate service connection: CRI v1 runtime API is not implemented for endpoint \\"unix:///var/run/containerd/containerd.sock\\": rpc error: code = Unimplemented desc = unknown service runtime.v1.RuntimeService"\n' +
                            ', error: exit status 1\n' +
                            '[preflight] If you know what you are doing, you can make a check non-fatal with `--ignore-preflight-errors=...`\n' +
                            'To see the stack trace of this error execute with --v=5 or higher\n'}
                    </SyntaxHighlighter>
                    o posibila solutie este:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo rm /etc/containerd/config.toml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'systemctl restart containerd'}
                    </SyntaxHighlighter>
                    <hr/>
                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubectl get nodes'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                 STATUS   ROLES           AGE     VERSION\n' +
                            'nas-ubuntu-kj        Ready    <none>          5m45s   v1.26.1\n' +
                            'nas-ubuntu-manager   Ready    control-plane   25m     v1.26.1\n'}
                    </SyntaxHighlighter>

                    Afisare mai multe detalii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubectl get nodes -o wide'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME                 STATUS   ROLES           AGE   VERSION   INTERNAL-IP    EXTERNAL-IP   OS-IMAGE             KERNEL-VERSION      CONTAINER-RUNTIME\n' +
                            'nas-ubuntu-kj        Ready    <none>          46m   v1.26.1   192.168.1.6    <none>        Ubuntu 22.04.1 LTS   5.15.0-56-generic   containerd://1.6.8\n' +
                            'nas-ubuntu-manager   Ready    control-plane   65m   v1.26.1   192.168.1.10   <none>        Ubuntu 22.04.1 LTS   5.15.0-58-generic   cri-o://1.23.5\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Testare Nod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose pod nginx --type=NodePort --port 80'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubectl get svc nginx'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME    TYPE       CLUSTER-IP       EXTERNAL-IP   PORT(S)        AGE\n' +
                            'nginx   NodePort   10.103.147.194   <none>        80:32096/TCP   21s\n'}
                    </SyntaxHighlighter>

                    In mod implicit, pe nodul cu control panel nu se pot deploya containere din motive de securitate:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo  kubectl describe nodes | grep -i taint'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Taints:             <none>\n' +
                            'Taints:             node-role.kubernetes.io/control-plane:NoSchedule\n'}
                    </SyntaxHighlighter>

                    Pentru a sterge <b>taint</b>:
                    <SyntaxHighlighter>
                        {'kubectl taint nodes --all node-role.kubernetes.io/control-plane-'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl taint nodes --all node-role.kubernetes.io/master-'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge un nod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubectl drain --ignore-daemonsets nas-ubuntu-kj'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo kubectl uncordon nas-ubuntu-kj'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://github.com/techiescamp/kubeadm-scripts"}>Kubeadm Cluster Setup Scripts</a>
                       </li>
                    </ol>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://blog.kubesimplify.com/kubernetes-126"}>Kubernetes 1.26 - The electrifying release setup</a>
                        </li>
                    </ol>

                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://blog.kubesimplify.com/kubernetes-126"}>Kubernetes 1.26 - The electrifying release setup</a>
                        </li>
                    </ol>

                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://blog.kubesimplify.com/kubernetes-125-dockerd"}>Kubernetes 1.25 + Dockerd</a>
                        </li>
                    </ol>

                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubeadmK8sContent;