import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class VariablesNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-variables", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Variabile</b>

                    <br/>
                    <br/>

                    Tipuri de variabile:
                    <ul>
                        <li>variabile configurabile:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'set $var  \'valoare\'; '}
                            </SyntaxHighlighter>
                        </li>

                        <li>variabile module Nginx:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'$http, $uri, $args, $host, $arg_name'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'server{\n' +
                            '\tlocation /inspect {\n' +
                            '\t\treturn 200 \'$host\\n$uri\\n$arg\';\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca se apleeaza:
                    <br/>
                    <i>http://IP/inspect?a=b</i>
                    <br/>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'IP\n' +
                            'inspect\n' +
                            'a=b'}
                    </SyntaxHighlighter>

                    Pentru a obtine valoarea unui argument/parametru din url, cu numele <i>nume</i>:
                    <br/>
                    <b>$arg</b><i>_nume</i>
                    <br/>
                    exemplu: <i>http://IP/inspect?nume=ion</i>

                    <hr/>
                    <b>2. Conditii</b>
                    <br/>
                    <br/>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'if ( conditie ){\n' +
                         '\t....\n'+
                            '}'}
                    </SyntaxHighlighter>

                    Exemplul 1:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\tif ( $arg_apikey != 1234 ) {\n' +
                            '\t\t\treturn 401 "Incorect"\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /inspect {\n' +
                            '\t\t\treturn 200 "Name: $arg_name";\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplul 2:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\tset $weekend \'nu\';\n' +
                            '\t\t\n' +
                            '\t\tif ( $date_local ~ \'Saturday|Sunday\' ) {\n' +
                            '\t\t\tset $weekend \'da\'\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /is_weekend {\n' +
                            '\t\t\treturn 200 $weekend;\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VariablesNginxContent;