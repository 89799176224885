import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class CombiningConfigContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-combining-config", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Combinare fisiere de configurare</b>
                    <br/>
                    <br/>

                    Combinarea fisierelor de configurare se face folosind ca separator in acelasi fisier <b>---</b>.

                    <br/>
                    <br/>
                    De exemplu daca avem 2 fisiere:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: server-deployment\n' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: server\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: server\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: server\n' +
                            '          image: iulianbuzdugan/multi-server\n' +
                            '          ports:\n' +
                            '            - containerPort: 5000'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: server-cluster-ip-service\n' +
                            'spec:\n' +
                            '  type: ClusterIP\n' +
                            '  selector:\n' +
                            '    component: server\n' +
                            '  ports:\n' +
                            '    - port: 5000\n' +
                            '      targetPort: 5000\n'}
                    </SyntaxHighlighter>

                    acestea se pot combina in acealsi fisier, avand urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: server-deployment\n' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: server\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: server\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: server\n' +
                            '          image: iulianbuzdugan/multi-server\n' +
                            '          ports:\n' +
                            '            - containerPort: 5000\n' +
                            '---\n' +
                            'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: server-cluster-ip-service\n' +
                            'spec:\n' +
                            '  type: ClusterIP\n' +
                            '  selector:\n' +
                            '    component: server\n' +
                            '  ports:\n' +
                            '    - port: 5000\n' +
                            '      targetPort: 5000\n'
                        }
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CombiningConfigContent;