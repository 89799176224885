import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class PackagesPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-packages", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Pachete</b>
                    <br/> <br/>

                    Un <b>modul</b> este un container de entitatii. <br/>

                    Un <b>pachet</b> este un container de module. <br/>

                    Deci, un <i>modul</i> este conceput pentru a aduna entități înrudite (funcții, variabile, constante etc.), iar un <i>pachet</i> este un container care stange/structureaza mai multe module înrudite sub un nume comun.
                    <hr/>
                    <b>1. Creare module proprii</b>
                    <br/>
                    <br/>
                    Un <b>modul este un fișier care conține definiții și declarații</b> Python. <b>Numele fișierului</b> este <b>numele modulului cu extensia .py</b>.
                    În cadrul unui modul, <b>numele modulului</b> (sub formă de șir) este disponibil ca valoare a variabilei globale <b>__name__</b>.
                    Daca nu este apleat in cadrul unui modul, variabila gloala <b>__name__</b> va avea valoarea <b>__main__</b>

                    <br/>
                    <br/>
                    Sa presupunem ca suntem in directorul <i>X</i> si avem fisierul <i>test_module.py</i> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import lectie_modul.primul_modul as lm\n' +
                        '\n' +
                        'print("Sunt:", __name__)\n' +
                        '\n' +
                        'print(lm.adunare(1, 2))'}
                    </SyntaxHighlighter>
                    Daca in directorul <i>X</i>, avem un alt director, numit <i>lectie_modul</i> si in acest director avem fisierul <i>primul_modul.py</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("Modul:", __name__)\n' +
                        '\n' +
                        'def adunare(a, b):\n' +
                        '    return a + b\n'}
                    </SyntaxHighlighter>
                    Atunci daca rulam fisierul <i>test_module.py</i>, se vor afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Modul: lectie_modul.primul_modul\n' +
                        'Sunt: __main__\n' +
                        '3\n' +
                        '\n' +
                        'Process finished with exit code 0'}
                    </SyntaxHighlighter>

                    Pentru a putea face distincia între momentul în care fișierul este încărcat ca modul și când este rulat ca script de sine stătător, atunci se poate face urmatoare verificare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if (__name__ == \'__main__\'):'}
                    </SyntaxHighlighter>

                    <b>În timpul primului import al unui modulul</b>, Python își traduce codul sursă în <b>formatul semi-compilat</b> stocat în fișierele <b>pyc</b> și pune aceste fișiere în directorul <b>__pycache__</b> în directorul modulului.
                    <br/>
                    <br/>

                    Deci, in directorul <i>lectie_modul</i> vom gasi, dupa rulare, si directorul generat <i>__pycache__</i>, in care vom gasi fisierul <i>primul_modul.cpython-39.pyc</i> (numele variaza, in functie de implementarea si versiunea Python).
                    Daca sunteti curiosi, continutul acestui fisier contine urmatorii monstrii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a\n' +
                        '    ®‚–aE   ã                   @   s   e d eƒ dd„ ZdS )zModul:c                 C   s\b   | | S )N© )ÚaÚbr   r   úCD:\\work\\python-vanilla\\src\\main\\python\\lectie_modul\\primul_modul.pyÚadunare   s    r   N)ÚprintÚ\b__name__r   r   r   r   r   Ú\b<module>   s   \n' +
                        ''}
                    </SyntaxHighlighter>


                    Python are o lista numita <b>path</b> (acesibila din modulul <b>sys</b>), unde pastreaza locatiile de unde poate face import: <b>lista de directoare de import</b>.
                    Pentru a adauga o cale noua (de exemplu, cand vrea sa importam un pachet scris de noi), putem folosi metodele <b>insert()</b> sau <b>append()</b>

                    <hr/>
                    <b>2. Creare pachete</b>
                    <br/> <br/>
                    Fiecare director care contine un pachet <b>trebuie</b> sa aiba un fisier Python numit <b>__init__.py</b>:
                    <ul>
                        <li>este <b>rulat implicit</b> atunci când un pachet care îl conține este importat și este folosit pentru a <b>inițializa</b> un pachet și/sau sub-pachetele acestuia (dacă există).
                        </li>
                        <li>fișierul poate fi <b>gol</b>, <b>dar nu trebuie să lipsească</b></li>
                    </ul>

                    Un pachet poate fi distribuit ca:
                    <ul>
                        <li>o structura (arborescenta) de fisiere sursa</li>
                        <li>într-un fișier zip</li>
                    </ul>

                    De exemplu, daca avem urmatoare structura:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/python-3.png'}/>
                    </div>

                    Cu modul1.py:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def f1():\n' +
                        '    print("f1")'}
                    </SyntaxHighlighter>

                    Cu modul1.py:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def f2():\n' +
                        '    print("f2")\n'}
                    </SyntaxHighlighter>

                    Presupunem ca avem scriptul (<i>test_pachet.py</i> pus in directorul unde este directorul <i>pkg</i>) urmatorul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import pkg.modul1 as pm1\n' +
                        'import pkg.modul2 as pm2\n' +
                        '\n' +
                        'pm1.f1() # f1\n' +
                        'pm2.f2() # f2'}
                    </SyntaxHighlighter>

                    Sa ne jucam un pic:
                    Presupunem ca avem scriptul (<i>test_pachet.py</i> pus in directorul unde este directorul <i>pkg</i>) urmatorul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import pkg.modul1 as pm1\n' +
                        'import pkg.modul2 as pm2\n' +
                        '\n' +
                        'pm1.f1() # f1\n' +
                        'pm2.f2() # f2\n' +
                        '\n' +
                        'print(dir(pm1)) # [\'__builtins__\', \'__cached__\', \'__doc__\', \'__file__\', \'__loader__\', \'__name__\', \'__package__\', \'__spec__\', \'f1\']\n' +
                        'print(dir(pm2)) # [\'__builtins__\', \'__cached__\', \'__doc__\', \'__file__\', \'__loader__\', \'__name__\', \'__package__\', \'__spec__\', \'f2\']\n' +
                        '\n' +
                        'print(pm1) # <module \'pkg.modul1\' from \'D:\\\\work\\\\python-vanilla\\\\src\\\\main\\\\python\\\\pkg\\\\modul1.py\'>\n' +
                        '\n' +
                        'import pkg # <module \'pkg\' (namespace)>\n' +
                        'import pkg.modul1 # <module \'pkg.modul1\' from \'D:\\\\work\\\\python-vanilla\\\\src\\\\main\\\\python\\\\pkg\\\\modul1.py\'>'}
                    </SyntaxHighlighter>

                    Dacă punem in fisierul <b>__init__.py</b> în directorul de pachet <i>pkg</i>, acesta:
                    <ul>
                        <li> este invocat atunci când pachetul sau un modul din pachet este importat</li>
                        <li> poate fi folosit pentru executarea codului de inițializare a pachetului (cum ar fi inițializarea datelor la nivel de pachet)</li>
                    </ul>

                    Fie <b>__init__.py</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("Initializare pachet")\n' +
                        '\n' +
                        'limbaje = ["Python", "Java"]'}
                    </SyntaxHighlighter>

                    Presupunem ca avem scriptul (<i>test_pachet_2.py</i> pus in directorul unde este directorul <i>pkg</i>) urmatorul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import pkg # afiseaza Initializare pachet\n' +
                        '\n' +
                        'print(pkg.limbaje) # [\'Python\', \'Java\']\n' +
                        'print(dir(pkg)) # [\'__builtins__\', \'__cached__\', \'__doc__\', \'__file__\', \'__loader__\', \'__name__\', \'__package__\', \'__path__\', \'__spec__\', \'limbaje\']'}
                    </SyntaxHighlighter>

                    Fisierul de initializare <b>__init__.py</b> ruleaza o singura data:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import pkg # afiseaza Initializare pachet\n' +
                        'import pkg # nu mai afiseaza nimic\n' +
                        'import pkg # u mai afiseaza nimic'}
                    </SyntaxHighlighter>



                    <hr/>
                    <b>Observatie 1:</b><br/>
                    In Python, <b>nu exista entiatati private</b>, dar exista o conventie/recomandare, ca acesta ca aiba prefixul: <b>_ sau __</b>.

                    <hr/>
                    <b>Observatie 2:</b><br/>
                    <b>#!</b> (cunoscut sub numele de: shabang, shebang, hasbang, poundbang și hashpling)- scris pe prima linie in cadrul unui fisier/script Python este folosit de sistemele de operare asemănătoare Unix pentru a rula fișierul sursă Python (in Windows nu are nici un efect).

                    <hr/>
                    <b>Observatie 3:</b><br/>
                    O mare parte din documentația Python afirmă că un __init__.py fișier trebuie să fie prezent în directorul pachetului atunci când se creează un pachet. Acest lucru a fost odată adevărat. Pe vremuri, însăși prezența lui __init__.py indica lui Python că un pachet era în curs de definire. Fișierul putea conține cod de inițializare sau chiar poate fi gol, dar trebuia să fie prezent.
                    <br/>
                    Incepând cu Python 3.3 , au fost introduse pachetele de spații de nume implicite. Acestea permit crearea unui pachet fără niciun __init__.py fișier. Desigur, poate fi încă prezent dacă este necesară inițializarea pachetului. Dar nu mai este necesar.

                    <hr/>
                    <b>Observatie 4:</b><br/>
                    Dacă fisierul <b>__init__.py</b> din directorul pachetului conține o listă numită <b>__all__</b>, atunci aceasta lista este considerată o listă de module care ar trebui importate atunci când este intalnita instructiunea <b>from [package_name] import *</b>.
                    Fie <b>__init__.py</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("Initializare pachet")\n' +
                        '\n' +
                        'limbaje = ["Python", "Java"]\n' +
                        '\n' +
                        '__all__ = [\n' +
                        '    \'modul1\',\n' +
                        '    \'modul2\'\n' +
                        ']'}
                    </SyntaxHighlighter>
                    O sa avem:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from pkg import *\n' +
                        'print(dir()) # [\'__annotations__\', \'__builtins__\', \'__cached__\', \'__doc__\', \'__file__\', \'__loader__\', \'__name__\', \'__package__\', \'__spec__\', \'modul1\', \'modul2\']'}
                    </SyntaxHighlighter>

                    Lista <b>__all__</b> poate fi folosita atât de pachete, cât și de module pentru a controla ceea ce este importat când <b>import *</b> este specificat. Dar comportamentul implicit diferă :
                    <ul>
                        <li>pentru un pachet, când <b>__all__</b> p nu este definit, import * nu importă nimic</li>
                        <li>pentru un modul, când <b>__all__</b> p nu este definit, import * importă totul (cu excepți, numele care încep cu un caracter de subliniere)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'__all__= ["f1"]\n' +
                                '\n' +
                                'def f1():\n' +
                                '    print("f1")\n'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://realpython.com/python-modules-packages/"}>Python Modules and Packages – An Introduction</a>
                            </div>
                        </li>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PackagesPythonContent;