import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class IdeModelsLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-ide-models", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. IDE Helper Generator: php artisan ide:models</b>
                    <br/>
                    <br/>
                    Acest pachet generează fișiere de ajutor care permit IDE-ului să ofere completare automată precisă.
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php -d memory_limit=-1 C:/tools/composer/composer.phar require --dev barryvdh/laravel-ide-helper'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan ide:models'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan ide-helper:models'}
                    </SyntaxHighlighter>

                    Exemplu (pentru a genera doar pentru un model anume; daca nu se specifica un model anume, se genereaza pentru toate modelele):
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan ide-helper:models "App\\Models\\Category"'}
                    </SyntaxHighlighter>

                    In clasa model Category, inainte de definirea clasei se vor genera urmatoarele linii:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'/**\n' +
                            ' * App\\Models\\Category\n' +
                            ' *\n' +
                            ' * @property int $id\n' +
                            ' * @property string $name\n' +
                            ' * @property string $slug\n' +
                            ' * @property int|null $parent_id\n' +
                            ' * @property int $visible\n' +
                            ' * @property int $level\n' +
                            ' * @property int $position\n' +
                            ' * @property string $pictogram\n' +
                            ' * @property \\Illuminate\\Support\\Carbon|null $created_at\n' +
                            ' * @property \\Illuminate\\Support\\Carbon|null $updated_at\n' +
                            ' * @method static \\Database\\Factories\\CategoryFactory factory(...$parameters)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category newModelQuery()\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category newQuery()\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category query()\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereCreatedAt($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereId($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereLevel($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereName($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereParentId($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category wherePictogram($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category wherePosition($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereSlug($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereUpdatedAt($value)\n' +
                            ' * @method static \\Illuminate\\Database\\Eloquent\\Builder|Category whereVisible($value)\n' +
                            ' * @mixin \\Eloquent\n' +
                            ' */'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IdeModelsLaravelContent;