import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DecisionsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-decisions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Instructiunea if</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'if (conditie){\n' +
                        '...\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Daca exista o singura instructiune se poate scrie si asa (nerecomandat):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'if (conditie) instructiune;'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Instructiunea while</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'while (conditie){\n' +
                        '...\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Daca exista o singura instructiune se poate scrie si asa (nerecomandat):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'while (conditie) instructiune;'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Instructiunea do-while</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'do{\n' +
                        '...\n' +
                        '}while(conditie);'}
                    </SyntaxHighlighter>

                    Daca exista o singura instructiune se poate scrie si asa (nerecomandat):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'do instructiune; while (conditie);'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    Dupa <b>while (conditie)</b> se pune <b>punct si virgula (;)</b>!

                    <hr/>
                    <b>4. Instructiunea for</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'for(instructiuni_initializare;conditie;instructiuni_incrementare){\n' +
                            '...\n'+
                        '}\n'}
                    </SyntaxHighlighter>

                    Daca exista o singura instructiune se poate scrie si asa (nerecomandat):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'for(instructiuni_initializare;conditie;instructiuni_incrementare) instructiune'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    <ul>
                        <li>instructiuni_initializare e optional
                            <br/>
                            toate declararile trebuie sa fie de acelasi tip:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'for(int a=0, b=2;;){} // CORECT'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'for(int a=0, b=2, c=a+b;;){} // CORECT'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'for(int a=0, long b=2 ;;){} // INCORECT'}
                            </SyntaxHighlighter>
                        </li>
                        <li>conditie e optional (daca lipseste se considera ca fiind <b>true</b>)</li>
                        <li>instructiuni_incrementare e optional</li>
                    </ul>

                    <hr/>
                    <b>5. Instructiunea for-each</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'for(Tip instanta: colectie){...}'}
                    </SyntaxHighlighter>

                    Daca exista o singura instructiune se poate scrie si asa (nerecomandat):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'for(Tip instanta: colectie) instructiune;'}
                    </SyntaxHighlighter>

                    Colectie:
                    <ul>
                        <li>array</li>
                        <li>obiect care implementeaza <b>java.lang.Iterable</b></li>
                    </ul>

                    <hr/>
                    <b>6. Etichete (labels) - nerecomandat</b>
                    <br/>
                    <br/>

                    O instructiune/bloc for, for-each, while, do-while, switch poate fi marcata cu o eticheta (label).
                    Aceasta eticheta este un identificator.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'eticheta:instructiune'}
                    </SyntaxHighlighter>

                    Exemplul 1:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'inceput: System.out.print("START");'}
                    </SyntaxHighlighter>

                    Exemplul 2:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'LX: for(;;) LY:System.out.println("x");'}
                    </SyntaxHighlighter>

                    Exemplul 3:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '        \n' +
                        '        L0:{\n' +
                        '            L1:\n' +
                        '            System.out.println("L1");\n' +
                        '            L2:\n' +
                        '            if (true){\n' +
                        '                break L0;\n ' +
                        '            }\n' +
                        '            System.out.println("L2");\n' +
                        '            L3:\n' +
                        '            System.out.println("L3");\n' +
                        '        }\n' +
                        '        // se va afisa doar L1\n' +
                        '\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Exemplul 4:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        L0:{\n' +
                        '            L1:\n' +
                        '            System.out.println("L1");\n' +
                        '            L2:\n' +
                        '            System.out.println("L2");\n' +
                        '            L3:\n' +
                        '            System.out.println("L3");\n' +
                        '            break L0; // pentru continue trebuie sa fie un label care marcheaza un LOOP\n' +
                        '        }\n' +
                        '\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Instructiunea switch</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'switch (variabilaDeTest){\n' +
                        '   case expresieConstanta1:\n' +
                        '       instructiuni1;\n' +
                        '       break;\n' +
                        '   case expresieConstanta2:\n' +
                        '       instructiuni2;\n' +
                        '       break;\n' +
                        '   case expresieConstantaN:\n' +
                        '       instructiuniN;\n' +
                        '       break;\n' +
                        '   default:\n' +
                        '       System.out.println("default");\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Tipul variabilei <i>variabilaDeTest</i> poate fi:
                    <ul>
                        <li>int / Integer</li>
                        <li>char / Character</li>
                        <li>byte / Byte</li>
                        <li>short / Short</li>
                        <li>String</li>
                        <li>enum</li>
                    </ul>

                    Tipul variabilei <i>variabilaDeTest</i> nu poate fi:
                    <ul>
                        <li>boolean</li>
                        <li>long</li>
                        <li>float</li>
                        <li>double</li>
                    </ul>

                    <b>Aceasta instructiune functioneaza in felul urmator</b>:
                    <ul>
                        <li><i>variabilaDeTest</i> este evaluata o singura data</li>
                        <li><i>variabilaDeTest</i> este comparata cu valorile fiecarui <b>case</b></li>
                        <li>daca valoarea <i>variabilaDeTest</i> este egala cu valoarea unui <b>case</b> se executa blocul asociat

                            <br/>
                            <b>Un bloc poate fi asociat la mai multe case-uri:</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'int expresie = 1;\n' +
                                '\n' +
                                'switch (expresie){\n' +
                                '   case 1:\n' +
                                '   case 2:\n' +
                                '       System.out.println("2"); // se va afisa 2\n' +
                                '       break;\n' +
                                '   case 3:\n' +
                                '       System.out.println("N");\n' +
                                '       break;\n' +
                                '   default:\n' +
                                '       System.out.println("default");\n' +
                                '       break;\n' +
                                '}'}

                            </SyntaxHighlighter>

                            Daca valoarea <i>variabilaDeTest</i> este egala cu valoarea unui <b>case</b> se executa blocul asociat, &nbsp;
                            <b>dar se restul de break-uri pana la primul <b>break</b></b>
                            <br/>
                            In exemplul de mai jos se afiseaza si 1 si 2:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'int expresie = 1;\n' +
                                '\n' +
                                'switch (expresie){\n' +
                                '   case 1:\n' +
                                '       System.out.println("1");\n' +
                                '   case 2:\n' +
                                '       System.out.println("2"); // se afiseaza 1, 2\n' +
                                '       break;\n' +
                                '   case 3:\n' +
                                '       System.out.println("N");\n' +
                                '       break;\n' +
                                '   default:\n' +
                                '       System.out.println("default");\n' +
                                '       break;\n' +
                                '}'}

                            </SyntaxHighlighter>

                        </li>
                        <li>cand se ajunge la un <b>break</b> se iese din <b>switch</b></li>
                        <li>
                            daca valoarea <i>variabilaDeTest</i> NU este egala cu nici o valoare a unui <b>case</b> se executa blocul asociat cu <b>default</b>, daca exista:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'int expresie = 10;\n' +
                                '\n' +
                                'switch (expresie){\n' +
                                '   default:\n' +
                                '        System.out.println("default");\n' +
                                '   case 1:\n' +
                                '       System.out.println("1");\n' +
                                '   case 2:\n' +
                                '       System.out.println("2"); // se afiseaza: default, 1, 2\n' +
                                '       break;\n' +
                                '   case 3:\n' +
                                '       System.out.println("N");\n' +
                                '       break;\n' +
                                '\n' +
                                '}'}

                            </SyntaxHighlighter>

                            Ramura <b>default</b>:
                            <ul>
                                <li>poate fi oriunde in instructiunea <b>switch</b></li>
                                <li>este optionala</li>
                                <li>poate exista maxim o ramura <b>default</b></li>
                                <li>recomandat, este sa fie ultima ramura</li>
                            </ul>

                        </li>
                    </ul>

                    <b>Observatii</b>:
                    <ul>
                        <li>cuvintele cheie: break, case, default sunt optionale:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'switch (expresie){} // e ok'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>8. Instructiunea continue</b>
                    <br/>
                    <br/>

                    Aceasta instructiune forteaza ignorarea instructiunilor de dupa el dintr-un bloc for, for-each, while, do-while, switch.
                    Daca este specificat si un label dupa acesta (break label), programul isi va relua instructiunile <b>incepand</b> cu blocul marcat cu label-ul specificat.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions3 {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        System.out.println("inainte de A");\n' +
                        '        A:for(int i=0;i<2;i++){\n' +
                        '            System.out.println("inainte de B");\n' +
                        '            B:for(int k=0;k<5; k++) {\n' +
                        '                if (k == 1) {\n' +
                        '                    System.out.println("continue A");\n' +
                        '                    continue A;\n' +
                        '                }\n' +
                        '                System.out.println(i+" "+k);\n' +
                        '            }\n' +
                        '        }\n' +
                        '\n' +
                        '        // se va afisa:\n' +
                        '        //        inainte de A\n' +
                        '        //        inainte de B\n' +
                        '        //        0 0\n' +
                        '        //        continue A\n' +
                        '        //        inainte de B\n' +
                        '        //        1 0\n' +
                        '        //        continue A \n' +
                        '\n' +
                        '\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        System.out.println("inainte de A");\n' +
                        '        A:for(int i=0;i<2;i++){\n' +
                        '            System.out.println("inainte de B");\n' +
                        '            B:for(int k=0;k<5; k++) {\n' +
                        '                if (k == 1) {\n' +
                        '                    System.out.println("continue B");\n' +
                        '                    continue B;\n' +
                        '                }\n' +
                        '                System.out.println(i+" "+k);\n' +
                        '            }\n' +
                        '        }\n' +
                        '\n' +
                        '        // se va afisa:\n' +
                        '        //        inainte de A\n' +
                        '        //        inainte de B\n' +
                        '        //        0 0\n' +
                        '        //        continue B\n' +
                        '        //        0 2\n' +
                        '        //        0 3\n' +
                        '        //        0 4\n' +
                        '        //        inainte de B\n' +
                        '        //        1 0\n' +
                        '        //        continue B\n' +
                        '        //        1 2\n' +
                        '        //        1 3\n' +
                        '        //        1 4\n' +
                        '\n' +
                        '\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Instructiunea break</b>
                    <br/>
                    <br/>
                    Aceasta instructiune forteaza iesirea dintr-un for, for-each, while, do-while, switch.
                    Daca este specificat si un label dupa acesta (break label), programul isi va relua instructiunile <b>dupa</b> blocul marcat cu label-ul specificat.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        A:for(int i=0;i<5;i++){\n' +
                        '            System.out.println(i);\n' +
                        '            if (i==2){\n' +
                        '                break A;\n' +
                        '            }\n' +
                        '        }\n' +
                        '\n' +
                        '        // se va afisa: 0,1,2\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        A:for(int i=0;i<2;i++){\n' +
                        '            B:for(int k=0;k<5; k++) {\n' +
                        '                System.out.println(i+" "+k);\n' +
                        '                if (k == 1) {\n' +
                        '                    break B;\n' +
                        '                }\n' +
                        '            }\n' +
                        '        }\n' +
                        '\n' +
                        '        // se va afisa:\n' +
                        '        //        0 0\n' +
                        '        //        0 1\n' +
                        '        //        1 0\n' +
                        '        //        1 1\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Decisions {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        A:for(int i=0;i<2;i++){\n' +
                        '            B:for(int k=0;k<5; k++) {\n' +
                        '                System.out.println(i+" "+k);\n' +
                        '                if (k == 1) {\n' +
                        '                    break A;\n' +
                        '                }\n' +
                        '            }\n' +
                        '        }\n' +
                        '\n' +
                        '        // se va afisa:\n' +
                        '        //        0 0\n' +
                        '        //        0 1\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Instructiunea return</b>
                    <br/>
                    <br/>
                    Aceasta instructiune forteaza iesirea dintr-o metoda.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.w3schools.com/java/java_switch.asp"}>Java Switch</a>
                            </div>
                        </li>
                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DecisionsJavaContent;