import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class SelectAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-select", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Select cu date statice</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<div class="row mb-3">\n' +
                            '          <label class="form-label"  for="field_sizeProduct">Size Product</label>\n' +
                            '          <select class="form-control" name="sizeProduct" formControlName="sizeProduct" id="field_sizeProduct" >\n' +
                            '            <option [ngValue]="null">Selecteaza dimenziune</option>\n' +
                            '            <option *ngFor="let size of sizeValues" [value]="size">{{ \'app.Size.\' + size | translate }}</option>\n' +
                            '          </select>\n' +
                            '          <div *ngIf="editForm.get(\'sizeProduct\')!.invalid && (editForm.get(\'sizeProduct\')!.dirty || editForm.get(\'sizeProduct\')!.touched)">\n' +
                            '            <small\n' +
                            '              class="form-text text-danger"\n' +
                            '              *ngIf="editForm.get(\'sizeProduct\')?.errors?.required"\n' +
                            '              jhiTranslate="entity.validation.required"\n' +
                            '            >\n' +
                            '              Campul este obligatoriu\n' +
                            '            </small>\n' +
                            '          </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li><i>editForm</i> este de tip <b>FormGroup</b>FormGroup</li>

                        <li>
                            Size:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export enum Size {\n' +
                                    '  S = \'S\',\n' +
                                    '\n' +
                                    '  M = \'M\',\n' +
                                    '\n' +
                                    '  L = \'L\',\n' +
                                    '\n' +
                                    '  XL = \'XL\',\n' +
                                    '\n' +
                                    '  XXL = \'XXL\',\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            sizeValues = Object.keys(Size);

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'sizeValues = Object.keys(Size)'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Select cu date dinamice din enum (key=value) cu directiva keyvalue</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'size = Size;\n' +
                            'sizeDefault: Size = Size.S;\n' +
                            'indexSize:number = Object.values(Size).indexOf(this.sizeDefault as unknown as Size);\n' +
                            'sizeDefaultValue = Object.keys(Size)[this.indexSize];'}
                    </SyntaxHighlighter>

                    apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'filterForm = this.formBuilder.group({\n' +
                            '  size: this.sizeDefaultValue\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SelectAngularContent showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<div class="form-group">\n' +
                            '  <!-- form-floating -->\n' +
                            '  <label class="my-1 mr-2" for="nivel_org">Dimensiune</label>\n' +
                            '  <select class="form-select" id="size" required formControlName="size" [value]="sizeDefaultValue">\n' +
                            '    <option [ngValue]="null" [disabled]="true">Selecteaza dimenziune</option>\n' +
                            '    <option *ngFor="let s of size | keyvalue" [value]="s.key">\n' +
                            '      {{s.value}}\n' +
                            '    </option>\n' +
                            '  </select>\n' +
                            '</div>'}
                    </SelectAngularContent>

                    <hr/>
                    <b>3. Select cu date dinamice</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<div class="row mb-3">\n' +
                            '          <label class="form-label" for="field_productCategory">Categorie produs</label>\n' +
                            '          <select\n' +
                            '            class="form-control"\n' +
                            '            id="field_productCategory"\n' +
                            '            name="productCategory"\n' +
                            '            formControlName="productCategory"\n' +
                            '            [compareWith]="compareProductCategory"\n' +
                            '          >\n' +
                            '            <option [ngValue]="null">Selecteaza o categorie</option>\n' +
                            '            <option [ngValue]="productCategoryOption" *ngFor="let productCategoryOption of productCategoriesSharedCollection">\n' +
                            '              {{ productCategoryOption.id }}\n' +
                            '            </option>\n' +
                            '          </select>\n' +
                            '        </div>'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            [compareWith]="compareProductCategory"
                            <br/>
                            compareProductCategory este o functie definita in componenta:
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'  compareProductCategory = (o1: IProductCategory | null, o2: IProductCategory | null): boolean =>\n' +
                                    '    this.productCategoryService.compareProductCategory(o1, o2);'}
                            </SyntaxHighlighter>

                            <i>this.compareProductCategory()</i> e definita in serviciul: <b>productCategoryService</b>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'  compareProductCategory(o1: Pick<IProductCategory, \'id\'> | null, o2: Pick<IProductCategory, \'id\'> | null): boolean {\n' +
                                    '    return o1 && o2 ? o1.id === o2.id : o1 === o2;\n' +
                                    '  }'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            productCategoriesSharedCollection:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'productCategoriesSharedCollection'}
                            </SyntaxHighlighter>

                            incarcare valori pentru categorii produs:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {' protected loadRelationshipsOptions(): void {\n' +
                                    '    this.productCategoryService\n' +
                                    '      .query()\n' +
                                    '      .pipe(map((res: HttpResponse<IProductCategory[]>) => res.body ?? []))\n' +
                                    '      .pipe(\n' +
                                    '        map((productCategories: IProductCategory[]) =>\n' +
                                    '          this.productCategoryService.addProductCategoryToCollectionIfMissing<IProductCategory>(\n' +
                                    '            productCategories,\n' +
                                    '            this.product?.productCategory\n' +
                                    '          )\n' +
                                    '        )\n' +
                                    '      )\n' +
                                    '      .subscribe((productCategories: IProductCategory[]) => (this.productCategoriesSharedCollection = productCategories));\n' +
                                    '  }'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'addProductCategoryToCollectionIfMissing<Type extends Pick<IProductCategory, \'id\'>>(\n' +
                                    '    productCategoryCollection: Type[],\n' +
                                    '    ...productCategoriesToCheck: (Type | null | undefined)[]\n' +
                                    '  ): Type[] {\n' +
                                    '    const productCategories: Type[] = productCategoriesToCheck.filter(isPresent);\n' +
                                    '    if (productCategories.length > 0) {\n' +
                                    '      const productCategoryCollectionIdentifiers = productCategoryCollection.map(\n' +
                                    '        productCategoryItem => this.getProductCategoryIdentifier(productCategoryItem)!\n' +
                                    '      );\n' +
                                    '      const productCategoriesToAdd = productCategories.filter(productCategoryItem => {\n' +
                                    '        const productCategoryIdentifier = this.getProductCategoryIdentifier(productCategoryItem);\n' +
                                    '        if (productCategoryCollectionIdentifiers.includes(productCategoryIdentifier)) {\n' +
                                    '          return false;\n' +
                                    '        }\n' +
                                    '        productCategoryCollectionIdentifiers.push(productCategoryIdentifier);\n' +
                                    '        return true;\n' +
                                    '      });\n' +
                                    '      return [...productCategoriesToAdd, ...productCategoryCollection];\n' +
                                    '    }\n' +
                                    '    return productCategoryCollection;\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'getProductCategoryIdentifier(productCategory: Pick<IProductCategory, \'id\'>): number {\n' +
                                    '    return productCategory.id;\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'import { HttpParams } from \'@angular/common/http\';\n' +
                                    '\n' +
                                    'export const createRequestOption = (req?: any): HttpParams => {\n' +
                                    '  let options: HttpParams = new HttpParams();\n' +
                                    '\n' +
                                    '  if (req) {\n' +
                                    '    Object.keys(req).forEach(key => {\n' +
                                    '      if (key !== \'sort\' && req[key]) {\n' +
                                    '        for (const value of [].concat(req[key]).filter(v => v !== \'\')) {\n' +
                                    '          options = options.append(key, value);\n' +
                                    '        }\n' +
                                    '      }\n' +
                                    '    });\n' +
                                    '\n' +
                                    '    if (req.sort) {\n' +
                                    '      req.sort.forEach((val: string) => {\n' +
                                    '        options = options.append(\'sort\', val);\n' +
                                    '      });\n' +
                                    '    }\n' +
                                    '  }\n' +
                                    '\n' +
                                    '  return options;\n' +
                                    '};\n'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'  query(req?: any): Observable<EntityArrayResponseType> {\n' +
                                    '    const options = createRequestOption(req);\n' +
                                    '    return this.http.get<IProductCategory[]>(this.resourceUrl, { params: options, observe: \'response\' });\n' +
                                    '  }'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SelectAngularContent;