import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"dpc", url:"#", title:"Creationale (C)", subtitle:"abstractizează modul de instantiere"},

        {id:"simple-factory", url:"/design-patterns/simple-factory", title:"C1. Simple Factory", subtitle:"", parent:"dpc"},
        // {id:"factory-method", url:"#/design-patterns/factory-method", title:"C2. Factory Method", subtitle:"", parent:"dpc"},
        // {id:"abstract-factory", url:"#/design-patterns/abstract-factory", title:"C3. Abstract Factory", subtitle:"", parent:"dpc"},
        // {id:"prototype", url:"#/design-patterns/prototype", title:"C4. Prototype", subtitle:"(creaza/cloneaza obiecte duplicate)", parent:"dpc"},
        // {id:"builder", url:"#/design-patterns/builder", title:"C5. Builder", subtitle:"(produce obiecte complexe))", parent:"dpc"},
        // {id:"singleton", url:"#/design-patterns/singleton", title:"C6. Singleton", subtitle:"(produce instanţă unică)", parent:"dpc"},
        // {id:"object-pool", url:"#/design-patterns/object-pool", title:"C7. Object Pool", subtitle:"", parent:"dpc"},
        //
        //
        // {id:"dps", url:"#", title:"Structurale (S)", subtitle:"se preocupă cu modul în care se construiesc structuri mai mari din clase şi obiecte mai mici"},
        //
        // {id:"adapter", url:"#/design-patterns/adapter", title:"S1. Adapter", subtitle:"(transformă interfaţa unei clase în alta aşteptată de client)", parent:"dps"},
        // {id:"bridge", url:"#/design-patterns/bridge", title:"S2. Bridge", subtitle:"(decuplează o abstractizare de implementarea ei)", parent:"dps"},
        // {id:"composite", url:"#/design-patterns/composite", title:"S3. Composite", subtitle:"(compune obiecte in structuri ierahice, tratate uniform)", parent:"dps"},
        // {id:"decorator", url:"#/design-patterns/decorator", title:"S4. Decorator", subtitle:"(adaugă dinamic noi responsabilitati la un obiect)", parent:"dps"},
        // {id:"proxy", url:"#/design-patterns/proxy", title:"S5. Proxy", subtitle:"(surogat pt. alt obiect în scopul controlării accesului la acesta )", parent:"dps"},
        // {id:"facade", url:"#/design-patterns/facade", title:"S6. Facade", subtitle:"(interfaţă unificată de nivel inalt pentru utilizarea subsistemelor)", parent:"dps"},
        // {id:"flyweight", url:"#/design-patterns/flyweight", title:"S7. Flyweight", subtitle:"(obtine obiecte dintr-un cache; obiectele create se pastreaza intr-un cache  (similar cu Prototype))", parent:"dps"},
        // {id:"filter", url:"#/design-patterns/filter", title:"S8. Filter", subtitle:"", parent:"dps"},
        //
        // {id:"dpb", url:"#", title:"Comportamentale (B)", subtitle:"se precupă de algoritmi şi atribuirea responsabilităţilor dintre obiecte"},
        //
        // {id:"chain-of-responsibility", url:"#/design-patterns/chain-of-responsibility", title:"B1. Chain of responsibility", subtitle:"(decuplează expeditorul de destinatarul unei cereri)", parent:"dpb"},
        // {id:"command", url:"#/design-patterns/command", title:"B2. Command", subtitle:"(încapsulează o cerere ca un obiect)", parent:"dpb"},
        // {id:"interpreter", url:"#/design-patterns/interpreter", title:"B3. Interpreter", subtitle:"(defineşte o reprezentare a gramaticii unui limbaj spre a-l interpreta)", parent:"dpb"},
        // {id:"iterator", url:"#/design-patterns/iterator", title:"B4. Iterator / Cursor", subtitle:"(accesare secventiala a elementelor unui obiect )", parent:"dpb"},
        // {id:"mediator", url:"#/design-patterns/mediator", title:"B5. Mediator", subtitle:"", parent:"dpb"},
        // {id:"memento", url:"#/design-patterns/memento", title:"B6. Memento", subtitle:"", parent:"dpb"},
        // {id:"observer", url:"#/design-patterns/observer", title:"B7. Observer", subtitle:"", parent:"dpb"},
        // {id:"state", url:"#/design-patterns/state", title:"B8. State", subtitle:"(similar cu Strategy, Bridge)", parent:"dpb"},
        // {id:"strategy-pattern", url:"#/design-patterns/strategy-pattern", title:"B9. Strategy Pattern", subtitle:"(separa contextual de algoritm (similar cu State, Bridge))", parent:"dpb"},
        // {id:"template", url:"#/design-patterns/template", title:"B10. Template", subtitle:"", parent:"dpb"},
        // {id:"visitor", url:"#/design-patterns/visitor", title:"B11. Visitor", subtitle:"", parent:"dpb"},
        // {id:"null", url:"#/design-patterns/null", title:"B12. Null", subtitle:"", parent:"dpb"},
        //
        // {id:"dpj", url:"#", title:"J2EE (J)", subtitle:"pentru dezvoltarea aplicațiilor bazate pe web"},
        //
        // {id:"mvc", url:"#/design-patterns/mvc", title:"J1. MVC", subtitle:"", parent:"dpj"},
        // {id:"composite-entity", url:"#/design-patterns/composite-entity", title:"J2. Composite Entity", subtitle:"", parent:"dpj"},
        // {id:"transfer-object", url:"#/design-patterns/transfer-object", title:"J3. Transfer Object ", subtitle:"", parent:"dpj"},
        // {id:"dao", url:"#/design-patterns/dao", title:"J4. DAO (Data Access Object)", subtitle:"(legătură cu o sursă de date)", parent:"dpj"},
        // {id:"front-controller", url:"#/design-patterns/front-controller", title:"J5. Front Controller", subtitle:"", parent:"dpj"},
        // {id:"intercepting-filter", url:"#/design-patterns/intercepting-filter", title:"J6. Intercepting Filter", subtitle:"", parent:"dpj"},
        // {id:"service-locator", url:"#/design-patterns/service-locator", title:"J7. Service Locator", subtitle:"(apelează servicii prin JNDI (optimizare cu un cache))", parent:"dpj"},
        // {id:"business-delegate", url:"#/design-patterns/business-delegate", title:"J8. Business Delegate", subtitle:"(clientul are un business delegate care determina ce serviciu să apeleze sau ce comportament să aibe)", parent:"dpj"},

    ];

    static indexUrl = "/design-patterns/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Design Patterns
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;