import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";


class JavaCryptographyEx03Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-cryptography-ex-03", IndexContent);
    }

    render() {

        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    Pentru semnarea documentelor XML (cu certificat generat) se va folosi API-ul <b>Java XML Digital Signature</b>.
                    Aceste este un API Java standard pentru generarea și validarea semnăturilor XML.
                    <br/>
                    <br/>
                    Pentru semnare se poate folosi clasa de mai jos:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>

                        {'package ro.letyournailsgrow.digitalsignature.test;\n' +
                        '\n' +
                        'import org.w3c.dom.Document;\n' +
                        '\n' +
                        'import javax.xml.crypto.dsig.*;\n' +
                        'import javax.xml.crypto.dsig.dom.DOMSignContext;\n' +
                        'import javax.xml.crypto.dsig.keyinfo.KeyInfo;\n' +
                        'import javax.xml.crypto.dsig.keyinfo.KeyInfoFactory;\n' +
                        'import javax.xml.crypto.dsig.keyinfo.KeyValue;\n' +
                        'import javax.xml.crypto.dsig.spec.C14NMethodParameterSpec;\n' +
                        'import javax.xml.crypto.dsig.spec.TransformParameterSpec;\n' +
                        'import javax.xml.parsers.DocumentBuilderFactory;\n' +
                        'import javax.xml.transform.Transformer;\n' +
                        'import javax.xml.transform.TransformerFactory;\n' +
                        'import javax.xml.transform.dom.DOMSource;\n' +
                        'import javax.xml.transform.stream.StreamResult;\n' +
                        'import java.io.ByteArrayInputStream;\n' +
                        'import java.io.OutputStream;\n' +
                        'import java.security.KeyPair;\n' +
                        'import java.security.KeyPairGenerator;\n' +
                        'import java.util.Collections;\n' +
                        '\n' +
                        'public class GenerateEnveloped {\n' +
                        '\n' +
                        '\n' +
                        '    public void generate(String xmlSource) throws Exception {\n' +
                        '\n' +
                        '        // se creeaza un obiect DOM XMLSignatureFactory care va fi folosit pentru a genera signatura invaluita / plic\n' +
                        '        XMLSignatureFactory fac = XMLSignatureFactory.getInstance("DOM");\n' +
                        '\n' +
                        '        KeyPair kp = getKeyPair();\n' +
                        '        KeyInfo ki = getKeyInfo(fac, kp);\n' +
                        '\n' +
                        '        Document doc = getDocument(xmlSource);\n' +
                        '\n' +
                        '        // se creaza un obiect de tip DOMSignContext specificand:\n' +
                        '        // - cheia privata RSA\n' +
                        '        // - locatia/elementul parinte unde va plasat elememntul XMLSignature, care va contine semnatura\n' +
                        '        DOMSignContext dsc = new DOMSignContext(kp.getPrivate(), doc.getDocumentElement());\n' +
                        '\n' +
                        '        // se creaza semnatura dar nu se semneaza\n' +
                        '        SignedInfo si = getSignedInfo(fac);\n' +
                        '        XMLSignature signature = fac.newXMLSignature(si, ki);\n' +
                        '\n' +
                        '        // generează (și semnează) semnătura eveloped / plic\n' +
                        '        signature.sign(dsc);\n' +
                        '\n' +
                        '        displayDocumentSign(doc);\n' +
                        '    }\n' +
                        '\n' +
                        '    private Document getDocument(String xmlSource) throws Exception{\n' +
                        '        // se construieste obiectul XML care va fi semnat\n' +
                        '        DocumentBuilderFactory dbf = DocumentBuilderFactory.newInstance();\n' +
                        '        dbf.setNamespaceAware(true);\n' +
                        '        Document doc = dbf.newDocumentBuilder().parse(new ByteArrayInputStream(xmlSource.getBytes()));\n' +
                        '        return doc;\n' +
                        '    }\n' +
                        '\n' +
                        '    private SignedInfo getSignedInfo(XMLSignatureFactory fac) throws Exception{\n' +
                        '\n' +
                        '        // se creaza un obiect de tip Reference, care va avea ca atribut:\n' +
                        '        // - URI:  care va fi o referinta catre documentul plic (in cazul in care se semneaza tot documentul, atributul URI = "" - sirul vid)\n' +
                        '        // - elementele:\n' +
                        '        //   - DigestMethod: se specifica algoritmul de digest (SHA256)\n' +
                        '        //   - Transforms: se specifica transformarea (ENVELOPED)\n' +
                        '\n' +
                        '        // Exemplu:\n' +
                        '        //    <Reference URI="">\n' +
                        '        //             <Transforms>\n' +
                        '        //               <Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature" />\n' +
                        '        //             </Transforms>\n' +
                        '        //             <DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1" />\n' +
                        '        //             <DigestValue>UWuYTYug10J1k5hKfonxthgrAR8=</DigestValue>\n' +
                        '        //    </Reference>\n' +
                        '\n' +
                        '        Reference ref = fac.newReference("",\n' +
                        '                fac.newDigestMethod(DigestMethod.SHA256, null),\n' +
                        '                Collections.singletonList(fac.newTransform(Transform.ENVELOPED, (TransformParameterSpec) null)),\n' +
                        '                null,\n' +
                        '                null);\n' +
                        '\n' +
                        '        // se creaza un obiect de tip SignatureMethod\n' +
                        '        SignatureMethod signatureMethod = fac.newSignatureMethod("http://www.w3.org/2001/04/xmldsig-more#rsa-sha256", null);\n' +
                        '\n' +
                        '        // se creaza un obiect de tip SignedInfo\n' +
                        '        SignedInfo si = fac.newSignedInfo\n' +
                        '                (fac.newCanonicalizationMethod(CanonicalizationMethod.INCLUSIVE_WITH_COMMENTS, (C14NMethodParameterSpec) null),\n' +
                        '                        signatureMethod,\n' +
                        '                        Collections.singletonList(ref));\n' +
                        '\n' +
                        '        return si;\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    private KeyPair getKeyPair() throws Exception{\n' +
                        '        // se creaaza un obiect RSA KeyPair\n' +
                        '        KeyPairGenerator kpg = KeyPairGenerator.getInstance("RSA");\n' +
                        '        kpg.initialize(2048);\n' +
                        '        KeyPair kp = kpg.generateKeyPair();\n' +
                        '\n' +
                        '        return kp;\n' +
                        '    }\n' +
                        '\n' +
                        '    private KeyInfo getKeyInfo(XMLSignatureFactory fac,  KeyPair kp) throws Exception {\n' +
                        '\n' +
                        '        // se creaza un obiect de tip KeyValue care va contine cheia publica\n' +
                        '        KeyInfoFactory kif = fac.getKeyInfoFactory();\n' +
                        '        KeyValue kv = kif.newKeyValue(kp.getPublic());\n' +
                        '\n' +
                        '        // se creaza un obiect de tip KeyInfo si se adauga obiectul de tip KeyValue creat mai sus\n' +
                        '        // Un KeyInfo conține o listă de obiecte de tip XMLStructure,\n' +
                        '        // fiecare dintre acestea conținând informații care permit destinatarilor să obțină cheia necesară pentru validarea unei semnături XML.\n' +
                        '        KeyInfo ki = kif.newKeyInfo(Collections.singletonList(kv));\n' +
                        '\n' +
                        '        return ki;\n' +
                        '    }\n' +
                        '\n' +
                        '    private void displayDocumentSign(Document doc) throws Exception{\n' +
                        '        // afisare document rezultat\n' +
                        '        OutputStream os = System.out;\n' +
                        '\n' +
                        '        TransformerFactory tf = TransformerFactory.newInstance();\n' +
                        '        Transformer trans = tf.newTransformer();\n' +
                        '        trans.transform(new DOMSource(doc), new StreamResult(os));\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}

                    </SyntaxHighlighter>

                    Testarea clasei <i>GenerateEnveloped</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>

                        {'package ro.letyournailsgrow.digitalsignature;\n' +
                        '\n' +
                        'import ro.letyournailsgrow.digitalsignature.test.GenerateEnveloped;\n' +
                        '\n' +
                        'public class DigitalSignatureApplication {\n' +
                        '\n' +
                        '    public static void main(String args[]) throws Exception {\n' +
                        '\n' +
                        '        GenerateEnveloped generateEnveloped = new GenerateEnveloped();\n' +
                        '        String xml = "<Mesaj>test</Mesaj>";\n' +
                        '        generateEnveloped.generate(xml);\n' +
                        '\n' +
                        '    }\n' +
                        '}\n'}

                    </SyntaxHighlighter>

                    Rularea aplicatiei de mai sus, va genera urmatorul XML semnat:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                        '<Mesaj>\n' +
                        '   test\n' +
                        '   <Signature xmlns="http://www.w3.org/2000/09/xmldsig#">\n' +
                        '      <SignedInfo>\n' +
                        '         <CanonicalizationMethod Algorithm="http://www.w3.org/TR/2001/REC-xml-c14n-20010315#WithComments" />\n' +
                        '         <SignatureMethod Algorithm="http://www.w3.org/2001/04/xmldsig-more#rsa-sha256" />\n' +
                        '         <Reference URI="">\n' +
                        '            <Transforms>\n' +
                        '               <Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature" />\n' +
                        '            </Transforms>\n' +
                        '            <DigestMethod Algorithm="http://www.w3.org/2001/04/xmlenc#sha256" />\n' +
                        '            <DigestValue>3dRCeMFYjmeQ7b3QMab2LdP3tDh2UGj4yITVhFIBAnU=</DigestValue>\n' +
                        '         </Reference>\n' +
                        '      </SignedInfo>\n' +
                        '      <SignatureValue>c0k7dMXq04CNxHJy+rl24fRyOssf4RvTF6fmQAMckpQ2rN1PEBHP6m7BVUtZFPot916zrKVx/wCt&#xD;\n' +
                        '9xlIVlA+5FVGUyOlDbLNhEvjY2GBwXIub7VsCiangk5I1yyD2JBHPoD4MwnqkJuYo+2SPVeXdbkB&#xD;\n' +
                        'aiHf7cC8vNb7K7id5F8HD3SlbjPKy+JtEk5RV3jeEeWgNMj7k/lgR663ScNPh7JiLYxOkZr14D1N&#xD;\n' +
                        'p1jahS2MVU3czyRdwSc/gtgn2U7hNHNO9kEO8iCarynRMTP+2z/EJ0Ec5I3GyLKudxil+tHMM2fg&#xD;\n' +
                        'KuXWF2yXN7U3c+ttlRXup1Ll7+DDIlZ33NzUhw==</SignatureValue>\n' +
                        '      <KeyInfo>\n' +
                        '         <KeyValue>\n' +
                        '            <RSAKeyValue>\n' +
                        '               <Modulus>mhJQ8CkR8zC/49zZx6MZyXcWM+PfjRxgMgQtD+qYYxfkxza3CQzmJ0l6/LgHgQ5fhrufF1U511V/&#xD;\n' +
                        'bMZpLl+nthZouVEVcp0vbZsfcGqYNMdccGW5QtjIONJgnZQCXbVJ1D/1tfEj0x6XgUou5j9dO+eR&#xD;\n' +
                        'WCH1uspyiw0+rnksbKCcX5BtswVn/I1dyleiMmS0voHKyw+uHsHaYE4OdUaX0XtYo6AjCJuhJfI1&#xD;\n' +
                        'cNTwRiDPQ7AaWyeHOmbMZ6VAg1Qhu5e3f82WzihkHAGheJkun1Q7eZxKXansYK9lrUCTs3X/s3Is&#xD;\n' +
                        'EJMY3dwOEa9Qs3QxneiGI/D+Ea52zhSFdw3wtQ==</Modulus>\n' +
                        '               <Exponent>AQAB</Exponent>\n' +
                        '            </RSAKeyValue>\n' +
                        '         </KeyValue>\n' +
                        '      </KeyInfo>\n' +
                        '   </Signature>\n' +
                        '</Mesaj>'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>Referinte:</b>
                    <ul>
                        <li>
                            <a href={"https://docs.oracle.com/javase/9/security/java-xml-digital-signature-api-overview-and-tutorial.htm"}>
                                XML Digital Signature API Overview and Tutorial
                            </a>
                        </li>

                    </ul>

                    <hr/>
                    <b>STOP</b>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaCryptographyEx03Content;