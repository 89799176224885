import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";



class SwrReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-swr", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. SWR</b>
                    <br/>
                    <br/>

                    SWR (stale-while-revalidate) este o stratefie de invalidare a cache-ului HTTP.
                    <br/>

                    SWR este o strategie de a returna mai întâi datele din cache (învechit), apoi de a trimite cererea de preluare (revalidare) și, în final, de a veni cu datele actualizate.

                    Instalare:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm i swr'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import useSWR from \'swr\'\n' +
                            ' \n' +
                            'function Profile() {\n' +
                            '  const { data, error, isLoading } = useSWR(\'/api/user\', fetcher)\n' +
                            ' \n' +
                            '  if (error) return <div>failed to load</div>\n' +
                            '  if (isLoading) return <div>loading...</div>\n' +
                            '  return <div>hello {data.name}!</div>\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Hook-ul <b>useSWR</b> are ca parametri:
                    <ul>
                        <li>o cheie (ex: /api/user): este un identificator unic al datelor (în mod normal, adresa URL API)  și va fi transmis către fetcher</li>
                        <li>o functie <i>fetcher</i>:  funcție asincronă care returnează datele ( se poate utiliza preluarea nativă - <i>fetch()</i> sau instrumente precum Axios);
                            <br/>
                            exemplu fetcher:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const fetcher = (...args) => fetch(...args).then(res => res.json())'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Hook-ul <b>useSWR</b> returneaza
                    <ul>
                        <li>
                            data
                        </li>
                        <li>
                            error
                        </li>
                        <li>
                            isLoading
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://swr.vercel.app/"}>SWR</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SwrReactContent;