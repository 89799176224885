import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ImagePullPolicyK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-image-pull-policy", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Image Pull Policy</b>
                    <br/>
                    <br/>

                    Pentru a specifica pentru un container politica, modul de update. In mod implicit este:
                    <ul>
                        <li>
                            <b>Always</b>, daca tag-ul <b>latest</b> este specificat
                        </li>
                        <li>
                            <b>IfNotPresent</b>, in caz contrar
                        </li>
                    </ul>

                    Valori posibile:
                    <ul>
                        <li>
                            Always
                        </li>
                        <li>
                            Never
                        </li>
                        <li>
                            IfNotPresent
                        </li>

                    </ul>

                    In cazul de mai sus, daca imaginea s-a actualizat pe DockerHub se actualizeaza si in k8s (la executia comenzii <b>kubectl apply -f</b>).
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'containers:\n' +
                            '  - name: kj-node\n' +
                            '    image: iulianbuzdugan/kj-node\n' +
                            '    imagaPullPolicy: Always'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImagePullPolicyK8sContent;