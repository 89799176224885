import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NetworkPluginsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-network-plugins", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Network Plugins</b>
                    <br/>
                    <br/>

                    Afisare pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -n kube-system '}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                                         READY   STATUS    RESTARTS   AGE\n' +
                            'calico-kube-controllers-57b57c56f-kz28f      1/1     Running   0          5d20h\n' +
                            'calico-node-ddbwb                            1/1     Running   0          5d20h\n' +
                            'calico-node-vk9t7                            1/1     Running   0          5d17h\n' +
                            'coredns-787d4945fb-whbsq                     1/1     Running   0          5d17h\n' +
                            'coredns-787d4945fb-zxqvb                     1/1     Running   0          5d17h\n' +
                            'etcd-nas-ubuntu-manager                      1/1     Running   92         5d20h\n' +
                            'kube-apiserver-nas-ubuntu-manager            1/1     Running   89         5d20h\n' +
                            'kube-controller-manager-nas-ubuntu-manager   1/1     Running   2          5d20h\n' +
                            'kube-proxy-nw4jg                             1/1     Running   0          5d17h\n' +
                            'kube-proxy-zw24n                             1/1     Running   0          5d20h\n' +
                            'kube-scheduler-nas-ubuntu-manager            1/1     Running   107        5d20h\n'}
                    </SyntaxHighlighter>

                    Pentru a verifica ce plugin CNI este folosit de noduri (folosind pod-ul <i>kube-controller-manager-nas-ubuntu-manager</i>):
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl logs kube-controller-manager-nas-ubuntu-manager  -n kube-system'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'I0205 10:41:48.383053       1 serving.go:348] Generated self-signed cert in-memory\n' +
                            'I0205 10:41:51.568036       1 controllermanager.go:182] Version: v1.26.1\n' +
                            'I0205 10:41:51.573020       1 controllermanager.go:184] "Golang settings" GOGC="" GOMAXPROCS="" GOTRACEBACK=""\n' +
                            'I0205 10:41:51.574932       1 secure_serving.go:210] Serving securely on 127.0.0.1:10257\n' +
                            'I0205 10:41:51.575335       1 leaderelection.go:248] attempting to acquire leader lease kube-system/kube-controller-manager...\n' +
                            'I0205 10:41:51.575967       1 dynamic_cafile_content.go:157] "Starting controller" name="request-header::/etc/kubernetes/pki/front-proxy-ca.crt"\n' +
                            'I0205 10:41:51.577184       1 tlsconfig.go:240] "Starting DynamicServingCertificateController"\n' +
                            'I0205 10:41:51.577397       1 dynamic_cafile_content.go:157] "Starting controller" name="client-ca-bundle::/etc/kubernetes/pki/ca.crt"\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl logs kube-proxy-nw4jg  -n kube-system'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'I0205 13:52:49.624910       1 node.go:163] Successfully retrieved node IP: 192.168.1.6\n' +
                            'I0205 13:52:49.625331       1 server_others.go:109] "Detected node IP" address="192.168.1.6"\n' +
                            'I0205 13:52:49.625437       1 server_others.go:535] "Using iptables proxy"\n' +
                            'I0205 13:52:49.657837       1 server_others.go:176] "Using iptables Proxier"\n' +
                            'I0205 13:52:49.657887       1 server_others.go:183] "kube-proxy running in dual-stack mode" ipFamily=IPv4\n' +
                            'I0205 13:52:49.657903       1 server_others.go:184] "Creating dualStackProxier for iptables"\n'}
                    </SyntaxHighlighter>

                    CNI providers:
                    <ul>
                        <li>
                            Project Calico
                        </li>
                        <li>
                            Calico with Canal
                        </li>
                        <li>
                            Weave Works
                        </li>
                        <li>
                            Flannel
                        </li>
                        <li>
                            Cilium
                        </li>
                        <li>
                            Kube Router
                        </li>
                    </ul>

                    Urmatoarele plugin-uri:
                    <ul>
                        <li>
                            permit <b>vxlangs</b>: Canal, Project Calico, Flannel, Weave Net, Cilium
                        </li>
                        <li>
                            au 2 layere: Canal, Flannel, Weave Net
                        </li>
                        <li>
                            au 2 layere: Project Calico, Romana, Kube Router
                        </li>
                        <li>
                            permit politici de retea: Project Calico, Canal, Kube Router, Weave Net, Cilium
                        </li>
                        <li>
                            pot cripa tot traficul TCP, UDP: Project Calico, Weave Net, Cilium
                        </li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>
                            1 singura adresa IP este asignata per pod
                        </li>
                        <li>
                            metoda care permite cea mai mare scalalibitate granulara si flexiblitate: one per pod
                        </li>
                        <li>
                            cea mai buna performata inter-container: multiple per pod
                        </li>
                        <li>
                            Metode de comunicare intre containere in acelasi pod: IPC, loopback / shared filesystem access
                        </li>
                        <li>
                            Motive pentru a folosi multiple containere per pod: Sidecar (logging), Ambassadors (container de sprijin, care adauga noua functionalitati)
                        </li>

                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NetworkPluginsK8sContent;