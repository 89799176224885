import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class HostnamectlLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-hostnamectl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Detalii hostname:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'hostnamectl'}
                    </SyntaxHighlighter>

                    poate afisa
                    <SyntaxHighlighter>
                        {' Static hostname: kj-Standard-PC-i440FX-PIIX-1996\n' +
                            '       Icon name: computer-vm\n' +
                            '         Chassis: vm\n' +
                            '      Machine ID: e55d4ebc292a489ab31da19b498fbc8c\n' +
                            '         Boot ID: 7523cbc045b14efba1aa6e8f33bd9c1a\n' +
                            '  Virtualization: microsoft\n' +
                            'Operating System: Ubuntu 22.04.1 LTS               \n' +
                            '          Kernel: Linux 5.15.0-50-generic\n' +
                            '    Architecture: x86-64\n' +
                            ' Hardware Vendor: QEMU\n' +
                            '  Hardware Model: Standard PC _i440FX + PIIX, 1996_\n'}
                    </SyntaxHighlighter>

                    Pentru a schimba numele hostname:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo hostnamectl set-hostname nas-ubuntu-kj'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HostnamectlLinuxContent;