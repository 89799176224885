import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class GitServerSSHAccessNasContent extends BaseContentPage {

    constructor(props) {
        super(props, "nas-git-server-ssh-access", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare SSH Access (optional, pentru nu a mai cere parola)</b>
                    <br/>
                    <br/>

                    Pe NAS se genereaza:
                    <ul>
                        <li>o cheie publica</li>
                        <li>o cheie privata</li>
                    </ul>
                    Cheia publica ramane pe NAS, cheia privata ajunge la client.

                    <ul>
                        <li>
                            in directorul utilizatorului care va administra git-ul, se creaza folderul <i>.ssh</i>:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'mkdir .ssh'}
                            </SyntaxHighlighter>

                            acordare drepturi pe folder
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'chmod 700 .ssh'}
                            </SyntaxHighlighter>

                            creare fisier <i>authorized_keys</i>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'touch .ssh/authorized_keys'}
                            </SyntaxHighlighter>

                            acordare drepturi pe fisier:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'chmod 600 .ssh/authorized_keys'}
                            </SyntaxHighlighter>

                            generare cheie (cu algorimtmul ed25519):
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'ssh-keygen -t ed25519'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'Generating public/private ed25519 key pair.\n' +
                                    'Enter file in which to save the key (/var/services/homes/adminkj/.ssh/id_ed25519):\n' +
                                    'Enter passphrase (empty for no passphrase):\n' +
                                    'Enter same passphrase again:\n' +
                                    'Your identification has been saved in /var/services/homes/adminkj/.ssh/id_ed25519\n' +
                                    'Your public key has been saved in /var/services/homes/adminkj/.ssh/id_ed25519.pub\n' +
                                    'The key fingerprint is:\n' +
                                    'SHA256:u3eVKetuwXgXk9DMVdL7H4I3Ms2GRt2KC+6PM7SjB/Y adminkj@DS1621NASKJ\n' +
                                    'The key\'s randomart image is:\n' +
                                    '+--[ED25519 256]--+\n' +
                                    '|             +.o+|\n' +
                                    '|            . +..|\n' +
                                    '|            .....|\n' +
                                    '|           . .+o |\n' +
                                    '|        S .o* .=.|\n' +
                                    '|       o +.B=O=..|\n' +
                                    '|      . * +.**o o|\n' +
                                    '|         Eo.+   .|\n' +
                                    '|       .=+=*o    |\n' +
                                    '+----[SHA256]-----+'}
                            </SyntaxHighlighter>

                            se vor genera 2 fisiere:
                            <ul>
                                <li>
                                    id_ed25519  - cheia privata (acesta ce poate sterge de aici, dupa ce se copiaza pe masina clientului)
                                </li>
                                <li>
                                    id_ed25519.pub - cheia publica
                                </li>
                            </ul>
                        </li>
                        <li>
                            se copiaza continutul fisierului din <b>id_ed25519.pub</b> in <b>authorized_keys</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cp .ssh/id_ed25519.pub .ssh/authorized_keys'}
                            </SyntaxHighlighter>
                            verificare:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cat .ssh/authorized_keys'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se copiaza <i>id_ed25519</i> pe calculatorul clientului;
                            <br/>
                            <b>urmatoarea comanda se de de masina clientului (Windows)</b>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'scp admin@192.168.1.177:.ssh/id_ed25519 c:\\Users\\buzdu\\.ssh\\id_ed25519'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se verifica sa fie decomentate urmatoare linii din fisierul: <b>/etc/ssh/sshd_config</b>

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'sudo nano /etc/ssh/sshd_config'}
                            </SyntaxHighlighter>
                            <ul>
                                <li>
                                    PubKeyAuthentication yes
                                </li>
                                <li>
                                    AuthorizedKeysFile .ssh/authorized_keys
                                </li>
                                <li>
                                    ChallengeResponseAuthentication no
                                </li>
                            </ul>

                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd ..'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'chmod 755 admin/'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            pe masina clientului (windows) se merge unde a fost instalat Git: <b>[git]\etc\ssh</b>.
                            <br/>
                            modificam fisierul <b>ssh_config</b>, adaugand la sfarsitul fisierului urmatoarea linie:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'IdentityFile ~/.ssh/id_ed25519'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Urmatoarea data cand se va da <b>push</b> (de exemplu din Intellij IDEA nu se va mai cere parola).
                        </li>
                    </ul>

                    <hr/>
                    Pentru a instala <b>nano</b>:
                    <br/>
                    <br/>

                    Package Center {"->"} All Package {"->"} Settings {"->"} Package Source {"->"} Add:
                    <ul>
                        <li>
                            Name: SynoCommunity
                        </li>
                        <li>
                            Location: https://packages.synocommunity.com/
                        </li>
                    </ul>
                    Dupa asta va apare, in stanga, si sectiunea <b>Community</b>.
                    <br/>
                    Se instaleaza: <b>SynoCli File Tools</b>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://silica.io/using-ssh-key-authentification-on-a-synology-nas-for-remote-rsync-backups/"}>
                                    Using SSH key authentification on a Synology NAS for remote rsync backups
                                </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"https://think.unblog.ch/en/how-to-install-nano-on-synology-nas/"}>
                                    HOW TO INSTALL NANO ON SYNOLOGY NAS
                                </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a target={"_blank"} href={"https://www.youtube.com/watch?v=aeo_Pax9MWE&ab_channel=GordonVart"}>
                                    Git Server on Synology | Setup SSH Key
                                </a>
                            </div>

                        </li>
                        <li>
                            <a target={"_blank"}
                               href={"https://www.youtube.com/watch?v=Z7WXiYni4GA&ab_channel=AppliedProjectsEngineering"}>
                                Git Server Synology NAS Setup and use with Visual Studio
                            </a>

                        </li>

                        <li>

                            <a target={"_blank"} href={"https://kb.synology.com/en-sg/DSM/help/Git/git?version=7"}>Git Server</a>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://gr8t.medium.com/synology-diskstation-dsm-7-setting-up-git-and-ssh-for-password-less-use-4a85ac8c0fd2"}>
                                    Synology DiskStation (NAS) DSM 7: Setting Up Git and SSH For Password-less Use
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://gist.github.com/walkerjeffd/374750c366605cd5123d"}>
                                    Configure Synology NAS as Git Server
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GitServerSSHAccessNasContent;