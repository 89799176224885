import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ValidateFormPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-form-validation", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Validarea datelot de intrare</b>
                    <br/>
                    <br/>

                    Validarea datelot de intrare se poate face:
                    <ul>
                        <li>client (folosind <i>Allow Validator</i>, <i>Clay Validation</i> (validatori Twitter Bootstrap), etc)</li>
                        <li>server (folosind clasa <i>Validator</i>)</li>
                    </ul>

                    O alta metoda de validare si protectie este folosind <b>AntiSamy Input Sanitizer</b>. Acestte permite definirea explicita de ce elemente HTML sunt permise.
                    <br/>
                    Configurarea se face din meniu: <i>Control Panel {">"} Configuration {">"} System Settings {">"} Security Tools {">"} AniSamy Sanitizer</i>

                    <br/>
                    <br/>

                    Exemplu 1 de validare pe client, folosind <i>Allow Validator</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<aui:input name="name">\n' +
                        '        <aui:validator name="required" errorMessage="Introduceti numele!"/>\n' +
                        '</aui:input>'}
                    </SyntaxHighlighter>

                    Exemplu 2 de validare pe client, folosind <i>Allow Validator</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<aui:input name="value">\n' +
                        '        <aui:validator name="custom" errorMessage="Trebuie introdusa o valoare valida!">\n' +
                        '            function (val, fieldNode, ruleValue){\n' +
                        '                return ((val!=\'\' || A.one(\'#<portlet:namespace/>file\').val()!=\'\');\n' +
                        '            }\n' +
                        '        </aui:validator>\n' +
                        '</aui:input>'}
                    </SyntaxHighlighter>

                    Exemplu 3 de validare pe client, folosing <i>Clay Validator</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<form data-toggle="validator" role="form" data-disable="false">\n' +
                        '    <div class="form-group">\n' +
                        '        <label class="control-label" for="inputName">Name</label>\n' +
                        '        <input class="form-control" data-error="Please enter your name" id="Name" placeholder="First Name" type="text" required />\n' +
                        '        <div class="help-block with-errors"></div>\n' +
                        '    </div>\n' +
                        '    <div class="form-group">\n' +
                        '        <button class="btn btn-primary" type="submit">\n' +
                        '            Submit\n' +
                        '        </button>\n' +
                        '    </div>\n' +
                        '</form>'}
                    </SyntaxHighlighter>


                    Exemplu 4 de validare pe server:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'if (Validator.isAddress(name)){\n' +
                        '            SessionErrors.add(actionRequest, "name-empty");\n' +
                        '            return false;\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Validarea datelot de iesire</b>
                    <br/>
                    <br/>

                    Pentru validatea datelor de intrare se poate folosi

                    <ul>
                        <li>pe client: libraria de taguri JSTL</li>
                        <li>pe server: clasa HtmlUtil</li>
                    </ul>


                    Exemplu 1 de validare pe client a datelor de iesire:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<c:out escapeXML="true">${bodyText}</c:out>'}
                    </SyntaxHighlighter>

                    Exemplu 2 de validare pe server a datelor de iesire:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'request.setAttribute("bodyText", HtmlUtil.escape(bodyText));'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Mesaje implicite de succes</b>
                    <br/>
                    <br/>

                    Liferay seteaza mesaje implicite de succes pentru actiunile unui portlet.
                    Acest lucru poate fi modificat prin intermediul proprietatii:
                    <br/>
                    <b>javax.portlet.init-param.add-process-action-success-action</b>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"javax.portlet.init-param.add-process-action-success-action=false",\n' +
                        '\t\t...\n' +
                        '\t}\n' +
                        ')'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Trimite mesaje de validare pe server</b>
                    <br/>
                    <br/>


                    Mesajele de validare de pe partea se server sunt trimise catre interfata utilizator cu ajutorul claselor:
                    <ul>
                        <li><b>SessionErrors</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'SessionErrors.add(actionRequest, "save-nok-key")'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>SessionMessages</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'SessionMessages.add(actionRequest, "save-ok-key")'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    In fisierul <b>init.jsp</b> se importa <b>SessionErrors</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ page import="com.liferay.portal.kernel.servlet.SessionErrors" %>'}
                    </SyntaxHighlighter>

                    In fisierul <b>view.jsp</b>, pentru a afisa mesajele de succes:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<liferay-ui:success key="save-ok-key" message="save-ok-key-message"></liferay-ui:success>'}
                    </SyntaxHighlighter>

                    In fisierul <b>view.jsp</b>, pentru a afisa mesajele de eroare:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<liferay-ui:error key="errorDetails">\n' +
                        '\t<liferay-ui:message key="save-nok-key" arguments=\'<%= SessionErrors.get(liferayPortletRequest, "errorDetails") %>\'></liferay-ui:message>\n' +
                        '</liferay-ui:error>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Daca pe server se trimite o exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'SessionErrors.add(request, Exception1.class.getName(), e);'}
                    </SyntaxHighlighter>

                    In JSP:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<liferay-ui:error exception="<%=Exception1.class %>" message="Exception 1 have occured"></liferay-ui:error>'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.xtivia.com/blog/liferay-forms-field-custom-validation/"}>Liferay Form Field Custom Validation</a>
                            </div>
                            <div>
                                <a href={"https://www.xtivia.com/blog/top-10-features-liferay-forms/"}>Top 10 Features of Liferay Forms</a>
                            </div>
                        </li>

                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>*/}
                        {/*            MVC Render Command*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ValidateFormPortletContent;