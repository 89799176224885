import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class SSLTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-ssl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare SSL cu Keystore</b>
                    <br/>
                    <br/>
                    Aceasta abordare se foloseste atunci când nu exista un certificat SSL real, dar trebuie activat HTTPs pentru Tomcat (de exemplu, pe o mașină locală sau pe serverul de dezvoltare)

                    <br/>
                    Pasii:
                    <ul>
                        <li>Generare keystore
                            <hr/>

                            Generarea keystore se va folosind comanda <b>keytool</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'cd $JAVA_HOME/bin\n' +
                                    ' keytool -genkey -alias ALIAS -keyalg RSA keystore PATH_TO_KEYSTORE/.keystore'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>ALIAS - alias pentru certificat (exemplu: myKey)</li>
                                <li>PATH_TO_KEYSTORE - calea catre directorul unde <b>.keystore</b> va fi creat</li>
                                <li>RSA este algoritmul (<i>keyalg</i>)</li>
                            </ul>

                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="powershell" style={androidstudio}>
                                {'PS C:\\tools\\jdk-11\\bin> keytool -genkey -keyalg RSA -alias tomcatCertificateKJ -keystore d:\\work-resources\\.keystore\n' +
                                    'Enter keystore password: tomcatkj\n' +
                                    'Re-enter new password: tomcatkj\n' +
                                    'What is your first and last name?\n' +
                                    '  [Unknown]:  kj\n' +
                                    'What is the name of your organizational unit?\n' +
                                    '  [Unknown]:  dopamina\n' +
                                    'What is the name of your organization?\n' +
                                    '  [Unknown]:  dopamina\n' +
                                    'What is the name of your City or Locality?\n' +
                                    '  [Unknown]:  Bucuresti\n' +
                                    'What is the name of your State or Province?\n' +
                                    '  [Unknown]:  Romania\n' +
                                    'What is the two-letter country code for this unit?\n' +
                                    '  [Unknown]:  RO\n' +
                                    'Is CN=kj, OU=dopamina, O=dopamina, L=Bucuresti, ST=Romania, C=RO correct?\n' +
                                    '  [no]: yes\n' +
                                    '\n' +
                                    'Enter key password for <tomcatCertificateKJ>\n' +
                                    '        (RETURN if same as keystore password):\n' +
                                    '\n' +
                                    'Warning:\n' +
                                    'The JKS keystore uses a proprietary format. It is recommended to migrate to PKCS12 \n' +
                                    'which is an industry standard format using \n' +
                                    '"keytool -importkeystore -srckeystore d:\\work-resources\\.keystore -destkeystore d:\\work-resources\\.keystore -deststoretype pkcs12".'}
                            </SyntaxHighlighter>
                        </li>
                        {/*<br/>*/}
                        {/*<li>Export keystore*/}

                        {/*    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>*/}
                        {/*        {'keytool -export -alias ALIAS -keystore PATH_TO_KEYSTORE/.keystore -file server.crt'}*/}
                        {/*    </SyntaxHighlighter>*/}

                        {/*    unde:*/}
                        {/*    <ul>*/}
                        {/*        <li>ALIAS - alias pentru certificat</li>*/}
                        {/*        <li>PATH_TO_KEYSTORE - calea catre directorul unde exista <b>.keystore</b></li>*/}
                        {/*    </ul>*/}

                        {/*</li>*/}
                        {/*<li>Import keystore:*/}

                        {/*    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>*/}
                        {/*        {'keytool -import -alias ALIAS -file server.crt -keystore $JAVA_HOME/jre/lib/cacerts'}*/}
                        {/*    </SyntaxHighlighter>*/}

                        {/*    unde:*/}
                        {/*    <ul>*/}
                        {/*        <li>ALIAS - alias pentru certificat</li>*/}
                        {/*        <li>PATH_TO_KEYSTORE - calea catre directorul unde exista <b>.keystore</b></li>*/}
                        {/*    </ul>*/}


                        {/*</li>*/}
                        <li>Configure SSL in Tomcat
                            <br/>
                            se comenteaza urmatoarea linie in [tomcat]/conf/server.xml:
                          <br/>
                            se decomenteaza linia cu:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<!-- <Connector port="8443" ...'}
                            </SyntaxHighlighter>

                            se completeaza linia de mai sus cu:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {' <Connector port="8443" protocol="org.apache.coyote.http11.Http11NioProtocol"\n' +
                                    '               maxThreads="150" SSLEnabled="true">\n' +
                                    '        <UpgradeProtocol className="org.apache.coyote.http2.Http2Protocol" />\n' +
                                    '        <SSLHostConfig>\n' +
                                    '            <Certificate certificateKeystoreFile="conf/localhost-rsa.jks"\n' +
                                    '                         type="RSA" />\n' +
                                    '        </SSLHostConfig>\n' +
                                    '    </Connector>'}
                            </SyntaxHighlighter>

                           si se modifica in urmatorul mod:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector port="8443" protocol="org.apache.coyote.http11.Http11NioProtocol"\n' +
                                    '               maxThreads="150" SSLEnabled="true">\n' +
                                    '        <UpgradeProtocol className="org.apache.coyote.http2.Http2Protocol" />\n' +
                                    '        <SSLHostConfig>\n' +
                                    '            <Certificate certificateKeystoreFile="D:\\work-resources\\.keystore"\n' +
                                    '                         type="RSA" certificateKeystorePassword="tomcatkj" />\n' +
                                    '        </SSLHostConfig>\n' +
                                    '    </Connector>'}
                            </SyntaxHighlighter>

                        </li>
                        <li>Restartare Tomcat</li>
                        <li>Verificare URL in browser:
                            <br/>
                            http://localhost:8580/ (sau http://localhost:8580/ daca am schimbat portul)
                            <br/>
                            <b>https</b>://localhost:8443/
                            <br/>
                            <br/>
                            Certificatul SSL va functiona, chiar daca browser-ul va da o eroare, pentru ca certificatul este semnat local si nu de o autoritate:
                            <br/>
                            <i>Your connection is not private</i>!
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Securizare URL-uri</b>
                    <br/>
                    <br/>

                    Pentru a specifica pentru o aplicatie, ca un anumit url sa functioneze securizat doar prin HTTPS, atunci se modifica fisieruk <b>web.xml</b>.
                    <br/>
                    De exemplu, pentru aplicatia <i>manager</i>, vrem ca orice url care contine <i>/html/*</i> sa fie securitzat.
                    <br/>
                    Atunci deschidem fisierul: <i>webapps\manager\WEB-INF\web.xml</i>
                    <br/>
                    Gasim urmatoare secventa:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<security-constraint>\n' +
                            '    <web-resource-collection>\n' +
                            '      <web-resource-name>HTML Manager interface (for humans)</web-resource-name>\n' +
                            '      <url-pattern>/html/*</url-pattern>\n' +
                            '    </web-resource-collection>\n' +
                            '    <auth-constraint>\n' +
                            '       <role-name>manager-gui</role-name>\n' +
                            '    </auth-constraint>\n' +
                            '  </security-constraint>'}
                    </SyntaxHighlighter>
                    si o modificam:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'  <security-constraint>\n' +
                            '    <web-resource-collection>\n' +
                            '      <web-resource-name>HTML Manager interface (for humans)</web-resource-name>\n' +
                            '      <url-pattern>/html/*</url-pattern>\n' +
                            '    </web-resource-collection>\n' +
                            '    <auth-constraint>\n' +
                            '       <role-name>manager-gui</role-name>\n' +
                            '    </auth-constraint>\n' +
                            '\t\n' +
                            '\t<user-data-constraint>\n' +
                            '\t\t<transport-guarantee>CONFIDENTIAL</transport-guarantee>\n' +
                            '\t</user-data-constraint>\n' +
                            '\t\n' +
                            '  </security-constraint>'}
                    </SyntaxHighlighter>
                </div>
                Dupa restart, daca vom vrea sa accesam <br/>
                https://localhost:8080/manager/html (sauhttps://localhost:8080/manager/html )
                <br/>
                vom fi redirectati in mod automat catre:
                <br/>
                https://localhost:8443/manager/html

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SSLTomcatContent;