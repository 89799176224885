import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PerceptronTheoryContent extends BaseContentPage {

    constructor(props) {
        super(props, "perceptron-theory", IndexContent);
    }

    render() {
        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    <b><a id={"perceptron"}>1.4. Perceptronul</a></b>
                    <br/>
                    <br/>
                    <div className={"text-justify important"}>
                        Perceptronul lui Rosenblatt (1962) este cea mai simpla arhitectură de rețea neuronală artificială. Caracteristiciile acestuia:
                        <table>
                            <tr>
                               <th>
                                   Parametru
                               </th>
                                <th>
                                    Variabila
                                </th>
                                <th>
                                    Tip
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Date de intrare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`x_i`}/>
                                </td>
                                <td>
                                    Real sau binar (0,1) / biploar (-1, 1)
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Ponderile
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`w_i`}/>
                                </td>
                                <td>
                                    Real (initializate cu valori aleatoare); ponderea asociată conexiunii dintre neuronul <i>i</i> din input layer
                                    și singurul neuron din output layer.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Prag (bias)
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`\theta`}/>
                                </td>
                                <td>
                                    Real (initializat cu valori aleatoare)
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Iesire
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`y`}/>
                                </td>
                                <td>
                                    Binar
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Functie de activare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`f()`}/>
                                </td>
                                <td>
                                    Functia pas sau functia pas bipolara
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Tip antrenare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`-`}/>
                                </td>
                                <td>
                                    Supervizată
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Rugula de învătare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`-`}/>
                                </td>
                                <td>
                                    Regula de învătarea a percepronului / Regula lui Hebb
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
                <br/>

                <div className={"text-justify"}>
                    Un perceptron cu <b>n</b> intrări:
                    <div style={{padding:50}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/perceptron_3.png'}/>
                    </div>
                </div>

                <div className={"text-justify important"}>
                    <b>Algoritm Perceptron (Faza de antrenare)</b>
                    <br/>
                    <br/>
                    Algoritmul se foloseste pentru ajustarea ponderilor de:
                    <ul>
                        <li>
                            un <b>set de antrenament A</b>
                            <MathComponent tex={String.raw`A=\{A^1,A^2,...,A^h\}`}/>
                            iar fiecare element din A il vom numi <b>imagine</b> sau observatie și va fi de forma:
                            <MathComponent tex={String.raw`A^i=\{x_1,x_2,...,x_n\}`}/>
                        </li>

                        <li>
                            un <b>set de etichete T</b> asociate setului de antrenament A:
                            <MathComponent tex={String.raw`T=\{t_1,t_2,...,t_h\}`}/>
                        </li>

                    </ul>
                    Perceptron va avea <b>n</b> intrări in input layer și <b>m</b> neuroni in output layer (nu are layere hidden)

                    <hr/>
                    <div className={"padding50"}>
                        <i>date de intrare:</i>
                        <br/>
                        <ul>
                            <li>perechea (A,T)</li>
                        </ul>

                        <i>initializare:</i>
                        <ul>
                            <li>ponderi <b>w<sub>ij</sub></b> cu valori mici aleatore</li>
                            <li>bias-urile <b>&theta;<sub>j</sub></b> cu valori mici aleatore</li>
                            <li>rata de invatare <b>&eta;</b> cu o valoare mică, de exemplu &eta;=0.001</li>
                            <li>epoca = 0</li>
                            <li>maxEpoca - numarul maxim de epoci (de iteratii prin A), de exemplu maxEpoca = 1000</li>
                        </ul>

                        <i>algoritm:</i>
                        <ul>
                            <li>repeta</li>

                                <ul>
                                    <li>existaEroare=false</li>
                                    <li>pentru toate perechile din setul de antrenament A<sup>k</sup> : T<sup>k</sup>, cu (1 &#8804;k &#8804; h)</li>
                                    <MathComponent tex={String.raw`A^k=\{x_1,x_2,...,x_n\}, t=T^k=\{t_k\}`}/>
                                    <ul>

                                        <li>
                                            calculăm sumarizarea:
                                            <MathComponent tex={String.raw`sum=\theta+\sum_{i}^{n} x_i w_{i}`}/>
                                            <MathComponent tex={String.raw`y=f(sum)`}/>
                                            unde <b>f</b> este <b>functia de activare (functia pas bipolara)</b>
                                        </li>
                                        <li>
                                            actualizam ponderile, folosind regula de invatare a perceptronului:
                                            <br/>
                                            if (y &lt;&gt; t)
                                            <ul>
                                                <li>
                                                    <MathComponent tex={String.raw`e = t-y`}/>
                                                </li>
                                                <li>
                                                    pentru fiecare <b>neuron i</b>  (1 &#8804;i &#8804; n), actualizăm:
                                                    <MathComponent tex={String.raw`w_{i}=w_{i}+\eta x_i e`}/>
                                                </li>
                                                <li>
                                                    <MathComponent tex={String.raw`existaEroare = true`}/>
                                                </li>
                                            </ul>
                                        </li>

                                    </ul>


                                    <li>epoca = epoca + 1</li>
                                </ul>

                            <li>până când (epoca &gt; maxEpoca || !existaEroare)</li>
                        </ul>
                        <br/>

                    </div>
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <b>Algoritm Perceptron (Faza de operare)</b>
                    <br/>
                    <br/>
                    Algoritmul:
                        <ul>
                            <li> primeste o imagine pentru a fi clasificata (adica daca x &#8712; ClasaA (reprezentata de iesirea y=-1) sau x &#8712; ClasaB (reprezentata de iesirea y=1) ):
                                <MathComponent tex={String.raw`x=\{x_1,x_2,...,x_n\}`}/>
                            </li>
                            <li> foloseste ponderile si bias-urile ajutate în faza de antrenament:
                                <MathComponent tex={String.raw`w_{i}, \theta`}/>
                            </li>
                        </ul>


                    <hr/>
                    <div className={""}>
                        <i>algoritm:</i>
                        <ul>

                            <li>
                                calculăm sumarizarea:
                                <MathComponent tex={String.raw`sum=\theta+\sum_{i}^{n} x_i w_{i}`}/>
                                <MathComponent tex={String.raw`y=f(sum)`}/>
                                unde <b>f</b> este <b>functia de activare (functia pas bipolara)</b>
                            </li>
                            <li>
                                if (y = -1):
                                <MathComponent tex={String.raw`x \in ClasaA`}/>
                            </li>
                            <li>
                                if (y = 1):
                                <MathComponent tex={String.raw`x \in ClasaB`}/>
                            </li>

                        </ul>
                    </div>

                </div>


                <br/>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson
                        </li>
                        <li>
                            <div>
                                Ivan Nunes da Silva, Danilo Hernane Spatti , Rogerio Andrade Flauzino, Luisa Helena Bartocci Liboni, Silas Franco dos Reis Alves. 2017. Artificial Neural Networks - A Practical Course
                            </div>
                        </li>
                        <li>
                            <a href={"https://www.tutorialspoint.com/artificial_neural_network/index.htm"} target={"_blank"}  rel="noreferrer">
                                https://www.tutorialspoint.com/artificial_neural_network/index.htm
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PerceptronTheoryContent;