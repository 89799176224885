import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"next-root", url:"#", title:"Introducere in Nextjs", subtitle:""},
        {id:"next-create-app", url:"/next/create-app", title:"Introducere in Next.js", subtitle:"", parent:"next-root"},
        {id:"next-update", url:"/next/update", title:"Update la o noua versiune Next.js", subtitle:"", parent:"next-root"},
        {id:"next-routing", url:"/next/routing", title:"Routing in Next.js", subtitle:"", parent:"next-root"},
        {id:"next-app", url:"/next/app", title:"App in Next.js", subtitle:"", parent:"next-root"},
        {id:"next-document", url:"/next/document", title:"Document in Next.js: _document.js", subtitle:"", parent:"next-root"},
        {id:"next-getstaticprops", url:"/next/getstaticprops", title:"Preluarea datelor: getStaticProps()", subtitle:"", parent:"next-root"},
        {id:"next-getstaticpaths", url:"/next/getstaticpaths", title:"Preluarea datelor: getStaticPaths()", subtitle:"", parent:"next-root"},
        {id:"next-getserversideprops", url:"/next/getserversideprops", title:"Preluarea datelor: getServerSideProps()", subtitle:"", parent:"next-root"},
        {id:"next-head", url:"/next/head", title:"next/head", subtitle:"", parent:"next-root"},
        {id:"next-env", url:"/next/env", title:"Variabile de mediu", subtitle:"", parent:"next-root"},
        {id:"next-nextconfigjs", url:"/next/nextconfigjs", title:"Mai multe despre next.config.js", subtitle:"", parent:"next-root"},
        {id:"next-api", url:"/next/api", title:"API", subtitle:"", parent:"next-root"},


    ]

    static indexUrl = "/util/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere in NextJS
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;