import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CnnIntroContent extends BaseContentPage {

    constructor(props) {
        super(props, "cnn-intro", IndexContent);
    }

    render() {
        return (
            <div className="home cnn">
                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify"}>
                    <b>1. Istoria CNN</b>
                </div>
                <br/>

                <div className={"text-justify"}>
                    <b>Anii 1950-1960: celule simple si celule complexe</b>
                    <br/>
                    Istoria relelelor neuronale convulutionale (CNN) incepe cu experimentele biologice din anii 1950.
                    In 1959, David Hubel si Torsten Wiesel au propus pentru recunoasterea modelelor folosirea a doua tipuri de celule, inspirate din cortexul vizual uman:
                    <ul>
                        <li>celule simple:<br/>
                            care sa răspunda la margini și bare cu diverse orientări
                        </li>
                        <li>celule complexe:<br/>
                            care sa răspunda la margini și bare cu diverse orientări, care pot fi deplasate in spatiu (adica au proprietatea de <i>invariatie spatiala</i>)<br/>
                            <i>de exemplu</i>: o celula simpla poate detecta o bara orizontala in partea de jos a unei imagini,
                            in timp ce o celula complexa poate detecta o bara orizontala in partea de mijloc sau sus a unei imagini
                        </li>
                    </ul>
                    In 1962, David Hubel si Torsten Wiesel au propus ca celule complexe realizeaza <i>invariatia spatiala</i> prin insumarea iesirilor mai multor celule simple
                    care prefera aceasi orientare (de exemplu, bare orizontale), dar campuri receptive diferite (de exemplu, partea de jos, partea de sus, partea de mijloc a imaginii).
                    Prin colectarea datelor de la o gramada de celule simple, o celula complexa poate detecta o bara orizontala oriunde ar fi in imagine.

                    <br/>
                    Acest concept - că detectoarele/celule simple pot fi „însumate” pentru a crea detectoare/celule mai complexe -
                    se găsește în întregul sistem vizual uman și este, de asemenea, baza fundamentală a modelelor de rețea neuronală de convoluție.

                    <br/>
                    <br/>
                    <b>Anii 1980: Neocognitronul</b>
                    <br/>
                    Neocognitronul a fost propus in anii 1980, de catre Dr. Kunihiko Fukushima, care a fost inspirat de modelul lui Hubel & Wiesel din 1959, fiind
                    o extensie a congnitronului [Fukushima, 1975] (<b>cognitronul</b> e o retea cu autoorganizare).
                    Modelul neocognitronului include componente denumite S-cell (celule S) și C-cell (celule C).
                    Acestea nu sunt celule biologice, ci mai degrabă operații matematice.
                    „Celulele S” stau în primul strat al modelului și sunt conectate la „celulele C” care se află în al doilea strat al modelului.
                    Ideea generală este de a surprinde conceptul „simplu-la-complex” și a-l transforma într-un model de calcul pentru recunoașterea vizuală a modelelor.

                    <br/>
                    <br/>
                    <b>Anii 1990: CNN</b>
                    <br/>
                    Prima lucrare privind rețelele neuronale convoluționale moderne (CNN) a avut loc în anii 1990,
                    inspirată de neocognitron si a fost scrisa de Yann LeCun și colab.
                    În lucrarea lor „Gradient-Based Learning Applied to Document Recognition”, ei au demonstrat că un model CNN
                    care agregă caracteristici mai simple în caracteristici progresiv mai complicate
                    poate fi utilizat cu succes pentru recunoașterea caracterelor scrise de mână.

                    <br/>
                    <br/>
                    <b>Anii 2010: AlexNet</b>
                    <br/>
                    În jurul anului 2012, CNN-urile s-au bucurat de o creștere uriașă a popularității după ce o CNN numită AlexNet
                    a obtinut o performanta ridicata in etichetarea imaginilor in provocarea <a href={"http://www.image-net.org/"}  rel="noreferrer"  target={"_blank"}>ImageNet</a> (baza de date cu imagini)
                    Alex Krizhevsky și colab. au publicat lucrarea „ImageNet Classification with Deep Convolutional Neural Networks” care descrie modelul câștigător AlexNet.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>
                                <a href={"https://glassboxmedicine.com/2019/04/13/a-short-history-of-convolutional-neural-networks/"}>The History of Convolutional Neural Networks</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CnnIntroContent;