import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class WhoisLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-whois", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Daca nu exista instalat:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install whois'}
                    </SyntaxHighlighter>

                    Pentru a obtine informații despre un domeniu înregistrat, adresa IP, detalii despre proprietatea domeniilor, cât și despre proprietari.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'whois scenic.ro'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'% The WHOIS service offered by ROTLD and the access to the records in the ROTLD WHOIS database \n' +
                            '% are provided for information purposes and to be used within the scope of technical or administrative\n' +
                            '% necessities of Internet operation or to remedy legal problems. The use for other purposes,\n' +
                            '% in particular for advertising and domain hunting, is not permitted.\n' +
                            '\n' +
                            '% Without prejudice to the above, it is explicitly forbidden to extract, copy and/or use or re-utilise\n' +
                            '% in any form and by any means (electronically or not) the whole or a quantitatively or qualitatively\n' +
                            '% substantial part of the contents of the WHOIS database without prior and explicit permission by ROTLD, \n' +
                            '% nor in any attempt hereof, to apply automated, electronic processes to ROTLD (or its systems).\n' +
                            '\n' +
                            '% ROTLD cannot, under any circumstances, be held liable in case the stored information would prove\n' +
                            '% to be wrong, incomplete or not accurate in any sense.\n' +
                            '\n' +
                            '% You agree that any reproduction and/or transmission of data for commercial purposes will always\n' +
                            '% be considered as the extraction of a substantial part of the content of the WHOIS database.\n' +
                            '\n' +
                            '% By submitting the query you agree to abide by this policy and accept that ROTLD can take measures\n' +
                            '% to limit the use of its WHOIS services in order to protect the privacy of its registrants or the\n' +
                            '% integrity of the database.\n' +
                            '\n' +
                            '% The ROTLD WHOIS service on port 43 never discloses any information concerning the registrant.\n' +
                            '\n' +
                            '% Registrant information can be obtained through use of the web-based whois service available from\n' +
                            '% the ROTLD website www.rotld.ro\n' +
                            '\n' +
                            '\n' +
                            '  Domain Name: scenic.ro\n' +
                            '  Registered On: 2016-08-27\n' +
                            '  Expires On: 2026-08-25\n' +
                            '  Registrar: Claus Web SRL\n' +
                            '  Referral URL: www.clausweb.ro\n' +
                            '\n' +
                            '  DNSSEC: Inactive\n' +
                            '\n' +
                            '  Nameserver: ns.clausweb.ro\n' +
                            '  Nameserver: ns.registar.ro\n' +
                            '  Nameserver: ns.romania-webhosting.com\n' +
                            '\n' +
                            '  Domain Status: OK\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WhoisLinuxContent;