import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class CommitDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-commit", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker commit / docker container commit</b>

                    <br/>
                    <br/>

                    Comanda <b>docker commit</b> se foloseste pentru a crea si salva:
                    <ul>
                        <li>o imagine fara a folosi Dockerfile</li>
                        <li>o imagine dintr-un container</li>
                    </ul>
                    Sa prespunem ca am pornit un container. Apoi, ne conectam in acel container si instalam o aplicatie.
                    Si vrem sa pastram aceasta <i>stare</i> a containerulului (cu aplicatia instalata) si dupa ce iesim din container (cu comanda <b>exit</b>).
                    Pentru asta, din alt terminal scriem:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker commit [-c|--change comanda_de_startup] nume_container_care_ruleaza'}
                    </SyntaxHighlighter>
                    in termina se va afisa id-ul noii imagini.

                    <br/>

                    Deci, se poate creea o imagine dint-un container.

                    <br/>
                    Este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container commit [-c|--change comanda_de_startup] nume_container_care_ruleaza'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it alpine sh'}
                    </SyntaxHighlighter>

                    in terminalul containerului <i>alpine</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apk add --update redis'}
                    </SyntaxHighlighter>

                    din alt terminal:
                    <br/>
                    pentru a afla id-ul containerului [id_container_care_ruleaza]:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE     COMMAND                  CREATED          STATUS          PORTS      NAMES\n' +
                            '22ad4a67809e   alpine    "sh"                     48 seconds ago   Up 39 seconds              objective_roentgen'}
                    </SyntaxHighlighter>

                    la comit se poate schimba <b>CMD|ENTRYPOINT|ENV|EXPOSE|LABEL|ONBUILD|USER|VOLUME|WORKDIR</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker commit -c \'CMD ["redis-server"]\' 22ad4a67809e '}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'sha256:72dbce97252a7f90a982e8ac94a3cda2f759984299dbb9edbb987b156db9afd7'}
                    </SyntaxHighlighter>

                    daca vom da <b>docker ps</b> se vor afisa acelasi contaiere pornite ca mai sus.
                    <br/>
                    pentru a crea si porni noul container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run 72dbce97252a7f90 '}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'1:C 07 Oct 2022 15:01:11.041 # oO0OoO0OoO0Oo Redis is starting oO0OoO0OoO0Oo\n' +
                            '1:C 07 Oct 2022 15:01:11.041 # Redis version=7.0.5, bits=64, commit=7658871b, modified=0, pid=1, just started\n' +
                            '1:C 07 Oct 2022 15:01:11.041 # Warning: no config file specified, using the default config. In order to specify a config file use redis-server /path/to/redis.conf\n' +
                            '1:M 07 Oct 2022 15:01:11.043 * monotonic clock: POSIX clock_gettime\n' +
                            '1:M 07 Oct 2022 15:01:11.044 * Running mode=standalone, port=6379.\n' +
                            '1:M 07 Oct 2022 15:01:11.044 # Server initialized\n' +
                            '1:M 07 Oct 2022 15:01:11.044 # WARNING overcommit_memory is set to 0! Background save may fail under low memory condition. To fix this issue add \'vm.overcommit_memory = 1\' to /etc/sysctl.conf and then reboot or run the command \'sysctl vm.overcommit_memory=1\' for this to take effect.\n' +
                            '1:M 07 Oct 2022 15:01:11.045 * Ready to accept connections'}
                    </SyntaxHighlighter>

                    <hr/>

                    Comit container cu optiunea <b>--author</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container commit --author="kj" ubuntu-dev ubuntu-kj:v1'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>ubuntu-dev {"=>"} nume container care ruleaza</li>
                        <li>ubuntu-kj:v1 {"=>"} nume imagine + tag</li>
                    </ul>

                    <hr/>
                    <ul>
                        <li>
                            in mod implicit, pe parcursul procesului de commit, procesele din cotainer sunt puse pe <i>pauza</i>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CommitDockerContent;