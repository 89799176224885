import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlGetContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-get", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl get</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectrl get</b> este folosita pentru a lista obiectele
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get <object>'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            object: specifica tipul de obiect pentru care vrem sa obtinem informatii (de exemplu: <i>pods</i> / <i>services</i> / <i>deployments</i>)
                        </li>
                    </ul>

                    <hr/>
                    <b>1.1. Kubectl get pods</b>
                    <br/>
                    <br/>

                    Pentur a afisa podu-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    se poate afisa ceva similar cu:
                    <SyntaxHighlighter>
                        {'NAME                                READY   STATUS    RESTARTS   AGE\n' +
                            'client-deployment-8d9b8cdd6-crc4m   1/1     Running   0          41m'}
                    </SyntaxHighlighter>

                    Pentru a afisa IP-ul pe care rulaaza un pod se mai adauga parametrul <b>-o wide</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -o wide'}
                    </SyntaxHighlighter>
                    se va afisa ceva simular cu:
                    <SyntaxHighlighter>
                        {'NAME                                READY   STATUS    RESTARTS   AGE   IP           NODE       NOMINATED NODE   READINESS GATES\n' +
                            'client-deployment-8d9b8cdd6-crc4m   1/1     Running   0          42m   172.17.0.3   minikube   <none>           <none>'}
                    </SyntaxHighlighter>

                    Cand se afiseaza:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    se afiseaza doar pod-urile din <i>default</i>namespaces.
                    <br/>
                    Daca vrem sa afisam podurile in alt namepaces:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --namespace=kube-system'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                               READY   STATUS    RESTARTS         AGE\n' +
                            'coredns-565d847f94-pws8l           1/1     Running   0                41d\n' +
                            'etcd-minikube                      1/1     Running   0                41d\n' +
                            'kube-apiserver-minikube            1/1     Running   2 (6d4h ago)     41d\n' +
                            'kube-controller-manager-minikube   1/1     Running   3 (41d ago)      41d\n' +
                            'kube-proxy-z8gr2                   1/1     Running   0                41d\n' +
                            'kube-scheduler-minikube            1/1     Running   0                41d\n' +
                            'storage-provisioner                1/1     Running   39 (5d12h ago)   41d'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Kubectl get nodes</b>
                    <br/>
                    <br/>

                    Pentru a afisa nodurile:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get nodes'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       STATUS   ROLES           AGE   VERSION\n' +
                            'minikube   Ready    control-plane   41d   v1.25.2\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa mai multe detalii legate de noduri:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get nodes -o wide'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       STATUS   ROLES           AGE   VERSION   INTERNAL-IP    EXTERNAL-IP   OS-IMAGE             KERNEL-VERSION      CONTAINER-RUNTIME\n' +
                            'minikube   Ready    control-plane   52d   v1.25.2   192.168.49.2   <none>        Ubuntu 20.04.5 LTS   5.15.0-50-generic   docker://20.10.18\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa etichetele (LABELS):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get nodes --show-labels'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       STATUS   ROLES           AGE   VERSION   LABELS\n' +
                            'minikube   Ready    control-plane   52d   v1.25.2   beta.kubernetes.io/arch=amd64,beta.kubernetes.io/os=linux,kubernetes.io/arch=amd64,kubernetes.io/hostname=minikube,kubernetes.io/os=linux,minikube.k8s.io/commit=fe869b5d4da11ba318e\n' +
                            'b84a3ac00f336411de7ba,minikube.k8s.io/name=minikube,minikube.k8s.io/primary=true,minikube.k8s.io/updated_at=2022_11_04T22_16_58_0700,minikube.k8s.io/version=v1.27.1,node-role.kubernetes.io/control-plane=,node.kubernetes.io/exclude-from-external-load-balancers='}
                    </SyntaxHighlighter>

                    Etichetare nod:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes node-3 disktype=ssd'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. Kubectl get namespaces</b>
                    <br/>
                    <br/>

                    Pentru a afisa namespaces:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get namespaces'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                   STATUS   AGE\n' +
                            'default                Active   41d\n' +
                            'ingress-nginx          Active   41d\n' +
                            'kube-node-lease        Active   41d\n' +
                            'kube-public            Active   41d\n' +
                            'kube-system            Active   41d\n' +
                            'kubernetes-dashboard   Active   41d\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.4. Kubectl get ...</b>
                    <br/>
                    <br/>

                    Afisare servicii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get services'}
                    </SyntaxHighlighter>

                    Afisare deployments:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployments'}
                    </SyntaxHighlighter>

                    Afisare persistent volume:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pv'}
                    </SyntaxHighlighter>

                    Afisare persistent volume claim:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pvc'}
                    </SyntaxHighlighter>

                    Afisare detalii legate de stocare:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get storageclass'}
                    </SyntaxHighlighter>

                    Pentru a afisa secretele:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>


                    Pentru a afisa certificatele:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get certificates'}
                    </SyntaxHighlighter>

                    Pentru a afisa hpa (horizontal pod autoscaler) - (se construieste cu comanda <b>kubectl autoscale</b>):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get hpa'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlGetContent;