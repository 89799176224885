class Auth{

    ACCESS_TOKEN_NAME = "ACCESS_TOKEN_NAME";

    isAuthenticated() {
        // if (true){
        //     return true;
        // }
        // case 1. token on url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')

        if (token!=null){
            // try, verify if token is already in localstorage
            let tokenStored = localStorage.getItem(this.ACCESS_TOKEN_NAME);
            let split = tokenStored.split(".");
            let oldToken = split[0];
            if (oldToken === token ){
                let ok =  this.valid(oldToken,split[1]);
                if (!ok){
                    localStorage.removeItem(this.ACCESS_TOKEN_NAME);
                }else{
                    return true;
                }
            }
            return this.valid(token,null);
        }else{
            let tokenStored = localStorage.getItem(this.ACCESS_TOKEN_NAME);
            if (tokenStored!=null){
                let split = tokenStored.split(".");
                let ok =  this.valid(split[0],split[1]);
                if (!ok){
                    localStorage.removeItem(this.ACCESS_TOKEN_NAME);
                }
                return ok;
            }else{
                return false;
            }
        }

    }

    valid(token,date){
        if (date==null){

            /*
            return fetch(process.env.REACT_APP_DOPAMINA_DATA_API_URL + '/util/wait', {
                method: 'POST',
            }).then(response => response.json())
                .then(jsonData => {
                    let ok =  Object.entries(jsonData).length === 0;

                    if (ok) {
                        localStorage.setItem(this.ACCESS_TOKEN_NAME, token + "." + Date.now());
                    }else{
                        localStorage.removeItem(this.ACCESS_TOKEN_NAME);
                    }

                    return ok;
                })
            */

            return false;

        }else{
            // check if the token is expired
            let time = Date.now();
            let tokenDate = date;
            let diff = this.diffMinutes(time, tokenDate);
            if (diff>10000){
                return false;
            }else {
                return true;
            }
        }
    }

    logout() {
        localStorage.removeItem(this.ACCESS_TOKEN_NAME);
    }

    diffMinutes(dt2, dt1) {
        let diff =(dt2 - dt1) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

}

export default Auth;