import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class TarLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-tar", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a creea o arhiva cu un fisier sau mai multe fisiere, fara compresie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tar -cf arhiva.tar fisier1.txt fisier2.txt'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>-c : creaza o noua arhiva</li>
                        <li>-f [nume-fisier.tar]: pentru a specifica numele fisierului <i>tar</i> rezultat</li>
                        <li>-x : dezarhiveaza o arhiva</li>
                        <li>-t : afiseaza lista de fisiere din arhiva</li>
                        <li>-z : comprima arhiva</li>
                        <li>-v : afisare detalii</li>
                    </ul>

                    Exemplu 1: arhivare fisiere din directorul curent in fisierul <i>arhiva.tar</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tar -cf arhiva.tar .'}
                    </SyntaxHighlighter>

                    Exemplu 2: afisare fisiere din <i>arhiva.tar</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tar -tf arhiva.tar'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'./\n' +
                            './xx\n' +
                            './a.txt.save\n' +
                            './a.txt\n' +
                            './test/\n' +
                            './test.txt\n'}
                    </SyntaxHighlighter>

                    Exemplu 3: arhivare fisiere din directorul curent in fisierul <i>arhiva.tar.gz</i>, cu compresie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tar -czf arhiva.tar.gz .'}
                    </SyntaxHighlighter>

                    Exemplu 4: decompresie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tar -xvzf ../arhiva.tar.gz'}
                    </SyntaxHighlighter>
                    Decompresia se face in directorul curent.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TarLinuxContent;