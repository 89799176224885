import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class Anexa1JavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-anexa-1", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    La inceput, n-a fost nimic. Adica, ce vrea sa spuna propozitia precedentă este ca a fost ceva, prin lipsa acestui nimic.
                    Ca alfel, daca vrei sa sugerezi vidul sau nimicul, zici "la inceput, n-a fost ceva".
                    <hr/>
                    In Javascript, la inceput au fost doua acolade - prima deschisa, iar a doua inchisa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{}'}
                    </SyntaxHighlighter>
                    Si Javascript a vazut ca era bine ce era si a numit aceasta constructie <b>bloc</b>. <br/>
                    In acest loc (numit bloc), misunau mici creaturi numite <i>instructiuni</i>.
                    <br/>
                    Acestea puteau sa se nasca dupa inceput si sa moara inainte de sfarsit.
                    <br/>
                    Si toate instructinile traiau fericite in acest tarc format din acolade.
                    <br/>
                    Doar ca aveau o viata efemeră, depinzand de viteza de executie a universului, numit procesor, care guverna durata lor de viata.
                    <br/>
                    Cu trecerea anilor aceasta viata a devenit tot mai scurta.
                    <hr/>
                    Si lui Javascript i se paru, intr-o zi, ca parca construia castele de nisip care se risipeau intr-o clipa.
                    Si nu asta era problema. Ci ca o lua de la 0 de la fiecare data, chiar daca unele dintre aceste <i>lumi</i>/<i>castele de nisip</i> erau identice.
                    <br/>
                    Si atunci a dat cate un nume acestor blocuri.
                    <br/>
                    Si Javascript a vazut ca era bine ce era si inainte sa le dea un nume oficial, pentru ca uneori unele castele de nisip,
                    variau doar prin culoarea steagului arborat pe cel mai inalt turn, sau prin culoarea caciulitei purtata pe cap de micile instructiuni,
                    a decis sa mai adauge pe langa un nume si posibilitatea de a schimba din comportamentul micilor instructiuni din bloc prin intermediul parametrilor.
                    <br/>
                    Si Javascript a vazut ca era bine ce era si a numit aceasta constructie <i>functie</i>.
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Anexa1JavaScriptContent;