import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaCustomConfigSystemSettingsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-custom-config-system-settings", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurari personalitate in System Settings</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_11.png'}/>
                    </div>

                    Language.properties:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'category-section.agnes = Sectiunea Agnes\n' +
                            '\n' +
                            'category.agnes.config.category = Categoria Agnes\n' +
                            '\n' +
                            'agnes-config-title = Configurari Agnes\n' +
                            '\n' +
                            'agnes-config-description = Configurari pentru Strada Agnes\n' +
                            '\n' +
                            'config.field.label.nrStreet = Nr. strada\n' +
                            'config.field.description.nrStreet = Numar stada\n' +
                            '\n' +
                            'config.field.label.bell = Sonerie\n' +
                            'config.field.description.bell = Are sonerie?\n' +
                            '\n' +
                            'config.field.label.password = Parola\n' +
                            'config.field.description.password = Specifica o parola pentru a intra'}
                    </SyntaxHighlighter>

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                            '    compileOnly group: "com.liferay.portal", name: "release.portal.api", version: "7.4.3.120-ga120"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Bundle-Name: Agnes Training Configuration\n' +
                            'Bundle-SymbolicName: ro.agnes.configuration\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Web-ContextPath: /agnes-configuration\n' +
                            '-dsannotations-options: inherit\n' +
                            'Export-Package: ro.agnes.configuration'}
                    </SyntaxHighlighter>

                    AgnesConfigCategory.java:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.configuration;\n' +
                            '\n' +
                            'import com.liferay.configuration.admin.category.ConfigurationCategory;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '@Component(service = ConfigurationCategory.class)\n' +
                            'public class AgnesConfigurationCategory implements ConfigurationCategory{\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getCategoryIcon() {\n' +
                            '        return _CATEGORY_ICON;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getCategoryKey() {\n' +
                            '        return _CATEGORY_KEY;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getCategorySection() {\n' +
                            '        return _CATEGORY_SECTION;\n' +
                            '    }\n' +
                            '\n' +
                            '    private static final String _CATEGORY_ICON = "cog";\n' +
                            '\n' +
                            '    public static final String _CATEGORY_KEY = "agnes.config.category";\n' +
                            '\n' +
                            '    private static final String _CATEGORY_SECTION = "agnes";\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_14.png'}/>
                    </div>

                    AgnesConfig.java:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.configuration;\n' +
                            '\n' +
                            'import aQute.bnd.annotation.metatype.Meta;\n' +
                            'import com.liferay.portal.configuration.metatype.annotations.ExtendedObjectClassDefinition;\n' +
                            '\n' +
                            '@ExtendedObjectClassDefinition(\n' +
                            '        category = AgnesConfigurationCategory._CATEGORY_KEY,\n' +
                            '        scope = ExtendedObjectClassDefinition.Scope.SYSTEM\n' +
                            ')\n' +
                            '@Meta.OCD(\n' +
                            '        id = "ro.agnes.configuration.AgnesConfiguration",\n' +
                            '        localization = "content/Language",\n' +
                            '        name = "agnes-config-title",\n' +
                            '        description = "agnes-config-description"\n' +
                            ')\n' +
                            'public interface AgnesConfiguration {\n' +
                            '\n' +
                            '    @Meta.AD(\n' +
                            '            deflt = "19",\n' +
                            '            required = true,\n' +
                            '            description = "config.field.description.nrStreet",\n' +
                            '            name = "config.field.label.nrStreet"\n' +
                            '\n' +
                            '    )\n' +
                            '    String nrStreet();\n' +
                            '\n' +
                            '    @Meta.AD(\n' +
                            '            deflt = "true",\n' +
                            '            required = true,\n' +
                            '            description = "config.field.description.bell",\n' +
                            '            name = "config.field.label.bell"\n' +
                            '\n' +
                            '    )\n' +
                            '    boolean bell();\n' +
                            '\n' +
                            '    @Meta.AD(\n' +
                            '            deflt = "test",\n' +
                            '            required = false,\n' +
                            '            description = "config.field.description.password",\n' +
                            '            name = "config.field.label.password",\n' +
                            '            type = Meta.Type.Password\n' +
                            '\n' +
                            '    )\n' +
                            '    String password();\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_12.png'}/>
                    </div>

                    <b>2. Salvare configurari in \bundles\osgi\config</b>
                    <br/>
                    <br/>

                    Configurarile pot fi exportate:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_15.png'}/>
                    </div>

                    Si puse in \bundles\osgi\config

                    <hr/>
                    <b>2. Obtinere valori configurari</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'  AgnesConfiguration agnesConfig = ConfigurationProviderUtil.getSystemConfiguration(AgnesConfiguration.class);\n' +
                            '        _log.info("nrStrada=" + agnesConfig.nrStreet());'}
                    </SyntaxHighlighter>

                    (nu a functionat!)

                    <br/>
                    Alta incercare (functioneaza):
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'public interface AgnesComponentService {\n' +
                            '\n' +
                            '    String getNrStreet();\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.configuration.util;\n' +
                            '\n' +
                            'import com.liferay.portal.configuration.metatype.bnd.util.ConfigurableUtil;\n' +
                            'import org.osgi.service.component.annotations.Activate;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Modified;\n' +
                            'import ro.agnes.configuration.AgnesComponentService;\n' +
                            'import ro.agnes.configuration.AgnesConfiguration;\n' +
                            'import ro.agnes.configuration.AgnesConfigurationKey;\n' +
                            '\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        configurationPid = AgnesConfigurationKey.KEY,\n' +
                            '        service = AgnesComponentService.class,\n' +
                            '        immediate = true\n' +
                            ')\n' +
                            'public class AgnesComponentServiceImpl implements AgnesComponentService {\n' +
                            '\n' +
                            '    private volatile AgnesConfiguration _configuration;\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    @Modified\n' +
                            '    protected void activate(Map<String, Object> properties) {\n' +
                            '        _configuration = ConfigurableUtil.createConfigurable(AgnesConfiguration.class, properties);\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getNrStreet(){\n' +
                            '        return _configuration.nrStreet();\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Observatii</b>
                    <ul>
                        <li>configurarile sunt persistate in tabele <b>configuration_</b>

                            <ul>
                                <li>
                                    configurationId:
                                    <br/>
                                    ro.agnes.configuration.AgnesConfiguration
                                </li>
                                <li>
                                    dictionary:
                                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                        {':org.apache.felix.configadmin.revision:=L"1"\n' +
                                            'bell=B"true"\n' +
                                            'nrStreet="19"\n' +
                                            'service.bundleLocation="?"\n' +
                                            'service.pid="ro.agnes.configuration.AgnesConfiguration"\n'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                        <li>
                            la o noua actualizare:
                            <li>
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {':org.apache.felix.configadmin.revision:=L"2"\n' +
                                        'bell=B"true"\n' +
                                        'nrStreet="191"\n' +
                                        'service.bundleLocation="?"\n' +
                                        'service.pid="ro.agnes.configuration.AgnesConfiguration"\n'}
                                </SyntaxHighlighter>
                            </li>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Scope = PORTLET_INSTANCE (netestat)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.configuration;\n' +
                            '\n' +
                            'import aQute.bnd.annotation.metatype.Meta;\n' +
                            'import com.liferay.portal.configuration.metatype.annotations.ExtendedObjectClassDefinition;\n' +
                            '\n' +
                            '@ExtendedObjectClassDefinition(\n' +
                            '        category =  AgnesConfigurationCategory._CATEGORY_KEY,\n' +
                            '        scope = ExtendedObjectClassDefinition.Scope.PORTLET_INSTANCE\n' +
                            ')\n' +
                            '@Meta.OCD(\n' +
                            '        id = "ro.agnes.configuration.AgnesPortletInstanceConfiguration",\n' +
                            '        localization = "content/Language",\n' +
                            '        name = "agnes-portlet-instance-configuration-name"\n' +
                            ')\n' +
                            'public interface AgnesPortletInstanceConfiguration {\n' +
                            '\n' +
                            '    @Meta.AD(name = "file-extensions", deflt = ".pdf,.jpg,.jpeg,.png")\n' +
                            '    public String fileExtensions();\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'     AgnesPortletInstanceConfiguration configuration = ConfigurationProviderUtil.getConfiguration(\n' +
                            '                AgnesPortletInstanceConfiguration.class,\n' +
                            '                new ParameterMapSettingsLocator(\n' +
                            '                        request.getParameterMap(),\n' +
                            '                        new PortletInstanceSettingsLocator(themeDisplay.getLayout(), portletDisplay.getPortletResource())\n' +
                            '                )\n' +
                            '        );'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaCustomConfigSystemSettingsLiferayContent;