import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FormPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-form", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Creare formular</b>
                    <br/>
                    <br/>

                    Creare forma in fisierul <i>edit.jsp</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<b>Creare/editare configurare</b>\n' +
                        '\n' +
                        '<portlet:actionURL name = "/save/config" var="saveConfigURL"/>\n' +
                        '\n' +
                        '<aui:form name="forma" action="${saveConfigURL}">\n' +
                        '\n' +
                        '    <aui:input name="code"></aui:input>\n' +
                        '    <aui:input name="name"></aui:input>\n' +
                        '    <aui:input name="value"></aui:input>\n' +
                        '    <aui:input name="typeId"></aui:input>\n' +
                        '\n' +
                        '    <aui:button-row>\n' +
                        '        <aui:button cssClass="btn btn-primary" type="submit"></aui:button>\n' +
                        '    </aui:button-row>\n' +
                        '</aui:form>\n' +
                        '\n' +
                        '<%--<a href="${saveConfigURL}">Salveaza configurare</a>--%>'}
                    </SyntaxHighlighter>

                    Forma este creata folosind taguri <b>aui</b>; acestea sunt declarate in <b>init.jsp</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %>'}
                    </SyntaxHighlighter>

                    Metoda <b>doProcessAction()</b> din clasa MVC Action Command, va putea primi parametrii trimisi din forma, folosind clasa <b>ParamUtil</b> si metoda <b>get()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' @Override\n' +
                        '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                        '\n' +
                        '        String code = ParamUtil.get(actionRequest, "code", "");\n' +
                        '        String name = ParamUtil.get(actionRequest, "name", "");\n' +
                        '        String value = ParamUtil.get(actionRequest, "value", "");\n' +
                        '        String typeId = ParamUtil.get(actionRequest, "typeId", "");\n' +
                        '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Persistare formular</b>
                    <br/>
                    <br/>

                    In <b>build.gradle</b> se adauga dependinta:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'compileOnly project(":modules:data:data-api")'}
                    </SyntaxHighlighter>

                    Se injecteaza referinta serviciului <i>ConfigIBService</i> cu ajutorul adnotarii <b> @Reference</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'    private ConfigIBService configIBService;\n' +
                        '    \n' +
                        '    @Reference(unbind = "-")\n' +
                        '    public void setConfigIBService(ConfigIBService configIBService) {\n' +
                        '        this.configIBService = configIBService;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                        '\n' +
                        '        String code = ParamUtil.get(actionRequest, "code", "");\n' +
                        '        String name = ParamUtil.get(actionRequest, "name", "");\n' +
                        '        String value = ParamUtil.get(actionRequest, "value", "");\n' +
                        '        String typeId = ParamUtil.get(actionRequest, "typeId", "");\n' +
                        '\n' +
                        '        //ConfigIBLocalServiceUtil.addConfigIB(code, name, value);\n' +
                        '\n' +
                        '        configIBService.addConfigIB(code, name, value);\n' +
                        '    }'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>*/}
                        {/*            MVC Render Command*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FormPortletContent;