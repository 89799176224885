import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class KeycloakIntegrationLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-keycloak-integration", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Liferay oferă suport încorporat pentru sistemele SSO, inclusiv OpenID Connect. Se pot adăuga mai multe configurații OpenID Connection Provider.
                    <br/>
                    Să presupunem că instanța Liferay este disponibilă la <b>https://localhost:8080</b>.
                    <br/> <br/>
                    Ne vom folosi de informatiile obtinute pe baza serviciului:
                    <ul>
                        <li>pe baza serviciului <b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration </li>
                    </ul>

                    <b>1. Configurare OpenID Connect</b>
                    <ul>
                        <li>
                            <b>Activare OpenID Connect</b>
                            <br/>
                            selectare <b>Control Panel {"=>"} System Settings {"=>"} (Seciunea Security) SSO:</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-1.png'}/>
                            </div>

                            selectare <b>OpenID Connect</b>, bifare <b>Enabled</b> (Activat) si apasat pe butonul <b>Save</b> (Salveaza):

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-2.png'}/>
                            </div>

                            selectare <b>OpenID Connect Provider</b> si apasat pe butonul <b>Adauga</b>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-3.png'}/>
                            </div>

                            se completeza formularul, cu informatiile completate in <b>Keycloak</b>:
                            <ul>
                                <li>
                                    <b>Provider Name</b> (<b>Alias</b> pentru <b>Identity provider</b> in <b>Keycloak</b>):
                                    <br/>
                                    <i>vanilla-keycloak-oidc</i>
                                </li>
                                <li>
                                    <b>OpenID Connect Client ID</b> (<b>Client ID</b> in <b>Keycloak</b>):
                                    <br/>
                                    <i>liferay-portal-client</i>
                                </li>
                                <li>
                                    <b>OpenID connect client secret</b>(<b>Client Secret</b> in <b>Keycloak</b>):
                                    <br/>
                                    <i>0c778028-f03e-4bdd-b835-8ecb33653ea0</i>
                                </li>
                                <li>
                                    <b>Scopes</b>(Domeniu):
                                    <br/>
                                    <i>openid email profile</i>
                                </li>
                                <li>
                                    <b>Authorization Endpoint</b> (<b>Authorization URL</b> in <b>Keycloak</b> sau <b>authorization_endpoint</b> in raspunsul JSON al servciului oferit de <b>Keycloak</b>):
                                    <br/>
                                    <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth</i>
                                </li>
                                <li>
                                    <b>Issuer URL</b> (<b>issuer</b> in raspunsul JSON al servciului oferit de <b>Keycloak</b>):
                                    <br/>
                                    <i>http://localhost:8180/auth/realms/vanilla-realm</i>
                                </li>
                                <li>
                                    <b>JWKS URI</b> (<b>jwks_uri</b> in raspunsul JSON al servciului oferit de <b>Keycloak</b>):
                                    <br/>
                                    <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/certs</i>
                                </li>

                                <li>
                                    <b>Subject Types</b>:
                                    <br/>
                                    <i>public</i>
                                </li>

                                <li>
                                    <b>Token Endpoint</b> (<b>Token URL</b> in <b>Keycloak</b>) <b>token_endpoint</b> in raspunsul JSON al servciului oferit de <b>Keycloak</b>):
                                    <br/>
                                    <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token</i>
                                </li>
                                <li>
                                    <b>User Information Endpoint</b> (<b>userinfo_endpoint</b> in raspunsul JSON al servciului oferit de <b>Keycloak</b>):
                                    <br/>
                                    <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/userinfo</i>
                                </li>

                                <div style={{padding:10}}>
                                    <img alt={""} style={{width:950}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-4.png'}/>
                                </div>

                                <li>se apasa butonul <b>Save</b>(Salveaza)</li>

                                <div style={{padding:10}}>
                                    <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-5.png'}/>
                                </div>

                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>2. Testare</b>
                    <br/>
                    Portalul Liferay poate fi acum accesat folosind protocolul <b>OpenID Connect</b> cu utilizatori din <b>Keycloak</b>.
                    <br/>
                    Portletul standard de conectare al Liferay va afișa linkul de conectare prin <b>OpenID Connect</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-6.png'}/>
                    </div>
                    <br/>
                    Când utilizatorul face clic pe linkul <b>OpenID Connect</b>, acesta este redirecționat către pagina de unde utilizatorul poate selecta furnizorul de autentificare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-7.png'}/>
                    </div>
                    <br/>
                    Puteți avea mai mulți furnizori de autentificare, acesta este motivul pentru care se oferă alegerea.
                    În cazul nostru avem un singur furnizor care se numește <b>vanilla-keycloak-oidc</b>.
                    <br/>
                    Odată ce ați selectat furnizorul și ați făcut clic pe butonul <b>Sign In</b>, <b>Liferay</b> trimite utilizatorul la pagina de conectare <b>Keycloak</b>.
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-8.png'}/>
                    </div>

                    Pe pagina de conectare, introduceți credentialele de conectare valide.
                    <br/>
                    Dacă credentialele introduse sunt corecte, atunci utilizatorul va fi redirecționat către pagina de start a portalului Liferay.
                    <br/>
                    <br/>

                    <b>Dacă utilizatorul nu este prezent pe Liferay, acesta va fi creat folosind informațiile obținute de la furnizorul de identitate (Keycloak)</b>


                    <hr/>

                    <b>Posibile erori:</b>
                    <br/>
                    <b>1. Error: Only known users are allowed to sign in using OpenId Connect.</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-9.png'}/>
                    </div>
                    <b>Cauza</b>:
                    <ul>
                        <li>
                            Din <b>Liferay</b> nu este bifata optiunea <b>"Permite străinilor să creeze conturi?"</b> si in <b>Liferay</b> nu exista utilizatorul!
                        </li>
                    </ul>
                    <b>Rezolvare</b>:
                    <ul>
                        <li>
                            Se bifata optiunea <b>Allow strangers to create accounts? (Permite străinilor să creeze conturi?) (Instance Settings {"=>"} User Administration {"=>"} General)</b>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-12.png'}/>
                            </div>
                            Si varianta in limba romana:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-11.png'}/>
                            </div>
                        </li>
                    </ul>

                    <br/>
                    <br/>

                    <b>2. Error: Internal Server Error</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/integration-keycloak-10.png'}/>
                    </div>

                    <b>Cauza</b>: <b>Liferay 7.3 nu suporta HS256, doar RS256.</b>

                    <br/>
                    Daca se adauga in campul <b>Discovery Endpoint</b> (System Settings {"=>"} SSO {"=>"} OpenID Connect Provider):
                    <br/>
                    <i>http://localhost:8180/auth/realms/vanilla-realm/.well-known/openid-configuration</i>
                    <br/> <br/>
                    <b>Explicatie:</b>
                    <br/>
                    In log-urile server-ului Liferay:
                    <SyntaxHighlighter   showLineNumbers={true} language="log" style={androidstudio}>
                        {'2021-11-06 10:59:39.814 ERROR [http-nio-8080-exec-9][status_jsp:855] Unable to validate tokens: Signed JWT rejected: Another algorithm expected, or no matching key(s) found'}
                    </SyntaxHighlighter>


                    <br/>
                    <SyntaxHighlighter   showLineNumbers={true} language="jspn" style={androidstudio}>
                        {'"id_token_signing_alg_values_supported":[\n' +
                        '                            "PS384",\n' +
                        '                            "ES384",\n' +
                        '                            "RS384",\n' +
                        '                            "HS256",\n' +
                        '                            "HS512",\n' +
                        '                            "ES256",\n' +
                        '                            "RS256",\n' +
                        '                            "HS384",\n' +
                        '                            "ES512",\n' +
                        '                            "PS256",\n' +
                        '                            "PS512",\n' +
                        '                            "RS512"\n' +
                        ']'}
                    </SyntaxHighlighter>

                    <b>Rezolvare:</b>
                    <ul>
                        <li>Campul din Liferay <b>Discovery Endpoint</b> (System Settings {"=>"} SSO {"=>"} OpenID Connect Provider) trebuie lasat gol!</li>
                        {/*<li>Campul din Keycloak <b>Client Assertion Signature Algorithm</b> (Identity Providers) trebuie setat la <b>RS256</b></li>*/}
                    </ul>

                    <hr/>
                    <b>3. Implementarea Single Logout (SLO)</b>
                    <br/>
                    Implementarea a OpenID Connect pe <b>Liferay versiunea 7.3 nu acceptă funcționalitatea SLO sau Single Logout</b>.
                    <br/>
                    Aceasta înseamnă că atunci când un utilizator este deconectat de la Liferay (operația de deconectare sau sesiune a expirat), acesta nu este deconectat automat de la Keycloak, cu consecința că un nou acces nu va necesita introducerea acreditărilor utilizatorului pe Keycloak (dacă sesiunea este încă activă).
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://techblog.smc.it/en/2021-10-15/how-to-connect-keycloak-liferay-openid-connect"}>
                                    How to connect Keycloak and Liferay via OpenID Connect
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360024805271-Authenticating-with-OpenID-Connect"}>
                                    Authenticating with OpenID Connect
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.aimprosoft.com/blog/liferay-sso-integration/"}>
                                    How to Implement Keycloak SSO Authentication in Liferay DXP
                                </a>
                            </div>
                        </li>

                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://lifedev-solutions.blogspot.com/2019/10/liferay-keycloak-integration-using.html"}>*/}
                        {/*            Liferay Keycloak integration using OpenID*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default KeycloakIntegrationLiferayContent;