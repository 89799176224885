import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ListPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-lists", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>   15. Python: Liste</b>

                    <br/>
                    <br/>

                    O <b>lista</b> este declarata intre <b>parenteze patrate</b> [...] ([] reprezinta o lista goala) si fiecare elemente poate fi accesat (pentru consultare sau modificare) printr-un index care porneste de la 0.
                    Indexul poate fi orice expresie.
                    <br/><br/>
                    <b>Lista este un tip de date în Python folosit pentru a stoca mai multe obiecte. <br/>
                        Este o colecție ordonată și mutabilă de articole separate prin virgulă între paranteze drepte.
                    </b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [10, False, 0.1, None, True, "text"]'}
                    </SyntaxHighlighter>

                    <b>Listele pot fi impricate</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [10, False, 0.1, [1, 2], 2]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Lungimea unei liste</b> se poate obtine prin intemediul functiei <b>len()</b> (exemplu: len(nume_variabila_list)).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [2, 41, 31, 1]\n' +
                        'print("Lista=",lista)\n' +
                        '\n' +
                        'lista[0]=3\n' +
                        'print("Lista (noua)=",lista)\n' +
                        '\n' +
                        'print("Elementul al-3-lea din lista=", lista[2])\n' +
                        '\n' +
                        'print("Lungime lista=",len(lista))'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Lista= [2, 41, 31, 1]\n' +
                        'Lista (noua)= [3, 41, 31, 1]\n' +
                        'Elementul al-3-lea din lista= 31\n' +
                        'Lungime lista= 4'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>2. Stegerea unui element din lista</b> se realizeaza prin intermediul instructiunii (cuvant cheie) <b>del</b> (de exemplu: del element_lista)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [2, 41, 31, 1]\n' +
                        '\n' +
                        'del lista[2]\n' +
                        'print("Lista (noua)=",lista)'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Lista (noua)= [2, 41, 1]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Accesare elemente folosind index negativ</b> (-1 inseamna ultimul element si se poate merge cu indecsi negativ pana la -len(lista); dupa care se va obtine eroare)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [1, 2, 5, 7]\n' +
                        'print(lista[-1])\n' +
                        'print(lista[-2])\n' +
                        'print(lista[-4])\n' +
                        'print(lista[-5])'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'7\n' +
                        '5\n' +
                        '1\n' +
                        'Traceback (most recent call last):\n' +
                        '  File "main.py", line 5, in <module>\n' +
                        '    print(lista[-5])\n' +
                        'IndexError: list index out of range'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>4. Adaugare la sfarsitul listei </b>
                    <br/>
                    Se realizeaza folosind metoda <b>append()</b>;
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [1, 2, 5, 2]\n' +
                        'list.append(7)\n' +
                        '\n' +
                        'print(list)'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[1, 2, 5, 2, 7]'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>5. Inserare oriunde in lista </b>
                    <br/>
                    Se realizeaza folosind metoda <b>insert(pozitie, element)</b>.
                    Toate elementele existente incepand cu indexul <b>pozitie</b> sunt deplasate spre dreapta, pentru a face loc noului element.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [1, 2, 5, 2]\n' +
                        'list.insert(1,777)\n' +
                        'print(list)'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[1, 777, 2, 5, 2]'}
                    </SyntaxHighlighter>
                    Daca <b>pozitie</b> e dupa lungimea listei se va adauga la final, iar daca este inainte de prima pozitie se va adauga la inceput.
                    In plus, se va pastra conventia cu indecsi negativi.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [1, 2, 5, 2]\n' +
                        'list.insert(-1,777)\n' +
                        'print(list)'}
                    </SyntaxHighlighter>
                    Rezultat (ultimul element, reprezentat de indexul -1, se deplaseaza spre dreapta, sa poata intra elementul cu valoarea 777):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[1, 2, 5, 777, 2]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Iterare </b>
                    <br/>
                    Se realizeaza folosind metoda <b>append()</b>;
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [1, 2, 5, 2]\n' +
                        '\n' +
                        'for i in list:\n' +
                        '    print(i)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Sortare </b>
                    <br/>
                    Se realizeaza folosind metoda <b>sort()</b>;
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [1, 2, 5, 2]\n' +
                        'list.sort()\n' +
                        'print(list)'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[1, 2, 2, 5]'}
                    </SyntaxHighlighter>

                    <b>8. Inversare </b>
                    <br/>
                    Se realizeaza folosind metoda <b>reverse()</b>;
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [1, 2, 5, 2]\n' +
                        'list.reverse()\n' +
                        'print(list)'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[2, 5, 2, 1]'}
                    </SyntaxHighlighter>

                    <b>9. Feliere (slice) </b>
                    <br/>
                    Folosind:
                    <ul>
                        <li><b>:</b> se face o copie a intregii liste

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'lista_1 = [7]\n' +
                                'lista_2 = lista_1[:]\n' +
                                'lista_1[0] = 2\n' +
                                'print(lista_2)'}
                            </SyntaxHighlighter>

                            Rezultatul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'[7]'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>start:end</b> se face o copie a elementelor incepand cu indexul <i>start</i> (daca se omite <b>start=0</b>, adica 0:<i>end</i>) si terminand cu indexul <i>end-1</i> (similar, daca se omite <i>end</i>, atunci <b>end=len(lista)</b>)
                            <br/>deci se face o copie de <b>end - start</b> elemente</li>

                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'lista_1 = [12, 18, 26, 47, 2]\n' +
                            'lista_2 = lista_1[1:4]\n' +
                            'print(lista_2)\n'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[18, 26, 47]'}
                        </SyntaxHighlighter>

                        <hr/>
                        Cu indecsi negativi:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'lista_1 = [12, 18, 26, 47, 2]\n' +
                            'lista_2 = lista_1[1:-1]\n' +
                            'print(lista_2)\n'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[18, 26, 47]'}
                        </SyntaxHighlighter>

                        <hr/>
                        Daca <i>start</i> este dupa <i>end</i> felia va fi goala:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'lista_1 = [12, 18, 26, 47, 2]\n' +
                            'lista_2 = lista_1[-1:1]\n' +
                            'print(lista_2)\n'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[]'}
                        </SyntaxHighlighter>

                        <hr/>
                        Alt exemplu (lista_1[-1:]):
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'lista_1 = [12, 18, 26, 47, 2]\n' +
                            'lista_2 = lista_1[-1:]\n' +
                            'print(lista_2)\n'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[2]'}
                        </SyntaxHighlighter>

                        <hr/>
                        Alt exemplu (lista_1[-1:111]):
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'lista_1 = [12, 18, 26, 47, 2]\n' +
                            'lista_2 = lista_1[-1:111]\n' +
                            'print(lista_2)\n'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[2]'}
                        </SyntaxHighlighter>

                        <hr/>
                        Alt exemplu (lista_1[:2]; daca este omis <i>start</i> se considera in mod implicit start=0):
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'lista_1 = [12, 18, 26, 47, 2]\n' +
                            'lista_2 = lista_1[:2]\n' +
                            'print(lista_2)\n'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[12, 18]'}
                        </SyntaxHighlighter>

                    </ul>

                    <b>10. Stergere elemente din lista / stergere lista </b>
                    <br/>
                    Se realizeaza folosind <b>del</b>
                    <ul>
                        <li><b>del lista[:]</b> - sterge <b>continutul</b> intregii liste

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'lista = [12, 18, 26, 47, 2]\n' +
                                'del lista[:]\n' +
                                'print(lista)'}
                            </SyntaxHighlighter>

                            Rezultatul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'[]'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>del lista</b> - sterge lista

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'lista = [12, 18, 26, 47, 2]\n' +
                                'del lista\n' +
                                'print(lista)'}
                            </SyntaxHighlighter>

                            Rezultatul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'Traceback (most recent call last):\n' +
                                '  File "main.py", line 3, in <module>\n' +
                                '    print(lista)\n' +
                                'NameError: name \'lista\' is not defined'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>del lista[start:end]</b> - sterge elementele din lista cuprinse din intervalul definit
                            <br/>
                            <b>Notă: în acest caz, felia nu produce nicio listă nouă!</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'lista = [12, 18, 26, 47, 2]\n' +
                                'del lista[1:3]\n' +
                                'print(lista)'}
                            </SyntaxHighlighter>

                            Rezultatul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'[12, 47, 2]'}
                            </SyntaxHighlighter>
                        </li>

                        <b>11. Operatorul <b>in</b> </b>
                        <br/>
                        Verifica daca un element exista in lista
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'list = [12, 18, 26, 47, 2]\n' +
                            'print(12 in list)'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'True'}
                        </SyntaxHighlighter>

                        <b>12. Operatorul <b>not in</b> </b>
                        <br/>
                        Verifica daca un element nu exista in lista
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'list = [12, 18, 26, 47, 2]\n' +
                            'print(12 not in list)'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'False'}
                        </SyntaxHighlighter>

                        <b>13. <b>List comprehension</b> </b>

                        <br/>
                        <b>List comprehension</b> permite crearea de liste noi din cele existente într-un mod concis și elegant.
                        Sintaxa unei liste <b>comprehension</b> arată după cum urmează:
                        <br/>
                        <b>[expresie for element in list if conditie]</b>
                        <br/>
                        care este echivalent cu
                        <b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'for element in list:\n' +
                                '   if conditional:\n' +
                                '       expression'}
                            </SyntaxHighlighter>

                        </b>

                        <hr/>
                        Exemplu 1:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'list = [12, 18, 26, 47, 2]\n' +
                            'list_2 = [x * 2 for x in list]\n' +
                            'print(list_2)'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[24, 36, 52, 94, 4]'}
                        </SyntaxHighlighter>

                        <hr/>
                        Exemplu 2:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'list = [12, 18, 26, 47, 2]\n' +
                            'list_2 = [x * 2 for x in list if x%2==0]\n' +
                            'print(list_2)'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[24, 36, 52, 4]'}
                        </SyntaxHighlighter>

                        <b>14. Matrici / List multidensionale </b>
                        <br/>
                        Matricile pot fi vazute ca sunt liste care contin liste.
                        <br/>
                        Liste multidimensionale pot fi vazute ca liste care contin liste care la randul lor contin alte liste si asa mai departe.

                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'matrice = [\n' +
                            '    [12, 18], \n' +
                            '    [26, 47]\n' +
                            ']\n' +
                            '\n' +
                            'print(matrice)\n' +
                            'print(matrice[0][1])'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[[12, 18], [26, 47]]\n' +
                            '18'}
                        </SyntaxHighlighter>

                        <hr/>

                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'matrice = [\n' +
                            '    [\'o\' for i in range(3)] for j in range(5)\n' +
                            ']\n' +
                            '\n' +
                            'print(matrice)'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[[\'o\', \'o\', \'o\'], [\'o\', \'o\', \'o\'], [\'o\', \'o\', \'o\'], [\'o\', \'o\', \'o\'], [\'o\', \'o\', \'o\']]'}
                        </SyntaxHighlighter>

                        <hr/>

                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'x = [[[\'x\' for r in range(2)] for f in range(4)] for t in range(3)]\n' +
                            '\n' +
                            'print(x)'}
                        </SyntaxHighlighter>

                        Rezultatul:
                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                            {'[[[\'x\', \'x\'], [\'x\', \'x\'], [\'x\', \'x\'], [\'x\', \'x\']], [[\'x\', \'x\'], [\'x\', \'x\'], [\'x\', \'x\'], [\'x\', \'x\']], [[\'x\', \'x\'], [\'x\', \'x\'], [\'x\', \'x\'], [\'x\', \'x\']]]'}
                        </SyntaxHighlighter>

                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ListPythonContent;