import React, {useEffect, useRef, useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";


function UseRef() {
    const [inputValue, setInputValue] = useState("");
    const previousInputValue = useRef("1");

    useEffect(() => {
        previousInputValue.current = inputValue;
    }, [inputValue]);

    return (
        <>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
            <h4>Current Value: {inputValue}</h4>
            <h4>Previous Value: {previousInputValue.current}</h4>
        </>
    );
}


class UseRefReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-useref", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hooks: useRef </b>
                    <br/>
                    <br/>

                    Hook-ul useRef permite să persitarea unei valori între randări.

                    <br/>
                    Poate fi folosit pentru:
                    <ul>
                        <li>
                            a stoca o valoare mutabilă care nu provoacă o redare când este actualizată.
                        </li>
                        <li>
                            a accesa direct un element DOM.
                        </li>
                        <li>
                            a ține evidența valorilor stărilor anterioare
                        </li>
                    </ul>

                    <hr/>
                    <b>1.1. Caz folosire useRef pentru a nu randara componenta </b>
                    <br/>
                    <br/>

                    Dacă am încerca să numărăm de câte ori se redă aplicația noastră folosind Hook useState, am fi prinși într-o buclă infinită,
                    deoarece acest Hook în sine provoacă o re-rendare.
                    <br/>
                    Pentru a evita acest lucru, putem folosi useRefHook.
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState, useEffect, useRef } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'function App() {\n' +
                            '  const [inputValue, setInputValue] = useState("");\n' +
                            '  const count = useRef(0);\n' +
                            '\n' +
                            '  useEffect(() => {\n' +
                            '    count.current = count.current + 1;\n' +
                            '  });\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <input\n' +
                            '        type="text"\n' +
                            '        value={inputValue}\n' +
                            '        onChange={(e) => setInputValue(e.target.value)}\n' +
                            '      />\n' +
                            '      <h1>Render Count: {count.current}</h1>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <b>useRef()</b> returneaza un item. Acesta returneaza un obiect numit <b>current</b>!

                    <hr/>
                    <b>1.2. Caz folosire useRef pentru accesare elemente DOM </b>
                    <br/>
                    <br/>

                    Se folsoete atributul HTML <b>ref</b> pentru a ne legat la o variabila gestionata de <i>useRef</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useRef } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'function App() {\n' +
                            '  const inputElement = useRef();\n' +
                            '\n' +
                            '  const focusInput = () => {\n' +
                            '    inputElement.current.focus();\n' +
                            '  };\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <input type="text" ref={inputElement} />\n' +
                            '      <button onClick={focusInput}>Focus Input</button>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>1.3. Caz folosire useRef pentru a ține evidența valorilor stărilor anterioare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState, useEffect, useRef } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'function App() {\n' +
                            '  const [inputValue, setInputValue] = useState("");\n' +
                            '  const previousInputValue = useRef("");\n' +
                            '\n' +
                            '  useEffect(() => {\n' +
                            '    previousInputValue.current = inputValue; // se apeleaza dupa fiecare render\n' +
                            '  }, [inputValue]);\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <input\n' +
                            '        type="text"\n' +
                            '        value={inputValue}\n' +
                            '        onChange={(e) => setInputValue(e.target.value)}\n' +
                            '      />\n' +
                            '      <h2>Current Value: {inputValue}</h2>\n' +
                            '      <h2>Previous Value: {previousInputValue.current}</h2>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <UseRef/>

                    <hr/>

                    De ce afiseaza asa?
                    <ul>
                        <li>initial: inputValue='' previousInputValue ='' {"=>"} se randeaza asa </li>
                        <li>se schimba starea: inputValue='b' {"=>"} randare (inputValue='b', previousInputValue ='') {"=>"} useEffect care seteaza previousInputValue='b'</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseRefReactContent;