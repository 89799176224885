import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class RevParseHeadGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-rev-parse-head", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda git rev-parse HEAD</b>
                    <br/>
                    <br/>

                    Pentru a afisa hash-ul SHA1 de la o revizie:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git rev-parse HEAD'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'c75af16d35ebec5761717a4ad8cb92698e494a15'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RevParseHeadGitContent;