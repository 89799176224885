import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";

class SystemCtlContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-systemctl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Pornire si oprire servicii cu systemctl</b>:
                    <br/>
                    <br/>

                    Pentru a porni un serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl start application.service'}
                    </SyntaxHighlighter>

                    <b>systemd</b> știe să caute fisiere *.service pentru comenzile de gestionare a serviciilor, astfel încât comanda poate fi scrisa si asa:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl start application'}
                    </SyntaxHighlighter>

                    Pentru a opri un serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl stop application'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Repornire și reîncărcare servicii cu systemctl</b>:
                    <br/>
                    <br/>

                    Pentru a restarta/reporni un serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl restart application'}
                    </SyntaxHighlighter>

                    Dacă aplicația este capabilă să-și reîncarce fișierele de configurare (fără a reporni)Ș
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl reload application'}
                    </SyntaxHighlighter>

                    Dacă nu stim sigur dacă serviciul are funcționalitatea de a-și reîncărca configurațiaȘ
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl reload-or-restart application'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Activarea și dezactivarea serviciilor cu systemctl</b>:
                    <br/>
                    <br/>
                    Comenzile de mai sus sunt utile pentru pornirea sau oprirea serviciilor în timpul sesiunii curente. Pentru a spune systemdsă pornească automat serviciile la pornire, trebuie să le activați.

                    <br/>
                    <br/>
                    Pentru a activa un serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl enable application'}
                    </SyntaxHighlighter>

                    Pentru a dezactiva serviciul de la pornirea automată:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl disable application'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Verificarea stării serviciilor</b>:
                    <br/>
                    <br/>

                    Pentru a afisa statusul unui serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl status application'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl is-active application'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl is-enabled application'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl is-failed application'}
                    </SyntaxHighlighter>



                    <hr/>
                    <b>5. Prezentare generală a stării sistemului</b>:
                    <br/>
                    <br/>

                    Listarea unităților curente:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl list-units'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl list-units --all'}
                    </SyntaxHighlighter>

                    Listarea tuturor fișierelor de unitate:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl list-unit-files'}
                    </SyntaxHighlighter>

                    Afișarea unui fișier unitar:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl cat application.service'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl cat liferay\n' +
                            '# /etc/systemd/system/liferay.service\n' +
                            '[Unit]\n' +
                            'Description=Liferay\n' +
                            'Wants=network.target\n' +
                            'After=network.target\n' +
                            '\n' +
                            '[Service]\n' +
                            'Type=forking\n' +
                            '\n' +
                            'Environment=JAVA_HOME=/usr/lib/jvm/jdk-11.0.14\n' +
                            'Environment=CATALINA_PID=/opt/liferay-ce-portal-7.2.1-ga2/tomcat-9.0.17/temp/to\n' +
                            'Environment=CATALINA_HOME=/opt/liferay-ce-portal-7.2.1-ga2/tomcat-9.0.17\n' +
                            'Environment=\'CATALINA_OPTS=-Xms512M -Xmx1G -Djava.net.preferIPv4Stack=true\'\n' +
                            '\n' +
                            '\n' +
                            'ExecStart=/opt/liferay-ce-portal-7.2.1-ga2/tomcat-9.0.17/bin/startup.sh\n' +
                            'ExecStop=/opt/liferay-ce-portal-7.2.1-ga2/tomcat-9.0.17/bin/shutdown.sh\n' +
                            'SuccessExitStatus=143\n' +
                            '\n' +
                            'UMask=0007\n' +
                            'RestartSec=10\n' +
                            'Restart=always\n' +
                            '\n' +
                            '[Install]\n' +
                            'WantedBy=multi-user.target'}
                    </SyntaxHighlighter>

                    Afișarea dependențelor:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl list-dependencies application.service'}
                    </SyntaxHighlighter>

                    Editarea fișierelor unitare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl edit application.service'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SystemCtlContent;