import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class IntroArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere in programarea Arduino</b>
                    <br/>
                    <br/>

                    La crearea unui noi <i>sketch</i> (program) in Arduino IDE (2.2.1) este generat urmatorul fragment de cod in mod automat:
                    <SyntaxHighlighter>
                        {'void setup() {\n' +
                            '  // put your setup code here, to run once:\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  // put your main code here, to run repeatedly:\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            functia <b>setup()</b> - blocul de cod din aceasta functie se ruleaza o singura data; este functia de initializare
                        </li>
                        <li>
                            functia <b>loop()</b> - blocul de cod din aceasta functie repetitiv; fiecare instructie este executata secvential de la
                            inceputul functiei pana la sfarsitul functiei si apoi se reia acest proces de la inceput.
                        </li>
                    </ul>

                    <hr/>
                    <b>1. pinMode. digitalRead. digitalWrite. delay.</b>
                    <br/>
                    <br/>

                    Pinii de pe Arduino pot fi configurați fie ca intrări, fie ca ieșiri.
                    <br/>
                    Pinii Arduino sunt implicit intrări, deci nu trebuie să fie declarați în mod explicit ca intrări cu pinMode() atunci când îi folosiți ca intrări.

                    <br/>
                    <br/>

                    Daca vrem sa LED-ul de pe placa sa clipeasca:
                    <SyntaxHighlighter>
                        {'void setup() {\n' +
                            '  // intializare pin digital LED_BUILTIN=13 ca iesire.\n' +
                            '  pinMode(LED_BUILTIN, OUTPUT);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  digitalWrite(LED_BUILTIN, HIGH);  // aprindere LED (voltaj mare = HIGH)\n' +
                            '  delay(1000);                      // asteapta o secunda\n' +
                            '  digitalWrite(LED_BUILTIN, LOW);   // stingere LED (voltaj mic = LOW)\n' +
                            '  delay(1000);                      // asteapta o secunda\n' +
                            '}'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <b>pinMode(pin, mod)</b> - seteaza modul in care pinul va functiona: ca intrare (INPUT) sau ca iesire (OUTPUT);
                            <br/>
                            modul implicit in care functioneaza un pin este INPUT, prin urmare, nu e nevoie sa fie declarat;
                            <br/>
                            dar se poate declara ca si claritate;
                        </li>
                        <li>
                            <b>digitalWrite(pin, val)</b> - seteaza o valoare pentru un pin digial; valoare poate fi HIGH(5V sau 3.3V) sau LOW (0V)
                        </li>
                        <li>
                            <b>delay(milisecunde)</b> - astepta un numar de milisecunde
                        </li>
                    </ul>

                    Observatie:
                    <ul>
                        Fără a seta în mod explicit pinMode(), digitalWrite() va fi activat rezistorul intern, care acționează ca un rezistor mare de limitare a curentului.
                        Si LED-ul va aparea foarte slab atunci când apelați digitalWrite(HIGH)
                    </ul>

                    Pentru a citi valoare unui pin digital se foloseste functia:
                    <ul>
                        <li>
                            <b>digitalRead(pin) - citeste valoarea de la un pin digital specificat; poate fie HIGH sau LOW</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Debug cu Serial Monitor: Serial.begin(), Serial.println(), Serial.read()</b>
                    <br/>
                    <br/>

                    Se folosesc metodele:
                    <ul>
                        <li>
                            <b>Serial.begin(baud)</b> - setează viteza de transmitere a datelor în biți pe secundă (baud) pentru transmisia de date în serie
                            <SyntaxHighlighter>
                                {'void setup() {\n' +
                                    '    Serial.begin(9600); // deschide un port serial cu 9600 bps\n' +
                                    '}\n' +
                                    '\n' +
                                    'void loop() {}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>Serial.println(text)</b> - scrie date pe portul serial
                            <SyntaxHighlighter>
                                {'void setup() {\n' +
                                    '  Serial.begin(9600);\n' +
                                    '  Serial.println("Salut!");\n' +
                                    '}\n' +
                                    '\n' +
                                    'void loop() {\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>SSerial.read()</b> - pentru a citi o valoare de la tastatura prin intermediul dialogului pentru Serial
                        </li>
                    </ul>

                    <hr/>
                    <b>3. analogRead. analogWrite</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            analogRead() -> poate returna valori intre 0..1023
                        </li>
                        <li>
                            analogWrite() -> poate seta valori intre 0..255, pe pinii PWN
                        </li>
                        <li>
                            avand in vedere ca sunt intervalele diferite, conversia se poate face, simplificat, impartind la 4
                        </li>
                        <li>
                            un pin digital analog se poate comporta ca un pin analog
                            <SyntaxHighlighter>
                                {'pinMode(A5, OUTPUT)'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*     */}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroArduinoContent;