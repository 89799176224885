import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class EurekaServerSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-eureka-server", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare Eureka Server</b>

                    <br/>
                    <br/>

                    Se creaza un nou proiect <b>Spring Boot</b>.

                    <br/>
                    <br/>
                    Fisierul <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'buildscript {\n' +
                            '    repositories {\n' +
                            '        maven {\n' +
                            '            url "https://plugins.gradle.org/m2/"\n' +
                            '        }\n' +
                            '    }\n' +
                            '    dependencies {\n' +
                            '        classpath "io.spring.gradle:dependency-management-plugin:1.0.14.RELEASE"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'plugins {\n' +
                            '    id \'org.springframework.boot\' version \'2.7.4\'\n' +
                            '    id \'java\'\n' +
                            '}\n' +
                            '\n' +
                            'apply plugin: "io.spring.dependency-management"\n' +
                            '\n' +
                            'version = \'1.0.0\'\n' +
                            'group = \'ro.ibrid.learn.microservices.spring\'\n' +
                            '\n' +
                            'java {\n' +
                            '    sourceCompatibility = JavaVersion.VERSION_1_8\n' +
                            '}\n' +
                            '\n' +
                            'ext {\n' +
                            '    set(\'springCloudVersion\', "2021.0.3")\n' +
                            '}\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '    maven {\n' +
                            '        url "https://plugins.gradle.org/m2/"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '    implementation \'org.springframework.cloud:spring-cloud-starter-netflix-eureka-server\'\n' +
                            '\n' +
                            '    testImplementation(\'org.springframework.boot:spring-boot-starter-test\') {\n' +
                            '        exclude group: \'org.junit.vintage\', module: \'junit-vintage-engine\'\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'dependencyManagement {\n' +
                            '    imports {\n' +
                            '        mavenBom "org.springframework.cloud:spring-cloud-dependencies:${springCloudVersion}"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\', Test) {\n' +
                            '    useJUnitPlatform()\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>application.properties</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'server.port=8761\n' +
                            '\n' +
                            'spring.application.name=eureka-server\n' +
                            '\n' +
                            'eureka.client.register-with-eureka=false\n' +
                            'eureka.client.fetch-registry=false'}
                    </SyntaxHighlighter>

                    Clasa <b>EurekaServerApplication</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring;\n' +
                            '\n' +
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            'import org.springframework.cloud.netflix.eureka.server.EnableEurekaServer;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            '@EnableEurekaServer\n' +
                            'public class EurekaServerApplication {\n' +
                            '\n' +
                            '\tpublic static void main(String[] args) {\n' +
                            '\t\tSpringApplication.run(EurekaServerApplication.class, args);\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Configurare Eureka Client</b>

                    <br/>
                    <br/>

                    In proiectul <i>student-service</i> si <i>address-service</i> se adauga in <b>build.gradle</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'// https://mvnrepository.com/artifact/org.springframework.cloud/spring-cloud-starter-netflix-eureka-client\n' +
                            'implementation group: \'org.springframework.cloud\', name: \'spring-cloud-starter-netflix-eureka-client\', version: \'3.1.4\'\n'}
                    </SyntaxHighlighter>

                    Clasele <i>AddressServiceApplication</i> + <i>StudentServiceApplication</i> se adnoteaza cu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@EnableEurekaClient'}
                    </SyntaxHighlighter>

                    In ambele proiecte de tip servicii, adaugam in <b>application.properties</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'eureka.client.service-url.defaultZone = http://localhost:8761/eureka'}
                    </SyntaxHighlighter>

                    Modificam in clasa <i>AddressFeignClient</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@FeignClient(url="${address.service.url}", value = "address-feign-client", path = "/api/address")'}
                    </SyntaxHighlighter>
                    cu
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@FeignClient(value = "address-service", path = "/api/address")'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Testare</b>

                    <br/>
                    <br/>
                    Se porneste eureka-server, si apoi address-service si student-service.
                    <br/>
                    <br/>
                    Pentru a verifica ca a pornit eureka-server:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'http://localhost:8761'}
                    </SyntaxHighlighter>

                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default EurekaServerSpringContent;