import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class UnnamedClassesJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-unnamed-classes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Fie programul clasic <i>Hello, World!</i> in Java:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloWorld { \n' +
                            '    public static void main(String[] args) { \n' +
                            '        System.out.println("Hello, World!");\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Sugestii:
                    <ul>
                        <li>
                            Metoda <b>main</b> ca metoda <i>principala</i> a unui <i>instante</i>  (<b>instance main method</b>).
                            <br/>
                            Metodele principale a unui instante:
                            <ul>
                                <li>
                                    nu trebuie sa fie <b>statice</b> (pentru ca tin de o instanta; deci nu pot fi statice)
                                </li>
                                <li>
                                    nu trebuie sa fie publice
                                </li>
                                <li>
                                    nu trebuie sa aiba parametrul <i>String[]</i>
                                </li>
                            </ul>
                            In felul aceste, se va putea scrie:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public class HelloWorld { \n' +
                                    '   void main() { \n' +
                                    '        System.out.println("Hello, World!");\n' +
                                    '    }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Clase fara nume (<b>unnamed classes</b>)
                            <br/>
                            In felul aceste, se va putea scrie:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'void main() {\n' +
                                    '    System.out.println("Hello, World!");\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    La lansarea unei clase, se va alege prima dintre următoarele metode de invocat:
                    <ul>
                        <li>
                            static void main(String[] args)
                        </li>
                        <li>
                            static void main()
                        </li>
                        <li>
                            void main(String[] args)
                        </li>
                        <li>
                            void main()
                        </li>
                    </ul>

                    O clasă fără nume:
                    <ul>
                        <li>
                            este întotdeauna un membru al pachetului fără nume.
                        </li>
                        <li>
                            este <b>final</b>
                        </li>
                        <li>
                            nu poate implementa nicio interfață
                        </li>
                        <li>
                            nu poate extinde nicio clasă în afară de Object
                        </li>
                        <li>
                            nu poate fi referită după nume, deci nu pot exista referiri la metodele sale statice
                            <br/>
                            (cu toate acestea, cuvântul cheie <b>this</b> poate fi utilizat în continuare)
                        </li>
                        <li>
                            are un constructor implicit cu parametri zero, nu poate avea niciun alt constructor
                        </li>
                        <li>
                            membrii de nivel superior sunt interpretați ca membri ai clasei fără nume, așa că putem scrie programul și ca:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'String greeting() { return "Hello, World!"; }\n' +
                                    '\n' +
                                    'void main() {\n' +
                                    '    System.out.println(greeting());\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            sau, folosind un câmp, ca:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'String greeting = "Hello, World!";\n' +
                                    '\n' +
                                    'void main() {\n' +
                                    '    System.out.println(greeting);\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            lansarea poate fi vazuta ca:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'new Object() {\n' +
                                    '    // corpul clasei fara nume\n' +
                                    '}.main();'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <a target={"_blank"} href={"https://openjdk.org/jeps/445"}>
                                JEP 445: Unnamed Classes and Instance Main Methods (Preview)
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UnnamedClassesJavaContent;