import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class Ex2k8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-ex2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 1: simplek8s</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>

                    Pornim de la proiectul <i>complex</i> si facem o copie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cp -r complex complex-k8s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd complexk8s'}
                    </SyntaxHighlighter>

                    Acum curatam un pic proiectul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rm docker-compose.yml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rm docker-compose-dev.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rm old.travis.yml'}
                    </SyntaxHighlighter>

                    in loc de nginx se va folosi ingres:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rm -r nginx'}
                    </SyntaxHighlighter>

                    sterg si urmarirea git-ului:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rm -rf .git'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir k8s'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.1. client-deployment.yaml</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano client-deployment.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment\n' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: web\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. client-cluster-ip-service.yaml</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano client-cluster-ip-service.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: client-cluster-ip-service\n' +
                            'spec:\n' +
                            '  type: ClusterIP\n' +
                            '  selector:\n' +
                            '    component: web\n' +
                            '  ports:\n' +
                            '    - port: 3000\n' +
                            '      targetPort: 3000\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployments'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME                READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'client-deployment   1/1     1            1           28h'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete deployment client-deployment'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get services'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME               TYPE        CLUSTER-IP      EXTERNAL-IP   PORT(S)          AGE\n' +
                            'client-node-port   NodePort    10.110.64.209   <none>        3050:31515/TCP   34h\n' +
                            'kubernetes         ClusterIP   10.96.0.1       <none>        443/TCP          3d21h'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete service client-node-port'}
                    </SyntaxHighlighter>

                    fiind in directorul <i>complex-k8s</i>, putem aplica toate fisierele de configurare din directorul <i>k8s</i> in modul urmator:
                    <SyntaxHighlighter showLineNumbers={false} language="python" style={androidstudio}>
                        {'kubectl apply k8s'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. server-deployment.yaml</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano server-deployment.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: server-deployment\n' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: server\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: server\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: server\n' +
                            '          image: iulianbuzdugan/multi-server\n' +
                            '          ports:\n' +
                            '            - containerPort: 5000'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.4. server-cluster-ip-service</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano server-cluster-ip-service.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: server-cluster-ip-service\n' +
                            'spec:\n' +
                            '  type: ClusterIP\n' +
                            '  selector:\n' +
                            '    component: server\n' +
                            '  ports:\n' +
                            '    - port: 5000\n' +
                            '      targetPort: 5000\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.5. worker-deployment</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano worker-deployment.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: worker-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: worker\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: worker\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: worker\n' +
                            '          image: iulianbuzdugan/multi-worker\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.6. redis-deployment</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano redis-deployment.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: redis-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: redis\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: redis\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: redis\n' +
                            '          image: redis\n' +
                            '          ports:\n' +
                            '            - containerPort: 6379'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.7. redis-cluster-ip-service</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano redis-cluster-ip-service.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: redis-cluster-ip-service\n' +
                            'spec:\n' +
                            '  type: ClusterIP\n' +
                            '  selector:\n' +
                            '    component: redis\n' +
                            '  ports:\n' +
                            '    - port: 6379\n' +
                            '      targetPort: 6379\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.8. postgres-deployment</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano postgres-deployment.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: postgres-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: postgres\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: postgres\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: postgres\n' +
                            '          image: postgres\n' +
                            '          ports:\n' +
                            '            - containerPort: 5432\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.9. postgres-cluster-ip-service</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano postgres-cluster-ip-service.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: postgres-cluster-ip-service\n' +
                            'spec:\n' +
                            '  type: ClusterIP\n' +
                            '  selector:\n' +
                            '    component: postgres\n' +
                            '  ports:\n' +
                            '    - port: 5432\n' +
                            '      targetPort: 5432\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.10. database-persistent-volume-claim</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano database-persistent-volume-claim.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  name: database-persistent-volume-claim\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 2Gi'}
                    </SyntaxHighlighter>

                    si actualizam <b>postgres-deployment.yaml</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: postgres-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: postgres\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: postgres\n' +
                            '    spec:\n' +
                            '      volumes:\n' +
                            '        - name: postgres-storage\n' +
                            '          persistentVolumeClaim:\n' +
                            '            claimName: database-persistent-volume-claim\n' +
                            '      containers:\n' +
                            '        - name: postgres\n' +
                            '          image: postgres\n' +
                            '          ports:\n' +
                            '            - containerPort: 5432\n' +
                            '          volumeMounts:\n' +
                            '            - name: postgres-storage\n' +
                            '              mountPath: /var/lib/postgresql/data\n' +
                            '              subPath: postgres'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.11. actualizare: postgres-deployment</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: postgres-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: postgres\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: postgres\n' +
                            '    spec:\n' +
                            '      volumes:\n' +
                            '        - name: postgres-storage\n' +
                            '          persistentVolumeClaim:\n' +
                            '            claimName: database-persistent-volume-claim\n' +
                            '      containers:\n' +
                            '        - name: postgres\n' +
                            '          image: postgres\n' +
                            '          ports:\n' +
                            '            - containerPort: 5432\n' +
                            '          volumeMounts:\n' +
                            '            - name: postgres-storage\n' +
                            '              mountPath: /var/lib/postgresql/data\n' +
                            '              subPath: postgres\n' +
                            '          env:\n' +
                            '            - name: POSTGRES_PASSWORD\n' +
                            '              valueFrom:\n' +
                            '                secretKeyRef:\n' +
                            '                  name: pgpassword\n' +
                            '                  key: PGPASSWORD'}
                    </SyntaxHighlighter>
                    avand la baza comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic pgpassword --from-literal PGPASSWORD=1234password'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <i>spec/template/spec/containers/env/valueFrom/secretKeyRef/name</i> = pgpassword (din comanda de mai sus)
                        </li>
                        <li>
                            <i>spec/template/spec/containers/env/valueFrom/secretKeyRef/key</i> = PGPASSWORD (din comanda de mai sus)
                        </li>
                    </ul>


                    <hr/>
                    <b>1.12. actualizare: server-deployment</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: server-deployment\n' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: server\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: server\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: server\n' +
                            '          image: cygnetops/multi-server-pgfix-5-11\n' +
                            '          ports:\n' +
                            '            - containerPort: 5000\n' +
                            '          env:\n' +
                            '            - name: REDIS_HOST\n' +
                            '              value: redis-cluster-ip-service\n' +
                            '            - name: REDIS_PORT\n' +
                            '              value: \'6379\'\n' +
                            '            - name: PGUSER\n' +
                            '              value: postgres\n' +
                            '            - name: PGHOST\n' +
                            '              value: postgres-cluster-ip-service\n' +
                            '            - name: PGPORT\n' +
                            '              value: \'5432\'\n' +
                            '            - name: PGDATABASE\n' +
                            '              value: postgres\n' +
                            '            - name: PGPASSWORD\n' +
                            '              valueFrom:\n' +
                            '                secretKeyRef:\n' +
                            '                  name: pgpassword\n' +
                            '                  key: PGPASSWORD'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.13. actualizare: worker-deployment</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: worker-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: worker\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: worker\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: worker\n' +
                            '          image: stephengrider/multi-worker\n' +
                            '          env:\n' +
                            '            - name: REDIS_HOST\n' +
                            '              value: redis-cluster-ip-service\n' +
                            '            - name: REDIS_PORT\n' +
                            '              value: \'6379\'\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.14. ingress-service</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano ingress-service.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            '# UPDATE API\n' +
                            'kind: Ingress\n' +
                            'metadata:\n' +
                            '  name: ingress-service\n' +
                            '  annotations:\n' +
                            '    kubernetes.io/ingress.class: \'nginx\'\n' +
                            '    nginx.ingress.kubernetes.io/use-regex: \'true\'\n' +
                            '    # ADD ANNOTATION\n' +
                            '    nginx.ingress.kubernetes.io/rewrite-target: /$1\n' +
                            '    # UPDATE ANNOTATION\n' +
                            'spec:\n' +
                            '  rules:\n' +
                            '    - http:\n' +
                            '        paths:\n' +
                            '          - path: /?(.*)\n' +
                            '            # UPDATE PATH\n' +
                            '            pathType: Prefix\n' +
                            '            # ADD PATHTYPE\n' +
                            '            backend:\n' +
                            '              service:\n' +
                            '                # UPDATE SERVICE FIELDS\n' +
                            '                name: client-cluster-ip-service\n' +
                            '                port:\n' +
                            '                  number: 3000\n' +
                            '          - path: /api/?(.*)\n' +
                            '            # UPDATE PATH\n' +
                            '            pathType: Prefix\n' +
                            '            # ADD PATHTYPE\n' +
                            '            backend:\n' +
                            '              service:\n' +
                            '                # UPDATE SERVICE FIELDS\n' +
                            '                name: server-cluster-ip-service\n' +
                            '                port:\n' +
                            '                  number: 5000\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.15. Verificare</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f k8s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube ip'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'192.168.49.2'}
                    </SyntaxHighlighter>

                    testare in <i>browser</i>:
                    <SyntaxHighlighter>
                        {'http://192.168.49.2'}
                    </SyntaxHighlighter>
                    sau testare cu <i>curl</i>:
                    <SyntaxHighlighter>
                        {'curl http://192.168.49.2'}
                    </SyntaxHighlighter>

                    daca ceva nu merge ok:
                    <ul>
                        <li>
                            verifica ca ai rulat comanda:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl create secret generic pgpassword --from-literal PGPASSWORD=1234password'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get pods'}
                            </SyntaxHighlighter>
                            poate arata:
                            <SyntaxHighlighter>
                                {'client-deployment-8d9b8cdd6-p6ppn      1/1     Running                      0          6m51s\n' +
                                    'client-deployment-8d9b8cdd6-t2dsm      1/1     Running                      0          6m51s\n' +
                                    'client-deployment-8d9b8cdd6-x2mj7      1/1     Running                      0          6m51s\n' +
                                    'postgres-deployment-56f74bd67d-zqpbr   0/1     CreateContainerConfigError   0          6m36s\n' +
                                    'redis-deployment-6d5d95ddc4-fnd72      1/1     Running                      0          6m18s\n' +
                                    'server-deployment-69b7549c95-299fj     0/1     CreateContainerConfigError   0          6m10s\n' +
                                    'server-deployment-69b7549c95-c4n8h     0/1     CreateContainerConfigError   0          6m11s\n' +
                                    'server-deployment-69b7549c95-mhk6h     0/1     CreateContainerConfigError   0          6m10s\n' +
                                    'worker-deployment-64bfc78ff-xzdfl      0/1     ContainerCreating            0          6m7s'}
                            </SyntaxHighlighter>
                            si atunci se poate inspecta log-ul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl logs postgres-deployment-56f74bd67d-zqpbr'}
                            </SyntaxHighlighter>

                            Observatie:
                            <ul>
                                <li>
                                    <b>CreateContainerConfigError</b> poate aparea:
                                    <ul>
                                        <li>
                                            lipseste <b>ConfigMap</b>
                                        </li>
                                        <li>
                                            lipseste <b>Secret</b>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl describe pod postgres-deployment-56f74bd67d-zqpbr'}
                            </SyntaxHighlighter>
                            se poate afisa:
                            <SyntaxHighlighter>
                                {'Warning  Failed     6m36s                kubelet            Error: secret "pgpassword" not found'}
                            </SyntaxHighlighter>
                            ceea ce inseamna ca lipseste <b>Secret</b>

                            <br/>
                            dupa ce se ruleaza:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl create secret generic pgpassword --from-literal PGPASSWORD=1234password'}
                            </SyntaxHighlighter>
                            se executa:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl delete -f  k8s/postgres-deployment.yaml'}
                            </SyntaxHighlighter>

                            si se executa din nou:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl apply -f k8s'}
                            </SyntaxHighlighter>

                            verificam:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get pods'}
                            </SyntaxHighlighter>
                            poate arata:
                            <SyntaxHighlighter>
                                {'client-deployment-8d9b8cdd6-p6ppn      1/1     Running                      0          18m\n' +
                                    'client-deployment-8d9b8cdd6-t2dsm      1/1     Running                      0          18m\n' +
                                    'client-deployment-8d9b8cdd6-x2mj7      1/1     Running                      0          18m\n' +
                                    'postgres-deployment-56f74bd67d-tz8hq   0/1     CreateContainerConfigError   0          81s\n' +
                                    'redis-deployment-6d5d95ddc4-fnd72      1/1     Running                      0          17m\n' +
                                    'server-deployment-69b7549c95-299fj     0/1     CreateContainerConfigError   0          17m\n' +
                                    'server-deployment-69b7549c95-c4n8h     0/1     CreateContainerConfigError   0          17m\n' +
                                    'server-deployment-69b7549c95-mhk6h     0/1     CreateContainerConfigError   0          17m\n' +
                                    'worker-deployment-64bfc78ff-xzdfl      1/1     Running                      0          17m'}
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter>
                                {'NAME                                   READY   STATUS              RESTARTS   AGE\n' +
                                    'client-deployment-8d9b8cdd6-p6ppn      1/1     Running             0          31m\n' +
                                    'client-deployment-8d9b8cdd6-t2dsm      1/1     Running             0          31m\n' +
                                    'client-deployment-8d9b8cdd6-x2mj7      1/1     Running             0          31m\n' +
                                    'postgres-deployment-56f74bd67d-9vw8h   0/1     ContainerCreating   0          42s\n' +
                                    'postgres-deployment-56f74bd67d-tz8hq   1/1     Terminating         0          14m\n' +
                                    'redis-deployment-6d5d95ddc4-fnd72      1/1     Running             0          31m\n' +
                                    'server-deployment-69b7549c95-299fj     1/1     Running             0          30m\n' +
                                    'server-deployment-69b7549c95-c4n8h     1/1     Running             0          30m\n' +
                                    'server-deployment-69b7549c95-mhk6h     1/1     Running             0          30m\n' +
                                    'worker-deployment-64bfc78ff-xzdfl      1/1     Running             0          30m'}
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter>
                                {'NAME                                   READY   STATUS    RESTARTS   AGE\n' +
                                    'client-deployment-8d9b8cdd6-p6ppn      1/1     Running   0          32m\n' +
                                    'client-deployment-8d9b8cdd6-t2dsm      1/1     Running   0          32m\n' +
                                    'client-deployment-8d9b8cdd6-x2mj7      1/1     Running   0          32m\n' +
                                    'postgres-deployment-56f74bd67d-9vw8h   1/1     Running   0          75s\n' +
                                    'redis-deployment-6d5d95ddc4-fnd72      1/1     Running   0          31m\n' +
                                    'server-deployment-69b7549c95-299fj     1/1     Running   0          31m\n' +
                                    'server-deployment-69b7549c95-c4n8h     1/1     Running   0          31m\n' +
                                    'server-deployment-69b7549c95-mhk6h     1/1     Running   0          31m\n' +
                                    'worker-deployment-64bfc78ff-xzdfl      1/1     Running   0          31m'}
                            </SyntaxHighlighter>
                            si atunci se poate inspecta log-ul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl logs postgres-deployment-56f74bd67d-tz8hq'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl describe pod postgres-deployment-56f74bd67d-tz8hq'}
                            </SyntaxHighlighter>


                            afisare servicii:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {' kubectl get services'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'NAME                          TYPE        CLUSTER-IP       EXTERNAL-IP   PORT(S)    AGE\n' +
                                    'client-cluster-ip-service     ClusterIP   10.99.151.88     <none>        3000/TCP   37m\n' +
                                    'kubernetes                    ClusterIP   10.96.0.1        <none>        443/TCP    12h\n' +
                                    'postgres-cluster-ip-service   ClusterIP   10.105.8.129     <none>        5432/TCP   37m\n' +
                                    'redis-cluster-ip-service      ClusterIP   10.99.27.56      <none>        6379/TCP   37m\n' +
                                    'server-cluster-ip-service     ClusterIP   10.108.108.193   <none>        5000/TCP   37m'}
                            </SyntaxHighlighter>

                            afisare ingress:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {' kubectl get ingress'}
                            </SyntaxHighlighter>
                            se va afisa:
                            <SyntaxHighlighter>
                                {'AME              CLASS    HOSTS   ADDRESS        PORTS   AGE\n' +
                                    'ingress-service   <none>   *       192.168.49.2   80      39m'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex2k8sContent;