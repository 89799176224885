import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

import styles from "./index.module.scss";

const Componenta = () => {

    const getColor = () => {
        if (Math.random()>0.5){
            return 'red';
        }else{
            return "green";
        }
    }
    return(
        <>
            <p style={{backgroundColor: getColor()}}>a</p>
            <p style={{backgroundColor: getColor()}}>b</p>
        </>
    )
}

const LanguageComponenta = () => {

    const languages = ['ro', 'en'];
    return(
        languages.map(language=>(
            <p>{language}</p>
        ))
    )
}

const LanguageComponentaIndex = () => {

    const languages = ['ro', 'en'];
    return(
        languages.map((language,i)=>(
            <p>{i+" "+language}</p>
        ))
    )
}

class DirectivesAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-directives", IndexContent);
    }

    isOk = true;
    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Directive: ngIf</b>
                    <br/>
                    <br/>

                    Directivele sunt legate de atribute.
                    <br/>
                    Directivele sunt de 2 tipuri:
                    <ul>
                        <li>
                            directive de atribute (schimba comportamentul unui element, de exemplu, poti adauga un stil unui element)
                        </li>
                        <li>
                            directive de structura (adauga sau sterge elemente)
                        </li>
                    </ul>

                    Directivele sunt instructiuni in DOM.
                    <br/>
                    Componentele sunt directive ( directive cu template ).
                    <br/>
                    Dar, exista directive si fara template.

                    <hr/>
                    <b>2. ngIf</b>
                    <br/>
                    <br/>

                    Trebuie sa incepa cu <b>*</b>.
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p *ngIf="1==1">Geniu</p>\n' +
                            '<p *ngIf="1==2">Prost</p>'}
                    </SyntaxHighlighter>
                    La randul 2, va apare o problema la copilare:
                    <i>This condition will always return 'false' since the types '1' and '2' have no overlap</i>

                    <hr/>
                    Exista si varianta de <b>else</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p *ngIf="isOk; else isNotOk">OK</p>\n' +
                            '<ng-template #isNotOk>\n' +
                            '   <p>Nok</p>\n' +
                            '</ng-template>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<ng-template [ngId]="conditie">\n' +
                            ' <p>text</p>\n' +
                            '</ng-template>'}
                    </SyntaxHighlighter>
                    este similar (scris mai pe scurt) cu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p *ngIf="conditie">text</p>'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'{1===1? <p>Geniu</p>:\'\'}\n' +
                            '{1===2? <p>Prost</p>:\'\'}\n' +
                            '{1===1 && <p>Geniu</p>}\n' +
                            '{1===2 && <p>Prost</p>}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'{isOk?<p>Ok</p>:<p>Nok</p>}'}
                    </SyntaxHighlighter>

                    {this.isOk?<p>Ok</p>:<p>Nok</p>}

                    <hr/>
                    <b>3. ngStyle</b>
                    <br/>
                    <br/>

                    Fie in clasa componenta:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'getColor(){\n' +
                            '    if (Math.random()>0.5){\n' +
                            '      return \'red\';\n' +
                            '    }else{\n' +
                            '      return "green";\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Codul de mai sus va crapa cu:
                    <i> ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'red'. Current value: 'green'</i>
                    <br/>
                    <br/>
                    Asa ca il schimb in:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'color = Math.random();\n' +
                            'getColor(){\n' +
                            '    if (this.color>0.5){\n' +
                            '      return \'red\';\n' +
                            '    }else{\n' +
                            '      return "green";\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In template HTML:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p [ngStyle]="{backgroundColor: getColor()}">a</p>\n' +
                            '<p [ngStyle]="{backgroundColor: getColor()}">b</p>'}
                    </SyntaxHighlighter>

                    In React:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const Componenta = () => {\n' +
                            '\n' +
                            '    const getColor = () => {\n' +
                            '        if (Math.random()>0.5){\n' +
                            '            return \'red\';\n' +
                            '        }else{\n' +
                            '            return "green";\n' +
                            '        }\n' +
                            '    }\n' +
                            '    return(\n' +
                            '        <>\n' +
                            '            <p style={{backgroundColor: getColor()}}>a</p>\n' +
                            '            <p style={{backgroundColor: getColor()}}>b</p>\n' +
                            '        </>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <Componenta/>

                    <hr/>
                    <b>4. ngClass</b>
                    <br/>
                    <br/>

                    Fie in fisierul CSS:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'.backgroundRed{\n' +
                            '  background: red;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In template HTML:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p [ngClass]="{backgroundRed:false}" >a</p>\n' +
                            '<p [ngClass]="{backgroundRed:true}" >b</p>'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React:

                    Fie in fisierul CSS <i>index.module.scss</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'.backgroundRed{\n' +
                            '  background: red;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi importam fisierul:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import styles from "./index.module.scss";'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p className={true?styles.backgroundRed:\'\'}>a</p>\n' +
                            '<p className={false?styles.backgroundRed:\'\'}>b</p>'}
                    </SyntaxHighlighter>

                    <hr/>

                    <hr/>
                    <b>5. ngFor</b>
                    <br/>
                    <br/>

                    Fie in clasa componenta:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'languages = [\'ro\', \'en\'];'}
                    </SyntaxHighlighter>

                    In casa HTML template:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p *ngFor="let language of languages">{{language}}</p>'}
                    </SyntaxHighlighter>

                    si cu index:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p *ngFor="let language of languages; let i=index">{{i+" "+language}}</p>'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'const LanguageComponenta = () => {\n' +
                            '\n' +
                            '    const languages = [\'ro\', \'en\'];\n' +
                            '    return(\n' +
                            '        languages.map(language=>(\n' +
                            '            <p>{language}</p>\n' +
                            '        ))\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <LanguageComponenta/>

                    Cu index:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const LanguageComponentaIndex = () => {\n' +
                            '\n' +
                            '    const languages = [\'ro\', \'en\'];\n' +
                            '    return(\n' +
                            '        languages.map((language,i)=>(\n' +
                            '            <p>{i+" "+language}</p>\n' +
                            '        ))\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <LanguageComponentaIndex/>

                    <hr/>
                    <b>6. Switch</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'<div [ngSwitch]="languages">\n' +
                            '    <p *ngSwitchCase="\'ro\'">RO</p>\n' +
                            '    <p *ngSwitchCase="\'en\'">EN</p>\n' +
                            '    <p *ngSwitchDefault>?</p>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Directiva personalizata: @Directive / @@HostListener / @HostBinding</b>
                    <br/>
                    <br/>

                    Directivele adaugă comportament unui element DOM existent sau unei instanțe de componentă existentă.
                    <br/><br/>

                    O directiva se declara cu adnotarea <b>@Directive</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Directive, ElementRef, OnInit} from "@angular/core";\n' +
                            '\n' +
                            '@Directive({\n' +
                            '  selector: \'[bg]\'\n' +
                            '})\n' +
                            'export class BgDirective implements OnInit {\n' +
                            '\n' +
                            '  constructor( private el: ElementRef<HTMLElement>) {};\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    this.el.nativeElement.style.backgroundColor = \'yellow\';\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    O varianta mai buna:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Directive, ElementRef, OnInit, Renderer2} from "@angular/core";\n' +
                            '\n' +
                            '@Directive({\n' +
                            '  selector: \'[bg2]\'\n' +
                            '})\n' +
                            'export class BgDirective2 implements OnInit {\n' +
                            '\n' +
                            '  constructor( private el: ElementRef<HTMLElement>, private render:Renderer2) {};\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    this.render.setStyle(this.el.nativeElement,\'background-color\', \'yellow\');\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p bg>Aici</p>'}
                    </SyntaxHighlighter>

                    Mod de folosire <b>@HostListener</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Directive, ElementRef, HostListener, OnInit, Renderer2} from \'@angular/core\';\n' +
                            '\n' +
                            '@Directive({\n' +
                            '  selector: \'[bg3]\',\n' +
                            '})\n' +
                            'export class BgDirective3 implements OnInit {\n' +
                            '  constructor(private el: ElementRef<HTMLElement>, private render: Renderer2) {}\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '   // this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseenter\') mouseOver(eventData: Event){\n' +
                            '    this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseleave\') mouseLeave(eventData: Event){\n' +
                            '    this.render.setStyle(this.el.nativeElement, \'background-color\', \'transparent\');\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Mod de folosire <b>@HostBinding</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Directive, ElementRef, HostBinding, HostListener, OnInit, Renderer2} from \'@angular/core\';\n' +
                            '\n' +
                            '@Directive({\n' +
                            '  selector: \'[bg4]\',\n' +
                            '})\n' +
                            'export class BgDirective4 implements OnInit {\n' +
                            '\n' +
                            '  @HostBinding(\'style.backgroundColor\') backgroundColor: string  = \'transparent\';\n' +
                            '\n' +
                            '  constructor(private el: ElementRef<HTMLElement>, private render: Renderer2) {}\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '   // this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseenter\') mouseOver(eventData: Event){\n' +
                            '    //this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '    this.backgroundColor = \'blue\';\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseleave\') mouseLeave(eventData: Event){\n' +
                            '    //this.render.setStyle(this.el.nativeElement, \'background-color\', \'transparent\');\n' +
                            '    this.backgroundColor = \'transparent\';\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Atribute la o directiva:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Renderer2} from \'@angular/core\';\n' +
                            '\n' +
                            '@Directive({\n' +
                            '  selector: \'[bg5]\',\n' +
                            '})\n' +
                            'export class BgDirective5 implements OnInit {\n' +
                            '\n' +
                            '  @Input() bgColor=\'blue\';\n' +
                            '  @Input() defaultColor=\'yellow\';\n' +
                            '\n' +
                            '  @HostBinding(\'style.backgroundColor\') backgroundColor: string = this.defaultColor;\n' +
                            '\n' +
                            '  constructor(private el: ElementRef<HTMLElement>, private render: Renderer2) {}\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    // this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseenter\') mouseOver(eventData: Event) {\n' +
                            '    //this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '    this.backgroundColor = this.bgColor;\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseleave\') mouseLeave(eventData: Event) {\n' +
                            '    //this.render.setStyle(this.el.nativeElement, \'background-color\', \'transparent\');\n' +
                            '    this.backgroundColor = this.defaultColor;\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' <p bg5 [bgColor]="\'green\'" [defaultColor]="\'red\'">Acolo</p>'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Renderer2} from \'@angular/core\';\n' +
                            '\n' +
                            '@Directive({\n' +
                            '  selector: \'[bg5]\',\n' +
                            '})\n' +
                            'export class BgDirective5 implements OnInit {\n' +
                            '\n' +
                            '  @Input(\'bg5\') bgColor=\'blue\';\n' +
                            '  @Input() defaultColor=\'yellow\';\n' +
                            '\n' +
                            '  @HostBinding(\'style.backgroundColor\') backgroundColor: string = this.defaultColor;\n' +
                            '\n' +
                            '  constructor(private el: ElementRef<HTMLElement>, private render: Renderer2) {}\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    // this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseenter\') mouseOver(eventData: Event) {\n' +
                            '    //this.render.setStyle(this.el.nativeElement, \'background-color\', \'yellow\');\n' +
                            '    this.backgroundColor = this.bgColor;\n' +
                            '  }\n' +
                            '\n' +
                            '  @HostListener(\'mouseleave\') mouseLeave(eventData: Event) {\n' +
                            '    //this.render.setStyle(this.el.nativeElement, \'background-color\', \'transparent\');\n' +
                            '    this.backgroundColor = this.defaultColor;\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Si atunci, se poate folosi:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' <p [bg]="\'green\'" [defaultColor]="\'red\'">Acolo</p>'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p bg5="green" defaultColor="red">Acolo</p>'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            se foloseste o componentă atunci când se vrea crearea unui set reutilizabil de elemente DOM ale UI cu comportament personalizat.
                        </li>
                        <li>
                            se foloseste o directivă atunci când se vrea un comportament reutilizabil pentru a completa elementele DOM existente.
                        </li>
                    </ul>

                    <hr/>
                    <b>8. Directiva structurale</b>
                    <br/>
                    <br/>

                    Directivele structurale sunt directive care modifică aspectul DOM prin adăugarea și eliminarea elementelor DOM.
                    <br/>
                    Exemple: NgIf, NgSwitch, etc

                    <br/>
                    <br/>
                    Directivele structurale sunt prefixate cu *.
                    Această convenție este o prescurtare pe care Angular o interpretează și o transformă într-o formă mai lungă.
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            *directiva {"=>"} [directiva]
                        </li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<div *ngIf="hero" class="name">{{hero.name}}</div>'}
                    </SyntaxHighlighter>
                    Devine:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<ng-template [ngIf]="hero">\n' +
                            '  <div class="name">{{hero.name}}</div>\n' +
                            '</ng-template>'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<div\n' +
                            '  *ngFor="let hero of heroes; let i=index; let odd=odd; trackBy: trackById"\n' +
                            '  [class.odd]="odd">\n' +
                            '  ({{i}}) {{hero.name}}\n' +
                            '</div>'}
                    </SyntaxHighlighter>
                    Devine:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<ng-template ngFor let-hero [ngForOf]="heroes"\n' +
                            '  let-i="index" let-odd="odd" [ngForTrackBy]="trackById">\n' +
                            '  <div [class.odd]="odd">\n' +
                            '    ({{i}}) {{hero.name}}\n' +
                            '  </div>\n' +
                            '</ng-template>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Crearea unui directive structurale:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<p *appUnless="condition">Show this sentence unless the condition is true.</p>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Directive, Input, TemplateRef, ViewContainerRef } from \'@angular/core\';\n' +
                            '\n' +
                            '/**\n' +
                            ' * Add the template content to the DOM unless the condition is true.\n' +
                            ' */\n' +
                            '@Directive({ selector: \'[appUnless]\'})\n' +
                            'export class UnlessDirective {\n' +
                            '  private hasView = false;\n' +
                            '\n' +
                            '  constructor(\n' +
                            '    private templateRef: TemplateRef<any>,\n' +
                            '    private viewContainer: ViewContainerRef) { }\n' +
                            '\n' +
                            '  @Input() set appUnless(condition: boolean) {\n' +
                            '    if (!condition && !this.hasView) {\n' +
                            '      this.viewContainer.createEmbeddedView(this.templateRef);\n' +
                            '      this.hasView = true;\n' +
                            '    } else if (condition && this.hasView) {\n' +
                            '      this.viewContainer.clear();\n' +
                            '      this.hasView = false;\n' +
                            '    }\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DirectivesAngularContent;