import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Anexa2MarkovContent extends BaseContentPage {

    constructor(props) {
        super(props, "anexa2-markov", IndexContent);
    }

    getMarkovText(phrase, n, grams){
        let generateText = "";
        let currentState = phrase.substring(0,n);
        //let firstState = currentState;
        generateText = currentState;
        let countIterator = phrase.length-n;
        for(let i = 0;i<countIterator;i++) {
            let probabilities = grams[currentState].next;
            if (!probabilities){
                break;
            }
            let rand = Math.floor(Math.random() * probabilities.length);
            let nextState = probabilities[rand];
            generateText = generateText + nextState;
            currentState = generateText.substring(generateText.length-n,generateText.length);
        }
        return generateText;
    }

    render() {

        // exemplul 1:
        let text1 = "caine";
        let n1 = 3;
        let grams1 = [];

        for(let i = 0;i<text1.length-n1+1;i++){
            grams1.push(text1.substring(i,i+3));
        }

        let text2 = "#"+text1+"#";
        let grams2 = [];

        for(let i = 0;i<text2.length-n1+1;i++){
            grams2.push(text2.substring(i,i+3));
        }

        // exemplul 2:
        let n = 3;
        let phrase = "sase sasi in sase saci";
        let grams = {};
        for(let i = 0;i<phrase.length-n1+1;i++){
            let gram = phrase.substring(i,i+3);

            let nextChar = phrase.charAt(i+3);
            if (!grams[gram]){
                grams[gram] = {};
                grams[gram].freq=1;
                grams[gram].next=[];
            }else{
                grams[gram].freq++;
            }
            grams[gram].next.push(nextChar);

        }

        // marcov

        let generateTexts = [];
        for(let i = 0; i<3; i++){
            generateTexts.push(this.getMarkovText(phrase,n,grams));
        }


        return (
            <div className="home boltzmann">
                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify"}>
                    <div className={"text-justify important"}>
                        Lanturile Markov, denumite dupa Andrey Markov, sunt sisteme/retele (matematice) care sar de la stare (sau situtatie sau set de valori)
                        la alta, pe baza probabilitatilor de a sari de la o stare la orice alta stare.
                    </div>
                    <br/>
                    Pentru 2 stari (A si B), exista 4 posibile tranzitii:
                    <br/>
                    <ul>
                        <li>A{"->"}A</li>
                        <li>A{"->"}B</li>
                        <li>B{"->"}A</li>
                        <li>B{"->"}B</li>
                    </ul>
                    <div className={"text-justify important"}>
                        Cu ajutorul, lanturilor Marcov se poate calcula probabilitatea de a gasi reteaua intr-o anumita stare la un moment dat.
                        <br/>
                        Să presupunem că un sistem/retea, la momentul <i>t</i> se poate găsi în stările:
                            <MathComponent tex={String.raw`S={1,2,...,n}`}/>
                        cu următoarele <i>probabilități de stare</i> (pe care le considerăm cunoscute):
                            <MathComponent tex={String.raw`P_1(t),P_2(t),...P_n(t)`}/>
                        și știm <i>probabilitatea de tranzitie</i> dintr-o stare i in starea j:
                            <MathComponent tex={String.raw`p_{ij}`}/>
                        Probabilitatile de tranzitie vor fi organizare intr-o matrice de <i>n</i> x <i>n</i>.
                        <br/>
                        Atunci probabilitatea (de stare) ca la momentul <i>t+1</i> sistemul sa se gaseasca in starea j:
                            <MathComponent tex={String.raw`P_j(t+1)=\sum_{i=1}^n P_i(t)p_{ij}`}/>
                    </div>
                    <br/>

                    <div className={"text-justify important"}>
                        Rezumând, lanturile Marcov, pot fi vazute ca o matrice, cu urmatoarele caracteristici:
                        <ul>
                            <li>este un proces (stochastic), cu spatiu starilor S
                                <MathComponent tex={String.raw`S=\{1,2,...,n\}`}/>
                            </li>
                            <li>trecerea de la o stare la alta se face pe baza unor probablitati
                                <MathComponent tex={String.raw`P_1(t),P_2(t),...P_n(t)`}/>
                            </li>
                            <li>
                                probabilitatea de a trece dintr-o stare in alta depinde doar de starea curenta
                            </li>
                            <li>
                                probabilitatea de tranzitie (trecere) din starea i in stare j
                                <MathComponent tex={String.raw`p_{ij}`}/>
                            </li>
                            <li>
                                probabilitatile de trecere sunt de fapt o matrice patrata ne-negativa, numita <b>matrice de trecere</b>
                                <MathComponent tex={String.raw`P=(p_{ij}), i,j=1,..,n`}/>
                            </li>

                            <li>
                                distributia lantului dupa t pasi este:
                                    <MathComponent tex={String.raw`p^t=p^0 P^t`}/>
                                unde:
                                <ul>
                                    <li>P<sup>t</sup> - matricea (probabilitatolor) de trecere in t pasi</li>
                                    <li>p<sup>0</sup> - vector/coloana de probabilitate, fiind distributia initiala a lantului Markov cu matricea de trecere P</li>
                                </ul>
                            </li>

                            <li>
                                o stare i se numeste <b>absorbanta/atractor</b> daca p<sub>ij</sub> = 1
                                (o stare absorbanta nu poate fi parasita, pentru trecerea de la stare i la stare i este sigura/certa/100%)
                            </li>

                            <li>
                               matricea P se numeste <b>primitiva</b> daca exista o putere a ei strict pozitiva
                            </li>

                            <li>
                                matricea P se numeste <b>egodica</b> daca puteriile ei tind catre o matrice cu toate liniile identice
                            </li>

                        </ul>
                    </div>

                    <br/>
                    <div className={"text-justify"}>
                        <b>Exemplu 1: Vremea probabila</b><br/>
                        Fie urmatoarele stari:
                        <MathComponent tex={String.raw`S=\{1,2,3\}=\{ploaie, nori, soare\}`}/>
                        Matricea de trecere:
                        <table>
                            <tr>
                                <th>-\-</th>
                                <th>ploaie</th>
                                <th>nori</th>
                                <th>soare</th>
                            </tr>
                            <tr>
                                <th>ploaie</th>
                                <td>0.4</td>
                                <td>0.3</td>
                                <td>0.3</td>
                            </tr>
                            <tr>
                                <th>nori</th>
                                <td>0.2</td>
                                <td>0.6</td>
                                <td>0.2</td>
                            </tr>
                            <tr>
                                <th>soare</th>
                                <td>0.1</td>
                                <td>0.1</td>
                                <td>0.8</td>
                            </tr>
                        </table>
                        <i>Problema</i>:<br/>
                        daca in ziua 1 este soare, care este probabilitatea ca saptamana urmatoare sa fie:<br/>
                        soare-soare-ploaie-ploaie-soare-nori-soare?
                        <br/>
                        Ziua 1 este soare se traduce prin vectorul de probabilitate p<sup>0</sup> = (0,0,1), adica soare a fost cert, iar ploaie si nori imposibil
                        (daca in ziua 1 ar fi fost ploaie atunci vectorul de probabilitate p<sup>0</sup> = (1,0,0) ).
                        <br/>
                        P(soare, soare, ploaie, ploaie, soare, nori, soare | soare) <br/>
                         = P(soare) * P(soare | soare) * P(soare | soare) * P(ploaie | soare) * P(ploaie | ploaie) * P( soare | ploaie ) * P( nori | soare ) * P(soare | nori)  <br/>
                         = 1 * 0.8 * 0.8 * 0.1 * 0.4 * 0.3 * 0.1 * 0.2 <br/>
                         = 0.0001536
                        <br/>
                        Primul P(soare)=1.
                        <br/>
                        {/*Folosind mai sus, urmatoarele reguli:*/}
                        {/*<MathComponent tex={String.raw`P(x_1,x_2,...,x_n) = P(x_1) * P(x_2)*....*P(x_{n})`}/>*/}
                        {/*<MathComponent tex={String.raw`P(x_1,x_2,...,x_n|y) = {P(x_1,x_2,...,x_n,y) \over P(y)}`}/>*/}

                    </div>
                    <br/>

                    <br/>
                    <div className={"text-justify important"}>
                        <b>Definitie:</b>
                        <br/>
                        Un <b>N-gram</b> este o multime formată din toate combinațiile de cuvinte adiacente sau litere de lungime n dintr-un text dat.
                    </div>

                    <br/>
                    <b>Exemplu 2:</b>
                    <br/>
                    Daca avem textul: {text2} si N={n1}, vom avem multimea {n1}-gram =
                    {
                        grams1.map((v,i)=>{
                            return v+((i==grams1.length-1)?"":",");
                        })
                    }
                    <br/>(daca consideram caracterul '#' ca litera/cuvant limită, avem {n1}-gram =
                    {
                        grams2.map((v,i)=>{
                            return v+((i==grams2.length-1)?"":",");
                        })
                    }
                    )
                    <br/>
                    <br/>
                    <b>Exemplu 3</b><br/>
                    Sa presupunem ca avem textul: {phrase}.
                    Vom construi un tabel cu {n}-ngramele asociate textului de mai sus,  plus o lista cu caracterele ce pot urma dupa {n}-gram:
                    <table>
                        <tr>
                            <td>n-gram</td>
                            {/*<td>frecventa</td>*/}
                            <td>catactere posibil urmatoare</td>
                        </tr>
                        {
                            Object.keys(grams).map((g,v)=>{
                                return<tr>
                                    <td>{g}</td>
                                    {/*<td>{grams[g].freq}</td>*/}
                                    <td>
                                        {
                                            grams[g].next.map((vv,ii)=>{
                                                return vv+ ((ii==grams[g].next.length-1)?"":",")
                                            })
                                        }
                                    </td>
                                </tr>;
                            })
                        }
                    </table>
                    Precum se poate observa unele caractere posibil urmatoare din caudrul unui element {n}-gram se repeta (de exemplu pentru 'sa ' avem posible
                    caractere urmatoare: 's,s,c', ceea ce inseamna că sunt de 2 ori mai multe sanse pentru 'sa a' sa mearga catre 's' decat catre 'c'.
                    <br/>
                    Pe baza tabelului de mai sus, folosind ideea de lant Markov,
                    se pot genera urmatoarele texte (pornind de la primul element {n}-gram, adica {phrase.substring(0,n)}):
                    <ul>
                        {
                            generateTexts.map((t)=>{
                                return <li>{t}</li>
                            })
                        }
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Dumitru Popescu, Maria Luiza Flonta. 2009. Teoria rețelelor neuronale artificiale, Editura Universității din București</div>
                        </li>
                        <li>
                            <a href={"https://setosa.io/ev/markov-chains/"} target={"_blank"}  rel="noreferrer">
                                https://setosa.io/ev/markov-chains/
                            </a>
                        </li>

                        <li>
                            <a href={"https://www.khanacademy.org/computing/computer-science/informationtheory/moderninfotheory/v/markov_chains"} target={"_blank"}  rel="noreferrer">
                                https://www.khanacademy.org/computing/computer-science/informationtheory/moderninfotheory/v/markov_chains
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Anexa2MarkovContent;