import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CaracteristiciLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-caracteristici", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Caracteristici tehnice</b>
                    <br/>
                    <br/>

                    Liferay Portal este disponibil în două ediții:
                    <ul>
                        <li>
                            Community Edition
                            <br/>
                            <ul>
                                <li>
                                    gratuită și licențiată sub licența publică LGNU
                                </li>
                                <li>
                                    dacă se modifica codul Liferay, modificările trebuie să fie lansate ca software gratuit
                                </li>
                            </ul>
                        </li>
                        <li>
                            Enterprise Edition
                            <ul>
                                <li>disponibilă pe bază de abonament</li>
                            </ul>
                        </li>
                    </ul>

                    În 2016, a fost lansată o nouă versiune de Liferay cu versiunea numărul 7, denumită <b>Liferay Digital Experience Platform (Liferay DXP)</b>.
                    <br/>
                    Una dintre principalele schimbări în tehnologie a fost adaptarea <b>Open Services Gateway Initiative (OSGi)</b> ca standard care este utilizat pentru modularizarea nucleului Liferay.

                    <br/>
                    <br/>
                    Comparăm Liferay 7 DXP cu predecesorul său, Liferay 6.2, principala schimbare este modularitatea realizată de OSGi, dar au existat multe alte modificări.
                    Liferay 7 DXP aduce și suport pentru componentele <b>Bootsrap 3</b>, AUI 3.x, iar noul motor de căutare, <b>Elastic Search</b>, este deja încorporat.

                    <table width={"100%"} className={"num"}>
                        <thead>
                            <tr>
                                <th>Caracterisitica</th>
                                <th>Observatie</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Java JDK</td>
                                <td>Liferay DXP/Portal necesită Java JDK 8 sau 11
                                    <br/>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>

                                </td>

                            </tr>

                            <tr>
                                <td>Operating System / Sisteme de operare</td>
                                <td>
                                    <ul>
                                        <li>Windows Server 2016 </li>
                                        <li>Windows Server 2019 </li>
                                        <li>Red Hat Enterprise Linux 7 </li>
                                        <li>Red Hat Enterprise Linux 8 </li>
                                        <li>SUSE Enterprise Linux 12 </li>
                                        <li>SUSE Enterprise Linux 15</li>
                                        <li>Ubuntu LTS 18.04 </li>
                                        <li>Ubuntu LTS 20.04</li>
                                        <li>Alpine Linux 3.10</li>
                                        <li>Amazon Linux 2</li>
                                        <li>CentOS 7</li>
                                        <li>CentOS 8</li>
                                        <li>Debian 10</li>
                                        <li>IBM AIX 7.2</li>
                                        <li>Oracle Linux 7</li>
                                        <li>Oracle Solaris 11</li>
                                    </ul>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>Desktop Browser/ Browsere de desktop</td>
                                <td>
                                    <ul>
                                        <li>Chrome 65 </li>
                                        <li>Chrome </li>
                                        <li>Edge </li>
                                        <li>Firefox  </li>
                                        <li>Firefox ESR </li>
                                        <li>Safari 12 </li>
                                        <li>Safari 13 </li>
                                        <li>Safari  </li>
                                        <li>Internet Explorer 11 (limitat)</li>
                                    </ul>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>Mobile Browser/ Browsere de mobil</td>
                                <td>
                                    <ul>
                                        <li>Chrome </li>
                                        <li>Safari </li>
                                    </ul>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>Application Server / Servere de aplicatie</td>
                                <td>
                                    <ul>
                                        <li>Tomcat 9.0 </li>
                                        <li>Websphere 9.0 </li>
                                        <li>Weblogic 12c Release 2 </li>
                                        <li>Wildfly 14.0 </li>
                                        <li>Wildfly 17.0 </li>
                                        <li>Wildfly 18.0</li>
                                        <li>Wildfly 23.0 </li>
                                        <li>JBoss EAP 7.2</li>
                                        <li>JBoss EAP 7.3</li>
                                        <li>JBoss EAP 7.4</li>
                                    </ul>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>Database / Baze de date</td>
                                <td>
                                    <ul>
                                        <li>MySQL 5.7 </li>
                                        <li>MySQL 8.0 </li>
                                        <li>Postgres 11 </li>
                                        <li>Postgres 12 </li>
                                        <li>SQL Server 2017 </li>
                                        <li>SQL Server 2019</li>
                                        <li>Oracle 19c </li>
                                        <li>DB2 11.5</li>
                                        <li>Amazon Aurora</li>
                                        <li>Maria DB 10.2</li>
                                        <li>Maria DB 10.4</li>
                                    </ul>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>Search Engines / Motoare de cautare</td>
                                <td>
                                    <ul>
                                        <li>Elasticsearch 7</li>
                                        <li>Solr</li>
                                    </ul>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                        Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                    </a>

                                    <br/>
                                    <a href={"https://help.liferay.com/hc/en-us/articles/360016511651"} target={"_blank"}>
                                        Search Engine Compatibility Matrix
                                    </a>

                                </td>
                            </tr>

                            <tr>
                                <td>suport RSS</td>
                                <td>
                                    Liferay DXP este o platformă pentru integrarea de conținut și servicii din back-end sau aplicații vechi.
                                    Liferay DXP suporta mai multe metode de integrare, inclusiv SOAP, REST, GraphQL, RSS, precum și API-uri proprietare.
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport GraphQL</td>
                                <td>
                                    Liferay DXP este o platformă pentru integrarea de conținut și servicii din back-end sau aplicații vechi.
                                    Liferay DXP suporta mai multe metode de integrare, inclusiv SOAP, REST, GraphQL, RSS, precum și API-uri proprietare.
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>

                            </tr>

                            <tr>
                                <td>suport SOAP</td>
                                <td>
                                    Liferay DXP este o platformă pentru integrarea de conținut și servicii din back-end sau aplicații vechi.
                                    Liferay DXP suporta mai multe metode de integrare, inclusiv SOAP, REST, GraphQL, RSS, precum și API-uri proprietare.
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport REST</td>
                                <td>
                                    Liferay DXP este o platformă pentru integrarea de conținut și servicii din back-end sau aplicații vechi.
                                    Liferay DXP suporta mai multe metode de integrare, inclusiv SOAP, REST, GraphQL, RSS, precum și API-uri proprietare.
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport Angular</td>
                                <td>
                                    Liferay DXP suporta Framwork-uri Javascript: Angular, Vue.js, React (fluxurile de lucru JavaScript moderne sunt incluse imediate (out-of-the-box / OOTB))
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport Vue.js</td>
                                <td>
                                    Liferay DXP suporta Framwork-uri Javascript: Angular, Vue.js, React (fluxurile de lucru JavaScript moderne sunt incluse imediate (out-of-the-box / OOTB))
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport React</td>
                                <td>
                                    Liferay DXP suporta Framwork-uri Javascript: Angular, Vue.js, React (fluxurile de lucru JavaScript moderne sunt incluse imediate (out-of-the-box / OOTB))
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport JSF</td>
                                <td>
                                    Liferay DXP suporta Framwork-uri Java: JSF, SpringMVC, Vaadin
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport SpringMVC</td>
                                <td>
                                    Liferay DXP suporta Framwork-uri Java: JSF, SpringMVC, Vaadin
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport Vaadin</td>
                                <td>
                                    Liferay DXP suporta Framwork-uri Java: JSF, SpringMVC, Vaadin
                                    <br/>
                                    sursa: Liferay DXP 7.3 Features Overview
                                </td>
                            </tr>

                            <tr>
                                <td>suport standarde si tehnnologii</td>
                                <td>
                                    Liferay DXP ofera suport pentru standardele si tehnlogiile:
                                    <ul>
                                        <li>
                                            AJAX
                                        </li>
                                        <li>
                                            <b>iCalendar</b>
                                        </li>
                                        <li>
                                            <b>Microformat</b>
                                        </li>
                                        <li>
                                            <b>MVC 1.0 (JSR-371)</b>
                                        </li>
                                        <li>
                                            <b>Spring 3.0</b>
                                        </li>

                                        <li><a href={"https://jcp.org/en/jsr/detail?id=168"} target={"_blank"}> Portleturi 1.0</a> (<b>JSR-168</b>) și
                                            <a href={"https://jcp.org/en/jsr/detail?id=286"} target={"_blank"}> Portleturi 2.0</a> (<b>JSR-286</b>).
                                            <br/>
                                            Liferay DXP poate rula orice portlet care urmează aceste două versiuni ale specificației.
                                            <br/>
                                            Liferay este implicat în viitoarea specificație Portlets 3.0.
                                        </li>

                                        <li>
                                            <b>JSR-362 (Portlet 3.0)</b>
                                        </li>

                                        <li>
                                            <a href={"https://jcp.org/en/jsr/detail?id=168"} target={"_blank"}> <b>JSF</b> </a>  <b>(JSR-127, JSR-314, JSR-344)</b>
                                            <br/>
                                            Standardul Java pentru construirea de aplicații web bazate pe componente.
                                            <br/>
                                            Liferay contribuie activ la standardul și liderul specificației JSF-Portlet Bridge.
                                        </li>
                                        <li>
                                            <b>JSF 2.2 (JSR-344)</b>
                                        </li>
                                        <li>
                                            <b>JAX-WS</b> și <b>JAX-RS</b>: incorporate începând cu Liferay 7 ca instrument pentru a crea servicii web.
                                        </li>
                                        <li>
                                            <b>OSGi r6</b> : Liferay suportă o gamă largă de standarde OSGi prin implementări proprii și integrează implementările de înaltă calitate ale proiectelor Apache Felix și Eclipse Equinox
                                        </li>
                                        <li>
                                            <b>OSGi Core 6.0</b>
                                        </li>
                                        <li>
                                            <b>SAML</b> și <b>OAuth 1.1</b> : protocoale de securitate pentru SSO și conectarea la aplicații,
                                        </li>
                                        <li>
                                            <b>SAML 3.0</b>
                                        </li>
                                        <li>
                                            <b>EcmaScript 2015</b> : standardul JavaScript.
                                            <br/>
                                            Instrumentele Liferay oferă posibilitatea de a-l utiliza în toate browserele moderne datorită integrării <b>Babel JS</b>.
                                        </li>
                                        <li>
                                            <b>WebDAV</b> (WebDAV înseamnă „Web-based Distributed Authoring and Versioning”. Este un set de extensii la protocolul HTTP care permite utilizatorilor să editeze și să gestioneze în colaborare fișierele de pe servere web la distanță)
                                            <br/>
                                            Orice folder Documents & Media poate fi montat oriunde este acceptat WebDAV, cum ar fi Windows Explorer sau clienți specifici WebDAV.
                                        </li>
                                        <li>
                                            <b>Content Management Interoperability Services (CMIS)</b>:  Documentele și media Liferay se pot comporta ca o interfață pentru orice depozit de documente extern care acceptă acest standard.
                                        </li>
                                        <li>
                                            <b>CMIS 1.0/1.1</b>
                                        </li>
                                        <li>
                                            <a href={"https://jcp.org/en/jsr/detail?id=170"} target={"_blank"}>  Java Content Repository </a> (JSR-170)
                                        </li>
                                    </ul>
                                    sursa: Liferay DXP 7.3 Features Overview <br/>
                                    * Development Standards  and Technologies
                                    AJAX, iCalendar, Microformat, JSR-362 (Portlet 3.0), JSR-371 (MVC1.0), JSR-344
                                    (JSF 2.2), Spring 3.0, CMIS 1.0/1.1, Hibernate collection of projects, OSGi Core 6.0
                                    and SAML 3.0.
                                </td>
                            </tr>
                            <tr>
                                <td>functionalitate Calendar</td>
                                <td>
                                    Un calendar bazat pe comunitate cu liste de sarcini care permite utilizatorilor să creeze,
                                    gestionați și căutați evenimente. Evenimentele pot fi partajate între comunități și
                                    mementourile de evenimente pot fi setate pentru a alerta utilizatorii cu privire la evenimentele viitoare prin e-mail,
                                    notificări sau SMS. De asemenea, puteți crea și gestiona resursele companiei
                                    prin calendar.
                                </td>
                            </tr>

                            <tr>
                                <td>functionalitate de tip Site Map</td>
                                <td>
                                    Liferay DXP ofera portletul/widget-ul <b>Site Map</b>
                                    <br/>
                                    Widget-ul <b>Site Map</b> poate fi adaugat din:
                                    Fragments and Widgets {"->"} Widgets {"->"} Content Management {"->"} Site Map
                                </td>
                            </tr>
                            <tr>
                                <td>suport de integrare si autentificare in servicii externe multiple, folosind SSO (Single-Sign On) securizate</td>
                                <td>
                                    <ul>
                                        <li>
                                            <b>SiteMinder</b>
                                            <br/>
                                            sursa: <a href={"https://liferay.dev/es/blogs/-/blogs/siteminder-integration-is-here"}>SiteMinder integration is here</a>
                                        </li>
                                        <li>Shibboleth
                                            <br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360018175391-Token-based-Single-Sign-On-Authentication"}>Token-based Single Sign On Authentication</a>
                                        </li>
                                        <li>Oracle OAM
                                            <br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360018175391-Token-based-Single-Sign-On-Authentication"}>Token-based Single Sign On Authentication</a>
                                        </li>
                                        <li>
                                            <b>LDAP</b>
                                            <br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>

                                        <li>

                                            SSO
                                            <br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>

                                        <li>

                                            <b>CAS</b>
                                           <br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>

                                        <li>

                                            <b>Facebook</b><br/>

                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>


                                        <li>

                                            NTLM<br/>

                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>


                                        <li>
                                            <b>OpenID</b><br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                            <br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360024805271-Authenticating-with-OpenID-Connect"}>Authenticating with OpenID Connect</a>
                                        </li>

                                        <li>
                                            Crowd<br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>

                                        <li>
                                            <b>Open SSO</b><br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>

                                        <li>
                                            <b>SAML</b><br/>
                                            sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360017905412-Integrating-Existing-Users-into-Liferay"}>Integrating Existing Users into Liferay</a>
                                        </li>
                                        <li>
                                            <b>OpenAM</b> (numai versiunea 13)
                                            <br/>
                                            sursa: <a href={"https://learn.liferay.com/dxp/latest/en/installation-and-upgrades/securing-liferay/configuring-sso/using-openam.html"}>Using OpenAM</a>
                                        </li>

                                    </ul>
                                </td>
                            </tr>
                            <tr>

                                    <td>
                                        Blog
                                    </td>
                                    <td>
                                        Widgetul de blog al Liferay DXP oferă un editor WYSIWYG modern (AlloyEditor)
                                        conceput pentru a crea intrări rapid și un design modern pentru afișarea blogurilor.
                                        <br/>
                                        Operatii:
                                        <ul>
                                            <li>editare imagini: editorul de imagini inline permite editarea ușoară a imaginii direct în interior Liferay DXP;
                                                se pot trage și plasa imagini direct în textul blogului.
                                            </li>
                                            <li>
                                                formare text: pentru a adauga un format unui text, trebuie selectat și o bară de instrumente modernă va afisa  opțiunile de format.
                                            </li>
                                            <li>crearea de adrese URL prietenoase</li>
                                            <li>timp estimat de citire</li>
                                            <li>dezabonare de la e-mailuri</li>
                                            <li>data publicării</li>
                                            <li>suport RSS</li>
                                            <li>suport comentarii ale utilizatorilor și oaspeților</li>
                                            <li>suport tag-uri</li>
                                            <li>suport etichete</li>
                                            <li>link-uri de marcare sociale</li>
                                            <li>notificări prin e-mail ale răspunsurilor pe blog</li>
                                            <li>sistem de evaluare a rating-urilor</li>
                                            <li>adăugare videoclipuri: YouTube, Vimeo, Facebook Video și Twitch</li>
                                        </ul>
                                    </td>

                            </tr>
                            <tr>
                                <td>
                                    Wiki
                                </td>
                                <td>
                                    O soluție Wiki simplă cu capabilități de versiuni, categorii,
                                    Moduri creolă, HTML sau text simplu, editare WYSIWYG, suport pentru glisare și plasare  pentru crearea paginii, istoricul paginii și revenirea și permisiunile.
                                </td>
                            </tr>
                            <tr>
                                <td>Editor WYSIWYG</td>
                                <td>da
                                    <ul>
                                        <li>
                                            Widgetul de blog al Liferay DXP oferă un editor WYSIWYG modern (AlloyEditor)
                                            conceput pentru a crea intrări rapid și un design modern pentru afișarea blogurilor.
                                        </li>
                                        <li>
                                            Widgetul de Wiki
                                        </li>
                                    </ul>

                                    Editorii WYSIWYG sunt o parte importantă a creării de conținut.
                                    Platforma Liferay acceptă mai multe editori diferite: CKEditor, TinyMCE și AlloyEditor.
                                    <br/>
                                    sursa: <a href={"https://help.liferay.com/hc/en-us/articles/360018168311-Introduction-to-WYSIWYG-Editors-"}>Introduction to WYSIWYG Editors</a>
                                </td>
                            </tr>


                        </tbody>
                    </table>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360049238151"} target={"_blank"}>
                                    Matricea de compatibilitate Liferay DXP 7.3 și Liferay Commerce 3.0
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360016511651"} target={"_blank"}>
                                    Search Engine Compatibility Matrix
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/getting-started/whats-new-74.html"}>
                                    What’s New in Liferay DXP 7.4
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/site-building/optimizing-sites/ab-testing/creating-ab-tests.html"}>
                                    Creating A/B Tests
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/content-dashboard/content-dashboard-interface.html"}>
                                    Content Dashboard Interface
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/web-content/translating-web-content/automatically-translating-web-content.html"}>
                                    Automatically Translating Web Content
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/documents-and-media/uploading-and-managing/using-file-expiration-and-review-dates.html"}>
                                    Using File Expiration and Review Dates
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/documents-and-media/uploading-and-managing/enabling-docusign-digital-signatures.html"}>
                                    Enabling DocuSign Digital Signatures
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CaracteristiciLiferayContent;