import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class SubjectAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-subject", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Subject</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const mySubject = new Subject<number>();\n' +
                            'mySubject.subscribe({\n' +
                            '  next: (z) => console.log(\'a\');\n' +
                            '});\n' +
                            'mySubject.subscribe({\n' +
                            '  next: (z) => console.log(\'b\');\n' +
                            '});\n' +
                            'mySubject.next(1)\n' +
                            'mySubject.next(2)\n' +
                            '\n' +
                            'mySubject.subscribe({\n' +
                            '  next: (z) => console.log(\'c\');\n' +
                            '});\n' +
                            '\n' +
                            'mySubject.next(3)'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'a\n' +
                            'b\n' +
                            'a\n' +
                            'b\n' +
                            'a\n' +
                            'b\n' +
                            'c'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            (a,b) {"->"} de la mySubject.next(1)
                        </li>
                        <li>
                            (a,b) {"->"} de la mySubject.next(2)
                        </li>
                        <li>
                            (a,b,c) {"->"} de la mySubject.next(3)
                        </li>
                    </ul>


                    <hr/>
                    <b>2. BehaviorSubject</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const source = new BehaviorSubject<string>(\'\');\n' +
                            'source.next(\'hi\')\n' +
                            'source.next(\'hello\')\n' +
                            'source.subscribe(x=>\n' +
                            '  console.log(x)\n' +
                            ')'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'hi\n' +
                            'hello'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var behariorSubject = new BehaviorSubject<number>(0);\n' +
                            'behariorSubject.subscribe({\n' +
                            '  next: (z) => console.log(\'observerA: \' + z) \n' +
                            '});\n' +
                            '\n' +
                            'behariorSubject.next(1);  \n' +
                            '\n' +
                            'behariorSubject.next(2);   \n' +
                            '\n' +
                            'behariorSubject.subscribe({\n' +
                            '  next: (z) => console.log(\'observerB: \' + z)  \n' +
                            '});\n' +
                            '\n' +
                            'behariorSubject.next(3);'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'observerA: 0\n // valoare intiala' +
                            'observerA: 1\n' +
                            'observerA: 2\n' +
                            'observerB: 2\n // valoare anterioara' +
                            'observerA: 3\n' +
                            'observerB: 3'}
                    </SyntaxHighlighter>

                    <hr/>
                    <ul>
                        <li>
                            <b>Subiect</b>-ul nu are nevoie de initializare cu o valoare
                        </li>
                        <li>
                            <b>BehaviorSubject</b>-ul  are nevoie de initializare cu o valoare implicita
                        </li>
                        <li>
                            abonatii unui <b>Subiect</b> vor primi doar valoarea viitoare;
                            <br/>
                            nu se primeste valoarea curenta sau valoare initiala
                        </li>
                        <li>
                            abonatii unui <b>BehaviorSubject</b> vor primi valoarea anterioara si viitoare
                            <br/>
                            se primeste valoarea curenta sau valoare initiala
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://dev.to/revanth_oleti/difference-between-subject-and-behaviorsubject-9g6"}>
                               Difference between Subject and BehaviorSubject
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SubjectAngularContent;