import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class GoogleAnalyticsIntegrationLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-ga", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Cookies: jquery.ihavecookies.min.js</b>

                    <br/>
                    <br/>
                    Fragment de cod:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'function loadGoogleAnalytics() {\n' +
                            '    if ($.fn.ihavecookies == null || $.fn.ihavecookies.cookie() == null ||\n' +
                            '        window.google_analytics_id == null || window.google_analytics_id.length === 0) {\n' +
                            '        return;\n' +
                            '    }\n' +
                            '    let analyticsEnabled = false;\n' +
                            '    let allowedCookies = $.fn.ihavecookies.cookie();\n' +
                            '    for (let i = 0; i < allowedCookies.length; i++) {\n' +
                            '        if ("analytics" === allowedCookies[i]) {\n' +
                            '            analyticsEnabled = true;\n' +
                            '        }\n' +
                            '    }\n' +
                            '    if (!analyticsEnabled) {\n' +
                            '        return;\n' +
                            '    }\n' +
                            '    let newScript = document.createElement("script");\n' +
                            '    newScript.type = "text/javascript";\n' +
                            '    newScript.setAttribute("async", "true");\n' +
                            '    newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + window.google_analytics_id);\n' +
                            '    document.documentElement.firstChild.appendChild(newScript);\n' +
                            '\n' +
                            '    window.dataLayer = window.dataLayer || [];\n' +
                            '    function gtag(){dataLayer.push(arguments);}\n' +
                            '    gtag(\'js\', new Date());\n' +
                            '\n' +
                            '    gtag(\'config\', window.google_analytics_id);\n' +
                            '}'}
                    </SyntaxHighlighter>


                    Fie <i>custom.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'window.onscroll = function (e) {\n' +
                            '    var topScrolled = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;\n' +
                            '    if (topScrolled > 125) {\n' +
                            '        document.getElementById(\'header-wrapper\').parentNode.parentNode.classList.add(\'scrolled\');\n' +
                            '        document.getElementById(\'back-to-top-icon\').classList.add(\'scrolled\');\n' +
                            '    } else {\n' +
                            '        document.getElementById(\'header-wrapper\').parentNode.parentNode.classList.remove(\'scrolled\');\n' +
                            '        document.getElementById(\'back-to-top-icon\').classList.remove(\'scrolled\');\n' +
                            '\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'var backToTopButton = document.getElementById("back-to-top-icon");\n' +
                            '\n' +
                            'if (backToTopButton) {\n' +
                            '    backToTopButton.addEventListener("click", function () {\n' +
                            '        document.body.scrollTop = 0;\n' +
                            '        document.documentElement.scrollTop = 0;\n' +
                            '    });\n' +
                            '}\n' +
                            '\n' +
                            'function loadGoogleAnalytics() {\n' +
                            '    if ($.fn.ihavecookies == null || $.fn.ihavecookies.cookie() == null ||\n' +
                            '        window.google_analytics_id == null || window.google_analytics_id.length === 0) {\n' +
                            '        return;\n' +
                            '    }\n' +
                            '    let analyticsEnabled = false;\n' +
                            '    let allowedCookies = $.fn.ihavecookies.cookie();\n' +
                            '    for (let i = 0; i < allowedCookies.length; i++) {\n' +
                            '        if ("analytics" === allowedCookies[i]) {\n' +
                            '            analyticsEnabled = true;\n' +
                            '        }\n' +
                            '    }\n' +
                            '    if (!analyticsEnabled) {\n' +
                            '        return;\n' +
                            '    }\n' +
                            '    let newScript = document.createElement("script");\n' +
                            '    newScript.type = "text/javascript";\n' +
                            '    newScript.setAttribute("async", "true");\n' +
                            '    newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + window.google_analytics_id);\n' +
                            '    document.documentElement.firstChild.appendChild(newScript);\n' +
                            '\n' +
                            '    window.dataLayer = window.dataLayer || [];\n' +
                            '    function gtag(){dataLayer.push(arguments);}\n' +
                            '    gtag(\'js\', new Date());\n' +
                            '\n' +
                            '    gtag(\'config\', window.google_analytics_id);\n' +
                            '}\n' +
                            '\n' +
                            '$(document).ready(function () {\n' +
                            '    $(\'body\').ihavecookies({\n' +
                            '        title: \'\',\n' +
                            '        message: Liferay.Language.get(\'theme.site.cookie.message\'),\n' +
                            '        link: \'/politica-cookies\',\n' +
                            '        moreInfoLabel: Liferay.Language.get(\'theme.site.cookie.moreInfoLabel\'),\n' +
                            '        acceptBtnLabel: Liferay.Language.get(\'theme.site.cookie.acceptBtnLabel\'),\n' +
                            '        advancedBtnLabel: Liferay.Language.get(\'theme.site.cookie.advancedBtnLabel\'),\n' +
                            '        cookieTypesTitle: Liferay.Language.get(\'theme.site.cookie.cookieTypesTitle\'),\n' +
                            '        fixedCookieTypeLabel: Liferay.Language.get(\'theme.site.cookie.fixedCookieTypeLabel\'),\n' +
                            '        fixedCookieTypeDesc: Liferay.Language.get(\'theme.site.cookie.fixedCookieTypeDesc\'),\n' +
                            '        cookieTypes: [\n' +
                            '            {\n' +
                            '                type: Liferay.Language.get(\'theme.site.cookie.cookieTypes.statistic-type\'),\n' +
                            '                value: \'analytics\',\n' +
                            '                description: \'\'\n' +
                            '            }\n' +
                            '        ],\n' +
                            '        onAccept: function () {\n' +
                            '            loadGoogleAnalytics();\n' +
                            '        }\n' +
                            '    });\n' +
                            '    loadGoogleAnalytics();\n' +
                            '});'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://liferay.dev/de/blogs/-/blogs/fronting-liferay-tomcat-with-apache-httpd-daemon-revisted"}>*/}
                    {/*                Fronting Liferay Tomcat with Apache HTTPd daemon Revisted*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GoogleAnalyticsIntegrationLiferayContent;