import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class StatsContainerDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-container-stats", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker container stats</b>
                    <br/>
                    <br/>

                    Pentru a vizualiza live resursele utilizate de containere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container stats'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   NAME                  CPU %     MEM USAGE / LIMIT     MEM %     NET I/O           BLOCK I/O         PIDS\n' +
                            '5a4cdd28b1fe   loving_pasteur        0.00%     2.672MiB / 5.795GiB   0.05%     26.8kB / 0B       32.8kB / 12.3kB   3\n' +
                            '213e8cf4e499   fervent_driscoll      0.00%     9.297MiB / 5.795GiB   0.16%     27.4kB / 1.27kB   8.64MB / 12.3kB   3\n' +
                            'a3bef0b0cc6e   curl                  0.00%     1.398MiB / 5.795GiB   0.02%     271kB / 18.3kB    1.49MB / 156kB    1\n' +
                            'c69ac6bb47d8   minikube              18.87%    1.165GiB / 2.148GiB   54.22%    789MB / 51.4MB    2.16GB / 126GB    1057\n' +
                            'bf43181baaa1   complex-nginx-1       0.00%     2.855MiB / 5.795GiB   0.05%     8MB / 4.1MB       1.51MB / 414kB    3\n' +
                            '05c4d660062b   complex-client-1      0.02%     50.99MiB / 5.795GiB   0.86%     4.52MB / 3.55MB   17.2MB / 494MB    30\n' +
                            '1de013bc0797   complex-postgres-1    0.01%     18.03MiB / 5.795GiB   0.30%     3.84MB / 10kB     19MB / 10.2MB     6\n' +
                            'aa07e6c09d06   complex-worker-1      0.00%     14.54MiB / 5.795GiB   0.25%     5.9MB / 3.97MB    6.97MB / 41.4MB   33\n' +
                            'cdb7c25cd6fa   complex-redis-1       0.18%     2.934MiB / 5.795GiB   0.05%     8.51MB / 7.96MB   5.27MB / 422kB    5\n' +
                            'af1aadadb6c7   complex-api-1         0.00%     15MiB / 5.795GiB      0.25%     6.5MB / 4MB       2.7MB / 36.1MB    34\n' +
                            '4ff5b2b10044   wizardly_kowalevski   0.00%     4.59MiB / 5.795GiB    0.08%     684kB / 155kB     9.02MB / 537kB    3\n'}
                    </SyntaxHighlighter>


                    Pentru a vizualiza live resursele utilizate de un singur container (de exemplu <i>minikube</i>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container stats minikube'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'CONTAINER ID   NAME       CPU %     MEM USAGE / LIMIT     MEM %     NET I/O          BLOCK I/O        PIDS\n' +
                            'c69ac6bb47d8   minikube   31.24%    1.168GiB / 2.148GiB   54.38%    789MB / 51.4MB   2.16GB / 126GB   1057\n'}
                    </SyntaxHighlighter>

                    Pentru a vizualiza "inghetate" resursele utilizate:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container stats --no-stream -all'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StatsContainerDockerContent;