import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class SSHNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-ssh", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare SSH pe NAS</b>
                    <br/>
                    <br/>

                    SSH  = Secure Shell Protocol, protocol criptografic de retea pentru servicii de operare in reata peste o retea nesigura.
                    <br/>
                    <br/>
                    Conectat la NAS:
                    <ul>
                        <li>
                            <b>Control Panel {"->"} Terminal & SNMP {"->"} tab Terminal:</b>
                                <ul>
                                    <li>bifare <b>Enable SSH service</b></li>
                                    <li>Port:22</li>
                                </ul>
                            <br/>

                        </li>
                    </ul>

                    <hr/>
                    <b>2. Conectare prin Putty la NAS</b>
                    <br/>
                    <br/>
                    Se deschide Putty din Windows:
                    <ul>
                        <li>Host Name (or IP address): IP NAS</li>
                        <li>Port: 22</li>
                        <li>se apasa butonul <i>Open</i></li>
                        <li>din consola, ne conectam cu un cont de administrator</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SSHNasContent;