import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";

class VirtualBoxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "windows-virtualbox", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Descarcare VirtualBox</b>:
                    <br/>
                    <br/>

                    VirtulaBox se poate descarca de pe: <a target={"_blank"} href={"https://www.virtualbox.org/"}>https://www.virtualbox.org/</a>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'https://www.virtualbox.org/'}
                    </SyntaxHighlighter>

                    De aici se merge in zona de download sau:
                    <a target={"_blank"} href={"https://www.virtualbox.org/wiki/Downloads"}> https://www.virtualbox.org/wiki/Downloads</a>

                    <br/>
                    Pentru Windows din sectiunea <b>VirtualBox 6.1.36 platform packages</b> se alege <b>Windows hosts</b> (se va descarca un fisier de 106M).
                    <br/>
                    Dupa ce se downloadeaza, se instaleaza.

                    <hr/>
                    <b>2. Instalare Ubuntu pe VirtualBox</b>:
                    <br/>
                    <br/>

                    Se descarca de pe urmatorul site: <a target={"_blank"} href={"https://www.osboxes.org/ubuntu"}>https://www.osboxes.org/ubuntu</a> versiunea pentru VirtualBox.
                    (se va descara un fisier de 2.6G - 64bit.7z)
                    <br/>
                    Acest fisier se dezarhiveaza, si va rezulta fisierul: <i>Ubuntu 22.04 (64bit).vdi</i>
                    <br/>
                    Acest fisier se importa in VirtualBox.
                    <br/>
                    <b>New {"->"} </b> din ecranul care apare {"->"}
                    <ul>
                        <li>Name: ElasticSearch</li>
                        <li>Machine Folder: D:\vm</li>
                        <li>Type: Linux</li>
                        <li>Version: Ubuntu (64-bit)</li>
                    </ul>
                    Din urmatorul ecran se alege 4096MB memorie.
                    <br/>
                    Din urmatorul ecran se alege: <b>Use an existing virtual hard disk file</b> si selecteaza fisierul <i>Ubuntu 22.04 (64bit).vdi</i> {"->"} Choose.
                    <br/>
                    Apoi se apasa butonul <b>Create</b>.
                    <br/>
                    Apoi cu <i>ElasticSearch</i> selectat, se apasa <b>Start</b>.
                    <br/>
                    <br/>
                    Logare in ubuntu:
                    <ul>
                        <li>
                            username:osboxes.org
                        </li>
                        <li>
                            password:osboxes.org
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/util/img.png'}/>
                    </div>


                    <hr/>
                    <b>3. Instalare ElasticSearch pe Ubuntu</b>:
                    <br/>
                    <br/>

                    Se urmeaza pasii: <a target={"_blank"} href={"http://media.sundog-soft.com/es8/install.txt"}>http://media.sundog-soft.com/es8/install.txt</a>

                    <b>3.1. Descarcare si instalare cheia publica se semnare:</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'wget -qO - https://artifacts.elastic.co/GPG-KEY-elasticsearch | sudo gpg --dearmor -o /usr/share/keyrings/elasticsearch-keyring.gpg'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        3.2. Install apt-transport-https
                    </b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get install apt-transport-https'}
                    </SyntaxHighlighter>

                    <b>
                        3.3. Salvare repository definition
                    </b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'echo "deb [signed-by=/usr/share/keyrings/elasticsearch-keyring.gpg] https://artifacts.elastic.co/packages/8.x/apt stable main" | sudo tee /etc/apt/sources.list.d/elastic-8.x.list'}
                    </SyntaxHighlighter>

                    <b>
                        3.4. Instalare Elasticsearch
                    </b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get update && sudo apt-get install elasticsearch'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        3.4. Configurare Elasticsearch
                    </b>
                    <br/>
                    <br/>
                    Trebuie dezactivata securitatea. V8 vine cu securitate activata.
                    <br/>
                    3.4.1. Se editeaza fisierul <b>/etc/elasticsearch/elasticsearch.yml</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nano /etc/elasticsearch/elasticsearch.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'node.name:  node-1 # trebuie decomentat\n' +
                        '\n' +
                        '\n' +
                        'network.host: 0.0.0.0 # trebuie decomentat si schimbat din 192.168.0.1 in 0.0.0.0\n' +
                        '\n' +
                        'discovery.seed.hosts: ["127.0.0.1"] # trebuie decomentat si modificat\n' +
                        '\n' +
                        'xpack.security.enabled: false # trebuie schimbat in false\n' +
                        '\n' +
                        'cluster.initial_master_nodes: ["node-1"] # trebuie schimbat cu node-1' }
                    </SyntaxHighlighter>
                    Se salveaza (CTRL+S) si se iese din editor (CTRL+X).

                    <br/>
                    <br/>

                    3.4.2. Se editeaza fisierul <b>/lib/systemd/system/elasticsearch.service</b>.

                    <br/>
                    Rularea Elasticsearch poate fi inceata pe laptop, deci trebuie marita timpul implicit pentru <i>Elasticsearch start operation</i>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nano /lib/systemd/system/elasticsearch.service'}
                    </SyntaxHighlighter>

                    Se modifica urmatoarea linie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'TimeoutStartSec=600'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        3.4. Drepturi vizualizare log-uri
                    </b>

                    Se da drepturi pentru a citi log-uri:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo chmod 755 -R /var/log/elasticsearch/'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        3.5. Configurare pornire Elasticsearch automata la pornirea sistemului:
                    </b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl daemon-reload'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl enable elasticsearch.service'}
                    </SyntaxHighlighter>

                    Pornirea Elasticsearch:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl start elasticsearch.service'}
                    </SyntaxHighlighter>

                    Status Elasticsearch:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl status elasticsearch.service'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Instalare cURL pe Ubuntu</b>:
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get install curl'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Teste</b>:
                    <br/>
                    <br/>

                    <b>
                        5.1. Verificare ca Elasticsearch functioneaza:
                    </b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET 127.0.0.1:9200'}
                    </SyntaxHighlighter>

                    <b>
                        5.1. Testare Elasticsearch:
                    </b>
                    <br/>

                    # Download mapping for index
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo wget http://media.sundog-soft.com/es8/shakes-mapping.json'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -H "Content-Type: application/json" -XPUT 127.0.0.1:9200/shakespeare --data-binary @shakes-mapping.json'}
                    </SyntaxHighlighter>

                    # Download shakespeare data
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo wget http://media.sundog-soft.com/es8/shakespeare_8.0.json'}
                    </SyntaxHighlighter>

                    # Index data Elasticsearch
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -H "Content-Type: application/json" -XPUT \'127.0.0.1:9200/shakespeare/_bulk\' --data-binary @shakespeare_8.0.json'}
                    </SyntaxHighlighter>

                    # Cautare

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'  curl -H "Content-Type: application/json" -XGET \'127.0.0.1:9200/shakespeare/_search?pretty\' -d \'\n' +
                            '                    {\n' +
                            '                        "query" : {\n' +
                            '                        "match_phrase" : {\n' +
                            '                        "text_entry" : "to be or not to be"\n' +
                            '                    }\n' +
                            '                    }\n' +
                            '                    }\''}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VirtualBoxContent;