import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"nas-index", url:"#", title:"NAS", subtitle:""},

        // {id:"nas-intro", url:"/nas/intro", title:"Introducere cu NAS", subtitle:"", parent:"nas-index"},

        {id:"nas-issues", url:"/nas/issues", title:"Gasirea NAS-ului. Configurare antivirus Nod32", subtitle:"", parent:"nas-index"},

        {id:"nas-ssh", url:"/nas/ssh", title:"Conectare la NAS prin SSH", subtitle:"", parent:"nas-index"},

        {id:"nas-ssh-vm", url:"/nas/ssh-vm", title:"Conectare la masina virtuala Ubuntu instalata pe NAS prin SSH", subtitle:"", parent:"nas-index"},

        {id:"nas-restore-vmm", url:"/nas/restore-vmm", title:"Recuperare clusterul Virtual Machine Manager după resetarea Synology NAS", subtitle:"", parent:"nas-index"},

        {id:"nas-access-router", url:"/nas/access-router", title:"Accesare router Digi de la distanta", subtitle:"", parent:"nas-index"},

        {id:"nas-arp-protocol", url:"/nas/arp-protocol", title:"Protocolul ARP", subtitle:"", parent:"nas-index"},

        {id:"nas-router", url:"/nas/router", title:"Gasirea adresei rooter-ului", subtitle:"", parent:"nas-index"},

        {id:"nas-speed-test", url:"/nas/speed-test", title:"Masurare performata retea Synology NAS", subtitle:"", parent:"nas-index"},

        {id:"nas-synology-mail-server", url:"/nas/synology-mail-server", title:"Synology Mail Server", subtitle:"", parent:"nas-index"},

        {id:"nas-wan-lan-isolate", url:"/nas/wan-lan-isolate", title:"Izolare in retea LAN de WAN", subtitle:"", parent:"nas-index"},

        {id:"nas-port-forwarding", url:"/nas/port-forwarding", title:"Port Forwarding", subtitle:"", parent:"nas-index"},

        {id:"nas-git-server", url:"/nas/git-server", title:"Git Server", subtitle:"", parent:"nas-index"},

        {id:"nas-nano", url:"/nas/nano", title:"Instalare nano Synlogoy NAS", subtitle:"", parent:"nas-index"},

        {id:"nas-git-server-ssh-access", url:"/nas/git-server-ssh-access", title:"Git Server. Access SSH fara parola", subtitle:"", parent:"nas-index"},

        {id:"nas-namecheap", url:"/nas/namecheap", title:"Configurare domeniu cumparat de pe Namecheap cu Synlogoy NAS", subtitle:"", parent:"nas-index"},

        {id:"nas-postgresql-vm", url:"/nas/postgresql-vm", title:"PosgreSQL pe o masina virtuala dintr-un NAS", subtitle:"", parent:"nas-index"},

    ]

    static indexUrl = "/nas/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    NAS
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;