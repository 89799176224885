import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WordEmbeddingLlmContent extends BaseContentPage  {

    constructor(props) {
        super(props, "llm-word-embedding", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Word Embedding (incorporarea cuvintelor)</b>
                    <br/>
                    <br/>

                    Modelele de rețele neuronale profunde, inclusiv LLM-urile, nu pot procesa direct textul brut (sau video, sau audio).
                    <br/>
                    Prin urmare, cuvintele unui text sunt transformate intr-un vector cu valori continue.
                    <br/>
                    Acesta conversie se numeste <b>embedding (incorporare)</b> si este realizata folosind un strat de rețea neuronală sau un alt model de rețea neuronală preantrenată.

                    <br/>
                    <br/>
                    <b>Observatie</b>: diferite formate de date necesită modele de încorporare distincte!
                    <br/>
                    <br/>

                    Există mai mulți algoritmi pentru a genera încorporare de cuvinte:
                    <ul>
                        <li>
                            Word2Vec
                        </li>
                        <li>
                            LLM-urile produc în mod obișnuit propriile încorporare care fac parte din stratul de intrare și sunt actualizate în timpul antrenamentului
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Tokenizarea textului - drumul spre tokeni</b>
                    <br/>
                    <br/>

                    Acest proces presupune împărțirea textului de intrare în simboluri individuale (cuvinte sau caractere speciale - cum ar fi semne de punctuatie).

                    <br/>
                    <br/>
                    Vom incepe cu niste excercitii/teste pe un o fraza:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import re\n' +
                            '\n' +
                            'text = "Acesta\\n, este un test."\n' +
                            '\n' +
                            'result = re.split(r\'([,.]|\\s)\', text)\n' +
                            '\n' +
                            'print(result)\n' +
                            '# [\'Acesta\', \'\\n\', \'\', \',\', \'\', \' \', \'este\', \' \', \'un\', \' \', \'test\', \'.\', \'\']\n' +
                            '\n' +
                            'result = [item for item in result if item.strip()]\n' +
                            '# \'item\' din \'result\' este adaugat la \'final_result\' daca conditia if este evaluata la True.\n' +
                            '# Un string gol este evaluat False\n' +
                            '\n' +
                            'print(result)\n' +
                            '# [\'Acesta\', \',\', \'este\', \'un\', \'test\', \'.\']'}
                    </SyntaxHighlighter>

                    Fie fisierul <i>the-verdict.txt</i> se poate downloada de aici: https://github.com/rasbt/LLMs-from-scratch/blob/main/ch02/01_main-chapter-code/the-verdict.txt.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import re\n' +
                            '\n' +
                            'with open("the-verdict.txt", "r", encoding="utf-8") as f:\n' +
                            '    raw_text = f.read()\n' +
                            '   \n' +
                            'print("Numarul total de caractere:", len(raw_text))\n' +
                            '# Numarul total de caractere: 20479\n' +
                            'print(raw_text[:99])\n' +
                            '# I HAD always thought Jack Gisburn rather a cheap genius--though a good fellow enough--so it was no\n' +
                            '\n' +
                            'preprocessed = re.split(r\'([,.?_!"()\\\']|--|\\\s)\', raw_text)\n'+
                            'preprocessed = [item for item in preprocessed if item.strip()]\n'+
                            '\n'+
                            'print(preprocessed[:30])\n'+
                            '# [\'I\', \'HAD\', \'always\', \'thought\', \'Jack\', \'Gisburn\', \'rather\', \'a\', \'cheap\', \'genius\', \'--\', \'though\', \'a\', \'good\', \'fellow\', \'enough\', \'--\', \'so\', \'it\', \'was\', \'no\', \'great\', \'surprise\', \'to\', \'me\', \'to\', \'hear\', \'that\', \',\', \'in\']\n'+
                            '\n'+
                            'print("Numarul total de tokens:", len(preprocessed))\n'+
                            '# Numarul total de tokens: 4649'}
                    </SyntaxHighlighter>
                    Deci avem:
                    <ul>
                        <li>
                            Numarul total de caractere: 20479
                        </li>
                        <li>
                            Numarul total de tokens: 4649
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Conversia de la tokeni la ID-uri de token</b>
                    <br/>
                    <br/>

                    In capitolul precedent am strans intr-o lista o gramada de tokeni (in exemplul de mai sus 4649).
                    <br/>
                    Acum, vom construi pe baza acestor tokeni un dictionar, care va contine toti tockeni luati in mod unic.

                    <br/>

                    Construim o lista cu toti tokeni luati in mod unic:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'all_words = sorted(list(set(preprocessed)))\n' +
                            'vocab_size = len(all_words)\n' +
                            '\n' +
                            'print("Dimensiune vocabular:",vocab_size)\n' +
                            '' +
                            '# Dimensiune vocabular: 1159'}
                    </SyntaxHighlighter>

                    Construim dictionarul ((token, id), de exemplu: ('Begin', 20)):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'vocab = {token:integer for integer,token in enumerate(all_words)}'}
                    </SyntaxHighlighter>

                    Clasa SimpleTokenizerV1:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'import re\n' +
                            'class SimpleTokenizerV1:\n' +
                            '    def __init__(self, vocab):\n' +
                            '        self.str_to_int = vocab\n' +
                            '        self.int_to_str = {i:s for s,i in vocab.items()}\n' +
                            '    \n' +
                            '    def encode(self, text):\n' +
                            '        preprocessed = re.split(r\'([,.?_!"()\\\']|--|\\s)\', text)\n'+
                            '        preprocessed = [item.strip() for item in preprocessed if item.strip()]\n'+
                            '        ids = [self.str_to_int[s] for s in preprocessed]\n'+
                            '        return ids\n'+
                            '        \n'+
                            '    def decode(self, ids):\n'+
                            '        text = " ".join([self.int_to_str[i] for i in ids]) \n'+
                            '        \n'+
                            '        text = re.sub(r\'\\s+([,.?!"()\\\'])\', r\'\\1\', text)\n'+
                            '        return text'}
                    </SyntaxHighlighter>

                    Functia <b>get_vocab</b>:
                    <ul>
                        <li>
                            contruieste vocabularul:
                            <ul>
                                <li>se elimina tokeni/jetoanele duplicate ( <b>set(preprocessed)</b>)</li>
                                <li>se sorteaza lista rezultata in urma eliminarii jetoanelor duplicate</li>
                                <li>se construieste vocabularul

                                    <SyntaxHighlighter>
                                        {'vocab = {token: integer for integer, token in enumerate(all_words)}'}
                                    </SyntaxHighlighter>

                                    exemplu (mapa) [token:index]:
                                    <SyntaxHighlighter>
                                        {'vocab = {token: index for index, token in enumerate(["Ana", "are", "mare"])}\n' +
                                            'print(vocab)\n' +
                                            '# {\'Ana\': 0, \'are\': 1, \'mare\': 2}'}
                                    </SyntaxHighlighter>

                                    exemplu (mapa) [index:token]:
                                    <SyntaxHighlighter>
                                        {'vocab = {index: token for index, token in enumerate(["Ana", "are", "mare"])}\n' +
                                            'print(vocab)\n' +
                                            '{0: \'Ana\', 1: \'are\', 2: \'mare\'}'}
                                    </SyntaxHighlighter>

                                    exemplu (lista):
                                    <SyntaxHighlighter>
                                        {'x = [value for index, value in enumerate(["Ana", "are", "mare"])]\n' +
                                            'print(x)\n' +
                                            '# [\'Ana\', \'are\', \'mare\']'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    Variabila vocab va putea arata:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '\'!\': 0,\n ' +
                            '\'"\': 1,\n' +
                            '"\'": 2,\n' +
                            '\'(\': 3,\n' +
                            '\')\': 4,\n' +
                            '\',\': 5,\n' +
                            '\'--\': 6,\n' +
                            '\'.\': 7,\n' +
                            '\':\': 8,\n' +
                            '\';\': 9,\n' +
                            '\'?\': 10,\n' +
                            '\'A\': 11,\n' +
                            '\'Ah\': 12,\n' +
                            '\'Among\': 13,\n' +
                            '\'And\': 14,\n' +
                            '\'Are\': 15,\n' +
                            '...\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Testare:
                    <SyntaxHighlighter>
                        {'import re\n' +
                            '\n' +
                            'from SimpleTokenizerV1 import SimpleTokenizerV1\n' +
                            '\n' +
                            '\n' +
                            'def get_vocab():\n' +
                            '    with open("the-verdict.txt", "r", encoding="utf-8") as f:\n' +
                            '        raw_text = f.read()\n' +
                            '\n' +
                            '    preprocessed = re.split(r\'([,.?_!"()\\\']|--|\\\s)\', raw_text)\n'+
                            '    preprocessed = [item for item in preprocessed if item.strip()]\n'+
                            '\n'+
                            '    all_words = sorted(list(set(preprocessed)))\n'+
                            '    # vocab_size = len(all_words)\n'+
                            '\n'+
                            '    vocab = {token: integer for integer, token in enumerate(all_words)}\n'+
                            '\n'+
                            '    return vocab\n'+
                            '\n'+
                            '\n'+
                            't = SimpleTokenizerV1(get_vocab())\n'+
                            '\n'+
                            'print(t.encode(\'The man is dark\'))\n'+
                            '# [96, 671, 595, 320]\n'+
                            'print(t.decode([96, 671, 595, 320]))\n'+
                            '# The man is dark\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Adaugarea de tokeni speciali (simboluri speciale de context)</b>
                    <br/>
                    <br/>

                    Tokeni speciali:
                    <ul>
                        <li>pot exista tokeni care nu se regasesc in datele de antrenare (cuvant necunoscut)
                            <SyntaxHighlighter>
                                {'<|unk|>'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            delimitare documente
                            <SyntaxHighlighter>
                                {'<|endoftext|>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Aceste simboluri speciale îmbunătatesc înțelegerea modelului.
                    <hr/>
                    Putem extinde vocabularul:
                    <SyntaxHighlighter>
                        {'all_tokens = sorted(list(set(preprocessed)))\n' +
                            'all_tokens.extend(["<|endoftext|>", "<|unk|>"])\n' +
                            'vocab = {token:integer for integer,token in enumerate(all_tokens)}\n' +
                            ' \n' +
                            'print(len(vocab.items()))'}
                    </SyntaxHighlighter>

                    SimpleTokenizerV2:
                    <SyntaxHighlighter>
                        {'import re\n' +
                            '\n' +
                            '\n' +
                            'class SimpleTokenizerV2:\n' +
                            '    def __init__(self, vocab):\n' +
                            '        self.str_to_int = vocab\n' +
                            '        self.int_to_str = { i:s for s,i in vocab.items()}\n' +
                            '\n' +
                            '    def encode(self, text):\n' +
                            '        preprocessed = re.split(r\'([,.?_!"()\\\']|--|\\s)\', text)\n'+
                            '        preprocessed = [item.strip() for item in preprocessed if item.strip()]\n'+
                            '        preprocessed = [item if item in self.str_to_int\n'+
                            '                        else "<|unk|>" for item in preprocessed]\n'+
                            '\n'+
                            '        ids = [self.str_to_int[s] for s in preprocessed]\n'+
                            '        return ids\n'+
                            '\n'+
                            '    def decode(self, ids):\n'+
                            '        text = " ".join([self.int_to_str[i] for i in ids])\n'+
                            '\n'+
                            '        text = re.sub(r\'\\s+([,.?!"()\\\'])\', r\'\\1\', text)\n'+
                            '        return text'}
                    </SyntaxHighlighter>
                    În comparație cu SimpleTokenizerV1  noul SimpleTokenizerV2 înlocuiește cuvintele necunoscute cu simboluri  |unk| .

                    <hr/>
                    Testare:
                    <SyntaxHighlighter>
                        {'import re\n' +
                            '\n' +
                            'from SimpleTokenizerV2 import SimpleTokenizerV2\n' +
                            '\n' +
                            '\n' +
                            'def get_vocab():\n' +
                            '    with open("the-verdict.txt", "r", encoding="utf-8") as f:\n' +
                            '        raw_text = f.read()\n' +
                            '\n' +
                            '    preprocessed = re.split(r\'([,.?_!"()\\\']|--|\\\s)\', raw_text)\n'+
                            '    preprocessed = [item for item in preprocessed if item.strip()]\n'+
                            '\n'+
                            '    all_words = sorted(list(set(preprocessed)))\n'+
                            '    all_words.extend(["<|endoftext|>", "<|unk|>"]) ## adaugat\n'+
                            '    # vocab_size = len(all_words)\n'+
                            '\n'+
                            '    vocab = {token: integer for integer, token in enumerate(all_words)}\n'+
                            '    # print(vocab);\n'+
                            '\n'+
                            '    return vocab\n'+
                            '\n'+
                            '\n'+
                            'text1 = "Hello, do you like tea?"\n'+
                            'text2 = "In the sunlit terraces of the palace."\n'+
                            'text = " <|endoftext|> ".join((text1, text2))\n'+
                            'print(text)\n'+
                            '\n'+
                            't = SimpleTokenizerV2(get_vocab())\n'+
                            '\n'+
                            'print(t.encode(text))\n'+
                            '# [1160, 5, 362, 1155, 642, 1000, 10, 1159, 57, 1013, 981, 1009, 738, 1013, 1160, 7]\n'+
                            '\n'+
                            'print(t.decode(t.encode(text)))\n'+
                            '# <|unk|>, do you like tea? <|endoftext|> In the sunlit terraces of the <|unk|>.'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Codificarea perechilor de 2,5 octeți - Tokenizatorul BPE</b>
                    <br/>
                    <br/>

                    Tokenizatorul BPE (byte pair encoding) a fost folosit pentru a antrena LLM-uri precum GPT-2, GPT-3 și ChatGPT.
                    <br/>
                    Pentru ca implementarea este mai complicata, se va folosi <b>tiktoken</b> (https://github.com/openai/tiktoken).
                    <br/>
                    Instalare:
                    <SyntaxHighlighter>
                        {'pip install tiktoken'}
                    </SyntaxHighlighter>

                    Import:
                    <SyntaxHighlighter>
                        {
                            'import tiktoken'
                        }
                    </SyntaxHighlighter>

                    Instantiere:
                    <SyntaxHighlighter>
                        {'tokenizer = tiktoken.get_encoding("gpt2")'}
                    </SyntaxHighlighter>

                    Testare (se foloseste similar cu <i>SimpleTokenizerV2</i>)::
                    <SyntaxHighlighter>
                        {'import tiktoken\n' +
                            '\n' +
                            'tokenizer = tiktoken.get_encoding("gpt2")\n' +
                            '\n' +
                            'text = "Hello, do you like tea? <|endoftext|> In the sunlit terraces of someunknownPlace."\n' +
                            'integers = tokenizer.encode(text, allowed_special={"<|endoftext|>"})\n' +
                            'print(integers)\n' +
                            '# [15496, 11, 466, 345, 588, 8887, 30, 220, 50256, 554, 262, 4252, 18250, 8812, 2114, 286, 617, 34680, 27271, 13]\n' +
                            '\n' +
                            'strings = tokenizer.decode(integers)\n' +
                            'print(strings)\n' +
                            '# Hello, do you like tea? <|endoftext|> In the sunlit terraces of someunknownPlace.\n'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>
                            Jetonul |endoftext| i se atribuie un ID token relativ mare, și anume 50256.
                            <br/>
                            Tokenizer-ul BPE care a fost folosit pentru a antrena modele precum GPT-2, GPT-3 și ChatGPT are o dimensiune totală a vocabularului de 50.257.
                        </li>
                        <li>
                            tokenizer-ul BPE de mai sus codifică și decodifică corect cuvinte necunoscute, cum ar fi „someunknownPlace”
                            <br/>
                            Tokenizatorul BPE poate gestiona orice cuvânt necunoscut. Cum realizează acest lucru fără a folosi |unk|?
                            <br/>
                            Algoritmul care stă la baza BPE descompune cuvintele care nu sunt în vocabularul său predefinit în unități mai mici de subcuvinte sau chiar caractere individuale, permițându-i să gestioneze cuvintele în afara vocabularului.
                            <br/>
                            Dacă tokenizatorul întâlnește un cuvânt necunoscut în timpul tokenizării, îl poate reprezenta ca o secvență de simboluri sau caractere subcuvinte.
                            <br/>
                            Deci, poate procesa orice text, chiar dacă acesta conține cuvinte care nu au fost prezente în datele sale de antrenament.
                            <br/>
                            BPE își construiește vocabularul prin îmbinarea iterativă a caracterelor frecvente în subcuvinte și a subcuvintelor frecvente în cuvinte.
                        </li>
                    </ul>

                    <hr/>
                    <b>5. șantionarea datelor cu o fereastră glisantă (Data sampling with a sliding window)</b>
                    <br/>
                    <br/>

                    Sarcina de predicție a LLM în timpul antrenamentului este de a prezice următorul cuvânt care urmează blocului de intrare.
                    <br/>
                    Se va implementa un incarcator de date, care preia perechile intrare-tinta (din setul de date de antrenament) folosind o abordare cu fereastră glisante.
                    <br/>
                    Exemplu:
                    <br/>
                    Daca avem textul: "Ana are mere si pere", vom aveam perechile (intare -> tinta):
                    <SyntaxHighlighter>
                        {'(Ana -> are)\n' +
                            '(Ana are -> mere)\n' +
                            '(Ana are mere -> si)\n' +
                            '(Ana are mere si -> pere)'}
                    </SyntaxHighlighter>
                    Unul dintre cele mai simple moduri de a crea perechile de intrare-țintă pentru sarcina de predicție a cuvântului următor este de a crea două variabile x și y, unde:
                    <ul>
                        <li>
                            x conține jetoanele de intrare
                        </li>
                        <li>
                            y conține țintele, care sunt intrările deplasate cu 1
                        </li>
                    </ul>
                    <SyntaxHighlighter>
                        {'context_size = 4\n' +
                            '\n' +
                            'enc_sample = ["Ana", "are", "mere", "si", "pere", "."]\n' +
                            '\n' +
                            'x = enc_sample[:context_size]\n' +
                            'y = enc_sample[1:context_size+1]\n' +
                            'print(f"x: {x}")\n' +
                            'print(f"y:        {y}")\n' +
                            '\n' +
                            '# x: [\'Ana\', \'are\', \'mere\', \'pere\']\n' +
                            '# y:        [\'are\', \'mere\', \'pere\']\n' +
                            '\n' +
                            'for i in range(1, min(len(enc_sample), context_size+1)):\n' +
                            '    context = enc_sample[:i] # toate elementele de la 0 la i\n' +
                            '    desired = enc_sample[i] # elementul de la pozitia i\n' +
                            '    print(context, "---->", desired)\n' +
                            '    \n' +
                            '# min(len(enc_sample), context_size+1) - daca enc_sample<=context_size\n' +
                            '\n' +
                            '# [\'Ana\'] ----> are\n' +
                            '# [\'Ana\', \'are\'] ----> mere\n' +
                            '# [\'Ana\', \'are\', \'mere\'] ----> si\n' +
                            '# [\'Ana\', \'are\', \'mere\', \'si\'] ----> pere\n'}
                    </SyntaxHighlighter>

                    GPTDatasetV1:
                    <SyntaxHighlighter>
                        {'import torch\n' +
                            'from torch.utils.data import Dataset, DataLoader\n' +
                            '\n' +
                            'class GPTDatasetV1(Dataset):\n' +
                            '    def __init__(self, txt, tokenizer, max_length, stride):\n' +
                            '        self.tokenizer = tokenizer\n' +
                            '        self.input_ids = []\n' +
                            '        self.target_ids = []\n' +
                            '\n' +
                            '        token_ids = tokenizer.encode(txt)\n' +
                            '\n' +
                            '        for i in range(0, len(token_ids) - max_length, stride):\n' +
                            '            input_chunk = token_ids[i:i + max_length]\n' +
                            '            target_chunk = token_ids[i + 1: i + max_length + 1]\n' +
                            '            self.input_ids.append(torch.tensor(input_chunk))\n' +
                            '            self.target_ids.append(torch.tensor(target_chunk))\n' +
                            '\n' +
                            '    def __len__(self):\n' +
                            '        return len(self.input_ids)\n' +
                            '\n' +
                            '    def __getitem__(self, idx):\n' +
                            '        return self.input_ids[idx], self.target_ids[idx]'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           Build a Large Language Model (From Scratch) - Sebastian Raschka, Manning, ISBN 9781633437166
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WordEmbeddingLlmContent;