import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NamespaceK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-namespace", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Namespaces</b>
                    <br/>
                    <br/>


                    Listare namespaces:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get namespaces'}
                    </SyntaxHighlighter>

                    Poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                   STATUS   AGE\n' +
                            'default                Active   51d\n' +
                            'ingress-nginx          Active   51d\n' +
                            'kube-node-lease        Active   51d\n' +
                            'kube-public            Active   51d\n' +
                            'kube-system            Active   51d\n' +
                            'kubernetes-dashboard   Active   51d\n'}
                    </SyntaxHighlighter>

                    Creare namespaces:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create namespace kj-ns'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'namespace/kj-ns created'}
                    </SyntaxHighlighter>

                    La aplicarea unui fisier de configurari se poate seta namespace-ul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply [nume-fisier.yaml] -f -na kj-ns'}
                    </SyntaxHighlighter>

                    In mod implicit, se afiseaza doar pod-uri din namespace-ul <i>default</i>; pentru a afisa toate podurile, indiferent de namespace se foloste optiunea <b>--all-namespaces</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --all-namespaces'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NamespaceK8sContent;