import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class BranchGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-branch", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Afisare branch-uri</b>
                    <br/>
                    <br/>

                    In mod implicit, exista un branch de baza (sau principal), conventional numit <b>master</b>.
                    Pornind de la un branch se pot creea alte branch-uri. Iar branch-ul nou creat isi continua viata independent.

                    <br/>
                    <br/>
                    Pentru a afisa lista de branch-uri si branch-ul curent:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch'}
                    </SyntaxHighlighter>
                    Rezultatul comenzii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'* master'}
                    </SyntaxHighlighter>

                    Clonarea este procesul de creare a unei copii locale a unui repository dintr-o sursa diferita.
                    Daca se seteaza o locatie remote, acestea pot fi si acestea afisate, adaugand flag-ul <b>-a</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch -a'}
                    </SyntaxHighlighter>

                    Rezultatul comenzii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'* master\n' +
                        '  remotes/origin/master'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Creare branch-uri</b>
                    <br/>
                    <br/>

                    Pentru a creea un nou branch si a ramane in branch-ul curent: <b>git branch <i>nume_branch</i></b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch nume_branch'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'>git branch test_branch_1\n' +
                        '\n' +
                        '>git branch\n' +
                        '* master\n' +
                        '  test_branch_1'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Schimbare branch</b>
                    <br/>
                    <br/>

                    Pentru a face alt branch sa fie activ se foloseste comanda: <b>git checkout <i>nume_branch</i></b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout nume_branch'}
                    </SyntaxHighlighter>

                    sau (comanda mai clara):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git switch nume_branch'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'>git checkout test_branch_1\n' +
                        'Switched to branch \'test_branch_1\'\n' +
                        '\n' +
                        '>git branch\n' +
                        '  master\n' +
                        '* test_branch_1'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>4. Creare si schimbare branch intr-o singura comanda</b>
                    <br/>
                    <br/>

                    Pentru a creea un nou branch si a face checkout intr-o singura comanda: <b>git checkout -b <i>nume_branch</i></b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout -b nume_branch'}
                    </SyntaxHighlighter>

                    sau (comanda mai clara):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git switch -c nume_branch'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'>git checkout -b test_branch_2\n' +
                        'Switched to a new branch \'test_branch_2\'\n' +
                        '\n' +
                        '>git branch\n' +
                        '  master\n' +
                        '  test_branch_1\n' +
                        '* test_branch_2'}
                    </SyntaxHighlighter>

                    <hr/>

                    Un branch este creat pe baza:
                    <ul>
                        <li>ultimului comit</li>
                        <li>branch-ului activ</li>
                    </ul>
                    Dar, se poate creea un nou branch, pornit de la un anumit comit specificand <i>hash-ul</i> sau identificatorul comitului: <b>git checkout -b <i>nume_branch</i> <i>identificator_commit</i></b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout -b nume_branch identificator_commit'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git log --oneline\n' +
                        '72f5099 (HEAD -> test_branch_2, origin/master, test_branch_1, master) Al doilea commit\n' +
                        '14055f8 Primul commit\n' +
                        '\n' +
                        '>git checkout -b test_branch_3 14055f8\n' +
                        'Switched to a new branch \'test_branch_3\'\n' +
                        '\n' +
                        '>git log --oneline\n' +
                        '14055f8 (HEAD -> test_branch_3) Primul commit'}
                    </SyntaxHighlighter>

                    Prima comanda afiseaza comiturile de pe branch-ul curent.
                    <br/>
                    A doua comanda creaza un nou branch, pornind de la comitul cu identificatorul <i>14055f8</i> (si nu de la ultimul comit), si face noul branch activ.
                    <br/>
                    A treia comanda, afiseaza comiturile de pe branch-ul curent (<i>test_branch_3</i>).

                    <hr/>
                    <b>HEAD</b> este un pointer catre ultimul comit a unui branch.

                    <hr/>
                    <b>5. Redenumire branch-uri</b>
                    <br/>
                    <br/>

                    Pentru a redenumi branch-ul curent se poate folosi comanda: <b>git branch -m <i>nume_branch_nou</i></b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch -m nume_branch_nou'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch -m test_branch_3a\n' +
                        '\n' +
                        '>git log --oneline\n' +
                        '14055f8 (HEAD -> test_branch_3a) Primul commit'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Stergere branch-uri</b>
                    <br/>
                    <br/>

                    Stergea unui branch poate fi efecuata folosind:
                    <ul>
                        <li>git branch <b>-d</b> <i>nume_branch</i>: se afiseaza un avertisment, daca exista isiere urmarite si modificate; nu sunt sinronizate cu branch-ul remote</li>
                        <li>git branch <b>-D</b> <i>nume_branch</i>: pentru a forta stergerea (se sterge branch-ul, chiar daca sunt fisiere urmarite si modificate; nu sunt sinronizate cu branch-ul remote)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch -d nume_branch'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Merge branch-uri</b>
                    <br/>
                    <br/>

                    Pentru a face merge, trebuie urmati 2 pasi:
                    <ul>
                        <li>sa fim pe branch-ul pe care se vrea aducerea modificarilor (de exemplu branch-ul <i>master</i>: git checkout master)</li>
                        <li>sa ruleze comanda: git merge <i>branch_sursa</i> </li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout master\n' +
                        'git merge branch_sursa'}
                    </SyntaxHighlighter>

                    Exista 2 tipuri de merge-uri:
                    <ul>
                        <li><b>fast-forward</b> (implicit)
                            <ul>
                                <li>se iau comiturile de pe branch-ul sursa (<i>feature branch</i>) si se pun in "varful" branch-ului destinatie (de obicei <i>master branch</i>);</li>
                                <li> acest lucru creează un istoric liniar;</li>
                                <li>dar lucru asta este posibil daca nu au fost efectuate modificări noi pe branch-ul principal (<i>master branch</i>) de când <i>feature branch</i> a fost generată;</li>
                            </ul>
                        </li>
                        <li><b>no-fast-forward</b> (folosind optiunea <b>--no-ff</b>):
                            <ul>
                                <li>se va crea <b>un commit de îmbinare (merge)/o singura unitate de comit (din comiturile de pe <i>feature branch</i>)</b> si se va pune in "vârful" branch-ului destinatie (de obicei <i>master</i>)</li>
                                <li>este de multe ori recomandat asa</li>
                                <li>ține evidența ramurilor mai explicit</li>
                            </ul>

                        </li>
                    </ul>

                    Exemplu:
                    (se creaza un nou branch din master - verificand branch-ul activ si schimband pe branch-ul <i>master</i> in caz ca nu e):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git branch\n' +
                        '  master\n' +
                        '  test_branch_1\n' +
                        '  test_branch_2\n' +
                        '* test_branch_3a\n' +
                        '\n' +
                        '>git checkout master\n' +
                        'Switched to branch \'master\'\n' +
                        '\n' +
                        '>git branch\n' +
                        '* master\n' +
                        '  test_branch_1\n' +
                        '  test_branch_2\n' +
                        '  test_branch_3a'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout -b functionalitate_noua\n' +
                        'Switched to a new branch \'functionalitate_noua\''}
                    </SyntaxHighlighter>

                    Cream un nou fisier si afisam continutul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo test_1  > fisier_1.txt\n' +
                        '\n' +
                        'type fisier_1.txt\n' +
                        'test_1'}
                    </SyntaxHighlighter>

                    Incepem urmarirea acestui fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git status\n' +
                        'On branch functionalitate_noua\n' +
                        'Untracked files:\n' +
                        '  (use "git add <file>..." to include in what will be committed)\n' +
                        '        fisier_1.txt\n' +
                        '\n' +
                        'nothing added to commit but untracked files present (use "git add" to track)\n' +
                        '\n' +
                        'git add fisier_1.txt'}
                    </SyntaxHighlighter>

                    Se comite noul fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git commit -m "Adaugare fisier_1.txt"\n' +
                        '[functionalitate_noua 691c5e3] Adaugare fisier_1.txt\n' +
                        ' 1 file changed, 1 insertion(+)\n' +
                        ' create mode 100644 fisier_1.txt'}
                    </SyntaxHighlighter>

                    Facem merge cu branch-ul master (aducem modificarile de pe branch-ul <i>functionalitate_noua</i> pe <i>master</i>).
                    <br/>
                    Pentru acest lucru:
                    <ul>
                        <li>ne asiguram ca branch-ul activ este <i>master</i>: git checkout master</li>
                        <li>executam comanda de merge: git merge functionalitate_noua</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout master\n' +
                        'Switched to branch \'master\'\n' +
                        '\n' +
                        '>git merge functionalitate_noua\n' +
                        'Updating 72f5099..691c5e3\n' +
                        'Fast-forward\n' +
                        ' fisier_1.txt | 1 +\n' +
                        ' 1 file changed, 1 insertion(+)\n' +
                        ' create mode 100644 fisier_1.txt'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://bluecast.tech/blog/git-switch-branch/"}>git switch branch – Easily checkout git branches with this new command</a>
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BranchGitContent;