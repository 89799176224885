import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InheritancePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-inheritance", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Mostenirea</b>
                    <br/>
                    <br/>

                    <b>Moștenirea</b> este o metoda de transmitere a <i>atributelor și metodelor</i> <b>din superclasă</b> la o clasă nou creată, numită <b>subclasă</b>.

                    Deci, se poate construi o nouă clasă:
                    <ul>
                        <li><b>nu de la zero</b>, ci prin utilizarea trăsăturilor deja definite (mostenite)</li>
                        <li>cu <b>noi</b> <i>atribute si metode</i> dacă este necesar</li>
                        <li><b>mai specializata (mai concreta)</b>, folosind unele seturi de reguli și comportamente generale predefinite (mostenite)</li>
                    </ul>

                    <hr/>

                    <b>1. Functia issubclass()</b>
                    <br/><br/>
                    Functia <b>issubclass(A,B)</b> verifica dacă o anumită clasă este o subclasă a oricărei alte clase (<b>relatia dintre cele 2 clase nu trebuie sa fie neaparat relatie directa</b>). Returneaza:
                    <ul>
                        <li>True, daca clasa B este sublcasa a lui A, cu A!=B</li>
                        <li>True, daca clasa A=B</li>
                        <li>False, daca clasa B nu este subclasa a lui A,  cu A!=B</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    pass\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    pass\n' +
                        '\n' +
                        'print(issubclass(A,B)) # False\n' +
                        'print(issubclass(B,A)) # True\n' +
                        'print(issubclass(A,A)) # True\n'}
                    </SyntaxHighlighter>
                    Observatie: fiecare clasă este considerată a fi o subclasă a ei însăși! (print(issubclass(A,A)) # True)

                    <hr/>

                    <b>2. Functia isinstance()</b>
                    <br/><br/>
                    Functia <b>isinstance(obiect,clasa)</b> verifica dacă un anumit obiect este de tipul unui clase. Returneaza:
                    <ul>
                        <li>True, dacă obiectul este o instanță a clasei (<b>sau a unui superclase a clasei</b>)</li>
                        <li>False, in caz contrar</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    pass\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    pass\n' +
                        '\n' +
                        'class C(B):\n' +
                        '    pass\n' +
                        '\n' +
                        'c = C()\n' +
                        'print(isinstance(c,C)) # True\n' +
                        'print(isinstance(c,B)) # True\n' +
                        'print(isinstance(c,A)) # True\n' +
                        '\n' +
                        'b = B()\n' +
                        'print(isinstance(b,C)) # False\n' +
                        'print(isinstance(b,B)) # True\n' +
                        'print(isinstance(b,A)) # True\n' +
                        '\n' +
                        'a = A()\n' +
                        'print(isinstance(a,C)) # False\n' +
                        'print(isinstance(a,B)) # False\n' +
                        'print(isinstance(a,A)) # True'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Operatorul is()</b>
                    <br/><br/>

                    Operatorul <b>is</b> verifică dacă două variabile se referă la același obiect.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    pass\n' +
                        '\n' +
                        '\n' +
                        'a1 = A()\n' +
                        'a2 = A()\n' +
                        'a3 = a1\n' +
                        '\n' +
                        'print(a1 is a1) # True\n' +
                        'print(a1 is a2) # False\n' +
                        'print(a1 is a3) # True\n'}
                    </SyntaxHighlighter>

                    Experiment 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a = "ab"\n' +
                        'b = "ab"\n' +
                        '\n' +
                        'print(a==b)   # True\n' +
                        'print(a is b) # True'}
                    </SyntaxHighlighter>

                    <b>Python poate optimiza resursele făcând ca două nume care se referă la aceeași valoare literală a șirului se referă la același obiect</b>

                    Experiment 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a = "ab"\n' +
                        'b = "a"\n' +
                        'b = b +"b"\n' +
                        '\n' +
                        'print(a==b)   # True\n' +
                        'print(a is b) # False'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Functia super()</b>
                    <br/><br/>

                    Functia <b>super()</b> permite sa accesam superclasa fără a fi nevoie să îi cunoaștem numele.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def __init__(self):\n' +
                        '        print("A")\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    def __init__(self):\n' +
                        '        super().__init__()  # echivalent cu: A.__init__(self)\n' +
                        '        print("B")\n' +
                        '\n' +
                        'b=B()'}
                    </SyntaxHighlighter>

                    Funcția <b>super()</b> creează un context în care <b>nu trebuie (mai mult, nu trebuie)</b> să transmiteți argumentul <i>self</i> metodei invocate.

                    <br/>

                    Funcția <b>super()</b> se poate folosi si in constructor si in metode.

                    <hr/>
                    <b>5. Metoda __str__()</b>
                    <br/>
                    <br/>
                    Cand incercam sa prezentam un obiect ca un sir de caractere, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Caine:\n' +
                        '    pass \n' +
                        '  \n' +
                        'c = Caine()\n' +
                        'print(c) # <__main__.Caine object at 0x7f701aade410>\n'}
                    </SyntaxHighlighter>
                    se va afisa un identificator intern al obiectului, pentru ca Python apeleaza implicit metoda <b>__str__()</b> , iar aceea metoda asa crede ea de cuviinta sa prezinte obiectul.
                    <br/>

                    Pentru a putea fi afisat mai inteligibil se poate suprascrie metoda <b>__str__</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Caine:\n' +
                        '   \n' +
                        '    def __str__(self):\n' +
                        '        return "catelus cu parul cret"\n' +
                        '        \n' +
                        'c = Caine()\n' +
                        'print(c) # catelus cu parul cret\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Accesare entitati</b>
                    <br/>
                    <br/>
                    Cand se incerca accesarea unei entitati (proprietate sau metoda) a unui obiect, Python va incerca sa o gaseasca de jos in sus:
                    <ul>
                        <li>cauta in obiectul insusi</li>
                        <li>cauta in clasele implicate in mostenire <b>de jos in sus</b></li>
                        <li>dacă există mai multe clase pe aceasi linie de mostenire (mostenira multipla), <b>se scanează de la stânga la dreapta</b></li>
                    </ul>
                    Daca nu poate accesa entitatea se arunca o exceptie de tipul <b>AttributeError</b>.

                    <hr/>
                    <b>7. Mostenire multipla</b>
                    <br/>
                    <br/>
                    O clasa poate mostenii mai multe clase:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Clasa(SuperClasa1, SuperClasa2,..., SuperClasaN):'}
                    </SyntaxHighlighter>

                    Exemplul 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A1:\n' +
                        '    x="x-a"\n' +
                        '    a=1\n' +
                        '    def metoda(self):\n' +
                        '        return "A1"\n' +
                        '\n' +
                        'class A2:\n' +
                        '    x="x-b"\n' +
                        '    b=2\n' +
                        '    def metoda(self):\n' +
                        '        return "A2"\n' +
                        '        \n' +
                        'class B(A1, A2):\n' +
                        '    pass\n' +
                        '\n' +
                        'class D(A2, A1):\n' +
                        '    pass\n' +
                        '\n' +
                        'b = B()\n' +
                        '\n' +
                        'print(b.x) # x-b\n' +
                        'print(b.a) # 1\n' +
                        'print(b.b) # 2\n' +
                        'print(b.metoda()) #A1\n' +
                        '\n' +
                        'd = D()\n' +
                        '\n' +
                        'print(d.x) # x-a\n' +
                        'print(d.a) # 1\n' +
                        'print(d.b) # 2\n' +
                        'print(d.metoda()) #A2'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def metoda(self):\n' +
                        '        print("A")\n' +
                        '        \n' +
                        'class B:\n' +
                        '    def metoda(self):\n' +
                        '        print("B")\n' +
                        '        \n' +
                        'class C(A,B):\n' +
                        '    def metoda(self):\n' +
                        '        super().metoda()\n' +
                        '\n' +
                        'class D(B,A):\n' +
                        '    def metoda(self):\n' +
                        '        super().metoda()\n' +
                        '        \n' +
                        'C().metoda() # A\n' +
                        'D().metoda() # B'}
                    </SyntaxHighlighter>

                    <b>Observatii:</b>
                    <ul>
                        <li>Mostenirea multipla ar trebui evitata! (încalcă principiul responsabilității unice / utilizarea funcției super() devine ambiguă)</li>
                    </ul>

                    <hr/>
                    <b>8. Polimorfism</b>
                    <br/>
                    <br/>
                    <b>Polimorfism</b> = cand subclasa poate modifica comportamentul superclasei; una și aceeași clasă poate lua diferite forme în funcție de redefinirile făcute de oricare dintre subclasele sale.
                    <br/>
                    <br/>
                    <b>Metoda virtuala</b>:
                    <ul>
                        <li>
                            =  metoda al cărei comportament este determinat de către definiția unei metode cu aceeași semnătură cea mai îndepărtată pe linia succesorală a obiectului în care este apelată.
                        </li>
                        <li>metodele virtuale permit claselor derivate sa inlocuiasca implementarea metodelor din clasa de baza</li>
                        <li>(În Java, toate metodele nestatice sunt implicite virtuale, cu excepția metodelor private sau marcate cu cuvântul cheie final.)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Imprimanta:\n' +
                        '    \n' +
                        '    def printeaza_frumos(self):\n' +
                        '        print("Printez frumos")\n' +
                        '        \n' +
                        '    def printeaza(self):\n' +
                        '        self.printeaza_frumos()\n' +
                        '        \n' +
                        'class ImprimantaWifi(Imprimanta):\n' +
                        '    \n' +
                        '    def printeaza_frumos(self):\n' +
                        '        print("Printez frumos, dar mai incet")\n' +
                        '        \n' +
                        '\n' +
                        'i1 = Imprimanta()\n' +
                        'i2 = ImprimantaWifi()\n' +
                        '\n' +
                        'i1.printeaza() # Printez frumos\n' +
                        'i2.printeaza() # Printez frumos, dar mai incet'}
                    </SyntaxHighlighter>

                    Metoda de mai sus <i>printeaza_frumos()</i> este metoda virtuala!

                    <br/>
                    <br/>
                    <b>Metoda abstracta</b>:
                    <ul>
                        <li>metoda goala, cu scopul de a fi implementata intr-o subclasa; in exemplul de mai jos, metoda <i>gata</i> poate fi considerata metoda abstracta:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'class Joc:\n' +
                                '    def gata(self):\n' +
                                '        pass'}
                            </SyntaxHighlighter>


                        </li>
                    </ul>

                    Exemplu de polimorfism:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Animal:\n' +
                        '    \n' +
                        '    def face_zgomot(self):\n' +
                        '        pass\n' +
                        '\n' +
                        '    def mananca(self):\n' +
                        '        print("hap hap")\n' +
                        '        \n' +
                        '    def vietuieste(self):\n' +
                        '        self.face_zgomot()\n' +
                        '        self.mananca()\n' +
                        '        \n' +
                        '\n' +
                        'class Caine(Animal):\n' +
                        '    \n' +
                        '    def face_zgomot(self):\n' +
                        '        print("ham ham")\n' +
                        '\n' +
                        'class Pisica(Animal):\n' +
                        '    \n' +
                        '    def face_zgomot(self):\n' +
                        '        print("miau miau")\n' +
                        '        \n' +
                        'c = Caine()\n' +
                        'p = Pisica()\n' +
                        '\n' +
                        'c.vietuieste() # ham ham / hap hap\n' +
                        'p.vietuieste() # miau miau / hap hap'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Compozitia</b>
                    <br/>
                    <br/>

                    <b>Compoziția</b> este procesul de compunere a unui obiect folosind alte obiecte.
                    Obiectele utilizate în compoziție oferă trăsăturile dorite (proprietăți și/sau metode), actionand ca niste parti/bucati care construiesc o structură mai complicată.
                    <br/>
                    Compozitia proiectează o clasă ca un container capabil să stocheze și să utilizeze alte obiecte, în care fiecare dintre obiecte implementează o parte din comportamentul clasei dorite.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class SunetDeCaine:\n' +
                        '    def face_zgomot(self):\n' +
                        '        print("ham ham")\n' +
                        '    \n' +
                        'class SunetDePisica:\n' +
                        '    def face_zgomot(self):\n' +
                        '        print("miau miau")\n' +
                        '    \n' +
                        '    \n' +
                        'class Caine:\n' +
                        '    \n' +
                        '    def __init__(self, sunet):\n' +
                        '        self.sunet = sunet\n' +
                        '        \n' +
                        '    def vietuieste(self):\n' +
                        '        self.sunet.face_zgomot()\n' +
                        '\n' +
                        'class Pisica:\n' +
                        '    \n' +
                        '    def __init__(self, sunet):\n' +
                        '        self.sunet = sunet\n' +
                        '        \n' +
                        '    def vietuieste(self):\n' +
                        '        self.sunet.face_zgomot()\n' +
                        '        \n' +
                        'c = Caine(SunetDeCaine())\n' +
                        'p = Pisica(SunetDePisica())\n' +
                        '\n' +
                        'c.vietuieste() # ham ham \n' +
                        'p.vietuieste() # miau miau'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InheritancePythonContent;