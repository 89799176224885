import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../music/IndexContent";
import {albumsDb} from "./album";

class VinylMusicContent extends BaseContentPage  {

    constructor(props) {
        super(props, "music-vinyl", IndexContent);

        this.state = {
            ...this.state,

            search:''
        };
    }

    static albums = albumsDb;

    handleSearch(event){
        let search = event.target.value;

        this.setState({search: search});
    }

    render() {

        this.state.filtredAlbums = VinylMusicContent.albums;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)} />
                    </label>
                    &nbsp;
                    <span>Numar total carti: {VinylMusicContent.albums.length}</span>
                </div>

                <hr/>

                <br/>
                <div className={"text-justify important"}>

                    {VinylMusicContent.albums
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a=>a.toLowerCase().includes(s));
                                let okTags= item.tags?.some(a=>a.toLowerCase().includes(s));
                                return okTitle || okPublisher || okAuthors || okTags;
                            }
                        )
                        .map(function(item, index){
                        return <div key={index} className="row">

                            <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index+1}</span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250} rowSpan={"7"}>
                                            <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}
                                                    src={process.env.PUBLIC_URL + '/img/music/'+item.img}/>
                                        </td>

                                        <td width={250}>
                                            <b>Titlu</b>
                                        </td>
                                        <td>
                                            <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>An</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Autori</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Numar discuri</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.discs}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Limba</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                    </tr>

                                    </tbody>
                                </table>

                            </div>

                        </div>;

                    })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VinylMusicContent;