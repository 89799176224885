import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class DHTArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-dht", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1.  Senzor temperatura si umiditate ( DHT 11) </b>
                    <br/>
                    <br/>

                    Intervale masurabile:
                    <ul>
                        <li>temperatura: intre [-40;80] grade; prezicie +-0.5 grade</li>
                        <li>umiditate: intre [0;100] RH; prezicie +-5 RH</li>
                    </ul>

                    DHT 11:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_30.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_32.png'}/>
                    </div>

                    Are 3 pini (de obicei in ordinea asta):
                    <ul>
                        <li>
                            S - pin, de exemplu 2 (pe arduino)
                        </li>
                        <li>
                            Vcc - 5V (pe arduino)
                        </li>
                        <li>
                            GND - GND (pe arduino)
                        </li>
                    </ul>

                    Schema:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_31.png'}/>
                    </div>

                    Instalare librarie <b>DHTlib</b> (https://github.com/RobTillaart/DHTlib)

                    <SyntaxHighlighter>
                        {'#include <dht.h>\n' +
                            '\n' +
                            '// pentru DHT11:\n' +
                            '//      VCC: 5V or 3V\n' +
                            '//      GND: GND\n' +
                            '//      DATA: 7\n' +
                            '\n' +
                            'int pinDHT = 7;\n' +
                            '\n' +
                            'dht DHT;\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  Serial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            ' \n' +
                            '\n' +
                            '  int chk = DHT.read11(pinDHT);\n' +
                            '\n' +
                            '  Serial.print("Temperatura = ");\n' +
                            '  Serial.println(DHT.temperature);\n' +
                            '  Serial.print("Umiditatea = ");\n' +
                            '  Serial.println(DHT.humidity);\n' +
                            '\n' +
                            '\n' +
                            '  // DHT11 sampling rate is 1HZ.\n' +
                            '  delay(1000);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alti senzori de temperatura si umiditate:
                    <ul>
                        <li>
                            DHT 22 sau AM2302
                        </li>
                        <li>
                            Termorezistor
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 150}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_34.png'}/>
                            </div>
                        </li>
                        <li>
                            TMP36
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_35.png'}/>
                            </div>
                            Are 3 pini:
                            <ul>
                                <li>
                                    Vcc
                                </li>
                                <li>
                                    Out
                                </li>
                                <li>
                                    GND
                                </li>
                            </ul>

                            <SyntaxHighlighter>
                                {'float V = 5;\n' +
                                    'void setup() {\n' +
                                    '  Serial.begin(9600);\n' +
                                    '\n' +
                                    '}\n' +
                                    '\n' +
                                    'void loop() {\n' +
                                    '  int valoare = analogRead(A0);\n' +
                                    '  float voltaj = valoare * V / 1024;\n' +
                                    '  float temperatura = (voltaj - 0.5) * 100;\n' +
                                    '\n' +
                                    '  Serial.println(valoare);\n' +
                                    '  Serial.println(voltaj);\n' +
                                    '  Serial.println(temperatura);\n' +
                                    '\n' +
                                    '  delay(1000);\n' +
                                    '}\n'}
                            </SyntaxHighlighter>

                            sau:
                            <SyntaxHighlighter>
                                {'int sensePin = A0;  //This is the Arduino Pin that will read the sensor output\n' +
                                    'int sensorInput;    //The variable we will use to store the sensor input\n' +
                                    'double temp;        //The variable we will use to store temperature in degrees.\n' +
                                    '\n' +
                                    'void setup() {\n' +
                                    '  // put your setup code here, to run once:\n' +
                                    '  Serial.begin(9600);  //Start the Serial Port at 9600 baud (default)\n' +
                                    '}\n' +
                                    'void loop() {\n' +
                                    '  // put your main code here, to run repeatedly:\n' +
                                    '  sensorInput = analogRead(A0);       //read the analog sensor and store it\n' +
                                    '  temp = (double)sensorInput / 1024;  //find percentage of input reading\n' +
                                    '  temp = temp * 5;                    //multiply by 5V to get voltage\n' +
                                    '  temp = temp - 0.5;                  //Subtract the offset\n' +
                                    '  temp = temp * 100;                  //Convert to degrees\n' +
                                    '\n' +
                                    '  Serial.print("Current Temperature: ");\n' +
                                    '  Serial.println(temp);\n' +
                                    '\n' +
                                    '  delay(2000);\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            MCP 9808 (acuratete +-0.5 grade)
                        </li>
                        <li>
                            MCP 9700AE (TO 3259TT)

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_46.png'}/>
                            </div>

                            Are 3 pini:
                            <ul>
                                <li>
                                    Vdd/Vcc
                                </li>
                                <li>
                                    Vout
                                </li>
                                <li>
                                    GND
                                </li>
                            </ul>

                            <SyntaxHighlighter>
                                {'#define sensorPin A0\n' +
                                    '\n' +
                                    'void setup() {\n' +
                                    '  Serial.begin(9600); \n' +
                                    '}\n' +
                                    '\n' +
                                    'void loop() {\n' +
                                    '  int sensorValue = analogRead(sensorPin);\n' +
                                    '\n' +
                                    '  float voltage =  (sensorValue/1024.0) * 5.0;\n' +
                                    '  float temperature = (voltage - 0.5) * 100;\n' +
                                    '  //Serial.println(sensorValue);\n' +
                                    '  //Serial.println(voltage);\n' +
                                    '  Serial.print("Temperatura = ");\n' +
                                    '  Serial.print(temperature);\n' +
                                    '  Serial.println(" grade");\n' +
                                    '  \n' +
                                    '  delay(1000);\n' +
                                    '}\n'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'Temperatura = 24.71 grade\n' +
                                    'Temperatura = 23.24 grade\n' +
                                    'Temperatura = 28.61 grade\n' +
                                    'Temperatura = 24.22 grade\n' +
                                    'Temperatura = 24.71 grade\n' +
                                    'Temperatura = 26.17 grade\n' +
                                    'Temperatura = 25.20 grade'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            BMP180 (Presiune BaroMetrica) - acuratete mare
                        </li>
                    </ul>

                    <hr/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_33.png'}/>
                    </div>

                    <SyntaxHighlighter>
                        {'Vout = Vin * (R1/(R1+R2))'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=zACmjwvbils&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=8&ab_channel=ScienceBuddies"}>*/}
                    {/*            PING Ultrasonic Distance Sensor and Arduino (Lesson #8)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=n-gJ00GTsNg&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=9&ab_channel=ScienceBuddies"}>*/}
                    {/*            HC-SR04 Ultrasonic Distance Sensor and Arduino (Lesson #9)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DHTArduinoContent;