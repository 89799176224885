import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TupleAndDictionariesPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-tuples-and-dictionaries", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Tuple si dictionare</b>
                    <br/> <br/>

                    <b>Tipul secventa (sequence type)</b> este un tip de data capabil să stocheze mai mult de o valoare (sau mai puțin de una, deoarece o secvență poate fi goală), iar aceste valori pot fi iterate secvențial (de unde și numele), element cu element.
                    <br/>
                    Un tip secventa este un tip de data care poate fi iterata de un <b>for</b>. Deci, <b>for</b> este un instrument special conceput pentru a itera prin secvențe.
                    <br/>
                    Tipuri secventa:
                    <ul>
                        <li>liste</li>
                        <li>tuple</li>
                        <li>dictionare</li>
                    </ul>

                    <b>Mutabilitatea</b> este o proprietatea unei date de putea fi schimbata in timpul executiei programului.
                    Exista:
                    <ul>
                        <li>tipuri modificabile (mutabile) - datele modificabile pot fi actualizate în mod liber în orice moment - numim o astfel de operațiune <b>in situ</b> (din latina, <i>in pozitie</i>)
                            <br/>
                            de exemplu, urmatoarele comanzi modifica o lista <i>in situ</i>: lista.insert(0,1) sau lista.append(2)
                        </li>
                        <li>tipuri nemodificabile (imutabile) - nu pot fi modificate, doar citite
                            <br/>
                            de exemplu, un tiplu este un tip nemodificabil
                        </li>
                    </ul>

                    <hr/>
                    <b>A. Tuplul</b>
                    <br/><br/>
                    <b>Tuplurile sunt colecții de date ordonate și neschimbabile (imuabile)</b><br/>
                    <br/>

                    Prima și cea mai clară distincție între liste și tupluri este de data de sintaxa folosită pentru a le crea.
                    <br/>
                    Tuplurile preferă să folosească paranteze (rotunde), în timp ce listele folosesc paranteze patrate.<br/>
                    Este posibil să se creeze un tuplu doar dintr-un set de valori separate prin virgule.
                    <br/> <br/>

                    <b>A.1. Creare tuplu gol:</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu=()'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>A2. Creare tuplu un un element</b> (<b>virgula este strict necesara</b>, pentru a se putea face distinctia de o singura valoare):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu=(element,)'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu=element,'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu_1 = ()\n' +
                        'tuplu_2 = (1,)\n' +
                        'tuplu_3 = 1,\n' +
                        '\n' +
                        'print(tuplu_1)\n' +
                        'print(tuplu_2)\n' +
                        'print(tuplu_3))'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'()\n' +
                        '(1,)\n' +
                        '(1,)'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>A.3. Create tuplu cu mai multe elemente</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu=(element_1,element_2,...,element_N)'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu=element_1,element_2,...,element)N'}
                    </SyntaxHighlighter>

                    <b>Fiecare element djn tuplu poate fi de un tip diferit.</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu_1 = (1,\'2\',3.,True)'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>Operatii/metode pe tuple</b>
                    <ul>
                        <li>len() : returneaza dimensiunea unui tuplu</li>
                        <li>+: concateneaza doua tuple (in alt tuplu)</li>
                        <li>*: mutiplicare (repeta un tuplu de n ori)</li>
                        <li>in / not in: verifica existenta/inexistena unui element intr-un tuplu, similar ca la liste</li>
                        <li>functia list(): converteste o lista la un tuplu

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'tuplu = 1, 2, 3, \n' +
                                'lista = list(tuplu)\n' +
                                'print(type(lista))\n' +
                                'print(tuplu)\n' +
                                'print(lista)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'<class \'list\'>\n' +
                                '(1, 2, 3)\n' +
                                '[1, 2, 3]'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            functia tuple(): converteste o lista, range, string, etc catre un tuplu
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'tuplu_1 = tuple([1,2,3])\n' +
                                'tuplu_2 = tuple(range(4))\n' +
                                'print(tuplu_1)\n' +
                                'print(tuplu_2)'}
                            </SyntaxHighlighter>

                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'(1, 2, 3)\n' +
                                '(0, 1, 2, 3)'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            metoda count(): calculeaza numarul de duplicate intr-un tuplu

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'tuplu = 4, 2, 13, 2, 45, 4\n' +
                                'print(tuplu.count(4))'}
                            </SyntaxHighlighter>

                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'2'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu = (10, 20, 30)\n' +
                        '\n' +
                        't1 = tuplu + (1000, 2000)\n' +
                        't2 = tuplu * 2\n' +
                        '\n' +
                        'print(t1)\n' +
                        'print(t2)\n' +
                        'print(len(t2))\n' +
                        '\n' +
                        'print(10 in tuplu)\n' +
                        'print(-10 not in tuplu)'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'(10, 20, 30, 1000, 2000)\n' +
                        '(10, 20, 30, 10, 20, 30)\n' +
                        '6\n' +
                        'True\n' +
                        'True'}
                    </SyntaxHighlighter>

                    Una dintre cele mai utile proprietăți de tuplu este <b>capacitatea lor de a apărea în partea stângă a operatorului de atribuire</b> (de exemplu, aceasta facilitate poate fi folosita la interchimbarea unor variabile).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'var = 4\n' +
                        '\n' +
                        't1 = (1, )\n' +
                        't2 = (2, )\n' +
                        't3 = (3, var)\n' +
                        '\n' +
                        't1, t2, t3 = t2, t3, t1\n' +
                        '\n' +
                        'print(t1, t2, t3)'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'(2,) (3, 4) (1,)'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <ul>
                        <li>elementele unui tuplu pot fi variabile, nu doar literale. Mai mult, pot fi expresii dacă se află în partea dreaptă a operatorului de atribuire.</li>
                        <li>elementele unui tuplu pot fi accesate prin index (ex: tuplu = (4,3,1) / print(tuplu[1]) {"=>"} 3)</li>
                        <li>se poate stege tot tuplul (ex: del tuplu)</li>
                        <li>nu se pot modifica elemente ale unui tuplu (un tuplu este imutabil, de ex: tuplu[1]=12 {"=>"} va arunca exceptie )</li>
                    </ul>

                    <hr/>
                    <b>B. Dictionarul</b>
                    <br/><br/>
                    Dicționarul <b>nu este un tip de secvență</b> (dar <b>poate fi ușor adaptat la procesarea secvenței</b>) și este <b>mutabil</b>.
                    <br/>
                    Un dicționar este un set de perechi cheie-valoare:
                    <ul>
                        <li>fiecare cheie trebuie să fie unică - nu este posibil să existe mai mult de o cheie de aceeași valoare</li>
                        <li>o cheie poate fi orice tip imuabil de obiect: poate fi un număr (întreg sau flotant), sau chiar un șir, dar nu o listă</li>
                        <li>un dicționar nu este o listă - o listă conține un set de valori numerotate, în timp ce un dicționar conține perechi de valori</li>
                        <li>funcția len() funcționează și pentru dicționare - returnează numărul de elemente cheie-valoare din dicționar</li>
                        <li>un dicționar este un instrument unidirecțional</li>
                    </ul>

                    <b>B.1. Creare dictionar gol:</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'dictionar={}'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>B.2. Create dictionar cu mai multe elemente</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tuplu={cheie_1:valoare_1, cheie_2:valoare_2,...,cheie_N:valoare_N}'}
                    </SyntaxHighlighter>

                    Lista de perechi este înconjurată de <b>acolade</b>, în timp ce perechile în sine sunt <b>separate prin virgule</b>, iar cheile și valorile prin <b>două puncte</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'dictionar = {"masina": "car", "caine": "dog"}\n' +
                        'munti = {\'moloveanu\': 2544, \'negoiu\': 2535 }\n' +
                        'dictionar_gol = {}\n' +
                        '\n' +
                        'print(dictionar)\n' +
                        'print(munti)\n' +
                        'print(dictionar_gol)'}
                    </SyntaxHighlighter>
                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'{\'caine\': \'dog\', \'masina\': \'car\'}\n' +
                        '{\'moloveanu\': 2544, \'negoiu\': 2535}\n' +
                        '{}'}
                    </SyntaxHighlighter>

                    <b>Observatii</b>
                    <ul>
                        <li>
                            Ordinea perechilor tipărite este diferită de cea din atribuirea inițială, pentru că dicționarele nu sunt liste - nu păstrează ordinea datelor lor
                        </li>
                        <li>
                            Ordinea în care un dicționar își stochează datele tine de implementarea in Python (in Python 3.6x, dicționarele au devenit colecții ordonate în mod implicit)
                        </li>
                        <li>
                            Daca se incerca accesarea unui perechi, dupa cheie, si cheia nu exista in dictionar, se va arunca exceptie in timpul rularii.
                        </li>
                    </ul>

                    <b>Operatii/metode pe dictionare</b>
                    <ul>
                        <li><b>in / not in</b>: verifica existenta/inexistena unui element intr-un dictionar, similar ca la tuple/liste</li>
                        <li>metoda <b>keys()</b>: returnează un obiect iterabil format din toate cheile dintr-un dicționar

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = {\n' +
                                '    "masina": "car", \n' +
                                '    "caine": "dog"\n' +
                                '}\n' +
                                '\n' +
                                'print(dictionar.keys())'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dict_keys([\'masina\', \'caine\'])'}
                            </SyntaxHighlighter>

                            metoda keys() este utila in iterarea dictionarului:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = {\n' +
                                '    "masina": "car", \n' +
                                '    "caine": "dog"\n' +
                                '}\n' +
                                '\n' +
                                'for e in dictionar.keys():\n' +
                                '    print(e,\'->\',dictionar[e])'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'masina -> car\n' +
                                'caine -> dog'}
                            </SyntaxHighlighter>

                        </li>
                        <li> functia <b>sorted()</b>: sorteaza o lista

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = {\n' +
                                '    "masina": "car", \n' +
                                '    "caine": "dog"\n' +
                                '}\n' +
                                '\n' +
                                'for e in sorted(dictionar.keys()):\n' +
                                '    print(e,\'->\',dictionar[e])'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'caine -> dog\n' +
                                'masina -> car'}
                            </SyntaxHighlighter>
                        </li>

                        <li>metoda <b>items()</b>: returneaza o lista de tuple

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                '\n' +
                                'print(dictionar.items())'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dict_items([(\'masina\', \'car\'), (\'caine\', \'dog\')])'}
                            </SyntaxHighlighter>

                            metoda items() poate fi utilizata si ea in iterarea dictionarului:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                '\n' +
                                'for cheie,valoare in dictionar.items():\n' +
                                '    print(cheie,\'->\',valoare)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'masina -> car\n' +
                                'caine -> dog'}
                            </SyntaxHighlighter>

                        </li>

                        <li>metoda <b>values()</b>: returnează un obiect iterabil format din toate valorile dintr-un dicționar

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                '\n' +
                                'print(dictionar.values())'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dict_values([\'car\', \'dog\'])'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>modificarea unei valori asociate unei chei</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                'dictionar[\'masina\']=\'autobuz\'\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'masina\': \'autobuz\', \'caine\': \'dog\'}'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>adaugarea unei noi perechi cheie-valoare</b> (dictionar[cheie_noua]=valoare_noua)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                'dictionar[\'mar\']=\'apple\'\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'masina\': \'car\', \'caine\': \'dog\', \'mar\': \'apple\'}'}
                            </SyntaxHighlighter>

                            Comportament de mai sus e diferit față de liste, care nu vă permite să atribuiți valori indicilor inexistenți.

                        </li>

                        <li><b>stergerea unui perechi, dupa o cheie</b> (del dictionar['cheie'])

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                'del dictionar[\'caine\']\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'masina\': \'car\'}'}
                            </SyntaxHighlighter>

                            <b>Daca se incerca stergerea dupa o cheie care nu exista, se va arunca o exceptie in timpul rularii programului</b>
                        </li>
                        <li><b>metoda popitem(): sterge ultima pereche/element din dictionar</b> (in versiunile mai vechi de Python, înainte de 3.6.7, metoda popitem() stergea un element aleatoriu dintr-un dicționar)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                'dictionar.popitem()\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'masina\': \'car\'}'}
                            </SyntaxHighlighter>
                        </li>

                        <li> <b>metoda update()</b> : poate fi folosita pentru a adauga un element nou intr-o lista:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                'dictionar.update({"cer":"sky"})\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'masina\': \'car\', \'caine\': \'dog\', \'cer\': \'sky\'}'}
                            </SyntaxHighlighter>
                        </li>

                        <li><b>iterare dictionar</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                '\n' +
                                'for x in dictionar:\n' +
                                '    print(x)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'masina\n' +
                                'caine'}
                            </SyntaxHighlighter>

                        </li>

                        <li>metoda <b>clear()</b>: sterge toate elementele dintr-un dictionar

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'dictionar = { "masina": "car", "caine": "dog"}\n' +
                                'dictionar.clear()\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>metoda <b>copy()</b>: face o copie la un dictionar

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'d1 = { "masina": "car", "caine": "dog"}\n' +
                                'd2 = d1.copy()\n' +
                                'd1.update({"carte":"book"})\n' +
                                'print(d1)\n' +
                                'print(d2)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'masina\': \'car\', \'caine\': \'dog\', \'carte\': \'book\'}\n' +
                                '{\'masina\': \'car\', \'caine\': \'dog\'}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>functia <b>dict()</b>: converteste un tuplu catre un dictionar:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'culori = (("green", "verde"), ("red", "rosu"))\n' +
                                '\n' +
                                'dictionar = dict(culori)\n' +
                                'print(dictionar)'}
                            </SyntaxHighlighter>
                            Rezultat:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'{\'green\': \'verde\', \'red\': \'rosu\'}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>Identare suspendata</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'dictionar = {\n' +
                        '    "masina": "car", \n' +
                        '    "caine": "dog"\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TupleAndDictionariesPythonContent;