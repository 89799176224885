import {Subject} from 'rxjs';
import {useEffect} from "react";
import {first} from "rxjs/operators";


type Color = 'white' | 'green' | 'red' | 'blue';

const color$ = new Subject<Color>();


const RxJxLastTestComponent = () => {



    useEffect(
        () => {

            // color$.pipe(last())
            //     .subscribe(value => {
            //         console.log("last =>",value);
            //     });

            color$.pipe()
                .subscribe(value => {
                    console.log("=>",value);
                });

            color$.pipe(first())
                .subscribe(value => {
                    console.log("first =>",value);
                });

            color$.next('white');
            color$.next('green');
            color$.next('red');
            color$.next('blue');
            // color$.complete();

        }, [])


    return <div>
        {/*{logs.map((l, i) => {*/}
        {/*    return <div key={i}>*/}
        {/*        {l}*/}
        {/*    </div>*/}
        {/*})}*/}
    </div>;
}

export default RxJxLastTestComponent;