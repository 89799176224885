import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class BinPremiereProContent extends BaseContentPage  {

    constructor(props) {
        super(props, "premiere-pro-organizer", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Gestionare bin-uri</b>
                    <br/>
                    <br/>
                    <b>Bin</b>-urile permit organizarea <b>clip</b>-urilor intr-o structura arborescenta, similar cu structura de directoare, doar ca <b>bin</b>-urile exista doar intr-o forma <i>logica</i> in contextul unui proiect (nu exista fizic pe disc sub forma de directoare).

                    <br/>
                    <br/>
                    Moduri de a creea un bin:
                    <ul>
                        <li>
                            din panel-ul Project, apasand pe iconita <i>New Bin</i>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/videography/premiere-pro-2.png'}/>
                            </div>
                        </li>
                        <li>
                            din panel-ul Project, tragand drag-and-drop peste iconita <i>New Bin</i> (vezi mai sus)
                        </li>
                        <li>
                            din panel-ul Project, click dreapta intr-o o zona libera, pentru a apare meniul contextual, si apoi se click pe <i>New Bin</i>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/videography/premiere-pro-3.png'}/>
                            </div>
                        </li>
                        <li>
                            din meniul aplicatiei, fiind selectat panel-ul Project: <i>File {"->"} New {"->"} Bin</i>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/videography/premiere-pro-4.png'}/>
                            </div>
                        </li>
                        <li>
                            fiind selectat panel-ul Project: <i>Ctrl + B</i>
                        </li>
                    </ul>
                    <hr/>
                    Pentru a vedea unde pe disc este un clip / resursa: <i>click-dreapta</i> pe clip {"->"} <b>Reveal in Explorer...</b>
                    <hr/>
                    Cand se stege un clip din panel-ul Project, se sterge doar logic, adica nu este sters si fizic de pe disc (se sterge logic doar din proiect).

                    <hr/>
                    Bin-urile se pot vizualiza in trei moduri:
                    <ul>
                        <li><b>List view</b></li>
                        <li><b>Icon view</b></li>
                        <li><b>Freeform view</b></li>
                    </ul>
                    In modul de vizualizare lista (<i>list view</i>), fiecare clip poate avea o imagine in miniatura (<i>thumbnails</i>), daca se bifeaza <b>Thumbnails</b> facand click pe meniu panel Proiect (iconita cu 3 linii orizonatale):
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/videography/premiere-pro-5.png'}/>
                    </div>
                    Pentru a schimba imaginea in miniatura:
                    <ul>
                        <ul>mergem in vizualizare de tip icon (icon view)</ul>
                        <li>facem hover pe clip, pana gasim un frame reprezentativ</li>
                        <li>apasam <i>I</i></li>
                    </ul>
                    <hr/>
                    Pe baza unei cautari putem face un <i>bin</i> virtual cu rezultatele cautarii (contintul acestui bin se va actualiza apoi dinamic pe masura ce apar noi clip-uri in proiect care indeplinesc conditiile cautarii), apasand pe iconita cu o lupa peste un director:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/videography/premiere-pro-6.png'}/>
                    </div>
                    <hr/>
                    Culoarea label-ului (patratul colorat) pentru un bin poate fi schimbat: click-dreapta pe label {"->"} <i>Label</i> {"->"} alege culoare:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/videography/premiere-pro-7.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BinPremiereProContent;