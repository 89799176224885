import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateUserAttributesKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-user-attributes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Creare atribute utilizator</b>
                    <br/>
                    Vezi link-urile de mai jos!

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.baeldung.com/keycloak-custom-user-attributes"}>
                                    Custom User Attributes with Keycloak
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://wstutorial.com/am/keycloak-custom-user-attributes.html"}>
                                    Keycloak custom user attributes
                                </a>
                            </div>
                        </li>

                        <li>
                            <di>
                                <a href={"https://www.sravi.com/2018/08/how-to-add-custom-user-attributes-in.html"}>
                                    How to add custom user attributes in keycloak and access them in spring boot application
                                </a>
                            </di>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateUserAttributesKeycloakContent;