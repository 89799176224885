import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ProfilerVisualVMJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-profiler-visual-vm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Profiler: VisualVM</b>
                    <br/>
                    <br/>

                    VisualVM este distribuit ca instrument independent la GitHub și ca componentă opțională a GraalVM
                    <br/>
                    VisualVM a fost distribuit în Oracle JDK 6~8 ca Java VisualVM  A fost întrerupt în Oracle JDK 9.

                    <hr/>
                    <b>2. Instalare/Descarcare</b>
                    <br/>
                    <br/>

                    Descarcare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'https://visualvm.github.io/download.html'}
                    </SyntaxHighlighter>
                    Dupa descarcare, se dezarhiveaza.

                    <hr/>
                    <b>3. Configurare</b>
                    <br/>
                    <br/>

                    In fisierul <b>/etc/visualvm.config</b> se decomenteaza linia:
                    <SyntaxHighlighter>
                        {'visualvm_jdkhome="/path/to/jdk"'}
                    </SyntaxHighlighter>
                    si se adauga calea catre JDK:
                    <SyntaxHighlighter>
                        {'visualvm_jdkhome="C:\\Program Files\\Java\\jdk-17"'}
                    </SyntaxHighlighter>

                    Uneori, este nevoie si de specificarea:
                    <SyntaxHighlighter>
                        {'-Djava.rmi.server.hostname=localhost'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Rulare</b>
                    <br/>
                    <br/>

                    In fisierul <b>/bin</b>:
                    <SyntaxHighlighter>
                        {'visualvm.exe'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Analiza</b>
                    <br/>
                    <br/>

                    Pe stanga click pe Aplicatie.
                    <br/>
                    <br/>

                    4.1. In dreapta pe tab <b>Monitor</b>.
                    <br/>
                    Grafic CPU (se monitorizeaza cat CPU foloseste aplicatia si cat CPU foloseste GC):
                    <ul>
                        <ul>
                            daca CPU mare si GC = 0, atunci aplicatia chetuieste multa putere de procesare, dar nu proceseaza nimic;
                            <br/>
                            aceasta indica, de obicei, fire zombie!
                        </ul>
                    </ul>

                    Grafic Memory (se monitorizeaza consumul de memorie):
                    <ul>
                        <li>Heap (daca used heap este mic inseamna ca nu aplicatia nu consuma memorie, si deci nici aplicatia nu face nimic)</li>
                        <li>Metadata (metadate legate de clase necesare pentru executia aplicatiei)</li>
                    </ul>

                    Pentru a seta o dimensiune maxima a heap-ului: <b>-Xmx</b> (ex: -Xmx1G)
                    <br/>
                    Pentru a seta o dimensiune initiala a heap-ului: <b>-Xms</b> (ex: -Xms500m)
                    <br/>
                    Pentru a seta o dimensiune maxima pentru metadata: <b>XX:MaxMetaspaceSize</b> (ex: -XX:MaxMetaspaceSize=100M)

                    <br/>
                    <br/>
                    4.2. In dreapta pe tab <b>Threads</b>
                    <br/>
                    Tabul <b>Threads</b> oferă o reprezentare vizuală a firelor în execuție și a stărilor acestora.
                    <br/>
                    O functionare corecta va afisa graficul ca un fierastrau cu zimti (in plus CPU nu e asa mare comparativ cu GC)

                    <br/>
                    <br/>
                    4.3. In dreapta pe tab <b>Sampler</b> (esantionare)
                    <br/>

                    Se poate folosi pentru a gasi cat timp are nevoie o metoda sa se ruleze

                    <br/>
                    <br/>
                    4.4. In dreapta pe tab <b>Profiler</b> (esantionare)
                    <br/>

                    Nu trebuie profilata intreaga aplicatie, ci pe baza esantionarii (tab <b>Sampler</b>) se decide ce parte din aplicatie sa fie profilata.
                    <br/>
                    Astfel, in drepta in seciunea <b>CPU Settings</b> se poate seta reguli:
                    <ul>
                        <li>fiecare regula se pune o linie separata</li>
                        <li>se foloseste * pentru a ne referi la toate clasele dintr-un pachet (ex: com.example.*)</li>
                        <li>se foloseste ** pentru a ne referi la toate clasele dintr-un pachet si subpachetele acestuia (ex: com.example.**)</li>
                        <li>se poate scrie numele complet al clasei (ex: com.example.Main)</li>
                    </ul>
                    Profilare pentru a afla de câte ori a fost executată o metodă

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>*/}
                    {/*            Writing Hello World in Java byte code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ProfilerVisualVMJavaContent;