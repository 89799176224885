import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../grafana/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class BuildFromSourceGrafanaContent extends BaseContentPage  {

    constructor(props) {
        super(props, "grafana-build-from-source", IndexContent);
    }


    render() {

        // let path = ' D:\\tools\\GrafanaLabs\\grafana\\public\\locales\\en-US\\grafana.json'; // PATH OF YOUR JSON FILE (includes file name)
        //
        // translator.translateFile(
        //     path,
        //     translator.languages.English,
        //     translator.languages.Romanian
        // );

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana - Build din surse</b>
                    <br/>
                    <br/>

                    Prerechizite:
                    <ul>
                        <li>
                            <b>git</b> (pentru clonare proiect)
                        </li>
                        <li>
                            <b>Go</b>, versiunea > 1.14
                            <SyntaxHighlighter>
                                {'go version'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'go version go1.21.0 windows/amd64'}
                            </SyntaxHighlighter>
                            Deci, 1.21 > 1.14, ok!
                            <br/>
                        </li>

                        <li>

                            <b>pentru frontend</b>:

                            <ul>
                                <li>
                                    <b>npm</b>:
                                    <SyntaxHighlighter>
                                        {'npm -v'}
                                    </SyntaxHighlighter>
                                    poate afisa:
                                    <SyntaxHighlighter>
                                        {'6.14.8'}
                                    </SyntaxHighlighter>
                                </li>

                                <li>
                                    <b>yarn</b>:
                                    <SyntaxHighlighter>
                                        {'yarn -v'}
                                    </SyntaxHighlighter>
                                    poate afisa:
                                    <SyntaxHighlighter>
                                        {'3.6.1'}
                                    </SyntaxHighlighter>

                                    daca yarn nu este instalat:
                                    <SyntaxHighlighter>
                                        {'npm install -g yarn'}
                                    </SyntaxHighlighter>
                                </li>

                                <li>
                                    <b>node</b>:
                                    <SyntaxHighlighter>
                                        {'node -v'}
                                    </SyntaxHighlighter>
                                    poate afisa:
                                    <SyntaxHighlighter>
                                        {'v18.15.0'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>

                        </li>

                        <li>
                            <b>make</b>

                            <br/>
                            <br/>
                            pentru windows:
                            <br/>
                            <b>wsl --install -d ubuntu</b>
                            <br/>

                            pentru a avea acces la <i>terminal ubuntu</i>!
                        </li>

                    </ul>

                    <hr/>
                    <b>1. Clonarea</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter>
                        {'cd D:\\work\\grafana-custom'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'git clone https://github.com/grafana/grafana.git'}
                    </SyntaxHighlighter>
                    (a nu se folosi <b>go get</b> pentru a downloada Grafana!)
                    <SyntaxHighlighter>
                        {'cd grafana'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Setup frontend</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'cd D:\\work\\grafana-custom\\grafana'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'yarn install --immutable'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Setup backend</b>
                    <br/>
                    <br/>

                    Folosind <b>terminal ubuntu</b> (sau scriind in cmd comanda <b>ubuntu</b>):
                    <SyntaxHighlighter>
                        {'cd /mnt/d/work/grafana-custom/grafana/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'go run build.go setup'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'go run build.go build'}
                    </SyntaxHighlighter>

                    Daca ceva crapa:
                    <SyntaxHighlighter>
                        {'note: module requires Go 1.19'}
                    </SyntaxHighlighter>
                    S-ar putea sa nu existe o anumita versiune de go instalata si atunci trebuie instalata:
                    <SyntaxHighlighter>
                        {'go install golang.org/dl/go1.19@latest'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'cd $HOME/go/bin/'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'./go1.19 download'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'go install golang.org/dl/go1.20@latest'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'cd $HOME/go/bin/'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'./go1.20 download'}
                    </SyntaxHighlighter>

                    Daca nu se pot downloada:
                    <SyntaxHighlighter>
                        {'sudo nano /etc/resolv.conf'}
                    </SyntaxHighlighter>
                    Se adauga linia:
                    <SyntaxHighlighter>
                        {'nameserver 1.1.1.1'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter>
                        {'export PATH=$PATH:$HOME/go/bin'}
                    </SyntaxHighlighter>

                    <hr/>
                    Instalare Go pe Ubuntu:
                    <SyntaxHighlighter>
                        {'cd /usr/local/'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'sudo mkdir go'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'cd go'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'sudo curl -OL https://golang.org/dl/go1.20.4.linux-amd64.tar.gz'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'sudo tar -C /usr/local -xvf go1.20.4.linux-amd64.tar.gz'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'sudo nano $HOME/.profile'}
                    </SyntaxHighlighter>
                    Se adauga:
                    <SyntaxHighlighter>
                        {'export PATH=$PATH:/usr/local/go/bin'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'source .profile'}
                    </SyntaxHighlighter>

                    Afisare detalii despre go:
                    <SyntaxHighlighter>
                        {'go env'}
                    </SyntaxHighlighter>
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'export GOROOT=/usr/local/go'}*/}
                    {/*</SyntaxHighlighter>*/}

                    {/*// <!-- GOVERSION="go1.18.1" -->*/}
                    {/*// <!-- GOROOT="/usr/lib/go-1.18" -->*/}

                    <hr/>
                    <b>4. Pornire aplicatie</b>
                    <br/>
                    <br/>

                    backend:
                    <SyntaxHighlighter>
                        {'make run'}
                    </SyntaxHighlighter>

                    frontend:
                    <SyntaxHighlighter>
                        {'yarn start'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pornire pe Windows din linie de comanda:
                    <SyntaxHighlighter>
                        {'grafana-server.exe --homepath="D:\\work\\grafana-custom\\grafana" --config="D:\\work\\grafana-custom\\grafana\\conf\\defaults.ini" web'}
                    </SyntaxHighlighter>

                    {/*<hr/>*/}
                    {/*Rezumat:*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'# backend\n' +*/}
                    {/*        'cd %GOPATH%\n' +*/}
                    {/*        'cd src\\github.com\\grafana\\grafana\n' +*/}
                    {/*        'go run build.go setup\n' +*/}
                    {/*        'go run build.go build              # (or \'go build ./pkg/cmd/grafana-server\')\n' +*/}
                    {/*        '\n' +*/}
                    {/*        '# frontend\n' +*/}
                    {/*        'npm install -g yarn\n' +*/}
                    {/*        'yarn install --pure-lockfile\n' +*/}
                    {/*        'yarn watch.\n' +*/}
                    {/*        '\n' +*/}
                    {/*        '\n' +*/}
                    {/*        'pornesc aplicatia:\n' +*/}
                    {/*        '-->make run\n' +*/}
                    {/*        '-->yarn start'}*/}
                    {/*</SyntaxHighlighter>*/}
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BuildFromSourceGrafanaContent;