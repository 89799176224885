import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ExportContainerDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-container-export", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker container export</b>
                    <br/>
                    <br/>

                    Pentru a exporta un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker export --output=[arhiva-tar] [container]'}
                    </SyntaxHighlighter>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker export --output="busybox-save.tar" busybox-save:latest'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a importa o imagine exportata:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker import [arhiva-export-tar] [container]'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker import --message "mesaj" --change "CMD bash" busybox-save.tar busybox-save:v1'}
                    </SyntaxHighlighter>

                    <hr/>
                    Folosind <b>docker export</b>:
                    <ul>
                        <li>se poate <b>reduce dimensiunea </b> unei imagini (imaginea va avea <b>un singur layer</b>) - operatie care mai poarta numele de <i>flattering</i> </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExportContainerDockerContent;