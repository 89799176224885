import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"llm-root", url:"#", title:"Large language model (LLM)", subtitle:""},
        {id:"llm-intro", url:"/llm/intro", title:"Introducere", subtitle:"", parent:"llm-root"},
        {id:"llm-word-embedding", url:"/llm/word-embedding", title:"Transformes: Word Embedding (incorporarea cuvintelor)", subtitle:"", parent:"llm-root"},
        {id:"llm-ollama-pi5", url:"/llm/ollama-pi5", title:"Ollama - Raspberry Pi 5", subtitle:"", parent:"llm-root"},

        {id:"llm-util", url:"/llm/util", title:"Util", subtitle:"", parent:"llm-root"},

    ]

    static indexUrl = "/llm/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Text-to-Speech (TTS)
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;