import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class ChmodLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-chmod", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a schimba permisiunile unui fisier se foloseste comanda: <b>chmod</b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod [permisiuneUser][permisiuneGrup][persmisiuneAltii] fisier'}
                    </SyntaxHighlighter>

                    unde [permisiuneUser][permisiuneGrup][persmisiuneAltii], poate avea ca valori:
                    <ul>
                        <li>rwx = 111 {"=>"} 7</li>
                        <li>rw- = 110 {"=>"} 6</li>
                        <li>r-- = 100 {"=>"} 4</li>
                        <li>--- = 000 {"=>"} 0</li>
                    </ul>

                    exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod 777 a.txt'}
                    </SyntaxHighlighter>
                    se avea ca rezultat:
                    <SyntaxHighlighter>
                        {'-rwxrwxrwx 1 kj kj  184 dec 12 17:50 a.txt'}
                    </SyntaxHighlighter>

                    exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod 644 a.txt'}
                    </SyntaxHighlighter>
                    se avea ca rezultat:
                    <SyntaxHighlighter>
                        {'-rw-r--r-- 1 kj kj  184 dec 12 17:50 a.txt'}
                    </SyntaxHighlighter>

                    exemplu 3: sterge permisiunea de executie (-x) pentru utilizator, grup si altii:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod -x a.txt'}
                    </SyntaxHighlighter>

                    exemplu 4: adauga permisiunea de executie (+x) pentru utilizator, grup si altii:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod +x a.txt'}
                    </SyntaxHighlighter>

                    exemplu 5: sterge permisiunea de executie (g-x) pentru grup:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod g-x a.txt'}
                    </SyntaxHighlighter>

                    exemplu 6: sterge permisiunea de read (g-r) pentru grup:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod g-r a.txt'}
                    </SyntaxHighlighter>

                    exemplu 7: sterge permisiunea de executie (u-x) pentru user:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chmod u-x a.txt'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChmodLinuxContent;