import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class EditareVideoIntroducereContent extends BaseContentPage  {

    constructor(props) {
        super(props, "premiere-pro-introducere", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    <b>1. Rezolutia</b>
                    <br/>
                    Rezolutii standard:
                    <ul>
                        <li>HD (High Definition): 1280 x 720</li>
                        <li>FHD (Full HD): 1920 x 1080</li>
                        <li>UHD (Ultra HD): 3840 x 2160</li>
                        <li>4K: 4096 x 2160</li>
                    </ul>
                    <hr/>
                    <b>2. Cadenta / fps</b>
                    <br/>
                    Standard:
                    <ul>
                        <li>cinematografic: 24fps</li>
                        <li>tv: 25fps (Europa)/ 30fps (SUA) </li>
                    </ul>

                    <hr/>
                    <b>3. Progresiv / Intercalat</b>
                    <br/>
                    <ul>
                        <li>progresiv: o fotograma e afisata integral</li>
                        <li>intercalat: o fotograma este impartita in 2 campuri A si B; redate la o anumita viteza, creeaza iluzia unei imagini redate integral (e nevoie de un numar dublu de 2xfps: 1080/25p = 1080/50i)</li>
                    </ul>

                    <hr/>
                    <b>4. Salvare workspace </b>
                    <br/>
                    Daca mutand ferestre, reconfiguran tab-uri, etc ajugem la concluzia ca este util atunci putem salva aceasta configurare:
                    <ul>
                        <li>Window {"->"} Workspaces {"->"} Save Changes to this Workspace (suprascrie workspace-ul curent, de exemplu Editing)</li>
                        <li>Window {"->"} Workspaces {"->"} Save as New Worskpace...</li>
                    </ul>
                    Ordinea in care se afiseaza spatiile de lucru se pot configura:
                    <ul>
                        <li>Window {"->"} Workspaces {"->"} Edit Workspaces (si apoi drag and drop)</li>
                    </ul>
                    Resetarea unui spatiu de lucru:
                    <ul>
                        <li>Window {"->"} Workspaces {"->"} Reset to Saved Layout</li>
                    </ul>

                    <hr/>
                    <b>5. Locatia unui proiect </b>
                    <br/>
                    <ul>
                        <li>File{"->"} Project Settings {"->"} General {"->"} tab Scratch Disks {"->"} Path</li>
                        <li>pe bara de titlu</li>
                    </ul>

                    <hr/>
                    <b>6. Salvari automate a unui proiect </b>
                    <br/>
                    <ul>
                        <li>File {"->"} Project Settings {"->"} General {"->"} tab Scratch Disks {"->"} Project Auto Save (se poate seta o alta locatie/partitie decat ceea in care este proiectul)</li>
                        <li>Edit {"->"} Preferences {"->"} Auto Save
                            <ul>
                                <li>la cate minute sa face save-ul: Automatically Save Every</li>
                                <li>numarul maxim de versiuni: Maximum Project Version</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>7. Organizarea unui proiect </b>
                    <br/>
                    Fiecare tip de material ar trebuie sa fie in propriul bin:
                    <ul>
                        <li>video (brut (camera1 (card1, card2), camera2 (card,...)), vfx)</li>
                        <li>audio (dialog, muzica, sfx (efecte se sunete))</li>
                        <li>imagini (fotografi, grafica, logo)</li>
                        <li>text (generic, lista numele persoanelor care apar in clip)</li>
                    </ul>

                    <hr/>
                    <b>8. Import unui proiect </b>
                    <br/>
                    <ul>
                        <li>fereastra Project, apoi dublu-click (se poate selecta un singur fisier video, mai multe sau un intreg director - Import Folder)</li>
                        <li>fereastra Project, click dreapta, din meniul contextual {"->"} Import (se poate selecta un singur fisier video, mai multe sau un intreg director - Import Folder)</li>
                        <li>drag and drop in fereastra Project</li>
                    </ul>

                    <hr/>
                    <b>9. Crearea unui bin </b>
                    <br/>
                    <ul>
                        <li>din fereastra Project {"->"} butonul New Bin</li>
                        <li>din fereastra Project {"->"} click dreapta {"->"} din meniul contextual {"->"} New Bin</li>
                    </ul>

                    <hr/>
                    <b>10. Fereastra Source </b>
                    <br/>
                    <ul>
                        <li>pornire clip: buton play / tasta spacebar</li>
                    </ul>

                    <hr/>
                    <b>11. Fereastra Timeline (montarea) </b>
                    <br/>
                    Moduri de a aduce material video in fereastra Timeline:
                    <ul>
                        <li>drag and drop, din fereastra Source in fereastra Timeline </li>
                        <li>drag and drop, din fereastra Source in fereastra Timeline </li>
                    </ul>

                    <hr/>
                    <b>12. Creare secventa noua </b>
                    <br/>
                    <ul>
                        <li>Din fereastra Proiect, pe un clip, click dreapta {"->"} <b>New Sequence from clip</b> (Premier Pro va creea o secventa prelunad numele, rezolutia, cadenta, codecul clipului selectat)</li>
                        <li>Din fereastra Proiect, click dreapta pe butonul <b>New Item {"->"} Sequence</b></li>
                        <li>Din fereastra Proiect, click dreapta {"->"} meniu contextul {"->"} <b>New Item {"->"} Sequence</b></li>
                    </ul>

                    <hr/>
                    <b>13. Stegerea unui element </b>
                    <br/>
                    Din fereastra Proiect, pe clip, click dreapta {"->"} <b>Clear</b> sau apasat tasta DEL.

                    <hr/>
                    <b>14. Modificare secventa </b>
                    <br/>
                    Din fereastra Proiect, selectare secventa, click dreapta {"->"} din meniul contextual {"->"} <b>Sequence Settings</b> (se poate modifica de exemplu cadenta, rezolutia, etc)

                    <hr/>
                    <b>15. Navigare Fereastra Source</b>
                    <br/>

                    Un clip este afisat ca durata in formatul HH:mm:ss:ff, unde ff = numar fotograme.
                    <br/>
                    Un clip poate fi taiat in fereastra <i>Source</i> marcand inceputul si sfarsitul:
                    <ul>
                        <li><b>Mark In (I)</b></li>
                        <li><b>Mark Out (O)</b></li>
                    </ul>
                    Pentru a merge rapid la unul din capete:
                    <ul>
                        <li><b>Shift+I</b> (Go to In): pentru a merge la marcajul Mark In</li>
                        <li><b>Shift+O</b> (Go to Out): pentru a merge la marcajul Mark Out</li>
                    </ul>
                    Pentru a merge fotograma cu fotograma se pot folosi <b>sagetile stanga/dreapta</b> de pe tastatura.
                    <br/>


                    <hr/>
                    <b>16. Navigare Fereastra Timeline </b>
                    <br/>

                    Pentru a naviga la inceput: tasta <b>HOME</b> (Fn+sageta stanga).
                    <br/>
                    Pentru a naviga la sfarsit: tasta <b>END</b> (Fn+sageta dreapta).
                    <br/>
                    <br/>
                    Pentru a vedea naviga intre clipurile unei secvente se foloseste <b>PgUp (sageata sus)/PgDown (sageata jos)</b>
                    <br/>
                    <br/>
                    Pentru a vedea secventa mai restransa/larga se foloseste scroll-ul din josul ferestrei sau tastele <b>+/-</b>.
                    <br/>
                    <br/>
                    Se poate mari zona dedicata unui track, pentru a vedea un clip mai mare, folosind <b>ALT+rotita de scroll mouse</b>
                    <br/>
                    <br/>
                    Daca exista mai multe clipuri pe mai multe track-uri, acestea sunt redate cu prioritate pe clipurile plasate pe cele de mai sus track-uri.
                    <br/>
                    <br/>
                    Atunci cand un clip are si parte video si partea audio si miscam de el, vom vedea ca cele 2 parti sunt lipite una de alta.
                    Pentru a le dezlipi, click dreapta pe una din parti {"->"} apare un meniu contextual {"->"} <b>Unlink</b>.

                    <br/>
                    <br/>
                    Pentru a bloca un track, pentru a evita eventuale modificari se foloseste <b>Toogle Track Lock</b> (iconita sub forma de lacat)

                    <br/>
                    <br/>
                    Pentru a nu vedea un anumit track, se foloseste <b>Toogle Track Output</b> (iconita sub forma de ochi)

                    <hr/>
                    <b>17. Trim tool </b>
                    <br/>

                    Un clip din fereastra Timeline poate fi marit sau micsorat, mergand la unul din capete (apare un marker ca o paranteza patrata rosie) si tragand in sensul dorit.

                    <br/>
                    Pentru a folosi trim tool independent de partea video/audio, trebuie tinuta apasata tasta <b>ALT</b>.

                    <hr/>
                    <b>18. Snap </b>
                    <br/>

                    Snap (scurtatura: S) (iconita sub forma de magnet) se refera la functia care permite ca atunci cand ducem un clip spre altul cu intentia de al lipi, Premier Pro tinde sa atraga clipul care se doreste lipit.

                    <hr/>
                    <b>19. Instrumente pentru editare </b>
                    <br/>
                    <br/>
                    Instrumente:
                    <ul>
                        <li>cursorul (<b>V</b>): folosit pentru a selecta, muta diverse clipuri; se poate folosi si la lungit si scurtat clipuri (<b>Trim tool</b>: paranteza patrata rosie)</li>
                        <li><b>Razor tool</b> (lama) (<b>C</b>): folosit pentru a face taieturi intr-un clip (desparte un clip in doua)
                            <br/>
                            in combinatie cu <b>Shift + click</b> poate taia de-a lungul tututor track-urilor.
                            <br/>
                            <b>CTRL+K</b> va taia acolo unde este marker-ul (pointer-ul de pozitie in cadrul clipului), indiferent de instrumentul selectat
                        </li>
                        <li>
                            <b>Track Select Forward Tool (A)</b>: are rolul de a selecta tot ce este in partea dreapta de pe track-ul cu care sunt cu mouse-ul in momentul respectiv
                            <br/>
                            in combinatie cu <b>Shift + click</b> va selecta totul din partea dreapta doar de pe track-ul pe care sunt cu mouse-ul in momentul respectiv
                        </li>
                        <li>
                            <b>Track Select Backward Tool (Shift+A)</b>: are rolul de a selecta tot ce este in partea stanga de pe track-ul cu care sunt cu mouse-ul in momentul respectiv
                            <br/>
                            in combinatie cu <b>Shift + click</b> va selecta totul din partea stanga doar de pe track-ul pe care sunt cu mouse-ul in momentul respectiv
                        </li>

                        <li>
                            <b>Ripple Edit Tool (B)</b>
                            <br/>
                            Seamana cu Trim Tool (paranteza patrata rosie), dar acesta este o paranteza patrata <i>galbena</i>,
                            dar cu acesta cand mergem la marginea unui clip sa il marim sau microram acesta:
                            <ul>
                                <li>nu va fi contrans de ce in stanga sau in dreapta, impindand restul clipurilor in stanga sau in dreapta</li>
                                <li>nu va lasa spatii libere</li>
                            </ul>

                        </li>

                        <li>
                            <b>Rolling Edit Tool (N)</b>
                            <br/>
                            Se foloseste doar la intalnirea dintre 2 clipuri, avand rolul de <i>alungi sfarsitul clipului din stanga</i> si <i>a scurta inceputul clipului din dreapta</i>
                            sau a <i>scurta sfarsitul clipului din stanga</i> si <i>a lungi inceputul clipului din dreapta</i>
                        </li>

                        <li>
                            <b>Rate Stretch  Tool (R)</b>
                            <br/>
                            Se ocupa cu accelerarea sau decelerarea timpului (slow motion) unui clip.
                            Se foloseste ca <b>Trim tool</b>. Dar nu aduce si nici nu scoate informatie, ci doar grabeste sau decelereaza informatia.
                        </li>

                        <li>
                            <b>Sleep Tool (Y)</b>
                            <br/>
                            Se foloseste doar in cadrul unui singur clip si numai daca are una din margini (din stanga sau din dreapta) taiate.
                            <br/>
                            De exemplu, daca taiem 3 secunde din inceputul si 3 secunde din sfarsitul unui clip, atunci Sleep Tool:
                            <ul>
                                <li>va pastra selectia de timp pe care am facut-o</li>
                                <li>va schimba inceputul si sfarsitul clipului in limita informatie care a ramas in afara selectiei</li>
                            </ul>
                            Cumva, acest tool <i>aluneca</i> cu totul informatia spre stanga sau spre dreapta, pastrand durata de timp a clipului.
                        </li>

                        <li>
                            <b>Slide Tool (U)</b>
                            <br/>
                            Acest tool, desi selecteza un clip, va avea efecte asupra clipului din stanga si clipului din dreapta.
                            Astfel, mutand un clip spre dreapta, se va lungi clipul din stanga (se modifica sfarsitul clipului din stanga, deci avem informatie noua)
                            si se va micsora clipul din dreapta (se modifica inceptul clipului din dreapta, deci va pierde informatie),
                            fara a modifica durata totala a secventei.
                        </li>

                        <li>
                            <b>Hand Tool (H)</b>
                            <br/>
                            Are rolul de a ne plimba pe parcursul timeline-ului, dar fara a selecta nici un clip.
                        </li>

                        <li>
                            <b>Zoom Tool (z)</b>
                            <br/>
                            Are rolul de a:
                            <ul>
                                <li>mari timeline-ul</li>
                                <li>microra timeline-ul (tinand apasata tasta ALT)</li>
                                <li>face selectii si de face zoom fix pe selectia facuta</li>
                            </ul>
                        </li>

                        <li>
                            <b>Type Tool (T)</b>
                            <br/>
                            Permite scrierea de titluri, texte.
                            <br/>
                            Creaza un layer nou, deasupra clipului activ.
                        </li>

                        <li>
                            <b>Vertical Type Tool (T)</b>
                            <br/>
                            Permite scrierea de titluri, texte pe verticala.
                            <br/>
                            Creaza un layer nou, deasupra clipului activ.
                        </li>

                        <li>
                            <b>Pen Tool (T)</b>
                            <br/>
                            Permite desenarea de diverse forme.
                            <br/>
                            Creaza un layer nou, deasupra clipului activ.
                        </li>

                        <li>
                            <b>Rectangle Tool (T)</b>
                            <br/>
                            Permite desenarea unui dreptunghi.
                            <br/>
                            Creaza un layer nou, deasupra clipului activ.
                        </li>

                        <li>
                            <b>Ellipse Tool (T)</b>
                            <br/>
                            Permite desenarea unui elipse.
                            <br/>
                            Creaza un layer nou, deasupra clipului activ.
                        </li>

                    </ul>

                    <hr/>
                    <b>20. Creare subclipuri </b>
                    <br/>

                    In fereastra Source, putem marca cu in (I) si out (O) in cadrul unui clip. Pentru al face subclip: click dreapta pe clip {"->"} din meniul contextul {"->"} <b>Make Subclip...</b>.
                    <br/>
                    Se va deschide o fereastra in care i se va da un nume: <i>numar_cadru</i>_<i>numar_dubla</i> (ex: 1_1, 1_2, etc)
                    <br/>
                    In fereastra Bin, va apare noul subclip (ca un clip de sine statator).
                    <br/>
                    Ca mod de organizare, se va creea un nou bin (ex: 1) cu secventa orginala si subclipurile ei.

                    <hr/>
                    <b>21. Effect Controls </b>
                    <br/>
                    <br/>

                    Tab-ul <b>Effect Controls</b> ne permite sa alteram proprietati ale clipurilor din timeline:
                    <ul>
                        <li>Motion (Position, Scale (permite Zoom in / Zoom Out), Rotation, etc)</li>
                        <li>Opacity (Opacity, Blend Mode)</li>
                        <li>Audio Effects:
                            <ul>
                                <li>
                                    <i>Volume - Level</i> / acealasi lucru se poate face si in timeline pe clipul audio, marindul si miscand de bara care apare;
                                </li>
                                <li>
                                    <i>Panner - Balance</i> / intr-un sistem stereo, daca ducem spre stanga slider-ul, sunetele se vor auzi mai mult in boxa din stanga;
                                    iar daca ducem slider-ul spre dreapta, sunetrele se vor auzi mai mult in boxa din dreapta;
                                </li>
                            </ul>

                        </li>
                    </ul>

                    Pentru ca Premier sa reincadreze un clip de o rezolutie mai mare intr-o secventa de o rezolutie mai mica:
                    <br/>
                    Click dreapta pe clip {"->"} <b>Scale to Frame Size</b>.

                    <br/>
                    <br/>
                    Se poate modifica pozitia/rotatia/zoom din fereastra de program, daca se selecteaza <b>Motion</b> din tab-ul <b>Video Effects</b> (va apare un chenar ce permite modificarea pozitiei/ratiatia/zoom-ul cu mouse sau drag and drop)
                    <br/>
                    Se poate de asemenea pozitia punctul in jurul caruia sa se faca rotatia (Anchor Point).
                    <br/>
                    <br/>
                    Dublarea unui clip: ALT + click.
                    <br/>
                    <br/>
                    Un alt mod de controla opacitatea este de a merge pe clip si marindul. Va apare o bara. Modificand pozitia barii se modifica si opacitatea.
                    <br/>
                    <br/>
                    Dezactivarea temporala a unui efect se face in tab-ul de Effect Controls, apasand pe iconitza de <i>fx</i> (acesta va apare taiata; apasand inca o data, se va reactiva).

                    <hr/>
                    <b>22. Efecte Video, Video Previews </b>
                    <br/>

                    <br/>
                    Din tab-ul de materiale:
                    <br/>
                    <b>Effects</b> {"->"} <b>Video Effects</b>.
                    <br/> <br/>
                    Efecte:
                    <ul>
                        <li>Distort {"->"} Magnify (efect de lupa)</li>
                        <li>Blur & Sharpen {"->"} Gaussian Blur </li>
                        <li>Stylize {"->"} Roughen Edges</li>
                    </ul>

                    Dupa ce adauga un efect, acesta poate parametrizat din ecranul de Source, tab-ul de <b>Effect Controls</b>, fiind ultima optiune.

                    <br/>
                    <br/>
                    In timeline, linea galbena poate devenii rosie, daca se pun efecte. Acest lucru inseamna ca sistemul poate sa nu redea fluid aceea parte.
                    <br/>
                    Ca solutie, se poate merge in fereastra <i>Program</i> si selecta o rezolutie mai mica decat <i>Full</i>, de exemplu: 1/2 sau 1/4 (pierzand temporal din calitatea clipului)
                    <br/>
                    Alternativ, cu fereatra de <i>Timeline</i> selectata, se apasa <b>ENTER</b> si Premier va randa aceea parte pe HDD.
                    Dupa ce operatiunea se va termina, linia rosie, va devenii verde.
                    <br/>
                    Daca se modifica o proprietatea a unui efect, linia va devenii rosie.
                    <br/>

                    <br/>
                    Locul in care Premier creaza aceste fisiere de randare:
                    <br/>
                    <b>File {"->"} Project Settings {"->"} Scratch Disks</b> din fereatra Project Settings {"->"} tab Scratch Disks {"->"} <b>Video Preview</b>
                    (de preferat aceasta locatie sa fie diferita de ceea pe care este sistemul de operare sau proiectul, pentru ca Premier sa se miste mai repede).

                    <br/>
                    <br/>
                    Pentru a salva unul sau mai multe efecte pentru a putea fi folosit in alt proiect de exemplu, se pot selecta cu CTRL efecte dorite, apoi click dreapta {"->"} <b>Save Preset</b>.
                    In fereastra care apare se da un nume.
                    <br/>
                    Acest nou efect/preset se va regasi in <b>Effects</b> {"->"} <b>Presets</b>.
                    <br/>
                    Pentru a sterge nou efect, se selecteaza, click dreapta {"->"} <b>Delete</b>.

                    <hr/>
                    <b>23. Tranzitii </b>
                    <br/>

                    Tranzitiile se aplica la inceputul si finalul clipurilor sau intre 2 clipuri consecutive.
                    <br/>
                    Pentru a uni doua clipuri printr-o tranzitie trebuie ca clipul sa nu fie chiar de la inceput sau chiar pana la sfarsit,
                    adica sa aiba informatie lipsa la inceput sau la sfarsit pentru a avea informatie din care sa creeeze tranzitia.
                    <br/>
                    Un clip pus chiar de la inceput va fi marcat in sectiunea de timeline ca avand coltul din stanga-sus ingrosat.
                    <br/>
                    Un clip pus chiar pana la inceput va fi marcat in sectiunea de timeline ca avand coltul din dreapta-sus ingrosat.
                    <br/>
                    <br/>
                    Daca se va pune o tranzitie intre 2 clipuri netaiate, Premier Pro va afisa un avertisment:
                    <br/>
                    <i>Insufficient media. This transition will contain repeated frames</i>
                    <br/>
                    Iar tranzitia va fi ca o imagine statica si nu contine miscare.
                    Premier Pro se foloseste de informatia lipsa de sfarsitul clipului precedent si informatia lipsa de la inceptul clipului urmator
                    pentru a face trecerea mai lina.

                    <br/>
                    <br/>
                    O tranzitie se poate lungi sau scurta precum un clip.
                    <br/>
                    Selectand o tranzitie, si mergand in <b>Effect Controls</b> se pot modifica diversi parametrii ai tranzitiei.

                    <br/> <br/>
                    Tranzitii video:
                    <ul>
                        <li>
                            Video Transitions {"->"} Dissolve {"->"} <b>Crosss Dissolve</b> (tranzitie implicita, ceea ce inseamna ca daca suntem la locul de intalnire a doua clipuri si apasam <b>Ctrl + D</b> se va aplica tranzitia)
                        </li>
                        <li>
                            Video Transitions {"->"} Dissolve {"->"} <b>Dip to Black</b>
                        </li>
                    </ul>

                    Daca se selecteaza 2 clipuri si se apasa <b>Ctrl+D</b> tranzitia se va pune la inceput, sfarsit si intre cele 2 clipuri.
                    <br/>
                    O tranzitie implicita este marcata cu un patrat albastru in jurul iconitei care o reprezinta.
                    Pentru a seta alta tranzitie ca fiind implciita: <b>Set Selected as Default Transition</b> pe tranzitia dorita.

                    <br/>
                    <br/>
                    Tranzitii video:
                    <ul>
                        <li>Audio Transitions {"->"} Crossfade {"->"} Constant Power (tranzitie implcita)</li>
                        <li>Audio Transitions {"->"} Crossfade {"->"} Constant Gain</li>
                    </ul>

                    Scurtatura pentru a pune tranzitia audio implicita este: <b>Ctrl+Shift+D</b>

                    <hr/>
                    <b>24. Text / forme </b>
                    <br/>

                    Pentru a adauga text sau forme se foloseste instrumentul <b>Text</b>.
                    <br/>
                    Cu textul selectat din <b>Effect Controls</b> putem modifica proprietatile.
                    <br/>
                    Modificarea proprietatilor se poate face si din <b>Essential Graphics</b> (activarea ecranului de Essential Graphics: Window {"->"} Essential Graphics).
                    <br/>
                    Sau selectat spatiul de lucru <b>Graphics</b>.
                    <br/>
                    <br/>
                    Din <b>Essential Graphics {"->"} tab Edit {"->"} new Layer {"->"} Text | Rectangle | from File...</b>
                    <br/>
                    In cadrul unui Layer se pot grupa mai multe elemente grafice (text, forme). Pentru a face acest lucru, trebuie sa avem selectat un layer si adaugat alt element (text, forma)

                    <br/>
                    O forma se poate construi si folosind <b>Pen Tool</b>.
                    <br/>
                    Daca exista mai multe layere, cel de deasupra are prioritate mai mare.
                    <br/>

                    <br/>
                    Daca vrem sa pastram grupul si sa il folosm in alte parti, alt proiect, atunci se poate exporta:
                    click dreapta pe layer-ul grafic din timeline {"->"} din meniu contexul {"->"} <b>Export As Motion Graphics Template...</b>
                    {"->"} din fereastra care apare {"->"} se alege un <i>nume</i>, o <i>locatie</i> {"->"} <i>Export</i>
                    <br/>
                    Din <i>Essential Graphics</i> {"->"} tab <b>Browse</b> cautand, vom  regasi template-ul salvat mai devreme.
                    <br/>
                    Pentru a-l sterge: click dreapta pe template {"->"} <i>Delete</i>.

                    <br/>

                    <hr/>
                    <b>25. Animatie, keyframe-uri </b>
                    <br/>

                    <ul>
                        <li><b>Effect Controls</b>

                            <br/>

                            Keyframe-urile sunt puncte folosite cand se activeaza <b>Toogle animation</b>.
                            <br/>
                            Pentru a adauga un nou punct (Keyframe) se apasa pe punctul dintre navigarea intre keyframe-uri.
                            <br/>
                            Pentru a dubla un punc: <b>Alt + click stanga mouse</b> pe un keyframe

                            <br/>
                            Pentru a face miscarea mai lina pe plecare: click dreapta pe keyframe {"->"} din meniul contextual {"->"} <b>Temporal Interpolation</b> {"->"} <b>Easy Out</b>
                            <br/>
                            Pentru a face miscarea mai lina pe oprire: click dreapta pe keyframe {"->"} din meniul contextual {"->"} <b>Temporal Interpolation</b> {"->"} <b>Easy In</b>

                        </li>
                        <li>
                            <b>direct pe clip video din timeline</b>
                            <br/>
                            click dreapta pe <b>fx</b> {"->"} din meniul contextual {"->"} Opacity {"->"} Opacity
                            <br/>
                            folosind CTRL + click stanga se pot adauga keyframe-uri pe bara de pe clip si se poate modifica opacitatea intre puncte
                        </li>

                        <li>
                            <b>direct pe audio din timeline</b>
                            <br/>
                            folosind CTRL + click stanga se pot adauga keyframe-uri pe bara de pe clip si se poate modifica volumul intre puncte.
                        </li>
                    </ul>

                    <hr/>
                    <b>26. Export, formate, export pentru diverse platforme </b>
                    <br/>

                    <ul>
                        <li>Save proiect</li>
                        <li>Fereastra de timeline trebuie selectata</li>
                        <li>Marcare In si Out</li>
                        <li>File {"->"} Export {"->"} Media (CTRL+M)</li>
                        <li>Export Settings
                            <ul>
                                <li>Format (H.264, H.265, MPEG2,... (pt video), MP3, AIFF... (pt audio), GIF, PNG... (pt imagini) etc): indica codecul video pe care vrem sa il folosim la export</li>
                                <li>Preset: YouTube 1080p Full HD,....: preia setarile din preset</li>
                                <li>Frame rate (cadenta): 25fps, 24fps..etc</li>
                                <li>Bit rate:
                                <br/>
                                    de exemplu youtube recomanda la o rezolutie de 1080p - 8Mbps (24fps,25fps,30fps) si 12Mbps (48fps,50fps,60fps)
                                    <br/>
                                    de exemplu vimeo recomanda la o rezolutie de 1080p - 10-20Mbps
                                </li>
                                <li>Bitrate Settings: Bitrate Encoding: VBR, 2pass (pentru a scoate cel mai mic fisier posibil - exportul va dura mai mult)</li>
                                <li>Export</li>
                            </ul>
                        </li>
                    </ul>

                    Alternativ, se poate pune intr-o coada/lista cu exporturile: <i>Queue</i> (buton langa cel de <i>Export</i>).
                    <br/>
                    La apasarea acestui buton se va deschide <b>Media Encoder</b>.
                    <br/>
                    Se pot scoate mai multe variante de export apasand <b>Duplicate</b> pe fisierul pentru care vrem alta varianta.
                    <br/>
                    Apoi de pe link-ul <i>Custom</i> de pe coloana <i>Preset</i> se pot modifica setarile de export.
                    <br/>
                    Apoi se apasa pe butonul <i>Play</i> pentru a incepe executia actiunilor de export.


                    <hr/>
                    <b>27. Sincron audio-video</b>
                    <br/>

                    <ul>
                        <li>
                            <b>video (cu sau fara audio) + audio separate</b>
                            <br/>
                            e nevoie de un sunet dupa care sa ne luam; de exemplu inchiderea unei clachete;
                            <br/>
                            pe video se marcheaza cu <b>In (I)</b> momentul in care se inchide clacheta
                            <br/>
                            pe audio se cauta si se marcheaza e marchaaza cu <i>In (I)</i> momentul in care se inchide clacheta, pe sunetul cel mai inalt (un pic inainte).
                            <br/>
                            se selecteaza ambele fisiere cu CTRL {"->"} <b>click dreapta {"->"} Merge clips</b> (apare o fereastra in care setam modul in care sa facem sicronul) si alegem <b>In Points</b>
                            <br/>
                            in plus, vom bifa <b>Remove Audio From AV Clip</b> pentru a scoate audio de pe video daca exista si a ramane doar cu cel de pe lavaliera.

                            <br/><br/>
                            (Pentru a scoate punctele de In si Out {"->"} click dreapta pe clip {"->"} <b>Clear In and Out</b>)
                        </li>
                        <li>
                            <b>video cu audio + audio separate</b>
                            <br/>
                            se selecteaza ambele fisiere cu CTRL {"->"} <b>click dreapta {"->"} Merge clips</b> (apare o fereastra in care setam modul in care sa facem sicronul) si alegem <b>Audio</b>
                            <br/>
                            in plus, vom bifa <b>Remove Audio From AV Clip</b> pentru a scoate audio de pe video daca exista si a ramane doar cu cel de pe lavaliera.
                        </li>
                        <li>
                            <b>video cu audio + audio separate in timeline</b>
                            <br/>
                            selectam video + audio video + audio independent {"->"} click dreapta {"->"} <b>Syncronize</b> {"->"} din fereastra care apare se selecteaza modul de sincronizare {"->"} alegem <b>Audio</b>
                            <br/>
                            pentru a sterge audio din video, o suprascriem cu audio independent (de pe lavaliera)
                        </li>
                    </ul>


                    <hr/>
                    <b>28. Slow-motion </b>

                    <ul>
                        <li>Bara de intrumente: <b>Rate Stretch Tool (R)</b> (lungeste materialul/secventa din clip si <b>nu aduce material in plus</b>)
                            <br/>
                            <i>dar, miscarea devine sacadata</i>
                            <br/>
                            pentru a remedia din miscarea scadata: <b>click dreapta pe clip {"->"} Time interpolation {"->"} Optical Flow</b> (e obtine o miscare un pic mai lina)
                        </li>

                        <li><b>click dreapta pe clip {"->"} Speed/Duration</b>
                            <br/>
                            pentru a remedia din miscarea scadata: <b>click dreapta pe clip {"->"} Time interpolation {"->"} Optical Flow</b> (e obtine o miscare un pic mai lina)
                        </li>

                        <li>
                            pentru a modifica viteza de redare in interiorul unui clip:
                            <br/>
                            click-dreapta pe <b>fx</b> {"->"} din meniul contextual {"->"} <b>Time Remapping {"->"} Speed</b>
                            <br/>
                            apoi cu CTRL sau din bara de intrumente se selecteaza Pen Tool (P) se pune, de exemplu, 2 puncte, pe linia care reprezinta timpul (time remapping);
                            <br/>
                            sectiunea dintre puncte poate fi incetinita (tragand in jos bara intre puncte) sau poate fi redata intr-u mod mai alert (tragand in sus bara intre puncte)
                            <br/>
                            trecerea de la miscare normala la miscare rapida (si invers) se poate face si <b>progresiv</b> prin a trage de marginile punctului pus (marker) si spargand-ul in 2 (inceput trece, sfarsit trecere)
                            <br/>
                            trecerea progresiva este <i>liniara</i>; daca se doreste o trecere mai lina (curbata), atunci putem realiza acest lucru tragand/rotind de <i>punctul care apare intre marcarul spart</i>
                            <br/>
                            pentru a sterge trecerea progresiva, se selecteaza unul din capete si sa apasa tasta <b>DEL</b>
                            <br/>
                            pentru a sterge marker-ul se selecteaza si sa apasa tasta <b>DEL</b>
                            <br/>
                            <br/>
                            pentru a iesi din modul  <b>Time Remapping</b> si a intra din nou in <b>slider-ul de opacitate</b>:
                            <br/>
                            click-dreapta pe <b>fx</b> {"->"} din meniul contextual {"->"} <b>Opacity {"->"} Opacity</b>
                            <br/>
                            <br/>
                            modul <b>Time Remapping</b> se poate face si din Effect Controls, nu doar de pe clip
                        </li>
                    </ul>

                    <i>Observatie:</i>
                    <br/>
                    Daca avem un clip filmat la 60fps (slow-motion)..vrem sa il redam la 24fps, aceea ce rezulta ca il putem incetini de 2.5x ori (miscarea naturala)

                    <br/>

                    <hr/>
                    <b>29. Crop </b>
                    <br/>
                    <b>Effects {"->"} Video Effects {"->"} Transform {"->"} Crop</b>
                    <br/>
                    Apoi din Effect Controls {"->"} Crop (se pot modificat Left, Top, Right, Bottom, Edge Feather si utiliza keyframe-uri)

                    <hr/>
                    <b>30. Clonare, masti </b>

                    <br/>
                    <br/>
                    Clonarea se poate face in 2 moduri (cele 2 clipuri trebuie filmate avand acelasi punct de statie - nemiscat):
                    <ul>
                        <li>crop (Effects {"->"} Video Effects {"->"} Transform {"->"} Crop; apoi cu Left - daca clipurile sunt destul de bine delimitate)</li>
                        <li>masca (<b>Effect Controls- {"->"} Opacity {"->"} creare masca (Free draw bezier)</b> si apoi folosind <b>Pen Tool</b> desena masca)</li>
                    </ul>

                    <hr/>
                    <b>31. Chroma </b>
                    <br/>
                    <br/>

                    Se trage urmatorul efect peste clipul nostru:
                    <ul>
                        <li>
                            <b>Effects {"->"} Video Effects {"->"} Keying {"->"} Ultra Key</b>
                        </li>
                    </ul>
                    Apoi:
                    <ul>
                        <li>Effect Controls {"->"} Ultra Key</li>
                        <li>Key Color {"->"} se selecteaza Eye dropper{"->"} apoi se alege culoarea chromei pe care vrem s-o eliminam (se alege un verde de tonalitate medie);
                            <br/>
                            dupa pasul de mai sus, chroma este inlocuita de fundal negru
                        </li>

                        <li>trecem din <b>Output {"->"} Composite</b> in <b>Output {"->"} Alpha Channel</b>
                            <br/>
                            dupa pasul de mai sus, se afiseaza imaginea alb negru in care alb este subiectul care trebuie sa ramana si negru ce v-a fi inlocuit.
                        </li>

                        <li>trebuie sa aducem negru sa fie cat mai negru si albul cat mai alb:
                            <ul>
                                <li>
                                    <b>Matte Generation {"->"} Shadow</b> (modificam fara afectam subiectul)
                                </li>
                                <li>
                                    <b>Matte Generation {"->"} Pedestral</b> (se poate da la maxim)
                                </li>
                            </ul>

                        </li>
                        <li>
                            trecem din <b>Output {"->"} Alpha Channel</b> in <b>Output {"->"} Composite</b> si verificam ce a iesit
                            <br/>
                            uneori se observa o margine verde pe subiect si pentru a o elimina se efectuaza urmatorii 2 pasi:
                            <ul>
                                <li>
                                    <b>Split Suppression {"->"} Spill</b> (dat spre dreapta aproape de maxim)
                                </li>
                                <li>
                                    <b>Split Suppression {"->"} Luma </b>(dat spre dreapta aproape de maxim)
                                </li>
                            </ul>
                        </li>
                        <li>pentru a adauga in spatele personajului un fundal:
                            <ul>
                                se duce clipul chroma pe un track superior
                            </ul>
                            <ul>
                                se duce mai jos fundalul (poze, video - Scale To Frame Size)
                            </ul>
                        </li>

                    </ul>

                    <b>Observatie:</b>
                    <br/>

                    Se poate elimina din partile chromei folosind:
                    <ul>
                        <li>Efectul Crop</li>
                        <li>Masca din Opacity</li>
                    </ul>
                    Efectul de Ultra Key va trebui mutat dupa Efectul de Crop.

                    <br/>
                    <br/>
                    <b>Observatie:</b>
                    <br/>
                    Pentru chromele cu iluminare slaba:
                    <ul>
                        <li>
                            Se dubleaza clipul (ALT si click stanga) punand de fiecare deasupra de mai multe ori (de exemmplu de 4 ori)
                        </li>
                        <li>
                            se dezactiveaza pe primele 3
                        </li>
                        <li>
                            se pune cropul peste toate clipurile dublate (astfel incat sa se imparta poza in 4 parti: stanga-sus, dreapta-sus, stanga-jos, dreapta-jos);
                            <br/>
                            daca se activeaza toate vom avea imaginea completa
                        </li>

                    </ul>
                    Efectul de Ultra Key va trebui mutat dupa Efectul de Crop. Astfel Ultra Key se ocupa pe bucati, pentru fiecare colt in parte (din cele 4 colturi)

                    <hr/>
                    <b>32. Nest </b>
                    <br/>

                    Pentru a grupa mai multe clipuri si folosi ca un tot unitar (sau a selecta un segment de clipuri care sunt pe track-uri diferite):
                    <ul>
                        <li>se selecteaza segmentul sau clipurile</li>
                        <li>click dreapta {"->"} <b>Nest</b></li>
                        <li>din fereastra care va apare, se poate da un nume acestei grupari</li>
                        <li>si va apare ca un singur clip</li>
                        <li>mai mult decat atat se va regasi printre bin-urile noastre ca o <i>secventa</i></li>
                    </ul>

                    La dublu click pe secventa (sau pe clipul nested) se va deschide secventa si putem face modificari in interior ei.
                    <br/>
                    Daca se efectueaza modificari si ne intoarcem in secventa nest acestea se vor reflecta si in secventa nest
                    (de exemplu, daca scurtam clipul de la sfarsit, secventa nest va marca acest lucru prin hasurare la sfarsit).

                    <br/>
                    <br/>

                    <b>Observatie:</b>
                    <br/>
                    avantaje in folosirea unei secvente nest:
                    <ul>
                        <li>aceasta este tratata ca un clip normal si poate fi deschis si in zona <b>Source</b>.</li>
                        <li>poate fi folosita in cadrul altor secvente (se poate insera de exemplu cu <i>Insert(,)</i> din zona <b>Source</b>)
                            <br/>
                            Daca se secventa nou inserata are alta rezolutie se poate folosi: <b>Scale to Frame Size</b>
                        </li>
                    </ul>
                    <b>Observatie:</b>
                    <br/>
                    Toate modificarile pe o seventa nest (crop, position, scale, efecte, etc) se aplica pe toata secventa, nu doar pe unul dintre clipurile componente.

                    <br/>
                    <br/>
                    <b>Observatie:</b>
                    <br/>
                    Chiar daca track-urile audio nu par incluse in secventa nest, aceste sunt incluse!

                    <hr/>
                    <b>33. Freeze frame </b>

                    <br/>
                    Variante:
                    <ul>
                        <li>Export Frame (CTRL+Shift+E): se exporta o poza, care apoi va fi insearata in clip</li>
                        <li>Insert Frame Hold Segment (cu click dreapta pe clip): insereaza o bucata de clip cu fotograma din dreptul cursorului</li>
                        <li>Add Frame Hold (click dreapta pe clip) - adauga o bucata de clip cu fotograma </li>
                    </ul>

                    <hr/>
                    <b>34. Stabilizare</b>

                    <br/>
                    <b>Effects</b> {"->"} Video Effects {"->"} Distort {"->"} <b>Warp Stabilizer</b>

                    <br/>
                    Optiuni:
                    <br/>
                    Metode de stabilizare ( <b>Effect Controls: Warp Stabilizer {"->"} Method</b>):
                    <ul>
                        <li>Position</li>
                        <li>Position, Scale, Rotation</li>
                        <li>Perspective (tinde sa lungeasca artificial, spre a mentine proportiile)</li>
                        <li>Subspace Warp (e una dintre metodele cele mai eficiente de stabilizare; tinde sa indoaie liniile orizontale si verticale)</li>
                    </ul>

                    <hr/>
                    <b>35. Creare Proxy-uri</b>
                    <br/>
                    <br/>

                    Daca avem un fisier de mari dimensiuni, acesta va fi redat sacadat pe un calculator mai modest.
                    Pentru a rezolva acest lucru, Premier vine cu 2 solutii:
                    <ul>
                        <li><b>Select Playback Resolution</b> (din ecranul Source), schimband din Full in 1/2 (rezolutie la jumatate) sau 1/4 sau 1/8 sau 1/16</li>
                        <li>fisiere tip Proxy (fisier indentic cu originalul cu o rezolutie mai mica, de exemplu de 4 ori mai mica)
                            <br/>
                            Pentru a face acest lucru, din <b>Adobe Media Encoder</b>, fereastra <b>Preset Browser</b>
                            <br/>
                            {"->"} + {"->"} <b>Create Encoding Preset</b> {"->"} din fereatra Preset Settings, se da un nume si:
                            <ul>
                                <li>se debifeaza: <b>Width: Based On Source</b> si se introduce rezolutia dorita de noi (de exemplu putem imparti rezolutia orginala la 4 si introducem valorile obtinute la width si height)</li>
                                <li>se debifeaza: <b>Frame Rate: Based On Source</b> si se introduce frame rate dorit de noi (de exemplu 24)</li>
                                <li>se debifeaza: <b>Field Order: Based On Source</b> si se alege Progressive</li>

                                <li>din sectiunea <b>Bitrate Settings</b> {"->"} <b>Bitrate Encoding {"->"} VBR, 2 pass</b></li>
                            </ul>
                            {"->"} + {"->"} <b>Create Ingest Preset</b> {"->"} din fereatra Ingest Settings, se da un nume (noua rezoltuie, de exemplu: 1536x648@24 (6144x2592)) si:
                            <ul>
                                <li>Se selecteaza <b>Transcode files to Destination</b>;
                                    <br/>
                                    Se alege: Destination, Format (H.264), Preset (se alege presetul creat mai sus)
                                </li>
                            </ul>
                            Vor aparea noile preseturi create de noi. Pe presetul <i>1536x648@24 (6144x2592)</i> se da click dreapta {"->"} din meniul contextul {"->"} <b>Export Presets...</b> si se da un nume

                            <br/>
                            <br/>
                            Inapoi in Premier, click dreapta pe clipul nostru {"->"} din meniul contextual {"->"} <b>Proxy {"->"} Create Proxies...</b> {"->"} iar din fereastra care apare {"->"} <b>Add Ingest Prest</b> {"->"} se selecteaza fisierul cu presetul salvat mai sus;
                            se alege apoi Destination si sa apasa pe butonul OK.
                            <br/>
                            Dupa aceasta operatie, <b>Adobe Media Encoder</b>, in background va incepe sa creeze proxiul.
                            <br/>
                            <br/>
                            Din fereastra Source, apasam pe butonul + {"->"} cautam butonul de <b>Toogle Proxies</b>, pe care il vom trage in bara.
                            <br/>
                            Daca vom incerca sa redam clipul vom vedea ca sunt aceleasi probleme de randare, dar daca se apasa pe butonul de <b>Toogle Proxies</b> si vom incerca din nou, redarea va deveni fluida.
                            <br/>
                            Butonul de <b>Toogle Proxies</b> comuta de la fisierul original la fisierul proxy (copia facuta dupa specificatiile noastre).
                            <br/>
                            <br/>
                            Se poate alege o rezolutie de 4 ori mai mica sau doar o injumatati, dar ceea ce trebuie pastrat sunt proportiiile clipului original, precum si specificatiile acestuia: Progresiv, intercalat, fps, etc.
                            <br/>
                            <br/>
                            In faza de export, Premier va lucra cu fisierele originale si nu va depinde de cele proxy, indiferent de optiunea <b>Toogle Proxies</b>.
                        </li>

                    </ul>

                    <hr/>
                    <b>36. Secventa multicamera</b>
                    <br/>
                    <br/>

                    Seventele multicamera ne ajuta la a monta un material care a fost inregistrat cu mai multe camere, pozitionate in diverse unchiuri.
                    Meterialul il vrem montat, alternand intre camerele cu care a fost inregistrat.

                    Pasi:
                    <ul>
                        <li>Se selecteaza toate clipuri care au rezultat de la toate camere (acelsi lucru se poate si pe un folder care contine toate clipurile), pentru a se sincroniza:
                            <br/>
                            click dreapta {"->"} din meniu contextual {"->"} <b>Create Multi-Camera Source Sequence....</b>
                            <br/>
                            in fereastra care apare, se seteaza:
                            <ul>
                                <li>nume</li>
                                <li>modul in care camere se vor sincroniza: <b>Synchronize Point</b>: Audio (daca toate au track sonor)</li>
                                <li>bifare optiune de a muta clipul procesat intr-un bin nou: <b>Move source clips to Processed Clips bin</b></li>
                                <li>Audio Channels Preset: Stereo</li>
                            </ul>
                            Dupa procesare in bin-ul nostru vom avea clipurile procesate (in bin-ul <i>Processed Clips</i>), plus un clip nou care contine toate camerele sincronizate.
                        </li>
                        <li>
                            Pe acest clip nou care contine toate camerele sincronizate, se da click dreapta {"->"} din meniu contextual {"->"} New Sequence From Clip.
                        </li>
                        <li>
                            Pentru a activa modul de vedere multicamera, se da din fereastra Program pe +,
                            se alege butonul de <b>Toogle Multi-Camera View</b> si se trage pentru a fi adus pe bara de instrumente a ferestrei Program; si vom apasa pe el;
                            <br/>
                            Fereastra Program va avea 2 zone; o zona in care vom vederea toate camere sincronizate; si o zona cu informatia din camera selectata.
                        </li>
                        <li>
                            Camerele sincronizate care apar in Fereastra Program pot fi invocate apasand tasta 1,2,3,etc in functie de camera.
                            Pentru a incepe sa montam, dam play si de fiecare data cand simtim nevoia sa schimbam camera, apasam pe cifra corespunzatoare camerei.
                            <br/>
                            Putem modifica ordinea camerelor din Settings (fereastra Program) {"->"} din meniul contexual {"->"} <b> Edit Cameras</b> (putem schimba ordinea; le putem activa sau dezactiva)
                            <br/>
                            Dupa ce vom da stop, vom observa, pe timeline, ca la fiecare modificare de camera (apasare de taste 1,2,3, etc) s-a facut o taietura si s-a inserat informatie din camera selectata.
                        </li>
                        <li>
                            pentru a vedea o reprezentare grafica a camerelor si a altor informatii, din fereatra Program {"->"} Settings {"->"} din meniul contextual {"->"} <b>Overlays</b>.
                            <br/>
                            Vom vedea numerele camerelor, precum si anumite date despre camerele active si track-ul audio.
                            <br/>
                            Pentru a avea informatiile de mai sus, in timp ce camerele merg, din fereatra Program {"->"} Settings {"->"} din meniul contextual {"->"}
                            <b>Overlay Settings</b> {"->"} <b>Settings</b> {"->"} din fereastra care apare {"->"} se bifeaza <b>Enable overlays during playback</b>
                        </li>
                        <li>
                            pe cut-urile / taiturile facute se mentioneza camera activa (in perioada cut-ului respectiv).
                            <br/>
                            daca vrem sa schimbam camera activa pe un anumit cut, selectam cut-ul, click dreapta {"->"} din meniul contexual {"->"} <b>Multi-Camera</b> {"->"} selecta alta camera (camera dorita).
                        </li>
                        <li>
                            folosind, din bara de instrumente, <b>Rolling Edit Tool (N)</b> si schimba punctul de cut (mai devreme sau mai tarziu)
                        </li>
                        <li>
                            pe cut-urle facute, se pot aplica tranzitii
                        </li>
                    </ul>


                    <hr/>
                    <b>37. Marker, label</b>
                    <br/>
                    <br/>

                    Instrumente utile in organizare:
                    <ul>
                        <li>Marker</li>
                        <li>Label</li>
                    </ul>

                    Un instrument util in organizare este Marker.
                    <br/>
                    Adugare marker, din fereastra Program: click pe butonul <b>Add Marker</b> (sau <b>M</b>).
                    Markerul se va adauga in timeline (culoare versuie). La dublu click pe el sau click drepta {"->"} Edit Marker, din fereastra care apare se poate seta:
                    <ul>
                        <li>nume</li>
                        <li>durata</li>
                        <li>culoarea</li>
                    </ul>
                    Marker-ul se poate lungi pe toata durata pe care vrem s-o marcam.

                    <br/>
                    <br/>
                    Daca avem un audio, putem pune un marker, apasand M, de fiecare data cand se aude un bit intalnit.
                    Sa presunem ca am adaugat 9 marcare. Acum, vom selecta 9 clipuri, apasam pe butonul din ecranul Bin: <b>Automate to Sequence</b> si din fereastra care apare:
                    <ul>
                        <li>Ordering: Sort Order</li>
                        <li>Placement: At Unnumbered Markers (pentru a le plasa pe marcherele puse)</li>
                        <li>Method: Overwrite Edit: pentru a suprasrie informatia in cazul in care 2 machere se intercaleaza</li>
                    </ul>

                    Daca avem o seventa care contine de exemplu 3 cursuri, punem cate un marker la inceputul fiecarui curs (fara a umbla la durata).

                    <br/>
                    <br/>
                    O alta functie utila in etichetarea brutului, sunt <b>label</b>-urile. Acestea se pot configura din meniu:
                    <br/>
                    <b>Edit {"->"} Preferences {"->"} Labels</b>. Din fereastra care apare, se pot da denumiri mai sugestive pentru culorile folosite in etichetare.
                    Daca se da dublu click pe o culoare, se poate si modifica nuanta.
                    <br/>
                    Din Timeline, se pot selecta cateva clipuri, click dreapta {"->"} din meniul contextual {"->"} <b>Label</b> alege eticheta dorita.
                    <br/>
                    Culoare clipurilor selectate se va schimba in culoare etichetei alese.
                    <br/>
                    Un exemplu, in care pot fi utile etichetele este etichetarea dublelor (dubla buna, dubla excelenta, dubla inutilizabila).

                    <hr/>
                    <b>38. Editare audio, dialog, muzica</b>
                    <br/>
                    <br/>

                    Dupa ce am sincronizat audio de pe lavaliera cu audio de pe video, nu mai avem nevoie de audio pe de pe video.
                    Asa ca putem selecta audio (lavaliera), si tinand SHIFT pentru a nu ne deplasa stanga-dreapta urcam cu mouse-ul audio (lavaliera) peste audio video.

                    <br/>
                    <br/>

                    Se poate aduga pe alt track alt audio ca ambianta si se va pune sub audio-lavaliera.

                    <br/>
                    <br/>
                    Urmarind pe audiometru putem decide daca e nevoie de a ajusta volumul la audio.
                    <br/>
                    Daca suma celor 2 trak-uri audio loveste in 'tavanu' audiometroului, atunci se poate produce in boxe o distorisie.

                    Pentru a rezolva acest lucru, mergem in:
                    <br/>
                    <b>Audio Clip Mixer</b> unde avem o reprezentare a celor 2 trak-uri audio.
                    <br/>
                    <br/>
                    <b>Vocea trebuie sa bata pana la -6db</b>
                    <br/>
                    In <b>Audio Clip Mixer</b> putem mari/microra un pic audio cu vocea mutand slider-ul astfel incat sa bata in jurul valorii de -6db.
                    <b>Muzica de fundal trebuie sa bata pana la -12db</b>
                    In <b>Audio Clip Mixer</b> putem mari/microra un pic audio cu ambianta mutand slider-ul astfel incat sa bata in jurul valorii de -12db.
                    Poate fi data si mai jos pentru a nu intra in concureta cu vocea personajului.

                    <br/>
                    <br/>
                    Pentru a face modificari mai avansate: <b>Window {"->"} Essential Sound</b>.
                    Cu track-ul audio de lavaliera, se poate alege <b>Dialog</b>.
                    <br/>
                    Se poate bifa:
                    <ul>
                        <li>Mute (se poate face si din Timeline)</li>
                        <li>Level (volum, asa cum se poate face si din Audio Clip Mixer)</li>
                        <li>Reverb (ecou), alegand si un Preset (ex: Church, Large Refletive Room, Warm Room etc)</li>
                        <li>Enchance Speach (imbunatatire voce), cu optiunile: Female / Male</li>
                        <li>EQ cu alegerea unui Preset (Old Radio, Vocal Presence, etc); Premier modificara freventele in functie de preset-ul ales.</li>
                        <li>Amount: se poate ajuta nivelul pe care il adauga egalizatorul (EQ)</li>
                        <li>Laudness {"->"} Auto Match: Premier va seta un anumit nivel care sa corespunda unui anumit standard (pt televiune de exemplu)</li>
                        <li>Repair: pentru a face corectii, daca sunetul este afectat de
                            <ul>
                                <li>DeHum: curent electric / bazait + selecta frecventa pe care suntem (50Hz - pt Romania /60Hz - pt SUA)</li>
                                <li>Reduce Reverb: ecou</li>
                                <li>Reduce Noise: zgomot in fundal</li>
                                <li>Reduce Rumble: bubuit</li>
                            </ul>
                        </li>
                        <li>Clarity: bifa Dynamics : seta dinamica sau prezenta vocala</li>
                        <li>Preset: selectie Preset potrivita (In a Large Room, From the Radio, Balance Male Voice, etc)</li>
                        <li>butonul <b>Clear Audio Type</b> scoate toate imbunatirile facute si reseta la valorile originale</li>
                    </ul>

                    Cu track-ul audio de ambianta, se poate alege <b>Music</b>.
                    <ul>
                        <li>Laudness {"->"} Auto Match: pentru a egaliza cat de tare este pe parcursul clipului
                            <br/>
                            Premier a adus varfurile si punctele cele mai de jos (din audio ambianta) un pic mai apropiate astfel incat sa nu existe momente in care
                             sa nu auzim melodia fata de voce sau ca melodia sa fie mult prea puternica fata de voce. A crescut minimul de frecvente si a redus freventele cele mai inalte.
                            <br/>

                        </li>
                    </ul>


                    In timeline, apasand pe butonul S (solo) se va putea asculta numai track-ul audio care are marcat S.

                    <br/>
                    <br/>
                    <b>Diferenta dintre voce si muzica de fundal trebuie sa fie de -6db</b>

                    <hr/>
                    <b>39. Colorizare Premiere Pro (lumetri color)</b>
                    <br/>
                    <br/>

                    <b>Window {"->"}Lumetri Color</b>
                    <br/>
                    <br/>
                    Se poate coloriza in 2 moduri:
                    <ul>
                        <li>direct pe clip</li>
                        <li>folosind un <b>Adjustment Layer</b> (click dreapta in fereastra de Proiect {"->"} meniu contextual {"->"} <b>New Item</b>  {"->"} <b>Adjustment Layer</b>)</li>
                    </ul>

                    <b>Window {"->"}Lumetri Scopes</b>
                    <br/>
                    Din ecranul de Lumetri Scopes {"->"} Settings {"->"} <b>Waveform (RGB)</b>;
                    graficul prezinta de la cele mai intunecate parti (jos) pana la cele mai luminoase parti (sus).

                    <hr/>

                    Din <b>Lumetri Color</b> se poate regla (toate modicarile se se regasi si in <b>Effect Controls {"->"} Lumetri Color</b>):
                    <br/>
                    Sectiunea <b>Basic Correction</b>:
                    <ul>
                        <li>White Balance
                            <ul>
                                <li>
                                    Temperature - poate face o imagine mai rece sau mai calda
                                </li>
                                <li>
                                    Tint - putem corecta diverse deviatii ale luminii, daca deviaza spre magenta, putem sa echilibram adaugand verde, si invers
                                </li>
                            </ul>
                          </li>
                        <li>Tone:
                            <ul>
                                <li>Exposure: regleaza luminozitoatea pe intreg spectrul</li>
                                <li>Contrast: adauga o separatie mai accetuata intre partile luminoase (facandu-le mai luminoase) si partile intunecate (facandu-le mai intunecate);
                                    <br/>
                                    partea de mijlocul cumva ramane nemodificata
                                    <br/>
                                    o imagine cu mai putin contrast e o imagine mai fada
                                </li>
                                <li>Highlights: se ocupa cu partile luminoase din imagine</li>
                                <li>Shadows: se ocupa ci partile intunecoase din imagine</li>
                                <li>White: putem face imaginea mai deschisa decat era permis inainte</li>
                                <li>Blacks: putem face imaginea mai inchisa decat era permis inainte</li>
                                <li>Saturation: satureaza toate culorile prezente, tinde sa imbogateeasca culorile calde, lasand in urma culorile reci (nunate de verde, albastru),
                                    marindu-le si pe ele, dar nu la fel de mult ca pe cele calde (rosu, portocaliu, galben)</li>
                            </ul>
                        </li>
                    </ul>
                    <br/>
                    Sectiunea <b>Creative</b>:
                    <ul>
                        <li>Look: putem alege un look pentru imagine</li>
                        <li>Intensity: regleaza intensitatea efectului de la look</li>
                        <li>Adjustments:
                            <ul>
                                <li>Faded Film: aspectul unei pelicule vechi care a inceput sa se incetoseze (cumva similar cu Contrast)</li>
                                <li>Sharpen: face imaginea mai clara</li>
                                <li>Vibrance: tinde sa satureze mai putin culorile calde si sa le atace mai mult pe cele reci (albastre si verzi)</li>
                                <li>Saturation: acelasi efect ca la <b>Basic Correction</b></li>
                                <li>Shadow Tint: tinde sa adauge nuante in zonele de umbra (daca vrem sa adaugam albastru in zonele de umbra, tragem mai mult spre albastru)</li>
                                <li>Highlight Tint: tinde sa adauge nuante in zonele luminoase (daca vrem sa facem zonele mai luminoase mai calde, putem trage mai mult spre portocaliu)</li>
                                <li>Tint Balance: balance-ul tint-urilor (cat de mult intra in partea de umbre si cat de mult intram in partea de lumina)</li>
                            </ul>
                        </li>
                    </ul>

                    <br/>
                    Sectiunea <b>Curves</b>:
                    <ul>
                        <li>linia de luminata, corespunzatoare Waveform-ului (se pot pune puncte si trage si sus si jos, construi o curba de contrast - curba S)</li>
                        <li>daca doarim putem sa umblam chiar pe cananele de rosu (pt a adauga/scoate mai mult rosu in/din umbre, de exemplu), verde, albastru</li>
                    </ul>

                    <br/>
                    Sectiunea <b>Color Wheels & Match</b>; putem adauga nunate si modifica luminanta in partile urmatoare din imagine:
                    <ul>
                        <li>Shadows (ocupa in imagine de la 0%-30% - partea de jos)</li>
                        <li>Midtones (30%-60% - pielea umana)</li>
                        <li>Highlight (60%-100%)</li>
                    </ul>

                    <br/>
                    Sectiunea <b>HSL Secondary</b>:
                    <ul>
                        <li>se pot selectii de o anumita culoare, folosind <b>Set color</b>
                            (fie alegand o coloare, fie folosind Eye Drop-ul - pipeta - pentru a face selectia de nuanta, saturatie si luminanta)
                            slidererele H, S, L;
                        <br/>
                            pentru a vizualiza zona selectata se bifeaza <b>Color/Gray</b>
                        </li>
                        <li>doar pe selectia facuta mai sus se pot face ajutari de Temperatura, Tint, Contrast, Sharpen, Saturation sau de la Correction o putem face mai albastra sau lucra pe luminata, facand-o mai luminoasa</li>
                        <li>doar pe selectia facuta mai sus se poate face <b>Denoise</b> sau <b>Blur</b> (din subsectiunea <i>Refine</i>)</li>
                    </ul>
                    Se pot face mai multe selectii, folosind un nou Adjustment Layer, pus deasupra celui existent deja.

                    <br/>
                    Sectiunea <b>Vignette</b>:
                    <ul>
                        <ul>Amount: cantitatea</ul>
                        <li>Midpoint: unde este punctul de mijloc (mai stransa sau mai larga)</li>
                        <li>Roundness: cat de rotunda</li>
                        <li>Feather: cat de mare este feather-ul, adica cat de larga este trecerea de la marginea cea mai pronuntata spre exterior, la marginea cea mai putin pronuntata</li>
                    </ul>

                    Daca se da dublu-click pe un cercul unui slider se reseteaza valorile la valorile initiale.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <div>*/}
                    {/*          */}
                    {/*       </div>*/}
                    {/*   </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EditareVideoIntroducereContent;