import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers10LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-10", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Fie in directorul <b>layouts</b>, directorul <i>1-archive</i>.
                    <br/>
                    Aici se vor gasi 2 fisiere:
                    <ul>
                        <li>
                            page.json
                        </li>
                        <li>
                            page-definition.page
                        </li>
                    </ul>

                    Fisierul page.json:
                    <SyntaxHighlighter  showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "hidden": false,\n' +
                            '  "private": false,\n' +
                            '  "name_i18n": {\n' +
                            '    "en_US": "Archive",\n' +
                            '    "ro_RO": "Archiva"\n' +
                            '  },\n' +
                            '  "friendlyURL": "/archive",\n' +
                            '  "system": false,\n' +
                            '  "type": "Content",\n' +
                            '  "typeSettings": [\n' +
                            '\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierul page.json:
                    <SyntaxHighlighter  showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "pageElement": {\n' +
                            '    "pageElements": [\n' +
                            '      {\n' +
                            '        "definition": {\n' +
                            '          "fragmentStyle": {\n' +
                            '            "borderWidth": "0"\n' +
                            '          },\n' +
                            '          "indexed": true,\n' +
                            '          "layout": {\n' +
                            '            "borderWidth": 0,\n' +
                            '            "marginBottom": 0,\n' +
                            '            "marginLeft": 0,\n' +
                            '            "marginRight": 0,\n' +
                            '            "marginTop": 0,\n' +
                            '            "opacity": 100,\n' +
                            '            "paddingBottom": 0,\n' +
                            '            "paddingLeft": 0,\n' +
                            '            "paddingRight": 0,\n' +
                            '            "paddingTop": 0,\n' +
                            '            "widthType": "Fluid"\n' +
                            '          }\n' +
                            '        },\n' +
                            '        "pageElements": [\n' +
                            '          {\n' +
                            '            "definition": {\n' +
                            '              "gutters": true,\n' +
                            '              "indexed": true,\n' +
                            '              "modulesPerRow": 3,\n' +
                            '              "numberOfColumns": 3,\n' +
                            '              "reverseOrder": false,\n' +
                            '              "rowViewports": [\n' +
                            '                {\n' +
                            '                  "id": "landscapeMobile",\n' +
                            '                  "rowViewportDefinition": {\n' +
                            '                    "modulesPerRow": 1\n' +
                            '                  }\n' +
                            '                },\n' +
                            '                {\n' +
                            '                  "id": "portraitMobile",\n' +
                            '                  "rowViewportDefinition": {}\n' +
                            '                },\n' +
                            '                {\n' +
                            '                  "id": "tablet",\n' +
                            '                  "rowViewportDefinition": {}\n' +
                            '                }\n' +
                            '              ],\n' +
                            '              "verticalAlignment": "top"\n' +
                            '            },\n' +
                            '            "pageElements": [\n' +
                            '              {\n' +
                            '                "definition": {\n' +
                            '                  "columnViewports": [\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {\n' +
                            '                        "size": 12\n' +
                            '                      },\n' +
                            '                      "id": "landscapeMobile"\n' +
                            '                    },\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {},\n' +
                            '                      "id": "portraitMobile"\n' +
                            '                    },\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {},\n' +
                            '                      "id": "tablet"\n' +
                            '                    }\n' +
                            '                  ],\n' +
                            '                  "size": 2\n' +
                            '                },\n' +
                            '                "type": "Column"\n' +
                            '              },\n' +
                            '              {\n' +
                            '                "definition": {\n' +
                            '                  "columnViewports": [\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {\n' +
                            '                        "size": 12\n' +
                            '                      },\n' +
                            '                      "id": "landscapeMobile"\n' +
                            '                    },\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {},\n' +
                            '                      "id": "portraitMobile"\n' +
                            '                    },\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {},\n' +
                            '                      "id": "tablet"\n' +
                            '                    }\n' +
                            '                  ],\n' +
                            '                  "size": 8\n' +
                            '                },\n' +
                            '                "pageElements": [\n' +
                            '                  {\n' +
                            '                    "definition": {\n' +
                            '                      "widgetInstance": {\n' +
                            '                        "widgetConfig": {},\n' +
                            '                        "widgetName": "ro_simavi_primaria_galati_repository_PrimariaGalatiRepositoryPortlet"\n' +
                            '                      }\n' +
                            '                    },\n' +
                            '                    "type": "Widget"\n' +
                            '                  }\n' +
                            '                ],\n' +
                            '                "type": "Column"\n' +
                            '              },\n' +
                            '              {\n' +
                            '                "definition": {\n' +
                            '                  "columnViewports": [\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {\n' +
                            '                        "size": 12\n' +
                            '                      },\n' +
                            '                      "id": "landscapeMobile"\n' +
                            '                    },\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {},\n' +
                            '                      "id": "portraitMobile"\n' +
                            '                    },\n' +
                            '                    {\n' +
                            '                      "columnViewportDefinition": {},\n' +
                            '                      "id": "tablet"\n' +
                            '                    }\n' +
                            '                  ],\n' +
                            '                  "size": 2\n' +
                            '                },\n' +
                            '                "type": "Column"\n' +
                            '              }\n' +
                            '            ],\n' +
                            '            "type": "Row"\n' +
                            '          }\n' +
                            '        ],\n' +
                            '        "type": "Section"\n' +
                            '      }\n' +
                            '    ],\n' +
                            '    "type": "Root"\n' +
                            '  },\n' +
                            '  "settings": {\n' +
                            '    "masterPage": {\n' +
                            '      "key": "ibrid"\n' +
                            '    }\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In fisierul <i>site-navigation-menus.json</i> poate exista urmatorul fragment:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "addNewPages": "Yes",\n' +
                            '  "menuItems": [\n' +
                            '    {\n' +
                            '      "friendlyURL": "/archive",\n' +
                            '      "privateLayout": false,\n' +
                            '      "type": "layout"\n' +
                            '    },\n' +
                            '  ],\n' +
                            '  "name": "Header Menu"\n' +
                            '},'}
                    </SyntaxHighlighter>

                    Exemplu (se meniul de exempplu in header.html):
                    <SyntaxHighlighter  showLineNumbers={true} language="json" style={androidstudio}>
                        {'<div class="row">\n' +
                            '    <div class="navbar-content col-12">\n' +
                            '        <svg role="presentation" viewBox="0 0 512 512" class="lexicon-icon bars-menu-icon" id="bars-menu-icon">\n' +
                            '            <use xlink:href="/o/primaria-galati-theme/images/clay/icons.svg#bars"></use>\n' +
                            '        </svg>\n' +
                            '        <nav class="navbar navbar-expand-lg" id="main-menu-nav">\n' +
                            '            <div class="collapse navbar-collapse full-menu" id="navbarNavDropdown">\n' +
                            '                <div class="portlet-content">\n' +
                            '                    <div class=" portlet-content-container">\n' +
                            '                        <div class="portlet-body">\n' +
                            '                            [@liferay_site_navigation["navigation-menu"]\n' +
                            '                            ddmTemplateGroupId=(scopeGroupId)\n' +
                            '                            displayDepth=0\n' +
                            '                            expandedLevels="auto"\n' +
                            '                            preview=false\n' +
                            '                            rootItemLevel=0\n' +
                            '                            rootLayoutType="absolute"\n' +
                            '                            siteNavigationMenuId=(header_menu_id)\n' +
                            '                            /]\n' +
                            '                        </div>\n' +
                            '                    </div>\n' +
                            '                </div>\n' +
                            '            </div>\n' +
                            '        </nav>\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    In clasa <b>PGLGlobalTemplateContextContributor</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="json" style={androidstudio}>
                        {'private static final String HEADER_LINKS = "Header Menu";\n' +
                            'long headerMenuId = getSiteNavigationMenuIdByName(themeDisplay.getScopeGroupId(), HEADER_LINKS);\n' +
                            '\n' +
                            'contextObjects.put("header_menu_id", headerMenuId);'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"}href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/embedding-portlets-and-setting-preferences-in-a-liferay-7-2-theme/maximized"}>
                                Embedding portlets and setting preferences in a Liferay 7.2 theme
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers10LiferayContent;