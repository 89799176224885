import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class CustomPluginGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-custom-plugin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Vom creea un plugin scris in Java pentru Gradle, care va afisa data curenta.
                    <hr/>
                    <b>1. Construire structura plugin</b>
                    <br/>
                    <br/>
                    Se creeaza un director nou <b>show-date</b> (un modul/proiect care va reprezenta plugin-ul). Aici se creeaza urmatoarea structura de foldere: <b>src/main/java</b>.

                    <br/>
                    Aici se creeaza clasa <i>ShowDate</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package kj;\n' +
                        '\n' +
                        'import org.gradle.api.tasks.TaskAction;\n' +
                        'import org.gradle.api.DefaultTask;\n' +
                        'import java.util.Date;\n' +
                        '\n' +
                        'public class ShowDate extends DefaultTask{\n' +
                        '\t\n' +
                        '\t@TaskAction\n' +
                        '\tpublic void show(){\n' +
                        '\t\tSystem.out.println("Data curenta este: "+new Date());\n' +
                        '\t}\n' +
                        '\t\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Tot aici se creeaza clasa <i>ShowDatePlugin</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package kj;\n' +
                        'import org.gradle.api.Plugin;\n' +
                        'import org.gradle.api.Project;\n' +
                        '\n' +
                        'public class ShowDatePlugin implements Plugin<Project>{\n' +
                        '\t\n' +
                        '\t@Override\n' +
                        '\tpublic void apply(Project project){\n' +
                        '\t\tproject.getTasks().create("showDate", ShowDate.class);\n' +
                        '\t}\n' +
                        '\t\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a permite lui Gradle să găsească implementarea pluginului (ShowDatePlugin), trebuie să creăm un fișier de proprietăți în:
                    <br/>
                    <b>src/main/resources/META-INF/gradle-plugins</b>
                    <br/>
                    Fisierul de proprietati trebuie să aibă un nume care să se potrivească cu id-ul pluginului.
                    <br/>
                    Deci daca id-ul plugin-ului va fi <i>show-date-plugin</i>, atunci numele fisierului va fi <i>show-date-plugin.properties</i>.
                    In acest fisier se va defini clasa de implementare a pluginului:

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'implementation-class=kj.ShowDatePlugin'}
                    </SyntaxHighlighter>

                    Valoare cheii <b>implementation-class</b> va fi numele complet clasei plugin cu tot cu pachet (kj).

                    <hr/>

                    Fisierul <b>build.gradle</b> pentru proiectul <i>show-date</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'plugins{\n' +
                        '\tid \'java\'\n' +
                        '}\n' +
                        '\n' +
                        'version="1.0.0"\n' +
                        '\n' +
                        'dependencies{\n' +
                        '\timplementation gradleApi()\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Fisierul settings.gradle</b>
                    <br/>
                    <br/>

                    Fisierul <b>settings.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'rootProject.name="test-custom-plugin"\n' +
                        '\n' +
                        'include \'show-date\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Fisierul build.gradle (al proiectului)</b>
                    <br/>
                    <br/>

                    Fisierul <b>build.gradle</b> al proiectului:

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'buildscript {\n' +
                        '\tdependencies {\n' +
                        '\t\tclasspath files(\'show-date/build/libs/show-date-1.0.0.jar\')\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'plugins{\n' +
                        '\tid \'java\'\t\n' +
                        '}\n' +
                        '\n' +
                        'apply plugin: \'show-date-plugin\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Testare</b>
                    <br/>
                    <br/>

                    Build:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'gradle build\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s'}
                    </SyntaxHighlighter>

                    Verificare task <i>showDate</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'gradle showDate\n' +
                        '\n' +
                        '> Task :showDate\n' +
                        'Data curenta este: Sun Mar 06 23:56:20 EET 2022\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '1 actionable task: 1 executed'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Extra 1: Plugin in script</b>
                    <br/>
                    <br/>
                    Alta metoda de a creea un plugin este de a-l scrie direct in fisierul de script <b>build.script</b>. De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'public class MyPlugin implements Plugin<Project>{\n' +
                        '\n' +
                        '\tpublic void apply(Project project){\n' +
                        '\t\n' +
                        '\t\tproject.task("task1"){\n' +
                        '\t\t\tdoLast{\n' +
                        '\t\t\t\tprintln("task-1")\n' +
                        '\t\t\t}\n' +
                        '\t\t}\n' +
                        '\t\t\n' +
                        '\t\tproject.task("task2"){\n' +
                        '\t\t\tdoLast{\n' +
                        '\t\t\t\tprintln("task-2")\n' +
                        '\t\t\t}\n' +
                        '\t\t}\n' +
                        '\t\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Si apoi ca sa fie disponibile cele 2 task-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'apply plugin: MyPlugin'}
                    </SyntaxHighlighter>
                    Si apoi se poate executa din linie de comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'gradle task1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Extra 2: Plugin in buildSrc</b>
                    <br/>
                    <br/>
                    In directorul radacina al proiectului se creaza directorul <b>buildSrc</b>.
                    In functie de limbajul in care este scris plugin-ul se creeaza structura corespunzatoare:
                    <ul>
                        <li>src/main/java</li>
                        <li>src/main/groovy</li>
                    </ul>
                    Apoi, se creaza structura pentru pachet: /ro/ibedaria. Si aici se scrie plugin-ul:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'package ro.ibedaria\n' +
                        '\n' +
                        'import org.gradle.api.Plugin\n' +
                        'import org.gradle.api.Project\n' +
                        '\n' +
                        'public class MyPlugin implements Plugin<Project>{\n' +
                        '\n' +
                        '\tpublic void apply(Project project){\n' +
                        '\t\n' +
                        '\t\tproject.task("task-1"){\n' +
                        '\t\t\tdoLast{\n' +
                        '\t\t\t\tprintln("task-1")\n' +
                        '\t\t\t}\n' +
                        '\t\t}\n' +
                        '\t\t\n' +
                        '\t\tproject.task("task-2"){\n' +
                        '\t\t\tdoLast{\n' +
                        '\t\t\t\tprintln("task-2")\n' +
                        '\t\t\t}\n' +
                        '\t\t}\n' +
                        '\t\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Apoi, in fisierul de script <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'import ro.ibedaria'}
                    </SyntaxHighlighter>

                    Si apoi ca sa fie disponibile cele 2 task-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'apply plugin: MyPlugin'}
                    </SyntaxHighlighter>
                    Si apoi se poate executa din linie de comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'gradle task1'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomPluginGradleContent;