import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class DependencyManagementMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-dependency-management", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Dependente</b>
                    <br/>
                    <br/>

                    Dependintele se pun in <b>dependencies</b>.
                    <br/>
                    Fiecare dependinta este definita in <b>dependency</b>, cu urmatoare informatii:
                    <ul>
                        <li>groupId</li>
                        <li>artifactId</li>
                        <li>version</li>
                    </ul>

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<dependencies>\n' +
                            '        <!-- https://mvnrepository.com/artifact/org.apache.commons/commons-lang3 -->\n' +
                            '        <dependency>\n' +
                            '            <groupId>org.apache.commons</groupId>\n' +
                            '            <artifactId>commons-lang3</artifactId>\n' +
                            '            <version>3.12.0</version>\n' +
                            '        </dependency>\n' +
                            '</dependencies>'}
                    </SyntaxHighlighter>

                    Dependintele se in mod implicit in: <i>c:\Users\[nume_user]</i>\<b>.m2</b>.
                    <br/>
                    Intai se cauta in directorul <b>.m2</b> si daca nu il gaseste, il aduce de pe un repository extern.

                    <br/>
                    Pentru a copia dependențele proiectului din depozit (repository) într-o locație definită:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn dependency:copy-dependencies'}
                    </SyntaxHighlighter>

                    * (doar pt Eclipse) Pentru a copia dependintele in <i>Referenced Libraries</i> din Eclipse:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn eclipse:eclipse'}
                    </SyntaxHighlighter>

                    Acum facem <i>clean</i> si <i>install</i> la proiect:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean install'}
                    </SyntaxHighlighter>

                    Acum putem compila clasa/proiectul:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import org.apache.commons.lang3.StringUtils;\n' +
                            '\n' +
                            'public class AppMain {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        System.out.println("AppMain");\n' +
                            '        StringUtils.isEmpty("");\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Dependente tranzitive</b>
                    <br/>
                    <br/>

                    Să presupunem că se adăugă o dependență A la proiect. Și:
                    <ul>
                        <li>A depinde de o dependență numită B</li>
                        <li>B depinde de o dependență numită C</li>
                    </ul>

                    Din moment ce se utilizeaza A în proiect, este nevoie de B și C.

                    <br/>

                    Cu <i>Maven</i>, este suficient adăugarea în <b>pom.xml</b> numai a lui A. <i>Maven</i> își dă seama că A depinde de B și că B depinde de C.
                    Intern, Maven va descărca automat B și C.

                    <br/>

                    Aici B și C sunt <b>dependențe tranzitive</b>.

                    <br/>
                    <br/>
                    De exemplu, daca avem urmatoarea dependenta:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<dependency>\n' +
                            '            <groupId>junit</groupId>\n' +
                            '            <artifactId>junit</artifactId>\n' +
                            '            <version>4.11</version>\n' +
                            '            <scope>test</scope>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>
                    Cand Maven rezolva dependintele, va aduce si <i>org.hamcrest:hamcrest-core:1.3</i> pentru ca este o dependenta tranzitiva
                    (<i>junit:junit:4.11</i> depinde de <i>org.hamcrest:hamcrest-core:1.3</i>).

                    <hr/>
                    <b>3. Definirea repository-urilor remote</b>
                    <br/>
                    <br/>

                    Daca vom da urmatoare comanda:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:effective-pom'}
                    </SyntaxHighlighter>
                    Se va afisa pom-ul efectiv (adica cu ce mosteneste din parintele implicit).
                    <br/>
                    In sectiunea <b>repositories</b> vom regasi:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {' <repositories>\n' +
                            '    <repository>\n' +
                            '      <snapshots>\n' +
                            '        <enabled>false</enabled>\n' +
                            '      </snapshots>\n' +
                            '      <id>central</id>\n' +
                            '      <name>Central Repository</name>\n' +
                            '      <url>https://repo.maven.apache.org/maven2</url>\n' +
                            '    </repository>\n' +
                            '  </repositories>\n'
                        }
                    </SyntaxHighlighter>

                    Fiecare repository este definit in sectiunea <b>repositories</b> \ <b>repository</b>.

                    <br/>
                    <br/>
                    In <b>[maven]\conf\settings</b> puteam adauga in sectiunea <b>profiles</b> un nou profil:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<profile>\n' +
                            '\t<id>spring_remote_settings</id>\n' +
                            '\t<repositories>\n' +
                            '\t\t<repository>\n' +
                            '\t\t\t<id>spring_repository_settings</id>\n' +
                            '\t\t\t<url>http://repo.spring.io/release/</url>\n' +
                            '\t\t</repository>\n' +
                            '\t</repositories>\n' +
                            '</profile>\n'
                        }
                    </SyntaxHighlighter>
                    Apoi, profilul trebuie activat (tot in <b>[maven]\conf\settings</b> ):
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<activeProfiles>\n' +
                            '\t<activeProfile>spring_remote_settings</activeProfile>\n' +
                            '</activeProfiles>'
                        }
                    </SyntaxHighlighter>

                    Acum, daca vom da urmatoare comanda:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:effective-pom'}
                    </SyntaxHighlighter>
                    Se va afisa si noul repository adaugat in configurarile facute in <b>maven</b>.

                    <hr/>
                    <b>
                        4. Scopul dependintelor
                    </b>
                    <br/>
                    <br/>

                    Scopuri:
                    <ul>
                        <li><b>compile</b> (scopul implicit, si nu e nevoie de a fi specificat)</li>
                        <li><b>provided</b>: similar cu compile, dar se asteapta ca JDK sau un container sa ofere dependinta la runtime</li>
                        <li><b>runtime</b>: nu este necesara la compilare, doar la executie</li>
                        <li><b>system</b>: similar cu provided, dar trebuie dat explicit jar-ul care contine dependinta de care avem nevoie</li>
                        <li><b>test</b>: nu este necesar pentru aplicatie, doar pentru rularea testelor
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'  <dependency>\n' +
                                    '            <groupId>junit</groupId>\n' +
                                    '            <artifactId>junit</artifactId>\n' +
                                    '            <version>4.11</version>\n' +
                                    '            <scope>test</scope>\n' +
                                    '        </dependency>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Daca vom executa urmatoarea comanda, cu flagul <b>-X</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -X compile'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -X text'}
                    </SyntaxHighlighter>
                    se vor afisa mai multe detalii, afisare in mod <b>debug</b> (-X = mod debug).

                    <hr/>
                    <b>
                       5. Rezolutia conflictelor
                    </b>
                    <br/>
                    <br/>

                    Daca in pom-ul parinte avem o dependenta de o anumita versiune, iar in pom-ul copil aceeasi dependinta dar cu alta versiune,
                    Maven va stii sa rezolve acest conflict, utilizand ultima versiune.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DependencyManagementMavenContent;