import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class KeycloakIntroJavaSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-keycloak-intro-spring-security", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Pornire Keycloak</b>
                    <br/>
                    <br/>

                    Din <i>[keycloak-15.0.2]\bin</i> se executa <b>standalone.bat</b>.

                    <br/>
                    <br/>
                    Implicit, porneste:
                    <ul>
                        <li>cu o baza de date <b>H2</b> (baza de date, nerecomandata pentru productie)</li>
                        <li>portul 8080</li>
                    </ul>


                    <hr/>
                    <b>2. Pornire Keycloak cu Postgresql</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            in Postgresql se creaza o schema noua numita <b>keycloak</b>
                        </li>
                        <li>
                            se descarca jar-ul: <b>postgresql-42.5.0.jar</b>(https://jdbc.postgresql.org/download/) <b></b>
                        </li>
                        <li>
                            jar-ul se compiaza in: [keycloak]\modules\system\layers\keycloak\com
                            <br/>

                            aici se creaza o strunctura de tipul
                            <SyntaxHighlighter>
                                {'org\n' +
                                    '│   ├── postgresql\n' +
                                    '│   │   └── main\n' +
                                    '│   │       ├── module.xml\n' +
                                    '│   │       └── postgresql-42.5.0.jar'}
                            </SyntaxHighlighter>
                            unde:
                            <br/>
                            fisierul <b>module.xml</b> va avea urmatorul continut:
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<?xml version="1.0" ?>\n' +
                                    '<module xmlns="urn:jboss:module:1.3" name="org.postgresql">\n' +
                                    ' <resources>\n' +
                                    '  <resource-root path="postgresql-42.5.0.jar" />\n' +
                                    ' </resources>\n' +
                                    ' <dependencies>\n' +
                                    '  <module name="javax.api"/>\n' +
                                    '  <module name="javax.transaction.api"/>\n' +
                                    ' </dependencies>\n' +
                                    '</module>'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se modifica fisierul <b>[keycloak]\standalone\configuration\standalone.xml</b> in sectiunea:
                            <SyntaxHighlighter>{'<subsystem xmlns="urn:jboss:domain:datasources:6.0"> <datasources>'}</SyntaxHighlighter>

                            se modifica <b>datasource</b> cu <i>jndi-name="java:jboss/datasources/KeycloakDS"</i>
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<datasource jndi-name="java:jboss/datasources/KeycloakDS" pool-name="KeycloakDS" enabled="true" use-java-context="true">\n' +
                                    '\t   <connection-url>jdbc:postgresql://192.168.1.8:5432/keycloak</connection-url>\n' +
                                    '\t   <driver>postgresql</driver>\n' +
                                    '\t   <pool>\n' +
                                    '\t\t   <max-pool-size>20</max-pool-size>\n' +
                                    '\t   </pool>\n' +
                                    '\t   <security>\n' +
                                    '\t\t   <user-name>postgres</user-name>\n' +
                                    '\t\t   <password>postgres</password>\n' +
                                    '\t   </security>\n' +
                                    '</datasource>'}
                            </SyntaxHighlighter>

                            se adauga o noua intrare <b>driver</b> in subsectiunea <b>drivers</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<driver name="postgresql" module="org.postgresql">\n' +
                                    '   <xa-datasource-class>org.postgresql.xa.PGXADataSource</xa-datasource-class>\n' +
                                    '</driver>'}
                            </SyntaxHighlighter>
                        </li>
                        <li>la pornirea server-ului se vor crea tabele necesare keycloak-ului (in jur de 90 de tabele)</li>
                    </ul>


                    <hr/>
                    <b>3. Schimbare port Keycloak</b>
                    <br/>
                    <br/>

                    Se modifica in fisierul <b>[keycloak]\standalone\configuration\standalone.xml</b> din:

                    <SyntaxHighlighter>
                        {'  <socket-binding name="http" port="${jboss.http.port:8080}"/>'}
                    </SyntaxHighlighter>

                    in
                    <SyntaxHighlighter>
                        {'  <socket-binding name="http" port="${jboss.http.port:9091}"/>'}
                    </SyntaxHighlighter>

                </div>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://medium.com/keycloak/running-keycloak-using-postgresql-database-d8edc8d22a16"}>
                                    Running Keycloak using PostgreSQL database
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default KeycloakIntroJavaSpringContent;