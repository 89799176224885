import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ContextReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-context", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Context </b>
                    <br/>
                    <br/>

                    Context oferă o modalitate de a transmite date prin arborele de componente fără a fi nevoie să transmiteți props-urile manual la fiecare nivel.

                    <br/>
                    Într-o aplicație tipică React, datele sunt transmise de sus în jos (de la părinte la copil) prin elemente de recuzită,
                    dar acest lucru poate fi greoi pentru anumite props-uri (de exemplu, preferința locală, tema UI) care sunt cerute de multe componente dintr-o aplicație.
                    <br/>

                    Contextul oferă o modalitate de a împărtăși valori între componente fără a fi necesar să treacă în mod explicit prin fiecare nivel al arborelui.
                    <br/>
                    <br/>
                    Creare obiect de Context:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const MyContext = React.createContext(defaultValue);'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createContext} from react\n' +
                            '\n' +
                            'const MyContext = createContext()'}
                    </SyntaxHighlighter>

                    Fiecare obiect Context vine cu o componentă <b>Provider</b> React care permite componentelor consumatoare să se aboneze la schimbările de context.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<MyContext.Provider value={/* some value */}>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemplu 1</b>: (scriem intr-un fisier separat, de exemplu <i>userContext.js</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createContext} from "react";\n' +
                            'const UserContext = createContext();\n' +
                            'export default UserContext;'}
                    </SyntaxHighlighter>

                    apoi, folosim contextul declarat in fisierul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<UserContext.Provider value="Text din context">\n' +
                            '   <MyComponent/>\n' +
                            '</UserContext.Provider>'}
                    </SyntaxHighlighter>

                    apoi, in componenta noastra (MyComponent sau copii acestui) putem accesa valorea <i>Text din context</i>, astfel:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const data = useContext(UserContext);\n' +
                            'console.log(data); // Text din context'}
                    </SyntaxHighlighter>

                    In value, se pot trimite obiecte, liste, functii:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<UserContext.Provider value={{listaUtilizatori, deleteHandler}}>\n' +
                            '   <MyComponent/>\n' +
                            '</UserContext.Provider>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemplu 2</b>: Componenta Provider:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createContext} from "react";\n' +
                            'const StoreContext = createContext({});\n' +
                            '\n' +
                            'const StoreProvider = ({children}) => {\n' +
                            '    const initialState = {\n' +
                            '        culoare: "rosu"\n' +
                            '    }\n' +
                            '\n' +
                            '    return(\n' +
                            '        <StoreContext.Provider value={{state: initialState}}>\n' +
                            '            {children}\n' +
                            '        </StoreContext.Provider>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>
                    si apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<StoreProvider>\n' +
                            '   <Component {...pageProps}  />\n' +
                            '</StoreProvider>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Cazuri de folosire pentru Context</b>
                    <br/>
                    <br/>
                    Cazuri de folosire pentru Context:
                    <ul>
                        <li>tema</li>
                        <li>autentificare utilizator</li>
                        <li>routing</li>
                        <li>gestionarea starii - cand aplicatia devine prea complexa</li>
                    </ul>

                    <hr/>
                    <b>3. Context cu useReducer</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createContext} from "react";\n' +
                            'const StoreContext = createContext({});\n' +
                            '\n' +
                            'export const ACTION_TYPE ={\n' +
                            '    SET_COLOR:"SET_COLOR"\n' +
                            '};\n' +
                            '\n' +
                            '// @ts-ignore\n' +
                            'const storeReducer = (state, action) => {\n' +
                            '    switch(action.type){\n' +
                            '        case ACTION_TYPE.SET_COLOR:{\n' +
                            '            return {...state, culoare: action.payload.culoare}\n' +
                            '        }\n' +
                            '        default:\n' +
                            '            throw new Error("Actiune gresita:"+action.type)\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'const StoreProvider = ({children}) => {\n' +
                            '    const initialState = {\n' +
                            '        culoare: "rosu"\n' +
                            '    }\n' +
                            '\n' +
                            '    const [state, dispatch] = useReducer(storeReducer, initialState);\n' +
                            '    return(\n' +
                            '        <StoreContext.Provider value={{state, dispatch}}>\n' +
                            '            {children}\n' +
                            '        </StoreContext.Provider>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Mod de folosire [setare valoare]:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const {dispatch} = useContext(StoreContext);'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'dispatch({\n' +
                            '  type: ACTION_TYPE.SET_COLOR\n' +
                            '  payload: {culoare: "verde"}\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Mod de folosire [obtinere valoare]:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const {dispatch, state} = useContext(StoreContext);'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let {culoare} = state'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>
                        Exemplu fara Context / cu Context:
                    </b>
                    <br/>
                    <br/>

                    Exemplu fara Context:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'function Component1() {\n' +
                            '  const [user, setUser] = useState("Jesse Hall");\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>{`Hello ${user}!`}</h1>\n' +
                            '      <Component2 user={user} />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component2({ user }) {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 2</h1>\n' +
                            '      <Component3 user={user} />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component3({ user }) {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 3</h1>\n' +
                            '      <Component4 user={user} />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component4({ user }) {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 4</h1>\n' +
                            '      <Component5 user={user} />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component5({ user }) {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 5</h1>\n' +
                            '      <h2>{`Hello ${user} again!`}</h2>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Exemplu cu Context:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState, createContext, useContext } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'const UserContext = createContext();\n' +
                            '\n' +
                            'function Component1() {\n' +
                            '  const [user, setUser] = useState("Jesse Hall");\n' +
                            '\n' +
                            '  return (\n' +
                            '    <UserContext.Provider value={user}>\n' +
                            '      <h1>{`Hello ${user}!`}</h1>\n' +
                            '      <Component2 />\n' +
                            '    </UserContext.Provider>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component2() {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 2</h1>\n' +
                            '      <Component3 />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component3() {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 3</h1>\n' +
                            '      <Component4 />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component4() {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 4</h1>\n' +
                            '      <Component5 />\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'function Component5() {\n' +
                            '  const user = useContext(UserContext);\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h1>Component 5</h1>\n' +
                            '      <h2>{`Hello ${user} again!`}</h2>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ContextReactContent;