import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class LuminantaIluminareVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-comparatie-luminanta-vs-iluminare", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Fotometria</b> este:
                    <ul>
                        <li>parte din domeniul opticii care se ocupa cu masurarea intensitatii luminii si a surselor sale</li>
                    </ul>
                    <hr/>

                    <b>Fluxul de radiatie / puterea de radiatie</b> este:
                    <ul>
                        <li>cantitatea de energie emisa de un corp luminos pe unitate de timp</li>
                    </ul>

                    <hr/>

                    <b>Fluxul luminos / puterea luminoasă (Φ)</b> este:
                    <ul>
                        <li>cantitatea de energie luminoasa masurata prin sensatia vizuala o ochiului (puterea perceputa a luminii - galben-verde este perceput mai puternic decat rosu)</li>
                    </ul>

                    <hr/>

                    <b>Intensitatea luminoasa - Candela (cd)</b> descrie:
                    <ul>
                        <li>fluxul luminos emis intr-un anumit unchi intr-un spatiu tridimensional (in general, sursele de lumina nu emit lumina omogena in toate directiile)</li>
                    </ul>

                    <hr/>
                    <b>Lumenul (lm)</b> este:
                    <ul>
                        <li>(1 lm) - fluxul luminos emis de o sursa punct intr-un unghi solid de 1 sr (steradian = 4π) cu o intensitate a luminii de 1 cd</li>
                    </ul>
                    <hr/>

                    <b>Luminanta (eng. luminance)</b>  este:
                    <ul>
                        <li>cantitatea de lumină detectată de un ochi uman tipic emis de o suprafață plană</li>
                        <li>cantitatea de energie luminoasă produsă de o suprafață sau de un obiect</li>
                        <li>o marime fotometrica ce caracterizeaza o sursa de lumina din punct de vedere a senzatiei ce o produce supra ochiului</li>
                        <li>raportul dintre <i>intensitatea luminoasă (unitatea de masura este candela - cd)</i> a unei surse de lumină și proiecția <i>ariei</i> sursei pe un plan; strălucire</li>
                    </ul>
                    Unitatea pentru măsurarea luminanței este <i>candela pe metru pătrat</i>, care este notată ca <b>cd / m^2</b>

                    <br/>
                    <br/>

                    Luminanța este importanta în fotografie, deoarece descrie cantitatea de lumină care va fi utilizată în formarea fotografiei.
                    <br/>
                    Luminanța de intrare a unui sistem optic ideal este egală cu luminanța de ieșire a sistemului.

                    <br/>
                    Luminanța nu este acealasi lucru cu luminozitate sau iluminarea.

                    <hr/>



                    <b>Iluminarea</b> este:
                    <ul>
                        <li>cantitatea de energie luminoasă care intră pe o anumită suprafață</li>
                        <li>cantitatea de lumină care luminează o suprafața</li>
                        <li>fluxul total de luminanță incident pe o suprafață dată pe unitatea de suprafață</li>
                    </ul>

                    Iluminanța este măsurată în:
                    <ul>
                        <li>lux</li>
                        <li>lumeni pe metru pătrat</li>
                    </ul>

                    <hr/>

                    <b>Temperatura de culoare</b> este:
                    <ul>
                        <li>masoara cat de calda (galbena) sau rece (albastra) este lumina dintr-o anumita sursa</li>
                    </ul>
                    Unitatea de masura este <i>Kelvin</i> si cu cat valoarea este mai mica, lumina este mai calda, si cu cat este mai mai mare este mai rece.

                    <hr/>
                    Omul este mai sensibil la luminozitate decat la culoare.
                    <hr/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://ro.weblogographic.com/difference-between-luminance-and-illuminance-7198"}>Luminanță vs iluminanță - 2022 - ȘTiință și natură</a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LuminantaIluminareVideografieContent;