import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TraitsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-traits", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Traits</b>
                    <br/>
                    <br/>

                    Traits sunt simulare cu interfetele in alte limbaje de programare.
                    <br/>
                    Rust prefera compozitia, in locul mostenirii!

                    <SyntaxHighlighter>
                        {'trait Bite {\n' +
                            '  fn bite(self: &mut Self);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'trait Html {\n' +
                            '  fn get_content(&self) -> &str;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Implementare Html pentru Document:
                    <SyntaxHighlighter>
                        {'trait Html {\n' +
                            '    fn get_content(&self) -> &str;\n' +
                            '}\n' +
                            '\n' +
                            'struct Document {\n' +
                            '    id: i32,\n' +
                            '    isHTML: bool,\n' +
                            '}\n' +
                            '\n' +
                            'impl Html for Document{\n' +
                            '    fn get_content(&self) -> &str {\n' +
                            '        "<html></html>"\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Functii generice:
                    <SyntaxHighlighter>
                        {'fn print<T: Html>(item: T){\n' +
                            '   item.get_content();\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'fn print_document<T: Html> (item: T){\n' +
                            '    println!("{}", item.get_content());\n' +
                            '}\n' +
                            '\n' +
                            'impl Html for u8{\n' +
                            '    fn get_content(&self) -> &str {\n' +
                            '        "<div>byte</div>"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'print_document(2); // va afisa <div>byte</div>'}
                    </SyntaxHighlighter>

                    Daca un tip implmenteaza <b>Copy</b> se va copia in loc de a se muta referinta.
                    <br/>
                    Primitivele, gen integer, floats, boolean implementeaza Copy in mod implicit.
                    <br/>
                    <br/>
                    O metoda dintru <b>trait</b> poate avea implementare:
                    <SyntaxHighlighter>
                        {'trait Run{\n' +
                            '    fn run(&self){\n' +
                            '        println!("Run");\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Exemplu de folosire:
                    <SyntaxHighlighter>
                        {'struct Robot {}\n' +
                            '\n' +
                            'impl Run for Robot {}'}
                    </SyntaxHighlighter>

                    Intr-un <b>trait</b> nu se pot defini campuri.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TraitsRustContent;