import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class WhilePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-while-statement", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>  11. Python: Instructiunea while</b>

                    <br/>
                    <br/>

                    Instructiunea <b>while</b> poate avea forma:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'while conditie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'while conditie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn\n'+
                        'else: \n' +
                        '    instructiune_S1\n' +
                        '    instructiune_S2\n' +
                        '    ...\n' +
                        '    instructiune_Sn'}
                    </SyntaxHighlighter>


                    Instructiunile instructiune_T1,instructiune_T1,...instructiune_Tn formeaza <b>corpul buclei</b> (loop's body).
                    <br/>
                    Instructiunea <b>while</b> va <b>repeta</b> instructiunile: instructiune_T1,instructiune_T1,...instructiune_Tn (adica corpul buclei) <b>cat timp</b> <i><b>conditia</b></i> este evaluata la <b>True</b>

                    <br/><br/>
                    Daca exista blocul de instructiuni <b>else</b> acestea se vor executa dupa terminarea executiei <b>while</b>-ului (daca nu se iese cu <b>break</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'i = 1\n' +
                        'while i<5:\n' +
                        '    print(i)\n' +
                        '    i = i + 1\n' +
                        'else:\n' +
                        '    print("stop")'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1\n' +
                        '2\n' +
                        '3\n' +
                        '4\n' +
                        'stop'}
                    </SyntaxHighlighter>

                    Daca se iese cu break, se sare si de blocul <b>else</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'i = 1\n' +
                        'while i<5:\n' +
                        '    print(i)\n' +
                        '    i = i + 1\n' +
                        '    break\n' +
                        'else:\n' +
                        '    print("stop")'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Observatii / Reguli (ca la instructiunea <b>if</b>)</b>
                    <ul>
                        <li>trebuie ca ":" sa fie urmat de o linie noua
                            <br/>
                            exista totusi o exceptie, daca urmeaza o singura instructiune:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'x=2;\n' +
                                'while x>5: x=x-1\n' +
                                'print(x)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'2'}
                            </SyntaxHighlighter>
                        </li>
                        <li>trebuie sa existe cel putin o instructiune</li>
                        <li>trebuie sa se pastreze aceeasi identare pentru toate instructiunile (recomandare: 4 spatii sau un tab)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WhilePythonContent;