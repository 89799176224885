import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class BuzzerArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-buzzer", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1.  Buzzer </b>
                    <br/>
                    <br/>

                    Tipuri de buzzer:
                    <ul>
                        <li>
                            pasiv (nu se poate pune la o baterie direct)
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_36.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_39.png'}/>
                            </div>

                        </li>
                        <li>
                            activ (se poate pune la o baterie direct):
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_37.png'}/>
                            </div>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_38.png'}/>
                            </div>
                        </li>
                    </ul>
                    Ca aspect sunt aproape identice. Pentru a le putea diferentia:
                    <ul>
                        <li>
                            un buzzer activ are deasupra un sticker alb (de obicei), care se poate dezlipi
                        </li>
                        <li>
                            la un buzzer activ nu se vad circuitele in spate:
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_40.png'}/>
                            </div>
                        </li>
                        <li>
                            la un buzzer pasiv se vad circuitele in spate:
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_41.png'}/>
                            </div>
                        </li>
                    </ul>

                    <hr/>
                    Buzzer pasiv, cu 2 pini:
                    <ul>
                        <li>+:  pin 9 (sau alt pin digital)</li>
                        <li>celalat pin: GND</li>
                    </ul>

                    Schema:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_42.png'}/>
                    </div>

                    <hr/>
                    Buzzer pasiv, cu 3 pini:
                    <ul>
                        <li>-: GND</li>
                        <li>Vcc: Power</li>
                        <li>S: pin 9 (sau alt pin digital)</li>
                    </ul>

                    Schema:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_43.png'}/>
                    </div>

                    Exemplu 1 (cod):
                    <SyntaxHighlighter>
                        {'const int buzzerPin = 9;\n' +
                            'void setup(){ \n' +
                            '  pinMode(buzzerPin, OUTPUT);\n' +
                            '}\n' +
                            '\n' +
                            'void loop(){\n' +
                            '  analogWrite(buzzerPin, 127);\n' +
                            '  delay(500);\n' +
                            '  analogWrite(buzzerPin,0);\n' +
                            '  delay(500);\n' +
                            '}'}
                    </SyntaxHighlighter>


                    Functii utile:
                    <ul>
                        <li>
                            tone(pin, frecventa) - sunetul dureaza pana la schimbarea in alt ton sau pana la <i>noTone()</i>
                        </li>
                        <li>
                            tone(pin, frecenta, durata);
                        </li>
                        <li>
                            noTone(pin);
                        </li>

                    </ul>


                    Exemplu 2 (cu tone, cod):
                    <SyntaxHighlighter>
                        {'const int buzzer = 9; //buzzer to arduino pin 9\n' +
                            '\n' +
                            'void setup(){\n' +
                            '  pinMode(buzzer, OUTPUT); // Set buzzer - pin 9 as an output\n' +
                            '}\n' +
                            '\n' +
                            'void loop(){\n' +
                            '  tone(buzzer, 1000); // Send 1KHz sound signal...\n' +
                            '  delay(1000);        // ...for 1 sec\n' +
                            '  noTone(buzzer);     // Stop sound...\n' +
                            '  delay(1000);        // ...for 1sec\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a controla volumnul unui buzzer se poate folosi un potentiometru!


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.circuitgeeks.com/arduino-buzzer-tutorial/"}>
                                Arduino Buzzer Tutorial
                            </a>
                        </li>


                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BuzzerArduinoContent;