import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class PluginsMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-plugins", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Plugin: clean</b>:
                    <br/>
                    <br/>

                    Sterge continutul din <i>target</i>-ul proiectului.
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean:clean'}
                    </SyntaxHighlighter>

                    sau

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Plugin: jar</b>:
                    <br/>
                    <br/>

                    Genereaza jar-ul in <i>target</i>-ul proiectului.
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn jar:jar'}
                    </SyntaxHighlighter>


                    Generare jar, cu parametrii:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn jar:jar -Djar.finalName=kj -Djar.forceCreation=true'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <b>-Djar.finalName=kj.jar</b>: specifica numele fisierului jar generat
                            <br/>
                            (<i>Building jar: D:\work\maven-project-learn\target\kj.jar</i>)
                        </li>
                        <li>
                            <b>-Djar.forceCreation=true</b>:  daca daca nu s-au facut modificari, executand comanda cu acest parametru cu valoare true, va forta crerea jar-ului din nou
                        </li>
                    </ul>

                    Pentru a nu mai scrie din linie de comanda, parametrii de mai sus, se pot specifica in <b>pom.xml</b> (sectiunea <i>\build\pluginManagement\plugins</i>)

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<plugin>\n' +
                            '                    <artifactId>maven-jar-plugin</artifactId>\n' +
                            '                    <version>2.4</version>\n' +
                            '                    <configuration>\n' +
                            '                        <forceCreation>true</forceCreation>\n' +
                            '                        <finalName>kj</finalName>\n' +
                            '                        <excludes>**/ClasaExlusaDinJAR.class</excludes>\n' +
                            '                    </configuration>\n' +
                            '</plugin>'}
                    </SyntaxHighlighter>

                    In exemplu de mai sus, parametrul din configurare <b>excludes</b> va exclude clasa numita <i>ClasaExlusaDinJAR</i> din Jar.

                    <hr/>
                    <b>3. Plugin: javadoc</b>:
                    <br/>
                    <br/>

                    Genereaza documentatia pentru proiect ca un site html in <b>target\site\apidocs</b> (se porneste de la fisierul <i>index.html</i>)
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn javadoc:javadoc'}
                    </SyntaxHighlighter>

                    Exemplu specificare parametri in linie de comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn javadoc:javadoc -Dheader=HEADER -Dfooter=FOOTER'}
                    </SyntaxHighlighter>
                    unde:

                    <ul>
                        <li>
                            <b>-Dheader=HEADER</b>: specifica textul care va apare in header-ul site-ului
                        </li>
                        <li>
                            <b>-Dfooter=FOOTER</b>: specifica textul care va apare in footer-ul site-ului
                        </li>
                    </ul>

                    {/*Exemplu specificare parametri in <b>pom.xml</b> (in sectiunea <i>\build\plugins</i>, si nu <i>\build\pluginManagement\plugins</i>):*/}
                    {/*<SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>*/}
                    {/*    {'<plugins>\n' +*/}
                    {/*        '            <plugin>\n' +*/}
                    {/*        '                <artifactId>maven-javadoc-plugin</artifactId>\n' +*/}
                    {/*        '                <groupId>org.apache.maven.plugins</groupId>\n' +*/}
                    {/*        '                <version>2.10.3</version>\n' +*/}
                    {/*        '            </plugin>\n' +*/}
                    {/*        '</plugins>'}*/}
                    {/*</SyntaxHighlighter>*/}
                    {/*Apoi se definesc efectiv parametrii in sectiunea <i>\build\pluginManagement\plugins</i>:*/}

                    Exemplu specificare parametri in <b>pom.xml</b> (in sectiunea <i>\build\pluginManagement\plugins</i>)
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<plugin>\n' +
                            '                    <artifactId>maven-javadoc-plugin</artifactId>\n' +
                            '                    <groupId>org.apache.maven.plugins</groupId>\n' +
                            '                    <configuration>\n' +
                            '                        <header>HEADERkj</header>\n' +
                            '                        <footer>FOOTERkj</footer>\n' +
                            '                    </configuration>\n' +
                            '</plugin>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Plugin: compile</b>:
                    <br/>
                    <br/>

                    Pentru a compila sursele proiectului (il compileaza, iar clasele compilate le pune in <i>target</i>, nu genereaza si jar-ul sau alt artefact):
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compile'}
                    </SyntaxHighlighter>
                    Acest scop, <i>compile</i> apeleaza 2 plugin-uri:
                    <ul>
                        <li>maven-resources-plugin</li>
                        <li>maven-compiler-plugin</li>
                    </ul>

                    Pentru a folosi doar <i>maven-compiler-plugin</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:compile'}
                    </SyntaxHighlighter>
                    E similar cu <i>mvn compile</i>, dar nu se ruleaza si <i>maven-resources-plugin</i>.

                    <br/>
                    <br/>
                    Pentru a afla mai multe detalii despre acest plugin:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:help -Ddetail=true'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>5. Plugin: surefire</b>:
                    <br/>
                    <br/>

                    Plugin-ul <b>surefire</b> este util in executia testelor.
                    <br/>
                    <br/>

                    Pentru a executa testele:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn test'}
                    </SyntaxHighlighter>
                    Acesta va genera un raport in <b>target\surefire-reports</b> cu ce teste au trecut si ce teste au esuat (plus, alte detalii legate de rularea testelor).

                    <br/>
                    <br/>
                    Pentru a ignora rularea testelor se foloseste <b>-Dmaven.test.skip=true</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn install -Dmaven.test.skip=true'}
                    </SyntaxHighlighter>

                    Pentru a executa teste si sa ignore daca crapa testele, pentru a nu afecta executarea build-ului, se foloseste <b>testFailureIgnore</b> = true.
                    Putem configura acest lucru scrise in <b>pom.xml</b> in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<plugin>\n' +
                            '                    <groupId>org.apache.maven.plugins</groupId>\n' +
                            '                    <artifactId>maven-surefire-plugin</artifactId>\n' +
                            '                    <version>2.22.2</version>\n' +
                            '                    <configuration>\n' +
                            '                        <testFailureIgnore>true</testFailureIgnore>\n' +
                            '                    </configuration>\n' +
                            '</plugin>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Plugin: eclipse</b>:
                    <br/>
                    <br/>

                    Acest plugin genereaza fisiere de configurare pentru Eclipse.

                    <br/>
                    <br/>
                    Dupa ce se genereaza un proiect maven cu <b>mvn archetype:generate</b>, pentru a putea fi importat in Eclipse trebuie executata urmatoare comanda:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn eclipse:eclipse'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Plugin: war</b>:
                    <br/>
                    <br/>

                    Acest plugin este folosit pentru a impacheta aplicatii pentru aplicatii web.

                    <br/>
                    <br/>
                    Daca vrem sa generam o aplicatie web folosind  <b>mvn archetype:generate</b> vom filtra dupa <b>maven-archetype-webapp</b>.

                    <br/>
                    <br/>
                    Apoi, adaugam in <b>pom.xml</b> in sectiunea <b>project\build\plugins\plugin</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<plugins>\n' +
                            '\n' +
                            '            <plugin>\n' +
                            '                <groupId>org.apache.maven.plugins</groupId>\n' +
                            '                <artifactId>maven-war-plugin</artifactId>\n' +
                            '                <version>3.2.0</version>\n' +
                            '                <configuration>\n' +
                            '                    <detail>\n' +
                            '                        Details about Maven Web Application\n' +
                            '                    </detail>\n' +
                            '                </configuration>\n' +
                            '            </plugin>\n' +
                            '</plugins>'}
                    </SyntaxHighlighter>

                    Apoi, rulam plugin-ul
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn war:war'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Plugin: archetypes</b>:
                    <br/>
                    <br/>

                    Pentru a genera un schelet de proiect:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate'}
                    </SyntaxHighlighter>

                    Cand ne suntem intrebati <i>Choose a number or apply filter</i> putem alege sa generam un proiect <i>Spring</i> de exemplu.


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PluginsMavenContent;