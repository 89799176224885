import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InvalidCENHeaderLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-invalid-cen-header", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Problema: Caused by: java.util.zip.ZipException: Invalid CEN header (invalid zip64 extra data field size)</b>
                    <br/>
                    <br/>

                    <b>Cauza:</b>
                    <br/>
                    <br/>
                    Java - openjdk version "11.0.21" 2023-10-17
                    <br/>
                    <br/>

                    <b>Rezolvare:</b>
                    <br/>
                    <br/>

                    Puteți accesa fișierul
                    <br/>
                    tomcat/bin/setenv.sh sau tomcat/bin/setenv.bat
                    <br/>
                    și îl puteți adăuga la variabila <b>JDK_JAVA_OPTIONS</b> (sau chiar <b>CATALINA_OPTIONS</b>): <br/>
                    -Djdk.util.zip.disableZip64ExtraFieldValidation=true

                    <br/>
                    De exemplu:
                    <br/>
                    CATALINA_OPTS="$CATALINA_OPTS -Djdk.util.zip.disableZip64ExtraFieldValidation=true ...

                    <br/>
                    <br/>
                    Sursa:
                    <br/>
                    https://liferay.dev/es/blogs/-/blogs/important-jdk-11-0-20-notice


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InvalidCENHeaderLiferayContent;