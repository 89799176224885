import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class React18Content extends BaseContentPage  {

    constructor(props) {
        super(props, "react-18", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Update to React 18</b>
                    <br/>
                    <br/>

                    Incepand cu versiunea 18 s-a renuntat la <b>ReactDOM.render</b> din si se foloseste in schimb <b>createRoot</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import App from \'./app/App\';\n' +
                            'import {BrowserRouter} from "react-router-dom";\n' +
                            '\n' +
                            '//ReactDOM.render is no longer supported in React 18\n' +
                            '// import ReactDOM from \'react-dom\';\n' +
                            '\n' +
                            'import { createRoot } from \'react-dom/client\';\n' +
                            '\n' +
                            '//ReactDOM.render is no longer supported in React 18\n' +
                            '//ReactDOM.render( <BrowserRouter><App /></BrowserRouter>, document.getElementById(\'root\'));\n' +
                            '\n' +
                            'const container = document.getElementById(\'root\');\n' +
                            'const root = createRoot(container);\n' +
                            'root.render(<BrowserRouter><App /></BrowserRouter>);'}
                    </SyntaxHighlighter>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default React18Content;