import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class IncadrariVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-incadrari", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Planuri:
                    <ul>
                        <li><b>plan de ansamblu (PAns) / plan foarte general (PfG) / Extrem Long Shot (ELS)</b>
                            <br/>
                            <ul>
                                <li>
                                    camera se gaseste la cel mai indepartatat punct posibil de subiect
                                </li>
                                <li>
                                    se evidentiaza intreg peisajul, decorul, fundalul
                                </li>
                                <li>
                                    ne introduce in atmosfera
                                </li>
                                <li>cadrul de inceput, ranspunzand la intrebarile: unde? cum? cand? </li>
                            </ul>
                        </li>
                        <li>
                            <b>plan general (PG) / Long Shot (LS)</b>
                            <br/>
                            <ul>
                                <li>
                                    incadrarea cuprinde intreg spartiul de desfasurare al actiunii si personajul face parte din compozitia decorului
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>plan intreg (PÎ) / Medium Long Shot (MLS)</b>
                            <ul>
                                <li>personajul, stand in picioare, este cuprins in cadru din cap pana in picioare</li>
                            </ul>
                        </li>
                        <li>
                            <b>plan american (variatia la Medium Long Shot (MLS) )</b>
                            <ul>
                                <li>
                                    personajul, stand in picioare, este cuprins in cadru din cap pana:<br/>
                                    - intre glezna si genunghi<br/>
                                    - un pic peste genunchi
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>plan mediu (PM) / Medium Shot (MS)</b>
                            <ul>
                                <li>personajul are capul intreg si taietura se face la mijlocul corpului, la nivelul taliei</li>
                                <li>personajul sta in picioare sau pe scaun, ocupand aproape intreaga suprafata a cadrului</li>
                                <li>personajul poate gesticula, fara ca mainile sa iasa din cadru sau sa fie taiate</li>
                                <li>se pot realiza incadrari cu doua personaje (<b>the two shot</b>) sau cu trei personaje (<b>the three shot</b>)</li>
                            </ul>
                        </li>
                        <li>
                            <b>plan mediu strans (PMS) / Medium Close Shot (MCS)</b>
                            <ul>
                                <li>personajului are capul intreg si taietura se face la nivelul pieptului, pastrand umerii</li>
                                <li>mainile personajului ies din cadru</li>
                                <li>este un cadru <b>prim-plan mai larg</b></li>
                            </ul>
                        </li>
                        <li>
                            <b>prim-plan clasic (PP) / Close-up (CU)</b>
                            <ul>
                                <li>personajului are capul intreg si taietura se face la nivelul umerilor</li>
                            </ul>
                        </li>
                        <li>
                            <b>prin-plan strans (PPS) </b>
                            <ul>
                                <li>
                                    personajul are capul intreg si se lasa cat mai putin din umeri
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>gros plan (GS) / Big Close Up (BCU)</b>
                            <ul>
                                <li>
                                    se taie din frunte si din barbie
                                </li>
                                <li>
                                    se taie din barbie, daca personajul nu vorbeste
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>plan detaliu (PD)</b>
                            <ul>
                                <li>detaliu pe ochi, un obiect, etc</li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IncadrariVideografieContent;