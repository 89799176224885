import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FunctionsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-functions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Functii</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'fn numeFunctie(param1:tip1, param2:tip2) -> tipReturnat {...}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'fn square(x:i32)->i32{\n' +
                            '    return x * x;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {'fn square(x: i32) -> i32 {\n' +
                            '    x * x\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionsRustContent;