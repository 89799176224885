import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"git-i", url:"#", title:"Introducere in Git", subtitle:""},

        {id:"git-create-repository", url:"/git/create-repository", title:"Creare repository", subtitle:"", parent: "git-i"},
        {id:"git-status", url:"/git/status", title:"Stare repository", subtitle:"", parent: "git-i"},
        {id:"git-add", url:"/git/add", title:"Urmarire fisier in repository", subtitle:"", parent: "git-i"},
        {id:"git-commit", url:"/git/commit", title:"Commit fisier", subtitle:"", parent: "git-i"},
        {id:"git-diff", url:"/git/diff", title:"Comanda git diff", subtitle:"", parent: "git-i"},
        {id:"git-log", url:"/git/log", title:"Comanda git log", subtitle:"", parent: "git-i"},
        {id:"git-show", url:"/git/show", title:"Comanda git show", subtitle:"", parent: "git-i"},
        {id:"git-ignore", url:"/git/ignore", title:"Fisierul .ignore", subtitle:"", parent: "git-i"},
        {id:"git-remote-repository", url:"/git/remote-repository", title:"Remote repository", subtitle:"", parent: "git-i"},
        {id:"git-branch", url:"/git/branch", title:"Gestionare branch-uri", subtitle:"", parent: "git-i"},
        {id:"git-clone", url:"/git/clone", title:"Comanda git clone", subtitle:"", parent: "git-i"},

        {id:"git-config", url:"/git/config", title:"Git Config", subtitle:"", parent: "git-i"},

        {id:"git-create-repository-existing-project", url:"/git/create-repository-existing-project", title:"Creare repository din proiect care exista", subtitle:"", parent: "git-i"},
        {id:"git-local-repo-to-remote-repo", url:"/git/local-repo-to-remote-repo", title:"De la repository-ul local la repository-ul remote", subtitle:"", parent: "git-i"},
        {id:"git-multiple-remote-repository", url:"/git/multiple-remote-repository", title:"Multiple repository-uri la distanta (multiple remote repository)", subtitle:"", parent: "git-i"},

        {id:"git-rev-parse-head", url:"/git/rev-parse-head", title:"Comanda git rev-parse HEAD", subtitle:"", parent: "git-i"},

        {id:"git-overview", url:"/git/overview", title:"Generalitati", subtitle:"", parent: "git-i"},

        {id:"git-copied-directory-branch", url:"/git/copied-directory-branch", title:"Copiat director dintr-un branch in alt branch", subtitle:"", parent: "git-i"},

        {id:"git-cicd", url:"#", title:"Pipeline, CI/CD si DevOps", subtitle:""},
        {id:"gitlab-ci", url:"/gitlab/ci", title:"GitLab CI", subtitle:"", parent: "git-cicd"},
    ];

    static indexUrl = "/git/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere în Git
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;