import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class NumericPromotionRulesJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-numeric-promotion-rules", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Reguli de promovare numerica:
                    <ul>
                        <li>daca doua valori au tipuri de data diferite, atunci valoarea mai mica se va promova in mod automat la tipul de data cel mai cuprinzator
                            <br/>
                            de exemplu:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'int a = 1;\n' +
                                'long b = 2;\n' +
                                '        \n' +
                                'long c = a + b; // c trebuie sa fie de tip long\n' +
                                '// observatie: daca ar fi int s-ar obtine eroare: error: incompatible types: possible lossy conversion from long to int'}
                            </SyntaxHighlighter>

                            <b>Observatie:</b>
                            <br/>

                            <b>Operatori de atribuire compusi (+=, -=, *=, /=) efectuaza automat cast catre tipul de data din stanga operaratorului compus</b>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'variabila=*(cast-la-tipul-de-date-din-stanga) expresie'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'int a = 1;\n' +
                                'long b = 2;\n' +
                                '        \n' +
                                'a = a + b; // nu e ok, pentru ca rezultatul lui a + b este de tip long \n'+
                                'a = (int)(a + b); // e ok \n'+
                                'a += b; // e ok, pentru ca operatori de atribuire compusi (+=, -=, *=, /=) efectuaza automat cast\n' +
                                'a += (int)b; // e ok \n' +
                                'a = a + (int)b; // e ok'}
                            </SyntaxHighlighter>
                        </li>
                        <li>daca una din valori este de tip intreg (byte, short, int, long) si cealalta de tip zecimal (float, double), atunci valoare intrega va fi promivata automat la tipul de data zecimal</li>

                        <li>tipurile de date: <b>byte, short, char</b> sunt promovate automat la <b>int</b> cand sunt folosite cu un <b>operator binar</b> aritmetic (chiar daca nici unul din operanzi nu e de tip int)</li>
                        <li>dupa toate promovarile si toti operanzi sunt de acelasi tip, valoarea rezultata va avea acelasi tip ca operanzii promovati</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NumericPromotionRulesJavaContent;