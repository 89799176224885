import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class AdduserLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-adduser", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Creare utilizator nou:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'adduser iulianb'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Adding user `iulianb\' ...\n' +
                            'Adding new group `iulianb\' (1002) ...\n' +
                            'Adding new user `iulianb\' (1002) with group `iulianb\' ...\n' +
                            'Creating home directory `/home/iulianb\' ...\n' +
                            'Copying files from `/etc/skel\' ...\n' +
                            'New password: \n' +
                            'BAD PASSWORD: The password is shorter than 8 characters\n' +
                            'Retype new password:\n' +
                            'passwd: password updated successfully\n' +
                            'Changing the user information for iulianb\n' +
                            'Enter the new value, or press ENTER for the default\n' +
                            '        Full Name []: iulianb\n' +
                            '        Room Number []:\n' +
                            '        Work Phone []:\n' +
                            '        Home Phone []:\n' +
                            '        Other []:\n' +
                            'Is the information correct? [Y/n] Y\n'}
                    </SyntaxHighlighter>
                    Implit, folosind comanda <b>adduser</b>, shell-ul este <b>bash</b>.

                    <hr/>
                    Fisierul de configurare, pentru crearea unui nou utilizator, folosind comanda <b>adduser</b> este: <b>/etc/adduser.conf</b>.
                    De exemplu, exista proprietatea DSHELL=/bin/bash!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AdduserLinuxContent;