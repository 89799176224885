import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ImageBuildDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-images-build", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker build</b>

                    <br/>
                    <br/>

                    O imagine Docker este o stiva de layere <b>read-only</b>, avand ca sistem de fisiere: <b>OverlayFS</b>.
                    <br/>
                    Acestea imagini build-ate/pulled se gasesc in: <b>/var/lib/docker/overlay2</b>.
                    <br/>
                    Dockerfile:
                    <ul>
                        <li>bootfs {"=>"} boot file system - folosit pentru a stoca boot loaders, adica scripturi care pornesc sistemul
                            <br/>
                            aici incepe izolarea containerului;
                            <br/>
                            bootfs ofera izolare si aloca resurse rezervate, care separa virtual imaginea de restul de fisiere de pe masina gazda sau cloud
                        </li>
                        <li>
                            base image
                        </li>
                        <li>
                            standard layere - layere itermediare, care au propriul image ID-: RUN, WORKDIR, EXPOSE, ENV, ADD, COPY, CMD etc;
                            <br/>
                            existenta layerelor intermediare este justificata de caching
                        </li>
                    </ul>

                    <hr/>
                    Creare imagine pe baza Dockerfile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    Creare imagine pe baza URL GitGub Repository:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build http://github.com/iulianbuzdugan/nginx/git -t ngnix-git'}
                    </SyntaxHighlighter>

                    Creare imagine pe baza Dockerfile (Pipe):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build - < Dockerfile -t ngnix-git'}
                    </SyntaxHighlighter>

                    Creare imagine pe baza Dockerfile arhivat (Pipe):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build - < ngnix.tar.gz -t ngnix-git'}
                    </SyntaxHighlighter>
                    (fisierul Dockerfile a fost arhivat in fisierul <i>ngnix.tar.gz</i> cu comanda: <i>tar -zcvf ngnix.tar.gz Dockerfile</i>)

                    Creare imagine pe baza URL arhivat (Pipe):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build - < http://abc.com/ngnix.tar.gz -t ngnix-git'}
                    </SyntaxHighlighter>

                    <hr/>
                    Creare imagine cu DOCKER_BUILDKIT=1 (implicit e dezactivat):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'DOCKER_BUILDKIT=1 docker build -t ngnix-git .'}
                    </SyntaxHighlighter>
                    DOCKER_BUILDKIT:
                    <ul>
                        <li>
                            foloseste procesar paralela
                        </li>
                        <li>
                            e folosit de <i>docker push</i>
                        </li>
                    </ul>
                    Se pot folosi optiunile: <b>--progress=plain</b> si <b>--no-cache</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'DOCKER_BUILDKIT=1 docker build --progress=plain --no-cache -t ngnix-git .'}
                    </SyntaxHighlighter>
                    Pentru a activa DOCKER_BUILDKIT, (pentru nu mai fi nevoie sa il mentionam inaintea comezii <i>docker build</i>), trebui modificat/creat (ca administator - <i>sudo su -</i>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano /etc/docker/daemon.json'}
                    </SyntaxHighlighter>
                    se adauga continutul:
                    <SyntaxHighlighter showLineNumbers={false} language="json" style={androidstudio}>
                        {'{\n' +
                            '\t"features":\n' +
                            '\t\t{"buildkit": true}\n' +
                            '}'}
                    </SyntaxHighlighter>
                    apoi, restart docker:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl restart docker'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImageBuildDockerContent;