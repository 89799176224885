import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

// const observable = new Observable(subscriber => {
//     subscriber.next("hello world 1");
//
//     subscriber.error("eroare!!");
//
//     subscriber.next("hello world 2");
//
//     subscriber.complete();
//     subscriber.error("gata?")
// });

// const observable = new Observable(subscriber => {
//     const interval = setInterval(
//         ()=>{
//             subscriber.next('test - next');
//             console.log('test - interval');
//         },1000);
//
//    // subscriber.complete();
//
//     return () => {
//         clearInterval(interval);
//     }
// });
//
// const substription = observable.subscribe({
//     next: (value) => {
//         console.log(value);
//     },
//     complete:()=>{
//         console.log("gata!")
//     },
//     error:(err)=>{
//         console.error(err);
//     }
// });
//
// setTimeout(()=>{
//     substription.unsubscribe();
// },4000)

// const observable = interval(1000)
// const observable = fromEvent(document, 'click');
//const observable = from(fetch('https://jsonplaceholder.typicode.com/todos/1'));

// const observable = of(1,2,3 )
//     .pipe(map(v=>v*2))
// const substription = observable.subscribe(console.log);
//
// const observable = of({'tara':'franta'},1, {'tara':'romania'} )
//     .pipe(pluck("tara"))
// const substription = observable.subscribe(console.log);

// const observable = of(1,2,3,4 )
//     .pipe(filter(v=>v%2==0))
// const substription = observable.subscribe(console.log);

// const observable = of(1,2,3,4 )
//     .pipe(reduce(
//         (acc, val) => acc + val, 0
//     ))
// const substription = observable.subscribe(console.log);

// const observable = interval(100)
//     .pipe(
//         take(10),
//         reduce(
//         (acc, val) => acc + val, 0
//         )
//     )
// const substription = observable.subscribe(console.log);

// const observable = interval(100)
//     .pipe(
//         take(5),
//         scan(
//             (acc, val) => acc + val, 0
//         )
//     )
// const substription = observable.subscribe(console.log);

// const observable = interval(100)
//     .pipe(
//         take(10),
//         tap(console.log),
//         reduce(
//             (acc, val) => acc + val, 0
//         )
//     )
// const substription = observable.subscribe(console.log);

// const letters = of('a', 'b', 'c');
// const result = letters.pipe(
//     mergeMap(x => interval(1000).pipe(map(i => x + i)))
// );
//
// result.subscribe(x => console.log(x));

// const switched = of(1, 2, 3)
//     .pipe(
//         switchMap(x => of(x, x ** 2, x ** 3)));
// switched.subscribe(x => console.log(x));

// const clicks = fromEvent(document, 'click');
// const result = clicks.pipe(
//     exhaustMap(() => interval(1000).pipe(take(5)))
// );
// result.subscribe(x => console.log(x));

class RxJsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-rxjs", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Observable </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {Observable} from "rxjs";\n' +
                            '\n' +
                            'const observable = new Observable(subscriber => {\n' +
                            '    subscriber.next("hello world")\n' +
                            '});\n' +
                            '\n' +
                            'observable.subscribe({\n' +
                            '    next: (value) => {\n' +
                            '        console.log(value);\n' +
                            '    }\n' +
                            '})'}
                    </SyntaxHighlighter>
                    Daca nu exista nici un <i>subscribe</i> atasat la un observer, functia <i>subscriber</i> nu se va apela!

                    <br/>

                    Metoda <b>complete()</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = new Observable(subscriber => {\n' +
                            '    subscriber.next("hello world 1");\n' +
                            '    subscriber.next("hello world 2");\n' +
                            '\n' +
                            '    subscriber.complete();\n' +
                            '    subscriber.error("gata?")\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'observable.subscribe({\n' +
                            '    next: (value) => {\n' +
                            '        console.log(value);\n' +
                            '    },\n' +
                            '    complete:()=>{\n' +
                            '        console.log("gata!")\n' +
                            '    }\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Metoda <b>error()</b>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = new Observable(subscriber => {\n' +
                            '    subscriber.next("hello world 1");\n' +
                            '\n' +
                            '    subscriber.error("eroare!!");\n' +
                            '\n' +
                            '    subscriber.next("hello world 2");\n' +
                            '\n' +
                            '    subscriber.complete();\n' +
                            '    subscriber.error("gata?")\n' +
                            '});\n' +
                            '\n' +
                            'observable.subscribe({\n' +
                            '    next: (value) => {\n' +
                            '        console.log(value);\n' +
                            '    },\n' +
                            '    complete:()=>{\n' +
                            '        console.log("gata!")\n' +
                            '    },\n' +
                            '    error:(err)=>{\n' +
                            '        console.error(err);\n' +
                            '    }\n' +
                            '})\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Eliberarea memoriei:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = new Observable(subscriber => {\n' +
                            '    const interval = setInterval(\n' +
                            '        ()=>{\n' +
                            '            subscriber.next(\'test\');\n' +
                            '            console.log(\'test\');\n' +
                            '        },1000);\n' +
                            '    \n' +
                            '    subscriber.complete();\n' +
                            '    \n' +
                            '    return () => {\n' +
                            '        clearInterval(interval);\n' +
                            '    }\n' +
                            '});'}
                    </SyntaxHighlighter>
                    Fara functia de return, chiar daca <i>subscriber</i>-ul e complet, <i>setInterval</i> va rula la infinit.
                    <br/>
                    Bine si fara <i>subscriber.complete();</i>, dar cu functia de return, tot la infinit va rula.

                    <hr/>
                    <b>2. Substription (abonament)</b>
                    <br/>
                    <br/>
                    Un <b>Substription</b> este un obiect care reprezinta o resursa de unica folosinta, de obicei executia unui <b>Observable</b>.
                    <br/>
                    Un <b>Substription</b> are o metoda <b>unsubscribe()</b>  care nu acceptă argumente si elibereaza resursele unui  <b>Observable</b>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const substription = observable.subscribe({\n' +
                            '    next: (value) => {\n' +
                            '        console.log(value);\n' +
                            '    },\n' +
                            '    complete:()=>{\n' +
                            '        console.log("gata!")\n' +
                            '    },\n' +
                            '    error:(err)=>{\n' +
                            '        console.error(err);\n' +
                            '    }\n' +
                            '});\n' +
                            '\n' +
                            'setTimeout(()=>{\n' +
                            '    substription.unsubscribe();\n' +
                            '},4000)'}
                    </SyntaxHighlighter>

                    Daca avem asa:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = new Observable(subscriber => {\n' +
                            '    const interval = setInterval(\n' +
                            '        ()=>{\n' +
                            '            subscriber.next(\'test - next\');\n' +
                            '            console.log(\'test - interval\');\n' +
                            '        },1000);\n' +
                            '\n' +
                            '   // subscriber.complete();\n' +
                            '\n' +
                            '    // return () => {\n' +
                            '    //     clearInterval(interval);\n' +
                            '    // }\n' +
                            '});'}
                    </SyntaxHighlighter>
                    atunci timp de 4 secunde se va afisa:
                    <SyntaxHighlighter>
                        {'test - interval\n' +
                            'test - next'}
                    </SyntaxHighlighter>
                    si apoi doar <i>test - interval</i>;
                    <br/>
                    Prin urmare este nevoie de <b>clearInterval</b> , pentru cazul de mai sus!

                    <hr/>
                    <b>3. Operatori</b>
                    <br/>
                    <br/>
                    Tipuri de operatori:
                    <ul>
                        <li>de creare: creaza obiect de tip Observable</li>
                        <li>pipe: ia un observable ca input si reurneaza un nou observable</li>
                    </ul>

                    <hr/>
                    <b>4. Operatori de creare</b>
                    <br/>
                    <br/>
                    <b>interval:</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = interval()\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    sau (la fiecare secunda):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = interval(1000)\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>

                    <b>timer: (similar cu interval(0, 1000)), dar poti specifica cat timp sa astepte pana sa emita valori</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = timer(0, 1000)\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>

                    <b>fromEvent: (observa DOM)</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = fromEvent(document, \'click\')\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>

                    <b>of (este sincron, fiind un observabil care emite sincron argumentele descrise mai sus și apoi se completează imediat)</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = of(1,2,3,4,5)\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>

                    <b>from (convertește o promisiune, o matrice sau un obiect iterabil într-un Observabil care emite elementele din acea promisiune, matrice sau iterabil)</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = from([1,2,3,4,5])\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    sau (se va executa pentru fiecare litera):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = from("1234")\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>

                    sau (cu <i>Promise</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = from(fetch(\'https://jsonplaceholder.typicode.com/todos/1\'));\n' +
                            'const substription = observable.subscribe({\n' +
                            '    next(v) {\n' +
                            '        console.log(v)\n' +
                            '    }\n' +
                            '});\n' +
                            ''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Operatori pipe</b>
                    <br/>
                    <br/>
                    <b>5.1. map</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = of(1,2,3 )\n' +
                            '    .pipe(map(v=>v*2))\n' +
                            'const substription = observable.subscribe(console.log);\n'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'2\n' +
                            '4\n' +
                            '6'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>5.2. plunk (extrage valoarea proprietati unui obiect)</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = of({\'tara\':\'franta\'},1, {\'tara\':\'romania\'} )\n' +
                            '    .pipe(pluck("tara"))\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'franta\n' +
                            'undefined\n' +
                            'romania'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// RxJS v6+\n' +
                            'import { from } from \'rxjs\';\n' +
                            'import { pluck } from \'rxjs/operators\';\n' +
                            '\n' +
                            'const source = from([\n' +
                            '  { name: \'Joe\', age: 30 },\n' +
                            '  { name: \'Sarah\', age: 35 }\n' +
                            ']);\n' +
                            '//grab names\n' +
                            'const example = source.pipe(pluck(\'name\'));\n' +
                            '//output: "Joe", "Sarah"\n' +
                            'const subscribe = example.subscribe(val => console.log(val));'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5.3. filter</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = of(1,2,3,4 )\n' +
                            '    .pipe(filter(v=>v%2==0))\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    va afisa: 2 si 4

                    <hr/>
                    <b>5.4. reduce</b>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = of(1,2,3,4 )\n' +
                            '    .pipe(reduce(\n' +
                            '        (acc, val) => acc + val, 0\n' +
                            '    ))\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    va afisa 10 (suma 1+2+3=4); 0 este valoare initiala pentru acc=0;
                    <br/>
                    Acest operator calculeaza cand observarea este completa; dar daca inca nu e?:)

                    <hr/>
                    <b>5.5. take (ia un numar de observatii in considerare)</b>

                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = interval(100)\n' +
                            '    .pipe(\n' +
                            '        take(10),\n' +
                            '        reduce(\n' +
                            '        (acc, val) => acc + val, 0\n' +
                            '        )\n' +
                            '    )\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    va afisa 45 (suma primelor 10 numere)

                    <hr/>
                    <b>5.6. scan (similar cu reduce, dar emite rezultate intermediare cumulative)</b>

                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = interval(100)\n' +
                            '    .pipe(\n' +
                            '        take(5),\n' +
                            '        scan(\n' +
                            '        (acc, val) => acc + val, 0\n' +
                            '        )\n' +
                            '    )\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    va afisa: 0, 1, 3, 6, 10

                    <hr/>
                    <b>5.7. tap (operator pt debugging) </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'const observable = interval(100)\n' +
                            '    .pipe(\n' +
                            '        take(5),\n' +
                            '        tap(console.log),\n' +
                            '        reduce(\n' +
                            '            (acc, val) => acc + val, 0\n' +
                            '        )\n' +
                            '    )\n' +
                            'const substription = observable.subscribe(console.log);'}
                    </SyntaxHighlighter>
                    va afisa: 0,1,2,3,4,10; daca take era 10, se afisa: 0,1,2,3,4,5,6,7,8,9,45

                    <hr/>
                    <b>6. Operatori de tip flat</b>
                    <br/>
                    <br/>

                    <b>6.1. mergeMap</b>
                    <br/>
                    <br/>

                    Returnează un observabil care emite elemente pe baza aplicării unei funcții pe care o furnizați fiecărui element emis de sursa Observable,
                    unde acea funcție returnează un observabil și apoi îmbinând observabilele rezultate și emitând rezultatele acestei fuziuni.
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { of, mergeMap, interval, map } from \'rxjs\';\n' +
                            ' \n' +
                            'const letters = of(\'a\', \'b\', \'c\');\n' +
                            'const result = letters.pipe(\n' +
                            '  mergeMap(x => interval(1000).pipe(map(i => x + i)))\n' +
                            ');\n' +
                            ' \n' +
                            'result.subscribe(x => console.log(x));\n' +
                            ' \n' +
                            '// Afiseaza:\n' +
                            '// a0\n' +
                            '// b0\n' +
                            '// c0\n' +
                            '// a1\n' +
                            '// b1\n' +
                            '// c1\n' +
                            '// ....\n' +
                            '// a43\n' +
                            '// b43\n' +
                            '// c43\n' +
                            '// ....\n' +
                            ''}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { of, from } from \'rxjs\'; \n' +
                            'import { map, mergeMap, delay, mergeAll } from \'rxjs/operators\';\n' +
                            '\n' +
                            'const getData = (param) => {\n' +
                            '  return of(`retrieved new data with param ${param}`).pipe(\n' +
                            '    delay(1000)\n' +
                            '  )\n' +
                            '}\n' +
                            '\n' +
                            '// using a regular map\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  map(param => getData(param))\n' +
                            ').subscribe(val => val.subscribe(data => console.log(data)));\n' +
                            '\n' +
                            '// using map and mergeAll\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  map(param => getData(param)),\n' +
                            '  mergeAll()\n' +
                            ').subscribe(val => console.log(val));\n' +
                            '\n' +
                            '// using mergeMap\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  mergeMap(param => getData(param))\n' +
                            ').subscribe(val => console.log(val));\n'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>6.2. switchMap</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { of, switchMap } from \'rxjs\';\n' +
                            ' \n' +
                            'const switched = of(1, 2, 3).pipe(switchMap(x => of(x, x ** 2, x ** 3)));\n' +
                            'switched.subscribe(x => console.log(x));\n' +
                            '// Afiseaza:\n' +
                            '// 1\n' +
                            '// 1\n' +
                            '// 1\n' +
                            '// 2\n' +
                            '// 4\n' +
                            '// 8\n' +
                            '// 3\n' +
                            '// 9\n' +
                            '// 27'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { of, from } from \'rxjs\'; \n' +
                            'import { map, delay, switchAll, switchMap } from \'rxjs/operators\';\n' +
                            '\n' +
                            'const getData = (param) => {\n' +
                            '  return of(`retrieved new data with param ${param}`).pipe(\n' +
                            '    delay(1000)\n' +
                            '  )\n' +
                            '}\n' +
                            '\n' +
                            '// using a regular map\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  map(param => getData(param))\n' +
                            ').subscribe(val => val.subscribe(data => console.log(data)));\n' +
                            '\n' +
                            '// using map and switchAll\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  map(param => getData(param)),\n' +
                            '  switchAll()\n' +
                            ').subscribe(val => console.log(val));\n' +
                            '\n' +
                            '// using switchMap\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  switchMap(param => getData(param))\n' +
                            ').subscribe(val => console.log(val));'}
                    </SyntaxHighlighter>

                    SwitchAll anulează abonamentul anterior și se abonează la cel nou.

                    <hr/>
                    <b>6.3. concatMap</b>
                    <br/>
                    <br/>

                    Spre deosebire de switchMap, care se dezabonează de la Observable actual dacă apare un nou Observable, &nbsp;
                    <b>concatMap</b> <i>nu se va abona la următorul Observable până la finalizarea celui actual</i>.
                    Avantajul acestui lucru este că se menține ordinea în care emit observabile.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { of, from } from \'rxjs\'; \n' +
                            'import { map, delay, mergeMap, concatMap } from \'rxjs/operators\';\n' +
                            '\n' +
                            'const getData = (param) => {\n' +
                            '  const delayTime = Math.floor(Math.random() * 10000) + 1;\n' +
                            '  return of(`retrieved new data with params: ${param} and delay: ${delayTime}`).pipe(\n' +
                            '    delay(delayTime)\n' +
                            '  )\n' +
                            '}\n' +
                            '\n' +
                            '// using a regular map\n' +
                            'from([1,2,3,4]).pipe(\n' +
                            '  map(param => getData(param))\n' +
                            ').subscribe(val => val.subscribe(data => console.log(\'map:\', data)));\n' +
                            '\n' +
                            '// using mergeMap\n' +
                            'from([1, 2, 3 ,4]).pipe(\n' +
                            '  mergeMap(param => getData(param))\n' +
                            ').subscribe(val => console.log(\'mergeMap:\', val));\n' +
                            '\n' +
                            '// using concatMap\n' +
                            'from([1, 2, 3 ,4]).pipe(\n' +
                            '  concatMap(param => getData(param))\n' +
                            ').subscribe(val => console.log(\'concatMap:\', val));\n'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>6.4. exhaustMap</b>
                    <br/>
                    <br/>

                    Proiectează fiecare valoare sursă la un observabil care este îmbinat în observabilul de ieșire numai dacă observabilul proiectat anterior a fost finalizat.
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { fromEvent, exhaustMap, interval, take } from \'rxjs\';\n' +
                            '\n' +
                            'const clicks = fromEvent(document, \'click\');\n' +
                            'const result = clicks.pipe(\n' +
                            '  exhaustMap(() => interval(1000).pipe(take(5)))\n' +
                            ');\n' +
                            'result.subscribe(x => console.log(x));'}
                    </SyntaxHighlighter>
                    Sa da click, se ruleaza ce are de rulat; daca in acest timp, cat dureaza ce are de rulat, se va mai da un click, acesta va fi ignorat;
                    <br/>
                    (Util, de exemplu, se apasa de 2 ori pe un buton care face o cere pe server; al doilea click va fi ignorat daca primul nu s-a finalizat)

                    <hr/>
                    Recapituland:
                    <ul>
                        <li>
                            <b>switchMap</b> - se opreste din lucrat la ce are de facut si incepe sa lucreze la noua <i>comanda</i>'
                            doar ultima comanda va fi finalizata
                        </li>
                        <li>
                            <b>concatMap</b> - noua comanda este pusa in coada; dupa ce se termina comanda curenta,
                            se incepe sa se lucreze la urmatoarea comanda din coada
                        </li>
                        <li>
                            <b>mergeMap</b> - se lucreza la toate comenziile in acelasi timp pe masura ce vin
                        </li>
                        <li>
                            <b>exhaustMap</b> - ignora noile comenzi cat timp se lucreaza o comanda; dupa ce se termina poate primi noi comenzi
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RxJsReactContent;