import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LexicalAnalysisContent extends BaseContentPage {

    constructor(props) {
        super(props, "compiler-lexical-analysis", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Analizatorul lexical sau <b>scanning</b>:
                    <ul>
                        <li>citeste fluxul de caractere care reprezinta codul sursa al programului</li>
                        <li><b>identifica</b> <i>unitatile lexicale</i> (sau <i>entitatile</i> sau <i>atomi</i> sau <i>simbolurile limbajului</i> sau <i>tokens</i>) ale unui program (secvente de caractere care au inteles logic)</li>
                        <li><b>clasifica</b> fiecare atom lexical, asociindul unei clase (daca nu se poate asocia unei clase, atunci este o eroare lexicala);
                            <br/>
                            Clasele:
                            <ul>
                                <li>
                                    <b>identificatori</b>
                                    <br/>
                                    <i>exemplu de regula</i>: trebuie sa incepa cu o litera, _ si $, urmat de 0 sau mai multe caractere alfanumerice (litere si cifere) plus _ si $
                                    <br/>
                                    <i>exemple valide</i>: variabila, _x, x2
                                    <br/>
                                    <i>exemplu invalid</i>: 1a (eroare, pentru ca incalca regula de mai sus si incepe cu o cifra )
                                </li>
                                <li>
                                    <b>cuvinte cheie</b> (cuvinte rezervate)
                                    <br/>
                                    similar ca la identificatori
                                    <i>exemple</i>: if, for, while, etc
                                </li>
                                <li>
                                    <b>constante</b>
                                    <br/>
                                    <i>exemplu de regula</i>: trebuie sa contina doar cifre, . o singura data, si poate incepe cu - sau +
                                    <i>exemple valide</i>: 123, -123, 1.34
                                </li>
                                <li>
                                    <b>operatori</b>
                                    <br/>
                                    <i>exemplu de regula</i>:
                                    <ul>
                                        <li>operatori aritmetici: + ,- , *, /</li>
                                        <li>operatori relationali: {">"}, == , &lt; </li>
                                        <li>operatori logici: &&, ||,</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>delimitatori</b> (separatori):
                                    <i>exemplu de regula</i>: spatiu (' '), punct si virgula (';')
                                </li>
                            </ul>
                        </li>
                        <li><b>codifica</b>: fiecarui atom i se asociaza un cod (numar); de exemplu: pentru un idenficator se asociaza codul 1; pentru cuvantul rezervat 'while' codul 2, etc </li>
                        <li>construieste <b>tabelul de simboluri</b> (simbol table)
                            <br/>
                            un nume simbolic (identificator, constanta, etc) apare o singura data in tabela de simboluri
                        </li>
                    </ul>

                    Pentru <i>verificarea</i> regulilor (in special pentru identificatori si constante) se folosesc:
                    <ul>
                        <li><b>expresii regulate</b></li>
                        <li><b>automate finite</b></li>
                    </ul>
                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Compilers: Principles, Techniques, & Tools (Second Edition) - Alfred V. Aho, Monica S. Lam, Ravi Sethi, Jeffrey D. Ullman   (Pearson, 2006)</div>
                        </li>
                        <li>
                            <div>Writing Compilers and Interpreters. A Software Engineering Approach (Third Edition) - Ronald Mak (Wiley, 2009)</div>
                        </li>
                        <li>
                            <div>Tehnici de compilare - Mircea Drăgan (2019)</div>
                        </li>
                        <li>
                            <div>Modele de proiectare a compilatoarelor - Editura Albastra - Simona Motogna (2006)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LexicalAnalysisContent;