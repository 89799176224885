import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlSetContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-set", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl set</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectrl set</b> este folosita pentru a seta o proprietate:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set <property-name> <object-type>/<object-name> <container-name>=<new-image>'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            property-name: este numele proprietatii pe care vrem s-o schimbam (ex: image)
                        </li>
                        <li>
                            object-type: tip obiect [kind] (ex: deployment)
                        </li>
                        <li>
                            object-name: nume obiect [metadata/name] (ex: client-deployment)
                        </li>
                        <li>
                            container-name: nume container [spec/template/spec/containers/name](ex: client)
                        </li>
                        <li>
                            new-image: nume imagine noua, care contine si tag-ul (ex: iulianbuzdugan/multi-client:v5)
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set image deployment/client-deployment client=iulianbuzdugan/multi-client:v5'}
                    </SyntaxHighlighter>
                    Daca nu se poate actualiza, se va folosi vechea imagine (de fapt vechiul deployment/pod ramane in picioare, pana cand noul pod este pornit cu succes)

                    <hr/>
                    Fisierul de configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        component: web\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlSetContent;