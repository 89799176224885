import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";


class JavaCryptographyEx02Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-cryptography-ex-02", IndexContent);
    }

    render() {

        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Semnăturile digitale XML</b>
                    <br/>

                    O semnătură XML constă dintr-un element <b>Signature</b> din spatiul de nume  http://www.w3.org/2000/09/xmldsig#. Structura de bază este următoarea:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                        {'<Signature>\n' +
                        '  <SignedInfo>\n' +
                        '    <CanonicalizationMethod />\n' +
                        '    <SignatureMethod />\n' +
                        '    <Reference>\n' +
                        '       <Transforms />\n' +
                        '       <DigestMethod />\n' +
                        '       <DigestValue />\n' +
                        '    </Reference>\n' +
                        '    <Reference /> etc.\n' +
                        '  </SignedInfo>\n' +
                        '  <SignatureValue />\n' +
                        '  <KeyInfo />\n' +
                        '  <Object />\n' +
                        '</Signature>'}

                    </SyntaxHighlighter>

                    <ul>
                        <li>
                            Elementul <b>SignedInfo</b> contine referintele datelor semnate și specifică ce algoritmi sunt utilizati.
                            <ul>
                                <li>
                                    Elementele <b>SignatureMethod</b> și <b>CanonicalizationMethod</b> sunt utilizate de elementul <b>SignatureValue</b> și sunt incluse în  <b>SignedInfo</b> pentru a le proteja de manipulare.
                                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                                        {'<CanonicalizationMethod Algorithm="http://www.w3.org/TR/2001/REC-xml-c14n-20010315" />\n' +
                                        '<SignatureMethod Algorithm="http://www.w3.org/2000/09/xmldsig#rsa-sha1" />'}

                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    Unul sau mai multe elemente <b>Reference</b> specifică resursa care este semnată prin referință URI și orice transformări care trebuie aplicate resursei înainte de semnare.

                                    <ul>
                                        <li><b>Transforms</b> conține transformările aplicate resursei înainte de semnare. O transformare poate fi o expresie XPath care selectează un subset definit al arborelui documentului.</li>
                                        <li><b>DigestMethod</b> specifică algoritmul hash înainte de a aplica hash-ul.</li>
                                        <li><b>DigestValue</b> conține rezultatul codificat Base64 al aplicării algoritmului hash la resursele transformate definite în <b>Reference</b> atributele elementului.</li>
                                    </ul>

                                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                                        {'<Reference URI="">\n' +
                                        '         <Transforms>\n' +
                                        '           <Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature" />\n' +
                                        '         </Transforms>\n' +
                                        '         <DigestMethod Algorithm="http://www.w3.org/2000/09/xmldsig#sha1" />\n' +
                                        '         <DigestValue>UWuYTYug10J1k5hKfonxthgrAR8=</DigestValue>\n' +
                                        '</Reference>'}

                                    </SyntaxHighlighter>

                                </li>
                                <li>
                                    Elementul <b>SignatureValue</b> conține rezultatul semnăturii codificat base64 - semnătura generată cu parametrii specificați în elementul <b>SignatureMethod</b> al elementului <b>SignedInfo</b>
                                    după aplicarea algoritmului specificat prin <b>CanonicalizationMethod</b>.

                                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                                        {'<SignatureValue>\n' +
                                        '   TSQUoVrQ0kg1eiltNwIhKPrIdsi1VhWjYNJlXvfQqW2EKk3X37X862SCfrz7v8IYJ7OorWwlFpGDStJDSR6saO\n' +
                                        '   ScqSvmesCrGEEq+U6zegR9nH0lvcGZ8Rvc/y7U9kZrE4fHqEiLyfpmzJyPmWUT9Uta14nPJYsl3cmdThHB8Bs=\n' +
                                        '</SignatureValue>'}

                                    </SyntaxHighlighter>

                                </li>
                                <li>
                                    Elementul <b>KeyInfo</b> (opțional) permite semnatarului să furnizeze destinatarilor cheia care validează semnătura, de obicei sub forma unuia sau mai multor certificate digitale X.509.
                                </li>
                                <li>
                                    Elementul <b>Object</b> (opțional) conține datele semnate în cazul în care acest lucru este o semnătură invaluitoare (enveloping).
                                </li>
                            </ul>
                        </li>

                    </ul>

                    Exista 3 tipuri de semnatura XML:
                    <ul>
                        <li>învelit/plic (enveloped):
                            semnătura este copilul obiectului care este semnat.
                            Semnătura acoperă întregul document XML care conține semnătura ca element.
                            Semnătura în plic trebuie să aibă grijă să nu includă propria valoare în calculul valorii semnăturii.


                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<DocumentulMeu id="documentDeSemnat">\n' +
                                '   <DetaliiDocument>\n' +
                                '       ...\n' +
                                '   </DetaliiDocument>\n' +
                                '   <Signature>\n' +
                                '     <SignedInfo>\n' +
                                '        <Reference URI="#documentDeSemnat">\n' +
                                '     </SignedInfo>\n' +
                                '   </Signature>' +
                                '</DocumentulMeu>'}
                            </SyntaxHighlighter>

                        </li>

                        <li>învăluitor (enveloping):
                            datele de semnat se află în interiorul semnăturii XML, fiind identificate printr-un identificator de fragment URI:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                            {'<Signature>\n' +
                            '   <SignedInfo>\n' +
                            '      <Reference URI="#obiectDeSemnat">\n' +
                            '   </SignedInfo>\n' +
                            '   <Object id="obiectDeSemnat">\n' +
                            '   ...\n' +
                            '   </Object>\n' +
                            '</Signature>'}
                            </SyntaxHighlighter>
                        </li>
                        <li>detașat (detached):
                            <ul>
                                <li>
                                    datele și semnătura nu sunt în același document:
                                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                                        {'<Signature>\n' +
                                        '   <SignedInfo>\n' +
                                        '      <Reference URI="http://somewhere.com/data.xml">\n' +
                                        '   </SignedInfo>\n' +
                                        '</Signature>'}

                                    </SyntaxHighlighter>
                                </li>

                                <li>
                                    datele și semnătura sunt în același document:
                                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                                        {'<Signature>\n' +
                                        '   <SignedInfo>\n' +
                                        '      <Reference URI="#obiectDeSemnat">\n' +
                                        '   </SignedInfo>\n' +
                                        '</Signature>\n' +
                                        '<DocumentulMeu id="obiectDeSemnat">'}

                                    </SyntaxHighlighter>
                                </li>
                            </ul>

                        </li>
                    </ul>


                    <br/>
                    <b>Referinte:</b>
                    <ul>
                        <li>
                            <a href={"https://docs.oracle.com/javase/9/security/java-xml-digital-signature-api-overview-and-tutorial.htm"}>
                                XML Digital Signature API Overview and Tutorial
                            </a>
                        </li>

                        <li>
                            <a href={"https://en.wikipedia.org/wiki/XML_Signature"}>
                                XML Signature
                            </a>
                        </li>

                        <li>
                            <a href={"https://www.giac.org/paper/gsec/1965/xml-digital-signature/103420"}>
                                XML Digital Signature by Example
                            </a>
                        </li>

                    </ul>



                    {/*<SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>*/}

                    {/*    {''}*/}

                    {/*</SyntaxHighlighter>*/}

                    {/*<br/>*/}

                    <hr/>
                    <b>STOP</b>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaCryptographyEx02Content;