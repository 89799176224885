import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ServiceWrapperLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-service-wrapper", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Pentru a creea o clasa de tip service wrapper pentru un model, clasa trebuie:
                    <ul>
                        <li>sa fie de tip <b>@Component(service = ServiceWrapper.class)</b></li>
                        <li>sa extinda <b>UserLocalServiceWrapper</b></li>
                        <li>in constructor sa se trimita <b>null</b> constructorului parinte:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public UserServiceWrapper() {\n' +
                                '        super(null);\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            sa se seteze noul service wrapper:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Reference(bind = "-")\n' +
                                'private void serviceSetter(UserLocalService userLocalService){\n' +
                                '        setWrappedService(userLocalService);\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>sa superascrie metodele de care e nevoie </li>
                    </ul>

                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package service.wrapper;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.exception.PortalException;\n' +
                        'import com.liferay.portal.kernel.model.User;\n' +
                        'import com.liferay.portal.kernel.model.UserGroupRole;\n' +
                        'import com.liferay.portal.kernel.service.ServiceContext;\n' +
                        'import com.liferay.portal.kernel.service.UserLocalService;\n' +
                        'import com.liferay.portal.kernel.service.UserLocalServiceWrapper;\n' +
                        'import com.liferay.portal.kernel.service.ServiceWrapper;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        '\n' +
                        'import java.util.List;\n' +
                        '\n' +
                        '@Component( immediate = true, service = ServiceWrapper.class)\n' +
                        'public class MyUserServiceWrapper extends UserLocalServiceWrapper {\n' +
                        '\n' +
                        '    public MyUserServiceWrapper() {\n' +
                        '        super(null);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Reference(unbind = "-")\n' +
                        '    private void serviceSetter(UserLocalService userLocalService) {\n' +
                        '        setWrappedService(userLocalService);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public User updateUser(long userId, String oldPassword, String newPassword1, String newPassword2, boolean passwordReset, String reminderQueryQuestion, String reminderQueryAnswer, String screenName, String emailAddress, boolean hasPortrait, byte[] portraitBytes, String languageId, String timeZoneId, String greeting, String comments, String firstName, String middleName, String lastName, long prefixId, long suffixId, boolean male, int birthdayMonth, int birthdayDay, int birthdayYear, String smsSn, String facebookSn, String jabberSn, String skypeSn, String twitterSn, String jobTitle, long[] groupIds, long[] organizationIds, long[] roleIds, List<UserGroupRole> userGroupRoles, long[] userGroupIds, ServiceContext serviceContext) throws PortalException {\n' +
                        '        System.out.println("UserServiceWrapper.updateUser");\n' +
                        '        return super.updateUser(userId, oldPassword, newPassword1, newPassword2, passwordReset, reminderQueryQuestion, reminderQueryAnswer, screenName, emailAddress, hasPortrait, portraitBytes, languageId, timeZoneId, greeting, comments, firstName, middleName, lastName, prefixId, suffixId, male, birthdayMonth, birthdayDay, birthdayYear, smsSn, facebookSn, jabberSn, skypeSn, twitterSn, jobTitle, groupIds, organizationIds, roleIds, userGroupRoles, userGroupIds, serviceContext);\n' +
                        '    }\n' +
                        '    \n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: service-wrapper\n' +
                        'Bundle-SymbolicName: service.wrapper\n' +
                        'Bundle-Version: 1.0.0'}
                    </SyntaxHighlighter>

                    Fisierul <b>build.gradle</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>
                                    Overriding Liferay Services (Service Wrappers)
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceWrapperLiferayContent;