import React, {useEffect, useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const InfiniteScrollComponent = () => {

    const [items, setItems] = useState([]);
    let isLoading = false;
    let error = null;
    let startItem = 0;
    let dataLength = 5;

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    const handleScroll = () => {
        if (isLoading){
            return;
        }

        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight
        const isAtBottom = window.scrollY >= scrollableHeight;

        if (isAtBottom) {
            fetchData();
        }

    };

    function fetchData() {
        isLoading = true ;
        let newItems = [];
        for(let i = startItem;i<startItem+dataLength;i++){
            newItems.push({
                id:i,
                name: "nume: "+ i
            })
        }
        startItem = startItem + dataLength;

        setItems((prev)=>[...prev, ...newItems]);
        isLoading = false;
    }

    return (
        <div>
            <ul>
                {items.length>0 && items.map(item => (
                    <li key={item.id}>{item.name}</li>
                ))}
            </ul>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
        </div>
    );
}


class InfiniteScrollReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-infinite-scroll", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Scroll infinit</b>
                    <br/>
                    <br/>

                    Functia <i>handleScroll</i> verifica daca utilizatorul a facut scroll pana in josul paginii:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const InfiniteScrollComponent = () => {\n' +
                            '\n' +
                            '    const [items, setItems] = useState([]);\n' +
                            '    let isLoading = false;\n' +
                            '    let error = null;\n' +
                            '    let startItem = 0;\n' +
                            '    let dataLength = 5;\n' +
                            '\n' +
                            '    useEffect(() => {\n' +
                            '        window.addEventListener(\'scroll\', handleScroll);\n' +
                            '        return () => window.removeEventListener(\'scroll\', handleScroll);\n' +
                            '    }, []);\n' +
                            '\n' +
                            '\n' +
                            '    const handleScroll = () => {\n' +
                            '        if (isLoading){\n' +
                            '            return;\n' +
                            '        }\n' +
                            '\n' +
                            '        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight\n' +
                            '        const isAtBottom = window.scrollY >= scrollableHeight;\n' +
                            '\n' +
                            '        if (isAtBottom) {\n' +
                            '            fetchData();\n' +
                            '        }\n' +
                            '\n' +
                            '    };\n' +
                            '\n' +
                            '    function fetchData() {\n' +
                            '        isLoading = true ;\n' +
                            '        let newItems = [];\n' +
                            '        for(let i = startItem;i<startItem+dataLength;i++){\n' +
                            '            newItems.push({\n' +
                            '                id:i,\n' +
                            '                name: "nume: "+ i\n' +
                            '            })\n' +
                            '        }\n' +
                            '        startItem = startItem + dataLength;\n' +
                            '\n' +
                            '        setItems((prev)=>[...prev, ...newItems]);\n' +
                            '        isLoading = false;\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '        <div>\n' +
                            '            <ul>\n' +
                            '                {items.length>0 && items.map(item => (\n' +
                            '                    <li key={item.id}>{item.name}</li>\n' +
                            '                ))}\n' +
                            '            </ul>\n' +
                            '            {isLoading && <p>Loading...</p>}\n' +
                            '            {error && <p>Error: {error.message}</p>}\n' +
                            '        </div>\n' +
                            '    );\n' +
                            '}'}
                    </SyntaxHighlighter>

                    unde:
                    <ul>
                        <li>
                            <b>window.innerHeight</b> = înălțimea zonei de conținut a unei ferestre (zona vizibila)
                        </li>
                        <li>
                            <b>document.documentElement.scrollHeight</b> =
                            înălțimea unui element, inclusiv padding și borders
                        </li>
                        <li>
                            <b>window.scrollY</b>:
                            numărul de pixeli pe care documentul este derulat în prezent pe verticală
                        </li>
                    </ul>

                    <hr/>
                    <InfiniteScrollComponent/>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://duncanhunter.gitbook.io/testing-angular/"}>*/}
                    {/*           Testing Angular*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://codecraft.tv/courses/angular/unit-testing/asynchronous/"}>*/}
                    {/*            Testing Asynchronous Code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InfiniteScrollReactContent;