import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class JasyptSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-jasypt", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Criptarea parolelor folosind Jasypt</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1. Adaugare dependinte</b>
                    <br/>
                    <br/>

                    In <b>pom.xml</b>, sectiunea <b>project/dependencies/</b>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<dependency>\n' +
                            '    <groupId>com.github.ulisesbocchio</groupId>\n' +
                            '    <artifactId>jasypt-spring-boot-starter</artifactId>\n' +
                            '    <version>3.0.3</version>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    In <b>pom.xml</b>, sectiunea <b>project/build/plugins</b>
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<plugin>\n' +
                            '   <groupId>com.github.ulisesbocchio</groupId>\n' +
                            '   <artifactId>jasypt-maven-plugin</artifactId>\n' +
                            '   <version>3.0.3</version>\n' +
                            '</plugin>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Adaugare adnotare @EnableEncryptableProperties pe clasa principala de tip @Configuration</b>
                    <br/>
                    <br/>

                    Se adauga adnotarea <b>@EnableEncryptableProperties</b> la clasa de configurare principală pentru ca aplicația să înțeleagă proprietățile criptabile în întregul mediu Spring.
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'pachet com.javajasypt.crud.example ;\n' +
                            'import com.ulisesbocchio.jasyptspringboot.annotation.EnableEncryptableProperties;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            '@EnableEncryptableProperties\n' +
                            'clasă publică MyApplication { \n' +
                            '     public static void main ( String [] args ) {      \n' +
                            '        ...\n' +
                            '     }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Alegere cheie secreta</b>
                    <br/>
                    <br/>

                    Cheia secreta este folosita la criptare si decriptare. Aceasta cheie e case sensitive.

                    <hr/>
                    <b>4. Criptarea unui singure valori</b>
                    <br/>
                    <br/>

                    Fie cheia <i>flutuash</i>. Pentru criptarea unei valori, de exemplu <i>parolamea</i> se ruleaza din directorul radacina al proiectului,
                    unde se gaseste si fisierul <i>pom.xml</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn jasypt:encrypt-value "-Djasypt.encryptor.password=flutuash" "-Djasypt.plugin.value=parolamea"'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] --------------------< ro.ibrid.lapretbun:lapretbun >--------------------\n' +
                            '[INFO] Building Lapretbun 0.0.1-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO]\n' +
                            '[INFO] --- jasypt-maven-plugin:3.0.3:encrypt-value (default-cli) @ lapretbun ---\n' +
                            '[INFO] Starting MavenCli v3.6.3 on desktop-lenovo-y540 with PID 21648 (C:\\tools\\maven-3.6.3\\bin\\..\\lib\\maven-embedder-3.6.3.jar started by iulianb in D:\\work\\jhipster\\jhipster-app2)\n' +
                            '[INFO] No active profile set, falling back to default profiles: default\n' +
                            '[INFO] Post-processing PropertySource instances\n' +
                            '[INFO] Converting PropertySource configurationProperties [org.springframework.boot.context.properties.source.ConfigurationPropertySourcesPropertySource] to AOP Proxy\n' +
                            '[INFO] Converting PropertySource systemProperties [org.springframework.core.env.PropertiesPropertySource] to EncryptableMapPropertySourceWrapper\n' +
                            '[INFO] Converting PropertySource systemEnvironment [org.springframework.boot.env.SystemEnvironmentPropertySourceEnvironmentPostProcessor$OriginAwareSystemEnvironmentPropertySource] to EncryptableSystemEnvironmentPropertySourceWrapper\n' +
                            '[INFO] Converting PropertySource random [org.springframework.boot.env.RandomValuePropertySource] to EncryptablePropertySourceWrapper\n' +
                            '[INFO] Property Filter custom Bean not found with name \'encryptablePropertyFilter\'. Initializing Default Property Filter\n' +
                            '[INFO] Started MavenCli in 1.129 seconds (JVM running for 3.232)\n' +
                            '[INFO] Active Profiles: Default\n' +
                            '[INFO] Encrypting value parolamea\n' +
                            '[INFO] String Encryptor custom Bean not found with name \'jasyptStringEncryptor\'. Initializing Default String Encryptor\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.algorithm, using default value: PBEWITHHMACSHA512ANDAES_256\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.key-obtention-iterations, using default value: 1000\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.pool-size, using default value: 1\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.provider-name, using default value: null\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.provider-class-name, using default value: null\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.salt-generator-classname, using default value: org.jasypt.salt.RandomSaltGenerator\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.iv-generator-classname, using default value: org.jasypt.iv.RandomIvGenerator\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.string-output-type, using default value: base64\n' +
                            '[INFO] \n' +
                            'ENC(2kjGJ6CVRBq3BHfI2NIpqF3SSCWTD1WyY8jNg0aCrkejKIZ32eFX9OjRTHNDGSvT)\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  2.250 s\n' +
                            '[INFO] Finished at: 2023-06-13T14:44:46+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>

                    Deci parola criptata este: 2kjGJ6CVRBq3BHfI2NIpqF3SSCWTD1WyY8jNg0aCrkejKIZ32eFX9OjRTHNDGSvT

                    <hr/>
                    <b>5. Decriptarea unui singure valori</b>
                    <br/>
                    <br/>

                    Fie cheia <i>flutuash</i>. Pentru decriptarea unei valori (de exemplu, ceea de mai sus: 2kjGJ6CVRBq3BHfI2NIpqF3SSCWTD1WyY8jNg0aCrkejKIZ32eFX9OjRTHNDGSvT),
                    se ruleaza din directorul radacina al proiectului, unde se gaseste si fisierul <i>pom.xml</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn jasypt:decrypt-value "-Djasypt.encryptor.password=flutuash" "-Djasypt.plugin.value=2kjGJ6CVRBq3BHfI2NIpqF3SSCWTD1WyY8jNg0aCrkejKIZ32eFX9OjRTHNDGSvT"'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] --------------------< ro.ibrid.lapretbun:lapretbun >--------------------\n' +
                            '[INFO] Building Lapretbun 0.0.1-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO] \n' +
                            '[INFO] --- jasypt-maven-plugin:3.0.3:decrypt-value (default-cli) @ lapretbun ---\n' +
                            '[INFO] Starting MavenCli v3.6.3 on desktop-lenovo-y540 with PID 15808 (C:\\tools\\maven-3.6.3\\bin\\..\\lib\\maven-embedder-3.6.3.jar started by iulianb in D:\\work\\jhipster\\jhipster-app2)\n' +
                            '[INFO] No active profile set, falling back to default profiles: default\n' +
                            '[INFO] Post-processing PropertySource instances\n' +
                            '[INFO] Converting PropertySource configurationProperties [org.springframework.boot.context.properties.source.ConfigurationPropertySourcesPropertySource] to AOP Proxy\n' +
                            '[INFO] Converting PropertySource systemProperties [org.springframework.core.env.PropertiesPropertySource] to EncryptableMapPropertySourceWrapper\n' +
                            '[INFO] Converting PropertySource systemEnvironment [org.springframework.boot.env.SystemEnvironmentPropertySourceEnvironmentPostProcessor$OriginAwareSystemEnvironmentPropertySource] to EncryptableSystemEnvironmentPropertySourceWrapper\n' +
                            '[INFO] Converting PropertySource random [org.springframework.boot.env.RandomValuePropertySource] to EncryptablePropertySourceWrapper\n' +
                            '[INFO] Property Filter custom Bean not found with name \'encryptablePropertyFilter\'. Initializing Default Property Filter\n' +
                            '[INFO] Started MavenCli in 1.124 seconds (JVM running for 3.204)\n' +
                            '[INFO] Active Profiles: Default\n' +
                            '[INFO] Decrypting value 2kjGJ6CVRBq3BHfI2NIpqF3SSCWTD1WyY8jNg0aCrkejKIZ32eFX9OjRTHNDGSvT\n' +
                            '[INFO] String Encryptor custom Bean not found with name \'jasyptStringEncryptor\'. Initializing Default String Encryptor\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.algorithm, using default value: PBEWITHHMACSHA512ANDAES_256\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.key-obtention-iterations, using default value: 1000\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.pool-size, using default value: 1\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.provider-name, using default value: null\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.provider-class-name, using default value: null\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.salt-generator-classname, using default value: org.jasypt.salt.RandomSaltGenerator\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.iv-generator-classname, using default value: org.jasypt.iv.RandomIvGenerator\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.string-output-type, using default value: base64\n' +
                            '[INFO] \n' +
                            'parolamea\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  2.292 s\n' +
                            '[INFO] Finished at: 2023-06-13T14:46:42+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>6. Criptarea credentialelor din fisierul de configurare *.yml</b>
                    <br/>
                    <br/>

                    Pentru a marca ca vrem sa criptam o valoare folosind <b>DEC</b>.
                    <br/>
                    De exemplu, fie <i>application-dev.yml</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'  datasource:\n' +
                            '    type: com.zaxxer.hikari.HikariDataSource\n' +
                            '    url: jdbc:postgresql://localhost:5432/lapretbun\n' +
                            '    username: lapretbun\n' +
                            '    password: lapretbun'}
                    </SyntaxHighlighter>

                    Marcam, cu <b>DEC</b> username si password:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'  datasource:\n' +
                            '    type: com.zaxxer.hikari.HikariDataSource\n' +
                            '    url: jdbc:postgresql://localhost:5432/lapretbun\n' +
                            '    username: DEC(lapretbun)\n' +
                            '    password: DEC(lapretbun)'}
                    </SyntaxHighlighter>

                    Apoi, rulam din linie de comanda <b>mvn jasypt:encrypt</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn jasypt:encrypt "-Djasypt.encryptor.password=fluturash" "-Djasypt.plugin.path=file:src/main/resources/config/application-dev.yml"'}
                    </SyntaxHighlighter>

                    Poate afisa:
                    <SyntaxHighlighter>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] --------------------< ro.ibrid.lapretbun:lapretbun >--------------------\n' +
                            '[INFO] Building Lapretbun 0.0.1-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO]\n' +
                            '[INFO] --- jasypt-maven-plugin:3.0.3:encrypt (default-cli) @ lapretbun ---\n' +
                            '[INFO] Starting MavenCli v3.6.3 on desktop-lenovo-y540 with PID 22356 (C:\\tools\\maven-3.6.3\\bin\\..\\lib\\maven-embedder-3.6.3.jar started by iulianb in D:\\work\\jhipster\\jhipster-app2)\n' +
                            '[INFO] No active profile set, falling back to default profiles: default\n' +
                            '[INFO] Post-processing PropertySource instances\n' +
                            '[INFO] Converting PropertySource configurationProperties [org.springframework.boot.context.properties.source.ConfigurationPropertySourcesPropertySource] to AOP Proxy\n' +
                            '[INFO] Converting PropertySource systemProperties [org.springframework.core.env.PropertiesPropertySource] to EncryptableMapPropertySourceWrapper\n' +
                            '[INFO] Converting PropertySource systemEnvironment [org.springframework.boot.env.SystemEnvironmentPropertySourceEnvironmentPostProcessor$OriginAwareSystemEnvironmentPropertySource] to EncryptableSystemEnvironmentPropertySourceWrapper\n' +
                            '[INFO] Converting PropertySource random [org.springframework.boot.env.RandomValuePropertySource] to EncryptablePropertySourceWrapper\n' +
                            '[INFO] Property Filter custom Bean not found with name \'encryptablePropertyFilter\'. Initializing Default Property Filter\n' +
                            '[INFO] Started MavenCli in 1.26 seconds (JVM running for 3.389)\n' +
                            '[INFO] Active Profiles: Default\n' +
                            '[INFO] Encrypting file src\\main\\resources\\config\\application-dev.yml\n' +
                            '[INFO] String Encryptor custom Bean not found with name \'jasyptStringEncryptor\'. Initializing Default String Encryptor\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.algorithm, using default value: PBEWITHHMACSHA512ANDAES_256\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.key-obtention-iterations, using default value: 1000\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.pool-size, using default value: 1\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.provider-name, using default value: null\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.provider-class-name, using default value: null\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.salt-generator-classname, using default value: org.jasypt.salt.RandomSaltGenerator\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.iv-generator-classname, using default value: org.jasypt.iv.RandomIvGenerator\n' +
                            '[INFO] Encryptor config not found for property jasypt.encryptor.string-output-type, using default value: base64\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  2.475 s\n' +
                            '[INFO] Finished at: 2023-06-13T14:56:14+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>

                    Fragmentul de cod din <i>application-dev.yml</i>, dupa criptare va arata in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {' datasource:\n' +
                            '    type: com.zaxxer.hikari.HikariDataSource\n' +
                            '    url: jdbc:postgresql://localhost:5432/lapretbun\n' +
                            '    username: ENC(v6kVCLgcM182FNI5eyYKW6DmgdR9j5Ukd34GfZLj91L1ImMJ0znZgqEpFyxQXP/k)\n' +
                            '    password: ENC(7n8c2S+bUhpQVhKCkvPF5++ssWyhETf11eMmS2JBv71hZu6q/tJhwIxaJCTjlc3L)'}
                    </SyntaxHighlighter>

                    Placeholder-ul <b>DEC</b> va fi inlocuit cu <b>ENC</b>

                    <hr/>
                    <b>7. Deccriptarea credentialelor din fisierul de configurare *.yml</b>
                    <br/>
                    <br/>

                    Pentru procesul invers, se ruleaza comanda <b>mvn jasypt:decrypt</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn jasypt:decrypt "-Djasypt.encryptor.password=fluturash" "-Djasypt.plugin.path=file:src/main/resources/config/application-dev.yml"'}
                    </SyntaxHighlighter>

                    Se se va modifica in fisier, ci rezultatul se va afisa in consola.

                    <hr/>
                    <b>8. Pornirea aplicatiei</b>
                    <br/>
                    <br/>
                    La pornirea aplicatie trebuie specificata cheia, <b>-Djasypt.encryptor.password</b>=fluturash:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'java -Djasypt.encryptor.password=fluturash -jar spring-boot-application.jar'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.config;\n' +
                            '\n' +
                            'import org.jasypt.encryption.StringEncryptor;\n' +
                            'import org.jasypt.encryption.pbe.PooledPBEStringEncryptor;\n' +
                            'import org.jasypt.encryption.pbe.config.SimpleStringPBEConfig;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class JasyptEncryptorConfig {\n' +
                            '\n' +
                            '    @Bean(name = "jasyptStringEncryptor")\n' +
                            '    public StringEncryptor getPasswordEncryptor() {\n' +
                            '        PooledPBEStringEncryptor encryptor = new PooledPBEStringEncryptor();\n' +
                            '        SimpleStringPBEConfig config = new SimpleStringPBEConfig();\n' +
                            '        config.setPassword("fluturash"); // encryptor\'s private key\n' +
                            '        config.setAlgorithm("PBEWITHHMACSHA512ANDAES_256");\n' +
                            '        config.setKeyObtentionIterations("1000");\n' +
                            '        config.setPoolSize("1");\n' +
                            '        config.setProviderName("SunJCE");\n' +
                            '        config.setSaltGeneratorClassName("org.jasypt.salt.RandomSaltGenerator");\n' +
                            '        config.setIvGeneratorClassName("org.jasypt.iv.RandomIvGenerator");\n' +
                            '        config.setStringOutputType("base64");\n' +
                            '        encryptor.setConfig(config);\n' +
                            '        return encryptor;\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.appsdeveloperblog.com/spring-boot-password-encryption-using-jasypt"}>
                               Spring Boot Password Encryption Using Jasypt
                           </a>

                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JasyptSpringBootContent;