import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class HardeningNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-hardening", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Hardening</b>
                    <br/>
                    <br/>

                    Pentru a fi la zi cu securitatea, trebuie sa fim la zi un aplicatiile instalate:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get upgrade'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.1. Ascundere informatii despre server</b>
                    <br/>
                    <br/>

                    Verificare versiune nginx si verificat daca exista versiuni care repara probleme critice:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -v'}
                    </SyntaxHighlighter>
                    Daca o anumita versiune are probleme, s-ar putea sa se profite de lucru asta.
                    Un lucru care se poate face, este ascunderea versiunii de ngxin din header-ul raspunsului:
                    <SyntaxHighlighter>
                        {'Server: nginx/1.13.10'}
                    </SyntaxHighlighter>
                    Pentru a face lucru acesta in <b>nginx.config</b>, in sectiune <b>http</b> se scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'server_tokens off'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Server: nginx'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Securizare X-Frame</b>
                    <br/>
                    <br/>

                    Putem scrie propria noastra pagina html si sa punem intr-un un iframe sa se incarce o pagina de pe alt site.
                    <br/>
                    <br/>
                    Pentru a permite acest lucru, in <b>nginx.config</b>, in sectiune <b>server</b> se scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'add_header X-Frame-Options "SAMEORIGIN"'}
                    </SyntaxHighlighter>
                    Acest lucru inseamna ca domeniul de pe care vine cererea pentru iframe trebuie sa fie aceeasi cu continutul iframe-ului.
                    <br/>
                    Daca se va incerca din pagina noastra html, din nou (dupa reincercarea server-ului) se va optine eroare:
                    <br/>
                    <i>Load denied by X-Frame-Options (...) does not permit cross-origin framing</i>
                    <br/>
                    la incercarea acelui iframe.

                    <hr/>
                    <b>1.3. Securizare X-XSS-Protection / Cross Site Script Protection</b>
                    <br/>
                    <br/>
                    Daca se detecteaza cross site scripting dezactiveaza incarcarea paginii
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'add_header X-XSS-Protection "1; mode=block";'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>1.4. Eliminare module nginx nefolosite sau cu potential de risc</b>
                    <br/>
                    <br/>

                    Din directorul cu sursa nginx:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./configure --help | grep without'}
                    </SyntaxHighlighter>

                    Se poate elimina de exemplu:
                    <ul>
                        <li>--without-http-autoindex_module</li>
                    </ul>

                    Pentru a elimina de exemplu <i>--without-http-autoindex_module</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -V'}
                    </SyntaxHighlighter>
                    (pentru a lua configurarile = [vechile_configurari])

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./configure -without-http-autoindex_module + [vechile_configurari]'}
                    </SyntaxHighlighter>

                    Compilare configurari:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'make'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'make install'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HardeningNginxContent;