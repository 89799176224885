import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class VolumeDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-volume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Drivere de stocare</b>
                    <br/>
                    <br/>

                    O imagine Docker este construită dintr-o serie de straturi (layere).
                    <br/>
                    Storage Driver reunește toate aceste lucruri.
                    <br/>
                    Există mai multe drivere de stocare disponibile cu diferite compromisuri.

                    <br/>
                    <b>Strategia copiere la scriere (CoW)</b>
                    <br/>
                    <br/>
                    "Copiere la scriere" înseamnă: toată lumea are o singură copie partajată a acelorași date până când sunt scrise, apoi se face o copie.

                    <hr/>

                    Există diverse drivere de stocare disponibile în Docker, acestea includ:
                    <ul>
                        <li>
                            AUFS
                        </li>
                        <li>
                            Device Mapper
                        </li>
                        <li>
                            OverlayFS
                        </li>
                        <li>
                            ZFS
                        </li>
                        <li>
                            VFS
                        </li>
                        <li>
                            Brtfs
                        </li>
                    </ul>

                    <hr/>
                    Pentru a afla Storage Driver:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info | grep "Storage Driver"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {' Storage Driver: overlay2'}
                    </SyntaxHighlighter>
                    deci: Storage Driver: <b>overlay2</b>

                    <hr/>
                    Date sunt tinute in locatia:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /var/lib/docker/overlay2'}
                    </SyntaxHighlighter>
                    In <b>/var/lib/docker/overlay2/l</b> se tin link-uri catre layerele propriu-zice.


                    <hr/>
                    <b>Joaca: layerele unei imagini</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull php'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Using default tag: latest\n' +
                            'latest: Pulling from library/php\n' +
                            '3f4ca61aafcd: Already exists\n' +
                            '460703cf6140: Pull complete\n' +
                            'eba06349db87: Pull complete\n' +
                            '9130a4183abd: Pull complete\n' +
                            'c5985e9936b4: Pull complete\n' +
                            'dd46959f6994: Pull complete\n' +
                            '5683f482de2e: Pull complete\n' +
                            'fcd5aae60867: Pull complete\n' +
                            'b88374f492c2: Pull complete\n' +
                            'Digest: sha256:fa840989efc52df732278f59377e780f65d6aa8c384bb9724babf190b49f4c35\n' +
                            'Status: Downloaded newer image for php:latest\n' +
                            'docker.io/library/php:latest\n'}
                    </SyntaxHighlighter>
                    In directorul <i>/var/lib/docker/overlay2</i> vom avea un nou director: <i>2daa67f39201936a910e77bc4417e98b9e95a01888a3206fe8577f3ac8fbcd4a</i>
                    (stim ca e nou dupa data creeari, dar nu stim al catelea layer al imaginii este)

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd 2daa67f39201936a910e77bc4417e98b9e95a01888a3206fe8577f3ac8fbcd4a'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'committed  diff  link  lower  work'}
                    </SyntaxHighlighter>

                    Afisam link-ul layer-ului de sub:
                    <SyntaxHighlighter>
                        {'nano lower'}
                    </SyntaxHighlighter>
                    va afisa: l/KINVTGM55VT2EAWWXMS6JE36HE

                    Afisam link-ul layer-ului curent
                    <SyntaxHighlighter>
                        {'nano link'}
                    </SyntaxHighlighter>
                    va afisa: ZUMT34NK6RMXCOMHW4ZYKSBLC3

                    <br/>
                    daca mergem in
                    <SyntaxHighlighter>
                        {'cd /var/lib/docker/overlay2/l'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'ls -l'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {
                            'lrwxrwxrwx 1 root root 72 ian  4 20:20 ZUMT34NK6RMXCOMHW4ZYKSBLC3 -> ../2daa67f39201936a910e77bc4417e98b9e95a01888a3206fe8577f3ac8fbcd4a/diff\n' +
                            'lrwxrwxrwx 1 root root 72 dec 31 11:39 KINVTGM55VT2EAWWXMS6JE36HE -> ../16afb6a586582527d72a69e92dd20946c8adac5f8ed202e17752a570c78080ac/diff' }
                    </SyntaxHighlighter>

                    daca merge in:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                    {'cd 16afb6a586582527d72a69e92dd20946c8adac5f8ed202e17752a570c78080ac'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'committed  diff  link'}
                    </SyntaxHighlighter>
                    (deci nu exista layer sub acesta)

                    <SyntaxHighlighter>
                        {'nano link'}
                    </SyntaxHighlighter>
                    va afisa: KINVTGM55VT2EAWWXMS6JE36HE

                    <SyntaxHighlighter>
                        {'cd ls'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'bin  boot  dev  etc  home  lib  lib64  media  mnt  opt  proc  root  run  sbin  srv  sys  tmp  usr  var'}
                    </SyntaxHighlighter>
                    (deci primul layer <i>2daa67f39201936a910e77bc4417e98b9e95a01888a3206fe8577f3ac8fbcd4a</i> este de fapt penultimul,
                    iar <i>16afb6a586582527d72a69e92dd20946c8adac5f8ed202e17752a570c78080ac</i> este ultimul - baza)
                    <hr/>

                    <b>2. Block vs Object Storage</b>
                    <br/>
                    <br/>

                    În stocarea în bloc, datele sunt stocate în termeni de blocuri.
                    <br/>
                    Datele stocate în blocuri sunt în mod normal citite sau scrise în întregime un bloc întreg în același timp.
                    <br/>
                    Majoritatea sistemelor de fișiere se bazează pe dispozitive bloc.

                    <br/>
                    Stocare in bloc:
                    <ul>
                        <li>
                            Fiecare bloc are o adresă și aplicația poate fi apelată prin apel SCSI prin adresa sa
                        </li>
                        <li>
                            Nu există metadate de stocare asociate blocului, cu excepția adresei.
                        </li>
                        <li>
                            Nu are descriere, nici proprietar
                        </li>
                        <li>
                            fisierul este spart si stocat in blocuri de dimensiuni fixe
                        </li>
                        <li>
                            capacitatea poate fi marita, adaugan mai multe noduri
                        </li>
                        <li>
                            potrivite pentru: baze de date, data tranactionale, IOPS ridicat
                        </li>
                    </ul>

                    <br/>
                    Stocare in obiecte:
                    <ul>
                        <li>
                            este o arhitectură de stocare a datelor care gestionează datele ca obiecte, spre deosebire de blocuri de stocare.
                        </li>
                        <li>
                            Un obiect este definit ca date (fișier) împreună cu toate metadatele sale care sunt combinate împreună ca un obiect.
                        </li>
                        <li>
                            acest obiect primește un ID care este calculat din conținutul obiectului (din date și metadate);
                            aplicația poate apela apoi obiectul cu ID-ul unic al obiectului.
                        </li>
                        <li>
                            nu sunt asa rapide ca stocarea in blocuri
                        </li>
                        <li>
                            fisiere pot fi distribuite in diferite noduri fizice
                        </li>
                        <li>
                            poate stoca un numar nelimitat de fisiere
                        </li>
                        <li>
                            mentine file revisions
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Schimbare driver de stocare</b>
                    <br/>
                    <br/>
                    Pentru acesta operatie trebuie oprit docker:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl stop docker'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /etc/docker'}
                    </SyntaxHighlighter>

                    Editare/Adaugare fisier <i>daemon.json</i>:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano daemon.json'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  "storage-driver": "aufs"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl start docker'}
                    </SyntaxHighlighter>

                    Pentru a afla Storage Driver:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info | grep "Storage Driver"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {' Storage Driver: aufs'}
                    </SyntaxHighlighter>
                    deci: Storage Driver: <b>aufs</b>
                    <br/>
                    Schimband driver, nu vom mai vedea containerele!

                    <br/>
                    va exista urmatoarea locatie:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /var/lib/docker/aufs'}
                    </SyntaxHighlighter>
                    Imaginea, folosind acest driver, va fi stocat in mod diferit!
                    <br/>
                    Driverul de stocare <b>overlay</b> si <b>devicemapper</b> este deprecated, deci trebuie folosit <b>overlay2</b> (fiind si recomandat).

                    <hr/>
                    <b>3. Volume</b>
                    <br/>
                    <br/>

                    În mod implicit, toate fișierele create în interiorul unui container sunt stocate pe un strat de container care poate fi scris (R/W layer - acest layer se gaseste in locatia: /var/lib/docker/overlay2; cand se sterg conatinerul se sterge si layerul).
                    <br/>
                    Aceasta înseamnă că:
                    <ul>
                        <li>
                            datele nu persistă atunci când acel container nu mai există (depinde de ciclul de viata al containerului)
                        </li>
                        <li>
                            poate fi dificil să scoți datele din container dacă un alt proces are nevoie de ele
                        </li>
                        <li>
                            Scrierea într-un strat care poate fi scris al unui container necesită un driver de stocare pentru a gestiona sistemul de fișiere.
                            Driverul de stocare oferă un sistem de fișiere <i>union</i>, folosind nucleul Linux.
                            <br/>
                            Această abstractizare suplimentară reduce performanța în comparație cu utilizarea volumelor de date, care scriu direct în sistemul de fișiere gazdă.
                        </li>
                    </ul>

                    Docker are două opțiuni pentru containere pentru a stoca fișiere în mașina gazdă, astfel încât fișierele să fie persistente chiar și după oprirea containerului:
                    <ul>
                        <li>
                            volume {"=>"} sunt gestionate de Docker (intr-o zona de fisiere de pe masina gazda)
                        </li>
                        <li>
                            bind mounts {"=>"} monturi de legare;
                            <br/>
                            un fișier sau un director de pe mașina gazdă este montat într-un container;
                            <br/>
                            fișierul sau directorul este referit prin calea completă sau relativă pe mașina gazdă.
                            <br/>
                            nu sunt gestionate de Docker
                        </li>
                        <li>
                            tmpfs (doar daca rulați Docker pe Linux)
                        </li>
                    </ul>
                    Diferenta in declarare intre volume (-v myvol:/etc) si bind mounts (-v /myvol:/etc) este <b>/</b>.
                    <br/>
                    Un volum se poate specifica si prin indicatorul <b>--mount</b> (pe langa <b>-v</b>):
                    <SyntaxHighlighter>
                        {'--mount type=bind, source=/myvol, destination=/etc'}
                    </SyntaxHighlighter>

                    Daca nu vrem ca fisierele sa fie modificate din container:
                    <SyntaxHighlighter>
                        {'--mount type=bind, source=/myvol, destination=/etc, readonly'}
                    </SyntaxHighlighter>

                    <br/>


                    Observatii 1:
                    <ul>
                        <li>
                            Un anumit volum poate fi montat în mai multe containere simultan
                        </li>
                        <li>
                            Când niciun container care rulează nu folosește un volum, volumul este încă disponibil pentru Docker și nu este eliminat automat.
                        </li>
                        <li>
                            Când montați un volum, acesta poate fi:
                            <ul>
                                <li>
                                    named  (numit)
                                </li>
                                <li>
                                    anonymous (anonim) {"=>"} Volumele anonime nu primesc un nume explicit atunci când sunt montate pentru prima dată într-un container, așa că Docker le oferă un nume aleatoriu care este garantat a fi unic într-o anumită gazdă Docker;
                                    <br/>
                                    volumele anomime exista cat containerele exista! (sunt sterse cand containerul este sters; se creeaza cand containerul este creat)
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Observatii 2:
                    <ul>
                        <li>
                            Volumul este mai ușor de copiat sau de migrat decât monturile de legare.
                        </li>
                        <li>
                            Puteți gestiona volume folosind comenzile Docker CLI sau API-ul Docker.
                        </li>
                        <li>
                            Volumele funcționează atât pe containerele Linux, cât și pe Windows.
                        </li>
                        <li>
                            Volumele pot fi partajate mai sigur între mai multe containere.
                        </li>
                        <li>
                            Driverele de volum vă permit să stocați volume pe gazde la distanță sau pe furnizorii de cloud, pentru a cripta conținutul volumelor sau pentru a adăuga alte funcționalități.
                        </li>
                        <li>
                            Noile volume pot avea conținutul pre-populat de un container.
                        </li>
                        <li>
                            Volumele de pe Docker Desktop au performanțe mult mai mari decât monturile de legare de la gazdele Mac și Windows.
                        </li>
                        <li>
                            volumele sunt adesea o alegere mai bună decât datele persistente în stratul de scriere al unui container, deoarece un volum nu mărește dimensiunea containerelor care îl folosesc, iar conținutul volumului există în afara ciclului de viață al unui container dat.
                        </li>

                    </ul>

                    <hr/>
                    Pentru a elimina automat volumele anonime, utilizați <b>--rm</b> opțiunea.
                    <br/>
                    De exemplu, urmatoarea comandă creează un volum anonim <i>/foo</i>. Când se sterge containerul, Docker Engine elimină volumul <i>/foo</i>, dar nu și volumul <i>awesome</i>.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --rm -v /foo -v awesome:/bar busybox top'}
                    </SyntaxHighlighter>

                    Dacă un alt container se leaga la volume cu <b>--volumes-from</b>, definițiile de volum sunt copiate și volumul anonim rămâne și după ce primul container este îndepărtat.

                    <hr/>
                    Comenzi:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume --help'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume ls'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume create [nume-volum]'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume rm [nume-volum]'}
                    </SyntaxHighlighter>
                    daca este folosit, se va o eroare similara:
                    <SyntaxHighlighter>
                        {'Error response from daemon: remove kj-vol: volume is in use - [f5c69bd67b4a30506bfa6b57eefea52fd77cb3209e7703efb9d1dd9a0a473d0d]'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume inspect [nume-volum]'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "CreatedAt": "2023-01-05T13:00:23+02:00",\n' +
                            '        "Driver": "local",\n' +
                            '        "Labels": {},\n' +
                            '        "Mountpoint": "/var/lib/docker/volumes/kj-vol/_data",\n' +
                            '        "Name": "kj-vol",\n' +
                            '        "Options": {},\n' +
                            '        "Scope": "local"\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>
                    de pe masina gazda:
                    <SyntaxHighlighter>
                        {'cd /var/lib/docker/volumes/kj-vol/_data'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'ls'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'adduser.conf            ca-certificates       debian_version  environment  group-     hostname  issue.net     libaudit.conf  motd           opt         passwd-    rc2.d  rcS.d        shadow   subgid    ucf.conf\n' +
                            'alternatives            ca-certificates.conf  default         fonts        gshadow    hosts     kernel        localtime      mtab           os-release  profile    rc3.d  resolv.conf  shadow-  subuid    update-motd.d\n' +
                            'apt                     cron.d                deluser.conf    fstab        gshadow-   init.d    ld.so.cache   login.defs     netconfig      pam.conf    profile.d  rc4.d  rmt          shells   systemd   xattr.conf\n' +
                            'bash.bashrc             cron.daily            dpkg            gai.conf     gss        inputrc   ld.so.conf    logrotate.d    nginx          pam.d       rc0.d      rc5.d  security     skel     terminfo\n' +
                            'bindresvport.blacklist  debconf.conf          e2scrub.conf    group        host.conf  issue     ld.so.conf.d  mke2fs.conf    nsswitch.conf  passwd      rc1.d      rc6.d  selinux      ssl      timezone\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume prune'}
                    </SyntaxHighlighter>

                    Volumele sunt tinute pe masina gazda in locatia: <b>/var/lib/docker/volumes</b>

                    Daca la crearea unui container se specifica un volum:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -itd -v kj-vol:/etc --name my_enginx nginx'}
                    </SyntaxHighlighter>
                    acesta va lega volumul <i>kj-vol</i> de locatia din container <i>/etc</i>.
                    <br/>
                    daca volumul <i>kj-vol</i> nu ar fi fost creat s-ar fi creat in mod automat!
                    <hr/>
                    In Dockerfile se pot specifica volume prin instructiunea VOLUME [cale_din_container]:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM ubuntu\n' +
                            'RUN mkdir /myvol\n' +
                            'RUN echo "hello world" > /myvol/greeting\n' +
                            'VOLUME /myvol'}
                    </SyntaxHighlighter>
                    Directorul gazdă este declarat în timpul rulării containerului.
                    <br/>
                    Directorul gazdă (punctul de montare) este, prin natura sa, dependent de gazdă.
                    Acest lucru este pentru a păstra portabilitatea imaginii, deoarece un anumit director gazdă nu poate fi garantat că va fi disponibil pe toate gazdele.
                    Din acest motiv, <b>nu puteți monta un director gazdă din Dockerfile</b>.
                    Instrucțiunea VOLUME nu acceptă specificarea unui host-dir parametru.
                    Trebuie să specificați punctul de montare când creați sau rulați containerul.
                    <br/>

                    <hr/>
                    <b>4. Device Mapper (deprecated)</b>
                    <br/>
                    <br/>

                    <b>Device Mapper</b> este un framework oferit de kernel-ul Linux pentru maparea dispozitivelor bloc fizice pe dispozitive bloc virtuale de nivel superior.
                    <br/>
                    Device Mapper funcționează prin transmiterea datelor de la un dispozitiv bloc virtual, care este furnizat de dispozitivul de cartografiere însuși, către un alt dispozitiv bloc.

                    <br/>

                    Device Mapper creează dispozitive logice deasupra dispozitivului bloc fizic și oferă caracteristici precum: RAID, Criptare, Cache și diverse altele.

                    <br/>


                    Există două moduri pentru driverul de stocare devicemapper:
                    <ul>
                        <li>
                            modul loop-lvm:
                            <ul>
                                <li>
                                    Ar trebui utilizat numai în mediul de testare
                                </li>
                                <li>
                                    Utilizează mecanismul de loopback care este în general pe partea mai lentă.
                                </li>
                            </ul>
                        </li>
                        <li>
                            modul direct-lvm
                            <ul>
                                <li>
                                    Gazdele de producție care utilizează driverul de stocare devicemapper trebuie să utilizeze modul direct-lvm.
                                </li>
                                <li>
                                    Acesta este mult mai rapid decât modul loop-lvm.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br/>

                    Pentru a afla Storage Driver:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info | grep "Storage Driver"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {' Storage Driver: overlay2'}
                    </SyntaxHighlighter>
                    deci: Storage Driver: <b>overlay2</b>

                    <hr/>
                    Date sunt tinute in locatia:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /var/lib/docker/overlay2'}
                    </SyntaxHighlighter>
                    Acest driver se poate modifica:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano /etc/docker/daemon.json'}
                    </SyntaxHighlighter>
                    cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'{\n' +
                            '"storage-driver":"devicemapper"\n' +
                            '}'}
                    </SyntaxHighlighter>
                    (dupa care e nevoie de restart la docker)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl restart docker'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl status docker'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>5. Logging Driver</b>
                    <br/>
                    <br/>
                    Comenzile UNIX și Linux deschid de obicei trei fluxuri I/O atunci când rulează, numite <b>STDIN</b>, <b>STDOUT</b> și <b>STDERR</b>.
                    <br/>
                    Există o mulțime de <i>logging driver</i>  disponibile în Docker, unele dintre acestea includ:
                    <ul>
                        <li>
                            json-file
                        </li>
                        <li>
                            none
                        </li>
                        <li>
                            syslog
                        </li>
                        <li>
                            local
                        </li>
                        <li>
                            journald
                        </li>
                        <li>
                            splunk
                        </li>
                        <li>
                            awslogs
                        </li>
                    </ul>
                    Comanda <b>docker logs</b> nu este disponibilă pentru alte drivere decât <b>json-file</b> și <b>journald</b>.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info | grep "Logging Driver"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {' Logging Driver: json-file'}
                    </SyntaxHighlighter>

                    Pentru a afisa logging driverele disponibile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info | grep "Log:"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Log: awslogs fluentd gcplogs gelf journald json-file local logentries splunk syslog'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl status [nume-container]'}
                    </SyntaxHighlighter>

                    Containerul scrie log-urile intr-un fisier; pentru a afla calea acestuia:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect b6a4c4a2a9c4 --format "{{.LogPath}}"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'/var/lib/docker/containers/b6a4c4a2a9c4765df1903c714a95ce3822317bf2df5f03acac9b15b0c7d7ac5b/b6a4c4a2a9c4765df1903c714a95ce3822317bf2df5f03acac9b15b0c7d7ac5b-json.log'}
                    </SyntaxHighlighter>

                    pentru a porni un container cu alt logging driver, se foloseste optiunea <b>--log-driver</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -d --name altlog --log-driver none busybox ping google.com'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker logs altlog'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'Error response from daemon: configured logging driver does not support reading'}
                    </SyntaxHighlighter>
                    Pentru ca comanda <b>docker logs</b> nu este disponibilă pentru alte drivere decât <b>json-file</b> și <b>journald</b>.


                    <hr/>
                    <b>6. Volume in Kubernetes</b>
                    <br/>
                    <br/>

                    Kubernetes acceptă mai multe tipuri de volume:
                    <ul>
                        <li>EBS</li>
                        <li>cinder</li>
                        <li>glusterfs</li>
                        <li>local</li>
                        <li>nfs</li>
                    </ul>

                    <hr/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: test-pd\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - image: registry.k8s.io/test-webserver\n' +
                            '    name: test-container\n' +
                            '    volumeMounts:\n' +
                            '    - mountPath: /test-pd\n' +
                            '      name: test-volume\n' +
                            '  volumes:\n' +
                            '  - name: test-volume\n' +
                            '    hostPath:\n' +
                            '      # directory location on host\n' +
                            '      path: /data\n' +
                            '      # this field is optional\n' +
                            '      type: Directory'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un <b>PersistentVolume (PV)</b> este o bucată de stocare dintr-un cluster care a fost furnizata de:
                    <ul>
                        <li>un administrator / Ops Team</li>
                        <li>dinamic folosind <b>Storage Classes</b> (clase de stocare)</li>
                    </ul>
                    Fiecare volum care este creat poate fi de tip diferit (EBS, NFS, etc).

                    <br/>
                    <br/>
                    Un <b>PersistentVolumeClaim</b> este o solicitare de stocare de către un utilizator/dezvoltator.
                    <br/>
                    În cadrul revendicării, utilizatorul trebuie să specifice dimensiunea volumului împreună cu modul de acces.
                    <br/>
                    (de exemplu, un dezvoltator: Vreau un volum de dimensiunea de 10 GB care are o viteză de Fast pentru podul meu)

                    <hr/>
                    Etape de lucru:
                    <ul>
                        <li>
                            Administratorul de stocare se ocupă de crearea PV:
                            <br/>
                            Exemplu PV (fisier: pv.yaml)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'apiVersion: v1\n' +
                                    'kind: PersistentVolume\n' +
                                    'metadata:\n' +
                                    '  name: host-pv\n' +
                                    'spec:\n' +
                                    '  capacity: \n' +
                                    '    storage: 10Gi\n' +
                                    '  volumeMode: Filesystem\n' +
                                    '  storageClassName: standard\n' +
                                    '  accessModes:\n' +
                                    '    - ReadWriteOnce\n' +
                                    '  hostPath:\n' +
                                    '    path: /data\n' +
                                    '    type: DirectoryOrCreate'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl apply -f pv.yaml'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'persistentvolume/host-pv created'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get pv'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'NAME                                       CAPACITY   ACCESS MODES   RECLAIM POLICY   STATUS      CLAIM                                      STORAGECLASS   REASON   AGE\n' +
                                    'host-pv                                    10Gi       RWO            Retain           Available                                              standard                90s\n'}
                            </SyntaxHighlighter>

                            Stari PV:
                            <ul>
                                <li>available {"=>"} PV este liber si nu este revendicat / mărginit</li>
                                <li>bound {"=>"} revendicat / mărginit</li>
                                <li>
                                    released {"=>"} PVC este sters si PV este liber
                                </li>
                                <li>failed {"=>"} PV nu a reusit reclamatia automata</li>
                            </ul>

                        </li>
                        <li>
                            Dezvoltatorul poate ridica o „Revendicare” (vreau un anumit tip de PV)

                            <br/>
                            Exemplu PVC (fisier: pvc.yaml)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'apiVersion: v1\n' +
                                    'kind: PersistentVolumeClaim\n' +
                                    'metadata:\n' +
                                    '  name: host-pvc\n' +
                                    'spec:\n' +
                                    '  #volumeName: host-pv\n' +
                                    '  accessModes:\n' +
                                    '    - ReadWriteOnce\n' +
                                    '  storageClassName: standard\n' +
                                    '  resources:\n' +
                                    '    requests: \n' +
                                    '      storage: 1Gi'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl apply -f pvc.yaml'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'persistentvolumeclaim/host-pvc created'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get pvc'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'NAME                               STATUS   VOLUME                                     CAPACITY   ACCESS MODES   STORAGECLASS   AGE\n' +
                                    'host-pvc                           Bound    host-pv                                    10Gi       RWO            standard       44s\n'}
                            </SyntaxHighlighter>

                            Stari PV:
                            <ul>

                                <li>bound {"=>"} PVC este legat la un PV</li>

                                <li>pending {"=>"} PVC este legat la un PV</li>
                            </ul>
                        </li>
                        <li>
                            Faceți referire la revendicarea respectivă în fișierul PodSpec;

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'apiVersion: apps/v1\n' +
                                    'kind: Deployment\n' +
                                    'metadata:\n' +
                                    '  name: story-deployment\n' +
                                    'spec: \n' +
                                    '  replicas: 2\n' +
                                    '  selector:\n' +
                                    '    matchLabels:\n' +
                                    '      app: story\n' +
                                    '  template:\n' +
                                    '    metadata:\n' +
                                    '      labels:\n' +
                                    '        app: story\n' +
                                    '    spec:\n' +
                                    '      containers:\n' +
                                    '        - name: story\n' +
                                    '          image: iulianbuzdugan/kub-data-demo:1\n' +
                                    '          volumeMounts:\n' +
                                    '            - mountPath: /app/story\n' +
                                    '              name: story-volume\n' +
                                    '      volumes:\n' +
                                    '        - name: story-volume\n' +
                                    '          persistentVolumeClaim:\n' +
                                    '            claimName: host-pvc'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    Observatie:
                    <b>Odată ce un PV este legat de un PVC, acel PV este în esență legat de proiectul PVC-ului și nu poate fi legat de un alt PVC . Există o mapare unu-la-unu a PV-urilor și PVC-urilor!</b>

                    <hr/>
                    Se poate întâmpla ca volumul persistent să se umple și să fie nevoie de extinderea spațiul de stocare pentru o capacitate suplimentară.
                    <br/>

                    Pasi pentru a realiza acest lucru:
                    <ul>
                        <li>Activați extinderea volumului (Volume Expansion) {"=>"}
                            <ul>
                                <li>
                                    Extinderea volumului trebuie să fie activată în clasa de stocare.
                                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                        {' kubectl describe storageclass standard'}
                                    </SyntaxHighlighter>
                                    poate afisa:
                                    <SyntaxHighlighter>
                                        {'Name:            standard\n' +
                                            'IsDefaultClass:  Yes\n' +
                                            'Annotations:     kubectl.kubernetes.io/last-applied-configuration={"apiVersion":"storage.k8s.io/v1","kind":"StorageClass","metadata":{"annotations":{"storageclass.kubernetes.io/is-default-class":"true"},"labels":{"addonmanager.kubernetes.io/mode":"EnsureExists"},"name":"standard"},"provisioner":"k8s.io/minikube-hostpath"}\n' +
                                            ',storageclass.kubernetes.io/is-default-class=true\n' +
                                            'Provisioner:           k8s.io/minikube-hostpath\n' +
                                            'Parameters:            <none>\n' +
                                            'AllowVolumeExpansion:  <unset>\n' +
                                            'MountOptions:          <none>\n' +
                                            'ReclaimPolicy:         Delete\n' +
                                            'VolumeBindingMode:     Immediate\n' +
                                            'Events:                <none>\n'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                        {'kubectl get  storageclass'}
                                    </SyntaxHighlighter>
                                    poate afisa:
                                    <SyntaxHighlighter>
                                        {'NAME                 PROVISIONER                RECLAIMPOLICY   VOLUMEBINDINGMODE   ALLOWVOLUMEEXPANSION   AGE\n' +
                                            'standard (default)   k8s.io/minikube-hostpath   Delete          Immediate           false                  61d'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                        {'kubectl get  storageclass standard -o yaml'}
                                    </SyntaxHighlighter>
                                    poate afisa:
                                    <SyntaxHighlighter>
                                        {'apiVersion: storage.k8s.io/v1\n' +
                                            'kind: StorageClass\n' +
                                            'metadata:\n' +
                                            '  annotations:\n' +
                                            '    kubectl.kubernetes.io/last-applied-configuration: |\n' +
                                            '      {"apiVersion":"storage.k8s.io/v1","kind":"StorageClass","metadata":{"annotations":{"storageclass.kubernetes.io/is-default-class":"true"},"labels":{"addonmanager.kubernetes.io/mode":"EnsureExists"},"name":"standard"},"provisioner":"k8s.io/minikube-hostpath"}\n' +
                                            '    storageclass.kubernetes.io/is-default-class: "true"\n' +
                                            '  creationTimestamp: "2022-11-04T20:17:14Z"\n' +
                                            '  labels:\n' +
                                            '    addonmanager.kubernetes.io/mode: EnsureExists\n' +
                                            '  name: standard\n' +
                                            '  resourceVersion: "346"\n' +
                                            '  uid: c2f30771-db08-4a77-8e4d-7f7a50db88bf\n' +
                                            'provisioner: k8s.io/minikube-hostpath\n' +
                                            'reclaimPolicy: Delete\n' +
                                            'volumeBindingMode: Immediate\n'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    Asigurați-vă că <b>allowVolumeExpansion</b> este setat la true.
                                    <br/>
                                    (in cazul de mai sus AllowVolumeExpansion nu este true)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Redimensionați PVC-ul {"=>"} redimensionarea PVC-ului cu capacitatea de stocare necesară.

                            <br/>
                            se modifica: <i>storage: 1Gi</i> {"=>"} <i>storage: 25Gi</i>:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'apiVersion: v1\n' +
                                    'kind: PersistentVolumeClaim\n' +
                                    'metadata:\n' +
                                    '  name: host-pvc\n' +
                                    'spec:\n' +
                                    '  #volumeName: host-pv # se spcifica in mod explicit PV la care se face "revendicarea"\n' +
                                    '  accessModes:\n' +
                                    '    - ReadWriteOnce\n' +
                                    '  storageClassName: standard\n' +
                                    '  resources:\n' +
                                    '    requests: \n' +
                                    '      storage: 10Gi'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            Reporniți POD-ul {"=>"} Odată ce obiectul PVC este modificat, va trebui să reporniți POD-ul.
                            <br/>
                            <SyntaxHighlighter>
                                {'kubectl delete pod [POD-NAME]'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                                {'kubectl apply -f pod-manifest.yaml'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>7. Reclaim Policy / Politica de revendicare</b>
                    <br/>
                    <br/>

                    Politica de revendicare este responsabilă pentru ceea ce se întâmplă cu datele în volum persistent atunci când (PVC) a fost ștearsă.
                    <br/>
                    Tipul de politici de revendicare disponibile:
                    <ul>
                        <li>
                            <b>Retain {"=>"} Când PVC-ul este șters, PersistentVolume (PV) încă există și volumul este considerat „released”.
                                <br/>
                                Nu este încă disponibil pentru o altă revendicare, deoarece datele solicitantului anterior rămân pe volum</b>
                        </li>
                        <li>
                            Delete {"=>"} Cand PVC-ul este sters, PV este sters
                        </li>
                        <li>
                            Recycle (<b>deprecated</b>) {"=>"} daca este suportata de plugin-ul de volum de baza, politica de reciclare, efecutaza o curatare: <b>rm -rf /volume</b> pe volum
                            si il face din nou disponibil pentru o noua revendicare
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pv'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME                                       CAPACITY   ACCESS MODES   RECLAIM POLICY   STATUS   CLAIM                                      STORAGECLASS   REASON   AGE\n' +
                            'host-pv                                    10Gi       RWO            Retain           Bound    default/host-pvc                           standard                41m\n' +
                            'pvc-022cacf7-f32c-493d-a870-348fd0739e99   2Gi        RWO            Delete           Bound    default/host-pvc2                          standard                41m\n' +
                            'pvc-cd048ade-1531-437d-88a9-fd7792541580   2Gi        RWO            Delete           Bound    default/database-persistent-volume-claim   standard                61d\n'}
                    </SyntaxHighlighter>

                    daca stergem pvc-ul <i>host-pvc2</i>, care are RECLAIM POLICY = Delete:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete pvc host-pvc2'}
                    </SyntaxHighlighter>
                    se va sterge si PV-ul asociat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pv'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME                                       CAPACITY   ACCESS MODES   RECLAIM POLICY   STATUS   CLAIM                                      STORAGECLASS   REASON   AGE\n' +
                            'host-pv                                    10Gi       RWO            Retain           Bound    default/host-pvc                           standard                43m\n' +
                            'pvc-cd048ade-1531-437d-88a9-fd7792541580   2Gi        RWO            Delete           Bound    default/database-persistent-volume-claim   standard                61d\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Un administrator poate revendica manual volumul urmând pașii următori.
                    <ul>
                        <li>
                            Ștergeți PersistentVolume (PV) {"=>"}
                            Stocarea asociat în infrastructura externă (cum ar fi un volum AWS EBS, GCE PD, Azure Disk sau Cinder) mai există după ștergerea PV.
                        </li>
                        <li>
                            Curățați manual datele de pe stocarea asociat în consecință.
                        </li>
                        <li>
                            Ștergeți manual materialul de stocare asociat sau, dacă doriți să reutilizați același material de stocare,
                            creați un nou PersistentVolume (PV) cu definiția materialului de stocare.
                        </li>
                    </ul>

                    Se poate modifica RECLAIM POLICY pentru un PV, editand PV-ul:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl edit pv pvc-cd048ade-1531-437d-88a9-fd7792541580'}
                    </SyntaxHighlighter>
                    si se modifica <b>persistentVolumeReclaimPolicy</b> din Delete in Retain:
                    <SyntaxHighlighter>
                        {'persistentVolumeReclaimPolicy: Delete'}
                    </SyntaxHighlighter>
                    {"=>"}
                    <SyntaxHighlighter>
                        {'persistentVolumeReclaimPolicy: Retain'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>9. Storage Classes / Clase de stocare</b>
                    <br/>
                    <br/>
                    Un StorageClass oferă administratorilor o modalitate de a descrie „clasele” de stocare pe care le oferă.
                    <br/>

                    In PVC, se specifica folosind prin <b>storageClassName</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  name: host-pvc\n' +
                            'spec:\n' +
                            '  #volumeName: host-pv # se spcifica in mod explicit PV la care se face "revendicarea"\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  storageClassName: standard\n' +
                            '  resources:\n' +
                            '    requests: \n' +
                            '      storage: 10Gi'}
                    </SyntaxHighlighter>

                    <hr/>

                    Diferitele clase se pot mapa la:
                    <ul>
                        <li>
                            niveluri de calitate a serviciului
                        </li>
                        <li>
                            politici de rezervă (backup)
                        </li>
                        <li>
                            politici arbitrare determinate de administratorii clusterului
                        </li>
                    </ul>

                    <b>Storage Class Resource</b> {"=>"} Fiecare StorageClass conține câmpurile:
                    <ul>
                        <li><b>provisioner</b> {"=>"} determină ce plugin de volum este utilizat pentru furnizarea de PV;
                            <br/>exemple de provisioner:
                            <ul>
                                <li>
                                    AWS Elastic Block Store
                                </li>
                                <li>AzureFile</li>
                                <li>Local</li>
                                <li>
                                    StorageOS
                                </li>
                                <li>Glusterfs</li>
                            </ul>
                            (ex: kubernetes.io/aws-ebs)
                        </li>
                        <li><b>parameters</b></li>
                        <li><b>reclaimPolicy</b></li>

                        <li><b>allowVolumeExpansion</b>(optional)</li>
                    </ul>
                    care sunt utilizate atunci când un <b>PersistentVolume</b> aparținând clasei trebuie să fie furnizat dinamic.

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter>
                        {'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: standard\n' +
                            'provisioner: kubernetes.io/aws-ebs\n' +
                            'parameters:\n' +
                            '  type: gp2\n' +
                            'reclaimPolicy: Retain\n' +
                            'allowVolumeExpansion: true\n' +
                            'mountOptions:\n' +
                            '  - debug\n' +
                            'volumeBindingMode: Immediate'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get sc'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME                 PROVISIONER                RECLAIMPOLICY   VOLUMEBINDINGMODE   ALLOWVOLUMEEXPANSION   AGE\n' +
                            'standard (default)   k8s.io/minikube-hostpath   Delete          Immediate           false                  61d\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VolumeDockerContent;