import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class DeploymentObjectK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-deployment-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiectul Deployment</b>
                    <br/>
                    <br/>

                    Acest tip de obiect:
                    <ul>
                        <li>controleaza (multiple) pod-uri</li>
                        <li>se stabileste o stare dorita (de exemplu, sa exista 10 pod-uri - Kubernates va porni un numar dorit de pod-uri si le pune in worker nodes cu suficiente resurse)</li>
                        <li>poate fi pus pe pauza, sters sau rolled back (in caz ca ultima versiune de container are niste probleme, de exemplu)</li>
                        <li>poate fi scalat dinamic si automat pe baza umor metrici (daca se constanta ca e nevoie de mai multe pod-uri se vor creea automat)</li>
                    </ul>

                    Exemplu: (comanda <b>kubectl</b> ruleaza pe masina locala; si trimite catre cluster-ul Kubernates o comanda):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment [nume-deployment] --image=[imagine-de-pe-docker-hub]'}
                    </SyntaxHighlighter>

                    se pot declara mai multe imagini:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment [nume-deployment] --image=[imagine-de-pe-docker-hub-1],[imagine-de-pe-docker-hub-2'}
                    </SyntaxHighlighter>

                    verificare deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployments'}
                    </SyntaxHighlighter>

                    verificare pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>

                    stergere deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete deployment [nume-deployment]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Mod functionare:
                    <ul>
                        <li>
                            <b>kubecrl</b> trimite comanda cate <b>Master Node</b> (Control Panel);
                        </li>
                        <li>
                            in <b>Master Mode</b> {"=>"} <b>Scheduler</b> analizeaza pod-urile care ruleaza si gaseste cel mai bun loc - Worker Node - pentru a creea pod-ul
                        </li>
                        <li>
                            in interiorul unui pod, <b>kubelet</b> gestioneaza pod-ul si containerele
                        </li>
                        <li>
                            se creeaza container-ul pe baza imaginii date in comanda:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl create deployment [nume-deployment] --image=[imagine-de-pe-docker-hub]'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Actualizare imagine (noua imagine <b>trebuie</b> tag-uita pentru a putea fi luata in considerare):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set image deployment/[nume-deployment] [nume-container]=[imagine-de-pe-docker-hub:tag]'}
                    </SyntaxHighlighter>

                    <hr/>

                    Acest tip de obiect este folosit pentru:
                    <ul>
                        <li>creare pod-uri</li>
                        <li>gestionare replici</li>
                        <li>atasare servicii</li>
                        <li>
                            rollout updates
                        </li>
                        <li>
                            etc
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  # selecteaza obiectele pe baza potrivirii cu labels (conditia de potrivire e cu AND)\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '      # tier: backend\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        # cheie: valoare (pot fi mai multe); se folosesc in selector\\matchLabels (vezi mai sus) \n' +
                            '        component: web\n' +
                            '        # tier: backend\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Afisare YAML pentru un deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployment [nume-deployment] -o yaml'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  annotations:\n' +
                            '    deployment.kubernetes.io/revision: "1"\n' +
                            '  creationTimestamp: "2022-12-16T11:31:51Z"\n' +
                            '  generation: 3\n' +
                            '  labels:\n' +
                            '    app: nginx-deployment\n' +
                            '  name: nginx-deployment\n' +
                            '  namespace: default\n' +
                            '  resourceVersion: "3804742"\n' +
                            '  uid: f276c266-bfd8-48d2-b82d-efdaaf376e87\n' +
                            'spec:\n' +
                            '  progressDeadlineSeconds: 600\n' +
                            '  replicas: 2\n' +
                            '  revisionHistoryLimit: 10\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: nginx-deployment\n' +
                            '  strategy:\n' +
                            '    rollingUpdate:\n' +
                            '      maxSurge: 25%\n' +
                            '      maxUnavailable: 25%\n' +
                            '    type: RollingUpdate\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      creationTimestamp: null\n' +
                            '      labels:\n' +
                            '        app: nginx-deployment\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - image: nginx\n' +
                            '        imagePullPolicy: Always\n' +
                            '        name: nginx\n' +
                            '        resources: {}\n' +
                            '        terminationMessagePath: /dev/termination-log\n' +
                            '        terminationMessagePolicy: File\n' +
                            '      dnsPolicy: ClusterFirst\n' +
                            '      restartPolicy: Always\n' +
                            '      schedulerName: default-scheduler\n' +
                            '      securityContext: {}\n' +
                            '      terminationGracePeriodSeconds: 30\n' +
                            'status:\n' +
                            '  availableReplicas: 2\n' +
                            '  conditions:\n' +
                            '  - lastTransitionTime: "2022-12-16T11:31:52Z"\n' +
                            '    lastUpdateTime: "2022-12-16T11:32:37Z"\n' +
                            '    message: ReplicaSet "nginx-deployment-5fbdf85c67" has successfully progressed.\n' +
                            '    reason: NewReplicaSetAvailable\n' +
                            '    status: "True"\n' +
                            '    type: Progressing\n' +
                            '  - lastTransitionTime: "2023-01-02T19:22:00Z"\n' +
                            '    lastUpdateTime: "2023-01-02T19:22:00Z"\n' +
                            '    message: Deployment has minimum availability.\n' +
                            '    reason: MinimumReplicasAvailable\n' +
                            '    status: "True"\n' +
                            '    type: Available\n' +
                            '  observedGeneration: 3\n' +
                            '  readyReplicas: 2\n' +
                            '  replicas: 2\n' +
                            '  updatedReplicas: 2\n'}
                    </SyntaxHighlighter>

                    Afisare Json pentru un deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployment [nume-deployment] -o json'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DeploymentObjectK8sContent;