import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DiffGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-diff", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git diff</b>
                    <br/>
                    <br/>

                    Sa presupunem ca facem o modificari in fisierul urmarit <i>citeste.txt</i>

                    Atunci daca executam comanda <b>git status</b> :
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git status\n' +
                        'On branch master\n' +
                        'Changes not staged for commit:\n' +
                        '  (use "git add <file>..." to update what will be committed)\n' +
                        '  (use "git restore <file>..." to discard changes in working directory)\n' +
                        '        modified:   citeste.txt'}
                    </SyntaxHighlighter>


                    Daca vrem sa vedem si ce modificari s-au efectuat in fisierul <i>citeste.txt</i> se poate folosi comanda <b>git diff <i>nume_fisier</i></b>:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git diff citeste.txt\n' +
                        'diff --git a/citeste.txt b/citeste.txt\n' +
                        'index e69de29..b9afc42 100644\n' +
                        '--- a/citeste.txt\n' +
                        '+++ b/citeste.txt\n' +
                        '@@ -0,0 +1 @@\n' +
                        '+kj a fost aici\n' +
                        '\\ No newline at end of file'}
                    </SyntaxHighlighter>

                    Liniile marcate cu + (colorate in verde) reprezinta linie nou adaugata (ex: +kj a fost aici).
                    <br/>
                    Liniile marcate cu - (colorate in rosu) reprezinta linie stearsa.
                    <br/>
                    Daca o linie a fost editata, atunci linia veche va apare in rosu cu -, iar noua versiune va apare in verde cu +.

                    <br/>
                    <br/>

                    Pentru a afisa modificarile pentru toate fisierele: <b>git diff</b>.

                    <hr/>

                    Daca executam acum comanda: <b>git commit -m "Al doilea comit"</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git commit -m "Al doilea commit"\n' +
                        'On branch master\n' +
                        'Changes not staged for commit:\n' +
                        '  (use "git add <file>..." to update what will be committed)\n' +
                        '  (use "git restore <file>..." to discard changes in working directory)\n' +
                        '        modified:   citeste.txt\n' +
                        '\n' +
                        'no changes added to commit (use "git add" and/or "git commit -a")'}
                    </SyntaxHighlighter>
                    Nu se vor comite modificarile pentru ca pentru ca penntru a face lucrul acesta trebuie intai executata una dintre comenziile:
                    <ul>
                        <li><b>git add</b> / <b>git add -u</b> (adauga doar fisierele urmarite)</li>
                        <li><b>git commit -a</b> (nerecomandat, pentru se vor comite doar fisirele urmarite; si poate avem si fisiere noi adaugate neurmarite inca)</li>
                    </ul>

                    Pentru a comite modificarile, efectuam urmatoarele comenzi:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git add citeste.txt\n' +
                        '\n' +
                        'git commit -m "Al doilea commit"\n' +
                        '[master 72f5099] Al doilea commit\n' +
                        ' 1 file changed, 1 insertion(+)'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DiffGitContent;