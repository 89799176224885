import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ServiceWebTestUILiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-service-web-test-ui", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>JAX-RS - Servicii Web Test UI</b>
                    <br/>
                    <br/>

                    JSP:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<portlet:renderURL var="testLoginURL">\n' +
                            '    <portlet:param name="serviceName" value="Test: Login"/>\n' +
                            '    <portlet:param name="mvcRenderCommandName" value="/test/login"/>\n' +
                            '</portlet:renderURL>\n' +
                            '\n' +
                            '<div class="row" style="padding-top: 15px;">\n' +
                            '    <div class="col-3">\n' +
                            '        <div class="card text-white bg-warning  mb-3" style="max-width: 18rem;">\n' +
                            '            <a class="btn bg-warning  btn-sm" style="width: 100%;" href="${testLoginURL}">\n' +
                            '                <div class="card-header  text-white">TEST</div>\n' +
                            '                <div class="card-body">\n' +
                            '                    <p class="card-text  text-white">Login</p>\n' +
                            '                </div>\n' +
                            '            </a>\n' +
                            '        </div>\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    MVCRenderCommand:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCRenderCommand;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            '\n' +
                            '\n' +
                            'import javax.portlet.PortletException;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.portlet.RenderResponse;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + IbridNomenclatorPortletKeys.PRIMARIAGALATINOMENCLATOR,\n' +
                            '                "mvc.command.name=/test/login"\n' +
                            '        },\n' +
                            '        service = MVCRenderCommand.class\n' +
                            ')\n' +
                            'public class TestLoginRenderMvcCommand implements MVCRenderCommand {\n' +
                            '\n' +
                            '    private static final Log LOGGER = LogFactoryUtil.getLog(TestLoginRenderMvcCommand.class);\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private TestIntegrationService integrationService;\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                            '\n' +
                            '        String serviceName = ParamUtil.getString(renderRequest, "serviceName");\n' +
                            '        renderRequest.setAttribute("serviceName", serviceName);\n' +
                            '\n' +
                            '        Credentials tcInfCredentials = new Credentials();\n' +
                            '        credentials.setEmail("my@yahoo.ro");\n' +
                            '        credentials.setCnp("1234567890123");\n' +
                            '\n' +
                            '        try {\n' +
                            '            LoginResponse loginResponse = integrationService.login(credentials);\n' +
                            '            renderRequest.setAttribute("requestWS", TransformUtil.toJson(credentials));\n' +
                            '            renderRequest.setAttribute("result", TransformUtil.toJson(loginResponse));\n' +
                            '        } catch (Exception e) {\n' +
                            '            String prettyResult = TransformUtil.prettyPrintByDom4j(e.getMessage(), 2, false);\n' +
                            '            renderRequest.setAttribute("result", prettyResult.trim());\n' +
                            '        }\n' +
                            '\n' +
                            '        return "/ws/test/show.jsp";\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    /ws/test/show.jsp:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<%\n' +
                            '    String requestWS = (String) renderRequest.getAttribute("requestWS");\n' +
                            '    String info = (String) renderRequest.getAttribute("info");\n' +
                            '    String result = (String) renderRequest.getAttribute("result");\n' +
                            '    String serviceName = (String) renderRequest.getAttribute("serviceName");\n' +
                            '%>\n' +
                            '\n' +
                            '<portlet:renderURL var="homeRenderURL">\n' +
                            '    <portlet:param name="mvcRenderCommandName" value="/home"/>\n' +
                            '</portlet:renderURL>\n' +
                            '\n' +
                            '<div class="container">\n' +
                            '    <div class="ibrid-nomenclator search-container">\n' +
                            '\n' +
                            '        <div class="d-flex container toolbar alert alert-primary" style="padding: 0px;margin-top: 10px">\n' +
                            '\n' +
                            '            <div class="mr-auto p-3">\n' +
                            '                <div class="nomTitle">\n' +
                            '                    Test: <%=serviceName%>\n' +
                            '                </div>\n' +
                            '            </div>\n' +
                            '\n' +
                            '            <div class="p-2">\n' +
                            '                <a class="btn btn-sm btn-info" href="<%=homeRenderURL%>">\n' +
                            '                    <clay:icon symbol="angle-left"/>\n' +
                            '                </a>\n' +
                            '            </div>\n' +
                            '\n' +
                            '        </div>\n' +
                            '\n' +
                            '\n' +
                            '\n' +
                            '        <div>\n' +
                            '\n' +
                            '            <% if (requestWS!=null){ %>\n' +
                            '                Cerere:\n' +
                            '                <div class="alert alert-success" role="alert">\n' +
                            '                    <pre><c:out value="<%=requestWS%>" escapeXml="true"/></pre>\n' +
                            '                </div>\n' +
                            '            <% } %>\n' +
                            '\n' +
                            '            Rezultat:\n' +
                            '            <div class="alert alert-warning" role="alert">\n' +
                            '                <pre><c:out value="${result}" escapeXml="true"/></pre>\n' +
                            '            </div>\n' +
                            '\n' +
                            '            <% if (info!=null){ %>\n' +
                            '            Info:\n' +
                            '            <div class="alert alert-secondary" role="alert">\n' +
                            '                <pre><c:out value="<%=info%>" escapeXml="true"/></pre>\n' +
                            '            </div>\n' +
                            '            <% } %>\n' +
                            '\n' +
                            '        </div>\n' +
                            '\n' +
                            '\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    TransformUtil:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.fasterxml.jackson.core.JsonProcessingException;\n' +
                            'import com.fasterxml.jackson.databind.ObjectMapper;\n' +
                            'import com.fasterxml.jackson.databind.ObjectWriter;\n' +
                            'import org.dom4j.DocumentHelper;\n' +
                            'import org.dom4j.io.OutputFormat;\n' +
                            'import org.dom4j.io.XMLWriter;\n' +
                            'import org.w3c.dom.Document;\n' +
                            'import org.xml.sax.InputSource;\n' +
                            '\n' +
                            'import javax.xml.parsers.DocumentBuilderFactory;\n' +
                            'import javax.xml.transform.OutputKeys;\n' +
                            'import javax.xml.transform.Transformer;\n' +
                            'import javax.xml.transform.TransformerFactory;\n' +
                            'import javax.xml.transform.dom.DOMSource;\n' +
                            'import javax.xml.transform.stream.StreamResult;\n' +
                            'import java.io.StringReader;\n' +
                            'import java.io.StringWriter;\n' +
                            'import java.io.Writer;\n' +
                            '\n' +
                            'public class TransformUtil {\n' +
                            '\n' +
                            '    public static String toJson(Object object) {\n' +
                            '        ObjectWriter ow = new ObjectMapper().writer().withDefaultPrettyPrinter();\n' +
                            '        String json = null;\n' +
                            '        try {\n' +
                            '            json = ow.writeValueAsString(object);\n' +
                            '        } catch (JsonProcessingException e) {\n' +
                            '            e.printStackTrace();\n' +
                            '        }\n' +
                            '        return json;\n' +
                            '    }\n' +
                            '\n' +
                            '    public static String prettyPrintByDom4j(String xmlString, int indent, boolean skipDeclaration) {\n' +
                            '        try {\n' +
                            '            OutputFormat format = OutputFormat.createPrettyPrint();\n' +
                            '            format.setIndentSize(indent);\n' +
                            '            format.setSuppressDeclaration(skipDeclaration);\n' +
                            '            format.setEncoding("UTF-8");\n' +
                            '\n' +
                            '            org.dom4j.Document document = DocumentHelper.parseText(xmlString);\n' +
                            '            StringWriter sw = new StringWriter();\n' +
                            '            XMLWriter writer = new XMLWriter(sw, format);\n' +
                            '            writer.write(document);\n' +
                            '            return sw.toString();\n' +
                            '        } catch (Exception e) {\n' +
                            '            throw new RuntimeException("Error occurs when pretty-printing xml:\\n" + xmlString, e);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    public static String prettyPrintByTransformer(String xmlString, int indent, boolean ignoreDeclaration) {\n' +
                            '\n' +
                            '        try {\n' +
                            '            InputSource src = new InputSource(new StringReader(xmlString));\n' +
                            '            Document document = DocumentBuilderFactory.newInstance().newDocumentBuilder().parse(src);\n' +
                            '\n' +
                            '            TransformerFactory transformerFactory = TransformerFactory.newInstance();\n' +
                            '            transformerFactory.setAttribute("indent-number", indent);\n' +
                            '            Transformer transformer = transformerFactory.newTransformer();\n' +
                            '            transformer.setOutputProperty(OutputKeys.ENCODING, "UTF-8");\n' +
                            '            transformer.setOutputProperty(OutputKeys.OMIT_XML_DECLARATION, ignoreDeclaration ? "yes" : "no");\n' +
                            '            transformer.setOutputProperty(OutputKeys.INDENT, "yes");\n' +
                            '\n' +
                            '            Writer out = new StringWriter();\n' +
                            '            transformer.transform(new DOMSource(document), new StreamResult(out));\n' +
                            '            return out.toString();\n' +
                            '        } catch (Exception e) {\n' +
                            '            throw new RuntimeException("Error occurs when pretty-printing xml:\\n" + xmlString, e);\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://lifedev-solutions.blogspot.com/2020/04/public-jax-rs-application-implementation.html"}>
                                Public JAX-RS Application Implementation
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default ServiceWebTestUILiferayContent;