import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AssignClientScopesToClientKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-assign-client-scopes-to-client", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Asignare scopuri de client la un client</b>
                    <ul>
                        <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                        <li>se face click pe <b>Clients</b> (meniul din stanga):</li>
                        <li>se alege tab-ul <b>Client Scopes</b>, apoi tab-ul <b>Setup</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l7.png'}/>
                            </div>
                        </li>
                        <li>se selecteaza din <b>Default Client Scopes</b>: <i>info</i></li>
                        <li>se apasa pe butonul <b>Add selected</b></li>
                        <li>daca vrem sa stergem un scop de client, selectem scopul de client pe care vrem sa il retragem si apasam pe butonul <b>Remove selected</b></li>
                    </ul>
                    <hr/>
                    <b>Observatie:</b>
                    <br/>
                    Acum, dupa ce am adaugat si scopul de client <b>info</b> la client <b>liferay-portal-client</b>, daca testam:
                    <ul>
                        <li><a href={"/keycloak/generate-token"}>Generare token</a></li>
                    </ul>
                    atunci vom regasi in token-ul de acces decodat:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'"scope": "profile info email",'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AssignClientScopesToClientKeycloakContent;