import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class VarJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-var", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Incepand cu Java 10, se poate folosii <b>var</b> in locul tipului pentru o variabila locala.

                    <br/>
                    Obsvervatie:
                    <ul>
                        <li><i>var</i> este mai degrabă un <i>nume de tip rezervat magic</i> decat un cuvant cheie,
                            pentru ca se poate folosi in continuare <i>var</i> ca nume de variabila, metoda sau pachet.
                        </li>
                    </ul>

                    Aceasta caracteristica poarta denumirea de <b>inferența tipului pentru o variabilă locală (local variable type inference)</b>.
                    <br/>
                    <b>Inferenta de tip in java este locala!</b>

                    <br/>
                    <br/>
                    Reguli <b>var</b>:
                    <ul>
                        <li>nu este un cuvant cheie, este un <b>nume de tip rezervat</b>;
                            <br/>
                            asta inseamna ca ca poate fi folosit ca identificator:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var var="var" // e corect'}
                            </SyntaxHighlighter>
                            dar <b>nu ca nume de clasa, interfata sau enum</b>
                        </li>
                        <li>
                            poate fi folosit la definirea <b>variabilelor locale </b> dintr-un:
                            <ul>
                                <li>constructor</li>
                                <li>metoda</li>
                                <li>bloc de initializare</li>
                            </ul>
                        </li>
                        <li>
                            nu poate fi folosit in:
                                <ul>
                                    <li>parametrii unui constructor</li>
                                    <li>parametrii unei metode</li>
                                    <li>variabile de instanta</li>
                                    <li>variabile de clasa</li>
                                </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'void metoda(var a){} // e incorect'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>trebuie initializat pe aceeasi linie (sau instructiune) unde este declarat - pentru a "descoperii" tipul</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var text\n' +
                                '="123" // e corect'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var text;\n' +
                                'text="123"; // e incorect'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var text;' +
                                'text="123"; // e incorect'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>valoarea se poate modifica, dar tipul nu</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var text="123";\n' +
                                'text=123; // e incorect'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>nu se poate initializa cu <b>null</b>, daca nu se cunoaste tipul</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var text=null; // e incorect'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var text=(String)null; // e corect'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>nu permite declararea de variabile multiple</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'var a=1,b=2; // e incorect'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    Observatii:
                    <ul>
                        <li>scopul inferentei de tip pentru o variabila locala este de a reduce textul si verbozitatea/limbuția</li>
                        <li>trebuie folosit doar acolo unde este necesar pentru a face codul mai clar, nu ca un instrument care sa fie folosit ori de cate ori este posibil</li>
                    </ul>
                    Indrumari cand sa fie folosit:
                    <ul>
                        <li>
                            la initializari simple
                        </li>
                        <li>cand intializarile se fac pe baza unui constructor sau o metoda statica</li>
                        <li>daca numele variabilei contine deja tipul</li>
                        <li>cand domeniul de aplicare este scurt</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VarJavaContent;