import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class RestoreVMMNasContent extends BaseContentPage {

    constructor(props) {
        super(props, "nas-restore-vmm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Recuperare clusterul Virtual Machine Manager după resetarea Synology NAS</b>
                    <br/>
                    <br/>

                    După ce se reseteaza NAS-ul (Mod 1):
                    <ul>
                        <li>datele de conectare ale administratorului {"->"} se reseteaza la valorile implicite</li>
                        <li>setările de rețea {"->"} se reseteaza la valorile implicite</li>
                        <li>clusterul Virtual Machine Manager va fi eliminat {"->"} dar mașinile virtuale vor rămâne</li>
                    </ul>

                    Pentru a recupera clusterul Virtual Machine Manager după resetarea Synology NAS prin Modul 1:
                    <ul>
                        <li>Se porneste <b>Virtual Machine Manager</b>, apoi faceți clic pe <b>Start</b>
                        </li>
                        <li>Verificați setările gazdei, apoi faceți clic pe <b>Next</b></li>
                        <li>Asigurați-vă că toate volumele rămân <b>neselectate</b>, apoi faceți clic pe <b>Next</b></li>
                        <li>Faceți clic pe <b>Finish</b> după ce clusterul este creat cu succes</li>
                        <li>În <b>Virtual Machine Manager {">"} Storage</b>, faceți clic pe <b>Add</b></li>
                        <li>Faceți clic pe <b>Start</b></li>
                        <li>Alegeți volumul în care ați stocat anterior mașinile virtuale; Faceți clic pe <b>Next</b></li>
                        <li>Configurați specificațiile generale pentru stocarea dvs. și faceți clic pe <b>Done</b></li>

                        <li>Va apărea un mesaj care indică faptul că volumul conține date ale mașinilor virtuale sau
                            imagini. Faceți clic pe <b>Import</b> pentru a importa mașinile virtuale din volumul dvs.</li>
                        <li>În <b>Virtual Machine Manager {">"} Virtual Machine</b>, puteți găsi mașinile virtuale recuperate.</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"}
                               href={"https://kb.synology.com/en-us/DSM/tutorial/How_do_I_recover_the_Virtual_Machine_Manager_cluster_after_resetting_my_Synology_NAS"}>
                                https://kb.synology.com/en-us/DSM/tutorial/How_do_I_recover_the_Virtual_Machine_Manager_cluster_after_resetting_my_Synology_NAS
                            </a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RestoreVMMNasContent;