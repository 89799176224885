import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Anexa1ProbabilityContent extends BaseContentPage{

    constructor(props) {
        super(props, "anexa1-probability", IndexContent);
    }

    render() {
        return (
            <div className="home article">
                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    Învățarea automată (machine learning) se referă la a face predicți.
                    Prin urmare e util să avem cunoștințe legate de teoria probabilitatilor.
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <b>Populația</b> este o multime de obiecte asupra cărora se pot face inferențe.
                    (conține toate obiectele sau observatiile posibile pentru care vrem să tragem o concluzie).
                    <br/>
                </div>
                <br/>

                <div className={"text-justify"}>
                    <i>exemple:</i>
                    <ul>
                        <li>intr-o campanie de votare</li>
                        <li>populatia este repzentata de toti oamenii care au drept de vot</li>
                        <li>toti studentii dintr-o universitate</li>
                    </ul>
                </div>

                <div className={"text-justify important"}>
                    <br/>
                    Un <b>esantion</b> (<i>en: sample</i>) este o submultime a unei populatii.
                    <br/>
                    <br/>
                    Un <b>experiment</b> este orice procedură care poate fi repetată la infinit și
                    are un <i>set de rezultate posibile</i> cunoscut sub numele de <b>spațiul eșantion</b> S.
                    Deci spatiu esantion a unui experiment contine toate rezultatele posibile.
                    <br/>
                </div>
                <br/>
                <div className={"text-justify"}>
                    <i>exemplu 1:</i>
                    <br/>
                    <div className={"padding50"}>
                        Dacă experimentul constă în aruncarea unui ban, atunci spațiul esantion este dat de multimea:
                        <MathComponent tex={String.raw`S=\{cap,pajura\}`}/>
                        sau
                        <MathComponent tex={String.raw`S=\{1,2\}`}/>
                    </div>
                    <i>exemplu 2:</i>
                    <br/>
                    <div className={"padding50"}>
                        Dacă experimentul constă în aruncarea unui zar, atunci spațiul esantion este dat de multimea:
                        <MathComponent tex={String.raw`S=\{1,2,3,4,5,6\}`}/>
                    </div>
                    <i>exemplu 3:</i>
                    <br/>
                    <div className={"padding50"}>
                        În cazul unei curse de cai, spatiu esantion este format din toate posibilele ordini ale cailor dupa linia de sfarsit.
                        Daca avem 3 cai (numerotati cu 1,2,3), vom putea avea urmatoarele (3!=6) rezultate ale cursei:
                        <MathComponent tex={String.raw`S=\{\{1,2,3\}, \{1,3,2\}, \{2,1,3\},\{2,3,1\},\{3,1,2\},\{3,2,1\}\}`}/>
                    </div>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    Se spune că un experiment este:
                    <ul>
                        <li><b>aleatoriu</b> dacă are mai multe rezultate posibile</li>
                        <li><b>determinist</b> dacă are un singur rezultat posibil</li>
                    </ul>
                    Un experiment aleatoriu care are exact două rezultate posibile (care se exclud reciproc) se numeste <b>experiment Bernoulli</b>.
                    <br/>
                    <br/>
                    Rezultatele posibile se mai numesc <b>evenimente</b>.
                    <br/>
                    Evenimentul care se realizeaza de fiecare data, la fiecare experiment se numeste <b>eveniment sigur</b> (si il putem nota cu E).
                    <br/>
                    Evenimentul care nu se realizeza niciodata in urma unui experiment se numeste <b>eveniment imposibil</b> (si se noteaza cu &empty;).
                    <br/>
                    <br/>
                </div>
                <div className={"text-justify"}>
                    <br/>
                    <i>Exemple:</i><br/>
                    exemplu de eveniment imposibil: avand un zar cu 6 fete sa iasa fata 7.
                    <br/>
                    exemplu de eveniment sigur: avand un zar sa iasa fata 1,2,3,4,5 sau 6.
                    <br/>
                    <br/>
                </div>
                <div className={"text-justify important"}>
                    <b>Probabilitatea</b> cuantifică probabilitatea unui eveniment.
                    <br/>
                    În mod specific, cuantifică cât de probabil este un rezultat specific pentru o <i>variabilă aleatorie</i>,
                    cum ar fi răsturnarea unei monede, aruncarea unui zar sau extragerea unei cărți de joc dintr-un pachet.
                    <br/><br/>
                    O <b>distribuție de probabilitate</b> (repartiție) este o listă a tuturor rezultatelor posibile ale unei variabile aleatorii,
                    împreună cu valorile lor de probabilitate corespunzătoare.
                    <br/>
                    De exemplu, distributia de probabilitate la aruncarea unui zar (aruncarea unui zar poate fi vazuta caa o variabila aleatoare) cu 6 fete:
                    <table>
                        <tr>
                            <td>Rezultat</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                        </tr>
                        <tr>
                            <td>Probabilitate</td>
                            <td>1/6</td>
                            <td>1/6</td>
                            <td>1/6</td>
                            <td>1/6</td>
                            <td>1/6</td>
                            <td>1/6</td>
                        </tr>
                    </table>
                    <br/>


                    Deci, distribuția probabilității este o modalitate de a vorbi despre organizarea unei liste de probabilități.
                    Distribuția poate fi, în unele cazuri, listată. În alte cazuri, este prezentat sub formă de grafic.
                    <br/>
                    În cazul variabilelor discrete, distribuția identifică valorile și probabilitatea fiecărei valori (vezi tabelul de mai sus cu distributia de probabilitate la aruncarea unui zar).
                    <br/>
                    Pentru variabilele continue, distribuția identifică valorile posibile și probabilitatea intervalelor de valori.
                    <br/><br/>

                    Deci, pentru o variabila aleatoare discreta X, toata informatia legata de aceasta variabila este constinută in distributia ei:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`X:(\begin{eqnarray} ... x_i... \\ ...p_i...  \end{eqnarray})`}/>
                    </div>
                    unde
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`x_i - valorile \space variabilei \space aleatoare \space X`}/>
                        <MathComponent tex={String.raw`p_i - probabilitatile \space pentru \space x_i`}/>
                    </div>

                    <i>Exemplu</i>:
                    Pentru distributia de probabilitate la aruncarea unui zar, avem:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`X:(\begin{eqnarray}  1 \space \space \space 2 \space \space \space 3  \space \space  4 \space \space \space 5 \space \space \space 6 \space \space \\ \space {1 \over 6} \space {1 \over 6} \space {1 \over 6} \space  {1\over 6} \space {1 \over 6} \space {1 \over 6} \space  \end{eqnarray})`}/>
                    </div>

                    {/*Cand folosim o <b>funcție</b> pentru a descrie/reprezenta o distributie discreta de probabilitate, o numim <b>functie de masa de probabilitate</b>*/}
                    {/*<br/>*/}

                    Când folosim o <b>funcție</b> pentru a descrie/reprezenta o distribuție (discreta/continuă) de probabilitate,
                    o numim <b>funcție de densitate de probabilitate</b> sau <b>densitate</b> (Probability Density / Density).
                    <br/>
                    Deci, pentru o variabilă aleatorie X, si x fiind o valoare din X, P(x) este o <b>funcție</b> care <b>atribuie o probabilitate tuturor valorilor lui X</b> si se noteaza P(x).<br/><br/>

                    <i>Exemplu</i> pentru distributia de probabilitate la aruncarea unui zar, unde x este o valoare posibila, avem:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(x)={1 \over 6}`}/>
                    </div>

                    <i>Exemplu</i> de distributie continua de probabilitate: distributia normala.<br/>
                    <i>Exemplu</i> functia densitate de proabilitate pentru distributia normala este:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(x)=f(x;u,d)={1 \over d \sqrt 2 \pi}exp(-{(x-u)^2 \over 2d^2})`}/>
                    </div>

                    <br/>


                    Probabilitatea unui eveniment specific A pentru o variabilă aleatorie x este notată ca P(x=A), sau P(A)<br/>
                    <b>Probabilitate unui eveniment</b> A: P(A)<br/><br/>
                    <b>Probablitatea</b> măsoară evenimentele, asociind numere în intervalul [0,1]. Deci, pentru un eveniment A, probablitatea lui A este:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A)={Nr.cazuri.favorabile.lui.A \over Nr.cazuri.posibile}`}/>
                    </div>
                    Avem:
                    <div className={"padding50"}>
                        Probabilitatea unui rezultat imposibil:
                        <MathComponent tex={String.raw`P(\emptyset)=0`}/>
                        Probabilitatea unui rezultat sigur:
                        <MathComponent tex={String.raw`P(E)=1`}/>
                        Probabilitatea sa nu apara un eveniment A (numit <i>complementar</i> si notat prin <b>not A</b>):
                        <MathComponent tex={String.raw`P(not \space A)=1-P(A)`}/>

                        Suma probabilităților pentru toate rezultatele = 1.

                    </div>
                    <br/>

                    <b>Legea numerelor mari</b> (Jakob Bernoulli) ne spune că odată cu creșterea numărului de experimente,
                    estimarea (rezultatul mediu obținut) se va apropia din ce în ce mai mult de adevărata probabilitate de bază sau de valoarea asteptată.
                    <br/>
                </div>

                <div className={"text-justify"}>
                    <br/>
                    <i>Exemplu:</i> Daca se <i>aruncă un zar</i> (asta poate fi vazuta ca o variabila alaeatoare, pe care o puteam nota cu Zar) cu 6 fețe, atunci șansa de a ieși cifra 4 este 1/6.
                    Adica probabilitatea de a iesi <i>cifra 4</i> (cifra 4 este un eveniment) este 1 din cele 6 cazuri posibile ( P(Zar=4) = 1/6 ).
                    <br/>
                    <br/>
                    <i>Exemplu:</i> Daca se aruncă un zar cu 6 fețe, atunci șansa de a ieși o cifra cifra para, adica (2,4,6) este 3/6=1/2.
                    Adica probabilitatea de a iesi o cifra para din din cele 6 cazuri posibile ( P(Zar=2 sau Zar=4 sau Zar=6) = 1/2 ).
                    <br/>
                    <br/>
                </div>

                <div className={"text-justify important"}>
                    O <b>variabila</b> aleatoare X este o expresie a cărei valoare este rezultatul unui experiment.
                    <br/>
                    O variabila aleatoare poate fi:
                    <ul>
                        <li><b>discreta</b>: poate avea o valoare finita (in cazul variabilelor discrete, numai anumite valori sunt posibile - exista goluri intre valorile posibile)
                            (exemple: numarul de masini aflate pe sosea, piese defecte, numar copii intr-o familie, numar de aruncari a unui zar pana la obtinerea lui 2)</li>
                        <li><b>continua</b>: poate lua orice valoare numerica dintr-un interval
                            (exemple: durabilitatea unui anumit material, cantitatea de apa de ploaie, inaltimea, greutatea, varsta, nivelul de colesterol)</li>
                    </ul>
                    <i>exemplu:</i>
                    <br/>
                    Într-un meci de fotbal, in cazul unui penalti, se poate marca sau nu.
                    Putem folosi o variabila discreta si sa atribuim valoarea 0 daca penaltiul a fost ratat si 1 in vaz contrar.

                    <br/>
                    <br/>
                    Daca avem un eveniment aleator si consideram X o variabila aleatoare care poate lua valori din spatiul esantion S:
                    <MathComponent tex={String.raw`S={1,2,3,...n}`}/>
                    atunci notam <b>X=a</b>, ca "X sa fie a" sau "sa fi iesit valoare <i>a</i>",
                    iar probabilitatea sa iasa o notam cu:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(X=a)`}/>
                    </div>
                    sau
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(a)`}/>
                    </div>
                    Se poate specifica un interval [a,b] pentru o variabila aleatoare, se poate scrie asa:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(a \le X \le b)`}/>
                    </div>
                    <b>Probabilitatea comuna (Joint Probability)</b> este probabilitatea ca A=a <b>si</b> B=b sa se intample simultat
                    (probabilitatea ca doua sau mai multe evenimente sa se intample simultan) si se noteaza:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A=a,B=b)`}/>
                        sau
                        <MathComponent tex={String.raw`P(A,B)`}/>
                        sau
                        <MathComponent tex={String.raw`P(A \cap B)`}/>
                        sau
                        <MathComponent tex={String.raw`P(A \space and \space B)`}/>
                    si
                        <MathComponent tex={String.raw`P(A=a,B=b) \le P(A=a)`}/>
                        {/*P(A=a,B=b)≤P(A=a)*/}
                    </div>

                    <b>Probabilitatea reuniunii</b> este probabilitatea ca A=a <b>sau</b> B=b sa se intample si se noteaza:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A \cup B)`}/>
                        si
                        <MathComponent tex={String.raw`P(A \cup B) = P(A)+P(B)-P(A \cap B)`}/>

                    </div>
                </div>
                <div className={"text-justify"}>
                    <br/>
                    <i>Exemplu</i>: Avand un zar cu 6 fete, probabilitatea de a iesi cifra 1 <b>sau</b> o cifra para (notam cu A evenimentul de iesi cifra 1 si cu B evenimentul de a iesi o cifra para) este:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A \cup B) = P(A)+P(B)-P(A \cap B)={1 \over 6}+{3 \over 6} - 0 = {2 \over 3}`}/>
                    </div>
                    <br/>

                </div>
                <div className={"text-justify important"}>
                    <b>Probabilitate conditionată</b> este probabilitatea sa se intample A=a, cu conditia sa se fi intamplat B=b si se noteaza
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A=a|B=b)`}/>
                        sau
                        <MathComponent tex={String.raw`P(A|B)`}/>
                    </div>
                    <b>Regula de multiplicare:</b>
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A=a,B=b) = P(B=b|A=a) P(A=a)`}/>
                        <MathComponent tex={String.raw`P(A=a,B=b) = P(A=a|B=b) P(B=b)`}/>
                        sau
                        <MathComponent tex={String.raw`P(A,B) = P(B|A) P(A)`}/>
                        <MathComponent tex={String.raw`P(A,B) = P(A|B) P(B)`}/>
                    </div>

                    <b>Probabilitatea marginala</b>
                    <br/>
                    Probabilitatea marginală este probabilitatea unui eveniment, indiferent de alte variabile aleatorii.
                    <br/>
                    <br/>

                    <b>Regula de sumarizare (maginalizare / sum rule)</b> reprezinta deducerea P(B) din P(A,B):
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(B)={\sum_A P(A,B)}`}/>
                    </div>

                    <b>Teorema lui Bayes (pentru probabilitatea conditionata):</b>
                    <br/>
                    Teorama lui Bayes oferă o modalitate de a calcula probabilitatea conditiontata.
                    De asemenea, teorema lui Bayes este utilizată pe scară largă în domeniul învățării automate (machine learning / ML).
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A|B)={P(A,B) \over P(B)}={ P(B|A)P(A) \over P(B)}`}/>
                    </div>



                    Două variabile aleatorii A și B sunt independente daca apariția unui eveniment B nu depinde apariția unui eveniment A, adica:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(B|A)=P(B)`}/>
                        {/*P(B∣A)=P(B)*/}
                    </div>

                    Daca avem doua evenimente independente A si B:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A \cap B)=P(A,B)=P(B|A)P(A)=P(B)P(A)`}/>
                        deci:
                        <MathComponent tex={String.raw`P(A,B)=P(A)P(B)`}/>
                    </div>
                </div>
                <div className={"text-justify"}>
                    <br/>
                    <i>Exemplu</i>: Daca avem un zar cu 6 fete, atunci probabilitatea de a iesi cifra 1 <b>si apoi urmat</b> de o cifra para este:<br/>
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A,B)=P(A)P(B)={1 \over 6}{3 \over 6} = {1 \over 12}`}/>
                    </div>
                    (am notat cu A evenimentul sa iasa cifra 1 si cu B evenimentul de a iesi o cifra para).
                    <br/>
                    <br/>
                    <i>Exemplu</i>: Avand un 2 zaruri, fiecare cu cu 6 fete, si vrem sa calculam probabilitatea de a iesi cifra 1 <b>sau</b> cifra 2.<br/>
                    <i>Varianta 1:</i>
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A \cup B) = P(A)+P(B)-P(A \cap B)={1 \over 3}+{1 \over 3} - {1 \over 9} = {5 \over 9}`}/>
                    </div>
                    Am notat cu A evenimentul de a se obtine 1 sau 2 cu primul zar si cu B evenimentul de a se obtine 1 sau 2 cu al doilea zar. Prin urmare avem:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A)=P(B)={(Zar=1 \space sau \space Zar=2) \over 6}={2 \over 6}={1 \over 3}`}/>
                        in plus
                        <MathComponent tex={String.raw`P(A \cap B)=P(A)P(B)={1\over 3}{1\over 3}={1 \over 9}`}/>
                    </div>
                    <i>Varianta 2: </i>Se poate calcula si altfel:
                    <ul>
                        <li>avem 36 de posibilitati: (1,1),(1,2),..,(1,6),(2,1),(2,2),...,(2,6),(3,1),...(6,6)</li>
                        <li>avem 20 de posibilitati faborabile: (1,1),(1,2),(1,3),(1,4),(1,5),(1,6),(2,1),(2,2),(2,3),(2,4),(2,5),(2,6),(3,1),(3,2),(4,1),(4,2),(5,1),(5,2),(6,1),(6,2)</li>
                        <li>ajungem la concluzia ca probabilitatea cautata este:
                            <MathComponent tex={String.raw`P(A \cap B)={20 \over 36}={5 \over 9}`}/>
                        </li>
                    </ul>
                    <i>Varianta 3: </i>Daca numarul posiblitatilor este mare, atunci se poate calcula folosind posiblitatea ca evenimentul sa <b>nu</b> se produca
                    (in acest fel, putem evita sa luam in considerare de 2 ori acelasi lucru, vezi varianta 1).<br/>
                    Probabilitatea de a nu obtine 1 sau 2 cu un <i>singur</i> zar (adica se se obtina 3,4,5,6) este:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(not(A))=P(not(B))={4 \over 6}={2 \over 3}`}/>
                    </div>
                    Probabilitatea de a nu obtine 1 sau 2 cu <i>doua</i> zaruri este:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(not(A) \cap not(B))={2 \over 3}{2 \over 3}={4 \over 9}`}/>
                    </div>
                    Prin urmare, probabilitatea de a obtine 1 sau 2 cu <i>doua</i> zaruri este:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A \cap B)=1-P(not(A) \cap not(B))=1-{4 \over 9}={5 \over 9}`}/>
                    </div>
                    <br/>
                    <br/>
                </div>
                <div className={"text-justify important"}>

                    Doua variabile A si B sunt <b>conditional independente</b> daca:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`P(A,B|C)=P(A|C)P(B|C)`}/>
                        {/*P(A,B∣C)=P(A∣C)P(B∣C)*/}
                    </div>
                    <br/>

                    Fie X o variabila aleatoare cu distributia:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`X:(\begin{eqnarray} ... x_i... \\ ...p_i...  \end{eqnarray})`}/>
                    </div>
                    unde:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`p_i=P(X=x_i)`}/>
                        <MathComponent tex={String.raw`\sum_{i} p_i=1`}/>
                    </div>
                    Media unei variabile aleatoare X:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`M(X)=\sum_{i} p_i x_i`}/>
                    </div>

                    <b>Procese stohastice</b> reprezinta o familie de variabile aleatoare X<sub>t</sub>,
                    caracterizatata printr-o relatie speciala intre variabile:
                    <ul>
                        <li>
                            X<sub>t</sub> este o variabila aleatoare, conectata printr-o proprietate comuna de variabilele aleatoare
                            care o preced sau o succed
                        </li>
                        <li>
                            multimea valorilor pe care le poate lua X<sub>t</sub> se numeste <b>spatiul starilor</b>
                        </li>
                        <li>
                            indicele t este interpretat de obicei ca timp, deci X<sub>t</sub>  reprezinta starea procesului la momentul de timp t
                        </li>
                    </ul>
                    <i>exemple:</i>
                    <ul>
                        <li> X<sub>t</sub> poate reprezenta numarul de persoane dintr-un tranvai la momentul de timp t</li>
                        <li> starea unui algoritm la iteratia t</li>
                    </ul>
                    Cele mai cunoscute procese stohastice sunt <b>lanturile Markov</b>.
                    <br/>
                    O familie de variabile aleatoare aleatoare X<sub>t</sub> este un lant Markov daca:
                        <MathComponent tex={String.raw`P(X_{t+1}=i_{t+1}|X_t=i_t, X_{t-1}=i_{t-1},...,X_0=i_0)=P(X_{t+1}=i_{t+1}|X_t=i_t)`}/>
                    adica <i>viitorul depinde doar de prezent, trecutul nu conteaza!</i>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <i>Cel mai mare castig îl obții în jocurile de noroc, daca nu participi</i>. (Girolamo Cardano 1501-1576)
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Keith Devlin. 2008. The Unfinished Game</div>
                        </li>

                        <li>
                            <div>Daphne Koller and Nir Friedman. 2009. Probabilistic Graphical Models: Principles and Techniques</div>
                        </li>
                        
                        <li>
                            <div>
                                <a href={"https://machinelearningmastery.com/bayes-theorem-for-machine-learning/"}>
                                    A Gentle Introduction to Bayes Theorem for Machine Learning
                                </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"https://towardsdatascience.com/probability-concepts-explained-probability-distributions-introduction-part-3-4a5db81858dc"}>
                                    Probability concepts explained: probability distributions (introduction part 3)
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Anexa1ProbabilityContent;