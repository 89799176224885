import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerComposeVolumesContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-compose-volumes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Volume in afara Docker (masina gazda) </b>
                    <br/>
                    <br/>

                    Se pot specifica volumele, prin parametrul <b>volumes</b>, ca in exemplul de mai jos:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  web:\n' +
                            '    build: .\n' +
                            '    ports:\n' +
                            '      - \'3000:3000\'\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - .:/app'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li> - /app/node_modules {"=>"} nu incerca sa mapezi folderul cu folder-ul corespondent din afara containerului</li>
                    </ul>

                    <hr/>
                    <b>2. Volume in interiorul Docker</b>
                    <br/>
                    <br/>

                    Pentru a declara un volum:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'volumes:\n' +
                            '\tmongodb_data'}
                    </SyntaxHighlighter>

                    Pentru a folosi volumul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'services:\n' +
                            '  db:\n' +
                            '    image:\n' +
                            '       mongo\n' +
                            '    ports:\n' +
                            '        - "27017:27017"\n' +
                            '    volumes:\n' +
                            '       - mongodb_data:/data/db'}
                    </SyntaxHighlighter>

                    Pentru a lista volumele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker volume ls'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerComposeVolumesContent;