import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InstallPostgresql15Ubuntu20Content extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-install-postgresql-15-ubuntu-20", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b> Instalare PostgreSQL 15 pe Ubuntu 20</b>
                    <br/>
                    <br/>

                    <b>Resurse necesare</b>:
                    <ul>
                        <li>
                            Procesor (CPU):
                            <ul>
                                <li>
                                    Minim: 1 nuclee fizice sau virtuale
                                </li>
                                <li>
                                    Recomandat: 4 nuclee fizice sau virtuale sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Memorie (RAM):
                            <ul>
                                <li>
                                    Minim: 2 GB RAM
                                </li>
                                <li>
                                    Recomandat: 4 GB RAM sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Stocare/spatiu pe disk:
                            <ul>
                                <li>
                                    Minim: 512 MB spațiu liber pe disc pentru instalare (fara date)
                                </li>
                                <li>
                                    Recomandat: 10 GB sau mai mult (în funcție de dimensiunea datelor și a conținutului)
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Activare depozit de pachete PostgreSQL</b>
                    <br/>
                    <br/>

                    Pachetul PostgreSQL 15 nu este disponibil în depozitul implicit de pachete, așa că activați depozitul oficial de pachete folosind următoarele comenzi.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo sh -c \'echo "deb http://apt.postgresql.org/pub/repos/apt $(lsb_release -cs)-pgdg main" > /etc/apt/sources.list.d/pgdg.list\''}
                    </SyntaxHighlighter>
                    se introduce parola de sudo, si apoi:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'wget -qO- https://www.postgresql.org/media/keys/ACCC4CF8.asc | sudo tee /etc/apt/trusted.gpg.d/pgdg.asc &>/dev/null'}
                    </SyntaxHighlighter>

                    Actualizare cele mai recente versiuni ale pachetelor:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt update'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Instalare client și server PostgreSQL</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt install postgresql postgresql-client -y'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Verificare dacă serviciul PostgreSQL este activ și rulează</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl status postgresql'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>4. Verificare versiunea PostgreSQL folosind utilitarul de linie de comandă psql</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'psql --version'}
                    </SyntaxHighlighter>



                    poate afisa:
                    <SyntaxHighlighter>
                        {'psql (PostgreSQL) 15.3 (Ubuntu 15.3-1.pgdg22.04+1)'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>5. Actualizare parola utilizatorului de administrator PostgreSQL</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo -u postgres psql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'postgres=# ALTER USER postgres PASSWORD \'postgres!@#\';'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ALTER ROLE'}
                    </SyntaxHighlighter>


                    Pentru terminarea sesiunii, se da comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'\\q'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>6. Conectare la baza de date</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'psql -h localhost -U postgres'}
                    </SyntaxHighlighter>

                    cu parola: postgres!@#

                    <br/>

                    se poate afisa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'psql (15.3 (Ubuntu 15.3-1.pgdg22.04+1))\n' +
                            'SSL connection (protocol: TLSv1.3, cipher: TLS_AES_256_GCM_SHA384, compression: off)\n' +
                            'Type "help" for help.'}
                    </SyntaxHighlighter>


                    Pentru terminarea sesiunii, se da comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'\\q'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Configurare PostgreSQL pentru a permite conexiuni la distanta</b>
                    <br/>
                    <br/>

                    În mod implicit, PostgreSQL acceptă conexiuni numai de la localhost.

                    <hr/>
                    <b>7.1. Modificare fisier postgresql.conf</b>
                    <br/>
                    <br/>

                    Pentru a modifica aceasta configuratie,se modifica in fisierul:
                    <SyntaxHighlighter>
                        {'postgresql.conf'}
                    </SyntaxHighlighter>

                    din directorul:

                    <SyntaxHighlighter>
                        {'/etc/postgresql/15/main/postgresql.conf'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /etc/postgresql/15/main/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo nano postgresql.conf'}
                    </SyntaxHighlighter>
                    cu parola masinii de Ubuntu

                    <br/>
                    <br/>

                    Din <b>postgresql.conf</b> se decomenteaza linia cu:
                    <SyntaxHighlighter>
                        {'listen_addresses = "localhost"'}
                    </SyntaxHighlighter>
                    si se modifica in:
                    <SyntaxHighlighter>
                        {'listen_addresses = "*"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7.2. Modificare fisier pg_hba.conf</b>
                    <br/>
                    <br/>

                    Pentru a permite conexiuni IPv4, trebuie modificat fisierul:

                    <SyntaxHighlighter>
                        {'pg_hba.conf'}
                    </SyntaxHighlighter>

                    din directorul:

                    <SyntaxHighlighter>
                        {'/etc/postgresql/15/main/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /etc/postgresql/15/main/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo nano pg_hba.conf'}
                    </SyntaxHighlighter>

                    Se modifica linia, sub IPv4 local connections:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# pentru IPv4\n' +
                            'host    all             all              0.0.0.0/0                       md5\n' +
                            '# pentru IPv6\n' +
                            'host all all ::/0 md5\n'}
                    </SyntaxHighlighter>

                    Daca nu este pusa linia de mai sus, la o conectare remote se va da o eroare similara cu cea de mai jos:

                    <SyntaxHighlighter>
                        {'psql: error: connection to server at "11.129.150.106", port 5432 failed: FATAL:  no pg_hba.conf entry for host "10.233.100.247", user "postgres", database "postgres", SSL encryption\n' +
                            'connection to server at "11.129.150.106", port 5432 failed: FATAL:  no pg_hba.conf entry for host "10.233.100.247", user "postgres", database "postgres", no encryption\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7.3. Permisiune port</b>
                    <br/>
                    <br/>

                    Se permite iesire pe portul 5432 si protocol TCP, folosind comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo ufw allow 5432/tcp'}
                    </SyntaxHighlighter>

                    Daca nu se executa comanda de mai sus, la o conectare remote se va da o eroare similara cu cea de mai jos:

                    <SyntaxHighlighter>
                        {'psql: error: connection to server at "11.129.150.106", port 5432 failed: Connection refused (0x0000274D/10061)\n' +
                            'Is the server running on that host and accepting TCP/IP connections?'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Verificare conexiune la distanta</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl restart postgresql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl status postgresql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'psql -h 11.129.150.106 -U postgres'}
                    </SyntaxHighlighter>
                    cu parola postgres!@#
                    <br/>
                    unde 11.129.150.106 este adresa IP pe care este serverul de baza de date

                    <hr/>
                    <b>9. Afisare utilizatori</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo -u postgres psql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'\\du'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Adaugare utilizator</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo -u postgres psql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'CREATE USER pgalati WITH PASSWORD \'pgalati\' SUPERUSER CREATEDB CREATEROLE LOGIN;'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>11. Afisare baze de date</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo -u postgres psql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'\\l'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>12. Adaugare baza de date cu un utilizator</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo -u postgres psql'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'CREATE DATABASE lportal WITH OWNER=pgalati ENCODING = \'UTF8\';'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.linuxtechi.com/how-to-install-postgresql-on-ubuntu/?utm_content=cmp-true"}>
                               How to Install PostgreSQL 15 on Ubuntu 22.04 Step-by-Step
                           </a>
                       </li>


                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallPostgresql15Ubuntu20Content;