import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class XargLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-xarg", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Pentru a trimite ca argumente rezultatele unui comenzi alte comenzi
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'xargs <comanda>'}
                    </SyntaxHighlighter>

                    Exemplu: se vor afisa continuturile fisierelor listate de ls:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls |  xargs cat'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default XargLinuxContent;