import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class MVCRenderCommandPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-mvc-render-command", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    Clasele <b>MVCRenderCommand</b> sunt folosite pentru a răspunde adreselor URL de randare ale portleturilor (adica te poti muta de pe o pagina la alta prin intermediul link-urilor).
                    <br/>
                    Pentru a utiliza comenzile de randare MVC, este nevoie de:
                    <ul>
                        <li>o implementare a interfeței <b>MVCRenderCommand</b>; aceasta componenta specifica 2 proprietati

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Component(\n' +
                                '        property  = {\n' +
                                '                "javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                                '                "mvc.command.name=/create/edit"\n' +
                                '        }\n' +
                                ')'}
                            </SyntaxHighlighter>

                            Componenta trebuie să publice, de asemenea, un serviciu MVCRenderCommand.class pe runtime OSGi:

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'service = MVCRenderCommand.class'}
                            </SyntaxHighlighter>

                            Implementarea <b>MVCRenderCommand</b>:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.letyournailsgrow.liferay.vanilla.apps.helloworld.portlet;\n' +
                                '\n' +
                                'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCRenderCommand;\n' +
                                'import org.osgi.service.component.annotations.Component;\n' +
                                'import ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants.HelloWorldPortletKeys;\n' +
                                '\n' +
                                'import javax.portlet.PortletException;\n' +
                                'import javax.portlet.RenderRequest;\n' +
                                'import javax.portlet.RenderResponse;\n' +
                                '\n' +
                                '@Component(\n' +
                                '        immediate = true,\n' +
                                '        property  = {\n' +
                                '                "javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                                '                "mvc.command.name=/create/edit"\n' +
                                '        }\n' +
                                ')\n' +
                                'public class CreateEditRenderMVCCommand implements MVCRenderCommand {\n' +
                                '    @Override\n' +
                                '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                                '        return "/edit.jsp";\n' +
                                '    }\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                            O comandă poate fi utilizată de un portlet, după cum arată exemplul de mai sus.
                            <br/>
                            O comandă poate fi utilizată insa si pentru mai multe portleturi adăugând mai multe intrări <b>javax.portlet.name</b> în lista de proprietăți.
                            De asemenea, mai multe comenzi pot invoca clasa de comandă MVC adăugând mai multe intrări <b>mvc.command.name</b>.

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Component(\n' +
                                '    immediate = true,\n' +
                                '    property = {\n' +
                                '       "javax.portlet.name=" + HelloWorldPortletKeys.HELLO_MY_WORLD,\n' +
                                '       "javax.portlet.name=" + HelloWorldPortletKeys.HELLO_WORLD,\n' +
                                '       "mvc.command.name=/hello/edit_super_entry",\n' +
                                '       "mvc.command.name=/hello/edit_entry"\n' +
                                '    },\n' +
                                '    service = MVCRenderCommand.class\n' +
                                ')'}
                            </SyntaxHighlighter>

                        </li>
                        <li>adresa URL de randare; se foloseste:

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<portlet:renderURL>'}
                            </SyntaxHighlighter>

                            Pentru a invoca comanda de randare MVC de la adresa URL de randare, trebuie specificat parametrul <b>mvcRenderCommandName</b> cu aceeași valoare ca și proprietatea din Component <b>mvc.command.name</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<portlet:renderURL var="createEditRender">\n' +
                                '\t<portlet:param name="mvcRenderCommandName" value="/create/edit"/>\n' +
                                '</portlet:renderURL>'}
                            </SyntaxHighlighter>

                        </li>

                    </ul>




                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>
                                    MVC Render Command
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MVCRenderCommandPortletContent;