import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaGibbsSamplingContent extends BaseContentPage {

    constructor(props) {
        super(props, "anexa-gibbs-sampling", IndexContent);
    }

    render() {
        return (
            <div className="home appendix">
                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify"}>
                    Esantionarea Gibbs (Gibbs Sampling) a fost introdusa de Geman si Geman in 1984 si numita dupa fizicianul Josiah Willard Gibbs.
                    Tehnica consta în repetarea a <i>k</i> iteratii pentru a imbunatatii calitatea estimarii. Fiecare iteratii are 2 pasi:
                    <ul>
                        <li>(1) probe de neuroni vizibili dau neuroni ascunsi</li>
                        <li>(2) probe de neuroni ascunsi dau neuroni vizibili</li>
                    </ul>
                    La prima iteratie a algoritmului de esantionare Gibbs, se porneste cu neuronii ascunsi pozitivi obtinuti de la ultima sectiune (?)
                    Se testeaza media probablitatii neuronilor vizibili din acestea (1). <br/>
                    Apoi se folosec acesti neuroni vizibili ascunsi (?) pentru a testa neuronii ascunsi (2). <br/>
                    <br/>
                    Aceste noi probabilități ascunse sunt <b>gradienți negativi</b>.
                    Pentru ciclul următor, vom folosi gradienții negativi în locul celor pozitivi. <br/>
                    Acest lucru continuă pentru fiecare iterație și produce gradienți negativi mai buni<br/>

                    Dupa ce tot procesul este complet, vom avea 6 vectori cu:
                    <ul>
                        <li>probabilități medii pozitive ale neuronilor ascunși</li>
                        <li>valori eșantionate pozitiv ale neuronilor ascunși</li>
                        <li>probabilități medii negative ale neuronilor vizibili</li>
                        <li>valori eșantionate negativ ale neuronilor vizibili</li>
                        <li>probabilități medii negative de neuroni ascunși</li>
                        <li>valori negative eșantionate ale neuronilor ascunși</li>
                    </ul>

                    Algoritmul up-down sau algoritmul de divergență contrastivă (contrastive divergence algorithm / CDA)
                    include urmatorii pasii:
                    <ul>
                        <li>calcularea gradintilor pozitivi</li>
                        <li>esantionarea Gibbs</li>
                        <li>actualizarea ponderilor si bias-urilor</li>
                        <li>backpropagation supervizat</li>
                    </ul>
                    Algoritmul de divergență contrastivă este un algoritm bazat pe gradientii descendenti.
                    Foloseste derivata unei functii pentru a gasi intrarile pentru functia care produce cea mai mica iesire pentru aceea functie.
                    Deoarece gradientii reali sunt prea complicatii pentru a fi calculati, se folosesc estimari in loc de calcule reale.
                    Iar pentru invatarea automata (machine learning) aceasta estimare este de obicei suficienta.
                    De aceea, trebuie sa calculam probabilitatea medie a neuronilor ascunsi propagand neuronii vizibili catre neuronii ascunsi.
                    Acest calcul este "up"-ul (ridicarea/urcarea) din algoritmul up-down:

                    <MathComponent tex={String.raw`\bar h_i^{+}=sigmoid(\sum_j {w_jv_j+b_i})`}/>

                    (Practic, partea asta reprezinta <b>activarea</b> neuronilor vizibili)
                    <br/>
                    Ecuatia de mai sus, calculeaza probabilitatea medie a fiecarui neuron ascuns.
                    Bara de deasupra lui h marcheaza faptul ca este o medie, iar indicele pozitiv indica faptul ca se calculeaza
                    media pentru partea pozitiva (sau "up") a algoritmului.

                </div>
                <br/>

                <div className={"text-justify important"}>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Jeff Heaton. 2015. AIFH, Volume 3: Deep Learning and Neural Networks</div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AnexaGibbsSamplingContent;