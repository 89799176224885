import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class I18nLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-i18n", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Exista 2 tipuri de chei de limba:
                    <ul>
                        <li>globale</li>
                        <li>per modul</li>
                    </ul>

                    Cheile globale se pot gasi in: <b>tomcat-9.0.37\webapps\ROOT\WEB-INF\lib\portal-impl.jar</b> in folderul <b>content</b>.

                    <hr/>
                    <b>1. Chei globale</b>
                    <br/>
                    <br/>

                    Pentru a suprascrie o chei globala, trebuie creata o clasa care:
                    <ul>
                        <li>sa fie de tip <b>@Component(service = ResourceBundle.class)</b></li>
                        <li>sa extinda <b>ResourceBundle</b></li>
                        <li>sa implementeze metodele: <b>protected Object handleGetObject(String key)</b> si <b>{"public Enumeration<String> getKeys()"}</b></li>
                    </ul>
                    In plus, in <b>src\main\resources\content</b> trebuie creat fisierul <i>Language_en_US.properties</i> cu cheile pe care vream sa le suprascriem:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'control-panel=Panou de control KJ'}
                    </SyntaxHighlighter>

                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package global.properties;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.language.UTF8Control;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import java.util.Enumeration;\n' +
                        'import java.util.ResourceBundle;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        property = {\n' +
                        '                "language.id=en_US"\n' +
                        '        },\n' +
                        '        service = ResourceBundle.class\n' +
                        ')\n' +
                        'public class EnGlobalResourceBundle extends ResourceBundle {\n' +
                        '\n' +
                        '    private ResourceBundle bundle = ResourceBundle.getBundle("content.Language_en_US", UTF8Control.INSTANCE);\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected Object handleGetObject(String key) {\n' +
                        '        return bundle.getObject(key);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public Enumeration<String> getKeys() {\n' +
                        '        return bundle.getKeys();\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Suprascriere chei dintr-un modul anume</b>
                    <br/>
                    <br/>

                    <b>2.1. Gasirea modulului si fisierului de proprietati</b>
                    <br/>
                    <br/>

                    Sa presupune ca vrem sa supracriem chei din <i>Liferay Login Web</i>.
                    Mergem in <b>Gogo Shell</b>, aflam ID-ul acestui portlet si scriem comanda:
                    <ul>
                        <li><b>headers <i>ID</i></b></li>
                    </ul>
                    Retinem cateva din detaliile acestui portlet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' Bundle-Name = Liferay Login Web\n' +
                        ' Bundle-SymbolicName = com.liferay.login.web\n' +
                        ' Bundle-Vendor = Liferay, Inc.\n' +
                        ' Bundle-Version = 5.0.26\n' +
                        ' Created-By = 1.8.0_221 (Oracle Corporation)'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' Web-ContextPath = /login-web'}
                    </SyntaxHighlighter>

                    Apoi cautam fisierul de proprietati pentru acest modul in sursele Liferay:
                    <ul>
                        <li><a href={"https://repository.liferay.com/nexus/"} target={"_blank"} > https://repository.liferay.com/nexus/ </a></li>
                    </ul>

                    Apoi se downloadeaza jar-ul, se dezarhveaza pentru a ajunge la fisierul de proprietati (care se va gasi in directorul <b>content</b>).

                    <br/>
                    <br/>
                    O alta metoda de a gasi fisierul de proprietati pentru un modul este sa il cautam in locatia:
                    <ul>
                        <li>[LIFERAY_HOME]/<b>osgi/marketplaces</b>/Liferay Foundation - Liferay Login - Impl.lpkg</li>
                    </ul>
                    sau:
                    <ul>
                        <li>[LIFERAY_HOME]/<b>osgi/marketplaces</b>/Liferay CE Foundation - Liferay CE Login - Impl.lpkg</li>
                    </ul>
                    Se dezarhiveaza acest fisier; arhiva va contine <i>com.liferay.login.web-5.0.26.jar</i> care la randul ei trebuie dezarhivata;
                    dupa ce se dezarhiveaza se poate ajunge la fisierul de proprietati, care se va gasi in directorul <b>content</b>.

                    <br/>
                    <br/>
                    Fisierul <b>build.gradle</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: login-language\n' +
                        'Bundle-SymbolicName: login.language\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Provide-Capability:\\\n' +
                        '    liferay.resource.bundle;\\\n' +
                        '            resource.bundle.base.name="content.Language",\\\n' +
                        '    liferay.resource.bundle;\\\n' +
                        '                     bundle.symbolic.name=com.liferay.login.web;\\\n' +
                        '                     resource.bundle.ageregate:String="(bundle.symbolic.name=login.language),(bundle.symbolic.name=com.liferay.login.web)";\\\n' +
                        '                     resource.bundle.base.name="content.Language";\\\n' +
                        '                     service.ranking:Long="2";\\\n' +
                        '                     servlet.context.name=login-web'}
                    </SyntaxHighlighter>

                    Fisierul <b>Language.properties</b> (din <b>scr/main/resources/content</b>)
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'you-are-signed-in-as-x=Esti logat ca {0}, zeule!.'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default I18nLiferayContent;