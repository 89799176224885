import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../grafana/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SSLGrafanaContent extends BaseContentPage  {

    constructor(props) {
        super(props, "grafana-ssl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana - SSL</b>
                    <br/>
                    <br/>

                    <b>1. SSL</b>
                    <br/>
                    <br/>

                    <b>1.1. Generare cheie privata (.key)</b>
                    <br/>
                    <br/>
                    Se foloseste <b>openssl</b>
                    <SyntaxHighlighter>
                        {'openssl genpkey -algorithm RSA -out private.key'}
                    </SyntaxHighlighter>
                    Se va genera fisierul <b>private.key</b>

                    <hr/>
                    <b>1.2. Generare cererea de semnare a certificatului (.req / .csr)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'openssl req -new -key private.key -out certificate.csr'}
                    </SyntaxHighlighter>
                    Se va genera fișierul <b>certificate.csr</b>

                    <hr/>
                    <b>1.3. Generare certifica (.crt / .cer)t</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter>
                        {'openssl x509 -req  -in certificate.csr -signkey private.key -out certificate.crt'}
                    </SyntaxHighlighter>
                    Se va genera fișierul <b>certificate.crt</b>.

                    <hr/>
                    In final vom avea 3 fisiere:
                    <ul>
                        <li>
                            private.key
                        </li>
                        <li>
                            certificate.csr
                        </li>
                        <li>
                            certificate.crt
                        </li>
                    </ul>
                    Aceste fisiere se vor pune in <b>[Grafana]/conf/certificate</b>.

                    <hr/>
                    <b>2. Configurare</b>
                    <br/>
                    <br/>

                    Modificam fisierul <b>custom.ini</b> din directorul <b>[Grafana]/conf</b>.
                    <br/>
                    In sectiunea <b>[server]</b>, decomentam <b>protocol</b> (stergem ; din fata) si apoi la protocol punem valoare <b></b>:
                    <SyntaxHighlighter>
                        {'protocol=https'}
                    </SyntaxHighlighter>

                    Apoi, schimbam si portul:
                    <SyntaxHighlighter>
                        {'http_port=443'}
                    </SyntaxHighlighter>

                    Apoi, decomentam <b>domain</b>:
                    <SyntaxHighlighter>
                        {'domain=localhost'}
                    </SyntaxHighlighter>

                    Apoi, decomentam <b>cert_file</b> si <b>cert_key</b> si setam urmatoarele valori, de exemplu:
                    <SyntaxHighlighter>
                        {'cert_file=c:\\grafana\\conf\\certificate\\certificate.crt'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'cert_key=c:\\grafana\\conf\\certificate\\private.crt'}
                    </SyntaxHighlighter>

                    Dupa aceste schimbari, se reporneste Grafana!
                    <hr/>
                    Se verifica: <b>https</b>://localhost
                    <br/>
                    Nu va mai functiona: <b>http</b>://localhost<b>:3000</b>!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SSLGrafanaContent;