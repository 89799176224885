import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class TopContainerDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-container-top", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker container top</b>
                    <br/>
                    <br/>

                    Pentru a vizualiza procesele din interiorul unui container care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container top [container]'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container top minikube'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'UID                 PID                 PPID                C                   STIME               TTY                 TIME                CMD\n' +
                            'root                3567459             3567438             0                   nov04               ?                   00:02:21            /sbin/init\n' +
                            '65535               4005158             4005132             0                   nov05               ?                   00:00:00            /pause\n' +
                            'root                4027532             4027512             0                   nov05               ?                   00:00:00            npm\n' +
                            'root                4027593             4027532             0                   nov05               ?                   00:00:00            node index.js\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TopContainerDockerContent;