import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class K8sDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-k8s", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Arhitectura Kubernetes</b>

                    <br/>
                    <br/>

                    Un cluster Kubernetes este format din 2 tipuri de masini:
                    <ul>
                        <li>master / control plane</li>
                        <li>workers / nodes</li>
                    </ul>

                    Kubernetes are o arhitectura bazata pe microservicii. Toate procesele sale sunt containere si comunica intre ele prin API-uri folosind request-uri HTTP REST.
                    <br/>
                    Componentele unui master/worker:
                    <ul>
                        <li><b>kube-apiserver</b>:
                            <ul>
                                <li>
                                    expune API-urile altor componente
                                </li>
                                <li>
                                    este ca un front-end pentru master
                                </li>
                                <li>
                                    valideaza request-urile facute de useri (privind obiectele k8s)
                                </li>
                                <li>
                                    este singura componente care comunica in mod direct cu toate celelalte componente control plane
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>kube-controllermanager</b> - parinte ale controalelor
                        </li>
                        <li>
                            <b>kube-scheduler</b> {"=>"} decide cand si pe ce nod un container sa sa fie planificat
                        </li>
                        <li>
                            <b>etcd</b> {"=>"} ceea mai mare diferenta intre Swarm si Kubernetes;
                            <br/>
                            este o baza de date non-relationala de tip cheie-valoare care stocheaza datele in RAM
                            <br/>
                            (etc = editable text configuration)
                            <br/>
                            (etc = etc distributed, configurari distribuite in cluster);
                            <br/>
                            k8s stocheaza fiecare detaliu: definiti de obiecte, comportamentul unui obiect, starea unui container, starea clusterului,
                            configurare cluster, configurare container, configurare service, reguli ingress, variabile de mediu, id-urile obiectelor,
                            evenimente logate, user input
                            <br/>
                            poate fi accesat prin intermediul <b>kube-apiserver</b>
                        </li>
                        <li>
                            <b>kubelet</b> {"=>"} ruleaza pe fiecare masina k8s (chiar si pe master);
                            <br/>
                            este agent care se asigura ca containerele dorite ruleaza
                        </li>
                        <li>
                            <b>kube-proxy</b> ruleaza pe fiecare masina k8s (chiar si pe master);
                            <br/>
                            este un network proxy pentru containerele care ruleaza in k8s (daca OS al masinii gazda are o retea care include reguli de filtrare al pachetelor, kube-proxy le va folosi)
                            <br/>
                            gestioneaza comunicarea:
                            <ul>
                                <li>container {"->"} container </li>
                                <li>node {"->"} node</li>
                                <li>
                                    node {"->"} container
                                </li>
                                <li> container {"->"} internet</li>
                            </ul>
                        </li>
                    </ul>

                    Flux:
                    <ul>
                        <li>
                            kube-apiserver {"=>"} [valideaza requestul de la user] {"=>"} trimite cererea mai departe la ube-controllermanager
                        </li>
                        <li>
                            kube-controllermanager {"=>"} [creaza definitia si configurarile pentru obiectele ce vor fi planificate] {"=>"} trimite mai departe la kube-schedule
                        </li>
                        <li>
                            kube-schedule {"=>"} [analizeaza de ce resurse are nevoie si ii aloca un nod disponibil]
                        </li>
                    </ul>

                    <hr/>
                    <b>Observatie 1</b>
                    <br/>

                    Componentele <b>kube-proxy</b> + <b>kubelet</b> nu sunt considerate ca fiind parte din control plane (planul de control)

                    <hr/>
                    <b>Observatie 2</b>
                    <br/>
                    Nodurile de tip <i>workers</i> ruleaza doar <b>kube-proxy</b> + <b>kubelet</b>, celelalte componente fiind inactive, pana cand nodul este promovat la rol de <i>master</i>

                    <hr/>
                    <b>2. Pod</b>
                    <br/>
                    <br/>

                    Un pod:
                    <ul>
                        <li>
                            cea mai mica unitate de orghestratie in k8s
                        </li>
                        <li>
                            contine 1 sau mai multe containere (de obicei 1 singur; cel putin 1)
                        </li>
                        <li>
                            conceput sa fie efemer
                        </li>
                        <li>
                            programeaza containere cu politica de restart ca fiind <b>always</b> in mod implicit, daca nu este mentionata altfel
                        </li>
                        <li>
                            obiectele de nivel inalt folosesc template de pod pentru a programa multiple pod-uri
                        </li>
                    </ul>

                    Din UCP se poate creea obiecte K8s, cum ar fi pod-uri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-pod # nume pod\n' +
                            '  labels:\n' +
                            '    app: my-app-kj\n' +
                            '\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kj-container\n' +
                            '    image: busybox\n' +
                            '    command: [\'sh\', \'-c\',\'echo this is busybox && sleep 360\']'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Etichete si selectori</b>

                    <br/>
                    <br/>

                    Etichetele sunt perechi cheie-valoare asociate cu un obiect k8s sau nod.
                    <br/>
                    Sunt folosite de user si controllere.
                    <br/>
                    Orice object k8s poate avea unul sau mai multe etichete.
                    <br/>
                    Un selector selecteaza etichete.

                    <hr/>

                    <b>3. Obiecte de tip ReplicaSet</b>

                    <br/>
                    <br/>

                    <ul>
                        <li>este o unitate de orghestratie de nivel mai inalt (comparativ cu un pod)</li>
                        <li>folosit pentru a creea si a mentine un anume numar de replici a unui pod</li>
                        <li>replica set sunt controlate de controllere de tip replicaset</li>
                        <li>foloseste etichete si seletori pentru a identifica ce poduri sa controleze</li>
                        <li>foloseste template pod pentru a crea pod-uri (campul <i>template</i>); (configuratiile sunt tinute in sectiunea <i>spec</i>)</li>
                        <li>sunt un mod de folosi la scalarea containere, dar nu sunt in varful obiectelor; cel mai des sunt gestionate de obiecte de tip <i>Deployment</i></li>
                    </ul>

                    Exemplu de ReplicaSet, pentru a creea Pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: ReplicaSet\n' +
                            'metadata:\n' +
                            '  name: replica-demo\n' +
                            'spec:\n' +
                            '  replicas: 3\n' +
                            '  selector: \n' +
                            '    matchLabels:\n' +
                            '      component: replicaset-demo\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:' +
                            '        component: replicaset-demo\n' +
                            '    spec: \n' +
                            '      containers: \n' +
                            '      - name: php-redis \n' +
                            '        image: gcr.io/google_sample/gb-frontend:v3\n' +
                            '        ports:\n' +
                            '        - containerPort: 80' +
                            ''}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Obiecte de tip Deployment</b>

                    <br/>
                    <br/>

                    <ul>
                        <li>este o unitate de orghestratie de nivel mai inalt (comparativ cu replicaset)</li>
                        <li>folosit pentru a creea pod-uri, gestiona replici, atasare servicii, rollout updates, rollback changes, etc</li>
                        <li>foloseste etichete si seletori pentru a identifica ce poduri sa controleze</li>
                        <li>utile pentru deployerea aplicatiilor stateless</li>
                        <li>este cel mai folosit si recomandat obiect k8s pentru orghestratie</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment # nume deployment\n' +
                            'spec:\n' +
                            '  replicas: 1 # numarul de replici\n' +
                            '  # selecteaza obiectele pe baza potrivirii cu labels (conditia de potrivire e cu AND)\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '      # tier: backend\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        # cheie: valoare (pot fi mai multe); se folosesc in selector\\matchLabels (vezi mai sus) \n' +
                            '        component: web\n' +
                            '        # tier: backend\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000\n'}
                    </SyntaxHighlighter>

                    Conectat ssh la masina, se pot lista pod-urile (cele din namespace <i>default</i>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>

                    Listare pod-urile dintr-un anumit namespace:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get pods --namespace=[nume-namespace]'}
                    </SyntaxHighlighter>

                    Aplicare fisier deployment:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl apply -f [nume-fisier-deployment.yaml]'}
                    </SyntaxHighlighter>
                    (daca se actualizeaza <i>image</i>, atunci se va creea o noua replicaset cu pod-urile dorite si vechiul replicaset nu va mai contine nici un pod;
                    dar, oprirese pod-urilor de pe repliaset-ul vechi se opresc si sterg pe masura ce sunt create si pornite pe noul replicaset)

                    <br/>
                    <br/>

                    Listare deployments-urile dintr-un anumit namespace:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get deployments --namespace=[nume-namespace]'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                  READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'client-deployment     3/3     3            3           58d\n' +
                            'nginx-deployment      5/5     5            5           17d\n' +
                            'postgres-deployment   1/1     1            1           58d\n' +
                            'redis-deployment      1/1     1            1           58d\n' +
                            'server-deployment     3/3     3            3           58d\n' +
                            'worker-deployment     1/1     1            1           58d\n'}
                    </SyntaxHighlighter>

                    Afisare revizii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl rollout history deployment.v1.apps/nginx-deployment'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'deployment.apps/nginx-deployment \n' +
                            'REVISION  CHANGE-CAUSE\n' +
                            '1         <none>\n'}
                    </SyntaxHighlighter>

                    Afisare mai multe informatii legate de revizie revizii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl rollout history deployment.v1.apps/nginx-deployment --revision [numar-revizie]'}
                    </SyntaxHighlighter>


                    Listare replicaset-urile dintr-un anumit namespace:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get rs --namespace=[nume-namespace]'}
                    </SyntaxHighlighter>

                    Scalare:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl scale deployment nginx-deployment --replicas=2'}
                    </SyntaxHighlighter>
                    Descriere deployment dintr-un anumit namespace:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl describe deployments [nume-deployment] --namespace=[nume-namespace]'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Name:                   nginx-deployment\n' +
                            'Namespace:              default\n' +
                            'CreationTimestamp:      Fri, 16 Dec 2022 13:31:51 +0200\n' +
                            'Labels:                 app=nginx-deployment\n' +
                            'Annotations:            deployment.kubernetes.io/revision: 1\n' +
                            'Selector:               app=nginx-deployment\n' +
                            'Replicas:               2 desired | 2 updated | 2 total | 2 available | 0 unavailable\n' +
                            'StrategyType:           RollingUpdate\n' +
                            'MinReadySeconds:        0\n' +
                            'RollingUpdateStrategy:  25% max unavailable, 25% max surge\n' +
                            'Pod Template:\n' +
                            '  Labels:  app=nginx-deployment\n' +
                            '  Containers:\n' +
                            '   nginx:\n' +
                            '    Image:        nginx\n' +
                            '    Port:         <none>\n' +
                            '    Host Port:    <none>\n' +
                            '    Environment:  <none>\n' +
                            '    Mounts:       <none>\n' +
                            '  Volumes:        <none>\n' +
                            'Conditions:\n' +
                            '  Type           Status  Reason\n' +
                            '  ----           ------  ------\n' +
                            '  Progressing    True    NewReplicaSetAvailable\n' +
                            '  Available      True    MinimumReplicasAvailable\n' +
                            'OldReplicaSets:  <none>\n' +
                            'NewReplicaSet:   nginx-deployment-5fbdf85c67 (2/2 replicas created)\n' +
                            'Events:\n' +
                            '  Type    Reason             Age   From                   Message\n' +
                            '  ----    ------             ----  ----                   -------\n' +
                            '  Normal  ScalingReplicaSet  47s   deployment-controller  Scaled down replica set nginx-deployment-5fbdf85c67 to 2 from 5\n'}
                    </SyntaxHighlighter>

                    Setare imagine noua pentru un deployment:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl set image deployment [nume-deployment] [nume-container]=[imagine-noua]'}
                    </SyntaxHighlighter>

                    Modificari ce se pot face la un deployment:
                    <ul>
                        <li>
                            mondificare imagine container
                        </li>
                        <li>
                            limitarea resurselor
                        </li>
                        <li>
                            schimbare politica Access control
                        </li>
                        <li>
                            modificari in metadata
                        </li>
                        <li>
                            modificari optiuni de stocare (storage)
                        </li>
                    </ul>

                    Strategi de update:
                    <ul>
                        <li>
                            <b>recreate</b> {"=>"} distruge pod-urile si creeaza altele noi; aplicatia e jos - downtime
                        </li>
                        <li>
                            <b>rolling update</b> {"=>"} nu distruge toate pod-uri deodata; le distruge pe masura ce creaza alte noi si ruteaza aplicatia catre ele;
                            <br/>
                            are parametrii:
                            <ul>
                                <li>
                                    <b>maxSurge</b> {"=>"} numarul maxim de poduri care pot fi programate peste numarul original de poduri in timpul unui update rolling;
                                    <br/>
                                    exemplu:
                                    <br/>
                                    daca avem 4 poduri si <i>maxSurge:25%</i> atunci controller-ul de deployment va creea 1 un nou pod la inceput;
                                    <br/>
                                    deci la un moment dat vom avea maxim 6 pod-uri; apoi traficul este rutat de la vechiul pod la noul pod;
                                    deci exista un fractiune de timp cand ambele pod-uri (cel vechi si cel nou) nu vor functiona;

                                    <br/>
                                    valoarea parametrului <b>maxSurge</b> poate fi dat in procente sau ca numar; daca <b>maxSurge</b>=0, atunci nu se programeaza nici un pod in timpul unui update rolling;

                                </li>
                                <li>
                                    <b>maxUnavailable</b> {"=>"} numarul maxim de pod-uri care pot fi indisponibile in timpul unei actualizari /update;
                                    <br/>
                                    exemplu:
                                    <br/>
                                    daca avem 4 poduri si <i>maxUnavailable:25%</i> inseamnaca ce cel putin 3 din cele 4 pod-uri trebuie sa functioneze;
                                    deci poate fi maxim un pod jos.
                                </li>
                            </ul>
                            4 pod-uri, maxSurge:25%, maxUnavailable:25% {"=>"} 1 pod o data!
                            <br/>
                            2 pod-uri, maxSurge:50%, maxUnavailable:50% {"=>"} 1 pod o data!
                            <br/>
                            8 pod-uri, maxSurge:25%, maxUnavailable:25% {"=>"} maxSurge = 2 pod, maxUnavailable=2 pod (deci pot exista maxim 10 poduri si minim 6 in timpul procesului de update)
                        </li>
                    </ul>

                    <hr/>

                    <b>3. Obiecte de tip Job</b>

                    <br/>
                    <br/>

                    <ul>
                        <li>este o unitate de orghestratie de nivel mai inalt (comparativ cu un pod)</li>
                        <li>folosit pentru a creea pod-uri care se vor termina la un moment dat; rulate sa se termine;
                            dupa si-a termintat treaba containerul este oprit</li>
                        <li>
                            un job poate fi repornit daca e nevoie sa fie repornit; dar daca job-ul este sters dupa terminare, se elibereaza resursele folosite;
                        </li>
                        <li>
                            pod-urile de tip job raman in starea COMPLETED dupa ce se termina
                        </li>
                        <li>
                            cel mai des sunt folosite pentru procesare paralela; cron job-urile sunt folosite pentru planificarile de rulare
                        </li>

                    </ul>

                    Exemplu de Job, pentru a creea Pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: batch/v1\n' +
                            'kind: Job\n' +
                            'metadata:\n' +
                            '  name: job-demo\n' +
                            'spec:\n' +
                            '  template:\n' +
                            '    spec: \n' +
                            '      containers: \n' +
                            '      - name: job-container \n' +
                            '        image: perl\n' +
                            '        command: ["perl", "-Mbignum=bpi", "-wle", "print bpi(2000)] \n' +
                            '      restartPolicy: Never # pt ca este Job\n' +
                            '  backoffLimit: 4 # numar de incercari de a porni containerul daca nu s-a reusit din prima' +
                            ''}
                    </SyntaxHighlighter>


                    <hr/>

                    <b>4. Obiecte de tip Service</b>

                    <br/>
                    <br/>

                    Serviciile sunt:
                    <ul>
                        <li>
                            obiecte de retea in k8s.
                        </li>
                        <li>independente de pod-uri</li>
                    </ul>
                    Pod-urile sunt expuse prin intermediul serviciilor si serviciile sunt expuse altor retele, inclusiv internet.
                    <br/>
                    Fiecare container (dintr-un pod) are o adresa IP privata (se atribuie si se poate schimba la crearea pod-ului).
                    <br/>
                    Serviciile ofera IP-uri si endpoint-uri pentru pod-uri, fiind folosite de containere.
                    <br/>
                    Fata de alte obiecte de tip <i>workload</i>, care sunt create de <i>kubelet</i>, serviciile sunt create si mentiunute de <b>kube-proxy</b>.
                    Kube-proxy ofera IP-uri virtuale catre pod-uri acordate de Service API
                    <br/>
                    Serviciile au IP-ul lor virtual si un interval de IP-uri virtuale care sunt transmise catre <i>workload</i>-ere.
                    <br/>
                    Foloseste etichete si selectori pentru a identifica ce workload-ere sa controleze si creaza endpoint-uri.
                    <br/>
                    Tipuri de servicii:
                    <ul>
                        <li>ClusterIP (serviciul implicit) {"=>"} creaza expunere interna; nu te poti conecta la un ClusterIP din afara clusterului;
                            <br/>
                            Daca un serviciu este de tip ClusterIP, atunci o adresa IP interna de cluster este asignata serviciului;
                            <br/>
                            O adresa IP interna de cluster este disponivila doar in interiorul clusterului.
                            <br/>
                            Cand se creaza un serviciu, si nu se specfica tipul, atunci in mod implicit va fi <i>ClusterIP</i>.
                        </li>
                        <li>NodePort {"=>"} expune la internet prin: IP-ul extern al fiecarui nod + port static al clusterului;
                            <br/>
                            Cand o cerere externa incerca sa accese o <i>workload</i> (aplicație care rulează în unul sau mai multe poduri Kubernetes (K8s)) prin NodePort,
                            serviciul NodePort ruteaza cererea catre clusterIP, care apoi permite cererii sa ajunga la <i>workload</i>;
                            <br/>
                            Daca serviciul este NodePort, atunci k8s va aloca un port (implicit: 30000-32767) pentru fiecare nod de tip worker;
                            <br/>
                            Un serviciu NodePort poate fi acesat din afara clusterului: [workerIP]:[nodePort];
                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl run nodeport-pod --labels="type=publicpod" --image=nginx'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get pods --show-labels'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'nano nodeport.yaml'}
                            </SyntaxHighlighter>
                            cu continutul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'apiVersion: v1\n' +
                                    'kind: Service\n' +
                                    'metadata:\n' +
                                    '   name: kplabs-nodeport\n' +
                                    'spec:\n' +
                                    '   selector:\n' +
                                    '     type: publicpod\n' +
                                    '   type: NodePort\n' +
                                    '   ports:\n' +
                                    '   - port: 80\n' +
                                    '     targetPort: 80'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl apply -f nodeport.yaml'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get service'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'NAME                          TYPE        CLUSTER-IP       EXTERNAL-IP   PORT(S)          AGE\n' +
                                    'client-cluster-ip-service     ClusterIP   10.99.151.88     <none>        3000/TCP         59d\n' +
                                    'kj-service                    ClusterIP   10.111.59.176    <none>        8080/TCP         51m\n' +
                                    'kplabs-nodeport               NodePort    10.109.209.92    <none>        80:30830/TCP     6s\n'}
                            </SyntaxHighlighter>
                            deci, Portul extern este 30830!

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get nodes -o wide'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'NAME       STATUS   ROLES           AGE   VERSION   INTERNAL-IP    EXTERNAL-IP   OS-IMAGE             KERNEL-VERSION      CONTAINER-RUNTIME\n' +
                                    'minikube   Ready    control-plane   59d   v1.25.2   192.168.49.2   <none>        Ubuntu 20.04.5 LTS   5.15.0-56-generic   docker://20.10.18'}
                            </SyntaxHighlighter>
                            poate sa nu afiseze nimic la EXTERNAL-IP, si atunci:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'minikube ip'}
                            </SyntaxHighlighter>
                            poate afisa: 192.168.49.2
                            <hr/>
                            Aplicatia se poate accesa: 192.168.49.2:30830
                        </li>
                    </ul>

                    Serviciile k8s permit: DNS, expunere multi-port, IP binding privat, etc
                    <hr/>
                    Resurse <i>workload</i>:
                    <ul>
                        <li>Deployment / ReplicaSet</li>
                        <li>StatefulSet</li>
                        <li>DaemonSet</li>
                        <li>Job / CronJob</li>
                        <li>Custom Resource</li>
                    </ul>

                    Exemplu de serviciu <i>ClusterIP</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: nginx-service # nume serviciu\n' +
                            '  labels:\n' +
                            '    run: my-nginx\n' +
                            '\n' +
                            'spec:\n' +
                            '  selector:\n' +
                            '    run:  my-nginx\n' +
                            '  type: ClusterIP # implicit, si se poate omite\n' +
                            '  ports:\n' +
                            '    - protocol: TCP\n' +
                            '      port: 80\n'}
                    </SyntaxHighlighter>
                    In mod implicit, K8s creaza, la instalare, serviciul numit <i>kubernetes</i> de tip ClusterIP.
                    <br/>
                    Acest serviciu este folosit de componente interne pentru alocare IP si comunicare.

                    <br/>
                    <br/>
                    Exemplu de serviciu <i>NodePort</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: nginx-nodeport # nume serviciu\n' +
                            '  labels:\n' +
                            '    run: my-nginx\n' +
                            '\n' +
                            'spec:\n' +
                            '  selector:\n' +
                            '    run:  my-nginx\n' +
                            '  type: NodePort\n' +
                            '  ports:\n' +
                            '    - protocol: TCP\n' +
                            '      name: http\n' +
                            '      port: 8080\n' +
                            '      targetPort: 80\n' +
                            '    - protocol: TCP\n' +
                            '      name: https\n' +
                            '      port: 443\n' +
                            ''}
                    </SyntaxHighlighter>

                    <br/>
                    Listare servicii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get svc'}
                    </SyntaxHighlighter>

                    Descriere serviciu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl describe svc [nume-serviciu]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alta abordare este crearea de servicii independente de pod-uri.
                    Si apoi sa se faca asociarea dintre serviu si endpoint (prin intermediu obiectului <b>Endpoints</b>)

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl run backend-pod-1 --image=nginx\n' +
                            'kubectl run backend-pod-2 --image=nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl run frontend-pod --image=ubuntu --command -- sleep 3600'}
                    </SyntaxHighlighter>

                    Testare:
                    <br/>
                    afisa pod-uri cu IP-urile (se foloseste optiunea <b>-o wide</b>)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get pods -o wide'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                                   READY   STATUS                       RESTARTS      AGE    IP            NODE       NOMINATED NODE   READINESS GATES\n' +
                            'backend-pod-1                          1/1     Running                      0             23s    172.17.0.22   minikube   <none>           <none>\n' +
                            'backend-pod-2                          1/1     Running                      0             17s    172.17.0.23   minikube   <none>           <none>\n' +
                            'frontend-pod                           1/1     Running                      0             11s    172.17.0.24   minikube   <none>           <none>\n'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl exec -it frontend-pod -- bash\n' +
                            'apt-get update && apt-get -y install curl\n' +
                            'curl 172.17.0.22 # ip pentru backend-pod-1'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano service.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '   name: kj-service\n' +
                            'spec:\n' +
                            '   ports:\n' +
                            '   - port: 8080\n' +
                            '     targetPort: 80'}
                    </SyntaxHighlighter>
                    (se creaza un serviciu, fara nici un endpoint)
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl apply -f service.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get service' }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                          TYPE        CLUSTER-IP       EXTERNAL-IP   PORT(S)          AGE\n' +
                            'kj-service                    ClusterIP   10.111.59.176    <none>        8080/TCP         6s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl describe service kj-service' }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Name:              kj-service\n' +
                            'Namespace:         default\n' +
                            'Labels:            <none>\n' +
                            'Annotations:       <none>\n' +
                            'Selector:          <none>\n' +
                            'Type:              ClusterIP\n' +
                            'IP Family Policy:  SingleStack\n' +
                            'IP Families:       IPv4\n' +
                            'IP:                10.111.59.176\n' +
                            'IPs:               10.111.59.176\n' +
                            'Port:              <unset>  8080/TCP\n' +
                            'TargetPort:        80/TCP\n' +
                            'Endpoints:         <none>\n' +
                            'Session Affinity:  None\n' +
                            'Events:            <none>\n'}
                    </SyntaxHighlighter>
                    (Endpoints = [none] - pentru ca nu e asociat inca nici un endpoint)
                    <br/>
                    <br/>
                    apoi asociere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano endpoint.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Endpoints\n' +
                            'metadata:\n' +
                            '  name: kj-service\n' +
                            'subsets:\n' +
                            '  - addresses:\n' +
                            '      - ip: 172.17.0.22 # ip pt backend-pod-1\n' +
                            '    ports:\n' +
                            '      - port: 80'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl apply -f endpoint.yaml'}
                    </SyntaxHighlighter>

                    acum:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl describe service kj-service' }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Name:              kj-service\n' +
                            'Namespace:         default\n' +
                            'Labels:            <none>\n' +
                            'Annotations:       <none>\n' +
                            'Selector:          <none>\n' +
                            'Type:              ClusterIP\n' +
                            'IP Family Policy:  SingleStack\n' +
                            'IP Families:       IPv4\n' +
                            'IP:                10.111.59.176\n' +
                            'IPs:               10.111.59.176\n' +
                            'Port:              <unset>  8080/TCP\n' +
                            'TargetPort:        80/TCP\n' +
                            'Endpoints:         172.17.0.22:80\n' +
                            'Session Affinity:  None\n' +
                            'Events:            <none>\n'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl exec -it frontend-pod -- bash\n' +
                            'curl <SERVICE-IP:8080>'}
                    </SyntaxHighlighter>
                    unde SERVICE-IP, in exemplul nostru este 10.111.59.176

                    <br/>
                    Pentru a afisa endpoint-urile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get endpoints' }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                          ENDPOINTS                                           AGE\n' +
                            'client-cluster-ip-service     172.17.0.16:3000,172.17.0.2:3000,172.17.0.3:3000    59d\n' +
                            'kj-service                    172.17.0.22:80                                      19m\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa endpoint-urile unui endpoint:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl describe endpoints kj-service' }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Name:         kj-service\n' +
                            'Namespace:    default\n' +
                            'Labels:       <none>\n' +
                            'Annotations:  <none>\n' +
                            'Subsets:\n' +
                            '  Addresses:          172.17.0.22\n' +
                            '  NotReadyAddresses:  <none>\n' +
                            '  Ports:\n' +
                            '    Name     Port  Protocol\n' +
                            '    ----     ----  --------\n' +
                            '    <unset>  80    TCP\n' +
                            '\n' +
                            'Events:  <none>\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca se adauga un pod manual:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl run manual-pod --image-nginx' }
                    </SyntaxHighlighter>
                    si apoi i se adauga o eticheta:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl label pods manual-pod app=nginx' }
                    </SyntaxHighlighter>
                    si exista un serviciu care selecteaza pod-urile cu eticheta <i>app=nginx</i>, atunci,
                    in mod dinamic va fi adaugat si acesta la serviciu.

                    <br/>
                    <br/>

                    Sterge test:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl delete service kplabs-service\n' +
                            'kubectl delete endpoints kplabs-service\n' +
                            'kubectl delete pod backend-pod-1\n' +
                            'kubectl delete pod backend-pod-2\n' +
                            'kubectl delete pod frontend-pod'}
                    </SyntaxHighlighter>

                    <hr/>
                    Comunicarea:
                    <ul>
                        <li>
                            container-to-container {"=>"} (comunicarea intre containerele dintrun pod) {"=>"} <b>localhost</b>
                            <br/>
                            pod-urile pot contine un grup de containere care au aceiasi adresa IP.
                        </li>
                        <li>
                            pod-to-pod {"=>"} (comunicare intre pod-uri) {"=>"} retea bridge
                        </li>
                        <li>
                            pod-to-service {"=>"} serviciile ofera o singura adresa IP si DNS pentru acesarea pod-urilor
                        </li>
                        <li>
                            internet-to-service {"=>"} ingress este o colectie de reguli de rutare care guverneaza cum utilizatori externu pot accesa serviciile dintr-un cluster
                        </li>
                    </ul>

                    <hr/>
                    <b>Politici de retea</b>
                    <br/>
                    <br/>

                    In mod implicit, pod-urile nu sunt izolate; accepta trafic din orice sursa.
                    <br/>
                    De exemplu:
                    <ul>
                        <li>Pod 1 poate comunica cu Pod 2.</li>
                        <li>Pod 1 din namespace X oate comunica cu Pod 2 din namespace Z</li>
                    </ul>

                    O politica de retea este o specificatie a modului in care unui grup de pod-uri se permite sa comunice intre ele si cu alte endpoint-uri ale retelei.
                    <br/>
                    Exemple:
                    <ul>
                        <li>pod 1 poate comunica cu Pod 2 daca e in acealasi namespace</li>
                        <li>pod 1 poate comunica cu Pod 2 daca se afla in spatiul de nume Security</li>
                        <li>Nimeni nu ar trebui sa comunie cu pod 1</li>
                    </ul>
                    Daca pod cu numele <i>pod01</i> este compromis, si trebuie sa blocam comunicarea cu acesta, trebuie izolat (nu sters):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: default-deny-pod\n' +
                            '  namespace: default\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '        run: pod01\n' +
                            '  policyTypes:\n' +
                            '  - Ingress\n' +
                            '  - Egress'}
                    </SyntaxHighlighter>
                    Obiect folosit: <b>NetworkPolicy</b> (apiVersion: networking.k8s.io/v1)

                    <br/>
                    <br/>

                    Tipuri de politici:
                    <ul>
                        <li>Ingress {"=>"} permite traficul care vine din interior; orice trafic care vine din afara este blocat</li>
                        <li>Egress {"=>"} permite traficul care vine din exterior </li>
                    </ul>


                    <hr/>
                    <b>5. Volume</b>
                    <br/>
                    <br/>

                    Volumele sunt folosite ca backup de date si permit partajarea datelor intre containere din acelasi pod.
                    <br/>
                    Pot fi:
                    <ul>
                        <li>
                            efemere {"=>"} traiesc cat traiesc si pod-urile pe care sunt montate
                        </li>
                        <li>
                            persistete {"=>"} trainesc independet de ce se intampla cu pod-urile
                        </li>
                    </ul>

                    Se folosesc obiecte numite <i>Persistent Volume Claims (PVC)</i> si <i>PersistentVolume (PV)</i>.

                    <br/>
                    <br/>

                    Exemplu (obiect de tip <b>PersistentVolume</b>, volum de tip <b>hostPath</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolume\n' +
                            'metadata:\n' +
                            '  name: my-pv\n' +
                            '  labels:\n' +
                            '    type: local\n' +
                            '\n' +
                            'spec:\n' +
                            '  capacity: \n' +
                            '    storage: 10Gi\n' +
                            '  storageClassName: manual\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  hostPath:\n' +
                            '    path: /mnt/data\n'}
                    </SyntaxHighlighter>
                    (se va creea un director <i>/mnt/data</i> si inauntru se poate crea un fisier html)
                    <br/>
                    <br/>
                    Exemplu (obiect de tip <b>PersistentVolumeClaim</b>):

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  name: my-pvc\n' +
                            '\n' +
                            'spec:\n' +
                            '  storageClassName: manual' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 2Gi'}
                    </SyntaxHighlighter>

                    Exemplu Pod, care foloseste volumul definit mai sus:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-pod # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  volumes: \n' +
                            '    - name: my-pv-storage\n' +
                            '      persistentVolumeClaim:\n' +
                            '        - claimName: my-pvc\n' +
                            '  containers:\n' +
                            '  - name: kj-pv-container\n' +
                            '    image: nginx\n' +
                            '    ports:\n' +
                            '      - containerPort: 80\n' +
                            '        name: "http:-server"\n' +
                            '    volumeMounts:\n' +
                            '      - mountPath: "/usr/share/nginx/html\n' +
                            '        name: my-pv-storage' +
                            '' +
                            ''}
                    </SyntaxHighlighter>

                    <ul>
                        <li>
                            Un PersistentVolume (PV) este o bucată de stocare în cluster sau stocare centrală, să spunem 100 GB.
                        </li>
                        <li>
                            Un PersistentVolumeClaim (PVC) este o solicitare de stocare de către un utilizator pentru ca aplicația să folosească 10 GB.
                        </li>
                    </ul>

                    În scenariul din viața reală, PV este o prăjitură întreagă, iar PVC-ul este o bucatica de prajitura!
                    <br/>

                    Pods consuma resursele nod-ului, PVC-urile consuma resursele PV-ului.

                    <hr/>
                    <b>5. Secrete</b>
                    <br/>
                    <br/>

                    Obiecte de tip <i>Volume projected</i>:
                    <ul>
                        <li>
                            secret {"=>"} este un obiect care contine date de dimensiuni mici, sensibile, cum ar fi: parole, token, key;
                            <br/>
                            sunt stocate in baza de date ETCD;
                            <br/>
                            permite stocarea secretelor central pentru a reduce riscuk de expunere
                        </li>
                        <li>
                            configmaps
                        </li>
                    </ul>

                    Tipuri  de secrete:
                    <ul>
                        <li>
                            opaque (tipul secret implicit) {"=>"} folosit pentru a stoca date nestructurate ale user-ului.
                        </li>
                        <li>
                            kubernetes.io/service-account {"=>"} service account token
                        </li>
                        <li>
                            kubernetes.io/dockercfg {"=>"} serializat in fisierul ~/.dockercfg
                        </li>
                        <li>
                            kubernetes.io/dockerconfigjson {"=>"} serializat in fisierul ~/.docker/config.json
                        </li>

                        <li>
                            kubernetes.io/basic-auth {"=>"} credentiale pentru basic authentification
                        </li>

                        <li>
                            kubernetes.io/ssh-auth {"=>"} credentiale pentru SSH authentification
                        </li>

                        <li>
                            kubernetes.io/tls {"=>"} date pentru un TLS client sau server
                        </li>

                        <li>
                            bootstrap.kubernetes.io/token {"=>"} boostrap token data
                        </li>

                    </ul>

                    Sintaxa pentru a creea un secret:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret [type] | [nume] | [data]'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            type:
                            <ul>
                                <li>generic (file [--form-file], directory, value [--from-literal]) {"=>"} creaza un secret dintr-un fisier local, director sau valoare literala </li>
                                <li>Docker Registry {"=>"} creaza un secret pentru utilizare cu un Docker registry</li>
                                <li>TLS {"=>"} creaza un secret TLS</li>
                            </ul>
                        </li>
                    </ul>

                    Moduri de creea secrete k8s:
                    <ul>
                        <li>
                            fisier YAML cu definirea obiectului secret
                        </li>
                        <li>
                            comenzi kubectl
                        </li>
                    </ul>

                    Creare secret pe baza unei valori/literal:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic primulsecret --from-literal=dbpass=mypass123'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'secret/primulsecret created'}
                    </SyntaxHighlighter>

                    Sa presupunem ca avem 2 fisiere cu date sensibile:
                    <ul>
                        <li>
                            username.txt
                        </li>
                        <li>
                            password.txt
                        </li>
                    </ul>

                    Creare secret pe baza unui fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic user --from-file=./username.txt'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic pswd --from-file=./password.txt'}
                    </SyntaxHighlighter>

                    Alte surse de creare a unui secret, pe langa fisier, sunt:
                    <ul>
                        <li>
                            director
                        </li>
                        <li>
                            variabile de mediu
                        </li>
                        <li>
                            URL
                        </li>
                    </ul>

                    Un singur secret poate imapcheta 1 sau mai multe perechi cheie-valoare din datele de intrare.
                    <br/>
                    Cand este creat dintr-un fisier, daca nu este mentionat, numele fisierului devine cheia secretului, iar continutul valoare cheii.
                    <br/>
                    In exemplul de mai sus, fiind specificate numele cheilor, avem: <i>user</i> si <i>pswd</i>.


                    Listare secrete:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>
                    poate lista:
                    <SyntaxHighlighter>
                        {'NAME         TYPE     DATA   AGE\n' +
                            'pgpassword   Opaque   1      51d\n'}
                    </SyntaxHighlighter>

                    Descriere secrete YAML (kind: List):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets -o yaml'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'items:\n' +
                            '- apiVersion: v1\n' +
                            '  data:\n' +
                            '    PGPASSWORD: MTIzNHBhc3N3b3Jk\n' +
                            '  kind: Secret\n' +
                            '  metadata:\n' +
                            '    creationTimestamp: "2022-11-05T08:51:30Z"\n' +
                            '    name: pgpassword\n' +
                            '    namespace: default\n' +
                            '    resourceVersion: "35481"\n' +
                            '    uid: 10b6b8ba-8138-440a-8490-369081ae8840\n' +
                            '  type: Opaque\n' +
                            'kind: List\n' +
                            'metadata:\n' +
                            '  resourceVersion: ""\n'}
                    </SyntaxHighlighter>

                    Descriere secret:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe secret pgpassword'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Name:         pgpassword\n' +
                            'Namespace:    default\n' +
                            'Labels:       <none>\n' +
                            'Annotations:  <none>\n' +
                            '\n' +
                            'Type:  Opaque\n' +
                            '\n' +
                            'Data\n' +
                            '====\n' +
                            'PGPASSWORD:  12 bytes\n'}
                    </SyntaxHighlighter>

                    Descriere secret YAML (kind: Secret):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets pgpassword -o yaml'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'data:\n' +
                            '  PGPASSWORD: MTIzNHBhc3N3b3Jk\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  creationTimestamp: "2022-11-05T08:51:30Z"\n' +
                            '  name: pgpassword\n' +
                            '  namespace: default\n' +
                            '  resourceVersion: "35481"\n' +
                            '  uid: 10b6b8ba-8138-440a-8490-369081ae8840\n' +
                            'type: Opaque\n'}
                    </SyntaxHighlighter>
                    parola este in <i>base64</i>; pentru verificare/decodificare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo MTIzNHBhc3N3b3Jk | base64 -d'}
                    </SyntaxHighlighter>
                    va afisa: 1234password

                    <hr/>
                    Definire obiect secret intr-un YAML (sa prespunem, ca denumim fisierul: secret-data.yaml):
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  name: altsecret\n' +
                            'type: Opaque\n'+
                            'data:\n' +
                            '  docker_password: 1234\n'
                        }
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f secret-data.yaml'}
                    </SyntaxHighlighter>
                    se va obtine eroare pentru ca parola este in plain-text; a functiona trebuie transformat 1234 in base64:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo -n \'1234\' | base64'}
                    </SyntaxHighlighter>
                    se va afisa: MTIzNA==
                    <br/>
                    Pentru a scris in plain-test, se poate scrie asa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  name: altsecret\n' +
                            'type: Opaque\n'+
                            'stringData:\n' +
                            '  config.yaml: |-\n' +
                            '    docker_password: 1234\n'
                        }
                    </SyntaxHighlighter>

                    se aplica:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f secret.yaml'}
                    </SyntaxHighlighter>

                    <hr/>

                    Dupa ce un secret a fost creat, este nevoie sa fie disponibil containerelor dintr-un pod.
                    <br/>
                    Exita 2 abordari pentru a face acest lucru:
                    <ul>
                        <li>volume</li>
                        <li>variabile de mediu</li>
                    </ul>

                    <hr/>
                    <b>Secrete cu volume</b>

                    <br/>
                    <br/>

                    Exemplu de folosire a unui secret, folosind volume (montare secrete in containere):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-secret-pod # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kj-secret-container\n' +
                            '    image: busybox\n' +
                            '    args: \n' +
                            '    - sleep\n' +
                            '    - "3600"\n' +
                            '    volumeMounts:\n' +
                            '    - name: busy-vol\n' +
                            '      mountPath: "/projected-volume" # calea catre directorul care contine secretele pe masina host\n' +
                            '      readOnly: true\n' +
                            '  volumes:\n' +
                            '  - name: busy-vol\n' +
                            '    projected:\n' +
                            '      sources:\n' +
                            '      - secret:\n' +
                            '          name: user\n' +
                            '      - secret:\n' +
                            '          name: pswd\n' +
                            ''}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-secret-pod # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kj-secret-container\n' +
                            '    image: nginx\n' +
                            '    volumeMounts:\n' +
                            '    - name: busy-vol\n' +
                            '      mountPath: "/projected-volume" # calea catre directorul care contine secretele pe masina host\n' +
                            '      readOnly: true\n' +
                            '  volumes:\n' +
                            '  - name: busy-vol\n' +
                            '    secret:\n' +
                            '      secretName: altsecret' +
                            ''}
                    </SyntaxHighlighter>

                    se aplica:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f pod.yaml'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'pod/kj-secret-pod configured'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl exec -it kj-secret-pod -- bash'}
                    </SyntaxHighlighter>
                    Daca se intra in container, se schimba directorul in <i>/projected-volume</i> si se listeaza sa regasi fisierul <i>config.yaml</i> care va avea ca si continut:
                    <SyntaxHighlighter>
                        {'docker_password: 1234'}
                    </SyntaxHighlighter>
                    reamintire:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  name: altsecret\n' +
                            'type: Opaque\n'+
                            'stringData:\n' +
                            '  config.yaml: |-\n' +
                            '    docker_password: 1234\n'
                        }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Secrete cu variabile de mediu</b>

                    <br/>
                    <br/>

                    Vom folosi secretul creat prin:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic primulsecret --from-literal=dbpass=mypass123'}
                    </SyntaxHighlighter>
                    Fie fisierul <i>secret-env.yaml</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: secret-kj-env\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: secret-kj-env\n' +
                            '    image: nginx\n' +
                            '    env:\n' +
                            '      - name: SECRET_USERNAME\n' +
                            '        valueFrom:\n' +
                            '          secretKeyRef:\n' +
                            '            name: primulsecret # nume secret\n' +
                            '            key: dbpass # cheie secretului \n' +
                            '  restartPolicy: Never'}
                    </SyntaxHighlighter>

                    se aplica:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f secret-env.yaml'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'pod/secret-env created'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl exec -it secret-kj-env -- bash'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'env | grep SECRET'}
                    </SyntaxHighlighter>
                    se va afisa: SECRET_USERNAME=mypass123
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo $SECRET_USERNAME'}
                    </SyntaxHighlighter>
                    se va afisa: mypass123

                    <hr/>
                    <b>6. Configmaps</b>
                    <br/>
                    <br/>

                    Obiecte de tip <i>Volume projected</i>:
                    <ul>
                        <li>
                            secret
                        </li>
                        <li>
                            configmaps
                        </li>
                    </ul>

                    ConfigMaps e similar cu secretele, cu execetia ca aceste se folosesc pentru date care nu sunt confidentiale.
                    <br/>
                    <br/>
                    Sintaxa pentru a creea un configmap:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create configmap [nume] [data-source]'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            data-source:
                            <ul>
                                <li>
                                    file [--form-file]
                                </li>
                                <li>
                                    directory
                                </li>
                                <li>
                                    literal value [--from-literal])
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create configmap dev-config --from-literal=app.mem=2048m'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get configmaps'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME               DATA   AGE\n' +
                            'dev-config         1      5s\n' +
                            'kube-root-ca.crt   1      59d\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get configmap dev-config -o yaml'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'data:\n' +
                            '  app.mem: 2048m\n' +
                            'kind: ConfigMap\n' +
                            'metadata:\n' +
                            '  creationTimestamp: "2023-01-03T12:33:25Z"\n' +
                            '  name: dev-config\n' +
                            '  namespace: default\n' +
                            '  resourceVersion: "3850588"\n' +
                            '  uid: b9d5f17f-f46f-47d3-93e1-98007e7d209a\n'}
                    </SyntaxHighlighter>

                    Exemplu 2 (<i>dev.properties</i> e un fisier cu proprietati):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create configmap dev-props --from-file=dev.properties'}
                    </SyntaxHighlighter>

                    Daca fisierul de proprietati <i></i> are urmatorul continut:
                    <SyntaxHighlighter>
                        {'roma=1\n' +
                            'londra=2\n' +
                            'paris=3'}
                    </SyntaxHighlighter>
                    atunci,
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get configmap dev-props -o yaml'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'data:\n' +
                            '  dev.properties: |\n' +
                            '    roma=1\n' +
                            '    londra=2\n' +
                            '    paris=3\n' +
                            'kind: ConfigMap\n' +
                            'metadata:\n' +
                            '  creationTimestamp: "2023-01-03T12:43:18Z"\n' +
                            '  name: dev-props\n' +
                            '  namespace: default\n' +
                            '  resourceVersion: "3851091"\n' +
                            '  uid: 22adeb4d-641f-464c-a7b5-4b73d926c282\n'}
                    </SyntaxHighlighter>

                    <br/>
                    Exemplu 3:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: ConfigMap\n' +
                            'metadata:\n' +
                            '  name: data-store-env\n' +
                            'data:\n' +
                            '  folder: \'story\'\n' +
                            '  director: \'home\'\n' +
                            '  # key: value..'}
                    </SyntaxHighlighter>

                    Exemplu de folosire a unui ConfigMap:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-configmap-pod # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kj-configmap-container\n' +
                            '    image: redis\n' +
                            '    volumeMounts:\n' +
                            '    - name: redis-vol\n' +
                            '      mountPath: "/etc/foo" \n' +
                            '      readOnly: true\n' +
                            '  volumes:\n' +
                            '  - name: redis-vol\n' +
                            '    configMap:\n' +
                            '      name: data-store-env\n' +
                            ''}
                    </SyntaxHighlighter>

                    Daca se intra pe pod, se poate intra din directorul <i>/etc/foo</i>.
                    <br/>
                    Daca se listeaza continutul acestui fisier se vor vedea 2 fisiere: <i>folder</i> si <i>director</i>;
                    aceste fisiere contin valorile.
                    <br/>
                    Daca se monta un configMap pe baza de fisier in directorul <i>/etc/foo</i>, atunci s-ar fi regasit fisierul folosit la configurare (de exemplu: dev.properties)

                    <hr/>
                    <b>7. Node Affinity (afinitate catre un nod pe baza de eticheta)</b>
                    <br/>
                    <br/>

                    Un nod master poate avea etichete (cheie-valoare). Si se pot adauga noi etichete (de exemplu: type = k8s-workload).
                    Pe baza acestei etichete, un pod poate fi programat pe acel nod sau nu.
                    <br/>
                    <br/>
                    <b>Node Affinity</b> poate fi:
                    <ul>
                        <li>hard {"=>"} requiredDuringSchedulingIgnoringDuringExecution</li>
                        <li>soft {"=>"} prefferedDuringSchedulingIgnoringDuringExecution</li>
                    </ul>

                    Exemplu de folosire <b>affinity</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: busybox-manager # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  affinity:\n' +
                            '    nodeAffinity:\n' +
                            '      requiredDuringSchedulingIgnoringDuringExecution\n' +
                            '        nodeSelectorTerms:\n' +
                            '        - matchExpressions:\n' +
                            '          - key: type\n' +
                            '            operator: In\n' +
                            '            values:\n' +
                            '            - k8s-workload\n' +
                            '  containers:\n' +
                            '  - name: busybox-container\n' +
                            '    image: busybox\n' +
                            ''}
                    </SyntaxHighlighter>
                    Podul va putea fi programat doar pe noduri cu eticheta <i>type = k8s-workload</i>
                    (daca <i>node affinity</i> este <i>prefferedDuringSchedulingIgnoringDuringExecution</i>; daca nu exista, il pune in pending).
                    <br/>
                    Daca <i>node affinity</i> este <i>prefferedDuringSchedulingIgnoringDuringExecution</i>,
                    atunci se incerca sa fie programat pe un nod care indeplineste conditiile, dar daca nu exista un astfel de nod, oricum il programeaza.

                    <hr/>
                    <b>8. Liveness Probe</b>
                    <br/>
                    <br/>

                    Multe aplicaticati care ruleaza perioade lungi de timp ajung intr-o stare blocanta, si singura metoda de fi deblocata este restartarea acesteia.
                    <nr/>
                    K8s ofera <i>liveness probes</i> (probe de viata) pentru a detacta si remdia situatiile de blocare ale aplicatiei.

                    <br/>
                    Exista 3 tipuri de probe care pot fi folosite:
                    <ul>
                        <li>http</li>
                        <li>command</li>
                        <li>tcp</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: liveness\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: liveness\n' +
                            '    image: ubuntu\n' +
                            '    tty: true\n' +
                            '    livenessProbe:\n' +
                            '      exec:\n' +
                            '        command:\n' +
                            '        - service\n' +
                            '        - nginx\n' +
                            '        - status\n' +
                            '      initialDelaySeconds: 20\n' +
                            '      periodSeconds: 5'}
                    </SyntaxHighlighter>
                    Daca comanda <i>service nginx status</i> da eroare, se va considera ca containerul nu e <i>sanatos</i> si k8s ca restarta in mod automat pod-ul.
                    <br/>
                    (<b>livenessProbe</b> {"=>"} ofera o dovada ca aplicatia a pornit cu succes si este sanatoase).
                    <br/>
                    Se vede in coloana STATUS (kubectl get pods).

                    <hr/>
                    <b>9. Liveness Probe</b>
                    <br/>
                    <br/>
                    Uneori, se intampla ca aplicatia sa ruleze, dar temporal sa nu fie capabila sa serveasca trafic.
                    <br/>
                    De exemplu, aplicatia ruleaza, dar inca incarca un fisier de configurare de dimensiuni mari de la vendor extern.
                    <br/>
                    In acest caz, nu vrem sa omoram containerul, si nici nu vrem sa serveasca trafic.
                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: readiness\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: readiness\n' +
                            '    image: ubuntu\n' +
                            '    tty: true\n' +
                            '    readinessProbe:\n' +
                            '     exec:\n' +
                            '       command:\n' +
                            '       - cat\n' +
                            '       - /tmp/healthy\n' +
                            '     initialDelaySeconds: 5\n' +
                            '     periodSeconds: 5'}
                    </SyntaxHighlighter>
                    Daca comanda <b>cat /tmp/healthy</b> se executa cu succes, inseamna ca aplicatia este pregatita sa raspunda la request-uri.
                    <br/>
                    Daca fisierul nu exista aplicatia nu este READY. In momentul in care se creeaza fisierul, aplicatia devine READY.
                    Daca fisierul apoi, este sters, atunci aplicatia nu mai e READY.

                    <br/>
                    (<b>readinessProbe</b> {"=>"} ofera o dovada ca aplicatia este pregatita sa raspunda la request-uri)
                    <br/>
                    Se vede in coloana READY (kubectl get pods).

                    <hr/>
                    <b>10. DeamonSets</b>
                    <br/>
                    <br/>
                    Sa presupunem ca avem 3 noduri. Si vrem sa rulam o singura copie a unui pod in fiecare nod.
                    <br/>
                    Un DaemonSet poate asigura ca toate nodurile ruleaza o copie a unui pod.
                    <br/>
                    Daca un nod este adaugat la cluster, atunci pod-ul este adaugat in mod automat la acest nod.
                    <br/>
                    Ca exemplu, este un pod care contine un container anti-virus (in <b>Swarm</b> exista optiunea <b> --mode global </b>).
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: DaemonSet\n' +
                            'metadata:\n' +
                            '  name: kplabs-daemonset\n' +
                            'spec:\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      name: kplabs-all-pods\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        name: kplabs-all-pods\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - name: kplabs-pods\n' +
                            '        image: nginx'}
                    </SyntaxHighlighter>

                    Afisare obiecte de tip daemonset:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get daemonset'}
                    </SyntaxHighlighter>

                    Descriere obiect de tip daemonset:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe daemonset [nume-daemonset]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>11. Taints si Tolerante: Programari avansate</b>
                    <br/>
                    <br/>

                    Taints = sunt folosite pentru a accepta/respinde un pod pe anumite noduri.
                    Toleratie = permis special, pentru ca un pod sa fie acceptat pe un nod care contine taints.

                    <br/>
                    Aplicare taint unui nod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes [nume-nod] key=value:NoSchedule'}
                    </SyntaxHighlighter>
                    In felul acesta nodul este <i>tainted</i>.

                    <br/>
                    Pentru ca un pod sa poate sa fie programat pe nodul <i>tainted</i> de mai sus, se fac configurari in sectiunea <b>tolerations</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment # nume deployment\n' +
                            'spec:\n' +
                            '  replicas: 5 # numarul de replici\n' +
                            '  # selecteaza obiectele pe baza potrivirii cu labels (conditia de potrivire e cu AND)\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '      # tier: backend\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        # cheie: valoare (pot fi mai multe); se folosesc in selector\\matchLabels (vezi mai sus) \n' +
                            '        component: web\n' +
                            '        # tier: backend\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000\n' +
                            '      tolerations:\n' +
                            '      - key: "key"\n' +
                            '        operator: "Exists"\n' +
                            '        effect: "NoSchedule"'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru adauga o conditie (daca nodul <i>nume-nod</i> are <i>eticheta</i> atunci efectuaza <i>actiune</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes <nume-nod> <eticheta>:<actiune>'}
                    </SyntaxHighlighter>

                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes node-kj disk=pd:NoSchedule'}
                    </SyntaxHighlighter>

                    Pentru a sterge o conditie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes <nume-nod> <cheie-eticheta>:<actiune>-'}
                    </SyntaxHighlighter>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes node-kj disk:NoSchedule-'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>12. Cereri de resurse si limite</b>
                    <br/>
                    <br/>

                    Daca se programeaza o aplicatie mare intr-un nod cu resurse limitate, atunci aceasta va conduce la OutOfMemory (OOM) si <i>downtime</i>

                    <br/>
                    Cererile (requests) si limitele (limits) sunt 2 moduri in care se poate controla cate resurse pot fi asignare unui pod (resurce cum ar fi CPU si memoria)
                    <br/>

                    <ul>
                        <li>
                            Requests {"=>"} garantate pentru a putea fi asignat unui nod
                        </li>
                        <li>
                            Limits {"=>"} se asigura ca containerul nu preaia din resursele nodului penste o anumita valoare
                        </li>
                    </ul>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kplabs-pod\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kplabs-container\n' +
                            '    image: nginx\n' +
                            '    resources:\n' +
                            '      requests:\n' +
                            '        memory: "640Mi"\n' +
                            '        cpu: "0.5"\n' +
                            '      limits:\n' +
                            '        memory: "12800Mi"\n' +
                            '        cpu: "1"'}
                    </SyntaxHighlighter>
                    (resources.requests.memory [mai-mic-sau-egaul-cu] resources.limits.memory) {"=>"} eroare
                    <br/>
                    (resources.requests.memory peste limita maxim disponibila pentru pod) {"=>"} eroare ca nu sunt destule resurse disponibile;
                    <br/>
                    Programare se face in functie de <b>requests</b> si nu de <li>limits</li>!

                    <hr/>
                    <b>Anexa 1. Comparatie docker vs kubenetes</b>
                    <br/>
                    <br/>


                    <table className={"table table-sm table-hover table-dark"}>
                        <thead>
                        <tr>
                            <td>Docker</td>
                            <td>K8s</td>
                            <td>Observatii</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>docker run --name [nume-container] [imagine]</td>
                            <td>kubectl run [nume-container] --image=[imagine]</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>docker exec -it [container] bash</td>
                            <td>kubectl exec -it [container] -- bash</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>[swarm]: docker service create --name antivirus --mode global -dt ubuntu</td>
                            <td>kind: DaemonSet</td>
                            <td>-</td>
                        </tr>

                        </tbody>
                    </table>

                    Pentru a afla mai multe informatii despre obiecte:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl api-resources'}
                    </SyntaxHighlighter>
                    va afisa o lista de obiecte:
                    <SyntaxHighlighter>
                        {'NAME                              SHORTNAMES   APIVERSION                             NAMESPACED   KIND\n' +
                            'bindings                                       v1                                     true         Binding\n' +
                            'componentstatuses                 cs           v1                                     false        ComponentStatus\n' +
                            'configmaps                        cm           v1                                     true         ConfigMap\n' +
                            'endpoints                         ep           v1                                     true         Endpoints\n' +
                            'events                            ev           v1                                     true         Event\n' +
                            'limitranges                       limits       v1                                     true         LimitRange\n' +
                            'namespaces                        ns           v1                                     false        Namespace\n' +
                            'nodes                             no           v1                                     false        Node\n' +
                            '.............................................................................................................'}
                    </SyntaxHighlighter>

                    Daca se obtine urmatoarea eroare:
                    <SyntaxHighlighter>
                        {'Unable to connect to the server: dial tcp 192.168.49.2:8443: connect: no route to host'}
                    </SyntaxHighlighter>
                    atunci trebuie pornit clusterul; de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>


                    Pentru a afla mai multe informatii despre un obiect anume:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl explain pod'}
                    </SyntaxHighlighter>

                    Afisare pod-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>

                    Afisare pod-uri in functie de label-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -l [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -l [cheie]!=[valoare]'}
                    </SyntaxHighlighter>

                    Afisare pod-uri cu label-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --show-labels'}
                    </SyntaxHighlighter>

                    Adaugare label la un pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pod [nume-pod] [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    Stergere label la un pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pod [nume-pod] [cheie]-'}
                    </SyntaxHighlighter>

                    Adaugare/actualizare pentru toate podurile o eticheta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label pods --all [cheie]=[valoare]'}
                    </SyntaxHighlighter>

                    Descriere pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe pod [nume-pod]'}
                    </SyntaxHighlighter>

                    Aplicare, crearea obiecte pe baza definitiilor dintr-un fisier yaml:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f [nume-fisier.yaml]'}
                    </SyntaxHighlighter>

                    Stergere servicii pe baza definitiilor dintr-un fisier yaml:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete -f [nume-fisier.yaml]'}
                    </SyntaxHighlighter>

                    Stergere toate pod-urile
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete pods --all'}
                    </SyntaxHighlighter>

                    <hr/>

                    Afisare YAML "generat" la pornirea unui pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  creationTimestamp: null\n' +
                            '  labels:\n' +
                            '    run: nginx\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - image: nginx\n' +
                            '    name: nginx\n' +
                            '    resources: {}\n' +
                            '  dnsPolicy: ClusterFirst\n' +
                            '  restartPolicy: Always\n' +
                            'status: {}\n'}
                    </SyntaxHighlighter>


                    Afisare YAML pentru un deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployment [nume-deployment] -o yaml'}
                    </SyntaxHighlighter>

                    Afisare Json pentru un deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployment [nume-deployment] -o json'}
                    </SyntaxHighlighter>

                    Editare fisier de deployment cu editorul implicit:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl edit deployment  nginx-deployment'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default K8sDockerContent;