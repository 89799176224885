import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class LiferayK8sContent extends BaseContentPage {

    constructor(props) {
        super(props, "kubernetes-liferay", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}


                <div className={"text-justify important"}>

                    <b>Liferay</b>
                    <br/>
                    <br/>

                    Resurse necesare:
                    <ul>
                        <li>
                            Procesor (CPU):
                            <ul>
                                <li>
                                    Minim: 2 nuclee fizice sau virtuale
                                </li>
                                <li>
                                    Recomandat: 4 nuclee fizice sau virtuale sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Memorie (RAM):
                            <ul>
                                <li>
                                    Minim: 4 GB RAM
                                </li>
                                <li>
                                    Recomandat: 8 GB RAM sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Stocare/spatiu pe disk:
                            <ul>
                                <li>
                                    Minim: 10 GB spațiu liber pe disc
                                </li>
                                <li>
                                    Recomandat: 20 GB sau mai mult (în funcție de dimensiunea datelor și a conținutului)
                                </li>
                            </ul>
                        </li>
                    </ul>


                    <b>0. Mediu</b>
                    <br/>
                    <br/>

                    Ne conectam pe masina:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'ssh kj@192.168.1.10 -p 22'}
                    </SyntaxHighlighter>

                    Se creaza directorul <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'mkdir liferay'}
                    </SyntaxHighlighter>

                    Navigam in directorul nou creat <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'cd liferay'}
                    </SyntaxHighlighter>

                    Verificare minikube:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Stopped\n' +
                            'kubelet: Stopped\n' +
                            'apiserver: Stopped\n' +
                            'kubeconfig: Stopped\n'}
                    </SyntaxHighlighter>
                    Daca este oprit, se poate porni:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>

                    Verificare din nou status:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Running\n' +
                            'kubelet: Running\n' +
                            'apiserver: Running\n' +
                            'kubeconfig: Configured\n'}
                    </SyntaxHighlighter>

                    Afisare detalii:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube profile list'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| Profile  | VM Driver | Runtime |      IP      | Port | Version | Status  | Nodes | Active |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| minikube | docker    | docker  | 192.168.49.2 | 8443 | v1.25.2 | Running |     1 | *      |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Deployment </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'nano liferay-deployment.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: liferay\n' +
                            '  name: liferay--cluster\n' +
                            'spec:\n' +
                            '  ports:\n' +
                            '  - name: "8080"\n' +
                            '    port: 8080\n' +
                            '  selector:\n' +
                            '    app: liferay\n' +
                            '\n' +
                            '---\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: liferay-data\n' +
                            '  name: liferay-data\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '  - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 100Mi\n' +
                            '\n' +
                            '\n' +
                            '---\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: liferay-config\n' +
                            '  name: liferay-config\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '  - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 100Mi\n' +
                            '\n' +
                            '---\n' +
                            '\n' +
                            'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: liferay\n' +
                            '  name: liferay\n' +
                            'spec:\n' +
                            '  replicas: 1 #Inicialmente crearemos 1 pod de este deployment\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: liferay\n' +
                            '  strategy:\n' +
                            '    type: RollingUpdate #Usaremos rollingupdate con el fin de disponer de zero-downtime\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: liferay\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - env: \n' +
                            '        - name: LIFERAY_JAVA_OPTS \n' +
                            '          value: -Xms2g -Xmx2g\n' +
                            '        - name: LIFERAY_MODULE_PERIOD_FRAMEWORK_PERIOD_PROPERTIES_PERIOD_OSGI_PERIOD_CONSOLE\n' +
                            '          value: 0.0.0.0:11311\n' +
                            '        - name: LIFERAY_WEB_PERIOD_SERVER_PERIOD_DISPLAY_PERIOD_NODE #La usaremos con el fin de probar el cluster\n' +
                            '          value: "true"\n' +
                            '        - name: LIFERAY_REDIRECT_PERIOD_URL_PERIOD_SECURITY_PERIOD_MODE #La usaremos para permitir los redirect en el cluster\n' +
                            '          value: "domain"\n' +
                            '        image: liferay/portal:7.3.1-ga2\n' +
                            '        resources:\n' +
                            '          requests: #Recursos que se aprovisionan al memomento de arrancar el pod\n' +
                            '            memory: "2048Mi"\n' +
                            '            cpu: "2"\n' +
                            '          limits: #Limite de recursos que podrá disponer el pod\n' +
                            '            memory: "4098Mi"\n' +
                            '            cpu: "3"\n' +
                            '        name: liferay \n' +
                            '        ports: #puertos abiertos en el contenedor\n' +
                            '        - containerPort: 11311\n' +
                            '        - containerPort: 8080\n' +
                            '        readinessProbe: # permite controlar si el pod esta con capacidad de dar servicio, si no tiene capacidad k8s detiene el trafico a el\n' +
                            '          httpGet:\n' +
                            '            path: "/c/portal/layout"\n' +
                            '            port: 8080\n' +
                            '          initialDelaySeconds: 120\n' +
                            '          periodSeconds: 15\n' +
                            '          failureThreshold: 3\n' +
                            '          successThreshold: 3\n' +
                            '        livenessProbe: # Permite controlar cuando el pod se considera vivo y se mete en en balanceador. Si falla, reinicia el pod\n' +
                            '          tcpSocket:\n' +
                            '            port: 8080\n' +
                            '          initialDelaySeconds: 120\n' +
                            '          periodSeconds: 20\n' +
                            '          failureThreshold: 3\n' +
                            '          successThreshold: 1\n' +
                            '        volumeMounts: # directorios donde se van a montar los volumenes\n' +
                            '        - mountPath: /opt/liferay/data\n' +
                            '          name: liferay-data\n' +
                            '        - mountPath: /mnt/liferay\n' +
                            '          name: liferay-config\n' +
                            '      restartPolicy: Always #politica de reinicio ante errores en el contenedor\n' +
                            '      volumes: # descripcion de los volumenes y pvc\n' +
                            '      - name: liferay-data\n' +
                            '        persistentVolumeClaim:\n' +
                            '          claimName: liferay-data\n' +
                            '      - name: liferay-config\n' +
                            '        persistentVolumeClaim:\n' +
                            '          claimName: liferay-config'}
                    </SyntaxHighlighter>

                    Aplicare:
                    <SyntaxHighlighter>
                        {'kubectl apply -f liferay-deployment.yml -n=liferay'}
                    </SyntaxHighlighter>

                    <hr/>
                    Fisiere de configurare:
                    <ul>
                        <li>/files/portal-ext.properties (in care se specifica configurare de conectare la baza de date)</li>
                        <li>/files/osgi/configs/com.liferay.portal.search.elasticsearch6.configuration.ElasticsearchConfiguration.config</li>
                    </ul>

                    Fisierul <i>portal-ext.properties</i> (pentru MySQL) poate avea urmatorul continut:
                    <SyntaxHighlighter>
                        {'# This is main Liferay configuration file, common (shared) for all Liferay environments.\n' +
                            '#\n' +
                            '# Liferay Workspace will copy this file into Liferay bundle\'s root directory (= ${liferay.home})\n' +
                            '# when Liferay bundle is being built.\n' +
                            '\n' +
                            '##\n' +
                            '## JDBC\n' +
                            '##\n' +
                            '\n' +
                            '\n' +
                            '    jdbc.default.driverClassName=com.mysql.cj.jdbc.Driver\n' +
                            '    jdbc.default.url=jdbc:mysql://database:3306/lportal?dontTrackOpenResources=true&holdResultsOpenOverStatementClose=true&useFastDateParsing=false\n' +
                            '    jdbc.default.username=roto2\n' +
                            '    jdbc.default.password=roto2\n' +
                            '\n' +
                            '##\n' +
                            '## Retry JDBC connection on portal startup.\n' +
                            '##\n' +
                            '\n' +
                            '    #\n' +
                            '    # Set the number of seconds to retry getting a JDBC connection on portal\n' +
                            '    # startup.\n' +
                            '    #\n' +
                            '    retry.jdbc.on.startup.delay=5\n' +
                            '\n' +
                            '    #\n' +
                            '    # Set the max number of times to retry getting a JDBC connection on portal\n' +
                            '    # startup.\n' +
                            '    #\n' +
                            '    retry.jdbc.on.startup.max.retries=5\n' +
                            '\n' +
                            '##\n' +
                            '## Company\n' +
                            '##\n' +
                            '\n' +
                            '    company.default.name=Liferay Kubernetes\n' +
                            '\n' +
                            '    #\n' +
                            '    # This sets the default web ID. Omniadmin users must belong to the company\n' +
                            '    # with this web ID.\n' +
                            '    #\n' +
                            '    company.default.web.id=liferay.com \n' +
                            '\n' +
                            '##\n' +
                            '## Servlet Filters\n' +
                            '##\n' +
                            '\n' +
                            '    #\n' +
                            '    # If the user can unzip compressed HTTP content, the GZip filter will\n' +
                            '    # zip up the HTTP content before sending it to the user. This will speed up\n' +
                            '    # page rendering for users that are on dial up.\n' +
                            '    #\n' +
                            '    com.liferay.portal.servlet.filters.gzip.GZipFilter=false\n' +
                            '\n' +
                            '    #\n' +
                            '    # The NTLM filter is used to provide NTLM based single sign on.\n' +
                            '    #\n' +
                            '    com.liferay.portal.servlet.filters.sso.ntlm.NtlmFilter=false\n' +
                            '\n' +
                            '    #\n' +
                            '    # The NTLM post filter is used to fix known issues with NTLM and ajax\n' +
                            '    # requests. See LPS-3795.\n' +
                            '    #\n' +
                            '    com.liferay.portal.servlet.filters.sso.ntlm.NtlmPostFilter=false\n' +
                            '    \n' +
                            '   \n' +
                            '    \n' +
                            '##\n' +
                            '#\t# Cluster Link\n' +
                            '#\t#\n' +
                            '\n' +
                            '    #\n' +
                            '    # Set this to true to enable the cluster link. This is required if you want\n' +
                            '    # to cluster indexing and other features that depend on the cluster link.\n' +
                            '    #\n' +
                            '    cluster.link.enabled=true\n' +
                            '\n' +
                            '    ehcache.cluster.link.replication.enabled=true\n' +
                            '\n' +
                            '    #\n' +
                            '    # Set this property to autodetect the default outgoing IP address so that\n' +
                            '    # JGroups can bind to it. The property must point to an address that is\n' +
                            '    # accessible to the portal server, www.google.com, or your local gateway.\n' +
                            '    #\n' +
                            '    cluster.link.autodetect.address=database:3306'}
                    </SyntaxHighlighter>

                    Fisierul <i>com.liferay.portal.search.elasticsearch6.configuration.ElasticsearchConfiguration.cfg</i> poate avea urmatorul continut:
                    <SyntaxHighlighter>
                        {'operationMode="REMOTE"\n' +
                            'indexNamePrefix="liferay-"\n' +
                            'transportAddresses="search.liferay-prod.svc.cluster.local:9300"\n' +
                            'clusterName="liferay_cluster"\n' +
                            'logExceptionsOnly="false"'}
                    </SyntaxHighlighter>

                    Afisare detalii despre pod:
                    <SyntaxHighlighter>
                        {'kubectl describe  pod liferay-68fb57fc59-2bf9x -n=liferay'}
                    </SyntaxHighlighter>
                    Daca nu sunt resurse destule poate afisa:
                    <SyntaxHighlighter>
                        {'Events:\n' +
                            '  Type     Reason            Age                  From               Message\n' +
                            '  ----     ------            ----                 ----               -------\n' +
                            '  Warning  FailedScheduling  5m47s                default-scheduler  0/1 nodes are available: 1 pod has unbound immediate PersistentVolumeClaims. preemption: 0/1 nodes are available: 1 Preemption is not helpful for scheduling.      \n' +
                            '  Warning  FailedScheduling  27s (x2 over 5m45s)  default-scheduler  0/1 nodes are available: 1 Insufficient cpu. preemption: 0/1 nodes are available: 1 No preemption victims found for incoming pod.\n'}
                    </SyntaxHighlighter>

                    Cu pod-ul pornit, executam comanda urmatoare, pentru a afla numele podului:
                    <SyntaxHighlighter  language="python" style={androidstudio}>
                        {'kubectl get pods -l app=liferay -n=liferay'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                       READY   STATUS    RESTARTS   AGE\n' +
                            'liferay-68fb57fc59-2bf9x   0/1     Pending   0          23s'}
                    </SyntaxHighlighter>

                    Cu numele podului aflat, copiem fisierele de configurare:
                    <SyntaxHighlighter>
                        {'kubectl cp ./files liferay-6f9554fdf9-dglnd:/mnt/liferay -n=liferay'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca s-au copiat fisierele:
                    <SyntaxHighlighter>
                        {'kubectl exec -it liferay-6f9554fdf9-dglnd /bin/bash'}
                    </SyntaxHighlighter>

                    Apoi, se șterge podul existent pentru a porni configurația încărcată pe volum:
                    <SyntaxHighlighter>
                        {'kubectl delete pods liferay-6f9554fdf9-dglnd -n=liferay'}
                    </SyntaxHighlighter>
                    Această acțiune va redistribui automat un alt pod și, atunci când este activ, îl va elimina pe cel vechi.

                    <br/>
                    Verificare pod:
                    <SyntaxHighlighter>
                        {'kubectl get pods -l app=liferay -n=liferay'}
                    </SyntaxHighlighter>

                    Verificare log-uri:
                    <SyntaxHighlighter>
                        {'kubectl logs liferay-6f9554fdf9-nvmsj -n=liferay --tail 1000'}
                    </SyntaxHighlighter>

                    <hr/>
                    Scalare:
                    <SyntaxHighlighter>
                        {'kubectl scale deployment.v1.apps/liferay -n=liferay --replicas=2'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LiferayK8sContent;