import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ComponentDidCatchReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-componentDidCatch", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Metoda componentDidCatch()</b>
                    <br/>
                    <br/>

                    Metoda <b>componentDidCatch()</b> se apeleaza cand unul dintre <i>copii</i> arunca o exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'componentDidCatch(error, errorInfo){...}'}
                    </SyntaxHighlighter>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import React, {Component} from "react";\n' +
                            '\n' +
                            'class ErrorBoundary extends Component {\n' +
                            '\n' +
                            '    constructor(props) {\n' +
                            '        super(props);\n' +
                            '        this.state = {\n' +
                            '            hasError: false\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    componentDidCatch(error, errorInfo) {\n' +
                            '        this.state({hasError: true})\n' +
                            '    }\n' +
                            '\n' +
                            '    render() {\n' +
                            '        if (this.state.hasError) {\n' +
                            '            return <h1>Opps!</h1>\n' +
                            '        }\n' +
                            '        return this.props.children;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ComponentDidCatchReactContent;