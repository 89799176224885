import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class WaitContainerDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-container-wait", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker container wait</b>
                    <br/>
                    <br/>

                    Urmatoare comanda este folosita pentru a bloca containerul si a printa exit code cand este oprit:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container wait [container]'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container wait ubuntu'}
                    </SyntaxHighlighter>
                    Fereastra aceasta va astepta sa se termine ubuntu.

                    <br/>
                    <br/>
                    In alta fereastra:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container kill ubuntu'}
                    </SyntaxHighlighter>

                    In prima fereastra va apare, codul de eroare (exit code), adica 137.


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WaitContainerDockerContent;