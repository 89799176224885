import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CoreSessionNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-coresession", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    1. Pentru a obtine <b>CoreSession</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'try (CoreSession session = CoreInstance.openCoreSession(repositoryName)) {\n' +
                        '// do something with session, including session.save()\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Se foloseste try-with-resource, deci nu trebuie în mod explicit session.close().
                    Similar cu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'CoreSession session = CoreInstance.openCoreSession(repositoryName)\n' +
                        'try {\n' +
                        '// do something with session, including session.save()\n' +
                        '} finally {\n' +
                        '\tsession.close();\n' +
                        '}'}
                    </SyntaxHighlighter>

                    2. Dintr-o operatie Automation:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Context\n' +
                        'protected CoreSession session;'}
                    </SyntaxHighlighter>

                    3. Dintr-o resursa JAX-RS:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'CoreSession session = WebEngine.getActiveContext().getCoreSession();'}
                    </SyntaxHighlighter>

                    4. Pe baza unui DocumentModel:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'CoreSession session = doc.getCoreSession();'}
                    </SyntaxHighlighter>

                    5. Dintr-un bean Seam:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@In(create = true, required = false)\n' +
                        'protected transient CoreSession documentManager;\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Mai multe: <a href={"https://www.nuxeo.com/blog/ways-nuxeo-platform-coresession/"}>aici</a>!

                </div>


                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CoreSessionNuxeoContent;