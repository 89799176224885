import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class New74LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-new-74", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Ce este nou in Liferay 7.4?</b>
                    <br/>
                    <br/>

                    Versiunea de Liferay 7.4 vine cu noi funcționalități, dar și imbunătățește funcționalitățile existente pentru a ușura experienta utilizatorilor tehnici și non-tehnici în utilizarea portalului.

                    <br/>
                    <br/>
                    Optimizari:
                    <ul>
                        <li><b>Testarea A/B:</b> se poate rula Teste A/B <b>pe orice element din pagina</b>, folosind ID-ul elementului (inainte de versiunea 7.4 se putea testa doar elemente de tip buton sau link)
                            <br/>
                            (cu Testarea A/B, se poate stabili care este cea mai eficientă versiune de conținut pentru o pagina testând variații pe diferiți utilizatori. <br/>
                            Majoritatea vizitatorilor văd conținutul și designul actual al paginii, în timp ce un grup selectat vede o variantă alternativă. <br/>
                            Se poate testa apoi performanța paginii curente și a variantei pentru a determina ce versiune are o performanța mai buna pentru un anumit obiectiv,
                            cum ar fi rata de respingere sau rata de click)
                        </li>
                        <li>
                            in sectiunea <b>Content Audit Tool</b> (Instrumentul de audit) din Content Dashboard (Tabloul de bord) destinat continutului:

                            <ul>
                                <li>
                                    se poate face clic pe bare pentru a mări rezultatele;
                                </li>
                                <li>
                                    conținutul atribuit unei singure categorii de vocabular este reprezentat grafic;
                                </li>
                                <li>
                                    se pot selecta vocabularele de auditat;
                                </li>
                                <li>
                                    este posibil să fie intoarce axele diagramei cu bare cu un clic;
                                </li>
                            </ul>

                            Content Audit Tool este un instrument din zona grafică din partea de sus a paginii din Content Dashboard / Tabloul de bord destinat continutului. Graficul reprezintă numărul total de asset-uri pentru combinația de categorii din Vocabularele globale selectate;
                            <br/>
                            Un asset se referă la orice tip de conținut din portal:  poate fi text, un fișier, o adresă URL, o imagine, documente, intrări de blog, pagini wiki sau orice ce se creaza prin intermediul aplicatiilor personalizate.

                        </li>

                        <li>
                            analiza performanței conținutului pentru toate tipurile de pagini
                        </li>
                        <li>
                            instrumentul de performanță a conținutului (<b>Content Performance Tool</b>) cu canale de achiziție de trafic care folosesc date <b>Analytics Cloud</b> și includ măsurători pentru căutare organică și plătită, trafic social și recomandări
                        </li>
                        <li>
                            traducere automată pentru conținut web
                            (<a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/web-content/translating-web-content/automatically-translating-web-content.html"}>
                                Automatically Translating Web Content
                            </a>)
                        </li>
                        <li>
                            datele de expirare pentru documente
                            ( <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/documents-and-media/uploading-and-managing/using-file-expiration-and-review-dates.html"}>
                            Using File Expiration and Review Dates
                            </a>)
                        </li>
                        <li>
                            suport OAuth2 (îmbunătățit)
                        </li>
                        <li>
                            editor WYSIWYG (îmbunătățit)
                        </li>
                        <li>
                            suport pentru Elasticsearch 7.15.x
                        </li>
                        <li>
                            suport pentru factorul cheie hardware bazat pe FIDO2 în autentificarea cu mai mulți factori
                        </li>
                        <li>
                            gestionarea mai bună a datelor și întreținerea SAML
                        </li>
                        <li>
                            integrare cu cu o serie de platforme de chat (este necesar un abonament separat la furnizorul de chat):
                            <ul>
                                <li>Chatwoot</li>
                                <li>crocant</li>
                                <li>Hubspot</li>
                                <li>JivoChat</li>
                                <li>LiveChat</li>
                                <li>LivePerson</li>
                                <li>Smartsupp</li>
                                <li>Tawk.to</li>
                                <li>Tidio</li>
                                <li>Zendesk</li>
                            </ul>
                        </li>
                        <li>
                            Semnare digitala[1] (DocuSign): DocuSign este un serviciu care gestionează documentele care urmează să fie semnate electronic. Cu această integrare, se pot gestiona și colecta semnături pe documentele
                        </li>


                    </ul>
                    Functionalitati noi:
                    <ul>
                        <li>
                            pagina de audit (<b>Page Audit</b>) utilizează serviciul <b>Google PageSpeed Insights</b>; cu acest instrument se poate verifica conformitatea paginii cu cele mai bune practici de performanță, accesibilitate, metadate ale motoarelor de căutare și SEO.
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/getting-started/whats-new-74.html"}>
                                    What’s New in Liferay DXP 7.4
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/site-building/optimizing-sites/ab-testing/creating-ab-tests.html"}>
                                    Creating A/B Tests
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/content-dashboard/content-dashboard-interface.html"}>
                                    Content Dashboard Interface
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/web-content/translating-web-content/automatically-translating-web-content.html"}>
                                    Automatically Translating Web Content
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/documents-and-media/uploading-and-managing/using-file-expiration-and-review-dates.html"}>
                                    Using File Expiration and Review Dates
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/content-authoring-and-management/documents-and-media/uploading-and-managing/enabling-docusign-digital-signatures.html"}>
                                    Enabling DocuSign Digital Signatures
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default New74LiferayContent;