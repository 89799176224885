import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class AuthorizationK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-authorization", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Autorizare</b>

                    <hr/>
                    <b>1. Tipuri de autorizare</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>Node</li>
                        <li>ABAC (Attribute-Based Access Control) -> accesul se da: user->resursa</li>
                        <li>RBAC (Role Based access Controls) -> accesul se da: user->rol-> reusrsa</li>
                        <li>Webhook</li>
                    </ul>

                    La pornirea Kube API Server se poate specifica modul de autorizare:
                    <SyntaxHighlighter>
                        {'kube-apiserver --authorization-mode=Node,RBAC,Webhook'}
                    </SyntaxHighlighter>
                    Daca sunt mai multe moduri de autorizare le incerca in ordine pana cand da primul care il autorizeaza!

                    Implicit este:
                    <SyntaxHighlighter>
                        {'kube-apiserver --authorization-mode=AlwaysAllow'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kube-apiserver --authorization-mode=Alwayseny'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. RBAC (Role Based access Controls)</b>
                    <br/>
                    <br/>
                    Crearea unui rol se face cu ajutorul unui obiect de tip <b>Role</b> (fie fisierul pod-reader-role.yaml):
                    <SyntaxHighlighter>
                        {'apiVersion: rbac.authorization.k8s.io/v1\n' +
                            'kind: Role\n' +
                            'metadata:\n' +
                            '  namespace: default\n' +
                            '  name: pod-reader # nume rol\n' +
                            'rules:\n' +
                            '- apiGroups: [""] # "" indicates the core API group\n' +
                            '  resources: ["pods"]\n' +
                            '  verbs: ["get", "watch", "list"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl create -f pod-reader-role.yaml'}
                    </SyntaxHighlighter>

                    Legatura dintre user si rol se face prin intermediul unui obiect de tipul <b>RoleBinding</b> (fie fisierul pod-reader-binding.yaml ):
                    <SyntaxHighlighter>
                        {'apiVersion: rbac.authorization.k8s.io/v1\n' +
                            '# This role binding allows "jane" to read pods in the "default" namespace.\n' +
                            '# You need to already have a Role named "pod-reader" in that namespace.\n' +
                            'kind: RoleBinding\n' +
                            'metadata:\n' +
                            '  name: read-pods\n' +
                            '  namespace: default\n' +
                            'subjects:\n' +
                            '# You can specify more than one "subject"\n' +
                            '- kind: User\n' +
                            '  name: jane # "name" is case sensitive\n' +
                            '  apiGroup: rbac.authorization.k8s.io\n' +
                            'roleRef:\n' +
                            '  # "roleRef" specifies the binding to a Role / ClusterRole\n' +
                            '  kind: Role #this must be Role or ClusterRole\n' +
                            '  name: pod-reader # this must match the name of the Role or ClusterRole you wish to bind to\n' +
                            '  apiGroup: rbac.authorization.k8s.io'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl create -f pod-reader-binding.yaml'}
                    </SyntaxHighlighter>

                    Pentru a afisa rolurile:
                    <SyntaxHighlighter>
                        {'kubectl get roles'}
                    </SyntaxHighlighter>

                    Pentru a afisa legaturile dintre rolurile si useri :
                    <SyntaxHighlighter>
                        {'kubectl get rolebindings'}
                    </SyntaxHighlighter>

                    Pentru a afisa detalii despre un rol :
                    <SyntaxHighlighter>
                        {'kubectl describe role pod-reader '}
                    </SyntaxHighlighter>

                    Pentru a afisa detalii despre un rolebinding :
                    <SyntaxHighlighter>
                        {'kubectl describe rolebinding read-pods '}
                    </SyntaxHighlighter>

                    Pentru a verifica un utilizator are rol la o resursa:
                    <SyntaxHighlighter>
                        {'kubectl auth can-i create deployments'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'yes'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca administrator daca un user are drepturi la o resursa:
                    <SyntaxHighlighter>
                        {'kubectl auth can-i create deployments --as dev-user'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'no'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca administrator daca un user are drepturi la o resursa pe un anumit namespace:
                    <SyntaxHighlighter>
                        {'kubectl auth can-i create deployments --as dev-user --namespace test'}
                    </SyntaxHighlighter>

                    In definirea unui rol, se pot specifica si numele resurselor la care poate avea access:
                    <SyntaxHighlighter>
                        {'resourceNames: ["blue","orange"]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Cluster roles</b>
                    <br/>
                    <br/>

                    Exista resurse:
                    <ul>
                        <li>
                            care sunt namespaced;  pentru a afisa care sunt acestea:
                            <SyntaxHighlighter>
                                {'kubectl api-resources --namespaced=true'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            care nu sunt namespaced; pentru a afisa care sunt acestea (node, pv, etc):
                            <SyntaxHighlighter>
                                {'kubectl api-resources --namespaced=true'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Cluster roles sunt similare cu Roles, dar pentru resurse care nu au namespace.
                    <br/>
                    Se foloseste obiectul <b>ClusterRole</b>.
                    <SyntaxHighlighter>
                        {'apiVersion: rbac.authorization.k8s.io/v1\n' +
                            'kind: ClusterRole\n' +
                            'metadata:\n' +
                            '  # "namespace" omitted since ClusterRoles are not namespaced\n' +
                            '  name: secret-reader\n' +
                            'rules:\n' +
                            '- apiGroups: [""]\n' +
                            '  #\n' +
                            '  # at the HTTP level, the name of the resource for accessing Secret\n' +
                            '  # objects is "secrets"\n' +
                            '  resources: ["secrets"]\n' +
                            '  verbs: ["get", "watch", "list"]'}
                    </SyntaxHighlighter>

                    Pentru binding se foloseste obiectul <b>ClusterRoleBinding</b>:
                    <SyntaxHighlighter>
                        {'apiVersion: rbac.authorization.k8s.io/v1\n' +
                            '# This cluster role binding allows anyone in the "manager" group to read secrets in any namespace.\n' +
                            'kind: ClusterRoleBinding\n' +
                            'metadata:\n' +
                            '  name: read-secrets-global\n' +
                            'subjects:\n' +
                            '- kind: Group\n' +
                            '  name: manager # Name is case sensitive\n' +
                            '  apiGroup: rbac.authorization.k8s.io\n' +
                            'roleRef:\n' +
                            '  kind: ClusterRole\n' +
                            '  name: secret-reader\n' +
                            '  apiGroup: rbac.authorization.k8s.io'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>A. Exercitii (RBAC)</b>
                    <br/>
                    <br/>
                    Pentru a afla authorization mode pentru api server:
                    <SyntaxHighlighter>
                        {'cat /etc/kubernetes/manifests/kube-apiserver.yaml'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter>
                        {'ps -aux | grep authorization-mode'}
                    </SyntaxHighlighter>

                    Afisare toate rolurile din toate namespace-urile:
                    <SyntaxHighlighter>
                        {'kubectl get roles -A'}
                    </SyntaxHighlighter>
                    Contorizare:
                    <SyntaxHighlighter>
                        {'kubectl get roles -A | wc -l'}
                    </SyntaxHighlighter>

                    Descriere rol:
                    <SyntaxHighlighter>
                        {'kubectl describe role kube-proxy -n kube-system'}
                    </SyntaxHighlighter>

                    Pentru a afla cui este asignat un rol:
                    <br/>
                    1. Se afiseaza toate rolebindings pentru a afla numele:
                    <SyntaxHighlighter>
                        {'kubectl get rolebindings -n kube-system'}
                    </SyntaxHighlighter>
                    2. Se afiseaza detaliat rolebinding dupa nume:
                    <SyntaxHighlighter>
                        {'kubectl describe rolebindings kube-proxy -n kube-system'}
                    </SyntaxHighlighter>

                    Pentru a afisa utilizatorii:
                    <SyntaxHighlighter>
                        {'kubectl config view'}
                    </SyntaxHighlighter>

                    Pentru a verica ca un utilizator (dev-user) are acces la o resursa:
                    <SyntaxHighlighter>
                        {'k get pods --as dev-user'}
                    </SyntaxHighlighter>

                    Creare rol si binding:
                    <SyntaxHighlighter>
                        {'kind: Role\n' +
                            'apiVersion: rbac.authorization.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  namespace: default\n' +
                            '  name: developer\n' +
                            'rules:\n' +
                            '- apiGroups: [""]\n' +
                            '  resources: ["pods"]\n' +
                            '  verbs: ["list", "create","delete"]\n' +
                            '\n' +
                            '---\n' +
                            'kind: RoleBinding\n' +
                            'apiVersion: rbac.authorization.k8s.io/v1\n' +
                            'metadata:\n' +
                            '  name: dev-user-binding\n' +
                            'subjects:\n' +
                            '- kind: User\n' +
                            '  name: dev-user\n' +
                            '  apiGroup: rbac.authorization.k8s.io\n' +
                            'roleRef:\n' +
                            '  kind: Role\n' +
                            '  name: developer\n' +
                            '  apiGroup: rbac.authorization.k8s.io'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter>
                        {'kubectl create role --help'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl create role developer --verb=list,create,delete --resource=pods'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl create rolebinding dev-user-binding --role=developer --user=dev-user'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl --as dev-user get pod dark-blue-app -n blue'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl edit role developer -n blue'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>B. Exercitii (Cluster Roles)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'kubectl get clusterroles --no-headers | wc -l'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get clusterrolebindings --no-headers | wc -l'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get nodes --as michelle'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl create clusterrole michelle-role --verb=get,list,watch --resource=nodes'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl create clusterrolebinding michelle-role-binding --clusterrole=michelle-role --user=michelle' }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl api-resources'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl create clusterrole storage-admin --resource=persistentvolumes,storageclasses --verb=list,create,get,watch' }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl create clusterrolebinding michelle-storage-admin --clusterrole=storage-admin --user=michelle' }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get clusterrole storage-admin -o yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get storageclass --as michelle'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AuthorizationK8sContent;