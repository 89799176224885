import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class InstallNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                   <b>1. Instalare Nginx</b>

                    <br/>
                    <br/>

                    <b>1.1. Windows</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get update'}
                    </SyntaxHighlighter>
                    Nginx este proiectat sa functioneze in special pe Linux.
                    <br/>
                    Dar se poate downloada (<b>nginx.org/en/download.html</b>) si porni folosind <b>nginx.exe</b>.
                    <br/>
                    Pe Windows:
                    <ul>
                        <li>performante slabe</li>
                        <li>1 singur process worker</li>
                        <li>module nesuportate (ex: caching)</li>
                    </ul>

                    <b>1.2. Ubuntu (instalare cu Package Manager: apt-get)</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get install nginx'}
                    </SyntaxHighlighter>

                    O data instalat, nginx si porneste automat.
                    <br/>
                    Pentru a verifica ca e pornit:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>
                    Pentru a verifica ca functioneaza in browser:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ifconfig'}
                    </SyntaxHighlighter>
                    Dupa ce se obtine ip-ul se foloseste in browser: http://ip.
                    <br/>
                    Deci e pornit pe portul 80.

                    <br/>
                    <br/>
                    Explorare nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx/'}
                    </SyntaxHighlighter>

                    <b>1.3. Centos  (instalare cu Package Manager: yum)</b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum check-update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum install epel-release'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum install nginx '}
                    </SyntaxHighlighter>

                    Pe CentOS nginx nu porneste in mod automat. Pentru a-l porni:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'service nginx start'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca e pornit:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca functioneaza in browser:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ifconfig'}
                    </SyntaxHighlighter>
                    Dupa ce se obtine ip-ul se foloseste in browser: http://ip.
                    <br/>
                    Deci e pornit pe portul 80.

                    <br/>
                    <br/>
                    Explorare nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx/'}
                    </SyntaxHighlighter>

                    <b>1.4. Ubuntu 17.10 (instalare de la sursa si adaugare module)</b>
                    <br/>
                    <br/>
                    Avantajul cand se instaleaza de la sursa este ca se pot adauga si module (ceea ce nu se poate folosind un package manager).
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get update'}
                    </SyntaxHighlighter>

                    Download arhiva:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'wget http://nginx.org/download/nginx-1.23.1.tar.gz'}
                    </SyntaxHighlighter>

                    Afisare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l'}
                    </SyntaxHighlighter>

                    Dezarhivare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tar -zxvf nginx-1.23.1.tar.gz'}
                    </SyntaxHighlighter>

                    Afisare iar, pentru ca a verifica ca s-a dezarhivat:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd nginx-1.23.1'}
                    </SyntaxHighlighter>

                    Instalare dependinte (compilator de C) pentru compilare Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get install build-essential'}
                    </SyntaxHighlighter>

                    Pe CentOS:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum groupinstall "Developer Tools"'}
                    </SyntaxHighlighter>

                    Instalare alte dependinte:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get install libpcre3 libpcre3-dev zlib1g zlib1g-dev libssl-dev'}
                    </SyntaxHighlighter>

                    Pe CentOS:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum install pcre pre-devel zlib zlib-devel openssl openssl-devl'}
                    </SyntaxHighlighter>

                    Compilare Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./configure'}
                    </SyntaxHighlighter>

                    Help configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                      {'./configure --help'}
                    </SyntaxHighlighter>

                    Configurare parametri:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                       {'./configure --sbin-path=/usr/bin/nginx --conf-path=/etc/nginx/nginx.conf --error-log-path=/var/log/nginx/error.log --http-log-path=/var/log/access.log --with-pcre --pid-path=/var/run/nginx.pid --with-http_ssl_module'}
                    </SyntaxHighlighter>

                     Compilare configurari:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                       {'make'}
                    </SyntaxHighlighter>

                    Compilare configurari:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo make install'}
                    </SyntaxHighlighter>

                    Afisare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx/'}
                    </SyntaxHighlighter>

                    Afisare versiune Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -V'}
                    </SyntaxHighlighter>

                    Pornire Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nginx'}
                    </SyntaxHighlighter>

                    Verificare proces Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca functioneaza in browser:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ifconfig'}
                    </SyntaxHighlighter>
                    Dupa ce se obtine ip-ul se foloseste in browser: http://ip.
                    <br/>
                    Sa presupunem ca IP este http://192.168.1.9/, atunci in browser: <i>http://192.168.1.9/</i>
                    <br/>
                    Deci e pornit pe portul 80.


                    <hr/>
                    <b>2. Adaugare serviciu Nginx</b>
                    <br/>
                    <br/>

                    Se foloseste <b>systemd</b>, care e disponibil pe Ubuntu incepand cu versiunea 15.0.4 iar pe CentOS incepand cu versiunea 7.0.0.

                    <br/>
                    Pentru a opri nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nginx -s stop'}
                    </SyntaxHighlighter>

                    Verificare proces Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>

                    De pe site-ul <a target={"_blank"} href={"https://www.nginx.com/resources/wiki/start/topics/examples/initscripts/"}>NGINX Init Scripts</a> apoi de la sectiunea <b>Systemd</b> {"->"} <b>NGINX systemd service file</b> se creaza un fisier <b> /lib/systemd/system/nginx.service</b> cu continutul gasit pe site (cu mici modicari).

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo touch /lib/systemd/system/nginx.service'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nano /lib/systemd/system/nginx.service'}
                    </SyntaxHighlighter>
                    Continutul:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'[Unit]\n' +
                            'Description=The NGINX HTTP and reverse proxy server\n' +
                            'After=syslog.target network-online.target remote-fs.target nss-lookup.target\n' +
                            'Wants=network-online.target\n' +
                            '\n' +
                            '[Service]\n' +
                            'Type=forking\n' +
                            'PIDFile=/var/run/nginx.pid\n' +
                            'ExecStartPre=/usr/bin/nginx -t\n' +
                            'ExecStart=/usr/bin/nginx\n' +
                            'ExecReload=/usr/bin/nginx -s reload\n' +
                            'ExecStop=/bin/kill -s QUIT $MAINPID\n' +
                            'PrivateTmp=true\n' +
                            '\n' +
                            '[Install]\n' +
                            'WantedBy=multi-user.target'}
                    </SyntaxHighlighter>

                    Apoi se apasa: <b>CTRL + O</b> pentru a salva modificarile.
                    <br/>
                    Apoi se apasa: <b>CTRL + X</b> pentru a iesi din editor.

                    <br/>
                    <br/>
                    Pentru a porni Nginx folosind systemd:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl start nginx'}
                    </SyntaxHighlighter>

                    Verificare proces Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep nginx'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl status nginx'}
                    </SyntaxHighlighter>

                    Pentru a opri Nginx folosind systemd:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl stop nginx'}
                    </SyntaxHighlighter>

                    Pentru a porni Nginx automat la pornirea sistemului:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl enable nginx'}
                    </SyntaxHighlighter>

                    Repornire masina:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'reboot'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl status nginx'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallNginxContent;