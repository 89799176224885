import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GeneratorsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-generators", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Generatori / Iteratori </b>
                    <br/> <br/>

                    Un <b>generator</b> este un fragment de cod capabil:
                    <ul>
                        <li>să producă o serie de valori</li>
                        <li>să controleze procesul de iterație</li>
                    </ul>

                    Acesta este motivul pentru care generatorii sunt foarte des numiți <i>iteratori</i>.

                    <br/>

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'for i in range(10):\n' +
                        '    print(i)'}
                    </SyntaxHighlighter>

                    Funcția <b>range()</b> este un generator/iterator.
                    Fata de o functie, un generator returnează o serie de valori și, în general, este (implicit) invocat de mai multe ori.
                    <br/>
                    In exemplul de mai sus, generatorul este:
                    <ul>
                        <li>invocat de 10 ori</li>
                        <li>furnizeaza 10 valori de la 0 la 9</li>
                        <li>la final, semnalizeaza ca seria de 10 valori s-a terminat</li>
                    </ul>

                    Procesul de mai sus este complet transparent, si respecta protocolul iteratorului.

                    <hr/>

                    <b>1. Protocolul iterator</b>

                    <br/>
                    <br/>

                    <b>Protocolul iterator</b> este o modalitate prin care un obiect ar trebui să se comporte pentru a se conforma regulilor impuse de contextul declarațiilor <b>for</b> și <b>in</b>.
                    Un obiect conform protocolului iterator este numit <b>iterator</b>.

                    <br/>
                    Un iterator trebuie sa aiba două metode:
                    <ul>
                        <li><b>__iter__()</b>: returneze obiectul în sine și care este invocat o dată (necesar pentru a porni iteratia)</li>
                        <li><b>__next__()</b>: are scopul de a returna următoarea valoare (prima, secunda și așa mai departe) din seria dorită - va fi invocată de instrucțiunile for/in pentru a trece prin următoarea iterație;
                            <br/>
                            dacă nu mai sunt valori de furnizat, metoda ar trebui să arunce excepția <b>StopIteration</b>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Interval:\n' +
                        '    def __init__(self, start, end):\n' +
                        '        self.__start = start-1\n' +
                        '        self.__end = end\n' +
                        '      \n' +
                        '    def __iter__(self):\n' +
                        '        return self\n' +
                        '\n' +
                        '    def __next__(self):\n' +
                        '        self.__start +=1 \n' +
                        '        if self.__start>self.__end:\n' +
                        '            raise StopIteration()\n' +
                        '            \n' +
                        '        return self.__start\n' +
                        '\n' +
                        '\n' +
                        'for i in Interval(5,10):\n' +
                        '    print(i, end=",") # 5,6,7,8,9,10,'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Instructiunea yield (a produce / a da)</b>

                    <br/>
                    <br/>

                    Instructiunea se foloseste <b>doar</b> in interiorul unei functii, similar cu instructiunea <b>return</b>, dar:
                    <ul>
                        <li>
                            <b>nu se pierde starea funcției</b>, dupa ce se furnizează valoarea expresiei specificată după cuvântul cheie yield;
                            <br/>
                            toate valorile variabilelor sunt înghețate și se așteaptă următoarea invocare, când execuția este reluată (nu luate de la zero, ca după instructiunea <b>return</b>)
                        </li>
                        <li>
                            <b>transforma functia in generator</b>
                        </li>
                        <li>
                            <b>o astfel de funcție nu trebuie invocată în mod explicit</b>, deoarece - de fapt - <b>nu mai este o funcție, este un obiect generator</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def interval(n):\n' +
                                '    for i in range(n):\n' +
                                '        yield i\n' +
                                '     \n' +
                                'print(interval) # <function interval at 0x7f8c73215200>\n' +
                                'print(interval(8)) # <generator object interval at 0x7f8c732093d0>'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def interval(start, end):\n' +
                        '    for i in range(start,end+1):\n' +
                        '        yield i\n' +
                        '\n' +
                        '\n' +
                        'for i in interval(5,10):\n' +
                        '    print(i, end=",") # 5,6,7,8,9,10,'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def ridicare_la_putere(n,exponent):\n' +
                        '    p = 1\n' +
                        '    for i in range(n):\n' +
                        '        yield p\n' +
                        '        p *= exponent\n' +
                        '\n' +
                        '\n' +
                        'for i in ridicare_la_putere(8,2):\n' +
                        '    print(i)'}
                    </SyntaxHighlighter>

                    Generatori pot fi folositi in:
                    <ul>
                        <li><b>liste comprehesive</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def ridicare_la_putere(n,exponent):\n' +
                                '    p = 1\n' +
                                '    for i in range(n):\n' +
                                '        yield p\n' +
                                '        p *= exponent\n' +
                                '\n' +
                                '\n' +
                                't = [i for i in ridicare_la_putere(8,2)]\n' +
                                'print(t) # [1, 2, 4, 8, 16, 32, 64, 128]'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>functia list()</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def ridicare_la_putere(n,exponent):\n' +
                                '    p = 1\n' +
                                '    for i in range(n):\n' +
                                '        yield p\n' +
                                '        p *= exponent\n' +
                                '\n' +
                                '\n' +
                                't = list(ridicare_la_putere(8,2))\n' +
                                'print(t) # [1, 2, 4, 8, 16, 32, 64, 128]'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Se poate utiliza operatorul <b>in</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def ridicare_la_putere(n,exponent):\n' +
                        '    p = 1\n' +
                        '    for i in range(n):\n' +
                        '        yield p\n' +
                        '        p *= exponent\n' +
                        '\n' +
                        '\n' +
                        'for i in range(5):\n' +
                        '    if i in ridicare_la_putere(8,2):\n' +
                        '        print(i) # 1 2 4'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Expresia conditionata / operator conditionat</b>

                    <br/>
                    <br/>

                    Expresie condiționată sau operatorul conditionat (nu instructiune conditionata) este o modalitate de a selecta o expresie din doua expresii posibile pe baza rezultatului unei expresii booleene.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'expresie_1 if conditie else expresie_2'}
                    </SyntaxHighlighter>

                    Valoarea pe care o furnizează este egală cu:
                    <ul>
                        <li>expresie_1, daca condiție=True</li>
                        <li>expresie_2, in caz contrar</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def par(x):\n' +
                        '    return True if x%2==0 else False\n' +
                        '    \n' +
                        'print(par(2))# True\n' +
                        'print(par(3)) # False\n' +
                        'print(1 if 2!=3 else 4) # 1'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>4. Liste complehensive si generatori</b>

                    <br/>
                    <br/>

                    <b>Parantezele patrate fac liste complehensive, parantezele rotunde fac un generator</b>

                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [1 if x % 2 == 0 else 0 for x in range(10)] # se creeaza o lista cu valori, 10 valori\n' +
                        'generator = (1 if x % 2 == 0 else 0 for x in range(10)) # generatorul produce valori, una cate una\n' +
                        '\n' +
                        'for v in lista:\n' +
                        '    print(v, end=" ") # 1 0 1 0 1 0 1 0 1 0\n' +
                        'print()\n' +
                        '\n' +
                        'for v in generator:\n' +
                        '    print(v, end=" ") # 1 0 1 0 1 0 1 0 1 0\n' +
                        'print()\n' +
                        ' \n' +
                        'print(len(lista)) # 10\n' +
                        'print(len(generator)) # EROARE: TypeError: object of type \'generator\' has no len()'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratorsPythonContent;