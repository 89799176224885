import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class BlocksJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-blocks", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    Un bloc independent:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{}'}
                    </SyntaxHighlighter>
                    Blocurile de cod ({}) nu au mecanismul de returnare, deoarece ele <b>nu sunt considerate expresii</b>.
                    Ele sunt doar o secvență de instrucțiuni executabile.
                    <br/>
                    Dacă încerci să folosești <b>return</b> într-un bloc care nu este funcțional, vei obține o eroare de sintaxă.

                    <br/>
                    Observatii:
                    <ul>
                        <li>
                            nu pot avea un nume explicit
                        </li>
                        <li>
                            sunt folosite pentru gruparea instrucțiunilor
                        </li>
                        <li>
                            pentru a defini un domeniu de aplicare local (block scope) pentru variabilele declarate cu let și const (nu si var).
                        </li>
                    </ul>

                    Important:
                    <ul>
                        <li>
                            <b>var</b>:
                            <ul>
                                <li>
                                    Scop: function scope (domeniu de funcție)
                                </li>
                                <li>
                                    Variabilele declarate cu var sunt disponibile în întreaga funcție în care sunt declarate, indiferent dacă sunt definite în interiorul unui bloc if, for, sau alt bloc.
                                </li>
                                <li>
                                    Variabilele <b>var</b> sunt <b>hoisted</b>, ceea ce înseamnă că declarația lor este mutată la începutul funcției sau contextului global, dar nu și inițializarea lor

                                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'if (true) {\n' +
                                            '    var x = 10; // variabila este definită cu var\n' +
                                            '}\n' +
                                            'console.log(x); // Output: 10 - x este accesibilă în afara blocului'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    var nu respectă scopul de bloc și este limitat doar la funcție sau la nivel global.
                                </li>
                            </ul>


                        </li>
                        <li>
                            <b>let si const</b>:

                            <ul>
                                <li>Scop: block scope (domeniu de bloc)</li>
                                <li>
                                    Variabilele declarate cu let și const sunt disponibile doar în interiorul blocului de cod delimitat de {} în care au fost definite (de exemplu, în interiorul unui if, for, while)
                                </li>
                                <li>
                                    Variabilele <b>let</b> si <b>const</b>  sunt <b>hoisted</b> dar <b>nu</b> în același mod ca var, ceea ce înseamnă că nu pot fi accesate înainte de declarație.
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'console.log(y); // Error: Cannot access \'y\' before initialization\n' +
                                            'let y = 5;'}
                                    </SyntaxHighlighter>
                                    Cu let și const, hoisting-ul există, dar variabilele nu pot fi accesate înainte de linia lor de declarație.
                                    Aceasta se numește <b>Temporal Dead Zone (TDZ)</b>
                                </li>
                                <li>
                                    let și const introduse în ES6 respectă block scope, ceea ce înseamnă că variabilele declarate cu ele sunt accesibile doar în interiorul blocului de cod în care au fost definite.
                                </li>
                                <li>
                                    Utilizarea let și const este recomandată în JavaScript modern pentru a evita problemele legate de scoping și hoisting asociate cu var.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>

                    Deși blocurile de cod nu pot returna valori direct, poți simula acest comportament prin utilizarea funcțiilor sau a expresiilor funcționale, cum ar fi IIFE (Immediately-Invoked Function Expressions) sau arrow functions.

                    <br/>
                    Returnarea unei valori dintr-o funcție (inclusiv IIFE):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let result = (function() {\n' +
                            '    let x = 10;\n' +
                            '    let y = 20;\n' +
                            '    return x + y;\n' +
                            '})();\n' +
                            '\n' +
                            'console.log(result); // Output: 30'}
                    </SyntaxHighlighter>

                    sau (utilizarea unei funcții săgeată (arrow function)):

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let result = (() => {\n' +
                            '    let x = 10;\n' +
                            '    let y = 20;\n' +
                            '    return x + y;\n' +
                            '})();\n' +
                            '\n' +
                            'console.log(result); // Output: 30'}
                    </SyntaxHighlighter>

                    sau (atribuirea valorii la o variabilă):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let result;\n' +
                            '{\n' +
                            '    let x = 10;\n' +
                            '    let y = 20;\n' +
                            '    result = x + y; // Atribuim rezultatul unei variabile\n' +
                            '}\n' +
                            '\n' +
                            'console.log(result); // Output: 30'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BlocksJavaScriptContent;