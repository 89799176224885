import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AjaxModalLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-modal-ajax", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Vrem sa afisam intr-o fereastra modala (Bootstrap) informatii primite in urma unui apel Ajax.

                    <hr/>
                    Avem butonul:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<aui:button cssClass="btn-info" icon="icon-eye-open icon-1x text-white" \n' +
                        '\tdata-entityid="<%=entity.getEntityId()+""%>"  data-toggle="modal" data-target="#detailsModal" iconAlign="right" />'}
                    </SyntaxHighlighter>

                    Avem fereastra modala:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<div class="modal fade" id="detailsModal" tabindex="-1" role="dialog" aria-hidden="true" style="display:none;">\n' +
                        '    <div class="vertical-alignment-helper">\n' +
                        '        <div class="modal-dialog vertical-align-center"> <!-- modal-dialog -->\n' +
                        '            <div class="modal-content">\n' +
                        '                <div class="modal-header">\n' +
                        '                    <h4 class="modal-title">Detalii..</h4>\n' +
                        '                </div>\n' +
                        '                <div class="modal-body">\n' +
                        '                    <div class="form-group">\n' +
                        '                        <table class="table table-dark">\n' +
                        '                            <tbody>\n' +
                        '                                <tr>\n' +
                        '                                    <td>\n' +
                        '                                        <liferay-ui:message key="entity.attr1" />\n' +
                        '                                    </td>\n' +
                        '                                    <td class="attr1">-</td>\n' +
                        '                                </tr>\n' +
                        '                                <tr>\n' +
                        '                                    <td>\n' +
                        '                                        <liferay-ui:message key="entity.attr2" />\n' +
                        '                                    </td>\n' +
                        '                                    <td class="attr1">-</td>\n' +
                        '                                </tr>\n' +
                        '                            </tbody>\n' +
                        '                        </table>\n' +
                        '                    </div>\n' +
                        '                </div>\n' +
                        '                <div class="modal-footer">\n' +
                        '                    <button type="button" class="btn btn-white" data-dismiss="modal">Inchide</button>\n' +
                        '                </div>\n' +
                        '            </div>\n' +
                        '        </div>\n' +
                        '    </div>\n' +
                        '</div>'}
                    </SyntaxHighlighter>

                    Cand se apasa pe buton se afiseaza modala de mai sus; pentru a se afisa pe mijlocul paginii, mai trebuie adaugate urmatoarele randuri CSS:
                    <SyntaxHighlighter showLineNumbers={true} language="css" style={androidstudio}>
                        {'.vertical-alignment-helper {\n' +
                        '    height: 100%;\n' +
                        '    width: 100%;\n' +
                        '    pointer-events:none;\n' +
                        '    margin-top:100px;\n' +
                        '}\n' +
                        '\n' +
                        '.vertical-align-center {\n' +
                        '    /* To center vertically */\n' +
                        '    vertical-align: middle;\n' +
                        '    pointer-events:none;\n' +
                        '\n' +
                        '    margin: 0 auto;\n' +
                        '    width: 500px;\n' +
                        '    margin-top:100px;\n' +
                        '}\n' +
                        '\n' +
                        '.modal-content {\n' +
                        '    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */\n' +
                        '    width: inherit;\n' +
                        '    max-width: inherit; /* For Bootstrap 4 - to avoid the modal window stretching full width */\n' +
                        '    height: inherit;\n' +
                        '    /* To center horizontally */\n' +
                        '    margin: 0 auto;\n' +
                        '    pointer-events: all;\n' +
                        '}\n' +
                        '\n' +
                        '.modal-dialog{\n' +
                        '    position: inherit !important;\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Scriem pe server, in portlet, codul care returneaza informatiile dorite, pe baza unui id (<i>id_param</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                        'public void serveResource(ResourceRequest resourceRequest, ResourceResponse resourceResponse) throws IOException, PortletException {\n' +
                        '    \n' +
                        '    final String request = ParamUtil.getString(resourceRequest, "action_request");\n' +
                        '\n' +
                        '    if ("getEntityDetails".equals(request)){\n' +
                        '        Long id=  ParamUtil.getLong(resourceRequest, "id_param");\n' +
                        '        try {\n' +
                        '            MyEntity entity= MyEntityLocalServiceUtil.getMyEntity(id);\n' +
                        '            PortletResponseUtil.write(resourceResponse, entity.toString());\n' +
                        '        } catch (PortalException e) {\n' +
                        '            log.error(e,e); }\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Apelul AJAX:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<portlet:resourceURL var="baseResourceUrl" ></portlet:resourceURL>\n' +
                        '\n' +
                        '<aui:script>\n' +
                        '\n' +
                        '    $(\'#detailsModal\').on(\'shown.bs.modal\', function (e) {\n' +
                        '        let button = $(e.relatedTarget);\n' +
                        '        let entity_id= button.data(\'entityid\'); // butonul are atributul \'data-entityid\' \n' +
                        '\n' +
                        '        let url = \'${baseResourceUrl}\'+"&<portlet:namespace/>action_request=getEntityDetails&<portlet:namespace/>id_param="+entity_id;\n' +
                        '\n' +
                        '        let modal = $(this);\n' +
                        '\n' +
                        '        $.ajax({\n' +
                        '            url:url,\n' +
                        '            type:"POST",\n' +
                        '            dataType:"json",\n' +
                        '            data:{\n' +
                        '                action_request: \'getEntityDetails\',\n' +
                        '                id_param:entity_id\n' +
                        '            },\n' +
                        '            async:false,\n' +
                        '            cache:false,\n' +
                        '            success:function(data,textStatus, XMLHttpRequest){\n' +
                        '                modal.find("td.attr1").html(data.attr1);\n' +
                        '                modal.find("td.attr1").html(data.attr2);\n' +
                        '            },\n' +
                        '            error:function(data,textStatus, XMLHttpRequest){\n' +
                        '\n' +
                        '            }\n' +
                        '        });\n' +
                        '\n' +
                        '    })\n' +
                        '\n' +
                        '</aui:script>'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AjaxModalLiferayContent;