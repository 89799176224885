import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class MiscareCameraVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-move-camera", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Miscari ale camerei:
                    <ul>
                        <li><b>Zoom</b>
                        </li>
                        <li><b>Pan</b>
                        <br/>
                            <i>miscare a camerei orizontal</i>, de la stanga la dreapta sau de la drepta la stanga, ca o panoramare, <i>dintr-un punct fix</i>
                        </li>
                        <li><b>Tilt</b> (inclinare)
                            <br/>
                            <i>miscare a camerei verticala</i>, de sus in jos sau de jos in sus, <i>dintr-un punct fix</i>
                        </li>
                        <li><b>Dolly</b>
                            <br/>
                            deplasarea camerei inainte sau inapoi pe o pista sau vehicul care permite o miscare fluida
                        </li>
                        <li><b>Truck</b>:
                            <br/>
                            similar cu dolly, dar deplasarea camerei se face de la stanga la dreapta (lateral sau paralel cu actiunea)
                            </li>
                        <li><b>Pedestal</b>
                            <br/>
                            deplasarea camerei vertical (în sus sau în jos) în timp ce este fixată într-o singură locație
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://blog.storyblocks.com/video-tutorials/7-basic-camera-movements/"}>7 Basic Camera Movements</a><br/>
                           </div>
                       </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MiscareCameraVideografieContent;