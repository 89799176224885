import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerComposeResumeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-compose-resume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Docker Compose</b>
                    <br/>
                    <br/>

                    Instalare Docker Compose:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get install docker-compose-plugin'}
                    </SyntaxHighlighter>

                    Verificare Docker Compose:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Docker Compose version v2.14.1'}
                    </SyntaxHighlighter>

                    <hr/>

                    Un fisier Docker Compose, contine:
                    <ul>
                        <li>servicii (containere)</li>
                        <li>fiecare serviciu poate fi configurat:
                            <ul>
                                <li>porturi</li>
                                <li>variabile de mediu</li>
                                <li>volume</li>
                                <li>retele</li>
                            </ul>
                        </li>
                    </ul>

                    Se creeaza un fisier <b>docker-compose.yaml</b>.
                    Exemplu de configurare, pentru 3 servicii, fiecare serviciu fiind de fapt un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: "3.8"\n' +
                            'services: \n' +
                            '  mongodb:\n' +
                            '  backend:\n' +
                            '  frontend:'}
                    </SyntaxHighlighter>
                    Configurare servicii (continuare):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: "3.8"\n' +
                            'services: \n' +
                            '  mongodb:\n' +
                            '    image: \'mongo\'\n' +
                            '    container_name: mongodb\n' +
                            '    volumes:\n' +
                            '      - data:/data/db\n' +
                            '    environment:\n' +
                            '       MONGO_INITDB_ROOT_USERNAME: kj\n' +
                            '       MONGO_INITDB_ROOT_PASSWORD: secret\n' +
                            '       # - MONGO_INITDB_ROOT_USERNAME=max\n' +
                            '    # env_file:\n' +
                            '    #  - ./env/mongo.env\n' +
                            '    # network:\n' +
                            '    #  - goals-net' +
                            '  backend:\n' +
                            '    build: ./backend\n' +
                            '    # build:\n' +
                            '    #  context: ./backend\n' +
                            '    #  dockerfile: Dockerfile\n' +
                            '    ports:\n' +
                            '      - \'80:80\'\n' +
                            '    volumes:\n' +
                            '      - logs:/app/logs\n' +
                            '      - ./backend:/app\n' +
                            '      - /app/node_modules\n' +
                            '    depends_on:\n' +
                            '      - mongodb\n' +
                            '  frontend:\n' +
                            '    build: ./frontend\n' +
                            '    ports:\n' +
                            '      - \'3000:3000\'\n' +
                            '    volumes:\n' +
                            '      -./frontend/src:/app/src\n' +
                            '    # optiunea -it\n' +
                            '    stdin_open:true\n' +
                            '    tty: true\n' +
                            '    depends_on:\n' +
                            '      -backend\n' +
                            '  npm:\n' +
                            '    image: node:14\n' +
                            '    # se poate suprascrie WORKDIR din Dockerfile\n' +
                            '    working_dir: /var/www/html\n' +
                            '    # se poate suprascrie ENTRYPOINT din Dockerfile\n' +
                            '    entrypoint: ["npm"]\n' +
                            '\n' +
                            '# necesar pentru volume cu nume; volume anonime si bind mounts nu trebuie specificate\n' +
                            'volumes:\n' +
                            '  data:\n' +
                            '  logs:'}
                    </SyntaxHighlighter>
                    unde <i>/env/mongo.env</i> este un fisier cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'MONGO_INITDB_ROOT_USERNAME=kj\n' +
                            'MONGO_INITDB_ROOT_PASSWORD=secret'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>optiunea <b>--rm</b> (sterge container la oprirea acestuia) nu mai trebuie specificata, pentru ca se face implicit</li>
                        <li>optiunea <b>-d</b> (pornire container in mode detasat) nu mai trebuie specificata, pentru ca se poate specifica la pornirea serviciilor; in mod implicit porneste in mod atasat</li>
                        <li>optiunea <b>-net</b> nu mai trebuie specificata, pentru ca Docker Compose creaza in mod automat o retea in care vor fi puse toate serviciile definite in fisier

                            <br/> se va creea o retea cu numele: <i>nume_director_unde_se_afla_fisierul_docker_compose</i><b>_default</b> (ex: app_default)
                            <br/> se poate totusi specifica: <b>network:</b> cu lista de retele (ca in exemplu de mai sus, comandat);
                        </li>
                        <li>
                            este necesar specificare pentru <b>volume cu nume</b>; <b>volume anonime</b> si <b>bind mounts</b> nu trebuie specificate;
                            <br/>
                            se va creea un volum cu numele: <i>nume_director_unde_se_afla_fisierul_docker_compose</i><b>_</b><i>nume_volum_declarat</i> (ex: app_data)
                        </li>
                        <li>
                            pentru <b>bind mounts</b> in docker compose se poate specifica calea si relativ: ./backend:/app
                        </li>
                        <li>
                            numele de servicii (containere) pot fi folosite ca nume de domenii
                        </li>
                        <li>
                            optiunea <b>-it</b> se poate <i>traduce</i> in:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'stdin_open:true\n' +
                                    'tty: true'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            in lipsa <b>container_name</b> numele container va fi: <i>nume_director_unde_se_afla_fisierul_docker_compose</i>_<i>nume_serviciu</i>_<i>index</i> (ex: app_mongodb_1)
                        </li>
                    </ul>

                    Pornire servicii (pe baza de docker compose):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose up'}
                    </SyntaxHighlighter>
                    in mod implicit, pentru imaginile custom, daca exista local, nu se mai face build (chiar daca s-au modificat)
                    <br/>
                    <br/>

                    Pornire servicii (pe baza de docker compose) - doar numite servicii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose up [nume_serviciu_1] [nume_serviciu_2]'}
                    </SyntaxHighlighter>
                    vor pornii si serviciile de care depinde (din sectiunea <b>depends_on</b>)
                    <br/>
                    <br/>

                    Pornire servicii (pe baza de docker compose) in mod detasat, folosind optiunea <b>-d</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose up -d'}
                    </SyntaxHighlighter>

                    Pornire servicii (pe baza de docker compose), fortand rebuild-area imaginii, inainte de creeare continerului, folosind optiunea <b>--build</b>
                    (in mod implicit, pentru imaginile custom, daca exista local, nu se mai face build (chiar daca s-au modificat)):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose up --build'}
                    </SyntaxHighlighter>

                    Build imagini, fara pornirea containerelor:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose build'}
                    </SyntaxHighlighter>

                    Oprire servicii (pe baza de docker compose):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose down'}
                    </SyntaxHighlighter>
                    se vor sterge:
                    <ul>
                        <li>containere</li>
                        <li>retelele</li>
                        <li>dar, nu si volumele</li>
                    </ul>
                    Oprire servicii (pe baza de docker compose) cu stergere volume, se foloseste optiunea <b>-v</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose down -v'}
                    </SyntaxHighlighter>

                    <hr/>
                    Se poate rula si doar un singur serviciu dintr-un Docker Compose.
                    <br/>
                    De exemplu, fie fisierul <i>docker-compose.yaml</i> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: "3.8"\n' +
                            'services:\n' +
                            '  npm:\n' +
                            '    build: ./\n' +
                            '    stdin_open: true\n' +
                            '    tty: true\n' +
                            '    volumes:\n' +
                            '      - ./:/app'}
                    </SyntaxHighlighter>
                    si <i>Dockerfile</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM node\n' +
                            'WORKDIR /app\n' +
                            'ENTRYPOINT ["npm"]'}
                    </SyntaxHighlighter>

                    atunci se poate rula doar serviciul <i>npm</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose run npm init'}
                    </SyntaxHighlighter>
                    In mod implicit, containerul, desi e rulat prin intermediul docker compose, nu se va sterge dupa ce s-a rulat.
                    Pentru a face acest lucru posibil, este nevoie de optiunea <b>--rm</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose run --rm npm init'}
                    </SyntaxHighlighter>

                    <hr/>
                    Docker Compose nu inlocuieste <b>Dockerfiles</b> pentru imagini custom, ci chiar se foloseste de ele.
                    <br/>
                    Docker Compose poate gestion multiple containere pe <b>acelasi host</b>.

                    <hr/>
                    <b>Docker Compose CLI</b>
                    <br/>
                    <br/>
                    Trebuie sa fim intr-un director care contine un fisier Docker Compose!
                    <br/>
                    <br/>

                    Validare si afisare fisier docker compose:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose config'}
                    </SyntaxHighlighter>

                    Afisare servicii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose config --services'}
                    </SyntaxHighlighter>

                    Afisare imaginile pe baza carora se creeaza containerele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose images'}
                    </SyntaxHighlighter>

                    Loguri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose logs'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose logs --tail=10'}
                    </SyntaxHighlighter>

                    Afisare procese:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose ps'}
                    </SyntaxHighlighter>

                    Afisare procese din containere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-compose top'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerComposeResumeContent;