import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class RelationshipJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-relationship-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Gestionare relatiilor JDL</b>
                    <br/>
                    <br/>

                    Relatiile dintre entitati se pot defini folosind urmatoarela sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'relationship <type> {\n' +
                            '  <from-entity>[{<relationship-name>[(<display-field>)] <validation>*}] \n' +
                            '  to \n' +
                            '  <to-entity>[{<relationship-name>[(<display-field>)] <validation>*}]\n' +
                            '}'}
                    </SyntaxHighlighter>
                    unde <i>type</i> poate fi:
                    <ul>
                        <li>
                            <b>OneToMany</b>
                        </li>
                        <li>
                            <b>ManyToOne</b>
                        </li>
                        <li>
                            <b>OneToOne</b>
                        </li>
                        <li>
                            <b>ManyToMany</b>
                        </li>
                    </ul>
                    unde:
                    <ul>
                        <li>
                            <i>from-entity</i> si <i>to-entity</i> sunt nume de entitati
                        </li>
                    </ul>
                    unde:
                    <ul>
                        <i>relationship-name</i>: este optional si defineste numele relatiei
                    </ul>
                    unde:
                    <ul>
                        <li>
                            <i>display-field</i>: este numele care va fi afisat in meniul din UI
                        </li>
                    </ul>

                    Relațiile <b>OneToMany</b> și <b>ManyToMany</b> sunt bidirecționale în JHipster.
                    <br/>
                    Relatiile <b>ManyToOne</b> și <b>OneToOne</b> pot bidirecționale și unidirecționale.
                    <br/>
                    Pentru relațiile unidirecționale, nu se introduce  <i>relationship-name</i> pe destinație/la entitate.
                    <br/>
                    Relații multiple de același tip pot fi declarate în cadrul aceluiași bloc, separate prin virgulă.

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'entity Book\n' +
                            'entity Author\n' +
                            'entity Tag\n' +
                            '\n' +
                            'relationship OneToMany {\n' +
                            '  Author{book} to Book{writer(name) required},\n' +
                            '  Book{tag} to Tag\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemple</b>
                    <br/>
                    <br/>

                    exemplu 1:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'relationship OneToOne {\n' +
                            '    Customer{user} to User\n' +
                            '}'}
                    </SyntaxHighlighter>

                    relatie unidirectionala:
                    <SyntaxHighlighter>
                        {'Customer (1) -----> (1) User'}
                    </SyntaxHighlighter>
                    Un Customer este legat de 1 singur user.

                    <br/>
                    <br/>

                    exemplu 2:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'relationship ManyToOne {\n' +
                            '    OrderItem{product} to Product\n' +
                            '}'}
                    </SyntaxHighlighter>

                    relatie unidirectionala:
                    <SyntaxHighlighter>
                        {'OrderItem (*) -----> (1) Product'}
                    </SyntaxHighlighter>

                    exemplu 3:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'relationship OneToMany {\n' +
                            '   Customer{order} to ProductOrder{customer},\n' +
                            '   ProductOrder{orderItem} to OrderItem{order},\n' +
                            '   ProductOrder{invoice} to Invoice{order},\n' +
                            '   Invoice{shipment} to Shipment{invoice},\n' +
                            '   ProductCategory{product} to Product{productCategory}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    relatii bidirectionale:
                    <SyntaxHighlighter>
                        {'Customer (1) <-----> (*) ProductOrder\n' +
                            'ProductOrder (1) <-----> (*) OrderItem\n' +
                            'ProductOrder (1) <-----> (*) Invoice\n' +
                            'Invoice (1) <-----> (*) Shipment\n' +
                            'ProductCategory (1) <-----> (*) Product'}
                    </SyntaxHighlighter>

                    Explicatie:
                    <SyntaxHighlighter>
                        {'ProductCategory (1) <-----> (*) Product'}
                    </SyntaxHighlighter>
                    se poate traduce:
                    <ul>
                        <li>
                            Un produs (Product) poate apartie unei singure categori (ProductCategory).
                        </li>
                        <li>
                            O categorie (ProductCategory) poate avea mai multe produse
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RelationshipJdlJHipsterContent;