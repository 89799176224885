import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StatusGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-status", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git status</b>
                    <br/>
                    <br/>

                    Sa presupunem ca adaug un fisier (ex: <i>citeste.txt</i>) intr-un repository (directorul <i>git-vanilla</i>).
                    Daca executam comanda <b>git status</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git status'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'On branch master\n' +
                        '\n' +
                        'No commits yet\n' +
                        '\n' +
                        'Untracked files:\n' +
                        '  (use "git add <file>..." to include in what will be committed)\n' +
                        '        citeste.txt\n' +
                        '\n' +
                        'nothing added to commit but untracked files present (use "git add" to track)'}
                    </SyntaxHighlighter>


                    Comanda <b>git status</b> ofera informatii legate de:
                    <ul>
                        <li>fisire neurmarite (<i>untracked</i>; marcate cu rosu)</li>
                        <li>fisiere urmarite (<i>tracked</i>)</li>
                        <li>ce modificari sunt in fisierele urmarite</li>
                        <li>care este <i>branch</i>-ul curent</li>
                        <li>statusul <i>branch</i>-ului curent in raport cu un <i>remote</i></li>
                    </ul>

                    Intr-un repository Git, orice fisier poate fi:
                    <ul>
                        <li>neurmarit (untracked): fisierul este ignorat de Git (in comanda <i>git status</i> sunt marcate cu rosu)</li>
                        <li>urmarit (tracked): fisierul este monitorizat de Git, urmarindu-se modificarile</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StatusGitContent;