import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class CommandLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-command", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. php artisan make:command</b>
                    <br/>
                    <br/>
                    Pentru a genera o clasa de tip command, se da comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {' php artisan make:command UpdateCategoryCommand'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Console\\Commands;\n' +
                            '\n' +
                            'use Illuminate\\Console\\Command;\n' +
                            '\n' +
                            'class UpdateCategoryCommand extends Command\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * The name and signature of the console command.\n' +
                            '     *\n' +
                            '     * @var string\n' +
                            '     */\n' +
                            '    protected $signature = \'command:name\';\n' +
                            '\n' +
                            '    /**\n' +
                            '     * The console command description.\n' +
                            '     *\n' +
                            '     * @var string\n' +
                            '     */\n' +
                            '    protected $description = \'Command description\';\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Create a new command instance.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function __construct()\n' +
                            '    {\n' +
                            '        parent::__construct();\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Execute the console command.\n' +
                            '     *\n' +
                            '     * @return int\n' +
                            '     */\n' +
                            '    public function handle()\n' +
                            '    {\n' +
                            '        return 0;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Pentru a adauga o bara de progres la rularea comenzii:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Console\\Commands;\n' +
                            '\n' +
                            'use Illuminate\\Console\\Command;\n' +
                            '\n' +
                            'class UpdateCategoryCommand extends Command\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * The name and signature of the console command.\n' +
                            '     *\n' +
                            '     * @var string\n' +
                            '     */\n' +
                            '    protected $signature = \'update:category\';\n' +
                            '\n' +
                            '    /**\n' +
                            '     * The console command description.\n' +
                            '     *\n' +
                            '     * @var string\n' +
                            '     */\n' +
                            '    protected $description = \'Command description\';\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Create a new command instance.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function __construct()\n' +
                            '    {\n' +
                            '        parent::__construct();\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Execute the console command.\n' +
                            '     *\n' +
                            '     * @return int\n' +
                            '     */\n' +
                            '    public function handle()\n' +
                            '    {\n' +
                            '        $no = 100;\n' +
                            '        $bar = $this->output->createProgressBar($no);\n' +
                            '        $bar->start();\n' +
                            '\n' +
                            '        for($i=0;$i<$no;$i++){\n' +
                            '            // bucata de cod care dureaza\n' +
                            '            $bar->advance();\n' +
                            '        }\n' +
                            '\n' +
                            '        $bar->finish();\n' +
                            '\n' +
                            '        // comanda se va rula cu: php artisan update:category\n' +
                            '        return 0;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Pentru a rula comanda de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan update:category'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CommandLaravelContent;