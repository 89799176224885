import {useRoutes} from "react-router-dom";
import UtilHomeContent from "./IndexContent";
import React from "react";
import KillProcessContent from "./KillProcessContent";
import VirtualBoxContent from "./VirtualBoxContent";
import CurlContent from "./CurlContent";
import SystemCtlContent from "./SystemCtlContent";
import NanoContent from "./NanoContent";
import LinksContent from "./LinksContent";
import InstallJava11Ubuntu20Content from "./InstallJava11Ubuntu20Content";
import InstallPostgresql15Ubuntu20Content from "./InstallPostgresql15Ubuntu20Content";
import ServiceLiferay74Ubuntu20Content from "./ServiceLiferay74Ubuntu20Content";
import InstallLiferay74Ubuntu20Content from "./InstallLiferay74Ubuntu20Content";
import PrivateRoute from "../../security/PrivateRoute";
import SftpContent from "./SftpContent";
import InstallGrafana10Ubuntu20Content from "./InstallGrafana10Ubuntu20Content";
import BackupRestoreDbContent from "./BackupRestoreDbContent";
import CreateDatabaseDbContent from "./CreateDatabaseDbContent";
import CreateUserDbContent from "./CreateUserDbContent";
import BackArticleContent from "./BackArticleContent";
import InstallLiferay74CentosContent from "./InstallLiferay74CentosContent";
import ChromeContent from "./ChromeContent";
//
// <Route path="/util/index" element={<UtilHomeContent/>} exact/>
// <Route path="/util/windows/kill-process" element={<KillProcessContent/>} exact/>
// <Route path="/util/windows/virtualbox" element={<VirtualBoxContent/>} exact/>
// <Route path="/util/windows/curl" element={<CurlContent/>} exact/>
// <Route path="/util/linux/systemctl" element={<SystemCtlContent/>} exact/>
// <Route path="/util/linux/nano" element={<NanoContent/>} exact/>

export default function Router() {
    let element = useRoutes([
        {path: "/util/index", element: <UtilHomeContent/>},
        {path: "/util/windows/kill-process", element: <KillProcessContent/>},
        {path: "/util/windows/virtualbox", element: <VirtualBoxContent/>},
        {path: "/util/windows/curl", element: <CurlContent/>},
        {path: "/util/linux/systemctl", element: <SystemCtlContent/>},
        {path: "/util/linux/nano", element: <NanoContent/>},
        {path: "/util/linux/sftp", element: <SftpContent/>},

        {path: "/util/linux/install-java-11-ubuntu-20", element: <PrivateRoute element={<InstallJava11Ubuntu20Content/>}/>},
        {path: "/util/linux/install-postgresql-15-ubuntu-20", element: <PrivateRoute element={<InstallPostgresql15Ubuntu20Content/>}/>},
        {path: "/util/linux/service-liferay-74-ubuntu-20", element: <PrivateRoute element={<ServiceLiferay74Ubuntu20Content/>}/>},
        {path: "/util/linux/install-liferay-74-ubuntu-20", element:  <PrivateRoute element={<InstallLiferay74Ubuntu20Content/>}/>},
        {path: "/util/linux/install-liferay-74-centos", element: <PrivateRoute element={<InstallLiferay74CentosContent/>}/>},
        {path: "/util/linux/install-grafana-10-ubuntu-20", element:  <PrivateRoute element={<InstallGrafana10Ubuntu20Content/>}/>},

        {path: "/util/links", element: <LinksContent/>},

        {path: "/db/backup-restore", element:  <PrivateRoute element={<BackupRestoreDbContent/>}/>},
        {path: "/db/create-database", element:  <PrivateRoute element={<CreateDatabaseDbContent/>}/>},
        {path: "/db/create-user", element:  <PrivateRoute element={<CreateUserDbContent/>}/>},

        {path: "/article/back", element: <BackArticleContent/>},
        {path: "/util/chrome", element: <ChromeContent/>},

    ]);

    return element;
}