import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class DependencyManagementGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-dependency-management", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    <b>1. Definire repository</b>
                    <br/>
                    <br/>

                    Pentru a configura / definii locul unde se cauta artefactele si de unde se aduc local, Gradle se foloseste de blocul de script <b>repositories</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'repositories{\n' +
                        '\tmavenCentral()\n' +
                        '}'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'repositories{\n' +
                        '\tdelegete.mavenCentral()\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Intern, exista o metoda numita <b>repository</b> care executa actiunea/closure; iar actiunii i se trimte un parametru numit <b>delegate</b> de tipul <b>RepositoryHandler</b>.
                    <br/>
                    Pe un obiect de tipul <b>RepositoryHandler</b> se pot apela metodele: <b>mavenCentral()</b>, <b>jcenter()</b>, etc.
                    <hr/>

                    Alte repository-uri:
                    <ul>
                        <li><b>jcenter()</b> (nerecomandat)</li>
                        <li><b>google()</b></li>
                        <li><b>mavenLocal()</b> (repository-ul locale de maven, de exemplu C:\Users\kj\<b>.m2</b>)</li>
                    </ul>

                    Se poate folosi un repository:
                    <ul>
                        <li>maven</li>
                        <li>ivy</li>
                        <li>file repository</li>
                    </ul>

                    Artefactele (dependintele si dependintele tranzitive) sunt descarcate dintr-un repository si stocate local.
                    <br/>
                    De exemplu, in locatia: C:\Users\kj\<b>.gradle\caches</b>.

                    <br/>
                    <br/>

                    Daca avem urmatoarea configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'repositories{\n' +
                        '\tmavenCentral()\n' +
                        '\tjcenter()\n' +
                        '\tmavenLocal()\n' +
                        '}'}
                    </SyntaxHighlighter>
                    artefactul se va cauta in mavenCentral(), si daca nu este gasit aici, se cauta in jcenter(), si daca nu e gasit nici aici se cauta si in mavenLocal().

                    <hr/>
                    Pentru a defini un repository custom remote pe baza de autentificare:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'repositories{\n' +
                        '\tmaven {\n' +
                        '\t\turl: "http://repo.custom.url"\n' +
                        '\t\t\n' +
                        '\t\tcredentials {\n' +
                        '\t\t\tusername \'nume\'\n' +
                        '\t\t\tpassword \'parola\'\n' +
                        '\t\t}\n' +
                        '\t\t\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a defini un repository custom local:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'repositories{\n' +
                        '\tflatDir {\n' +
                        '\t\tdirs \'libs\'\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Adaugare dependinte</b>
                    <br/>
                    <br/>

                    Dependintele se adauga in sectiunea <b>dependencies</b>.
                    <br/>
                    <br/>
                    Sintaxa:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies{\n' +
                        '\tscop \'groupId:artefactId:version\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Intern, exista o metoda numita <b>dependencies</b> care executa actiunea/closure; iar actiunii i se trimte un parametru numit <b>delegate</b> de tipul <b>DependencyHandler</b>.

                    <hr/>

                    <br/>
                    Fiecare dependinta are urmatorul format: <i>scop grup:artefact:versiune</i>
                    <ul>
                        <li>scop:
                            <ul>
                                <li><b>api</b>: este nevoie de dependinta in <i>procesul de compilare (compile)</i> si <i>procesul de executie(run)</i> a surselor <i>api</i>
                                    <br/>
                                    (similar cu <b>compiler</b> care a devenit deprecated in ultimele versiune de Gradle)
                                </li>
                                <li><b>compileOnlyApi</b>: este nevoie de dependinta in <i>procesul de compilare (compile)</i> a surselor <i>api</i></li>

                                <li><b>implementation</b>: este nevoie de dependinta in <i>procesul de compilare (compile)</i> si <i>procesul de executie(run)</i> a surselor (src/main/java), dar nu <i>api</i></li>
                                <li><b>compileOnly</b>: este nevoie de dependinta in <i>procesul de executie</i> (de exemplu: Lombok, Servlet API); aceasta inseamna, de exemplu, daca se creeaza un WAR, dependinta nu va fi adaugata in /WEB-INF/lib</li>
                                <li><b>runtimeOnly</b>: este nevoie de dependinta in <i>procesul de compilare</i></li>

                                <li><b>testImplementation</b>: este nevoie de dependinta in <i>procesul de compilare (compile)</i> si <i>procesul de executie a testelor (run)</i> a testelor (src/test/java) (de exemplu: JUnit)</li>
                                <li><b>testCompileOnly</b>: este nevoie de dependinta in <i>procesul de compilare a testelor</i> (si nu in procesul de executie a testelor) (de exemplu: JUnit)</li>
                                <li><b>testRuntimeOnly</b> este nevoie de dependinta in <i>procesul de executie a testelor</i> (si nu in procesul de compilare a testelor) (de exemplu: Jupiter)</li>
                            </ul>
                        </li>
                        <li>
                            grup
                        </li>
                        <li>
                            artefact
                        </li>
                        <li>
                            versiune
                        </li>
                    </ul>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies{\n' +
                        '\timplementation \'commons-cli:commons-cli:1.4\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies{\n' +
                        '\ttestImplementation \'org.junit.jupiter:junit-jupiter-api:5.7.0\'\n' +
                        '\ttestRuntime \'org.junit.jupiter:junit-jupiter-engine:5.7.0\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Tipuri de dependinte:
                    <ul>
                        <li>externe:

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'dependencies{\n' +
                                '\ttestImplementation \'org.junit.jupiter:junit-jupiter-api:5.7.0\'\n' +
                                '\ttestRuntime \'org.junit.jupiter:junit-jupiter-engine:5.7.0\'\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                        <li>fisier: cand o librarie/jar (a.jar; b.jar) este intr-o anumita locatie (de exemplu: libs)

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'dependencies {\n' +
                                '    runtimeOnly files(\'libs/a.jar\', \'libs/b.jar\')\n' +
                                '    runtimeOnly fileTree(\'libs\') { include \'*.jar\' }\n' +
                                '}'}
                            </SyntaxHighlighter>
                            <b>fileTree</b> - se foloseste pentru a include fisiere dupa un anumit pattern;
                            <br/>
                            <b>files</b> - se foloseste pentru a include individual fisiere

                        </li>
                        <li>proiect: cand un modul din proiect depinde de alt modul din proiect

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'dependencies {\n' +
                                '    implementation project(: \':service\')\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>Diferenta intre implementation si api</b>
                    <br/>
                    <br/>
                    Sa presupunem ca avem, modul principal M. Aceste are 4 module:
                    <ul>
                        <li>A</li>
                        <li>B</li>
                        <li>C</li>
                        <li>D</li>
                    </ul>
                    <pre>
                    M <br/>
                        {"->"} A {"->"} B<br/>
                        {"->"} C {"->"} D
                    </pre>
                    <br/>

                    Si A depinde de B, build.gradle pentru modul A:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '          api project(path: \':B\')\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Si C depinde de D, build.gradle pentru modul C:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '          api project(path: \':D\')\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Folosind <b>api</b>, modul princiapl M, va avea acces la modulele B si D.
                    <br/>
                    Mai mult decat atat, modulul D este compilat prin api. Daca se modifica ceva in modulul D, Gradle va trebui sa recompileze modulul D, modulul C si toate modulele care importa modulul C (adica si M);

                    <br/>
                    <br/>
                    Folosind <b>implementation</b>, modulul principal M, nu va avea acces la modulele B si D.
                    Mai mult decat atata, modulul D este compilat prin implementation. Daca se modifica ceva in modulul D, Gradle va trebuie sa recompileze modulul D, modulul C si toate modulele care depind <b>direct</b> de modulul D (deci M nu se va recompila)
                    <br/>
                    <br/>
                    Deci se va folosi <b>api</b> cand e nevoie de tranzivitate si <b>implementation</b> cand nu este nevoie de tranzitivitate.

                    <hr/>
                    <b>3. Afisare dependinte</b>
                    <br/>
                    <br/>

                    Pentru afisarea arborelui de dependinte se utilizeaza task-ul <b>dependencies</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle dependencies -b build-java.gradle\n' +
                        '\n' +
                        '> Task :dependencies\n' +
                        '\n' +
                        '------------------------------------------------------------\n' +
                        'Root project\n' +
                        '------------------------------------------------------------\n' +
                        '\n' +
                        'annotationProcessor - Annotation processors and their dependencies for source set \'main\'.\n' +
                        'No dependencies\n' +
                        '\n' +
                        'apiElements - API elements for main. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'archives - Configuration for archive artifacts. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'compileClasspath - Compile classpath for source set \'main\'.\n' +
                        '\\--- commons-cli:commons-cli:1.4\n' +
                        '\n' +
                        'compileOnly - Compile only dependencies for source set \'main\'. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'default - Configuration for default artifacts. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'implementation - Implementation only dependencies for source set \'main\'. (n)\n' +
                        '\\--- commons-cli:commons-cli:1.4 (n)\n' +
                        '\n' +
                        'runtimeClasspath - Runtime classpath of source set \'main\'.\n' +
                        '\\--- commons-cli:commons-cli:1.4\n' +
                        '\n' +
                        'runtimeElements - Elements of runtime for main. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'runtimeOnly - Runtime only dependencies for source set \'main\'. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'testAnnotationProcessor - Annotation processors and their dependencies for source set \'test\'.\n' +
                        'No dependencies\n' +
                        '\n' +
                        'testCompileClasspath - Compile classpath for source set \'test\'.\n' +
                        '\\--- commons-cli:commons-cli:1.4\n' +
                        '\n' +
                        'testCompileOnly - Compile only dependencies for source set \'test\'. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'testImplementation - Implementation only dependencies for source set \'test\'. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        'testRuntimeClasspath - Runtime classpath of source set \'test\'.\n' +
                        '\\--- commons-cli:commons-cli:1.4\n' +
                        '\n' +
                        'testRuntimeOnly - Runtime only dependencies for source set \'test\'. (n)\n' +
                        'No dependencies\n' +
                        '\n' +
                        '(n) - Not resolved (configuration is not meant to be resolved)\n' +
                        '\n' +
                        'A web-based, searchable dependency report is available by adding the --scan option.\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '1 actionable task: 1 executed'}
                    </SyntaxHighlighter>

                    <hr/>

                    Daca apar conflicte intre dependente (o modula X depinde de libraria A versiunea 1; modulul Y depinde tot de libraria A, dar versiunea 2), atunci se poate stabili o stategie de a gestiona aceste cazuri, folosind <b>resolutionStrategy</b>:
                    <ul>
                        <li><b>force</b>: pentru a forta utlizarea unei anumite versiuni de librarie</li>
                        <li><b>failOnVersionConflict()</b>: pentru a aruca exceptie in caz de conflict</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'configuration.all{\n' +
                        '\n' +
                        '\tresolutionStrategy{\n' +
                        '\t\tforce \'common-loggin:commons-logging:1:1:2\'\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'configuration.all{\n' +
                        '\n' +
                        '\tresolutionStrategy{\n' +
                        '\t\tfailOnVersionConflict()\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a exclude o dependinta tranzitiva pentru o dependinta:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies{\n' +
                        '\timplementation(\'org.springframework:spring-webmvc:4.0.2\'){\n' +
                        '\t\texcluse group: \'commons-logging\', modules: \'commons-logging\'\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Simulare profile din Maven</b>
                    <br/>
                    <br/>

                    Pentru fiecare profil se poate crea un fisier de build:
                    <ul>
                        <li>build-profile1.gradle</li>
                        <li>build-profile2.gradle</li>
                        <li>build-profile3.gradle</li>
                    </ul>
                    Din linie de comanda se poate da un parametru care poate reprezenta unul dintre profile (profile1, profile2, profile3):
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle -PbuildProfile=profile1'}
                    </SyntaxHighlighter>

                    In plus, vom avea si fisierul de build <b>build.gradle</b> (acesta fiind de fapt fisierul apelat):

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'if (!hasProperty(\'buildProfile\')) ext.buildProfile = \'profile3\'\n' +
                        '\n' +
                        'apply from: "build-${buildProfile}.gradle"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Raport dependinte</b>
                    <br/>
                    <br/>

                    Pentru a avea un raport privind dependintele se poate utiliza plugin-ul <b>project-report</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins {\n' +
                        '    id \'project-report\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Acest plugin vine cu task-urile:

                    <ul>
                        <li><b>htmlDependencyReport</b>: acest task va genera in <b>\build\reports\project\dependecies</b> un site cu informatii prinvind dependintele proiectului;
                        </li>
                        <li>
                            <b>dependencyReport</b>: acest task va genera in <b>\build\reports\</b> fisierul text <b>dependencies.txt</b> cu informatii prinvind dependintele proiectului;
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a href={"https://medium.com/mindorks/implementation-vs-api-in-gradle-3-0-494c817a6fa"}>Implementation Vs Api in Android Gradle plugin 3.0</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DependencyManagementGradleContent;