import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class HttpsNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-https", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. HTTPS (SSL)</b>

                    <br/>
                    <br/>

                    <b>1.1. Redirectare trafic pe HTTPS</b>

                    <br/>
                    <br/>

                    Daca vrem sa functioneze site-ul si pe portul 80, cel mai ok este sa redirectam tot traficul pe HTTPS:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' # Redirectare tot trafic pe HTTPS\n' +
                            'server {\n' +
                            '\tlisten 80;\n' +
                            '\tserver_name 167.99.93.26;\n' +
                            '\treturn 301 https://$host$request_uri;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <b>1.2. Dezactivare SSL. TLS</b>

                    <br/>
                    <br/>

                    Pentru a imbunatati secutiatea vom dezactiva <b>SSL (Secure Socket Layer)</b>.
                     <br/>
                    Protocolul SSL a fost inlocuit de <b>TLS (Transport Layer Security</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'# Dezactivare SSL\n' +
                            'ssl_protocols TLSv1 TLSv1.1 TLSv1.2;'}
                    </SyntaxHighlighter>

                    <b>1.3. Optimizare cifrarea</b>

                    <br/>
                    <br/>

                    Cifrarea este folosita de TLS:
                    <SyntaxHighlighter howLineNumbers={true} language="cmd" style={androidstudio}>
                        {' # Optimizare cipher suits\n' +
                            'ssl_prefer_server_ciphers on;\n' +
                            'ssl_ciphers ECDH+AESGCM:ECDH+AES256:ECDH+AES128:DH+3DES:!ADH:!AECDH:!MD5;'}
                    </SyntaxHighlighter>


                    <b>1.3. Activate parametrii DH</b>

                    <br/>
                    <br/>

                    Pentru mai multe informatii legate de DH:
                    <ul>
                        <li>
                            <a target={"_blank"} href={"https://hackernoon.com/algorithms-explained-diffie-hellman-1034210d5100"}>Algorithms Explained: Diffie-Hellman</a>
                            <a target={"_blank"} href={"https://en.wikipedia.org/wiki/Diffie%E2%80%93Hellman_key_exchange"}>Diffie–Hellman key exchange</a>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' # Activare parametrii DH\n' +
                            'ssl_dhparam /etc/nginx/ssl/dhparam.pem;'}
                    </SyntaxHighlighter>

                    Pentru a genera parametrii <i>/etc/nginx/ssl/dhparam.pem</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'openssl dhparam 2048 -out /etc/nginx/ssl/dhparam.pem'}
                    </SyntaxHighlighter>
                    <b>Observatie</b>:
                    <br/>
                    Dimensiunea data (2048) trebuie sa coincida cu dimesiunea data la RSA:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'openssl req -x509 -days 10 -nodes -newkey rsa:2048 -keyout /etc/nginx/ssl/self.key -out /etc/nginx/ssl/self.crt'}
                    </SyntaxHighlighter>

                    <b>1.4. Activare HSTS</b>

                    <br/>
                    <br/>

                    Antetul de răspuns HTTP <b>Strict-Transport-Security</b> (adesea prescurtat ca HSTS ) informează browserele că site-ul ar trebui să fie accesat numai folosind HTTPS și că orice încercări viitoare de a-l accesa folosind HTTP ar trebui convertite automat în HTTPS.
                    <br/>
                    <br/>
                    <b>Notă</b>: Acest lucru este mai sigur decât simpla configurare a unei redirecționări HTTP către HTTPS (301) pe serverul dvs., unde conexiunea HTTP inițială este încă vulnerabilă la un atac de tip man-in-the-middle.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'# Activare HSTS\n' +
                            'add_header Strict-Transport-Security "max-age=31536000" always;'}
                    </SyntaxHighlighter>

                    <b>1.4. Sesiuni SSL</b>

                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'# Sesiuni SSL\n' +
                            'ssl_session_cache shared:SSL:40m;\n' +
                            'ssl_session_timeout 4h;\n' +
                            'ssl_session_tickets on;'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li><b>ssl_session_cache shared:SSL:40m</b>:
                            shared - cache in memorie, fiind accesat de orice procces; SSL - numele cache-ului; 40m - dimesiunea cache-ului
                        </li>
                        <li>
                            <b>ssl_session_timeout 4h;</b>:
                            timeout sesiune ssl
                        </li>
                        <li>
                            <b>ssl_session_tickets on</b>:
                            pentru a utiliza cache-ul de sesiune fără ca serverul să acceseze memoria cache, putem activa biletele (ticket) de sesiune SSL.
                            <br/>
                            Aceasta înseamnă să oferiți browserului un bilet care validează sesiunea SSL.
                            <br/>
                            Acest bilet este emis de server, deci este de încredere și ne permite să ocolim citirea din memoria cache a stației.
                            <br/>
                            Cu toate acestea, nu înseamnă că putem dezactiva memoria cache, deoarece nu putem fi siguri că clientul deține un bilet,
                            dar optimizează resursele serverului în reducerea căutărilor de sesiuni SSL.
                        </li>
                    </ul>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'user www-data;\n' +
                            '\n' +
                            'worker_processes auto;\n' +
                            '\n' +
                            'events {\n' +
                            '  worker_connections 1024;\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  # Redirectare tot trafic pe HTTPS\n' +
                            '  server {\n' +
                            '    listen 80;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '    return 301 https://$host$request_uri;\n' +
                            '  }\n' +
                            '\n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 443 ssl http2;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '\n' +
                            '    root /sites/demo;\n' +
                            '\n' +
                            '    index index.html;\n' +
                            '\n' +
                            '    ssl_certificate /etc/nginx/ssl/self.crt;\n' +
                            '    ssl_certificate_key /etc/nginx/ssl/self.key;\n' +
                            '\n' +
                            '    # Dezactivare SSL\n' +
                            '    ssl_protocols TLSv1 TLSv1.1 TLSv1.2;\n' +
                            '\n' +
                            '    # Optimizare cipher suits\n' +
                            '    ssl_prefer_server_ciphers on;\n' +
                            '    ssl_ciphers ECDH+AESGCM:ECDH+AES256:ECDH+AES128:DH+3DES:!ADH:!AECDH:!MD5;\n' +
                            '\n' +
                            '    # Activare parametri DH\n' +
                            '    ssl_dhparam /etc/nginx/ssl/dhparam.pem;\n' +
                            '\n' +
                            '    # Activare HSTS\n' +
                            '    add_header Strict-Transport-Security "max-age=31536000" always;\n' +
                            '\n' +
                            '    # Sesiuni SSL\n' +
                            '    ssl_session_cache shared:SSL:40m;\n' +
                            '    ssl_session_timeout 4h;\n' +
                            '    ssl_session_tickets on;\n' +
                            '\n' +
                            '    location / {\n' +
                            '      try_files $uri $uri/ =404;\n' +
                            '    }\n' +
                            '\n' +
                            '    location ~\\.php$ {\n' +
                            '      # Pass php requests to the php-fpm service (fastcgi)\n' +
                            '      include fastcgi.conf;\n' +
                            '      fastcgi_pass unix:/run/php/php7.1-fpm.sock;\n' +
                            '    }\n' +
                            '\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HttpsNginxContent;