import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class VersionDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "version-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Comanda docker version</b>


                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker version'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'Client: Docker Engine - Community\n' +
                            ' Version:           20.10.18\n' +
                            ' API version:       1.41\n' +
                            ' Go version:        go1.18.6\n' +
                            ' Git commit:        b40c2f6\n' +
                            ' Built:             Thu Sep  8 23:11:43 2022\n' +
                            ' OS/Arch:           linux/amd64\n' +
                            ' Context:           default\n' +
                            ' Experimental:      true\n' +
                            '\n' +
                            'Server: Docker Engine - Community\n' +
                            ' Engine:\n' +
                            '  Version:          20.10.18\n' +
                            '  API version:      1.41 (minimum version 1.12)\n' +
                            '  Go version:       go1.18.6\n' +
                            '  Git commit:       e42327a\n' +
                            '  Built:            Thu Sep  8 23:09:30 2022\n' +
                            '  OS/Arch:          linux/amd64\n' +
                            '  Experimental:     false\n' +
                            ' containerd:\n' +
                            '  Version:          1.6.8\n' +
                            '  GitCommit:        9cd3357b7fd7218e4aec3eae239db1f68a5a6ec6\n' +
                            ' runc:\n' +
                            '  Version:          1.1.4\n' +
                            '  GitCommit:        v1.1.4-0-g5fd4c4d\n' +
                            ' docker-init:\n' +
                            '  Version:          0.19.0\n' +
                            '  GitCommit:        de40ad0'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VersionDockerContent;