import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ElasticsearchK8sContent extends BaseContentPage {

    constructor(props) {
        super(props, "kubernetes-elasticsearch", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}


                <div className={"text-justify important"}>

                    <b>ElasticSearch</b>
                    <br/>
                    <br/>

                    <b>0. Mediu</b>
                    <br/>
                    <br/>

                    Ne conectam pe masina:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'ssh kj@192.168.1.10 -p 22'}
                    </SyntaxHighlighter>

                    Se creaza directorul <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'mkdir liferay'}
                    </SyntaxHighlighter>

                    Navigam in directorul nou creat <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'cd liferay'}
                    </SyntaxHighlighter>

                    Verificare minikube:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Stopped\n' +
                            'kubelet: Stopped\n' +
                            'apiserver: Stopped\n' +
                            'kubeconfig: Stopped\n'}
                    </SyntaxHighlighter>
                    Daca este oprit, se poate porni:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>

                    Verificare din nou status:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Running\n' +
                            'kubelet: Running\n' +
                            'apiserver: Running\n' +
                            'kubeconfig: Configured\n'}
                    </SyntaxHighlighter>

                    Afisare detalii:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube profile list'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| Profile  | VM Driver | Runtime |      IP      | Port | Version | Status  | Nodes | Active |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| minikube | docker    | docker  | 192.168.49.2 | 8443 | v1.25.2 | Running |     1 | *      |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Deployment</b>
                    <br/>
                    <br/>

                    Se creaza fisierul <i>elasticsearch-deployment.yml</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'nano elasticsearch-deployment.yml'}
                    </SyntaxHighlighter>
                    cu urmatorul continut:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: search\n' +
                            'spec:\n' +
                            '  ports:\n' +
                            '    - port: 9300\n' +
                            '  selector:\n' +
                            '    app: search\n' +
                            '\n' +
                            '---\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: search-claim\n' +
                            '  name: search-claim\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 100Mi\n' +
                            '\n' +
                            '---\n' +
                            '\n' +
                            'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  labels:\n' +
                            '    app: search\n' +
                            '  name: search\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: search\n' +
                            '  strategy:\n' +
                            '    type: Recreate\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: search\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - env:\n' +
                            '            - name: ES_JAVA_OPTS\n' +
                            '              value: -Xms1g -Xmx1g\n' +
                            '            - name: LCP_PROJECT_ENVIRONMENT\n' +
                            '              value: local\n' +
                            '            - name: LCP_SERVICE_ID\n' +
                            '              value: search\n' +
                            '            - name: LCP_SERVICE_SCALE\n' +
                            '              value: "1"\n' +
                            '            - name: cluster.routing.allocation.disk.threshold_enabled\n' +
                            '              value: "false"\n' +
                            '            - name: discovery.type\n' +
                            '              value: single-node\n' +
                            '          image: liferaycloud/elasticsearch:7.17.9-5.1.1\n' +
                            '          resources:\n' +
                            '            limits:\n' +
                            '              memory: "2024Mi"\n' +
                            '              cpu: "2"\n' +
                            '          ports:\n' +
                            '            - containerPort: 9300\n' +
                            '          name: search\n' +
                            '          volumeMounts:\n' +
                            '            - mountPath: /lcp-container\n' +
                            '              name: search-claim\n' +
                            '      restartPolicy: Always\n' +
                            '      volumes:\n' +
                            '        - name: search-claim\n' +
                            '          persistentVolumeClaim:\n' +
                            '            claimName: search-claim'}
                    </SyntaxHighlighter>

                    Aplicare:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {
                            'kubectl apply -f elasticsearch-deployment.yml -n=liferay'
                        }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'service/search created\n' +
                            'persistentvolumeclaim/search-claim created\n' +
                            'deployment.apps/search created\n'}
                    </SyntaxHighlighter>

                    Verificari:
                    <SyntaxHighlighter>
                        {'kubectl get deployments -n=liferay'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME       READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'postgres   1/1     1            1           19h\n' +
                            'search     0/1     1            0           34m\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl get pod -n=liferay'}
                    </SyntaxHighlighter>
                    poate afida:
                    <SyntaxHighlighter>
                        {'NAME                        READY   STATUS    RESTARTS   AGE\n' +
                            'postgres-6d745c4876-hjbhq   1/1     Running   0          19h\n' +
                            'search-84b9c54f65-dzx8w     0/1     Pending   0          41m'}
                    </SyntaxHighlighter>

                    Verificam de ce nu a pornit:
                    <SyntaxHighlighter>
                        {'kubectl describe deployment search -n=liferay'}
                    </SyntaxHighlighter>

                    Daca afiseaza:
                    <SyntaxHighlighter>
                        {'Available      False   MinimumReplicasUnavailable\n' +
                            'Progressing    False   ProgressDeadlineExceeded\n'}
                    </SyntaxHighlighter>

                    (s-ar sa nu aibe resurse destule si trebuie misorate, de exemplu)
                    <SyntaxHighlighter>
                        {' resources:\n            limits:\n              memory: "512" # "2024Mi"\n              cpu: "0.5" # "2"'}
                    </SyntaxHighlighter>

                    <br/>
                    sau:
                    <SyntaxHighlighter>
                        {'NAME                        READY   STATUS              RESTARTS   AGE\n' +
                            'postgres-6d745c4876-hjbhq   1/1     Running             0          20h\n' +
                            'search-db895468c-526b7      0/1     ContainerCreating   0          38s\n'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {'NAME                        READY   STATUS             RESTARTS      AGE\n' +
                            'postgres-6d745c4876-hjbhq   1/1     Running            0             20h\n' +
                            'search-db895468c-526b7      0/1     CrashLoopBackOff   3 (30s ago)   3m4s\n'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {'NAME                        READY   STATUS    RESTARTS      AGE\n' +
                            'postgres-6d745c4876-hjbhq   1/1     Running   0             20h\n' +
                            'search-db895468c-526b7      1/1     Running   4 (54s ago)   3m28s\n'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {'NAME                        READY   STATUS      RESTARTS      AGE\n' +
                            'postgres-6d745c4876-hjbhq   1/1     Running     0             20h\n' +
                            'search-db895468c-526b7      0/1     OOMKilled   4 (69s ago)   3m43s\n'}
                    </SyntaxHighlighter>
                    OOM inseamna Out Of Memory!

                    <b>
                        Observatie:
                    </b>

                    <SyntaxHighlighter>
                        {'Pentru mediile de producție este recomandat un minim de 8 GB RAM, cu cel puțin 50% din memoria totală alocată heap-ului Java (4 GB). Pentru implementări mai mari, luați în considerare utilizarea mașinilor cu 64 GB sau mai mult RAM'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ElasticsearchK8sContent;