import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class RateLimitingNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-rate-limiting", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Rate limiting</b>


                    Motive:
                    <ul>
                        <li>Security - protectie Brute Force</li>
                        <li>Realility - prevenire Traffic Spikes (cresteri bruste de trafic)</li>
                        <li>Shaping - Service Priority (restrictionarea utilizatorilor la niste servicii, cum ar download service)</li>
                    </ul>

                    Pentru a testa se foloseste un nou tool, numit <b>Siege</b>.
                    <br/>
                    Siege este folosit pentru testarea incercarii htpp si benchmarking.

                    <b>1.1. Instalare Siege</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get install siege'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'siege v -r 2 -c 5 http://IP/logo.png'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>v : verbose</li>
                        <li>-r 2 : ruleaza 2 teste</li>
                        <li>-c 5: numar conexuni concurente</li>
                        <li>{"=>"} rezulta 10 request-uri (2 x 5 = 10)</li>
                    </ul>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'\n' +
                            '{       "transactions":                           10,\n' +
                            '        "availability":                       100.00,\n' +
                            '        "elapsed_time":                         0.00,\n' +
                            '        "data_transferred":                     0.00,\n' +
                            '        "response_time":                        0.00,\n' +
                            '        "transaction_rate":                     0.00,\n' +
                            '        "throughput":                           0.00,\n' +
                            '        "concurrency":                          0.00,\n' +
                            '        "successful_transactions":                10,\n' +
                            '        "failed_transactions":                     0,\n' +
                            '        "longest_transaction":                  0.00,\n' +
                            '        "shortest_transaction":                 0.00\n' +
                            '}'}
                    </SyntaxHighlighter>


                    <b>1.2. Configurare</b>

                    <br/>
                    <br/>

                    Configurari in <b>nginx.conf</b>:
                    <ul>
                        <li>
                            <b>limit_req_zone</b> <i>kid</i> zone=<i>MYZONE</i>:<i>dimensiune</i> rate=<i>rata</i> {"=>"} defineste o noua zona de memorie pentru a urmarii conexiunile:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {' # Definire limita pentru o zona\n' +
                                    'limit_req_zone $request_uri zone=MYZONE:10m rate=1r/s;'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>
                                    kid - cheie pentru identificarea rate limiting,
                                    <br/>
                                    poate fi de exemplu:
                                    <ul>
                                        <li>$server_name: limita este aplicata pe baza numelui server-ului</li>
                                        <li>$binary_remote_address: IP de conectare, deci limitarea va fi facute per user (fiecare lcient are o adresa unica de IP)
                                            <br/>
                                            aceasta regula poate fi potrivita pentru a limita forma de logare sau bruce force attack
                                        </li>
                                        <li>$request_uri: limita este aplicata per request/cerere</li>
                                    </ul>
                                </li>
                                <li>
                                    zone=<i>MYZONE</i>:<i>dimensiune</i> - defineste zona si dimensiunea ei in memorie
                                </li>
                                <li>
                                    rate=<i>rata</i> - defineste rata (numarul de request-uri per secunda)
                                    <br/>
                                    60r/m = 1r/s
                                </li>
                            </ul>
                            se poate definii si <b>burst</b>=<i>dimensiune</i>, dar e mai ok daca e definita in sectiunea <b>location</b>.

                        </li>

                        <li>
                            <b>limit_req_zone</b> zone=<i>MYZONE</i> burst=<i>dimensiune</i> nodelay {"=>"} se poate aplica pentru o anumita <b>location</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'limit_req zone=MYZONE burst=5 nodelay;'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>zone=<i>MYZONE</i>: definire zona folosita</li>
                                <li>
                                    burst=<i>dimensiune</i> : schimba comportamentul de a respinge o cere imediat toate request-urile.
                                    acesta permite in functie de <i>dimensiune</i> de a impune o limita;
                                    <br/>
                                    asta nu inseamna ca va raspunde imediat, ci in functie de rate, asteptand de exemplu pentry 1r/s o secunda.
                                    <br/>
                                    deci server-ul, pentu exemplul de mai sus, va accepta 1r/s + 5burst = 6 conexiuni

                                    <hr/>

                                    Parametrul <b>burst</b> definește câte solicitări poate face un client peste rata specificată de zonă ( sa presupunem ca rate = 1r/100ms).
                                    <br/>
                                    O solicitare care sosește mai devreme de 100 ms după ce cea anterioară este pusă într-o coadă.
                                    <br/>
                                    Daca <b>burst=20</b> inseamna ca setăm dimensiunea cozii la 20.
                                    <br/>
                                    Aceasta înseamnă că dacă 21 de solicitări sosesc de la o anumită adresă IP simultan, NGINX:
                                    <ul>
                                        <li>
                                            redirecționează pe prima către server
                                        </li>

                                        <li>
                                            pune pe cele 20 rămase în coadă
                                        </li>
                                    </ul>
                                    Apoi redirecționează o solicitare în coadă la fiecare 100 ms și revine 503 la client numai dacă o solicitare primită face ca numărul de cereri din coadă să depășească 20.

                                </li>
                                <li>
                                    nodelay : se aplica in combinatie cu <b>burst</b> pentru a specifica sa serveasca request-urile <i>burst</i> cat mai repede posibil;
                                    <br/>
                                    dar se va aplica limita pentru un nou request
                                    (deci, daca executam 6 request-uri concurente, toate vor fi servite cate mai repede posibil,
                                    dar rate limit se va reseta in continuare la 1r/s)

                                    <hr/>

                                    O configurație cu <b>burst</b> într-un flux fluid al traficului nu este foarte practică, deoarece poate face site-ul tău să pară lent.
                                    În exemplul nostru, al 20-lea pachet din coadă așteaptă 2 secunde pentru a fi redirecționat, moment în care un răspuns la acesta ar putea să nu mai fie util clientului.
                                    Pentru a rezolva această situație, se foloseste paramentrul <b>nodelay</b> împreună cu parametrul <b>burst</b> ( limit_req zone=mylimit burst=20 nodelay;)

                                    <br/>
                                    <br/>

                                    Cu parametrul <b>nodelay</b>, NGINX încă alocă sloturi în coadă conform parametrului <b>burst</b> și impune limita de rată configurată,
                                    dar <b>nu prin distanțarea redirecționării cererilor din coadă</b>.
                                    În schimb, atunci când o solicitare ajunge „prea devreme”, NGINX o redirecționează imediat atâta timp cât există un slot disponibil pentru ea în coadă.
                                    <br/>
                                    Acesta <b>marchează acel slot ca „luat” și nu îl eliberează pentru utilizare de către o altă solicitare până când nu trece timpul corespunzător</b> (în exemplul nostru, după 100 ms).

                                    <br/>
                                    <br/>
                                    Să presupunem, ca și înainte, că coada de 20 de sloturi este goală și 21 de solicitări sosesc simultan de la o anumită adresă IP.
                                    NGINX redirecționează imediat toate cele 21 de solicitări și marchează cele 20 de sloturi din coadă ca fiind preluate,
                                    apoi eliberează 1 slot la fiecare 100 ms.
                                    <br/>
                                    Dacă ar exista 25 de solicitări, NGINX ar redirecționa imediat 21 dintre ele, ar marca 20 de spații ca fiind preluate și ar respinge 4 solicitări cu status  503.
                                    <br/>
                                    <br/>

                                    Acum să presupunem că la 101 ms după ce primul set de solicitări a fost transmis, alte 20 de solicitări ajung simultan. Doar 1 slot din coadă a fost eliberat,
                                    așa că NGINX redirecționează 1 cerere și respinge celelalte 19 cu status  503.
                                    <br/>
                                    Dacă, în schimb, au trecut 501 ms înainte de sosirea celor 20 de noi solicitări, 5 sloturi sunt libere, așa că NGINX redirecționează imediat 5 solicitări și respinge 15.

                                    <br/>
                                    <br/>

                                    <b>Efectul este echivalent cu o limită de rată de 10 solicitări pe secundă.</b>
                                    Opțiunea <b>nodelay</b> este utilă dacă doriți să impuneți o limită de rată fără a restricționa distanța permisă între cereri.

                                </li>
                            </ul>

                        </li>
                    </ul>

                    Daca rulam, cu configurarile de mai sus (fara burst):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'siege v -r 2 -c 5 http://IP/logo.png'}
                    </SyntaxHighlighter>
                    din 10 request-uri, doar 1 va fi succes.

                    <br/>
                    <br/>

                    Daca rulam, cu configurarile de mai sus (cu burst):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'siege v -r 2 -c 5 http://IP/logo.png'}
                    </SyntaxHighlighter>
                    din 10 request-uri, toate request-urile vor avea succes, dar va dura mai mult (1 secunda per request ~ 9-10 secunde).

                    Daca rulam, cu configurarile de mai sus (cu burst):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'siege v -r 1 -c 15 http://IP/logo.png'}
                    </SyntaxHighlighter>
                    va rezulta 6 request-uri ok, 9 respinse

                    Daca rulam, cu configurarile de mai sus (cu burst+noday):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'siege v -r 1 -c 6 http://IP/logo.png'}
                    </SyntaxHighlighter>
                    va rezulta 6 request-uri ok

                    <br/>

                    Daca rulam, cu configurarile de mai sus inca o data (cu burst+noday):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'siege v -r 1 -c 6 http://IP/logo.png'}
                    </SyntaxHighlighter>
                    va rezulta 2 request-uri ok, 4 respinse (2 request-uri pentru ca intre cele 2 teste s-a scurs 2 secunde...dar nu mai poate raspunde ok si pentru restul de 4;
                    ar fi fost nevoie de inca 4 secunde si pentru ele)
                    <br/>

                    Mai multe detalii:
                    <ul>
                        <li>
                            <a target={"_blank"} href={"https://www.nginx.com/blog/rate-limiting-nginx/"}>Rate Limiting with NGINX and NGINX Plus</a>
                        </li>
                        <li>
                            <a target={"_blank"} href={"https://www.freecodecamp.org/news/nginx-rate-limiting-in-a-nutshell-128fe9e0126c"}>NGINX rate-limiting in a nutshell</a>
                        </li>
                    </ul>

                    <hr/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'user www-data;\n' +
                            '\n' +
                            'worker_processes auto;\n' +
                            '\n' +
                            'events {\n' +
                            '  worker_connections 1024;\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  # Definire limita pentru o zona\n' +
                            '  limit_req_zone $request_uri zone=MYZONE:10m rate=1r/s;\n' +
                            '\n' +
                            '  # Redirect all traffic to HTTPS\n' +
                            '  server {\n' +
                            '    listen 80;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '    return 301 https://$host$request_uri;\n' +
                            '  }\n' +
                            '\n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 443 ssl http2;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '\n' +
                            '    root /sites/demo;\n' +
                            '\n' +
                            '    index index.html;\n' +
                            '\n' +
                            '    ssl_certificate /etc/nginx/ssl/self.crt;\n' +
                            '    ssl_certificate_key /etc/nginx/ssl/self.key;\n' +
                            '\n' +
                            '    # Disable SSL\n' +
                            '    ssl_protocols TLSv1 TLSv1.1 TLSv1.2;\n' +
                            '\n' +
                            '    # Optimise cipher suits\n' +
                            '    ssl_prefer_server_ciphers on;\n' +
                            '    ssl_ciphers ECDH+AESGCM:ECDH+AES256:ECDH+AES128:DH+3DES:!ADH:!AECDH:!MD5;\n' +
                            '\n' +
                            '    # Enable DH Params\n' +
                            '    ssl_dhparam /etc/nginx/ssl/dhparam.pem;\n' +
                            '\n' +
                            '    # Enable HSTS\n' +
                            '    add_header Strict-Transport-Security "max-age=31536000" always;\n' +
                            '\n' +
                            '    # SSL sessions\n' +
                            '    ssl_session_cache shared:SSL:40m;\n' +
                            '    ssl_session_timeout 4h;\n' +
                            '    ssl_session_tickets on;\n' +
                            '\n' +
                            '    location / {\n' +
                            '      limit_req zone=MYZONE burst=5 nodelay;\n' +
                            '      try_files $uri $uri/ =404;\n' +
                            '    }\n' +
                            '\n' +
                            '    location ~\\.php$ {\n' +
                            '      # Pass php requests to the php-fpm service (fastcgi)\n' +
                            '      include fastcgi.conf;\n' +
                            '      fastcgi_pass unix:/run/php/php7.1-fpm.sock;\n' +
                            '    }\n' +
                            '\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RateLimitingNginxContent;