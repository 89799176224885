import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class DynamicComponentsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-dynamic-components", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Componenta dinamice: ComponentFactoryResolver (depreciat in Angular 13)</b>
                    <br/>
                    <br/>

                    Modalitatea de creea componenta dinamice folosind <b>ComponentFactoryResolver</b> si <b>createComponent()</b>
                    este depreciat din Angular 13:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<ng-template #id></ng-template>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class UserCardComponent implements OnInit, AfterViewInit {\n' +
                            '  @ViewChild(\'id\', { read: ViewContainerRef }) view: ViewContainerRef;\n' +
                            '  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}\n' +
                            '  ngOnInit() {}\n' +
                            '  ngAfterViewInit() {\n' +
                            '    const componentFactory =\n' +
                            '      this.componentFactoryResolver.resolveComponentFactory(IdComponent);\n' +
                            '    let componentRef: ComponentRef<IdComponent> =\n' +
                            '      this.view.createComponent(componentFactory);\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    unde <i>IdComponent</i> este o clasa-componenta Angular.

                    <hr/>
                    <b>2. Componenta dinamice: direct</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<ng-template #id></ng-template>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'@ViewChild(\'id\', { read: ViewContainerRef }) view: ViewContainerRef;'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const componentRef = this.view.createComponent(IdComponent);'}
                    </SyntaxHighlighter>

                    In plus, trebuie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'constructor(\n' +
                            '    private cdRef: ChangeDetectorRef\n' +
                            ') {}'}
                    </SyntaxHighlighter>
                    si:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ngAfterViewInit() {\n' +
                            '  this.instantiateWithoutFactory(); // metoda care creeaza componenta dinamica\n' +
                            '  this.cdRef.detectChanges(); // pentru eroarea: “expression changed after it has been checked”\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se pot modifica/accesa proprietati:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' instantiateWithoutFactory(): void {\n' +
                            '    const componentRef = this.view2.createComponent(IdComponent);\n' +
                            '    componentRef.instance.backgroundColor = \'pink\';\n' +
                            '    componentRef.instance.foregroundColor = \'black\';\n' +
                            '    componentRef.instance.fontWeight = \'normal\';\n' +
                            '  }'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DynamicComponentsAngularContent;