import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TypeConversionJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-type-conversion", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    Pentru a crea variabile de tip primitiv, se pot folosi:
                    <ul>
                        <li>literali</li>
                        <li>functii de constructor: Boolean, Number, BigInt și String</li>
                    </ul>

                    Functiile de constructor pot fi apelate in urmatorul fel:
                    <ul>
                        <li>functia <b>String()</b> va creea si returna o primitiva de tip sir de caractere cu valoare "" (sirul vid)
                            <br/>
                            poate primi un argument de tip:
                            <ul>
                                <li>number / Number</li>
                                <li>boolean / Boolean (true e convertit la 1; false e convertit la 0)</li>
                                <li>string / String</li>
                                <li>bigint / BigInt</li>
                                <li>null (va fi convertit la sirul de caractere 'null')</li>
                                <li>undefined (va fi convertit la sirul de caractere 'undefined')</li>
                                <li>NaN (va fi convertit la sirul de caractere 'NaN')</li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const str1 = String(13);\n' +
                                'const str2 = String(true);\n' +
                                'const str3 = String("test");\n' +
                                'const str4 = String(13n);\n' +
                                'console.log(str1); // "13"\n' +
                                'console.log(str2); // "true"\n' +
                                'console.log(str3); // "test"\n' +
                                'console.log(str4); // "13"'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const str1 = String(Number(13));\n' +
                                'const str2 = String(Boolean());\n' +
                                'const str3 = String(String("test"));\n' +
                                'const str4 = String(BigInt(13n));\n' +
                                'console.log(str1); // "13"\n' +
                                'console.log(str2); // "true"\n' +
                                'console.log(str3); // "test"\n' +
                                'console.log(str4); // "13"\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>functia <b>Number()</b> va creea si returna o primitiva cu valoarea 0

                            <br/>
                            poate primi un argument de tip:
                            <ul>
                                <li>number / Number</li>
                                <li>boolean / Boolean (valoare true va fi convertita la 1, valoare false va fi convertita la 0 )</li>
                                <li>string / String

                                    <ul>
                                        <li>
                                            șirul de caractere poate conține numere în format hexazecimal, octal și binar (precedat de 0x, 0o sau 0b);
                                            acest sir va fi interpretat si converit la nu numar in format zecimal;

                                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                                {'let x = Number("0b101");\n' +
                                                'console.log(x); // 5\n' +
                                                'console.log(typeof x); // number'}
                                            </SyntaxHighlighter>
                                        </li>
                                        <li>sirul de caractere "Infinity" este convertit la valoarea numerica Infinity
                                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                                {'let x = Number("Infinity");\n' +
                                                'console.log(x); // Infinity\n' +
                                                'console.log(typeof x); // number'}
                                            </SyntaxHighlighter>
                                        </li>

                                        <li>sirul de caractere "NaN" este convertit la valoarea numerica NaN
                                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                                {'let x = Number("NaN");\n' +
                                                'console.log(x); // NaN\n' +
                                                'console.log(typeof x); // number'}
                                            </SyntaxHighlighter>
                                        </li>

                                        <li>daca un sir de caractere nu poate fi convertit i se atribuie valoare NaN</li>
                                    </ul>


                                </li>
                                <li>bigint / BigInt (pentru valori mari, o parte din ele poate fi trunchiată si poate ajunge să fie imprecisă)</li>
                                <li><b>undefined</b> (este convertit la valoarea numerica <b>NaN</b>)</li>
                                <li>null (este convertit la valoare numerica 0)</li>
                                <li>NaN (este convertit la valoare numerica NaN)</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const num1 = Number(Number(13));\n' +
                                'const num2 = Number(Boolean());\n' +
                                'const num3 = Number(Boolean(true));\n' +
                                'const num4 = Number(String("test")); \n' +
                                'const num5 = Number(BigInt(13n));\n' +
                                'console.log(num1); // 13\n' +
                                'console.log(num2); // 0\n' +
                                'console.log(num3); // 1\n' +
                                'console.log(num4); // "NaN"\n' +
                                'console.log(num5); // 13\n'}
                            </SyntaxHighlighter>

                        </li>
                        <li>functia <b>Boolean()</b> va creea si returna o primitiva de tip boolean cu valoare <i>false</i>

                            <br/>
                            poate primi un argument de tip:
                            <ul>
                                <li>number / Number (valoarea 0 va fi convertita la false; orice alta valoare diferita de 1 va fi convertita la true)</li>
                                <li>boolean / Boolean</li>
                                <li>string / String (valoarea "" va fi convertita la false; orice alta valoare diferita de "" va fi convertita la true)</li>
                                <li>bigint / BigInt (valoarea 0 va fi convertita la false; orice alta valoare diferita de 1 va fi convertita la true)</li>
                                <li>undefined (este convertit la false)</li>
                                <li>null (este convertit la false)</li>
                                <li>NaN (este convertit la false)</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const b1 = Boolean(0);\n' +
                                'const b2 = Boolean(1);\n' +
                                'const b3 = Boolean(13);\n' +
                                'console.log(b1); // false\n' +
                                'console.log(b2); // true\n' +
                                'console.log(b3); // true'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'const b4 = Boolean("true");\n' +
                                'const b5 = Boolean("false");\n' +
                                'const b6 = Boolean("x");\n' +
                                'const b7 = Boolean("");\n' +
                                'console.log(b4); // true\n' +
                                'console.log(b5); // true\n' +
                                'console.log(b6); // true\n' +
                                'console.log(b7); // false'}
                            </SyntaxHighlighter>
                        </li>
                        <li>functia <b>BigInt(valoare_initiala)</b> va creea si returna o primitiva de tip BigInt cu valoarea primita de functie ca argument

                            <br/>
                            poate primi un argument de tip:
                            <ul>
                                <li>number / Number (in format zecimal, hexazecila, octal sau binar)
                                    <br/>
                                    se poate folosi si notatia exponentiala
                                </li>
                                <li>boolean / Boolean (true e convertit la 1; false e convertit la 0)</li>
                                <li>string / String (sir de caractere care reprezinta un numar in format zecimal, hexazecila, octal sau binar);
                                    <br/>
                                    nu se poate folosi si notatia exponentiala:
                                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                        {'console.log(BigInt("11e2")); // -> Uncaught SyntaxError: Cannot convert 11e2 to a BigInt'}
                                    </SyntaxHighlighter>
                                </li>

                                <li>bigint / BigInt</li>
                                <li>null (eroare: Uncaught TypeError: Cannot convert null to a BigInt)</li>
                                <li>undefined (eroare:  Uncaught TypeError: Cannot convert undefined to a BigInt)</li>
                                <li>NaN (eroare: Uncaught RangeError: The number NaN cannot be converted to a BigInt because it is not an integer)</li>
                            </ul>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const str = String();\n' +
                        'const num = Number();\n' +
                        'const bool = Boolean();\n' +
                        'const big = BigInt(13);\n' +
                        '\n' +
                        'console.log(str);\n' +
                        'console.log(num); // 0\n' +
                        'console.log(bool); // false\n' +
                        'console.log(big); // 13\n' +
                        'console.log(typeof big) // bigint'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Conversii implicite</b>
                    <br/>
                    <br/>
                    <ul>
                        <li>numar + str {"=>"} concatenare intre numar convertit la string + str
                            <br/>
                            numar + str = String(numar)+str
                        </li>
                        <li>numar - str {"=>"} scadere intre numar si str convertit la o valoare numerica
                            <br/>
                            numar - str = numar - Number(str)
                        </li>
                        <li>
                            true + numar {"=>"} 1 + numar
                        </li>
                        <li>
                            false + numar {"=>"} 0 + numar = numar
                        </li>

                        <li>
                            true + bigint {"=>"} error
                            <br/>
                            bigint + true {"=>"} error
                        </li>
                        <li>
                            false + bigint {"=>"} error
                            <br/>
                            bigint + false {"=>"} error
                        </li>

                        <li>
                            numar + bigint {"=>"} error
                        </li>

                        <li>
                            bigint + numar {"=>"} error
                        </li>

                        <li>
                            bigint + string {"=>"} concaternare
                        </li>
                        <li>
                            string + bigint {"=>"} concaternare
                        </li>

                        <li>
                            string + true {"=>"} concaternare intre string si sirul de caractere "true"
                        </li>

                        <li>
                            string + false {"=>"} concaternare intre string si sirul de caractere "false"
                        </li>
                    </ul>

                    <b>Observatie</b>:
                    <br/>
                    Operatorul unar + / - in fata unui sir de caractere, converteste sirul de caractere la numar:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'const x =  +"1";\n' +
                        'console.log(x); // 1\n' +
                        'console.log(typeof x); // number\n' +
                        '\n' +
                        'const y =  "1";\n' +
                        'console.log(y); // 1\n' +
                        'console.log(typeof y); // string'}
                    </SyntaxHighlighter>

                    Daca conversia nu reuseste se returneaza NaN:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const x =  -"1a";\n' +
                        'console.log(x); // 1\n' +
                        'console.log(typeof x); // number\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TypeConversionJavaScriptContent;