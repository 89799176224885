import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class BoltzmannContent extends BaseContentPage {

    constructor(props) {
        super(props, "boltzmann", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">
                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify"}>
                    Mașinile Boltzmann sunt rețele Hopfield în care neuronii au proprietăți probabilistice. Acestea au fost propuse de Georffy Hinton și Terry Sejnowsky în 1983.<br/>
                    Aceste retele sunt folosite în <b><i>recunoașterea formelor</i></b>.
                </div>
                <br/>

                <div className={"text-justify important"}>
                    În rețeaua Boltzmann:<br/>
                    <ul>
                        <li>
                            neuronii sunt împărțiți în <i>două straturi</i>:
                            <ul>
                                <li>
                                    <i>statul de neuroni vizibili</i>
                                </li>
                                <li>
                                    <i>statul de neuroni ascunși</i>
                                </li>
                            </ul>
                        </li>
                        <li>
                            ponderile sunt simetrice:
                            <ul>
                                <li><MathComponent tex={String.raw`w_{ij}=w_{ji}`}/> </li>
                            </ul>
                        </li>
                    </ul>
                    Rețeaua Boltzmann este <i>complet interconectată</i>, adică fiecare neuron este conectat cu toți ceilalți neuroni, indifierent de strat.<br/>
                    <br/>
                    Neuronii vizibili:
                    <ul>
                        <li>
                            <i>primesc informația</i>
                        </li>
                        <li>
                            <i>returnează rezultatul prelucrării când rețeaua ajunge într-o stare stabilă</i>.
                        </li>
                    </ul>
                    Neuronii ascunsi:
                    <ul>
                        <li>
                            <i>prelucrează și memorează informația</i>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Dumitru Popescu, Maria Luiza Flonta. 2009. Teoria rețelelor neuronale artificiale, Editura Universității din București</div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BoltzmannContent;