import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class UbuntuDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "ubuntu-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare Docker pe Ubuntu</b>

                    <br/>
                    <br/>

                    <b>1.1. Instalare din repository</b>
                    <br/>
                    <br/>

                    Actualizare index pachete:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get update'}
                    </SyntaxHighlighter>

                    Instalare pachete pentru a permite <b>apt</b> pentru a folosit un repository cu HTTPS:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get install \\\n' +
                            '    ca-certificates \\\n' +
                            '    curl \\\n' +
                            '    gnupg \\\n' +
                            '    lsb-release'}
                    </SyntaxHighlighter>

                    Adăugați cheia GPG oficială a lui Docker:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo mkdir -p /etc/apt/keyrings'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo gpg --dearmor -o /etc/apt/keyrings/docker.gpg'}
                    </SyntaxHighlighter>

                    Configurare depozit:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'echo \\\n' +
                            '  "deb [arch=$(dpkg --print-architecture) signed-by=/etc/apt/keyrings/docker.gpg] https://download.docker.com/linux/ubuntu \\\n' +
                            '  $(lsb_release -cs) stable" | sudo tee /etc/apt/sources.list.d/docker.list > /dev/null'}
                    </SyntaxHighlighter>

                    Instalare <b>Docker Engine</b>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get update'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get install docker-ce docker-ce-cli containerd.io docker-compose-plugin'}
                    </SyntaxHighlighter>

                    Verificare dacă Docker Engine este instalat corect:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo service docker start'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo docker run hello-world'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Unable to find image \'hello-world:latest\' locally\n' +
                            'latest: Pulling from library/hello-world\n' +
                            '2db29710123e: Pull complete\n' +
                            'Digest: sha256:62af9efd515a25f84961b70f973a798d2eca956b1b2b026d0a4a63a3b0b6a3f2\n' +
                            'Status: Downloaded newer image for hello-world:latest\n' +
                            '\n' +
                            'Hello from Docker!\n' +
                            'This message shows that your installation appears to be working correctly.\n' +
                            '\n' +
                            'To generate this message, Docker took the following steps:\n' +
                            ' 1. The Docker client contacted the Docker daemon.\n' +
                            ' 2. The Docker daemon pulled the "hello-world" image from the Docker Hub.\n' +
                            '    (amd64)\n' +
                            ' 3. The Docker daemon created a new container from that image which runs the\n' +
                            '    executable that produces the output you are currently reading.\n' +
                            ' 4. The Docker daemon streamed that output to the Docker client, which sent it\n' +
                            '    to your terminal.\n' +
                            '\n' +
                            'To try something more ambitious, you can run an Ubuntu container with:\n' +
                            ' $ docker run -it ubuntu bash\n' +
                            '\n' +
                            'Share images, automate workflows, and more with a free Docker ID:\n' +
                            ' https://hub.docker.com/\n' +
                            '\n' +
                            'For more examples and ideas, visit:\n' +
                            ' https://docs.docker.com/get-started/'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            Learn Docker in a month of lunches - Elton Stoneman, Manning, 2020*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UbuntuDockerContent;