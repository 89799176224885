import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Art1Ex1Content extends BaseContentPage{

    constructor(props) {
        super(props, "art1ex1", IndexContent);
    }

    norm(vector){
        let sum = 0;
        for(let i = 0;i<vector.length;i++){
            sum += vector[i];
        }
        return sum;
    }

    render() {

        const setA = [];
        setA.push([1,1,0,0]);
        setA.push([0,0,0,1]);
        setA.push([1,0,0,0]);
        setA.push([0,0,1,1]);

        let n = setA[0].length;
        let m = 3;
        let p = 0.7;
        let l = 2;
        let initT = 1;
        let initB = 1/(1+n);

        let b = [];
        for (let i = 0; i < n; i++) {
            b[i] = new Array(m);
            for(let j=0;j<m;j++){
                b[i][j] = initB;
            }
        }

        let t = [];
        for (let i = 0; i < m; i++) {
            t[i] = new Array(n);
            for(let j=0;j<n;j++){
                t[i][j] = initT;
            }
        }

        let iteration = 0;
        let epoca = 1;
        let order = [0,1,2,3];

        let iterationStore = [];

        for(iteration=0; iteration<order.length;iteration++) {
            let s = setA[order[iteration % n]];

            iterationStore[iteration] = {};
            iterationStore[iteration].s = s.slice();

            //  x[i] = s[i]
            let x = [];
            for (let i = 0; i < s.length; i++) {
                x[i] = s[i];
            }

            iterationStore[iteration].x = x.slice();

            // calcul y
            let y = [];
            for (let j = 0; j < m; j++) {

                y[j] = 0;
                for (let i = 0; i < n; i++) {
                    y[j] += x[i] * b[i][j];
                }

            }

            iterationStore[iteration].y = y.slice();

            let reset = true;
            let inhibitor = 0;
            let iterationReset = 0;
            iterationStore[iteration].reset=[];
            while (reset) {

                // calcul J
                let maxJ = -1;
                let max = -1000000;
                for (let j = 0; j < m; j++) {
                    if (y[j]!=-1) { // nu e inhibat
                        if (y[j] > max) {
                            max = y[j];
                            maxJ = j;
                        }
                    }
                }

                iterationStore[iteration].reset[iterationReset]={};
                iterationStore[iteration].reset[iterationReset].max = max;
                iterationStore[iteration].reset[iterationReset].maxJ = maxJ;

                // actualizare x:
                for (let i = 0; i < s.length; i++) {
                    x[i] = s[i] * t[maxJ][i];
                }

                iterationStore[iteration].reset[iterationReset].xx = x.slice();

                let normX = this.norm(x);
                let r = normX / this.norm(s);

                iterationStore[iteration].reset[iterationReset].r = r;

                if (r >= p) {
                    // actualizare parametrii

                    for (let i = 0; i < n; i++) {
                        b[i][maxJ] = (2 * x[i]) / (1 + normX);
                    }
                    for (let i = 0; i < n; i++) {
                        t[maxJ][i] = x[i];
                    }
                    reset = false;

                    iterationStore[iteration].reset[iterationReset].b = b.map(function (arr) {
                        return arr.slice();
                    });

                    iterationStore[iteration].reset[iterationReset].t = t.map(function (arr) {
                        return arr.slice();
                    });
                } else {
                    reset = true;
                    // neuronul J se inhiba
                    y[maxJ] = -1;
                    inhibitor++;

                    iterationStore[iteration].reset[iterationReset].b = b.map(function (arr) {
                        return arr.slice();
                    });

                    iterationStore[iteration].reset[iterationReset].t = t.map(function (arr) {
                        return arr.slice();
                    });
                }

                iterationStore[iteration].reset[iterationReset].reset = reset;

                if (reset){
                    iterationReset++;
                }
            }
        }
        //epoca++;
        let iterationReset = 0;

        return (
            <div className="home art">

                {this.title()}
                {this.navigator()}

                <br/>

                <div id={"art1ex1"} className={"text-justify"}>
                    <b>2.1 Exemplu</b>
                    <br/>
                    Sa presupunem că avem următoarele <b>{setA.length}</b> exemple:
                    <table>
                        {
                            setA.map((row,index) => {
                                return <tr>
                                    <td><span className={"badge badge-warning"}>{index+1}</span></td>
                                    {
                                        row.map(value=>{
                                            return <td>{value}</td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </table>

                    Notam și initalizam cu:
                    <ul>
                        <li> m = {m} - numărul maxim de clase/clustere pe care vrem sa le construim</li>
                        <li> n = {n} - numărul de componente dintr-un exemplu</li>
                        <li> p = {p} - parametrul de vigilenta</li>
                        <li> L = {l} - parametru folosit in actualizarea ponderlor</li>
                        <li> b<sub>ij</sub>={initB} (matrice de {m}x{n})</li>
                        <li> b<sub>ji</sub>={initT} (matrice de {n}x{m})</li>
                    </ul>
                    <hr/>

                    {
                        [0,1,2,3].map((vvv,i)=>{
                            return <div>
                                {console.log(iterationStore[i].reset)}
                                <b><h5><span className="badge badge-success">Epoca {epoca}:</span></h5></b>
                                <hr/>
                                <span className={"badge badge-info"}>Exemplul {i%n+1}:</span>
                                <hr/>
                                <b>Pas 1</b>. Se alege exemplul s:
                                <table>
                                    <tr>
                                        <td><span className={"badge badge-warning"}>{i%n+1}</span></td>
                                        {

                                            iterationStore[i].s.map(v=>{
                                                return <td>{v}</td>;
                                            })
                                        }
                                    </tr>
                                </table>
                                <br/>
                                <b>Pas 2</b>. Se calculeaza normala lui s:<br/>
                                <MathComponent tex={String.raw`||s||=\sum_i s_i`}/>
                                ||s|| = {this.norm(iterationStore[i].s)}
                                <br/>
                                <br/>
                                <b>Pas 3</b>. Se calculeza activarile pentru fiecare neuron din layerul F<sub>1</sub>:
                                <MathComponent tex={String.raw`x_i=s_i`}/>
                                <table>
                                    <tr>
                                        {
                                            iterationStore[i].x.map(v=>{
                                                return <td>{v}</td>;
                                            })
                                        }
                                    </tr>
                                </table>
                                <br/>
                                <b>Pas 4</b>. se calculeza intrarile net (sumarizarile) in fiecare nod din F<sub>2</sub>:
                                <MathComponent tex={String.raw`y_j=\sum_i b_{ij}x_i`}/>
                                {
                                    iterationStore[i].y.map((v,item)=>{
                                        return <div>
                                            y<sub>{item+1}</sub> = {v}
                                        </div>
                                    })
                                }

                                {

                                    iterationStore[i].reset.map((resetValues, iterationReset)=>{

                                        return <div>
                                            <br/>{/* calcul J*/}

                                            <b><h5><span className={"badge badge-dark"}>Pas 5</span></h5></b><br/>

                                            <b>Pas 5.{iterationReset+1}</b>. se gaseste neuronul J (neinhibat) cu:
                                            <MathComponent tex={String.raw`y_J=max(y_j), 0 \le j \le m`}/>
                                            intrarea neta maxima = {iterationStore[i].reset[iterationReset].max} si alegem <b>J = {iterationStore[i].reset[iterationReset].maxJ+1}</b><br/>
                                            <br/>
                                            <b>Pas 6.{iterationReset+1}</b>. se recompune activarea neuronilor din F<sub>1</sub>(b):
                                            <MathComponent tex={String.raw`x_i = s_i t_{Ji}`}/>
                                            <table>
                                                <tr>
                                                    {
                                                        iterationStore[i].reset[iterationReset].xx.map(v=>{
                                                            return <td>{v}</td>;
                                                        })
                                                    }
                                                </tr>
                                            </table>
                                            <br/>
                                            <b>Pas 7.{iterationReset+1}</b>. se recompune norma lui x:<br/>
                                            ||x|| = {this.norm(iterationStore[i].reset[iterationReset].xx)}
                                            <br/>
                                            <br/>
                                            <b>Pas 8.{iterationReset+1}</b>. se efecuteaza testul de vigilenta:
                                            <MathComponent tex={String.raw`{r={||x|| \over ||s||}}`}/>
                                            {
                                                (iterationStore[i].reset[iterationReset].reset==false)?
                                                    <div>
                                                        r={iterationStore[i].reset[iterationReset].r} &ge; {p}, (reset = false) deci se <b>actualizeaza</b> ponderile:

                                                        <MathComponent tex={String.raw`b_{iJ}={x_i L \over L-1 + ||x||}`}/>
                                                        <MathComponent tex={String.raw`t_{jI}=x_i`}/>
                                                        Obtinem matricea ponderilor b:

                                                        <table>
                                                            {
                                                                iterationStore[i].reset[iterationReset].b.map((row,index) => {
                                                                    return <tr>
                                                                        <td>{index+1}</td>
                                                                        {
                                                                            row.map(value=>{
                                                                                return <td>{value.toFixed(2)}</td>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                })
                                                            }
                                                        </table>

                                                        Obtinem matricea ponderilor t:

                                                        <table>
                                                            {
                                                                iterationStore[i].reset[iterationReset].t.map((row,index) => {
                                                                    return <tr>
                                                                        <td>{index+1}</td>
                                                                        {
                                                                            row.map(value=>{
                                                                                return <td>{value.toFixed(2)}</td>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                })
                                                            }
                                                        </table>

                                                    </div>
                                                    :
                                                    <div>
                                                        r={iterationStore[i].reset[iterationReset].r} &lt; {p}, (reset = true), se deci:
                                                        <ul>
                                                            <li>ponderile <b>nu se schimba</b></li>
                                                            <li>se inhibă neuronul {iterationStore[i].reset[iterationReset].maxJ+1}  (y<sub>{iterationStore[i].reset[iterationReset].maxJ+1}</sub>=-1)</li>
                                                            <li>se reia de la <b>pasul 5</b></li>
                                                        </ul>

                                                    </div>
                                            }


                                        </div>;
                                    })

                                }

                                <hr/>

                            </div>
                        })
                    }

                    <b>Observatie:</b><br/>
                    La urmatoarea epocă, la prentarea primului exemplu, testul vigilenta nu va putea fi satisfacut de nici un neuron din F<sub>2</sub>.
                    În acest caz,  utilizatorul va putea:
                    <ul>
                        <li>adauga un nou neuron in F<sub>2</sub></li>
                        <li>sa micsoreze parametrul de vigilenta</li>
                    </ul>
                    În contrast cu alete retele, ART nu va fosrta adaugarea unui exemplu intr-un cluster, daca nu e destul de similar.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson</div>
                        </li>
                        <li>
                            <div>Victor Neagoe. 2018. Retele neurale pentru exploarea datelor, Matrixrom</div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Art1Ex1Content;