import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class EspeakRi5TtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-espeak-pi5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. espeak</b>
                    <br/>
                    <br/>

                    site: <br/>
                    https://www.aranacorp.com/en/espeak-voice-synthesizer-on-raspberry-pi/
                    <br/>
                    <br/>
                    instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get install espeak'}
                    </SyntaxHighlighter>


                    testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak "Hello World"'}
                    </SyntaxHighlighter>


                    listare voci disponibile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak --voices'}
                    </SyntaxHighlighter>
                    espeak --voices

                    testare limba romana:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak -v ro "Salut! Ce faci?"'}
                    </SyntaxHighlighter>

                    adaugare voci cu mbrola:
                    <br/>
                    vezi: https://github.com/espeak-ng/espeak-ng/blob/master/docs/mbrola.md
                    <br/>
                    <br/>

                    instalare mbrola + mbrola-ro1
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get install mbrola mbrola-ro1'}
                    </SyntaxHighlighter>

                    testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak -v mb-ro1 "Salut! Ce faci?"'}
                    </SyntaxHighlighter>

                    <b>2. espeak-ng</b>
                    <br/>
                    <br/>


                    site:
                    <br/>
                    <br/>
                    https://github.com/espeak-ng/espeak-ng/

                    instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo apt-get install espeak-ng'}
                    </SyntaxHighlighter>

                    limbi suportate:
                    <br/>
                    https://github.com/espeak-ng/espeak-ng/blob/master/docs/languages.md
                    <br/>
                    <br/>

                    testare
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak-ng hello'}
                    </SyntaxHighlighter>

                    testare limba romana:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak-ng "Tranzistoare, tranzistoare ce ai facut la rezistoare?"'}
                    </SyntaxHighlighter>


                    afisare voci disponibile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak-ng --voices'}
                    </SyntaxHighlighter>


                    afisare voci disponibile pentru engleza:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak-ng --voices=en'}
                    </SyntaxHighlighter>

                    afisare voci disponibile pentru romana:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'espeak-ng --voices=ro'}
                    </SyntaxHighlighter>

                    afisare voci disponibile de la mbrola:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' espeak-ng --voices=mbmb'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Librarii Python</b>
                    <br/>
                    <br/>

                    Pyttsx3 - este o librarie Python care foloseste:
                    <ul>
                        <li>
                            eSpeak pe Linux
                        </li>
                        <li>
                            nsss (NSSpeechSythesizer) pt macOS
                        </li>
                        <li>
                            sapi5 pw Windows
                        </li>
                    </ul>


                    Alte librarii pentru Python:
                    <ul>
                        <li>gTTS (online)</li>
                        <li>pyttsx</li>
                        <li>DeepSpeech - dezvoltat de Mozilla</li>
                        <li>pyttsx3-rs</li>
                        <li>MaryTTS</li>
                        <li>pyttsx3-espeak</li>
                        <li>Tacotron</li>
                        <li>pyttsx3-nsss (pentru macOS)</li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EspeakRi5TtsContent;