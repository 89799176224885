import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class UCPDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ucp", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Docker Universal Control Plane (UCP)/ Mirantis Kubernetes Engine (MKE)</b>

                    <br/>
                    <br/>

                    In fiecare nod exista:
                    <ul>
                        <li>
                            UPC Agent
                        </li>
                        <li>
                            UCP Manager
                        </li>
                        <li>
                            UCP Worker
                        </li>
                    </ul>
                    Instrumente:
                    <ul>
                        <li>
                            UCP Controller
                        </li>
                        <li>
                            UCP Swarm manager
                        </li>
                        <li>
                            UCP UI
                        </li>
                        <li>
                            Docker Trusted Registry = DTR
                        </li>
                    </ul>

                    <hr/>

                    <b>2. Setare infrastructura MKE</b>

                    <br/>
                    <br/>

                    Se creaza 3 masini virtuale (VM), de exemplu in <i>Google Cloud Platform</i>:
                    <ul>
                        <li>admin</li>
                        <li>manager</li>
                        <li>worker</li>
                    </ul>

                    Pe masina <i>admin</i>:
                    <br/>
                    <br/>
                    Creare SSH access:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh-keygen -t rsa -b 4096'}
                    </SyntaxHighlighter>

                    In directorul <i>./ssh</i> de pe masina virtuala, se vor genera fisierele:
                    <ul>
                        <li>id_rsa.pub - cheia publica</li>
                        <li>id_rsa - cheia privata</li>
                    </ul>

                    Conversie cheie privata in <b>.pem</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'openssl rsa -in ~/.ssh/id_rsa -out ~/.ssh/id_rsa.pem '}
                    </SyntaxHighlighter>

                    Acum se adauga cheia publica generata pe masina <i>admin</i> pe <i>manager</i> si pe <i>worker</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cat ~/.ssh/id_rsa.pem '}
                    </SyntaxHighlighter>
                    se copiaza cheia in clipboard.
                    <br/>
                    <br/>
                    Din interfata Google Cloud Platform se adauga SSH Key pentru masina <i>manager</i> si <i>worker</i>.
                    <br/>
                    Dupa asta, de pe masina <i>admin</i> ne vom putea conecta la masina <i>manager</i> si <i>worker</i>, fara parola, ci pe baza cheii publice:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh -i ~/.ssh/id_rsa.pem utilizator@IP_MASINA_MANAGER_SAU_WORKER'}
                    </SyntaxHighlighter>

                    Pentru <i>manager</i> si <i>worker</i> se repeta pasii:
                    <ul>
                        <li>
                            Editare fisier visudo, cu privilegii de root (acest fisier permite modificarea permisiunilor pentru utilizatori):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'sudo visudo'}
                            </SyntaxHighlighter>
                            se adauga urmatoarea linie care permite tutoror utilizatorilor sa se conecteze fara parola:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'$USER ALL=(ALL) NOPASSWD: ALL'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            testare:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ping google.com'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    <hr/>

                    <b>3. Mirantis Launchpad</b>

                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'gsutil cp -r gs://mke_instalation/launchpad .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo chmod +x launchpad'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls -la'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'/launchpad'}
                    </SyntaxHighlighter>

                    <hr/>

                    Inregistrare utilizator:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'/launchpad register'}
                    </SyntaxHighlighter>
                    (se prespunem ca punem utilizatorul: <i>kj</i>)

                    Initializare cluster (va genera un fisier similar cu un fisier de configurare YAML K8s):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'/launchpad init > launchpad.yaml'}
                    </SyntaxHighlighter>

                    Se editeaza fisierul generat:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano launchpad.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: launchpad.mirantis.com/mke/v1.4\n' +
                            'kind: mke\n' +
                            'metadata:\n' +
                            '  name: mke-cluster # editat\n' +
                            'spec:\n' +
                            '  host:\n' +
                            '  - ssh: \n' +
                            '      address: 34.62.111.121 # editat\n' +
                            '      user: kj # editat \n' +
                            '      port: 22\n' +
                            '      keyPath: ~/.ssh/id_rsa.pem # editat\n' +
                            '    role:manager\n' +
                            '  - ssh: \n' +
                            '      address: 34.62.204.78  #editat\n' +
                            '      user: kj # editat\n' +
                            '      port: 22\n' +
                            '      keyPath: ~/.ssh/id_rsa.pem # editat\n' +
                            '    role:worker\n' +
                            '  mke:\n' +
                            '    version: 3.4.4 # editat\n' +
                            '  mcr: # Mirantis Container Runtime (Doker EE) \n'+
                            '    version: 20.10.7 # editat\n' +
                            '  cluster:\n' +
                            '    prune: false' +
                            ''}
                    </SyntaxHighlighter>

                    Aplicare configurari pentru constuirea clusterului:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'./launchpad apply --config launchpad.yaml'}
                    </SyntaxHighlighter>
                    Se va afisa la final un URL, gen: https://34.72.111.121/ {"->"} MKE cluster admin UI.
                    <br/>
                    Se va deschide o aplicatie web, unde intai ne vom loga cu: admin si o parola generata de procesul de constuire a clusterului.
                    <br/>
                    Parola se vede in log-uri: "Generated random admin password: VSDFsfdfdfsdrewR#wreREWREW"
                    <br/>
                    Dupa logare, vom avea acces la un ecran de tip Dashboard.

                    <br/>
                    <br/>
                    Urmatorul pas este setarea certificatului de autetificare client.
                    Este un certificat reciproc baza de autentificare in care clientul ofera certificatul lui de client catre server pentru a dovedi identitatea.
                    Folosind certificatul, se poate autentifica cererile trimise de la nodurile clusterului la UCP, folosind comenzi Docker si Kubernates.
                    <br/>
                    Pentru a descarca certificatul:
                    <b>Admin {'->'} My Profile {'->'} Profile {'->'} Client Bundles {'->'} New Client Bundle {'->'} Generate Client Bundle</b>
                    <br/>
                    Dupa ce se descarca aceasta arhiva "ucp-bundle-admin", aceasta se uploadaza pe nodul <i>manager</i>.

                    <br/>
                    Se dezarhiveaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get install unzip'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir client-bundle && unzip ucp-bundle-admin.zip -d ~/client-bundle'}
                    </SyntaxHighlighter>

                    In fisierul <i>client-bundle</i> se vor regasi:
                    <ul>
                        <li>ca.pem</li>
                        <li>
                            cert.pem
                        </li>
                        <li>
                            ert.pub
                        </li>
                        <li>
                            env.cmd
                        </li>
                        <li>
                            env.ps1
                        </li>
                        <li>
                            <b>env.sh</b> - contine lista variabile de mediu folosite pentru a setapa mediul local
                        </li>
                        <li>
                            key.pem
                        </li>
                        <li>
                            <b>kube.yml</b> - configurarea K8s
                        </li>
                        <li>
                            meta.json
                        </li>
                        <li>
                            tls
                        </li>
                    </ul>

                    Se executa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'eval "$(<env.sh)"'}
                    </SyntaxHighlighter>

                    Se editeaza <i>env.sh</i> modificand <i>DOCKER_HOST</i> cu IP-ul corect al masinii <i>managerului</i>
                    <br/>
                    Se editeaza <i>kube.yml</i> modificand <i>clusters/cluster/server</i> cu IP-ul corect al masinii <i>managerului</i>


                    <hr/>

                    Kubernetes este parte integratea in MKE, deci nu se instaleaza.
                    <br/>

                    Verificare versiune Docker:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker version | grep gitVersion'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'gitVersion: v1.20.7-mirantis-1'}
                    </SyntaxHighlighter>
                    Minor version este 20.

                    Instalare <b>kubectl</b> (avand in vedere ca versiunea minora a Docker Engine este 20, inseamna ca versiunea kubectl poate fi 20, 21 sau 22):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'MINOR_VERION=20'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'curl -LO https://storage.googleapis.com/kubernetes-release/release/v1.${MINOR_VERION}.0/bin/linux/amd64/kubectl'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'chmod +x kubectl'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'./kubectl get pods'}
                    </SyntaxHighlighter>

                    Pentru a rula comanda <i>kubectl</i> de orice utilizator:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo mv ./kubectl /usr/local/bin/kubectl'}
                    </SyntaxHighlighter>

                    Verificare (acum se poate scrie si asa):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>4. Role Based Access Control (RBAC)</b>
                    <br/>
                    <br/>

                    Sa presupunem ca avem un cluster Kubernetes si useri cu diferite nivele de privilegii care incerca sa acceseze acest cluster.
                    <br/>
                    Un user poate avea drepturi de:
                    <ul>
                        <li>vizualizare: kubectl get pods</li>
                        <li>scriere: kubectl create -f</li>
                        <li>gestiona: kubectl taint</li>
                        <li>fara acces</li>
                    </ul>

                    Grants:
                    <ul>
                        <li>
                            <b>Subiect</b> {"=>"} cel care cere access (un utilizator, o organizatie, un team);
                            <br/>
                            Un <b>utilizator</b>: utilizator individual;
                            <br/>
                            O <b>organizatie</b>:
                            <ul>
                                <li>
                                    poate avea mai multe echipe (<b>team</b>)
                                </li>
                                <li>
                                    un grup de utilizatori care partajeaza un set de permisiuni, definite de rolurile oganizatiei;
                                </li>
                            </ul>
                            Team:
                            <ul>
                                <li>exista doar ca partea a unei organizatii</li>
                                <li>un grup de utilizatori care partajeaza un set de persmisiuni definite de echipa/team</li>
                                <li>fiecare team este asociat unui rol (no access, read only, restricted control, full control);</li>
                            </ul>

                            <br/>
                            Fiecare rol este asociat unui nod sau a unui serviciu.
                        </li>
                        <li>
                            Roles {"=>"} multime de permisiuni (View, Edit, Admin) {"=>"} asignate unui utilizator / echipe pentru o multime de resurse;
                            <br/>
                            Rolul <i>View Only</i>{"=>"} poate doar vizualiza resursele
                        </li>
                        <li>
                            Resources {"=>"} Swarm Collections/ k8s namespaces;
                            <br/>
                            Docker EE activeaza controlul de acces la resursele swarm prin intermediul colectiilor;
                            <br/>
                            Resursele Swarm care pot fi puse intr-o colectie:
                            <ul>
                                <li>noduri virtuale/fizice</li>
                                <li>containere</li>
                                <li>servicii</li>
                                <li>retele</li>
                                <li>volume</li>
                                <li>secrete</li>
                                <li>configurarile aplicatiei</li>
                            </ul>
                        </li>
                    </ul>
                    Aceste graturi sunt acordate unor utilizatori.

                    <hr/>
                    Urmatoarele Swarm collections sunt prezente in MKE (UPC) cluster in mod implcit:
                    <ul>
                        <li>
                            Shared
                        </li>
                        <li>
                            System
                        </li>
                    </ul>
                    <hr/>
                    Pentru a actualiza numarul de replici in MKE (UPC):
                    <ul>
                        <li>Services {'->'} Update {'->'} Scheduling {'->'} Scale </li>
                    </ul>

                    <hr/>
                    Core component UPC/MKE {"=>"} ucp-agent

                    <hr/>
                    Instalare Docker UPC (probabil deprecated):
                    <SyntaxHighlighter>
                        {'docker container run --rm -it --name ucp -v /var/run/docker.sock:/var/run/docker.sock docker/ucp:3.1.4 install --host-address 139.59.82.72 --force-minimums'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UCPDockerContent;