import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ComponentReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-component", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Componente</b>
                    <br/>
                    <br/>

                    Observatie:
                    <ul>
                        <li>
                            Se recomanda folosirea componentelor ca functii in loc de clase.
                        </li>
                    </ul>

                    Exemplu componenta bazata pe clasa:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'class Salut extends Component {\n' +
                            '  render() {\n' +
                            '    return <h1>Buna, {this.props.name}!</h1>;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'class Salut extends React.Component {\n' +
                            '  render() {\n' +
                            '    return <h1>Buna, {this.props.name}!</h1>;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Daca avem un fragment html mai complicat trebuie scris <b>return ([cod-similar-html])</b> cu <b>paranteze</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'class Salut extends React.Component {\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '       <div>\n' +
                            '          <h1 className="red">Buna, {this.props.name}!</h1>;\n' +
                            '       </div>\n' +
                            '    )\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Metoada <b>render()</b> este obligatorie.

                    <br/>
                    <br/>

                    Se foloseste in urmatorul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Salut name="KJ"/>'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            In loc de <b>class</b> se foloseste <b>className</b> pentru ca <b>class</b> este cuvant cheie in JavaScript!
                        </li>
                    </ul>

                    <hr/>
                    Exemplu componenta bazata pe functie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function Salut({ name }) {\n' +
                            '  return <h1>Buna, {name}!</h1>;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const Salut = ({ name }) => {\n' +
                            '  return <h1>Buna, {name}!</h1>;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatie 1:
                    <ul>
                        <li>
                           Componentele react sunt functii JavaScript, dar numele lor trebuie sa incepa cu litera mare!
                        </li>
                    </ul>

                    Observatie 2:
                    <ul>
                        <li>
                           Trebuie facut importul <b>import React from 'react';</b> (in caz contrar se va obtine eroare, codul nefiind interpretat ca JSX):
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'import React from \'react\';\n' +
                                    '\n' +
                                    'const Salut = ({ name }) => {\n' +
                                    '  return <h1>Buna, {name}!</h1>;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Migrare de la componente bazate pe clasa la componenta bazate pe functii:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Component } from \'react\';\n' +
                            '\n' +
                            'export default class Counter extends Component {\n' +
                            '  state = {\n' +
                            '    name: \'Taylor\',\n' +
                            '    age: 42,\n' +
                            '  };\n' +
                            '\n' +
                            '  handleNameChange = (e) => {\n' +
                            '    this.setState({\n' +
                            '      name: e.target.value\n' +
                            '    });\n' +
                            '  }\n' +
                            '\n' +
                            '  handleAgeChange = (e) => {\n' +
                            '    this.setState({\n' +
                            '      age: this.state.age + 1 \n' +
                            '    });\n' +
                            '  };\n' +
                            '\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <>\n' +
                            '        <input\n' +
                            '          value={this.state.name}\n' +
                            '          onChange={this.handleNameChange}\n' +
                            '        />\n' +
                            '        <button onClick={this.handleAgeChange}>\n' +
                            '          Increment age\n' +
                            '        </button>\n' +
                            '        <p>Hello, {this.state.name}. You are {this.state.age}.</p>\n' +
                            '      </>\n' +
                            '    );\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    1. De la clasa la functie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Component } from \'react\';\n' +
                            '\n' +
                            'export default class Counter extends Component {..}'}
                    </SyntaxHighlighter>
                    devine:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'export default function Counter() {..}'}
                    </SyntaxHighlighter>

                    <hr/>
                    2. Convertire variabile de stare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' state = {\n' +
                            '    name: \'Taylor\',\n' +
                            '    age: 42,\n' +
                            '  };'}
                    </SyntaxHighlighter>
                    devine:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'  const [name, setName] = useState(\'Taylor\');\n' +
                            '  const [age, setAge] = useState(42);'}
                    </SyntaxHighlighter>
                    cu adaugarea urmatorului import:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { useState } from \'react\';'}
                    </SyntaxHighlighter>

                    <hr/>

                    3. Convertire handlers:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'function handleNameChange(e) {\n' +
                            '    setName(e.target.value);\n' +
                            '  }'}
                    </SyntaxHighlighter>
                    devine:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'handleNameChange = (e) => {\n' +
                            '    this.setState({\n' +
                            '      name: e.target.value\n' +
                            '    });\n' +
                            '  }'}
                    </SyntaxHighlighter>

                    <hr/>

                    In final:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { useState } from \'react\';\n' +
                            '\n' +
                            'export default function Counter() {\n' +
                            '  const [name, setName] = useState(\'Taylor\');\n' +
                            '  const [age, setAge] = useState(42);\n' +
                            '\n' +
                            '  function handleNameChange(e) {\n' +
                            '    setName(e.target.value);\n' +
                            '  }\n' +
                            '\n' +
                            '  function handleAgeChange() {\n' +
                            '    setAge(age + 1);\n' +
                            '  }\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <input\n' +
                            '        value={name}\n' +
                            '        onChange={handleNameChange}\n' +
                            '      />\n' +
                            '      <button onClick={handleAgeChange}>\n' +
                            '        Increment age\n' +
                            '      </button>\n' +
                            '      <p>Hello, {name}. You are {age}.</p>\n' +
                            '    </>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Urmatoarele metode sunt apelate (in ordinea scrisa mai jos) cand o instanta a unui componente este creata si inserata in DOM:
                    <ul>
                        <li>
                            constructor()
                        </li>
                        <li>
                            componentWillMount()
                        </li>
                        <li>
                            render()
                        </li>
                        <li>
                            componentDidMount()
                        </li>
                    </ul>

                    O actualizare/update este declansat de schimbarea unui <b>props</b> sau <b>state</b>.
                    Urmatoarele metode sunt apelate cand o componenta este re-randata:
                    <ul>
                        <li>componentWillReceiveProps()</li>
                        <li>shouldComponentUpdate()</li>
                        <li>componentWillUpdate()</li>
                        <li>render()</li>
                        <li>componentDitUpdate()</li>
                    </ul>

                    Urmatoarea metoda este apelalta cand o componenta este stearsa din DOM:
                    <ul>
                        <li>
                            componentWillUnmount() {"=>"} se apeleaza de exemplu daca de exemplu pe o pagina avem o componenta si mergem in alta pagina
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ComponentReactContent;