import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class StringInterpolationAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-string-interpolation", IndexContent);
    }

    number = 7;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Text / String Interpolation</b>
                    <br/>
                    <br/>

                    Intre acolade duble se pot scrie:
                    <ul>
                        <li>siruri de caractere</li>
                        <li>apela functii (definite in ts)</li>
                        <li>variabile (definite in ts)</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{"String"}} sau {{getZiuaCurenta()}} sau {{nume}}'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{"String"} sau {getZiuaCurenta()} sau {nume}'}
                    </SyntaxHighlighter>

                    sau chiar <i>String interpolation</i> de JavaScript:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'`Numarul este: ${this.number}`'}
                    </SyntaxHighlighter>

                    Exemplu rulare:
                    <br/>
                    {`Numarul este: ${this.number}`}


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringInterpolationAngularContent;