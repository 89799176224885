import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class PodObjectK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-pod-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiectul Pod</b>
                    <br/>
                    <br/>

                    Acest tip de obiect:
                    <ul>
                        <li>controleaza un sau mai multe containere</li>
                        <li>de obicei, se creaza obiecte de tip Deployment, care gestioneaza si controleaza pod-urile</li>
                        <li>pod-urile au un IP intern; dar nu pot fi accesate, in mod implicit din afara cluster-ului</li>
                        <li>IP intern se modifica de fiecare data cand pod-ul este inlocuit</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: client-pod\n' +
                            '  labels:\n' +
                            '    component: web\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '    - name: client\n' +
                            '      image: iulianbuzdugan/multi-client\n' +
                            '      ports:\n' +
                            '        - containerPort: 3000'}
                    </SyntaxHighlighter>

                    Se pot atasa handle-re in ciclul de viata a unui container, in sectiunea <b>lifecycle</b> (cu <b>postStart/exec/command</b> sau <b>preStop/exec/command</b>)

                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: client-pod\n' +
                            '  labels:\n' +
                            '    component: web\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '    - name: client\n' +
                            '      image: nginx\n' +
                            '      lifecycle:\n' +
                            '        postStart: \n' +
                            '          exec: \n' +
                            '            command: ["/bin/sh","-c","echo Welcome! > /usr/share/poststart-msg"]' +
                            '        preStop: \n' +
                            '          exec: \n' +
                            '            command: ["usr/sbin/nginx","-s","quit"]'}
                    </SyntaxHighlighter>

                    De asemanea se poate stabili/suprascrie comanda si argumentele unui container:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: cmd-pod\n' +
                            '  labels:\n' +
                            '    component: web\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '    - name: cmd\n' +
                            '      image: debian\n' +
                            '      command: ["printenv"]\n' +
                            '      args: ["HOSTNAME", "KUBERNATES_PORT"]\n' +
                            '  restartPolicy: OnFailure'
                       }
                    </SyntaxHighlighter>

                    <hr/>
                    Pe langa Docker Hub, exista si alte repository-uri; de exemplu: <b>gcr.io</b> (Google Container Registry).
                    <br/>
                    Exemplu de imagine: <i>gcr.io/google-samble/node-hello:1.0</i>

                    <hr/>
                    La crearea unui container se pot seta detalii legate de resursele necesare (cerinte minime / maxime de memorie si CPU):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: db\n' +
                            '  labels:\n' +
                            '    component: web\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '    - name: db-container\n' +
                            '      image: mysql\n' +
                            '      resources: \n' +
                            '        requests: \n' +
                            '          memory: "64Mi" \n' +
                            '          cpu: "250m" \n' +
                            '        limits: \n' +
                            '          memory: "128Mi" \n' +
                            '          cpu: "500m"'
                        }
                    </SyntaxHighlighter>
                    Daca pod-ul nu poate oferi resursele necesare, containerul nu va porni in mod corespunzator si se va termina cu eroare.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PodObjectK8sContent;