import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class IpLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-ip", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru obtine informatii despre toate interfetele de retea de pe un calculator:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ip address'}
                    </SyntaxHighlighter>

                    Pentru obtine informatii despre tabela de rutare de pe calculator:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ip route'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'default via 192.168.1.1 dev ens3 proto dhcp metric 100 \n' +
                            '169.254.0.0/16 dev br-7c8e7915edf6 scope link metric 1000 \n' +
                            '172.17.0.0/16 dev docker0 proto kernel scope link src 172.17.0.1\n' +
                            '172.19.0.0/16 dev br-5d4729895d48 proto kernel scope link src 172.19.0.1 linkdown\n' +
                            '172.20.0.0/16 dev br-14875372bd61 proto kernel scope link src 172.20.0.1 linkdown\n' +
                            '172.21.0.0/16 dev br-7c8e7915edf6 proto kernel scope link src 172.21.0.1\n' +
                            '172.22.0.0/16 dev br-1a096f16a145 proto kernel scope link src 172.22.0.1 linkdown\n' +
                            '172.23.0.0/16 dev br-b486cab2c9bb proto kernel scope link src 172.23.0.1\n' +
                            '192.168.1.0/24 dev ens3 proto kernel scope link src 192.168.1.6 metric 100\n' +
                            '192.168.49.0/24 dev br-4d4450cdfa4d proto kernel scope link src 192.168.49.1\n'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IpLinuxContent;