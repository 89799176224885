import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SerializationPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-serialization", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Introducere</b>
                    <br/>
                    <br/>

                    <b>Serializarea</b> obiectelor este procesul de conversie a unei structuri de obiect într-un flux de octeți pentru a stoca obiectul într-un fișier sau bază de date sau pentru a-l transmite printr-o rețea.
                    Acest flux de octeți conține toate informațiile necesare pentru a reconstrui obiectul într-un alt script Python.

                    <br/>
                    Procesul invers se numește <b>deserializare</b>.

                    <hr/>
                    <b>2. Serializare cu pickle (cu persistare intr-un fisier)</b>
                    <br/>
                    <br/>

                    Pentru serializarea datelor/obiectelor se poate folosi modulul <b>pickle</b> (a mura).

                    <br/>

                    Tipuri ce pot fi serializate:
                    <ul>
                        <li>None</li>
                        <li>boolean</li>
                        <li>numere întregi, numere în virgulă mobilă, numere complexe </li>
                        <li>șiruri de caractere (strings), octeți (bytes), bytearrays</li>
                        <li>tupluri, liste, seturi și dicționare care conțin obiecte murabile</li>
                        <li>obiecte, inclusiv obiecte cu referințe la alte obiecte (de evitat ciclurile!)</li>
                        <li>referințe la funcții și clase, dar nu și definițiile acestora</li>
                    </ul>

                    Pentru operatia de serializare, folosind modulul pickle, trebuie:
                    <ul>
                        <li>
                            importat modul <b>pickle</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import pickle'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            deschis un fisier pentru scriere in mod binar (de scrie un flux de octeti)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'with open(\'fisierSerializare.pckl\', \'wb\') as f:'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            scriere propriu-zisa, folosind metoda <b>dump()</b> (permite persistarea unui <i>obiect</i> intru-un fisier - adica operatia de serializare)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'pickle.dump(obiect, f)'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>


                    <hr/>
                    <b>3. Deserializare cu pickle (cu citire dintr-un fisier)</b>
                    <br/>
                    <br/>

                    Pentru operatia de deserializare, folosind modulul pickle, trebuie:
                    <ul>
                        <li>
                            importat modul <b>pickle</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import pickle'}
                            </SyntaxHighlighter>

                        </li>


                        <li>
                            deschis un fisier cu obiectele serializate pentru citire in mod binar (de citeste un flux de octeti)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'with open(\'fisierSerializare.pckl\', \'rb\') as f:'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            citirea propriu-zisa, folosind metoda <b>load()</b> (citeste dintr-un fisier cu obecte serializate - adica operatia de deserializare)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'obiect = pickle.load(f)'}
                            </SyntaxHighlighter>

                            <b>observatie:</b>
                            <br/>
                            citirea datelor din fisierul cu obiecte serializate, <b>trebuie</b> sa se faca in <b>acceasi ordine</b> in care au fost persistate

                        </li>

                    </ul>

                    <hr/>
                    <b>4. Serializare / deserializare obiecte cu pickle</b>
                    <br/>
                    <br/>

                    Obiectele pot fi serializate pentru a fi stocate intr-o baza de date sau trimise prin retea. Pentru a realiza acest lucru modul <b>pickle</b> ofera urmatoarele metode:
                    <ul>
                        <li>bytes_obiect = <b>dumps</b>(obiect)</li>
                        <li>obiect = <b>loads</b>(bytes_obiect)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import pickle\n' +
                        '\n' +
                        'lista1 = [1,2,3]\n' +
                        'bytes = pickle.dumps(lista1)\n' +
                        'print(type(bytes)) # <class \'bytes\'>\n' +
                        '\n' +
                        'lista2 = pickle.loads(bytes) \n' +
                        'print(type(lista2)) # <class \'list\'>\n' +
                        'print(lista2) # [1, 2, 3]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Erori la serializare pentru modulul pickle</b>
                    <br/>
                    <br/>

                    In procesul de serializare/deserializare se pot arunca exceptii:
                    <ul>
                        <li><b>PicklingError</b>: daca se incearca serializarea unui obiect care nu se poate serializa</li>
                        <li><b>RecursionError</b>: daca se incearca serializarea unui structuri care prespune recursivitate si se depaseste adâncimea maximă de recursivitate</li>
                        <li><b>AtrributeError</b>: daca <i>functia/clasa</i> citita la deserializare nu este disponibila in spatiu de nume in momentul citirii fisierului serializat

                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import pickle\n' +
                                '\n' +
                                'def functie():\n' +
                                '    print(\'salut!\')\n' +
                                '\n' +
                                'with open(\'function.pckl\', \'wb\') as f:\n' +
                                '    pickle.dump(functie)'}
                            </SyntaxHighlighter>

                            Eroare la rularea urmatorului cod, pentru ca functia <i>functie</i> nu este disponibila in spatiul de numele in momentul deserializarii:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import pickle\n' +
                                '\n' +
                                'with open(\'function.pckl\', \'rb\') as f:\n' +
                                '    data = pickle.load(f)\n' +
                                '\n' +
                                'print(type(data))\n' +
                                'print(data)\n' +
                                'data()\n'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter>
                                {'Traceback (most recent call last):\n' +
                                '  File "main.py", line 4, in <module>\n' +
                                '    data = pickle.load(f)\n' +
                                'AttributeError: Can\'t get attribute \'functie\' on <module \'__main__\' from \'main.py\'>'}
                            </SyntaxHighlighter>

                            In schimb, urmatorul cod, va rula fara aruncarea vreunei erori:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import pickle\n' +
                                '\n' +
                                'def functie():\n' +
                                '    print(\'salut\')\n' +
                                '    \n' +
                                'with open(\'function.pckl\', \'rb\') as f:\n' +
                                '    data = pickle.load(f)\n' +
                                '\n' +
                                'print(type(data)) # <class \'function\'>\n' +
                                'print(data) # <function f1 at 0x7f6c83aa5200>\n' +
                                'data() # salut'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>5. Observatii pentru modulul pickle</b>
                    <br/>
                    <br/>

                    In procesul de serializare:
                    <ul>
                        <li>pentru o functie se va serializa doar numele acesteia (nu si argumentele sau corpul acesteia)</li>
                        <li>pentru o clasa se va serializa doar numele acesteia (si nu metodele sau atributele acesteia)</li>
                    </ul>

                    Modulul <b>pickle</b> :
                    <ul>
                        <li>evoluează constant, astfel încât formatul binar poate diferi între diverse versiuni de Python (deci partea de serializare si partea de deserializare ar trebui sa foloseasca acceasi versiune pickle).</li>
                        <li>nu este securizat împotriva datelor eronate sau construite cu răutate</li>
                        <li>foloseste un format propriu, deci nu poate fi folosit cu alte limbaje de programare (Java, C, etc)</li>
                    </ul>

                    <hr/>
                    <b>6.  Serializarea obiectelor folosind modulul shelve</b>
                    <br/>
                    <br/>

                    Serializare obiectelor folosind modulul <b>shelve</b> foloseste un <b>dicționar</b> de serializare în care <b>obiectele sunt serializate și asociate cu o cheie</b>.
                    <br/>
                    Cheile trebuie să fie șiruri obișnuite, deoarece baza de date de bază (dbm) necesită șiruri.
                    <br/>
                    Obiectele serializare dintr-un fisier serializat cu <b>shelve</b> pot fi accesate pe baza cheiilor.

                    <br/>
                    <br/>
                    Utilizarea modulului <b>shelve</b>:
                    <ul>
                        <li>
                            importat modul <b>shelve</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import shelve'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            deschiderea fisierului:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'fisier = shelve.open(\'fisier.shlv\', flag=\'flag\')'}
                            </SyntaxHighlighter>

                            Flag-uri posibile:
                            <ul>
                                <li> <b>r</b>: deschide baza de date existenta numai pentru citire</li>
                                <li> <b>w</b>: deschide baza de date existentă numai pentru citire și scriere</li>
                                <li> <b>c</b>: deschide baza de date pentru citire și scriere, creând o baza de date dacă nu există (aceasta este o valoare implicită)</li>
                                <li> <b>n</b>: deschide baza de date pentru citire și scriere, creand o baza de date noua, goala, de fiecare data</li>
                            </ul>

                        </li>

                        <li>
                            adaugare articole in baza de date

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'fisier["lista"]=[1,2,3]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            inchidere fisier (baza de date)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'fisier.close()'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            deschidere fisier pentru citire si accesare articol:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'fisier = shelve.open(nume_fisier)\n' +
                                'print(fisier["lista"])'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import shelve\n' +
                        '\n' +
                        'nume_fisier = \'fisier.shlv\'\n' +
                        '\n' +
                        'fisier1 = shelve.open(nume_fisier, flag=\'c\')\n' +
                        'fisier1[\'lista\'] = [1,2,3]\n' +
                        'fisier1.close()\n' +
                        '\n' +
                        'fisier2 = shelve.open(nume_fisier)\n' +
                        'print(fisier2[\'lista\']) # [1, 2, 3]\n' +
                        'fisier2.close()'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>cheile trebuie sa fie string-uri</li>
                        <li>Python pune modificarile intr-un buffer care este șters periodic pe disc (pentru a face flush imediat, trebuie apelelata metoda <b>sync()</b> de pe obiectul de tip <b>shelve</b>)</li>
                        <li>cand se apeleaza metoda <b>close()</b> pe un obiect de tip <b>shelve</b> se sterg si bufferele </li>
                        <li>obiectul shelve este la fel ca un dictionar, deci se poate folosi:
                            <ul>
                                <li>functia <b>len()</b></li>
                                <li>operatorul <b>in</b></li>
                                <li>metodele <b>keys()</b> si <b>items()</b></li>
                                <li>operatia de actualizare (update)</li>
                                <li>instructiunea <b>del</b></li>
                            </ul>
                        </li>
                        <li>
                            pentru a venii in sprijinul fisierului cu baza de date se mai creaza si alte fisiere extene (care nu trebuie modificate)
                        </li>
                    </ul>



                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SerializationPythonContent;