import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class RestParameterReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-rest-parameter", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Parametrul Rest (...)</b>
                    <br/>
                    <br/>

                    Exemplu (in parametrul r se retin restul de argumente):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const fn = (a, ...r)=>{\n' +
                            '\treturn a+r[0]+r[1];\n' +
                            '}\n' +
                            '\n' +
                            'console.log(fn(1,2,3));'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'6'}
                    </SyntaxHighlighter>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RestParameterReactContent;