import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerComposeEnvironmentContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-compose-environment", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker compose - environment </b>
                    <br/>
                    <br/>

                    Se pot specifica variabile de mediu prin parametrul <b>environment</b>, ca in exemplul de mai jos:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3.7\'\n' +
                            '\n' +
                            'services:\n' +
                            '\n' +
                            '  numeProiect:\n' +
                            '    container_name: numeProiect\n' +
                            '    build:\n' +
                            '      context: .\n' +
                            '      dockerfile: Dockerfile\n' +
                            '    volumes:\n' +
                            '      - \'.:/app\'\n' +
                            '      - \'/app/node_modules\'\n' +
                            '    ports:\n' +
                            '      - 3001:3000\n' +
                            '    environment:\n' +
                            '      - CHOKIDAR_USEPOLLING=true'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerComposeEnvironmentContent;