import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WithPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-with", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Instructiunea with</b>
                    <br/>
                    <br/>
                    Instructiunea <b>with</b> este folosită în gestionarea excepțiilor pentru a face codul mai curat și mult mai lizibil.
                    Simplifică gestionarea resurselor comune, cum ar fi fluxurile de fișiere.

                    <br/>

                    De exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# 1) fara instructiunea with\n' +
                        'file = open(\'file_path\', \'w\')\n' +
                        'file.write(\'text\')\n' +
                        'file.close()\n' +
                        '  \n' +
                        '# 2) fara instructiunea with\n' +
                        'file = open(\'file_path\', \'w\')\n' +
                        'try:\n' +
                        '    file.write(\'text\')\n' +
                        'finally:\n' +
                        '    file.close()\n' +
                        '    \n' +
                        '# cu instructiunea with\n' +
                        'with open(\'file_path\', \'w\') as file:\n' +
                        '    file.write(\'text\')'}
                    </SyntaxHighlighter>

                    Folosind instructiunea <b>with</b>:
                    <ul>
                        <li>nu este nevoie să apelați <b>file.close()</b></li>
                        <li>asigura eliberarea corecta a resurselor</li>
                    </ul>

                    Pentru a utiliza instructiunea <b>with</b> în obiecte definite de utilizator, atunci trebuie sa existe urmatoarele metode:
                    <ul>
                        <li><b>__enter__()</b>:  inițializeaza resursa (de exemplu, se apleaza functia <i>open()</i>) si returneaza resursa</li>
                        <li><b>__exit__()</b>: eliberare resurse (de exemplu, se apleaza metoda <i>close()</i>)</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class MessageWriter(object):\n' +
                        '    def __init__(self, file_name):\n' +
                        '        self.file_name = file_name\n' +
                        '      \n' +
                        '    def __enter__(self):\n' +
                        '        self.file = open(self.file_name, \'w\')\n' +
                        '        return self.file\n' +
                        '  \n' +
                        '    def __exit__(self):\n' +
                        '        self.file.close()\n' +
                        '  \n' +
                        'with MessageWriter(\'file.txt\') as f:\n' +
                        '    f.write(\'text\')'}
                    </SyntaxHighlighter>

                    O interfata care ofera suport pentru instructiunea <b>with</b> (adica are metodele <b>__enter()__ si __exit__()</b>) se numeste <b>Context Manager</b>.

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class ContextManager():\n' +
                        '    def __init__(self):\n' +
                        '        print(\'init\')\n' +
                        '          \n' +
                        '    def __enter__(self):\n' +
                        '        print(\'enter\')\n' +
                        '        return self\n' +
                        '      \n' +
                        '    def __exit__(self, exc_type, exc_value, exc_traceback):\n' +
                        '        print(\'exit\')\n' +
                        '  \n' +
                        '  \n' +
                        'with ContextManager() as manager:\n' +
                        '    print(\'block instructiune with\')'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Modulul contextlib</b>
                    <br/>
                    <br/>

                    O alta modalitate de a sustine instructiunea <b>with</b> este oferit de modulul <b>contextlib</b>.

                    <br/>
                    Astfel, Context Manager de mai sus poate fi rescris asa:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from contextlib import contextmanager\n' +
                        '  \n' +
                        'class MessageWriter(object):\n' +
                        '    def __init__(self, filename):\n' +
                        '        self.file_name = filename\n' +
                        '  \n' +
                        '    @contextmanager\n' +
                        '    def open_file(self):\n' +
                        '        try:\n' +
                        '            file = open(self.file_name, \'w\')\n' +
                        '            yield file\n' +
                        '        finally:\n' +
                        '            file.close()\n' +
                        '  \n' +
                        '# usage\n' +
                        'message_writer = MessageWriter(\'hello.txt\')\n' +
                        'with message_writer.open_file() as f:\n' +
                        '    f.write(\'text\')'}
                    </SyntaxHighlighter>

                    In exemplul de mai sus, datorita instructiunii <b>yield</b>, metoda <i>open_file()</i> este o <b>funcție generatoare</b>.<br/>

                    Când metoda <i>open_file()</i> este apelată, se creează un descriptor de resurse numit <i>file</i>. <br/>
                    Acest descriptor de resursă este returnat și este reprezentat de variabila <i>f</i>. <br/>
                    După ce codul din interiorul blocului <b>with</b> este executat, controlul programului revine la metoda <i>open_file()</i>. <br/>
                    Metoda <i>open_file()</i> reia executarea programului si executa codul dupa instructiunea <b>yield</b>.<br/>
                    Codul care apare dupa instructiunea <b>yield</b> elibereaza resursele.<br/>

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <di>
                                <a href={"https://www.geeksforgeeks.org/with-statement-in-python/"}>https://www.geeksforgeeks.org/with-statement-in-python/</a>
                            </di>
                        </li>

                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WithPythonContent;