import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DebuggingJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-debugging", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Instructiunea debugger</b>
                    <br/>
                    <br/>
                    Instrucțiunea <b>debugger</b>, verifică dacă browser-ul are instrument de depanare și, dacă da, execuția codului este oprită exact în acel punct.
                    <br/>
                    <br/>

                    Una dintre principalele caracteristici ale depanatorului este capacitatea sa de a <b>executa cod pas cu pas</b>.
                    Aceasta înseamnă că putem opri execuția programului în orice loc folosind o instrucțiune de depanare (debugger) și apoi putem continua execuția instrucțiune cu instrucțiune.
                    <br/>
                    De obicei, fiecare browser, acceptă următoarele opțiuni pentru a controla execuția scriptului în modul de depanare:
                    <ul>
                        <li><b>Resume / Continue (F8)</b>: va relua execuția scriptului in mod normal (se iese din modul debug);
                            <br/>
                            folosirea Resume nu determină neapărat executarea programului până la finalizare; putem indica unde ar trebui să se oprească din nou, punand <b>breakpoint</b>-uri;
                        </li>
                        <li><b>Step Into (F9)</b>: execută următoarea instrucțiune si se opreste; dacă următoarea instrucțiune este un apel de funcție, folosind Step Into va intra în codul acestei funcții.</li>
                        <li><b>Step Over (F10 / sageata arcuita cu punct sub ea)</b>: funcționează ca Step Into, cu excepția faptului că, dacă următoarea instrucțiune este un apel de funcție, codul nu va sări în codul funcției, ci întreaga funcție va fi executată și programul va fi întrerupt din nou după ce a revenit din această funcție</li>
                        <li><b>Step Out</b>: permite să ieșim imediat dintr-o funcție în care codul este întrerupt; (reia funcționarea programului prin executarea comenzilor succesive până când funcția curentă se termina si se revine la funcția de la care a fost apelată)</li>
                    </ul>

                    <i>Observatie</i>:
                    <br/>
                    Atunci când depanăm codul, folosim rar instrucțiunea <b>debugger</b>, ci ne folosim de <b>breakpoint-uri</b>.

                    <hr/>
                    <b>2. Sectiunea Call Stack</b>
                    <br/>
                    <br/>
                    Un alt instrument este Call Stack (stiva de apeluri). Intr-o sectiune a instrumentului de depanare (numita de obicei Call Stack), putem vedea în ce funcție ne aflăm la un moment dat, precum si de unde a fost apelata.
                    În partea de jos a stivei, vom vedea funcția principală (nu are un nume, iar în Firefox este marcată ca (global) și în Chrome (anonim)).
                    <br/>
                    Fiind cu oprit intr-un breakpoint, putem avea acces la contextul functiei (nume de variabile) si chiar le putem modifica.

                    <hr/>
                    <b>3. Sectiunea Watch</b>
                    <br/>
                    <br/>
                    In aceasta sectiune putem evalua expresii, vizualiza sau modifica variabile. Adaugarea unei noi valori pe care vrem s-o urmarim se face folosind butonul (+).

                    <hr/>
                    <b>4. Măsurarea timpului de execuție a codului</b>
                    <br/>
                    <br/>

                    Pentru măsurarea timpului de execuție a codului se pot folosi metodele:
                    <ul>
                        <li>console.time</li>
                        <li>console.timeEnd</li>
                    </ul>
                    Cele doua metode permit efectuarea măsurararii precise a timpului dintre două locuri specificate în codul nostru și să afișăm rezultatul pe consolă.
                    <br/>
                    În apelurile metodelor console.time și console.timeEnd, putem specifica un șir care ne va identifica cronometrul în cazul în care vom folosi multe dintre ele în programul nostru.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'console.time(\'metoda-cosumatare-de-timp\');\n' +
                        'let a=2; // glumesc\n' +
                        'console.timeEnd(\'metoda-cosumatare-de-timp\'); // metoda-cosumatare-de-timp: 1ms'}
                    </SyntaxHighlighter>

                    Folosirea metodelor console.time și console.timeEnd permite să ne analizăm codul pentru performanță.
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DebuggingJavaScriptContent;