import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class NgSelectAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-ng-select", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Instalare</b>
                    <br/>
                    <br/>


                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install --save @ng-select/ng-select'}
                    </SyntaxHighlighter>

                    Import modul <b>NgSelectModule</b> si <b>FormsModule</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { NgSelectModule } from \'@ng-select/ng-select\';\n' +
                            'import { FormsModule } from \'@angular/forms\';\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  declarations: [AppComponent],\n' +
                            '  imports: [NgSelectModule, FormsModule],\n' +
                            '  bootstrap: [AppComponent]\n' +
                            '})\n' +
                            'export class AppModule {}'}
                    </SyntaxHighlighter>

                    Includere tema in .scss proiectului:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'@import "~@ng-select/ng-select/themes/default.theme.css";'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Mod de folosire</b>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<ng-select [items]="userCollection | async"\n' +
                            '           [selectOnTab]="true"\n' +
                            '           bindValue="cnp"\n' +
                            '           bindLabel="nume"\n' +
                            '           labelForId="user"\n' +
                            '           placeholder="Selecteaza user"\n' +
                            '           formControlName="user">\n' +
                            '</ng-select>'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <SyntaxHighlighter>
                                {'userCollection?: Observable<User[]>;'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter>
                                {'ngOnInit(): void {\n' +
                                    '\n' +
                                    '  this.userCollection = this.loadUsersAsync();\n' +
                                    '\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter>
                                {'protected loadUsersAsync(): Observable<User[]> {\n' +
                                    '  return this.userService\n' +
                                    '    .loadUsers()\n' +
                                    '    .pipe(map((res: HttpResponse<User[]>) => (res.body ? res.body : [])));\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://ng-select.github.io/ng-select#/data-sources"}>ng-select</a>
                        </li>
                        <li>
                            <a target={"_blank"} href={"https://github.com/ng-select/ng-select"}>ng-select / ng-select (github)</a>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NgSelectAngularContent;