import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ObiectiveVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-obiective", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiective si perspectiva</b>
                    <br/>

                    Obiectivele altereaza perspectiva:
                    <ul>
                        <li>obiectele pot sa para mai departe unele de altele</li>
                        <li>altera formele</li>
                    </ul>
                    Un obiectiv este format dintr-un set de lentile care directioneaza lumina din punctul in care proiectata sau reflectata pana la senzor.
                    <br/>
                    Lentile bune ajuta la:
                    <ul>
                        <li>evitarea <i>aberatiilor cromatice</i> (frecventele luminii nu se intalnesc in acelasi loc)</li>
                        <li>protejarea imaginii de distorsiuni (prezenta la focale foarte scurte, de exemplu: 16mm)</li>
                    </ul>

                    <b>Distanta focala</b> = distanta de la centrul optic al obiectivului pana la senzor, cand focus-ul obiectivului este setata la infinit;
                    Cu cat aceasta distanta focala este mai mica (scurta) cu atat obiectivul este mai wide, are un camp de profunzime mare si o magnificare mai mica.

                    <br/>
                    Distante focale:
                    <ul>
                        <li>
                            fisheye (8-16mm)
                            <ul>
                                <li>unghi foarte mare</li>
                                <li>distorsie maxima care acopera intreg cadrul</li>
                            </ul>
                        </li>
                        <li>
                            ultrawide (16-24mm)
                            <ul>
                                <li>unghi mare</li>
                                <li>distorsie pronuntata (un personaj pe centrul cadrului, nu va fi afectat de distorsie asa tare)</li>
                            </ul>
                        </li>
                        <li>scurte (24-40mm) / Wide
                            <ul>
                                <li>unghi mare</li>
                                <li>distorsie medie - minima</li>
                            </ul>
                        </li>
                        <li>medii (40-60mm):
                            <ul>
                                <li>
                                    <li>unghi comprabil cu ochiul uman</li>
                                    <li>distorsie - minima</li>
                                </li>
                            </ul>
                        </li>
                        <li>
                            tele (70-200mm)
                            <ul>
                                <li>
                                    <li>unghi de cuprindere care se ingusteaza progresiv</li>
                                    <li>fara distorsie</li>
                                    <li>pentru prim-planuri/detalii/actiuni sportive</li>
                                </li>
                            </ul>
                        </li>

                        <li>
                            supertele (+200mm)
                            <ul>
                                <li>
                                    <li>unghi de cuprindere care se ingusteaza progresiv</li>
                                    <li>fara distorsie</li>
                                    <li>pentru documentare (pentru a nu invada spatiu faunei locale)</li>
                                </li>
                            </ul>
                        </li>

                    </ul>

                    Obiective:
                    <ul>
                        <li><b>fixe / prime</b>: au o singura distanta focala (ex: 50mm, 85mm, etc)</li>
                        <li><b>zoom</b>: au distante focale variabile (ex: 70-300mm, etc);
                            <br/>
                            unele obiective de tip zoom au <i>diafragme variabila</i> in functie de distanta focala, afectand deschiderea maxima a obiectivului pe masura ce urcam distanta focala;
                            (ex: obiectiv 18-55mm, cu diafragma de la f/3.5-5.6)
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Compresia</b>
                    <br/>

                    Obiectele aflate in planuri diferite de-a lungul perspectivei:
                    <ul>
                        <li>
                            cu obiective cu <i>distanta focale mai scurta</i> vor <i>parea mai indepartate</i> unele de altele
                        </li>
                        <li>
                            cu obiective cu <i>distanta focale mai lunga</i> vor <i>parea mai aproape</i> unele de altele (distanta pare mai scurta, mai compresata)
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Varifocal vs. Parfocal</b>
                    <br/>

                    La un obiectiv varifocal, daca setam punctul de focus intr-un anumit plan si apoi facem zoom, atunci punctul de focus se misca pe masura ce executam zoom-ul.
                    <br/>
                    La un obiectiv parfocal, daca setam punctul de focus intr-un anumit plan si apoi facem zoom, atunci punctul de focus nu se misca pe masura ce executam zoom-ul.
                    <br/>
                    Exemple de obiective parfocale: ENG si CINE
                    <br/>
                    Obiectivele ENS si CINE sunt <b>de-clicked</b>, avand inelul de control al diafragmei direct pe obiectiv si poti fi folosite in timpul inregistrarii fara a avea un efect evident (ca in cazul obiectivelor clicked) asupra imaginii.


                    <hr/>
                    <b>4. Anamorfic</b>
                    <br/>

                    Obiectivele pot fi:
                    <ul>
                        <li>sferice</li>
                        <li>anamorfice (obiective in sine sau adaptoare pentru obiectivele sferice);
                            <br/>
                            materialul in format anamorfic, in post-productie, trece printr-un proces de <i>de-squeeze</i> (de 2.0x sau 1.33x, in functie de obiectivul/adaptorul folosit)
                        </li>
                    </ul>

                    <b>5. Formate</b>
                    <br/>

                    Formate:
                    <ul>
                        <li>4:3 (format clasic de televiziune)</li>
                        <li>16:9 (format standard produs de aparatele de format); atribut rezolutiei HD - 4k, standardul televizoarelor moderne</li>
                        <li>2.39:1 (format widescreen - anamorfic 2.0x)</li>
                        <li>1.185:1 (format widescreen - anamorfic 1.33x)</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ObiectiveVideografieContent;