import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class FileLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-file", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a obtine detalii despre un fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'file arhiva.tar.gz'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'arhiva.tar.gz: gzip compressed data, was "arhiva.tar", last modified: Tue Dec 13 11:26:29 2022, from Unix, original size modulo 2^32 10240'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'file a.txt'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'a.txt: ASCII text'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FileLinuxContent;