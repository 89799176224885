import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CustomFieldLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-custom-field", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    package.json:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "dependencies": {\n' +
                            '  },\n' +
                            '  "devDependencies": {\n' +
                            '    "@babel/cli": "^7.14.3",\n' +
                            '    "@babel/preset-react": "^7.13.13",\n' +
                            '    "@liferay/portal-7.4": "*"\n' +
                            '  },\n' +
                            '  "main": "./",\n' +
                            '  "name": "dynamic-data-mapping-form-field-type-increment",\n' +
                            '  "scripts": {\n' +
                            '    "build": "npm run build-js && liferay-npm-bundler",\n' +
                            '    "build-js": "babel --source-maps -d build/resources/main/META-INF/resources src/main/resources/META-INF/resources"\n' +
                            '  },\n' +
                            '  "version": "1.0.0"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: Increment Form Field Implementation\n' +
                            'Bundle-SymbolicName: ro.ibrid.custom.field.increment\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Web-ContextPath: /dynamic-data-mapping-form-field-type-increment'}
                    </SyntaxHighlighter>

                    .npmbundlerrc:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {
                            '{\n' +
                            '    "config": {\n' +
                            '\t\t"imports": {\n' +
                            '\t\t\t"dynamic-data-mapping-form-field-type": {\n' +
                            '\t\t\t\t"/": ">=4.0.25"\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t},\n' +
                            '\t"output": "build/resources/main/META-INF/resources"\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    .babelrc.js:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'module.exports = {\n' +
                            '\tpresets: [\n' +
                            '\t\t[\n' +
                            '\t\t\t\'@babel/preset-env\',\n' +
                            '\t\t\t{\n' +
                            '\t\t\t\ttargets: \'defaults\',\n' +
                            '\t\t\t},\n' +
                            '\t\t],\n' +
                            '\t\t[\'@babel/preset-react\'],\n' +
                            '\t],\n' +
                            '};\n'}
                    </SyntaxHighlighter>


                    IncrementDDMFormFieldType:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.custom.field.increment.internal.dynamic.data.mapping.form.field.type;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.BaseDDMFormFieldType;\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.DDMFormFieldType;\n' +
                            'import com.liferay.frontend.js.loader.modules.extender.npm.NPMResolver;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            '\n' +
                            '@Component(\n' +
                            '\tproperty = {\n' +
                            '\t\t"ddm.form.field.type.description=increment-description",\n' +
                            '\t\t"ddm.form.field.type.display.order:Integer=10",\n' +
                            '\t\t"ddm.form.field.type.group=customized", "ddm.form.field.type.icon=text",\n' +
                            '\t\t"ddm.form.field.type.label=increment-label",\n' +
                            '\t\t"ddm.form.field.type.name=increment"\n' +
                            '\t},\n' +
                            '\tservice = DDMFormFieldType.class\n' +
                            ')\n' +
                            'public class IncrementDDMFormFieldType extends BaseDDMFormFieldType {\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getModuleName() {\n' +
                            '\t\treturn _npmResolver.resolveModuleName(\n' +
                            '\t\t\t"dynamic-data-mapping-form-field-type-increment/increment/Increment.es");\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getName() {\n' +
                            '\t\treturn "increment";\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic boolean isCustomDDMFormFieldType() {\n' +
                            '\t\treturn true;\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Reference\n' +
                            '\tprivate NPMResolver _npmResolver;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Language.properties (in resources/content):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'increment-description=incrementeaza\n' +
                            'increment-label=increment'}
                    </SyntaxHighlighter>


                    Increment.es.js (in resources/META-INF/resources/increment):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import {FieldBase} from \'dynamic-data-mapping-form-field-type/FieldBase/ReactFieldBase.es\';\n' +
                            'import {useSyncValue} from \'dynamic-data-mapping-form-field-type/hooks/useSyncValue.es\';\n' +
                            'import React,  {useEffect} from \'react\';\n' +
                            '\n' +
                            'const Increment = ({name, onChange, predefinedValue, readOnly, value}) => {\n' +
                            '\n' +
                            '\tconst [taxa, setTaxa ]= useSyncValue(value);\n' +
                            '\n' +
                            '\tconst onClickButton = () =>{\n' +
                            '\t\tsetTaxa((+taxa)+1)\n' +
                            '\t}\n' +
                            '\n' +
                            '\treturn <div>\n' +
                            '\t\t<input\n' +
                            '\t\t\tclassName="form-control"\n' +
                            '\t\t\tdisabled={readOnly}\n' +
                            '\t\t\tid="increment"\n' +
                            '\t\t\tname={name}\n' +
                            '\t\t\tonInput={onChange}\n' +
                            '\t\t\ttype="number"\n' +
                            '\t\t\tvalue={value ? value : predefinedValue}\n' +
                            '\t\t/>\n' +
                            '\t\t<div className={"btn btn-primary"} onClick={onClickButton}>Incrementeaza</div>\n' +
                            '\t\t<span>{taxa}</span>\n' +
                            '\t</div>\n' +
                            '};\n' +
                            '\n' +
                            'const Main = ({\n' +
                            '\tlabel,\n' +
                            '\tname,\n' +
                            '\tonChange,\n' +
                            '\tpredefinedValue,\n' +
                            '\treadOnly,\n' +
                            '\tvalue,\n' +
                            '\t...otherProps\n' +
                            '}) => {\n' +
                            '\tconst [currentValue, setCurrentValue] = useSyncValue(\n' +
                            '\t\tvalue ? value : predefinedValue\n' +
                            '\t);\n' +
                            '\n' +
                            '\treturn (\n' +
                            '\t\t<FieldBase\n' +
                            '\t\t\tlabel={label}\n' +
                            '\t\t\tname={name}\n' +
                            '\t\t\tpredefinedValue={predefinedValue}\n' +
                            '\t\t\t{...otherProps}\n' +
                            '\t\t>\n' +
                            '\t\t\t<Increment\n' +
                            '\t\t\t\tname={name}\n' +
                            '\t\t\t\tonChange={(event) => {\n' +
                            '\t\t\t\t\tsetCurrentValue(event.target.value);\n' +
                            '\t\t\t\t\tonChange(event);\n' +
                            '\t\t\t\t}}\n' +
                            '\t\t\t\tpredefinedValue={predefinedValue}\n' +
                            '\t\t\t\treadOnly={readOnly}\n' +
                            '\t\t\t\tvalue={currentValue}\n' +
                            '\t\t\t/>\n' +
                            '\t\t</FieldBase>\n' +
                            '\t);\n' +
                            '};\n' +
                            '\n' +
                            'Main.displayName = \'Increment\';\n' +
                            '\n' +
                            'export default Main;\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    Sa prespunem ca vrem sa mai avem in tab-ul de proprietati si <b>Field Reference</b> / <b>fieldReference</b>
                    (in mod implicit nu exista).
                    <br/>
                    Pentru a face acest lucru se creeaza interfata <b>IncrementDDMFieldTypeSettings</b> care va extinde <b>DefaultDDMFormFieldTypeSettings</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.custom.field.dynamic.data.mapping.form.field.type.increment;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.annotations.*;\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.DefaultDDMFormFieldTypeSettings;\n' +
                            '\n' +
                            '@DDMForm\n' +
                            '@DDMFormLayout(\n' +
                            '        paginationMode = com.liferay.dynamic.data.mapping.model.DDMFormLayout.TABBED_MODE,\n' +
                            '        value = {\n' +
                            '                @DDMFormLayoutPage(\n' +
                            '                        title = "%basic",\n' +
                            '                        value = {\n' +
                            '                                @DDMFormLayoutRow(\n' +
                            '                                        {\n' +
                            '                                                @DDMFormLayoutColumn(\n' +
                            '                                                        size = 12,\n' +
                            '                                                        value = {\n' +
                            '                                                                "label", "predefinedValue", "required", "tip"\n' +
                            '                                                        }\n' +
                            '                                                )\n' +
                            '                                        }\n' +
                            '                                )\n' +
                            '                        }\n' +
                            '                ),\n' +
                            '                @DDMFormLayoutPage(\n' +
                            '                        title = "%properties",\n' +
                            '                        value = {\n' +
                            '                                @DDMFormLayoutRow(\n' +
                            '                                        {\n' +
                            '                                                @DDMFormLayoutColumn(\n' +
                            '                                                        size = 12,\n' +
                            '                                                        value = {\n' +
                            '                                                                "fieldReference", "dataType", "name", "showLabel", "repeatable",\n' +
                            '                                                                "type", "validation", "visibilityExpression",\n' +
                            '                                                                "visualProperty", "objectFieldName"\n' +
                            '                                                        }\n' +
                            '                                                )\n' +
                            '                                        }\n' +
                            '                                )\n' +
                            '                        }\n' +
                            '                )\n' +
                            '        }\n' +
                            ')\n' +
                            'public interface IncrementDDMFieldTypeSettings extends DefaultDDMFormFieldTypeSettings {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Actualizam <b>IncrementDDMFormFieldType</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.custom.field.dynamic.data.mapping.form.field.type.increment;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.BaseDDMFormFieldType;\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.DDMFormFieldType;\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.DDMFormFieldTypeSettings;\n' +
                            'import com.liferay.frontend.js.loader.modules.extender.npm.NPMResolver;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            '\n' +
                            '@Component(\n' +
                            '\tproperty = {\n' +
                            '\t\t"ddm.form.field.type.description=increment-description",\n' +
                            '\t\t"ddm.form.field.type.display.order:Integer=10",\n' +
                            '\t\t"ddm.form.field.type.group=customized", "ddm.form.field.type.icon=text",\n' +
                            '\t\t"ddm.form.field.type.label=increment-label",\n' +
                            '\t\t"ddm.form.field.type.name=increment"\n' +
                            '\t},\n' +
                            '\tservice = DDMFormFieldType.class\n' +
                            ')\n' +
                            'public class IncrementDDMFormFieldType extends BaseDDMFormFieldType {\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getModuleName() {\n' +
                            '\t\treturn _npmResolver.resolveModuleName(\n' +
                            '\t\t\t"dynamic-data-mapping-form-field-type-increment/increment/Increment.es");\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getName() {\n' +
                            '\t\treturn "increment";\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic boolean isCustomDDMFormFieldType() {\n' +
                            '\t\treturn true;\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic Class<? extends DDMFormFieldTypeSettings>\n' +
                            '\tgetDDMFormFieldTypeSettings() {\n' +
                            '\n' +
                            '\t\treturn IncrementDDMFieldTypeSettings.class;\n' +
                            '\t}\n' +
                            '\n' +
                            '\n' +
                            '\t@Reference\n' +
                            '\tprivate NPMResolver _npmResolver;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Adica, se suprascrie metoda <b>getDDMFormFieldTypeSettings</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                            '\tpublic Class<? extends DDMFormFieldTypeSettings>\n' +
                            '\tgetDDMFormFieldTypeSettings() {\n' +
                            '\n' +
                            '\t\treturn IncrementDDMFieldTypeSettings.class;\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Vezi aici:
                    <a target={"_blank"} href={"https://github.com/liferay/liferay-portal/blob/7.4.3.89-ga89/modules/apps/dynamic-data-mapping/dynamic-data-mapping-form-field-type/src/main/resources/META-INF/resources/Separator/Separator.es.js"}>
                        modules/apps/dynamic-data-mapping/dynamic-data-mapping-form-field-type/src/main/resources/META-INF/resources/Separator/Separator.es.js
                    </a>

                </div>




                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default CustomFieldLiferayContent;