import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class GeneratedCodeJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-jhipster-folder-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: .jhipster</b>
                    <br/>
                    <br/>

                    In directorul <b>.jhipster</b> s-au generat urmatoarele fisierele JSON:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'Customer.json\n' +
                            'Invoice.json\n' +
                            'OrderItem.json\n' +
                            'Product.json\n' +
                            'ProductCategory.json\n' +
                            'ProductOrder.json\n' +
                            'Shipment.json'}
                    </SyntaxHighlighter>

                    Aceste fisiere sunt folosite de JHipster pentru a regenera și edita o entitate atunci când este necesar.

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>Product.json</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "applications": "*",\n' +
                            '  "changelogDate": "20230506210803",\n' +
                            '  "dto": "no",\n' +
                            '  "embedded": false,\n' +
                            '  "entityTableName": "product",\n' +
                            '  "fields": [\n' +
                            '    {\n' +
                            '      "fieldName": "name",\n' +
                            '      "fieldType": "String",\n' +
                            '      "fieldValidateRules": ["required"]\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "fieldName": "description",\n' +
                            '      "fieldType": "String"\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "fieldName": "price",\n' +
                            '      "fieldType": "BigDecimal",\n' +
                            '      "fieldValidateRules": ["required", "min"],\n' +
                            '      "fieldValidateRulesMin": "0"\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "fieldName": "sizeProduct",\n' +
                            '      "fieldType": "Size",\n' +
                            '      "fieldValidateRules": ["required"],\n' +
                            '      "fieldValues": "S,M,L,XL,XXL"\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "fieldName": "image",\n' +
                            '      "fieldType": "byte[]",\n' +
                            '      "fieldTypeBlobContent": "image"\n' +
                            '    }\n' +
                            '  ],\n' +
                            '  "fluentMethods": true,\n' +
                            '  "javadoc": "Product sold by the Online store",\n' +
                            '  "jpaMetamodelFiltering": false,\n' +
                            '  "name": "Product",\n' +
                            '  "pagination": "no",\n' +
                            '  "readOnly": false,\n' +
                            '  "relationships": [\n' +
                            '    {\n' +
                            '      "otherEntityName": "productCategory",\n' +
                            '      "otherEntityRelationshipName": "product",\n' +
                            '      "relationshipName": "productCategory",\n' +
                            '      "relationshipType": "many-to-one"\n' +
                            '    }\n' +
                            '  ],\n' +
                            '  "service": "no"\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    De exemplu, continutul fisierului <i>ProductCategory.json</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "applications": "*",\n' +
                            '  "changelogDate": "20230506210804",\n' +
                            '  "dto": "no",\n' +
                            '  "embedded": false,\n' +
                            '  "entityTableName": "product_category",\n' +
                            '  "fields": [\n' +
                            '    {\n' +
                            '      "fieldName": "name",\n' +
                            '      "fieldType": "String",\n' +
                            '      "fieldValidateRules": ["required"]\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "fieldName": "description",\n' +
                            '      "fieldType": "String"\n' +
                            '    }\n' +
                            '  ],\n' +
                            '  "fluentMethods": true,\n' +
                            '  "jpaMetamodelFiltering": false,\n' +
                            '  "name": "ProductCategory",\n' +
                            '  "pagination": "no",\n' +
                            '  "readOnly": false,\n' +
                            '  "relationships": [\n' +
                            '    {\n' +
                            '      "otherEntityName": "product",\n' +
                            '      "otherEntityRelationshipName": "productCategory",\n' +
                            '      "relationshipName": "product",\n' +
                            '      "relationshipType": "one-to-many"\n' +
                            '    }\n' +
                            '  ],\n' +
                            '  "service": "no"\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratedCodeJdlJHipsterContent;