import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"linux-basics", url:"#", title:"Notiuni Linux", subtitle:""},
        {id:"linux-stream", url:"/linux/stream", title:"Stream-urile unui proces", subtitle:"", parent:"linux-basics"},
        {id:"linux-piping", url:"/linux/piping", title:"Piping", subtitle:"", parent:"linux-basics"},
        {id:"linux-hard-soft-links", url:"/linux/hard-soft-links", title:"Link-uri soft si hard", subtitle:"", parent:"linux-basics"},
        {id:"linux-script", url:"/linux/script", title:"Fisiere executabile", subtitle:"", parent:"linux-basics"},

        {id:"linux-manage-file-dir-root", url:"#", title:"Gestiune fisiere si directoare in Linux", subtitle:""},
        {id:"linux-pwd", url:"/linux/pwd", title:"Afisare director curent: pwd (print working directory)", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-cd", url:"/linux/cd", title:"Schimbare director curent cd (change directory)", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-ls", url:"/linux/ls", title:"Listare fisire si directoare. Sortare: ls", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-mkdir", url:"/linux/mkdir", title:"Creare director: mkdir", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-rm", url:"/linux/rm", title:"Stergere fisier/director: rm", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-cat", url:"/linux/cat", title:"Afisare continut fisier: cat", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-head", url:"/linux/head", title:"Afisare primele linii dintr-un fisier: head", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-tail", url:"/linux/tail", title:"Afisare ultimele linii dintr-un fisier: tail", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-more", url:"/linux/more", title:"Afisare paginat fisier: more", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-grep", url:"/linux/grep", title:"Filtrare: grep", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-touch", url:"/linux/touch", title:"Creare fisier: touch", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-vim", url:"/linux/vim", title:"Editare fisier: vim", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-nano", url:"/linux/nano", title:"Editare fisier: nano", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-cp", url:"/linux/cp", title:"Copiere fisier/director: cp", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-mv", url:"/linux/mv", title:"Redenumire/mutare fisier/director: mv", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-ln", url:"/linux/ln", title:"Creare soft/hard link: ln", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-find", url:"/linux/find", title:"Cautare: find", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-file", url:"/linux/file", title:"Detalii fisier: file", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-sort", url:"/linux/sort", title:"Sortare continut fisier: sort", subtitle:"", parent:"linux-manage-file-dir-root"},
        {id:"linux-du", url:"/linux/du", title:"Dimeniune fisier: du", subtitle:"", parent:"linux-manage-file-dir-root"},

        {id:"linux-manage-user-perm-root", url:"#", title:"Gestiune utilizatori si permisiuni in Linux", subtitle:""},
        {id:"linux-whoami", url:"/linux/whoami", title:"Afisare utilizator curent: whoami", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-useradd", url:"/linux/useradd", title:"Creare utilizator: useradd", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-adduser", url:"/linux/adduser", title:"Creare utilizator: adduser", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-su", url:"/linux/su", title:"Logare utilizator: su", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-passwd", url:"/linux/passwd", title:"Setare parola utilizator: passwd", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-userdel", url:"/linux/userdel", title:"Stergere utilizator: userdel", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-cat-etc-passwd", url:"/linux/cat-etc-passwd", title:"Afisare lista utilizatori: cat /etc/passwd", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-chown", url:"/linux/chown", title:"Schimbare proprietar: chown", subtitle:"", parent:"linux-manage-user-perm-root"},
        {id:"linux-chmod", url:"/linux/chmod", title:"Schimbare permisiuni: chomod", subtitle:"", parent:"linux-manage-user-perm-root"},

        {id:"linux-networking", url:"#", title:"Networking in Linux", subtitle:""},
        {id:"linux-hostname", url:"/linux/hostname", title:"hostname", subtitle:"", parent:"linux-networking"},
        {id:"linux-hostnamectl", url:"/linux/hostnamectl", title:"Schimbare nume hostname: hostnamectl", subtitle:"", parent:"linux-networking"},
        {id:"linux-ping", url:"/linux/ping", title:"ping", subtitle:"", parent:"linux-networking"},
        {id:"linux-ip", url:"/linux/ip", title:"ip", subtitle:"", parent:"linux-networking"},
        {id:"linux-ifconfig", url:"/linux/ifconfig", title:"ifconfig", subtitle:"", parent:"linux-networking"},
        {id:"linux-nslookup", url:"/linux/nslookup", title:"DNS->IP: nslookup", subtitle:"", parent:"linux-networking"},
        {id:"linux-traceroute", url:"/linux/traceroute", title:"traceroute", subtitle:"", parent:"linux-networking"},
        {id:"linux-whois", url:"/linux/whois", title:"whois", subtitle:"", parent:"linux-networking"},
        {id:"linux-ssh", url:"/linux/ssh", title:"ssh", subtitle:"", parent:"linux-networking"},
        {id:"linux-curl", url:"/linux/curl", title:"curl", subtitle:"", parent:"linux-networking"},
        {id:"linux-wget", url:"/linux/wget", title:"wget", subtitle:"", parent:"linux-networking"},
        {id:"linux-netstat", url:"/linux/netstat", title:"netstat", subtitle:"", parent:"linux-networking"},

        {id:"linux-root", url:"#", title:"Comenzi Linux", subtitle:""},
        {id:"linux-echo", url:"/linux/echo", title:"Afisare mesaj: echo", subtitle:"", parent:"linux-root"},
        {id:"linux-date", url:"/linux/date", title:"date", subtitle:"", parent:"linux-root"},
        {id:"linux-df", url:"/linux/df", title:"df", subtitle:"", parent:"linux-root"},
        {id:"linux-free", url:"/linux/free", title:"free", subtitle:"", parent:"linux-root"},
        {id:"linux-os-release", url:"/linux/os-release", title:"cat /etc/os-release", subtitle:"", parent:"linux-root"},
        {id:"linux-lsb-release", url:"/linux/lsb-release", title:"lsb-release", subtitle:"", parent:"linux-root"},
        {id:"linux-uname", url:"/linux/uname", title:"uname", subtitle:"", parent:"linux-root"},
        {id:"linux-clean", url:"/linux/clean", title:"Curatare terminal: clean", subtitle:"", parent:"linux-root"},
        {id:"linux-uptime", url:"/linux/uptime", title:"uptime", subtitle:"", parent:"linux-root"},
        {id:"linux-w", url:"/linux/w", title:"Utilizatori contectati: w", subtitle:"", parent:"linux-root"},
        {id:"linux-man", url:"/linux/man", title:"Manual aplicatie: man", subtitle:"", parent:"linux-root"},
        {id:"linux-which", url:"/linux/which", title:"which", subtitle:"", parent:"linux-root"},
        {id:"linux-history", url:"/linux/history", title:"Istoricul comenzilor: history", subtitle:"", parent:"linux-root"},
        {id:"linux-xarg", url:"/linux/xarg", title:"Argumente: xarg", subtitle:"", parent:"linux-root"},
        {id:"linux-tar", url:"/linux/tar", title:"Arhivare/Arhivare cu compresie: tar", subtitle:"", parent:"linux-root"},
        {id:"linux-gzip", url:"/linux/gzip", title:"Compresie: gzip", subtitle:"", parent:"linux-root"},
        {id:"linux-alias", url:"/linux/alias", title:"alias", subtitle:"", parent:"linux-root"},

        {id:"linux-env-root", url:"#", title:"Variabile de mediu in Linux", subtitle:""},
        {id:"linux-env", url:"/linux/env", title:"Variabile de mediu: env", subtitle:"", parent:"linux-env-root"},
        {id:"linux-export", url:"/linux/export", title:"Adaugare/modificare variabila de mediu: export", subtitle:"", parent:"linux-env-root"},
        {id:"linux-unset", url:"/linux/unset", title:"Stergere variabila mediu: unset", subtitle:"", parent:"linux-env-root"},

        {id:"linux-process-root", url:"#", title:"Procese Linux", subtitle:""},
        {id:"linux-ps", url:"/linux/ps", title:"ps", subtitle:"", parent:"linux-process-root"},
        {id:"linux-kill", url:"/linux/kill", title:"kill", subtitle:"", parent:"linux-process-root"},
        {id:"linux-top", url:"/linux/top", title:"top", subtitle:"", parent:"linux-process-root"},

        {id:"linux-tools", url:"#", title:"Utilitare Linux", subtitle:""},
        {id:"linux-test-speed-net", url:"/linux/test-speed-net", title:"Testare viteza retea: speedtest", subtitle:"", parent:"linux-tools"},
        {id:"linux-htop", url:"/linux/htop", title:"Vizualizare procese: htop", subtitle:"", parent:"linux-tools"},

    ]

    static indexUrl = "/linux/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Comenzi Linux
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;