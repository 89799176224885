import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class CompressedResponsesNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-compressed-responses", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Compresia raspunsului</b>

                    <br/>
                    <br/>

                    Clientul/browser-ul poate sa specifice ca poate accepta resurse compresate. <br/>
                    Acest lucru se poate realiza prin header-ul <b>Accept-Encoding:gzip</b>. <br/>
                    Astfel, nxing, stiind acest lucru, poate trimite catre client resurse compresate (css, js, html),
                    reduncadu-se astfel dimensiunea raspunsului venit de pe server cat si timpul de raspuns.
                    <br/>
                    Clientul apoi are responsabilitatea sa decompreseze resursa inainte de randare/afisare.

                    <br/>
                    <br/>
                    In fisierul <b>nginx.conf</b>, in sectiunea <b>http</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'gzip on; # activare gzip\n' +
                            'gzip_comp_level 3; # nivelul de compresie; un numar mai mare compresie mai mare, \n' +
                            '#dar e nevoie de mai multe resurse din partea serverului, \n' +
                            '#valori posibile intre 0 si 9\n' +
                            '\n' +
                            '# specificam pentru ce tipuri de fisiere se aplica compresia:\n' +
                            'gzip_types text/css;\n' +
                            'gzip_types text/javascript;'}
                    </SyntaxHighlighter>

                    Serverul web trebuie configurat să includă antetul de răspuns „Vary: Accept-Encoding” atunci când sunt activate directive comune bazate pe gzip:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'add_header Vary Accept-Encoding;'}
                    </SyntaxHighlighter>

                    Când antetul „Vary: Accept-Encoding” este activat:
                    <ul>
                        <li>acesta raportează clientului că fișierul poate fi stocat în cache sau nu</li>
                    </ul>
                    Antetul „Vary: Accept-Encoding”:
                    <ul>
                        <li>definește modul în care serverul se va ocupa de stocarea în cache a fișierelor</li>
                    </ul>

                    De exemplu: dacă există o solicitare către un fișier care a fost stocat anterior în cache, acesta va fi servit din fișierele din cache.
                    Cu toate acestea, dacă antetul <b>Accept-Encoding</b> este prezent și noua solicitare este destul de diferită de versiunea anterioară din cache,
                    va fi gestionată ca o nouă solicitare și, prin urmare, va fi servită fără cache.

                    <br/>
                    <br/>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -I -H "Accept-Encoding: gzip, deflate" http://IP/thumb.png'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CompressedResponsesNginxContent;