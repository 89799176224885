import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class HeadNextReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-head", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. next/head </b>
                    <br/>
                    <br/>


                    Pentru a schimba titlul din browser a paginii:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import Head from "next/head";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<Head>\n' +
                            '   <title>Cont nou</title>\n' +
                            '</Head>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HeadNextReactContent;