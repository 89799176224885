import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class LoadBalancerNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-load-balancer", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Load Balancer</b>

                    <br/>
                    <br/>

                    Obiective:
                    <ul>
                        <li>abilitatea de a distribui request-uri catre mai multe servere (reducand incarcarea pe acestea servere individual)</li>
                        <li>oferi reduntata (in caz ca unul sau mai multe servere crapa, sa redirecteaza request-urile catre serverele care inca sunt in picioare - disponibile)</li>
                    </ul>

                    In contexul "load balancer" trebuie sa exista mai multe servere:
                    <ul>
                        <li>fie pe aceasi masina</li>
                        <li>fie pe masini fizice diferite</li>
                    </ul>

                    Cream 3 fisiere s1, s2, s3:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'echo "PHP server 1" > s1'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'echo "PHP server 2" > s3'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'echo "PHP server 3" > s3'}
                    </SyntaxHighlighter>

                    Pentru a vizualiza continutul de la s1:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'type s1'}
                    </SyntaxHighlighter>

                    Pornim 3 servere PHP:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'php -S localhost:10001 s1'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'php -S localhost:10002 s2'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'php -S localhost:10003 s3'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://localhost:10001'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://localhost:10002'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://localhost:10003'}
                    </SyntaxHighlighter>

                    In lumea reala, cele 3 servere sunt identice, adica raspund la fel la un request.

                    <hr/>

                    Se creaza un fisier <i>load-balancer.conf</i>; se foloseste directiva <b>upstream</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '    upstream php_servers{\n' +
                            '\t\n' +
                            '\t\tserver locahost:10001;\n' +
                            '\t\tserver locahost:10002;\n' +
                            '\t\tserver locahost:10003;\n' +
                            '\t\n' +
                            '\t}\n' +
                            '\t\n' +
                            '    server {\n' +
                            '\t\tlisten 8888; \t\n' +
                            '\t\t\n' +
                            '\t\tlocation / {\n' +
                            '\t\t\tproxy_pass http://php_servers;\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '    }\n' +
                            '} '}
                    </SyntaxHighlighter>

                    Pornire nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -c load-balancer.conf'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'while sleep 0.5; do curl http://localhost:8888; done'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Sticky Session (ip hash)</b>
                    <br/>
                    <br/>

                    Un request care vine de pe un anumit IP sa ii fie asociat (cand este posibil) acelasi server.

                    <br/>
                    <br/>
                    Se adauga directiva <b>ip_hash</b> in blocul <b>upstream</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' upstream php_servers{\n' +
                            '\t\n' +
                            '\tip_hash;\n' +
                            '\t\n' +
                            '\tserver locahost:10001;\n' +
                            '\tserver locahost:10002;\n' +
                            '\tserver locahost:10003;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Ruland:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'while sleep 0.5; do curl http://localhost:8888; done'}
                    </SyntaxHighlighter>
                    Se va afisa, atata timp e posibil (adica cat e disponivil serverul) acelasi mesaj.


                    <b>2. Load Balancer bazat pe cel mai mic număr de conexiuni</b>
                    <br/>
                    <br/>

                    Uneori un request poate dura mult pe un anumit server.
                    Si atunci o idee ar fi ca urmatorul request sa se duca pe un server care are conexiuni active mai putine.

                    <br/>
                    <br/>

                    Se adauga directiva <b>least_conn</b> in blocul <b>upstream</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' upstream php_servers{\n' +
                            '\t\n' +
                            '\tleast_conn;\n' +
                            '\t\n' +
                            '\tserver locahost:10001;\n' +
                            '\tserver locahost:10002;\n' +
                            '\tserver locahost:10003;\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LoadBalancerNginxContent;