import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class SecretK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-secret", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Secret</b>
                    <br/>
                    <br/>

                    Creare secret:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic mypass --from-literal=password=abc'}
                    </SyntaxHighlighter>

                    Mod de folosire intr-un fisier YAML:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'          env:\n' +
                            '            - name: POSTGRES_PASSWORD\n' +
                            '              valueFrom:\n' +
                            '                secretKeyRef:\n' +
                            '                  name: mypass\n' +
                            '                  key: password'}
                    </SyntaxHighlighter>

                    Creare secret pe baza unui fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic user --from-file=./username.txt'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic pswd --from-file=./password.txt'}
                    </SyntaxHighlighter>

                    Alte surse de creare a unui secret, pe langa fisier, sunt:
                    <ul>
                        <li>
                            director
                        </li>
                        <li>
                            variabile de mediu
                        </li>
                        <li>
                            URL
                        </li>
                    </ul>
                    Un singur secret poate imapcheta 1 sau mai multe perechi cheie-valoare din datele de intrare.
                    <br/>
                    Cand este creat dintr-un fisier, daca nu este mentionat, numele fisierului devine cheia secretului, iar continutul valoare cheii.
                    <br/>
                    In exemplul de mai sus, fiind specificate numele cheilor, avem: <i>user</i> si <i>pswd</i>.

                    Listare secrete:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>
                    poate lista:
                    <SyntaxHighlighter>
                        {'NAME         TYPE     DATA   AGE\n' +
                            'pgpassword   Opaque   1      51d\n'}
                    </SyntaxHighlighter>

                    Descriere decret:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe secret pgpassword'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Name:         pgpassword\n' +
                            'Namespace:    default\n' +
                            'Labels:       <none>\n' +
                            'Annotations:  <none>\n' +
                            '\n' +
                            'Type:  Opaque\n' +
                            '\n' +
                            'Data\n' +
                            '====\n' +
                            'PGPASSWORD:  12 bytes\n'}
                    </SyntaxHighlighter>

                    Exemplu de folosire a unui secret:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: kj-secret-pod # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: kj-secret-container\n' +
                            '    image: busybox\n' +
                            '    args: \n' +
                            '    - sleep\n' +
                            '    - "3600"\n' +
                            '    volumeMounts:\n' +
                            '    - name: busy-vol\n' +
                            '      mountPath: "/projected-volume" # calea catre directorul care contine secretele pe masina host\n' +
                            '      readOnly: true\n' +
                            '  volumes:\m' +
                            '  - name: busy-vol\n' +
                            '    projected:\n' +
                            '      sources:\n' +
                            '      - secret:\n' +
                            '          name: user\n' +
                            '      - secret:\n' +
                            '          name: pswd\n' +
                            ''}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>De ce nu e sigur un secret</b>
                    <br/>
                    <br/>
                    Se creaza un secret:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic secretele-mele --from-literal=parola=secreta'}
                    </SyntaxHighlighter>

                    Afisare secrete:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>

                    Afisare un secret (ca un fel de filtrare)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secret secretele-mele'}
                    </SyntaxHighlighter>

                    Afisare detalii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe secret secretele-mele'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'Name:         secretele-mele\n' +
                            'Namespace:    default\n' +
                            'Labels:       <none>\n' +
                            'Annotations:  <none>\n' +
                            '\n' +
                            'Type:  Opaque\n' +
                            '\n' +
                            'Data\n' +
                            '====\n' +
                            'parola:  7 bytes\n'}
                    </SyntaxHighlighter>

                    Afisare in format yaml:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secret secretele-mele -o yaml'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'data:\n' +
                            '  parola: c2VjcmV0YQ==\n' +
                            'kind: Secret\n' +
                            'metadata:\n' +
                            '  creationTimestamp: "2023-01-25T20:19:43Z"\n' +
                            '  name: secretele-mele\n' +
                            '  namespace: default\n' +
                            '  resourceVersion: "5490089"\n' +
                            '  uid: 9bb87906-45ff-43c0-8468-9610645a68c9\n' +
                            'type: Opaque\n'}
                    </SyntaxHighlighter>
                    Deci <i>parola</i> este <i>c2VjcmV0YQ==</i>; ea este encodata base64, dar nu criptata; aceasta inseamna ca poate fi decodata:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo c2VjcmV0YQ== | base64 --decode'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'secreta'}
                    </SyntaxHighlighter>

                    Pentru a verifica daca o data este criptata se poate folosi:
                    <SyntaxHighlighter>
                        {'ETCDCTL_API=3 etcdctl --cacert=/etc/kubernetes/pki/etcd/ca.crt --cert=/etc/kubernetes/pki/etcd/server.crt --key=/etc/kubernetes/pki/etcd/server.key get /registry/secrets/default/secretele-mele | hexdump -C'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Criptarea datelor/secretelor</b>
                    <br/>
                    <br/>

                    Daca folosim <b>minikube</b>, ne logam intai (pentru ca vom avea de editat fisiere de configurare de pe acel nod):
                    <SyntaxHighlighter>
                        {'minikube ssh'}
                    </SyntaxHighlighter>

                    <hr/>

                    1. Generare cheie aleatorie de 32 de octeți și codificare în base64. Pe Linux sau macOS, se ruleaza următoarea comandă:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'head -c 32 /dev/urandom | base64'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'4JjnC220llX3OC23RQhhUEVNpG9kwTA3WdUwJzz/Ezc='}
                    </SyntaxHighlighter>

                    2. Se creeaza un fisier de configurare (vi enc.yaml sau nano enc.yaml), folosind in <b>resources/providers/aescbc/keys/secret</b> cheia generata mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apiserver.config.k8s.io/v1\n' +
                            'kind: EncryptionConfiguration\n' +
                            'resources:\n' +
                            '  - resources:\n' +
                            '      - secrets\n' +
                            '    providers:\n' +
                            '      - aescbc:\n' +
                            '          keys:\n' +
                            '            - name: key1\n' +
                            '              secret: 4JjnC220llX3OC23RQhhUEVNpG9kwTA3WdUwJzz/Ezc=\n' +
                            '      - identity: {}'}
                    </SyntaxHighlighter>

                    3. Creare director <b>/etc/kubernetes/enc</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir /etc/kubernetes/enc'}
                    </SyntaxHighlighter>

                    4. Copiere fisier <b>enc.yaml</b> in <b>/etc/kubernetes/enc</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mv enc.yaml /etc/kubernetes/enc/'}
                    </SyntaxHighlighter>

                    5. Se editeaza manifestul pentru pod <b>kube-apiserver</b> : <b>/etc/kubernetes/manifests/kube-apiserver.yaml</b> similar cu acesta:
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  annotations:\n' +
                            '    kubeadm.kubernetes.io/kube-apiserver.advertise-address.endpoint: 192.168.49.2:8443\n' +
                            '  creationTimestamp: null\n' +
                            '  labels:\n' +
                            '    component: kube-apiserver\n' +
                            '    tier: control-plane\n' +
                            '  name: kube-apiserver\n' +
                            '  namespace: kube-system\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - command:\n' +
                            '    - kube-apiserver\n' +
                            '    - --advertise-address=192.168.49.2\n' +
                            '    - --allow-privileged=true\n' +
                            '    - --authorization-mode=Node,RBAC\n' +
                            '    - --client-ca-file=/var/lib/minikube/certs/ca.crt\n' +
                            '    - --enable-admission-plugins=NamespaceLifecycle,LimitRanger,ServiceAccount,DefaultStorageClass,DefaultTolerationSeconds,NodeRestriction,MutatingAdmissionWebhook,ValidatingAdmissionWebhook,ResourceQuota\n' +
                            '    - --enable-bootstrap-token-auth=true\n' +
                            '    - --etcd-cafile=/var/lib/minikube/certs/etcd/ca.crt\n' +
                            '    - --etcd-certfile=/var/lib/minikube/certs/apiserver-etcd-client.crt\n' +
                            '    - --etcd-keyfile=/var/lib/minikube/certs/apiserver-etcd-client.key\n' +
                            '    - --etcd-servers=https://127.0.0.1:2379\n' +
                            '    - --kubelet-client-certificate=/var/lib/minikube/certs/apiserver-kubelet-client.crt\n' +
                            '    - --kubelet-client-key=/var/lib/minikube/certs/apiserver-kubelet-client.key\n' +
                            '    - --kubelet-preferred-address-types=InternalIP,ExternalIP,Hostname\n' +
                            '    - --proxy-client-cert-file=/var/lib/minikube/certs/front-proxy-client.crt\n' +
                            '    - --proxy-client-key-file=/var/lib/minikube/certs/front-proxy-client.key\n' +
                            '    - --requestheader-allowed-names=front-proxy-client\n' +
                            '    - --requestheader-client-ca-file=/var/lib/minikube/certs/front-proxy-ca.crt\n' +
                            '    - --requestheader-extra-headers-prefix=X-Remote-Extra-\n' +
                            '    - --requestheader-group-headers=X-Remote-Group\n' +
                            '    - --requestheader-username-headers=X-Remote-User\n' +
                            '    - --secure-port=8443\n' +
                            '    - --service-account-issuer=https://kubernetes.default.svc.cluster.local\n' +
                            '    - --service-account-key-file=/var/lib/minikube/certs/sa.pub\n' +
                            '    - --service-account-signing-key-file=/var/lib/minikube/certs/sa.key\n' +
                            '    - --service-cluster-ip-range=10.96.0.0/12\n' +
                            '    - --tls-cert-file=/var/lib/minikube/certs/apiserver.crt\n' +
                            '    - --tls-private-key-file=/var/lib/minikube/certs/apiserver.key\n' +
                            '    image: registry.k8s.io/kube-apiserver:v1.25.2\n' +
                            '    imagePullPolicy: IfNotPresent\n' +
                            '    livenessProbe:\n' +
                            '      failureThreshold: 8\n' +
                            '      httpGet:\n' +
                            '        host: 192.168.49.2\n' +
                            '        path: /livez\n' +
                            '        port: 8443\n' +
                            '        scheme: HTTPS\n' +
                            '      initialDelaySeconds: 10\n' +
                            '      periodSeconds: 10\n' +
                            '      timeoutSeconds: 15\n' +
                            '    name: kube-apiserver\n' +
                            '    readinessProbe:\n' +
                            '      failureThreshold: 3\n' +
                            '      httpGet:\n' +
                            '        host: 192.168.49.2\n' +
                            '        path: /readyz\n' +
                            '        port: 8443\n' +
                            '        scheme: HTTPS\n' +
                            '      periodSeconds: 1\n' +
                            '      timeoutSeconds: 15\n' +
                            '    resources:\n' +
                            '      requests:\n' +
                            '        cpu: 250m\n' +
                            '    startupProbe:\n' +
                            '      failureThreshold: 24\n' +
                            '      httpGet:\n' +
                            '        host: 192.168.49.2\n' +
                            '        path: /livez\n' +
                            '        port: 8443\n' +
                            '        scheme: HTTPS\n' +
                            '      initialDelaySeconds: 10\n' +
                            '      periodSeconds: 10\n' +
                            '      timeoutSeconds: 15\n' +
                            '    volumeMounts:\n' +
                            '    - mountPath: /etc/ssl/certs\n' +
                            '      name: ca-certs\n' +
                            '      readOnly: true\n' +
                            '    - mountPath: /etc/ca-certificates\n' +
                            '      name: etc-ca-certificates\n' +
                            '      readOnly: true\n' +
                            '    - mountPath: /var/lib/minikube/certs\n' +
                            '      name: k8s-certs\n' +
                            '      readOnly: true\n' +
                            '    - mountPath: /usr/local/share/ca-certificates\n' +
                            '      name: usr-local-share-ca-certificates\n' +
                            '      readOnly: true\n' +
                            '    - mountPath: /usr/share/ca-certificates\n' +
                            '      name: usr-share-ca-certificates\n' +
                            '      readOnly: true\n' +
                            '  hostNetwork: true\n' +
                            '  priorityClassName: system-node-critical\n' +
                            '      path: /var/lib/minikube/certs\n' +
                            '      type: DirectoryOrCreate\n' +
                            '    name: k8s-certs\n' +
                            '  - hostPath:\n' +
                            '      path: /usr/local/share/ca-certificates\n' +
                            '      type: DirectoryOrCreate\n' +
                            '    name: usr-local-share-ca-certificates\n' +
                            '  - hostPath:\n' +
                            '      path: /usr/share/ca-certificates\n' +
                            '      type: DirectoryOrCreate\n' +
                            '    name: usr-share-ca-certificates\n' +
                            'status: {}\n'}
                    </SyntaxHighlighter>

                    Ca ghidare:

                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            ' ...\n ' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - command:\n' +
                            '    - kube-apiserver\n' +
                            '    ...\n' +
                            '    - --encryption-provider-config=/etc/kubernetes/enc/enc.yaml  # <-- add this line\n' +
                            '    volumeMounts:\n' +
                            '    ...\n' +
                            '    - name: enc                           # <-- add this line\n' +
                            '      mountPath: /etc/kubernetes/enc      # <-- add this line\n' +
                            '      readonly: true                      # <-- add this line\n' +
                            '    ...\n' +
                            '  volumes:\n' +
                            '  ...\n' +
                            '  - name: enc                             # <-- add this line\n' +
                            '    hostPath:                             # <-- add this line\n' +
                            '      path: /etc/kubernetes/enc           # <-- add this line\n' +
                            '      type: DirectoryOrCreate             # <-- add this line\n' +
                            '  ...'}
                    </SyntaxHighlighter>

                    Acum, orice secret creat va fi criptat.

                    <hr/>
                    Pentru a cripta toate secretele vechi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets --all-namespaces -o json | kubectl replace -f -'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SecretK8sContent;