import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AddGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-add", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git add</b>
                    <br/>
                    <br/>

                    Intr-un repository Git, orice fisier poate fi:
                    <ul>
                        <li>neurmarit (untracked): fisierul este ignorat de Git (in comanda <i>git status</i> sunt marcate cu rosu)</li>
                        <li>urmarit (tracked): fisierul este monitorizat de Git, urmarindu-se modificarile</li>
                    </ul>

                    Sa presupunem ca vrem ca fisierul <i>citeste.txt</i> sa fie urmatari in repository.
                    Atunci executam comanda <b>git add <i>nume_fisier</i></b> :
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git add citeste.txt'}
                    </SyntaxHighlighter>

                    Daca executam acum comanda <b>git status</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git status\n' +
                        'On branch master\n' +
                        '\n' +
                        'No commits yet\n' +
                        '\n' +
                        'Changes to be committed:\n' +
                        '  (use "git rm --cached <file>..." to unstage)\n' +
                        '        new file:   citeste.txt'}
                    </SyntaxHighlighter>

                    Se pot adauga spre urmarire mai multe fisiere in acelasi timp, folosind comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git add nume_fisier_1, nume_fisier_2, nume_fisier_N'}
                    </SyntaxHighlighter>

                    Pentru a adauga toate fisiere din directorul curent si subdirectoarele acestuia, se poate folosi comanda (dar nu este recomandat):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AddGitContent;