import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class DuLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-du", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Permite unui utilizator să obțină rapid informații despre utilizarea discului / sau a unui fisier
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'du'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'48      ./learn/complex/client/node_modules/@jest/transform/node_modules/color-convert\n' +
                            '24      ./learn/complex/client/node_modules/@jest/transform/node_modules/has-flag\n' +
                            '36      ./learn/complex/client/node_modules/@jest/transform/node_modules/ansi-styles\n' +
                            '124     ./learn/complex/client/node_modules/@jest/transform/node_modules/source-map/lib\n'}
                    </SyntaxHighlighter>

                     Pentru a afisa informatii despre un sigur fisier (optiunile <b>-sh</b> sunt pentru o afisare mai umana):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'du -sh detalii-nginx.txt'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'4,0K    detalii-nginx.txt'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DuLinuxContent;