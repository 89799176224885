import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FakeSMTPEtherealEmailConfigPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-fake-smtp-ethereal-email-config", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    <b>1. Creare cont</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            URL: https://ethereal.email/create
                        </li>
                        <li>
                            Se apasa pe butonul <b>Create Ethereal Account</b>
                        </li>
                        <li>
                            Dupa ce se apasa, vom obtine credentialele:
                            <ul>
                                <li>
                                    Name        Mckenna Moore
                                </li>
                                <li>
                                    Username    mckenna.moore@ethereal.email
                                </li>
                                <li>
                                    Password    qZDrwyqe7XDm7QJSG2
                                </li>
                            </ul>
                        </li>
                    </ul>

                    SMTP configuration
                    <SyntaxHighlighter>
                        {'Host    smtp.ethereal.email\n' +
                            'Port    587\n' +
                            'Security    STARTTLS\n' +
                            'Username    mckenna.moore@ethereal.email\n' +
                            'Password    qZDrwyqe7XDm7QJSG2'}
                    </SyntaxHighlighter>

                    IMAP configuration
                    <SyntaxHighlighter>
                        {'Host    imap.ethereal.email\n' +
                            'Port    993\n' +
                            'Security    TLS\n' +
                            'Username    mckenna.moore@ethereal.email\n' +
                            'Password    qZDrwyqe7XDm7QJSG2\n'}
                    </SyntaxHighlighter>

                    POP3 configuration:
                    <SyntaxHighlighter>
                        {'Host    pop3.ethereal.email\n' +
                            'Port    995\n' +
                            'Security    TLS\n' +
                            'Username    mckenna.moore@ethereal.email\n' +
                            'Password    qZDrwyqe7XDm7QJSG2'}
                    </SyntaxHighlighter>

                    Tabelar:
                    <SyntaxHighlighter>
                        {'Service Name   Username   Password   Hostname   Port   Security\n' +
                            'SMTP    Mckenna Moore  mckenna.moore@ethereal.email   qZDrwyqe7XDm7QJSG2 smtp.ethereal.email    587    STARTTLS\n' +
                            'IMAP    Mckenna Moore  mckenna.moore@ethereal.email   qZDrwyqe7XDm7QJSG2 imap.ethereal.email    993    TLS\n' +
                            'POP3    Mckenna Moore  mckenna.moore@ethereal.email   qZDrwyqe7XDm7QJSG2 pop3.ethereal.email    995    TLS'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Verificare email</b>
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            <a target={"_blank"} href={"https://ethereal.email/messages"}> https://ethereal.email/messages</a>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Pasi de urmat in Liferay</b>
                    <ul>
                        <li>Control Panel {">"} System {">"} Server Administration {"->"} tab <b>Email</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/cc21_email_png.png'}/>
                            </div>

                            <SyntaxHighlighter>
                                {'mail.smtp.starttls.enable: true\n' +
                                    'mail.smtp.starttls.required: true\n' +
                                    'mail.smtp.auth: true\n' +
                                    'mail.smtp.ssl.enable: false\n' +
                                    'mail.smtp.socketFactory.fallback: true'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a target={"_blank"} href={"https://medium.com/@dzivet/email-integration-in-spring-boot-with-a-fake-smtp-service-ethereal-677937bad4bf"}>
                                    Email Integration in Spring Boot with a fake SMTP service — Ethereal
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FakeSMTPEtherealEmailConfigPortletContent;