import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class SecurityDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-security", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Securitate</b>
                    <br/>

                    Securitatea presupune:
                    <ul>
                        <li>scanarea imaginilor de vulnerabilitati</li>
                        <li>namespaces</li>
                        <li>cgroups</li>
                        <li>certificate</li>
                        <li>limitare si rezervare CPU</li>
                    </ul>

                    <hr/>
                    <b>2. Container Security Scanning (DTR)</b>
                    <br/>
                    <br/>

                    Containerele Docker pot avea vulnerabilitati de securitate.
                    DTR permite scanarea de securitate pentru containere.
                    Aceasta scanare poate fi efectualta:
                    <ul>
                        <li>on push / la push (scanare - recomandata - se la face la push - incepe imediat scanarea dupa push -, dar se poate face si manual)</li>
                        <li>manual </li>
                    </ul>
                    Si se poate seta din: <b>Repositories {"->"} (se alege un repository) {"->"} Settings{"->"} Image Scanning {"->"} [ ] On Push | [x] manual</b>


                    <hr/>

                    Activare scanare (implicit este dezactivata):
                    <b>System {"->"} Security {"->"} [X] Enable Scanning</b>
                    <br/>
                    Exista 2 metode de instalare si actualizare:
                    <ul>
                        <li>Online {"=>"} DTR va contacta un server Docker pentru download si va instala ultima baza de date cu informatii despre vulnerabilitati;
                            <br/>
                            DTR va verifica zilnic dupa actualizari ale bazei de date (CVE Database);
                        </li>
                        <li>Offline {"=>"} se face upload manual la un fisier</li>
                    </ul>
                    Apoi se apasa pe butonul <b>Enable Online Syncing</b>

                    <hr/>
                    Daca se pune o imagine in repository se poate scana dupa vulnerabilitati.
                    Vulnerabilitatile pot fi:
                    <ul>
                        <li>critice</li>
                        <li>majore</li>
                        <li>minore</li>
                    </ul>
                    Pentru o imagine cu vulnerabilitati se pot afisa vulnerabilitatile pentru fiecare layer/componenta (cu vulnerabilitati) ale imaginii.

                    <hr/>
                    <b>3. Webhooks (DTR)</b>
                    <br/>
                    <br/>

                    DTR poate fi configurat sa posteze notifcari de evenimente la o adresa URL webhook (aleasa).
                    <br/>
                    De exemplu, dupa ce s-a scanat o imagine (DTR), se va declansa webhook pentru teste unitare (Jenkins).

                    <br/>

                    Si se poate seta din: <b>Repositories {"->"} (se alege un repository) {"->"} Webhooks</b>.
                    <br/>
                    Cand se creeaza un webhook, se alege:
                    <ul>
                        <li>Notification to receive (Tag pushed to repository, Tag pulled from repository, Tag deleted on repository, Security scan completed, Security scan failed,  etc)</li>
                        <li>URL Workhook</li>
                    </ul>

                    <hr/>
                    <b>4. Client Bundles</b>
                    <br/>
                    <br/>

                    Un client bundle este un grup de certificate care pot fi descarcate direct de pe UCP (Universal Control Plane).
                    <br/>
                    In functie de permisiunile asociate cu un utilizator, se pot executa comenzi docker swarm de pe masina remote care au efect pe cluster remote.

                    <br/>
                    Exemple:
                    <ul>
                        <li>se poate creea un nou serviciu in UCP de pe laptop</li>
                        <li>te poti loga la container, remote de pe laptop fara SSH (via API)</li>
                    </ul>

                    Pentru a descara client bundle: <b>admin (sau userul conectat la UCP){"->"} My profile {"->"} Client Bundles {"->"} New  Client Bundle {"->"} Generate Client Bundle (se va genera o arhiva, de exemplu: ucp-bundle-admin.zip)</b>.
                    <hr/>

                    <b>Testare folosind un container cu ubuntu</b>
                    <br/>
                    <br/>

                    Se downloadeaza arhiva pe masina locala (ucp-bundle-admin.zip) si apoi:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -dt --name kj-ubuntu ubuntu bash'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker cp ucp-bundle-admin.zip kj-ubuntu:/tmp'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container exec -it kj-ubuntu bash'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir ucp'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd ucp'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cp /tmp/ucp-bundle-admin.zip .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apt-get update && apt-get install unzip && apt-get install nano'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'unzip ucp-bundle-admin.zip'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano env.sh'}
                    </SyntaxHighlighter>
                    acest script exporta variabile:
                    <ul>
                        <li>DOCKER_HOST=tcp://111.111.111.111:443</li>
                        <li>DOCKER_CERT_PATH=$PWD</li>
                        <li>DOCKER_TLS_VERIFY=1</li>
                        <li>COMPOSE_TLS_VERSION=TLSv1_2</li>
                        <li>KUBECONFIG=$PWD/kube.yml</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'eval "$(<env.sh)"'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'echo $DOCKER_HOST'}
                    </SyntaxHighlighter>

                    instalare docker:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'curl -sSL https://get.docker.com/ | sh'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info'}
                    </SyntaxHighlighter>

                    creare serviciu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name mydemoservice nginx'}
                    </SyntaxHighlighter>
                    acest service se va creea in UCP pe server (si nu in masina/containerul ubuntu).


                    <hr/>
                    <b>5. Namespaces</b>
                    <br/>
                    <br/>

                    Linux are 6 tipuri de namespace-uri:
                    <ul>
                        <li>Inter-Process Communication (IPC)</li>
                        <li>Process (pid)</li>
                        <li>Network (net)</li>
                        <li>User Id (user)</li>
                        <li>Mount (mnt)</li>
                        <li>UTS (UNIX Timesharing System) {"=>"} copie separată a numelui de gazdă,  astfel încât să îl poate seta la altceva fără a afecta restul sistemului
                            <br/>
                            Numele de gazdă este setat prin <i>sethostname</i>
                            <br/>
                            UTS permite unui singur sistemsă aibă nume de gazdă (hostname) și domenii diferite pentru procese diferite!
                        </li>
                    </ul>

                    <hr/>
                    <b>6. Control Groups (cgroups)</b>
                    <br/>
                    <br/>

                    Control Groups este o caracteristica Linux kernel care liminteaza si izoleaza resusele folosite (CPU, memory, disk I/O, retea) de o colectie de procese.

                    <br/>
                    Limitari container:
                    <ul>
                        <li>
                            memoria (<b>-m</b>):  Pentru a limita memoria maxima folosita de un container se poate adauga optiunea <b>-m</b> la pornirea acestuia.
                        </li>
                        <li>
                            CPU:
                            <ul>
                                <li>
                                    <b>--cpus</b>=[valoare] {"=>"} daca masina gazda are 2 CPU (procesoare), si se seteaza <b>--cpus=</b>1,
                                    atunci containerul este garantat la maxim 1 CPU (se poate specifica si --cpus=0.5)
                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker container run -dt --name constraint01 --cpus=1.5 busybox sh'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    <b>--cpuset-cpus</b> {"=>"} liminteaza CPU si nuclee (core) pe care un container le poate utiliza;
                                    <br/>
                                    Se poate specifica ca o lista separata prin virgula sau interval de CPU separate prin cratima;
                                    <br/>
                                    primul CPU este numerotat cu 0, al doilea CPU este numeroatat cu 1;
                                    <br/>
                                    exemple:
                                    <ul>
                                        <li>
                                            0-3 inseamna ca un container poate folosi CPU-ul 1,2,3 si 4
                                        </li>
                                        <li>
                                            1,3 insemana ca un container poate folosi CPU-ul 2 si 4
                                        </li>
                                    </ul>

                                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                        {'docker container run -dt --name constraint02 --cpuset-cpus=0,1 busybox sh'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br/>
                    Comanda <b>free</b> folosita intr-un container nu va afisa in mod real memoria disponibila pentru container, ci pentru intreaga masina (asta pentru comanda <i>free</i> exista dinainte sa existe caracteristica <i>cgroups</i>)


                    <hr/>
                    <b>7. Rezervarea si limitare</b>
                    <br/>
                    <br/>

                    In mod implicit, un container nu are nici o constrangere legata de resurse si poate folosi cate resurse ii permite <i>kernel scheduler</i> de pa masina gazda.
                    <br/>
                    Dar, este important, sa nu permitem unui container sa foloseasca prea multa memorie din memoria masinii gazda.
                    <br/>
                    O pe masina gazda Linux, daca kernel-ul detecteaza ca nu exista destula memorie pentru a se efecuta o functie importanta de siste se arunca o exceptie de tip
                    <i>Out of Memory</i> si incepe sa opreasca procese pentru a elibera memoria.

                    <ul>
                        <li>
                            Limit (hard limit){"=>"} impune o limita superioasa a cantitatii de memorie care poate fi utilizata de un container Docker.
                            <br/>
                            Se foloseste optiunea: <b>--memory</b> sau <b>-m</b>
                        </li>
                        <li>
                            Rezervarile (soft limit) {"=>"} sunt mai putin rigide.
                            <br/>
                            Cand sistemul epuizeaza memoria, rezervarea incerca sa aduca consumul de memorie al containerului egala sau sub limita de rezervare.
                            <br/>
                            Se foloseste optiunea: <b>--memory-reservation</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>8. Swarm MTLS</b>
                    <br/>
                    <br/>

                    Nodurile dintr-un Swarm folosesc in mod reciproc <i>Transport Layer Security (TLS)</i> pentru autentificare, autorizare, criparea comunicarii cu alte noduri in swarm.
                    <br/>
                    In mod implicit, nodul manager genereaza o noua autoritate de certificare (<i>Certificate Authority - CA </i>) cu o pereche de chei, care sunt folosite pentru a securiza comunicare cu alte noduri care s-au alaturat swarm-ului.
                    <br/>
                    Se poate specifica propria (radacina) CA generata extern, utilizand indicatorul/optiunea <b>--external-ca</b> in comanda <b>docker swarm init</b>.
                    <br/>
                    De fiecare data cand un nod se alatura la swarm, manger-ul elibereaza un certificat nodului.
                    <br/>
                    Certificate se gasesc in locatia: <b>/var/lib/docker/certificates</b>
                    <br/>
                    <hr/>
                    Nodul mananger genereaza 2 tokeni care vor fi folositi atunci cand se alatura noduri suplimentare in swarm:
                    <ul>
                        <ul>un worken token:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker swarm join-token worker'}
                            </SyntaxHighlighter>
                        </ul>
                        <ul>un manager token:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker swarm join-token manager'}
                            </SyntaxHighlighter>
                        </ul>
                    </ul>
                    Fiecare token include:
                    <ul>
                        <li>
                            rezumatul (digest) certificatului CA
                        </li>
                        <li>
                            un secret generat aleator
                        </li>
                    </ul>
                    Cand un nod se alatura in swarm se foloeste digest-ul pentru a valida certificatul CA de pe manger-ul remote.

                    <hr/>
                    Pentru a genera un nou certificat si cheie (de exemplu, cand vechiul certificat este compromis):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm ca --rotate'}
                    </SyntaxHighlighter>
                    (se genereaza un certificat cross-signed, semnat de vechiul certificat CA)
                    <br/>
                    Dupa ce fiecare nod din swarm are un nou certificat TLS semnat de noul CA, Doker uita de vechiul certificat CA si cheie,
                    si spune tuturor nodurile sa aiba incredere doar in noul certificat. Adica, certificatele din locatia:
                    <br/>
                    <b>/var/lib/docker/certificates</b>
                    <br/>
                    se actualizeaza (swarm-node.crt, swarm-node.key).
                    <br/>
                    <br/>

                    Token-ii de join se modifica in consecinta.
                    <br/>
                    Trebuie geneara alt token, pentru a permite alturarea unui nod e tip worker in swarm:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join-token worker'}
                    </SyntaxHighlighter>
                    <hr/>
                    In mod implicit, fiecare nod dintr-un swarm isi reinoieste certificate la fiecare 3 luni.
                    Se poate configura acest interval ruland actualizarea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --cert-expiry [time-period]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Gestiunea secretelor in Swarm</b>
                    <br/>
                    <br/>

                    Secretele Docker:
                    <ul>
                        <li>gestionate central</li>
                        <li>se transmit in siguranta doar containerelor care cau nevoie de acces la ele</li>
                        <li>sunt criptate in timpul trazitului intr-un Docker Swarm</li>
                        <li>un anumit secret este accesibil acelor servicii carora li s-a acordat acces explit la el si numai cand aceste servicii sunt in executie (ruleaza)</li>
                    </ul>

                    Listare secrete:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker secret ls'}
                    </SyntaxHighlighter>

                    Creare secrete:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker secret create [nume-secret] [nume-fisier]'}
                    </SyntaxHighlighter>
                    (nume-fisier, e un fisier care contine o lista de cheie=valoare)

                    <br/>
                    <br/>

                    Inspectare secret:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker secret inspect [nume-secret]'}
                    </SyntaxHighlighter>

                    Creare serviciu care foloseste un secret
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name [nume-serviciu] --secret [nume-secret] nginx'}
                    </SyntaxHighlighter>

                    Intrare in container:
                    <SyntaxHighlighter>
                        {'docker container exec -it 1004cab996b6 bash'}
                    </SyntaxHighlighter>
                    In interiorul containerul o sa avem in locatia: /run/secrets/[nume-fisier]


                    <hr/>
                    <b>10. Docker Content Trust</b>
                    <br/>
                    <br/>

                    Când descărcăm imagini din rețea sau de pe internet, integritatea devine o adevărată preocupare.
                    <br/>

                    Încrederea în conținut vă oferă posibilitatea de a verifica atât integritatea,
                    cât și editorul tuturor datelor primite de la un registru pe orice canal.
                    <br/>
                    Acest lucru se poate realiza cu ajutorul <b>semnăturilor digitale</b>.

                    <br/>
                    Pentru a forta pull de imagini de incredere / activare DCT:
                    <SyntaxHighlighter>
                        {'export DOCKER_CONTENT_TRUST=1'}
                    </SyntaxHighlighter>

                    Inpectare daca o imagine este de incredere:
                    <SyntaxHighlighter>
                        {'docker trust inspect [imagine]'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>11. Capabilitati Linux pentru Docker</b>
                    <br/>
                    <br/>

                    <b>Test rulare Docker cu un utilizator fara privilegii</b>
                    <br/>
                    <br/>
                    Creare user:
                    <SyntaxHighlighter>
                        {'sudo useradd kj-test'}
                    </SyntaxHighlighter>
                    Setare parola:
                    <SyntaxHighlighter>
                        {'sudo passwd kj-test'}
                    </SyntaxHighlighter>

                    Logare:
                    <SyntaxHighlighter>
                        {'su - kj-test'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'Got permission denied while trying to connect to the Docker daemon socket at unix:///var/run/docker.sock: Get "http://%2Fvar%2Frun%2Fdocker.sock/v1.24/containers/json": dial unix /var/run/docker.sock: connect: permission denied'}
                    </SyntaxHighlighter>
                    (acest lucru se intampla pentru ca docker nu are persmiune, nu este in grupul <i>docker</i>)
                    <br/>
                    <br/>
                    logout, pentru a putea sa adaugam utilizatorul nou creat la grupul <i>docker</i>:
                    <SyntaxHighlighter>
                        {'exit'}
                    </SyntaxHighlighter>

                    Putea a adaug utilizatorul nou creat la grupul <i>docker</i> se poate:
                    <ul>
                        <li>modifica direct in fisierul <b>/etc/group</b>
                            <SyntaxHighlighter>
                                {'cat /etc/group | grep docker'}
                            </SyntaxHighlighter>
                            se poate afisa: docker:x:999:kj
                        </li>
                        <li>
                            <SyntaxHighlighter>
                                {'sudo usermod -aG docker kj-test'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                                {'cat /etc/group | grep docker'}
                            </SyntaxHighlighter>
                            se poate afisa: docker:x:999:kj,kj-test
                        </li>
                    </ul>

                    Logare:
                    <SyntaxHighlighter>
                        {'su - kj-test'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    (si acum va functiona)

                    <hr/>


                    Există mai multe tipuri de capabilități pe care Linux le oferă pentru a avea acces granular la nivel de aplicatie.

                    <br/>
                    Exemplu (unele sunt acordate in mod default, unele trebuie specificate explicit):
                    <ul>
                        <li>
                            SETPCAP {"->"} implicit
                        </li>
                        <li>
                            CHOWN {"->"} implicit
                        </li>
                        <li>
                            SYS_MODULE {"->"} trebuie explicit
                        </li>
                        <li>
                            LINUX_IMMUTABLE {"->"} trebuie explicit
                        </li>
                    </ul>

                    Adaugare capabilitate (<b>--cap-add</b>):
                    <SyntaxHighlighter>
                        {'docker run -dt --name container-capadd --cap-add LINUX_IMMUTABLE amazonlinux'}
                    </SyntaxHighlighter>
                    (daca un fisier este declarat ca imutabil, nu se va putea sterge sau modifica)

                    Stergere capabilitate (<b>--cap-drop</b>):
                    <SyntaxHighlighter>
                        {'docker run -dt --name container-capdrop --cap-drop CHOWN amazonlinux'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>12. Containere privilegiate</b>
                    <br/>
                    <br/>

                    În mod implicit, containerul docker nu are multe capabilități atribuite.
                    <br/>
                    De asemenea, containerele Docker nu au voie să acceseze niciun dispozitiv.
                    <br/>
                    Prin urmare, în mod implicit, containerul docker nu poate realiza diverse cazuri de utilizare,
                    cum ar fi rularea containerului docker în interiorul unui container docker

                    <hr/>

                    <b>Privileged Container</b> poate accesa toate dispozitivele de pe gazdă și poate avea o configurație în:
                    <ul>
                        <li><b>AppArmor</b></li>
                        <li><b>SELinux</b></li>
                    </ul>
                    pentru a permite containerului aproape același acces la gazdă ca și procesele care rulează în afara containerelor pe gazdă.
                    <br/>

                    Limitele pe care le-ați stabilit pentru containerele privilegiate nu vor fi respectate.
                    <br/>
                    Rularea unui flag privilegiat oferă containerului toate capabilitățile.

                    Acordare privilegii extinse unui containere (<b>--privileged</b>):
                    <SyntaxHighlighter>
                        {'docker run -dt --name container-privileged --privileged CHOWN amazonlinux'}
                    </SyntaxHighlighter>

                    <hr/>

                    <hr/>
                    <b>13. Colecții UCP</b>
                    <br/>
                    <br/>

                    Colecțiile sunt implementate în UCP prin utilizarea etichetelor Docker .

                    <hr/>
                    <b>14. Seccomp</b>
                    <br/>
                    <br/>
                    Ce caracteristică Docker vă permite să restricționați apelurile de sistem disponibile pentru un anumit proces? <b>Seccomp = Secure Computing Mode</b>
                    <br/>
                    Seccomp (prescurtare de la Secure Computing Mode) este o caracteristică de securitate a nucleului Linux, folosită pentru a restricționa apelurile de sistem disponibile pentru un anumit proces.
                    Această facilitate a fost în nucleu în diferite forme începând cu 2.6.12 și este disponibilă în Docker Engine din 1.10.
                    <br/>

                    Implementarea actuală în Docker Engine oferă un set implicit de apeluri sistem restricționate și permite, de asemenea, filtrarea apelurilor de sistem fie printr-o listă albă, fie printr-o listă neagră pe bază de container (adică filtre diferite pot fi aplicate diferitelor containere care rulează în același motor).
                    Profilurile Seccomp sunt aplicate la momentul creării containerului și nu pot fi modificate pentru rularea containerelor.


                    <hr/>
                    <b>15. Secrete</b>
                    <br/>
                    <br/>

                    Un secret Docker este un blob de date sensibile care nu ar trebui transmise printr-o rețea, cum ar fi:
                    <ul>
                        <li>Nume de utilizator și parole</li>
                        <li>Certificate și chei TLS</li>
                        <li>Chei SSH</li>
                        <li>Alte date importante, cum ar fi numele unei baze de date sau al unui server intern</li>
                        <li>Șiruri generice sau conținut binar (până la 500 kb în dimensiune)</li>
                    </ul>

                    Comanda pentru a crea un secret dintr-un fișier sau intrare standard (STDIN) ca conținut este:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker secret create [ OPȚIUNI ] SECRET [ fișier |-]'}
                    </SyntaxHighlighter>

                    Deoarece aceasta este o comandă de gestionare a clusterului, <b>trebuie executată pe un nod manager swarm</b>.
                    <br/>
                    Notă: După ce creați un secret, nu îl puteți actualiza. Puteți doar să îl eliminați și să îl recreați și nu puteți elimina un secret pe care îl folosește un serviciu.
                    <br/>
                    Secretele sunt criptate în timpul tranzitului și în repaus într-un Docker Swarm (Secretul este stocat în jurnalul Raft, care este criptat)

                    <br/>
                    <hr/>
                    Aveți nevoie de controlul unui dispozitiv de sunet pe serverul gazdă dintr-un container. Ce comandă ar trebui să folosești?
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --device=/dev/snd:/dev/ snd'}
                    </SyntaxHighlighter>

                    Explicaţie:
                    <br/>
                    În mod implicit, containerele Docker sunt „neprivilegiate” și, de exemplu, nu pot rula un daemon Docker în interiorul unui container Docker.
                    Acest lucru se datorează faptului că, în mod implicit, unui container nu i se permite să acceseze niciun dispozitiv,
                    dar unui container „privilegiat” i se oferă acces la toate dispozitivele (consultați documentația despre dispozitivele cgroups).
                    <br/>
                    <br/>
                    Când operatorul execută docker run --privileged, Docker va activa accesul la toate dispozitivele de pe gazdă,
                    precum și va seta o anumită configurație în AppArmor sau SELinux pentru a permite containerului
                    aproape același acces la gazdă ca și procesele care rulează în afara containerelor pe gazdă.
                    <br/>
                    <br/>
                    Dacă doriți să limitați accesul la un anumit dispozitiv sau dispozitive, puteți utiliza marcatorul <b>--device</b>.
                    Vă permite să specificați unul sau mai multe dispozitive care vor fi accesibile în container.
                    <br/>
                    <br/>
                    Urmând principiul cel mai puțin privilegiat, ar trebui să utilizați --device în loc de --priviledged.

                    <hr/>
                    <b>Scopul Docker Content Trust</b>
                    <br/>
                    <br/>

                    Când comunicați pe un mediu neîncrezător, cum ar fi internetul,
                    este esențial să asigurați integritatea și editorul tuturor datelor pe care operează un sistem. Utilizați motorul Docker pentru a împinge și a extrage imagini (date)
                    într-un registru public sau privat.
                    Încrederea în conținut vă oferă posibilitatea de a verifica atât integritatea, cât și editorul tuturor datelor primite de la un registru pe orice canal.

                    <br/>
                    <br/>
                    Docker Content Trust (DCT) oferă posibilitatea de a utiliza <b>semnături digitale</b> pentru datele trimise și primite de la registrele Docker la distanță.
                    Aceste semnături permit <b>verificarea</b> pe partea client sau în timpul executării a integrității și a editorului etichetelor de imagine specifice.

                    <hr/>
                    <b>UCP</b>
                    <br/>
                    <br/>

                    Când implementați UCP, acesta începe să ruleze un serviciu programat la nivel global numit <b>ucp-agent</b>.
                    Acest serviciu monitorizează nodul unde rulează și pornește și oprește serviciile UCP, în funcție de faptul că nodul este un manager sau un nod de lucru.

                    Dacă nodul este un:
                    <ul>
                        <li>Manager : <b>serviciul ucp-agent</b> începe automat să deservească toate componentele UCP, inclusiv interfața web UCP și depozitele de date utilizate de UCP.
                            Agentul ucp realizează acest lucru prin implementarea mai multor containere pe nod.</li>
                        <li>Worker : pe nodurile de lucru, serviciul ucp-agent începe să servească un <b>serviciu proxy</b> care asigură că numai utilizatorii autorizați și alte servicii UCP pot rula comenzi Docker în acel nod.</li>
                    </ul>

                    <hr/>
                    <b>Scanarea imaginilor</b>
                    <br/>
                    <br/>

                    Scanarea imaginilor este o caracteristică a Docker trusted Repository DTR, care face parte din <b>Docker Entreprise Edition</b>.

                    Pentru a utiliza funcția de scanare a imaginilor, asigurați-vă că dvs. sau organizația dvs.:
                    <ul>
                        <li>
                            ați achiziționat o licență DTR care include Docker Security Scanning
                        </li>
                        <li>
                            ID-ul dvs. Docker poate accesa și descărca această licență din Docker Hub (adica aveti autoritatea sa accesati si descarca aceasta licenta)
                        </li>
                        <li>
                            După ce obțineți licența, va trebui să activați scanarea de securitate DTR.
                        </li>
                    </ul>

                    <hr/>
                    <b>Securizare registru</b>
                    <br/>
                    <br/>
                    Deși este foarte recomandat să vă securizați registrul folosind un certificat TLS emis de o CA cunoscută,
                    puteți alege să utilizați certificate autosemnate sau să utilizați registrul printr-o conexiune HTTP necriptată.
                    <br/>
                    Oricare dintre aceste alegeri implică compromisuri de securitate și pași suplimentari de configurare.
                    <br/>
                    Această procedură configurează Docker să ignore complet securitatea registrului dvs.
                    <br/>
                    1. Editați fișierul <b>daemon.json</b>, a cărui locație implicită este <b>/etc/docker/daemon.json</b>. Adăugați următorul conținut.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'{\n' +
                            '  "insecure-registries": ["myregistrydomain.com:5000"]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    2. Reporniți Docker pentru ca modificările să intre în vigoare.
                    <br/>
                    3. <b>Repetați acești pași pe fiecare gazdă Engine care dorește să vă acceseze registry</b>.
                    <br/>
                    <br/>
                    Alternativ, puteți trece optiunea <b>--engine-insecure-registry</b> atunci când motorul docker este pornit.


                    <hr/>
                    <b>Loguri</b>
                    <br/>
                    <br/>
                    Este recomandat să aveți o locație centrală pentru toate jurnalele de motor și container.
                    <br/>
                    Acest lucru oferă acces „în afara nodului” la toate jurnalele, dând putere dezvoltatorilor fără a fi necesar să le acorde acces SSH.
                    <br/>
                    <br/>
                    Pentru a activa înregistrarea centralizată, modificați <b>/etc/docker/daemon.json</b> și adăugați următoarele:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'{\n' +
                            '  "log-level": "syslog",\n' +
                            '  "log-opts": {syslog-address=tcp://192.x.x.x}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi reporniți demonul: <b>sudo systemctl restart docker</b>

                    <hr/>
                    <b>docker trust</b>
                    <br/>
                    <br/>
                    Pe care dintre următoarele opțiuni puteți utiliza pentru publicarea și gestionarea colecțiilor de conținut de încredere?
                    <br/>
                    docker trust
                    <br/>
                    <br/>

                    Începând cu Docker Engine 18.06, există comanda <b>docker trust</b> care va simplifica procesul de semnare a imaginii.
                    Varianta veche de semnare era/este <b>Notary</b>.
                    Notary este un instrument pentru publicarea și gestionarea colecțiilor de conținut de încredere.
                    Editorii pot semna digital colecții, iar consumatorii pot verifica integritatea și originea conținutului.
                    Această abilitate este construită pe o interfață simplă de gestionare a cheilor și semnare pentru a crea colecții semnate și a configura editorii de încredere.

                    <hr/>
                    Numai utilizatorii cu acces de scriere la un depozit pot începe manual o scanare. Utilizatorii cu acces numai în citire pot vizualiza rezultatele scanării, dar nu pot începe o nouă scanare.
                    <hr/>

                    Care dintre următoarele comenzi poate fi folosită pentru a reduce suprafața de atac dintr-un container? <b>--cap-drop</b>
                    <br/>

                    Capacitățile Linux sunt o modalitate și mai granulară de a reduce suprafața.
                    Docker Engine are o listă implicită de capabilități care sunt păstrate pentru containerele nou create și,
                    utilizând <b>--cap-drop</b>  pentru rularea docker,
                    utilizatorii pot exclude capabilitățile suplimentare de la utilizarea de către procesele din interiorul containerului, în funcție de capacitate.
                    <br/>
                    Toate privilegiile pot fi renunțate cu <b>--user</b>.
                    <br/>
                    <br/>
                    De asemenea, capabilitățile care, în mod implicit, nu sunt acordate containerelor noi pot fi adăugate cu opțiunea <b>--cap-add</b>.
                    Acest lucru este descurajat dacă nu este absolut necesar, iar utilizarea <b>--cap-add=ALL</b> este foarte descurajată.
                    <br/>
                    <br/>

                    Limitarea capacităților unui container reduce suprafața de atac.

                    <hr/>

                    Puteți avea o compoziție hibridă de utilizatori susținută de un grup LDAP/AD și configurarea manuală a utilizatorilor.  FALS

                    <br/>
                    <br/>
                    Subiecții sunt utilizatori individuali sau echipe din cadrul unei organizații.
                    Echipele sunt de obicei susținute de un grup LDAP/AD sau de un filtru de căutare.
                    De asemenea, este posibil să adăugați utilizatori manual.
                    Dar nu este posibil să existe o compoziție hibridă a utilizatorilor.
                    <br/>
                    Cu alte cuvinte, lista de utilizatori din cadrul unei echipe ar trebui să fie derivată de la un server de directoare (ex. AD) sau ar trebui adăugată manual, nu ambele.


                    <hr/>
                    Cand trebuie să schimbați în întregime stratul de imagine docker? Componentele imaginii de baza au vulnerabilitati critice.
                    <br/>
                    <br/>

                    Dacă vulnerabilitatea se află într-un strat de bază (cum ar fi un sistem de operare),
                    este posibil să nu puteți corecta problema din imagine,
                    deoarece atunci când un strat de bază trebuie reconstruit, toate straturile ulterioare trebuie să fie și ele reconstruite.
                    <br/>
                    <br/>


                    În acest caz, puteți trece la o versiune diferită a stratului de bază sau puteți găsi un strat de bază echivalent, mai puțin vulnerabil.
                    <br/>
                    De asemenea, puteți decide că vulnerabilitatea sau expunerea este acceptabilă.

                    <hr/>

                    Caracteristici de securitate disponibile ale Docker Engine:
                    <ul>
                        <li>
                            se poate utiliza autentificarea client-server pe baza de certificat pentru a varifica ca un daemon Docker are drepturi de acces la imagini dintr-un registru
                        </li>
                        <li>
                            se poate proteja socket-ul pentru daemonul Docker si asigura numai conexiuni de incredere pentru un client Docker
                        </li>
                        <li>
                            se pot configura politicile modului de calcul securizat (<b>Seccomp</b>) pentru a securiza apelurile de sistem dintr-un container
                        </li>
                        <li>
                            se pot configura functiile docker trust, astfel incat utulziatorii sa poate face pull si push la imagini de incredere
                        </li>
                    </ul>

                    În mod implicit, Docker rulează printr-un socket UNIX fără rețea.
                    <br/>
                    <br/>
                    De asemenea, poate comunica opțional folosind un socket HTTP.
                    Dacă aveți nevoie ca Docker să fie accesibil prin rețea într-o manieră sigură, puteți activa TLS specificând
                    indicatorul <b>tlsverify</b> și indicând indicatorul <b>tlscacert</b> al Docker către un certificat CA de încredere.
                    <br/>
                    <br/>
                    TLS trebuie să fie activat pentru ca clientul Docker și demonul să comunice în siguranță prin HTTPS.
                    <br/>
                    <br/>
                    În plus, TLS asigură autenticitatea punctului final al registrului și că traficul către/de la registru este criptat.
                    <br/>
                    <br/>
                    Pentru a vă asigura că traficul dintre serverul de registry Docker și demonul Docker (un client al serverului de registry) este criptat și autentificat corespunzător, utilizați autentificarea client-server bazată pe certificat.

                    <br/>
                    <br/>
                    În modul daemon, permite doar conexiuni de la clienți autentificați printr-un certificat semnat de acea CA.
                    <br/>
                    În modul client, se conectează doar la servere cu un certificat semnat de acea CA.
                    <br/>
                    <br/>
                    Docker Content Trust (DCT) oferă posibilitatea de a utiliza semnături digitale pentru datele trimise și primite de la registrele Docker la distanță.
                    Aceste semnături permit verificarea pe partea client sau în timpul executării a integrității și a editorului etichetelor de imagine specifice.
                    <br/>
                    <br/>
                    Verificarea semnăturii Docker Content Trust:
                    <br/>
                    Motorul Docker poate fi configurat să ruleze numai imagini semnate.
                    Caracteristica de verificare a semnăturii Docker Content Trust este încorporată direct în binarul dockerd.
                    <br/>
                    Acesta este configurat în fișierul de configurare Dockerd.
                    <br/>

                    Pentru a activa această caracteristică, trustpinning poate fi configurat în daemon.json, prin care numai depozitele semnate cu o cheie rădăcină specificată de utilizator pot fi extrase și rulate.

                    <br/>
                    <br/>
                    Modul de calcul securizat (seccomp) este o caracteristică a nucleului Linux.
                    Îl puteți folosi pentru a restricționa acțiunile disponibile în container.
                    Apelul de sistem seccomp() operează în starea seccomp a procesului de apelare.
                    Puteți utiliza această funcție pentru a restricționa accesul aplicației dvs.

                    <hr/>
                    Puteți configura DTR să vă utilizeze propriile certificate TLS, astfel încât să fie automat de încredere de browserul utilizatorilor dvs. și de instrumentele client? TRUE
                    <br/>
                    <br/>
                    Explicaţie:
                    <br/>

                    Serviciile UCP includ o componentă numită <b>ucp-auth-api</b>, care este serviciul centralizat pentru identitate și autentificare utilizat de UCP și DTR.
                    <br/>
                    <br/>


                    Toate serviciile UCP sunt expuse folosind HTTPS, pentru a se asigura că toate comunicațiile dintre clienți și UCP sunt criptate.
                    <br/>
                    <br/>
                    În mod implicit, acest lucru se face folosind certificate TLS autosemnate care nu sunt de încredere de instrumentele client, cum ar fi browserele web. Deci, atunci când încercați să accesați UCP, browserul vă avertizează că nu are încredere în UCP sau că UCP are un certificat nevalid.
                    Același lucru se întâmplă și cu alte instrumente pentru clienți.
                    <br/>
                    <br/>
                    Puteți configura UCP să vă utilizeze propriile certificate TLS, astfel încât să fie automat de încredere de către browser și instrumentele client.


                    <hr/>

                    De fiecare dată când un nou nod se alătură Swarm, managerul eliberează un certificat nodului. Adevărat sau fals? TRUE
                    <br/>
                    <br/>
                    Nodul de manager generează, de asemenea, două jetoane de utilizat atunci când uniți noduri suplimentare la swarm: un token de worker și unul de manager.
                    Fiecare token include rezumatul certificatului CA rădăcină și un secret generat aleatoriu.
                    Când un nod se alătură roiului, nodul care se alătură folosește digest-ul pentru a valida certificatul CA rădăcină de la managerul de la distanță.
                    Managerul de la distanță folosește secretul pentru a se asigura că nodul de alăturare este un nod aprobat.
                    <br/>
                    <br/>
                    De fiecare dată când un nou nod se alătură swarm, managerul eliberează un certificat nodului.
                    Certificatul conține un ID de nod generat aleatoriu pentru a identifica nodul sub numele comun al certificatului (CN) și rolul din unitatea organizațională (OU).
                    ID-ul nodului servește ca identitate criptografic sigură a nodului pe durata de viață a nodului din roiul curent.

                    <hr/>
                    Când efectuați operațiuni cu un registru Docker de la distanță, ce caracteristică Docker vă permite să impuneți semnarea și verificarea etichetelor de imagine la nivelul clientului?

                    <b>Docker Content Trust</b>

                    <hr/>
                    Este posibil să setați un certificat securizat care este valabil pentru mai multe adrese URL. Adevărat sau fals? ADEVARAT
                    <br/>
                    <br/>

                    Explicaţie
                    Utilizarea certificatelor externe este recomandată atunci când se integrează într-un mediu corporativ.
                    Utilizarea certificatelor externe, semnate oficial, simplifică distribuirea certificatelor interne de autoritate de certificare (CA).
                    O bună practică este să utilizați Autoritatea de Certificare pentru organizația dvs.
                    Reduceți numărul de certificate adăugând mai multe nume alternative de subiecte (SAN) la un singur certificat.
                    Acest lucru permite ca certificatul să fie valabil pentru mai multe adrese URL.
                    <br/>
                    De exemplu, puteți configura un certificat pentru ucp.example.com, dtr.example.com și toate numele de gazdă și adresele IP subiacente.
                    O pereche de certificat/cheie facilitează implementarea certificatelor.

                    <hr/>
                    Urmând principiul cel mai mic privilegiu, pe care dintre următoarele metode puteți folosi pentru a acorda acces în siguranță unui anumit utilizator pentru a comunica cu un motor Docker?

                    <br/>
                    Raspuns: utilizati openssl pentru a creea certificate de client si server TLS, configurand motorul docker pentru a fi utilizat prin TLS reciproc prin TCP.
                    <br/>
                    Explicaţie:
                    <br/>
                    Nu trebuie să rulați clientul docker cu sudo sau grupul docker atunci când utilizați autentificarea prin certificat .
                    Asta înseamnă că oricine are cheile poate da orice instrucțiuni demonului tău Docker, oferindu-i acces root la mașina care găzduiește demonul.
                    Cu toate acestea, păstrați aceste chei așa cum ați face cu o parolă de root!

                    <hr/>
                    Collections are groupings of objects within UCP. Which of the following is not a valid object for a collection? Firewall rules

                    <hr/>
                    What security needs does mutual Transport Layer Security (MTLS) satisfy in a swarm?
                    <ul>
                        <li>autorizatie</li>
                        <li>autentificare</li>
                        <li>criptarea comunicatiilor cu nodurile din Swarm</li>
                    </ul>

                    <hr/>
                    A team of developers needs root access to a node. What’s the proper way to handle the situation? Use RBAC Labels to give access to objects like images and running containers.


                    <hr/>
                    Which of the following options contribute directly to Docker security? (select all that apply)
                    <ul>
                        <li>namespaces</li>
                        <li>Seccomp</li>
                        <li>colectarea gunoiului (Garbage collection )
                            <br/>
                            Colectarea gunoiului este o zonă adesea trecută cu vederea din punct de vedere al securității. Imaginile vechi, învechite pot conține defecte de securitate sau vulnerabilități exploatabile; eliminarea imaginilor inutile este importantă.
                            Colectarea gunoiului este o caracteristică care asigură eliminarea imaginilor (și a straturilor) fără referință.
                        </li>
                    </ul>

                    <br/>
                    Pentru a securiza traficul UCP în modul Docker Swarm, trebuie să generați certificate extern sau să configurați manual orice CA. Adevărat sau fals? FALS
                    <br/>
                    Docker Swarm vine cu PKI integrat.
                    <br/>
                    Toți managerii și nodurile din Swarm au o identitate semnată criptografic sub forma unui certificat semnat.
                    <br/>
                    Toate comunicațiile de control de la manager la manager și de la manager la nod sunt securizate imediat cu TLS.
                    <br/>
                    Nu este nevoie să generați certificate extern sau să configurați manual CA pentru a asigura traficul din planul de control de la capăt la capăt în modul Docker Swarm.
                    Certificatele sunt rotate periodic și automat.

                    <hr/>
                    Ce indică următoarea eroare? x509: certificate signed by unknown authority. Raspuns: Docker nu a fost furnizat cu certificatul CA
                    <hr/>
                    The default roles in UCP are:
                    <b>None, View Only, Restricted Control, Scheduler, and Full Control</b>

                    <hr/>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SecurityDockerContent;