import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CustomJspBagVsOSGiFragmentsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-custom-jsp-bag-vs-osgi-fragments", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Diferente dintre Custom JSP Bag si OSGi Fragments</b>
                    <br/>
                    <br/>

                    Diferența dintre un Custom JSP Bag și OSGi Fragments în Liferay se referă la modul în care acestea sunt utilizate pentru a personaliza sau a extinde funcționalitățile platformei Liferay.
                    <hr/>

                    <b>Custom JSP Bag</b>
                    <ul>
                        <li>
                            <b>Scop:</b> Custom JSP Bags sunt utilizate pentru a suprascrie sau a modifica fișierele JSP (JavaServer Pages) standard ale Liferay.
                            Acest lucru este util pentru personalizarea aspectului UI sau a comportamentului paginilor web.
                        </li>
                        <li>
                            <b>Implementare</b>: Se creează un modul OSGi care conține fișierele JSP personalizate.
                            Acest modul trebuie să implementeze interfața <b>CustomJspBag</b> pentru a indica Liferay care sunt fișierele JSP ce trebuie suprascrise.
                        </li>
                        <li>
                            <b>Actualizări și mentenanță</b>: Modificările realizate prin Custom JSP Bags pot fi afectate de actualizările Liferay,
                            deoarece actualizările la fișierele JSP originale pot necesita ajustări corespunzătoare în fișierele JSP personalizate.
                        </li>
                    </ul>

                    <b>OSGi Fragments</b>
                    <ul>
                        <li>
                            <b>Scop</b>: OSGi Fragments sunt utilizate pentru a extinde sau modifica orice modul OSGi existent în Liferay, inclusiv, dar nu limitat la, fișierele JSP.
                            Ele pot fi folosite pentru a adăuga funcționalități suplimentare sau a modifica logica de business.
                        </li>
                        <li>
                            <b>Implementare</b>: Un Fragment OSGi este un tip special de modul OSGi care este atașat la un alt modul OSGi (gazdă).
                            Acesta poate conține clasă Java, resurse, și/sau fișiere JSP care suprascriu sau extind modulele gazdă.
                        </li>
                        <li>
                            <b>Actualizări și mentenanță</b>: Fiind strâns legate de modulele OSGi gazdă, fragmentele pot necesita actualizări atunci când modulele gazdă sunt actualizate.
                            Totuși, ele oferă un grad mai mare de flexibilitate și control comparativ cu Custom JSP Bags.
                        </li>
                    </ul>

                    În concluzie, principala diferență constă în scopul și flexibilitatea lor: Custom JSP Bags sunt mai focalizate pe personalizarea UI prin JSP-uri,
                    în timp ce OSGi Fragments oferă o abordare mai largă și mai flexibilă pentru extinderea sau modificarea oricărui aspect al unui modul OSGi, inclusiv, dar nu limitat la, JSP-uri.


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://help.liferay.com/hc/en-us/articles/360029122491-JSP-Overrides-Using-Custom-JSP-Bag"}>
                                    JSP Overrides Using Custom JSP Bag
                                </a>
                            </div>
                        </li>



                        <li>
                            <div>
                                <a target={"_blank"} href={"https://help.liferay.com/hc/en-us/articles/360017891932-Module-JSP-Override-"}>
                                    Module JSP Override
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/overriding-module-jsp-in-liferay-7-using-liferay-ide"}>
                                    Overriding Module JSP in Liferay 7 using Liferay IDE
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://lifedev-solutions.blogspot.com/2023/03/liferay-module-jsp-override.html"}>
                                    Liferay Module JSP Override
                                </a>
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomJspBagVsOSGiFragmentsLiferayContent;