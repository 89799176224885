import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class InspectNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-inspect", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Inspectare retea: docker network inspect</b>
                    <br/>
                    <br/>

                    Inspectare retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect bridge'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "bridge",\n' +
                            '        "Id": "2be808aad381d0071099ea4d164d3874171cbac8b74cbd50404b4266acac5dc9",\n' +
                            '        "Created": "2022-10-12T22:02:25.426814866+03:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": null,\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.17.0.0/16",\n' +
                            '                    "Gateway": "172.17.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {\n' +
                            '            "213e8cf4e499e2207e29bc9940ed8d328b86ae53517983ba1de70a89ae858a36": {\n' +
                            '                "Name": "fervent_driscoll",\n' +
                            '                "EndpointID": "7ec8390f7e5a7d4db5adaa2b319bf7c8e3f607bbdc1677a7173813dc85c374d8",\n' +
                            '                "MacAddress": "02:42:ac:11:00:03",\n' +
                            '                "IPv4Address": "172.17.0.3/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "4ff5b2b1004427cb772fbaac644715a60a4789e89158856076f66ac77f6f858c": {\n' +
                            '                "Name": "wizardly_kowalevski",\n' +
                            '                "EndpointID": "114386d324456aca68405b12ff23e757d32c044559f0088c4db4aaeb6af3c6ba",\n' +
                            '                "MacAddress": "02:42:ac:11:00:02",\n' +
                            '                "IPv4Address": "172.17.0.2/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "5a4cdd28b1fe32ae9e455c516daecd776c98ecb4cf7afaec11f1b7b8793de874": {\n' +
                            '                "Name": "loving_pasteur",\n' +
                            '                "EndpointID": "486c556cfab4717aec34e79e783c5eab8c31c98ff26f6cefb778b91230fcd436",\n' +
                            '                "MacAddress": "02:42:ac:11:00:04",\n' +
                            '                "IPv4Address": "172.17.0.4/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n' +
                            '        "Options": {\n' +
                            '            "com.docker.network.bridge.default_bridge": "true",\n' +
                            '            "com.docker.network.bridge.enable_icc": "true",\n' +
                            '            "com.docker.network.bridge.enable_ip_masquerade": "true",\n' +
                            '            "com.docker.network.bridge.host_binding_ipv4": "0.0.0.0",\n' +
                            '            "com.docker.network.bridge.name": "docker0",\n' +
                            '            "com.docker.network.driver.mtu": "1500"\n' +
                            '        },\n' +
                            '        "Labels": {}\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>

                    Inspectare retea, cu filtrare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect --format "{{.Scope}}" bridge'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'local'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect --format "{{.ID}}:{{.Name}}" bridge'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'2be808aad381d0071099ea4d164d3874171cbac8b74cbd50404b4266acac5dc9:bridge'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InspectNetworkDockerContent;