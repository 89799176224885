import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaServletFilterLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-portlet-filter", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Servlet Filter</b>
                    <br/>
                    <br/>

                    Filtrele servlet pot atât preprocesa cererile pe măsură ce sosesc, cât și postprocesează răspunsurile înainte ca acestea să ajungă la browserul clientului. Acestea vă permit să aplicați funcționalitatea solicitărilor și răspunsurilor pentru mai multe servlet-uri, fără ca servleturile să știe.
                    Iată câteva cazuri comune de utilizare a filtrelor:
                    <ul>
                        <li>Logging</li>
                        <li>Audit</li>
                        <li>Securitate</li>
                        <li>Managementul tranzacțiilor</li>
                    </ul>

                    build.gradle
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                            '    compileOnly group: "com.liferay.portal", name: "release.portal.api", version: "7.4.3.120-ga120"\n' +
                            '\n' +
                            '    compileOnly group: "javax.servlet", name: "javax.servlet-api", version: "3.0.1"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    bnd.bnd
                    <SyntaxHighlighter>
                        {'Bundle-Name: Agnes Audit Servlet Filters\n' +
                            'Bundle-SymbolicName: ro.agnes.servlet.filter\n' +
                            'Bundle-Version: 1.0.0\n'}
                    </SyntaxHighlighter>

                    AuditServletFilter:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.servlet.filter;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.servlet.BaseFilter;\n' +
                            'import com.liferay.portal.kernel.util.PortalUtil;\n' +
                            'import com.liferay.portal.kernel.util.WebKeys;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            'import javax.servlet.*;\n' +
                            'import javax.servlet.http.HttpServletRequest;\n' +
                            'import java.io.IOException;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                "servlet-context-name=",\n' +
                            '                "servlet-filter-name=Agnes Audit Servlet Filter",\n' +
                            '                "dispatcher=REQUEST",\n' +
                            '                "dispatcher=FORWARD",\n' +
                            '                "url-pattern=/*"\n' +
                            '        },\n' +
                            '        service = Filter.class\n' +
                            ')\n' +
                            'public class AuditServletFilter extends BaseFilter {\n' +
                            '\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void init(FilterConfig filterConfig) {\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void destroy() {\n' +
                            '    }\n' +
                            '\n' +
                            '    public void doFilter(ServletRequest servletRequest, ServletResponse servletResponse, FilterChain filterChain) throws IOException, ServletException {\n' +
                            '\n' +
                            '        try {\n' +
                            '\n' +
                            '            HttpServletRequest request = (HttpServletRequest) servletRequest;\n' +
                            '\n' +
                            '            String uri = (String) servletRequest.getAttribute(WebKeys.INVOKER_FILTER_URI);\n' +
                            '            String currentURL = PortalUtil.getCurrentURL(request);\n' +
                            '\n' +
                            '            if (!currentURL.startsWith("/o") &&\n' +
                            '                    !currentURL.startsWith("/image") &&\n' +
                            '                    !currentURL.startsWith("/combo")) {\n' +
                            '                // ignor sa se afiseze .js-urile sau imaginile\n' +
                            '               // _log.info("doFilter(" + servletRequest + ", " + servletResponse + ", " + filterChain + ") for URI " + uri + " (url = " + currentURL + ")");\n' +
                            '                _log.info("doFilter() / (url = " + currentURL + ")");\n' +
                            '            }\n' +
                            '\n' +
                            '            filterChain.doFilter(servletRequest, servletResponse);\n' +
                            '\n' +
                            '            // daca nu se indeplinesc niste conditii se poate si redirecta\n' +
                            '//            HttpServletResponse response = (HttpServletResponse) servletResponse;\n' +
                            '//            response.sendRedirect("/web/login");\n' +
                            '//\n' +
                            '        } catch (Exception e) {\n' +
                            '            _log.error(e.getMessage(), e);\n' +
                            '            filterChain.doFilter(servletRequest, servletResponse);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected Log getLog() {\n' +
                            '        return _log;\n' +
                            '    }\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(AuditServletFilter.class);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaServletFilterLiferayContent;