import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DisablePasswordReminderQuestionsFirstLoginLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-disable-password-reminder-questions-first-login", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    In <b>portal-ext.properties</b>:
                    <SyntaxHighlighter>
                        {'users.reminder.queries.enabled=false'}
                    </SyntaxHighlighter>

                    Restartare aplicatie

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://liferayiseasy.blogspot.com/2022/06/how-to-enable-jquery-in-liferay-dxp-73.html"}>*/}
                        {/*            How to enable jQuery in Liferay DXP (7.3)*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DisablePasswordReminderQuestionsFirstLoginLiferayContent;