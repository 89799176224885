import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"elasticsearch-index", url:"#", title:"Elasticsearch", subtitle:""},

        {id:"elasticsearch-install", url:"/elasticsearch/install", title:"Instalare", subtitle:"", parent:"elasticsearch-index"},
        {id:"elasticsearch-intro", url:"/elasticsearch/intro", title:"Introducere", subtitle:"", parent:"elasticsearch-index"},
        {id:"elasticsearch-connecting-cluster", url:"/elasticsearch/connecting-cluster", title:"Conectare la Cluster", subtitle:"", parent:"elasticsearch-index"},
        {id:"elasticsearch-mapping", url:"/elasticsearch/mapping", title:"Mapare", subtitle:"", parent:"elasticsearch-index"},
        {id:"elasticsearch-insert", url:"/elasticsearch/insert", title:"Inserare", subtitle:"", parent:"elasticsearch-index"},

        {id:"elasticsearch-docker", url:"/elasticsearch/docker", title:"Docker", subtitle:"", parent:"elasticsearch-index"},

    ]

    static indexUrl = "/elasticsearch/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Tips & Tricks
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;