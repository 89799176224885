import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NginxK8sContent extends BaseContentPage {

    constructor(props) {
        super(props, "kubernetes-nginx", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}


                <div className={"text-justify important"}>

                    <b>Nginx</b>
                    <br/>
                    <br/>

                    <b>0. Mediu</b>
                    <br/>
                    <br/>

                    Ne conectam pe masina:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'ssh kj@192.168.1.10 -p 22'}
                    </SyntaxHighlighter>

                    Se creaza directorul <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'mkdir liferay'}
                    </SyntaxHighlighter>

                    Navigam in directorul nou creat <i>liferay</i>:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'cd liferay'}
                    </SyntaxHighlighter>

                    Verificare minikube:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Stopped\n' +
                            'kubelet: Stopped\n' +
                            'apiserver: Stopped\n' +
                            'kubeconfig: Stopped\n'}
                    </SyntaxHighlighter>
                    Daca este oprit, se poate porni:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>

                    Verificare din nou status:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube status'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'minikube\n' +
                            'type: Control Plane\n' +
                            'host: Running\n' +
                            'kubelet: Running\n' +
                            'apiserver: Running\n' +
                            'kubeconfig: Configured\n'}
                    </SyntaxHighlighter>

                    Afisare detalii:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'minikube profile list'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| Profile  | VM Driver | Runtime |      IP      | Port | Version | Status  | Nodes | Active |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n' +
                            '| minikube | docker    | docker  | 192.168.49.2 | 8443 | v1.25.2 | Running |     1 | *      |\n' +
                            '|----------|-----------|---------|--------------|------|---------|---------|-------|--------|\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Deployment </b>
                    <br/>
                    <br/>

                    Activare:
                    <SyntaxHighlighter>
                        {'minikube addons enable ingress'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'💡  ingress is an addon maintained by Kubernetes. For any concerns contact minikube on GitHub.\n' +
                            'You can view the list of minikube maintainers at: https://github.com/kubernetes/minikube/blob/master/OWNERS\n' +
                            '    ▪ Using image k8s.gcr.io/ingress-nginx/kube-webhook-certgen:v1.1.1\n' +
                            '    ▪ Using image k8s.gcr.io/ingress-nginx/kube-webhook-certgen:v1.1.1\n' +
                            '    ▪ Using image k8s.gcr.io/ingress-nginx/controller:v1.2.1\n' +
                            '🔎  Verifying ingress addon...\n' +
                            '🌟  The \'ingress\' addon is enabled\n'}
                    </SyntaxHighlighter>

                    Creare fisier:
                    <SyntaxHighlighter>
                        {'nano nginx-ingress.yml'}
                    </SyntaxHighlighter>
                    cu continutul:
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1beta1\n' +
                            'kind: Ingress\n' +
                            'metadata:\n' +
                            '  name: nginx-ingress\n' +
                            '  annotations:\n' +
                            '    nginx.ingress.kubernetes.io/affinity: "cookie"\n' +
                            '    nginx.ingress.kubernetes.io/session-cookie-name: "route"\n' +
                            '    nginx.ingress.kubernetes.io/session-cookie-expires: "172800"\n' +
                            '    nginx.ingress.kubernetes.io/session-cookie-max-age: "172800"\n' +
                            '\n' +
                            'spec:\n' +
                            '  rules:\n' +
                            '  - host: liferay.kubernetes.com\n' +
                            '    http:\n' +
                            '      paths:\n' +
                            '      - backend:\n' +
                            '          serviceName: liferay--cluster\n' +
                            '          servicePort: 8080\n' +
                            '        path: /'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl apply -f nginx-ingress.yaml -n=liferay'}
                    </SyntaxHighlighter>

                    Verificam ce IP a fost atribuit
                    <SyntaxHighlighter>
                        {'kubectl get ingress -n=liferay'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter>
                        {'sudo nano /etc/hosts'}
                    </SyntaxHighlighter>

                    Se adauga intrarea:
                    <SyntaxHighlighter>
                        {'IP liferay.kubernetes.com'}
                    </SyntaxHighlighter>
                    unde IP poate fi 192.168.64.7

                    Verificare:
                    <SyntaxHighlighter>
                        {'http://liferay.kubernetes.com'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NginxK8sContent;