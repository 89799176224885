import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class InterruptsArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-interrupts", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Intreruperi</b>
                    <br/>
                    <br/>

                    Exista anumiti pini care suporta intreruperi. De exemplu, de Arduino Uno pinii sunt:
                    <ul>
                        <li>
                            2
                        </li>
                        <li>
                            3
                        </li>
                    </ul>

                    Limitari:
                    <ul>
                        <li>
                            functia de intrerupere nu returneaza nici o valoare (void)
                        </li>
                        <li>
                            functia de intrerupere nu primeste nici un parametru
                        </li>
                        <li>
                            functia de intrerupere trebuie sa fie rapida (de exemplu sa schimbe doar valoarea unei variabile)
                        </li>
                        <li>
                            se folosesc variabile marcate va fiind <b>volatile</b> pentru a modifica o intrerupere
                        </li>
                        <li>
                            nu se folosesc functiile:
                            <ul>
                                <li>
                                    delay()
                                </li>
                                <li>
                                    delayMicroseconds()
                                </li>
                            </ul>
                        </li>
                        <li>
                            functiile:
                            <ul>
                                <li>
                                    millis()
                                </li>
                                <li>
                                    micros()
                                </li>
                            </ul>
                            se pot folosi 1 data la inceputul functiei de intrerupere
                        </li>
                        <li>
                            intr-o functie de intrerupere nu se foloseste <b>Serial</b>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'const byte ledPin = 13;\n' +
                            'const byte interruptPin = 2;\n' +
                            'volatile byte state = LOW;\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  pinMode(ledPin, OUTPUT);\n' +
                            '  pinMode(interruptPin, INPUT_PULLUP);\n' +
                            '  attachInterrupt(digitalPinToInterrupt(interruptPin), blink, CHANGE);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  digitalWrite(ledPin, state);\n' +
                            '}\n' +
                            '\n' +
                            'void blink() {\n' +
                            '  state = !state;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>
                            se foloseste metoda: <b>attachInterrupt()</b>, cu sintaxa:
                            <SyntaxHighlighter>
                                {'attachInterrupt(digitalPinToInterrupt(pin), ISR, mode)'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>pin = pinul intrerupt, se foloseste cu functia <b>digitalPinToInterrupt()</b> pentru ca:
                                    <br/>
                                    Pinii specifici cu întreruperi și maparea lor la numărul de întreruperi variază pentru fiecare tip de placă
                                </li>
                                <li>
                                    ISR = functia care se va apela (fara parametrii de intrare si iesire)
                                </li>
                                <li>
                                    mode, definește când trebuie declanșată întreruperea, putand avea urmatoarele valori valide:
                                    <ul>
                                        <li>
                                            LOW - pentru a declanșa întreruperea ori de câte ori pinul este jos
                                        </li>
                                        <li>
                                            CHANGE - pentru a declanșa întreruperea ori de câte ori pinul își schimbă valoarea
                                        </li>
                                        <li>
                                            RISING - pentru a se declanșa atunci când pinul trece de la jos la sus
                                        </li>
                                        <li>
                                            CĂDEREA - pentru a se declanșa atunci cand pinul trece de la sus la mai jos.
                                        </li>
                                        <li>
                                            HIGH - pentru a declanșa întreruperea ori de câte ori pinul este ridicat (doar pentru păcile Due, Zero și MKR1000)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*     */}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InterruptsArduinoContent;