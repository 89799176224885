import React from "react";
import Canvas from "../../component/Canvas";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class HandwrittenContent extends BaseContentPage {

    constructor(props) {
        super(props, "handwritten", IndexContent);
        this.canvasRef = React.createRef();
    }

    async componentDidMount() {

        this.state = {
            random: 0,
            guess: null,
            verify: true,
        }

        this.next();
    }

    verify() {
       // this.setState({"verify": false});

        let image = this.canvasRef.current.getDataImage();
        const formData = new FormData();
        formData.append("data", image);
        formData.append("label", this.state.random);

        fetch(process.env.REACT_APP_DOPAMINA_DATA_API_URL + '/handwritten/getGuess', {
                method: 'POST',
                body:formData
            })
            .then(response => response.json())
            .then(jsondata => {

                this.setState(prevState => ({
                    ...prevState,
                    guess: jsondata.guess,
                    verify:false

                }))
            });
    }

    next() {
        this.setState({
            "random": Math.floor(Math.random() * 10),
            "verify": true
        })
        this.canvasRef.current.clear();
    }

    render() {

        return (
            <div className="home index-page ann">

                {this.title()}
                {this.navigator()}

                {/*<h3 style={{ textAlign: 'center' }}>Handwritten</h3>*/}
                <div className="main">

                    <div className="color-guide">
                            <span className={"h5"}>
                                <span>Draw</span>
                                <span className="badge badge-pill badge-warning m-1 p-1">{this.state.random}</span>
                            </span>
                    </div>

                    <Canvas ref={this.canvasRef}/>

                    <div>
                        {this.state.verify ? (
                            <button type="button" className={"btn btn-warning"} onClick={() => this.verify()}>
                                Verify
                            </button>
                        ) : (
                            <div>
                                <div className="color-guide">
                                    <h5>I think you drew {this.state.guess}</h5>
                                </div>
                                <button type="button" className={"btn btn-info"} onClick={() => this.next()}>
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default HandwrittenContent;