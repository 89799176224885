import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class MockDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-mock", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. How do you interpret a "running container" in a Linux environment?</b>
                    <ul>
                        <li>
                            Process
                        </li>
                    </ul>

                    <hr/>
                    <b>2. What is the scope of default <b>bridge</b> network in Docker?</b>
                    <ul>
                        <li>
                            local
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Which command will you use to list out tasks of one or more services running on a swarm cluster?</b>
                    <ul>
                        <li>
                            docker service ps
                        </li>
                    </ul>

                    <hr/>
                    <b>4. Which Docker command is used to list out bind-mounts on a Docker host?</b>
                    <ul>
                        <li>
                            bind-mounts cannot be managed by Docker CLI
                        </li>
                    </ul>

                    <hr/>
                    <b>5. You wanted to calculate the value of Pi up to 40 decimals using a containerized python application as a Kubernetes job. What will be the state of pod once the task is finished?</b>
                    <ul>
                        <li>
                            Completed
                        </li>
                    </ul>

                    <hr/>
                    <b>6. Which Dockerfile instruction is used to set the system call signal that will be sent to the container to exit?</b>
                    <ul>
                        <li>
                            STOPSIGNAL
                        </li>
                    </ul>

                    <hr/>
                    <b>7. What is the purpose of Replicasets?</b>
                    <ul>
                        <li>
                            To keep a stable set of replicated Pods running at a time.
                        </li>
                        <li>
                            GRESIT: To create a certain number of pods to successfully complete a job.
                        </li>
                    </ul>

                    <hr/>
                    <b>8. Which of the following is the smallest deployable unit in Kubernetes?
                    </b>
                    <ul>
                        <li>
                            Pod
                        </li>
                    </ul>

                    <hr/>
                    <b>9. Where does docker daemon store Docker objects related data such as Docker Images, Volumes, secrets, etc... on a Linux host system?
                    </b>
                    <ul>
                        <li>
                            /var/lib/docker
                        </li>
                    </ul>

                    <hr/>
                    <b>10. Which Docker network assigns MAC address to container's virtual network interface to make it appear as directly connected to the physical network of Docker host?
                    </b>
                    <ul>
                        <li>
                            macvlan
                        </li>
                    </ul>

                    <hr/>
                    <b>11. Which of the following services does not allow you to access your containers outside the cluster?</b>
                    <ul>
                        <li>
                            ClusterIP
                        </li>
                    </ul>

                    <hr/>
                    <b>12. What is the role of Orchestrator in Swarm?</b>
                    <ul>
                        <li>
                            To create tasks for service objects in swarm.
                        </li>
                        <li>GRESIT: To accept the command and create a service object.</li>
                        <li>GRESIT: To allocate IP addresses to tasks.</li>
                        <li>GRESIT: To assign tasks on swarm nodes.</li>
                        <li>GRESIT: To execute tasks assigned to worker nodes..</li>
                    </ul>

                    <hr/>
                    <b>13. Which kubectl command will you use to scale up a deployment to create five more pod replicas?</b>
                    <ul>
                        <li>
                            kubectl scale [deployment-name] --replicas=5
                        </li>
                        <li>GRESIT: kubectl deployment scale [deployment-name] --replicas=5</li>
                    </ul>

                    <hr/>
                    <b>14. Which Kubernetes component watches Pod creation process and schedules newly created pods on suitable cluster node?</b>
                    <ul>
                        <li>
                            kube-scheduler
                        </li>
                        <li>GRESIT: kube-controller-manager</li>
                    </ul>

                    <hr/>
                    <b>15. You have a swarm cluster which consists of 27 active nodes. Out of which, 9 nodes are managers, and one node is the leader. What will be the fault tolerance and quorum of this swarm cluster respectively?
                       </b>
                    <ul>
                        <li>
                            4, 5
                        </li>
                    </ul>

                    <hr/>
                    <b>16. What is the role of Scheduler in Swarm?
                    </b>
                    <ul>
                        <li>
                            To instruct worker node to start running a task.
                        </li>
                        <li>GRESIT: To assign tasks on swarm nodes.</li>
                        <li>GRESIT: To execute tasks assigned to worker nodes.</li>
                    </ul>

                    <hr/>
                    <b>17.
                        What is the default container format of Docker?
                    </b>
                    <ul>
                        <li>
                            libcontainer
                        </li>
                    </ul>

                    <hr/>
                    <b>18. Which instruction is used to set the base image for the subsequent builds in the Dockerfile?</b>
                    <ul>
                        <li>
                            FROM
                        </li>
                    </ul>

                    <hr/>
                    <b>19. Which logging-drivers are supported by Docker?
                    </b>
                    <ul>
                        <li>
                            json-file
                        </li>
                        <li>
                            Splunk
                        </li>
                        <li>
                            syslog
                        </li>

                        <li>
                            fluentd
                        </li>
                    </ul>

                    <hr/>
                    <b>20.
                        Which of the following describes Kubernetes in the most suitable way?
                    </b>
                    <ul>
                        <li>
                            An open-source platform to manage containerized workloads.
                        </li>
                    </ul>

                    <hr/>
                    <b>21. docker service scale command can be applied only on…
                    </b>
                    <ul>
                        <li>
                            replicated service
                        </li>
                    </ul>

                    <hr/>
                    <b>22. Which components are active on Kubernetes master?
                    </b>
                    <ul>
                        <li>
                            etcd
                        </li>
                        <li>
                            kubelet
                        </li>
                        <li>
                            kube-apiserver
                        </li>
                        <li>
                            kube-scheduler
                        </li>
                        <li>
                            kube-proxy
                        </li>
                    </ul>

                    <hr/>
                    <b>23.
                        Which of the following apiVersion applies to pods
                    </b>
                    <ul>
                        <li>
                            v1
                        </li>
                    </ul>

                    <hr/>
                    <b>24. Following is a YAML configuration of a standard Kubernetes pod. Choose which container image registry will be used by default to pull nginx image for this pod.
                    </b>
                    <ul>
                        <li>
                            Docker Hub
                        </li>
                    </ul>

                    <hr/>
                    <b>25. Which command will you use to clean up dangling Docker Images from your Docker host?
                    </b>
                    <ul>
                        <li>
                            docker image prune
                        </li>
                    </ul>

                    <hr/>
                    <b>26. Which command will you use to list out the contents of /etc directory of a running Ubuntu Container, without interrupting container's default process?
                    </b>
                    <ul>
                        <li>
                            docker exec [container_name] ls /etc
                        </li>
                    </ul>


                    <hr/>
                    <b>27. Which process does DTR carry out to automatically delete unused Docker Image layers stored on it?
                    </b>
                    <ul>
                        <li>
                            Garbage Collection
                        </li>
                    </ul>


                    <hr/>
                    <b>28. You tried to spin up a container using Kubernetes Pod, but because of the improper image URL, Pod has not been scheduled successfully. What would be the current state of Pod?
                    </b>
                    <ul>
                        <li>
                            Pending
                        </li>
                    </ul>

                    <hr/>
                    <b>29. Which key-value store is used to store all cluster data in Kubernetes?
                    </b>
                    <ul>
                        <li>
                            etcd
                        </li>
                    </ul>

                    <hr/>
                    <b>30. Which command will you use to remove UCP from an active swarm cluster in Docker?
                    </b>
                    <ul>
                        <li>
                            docker run --rm -it -v /var/run/docker.sock:/var/run/docker.sock mirantis/ucp uninstall-ucp --interactive
                        </li>
                    </ul>

                    <hr/>
                    <b>31. You create a website with a visitor counter and host it using a containerized webserver. The data of the visitor count is stored in tmpfs storage. What will happen to this data once the container stops running?
                    </b>
                    <ul>
                        <li>
                            Visitor data is lost from tmpfs storage.
                        </li>
                    </ul>

                    <hr/>
                    <b>32. Which command will you use to rename busybox:latest as busybox:v1</b>
                    <ul>
                        <li>
                            docker tag busybox:latest busybox:v1
                        </li>
                    </ul>

                    <hr/>
                    <b>33 .Which kubectl command will give you information such as what node and IP address a pod is on? And any failure events?</b>
                    <ul>
                        <li>
                            kubectl describe pod [pod-name]
                        </li>
                    </ul>

                    <hr/>
                    <b>34. Which docker network is used to connect individual docker daemons participating in swarm cluster?</b>
                    <ul>
                        <li>
                            docker_gwbridge
                        </li>
                    </ul>

                    <hr/>
                    <b>35. Which container runtimes are supported by Kubernetes (up to v1.19)?</b>
                    <ul>
                        <li>

                            containerd
                        </li>
                        <li>
                            Docker
                        </li>
                        <li>
                            CRI-O
                        </li>
                    </ul>

                    <hr/>
                    <b>36. Which command will you use to revoke cluster management and orchestrating privileges from manager node?</b>
                    <ul>
                        <li>
                            docker node demote
                        </li>
                    </ul>

                    <hr/>
                    <b>37. You have built your Docker Image using Docker Daemon on your system and now you want to deliver it as a tar archive to your client. Which command will you use?</b>
                    <ul>
                        <li>
                            docker save
                        </li>
                        <li>
                            GRESIT: docker export
                        </li>
                        <li>
                            GRESIT: docker commit
                        </li>
                    </ul>

                    <hr/>
                    <b>38. Which command is used to list out all the intermediate layers of a Docker image?
                    </b>
                    <ul>
                        <li>
                            docker history
                        </li>
                    </ul>

                    <hr/>
                    <b>39. Which command will you use to deploy a new stack of swarm services?
                    </b>
                    <ul>
                        <li>
                            docker stack deploy -c [compose file] [stack-name]
                        </li>
                    </ul>

                    <hr/>
                    <b>40. What is the outcome of the container created from the following Dockerfile?
                    </b>
                    <ul>
                        <li>
                            Container ping localhost for 6 times and exit with code 0
                        </li>
                    </ul>


                    <hr/>
                    <b>41. You have a swarm cluster of 22 nodes, out of which 9 are managers. How can you divide them across 3 availability zones?</b>
                    <ul>
                        <li>
                            3-3-3
                        </li>
                    </ul>

                    <hr/>
                    <b>42. What is the correct order of service creation process in swarm mode?
                    </b>
                    <ul>
                        <li>
                            docker API {">"} orchestrator {">"} allocator {">"} dispatcher {">"} scheduler
                        </li>
                    </ul>

                    <hr/>
                    <b>43. Which are the exec and shell forms of ENTRYPOINT Dockerfile instruction?</b>
                    <ul>
                        <li>
                            ENTRYPOINT ["executable ", "param1" ] , ENTRYPOINT command param1
                        </li>
                    </ul>

                    <hr/>
                    <b>44. Which software release channels are used for Docker Community Edition?
                    </b>
                    <ul>
                        <li>
                            Stable
                        </li>
                        <li>
                            Test
                        </li>
                    </ul>

                    <hr/>
                    <b>45. Which network driver is not supported by Docker natively?</b>
                    <ul>
                        <li>
                            Load Balancer
                        </li>
                        <li>
                            VPN
                        </li>
                    </ul>

                    <hr/>
                    <b>46. One of the swarm cluster's node is facing some troubles. Which command will you run on manager node to find out about the target node's ID, status, and its availability?</b>
                    <ul>
                        <li>
                            docker node ls
                        </li>
                    </ul>

                    <hr/>
                    <b>47. Which ports on Docker host must be available for communication to install and manage Swarm mode?</b>
                    <ul>
                        <li>
                            UDP Port 4789
                        </li>
                        <li>
                            TCP and UDP Port 7946
                        </li>

                    </ul>

                    <hr/>
                    <b>48. Which of the following commands is used to pretty-print the list of all the containers to display only containers’ IDs, names and statuses in tabular form?</b>
                    <ul>
                        <li>
                            <SyntaxHighlighter>
                                {'docker ps --format "table {{.ID}}\\t{{.Names}}\\t{{.Status}}"'}
                            </SyntaxHighlighter>

                        </li>

                    </ul>

                    <hr/>
                    <b>49. Which statement(s) are correct about Docker Images?
                    </b>
                    <ul>
                        <li>
                            All Docker Images layers are Read-Only.
                        </li>
                        <li>
                            Each layer of a Docker Image is a delta of the previous layer.
                        </li>

                    </ul>

                    <hr/>
                    <b>50. You have specified multiple WORKDIR instructions in the Dockerfile, what will be the output of RUN instruction in the container?</b>
                    <ul>
                        <li>
                            /x/y/z
                        </li>

                    </ul>

                    <hr/>
                    <b>51. Which Docker command will you use to initialize a new swarm cluster?
                    </b>
                    <ul>
                        <li>
                            docker swarm init --advertise-addr [managerIP]
                        </li>

                    </ul>

                    <hr/>
                    <b>52. You need to create a MySQL database container. Which command will you use to instruct Docker Daemon to create it?</b>
                    <ul>
                        <li>
                            docker create --name database mysql:5.4
                        </li>
                        <li>
                            docker container create --name database mysql:5.4
                        </li>

                    </ul>

                    <hr/>
                    <b>53. In a Kubernetes cluster, which process is responsible for running the containers in a scheduled Pod?</b>
                    <ul>
                        <li>
                            kubelet
                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a target={"_blank"} href={"https://medium.com/bb-tutorials-and-thoughts/250-practice-questions-for-the-dca-exam-84f3b9e8f5ce"}>
                                    250 Practice Questions for the DCA Exam
                                </a>
                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MockDockerContent;