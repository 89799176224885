import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class TranslatorsContent extends BaseContentPage {

    constructor(props) {
        super(props, "compiler-translators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Un <b>translator</b>/<b>traducator</b> este un termen generic pentrun un instrument de <i>conversie</i> a codului software.
                    Poate fi un:
                    <ul>
                        <li><b>compilator</b>: traduce codul de limbaj de nivel înalt (Java, C, etc) în cod de mașină (cod obiect);
                            <br/>
                            un compilator este:
                            <ul>
                                <li>dependent de procesor</li>
                                <li>dependent de platforma</li>
                            </ul>

                        </li>
                        <li><b>interpretor</b>: traduce codul ca un compilator, dar citeste codul si executa imediat codul; exemple de limbaje interpretate (Python, PHP)

                        </li>
                        <li><b>asamblator</b>: traduce un program scris în limbaj de asamblare în limbaj de mașină (cod obiect)</li>
                    </ul>



                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.microcontrollertips.com/compilers-translators-interpreters-assemblers-faq/"} target={"_blank"}>What are compilers, translators, interpreters, and assemblers?</a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://ruslanspivak.com/lsbasi-part1"} target={"_blank"}>Let’s Build a Simple Interpreter</a>
                            </div>
                        </li>

                        <li>
                            <div>Compilers: Principles, Techniques, & Tools (Second Edition) - Alfred V. Aho, Monica S. Lam, Ravi Sethi, Jeffrey D. Ullman   (Pearson, 2006)</div>
                        </li>
                        <li>
                            <div>Writing Compilers and Interpreters. A Software Engineering Approach (Third Edition) - Ronald Mak (Wiley, 2009)</div>
                        </li>
                        <li>
                            <div>Tehnici de compilare - Mircea Drăgan (2019)</div>
                        </li>
                        <li>
                            <div>Modele de proiectare a compilatoarelor - Editura Albastra - Simona Motogna (2006)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TranslatorsContent;