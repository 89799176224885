import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class IntroLlmContent extends BaseContentPage  {

    constructor(props) {
        super(props, "llm-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Introducere LLM: Masinile visează</b>
                    <br/>
                    <br/>

                    LLM-urile au capacități remarcabile de a:
                    <ul>
                        <li>
                            înțelege
                        </li>
                        <li>
                            genera
                        </li>
                        <li>
                            interpreta
                        </li>
                    </ul>
                    limbajul uman.
                    <br/>
                    <br/>

                    Dar, când spunem că modelele de limbaj <i>inteleg</i>, inseamna că pot <i>procesa</i> și <i>genera</i> text în moduri <b>care par coerente și relevante din punct de vedere contextual</b>, dar <b>nu că posedă conștiință sau înțelegere asemănătoare omului</b>.
                    <hr/>
                    In acest moment, LLM-urile sunt asemanatoare viselor, in sensul in care par coerente și relevante.
                    <br/>
                    De cate ori in vis, n-am avut impresia ca tot ce se intampla are logica si sens?
                    <br/>
                    <b>Masinile visează</b>. S-au născut visând și raspund visând.
                    <hr/>
                    In romanul Vanatorul de recompense, Philip K. Dick imagineaza androizi cu un asemenea grad de umanizarea incat acestia aveau capacitatea de a visa.
                    <br/>
                    Doar ca eu cred ca capacitatea de a visa, nu are nici o legatura cu gradul de umanizare.
                    <br/>
                    Visarea nu este neaparat un pas spre masinile constiente, e mai degraba un drum paralel sau alternativ (pentru ca fragmente de drum se pot regasi si pe drumul constient).
                    <br/>
                    Si daca masina, devenita android, ar intelege omul si ar vedea cum e omul, si-ar mai dori mai mult decat visarea?

                    <hr/>

                    Cele mai multe LLM-urile au la baza arhitectura <b>Transformers</b> (nascuta in 2017, creată de cercetătorii Google ):
                    <ul>
                        <li>
                            <b>GPT = Generative Pre-trained Transformer</b>
                        </li>
                        <li>
                            <b>BERT (2018) = Bidirectional Encoder Representations from Transformers (dezvoltat de Google)</b>
                        </li>
                    </ul>

                    Istoric:
                    <ul>
                        <li>
                            2017 - arhitectura Transformers ( creată de cercetătorii Google )
                        </li>
                        <li>
                            2018 - GPT-1, de la Open IA
                        </li>
                        <li>
                            2018 - BERT  = Bidirectional Encoder Representations from Transformers (dezvoltat de Google)
                        </li>
                        <li>
                            2022 noiembrie - ChatGPT de la OpenIA - creat spre disperarea multor acționari Google, avand in vedere ca arhitectura Transformers a fost creată de cercetătorii Google
                        </li>
                        <li>
                            2023 februarie - Bard de la Google
                        </li>
                        <li>
                            2023 martie - ChatGpt 4
                        </li>
                        <li>
                            2023 decembrie 6 - Gemini 1.0, succesorul lui Bard de la Google
                        </li>
                        <li>
                            <b>Sora</b> (la OpenIA) - creare video din text: https://openai.com/sora
                        </li>
                    </ul>

                    Modele:
                    <ul>
                        <li>Llama 2 - 2023 (de la Meta IA - este open source)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           Build a Large Language Model (From Scratch) - Sebastian Raschka, Manning, ISBN 9781633437166
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroLlmContent;