import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FormsCannotBeImportedLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-forms-cannot-be-imported", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Problema: The file Forms-202402151551.portlet.lar cannot be imported.</b>
                    <br/>
                    <br/>

                    The file Forms-202402151551.portlet.lar cannot be imported. <br/>
                    The available languages in the LAR file (<em>ro_RO, ar_SA, ca_ES, zh_CN, nl_NL, en_US, fi_FI, fr_FR, de_DE, hu_HU, ja_JP, pt_BR, es_ES, sv_SE</em>) do not match the site's available languages (<em>ro_RO, en_US</em>).

                    <b>Cauza:</b>
                    <br/>
                    <br/>
                    Inseamna ca aplicatia/portalul de unde s-a exportat are urmatoarele limbi: <br/>
                    ro_RO, ar_SA, ca_ES, zh_CN, nl_NL, en_US, fi_FI, fr_FR, de_DE, hu_HU, ja_JP, pt_BR, es_ES, sv_SE <br/>
                    iar aplicatia unde se face importul: <br/>
                    ro_RO, en_US
                    <br/>
                    <br/>

                    Observatie:
                    <ul>
                        <li>ro_RO: Română (România)</li>
                        <li>ar_SA: Arabă (Arabia Saudită)</li>
                        <li>ca_ES: Catalană (Spania)</li>
                        <li>zh_CN: Chineză simplificată (China)</li>
                        <li>nl_NL: Neerlandeză (Țările de Jos)</li>
                        <li>en_US: Engleză (Statele Unite ale Americii)</li>
                        <li>fi_FI: Finlandeză (Finlanda)</li>
                        <li>fr_FR: Franceză (Franța)</li>
                        <li>de_DE: Germană (Germania)</li>
                        <li>hu_HU: Maghiară (Ungaria)</li>
                        <li>ja_JP: Japoneză (Japonia)</li>
                        <li>pt_BR: Portugheză (Brazilia)</li>
                        <li>es_ES: Spaniolă (Spania)</li>
                        <li>sv_SE: Suedeză (Suedia)</li>
                    </ul>

                    <b>Rezolvare:</b>

                    <ul>
                        <li>
                            1. Control Panel -> Configuration -> Instance Settings -> Miscellaneous (localizare)
                        </li>
                        <li>
                            2. Mutare  required languages de la Available la Current
                        </li>
                        <li>
                            3. Save
                        </li>
                        <li>
                            4. Import din nou fisier .LAR
                        </li>
                    </ul>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FormsCannotBeImportedLiferayContent;