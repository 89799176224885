import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class PropertyBindingAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-property-binding", IndexContent);
    }

    number = 7;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Property binding (legarea unei proprietati / legarea datelor)</b>
                    <br/>
                    <br/>

                    Pentru a lega o <i>proprietate a unui element</i> de o <i>proprietate tinta</i>, se folosesc paranteze drepte
                    [<i>proprietateElement</i>] = "proprietateTinta"

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<button type="button" [disabled]="isDisabled">Button</button>'}
                    </SyntaxHighlighter>
                    iar in ts avem:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'isDisabled = false;'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'isDisabled = true;'}
                    </SyntaxHighlighter>

                    <hr/>

                    Legarea datelor în React poate fi realizată utilizând un <i>controlled input</i> (intrare controlată).
                    O intrare controlată este realizată prin legarea valorii la:
                    <ul>
                        <li>stare a variabilei</li>
                        <li>eveniment <i>onChange</i> - entru a schimba starea pe măsură ce valoarea de intrare se modifică</li>
                    </ul>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'class App extends React.Component {\n' +
                            '  constructor() {\n' +
                            '    super();\n' +
                            '    this.state = { value: \'Hello World\' };\n' +
                            '  }\n' +
                            '  handleChange = (e) => {\n' +
                            '    this.setState({ value: e.target.value });\n' +
                            '  };\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <div>\n' +
                            '        <input\n' +
                            '          type="text"\n' +
                            '          value={this.state.value}\n' +
                            '          onChange={this.handleChange}\n' +
                            '        />\n' +
                            '        <p>{this.state.value}</p>\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Componenta-functie echivalenta componentei-clasa de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'const { useState } = React;\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  const [value, setValue] = useState(\'Hello World\');\n' +
                            '  const handleChange = (e) => setValue(e.target.value);\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <input type="text" value={value} onChange={handleChange} />\n' +
                            '      <p>{value}</p>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '};'}
                    </SyntaxHighlighter>

                    In React, nu există legare bidirecțională a datelor!
                    Dar, vezi si: <a target={"_blank"} href={"https://legacy.reactjs.org/docs/two-way-binding-helpers.html"}>Two-way Binding Helpers</a>!
                    (insa este depreciat)


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PropertyBindingAngularContent;