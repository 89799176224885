import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class WebClientSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-webclient", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Context</b>

                    <br/>
                    <br/>
                    In Gradle <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {' implementation group: \'org.springframework.boot\', name: \'spring-boot-starter-webflux\', version: \'2.7.4\''}
                    </SyntaxHighlighter>

                    Fie 2 servicii:
                    <ul>
                        <li>student-service (aplicatie Spring Boot, pornita pe portul 9091)</li>
                        <li>address-service (aplicatie Spring Boot, pornita pe portul 9092)</li>
                    </ul>

                    In <i>address-service</i>, exista <b>@Controller</b>-ul:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.controller;\n' +
                            '\n' +
                            'import org.springframework.web.bind.annotation.*;\n' +
                            'import ro.ibrid.learn.microservices.spring.request.CreateAddressRequest;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.AddressResponse;\n' +
                            'import ro.ibrid.learn.microservices.spring.service.AddressService;\n' +
                            '\n' +
                            '@RestController\n' +
                            '@RequestMapping("/api/address")\n' +
                            'public class AddressController {\n' +
                            '\n' +
                            '    private AddressService addressService;\n' +
                            '\n' +
                            '    /*\n' +
                            '    Înainte de Spring 4.3, trebuia să adăugăm adnotarea @Autowired la constructor.\n' +
                            '    Cu versiunile mai noi, acest lucru este opțional dacă clasa are un singur constructor.\n' +
                            '     */\n' +
                            '    public AddressController(AddressService addressService){\n' +
                            '        this.addressService = addressService;\n' +
                            '    }\n' +
                            '\n' +
                            '    @PostMapping("/create")\n' +
                            '    public AddressResponse createAddress (@RequestBody CreateAddressRequest createAddressRequest) {\n' +
                            '        return addressService.createAddress(createAddressRequest);\n' +
                            '    }\n' +
                            '\n' +
                            '    @GetMapping("/getById/{id}")\n' +
                            '    public AddressResponse getById(@PathVariable long id) {\n' +
                            '        return addressService.getById(id);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.entity;\n' +
                            '\n' +
                            'import javax.persistence.*;\n' +
                            '\n' +
                            '@Entity\n' +
                            '@Table(name = "address")\n' +
                            'public class Address {\n' +
                            '\n' +
                            '    @Id\n' +
                            '    @GeneratedValue(strategy = GenerationType.IDENTITY)\n' +
                            '    @Column(name = "id", columnDefinition = "serial")\n' +
                            '    private Long id;\n' +
                            '\n' +
                            '    @Column(name = "street")\n' +
                            '    private String street;\n' +
                            '\n' +
                            '    @Column(name = "city")\n' +
                            '    private String city;\n' +
                            '\n' +
                            '    public Long getId() {\n' +
                            '        return id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setId(Long id) {\n' +
                            '        this.id = id;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getStreet() {\n' +
                            '        return street;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setStreet(String street) {\n' +
                            '        this.street = street;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getCity() {\n' +
                            '        return city;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setCity(String city) {\n' +
                            '        this.city = city;\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.repository;\n' +
                            '\n' +
                            'import org.springframework.data.jpa.repository.JpaRepository;\n' +
                            'import org.springframework.stereotype.Repository;\n' +
                            'import ro.ibrid.learn.microservices.spring.entity.Address;\n' +
                            '\n' +
                            '@Repository\n' +
                            'public interface AddressRepository extends JpaRepository<Address, Long> {\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.request;\n' +
                            '\n' +
                            'public class CreateAddressRequest {\n' +
                            '\n' +
                            '\tprivate String street;\n' +
                            '\n' +
                            '\tprivate String city;\n' +
                            '\n' +
                            '\tpublic String getStreet() {\n' +
                            '\t\treturn street;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setStreet(String street) {\n' +
                            '\t\tthis.street = street;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getCity() {\n' +
                            '\t\treturn city;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setCity(String city) {\n' +
                            '\t\tthis.city = city;\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.response;\n' +
                            '\n' +
                            'import ro.ibrid.learn.microservices.spring.entity.Address;\n' +
                            '\n' +
                            'public class AddressResponse {\n' +
                            '\n' +
                            '\tprivate long addressId;\n' +
                            '\n' +
                            '\tprivate String street;\n' +
                            '\n' +
                            '\tprivate String city;\n' +
                            '\t\n' +
                            '\tpublic AddressResponse(Address address) {\n' +
                            '\t\tthis.addressId = address.getId();\n' +
                            '\t\tthis.street = address.getStreet();\n' +
                            '\t\tthis.city = address.getCity();\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic long getAddressId() {\n' +
                            '\t\treturn addressId;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setAddressId(long addressId) {\n' +
                            '\t\tthis.addressId = addressId;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getStreet() {\n' +
                            '\t\treturn street;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setStreet(String street) {\n' +
                            '\t\tthis.street = street;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getCity() {\n' +
                            '\t\treturn city;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setCity(String city) {\n' +
                            '\t\tthis.city = city;\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.service;\n' +
                            '\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import ro.ibrid.learn.microservices.spring.entity.Address;\n' +
                            'import ro.ibrid.learn.microservices.spring.repository.AddressRepository;\n' +
                            'import ro.ibrid.learn.microservices.spring.request.CreateAddressRequest;\n' +
                            'import ro.ibrid.learn.microservices.spring.response.AddressResponse;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class AddressService {\n' +
                            '\n' +
                            '    Logger logger = LoggerFactory.getLogger(AddressService.class);\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    AddressRepository addressRepository;\n' +
                            '\n' +
                            '    public AddressResponse createAddress(CreateAddressRequest createAddressRequest) {\n' +
                            '\n' +
                            '        Address address = new Address();\n' +
                            '        address.setStreet(createAddressRequest.getStreet());\n' +
                            '        address.setCity(createAddressRequest.getCity());\n' +
                            '\n' +
                            '        addressRepository.save(address);\n' +
                            '\n' +
                            '        return new AddressResponse(address);\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    public AddressResponse getById (long id) {\n' +
                            '\n' +
                            '        logger.info("Inside getById " + id);\n' +
                            '\n' +
                            '        Address address = addressRepository.findById(id).get();\n' +
                            '\n' +
                            '        return new AddressResponse(address);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    si
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.app;\n' +
                            '\n' +
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            'import org.springframework.boot.autoconfigure.domain.EntityScan;\n' +
                            'import org.springframework.context.annotation.ComponentScan;\n' +
                            'import org.springframework.data.jpa.repository.config.EnableJpaRepositories;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            '@ComponentScan({"ro.ibrid.learn.microservices.spring.controller", "ro.ibrid.learn.microservices.spring.service"})\n' +
                            '@EntityScan("ro.ibrid.learn.microservices.spring.entity")\n' +
                            '@EnableJpaRepositories("ro.ibrid.learn.microservices.spring.repository")\n' +
                            'public class AddressServiceApplication {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        SpringApplication.run(AddressServiceApplication.class, args);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    si <b>application.properties</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'spring.jpa.database=POSTGRESQL\n' +
                            'spring.datasource.url=jdbc:postgresql://192.168.1.8:5432/university\n' +
                            'spring.datasource.username=postgres\n' +
                            'spring.datasource.password=postgres\n' +
                            '\n' +
                            'server.port=9091\n' +
                            '\n' +
                            'spring.application.name=address-service'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'org.springframework.boot\' version \'2.7.4\'\n' +
                            '    id \'java\'\n' +
                            '}\n' +
                            '\n' +
                            'version = \'1.0.0\'\n' +
                            'group = \'ro.ibrid.learn.microservices.spring\'\n' +
                            '\n' +
                            'java {\n' +
                            '    sourceCompatibility = JavaVersion.VERSION_1_8\n' +
                            '}\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '    implementation platform(\'org.springframework.boot:spring-boot-dependencies:2.7.4\')\n' +
                            '\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-web\'\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-data-jpa\'\n' +
                            '\n' +
                            '    // https://mvnrepository.com/artifact/org.springframework.boot/spring-boot-starter-webflux\n' +
                            '    implementation group: \'org.springframework.boot\', name: \'spring-boot-starter-webflux\', version: \'2.7.4\'\n' +
                            '\n' +
                            '    //runtimeOnly \'org.postgresql:postgresql\'\n' +
                            '    implementation \'org.postgresql:postgresql\'\n' +
                            '\n' +
                            '    testImplementation(\'org.springframework.boot:spring-boot-starter-test\') {\n' +
                            '        exclude group: \'org.junit.vintage\', module: \'junit-vintage-engine\'\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\', Test) {\n' +
                            '    useJUnitPlatform()\n' +
                            '}'}
                    </SyntaxHighlighter>

                    similar e si pentru <b>student-service</b>; iar student are nevoie de adresa.
                    <br/>


                    <hr/>
                    <b>2. Configurare WebClient</b>
                    <br/>
                    <br/>

                    In Gradle <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {' implementation group: \'org.springframework.boot\', name: \'spring-boot-starter-webflux\', version: \'2.7.4\''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' @Value("${address.service.url}")\n' +
                            '    private String addressServiceUrl;\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    public WebClient webClient(){\n' +
                            '\n' +
                            '        WebClient webClient = WebClient.builder().baseUrl(addressServiceUrl).build();\n' +
                            '\n' +
                            '        return webClient;\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    In <b>application.properties</b>
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'address.service.url = http://localhost:9091/api/address'}
                    </SyntaxHighlighter>

                    Pentru a obtine adresa:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public AddressResponse getAddressById(long addressId){\n' +
                            '\t\tMono<AddressResponse> addressResponseMono = webClient.get().uri("/getById/"+addressId).retrieve().bodyToMono(AddressResponse.class);\n' +
                            '\n' +
                            '\t\treturn addressResponseMono.block();\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default WebClientSpringContent;