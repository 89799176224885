import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [
        {id:"java-cryptography", url:"#", title:"Introducere în semnaturi digitale", subtitle:""},

        {id:"java-cryptography-ex-01", url:"/java-cryptography/ex-01", title:"Introducere in semnaturi digitale", subtitle:"", parent: "java-cryptography"},
        {id:"java-cryptography-ex-02", url:"/java-cryptography/ex-02", title:"Semnaturi digitale XML", subtitle:"", parent: "java-cryptography"},
        {id:"java-cryptography-ex-03", url:"/java-cryptography/ex-03", title:"Semnare document XML in Java (cu certificat generat)", subtitle:"", parent: "java-cryptography"},
        // {id:"java-cryptography-ex-04", url:"#/java-cryptography/ex-04", title:"Semnare document XML in Java (cu cheie privata data)", subtitle:"", parent: "java-cryptography"},
        // {id:"java-cryptography-ex-05", url:"#/java-cryptography/ex-05", title:"Validate document XML in Java", subtitle:"", parent: "java-cryptography"},

    ];

    static indexUrl = "/java-cryptography/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Semnaturi digitale
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;