import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"arduino-index", url:"#", title:"Arduino", subtitle:""},

        {id:"arduino-intro", url:"/arduino/intro", title:"Introducere", subtitle:"", parent:"arduino-index"},
        {id:"arduino-interrupts", url:"/arduino/interrupts", title:"Intreruperi", subtitle:"", parent:"arduino-index"},
        {id:"arduino-eeprom", url:"/arduino/eeprom", title:"EEPROM", subtitle:"", parent:"arduino-index"},

        {id:"arduino-led", url:"/arduino/led", title:"LED", subtitle:"", parent:"arduino-index"},
        {id:"arduino-led-button", url:"/arduino/led-button", title:"LED + PushButton", subtitle:"", parent:"arduino-index"},
        {id:"arduino-led-fade", url:"/arduino/led-fade", title:"LED fade", subtitle:"", parent:"arduino-index"},
        {id:"arduino-potentiometer", url:"/arduino/potentiometer", title:"Potentiometru", subtitle:"", parent:"arduino-index"},
        {id:"arduino-led-rgb", url:"/arduino/led-rgb", title:"LED RGB", subtitle:"", parent:"arduino-index"},

        {id:"arduino-ultrasonic-distance", url:"/arduino/ultrasonic-distance", title:"Senzor de distanta", subtitle:"", parent:"arduino-index"},
        {id:"arduino-servo-motor", url:"/arduino/servo-motor", title:"Motor servo", subtitle:"", parent:"arduino-index"},
        {id:"arduino-lcd", url:"/arduino/lcd", title:"LCD (2x16)", subtitle:"", parent:"arduino-index"},
        {id:"arduino-ir-remote", url:"/arduino/ir-remote", title:"IR Remote", subtitle:"", parent:"arduino-index"},
        {id:"arduino-photoresistor", url:"/arduino/photoresistor", title:"Fotorezistor - masurarea luminozitatii", subtitle:"", parent:"arduino-index"},
        {id:"arduino-bluetooth-hc06", url:"/arduino/bluetooth-hc06", title:"Modul Bluetooth - HC 06", subtitle:"", parent:"arduino-index"},
        {id:"arduino-buzzer", url:"/arduino/buzzer", title:"Sunete: Buzzer", subtitle:"", parent:"arduino-index"},

        {id:"arduino-sound-sensor", url:"/arduino/sound-sensor", title:"Senzor de sunet (microfon)", subtitle:"", parent:"arduino-index"},
        {id:"arduino-dht", url:"/arduino/dht", title:"Senzor temperatura si umiditate - DHT 11", subtitle:"", parent:"arduino-index"},
        {id:"arduino-other-sensors", url:"/arduino/other-sensors", title:"Alti senzori", subtitle:"", parent:"arduino-index"},

    ]

    static indexUrl = "/arduino/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Arduino
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;