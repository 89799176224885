import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [
        {id:"java-spring-security", url:"#", title:"Introducere în Java Spring Security", subtitle:""},

        {id:"java-spring-security-ex-01", url:"/java-spring-security/ex-01", title:"Fara securitate", subtitle:"", parent: "java-spring-security"},
        {id:"java-spring-security-ex-02", url:"/java-spring-security/ex-02", title:"Refactorizare: modularizare proiect", subtitle:"", parent: "java-spring-security"},
        {id:"java-spring-security-ex-03", url:"/java-spring-security/ex-03", title:"Securitate implicita", subtitle:"", parent: "java-spring-security"},
        {id:"java-spring-security-ex-04", url:"/java-spring-security/ex-04", title:"Autentificare (bean-uri))", subtitle:"UserDetailsService si PasswordEncoder definite ca bean-uri", parent: "java-spring-security"},
        {id:"java-spring-security-ex-05", url:"/java-spring-security/ex-05", title:"Autentificare (WebSecurityConfigurerAdapter)", subtitle:"UserDetailsService si PasswordEncoder prin suprescrierea metodei configure() din WebSecurityConfigurerAdapter", parent: "java-spring-security"},
        {id:"java-spring-security-ex-06", url:"/java-spring-security/ex-06", title:"Autorizare (WebSecurityConfigurerAdapter))", subtitle:"", parent: "java-spring-security"},
    ];

    static indexUrl = "/java-spring-security/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere în Java Spring Security
                </div>

                {TocHelper.display(IndexContent.content)}
            </div>
        );
    }
}

export default IndexContent;