import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"kubernetes-root", url:"#", title:"Introducere in Kubernetes", subtitle:""},

        {id:"kubernetes-install", url:"/kubernetes/install", title:"Instalare Kubernetes (K8s): Minikube, Kubectl", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-kubeadm", url:"/kubernetes/kubeadm", title:"Kubeadm (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-kubeadm-docker", url:"/kubernetes/kubeadm-docker", title:"Kubeadm - Docker (K8s)", subtitle:"", parent:"kubernetes-root"},

        {id:"kubernetes-components", url:"/kubernetes/components", title:"Componente Kubernetes", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-core", url:"/kubernetes/core", title:"Concepte Kubernetes (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-image-pull-policy", url:"/kubernetes/image-pull-policy", title:"Image Pull Policy (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-env", url:"/kubernetes/env", title:"Variabile de mediu (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-network-plugins", url:"/kubernetes/network-plugins", title:"Network Plugins (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-networking", url:"/kubernetes/networking", title:"Networking (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-namespace", url:"/kubernetes/namespace", title:"Namespace (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-secret", url:"/kubernetes/secret", title:"Secret (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-security-context", url:"/kubernetes/security-context", title:"Security Context (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-service-account", url:"/kubernetes/service-account", title:"Service Account (K8s)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-resources", url:"/kubernetes/resources", title:"Resurse  (requests / limits)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-initcontainer", url:"/kubernetes/initcontainer", title:"InitContainer", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-crd", url:"/kubernetes/crd", title:"Custom Resource Definitions (crd)", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-taints-tolerations", url:"/kubernetes/taints-tolerations", title:"Taints si Tolerations", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-node-selectors", url:"/kubernetes/node-selectors", title:"Selectare noduri", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-node-affinity", url:"/kubernetes/node-affinity", title:"Afinitate noduri", subtitle:"", parent:"kubernetes-root"},
        {id:"kubernetes-multipod", url:"/kubernetes/multipod", title:"Multipod", subtitle:"", parent:"kubernetes-root"},

        {id:"kubernetes-observability-root", url:"#", title:"Observabilitate", subtitle:""},
        {id:"kubernetes-readiness-probe", url:"/kubernetes/readiness-probe", title:"Readiness Probe", subtitle:"", parent:"kubernetes-observability-root"},
        {id:"kubernetes-liveness-probe", url:"/kubernetes/liveness-probe", title:"Liveness Probe", subtitle:"", parent:"kubernetes-observability-root"},
        {id:"kubernetes-logging", url:"/kubernetes/logging", title:"Logging", subtitle:"", parent:"kubernetes-observability-root"},
        {id:"kubernetes-monitor-debug", url:"/kubernetes/monitor-debug", title:"Monitor & Debug", subtitle:"", parent:"kubernetes-observability-root"},

        {id:"kubernetes-pod-design-root", url:"#", title:"POD Design", subtitle:""},
        {id:"kubernetes-selector", url:"/kubernetes/selector", title:"Labels & Selectors", subtitle:"", parent:"kubernetes-pod-design-root"},
        {id:"kubernetes-rolling-rollout", url:"/kubernetes/rolling-rollout", title:"Rolling & Rollout", subtitle:"", parent:"kubernetes-pod-design-root"},

        {id:"kubernetes-service-networking-root", url:"#", title:"Services & Networking", subtitle:""},
        {id:"kubernetes-network-policies", url:"/kubernetes/network-policies", title:"Network policies", subtitle:"", parent:"kubernetes-service-networking-root"},

        {id:"kubernetes-kubectl-root", url:"#", title:"Kubectl", subtitle:""},
        {id:"kubernetes-kubectl-apply", url:"/kubernetes/kubectl-apply", title:"Kubectl apply", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-get", url:"/kubernetes/kubectl-get", title:"Kubectl get", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-run", url:"/kubernetes/kubectl-run", title:"Kubectl run", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-describe", url:"/kubernetes/kubectl-describe", title:"Kubectl describe", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-delete", url:"/kubernetes/kubectl-delete", title:"Kubectl delete", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-set", url:"/kubernetes/kubectl-set", title:"Kubectl set", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-logs", url:"/kubernetes/kubectl-logs", title:"Kubectl logs", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-exec", url:"/kubernetes/kubectl-exec", title:"Kubectl exec", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-create", url:"/kubernetes/kubectl-create", title:"Kubectl create", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-scale", url:"/kubernetes/kubectl-scale", title:"Kubectl scale", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-expose", url:"/kubernetes/kubectl-expose", title:"Kubectl expose", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-rollout", url:"/kubernetes/kubectl-rollout", title:"Kubectl rollout", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-drain", url:"/kubernetes/kubectl-drain", title:"Kubectl drain", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-cordon", url:"/kubernetes/kubectl-cordon", title:"Kubectl cordon", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-uncordon", url:"/kubernetes/kubectl-uncordon", title:"Kubectl uncordon", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-label", url:"/kubernetes/kubectl-label", title:"Kubectl label", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-taint", url:"/kubernetes/kubectl-taint", title:"Kubectl taint", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-autoscale", url:"/kubernetes/kubectl-autoscale", title:"Kubectl autoscale", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-edit", url:"/kubernetes/kubectl-edit", title:"Kubectl edit", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-cluster-info", url:"/kubernetes/kubectl-cluster-info", title:"kubectl cluster-info", subtitle:"", parent:"kubernetes-kubectl-root"},
        {id:"kubernetes-kubectl-replace", url:"/kubernetes/kubectl-replace", title:"kubectl replace", subtitle:"", parent:"kubernetes-kubectl-root"},

        {id:"kubernetes-config-root", url:"#", title:"Fisiere de configurare", subtitle:""},
        {id:"kubernetes-config-file", url:"/kubernetes/config-file", title:"Fisiere de configurare", subtitle:"", parent:"kubernetes-config-root"},
        {id:"kubernetes-combining-config", url:"/kubernetes/combining-config", title:"Combinare fisiere de configurare", subtitle:"", parent:"kubernetes-config-root"},
        {id:"kubernetes-ex1", url:"/kubernetes/ex1", title:"Exercitiul 1: simplek8s", subtitle:"", parent:"kubernetes-config-root"},
        {id:"kubernetes-ex3", url:"/kubernetes/ex3", title:"Exercitiul 2: webk8s", subtitle:"", parent:"kubernetes-config-root"},

        {id:"kubernetes-objects-root", url:"#", title:"Obiecte", subtitle:""},
        {id:"kubernetes-pod-object", url:"/kubernetes/pod", title:"Pod", subtitle:"", parent:"kubernetes-objects-root"},
        {id:"kubernetes-deployment-object", url:"/kubernetes/deployment", title:"Deployment", subtitle:"", parent:"kubernetes-objects-root"},
        {id:"kubernetes-service-object", url:"/kubernetes/service", title:"Service", subtitle:"", parent:"kubernetes-objects-root"},
        {id:"kubernetes-config-map", url:"/kubernetes/config-map", title:"ConfigMap", subtitle:"", parent:"kubernetes-objects-root"},
        {id:"kubernetes-replicasets-object", url:"/kubernetes/replicasets", title:"ReplicaSet", subtitle:"", parent:"kubernetes-objects-root"},
        {id:"kubernetes-job-object", url:"/kubernetes/job", title:"Job", subtitle:"", parent:"kubernetes-objects-root"},
        {id:"kubernetes-cronjob-object", url:"/kubernetes/cronjob", title:"CronJob", subtitle:"", parent:"kubernetes-objects-root"},

        {id:"kubernetes-prod", url:"#", title:"Drumul spre productie", subtitle:""},
        {id:"kubernetes-volume", url:"/kubernetes/volume", title:"Volume", subtitle:"", parent:"kubernetes-prod"},
        {id:"kubernetes-ingress", url:"/kubernetes/ingress", title:"Ingress", subtitle:"", parent:"kubernetes-prod"},
        {id:"kubernetes-minikube-dashboard", url:"/kubernetes/minikube-dashboard", title:"Minikube Dashboard", subtitle:"", parent:"kubernetes-prod"},
        {id:"kubernetes-ex2", url:"/kubernetes/ex2", title:"Exercitiul 2: complex-k8s (partea I-a: Local)", subtitle:"", parent:"kubernetes-prod"},
        {id:"kubernetes-ex2-2", url:"/kubernetes/ex2-2", title:"Exercitiul 2: complex-k8s (partea a II-a: Google Cloud)", subtitle:"", parent:"kubernetes-prod"},

        {id:"kubernetes-state-persistence", url:"#", title:"State persistence", subtitle:""},
        {id:"kubernetes-storage-classes", url:"/kubernetes/storage-classes", title:"Storage classes", subtitle:"", parent:"kubernetes-state-persistence"},
        {id:"kubernetes-stateful-sets", url:"/kubernetes/stateful-sets", title:"Stateful sets", subtitle:"", parent:"kubernetes-state-persistence"},
        {id:"kubernetes-headless-services", url:"/kubernetes/headless-services", title:"Headless Services", subtitle:"", parent:"kubernetes-state-persistence"},

        {id:"kubernetes-auth-root", url:"#", title:"Autentificare si autorizare", subtitle:""},
        {id:"kubernetes-authentication", url:"/kubernetes/authentication", title:"Autentificare", subtitle:"", parent:"kubernetes-auth-root"},
        {id:"kubernetes-authorization", url:"/kubernetes/authorization", title:"Autorizare", subtitle:"", parent:"kubernetes-auth-root"},
        {id:"kubernetes-admission", url:"/kubernetes/admission", title:"Admission", subtitle:"", parent:"kubernetes-auth-root"},
        {id:"kubernetes-kubeconfig", url:"/kubernetes/kubeconfig", title:"KubeConfig", subtitle:"", parent:"kubernetes-auth-root"},
        {id:"kubernetes-api-groups", url:"/kubernetes/api-groups", title:"API Groups", subtitle:"", parent:"kubernetes-auth-root"},
        {id:"kubernetes-api-version-deprecations", url:"/kubernetes/api-version-deprecations", title:"API Version & Deprecations", subtitle:"", parent:"kubernetes-auth-root"},
        {id:"kubernetes-crd", url:"/kubernetes/crd", title:"Custom Resource Definitions (crd)", subtitle:"", parent:"kubernetes-root"},

        {id:"kubernetes-helm-root", url:"#", title:"Fundamente Helm", subtitle:""},
        {id:"kubernetes-helm", url:"/kubernetes/helm", title:"Helm", subtitle:"", parent:"kubernetes-helm-root"},

        {id:"kubernetes-link-root", url:"#", title:"Link-uri", subtitle:""},
        {id:"kubernetes-links", url:"/kubernetes/links", title:"Link-uri utile", subtitle:"", parent:"kubernetes-link-root"},

        {id:"kubernetes-resume-root", url:"#", title:"Rezumate", subtitle:""},
        {id:"kubernetes-cmd-resume", url:"/kubernetes/cmd-resume", title:"Link-uri utile", subtitle:"", parent:"kubernetes-resume-root"},

        {id:"kubernetes-anexe-root", url:"#", title:"Anexe", subtitle:""},
        {id:"kubernetes-podman", url:"/kubernetes/podman", title:"Podman", subtitle:"", parent:"kubernetes-anexe-root"},
        {id:"kubernetes-postgresql", url:"/kubernetes/postgresql", title:"PostgreSQL", subtitle:"", parent:"kubernetes-anexe-root"},
        {id:"kubernetes-elasticsearch", url:"/kubernetes/elasticsearch", title:"Elasticsearch", subtitle:"", parent:"kubernetes-anexe-root"},
        {id:"kubernetes-liferay", url:"/kubernetes/liferay", title:"Liferay 7.4", subtitle:"", parent:"kubernetes-anexe-root"},
        {id:"kubernetes-ngnix", url:"/kubernetes/nginx", title:"Nginx Ingress", subtitle:"", parent:"kubernetes-anexe-root"},

        // {id:"kubernetes-lab", url:"#", title:"Exercitii / Laboratoare", subtitle:""},
        // {id:"kubernetes-service-netpol", url:"/kubernetes/service-netpol", title:"Rezumat: Servicii & Network Policy", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad", url:"/kubernetes/ckad", title:"Rezumat: CKAD", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex", url:"/kubernetes/ckad-ex", title:"Exercitii : CKAD (36 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex2", url:"/kubernetes/ckad-ex2", title:"Mocks: CKAD [1] (19 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex3", url:"/kubernetes/ckad-ex3", title:"Mocks: CKAD [2] (19 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex4", url:"/kubernetes/ckad-ex4", title:"Mocks: CKAD [4] (40 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex5", url:"/kubernetes/ckad-ex5", title:"Mocks: CKAD [5] (40 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex6", url:"/kubernetes/ckad-ex6", title:"Mocks: CKAD [6] (40 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-ex7", url:"/kubernetes/ckad-ex7", title:"Mocks: CKAD [7] (0 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-ckad-exr", url:"/kubernetes/ckad-exr", title:"Mocks-R: CKAD [1] (19+33 exercitii)", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-1", url:"/kubernetes/lab/1", title:"Exercitii 1", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-2", url:"/kubernetes/lab/2", title:"Exercitii 2", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-3", url:"/kubernetes/lab/3", title:"Exercitii 3", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-4", url:"/kubernetes/lab/4", title:"Exercitii 4", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-5", url:"/kubernetes/lab/5", title:"Exercitii 5", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-6", url:"/kubernetes/lab/6", title:"Exercitii 6", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-7", url:"/kubernetes/lab/7", title:"Exercitii 7", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-8", url:"/kubernetes/lab/8", title:"Exercitii 8", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab-9", url:"/kubernetes/lab/9", title:"Exercitii 9", subtitle:"", parent:"kubernetes-lab"},
        // {id:"kubernetes-lab", url:"/kubernetes/lab", title:"Rezumat: Laboratoare", subtitle:"", parent:"kubernetes-lab"},
]

    static indexUrl = "/kubernetes/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere in Kubernetes
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;