import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SwitchJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-switch", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'switch (expresie) {\n' +
                        '    case valoare1:\n' +
                        '        bloc_cod_1\n' +
                        '        break;\n' +
                        '    case valoare_2:\n' +
                        '        bloc_cod_2\n' +
                        '        break;\n' +
                        '        .....\n' +
                        '    case valoare_n:\n' +
                        '        bloc_cod_n\n' +
                        '        break;\n' +
                        '    default:  \n' +
                        '        bloc_default\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>expresie - trebuie sa fie intre paranteze</li>
                        <li>poate sa nu existe nici un <i>case</i></li>
                        <li>poate sa nu existe <i>default</i></li>
                        <li>cazul <i>default</i> se executa atunci când niciunul dintre cazuri nu se potrivește cu <i>expresie</i></li>
                        <li>cazul <i>default</i> este pus ultimul, dar este doar o conventie</li>
                        <li>evaluarea se face cu operatorul de comparație strict (===), așa că nu numai valoarea trebuie să se potrivească, ci și tipul valoarii cu <i>expresie</i></li>
                        <li>dupa ce evalueaza cu true un <i>case</i> se executa blocul corespunzator; daca blocul se termina cu un break se iese din switch, altfel se excuta urmatoarele blocuri <i>case</i>, chiar daca nu se mai evalueaza cu true, pana la primul <i>break</i>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let anotimp = "vara";\n' +
                        'switch (anotimp){\n' +
                        '  case "iarna":\n' +
                        '    console.log("iarna");\n' +
                        '    break;\n' +
                        '  case "primavara":\n' +
                        '    console.log("iarnprimavaraa");\n' +
                        '    break;\n' +
                        '  case "vara":\n' +
                        '    console.log("vara");\n' +
                        '    break;\n' +
                        '  case "toamna":\n' +
                        '    console.log("toamna");\n' +
                        '    break;    \n' +
                        '  default:\n' +
                        '    console.log("esti de pe alta planeta");\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Extra</b>
                    <br/>
                    Metoda <b>Number.isNaN(valoare)</b> verifica daca valoarea data este un numar.
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SwitchJavaScriptContent;