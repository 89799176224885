import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class LoginDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "login-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Docker Login (Ubuntu)</b>
                    <br/>
                    <br/>

                    Pentru a putea face <b>pull</b> si <b>push</b> la imagini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker login'}
                    </SyntaxHighlighter>

                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Login with your Docker ID to push and pull images from Docker Hub. If you don\'t have a Docker ID, head over to https://hub.docker.com to create one.\n' +
                            'Username: buzdugan.iulian@gmail.com\n' +
                            'Password:\n' +
                            'Got permission denied while trying to connect to the Docker daemon socket at unix:///var/run/docker.sock: Post "http://%2Fvar%2Frun%2Fdocker.sock/v1.24/auth": dial unix /var/run/docker.sock: connect: permission denied'}
                    </SyntaxHighlighter>

                    Daca se obtine eroare de mai sus, atunci:
                    <br/>
                    - se creaza un nou grup <b>docker</b> (daca nu exista)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo groupadd docker'}
                    </SyntaxHighlighter>
                    - se aduga la acest grup user-ul curent:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo usermod -aG docker $USER'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'su - $USER'}
                    </SyntaxHighlighter>

                    Pentru a testa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -a'}
                    </SyntaxHighlighter>

                    Pentru a ne loga:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker login'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {
                            'Login with your Docker ID to push and pull images from Docker Hub. If you don\'t have a Docker ID, head over to https://hub.docker.com to create one.\n' +
                            'Username: iulianbuzdugan\n' +
                            'Password:\n' +
                            'WARNING! Your password will be stored unencrypted in /home/kj/.docker/config.json.\n' +
                            'Configure a credential helper to remove this warning. See\n' +
                            'https://docs.docker.com/engine/reference/commandline/login/#credentials-store\n' +
                            '\n' +
                            'Login Succeeded'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>
                                    How to fix docker: Got permission denied while trying to connect to the Docker daemon socket
                                </a>
                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LoginDockerContent;