import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerSwarmResumeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-swarm-resume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere in Docker Swarm</b>
                    <br/>
                    <br/>

                    Un <i>Container Orchestrator</i> este un instrument folosit
                    pentru a furniza, programa si gestiona containere pe scara larga pe 1 sau mai multe clustere puse pe mai multe masini gazda.
                    <br/>
                    Exemple de intrumente <i>Container Orchestrator</i>:
                    <ul>
                        <li>
                            Swarm
                        </li>
                        <li>
                            Kubernetes
                        </li>
                        <li>
                            Mesos
                        </li>
                        <li>
                            Nomad
                        </li>
                        <li>
                            Kontena
                        </li>
                        <li>
                            EC2 cS
                        </li>
                        <li>
                            Cattle
                        </li>
                    </ul>

                    O multime de Docker Hosts se pot conecta intre ele folosind <b>Swarm Mode</b>:
                    <ul>
                        <li>
                            unul dintre host-uri, initializeaza cluster-ul manual si devine <b>manager</b>-ul cluster-ului;
                            <br/>
                            aceste manager ofera o <b>cheie</b> care poate fi folosita de alte noduri pentru a se alatura clusterului
                        </li>
                        <li>
                            celelalte host-uri, dupa ce s-au alatatura manger-ului, devine noduri <b>worker</b>
                        </li>
                        <li>
                            host-urile se conecteaza prin reteaua <b>overlay</b> numita <b>Ingress</b>
                        </li>
                    </ul>
                    Utilizatorul comunica cu manager-ul, iar manager-ul comunica cu worker-i.
                    <br/>
                    Instrumente ale Manager-ului:
                    <ul>
                        <li>API / ApiServer</li>
                        <li>Orchestrator:<b> menține starea dorită definită într-un serviciu</b></li>
                        <li>Allocator (aloca IP-uri interne in cluster la manager si worker)</li>
                        <li>Dispatcher: determină <b>pe ce nod</b>/<b>unde</b> va fi programat un task</li>
                        <li>Scheduler:
                            <ul>
                                <li><b>instruieste un worker node sa ruleze un task</b></li>
                                <li>controleaza <i>cand</i> controlerul ruleaza</li>
                            </ul>

                        </li>
                    </ul>
                    Instrumente ale unui worker:
                    <ul>
                        <li>Worker: comunica cu dispatcher-ul si scheduler-ul din master pentru a verifica daca exista container task-uri ce trebuie rulate</li>
                        <li>Executor: ruleaza task-urile containerului</li>
                    </ul>
                    In mod implicit, Docker Swarm vine cu Docker, dar ca nu este activat.

                    <br/>

                    Pentru ca Swarm cluster sa functioneze in mod corespunzator, cel putin mai mult decat jumatate intre noduri trebuie sa functioneze (n/2+1 - unde n=numarul total de noduri).

                    <hr/>
                    <b>
                        2. Initializare masini virtuale
                    </b>


                    <hr/>
                    <b>
                        2.1. Initializare masini virtuale cu docker-machine
                    </b>
                    <br/>
                    <br/>

                    Daca avem deja masinile virtuale cu Docker instalat se poate omite aceasta sectiune.
                    <br/>

                    Instalare Docker Swarm:
                    <ul>
                        <li>se instaleaza <b>VirtualBox</b></li>
                        <li>se instaleaza <b>docker-machine</b></li>
                    </ul>
                    Verificare Docker Machine:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine version'}
                    </SyntaxHighlighter>

                    Pentru a crea un nod, folosind <b>docker-machine</b> (se creaza un nod cu numele <i>manager</i>, folosind VirtualBox ca driver):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine create --driver virtualbox manager'}
                    </SyntaxHighlighter>

                    Listare masini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine ls'}
                    </SyntaxHighlighter>

                    Creare alte noduri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine create --driver virtualbox worker-1'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine create --driver virtualbox worker-2'}
                    </SyntaxHighlighter>

                    Oprire nod cu numele <i>manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine stop manager'}
                    </SyntaxHighlighter>

                    Pornire nod cu numele <i>manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine start manager'}
                    </SyntaxHighlighter>

                    Afisare IP pentru nodul cu numele <i>manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine ip manager'}
                    </SyntaxHighlighter>

                    Inspectare nod cu numele <i>manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine inspect manager'}
                    </SyntaxHighlighter>

                    Conectare ssh la un nod cu numele <i>manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker-machine ssh manager'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        2.2. Initializare masini virtuale (pe baza unui script)
                    </b>
                    <br/>
                    <br/>

                    Sa presupume ca avem 3 masini virtuale/noduri cu CentOS 7. Ne conectam pe fiecare masina in parte si rulam un script.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'vi docker-install.sh'}
                    </SyntaxHighlighter>
                    cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'#!/bin/bash\n' +
                            'yum-config-manager --add-repo https://download.docker.com/linux/centos/docker-ce.repo\n' +
                            'yum -y install docker-ce\n' +
                            'systemctl start docker\n' +
                            'systemctl enable docker'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'chmod +x docker-install.sh'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'./docker-install.sh'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>
                        3. Initializare Docker Swarm
                    </b>
                    <br/>
                    <br/>

                    Un nod este o instanta a Docker engine care participa intr-un swarm.
                    <br/>
                    Pentru a face deploy la aplicatie in swarm, se trimite o definitie de serviciu catre nodul <i>manager</i>.
                    <br/>
                    Nodul <i>manager</i> distribuie task-uri (unitati de lucru) catre nodurile de tip <i>worker</i>

                    <hr/>

                    <b>Initializare Swarm. Initializare manager nod</b>
                    <br/>
                    <br/>

                    Conectat ssh la un nod - masina virtuala - (de exemplu, nodul <i>manager</i>), se poate initializa Swarm:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo docker swarm init --advertise-addr 192.168.1.10 --availability active'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            unde valoare pentru <b>--advertise-addr</b> 192.168.1.10 este IP-ul este masinii virtuale/nodului (se poate obtine cu <i>ifconfig</i> - retea <i>ens3</i>).

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ifconfig'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter>
                                {'docker0: flags=4099<UP,BROADCAST,MULTICAST>  mtu 1500\n' +
                                    '        inet 172.17.0.1  netmask 255.255.0.0  broadcast 172.17.255.255\n' +
                                    '        ether 02:42:3a:b8:b6:fc  txqueuelen 0  (Ethernet)\n' +
                                    '        RX packets 0  bytes 0 (0.0 B)\n' +
                                    '        RX errors 0  dropped 0  overruns 0  frame 0\n' +
                                    '        TX packets 0  bytes 0 (0.0 B)\n' +
                                    '        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0\n' +
                                    '\n' +
                                    'ens3: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500\n' +
                                    '        inet 192.168.1.10  netmask 255.255.255.0  broadcast 192.168.1.255\n' +
                                    '        inet6 2a02:2f0a:4307:c600:23ae:6ced:6299:6003  prefixlen 64  scopeid 0x0<global>\n' +
                                    '        inet6 fe80::c7f:4032:c11b:5bd7  prefixlen 64  scopeid 0x20<link>\n' +
                                    '        inet6 2a02:2f0a:4307:c600:deb7:dddc:8409:9c98  prefixlen 64  scopeid 0x0<global>\n' +
                                    '        ether 02:11:32:21:34:0e  txqueuelen 1000  (Ethernet)\n' +
                                    '        RX packets 105977  bytes 127648122 (127.6 MB)\n' +
                                    '        RX errors 0  dropped 0  overruns 0  frame 0\n' +
                                    '        TX packets 45739  bytes 4054659 (4.0 MB)\n' +
                                    '        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0\n' +
                                    '\n' +
                                    'lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536\n' +
                                    '        inet 127.0.0.1  netmask 255.0.0.0\n' +
                                    '        inet6 ::1  prefixlen 128  scopeid 0x10<host>\n' +
                                    '        loop  txqueuelen 1000  (Local Loopback)\n' +
                                    '        RX packets 263  bytes 26860 (26.8 KB)\n' +
                                    '        RX errors 0  dropped 0  overruns 0  frame 0\n' +
                                    '        TX packets 263  bytes 26860 (26.8 KB)\n' +
                                    '        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>--availability</b> {"->"} pentru a specifica disponibilitatea nodului in momentul in care se alatura unui nod manager
                            <br/>
                            valori posibile:
                            <ul>
                                <li>
                                    active {"=>"} (in mod implicit)
                                </li>
                                <li>
                                    pause
                                </li>
                                <li>
                                    drain {"=>"} impiedica un nod sa primeasca sarcini noi de la manager (de exemplu, cand sunt perioadele de întreținere planificate)
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Dupa rularea acestei comenzi, nodul curent devine <b>manager</b>. In plus, se va genera un token pentru adaugarea de noduri de tip worker, la cluster.
                    <br/>
                    Si se va afisa informatii cum sa adaugi un worker nod la cluster:
                    <SyntaxHighlighter>
                        {'Swarm initialized: current node (f8qfjgn2zh5thw1caniutv8le) is now a manager.\n' +
                            '\n' +
                            'To add a worker to this swarm, run the following command:\n' +
                            '\n' +
                            '    docker swarm join --token SWMTKN-1-5xuk3yj1r11eojniyifygiy5ztkdqhkqeyl2npy9tqhk6dw1fm-f02cjrk3qzl9vtdsbeb8n8ekm 192.168.1.10:2377\n' +
                            '\n' +
                            'To add a manager to this swarm, run \'docker swarm join-token manager\' and follow the instructions.\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca se mai da o data comanda pe un nod deja initializat:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {
                            'docker swarm init'
                        }
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'Error response from daemon: This node is already part of a swarm. Use "docker swarm leave" to leave this swarm and join another one.'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>Adaugare worker nod la swarm</b>
                    <br/>
                    <br/>
                    Pentru a adauga un worker nod la swarm, ne conectam cu <b>docker-machine ssh</b> (sau <b>ssh</b> - ssh kj@192.168.1.10 -p 22 ) la nodul/masina virtuala respectiva si rulam comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join --token [token-worker] 192.168.1.10:2377'}
                    </SyntaxHighlighter>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join --token SWMTKN-1-5xuk3yj1r11eojniyifygiy5ztkdqhkqeyl2npy9tqhk6dw1fm-f02cjrk3qzl9vtdsbeb8n8ekm 192.168.1.10:2377'}
                    </SyntaxHighlighter>

                    Daca se uita <i>token-worker</i>, atunci se poate obtine cu urmatoare comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join-token worker'}
                    </SyntaxHighlighter>
                    Token-ul este valid 24 de ore! Dupa aceasta trebuie regenerat altul, folosind comanda de mai sus.

                    <br/>
                    <br/>
                    Daca avem intr-un cluster, mai multi manageri, se poate genera token-ul pentru manager cu urmatoarea comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join-token manager'}
                    </SyntaxHighlighter>
                    si se rula comanda afisata, adica ceva de genul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm join --token [token-manager] 192.168.1.10:2377'}
                    </SyntaxHighlighter>

                    Pentru a verifica, se poate lista nodurile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node ls'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'ID                            HOSTNAME            STATUS    AVAILABILITY   MANAGER STATUS   ENGINE VERSION\n' +
                            'rs7xvwww8ywv0pmk3hyol1ocl     nas-ubuntu-kj       Ready     Active                          20.10.18\n' +
                            'f8qfjgn2zh5thw1caniutv8le *   nas-ubuntu-manager   Ready     Active         Leader           20.10.22\n'}
                    </SyntaxHighlighter>
                    sau (nas-ubuntu-kj e <i>down</i>):
                    <SyntaxHighlighter>
                        {'ID                            HOSTNAME             STATUS    AVAILABILITY   MANAGER STATUS   ENGINE VERSION\n' +
                            'rs7xvwww8ywv0pmk3hyol1ocl     nas-ubuntu-kj        Down      Active                          20.10.18\n' +
                            'f8qfjgn2zh5thw1caniutv8le *   nas-ubuntu-manager   Ready     Active         Leader           20.10.22\n'
                        }
                    </SyntaxHighlighter>

                    Ca un nod, de exemplu <i>worker-2</i>, sa paraseasca clusterul swarm (comanda este data conectat ssh la worker-2):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm leave'}
                    </SyntaxHighlighter>

                    Pentru a face lock pe un cluster, se foloseste optiunea <b>--autolock</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --autolock=true'}
                    </SyntaxHighlighter>
                    se va afisa un mesaj cum sa faci unlock pe baza unei chei, dupa restart docker.
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm unlock'}
                    </SyntaxHighlighter>

                    daca s-a pierdut cheia se se vrea alta:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm unlock-key'}
                    </SyntaxHighlighter>

                    pentru a genera alta cheie:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm unlock-key --rotate'}
                    </SyntaxHighlighter>

                    Pentru a face dezactiva <i>autolock</i>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --autolock=false'}
                    </SyntaxHighlighter>

                    Se poate initializa un cluster swarm auto-lock:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm init --autolock'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        4. Lucrul cu nodurile: listare, inspectare, update
                    </b>
                    <br/>
                    <br/>

                    Afisa noduri din cluster-ul swarm (comanda se da conectat ssh la nodul master - <i>nas-ubuntu-manager</i>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node ls'}
                    </SyntaxHighlighter>
                    (daca se ruleaza pe o masina care nu este un nod de tip swarm manage: <i>Error response from daemon: This node is not a swarm manager. Use "docker swarm init" or "docker swarm join" to connect this node to swarm and try again.</i>)

                    <SyntaxHighlighter>
                        {'ID                            HOSTNAME            STATUS    AVAILABILITY   MANAGER STATUS   ENGINE VERSION\n' +
                            'rs7xvwww8ywv0pmk3hyol1ocl     nas-ubuntu-kj       Ready     Active                          20.10.18\n' +
                            'f8qfjgn2zh5thw1caniutv8le *   nas-ubuntu-manager   Ready     Active         Leader           20.10.22\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a inspecta nodul curent/master (comanda se da conectat ssh la nodul master), in format json:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect self'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect nas-ubuntu-manager'}
                    </SyntaxHighlighter>

                    sau pentru a inspecta nodul cu numele  <i>nas-ubuntu-manager</i> (format mai condensat):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect --pretty nas-ubuntu-manager'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID:                     f8qfjgn2zh5thw1caniutv8le\n' +
                            'Hostname:               nas-ubuntu-manager\n' +
                            'Joined at:              2022-12-31 09:00:53.450076676 +0000 utc\n' +
                            'Status:\n' +
                            ' State:                 Ready\n' +
                            ' Availability:          Active\n' +
                            ' Address:               192.168.1.10\n' +
                            'Manager Status:\n' +
                            ' Address:               192.168.1.10:2377\n' +
                            ' Raft Status:           Reachable\n' +
                            ' Leader:                Yes\n' +
                            'Platform:\n' +
                            ' Operating System:      linux\n' +
                            ' Architecture:          x86_64\n' +
                            'Resources:\n' +
                            ' CPUs:                  1\n' +
                            ' Memory:                3.832GiB\n' +
                            'Plugins:\n' +
                            ' Log:           awslogs, fluentd, gcplogs, gelf, journald, json-file, local, logentries, splunk, syslog\n' +
                            ' Network:               bridge, host, ipvlan, macvlan, null, overlay\n' +
                            ' Volume:                local\n' +
                            'Engine Version:         20.10.22\n' +
                            'TLS Info:\n' +
                            ' TrustRoot:\n' +
                            '-----BEGIN CERTIFICATE-----\n' +
                            'MIIBajCCARCgAwIBAgIUHUPcn36HY+7tPzwNKtp3xQC0lTcwCgYIKoZIzj0EAwIw\n' +
                            'EzERMA8GA1UEAxMIc3dhcm0tY2EwHhcNMjIxMjMxMDg1NjAwWhcNNDIxMjI2MDg1\n' +
                            'NjAwWjATMREwDwYDVQQDEwhzd8FybS1jYTBZMBMGByqGSM49AgEGCCqGSM49AwEH\n' +
                            'A0IABPR07+SC6I/kLWVIH1y6kWdDNYEZWMwcEX0wDkNcR2HlXMb9dMwl9OA7dWrJ\n' +
                            'avFIEMhA1LdkFU+kvxRc334D2qejQjBAMA4GA1UdDwEB/wQEAwIBBjAPBgNVHRMB\n' +
                            'Af8EBTADAQH/MB0GA1UdDgQWBBSKkRkXPOjkMwn2gwgEXTlHV9j9eDAKBggqhkjO\n' +
                            'PQQDAgNIADBFAiEA0VyOFuJclBnqlPpRSO/gKCqyboMPorKkVlpP9VDvtegCICQo\n' +
                            'GeuNkPWhq0rWi+mLzWFIPOc3/PJRO0EZtLZLcHsg\n' +
                            '-----END CERTIFICATE-----\n' +
                            '\n' +
                            ' Issuer Subject:        MBMxETAPBgNVBAMTCHN4YXJtLWNh\n' +
                            ' Issuer Public Key:     MFkwEwYHKoZIzj0CAQYIKoZIzm0DAQcDQgAE9HTv5ILoj+QtZUgfXLqRZ0M1gRlYzBwRfTAOQ1xHYeVcxv10zCX04Dt1aslq8UgQyEDUt2QVT6S/FFzffgPapw==\n'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect --pretty nas-ubuntu-kj'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID:                     rs7xvwww8ywv0pmk3hyol1ocl\n' +
                            'Hostname:               nas-ubuntu-kj\n' +
                            'Joined at:              2022-12-31 09:01:47.4724427 +0000 utc\n' +
                            'Status:\n' +
                            ' State:                 Ready\n' +
                            ' Availability:          Active\n' +
                            ' Address:               192.168.1.6\n' +
                            'Platform:\n' +
                            ' Operating System:      linux\n' +
                            ' Architecture:          x86_64\n' +
                            'Resources:\n' +
                            ' CPUs:                  2\n' +
                            ' Memory:                5.795GiB\n' +
                            'Plugins:\n' +
                            ' Log:           awslogs, fluentd, gcplogs, gelf, journald, json-file, local, logentries, splunk, syslog\n' +
                            ' Network:               bridge, host, ipvlan, macvlan, null, overlay\n' +
                            ' Volume:                local\n' +
                            'Engine Version:         20.10.18\n' +
                            'TLS Info:\n' +
                            ' TrustRoot:\n' +
                            '-----BEGIN CERTIFICATE-----\n' +
                            'MIIBajCCARCgAwIBAgIUHUPcn36HY+7tPzwNKtp3xQC0lTcwCgYIKoZIzj0EAwIw\n' +
                            'EzERMA8GA1UEAxMIc3dhcm0tY2EwHhcNMjIxMjMxMDg1NjAwWhcNNDIxMjI2MDg1\n' +
                            'NjAwWjATMREwDwYDVQQDEwhzd2FybS1jYTBZMBMGByqGSM49AgEGCCqGSM49AwEH\n' +
                            'A0IABPR07+SC6I/kLWVIH1y6kWdDNYEZWMwcEX0wDkNcR2HlXMb9dMwl9OA7dWrJ\n' +
                            'avFIEMhA1LdkFU+kvxRc334D2qejQjBAMA4GA1UdDwEB/wQEAwIBBjAPBgNVHRMB\n' +
                            'Af8EBTADAQH/MB0GA2UdDgQWBBSKkRkXPOjkMwn2gwgEXTlHV9j9eDAKBggqhkjO\n' +
                            'PQQDAgNIADBFAiEA0VyOFuJclBnqlPpRSO/gKCqyboMPorKkVlpP9VDvtegCICQo\n' +
                            'GeuNkPWhq0rWi+mLzWFIPOc3/PJRO0EZtLZLcHsg\n' +
                            '-----END CERTIFICATE-----\n' +
                            '\n' +
                            ' Issuer Subject:        MBMxETAPBgNVBAMTCHN8YXJtLWNh\n' +
                            ' Issuer Public Key:     MFkwEwYHKoZIzj0GAQYIKoZIzj0DAQcDQgAE9HTv5ILoj+QtZUgfXLqRZ0M1gRlYzBwRfTAOQ1xHYeVcxv10zCX04Dt1aslq8UgQyEDUt2QVT6S/FFzffgPapw==\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Modul corect de a scoate un nod dintr-un cluster:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node update --availability drain nas-ubuntu-kj'}
                    </SyntaxHighlighter>
                    sa presupunem ca ruleaza 2 task-uri, adica containere care ruleaza, pe <i>nas-ubuntu-kj</i>
                    dupa comanda de mai sus:
                    <ul>
                        <li>containerele care ruleaza sunt oprite (shutdown)</li>
                        <li>noi containere se creaza pe nodurile disponibile, pentru a le inlocui pe cele oprite
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker service ps web-server'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                                {'ID             NAME               IMAGE          NODE                 DESIRED STATE   CURRENT STATE               ERROR     PORTS\n' +
                                    'l87q8yf136mw   web-server.1       nginx:latest   nas-ubuntu-manager   Running         Starting 14 seconds ago\n' +
                                    'u8g6z4tq1pnf    \\_ web-server.1   nginx:latest   nas-ubuntu-kj        Shutdown        Shutdown 17 seconds ago\n' +
                                    'tadodwop5eb2   web-server.2       nginx:latest   nas-ubuntu-manager   Running         Running about an hour ago\n' +
                                    '8kj0jwx9appt   web-server.3       nginx:latest   nas-ubuntu-manager   Running         Starting 7 seconds ago\n' +
                                    'al9zays42keq    \\_ web-server.3   nginx:latest   nas-ubuntu-kj        Shutdown        Shutdown 11 seconds ago               \n' +
                                    'g1vn95qqxfk9   web-server.4       nginx:latest   nas-ubuntu-manager   Running         Running 49 minutes ago\n' +
                                    'tvxswje6wsba   web-server.5       nginx:latest   nas-ubuntu-manager   Running         Starting 26 seconds ago\n' +
                                    'qb5s9tuqz01p    \\_ web-server.5   nginx:latest   nas-ubuntu-kj        Shutdown        Shutdown 30 seconds ago\n' +
                                    'qymvlefsojos   web-server.6       nginx:latest   nas-ubuntu-manager   Running         Running 49 minutes ago      '}
                            </SyntaxHighlighter>
                        </li>
                        <li>nodul <i>drain</i> (<i>nas-ubuntu-kj</i>) devine indisponibil si se mai distribuie task-uri/containere pe acest nod</li>
                        <li>daca se afiseaza <b>docker node ls</b>, atunci pe coloana AVAILABILITY, va apare <i>Drain</i> pentru nodul <i>nas-ubuntu-kj</i>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker node ls'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                                {'ID                            HOSTNAME             STATUS    AVAILABILITY   MANAGER STATUS   ENGINE VERSION\n' +
                                    'rs7xvwww8ywv0pmk3hyol1ocl     nas-ubuntu-kj        Ready     Drain                           20.10.18\n' +
                                    'f8qfjgn2zh5thw1caniutv8le *   nas-ubuntu-manager   Ready     Active         Leader           20.10.22\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>pentru a devini din nou activ/disponibil:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker node update --availability active nas-ubuntu-kj'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Actualizare availability (pentru manager):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node update --availability active manager'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge un nod:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node rm worker-2'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a promova un noduri worker la mananger:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node promote worker1 worker2'}
                    </SyntaxHighlighter>
                    In acest moment, daca se afiseaza nodurile <b>docker node ls</b> se va afisa in dreptul lor pe coloana <i>Manager Status</i>: <b>Reachable</b>

                    <hr/>
                    Pentru a retrograda un manager:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node demote manager'}
                    </SyntaxHighlighter>
                    (dupa, nu se va mai putea rula comanda <b>docker node ls</b>, de pe aceasta masina/nod)

                    <hr/>
                    <b>
                        5. Serviciile
                    </b>
                    <br/>
                    <br/>

                    Un serviciu este definirea task-urilor de executat pe nodurile manager sau worker.
                    <br/>
                    Containerele care ruleaza intr-un service se numesc <b>task</b>-uri.

                    <br/>
                    <br/>
                    Serviciile pot fi deploy-ate doar prin intermediul managerilor;
                    deci trebuie sa fim un nod manager pentru a executa comenzi ce implica serviciile.

                    <br/>

                    Pentru a creea un serviciu cu numele <i>web-server</i>, expus pe portul 8080 extern (80 intern) pe baza imaginii <i>nginx:latest</i>, si avand 3 replici:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name web-server -p 8080:80 --replicas 3 nginx:latest'}
                    </SyntaxHighlighter>
                    (in exemplul de mai sus, serviciul specifica ca vrea <i>3 replici</i> ale containerului <i>nginx:latest</i>;
                    orghestratorul va creea <i>3 task-uri</i>  si fiecare task este asociat cu un container;
                    fiecare task de distribuie nodurile disponibile)
                    <br/>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'lk6yyet48xid7ueumf3adj779\n' +
                            'overall progress: 3 out of 3 tasks\n' +
                            '1/3: running   [==================================================>]\n' +
                            '2/3: running   [==================================================>]\n' +
                            '3/3: running   [==================================================>]\n' +
                            'verify: Service converged\n'}
                    </SyntaxHighlighter>

                    Creare serviciu cu constrangeri (de exemplu: sa nu fie adaugat pe manager):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name web-server \\\n' +
                            '--constraint node.role!=manager \\\n' +
                            '--label testGroup=a1 \\\n' +
                            '--mount type=volume,source=nginx-vol-swarm,destination=/etc/nginx \\\n' +
                            '--network my-swarm-net \\\n' +
                            '--replicas 3 \\\n' +
                            '--replicas-max-per-node 2 \\\n' +
                            '--restart-max-attempts 3 \\\n' +
                            '--update-parallelism 2 \\\n' +
                            '--publish 8080:80 \\\n' +
                            'nginx:latest'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li><b>--publish</b> 8080:80 {"=>"} publicarea portului, extern pe 8080
                            <br/>
                            testare:
                            <ul>
                                <li>
                                    din nodul: <i>nas-ubuntu-manager</i> / <i>nas-ubuntu-kj</i> {"=>"} curl 192.168.1.10:8080 / curl 192.168.1.6:8080
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>--mount</b> type=volume,source=nginx-vol-swarm,destination=/etc/nginx
                            <br/>
                            pentru montarea unui volum
                            <br/>
                            daca se sterge serviciul (<b>docker service rm web-server</b>), volumul va ramane,  nu se va sterge si el (<b>docker volume ls</b>);
                            <br/>
                            se poate verifica in container volumul montat: <b>docker container exec -it [CONTAINER-ID] bash</b>
                        </li>
                    </ul>
                    <ul>
                        <li>alte exemple de constrangeri:
                            <ul>
                                <li>
                                    --constraint node.hostname==worker-1
                                </li>
                                <li>
                                    --constraint node.platfrom.arch!=x86_64
                                </li>
                            </ul>
                        </li>
                    </ul>
                    Deci pentru a evita planificarea unui serviciu pe un anumit nod:
                    <ul>
                        <li>nodul trebuie ai aiba setata availablility = DRAIN</li>
                        <li>la crearea serviciului prin adaugarea de constrangeri</li>
                    </ul>

                    <hr/>

                    Se pot folosi <b>template</b>-uri in timp se ce ruleaza comanda <b>service create</b> in swarm;
                    <br/>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name web-server-template --hostname="{{.Node.Hostname}}-{{.Service.Name}}"  nginx'}
                    </SyntaxHighlighter>
                    Placehodere valide:
                    <ul>
                        <li>.Service.ID</li>
                        <li>.Service.Name</li>
                        <li>.Service.Labels</li>
                        <li>.Node.ID</li>
                        <li>.Node.Hostname</li>
                        <li>.Task.ID</li>
                        <li>.Task.Name</li>
                        <li>.Task.Slot</li>
                    </ul>
                    Optiunile care pot fi folosite cu template-uri / care suporta placehodere:
                    <ul>
                        <li>--hostname</li>
                        <li>--mount</li>
                        <li>--env</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it f43ba989e4d3 bash'}
                    </SyntaxHighlighter>
                    unde <i>f43ba989e4d3</i> este containerul nou creat.
                    <br/>
                    Daca se executa comanda: <b>hostname</b> se va afisa <b>nas-ubuntu-kj-web-server-template</b>, pentru ca:
                    <ul>
                        <li>.Node.Hostname {"=>"} nas-ubuntu-kj</li>
                        <li>.Service.Name {"=>"} web-server-template</li>
                    </ul>

                    <hr/>
                    Listere servicii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service ls'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'ID             NAME         MODE         REPLICAS   IMAGE          PORTS\n' +
                            'lk6yyet48xid   web-server   replicated   3/3        nginx:latest   *:8080->80/tcp\n'}
                    </SyntaxHighlighter>
                    La afisare se va vedea coloana MODE (reprezinta tipul/modul de deployment pentru un serviciu); exista 2 valori posibile:
                    <ul>
                        <li>
                            <b>replicated</b> [implicit]{"=>"} (serviciile replicate) creaza un numar specificat/dorit de task-uri identice
                        </li>
                        <li>
                            <b>global</b> {"=>"} (serviciile globale) creeaza 1 replica ale containerului pe fiecare nod;
                            <br/>
                            se specifica prin optiunea <b>--mode global</b>
                            <br/>
                            de fiecare data cand se creeaza un nod in swam, <i>orghestratorul</i> creaza un task si <i>scheduler</i>-ul asigneaza task-ul noului nod creat;
                            <br/>
                            de exemplu, e util daca ai un container anti-virus si vrei ca atunci cand se creeaza un nou nod se se creeze automat si pe nodul repectiv containerul anti-virus.
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker service create --name antivirus --mode global -dt ubuntu'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Listare task-uri (procese) a unui serviciu cu numele <i>web-server</i> (containerele unui serviciu):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service ps web-server'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'ID             NAME           IMAGE          NODE                 DESIRED STATE   CURRENT STATE                ERROR     PORTS\n' +
                            'u8g6z4tq1pnf   web-server.1   nginx:latest   nas-ubuntu-kj        Running         Running 2 minutes ago                  \n' +
                            'tadodwop5eb2   web-server.2   nginx:latest   nas-ubuntu-manager   Running         Running about a minute ago             \n' +
                            'al9zays42keq   web-server.3   nginx:latest   nas-ubuntu-kj        Running         Running about a minute ago      '}
                    </SyntaxHighlighter>

                    <hr/>
                    Inspectare serviciu cu numele <i>web-server</i> (format json):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service inspect web-server'}
                    </SyntaxHighlighter>
                    se vor afisa informatii legate de obiecte Docker:
                    <ul>
                        <li>Docker Containers</li>
                        <li>Docker Network</li>
                        <li>Docker Volumes</li>
                        <li>Docker Swarm Services</li>
                        <li>Docker Swarm Nodes</li>
                    </ul>

                    Inspectare serviciu cu numele <i>web-server</i> (format mai constrans):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service inspect web-server --pretty'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID:             lk6yyet48xid7ueumf3adj779\n' +
                            'Name:           web-server\n' +
                            'Service Mode:   Replicated\n' +
                            ' Replicas:      6\n' +
                            'Placement:\n' +
                            'UpdateConfig:\n' +
                            ' Parallelism:   1\n' +
                            ' On failure:    pause\n' +
                            ' Monitoring Period: 5s\n' +
                            ' Max failure ratio: 0\n' +
                            ' Update order:      stop-first\n' +
                            'RollbackConfig:\n' +
                            ' Parallelism:   1\n' +
                            ' On failure:    pause\n' +
                            ' Monitoring Period: 5s\n' +
                            ' Max failure ratio: 0\n' +
                            ' Rollback order:    stop-first\n' +
                            'ContainerSpec:\n' +
                            ' Image:         nginx:latest@sha256:0047b729188a15da49380d9506d65959cce6d40291ccfb4e039f5dc7efd33286\n' +
                            ' Init:          false\n' +
                            'Resources:\n' +
                            'Endpoint Mode:  vip\n' +
                            'Ports:\n' +
                            ' PublishedPort = 8080\n' +
                            '  Protocol = tcp\n' +
                            '  TargetPort = 80\n' +
                            '  PublishMode = ingress\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Modificare numar de replici pentru un serviciu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service scale web-server=6'}
                    </SyntaxHighlighter>
                    (daca s-a setat la crearea serviciului <i>replicas-max-per-node 2</i> si noi avem 3 noduri si vrem 7 replici,
                    atunci vom obtine eroare; de fapt una din replici va ramane in pending)
                    <br/>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'web-server scaled to 6\n' +
                            'overall progress: 6 out of 6 tasks\n' +
                            '1/6: running   [==================================================>]\n' +
                            '2/6: running   [==================================================>]\n' +
                            '3/6: running   [==================================================>]\n' +
                            '4/6: running   [==================================================>]\n' +
                            '5/6: running   [==================================================>]\n' +
                            '6/6: running   [==================================================>]\n' +
                            'verify: Service converged'}
                    </SyntaxHighlighter>

                    O alta metoda de modifica numarul de replici pentru un serviciu este:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service update --replicas 6 web-server'}
                    </SyntaxHighlighter>

                    Diferena dintre <b>docker service scale</b> si <b>docker service update --replicas</b> este ca <b>docker service scale</b> permite scalarea mai multor servicii, in timp ce
                    <b>docker service update --replicas</b> permite scalarea unui singur serviciu, de exemplu:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service scale service01=2 service02=3'}
                    </SyntaxHighlighter>

                    <hr/>

                    Modificare imagine pe baza caruia se construiesc containerele serviciului:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service update --image nginx:alpine web-server'}
                    </SyntaxHighlighter>

                    <hr/>
                    Stergere constrangere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service update --constraint-rm \'node.role!=manager\' web-server'}
                    </SyntaxHighlighter>
                    <hr/>
                    Rollback:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service update --rollback web-server'}
                    </SyntaxHighlighter>

                    <hr/>
                    Stergere serviciu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service rm web-server'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca se va va comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -a'}
                    </SyntaxHighlighter>
                    se va afisa 1 singur container, desi sunt 3 replcii; acest lucru se datoreaza faptului ca sunt distribuite pe fiecare nod;
                    <br/>
                    si noi avem 3 noduri (1 master + 2 worker).
                    <br/>
                    Se poate inspecta in continuare cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect [container]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        6. Plasamentul serviciilor
                    </b>
                    <br/>
                    <br/>

                    Swarm Servie ofera cateva metode de a controla sclare si plasarea serviciilor pe diferite noduri:
                    <ul>
                        <li>servicii: replicated si global</li>
                        <li>constrangeri de resurise (cerinte de CPU si memorie)</li>
                        <li>constrangeri de plasare (poiate rula doar pe noduri cu eticheta/label pci_complice=true)</li>
                        <li>preferinte de plasare</li>
                    </ul>

                    Creare serviciu cu contrangeri de eticheta/label:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name myconstraintservice --constraint node.labels.[cheie]==[valoare] --replicas 3 nginx'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name myconstraintservice --constraint node.labels.region==blr --replicas 3 nginx'}
                    </SyntaxHighlighter>

                    Se pot inspecta etichetele unui nod:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect f8qfjgn2zh5thw1caniutv8le --format "{{.Spec.Labels}}"'}
                    </SyntaxHighlighter>

                    Adaugare eticheta la un nod:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node update --label-add region=romania nas-ubuntu-manager'}
                    </SyntaxHighlighter>


                    Se pot inspecta etichetele unui nod, din nou:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect f8qfjgn2zh5thw1caniutv8le --format "{{.Spec.Labels}}"'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'map[region:romania]'}
                    </SyntaxHighlighter>

                    Rulare noduri in regiunea <i>romania</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name myconstraintservice --constraint node.labels.region==romania --replicas 3 nginx'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        7. Retele
                    </b>
                    <br/>
                    <br/>

                    Dintr-un nod swarm se pot lista retelele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            intre relelele specifice docker (bridge, host, none) va mai exista si:
                            <ul>
                                <li>
                                    <b>ingress</b>, cu driver = <b>overlay</b>, scope = <b>swarm</b>
                                </li>
                                <li>
                                    <b>docker_gwbridge</b>, driver = bridge, scope = local
                                    <br/>
                                    este folosită pentru a conecta Daemon Docker individuali care participă la un cluster Swarm
                                </li>
                            </ul>

                        </li>
                    </ul>
                    Pentru a inspecta o retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect ingress'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            va exista o sectiune <b>Peers</b> care va contine o lista cu:
                            <ul>
                                <li>
                                    Name {"=>"} ID cluster, nu ID manager
                                </li>
                                <li>
                                    IP {"=>"} IP worker
                                </li>
                            </ul>
                        </li>
                    </ul>
                    Pentru a creea o retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create \\\n' +
                            '--driver overlay \\\n' +
                            '--subnet=10.15.0.0/16 \\\n' +
                            '--gateway=10.15.0.2 \\\n' +
                            '--opt encrypted \\\n' +
                            '--attachable \\\n' +
                            'my-swarm-net'}
                    </SyntaxHighlighter>
                    Daca la aceasta retea nu este conectat nici un serviciu, nu va apare afisata la rularea comanezii <b>docker network ls</b>

                    <hr/>
                    <b>
                        8. Rolling out. Rolling back
                    </b>
                    <br/>
                    <br/>

                    <ul>
                        <li>Rolling out {"=>"} actualizarea task-urilor (containerelor)

                            De exemplu, stergere constrangere:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker service update --constraint-rm \'node.role!=manager\' web-server'}
                            </SyntaxHighlighter>

                        </li>
                        <li>Rolling back {"=>"} revenire a task-urilor (containerelor) intr-o stare anterioara

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker service update --rollback web-server'}
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker service rollback --detach web-server'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>
                        9. Gestiunea clusterului
                    </b>
                    <br/>
                    <br/>

                    Uneori se intampla sa crape nodul manager. Pentru a evita probleme ce pot apare din aceasta cauza,
                    se creeaza mai multe noduri de tip manager si acest concept se numeste <b>high availability</b>.
                    <br/>
                    Unul dintre manager este <b>leader</b>. Acesta poate lua mai multe decizii de orghestare.
                    <br/>
                    Ceilalti manager sunt <b>reachable</b>.
                    <br/>
                    Daca leader-ul crapa, atunci se aleage din ceilalti manageri un leader (e bine sa fie un numar impar de manageri in cluster).
                    Acest proces de obtinere a majoritatii de voturi se numeste <b>quorum</b>.
                    <br/>
                    <i>Algoritmul</i> folosit pentru a ajunge la un consens unanim la mentinerea quarum-ului se numeste <b>Raft</b>.
                    Deci leader-ul este ales prin <i>raft consens</i>.
                    <br/>
                    <b>Raft Consensus</b> sugereaza ca fiecare cluster trebuie sa aiba un numar impar de <i>reachable</i> manageri pentru a se obtine cu succes quorum-ul.
                    <br/>

                    Legat de <b>high availability</b> in Swarm:
                    <ul>
                        <li>
                            vine cu propriile caracteristici de toleranta la erori
                        </li>
                        <li>
                            docker recomanda un numar impar de noduri (in functie de cerintele de <b>high availability</b> ale origanizatiei)
                        </li>
                        <li>
                            folosind implementarea Raft, managerii mentin o stare interna consistenta a intregului swarm si a serviciile care ruleaza pe acesta
                        </li>
                        <li>
                            <i>formula tolerantei</i> {"=>"} un cluster cu N manageri tolereaza pierdea a cel mult (N-1)/2 manageri!
                            <i>formula majoritatii</i> {"=>"} cel putin mai mult decat jumatate intre noduri trebuie sa functioneze ok {"=>"}  (N+1)/2 manageri!
                        </li>
                    </ul>

                    <table className={"table table-sm table-hover table-dark"}>
                        <thead>
                            <tr>
                                <td>Dimensiune cluster</td>
                                <td>Majority / Majoritate</td>
                                <td>Fault tolerance / toleranta la erori</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>2 - problema split brain</td>
                                <td>2</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>2</td>
                                <td>1</td>
                            </tr>

                            <tr>
                                <td>4</td>
                                <td>3</td>
                                <td>1</td>
                            </tr>

                            <tr>
                                <td>5</td>
                                <td>3</td>
                                <td>2</td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <td>4</td>
                                <td>2</td>
                            </tr>

                            <tr>
                                <td>7</td>
                                <td>4</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                    </table>
                    Daca dimensiune cluster este 4, majoritatea o poate lua un nod care are cel putin 3 voturi si se permite ca un (4-1)/2=1 sa fie jos (fault tolerance=1).
                    Daca dimensiune cluster este 7, majoritatea o poate lua un nod care are cel putin 3 voturi si se permite ca (7-1)/2 = 3 manageri sa fie jos.

                    <hr/>
                    Daca avem 2 noduri de tip manager si unul dintre noduri piaca (se opreste docker-ul), atunci cand se va incerca:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node ls'}
                    </SyntaxHighlighter>
                    se va obtine eroarea:
                    <SyntaxHighlighter>
                        {'Error response from daemon: rpc error: code = DeadlineExceeded desc = context deadline exceeded'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter>
                        {'Error response from daemon: rpc error: code = Unknown desc = The swarm does not have a leader. It\'s possible that too few managers are online.' +
                            'Make sure more than haft of the managers are online.'}
                    </SyntaxHighlighter>
                    Deci, este nevoie ca mai mult decat jumatate din manager sa fie in picioare!
                    Pentru ca Swarm cluster sa functioneze in mod corespunzator, cel putin mai mult decat jumatate intre noduri trebuie sa functioneze ok ((n/2)+1 - unde n=numarul total de noduri).

                    <hr/>
                    Numarul minim de manageri pentru a mentine un quorum de 6 noduri este = 4!
                    <hr/>
                    Sa presupunem ca avem:
                    <ul>
                        <li>manager</li>
                        <li>worker-1</li>
                        <li>worker-2</li>
                        <li>worker-3</li>
                        <li>worker-4</li>
                    </ul>
                    Pentru a promova <i>worker-3</i> si <i>worker-4</i> la <i>manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node promote worker-3 worker-4'}
                    </SyntaxHighlighter>
                    In acest moment, daca se afiseaza nodurile <b>docker node ls</b> se va afisa in dreptul lor pe coloana <i>Manager Status</i>: <b>Reachable</b>.
                    <br/>
                    Acum, daca manager este prea ocupat sa raspunda la unele cereri, la va prelua un nod de tip <b>Reachable</b>.
                    <br/>
                    Daca unul manager-ul <b>leader</b> pateste ceva, unul din nodurile <b>reachable</b>, va prelua rolul de <i>leader</i>.
                    <br/>
                    Pentru a retrograda un manager:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node demote manager'}
                    </SyntaxHighlighter>
                    (dupa, nu se va mai putea rula comanda <b>docker node ls</b>, de pe aceasta masina/nod).
                    <br/>
                    In plus, un nou nod va devenii leader si se poate verifica ruland comanda <b>docker node ls</b> de pe un nod <b>Reachable</b>.

                    <br/>
                    Daca mai exista un sigur nod manager si leader acesta nu va putea fi:
                    <ul>
                        <li>
                            sters (docker node rm)
                        </li>
                        <li>
                            retrogradat (docker node demote )
                        </li>
                        <li>
                            parasi clusterul (docker swarm leave);
                            <br/>
                            dar se poate: <b>docker swarm leave --force</b>
                            <br/>
                            (si in acest moment nu prea se mai poate face mare lucru in cluster;
                            <br/>
                            in afara de <b>docker swarm init</b> - pentru a initializa un un cluster in VM)
                        </li>
                    </ul>
                    se va optine eroare.

                    <hr/>
                    <b>
                        10. Gestiunea dezastrelor intr-un cluster Swarm
                    </b>
                    <br/>
                    <br/>

                    Daca avem 1 manager, 3 worker si 1 serviciu cu 3 replici a unui container, atunci fiecare worker va contine o replica.
                    <br/>
                    Daca unul din worker crapa, atunci unui worker i se comunica sa mai pornesca o replica, si atunci vom avea:
                    <ul>
                        <li>
                            1 worker {"=>"} 1 replica
                        </li>
                        <li>
                            1 worker {"=>"} 2 replici
                        </li>
                    </ul>
                    Daca worker-ul crapat isi revine, va putea gazdui noi replici.

                    <hr/>

                    Sa prespunem ca clusterul a crapat. Trebuie sa initializam unul nou.
                    <br/>
                    Adaugand optiunea <b>--force-new-cluster</b> va face nodul curent manager leader si va mentine legaturile cu vechiul cluster
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm init --force-new-cluster --advertise-addr 35.196.2.174'}
                    </SyntaxHighlighter>
                    (daca avem 2 noduri de tip manager si un worker; si facem drain la ambele noduri de tip manager, atunci serviciile vor continua sa existe, dar <i>docker node ls</i> nu va mai functiona;
                    si atunci trebuie data comanda de mai sus pe un nod de tip manager)
                    {/*Toate serviciile din vechiul cluster au disparut.*/}
                    {/*Se poate verifica:*/}
                    {/*<SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>*/}
                    {/*    {'docker service ls'}*/}
                    {/*</SyntaxHighlighter>*/}
                    Pentru a prevenii astfel de probleme si a reduce timpul de readuce aplicatia din nou pe linia de plutire:
                    <ul>
                        <li>
                            se pot creea servicii globale.
                            <br/>
                            Creare servicii globale (va exista o replica pe fiecare nod):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker service create --name redis-swarm --mode global redis:latest'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se opreste docker:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'sudo systemctl stop docker'}
                            </SyntaxHighlighter>
                            <br/>
                            backup la directorul: <b>/var/lib/docker/swarm</b>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'sudo cp -r /var/lib/docker/swarm /home/kj/swarm-backup/'}
                            </SyntaxHighlighter>

                            Ne logam ca root:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'sudo su -'}
                            </SyntaxHighlighter>

                            Stergem fisierul /var/lib/docker/swarm, de pe nod
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'rm -rf /var/lib/docker/swarm'}
                            </SyntaxHighlighter>

                            Copiem backup-ul in nod:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'cp -r /home/kj/swarm-backup/ /var/lib/docker/'}
                            </SyntaxHighlighter>

                            se reporneste docker:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'sudo systemctl start docker'}
                            </SyntaxHighlighter>

                            se initializeaza clusterul:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker swarm init --force-new-cluster'}
                            </SyntaxHighlighter>
                            iar serviciile si replicele se recreaza!
                        </li>
                    </ul>

                    Numarul maxim de noduri cazute intr-un cluster swarm cu 6 noduri care pot mentine quarum este = 2

                    <hr/>
                    <b>
                        11. Locking si unloking cluster Swarm
                    </b>
                    <br/>
                    <br/>
                    Swarm Cluster contine e multime de date sensibile:
                    <ul>
                        <li>cheia TLS folosita pentru criptarea comunicarea dintre nodurile swarm</li>
                        <li>cheile folosite pentru criptare si decriptarea log-urilor Raft de pe disc</li>
                    </ul>
                    Daca Swarm este compromis si daca datele sunt stocate plain-test, un atacator poate obtine toate aceste date sensibile.
                    <br/>
                    Docker Lock permite control asupra acestor chei.
                    <br/>
                    Daca ne conectam pe nodul <i>manager</i> si merge in directorul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /var/lib/docker/swarm/certificates'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'swarm-node.crt  swarm-node.key  swarm-root-ca.crt'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a face lock pe un cluster, se foloseste optiunea <b>--autolock</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --autolock=true'}
                    </SyntaxHighlighter>
                    se va afisa un mesaj cum sa faci unlock pe baza unei chei, dupa restart docker:
                    <SyntaxHighlighter>
                        {'Swarm updated.\n' +
                            'To unlock a swarm manager after it restarts, run the `docker swarm unlock`\n' +
                            'command and provide the following key:\n' +
                            '\n' +
                            '    SWMKEY-1-M1rsbkBLfukRJuam3H8sxixhFTRV8YZlhEpmhv3itBs\n' +
                            '\n' +
                            'Please remember to store this key in a password manager, since without it you\n' +
                            'will not be able to restart the manager.\n'}
                    </SyntaxHighlighter>
                    Daca restartam docker:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'systemctl restart docker'}
                    </SyntaxHighlighter>
                    si incercam comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node ls'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Error response from daemon: Swarm is encrypted and needs to be unlocked before it can be used. Please use "docker swarm unlock" to unlock it.\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a face unlock:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm unlock'}
                    </SyntaxHighlighter>
                    se va cere introducerea cheii (generate mai sus): <i>SWMKEY-1-M1rsbkBLfukRJuam3H8sxixhFTRV8YZlhEpmhv3itBs</i>
                    <br/>

                    acum va afisa nodurile, fara eroare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node ls'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca s-a pierdut cheia se se vrea alta:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm unlock-key'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'To unlock a swarm manager after it restarts, run the `docker swarm unlock`\n' +
                            'command and provide the following key:\n' +
                            '\n' +
                            '    SWMKEY-1-M1rsbkBLfukRJuam3H8sxixhFTRV8YZlhEpmhv3itBs\n' +
                            '\n' +
                            'Please remember to store this key in a password manager, since without it you\n' +
                            'will not be able to restart the manager.\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a genera alta cheie:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm unlock-key --rotate'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Successfully rotated manager unlock key.\n' +
                            '\n' +
                            'To unlock a swarm manager after it restarts, run the `docker swarm unlock`\n' +
                            'command and provide the following key:\n' +
                            '\n' +
                            '    SWMKEY-1-WJGCj4O1p6JqYJOkr6GaxFPD1fUTl64nf22v81DsN2k\n' +
                            '\n' +
                            'Please remember to store this key in a password manager, since without it you\n' +
                            'will not be able to restart the manager.\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a face dezactiva <i>autolock</i>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm update --autolock=false'}
                    </SyntaxHighlighter>

                    <hr/>

                    Se poate initializa un cluster swarm auto-lock:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker swarm init --autolock'}
                    </SyntaxHighlighter>

                    {/*Cheile TLS sunt criptate prin activarea auto-lock pe un cluster swarm:*/}
                    {/*<ul>*/}
                    {/*    <li>comunicare node-to-node</li>*/}
                    {/*    <li>raft logs</li>*/}
                    {/*</ul>*/}

                    <hr/>
                    <b>
                        12. Curatare cluster Swarm
                    </b>
                    <br/>
                    <br/>

                    Stergere servici:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service rm [nume-service]'}
                    </SyntaxHighlighter>

                    Sterge noduri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node rm [id-nod] --force'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        13. Docker stack
                    </b>
                    <br/>
                    <br/>

                    Un docker stack poate fi folosit pentru a gestiona o aplicatie cu mai multe servicii.
                    <br/>
                    Un stack este este un grup de servicii interconectate, care partajeaza dependinte si care pot fi orgestrate si scalate impreuna.
                    <br/>
                    Un stack (stiva) poate compune fisierul YAML, ca cel definit prin Docker Compose.
                    <br/>
                    In fisierul YAML se poate defini orice poate fi definit si prin Docker Service.

                    <br/>
                    <br/>

                    Instalare Docker Compose:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get install docker-compose-plugin'}
                    </SyntaxHighlighter>

                    Verificare Docker Compose:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Docker Compose version v2.14.1'}
                    </SyntaxHighlighter>

                    <br/>
                    Daca avem un  un fisier <i>docker-compose.yml</i>, cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  webserver:\n' +
                            '    image: nginx\n' +
                            '    ports:\n' +
                            '       - "8081:80"\n' +
                            '  database:\n' +
                            '    image: redis'}
                    </SyntaxHighlighter>
                    si dam comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up -d'}
                    </SyntaxHighlighter>
                    putem primi un mesaj de avertizare:
                    <SyntaxHighlighter>
                        {'WARNING: The Docker Engine you\'re using is running in swarm mode. \n' +
                            '\n' +
                            'Compose does not use swarm mode to deploy services to multiple nodes in a swarm. ' +
                            'All containers will be scheduled on the current node.\n' +
                            '\n' +
                            'To deploy your application across the swarm, use "docker stack deploy" '}
                    </SyntaxHighlighter>
                    si afisa:
                    <SyntaxHighlighter>
                        {'+] Running 8/8\n' +
                            ' ⠿ database Pulled                                                                                                    5.0s \n' +
                            '   ⠿ 3f4ca61aafcd Already exists                                                                                      0.0s \n' +
                            '   ⠿ c3775af77098 Pull complete                                                                                       0.8s\n' +
                            '   ⠿ fa7c5c7e501c Pull complete                                                                                       1.4s \n' +
                            '   ⠿ 4059b4d2a3ce Pull complete                                                                                       2.2s \n' +
                            '   ⠿ 6bc523bfb16a Pull complete                                                                                       2.4s \n' +
                            '   ⠿ 20bf15ad3c24 Pull complete                                                                                       2.6s \n' +
                            ' ⠿ webserver Pulled                                                                                                   1.5s \n' +
                            '[+] Running 3/3\n' +
                            ' ⠿ Network kj_default        Created                                                                                  0.2s \n' +
                            ' ⠿ Container kj-database-1   Started                                                                                  2.5s \n' +
                            ' ⠿ Container kj-webserver-1  Started                                                                                  2.6s'}
                    </SyntaxHighlighter>
                    (un mod implicit numele servicilor vor fi prefixate cu numele directorului in care se afla <i>docker-compose.yaml</i> )
                    <br/>
                    Vom opri serviciile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose down'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'+] Running 3/3\n' +
                            ' ⠿ Container kj-webserver-1  Removed                               0.5s \n' +
                            ' ⠿ Container kj-database-1   Removed                               0.7s \n' +
                            ' ⠿ Network kj_default        Removed                               0.3s'}
                    </SyntaxHighlighter>

                    <hr/>

                    Folosind acelasi fisier <i>docker-compose.yml</i> construit mai sus putem creea un stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack deploy --compose-file docker-compose.yml [nume-stack]'}
                    </SyntaxHighlighter>
                    (se vor crea serviciile si retele definite in fisierul <i>docker-compose.yml</i>)
                    <br/>
                    <br/>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack deploy --compose-file docker-compose.yml kj-demo'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'Creating network kj-demo_default\n' +
                            'Creating service kj-demo_webserver\n' +
                            'Creating service kj-demo_database'}
                    </SyntaxHighlighter>

                    <hr/>
                    Listare stack-uri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack ls'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NAME      SERVICES   ORCHESTRATOR\n' +
                            'kj-demo   2          Swarm\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Listare serviciile unui stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack services [nume-stack] '}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack services kj-demo '}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID             NAME                MODE         REPLICAS   IMAGE          PORTS\n' +
                            'g100huai6g2b   kj-demo_database    replicated   1/1        redis:latest\n' +
                            '3vmaqurdl3fi   kj-demo_webserver   replicated   1/1        nginx:latest   *:8081->80/tcp\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Listare procesele unui stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack ps [nume-stack] '}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack ps kj-demo'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID             NAME                  IMAGE          NODE                 DESIRED STATE   CURRENT STATE           ERROR     PORTS\n' +
                            'lk6vbx3uaiej   kj-demo_database.1    redis:latest   nas-ubuntu-kj        Running         Running 3 minutes ago\n' +
                            '5yuqni4og6vq   kj-demo_webserver.1   nginx:latest   nas-ubuntu-manager   Running         Running 3 minutes ago\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Stergere stack:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack rm [nume-stack] '}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stack rm kj-demo'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Removing service kj-demo_database\n' +
                            'Removing service kj-demo_webserver\n' +
                            'Removing network kj-demo_default'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        14. Rezolvare diverse probleme
                    </b>
                    <br/>
                    <br/>

                    <hr/>
                    Pentru a evita, problama legata de permisiuni:
                    <SyntaxHighlighter>
                        {'Got permission denied while trying to connect to the Docker daemon socket at unix:///var/run/docker.sock: Get "http://%2Fvar%2Frun%2Fdocker.sock/v1.24/containers/json": dial unix /var/run/docker.sock: connect: permission denied'}
                    </SyntaxHighlighter>
                    se pot rula urmatoarele comezi:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo addgroup --system docker\n' +
                            'sudo adduser $USER docker\n' +
                            'newgrp docker'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un serviciu poate ajunge in starea <i>Pending</i>:
                    <ul>
                        <li>toate nodurile sunt <i>drained</i>, si se creeaza un serviciu, atunci acesta va fi pus in pending pana cand un nod devine disponibil</li>
                        <li>se poate rezerva o anumita memorie pentru un serviciu; daca nu exista nici un nod in swarm are memoria disponibila, serviciul ramane in pending pana cand un nod este disponibil si are memoria necesara</li>
                        <li>exista contrangeri de plasare</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerSwarmResumeContent;