import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ServiceWebMockLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-service-web-mock", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>JAX-RS - Servicii Web Mock</b>
                    <br/>
                    <br/>

                    Exemplu GET (fisierul getDetaliiNumarRegistru.json se gaseste in /resources/):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@GET\n' +
                            '@Path("registratura/{nrRegAn}")\n' +
                            '@Produces(MediaType.APPLICATION_JSON)\n' +
                            'public MyResponse getDetaliiNumarRegistruURL(\n' +
                            '        @PathParam("nrRegAn") String id,\n' +
                            '        @QueryParam("idPartener") String idPartener,\n' +
                            '        @QueryParam("userPartener") String userPartener\n' +
                            ')  {\n' +
                            '\n' +
                            '    InputStream inputStream = getClass().getClassLoader().getResourceAsStream("/mock/getDetaliiNumarRegistru.json");\n' +
                            '\n' +
                            '    try {\n' +
                            '        String jsonString = new String(inputStream.readAllBytes(), StandardCharsets.UTF_8);\n' +
                            '        ObjectMapper objectMapper = new ObjectMapper();\n' +
                            '        MyResponse response = null;\n' +
                            '        try {\n' +
                            '            response = objectMapper.readValue(jsonString, MyResponse.class);\n' +
                            '        } catch (JsonProcessingException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '\n' +
                            '        return response;\n' +
                            '    } catch (IOException e) {\n' +
                            '        throw new RuntimeException(e);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu POST (fisierul getDetaliiNumarRegistru.json se gaseste in /resources/):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'// "registratura" + "/" + "tipDocument"\n' +
                            '@POST\n' +
                            '@Path("registratura/tipDocument")\n' +
                            '@Produces(MediaType.APPLICATION_JSON)\n' +
                            '@Consumes(MediaType.APPLICATION_JSON)\n' +
                            'public AnotherResponse getTipDocumentResponse(MyRequest myResponse)  {\n' +
                            '    InputStream inputStream = getClass().getClassLoader().getResourceAsStream("/mock/getTipuriDocumente.json");\n' +
                            '\n' +
                            '    try {\n' +
                            '        String jsonString = new String(inputStream.readAllBytes(), StandardCharsets.UTF_8);\n' +
                            '        ObjectMapper objectMapper = new ObjectMapper();\n' +
                            '        AnotherResponse response = null;\n' +
                            '        try {\n' +
                            '            response = objectMapper.readValue(jsonString, AnotherResponse.class);\n' +
                            '        } catch (JsonProcessingException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '\n' +
                            '        return response;\n' +
                            '    } catch (IOException e) {\n' +
                            '        throw new RuntimeException(e);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu apel serviciu de mai sus, din Java:
                    <SyntaxHighlighter>
                        {'@Override\n' +
                            'public AnotherResponse getTipuriDocument(String idPartener, String userPartener) {\n' +
                            '    String baseUrl = _configuration.url();\n' +
                            '    String url = baseUrl + "registratura" + "/" + "tipDocument";\n' +
                            '  \n' +
                            '    Partener partener = new Partener();\n' +
                            '    partener.setIdPartener(idPartener);\n' +
                            '    partener.setUserPartener(userPartener);\n' +
                            '\n' +
                            '    AnotherRequest anotherRequest = new AnotherRequest();\n' +
                            '    anotherRequest.setPartener(partener);\n' +
                            '\n' +
                            '    StringEntity stringEntity = new StringEntity(toJson(anotherRequest), HTTP.UTF_8);\n' +
                            '    HttpPost httpPost = new HttpPost(url);\n' +
                            '\n' +
                            '    httpPost.addHeader(HttpHeaders.CONTENT_TYPE, MediaType.APPLICATION_JSON);\n' +
                            '    httpPost.setHeader(HttpHeaders.USER_AGENT, "ApacheHttpClient");\n' +
                            '    httpPost.setEntity(stringEntity);\n' +
                            '\n' +
                            '    String result = null;\n' +
                            '    boolean httpUnsecureCertificates = false;\n' +
                            '    try (CloseableHttpClient httpClient = httpUnsecureCertificates ? getHttpClient() : HttpClients.createDefault();\n' +
                            '         CloseableHttpResponse response = httpClient.execute(httpPost)) {\n' +
                            '\n' +
                            '        HttpEntity entity = response.getEntity();\n' +
                            '\n' +
                            '        if (entity != null) {\n' +
                            '            result = EntityUtils.toString(entity);\n' +
                            '\n' +
                            '            ObjectMapper om = new ObjectMapper();\n' +
                            '            om.configure(DeserializationFeature.FAIL_ON_UNKNOWN_PROPERTIES, false);\n' +
                            '            return om.readValue(result, AnotherResponse.class);\n' +
                            '        }\n' +
                            '\n' +
                            '    } catch (Exception e) {\n' +
                            '        LOGGER.error("=== : " + e.getMessage());\n' +
                            '        throw new RuntimeException(result);\n' +
                            '    }\n' +
                            '\n' +
                            '    return null;\n' +
                            '}' +
                            ''}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter>
                        {'private String toJson(Object object) {\n' +
                            '    ObjectWriter ow = new ObjectMapper().writer().withDefaultPrettyPrinter();\n' +
                            '    String json = null;\n' +
                            '    try {\n' +
                            '        json = ow.writeValueAsString(object);\n' +
                            '    } catch (JsonProcessingException e) {\n' +
                            '        e.printStackTrace();\n' +
                            '    }\n' +
                            '    return json;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu apel serviciu de mai sus, din <b>.http</b>:
                    <SyntaxHighlighter>
                        {'POST [BASE_URL]/registratura/tipDocument\n' +
                            'User-Agent: ApacheHttpClient\n' +
                            '\n' +
                            '{\n' +
                            '    "partener": {\n' +
                            '                "idPartener": "test",\n' +
                            '                "userPartener": "user test"\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://lifedev-solutions.blogspot.com/2020/04/public-jax-rs-application-implementation.html"}>
                                Public JAX-RS Application Implementation
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default ServiceWebMockLiferayContent;