import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CollectionsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-collections", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Colectii</b>
                    <br/>
                    <br/>

                    <b>Vec</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'let mut v: Vec<i32> = Vec::new();'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'v.push(2);\n' +
                            'v.push(4);'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'let x = v.pop(); // 4\n' +
                            'println!("{}", v[1]); // 2'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>vec!</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'let mut v = vec![2,4];'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>HashMap</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'let mut h: HashMap<u8, bool> = HashMap::new();'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'v.insert(2, true);\n' +
                            'v.insert(4, false);'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'let x = h.remove(&5).unwrap();'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CollectionsRustContent;