import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class SearchContainerPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-search-container", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Actualizare  service: Creare metode de returnare lista si numarul elementelor din lista</b>
                    <br/>
                    <br/>

                    Se completeaza clasa <i>ConfigIBServiceImpl</i> cu metodele:
                    <ul>
                        <li>getConfigIBs()</li>
                        <li>getConfigIBsCount()</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.liferay.vanilla.data.service.impl;\n' +
                        '\n' +
                        'import com.liferay.portal.aop.AopService;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.model.ConfigIB;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.base.ConfigIBServiceBaseImpl;\n' +
                        '\n' +
                        'import java.util.ArrayList;\n' +
                        'import java.util.List;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author Brian Wing Shun Chan\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '\tproperty = {\n' +
                        '\t\t"json.web.service.context.name=ibedaria",\n' +
                        '\t\t"json.web.service.context.path=ConfigIB"\n' +
                        '\t},\n' +
                        '\tservice = AopService.class\n' +
                        ')\n' +
                        'public class ConfigIBServiceImpl extends ConfigIBServiceBaseImpl {\n' +
                        '\n' +
                        '\tpublic ConfigIB addConfigIB(String code, String name, String value){\n' +
                        '\n' +
                        '\t\t// todo de verificat permisiuni\n' +
                        '\n' +
                        '\t\treturn configIBLocalService.addConfigIB(code, name, value);\n' +
                        '\t\t// return ConfigIBLocalServiceUtil.addConfigIB(code,name, value);\n' +
                        '\n' +
                        '\t}\n' +
                        '\n' +
                        '\tpublic List<ConfigIB> getConfigIBs(int startIndex, int endIndex){\n' +
                        '\n' +
                        '\t\tList<ConfigIB> configIBS = new ArrayList<>();\n' +
                        '\n' +
                        '\t\tconfigIBS = configIBLocalService.getConfigIBs(startIndex, endIndex);\n' +
                        '\n' +
                        '\t\treturn configIBS;\n' +
                        '\n' +
                        '\t}\n' +
                        '\n' +
                        '\tpublic int getConfigIBsCount(){\n' +
                        '\t\treturn configIBLocalService.getConfigIBsCount();\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Apoi se ruleaza Service Builder-ul si se face deploy:
                    <ul>
                        <li>
                            <b>modules\data\data-service{">"} ..\..\..\gradlew buildService</b>
                        </li>
                        <li>
                            <b>modules\data\data-service{">"} ..\..\..\gradlew deploy</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Actualizare controller: returnare lista</b>
                    <br/>
                    <br/>

                    Se obtine referinta catre <i>ConfigIBService</i> prin intermediul adnotarii <b>@Reference</b> si se pune pe <i>renderRequest</i> lista:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'renderRequest.setAttribute("entries", configIBService.getConfigIBs(0,10));'}
                    </SyntaxHighlighter>

                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.helloworld.portlet;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.ConfigIBService;\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants.HelloWorldPortletKeys;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                        '\n' +
                        'import javax.portlet.Portlet;\n' +
                        'import javax.portlet.PortletException;\n' +
                        'import javax.portlet.RenderRequest;\n' +
                        'import javax.portlet.RenderResponse;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import java.io.IOException;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=HelloWorld",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class HelloWorldPortlet extends MVCPortlet {\n' +
                        '\n' +
                        '\tprivate ConfigIBService configIBService;\n' +
                        '\n' +
                        '\t@Reference(unbind = "-")\n' +
                        '\tpublic void setConfigIBService(ConfigIBService configIBService) {\n' +
                        '\t\tthis.configIBService = configIBService;\n' +
                        '\t}\n' +
                        '\n' +
                        '\t@Override\n' +
                        '\tpublic void doView(RenderRequest renderRequest, RenderResponse renderResponse) throws IOException, PortletException {\n' +
                        '\n' +
                        '\t\trenderRequest.setAttribute("entries", configIBService.getConfigIBs(0,10));\n' +
                        '\n' +
                        '\t\tsuper.doView(renderRequest, renderResponse);\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Actualizare view: folosire Search Controler pentru vizualizare</b>
                    <br/>
                    <br/>

                    Pentru gestionarea si afisare listei de elemente se foloseste <b>liferay-ui:search-container</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<p>\n' +
                        '\t<b><liferay-ui:message key="helloworld.caption"/></b>\n' +
                        '</p>\n' +
                        '\n' +
                        '<portlet:renderURL var="createEditRender">\n' +
                        '\t<portlet:param name="mvcRenderCommandName" value="/create/edit"/>\n' +
                        '</portlet:renderURL>\n' +
                        '\n' +
                        '<a href="${createEditRender}">Creaza configurare</a>\n' +
                        '\n' +
                        '<liferay-ui:search-container delta="10" emptyResultsMessage="no-entries-were-found" total="${entries.size()}">\n' +
                        '\t<liferay-ui:search-container-results results="${entries}"/>\n' +
                        '\t\n' +
                        '\t<liferay-ui:search-container-row\n' +
                        '\t\t\tclassName="ro.ibedaria.liferay.vanilla.data.model.ConfigIB"\n' +
                        '\t\t\tkeyProperty="id"\n' +
                        '\t\t\tmodelVar="configIB"\n' +
                        '\t\t\tescapedModel="<%=true%>"\n' +
                        '\t>\n' +
                        '\t\t<liferay-ui:search-container-column-text property="name"/>\n' +
                        '\t\t<liferay-ui:search-container-column-text property="value"/>\n' +
                        '\n' +
                        '\t</liferay-ui:search-container-row>\n' +
                        '\t\n' +
                        '\t<liferay-ui:search-iterator/>\n' +
                        '</liferay-ui:search-container>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Paginare</b>
                    <br/>
                    <br/>

                    Actulizare metoda <i>doView()</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                        '\tpublic void doView(RenderRequest renderRequest, RenderResponse renderResponse) throws IOException, PortletException {\n' +
                        '\n' +
                        '\t\tint delta = ParamUtil.getInteger(renderRequest, "delta");\n' +
                        '\t\tint cur = ParamUtil.getInteger(renderRequest, "cur");\n' +
                        '\t\tint startIndex = delta * (cur - 1) ;\n' +
                        '\t\tint endIndex = delta * cur;\n' +
                        '\t\trenderRequest.setAttribute("total", configIBService.getConfigIBsCount());\n' +
                        '\n' +
                        '\t\trenderRequest.setAttribute("entries", configIBService.getConfigIBs(startIndex, endIndex));\n' +
                        '\n' +
                        '\t\tsuper.doView(renderRequest, renderResponse);\n' +
                        '\t}'}
                    </SyntaxHighlighter>

                    Actulizare atribut <b>total</b> cu valoare <i>total</i> setata in controller:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:search-container delta="10" emptyResultsMessage="no-entries-were-found" total="${total}">...</liferay-ui:search-container>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Editare</b>
                    <br/>
                    <br/>

                    Se adauga o noua coloana care va contine un link care va duce catre pagina de editare; link-ul va contine id-ul inregistrarii (<i>configIB.id</i>).
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:search-container-column-text name="action">\n' +
                        '\t<portlet:renderURL var="editRender">\n' +
                        '\t\t<portlet:param name="mvcRenderCommandName" value="/create/edit"/>\n' +
                        '\t\t<portlet:param name="entityId" value="${configIB.id}"/>\n' +
                        '\t</portlet:renderURL>\n' +
                        '\t<a href="${editRender}">edit</a>\n' +
                        '</liferay-ui:search-container-column-text>'}
                    </SyntaxHighlighter>

                    Codul complet:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<p>\n' +
                        '\t<b><liferay-ui:message key="helloworld.caption"/></b>\n' +
                        '</p>\n' +
                        '\n' +
                        '<portlet:renderURL var="createEditRender">\n' +
                        '\t<portlet:param name="mvcRenderCommandName" value="/create/edit"/>\n' +
                        '</portlet:renderURL>\n' +
                        '\n' +
                        '<a href="${createEditRender}">Creaza configurare</a>\n' +
                        '\n' +
                        '<liferay-ui:search-container delta="10" emptyResultsMessage="no-entries-were-found" total="${total}">\n' +
                        '\t<liferay-ui:search-container-results results="${entries}"/>\n' +
                        '\t\n' +
                        '\t<liferay-ui:search-container-row\n' +
                        '\t\t\tclassName="ro.ibedaria.liferay.vanilla.data.model.ConfigIB"\n' +
                        '\t\t\tkeyProperty="id"\n' +
                        '\t\t\tmodelVar="configIB"\n' +
                        '\t\t\tescapedModel="<%=true%>"\n' +
                        '\t>\n' +
                        '\t\t<liferay-ui:search-container-column-text property="name"/>\n' +
                        '\t\t<liferay-ui:search-container-column-text property="value"/>\n' +
                        '\n' +
                        '\t\t<liferay-ui:search-container-column-text name="action">\n' +
                        '\t\t\t<portlet:renderURL var="editRender">\n' +
                        '\t\t\t\t<portlet:param name="mvcRenderCommandName" value="/create/edit"/>\n' +
                        '\t\t\t\t<portlet:param name="entityId" value="${configIB.id}"/>\n' +
                        '\t\t\t</portlet:renderURL>\n' +
                        '\t\t\t<a href="${editRender}">edit</a>\n' +
                        '\t\t</liferay-ui:search-container-column-text>\n' +
                        '\n' +
                        '\t</liferay-ui:search-container-row>\n' +
                        '\t\n' +
                        '\t<liferay-ui:search-iterator/>\n' +
                        '</liferay-ui:search-container>'}
                    </SyntaxHighlighter>

                    Codul complet pentru clasa controller care gestioneaza adaugarea/editarea:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.helloworld.portlet;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.exception.PortalException;\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCRenderCommand;\n' +
                        'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.model.ConfigIB;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.ConfigIBLocalServiceUtil;\n' +
                        'import ro.ibedaria.liferay.vanilla.data.service.ConfigIBService;\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants.HelloWorldPortletKeys;\n' +
                        '\n' +
                        'import javax.portlet.PortletException;\n' +
                        'import javax.portlet.RenderRequest;\n' +
                        'import javax.portlet.RenderResponse;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                        '                "mvc.command.name=/create/edit"\n' +
                        '        }\n' +
                        ')\n' +
                        'public class CreateEditRenderMVCCommand implements MVCRenderCommand {\n' +
                        '\n' +
                        '    private ConfigIBService configIBService;\n' +
                        '\n' +
                        '    @Reference(unbind = "-")\n' +
                        '    public void setConfigIBService(ConfigIBService configIBService) {\n' +
                        '        this.configIBService = configIBService;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                        '\n' +
                        '        long id = ParamUtil.getLong(renderRequest, "entityId", -1L);\n' +
                        '        if (id!=-1){\n' +
                        '            //ConfigIBLocalServiceUtil.getConfigIB(id);\n' +
                        '            try {\n' +
                        '                ConfigIB configIB = configIBService.getConfigIBById(id);\n' +
                        '                renderRequest.setAttribute("configIB", configIB);\n' +
                        '            } catch (PortalException e) {\n' +
                        '                e.printStackTrace();\n' +
                        '            }\n' +
                        '        }\n' +
                        '\n' +
                        '        return "/edit.jsp";\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Trebuie definit in service, metoda: <i>getConfigIBById()</i>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'\tpublic ConfigIB getConfigIBById(long id) throws PortalException {\n' +
                        '\t\treturn configIBLocalService.getConfigIB(id);\n' +
                        '\t}'}
                    </SyntaxHighlighter>

                    Apoi se ruleaza Service Builder-ul si se face deploy:
                    <ul>
                        <li>
                            <b>modules\data\data-service{">"} ..\..\..\gradlew buildService</b>
                        </li>
                        <li>
                            <b>modules\data\data-service{">"} ..\..\..\gradlew deploy</b>
                        </li>
                    </ul>

                    Apoi, in clasa <i>edit.jsp</i> se adauga urmatoarea linie:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<aui:model-context bean="${configIB}" model ="<%=ConfigIB.class%>"/>'}
                    </SyntaxHighlighter>

                    Codul complet:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<%@ page import="ro.ibedaria.liferay.vanilla.data.model.ConfigIB" %>\n' +
                        '<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<b>Creare/editare configurare</b>\n' +
                        '\n' +
                        '<portlet:actionURL name = "/save/config" var="saveConfigURL"/>\n' +
                        '\n' +
                        '<aui:form name="forma" action="${saveConfigURL}">\n' +
                        '\n' +
                        '    <aui:model-context bean="${configIB}" model ="<%=ConfigIB.class%>"/>\n' +
                        '\n' +
                        '    <aui:input name="code"></aui:input>\n' +
                        '    <aui:input name="name"></aui:input>\n' +
                        '    <aui:input name="value"></aui:input>\n' +
                        '    <aui:input name="typeId"></aui:input>\n' +
                        '\n' +
                        '    <aui:button-row>\n' +
                        '        <aui:button cssClass="btn btn-primary" type="submit"></aui:button>\n' +
                        '    </aui:button-row>\n' +
                        '</aui:form>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>*/}
                        {/*            MVC Render Command*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SearchContainerPortletContent;