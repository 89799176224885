import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class ScopesLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-scopes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Scopuri</b>
                    <br/>
                    <br/>

                    La crearea unui token se poate specifica <b>scopul</b>, de exemplu <i>admin</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'$token = $user->createToken(\'token\',[\'admin\'])->planTextToken;'}
                    </SyntaxHighlighter>

                    Apoi, creem un middleware (ScopeAdminMiddleware), prin intermediul caruia verificam daca un token are scopul dorit:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:middleware ScopeAdminMiddleware'}
                    </SyntaxHighlighter>

                    Editam clasa generata:
                    <SyntaxHighlighter  showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Http\\Middleware;\n' +
                            '\n' +
                            'use Closure;\n' +
                            'use Illuminate\\Http\\Request;\n' +
                            'use Services\\UserService;\n' +
                            '\n' +
                            'class ScopeAdminMiddleware\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Handle an incoming request.\n' +
                            '     *\n' +
                            '     * @param \\Illuminate\\Http\\Request $request\n' +
                            '     * @param \\Closure $next\n' +
                            '     * @return mixed\n' +
                            '     */\n' +
                            '    public function handle(Request $request, Closure $next)\n' +
                            '    {\n' +
                            '        if ($request->user()->tokenCan(\'admin\')){\n' +
                            '            abort(401, \'unauthorized\');\n' +
                            '        }\n' +
                            '\n' +
                            '        return $next($request);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi modificam in Kernel.php, adaugand in lista <b> protected $routeMiddleware = [..]</b> si:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"'scope.admin'=> ScopeAdminMiddleware::class"}
                    </SyntaxHighlighter>

                    Modificam:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"Route::prefix('admin')->group(function(){\n" +
                            "  Route::post('register', [AuthController::class, 'register'])\n" +
                            "  Route::post('login', [AuthController::class, 'login'])\n" +
                            "  Route::middleware(['auth:sanctum','scope.admin'])->group(){\n" +
                            "       Route::get('user', [AuthController::class, 'user'])\n" +
                            "       Route::post('logout', [AuthController::class, 'logout'])\n" +
                            "  });\n" +
                            "})"}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ScopesLaravelContent;