import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavascriptModuleESMLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-javascript-module-esm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    In bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'Bundle-Name: agnes-bell-scheduler-web\n' +
                            'Bundle-SymbolicName: ro.agnes.bell.scheduler\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Export-Package: ro.agnes.bell.scheduler.constants\n' +
                            '\n' +
                            'Web-ContextPath: /ts'}
                    </SyntaxHighlighter>
                    Ce este de retinut? <b>Web-ContextPath: /ts</b>
                    <SyntaxHighlighter>
                        {'Web-ContextPath: /ts'}
                    </SyntaxHighlighter>

                    Fie intr-un fisier jsp (calea catre resursa: <b>/o/[Web-ContextPath]/cale-fisier.js</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<script type="module" src="/o/ts/js/test.js"></script>'}
                    </SyntaxHighlighter>

                    Fie test.js (resources/META-INF/resources/js/test.js):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { showMessage } from \'./greet.js\';\n' +
                            '\n' +
                            'showMessage("KJ e aici!");'}
                    </SyntaxHighlighter>

                    Fie greet.js (resources/META-INF/resources/js/greet.js):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export function showMessage(message) {\n' +
                            '    alert(message)\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavascriptModuleESMLiferayContent;