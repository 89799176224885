import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IdentifiersJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-identifiers", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un <b>identificator</b> trebuie sa respecte urmatorele reguli:
                    <ul>
                        <li>
                            sa incepa cu: o litera, _, $
                        </li>
                        <li>
                            poate include numere, dar nu se poate incepe cu un numar
                        </li>
                        <li>
                            incepand cu Java 9, nu este permis identificatorul <b>_</b> (adica un identificator format doar din simbolul <b>_</b>)
                        </li>
                        <li>
                            sa nu fie un cuvant cheie rezervat
                        </li>
                    </ul>
                    Cuvinte cheie rezervate:
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={5}>Cuvinte cheie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>abstact</td>
                                <td>assert</td>
                                <td>boolean</td>
                                <td>break</td>
                                <td>byte</td>
                            </tr>

                            <tr>
                                <td>case</td>
                                <td>catch</td>
                                <td>char</td>
                                <td>class</td>
                                <td><i>const</i></td>
                            </tr>

                            <tr>
                                <td>continue</td>
                                <td>default</td>
                                <td>do</td>
                                <td>double</td>
                                <td>else</td>
                            </tr>

                            <tr>
                                <td>enum</td>
                                <td>extends</td>
                                <td>false</td>
                                <td>final</td>
                                <td>finally</td>
                            </tr>

                            <tr>
                                <td>float</td>
                                <td>for</td>
                                <td><i>goto</i></td>
                                <td>if</td>
                                <td>implements</td>
                            </tr>

                            <tr>
                                <td>native</td>
                                <td>new</td>
                                <td>null</td>
                                <td>package</td>
                                <td>private</td>
                            </tr>

                            <tr>
                                <td>protected</td>
                                <td>public</td>
                                <td>return</td>
                                <td>short</td>
                                <td>static</td>
                            </tr>

                            <tr>
                                <td>strictfp</td>
                                <td>super</td>
                                <td>switch</td>
                                <td>synchronized</td>
                                <td>this</td>
                            </tr>

                            <tr>
                                <td>throw</td>
                                <td>throws</td>
                                <td>transient</td>
                                <td>true</td>
                                <td>try</td>
                            </tr>


                            <tr>
                                <td>void</td>
                                <td>volatile</td>
                                <td>while</td>
                                <td>_</td>
                                <td></td>
                            </tr>


                        </tbody>
                    </table>

                    Observatii:
                    <ul>
                        <li>goto, const - nu sunt folosite de Java</li>
                        <li>true, false, null - nu sunt cuvinte cheie rezervate, ci valori literale </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IdentifiersJavaContent;