import Perceptron from "../../nn/Perceptron";
import Util from "../../util/Util";
import * as d3 from "d3";

export default class PerceptronDraw{

    #perceptron;

    #width;
    #height;
    #r;
    #colors = [];
    #dataSet = [];
    #svg;

    #epoch = 0;

    #maxEpoch = 50;

    constructor(n=2, width=900, height=900, r=10, padding=100){
        this.#width =  width;
        this.#height = height;
        this.#r = r;
        this.#colors = ['#c2e699','#FE2712'];
        this.#perceptron = new Perceptron(n);

        this.initCanvas();
    }

    initCanvas(){
        //Create SVG element
        this.#svg = d3.select("div#container")
            .append("svg")
            .attr("width", this.#width)
            .attr("height", this.#height)
            .style("border", "1px solid black");
    }

    setDataSet(dataSet=[]){
        this.#dataSet = dataSet;
    }

    getPointX(x){
        return Util.map(x,-1,1, 0, this.#width);
    }

    getPointY(y){
        return Util.map(y,-1,1, this.#height, 0);
    }

    draw(){

        // epoch
        this.#epoch = 0;
        let error = 0;
        do {
            error = 0;
            for (let i = 0; i < this.#dataSet.length; i++) {
                error += this.#perceptron.train(this.#dataSet[i].input, this.#dataSet[i].answer);
            }
            console.log("Epoch:"+this.#epoch+" / Error:"+error);
            this.#epoch++;
        }while(this.#epoch<this.#maxEpoch && error!==0);

        console.log("Epoch:"+this.#epoch);

        d3.select("div#container > svg").remove();

        //Create SVG element
        this.#svg = d3.select("div#container")
            .append("svg")
            .attr("width", this.#width)
            .attr("height", this.#height)
            .style("border", "1px solid black");

        // draw a rect
        this.#svg.append("rect")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("fill", "#f1f2f1");

        // draw circles
        this.#svg.selectAll('circle')
            .data(this.#dataSet)
            .join(
                (enter) => enter
                    .append('circle')
                    .attr('cx', d => this.getPointX(d.input[0]))
                    .attr('cy', d => this.getPointY(d.input[1]))
                    .attr('r', d => this.#r)
                    .attr('fill', d => (d.answer===-1)?this.#colors[0]:this.#colors[1])
            );


        // draw line
        this.drawLine(this.#svg, -1,-1, 1, 1, "black");

        // Draw the line based on the current weights
        // Formula is weights[0]*x + weights[1]*y + weights[2] = 0
        let weights = this.#perceptron.weights;

        let x1 = -1;
        let y1 = ( -weights[2] - weights[0] * x1) / weights[1];
        let x2 = 1;
        let y2 = ( -weights[2] - weights[0] * x2) / weights[1];

        this.drawLine(this.#svg, x1,y1,x2, y2, "red");
    }

    drawLine(svg, x1, y1, x2, y2, color){
        let px1 = this.getPointX(x1);
        let py1 = this.getPointY(y1);
        let px2 = this.getPointX(x2);
        let py2 = this.getPointY(y2);

        svg.append("line")
            .attr("x1", px1)
            .attr("x2", px2)
            .attr("y1", py1)
            .attr("y2", py2)
            .attr("stroke", color)
    }

    get epoch(){
        return this.#epoch;
    }

    get maxEpoch(){
        return this.#maxEpoch;
    }

    get learningRate(){
        return this.#perceptron.learningRate;
    }

    f(x){
        return x;
    }
}