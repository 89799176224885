import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class EmailSettingsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-email-settings", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Pasi de urmat:
                    <ul>
                        <li>
                            Global Menu {">"} Control Panel {">"} Configuration  {"->"} Instance Settings {"->"} sectiunea <b>PLATFORM</b> {"->"} <b>Email</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Configurarea notificare la crearea unui cont / Account Created Notification</b>
                    <br/>
                    <br/>
                    Permite:
                    <ul>
                        <li>
                            activarea/dezactivarea notificărilor prin e-mail pentru crearea contului
                        </li>
                        <li>
                            personaliza șablonul de notificare (definesc e-mailurile trimise utilizatorilor după crearea unui cont):
                            <ul>
                                <li>
                                    un șablon nu include un link de configurare a parolei
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/c_21.png'}/>
                                    </div>
                                </li>
                                <li>
                                    un șablon include un link de configurare a parolei
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/c_22.png'}/>
                                    </div>
                                </li>
                            </ul>
                            se pot folosi urmatoarele definitii:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/c_23.png'}/>
                            </div>
                        </li>
                    </ul>

                    Configurarea notificare la crearea unui cont:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_20.png'}/>
                    </div>



                    <hr/>
                    <b>2. Email Sender</b>
                    <br/>
                    <br/>
                    Expeditorul e-mailului specifică numele administrativ al instanței virtuale și adresa de e-mail pentru notificările prin e-mail.
                    <br/>
                    Se poate specifica din:
                    <ul>
                        <li>
                            portal-setup-wizard.properties
                        </li>
                        <li>
                            UI
                        </li>
                    </ul>
                    In <b>portal-setup-wizard.properties</b> urmatoarele proprietati:
                    <SyntaxHighlighter>
                        {'admin.email.from.address=admin@liferay.com\n' +
                            'admin.email.from.name=admin admin'}
                    </SyntaxHighlighter>
                    Pot fi suprascrise din <b>Control Panel</b> ~ <b>Instance Settings</b> ~ <b>Email</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/e_1.png'}/>
                    </div>

                    Tab <b>Email Sender</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/e_3.png'}/>
                    </div>

                    <hr/>
                    <b>3. Notificare de verificare email / Email Verification Notification</b>
                    <br/>
                    <br/>
                    Acest șablon definește e-mailurile trimise utilizatorilor atunci când li se cere să verifice adresa lor de e-mail.

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_24.png'}/>
                    </div>


                    <hr/>
                    <b>4. Notificare schimbare parola / Password Changed Notification</b>
                    <br/>
                    <br/>
                    Acest șablon definește e-mailurile care informează utilizatorii că parola lor a fost schimbată.

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_25.png'}/>
                    </div>

                    <hr/>
                    <b>5. Notificare solicitare resetare parola / Password Reset Notification</b>
                    <br/>
                    <br/>
                    Acest șablon este pentru solicitările de resetare a parolei.
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_26.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/w/dxp/system-administration/configuring-liferay/virtual-instances/email-settings"}>
                                    Email Settings
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EmailSettingsLiferayContent;