import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class CreateReactAppContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-create-react-app", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Generare aplicatie React</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1.1. npm vs npx</b>
                    <br/>
                    <br/>

                    <b>npm</b> (managerul de pachete nod):
                    <ul>
                        <li>
                            este managerul de dependențe/pachete instalat o data cu <b>Node.js</b>.
                        </li>
                        <li>
                            oferă o modalitate pentru dezvoltatori de a instala pachete atât la nivel global, cât și local.
                        </li>
                        <li>
                            este un depozit online pentru publicarea proiectelor open-source Node.js
                        </li>
                        <li>
                            este un instrument CLI care vă ajută să instalați pachete și să gestionați versiunile și dependențele acestora
                        </li>
                        <li>
                            dacă se doreste rularea unui pachet folosind npm, trebuie să specificați acel pachet în fisierul <b>package.json</b>
                        </li>
                    </ul>

                    <b>npx</b>:
                    <ul>
                        <li>un instrument CLI al cărui scop este de a facilita instalarea și gestionarea dependențelor găzduite în registrul npm</li>
                        <li>vine cu npm {">"} 5.2.0</li>
                    </ul>

                    <hr/>
                    <b>1.2. Prerechizite</b>
                    <br/>
                    <br/>

                    Instalare:
                    <ul>
                        <li>nodejs:

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'sudo apt install nodejs'}
                            </SyntaxHighlighter>

                            verificare:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'node -v'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'v12.22.9'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            npm:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'sudo apt install npm'}
                            </SyntaxHighlighter>

                            verificare:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'npm -v'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'v12.22.9'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            npx:
                            <br/>
                            npx vine cu npm.
                            <br/>
                            verificare:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'npx -v'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter>
                                {'8.5.1'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>1.3. create-react-app</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npx create-react-app frontend'}
                    </SyntaxHighlighter>
                    daca se obtine urmatoarea eroare:
                    <SyntaxHighlighter>
                        {'Need to install the following packages:\n' +
                            '  create-react-app\n' +
                            'Ok to proceed? (y)\n' +
                            'npm WARN EBADENGINE Unsupported engine {\n' +
                            'npm WARN EBADENGINE   package: \'create-react-app@5.0.1\',\n' +
                            'npm WARN EBADENGINE   required: { node: \'>=14\' },\n' +
                            'npm WARN EBADENGINE   current: { node: \'v12.22.9\', npm: \'8.5.1\' }\n' +
                            'npm WARN EBADENGINE }\n' +
                            'npm WARN deprecated tar@2.2.2: This version of tar is no longer supported, and will not receive security updates. Please upgrade asap.\n' +
                            'You are running Node 12.22.9.\n' +
                            'Create React App requires Node 14 or higher.\n' +
                            'Please update your version of Node.'}
                    </SyntaxHighlighter>
                    pentru a remedia problema:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo npm cache clean -f\n' +
                            'sudo npm install -g n\n' +
                            'sudo n stable'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'node -v'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'v16.17.1'}
                    </SyntaxHighlighter>
                    si acum, puteam sa rulam din noua comanda:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npx create-react-app frontend'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd frontend'}
                    </SyntaxHighlighter>

                    pornire server local:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm start'}
                    </SyntaxHighlighter>

                    verificare:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'http://localhost:3000'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'http://192.168.1.8:3000/'}
                    </SyntaxHighlighter>

                    build pentru productie:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm run build'}
                    </SyntaxHighlighter>

                    rulare teste:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm run test'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateReactAppContent;