import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class RmLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-rm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a sterge un fisier/director:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'rm <nume-fisier>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'rm -r <nume-director>'}
                    </SyntaxHighlighter>

                    <i>Exemple</i>:
                    <br/>
                    Stergere toate fisierele cu extensia .txt:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'rm *.txt'}
                    </SyntaxHighlighter>
                    Pentru a sterge o structura de directoare, cu tot cu fisire (uneori se mai foloseste comanda: <b>rm -rf</b>):
                    <SyntaxHighlighter>
                        {'rm -r a/b/c/d'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RmLinuxContent;