import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class MultiProjectsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-multi-projects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Proiecte multiple</b>
                    <br/>
                    <br/>

                    Pot exista mai multe proiecte/aplicatii intr-un singur director. O noua aplicatie se poate genera cu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng generate application nume-proiect'}
                    </SyntaxHighlighter>

                    Avand mai multe proiete/aplicatii, pentru a porni unul dintre ele:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve --proiect=nume-proiect'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca exista un proiect, noul proiect se va genera in <b>projects/nume-nou-proiect</b>; o structura destul de urata.
                    <br/>
                    Dar pentru a creea o structura mai ok, se poate face in felul urmator:
                    <ul>
                        <li>
                            se creaza un proiect, fara aplicatie:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng new project-root --create-application=false'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se genereaza prima aplicatie/proiect:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng generate application backend'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se genereaza a doua aplicatie/proiect:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng generate application frontend'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultiProjectsAngularContent;