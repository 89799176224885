import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class VariablesGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-variables", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Variabile</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'var name string = "kj"\n\nfmt.Println(name)'}
                    </SyntaxHighlighter>

                    sau, mai simplu (<b>:=</b>):
                    <SyntaxHighlighter>
                        {'name := "kj"\n\nfmt.Println(name)'}
                    </SyntaxHighlighter>
                    dupa initializare, se foloseste <b>=</b>
                    <SyntaxHighlighter>
                        {'name="X"'}
                    </SyntaxHighlighter>

                    Tipuri:
                    <ul>
                        <li>bool</li>
                        <li>string</li>
                        <li>int</li>
                        <li>float64</li>
                        <li>byte</li>
                    </ul>

                    Se poate scrie si asa:
                    <SyntaxHighlighter>
                        {'var x int\n' +
                            'x = 52'}
                    </SyntaxHighlighter>

                    <hr/>
                    Observatie:
                    <ul>
                        <li>Orice variabila declarata trebuie folosita, altfel se va arunca eroare la compilare!</li>
                        <li>Daca e nevoie de o variabila si nu o folosim, o putem inlocui cu "_"!</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VariablesGoContent;