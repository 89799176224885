import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class StorageClassesK8sContent extends BaseContentPage {

    constructor(props) {
        super(props, "kubernetes-storage-classes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Storage classes</b>
                    <br/>
                    <br/>

                    Exista:
                    <ul>
                        <li>
                            Static Provisioning (spatiul este creat manual)
                        </li>
                        <li>
                            Dynamic Provisioning
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Static Provisioning</b>
                    <br/>
                    <br/>

                    Inainte de a face un PV si al folosi (<i>pdName: pd-disk</i>):
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolume\n' +
                            'metadata:\n' +
                            '  name: pv-vol1\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  capacity:' +
                            '    storage: 500Mi' +
                            '  gcePersistentDisc:\n' +
                            '    pdName: pd-disk\n' +
                            '    fsType: ext4'}
                    </SyntaxHighlighter>
                    trebuie creat <b>manual</b> spatiul (de exemplu pentru google cloud, <i>pd-disk</i>):
                    <SyntaxHighlighter>
                        {'gcloud beta compute disks create --size 1GB --region us-east1 pd-disk'}
                    </SyntaxHighlighter>

                    Acest lucru se numeste: <b>Static Provisioning</b>.
                    <br/>
                    Deci pentru  <b>Static Provisioning</b> este nevoie de:
                    <ul>
                        <li>
                            definitie PV
                        </li>
                        <li>
                            definitie PVC
                        </li>
                        <li>
                            definitie Pod
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/k8s/k8s-1.jpg'}/>
                    </div>

                    <hr/>
                    <b>2. Dynamic Provisioning</b>
                    <br/>
                    <br/>

                    Dynamic Provisioning permite crearea la cerere a volumelor de stocare.
                    Fara <b>dynamic Provisioning</b> trebuie:
                    <ul>
                        <li>
                            creat manuale volume de stocare (de catre administratori)
                        </li>
                        <li>
                            craat un PV
                        </li>
                    </ul>


                    <br/>
                    Pentru a se putea crea in mod dinamic spatiu se foloseste un obiect de tip <b>StorageClass</b>:
                    <SyntaxHighlighter>
                        {'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: google-storage\n' +
                            'provisioner: kubernetes.io/gce-pd'}
                    </SyntaxHighlighter>

                    Folosind un <b>StorageClass</b> nu mai este nevoie de un <b>PV</b>!

                    <br/>
                    In pvc, vom folosi:
                    <SyntaxHighlighter>
                        {'storageClassName: google-storage'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: fast\n' +
                            'provisioner: kubernetes.io/gce-pd\n' +
                            'parameters:\n' +
                            '  type: pd-ssd'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/k8s/k8s-2.jpg'}/>
                    </div>

                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  name: claim1\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  storageClassName: fast\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 30Gi'}
                    </SyntaxHighlighter>

                    Exista multi provideri si trebuie folositi cu parametri specifici.
                    <ul>
                        <li>
                            <b>type</b>: pd-standard [ pd-standard | pd-ssd ]
                        </li>
                        <li>
                            <b>replication-type</b>: none [none | regional-pd ]
                        </li>
                    </ul>

                    Deci, se pot creea diverse clase de stocare:
                    <SyntaxHighlighter>
                        {'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: silver\n' +
                            'provisioner: kubernetes.io/gce-pd\n' +
                            'parameters:\n' +
                            '  type: pd-standard\n' +
                            '  replication-type: none'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: gold\n' +
                            'provisioner: kubernetes.io/gce-pd\n' +
                            'parameters:\n' +
                            '  type: pd-ssd\n' +
                            '  replication-type: none'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: platinum\n' +
                            'provisioner: kubernetes.io/gce-pd\n' +
                            'parameters:\n' +
                            '  type: pd-ssd\n' +
                            '  replication-type: regional-pd'}
                    </SyntaxHighlighter>

                    Cu Stateful Set, cand se specifica un PVC, toate podurile create prin intermediul acelui Stateful Set vor folosi acel PVC.
                    <br/>
                    Daca vrem volume separate pentru fiecare pod (adica fiecare pod cu PVC-ul lui), se foloseste:
                    <ul>
                        <li><b>VolumeClaimTemplate</b></li>
                    </ul>
                    Se muta ce e intr-un PVC, in defintia Statefull Set, in sectiunea din <i>spec</i>: <b>volumeClaimTemplates</b>
                    <SyntaxHighlighter>
                        {'spec:\n' +
                            '  volumeClaimTemplates:\n' +
                            '  - metadata:\n' +
                            '      name: data-volume\n' +
                            '    spec:\n' +
                            '      accessMode: \n' +
                            '         - ReadWriteOnce\n' +
                            '      storageClassName: google-storage\n' +
                            '      resources:\n' +
                            '        requests:' +
                            '          storage: 500Mi'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StorageClassesK8sContent;