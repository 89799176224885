import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AopSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-aop", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>AOP</b> inseamana <b>programare orientata pe aspect</b>.
                    <br/>
                    <br/>
                    Un <b>aspect</b> este o bucata de cod (de logica) pe care framework-ul o executa cand se apleaza o anumita metoda (<b>pointcut</b>).
                    Cand se proiecteaza un aspect, se definesc urmatoarele:
                    <ul>
                        <li><b>aspect-ul</b>: <i>ce</i> cod sa fie executat cand se apleaza o anumita metoda (<b>pointcut</b>);
                            <br/>
                            # definire cod aspect (logica)</li>
                        <li><b>advice-ul</b>: <i>cand</i> ar trebui executat codul aspectului;
                            <br/>
                            de exemplu: inainte de apelarea metodei, dupa apelarea metodei
                            <br/>
                            # definire cand se executa aspectul (pentru un <b>pointcut</b>)
                        </li>
                        <li><b>joinpoint-uri</b>: <i>care</i> metode trebuie interceptate pentru a fi executat aspectul pentru acestea;
                            <br/>
                            de exemplu: toate metodele dintr-un anumit pachet sau toate metodele cu o anumita adnotare;
                            <br/>
                            # selectie metode pentru aspect-are
                        </li>
                    </ul>

                    Pentru ca Spring sa intercepteze metoda (<b>pointcut</b>), obiectul care defineste metoda, trebuie sa fie <b>bean</b> in contextul Spring.
                    Un bean care are o metoda interceptata de un <b>aspect</b> se numeste <b>obiect tinta (target object)</b>.
                    <br/>
                    Pentru obiectele target, atunci cand se cere un bean din context, nu se returneaza referinta catre obiect, ci un <b>obiect proxy</b>.
                    Acesta abordare se numeste <b>weaving</b>.

                    <hr/>
                    <b>Implementare aspecte cu Spring</b>:
                    <br/>
                    <br/>

                    Sa presupunem ca avem urmatoarea aplicatie (model, service, etc) si vrem sa marcam ca s-a intrat si iesit din metoda <i>afiseaza()</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop.model;\n' +
                        '\n' +
                        'public class Poem {\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop.services;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Service;\n' +
                        'import ro.ibedaria.spring.aop.model.Poem;\n' +
                        '\n' +
                        '@Service\n' +
                        'public class PoemService {\n' +
                        '\n' +
                        '    public void afiseaza(Poem carte){\n' +
                        '        System.out.println("Se afiseaza poem!");\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Atunci ar trebui sa urmam urmatori pasi:
                    <br/>
                    <br/>
                    <b>1. Adaugare dependinta spring-aspects</b>
                    <br/>
                    <br/>
                    In <b>pom.xml</b> trebuie sa avem dependintele (<b>spring-context</b> si <b>spring-aspects</b>):

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<dependency>\n' +
                        '    <groupId>org.springframework</groupId>\n' +
                        '    <artifactId>spring-context</artifactId>\n' +
                        '    <version>5.3.10</version>\n' +
                        '</dependency>\n' +
                        '<dependency>\n' +
                        '    <groupId>org.springframework</groupId>\n' +
                        '    <artifactId>spring-aspects</artifactId>\n' +
                        '    <version>5.3.10</version>\n' +
                        '</dependency>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Definire aspect</b>
                    <br/>
                    <br/>

                    <b>2.1.</b> Se creaza o <b>clasa aspect</b>. Aceasta clasa este adnota cu <b>@Aspect</b>.

                    <br/>
                    <br/>

                    <b>Observatie</b>:
                    <br/>
                    <ul>
                        <li>Adnotarea <b>@Aspect</b> nu este o adnotare stereotip, deci Spring <b>nu va creea un bean pentru aceasta clasa</b>.</li>
                        <li>Adnotarea <b>@Aspect</b> marcheaza faptul ca aceasta clasa contine aspecte</li>
                    </ul>

                    <b>2.2.</b> Se definesc aspectele, metode adnotate cu adnotari de tip <b>advice</b> (<b>@Around</b>, <b>@Before</b>, <b>@After</b>, <b>@AfterReturning</b>, <b>@AfterThrowing</b>).
                    <br/>

                    Aceste adnotari permit definirea de <b>jointpoint-uri</b>:
                    <ul>
                        <li>interceptare metode</li>
                        <li>interceptare metode adnotate</li>
                    </ul>

                    Exemple:
                    <ul>
                        <li><b>execution</b>(* ro.ibedaria.spring.aop.services.*.*(..))
                            <br/>
                            <ul>
                                <li>primul (*): inseamna ca metoda interceptata poate sa returneze orice tip</li>
                                <li><i>ro.ibedaria.spring.aop.services</i>: inseamna ca toate metodele trebuie sa fie in pachetul <i>ro.ibedaria.spring.aop.services</i></li>
                                <li>al doilea (*): inseamna ca metoda interceptata poate fi in orice clasa din pachetul <i>ro.ibedaria.spring.aop.services</i></li>
                                <li>al treilea (*): inseamna ca metoda interceptata poate avea orice nume; adica sunt interceptate toate metodele</li>
                                <li><b>(..)</b>: inseamna ca metoda poate oricati parametri</li>
                            </ul>
                        </li>
                        <li><b>execution</b>(* *..*.services.*.*(..))
                            fata de jointpoint-ul de mai sus:
                            <ul>
                                <li> <b>*..*</b>.services: inseamna ca metoda poate fi in orice pachet care se termina cu <i>services</i></li>
                            </ul>
                        </li>
                        <li>
                            <b>@annotation</b>(ToAfisare): inseamna ca metoda interceptata trebuie sa aiba adnotarea <i>@ToAfisare</i>:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.aop.annotations;\n' +
                                '\n' +
                                'import java.lang.annotation.ElementType;\n' +
                                'import java.lang.annotation.Retention;\n' +
                                'import java.lang.annotation.RetentionPolicy;\n' +
                                'import java.lang.annotation.Target;\n' +
                                '\n' +
                                '@Retention(RetentionPolicy.RUNTIME) // adnotarea trebuie sa specifice ca politica de retentie RUNTIME, altfel nu va putea fi folosita la runtime\n' +
                                '@Target(ElementType.METHOD) // adnotarea se poate aplica doar pe metode\n' +
                                'public @interface ToAfisare {\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                            Exemplu de folosire a adnotarii @ToAfisare:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.aop.services;\n' +
                                '\n' +
                                'import org.springframework.stereotype.Service;\n' +
                                'import ro.ibedaria.spring.aop.annotations.ToAfisare;\n' +
                                'import ro.ibedaria.spring.aop.model.Poem;\n' +
                                '\n' +
                                '@Service\n' +
                                'public class PoemService {\n' +
                                '\n' +
                                '    @ToAfisare\n' +
                                '    public void afiseaza(Poem carte){\n' +
                                '        System.out.println("Se afiseaza poem!");\n' +
                                '    }\n' +
                                '\n' +
                                '}\n'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <b>2.3.</b> Se implementeaza logica aspectului (implementare metoda).

                    <br/>
                    <br/>
                    Apelarea metodei interceptate se face cu ajutorul metodei <b>proceed()</b> al obiectului de tip <b>ProceedingJoinPoint</b> pus ca parametru al aspectului
                    (daca nu se apeleaza metoda <b>proceed()</b> nu se va mai apela metoda interceptata)

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public void log(ProceedingJoinPoint joinPoint) throws Throwable {\n' +
                        '    System.out.println("==start==");\n' +
                        '    joinPoint.proceed();\n' +
                        '    System.out.println("==end==");\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Prin intermediul obiectului de tip <b>ProceedingJoinPoint</b> se poate obtine:
                    <ul>
                        <li>numele metodei interceptate:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'joinPoint.getSignature.getName()'}
                            </SyntaxHighlighter>
                        </li>
                        <li>argumentele metodei interceptate:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'joinPoint.getArgs()'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Clasa aspect care intercepteaza toate metodele din pachetul <i>ro.ibedaria.spring.aop.services</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop.aspects;\n' +
                        '\n' +
                        'import org.aspectj.lang.annotation.Aspect;\n' +
                        'import org.aspectj.lang.ProceedingJoinPoint;\n' +
                        'import org.aspectj.lang.annotation.Around;\n' +
                        '\n' +
                        '@Aspect\n' +
                        'public class LoggingAspect {\n' +
                        '\n' +
                        '    //@Around("execution(* ro.ibedaria.spring.aop.services.*.*(..))")\n' +
                        '    @Around("execution(* *..*.services.*.*(..))")\n' +
                        '    public void log(ProceedingJoinPoint joinPoint) throws Throwable {\n' +
                        '        System.out.println("==start==");\n' +
                        '        joinPoint.proceed();\n' +
                        '        System.out.println("==end==");\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Clasa aspect care intercepteaza o metoda adnotata cu <i>ToAfisare</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop.aspects;\n' +
                        '\n' +
                        'import org.aspectj.lang.ProceedingJoinPoint;\n' +
                        'import org.aspectj.lang.annotation.Around;\n' +
                        'import org.aspectj.lang.annotation.Aspect;\n' +
                        '\n' +
                        '@Aspect\n' +
                        'public class LoggingAspect {\n' +
                        '    \n' +
                        '    @Around("@annotation(ro.ibedaria.spring.aop.annotations.ToAfisare)")\n' +
                        '    public void log(ProceedingJoinPoint joinPoint) throws Throwable {\n' +
                        '        System.out.println("==(ToAfisare)start==");\n' +
                        '\n' +
                        '        joinPoint.proceed();\n' +
                        '\n' +
                        '        System.out.println("==(ToAfisare)end==");\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    O metoda aspect poate modifica:
                    <ul>
                        <li>valoarea returnata de metoda interceptata (de exemplu, daca returneaza valoarea Booleana <i>true</i>, poate poate schimba in <i>false</i>)
                        </li>
                        <li>parametrii cu care este apelata metoda interceptata
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public void log(ProceedingJoinPoint joinPoint) throws Throwable {\n' +
                                '        System.out.println("==start==");\n' +
                                '        \n' +
                                '        Poem poem = new Poem();\n' +
                                '        Object[] args = new Object[]{poem};\n' +
                                '        \n' +
                                '        joinPoint.proceed(args); // se trimite alt poem!\n' +
                                '        \n' +
                                '        System.out.println("==end==");\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    <hr/>
                    <b>3. Activare aspecte</b>:

                    <br/>
                    <br/>

                    Adnotarea <b>@Aspect</b> nu este o adnotare stereotip, deci Spring <b>nu va creea un bean pentru aceasta clasa</b>.
                    Deci, pentru a adauga in contextul Spring, clasa adnotata cu @Aspect, se poate face in clasa de configurare, folosind <b>@Bean</b>.
                    <br/>
                    A uita adaugarea clasei aspect in contextul Spring este o greseala frecventa care duce la multa frustarea si consum de timp:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.Bean;\n' +
                        'import org.springframework.context.annotation.ComponentScan;\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        'import org.springframework.context.annotation.EnableAspectJAutoProxy;\n' +
                        'import ro.ibedaria.spring.aop.aspects.LoggingAspect;\n' +
                        '\n' +
                        '@Configuration\n' +
                        '@EnableAspectJAutoProxy\n' +
                        '@ComponentScan(basePackages = "ro.ibedaria.spring.aop.services")\n' +
                        'public class AppConfig {\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public LoggingAspect aspect() {\n' +
                        '        return new LoggingAspect();\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Testare</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.AnnotationConfigApplicationContext;\n' +
                        'import ro.ibedaria.spring.aop.config.AppConfig;\n' +
                        'import ro.ibedaria.spring.aop.model.Poem;\n' +
                        'import ro.ibedaria.spring.aop.services.PoemService;\n' +
                        '\n' +
                        'public class SpringAopApp {\n' +
                        '\n' +
                        '    public static void main(String args[]){\n' +
                        '\n' +
                        '        AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(AppConfig.class);\n' +
                        '\n' +
                        '        PoemService bibliotecaService = context.getBean(PoemService.class);\n' +
                        '        \n' +
                        '        /*\n' +
                        '        ==start==\n' +
                        '        Se afiseaza poem!\n' +
                        '        ==end==\n' +
                        '         */\n' +
                        '        bibliotecaService.afiseaza(new Poem());\n' +
                        '\n' +
                        '        System.out.println(bibliotecaService.getClass());\n' +
                        '        // cu mecanism de aspectare activat: class ro.ibedaria.spring.aop.services.PoemService$$EnhancerBySpringCGLIB$$430507b7\n' +
                        '        // fara mecanism de aspectare activare class ro.ibedaria.spring.aop.services.BibliotecaService\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Alte adnotari de tip advice</b>:
                    <br/>
                    <br/>
                    Alte adnotari de tip <b>advice</b>, pe langa <b>@Around</b>:
                    <ul>
                        <li><b>@Before</b>: aspectul se apeleaza inainte de metode interceptata</li>
                        <li><b>@After</b>: aspectul se apeleza dupa ce metoda interceptata s-a executat</li>
                        <li><b>@AfterReturning</b>: aspectul se apeleza dupa ce metoda interceptata s-a executat, doar daca nu s-a terminat prin aruncarea unei exceptii, trimitand valoare returnata ca parametru al metodei aspect</li>
                        <li><b>@AfterThrowing</b>: aspectul se apeleza dupa ce metoda interceptata s-a executat, doar daca s-a terminat prin aruncarea unei exceptii, trimitand o instanta a exeptiei ca parametru al metodei aspect</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'    @AfterReturning(value = "@annotation(ro.ibedaria.spring.aop.annotations.ToAfisare)",\n' +
                        '                    returning = "value" )\n' +
                        '    public void log(Object value) throws Throwable {\n' +
                        '        System.out.println("valoare returnata "+ value);\n' +
                        '    }'}
                    </SyntaxHighlighter>
                    Numele parametrului metodei <i>log()</i> (adica, <i>value</i>) trebuie sa coincida cu valoarea data prin intermediul atributului <i>returning</i> al adnotarii <b>@AfterReturning</b>.

                    <hr/>
                    <b>6. Executia apectelor in lant</b>:
                    <br/>
                    <br/>

                    Pot exista mai multe aspecte care intercepteaza aceeasi metoda. Ordinea in care Spring executa aspectele se poate stabili cu ajutorul adnotarii <b>@Order</b>.
                    Aceasta adnotare primeste un numar, care reprezinta ordinea de executie in lant (un numar mai mic, inseamna ca se executa mai inainte).
                    <br/>
                    In lipsa acestei acestei adnotarii sau la valori egale, ordinea exceutiei nu este definita (nu se poate stii ordinea).

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.aop.aspects;\n' +
                        '\n' +
                        'import org.aspectj.lang.annotation.Aspect;\n' +
                        'import org.springframework.core.annotation.Order;\n' +
                        '\n' +
                        '@Aspect\n' +
                        '@Order(1)\n' +
                        'public class AfisareAspect {\n' +
                        '    \n' +
                        '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default AopSpringContent;