import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubeConfigK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubeconfig", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>KubeConfig</b>

                    <br/>
                    <br/>

                    Penru a afisa pod-urile folosind curl si certificatele via API Server:
                    <SyntaxHighlighter>
                        {
                            'curl https://my-kube-playground:6443/api/v1/pods --key admin.key --cert admin.crt --cacert ca.crt'
                        }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  "kind": "PodList",\n' +
                            '  "apiVersion: "v1",\n' +
                            '  "metadata":{\n' +
                            '     "selfLink":"/api/v1/pods",\n' +
                            '  },\n ' +
                            '  "items": [], \n' +
                            '' +
                            ''}
                    </SyntaxHighlighter>

                    Pentru a face acelasi lucru folosind Kubectl (afisare poduri folosind certificate):
                    <SyntaxHighlighter>
                        {'kubectl get pods\n' +
                            '--server my-kube-playground:6443\n' +
                            '--client-key admin.key\n' +
                            '--client-certificate admin.crt\n' +
                            '--certificate-authority ca.crt'}
                    </SyntaxHighlighter>

                    Toata zona:
                    <SyntaxHighlighter>
                        { '--server my-kube-playground:6443\n' +
                            '--client-key admin.key\n' +
                            '--client-certificate admin.crt\n' +
                            '--certificate-authority ca.crt'}
                    </SyntaxHighlighter>
                    se poate muta in fisierul KubeConfig <b>($HOME/.kube/config)</b>
                    <br/>
                    Formatul fisierului <b>config</b> are 3 sectiuni:
                    <ul>
                        <li>
                            clusters
                        </li>
                        <li>
                            contexts (defineste ce user foloseste ce cluster)
                        </li>
                        <li>
                            users
                        </li>
                    </ul>

                    Caile catre certificate ar fi bine sa fie absolute.

                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'kind: Config\n' +
                            'current-context: my-kube-admin@my-kube-playground\n'+
                            'clusters:\n' +
                            '- name: my-kube-playground\n' +
                            '  cluster:\n' +
                            '    server: https://my-kube-playground:6443\n' +
                            '    certificate-authority: ca.crt\n' +
                            'contexts:\n' +
                            '- name: my-kube-admin@my-kube-playground\n' +
                            '  context:\n' +
                            '    cluster: my-kube-playground\n' +
                            '    user: my-kube-admin\n' +
                            'users:\n' +
                            '- name: my-kube-admin\n' +
                            '  user:\n' +
                            '    client-certificate: admin.crt\n' +
                            '    client-key: admin.key\n'}
                    </SyntaxHighlighter>

                    In loc sa se foloseasca <b>certificate-authority</b> se poate folosi <b>certificate-authority-data</b> si scrie continutul certificatului in base64!

                    <br/>
                    <br/>

                    Definire context curent:
                    <SyntaxHighlighter>
                        {'current-context: my-kube-admin@my-kube-playground'}
                    </SyntaxHighlighter>

                    Vizualizare fisier config <b>kubectl config view</b> (se refera la fisierul default din /root/.kube/config):
                    <SyntaxHighlighter>
                        {'kubectl config view'}
                    </SyntaxHighlighter>

                    Pentru a vizualiza configurarile din alt fisier (de exemplu my-custom-config):
                    <SyntaxHighlighter>
                        {'kubectl config view --kubeconfig=my-custom-config'}
                    </SyntaxHighlighter>

                    Pentru a modifica contextul <b>kubectl config use-context</b>:
                    <SyntaxHighlighter>
                        {'kubectl config use-context prod-user@production'}
                    </SyntaxHighlighter>

                    Help:
                    <SyntaxHighlighter>
                        {'kubectl config -h'}
                    </SyntaxHighlighter>

                    Si apoi se poate scrie:
                    <SyntaxHighlighter>
                        {'kubectl get pods\n' +
                            '--kubeconfig config'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un context poate avea un <b>namespace</b> care poate specificat:

                    <SyntaxHighlighter>
                        {
                            'contexts:\n' +
                            '- name: my-kube-admin@my-kube-playground\n' +
                            '  context:\n' +
                            '    cluster: my-kube-playground\n' +
                            '    user: my-kube-admin\n' +
                            '    namespace: kj'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubeConfigK8sContent;