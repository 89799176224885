import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ShareLanguageKeyThemeContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-share-language-key-theme", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Vom creea un modul nou pe care il vom numi <b>i18n</b>.
                    <br/>
                    Apoi com creea urmatoare structura de directoare:
                    <br/>
                    <b>\src\main\resources\content</b>
                    <br/>
                    Aici vom creea doua fisiere:
                    <ul>
                        <li>Language.properties</li>
                        <li>Language_ro.properties</li>
                    </ul>

                    Continutul fisierului  <b>Language.properties</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="properties" style={androidstudio}>
                        {'theme.vanilla.author = KJ'}
                    </SyntaxHighlighter>

                    Continutul fisierului  <b>Language_ro.properties</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="properties" style={androidstudio}>
                        {'theme.vanilla.author = KJ'}
                    </SyntaxHighlighter>

                    Apoi vom creea urmatoarea structura de directoare:<br/>
                    <b>\src\main\java</b>
                    <br/>
                    Si aici vom creea pachetul <b>ro.letyournailsgrow.liferay.vanilla.common.i18n</b> si clasa <b>CommonResourceBundle</b>:

                    <SyntaxHighlighter   showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.common.i18n;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.language.UTF8Control;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import java.util.Enumeration;\n' +
                        'import java.util.ResourceBundle;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        property = { "language.id=ro_RO" },\n' +
                        '        service = ResourceBundle.class\n' +
                        ')\n' +
                        'public class CommonResourceBundle extends ResourceBundle {\n' +
                        '\n' +
                        '    private final ResourceBundle _resourceBundle = ResourceBundle.getBundle(\n' +
                        '            "content.Language_ro", UTF8Control.INSTANCE);\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected Object handleGetObject(String key) {\n' +
                        '        return _resourceBundle.getObject(key);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public Enumeration<String> getKeys() {\n' +
                        '        return _resourceBundle.getKeys();\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    In radacina modului, vom avea fisierul <b>bnd.bnd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'Bundle-Name: Common i18n\n' +
                        'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.common.i18n\n' +
                        'Bundle-Version: 1.0.0\n' +
                        '-sources: true\n'}
                    </SyntaxHighlighter>

                    Tot in radacina modului, vom creea fisierul <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '    compileOnly \'com.liferay.portal:release.dxp.api\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Testare</b><br/>
                    Sa presupunem ca avem tema <b>vanilla-theme</b>. In fisierul <b>portal_normal.ftl</b> putem defini o variabila <b>author_text</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'<#assign\n'+
                            'author_text = languageUtil.get(locale, "theme.vanilla.author")\n'+
                            '/>'}
                    </SyntaxHighlighter>

                    Apoi, de exemplu, in fisierul <b>portal_normal</b> putem testa:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'${author_text}'}
                    </SyntaxHighlighter>

                    ori
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:message key="theme.vanilla.author"/>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.liferayexample.com/example/language-key-theme-custom"}>
                                    Language key custom in Theme by Language Module Example
                                </a>
                            </div>
                        </li>
                        
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>
                                    Overriding Global Language Keys
                                </a>
                            </div>
                        </li>


                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ShareLanguageKeyThemeContent;