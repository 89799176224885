import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class NslookupLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-nslookup", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Este un instrument pentru interogarea DNS (seviciul de nume de domeniu) pentru a obtine maparea dintre numele de domeniu si adresa IP
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ifconfig google.com'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Server:         127.0.0.53\n' +
                            'Address:        127.0.0.53#53\n' +
                            '\n' +
                            'Non-authoritative answer:\n' +
                            'Name:   google.com\n' +
                            'Address: 142.251.39.78\n' +
                            'Name:   google.com\n' +
                            'Address: 2a00:1450:400d:80c::200e\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NslookupLinuxContent;