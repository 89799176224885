import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OperatorsJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-operators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Operatorii</b>
                    <br/>
                    <br/>

                    Operatori sunt simboluri folosite pentru a efectua actiuni, operatii asura unor argumente, numite operanzi.
                    <br/>
                    Operatori, in functie de numarul de operanzi:
                    <ul>
                        <li>unari: typeof</li>
                        <li>binari: +</li>
                        <li>ternali</li>
                    </ul>
                    Operatori, in functie de pozitia operanzilor:
                    <ul>
                        <li>prefix: inainte operatorilor</li>
                        <li>postfix: dupa operatori</li>
                        <li>infix: intre operatori</li>
                    </ul>

                    Operatori pot fi:
                    <ul>
                        <li><b>atribuire</b>: se atribuie o valoare unei variabile sau constante;
                            <br/>
                            Dacă mai mulți operatori de atribuire apar într-o secvență, se aplică ordinea de la dreapta la stânga.
                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let a = b = 7;\n' +
                                '\n' +
                                'console.log(a,b) // 7 7'}
                            </SyntaxHighlighter>
                            e identica cu:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let b = 7;\n' +
                                'let a = b;\n' +
                                '\n' +
                                'console.log(a,b)'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>aritmetici</b>
                            <br/>
                            avem urmatori operatori aritmetici binari:
                            <ul>
                                <li>+ (adunare; daca unul dintre operanzi este string sau nu se poate converti la Number se face concatenare)</li>
                                <li>- (scadere)</li>
                                <li>* (inmultire)</li>
                                <li>/ (impartire - nu intreaga! 5/2 {"=>"} 2.5)</li>
                                <li>% (restul impartirii)</li>
                                <li>** (ridicare la putere)</li>
                            </ul>
                            avem urmatorii operatori unari:
                            <ul>
                                <li>+ (converteste catre Number, chiar daca e string; daca nu reuseste conversia, rezultatul este NaN)</li>
                                <li>- (converteste catre Number, chiar daca e string; daca nu reuseste conversia, rezultatul este NaN; ex: -"abc" {"=>"} NaN)</li>
                                <li>++ (operator incremental; exista in versiune postfixata (operand++; returneaza valoare inainte de incrementare) si prefixata (--operand))

                                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                        {'let a = 2;\n' +
                                        'console.log(a++);//2\n' +
                                        'console.log(a);//3\n' +
                                        'console.log(++a);//4\n' +
                                        'console.log(a);//4'}
                                    </SyntaxHighlighter>

                                </li>
                                <li>-- (operator incremental; exista in versiune postfixata (operand--; returneaza valoare inainte de incrementare) si prefixata (--operand))
                                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                        {'let b = 2\n' +
                                        'console.log(b--);//2\n' +
                                        'console.log(b);// 1\n' +
                                        'console.log(--b);//0\n' +
                                        'console.log(b);//0'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                        <li><b>logici sau conditionali</b>
                            <ul>
                                <li>&& (si)</li>
                                <li>|| (sau)</li>
                                <li>! (not)</li>
                            </ul>

                            Prioritatea cea mai mare este negația !, apoi conjuncția && și, în final, alternativa ||.
                            <br/>
                            Prioritatea poate fi schimbată prin intermediul parantezei.
                        </li>
                        <li>
                            + (<b>operator de concatenare</b>; cand cel putin unul din operanzi este sir de caractere)
                        </li>
                        <li>
                            <b>operatori de atributire compusi:</b>
                            <br/>
                            (ia valoarea variabilei careia urmează să i se facă atribuirea (operandul din stânga) și o modifică prin efectuarea unei operații aritmetice folosind valoarea operandului din dreapta.
                            noua valoare este atribuită operandului din stânga; ex: x += 100 este echivalenta cu x = x + 100 )
                            <ul>
                                <li>+=</li>
                                <li>-=</li>
                                <li>*=</li>
                                <li>/=</li>
                                <li>%=</li>
                                <li>**=</li>
                                <li>&&= (a &&=b {"=>"} a = a && b)</li>
                                <li>||=</li>
                                <li>+= (concatenare)</li>
                            </ul>
                        </li>
                        <li>
                            <b>operatori de comparatie</b>
                            <br/>
                            sunt operatori binari care returneaza o valoare logica: false sau true
                            <br/>
                            se va incerca convertirea operanzilor la o valoare numarica, in afara de operatorii de identitate (===) si egalitate (==)
                            <ul>
                                <li>== (egalitate pe valoare; se realizeaza conversia catre numeric:
                                    <ul>
                                        <li>false se va converti la 0</li>
                                        <li>true se va converti la 1</li>
                                        <li>undefined la NaN</li>
                                        <li>null la 0</li>
                                        <li>5n la 5</li>
                                        <li>"5" la 5</li>
                                    </ul>

                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'console.log(0 == false); // true\n' +
                                        'console.log(undefined == false); // false\n' +
                                        'console.log("2" == 2); // true\n' +
                                        'console.log(NaN == NaN); // false'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>=== (egalitate pe valoare si tip)
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'console.log(101 === 101); // -> true\n' +
                                        'console.log(101 === 101n); // -> false\n' +
                                        'console.log(101 === "101"); // -> false\n' +
                                        'console.log("101" === "101"); // -> true'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>!== (non-identitate)
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'console.log(7 !== 7n); // -> true\n' +
                                        'console.log(7 !== "7"); // -> true'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>!= (inegalitate)
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'console.log(100 != 100); // -> false\n' +
                                        'console.log(100 != 100n); // -> false\n' +
                                        'console.log(100 != "100"); // -> false\n' +
                                        'console.log("100" != "100"); // -> false'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>&lt;</li>
                                <li>&lt;=</li>
                                <li>&gt;</li>
                                <li>&gt;=</li>
                            </ul>
                        </li>
                        <li><b>operatori de comparatie pe siruri de caractere</b>
                            <br/>
                            exista urmatoarele reguli:
                            <ul>
                                <li>caracterele individuale ale ambelor șiruri sunt testate pe aceleași poziții</li>
                                <li>literele mari au valori mai mici decât literele mici</li>
                                <li>cifrele au valori mai mici decat literele</li>
                                <li>litera b are o valoare mai mare decât litera a</li>
                            </ul>
                            operatori:
                            <ul>
                                <li>&lt;</li>
                                <li>&gt;</li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'console.log("b" > "a"); // -> true\n' +
                                'console.log("a" > "B"); // -> true\n' +
                                'console.log("B" > "A"); // -> true\n' +
                                'console.log("B" > "1"); // -> true\n' +
                                'console.log("5" > "1"); // -> true\n' +
                                '\n' +
                                'console.log("ab2" < "ab4"); // -> true\n' +
                                'console.log("ab7" < "abA"); // -> true\n' +
                                'console.log("abC" < "aba"); // -> true\n' +
                                'console.log("aba" < "abb"); // -> true\n' +
                                '\n' +
                                'console.log("ab" < "ab2"); // -> true\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>operatorul typeof</b>: returnează un șir de caractere cu numele tipului pentru o variabila/literal verificat (typeof "1")
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'console.log(typeof "1"); // string'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>operatorul instanceof</b>: verifică dacă un obiect (operandul din stânga) este de un anumit tip (operandul din dreapta)
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'console.log([] instanceof Array); true'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>operatorul delete</b>: permite stergrea unui câmp selectat al obiectului dat
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let persoana = {\n' +
                                '  nume: "Mircea",\n' +
                                '  varsta: 12\n' +
                                '};\n' +
                                'console.log(persoana.varsta); // -> 12\n' +
                                'delete persoana.varsta;\n' +
                                'console.log(persoana.varsta); // -> undefined'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>operatorul ternal (?:)</b>: pe baza valorii primului operand (adevărat sau fals), este returnată valoarea celui de-al doilea sau, respectiv, al treilea operand
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let max = 1 > 2 ? 1 : 2;\n' +
                                'console.log(max); // -> 2'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Acelasi simbol, poate fi interpretat ca operator diferit in functie de context ( de exemplu: +, intre numere, efectueaza adunarea, intre siruri de caractere, efectueaza concatarea).

                    <hr/>
                    <b>Observatii:</b>
                    <ul>
                        <li>tipul Number este un tip în virgulă mobilă, ceea ce înseamnă că rezultatele unora dintre operații pot fi

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'console.log(0.2 + 0.1); // 0.30000000000000004'}
                            </SyntaxHighlighter>

                            Numărul va fi precis pentru numere întregi de până la 252, dar fracțiile pot să nu fie la fel de precise, deoarece multe fracții sunt imposibil de reprezentat direct în format binar.
                        </li>

                        <li>
                            operatori logici || si && nu returnează o valoare booleană; returnează primul sau al doilea operand.
                            <br/>
                            Operatorul AND va returna:
                            <ul>
                                <li>primul operand dacă se evaluează ca false</li>
                                <li>al doilea operand în caz contrar</li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'console.log(true && 5); // -> 5\n' +
                                'console.log(false && 5); // -> false\n' +
                                'console.log(2 && 5); // -> 5\n' +
                                'console.log(0 && 5); // -> 0\n' +
                                'console.log("alina" && "bobita"); // -> bobita\n' +
                                'console.log("" && "bobita"); // -> ""'}
                            </SyntaxHighlighter>
                            Operatorul OR va returna:
                            <ul>
                                <li>primul său operand dacă se evaluează la true</li>
                                <li>al doilea operand în caz contrar</li>
                            </ul>
                            Evaluarea este pur și simplu o încercare de a converti un operand într-o valoare de tip booleana.
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'console.log(true || 5); // -> true\n' +
                                'console.log(false || 5); // -> 5\n' +
                                'console.log(2 || 5); // -> 2\n' +
                                'console.log(0 || 5); // -> 5\n' +
                                'console.log("alina" || "bobita"); // -> alina\n' +
                                'console.log("" || "bobita"); // -> bobita'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            operatori logici || si && au evaluare de tip<i>short-circuit</i> (adica nu se mai evalueaza alte expresii daca nu e cazul)
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let x = 0;\n' +
                                'let y = 0;\n' +
                                '\n' +
                                'console.log(x++ && y++); // 0\n' +
                                'console.log(x); // 1\n' +
                                'console.log(y); // 0'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let x = 1;\n' +
                                'let y = 0;\n' +
                                '\n' +
                                'console.log(x++ || y++); // 1\n' +
                                'console.log(x); // 2\n' +
                                'console.log(y); // 0'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Precendenta operatorilor</b>
                    <br/>
                    <br/>

                    <b>Precedența</b> poate fi prezentată ca valoare numerică – cu cât valoarea este mai mare, cu atât este mai mare prioritatea operației.
                    <br/>
                    Daca efectuam aceleasi operatii (sau operatii diferite dar cu aceeasi prioritate), interpretul foloseste <b>asociativitatea</b> pentru a determina ordinea operatiilor.
                    Operatorii pot avea o asociativitate:
                    <ul>
                        <li>de la stânga specificată (ordine de la stânga la dreapta)</li>
                        <li>de la dreapta (ordine de la dreapta la stânga)</li>
                    </ul>

                    Precedenta (in ordine descrescatoare):
                    <ul>
                        <li>() : paranteze</li>
                        <li>. : acceare camp al unui obiect</li>
                        <li>f(): apel de functie</li>
                        <li>
                            increment postfix (a++), decrement postfix (a--)
                            <br/>
                            !
                            <br/>
                            plus unar (+a), negare unara (-a)
                            <br/>
                            increment prefix (--a), decrement prefix (--a)
                            <br/>
                            typeof
                            <br/>
                            delete
                        </li>
                        <li>
                            ** (ridicare la putere), *
                        </li>
                        <li>
                            /, %
                        </li>
                        <li>
                            +,-
                            <br/>
                            &lt; &lt;=
                        </li>
                        <li>
                            &gt; &gt;=
                            <br/>
                            instanceof
                        </li>
                        <li>
                            ==, !=, ===, !==,
                        </li>
                        <li>
                            &&
                        </li>
                        <li>
                            ||
                        </li>
                        <li>
                            x?y:z
                        </li>
                        <li>
                            =, +=,-=,etc
                        </li>
                    </ul>
                    Parantezele vă permit să impuneți ordinea operațiilor (astfel incat sa nu fie nevoie memorarea precedentelor)
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OperatorsJavaScriptContent;