import React, {useReducer} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class UseReducerReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-usereducer", IndexContent);
    }

    render() {

        // const [count, setCount] = useState(0);
        //
        // const initialState = {
        //     count: 0
        // }
        //
        // const countReducer = (state, action) => {
        //     if (action.type === "SUBSTRACT") {
        //         return {
        //             count: state.count - 1
        //         };
        //     }
        //     if (action.type === "RESET") {
        //         return {
        //             count: 0
        //         };
        //     }
        //     if (action.type === "ADD") {
        //         return {
        //             count: state.count + 1
        //         };
        //     }
        //     return state;
        // }

        // dispatch - inlocuieste setCount si specifica la 'reducer' ce functie sa fie apelata
        // payload - trimtere extra informatii catre functia reducer

        // const [state, dispatch] = useReducer(countReducer, initialState);
        //
        // const handlerSubtract = () => {
        //     dispatch({
        //         type: "SUBSTRACT"
        //     })
        //     //setCount(count - 1);
        // }
        // const handlerReset = () => {
        //     dispatch({
        //         type: "RESET"
        //     })
        //     //setCount(0);
        // }
        //
        // const handlerAdd = () => {
        //     dispatch({
        //         type: "ADD"
        //     })
        //     //setCount(count + 1);
        // }

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. useReducer</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>de la useState la useReducer</b>
                    <br/>
                    <br/>
                    Sa presupunem ca avem:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'  const [count, setCount] = useState(0);\n' +
                            '\n' +
                            '        const handlerSubtract = () => {\n' +
                            '            setCount(count - 1);\n' +
                            '        }\n' +
                            '        const handlerReset = () => {\n' +
                            '            setCount(0);\n' +
                            '        }\n' +
                            '\n' +
                            '        const handlerAdd = () => {\n' +
                            '            setCount(count + 1);\n' +
                            '        }'}
                    </SyntaxHighlighter>
                    Modificam, sa folosim <b>useReducer</b> (mai jos avem obiectul de stare, cu valorile initiale <i>initialState</i> si functia reducer <i>countReducer</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'\n' +
                            'const initialState = {\n' +
                            '    count: 0\n' +
                            '}\n' +
                            '\n' +
                            'const countReducer = (state, action) => {\n' +
                            '    if (action.type === "SUBSTRACT") {\n' +
                            '        return {\n' +
                            '            count: state.count - 1\n' +
                            '        };\n' +
                            '    }\n' +
                            '    if (action.type === "RESET") {\n' +
                            '        return {\n' +
                            '            count: 0\n' +
                            '        };\n' +
                            '    }\n' +
                            '    if (action.type === "ADD") {\n' +
                            '        return {\n' +
                            '            count: state.count + 1\n' +
                            '        };\n' +
                            '    }\n' +
                            '    return state;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi, folosim <b>useReducer</b> (trimitem ca parametri functia reducere si obiectul de stare).
                    <br/>
                    Hock-ul useReducer, trimte obiectul de stare <i>state</i> si o functie <i>dispatch</i> prin intermediul caruia apelam efectiv functia reducer:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// dispatch - inlocuieste setCount si specifica la \'reducer\' ce functie sa fie apelata\n' +
                            '// payload - trimtere extra informatii catre functia reducer\n' +
                            '\n' +
                            'const [state, dispatch] = useReducer(countReducer, initialState);\n' +
                            '\n' +
                            'const handlerSubtract = () => {\n' +
                            '    dispatch({\n' +
                            '        type: "SUBSTRACT"\n' +
                            '    })\n' +
                            '    //setCount(count - 1);\n' +
                            '}\n' +
                            'const handlerReset = () => {\n' +
                            '    dispatch({\n' +
                            '        type: "RESET"\n' +
                            '    })\n' +
                            '    //setCount(0);\n' +
                            '}\n' +
                            '\n' +
                            'const handlerAdd = () => {\n' +
                            '    dispatch({\n' +
                            '        type: "ADD"\n' +
                            '    })\n' +
                            '    //setCount(count + 1);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createContext} from "react";\n' +
                            'const StoreContext = createContext({});\n' +
                            '\n' +
                            'export const ACTION_TYPE ={\n' +
                            '    SET_COLOR:"SET_COLOR"\n' +
                            '};\n' +
                            '\n' +

                            'const storeReducer = (state, action) => {\n' +
                            '    switch(action.type){\n' +
                            '        case ACTION_TYPE.SET_COLOR:{\n' +
                            '            return {...state, culoare: action.payload.culoare}\n' +
                            '        }\n' +
                            '        default:\n' +
                            '            throw new Error("Actiune gresita:"+action.type)\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'const StoreProvider = ({children}) => {\n' +
                            '    const initialState = {\n' +
                            '        culoare: "rosu"\n' +
                            '    }\n' +
                            '\n' +
                            '    const [state, dispatch] = useReducer(storeReducer, initialState);\n' +
                            '    return(\n' +
                            '        <StoreContext.Provider value={{state, dispatch}}>\n' +
                            '            {children}\n' +
                            '        </StoreContext.Provider>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Mod de folosire [setare valoare]:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const {dispatch} = useContext(StoreContext);'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'dispatch({\n' +
                            '  type: ACTION_TYPE.SET_COLOR\n' +
                            '  payload: {culoare: "verde"}\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Mod de folosire [obtinere valoare]:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const {dispatch, state} = useContext(StoreContext);'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let {culoare} = state'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseReducerReactContent;