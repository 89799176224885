import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class CpLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-cp", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Crearea copie la fisierul <i>fisier1</i> in <i>fisierul2</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cp fisier1 fisier2'}
                    </SyntaxHighlighter>

                    Pentru a copia un intreg director, se foloseste flag-ul <b>-r</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cp -r director1 director2'}
                    </SyntaxHighlighter>

                    Pentru a copia continutul unui director in alt director, se foloseste flag-ul <b>-r</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cp -r director1/* director2'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CpLinuxContent;