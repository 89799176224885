import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";


class UseMemoReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-usememo", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hooks: useMemo </b>
                    <br/>
                    <br/>

                    Hook-ul <b>useMemo</b> returnează o valoare memorizata.
                    <br/>
                    <br/>

                    Se poate vedea ca memorarea în cache a unei valori, astfel încât să nu fie nevoie să fie recalculată.
                    <br/>
                    <br/>

                    Hook-ul <b>useMemo</b>  rulează numai când una dintre dependențele sale se actualizează.

                    <br/>
                    <br/>

                    Acest lucru poate îmbunătăți performanța.

                    <br/>
                    <br/>

                    Poate fi folosit pentru a împiedica funcționarea inutilă a funcțiilor costisitoare, care necesită mult resurse.

                    <br/>
                    În urmatorul exemplu, avem o funcție costisitoare care rulează la fiecare randare.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '  const [todos, setTodos] = useState([]);\n' +
                            '  const calculation = expensiveCalculation(count);\n' +
                            '\n' +
                            '  const increment = () => {\n' +
                            '    setCount((c) => c + 1);\n' +
                            '  };\n' +
                            '  const addTodo = () => {\n' +
                            '    setTodos((t) => [...t, "New Todo"]);\n' +
                            '  };\n' +
                            '\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <div>\n' +
                            '        <h2>My Todos</h2>\n' +
                            '        {todos.map((todo, index) => {\n' +
                            '          return <p key={index}>{todo}</p>;\n' +
                            '        })}\n' +
                            '        <button onClick={addTodo}>Add Todo</button>\n' +
                            '      </div>\n' +
                            '      <hr />\n' +
                            '      <div>\n' +
                            '        Count: {count}\n' +
                            '        <button onClick={increment}>+</button>\n' +
                            '        <h2>Expensive Calculation</h2>\n' +
                            '        {calculation}\n' +
                            '      </div>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '};\n' +
                            '\n' +
                            'const expensiveCalculation = (num) => {\n' +
                            '  console.log("Calculating...");\n' +
                            '  for (let i = 0; i < 1000000000; i++) {\n' +
                            '    num += 1;\n' +
                            '  }\n' +
                            '  return num;\n' +
                            '};'}
                    </SyntaxHighlighter>

                    Pentru a remedia această problemă de performanță, putem folosi <b>useMemo</b> pentru a memoriza funcția expensiveCalculation.
                    Acest lucru va face ca funcția să ruleze numai atunci când este necesar.
                    <br/>

                    Putem încheia apelul de funcție costisitor cu <b>useMemo</b>.

                    <br/>
                    <br/>

                    Hook <b>useMemo</b> acceptă un al doilea parametru pentru a declara dependențe.
                    Funcția costisitoare va rula numai atunci când dependențele sale s-au schimbat.
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' const calculation = useMemo(() => expensiveCalculation(count), [count]);'}
                    </SyntaxHighlighter>

                    <br/>

                    În exemplul următor, funcția costisitoare va rula numai când <i>count</i> este schimbată și nu când sunt adăugate tot.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState, useMemo } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '  const [todos, setTodos] = useState([]);\n' +
                            '  const calculation = useMemo(() => expensiveCalculation(count), [count]);\n' +
                            '\n' +
                            '  const increment = () => {\n' +
                            '    setCount((c) => c + 1);\n' +
                            '  };\n' +
                            '  const addTodo = () => {\n' +
                            '    setTodos((t) => [...t, "New Todo"]);\n' +
                            '  };\n' +
                            '\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <div>\n' +
                            '        <h2>My Todos</h2>\n' +
                            '        {todos.map((todo, index) => {\n' +
                            '          return <p key={index}>{todo}</p>;\n' +
                            '        })}\n' +
                            '        <button onClick={addTodo}>Add Todo</button>\n' +
                            '      </div>\n' +
                            '      <hr />\n' +
                            '      <div>\n' +
                            '        Count: {count}\n' +
                            '        <button onClick={increment}>+</button>\n' +
                            '        <h2>Expensive Calculation</h2>\n' +
                            '        {calculation}\n' +
                            '      </div>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '};\n' +
                            '\n' +
                            'const expensiveCalculation = (num) => {\n' +
                            '  console.log("Calculating...");\n' +
                            '  for (let i = 0; i < 1000000000; i++) {\n' +
                            '    num += 1;\n' +
                            '  }\n' +
                            '  return num;\n' +
                            '};\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseMemoReactContent;