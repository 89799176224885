import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";

class KillProcessContent extends BaseContentPage  {

    constructor(props) {
        super(props, "windows-kill-process", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    1. Se porneste un <b>Command Prompt</b> (recomandabil ca <b>Administator</b>) si se introduce urmatoarea comanda, pentru a obtine <b>PID</b>-ul (Process IDentifier):

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'netstat -ano | findstr :<PORT>'}
                    </SyntaxHighlighter>

                    <i>Exemplu</i> (vrem sa oprim aplicatia care e pornita pe portul 8005):
                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'C:\\Users\\kj>netstat -ano | findstr 8005\n' +
                        '  TCP    127.0.0.1:8005         0.0.0.0:0              LISTENING       2772'}
                    </SyntaxHighlighter>

                    PID-ul pentru procesul de mai sus este <i>2772</i>.
                    <br/><br/>

                    2. Se opreste procesul cu <b>PID</b> obtinut cu comanda de mai sus, folosind urmatoarea comanda:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'taskkill /PID <PID> /F'}
                    </SyntaxHighlighter>

                    <i>Exemplu</i> (pentru aplicatia care pornita pe portul 8005, in exemplul de mai sus, PID este 2772):
                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'C:\WINDOWS\system32>taskkill /PID 2772 /F\n'+
                            'SUCCESS: The process with PID 2772 has been terminated.'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KillProcessContent;