import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class UploadFilesAjaxLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-upload-files-ajax", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    In Java, metoda <b>serveResource()</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                        'public void serveResource(ResourceRequest resourceRequest, ResourceResponse resourceResponse) throws IOException, PortletException {\n' +
                        '\n' +
                        '\tUploadPortletRequest uploadRequest = PortalUtil.getUploadPortletRequest(actionRequest|resourceRequest);\n' +
                        '\tMap<String, FileItem[]> filesMap = uploadRequest.getMultipartParameterMap();\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    In JavaScript:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let form = this.form;        \n' +
                        'let $form = $(form);\n' +
                        '\t\t\t\n' +
                        'let enctype = $form.attr("enctype");\n' +
                        'let contentType = \'application/x-www-form-urlencoded; charset=UTF-8\';\n' +
                        'let data = $form.serialize();\n' +
                        'let processData = true;\n' +
                        'let cache = false;\n' +
                        'let timeout = 0;\n' +
                        '\n' +
                        'if (enctype==="multipart/form-data"){\n' +
                        '\tdata = new FormData($form[0]);\n' +
                        '\tprocessData = false;\n' +
                        '\tcontentType = false;\n' +
                        '\tcache = true;\n' +
                        '\ttimeout = 80000;\n' +
                        '}\n' +
                        '\n' +
                        '$.ajax({\n' +
                        '\ttype:"POST",\n' +
                        '\turl:url,\n' +
                        '\tdata: data,\n' +
                        '\tprocessData: processData,\n' +
                        '\tcontentType: contentType,\n' +
                        '\tcache: cache,\n' +
                        '\ttimeout: timeout,\n' +
                        '\n' +
                        '\t//dataType:"json",\n' +
                        '\n' +
                        '\tsuccess(data){\n' +
                        '\t \n' +
                        '\t}\n' +
                        '});'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UploadFilesAjaxLiferayContent;