import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ReadinessProbeK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-readiness-probe", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Readiness Probe</b>
                    <br/>
                    <br/>

                    Pentru a nu permite trafic catre aplicatie pana nu se indeplineste o conditie, se foloseste <b>readinessProbe</b>.
                    <hr/>

                    Un pod are:
                    <ul>
                        <li>un <b>status</b> {"=>"} specifica in ce stare este pod in ciclul lui de viata;
                            <br/>
                            In momentul in care se creeaza este in starea: <b>Pending</b>.
                            <br/>
                            In aceasta stare <b>scheduler</b> incerca sa plaseze pod-ul intr-un nod.
                            <br/>
                            Daca nu gaseste un nod, pod-ul ramane in starea <i>pending</i>.
                            <br/>
                            Pentru a afla de ce pod-ul a ramas in starea <i>pending</i> se poate executa comanda:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl describe pods'}
                            </SyntaxHighlighter>

                            In momentul in care este planificat, pod-ul trece in starea <b>ContainerCreating</b>.
                            <br/>
                            In momentul in care toate containerele din pod pornesc, pod-ul trece in starea <b>Running</b>
                            <br/>
                            Pentru a afla starea unui pod, se poate executa comanda:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl get pods'}
                            </SyntaxHighlighter>

                            Rezumand, exista starile:
                            <ul>
                                <li>
                                    <b>Pending</b>
                                </li>
                                <li>
                                    <b>ContainerCreating</b>
                                </li>
                                <li>
                                    <b>Running</b>
                                </li>
                            </ul>

                        </li>
                        <li>niste <b>conditii</b>: conditiile completeaza status-ul pod-ului
                            <br/>
                            uneori vrem informatii aditionale;
                            <br/>
                            este un array cu valori <i>true</i> sau <i>false</i>, in care se specifica starea pod-ului:
                            <ul>
                                <li>
                                    PodScheduled {"=>"} pod-ul este programat pe un nod
                                </li>
                                <li>
                                    Initialized {"=>"} pod-ul este ininitializat
                                </li>
                                <li>
                                    ContainerReady {"=>"} toate containere din pod sunt ready
                                </li>
                                <li>
                                    Ready {"=>"} nod-ul este ready
                                </li>
                            </ul>

                            Pentru a afla conditiile unui pod, se poate executa comanda:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'kubectl describe pods'}
                            </SyntaxHighlighter>

                            Apoi sectiunea <b>Conditions</b>:
                            <SyntaxHighlighter>
                                {'Conditions:\n' +
                                    '  Type              Status\n' +
                                    '  Initialized       True\n' +
                                    '  Ready             True\n' +
                                    '  ContainersReady   True\n' +
                                    '  PodScheduled      True\n'}
                            </SyntaxHighlighter>

                            <br/>
                        </li>
                    </ul>

                    Kubernetes presupune ca din momentul in care containerul de creat (Pod este Ready), aceste pregatir sa serveasca si trafic.
                    <br/>
                    Doar ca acest lucru, nu este intodeauna adevarat, daca initializarea aplicatie dureaza mai mult timp.
                    <br/>
                    De fapt, vrem sa stim ca aplicatie este Ready in interiorul containerului.
                    <br/>
                    Exista mai multe metode a verifica ca aplicatia este Ready:
                    <ul>
                        <li>HTTP Test: pentru un server, de exemplu

                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {'readinessProbe:\n' +
                                    '  httpGet:\n' +
                                    '    path: /api/ready\n' +
                                    '    port: 8080'}
                            </SyntaxHighlighter>

                        </li>
                        <li>TCP Test: pentru o baza de date, de exemplu

                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {'readinessProbe:\n' +
                                    '  tcpSocket:\n' +
                                    '    port: 8080'}
                            </SyntaxHighlighter>

                        </li>
                        <li>Executia lui Script/Comanda: care iese cu succes, de exemplu

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'readinessProbe: \n' +
                                    '  exec:\n' +
                                    '    command:\n' +
                                    '    - cat\n' +
                                    '    - /tmp/healthy\n'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>
                    Daca exista sectiunea <b>readinessProbe</b>, Kubernetes nu va mai prespune ca aplicatia este Ready dupa ce containerul este creat.
                    Nu mai seteaza conditia de <b>Ready</b> la true. In schimb, va efectua un test sa vada daca raspunsul API este ok.

                    <br/>
                    <br/>
                    Daca stim ca aplicatia porneste in aproximativ 10 secunde, se poate folosi optiunea <b>initialDelaySeconds</b>, pentru a verifica dupa 10 secunde.
                    <br/>
                    Putem specifica dupa cate secunde sa reincerce, dupa ce testul nu a reusit, folosind optiunea <b>periodSeconds</b>.
                    <br/>
                    In mod implicit, se incerca de 3 ori. Pentru a modifica acest comportament, se foloseste optiunea <b>failureThreshold</b>.
                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'readinessProbe:\n' +
                            '  tcpSocket:\n' +
                            '    port: 8080\n' +
                            '  initialDelaySeconds: 5\n' +
                            '  periodSeconds: 10\n' +
                            '  failureThreshold: 8'}
                    </SyntaxHighlighter>
                    <hr/>

                    Exemplu 1 (se verifica pana cand <i>apare</i> fisierul <i>/tmp/healthy</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'readinessProbe: \n' +
                            '  periodSeconds: 5\n' +
                            '  exec:\n' +
                            '    command:\n' +
                            '    - cat\n' +
                            '    - /tmp/healthy\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu 2:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'readinessProbe:\n' +
                            '  tcpSocket:\n' +
                            '    port: 8080\n' +
                            '  initialDelaySeconds: 5\n' +
                            '  periodSeconds: 10'}
                    </SyntaxHighlighter>

                    <hr/>
                    Când aplicația se blochează, containerul este repornit.
                    În această perioadă, serviciul direcționează utilizatorii către POD-ul disponibil, deoarece starea POD-ului nu este READY.
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReadinessProbeK8sContent;