import React from "react";
import {MathComponent} from "mathjax-react";
import {sqrt} from "mathjs";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class KohonenContent extends BaseContentPage {

    constructor(props) {
        super(props, "kohonen", IndexContent);
    }

    render() {

        const setA = [];
        // setA.push([1,1,0,0]);
        // setA.push([0,0,0,1]);
        // setA.push([1,0,0,0]);
        // setA.push([0,0,1,1]);

        setA.push([1, 0.160, 5]);
        setA.push([2, 0.205, 9]);
        setA.push([2, 0.210, 12]);
        setA.push([1, 0.156, 4]);
        setA.push([1, 0.156, 6]);
        setA.push([2, 0.200, 10]);
        setA.push([1, 0.200, 15]);

        let dimObs = setA[0].length;

        let mFalse = Math.floor(3 * sqrt(setA.length));
        let mDim = Math.ceil(sqrt(mFalse));
      //  let m = mDim * mDim;
        let m = 3; // pt setul din carte;
      //  let m = 2; // pt setul din carte;

        let matrixW = [];
        for (let i = 0; i < dimObs; i++) {
            matrixW[i] = new Array(m);
            for (let j = 0; j < m; j++) {
                matrixW[i][j] = (Math.random() * 2.05) + 1;
            //    matrixW[i][j] = Math.random() ;// pt setul din carte;
            }
        }
        // exemplu din carte
        // matrixW = [
        //     [0.2, 0.8],
        //     [0.6, 0.4],
        //     [0.5, 0.7],
        //     [0.9, 0.3]
        // ];
        //m=2;

        let matrixWIterate = [];
        let matrixWCopy = matrixW.map(function(arr) {
            return arr.slice();
        });

        matrixWIterate.push(matrixWCopy);

        let matrixP = [];
        let matrixPL = [];
        for (let i = 0; i < mDim; i++) {
            matrixP[i] = new Array(mDim);
            for (let j = 0; j < mDim; j++) {
                matrixP[i][j] = [i, j];
                matrixPL.push(matrixP[i][j]);
            }
        }

        let indexO = 0;
        let t = 1;
        let tMax = setA.length*30;
    //    let tMax = 5;
        let lr0 = 0.6;
        let tRef = 30;
        let r0 = 1;
        let distante = [];
        let minList = [];
        let minIndexNeuronList = [];
        let shape = 1; // 1: 1-D, 2: 2-D

        for (t=0;t<tMax;t++) {
            let min = 10000000;
            let h = -1;
            let distanteIteratie = [];

            // distanta dintre x si fiecare neuron
            let index = t%setA.length;

            let x = setA[index];
            for(let j=0;j<m;j++){

                let d = 0;
                for(let i = 0; i<x.length; i++){
                    d += Math.pow((x[i]-matrixW[i][j]),2);
                }
                d = Math.sqrt(d);
                distanteIteratie.push(d);

                if (d<min){
                    min = d;
                    h = j;
                }
            }

            // update matrixW:
            for (let i = 0; i < dimObs; i++) {
                for (let j = 0; j < m; j++) {
                    let lr = lr0*Math.exp(-(t/tRef));
                    let r = r0*Math.exp(-(t/tRef));

                    let dl=0;
                    // 1-D
                    if (shape==1) {
                        dl = Math.sqrt(Math.pow((h - j), 2));
                    }else if (shape==2){ // 2-D
                        // de experimentat
                        let pH = matrixPL[h];
                        let pJ = matrixPL[j];
                        let hx = pH[0];
                        let hy = pH[1];
                        let jx = pJ[0];
                        let jy = pJ[1];
                        dl= Math.sqrt(Math.pow((hx-jx),2)+Math.pow((hy-jy),2));
                    }

                    let v = Math.exp(-(Math.pow(dl,2)/Math.pow(r,2)));

                    // daca raza e 0; se actualizeaza doar coloana castigatorare
                    if (r==0){
                        if (j==h){
                            v=1;
                        }else{
                            v=0;
                        }
                    }

                    let delta = lr * v *(x[i] - matrixW[i][j]);
                    matrixW[i][j] += delta;

                }
            }

            let matrixWCopy = matrixW.map(function(arr) {
                return arr.slice();
            });

            matrixWIterate.push(matrixWCopy);

            minList.push(min);
            minIndexNeuronList.push(h);
            distante.push(distanteIteratie);

            // in varianta orginala:
            // lr = lr0;
            // dupa fiecare iteratie : lr = 0.5*lr; (iteratie = dupa ce s-au prezentat toate observatiile)
            // if (t>0 && t%setA.length==0){
            //     lr0 = 0.5*lr0;
            // }
        }

        let cluster = [];
        for(let k=0;k<setA.length;k++) {
            let min = 10000000;
            let h = -1;
            let d = 0;
            let x = setA[k];
            for (let j = 0; j < m; j++){
                for (let i = 0; i < x.length; i++) {
                    d += Math.pow((x[i] - matrixW[i][j]), 2);
                }
                d = Math.sqrt(d);

                if (d<min){
                    min = d;
                    h = j;
                }
            }
            cluster.push(h);
        }

        return (
            <div className="home kohonen">
                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    Retele Kohonen (SOM / Self Organizing Map) sunt un tip de retele neuronale cu auto-organizare introduse de către Teuvo Kohonen în 1982. <br/>
                    Aceste rețele sunt capabile să <i><b>grupeze</b> / <b>clasifice</b></i> informația primită.
                </div>
                <br/>

                <div className={"text-justify"}>
                    Să presupunem să un obiect poate fi definit prin câteva caracteristici sau atribute (ex: înăltime, greutate, culoare, etc ), fiecare caracteristică
                    putând fi redusă la o valoare numerică.
                    <br/>
                    <br/>
                    De exemplu, un măr:
                    <ul>
                        <li>poate fi roșu (culorii roși îi putem asocia cifra 1; pentru verde cifra 2)</li>
                        <li>având 160 grame (transformăm în kg)</li>
                        <li>având 5cm înălțime</li>
                    </ul>
                    Deci, putem <i>rezuma</i> mărul de mai sus printr-o listă cu următoarele caracteristici/valori: 1, 0.16, 5.
                    <br/>  <br/>

                    Având o găleată cu mere (de diferite soiuri, de exemplu: bot de iepure, ionatan, ardelean, ciprian, domnesc) ne propunem să le grupăm
                    după caracteristici, fără a le ști soiul (soiul se va putea deduce din grămezile rezultate).
                    Deci, să presupunem că avem <i>p</i> mere, fiecare măr cu caracteristicile lui.

                </div>
                <br/>
                <div className={"text-justify important"}>
                    Fie X o mulțime care conține <b>p</b> liste de caracteristici/atribute sau vectori de caracterisitici.
                    <MathComponent tex={String.raw`X=\{X^1,X^2,X^3,...,X^p\}`}/>
                    iar fiecare vector conține <b>n</b> caracterisitici (acest vector se mai numește și <b>observație</b>):
                    <MathComponent tex={String.raw`X^i=\{x^i_1,x^i_2,x^i_3,...,x^i_n\}, 1<i<p`}/>
                    Pe viitor, vom renunța la indicele (de sus) care indică cărui vector din X îi aparține o anumită caracteristică.
                    Și ne vom referi la un vector în felul următor:
                    <MathComponent tex={String.raw`\{x_1,x_2,x_3,...,x_n\}`}/>
                    Mulțimea X o vom numi <b>mulțime de antrenament</b>.<br/>
                    Dimensiunea lui X, adică <b>p</b> se mai numește <b>numărul de observații</b>.
                    Deci, X este format din <b>p</b> observații.
                </div>

                <div className={"text-justify"}>
                    <br/>
                    Pentru exemplu de mai sus vom avea un vector cu următoarele valori: {/* {'{'} 1, 160, 5 {'}'}. */}
                     <MathComponent tex={String.raw`\{x_1,x_2,x_3\}=\{1,0.160,5\}`}/>
                    Vectorul de mai sus este o observație.
                    <br/><br/>

                    Să presupunem că avem următoarea găleată (X) cu {setA.length} mere amestecate de câteva soiuri:
                     <table>
                         <tr>
                             <th>id</th>
                             <th>culoare</th>
                             <th>greutate</th>
                             <th>inaltime</th>
                         </tr>
                         {
                             setA.map((row,index) => {
                                return <tr>
                                    <td>{index+1}</td>
                                    {
                                        row.map(value=>{
                                            return <td>{value}</td>
                                        })
                                    }
                                </tr>
                             })
                         }
                     </table>

                    Deci, numărul de observații pentru X definit în tabelul de mai sus este {setA.length}, iar numărul de atribute/caracterisitici este {setA[0].length}.
                    <br/><br/>

                    Ne dorim ca algoritmul să grupeze merele astfel încât să determine soiul.
                    <br/>
                    Deocamdată suntem la nivelul de mărsiști, adică în loc să îi spunem unui anumit măr: 'măr ionatan', îi spunem 'ăla roșu'.
                    <br/>
                    <br/>

                    Să presupunem că nu toate caracteristicile contează la fel de mult (de exemplu, poate culoarea e mai importanță decât înălțimea).
                    Această importanță va fi numită greutate sau pondere.
                    <br/>
                </div>

                <div className={"text-justify important"}>
                    O rețea Kohonen sau SOM (Self-Organizing Maps) constă dintr-un număr de <i>neuroni</i>.
                    Notăm cu <b>m</b> numărul de neuroni și (uneori) poate fi aproximat: <br/>
                    {/* <ul>
                        <li>
                            <MathComponent tex={String.raw`m\approx= 3 \sqrt p `}/>
                        </li>
                    </ul>
                    unde <b>p</b> este numărul de observații din X.
                    <br/>
                    sau */}
                    <ul>
                        <li>
                            <MathComponent tex={String.raw`m\approx= c `}/>
                        </li>
                    </ul>
                    unde <b>c</b> este numărul maxim de clustere în care vrem să grupăm observațiile din X.
                    <br/>
                    <br/>
                    Rețelele Kohonen mai sunt numite <i>hărți de conservare a topologiei</i> pentru că acest tip de rețele
                    presupun o structură topologică printre neuroni (neuronii, în acest context, mai sunt numiți <i>unități cluster</i>
                    - un cluster este un grup mic sau o mulțime - adunătură - de observații).   <br/>
                    Acest mod organizare se observă și în creier și a mai fost preluat de nici o altă rețea neuronală artificială.  <br/>

                    <br/>
                    <b>Vector pondere</b> <br/>
                    Fiecare <b>neuron</b> este reprezentat de un <b>vector pondere</b> cu dimensiunea unei observații din X, adică <b>n</b>.
                    <br/>
                    <i>Vectorul pondere</i> pentru un neuron (unitate cluster) servește <i>ca și exemplu</i> pentru observația (numită și <i>model de intrare</i>) asociată cu acest cluster.
                    <br/>
                    Avand în vedere că avem o mulțime de neuroni, o să avem o matrice de ponderi W de dimensiune <b>n x m</b>:
                    <MathComponent tex={String.raw`w_{ij}`}/>
                    cu semnificatia ponderea de la intrarea i la neuronul j.
                    <br/>
                    Deci, fiecare coloană reprezintă un vector pondere (coloana i reprezintă vectorul pondere pentru neuronul i)
                    <br/>
                    <br/>
                    <b>Vector pozitie</b> <br/>
                    In plus, fiecare <b>neuron</b> are și o poziție (o coordonată). Definim funcția poziție <b>P(i)</b> pentru neuronul i astfel:
                    <ul>
                        <li>
                            în spatiul 1D (x)
                            <MathComponent tex={String.raw`P(i)=(p_1(i))=(x)=i`}/>
                        </li>
                        <li>
                            în spatiul 2D (x,y)
                            <MathComponent tex={String.raw`P(i)=(p_1(i),p_2(i))=(x,y)`}/>
                        </li>
                        <li>
                            în spatiul 3D (x,y,z)
                            <MathComponent tex={String.raw`P(i)=(p_1(i),p_2(i),p_3(i))=(x,y,x)`}/>
                        </li>
                        <li>
                            în spatiul nD: <MathComponent tex={String.raw`P(i)=(p_1(i),p_2(i),p_3(i),...,p_n(i))`}/>
                        </li>
                    </ul>

                    <b>Distanțe laterale</b> <br/>
                    Având in vedere că putem poziționa neuronii, putem definii o funcție care să calculeze distanța dintre doi neuroni.<br/>
                    Vom numi această funcție <b>distanță laterală</b>
                        <MathComponent tex={String.raw`D_{ij}`}/>
                    între neuronul i și neuronul j (ambii din layer-ul de ieșire - output layer)
                    și o vom definii astfel:
                    <ul>
                        <li>
                            în spatiul 1D:
                            <MathComponent tex={String.raw`D_{ij}=|p(i)-p(j)|=|i-j|`}/>
                        </li>
                        <li>
                            în spatiul 2D: (x,y) sau
                            <MathComponent tex={String.raw`D_{ij}=\sqrt{(p_1(i)-p_1(j))^2+(p_2(i)-p_2(j))^2}`}/>
                        </li>
                        <li>
                            în spatiul 3D: (x,y,z) sau
                            <MathComponent tex={String.raw`D_{ij}=\sqrt{(p_1(i)-p_1(j))^2+(p_2(i)-p_2(j))^2+(p_3(i)-p_3(j))^2}`}/>
                        </li>
                        <li>
                            în spatiul nD:
                            <MathComponent tex={String.raw`D_{ij}=\sqrt{\sum_{k=1}^n(p_k(i)-p_k(j))^2}`}/>
                        </li>
                    </ul>

                    <br/>
                    <b>Etapa de antrenare</b> constă în <i>deplasarea</i> vectorilor pondere către datele de intrare.
                    <br/>
                    <a title="Chompinha, CC BY-SA 4.0 &lt;https://creativecommons.org/licenses/by-sa/4.0&gt;, via Wikimedia Commons" href="https://commons.wikimedia.org/wiki/File:TrainSOM.gif">
                        <img width="256" alt="TrainSOM" src="https://upload.wikimedia.org/wikipedia/commons/3/35/TrainSOM.gif"/>
                    </a>
                    <br/>
                    Prin această deplasare, adică prin ajustarea vectorilor pondere se realizează practic gruparea efectivă.
                    <br/>
                    <br/>
                    <b>Etapa de testare</b> sau utilizare constă în clasificarea unui vector de intrare (a unei observații), adică găsind neuronul cu vectorul-pondere cel mai aproape de observație.

                    <br/>
                    <br/>
                    Recapitulând, un neuron este reprezentat prin:
                    <ul>
                        <li>
                            vector pondere
                        </li>
                        <li>
                            vector pozitie
                        </li>
                    </ul>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    Pentru exemplu de mai sus, numărul de neuroni ar fi {mFalse}.*/}
                {/*    Doar că am dori să organizăm neuronii ca și cum ar fi intr-un spatiu 2D.*/}
                {/*    Prin urmare cel mai aproape de ce vrem noi ar fi sub forma unei matricii de {mDim}  x {mDim}  (adică {m} neuroni).*/}
                {/*</div>*/}
                <div className={"text-justify"}>
                    Pentru exemplu de mai sus, vom lucra intr-un spatiu unidimensional (1d) si vom alege <i>m</i>=3.
                    Anticipăm că ar fi 3 soiuri de mare în găleată.
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <b>Etapa de initalizare</b>
                    <br/>
                    Se initializează:
                    <ul>
                        <li>
                            matricea ponderilor W cu dimensiunea <b>n x m</b> cu <b>valori aleatoare</b>
                        </li>
                        <li>
                            {/*matricea pozitiilor neuronilor cu dimensiunea <b>{mDim} x {mDim}</b> cu coordonatele neuronilor.*/}
                            matricea pozitiilor neuronilor cu dimensiunea <b>m</b>
                        </li>
                        <li>
                            pentru calcule sunt initializate următoarele variabile:
                            <ul>
                                <li>
                                    rata de invatare initiala:
                                        <MathComponent tex={String.raw`\eta_0, (exemplu :\eta_0=0.3)`}/>
                                </li>
                                <li>
                                    raza initiala:
                                        <MathComponent tex={String.raw`r_0, (exemplu :r_0=1.0)`}/>
                                </li>
                                <li>
                                    epoca de referinta:
                                    <MathComponent tex={String.raw`\lambda, (exemplu :\lambda=30)`}/>
                                </li>
                                <li>
                                    epoca sau iterația curentă:
                                    <MathComponent tex={String.raw`t, t=1`}/>
                                </li>
                                <li>
                                    maximul de epoci sau iterații:
                                    <MathComponent tex={String.raw`t_{max}, (exemplu :t_{max}=1000)`}/>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    Matricea ponderilor, initializată cu valori aleatoare:
                    <table>
                        {
                            matrixWIterate[0].map((row,index)=>{
                                return <tr>
                                    {
                                        row.map((value, index)=>{
                                            return <td>{value}</td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </table>
                    {/*Matricea pozitiilor neuronilor (cu galben sunt <span className="badge badge-warning">neuronii</span>, cu gri sunt <span className="badge badge-secondary">pozitiile</span> asociate neuronilor):*/}
                    {/*<table>*/}
                    {/*    {*/}
                    {/*        matrixP.map((row,indexI)=>{*/}
                    {/*            return <tr>*/}
                    {/*                {*/}
                    {/*                    row.map((value, indexJ)=>{*/}
                    {/*                        return <td>*/}
                    {/*                            <div className={"text-center"}>*/}
                    {/*                                <span className="badge badge-warning">{indexI*mDim+indexJ+1}</span>*/}
                    {/*                            </div>*/}
                    {/*                            <span className="badge badge-secondary">({value[0]+","+value[1]})</span>*/}
                    {/*                        </td>*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </tr>*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</table>*/}

                </div>

                <div className={"text-justify important"}>
                    <b>Etapa de instruire</b>
                    <br/>
                    Pe parcursul procesului de auto-organizare <i>neuroni</i> ai căror <i>vector</i> pondere se potrivesc cel mai mult cu observația prezentată
                    la intrarea rețelei sunt considerați câștigători. Neuronii câștigători și neuronii vecini (în sensul topologic pentru neuroni) își actualizează
                    ponderile.
                    <br/>
                    De exemplu, dacă neuronii sunt aranjați liniar (spatiu 1D), atunci neuronii vecini pot fi considerați toți neuronii j care respectă
                         <MathComponent tex={String.raw`max(1, J-R) \le j \le min (J+R, m) `}/>
                    unde R este raza, J este indicele neuronului câștigator.
                    <br/>

                    Vizual, dacă notăm cu # neuronul câștigător și cu * neuronii vecini, atunci având următoarea secventă de neuroni:<br/>

                         * * [ * (*  # *) * ] * *<br/>

                    <ul>
                        <li>
                            R = 2, corespunde secventei de neuroni conținuți între []
                        </li>
                        <li>
                            R = 1, corespunde secventei de neuroni conținuți între ()
                        </li>
                        <li>
                            R = 0, corespunde doar neuronului câștigător # (nu sunt aleși neuro);
                        </li>
                    </ul>
                    <br/>
                    Pașii pentru etapa de instruire:
                    <br/>
                    <ol>
                        <li>se selectează <i>aleator</i> sau într-o <i>ordine predefinită</i> o observație din X:
                            <MathComponent tex={String.raw`X^a, 1<a<p`}/>
                           (pentru simplificare vom renunța la indice <b>a</b> și ne vom referi la observația de mai sus doar prin X).
                        </li>
                        <li>
                            se calculează distanțele (de obicei, se foloseste <i>distanța euclidiană</i>) dintre X și fiecare din cei <b>m</b> neuroni:
                            <MathComponent tex={String.raw`d_j(X)={\sqrt{\sum_{i=1}^n(x_i - w_{ij})^2}}, 1 \le j \le m`}/>
                            formula de mai sus, s-ar traduce <i>distanța dintre X si neuronul j</i>.
                        </li>
                        <li>
                            se găsește distanta minima:
                            <MathComponent tex={String.raw`d=min(d_j(X)), 1 \le j \le m`}/>
                            și pe baza ei se reține neuronul <b>h</b>, numit <b>BMU (best-maching-unit)</b> pentru care distanța de la X la el este minimă, adica egala cu <b>b</b>:
                            <MathComponent tex={String.raw`h=argmin(d_j(X)), 1 \le j \le m`}/>
                        </li>
                        <li>
                            se actualizează ponderile:
                            <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta(t)*V_{hj}(t)*(x_i-w_{ij})`}/>
                            unde:
                            <ul>
                                <li>
                                    functia ratei de învătare:
                                    <MathComponent tex={String.raw`\eta(t)=\eta_0exp\big(-\frac{t}{\lambda}\big)`}/>
                                </li>
                                <li>
                                    functia de vecinatate topologică:
                                    <MathComponent tex={String.raw`V_{hi}=exp(-\frac{D_{hi}^2}{2r(t)^2})`}/>
                                </li>
                                <li>
                                    raza sau dimensiunea vecinătății:
                                    <MathComponent tex={String.raw`r(t)=r_0exp(-\frac{t}{\lambda})`}/>
                                    <b>observatie:</b> dimensiunea vecinătății din jurul neuronului h, adică BMU scade la fiecare iterație până ajunge la doar BMU.
                                </li>
                            </ul>
                        </li>
                        <li>se incrementeaza t
                            <MathComponent tex={String.raw`t=t+1`}/>
                        </li>
                    </ol>

                    Repetăm pașii de mai sus cât timp:
                        <ul>
                            <li>
                                <MathComponent tex={String.raw`t<t_{max}`}/>
                            </li>
                        </ul>
                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Initializare:</b>
                    <br/>
                    Fie următoarea mulțime X de observații:
                    <table>
                        {/*<tr>*/}
                        {/*    <th>id</th>*/}
                        {/*    <th>culoare</th>*/}
                        {/*    <th>greutate</th>*/}
                        {/*    <th>inaltime</th>*/}
                        {/*</tr>*/}
                        {
                            setA.map((row,index) => {
                                return <tr>
                                    <td> <span className="badge badge-warning">{index+1}</span></td>
                                    {
                                        row.map((value)=>{
                                            return <td>{value}</td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </table>

                    Matricea ponderilor, initializată cu valori aleatoare:
                    <table>
                        <tr>
                            {
                                Array.apply(0, Array(m+1)).map((v,i)=>{
                                    if (i==0){
                                        return  <th>-</th>;
                                    }
                                    return <th className={"text-center"}>neuron {i}</th>
                                })
                            }
                        </tr>
                        {
                            matrixWIterate[0].map((row,indexRow)=>{
                                return <tr><th>x<sub>{indexRow+1}</sub></th>
                                    {
                                        row.map((value, index)=>{
                                            return <td>{value.toFixed(4)}</td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </table>

                    {
                        [0,1,2,3].map(iteratie=>{
                            return <div>
                                <b>Iteratia {Math.floor(iteratie/setA.length)+1}. Observația {iteratie%setA.length+1}:</b>
                                <br/>
                                Alegând observația <span className="badge badge-warning"> {iteratie%setA.length+1}</span> și calculând distantele dintre ea si toti neuronii obținem:
                                <table>
                                    <tr>
                                        {/*<th>*/}
                                        {/*    iteratie*/}
                                        {/*</th>*/}
                                        {/*<th>*/}
                                        {/*    observatie*/}
                                        {/*</th>*/}
                                        <th>
                                            neuron
                                        </th>
                                        <th>
                                            distanta
                                        </th>
                                    </tr>
                                    {
                                        distante[iteratie].map((d,n)=>{
                                            if (minIndexNeuronList[iteratie]==n){
                                                return <tr className={"content-dpup"}>
                                                    <td>{(n+1)}</td>
                                                    <td>{d.toFixed(4)}</td>
                                                </tr>
                                            }
                                            return <tr>
                                                <td>{(n+1)}</td>
                                                <td>{d.toFixed(4)}</td>
                                            </tr>
                                        })
                                    }
                                </table>

                                Distanta minima este {minList[iteratie].toFixed(4)}. Iar <span className="badge badge-success">{minIndexNeuronList[iteratie]+1}</span> este neuronul pe care il cautam.
                                <br/>
                                Actualizăm ponderile (coloana {minIndexNeuronList[iteratie]+1} și vecinii ei cu raza={r0}):
                                <table>
                                    <tr>
                                        {
                                            Array.apply(0, Array(m+1)).map((v,i)=>{
                                                if (i==0){
                                                    return  <th>-</th>;
                                                }
                                                return <th className={"text-center"}>neuron {i}</th>
                                            })
                                        }
                                    </tr>
                                    {
                                        matrixWIterate[iteratie+1].map((row,indexRow)=>{
                                            return <tr><th>x<sub>{indexRow+1}</sub></th>
                                                {
                                                    row.map((value, index)=>{
                                                        return <td>{value.toFixed(4)}</td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>

                            </div>
                        })
                    }

                    <b>Dupa {Math.floor(tMax/setA.length)} de iteratii:</b>
                    <br/>
                    Matricea ponderilor:
                    <table>
                        <tr>
                            {
                                Array.apply(0, Array(m+1)).map((v,i)=>{
                                    if (i==0){
                                        return  <th>-</th>;
                                    }
                                    return <th className={"text-center"}>neuron {i}</th>
                                })
                            }
                        </tr>
                        {
                            matrixWIterate[matrixWIterate.length-1].map((row,indexRow)=>{
                                return <tr><th>x<sub>{indexRow+1}</sub></th>
                                    {
                                        row.map((value, index)=>{
                                            return <td>{value.toFixed(4)}</td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </table>

                    <br/>
                    <div className={"text-justify important"}>
                        <b>Etapa de utilizare sau de clasificare</b>
                        <br/>
                        Pe baza matricii pondere care a rezultat în urma etapei de instruire, asociem fiecărei observații din X (se poate testa și cu observații noi)
                        cluster-ul din care face parte.
                        <br/>
                        <i>Algoritm:</i>
                        <ul>
                            <li>
                                calculăm pentru observația X distanța dintre ea și neuroni:
                                    <MathComponent tex={String.raw`d_j(X)={\sqrt{\sum_{i=1}^n(x_i - w_{ij})^2}}, 1 \le j \le m`}/>
                            </li>
                            <li>
                                se găsește distanta minima:
                                <MathComponent tex={String.raw`d=min(d_j(X)), 1 \le j \le m`}/>
                                și pe baza ei se reține neuronul <b>h</b>, pentru care distanța de la X la el este minimă, adica egală cu <b>b</b>:
                                <MathComponent tex={String.raw`h=argmin(d_j(X)), 1 \le j \le m`}/>
                            </li>
                            <li>
                                <b>h</b> este clusterul
                            </li>
                        </ul>
                        <br/>
                        <b>Observatie:</b> Rețeaua Kohonen produce <b>un vector (o observație surogat) reprezentativ (un exemplar)</b> pentru fiecare cluster format.

                    </div>
                    <br/>

                    Folosind matricea pondere, de mai sus vom calcula distantele de la fiecare observatie la fiecare  neuron.
                    Si vom considera ca observația apartine cluster-ului i, daca distanta de la observatie la neuronul i este minimă.
                    <table>
                        <tr>
                            <th>id</th>
                            <th>culoare</th>
                            <th>greutate</th>
                            <th>inaltime</th>
                            <th><b>cluster</b></th>
                        </tr>
                        {
                            setA.map((row,index) => {
                                return <tr>
                                    <td>{index+1}</td>
                                    {
                                        row.map(value=>{
                                            return <td>{value}</td>
                                        })
                                    }
                                    <td className={"text-center"}><span className="badge badge-success">{cluster[index]+1}</span></td>
                                </tr>
                            })
                        }
                    </table>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson</div>
                        </li>

                        <li>
                            <a href={"https://www.youtube.com/watch?v=H9H6s-x-0YE&ab_channel=ThalesSehnK%C3%B6rting"}
                               rel="noreferrer"
                               target="_blank">https://www.youtube.com/watch?v=H9H6s-x-0YE&ab_channel=ThalesSehnK%C3%B6rting</a>
                        </li>
                        <li>
                            <a href={"https://code-it.ro/introducere-in-retele-neuronale-teorie-si-aplicatii/#koh"}
                               rel="noreferrer"
                               target="_blank">https://code-it.ro/introducere-in-retele-neuronale-teorie-si-aplicatii/#koh</a>
                        </li>

                        <li>
                            <a href={"https://eric.univ-lyon2.fr/~ricco/cours/slides/en/kohonen_som.pdf"} rel="noreferrer" target="_blank">
                                https://eric.univ-lyon2.fr/~ricco/cours/slides/en/kohonen_som.pdf
                            </a>
                        </li>
                        <li>
                            <a href={"https://eklavyafcb.github.io/docs/KohonenThesis.pdf"} rel="noreferrer" target="_blank">
                                https://eklavyafcb.github.io/docs/KohonenThesis.pdf
                            </a>
                        </li>

                        <li>
                            <a href={"https://en.wikipedia.org/wiki/Self-organizing_map"} rel="noreferrer" target="_blank">
                                https://en.wikipedia.org/wiki/Self-organizing_map
                            </a>
                        </li>

                        <li>
                            <a href={"http://www.cleartheconcepts.com/kohonen-self-organizing-feature-map/"}  rel="noreferrer" target="_blank">
                                http://www.cleartheconcepts.com/kohonen-self-organizing-feature-map/
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KohonenContent;