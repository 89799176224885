import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerfileDockerResumeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dockerfile-resume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Fisierul Dockerfile</b>

                    <br/>
                    <br/>

                    Un <b>Dockerfile</b> este un document text care contine toate comenzile pe care un utilizator le-a putea da in linie de comanda pentru a creea o imagine.
                    <br/>
                    <br/>
                    Formatul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# comentariu\n' +
                            'INSTRUCTIUNE argumente'}
                    </SyntaxHighlighter>
                    unde <i>INSTRUCTIUNE</i>:
                    <ul>
                        <li>
                            <b>FROM</b> specificare imagine de baza (base image)
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM [--platform=<platform>] <image>[:<tag>] [AS <name>]'}
                            </SyntaxHighlighter>

                            exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM node'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>ARG</b> specificare argumente pentru imagine de baza (base image);
                            <br/>
                            <b>ARG</b> trebuie sa apara inainte de <b>FROM</b>, pentru a putea fi folosit in FROM
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ARG  CODE_VERSION=latest\n' +
                                    'FROM base:${CODE_VERSION}'}
                            </SyntaxHighlighter>
                            pot fi accesate in Dockerfile, dar nu sunt accesibile in CMD sau codul aplicatiei.
                            <br/>
                            exemplu (ARG in acest caz poate sa apara si dupa FROM):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ARG DEFAULT_PORT=80\n' +
                                    'ENV PORT $DEFAULT_PORT'}
                            </SyntaxHighlighter>

                            se pot seta cu optiunea <b>--build-arg</b>:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker build [imagine] --build-arg DEFAULT_PORT=81'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>ENV</b> - declarare variabila de mediu;
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ENV <key>=<value>'}
                            </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ENV <key> <value>'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ENV abc=hello'}
                            </SyntaxHighlighter>
                            pot fi accesate in Dockerfile (<b>$key</b>, de exemplu: $abc), si sunt accesibile in CMD sau codul aplicatiei.
                            <br/>
                            <br/>
                            exemplu 1:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ENV PORT=80\n' +
                                    'EXPOSE $PORT'}
                            </SyntaxHighlighter>
                            exemplu 2:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM busybox\n' +
                                    'ENV NGINX 1.2\n' +
                                    'RUN touch web-$NGINX.txt\n' +
                                    'CMD ["/bin/sh"]'}
                            </SyntaxHighlighter>

                            se pot seta cu optiunea <b>--env</b> sau <b>-e</b>:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run --env PORT=81'}
                            </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -e PORT=81'}
                            </SyntaxHighlighter>
                            in felul acesta se pot suprascrie valorile implicite declarate in fisierul Dockerfile
                            <br/>
                            <br/>
                            variabile de mediu pot fi citite si dintr-un fisier; de exemplu <i>.env</i>;
                            <br/>
                            pentru a specifica acest lucru se foloseste optiunea <b>--env-file</b>:

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -env-file ./.env'}
                            </SyntaxHighlighter>
                            abordarea cu fisier care contine variabie de mediu este util cand sunt valori sensibile (parole de exemplu);
                            daca se foloseste un fisier, <b>valorile nu fac parte din imagine</b>;
                            in caz contrar, acestea pot fi citite utilizand: <b>docker history [imagine]</b>
                        </li>
                        <li>
                            <b>COPY</b> - copiere fisiere de pe masina locala in container;
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'COPY [--chown=<user>:<group>] <src>... <dest>'}
                            </SyntaxHighlighter>
                            se copiaza doar continutul din <b>src</b>.
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'COPY . .'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'COPY . /app'}
                            </SyntaxHighlighter>
                            Directorul <i>app</i> se creeaza daca nu exista in container!
                            <br/>
                            Instrucțiunea poate fi folosită numai pentru fișierele stocate local.
                            Prin urmare, nu poate fi utilizat cu adrese URL pentru a copia fișiere externe în container.
                            <br/>
                            Se recomanda folosirea instructiunii <b>COPY</b>.
                            <br/>
                            Singura dată când ar trebui să utilizați comanda <b>ADD</b> este atunci când extrageți fișiere tar locale în imagine.

                            <br/>
                            <br/>
                            Instructiunea COPY tine cont de fisierul <b>.dockerignore</b>!
                            <br/>
                            De exemplu,  fisierul <b>.dockerignore</b> poate avea urmatorul continut (cu fisierele si directoarele care vor fi ignorate):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'node_modules\n' +
                                    '.git\n' +
                                    'Dockerfile'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>ADD</b> - copiere fisiere noi, directoare sau URL-uri ale fișierelor de la distanță de pe masina locala in container;
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ADD [--chown=<user>:<group>] <src>... <dest>'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ADD hom?.txt /mydir/'}
                            </SyntaxHighlighter>
                            Singura dată când ar trebui să utilizați comanda <b>ADD</b> este atunci când extrageți fișiere tar locale în imagine.
                            Daca avem local o arhiva instructiuna <i>ADD arhiva.tar.gz /tmp</i> va avea ca efect dezarhivarea <i>arhiva.tar</i> in locatia de pe container: <i>/tmp</i>
                            In rest se recomanda folosirea <b>COPY</b>.
                        </li>

                        <li>
                            <b>WORKDIR</b> - seteaza directorul de lucru pentru RUN, CMD, EMTRYPOINT, COPY si ADD
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'WORKDIR /app'}
                            </SyntaxHighlighter>
                            Are efect pentru orice instructiune de dupe ea. In plus, la rularea containerului, ramane setat directorul de lucru.

                            <hr/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM busybox\n' +
                                    'RUN mkdir -p /root/demo/context1/context2\n' +
                                    'WORKDIR /root/demo\n' +
                                    'WORKDIR context1\n' +
                                    'WORKDIR context2\n' +
                                    'RUN touch file01.txt\n' +
                                    'CMD ["/bin/sh"]'}
                            </SyntaxHighlighter>


                        </li>

                        <li>
                            <b>RUN</b> - rulare comenzi pentru instalare programe;
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'RUN echo hello'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>CMD</b> - specificare comanda care se va executa la pornirea containerului
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'CMD ["node","server.js"]'}
                            </SyntaxHighlighter>
                            diferenta dintre <b>RUN</b> si <b>CMD</b> este ca instructiunea <b>RUN</b> se executa la crearea imaginii, in timp ce instructiunea <b>CMD</b> la crearea unui container.
                            <br/>
                            Daca nu se specifica un <b>CMD</b>, atunci se va executa <b>CMD</b>-ul imaginii de baza; fara imagine de baza si fara CMD se va optine o eroare!
                            <br/>
                            Instructiunea <b>CMD</b> poate fi suprascrisa de comanda <i>docker run [container] npm init</i> (de argumentele comenzii)
                        </li>
                        <li>
                            <b>ENTRYPOINT</b> -  specificare comanda care se va executa la pornirea containerului;
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ENTRYPOINT ["executable", "param1", "param2"]'}
                            </SyntaxHighlighter>

                            exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ENTRYPOINT ["npm"]'}
                            </SyntaxHighlighter>
                            parametrii se adauga la <i>npm</i>
                            <br/>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM node\n' +
                                    'WORKDIR /app\n' +
                                    'ENTRYPOINT ["npm"]'}
                            </SyntaxHighlighter>
                            daca executam: docker run [container] init {"=>"} docker run [container] npm init;
                            <br/>
                            Instructiunea <b>ENTRYPOINT</b> <i>nu poate fi suprascrisa</i>, argumentele se adauga la <i>executabilul</i> specificat;
                            <hr/>
                            Exemplu, daca avem urmatorul Dockerfile:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM busybox\n' +
                                    'CMD ["sh"]'}
                            </SyntaxHighlighter>
                            si facem build:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker build -t b1 .'}
                            </SyntaxHighlighter>
                            putem apoi rula:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -it b1'}
                            </SyntaxHighlighter>
                            sau chiar suprascrie comanda <i>sh</i>:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                 {'docker run -it b1 ping -c 2 google.com'}
                            </SyntaxHighlighter>

                            poate afisa:
                            <SyntaxHighlighter>
                                {'PING google.com (142.251.39.14): 56 data bytes\n' +
                                    '64 bytes from 142.251.39.14: seq=0 ttl=57 time=16.217 ms\n' +
                                    '64 bytes from 142.251.39.14: seq=1 ttl=57 time=16.151 ms\n' +
                                    '\n' +
                                    '--- google.com ping statistics ---\n' +
                                    '2 packets transmitted, 2 packets received, 0% packet loss\n' +
                                    'round-trip min/avg/max = 16.151/16.184/16.217 ms\n'}
                            </SyntaxHighlighter>

                            Daca am avea Dockerfile cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'FROM busybox\n' +
                                    'ENTRYPOINT ["/bin/ping"]'}
                            </SyntaxHighlighter>
                            atunci comanda:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -it b1 ping -c 2 google.com'}
                            </SyntaxHighlighter>
                            s-ar traduce in: <b>/bin/ping ping -c 2 google.com</b> {"=>"} deci s-ar obtine eroare;
                            <br/>
                            corect ar fi (pentru ca <b>ENTRYPOINT</b> <i>nu poate fi suprascrisa</i>, argumentele se adauga la <i>executabilul</i> specificat, la /bin/ping, in cazul de fata):
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker run -it b1 -c 2 google.com'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>MAINTENER</b> - setează câmpul Autor al imaginilor generate;
                            <br/>
                            instructiunea este invechita si trebuie folosit in schimb <b>LABEL</b>
                        </li>

                        <li>
                            <b>LABEL</b> - adaugă metadate unei imagini.;
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'LABEL <key>=<value> <key>=<value> <key>=<value> ...'}
                            </SyntaxHighlighter>
                            de exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'LABEL version="1.0"'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>EXPOSE</b> - Instrucțiunea EXPOSEinformează Docker că containerul ascultă pe porturile de rețea specificate în timpul execuției. Puteți specifica dacă portul ascultă pe TCP sau UDP, iar implicit este TCP dacă protocolul nu este specificat.

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'EXPOSE 3000'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'EXPOSE 80/udp'}
                            </SyntaxHighlighter>

                            Instrucțiunea EXPOSE nu publică de fapt portul (este doar pentru documentatie si <i>best practice</i>).
                            <br/>
                            Pentru a publica efectiv portul atunci când rulați containerul, trebuie:
                            <ul>
                                <li>
                                    utilizat flag-ul <b>-p</b> pentru docker run a publica și mapa unul sau mai multe porturi
                                </li>
                                <li>
                                    utilizat flag-ul <b>-P</b> pentru a publica toate porturile expuse și a le mapa la porturi de ordin înalt.
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>VOLUME</b> - creează un punct de montare cu numele specificat și îl marchează ca deținând volume montate extern din gazda nativă sau din alte containere
                            <br/>
                            De exemplu pentru configura volum pentru directorul <i>app/director</i> din container:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'VOLUME ["app/director"]'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>USER</b> - setează numele de utilizator (sau UID) și opțional grupul de utilizatori (sau GID) pentru a le utiliza ca utilizator și grup implicit pentru restul etapei curente.
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'USER <user>[:<group>]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>ONBUILD</b> - adaugă imaginii o instrucțiune de declanșare care urmează să fie executată mai târziu, când imaginea este folosită ca bază pentru o altă construcție.
                            <br/>

                            <ul>
                                <li>
                                    se execută în orice imagine copil derivată DIN imaginea curentă
                                </li>
                                <li>
                                    se execută înaintea oricărei comenzi dintr-un copil
                                </li>
                                <li>
                                    instrucțiunea nu afectează construcția curentă.
                                </li>
                            </ul>

                            Mod de functionare:
                            <ul>
                                <li>
                                    cand se intalneste o instructiune ONBUILD, builder-ul adauga un declansator la metadatele imaginiii care este construita;
                                    <br/>
                                    instructiunea nu afecteaza build-ul imaginii curente
                                </li>
                                <li>
                                    La sfârșitul build-ului, o listă a tuturor declanșatorilor este stocată în manifestul imaginii, sub cheia OnBuild.
                                    <br/>
                                    Acestea pot fi inspectate cu comanda <b>docker inspect</b>.
                                </li>
                                <li>
                                    Mai târziu, imaginea poate fi folosită ca bază pentru o nouă construcție, folosind instrucțiunea FROM.
                                    <br/>
                                    Ca parte a procesării instrucțiunii FROM, constructorul caută declanșatoarele ONBUILD și le execută în aceeași ordine în care au fost înregistrate.
                                    <br/>
                                    Dacă oricare dintre declanșatori eșuează, instrucțiunea FROM este întreruptă, ceea ce la rândul său face ca construcția să eșueze.
                                    <br/>
                                    Dacă toate declanșatoarele reușesc, instrucțiunea FROM se finalizează și construirea continuă ca de obicei.
                                </li>
                                <li>
                                    declanșatoarele sunt șterse din imaginea finală după ce au fost executate. Cu alte cuvinte, ele nu sunt moștenite de construcțiile „nepoților”.
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>STOPSIGNAL</b> - setează semnalul de apel de sistem care va fi trimis containerului pentru a ieși;
                            <br/>

                            Acest semnal poate fi un nume de semnal în formatul SIG<b>NAME</b>, de exemplu SIGKILL, sau un număr fără semn care se potrivește cu o poziție din tabelul de apeluri sistem al nucleului, de exemplu 9.
                            Valoarea implicită este SIGTERM dacă nu este definită.

                            <br/>
                            valori posibile:
                            <ul>
                                <li>SIGKILL</li>
                                <li>SIGTERM</li>
                                <li>SIGSTOP</li>
                            </ul>
                            Valoare implicita poate fi suprascrisa prin optiunea <b>--stop-signal</b> pentru <b>docker run</b> / <b>docker create</b>.

                            <br/>
                            <br/>
                            Daca containerul este oprit si exista instructiunea <b>STOPSIGNAL SIGKILL</b>, atunci in urma comenzii <b>docker ps -a</b>, poate aparea ca a iesit cu codul 137, si nu 0, ca de obicei.
                        </li>
                        <li>
                            <b>HEALTHCHECK</b>:  îi spune lui Docker cum să determine dacă starea containerului este normală

                            <br/>
                            Sintaxa arată astfel:
                            <SyntaxHighlighter>
                                {
                                    'HEALTHCHECK [options] CMD <command>'
                                }
                            </SyntaxHighlighter>
                            sau:
                            <SyntaxHighlighter>
                                {'HEALTHCHECK NONE '}
                            </SyntaxHighlighter>
                            ultima forma a instructiunii HEALTHCHECK dezactiveaza orice HEALTHCHECK moștenită din imaginea de bază.

                            Mod de functionare:
                            <br/>
                            Când o instrucțiune HEALTHCHECK este specificată într-o imagine, containerul este pornit cu ea, starea inițială va începe și va deveni ok după ce instrucțiunea HEALTHCHECK este verificată cu succes.
                            Dacă eșuează de un anumit număr de ori, va deveni 'nesănătos'.
                            <br/>
                            <br/>
                            Când un container are o verificare de sănătate specificată, acesta are o  stare de sănătate  în plus față de starea sa normală.
                            Această stare este inițial <i>starting</i>.
                            <br/>
                            Ori de câte ori trece un control de sănătate, acesta devine  <i>healthy</i> (indiferent de starea în care se afla anterior).
                            După un anumit număr de eșecuri consecutive, devine <i>unhealthy</i>.
                            <br/>
                            <br/>
                            Optiuni:
                            <ul>
                                <li>
                                    --interval=30s, interval intre doua verificari, implicit este 30 de secunde; deci face verificari din 30 in 30 de secunde;
                                </li>
                                <li>
                                    --timeout=30s, comanda de verificare a starii ruleaza pana cand nu expira timpul setat; valoare implicita este 30 de secunde
                                </li>
                                <li>
                                    --start-period=0s, perioada de timp data containerul pentru a se initializa;
                                    <br/>
                                    daca verificarea starii de sanatate nu reuseste in aceea perioada, nu se ia in considerare pentru numarul maxim de incercari;
                                    <br/>
                                    daca verificarea starii de sanatate reuseste in aceea perioada, containerul este considerat pornit si toate  eșecurile consecutive vor fi luate în considerare pentru numărul maxim de reîncercări
                                    {/*<br/>*/}
                                    {/*optiunea este adaugate in formatul de fisier 3.4.*/}
                                </li>
                                <li>
                                    -retries=3, numarul de erori consecutive;
                                    <br/>
                                    La fel ca CMD, ENTRYPOINT, HEALTHCHECK poate apărea o singură dată. Dacă sunt scrise mai multe, doar ultima va avea efect
                                </li>
                            </ul>
                            Exemplu:
                            <SyntaxHighlighter>
                                {'FROM nginx:1.13\n' +
                                    'HEALTHCHECK --interval=30s --timeout=3s \\\n' +
                                    '  CMD curl -f http://localhost/ || exit 1\n' +
                                    'EXPOSE 80'}
                            </SyntaxHighlighter>
                            Daca se iese pe ramura de <i>exit 1</i>, atunci starea containerul nu e ok (e nesanatoasa)!

                            <br/>
                            <br/>
                            Valorile posibile de iesire (indica starea de sanatate a containerului):
                            <ul>
                                <li>0: success {"=>"} conatainerul este ok si pregatit sa fie utilizat</li>
                                <li>1: failure {"=>"} containerul nu functioneaza cum trebuie</li>
                                <li>2: reservate {"=>"} a nu se folosi acest code de iesire</li>
                            </ul>

                            Nu poate exista decât o singură  HEALTHCHECK instrucțiune într-un Dockerfile. Dacă enumerați mai multe, atunci numai ultima  HEALTHCHECK va avea efect.

                            <br/>
                            <br/>
                            Verificarea sănătății va rula mai întâi la intervale de secunde specificate după pornirea containerului și apoi din nou  la intervale de  secunde după finalizarea fiecărei verificări anterioare.

                            <br/>
                            <br/>
                            Pentru a ajuta la depanarea probelor eșuate, orice text de ieșire (codificat UTF-8) pe care comanda îl scrie pe stdout sau stderr va fi stocat în starea de sănătate și poate fi interogat cu <b>docker inspect</b>.
                            O astfel de ieșire ar trebui să fie scurtă (în prezent sunt stocați doar primii 4096 de octeți)
                            <hr/>
                            Caracteristica  HEALTHCHECK a fost adăugată în Docker 1.12
                            <hr/>
                            La rularea unei container se poate specifica urmatoarele optiune privind starea de sanatate (HEALTHCHECK):
                            <ul>
                                <li>
                                    --health-cmd {"=>"} Comandă pentru a rula pentru a verifica starea de sănătate
                                </li>
                                <li>
                                    --health-interval {"=>"} Timp între efectuarea verificării
                                </li>
                                <li>
                                    --health-retries {"=>"} Eșecuri consecutive necesare pentru raportarea nesănătoasă
                                </li>
                                <li>
                                    --health-timeout {"=>"} Timp maxim pentru a permite executarea unei verificări
                                </li>
                                <li>
                                    --health-start-period {"=>"} Perioada de început pentru inițializarea containerului înainte de a începe numărătoarea inversă pentru reîncercări de sănătate
                                </li>
                                <li>
                                    --no-healthcheck {"=>"} Dezactivare orice HEALTHCHECK specificat de container
                                </li>
                            </ul>

                            <hr/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {
                                    'docker run --name=test -d \\\n' +
                                    '    --health-cmd=\'stat /etc/passwd || exit 1\' \\\n' +
                                    '    --health-interval=2s \\\n' +
                                    '    busybox sleep 1d'
                                }
                            </SyntaxHighlighter>

                            Verificare stare de sanatate:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker inspect --format=\'{{.State.Health.Status}}\' test'}
                            </SyntaxHighlighter>
                            {"=>"} healthy
                            <br/>
                            <br/>
                            Stergere fisier:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker exec test rm /etc/passwd'}
                            </SyntaxHighlighter>

                            Verificare, din nou, stare de sanatate:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker inspect --format=\'{{.State.Health.Status}}\' test'}
                            </SyntaxHighlighter>
                            {"=>"} unhealthy

                            Verificare, din nou, stare de sanatate, cu mai multe detalii:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker inspect --format=\'{{json .State.Health}}\' test'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <hr/>
                            <b>SHELL</b>
                        </li>


                    </ul>



                    Un fisier Dockerfile <b>trebuie sa incepa cu instructiunea FROM</b>.

                    <br/>
                    <br/>

                    Creare imagine din Dockerfile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    Observatii legate de build:
                    <ul>
                        <li>se bazeaza pe layere; fiecare instructiune devine un layer si este <i>cache-uit</i></li>
                        <li>imaginea rezultata este read-only</li>
                        <li>cand se construieste un container pe baza unei imagini:
                            <ul>
                                <li>
                                    se foloseste de layerele din imagine - layere read-only
                                </li>
                                <li>
                                    se mai creeaza un layer - layer-ul de container - care nu este read-only
                                </li>
                            </ul>
                            deci daca avem 2 contaiere, atunci acestea folosesc acelasi layere din imagine, nu le duplica
                        </li>
                        <li>la un nou rebuild se incerca sa se refoloseasca layerele cache-uite (daca nu s-a modificat nimic)</li>
                        <li>daca s-a modificat ceva intr-un layer (de exemplu in codul sursa al aplicatiei), atunci layer-ul nu se mai ia din cache si se face rebuild la layer;
                            <br/>
                            <i>si se face rebuild la toate layerele de dupa acest layer</i>
                        </li>
                    </ul>

                    <hr/>
                    <b>Comenzi utile pentru imagini</b>:
                    <br/>
                    <br/>

                    Tag-urire (atribuire nume):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t [nume_imagine:nume_tag].'}
                    </SyntaxHighlighter>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t aplicatie:1.0 .'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t aplicatie .'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Un tag este o referință la o imagine. Mai multe tag-uri se pot referi la aceeași imagine.
                        </li>
                        <li>
                            Dacă se reatribuie un tag care este deja utilizată, atunci imaginea originală va pierde tag-ul, dar va continua să existe (va fi în continuare accesibilă prin ID-ul imaginii și alte tag-uri s-ar putea referi la ea).
                        </li>
                    </ul>


                    Listare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker images'}
                    </SyntaxHighlighter>
                    Analiza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect [imagine]'}
                    </SyntaxHighlighter>
                    Stergere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rmi [imagine]'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rmi [imagine_1] [imagine_2] [imagine_3]'}
                    </SyntaxHighlighter>
                    Se pot sterge doar imaginile care nu sunt folosite de nici un container (oprit sau care ruleaza)!

                    <br/>
                    <br/>

                    Pentru a sterge toate imaginile nefolosite de nici un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image prune'}
                    </SyntaxHighlighter>

                    Pentru a sterge toate imaginile nefolosite de nici un container, inclusiv cele tag-uite:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image prune -a'}
                    </SyntaxHighlighter>

                    Creare tag pentru o imagine (un fel de rename, desi e o clona):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag [nume_imagine] [nume_imagine_nou]'}
                    </SyntaxHighlighter>

                    Push / Upload pe DockerHub:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker push [imagine]'}
                    </SyntaxHighlighter>

                    Pull / Download de pe DockerHub:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull [imagine]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Comenzi utile pentru containere:</b>
                    <br/>
                    <br/>

                    Atribuire nume:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run --name [nume_container]'}
                    </SyntaxHighlighter>

                    Listare continere care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>

                    Listare si containere care au rulat in trecut (sunt oprite):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -a'}
                    </SyntaxHighlighter>

                    Analiza/configurare in detalii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker --help'}
                    </SyntaxHighlighter>

                    Rulare (<b>creeaza un nou container si il ruleaza</b>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run [-p <port-extern>:<port-intern>] [imagine]'}
                    </SyntaxHighlighter>
                    Containerul ruleaza, in mod implicit, in foreground (atasat);
                    <br/>
                    Pentru a rula in mod background (detached mode) se foloseste flag-ul <b>-d</b>.
                    <br/>
                    Pentru a rula in mod interactiv (sa preia date de la tastatura, de exemplu), se foloseste flag-ul <b>-i</b>;
                    <br/>
                    Pentru a rula in mod tty, se foloseste flag-ul <b>-t</b> (de obicei -it se folosesc impreuna);
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it [imagine]'}
                    </SyntaxHighlighter>

                    Pentru ca un container sa se stearga in mod automat cand este oprit, se foloseste flag-ul <b>--rm</b>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -rm [imagine]'}
                    </SyntaxHighlighter>

                    Pentru a trece in modul atasat (<b>attach</b> mode) un container care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker attach [container]'}
                    </SyntaxHighlighter>
                    Daca se va incerca trecerea unui container in mod atasat si containerul este oprit se va genera o eroare:
                    <br/>
                    <i>You cannot attach to a stopped container, start it first</i>
                    <br/>
                    <br/>

                    Oprire:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker stop [container]'}
                    </SyntaxHighlighter>

                    Un container oprit poate fi repornit (nu e nevoie de <b>docker run</b>, care oricum face alt container):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker start [container]'}
                    </SyntaxHighlighter>
                    <b>Este pornit in mod detasat, adica ruleaza in background</b>.
                    Daca se vrea pornit in mod atasat, se foloseste flag-ul <b>-a</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker start -a [container]'}
                    </SyntaxHighlighter>
                    Daca se vrea pornit si in mod interactiv:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker start -a -i [container]'}
                    </SyntaxHighlighter>

                    Stergere (container care este oprit):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm [container]'}
                    </SyntaxHighlighter>
                    Nu se poate sterge un container care ruleaza. Daca se incerca acest lucru, se va genera o eroare.
                    <br/>
                    Se pot sterge mai multe containere:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm [container_1] [container_2] [container_3] ...'}
                    </SyntaxHighlighter>

                    Pentru a sterge toate containerele oprite:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container prune'}
                    </SyntaxHighlighter>
                    
                    Loguri:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker logs [container]'}
                    </SyntaxHighlighter>

                    Loguri in mod atasat:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker logs -f [container]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a copia un fisier intr-un container care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker cp my-pc/text.txt [container]:/test'}
                    </SyntaxHighlighter>
                    Directorul <i>/test</i> se creeaza in mod automat in container, daca nu exista!

                    <hr/>
                    Pentru a copia un fisier dintr-un container care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker cp [container]:/test my-pc'}
                    </SyntaxHighlighter>
                    Directorul <i>my-pc</i> se creeaza in mod automat in container, daca nu exista! (de fapt se va copia in: my-pc/test/text.txt)

                    <hr/>
                    Login:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker login'}
                    </SyntaxHighlighter>

                    Logout:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker logout'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerfileDockerResumeContent;