import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"tomcat-index", url:"#", title:"Tomcat", subtitle:""},

        {id:"tomcat-intro", url:"/tomcat/intro", title:"Introducere", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-ssl", url:"/tomcat/ssl", title:"Securitate si configurare SSL", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-virtualhosts", url:"/tomcat/virtualhosts", title:"Virtual Hosts", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-clustering", url:"/tomcat/clustering", title:"Clustering. LoadBalancing", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-loadbalancing-nginx", url:"/tomcat/loadbalancing-nginx", title:"LoadBalancing cu NGinx", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-loadbalancing-apache", url:"/tomcat/loadbalancing-apache", title:"LoadBalancing cu Apache HTTP & MOD_KJ", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-loadbalancing-session", url:"/tomcat/loadbalancing-session", title:"LoadBalancing. Gestiunea sesiunilor", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-jndi", url:"/tomcat/jndi", title:"Creare JNDI si JDBC Connection Pooling", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-valves", url:"/tomcat/valves", title:"Interceptarea request-urilor folosind Valves", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-realms", url:"/tomcat/realms", title:"Realms si gestiunea autentificarii", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-error-page", url:"/tomcat/error-page", title:"Pagini de eroare", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-logs", url:"/tomcat/logs", title:"Loguri", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-optimization", url:"/tomcat/optimization", title:"Optimizari", subtitle:"", parent:"tomcat-index"},

        {id:"tomcat-tips-tricks", url:"/tomcat/tips-tricks", title:"Tips and Tricks", subtitle:"", parent:"tomcat-index"},

    ]

    static indexUrl = "/tomcat/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere in Tomcat
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;