import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class NamecheapNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-namecheap", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare domeniu cumparat de pe Namecheap cu Synlogoy NAS</b>
                    <br/>
                    <br/>
                    Se cumpara un nume de domeniu (DNS - Domain Name System) de pe: <a target={"_blank"} href={"https://www.namecheap.com/"}>Namecheap</a>

                    <br/>
                    Sa presupunem ca domeniul cumparat este: <b>ibrid.art</b> (pe 2 ani in jur de $13.34)

                    <br/>
                    <br/>
                    Din meniul din stanga se selecteaza: <b>Domain List</b>.
                    <br/>
                    Din lista de domenii, se gaseste <b>ibrid.art</b> si sa apasa pe butonul <b>Manage</b>.
                    <br/>
                    Se apasa pe <b>Advanced DNS</b>.
                    <br/>
                    Apoi din sectiunea <b>HOST RECORDS</b> se apasa pe <b>ADD NEW RECORD</b>:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nas-6/img.png'}/>
                    </div>

                    Aici se completeaza:
                    <ul>
                        <li>Type: A + Dynamic DNS Record</li>
                        <li>Host: @</li>
                        <li>Value: 86.127.153.177 (Adresa IP de aici nu contează, scriptul pe care îl vom configura o va actualiza)</li>
                    </ul>
                    <ul>
                        <li>CNAME Record</li>
                        <li>Host: @</li>
                        <li>ibrid.synology.me</li>
                    </ul>
                    <ul>
                        <li>CNAME Record</li>
                        <li>Host: www</li>
                        <li>ibrid.synology.me</li>
                    </ul>

                    Apoi din sectiunea <b>DYNAMIC DNS</b> se activeaza si retine parola <b>Dynamic DNS Password</b> (ex: 67d604435344cafa16a569f1ce7a0a2 )


                    <hr/>
                    <b>2. Configurari terminal NAS</b>
                    <br/>
                    <br/>

                    Conectat la nas:

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd /usr/local/bin/'}
                    </SyntaxHighlighter>

                    creare fisier <b>namecheap_ddns.sh</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo touch namecheap_ddns.sh'}
                    </SyntaxHighlighter>

                    editare fisier:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nano namecheap_ddns.sh'}
                    </SyntaxHighlighter>

                    se adauga urmatorul continut:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'#!/bin/bash\n' +
                            '\n' +
                            '## Namecheap DDNS updater for Synology\n' +
                            '## Brett Terpstra <https://brettterpstra.com>\n' +
                            '\n' +
                            'PASSWORD="$2"\n' +
                            'DOMAIN="$3"\n' +
                            'IP="$4"\n' +
                            '\n' +
                            'PARTS=$(echo $DOMAIN | awk \'BEGIN{FS="."} {print NF?NF-1:0}\')\n' +
                            '# If $DOMAIN has two parts (domain + tld), use wildcard for host\n' +
                            'if [[ $PARTS == 1 ]]; then\n' +
                            '    HOST=\'@\'\n' +
                            '    DOMAIN=$DOMAIN\n' +
                            '# If $DOMAIN has a subdomain, separate for HOST and DOMAIN variables\n' +
                            'elif [[ $PARTS == 2 ]]; then\n' +
                            '    HOST=${DOMAIN%%.*}\n' +
                            '    DOMAIN=${DOMAIN#*.}\n' +
                            'fi\n' +
                            '\n' +
                            'RES=$(curl -s "https://dynamicdns.park-your-domain.com/update?host=$HOST&domain=$DOMAIN&password=$PASSWORD&ip=$IP")\n' +
                            'ERR=$(echo $RES | sed -n "s/.*<ErrCount>\\(.*\\)<\\/ErrCount>.*/\\1/p")\n' +
                            '\n' +
                            'if [[ $ERR -gt 0 ]]; then\n' +
                            '    echo "badauth"\n' +
                            'else\n' +
                            '    echo "good"\n' +
                            'fi'}
                    </SyntaxHighlighter>
                    Apoi se salveaza cu <b>CTRL+O</b>, apoi ENTER, apoi se iese cu <b>CTRL+X</b>.

                    <br/>
                    se acorda drepturi de executie:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo chmod a+x /usr/local/bin/namecheap_ddns.sh'}
                    </SyntaxHighlighter>

                    apoi editam: <b>/etc.defaults/ddns_provider.conf</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd /etc.defaults/'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nano ddns_provider.conf'}
                    </SyntaxHighlighter>
                    se adauga la sfarsitul fisierului, urmatoarele linii:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' [Namecheap]\n' +
                            '                    modulepath=/usr/local/bin/namecheap_ddns.sh\n' +
                            '                    queryurl=https://namecheap.com\n' +
                            '                    website=https://namecheap.com'}
                    </SyntaxHighlighter>
                    Apoi se salveaza cu <b>CTRL+O</b>, apoi ENTER, apoi se iese cu <b>CTRL+X</b>.

                    <hr/>
                    <b>3. Configurari DSM </b>
                    <br/>
                    <br/>

                    Navigam: <b>Control Panel {"->"} External Access {"->"} DDNS {"->"} Add</b>. Se completeaza
                    <ul>
                        <li>
                            Service Provider: Namecheap
                        </li>
                        <li>
                            Hostname: ibrid.art
                        </li>
                        <li>
                            Password/Key: 67d604435344cafa16a569f1ce7a0a2
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Adaugare certificat pentru domeniu </b>
                    <br/>
                    <br/>
                    Navigam: <b>Control Panel {"->"} Security {"->"} Add</b>. Se completeaza:
                    <ul>
                        <li>
                            Add a new certicate {"->"} Next
                        </li>
                        <li>
                            Description: ibrid.art
                            <br/>
                            Ge a certificate form Let's Encrypt {"->"} Next
                        </li>
                        <li>
                            Domanin name:  ibrid.art
                            <br/>
                            Email: buzdugan.iulian@gmail.com
                            <br/>
                            Subject Alternative Name: ibrid.art
                            <br/>
                            Done
                        </li>
                    </ul>

                    Conectare SSH:
                    <ul>
                        <li>ssh admin@192.168.1.177 -p 22</li>
                        <li>ssh admin@ibrid.synology.me -p 22</li>
                        <li>ssh admin@ibrid.art -p 22</li>
                        <li>ssh admin@www.ibrid.art -p 22</li>
                    </ul>
                    Delogare:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'exit'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://brettterpstra.com/2021/08/26/custom-urls-for-your-synology-with-namecheap/"}>
                               Custom URLs for your Synology with Namecheap
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NamecheapNasContent;