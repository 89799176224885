import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class SetupIntelliJContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-setup-intellij", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}


                <div  className={"text-justify important"}>
                    <b>Resurse necesare</b>:
                    <ul>
                        <li>
                            Procesor (CPU):
                            <ul>
                                <li>
                                    Minim: 2 nuclee fizice sau virtuale
                                </li>
                                <li>
                                    Recomandat: 4 nuclee fizice sau virtuale sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Memorie (RAM):
                            <ul>
                                <li>
                                    Minim: 4 GB RAM
                                </li>
                                <li>
                                    Recomandat: 8 GB RAM sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Stocare/spatiu pe disk:
                            <ul>
                                <li>
                                    Minim: 10 GB spațiu liber pe disc
                                </li>
                                <li>
                                    Recomandat: 20 GB sau mai mult (în funcție de dimensiunea datelor și a conținutului)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className={"text-justify important"}>
                    <div><b>1. Despre plugin-ul Liferay IntelliJ</b></div>
                    Acest plugin oferă suport pentru dezvoltarea Liferay DXP în IntelliJ IDEA.
                    Acest plugin o vine cu următoarele funcționalități încorporate:
                    <ul>
                        <li>crearea unui spațiu de lucru Liferay (<b>Liferay Workspace</b>) bazat pe Gradle/Maven</li>
                        <li>crearea proiectelor Liferay (bazate pe Gradle/Maven)</li>
                        <li>suport pentru server-ul Liferay DXP Tomcat pentru deployment si depanare (debugging)</li>
                        <li>suport pentru adaugarea de marcatori de linie pentru fiecare entitate in editorul de servicii</li>
                        <li>verificare sintaxa evidentiere sintaxa, autocompletare cod (de exemplu, in fisiere bnd si fisiere xml)</li>
                    </ul>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>2. Instalare plugin Liferay IntelliJ</b></div>
                    Instalare via <i>IntelliJ Marketplace</i>:
                    <ol>
                        <li>Din IntelliJ, navigare: <i>File → Settings → Plugins</i></li>
                        <li>In tab-ul <b>Marketplace</b>, in bara de cautare se cauta Liferay</li>
                        <li>Se apasa pe butonu Install la <b>Liferay IntelliJ Plugin</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-1.png'}/>
                            </div>

                        </li>
                        <li>Restartare IntelliJ</li>
                    </ol>

                </div>

                <br/>

                <div className={"text-justify important"}>
                    <div><b>3. Creare <i>Liferay Workspace</i></b></div>

                    <ol>
                        <li>Din IntelliJ, navigare: <i>File → New → Project</i></li>
                        <li>Din ecranul din New Project, se selecteaza din meniu de stanga Liferay, iar din dreapta <b>Liferay Gradle Workspace</b>
                            <div style={{padding:10}}>
                                <img alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-2.png'}/>
                            </div>
                        </li>

                        <li>Apasare buton <i>Next</i></li>

                        <li>Creare spatiu de lucru (workspace). Completare detalii:

                            <ol>
                                <li>Project name: liferay-vanilla (sau orice alt nume dorit)</li>
                                <li>Product version: dxp-7.3-sp2</li>
                                <li>Project SDK : 1.8 java version (cu versiunea 11 sunt inca probleme)</li>
                            </ol>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-3.png'}/>
                            </div>

                        </li>
                        <li>Apasare buton <i>Finish</i></li>

                        <li>Structura <b>Liferay Workspace</b> generat:

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-4.png'}/>
                            </div>
                        </li>

                    </ol>

                    <hr/>
                    <div><b>Creare <i>Liferay Workspace</i></b> din linie de comanda</div>
                    <ul>
                        <li>se creeaza un director <i>liferay-vanilla-cmd</i>, care va reprezenta workspace-ul:
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'mkdir liferay-vanilla-cmd'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'cd liferay-vanilla-cmd'}
                            </SyntaxHighlighter>
                        </li>

                        <li>se creeaza fisierul <b>settings.gradle</b> cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'buildscript {\n' +
                                '        dependencies {\n' +
                                '                classpath group: "com.liferay", name: "com.liferay.gradle.plugins.workspace", version: "latest.release"\n' +
                                '        }\n' +
                                '\n' +
                                '        repositories {\n' +
                                '                mavenLocal()\n' +
                                '\n' +
                                '                maven {\n' +
                                '                        url "https://repository-cdn.liferay.com/nexus/content/groups/public"\n' +
                                '                }\n' +
                                '        }\n' +
                                '}\n' +
                                '\n' +
                                'apply plugin: "com.liferay.workspace"'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se genereaza/seteaza versiune de gradle (daca crapa ceva, atunci se poate sterge continutul din fisierul <b>settings.gradle</b>, se ruleaza comanda de mai jos, si apoi se pune in fisierul <b>setting.gradle</b> continutul de mai sus ):
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradle wrapper --gradle-version 6.6.1'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se creeaza fisierul <b>gradle.properties</b> cu urmatorul continut:
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'liferay.workspace.product=portal-7.4-ga8'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            pentru a descarca si dezarhiva serverul Liferay DXP in directorul <b>bundles</b>; acest director se creeaza in workspace-ul proiectului Liferay:
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradlew initBundle'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            pentru a descarca bundle Liferay Zip:
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradlew downloadBundle\n' +
                                'Download https://releases-cdn.liferay.com/portal/7.4.3.8-ga8/liferay-ce-portal-tomcat-7.4.3.8-ga8-20220117195955276.tar.gz'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                </div>
                <br/>

                <div className={"text-justify important"}>
                    <div><b>4. Configurare server</b></div>

                    Se poate folosi un server:

                    <ul>
                        <li>inclus in Liferay Workspace</li>
                        <li>separat de Liferay Workspace</li>
                    </ul>

                    <div><b>4.1. Server inclus in Liferay Workspace</b></div>

                    Se apasa click dreapta pe <b>Liferay Workspace</b> creat la pasul precedent ('liferay-vanilla'): <i>Liferay → InitBundle </i>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-5.png'}/>
                    </div>

                    Aceasta comanda (InitBundle) descarcă serverul <i>Liferay DXP</i> specificat în fișierul <b>gradle.properties</b> al spațiului dvs. de lucru prin intermediul proprietatilor:
                    <ul>
                        <li>
                            <b>liferay.workspace.bundle.url </b>
                            (<i>de unde</i> se descarca arhiva, de exemplu: https://api.liferay.com/downloads/portal//7.3.10-dxp-2/liferay-dxp-tomcat-7.3.10-dxp-2-slim-20210623003016661.tar.gz )
                        </li>
                        <li>
                            <b>liferay.workspace.home.dir </b>
                            (<i>unde</i> de descarca, implicit e directorul <b>bundles</b> al spatiului dvs. de lucru (Liferay Workspace) )
                        </li>
                    </ul>

                    <hr/>
                    Actiunea de mai sus se poate face si din linie de  comanda. Pentru a descarca si dezarhiva serverul Liferay DXP in directorul <b>bundles</b> (acest director se creeaza in workspace-ul proiectului Liferay) se ruleaza urmatoarea comanda <i>Gradle</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradlew initBundle'}
                    </SyntaxHighlighter>

                    <br/>
                    <div><b>4.2. Server separat de Liferay Workspace</b></div>

                    Cand avem deja instalat un serverul Liferay DXP, atunci putem specifica in fisierul <b>gradle.properties</b> al spațiului dvs. de lucru
                    prin intermediul proprietatii <b>liferay.workspace.home.dir </b>:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-6.png'}/>
                    </div>

                    <br/>
                    <div><b>4.3. Pornire server Liferay DXP standalone</b></div>

                    Cand avem deja instalat un server Liferay DXP, atunci putem merge in locatia unde este instalat:

                    <SyntaxHighlighter showLineNumbers={true} language="shell" style={androidstudio}>
                        {'cd [LIFERAY_HOME]\\tomcat-9.0.40\\bin\n' +
                        'catalina jpda start'}
                    </SyntaxHighlighter>

                    Daca cumva, la pornire se blocheaza sau se obtin erori, de genul ca nu gaseste un fisier undeva in:
                    <SyntaxHighlighter showLineNumbers={true} language="shell" style={androidstudio}>
                        {'[LIFERAY_HOME]\\osgi\\state'}
                    </SyntaxHighlighter>
                    atunci se poate sterge continutul acestui director.
                    <br/>
                    In caz de alte probleme se mai poate sterge continutul din directoarele:
                    <SyntaxHighlighter showLineNumbers={true} language="shell" style={androidstudio}>
                        {'[LIFERAY_HOME]\\work\n' +
                        '[LIFERAY_HOME]\\tomcat-9.0.40\\work'}
                    </SyntaxHighlighter>
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <div><b>5. Gestionare server</b></div>
                    Din IntelliJ se poate porni, opri si depana (debuging) serverul Liferay DXP.
                    Pentru a configura acest lucru:
                    <ol>
                        <li>se navigeaza pe meniu de sus de configurari: <i>Add Configuration</i>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-7.png'}/>
                            </div>
                        </li>
                        <li>se cauta Liferay Server, se selecteaza Liferay Server si se configureaza calea catre server:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-8.png'}/>
                            </div>
                        </li>

                        <li>se apasa <i>Apply</i> sau <i>OK</i></li>

                    </ol>

                    Serverul poate fi pornit / pornit cu debug si oprit:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-9.png'}/>
                    </div>

                    In <b>Terminal</b> din IntelliJ se afiseaza log-uri legate de pornirea server-ului:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-10.png'}/>
                    </div>

                    Din <b>browser</b>, in mod implicit serverul porneste pe portul 8080 si poate fi accesat local folosind urmatorul URL: <b>localhost:8080</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/setup-intellij-11.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360017885612-IntelliJ-IDEA"}>
                                    https://help.liferay.com/hc/en-us/articles/360017885612-IntelliJ-IDEA
                                </a>
                            </div>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/building-applications/tooling/liferay-workspace/creating-a-liferay-workspace.html"}>
                                    Creating A Liferay Workspace
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SetupIntelliJContent;