import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PublicServiceWebLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-build-service-web-public", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>JAX-RS publice</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1. Metoda prin configurare din interfata</b>
                    <br/>
                    <br/>
                    <b>Control Panel > Configuration > Service Access Policy</b>
                    <br/>
                    Apoi se apasa click pe <b>SYSTEM_DEFAULT</b> sau se editeaza.
                    <br/>
                    Se apasa pe butonul: <b>Switch to Advanced Mode</b>
                    <br/>
                    In sectiunea <b>Allow Service Signature</b> se adauga metoda noastra pe care o vrem publica, de exemplu:
                    <br/>
                    <i>ro.ibrid.app.config.rest.AppConfigRest#getAllAppConfig</i>
                    <br/>
                    <br/>
                    Sintaxa este:
                    <i>numeClasa#numeMetoda</i>

                    <hr/>
                    <b>2. Metoda programatica</b>
                    <br/>
                    <br/>

                    Se adauuga urmatoarele proprietati:
                    <SyntaxHighlighter>
                        {'"auth.verifier.guest.allowed=true",\n' +
                            '"liferay.access.control.disable=true"'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                            '  property = {\n' +
                            '     JaxrsWhiteboardConstants.JAX_RS_APPLICATION_BASE + "=/greetings",\n' +
                            '     JaxrsWhiteboardConstants.JAX_RS_NAME + "=Greetings.Rest",\n' +
                            '     "auth.verifier.guest.allowed=true",\n' +
                            '     "liferay.access.control.disable=true"\n' +
                            '  },\n' +
                            '  service = Application.class\n' +
                            ')\n' +
                            'public class JaxRSSampleAppApplication extends Application {\n' +
                            '//\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Alt exemplu (Mock):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.fasterxml.jackson.core.JsonProcessingException;\n' +
                            'import com.fasterxml.jackson.databind.ObjectMapper;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.jaxrs.whiteboard.JaxrsWhiteboardConstants;\n' +
                            '\n' +
                            'import javax.ws.rs.*;\n' +
                            'import javax.ws.rs.core.Application;\n' +
                            'import javax.ws.rs.core.MediaType;\n' +
                            'import java.util.Collections;\n' +
                            'import java.util.Set;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                JaxrsWhiteboardConstants.JAX_RS_APPLICATION_BASE + "=/mock",\n' +
                            '                JaxrsWhiteboardConstants.JAX_RS_NAME + "=Mock.Rest",\n' +
                            '                "auth.verifier.guest.allowed=true",\n' +
                            '                "liferay.access.control.disable=true"\n' +
                            '        }, service = Application.class)\n' +
                            'public class IntegrationMockService extends Application{\n' +
                            '\n' +
                            '   \n' +
                            '    public Set<Object> getSingletons() {\n' +
                            '        return Collections.<Object>singleton(this);\n' +
                            '    }\n' +
                            '\n' +
                            '    // http://localhost:8080/o/mock\n' +
                            '    @GET\n' +
                            '    @Path("")\n' +
                            '    @Produces(MediaType.TEXT_PLAIN)\n' +
                            '    public String getDetaliiNumarRegistruURL()  {\n' +
                            '        return "test";\n' +
                            '    }\n' +
                            '\n' +
                            '    /*\n' +
                            '        "registratura" + "/" + nrRegAn + "?idPartener=" + idPartener + "&userPartener=" + userPartener\n' +
                            '     */\n' +
                            '\n' +
                            '    // http://localhost:8080/o/mock/registratura/8000-2023?idPartener=a&userPartener=b\n' +
                            '    @GET\n' +
                            '    @Path("registratura/{nrRegAn}")\n' +
                            '    @Produces(MediaType.APPLICATION_JSON)\n' +
                            '    public DetaliiNumarRegistruSobisResponse getDetaliiNumarRegistruURL(\n' +
                            '            @PathParam("nrRegAn") String id,\n' +
                            '            @QueryParam("idPartener") String idPartener,\n' +
                            '            @QueryParam("userPartener") String userPartener\n' +
                            '    )  {\n' +
                            '\n' +
                            '        String jsonString = "{ ... }";\n' +
                            '\n' +
                            '        ObjectMapper objectMapper = new ObjectMapper();\n' +
                            '        DetaliiNumarRegistruSobisResponse response = null;\n' +
                            '        try {\n' +
                            '            response = objectMapper.readValue(jsonString, MyResponse.class);\n' +
                            '        } catch (JsonProcessingException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '\n' +
                            '        return response;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://lifedev-solutions.blogspot.com/2020/04/public-jax-rs-application-implementation.html"}>
                                Public JAX-RS Application Implementation
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default PublicServiceWebLiferayContent;