import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class MemoReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-memo", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Actualizare copii doar daca s-a actualizat props: momo</b>
                    <br/>
                    <br/>

                    Folosirea <b>memo</b> va face ca React să omite randarea unei componente dacă elementele sale de
                    recuzită nu s-au schimbat.
                    <br/>
                    Acest lucru poate îmbunătăți performanța.

                    <hr/>
                    <b>Problema</b>:
                    <br/>
                    <br/>

                    În acest exemplu, componenta Todos se redă din nou chiar și atunci când toate nu s-au schimbat.
                    <br/>
                    Componenta App se randeaza atunci cand se apasa pe buton.
                    <br/>
                    Pentru ca la apasarea butonului se schimba starea <i>count</i>.
                    <br/>
                    Se schimba starea, deci se randeaza componenta App cu toti copii lui (chiar daca nu e nevoie):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            'import ListaTodo from "./ListaTodo";\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '  const [lista, setLista] = useState(["De facut frumos", "De facut urat"]);\n' +
                            '\n' +
                            '  const increment = () => {\n' +
                            '    setCount((c) => c + 1);\n' +
                            '  };\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <ListaTodo lista={lista} />\n' +
                            '      <hr />\n' +
                            '      <div>\n' +
                            '        Count: {count}\n' +
                            '        <button onClick={increment}>+</button>\n' +
                            '      </div>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '};\n'}
                    </SyntaxHighlighter>

                    ListaTodo.js:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                        'const ListaTodo = ({ list }) => {\n' +
                        '  console.log("child render");\n' +
                        '  return (\n' +
                        '    <>\n' +
                        '      <h2>Lista mea</h2>\n' +
                        '      {list.map((item, index) => {\n' +
                        '        return <p key={index}>{item}</p>;\n' +
                        '      })}\n' +
                        '    </>\n' +
                        '  );\n' +
                        '};\n' +
                            'export default Todos'
                    }</SyntaxHighlighter>

                    <hr/>
                    <b>Solutia</b>:
                    <br/>
                    <br/>

                    Se modifica doar ListTodo.js:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const ListaTodo = ({ list }) => {\n' +
                            '  console.log("child render");\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h2>Lista mea</h2>\n' +
                            '      {list.map((item, index) => {\n' +
                            '        return <p key={index}>{item}</p>;\n' +
                            '      })}\n' +
                            '    </>\n' +
                            '  );\n' +
                            '};\n' +
                            'export default memo(Todos);'}
                    </SyntaxHighlighter>
                    adica doar :
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default memo(Todos);'}
                    </SyntaxHighlighter>

                    Acum, componenta <i>ListaTodo</i> se randeaza din nou atunci când props-ul <i>list</i> este actualizat.
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MemoReactContent;