import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CustomValidatorAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-custom-validator", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Validator custom: metoda statica</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class RegisterValidators{\n' +
                            '\n' +
                            '\tstatic match(group: AbstractControl): ValidationErrors | null {\n' +
                            '\t\n' +
                            '\t\tconst passwordControl = group.get(\'password\');\n' +
                            '\t\tconst confirmPasswordControl = group.get(\'confirmPassword\');\n' +
                            '\t\t\n' +
                            '\t\tif (!passwordControl || !confirmPasswordControl){\n' +
                            '\t\t\treturn {\n' +
                            '\t\t\t\tcontrolNotFound: false\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tconst error = passwordControl.value ==  confirmPasswordControl.value ? null: {noMatch:true}\n' +
                            '\t\n' +
                            '\t\treturn error;\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'registrationForm = new FormGroup({\n' +
                            '\temail: ...\n' +
                            '\tpassword: ...\n' +
                            '\tconfirmPassword: ...\n' +
                            '}, [\n' +
                            'RegisterValidators.match]) '}
                    </SyntaxHighlighter>

                    Se returneaza <b>null</b> daca password si confirmPassword sunt identice!

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<p *ngIf="control.errors?.noMatch">Parolele nu se potrivesc</p>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Validator custom:ValidatorFn  </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export class RegisterValidators{\n' +
                            '\n' +
                            '\tstatic match(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn {\n' +
                            '\t\n' +
                            '\t\treturn (group: AbstractControl): ValidationErrors | null => {\n' +
                            '\t\t\tconst passwordControl = group.get(passwordControlName);\n' +
                            '\t\t\tconst confirmPasswordControl = group.get(confirmPasswordControlName);\n' +
                            '\t\t\t\n' +
                            '\t\t\tif (!passwordControl || !confirmPasswordControl){\n' +
                            '\t\t\t\treturn {\n' +
                            '\t\t\t\t\tcontrolNotFound: false\n' +
                            '\t\t\t\t}\n' +
                            '\t\t\t}\n' +
                            '\t\t\t\n' +
                            '\t\t\tconst error = passwordControl.value ==  confirmPasswordControl.value ? null: {noMatch:true}\n' +
                            '\t\t\n' +
                            '\t\t\tconfirmPasswordControl.setErrors(error); // pun eroare si pe control\n' +
                            '\t\t\n' +
                            '\t\t\treturn error;\n' +
                            '\t\t\t\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'registrationForm = new FormGroup({\n' +
                            '\temail: ...\n' +
                            '\tpassword: ...\n' +
                            '\tconfirmPassword: ...\n' +
                            '}, [\n' +
                            'RegisterValidators.match(\'password\',\'confirmPassword\')]) '}
                    </SyntaxHighlighter>

                    Pentru afisare in template:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<p *ngIf="control.errors?.noMatch">Parolele nu se potrivesc</p>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alt exemplu:
                    <br/>

                    Se returneaza <b>null</b> daca parola este valida!
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {AbstractControl, ValidationErrors, ValidatorFn} from \'@angular/forms\';\n' +
                            '\n' +
                            'export function createPasswordStrengthValidator(): ValidatorFn {\n' +
                            '    return (control:AbstractControl) : ValidationErrors | null => {\n' +
                            '\n' +
                            '        const value = control.value;\n' +
                            '\n' +
                            '        if (!value) {\n' +
                            '            return null;\n' +
                            '        }\n' +
                            '\n' +
                            '        const hasUpperCase = /[A-Z]+/.test(value);\n' +
                            '\n' +
                            '        const hasLowerCase = /[a-z]+/.test(value);\n' +
                            '\n' +
                            '        const hasNumeric = /[0-9]+/.test(value);\n' +
                            '\n' +
                            '        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;\n' +
                            '\n' +
                            '        return !passwordValid ? {passwordStrength:true}: null;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Validare asincrona</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'emailInterzis(control: FormControl): Promise<any> | Observable<any>{\n' +
                            '\n' +
                            '\tconst promise = new Promise<any>(\n' +
                            '\t\t(resolve, reject)=>{\n' +
                            '\t\t\tsetTimeout(()=>{\n' +
                            '\t\t\t\tif (control.value=\'test@test.ro\'){\n' +
                            '\t\t\t\t\tresolve( {\'emailEsteInterzis\': true})\n' +
                            '\t\t\t\t}else{\n' +
                            '\t\t\t\t\tresolve( null ); // daca validarea e ok, trebuie sa se returneze null !!!\n' +
                            '\t\t\t\t}\n' +
                            '\t\t\t},1500);\n' +
                            '\t\t}\n' +
                            '\t)\n' +
                            '\n' +
                            '\treturn promise; \n' +
                            '\t\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Injectable } from \'@angular/core\';\n' +
                            'import { AsyncValidator, AbstractControl, ValidationErrors } from \'@angular/core\';\n' +
                            '\n' +
                            '@Injectable({providedIn:\'root\'}) // pentru ca sigur e nevoie de un serviciu, de exemplu: EmailService\n' +
                            'export class EmailValidator implements AsyncValidator{\n' +
                            '\n' +
                            '\tconstructor(private service: EmailService){}\n' +
                            '\n' +
                            '\tvalidate = (control: AbstractControl): Promise<ValidationErrors | null> => { // se foloste => ca sa nu fie dependent de context (this)\n' +
                            '\t\n' +
                            '\t\treturn this.service.existEmail(control.value).then(\n' +
                            '\t\t\tresponse => response.length? {emailTaken: true}: null\n' +
                            '\t\t)\n' +
                            '\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Adaugare validare pe camp
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'email = new FormControl(\'\',[\n' +
                            '\tValidators.required,\n' +
                            '\tValidators.email\n' +
                            '],\n' +
                            '[\n' +
                            '\tthis.emailValidator.validate // emailValidator e injectat ca si cum ar fi serviciu\n' +
                            ']);'}
                    </SyntaxHighlighter>

                    Mesaj de validare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<p *ngIf="control.errors?.emailTaken">Email-ul este luat! Incercati alt email</p>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://dev.to/revanth_oleti/difference-between-subject-and-behaviorsubject-9g6"}>
                               Difference between Subject and BehaviorSubject
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomValidatorAngularContent;