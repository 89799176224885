import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";

class PickTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-pick", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Pick</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'Omit<Type, Keys>'}
                    </SyntaxHighlighter>

                    Folosind <b>Omit</b>, putem spune <b>TypeScript</b>  toate proprietatile din Type, dar fara Keys.
                    <br/>

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'interface Todo {\n' +
                            '  title: string;\n' +
                            '  description: string;\n' +
                            '  completed: boolean;\n' +
                            '  createdAt: number;\n' +
                            '}\n' +
                            ' \n' +
                            'type TodoPreview = Omit<Todo, "description">;\n' +
                            ' \n' +
                            'const todo: TodoPreview = {\n' +
                            '  title: "Clean room",\n' +
                            '  completed: false,\n' +
                            '  createdAt: 1615544252770,\n' +
                            '};\n' +
                            ' \n' +
                            'todo;\n' +
                            ' \n' +
                            'const todo: TodoPreview\n' +
                            ' \n' +
                            'type TodoInfo = Omit<Todo, "completed" | "createdAt">;\n' +
                            ' \n' +
                            'const todoInfo: TodoInfo = {\n' +
                            '  title: "Pick up kids",\n' +
                            '  description: "Kindergarten closes at 5pm",\n' +
                            '};\n' +
                            ' \n' +
                            'todoInfo;'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PickTypescriptContent;