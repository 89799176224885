import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";

class DecoratorsTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-decorators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Decoratori</b>
                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'function MenuItem(value){\n' +
                            '    return class extends value{\n' +
                            '        id: "abc";\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            '@MenuItem\n' +
                            'class Pizza{\n' +
                            '    id:string;\n' +
                            '}\n' +
                            '\n' +
                            'class Hamburger{\n' +
                            '    id:string;\n' +
                            '}\n' +
                            '\n' +
                            'console.log(new Pizza().id); // va afisa "abc"'}
                    </SyntaxHighlighter>

                    Decorator cu parametri:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'function MenuItem(itemID: string) {\n' +
                            '\n' +
                            '    return function (value) {\n' +
                            '\n' +
                            '\n' +
                            '        return class extends value {\n' +
                            '            id = itemID;\n' +
                            '        }\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            '@MenuItem("Pizza")\n' +
                            'class Pizza {\n' +
                            '    id: string;\n' +
                            '}\n' +
                            '\n' +
                            'class Hamburger {\n' +
                            '    id: string;\n' +
                            '}\n' +
                            '\n' +
                            'console.log(new Pizza().id); // va Pizza'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DecoratorsTypescriptContent;