import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InterfacesGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-interfaces", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Interfete</b>
                    <br/>
                    <br/>

                    Im Go nu pot exista in acelasi fisier 2 functii cu accelasi nume!

                    <SyntaxHighlighter>
                        {'type geometry interface {\n' +
                            '    area() float64\n' +
                            '    perim() float64\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'type rect struct {\n' +
                            '    width, height float64\n' +
                            '}\n' +
                            'type circle struct {\n' +
                            '    radius float64\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'func (r rect) area() float64 {\n' +
                            '    return r.width * r.height\n' +
                            '}\n' +
                            'func (r rect) perim() float64 {\n' +
                            '    return 2*r.width + 2*r.height\n' +
                            '}\n' +
                            '\n' +
                            'func (c circle) area() float64 {\n' +
                            '    return math.Pi * c.radius * c.radius\n' +
                            '}\n' +
                            'func (c circle) perim() float64 {\n' +
                            '    return 2 * math.Pi * c.radius\n' +
                            '}'}
                    </SyntaxHighlighter>
                    In acest moment, <i>rect</i> si <i>cicle</i> implmenteaza interfata <i>geometry</i>.
                    <br/>
                    De ce? Pentru ca <i>rect</i> si <i>cicle</i>, au metodele asociate <i>area()</i> si <i>perim()</i>.
                    <br/>
                    Si pentru ca aceste metode <i>definesc</i> o <i>geometry</i> devin ele insele <i>geometry</i>.
                    Sau altfel spus, cele 2 structuri <i>rect</i> si <i>cicle</i> au metode asociate care se regasesc in interfata <i>geometry</i>

                    <SyntaxHighlighter>
                        {'func measure(g geometry) {\n' +
                            '    fmt.Println(g)\n' +
                            '    fmt.Println(g.area())\n' +
                            '    fmt.Println(g.perim())\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'func main() {\n' +
                            '    r := rect{width: 3, height: 4}\n' +
                            '    c := circle{radius: 5}\n' +

                            '\n' +
                            '    measure(r)\n' +
                            '    measure(c)\n' +
                            '}'}
                    </SyntaxHighlighter>

                    O interfata poate fi compuse din mai multe interfete:
                    <SyntaxHighlighter>
                        {'type InterfataMare interface {\n' +
                            '\tInterfata1\n' +
                            '\tInterfata2\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    A spune că un tip satisface o interfață înseamnă că tipul implementeaza toate functiile pe care le contine definitia interfetei.


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InterfacesGoContent;