import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class OsgiLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-osgi", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. OSGi</b>
                    <br/>
                    <br/>

                    <b>OSGi</b> (Open Services Gateway initiative) - permitea crearea si gestionarea modulara a componentelor Java, numite <b>bundle</b>-uri, care pot fi deploy-ate intr-un container.
                    <br/>
                    Un bundle este o funcționalitate care are un ciclu de viață independent - ceea ce inseaman ca poate fi pornit/oprit/eliminat indepedent.
                    <br/>
                    Un bundle OSGi este un JAR care contine:
                    <ul>
                        <li>clasele java</li>
                        <li>fisierul <b>MANIFEST.MF</b> (in <b>META-INF</b>) care defineste, prin intermediul unor antente:
                            <ul>
                                <li>numele claselor care sunt exportate (<b>Export-Package</b>): defineste pachete expuse de catre acest bundle</li>
                                <li>numele claselor care sunt importate (<b>Import-Package</b>): daca exista vreo dependinta care nu poate fi satifacuta, atunci bundle-ul nu va porni</li>
                                <li>versiune bundle (<b>Bundle-Version</b>)</li>
                                <li>nume bundle (<b>Bundle-Name</b>)</li>
                                <li>classpath-ul pentru librarii folosite de bundle(<b>Bundle-ClassPath</b>)</li>
                                <li>numele simbolic pentru bundle (<b>Bundle-SymbolicName</b>)</li>
                            </ul>

                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'Manifest-Version: 1.0\n' +
                                'Bnd-LastModified: 1636206567439\n' +
                                'Bundle-ManifestVersion: 2\n' +
                                'Bundle-Name: Keycloak Logout Filter\n' +
                                'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.common.keyclo\n' +
                                ' ak.logout.filter\n' +
                                'Bundle-Version: 1.0.0\n' +
                                'Created-By: 1.8.0_301 (Oracle Corporation)\n' +
                                'Import-Package: com.liferay.portal.kernel.events,com.liferay.portal.ke\n' +
                                ' rnel.json,com.liferay.portal.kernel.log,com.liferay.portal.kernel.uti\n' +
                                ' l,com.liferay.portal.security.sso.openid.connect,javax.portlet,javax.\n' +
                                ' servlet.http\n' +
                                'Javac-Debug: on\n' +
                                'Javac-Deprecation: off\n' +
                                'Javac-Encoding: Cp1252\n' +
                                'Private-Package: ro.letyournailsgrow.liferay.vanilla.common.keycloak.l\n' +
                                ' ogout.filter\n' +
                                'Provide-Capability: osgi.service;objectClass:List<String>="com.liferay\n' +
                                ' .portal.kernel.events.LifecycleAction";uses:="com.liferay.portal.kern\n' +
                                ' el.events"\n' +
                                'Require-Capability: osgi.extender;filter:="(&(osgi.extender=osgi.compo\n' +
                                ' nent)(version>=1.3.0)(!(version>=2.0.0)))",osgi.service;filter:="(obj\n' +
                                ' ectClass=com.liferay.portal.kernel.util.Portal)";effective:=active,os\n' +
                                ' gi.service;filter:="(objectClass=com.liferay.portal.kernel.util.Prefs\n' +
                                ' Props)";effective:=active,osgi.service;filter:="(objectClass=com.life\n' +
                                ' ray.portal.security.sso.openid.connect.OpenIdConnectProviderRegistry)\n' +
                                ' ";effective:=active,osgi.ee;filter:="(&(osgi.ee=JavaSE)(version=1.8))\n' +
                                ' "\n' +
                                'Service-Component: OSGI-INF/ro.letyournailsgrow.liferay.vanilla.common\n' +
                                ' .keycloak.logout.filter.KeycloakLogoutPostAction.xml\n' +
                                'Tool: Bnd-4.3.0.201909301554'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Platforma OSGi oferă:
                    <ul>
                        <li>o modalitate de a primi notificări despre pachetele care devin <i>disponibile</i> sau când sunt <i>eliminate</i> de pe platformă</li>
                    </ul>

                    Un bundle trebuie să declare în mod explicit la ce pachete trebuie să aibă acces și platforma OSGi îl va porni numai dacă dependențele sunt disponibile în pachetul în sine sau în alte pachete deja instalate în platformă.

                    <br/>
                    Un bundle ruleaza in interiorul unui container OSGi ca si client, dar este de asemenea si o compononeta server.

                    <hr/>
                    <b>2. Starile unui bundle OSGi</b>
                    <br/>
                    <br/>
                    Starile unui bundle OSGi:
                    <ul>
                        <li><b>installed</b>: dupa ce se da actiunea <i>install</i> sau se face actiunea <i>update refresh</i> (din starea <i>installed</i> sau starea <i>resolved</i>)</li>
                        <li><b>resolved</b>: dupa ce ce se iese din starea de <i>installed</i> cu actiunea de <i>resolve</i> sau dupa ce se iese din starea <i>stopping</i>
                            <br/>
                            aici se rezolva toate dependintele de care are nevoie bundle-ul; daca nu se pot rezolva toate dependintele, bundle-ul nu va porni
                        </li>
                        <li><b>starting</b>: dupa ce se se iese din starea <i>resolved</i> cu actiunea de <i>start</i></li>
                        <li><b>active</b>: dupa ce se iese din starea <i>starting</i></li>
                        <li><b>stopping</b>: dupa ce se iese din starea de <i>starting</i> cu actiunea <i>stop</i> sau din starea <i>active</i></li>
                        <li><b>uninstalled</b>: dupa ce se iese din starea<i>installed</i> sau starea <i>resolved</i> cu actiunea  <i>uninstall</i></li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default OsgiLiferayContent;