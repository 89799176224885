import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ErrorsJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-errors", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Tipuri de erori</b>
                    <br/>
                    <br/>
                    Tipuri de erori:
                    <ul>
                        <li><b>erori de sintaxa</b>: nu respecta sintaxa limbajului de programare (de exemplu: există greșeli de scriere în cuvintele cheie, paranteze sau paranteze nepotrivite)
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'(a b) => a + b; // Unexpected token, expected "," (1:3)'}
                            </SyntaxHighlighter>
                            Corect: (a, b) {"=>"} a + b; (lipsese o virgula)
                            <br/>
                            Eroarea de sintaxa este detectată de JavaScript, care nu permite executarea programului.
                            <br/>
                            Alt exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'for (true) { //-> Uncaught SyntaxError: Unexpected token \')\'\n' +
                                '    console.log("true");\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>erori semantice</b>:
                            <ul>
                                <li><b>ReferenceError (eroare de referinta)</b>:
                                    apare atunci când încercăm să accesăm o funcție sau o variabilă care nu există:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'let a = b; // Uncaught ReferenceError: b is not defined'}
                                    </SyntaxHighlighter>
                                    erorile de referinta sunt aruncate la momentul execuției programului, când se ajunge la instrucțiunea greșită (în JavaScript, erorile semantice sunt erori de rulare).
                                    Alt exemplu:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'functie(); // Uncaught ReferenceError: functie is not defined'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    <b>TypeError (eroare de tip)</b>:
                                    apare atunci când o anumită valoare nu este de tipul așteptat (se încerca efectuarea unei operație pe ea care nu este acceptata).
                                    <br/>
                                    De exemplu:
                                    <ul>
                                        <li>schimbarea valorii constante</li>
                                        <li>verificarea lungimii unei variabile care nu este un șir</li>
                                    </ul>
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'const a = 1;\n' +
                                        'a=2; // error: Uncaught TypeError: Assignment to constant variable.'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'const a = 1;\n' +
                                        'console.log(a.len()); // error: Uncaught TypeError: a.len is not a function'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    <b>RangeError (eroare de interval)</b>:
                                    generat atunci când se trimite o valoare unei funcții care se află în afara intervalului său acceptabil:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'let vector = Array(-1); // -> Uncaught RangeError: Invalid array length'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    alte erori: EvalError, InternalError, and URIError
                                </li>
                            </ul>

                            <br/>
                        </li>
                        <li><b>erori logice</b>: se executa instructiunea, dar resultatul este gresit
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let produs = (a, b) => a + b;  '}
                            </SyntaxHighlighter>
                            corect a fi: let produs = (a, b) {"=>"} a * b;
                        </li>
                    </ul>

                    Când se detectează erori sintactice sau semantice, se generează și aruncă obiecte specifice care conțin informații despre eroarea întâlnită. Spunem ca s-a <i>aruncat o eroare</i>.
                    <br/>
                    În cazul erorilor de sintaxă, motorul JavaScript nu ne permite să rulăm programul, iar pe consolă, primim informații despre ceea ce este incorect.
                    <br/>
                    Erorile, altele decât erorile de sintaxă (de exemplu, erorile semantice) sunt în general numite <b>erori de rulare</b> în JavaScript (apar în timp ce programul rulează).
                    Le putem numi și <b>excepții</b>.
                    În mod implicit, excepțiile aruncate întrerup execuția programului și fac ca informațiile corespunzătoare să apară pe consolă.

                    <hr/>
                    <b>2. Instructiunea try...catch</b>
                    <br/>
                    <br/>
                    Pentru a gestiona excepțiile generate se foloste instrucțiunea <b>try ... catch</b>.
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'try{\n' +
                        '  // bloc instructiuni (care poate aruca o exceptie)\n' +
                        '}catch(error){\n' +
                        '  // bloc gestionare eroare\n' +
                        '}finally{\n' +
                        '  // bloc finally (optional) - executat mereu\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>dacă se aruncă o excepție în blocul de cod după cuvântul cheie try, programul nu se întrerupe complet, ci sare la partea de cod după cuvântul cheie catch și continuă de acolo.</li>
                        <li>cuvântul cheie catch este urmat de paranteze care conține o variabila (<i>error</i> sau alt nume valid de variabila);
                            aceasta variabilă va conține informații despre tipul de eroare și este convertit într-un șir
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'try {\n' +
                                '    let a = b;\n' +
                                '} catch (error) {\n' +
                                '    console.log("Caught " + error); // -> Caught ReferenceError: b is not defined\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>putem gestiona o eroare dupa tipul acesteia folosind operatorul <b>instanceof</b>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'try {\n' +
                                '    a = b;\n' +
                                '} catch (error) {\n' +
                                '    if (error instanceof ReferenceError) {\n' +
                                '        console.log("Reference error"); // Reference error\n' +
                                '    } else {\n' +
                                '        console.log("alta eroare - " + error);\n' +
                                '    }\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            orice variabilă care este declarată folosind cuvântul cheie let în interiorul unui bloc try nu este accesibilă în blocul catch sau finally
                        </li>
                        <li>bloc-ul <i>finally</i> este optional si daca exista se executa intotdeauna</li>
                        <li>daca exista bloc-ul finally, poate lipsi blocul <i>catch</i>

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'try{\n' +
                                '  // bloc instructiuni (care poate aruca o exceptie)\n' +
                                '}finally{\n' +
                                '  // bloc finally - executat mereu\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            blocul <i>finally</i> poate fi folosit și împreună cu blocul <i>catch</i>, deoarece ambele sunt opționale, dar cel puțin unul dintre ele este cerut de instrucțiunea <i>try</i>, iar dacă niciunul nu este prezent, este aruncat o SyntaxError.
                        </li>
                        <li>
                            blocul <i>finally</i> va fi executat chiar și atunci când o eroare este aruncată din blocul catch
                        </li>
                    </ul>

                    În JavaScript, nu toate situațiile eronate aruncă excepții (de exemplu <b>erorile aritmetice</b>).
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'console.log(10 / 0); // -> Infinity\n' +
                        'console.log(10 * "2"); // -> 200\n' +
                        'console.log(10 * "abc"); // -> NaN'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Instructiunea throw</b>
                    <br/>
                    <br/>

                    Se poate arunca explicit o eroare folosind instructiunea throw urmata de o valoare care poate fi tratata ca o exceptie (de exemplu: RangeError, sir de caractere, numar):

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'try {\n' +
                        '    throw "100eroare";\n' +
                        '} catch (error) {\n' +
                        '    console.log(error); // -> 100eroare\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'try{\n' +
                        '   throw new RangeError("eroare de tip RangeError");\n' +
                        '}catch(err){\n' +
                        '  console.log(err)\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Crearea unui obiect in JavaScript se face folosind operatorul <b>new</b>.

                    <hr/>
                    <b>Extra:</b>
                    <br/>
                    Metoda <b>Number.isFinite()</b> determină dacă valoarea transmisă este un număr finit - adică verifică dacă tipul unei valori date este Number, iar numărul nu este Infinity, nici -Infinity, nici NaN.
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ErrorsJavaScriptContent;