import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

const Parent = () => {
    const handleChildClicked = () => {
        alert('click de la Child');
    }
    return (
        <Child onClick={handleChildClicked} />
    )
}

const Child = ({onClick}) => {
    return (
        <button onClick={onClick}>Apasa!</button>
    );
};

class UpdateChildParentAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-update-child-parent", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Anuntare parinte in urma unui eveniment al copilului</b>
                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Component({\n' +
                            '    selector : \'child\',\n' +
                            '    template : `\n' +
                            '        <button (click)="sendNotification()">Notify my parent!</button>\n' +
                            '    `\n' +
                            '})\n' +
                            'class Child {\n' +
                            '    @Output() notifyParent: EventEmitter<any> = new EventEmitter();\n' +
                            '    sendNotification() {\n' +
                            '        this.notifyParent.emit(\'Some value to send to the parent\');\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            '@Component({\n' +
                            '    selector : \'parent\',\n' +
                            '    template : `\n' +
                            '        <child (notifyParent)="getNotification($event)"></child>\n' +
                            '    `\n' +
                            '})\n' +
                            'class Parent {\n' +
                            '    getNotification(date) {\n' +
                            '        // in date, sunte datele trimise de la copil\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In clasa copil exista un obiect de tip <b>EventEmitter</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Output() notifyParent: EventEmitter<any> = new EventEmitter();'}
                    </SyntaxHighlighter>
                    Pentru a trimite date catre parinte pe apeleaza metoda <b>emit()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'this.notifyParent.emit(\'Some value to send to the parent\');'}
                    </SyntaxHighlighter>

                    In clasa parinte, va exista o metoda, care va prelua datele trimise de la copil, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'getNotification(date) {\n' +
                            '        // in date, sunt datele trimise de la copil\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a lega, obiectul eveniment de tip <b>EventEmitter</b> de metoda care pria datele trimise de la copil:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {' <child (notifyParent)="getNotification($event)"></child>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alias:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Output("notify") notifyParent: EventEmitter<any> = new EventEmitter();'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {' <child (notify)="getNotification($event)"></child>'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const Parent = () => {\n' +
                            '    const handleChildClicked = () => {\n' +
                            '        alert(\'click de la Child\');\n' +
                            '    }\n' +
                            '    return (\n' +
                            '       <Child onClick={handleChildClicked} />\n' +
                            '    )\n' +
                            '}\n' +
                            '\n' +
                            'const Child = ({onClick}) => {\n' +
                            '    return (\n' +
                            '       <button onClick={onClick}>Apasa!</button>\n' +
                            '    );\n' +
                            '};'}
                    </SyntaxHighlighter>
                    <Parent/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UpdateChildParentAngularContent;