import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Tips3LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-tips3", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    1. Asset Framework:
                    <ul>
                        <li>face posibila publicarea si gestionarea diferitelor tipuri de continut intr-o forma unificata prin intemediul unui API standard</li>
                        <li>ofera o modalitatea de a asocia si legata oricarui tip de continut cu alte portlet asset, tag-uri si categorii</li>
                        <li>ofera o modalitatea de a integra continut, de exemplu, cu platforme de cautaure sau workflows</li>
                    </ul>

                    <hr/>
                    2. Factory-ul <b>Asset Render</b> este o componentă care oferă un Asset Renderer pentru API-ul aplicației care apelează și oferă o facilitate pentru a crea mai multe randări pentru un tip de asset.

                    <hr/>
                    3. Liferay Assets si resursele vă permit să adăugați câmpuri noi la formularele lor de editare:
                    <ul>
                        <li>User</li>
                        <li>User Group</li>
                        <li>Web Content Article</li>
                        <li>Web Content Folder</li>
                        <li>Wiki page</li>
                    </ul>

                    <hr/>
                    4. Atributele <b>target</b> permit filtrarea serviciilor implementate ce pot fi injectate.

                    <hr/>
                    5. Când se utilizează opțiunea MULTIPLE în cardinalitatea de referință (reference cardinality), trebuie definită o metodă de setare.

                    <hr/>
                    6. Evenimentele ciclului de viață al portalului vă permit să executați acțiuni pe:
                    <ul>
                        <li>pornire/oprire portal</li>
                        <li>apel service Servlet</li>
                        <li>login/logout</li>
                        <li>layout (page) update</li>
                    </ul>

                    <hr/>
                    7. Evenimentele de pornire și oprire apelate o dată la pornirea sau închiderea portalului.

                    <hr/>
                    8. Evenimentele de conectare (Login Events) permit prinderea unui eveniment pre-login, post-login și deconectare.

                    <hr/>
                    9. Liferay Service Builder folosește Message Bus cu două adnotări:
                    <ul>
                        <li><b>@Async</b></li>
                        <li><b>@Clusterable</b></li>
                    </ul>

                    <hr/>
                    10. Liferay Search Framework:
                    <ul>
                        <li>Liferay DXP foloseste un motor de cautare extern</li>
                        <li>motorul de cautare implicit este ElasticSearch</li>
                    </ul>

                    <hr/>
                    11. Postprocesoarele de indexare (Indexer post processors) sunt componente care vă permit să modificați modul în care sunt indexate asset-urile portalului.

                    <hr/>
                    12. API-urile pentru servicii web sunt generate automat pentru entitățile care au remote-service=true.

                    <hr/>
                    13. API-ul SOAP nu este generat implicit.

                    <hr/>
                    14. FALS: Service Builder este un instrument de generare de cod bazat pe model care preia un fișier de intrare cfg și generează codul automat.

                    <hr/>
                    15. Beneficii MVC command:
                    <ul>
                        <li>reduce code boilercode</li>
                        <li>gestioneaza interactiunea cu portletul</li>
                        <li>se inregistreaza la un portlet cu proprietatea componentei <b>javax.portlet.name</b></li>
                        <li>se inregistreaza la numele componentei cu proprietatea: <b>mvc.command.name</b></li>
                    </ul>

                    <hr/>
                    16. Referința trebuie să fie satisfăcută (disponibilă și injectată) pentru ca componenta să pornească implicit folosind:<b>MANDATOTY</b>.

                    <hr/>
                    17. Permisiuni:
                    <ul>
                        <li>acordate la un rol, nu la un utilizator</li>
                        <li>defineste o actiune ce poate fi efectuata pe o resursa</li>
                        <li>combinatie intre o actiune si o resursa</li>
                    </ul>

                    <hr/>
                    18. Asset este o reprezentare abstractă, generică a oricărui model, acționează ca un înveliș și oferă câteva metadate în ceea ce privește conținutul în sine.

                    <hr/>
                    19. Pasi pentru a supracrie comenzi MVC:
                    <ul>
                        <li>localizare bundle cu portletul tinta si numele bundle</li>
                        <li>gasire clasa MVC Command pentru a o suprascrie</li>
                        <li>localizare parametru <b>mvc.command.name</b></li>
                        <li>implementare componenta care suprascrie, cu un ranking de servciu mai mare</li>
                    </ul>

                    <hr/>
                    20. Cea mai bună practică pentru a înlocui proprietățile portalului este să utilizați: <b>portal-ext.properties</b>.

                    <hr/>
                    21. Starile unei ferestre portlet:
                    <ul>
                        <li>Normal</li>
                        <li>Maximized</li>
                        <li>Minimized</li>
                    </ul>

                    <hr/>
                    22. Reference cardinality:
                    <ul>
                        <li>MANDATORY (implicita): Referința trebuie să fie disponibilă și injectată înainte ca această componentă să pornească.</li>
                        <li>OPTIONAL: Referința nu este necesară pentru ca componenta să pornească și va funcționa cu alocare de componente.</li>
                        <li>MULTIPLE: Resurse multiple pot satisface referința și componenta le va prelua pe toate</li>
                        <li>AT_LEAST_ONE: - Resurse multiple pot satisface referința și componenta le va prelua pe toate, dar cel puțin una este obligatorie pentru ca componenta să pornească.</li>
                    </ul>

                    Mai multe: <a href={"https://liferay.dev/blogs/-/blogs/liferay-osgi-annotations-what-they-are-and-when-to-use-them"}>Liferay/OSGi Annotations - What they are and when to use them</a>

                    <hr/>
                    23. Message Bus System este alcătuit din următoarele:
                    <ul>
                        <li>destinatii</li>
                        <li>ascultatori</li>
                        <li>expeditori (senders)</li>
                    </ul>

                    <hr/>
                    24. FALS: Trimiterea sincronă are două moduri de funcționare: implicit care livrează mesajul în același fir de execuție și îl blochează până când primește un răspuns și Directs care livrează mesajul într-un fir separat cu un timeout.

                    <hr/>
                    25. FALS: Ascultătorii de evenimente ciclului de viață al portalului sunt componente OSGi care implementează interfața LifecycleAction, iar evenimentul de prins este definit cu proprietatea componenta Event.

                    <hr/>
                    26. Metode de comunicare non-standard:
                    <ul>
                        <li>Portlet sessions</li>
                        <li>Client-Side IPC</li>
                    </ul>

                    Metode de comunicare standard:
                    <ul>
                        <li>Events</li>
                        <li>Public render parameters</li>
                    </ul>

                    <hr/>
                    27. MVC Action:
                    <ul>
                        <li>actionate de obicei de un submit de forma</li>
                        <li>poate actiona evenimente din alti portleti</li>
                        <li>urmat intodeauna de faza de randare</li>
                        <li>metoda standard: processAction()</li>
                        <li>obiecte: ActionRequest - ActionResponse</li>
                    </ul>

                    <hr/>
                    28. Service action Events se executa la fiecare cerere, inclusiv cele pentru resursa statică.

                    <hr/>
                    29. Evenimentele de layout sunt declanșate atunci când un layout (pagină) este actualizat sau șters.

                    <hr/>
                    30. Adaptor de server Tomcat livrat cu studioul de dezvoltare (DXP și comunitate).

                    <hr/>
                    31. FALS: Provide Capability – Required Capability este o metodă de partajare a pachetelor între pachete.
                    <br/> Provide Capability – Required Capability este o modalitate de a partaja servicii.

                    <hr/>
                    32. OSGi Bundle este:
                    <ul>
                        <li>unitate de dezvolitare in OSGi</li>
                        <li>un fisier jar cu headere OSGi</li>
                        <li>de sine statator (sefl-contained)</li>
                        <li>gestionalil, testabil</li>
                    </ul>

                    <hr/>
                    33. Parametrii ActionRequest nu sunt disponibili automat în fazele de randare, dar pot fi transmiși cu un obiect ActionResponse.

                    <hr/>
                    34. FALS: Starea ferestrei poate fi setată numai în faza de acțiune.

                    <hr/>
                    35. FALS: Liferay acceptă numai motorul Elasticsearch.

                    <hr/>
                    36. Liferay foloseste Elasticsearch pentru ca:
                    <ul>
                        <li>foloseste algoritmi si structuri precum <i>inverted index</i> care optimizeaza viteza</li>
                        <li>suport pentru tipuri de cautari avansate si caracteristici precum cautari fuzzy si proximity</li>
                        <li>structura de date RDBMS nu este optimizata pentru cautari si legaturi consumatoare</li>
                        <li>de obicei ruleaza pe servere dedicate</li>
                    </ul>

                    <hr/>
                    37. Blade CLI este:
                    <ul>
                        <li>un set de comenzi in linie de comanda</li>
                        <li>folosit de Liferay workspace pentru a gestiona module proiectelor</li>
                        <li>poate fi folosit fara vrun IDE</li>
                        <li>independent de Liferay workspace</li>
                    </ul>

                    <hr/>
                    38. Pasi pentru integrare cu Asset Framework:
                    <ul>
                        <li>adaugare campuri necesare si referinte catre definitiile entitatii</li>
                        <li>creare un Asset Render Factory pentru clasa model</li>
                        <li>implementare in fisiere JSP suport Asset Publisher</li>
                    </ul>
                    Nu e pas in integrarea cu Asset Framework: gestionare Asset Render Helper.

                    <hr/>
                    39. FALS: Dacă se utilizeaza Service Builder pentru a vă crea entitățile personalizate și se doreste implementarea suportului de permisiuni pentru aplicație,
                    trebuie să definiți permisiunile de serviciu în modulul de servicii și permisiunile de model în modulul portlet, de obicei modulul „web”.

                    <hr/>
                    40. Proprietăți ale componentelor de portlet legate de CSS:
                    <ul>
                        <li>com.liferay.portlet.header-portal-css (calea CSS la care se va face referire în header-ul paginii în raport cu calea contextului portalului)</li>
                        <li>com.liferay.portlet.footer-portal-css (calea CSS la care se va face referire în subsolul paginii în raport cu calea contextului portalului)</li>
                        <li>com.liferay.portlet.header-portlet-css (calea CSS la care se va face referire în header-ul paginii în raport cu calea contextului portletului)</li>
                        <li>com.liferay.portlet.footer-portlet-css (calea CSS la care se va face referire în subsolul paginii în raport cu calea contextului portletului)</li>
                    </ul>

                    <hr/>
                    41. FALS: Bibliotecile de tag-uri furnizate de Liferay, cum ar fi clay și liferay-theme, conțin etichete pentru cele mai comune componente ale interfeței cu utilizatorul,
                    dar sunt în cele din urmă înlocuite de bibliotecile de tag-uri standard.

                    <hr/>
                    42. Service Builder creează clase de implementare pentru fiecare clasă de model a entității definite și pentru instrumentele de căutare de entități (Entity Finders).

                    <hr/>
                    43. Pachetele Liferay sunt furnizate pentru Apache Tomcat și Wildfly.

                    <hr/>
                    44. Liferay developer studio community edition:
                    <ul>
                        <li>open-source disponibil a Liferay Eclipse Plugins</li>
                        <li>poate fi instalat pe un Eclipse existent</li>
                        <li>permite intalarea Liferay DXP bundle automat</li>
                        <li>la fel ca studioul de dezvoltare DXP, dar vă permite doar să instalați automat pachetul Liferay DXP</li>
                        <li>NU este disponibil ca Eclipse bundle</li>
                    </ul>

                    <hr/>
                    45. Se poate folosi Gogo Shell pentru a scrie un script pentru a obține toți utilizatorii portalului.
                    <br/>
                    Shell-ul Gogo nu numai că permite să vedem ce se întâmplă în shell-ul Gogo,
                    ci avem acces la containerul OSGi care permite să facem o mulțime de lucruri diferite, dar putem, de asemenea, să apelăm servicii și să creăm scripturi.

                    <hr/>
                    46. FALS: În Liferay, serviciile web JSON sunt dezactivate implicit, pentru a le activa, ar trebui să setați Jsonws.web.service.enabled=true

                    <hr/>
                    47. Asset framework este un cadru care face posibilă publicarea și gestionarea diferitelor tipuri de conținut într-un mod unificat și printr-un API standard.

                    <hr/>
                    48. Caracteristi câmpuri personalizate (custom fields):
                    <ul>
                        <li>cautabile</li>
                        <li>cachabile</li>
                        <li>permisionabile</li>
                        <li>accesibile din FreeMarker si Velocity template</li>
                    </ul>

                    <hr/>
                    49. Framework-uri incorporate (build-in) in Liferay:
                    <ul>
                        <li>Asset Framwwork</li>
                        <li>Search</li>
                        <li>Localization</li>
                    </ul>

                    <hr/>
                    <hr/>
                    50. Custom field-uri:
                    <ul>
                        <li>Text Area</li>
                        <li>Input Field</li>
                        <li>Checkbox</li>
                        <li>Radio</li>
                        <li>Geoloation</li>
                        <li>Date</li>
                        <li>True/False</li>
                        <li>Dropdown cu valori double</li>
                        <li>Dropdown cu valori intregi</li>
                    </ul>
                    Nu este custom field: Image!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips3LiferayContent;