import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class ServerConfigSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-server-config", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Configurare Local Git Repository</b>
                    <br/>
                    <br/>

                    Luam fisierul <i>application.properties</i> din <i>address-service</i> si il copiem de exemplu:
                    <br/>
                    <i>D:\work-resources\learn\microservices-with-java-spring-boot-cloud\repository</i>
                    <br/>
                    apoi redenumim fisierul in:
                    <i>address-service.properties</i>
                    <br/>
                    si stergem/comentam din acest fisier linia:
                    <SyntaxHighlighter>
                        {'spring.application.name=address-service'}
                    </SyntaxHighlighter>
                    astfel, continul fisierului va arata:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'spring.jpa.database=POSTGRESQL\n' +
                            'spring.datasource.url=jdbc:postgresql://192.168.1.8:5432/university\n' +
                            'spring.datasource.username=postgres\n' +
                            'spring.datasource.password=postgres\n' +
                            '\n' +
                            'server.port=9094\n' +
                            '\n' +
                            '# spring.application.name=address-service\n' +
                            '\n' +
                            'eureka.client.service-url.defaultZone = http://localhost:8761/eureka\n' +
                            'eureka.instance.hostname=localhost\n' +
                            '\n' +
                            'spring.zipkin.base-url = http://127.0.0.1:9411'}
                    </SyntaxHighlighter>

                    In <i>address-service</i>, fisierul <i>application.properties</i> va ramane doar linia:
                    <SyntaxHighlighter>
                        {'spring.application.name=address-service'}
                    </SyntaxHighlighter>

                    Adaugare fisier <i>address-service.properties</i> (aflandu-ne in  <i>D:\work-resources\learn\microservices-with-java-spring-boot-cloud\repository</i>) pe git:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'git init\n' +
                            'git add .\n' +
                            'git commit -m "first commit"\n' +
                            'git status'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Configurare Config Server</b>
                    <br/>
                    <br/>

                    <i>Spring Cloud Config Server</i> este o aplicatie <i>spring boot</i>.
                    <br/>
                    Se creaza o aplicatie Spring Boot, numita <i>config-server</i>, cu <i>group</i>: <i>ro.ibrid.learn.microservices.spring</i>
                    <i>ro.ibrid.learn.microservices.spring</i>
                    <br/>
                    Fie urmatorul <i>build.gradle</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'org.springframework.boot\' version \'2.7.4\'\n' +
                            '    id \'io.spring.dependency-management\' version \'1.0.14.RELEASE\'\n' +
                            '    id \'java\'\n' +
                            '}\n' +
                            '\n' +
                            'group = \'ro.ibrid.learn.microservices.spring\'\n' +
                            'version = \'0.0.1-SNAPSHOT\'\n' +
                            'sourceCompatibility = \'11\'\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '}\n' +
                            '\n' +
                            'ext {\n' +
                            '    set(\'springCloudVersion\', "2021.0.4")\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '    implementation \'org.springframework.cloud:spring-cloud-config-server\'\n' +
                            '    implementation \'org.springframework.cloud:spring-cloud-starter-netflix-eureka-client\'\n' +
                            '    testImplementation \'org.springframework.boot:spring-boot-starter-test\'\n' +
                            '}\n' +
                            '\n' +
                            'dependencyManagement {\n' +
                            '    imports {\n' +
                            '        mavenBom "org.springframework.cloud:spring-cloud-dependencies:${springCloudVersion}"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\') {\n' +
                            '    useJUnitPlatform()\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    apoi, se adauga adnotarea <b>@EnableConfigServer</b> si <b>@EnableEurekaClient</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.microservices.spring.app;\n' +
                            '\n' +
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            'import org.springframework.cloud.config.server.EnableConfigServer;\n' +
                            'import org.springframework.cloud.netflix.eureka.EnableEurekaClient;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            '@EnableConfigServer\n' +
                            '@EnableEurekaClient\n' +
                            'public class ConfigServerApplication {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        SpringApplication.run(ConfigServerApplication.class, args);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    apoi, in <i>application.properties</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'server.port=8888\n' +
                            '\n' +
                            'spring.application.name=config-server\n' +
                            '\n' +
                            'spring.cloud.config.server.git.uri=file:///D:/work-resources/learn/microservices-with-java-spring-boot-cloud/repository\n' +
                            '\n' +
                            'eureka.client.service-url.defaultZone = http://localhost:8761/eureka'}
                    </SyntaxHighlighter>

                    dupa ce pormin <i>config-server</i> :
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'http://localhost:8888/address-service/default'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "name": "address-service",\n' +
                            '  "profiles": [\n' +
                            '    "default"\n' +
                            '  ],\n' +
                            '  "label": null,\n' +
                            '  "version": "f253b5d61e0920e2d80986db41673d44f62b6568",\n' +
                            '  "state": null,\n' +
                            '  "propertySources": [\n' +
                            '    {\n' +
                            '      "name": "file:///D:/work-resources/learn/microservices-with-java-spring-boot-cloud/repository/file:D:\\\\work-resources\\\\learn\\\\microservices-with-java-spring-boot-cloud\\\\repository\\\\address-service.properties",\n' +
                            '      "source": {\n' +
                            '        "spring.jpa.database": "POSTGRESQL",\n' +
                            '        "spring.datasource.url": "jdbc:postgresql://192.168.1.8:5432/university",\n' +
                            '        "spring.datasource.username": "postgres",\n' +
                            '        "spring.datasource.password": "postgres",\n' +
                            '        "server.port": "9094",\n' +
                            '        "spring.application.name": "address-service",\n' +
                            '        "eureka.client.service-url.defaultZone": "http://localhost:8761/eureka",\n' +
                            '        "eureka.instance.hostname": "localhost",\n' +
                            '        "spring.zipkin.base-url": "http://127.0.0.1:9411"\n' +
                            '      }\n' +
                            '    }\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Configurare microservicii</b>
                    <br/>
                    <br/>
                    Pentru a citi din fisierul de proprietati de pe git, microserviciile trebuie configurate.

                    <br/>

                    Se adauga urmatoarele 2 dependinte in <i>build.grade</i> (pentru fiecare serviciu):
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'   implementation group: \'org.springframework.cloud\', name: \'spring-cloud-config-client\', version: "${spring_cloud_version}"\n' +
                            '    implementation group: \'org.springframework.cloud\', name: \'spring-cloud-starter-bootstrap\', version: "${spring_cloud_version}"\n'}
                    </SyntaxHighlighter>

                    Apoi, in <i>application.properties</i>
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'spring.application.name=address-service\n' +
                            '\n' +
                            '# pentru fi gasit serviciul (config-server) in Eureka\n' +
                            'spring.cloud.config.discovery.enabled=true\n' +
                            'spring.cloud.config.discovery.service-id=config-server\n' +
                            '\n' +
                            '# daca nu se foloseste Eureka\n' +
                            '# spring.cloud.config.uri=http://localhost:8888'}
                    </SyntaxHighlighter>

                    Apoi, se redenumeste <i>application.properties</i> in <i>bootstrap.properties</i>.

                    <hr/>
                    <b>4. Profile</b>
                    <br/>
                    <br/>
                    Pe server-ul de git putem avea mai multe profile, de exemplu:
                    <ul>
                        <li>address-service.properties</li>
                        <li>address-service-dev.properties</li>
                        <li>address-service-prod.properties</li>
                    </ul>
                    Nume de profile pentru exemple de mai sus: dev, prod.

                    <br/>
                    <br/>

                    Continutul de <i>address-service-dev.properties</i> poate fi:
                    <SyntaxHighlighter>
                        {'spring.jpa.database=POSTGRESQL\n' +
                            'spring.datasource.url=jdbc:postgresql://192.168.1.8:5432/university\n' +
                            'spring.datasource.username=postgres\n' +
                            'spring.datasource.password=postgres\n' +
                            '\n' +
                            'server.port=9094\n' +
                            '\n' +
                            'spring.application.name=address-service\n' +
                            '\n' +
                            'eureka.client.service-url.defaultZone = http://localhost:8761/eureka\n' +
                            'eureka.instance.hostname=localhost\n' +
                            '\n' +
                            'spring.zipkin.base-url = http://127.0.0.1:9411\n' +
                            '\n' +
                            'kj.env.name=dev'}
                    </SyntaxHighlighter>
                    Dupa ce se comite fisierul pe git, se poate testa
                    <br/>
                    In <i>bootstrap.proeperties</i> din microserviciul <i>address-service</i>, se adauga urmatoare linie,
                    prin care specificam ce profil sa se foloseasca:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'spring.cloud.config.profile=dev'}
                    </SyntaxHighlighter>

                    Pentru testare, in <i>AddressController</i> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'@Value("${kj.env.name}")\n' +
                            'private String kjEnvName;'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'@GetMapping("/test")\n' +
                            'public String test() {\n' +
                            '\treturn kjEnvName;\n' +
                            '}'}
                    </SyntaxHighlighter>
                    test:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'GET http://localhost:9094/api/address/test'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'dev'}
                    </SyntaxHighlighter>

                    Pentru a verifica ca exista profilul:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'http://localhost:8888/address-service/dev'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "name": "address-service",\n' +
                            '  "profiles": [\n' +
                            '    "dev"\n' +
                            '  ],\n' +
                            '  "label": null,\n' +
                            '  "version": "459c501371f0679e4fe335bfef3c1133bbd2833b",\n' +
                            '  "state": null,\n' +
                            '  "propertySources": [\n' +
                            '    {\n' +
                            '      "name": "file:///D:/work-resources/learn/microservices-with-java-spring-boot-cloud/repository/file:D:\\\\work-resources\\\\learn\\\\microservices-with-java-spring-boot-cloud\\\\repository\\\\address-service-dev.properties",\n' +
                            '      "source": {\n' +
                            '        "spring.jpa.database": "POSTGRESQL",\n' +
                            '        "spring.datasource.url": "jdbc:postgresql://192.168.1.8:5432/university",\n' +
                            '        "spring.datasource.username": "postgres",\n' +
                            '        "spring.datasource.password": "postgres",\n' +
                            '        "server.port": "9094",\n' +
                            '        "spring.application.name": "address-service",\n' +
                            '        "eureka.client.service-url.defaultZone": "http://localhost:8761/eureka",\n' +
                            '        "eureka.instance.hostname": "localhost",\n' +
                            '        "spring.zipkin.base-url": "http://127.0.0.1:9411",\n' +
                            '        "kj.env.name": "dev"\n' +
                            '      }\n' +
                            '    },\n' +
                            '    {\n' +
                            '      "name": "file:///D:/work-resources/learn/microservices-with-java-spring-boot-cloud/repository/file:D:\\\\work-resources\\\\learn\\\\microservices-with-java-spring-boot-cloud\\\\repository\\\\address-service.properties",\n' +
                            '      "source": {\n' +
                            '        "spring.jpa.database": "POSTGRESQL",\n' +
                            '        "spring.datasource.url": "jdbc:postgresql://192.168.1.8:5432/university",\n' +
                            '        "spring.datasource.username": "postgres",\n' +
                            '        "spring.datasource.password": "postgres",\n' +
                            '        "server.port": "9094",\n' +
                            '        "spring.application.name": "address-service",\n' +
                            '        "eureka.client.service-url.defaultZone": "http://localhost:8761/eureka",\n' +
                            '        "eureka.instance.hostname": "localhost",\n' +
                            '        "spring.zipkin.base-url": "http://127.0.0.1:9411"\n' +
                            '      }\n' +
                            '    }\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Actualizarea proprietatilor</b>
                    <br/>
                    <br/>

                    Daca se actualizeaza valoarea unei proprietati, de exemplu in <i>address-service-dev.properties</i>, atunci:
                    <ul>
                        <li>
                            apeland:
                            <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                {'http://localhost:8888/address-service/dev'}
                            </SyntaxHighlighter>
                            se va afisa valoarea actualizata
                        </li>
                        <li>
                            apeland din microserviciu:
                            <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                {'GET http://localhost:9094/api/address/test'}
                            </SyntaxHighlighter>
                            se va afisa vechea valoarea
                            <br/>
                            pentru actualiza proprietatea cu noua valoare trebuie:
                            <ul>
                                <li>restartat microserviciu</li>
                                <li>daca nu vream sa restartam serverul, folosim <b>actuator</b></li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>
                    Configurare <b>acturator</b>
                    <br/>
                    <br/>
                    Adauga dependinta in microserviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'implementation \'org.springframework.boot:spring-boot-starter-actuator\''}
                    </SyntaxHighlighter>
                    Apoi in <b><i>bootstrap.proeperties</i></b> adaugam:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'management.endpoints.web.exposure.include=refresh'}
                    </SyntaxHighlighter>

                    Apoi, in <i>AddressController</i> se adnoteaza clasa cu:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'@RefreshScope'}
                    </SyntaxHighlighter>
                    Apoi, de fiecare data cand se modifica ceva, pentru a se reflecta si in microserviciu, apelam, mai intai <i>actuator</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'POST http://localhost:9094/actuator/refresh'}
                    </SyntaxHighlighter>
                    Se va afisa o lista cu ce proprietati au fost modificate:
                    <SyntaxHighlighter  showLineNumbers={true} language="json" style={androidstudio}>
                        {'[\n' +
                            '  "kj.env.name"\n' +
                            ']'}
                    </SyntaxHighlighter>
                    Acum, daca apelam:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'GET http://localhost:9094/api/address/test'}
                    </SyntaxHighlighter>
                    se va afisa noua valoare modificata; (nu e nevoie sa comitem fisierul <i>address-service-dev.properties</i> daca l-am modificat )

                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default ServerConfigSpringContent;