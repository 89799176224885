import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class MultiDataSourceJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-multi-datasources", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Multi DataSource</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.zaxxer.hikari.HikariDataSource;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            'import org.springframework.boot.autoconfigure.jdbc.DataSourceProperties;\n' +
                            'import org.springframework.boot.context.properties.ConfigurationProperties;\n' +
                            'import org.springframework.boot.orm.jpa.EntityManagerFactoryBuilder;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.context.annotation.Primary;\n' +
                            'import org.springframework.orm.jpa.JpaTransactionManager;\n' +
                            'import org.springframework.orm.jpa.LocalContainerEntityManagerFactoryBean;\n' +
                            '\n' +
                            'import javax.persistence.EntityManagerFactory;\n' +
                            'import javax.sql.DataSource;\n' +
                            'import java.util.HashMap;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class DefaultDatabaseConfig {\n' +
                            '\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Primary\n' +
                            '    @ConfigurationProperties("spring.datasource")\n' +
                            '    public DataSourceProperties defaultDataSourceProperties() {\n' +
                            '        return new DataSourceProperties();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Primary\n' +
                            '    @ConfigurationProperties("spring.datasource.hikari")\n' +
                            '    public DataSource defaultDataSource() {\n' +
                            '        return defaultDataSourceProperties()\n' +
                            '            .initializeDataSourceBuilder()\n' +
                            '            .type(HikariDataSource.class)\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "entityManagerFactory")\n' +
                            '    @Primary\n' +
                            '    public LocalContainerEntityManagerFactoryBean customerEntityManagerFactory(EntityManagerFactoryBuilder builder) {\n' +
                            '\n' +
                            '        Map<String, String> properties = new HashMap<>();\n' +
                            '        properties.put("hibernate.connection.provider_disables_autocommit","true");\n' +
                            '        properties.put("spring.datasource.hikari.auto-commit", "false");\n' +
                            '\n' +
                            '        return builder\n' +
                            '            .dataSource(defaultDataSource())\n' +
                            '            .packages("ro.domain")\n' +
                            '            .persistenceUnit("default")\n' +
                            '            .properties(properties)\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "transactionManager")\n' +
                            '    @Primary\n' +
                            '    public JpaTransactionManager db2TransactionManager(@Qualifier("entityManagerFactory") final EntityManagerFactory emf) {\n' +
                            '        JpaTransactionManager transactionManager = new JpaTransactionManager();\n' +
                            '        transactionManager.setEntityManagerFactory(emf);\n' +
                            '        return transactionManager;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Configurare a doua DataSource:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {
                            'import com.zaxxer.hikari.HikariDataSource;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            'import org.springframework.boot.autoconfigure.domain.EntityScan;\n' +
                            'import org.springframework.boot.autoconfigure.jdbc.DataSourceProperties;\n' +
                            'import org.springframework.boot.context.properties.ConfigurationProperties;\n' +
                            'import org.springframework.boot.orm.jpa.EntityManagerFactoryBuilder;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.context.annotation.Primary;\n' +
                            'import org.springframework.data.jpa.repository.config.EnableJpaRepositories;\n' +
                            'import org.springframework.orm.jpa.JpaTransactionManager;\n' +
                            'import org.springframework.orm.jpa.LocalContainerEntityManagerFactoryBean;\n' +
                            'import org.springframework.transaction.annotation.EnableTransactionManagement;\n' +
                            '\n' +
                            'import javax.persistence.EntityManagerFactory;\n' +
                            'import javax.sql.DataSource;\n' +
                            'import java.util.HashMap;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Configuration\n' +
                            '@EnableTransactionManagement\n' +
                            '@EntityScan(basePackages = "ro.ibrid.integration.doi.domain")\n' +
                            '@EnableJpaRepositories(transactionManagerRef = "doiTransactionManager",\n' +
                            '    entityManagerFactoryRef = doiEntityManagerFactory",\n' +
                            '    basePackages = "ro.ibrid.integration.doi.repository")\n' +
                            'public class DoiDatabaseConfig {\n' +
                            '\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @ConfigurationProperties("integration.doi.datasource")\n' +
                            '    public DataSourceProperties doiDataSourceProperties() {\n' +
                            '        return new DataSourceProperties();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @ConfigurationProperties("integration.doi.datasource.hikari")\n' +
                            '    public DataSource doiDataSource() {\n' +
                            '        return serviceDeskDataSourceProperties()\n' +
                            '            .initializeDataSourceBuilder()\n' +
                            '            .type(HikariDataSource.class)\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "doiManagerFactory")\n' +
                            '    public LocalContainerEntityManagerFactoryBean doiEntityManagerFactory(EntityManagerFactoryBuilder builder) {\n' +
                            '\n' +
                            '        Map<String, String> properties = new HashMap<>();\n' +
                            '        properties.put("hibernate.connection.provider_disables_autocommit","true");\n' +
                            '        properties.put("spring.datasource.hikari.auto-commit", "false");\n' +
                            '\n' +
                            '        return builder\n' +
                            '            .dataSource(serviceDeskDataSource())\n' +
                            '            .packages("ro.ibrid.integration.domain")\n' +
                            '            .persistenceUnit("doi")\n' +
                            '            .properties(properties)\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean(name = "doiTransactionManager")\n' +
                            '    public JpaTransactionManager doiTransactionManager(@Qualifier("doiEntityManagerFactory") final EntityManagerFactory emf) {\n' +
                            '\n' +
                            '        JpaTransactionManager transactionManager = new JpaTransactionManager();\n' +
                            '        transactionManager.setEntityManagerFactory(emf);\n' +
                            '        return transactionManager;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Rezolvare probleme</b>
                    <br/>
                    <br/>

                    Rezolvare problema strategie de naming:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.physical_naming_strategy", "org.hibernate.boot.model.naming.CamelCaseToUnderscoresNamingStrategy");'}
                    </SyntaxHighlighter>

                    Rezolvare problema de dialect pe SQLServer:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.dialect", "org.hibernate.dialect.SQLServer2012Dialect");'}
                    </SyntaxHighlighter>

                    Rezolvare problema de dialect pe PostgreSQL:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.dialect", "org.hibernate.dialect.PostgreSQLDialect");'}
                    </SyntaxHighlighter>

                    Rezolvare probema de autocomit pe PostreSQL:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'properties.put("hibernate.connection.provider_disables_autocommit", "true");\n' +
                            'properties.put("spring.datasource.hikari.auto-commit", "false");'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultiDataSourceJHipsterContent;