import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ColorSamplingVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-color-sampling", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Color sampling / Chroma sampling (esantionare culoare)</b>
                    <br/>
                    <br/>
                    Majoritatea camerelor de filmat nu inregistraza toate informatiile legate de culoare.
                    Si acest lucru se intampla pentru a avea un fisier rezultat cu o dimensiune cat mai mica.
                    <br/>
                    Si in acest sens, o parte din informatiile legate de culoare sunt copiate de la pixelii din apropiere.
                    Iar cantitatea care este copiata este reprezentata in forma de <i>raport</i>. De exemplu:
                    <ul>
                        <li>4:2:0</li>
                        <li>4:2:2</li>
                        <li>4:4:4</li>
                    </ul>

                    Motiv pentru care camere se folosesc de acest truc de copiere a informatiilor legate de culoare este pentru ca ochii
                    sunt mult mai putini senzibili la schimbarile de culoare decat la schimbarile de luminozitate.
                    <br/>
                    Fiecare pixel contine informatii legate de :
                    <ul>
                        <li>luminanta (<i>luma</i>) {"->"} luminozitate</li>
                        <li>crominanta / informatii cromatice (<i>chroma</i>) {"->"} culoare</li>
                    </ul>

                    Sistemul de <i>raport</i> reprezinta un bloc de 8 pixeli, aranjati pe 2 randuri si 4 coloane: luma:chormaRand1:chromaRand2
                    <ul>
                        <li>numarul de pixeli care contin informatii legate de luma (luminizitate); deoarece ochii sunt mai sensibili la luminozitate, luma este inregistrata in toti pixelii</li>
                        <li>numarul de pixeli din primul rand au informatii de culoare (chroma) necopiate</li>
                        <li>numarul de pixeli din primul rand au informatii de culoare (chroma) necopiate</li>
                    </ul>

                    Revenim la exemplu:
                    <ul>
                        <li>4:2:0
                            <br/>
                            inregistreaza un sfert (25%) din informatia de culoare (dar economiseste mult spatiu de stocare si de multe ori este destul de acceptabil in cele mai multe cazuri) :
                            <ul>
                                <li>informatii despre luminanta au toti pixeli</li>
                                <li>in randul 1 (de sus) 2 pixeli au informatie despre culoare si 2 pixeli preiau sau copiaza informatiile despre culoare de la pixeli de langa ei</li>
                                <li>in randul 2 (de jos) nici un pixel nu vine cu informatie despre culoare; toti cei 4 pixeli preau sau copiza informatiile despre culoare de la pixelii de mai sus</li>
                            </ul>

                            Valori chroma:
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{backgroundColor:'red', color: '#000'}}>p11</td>
                                    <td style={{backgroundColor:'red', color: '#000'}}><b>p12</b></td>
                                    <td style={{backgroundColor:'yellow', color: '#000'}}>p13</td>
                                    <td style={{backgroundColor:'yellow', color: '#000'}}><b>p14</b></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'red', color: '#000'}}>p21</td>
                                    <td style={{backgroundColor:'red', color: '#000'}}>p22</td>
                                    <td style={{backgroundColor:'yellow', color: '#000'}}>p23</td>
                                    <td style={{backgroundColor:'yellow', color: '#000'}}>p24</td>
                                </tr>
                                </tbody>
                            </table>

                            Ca observatie, adaugand luminanta pentru fiecare pixel, chiar daca chroma este acceasi, pixelul va avea alta nuanta.
                            De exemplu:
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{backgroundColor:'red', color: '#000',opacity: 0.8}}>p11</td>
                                    <td style={{backgroundColor:'red', color: '#000',opacity: 0.6}}><b>p12</b></td>
                                    <td style={{backgroundColor:'yellow', color: '#000',opacity: 0.5}}>p13</td>
                                    <td style={{backgroundColor:'yellow', color: '#000',opacity: 0.7}}><b>p14</b></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'blue', color: '#fff',opacity: 0.9}}>p21</td>
                                    <td style={{backgroundColor:'blue', color: '#fff',opacity: 0.7}}><b>p22</b></td>
                                    <td style={{backgroundColor:'green', color: '#000',opacity: 0.3}}>p23</td>
                                    <td style={{backgroundColor:'green', color: '#000',opacity: 0.6}}><b>p24</b></td>
                                </tr>
                                </tbody>
                            </table>

                        </li>

                        <li>4:2:2
                            <br/>
                            inregistreaza jumatate din informatia de culoare:

                            <ul>
                                <li>informatii despre luminanta au toti pixeli</li>
                                <li>in randul 1 (de sus) 2 pixeli au informatie despre culoare si 2 pixeli preiau sau copiaza informatiile despre culoare de la pixeli de langa ei</li>
                                <li>in randul 2 (de jos) 2 pixeli au informatie despre culoare si 2 pixeli preiau sau copiaza informatiile despre culoare de la pixeli de langa ei</li>
                            </ul>

                            Valori chroma:
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor:'red', color: '#000'}}>p11</td>
                                        <td style={{backgroundColor:'red', color: '#000'}}><b>p12</b></td>
                                        <td style={{backgroundColor:'yellow', color: '#000'}}>p13</td>
                                        <td style={{backgroundColor:'yellow', color: '#000'}}><b>p14</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:'blue', color: '#fff'}}>p21</td>
                                        <td style={{backgroundColor:'blue', color: '#fff'}}><b>p22</b></td>
                                        <td style={{backgroundColor:'green', color: '#000'}}>p23</td>
                                        <td style={{backgroundColor:'green', color: '#000'}}><b>p24</b></td>
                                    </tr>
                                </tbody>
                            </table>

                        </li>
                        <li>4:4:4
                            <br/>
                            se inregistreaza toate detaliile despre coloare:
                            <ul>
                                <li>informatii despre luminanta au toti pixeli</li>
                                <li>in randul 1 (de sus), toti cei 4 pixeli au informatie despre culoare</li>
                                <li>in randul 2 (de jos), toti cei 4 pixeli au informatie despre culoare </li>
                            </ul>

                            Valori chroma:
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{backgroundColor:'red', color: '#000'}}>p11</td>
                                    <td style={{backgroundColor:'cyan', color: '#000'}}>p12</td>
                                    <td style={{backgroundColor:'yellow', color: '#000'}}>p13</td>
                                    <td style={{backgroundColor:'magenta', color: '#000'}}>p14</td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'blue', color: '#fff'}}>p21</td>
                                    <td style={{backgroundColor:'pink', color: '#000'}}>p22</td>
                                    <td style={{backgroundColor:'green', color: '#000'}}>p23</td>
                                    <td style={{backgroundColor:'coral', color: '#000'}}>p24</td>
                                </tr>
                                </tbody>
                            </table>

                        </li>
                    </ul>

                    <hr/>
                    Omul este mai sensibil la luminozitate decat la culoare.
                    <hr/>

                    O imagine pe 8 biti poate reprezenta 256 de umbre ale luminantei pe un canal RGB.
                    <br/>
                    O imagine pe 10 biti poate reprezenta 1024 de umbre ale luminantei pe un canal RGB

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=32PPzwPjDZ8&ab_channel=CamberMotion"}>Do You NEED 4:2:2 Color Sampling – Understanding 4:2:2 vs 4:2:0 Chroma Sampling for BETTER Colors</a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ColorSamplingVideografieContent;