import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class LinksNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-links", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/setup-synology-nas/"}>How to Setup Synology NAS?</a>
                        </li>

                       <li>
                           <a target={"_blank"} href={"https://linuxhint.com/speed-test-synology-nas/"}>How to Speed Test Synology NAS?</a>
                       </li>

                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/docker-compose-with-synology/"}>How Do I Use Docker Compose with Synology?</a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/synology-mail-server-vs-mailplus-server/"}>Synology Mail Server vs. MailPlus Server Compared</a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/run-synology-email-server/"}>How to Run a Synology Email Server</a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/enable-ssh-access-synology-nas/"}>Enable SSH Access on Synology NAS</a>
                        </li>



                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LinksNasContent;