import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class ControllerLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-controller", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. php artisan make:controller</b>
                    <br/>
                    <br/>
                    Pentru a genera un controller, se da comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:model NUME_CONTROLLER'}
                    </SyntaxHighlighter>
                    Accepta parametrii:
                    <ul>
                        <li>
                            --api
                        </li>
                        <li>
                            --model=NUME_MODEL
                        </li>
                    </ul>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:controller CategoryController --api --model=Category'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ControllerLaravelContent;