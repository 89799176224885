import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class IntroMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere</b>:
                    <br/>
                    <br/>

                    Fie urmatorul fisier <b>pom.xml</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<project xmlns="http://maven.apache.org/POM/4.0.0"\n' +
                            '         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">\n' +
                            '    <modelVersion>4.0.0</modelVersion>\n' +
                            '\n' +
                            '    <groupId>ro.maven.learn</groupId>\n' +
                            '    <artifactId>maven-project-learn</artifactId>\n' +
                            '    <version>1.0-SNAPSHOT</version>\n' +
                            '\n' +
                            '    <properties>\n' +
                            '        <maven.compiler.source>11</maven.compiler.source>\n' +
                            '        <maven.compiler.target>11</maven.compiler.target>\n' +
                            '        <project.build.sourceEncoding>UTF-8</project.build.sourceEncoding>\n' +
                            '    </properties>\n' +
                            '\n' +
                            '</project>'}
                    </SyntaxHighlighter>

                    In acest fisier se mai poate defini:
                    <ul>
                        <li>packaging: ear | jar</li>
                        <li>licente</li>
                        <li>organizatie</li>
                        <li>dezvoltatori</li>
                    </ul>

                    Completam fisierul <b>pom.xml</b> cu:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'    <packaging>jar</packaging>\n' +
                            '\n' +
                            '    <name>Invat Maven cu exemple</name>\n' +
                            '\n' +
                            '    <description>In acest proiect invat Maven</description>\n' +
                            '\n' +
                            '    <url>www.dopamina.ro</url>\n' +
                            '\n' +
                            '    <licenses>\n' +
                            '        <license>\n' +
                            '            <name>GNU</name>\n' +
                            '            <comments>You may copy, distribute and modify the software as long as you track changes/dates in source files.</comments>\n' +
                            '        </license>\n' +
                            '\n' +
                            '        <license>\n' +
                            '            <name>Apache</name>\n' +
                            '            <comments>You can do what you like with the software, as long as you include the required notices</comments>\n' +
                            '        </license>\n' +
                            '    </licenses>\n' +
                            '\n' +
                            '    <organization>\n' +
                            '        <name>The Dopamina</name>\n' +
                            '        <url>www.dopamina.ro</url>\n' +
                            '    </organization>\n' +
                            '\n' +
                            '    <developers>\n' +
                            '        <developer>\n' +
                            '            <name>Iulian KJ</name>\n' +
                            '            <roles>\n' +
                            '                <role> Java Developer</role>\n' +
                            '                <role> Python Developer</role>\n' +
                            '            </roles>\n' +
                            '            <email>iulian@gmail.com</email>\n' +
                            '        </developer>\n' +
                            '    </developers>'}
                    </SyntaxHighlighter>

                    Pentru a genera <b>site</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn site'}
                    </SyntaxHighlighter>

                    Daca crapa cu:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'[WARNING] Error injecting: org.apache.maven.report.projectinfo.CiManagementReport\n' +
                            'java.lang.NoClassDefFoundError: org/apache/maven/doxia/siterenderer/DocumentContent\n'}
                    </SyntaxHighlighter>

                    se adauga urmatoare sectiune:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<build>\n' +
                            '        <plugins>\n' +
                            '            <!-- Part of Maven - specified version explicitly for compatibility\n' +
                            '                 with the maven-project-info-reports-plugin 3.0.0-->\n' +
                            '            <plugin>\n' +
                            '                <groupId>org.apache.maven.plugins</groupId>\n' +
                            '                <artifactId>maven-site-plugin</artifactId>\n' +
                            '                <version>3.7.1</version>\n' +
                            '            </plugin>\n' +
                            '        </plugins>\n' +
                            '    </build>'}
                    </SyntaxHighlighter>

                    Site-ul este generat in <i>target\site</i>.

                    <hr/>
                    <b>2. Compilare proiect</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compile'}
                    </SyntaxHighlighter>

                    Daca se compileaza, fara sa fi facut vrun pachet sau clasa, se creeaza urmatoarea structura:
                    <ul>
                        <li>src\main\java</li>
                        <li>src\main\resources</li>
                    </ul>

                    Vom creea pachetul <i>ro.maven.learn</i>.
                    <br/>

                    Se creeaza clasa <i>AppMain</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'package ro.maven.learn;\n' +
                            '\n' +
                            'public class AppMain {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        System.out.println("AppMain");\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi, se compileaza proiectul, din nou:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compile'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Structura proiect personalizata</b>
                    <br/>
                    <br/>

                    Sa presupunem ca:
                    <ul>
                        <li>vrem ca sursele sa se gaseasca in: src/root/java (si nu in src/main/java)</li>
                        <li>vrem ca fisierel compilate sa se puna in [radacina proietului]/<i>compile</i>/classes/[pachet]</li>
                    </ul>
                    Atunci in <b>pom.xml</b>, specificam acest lucru:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'   <build>\n' +
                            '        <sourceDirectory>src/root/java</sourceDirectory>\n' +
                            '        <directory>compile</directory>\n' +
                            '    </build>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroMavenContent;