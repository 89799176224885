import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"compiler", url:"#", title:"Introducere in compilatoare", subtitle:""},
        {id:"compiler-translators", url:"/compiler/translators", title:"Translatori", subtitle:"", parent:"compiler"},

        {id:"compiler-lexical-analysis", url:"/compiler/lexical-analysis", title:"Analiza lexicala", subtitle:"", parent:"compiler"},

    ];

    static indexUrl = "/compiler/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Compilatoare
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;