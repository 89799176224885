import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"python-i", url:"#", title:"Introducere in Python", subtitle:""},

        {id:"python-overview", url:"/python/overview", title:"Generalitati", subtitle:"", parent: "python-i"},
        {id:"python-install", url:"/python/install", title:"Instalare si pornire IDLE", subtitle: "", parent: "python-i"}, // subtitle:"IDLE = (Integrated Development and Learning Environment)"
        {id:"python-print-function", url:"/python/print-function", title:"Functia print()", subtitle:"", parent: "python-i"},
        {id:"python-literals", url:"/python/literals", title:"Literali", subtitle:"", parent: "python-i"},
        {id:"python-comments", url:"/python/comments", title:"Comentarii", subtitle:"", parent: "python-i"},
        {id:"python-operators",url:"/python/operators", title:"Operatori aritmetici", subtitle:"", parent: "python-i"},
        {id:"python-variables",url:"/python/variables", title:"Variabile", subtitle:"", parent: "python-i"},
        {id:"python-input-function", url:"/python/input-function", title:"Functia input()", subtitle:"", parent: "python-i"},
        {id:"python-string",url:"/python/string", title:"Tipul String", subtitle:"", parent: "python-i"},
        {id:"python-relational-and-logic-operators",url:"/python/relational-and-logic-operators", title:"Operatori relationali si logici", subtitle:"", parent: "python-i"},
        {id:"python-if-statement",url:"/python/if-statement", title:"Instructiunea if/else/elif", subtitle:"", parent: "python-i"},
        {id:"python-while-statement",url:"/python/while-statement", title:"Instructiunea while", subtitle:"", parent: "python-i"},
        {id:"python-for-statement",url:"/python/for-statement", title:"Instructiunea for", subtitle:"", parent: "python-i"}, /* (+ functia <b>range()</b>, functia <b>sleep()</b>, functia <b>upper()</b>cuvintele cheie: <b>in</b>, <b>pass</b>, <b>continue</b>, <b>break</b>) */
        {id:"python-bits-operators",url:"/python/bits-operators", title:"Operatori pe biti", subtitle:"", parent: "python-i"},
        {id:"python-lists",url:"/python/lists", title:"Liste", subtitle:"", parent: "python-i"},
        {id:"python-functions",url:"/python/functions", title:"Functii", subtitle:"", parent: "python-i"},
        {id:"python-tuples-and-dictionaries",url:"/python/tuples-and-dictionaries", title:"Tuple si dictionare", subtitle:"", parent: "python-i"},
        {id:"python-exceptions",url:"/python/exceptions", title:"Exceptii", subtitle:"", parent: "python-i"},
        {id:"python-modules",url:"/python/modules", title:"Module", subtitle:"", parent: "python-i"},
        {id:"python-packages",url:"/python/packages", title:"Pachete", subtitle:"", parent: "python-i"},
        {id:"python-pip",url:"/python/pip", title:"PIP", subtitle:"", parent: "python-i"},
        {id:"python-string-ii",url:"/python/string-ii", title:"Tipul String (II)", subtitle:"", parent: "python-i"},
        {id:"python-classes",url:"/python/classes", title:"OOP: Clase", subtitle:"", parent: "python-i"},
        {id:"python-inheritance",url:"/python/inheritance", title:"OOP: Mostenirea", subtitle:"", parent: "python-i"},
        {id:"python-mro",url:"/python/mro", title:"OOP: MRO", subtitle:"", parent: "python-i"},
        {id:"python-exceptions-ii",url:"/python/exceptions-ii", title:"OOP: Exceptii", subtitle:"", parent: "python-i"},
        {id:"python-generators",url:"/python/generators", title:"Generatori", subtitle:"", parent: "python-i"},
        {id:"python-lambdas",url:"/python/lambdas", title:"Functii lambdas", subtitle:"", parent: "python-i"},
        {id:"python-closures",url:"/python/closures", title:"Closures", subtitle:"", parent: "python-i"},

        {id:"python-i-files", url:"#", title:"Fisiere", subtitle:""},

        {id:"python-file-streams",url:"/python/file-streams", title:"Fisiere", subtitle:"", parent: "python-i-files"},
        {id:"python-processing-files",url:"/python/processing-files", title:"Procesare fisierelor", subtitle:"", parent: "python-i-files"},

        {id:"python-i-modules", url:"#", title:"Module", subtitle:""},

        {id:"python-os-module",url:"/python/os-module", title:"Modulul os", subtitle:"", parent: "python-i-modules"},
        {id:"python-datetime-module",url:"/python/datetime-module", title:"Modulul datetime", subtitle:"", parent: "python-i-modules"},
        {id:"python-time-module",url:"/python/time-module", title:"Modulul time", subtitle:"", parent: "python-i-modules"},
        {id:"python-calendar-module",url:"/python/calendar-module", title:"Modulul calendar", subtitle:"", parent: "python-i-modules"},
    ];

    static indexUrl = "/python/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere în Python
                </div>

                <div>
                    <i>șarpele însă era cel mai şiret dintre toate fiarele de pe pământ</i> (Facearea, Capitolul 3.1)
                    <br/>
                    <br/>
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;