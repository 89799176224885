import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCogs, faHome, faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import Auth from "../security/Auth";
import {Link} from "react-router-dom";

class Navbar extends React.Component {

    auth = new Auth();

    logout(e) {
        this.auth.logout();
        window.location.reload()
    }

    render() {

        //const PUBLIC_URL = import.meta.env.BASE_URL;
        const PUBLIC_URL = process.env.PUBLIC_URL;

        let isAuth = this.auth.isAuthenticated();

        // Auth.logout()

        return (
            // <!-- Navigation -->
            <header className="header-bg">
                <nav className="navbar navbar-expand-md navbar-dark container">
                    <Link className="navbar-brand logo" to="/">
                        <img style={{width:50}}
                             src={PUBLIC_URL + '/logo.svg'} alt={""}/>dopamina
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">

                        </div>
                        <ul className="navbar-nav ml-auto" id="#mainNavbarNavDropdown">

                            <li className="nav-item">
                                <a className="nav-link"  href="/"><FontAwesomeIcon icon={faHome} /><div>home</div></a>
                            </li>

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link"  href="ann"><FontAwesomeIcon icon={faFile} /><div>ann</div></a>*/}
                            {/*</li>*/}

                            <li className="nav-item">
                                <a className="nav-link"  href="perceptron"><FontAwesomeIcon icon={faCogs} /><div>perceptron</div></a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link"  href="handwritten"><FontAwesomeIcon icon={faCogs} /><div>handwritten</div></a>
                            </li>

                            <li className="nav-item">
                                {!isAuth ?
                                    <a className="nav-link" href="/login"><FontAwesomeIcon icon={faSignInAlt}/>
                                        <div>login</div>
                                    </a>
                                    :
                                    <a className="nav-link" href="#" onClick={this.logout.bind(this)}>
                                        <FontAwesomeIcon icon={faSignOutAlt}/>
                                        <div>logout</div>
                                    </a>
                                }
                            </li>

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link"  href="kohonen"><FontAwesomeIcon icon={faFile} /><div>kohonen</div></a>*/}
                            {/*</li>*/}

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link"  href="hopfield"><FontAwesomeIcon icon={faFile} /><div>hopfield</div></a>*/}
                            {/*</li>*/}

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link"  href="boltzmann"><FontAwesomeIcon icon={faFile} /><div>boltzmann</div></a>*/}
                            {/*</li>*/}

                        </ul>
                    </div>

                </nav>
            </header>
        );

    }
}

export default Navbar;