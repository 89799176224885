import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class DaemonGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-daemon", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Pentru a optimiza procesul de build se poate porni un proces care gestioneaza acest lucru:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle build --daemon'}
                    </SyntaxHighlighter>

                    Dupa ce s-a dat acest parametru, nu mai e nevoie sa se foloseasca, pentru ca <i>daemon</i>-ul e folosit in <i>background</i> la urmatoare executii de <i>build</i>.

                    <br/>
                    <br/>

                    Pentru a opri procesul <i>deamon</i> sau pentru un anumit build sa nu se foloseasca <i>daemon</i>-ul:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle build --no-daemon'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DaemonGradleContent;