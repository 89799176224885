import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FragmentsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-fragments", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    1. Lista de forme (Liferay Forms) definite:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private List<DDMFormInstance> getDDMFormInstanceList(HttpServletRequest httpServletRequest){\n' +
                        '\tList<DDMFormInstance> ddmFormInstancesList = DDMFormInstanceLocalServiceUtil.getDDMFormInstances(-1,-1)\n' +
                        '\t\t.stream()\n' +
                        '\t\t.sorted(Comparator.comparing(ddmFormInstance -> ddmFormInstance.getName(PortalUtil.getLocale(httpServletRequest))))\n' +
                        '\t\t.collect(Collectors.toList());\n' +
                        '\treturn ddmFormInstancesList;\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    2. Conversie <b>RenderRequest</b> to <b>HttpServletRequest</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'HttpServletRequest httpServletRequest = PortalUtil.getOriginalServletRequest(PortalUtil.getHttpServletRequest(renderRequest));'}
                    </SyntaxHighlighter>

                    <hr/>
                    3. Trimitere atribut catre view:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'renderRequest.setAttribute("formInstanceList", ddmFormInstanceList);'}
                    </SyntaxHighlighter>

                    <hr/>

                    4. Receptionare atribut in view:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'List<DDMFormInstance> ddmFormInstanceList = (List<DDMFormInstance>)request.getAttribute("formInstanceList");'}
                    </SyntaxHighlighter>

                    <hr/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FragmentsLiferayContent;