import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class Pep257PythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-pep-257", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    <b>1. Introducere in docstring</b>
                    <br/>
                    <br/>

                    Un docstring este șir de caractere (siruri de documentatie) care apare ca prima instrucțiune dintr-o definiție de modul, funcție, clasă sau metodă.
                    Un docstring devine atributul <b>__doc__</b> al acelui obiect.
                    Un docstring oferi informații despre funcționalitatea unei părți mai mari de cod.


                    <br/>
                    Comentariile sunt folosite pentru a <i>comenta</i> codul.
                    <br/>
                    Doctring-urile sunt folosite pentru a <i>documenta</i> codul.


                    <table>
                        <thead>
                            <tr>
                                <th>Comentarii</th>
                                <th>Docstrings</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Comentariile sunt instrucțiuni neexecutabile, nu sunt stocate în memorie și nu pot fi accesate în timpul execuției programului (pot fi accesate uitându-se la codul sursă).
                                </td>
                                <td>
                                    Docstrings pot fi accesate citind codul sursă și folosind atributul <b>__doc__</b> sau funcția <b>help()</b>.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Scopul principal al comentariilor este creșterea lizibilității și înțelegerii codului și explicarea codului.
                                </td>
                                <td>
                                    Scopul principal al documentelor este documentarea codului dvs. - descrierea utilizării, funcționalității și capabilităților.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Nu pot fi transformate în documentație.
                                </td>
                                <td>
                                    Pot fi ușor transformate în documentație reală, care descrie comportamentul unui modul sau al unei funcții, semnificația parametrilor sau scopul unui pachet specific.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <hr/>
                    <b>2. Indicarea tipului (PEP 484)</b>
                    <br/>
                    <br/>

                    Indicarea tipului este un mecanism introdus cu Python 3.5 și descris în PEP 484 care vă permite să vă <i>adnotati</i> codul cu informații suplimentare fără a utiliza comentarii.
                    <br/>
                    Este o caracteristică opțională (dar formalizată), care permite să utilizarea modulul de tastare încorporat Python pentru:
                    <ul>
                        <li> a indica tipul unei variabile</li>
                        <li> a lăsa sugestii</li>
                        <li> a marca probleme posibile care pot apărea în dezvoltare proces</li>
                        <li> a eticheta nume cu informații despre tip</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# fara indicarea tipului\n' +
                        'def salut(nume):\n' +
                        '    return "Buna, " + nume\n' +
                        '\n' +
                        '# cu indicarea tipului\n' +
                        'def salut(nume: str) -> str:\n' +
                        '    return "Buna, " + name'}
                    </SyntaxHighlighter>

                    Indicarea tipului ajuta la documentarea codului, iar acest lucru este o practică recomandată.

                    <br/>
                    Indicarea tipului nu este utilizată în timpul execuției (toate informațiile de tip pe care le lăsați în cod sub formă de adnotări sunt șterse atunci când programul este executat)
                    Indicarea tipului nu are niciun efect asupra funcționării codului.

                    <hr/>
                    <b>3. Docstring</b>
                    <br/>
                    <br/>

                    Toate modulele publice, funcțiile, clasele și metodele care sunt exportate de un anumit modul ar trebui să aibă docstrings.
                    <br/>

                    Metodele non-publice nu trebuie să conțină docstrings.

                    <br/>

                    Pachetele ar trebui să fie documentate in fișierului <b>__init__.py</b> din folderul pachetului.

                    <br/>
                    <br/>

                    Tipuri de docstrings:
                    <ul>
                        <li><i>docstrings pentru atribute</i>: situtate imediat după o instrucțiune de atribuire la nivelul superior al unui modul (atribute ale unui modul), clasă (atribute ale clasei) sau definiția metodei __init__ a unei clase (atribute ale instanței).
                            <br/>
                            Acestea sunt interpretate de instrumentele de extracție, cum ar fi Doctils
                        </li>
                        <li>
                            <i>dosctring-uri suplimentare</i>: situate imediat după un alt docstring
                        </li>
                    </ul>

                    Docstring-urile sunt intre ghilimele triple.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'"""documentatie"""'}
                    </SyntaxHighlighter>

                    Dacă trebuie să utilizați '\' în documentatie, atunci trebuie urmat formatul: <b>r</b>"""ghilimele duble triple""".
                    <br/>
                    Dacă trebuie să utilizați șiruri de documente Unicode, atunci urmat formatul: <b>u</b>"""șiruri de ghilimele triple Unicode""".
                    <br/>
                    <br/>
                    Există două forme de documente:
                    <ul>
                        <li><i>docstrings pe o linie</i> – sunt folosite pentru descrieri simple și scurte și ar trebui sa incapa pe o singură linie</li>
                        <li><i>docstrings pe mai multe rânduri</i> – sunt folosite pentru descrieri mai elaborate și ar trebui să conțină o linie rezumată urmată de o linie goală și o descriere mai elaborată

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'"""documentatie despre femei"""\n' +
                                '\n' +
                                '"""sunt multe de spus"""\n' +
                                '"""si mai ales doar comentarii"""'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Un docstring ar trebui să:
                    <ul>
                        <li>
                            înceapă cu o literă mare (cu excepția cazului în care un identificator începe propoziția) și să se termine cu un punct
                        </li>
                        <li>
                            prescrie efectul segmentului de cod, nu să-l descrie
                        </li>
                        <li>
                            nu repete pur și simplu parametrii funcției sau metodei
                        </li>
                        <li>
                            nu utilizați o linie goală deasupra sau sub un șir de documente cu un singur rând decât dacă documentați o clasă,
                            caz în care ar trebui să puneți o linie goală după toate șirurile de documente care o documentează
                        </li>
                        <li>
                            un șir documentar cu mai multe linii ar trebui să fie indentat la același nivel cu ghilimele deschise
                        </li>

                    </ul>

                    Docstring-urile de script (în sensul de programe autonome/executabile cu un singur fișier) ar trebui să documenteze funcția scriptului, sintaxa liniei de comandă, variabilele de mediu și fișierele.

                    <br/>

                    Docstring-urile pentru module ar trebui să listeze clasele, excepțiile și funcțiile exportate de modul.
                    <br/>

                    Docstring-urile pentru pachete (docstring al modulului __init__.py al pachetului) ar trebui să enumere modulele și subpachetele exportate de pachet.

                    <br/>

                    Docstring-urile pentru functii si module de clasa ar trebui să rezuma comportamentul și să ofere informații despre:
                    <ul>
                        <li>argumente (inclusiv argumente opționale)</li>
                        <li>valori</li>
                        <li>excepții</li>
                        <li>restricții</li>
                    </ul>

                    Docstrings-urile pentru  clase ar trebui să rezuma comportamentul și să documenteze metodele publice și variabilele de instanță.


                    <hr/>
                    <b>4. Tipuri de documentare</b>
                    <br/>
                    <br/>

                    Tipuri de documentare:
                    <ul>
                        <li>reStructuredText (standardul oficial)</li>
                        <li>NumPy/SciPy</li>
                    </ul>

                    Ambele tipuri de documentare sunt acceptate de <b>Sphinx</b>, unul dintre cei mai populari generatori de documentație Python.
                    Sphinx suporta:
                    <ul>
                        <li>formatul HTML ca rezultat</li>
                        <li>testarea automată a fragmentelor de cod</li>
                        <li>referințe încrucișate</li>
                        <li>structură ierarhică</li>
                    </ul>

                    <hr/>
                    <b>5. Documentare proiect</b>
                    <br/>
                    <br/>

                    De obiecei, exista urmatoarele elemente:
                    <ul>
                        <li>fisier <b>readme</b>: scurt rezumat al proiectului, scopul acestuia și, eventual, câteva instrucțiuni de instalare</li>
                        <li>fisier <b>examples.py</b>: script cu exemple de utilizare</li>
                        <li>fisier de <b>licenta</b></li>
                        <li>fișier <b>cum să contribuiți</b>: oferă informații despre posibilele modalități de a contribui la proiect</li>
                    </ul>

                    <hr/>
                    <b>6. Linter și fixer</b>
                    <br/>
                    <br/>

                    <b>Linter</b> = instrument care vă ajută să vă scrieți codul, deoarece îl analizează pentru orice anomalii stilistice și erori de programare în raport cu un set de reguli predefinite;
                    (exemple: Flake8, Pylint , Pyflakes, Pychecker, Mypy și Pycodestyle)

                    <br/>
                    <br/>
                    <b>Fixer</b> = un program care vă ajută să remediați probleme și să vă formatați codul pentru a fi în concordanță cu standardele adoptate
                    (exemple: Black, YAPF și autopep8). Majoritatea editoarelor și IDE-urilor (de exemplu, PyCharm, Spyder, Atom, Sublime Text, Visual Studio, Eclipse + PyDev, VIM sau Thonny) acceptă linter-uri.

                    <hr/>
                    <b>7. Afisare documentatie</b>
                    <br/>
                    <br/>
                    Daca avem o functie (de exemplu aduna) care contine un docstring, acest docstring poate fi afisat in urmatoarele 2 moduri
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(aduna.__doc__)\n' +
                        'help(aduna)'}
                    </SyntaxHighlighter>

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP2 | Best practices and Standardization (Professional 2/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Pep257PythonContent;