import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../elasticsearch/IndexContent";

class DockerElasticsearchContent extends BaseContentPage  {

    constructor(props) {
        super(props, "elasticsearch-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Conectare la masina virtuala:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    Se creeaza o noua retea de tip bridge
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker network create elasticsearch'}
                    </SyntaxHighlighter>

                    Se creeaza containerul elasticsearch
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run \\\n' +
                            '    --network elasticsearch \\\n' +
                            '    --name elasticsearch \\\n' +
                            '    -e "discovery.type=single-node" \\\n' +
                            '    -p 9200:9200 \\\n' +
                            '    elasticsearch:7.6.2'}
                    </SyntaxHighlighter>

                    Se creeaza containerul elasticsearch:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run \\\n' +
                            '    --network elasticsearch \\\n' +
                            '    --name elasticsearch \\\n' +
                            '    -e "discovery.type=single-node" \\\n' +
                            '    -p 9200:9200 \\\n' +
                            '    elasticsearch:7.6.2'}
                    </SyntaxHighlighter>

                    Se creeaza containerul curl:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run \\\n' +
                            '    -it \\\n' +
                            '    --network elasticsearch \\\n' +
                            '    --name curl \\\n' +
                            '    appropriate/curl sh'}
                    </SyntaxHighlighter>

                    <hr/>

                    Din container-ul curl, se creeaza un index:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XPUT http://elasticsearch:9200/my-index'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{"acknowledged":true,"shards_acknowledged":true,"index":"my-index"}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Din container-ul curl, se creeaza afiseaza toti indecsi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET http://elasticsearch:9200/_cat/indices?v'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'health status index    uuid                   pri rep docs.count docs.deleted store.size pri.store.size\n' +
                            'yellow open   my-index 7WEYoruOR1abGMEVSZjgKg   1   1          0            0       230b           230b'}
                    </SyntaxHighlighter>

                    <hr/>

                    Din container-ul curl, se insereaza un document in index:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XPOST http://elasticsearch:9200/my-index/cities/1 \\\n' +
                            '   -H \'Content-Type: application/json\' \\\n' +
                            '   -d \'{"city":"Paris"}\''}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{"_index":"my-index","_type":"cities","_id":"1","_version":1,"result":"created","_shards":{"total":2,"successful":1,"failed":0},"_seq_no":0,"_primary_term":1}'}
                    </SyntaxHighlighter>

                    Mai insearam, inca 2 orase:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XPOST http://elasticsearch:9200/my-index/cities/2 \\\n' +
                            '   -H \'Content-Type: application/json\' \\\n' +
                            '   -d \'{"city":"Londra"}\''}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{"_index":"my-index","_type":"cities","_id":"2","_version":1,"result":"created","_shards":{"total":2,"successful":1,"failed":0},"_seq_no":1,"_primary_term":1}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XPOST http://elasticsearch:9200/my-index/cities/3 \\\n' +
                            '   -H \'Content-Type: application/json\' \\\n' +
                            '   -d \'{"city":"Bucuresti"}\''}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{"_index":"my-index","_type":"cities","_id":"3","_version":1,"result":"created","_shards":{"total":2,"successful":1,"failed":0},"_seq_no":2,"_primary_term":1}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Afisare campuri/proprietati care pot fi folosite in cautare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET http://elasticsearch:9200/my-index/_mapping'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{"my-index":{"mappings":{"properties":{"city":{"type":"text","fields":{"keyword":{"type":"keyword","ignore_above":256}}}}}}}'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET http://elasticsearch:9200/my-index/_mapping?pretty'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  "my-index" : {\n' +
                            '    "mappings" : {\n' +
                            '      "properties" : {\n' +
                            '        "city" : {\n' +
                            '          "type" : "text",\n' +
                            '          "fields" : {\n' +
                            '            "keyword" : {\n' +
                            '              "type" : "keyword",\n' +
                            '              "ignore_above" : 256\n' +
                            '            }\n' +
                            '          }\n' +
                            '        }\n' +
                            '      }\n' +
                            '    }\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Deci avem o singura proprietate <i>city</i>

                    <hr/>

                    Cautare dupa id:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET http://elasticsearch:9200/my-index/cities/1?pretty'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  "_index" : "my-index",\n' +
                            '  "_type" : "cities",\n' +
                            '  "_id" : "1",\n' +
                            '  "_version" : 1,\n' +
                            '  "_seq_no" : 0,\n' +
                            '  "_primary_term" : 1,\n' +
                            '  "found" : true,\n' +
                            '  "_source" : {\n' +
                            '    "city" : "Paris"\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Cautare toate documentele:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET http://elasticsearch:9200/my-index/_search?pretty'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  "took" : 474,\n' +
                            '  "timed_out" : false,\n' +
                            '  "_shards" : {\n' +
                            '    "total" : 1,\n' +
                            '    "successful" : 1,\n' +
                            '    "skipped" : 0,\n' +
                            '    "failed" : 0\n' +
                            '  },\n' +
                            '  "hits" : {\n' +
                            '    "total" : {\n' +
                            '      "value" : 3,\n' +
                            '      "relation" : "eq"\n' +
                            '    },\n' +
                            '    "max_score" : 1.0,\n' +
                            '    "hits" : [\n' +
                            '      {\n' +
                            '        "_index" : "my-index",\n' +
                            '        "_type" : "cities",\n' +
                            '        "_id" : "1",\n' +
                            '        "_score" : 1.0,\n' +
                            '        "_source" : {\n' +
                            '          "city" : "Paris"\n' +
                            '        }\n' +
                            '      },\n' +
                            '      {\n' +
                            '        "_index" : "my-index",\n' +
                            '        "_type" : "cities",\n' +
                            '        "_id" : "2",\n' +
                            '        "_score" : 1.0,\n' +
                            '        "_source" : {\n' +
                            '          "city" : "Londra"\n' +
                            '        }\n' +
                            '      },\n' +
                            '      {\n' +
                            '        "_index" : "my-index",\n' +
                            '        "_type" : "cities",\n' +
                            '        "_id" : "3",\n' +
                            '        "_score" : 1.0,\n' +
                            '        "_source" : {\n' +
                            '          "city" : "Bucuresti"\n' +
                            '        }\n' +
                            '      }\n' +
                            '    ]\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Cautare documente dupa un camp:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XGET http://elasticsearch:9200/my-index/_search?q=city:bucuresti'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'{"took":6,"timed_out":false,"_shards":{"total":1,"successful":1,"skipped":0,"failed":0},"hits":{"total":{"value":1,"relation":"eq"},"max_score":0.9808291,"hits":[{"_index":"my-index","_type":"cities","_id":"3","_score":0.9808291,"_source":{"city":"Bucuresti"}}]}}'}
                    </SyntaxHighlighter>

{/*docker network create elasticsearch*/}
{/*docker run \*/}
{/*    --network elasticsearch \*/}
{/*    --name elasticsearch \*/}
{/*    -e "discovery.type=single-node" \*/}
{/*    -p 9200:9200 \*/}
{/*    elasticsearch:7.6.2*/}

{/*docker run \*/}
{/*    -it \*/}
{/*    --network elasticsearch \*/}
{/*    --name curl \*/}
{/*    appropriate/curl sh*/}


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerElasticsearchContent;