import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class VariablesRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-variables", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Variabile</b>
                    <br/>
                    <br/>

                    Variabile sunt imutabile in mod implicit (nu se mod modifica):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'let x = 2;'}
                    </SyntaxHighlighter>
                    Motivul pentru care variabile in Rust sunt imutabile: siguranta, concurenta, viteza.
                    <br/>
                    Daca nu e declarat tipul, se poate determina automat; pentru intregi este <b>i32</b>  (x din exemplul de mai sus este de tip i32)!
                    <br/>
                    In plus, variabile trebuie initializate inainte de a fi folosite:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'    let x:i32;\n' +
                            '    println!("x = {}", x); // eroare'}
                    </SyntaxHighlighter>

                    Urmatorul cod va genera eroarea:  `y` used here but it is possibly-uninitialized
                    <SyntaxHighlighter>
                        {' let y:i32;\n' +
                            '    if true {\n' +
                            '        y = 2;\n' +
                            '    }\n' +
                            '    println!("y = {}", y); // eroare'}
                    </SyntaxHighlighter>

                    Urmatorul cod nu are eroare:
                    <SyntaxHighlighter>
                        {'  let y: i32;\n' +
                            '    if true {\n' +
                            '        y = 2;\n' +
                            '    } else {\n' +
                            '        y = 1;\n' +
                            '    }\n' +
                            '    println!("y = {}", y); '}
                    </SyntaxHighlighter>


                    <hr/>
                    Pentru a declara o variabila mutabila:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'let mut x = 2;'}
                    </SyntaxHighlighter>

                    Declarare constante:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'const NUME_CONSTANTA:tip = valoare;'}
                    </SyntaxHighlighter>
                    Prin conventie numele unui constante este scris cu litere mari.
                    <br/>
                    O constanta trebuie sa aiba declarat explicit tipul.

                    <br/>
                    Exemplu corect:
                    <SyntaxHighlighter>
                        {'const X:i8=1;'}
                    </SyntaxHighlighter>
                    Exemplu (eroare - nu are tip declarat):
                    <SyntaxHighlighter>
                        {'const X=1;'}
                    </SyntaxHighlighter>

                    Specificarea tipului:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'let x:i32 = 2;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'let (x, y) = (2, 3);'}
                    </SyntaxHighlighter>

                    O variabila mutabila poate deveni imutabila si invers; variabile se pot redeclara:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'let mut a=2;\n' +
                            'let a=a;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'let mut a=2;\n' +
                            'let a=a;\n' +
                            'let mut a=3;'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VariablesRustContent;