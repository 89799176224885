import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class ProjectsGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-projects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    <b>1. Proiect</b>
                    <br/>
                    <br/>

                    Un <b>proiect</b> este un concept abstract care reprezinta un artefact care trebuie construit (build).
                    Pentru fiecare proiect, Gradle creaza o instanta <b>org.gradle.api.Project</b> si il asociaza cu scriptul de build.
                    Acest lucru, permite ca script-ul de build sa aiba acces la proprietatile API-ului definit de <b>org.gradle.api.Project</b>.

                    <br/>
                    <br/>
                    De exemplu, daca avem fisierul <i>build-project.gradle</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println name;\n' +
                        'println "nume proiect=${project.name}";'}
                    </SyntaxHighlighter>

                    Si il rulam cu urmatoarea comanda <i>gradle -b build-project.gradle -q</i>, vom obtine:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'>gradle -b build-project.gradle -q\n' +
                        'gradle\n' +
                        'nume proiect=gradle'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Metode si proprietati pentru Project</b>
                    <br/>
                    <br/>

                    Proprietati/Metode:
                    <table>
                        <thead>
                        <tr>
                            <th>proprietate</th>
                            <th>descriere</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>name</td>
                            <td>numele proiectului;
                                <br/>
                                poate fi schimbat din fisierul <b>settings.gradle</b>;
                                <br/>
                                in mod implicit, numele proiectului este identic cu numele directorului in care se afla proiectul
                            </td>
                        </tr>
                        <tr>
                            <td>description</td>
                            <td>descrierea proiectului;
                                <br/>
                                de obicei, este setat din fisierul <b>gradle.build</b>
                            </td>
                        </tr>
                        <tr>
                            <td>version</td>
                            <td>versiunea proiectului</td>
                        </tr>
                        <tr>
                            <td>parent</td>
                            <td>parintele proiectului (daca exista)</td>
                        </tr>
                        <tr>
                            <td>group</td>
                            <td>numele grupului din care face parte proiectul; grupul reprezinta o modalitate indica grupul sau organizatia responsabila de proiect</td>
                        </tr>

                        <tr>
                            <td>ext</td>
                            <td>permite adaugare de noi proprietati la proiect
                                <br/>
                                se pot configura:
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {'ext{\n' +
                                    '   proprietate=valoare\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                sau
                                <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                    {'ext.proprietate=valoare'}
                                </SyntaxHighlighter>
                            </td>
                        </tr>

                        <tr>
                            <td>task</td>
                            <td>suprascrie metoda de creare a unui nou task</td>
                        </tr>

                        <tr>
                            <td>defaultTasks</td>
                            <td>configureaza numele implicite de task-uri ce vor fi rulate pentru proiect</td>
                        </tr>

                        <tr>
                            <td>dependecies</td>
                            <td>configureaza dependintele pentru proiect</td>
                        </tr>

                        <tr>
                            <td>repositories</td>
                            <td>configureaza repositoriurile pentru proiect</td>
                        </tr>

                        </tbody>
                    </table>

                    <b>Observatii</b>:
                    <ul>
                        <li>daca se definesc proprietatile proiectului inainte de blocul plugins{} se va arunca exceptie</li>
                        <li>doar blocurile: buildscript {}, pluginManagement {} si alte plugins {} sunt permise intainte de blocul plugins{}</li>
                        <li>daca este pusa proprietatea <b>version</b> si exista plugin-ul cu id-ul <i>application</i> aceasta se va concatena la artefactul rezultat in urma <i>build</i>-ului</li>
                    </ul>

                    Daca se ruleaza: <b>gradle build</b> din <i>D:\work-resources\gradle\proiect-java</i> (si exista modulul app):
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins {\n' +
                        '    // Apply the application plugin to add support for building a CLI application in Java.\n' +
                        '    id \'application\'\n' +
                        '}\n' +
                        '\n' +
                        '// daca se scrie inainte de blocul plugins{} se va arunca exceptie;\n' +
                        '\n' +
                        '/*\n' +
                        'doar blocurile: buildscript {}, pluginManagement {} si alte plugins {} sunt permise intainte de blocul plugins{}\n' +
                        '*/\n' +
                        '\n' +
                        'project.description = "Proiect Java"\n' +
                        'project.version = 1.0 \n' +
                        '\n' +
                        'println name // app \n' +
                        'println project.name // app \n' +
                        'println project.description // Proiect Java\n' +
                        'println project.version // 1.0\n' +
                        'println project.path //:app\n' +
                        'println project.projectDir // D:\\work-resources\\gradle\\proiect-java\\app'}
                    </SyntaxHighlighter>

                    Pentru a adauga noi proprietati se foloseste <b>ext</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'project.ext.proprietateNoua="ABC"'}
                    </SyntaxHighlighter>
                    Pentru a accesa noua proprietate:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println project.proprietateNoua\n' +
                        'println project.property("proprietateNoua")'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ProjectsGradleContent;