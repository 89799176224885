import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InstanceableLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-instanceable", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    La definirea unui portlet se poate seta proprietatea:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'com.liferay.portlet.instanceable=true'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=HelloWorld",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class HelloWorldPortlet extends MVCPortlet {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Daca <b>com.liferay.portlet.instanceable</b> este:
                    <ul>
                        <li>true: atunci portletul se poate adauga de mai multe ori pe aceeasi pagina (nu conține niciun ID de instanță)</li>
                        <li>false: atunci portletul se poate adauga doar o singura data pe o pagina (fiecare instanță conține un ID de instanță diferit)</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default InstanceableLiferayContent;