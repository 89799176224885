import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class LifecyclePluginsMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-lifecycle-plugins", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Lifecycle (ciclul de viata)</b>
                    <br/>
                    <br/>

                    Există trei cicluri principale de viață:
                    <ul>
                        <li>
                            <b>implicit</b>: se ocupă cu construirea și implementarea artefactului
                        </li>
                        <li>
                            <b>clean</b>: se ocupă cu curățarea proiectului
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn clean'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>site</b>: genereaza un site cu documentația proiectului
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn site'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Un ciclu de viață este alcătuit din faze.
                    <br/>
                    De exemplu, pentru ciclul de viata implicit, exista urmatoarele faze mai importante:
                    <ul>
                        <li><b>compile</b>: compileaza fișierele sursă:

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn compile'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>test</b>: folosit pentru a rula toate testele unitare prezente în proiect

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn test'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>install</b>: va instala artefactul rezultat într-un depozit local (locația depozitului local este de obicei [user.home]/.m2/).
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn install'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>package</b>: va împacheta artefactul (jar-ul)
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn package'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>deploy</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'mvn deploy'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    Pentru a afla mai multe detalii despre un ciclu de viata, de exemplu <i>clean</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:describe -Dcmd=clean'}
                    </SyntaxHighlighter>

                    Va afisa:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] -----------------< ro.maven.learn:maven-project-learn >-----------------\n' +
                            '[INFO] Building maven-project-learn 1.0-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO] \n' +
                            '[INFO] --- maven-help-plugin:3.2.0:describe (default-cli) @ maven-project-learn ---\n' +
                            '[INFO] \'clean\' is a phase within the \'clean\' lifecycle, which has the following phases: \n' +
                            '* pre-clean: Not defined\n' +
                            '* clean: org.apache.maven.plugins:maven-clean-plugin:2.5:clean\n' +
                            '* post-clean: Not defined\n' +
                            '\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  0.659 s\n' +
                            '[INFO] Finished at: 2022-09-15T22:52:51+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>
                    Se observa ca faza <i>clean</i> are 3 faze:
                    <ul>
                        <li>pre-clean</li>
                        <li>clean</li>
                        <li>post-clean</li>
                    </ul>

                    Pentru faza <i>deploy</i>
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:describe -Dcmd=deploy'}
                    </SyntaxHighlighter>
                    se va afisa:

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] -----------------< ro.maven.learn:maven-project-learn >-----------------\n' +
                            '[INFO] Building maven-project-learn 1.0-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO] \n' +
                            '[INFO] --- maven-help-plugin:3.2.0:describe (default-cli) @ maven-project-learn ---\n' +
                            '[INFO] \'deploy\' is a phase corresponding to this plugin:\n' +
                            'org.apache.maven.plugins:maven-deploy-plugin:2.7:deploy\n' +
                            '\n' +
                            'It is a part of the lifecycle for the POM packaging \'jar\'. This lifecycle includes the following phases:\n' +
                            '* validate: Not defined\n' +
                            '* initialize: Not defined\n' +
                            '* generate-sources: Not defined\n' +
                            '* process-sources: Not defined\n' +
                            '* generate-resources: Not defined\n' +
                            '* process-resources: org.apache.maven.plugins:maven-resources-plugin:2.6:resources\n' +
                            '* compile: org.apache.maven.plugins:maven-compiler-plugin:3.1:compile\n' +
                            '* process-classes: Not defined\n' +
                            '* generate-test-sources: Not defined\n' +
                            '* process-test-sources: Not defined\n' +
                            '* generate-test-resources: Not defined\n' +
                            '* process-test-resources: org.apache.maven.plugins:maven-resources-plugin:2.6:testResources\n' +
                            '* test-compile: org.apache.maven.plugins:maven-compiler-plugin:3.1:testCompile\n' +
                            '* process-test-classes: Not defined\n' +
                            '* test: org.apache.maven.plugins:maven-surefire-plugin:2.12.4:test\n' +
                            '* prepare-package: Not defined\n' +
                            '* package: org.apache.maven.plugins:maven-jar-plugin:2.4:jar\n' +
                            '* pre-integration-test: Not defined\n' +
                            '* integration-test: Not defined\n' +
                            '* post-integration-test: Not defined\n' +
                            '* verify: Not defined\n' +
                            '* install: org.apache.maven.plugins:maven-install-plugin:2.4:install\n' +
                            '* deploy: org.apache.maven.plugins:maven-deploy-plugin:2.7:deploy\n' +
                            '\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  0.737 s\n' +
                            '[INFO] Finished at: 2022-09-15T22:57:19+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>

                    Pentru a instala proiectul, curantand inainte:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean install'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Plugins. Goals</b>
                    <br/>
                    <br/>

                    <i>Plugin-urile</i> sunt in mod de a aduce Maven-ului <i>goals</i>.
                    <br/>
                    De exemplu plugin-ul <i>compiler</i> are 2 goals:
                    <ul>
                        <li>compile</li>
                        <li>test-compile</li>
                    </ul>

                    Executia unui goals (<i>compile</i>)
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:compile'}
                    </SyntaxHighlighter>

                    Daca vom rula:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:effective-pom'}
                    </SyntaxHighlighter>
                    Vom observa ce plugin-uri putem sa folosim. De exemplu, 2 exemple (clean si compiler):

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {' <plugins>\n' +
                            '      <plugin>\n' +
                            '        <artifactId>maven-clean-plugin</artifactId>\n' +
                            '        <version>2.5</version>\n' +
                            '        <executions>\n' +
                            '          <execution>\n' +
                            '            <id>default-clean</id>\n' +
                            '            <phase>clean</phase>\n' +
                            '            <goals>\n' +
                            '              <goal>clean</goal>\n' +
                            '            </goals>\n' +
                            '          </execution>\n' +
                            '        </executions>\n' +
                            '      </plugin>\n' +
                            '      <plugin>\n' +
                            '      <plugin>\n' +
                            '        <artifactId>maven-compiler-plugin</artifactId>\n' +
                            '        <version>3.1</version>\n' +
                            '        <executions>\n' +
                            '          <execution>\n' +
                            '            <id>default-compile</id>\n' +
                            '            <phase>compile</phase>\n' +
                            '            <goals>\n' +
                            '              <goal>compile</goal>\n' +
                            '            </goals>\n' +
                            '          </execution>\n' +
                            '          <execution>\n' +
                            '            <id>default-testCompile</id>\n' +
                            '            <phase>test-compile</phase>\n' +
                            '            <goals>\n' +
                            '              <goal>testCompile</goal>\n' +
                            '            </goals>\n' +
                            '          </execution>\n' +
                            '        </executions>\n' +
                            '      </plugin>\n'}
                    </SyntaxHighlighter>

                    Vom regasi cele 2 gosls:

                    <ul>
                        <li>compile</li>
                        <li>test-compile</li>
                    </ul>

                    Pentru a afisa mai multe detalii legate de plugin-ul <i>compiler</i> (se afiseaza ce <i>goal</i>-uri are)
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:describe -Dplugin=compiler'}
                    </SyntaxHighlighter>
                    Se va afisa:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] -----------------< ro.maven.learn:maven-project-learn >-----------------\n' +
                            '[INFO] Building maven-project-learn 1.0-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO] \n' +
                            '[INFO] --- maven-help-plugin:3.2.0:describe (default-cli) @ maven-project-learn ---\n' +
                            '[INFO] org.apache.maven.plugins:maven-compiler-plugin:3.1\n' +
                            '\n' +
                            'Name: Maven Compiler Plugin\n' +
                            'Description: The Compiler Plugin is used to compile the sources of your\n' +
                            '  project.\n' +
                            'Group Id: org.apache.maven.plugins\n' +
                            'Artifact Id: maven-compiler-plugin\n' +
                            'Version: 3.1\n' +
                            'Goal Prefix: compiler\n' +
                            '\n' +
                            'This plugin has 3 goals:\n' +
                            '\n' +
                            'compiler:compile\n' +
                            '  Description: Compiles application sources\n' +
                            '\n' +
                            'compiler:help\n' +
                            '  Description: Display help information on maven-compiler-plugin.\n' +
                            '    Call mvn compiler:help -Ddetail=true -Dgoal=<goal-name> to display\n' +
                            '    parameter details.\n' +
                            '\n' +
                            'compiler:testCompile\n' +
                            '  Description: Compiles application test sources.\n' +
                            '\n' +
                            'For more information, run \'mvn help:describe [...] -Ddetail\'\n' +
                            '\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  1.149 s\n' +
                            '[INFO] Finished at: 2022-09-15T23:21:48+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>

                    Pentru a afisa mai multe detalii legate de un <i>goal</i> a unui <i>plugin</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:help -Ddetail=true -Dgoal=compile'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {' mvn compiler:help -Ddetail=true -Dgoal=compile\n' +
                            '[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] -----------------< ro.maven.learn:maven-project-learn >-----------------\n' +
                            '[INFO] Building maven-project-learn 1.0-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO] \n' +
                            '[INFO] --- maven-compiler-plugin:3.1:help (default-cli) @ maven-project-learn ---\n' +
                            '[INFO] Maven Compiler Plugin 3.1\n' +
                            '  The Compiler Plugin is used to compile the sources of your project.\n' +
                            '\n' +
                            'compiler:compile\n' +
                            '  Compiles application sources\n' +
                            '\n' +
                            '  Available parameters:\n' +
                            '\n' +
                            '    annotationProcessors\n' +
                            '      Names of annotation processors to run. Only applies to JDK 1.6+ If not\n' +
                            '      set, the default annotation processors discovery process applies.\n' +
                            '\n' +
                            '    compilerArgs\n' +
                            '      Sets the arguments to be passed to the compiler if fork is set to true.\n' +
                            '      Example:\n' +
                            '\n' +
                            '      <compilerArgs>\n' +
                            '       <arg>-Xmaxerrs=1000</arg>\n' +
                            '       <arg>-Xlint</arg>\n' +
                            '      </compilerArgs>\n' +
                            '\n' +
                            '    compilerArgument\n' +
                            '      Sets the unformatted single argument string to be passed to the compiler\n' +
                            '      if fork is set to true. To pass multiple arguments such as -Xmaxerrs 1000\n' +
                            '      (which are actually two arguments) you have to use compilerArguments.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '    compilerArguments\n' +
                            '      Sets the arguments to be passed to the compiler (prepending a dash) if\n' +
                            '      fork is set to true.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '      To pass -Xmaxerrs 1000 -Xlint -Xlint:-path -Averbose=true you should\n' +
                            '      include the following:\n' +
                            '\n' +
                            '      <compilerArguments>\n' +
                            '       <Xmaxerrs>1000</Xmaxerrs>\n' +
                            '       <Xlint/>\n' +
                            '       <Xlint:-path/>\n' +
                            '       <Averbose>true</Averbose>\n' +
                            '      </compilerArguments>\n' +
                            '\n' +
                            '    compilerId\n' +
                            '      The compiler id of the compiler to use. See this guide for more\n' +
                            '      information.\n' +
                            '\n' +
                            '    compilerReuseStrategy\n' +
                            '      Strategy to re use javacc class created:\n' +
                            '      - reuseCreated (default): will reuse already created but in case of\n' +
                            '        multi-threaded builds, each thread will have its own instance\n' +
                            '      - reuseSame: the same Javacc class will be used for each compilation even\n' +
                            '        for multi-threaded build\n' +
                            '      - alwaysNew: a new Javacc class will be created for each compilation\n' +
                            '      Note this parameter value depends on the os/jdk you are using, but the\n' +
                            '      default value should work on most of env.\n' +
                            '\n' +
                            '    compilerVersion\n' +
                            '      Version of the compiler to use, ex. \'1.3\', \'1.5\', if fork is set to true.\n' +
                            '\n' +
                            '    debug\n' +
                            '      Set to true to include debugging information in the compiled class files.\n' +
                            '\n' +
                            '    debuglevel\n' +
                            '      Keyword list to be appended to the -g command-line switch. Legal values\n' +
                            '      are none or a comma-separated list of the following keywords: lines, vars,\n' +
                            '      and source. If debug level is not specified, by default, nothing will be\n' +
                            '      appended to -g. If debug is not turned on, this attribute will be ignored.\n' +
                            '\n' +
                            '    encoding\n' +
                            '      The -encoding argument for the Java compiler.\n' +
                            '\n' +
                            '    excludes\n' +
                            '      A list of exclusion filters for the compiler.\n' +
                            '\n' +
                            '    executable\n' +
                            '      Sets the executable of the compiler to use when fork is true.\n' +
                            '\n' +
                            '    failOnError\n' +
                            '      Indicates whether the build will continue even if there are compilation\n' +
                            '      errors.\n' +
                            '\n' +
                            '    fileExtensions\n' +
                            '      file extensions to check timestamp for incremental build default contains\n' +
                            '      only .class\n' +
                            '\n' +
                            '    forceJavacCompilerUse\n' +
                            '      compiler can now use javax.tools if available in your current jdk, you can\n' +
                            '      disable this feature using -Dmaven.compiler.forceJavacCompilerUse=true or\n' +
                            '      in the plugin configuration\n' +
                            '\n' +
                            '    fork\n' +
                            '      Allows running the compiler in a separate process. If false it uses the\n' +
                            '      built in compiler, while if true it will use an executable.\n' +
                            '\n' +
                            '    generatedSourcesDirectory\n' +
                            '      Specify where to place generated source files created by annotation\n' +
                            '      processing. Only applies to JDK 1.6+\n' +
                            '\n' +
                            '    includes\n' +
                            '      A list of inclusion filters for the compiler.\n' +
                            '\n' +
                            '    maxmem\n' +
                            '      Sets the maximum size, in megabytes, of the memory allocation pool, ex.\n' +
                            '      \'128\', \'128m\' if fork is set to true.\n' +
                            '\n' +
                            '    meminitial\n' +
                            '      Initial size, in megabytes, of the memory allocation pool, ex. \'64\', \'64m\'\n' +
                            '      if fork is set to true.\n' +
                            '\n' +
                            '    mojoExecution\n' +
                            '\n' +
                            '\n' +
                            '    optimize\n' +
                            '      Set to true to optimize the compiled code using the compiler\'s\n' +
                            '      optimization methods.\n' +
                            '\n' +
                            '    outputFileName\n' +
                            '      Sets the name of the output file when compiling a set of sources to a\n' +
                            '      single file. expression=\'${project.build.finalName}\'\n' +
                            '\n' +
                            '    proc\n' +
                            '      Sets whether annotation processing is performed or not. Only applies to\n' +
                            '      JDK 1.6+ If not set, both compilation and annotation processing are\n' +
                            '      performed at the same time.\n' +
                            '\n' +
                            '      Allowed values are:\n' +
                            '\n' +
                            '      - none - no annotation processing is performed.\n' +
                            '      - only - only annotation processing is done, no compilation.\n' +
                            '\n' +
                            '    showDeprecation\n' +
                            '      Sets whether to show source locations where deprecated APIs are used.\n' +
                            '\n' +
                            '    showWarnings\n' +
                            '      Set to true to show compilation warnings.\n' +
                            '\n' +
                            '    skipMain\n' +
                            '      Set this to \'true\' to bypass compilation of main sources. Its use is NOT\n' +
                            '      RECOMMENDED, but quite convenient on occasion.\n' +
                            '\n' +
                            '    skipMultiThreadWarning\n' +
                            '\n' +
                            '\n' +
                            '    source\n' +
                            '      The -source argument for the Java compiler.\n' +
                            '\n' +
                            '    staleMillis\n' +
                            '      Sets the granularity in milliseconds of the last modification date for\n' +
                            '      testing whether a source needs recompilation.\n' +
                            '\n' +
                            '    target\n' +
                            '      The -target argument for the Java compiler.\n' +
                            '\n' +
                            '    useIncrementalCompilation\n' +
                            '      to enable/disable incrementation compilation feature\n' +
                            '\n' +
                            '    verbose\n' +
                            '      Set to true to show messages about what the compiler is doing.\n' +
                            '\n' +
                            '\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  0.897 s\n' +
                            '[INFO] Finished at: 2022-09-15T23:25:47+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Proprietati plugin</b>
                    <br/>
                    <br/>

                    Pentru a afisa mai multe detalii legate de plugin-ul <i>compiler</i> (se afiseaza doar ce <i>goal</i>-uri are)
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:describe -Dplugin=compiler'}
                    </SyntaxHighlighter>

                    Pentru a afisa mai multe detalii despre un plugin:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:help -Dcmd=compiler:compile -Ddetail=true'}
                    </SyntaxHighlighter>

                    Se va afisa
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:help -Dcmd=compiler:compile -Ddetail=true\n' +
                            '[INFO] Scanning for projects...\n' +
                            '[INFO] \n' +
                            '[INFO] -----------------< ro.maven.learn:maven-project-learn >-----------------\n' +
                            '[INFO] Building maven-project-learn 1.0-SNAPSHOT\n' +
                            '[INFO] --------------------------------[ jar ]---------------------------------\n' +
                            '[INFO]\n' +
                            '[INFO] --- maven-compiler-plugin:3.1:help (default-cli) @ maven-project-learn ---\n' +
                            '[INFO] Maven Compiler Plugin 3.1\n' +
                            '  The Compiler Plugin is used to compile the sources of your project.\n' +
                            '\n' +
                            'This plugin has 3 goals:\n' +
                            '\n' +
                            'compiler:compile\n' +
                            '  Compiles application sources\n' +
                            '\n' +
                            '  Available parameters:\n' +
                            '\n' +
                            '    annotationProcessors\n' +
                            '      Names of annotation processors to run. Only applies to JDK 1.6+ If not\n' +
                            '      set, the default annotation processors discovery process applies.\n' +
                            '\n' +
                            '    compilerArgs\n' +
                            '      Sets the arguments to be passed to the compiler if fork is set to true.\n' +
                            '      Example:\n' +
                            '\n' +
                            '      <compilerArgs>\n' +
                            '       <arg>-Xmaxerrs=1000</arg>\n' +
                            '       <arg>-Xlint</arg>\n' +
                            '      </compilerArgs>\n' +
                            '\n' +
                            '    compilerArgument\n' +
                            '      Sets the unformatted single argument string to be passed to the compiler\n' +
                            '      if fork is set to true. To pass multiple arguments such as -Xmaxerrs 1000\n' +
                            '      (which are actually two arguments) you have to use compilerArguments.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '    compilerArguments\n' +
                            '      Sets the arguments to be passed to the compiler (prepending a dash) if\n' +
                            '      fork is set to true.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '      To pass -Xmaxerrs 1000 -Xlint -Xlint:-path -Averbose=true you should\n' +
                            '      include the following:\n' +
                            '\n' +
                            '      <compilerArguments>\n' +
                            '       <Xmaxerrs>1000</Xmaxerrs>\n' +
                            '       <Xlint/>\n' +
                            '       <Xlint:-path/>\n' +
                            '       <Averbose>true</Averbose>\n' +
                            '      </compilerArguments>\n' +
                            '\n' +
                            '    compilerId\n' +
                            '      The compiler id of the compiler to use. See this guide for more\n' +
                            '      information.\n' +
                            '\n' +
                            '    compilerReuseStrategy\n' +
                            '      Strategy to re use javacc class created:\n' +
                            '      - reuseCreated (default): will reuse already created but in case of\n' +
                            '        multi-threaded builds, each thread will have its own instance\n' +
                            '      - reuseSame: the same Javacc class will be used for each compilation even\n' +
                            '        for multi-threaded build\n' +
                            '      - alwaysNew: a new Javacc class will be created for each compilation\n' +
                            '      Note this parameter value depends on the os/jdk you are using, but the\n' +
                            '      default value should work on most of env.\n' +
                            '\n' +
                            '    compilerVersion\n' +
                            '      Version of the compiler to use, ex. \'1.3\', \'1.5\', if fork is set to true.\n' +
                            '\n' +
                            '    debug\n' +
                            '      Set to true to include debugging information in the compiled class files.\n' +
                            '\n' +
                            '    debuglevel\n' +
                            '      Keyword list to be appended to the -g command-line switch. Legal values\n' +
                            '      are none or a comma-separated list of the following keywords: lines, vars,\n' +
                            '      and source. If debug level is not specified, by default, nothing will be\n' +
                            '      appended to -g. If debug is not turned on, this attribute will be ignored.\n' +
                            '\n' +
                            '    encoding\n' +
                            '      The -encoding argument for the Java compiler.\n' +
                            '\n' +
                            '    excludes\n' +
                            '      A list of exclusion filters for the compiler.\n' +
                            '\n' +
                            '    executable\n' +
                            '      Sets the executable of the compiler to use when fork is true.\n' +
                            '\n' +
                            '    failOnError\n' +
                            '      Indicates whether the build will continue even if there are compilation\n' +
                            '      errors.\n' +
                            '\n' +
                            '    fileExtensions\n' +
                            '      file extensions to check timestamp for incremental build default contains\n' +
                            '      only .class\n' +
                            '\n' +
                            '    forceJavacCompilerUse\n' +
                            '      compiler can now use javax.tools if available in your current jdk, you can\n' +
                            '      disable this feature using -Dmaven.compiler.forceJavacCompilerUse=true or\n' +
                            '      in the plugin configuration\n' +
                            '\n' +
                            '    fork\n' +
                            '      Allows running the compiler in a separate process. If false it uses the\n' +
                            '      built in compiler, while if true it will use an executable.\n' +
                            '\n' +
                            '    generatedSourcesDirectory\n' +
                            '      Specify where to place generated source files created by annotation\n' +
                            '      processing. Only applies to JDK 1.6+\n' +
                            '\n' +
                            '    includes\n' +
                            '      A list of inclusion filters for the compiler.\n' +
                            '\n' +
                            '    maxmem\n' +
                            '      Sets the maximum size, in megabytes, of the memory allocation pool, ex.\n' +
                            '      \'128\', \'128m\' if fork is set to true.\n' +
                            '\n' +
                            '    meminitial\n' +
                            '      Initial size, in megabytes, of the memory allocation pool, ex. \'64\', \'64m\'\n' +
                            '      if fork is set to true.\n' +
                            '\n' +
                            '    mojoExecution\n' +
                            '\n' +
                            '\n' +
                            '    optimize\n' +
                            '      Set to true to optimize the compiled code using the compiler\'s\n' +
                            '      optimization methods.\n' +
                            '\n' +
                            '    outputFileName\n' +
                            '      Sets the name of the output file when compiling a set of sources to a\n' +
                            '      single file. expression=\'${project.build.finalName}\'\n' +
                            '\n' +
                            '    proc\n' +
                            '      Sets whether annotation processing is performed or not. Only applies to\n' +
                            '      JDK 1.6+ If not set, both compilation and annotation processing are\n' +
                            '      performed at the same time.\n' +
                            '\n' +
                            '      Allowed values are:\n' +
                            '\n' +
                            '      - none - no annotation processing is performed.\n' +
                            '      - only - only annotation processing is done, no compilation.\n' +
                            '\n' +
                            '    showDeprecation\n' +
                            '      Sets whether to show source locations where deprecated APIs are used.\n' +
                            '\n' +
                            '    showWarnings\n' +
                            '      Set to true to show compilation warnings.\n' +
                            '\n' +
                            '    skipMain\n' +
                            '      Set this to \'true\' to bypass compilation of main sources. Its use is NOT\n' +
                            '      RECOMMENDED, but quite convenient on occasion.\n' +
                            '\n' +
                            '    skipMultiThreadWarning\n' +
                            '\n' +
                            '\n' +
                            '    source\n' +
                            '      The -source argument for the Java compiler.\n' +
                            '\n' +
                            '    staleMillis\n' +
                            '      Sets the granularity in milliseconds of the last modification date for\n' +
                            '      testing whether a source needs recompilation.\n' +
                            '\n' +
                            '    target\n' +
                            '      The -target argument for the Java compiler.\n' +
                            '\n' +
                            '    useIncrementalCompilation\n' +
                            '      to enable/disable incrementation compilation feature\n' +
                            '\n' +
                            '    verbose\n' +
                            '      Set to true to show messages about what the compiler is doing.\n' +
                            '\n' +
                            'compiler:help\n' +
                            '  Display help information on maven-compiler-plugin.\n' +
                            '  Call mvn compiler:help -Ddetail=true -Dgoal=<goal-name> to display parameter\n' +
                            '  details.\n' +
                            '\n' +
                            '  Available parameters:\n' +
                            '\n' +
                            '    detail\n' +
                            '      If true, display all settable properties for each goal.\n' +
                            '\n' +
                            '    goal\n' +
                            '      The name of the goal for which to show help. If unspecified, all goals\n' +
                            '      will be displayed.\n' +
                            '\n' +
                            '    indentSize\n' +
                            '      The number of spaces per indentation level, should be positive.\n' +
                            '\n' +
                            '    lineLength\n' +
                            '      The maximum length of a display line, should be positive.\n' +
                            '\n' +
                            'compiler:testCompile\n' +
                            '  Compiles application test sources.\n' +
                            '\n' +
                            '  Available parameters:\n' +
                            '\n' +
                            '    annotationProcessors\n' +
                            '      Names of annotation processors to run. Only applies to JDK 1.6+ If not\n' +
                            '      set, the default annotation processors discovery process applies.\n' +
                            '\n' +
                            '    compilerArgs\n' +
                            '      Sets the arguments to be passed to the compiler if fork is set to true.\n' +
                            '      Example:\n' +
                            '\n' +
                            '      <compilerArgs>\n' +
                            '       <arg>-Xmaxerrs=1000</arg>\n' +
                            '       <arg>-Xlint</arg>\n' +
                            '      </compilerArgs>\n' +
                            '\n' +
                            '    compilerArgument\n' +
                            '      Sets the unformatted single argument string to be passed to the compiler\n' +
                            '      if fork is set to true. To pass multiple arguments such as -Xmaxerrs 1000\n' +
                            '      (which are actually two arguments) you have to use compilerArguments.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '    compilerArguments\n' +
                            '      Sets the arguments to be passed to the compiler (prepending a dash) if\n' +
                            '      fork is set to true.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '      To pass -Xmaxerrs 1000 -Xlint -Xlint:-path -Averbose=true you should\n' +
                            '      include the following:\n' +
                            '\n' +
                            '      <compilerArguments>\n' +
                            '       <Xmaxerrs>1000</Xmaxerrs>\n' +
                            '       <Xlint/>\n' +
                            '       <Xlint:-path/>\n' +
                            '       <Averbose>true</Averbose>\n' +
                            '      </compilerArguments>\n' +
                            '\n' +
                            '    compilerId\n' +
                            '      The compiler id of the compiler to use. See this guide for more\n' +
                            '      information.\n' +
                            '\n' +
                            '    compilerReuseStrategy\n' +
                            '      Strategy to re use javacc class created:\n' +
                            '      - reuseCreated (default): will reuse already created but in case of\n' +
                            '        multi-threaded builds, each thread will have its own instance\n' +
                            '      - reuseSame: the same Javacc class will be used for each compilation even\n' +
                            '        for multi-threaded build\n' +
                            '      - alwaysNew: a new Javacc class will be created for each compilation\n' +
                            '      Note this parameter value depends on the os/jdk you are using, but the\n' +
                            '      default value should work on most of env.\n' +
                            '\n' +
                            '    compilerVersion\n' +
                            '      Version of the compiler to use, ex. \'1.3\', \'1.5\', if fork is set to true.\n' +
                            '\n' +
                            '    debug\n' +
                            '      Set to true to include debugging information in the compiled class files.\n' +
                            '\n' +
                            '    debuglevel\n' +
                            '      Keyword list to be appended to the -g command-line switch. Legal values\n' +
                            '      are none or a comma-separated list of the following keywords: lines, vars,\n' +
                            '      and source. If debug level is not specified, by default, nothing will be\n' +
                            '      appended to -g. If debug is not turned on, this attribute will be ignored.\n' +
                            '\n' +
                            '    encoding\n' +
                            '      The -encoding argument for the Java compiler.\n' +
                            '\n' +
                            '    executable\n' +
                            '      Sets the executable of the compiler to use when fork is true.\n' +
                            '\n' +
                            '    failOnError\n' +
                            '      Indicates whether the build will continue even if there are compilation\n' +
                            '      errors.\n' +
                            '\n' +
                            '    fileExtensions\n' +
                            '      file extensions to check timestamp for incremental build default contains\n' +
                            '      only .class\n' +
                            '\n' +
                            '    forceJavacCompilerUse\n' +
                            '      compiler can now use javax.tools if available in your current jdk, you can\n' +
                            '      disable this feature using -Dmaven.compiler.forceJavacCompilerUse=true or\n' +
                            '      in the plugin configuration\n' +
                            '\n' +
                            '    fork\n' +
                            '      Allows running the compiler in a separate process. If false it uses the\n' +
                            '      built in compiler, while if true it will use an executable.\n' +
                            '\n' +
                            '    generatedTestSourcesDirectory\n' +
                            '      Specify where to place generated source files created by annotation\n' +
                            '      processing. Only applies to JDK 1.6+\n' +
                            '\n' +
                            '    maxmem\n' +
                            '      Sets the maximum size, in megabytes, of the memory allocation pool, ex.\n' +
                            '      \'128\', \'128m\' if fork is set to true.\n' +
                            '\n' +
                            '    meminitial\n' +
                            '      Initial size, in megabytes, of the memory allocation pool, ex. \'64\', \'64m\'\n' +
                            '      if fork is set to true.\n' +
                            '\n' +
                            '    mojoExecution\n' +
                            '\n' +
                            '\n' +
                            '    optimize\n' +
                            '      Set to true to optimize the compiled code using the compiler\'s\n' +
                            '      optimization methods.\n' +
                            '\n' +
                            '    outputFileName\n' +
                            '      Sets the name of the output file when compiling a set of sources to a\n' +
                            '      single file. expression=\'${project.build.finalName}\'\n' +
                            '\n' +
                            '    proc\n' +
                            '      Sets whether annotation processing is performed or not. Only applies to\n' +
                            '      JDK 1.6+ If not set, both compilation and annotation processing are\n' +
                            '      performed at the same time.\n' +
                            '\n' +
                            '      Allowed values are:\n' +
                            '\n' +
                            '      - none - no annotation processing is performed.\n' +
                            '      - only - only annotation processing is done, no compilation.\n' +
                            '\n' +
                            '    showDeprecation\n' +
                            '      Sets whether to show source locations where deprecated APIs are used.\n' +
                            '\n' +
                            '    showWarnings\n' +
                            '      Set to true to show compilation warnings.\n' +
                            '\n' +
                            '    skip\n' +
                            '      Set this to \'true\' to bypass compilation of test sources. Its use is NOT\n' +
                            '      RECOMMENDED, but quite convenient on occasion.\n' +
                            '\n' +
                            '    skipMultiThreadWarning\n' +
                            '\n' +
                            '\n' +
                            '    source\n' +
                            '      The -source argument for the Java compiler.\n' +
                            '\n' +
                            '    staleMillis\n' +
                            '      Sets the granularity in milliseconds of the last modification date for\n' +
                            '      testing whether a source needs recompilation.\n' +
                            '\n' +
                            '    target\n' +
                            '      The -target argument for the Java compiler.\n' +
                            '\n' +
                            '    testCompilerArgument\n' +
                            '      Sets the unformatted argument string to be passed to test compiler if fork\n' +
                            '      is set to true.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '    testCompilerArguments\n' +
                            '      Sets the arguments to be passed to test compiler (prepending a dash) if\n' +
                            '      fork is set to true.\n' +
                            '\n' +
                            '      This is because the list of valid arguments passed to a Java compiler\n' +
                            '      varies based on the compiler version.\n' +
                            '\n' +
                            '    testExcludes\n' +
                            '      A list of exclusion filters for the compiler.\n' +
                            '\n' +
                            '    testIncludes\n' +
                            '      A list of inclusion filters for the compiler.\n' +
                            '\n' +
                            '    testSource\n' +
                            '      The -source argument for the test Java compiler.\n' +
                            '\n' +
                            '    testTarget\n' +
                            '      The -target argument for the test Java compiler.\n' +
                            '\n' +
                            '    useIncrementalCompilation\n' +
                            '      to enable/disable incrementation compilation feature\n' +
                            '\n' +
                            '    verbose\n' +
                            '      Set to true to show messages about what the compiler is doing.\n' +
                            '\n' +
                            '\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  0.893 s\n' +
                            '[INFO] Finished at: 2022-09-15T23:38:54+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------\n'}
                    </SyntaxHighlighter>


                    Pentru a seta un parametru (de exemplu <i>verbose</i>, care implicit e false):
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:compile -Dmaven.compiler.verbose=true'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {' mvn compiler:compile "-Dmaven.compiler.verbose=true"\n'}
                    </SyntaxHighlighter>

                    Pentru a evita sa se introduca parametrii din linie de comanda, acestia se pot introduce in <i>pom.xml</i>, sectiunea <i>build\pluginManagement\plugins\plugin</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {
                            '<pluginManagement>\n' +
                            '            <plugins>\n' +
                            '                <plugin>\n' +
                            '                    <groupId>org.apache.maven.plugins</groupId>\n' +
                            '                    <artifactId>maven-compiler-plugin</artifactId>\n' +
                            '                    <version>3.1</version>\n' +
                            '                    <configuration>\n' +
                            '                        <verbose>true</verbose>\n' +
                            '                    </configuration>\n' +
                            '                </plugin>\n' +
                            '            </plugins>\n' +
                            '        </pluginManagement>'
                        }
                    </SyntaxHighlighter>

                    Si de acum vom putea scrie direct:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compiler:compile'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn compile'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LifecyclePluginsMavenContent;