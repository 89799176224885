import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CalendarModulePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-calendar-module", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Modulul calendar</b>
                    <br/>
                    <hr/>

                    Constante pentru zilele saptamanii:
                    <ul>
                        <li>calendar.MONDAY = 0</li>
                        <li>calendar.TUESDAY = 1</li>
                        <li>calendar.WEDNESDAY = 2</li>
                        <li>calendar.THURSDAY = 3</li>
                        <li>calendar.FRIDAY = 4</li>
                        <li>calendar.SATURDAY = 5</li>
                        <li>calendar.SUNDAY = 6</li>
                    </ul>

                    Nu există constante care exprimă lunile.
                    <br/>
                    Pentru luni, valorile întregi sunt <b>indexate de la 1</b>, adică ianuarie este reprezentat cu 1, iar decembrie cu 12
                    <hr/>

                    Metode pentru afisarea intregului calendar:
                    <ul>
                        <li><b>calendar(an)</b>: afisare calendar pe tot anul

                            Rezultatul afișat este similar cu rezultatul comenzii <b>cal</b> disponibilă în Unix.
                            Dacă se vream modificarea formatul implicit al calendarului, se pot utiliza următorii parametri:

                            <ul>
                                <li> w – lățimea coloanei pentru data (implicit 2, de exemplu, daca se seteaza cu valoarea 3, zilele saptamanii vor contine primele 3 caractere: Mon..etc)</li>
                                <li> l – numărul de linii pe săptămână (implicit 1)</li>
                                <li> c – numărul de spații dintre coloanele luni (implicit 6)</li>
                                <li> m – numărul de coloane (implicit 3)</li>
                            </ul>

                        </li>
                        <li><b>prcal(an)</b>: este identic cu <b>pr</b>int(calendar(an))</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import calendar\n' +
                        '\n' +
                        'print(calendar.calendar(2021, m=4))\n' +
                        '# calendar.prcal(2020) # identic cu: print(calendar.calendar(2021))'}
                    </SyntaxHighlighter>
                    Va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'                                               2021\n' +
                        '\n' +
                        '      January                   February                   March                     April\n' +
                        'Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su\n' +
                        '             1  2  3       1  2  3  4  5  6  7       1  2  3  4  5  6  7                1  2  3  4\n' +
                        ' 4  5  6  7  8  9 10       8  9 10 11 12 13 14       8  9 10 11 12 13 14       5  6  7  8  9 10 11\n' +
                        '11 12 13 14 15 16 17      15 16 17 18 19 20 21      15 16 17 18 19 20 21      12 13 14 15 16 17 18\n' +
                        '18 19 20 21 22 23 24      22 23 24 25 26 27 28      22 23 24 25 26 27 28      19 20 21 22 23 24 25\n' +
                        '25 26 27 28 29 30 31                                29 30 31                  26 27 28 29 30\n' +
                        '\n' +
                        '        May                       June                      July                     August\n' +
                        'Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su\n' +
                        '                1  2          1  2  3  4  5  6                1  2  3  4                         1\n' +
                        ' 3  4  5  6  7  8  9       7  8  9 10 11 12 13       5  6  7  8  9 10 11       2  3  4  5  6  7  8\n' +
                        '10 11 12 13 14 15 16      14 15 16 17 18 19 20      12 13 14 15 16 17 18       9 10 11 12 13 14 15\n' +
                        '17 18 19 20 21 22 23      21 22 23 24 25 26 27      19 20 21 22 23 24 25      16 17 18 19 20 21 22\n' +
                        '24 25 26 27 28 29 30      28 29 30                  26 27 28 29 30 31         23 24 25 26 27 28 29\n' +
                        '31                                                                            30 31\n' +
                        '\n' +
                        '     September                  October                   November                  December\n' +
                        'Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su      Mo Tu We Th Fr Sa Su\n' +
                        '       1  2  3  4  5                   1  2  3       1  2  3  4  5  6  7             1  2  3  4  5\n' +
                        ' 6  7  8  9 10 11 12       4  5  6  7  8  9 10       8  9 10 11 12 13 14       6  7  8  9 10 11 12\n' +
                        '13 14 15 16 17 18 19      11 12 13 14 15 16 17      15 16 17 18 19 20 21      13 14 15 16 17 18 19\n' +
                        '20 21 22 23 24 25 26      18 19 20 21 22 23 24      22 23 24 25 26 27 28      20 21 22 23 24 25 26\n' +
                        '27 28 29 30               25 26 27 28 29 30 31      29 30                     27 28 29 30 31\n'}
                    </SyntaxHighlighter>

                    Metode pentru afisarea unei luni din calendar:
                    <ul>
                        <li><b>month(an, luna)</b>: afisare luna dintr-un an

                            Dacă se vream modificarea formatul implicit, se pot utiliza următorii parametri:

                            <ul>
                                <li> w – lățimea coloanei pentru data (implicit 2</li>
                                <li> l – numărul de linii pe săptămână (implicit 1)</li>
                            </ul>

                        </li>
                        <li><b>prmonth(an, luna)</b>: este identic cu <b>pr</b>int(prmonth(an))</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import calendar\n' +
                        'print(calendar.month(2021, 12, w=3))'}
                    </SyntaxHighlighter>
                    Va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'       December 2021\n' +
                        'Mon Tue Wed Thu Fri Sat Sun\n' +
                        '          1   2   3   4   5\n' +
                        '  6   7   8   9  10  11  12\n' +
                        ' 13  14  15  16  17  18  19\n' +
                        ' 20  21  22  23  24  25  26\n' +
                        ' 27  28  29  30  31'}
                    </SyntaxHighlighter>

                    Alte metode:
                    <ul>
                        <li><b>setfirstweekday(zi_din_saptamana): seteaza cu ce zi sa inceapa ziua de saptamana (implicit este luni)</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar\n' +
                                '\n' +
                                'calendar.setfirstweekday(calendar.FRIDAY) # sau calendar.setfirstweekday(4)\n' +
                                'calendar.prmonth(2022, 12)'}
                            </SyntaxHighlighter>
                            Va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'   December 2020\n' +
                                'Fr Sa Su Mo Tu We Th\n' +
                                '             1  2  3\n' +
                                ' 4  5  6  7  8  9 10\n' +
                                '11 12 13 14 15 16 17\n' +
                                '18 19 20 21 22 23 24\n' +
                                '25 26 27 28 29 30 31'}
                            </SyntaxHighlighter>

                        </li>

                        <li><b>weekday(an, luna, zi)</b>: returneaza ziua săptămânii ca valoare întreagă

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar\n' +
                                'print(calendar.weekday(2022, 11, 22)) # 1, adica marti\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li><b>weekheader(lungime_nume_zi_saptamana)</b>: returneaza numele zilelor saptamanii (trunchiate sau nu, in functie de paramatrul dat: lungime_nume_zi_saptamana )
                            <br/>
                            metoda <b>setfirstweekday()</b> are efect in afisarea zilelor saptamanii
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar\n' +
                                'print(calendar.weekheader(1)) # M T W T F S S\n' +
                                'print(calendar.weekheader(2)) # Mo Tu We Th Fr Sa Su\n' +
                                'print(calendar.weekheader(3)) # Mon Tue Wed Thu Fri Sat Sun\n' +
                                'print(calendar.weekheader(4)) # Mon  Tue  Wed  Thu  Fri  Sat  Sun \n' +
                                'print(calendar.weekheader(5)) #  Mon   Tue   Wed   Thu   Fri   Sat   Sun\n' +
                                'print(calendar.weekheader(9)) #   Monday    Tuesday   Wednesday   Thursday    Friday    Saturday    Sunday '}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>isleap(an)</b>: returneaza True, daca <i>an</i> este bisect, si False altfel
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar\n' +
                                '\n' +
                                'print(calendar.isleap(2022) # False'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>leapdays(an_inceput, an_sfarsit): returneaza numarul de ani bisecti in intervalul [an_inceput, an_sfarsit)</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar\n' +
                                '\n' +
                                'print(calendar.leapdays(1900, 2023))  # 30 (Nu este inclus si 2023)'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    Modul vine si cu urmatoarele clase:
                    <ul>
                        <li><b>calendar.Calendar</b>: oferă metode de pregătire a datelor din calendar pentru formatare</li>
                        <li><b>calendar.TextCalendar</b>: este folosit pentru a crea calendare text obișnuite</li>
                        <li><b>calendar.HTMLCalendar</b>: este folosit pentru a crea calendare HTML</li>
                        <li><b>calendar.LocalTextCalendar</b>: subclasă a clasei calendar.TextCalendar; constuctorul preia parametrul <i>locale</i>, fiind folosit pentru a returna numele de luni și zile corespunzătoare
                            <br/>
                            exemplu: calendar.LocaleTextCalendar(locale='ro_RO')
                        </li>
                        <li><b>calendar.LocalHTMLCalendar</b>: subclasă a clasei calendar.HTMLCalendar; constuctorul preia parametrul <i>locale</i>, fiind folosit pentru a returna numele de luni și zile corespunzătoare</li>
                    </ul>
                    <hr/>

                    Crarea unui obiect de tipul <b>calendar.Calendar</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import calendar  \n' +
                        '\n' +
                        'c = calendar.Calendar(prima_zi_din_saptamana=0)'}
                    </SyntaxHighlighter>

                    Metode:
                    <ul>
                        <li><b>iterweekdays()</b>: returneaza un iterator cu numerele zilelor saptamanii, tinand cont de <i>prima_zi_din_saptamana</i>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar(calendar.SUNDAY)\n' +
                                '\n' +
                                'for index_zi in c.iterweekdays():\n' +
                                '    print(index_zi, end=" ") # 6 0 1 2 3 4 5 '}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>itermonthdates(an, luna)</b>: returneaza un iterator cu zilele lunii din anul specificat ca argument, &nbsp;
                            <b>precum și toate zilele înainte de începutul lunii sau sfârșitul lunii care sunt necesare pentru a obține o săptămână completă</b>
                            <br/>
                            fiecare zi este reprezentată de un obiect <b>datetime.date</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar()\n' +
                                '\n' +
                                'for date in c.itermonthdates(2022, 2):\n' +
                                '    print(date)'}
                            </SyntaxHighlighter>

                            Se vor afisa zile din februarie, dar si o zi din ianuarie si 6 din martie ca completare:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'2022-01-31\n' +
                                '2022-02-01\n' +
                                '2022-02-02\n' +
                                '2022-02-03\n' +
                                '2022-02-04\n' +
                                '2022-02-05\n' +
                                '2022-02-06\n' +
                                '2022-02-07\n' +
                                '2022-02-08\n' +
                                '2022-02-09\n' +
                                '2022-02-10\n' +
                                '2022-02-11\n' +
                                '2022-02-12\n' +
                                '2022-02-13\n' +
                                '2022-02-14\n' +
                                '2022-02-15\n' +
                                '2022-02-16\n' +
                                '2022-02-17\n' +
                                '2022-02-18\n' +
                                '2022-02-19\n' +
                                '2022-02-20\n' +
                                '2022-02-21\n' +
                                '2022-02-22\n' +
                                '2022-02-23\n' +
                                '2022-02-24\n' +
                                '2022-02-25\n' +
                                '2022-02-26\n' +
                                '2022-02-27\n' +
                                '2022-02-28\n' +
                                '2022-03-01\n' +
                                '2022-03-02\n' +
                                '2022-03-03\n' +
                                '2022-03-04\n' +
                                '2022-03-05\n' +
                                '2022-03-06\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>itermonthdays(an, luna)</b>: returnează un iterator cu zilele săptămânii reprezentate prin numere (zile din afara intervalului de luni specificate, sunt adăugate pentru a păstra săptămâna completă si au valoarea 0)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar()\n' +
                                '\n' +
                                'for iter in c.itermonthdays(2022, 2):\n' +
                                '    print(iter, end=" ") # 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 0 0 0 0 0 0 '}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>itermonthdays2(an, luna)</b>: returnează un iterator cu zilele sub formă de tuplu formate dintr-un <b>număr al zilei lunii</b> și un <b>număr al zilei săptămânii</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar()\n' +
                                '\n' +
                                'for iter in c.itermonthdays2(2022, 2):\n' +
                                '    print(iter, end=" ") # (0, 0) (1, 1) (2, 2) (3, 3) (4, 4) (5, 5) (6, 6) (7, 0) (8, 1) (9, 2) (10, 3) (11, 4) (12, 5) (13, 6) (14, 0) (15, 1) (16, 2) (17, 3) (18, 4) (19, 5) (20, 6) (21, 0) (22, 1) (23, 2) (24, 3) (25, 4) (26, 5) (27, 6) (28, 0) (0, 1) (0, 2) (0, 3) (0, 4) (0, 5) (0, 6) \n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>itermonthdays3(an, luna)</b>: returnează un iterator cu zilele sub formă de tupluri formate <b>dintr-un an, o lună și o zi a lunii</b>; această metodă este disponibilă începând cu versiunea 3.7;

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar()\n' +
                                '\n' +
                                'for iter in c.itermonthdays3(2022, 2):\n' +
                                '    print(iter, end=" ") # (2022, 1, 31) (2022, 2, 1) (2022, 2, 2) (2022, 2, 3) (2022, 2, 4) (2022, 2, 5) (2022, 2, 6) (2022, 2, 7) (2022, 2, 8) (2022, 2, 9) (2022, 2, 10) (2022, 2, 11) (2022, 2, 12) (2022, 2, 13) (2022, 2, 14) (2022, 2, 15) (2022, 2, 16) (2022, 2, 17) (2022, 2, 18) (2022, 2, 19) (2022, 2, 20) (2022, 2, 21) (2022, 2, 22) (2022, 2, 23) (2022, 2, 24) (2022, 2, 25) (2022, 2, 26) (2022, 2, 27) (2022, 2, 28) (2022, 3, 1) (2022, 3, 2) (2022, 3, 3) (2022, 3, 4) (2022, 3, 5) (2022, 3, 6) \n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>itermonthdays4(an, luna)</b>: returnează un iterator cu zilele sub formă de tupluri formate <b>dintr-un an, o lună, o zi a lunii și o zi a săptămânii</b>; această metodă este disponibilă începând cu versiunea Python 3.7.

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar()\n' +
                                '\n' +
                                'for iter in c.itermonthdays4(2022, 2):\n' +
                                '    print(iter, end=" ") # (2022, 1, 31, 0) (2022, 2, 1, 1) (2022, 2, 2, 2)  ... '}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>monthdays2calendar(an, luna)</b>: returnează un iterator cu listă de săptămâni dintr-o anumită lună; fiecare săptămână este un tuplu format din <b>numarul zilei din cadrul lunii</b> și <b>numarul zile din cadrul săptămânii</b>;
                            &nbsp; numerele zilelor din afara lunii sunt reprezentate de 0, în timp ce numerele zilelor lucrătoare sunt un număr de la 0 la 6, unde 0 este luni și 6 este duminică:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import calendar  \n' +
                                '\n' +
                                'c = calendar.Calendar()\n' +
                                '\n' +
                                'for data in c.monthdays2calendar(2022, 2):\n' +
                                '    print(data)'}
                            </SyntaxHighlighter>

                            Va afisa:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'[(0, 0), (1, 1), (2, 2), (3, 3), (4, 4), (5, 5), (6, 6)]\n' +
                                '[(7, 0), (8, 1), (9, 2), (10, 3), (11, 4), (12, 5), (13, 6)]\n' +
                                '[(14, 0), (15, 1), (16, 2), (17, 3), (18, 4), (19, 5), (20, 6)]\n' +
                                '[(21, 0), (22, 1), (23, 2), (24, 3), (25, 4), (26, 5), (27, 6)]\n' +
                                '[(28, 0), (0, 1), (0, 2), (0, 3), (0, 4), (0, 5), (0, 6)]'}
                            </SyntaxHighlighter>

                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CalendarModulePythonContent;