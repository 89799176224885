import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ThreadDumpJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-thread-dump", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Thread Dump</b>
                    <br/>
                    <br/>

                    Folosind <b>VisualVM</b>, acesta isi poate da seama  unele dintre firele aplicatiei au intrat intr-un impas (deadlook).
                    <br/>
                    Din tab-ul <b>Threads</b> se apasa pe butonul <b>Thread Dump</b> si se poate descarca un fisier de thread dump.
                    <hr/>

                    Folosind <i>linia de comanda</i> (profilarea de la distanță și depanarea la distanță nu sunt recomandate într-un mediu de producție).
                    <br/>
                    Pentru a obtine un thread dump din linie de comanda:
                    <ul>
                        <li>se gaseste ID-ul procesului pentru care se vrea descarcarea firului, folsind comanda <b>jps -l</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'jps -l'}
                            </SyntaxHighlighter>

                            se poate afisa:
                            <SyntaxHighlighter>
                                {'25792 C:\\Program\n' +
                                    '2640 \n' +
                                    '20308 com.example.Main\n' +
                                    '12236 jdk.jcmd/sun.tools.jps.Jps\n' +
                                    '24076 com.zerog.lax.LAX\n' +
                                    '27148 org.jetbrains.jps.cmdline.Launcher\n'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            salvare/descarcare thread dump, folosind comanda <b>jstack</b> PID.
                            <br/>
                            afisare in consola thread dump:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'jstack 20308'}
                            </SyntaxHighlighter>

                            salvare intr-un fisier:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'jstack 20308 > d:\\export.tdump'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            incarcare fisier de thread dump:
                            <ul>
                                <li>intr-un instrument de profilare, cum ar fi <b>VisualVM</b>: (<b>File {"->"} Load</b>)</li>
                                <li><b>fastThread</b>: <a target={"_blank"} href={" https://fastthread.io/ )"}> https://fastthread.io/ )</a> </li>
                                <li>vizualizare intr-un editor text (pentru un thread dump este un fisier text cu detalii legate de firele de executie)</li>
                            </ul>
                            exemplu:
                            <SyntaxHighlighter>
                                {'"_Producer" #16 prio=5 os_prio=0 cpu=46.88ms \n' +
                                    ' elapsed=763.96s tid=0x000002f964987690 \n' +
                                    ' nid=0xcac waiting for monitor entry  [0x000000fe5ceff000]\n' +
                                    '   java.lang.Thread.State: BLOCKED (on object monitor)\n' +
                                    '    at main.Producer.run(Unknown Source)\n' +
                                    '    - waiting to lock <0x000000052e0313f8> \n' +
                                    ' (a java.util.ArrayList)     \n' +
                                    '    - locked <0x000000052e049d38> \n' +
                                    ' (a java.util.ArrayList)     \n' +
                                    ' \n' +
                                    '"_Consumer" #18 prio=5 os_prio=0 cpu=0.00ms \n' +
                                    ' elapsed=763.96s tid=0x000002f96498b030 \n' +
                                    ' nid=0x4254 waiting for monitor entry  [0x000000fe5cfff000]\n' +
                                    '   java.lang.Thread.State: BLOCKED (on object monitor)\n' +
                                    '    at main.Consumer.run(Unknown Source)\n' +
                                    '    - waiting to lock <0x000000052e049d38> (a java.util.ArrayList)\n' +
                                    '    - locked <0x000000052e0313f8> (a java.util.ArrayList)'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Cand se suspecteaza un deadlock:
                    <ul>
                        <li>se elimina toate firele care nu sunt blocate (diferite de java.lang.Thread.State: BLOCKED (on object monitor); de exemplu cele in starea Running)</li>
                        <li>se ia unul dintre firele blocat (de exemplu: "_Producer" ) si se cauta ce il blocheaza dupa ID-ul de blocare
                        <SyntaxHighlighter>
                            {'  - waiting to lock <0x000000052e0313f8> \n' +
                                '    - locked <0x000000052e049d38> '}
                        </SyntaxHighlighter>
                        </li>
                        <li>se gaseste firul care provoaca blocarea ( de exemplu: "_Consumer") si se verifica ce bloceaza acel fir; daca se revine la un fir investigat deja inseamna ca am gasit un deadlock
                            <SyntaxHighlighter>
                                {'  - waiting to lock <0x000000052e049d38>\n' +
                                    '    - locked <0x000000052e0313f8>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>*/}
                    {/*            Writing Hello World in Java byte code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ThreadDumpJavaContent;