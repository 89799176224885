import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ControlFlowRustContent extends BaseContentPage {

    constructor(props) {
        super(props, "rust-control-flow", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Instructiuni de control</b>
                    <br/>
                    <br/>

                    <b>1. if</b>
                    <SyntaxHighlighter>
                        {'if a == 20 {\n' +
                            '  x=1;\n' +
                            '} else if a==30 {\n' +
                            '  x=2;\n' +
                            '} else {\n' +
                            '  x=3;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {'x = if a == 20 {\n' +
                            '  1\n' +
                            '} else if a==30 {\n' +
                            '  2\n' +
                            '} else {\n' +
                            '  3\n' +
                            '};'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter>
                        {'x = if a { b } else { c };'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. loop</b>
                    <SyntaxHighlighter>
                        {'loop { }'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'loop {\n' +
                            '  break;' +
                            '}'}
                    </SyntaxHighlighter>

                    Un label este un identificator care incepe cu ':
                    <SyntaxHighlighter>
                        {'\'label_1: loop{\n' +
                            '        loop{\n' +
                            '            println!("loop!");\n' +
                            '            break \'label_1;\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Urmatorul cod va rula la nesfarsit
                    <SyntaxHighlighter>
                        {'\'label_1: loop{\n' +
                            '        loop{\n' +
                            '            println!("loop!");\n' +
                            '            continue \'label_1;\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. while</b>
                    <SyntaxHighlighter>
                        {'    let mut a = true;\n' +
                            '    let mut i = 1;\n' +
                            '    while a {\n' +
                            '        println!("{}",i);\n' +
                            '        i=i+1;\n' +
                            '        if i>4 {\n' +
                            '            a = false;\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <b>while</b> e o simplificare a lui <b>loop</b>:
                    <SyntaxHighlighter>
                        {'loop {\n' +
                            '  if !exp {break} \n' +
                            '  // instructiuni\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In rust nu exista <b>do while</b>! Dar poate fi simulata:

                    <SyntaxHighlighter>
                        {'loop {\n' +
                            '  // instructiuni\n' +
                            '  if !exp {break} \n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. for</b>

                    <SyntaxHighlighter>
                        {'for i in [1,2,3].iter() {\n' +
                            '    println!("{}",i);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'let array = [(1,2), (3,4)];\n' +
                            'for (i,j) in array.iter(){\n' +
                            '    println!("{} {}",i, j);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    // 0,1
                    <SyntaxHighlighter>
                        {'for i in 0..2 {\n' +
                            '    println!("{}",i);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    // 0,1,2
                    <SyntaxHighlighter>
                        {'for i in 0..=2 {\n' +
                            '    println!("{}",i);\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ControlFlowRustContent;