import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"maven-root", url:"#", title:"Maven", subtitle:""},
        {id:"maven-intro", url:"/maven/intro", title:"Introducere", subtitle:"", parent:"maven-root"},
        {id:"maven-inheritance", url:"/maven/inheritance", title:"Mostenirea", subtitle:"", parent:"maven-root"},
        {id:"maven-profiles", url:"/maven/profiles", title:"Profile", subtitle:"", parent:"maven-root"},
        {id:"maven-generate-projects", url:"/maven/generate-projects", title:"Generare proiecte", subtitle:"", parent:"maven-root"},
        {id:"maven-dependency-management", url:"/maven/dependency-management", title:"Gestionarea dependentelor", subtitle:"", parent:"maven-root"},
        {id:"maven-lifecycle-plugins", url:"/maven/lifecycle-plugins", title:"Ciclul de viata. Plugin-uri", subtitle:"", parent:"maven-root"},
        {id:"maven-custom-plugin", url:"/maven/custom-plugin", title:"Crearea propriului plugin", subtitle:"", parent:"maven-root"},
        {id:"maven-plugins", url:"/maven/plugins", title:"Plugin-uri", subtitle:"", parent:"maven-root"},
        {id:"maven-build-archetype", url:"/maven/build-archetype", title:"Creare Archetype", subtitle:"", parent:"maven-root"},
        {id:"maven-multi-module", url:"/maven/multi-module", title:"Multi module", subtitle:"", parent:"maven-root"},
        {id:"maven-deploy-web-applications", url:"/maven/deploy-web-applications", title:"Deploy aplicatii web", subtitle:"", parent:"maven-root"},
        {id:"maven-m2-eclipse-plugin", url:"/maven/m2-eclipse-plugin", title:"M2 Eclipse Plugin", subtitle:"", parent:"maven-root"},
    ]

    static indexUrl = "/maven/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Maven
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;