import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ConfigGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-config", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git config</b>
                    <br/>
                    <br/>

                    Pentru a afisa detalii legate de configurarea git-ului:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git config --list'}
                    </SyntaxHighlighter>
                    Se poate afisa uramtaorele informatii:
                    <SyntaxHighlighter>
                        {'diff.astextplain.textconv=astextplain\n' +
                            'filter.lfs.clean=git-lfs clean -- %f\n' +
                            'filter.lfs.smudge=git-lfs smudge -- %f\n' +
                            'filter.lfs.process=git-lfs filter-process\n' +
                            'filter.lfs.required=true\n' +
                            'http.sslbackend=openssl\n' +
                            'http.sslcainfo=C:/Program Files/Git/mingw64/ssl/certs/ca-bundle.crt\n' +
                            'core.autocrlf=true\n' +
                            'core.fscache=true\n' +
                            'core.symlinks=false\n' +
                            'credential.helper=manager\n' +
                            'filesystem.Oracle Corporation|11|2084683118.timestampresolution=9974 microseconds\n' +
                            'filesystem.Oracle Corporation|11|2084683118.minracythreshold=0 nanoseconds\n' +
                            'user.name=letyournailsgrow\n'}
                    </SyntaxHighlighter>
                    Pentru a iesi se apasa tasta <b>q</b>.

                    <br/>
                    <br/>

                    Pentru a schimba valoarea unei chei
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git config --global user.name "kj"'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ConfigGitContent;