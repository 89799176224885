import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers8LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-8", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Continuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            ├── segments-entries.json     \n' +
                            '│            ├── site-navigation-menus.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Creare fisier JSON site-navigation-menus.json</b>
                    <br/>
                    <br/>

                    Se creeaza un fisier cu numele:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-navigation-menus.json'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Adaugare continut site-navigation-menus.json</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'[\n' +
                            '  {\n' +
                            '    "addNewPages": "Yes",\n' +
                            '    "menuItems": [\n' +
                            '      {\n' +
                            '        "name": "Google",\n' +
                            '        "url": "https://www.google.ro",\n' +
                            '        "type": "url"\n' +
                            '      }\n' +
                            '    ],\n' +
                            '    "name": "Footer Admin Links"\n' +
                            '  }\n' +
                            ']'}
                    </SyntaxHighlighter>

                    Dupa aplicarea site-ului:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/nav_1.png'}/>
                    </div>

                    <hr/>
                    Se poate adauga intr-un fragment:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<div class="footer-nav-menu-content">\n' +
                            '    [#assign preferences = freeMarkerPortletPreferences.getPreferences({ "displayStyle": "ddmTemplate_NAV-PILLS-STACKED-FTL","siteNavigationMenuId": "${footer_admin_links_menu_id}" }) /]\n' +
                            '    [@liferay_portlet["runtime"] instanceId="footer-contact-menu-content"\n' +
                            '    defaultPreferences=preferences\n' +
                            '    portletName="com_liferay_site_navigation_menu_web_portlet_SiteNavigationMenuPortlet"\n' +
                            '    /]\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    Folosirea unei variabile puse in contextul global templatelor:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'${footer_admin_links_menu_id}'}
                    </SyntaxHighlighter>

                    unde Contextul global pentru template:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.theme.context.contributor;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.template.TemplateContextContributor;\n' +
                            'import com.liferay.portal.kernel.theme.ThemeDisplay;\n' +
                            'import com.liferay.portal.kernel.util.Validator;\n' +
                            'import com.liferay.portal.kernel.util.WebKeys;\n' +
                            'import com.liferay.site.navigation.model.SiteNavigationMenu;\n' +
                            'import com.liferay.site.navigation.service.SiteNavigationMenuLocalService;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            '\n' +
                            'import javax.servlet.http.HttpServletRequest;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = "type=" + TemplateContextContributor.TYPE_GLOBAL,\n' +
                            '        service = TemplateContextContributor.class\n' +
                            ')\n' +
                            'public class IbridGlobalTemplateContextContributor implements TemplateContextContributor {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void prepare(Map<String, Object> contextObjects, HttpServletRequest httpServletRequest) {\n' +
                            '        ThemeDisplay themeDisplay = (ThemeDisplay) httpServletRequest.getAttribute(WebKeys.THEME_DISPLAY);\n' +
                            '\n' +
                            '        contextObjects.put("images_folder", themeDisplay.getPathThemeImages());\n' +
                            '        contextObjects.put("site_url", themeDisplay.getScopeGroup().getDisplayURL(themeDisplay));\n' +
                            '        contextObjects.put("footer_admin_links_menu_id", getSiteNavigationMenuIdByName(themeDisplay.getScopeGroupId(), "Footer Admin Links"));\n' +
                            '\n' +
                            '        boolean isInfochiosk = isInfochiosk(httpServletRequest);\n' +
                            '        contextObjects.put("isInfochiosk", isInfochiosk);\n' +
                            '    }\n' +
                            '\n' +
                            '    private long getSiteNavigationMenuIdByName(long groupId, String siteNavigationMenuName) {\n' +
                            '        long siteNavigationMenuId = 0;\n' +
                            '        SiteNavigationMenu siteNavigationMenu = siteNavigationMenuLocalService.fetchSiteNavigationMenuByName(groupId, siteNavigationMenuName);\n' +
                            '\n' +
                            '        if (Validator.isNotNull(siteNavigationMenu)) {\n' +
                            '            siteNavigationMenuId = siteNavigationMenu.getSiteNavigationMenuId();\n' +
                            '        }\n' +
                            '\n' +
                            '        return siteNavigationMenuId;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private SiteNavigationMenuLocalService siteNavigationMenuLocalService;\n' +
                            '\n' +
                            '    private boolean isInfochiosk(HttpServletRequest httpServletRequest) {\n' +
                            '        return false;\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    Variabila <b>footer_admin_links_menu_id</b> - va retine id-ul din baza pentru meniul cu numele <i>Footer Admin Links</i>.
                    <ul>
                        <li>
                            tabela in care retin meniurile se numeste <b>sitenavigationmenu</b>
                        </li>
                        <li>
                           fiecare item dintr-uun meniul este stocat in tabela <b>sitenavigationmenuitem</b>
                        </li>

                    </ul>
                   sau
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'[@liferay_site_navigation["navigation-menu"]\n' +
                            'ddmTemplateGroupId=(scopeGroupId)\n' +
                            'displayDepth=0\n' +
                            'expandedLevels="auto"\n' +
                            'preview=false\n' +
                            'rootItemLevel=0\n' +
                            'rootLayoutType="absolute"\n' +
                            'displayStyle="ddmTemplate_NAV-PILLS-STACKED-FTL"\n' +
                            'siteNavigationMenuId=(footer_admin_links_menu_id)\n' +
                            '/]'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<div class="portlet-content">\n' +
                            '    <div class=" portlet-content-container">\n' +
                            '        <div class="portlet-body">\n' +
                            '            [@liferay_site_navigation["navigation-menu"]\n' +
                            '            ddmTemplateGroupId=(scopeGroupId)\n' +
                            '            ddmTemplateKey="NAV-PILLS-STACKED-FTL"\n' +
                            '            displayDepth=0\n' +
                            '            expandedLevels="auto"\n' +
                            '            preview=false\n' +
                            '            rootItemLevel=0\n' +
                            '            rootLayoutType="absolute"\n' +
                            '            siteNavigationMenuId=(footer_admin_links_menu_id)\n' +
                            '            /]\n' +
                            '        </div>\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"}href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/embedding-portlets-and-setting-preferences-in-a-liferay-7-2-theme/maximized"}>
                                Embedding portlets and setting preferences in a Liferay 7.2 theme
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers8LiferayContent;