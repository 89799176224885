import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex4DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex4", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 4 (fontend v2) </b>
                    <br/>
                    <br/>

                    Pentru a functiona pe platforma AWS (si a nu intra in conflict cu proiectul nostru), trebuie:
                    <ul>
                        <li>redenumit fisierul <b>docker-compose.yml</b> în <b>docker-compose-dev.yml</b>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'mv docker-compose.yml docker-compose-dev.yml'}
                            </SyntaxHighlighter>
                            În continuare, va trebui specificat din ce fișier compus doriți să construiți și să rulați:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker compose -f docker-compose-dev.yml up'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker compose -f docker-compose-dev.yml up --build'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker compose -f docker-compose-dev.yml down'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            creat fisierul <b>docker-compose.yml</b> cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'version: \'3\'\n' +
                                    'services:\n' +
                                    '  web:\n' +
                                    '    build:\n' +
                                    '      context: .\n' +
                                    '      dockerfile: Dockerfile\n' +
                                    '    ports:\n' +
                                    '      - \'80:80\''}
                            </SyntaxHighlighter>
                        </li>

                    </ul>
                   

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex4DockerContent;