import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class InfoDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-info", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker system info (docker info)</b>

                    <br/>
                    <br/>

                    Afiseaza informatii despre docker:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker info'}
                    </SyntaxHighlighter>
                    Această comandă afișează informații la nivel de sistem cu privire la instalarea Docker.
                    Informațiile afișate includ versiunea kernelului, numărul de containere și imagini.
                    Numărul de imagini afișate este numărul de imagini unice. Aceeași imagine etichetată sub nume diferite este numărată o singură dată.

                    <SyntaxHighlighter>
                        {'Client:\n' +
                            ' Context:    default\n' +
                            ' Debug Mode: false\n' +
                            ' Plugins:\n' +
                            '  app: Docker App (Docker Inc., v0.9.1-beta3)\n' +
                            '  buildx: Docker Buildx (Docker Inc., v0.9.1-docker)\n' +
                            '  compose: Docker Compose (Docker Inc., v2.10.2)\n' +
                            '  scan: Docker Scan (Docker Inc., v0.17.0)\n' +
                            '\n' +
                            'Server:\n' +
                            ' Containers: 6\n' +
                            '  Running: 0\n' +
                            '  Paused: 0\n' +
                            '  Stopped: 6\n' +
                            ' Images: 2\n' +
                            ' Server Version: 20.10.18\n' +
                            ' Storage Driver: overlay2\n' +
                            '  Backing Filesystem: extfs\n' +
                            '  Supports d_type: true\n' +
                            '  Native Overlay Diff: true\n' +
                            '  userxattr: false\n' +
                            ' Logging Driver: json-file\n' +
                            ' Cgroup Driver: systemd\n' +
                            ' Cgroup Version: 2\n' +
                            ' Plugins:\n' +
                            '  Volume: local\n' +
                            '  Network: bridge host ipvlan macvlan null overlay\n' +
                            '  Log: awslogs fluentd gcplogs gelf journald json-file local logentries splunk syslog\n' +
                            ' Swarm: inactive\n' +
                            ' Runtimes: runc io.containerd.runc.v2 io.containerd.runtime.v1.linux\n' +
                            ' Default Runtime: runc\n' +
                            ' Init Binary: docker-init\n' +
                            ' containerd version: 9cd3357b7fd7218e4aec3eae239db1f68a5a6ec6\n' +
                            ' runc version: v1.1.4-0-g5fd4c4d\n' +
                            ' init version: de40ad0\n' +
                            ' Security Options:\n' +
                            '  apparmor\n' +
                            '  seccomp\n' +
                            '   Profile: default\n' +
                            '  cgroupns\n' +
                            ' Kernel Version: 5.15.0-47-generic\n' +
                            ' Operating System: Ubuntu 22.04.1 LTS\n' +
                            ' OSType: linux\n' +
                            ' Architecture: x86_64\n' +
                            ' CPUs: 2\n' +
                            ' Total Memory: 1.929GiB\n' +
                            ' Name: kj-Standard-PC-i440FX-PIIX-1996\n' +
                            ' ID: HCWU:UWHZ:G6BF:7SLR:NPFY:ZTSA:ABAV:EIZO:72Y3:KKBN:DPRN:7VBN\n' +
                            ' Docker Root Dir: /var/lib/docker\n' +
                            ' Debug Mode: false\n' +
                            ' Username: iulianbuzdugan\n' +
                            ' Registry: https://index.docker.io/v1/\n' +
                            ' Labels:\n' +
                            ' Experimental: false\n' +
                            ' Insecure Registries:\n' +
                            '  127.0.0.0/8\n' +
                            ' Live Restore Enabled: false'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InfoDockerContent;