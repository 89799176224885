import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class TapRxJsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-rxjs-tap", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. RxJs: tap</b>
                    <br/>
                    <br/>

                    Folosit pentru a efectua efecte secundare la notificarile de la sursa observabila.
                    <br/>
                    Cu alte cuvinte, putem executa unele operații de îndată ce Observabilul emite un eveniment <i>next</i> , <i>error</i> sau <i>complete</i>
                    și o vom face fără a modifica rezultatul Observabilului la care este înlănțuit.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.getDataFromServer()\n' +
                            '  .pipe(\n' +
                            '    tap((response) => this.originalResponse = response),\n' +
                            '    map(response => this.doSomethingWithResponse(response)),\n' +
                            '    tap(responseAfterMap => console.debug("transformed response", responseAfterMap))\n' +
                            '  )\n' +
                            '  .subscribe();'}
                    </SyntaxHighlighter>

                    Folosim tap pentru a salva mai întâi răspunsul inițial (pentru că avem nevoie de el din anumite motive în componenta noastră):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tap((response) => this.originalResponse = response)'}
                    </SyntaxHighlighter>
                    și pentru a vedea cum a fost transformat de map mai târziu.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'tap(responseAfterMap => console.debug("transformed response", responseAfterMap))'}
                    </SyntaxHighlighter>
                    Această a doua utilizare clarifică de ce acest operator este atât de folosit în scopuri de depanare.

                    <hr/>
                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// RxJS v6+\n' +
                            'import { of } from \'rxjs\';\n' +
                            'import { tap, map } from \'rxjs/operators\';\n' +
                            '\n' +
                            'const source = of(1, 2, 3, 4, 5);\n' +
                            '// transparently log values from source with \'tap\'\n' +
                            'const example = source.pipe(\n' +
                            '  tap(val => console.log(`Inainte de MAP: ${val}`)),\n' +
                            '  map(val => val + 10),\n' +
                            '  tap(val => console.log(`Dupa MAP: ${val}`))\n' +
                            ');\n' +
                            '\n' +
                            '//\'tap\' nu transforma valori\n' +
                            '//output: 11...12...13...14...15\n' +
                            'const subscribe = example.subscribe(val => console.log(val));'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// RxJS v6+\n' +
                            'import { of } from \'rxjs\';\n' +
                            'import { tap, map } from \'rxjs/operators\';\n' +
                            '\n' +
                            'const source = of(1, 2, 3, 4, 5);\n' +
                            '\n' +
                            'const example = source\n' +
                            '  .pipe(\n' +
                            '    map(val => val + 10),\n' +
                            '    tap({\n' +
                            '      next: val => {\n' +
                            '        // on next 11, etc.\n' +
                            '        console.log(\'on next\', val);\n' +
                            '      },\n' +
                            '      error: error => {\n' +
                            '        console.log(\'on error\', error.message);\n' +
                            '      },\n' +
                            '      complete: () => console.log(\'on complete\')\n' +
                            '    })\n' +
                            '  )\n' +
                            '  // output: 11, 12, 13, 14, 15\n' +
                            '  .subscribe(val => console.log(val));'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://medium.com/@andreacaruso093_54767/10-rxjs-operators-which-i-use-daily-as-an-angular-developer-b9af79120876"}>
                               10 RxJS operators which I use daily as an Angular developer
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TapRxJsAngularContent;