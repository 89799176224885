import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";


class JavaSpringSecurityEx02Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-security-ex-02", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify content important"}>

                    <i>Aplicatie:</i> <b>security</b>
                    <br/>
                    <i>Modul:</i> <b>security-01</b>
                    <br/>
                    <i>Descriere actiune:</i> Construirea unui proiect care contine mai multe module. Aplicatia <i>security-01</i> va deveni un modul pentru aceasta aplicatie.
                    <br/>
                    <i>IDE folosit</i>: Intellij IDEA
                    <hr/>

                    1. Continut fisier <b>pom.xml</b> (in radacina proiectului):

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>

                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                        '<project xmlns="http://maven.apache.org/POM/4.0.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                        '         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 https://maven.apache.org/xsd/maven-4.0.0.xsd">\n' +
                        '\n' +
                        '    <modelVersion>4.0.0</modelVersion>\n' +
                        '    \n' +
                        '    <parent>\n' +
                        '        <groupId>org.springframework.boot</groupId>\n' +
                        '        <artifactId>spring-boot-starter-parent</artifactId>\n' +
                        '        <version>2.5.5</version>\n' +
                        '        <relativePath/> <!-- lookup parent from repository -->\n' +
                        '    </parent>\n' +
                        '\n' +
                        '    <packaging>pom</packaging>\n' +
                        '\n' +
                        '    <groupId>ro.letyournailsgrow.security</groupId>\n' +
                        '    <artifactId>security</artifactId>\n' +
                        '    <version>0.0.1-SNAPSHOT</version>\n' +
                        '    <name>security</name>\n' +
                        '    <description>security</description>\n' +
                        '\n' +
                        '    <modules>\n' +
                        '        <module>security-01</module>\n' +
                        '    </modules>\n' +
                        '\n' +
                        '    <properties>\n' +
                        '        <java.version>1.8</java.version>\n' +
                        '    </properties>\n' +
                        '    \n' +
                        '    <dependencies>\n' +
                        '        <dependency>\n' +
                        '            <groupId>org.springframework.boot</groupId>\n' +
                        '            <artifactId>spring-boot-starter-web</artifactId>\n' +
                        '        </dependency>\n' +
                        '    </dependencies>\n' +
                        '\n' +
                        '</project>\n'}
                    </SyntaxHighlighter>

                    2. Apoi, se muta directorul <b>security-01</b> (aplicatia din <a href={"/java-spring-security/ex-01"}>exemplul-01</a>) in root aplicatie <b>security</b>.
                    <br/>
                    <br/>

                    3. Fisierul <b>pom.xml</b> al modulului <b>security-01</b> va avea urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                        '<project xmlns="http://maven.apache.org/POM/4.0.0"\n' +
                        '         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                        '         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">\n' +
                        '    \n' +
                        '    <modelVersion>4.0.0</modelVersion>\n' +
                        '\n' +
                        '    <parent>\n' +
                        '        <groupId>ro.letyournailsgrow.security</groupId>\n' +
                        '        <artifactId>security</artifactId>\n' +
                        '        <version>0.0.1-SNAPSHOT</version>\n' +
                        '    </parent>\n' +
                        '\n' +
                        '    <groupId>ro.letyournailsgrow.security</groupId>\n' +
                        '    <artifactId>security-01</artifactId>\n' +
                        '    <version>0.0.1-SNAPSHOT</version>\n' +
                        '\n' +
                        '    <properties>\n' +
                        '        <maven.compiler.source>8</maven.compiler.source>\n' +
                        '        <maven.compiler.target>8</maven.compiler.target>\n' +
                        '    </properties>\n' +
                        '\n' +
                        '    <build>\n' +
                        '        <plugins>\n' +
                        '            <plugin>\n' +
                        '                <groupId>org.springframework.boot</groupId>\n' +
                        '                <artifactId>spring-boot-maven-plugin</artifactId>\n' +
                        '            </plugin>\n' +
                        '        </plugins>\n' +
                        '    </build>\n' +
                        '\n' +
                        '</project>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>STOP</b>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaSpringSecurityEx02Content;