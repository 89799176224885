import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class SpeedTestNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-speed-test", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://linuxhint.com/speed-test-synology-nas/"}>How to Speed Test Synology NAS?</a>
                       </li>

                        {/*<li>*/}
                        {/*    <a target={"_blank"} href={"https://linuxhint.com/use-vmm-to-create-a-linux-developer-environment-on-synology-nas/"}>Use VMM to Create a Linux Developer Environment on Synology NAS</a>*/}
                        {/*</li>*/}
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpeedTestNasContent;