import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class SshVmNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-ssh-vm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Conectare SSH la masina virtuala Ubuntu instalata pe NAS</b>
                    <br/><br/>

                    Context:
                    <ul>
                        <li>NAS conectat la router prin cablu</li>
                        <li>Laptop conectat la router prin cablu
                            <br/>
                            (observatie: de fapt NAS si laptopul sunt conectat la un <i>switch</i>; router-ul pe care il am - care e un ONT, de fapt - , desi are 2 LAN-uri, pare ca functioneaza doar cu 1-ul)</li>
                    </ul>

                    Probabil merge si in urmatorul context:
                    <ul>
                        <li>NAS conectat la router prin cablu</li>
                        <li>Laptop conectat la router wireless</li>
                        <li>NAS conectat la laptop prin cablu</li>
                    </ul>

                    <b>1.1. IP</b>
                    <br/><br/>

                    Dupa ce se intaleaza masina virtuala Ubuntu pe NAS, de obicei nu ia si un <b>IP</b>.
                    <br/>
                    Pentru a realiza acest lucru, ne conectam la Ubuntu. Apoi dintr-in <b>terminal</b> executam comenzile:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt update\n' +
                            'sudo apt install net-tools\n\n' +
                            'sudo apt update\n' +
                            'sudo apt -y install qemu-guest-agent\n\n' +
                            'systemctl enable qemu-guest-agent\n' +
                            'systemctl start qemu-guest-agent\n' +
                            'systemctl status qemu-guest-agent\n\n' +
                            'ifconfig'}
                    </SyntaxHighlighter>
                    Apoi se face restart la VM.
                    <br/>

                    Dupa comenzile de mai sus ar trebui sa vedem ca avem un IP.

                    <br/>
                    <br/>
                    <b>1.2. Server SSH</b>
                    <br/><br/>

                    Apoi, pentru ca masina sa accepte conexiuni SSH:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install openssh-server'}
                    </SyntaxHighlighter>

                    Verificare ca e pornit serverul de ssh:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl status ssh'}
                    </SyntaxHighlighter>

                    Pentru a obtine IP:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'hostname -I'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>1.3. Conectare din windows cu putty</b>
                    <br/><br/>

                    Se deschide Putty din Windows:
                    <ul>
                        <li>Host Name (or IP address): vom folosi IP-ul dat de comanda <b>hostname - I</b></li>
                        <li>Port: 22</li>
                        <li>se apasa butonul <i>Open</i></li>
                        <li>din consola, ne conectam cu un cont de administrator</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.snel.com/support/nstall-qemu-guest-agent-for-debian-ubuntu/"}>How to install Qemu guest agent for Debian / Ubuntu</a>
                       </li>

                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/use-vmm-to-create-a-linux-developer-environment-on-synology-nas/"}>Use VMM to Create a Linux Developer Environment on Synology NAS</a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SshVmNasContent;