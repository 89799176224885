import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class PunctStatieVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-puncte-de-statie", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Unghiulatia</b> este definita de locul si inaltimea de la camara <i>vede</i> o scena.
                    <br/>

                    In plan vertical, exista 3 directii de orientare ale axului vederii:
                    <ul>
                        <li>
                            <b>unghi normal</b>
                            <ul>
                                <li>camera se afla la acelasi nivel cu subiectul</li>
                                <li>subiectul este vazut de la inaltimea ochilor lui</li>
                                <li>axul privirii ramane paralel cu planul orizontal al pamantului</li>
                                <li>efect: relatie echidistanta, normala, neimplictata cu subiectul</li>
                            </ul>
                        </li>
                        <li>
                            <b>unchi plonjat</b>
                            <ul>
                                <li>
                                    camera se afla dessupra nivelului subiectului
                                </li>
                                <li>obiectivul este indreptat spre jos</li>
                                <li>efect: diminiuarea importantei subiectului</li>
                            </ul>

                            Variatii:
                            <ul>
                                <li>
                                    <b>unghiul pasarii</b>
                                    <ul>
                                        <li>punctul de statie e la un inaltime mai mare, aproape de vertical</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>unghiul calaretului</b>
                                    <ul>
                                        <li>punctul de statie e la inaltimea unui om urcat pe un cal</li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>unchi contraplojat / racursi</b>
                            <li>
                                <li>camera se afla sub nivelul subiectului</li>
                                <li>obiectivul este indreptat in sus</li>
                                <li>efect: accentuare importanta subiect, exagerand perspectiva normala, deformand obiectele</li>
                            </li>

                            Variatii:
                            <ul>
                                <li>
                                    <b>unghiul stomacului</b>
                                    <ul>
                                        <li>subiectul este privit de la nivelul stomacului</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>unghiul broastei</b>
                                    <ul>
                                        <li>
                                            subiectul este privit aproape de jos
                                        </li>
                                        <li>
                                            exemplu efect: mareste inaltimea unui sarituri
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                    </ul>

                    Din punct de vedere al inclinarii laterale:
                    <ul>
                        <li>
                            <b>unchiul olandez</b>
                            <ul>
                                <li>efect: anormalitate</li>
                            </ul>
                        </li>
                    </ul>

                    <b>Punctul de statie</b> (viewpoint) al aparatului este pozitia fixa a aparatului in decor (de unde se fotografiaza, filmeaza).

                    <br/>
                    <br/>

                    Pozitia camerei fata de subiect/personaj (<b>point-of-view-shot / POV</b>), determina:
                    <ul>
                        <li>un unghi al privirii</li>
                        <li>o directie a privirii (de sus in jos, de jos in sus, de la stanga la drepta; de la dreapta spre stanga; privire drept in fata)</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PunctStatieVideografieContent;