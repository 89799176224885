import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-cryptography/IndexContent";


class JavaCryptographyEx01Content extends BaseContentPage{

    constructor(props) {
        super(props, "java-cryptography-ex-01", IndexContent);
    }

    render() {

        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    O semnatura digitala trebuie sa respecte urmatoarele cerinte:
                    <ul>
                        <li><b>autentificare</b>: semnatura digitala ofera destinatorului motive sa creada ca mesajul/documentul a fost creat si trimis de expeditorul pretins </li>
                        <li><b>integritate</b>: semnatura digitala asigura ca mesajul/documentul nu a fost alterat in procesul de transmitere</li>
                        <li><b>nerepudiere (nonrepudiation)</b>: expeditorul/semanatarul nu poate nega autenticitatea semnaturii digitale</li>
                    </ul>

                    <b>Criptografia cu cheie publica (sau criptografia asimentrica)</b> foloseste 2 chei diferite, generate simultan și legate între ele:
                    <ul>
                        <li>cheie publica</li>
                        <li>cheie privata</li>
                    </ul>

                    Cheia privată trebuie sa fie:
                    <ul>
                        <li><b>păstrată de utilizator</b></li>
                        <li><b>secreta</b>: nu trebuie sa fie trimisa nimanui</li>
                    </ul>

                    Cheia publică trebuie sa fie:
                    <ul>
                        <li><b>accesibilă</b> tuturor utilizatorilor sistemului care doresc să comunice.</li>
                    </ul>

                    {/*<b>Observatie</b>: Cheile publice și private sunt generate simultan și sunt legate între ele.*/}
                    {/*<br/>*/}
                    {/*<i><b>Un mesaj criptat cu cheia publică a destinatarului, poate fi decriptat numai cu cheia privată asociată.</b></i>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}

                    {/*Fluxul pentru criptarea/decriptarea unui mesaj:*/}
                    {/*<ul>*/}
                    {/*    <li>Mesaj (expeditor) + cheie publică (a destinatarului) => Mesaj criptat (catre destinatar)</li>*/}
                    {/*    <li>Mesaj criptat + cheie privată => Mesaj decriptat</li>*/}
                    {/*</ul>*/}

                    {/*<b>Criptarea:</b> Utilizatorul 1 (U1) vrea sa trimita un document XML criptat catre utilizatorul 2 (U2):*/}
                    {/*<ul>*/}
                    {/*    <li>U1 are documentul XML necriptat</li>*/}
                    {/*    <li><b>U1 are cheia publica de la U2</b></li>*/}
                    {/*    <li>U1 cripteaza documentul XML cu <b>cheia publica</b> de la U2</li>*/}
                    {/*    <li>U1 trimite documentul XML criptat catre U2</li>*/}
                    {/*</ul>*/}

                    {/*<b>Decriptarea:</b> Utilizatorul 2 (U2) primeste un document XML criptat de la utilizatorul 1 (U1) si vrea sa il decripteze:*/}
                    {/*<ul>*/}
                    {/*    <li>U2 primeste documentul XML criptat de la U1</li>*/}
                    {/*    <li><b>U2 are cheia privata</b> (cheia publica, asociata cu aceata cheie privata a fost trimisa catre U1)</li>*/}
                    {/*    <li>U2 decripteaza documentul XML criptat cu <b>cheia privata</b></li>*/}
                    {/*</ul>*/}

                    {/*Fluxul pentru verificarea autenticitatii unui mesaj:*/}
                    {/*<ul>*/}
                    {/*    <li>Mesaj (decriptat?)+ cheie privată => Mesaj semnat</li>*/}
                    {/*    <li>Mesaj semnat + cheie publică => Autentificare</li>*/}
                    {/*</ul>*/}

                    Cele două faze pentru realizarea semnăturii digitale:
                    <ul>
                        <li><b>procesul de semnare</b>: <i>expeditorul</i> criptează datele cu <i>cheia privată a lui</i> și le trimite <i>receptorului</i>;
                            <br/>
                            <br/>
                            <b>Pasi:</b> <br/>
                            <ul>
                                <li>
                                    Preconditii expeditor:
                                    <ul>
                                        <li>
                                            documentul expeditor: A
                                        </li>
                                        <li>
                                            cheia privata a expeditorului: k
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Algoritm:
                                    <ul>
                                        <li>
                                            pe <b>documentul expeditorului (A)</b> se aplică o <b>functie de hash: h(A)</b> si se obtine <b>hash-ul documentului: hd</b>
                                        </li>
                                        <li>
                                            se <b>cripteaza hash-ul documentului (hd)</b> cu <b>cheia privata a expeditorului (k)</b>:  <b>DS (Digital Signature)</b>
                                        </li>
                                        <li>
                                            se trimite receptorului documentul A + DS (unde DS = cripteaza(hd, k)
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <b>Observatie:</b> procesul de semnare nu se urmareste protejarea informatiilor din documentul semnat
                            ci numai dovedirea faptului ca acel document provine de la cel care l-a semnat.
                        </li>
                        <br/>
                        <li>
                            <b>procesul de verificare semnare</b>: <i>receptorul</i> primeste document A + DS (Digital Signature)<br/> si verifica daca este valid<br/>

                            <br/>
                            <b>Pasi:</b> <br/>
                            <ul>
                                <li>
                                    Preconditii receptor:
                                    <ul>
                                        <li>
                                            documentul A + DS (Digital Signature)
                                        </li>
                                        <li>
                                            cheia publica a expeditorului: K
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Algoritm:
                                    <ul>
                                        <li>
                                            <b>se decripteaza DS (Digital Signature) cu cheia publica a expeditorului si se obtine un hash-ul semnaturii</b>: hs
                                        </li>
                                        <li>
                                            pe <b>documentul expeditorului (A)</b> se aplică o <b>functie de hash <i>h(A)</i></b> si obtinem <b>hash-ul documentului: hd</b>
                                        </li>
                                        <li>
                                            <b>se compara</b> hash-ul semnaturii (hs) cu hash-ul documentului A (hd); daca cele 2 valori coincid atunci documentul+AD (Digital Signature) este valid
                                         </li>
                                    </ul>
                                </li>

                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>STOP</b>
                </div>


                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaCryptographyEx01Content;