import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class MigrationLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-migration", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. php artisan make:migration</b>
                    <br/>
                    <br/>
                    Pentru a genera un fisier pentru monipularea bazei de date, se da comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:migration NUME'}
                    </SyntaxHighlighter>
                    Se va creea un fisier cu numele <i>DATA_ID_NUME.php</i>.
                    <br/>
                    In acest fisier se definesc modificari de baza de date:
                    <ul>
                        <li>creare tabele</li>
                        <li>adaugare coloane</li>
                        <li>etc</li>
                    </ul>

                    Apoi, pentru executarea efectiva a modificarilor definite in fisierul de mai sus, in baza de date
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan migrate'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Exemplu: Adaugare coloana noua</b>
                    <br/>
                    <br/>

                    Se da comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:migration update_users_table_add_provider_column'}
                    </SyntaxHighlighter>
                    Se va creea un fisier cu numele: <i>2023_04_01_070150_update_users_table_add_provider_column.php</i> avand urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'use Illuminate\\Database\\Migrations\\Migration;\n' +
                            'use Illuminate\\Database\\Schema\\Blueprint;\n' +
                            'use Illuminate\\Support\\Facades\\Schema;\n' +
                            '\n' +
                            'class UpdateUsersTableAddProviderColumn extends Migration\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Run the migrations.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function up()\n' +
                            '    {\n' +
                            '        //\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Reverse the migrations.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function down()\n' +
                            '    {\n' +
                            '        //\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Apoi se modifica, rezultand:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'use Illuminate\\Database\\Migrations\\Migration;\n' +
                            'use Illuminate\\Database\\Schema\\Blueprint;\n' +
                            'use Illuminate\\Support\\Facades\\Schema;\n' +
                            '\n' +
                            'class UpdateUsersTableAddProviderColumn extends Migration\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Run the migrations.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function up()\n' +
                            '    {\n' +
                            '        Schema::table(\'users\', function($table) {\n' +
                            '            $table->string(\'provider\')->default(\'INTERN\');\n' +
                            '        });\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Reverse the migrations.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function down()\n' +
                            '    {\n' +
                            '        Schema::table(\'users\', function($table) {\n' +
                            '            $table->dropColumn(\'provider\');\n' +
                            '        });\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Apoi se ruleaza comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan migrate'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MigrationLaravelContent;