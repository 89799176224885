import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CloneGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-clone", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git clone</b>
                    <br/>
                    <br/>

                    Clonarea este procesul de creere a unui copi a un repository remote.
                    <br/>
                    Atata timp cat nu se face <i>merge</i> intre copie si remote, acesta pot fi vazute ca 2 entitati separate.
                    <br/>
                    Implicit, cand se face o clona, sursa remote are numele <i>origin</i>. In plus, trebuie cunoscut locatia repository-ului remote (pe Bitbucket, URL-ul).

                    <hr/>

                    <b>1. Clonarea</b>
                    <br/>
                    <br/>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git clone URL'}
                    </SyntaxHighlighter>

                    Exemplu :
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git clone https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git\n\n' +
                        'Cloning into \'git-vanilla\'...\n' +
                        'remote: Enumerating objects: 6, done.\n' +
                        'remote: Counting objects: 100% (6/6), done.\n' +
                        'remote: Compressing objects: 100% (2/2), done.\n' +
                        'remote: Total 6 (delta 0), reused 0 (delta 0), pack-reused 0\n' +
                        'Unpacking objects: 100% (6/6), 442 bytes | 20.00 KiB/s, done.'}
                    </SyntaxHighlighter>
                    Se va creea un director cu numele proiectului de pe Bitbucket (in acest caz: <i>git-vanilla</i>) si in interior se va descarca sursele proiectului.

                    <br/>
                    Daca se vrea ca numele directorului sa aiba alt nume, se poate specifca acest lucru:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git clone URL nume_proiect'}
                    </SyntaxHighlighter>

                    Exemplu :

                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git clone https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git git-vanilla-tmp\n' +
                        'Cloning into \'git-vanilla-tmp\'...\n' +
                        'Unpacking objects: 100% (6/6), 442 bytes | 27.00 KiB/s, done.'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a verifica ca numele <i>origin</i> indica catre URL de unde s-a clonat proiectul, se poate folosi instructiunea: <b>git remote -v</b> (Optiunea <b>-v</b> / <b>--verbose</b> determina afisarrea URL-ului langa numele repository-ului remote)

                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'git remote -v\n' +
                        'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git (fetch)\n' +
                        'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git (push)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Protocolul SSH</b>
                    <br/>
                    <br/>
                    Pentru protoclul SSH este nevoie de generarea unui perechi de chei publice si private.
                    <br/>
                    In Windows, se poate folosi Putty sau Git Bash pentru a genera cheia.

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="git" style={androidstudio}>
                        {'ssh-keygen -t rsa -C "kj@ibedaria.ro" -b 4096'}
                    </SyntaxHighlighter>

                    Se vor genera 2 fisiere (in C:\Users\[utilizator]\.ssh):
                    <ul>
                        <li>id_rda</li>
                        <li>id_rsa.pub</li>
                    </ul>
                    Cheia publica este in fisierul <b>id_rsa.pub</b>.
                    <br/>
                    Daca se foloeste GitHub, cheia publica (continutul fisierului id_rsa.pub) se copiaza in : https://<i>[github]</i>/profile/keys.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CloneGitContent;