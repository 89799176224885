import {useRoutes} from "react-router-dom";
import React from "react";
import SpringBootHomeContent from "./IndexContent";
import PrivateRoute from "../../security/PrivateRoute";
import GithubProviderSpringBootContent from "./GithubProviderSpringBootContent";
import GoogleProviderSpringBootContent from "./GoogleProviderSpringBootContent";
import LDAPSpringBootContent from "./LDAPSpringBootContent";
import ExternalizeApplicationPropertiesTomcatSpringBootContent from "./ExternalizeApplicationPropertiesTomcatSpringBootContent";
import NamingStrategySpringBootContent from "./NamingStrategySpringBootContent";
import IterationDataSpringBootContent from "./IterationDataSpringBootContent";
import SpecificationExistsSpringBootContent from "./SpecificationExistsSpringBootContent";
import JasyptSpringBootContent from "./JasyptSpringBootContent";
import RepositoryQuerySpringBootContent from "./RepositoryQuerySpringBootContent";

{/*Spring Boot*/}
// <Route path="/spring-boot/index" element={<SpringBootHomeContent/>} exact/>
// <Route element={<PrivateRoutes/>}>
//     <Route path="/spring-boot/github-provider-auth" element={<GithubProviderSpringBootContent/>} exact/>
//     <Route path="/spring-boot/google-provider-auth" element={<GoogleProviderSpringBootContent/>} exact/>
// </Route>

export default function Router() {
    let element = useRoutes([
        {path: "/spring-boot/index", element: <SpringBootHomeContent/>},
        {path: "/spring-boot/github-provider-auth", element: <PrivateRoute element={<GithubProviderSpringBootContent/>}/>},
        {path: "/spring-boot/google-provider-auth", element: <PrivateRoute element={<GoogleProviderSpringBootContent/>}/>},
        {path: "/spring-boot/ldap", element: <PrivateRoute element={<LDAPSpringBootContent/>}/>},
        {path: "/spring-boot/externalize-application-properties-tomcat", element: <PrivateRoute element={<ExternalizeApplicationPropertiesTomcatSpringBootContent/>}/>},
        {path: "/spring-boot/naming-strategy", element: <PrivateRoute element={<NamingStrategySpringBootContent/>}/>},
        {path: "/spring-boot/iterating-data", element: <PrivateRoute element={<IterationDataSpringBootContent/>}/>},
        {path: "/spring-boot/specification-exists", element: <PrivateRoute element={<SpecificationExistsSpringBootContent/>}/>},
        {path: "/spring-boot/jasypt", element: <PrivateRoute element={<JasyptSpringBootContent/>}/>},
        {path: "/spring-boot/repository-query", element: <PrivateRoute element={<RepositoryQuerySpringBootContent/>}/>},
    ]);

    return element;
}