import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DiffContainerDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-container-diff", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker container diff</b>
                    <br/>
                    <br/>

                    Ofera un change-log pentru sistemul de fisiere, de cand a inceput containerul sa ruleze (ce fisiere au aparut noi, in comparatie cu ce era in imagine)
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container diff [container]'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'C /etc\n' +
                            'C /etc/nginx\n' +
                            'C /etc/nginx/conf.d\n' +
                            'C /etc/nginx/conf.d/default.conf\n' +
                            'C /run\n' +
                            'A /run/nginx.pid\n' +
                            'C /var\n' +
                            'C /var/cache\n' +
                            'C /var/cache/nginx\n' +
                            'A /var/cache/nginx/client_temp\n' +
                            'A /var/cache/nginx/fastcgi_temp\n' +
                            'A /var/cache/nginx/proxy_temp\n' +
                            'A /var/cache/nginx/scgi_temp\n' +
                            'A /var/cache/nginx/uwsgi_temp\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DiffContainerDockerContent;