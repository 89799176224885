import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class NgRxAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-ngrx", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. NgRx: instalare</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @ngrx/store'}
                    </SyntaxHighlighter>
                    (va genera cod diferit pentru componentele standalone si pentru cele normale)
                    <br/>
                    <br/>
                    sau (alternativ, dar fara de adaugare/completare de cod automata):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install --save @ngrx/store'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. NgRx: Stuctura (reducer, actions)</b>
                    <br/>
                    <br/>

                    De obicei, avem urmatoarea structura:
                    <ul>
                        <li>/store</li>
                        <li>/store/*.actions.ts (contine actiunile posibile)</li>
                        <li>/store/*.reducer.ts (contine functia reducer)</li>
                    </ul>

                    Exemplu *.reducer.ts (shopping-list.reducer.ts):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Action} from \'@ngrx/store\';\n' +
                            '\n' +
                            'const initialState = {\n' +
                            '\tdata: [\n' +
                            '\t\tnew Product("telefon"),\n' +
                            '\t\tnew Product("ceas")\n' +
                            '\t]\n' +
                            '}\n' +
                            '\n' +
                            'export function shoppingListReducer(state = initialState, action: Action){\n' +
                            '\n' +
                            '\tswitch(action.type){\n' +
                            '\t\tcase \'ADD_PRODUCT\':\n' +
                            '\t\t\t// se returneaza o noua stare; nu se modifica ceea veche\n' +
                            '\t\t\treturn {\n' +
                            '\t\t\t\t...state,\n' +
                            '\t\t\t\tdata: [...state.data, action] // <-- aici inca nu e complet codul\n' +
                            '\t\t\t};\n' +
                            '\t\tdefault:\n' +
                            '\t\t\treturn state\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Un reducer primeste o stare (starea de inceput) si o actiune si returneaza o noua stare (o stare de sfarsit).
                    <br/>
                    <b>Starea de inceput NU trebuie sa se modifice</b> (de obicei se face o copie, se fac modificari pe aceea copie, si se returneaza copia)

                    <hr/>
                    Exemplu *.actions.ts (shopping-list.actions.ts):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Action} from \'@ngrx/store\';\n' +
                            '\n' +
                            'export const ADD_PRODUCT = \'ADD_PRODUCT\'\n' +
                            '\n' +
                            'export class AddProductAction implements Action{\n' +
                            '\treadonly type = ADD_PRODUCT;\n' +
                            '\tpayload: Product; // payload - se poate da orice nume; \n' +
                            '\t                  // doar "type" este obligatoriu pentru ca se implementeaza interfata Action\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Refactorizam codul sa folosim <b>*.actions.ts</b> (de mai sus):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Action} from \'@ngrx/store\';\n' +
                            'import * as ShoppingListActions from \'./shopping-list.actions\'; \n' +
                            '\n' +
                            'const initialState = {\n' +
                            '\tdata: [\n' +
                            '\t\tnew Product("telefon"),\n' +
                            '\t\tnew Product("ceas")\n' +
                            '\t]\n' +
                            '}\n' +
                            '\n' +
                            'export function shoppingListReducer(state = initialState, action: AddProductAction){\n' +
                            '\n' +
                            '\tswitch(action.type){\n' +
                            '\t\tcase ShoppingListActions.ADD_PRODUCT:\n' +
                            '\t\t\t// se returneaza o noua stare; nu se modifica ceea veche\n' +
                            '\t\t\treturn {\n' +
                            '\t\t\t\t...state,\n' +
                            '\t\t\t\tdata: [...state.data, action.payload] // <!-- action.payload \n' +
                            '\t\t\t};\n' +
                            '\t\tdefault:\n' +
                            '\t\t\treturn state\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. NgRx: .module.ts</b>
                    <br/>
                    <br/>

                    Apoi, in <b>.module.ts</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {StoreModule} from \'@ngrx/store\';'}
                    </SyntaxHighlighter>
                    apoi in sectiunea <b>imports</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [StoreModule.forRoot({shoppingList: shoppingLostReducer})]'}
                    </SyntaxHighlighter>
                    unde <i>shoppingListReducer</i> e functia reducer definita mai sus.
                    <br/>
                    <br/>

                    Daca am avea mai multe reducere:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [StoreModule.forRoot({\n' +
                            '   shoppingList: shoppingListReducer,\n' +
                            '   auth: authReducer\n' +
                            '})]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Mod de folosire</b>
                    <br/>
                    <br/>

                    In componenta noastra, injectam in constructor un obiect de tip <b>Store</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'constructor(private store: Store<{shoppingList: {data: Product[]} }>){}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.dataObserver = this.store.select(\'shoppingList\')'}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'dataObserver: Observable<{data: Product[]}>'}
                    </SyntaxHighlighter>

                    iterare printr-un observer (de fapt conversie):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'*ngFor="let produse of (dataObserver | async).data"'}
                    </SyntaxHighlighter>

                    <b>dispatch</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.store.dispatch(new ShoppingListActions.AddProduct(product))'}
                    </SyntaxHighlighter>
                    pentru simplificare in folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Action} from \'@ngrx/store\';\n' +
                            '\n' +
                            'export const ADD_PRODUCT = \'ADD_PRODUCT\'\n' +
                            '\n' +
                            'export class AddProductAction implements Action{\n' +
                            '\treadonly type = ADD_PRODUCT;\n' +
                            '\tconstructor(private payload: Product);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pot exista mai multe actiuni, declarate similar:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Action} from \'@ngrx/store\';\n' +
                            '\n' +
                            'export const DELETE_PRODUCT = \'DELETE_PRODUCT\'\n' +
                            '\n' +
                            'export class DeleteProductAction implements Action{\n' +
                            '\treadonly type = DELETE_PRODUCT;\n' +
                            '\tconstructor();\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Putem crea un tip cu toate actiunile:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export type ShoopingListActions = AddProductAction | DeleteProductAction'}
                    </SyntaxHighlighter>

                    si apoi putem scrie <i> action: ShoopingListActions</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export function shoppingListReducer(state = initialState, action: ShoopingListActions){...}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. ActionReducerMap</b>
                    <br/>
                    <br/>
                    Sa refactorizam:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export interface State{\n' +
                            '\tdata: Product[];\n' +
                            '\teditedProduct: Product;\n' +
                            '}\n' +
                            '\n' +
                            'const initialState: State = {\n' +
                            '\tdata: [\n' +
                            '\t\tnew Product("telefon"),\n' +
                            '\t\tnew Product("ceas")\n' +
                            '\t],\n' +
                            '\teditedProduct: null,\n' +
                            '}'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export function shoppingListReducer(state: State = initialState, action: ShoopingListActions){...}'}
                    </SyntaxHighlighter>

                    apoi cream o clasa in care gestionam reduceri (de exemplu: <i>app.reducer.ts</i>) in care folosim <b>ActionReducerMap</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export interface AppState{\n' +
                            '\tshoppingList: fromShoppingList.State;\n' +
                            '\t//auth: fromAuth.State;\n' +
                            '}\n' +
                            '\n' +
                            'export const appReducer: ActionReducerMap<AppState>={\n' +
                            '\tshoppingList: fromShoppingList.shoppingListReducer,\n' +
                            '\t//auth: fromAuth.authReducer\n' +
                            '}'}
                    </SyntaxHighlighter>

                    apoi in <i> ./module.ts</i> vom putea scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import * as fromApp from \'./store/app.reducer\'\n' +
                            'StoreModule.forRoot(fromApp.appReducer);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. NgRx Effects</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install --save @ngrx/effects'}
                    </SyntaxHighlighter>

                    Fie urmatorul fisier care contine actiunile pentru <i>auth.actions.ts</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export const LOGIN_START=\'LOGIN_START\';\n' +
                            'export const LOGIN=\'LOGIN\';\n' +
                            'export const LOGIN_FAIL=\'LOGIN_FAIL\';\n' +
                            'export const LOGIN_LOGOUT=\'LOGIN_LOGOUT\';\n' +
                            '\n' +
                            '\n' +
                            'export class LoginStartAction implements Action{\n' +
                            '\treadonly type = LOGIN_START;\n' +
                            '\tconstructor(public payload: {email: string; password: string})\n' +
                            '}\n' +
                            '\n' +
                            'export class LoginAction implements Action{\n' +
                            '\treadonly type = LOGIN;\n' +
                            '\tconstructor(public payload: {email: string; userId: string})\n' +
                            '}\n' +
                            '\n' +
                            'export class LogoutFailAction implements Action{\n' +
                            '\treadonly type = LOGIN_FAIL;\n' +
                            '\tconstructor(public payload: {errorMessage: string})\n' +
                            '}\n' +
                            '\n' +
                            '\n' +
                            'export class LogoutAction implements Action{\n' +
                            '\treadonly type = LOGOUT;\n' +
                            '}\n' +
                            '\n' +
                            'export type AuthActions = LoginAction | LoginStartAction | LogoutAction | LogoutFailAction'}
                    </SyntaxHighlighter>

                    <hr/>
                    Ca idee:
                    <ul>
                        <li>switchMap - se opreste din lucrat la ce are de facut si incepe sa lucreze la noua comanda' doar ultima comanda va fi finalizata</li>
                        <li> concatMap - noua comanda este pusa in coada; dupa ce se termina comanda curenta, se incepe sa se lucreze la urmatoarea comanda din coada</li>
                        <li>
                            mergeMap - se lucreza la toate comenziile in acelasi timp pe masura ce vin
                        </li>
                        <li>
                            exhaustMap - ignora noile comenzi cat timp se lucreaza o comanda; dupa ce se termina poate primi noi comenzi
                        </li>
                        <li>
                            of (este sincron, fiind un observabil care emite sincron)
                        </li>
                    </ul>
                    <hr/>

                    Se creeaza <b>.effects.ts</b> (de exemplu: auth.effects.ts):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {Actions, ofType, createEffect } from \'@ngrx/effects\';\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'export class AuthEffects{\n' +
                            '\n' +
                            '\tauthLogin = createEffect(() => \n' +
                            '\t\tthis.actions$.pipe(\n' +
                            '\t\t\tofType(AuthActions.LOGIN_START), // filtreaza din fluxul de actiuni doar LOGIN_START \n' +
                            '\t\t\tswitchMap(\n' +
                            '\t\t\t    (authData: AuthActions.LoginStartAction) => {\n' +
                            '\t\t\t\t\t\n' +
                            '\t\t\t        return this.http\n' +
                            '\t\t\t          .post(URL)\n' +
                            '\t\t\t          .pipe(\n' +
                            '\t\t\t\t\tmap( data => {\n' +
                            '\t\t\t\t\t\t// trimite catre actiunea LoginAction\n' +
                            '\t\t\t\t\t\treturn new AuthActions.LoginAction({email: data.email, userId: data.userId});' +
                            '\t\t\t\t\t}),\n' +
                            '\t\t\t\t\tcatchError( error => {\n' +
                            '\t\t\t\t\t\t// trimite catre actiune LoginFail\n' +
                            '\t\t\t\t\t\treturn of(new AuthActions.LoginFail("Eroare"));\n' +
                            '\t\t\t\t\t})\n' +
                            '\t\t\t        );\n' +
                            '\t\t\t\t\t\n' +
                            '\t\t\t    }\n' +
                            '\t\t\t)\n' +
                            '\t\t)\n' +
                            '\t);\n' +
                            '\n' +
                            '\tauthSuccess = createEffect(() =>  \n' +
                            '\t\tthis.actions$.pipe(\n' +
                            '\t\t\tofType(AuthActions.LOGIN), // filtreaza din fluxul de actiuni doar LOGIN\n' +
                            '\t\t\ttap(()=>{ // nu trimite nici o actiune; ci doar face redirect catre alta pagina\n' +
                            '\t\t\t\tthis.router.navigate([\'/\']);\n' +
                            '\t\t\t})\n' +
                            '\t\t)\n' +
                            '\t);\n' +
                            '\n' +
                            '\tconstructor(private actions$: Actions, private http: HttpClient, private router: Router){ // <!- $ de la sfarsit e doar o conventie si poate lipsi\n' +
                            '\t\t// actions$ - e un observable\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In <b>.module.ts</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'EffectModule.forRoot([AuthEffects])'}
                    </SyntaxHighlighter>

                    Exemplu de dispatch:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'this.store.dispath(new AuthActions.LoginStartAction({email: email, password: password}))'
                        }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. NgRx Effects - sintaxa noua/alternativa</b>
                    <br/>
                    <br/>

                    Actiunile se definesc folosind <b>createAction</b> si <b>props</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {createAction, props} from \'@ngrx/store\';\n' +
                            '\n' +
                            'export const loginStart = createAction(\n' +
                            '  \'[Auth] Login Start\',\n' +
                            '  props<{\n' +
                            '    email: string;\n' +
                            '    password: string\n' +
                            '  }>()\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export const signupStart = createAction(\n' +
                            '  \'[Auth] Signup Start\',\n' +
                            '  props<{\n' +
                            '    email: string;\n' +
                            '    password: string\n' +
                            '  }>()\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export const authenticateSuccess = createAction(\n' +
                            '  \'[Auth] Authenticate Success\',\n' +
                            '  props<{\n' +
                            '    email: string;\n' +
                            '    userId: string;\n' +
                            '    token: string;\n' +
                            '    expirationDate: Date;\n' +
                            '    redirect: boolean\n' +
                            '  }>()\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export const authenticateFail = createAction(\n' +
                            '  \'[Auth] Authenticate Fail\',\n' +
                            '  props<{\n' +
                            '    errorMessage: string\n' +
                            '  }>()\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export const clearError = createAction(\n' +
                            '  \'[Auth] Clear Error\'\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export const autoLogin = createAction(\n' +
                            '  \'[Auth] Auto Login\'\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export const logout = createAction(\n' +
                            '  \'[Auth] Logout\'\n' +
                            ');\n'}
                    </SyntaxHighlighter>

                    Functiile de reducere se definesc folosind <b>createReducer</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'import { Action, createReducer, on } from \'@ngrx/store\';\n' +
                            'import { User } from \'../user.model\';\n' +
                            'import * as AuthActions from \'./auth.actions\';\n' +
                            '\n' +
                            '\n' +
                            'export interface State {\n' +
                            '  user: User;\n' +
                            '  authError: string;\n' +
                            '  loading: boolean;\n' +
                            '}\n' +
                            '\n' +
                            '\n' +
                            'const initialState: State = {\n' +
                            '  user: null,\n' +
                            '  authError: null,\n' +
                            '  loading: false\n' +
                            '};\n' +
                            '\n' +
                            '\n' +
                            'const _authReducer = createReducer(\n' +
                            '\n' +
                            '  initialState,\n' +
                            '\n' +
                            '  on(\n' +
                            '    AuthActions.loginStart,\n' +
                            '    AuthActions.signupStart,\n' +
                            '    (state) => ({\n' +
                            '      ...state,\n' +
                            '      authError: null,\n' +
                            '      loading: true\n' +
                            '    })\n' +
                            '  ),\n' +
                            '\n' +
                            '  on(\n' +
                            '    AuthActions.authenticateSuccess,\n' +
                            '    (state, action) => ({\n' +
                            '      ...state,\n' +
                            '      authError: null,\n' +
                            '      loading: false,\n' +
                            '      user: new User(\n' +
                            '        action.email,\n' +
                            '        action.userId,\n' +
                            '        action.token,\n' +
                            '        action.expirationDate\n' +
                            '      )\n' +
                            '    })\n' +
                            '  ),\n' +
                            '\n' +
                            '  on(\n' +
                            '    AuthActions.authenticateFail,\n' +
                            '    (state, action) => ({\n' +
                            '      ...state,\n' +
                            '      user: null,\n' +
                            '      authError: action.errorMessage,\n' +
                            '      loading: false\n' +
                            '    })\n' +
                            '  ),\n' +
                            '\n' +
                            '  on(\n' +
                            '    AuthActions.logout,\n' +
                            '    (state) => ({\n' +
                            '      ...state,\n' +
                            '      user: null\n' +
                            '    })\n' +
                            '  ),\n' +
                            '\n' +
                            '  on(\n' +
                            '    AuthActions.clearError,\n' +
                            '    (state) => ({\n' +
                            '      ...state,\n' +
                            '      authError: null\n' +
                            '    })\n' +
                            '  ),\n' +
                            '\n' +
                            ');\n' +
                            '\n' +
                            '\n' +
                            'export function authReducer(state: State, action: Action) {\n' +
                            '  return _authReducer(state, action);\n' +
                            '}\n'
                        }
                    </SyntaxHighlighter>

                    Efecte se definesc folosind <b>createEffect</b> (ca in sectiunea precedenta).


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NgRxAngularContent;