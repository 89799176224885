import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FriendlyURLLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-friendly-url", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    <b>Crearea de Friendly URL</b>
                    <br/>
                    <br/>

                    In <b>src/main/resources/META-INF</b> se creaza:
                    <ul>
                        <li>
                            directorul <b>friendly-url-routes/</b>
                        </li>
                        <li>
                            fisierul <b>routes.xml</b> (in directorul <b>friendly-url-routes/</b>)
                        </li>
                    </ul>

                    Daca avem <i>CreateEditRenderMvcCommand</i> (un clasa care implementeaza <b>MVCRenderCommand</b>):
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + IbridNomenclatorPortletKeys.IBRIDNOMENCLATOR,\n' +
                            '                "mvc.command.name=/",\n' +
                            '                "mvc.command.name=/add"\n' +
                            '        },\n' +
                            '        service = MVCRenderCommand.class\n' +
                            ')\n' +
                            'public class CreateEditRenderMvcCommand implements MVCRenderCommand {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                            '        \n' +
                            '        return "/edit.jsp";\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Atunci fisierul <b>routes.xml</b> :
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                            '<!DOCTYPE routes PUBLIC "-//Liferay//DTD Friendly URL Routes 7.3.0//EN" "http://www.liferay.com/dtd/liferay-friendly-url-routes_7_3_0.dtd">\n' +
                            '\n' +
                            '<routes>\n' +
                            '\n' +
                            '    <route>\n' +
                            '        <pattern>/add</pattern>\n' +
                            '        <implicit-parameter name="mvcRenderCommandName">/add</implicit-parameter>\n' +
                            '        <implicit-parameter name="p_p_lifecycle">0</implicit-parameter>\n' +
                            '        <implicit-parameter name="p_p_state">normal</implicit-parameter>\n' +
                            '    </route>\n' +
                            '\n' +
                            '</routes>'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            valorile din <b>pattern</b> nu se termină cu o bară oblică /.
                            <br/>
                            Un caracter oblic în urmă împiedică solicitarea să identifice ruta corectă.
                        </li>
                    </ul>

                    <hr/>
                    <b>Implementare <b>FriendlyURLMapper</b></b>
                    <br/>
                    <br/>

                    Implementare <b>FriendlyURLMapper</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'package ro.ibrid.nomenclator.portlet.route;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.portlet.DefaultFriendlyURLMapper;\n' +
                            'import com.liferay.portal.kernel.portlet.FriendlyURLMapper;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.nomenclator.constants.IbridNomenclatorPortletKeys;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "com.liferay.portlet.friendly-url-routes=META-INF/friendly-url-routes/routes.xml",\n' +
                            '                "javax.portlet.name=" + IbridNomenclatorPortletKeys.IBRIDNOMENCLATOR\n' +
                            '        },\n' +
                            '        service = FriendlyURLMapper.class\n' +
                            ')\n' +
                            'public class IbridFormsFriendlyURLMapper extends DefaultFriendlyURLMapper {\n' +
                            '\n' +
                            '    private static final String MAPPING = "nom-forms";\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getMapping() {\n' +
                            '        return MAPPING;\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    Componenta de mai sus are 2 proprietati:
                    <ul>
                        <li>
                            <b>com.liferay.portlet.friendly-url-router</b>: care specifica calea catre fisierul <b>routes.xml</b>
                        </li>
                        <li>
                            <b>javax.portlet.name</b>: care specifica numele portretului
                        </li>
                    </ul>

                    Portletul va putea fi apelat, exemplu:
                    <i>http://localhost:8080/web/guest/home<b>/-/nom-forms/</b></i>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a target={"_blank"} href={"https://help.liferay.com/hc/en-us/articles/360030416992-Friendly-URLs"}>
                                    Friendly URLs
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://www.opensource-techblog.com/how-to-create-friendly-url-for-liferay.html"}>
                                    How to create Friendly URL for Liferay portlet
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FriendlyURLLiferayContent;