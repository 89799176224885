import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JcmdJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-jcmd", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Flight Recorder (JFR)</b>
                    <br/>
                    <br/>

                    Se foloseste folosind comanda: <b>jcmd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'jcmd -help'}
                    </SyntaxHighlighter>

                    poate afisa
                    <SyntaxHighlighter>
                        {'Usage: jcmd <pid | main class> <command ...|PerfCounter.print|-f file>\n' +
                            '   or: jcmd -l\n' +
                            '   or: jcmd -h\n' +
                            '\n' +
                            '  command must be a valid jcmd command for the selected jvm.\n' +
                            '  Use the command "help" to see which commands are available.\n' +
                            '  If the pid is 0, commands will be sent to all Java processes.\n' +
                            '  The main class argument will be used to match (either partially\n' +
                            '  or fully) the class used to start Java.\n' +
                            '  If no options are given, lists Java processes (same as -l).\n' +
                            '\n' +
                            '  PerfCounter.print display the counters exposed by this process\n' +
                            '  -f  read and execute commands from the file\n' +
                            '  -l  list JVM processes on the local machine\n' +
                            '  -? -h --help print this help message'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Mission Control (JMC)</b>
                    <br/>
                    <br/>

                    <b>JDK Mission Control (JMC)</b> este un instrument grafic folosit pentru a afișa datele conținute în fișierele de ieșire JFR.

                    <br/>
                    <br/>
                    Pentru descarcare: <a target={"_blank"} href={"https://jdk.java.net/jmc/8/"}>JMC 8.3.1 General-Availability Release</a>
                    <br/>
                    <br/>

                    Se foloseste folosind comanda: <b>jmc</b>:

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>*/}
                    {/*            Writing Hello World in Java byte code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JcmdJavaContent;