import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class UnameLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-uname", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa informatii legate de sistemul de operare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uname'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Linux'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afisa arhitectura:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uname -m'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'x86_64'}
                    </SyntaxHighlighter>

                    Pentru a afisa toate detaliile:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uname -a'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Linux kj-Standard-PC-i440FX-PIIX-1996 5.15.0-50-generic #56-Ubuntu SMP Tue Sep 20 13:23:26 UTC 2022 x86_64 x86_64 x86_64 GNU/Linux'}
                    </SyntaxHighlighter>

                    Pentru a afisa kernel release:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uname -r'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'5.15.0-50-generic'}
                    </SyntaxHighlighter>

                    Pentru a afisa help-ul pentru comanda <i>uname</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uname --help'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Usage: uname [OPTION]...\n' +
                            'Print certain system information.  With no OPTION, same as -s.\n' +
                            '\n' +
                            '  -a, --all                print all information, in the following order,\n' +
                            '                             except omit -p and -i if unknown:\n' +
                            '  -s, --kernel-name        print the kernel name\n' +
                            '  -n, --nodename           print the network node hostname\n' +
                            '  -r, --kernel-release     print the kernel release\n' +
                            '  -v, --kernel-version     print the kernel version\n' +
                            '  -m, --machine            print the machine hardware name\n' +
                            '  -p, --processor          print the processor type (non-portable)\n' +
                            '  -i, --hardware-platform  print the hardware platform (non-portable)\n' +
                            '  -o, --operating-system   print the operating system\n' +
                            '      --help     display this help and exit\n' +
                            '      --version  output version information and exit\n' +
                            '\n' +
                            'GNU coreutils online help: <https://www.gnu.org/software/coreutils/>\n' +
                            'Full documentation <https://www.gnu.org/software/coreutils/uname>\n' +
                            'or available locally via: info \'(coreutils) uname invocation\''}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UnameLinuxContent;