import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class PortMappingFlagDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-port-mapping-flag", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker: flag -p (port mapping)</b>

                    <br/>
                    <br/>
                    Mapeaza un port de pe masina locala la un port din interiorul containerului.
                    Adica redirecteaza cererile care vin pe un port de pe masina locala catre un port din interiorul containerului.
                    <br/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p portLocal:portContainer imagine'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>portLocal: port de pe masina locala; redirecteaza cererile care vin pe un port de pe masina locala catre un port din interiorul containerului</li>
                        <li>portContainer: port din interiorul containerului</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -itd -p 8080:80 nginx'}
                    </SyntaxHighlighter>

                    verificare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'curl http://localhost:8080'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. docker: flag -P (port mapping)</b>
                    <br/>
                    <br/>
                    Pentru a permite Docker sa puna aleator portul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -itd -P nginx'}
                    </SyntaxHighlighter>

                    Daca inspectam cu <b>docker ps</b>, vom gasi portul <i>49153</i>.

                    <br/>
                    verificare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'curl http://localhost:49153'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PortMappingFlagDockerContent;