import React, {useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

const Child = () =>{
    return(
        <div>{new Date().toString()}</div>
    )
}

const Parent = () =>{

    let [count, setCount] = useState(1);

    const onClick = ()=>{
        setCount(count+1);
    }

    return (
        <>
            <button onClick={onClick}>Apasa</button>
            <div>S-a apasat de: {count} ori</div>
            <Child/>
        </>
    )
}

const Parent2 = ({children}) =>{

    let [count, setCount] = useState(1);

    const onClick = ()=>{
        setCount(count+1);
    }

    return (
        <>
            <button onClick={onClick}>Apasa</button>
            <div>S-a apasat de: {count} ori</div>
            {children}
        </>
    )
}

const Child3 = ({count}) =>{
    return(
        <div>
            {new Date().toString()}
            <br/>
            {count}
        </div>
    )
}

const Parent3 = ({children}) =>{

    let [count, setCount] = useState(1);

    const onClick = ()=>{
        setCount(count+1);
    }

    return (
        <>
            <button onClick={onClick}>Apasa</button>
            <div>S-a apasat de: {count} ori</div>

            {React.cloneElement(children, { count: count })}

        </>
    )
}

class UpdateChildReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-update-child", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Actualizare copii</b>
                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const Child = () =>{\n' +
                            '    return(\n' +
                            '        <div>{new Date().toString()}</div>\n' +
                            '    )\n' +
                            '}\n' +
                            '\n' +
                            'const Parent = () =>{\n' +
                            '\n' +
                            '    let [count, setCount] = useState(1);\n' +
                            '\n' +
                            '    const onClick = ()=>{\n' +
                            '        setCount(count+1);\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '        <>\n' +
                            '            <button onClick={onClick}>Apasa</button>\n' +
                            '            <div>S-a apasat de: {count} ori</div>\n' +
                            '            <Child/>\n' +
                            '        </>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <Parent/>

                    <br/>
                    Componenta parinte este creata astfel:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' <Parent />'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Fata actualizare copii</b>
                    <br/>
                    <br/>

                    <b>Daca componenta copil se trimite prin <b>props</b>, atunci randarea parintelui nu mai declaseaza randarea copiilor!</b>

                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const Parent = ({childen}) =>{\n' +
                            '\n' +
                            '    let [count, setCount] = useState(1);\n' +
                            '\n' +
                            '    const onClick = ()=>{\n' +
                            '        setCount(count+1);\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '        <>\n' +
                            '            <button onClick={onClick}>Apasa</button>\n' +
                            '            <div>S-a apasat de: {count} ori</div>\n' +
                            '            {childen}\n' +
                            '        </>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <Parent2 children={<Child/>}/>

                    <br/>

                    Componenta parinte este creata astfel:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' <Parent children={<Child/>}/>'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Parent>\n' +
                            '  <Child/>\n' +
                            '</Parent>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Apelare children cu props</b>
                    <br/>
                    <br/>

                    Pentru a trimite la <b>children</b>, noi <b>props</b>-uri:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' {React.cloneElement(children, { count: count })}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const Child = ({count}) =>{\n' +
                            '    return(\n' +
                            '        <div>\n' +
                            '            {new Date().toString()}\n' +
                            '            <br/>\n' +
                            '            {count}\n' +
                            '        </div>\n' +
                            '    )\n' +
                            '}\n' +
                            '\n' +
                            'const Parent = ({children}) =>{\n' +
                            '\n' +
                            '    let [count, setCount] = useState(1);\n' +
                            '\n' +
                            '    const onClick = ()=>{\n' +
                            '        setCount(count+1);\n' +
                            '    }\n' +
                            '\n' +
                            '    return (\n' +
                            '        <>\n' +
                            '            <button onClick={onClick}>Apasa</button>\n' +
                            '            <div>S-a apasat de: {count} ori</div>\n' +
                            '\n' +
                            '            {React.cloneElement(children, { count: count })}\n' +
                            '\n' +
                            '        </>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <Parent3>
                        <Child3 count={3}/>
                    </Parent3>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UpdateChildReactContent;