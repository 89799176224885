import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class RemoteRepositoryGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-remote-repository", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Remote repository</b>
                    <br/>
                    <br/>

                    Git poate fi folosit pe masina locala / calculatorul personal pentru gestionarea versiunilor.
                    <br/>
                    Dar, Git, fiind un sistem de control a versiunilor distribuit permite sa exista mai multe copii a aceluiasi repository.
                    <br/>
                    De aceea, se obisnuiste sa se pastreze o copie pe un server (in cloud) - locatie centalizata. Astfel, acest repository va putea fi accesat si de alti programatori sau de pe alt calculator.
                    <br/>
                    Orice copie a acestui repository poate fi legat de repository-ul din cloud, numit <b>remote</b>. Acest lucru, activeaza sicronizarea intre repository-uri.
                    <br/>

                    <br/>
                    Sa incercam sa punem proiectul nostru pe Bitbucket (presupunem ca avem cont pe Bitbucket).

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/git/git-1.png'}/>
                    </div>

                    Apasand pe butonul <i>Create</i> se alege <i>Repository</i>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/git/git-2.png'}/>
                    </div>
                    Sa apasa pe butonul <i>Create repository</i>

                    Vom adauga un repository <b>remote</b> numit <i>origin</i> care va fi asociat cu url-ul de pe Bitbucket <i>https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git</i>.
                    <br/>
                    Vom folosi comanda: <b>git remote add <i>nume_remote</i> <i>url</i></b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add origin https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git'}
                    </SyntaxHighlighter>

                    Apoi putem trimite comiturile locale catre repository-ul <b>remote</b> folosind comanda: <b>git push</b> cu optiunea <b>-u</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push -u origin master'}
                    </SyntaxHighlighter>

                    Comanda  <b>git push -u</b> este echivalentă cu <b>-set-upstream</b>; indicatorul -u  este folosit pentru a seta repository-ul remote;

                    <br/>
                    <br/>

                    Daca se obtine eroare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'To https://bitbucket.org/letyournailsgrow/git-vanilla.git\n' +
                        ' ! [rejected]        master -> master (non-fast-forward)\n' +
                        'error: failed to push some refs to \'https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git\'\n' +
                        'hint: Updates were rejected because the tip of your current branch is behind\n' +
                        'hint: its remote counterpart. Integrate the remote changes (e.g.\n' +
                        'hint: \'git pull ...\') before pushing again.\n' +
                        'hint: See the \'Note about fast-forwards\' in \'git push --help\' for details.'}
                    </SyntaxHighlighter>
                    Se poate forta <b>git push</b> cu optiunea <b>-f</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push -f origin master'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>

                    Optiunea <b>-f</b>: forteaza copierea/sincronizarea repository-ului remote cu repository-ul local (cel local este referinta); trebuie rulat cu <b>atentie</b>;
                    <br/>
                    Uneori, Git poate refuza să împingă (sa faca push) modificările efectuate local folosind comanda <b>git push</b>;
                acest lucru se poate intampla din cauza unor conflicte (de exemplu:
                s-a adăugat un fișier nou sau s-au făcut unele modificări în depozitul/repository-ul remote,
                în timp ce repositor-ul local este în urma - nu are ultimele modificari;
                in acest caz, dacă se face push cu noi modificări locale prin simpla comandă push, se va genera o eroare;

                Folosind <b>-f</b>, Git va actualiza repository-ul remote cu ce gaseste in repository-ul local.
                    <br/>

                    <b>Dar</b>, munca depusă de alți colegi de echipă poate fi pierdută.
                Deci, trebuie să <b>atentie</b> și trebuie mai ales sa se faca in cunostiinta de cauza!
<br/>
                    <br/>

                    Parametrii comenzii <b>git push</b>:
                    <ul>
                        <li>origin: numele repository-ului remote (definit locat)</li>
                        <li>master: numele branch-ului (local) (pe care vrem sa il sincronizam cu repozisoty-ul remote, definit prin <i>origin</i></li>
                    </ul>

                    <hr/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push  <REMOTENAME> <BRANCHNAME>'}
                    </SyntaxHighlighter>

                    Pentru a vizualiza repository-urile remote: <b>git remote -v</b>.
                    <br/>
                    Rezultatul poate arata asa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git (fetch)\n' +
                        'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/git-vanilla.git (push)'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                        <li>
                            <div>

                                <a href={"https://www.jquery-az.com/git-push-command/"}>The Purpose of Git Push command</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RemoteRepositoryGitContent;