import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class LedRGBArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-led-rgb", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LED RGB(Light Emitted Diode / diodă emițătoare de lumină)</b>
                    <br/>
                    <br/>

                    Daca la un LED cu 2 picioruse, avem:
                    <ul>
                        <li>unul mai scurt (catod) - care se conecteaza la pinul GND al placii Arduino</li>
                        <li>unul mai lung (anodul) - care se conecteaza la unul dintre pinii digitali al placii Ardunino</li>
                    </ul>

                    Un LED RGB are 4 picioruse:
                    <ul>
                        <li>3 mai scurti (anodul) - care se conecteaza la unul dintre pinii digitali al placii Ardunino cate un pic pentru rosu, verde, albastru</li>
                        <li>unul mai lung (catod) - care se conecteaza la pinul GND al placii Arduino</li>
                    </ul>


                    Se va aprinde LED-ul RBG, in coloare rosie:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_6.png'}/>
                    </div>

                    Se va aprinde LED-ul RBG, in coloare albastra:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_7.png'}/>
                    </div>

                    Se va aprinde LED-ul RBG, in coloare verde:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_8.png'}/>
                    </div>

                    Program prin care se va aprinde LED-ul RGB, aternand culorile:
                    <SyntaxHighlighter>
                        {'#define PIN_ROSU 11\n' +
                            '#define PIN_ALBASTRU 5\n' +
                            '#define PIN_VERDE 3\n' +
                            '\n' +
                            'void setup() {\n' +
                            ' pinMode(PIN_ROSU, OUTPUT); // rosu\n' +
                            ' pinMode(PIN_ALBASTRU, OUTPUT); // albastru\n' +
                            ' pinMode(PIN_VERDE, OUTPUT); // verde\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  digitalWrite(PIN_VERDE,LOW);\n' +
                            '  digitalWrite(PIN_ROSU,HIGH);\n' +
                            '  delay(1000);\n' +
                            '\n' +
                            '  digitalWrite(PIN_ROSU,LOW);\n' +
                            '  digitalWrite(PIN_ALBASTRU,HIGH);\n' +
                            '  delay(1000);\n' +
                            '\n' +
                            '  digitalWrite(PIN_ALBASTRU,LOW);\n' +
                            '  digitalWrite(PIN_VERDE,HIGH);\n' +
                            '  delay(1000);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_9.png'}/>
                    </div>

                    Program pentru a afisa o combinatie de cele 3 culori (va da un soi de alb, mai degraba un soi de albastru-rozaliu spre alb):
                    <SyntaxHighlighter>
                        {'#define PIN_ROSU 11\n' +
                            '#define PIN_ALBASTRU 5\n' +
                            '#define PIN_VERDE 3\n' +
                            '\n' +
                            'void setup() {\n' +
                            ' pinMode(PIN_ROSU, OUTPUT); // rosu\n' +
                            ' pinMode(PIN_ALBASTRU, OUTPUT); // albastru\n' +
                            ' pinMode(PIN_VERDE, OUTPUT); // verde\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  digitalWrite(PIN_VERDE,HIGH);\n' +
                            '  digitalWrite(PIN_ROSU,HIGH);\n' +
                            '  digitalWrite(PIN_VERDE,HIGH);\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>

                    Cu pin care au capacitate PWN, se pot combina cele 3 culori:
                    <SyntaxHighlighter>
                        {'#define PIN_ROSU 11\n' +
                            '#define PIN_ALBASTRU 3\n' +
                            '#define PIN_VERDE 5\n' +
                            '\n' +
                            'void setup() {\n' +
                            ' pinMode(PIN_ROSU, OUTPUT); // rosu\n' +
                            ' pinMode(PIN_ALBASTRU, OUTPUT); // albastru\n' +
                            ' pinMode(PIN_VERDE, OUTPUT); // verde\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  analogWrite(PIN_ALBASTRU, 25);\n' +
                            '  analogWrite(PIN_ROSU, 25);\n' +
                            '  analogWrite(PIN_VERDE, 125);\n' +
                            '  \n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       */}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LedRGBArduinoContent;