import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FunctionsGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-functions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Functii</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'package main\n\nimport "fmt"\n\nfunc main() {\n	name := getName()\n	fmt.Println(name)\n}\n\nfunc getName() string {\n	return "kj"\n}'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Fișierele din același pachet pot apela funcții definite în alte fișiere (fara import)
                        </li>
                    </ul>

                    se pot returna mai multe valori:
                    <SyntaxHighlighter>
                        {'funct functie() (int, int ){\n' +
                            '  return 1,2' +
                            '}'}
                    </SyntaxHighlighter>

                    mod de folosire:
                    <SyntaxHighlighter>
                        {'a, b := functie()\n	fmt.Println(a, b)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Functie recever</b>
                    <br/>
                    <br/>

                    O functie atasata la un tip este <b>receiver</b>:
                    <SyntaxHighlighter>
                        {'func (nume_variabila tip) nume_functie(){..})'}
                    </SyntaxHighlighter>
                    Orice variabila de tipul <i>tip</i> are acces la <i>nume_functie</i>!
                    <br/>
                    Variabila <i>nume_variabila</i> este o copie a variabilei/ o referinta (sa i zicem <i>v</i>) care "apeleaza" functia <i>nume_functie</i>
                    <SyntaxHighlighter>
                        {'v.nume_functie()'}
                    </SyntaxHighlighter>
                    Deci <i>nume_variabila</i> = <i>v</i>.
                    <br/>
                    In alte limbaje, <i>nume_variabila</i> ar fi: this sau self! (dar prin conventie, nu se folosesc)

                    <br/>
                    <br/>
                    Daca <i>nume_variabila</i> nu este folosita in functia atasata se poate scrie:

                    <SyntaxHighlighter>
                        {'func (tip) nume_functie(){..})'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionsGoContent;