import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class SSLCertificatesNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-ssl-certificates", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Certificate SSL</b>
                    <br/>
                    <br/>

                    <b>Let's Encrypt</b> este o autoritate de certificare (CA) care oferă o modalitate accesibilă de a obține și de a <b>instala certificate TLS/SSL gratuite</b>,
                    permițând astfel HTTPS criptat pe serverele web.

                    <br/>
                    <br/>
                    <b>Let's Encrypt</b> simplifică procesul prin furnizarea unui client software, <b>Certbot</b>, care încearcă să automatizeze majoritatea (dacă nu toți) pașii necesari.
                    În prezent, întregul proces de obținere și instalare a unui certificat este complet automatizat atât pe Apache, cât și pe Nginx.
                    <br/>
                    <br/>
                    Vom folosi <b>Certbot</b> pentru a:
                    <ul>
                        <li><b>obține un certificat SSL gratuit</b> pentru <b>Nginx</b> pe Ubuntu <b>22.04</b></li>
                        <li><b>configura certificatul pentru reînnoirea automată</b></li>
                    </ul>

                    Se va folosi un fișier separat de configurare a serverului Nginx în loc de fișierul implicit.
                    <br/>
                    Se recomanda crearea de blocuri de tip <b>server</b> Nginx pentru fiecare domeniu, deoarece ajută la evitarea greșelilor comune și pentru a menține fișierele implicite ca o configurație de rezervă.

                    <hr/>
                    <b>Prerechizite</b>
                    <ul>
                        <li>Ubuntu 22.04</li>
                        <li>Un nume de domeniu înregistrat (acest tutorial va folosi example.com pe tot parcursul)
                        <br/>
                            Puteți achiziționa un nume de domeniu de la:
                            <ul>
                                <li>
                                    <a href={"https://www.namecheap.com/"}>Namecheap</a>
                                </li>
                                <li>
                                    gratuit: <a href={"https://www.freenom.com/en/index.html?lang=en"}>Freenom</a>
                                </li>
                                <li>
                                    alt registrator de domenii
                                </li>
                            </ul>
                        </li>
                        <li>
                            facute urmatoarele 2 setari legate de <b>DNS records</b>:
                            <ul>
                                <li>O inregistrare <b>A</b> cu <i>example.com</i> care sa pointeze catre adresa IP publica a serverului</li>
                                <li>O inregistrare <b>A</b> cu <i>www.example.com</i> care sa pointeze catre adresa IP publica a serverului</li>
                            </ul>
                        </li>
                        <li>
                            Nginx
                        </li>
                    </ul>
                    <hr/>
                    <b>1.1. Instalare CertBot</b>
                    <br/>
                    <br/>

                    <b>Pas 1: instalare snap / aducere la zi</b>
                    <br/>
                    <br/>
                    Certbot recomanda folosirea packetului <b>snap</b> pentru instalare. Ubuntu 22.04 vine cu suport <i>snap</i>.
                    <br/>
                    Pentru a verifica ca <i>snap</i> este la zi cu actualizarile:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo snap install core; sudo snap refresh core'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'core 16-2.57.1 from Canonical✓ installed\n' +
                            'snap "core" has no updates available'}
                    </SyntaxHighlighter>

                    <b>Pas 2: stergere versiune veche de certbot</b>
                    <br/>
                    <br/>

                    Stergere versiune veche de certbot daca exista:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt remove certbot'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'sudo apt remove certbot\n' +
                            'Reading package lists... Done\n' +
                            'Building dependency tree... Done\n' +
                            'Reading state information... Done\n' +
                            'Package \'certbot\' is not installed, so not removed\n' +
                            '0 upgraded, 0 newly installed, 0 to remove and 68 not upgraded.'}
                    </SyntaxHighlighter>

                    <b>Pas 3: instalare certbot</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo snap install --classic certbot'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'certbot 1.30.0 from Certbot Project (certbot-eff✓) installed'}
                    </SyntaxHighlighter>

                    <b>Pas 5: pregatire comanda certbot</b>
                    <br/>
                    <br/>

                    Executați următoarea instrucțiune pentru a vă asigura că comanda certbot poate fi executată.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo ln -s /snap/bin/certbot /usr/bin/certbot'}
                    </SyntaxHighlighter>


                    <b>Pas 5: certbot --help</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'certbot --help'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>1.2. Configurarea cu Nginx</b>
                    <br/>
                    <br/>

                    <b>1.2.1 Preconditii</b>
                    <br/>
                    <br/>

                    Certbot trebuie să poată găsi blocul <b>server</b> blocul corect în configurația Nginx (<b>nginx.conf</b>) pentru a putea configura automat SSL.
                    Va cauta directiva <b>server_name</b> care se potrivește cu domeniul pentru care se solicita certificat.

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter>
                        {'...\n' +
                            'server_name example.com www.example.com;\n' +
                            '...'}
                    </SyntaxHighlighter>

                    Dupa ce se editeaza fisierul de configurare <b>nginx.conf</b>, e bine sa se verifice sintaxa:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {
                            'sudo nginx -t'
                        }
                    </SyntaxHighlighter>
                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl reload nginx'}
                    </SyntaxHighlighter>

                    <b>1.2.2 Permiterea HTTPS prin firewall</b>
                    <br/>
                    <br/>

                    Dacă aveți firewall activat <b>ufw</b>, așa cum este recomandat, va trebui să ajustați setările pentru a permite traficul HTTPS. <br/>
                    Din fericire, Nginx înregistrează câteva profiluri ufw la instalare.

                    Puteți vedea setarea curentă tastând:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo ufw status'}
                    </SyntaxHighlighter>
                    Ar putea afisa:
                    <SyntaxHighlighter>
                        {'Status: inactive'}
                    </SyntaxHighlighter>
                    Ceee ce inseamna ca nu este activat firewall-ul.

                    <br/>
                    <br/>

                    Pentru lista aplicatiile configurate <b>ufw</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo ufw app list'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'Available applications:\n' +
                            '  CUPS\n' +
                            '  OpenSSH'}
                    </SyntaxHighlighter>

                    Exista trei profiluri disponibile pentru Nginx:

                    <ul>
                        <li> Nginx Full : Acest profil deschide atât portul 80 (trafic web normal, necriptat) cât și portul 443 (trafic criptat TLS/SSL)</li>
                        <li> Nginx HTTP : Acest profil deschide doar portul 80 (trafic web normal, necriptat)</li>
                        <li> Nginx HTTPS : Acest profil deschide doar portul 443 (trafic criptat TLS/SSL)</li>
                    </ul>

                    Dar, daca firewall-ul este activat, pentru a permite în plus traficul HTTPS, permiteți profilul complet Nginx și ștergeți permisiunea redundantă pentru profilul HTTP Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo ufw allow \'Nginx Full\'\n' +
                            'sudo ufw delete allow \'Nginx HTTP\''}
                    </SyntaxHighlighter>

                    <b>1.2.2 Obtinere un certificat</b>
                    <br/>
                    <br/>
                    Moduri de a obtine un certificat:
                    <ul>
                        <li>
                            pentru a obtine un certificat si Certbot sa editeze automat configuratia de nginx:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'sudo certbot --nginx'}
                            </SyntaxHighlighter>
                            aceasta ar putea sa nu fie o idee buna, daca site-ul exista si sunt multe configurari facute in <b>nginx.config</b>
                        </li>
                        <li>pentru a obtine un certificat si apoi modificările in configurația nginx sa se faca manual (cu <b>certonly</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'sudo certbot certonly --nginx'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Exemplu (fara <b>certonly</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo certbot --nginx -d example.com -d www.example.com'}
                    </SyntaxHighlighter>

                    Exemplu (cu  <b>certonly</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo certbot certonly --nginx -d example.com -d www.example.com'}
                    </SyntaxHighlighter>

                    Acesta comanda rulează certbot cu pluginul <b>--nginx</b>, folosind <b>-d</b> pentru a specifica numele de domenii pentru care dorim ca certificatul să fie valabil.
                    <br/>
                    Când rulați comanda, vi se va solicita să introduceți o adresă de e-mail și să acceptați termenii și condițiile.
                    După ce faceți acest lucru, ar trebui să vedeți un mesaj care vă spune că procesul a avut succes și unde sunt stocate certificatele.

                    <br/>
                    <br/>

                    Certbot se folosete de configurarea Nginx (<b>nginx.conf</b>). Cauta blocul <b>server</b> pentru a putea configura automat SSL.
                    Va cauta directiva <b>server_name</b> care se potrivește cu domeniul pentru care se solicita certificat.

                    <br/>
                    <br/>

                    <b>Exemplu</b> (fie urmatoare configurare <b>nginx.conf</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            ' \n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 80;\n' +
                            '\t\n' +
                            '    server_name dopamina.ro;\n' +
                            '\n' +
                            '    location / {\n' +
                            '\t\treturn 200 "heeloo";\n' +
                            '\t}\n' +
                            '\n' +
                            '  }\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>
                    Daca s-a modificat ceva:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl reload nginx'}
                    </SyntaxHighlighter>

                    Se poate scrie doar (pentru ca Certbot se folosete de configurarea Nginx (<b>nginx.conf</b>), cauta blocul <b>server</b>, directiva <b>server_name</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo certbot --nginx'}
                    </SyntaxHighlighter>

                    Certificatele dvs. sunt descărcate, instalate și încărcate, iar configurația dvs. Nginx va redirecționa automat toate solicitările web către https:// (daca se specifica acest lucru la rularea certbot).
                    <br/>

                    Certificatele sunt puse in:
                    <ul>
                        <li>/etc/letsencrypt/life/dopamin.ro</li>
                    </ul>

                    Daca s-a ales generarea certificatelor cu autoconfigurarea <b>nginx.conf</b>, atunci acesta va arata astfel ( se adauga 5 linii cu prefixul: #manged by Cerbot):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            ' \n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 80;\n' +
                            '\t\n' +
                            '    server_name dopamina.ro;\n' +
                            '\n' +
                            '    location / {\n' +
                            '\t\treturn 200 "heeloo";\n' +
                            '\t}\n' +
                            '\n' +
                            '\tlisten 443 ssl; #manged by Cerbot\n' +
                            '\tssl_certificate /etc/letsencrypt/life/dopamina.ro/fullchain.pem; #manged by Cerbot\n' +
                            '\tssl_certificate_key /etc/letsencrypt/life/dopamina.ro/private.pem; #manged by Cerbot\n' +
                            '\tinclude /etc/letsencrypt/options-ssl-nging.conf; #manged by Cerbot\n' +
                            '\tssl_dhparam /etc/letsencrypt/ssl-dhparams.pem; #manged by Cerbot\n' +
                            '  }\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>

                    Încercați să vă reîncărcați site-ul și observați indicatorul de securitate al browserului.
                    Ar trebui să indice că site-ul este securizat corespunzător, de obicei cu o pictogramă de lacăt.

                    <br/>
                    Dacă vă testați serverul utilizând testul <a href={"https://www.ssllabs.com/ssltest/"}>SSL Labs Server</a>, acesta va primi nota A.

                    <hr/>

                    <b>1.3. Reînnoire automată certificat</b>
                    <br/>
                    <br/>

                    Certificatele Let's Encrypt sunt valabile doar <b>90</b> de zile. <br/>
                    Acest lucru este pentru a încuraja utilizatorii să-și automatizeze procesul de reînnoire a certificatelor.
                    <br/>
                    Certbot vine cu un cron job care vă va reînnoi automat certificatele înainte ca acestea să expire.
                    <br/>
                    Nu va trebui să rulați din nou Certbot, decât dacă vă schimbați configurația.
                    <br/>
                    <br/>
                    Pachetul Certbot, cu cron job-ul cu care vine, va rula de două ori pe zi și va reînnoi automat orice certificat care se află în termen de <b>30</b> de zile de la expirare.

                    <br/>
                    <br/>

                    Pentru a verifica starea legata de expirarea certificatelor:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl status snap.certbot.renew.service'}
                    </SyntaxHighlighter>

                    Poate afisa:
                    <SyntaxHighlighter>
                        {' Loaded: loaded (/etc/systemd/system/snap.certbot.renew.service; static)\n' +
                            '     Active: inactive (dead)\n' +
                            'TriggeredBy: ● snap.certbot.renew.timer\n'}
                    </SyntaxHighlighter>

                    Puteți testa reînnoirea automată a certificatelor rulând această comandă:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo certbot renew --dry-run'}
                    </SyntaxHighlighter>

                    Comanda de reînnoire a certbot este instalată într-una dintre următoarele locații:

                    <ul>
                        <li>/etc/crontab/</li>
                        <li>/etc/cron.*/*</li>
                        <li>systemctl list-timers</li>
                    </ul>


                    Dacă procesul de reînnoire automată eșuează vreodată, Let's Encrypt va trimite un mesaj la e-mailul pe care l-ați specificat, avertizându-vă când certificatul este pe cale să expire.

                    <br/>
                    <br/>

                    <hr/>

                    <b>Extra: lucrul cu crontab</b>

                    <br/>
                    <br/>

                    Listare crontab:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'crontab -l'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'no crontab for kj'}
                    </SyntaxHighlighter>

                    Se poate edita <b>crontab</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'crontab - e'}
                    </SyntaxHighlighter>
                    poatem edita cu <b>nano</b>:
                    <SyntaxHighlighter>
                        {'@dauky cerbot renew'}
                    </SyntaxHighlighter>

                    <hr/>
                    Link-uri:
                    <ul>
                        <li> <a target={"_blank"} href={"https://certbot.eff.org/"}>https://certbot.eff.org/</a></li>
                        <li> <a target={"_blank"} href={"https://certbot.eff.org/instructions?ws=nginx&os=ubuntufocal"}>Nginx on Ubuntu 20</a></li>
                        <li> <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-secure-nginx-with-let-s-encrypt-on-ubuntu-22-04"}>
                            https://www.digitalocean.com/community/tutorials/how-to-secure-nginx-with-let-s-encrypt-on-ubuntu-22-04
                        </a>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SSLCertificatesNginxContent;