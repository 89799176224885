import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class SeederLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-seeder", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Generarea date fake</b>
                    <br/>
                    <br/>

                    Generare factory:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:factory CategoryFactory'}
                    </SyntaxHighlighter>

                    Generare seeder:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:seeder CategorySeeder'}
                    </SyntaxHighlighter>

                    Populare baza de date cu date fake:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan db:seed --class=CategorySeeder'}
                    </SyntaxHighlighter>

                    <hr/>

                    Se va genera clasa CategoryFactory, si vom completa metoda definition(), pentru a defini cum sa se genereze datele fake:
                    <SyntaxHighlighter  showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace Database\\Factories;\n' +
                            '\n' +
                            'use App\\Models\\Category;\n' +
                            'use Illuminate\\Database\\Eloquent\\Factories\\Factory;\n' +
                            '\n' +
                            'class CategoryFactory extends Factory\n' +
                            '{\n' +
                            '\n' +
                            '    protected $model = Category::class;\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Define the model\'s default state.\n' +
                            '     *\n' +
                            '     * @return array\n' +
                            '     */\n' +
                            '\n' +
                            '    public function definition()\n' +
                            '    {\n' +
                            '        return [\n' +
                            '            \'name\' => $this->faker->text(30)\n' +
                            '        ];\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    Se va genera clasa CategorySeeder:

                    <SyntaxHighlighter  showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace Database\\Seeders;\n' +
                            '\n' +
                            'use App\\Models\\Category;\n' +
                            'use Illuminate\\Database\\Seeder;\n' +
                            '\n' +
                            'class CategorySeeder extends Seeder\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Run the database seeds.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function run()\n' +
                            '    {\n' +
                            '        Category::factory(30)->create(); // se creeza 30 de inregistri in tabela modului Category\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SeederLaravelContent;