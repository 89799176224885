import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class HttpAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-http", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Http: declararea</b>
                    <br/>
                    <br/>

                    Import pentru a folosi modul <b>HttpClientModule</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {HttpClientModule} from \'@angular/common/http\' '}
                    </SyntaxHighlighter>

                    In modul, se importa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'imports: [HttpClientModule]'}
                    </SyntaxHighlighter>

                    In componenta TS se poate injecta in constructor, un obiect de tip <b>HttpClient</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'constructor(protected http: HttpClient}'}
                    </SyntaxHighlighter>
                    cu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { HttpClient } from \'@angular/common/http\';'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Http: Post</b>
                    <br/>
                    <br/>

                    POST:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.post(url, postDataInJson)'}
                    </SyntaxHighlighter>
                    (daca nimeni nu interesat de raspuns, request-ul/cererea nu se trimite;
                    <br/>
                    deci instructiunea de mai sunt nu va face POST efectiv;
                    instructiunea de mai sus, va returna un <b>Observable</b>;
                    <br/>
                    asa ca trebuie sa existe un <i>subscribe</i> ca sa se trimita cererea);
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.post(url, postDataInJson)\n' +
                            '  .subscribe( responseData => {\n' +
                            '     console.log(responseData);\n' +
                            '   })'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Http: Get</b>
                    <br/>
                    <br/>
                    GET:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.get(url)\n' +
                            '  .subscribe( responseData => {\n' +
                            '     console.log(responseData);\n' +
                            '   })'}
                    </SyntaxHighlighter>
                    (la fel, no woman no cry,..., scuze, no subscribe no request)

                    <hr/>
                    <b>3. Http: Transformarea datelor</b>
                    <br/>
                    <br/>
                    Transformarea datelor (cu <b>pipe()</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '   .get(url)\n' +
                            '   .pipe(map(responseData => {\n' +
                            '       const dataArray = [];\n' +
                            '       for(const key in responseData) {\n' +
                            '          if (responseData.hasOwnProperty(ley)){\n' +
                            '             dataArray.push({...responseData[key], id: key})\n' +
                            '          }\n' +
                            '       }\n' +
                            '       return dataArray;\n' +
                            '    }))\n'+
                            '   .subscribe( responseData => {\n' +
                            '      console.log(responseData);\n' +
                            '   })'}
                    </SyntaxHighlighter>

                    <hr/>
                    Tipizare raspuns:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '   .get<{ [key: string]: KjModel}>(url) // <--AICI\n' +
                            '   .pipe(map(responseData => {\n' +
                            '       const dataArray = [];\n' +
                            '       for(const key in responseData) {\n' +
                            '          if (responseData.hasOwnProperty(ley)){\n' +
                            '             dataArray.push({...responseData[key], id: key})\n' +
                            '          }\n' +
                            '       }\n' +
                            '       return dataArray;\n' +
                            '    }))\n'+
                            '   .subscribe( responseData => {\n' +
                            '      console.log(responseData);\n' +
                            '   })'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Http: Delete</b>
                    <br/>
                    <br/>
                    DELETE:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.delete(url)\n' +
                            '  .subscribe( ()  => {\n' +
                            '     console.log(responseData);\n' +
                            '   })'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Http: Gestionare erori</b>
                    <br/>
                    <br/>
                    Gestionare erori:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.get(url)\n' +
                            '  .subscribe( \n' +
                            '     responseData => {\n' +
                            '       console.log(responseData);\n' +
                            '     }, \n' +
                            '     error => {\n' +
                            '        console.log(error.message, error.error)\n' +
                            '     }\n' +
                            '   )'}
                    </SyntaxHighlighter>

                    Gestionare erori ( cu operatorul <b>rxjs/operators</b> <b>catchError</b> si <b>throwError</b> din <b>rx</b>):

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '   .get(url)\n' +
                            '   .pipe(\n' +
                            '      map(responseData => {\n' +
                            '         const dataArray = [];\n' +
                            '         for(const key in responseData) {\n' +
                            '           if (responseData.hasOwnProperty(ley)){\n' +
                            '              dataArray.push({...responseData[key], id: key})\n' +
                            '           }\n' +
                            '         }\n' +
                            '         return dataArray;\n' +
                            '      }),\n' +
                            '      catchError(responseError => { // <-- AICI \n' +
                            '           throwError(responseError); // <-- AICI import {throwError} from rx\n' +
                            '      })\n' +
                            '    )\n'+
                            '   .subscribe( responseData => {\n' +
                            '      console.log(responseData);\n' +
                            '   })'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Http: Setare headers</b>
                    <br/>
                    <br/>
                    Setare headers:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '  .get(url,\n' +
                            '   {\n' +
                            '       headers: new HttpHeaders({"Custom-Header":"Hau, bau!"})\n' +
                            '   }\n' +
                            ')'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Http: Parametri Query</b>
                    <br/>
                    <br/>
                    Parametri Query:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '  .get(url,\n' +
                            '   {\n' +
                            '       headers: new HttpHeaders({"Custom-Header":"Hau, bau!"}),\n' +
                            '       params: new HttpParams().set(\'print\',\'pretty\')\n' +
                            '   }\n' +
                            ')'}
                    </SyntaxHighlighter>

                    Alternativ se putea scrie: <i>url?print=pretty</i>
                    <br/>
                    Sau, se putea creea un obiect de tip <b>HttpParams</b> si apoi la acesta sa se adauge parametrii:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'let p = new HttpParams();\n' +
                            'p = p.append("print", "pretty");\n' +
                            'p = p.append("zeu", "kj");'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Http: Observarea tipurilor de raspuns</b>
                    <br/>
                    <br/>
                    Observarea tipurilor de raspuns:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '  .get(url,\n' +
                            '   {\n' +
                            '       observe:\'response\', // <!-- in mod implicit este body; response contine body (vezi mai jos)\n' +
                            '   }\n' +
                            ')'}
                    </SyntaxHighlighter>

                    si atunci:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.get(url, {observe:\'response\'})\n' +
                            '  .subscribe( \n' +
                            '     responseData => {\n' +
                            '       console.log(responseData.body); // <- AICI\n' +
                            '     }\n' +
                            '   )'}
                    </SyntaxHighlighter>

                    Observe: <b>events</b> si operator <b>rxjs/operators</b> : <b>tap</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http\n' +
                            '  .delete(url,\n' +
                            '   {\n' +
                            '       observe:\'events\',\n' +
                            '   }\n' +
                            ').pipe(\n' +
                            '  tap(event => {\n' +
                            '    console.log(event)\n' +
                            '    if (event.type === HttpEventType.Response){\n' +
                            '        console.log(event.body);\n' +
                            '    }\n' +
                            '  } \n' +
                            '))'}
                    </SyntaxHighlighter>
                    Se va observa, de exemplu ca se va loga: 0 (HttpEventType=0 {"->"} Sent) + Raspunsul (HttpEventType=4 {"->"} Response)
                    <br/>
                    Alte tipuri de evenimente:
                    <ul>
                        <li>
                            DownloadProgress
                        </li>
                        <li>
                            ResponseHeader
                        </li>
                        <li>
                            UploadProgress
                        </li>
                        <li>
                            User
                        </li>
                        <li>
                            Sent = 0
                        </li>
                        <li>
                            Response = 4
                        </li>
                    </ul>

                    <hr/>
                    <b>9. Http: Response Body Type</b>
                    <br/>
                    <br/>
                    Response Body Type (in mod implicit, este <b>json</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.http.get(url, {responseType:\'json\'}) //<-- poate fi blob sau text'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Interceptori</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {HttpInterceptor, HttpRequest, HttpHandler} from \'@angular/common/http\'; \n' +
                            '\n' +
                            'export class AuthInterceptorService implements HttpInterceptor{\n' +
                            '\n' +
                            '\tintercept(req: HttpRequest<any>, next: HttpHandler){\n' +
                            '\t\n' +
                            '\t\tconsole.log("Requestul basjbaie...");\n' +
                            '\t\t\n' +
                            '\t\treturn next.handle(next); // lasa request-ul sa continue nestingherit\n' +
                            '\t\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Declararea in modul:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'providers: [\n' +
                            '\t{\n' +
                            '\t\tprovide: HTTP_INTERCEPTORS, \n' +
                            '\t\tuseClass: AuthInterceptorService,\n' +
                            '\t\tmulti: true\t\n' +
                            '\t}\n' +
                            ']'}
                    </SyntaxHighlighter>
                    cu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {HTTP_INTERCEPTORS} from \'@angular/common/http\';'}
                    </SyntaxHighlighter>

                    Pentru a modifica request-ul se face o clona a acestuia cu <b>clone</b> si apoi se pot modifica,
                    de exemplu <i>url</i>, <i>params</i>, <i>headers</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {HttpInterceptor, HttpRequest, HttpHandler} from \'@angular/common/http\'; \n' +
                            '\n' +
                            'export class AuthInterceptorService implements HttpInterceptor{\n' +
                            '\n' +
                            '\tintercept(req: HttpRequest<any>, next: HttpHandler){\n' +
                            '\t\n' +
                            '\t\tconsole.log("Requestul basjbaie...");\n' +
                            '\t\t\n' +
                            '\t\tconst modifiedRequest = req.clone({ // <- AICI\n' +
                            '\t\t\turl:\'http://alt-url\',\n' +
                            '\t\t\theaders: req.headers.append("Auth","abc"),\n' +
                            '\t\t\tparams: req.params.append("key","valoare")\n' +
                            '\t\t});\n' +
                            '\t\t\n' +
                            '\t\treturn next.handle(modifiedRequest); \n' +
                            '\t\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a modifica response-ul (folosind <b>pipe()</b>, cu <b>tap</b> ori <b>map</b>):

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'export class AuthInterceptorService implements HttpInterceptor{\n' +
                            '\n' +
                            '\tintercept(req: HttpRequest<any>, next: HttpHandler){\n' +
                            '\t\n' +
                            '\t\tconsole.log("Requestul basjbaie...");\n' +
                            '\t\t\n' +
                            '\t\tconst modifiedRequest = req.clone({\n' +
                            '\t\t\turl:\'http://alt-url\',\n' +
                            '\t\t\theaders: req.headers.append("Auth","abc"),\n' +
                            '\t\t\tparams: req.params.append("key","valoare")\n' +
                            '\t\t});\n' +
                            '\t\t\n' +
                            '\t\treturn next\n' +
                            '\t\t\t.handle(modifiedRequest)\n' +
                            '\t\t\t.pipe(\n' +
                            '\t\t\t\ttap(event=>{\n' +
                            '\t\t\t\t\tconsole.log(event);\n' +
                            '\t\t\t\t\tif (event === HttpEventType.Response){\n' +
                            '\t\t\t\t\t\tconsole.log(response.body);\n' +
                            '\t\t\t\t\t}\n' +
                            '\t\t\t\t})\n' +
                            '\t\t\t);\n' +
                            '\t\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pot exista mai multi interceptori si se executa in ordinea in care sunt declaratii in lista din <b>.module.ts</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'providers: [\n' +
                            '\t{\n' +
                            '\t\tprovide: HTTP_INTERCEPTORS, \n' +
                            '\t\tuseClass: AuthInterceptorService,\n' +
                            '\t\tmulti: true\t\n' +
                            '\t},\n' +
                            '\t{\n' +
                            '\t\tprovide: HTTP_INTERCEPTORS, \n' +
                            '\t\tuseClass: LogInterceptorService,\n' +
                            '\t\tmulti: true\t\n' +
                            '\t}\n' +
                            ']'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HttpAngularContent;