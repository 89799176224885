import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class CatLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-cat", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa continutul unui fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cat <nume-fisier>'}
                    </SyntaxHighlighter>

                    <hr/>
                    De asemenea aceasta instructiune, daca nu ii se specifica nici un fisier, va citi de la tastatura input-ul.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cat > test.txt'}
                    </SyntaxHighlighter>
                    Se va citi de la tastatura pana se va apasa <b>CTRL+C</b>, iar rezultatul se va salva in fisierul <i>test.txt</i>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CatLinuxContent;