import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ShareLanguageKeyPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-share-language-key-portlet", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Vom creea un modul nou pe care il vom numi <b>i18n</b>.
                    <br/>
                    Apoi com creea urmatoare structura de directoare:
                    <br/>
                    <b>\src\main\resources\content</b>
                    <br/>
                    Aici vom creea doua fisiere:
                    <ul>
                        <li>Language.properties</li>
                        <li>Language_ro.properties</li>
                    </ul>

                    Continutul fisierului  <b>Language.properties</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="properties" style={androidstudio}>
                        {'theme.vanilla.author = KJ'}
                    </SyntaxHighlighter>

                    Continutul fisierului  <b>Language_ro.properties</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="properties" style={androidstudio}>
                        {'theme.vanilla.author = KJ'}
                    </SyntaxHighlighter>

                    In radacina modului, vom avea fisierul <b>bnd.bnd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'Bundle-Name: Common i18n\n' +
                        'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.common.i18n\n' +
                        'Bundle-Version: 1.0.0\n' +
                        '-sources: true\n'}
                    </SyntaxHighlighter>

                    Tot in radacina modului, vom creea fisierul <b>build.gradle</b> gol.

                    <hr/>

                    <b>Testare</b><br/>
                    Sa presupunem ca avem portletul <b>hello-world</b>. Vom adauga in fisierul <b>bnd.bnd</b> linia <b>5</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'Bundle-Name: hello-world\n' +
                        'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.apps.helloworld\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Export-Package: ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants\n' +
                        '-liferay-aggregate-resource-bundles: ro.letyournailsgrow.liferay.vanilla.common.i18n'}
                    </SyntaxHighlighter>

                    Apoi, de exemplu, in fisierul <b>view.jsp</b> putem adauga urmatoarea linie:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:message key="theme.vanilla.author"/>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>
                                Overriding Global Language Keys
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default ShareLanguageKeyPortletContent;