import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CustomLoginLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-custom-login", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    MyLoginMVCActionCommand:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.login.portlet;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.model.CompanyConstants;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.security.auth.session.AuthenticatedSessionManagerUtil;\n' +
                            'import com.liferay.portal.kernel.theme.ThemeDisplay;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import com.liferay.portal.kernel.util.PortalUtil;\n' +
                            'import com.liferay.portal.kernel.util.WebKeys;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.login.constants.IbridLoginPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.ActionRequest;\n' +
                            'import javax.portlet.ActionResponse;\n' +
                            'import javax.servlet.http.HttpServletRequest;\n' +
                            'import javax.servlet.http.HttpServletResponse;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + IbridLoginPortletKeys.IBRIDLOGIN,\n' +
                            '                "mvc.command.name=/login/login"\n' +
                            '        },\n' +
                            '        service = MVCActionCommand.class\n' +
                            ')\n' +
                            'public class MyLoginMVCActionCommand extends BaseMVCActionCommand {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doProcessAction(ActionRequest actionRequest,\n' +
                            '                                   ActionResponse actionResponse) throws Exception {\n' +
                            '\n' +
                            '        ThemeDisplay themeDisplay = (ThemeDisplay)actionRequest.getAttribute(\n' +
                            '                WebKeys.THEME_DISPLAY);\n' +
                            '\n' +
                            '        HttpServletRequest request = PortalUtil.getOriginalServletRequest(\n' +
                            '                PortalUtil.getHttpServletRequest(actionRequest));\n' +
                            '\n' +
                            '        HttpServletResponse response = PortalUtil.getHttpServletResponse(\n' +
                            '                actionResponse);\n' +
                            '\n' +
                            '        String login = ParamUtil.getString(actionRequest, "login");\n' +
                            '        String password = actionRequest.getParameter("password");\n' +
                            '        boolean rememberMe = ParamUtil.getBoolean(actionRequest, "rememberMe");\n' +
                            '        String authType = CompanyConstants.AUTH_TYPE_EA;\n' +
                            '\n' +
                            '        AuthenticatedSessionManagerUtil.login(\n' +
                            '                request, response, login, password, rememberMe, authType);\n' +
                            '\n' +
                            '        actionResponse.sendRedirect(themeDisplay.getPathMain());\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ page import="com.liferay.portal.kernel.util.HtmlUtil" %>\n' +
                            '<%@ page import="com.liferay.portal.kernel.util.ParamUtil" %>\n' +
                            '<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<p>\n' +
                            '\t<b><liferay-ui:message key="myloginportlet_MyLogin.caption"/></b>\n' +
                            '</p>\n' +
                            '\n' +
                            '<c:choose>\n' +
                            '\t<c:when test="<%= themeDisplay.isSignedIn() %>">\n' +
                            '\n' +
                            '\t\t<%\n' +
                            '\t\t\tString signedInAs = HtmlUtil.escape(user.getFullName());\n' +
                            '\n' +
                            '\t\t\tif (themeDisplay.isShowMyAccountIcon() && (themeDisplay.getURLMyAccount() != null)) {\n' +
                            '\t\t\t\tString myAccountURL = String.valueOf(themeDisplay.getURLMyAccount());\n' +
                            '\n' +
                            '\t\t\t\tsignedInAs = "<a class=\\"signed-in\\" href=\\"" + HtmlUtil.escape(myAccountURL) + "\\">" + signedInAs + "</a>";\n' +
                            '\t\t\t}\n' +
                            '\t\t%>\n' +
                            '\n' +
                            '\t\t<liferay-ui:message arguments="<%= signedInAs %>" key="you-are-signed-in-as-x" translateArguments="<%= false %>" />\n' +
                            '\t</c:when>\n' +
                            '\t<c:otherwise>\n' +
                            '\n' +
                            '\t\t<%\n' +
                            '\t\t\tString redirect = ParamUtil.getString(request, "redirect");\n' +
                            '\t\t%>\n' +
                            '\n' +
                            '\t\t<portlet:actionURL name="/login/login" var="loginURL">\n' +
                            '\t\t\t<portlet:param name="mvcRenderCommandName" value="/login/login" />\n' +
                            '\t\t</portlet:actionURL>\n' +
                            '\n' +
                            '\t\t<aui:form action="<%= loginURL %>" autocomplete=\'on\' cssClass="sign-in-form" method="post" name="loginForm">\n' +
                            '\n' +
                            '\t\t\t<aui:input name="saveLastPath" type="hidden" value="<%= false %>" />\n' +
                            '\t\t\t<aui:input name="redirect" type="hidden" value="<%= redirect %>" />\n' +
                            '\n' +
                            '\t\t\t<aui:input autoFocus="true" cssClass="clearable" label="email-address" name="login" showRequiredLabel="<%= false %>" type="text" value="">\n' +
                            '\t\t\t\t<aui:validator name="required" />\n' +
                            '\t\t\t</aui:input>\n' +
                            '\n' +
                            '\t\t\t<aui:input name="password" showRequiredLabel="<%= false %>" type="password">\n' +
                            '\t\t\t\t<aui:validator name="required" />\n' +
                            '\t\t\t</aui:input>\n' +
                            '\n' +
                            '\t\t\t<aui:button-row>\n' +
                            '\t\t\t\t<aui:button cssClass="btn-lg" type="submit" value="sign-in" />\n' +
                            '\t\t\t</aui:button-row>\n' +
                            '\n' +
                            '\t\t</aui:form>\n' +
                            '\t</c:otherwise>\n' +
                            '</c:choose>'}
                    </SyntaxHighlighter>

                </div>




                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default CustomLoginLiferayContent;