import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CliAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-cli", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. CLI</b>
                    <br/>
                    <br/>


                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install -g @angular/cli@latest'}
                    </SyntaxHighlighter>

                    Help:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng help'}
                    </SyntaxHighlighter>

                    Help pentru comanda de creare proiect (<b>ng new</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng new --help'}
                    </SyntaxHighlighter>

                    Creare proiect:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng new nume_proiect'}
                    </SyntaxHighlighter>
                    sau
                    <br/>
                    Creare proiect:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng n nume_proiect'}
                    </SyntaxHighlighter>

                    Pornire server:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve'}
                    </SyntaxHighlighter>

                    Help pentru comanda: ng serve:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve --help'}
                    </SyntaxHighlighter>

                    Pornire server, cu schimbare port:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve --port 8081'}
                    </SyntaxHighlighter>

                    Pornire server, in mod prod:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve --prod'}
                    </SyntaxHighlighter>

                    Pornire server, cu deschidere automata in browser:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve --open'}
                    </SyntaxHighlighter>

                    Help pentru comanda: ng generate
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng generate --help'}
                    </SyntaxHighlighter>

                    Se pot genera, printre multe altele:
                    <ul>
                        <li>component</li>
                        <li>directive</li>
                        <li>pipe</li>
                        <li>module</li>
                        <li>service</li>
                        <li>enum</li>
                        <li>interface</li>
                        <li>interceptor</li>
                        <li>class</li>
                        <li>guard</li>
                        <li>application</li>
                        <li>serviceWorker</li>
                        <li>webWorker</li>
                        <li>library</li>
                    </ul>

                    Verificare daca se urmaza practici recomandate, pentru calitatea codului:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng lint'}
                    </SyntaxHighlighter>

                    Build:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng build'}
                    </SyntaxHighlighter>

                    Build, optimizat pentru prod:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng build --prod'}
                    </SyntaxHighlighter>
                    (genereaza aplicatia in directorul <b>dist</b>)

                    <hr/>
                    Pentru a verifica dupa noi update-uri si pentru a analiza codul sursa ce trebuie actualizat:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng update'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng update @angular/core @angular/cli'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>ng add</b>
                    <br/>
                    <br/>

                    Adaugare Angular Material:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @angular/material'}
                    </SyntaxHighlighter>
                    Folosind comanda <b>ng add</b> se intaleaza pachetele prin intermediul <b>npm</b>, dar in plus:
                    <ul>
                        <li>se mai fac si alte configurari</li>
                        <li>se fac modificari in fisierele sursa (ex: index.html, pentru a adauga link-uri catre font-uri)</li>
                    </ul>

                    Se poate folosi <b>ng generate</b> (avand @angular/material) si pentru componente de tip <i>nav</i>, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng generate @angular/material:nav main-nav'}
                    </SyntaxHighlighter>

                    Adaugare Firebase:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @angular/fire'}
                    </SyntaxHighlighter>
                    Daca se va rula:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng deploy'}
                    </SyntaxHighlighter>
                    Se va face automat (dupa configurari) deploy la aplicatie pe server.

                    <hr/>
                    <b>2. Proiecte multiple</b>
                    <br/>
                    <br/>

                    Pot exista mai multe proiecte/aplicatii intr-un singur director. O noua aplicatie se poate genera cu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng generate application nume-proiect'}
                    </SyntaxHighlighter>

                    Avand mai multe proiete/aplicatii, pentru a porni unul dintre ele:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng serve --proiect=nume-proiect'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca exista un proiect, noul proiect se va genera in <b>projects/nume-nou-proiect</b>; o structura destul de urata.
                    <br/>
                    Dar pentru a creea o structura mai ok, se poate face in felul urmator:
                    <ul>
                        <li>
                            se creaza un proiect, fara aplicatie:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng new project-root --create-application=false'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se genereaza prima aplicatie/proiect:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng generate application backend'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se genereaza a doua aplicatie/proiect:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng generate application frontend'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Librari</b>
                    <br/>
                    <br/>

                    Pentru a genera o librarie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng generate library nume-librarie'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CliAngularContent;