import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class ProfilesMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-profiles", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Profile</b>:
                    <br/>
                    <br/>

                    Profile permit sa avem diferite proprietati pentru diferite sisteme.
                    <br/>
                    De exemmplu, pe mediul de test sunt anumite configurari, pe mediul de productie altele.

                    <br/>
                    <br/>
                    Profilele se definesc in <i>pom.xml</i> prin intermediul tag-ului <b>profiles</b>.
                    <br/>
                    Fiecare profil este declarat prin intermediul tag-ului <b>profile</b> si are un <b>id</b> unic.
                    <br/>
                    Pentru a folosi un anumit profil se foloseste in argumentele comenzii <i>mvn</i>, flag-ul <b>-P</b> urmat de numele profilului:
                    <br/>
                    <b>mvn -P<i>nume_profil</i></b>

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<project xmlns="http://maven.apache.org/POM/4.0.0"\n' +
                            '         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">\n' +
                            '    <modelVersion>4.0.0</modelVersion>\n' +
                            '\n' +
                            '    <groupId>ro.maven.learn</groupId>\n' +
                            '    <artifactId>maven-project-learn</artifactId>\n' +
                            '    <version>1.0-SNAPSHOT</version>\n' +
                            '\n' +
                            '    <properties>\n' +
                            '        <maven.compiler.source>11</maven.compiler.source>\n' +
                            '        <maven.compiler.target>11</maven.compiler.target>\n' +
                            '        <project.build.sourceEncoding>UTF-8</project.build.sourceEncoding>\n' +
                            '    </properties>\n' +
                            '\n' +
                            '    <build>\n' +
                            '        <directory>D:\\work\\maven-project-learn\\test</directory>\n' +
                            '    </build>\n' +
                            '\n' +
                            '    <!-- mvn -Pproduction package -->\n' +
                            '    <profiles>\n' +
                            '        <profile>\n' +
                            '            <id>production</id>\n' +
                            '            <build>\n' +
                            '                <directory>D:\\work\\maven-project-learn\\prod</directory>\n' +
                            '            </build>\n' +
                            '        </profile>\n' +
                            '    </profiles>\n' +
                            '\n' +
                            '</project>'}
                    </SyntaxHighlighter>

                    Daca vom executa urmatoarea comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn -Pproduction package'}
                    </SyntaxHighlighter>
                    Atunci rezultatul <i>building</i>-ului se va pune in directorul <i>D:\work\maven-project-learn\prod</i>

                    <hr/>
                    Un profil poate fi activat, folosind tag-ul <b>activation</b> pe baza:
                    <ul>
                        <li><b>os</b>: sistemului de operare</li>
                        <li><b>jdk</b>: versiunii de jdk</li>
                        <li><b>file</b>: pe baza unui fisier (daca exista)

                            <br/>
                            profilul se activeaza daca exista fisierul <i>conditionfile.xml</i> (poate fi si gol)

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<profile>\n' +
                                    '            <id>production</id>\n' +
                                    '            <activation>\n' +
                                    '                <file>\n' +
                                    '                    <exists>conditionfile.xml</exists>\n' +
                                    '                </file>\n' +
                                    '            </activation>\n' +
                                    '            <build>\n' +
                                    '                <directory>D:\\work\\maven-project-learn\\prod</directory>\n' +
                                    '            </build>\n' +
                                    '        </profile>'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>activeByDefault</b>: daca este activ implicit (si nu mai e nevoie de specficicare <b>-P</b>)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ProfilesMavenContent;