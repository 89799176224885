import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"java-spring-core", url:"#", title:"Introducere in Spring", subtitle:""},

        {id:"java-spring-ioc", url:"/java-spring/ioc", title:"Spring IoC", subtitle:"", parent:"java-spring-core"},

        {id:"java-spring-context", url:"/java-spring/context", title:"Spring Context", subtitle:"", parent:"java-spring-core"},
        {id:"java-spring-wiring-beans", url:"/java-spring/wiring-beans", title:"Interconectarea bean-urilor", subtitle:"", parent:"java-spring-core"},
        {id:"java-spring-stereotypes", url:"/java-spring/stereotypes", title:"Stereotipuri: @Component, @Repository, @Service", subtitle:"", parent:"java-spring-core"},
        {id:"java-spring-scopes", url:"/java-spring/scopes", title:"Scopuri: Singleton, Prototype", subtitle:"", parent:"java-spring-core"},
        {id:"java-spring-aop", url:"/java-spring/aop", title:"AOP", subtitle:"", parent:"java-spring-core"},

        {id:"java-spring-boot-root", url:"#", title:"Introducere in Spring Boot", subtitle:""},
        {id:"java-spring-boot", url:"/java-spring/spring-boot", title:"Spring Boot", subtitle:"", parent:"java-spring-boot-root"},
        {id:"java-spring-mvc", url:"/java-spring/spring-mvc", title:"Spring MVC", subtitle:"", parent:"java-spring-boot-root"},
        {id:"java-spring-web-scopes", url:"/java-spring/web-scopes", title:"Scopuri web: Request, Session, Application", subtitle:"", parent:"java-spring-boot-root"},

        {id:"java-spring-conditionalonproperty-annotation", url:"/java-spring/conditionalonproperty-annotation", title:"Adnotarea @ConditionalOnProperty", subtitle:"", parent:"java-spring-core"},

        {id:"java-spring-cloud", url:"#", title:"Microservicii", subtitle:""},
        {id:"java-spring-webclient", url:"/java-spring/webclient", title:"WebClient", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-open-feign", url:"/java-spring/open-feign", title:"Open Feign", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-eureka-server", url:"/java-spring/eureka-server", title:"Eureka Server", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-cloud-balancer", url:"/java-spring/cloud-balancer", title:"Spring Cloud Balancer", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-cloud-api-gateway", url:"/java-spring/cloud-api-gateway", title:"Spring Cloud API Gateway", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-resilience4j", url:"/java-spring/resilience4j", title:"Toleranta la erori: Resilience4j", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-sleuth-zipkin", url:"/java-spring/sleuth-zipkin", title:"Urmarirea distribuita: Sleuth si Zipkin", subtitle:"", parent:"java-spring-cloud"},
        {id:"java-spring-server-config", url:"/java-spring/server-config", title:"Spring Cloud Config Server", subtitle:"", parent:"java-spring-cloud"},

        {id:"java-spring-keycloak", url:"#", title:"Keycloak", subtitle:""},
        {id:"java-spring-keycloak-spring-security", url:"/java-spring/keycloak-spring-security", title:"Introducere in Spring Security", subtitle:"", parent:"java-spring-keycloak"},
        {id:"java-spring-keycloak-intro-spring-security", url:"/java-spring/keycloak-intro-spring-security", title:"Introducere in Keycloak", subtitle:"", parent:"java-spring-keycloak"},
        {id:"java-spring-keycloak-intro-2-spring-security", url:"/java-spring/keycloak-intro-2-spring-security", title:"Explorare Keycloak", subtitle:"", parent:"java-spring-keycloak"},
        {id:"java-spring-keycloak-spring-boot", url:"/java-spring/keycloak-spring-boot", title:"Keycloak cu Spring Boot", subtitle:"", parent:"java-spring-keycloak"},
    ]

    static indexUrl = "/java-spring/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere în Spring Boot
                </div>

                {TocHelper.display(IndexContent.content)}
            </div>
        );
    }
}

export default IndexContent;