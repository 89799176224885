import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

function InjectContent({children}){
    return(
        <>
            A
            {children}
            B
        </>
    )
}
class InjectContentAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-inject-content", IndexContent);
    }

    number = 7;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Referinta locala</b>
                    <br/>
                    <br/>

                    Se poate injecta contintu folosind tag-ul <b>ng-content</b>.
                    Sa presupunem ca avem componenta <i>text</i>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'A\n' +
                            '<ng-content></ng-content>\n' +
                            'B'}
                    </SyntaxHighlighter>
                    si scriem:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<test>X</test>'}
                    </SyntaxHighlighter>
                    se va afisa: AXB

                    <hr/>
                    <b>@ContentChild</b>
                    <br/>
                    <br/>

                    Acum, sa presupunem ca avem referinta urmatoare
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<test><div #refChild>xxx</div></test>'}
                    </SyntaxHighlighter>

                    Atunci pentru a ne referi in componenta <i>text</i> la un element din children, vom folosi <b>@ContentChild</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'  @ContentChild(\'refChild\')\n' +
                            '  refChild!: ElementRef;\n' +
                            '\n' +
                            '  ngOnInit(): void {\n' +
                            '    console.log("ngOnInit:",this.refChild); // nu afideaza nimic\n' +
                            '  }\n' +
                            '\n' +
                            '  ngAfterContentInit(): void {\n' +
                            '      console.log("ngAfterContentInit:",this.refChild.nativeElement.innerText);\n' +
                            '  }'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React se foloseste un props, numit <b>children()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function InjectContent({children}){\n' +
                            '    return(\n' +
                            '        <>\n' +
                            '            A\n' +
                            '            {children}\n' +
                            '            B\n' +
                            '        </>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <InjectContent>X</InjectContent>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InjectContentAngularContent;