import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class GeneratedCodeServiceJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-service-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: Servicii</b>
                    <br/>
                    <br/>

                    In directorul <b>src/main/java</b> pachetul <i>ro.ibrid.lapretbun.service</i> s-au generat service-urile Java:

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>ProductService.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.service;\n' +
                            '\n' +
                            'import java.util.Optional;\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.data.domain.Page;\n' +
                            'import org.springframework.data.domain.Pageable;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import org.springframework.transaction.annotation.Transactional;\n' +
                            'import ro.ibrid.lapretbun.domain.Product;\n' +
                            'import ro.ibrid.lapretbun.repository.ProductRepository;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Service Implementation for managing {@link Product}.\n' +
                            ' */\n' +
                            '@Service\n' +
                            '@Transactional\n' +
                            'public class ProductService {\n' +
                            '\n' +
                            '    private final Logger log = LoggerFactory.getLogger(ProductService.class);\n' +
                            '\n' +
                            '    private final ProductRepository productRepository;\n' +
                            '\n' +
                            '    public ProductService(ProductRepository productRepository) {\n' +
                            '        this.productRepository = productRepository;\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Save a product.\n' +
                            '     *\n' +
                            '     * @param product the entity to save.\n' +
                            '     * @return the persisted entity.\n' +
                            '     */\n' +
                            '    public Product save(Product product) {\n' +
                            '        log.debug("Request to save Product : {}", product);\n' +
                            '        return productRepository.save(product);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Update a product.\n' +
                            '     *\n' +
                            '     * @param product the entity to save.\n' +
                            '     * @return the persisted entity.\n' +
                            '     */\n' +
                            '    public Product update(Product product) {\n' +
                            '        log.debug("Request to update Product : {}", product);\n' +
                            '        return productRepository.save(product);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Partially update a product.\n' +
                            '     *\n' +
                            '     * @param product the entity to update partially.\n' +
                            '     * @return the persisted entity.\n' +
                            '     */\n' +
                            '    public Optional<Product> partialUpdate(Product product) {\n' +
                            '        log.debug("Request to partially update Product : {}", product);\n' +
                            '\n' +
                            '        return productRepository\n' +
                            '            .findById(product.getId())\n' +
                            '            .map(existingProduct -> {\n' +
                            '                if (product.getName() != null) {\n' +
                            '                    existingProduct.setName(product.getName());\n' +
                            '                }\n' +
                            '                if (product.getDescription() != null) {\n' +
                            '                    existingProduct.setDescription(product.getDescription());\n' +
                            '                }\n' +
                            '                if (product.getPrice() != null) {\n' +
                            '                    existingProduct.setPrice(product.getPrice());\n' +
                            '                }\n' +
                            '                if (product.getSizeProduct() != null) {\n' +
                            '                    existingProduct.setSizeProduct(product.getSizeProduct());\n' +
                            '                }\n' +
                            '                if (product.getImage() != null) {\n' +
                            '                    existingProduct.setImage(product.getImage());\n' +
                            '                }\n' +
                            '                if (product.getImageContentType() != null) {\n' +
                            '                    existingProduct.setImageContentType(product.getImageContentType());\n' +
                            '                }\n' +
                            '\n' +
                            '                return existingProduct;\n' +
                            '            })\n' +
                            '            .map(productRepository::save);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Get all the products.\n' +
                            '     *\n' +
                            '     * @param pageable the pagination information.\n' +
                            '     * @return the list of entities.\n' +
                            '     */\n' +
                            '    @Transactional(readOnly = true)\n' +
                            '    public Page<Product> findAll(Pageable pageable) {\n' +
                            '        log.debug("Request to get all Products");\n' +
                            '        return productRepository.findAll(pageable);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Get one product by id.\n' +
                            '     *\n' +
                            '     * @param id the id of the entity.\n' +
                            '     * @return the entity.\n' +
                            '     */\n' +
                            '    @Transactional(readOnly = true)\n' +
                            '    public Optional<Product> findOne(Long id) {\n' +
                            '        log.debug("Request to get Product : {}", id);\n' +
                            '        return productRepository.findById(id);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Delete the product by id.\n' +
                            '     *\n' +
                            '     * @param id the id of the entity.\n' +
                            '     */\n' +
                            '    public void delete(Long id) {\n' +
                            '        log.debug("Request to delete Product : {}", id);\n' +
                            '        productRepository.deleteById(id);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Serviciul este marcat cu <b>@Transactional</b> pentru a permite gestionarea tranzacțiilor pentru accesul la date.
                    <br/>
                    Serviciul definește metode de acțiune CRUD — de exemplu, metoda <i>findAll()</i> .
                    <br/>
                    Metoda acceptă deja paginare și returnează rezultatele ca <b>Page</b>.
                    Obiectele <b>Page</b> și <b>Pageable</b> sunt furnizate de Spring și ne permit să controlăm cu ușurință paginarea:
                    <SyntaxHighlighter>
                        {
                            '@Transactional(readOnly = true)\n' +
                            '    public Page<Product> findAll(Pageable pageable) {\n' +
                            '        log.debug("Request to get all Products");\n' +
                            '        return productRepository.findAll(pageable);\n' +
                            '    }\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    De exemplu, continutul fisierului <i>ProductCategoryService.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.service;\n' +
                            '\n' +
                            'import java.util.Optional;\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.data.domain.Page;\n' +
                            'import org.springframework.data.domain.Pageable;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import org.springframework.transaction.annotation.Transactional;\n' +
                            'import ro.ibrid.lapretbun.domain.Shipment;\n' +
                            'import ro.ibrid.lapretbun.repository.ShipmentRepository;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Service Implementation for managing {@link Shipment}.\n' +
                            ' */\n' +
                            '@Service\n' +
                            '@Transactional\n' +
                            'public class ShipmentService {\n' +
                            '\n' +
                            '    private final Logger log = LoggerFactory.getLogger(ShipmentService.class);\n' +
                            '\n' +
                            '    private final ShipmentRepository shipmentRepository;\n' +
                            '\n' +
                            '    public ShipmentService(ShipmentRepository shipmentRepository) {\n' +
                            '        this.shipmentRepository = shipmentRepository;\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Save a shipment.\n' +
                            '     *\n' +
                            '     * @param shipment the entity to save.\n' +
                            '     * @return the persisted entity.\n' +
                            '     */\n' +
                            '    public Shipment save(Shipment shipment) {\n' +
                            '        log.debug("Request to save Shipment : {}", shipment);\n' +
                            '        return shipmentRepository.save(shipment);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Update a shipment.\n' +
                            '     *\n' +
                            '     * @param shipment the entity to save.\n' +
                            '     * @return the persisted entity.\n' +
                            '     */\n' +
                            '    public Shipment update(Shipment shipment) {\n' +
                            '        log.debug("Request to update Shipment : {}", shipment);\n' +
                            '        return shipmentRepository.save(shipment);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Partially update a shipment.\n' +
                            '     *\n' +
                            '     * @param shipment the entity to update partially.\n' +
                            '     * @return the persisted entity.\n' +
                            '     */\n' +
                            '    public Optional<Shipment> partialUpdate(Shipment shipment) {\n' +
                            '        log.debug("Request to partially update Shipment : {}", shipment);\n' +
                            '\n' +
                            '        return shipmentRepository\n' +
                            '            .findById(shipment.getId())\n' +
                            '            .map(existingShipment -> {\n' +
                            '                if (shipment.getTrackingCode() != null) {\n' +
                            '                    existingShipment.setTrackingCode(shipment.getTrackingCode());\n' +
                            '                }\n' +
                            '                if (shipment.getDate() != null) {\n' +
                            '                    existingShipment.setDate(shipment.getDate());\n' +
                            '                }\n' +
                            '                if (shipment.getDetails() != null) {\n' +
                            '                    existingShipment.setDetails(shipment.getDetails());\n' +
                            '                }\n' +
                            '\n' +
                            '                return existingShipment;\n' +
                            '            })\n' +
                            '            .map(shipmentRepository::save);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Get all the shipments.\n' +
                            '     *\n' +
                            '     * @param pageable the pagination information.\n' +
                            '     * @return the list of entities.\n' +
                            '     */\n' +
                            '    @Transactional(readOnly = true)\n' +
                            '    public Page<Shipment> findAll(Pageable pageable) {\n' +
                            '        log.debug("Request to get all Shipments");\n' +
                            '        return shipmentRepository.findAll(pageable);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Get one shipment by id.\n' +
                            '     *\n' +
                            '     * @param id the id of the entity.\n' +
                            '     * @return the entity.\n' +
                            '     */\n' +
                            '    @Transactional(readOnly = true)\n' +
                            '    public Optional<Shipment> findOne(Long id) {\n' +
                            '        log.debug("Request to get Shipment : {}", id);\n' +
                            '        return shipmentRepository.findById(id);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Delete the shipment by id.\n' +
                            '     *\n' +
                            '     * @param id the id of the entity.\n' +
                            '     */\n' +
                            '    public void delete(Long id) {\n' +
                            '        log.debug("Request to delete Shipment : {}", id);\n' +
                            '        shipmentRepository.deleteById(id);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratedCodeServiceJdlJHipsterContent;