import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class HeaderThemeContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-header-theme", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Vom adauga in partea de sus a aplicatiei o bara, care va contine:
                    <ul>
                        <li>searchbar</li>
                        <li>link de autenticare</li>
                        <li>link de creare cont</li>
                    </ul>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/anatomy-theme-2.png'}/>
                    </div>

                    Pentru inceput vom prezenta tot codul din fisierul <b>portal_normal.ftl</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                        '\n' +
                        '<#include init />\n' +
                        '\n' +
                        '<html class="${root_css_class}" dir="<@liferay.language key="lang.dir" />" lang="${w3c_language_id}">\n' +
                        '\n' +
                        '<head>\n' +
                        '\t<title>${html_title}</title>\n' +
                        '\n' +
                        '\t<meta content="initial-scale=1.0, width=device-width" name="viewport" />\n' +
                        '\n' +
                        '\t<@liferay_util["include"] page=top_head_include />\n' +
                        '</head>\n' +
                        '\n' +
                        '<body class="${css_class}">\n' +
                        '\n' +
                        '<@liferay_ui["quick-access"] contentId="#main-content" />\n' +
                        '\n' +
                        '<@liferay_util["include"] page=body_top_include />\n' +
                        '\n' +
                        '<@liferay.control_menu />\n' +
                        '\n' +
                        '<#assign\n' +
                        '\tcreate_account_text = languageUtil.get(locale, "create-account")\n' +
                        '/>\n' +
                        '\n' +
                        '<div class="container-fluid d-flex flex-column min-vh-100" id="wrapper">\n' +
                        '\t<header id="banner" role="banner">\n' +
                        '\n' +
                        '\t\t<div id="heading-top">\n' +
                        '\t\t\t<div class="container-fluid container-fluid-max-xl user-personal-bar">\n' +
                        '\n' +
                        '\t\t\t\t<div class="align-items-center autofit-row">\n' +
                        '\n' +
                        '\t\t\t\t\t<#assign preferences = freeMarkerPortletPreferences.getPreferences({"portletSetupPortletDecoratorId": "barebone", "destination": "/search", "title":""}) />\n' +
                        '\t\t\t\t\t<div class="autofit-co autofit-col-expand">\n' +
                        '\t\t\t\t\t\t<#if show_header_search>\n' +
                        '\t\t\t\t\t\t\t<div class="justify-content-md-end mr-4 navbar-form" role="search">\n' +
                        '\t\t\t\t\t\t\t\t<@liferay.search_bar default_preferences="${preferences}" />\n' +
                        '\t\t\t\t\t\t\t</div>\n' +
                        '\t\t\t\t\t\t</#if>\n' +
                        '\t\t\t\t\t</div>\n' +
                        '\n' +
                        '\t\t\t\t\t<#if !is_signed_in>\n' +
                        '\n' +
                        '\t\t\t\t\t\t<#assign plid = layout.getPlid()>\n' +
                        '\t\t\t\t\t\t<#assign createAccountURL = portletURLFactory.create(request,"com_liferay_login_web_portlet_LoginPortlet",plid,"RENDER_PHASE")>\n' +
                        '\t\t\t\t\t\t${createAccountURL.setParameter("mvcRenderCommandName", "/login/create_account")}\n' +
                        '\t\t\t\t\t\t${createAccountURL.setParameter("p_p_state", "maximized")}\n' +
                        '\t\t\t\t\t\t${createAccountURL.setParameter("p_p_mode", "view")}\n' +
                        '\n' +
                        '\t\t\t\t\t\t<div class="autofit-col">\n' +
                        '\t\t\t\t\t\t\t<@liferay.user_personal_bar />\n' +
                        '\t\t\t\t\t\t</div>\n' +
                        '\n' +
                        '\t\t\t\t\t\t<div class="autofit-col">\n' +
                        '\t\t\t\t\t\t\t<a href="${createAccountURL}" id="create-account" rel="nofollow">${create_account_text}</a>\n' +
                        '\t\t\t\t\t\t</div>\n' +
                        '\n' +
                        '\t\t\t\t\t</#if>\n' +
                        '\n' +
                        '\t\t\t\t\t<#if is_signed_in>\n' +
                        '\t\t\t\t\t\t<div class="autofit-col">\n' +
                        '\t\t\t\t\t\t\t<@liferay.user_personal_bar />\n' +
                        '\t\t\t\t\t\t</div>\n' +
                        '\n' +
                        '\t\t\t\t\t</#if>\n' +
                        '\n' +
                        '\t\t\t\t</div>\n' +
                        '\n' +
                        '\t\t\t</div>\n' +
                        '\n' +
                        '\t\t</div>\n' +
                        '\n' +
                        '\n' +
                        '\t\t<div id="heading" class="container">\n' +
                        '\t\t\t<div aria-level="1" class="site-title" role="heading">\n' +
                        '\t\t\t\t<a class="${logo_css_class}" href="${site_default_url}" title="<@liferay.language_format arguments="${site_name}" key="go-to-x" />">\n' +
                        '\t\t\t\t\t<img alt="${logo_description}" height="${site_logo_height}" src="${site_logo}" width="${site_logo_width}" />\n' +
                        '\t\t\t\t</a>\n' +
                        '\n' +
                        '\t\t\t\t<#if show_site_name>\n' +
                        '\t\t\t\t\t<span class="site-name" title="<@liferay.language_format arguments="${site_name}" key="go-to-x" />">\n' +
                        '\t\t\t\t\t\t${site_name}\n' +
                        '\t\t\t\t\t</span>\n' +
                        '\t\t\t\t</#if>\n' +
                        '\t\t\t</div>\n' +
                        '\t\t</div>\n' +
                        '\n' +
                        '\t\t<#if has_navigation && is_setup_complete>\n' +
                        '\t\t\t<#include "${full_templates_path}/navigation.ftl" />\n' +
                        '\t\t</#if>\n' +
                        '\t</header>\n' +
                        '\n' +
                        '\t<section id="content" class="container">\n' +
                        '\t\t<h2 class="hide-accessible" role="heading" aria-level="1">${the_title}</h2>\n' +
                        '\n' +
                        '\t\t<#if selectable>\n' +
                        '\t\t\t<@liferay_util["include"] page=content_include />\n' +
                        '\t\t<#else>\n' +
                        '\t\t\t${portletDisplay.recycle()}\n' +
                        '\n' +
                        '\t\t\t${portletDisplay.setTitle(the_title)}\n' +
                        '\n' +
                        '\t\t\t<@liferay_theme["wrap-portlet"] page="portlet.ftl">\n' +
                        '\t\t\t\t<@liferay_util["include"] page=content_include />\n' +
                        '\t\t\t</@>\n' +
                        '\t\t</#if>\n' +
                        '\t</section>\n' +
                        '\n' +
                        '\t<#if show_footer>\n' +
                        '\n' +
                        '\t\t<footer id="footer" class="mt-auto" role="contentinfo">\n' +
                        '\t\t\t<div class="container">\n' +
                        '\t\t\t\t<div class="d-flex justify-content-between">\n' +
                        '\t\t\t\t\tVanilla\n' +
                        '\t\t\t\t</div>\n' +
                        '\t\t\t</div>\n' +
                        '\t\t</footer>\n' +
                        '\n' +
                        '\t</#if>\n' +
                        '\n' +
                        '</div>\n' +
                        '\n' +
                        '<@liferay_util["include"] page=body_bottom_include />\n' +
                        '\n' +
                        '<@liferay_util["include"] page=bottom_include />\n' +
                        '\n' +
                        '</body>\n' +
                        '\n' +
                        '</html>'}
                    </SyntaxHighlighter>

                    Codul SCSS:
                    <SyntaxHighlighter   showLineNumbers={true} language="css" style={androidstudio}>
                        {'$base-color: #2d3142;\n' +
                        '$a-color:#ef8354;\n' +
                        '$a-color-hover:#ef783a;\n' +
                        '\n' +
                        '$nav-bg:#f5dfbb;\n' +
                        '\n' +
                        'header{\n' +
                        '  #heading-top{\n' +
                        '    background: $base-color;\n' +
                        '    margin-right: -15px;\n' +
                        '    margin-left:-15px;\n' +
                        '    color:white;\n' +
                        '    padding: 10px;\n' +
                        '\n' +
                        '    #create-account{\n' +
                        '      text-align: right;\n' +
                        '      margin-right: 10px;\n' +
                        '      margin-left: 10px;\n' +
                        '    }\n' +
                        '\n' +
                        '    .portlet-header{\n' +
                        '      display: none;\n' +
                        '    }\n' +
                        '\n' +
                        '    .portlet {\n' +
                        '      margin-bottom: 0px !important;\n' +
                        '    }\n' +
                        '\n' +
                        '    a{\n' +
                        '      color: $a-color;\n' +
                        '      text-transform: uppercase;\n' +
                        '      text-decoration: none;\n' +
                        '    }\n' +
                        '\n' +
                        '    a:hover{\n' +
                        '      color: $a-color-hover;\n' +
                        '      text-transform: uppercase;\n' +
                        '      text-decoration: none;\n' +
                        '    }\n' +
                        '  }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    In fisierul <b>liferay-look-and-feel.xml</b> se adauga linia <b>14</b>:
                    <SyntaxHighlighter   showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<!DOCTYPE look-and-feel PUBLIC "-//Liferay//DTD Look and Feel 7.3.0//EN" "http://www.liferay.com/dtd/liferay-look-and-feel_7_3_0.dtd">\n' +
                        '\n' +
                        '<look-and-feel>\n' +
                        '\t<compatibility>\n' +
                        '\t\t<version>7.3.0+</version>\n' +
                        '\t</compatibility>\n' +
                        '\t<theme id="vanilla" name="Vanilla">\n' +
                        '\t\t<template-extension>ftl</template-extension>\n' +
                        '\n' +
                        '\t\t<settings>\n' +
                        '\t\t\t<setting configurable="true" key="show-footer" type="checkbox" value="true" />\n' +
                        '\t\t\t<setting configurable="true" key="show-header" type="checkbox" value="true" />\n' +
                        '\t\t\t<setting configurable="true" key="show-header-search" type="checkbox" value="true" />\n' +
                        '\t\t</settings>\n' +
                        '\n' +
                        '\t\t<portlet-decorator id="barebone" name="Barebone">\n' +
                        '\t\t\t<portlet-decorator-css-class>portlet-barebone</portlet-decorator-css-class>\n' +
                        '\t\t</portlet-decorator>\n' +
                        '\t\t<portlet-decorator id="borderless" name="Borderless">\n' +
                        '\t\t\t<portlet-decorator-css-class>portlet-borderless</portlet-decorator-css-class>\n' +
                        '\t\t</portlet-decorator>\n' +
                        '\t\t<portlet-decorator id="decorate" name="Decorate">\n' +
                        '\t\t\t<default-portlet-decorator>true</default-portlet-decorator>\n' +
                        '\t\t\t<portlet-decorator-css-class>portlet-decorate</portlet-decorator-css-class>\n' +
                        '\t\t</portlet-decorator>\n' +
                        '\t</theme>\n' +
                        '</look-and-feel>\n'}
                    </SyntaxHighlighter>

                    Iar continutul fisierul <b>init_custom.ftl</b> completat cu linia <b>8</b>:
                    <SyntaxHighlighter   showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<#--\n' +
                        'This file allows you to override and define new FreeMarker variables.\n' +
                        '-->\n' +
                        '\n' +
                        '<#assign\n' +
                        'show_footer = getterUtil.getBoolean(themeDisplay.getThemeSetting("show-footer"))\n' +
                        'show_header = getterUtil.getBoolean(themeDisplay.getThemeSetting("show-header"))\n' +
                        'show_header_search = getterUtil.getBoolean(themeDisplay.getThemeSetting("show-header-search"))\n' +
                        '/>'}
                    </SyntaxHighlighter>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360028833812-Theme-Anatomy-Reference-Guide"}>
                                    Theme Anatomy Reference Guide
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default HeaderThemeContent;