import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class SpreadOperatorReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-spread-operator", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Operatorul Spread (...)</b>
                    <br/>
                    <br/>

                    Copiere elemente array:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const x = [1,2,3];\n' +
                            'const y = [...x, 4, 5];'}
                    </SyntaxHighlighter>

                    Copiere proprietati obiect:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const a = {\n' +
                            ' \tname: "a",\n' +
                            '  age: 4\n' +
                            '}\n' +
                            '\n' +
                            'const b = {...a, car: "red"}'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  age: 4,\n' +
                            '  car: "red",\n' +
                            '  name: "a"\n' +
                            '}'}
                    </SyntaxHighlighter>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpreadOperatorReactContent;