import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class EnvK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-env", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Variabile de mediu</b>
                    <br/>
                    <br/>


                    O variabila de mediu pentru un container se seteaza in sectiunea <b>env</b>.
                    Fiecare variabila fiind definita prin <b>name</b> + <b>value</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:2\n' +
                            '          env:\n' +
                            '            - name: STORY_FOLDER\n' +
                            '              value: \'story\''}
                    </SyntaxHighlighter>

                    O alta posibilitate este ca in loc de <b>value</b>, sa se foloseasca <b>valueFrom</b> (atunci cand vrem ca valorile sa fie tinute intr-o alta resursa/fisier).
                    Adica se foloseasca un obiect de tip <b>ConfigMap</b>.
                    <br/>
                    Putem creea un fisier: <i>environment.yaml</i> (poate fi orice nume), cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: ConfigMap\n' +
                            'metadata:\n' +
                            '  name: data-store-env\n' +
                            'data:\n' +
                            '  folder: \'story\'\n' +
                            '  # key: value..'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:2\n' +
                            '          env:\n' +
                            '            - name: STORY_FOLDER\n' +
                            '              # value: \'story\'\n' +
                            '              valueFrom: \n' +
                            '                configMapKeyRef:\n' +
                            '                  name: data-store-env\n' +
                            '                 key: folder'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: story-deployment\n' +
                            'spec: \n' +
                            '  replicas: 2\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: story\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: story\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:2\n' +
                            '          env:\n' +
                            '            - name: STORY_FOLDER\n' +
                            '              # value: \'story\'\n' +
                            '              valueFrom: \n' +
                            '                configMapKeyRef:\n' +
                            '                  name: data-store-env\n' +
                            '                  key: folder\n' +
                            '          volumeMounts:\n' +
                            '            - mountPath: /app/story\n' +
                            '              name: story-volume\n' +
                            '      volumes:\n' +
                            '        - name: story-volume\n' +
                            '          persistentVolumeClaim:\n' +
                            '            claimName: host-pvc'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca in fisierul Dockerfile se definesc variabile de mediu, acestea pot fi superascrise prin <b>env:</b>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EnvK8sContent;