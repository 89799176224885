import React from "react";

class AccessDeniedContent extends React.Component {

    render() {
        return (
            <div className="home index-page p-5">
                <h3>
                    Access interzis!
                </h3>
                <a href={"/login"}>logare</a>
            </div>
        );
    }
}

export default AccessDeniedContent;