import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaActivatorsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-activators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Activators</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'@Component\n' +
                            'public class BookActivator {\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    public void init() {\n' +
                            '\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.global.resource.bundle;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.language.LanguageUtil;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.util.HashMapDictionaryBuilder;\n' +
                            'import org.osgi.framework.BundleContext;\n' +
                            'import org.osgi.service.component.annotations.Activate;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            'import java.util.Locale;\n' +
                            'import java.util.Map;\n' +
                            'import java.util.ResourceBundle;\n' +
                            'import java.util.Set;\n' +
                            '\n' +
                            '@Component\n' +
                            'public class DatabaseResourceBundleActivator {\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    public void activate(BundleContext bundleContext, Map<String, Object> properties) {\n' +
                            '\n' +
                            '        Set<Locale> availableLocales = LanguageUtil.getAvailableLocales();\n' +
                            '\n' +
                            '        for (Locale locale : availableLocales) {\n' +
                            '\n' +
                            '           bundleContext.registerService(ResourceBundle.class,\n' +
                            '                   new DatabaseResourceBundle(locale),\n' +
                            '                   HashMapDictionaryBuilder.<String, Object>put(\n' +
                            '                         "language.id", locale.getLanguage()\n' +
                            '                    ).build());\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(DatabaseResourceBundleActivator.class);\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaActivatorsLiferayContent;