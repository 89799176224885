import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class WrapperGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-wrapper", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Gradle Wrapper permite rularea de task-uri Gradle fara a fi nevoie ca Gradle sa fie instalat pe sistem
                    (prin urmare, se poate rula un proiect Gradle, fara sa fie instalat Gradle).

                    <hr/>

                    Pentru a genera wrapper cu o anumita versiune de Gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle wrapper --gradle-version 6.0'}
                    </SyntaxHighlighter>

                    O alta varianta este de a specifica versiunea in <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'wrapper {\n' +
                        '   gradle-version = \'6.0\'\n' +
                        '}'}
                    </SyntaxHighlighter>
                    si apoi rulat task-ul <b>gradle wrapper</b>.

                    <br/>
                    <br/>
                    Aceasta instructiune va genera un director numit <b>gradle/wrapper</b> cu 2 fisiere:
                    <ul>
                        <li>gradle-wrapper.jar</li>
                        <li>gradle-wrapper.properties</li>
                    </ul>

                    Fisierul <i>gradle-wrapper.properties</i> va avea urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'distributionBase=GRADLE_USER_HOME\n' +
                        'distributionPath=wrapper/dists\n' +
                        'distributionUrl=https\\://services.gradle.org/distributions/gradle-6.0-bin.zip\n' +
                        'zipStoreBase=GRADLE_USER_HOME\n' +
                        'zipStorePath=wrapper/dists'}
                    </SyntaxHighlighter>

                    In plus, se vor genera si urmatoarele 2 fisire:
                    <ul>
                        <li>gradlew</li>
                        <li>gradlew.bat (folosind aceasta comanda, in loc de gradle, se va folosi versiune specificata cand a fost creat wrapper-ul)

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradlew clean build'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    Pentru a afla versiunea instalata de Gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle -v'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afla versiunea de Gradle Wrapper:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradlew -v'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WrapperGradleContent;