import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";


class WebpackReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-webpack", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Instalare Webpack</b>
                    <br/>
                    <br/>


                    Initializare (se va genera un fisier <b>package.json</b> - util pentru gestionare dependintelor, de exemplu):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm init -y'}
                    </SyntaxHighlighter>

                    va afisa:
                    <SyntaxHighlighter>
                        {'Wrote to D:\\work-resources\\webpack-test\\package.json:\n' +
                            '\n' +
                            '{\n' +
                            '  "name": "webpckage-test",\n' +
                            '  "version": "1.0.0",\n' +
                            '  "description": "",\n' +
                            '  "main": "index.js",\n' +
                            '  "scripts": {\n' +
                            '    "test": "echo \\"Error: no test specified\\" && exit 1"\n' +
                            '  },\n' +
                            '  "keywords": [],\n' +
                            '  "author": "",\n' +
                            '  "license": "ISC"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install -D webpack webpack-cli webpack-dev-server'}
                    </SyntaxHighlighter>

                    Dupa instalare, fisierul <b>package.json</b> va fi actualizat cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '  "name": "webpckage-test",\n' +
                            '  "version": "1.0.0",\n' +
                            '  "description": "",\n' +
                            '  "main": "index.js",\n' +
                            '  "scripts": {\n' +
                            '    "test": "echo \\"Error: no test specified\\" && exit 1"\n' +
                            '  },\n' +
                            '  "keywords": [],\n' +
                            '  "author": "",\n' +
                            '  "license": "ISC",\n' +
                            '  "devDependencies": {\n' +
                            '    "webpack": "^5.83.1",\n' +
                            '    "webpack-cli": "^5.1.1",\n' +
                            '    "webpack-dev-server": "^4.15.0"\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Configurare Webpack</b>
                    <br/>
                    <br/>

                    Se creaza fisirul de configurare cu numele <b>webpack.config.js</b> cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const path = require(\'path\')\n' +
                            '\n' +
                            'module.exports = {\n' +
                            '\tentry: \'./public/main.js\',\n' +
                            '\toutput: {\n' +
                            '\t\tpath: path.resolve(__dirname, \'dist\'),\n' +
                            '\t\tfilename: \'index.js\'\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Instalare plugin html: <b>html-webpack-plugin</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install -D html-webpack-plugin'}
                    </SyntaxHighlighter>

                    Adaugam plugin in fisierul de configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const path = require(\'path\')\n' +
                            'const HTMLWebpackPlugin = require(\'html-webpack-plugin\')\n' +
                            '\n' +
                            'module.exports = {\n' +
                            '\tentry: \'./public/main.js\',\n' +
                            '\toutput: {\n' +
                            '\t\tpath: path.resolve(__dirname, \'dist\'),\n' +
                            '\t\tfilename: \'index.js\'\n' +
                            '\t},\n' +
                            '\tplugins:[\n' +
                            '\t\tnew HTMLWebpackPlugin({\n' +
                            '\t\t\ttemplate: \'./public/index.html\'\n' +
                            '\t\t})\n' +
                            '\t]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In fisierul <b>package.json</b>, sectiunea <b>scripts</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {' "scripts": {\n' +
                            '\t"serve": "webpack serve --mode=development",\n' +
                            '\t"build": "webpack --mode=production",\n' +
                            '    "test": "echo \\"Error: no test specified\\" && exit 1"\n' +
                            '  },'}
                    </SyntaxHighlighter>

                    Pentru a testa, vom creea un director <i>public</i> si in interior 2 fisiere:
                    <ul>
                        <li>main.js
                            <br/>
                            cu continutul:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'console.log(\'test\')'}
                            </SyntaxHighlighter>
                        </li>
                        <li>index.html
                            <br/>
                            cu continutul:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'<h1>Hello!</h1>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Apoi se poate rula:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm run serve'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'> webpack serve --mode=development\n' +
                            '\n' +
                            '<i> [webpack-dev-server] Project is running at:\n' +
                            '<i> [webpack-dev-server] Loopback: http://localhost:8081/\n' +
                            '<i> [webpack-dev-server] On Your Network (IPv4): http://192.168.1.5:8081/\n' +
                            '<i> [webpack-dev-server] On Your Network (IPv6): http://[fe80::1a3b:a26d:3f0c:b80d]:8081/\n' +
                            '<i> [webpack-dev-server] Content not from webpack is served from \'D:\\work-resources\\webpckage-test\\public\' directory\n' +
                            'asset index.js 265 KiB [emitted] (name: main)\n' +
                            'asset index.html 15 bytes [emitted]\n' +
                            'runtime modules 27.3 KiB 12 modules\n' +
                            'modules by path ./node_modules/ 177 KiB\n' +
                            '  modules by path ./node_modules/webpack-dev-server/client/ 71.8 KiB 16 modules\n' +
                            '  modules by path ./node_modules/webpack/hot/*.js 5.3 KiB\n' +
                            '    ./node_modules/webpack/hot/dev-server.js 1.94 KiB [built] [code generated]\n' +
                            '    ./node_modules/webpack/hot/log.js 1.86 KiB [built] [code generated]\n' +
                            '    + 2 modules\n' +
                            '  modules by path ./node_modules/html-entities/lib/*.js 81.3 KiB\n' +
                            '    ./node_modules/html-entities/lib/index.js 7.74 KiB [built] [code generated]\n' +
                            '    ./node_modules/html-entities/lib/named-references.js 72.7 KiB [built] [code generated]\n' +
                            '    ./node_modules/html-entities/lib/numeric-unicode-map.js 339 bytes [built] [code generated]\n' +
                            '    ./node_modules/html-entities/lib/surrogate-pairs.js 537 bytes [built] [code generated]\n' +
                            '  ./node_modules/ansi-html-community/index.js 4.16 KiB [built] [code generated]\n' +
                            '  ./node_modules/events/events.js 14.5 KiB [built] [code generated]\n' +
                            './public/main.js 20 bytes [built] [code generated]\n' +
                            'webpack 5.83.1 compiled successfully in 1923 ms'}
                    </SyntaxHighlighter>

                    Daca vom deschide cu un browser:
                    <SyntaxHighlighter>
                        {'http://localhost:8081'}
                    </SyntaxHighlighter>
                    se va afisa: Hello!
                    <br/>
                    Daca modificam in fisierul <i>index.html</i>, la refresh se vor vedea noile modificari.


                    <hr/>
                    <b>3. Instalare Rust</b>
                    <br/>
                    <br/>

                    Se poate instala de pe aceasta adresa:
                    <a target={"_blank"} href={"https://www.rust-lang.org/tools/install"}>
                        <SyntaxHighlighter>
                            {'https://www.rust-lang.org/tools/install'}
                        </SyntaxHighlighter>
                    </a>

                    Apoi, pentru a creea un proiect Rust:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'cargo init --lib'}
                    </SyntaxHighlighter>

                    Se va genera un fisier numit <b>Cargo.toml</b>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'[package]\n' +
                            'name = "webpckage-test"\n' +
                            'version = "0.1.0"\n' +
                            'edition = "2021"\n' +
                            '\n' +
                            '# See more keys and their definitions at https://doc.rust-lang.org/cargo/reference/manifest.html\n' +
                            '\n' +
                            '[dependencies]'}
                    </SyntaxHighlighter>
                    Se va genera un director <b>src</b> cu un fisier numit <b>lib.rs</b>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'pub fn add(left: usize, right: usize) -> usize {\n' +
                            '    left + right\n' +
                            '}\n' +
                            '\n' +
                            '#[cfg(test)]\n' +
                            'mod tests {\n' +
                            '    use super::*;\n' +
                            '\n' +
                            '    #[test]\n' +
                            '    fn it_works() {\n' +
                            '        let result = add(2, 2);\n' +
                            '        assert_eq!(result, 4);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    {/*Testare:*/}
                    {/*<SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>*/}
                    {/*    {'npm install -D @wasm-tool/wasm-pack-plugin'}*/}
                    {/*</SyntaxHighlighter>*/}

                    <hr/>
                    <b>4. Configurare Rust cu Webpack</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install -D @wasm-tool/wasm-pack-plugin'}
                    </SyntaxHighlighter>

                    Apoi, adaugam acest plugin in fisierul de configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {
                            'const path = require(\'path\')\n' +
                            'const HTMLWebpackPlugin = require(\'html-webpack-plugin\')\n' +
                            'const WasmPackPlugin = require(\'@wasm-tool/wasm-pack-plugin\')\n' +
                            '\n' +
                            'module.exports = {\n' +
                            '\tentry: \'./public/main.js\',\n' +
                            '\toutput: {\n' +
                            '\t\tpath: path.resolve(__dirname, \'dist\'),\n' +
                            '\t\tfilename: \'index.js\'\n' +
                            '\t},\n' +
                            '\tplugins:[\n' +
                            '\t\tnew HTMLWebpackPlugin({\n' +
                            '\t\t\ttemplate: \'./public/index.html\'\n' +
                            '\t\t}),\n' +
                            '\t\tnew WasmPackPlugin({\n' +
                            '\t\t\tcrateDirectory: path.resolve(__dirname, \'.\')\n' +
                            '\t\t})\n' +
                            '\t\t\n' +
                            '\t]\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    In plus, trebuie adaugate in <b>Cargo.toml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'[lib]\n' +
                            'crate-type = ["cdylib"]'}
                    </SyntaxHighlighter>

                    Deci:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'[package]\n' +
                            'name = "webpckage-test"\n' +
                            'version = "0.1.0"\n' +
                            'edition = "2021"\n' +
                            '\n' +
                            '[lib]\n' +
                            'crate-type = ["cdylib"]\n' +
                            '\n' +
                            '# See more keys and their definitions at https://doc.rust-lang.org/cargo/reference/manifest.html\n' +
                            '\n' +
                            '[dependencies]\n'}
                    </SyntaxHighlighter>

                    Instalare:
                    <a target={"_blank"} href={"https://rustwasm.github.io/wasm-pack/installer/"}>
                        <SyntaxHighlighter>
                            {'https://rustwasm.github.io/wasm-pack/installer/'}
                        </SyntaxHighlighter>
                    </a>

                    <hr/>
                    <b>5. Legatura intre Wasm si JavaScript</b>
                    <br/>
                    <br/>

                    <b>Wasm-bindgen</b> este o dependență de construcție pentru Rust.
                    Este capabil să genereze cod Rust și JavaScript în timpul compilării.

                    <br/>
                    <br/>

                    In <b>Cargo.toml</b>, adaugam in sectiunea [dependencies]:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'[dependencies]\n' +
                            'wasm-bindgen = "0.2.86" '}
                    </SyntaxHighlighter>

                    Adaugam sectiunea <b>experiments</b>:
                    <SyntaxHighlighter>
                        {'const path = require(\'path\')\n' +
                            'const HTMLWebpackPlugin = require(\'html-webpack-plugin\')\n' +
                            'const WasmPackPlugin = require(\'@wasm-tool/wasm-pack-plugin\')\n' +
                            '\n' +
                            'module.exports = {\n' +
                            '\tentry: \'./public/main.js\',\n' +
                            '\toutput: {\n' +
                            '\t\tpath: path.resolve(__dirname, \'dist\'),\n' +
                            '\t\tfilename: \'index.js\'\n' +
                            '\t},\n' +
                            '\tplugins:[\n' +
                            '\t\tnew HTMLWebpackPlugin({\n' +
                            '\t\t\ttemplate: \'./public/index.html\'\n' +
                            '\t\t}),\n' +
                            '\t\tnew WasmPackPlugin({\n' +
                            '\t\t\tcrateDirectory: path.resolve(__dirname, \'.\')\n' +
                            '\t\t})\n' +
                            '\t\t\n' +
                            '\t],\n' +
                            '\texperiments: {\n' +
                            '\t\tasyncWebAssembly: true\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Aplicatia</b>
                    <br/>
                    <br/>

                    De converitit o poza color in poza alb-negru.

                    <hr/>

                    index.html:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<h1>Upload</h1>\n' +
                            '<input id="upload" type="file"/>'}
                    </SyntaxHighlighter>

                    main.js:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// Observatii:\n' +
                            '//  - input.files[0] // <-- returneaza un obiect de tip File\n' +
                            '//  - fileReader.result // <-- returneaza un string\n' +
                            '\n' +
                            'function init(){\n' +
                            '\t\n' +
                            '\tconst input = document.getElementById("upload");\n' +
                            '\tconst fileReader = new FileReader();\n' +
                            '\t\n' +
                            '\tfileReader.onloadend = () => {\n' +
                            '\t\tconst base64 = fileReader.result.replace(/^data:image\\/(png|jpeg|jpg);base64,/,\'\');\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tinput.addEventListener(\'change\', ()=>{\n' +
                            '\t\t\n' +
                            '\t\tfileReader.readAsDataURL(input.files[0])\n' +
                            '\t})\n' +
                            '\t\n' +
                            '}\n' +
                            '\n' +
                            'init();'}
                    </SyntaxHighlighter>


                    <hr/>
                    Modificam <i>main.js</i> se putem avea acces la <b>Rust</b>:
                    <SyntaxHighlighter>
                        {'// Observatii:\n' +
                            '//  - input.files[0] // <-- returneaza un obiect de tip File\n' +
                            '//  - fileReader.result // <-- returneaza un string\n' +
                            '\n' +
                            'async function init(){\n' +
                            '\t\n' +
                            '\tconsole.log("start...");\n' +
                            '\t\n' +
                            '\tlet rustApp = null;\n' +
                            '\t\n' +
                            '\ttry{\n' +
                            '\t\trustApp = await import(\'../pkg\')\n' +
                            '\t} catch (e){\n' +
                            '\t\tconsole.error(e)\n' +
                            '\t\treturn;\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tconsole.log(rustApp);\n' +
                            '\t\n' +
                            '\tconst input = document.getElementById("upload");\n' +
                            '\tconst fileReader = new FileReader();\n' +
                            '\t\n' +
                            '\tfileReader.onloadend = () => {\n' +
                            '\t\tconst base64 = fileReader.result.replace(/^data:image\\/(png|jpeg|jpg);base64,/,\'\');\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tinput.addEventListener(\'change\', ()=>{\n' +
                            '\t\t\n' +
                            '\t\tfileReader.readAsDataURL(input.files[0])\n' +
                            '\t})\n' +
                            '\t\n' +
                            '}\n' +
                            '\n' +
                            'init();'}
                    </SyntaxHighlighter>
                    <hr/>
                    Apoi se poate rula:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm run serve'}
                    </SyntaxHighlighter>

                    Se poate afisa:
                    <SyntaxHighlighter>
                        {'> webpckage-test@1.0.0 serve D:\\work-resources\\webpckage-test\n' +
                            '> webpack serve --mode=development\n' +
                            '\n' +
                            '🧐  Checking for wasm-pack...\n' +
                            '\n' +
                            '✅  wasm-pack is installed at C:\\Users\\buzdu\\.cargo\\bin\\wasm-pack.EXE.\n' +
                            '\n' +
                            'ℹ️  Compiling your crate in development mode...\n' +
                            '\n' +
                            '<i> [webpack-dev-server] Project is running at:\n' +
                            '<i> [webpack-dev-server] Loopback: http://localhost:8081/\n' +
                            '<i> [webpack-dev-server] On Your Network (IPv4): http://192.168.1.5:8081/\n' +
                            '<i> [webpack-dev-server] On Your Network (IPv6): http://[fe80::1a3b:a26d:3f0c:b80d]:8081/\n' +
                            '<i> [webpack-dev-server] Content not from webpack is served from \'D:\\work-resources\\webpckage-test\\public\' directory\n' +
                            '<i> [webpack-dev-middleware] wait until bundle finished: /\n' +
                            '[INFO]: Checking for the Wasm target...\n' +
                            'info: downloading component \'rust-std\' for \'wasm32-unknown-unknown\'\n' +
                            'info: installing component \'rust-std\' for \'wasm32-unknown-unknown\'\n' +
                            ' 18.2 MiB /  18.2 MiB (100 %)  13.1 MiB/s in  1s ETA:  0s\n' +
                            '[INFO]: Compiling to Wasm...\n' +
                            '   Compiling proc-macro2 v1.0.58\n' +
                            '   Compiling unicode-ident v1.0.8\n' +
                            '   Compiling quote v1.0.27\n' +
                            '   Compiling log v0.4.17\n' +
                            '   Compiling wasm-bindgen-shared v0.2.86\n' +
                            '   Compiling cfg-if v1.0.0\n' +
                            '   Compiling bumpalo v3.12.2\n' +
                            '   Compiling once_cell v1.17.1\n' +
                            '   Compiling wasm-bindgen v0.2.86\n' +
                            'error: linker `link.exe` not found\n' +
                            '  |\n' +
                            '  = note: program not found\n' +
                            '\n' +
                            'note: the msvc targets depend on the msvc linker but `link.exe` was not found\n' +
                            '\n' +
                            'note: please ensure that Visual Studio 2017 or later, or Build Tools for Visual Studio were installed with the Visual C++ option.\n' +
                            '\n' +
                            'note: VS Code is a different product, and is not sufficient.\n' +
                            '\n' +
                            'error: could not compile `wasm-bindgen` due to previous error\n' +
                            'warning: build failed, waiting for other jobs to finish...\n' +
                            'error: could not compile `log` due to previous error\n' +
                            'error: could not compile `wasm-bindgen-shared` due to previous error\n' +
                            'error: could not compile `quote` due to previous error\n' +
                            'error: could not compile `proc-macro2` due to previous error\n' +
                            'Error: Compiling your crate to WebAssembly failed\n' +
                            'Caused by: Compiling your crate to WebAssembly failed\n' +
                            'Caused by: failed to execute `cargo build`: exited with exit code: 101\n' +
                            '  full command: "cargo" "build" "--lib" "--target" "wasm32-unknown-unknown"\n' +
                            'asset index.js 271 KiB [emitted] (name: main)\n' +
                            'asset pkg_index_js.index.js 875 bytes [emitted]\n' +
                            'asset index.html 100 bytes [emitted]\n' +
                            'runtime modules 31.6 KiB 15 modules\n' +
                            'modules by path ./node_modules/ 177 KiB\n' +
                            '  modules by path ./node_modules/webpack-dev-server/client/ 71.8 KiB 16 modules\n' +
                            '  modules by path ./node_modules/webpack/hot/*.js 5.3 KiB\n' +
                            '    ./node_modules/webpack/hot/dev-server.js 1.94 KiB [built] [code generated]\n' +
                            '    ./node_modules/webpack/hot/log.js 1.86 KiB [built] [code generated]\n' +
                            '    + 2 modules\n' +
                            '  modules by path ./node_modules/html-entities/lib/*.js 81.3 KiB\n' +
                            '    ./node_modules/html-entities/lib/index.js 7.74 KiB [built] [code generated]\n' +
                            '    ./node_modules/html-entities/lib/named-references.js 72.7 KiB [built] [code generated]\n' +
                            '    + 2 modules\n' +
                            '  ./node_modules/ansi-html-community/index.js 4.16 KiB [built] [code generated]\n' +
                            '  ./node_modules/events/events.js 14.5 KiB [built] [code generated]\n' +
                            './public/main.js 667 bytes [built] [code generated]\n' +
                            './pkg/index.js 1 bytes [built] [code generated]\n' +
                            'webpack 5.83.1 compiled successfully in 118434 ms'}
                    </SyntaxHighlighter>

                    Fie fisierul Rust, <i>lib.rs</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'use wasm_bindgen::prelude::*;\n' +
                            'use web_sys::console::log_1 as log;\n' +
                            'use base64::{ encode, decode };\n' +
                            'use image::load_from_memory;\n' +
                            'use image::ImageOutputFormat::Png;\n' +
                            '\n' +
                            '#[wasm_bindgen]\n' +
                            'pub fn grayscale(encoded_file: &str) -> String {\n' +
                            '  log(&"Grayscale called".into());\n' +
                            '\n' +
                            '  let base64_to_vector = decode(encoded_file).unwrap();\n' +
                            '  log(&"Image decoded".into());\n' +
                            '\n' +
                            '  let mut img = load_from_memory(&base64_to_vector).unwrap();\n' +
                            '  log(&"Image loaded".into());\n' +
                            '\n' +
                            '  img = img.grayscale();\n' +
                            '  log(&"Grayscale effect applied".into());\n' +
                            '\n' +
                            '  let mut buffer = vec![];\n' +
                            '  img.write_to(&mut buffer, Png).unwrap();\n' +
                            '  log(&"New image written".into());\n' +
                            '\n' +
                            '  let encoded_img = encode(&buffer);\n' +
                            '  let data_url = format!(\n' +
                            '    "data:image/png;base64,{}",\n' +
                            '    encoded_img\n' +
                            '  );\n' +
                            '\n' +
                            '  data_url\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fie fisierul <i>Cargo.toml</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'[package]\n' +
                            'name = "image-effects"\n' +
                            'version = "0.1.0"\n' +
                            'edition = "2018"\n' +
                            '\n' +
                            '[lib]\n' +
                            'crate-type = ["cdylib"]\n' +
                            '\n' +
                            '# See more keys and their definitions at https://doc.rust-lang.org/cargo/reference/manifest.html\n' +
                            '\n' +
                            '[dependencies]\n' +
                            'wasm-bindgen = "0.2.86"\n' +
                            'base64 = "0.13.0"\n' +
                            'image = "0.23.14"\n' +
                            '\n' +
                            '[dependencies.web-sys]\n' +
                            'version = "0.3.4"\n' +
                            'features = ["console"]'}
                    </SyntaxHighlighter>

                    Fie fisierul <i>main.js</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// Observatii:\n' +
                            '//  - input.files[0] // <-- returneaza un obiect de tip File\n' +
                            '//  - fileReader.result // <-- returneaza un string\n' +
                            '\n' +
                            'async function init() {\n' +
                            '  let rustApp = null\n' +
                            '\n' +
                            '  try {\n' +
                            '    rustApp = await import(\'../pkg\')\n' +
                            '  } catch(err) {\n' +
                            '    console.error(err)\n' +
                            '    return;\n' +
                            '  }\n' +
                            '\n' +
                            '  console.log(rustApp)\n' +
                            '\n' +
                            '  const input = document.getElementById(\'upload\')\n' +
                            '  const fileReader = new FileReader()\n' +
                            '\n' +
                            '  fileReader.onloadend = () => {\n' +
                            '    let base64 = fileReader.result.replace(\n' +
                            '      /^data:image\\/(png|jpeg|jpg);base64,/, \'\'\n' +
                            '    )\n' +
                            '    let img_data_url = rustApp.grayscale(base64)\n' +
                            '    document.getElementById(\'new-img\').setAttribute(\n' +
                            '      \'src\', img_data_url\n' +
                            '    )\n' +
                            '  }\n' +
                            '\n' +
                            '  input.addEventListener(\'change\', () => {\n' +
                            '    fileReader.readAsDataURL(input.files[0])\n' +
                            '  })\n' +
                            '}\n' +
                            '\n' +
                            'init()'}
                    </SyntaxHighlighter>

                    Fie fisierul <i>index.html</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'<h1>Upload</h1>\n' +
                            '<input id="upload" type="file"/>\n' +
                            '<img id="new-img" class="w-auto mx-auto">'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Daca exista e eroare de tipul:
                            <SyntaxHighlighter>
                                {'error: linker `link.exe` not found\n' +
                                    '  |\n' +
                                    '  = note: program not found\n' +
                                    '\n' +
                                    'note: the msvc targets depend on the msvc linker but `link.exe` was not found\n' +
                                    '\n' +
                                    'note: please ensure that Visual Studio 2017 or later, or Build Tools for Visual Studio were installed with the Visual C++ option.\n' +
                                    '\n' +
                                    'note: VS Code is a different product, and is not sufficient.'}
                            </SyntaxHighlighter>
                            trebuie instalat:
                            <b>Build Tools for Visual Studio 2019!</b>
                        </li>
                    </ul>
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WebpackReactContent;