import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class DataToRouteAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-data-to-route", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Trimitere date statice unei rute</b>
                    <br/>
                    <br/>

                    Se foloseste <b>data</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{ path: \'not-found\', component: ErrorComponent, data: {message:\'Pagina n-a fost gasita\'} } '}
                    </SyntaxHighlighter>

                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, OnInit } from \'@angular/core\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'app-page-not-found\',\n' +
                            '  templateUrl: \'./page-not-found.component.html\',\n' +
                            '  styleUrls: [\'./page-not-found.component.css\']\n' +
                            '})\n' +
                            'export class PageNotFoundComponent implements OnInit {\n' +
                            '\n' +
                            '  errorMessage: string' +
                            '\n' +
                            '  constructor(private route:ActivatedRoute) { }\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    // this.errorMessage = this.route.snapshot.data["message];' +
                            '    this.route.data.substribe( (data: Data) => { this.errorMessage = data["message"]})' +
                            '  }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Trimitere date dinamice unei rute</b>
                    <br/>
                    <br/>
                    Inainte de se duce pe alta ruta, se obtin date in mod dinamic. Pentru aceasta se foloseste un <b>Resolver</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from \'@angular/router\';\n' +
                            'import { Observable } from \'rxjs/Observable\';\n' +
                            'import { Injectable } from \'@angular/core\';\n' +
                            '\n' +
                            'import { ServersService } from \'../servers.service\';\n' +
                            '\n' +
                            'interface Server {\n' +
                            '  id: number;\n' +
                            '  name: string;\n' +
                            '  status: string;\n' +
                            '}\n' +
                            '\n' +
                            '@Injectable()\n' +
                            'export class ServerResolver implements Resolve<Server> {\n' +
                            '  constructor(private serversService: ServersService) {}\n' +
                            '\n' +
                            '  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Server> | Promise<Server> | Server {\n' +
                            '    return this.serversService.getServer(+route.params[\'id\']);\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Apoi se foloseste <b>resolve</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{ path: \'not-found\', component: ServerComponent, resolve: {server:ServerResolver} } '}
                    </SyntaxHighlighter>

                    In plus trebuie declarate in <i>module.ts</i>, in sectiunea <b>providers</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'providers:[ServerResolver]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, OnInit } from \'@angular/core\';\n' +
                            'import { ActivatedRoute, Params, Router, Data } from \'@angular/router\';\n' +
                            '\n' +
                            'import { ServersService } from \'../servers.service\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'app-server\',\n' +
                            '  templateUrl: \'./server.component.html\',\n' +
                            '  styleUrls: [\'./server.component.css\']\n' +
                            '})\n' +
                            'export class ServerComponent implements OnInit {\n' +
                            '  server: {id: number, name: string, status: string};\n' +
                            '\n' +
                            '  constructor(private serversService: ServersService,\n' +
                            '              private route: ActivatedRoute,\n' +
                            '              private router: Router) {\n' +
                            '  }\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    this.route.data\n' +
                            '      .subscribe(\n' +
                            '        (data: Data) => {\n' +
                            '          this.server = data[\'server\'];\n' +
                            '        }\n' +
                            '      );\n' +
                            '    // const id = +this.route.snapshot.params[\'id\'];\n' +
                            '    // this.server = this.serversService.getServer(id);\n' +
                            '    // this.route.params\n' +
                            '    //   .subscribe(\n' +
                            '    //     (params: Params) => {\n' +
                            '    //       this.server = this.serversService.getServer(+params[\'id\']);\n' +
                            '    //     }\n' +
                            '    //   );\n' +
                            '  }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DataToRouteAngularContent;