import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StructGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-struct", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Struct</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'type persoana struct {\n	' +
                            'nume    string\n	' +
                            'prenume string\n}' +
                            '\n' +
                            '\n' +
                            'func main() {\n	' +
                            'yo1 := persoana{"ion", "grigore"}\n	' +
                            'yo2 := persoana{nume: "aka", prenume: "akautzu"}\n\n	' +
                            'fmt.Println(yo1, yo2)\n}'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter>
                        {'import "fmt"\n\ntype persoana struct {\n	nume    string\n	' +
                            'prenume string\n	' +
                            'varsta  int\n}\n\n' +
                            'func main() {\n	' +
                            'yo1 := persoana{"ion", "grigore", 7} // in acest caz e obligatoriu sa defim toate valorile\n	' +
                            'yo2 := persoana{nume: "ion", prenume: "grig"}\n\n	' +
                            'var p persoana\n	' +
                            'fmt.Println(yo1) // {ion grigore 7}\n	' +
                            'fmt.Println(yo2) // {ion grig 0}\n	' +
                            'fmt.Println(p) // { 0}\n}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'fmt.Printf("%+v", p) // {nume: prenume: varsta:0}'}
                    </SyntaxHighlighter>

                    Valori implicite pentru fiecare tip:
                    <ul>
                        <li>
                            string: ""
                        </li>
                        <li>
                            int: 0
                        </li>
                        <li>
                            float: 0
                        </li>
                        <li>
                            bool: false
                        </li>
                    </ul>

                    Modificare valoare camp:
                    <SyntaxHighlighter>
                        {'p.nume = "aka"'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alt exemplu:
                    <SyntaxHighlighter>
                        {'type contact struct {\n	' +
                            'email   string\n	' +
                            'telefon string\n}\n' +
                            'type persoana struct {\n	' +
                            'nume    string\n	' +
                            'prenume string\n	' +
                            'varsta  int\n	' +
                            'contact contact // daca numele campului coincide cu numele tipului se poate scrie doar: contact\n}\n\n' +
                            'func main() {\n\n	' +
                            'p := persoana{\n		' +
                            'nume:    "aka",\n		' +
                            'prenume: "akautzu",\n		' +
                            'varsta:  30,\n		' +
                            'contact: contact{\n			' +
                            'email:   "a",\n			' +
                            'telefon: "1233",\n		},\n	}\n	' +
                            'fmt.Printf("%+v", p) // {nume:aka prenume:akautzu varsta:30 contact:{email:a telefon:1233}}\n}'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>, este obligatorie chiar si dupa ultimul camp</li>
                    </ul>


                    <hr/>
                    <b>Pointeri</b>
                    <br/>
                    <br/>

                    Fie (<b>se transmite o copie a valorii</b>; poate fi o primitiva sau struct - exista si tipuri referinta (map, slice, channels, pointeri, functii)):
                    <SyntaxHighlighter>
                        {'func inc(x int) {\n	x++\n}'}
                    </SyntaxHighlighter>
                    cu
                    <SyntaxHighlighter>
                        {'x := 1\n	' +
                            'inc(x)\n	' +
                            'println(x) // va afisa 1'}
                    </SyntaxHighlighter>

                    Fie (se trimite o referinta/pointer):
                    <SyntaxHighlighter>
                        {'func inc(x *int) {\n	*x++\n}'}
                    </SyntaxHighlighter>
                    cu
                    <SyntaxHighlighter>
                        {'x := 1\n' +
                            'inc(&x)\n' +
                            'println(x) // va afisa 2'}
                    </SyntaxHighlighter>

                    Explicatii:
                    <ul>
                        <li>
                            <b>&variabila</b>: returneaza adresa din memorie/pointerul pentru valoare variabilei
                            <SyntaxHighlighter>
                                {'inc(&x)'}
                            </SyntaxHighlighter>
                            deci cu &valoare = valoare --&--> adresa
                        </li>
                        <li>
                            <b>*pointer</b>: returnaza valoarea adresei de memorie la care pointeaza <i>pointer</i>

                            <SyntaxHighlighter>
                                {'func inc(x *int) {\n	' +
                                    '*x++\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            deci cu *adresa: adresa --*--> valoare
                            <ul>
                                <li>
                                    <b> * in fata unui tip</b> inseamna: pointer la o valoare de tipul <i>tip</i>
                                </li>
                                <li>
                                    <b>* in fata unei variabile de tip pointer</b> inseamna: ca vrem sa manipulam valoare/continutul la care se refera pointerul
                                </li>
                            </ul>

                        </li>
                    </ul>

                    Pentru structuri complexe, se poate omite <b>&</b> (Go va stii sa transforme in referinta daca se asteapta sa primeasca o referinta):
                    <SyntaxHighlighter>
                        {'func changeName(p *persoana) {\n	(*p).nume = "kj"\n}\n\nfunc main() {\n\n	p := persoana{\n		nume:    "aka",\n		prenume: "akautzu",\n		varsta:  30,\n		contact: contact{\n			email:   "a",\n			telefon: "1233",\n		},\n	}\n\n	changeName(&p)\n	fmt.Printf("%+v", p) // {nume:aka prenume:akautzu varsta:30 contact:{email:a telefon:1233}}\n}'}
                    </SyntaxHighlighter>

                    Tipuri referinta:
                    <ul>
                        <li>slice (array cu fara dimensiune fixa)</li>
                        <li>
                            map
                        </li>
                        <li>
                            channels
                        </li>
                        <li>
                            pointeri
                        </li>
                        <li>
                            functii
                        </li>

                        Tipuri valore (se trimit prin copierea valorii):
                        <ul>
                            <li>
                                int
                            </li>
                            <li>
                                float
                            </li>
                            <li>
                                string
                            </li>
                            <li>
                                bool
                            </li>
                            <li>
                                structs
                            </li>
                        </ul>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StructGoContent;