import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ByteCodeJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-bytecode", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>
                        Bytecode: Hello World!
                    </b>
                    <br/>
                    <br/>

                    Când un program Java este compilat (<b>javac</b>), se obtine un fișier de clasă (<b>.class</b>).
                    <br/>
                    Acest fișier de clasă este codul de octeți Java.
                    <br/>
                    Este un fișier de date binar care conține instrucțiuni pentru ca mașina virtuală Java să execute programul.

                    <br/>
                    <br/>
                    Structura unui fișier de clasă
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ClassFile { \n' +
                            '    4 octeți Java Magic Număr \n' +
                            '    2 octeți Versiune minoră \n' +
                            '    2 octeți Versiune majoră \n' +
                            '    2 octeți Mărimea pool-ului constant \n' +
                            '    * octeți Numeroși octeți care alcătuiesc pool-ul constant \n' +
                            '    2 octeți Modificatori de acces la această clasă (adică public) \n' +
                            '    2 octeți Indexul acestei clase în grup constant \n' +
                            '    2 octeți Indexul superclasei acestei clase în grupul constant \n' +
                            '    2 octeți Număr de interfețe \n' +
                            '    * octeți Numeroși octeți care alcătuiesc definițiile interfeței \n' +
                            '    2 octeți Număr de câmpuri din această clasă \n' +
                            '    * octeți Numeroși octeți care formează definițiile câmpurilor \n' +
                            '    2 octeți Numărul de metode din această clasă clasă\n' +
                            '    * octeți Numeroși octeți care alcătuiesc definițiile metodei \n' +
                            '    2 octeți Număr de atribute ( metadate pentru fișierul de clasă ) \n' +
                            '    * octeți Numeroși octeți care alcătuiesc definițiile de atribute \n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Număr magic</b>
                    <br/>
                    <br/>

                    Sunt 4 octeți care sunt întotdeauna la începutul fișierului.
                    <br/>
                    Aceasta indică faptul că este un fișier de clasă Java.
                    <br/>
                    Cei 4 octeți sunt <b>CA FE BA BE</b>.

                    <hr/>
                    <b>2. Versiune</b>
                    <br/>
                    <br/>
                    Următorii 4 octeți sunt două construcții de 2 octeți care alcătuiesc versiunea.
                    <br/>
                    Pentru Java 8, versiunea ar fi 52.0 (52 in octa ar fi 34), deci cei 4 octeti sunt <b>00 00 00 34</b>.

                    <hr/>
                    <b>3. Mărimea pool-ului constant</b>
                    <br/>
                    <br/>
                    Următorii 2 octeți sunt foarte importanți și, de asemenea, puțin greu de obținut la prima încercare.
                    Ele indică dimensiunea pool-ului constant.
                    <br/>
                    După cei 2 octeți pentru dimensiunea constantă a pool-ului, vine pool-ul constant.
                    Aceasta este de lungime variabilă de octeți și depinde de datele conținute.
                    <br/>
                    Fiecare intrare începe cu o etichetă și, pe baza etichetei, știm câți octeți va avea eticheta.
                    <br/>
                    Acest lucru este valabil pentru alte zone cu lungime variabilă.

                    <hr/>
                    <b>4. Modificatori de acces (2 octeti)</b>
                    <br/>
                    <br/>

                    Exista modificatorii de acces,  pe baza unei combinații a următoarelor:
                    <SyntaxHighlighter>
                        {'ACC_PUBLIC     0x0001 \n' +
                            'ACC_FINAL      0x0010\n' +
                            'ACC_SUPER      0x0020 ( Not final, can be extended )\n' +
                            'ACC_INTERFACE  0x0200\n' +
                            'ACC_ABSTRACT   0x0400\n' +
                            'ACC_SYNTHETIC  0x1000 ( Not present in source code. Generated )\n' +
                            'ACC_ANNOTATION 0x2000\n' +
                            'ACC_ENUM       0x4000'}
                    </SyntaxHighlighter>

                    Pentru programul nostru Hello World putem merge doar la <b>00 21</b> ( Super Public: 0x0020 + 0x0001 = 0x0021 = 00 21).

                    <hr/>
                    <b>5. Referințe de grup constant de clasă (4 octeti)</b>
                    <br/>
                    <br/>

                    Indicii de clasă din pool-ul constant. 2 octeți fiecare.
                    <br/>
                    Prima este referința la această clasă, iar a doua este super-clasa.
                    <br/>
                    Toate clasele au o super clasă, chiar dacă nu o declari, caz în care este java/lang/Object.

                    <hr/>
                    <b>6. Restul, interfețe, câmpuri, metode și atribute</b>
                    <br/>
                    <br/>
                    Aceste secțiuni sunt toate structurate similar cu pool-ul constant.
                    <br/>
                    Fiecare secțiune, fie că este vorba de metode sau câmpuri etc., începe cu 2 octeți care indică dimensiunea sa.
                    Apoi un număr variabil de octeți care definesc datele.
                    Spre deosebire de pool-ul constant, octeții de dimensiune indică numărul real de intrări, nu dimensiunea intrărilor -1.

                    <br/>
                    Datele de octeți variabili sunt similare cu datele variabilelor de grup constant,
                    fiind că fiecare intrare începe cu o etichetă care indică câte date urmează să vină și de ce tip.

                    <br/>

                    Pentru ca vom folosi doar metode pentru acest program și le vom lăsa goale pe celelalte.
                    <br/>
                    Pentru a lăsa o secțiune goală, dați-i o dimensiune de zero și fără alți octeți. În esență, doar 0000.

                    <hr/>
                    <b>7. Hello World</b>

                    Se poate scrie in <b>Notepad++</b>, folosind Plugin-ul <b>Hex-Editor</b>
                    (instalarea se face in Plugins {">"} Plugins Admin / apoi folosirea Plugins {">"} Hex-Editor)

                    <br/>

                    Incepem sa scriem prima clasa:
                    <SyntaxHighlighter>
                        {'cafenea babe 0000 0034 0000 0021 0000 0000 0000 0000 0000 0000'}
                    </SyntaxHighlighter>
                    Ce am scris mai sus?
                    <br/>
                    "Sunt un fișier Java 8 Class care este Super Public, indecși de clasă nevalizi, cu 0 interfețe, 0 câmpuri, 0 metode și 0 atribute"
                    <br/>
                    Sau:
                    <SyntaxHighlighter>
                        {'Fișier Java: CAFE BABE\n' +
                            'Versiunea 8: 0000 0034\n' +
                            'Dimensiunea constantă a piscinei de ZERO: 0000\n' +
                            'Super Public: 0021\n' +
                            'Index necunoscut al clasei în pool constant: 0000\n' +
                            'Index necunoscut al super-clasei în pool constant: 0000\n' +
                            'zero interfețe: 0000\n' +
                            'câmpuri zero: 0000\n' +
                            'metode zero: 0000\n' +
                            'zero atribute: 0000'}
                    </SyntaxHighlighter>

                    Daca salvam fisierul ca HelloWorld.class si rulam:
                    <SyntaxHighlighter>
                        {'javap .\\HelloWorld.class'}
                    </SyntaxHighlighter>
                    vom obtine eroarea:
                    <SyntaxHighlighter>
                        {'Error: invalid index #0\n' +
                            'public class ??? {\n' +
                            '}'}
                    </SyntaxHighlighter>
                    semn ca inca nu e inca complet codul!

                    <br/>
                    Daca incerca sa se ruleze:
                    <SyntaxHighlighter>
                        {'java .\\HelloWorld'}
                    </SyntaxHighlighter>
                    se va optine
                    <SyntaxHighlighter>
                        {'Error: Could not find or load main class .\\HelloWorld\n' +
                            'Caused by: java.lang.ClassNotFoundException: /\\HelloWorld'}
                    </SyntaxHighlighter>
                    Semn ca nu avem metoda <b>main</b>!

                    <hr/>
                    <b>7.1. Adăugarea numelui clasei HelloWorld</b>
                    <br/>
                    <br/>
                    Trebuie sa adaugam in pool-ul de constante clasa si numele clasei.

                    Etichete:
                    <ul>
                        <li><b>07</b> - pentru clasa;
                            <br/>
                            Intrarea constantă a grupului de clasă este de trei octeți.
                            1 pentru etichetă și 2 pentru un index care indică către o intrare UTF8 din pool-ul constant
                            <SyntaxHighlighter>
                                {'-- Class at index 2\n' +
                                    '07 00 02'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>01</b> - intrare UTF8;
                            eticheta este urmata doi octeți care indică dimensiunea în octeți a șirului UTF8;
                            <br/>
                            acea dimensiune nu este dimensiunea șirului, ci numărul de octeți din șirul UTF8;
                            Caracterele extinse vor lua mai mult de 1 octet; deocamdata vom lucra doar cu caractere de 1 octet;
                            <br/>
                            deci:"HelloWorld" are 10 caractere = 10 octeti;
                            <br/>
                            iar intrarea ar arata cam asa:
                            <SyntaxHighlighter>
                                {'-- UTF8 10 bytes    H  e  l  l  o  W  o  r  l  d\n' +
                                    '01 00 0a            48 65 6c 6c 6f 57 6f 72 6c 64'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Se adauga:
                    <SyntaxHighlighter>
                        {'0003\n' +
                            '0700 02 \n' +
                            '0100 0a 48 65 6c 6c 6f 57 6f 72 6c 64'}
                    </SyntaxHighlighter>
                    la:
                    <SyntaxHighlighter>
                        {'cafe babe 0000 0034\n' +
                            '0003\n' +
                            '0700 02 \n' +
                            '0100 0a 48 65 6c 6c 6f 57 6f 72 6c 64\n' +
                            '0021 0001 0000\n' +
                            '0000 0000 0000 0000'}
                    </SyntaxHighlighter>
                    Observați că dimensiunea pool-ului nostru constant este 0003?
                    Asta pentru că dimensiunea constantă a piscinei este întotdeauna cu 1 mai mare decât dimensiunea reală.

                    <br/>
                    <br/>
                    Deci avem:
                    <SyntaxHighlighter>
                        {'ca fe ba be 00 00 00 34 00 03 07 00 02 01 00 0a 48 65 6c 6c 6f 57 6f 72 6c 64 00 21 00 01 00 00 00 00 00 00 00 00 00 20'}
                    </SyntaxHighlighter>

                    Rulare:
                    <SyntaxHighlighter>
                        {'javap .\\HelloWorld2_2.class\n' +
                            'public class HelloWorld {\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Clasa are un nume!

                    <SyntaxHighlighter>
                        {' javap -v .\\HelloWorld2.class\n' +
                            'Classfile /D:/work-resources/learn/java/HelloWorld2.class\n' +
                            '  Last modified 15 Jun 2023; size 40 bytes\n' +
                            '  SHA-256 checksum 768c11178602f19af1aecb5f29ddb71837856f73049758b6ce4ebc0b58f3cb5e\n' +
                            'public class HelloWorld\n' +
                            '  minor version: 0\n' +
                            '  major version: 52\n' +
                            '  flags: (0x0021) ACC_PUBLIC, ACC_SUPER\n' +
                            '  this_class: #1                          // HelloWorld\n' +
                            '  super_class: #0\n' +
                            '  interfaces: 0, fields: 0, methods: 0, attributes: 0\n' +
                            'Constant pool:\n' +
                            '  #1 = Class              #2              // HelloWorld\n' +
                            '  #2 = Utf8               HelloWorld\n' +
                            '{\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <hr/>
                    <b>Adăugarea Super Class</b>
                    <br/>
                    <br/>
                    Clasa noastra extinde in mod implicit clasa Object.
                    <br/>
                    Se adauga la fel ca o intrare de tip clasa: tag (07) + index pe 2 bytes.
                    <SyntaxHighlighter>
                        {' addClass(constantPool, (byte)0x07, convert((short) 4));\n' +
                            'addUtf8(constantPool, (byte)0x01, "java/lang/Object");'}
                    </SyntaxHighlighter>
                    Pe pozitia 4 de adauga numele superclasei: <i>java/lang/Object</i> (se foloseste / in loc de .)

                    <hr/>
                    <b>Adăugarea alte clase</b>
                    <br/>
                    <br/>

                    Avand in minte:
                    <SyntaxHighlighter>
                        {'System.out.println("Hello World");'}
                    </SyntaxHighlighter>

                    Adaugam:
                    <SyntaxHighlighter>
                        {'addClass(constantPool, (byte)0x07, convert((short) 6));\n' +
                            'addUtf8(constantPool, (byte)0x01, "java/lang/System"); // System.out.println("Hello World");\n' +
                            '\n' +
                            'addClass(constantPool, (byte)0x07, convert((short) 8));\n' +
                            'addUtf8(constantPool, (byte)0x01, "java/io/PrintStream"); // System.out.println("Hello World"); out este de tip PrintStream' }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Intrare de tip constanta String</b>
                    <br/>
                    <br/>

                    O intrare de tip constanta String are tag-ul 08:
                    <SyntaxHighlighter>
                        {'addClass(constantPool, (byte)0x08, convert((short) 10)); // 08 este intrare de tip constanta String\n' +
                            'addUtf8(constantPool, (byte)0x01, "KJ e aici!"); // System.out.println("Hello World"); out este de tip PrintStream'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>Referinta la o variabila statica</b>
                    <br/>
                    <br/>

                    Avand in minte:
                    <SyntaxHighlighter>
                        {'System.out.println("Hello World");'}
                    </SyntaxHighlighter>
                    Pentru a adauga in pool, <i>out</i>, se vor adauga 4 noi intrari:
                    <ul>
                        <li>[index 11] o intrare de tip <b>camp referinta (tag 09 / Fieldref)</b> - 5 bytes:
                            <ul>
                                <li>1 byte - tag (09) / Fieldref</li>
                                <li>2 bytes - index referinta tip de care apartine (catre System - indexul 5 - in cazul nostru)</li>
                                <li>2 bytes - index referinta nume si tip <b>NameAndType</b> (indexul 12 - in cazul nostru)</li>
                            </ul>
                        </li>
                        <li>
                            [index 12] o intrare de tip <b>nume si tip referinta (0c / NameAndType)</b> - 5 bytes
                            <li>
                                <li>1 byte - tag (0c) / NameAndType</li>
                                <li>2 bytes - index catre numele variabilei (index 13 - in cazul nostru)</li>
                                <li>2 bytes - index catre tipul variabilei (index 14 - in cazul nostru)</li>
                            </li>
                        </li>
                        <li>
                            [index 13] o intrare de tip Utf cu valoare <i>out</i>
                        </li>
                        <li>
                            [index 14] o intrare de tip Utf cu valoare <i>Ljava/io/PrintStream;</i>
                            <br/>
                            Clasele la care se face referire ca tipuri, încep întotdeauna cu un L!
                            <br/>
                            De asemenea, ni se cere să punem un punct și virgulă la sfârșitul acelui șir,
                            deoarece ar putea fi urmat de un alt tip atunci când definim tipurile de parametri de metodă.
                        </li>
                    </ul>

                    <hr/>
                    <b>Referinta la o metoda</b>
                    <br/>
                    <br/>

                    Avand in minte:
                    <SyntaxHighlighter>
                        {'System.out.println("Hello World");'}
                    </SyntaxHighlighter>
                    Pentru a adauga in pool, <i>println</i>, se vor adauga 4 noi intrari:
                    <ul>
                        <li>[index 15] o intrare de tip <b>camp referinta (tag 0a / Metoda )</b> - 5 bytes:
                            <ul>
                                <li>1 byte - tag (0a) / Metoda </li>
                                <li>2 bytes - index referinta tip de care apartine (catre PrintStream - indexul 7 - in cazul nostru)</li>
                                <li>2 bytes - index referinta nume si tip <b>NameAndType</b> (indexul 16 - in cazul nostru)</li>
                            </ul>
                        </li>
                        <li>
                            [index 16] o intrare de tip <b>nume si tip referinta (0c / NameAndType)</b> - 5 bytes
                            <li>
                                <li>1 byte - tag (0c) / NameAndType</li>
                                <li>2 bytes - index catre numele variabilei (index 17 - in cazul nostru)</li>
                                <li>2 bytes - index catre tipul variabilei (index 18 - in cazul nostru)</li>
                            </li>
                        </li>
                        <li>
                            [index 17] o intrare de tip Utf cu valoare <i>println</i>
                        </li>
                        <li>
                            [index 18] o intrare de tip Utf cu valoare <i>(Ljava/lang/String;)V</i>
                            <br/>
                            Formatul este: (tipParametri)TipReturn; V = Void
                        </li>
                    </ul>

                    Se vor mai adauga 3 intrari:
                    <ul>
                        <li>o intrare de tip Utf8: main (numele metodei noastre)</li>
                        <li>o intrare de tip Utf8: ([Ljava/lang/String;)V (tipul parametrului de intrare: String[] si tipul returnat de metoda main: V)</li>
                        <li>o intrare de tip Utf8: Code (atribut special; indica ca urmeaza instructiunile masinii JVM)</li>
                    </ul>

                    Pentru:
                    <SyntaxHighlighter>
                        {'public class HelloWorld {\n' +
                            '  public static void main(String[] args) {\n' +
                            '     System.out.println("KJ e aici!");\n' +
                            '  }' +
                            '}'}
                    </SyntaxHighlighter>

                    Pana acum, constant pool-ul ar arata cam asa:
                    <SyntaxHighlighter>
                        {'Constant pool:\n' +
                            '   #1 = Class              #2             // HelloWorld\n' +
                            '   #2 = Utf8               HelloWorld\n' +
                            '   #3 = Class              #4             // java/lang/Object\n' +
                            '   #4 = Utf8               java/lang/Object\n' +
                            '   #5 = Class              #6             // java/lang/System\n' +
                            '   #6 = Utf8               java/lang/System\n' +
                            '   #7 = Class              #8             // java/io/PrintStream\n' +
                            '   #8 = Utf8               java/io/PrintStream\n' +
                            '   #9 = String             #10            // KJ e aici!\n' +
                            '  #10 = Utf8               KJ e aici!\n' +
                            '  #11 = Fieldref           #5.#12         // java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '  #12 = NameAndType        #13:#14        // out:Ljava/io/PrintStream;\n' +
                            '  #13 = Utf8               out\n' +
                            '  #14 = Utf8               Ljava/io/PrintStream;\n' +
                            '  #15 = Methodref          #7.#16         // java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '  #16 = NameAndType        #17:#18        // println:(Ljava/lang/String;)V\n' +
                            '  #17 = Utf8               println\n' +
                            '  #18 = Utf8               (Ljava/lang/String;)V\n' +
                            '  #19 = Utf8               main\n' +
                            '  #20 = Utf8               ([Ljava/lang/String;)V\n' +
                            '  #21 = Utf8               Code'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Scrierea metodei principale HelloWorld</b>
                    <br/>
                    <br/>

                    Codul byte al unei metodei are urmatoarea structură:
                    <SyntaxHighlighter>
                        {'method_info {\n' +
                            '    2 bytes        Methods access flags\n' +
                            '    2 bytes        Name of method. UTF8 index in constant pool\n' +
                            '    2 bytes        Type of method. UTF8 index in constant pool\n' +
                            '    2 bytes        Number of attributes\n' +
                            '    * bytes        Variable bytes describing attribute_info structs\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Modificatori de acces (Methods access flags):
                    <SyntaxHighlighter>
                        {'ACC_PUBLIC        0x0001\n' +
                            'ACC_PRIVATE       0x0002\n' +
                            'ACC_PROTECTED     0x0004\n' +
                            'ACC_STATIC        0x0008\n' +
                            'ACC_FINAL         0x0010\n' +
                            'ACC_SYNCHRONIZED  0x0020\n' +
                            'ACC_BRIDGE        0x0040\n' +
                            'ACC_VARARGS       0x0080\n' +
                            'ACC_NATIVE        0x0100\n' +
                            'ACC_ABSTRACT      0x0400\n' +
                            'ACC_STRICT        0x0800\n' +
                            'ACC_SYNTHETIC     0x1000'}
                    </SyntaxHighlighter>

                    Pentru:
                    <SyntaxHighlighter>
                        {'public static void main(String[] args)'}
                    </SyntaxHighlighter>
                    Avem ca modificatori: public + static ===  0x0001 + 0x0008 == 0x0009

                    Deci vom avea:
                    <SyntaxHighlighter>
                        {'0009 - public static\n' +
                            '0013 - main\n' +
                            '0014 - ([Ljava/lang/String;)\n' +
                            '0001 - numar atribute size = 1\n' +
                            '0015 - index atribut Code ( in cazul nostru, indexul #21 in constant pool )'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'javap -c HelloWorld.class\n' +
                            'public class HelloWorld {\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Deci avem o metoda main goala!:)
                    <hr/>

                    Sa scriem intr-un fisier binar <b>Java Magic Number</b>: (CA FE BA BE)
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloWorldByte {\n' +
                            '\n' +
                            '    final static String PATH = "D:\\\\work\\\\java-vanilla\\\\src\\\\main\\\\resources\\\\hello-world-bytes\\\\";\n' +
                            '\n' +
                            '    final static byte[] JAVA_MAGIC_NUMBER = {\n' +
                            '            (byte) 0xCA,\n' +
                            '            (byte) 0xFE,\n' +
                            '            (byte) 0xBA,\n' +
                            '            (byte) 0xBE}; // Java Magic Number / 4 biti / CA FE BA BE\n' +
                            '\n' +
                            '    public static void main(String arg[]) {\n' +
                            '\n' +
                            '        Path pathToClass = Paths.get(PATH + "HelloWorld.class");\n' +
                            '        \n' +
                            '        try {\n' +
                            '            Files.write(pathToClass, JAVA_MAGIC_NUMBER);    // Java 7+ only\n' +
                            '        } catch (IOException e) {\n' +
                            '            e.printStackTrace();\n' +
                            '        }\n' +
                            '\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Reamintim ca in numarul 52 in hexa este 34!

                    <hr/>
                    Programul intreg:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.jvanilla.hellowordbyte;\n' +
                            '\n' +
                            'import java.io.ByteArrayOutputStream;\n' +
                            'import java.io.IOException;\n' +
                            'import java.nio.file.Files;\n' +
                            'import java.nio.file.Path;\n' +
                            'import java.nio.file.Paths;\n' +
                            'import java.util.ArrayList;\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            'public class HelloWorldByte {\n' +
                            '\n' +
                            '    final static String PATH = "D:\\\\work\\\\java-vanilla\\\\src\\\\main\\\\resources\\\\hello-world-bytes\\\\";\n' +
                            '\n' +
                            '    final static byte[] JAVA_MAGIC_NUMBER = {\n' +
                            '            (byte) 0xCA,\n' +
                            '            (byte) 0xFE,\n' +
                            '            (byte) 0xBA,\n' +
                            '            (byte) 0xBE}; // Java Magic Number / 4 bytes / CA FE BA BE\n' +
                            '\n' +
                            '    final static byte[] JAVA_VERSION = {\n' +
                            '            (byte) 0x00,\n' +
                            '            (byte) 0x00,\n' +
                            '            (byte) 0x00,\n' +
                            '            (byte) 0x34,\n' +
                            '    }; // Version Java 8 // adica 52.0 (in hexa este 34) /  2 bytes Minor Version / 2 bytes  Major Version\n' +
                            '\n' +
                            '//    static byte[] constantPoolSize = {\n' +
                            '//            (byte) 0x00,\n' +
                            '//            (byte) 0x00,\n' +
                            '//    }; // Size of the constant pool / 2 bytes, deci maxim 65,535 de constante pot exista intr-o aplicatie java\n' +
                            '\n' +
                            '    static short constantPoolSize = 0;\n' +
                            '\n' +
                            '    static short indexClassInConstantPool = 1; // 0 inseamna ca nu e cunoscut\n' +
                            '    static short indexSuperClassInConstantPool = 3; // 0 inseamna ca nu e cunoscut\n' +
                            '    static short numberOfInterfaces = 0;\n' +
                            '    static short numberOfFields = 0;\n' +
                            '    static short numberOfMethods = 1;\n' +
                            '    static short attributesCount = 0;\n' +
                            '\n' +
                            '    final static short ACC_PUBLIC = 0x0001;\n' +
                            '    final static short ACC_FINAL = 0x0010;\n' +
                            '    final static short ACC_SUPER = 0x0020;\n' +
                            '    final static short ACC_INTERFACE = 0x0200;\n' +
                            '    final static short ACC_ABSTRACT = 0x0400;\n' +
                            '    final static short ACC_SYNTHETIC = 0x1000;\n' +
                            '    final static short ACC_ANNOTATION = 0x2000;\n' +
                            '    final static short ACC_ENUM = 0x4000;\n' +
                            '\n' +
                            '    static final short SUPER_PUBLIC = ACC_SUPER | ACC_PUBLIC;\n' +
                            '\n' +
                            '    static List<byte[]> constantPool = new ArrayList<>();\n' +
                            '\n' +
                            '    public static void main(String arg[]) throws IOException {\n' +
                            '\n' +
                            '        Path pathToClass = Paths.get(PATH + "HelloWorld.class");\n' +
                            '\n' +
                            '        try {\n' +
                            '\n' +
                            '            // 1\n' +
                            '            addClass(constantPool, (byte)0x07, convert((short) 2)); // o intrare pentru o clasa (de tip clasa) in constant pool e pe 3 bytes:\n' +
                            '                                      // 1 pentru tag = 07\n' +
                            '                                      // 2 pentru index care pointeaza catre o intrare de tip UTF8; de exemplu 2\n' +
                            '\n' +
                            '            // 2\n' +
                            '            addUtf8(constantPool, (byte)0x01, "HelloWorld"); // o intrare de tip UTF8:\n' +
                            '                                      // 1 pentru tag = 01\n' +
                            '                                      // numarul de bytes retinuti => 2 bytes, deci short\n' +
                            '                                      // string-ul in sine\n' +
                            '\n' +
                            '            // 3\n' +
                            '            addClass(constantPool, (byte)0x07, convert((short) 4));\n' +
                            '            addUtf8(constantPool, (byte)0x01, "java/lang/Object");\n' +
                            '\n' +
                            '            //5\n' +
                            '            addClass(constantPool, (byte)0x07, convert((short) 6));\n' +
                            '            addUtf8(constantPool, (byte)0x01, "java/lang/System"); // System.out.println("Hello World");\n' +
                            '\n' +
                            '            // 7\n' +
                            '            addClass(constantPool, (byte)0x07, convert((short) 8));\n' +
                            '            addUtf8(constantPool, (byte)0x01, "java/io/PrintStream"); // System.out.println("Hello World"); out este de tip PrintStream\n' +
                            '\n' +
                            '            // 9\n' +
                            '            addString(constantPool, (byte)0x08, convert((short) 10)); // 08 este intrare de tip constanta String\n' +
                            '            addUtf8(constantPool, (byte)0x01, "KJ e aici!"); // System.out.println("Hello World"); out este de tip PrintStream\n' +
                            '\n' +
                            '            // 11\n' +
                            '            addFieldRef(constantPool, (byte)0x09, convert((short) 5), convert((short) 12) ); // 09 este intrare de tip FieldRef [java/lang/System, ]\n' +
                            '            addNameAndType(constantPool, (byte)0x0c, convert((short) 13), convert((short) 14) );\n' +
                            '            addUtf8(constantPool, (byte)0x01, "out");\n' +
                            '            addUtf8(constantPool, (byte)0x01, "Ljava/io/PrintStream;");\n' +
                            '\n' +
                            '            // 15\n' +
                            '            addMethodref(constantPool, (byte)0x0a, convert((short) 7), convert((short) 16) );\n' +
                            '            addNameAndType(constantPool, (byte)0x0c, convert((short) 17), convert((short) 18) );\n' +
                            '            addUtf8(constantPool, (byte)0x01, "println");\n' +
                            '            addUtf8(constantPool, (byte)0x01, "(Ljava/lang/String;)V");\n' +
                            '\n' +
                            '            //19\n' +
                            '            addUtf8(constantPool, (byte)0x01, "main");\n' +
                            '            addUtf8(constantPool, (byte)0x01, "([Ljava/lang/String;)V");\n' +
                            '            addUtf8(constantPool, (byte)0x01, "Code");\n' +
                            '\n' +
                            '            constantPoolSize = (short)(constantPool.size()+1);\n' +
                            '\n' +
                            '            ByteArrayOutputStream programStream = new ByteArrayOutputStream();\n' +
                            '            programStream.write(JAVA_MAGIC_NUMBER);\n' +
                            '            programStream.write(JAVA_VERSION);\n' +
                            '            programStream.write(convert(constantPoolSize));  // convertesc un short (2 bytes) -> array de 2 bytes; trebuie sa fie " + 1"\n' +
                            '            for(byte[] entry: constantPool){\n' +
                            '                programStream.write(entry);\n' +
                            '            }\n' +
                            '            programStream.write(convert(SUPER_PUBLIC));\n' +
                            '            programStream.write(convert(indexClassInConstantPool));\n' +
                            '            programStream.write(convert(indexSuperClassInConstantPool));\n' +
                            '            programStream.write(convert(numberOfInterfaces));\n' +
                            '            programStream.write(convert(numberOfFields));\n' +
                            '            programStream.write(convert(numberOfMethods));\n' +
                            '\n' +
                            '            programStream.write(convert((short)9)); // 0009 - public static\n' +
                            '            programStream.write(convert((short)19)); // index main in constant pool - index 19\n' +
                            '            programStream.write(convert((short)20)); // index [Ljava/lang/String; in constant pool- index 20\n' +
                            '            programStream.write(convert((short)1)); // attribute size = 1\n' +
                            '            programStream.write(convert((short)21)); // Code Attribute (  index #21 in constant pool; adica catre Code )\n' +
                            '            programStream.write(intToBytes(21));// dimensiune Code Atribute => 21 bytes (4 bytes; un int are 4 bytes)\n' +
                            '\n' +
                            '            // 21 bytes of code attribute:\n' +
                            '            programStream.write(convert((short)2)); // Max stack size =  2\n' +
                            '            programStream.write(convert((short)1));// Max local var size = 1\n' +
                            '            programStream.write(intToBytes(9));// Size of code. 9 bytes\n' +
                            '            //Instrucțiunile actuale ale mașinii:\n' +
                            '\n' +
                            '            programStream.write((byte)0xb2); // b2 = getstatic + index catre #11 in constant poll (out)\n' +
                            '            programStream.write(convert((short)11));\n' +
                            '\n' +
                            '            programStream.write((byte)0x12); // 12 = ldc (load constant) + index catre #9 in constant poll ("KJ e aici!")\n' +
                            '            programStream.write((byte)0x09);\n' +
                            '\n' +
                            '            programStream.write((byte)0xb6);// b6 = invokevirtual + index catre #15\n' +
                            '            programStream.write(convert((short)15));\n' +
                            '\n' +
                            '            programStream.write((byte)0xb1); // b1 = return void\n' +
                            '\n' +
                            '            programStream.write(convert((short)0)); // Tabel de excepții cu dimensiunea = 0\n' +
                            '            programStream.write(convert((short)0)); // Numărul de atribute pentru acest atribut = 0\n' +
                            '\n' +
                            '            programStream.write(convert(attributesCount));\n' +
                            '\n' +
                            '            byte[] result = programStream.toByteArray();\n' +
                            '\n' +
                            '            Files.write(pathToClass, result);\n' +
                            '\n' +
                            '            System.out.println("Programul = "+ new String(result));\n' +
                            '\n' +
                            '        } catch (IOException e) {\n' +
                            '            e.printStackTrace();\n' +
                            '        }\n' +
                            '\n' +
                            '        //https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    private static void addClass(List<byte[]> constantPool, byte tag, byte[] index){\n' +
                            '        constantPool.add(concatenate(new byte[]{tag}, index));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static void addString(List<byte[]> constantPool, byte tag, byte[] index){\n' +
                            '        constantPool.add(concatenate(new byte[]{tag}, index));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static void addFieldRef(List<byte[]> constantPool, byte tag, byte[] indexRef, byte[] indexNameType) throws IOException {\n' +
                            '        constantPool.add(concatenate(new byte[]{tag}, indexRef, indexNameType));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static void addMethodref(List<byte[]> constantPool, byte tag, byte[] indexRef, byte[] indexNameType) throws IOException {\n' +
                            '        constantPool.add(concatenate(new byte[]{tag}, indexRef, indexNameType));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static void addNameAndType(List<byte[]> constantPool, byte tag, byte[] indexRef, byte[] indexNameType) throws IOException {\n' +
                            '        constantPool.add(concatenate(new byte[]{tag}, indexRef, indexNameType));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static void addUtf8(List<byte[]> constantPool, byte tag, String text) throws IOException {\n' +
                            '        byte [] textBytes = text.getBytes();\n' +
                            '        constantPool.add(concatenate(new byte[]{tag}, convert((short)textBytes.length), textBytes));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static byte[] concatenate(byte[] a, byte[] b){\n' +
                            '        byte[] c = new byte[a.length + b.length];\n' +
                            '        System.arraycopy(a, 0, c, 0, a.length);\n' +
                            '        System.arraycopy(b, 0, c, a.length, b.length);\n' +
                            '        return c;\n' +
                            '    }\n' +
                            '\n' +
                            '    private static byte[] concatenate(byte[] ... list) throws IOException {\n' +
                            '        ByteArrayOutputStream byteArrayOutputStream = new ByteArrayOutputStream();\n' +
                            '        for(byte[] b: list){\n' +
                            '            byteArrayOutputStream.write(b);\n' +
                            '        }\n' +
                            '        return byteArrayOutputStream.toByteArray();\n' +
                            '    }\n' +
                            '\n' +
                            '    public static byte[] convert(short value) {\n' +
                            '        return new byte[]{\n' +
                            '                (byte) ((value >> 8) & 0xff),\n' +
                            '                (byte) (value & 0xff)\n' +
                            '        };\n' +
                            '    }\n' +
                            '\n' +
                            '    private static byte[] intToBytes(final int data) {\n' +
                            '        return new byte[] {\n' +
                            '                (byte)((data >> 24) & 0xff),\n' +
                            '                (byte)((data >> 16) & 0xff),\n' +
                            '                (byte)((data >> 8) & 0xff),\n' +
                            '                (byte)((data >> 0) & 0xff),\n' +
                            '        };\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'javap -c HelloWorld.class\n' +
                            'public class HelloWorld {\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    Code:\n' +
                            '       0: getstatic     #11                 // Field java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '       3: ldc           #9                  // String KJ e aici!\n' +
                            '       5: invokevirtual #15                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '       8: return\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'>javap -v HelloWorld.class\n' +
                            'Classfile /D:/work/java-vanilla/src/main/resources/hello-world-bytes/HelloWorld.class\n' +
                            '  Last modified 17 Jun 2023; size 283 bytes\n' +
                            '  SHA-256 checksum 1896b77c4303c53d72074c177035bdf3d6c70c66ddb00245d5ffaaa5448c4046\n' +
                            'public class HelloWorld\n' +
                            '  minor version: 0\n' +
                            '  major version: 52\n' +
                            '  flags: (0x0021) ACC_PUBLIC, ACC_SUPER\n' +
                            '  this_class: #1                          // HelloWorld\n' +
                            '  super_class: #3                         // java/lang/Object\n' +
                            '  interfaces: 0, fields: 0, methods: 1, attributes: 0\n' +
                            'Constant pool:\n' +
                            '   #1 = Class              #2             // HelloWorld\n' +
                            '   #2 = Utf8               HelloWorld\n' +
                            '   #3 = Class              #4             // java/lang/Object\n' +
                            '   #4 = Utf8               java/lang/Object\n' +
                            '   #5 = Class              #6             // java/lang/System\n' +
                            '   #6 = Utf8               java/lang/System\n' +
                            '   #7 = Class              #8             // java/io/PrintStream\n' +
                            '   #8 = Utf8               java/io/PrintStream\n' +
                            '   #9 = String             #10            // KJ e aici!\n' +
                            '  #10 = Utf8               KJ e aici!\n' +
                            '  #11 = Fieldref           #5.#12         // java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '  #12 = NameAndType        #13:#14        // out:Ljava/io/PrintStream;\n' +
                            '  #13 = Utf8               out\n' +
                            '  #14 = Utf8               Ljava/io/PrintStream;\n' +
                            '  #15 = Methodref          #7.#16         // java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '  #16 = NameAndType        #17:#18        // println:(Ljava/lang/String;)V\n' +
                            '  #17 = Utf8               println\n' +
                            '  #18 = Utf8               (Ljava/lang/String;)V\n' +
                            '  #19 = Utf8               main\n' +
                            '  #20 = Utf8               ([Ljava/lang/String;)V\n' +
                            '  #21 = Utf8               Code\n' +
                            '{\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    descriptor: ([Ljava/lang/String;)V\n' +
                            '    flags: (0x0009) ACC_PUBLIC, ACC_STATIC\n' +
                            '    Code:\n' +
                            '      stack=2, locals=1, args_size=1\n' +
                            '         0: getstatic     #11                 // Field java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '         3: ldc           #9                  // String KJ e aici!\n' +
                            '         5: invokevirtual #15                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '         8: return\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'javap -p HelloWorld.class\n' +
                            'public class HelloWorld {\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'java HelloWorld\n' +
                            'KJ e aici!'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>
                                Writing Hello World in Java byte code
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ByteCodeJavaContent;