import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FileStreamsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-file-streams", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Fisiere</b>
                    <br/> <br/>

                    Python, la fel ca majoritatea limbajelor de programare, nu comunica direct cu fisierele, ci prin intermediul unor entitati abstracte numite <b>fluxuri (handles / streams)</b>.

                    <br/>

                    Operația de conectare a fluxului cu un fișier se numește <b>deschiderea fișierului</b>, în timp ce deconectarea acestei legături se numește <b>închiderea fișierului</b>.

                    <br/>
                    Cand de deschide un fisier trebuie specificat si ce in ce <b>mod</b> (open mode) (ce vrem sa facem cu el):
                    <ul>
                        <li>citire (read mode):
                            un flux deschis în acest mod permite doar operațiuni de citire;
                            <br/>
                            încercarea de a scrie în flux va provoca o excepție (<b>UnsupportedOperation</b>, care moștenește <b>OSError</b> și <b>ValueError</b> și provine din modulul <b>io</b>);
                        </li>
                        <li>scriere (write mode):
                            un flux deschis în acest mod permite numai operațiuni de scriere;
                            <br/>
                            încercarea de a citi fluxul va provoca excepția menționată mai sus;</li>
                        <li>actualizare (update mode):
                            un flux deschis în acest mod permite atât scrieri, cât și citiri.
                        </li>
                    </ul>

                    Pe un flux / stream se poate efectua doua operatii:
                    <ul>
                        <li>citire (din flux): datele sunt citite din fisier</li>
                        <li>scriere (in flux):  datele sunt transferate/scrise in fisier</li>
                    </ul>

                    Python presupune că fiecare fișier este ascuns în spatele unui obiect dintr-o clasă (adecvata):
                    <ul>
                        <li>
                            IOBase
                            <ul>
                                <li>RawIOBase</li>
                                <li>TextIOBase</li>
                                <li>BufferIOBase</li>
                            </ul>
                        </li>
                    </ul>
                    Acest obiect este creat la deschiderea fișierului (functia <b>open()</b>) și distrus în momentul închiderii fisierului (functia <b>close()</b>).

                    <br/>
                    <br/>
                    Pe baza tipului continutului, un flux poate fi:
                    <ul>
                        <li>
                            flux <b>text</b>:

                            <ul>
                                <li>conține caractere tipografice (litere, cifre, semne de punctuație etc.) dispuse în rânduri (linii), așa cum se vede cu ochiul liber atunci când privești conținutul fișierului în editor</li>
                                <li>este structurat pe linii;</li>
                                <li>este scris/citit caracter cu caracter sau ranc cu rand</li>
                            </ul>

                        </li>
                        <li>
                            flux <b>binar</b>:
                            <ul>
                                <li>nu conține text, ci o secvență de octeți de orice valoare (aceasta secventa poate fi un program executabil, o poza, un fisier audio)</li>
                                <li>nu este structurate pe linii;</li>
                                <li>este citit/scris octet cu octet, sau bloc cu bloc, unde dimensiunea blocului variază de obicei de la unu la o valoare aleasă în mod arbitrar</li>
                            </ul>
                        </li>
                    </ul>

                    In sistemele Unix, sfarsitul de linie intr-un fisier este marcat de \n, iar in Windows cu \r\n.
                    Daca un program e capabil sa foloseasca corect un fisier, indiferent de sistemul de operare se numeste portabil.
                    In Python, portabilitatea este asigurate de clase in felul urmator:
                    <ul>
                        <li>daca e deschis un flux text, se trece in modul text (text mode); daca e deschis intr-un mediu unix, nu se intampla nimic, daca e deschis intr-un mediu window se face traducerea caracterelor newline (la citire \r\n devine \n si la sciere invers);
                            <br/>
                            acest mecanism este transparent
                        </li>
                        <li>
                            daca e deschis un flux binar, nu se intampla nimic special, nici o conversie.
                        </li>
                    </ul>

                    <b>1. Deschidere fisier</b>
                    <br/>
                    <br/>

                    Există trei moduri de deschidere fisier:
                    <ul>
                        <li>modul citire – sunt permise doar operați de citire</li>
                        <li>modul de scriere – sunt permise doar operați de scriere</li>
                        <li>modul de actualizare (update) – sunt permise atât scrierile, cât și citirile</li>
                    </ul>


                    <SyntaxHighlighter showLineNumbers={false} language="python" style={androidstudio}>
                        {'open(nume_fisier, mode=mod_deschidere, encoding=text_encoding)'}
                    </SyntaxHighlighter>


                    Valori pentru parametrul <b>mode</b>:

                    <ul>
                        <li>
                            <b>r</b> (citire): <br/>fisierul <b>trebuie sa existe</b> si poate fi citit, altfel se arunca exceptie
                        </li>
                        <li>
                            <b>w</b> (scriere): <br/>fisierul <b>nu e nevoie sa existe</b> (daca nu exista se creeaza, daca exista, continutul e sters); daca nu se poate creea (de exemplu, din cauza permisiunilor) se arunca exceptie
                        </li>
                        <li>
                            <b>a</b> (adaugare): <br/>fisierul <b>nu e nevoie sa existe</b> (daca nu exista se creeaza, daca exista, scrierea incepe cu sfarsitul continutului)
                        </li>
                        <li>
                            <b>r+</b> (citire si actualizare): <br/>fisierul <b>trebuie sa existe si se poate scrie in el</b>, altfel se arunca exceptie (se poate scrie si citi din flux; sunt permise operatii de scriere si citire)
                        </li>
                        <li>
                            <b>w+</b> (scriere si actualizare): <br/>fisierul <b>nu e nevoie sa existe</b>  (daca nu exista se creeaza, daca exista atunci conținutul anterior al fișierului <b>nu se sterge</b>; sunt permise operatii de scriere si citire)
                        </li>
                        <li>
                            <b>x</b> (creare exlusiva): <br/> fisierul <b>nu trebuie sa existe</b>, altfel se arunca exceptie
                        </li>
                    </ul>

                    Dacă există litera <b>b</b> la sfârșitul șirului de mod de deschidere, înseamnă că fluxul urmează să fie deschis în modul binar.
                    <br/>
                    Dacă există litera <b>t</b> la sfârșitul șirului de mod de deschidere, înseamnă că fluxul urmează să fie deschis în modul text.
                    <br/>
                    Modul text este comportamentul implicit, deci se poate omite <b>t</b>, daca fluxul urmează să fie deschis în modul text
                    <br/>
                    <br/>
                    <table>
                        <thead>
                            <tr>
                                <td>Mod text</td>
                                <td>Mod binar</td>
                                <td>operatii permise</td>
                            </tr>
                        </thead>


                        <tr>
                            <td>rt</td>
                            <td>rb</td>
                            <td>citire</td>
                        </tr>

                        <tr>
                            <td>wt</td>
                            <td>wb</td>
                            <td>scriere</td>
                        </tr>

                        <tr>
                            <td>at</td>
                            <td>ab</td>
                            <td>scriere</td>
                        </tr>

                        <tr>
                            <td>r+t</td>
                            <td>r+b</td>
                            <td>citire/scriere</td>
                        </tr>

                        <tr>
                            <td>w+t</td>
                            <td>w+b</td>
                            <td>citire/scriere</td>
                        </tr>

                        <tr>
                            <td>xt</td>
                            <td>xb</td>
                            <td>scriere</td>
                        </tr>

                    </table>
                    <hr/>
                    <b>2. Fluxuri implicite</b>
                    <br/>
                    <br/>

                    Inainte de orice operatie pe un flux, acest flux trebuie deschis prin invocarea functiei <b>open()</b>. Dar exista 3 fluxuri care deschise automat, de fiecare data cand se porneste un progream si pot fi folosite, daca se importa modulul <b>sys</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import sys'}
                    </SyntaxHighlighter>

                    Cele 3 fluxuri sunt:
                    <ul>
                        <li><b>sys.stdin</b> (standard input):
                            <br/>
                            este în mod normal asociat cu tastatura; deschis pentru citire
                            <br/>
                            exemplu: functia <b>input()</b> citeste din acest flux, in mod implicit
                        </li>
                        <li><b>sys.stdout</b> (standard output):
                            <br/>
                            este în mod normal asociat cu monitorul; deschis pentru scriere
                            <br/>
                            exemplu: functia <b>print()</b> scrie in acest flux
                        </li>
                        <li><b>sys.strerr</b> (standard error output):
                            <br/>
                            este în mod normal asociat cu monitorul; deschis pentru scriere;
                            <br/>
                            locul unde programul care rulează ar trebui să trimită informații despre erorile întâlnite în timpul rularii
                        </li>
                    </ul>

                    Cele 3 fluxuri descrise mai sus, nu necesita nici apelarea metodei <b>close()</b>.

                    <hr/>

                    <b>3. Inchiderea unui flux</b>
                    <br/>
                    <br/>

                    Inchiderea unui flux se face prin apelarea metodei <b>close()</b>.
                    <br/>
                    Legat de metoda <b>close()</b>:
                    <ul>
                        <li>fluxul nu trebuie sa fie neaparat deschis</li>
                        <li>poate arunca o eroare de tipul <b>IOError</b></li>
                    </ul>

                    <hr/>

                    <b>4. Erori IOError</b>
                    <br/>
                    <br/>

                    Erori pot apare si la deschiderea cat si la inchiderea unui fisier. Si atunci se arunca o eroare de tip  <b>IOError</b>.
                    <br/>

                    Un obiect de tipul <b>IOError</b> are proprietatea <b>errno</b>. Valoarea atributului <b>errno</b> poate fi comparată cu una dintre constantele simbolice predefinite definite în modulul <b>errno</b>:

                    <table>
                        <thead>
                            <tr>
                                <td><b>errno</b></td>
                                <td><b>eroarea apare, de exemplu</b></td>
                            </tr>
                        </thead>

                        <tr>
                            <td><b>errno.EACCES → Permission denied</b></td>
                            <td>daca nu aveți voie să accesați conținutul fișierului (sau incercati sa deschideti un fisier pentru scriere, iar fisierul are atributul <i>read only</i>)</td>
                        </tr>

                        <tr>
                            <td><b>errno.EBADF → Bad file number</b></td>
                            <td>cand se incerca o operatie pe un flux nedeschis</td>
                        </tr>

                        <tr>
                            <td><b>errno.EEXIST → File exists</b></td>
                            <td>cand se incerca redenumirea unui fișier cu numele său anterior</td>
                        </tr>

                        <tr>
                            <td><b>errno.EFBIG → File too large</b></td>
                            <td>cand incerca crearea unui fișier mai mare decât maximul permis de sistemul de operare</td>
                        </tr>

                        <tr>
                            <td><b>errno.EISDIR → Is a directory</b></td>
                            <td>cand utilizati numele unui director ca numele unui fișier obișnuit</td>
                        </tr>

                        <tr>
                            <td><b>errno.EMFILE → Too many open files</b></td>
                            <td>cand fisierul este deschis simultan de mai multe fluxuri decât este permis de sistemul de operare</td>
                        </tr>

                        <tr>
                            <td><b>errno.ENOENT → No such file or directory</b></td>
                            <td>la accesarea unui fișier/director inexistent</td>
                        </tr>

                        <tr>
                            <td><b>errno.ENOSPC → No space left on device</b></td>
                            <td>la salvarea unui fisier, dar nu există spațiu liber pe HDD</td>
                        </tr>

                    </table>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import errno\n' +
                        '\n' +
                        'try:\n' +
                        '    s = open("c:/users/kj/file.txt", "rt")\n' +
                        '    s.close()\n' +
                        'except Exception as exc:\n' +
                        '    if exc.errno == errno.ENOENT:\n' +
                        '        print("Fisierul nu exista") # Fisierul nu exista\n' +
                        '    elif exc.errno == errno.ENOSPC :\n' +
                        '        print("Nu mai exista spariu de disk")\n' +
                        '    else:\n' +
                        '        print("Numarul erorii este:", exc.errno)'}
                    </SyntaxHighlighter>

                    Exista in modulul <b>os</b>, functia <b>strerror()</b> care așteaptă un singur argument - un număr de eroare si returneaza un șir care descrie eroarea.
                    Daca se trimite un numar de eroare inexistent, functia va arunca o eroare de tipul <b>ValueError</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from os import strerror\n' +
                        '\n' +
                        'try:\n' +
                        '    s = open("c:/users/kj/file.txt", "rt")\n' +
                        '    s.close()\n' +
                        'except Exception as exc:\n' +
                        '    print("Fisierul n-a putut fi deschis:", strerror(exc.errno)) # Fisierul a putut fi deschis: No such file or directory'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FileStreamsPythonContent;