import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class OtherSensorsArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-other-sensors", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Alti senzori </b>
                    <br/>
                    <br/>

                    Senzori
                    <ul>
                        <li>
                            Senzor de lumina UV (GUVA-S12SD)
                        </li>
                        <li>
                            RGB Light (TCS 34725 sau TCS 230);
                            <br/>

                            Exemplu (de pe https://github.com/adafruit/Adafruit_TCS34725/)
                            <SyntaxHighlighter>
                                {'#include <Wire.h>\n' +
                                    '#include "Adafruit_TCS34725.h"\n' +
                                    '\n' +
                                    '/* Example code for the Adafruit TCS34725 breakout library */\n' +
                                    '\n' +
                                    '/* Connect SCL    to analog 5\n' +
                                    '   Connect SDA    to analog 4\n' +
                                    '   Connect VDD    to 3.3V DC\n' +
                                    '   Connect GROUND to common ground */\n' +
                                    '\n' +
                                    '/* Initialise with default values (int time = 2.4ms, gain = 1x) */\n' +
                                    '// Adafruit_TCS34725 tcs = Adafruit_TCS34725();\n' +
                                    '\n' +
                                    '/* Initialise with specific int time and gain values */\n' +
                                    'Adafruit_TCS34725 tcs = Adafruit_TCS34725(TCS34725_INTEGRATIONTIME_614MS, TCS34725_GAIN_1X);\n' +
                                    '\n' +
                                    'void setup(void) {\n' +
                                    '  Serial.begin(9600);\n' +
                                    '\n' +
                                    '  if (tcs.begin()) {\n' +
                                    '    Serial.println("Found sensor");\n' +
                                    '  } else {\n' +
                                    '    Serial.println("No TCS34725 found ... check your connections");\n' +
                                    '    while (1);\n' +
                                    '  }\n' +
                                    '\n' +
                                    '  // Now we\'re ready to get readings!\n' +
                                    '}\n' +
                                    '\n' +
                                    'void loop(void) {\n' +
                                    '  uint16_t r, g, b, c, colorTemp, lux;\n' +
                                    '\n' +
                                    '  tcs.getRawData(&r, &g, &b, &c);\n' +
                                    '  // colorTemp = tcs.calculateColorTemperature(r, g, b);\n' +
                                    '  colorTemp = tcs.calculateColorTemperature_dn40(r, g, b, c);\n' +
                                    '  lux = tcs.calculateLux(r, g, b);\n' +
                                    '\n' +
                                    '  Serial.print("Color Temp: "); Serial.print(colorTemp, DEC); Serial.print(" K - ");\n' +
                                    '  Serial.print("Lux: "); Serial.print(lux, DEC); Serial.print(" - ");\n' +
                                    '  Serial.print("R: "); Serial.print(r, DEC); Serial.print(" ");\n' +
                                    '  Serial.print("G: "); Serial.print(g, DEC); Serial.print(" ");\n' +
                                    '  Serial.print("B: "); Serial.print(b, DEC); Serial.print(" ");\n' +
                                    '  Serial.print("C: "); Serial.print(c, DEC); Serial.print(" ");\n' +
                                    '  Serial.println(" ");\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Senzor temperatura si umiditate (DHT 22 sau AM2302/ DHT 11)
                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=zACmjwvbils&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=8&ab_channel=ScienceBuddies"}>*/}
                    {/*            PING Ultrasonic Distance Sensor and Arduino (Lesson #8)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=n-gJ00GTsNg&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=9&ab_channel=ScienceBuddies"}>*/}
                    {/*            HC-SR04 Ultrasonic Distance Sensor and Arduino (Lesson #9)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OtherSensorsArduinoContent;