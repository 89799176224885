import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class LambdasPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-lambdas", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Functii lambda</b>
                    <br/> <br/>

                    <b>1. Functia lambda</b>
                    <br/>
                    <br/>

                    O funcție lambda este o <b>funcție fără nume</b> (o puteți numi și <b>funcție anonimă</b>).
                    Se poate denumi o astfel de funcție dacă este chiar ai nevoie, dar în multe cazuri funcția lambda poate exista și funcționa în timp ce rămâne complet incognito.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lambda parametri: expresie'}
                    </SyntaxHighlighter>

                    <b>Se returneaza valoarea expresiei</b> atunci când ia în considerare <b>valoarea curentă a argumentului lambda curent</b>.

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'constanta_2 = lambda: 2\n' +
                        'sqrt = lambda x: x * x\n' +
                        'putere = lambda x, y: x ** y\n' +
                        '\n' +
                        'for a in range(1,3):\n' +
                        '    print(sqrt(a), putere(a,2),constanta_2()) # 1 1 2 si 4 4 2'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    Ghidul de stil pentru codul Python, recomandă ca functiile lambda să nu fie atribuite variabilelor, ci mai degrabă să fie definite ca funcții.
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# recomandat:\n' +
                        'def f(x): return 2*x\n' +
                        '\n' +
                        '# nu e recomandat:\n' +
                        'f = lambda x: 2*x'}
                    </SyntaxHighlighter>

                    Legarea lambdas la identificatori dublează, în general, funcționalitatea instrucțiunii <b>def</b>.
                    Functile lambda, se pot rescrie cu functii normale in felul urmator:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# constanta_2 = lambda: 2\n' +
                        'def constanta_2():\n' +
                        '    return 2\n' +
                        '    \n' +
                        '# sqrt = lambda x: x * x\n' +
                        '\n' +
                        'def sqrt(x):\n' +
                        '    return x*x\n' +
                        '    \n' +
                        '# putere = lambda x, y: x ** y\n' +
                        '\n' +
                        'def putere(x,y):\n' +
                        '    return x ** y\n' +
                        '    \n' +
                        '\n' +
                        'for a in range(1,3):\n' +
                        '    print(sqrt(a), putere(a,2),constanta_2()) # 1 1 2 si 4 4 2'}
                    </SyntaxHighlighter>

                    Unei functii i se poate trimite ca argument o referinta la functie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(x):\n' +
                        '   return x*x+1\n' +
                        '\n' +
                        'def calcul(valoare, f):\n' +
                        '    return f(valoare)\n' +
                        '    \n' +
                        'print(calcul(5,functie)) # 26\n' +
                        'print(calcul(10,functie)) # 101'}
                    </SyntaxHighlighter>

                    Se poate evita definirea unei functii si se poate folosi o functie anonima (lambda):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def calcul(valoare, f):\n' +
                        '    return f(valoare)\n' +
                        '    \n' +
                        'print(calcul(5, lambda x:x*x+1)) # 26\n' +
                        'print(calcul(10, lambda x:x*x+1)) # 101'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    O functie <b>lambda</b> evaluaza <b>o singura expresie</b>!

                    <hr/>
                    <b>1.1. Functia map()</b>
                    <br/>
                    <br/>

                    Functia map() are 2 argumente (in principiu), dar <b>poate accepta mai multe argumente</b>:
                    <ul>
                        <li>o functie</li>
                        <li>o lista (sau orice poate fi iterat: tuplu, generator, etc)</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'map(functie, lista)'}
                    </SyntaxHighlighter>

                    Funcția <b>map()</b>:
                    <ul>
                        <li>aplică funcția transmisă de primul argument la toate elementele celui de-al doilea argument</li>
                        <li><b>returnează un iterator</b> care oferă toate rezultatele funcției</li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista_1 = [x for x in range(4)]\n' +
                        'lista_2 = list(map(lambda x: x * x, lista_1))\n' +
                        'print(lista_2) # [0, 1, 4, 9]'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(list(map(lambda x: x * x, range(4)))) # [0, 1, 4, 9]'}
                    </SyntaxHighlighter>

                    Exemplu 3:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def la_patrat(x):\n' +
                        '    return x*x\n' +
                        '    \n' +
                        'print(list(map(la_patrat, range(4)))) # [0, 1, 4, 9]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Functia filter()</b>
                    <br/>
                    <br/>

                    Functia filter:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'filter(functie, lista)'}
                    </SyntaxHighlighter>
                    returneaza un <b>interator</b> continand elementele din lista, pentru care functia apelatata cu elementul respectiv a returnat <b>True</b>.

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(list(filter(lambda x: x%2==0, range(10)))) # afiseaza doar numerele pare din intervalul [0,10): [0, 2, 4, 6, 8]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Execitiu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# afisare dictionar sortat descrescator dupa valoarea cheii:\n' +
                        '\n' +
                        'kj = "kj"\n' +
                        'dict = {"ion":40, "vasile":3, kj: 77}\n' +
                        '\n' +
                        'lista_sortata = sorted(dict.keys(), key=lambda x: dict[x], reverse=True)\n' +
                        '\n' +
                        'for k in lista_sortata:\n' +
                        '    print(k, \'->\', dict[k])'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LambdasPythonContent;