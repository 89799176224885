import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class IntroDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "intro-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Containerizarea este o alternativa sau o completare la virtualizare. Containerizarea permite:
                    <ul>
                        <li>împachetarea unui aplicatii cu toate dependintele acestuia </li>
                        <li>un mediu in care aplicatia sa ruleze uniform pe diferinte infrastructuri si platforme</li>
                    </ul>

                    Rularea unui aplicatii intr-un mediu de tip container (containerizat) utilizeaza <i>mai putine resurse</i> decat daca ar fi rulat pe o masina virtuala (acesta necesita un sistem de operare pe masina virtuala).

                    <br/>
                    <br/>
                    Mașinile virtuale rulează sisteme de operare „oaspete”, lucru care consumă multe resurse, iar imaginea rezultată ocupă mult spațiu, conținând setări de sistem de operare, dependențe, patch-uri de securitate, etc.
                    <br/>
                    Containerele pot să împartă același nucleu, și singurele date care trebuie să fie într-o imagine de container sunt executabilul și pachetele de care depinde, care nu trebuie deloc instalate pe sistemul gazdă.

                    <hr/>
                    <b>1. Imagini</b>
                    <br/>

                    Containerele Docker au la bază <b>imagini</b>. Acestea sunt pachete executabile lightweight de sine stătătoare ce conțin tot ce este necesar pentru rularea unor aplicații software, incluzând cod, runtime, biblioteci, variabile de mediu și fișiere de configurare.
                    Imaginile:
                    <ul>
                        <li>au o dimensiune variabilă</li>
                        <li>nu conțin versiuni complete ale sistemelor de operare</li>
                        <li>sunt stocate în cache-ul local sau într-un registru</li>
                    </ul>
                    O imagine Docker are un sistem de fișiere de tip <i>union</i>, unde fiecare schimbare asupra sistemului de fișiere sau metadate este considerată ca fiind un strat (layer), mai multe astfel de straturi formând o imagine.
                    Fiecare strat este identificat unic (printr-un hash) și stocat doar o singură dată.

                    <hr/>
                    <b>2. Containere</b>
                    <br/>

                    Un container reprezintă o <i>instanță a unei imagini</i>, adică ceea ce imaginea devine în memorie atunci când este executată.
                    <br/>
                    Un container ruleaza:
                    <ul>
                        <li>complet izolat de mediul gazdă, accesând fișiere și porturi ale acestuia doar dacă este configurat să facă acest lucru</li>
                        <li>aplicații nativ pe nucleul mașinii gazdă, având performanțe mai bune decât mașinile virtuale, care au acces la resursele gazdei prin intermediul unui hipervizor</li>
                        <li>într-un proces discret, necesitând tot atât de multă memorie cât orice alt executabil</li>
                    </ul>

                    <hr/>
                    <b>3. Verificare instalare</b>
                    <br/>

                    Pentru a verifica dacă instalarea Docker s-a realizat cu succes, putem rula un container simplu de tip Hello World:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container run hello-world\n' +
                        'Unable to find image \'hello-world:latest\' locally\n' +
                        'latest: Pulling from library/hello-world\n' +
                        '2db29710123e: Pull complete\n' +
                        'Digest: sha256:97a379f4f88575512824f3b352bc03cd75e239179eea0fecc38e597b2209f49a\n' +
                        'Status: Downloaded newer image for hello-world:latest\n' +
                        '\n' +
                        'Hello from Docker!\n' +
                        'This message shows that your installation appears to be working correctly.\n' +
                        '\n' +
                        'To generate this message, Docker took the following steps:\n' +
                        ' 1. The Docker client contacted the Docker daemon.\n' +
                        ' 2. The Docker daemon pulled the "hello-world" image from the Docker Hub.\n' +
                        '    (amd64)\n' +
                        ' 3. The Docker daemon created a new container from that image which runs the\n' +
                        '    executable that produces the output you are currently reading.\n' +
                        ' 4. The Docker daemon streamed that output to the Docker client, which sent it\n' +
                        '    to your terminal.\n' +
                        '\n' +
                        'To try something more ambitious, you can run an Ubuntu container with:\n' +
                        ' $ docker run -it ubuntu bash\n' +
                        '\n' +
                        'Share images, automate workflows, and more with a free Docker ID:\n' +
                        ' https://hub.docker.com/\n' +
                        '\n' +
                        'For more examples and ideas, visit:\n' +
                        ' https://docs.docker.com/get-started/'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>4. Docker version</b>
                    <br/>

                    Comanda: <b>docker version</b>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker version\n' +
                        'Client:\n' +
                        ' Cloud integration: v1.0.22\n' +
                        ' Version:           20.10.12\n' +
                        ' API version:       1.41\n' +
                        ' Go version:        go1.16.12\n' +
                        ' Git commit:        e91ed57\n' +
                        ' Built:             Mon Dec 13 11:44:07 2021\n' +
                        ' OS/Arch:           windows/amd64\n' +
                        ' Context:           default\n' +
                        ' Experimental:      true\n' +
                        '\n' +
                        'Server: Docker Desktop 4.5.1 (74721)\n' +
                        ' Engine:\n' +
                        '  Version:          20.10.12\n' +
                        '  API version:      1.41 (minimum version 1.12)\n' +
                        '  Go version:       go1.16.12\n' +
                        '  Git commit:       459d0df\n' +
                        '  Built:            Mon Dec 13 11:43:56 2021\n' +
                        '  OS/Arch:          linux/amd64\n' +
                        '  Experimental:     false\n' +
                        ' containerd:\n' +
                        '  Version:          1.4.12\n' +
                        '  GitCommit:        7b11cfaabd73bb80907dd23182b9347b4245eb5d\n' +
                        ' runc:\n' +
                        '  Version:          1.0.2\n' +
                        '  GitCommit:        v1.0.2-0-g52b36a2\n' +
                        ' docker-init:\n' +
                        '  Version:          0.19.0\n' +
                        '  GitCommit:        de40ad0'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>5. Docker Compose version</b>
                    <br/>

                    Comanda: <b>docker-compose version</b>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker-compose version\n' +
                        'Docker Compose version v2.2.3'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                Learn Docker in a month of lunches - Elton Stoneman, Manning, 2020
                            </div>
                        </li>

                        <li>
                            <div>
                                Docker Demystified - Saibal Ghosh, BPB, 2021
                            </div>
                        </li>

                        <li>
                            <div>

                                <a href={"https://ocw.cs.pub.ro/courses/idp/laboratoare/01"}>Laboratorul 01 - Docker</a>

                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroDockerContent;