import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class DocumentNextContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-document", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Document</b>
                    <br/>
                    <br/>

                    <b>_document.js</b> formează structura generală a HTML-ului.
                    Dacă trebuie să modificați etichetele html sau body , _document.js este locul potrivit pentru a face acest lucru.

                    <br/>

                    Pentru a înlocui valoarea implicită <b>Document</b>, creați fișierul <b>pages/_document.js</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Html, Head, Main, NextScript } from \'next/document\'\n' +
                            '\n' +
                            'export default function Document() {\n' +
                            '  return (\n' +
                            '    <Html>\n' +
                            '      <Head />\n' +
                            '      <body>\n' +
                            '        <Main />\n' +
                            '        <NextScript />\n' +
                            '      </body>\n' +
                            '    </Html>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fișierul _document este apelat în timpul inițializării paginii și este folosit pentru a înlocui pagina implicită a documentului.
                    În fișierul _document puteți actualiza etichetele HTML și Body pentru a înlocui implementarea implicită.

                    <br/>
                    <br/>
                    Aplicare:
                    <ul>
                        <li>
                            Adăugarea unui limbaj personalizat la eticheta HTML
                        </li>
                        <li>
                            Adăugarea unui ClassName personalizat la eticheta Body
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Html, Head, Main, NextScript } from \'next/document\'\n' +
                            '\n' +
                            'export default function Document() {\n' +
                            '  return (\n' +
                            '    <Html lang="en">\n' +
                            '      <Head />\n' +
                            '      <body className="bg-black">\n' +
                            '        <Main />\n' +
                            '        <NextScript />\n' +
                            '      </body>\n' +
                            '    </Html>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>
                            _document.js permite editarea numai in structura codului html, ceea ce înseamnă că nu se pot adăuga evenimente javascript în el.
                        </li>
                        <li>
                            HeadComponenta din _document.js este complet diferită de Head din fișierul _app.js
                        </li>
                    </ul>

                    Utilizati:
                    <ul>
                        <li>
                            fișierul _ app.js atunci când trebuie să adăugați un aspect global sau etichete meta
                        </li>
                        <li>
                            fișierul _ document.js numai dacă trebuie să editați structura de bază a codului HTML
                        </li>
                    </ul>

                    Next.js folosește componenta App pentru a inițializa paginile.
                    Pentru a suprascrie, se creeaza fisierul ./pages/_app.js și suprascrieți clasa App.
                    Nu includeți niciodată html, body, etc.
                    <br/>
                    Pentru a înlocui acest comportament implicit, trebuie să creați un fișier la ./pages/_document.js, unde puteți extinde clasa Document.
                    <br/>
                    _document.js este redat doar pe partea serverului și nu pe partea clientului, astfel încât gestionatorii de evenimente (ca onClick) nu vor funcționa.


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DocumentNextContent;