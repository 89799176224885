import React from "react";

import AnnIC from "./ann/IndexContent"
import DesignPatternsIC from "./design-patterns/IndexContent"
import JavaCryptographyIC from "./java-cryptography/IndexContent"
import LiferayIC from "./liferay/IndexContent"
import NuxeoIC from "./nuxeo/IndexContent"
import KeyclockIC from "./keycloak/IndexContent"
import JavaIC from "./java/IndexContent"
import JavaSpringIC from "./java-spring/IndexContent"
import JavaSpringSecurityIC from "./java-spring-security/IndexContent"
import PythonIIC from "./python/IndexContent"
import PythonIIIC from "./python-ii/IndexContent"
import PythonGuiIC from "./python-gui/IndexContent"
import UtilIC from "./util/IndexContent"
import IndexContentHelper from "./IndexContentHelper";
import CompilerIC from "./compiler/IndexContent";
import JavaScriptIC from "./javascript/IndexContent";
import GitIC from "./git/IndexContent";
import DockerIC from "./docker/IndexContent";
import GradleIC from "./gradle/IndexContent";
import VideografieIC from "./videografie/IndexContent";
import BooksIC from "./books/IndexContent";
import AlbumsIC from "./music/IndexContent";
import ElasticsearchIC from "./elasticsearch/IndexContent";
import TomcatIC from "./tomcat/IndexContent";
import CvIC from "./cv/IndexContent";
import MavenIC from "./maven/IndexContent";
import NasIC from "./nas/IndexContent";
import NginxIC from "./nginx/IndexContent";
import ReactIC from "./react/IndexContent";
import KubernetesIC from "./kubernetes/IndexContent"
import LinuxIC from "./linux/IndexContent"
import SpringBootIC from "./spring-boot/IndexContent"
import LaravelIC from "./laravel/IndexContent"
import NextJsIC from "./next/IndexContent"
import JHipsterIC from "./jhipster/IndexContent"
import AngularIC from "./angular/IndexContent"
import TypescriptIC from "./typescript/IndexContent"
import {Link} from "react-router-dom";

import GrafanaIC from "./grafana/IndexContent"
import RustIC from "./rust/IndexContent"
import GoIC from "./go/IndexContent"
import ArduinoIC from  "./arduino/IndexContent"
import TTSIC from  "./text-to-speech/IndexContent"
import LLMIC from  "./llm/IndexContent"
import RpiIC from "./raspberrypi/IndexContent"
import RobotsIC from "./robots/IndexContent"

class IndexContent extends React.Component {

    static content = [
        {id:"ann-index", url:"/ann/index", title:"Rețele neuronale artificiale", subtitle:"Introducere in lumea IA", content: AnnIC.content},
        {id:"design-patterns-index", url:"/design-patterns/index", title:"Design Patterns", subtitle:"sabloane de proiectare", content: DesignPatternsIC.content},
        {id:"java-cryptography-index", url:"java-cryptography/index", title:"Semnaturi digitale", subtitle:"Introducere in semnaturi digitale", content: JavaCryptographyIC.content},
        {id:"liferay-index", url:"/liferay/index", title:"Liferay", subtitle:"Scurta introducere in Liferay", content: LiferayIC.content},
        {id:"nuxeo-index", url:"/nuxeo/index", title:"Nuxeo", subtitle:"Scurta introducere in Nuxeo", content: NuxeoIC.content},
        {id:"keycloak", url:"/keycloak/index", title:"Keycloak", subtitle:"Introducere in Keycloak", content: KeyclockIC.content},
        {id:"java-index", url:"/java/index", title:"Java", subtitle:"Fundamente Java", content: JavaIC.content},
        {id:"java-spring-index", url:"/java-spring/index", title:"Java Spring", subtitle:"Introducere in Spring", content: JavaSpringIC.content},
        {id:"spring-boot-index", url:"/spring-boot/index", title:"Spring Boot", subtitle:"Introducere in Spring Boot", content: SpringBootIC.content},
        {id:"java-spring-security-index", url:"/java-spring-security/index", title:"Java Spring Security", subtitle:"Introducere in securitate prin exemple", content: JavaSpringSecurityIC.content},
        {id:"jhipster-index", url:"/jhipster/index", title:"JHipster", subtitle:"Introducere in JHipster", content: JHipsterIC.content},
        {id:"python-index", url:"/python/index", title:"Python", subtitle:"Introducere in Python", content: PythonIIC.content},
        {id:"python-ii-index", url:"/python-ii/index", title:"Python (II)", subtitle:"Notiuni mai avansate", content: PythonIIIC.content},
        {id:"python-ii-gui", url:"/python-gui/index", title:"Python (GUI)", subtitle:"Interfete grafice pentru utilizatori", content: PythonGuiIC.content},
        {id:"javascript-index", url:"/javascript/index", title:"JavaScript", subtitle:"Introducere in JavaScript", content: JavaScriptIC.content},
        {id:"git-index", url:"/git/index", title:"Git", subtitle:"Introducere in Git", content: GitIC.content},
        {id:"docker-index", url:"/docker/index", title:"Docker", subtitle:"Introducere in Docker", content: DockerIC.content},
        {id:"kubernetes-index", url:"/kubernetes/index", title:"Kubernetes", subtitle:"Introducere in Kubernetes (K8s)", content: KubernetesIC.content},
        {id:"gradle-index", url:"/gradle/index", title:"Gradle", subtitle:"Introducere in Gradle", content: GradleIC.content},
        {id:"maven-index", url:"/maven/index", title:"Maven", subtitle:"Introducere in Maven", content: MavenIC.content},
        {id:"tomcat-index", url:"/tomcat/index", title:"Tomcat", subtitle:"Introducere in Tomcat", content: TomcatIC.content},
        {id:"nginx-index", url:"/nginx/index", title:"Nginx", subtitle:"Introducere in Nginx", content: NginxIC.content},
        {id:"react-index", url:"/react/index", title:"React", subtitle:"Introducere in React", content: ReactIC.content},
        {id:"next-index", url:"/next/index", title:"Next", subtitle:"Introducere in Next", content: NextJsIC.content},
        {id:"laravel-index", url:"/laravel/index", title:"Laravel", subtitle:"Introducere in Laravel", content: LaravelIC.content},
        {id:"angular-index", url:"/angular/index", title:"Angular", subtitle:"Introducere in Angular", content: AngularIC.content},
        {id:"typescript-index", url:"/typescript/index", title:"Typescript", subtitle:"Introducere in Typescript", content: TypescriptIC.content},
        {id:"elasticsearch-index", url:"/elasticsearch/index", title:"Elasticsearch", subtitle:"Introducere in Elasticsearch", content: ElasticsearchIC.content},
        {id:"compiler-index", url:"/compiler/index", title:"Compilatoare", subtitle:"Introducere in compilatoare", content: CompilerIC.content},
        {id:"videografie-index", url:"/videografie/index", title:"Videografie", subtitle:"Notiuni introductive", content: VideografieIC.content},
        {id:"book-index", url:"/books/index", title:"Carti", subtitle:"Biblioteca", content: BooksIC.content},
        {id:"music-index", url:"/music/index", title:"Muzica", subtitle:"Albume", content: AlbumsIC.content},
        {id:"cv-index", url:"/cv/index", title:"CV", subtitle:"CV", content: CvIC.content},
        {id:"nas-index", url:"/nas/index", title:"NAS", subtitle:"Introducere NAS", content: NasIC.content},
        {id:"linux-index", url:"/linux/index", title:"Linux", subtitle:"Introducere in Linux", content: LinuxIC.content},
        {id:"util-index", url:"/util/index", title:"Tips & Tricks", subtitle:"chestii utile", content: UtilIC.content},

        {id:"grafana-index", url:"/grafana/index", title:"Grafana", subtitle:"Introducere in Grafana", content: GrafanaIC.content},
        {id:"rust-index", url:"/rust/index", title:"Rust", subtitle:"Introducere in Rust", content: RustIC.content},
        {id:"go-index", url:"/go/index", title:"Go", subtitle:"Introducere in Go", content: GoIC.content},

        {id:"arduino-index", url:"/arduino/index", title:"Arduino", subtitle:"Introducere in Arduino", content: ArduinoIC.content},

        {id:"tts-index", url:"/tts/index", title:"Text-to-Speech", subtitle:"Introducere in TTS", content: TTSIC.content},
        {id:"llm-index", url:"/llm/index", title:"Large language model", subtitle:"Introducere in LLM", content: LLMIC.content},
        {id:"rpi-index", url:"/rpi/index", title:"Raspberry PI", subtitle:"Introducere in Raspberry PI", content: RpiIC.content},
        {id:"robots-index", url:"/robots/index", title:"Roboti", subtitle:"Roboti", content: RobotsIC.content},
    ]


    totalArticle = 0;

    constructor(prop) {
        super(prop);
        this.totalArticleRef = React.createRef();
    }

    componentDidMount() {
      //  $("#totalArticleRef").html("Numar articole:" + this.totalArticle);
        let node = this.totalArticleRef.current;
        node.innerHTML="Numar articole: " + this.totalArticle;
    }

    aggregator(content) {
        let article = IndexContentHelper.getArticle(content);
        this.totalArticle += article;
        return article;
    }

    render() {

        let this_=this;
        return (
            <div className="home index home-index">

                <div ref={ this.totalArticleRef} id="totalArticleRef"  className="row p-2 card text-white bg-card">
                    Numar articole: {this.totalArticle}
                </div>

                <div className="row pt-4">

                    {IndexContent.content.map(function(item, index){

                        return <div  key={item.url} className="col-sm-4 p-1">
                                <Link to={item.url} key={item.url}>
                                    <div className="card text-white bg-card">
                                        <div className="card-body">
                                            <h5 className="card-title" dangerouslySetInnerHTML={{__html: item.title}}/>
                                            <span>
                                                <span className="card-text" dangerouslySetInnerHTML={{__html: item.subtitle}}/>
                                                <span className="badge badge-dark m-1">{ this_.aggregator(item.content) }</span>
                                            </span>
                                        </div>
                                    </div>
                                </Link>

                            </div>;

                    })}

                </div>

            </div>
        );
    }
}

export default IndexContent;