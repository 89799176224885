import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class WanLanIsolateNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-wan-lan-isolate", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Izolare in retea LAN de WAN</b>

                    <br/>
                    <br/>

                    <i>Problema</i>: dispozitivele conectate la reteaua wireless (WLAN) nu pot sa comunica cu dispozitivele conectate la reteaua prin cablu (LAN – Ethernet).
                    <br/>
                    <i>Exemplu</i>:  imprimanta conectata wireless la ONT-ul Digi si un computer conectat prin cablu.

                    <br/>

                    Aceasta functie se poate numi:
                    <ul>
                        <li>Isolate from LAN</li>
                        <li>Wireless Isolation</li>
                        <li>AP Isolation </li>
                        <li>Station Isolation</li>
                        <li>Client Isolation</li>
                    </ul>

                    <hr/>

                    Deci, daca in retea sunt dispozitive/echipamente contectate prin cablu (LAN) si dispozitive/echipamente fara fir - adica wireless - (WAN) si aceste doua grupuri:
                    LAN si WAN nu se vad intre ele, atunci e posibil ca din router sa exista o setare de separare a retelelor wireless si ethernet de a putea comunica intre ele dispozitivele din cele doua retele.
                    <br/>
                    <br/>
                    Daca sunteti client Digi, aveti router Fiberhome AN5506-02-FG, atunci trebuie sa va conectati la contul digi si sa faceti o sesizare, de tipul:
                    <br/>

                    As vrea <b>dezactivarea separarii retelelor wireless si ethernet pentru a putea comunica intre ele dispozitivele din cele doua retele</b>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.media-max.ro/separare-sau-izolare-retele-wireless-ethernet-digi/"}>Separare sau izolare retele wireless-ethernet Digi</a>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WanLanIsolateNasContent;