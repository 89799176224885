import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class BrowserslistAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-cli", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <hr/>
                    <b>
                        Differential loading: browserslist
                    </b>
                    <br/>
                    <br/>
                    Angular genereaza aplicatii pentru diverse browsere! (in <i>dist</i>). Acest lucru poarta numele de "differential loading".
                    <br/>
                    In fisierul <b>browserlist</b> se poate specifica ce browsere trebuie sa fie suporte aplicatia:
                    <SyntaxHighlighter>
                        {'# This file is used by the build system to adjust CSS and JS output to support the specified browsers below.\n' +
                            '# For additional information regarding the format and rule options, please see:\n' +
                            '# https://github.com/browserslist/browserslist#queries\n' +
                            '\n' +
                            '# For the full list of supported browsers by the Angular framework, please see:\n' +
                            '# https://angular.io/guide/browser-support\n' +
                            '\n' +
                            '# You can see what browsers were selected by your queries by running:\n' +
                            '#   npx browserslist\n' +
                            '\n' +
                            'last 1 Chrome version\n' +
                            'last 1 Firefox version\n' +
                            'last 2 Edge major versions\n' +
                            'last 2 Safari major versions\n' +
                            'last 2 iOS major versions\n' +
                            'Firefox ESR\n' +
                            'not IE 9-10 # Angular support for IE 9-10 has been deprecated and will be removed as of Angular v11. To opt-in, remove the \'not\' prefix on this line.\n' +
                            'not IE 11 # Angular supports IE 11 only as an opt-in. To opt-in, remove the \'not\' prefix on this line.\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        Proiecte multiple
                    </b>
                    <br/>
                    <br/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BrowserslistAngularContent;