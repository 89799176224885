import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"robots-root", url:"#", title:"Roboti", subtitle:""},
        {id:"robots-my", url:"/robots/my", title:"Lista roboti", subtitle:"", parent:"robots-root"},
        {id:"robots-rux", url:"/robots/rux", title:"Rux", subtitle:"", parent:"robots-root"},

        {id:"robots-util", url:"/robots/util", title:"Link-uri utile", subtitle:"", parent:"robots-root"},
    ]

    static indexUrl = "/llm/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Raspberry PI
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;