import React, {useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

const Componenta = ()=>{

    const onSave = () => {
        alert("onSave")
    }

    return(
        <button onClick={onSave}>Salveaza</button>
    )

}


const Componenta2 = ()=>{

    let [text, setText] = useState("")

    const update = (e) => {
        setText(e.target.value);
    }

    return(
       <>
           <input onChange={update} type="text" class="form-control"/>
           {text}
       </>

    )

}

class TwoWayBindingAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-two-way-binding", IndexContent);
    }

    onSave = () => {
        alert("onSave")
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Two-Way-Binding / Legarea bidirectionala</b>
                    <br/>
                    <br/>

                    <b>Important</b>:
                    <br/>

                    Pentru ca legarea bidirecțională să funcționeze, trebuie să activata directiva <b>ngModel</b>.
                    <br/>
                    Acest lucru se face prin adăugarea <b>FormsModule</b>  la <b>imports[]</b> în AppModule.

                    <br/>
                    Apoi, trebuie să adaugat și importul din <b>@angular/forms</b>  fișierul <b>app.module.ts</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { FormsModule } from \'@angular/forms\';'}
                    </SyntaxHighlighter>

                    <hr/>

                    Folosind aceasta modalitate, se modifica si in UI si in model.

                    <br/>

                    In HTML template:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input [(ngModel)]="text" type="text" class="form-control">\n' +
                            '{{text}}'}
                    </SyntaxHighlighter>

                    In clasa componenta:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'text=\'\';'}
                    </SyntaxHighlighter>

                    <hr/>
                    Ca si comparatie, reaiau exemplul de la 1-way binding
                    (prin aceasta abordare daca se modifica <i>text</i>, si nu prin input-ul de mai jos, in UI, valoare din campul input, UI-ul nu se modifica):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input (input)="update($event)" type="text" class="form-control">\n' +
                            '{{text}}'}
                    </SyntaxHighlighter>

                    In clasa componenta:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'text=\'\';\n' +
                            'update(event: Event){\n' +
                            '   this.text = (<HTMLInputElement>event.target).value;\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    In React, nu există legare bidirecțională a datelor!
                    Dar, vezi si: <a target={"_blank"} href={"https://legacy.reactjs.org/docs/two-way-binding-helpers.html"}>Two-way Binding Helpers</a>!
                    (insa este depreciat)



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TwoWayBindingAngularContent;