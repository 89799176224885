import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class CleanupCmdDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "cleanup-cmd-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cleanup Container</b>
                    <br/>

                    Docker nu face automat curat (clean up) in containere. Pentru a realiza acest lucru trebuie rulalat din PowerShell:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container rm -f $(docker container ls -aq) \n' +
                        '8598155d1efa\n' +
                        'e1cad3ce73af\n' +
                        'e29bdd3a597c'}
                    </SyntaxHighlighter>

                    <b>2. Cleanup imagine</b>
                    <br/>
                    Sterge una sau mai multe imagini:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker image rm -f $(docker image ls -f reference=\'imagine/*\' -q) \n' +
                        '8598155d1efa\n' +
                        'e1cad3ce73af\n' +
                        'e29bdd3a597c'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                Learn Docker in a month of lunches - Elton Stoneman, Manning, 2020
                            </div>
                        </li>

                        <li>
                            <div>
                                Docker Demystified - Saibal Ghosh, BPB, 2021
                            </div>
                        </li>

                        <li>
                            <div>

                                <a href={"https://ocw.cs.pub.ro/courses/idp/laboratoare/01"}>Laboratorul 01 - Docker</a>

                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CleanupCmdDockerContent;