import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import Auth from "./Auth";

const PrivateRoute = ({ element }) => {

    const [state, setState] = useState('loading');

    const auth = new Auth();

    useEffect(() => {
        (async function() {
            try {
                /* Update effect logic to track correct state */
                const isUserLogged = await auth.isAuthenticated();
                setState(isUserLogged ? 'loggedin' : 'redirect');
            }
            catch {
                setState('redirect');
            }
        })();
    }, []);

    if(state === 'loading') {
        return <div>Loading..</div>
    }

    return state === 'loggedin'
            ? element
            : <Navigate to='/access-denied' />

}

export default PrivateRoute;