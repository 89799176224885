import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class HostNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-host", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>
                        1. Creare retea de tip host
                    </b>
                    <br/>
                    <br/>

                    O retea de tip <b>host</b>:
                    <ul>
                        <li>
                            elimina isolarea dintre docker host si containere docker, utilizandu-se retea gazdei/host in mod direct
                        </li>
                        <li>
                            de exemplu, daca se ruleaza un container care asculta pe portul 80 si se foloseste reteaua host,
                            atunci containerul este disponibil de pe masina gazda pe portul 80 si adresa IP a masinii gazde.
                        </li>
                    </ul>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-bridge-host --network=host busybox'}
                    </SyntaxHighlighter>
                    Daca ne conectam la container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it busybox-bridge-host sh'}
                    </SyntaxHighlighter>
                    si apoi rulam comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'hostname'}
                    </SyntaxHighlighter>

                    se va afisa: kj-Standard-PC-i440FX-PIIX-1996, adica numele masinii gazda.

                    <hr/>
                    Daca in container, avem de exemplu un server, nginx; si acesta asculta pe portul 80;
                    atunci nu este nevoie la pornirea containerului de optiunea <b>-p 80:80</b>;
                    se poate accesa in mod direct folosind adresa masinii gazda si portul 80.

                    <hr/>
                    si daca rulam din container apoi rulam comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ipconfig'}
                    </SyntaxHighlighter>
                    va afisa aceleasi rezultate ca si cum ar fi executata pe masina gazda.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HostNetworkDockerContent;