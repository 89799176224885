import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class CustomResourceDefinitionsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-crd", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Custom Resource Definitions (crd)</b>
                    <br/>
                    <br/>



                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get cdr'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'NAME                                                  CREATED AT\n' +
                            'bgpconfigurations.crd.projectcalico.org               2023-02-05T10:43:01Z\n' +
                            'bgppeers.crd.projectcalico.org                        2023-02-05T10:43:01Z\n' +
                            'blockaffinities.crd.projectcalico.org                 2023-02-05T10:43:01Z\n' +
                            'caliconodestatuses.crd.projectcalico.org              2023-02-05T10:43:01Z\n' +
                            'clusterinformations.crd.projectcalico.org             2023-02-05T10:43:01Z\n' +
                            'felixconfigurations.crd.projectcalico.org             2023-02-05T10:43:01Z\n' +
                            'globalnetworkpolicies.crd.projectcalico.org           2023-02-05T10:43:01Z\n' +
                            'globalnetworksets.crd.projectcalico.org               2023-02-05T10:43:02Z\n' +
                            'hostendpoints.crd.projectcalico.org                   2023-02-05T10:43:02Z\n' +
                            'ipamblocks.crd.projectcalico.org                      2023-02-05T10:43:02Z\n' +
                            'ipamconfigs.crd.projectcalico.org                     2023-02-05T10:43:02Z\n' +
                            'ipamhandles.crd.projectcalico.org                     2023-02-05T10:43:02Z\n' +
                            'ippools.crd.projectcalico.org                         2023-02-05T10:43:02Z\n' +
                            'ipreservations.crd.projectcalico.org                  2023-02-05T10:43:02Z\n' +
                            'kubecontrollersconfigurations.crd.projectcalico.org   2023-02-05T10:43:02Z\n' +
                            'networkpolicies.crd.projectcalico.org                 2023-02-05T10:43:02Z\n' +
                            'networksets.crd.projectcalico.org                     2023-02-05T10:43:03Z\n'}
                    </SyntaxHighlighter>

                    Afisare detalii despre crd-ul IPPool (ippools.crd.projectcalico.org):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get crd ippools.crd.projectcalico.org -o yaml'}
                    </SyntaxHighlighter>
                    poate afisa (fragment)
                    <SyntaxHighlighter>
                        {'spec:\n' +
                            '  conversion:\n' +
                            '    strategy: None\n' +
                            '  group: crd.projectcalico.org\n' +
                            '  names:\n' +
                            '    kind: IPPool\n' +
                            '    listKind: IPPoolList\n' +
                            '    plural: ippools\n' +
                            '    singular: ippool\n' +
                            '  scope: Cluster\n' +
                            '  versions:\n' +
                            '  - name: v1\n'}
                    </SyntaxHighlighter>


                    Afisare resurse de tip IPPool:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get IPPool'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                  AGE\n' +
                            'default-ipv4-ippool   6d10h\n'}
                    </SyntaxHighlighter>

                    Detalii despre resursa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get IPPool -o yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'apiVersion: v1\n' +
                            'items:\n' +
                            '- apiVersion: crd.projectcalico.org/v1\n' +
                            '  kind: IPPool\n' +
                            '  metadata:\n' +
                            '    annotations:\n' +
                            '      projectcalico.org/metadata: \'{"uid":"4167d1cf-1c0a-4fa5-ae69-e63cdc000b7b","creationTimestamp":"2023-02-05T10:43:18Z"}\'\n' +
                            '    creationTimestamp: "2023-02-05T10:43:18Z"\n' +
                            '    generation: 1\n' +
                            '    name: default-ipv4-ippool\n' +
                            '    resourceVersion: "596"\n' +
                            '    uid: c7a04e14-a421-44e4-9438-f9bbe614ac4b\n' +
                            '  spec:\n' +
                            '    allowedUses:\n' +
                            '    - Workload\n' +
                            '    - Tunnel\n' +
                            '    blockSize: 26\n' +
                            '    cidr: 192.168.0.0/16\n' +
                            '    ipipMode: Always\n' +
                            '    natOutgoing: true\n' +
                            '    nodeSelector: all()\n' +
                            '    vxlanMode: Never\n' +
                            'kind: List\n' +
                            'metadata:\n' +
                            '  resourceVersion: ""\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Creare resursa personalizata</b>
                    <br/>
                    <br/>
                    Pentru a avea un nou tip de resursa (kind) trebuie definit acest nou tip.
                    <br/>
                    Pasi de urmat:
                    <ul>
                        <li>1. creare obiect crd (Custom Resource Definition)</li>
                        <li>2. creare controller (fara el, se poate crea resursa, dar nu va face nimic)</li>
                    </ul>
                    1. creare obiect crd (Custom Resource Definition)
                    <br/>
                    <br/>
                    Acest lucru se face prin intermediul unui fisier yaml + obiect CRD (Custom Resource Definition):
                    <SyntaxHighlighter>
                        {'apiVersion: apiextensions.k8s.io/v1\n' +
                            'kind: CustomResourceDefinition\n' +
                            'metadata:\n' +
                            '  # name must match the spec fields below, and be in the form: <plural>.<group>\n' +
                            '  name: tickets.theater.com\n' +
                            'spec:\n' +
                            '  # either Namespaced or Cluster\n' +
                            '  scope: Namespaced\n' +

                            '  # group name to use for REST API: /apis/<group>/<version>\n' +
                            '  group: theater.com\n' +

                            '  names:\n' +
                            '    # kind is normally the CamelCased singular type. Your resource manifests use this.\n' +
                            '    kind: Ticket\n' +

                            '    # plural name to be used in the URL: /apis/<group>/<version>/<plural>\n' +
                            '    plural: tickets\n' +

                            '    # singular name to be used as an alias on the CLI and for display\n' +
                            '    singular: ticket\n' +

                            '    # shortNames allow shorter string to match your resource on the CLI\n' +
                            '    shortNames:\n' +
                            '    - tt\n'+

                            '  # list of versions supported by this CustomResourceDefinition\n' +
                            '  versions:\n' +
                            '    - name: v1\n' +
                            '      # Each version can be enabled/disabled by Served flag.\n' +
                            '      served: true\n' +
                            '      # One and only one version must be marked as the storage version.\n' +
                            '      storage: true\n' +
                            '      schema:\n' +
                            '        openAPIV3Schema:\n' +
                            '          type: object\n' +
                            '          properties:\n' +
                            '            spec:\n' +
                            '              type: object\n' +
                            '              properties:\n' +
                            '                location:\n' +
                            '                  type: string\n' +
                            '                seats:\n' +
                            '                  type: string\n' +
                            '                number:\n' +
                            '                  type: integer\n' +
                            '                  minimum: 1\n' +
                            '                  maximum: 10\n'
                           }
                    </SyntaxHighlighter>
                    Observatii:
                    <ul>
                        <li>un obiect CRD poate fi Namespaced sau Cluster</li>
                    </ul>

                    <SyntaxHighlighter>
                        {'kubectl get crd collectors.monitoring.controller'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl describe crd collectors.monitoring.controller'}
                    </SyntaxHighlighter>

                    2. creare controller (fara el, se poate crea resursa, dar nu va face nimic)
                    <br/>
                    <br/>
                    Controller-ul se poate scrie in:
                    <ul>
                        <li>Go</li>
                        <li>Python</li>
                    </ul>
                    Apoi acesta va fi pus intr-un pod (imagine docker)
                    <hr/>
                    Custom Respurce Definition + Custom Contooler pot fi deployate ca o singura entitate folosind <b>Operator Framework</b>!
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomResourceDefinitionsK8sContent;