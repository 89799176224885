import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ClassReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-class", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Clase</b>
                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'class Person{\n' +
                            '  constructor(name){ // constructor\n' +
                            '  \tthis.name = name;\n' +
                            '  }\n' +
                            '  \n' +
                            '  salut(){ // metoda\n' +
                            '  \treturn `Buna, ${this.name}`;\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            'const p = new Person("kj");\n' +
                            'console.log(p.salut());'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClassReactContent;