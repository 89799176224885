import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class RadialBasisFunctionContent extends BaseContentPage {

    constructor(props) {
        super(props, "rbnn", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">
                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify"}>
                    Retele neurale cu functii de baza radiale (RBF- Radial Basis Function Neural Network) au fost popularizata de catre Moody si Darken (1989).
                    <br/>
                    Aceste retele au aceeasi structura ca si perceptronul, fiind retele feed-forward atrenate cu ajutorul unei algoritm de invatare supervizat.
                </div>
                <br/>

                <div className={"text-justify important"}>
                    RBF este compusă din:
                    <ul>
                        <li>stratul de intrare (input layer); să presupunem că este format din <b>n</b> neuroni </li>
                        <li><b>un sigur strat ascuns</b> (hidden layer); să presupunem că este format din <b>m</b> neuroni </li>
                        <li>stratul de iesire (output layer)</li>
                    </ul>
                    Statul ascuns este compus din neuroni care sunt activati de <b>functii de baza radiale</b>; acesti neuroni reprezinta <b>campul receptor</b>.
                    Functia de activare a campului receptor poate fi de exemplu functia gausiana n-dimensionala (caz particular de functie de baza radiala).
                    <br/>
                    Centrul unei functii de baza pentru un nod <i>i</i> din stratul ascuns este un vector c<sub>i</sub> a carei dimensiune este egala nu numarul de neuroni din stratul de intrare.
                    <br/>
                    Functia de activare pentru un neuron i din stratul de iesire:
                    <MathComponent tex={String.raw`y_k(x) = \sum_{j=1}^m w_{kj} h_j(x)`}/>
                    unde:
                    <ul>
                        <li>
                            <b>h<sub>j</sub></b> este iesirea neuronului <i>j</i>:
                            <MathComponent tex={String.raw`h_j(x)=exp(-{||x-c_j|| \over 2r_j^2})`}/>
                            de obicei raza r:
                            <MathComponent tex={String.raw`r_j={d_{max} \over \sqrt 2m}`}/>
                            sau
                            <MathComponent tex={String.raw`r_j=2d_{avg}`}/>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>
                               Victor Neagoe. 2018. Retele neurale pentru exploarea datelor, Matrixrom
                            </div>
                            <div>
                                <a href={"https://towardsdatascience.com/radial-basis-functions-neural-networks-all-we-need-to-know-9a88cc053448"}>
                                    Radial Basis Functions Neural Networks — All we need to know
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RadialBasisFunctionContent;