import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class EepromArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-eeprom", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. EEPROM</b>
                    <br/>
                    <br/>

                    Pentru a se pastra unele valori si dupa restart, se foloseste EEPROM.

                    <br/>
                    Limitari:
                    <ul>
                        <li>
                            nu toate placile Arduino au EEPROM (Uno, Mega, Nano: au, Zero, nu)
                        </li>
                        <li>
                            spatiu de stocare: 1024 bytes (Uno, Nano), 4096 (Mega)
                        </li>
                        <li>
                            maxim 100.000 de operatii de scriere pe o singura adresa
                        </li>
                    </ul>

                    Se importa libraria:
                    <SyntaxHighlighter>
                        {'#include <EEPROM.h>'}
                    </SyntaxHighlighter>
                    Functii:
                    <ul>
                        <li>
                            EEPROM.read(address) - citeste valoarea de la address, porneste de la 0
                        </li>
                        <li>
                            EEPROM.write(address, valoare) - scrie valoare, (unde valoare poate fi intre 0 si 255) la address, unde address, porneste de la 0
                        </li>
                        <li>
                            EEPROM.update(address, value)
                        </li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter>
                        {'#include <EEPROM.h>\n' +
                            '\n' +
                            'int value;\n' +
                            '\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  Serial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '  value = EEPROM.read(0);\n' +
                            '  delay(500);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter>
                        {'#include <EEPROM.h>\n' +
                            '\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  for (int i = 0; i < 255; i++)\n' +
                            '    EEPROM.write(i, i);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*     */}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EepromArduinoContent;