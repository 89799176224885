import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../robots/IndexContent";

class UtilRobotContent extends BaseContentPage  {

    constructor(props) {
        super(props, "robot-util", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Link-uri:
                    <ul>
                        <li>
                            <a href={"https://www.personalrobots.biz/otto-ninja-is-great-surprise-for-us-lets-find-out-why/"}>
                                Otto Ninja is great surprise for us, let’s find out why!
                            </a>
                        </li>

                        <li>
                            <a href={"https://hprobots.com/otto-robot/product/"}>
                                Otto
                            </a>
                        </li>

                        <li>
                            <a href={"https://www.personalrobots.biz/top-5-alternatives-to-vector-robot/"}>
                                TOP 5 Alternatives to Vector Robot
                            </a>
                        </li>
                    </ul>

                    Link-uri utile IA:
                    <ul>
                        <li>
                            <a href={"https://www.unite.ai/"}>unite.ai</a>
                        </li>
                        <li>
                            <a href={"https://www.unite.ai/ro/cele-mai-bune-generatoare-de-muzic%C4%83-ai/"}>
                                BEST OF9 cele mai bune generatoare de muzică AI (februarie 2024)
                            </a>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UtilRobotContent;