import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InstallLiferay74CentosContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-install-liferay-74-centos", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Resurse necesare</b>:
                    <ul>
                        <li>
                            Procesor (CPU):
                            <ul>
                                <li>
                                    Minim: 2 nuclee fizice sau virtuale
                                </li>
                                <li>
                                    Recomandat: 4 nuclee fizice sau virtuale sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Memorie (RAM):
                            <ul>
                                <li>
                                    Minim: 4 GB RAM
                                </li>
                                <li>
                                    Recomandat: 8 GB RAM sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Stocare/spatiu pe disk:
                            <ul>
                                <li>
                                    Minim: 10 GB spațiu liber pe disc
                                </li>
                                <li>
                                    Recomandat: 20 GB sau mai mult (în funcție de dimensiunea datelor și a conținutului)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    Mai multe detalii:
                    <a target={"_blank"} href={"https://www.liferay.com/documents/10182/1645493/Liferay+Portal+EE+Reference+Architecture+%26+Hardware+Requirements/7f618f87-ca55-4e39-ba21-b3faadbca206?version=1.3"}>Liferay Portal EE Reference Architecture & Hardware
                        Requirements</a>

                    <hr/>
                    <b>1. Verificare sistem de operare</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cat /etc/os-release'}
                    </SyntaxHighlighter>
                    poatea afisa:
                    <SyntaxHighlighter>
                        {'NAME="Rocky Linux"\n' +
                            'VERSION="8.8 (Green Obsidian)"'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Verificare locatie java</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'which java'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'/usr/bin/java'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'whereis java'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'java: /usr/bin/java /usr/lib/java /etc/java /usr/share/java /opt/jdk-11.0.14/bin/java'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Instalare nano</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo yum install -y nano'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Instalare 7zip</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo yum install p7zip'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Copiere kit in /home/ibrid/kit</b>
                    <br/>
                    <br/>
                    Se copiaza in /home/ibrid/kit
                    <br/>
                    liferay-ce-portal-tomcat-7.4.3.89-ga89-20230809092213693.7z
                    <br/>
                    (sau cu wget -O liferay-ce-portal-tomcat-7.4.3.89-ga89 https://github.com/liferay/liferay-portal/releases/download/7.4.3.89-ga89/liferay-ce-portal-tomcat-7.4.3.89-ga89-20230809092213693.7z)
                    <br/>

                    <hr/>
                    <b>6. Dezarhivare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /home/ibrid/kit'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo 7za x liferay-ce-portal-tomcat-7.4.3.89-ga89-20230809092213693.7z -o/opt'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Permisiuni</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo chown -R $USER:$USER /opt/liferay-ce-portal-7.4.3.89-ga89'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Adaugare JAVA_HOME in setenv.sh</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/bin'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano setenv.sh'}
                    </SyntaxHighlighter>
                    se adauga
                    <SyntaxHighlighter>
                        {'JAVA_HOME=/opt/jdk-11.0.14'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Adaugare permsiuni sa fie vazut serverul de pe alta masina</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.89-ga89/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano portal-ext.properties'}
                    </SyntaxHighlighter>
                    se adauga urmatorul continut:
                    <SyntaxHighlighter>
                        {'include-and-override=portal-developer.properties\n' +
                            'virtual.hosts.valid.hosts=localhost,127.0.0.1,10.129.150.206'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Adaugare fisier portal-setup-wizard.properties</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano portal-setup-wizard.properties'}
                    </SyntaxHighlighter>

                    cu continutul:
                    <SyntaxHighlighter>
                        {'admin.email.from.address=portal@primariagalati.ro\n' +
                            'admin.email.from.name=admin admin\n' +
                            'company.default.locale=en_US\n' +
                            'company.default.name=Primăria Galați\n' +
                            'company.default.time.zone=Europe/Bucharest\n' +
                            'company.default.web.id=primariagalati.ro\n' +
                            'default.admin.email.address.prefix=portal\n' +
                            'default.admin.first.name=admin\n' +
                            'default.admin.last.name=admin\n' +
                            'jdbc.default.driverClassName=org.postgresql.Driver\n' +
                            'jdbc.default.password=pgalati\n' +
                            'jdbc.default.url=jdbc:postgresql://localhost:5432/lportal\n' +
                            'jdbc.default.username=pgalati\n' +
                            'liferay.home=/opt/liferay-ce-portal-7.4.3.89-ga89\n' +
                            'setup.wizard.enabled=false'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>11. Schimbare porturi Liferay</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/conf/'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'nano server.xml'}
                    </SyntaxHighlighter>

                    se modifica din - in:
                    <SyntaxHighlighter>
                        {'<Server port="8005" shutdown="SHUTDOWN"> => <Server port="8006" shutdown="SHUTDOWN">'}
                    </SyntaxHighlighter>
                    si:
                    <SyntaxHighlighter>
                        {'<Connector port="8080" protocol="HTTP/1.1" => <Connector port="8082" protocol="HTTP/1.1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>12. Pornire/Oprire manuala</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/bin'}
                    </SyntaxHighlighter>

                    Pornire:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'./startup.sh'}
                    </SyntaxHighlighter>

                    Oprire:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'./shutdown.sh'}
                    </SyntaxHighlighter>

                    Verificare proces:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ps -ea | grep java'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>13. Verificare loguri</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'tail -f /opt/liferay-ce/tomcat-8.0.32/logs/catalina.out'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>
                        14. Pornire/Oprire prin service
                    </b>
                    <br/>
                    <br/>

                    Crearea fisier /etc/systemd/system/liferay.service
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo nano /etc/systemd/system/liferay.service'}
                    </SyntaxHighlighter>
                    cu urmatorul continutul:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'[Unit]\n' +
                            'Description=Liferay\n' +
                            'Wants=network.target\n' +
                            'After=network.target\n' +
                            '\n' +
                            '[Service]\n' +
                            'Type=forking\n' +
                            '\n' +
                            'User=ibrid\n' +
                            'Group=ibrid\n' +
                            '\n' +
                            'Environment=JAVA_HOME=/opt/jdk-11.0.14\n' +
                            'Environment=CATALINA_PID=/opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/temp/tomcat.pid\n' +
                            'Environment=CATALINA_HOME=/opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75\n' +
                            'Environment=\'CATALINA_OPTS=-Xms512M -Xmx1G -Djava.net.preferIPv4Stack=true\'\n' +
                            '\n' +
                            'ExecStart=/opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/bin/startup.sh\n' +
                            'ExecStop=/opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/bin/shutdown.sh\n' +
                            '\n' +
                            'SuccessExitStatus=143\n' +
                            'UMask=0007\n' +
                            'RestartSec=10\n' +
                            'Restart=always\n' +
                            '\n' +
                            '[Install]\n' +
                            'WantedBy=multi-user.target\n'}
                    </SyntaxHighlighter>

                    apoi:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'# 2. Acordare permisiuni\n' +
                            'udo chown -R $USER:$USER /etc/systemd/system/liferay.service\n\n' +
                            '# 3. Reincarcare demonul systemd, astfel încât să știe despre fișierul nostru de serviciu\n' +
                            'sudo systemctl daemon-reload\n' +
                            '\n' +
                            '# 4. Activare serviciu:\n' +
                            'sudo systemctl enable liferay\n' +
                            '\n' +
                            'poate afisa:\n' +
                            'Created symlink /etc/systemd/system/multi-user.target.wants/liferay.service -> /etc/systemd/system/liferay.service.\n' +
                            '\n' +
                            '# 5. Pornire serviciu Liferay:\n' +
                            'sudo systemctl start liferay\n' +
                            '\n' +
                            '# 6. Status serviciu Liferay:\n' +
                            'systemctl status liferay'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>15. Verificare in browser</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'http://83.103.189.182:8082'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>16. Drepturi pe log-uri</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.89-ga89/logs'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo chmod 777 *'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Posibile probleme</b>
                    <br/>
                    <br/>

                    Posibile probleme:
                    <SyntaxHighlighter>
                        {'8259 ExecStart=/opt/liferay-ce-portal-7.4.3.89-ga89/tomcat-9.0.75/bin/startup.sh (code=exited, status=217/USER)'}
                    </SyntaxHighlighter>
                    Rezolvare:
                    <br/>
                    De verificat in /etc/systemd/system/liferay.service daca e pusa calea corecta catre java
                    <SyntaxHighlighter>
                        {'nano /etc/systemd/system/liferay.service\n' +
                            'Environment=JAVA_HOME=/usr/bin/java'}
                    </SyntaxHighlighter>

                    Daca inca tot nu porneste:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo journalctl -xe'}
                    </SyntaxHighlighter>
                    De verificat in /etc/systemd/system/liferay.service e pus User + Group corect
                    <SyntaxHighlighter>
                        {'nano /etc/systemd/system/liferay.service\n' +
                            'User=ibrid\n' +
                            'Group=ibrid'}
                    </SyntaxHighlighter>

                    Daca nu porneste verifica locatia JDK Java:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'whereis java'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'java: /usr/bin/java /usr/lib/java /etc/java /usr/share/java /opt/jdk-11.0.14/bin/java'}
                    </SyntaxHighlighter>

                    Se executa din nou comenzile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl daemon-reload\n' +
                            'sudo systemctl enable liferay\n' +
                            'sudo systemctl start liferay'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*       <li>*/}
                {/*           <a target={"_blank"} href={"https://linuxhint.com/install_oracle_jdk11_ubuntu/"}>*/}
                {/*               Install Oracle JDK 11 on Ubuntu*/}
                {/*           </a>*/}
                {/*       </li>*/}

                {/*        <li>*/}
                {/*            <a target={"_blank"} href={"https://computingforgeeks.com/how-to-install-java-11-on-ubuntu-linux/"}>*/}
                {/*                Install Java 11 on Ubuntu 22.04*/}
                {/*            </a>*/}
                {/*        </li>*/}

                {/*        <li>*/}
                {/*            <a target={"_blank"} href={"https://www.cherryservers.com/blog/how-to-install-java-on-ubuntu"}>*/}
                {/*                How to Install Java on Ubuntu 22.04 | Step-by-Step Tutorial*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallLiferay74CentosContent;