import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class GansContent extends BaseContentPage {

    constructor(props) {
        super(props, "gans", IndexContent);
    }

    render() {
        return (
            <div className="home gan">
                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify"}>
                    Retele generativ-adverse (GANs)
                </div>
                <br/>

                <div className={"text-justify important"}>
                    GAN-urile au fost introduse in 2014 de catre Ian Goodfellow si fac parte din retele cu invatare nesupervizata.
                    <br/>
                    GAN-urile sunt construite din doua parti:
                    <ul>
                        <li>
                            discriminator (D):
                            <ul>
                                <li>
                                    este o retea CNN, cu diferenta ca GAN-urile pot avea doar doua iesiri (1, pentru date reale, 0 pentru date false);<br/>
                                    deci este o retea capabila sa recunoasca datele reale.
                                </li>
                            </ul>
                        </li>
                        <li>
                            generator (G):
                            <ul>
                                <li>
                                    este opusul unui retele CNN, în sensul in care:<br/>
                                    - CNN (discriminatorul) ia o imagine reala ca data de intrare si o eticheteaza (iesirea retelei este o eticheta: 1 sau 0)<br/>
                                    - opusul CNN (adica geneatorul) primeste un zgomot aleator (Z) ca data de intrare si genereaza ca iesire o imagine reala (O)
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Modul de functionare:
                    <ul>
                        <li>avem un zgomot aleator (Z)</li>
                        <li>acest zgomot aleator devine intrare pentru generator (Z{"->"}G)</li>
                        <li>generatorul genereza o iesire/imagine din zgomotul aleator (G{"->"}O)</li>
                        <li>iesirea generatorului (O) devine data de intrare pentru discrimintor (I{"->"}D)</li>
                        <li>discriminatorul analizeaza datele (O) si verifica cat de aproape sunt datele (O) pentru a fi considerate reale</li>
                        <li>daca nu sunt considerate reale, atunci discriminatorul 'respinge' data generata de generator, iar generatorul isi ajusteaza ponderile astfel incat sa genereze o data mai buna decat precendentele</li>
                        <li>procesul de mai sus continua pana cand discriminatorul se va lasa inselat de o intrare genereata si o va accepta ca fiind o imagine reala</li>
                    </ul>

                    Deci, Discriminatorul spune ce este real și ce este fals, iar Generator încearcă să-l păcălească pe Discriminator și să-l facă să creadă că datele generate sunt reale.
                    Cu fiecare încercare nereușită, Generatorul învață și se îmbunătățește pentru a produce date cat mai reale.
                    Se poate considera ca exista o competiție între Generator și Discriminator.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>
                                <a href={"https://www.geeksforgeeks.org/generative-adversarial-networks-gans-an-introduction/?ref=rp"}>Generative Adversarial Networks (GANs) | An Introduction</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GansContent;