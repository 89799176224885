import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class TomcatSSLLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-tomcat-ssl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Configurarea HTTPs/SSL pentru Liferay împreună cu Tomcat se poate face in doua moduri:
                    <ul>
                        <li>Keystore - pentru certificatele <i>autosemnate</i> generate</li>
                        <li>SSLCertificateFile - pentru certificatele obținute extern</li>
                    </ul>

                    <hr/>
                    <b>1. Configurare SSL cu Keystore</b>
                    <br/>
                    <br/>
                    Aceasta abordare se foloseste atunci când nu exista un certificat SSL real, dar trebuie activat HTTPs pentru Tomcat (de exemplu, pe o mașină locală sau pe serverul de dezvoltare)

                    <br/>
                    Pasii:
                    <ul>
                        <li>Generare keystore
                            <hr/>

                            Generarea keystoare se va folosind comanda <b>keytool</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'cd $JAVA_HOME/bin\n' +
                                ' keytool -genkey -alias ALIAS -keyalg RSA keystore PATH_TO_KEYSTORE/.keystore'}
                            </SyntaxHighlighter>
                            unde:
                            <ul>
                                <li>ALIAS - alias pentru certificat</li>
                                <li>PATH_TO_KEYSTORE - calea catre directorul unde <b>.keystore</b> va fi creat</li>
                            </ul>
                        </li>
                        <li>Export keystore

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'keytool -export -alias ALIAS -keystore PATH_TO_KEYSTORE/.keystore -file server.crt'}
                            </SyntaxHighlighter>

                            unde:
                            <ul>
                                <li>ALIAS - alias pentru certificat</li>
                                <li>PATH_TO_KEYSTORE - calea catre directorul unde exista <b>.keystore</b></li>
                            </ul>

                        </li>
                        <li>Import keystore:

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'keytool -import -alias ALIAS -file server.crt -keystore $JAVA_HOME/jre/lib/cacerts'}
                            </SyntaxHighlighter>

                            unde:
                            <ul>
                                <li>ALIAS - alias pentru certificat</li>
                                <li>PATH_TO_KEYSTORE - calea catre directorul unde exista <b>.keystore</b></li>
                            </ul>


                        </li>
                        <li>Configure SSL in Tomcat
                            <br/>
                            se comenteaza urmatoarea linie:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<!--<Listener className="org.apache.catalina.core.AprLifecycleListener" SSLEngine="on" />-->'}
                            </SyntaxHighlighter>

                            se decomenteaza linia cu:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<!-- <Connector port="8443" ...'}
                            </SyntaxHighlighter>

                            se completeaza linia de mai sus cu:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector port="8443" protocol="HTTP/1.1" SSLEnabled="true"\n' +
                                '\n' +
                                '          maxThreads="150" scheme="https" secure="true"\n' +
                                '\n' +
                                '          clientAuth="false" sslProtocol="TLS"\n' +
                                '\n' +
                                '          keystoreFile="PATH_TO_KEYSTORE"\n' +
                                '\n' +
                                '          keystorePass="KEYSTORE_PASSWORD"\n' +
                                '\n' +
                                '/>'}
                            </SyntaxHighlighter>

                            unde:

                            unde:
                            <ul>
                                <li>KEYSTORE_PASSWORD - parola keystore</li>
                                <li>PATH_TO_KEYSTORE - calea catre directorul unde <b>.keystore</b> a fost creat</li>
                            </ul>

                        </li>
                        <li>Restartare Tomcat</li>
                        <li>Verificare URL in browser: https://localhost:8443</li>
                    </ul>

                    <hr/>
                    <b>2. Configurare SSL cu fișiere de certificat</b>
                    <br/>
                    <br/>
                    Aceasta abordare se foloseste atunci când exista un certificat SSL real, dar trebuie activat HTTPs pentru Tomcat (de exemplu, pe o mașină locală sau pe serverul de dezvoltare)


                    <hr/>
                    <b>3. Exemplu</b>
                    <br/>
                    <br/>

                    1. Generare certificat / Keystore:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'keytool -genkey -alias tomcat -keyalg RSA -keystore "C:\\work\\portal\\certificate\\keystore"'}
                    </SyntaxHighlighter>
                    cu parola secret123

                    <br/>
                    <br/>
                    2. Export certificat:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'keytool -export -alias tomcat -keypass changeit -file C:\\work\\portal\\certificate\\server.crt -keystore C:\\work\\portal\\certificate\\keystore'}
                    </SyntaxHighlighter>

                    <br/>
                    <br/>
                    3. Import certificat (rulat pe windows ca administrator) in trust store file (CACERT):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'keytool -importcert -alias tomcat -file C:\\work\\portal\\certificate\\server.crt -keystore "C:\\Program Files\\Java\\jdk-11.0.13\\lib\\security\\jssecacerts" -storepass changeit'}
                    </SyntaxHighlighter>

                    <br/>
                    <br/>
                    4. Listare certificate (verificare):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'keytool -list -keystore "C:\\Program Files\\Java\\jdk-11.0.13\\lib\\security\\jssecacerts" -storepass changeit'}
                    </SyntaxHighlighter>

                    Poate afisa:
                    <SyntaxHighlighter>
                        {'# poate afisa:\n' +
                            'Keystore type: JKS\n' +
                            'Keystore provider: SUN\n' +
                            '\n' +
                            'Your keystore contains 1 entry\n' +
                            '\n' +
                            'tomcat, 29-Sep-2023, trustedCertEntry,\n' +
                            'Certificate fingerprint (SHA1): CA:B9:20:D4:C5:CC:2B:40:06:4F:BA:F9:6A:A7:B5:37:BA:24:34:AD'}
                    </SyntaxHighlighter>

                    <br/>
                    <br/>
                    5. Adaugare in <b>portal-ext.properties</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'web.server.protocol=https'}
                    </SyntaxHighlighter>

                    <br/>
                    <br/>
                    6. Adaugare in <b>server.xml</b> (cale [liferay]\[tomcat]\conf\server.xml):

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<Connector\n' +
                            '           protocol="org.apache.coyote.http11.Http11NioProtocol"\n' +
                            '           port="8443"\n' +
                            '           maxThreads="200"\n' +
                            '           scheme="https"\n' +
                            '           secure="true"\n' +
                            '           SSLEnabled="true"\n' +
                            '           keystoreFile="C:/work/portal/certificate/keystore"\n' +
                            '           keystorePass="secret123"\n' +
                            '           clientAuth="false"\n' +
                            '  sslProtocol="TLS"/>'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a target={"_blank"} href={"https://lifedev-solutions.blogspot.com/2021/03/liferay-tomcat-ssl-configuration.html"}>
                                    Liferay Tomcat SSL Configuration: Keystore and SSLCertificateFile
                                </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a target={"_blank"} href={"https://proliferay.com/configure-https-in-liferay-dxp-7-4-tomcat-9/"}>
                                    Configure HTTPS in Liferay DXP 7.4 (Tomcat 9)
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://crunchify.com/step-by-step-guide-to-enable-https-or-ssl-correct-way-on-apache-tomcat-server-port-8443/"}>
                                    Step by Step guide to Enable HTTPS or SSL correct way on Apache Tomcat Server – Port 8443
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TomcatSSLLiferayContent;