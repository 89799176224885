import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FireAndOnEventLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-fire-and-on-event", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Inregistrare eveniment:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' Liferay.fire(\'numeEveniment\',{\n' +
                        '                param1:paramValue1,\n' +
                        '                param2:paramValue2\n' +
                        '});\n'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Liferay.fire(\'getUserData\',{\n' +
                        '       name: KJ\n' +
                        '});'}
                    </SyntaxHighlighter>

                    Receptionare mesaj emis de un eveniment:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Liferay.on(\'numeEveniment\',function(event) {\n' +
                        '// cod\n' +
                        '});'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Liferay.on(\'getUserData\',function(event) {\n' +
                        ' alert(\'User Name:\'+ event.name)\n' +
                        '});'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"http://www.liferaysavvy.com/2014/01/liferay-client-side-inter-portlet.html"}>
                                    Liferay Client Side Inter Portlet Communication Using Ajax
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FireAndOnEventLiferayContent;