import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class DockerReactAppContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-docker-react-app", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Dockerizare aplicatie React</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1. Dockerfile</b>
                    <br/>
                    <br/>

                    In radacina proiectului se adauga fisierul <b>Dockerfile</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'# pull official base image\n' +
                            'FROM node:13.12.0-alpine\n' +
                            '\n' +
                            '# set working directory\n' +
                            'WORKDIR /app\n' +
                            '\n' +
                            '# add `/app/node_modules/.bin` to $PATH\n' +
                            'ENV PATH /app/node_modules/.bin:$PATH\n' +
                            '\n' +
                            '# install app dependencies\n' +
                            'COPY package.json ./\n' +
                            'COPY package-lock.json ./\n' +
                            'RUN npm install --silent\n' +
                            'RUN npm install react-scripts@3.4.1 -g --silent\n' +
                            '\n' +
                            '# add app\n' +
                            'COPY . ./\n' +
                            '\n' +
                            '# start app\n' +
                            'CMD ["npm", "start"]'}
                    </SyntaxHighlighter>

                    In radacina proiectului se adauga fisierul <b> .dockerignore</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'node_modules\n' +
                            'build\n' +
                            '.dockerignore\n' +
                            'Dockerfile\n' +
                            'Dockerfile.prod'}
                    </SyntaxHighlighter>

                    Build:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker build -t numeProiect:dev .'}
                    </SyntaxHighlighter>

                    Rulare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run \\\n' +
                            '    -it \\\n' +
                            '    --rm \\\n' +
                            '    -v ${PWD}:/app \\\n' +
                            '    -v /app/node_modules \\\n' +
                            '    -p 3001:3000 \\\n' +
                            '    -e CHOKIDAR_USEPOLLING=true \\\n' +
                            '    numeProiect:dev'}
                    </SyntaxHighlighter>
                    Observatii:
                    <ul>
                        <li>
                            <b>--rm</b>: sterge containerul și volumele după ce se iese din container.
                        </li>
                        <li>
                            <b>-e CHOKIDAR_USEPOLLING=true</b> activează un mecanism de polling prin <b>chokidar</b>
                            (care include <i>fs.watch, fs.watchFile</i> și <i>fsevents</i>)
                            astfel încât <b>hot-reload</b> la cald să funcționeze.
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Docker compose</b>
                    <br/>
                    <br/>
                    Se adauga fisierul <b>docker-compose.yml</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'version: \'3.7\'\n' +
                            '\n' +
                            'services:\n' +
                            '\n' +
                            '  numeProiect:\n' +
                            '    container_name: numeProiect\n' +
                            '    build:\n' +
                            '      context: .\n' +
                            '      dockerfile: Dockerfile\n' +
                            '    volumes:\n' +
                            '      - \'.:/app\'\n' +
                            '      - \'/app/node_modules\'\n' +
                            '    ports:\n' +
                            '      - 3001:3000\n' +
                            '    environment:\n' +
                            '      - CHOKIDAR_USEPOLLING=true'}
                    </SyntaxHighlighter>

                    Reconstruire imagine si pornire container:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker-compose up -d --build'}
                    </SyntaxHighlighter>

                    Oprire container:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker-compose stop'}
                    </SyntaxHighlighter>

                    (pe Windows, s-ar putea sa fie nevoie de adaugarea urmatoarei variabile de mediu: <b>COMPOSE_CONVERT_WINDOWS_PATHS=1</b>)

                    <hr/>
                    <b>3. Productie</b>
                    <br/>
                    <br/>

                    In radacina proiectului se adauga fisierul <b>Dockerfile.prod</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'# build environment\n' +
                            'FROM node:13.12.0-alpine as build\n' +
                            'WORKDIR /app\n' +
                            'ENV PATH /app/node_modules/.bin:$PATH\n' +
                            'COPY package.json ./\n' +
                            'COPY package-lock.json ./\n' +
                            'RUN npm ci --silent\n' +
                            'RUN npm install react-scripts@3.4.1 -g --silent\n' +
                            'COPY . ./\n' +
                            'RUN npm run build\n' +
                            '\n' +
                            '# production environment\n' +
                            'FROM nginx:stable-alpine\n' +
                            'COPY --from=build /app/build /usr/share/nginx/html\n' +
                            'EXPOSE 80\n' +
                            'CMD ["nginx", "-g", "daemon off;"]'}
                    </SyntaxHighlighter>

                    Build:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker build -f Dockerfile.prod -t numeProiect:prod .'}
                    </SyntaxHighlighter>

                    Rulare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run -it --rm -p 1337:80 numeProiect:prod'}
                    </SyntaxHighlighter>

                    Se adauga fisierul <b>docker-compose.prod.yml</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'version: \'3.7\'\n' +
                            '\n' +
                            'services:\n' +
                            '\n' +
                            '  numeProiect-prod:\n' +
                            '    container_name: numeProiect-prod\n' +
                            '    build:\n' +
                            '      context: .\n' +
                            '      dockerfile: Dockerfile.prod\n' +
                            '    ports:\n' +
                            '      - \'1337:80\''}
                    </SyntaxHighlighter>

                    Reconstruire imagine si pornire container:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker-compose -f docker-compose.prod.yml up -d --build'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. React Router și Nginx</b>
                    <br/>
                    <br/>

                    Dacă utilizați React Router, atunci va trebui să modificați configurația Nginx implicită în momentul construirii:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'COPY --from=build /app/build /usr/share/nginx/html'}
                    </SyntaxHighlighter>

                    Adăugați modificarea în Dockerfile.prod:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'# build environment\n' +
                            'FROM node:13.12.0-alpine as build\n' +
                            'WORKDIR /app\n' +
                            'ENV PATH /app/node_modules/.bin:$PATH\n' +
                            'COPY package.json ./\n' +
                            'COPY package-lock.json ./\n' +
                            'RUN npm ci --silent\n' +
                            'RUN npm install react-scripts@3.4.1 -g --silent\n' +
                            'COPY . ./\n' +
                            'RUN npm run build\n' +
                            '\n' +
                            '# production environment\n' +
                            'FROM nginx:stable-alpine\n' +
                            'COPY --from=build /app/build /usr/share/nginx/html\n' +
                            '# new\n' +
                            'COPY nginx/nginx.conf /etc/nginx/conf.d/default.conf\n' +
                            'EXPOSE 80\n' +
                            'CMD ["nginx", "-g", "daemon off;"]'}
                    </SyntaxHighlighter>

                    Creați următorul folder împreună cu un fișier nginx.conf :
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'└── nginx\n' +
                            '    └── nginx.conf'}
                    </SyntaxHighlighter>

                    nginx.conf :
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'server {\n' +
                            '\n' +
                            '  listen 80;\n' +
                            '\n' +
                            '  location / {\n' +
                            '    root   /usr/share/nginx/html;\n' +
                            '    index  index.html index.htm;\n' +
                            '    try_files $uri $uri/ /index.html;\n' +
                            '  }\n' +
                            '\n' +
                            '  error_page   500 502 503 504  /50x.html;\n' +
                            '\n' +
                            '  location = /50x.html {\n' +
                            '    root   /usr/share/nginx/html;\n' +
                            '  }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://mherman.org/blog/dockerizing-a-react-app/"}>Dockerizing a React App</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerReactAppContent;