import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AssignClientRolesToUserKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-assign-client-roles-to-user", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Asignare roluri de client la un utilizator</b>
                    <ul>
                        <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                        <li>se face click pe <b>Users</b> (meniul din stanga):</li>
                        <li>se alege un utilizator din lista cu utilizatori (tab <b>Lookup</b>)</li>
                        <li>se alege tab-ul <b>Roles Mappings</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-26.png'}/>
                            </div>
                        </li>
                        <li>se selecteaza din <b>Client Roles</b>: <b>liferay-portal-client</b></li>
                        <li>se poate selecta rolul <b>administrator</b> se apasa pe butonul <b>Add selected</b></li>
                        <li>daca vrem sa stergem un rol de la utilizator, selectem rolul pe care vrem sa il retragem si apasam pe butonul <b>Remove selected</b></li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AssignClientRolesToUserKeycloakContent;