import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";

class ObjectsTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-objects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Obiecte</b>
                    <br/>
                    <br/>

                    Unui obiect i se poate declara un tip, folosind <b>type</b> sau <b>interface</b> sau <i>tip anonim</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'type Config = {\n' +
                            '    name: string;\n' +
                            '    isAdmin?: boolean\n' +
                            '};\n' +
                            '\n' +
                            'const config : Config = {\n' +
                            '    name:\'kj\'\n' +
                            '}'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'interface Config{\n' +
                            '    name: string;\n' +
                            '    isAdmin?: boolean\n' +
                            '}\n' +
                            'const config : Config = {\n' +
                            '    name:\'kj\'\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'const config :{\n' +
                            '    name: string;\n' +
                            '    isAdmin?: boolean\n' +
                            '} = {\n' +
                            '    name:\'kj\'\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Observatii:
                    <ul>
                        <li>
                            ? - specifica faptul ca atributul este <i>optional</i>
                        </li>
                        <li>
                            pentru a specifica ca o variabila poate avea ca valori o lista de obiecte:

                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'let listaObiecte: {}[];'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            O interfata poate avea si metode:

                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'interface Config{\n' +
                                    '    name: string;\n' +
                                    '    isAdmin?: boolean\n' +
                                    '    getStatus?:() => void' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ObjectsTypescriptContent;