import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class VarLetConstJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-var-let-const", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>




                    Important:
                    <ul>
                        <li>
                            <b>var</b>:
                            <ul>
                                <li>
                                    Scop: function scope (domeniu de funcție)
                                </li>
                                <li>
                                    Variabilele declarate cu var sunt disponibile în întreaga funcție în care sunt declarate, indiferent dacă sunt definite în interiorul unui bloc if, for, sau alt bloc.
                                </li>
                                <li>
                                    Variabilele <b>var</b> sunt <b>hoisted</b>, ceea ce înseamnă că declarația lor este mutată la începutul funcției sau contextului global, dar nu și inițializarea lor

                                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'if (true) {\n' +
                                            '    var x = 10; // variabila este definită cu var\n' +
                                            '}\n' +
                                            'console.log(x); // Output: 10 - x este accesibilă în afara blocului'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    var nu respectă scopul de bloc și este limitat doar la funcție sau la nivel global.
                                </li>
                            </ul>


                        </li>
                        <li>
                            <b>let si const</b>:

                            <ul>
                                <li>Scop: block scope (domeniu de bloc)</li>
                                <li>
                                    Variabilele declarate cu let și const sunt disponibile doar în interiorul blocului de cod delimitat de {} în care au fost definite (de exemplu, în interiorul unui if, for, while)
                                </li>
                                <li>
                                    Variabilele <b>let</b> si <b>const</b>  sunt <b>hoisted</b> dar <b>nu</b> în același mod ca var, ceea ce înseamnă că nu pot fi accesate înainte de declarație.
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'console.log(y); // Error: Cannot access \'y\' before initialization\n' +
                                            'let y = 5;'}
                                    </SyntaxHighlighter>
                                    Cu let și const, hoisting-ul există, dar variabilele nu pot fi accesate înainte de linia lor de declarație.
                                    Aceasta se numește <b>Temporal Dead Zone (TDZ)</b>
                                </li>
                                <li>
                                    let și const introduse în ES6 respectă block scope, ceea ce înseamnă că variabilele declarate cu ele sunt accesibile doar în interiorul blocului de cod în care au fost definite.
                                </li>
                                <li>
                                    Utilizarea let și const este recomandată în JavaScript modern pentru a evita problemele legate de scoping și hoisting asociate cu var.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>

                    Un exemplu clasic de problemă cu var în bucle este următorul:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (var i = 0; i < 3; i++) {\n' +
                            '    setTimeout(function() {\n' +
                            '        console.log(i); // Output: 3, 3, 3 - toate cele 3 valori sunt 3\n' +
                            '    }, 1000);\n' +
                            '}'}
                    </SyntaxHighlighter>
                    În acest exemplu, deoarece var nu are scop de bloc, variabila i este partajată în cadrul funcției, iar valoarea finală (după terminarea buclei) este utilizată în toate cele 3 funcții setTimeout

                    <br/>
                    Această problemă a fost rezolvată cu let, care respectă scopul de bloc:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (let i = 0; i < 3; i++) {\n' +
                            '    setTimeout(function() {\n' +
                            '        console.log(i); // Output: 0, 1, 2 - fiecare valoare este corectă\n' +
                            '    }, 1000);\n' +
                            '}'}
                    </SyntaxHighlighter>
                    În acest caz, let creează o nouă instanță a variabilei i pentru fiecare iterație a buclei, păstrând valorile corecte pentru fiecare apel de funcție.

                    <hr/>
                    <b>Umbrire</b>
                    <br/>
                    <br/>

                    Legal:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function something() {\n' +
                            '    var special = "JavaScript";\n' +
                            '\n' +
                            '    {\n' +
                            '        let special = 42;   // totally fine shadowing\n' +
                            '\n' +
                            '        // ..\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Ilegal:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function another() {\n' +
                            '    // ..\n' +
                            '\n' +
                            '    {\n' +
                            '        let special = "JavaScript";\n' +
                            '\n' +
                            '        {\n' +
                            '            var special = "JavaScript";\n' +
                            '            // ^^^ Syntax Error\n' +
                            '\n' +
                            '            // ..\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Legal:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function another() {\n' +
                            '    // ..\n' +
                            '\n' +
                            '    {\n' +
                            '        let special = "JavaScript";\n' +
                            '\n' +
                            '        ajax("https://some.url",function callback(){\n' +
                            '            // totally fine shadowing\n' +
                            '            var special = "JavaScript";\n' +
                            '\n' +
                            '            // ..\n' +
                            '        });\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li><b>let</b> (într-un domeniu interior) poate umbri un domeniu de aplicare extern <b>var</b></li>
                        <li><b>var</b> (într-un domeniu interior) poate umbri doar un domeniu exterior <b>let</b> dacă există o limită a funcției între ele</li>
                    </ul>

                    <hr/>
                    <b>Umbrire</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'window.something = 40;\n' +
                            '\n' +
                            'let something = "KJ";\n' +
                            '\n' +
                            'console.log(something);\n' +
                            '// KJ\n' +
                            '\n' +
                            'console.log(window.something);\n' +
                            '// 40'}
                    </SyntaxHighlighter>

                    Declarația letadaugă o somethingvariabilă globală, dar nu o proprietate globală a obiectului.
                    Efectul este că somethingidentificatorul lexical umbrește somethingproprietatea globală a obiectului.
                    <br/>
                    O modalitate simplă de a evita această problemă cu declarații globale: folosiți întotdeauna varpentru global.
                    A se folosi let și const pentru domenii de bloc.

                    <hr/>
                    Într-un Web Worker, referința la obiect global este de obicei făcută folosind self:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Kyle";\n' +
                            'let studentID = 42;\n' +
                            '\n' +
                            'function hello() {\n' +
                            '    console.log(`Hello, ${ self.studentName }!`);\n' +
                            '}\n' +
                            '\n' +
                            'self.hello();\n' +
                            '// Hello, Kyle!\n' +
                            '\n' +
                            'self.studentID;\n' +
                            '// undefined'}
                    </SyntaxHighlighter>

                    <hr/>
                    Fie modulul ES (ESM):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Kyle";\n' +
                            '\n' +
                            'function hello() {\n' +
                            '    console.log(`Hello, ${ studentName }!`);\n' +
                            '}\n' +
                            '\n' +
                            'hello();\n' +
                            '// Hello, Kyle!\n' +
                            '\n' +
                            'export hello;'}
                    </SyntaxHighlighter>
                    În ciuda faptului că sunt declarate la nivelul superior al fișierului (modulului), în domeniul de aplicare evident, studentNameși hellonu sunt variabile globale.
                    În schimb, sunt la nivelul întregului modul sau, dacă preferați, „modul-global”.
                    <br/>
                    Domeniul de aplicare de nivel superior al modulului este coborât din domeniul global, aproape ca și cum întregul conținut al modulului ar fi împachetat într-o funcție.

                    <hr/>
                    Moduri de accesare a referintei catre "global":
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const theGlobalScopeObject =\n' +
                            '    (typeof globalThis != "undefined") ? globalThis :\n' +
                            '    (typeof global != "undefined") ? global :\n' +
                            '    (typeof window != "undefined") ? window :\n' +
                            '    (typeof self != "undefined") ? self :\n' +
                            '    (new Function("return this"))();'}
                    </SyntaxHighlighter>
                    (global - e pt mediul Node, globalThis - e incepand cu ES2020, window - pt browser, self - pt browser, Web Worker; iar  (new Function("return this"))() - e un truc)

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VarLetConstJavaScriptContent;