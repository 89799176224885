import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class HistogramaVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-histograma", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Histograma</b> este folosita pentru a obtine o expunere cat mai corecta. O histograma pentru o imagine digitala este un grafic ce prezinta numarul de pixeli dintr-o imagine care au o anumita luminozitate si cartografiaza numerele in grafic.

                    <br/>

                    Axa orizontala x reprezinta scala luminozitatii (variaza de la 0 la 255):
                    <ul>
                        <li>0 (din partea stanga) reprezinta negrul pur</li>
                        <li>255 (din partea dreapta) reprezinta albul pur</li>
                    </ul>
                    Axa verticala y reprezinta numarul de pixeli intr-un anumit ton:
                    <ul>
                        <li>cu cat o bara verticala este mai inalta, cu atat sunt mai multi pixeli in tonul reprezentat de bara</li>
                    </ul>

                    O expunere corecta ar putea reprezenta o cocoasa in zona centrala a histogramei, fara tonuri de negru si alb pur:
                    <ul>
                        <li>daca vrem sa expunem pamantul, vom expune corect pe el, chiar daca cerul va deveni ars - alb pur (dynamic range-ul nu iarta)</li>
                        <li>daca vrem sa expunem cerul, vom expune corect pe cer, chiar daca pamantul va deveni ars - negru pur (dynamic range-ul nu iarta)</li>
                    </ul>
                    Pentru o expunere corecta a pielii se poate folosi un <b>card gri-18</b>

                    <hr/>

                    Pe Nikon Z6, pentru a afisa histograma:
                    <ul>
                        <li><b>pe vizor</b> (electronic viewfinder / EVF ):
                            <br/>
                            se apasa butonul <b>DISP</b> pana cand apare Historgrama in dreapta-jos (cu ochiul la vizor, in timp ce se priveste prin vizor)
                        </li>
                        <li><b>in Live View</b>:
                            <br/>
                            se apasa butonul <b>DISP</b> pana cand apare Historgrama in dreapta-jos (cu ochii pe LiveView, nu privind prin vizor)
                        </li>
                    </ul>




                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.photopills.com/calculators/timelapse"}>TIMELAPSE CALCULATOR</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://photographylife.com/what-is-depth-of-field"}>Understanding Depth of Field – A Beginner’s Guide</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Contrast"}>Contrast</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.greelane.com/ro/umanistic%c4%83/arte-vizuale/tone-definition-in-art-182471/"}>Ce face tonul pentru arta?</a><br/>
                           </div>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HistogramaVideografieContent;