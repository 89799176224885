import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OopCoreSyntaxPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-oop-core-syntax", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Exista o corespondenta intre operatori sau functii si metode magice.
                    <br/>
                    <br/>
                    Metode de comparare:
                    <table>
                        <thead>
                            <tr>
                                <th>operator / functie</th>
                                <th>metoda magica</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>==</td>
                                <td>__eq__(self, other)</td>
                            </tr>

                            <tr>
                                <td>!=</td>
                                <td>__ne__(self, other)</td>
                            </tr>

                            <tr>
                                <td>&lt;</td>
                                <td>__lt__(self, other)</td>
                            </tr>

                            <tr>
                                <td>{">"}</td>
                                <td>__gt__(self, other)</td>
                            </tr>

                            <tr>
                                <td>&lt;=</td>
                                <td>__le__(self, other)</td>
                            </tr>

                            <tr>
                                <td>{">="}</td>
                                <td>__ge__(self, other)</td>
                            </tr>

                        </tbody>
                    </table>


                    Metode numerice pentru operatori si functii unare:
                    <table>
                        <thead>
                        <tr>
                            <th>operator / functie</th>
                            <th>metoda magica</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>+</td>
                                <td>__pos__(self)</td>
                            </tr>

                            <tr>
                                <td>-</td>
                                <td>__neg__(self)</td>
                            </tr>

                            <tr>
                                <td>abs()</td>
                                <td>__abs__(self)</td>
                            </tr>

                            <tr>
                                <td>round(a,b)</td>
                                <td>__round__(self,b)</td>
                            </tr>

                        </tbody>
                    </table>

                    Metode pentru operatori binari:
                    <table>
                        <thead>
                        <tr>
                            <th>operator / functie</th>
                            <th>metoda magica</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>+</td>
                                <td>__add__(self, other)</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>__sub__(self, other)</td>
                            </tr>
                            <tr>
                                <td>*</td>
                                <td>__mul__(self, other)</td>
                            </tr>
                            <tr>
                                <td>/</td>
                                <td>__div__(self, other)</td>
                            </tr>
                            <tr>
                                <td>//</td>
                                <td>__flooriv__(self, other)</td>
                            </tr>
                            <tr>
                                <td>%</td>
                                <td>__mod__(self, other)</td>
                            </tr>
                            <tr>
                                <td>**</td>
                                <td>__pow__(self, other)</td>
                            </tr>
                        </tbody>
                    </table>

                    Metode pentru operatori binari compusi (augmentati):

                    <table>
                        <thead>
                        <tr>
                            <th>operator / functie</th>
                            <th>metoda magica</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>+=</td>
                            <td>__iadd__(self, other)</td>
                        </tr>
                        <tr>
                            <td>-=</td>
                            <td>__isub__(self, other)</td>
                        </tr>
                        <tr>
                            <td>*=</td>
                            <td>__imul__(self, other)</td>
                        </tr>
                        <tr>
                            <td>/=</td>
                            <td>__idiv__(self, other)</td>
                        </tr>
                        <tr>
                            <td>//=</td>
                            <td>__iflooriv__(self, other)</td>
                        </tr>
                        <tr>
                            <td>%=</td>
                            <td>__imod__(self, other)</td>
                        </tr>
                        <tr>
                            <td>**=</td>
                            <td>__ipow__(self, other)</td>
                        </tr>
                        </tbody>
                    </table>

                    Metode de conversia tipului:

                    <table>
                        <thead>
                        <tr>
                            <th>operator / functie</th>
                            <th>metoda magica</th>
                            <th>semnificatie</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>int()</td>
                                <td>__int__(self)</td>
                                <td>conversie la tipul int</td>
                            </tr>

                            <tr>
                                <td>float()</td>
                                <td>__float__(self)</td>
                                <td>conversie la tipul float</td>
                            </tr>

                            <tr>
                                <td>oct()</td>
                                <td>__oct__(self)</td>
                                <td>conversie la tipul string, continand o reprezentare octala</td>
                            </tr>

                            <tr>
                                <td>hex()</td>
                                <td>__hex__(self)</td>
                                <td>conversie la tipul string, continand o reprezentare hexa</td>
                            </tr>

                        </tbody>
                    </table>

                    Metode de instrospectie (reprezentarea detaliilor unui obiect)

                    <table>
                        <thead>
                        <tr>
                            <th>operator / functie</th>
                            <th>metoda magica</th>
                            <th>semnificatie</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>bool()</td>
                                <td>__bool__(self)</td>
                                <td>responsabil cu gestionarea apelarii functiei bool()</td>
                            </tr>
                            <tr>
                                <td>dir()</td>
                                <td>__dir__(self)</td>
                                <td>responsabil cu gestionarea apelarii functiei dir()</td>
                            </tr>
                            <tr>
                                <td>hash()</td>
                                <td>__hash__(self)</td>
                                <td>responsabil cu gestionarea apelarii functiei hash()</td>
                            </tr>

                            <tr>
                                <td>str()</td>
                                <td>__str__(self)</td>
                                <td>responsabil cu gestionarea apelarii functiei str()</td>
                            </tr>

                            <tr>
                                <td>repr()</td>
                                <td>__repr__(self)</td>
                                <td>responsabil cu gestionarea apelarii functiei repr()</td>
                            </tr>

                            <tr>
                                <td>format()</td>
                                <td>__format__(self, formatstr)</td>
                                <td>apelat atunci când formatarea șirurilor de tip nou este aplicată unui obiect</td>
                            </tr>


                        </tbody>
                    </table>

                    Metode de retropectie:

                    <table>
                        <thead>
                        <tr>
                            <th>operator / functie</th>
                            <th>metoda magica</th>
                            <th>semnificatie</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>isinstance(object, class)</td>
                                <td>__instancecheck__(self, object)</td>
                                <td>responsabil cu gestionarea apelarii functiei isinstance()</td>
                            </tr>
                            <tr>
                                <td>issubclass(subclass, class)</td>
                                <td>__subclasscheck__(self, subclass)</td>
                                <td>responsabil cu gestionarea apelarii functiei issubclass()</td>
                            </tr>
                        </tbody>
                    </table>

                    Metode de access atribute:
                    <table>
                        <thead>
                            <tr>
                                <th>exemplu expresie</th>
                                <th>metoda magica</th>
                                <th>semnificatie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>obiect.attribute</td>
                                <td>__getattr__(self, attribute)</td>
                                <td>responsabil cu gestionarea acesarii unui atribut care nu exista</td>
                            </tr>
                            <tr>
                                <td>obiect.attribute</td>
                                <td>__getattribute__(self, attribute)</td>
                                <td>responsabil cu gestionarea acesarii unui atribut care exista</td>
                            </tr>
                            <tr>
                                <td>obiect.attribute=value</td>
                                <td>__setattr__(self, attribute, value)</td>
                                <td>responsabil cu atriburea unui valori unui atribut</td>
                            </tr>
                            <tr>
                                <td>del obiect.attribute</td>
                                <td>__delattr__(self, attribute)</td>
                                <td>responsabil cu stergerea unui atribut</td>
                            </tr>
                        </tbody>
                    </table>

                    Metode care permit accesul la containere (un container este un obiect care pastreaza un numar arbitrare de alte obiecte si ofera o modalitate de iterare prin obiectele continute; exemple de containere: lista, dictionar, tuplu, set (multime)):

                    Metode de access atribute:
                    <table>
                        <thead>
                            <tr>
                                <th>exemplu expresie</th>
                                <th>metoda magica</th>
                                <th>semnificatie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>len(container)</td>
                                <td>__len__(self)</td>
                                <td>returneaza numarul de elemente dintr-un container</td>
                            </tr>
                            <tr>
                                <td>container[key]</td>
                                <td>__getitem__(self, key)</td>
                                <td>responsabil cu accesarea unui elemente identificat prin argumentul <i>key</i></td>
                            </tr>
                            <tr>
                                <td>container[key] = value</td>
                                <td>__setitem__(self, key, value)</td>
                                <td>responsabil cu setarea unei valori unui element identificat prin argumentul <i>key</i></td>
                            </tr>
                            <tr>
                                <td>del container[key]</td>
                                <td>__delitem__(self, key)</td>
                                <td>responsabil cu stergerea unui element identificat prin argumentul <i>key</i></td>
                            </tr>

                            <tr>
                                <td>for element in container</td>
                                <td>__iter__(self)</td>
                                <td>returneaza un iterator pentru container</td>
                            </tr>

                            <tr>
                                <td>item in container</td>
                                <td>__contains__(self, item)</td>
                                <td>returneaza <i>true</i> daca un <i>item</i> se gaseste in container, si <i>false</i> altfel</td>
                            </tr>
                        </tbody>
                    </table>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Iarba:\n' +
                        '    pass\n' +
                        '\n' +
                        'class Carnita:\n' +
                        '    pass\n' +
                        '\n' +
                        'class Vegan:\n' +
                        '    def __add__(self, obiect):\n' +
                        '        if isinstance(obiect,Iarba):\n' +
                        '            return "Fumat"\n' +
                        '        if isinstance(obiect,Carnita):\n' +
                        '            return "Flamand"\n' +
                        '        return "Nu destul de flamand"\n' +
                        '\n' +
                        'vegan = Vegan()\n' +
                        'iarba = Iarba()\n' +
                        'carnita = Carnita()\n' +
                        '\n' +
                        'print(vegan + vegan)\n' +
                        'print(vegan + iarba)\n' +
                        'print(vegan + carnita)'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OopCoreSyntaxPythonContent;