import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class GithubProviderSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-github-provider-auth", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>GitHub Provider</b>

                    <hr/>
                    Documentatie:
                    <br/>
                    <a target={"_blank"} href="https://developer.github.com/apps/building-oauth-apps/authorizing-oauth-apps">Authorizing OAuth Apps</a>

                    <hr/>

                    Configurare:
                    <br/>
                    <a target={"_blank"} href="https://github.com/settings/apps">GitHub Apps</a>

                    <br/>
                    <br/>
                    Observatie:
                    <br/>
                    Un utilizator sau o organizație poate deține până la 100 de aplicații OAuth.
                    <hr/>

                    Din meniul din coltul dreapta-sus, se apasa pe imaginea de profil, si se face click pe <b>Settings</b>:
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:300}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img.png'}/>
                    </div>

                    Din meniul din stanga, se apasa click pe  <b>Developer settings</b>:
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_1.png'}/>
                    </div>

                    Dim meniul din stanga, se apasa click pe <b>OAuth Apps</b>:
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_2.png'}/>
                    </div>

                    Se completeaza:
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:650}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_3.png'}/>
                    </div>
                    <ul>
                        <li>Application name: lapretbun-springboot</li>
                        <li>Homepage URL: http://localhost:8080</li>
                        <li>Authorization callback URL: http://localhost:8080</li>
                    </ul>
                    Se apasa pe butonul <b>Register Application</b>
                    <br/>
                    <br/>

                    Retinem:
                    <ul>
                        <li>
                            CLient ID: 5fe27d2832e6817c290e
                        </li>
                        <li>
                            Client secret: ***** (se genereaza la apasarea butonului <b>Generatea a new client secret</b>)
                        </li>
                    </ul>
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:650}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/spring-boot/img_4.png'}/>
                    </div>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {''}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GithubProviderSpringBootContent;