import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"angular-root", url:"#", title:"Introducere in Angular", subtitle:""},

        {id:"angular-install", url:"/angular/install", title:"Instalare", subtitle:"", parent:"angular-root"},
        {id:"angular-create-project", url:"/angular/create-project", title:"Creare proiect: ng new", subtitle:"", parent:"angular-root"},
        {id:"angular-create-component", url:"/angular/create-component", title:"Creare componenta: ng generate component", subtitle:"", parent:"angular-root"},
        {id:"angular-string-interpolation", url:"/angular/string-interpolation", title:"Text / String interpolation", subtitle:"", parent:"angular-root"},
        {id:"angular-property-binding", url:"/angular/property-binding", title:"Property binding", subtitle:"", parent:"angular-root"},
        {id:"angular-event-binding", url:"/angular/event-binding", title:"Event binding / Legarea evenimentului", subtitle:"", parent:"angular-root"},
        {id:"angular-two-way-binding", url:"/angular/two-way-binding", title:"Two-Way-Binding / Legarea bidirectionala", subtitle:"", parent:"angular-root"},
        {id:"angular-directives", url:"/angular/directives", title:"Directive", subtitle:"", parent:"angular-root"},
        {id:"angular-update-parent-child", url:"/angular/update-parent-child", title:"Trimitere date de la parinte la copil (anuntare copil in urma unui eveniment al parintelui): @Input / ngOnChanges", subtitle:"", parent:"angular-root"},
        {id:"angular-update-child-parent", url:"/angular/update-child-parent", title:"Trimitere date de la copil la parinte (anuntare parinte in urma unui eveniment al copilului): @Output / EventEmitter", subtitle:"", parent:"angular-root"},
        {id:"angular-local-references", url:"/angular/local-references", title:"Referinta locala: # / @ViewChild", subtitle:"", parent:"angular-root"},

        {id:"angular-inject-content", url:"/angular/inject-content", title:"Injectare continut: ng-content / @ContentChild", subtitle:"", parent:"angular-root"},
        {id:"angular-lifecycle", url:"/angular/lifecycle", title:"lifecycle", subtitle:"", parent:"angular-root"},

        {id:"angular-services", url:"/angular/services", title:"Serviciile", subtitle:"", parent:"angular-root"},
        {id:"angular-routing", url:"/angular/routing", title:"Routing", subtitle:"", parent:"angular-root"},
        {id:"angular-can-activate", url:"/angular/can-activate", title:"Guard: CanActivate (protejare ruta)", subtitle:"", parent:"angular-root"},
        {id:"angular-can-deactivate", url:"/angular/can-deactivate", title:"Guard: CanDeactivate (notificare la parasirea accidenta la paginii)", subtitle:"", parent:"angular-root"},
        {id:"angular-data-to-route", url:"/angular/data-to-route", title:"Trimitere date unei rute (static/dinamic)", subtitle:"", parent:"angular-root"},

        {id:"angular-pipe", url:"/angular/pipe", title:"Pipe", subtitle:"", parent:"angular-root"},
        {id:"angular-observable", url:"/angular/observable", title:"RxJS: Observable", subtitle:"", parent:"angular-root"},
        {id:"angular-subject", url:"/angular/subject", title:"Subject / BehaviorSubject", subtitle:"", parent:"angular-root"},
        {id:"angular-form", url:"/angular/form", title:"Form", subtitle:"", parent:"angular-root"},
        {id:"angular-custom-validator", url:"/angular/custom-validator", title:"Validator custom", subtitle:"", parent:"angular-root"},
        {id:"angular-module", url:"/angular/module", title:"Module", subtitle:"", parent:"angular-root"},
        {id:"angular-slot", url:"/angular/slot", title:"Slot", subtitle:"", parent:"angular-root"},
        {id:"angular-http", url:"/angular/http", title:"Http", subtitle:"", parent:"angular-root"},
        {id:"angular-signals", url:"/angular/signals", title:"Signals", subtitle:"", parent:"angular-root"},
        {id:"angular-deploy", url:"/angular/deploy", title:"Deploy", subtitle:"", parent:"angular-root"},
        {id:"angular-tests", url:"/angular/tests", title:"Testare", subtitle:"", parent:"angular-root"},
        {id:"angular-cypress", url:"/angular/cypress", title:"Testare E2E: Cypress", subtitle:"", parent:"angular-root"},
        {id:"angular-universal", url:"/angular/universal", title:"Angular Universal", subtitle:"", parent:"angular-root"},
        {id:"angular-worker", url:"/angular/worker", title:"Worker", subtitle:"", parent:"angular-root"},
        {id:"angular-cli", url:"/angular/cli", title:"CLI", subtitle:"", parent:"angular-root"},
        {id:"angular-multi-projects", url:"/angular/multi-projects", title:"Multi-proiecte", subtitle:"", parent:"angular-root"},
        {id:"angular-angular-json", url:"/angular/angular-json", title:"Fisier de configurare: angular.json", subtitle:"", parent:"angular-root"},
        {id:"angular-browserslist", url:"/angular/browserslist", title:"Fisier de configurare: (differential loading) browserslist", subtitle:"", parent:"angular-root"},

        {id:"angular-ngrx-root", url:"#", title:"RxJs", subtitle:""},
        {id:"angular-ngrx", url:"/angular/ngrx", title:"NgRx (deprecate)", subtitle:"", parent:"angular-ngrx-root"},
        {id:"angular-ngrx-new", url:"/angular/ngrx-new", title:"NgRx", subtitle:"", parent:"angular-ngrx-root"},
        {id:"angular-ngrx-effects", url:"/angular/ngrx-effects", title:"NgRx Effects (varianta 1)", subtitle:"", parent:"angular-ngrx-root"},
        {id:"angular-ngrx-effects-new", url:"/angular/ngrx-effects-new", title:"NgRx Effects (varianta 2)", subtitle:"", parent:"angular-ngrx-root"},

        {id:"angular-rxjs-root", url:"#", title:"RxJs", subtitle:""},
        {id:"angular-rxjs-tap", url:"/angular/rxjs-tap", title:"Tap", subtitle:"", parent:"angular-rxjs-root"},
        {id:"angular-rxjs-of", url:"/angular/rxjs-of", title:"Of", subtitle:"", parent:"angular-rxjs-root"},

        {id:"angular-components-root", url:"#", title:"Componente", subtitle:""},
        {id:"angular-dynamic-components", url:"/angular/dynamic-components", title:"Componente dinamice", subtitle:"", parent:"angular-components-root"},
        {id:"angular-standalone-components", url:"/angular/standalone-components", title:"Componente standalone", subtitle:"", parent:"angular-components-root"},
        {id:"angular-custom-file-upload", url:"/angular/custom-file-upload", title:"Customizare File Upload", subtitle:"", parent:"angular-components-root"},
        {id:"angular-select", url:"/angular/select", title:"Select", subtitle:"", parent:"angular-components-root"},
        {id:"angular-ng-select", url:"/angular/ng-select", title:"NgSelect", subtitle:"", parent:"angular-components-root"},

        {id:"angular-util-root", url:"#", title:"Util", subtitle:""},
        {id:"angular-function-inject", url:"/angular/function-inject", title:"Injectare prin functia inject()", subtitle:"", parent:"angular-util-root"},

    ]

    static indexUrl = "/angular/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Angular
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;