import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class MultiStepDockerBuildsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-multi-step-builds", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Multi-Stage Build / Multi-Step Builds</b>
                    <br/>
                    <br/>

                    Intr-un fisier <b>Dockerfile</b>, pot exista mai multe comenzi <b>FROM</b>.
                    Este folosit pentru:
                    <ul>
                        <li>reducere imagine Docker</li>
                        <li>organizare</li>
                    </ul>

                    <br/>
                    <br/>
                    Fie fisierul <b>Dockerfile</b> cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'FROM node:16-alpine as builder\n' +
                            'WORKDIR \'/app\'\n' +
                            'COPY package.json .\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'RUN npm run build\n' +
                            '\n' +
                            'FROM nginx\n' +
                            'COPY --from=builder /app/build /usr/share/nginx/html'}
                    </SyntaxHighlighter>

                    Acest fisier defineste 2 pasi de build:
                    <ul>
                        <li>linia 1-6: pasul de build cu numele <i>builder</i> (definit folosind <b>as</b>)
                            <br/>
                            in urma executarii acestui pas va rezulta build-ul aplicatie care va fi pus in: <i>/app/build</i>
                        </li>
                        <li>linia 8-9: pasul de build al doilea (fara nume)
                            <br/>
                            in acest pas se copiaza fisierele din <i>/app/build</i> in <i>/usr/share/nginx/html</i>, folosind <b>--from=builder</b>,
                            adica pas-ul de build cu numele <i>builder</i>
                        </li>
                    </ul>

                    se executa cu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker build .'}
                    </SyntaxHighlighter>

                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  50.69kB\n' +
                            'Step 1/8 : FROM node:16-alpine as builder\n' +
                            ' ---> f7ef5856dc1f\n' +
                            'Step 2/8 : WORKDIR \'/app\'\n' +
                            ' ---> Using cache\n' +
                            ' ---> 3f6cba5dfcb8\n' +
                            'Step 3/8 : COPY package.json .\n' +
                            ' ---> Using cache\n' +
                            ' ---> 87e07cbceb5d\n' +
                            'Step 4/8 : RUN npm install\n' +
                            ' ---> Using cache\n' +
                            ' ---> 78394dde841a\n' +
                            'Step 5/8 : COPY . .\n' +
                            ' ---> d72224103873\n' +
                            'Step 6/8 : RUN npm run build\n' +
                            ' ---> Running in 28a91c701655\n' +
                            '\n' +
                            '> frontend@0.1.0 build\n' +
                            '> react-scripts build\n' +
                            '\n' +
                            'Creating an optimized production build...\n' +
                            'Compiled successfully.\n' +
                            '\n' +
                            'File sizes after gzip:\n' +
                            '\n' +
                            '  46.61 kB  build/static/js/main.f05ad599.js\n' +
                            '  1.78 kB   build/static/js/787.cda612ba.chunk.js\n' +
                            '  541 B     build/static/css/main.073c9b0a.css\n' +
                            '\n' +
                            'The project was built assuming it is hosted at /.\n' +
                            'You can control this with the homepage field in your package.json.\n' +
                            '\n' +
                            'The build folder is ready to be deployed.\n' +
                            'You may serve it with a static server:\n' +
                            '\n' +
                            '  npm install -g serve\n' +
                            '  serve -s build\n' +
                            '\n' +
                            'Find out more about deployment here:\n' +
                            '\n' +
                            '  https://cra.link/deployment\n' +
                            '\n' +
                            'Removing intermediate container 28a91c701655\n' +
                            ' ---> 4d9e123eea3f\n' +
                            'Step 7/8 : FROM nginx\n' +
                            'latest: Pulling from library/nginx\n' +
                            'bd159e379b3b: Already exists\n' +
                            '8d634ce99fb9: Pull complete\n' +
                            '98b0bbcc0ec6: Pull complete\n' +
                            '6ab6a6301bde: Pull complete\n' +
                            'f5d8edcd47b1: Pull complete\n' +
                            'fe24ce36f968: Pull complete\n' +
                            'Digest: sha256:2f770d2fe27bc85f68fd7fe6a63900ef7076bc703022fe81b980377fe3d27b70\n' +
                            'Status: Downloaded newer image for nginx:latest\n' +
                            ' ---> 51086ed63d8c\n' +
                            'Step 8/8 : COPY --from=builder /app/build /usr/share/nginx/html\n' +
                            ' ---> 4799d3ec4036\n' +
                            'Successfully built 4799d3ec4036'}
                    </SyntaxHighlighter>

                    pornim containerul pe baza imaginii <i>4799d3ec4036</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run -p 8181:80 4799d3ec4036'}
                    </SyntaxHighlighter>

                    test:
                    <SyntaxHighlighter>
                        {'http://192.168.1.8:8181/'}
                    </SyntaxHighlighter>

                    <hr/>

                    Se poate specifica sa se execute doar un singur pas/stage, folosind optiuneaa <b>--target</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker build --target builder .'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'FROM ubuntu:18.04 AS stage0\n' +
                            'RUN apt-get update && apt-get install -y git\n' +
                            '\n' +
                            'FROM stage0 as stage1\n' +
                            'RUN git clone http://github.com/iulianbuzdugan/nginx.git'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultiStepDockerBuildsContent;