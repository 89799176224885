export default class Trainer{

    #input=[];

    #answer;

    constructor(x,y){
        this.#answer = x-y > 0 ? -1:1;
        this.#input.push(x);
        this.#input.push(y);
    }

    get input(){
        return this.#input;
    }

    get answer(){
        return this.#answer;
    }
}