import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Tips3DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tips3", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Tips - Storage si Volume</b>

                    <hr/>
                    1. Montare volume
                    <br/>
                    Se pot monta acelasi volum la mai multe containere.

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -dt --name webserver --mount source=kj-volume,target=/etc,readonly busybox sh'}
                    </SyntaxHighlighter>
                    in loc de <b>target</b> se mai poate folosi si: <b>dst</b> sau <b>destination</b>
                    <br/>
                    in loc de <b>source</b> se mai poate folosi si: <b>src</b>
                    <br/>
                    Se poate specifica <b>type</b>; valori posibile:
                    <ul>
                        <li>volume</li>
                        <li>tmpfs</li>
                        <li>bind</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -dt --name webserver -v kj-volume:/etc:ro busybox sh'}
                    </SyntaxHighlighter>

                    <hr/>
                    2. Stergere volume
                    <br/>

                    Un volum anonim  asociat unui container construit cu optiunea <b>--rm</b>, la stergerea containerului se sterge si volumul.

                    <hr/>
                    3. Device Mapper
                    <br/>
                    <ul>
                        <li> loop-lvm mod {'->'} este doar pentru testare</li>
                        <li> direct-lvm mod {'->'} poate fi utilizat in productie</li>
                    </ul>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips3DockerContent;