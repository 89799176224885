import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class HostImageRegistryDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-host-image-registry", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Hostare propriul Docker Image Registry</b>

                    <br/>
                    <br/>

                    Pentru acesta se ruleaza (Docker Image Registry este un container):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -d --name registry -p 5000:5000 --restart=always registry'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Testare</b>
                    <br/>
                    <br/>
                    Pull in Docker Hub:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull busybox'}
                    </SyntaxHighlighter>

                    Tag-uire pentru noul repository:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker tag busybox:latest localhost:5000/kj-busybox'}
                    </SyntaxHighlighter>

                    Push in repository-ul local:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker push localhost:5000/kj-busybox'}
                    </SyntaxHighlighter>

                    Pull din repository-ul local:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull localhost:5000/kj-busybox'}
                    </SyntaxHighlighter>

                    Un registru personal de imagini (Docker Image Registry ) poate fi pe:
                    <ul>
                        <li>local VM ca container docker</li>
                        <li>cloud VM ca container docker</li>
                        <li>Raspberry Pi ca container docker</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HostImageRegistryDockerContent;