import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Tips2LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-tips2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    1. FALS: În timp ce spațiile de lucru Liferay pot fi utilizate în multe medii de dezvoltare diferite, ele pot fi generate numai prin utilizarea Liferay Developer Studio.

                    <hr/>
                    2. FALS: Este foarte recomandat să utilizați baza de date Hypersonic în mediul de producție.

                    <hr/>
                    3. Liferay Developer Studio DXP:
                    <ul>
                        <li>O versiune comercială, produsă de Eclipse</li>
                        <li>conține toate instrumentele necesare dezvoltării Liferay</li>
                        <li>vă permit să instalați automat pachetul Liferay DXP</li>
                    </ul>
                    FALS: permite doar instalați automat pachetul Liferay CE

                    <hr/>
                    4. Liferay Developer Studio CE (Community Edition):
                    <ul>
                        <li>o versiune disponibila open-souce, produsă de Eclipse</li>
                        <li>poate fi instalata on top pe un Eclipse existent</li>
                        <li>disponibil ca bundle Eclipse</li>
                    </ul>
                    FALS: permite instalați automat pachetul Liferay DXP!

                    <hr/>
                    5. FALS: Un bundle poate fi oprit în timp ce este în starea resolved.
                    Un bundle pachetul este în stare resolved, poate fi pornit sau dezinstalat.

                    <hr/>
                    6. Validarea intrărilor este de obicei benefică pentru:
                    <ul>
                        <li>uzabilitate</li>
                        <li>securitate</li>
                        <li>folosirea resurselor</li>
                    </ul>
                    Nu si pentru fiabilitate!

                    <hr/>
                    7. Folosind Service Builder, în  fișierul <b>portlet-model-hints.xml</b> se specifica restricții de date (de exemplu, dimensiunea câmpului, validare, ..).

                    <hr/>
                    8. Pentru MVCRenderCommand trebuie implementata interfata MVCRenderCommand.

                    <hr/>
                    9. Următoarele clase oferă suport pentru furnizarea de mesaje personalizate de succes și de eroare către client:
                    <ul>
                        <li>SessionMessages</li>
                        <li>SessionErrors</li>
                    </ul>

                    <hr/>
                    10. FALS: Acțiunile de nivel superior (Top-level action) sunt acțiuni pentru resurse existente, în timp ce acțiunile pentru resurse (Resource action) sunt acțiuni model pentru resurse inexistente.
                    <br/>
                    Acțiune de nivel superior (Top-level action): acțiuni moel pentru resurse inexistente (ex: ADD_ENTRY pentru numele modelului com.liferay.blogs)
                    <br/>
                    Acțiune resursă (Resource action): acțiuni pentru resursa existentă (ex: DELETE pentru numele modelului com.liferay.blogs.model.BlogsEntry)

                    <hr/>
                    11. Un rol este o colecție de permisiuni care pot fi atribuite:
                    <ul>
                        <li>Users</li>
                        <li>Sites</li>
                        <li>Organizations</li>
                        <li>User groups</li>
                    </ul>

                    <hr/>
                    12. In fisierul <b>default.xml</b> se declara resursele și permisiunile acestora.

                    <hr/>
                    13. <b>Asset framework</b> este un cadru legat de conținut care vă permite să agregați toate tipurile diferite de conținut sub o singură umbrelă sau un nume cunoscut ca <b>asset</b>.

                    <hr/>
                    14. Următoarele aspecte ale asset-ului sunt definite în implementarea AssetRenderFactory:
                    <ul>
                        <li>daca e selectabil</li>
                        <li>daca e categorizabil</li>
                        <li>daca e activ</li>
                        <li>daca e linkabil</li>
                    </ul>
                    Nu sunt definite detalii legate de afisare si localizare.

                    <hr/>
                    15. FALS: Bundle Activator este o clasă care implementează interfața org.osgi.framework.BundleActivator și Liferay utilizează bundle activator pentru a controla diferite stări ale ciclului de viață ale unui pachet.
                    <br/>
                    Bundle Activator este o clasă care implementează interfața org.osgi.framework.BundleActivator, dar Liferay nu se ocupă cu adevărat de acești activatori.

                    Mai multe informatii: <a href={"https://help.liferay.com/hc/en-us/articles/360030826132-Upgrade-Processes-for-Former-Service-Builder-Plugins"}>aici</a>!

                    <hr/>
                    16. OSGi are grijă de bundle și de componentă în același timp (componenta nu are grija singura de ciclul de viata)

                    <hr/>
                    17. Taglib-urile UI revin la <i>bundle de portal</i> dacă nu este găsit un <i>bundle de portlet</i>.

                    <hr/>
                    18. O capacitate (capability) este descrisă de două atribute principale:
                    <ul>
                        <li>Namespace</li>
                        <li>Attributes</li>
                    </ul>
                    Provide Capability – Required Capability este o altă modalitate prin care putem partaja caracteristici.
                    <br/>
                    Ceea ce alcătuiește o capacitate este un spațiu de nume și atribute.
                    <br/>
                    Spațiul de nume este un identificator unic pe care noi, ca dezvoltatori, îl putem declara.
                    <br/>
                    Spațiul de nume poate avea orice fel de nume descriptiv doriți să fie.
                    <br/>
                    La fel este și cu atributele, puteți declara și ce fel de atribute doriți împreună cu capacitatea.

                    <hr/>
                    19. Exportarea unui pachet definește în mod explicit pachetele de exportat și puse la dispoziție altor pachete.

                    <hr/>
                    20. Liferay DXP încă mai suportă dezvoltarea tradițională de portlet bazată pe WAR.

                    <hr/>
                    21. Faza evenimentului MVC (MVC event phase) face ca toate portlet-urile din pagină să fie redate din nou.

                    <hr/>
                    22. Prin convenție, acțiunile de nivel superior sunt referite prin numele pachetului serviciului respectiv,
                    iar acțiunile resursă prin numele complet calificat al entității model vizate.

                    <hr/>
                    23. Cele 3 moduri standard de portlet:
                    <ul>
                        <li>VIEW</li>
                        <li>EDIT</li>
                        <li>HELP</li>
                    </ul>

                    <hr/>
                    24. Pentru a obtine un rezumat (summary) a unui asset se apeleaza metoda: <b>getSummary()</b> pe de interfata <b>AssetRender</b>

                    <hr/>
                    25. Liferay se bazează pe standarde dovedite în industrie, cum ar fi standardul Portlet și JSF.

                    <hr/>
                    26. Cerințe pe care le îndeplinește arhitectura Liferay:
                    <ul>
                        <li>suporta utilizarea tehnologiilor comune de dezolvare</li>
                        <li>valorifică standardele de dezvoltare</li>
                        <li>faciliteaza schimbul de compoente</li>
                        <li>porneste rapid si se comporta bine</li>
                        <li>runtime este usor de configurat si inspectat</li>
                    </ul>

                    <hr/>
                    27. Cele patru entități reprezintă Expandos în baza de date:
                    <ul>
                        <li>
                            ExpandoTable
                        </li>
                        <li>
                            ExpandoEow
                        </li>
                        <li>
                            ExpandoColumn
                        </li>
                        <li>
                            ExpandoValue
                        </li>
                    </ul>

                    <hr/>
                    28. Assets și resurse Liferay vă permit să adăugați câmpuri noi la formularele lor de editare:
                    <ul>
                        <li>Organization</li>
                        <li>Page</li>
                        <li>Role</li>
                        <li>Site</li>
                    </ul>

                    <hr/>
                    29. Politica de referință definește dacă o referință de servicii injectată poate fi înlocuită dinamic.

                    <hr/>
                    30. Opțiunile de politică de referință disponibile:
                    <ul>
                        <li>GREEDY (lacom)</li>
                        <li>RELUCTANT (reticent)</li>
                    </ul>
                    Mai multe: <a href={"https://help.liferay.com/hc/en-us/articles/360018170671-Greedy-Policy-Option-Application"}>Greedy Policy Option Application</a>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference (policyOption = ReferencePolicyOption.GREEDY)\n' +
                        'private SomeService _someService;'}
                    </SyntaxHighlighter>

                    Opțiunea de politică lacomă (ReferencePolicyOption.GREEDY) permite componentei să treacă la utilizarea unei SomeServiceinstanțe de rang superior dacă una devine activă în sistem.

                    <br/>
                    Opțiunile GREEDY si RELUCTANT sunt valide pentru ambele politici de referinta: STATIC si DYNAMIC.
                    <br/>
                    Observatii: Politicile de referință (atributul <b>policy</b>):
                    <ul>
                        <li>STATIC (implicit) (ReferencePolicy.STATIC)
                            <br/>
                            Componenta va fi pornită numai când există o referință atribuită și nu va fi notificată cu privire la serviciile alternative pe măsură ce acestea devin disponibile.
                        </li>
                        <li>DYNAMIC (ReferencePolicy.DYNAMIC):
                            <br/>
                            Componenta va porni atunci când există referințe sau nu, iar componenta va accepta referințe noi pe măsură ce acestea devin disponibile.</li>
                    </ul>

                    Politica de referință controlează ce se întâmplă după ce componenta dvs. pornește când devin disponibile noi opțiuni de referință.
                    Pentru STATIC, noile opțiuni de referință sunt ignorate și pentru DYNAMIC componenta este dispusă să se schimbe.

                    <br/>
                    Implicit, serviciul devine activ numai atunci când această referință poate fi rezolvată:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Referinţă\n' +
                        'MyRepository depozit;'}
                    </SyntaxHighlighter>

                    Serviciul ar putea deveni activ înainte ca serviciul MyRepository să fie disponibil:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference(policy=ReferencePolicy.DYNAMIC)\n' +
                        'MyRepository depozit;'}
                    </SyntaxHighlighter>

                    <hr/>
                    31. Comenzile MVC Action sunt componente de gestionare pentru faza de acțiune a unui portlet, utilizate de obicei pentru a procesa o trimitere a unui formular și pentru a efectua o acțiune model.

                    <hr/>
                    32. Comenzile MVC Render pot fi utilizate pentru:
                    <ul>
                        <ul>obtinere lista de asset-uri si afisare in interfata UI</ul>
                        <ul>aducere un asset (asset entry) si afisare in interfata UI</ul>
                    </ul>

                    <hr/>
                    33. Următorii pași sunt pentru suprascrierea de acțiunii Struts:
                    <ul>
                        <li>gasire action path pe care o vrem suprascrisa (struts-config.xml)</li>
                        <li>creare modul Liferay (Liferay Module Project)</li>
                        <li>crearea a noua componenta Struts Action</li>
                        <li>suprascrierea si implementarea metodelor de care avem nevoie</li>
                    </ul>

                    <hr/>
                    34. Adnotarea <b>@Indexable</b> este o adnotare la nivel de metodă furnizată de Liferay, care poate fi utilizată cu Service Builder,
                    care actualizează automat indexul la evenimentele de modificare a entității.

                    <hr/>
                    35. Următorii  pași sunt pentru activarea fluxurilor de lucru pentru entități personalizate:
                    <ul>
                        <li>adaugare in layer-ul service manipulatori de crarea si stergere a instantei workflow</li>
                        <li>asigurarea ca modelul entitate are campul <b>status</b> </li>
                        <li>crarea metoda gettter in layer-ul de service pentru a stii de status</li>
                    </ul>

                    Mai multe: <a href={"https://help.liferay.com/hc/en-us/articles/360029046731-Liferay-s-Workflow-Framework"}>Liferay's Workflow Framework</a>

                    <hr/>
                    36. Urmatorii pasi sunt pentru crearea unui Model Listener:
                    <ul>
                        <li>crearea a unui nou modul Lifery folosind API template</li>
                        <li>crearea unei componente model listener folsoind wizard-ul Liferay Component Class</li>
                        <li>implementarea metodelor de gestionare a evenimentelor (ex: onAfterCreate() )</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(service = ModelListener.class)\n' +
                        'public class UserModelListener extends BaseModelListener<User> {..}'}
                    </SyntaxHighlighter>

                    <hr/>
                    37. Destinația (<b>destination</b>) este un punct final cu nume (<b>endpoint</b>) pentru trimiterea si primierea de mesaje
                    de la ascultătorii înregistrați la aceasta destitinatia pentru a primii mesaje.

                    <hr/>
                    38. Tipuri de destinatie in Liferay Message Bus system:
                    <ul>
                        <li>Parallel</li>
                        <li>Serial</li>
                        <li>Synchronous</li>
                    </ul>

                    <hr/>
                    39. Codul folosind platforma Liferay de căutare comunică întotdeauna prin intermediul API-ului de căutare a portalului.

                    <hr/>
                    40. Framework-ul implicit utilizat de Liferay DXP pentru indexare este <b>Elastic Search</b>

                    <hr/>
                    41. Fază de analiză în căutarea Liferay:
                    <ul>
                        <li>poate fi configurata pentru fiecare camp individual in definitia de mapare</li>
                        <li>un proces la nivel de câmp de transformare a datelor de intrare în date ale motorului de căutare</li>
                    </ul>

                    <hr/>
                    42. Postprocesoarele Hits indexer:
                    <ul>
                        <li>modifica index-ul documentelor inainte de a le trimite catre motorul de cautare</li>
                        <li>modifica query-urile inainte de a fi executate</li>
                        <li>modifica resultatele de tip summaary cand sunt returnate catre end-user</li>
                    </ul>

                    <hr/>
                    43. FALS: bnd.bnd furnizează dependențele; build.gradle conectează dependențele la pachet.

                    <hr/>
                    44. Workspace-ul Liferay generat cu gradle contine:
                    <ul>
                        <li>bundles</li>
                        <li>config</li>
                        <li>themes</li>
                        <li>settings</li>
                    </ul>

                    <hr/>
                    45. Un bundle functionaza in urmatoare stare a ciclului de viata: <b>rezolved</b>
                    <br/>
                    În starea rezolvată (rezolved), pachetul (bundle) și-a găsit toate dependențele, este gata de funcționare, dar nu i s-a spus încă să înceapă.

                    <hr/>
                    46. O clasa este declarata componenta (adnotarea @Component) pentru ca:
                    <ul>
                        <li>
                            poate avea proprietati si politici de activare
                        </li>
                        <li>are ciclu de viata independent si este reutilizabil</li>
                        <li>poate consuma servicii publicate de alte componente</li>
                        <li>se poate publica ca serviciu si devenii disponibil pentru alte componente</li>
                    </ul>

                    <hr/>
                    47. Se pot suprascrie servicii OSGI prin injectarea unei implementări Service Wrapper cu o prioritate mai mare decât cea existentă.

                    <hr/>
                    48. Urmatorii pasi sunt pentru crearea unei componente in Liferay:
                    <ul>
                        <li>gasirea unei intefete sau superclase de implementat sau extins, daca e disponibila</li>
                        <li>adnotarea clasei cu @Component</li>
                        <li>definirea proprietati componenta/serviciu</li>
                        <li>implementare/suprascriere metode</li>
                        <li>injectare alte componente si servicii cu @Reference</li>
                    </ul>

                    <hr/>
                    49. Serviciile și funcționalitățile din cadrul platformei Liferay folosesc componenta și modelul de servicii OSGi.

                    <hr/>
                    50. Un proces de feedback în cadrul unei aplicații ar putea consta în setarea unei chei de mesaj în back-end, realizarea unei localizări în <b>Langauge.properties</b> și afișarea mesajului pe interfața cu utilizatorul folosind tag-urile:
                    <SyntaxHighlighter>
                        {'<liferay-ui:success> și <liferay-ui:error>'}
                    </SyntaxHighlighter>

                    <hr/>
                    51. Comenzi gogo shell:
                    <ul>
                        <li>lb - afiseaza toate bundle-urie</li>
                        <li>dm wtf - afiseaza dependintele lipsa</li>
                    </ul>

                    52. Setările instanței portalului pot fi gestionate în panoul de control, precum și în fișierul de proprietăți.
                    <br/>
                    Fisierul <b>portal.properties</b> este fișierul principal de proprietăți.
                    <br/>

                    Daca se vrea inlocuirea unor proprietăți care se află în portal.properties, se creeaza un fișier numit <b>portal-ext.properties</b>.
                    <br/>
                    Fisierul de proprietati <b>portal-setup-wizard.properties</b> este creat automat dupa ce se trece prin ecranul de configurare de bază.
                    <br/>
                    Setările instanței portalului pot fi gestionate în panoul de control.
                    <br/>
                    Dacă setările se suprapun sau intră în conflict, aceasta este ordinea de prioritate (ultima rămâne):
                    <ul>
                        <li>portal.properties</li>
                        <li>portal-ext.properties</li>
                        <li>portal-setup-wizard.properties</li>
                        <li>setări prin panoul de control</li>
                    </ul>

                    <hr/>
                    53. Se poate accesa gogo shell folosind comanda blade CLI: <b>blade sh</b>

                    <hr/>
                    54. Faza MVC event
                    <ul>
                        <li>evenimente de proces declanșate în faza de acțiune</li>
                        <li>folosite pentru IPC: comunicare inter-portlet</li>
                        <li>toti ascultatorii inregistrati proceseaza evenimentul</li>
                        <li>provoaca toti portletii in pagina sa se re-randeze</li>
                        <li>Obiecte wrapper: <b>EventRequest - EventResponse</b></li>
                        <li>Corespunde metodei standard: <b>processEvent()</b></li>
                    </ul>

                    Faza de eveniment (event phase) este una dintre modalitățile prin care portleții pot comunica între ei, pe scurt numim acest IPC.
                    <br/>
                    Modul în care funcționează faza evenimentului:
                    <ul>
                        <li>exista un portlet care va fi responsabil pentru trimiterea oricărui obiect de tip eveniment</li>
                        <li>exista un numar de portlete, configurate sa asculte acel obiect de tip eveniment</li>
                    </ul>
                    Dacă un anumit obiect eveniment este trimis, inter-portletul B, este cel configurat pentru a-l asculta.
                    <br/>
                    Odată ce acel obiect este trimis, acesta va prelua obiectul eveniment și putem face orice vrem la acel obiect eveniment.
                    <br/>
                    Deoarece folosim faza de acțiune (<b>action phase</b>) pentru a trimite evenimentul, iar după faza de acțiune (<b>action phase</b>)  urmează faza de randare (<b>arender phase</b>), acest lucru este valabil și pentru faza de eveniment (<b>event phase</b>).
                    <br/>
                    Metoda care corespunde fazei evenimentului este metoda <b>processEvent()</b>.

                    <hr/>
                    55. Faza MVC Resource poate fi utilizată pentru:
                    <ul>
                        <li>autocompletare</li>
                        <li>refresh continut portlet fara refresh la toata pagina</li>
                        <li>incarcare contiua de continut</li>
                        <li>executarea de operatii in background fara refresh la pagina</li>
                    </ul>

                    Daca action phase, se executa render phase; pentru a ocoli acest lucru se foloseste faza de servire a resurselor (MVC Resource).

                    <hr/>
                    56. FALS: Fișierele cu sufixul .css sunt compilate prin SASS în .scss și referentiate în proprietăți cu .css.

                    <hr/>

                    57. Următoarele domenii pot fi atribuite unui rol în Liferay DXP:
                    <ul>
                        <li>Team</li>
                        <li>Organization</li>
                        <li>Global</li>
                    </ul>

                    <hr/>
                    58. FALS: Parametrii de randare publică și Evenimentele sunt singurele două moduri de comunicare între portlet.

                    <br/>
                    Alte modalitati:
                    <ul>
                        <li>Portlet Session publice</li>
                        <li>Client-Side IPC</li>
                    </ul>

                    Fiecare portlet are propria sa sesiune care se numește <b>Portlet Session</b> și datele sesiunii nu vor fi partajate cu alte portleturi.
                    <br/>
                    Pentru a face Comunicarea Inter Portlet (IPC) cu ajutorul sesiunii Portlet, vom face publica sesiunea de Portlet, astfel încât datele să fie partajate în alte portleturi.

                    <hr/>
                    59. Beneficiile utilizării spațiului de lucru Liferay:
                    <ul>
                        <li>suport pentru Gradle/Maven</li>
                        <li>mediu transportabil</li>
                        <li>ofera un mediu complet de lucru cand vine si cu serverul de portal</li>
                        <li>ofera toate instrumentele necesare pentru a creea module Liferay</li>
                    </ul>

                    <hr/>
                    60. Liferay are propria sa implementare a unui portlet care extinde GenericPortlet (MVCPortlet)

                    <hr/>
                    61. Beneficiile folosirii MVCPortlet:
                    <ul>
                        <li>reduce cod boilerplate</li>
                        <li>permite scrierea de aplicatii multi module</li>
                        <li>mosteneste javax.portlet.Portlet</li>
                        <li>extinde javax.portlet.GenericPortlet</li>
                    </ul>
                    Observatie: NU permite includerea tuturor resurselor intr-un sigur fisier WAR.

                    <hr/>
                    62. Folosind Service Builder se genereaza automat:
                    <ul>
                        <li>serviciile locale si remote</li>
                        <li>layerele: model, persistenta, servicii</li>
                    </ul>

                    <hr/>
                    63. Task-ul Gradle <b>buildService</b> generează codul pe baza <b>service.xml</b>.

                    <hr/>
                    64. FALS: Clasele wrapper sunt generate în modulul de servicii.
                    <br/>
                    Service Wrapper-urile sunt de obicei folosite pentru a suprascrie clasele de servicii de bază;
                    <br/>
                    Service builder creează clase stub de wrapper în modulul API pentru toate serviciile generate.

                    <hr/>
                    65. E nevoie de rebuild la Service Builder cand s-a modificat:
                    <ul>
                        <li><b>service.xml</b></li>
                        <li>signatura unei clase din implementare s-a schimbat</li>
                    </ul>
                    Nu e nevoie daca:
                    <ul>
                        <li><b>portlet-model-hints.xml</b> s-a modificat</li>
                        <li>daca modulul -api s-a modificat</li>
                    </ul>

                    <hr/>
                    66. Service Builder este un instrument de generare a codului care ia ca intrare un fișier de configurare xml și generează un nivel de serviciu complet ca ieșire.

                    <hr/>
                    67. Service Wrapper vă permite să suprascrieți serviciile generate de Service Builder din module externe.

                    <hr/>
                    68. FALS: În lumea Liferay, o singură validare este suficientă pentru a garanta securitatea beneficiarului, partea client sau partea serverului.

                    <hr/>
                    69. Domenii în care rolurile pot fi aplicate:
                    <ul>
                        <li>Global</li>
                        <li>Site sau organization</li>
                    </ul>

                    <hr/>
                    70. FALS: ermisiunile sunt întotdeauna acordate utilizatorilor direct.
                    <br/>
                    Permisiunile sunt acordate întotdeauna rolurilor, nu utilizatorilor direct.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips2LiferayContent;