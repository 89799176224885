import {useRoutes} from "react-router-dom";
import React from "react";
import PrivateRoute from "../../security/PrivateRoute";
import ChangePortGrafanaContent from "./ChangePortGrafanaContent";
import DataSourcesGrafanaContent from "./DataSourcesGrafanaContent";
import DashboardsGrafanaContent from "./DashboardsGrafanaContent";
import VariablesGrafanaContent from "./VariablesGrafanaContent";
import SSLGrafanaContent from "./SSLGrafanaContent";
import IndexContent from "../grafana/IndexContent";
import BuildFromSourceGrafanaContent from "./BuildFromSourceGrafanaContent";

export default function Router() {
    let element = useRoutes([

        {path: "/grafana/index", element: <IndexContent/>},
        {path: "/grafana/change-port", element:  <PrivateRoute element={<ChangePortGrafanaContent/>}/>},
        {path: "/grafana/data-sources", element:  <PrivateRoute element={<DataSourcesGrafanaContent/>}/>},
        {path: "/grafana/dashboards", element:  <PrivateRoute element={<DashboardsGrafanaContent/>}/>},
        {path: "/grafana/variables", element:  <PrivateRoute element={<VariablesGrafanaContent/>}/>},
        {path: "/grafana/ssl", element:  <PrivateRoute element={<SSLGrafanaContent/>}/>},

        {path: "/grafana/build-from-source", element:  <PrivateRoute element={<BuildFromSourceGrafanaContent/>}/>},

    ]);

    return element;
}