import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ScopesSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-scopes", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    Scopul se refera la modul de creare a unui bean si gestionarea ciclului de viata.
                    Exista urmatoarele scopuri:
                    <ul>
                        <li>
                            Sincleton
                        </li>
                        <li>
                            Prototype
                        </li>
                        <li>
                            Request (pentru aplicatii web)
                        </li>
                        <li>
                            Session (pentru aplicatii web)
                        </li>
                        <li>
                            Application (pentru aplicatii web)
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Singleton</b>
                    <br/>
                    <br/>

                    In mod implicit, Spring creaza bean-uri cu scopul Singleton. Aceasta inseamna ca un nume este asignat unei instante, iar aceasta instanta va fi folosita ori de cate ori este referinta prin intermediul acestui nume.

                    <br/>

                    Bean-urile nu ar trebui create cu scopul Singleton daca nu poate fi imutabile.

                    <br/>
                    <br/>
                    Bean-urile nu au fost proiecte cu ideea a fi mutabile. Si de aceea, daca exista o arhitectura cu mai multe fire de executie care folosesc aceeasi resursa (bean), aceasta trebuie sincronizata.
                    Sincronizarea este posibila, dar nu este o practica buna, afectand performanta aplicatiei.

                    <br/>
                    <br/>
                    Spring creeaza toate bean-urile de tip Singleton cand este initializat contextul. Acesta este comportamentul implicit, si se numeste initializare <b>eager</b>.
                    <br/>Spring permite si un alt mod de initializare - initializare <b>lazy</b>. Instanta este creata prima data cand cineva refera bean-ul.
                    <br/>Pentru a marca ca un bean este lazy este folosita adnotarea <b>@Lazy</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.services;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Service;\n' +
                        'import org.springframework.context.annotation.Lazy;\n' +
                        '\n' +
                        '@Service\n' +
                        '@Lazy\n' +
                        'public class PoemService {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Prototype</b>
                    <br/>
                    <br/>

                    Daca un bean este creat cu scopul Prototype, atunci de fiecare data cand este ceruta o referinta catre acest bean, Spring creaza un <b>nou obiect</b>.
                    <br/>
                    Pentru a declara un ben cu scopul Prototype se foloseste adnotarea <b>@Scope</b>:

                    <SyntaxHighlighter showLineNumbers={false} language="java" style={androidstudio}>
                        {'@Scope(BeanDefinition.SCOPE_PROTOTYPE)'}
                    </SyntaxHighlighter>


                    Exemplu (declarare scop Prototype cu adnotari stereotip):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.services;\n' +
                        '\n' +
                        'import org.springframework.beans.factory.config.BeanDefinition;\n' +
                        'import org.springframework.context.annotation.Scope;\n' +
                        'import org.springframework.stereotype.Service;\n' +
                        '\n' +
                        '@Service\n' +
                        '@Scope(BeanDefinition.SCOPE_PROTOTYPE)\n' +
                        'public class PoemService {\n' +
                        '    \n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Exemplu (declarare scop Prototype cu @Bean):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Configuration\n' +
                        'public class AppConfig {\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    @Scope(BeanDefinition.SCOPE_PROTOTYPE)\n' +
                        '    public Poet poet(){\n' +
                        '        return new Poet();\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    Bean-urile cu scopul Prototype sunt de evitat si instantele mutabile in general.

                    Comparatie:
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Singleton
                                </th>
                                <th>
                                    Prototype
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Spring asociaza un nume cu o instanta
                            </td>
                            <td>
                                Spring asociaza un nume cu un tip
                            </td>
                        </tr>

                        <tr>
                            <td>
                                la accesare prin nume, se obtine acceasi instanta
                            </td>
                            <td>
                                la accesare prin nume, se obtine o noua instanta
                            </td>
                        </tr>

                        <tr>
                            <td>
                                instanta poate fi creata la incarcarea contexului (Eager) sau la prima referire (Lazy)
                            </td>
                            <td>
                                o noua instanta este creata la fiecare referire
                            </td>
                        </tr>

                        <tr>
                            <td>
                                configurare implicita
                            </td>
                            <td>
                                configurare explicita: @Scope(BeanDefinition.SCOPE_PROTOTYPE)
                            </td>
                        </tr>

                        <tr>
                            <td>
                                nu se recomandata pentru obiecte cu atribute mutabile (modificabile)
                            </td>
                            <td>
                                poate avea atribute  mutabile
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    <b>Observatie</b>
                    <br/>
                    Atunci cand se injecteaza un bean cu scop Prototype intr-un bean cu scop Singleton, atunci se va folosi <i>acceasi instanta Prototype</i> creata cand s-a creat si instanta singleton.
                    Pentru a evita aceast lucru (pentru ca de obicei se doreste o noua instanta prototype la fiecare folosire/accesare) se va accesa bean-ul propotype prin intemediul unui obiect de tipul <b>ApplicationContext</b>:
                    <br/>
                    <br/>
                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.services;\n' +
                        '\n' +
                        'import org.springframework.beans.factory.config.BeanDefinition;\n' +
                        'import org.springframework.context.annotation.Scope;\n' +
                        'import ro.ibedaria.spring.context.model.Poem;\n' +
                        '\n' +
                        '@Scope(BeanDefinition.SCOPE_PROTOTYPE)\n' +
                        'public class VerificaRimaProcessor {\n' +
                        '\n' +
                        '    public boolean areRima(Poem poem) {\n' +
                        '        return !poem.getPoet().getNume().equals("KJ");\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.services;\n' +
                        '\n' +
                        'import org.springframework.beans.factory.annotation.Autowired;\n' +
                        'import org.springframework.context.ApplicationContext;\n' +
                        'import org.springframework.stereotype.Service;\n' +
                        'import ro.ibedaria.spring.context.model.Poem;\n' +
                        '\n' +
                        '@Service\n' +
                        'public class PoemColaborativService {\n' +
                        '\n' +
                        '    @Autowired\n' +
                        '    private ApplicationContext context;\n' +
                        '\n' +
                        '    public boolean verificaRima(Poem poem){\n' +
                        '        VerificaRimaProcessor verificaRimaProcessor = context.getBean(VerificaRimaProcessor.class);\n' +
                        '        return verificaRimaProcessor.areRima(poem);\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default ScopesSpringContent;