import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ServiceObjectK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-service-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiectul Service</b>
                    <br/>
                    <br/>

                    Acest tip de obiect:
                    <ul>
                        <li>expune pod-urile (pod-urile au un IP intern) clusterului si lumii din afara</li>
                    </ul>

                    In mod implicit:
                    <ul>
                        <li>pod-urile au un IP intern; dar nu pot fi accesate, in mod implicit din afara cluster-ului</li>
                        <li>IP intern pentru un pod se modifica de fiecare data cand pod-ul este inlocuit</li>
                    </ul>

                    Pentru a creea un serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment [nume-deployment] --type=LoadBalancer --port=8080'}
                    </SyntaxHighlighter>
                    unde <b>--type</b> poate fi:
                    <ul>
                        <li>ClusterIP: expunere doar in interiorul cluster-ului</li>
                        <li>NodePort: expunere si in afara cluster-ului</li>
                        <li>LoadBalancer: expunere si in afara cluster-ului; conteaza la scalare</li>
                    </ul>

                    verificare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get services'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get svc'}
                    </SyntaxHighlighter>

                    verificare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube service [nume-deployment]'}
                    </SyntaxHighlighter>

                    stergere serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete service [nume-serviciu]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Cand se defineste un Service:
                    <ul>
                        <li>se selecteaza pod-urile asociate (acest Service), folosind mecanismul de label-uri</li>
                        <li>ce porturi sa fie expuse</li>
                        <li>tipul de serviciu</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: users-service\n' +
                            'spec:\n' +
                            '  selector:\n' +
                            '    app: users\n' +
                            '  type: LoadBalancer\n' +
                            '  ports:\n' +
                            '    - protocol: TCP\n' +
                            '      port: 8080\n' +
                            '      targetPort: 8080'}
                    </SyntaxHighlighter>

                    descriere serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube describe svc [nume-servciu]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exercitii:
                    <SyntaxHighlighter>
                        {'kubectl get services\n' +
                            'kubectl get svc\n' +
                            'kubectl describe services\n' +
                            '\n' +
                            '=> pentru a vedea de exemplu:\n' +
                            '  - targetPort\n' +
                            '  - labels\n' +
                            '  - endpoints\n' +
                            '\n' +
                            'kubectl get deployment\n' +
                            'kubectl describe deployment\n' +
                            '\n' +
                            'nano service-definition.yaml\n' +
                            '--- \n' +
                            'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: webapp-service\n' +
                            '  namespace: default\n' +
                            'spec:\n' +
                            '  ports:\n' +
                            '  - nodePort: 30080\n' +
                            '    port: 8080\n' +
                            '    targetPort: 8080\n' +
                            '  selector:\n' +
                            '    name: simple-webapp\n' +
                            '  type: NodePort\n' +
                            '---\n' +
                            '\n' +
                            'kubectl apply -f service-definition.yaml'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceObjectK8sContent;