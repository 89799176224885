import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaPortletFilterLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-portlet-filter", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Portlet Filter</b>
                    <br/>
                    <br/>

                    De obicei se face un pachet in modulul web (MVC Portlet):
                    <ul>
                        <li>ro.agnes.book.web.portlet.<b>filter</b></li>
                    </ul>

                    Exista:
                    <ul>
                        <li>
                            ActionFilter
                        </li>
                        <li>
                            RenderFilter
                        </li>
                        <li>
                            ResourceFilter
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.portlet.filter;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.agnes.book.web.constants.AgnesBookWebPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.*;\n' +
                            'import javax.portlet.filter.*;\n' +
                            'import java.io.IOException;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + AgnesBookWebPortletKeys.PORTLET_ID,\n' +
                            '        },\n' +
                            '        service = PortletFilter.class\n' +
                            ')\n' +
                            'public class AgnesBookPortletFilter implements ActionFilter, RenderFilter, ResourceFilter {\n' +
                            '    @Override\n' +
                            '    public void doFilter(ActionRequest request, ActionResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                            '        _log.info("doFilter -> ActionRequest");\n' +
                            '        chain.doFilter(request, response);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void doFilter(RenderRequest request, RenderResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                            '        _log.info("doFilter -> RenderRequest");\n' +
                            '        chain.doFilter(request, response);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void doFilter(ResourceRequest request, ResourceResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                            '        _log.info("doFilter -> ResourceResponse");\n' +
                            '        chain.doFilter(request, response);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void init(FilterConfig filterConfig) throws PortletException {\n' +
                            '        _log.info("init");\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void destroy() {\n' +
                            '        _log.info("destroy");\n' +
                            '    }\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(AgnesBookPortletFilter.class);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Mici modificari, cu verificari de rol:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.portlet.filter;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.model.role.RoleConstants;\n' +
                            'import com.liferay.portal.kernel.portlet.PortletResponseUtil;\n' +
                            'import com.liferay.portal.kernel.service.RoleLocalServiceUtil;\n' +
                            'import com.liferay.portal.kernel.service.persistence.PortletUtil;\n' +
                            'import com.liferay.portal.kernel.util.PortalUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.agnes.book.web.constants.AgnesBookWebPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.*;\n' +
                            'import javax.portlet.filter.*;\n' +
                            'import java.io.IOException;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + AgnesBookWebPortletKeys.PORTLET_ID,\n' +
                            '        },\n' +
                            '        service = PortletFilter.class\n' +
                            ')\n' +
                            'public class AgnesBookPortletFilter implements ActionFilter, RenderFilter, ResourceFilter {\n' +
                            '    @Override\n' +
                            '    public void doFilter(ActionRequest request, ActionResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                            '        _log.info("doFilter -> ActionRequest");\n' +
                            '        chain.doFilter(request, response);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void doFilter(RenderRequest request, RenderResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                            '        _log.info("doFilter -> RenderRequest");\n' +
                            '\n' +
                            '        if (hasAccess(request)){\n' +
                            '\n' +
                            '            chain.doFilter(request, response);\n' +
                            '\n' +
                            '        }else{\n' +
                            '            _log.info("doFilter -> AccessDeniedException");\n' +
                            '            // String errorMessage = "Access denied";\n' +
                            '            String errorMessage = String.format("<div class=\'portlet-msg-error\'>%s</div>", "Access denied");\n' +
                            '            PortletResponseUtil.write(response, errorMessage);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void doFilter(ResourceRequest request, ResourceResponse response, FilterChain chain) throws IOException, PortletException {\n' +
                            '        _log.info("doFilter -> ResourceResponse");\n' +
                            '        if (hasAccess(request)){\n' +
                            '\n' +
                            '            chain.doFilter(request, response);\n' +
                            '\n' +
                            '        }else{\n' +
                            '            _log.info("doFilter -> AccessDeniedException");\n' +
                            '            // String errorMessage = "Access denied";\n' +
                            '            String errorMessage = String.format("<div class=\'portlet-msg-error\'>%s</div>", "Access denied");\n' +
                            '            PortletResponseUtil.write(response, errorMessage);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void init(FilterConfig filterConfig) throws PortletException {\n' +
                            '        _log.info("init");\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void destroy() {\n' +
                            '        _log.info("destroy");\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    private boolean hasAccess(PortletRequest request) {\n' +
                            '        boolean isAdmin = false;\n' +
                            '        try{\n' +
                            '\n' +
                            '            long userId = PortalUtil.getUserId(request);\n' +
                            '            long companyId = PortalUtil.getCompanyId(request);\n' +
                            '            isAdmin = RoleLocalServiceUtil.hasUserRole(userId, companyId, RoleConstants.ADMINISTRATOR, true);\n' +
                            '\n' +
                            '        }catch (Exception e){\n' +
                            '            _log.error(e.getMessage(), e);\n' +
                            '        }\n' +
                            '        return isAdmin;\n' +
                            '    }\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(AgnesBookPortletFilter.class);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Daca nu are rol de Administrator, se va afisa:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x7.png'}/>
                    </div>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaPortletFilterLiferayContent;