import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class SynologyMailServerNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-synology-mail-server", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Activare User home service</b>
                    <br/>
                    <br/>
                    Din <b>Control Panel {"->"} User & Group {"->"} tab Advanced {"->"} Sectiune User Home {"->"} bifare Enable user home service</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nas-5/img1.png'}/>
                    </div>

                    <hr/>
                    <b>2. Instalare Synology Mail Server</b>
                    <br/>
                    <br/>
                    Din <b>Package Center</b>, din casuta de cautare: <i>mail</i> pentru a filtra din pachetele care se pot instala.
                    <br/>
                    Se alege <b>Synology Mail Server</b> si se da <b>Install</b>.
                    <br/>Ne va informa ca va instala Perl, pentru ca depinde de acesta. Si vom da <i>Yes</i>.

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nas-5/img.png'}/>
                    </div>

                    <hr/>
                    <b>3. Configurare Synology Mail Server</b>
                    <br/>
                    <br/>

                    Dupa ce s-a instalat, deschidem <i>Synology Mail Server</i>.
                    <br/>
                    Din sectiunea <b>SMTP</b> se:
                    <ul>
                        <li>bifeaza: <b>Enable SMTP</b></li>
                        <li>Hostname (FQDN): dopamina.com (daca se vrea ca adresa de email a utilizatorului sa fie: username@dopamina.ro)</li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/nas-5/img_1.png'}/>
                    </div>

                    <hr/>
                    <b>4. Instalare Mail Station</b>
                    <br/>
                    <br/>

                    Din <b>Package Center</b>, din casuta de cautare: <i>mail</i> pentru a filtra din pachetele care se pot instala.
                    <br/>
                    Se alege <b>Mail Station</b> si se da <b>Install</b>.
                    <br/>Ne va informa ca va instala PHP 7.4, pentru ca depinde de acesta. Si vom da <i>Yes</i>.

                    <hr/>
                    <b>5. Accesare client Mail Station</b>
                    <br/>
                    <br/>

                    Un client nu poate accesa prin QuickConnect Mail Station, ci pe baza IP-ului de la NAS:
                    <br/>
                    <b>http://IP/mail</b>

                    (status (deocamdata): nu merge decat pe calculatorul conectat cu cablu la routerul la care este conectat si NAS-ul)

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       {/* <li>*/}
                       {/*     <a target={"_blank"} href={"https://linuxhint.com/setup-synology-nas/"}>How to Setup Synology NAS?</a>*/}
                       {/* </li>*/}

                       {/*<li>*/}
                       {/*    <a target={"_blank"} href={"https://linuxhint.com/speed-test-synology-nas/"}>How to Speed Test Synology NAS?</a>*/}
                       {/*</li>*/}

                       {/* <li>*/}
                       {/*     <a target={"_blank"} href={"https://linuxhint.com/docker-compose-with-synology/"}>How Do I Use Docker Compose with Synology?</a>*/}
                       {/* </li>*/}

                        <li>
                            <a target={"_blank"} href={"https://linuxhint.com/synology-mail-server-vs-mailplus-server/"}>Synology Mail Server vs. MailPlus Server Compared</a>
                        </li>

                        {/*<li>*/}
                        {/*    <a target={"_blank"} href={"https://linuxhint.com/run-synology-email-server/"}>How to Run a Synology Email Server</a>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <a target={"_blank"} href={"https://linuxhint.com/enable-ssh-access-synology-nas/"}>Enable SSH Access on Synology NAS</a>*/}
                        {/*</li>*/}



                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SynologyMailServerNasContent;