import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ExceptionsIIPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-exceptions-ii", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>OOP: Exceptii</b>
                    <br/> <br/>

                    Exceptiile sunt clase. Cand se arunca o exceptie, un obiect de tipul exceptiei este instantiat.

                    Sintaxa:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'except NumeExceptie as identificator:'}
                    </SyntaxHighlighter>

                    permite să interceptați un astfel de obiect, care poartă informații despre o excepție. Acest identificator are ca scop (sau vizibilitate) doar in ramura <b>except:</b>.

                    <br/>
                    <br/>
                    Metode ale unui obiect de tip excetie:
                    <ul>
                        <li><b>__str__()</b>: printeaza obiectul exceptie (plus un mesaj scurt care descrie motivul pentru care s-a aruncat exceptia)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'try:\n' +
                                '    i = int("Buna!")\n' +
                                'except Exception as e:\n' +
                                '    print(e) # invalid literal for int() with base 10: \'Buna!\'\n' +
                                '    print(e.__str__()) # invalid literal for int() with base 10: \'Buna!\''}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Proprietati pe clasa unei exceptii:
                    <ul>
                        <li>
                            <b>__name__</b>: retine numele clasei exceptiei
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'try:\n' +
                                '    i = int("Buna!")\n' +
                                'except Exception as e:\n' +
                                '    print(type(e).__name__) # ValueError\n' +
                                '  '}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>__subclasses__()</b>: lista cu sublasele unei clase
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'class A: pass\n' +
                                '\n' +
                                'class B(A): pass\n' +
                                '\n' +
                                'class C(A): pass\n' +
                                '\n' +
                                'print(A.__subclasses__())'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    Proprietati ale unui obiect de tip exceptie:
                    <ul>
                        <li>
                            <b>args</b>: tuplu care stochează toate argumentele transmise constructorului obiectului
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'try:\n' +
                                '    raise Exception("matematica", "nu stiu integrale")\n' +
                                'except Exception as e:\n' +
                                '    print(e.args) # (\'matematica\', \'nu stiu integrale\')'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Se pot definii <b>exceptii proprii</b> (de exemplu, se pot definii clase care extind de exemplu clasa <b>Exception</b>).

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExceptionsIIPythonContent;