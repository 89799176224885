import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"go-root", url:"#", title:"Go", subtitle:""},

        {id:"go-install", url:"/go/install", title:"Instalare", subtitle:"", parent:"go-root"},
        {id:"go-workspace", url:"/go/workspace", title:"Workspace", subtitle:"", parent:"go-root"},
        {id:"go-goroot-gopath", url:"/go/goroot-gopath", title:"Variabile de mediu: GOROOT si GOPATH", subtitle:"", parent:"go-root"},
        {id:"go-hello-world", url:"/go/hello-world", title:"Hello World", subtitle:"", parent:"go-root"},

        {id:"go-variables", url:"/go/variables", title:"Variabile", subtitle:"", parent:"go-root"},
        {id:"go-functions", url:"/go/functions", title:"Functii", subtitle:"", parent:"go-root"},
        {id:"go-types", url:"/go/types", title:"Tipuri", subtitle:"", parent:"go-root"},
        {id:"go-control-flow", url:"/go/control-flow", title:"Instructiuni de control", subtitle:"", parent:"go-root"},
        {id:"go-import", url:"/go/import", title:"Import", subtitle:"", parent:"go-root"},

        {id:"go-struct", url:"/go/struct", title:"Struct", subtitle:"", parent:"go-root"},
        {id:"go-map", url:"/go/map", title:"Map", subtitle:"", parent:"go-root"},
        {id:"go-interfaces", url:"/go/interfaces", title:"Interfete", subtitle:"", parent:"go-root"},
        {id:"go-go-routines", url:"/go/go-routines", title:"Rutine Go si Channels", subtitle:"", parent:"go-root"},

        {id:"go-testing", url:"/go/testing", title:"Testare", subtitle:"", parent:"go-root"},

    ]

    static indexUrl = "/go/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Go
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;