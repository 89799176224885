import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class Http2NginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-http2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. HTTP2</b>

                    <br/>
                    <br/>

                    HTTP 1.1:
                    <ul>
                        <li>protocol text</li>
                        <li>conexiune dedicata pentru fiecare resursa (de exemplu, daca avem un html cu css si js {"=>"} 3 conexiuni; unele browsere limiteaza numarul de conexiuni la 15)</li>
                    </ul>

                    HTTP 2:
                    <ul>
                        <li>protocol binar</li>
                        <li>comprima headerele/antetele</li>
                        <li>conexiuni persistente</li>
                        <li>multiplex streaming: mai multe fisiere/resurse (css, html, js) pot fi combinate intr-un singur stream si trimise pe o singura conexiune
                            <br/>
                            de exemplu, daca avem un html cu css si js {"=>"} 1 conexiune (pentru ca conexiunea ramane deschisa)
                        </li>
                        <li>Server Push</li>
                    </ul>

                    <hr/>
                    <b>2. Configurare HTTP2</b>

                    <br/>
                    <br/>

                    Pentru HTTP2 este nevoie de SSL sau HTTPS.

                    <br/>
                    <br/>
                    <b>2.1. Adaugare modul SSL in eginx</b>
                    <br/>
                    <br/>

                    Din directorul unde se gaseste sursele <b>nginx</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -V'}
                    </SyntaxHighlighter>
                    Se iau configurarile.
                    <br/>
                    <br/>
                    Pentru a afla configurarea legate de SSL:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./configure -help | grep http_v2'}
                    </SyntaxHighlighter>
                    Gasim: <b>--with-http_v2_module</b>.

                    <br/>
                    Rulam <b>.configure</b> cu vechea configurare + <b>--with-http_v2_module</b> (<b>--with-http_ssl_module</b>  nu mai trebuie adauga pentru ca exista deja in configurari; daca nu exista trebuie adaugat)

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./configure --sbin-path=/usr/bin/nginx --conf-path=/etc/nginx/nginx.conf --error-log-path=/var/log/nginx/error.log --http-log-path=/var/log/access.log --with-pcre --pid-path=/var/run/nginx.pid --with-http_ssl_module --with-http_image_filter_module-dynamic --modules-path=/etc/nginx/modules --with-http_v2_module'}
                    </SyntaxHighlighter>

                    Compilare configurari:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'make'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'make install'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl restart nginx'}
                    </SyntaxHighlighter>

                    <b>2.2. Creare certificat SSL</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'mkdir /etc/nginx/ssl'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l /etc/nginx/ssl'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'openssl req -x509 -days 10 -nodes -newkey rsa:2048 -keyout /etc/nginx/ssl/self.key -out /etc/nginx/ssl/self.crt'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>req : cerere pentru un nou certificat</li>
                        <li>-x509 : certificatul standard</li>
                        <li>-days 10 :  valid 10 zile</li>
                        <li>-nodes : dacă această opțiune este specificată, atunci dacă este creată o cheie privată, aceasta nu va fi criptată (noDES) </li>
                        <li>-newkey rsa:nbits: creează o nouă cerere de certificat și o nouă cheie privată; se genreaza o cheie RSA cu nbiti dimensiune</li>
                        <li>-keyout : fisierul unde va fi salvata cheia </li>
                        <li>-out: fisierul unde va fi salvat certificatul</li>
                    </ul>

                    <hr/>

                    <b>2. Configurare nginx</b>
                    <br/>
                    <br/>

                    In fisierul <b>nginx.conf</b>:
                    <ul>
                        <li>
                            modificam portul, specificand modulule folosite (ssl, https):
                            <br/>
                            <b>listen 80</b>
                            <br/>
                            {"=>"}
                            <br/>
                            <b>listen 443 ssl http2;</b>
                        </li>
                        <li>
                            specificam <b>ssl_certificate</b> si <b>ssl_certificate_key</b> (in sectiunea <i>server</i>):
                            <ul>
                                <li>ssl_certificate /etc/nginx/ssl/self.crt;</li>
                                <li>ssl_certificate_key /etc/nginx/ssl/self.key;</li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'user www-data;\n' +
                            '\n' +
                            'worker_processes auto;\n' +
                            '\n' +
                            'events {\n' +
                            '  worker_connections 1024;\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 443 ssl http2;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '\n' +
                            '    root /sites/demo;\n' +
                            '\n' +
                            '    index index.php index.html;\n' +
                            '\n' +
                            '    ssl_certificate /etc/nginx/ssl/self.crt;\n' +
                            '    ssl_certificate_key /etc/nginx/ssl/self.key;\n' +
                            '\n' +
                            '    location / {\n' +
                            '      try_files $uri $uri/ =404;\n' +
                            '    }\n' +
                            '\n' +
                            '    location ~\\.php$ {\n' +
                            '      # Pass php requests to the php-fpm service (fastcgi)\n' +
                            '      include fastcgi.conf;\n' +
                            '      fastcgi_pass unix:/run/php/php7.1-fpm.sock;\n' +
                            '    }\n' +
                            '\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Http2NginxContent;