import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CypressAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-cypress", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Testare E2E: Cypress</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @cypress/schematic'}
                    </SyntaxHighlighter>

                    Pornire:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng e2e'}
                    </SyntaxHighlighter>

                    Testare (<i>app.cy.ts</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'describe(\'Primul test\', () => {\n' +
                            '\tif(\'Test\', ()=>{\n' +
                            '\t\tcy.visit(\'/\');\n' +
                            '\t\tcy.contains(\'dopamina\');\n' +
                            '\t}); \n' +
                            '})'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://duncanhunter.gitbook.io/testing-angular/"}>*/}
                    {/*           Testing Angular*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://codecraft.tv/courses/angular/unit-testing/asynchronous/"}>*/}
                    {/*            Testing Asynchronous Code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CypressAngularContent;