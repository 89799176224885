import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class GeneratedCodeResourceJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-resource-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: Servicii</b>
                    <br/>
                    <br/>

                    In directorul <b>src/main/java</b> pachetul <i>ro.ibrid.lapretbun.web.rest</i> s-au generat clase de tip resource/rest Java:

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>ProductResource.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.web.rest;\n' +
                            '\n' +
                            'import java.net.URI;\n' +
                            'import java.net.URISyntaxException;\n' +
                            'import java.util.List;\n' +
                            'import java.util.Objects;\n' +
                            'import java.util.Optional;\n' +
                            'import javax.validation.Valid;\n' +
                            'import javax.validation.constraints.NotNull;\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.beans.factory.annotation.Value;\n' +
                            'import org.springframework.data.domain.Page;\n' +
                            'import org.springframework.data.domain.Pageable;\n' +
                            'import org.springframework.http.HttpHeaders;\n' +
                            'import org.springframework.http.HttpStatus;\n' +
                            'import org.springframework.http.ResponseEntity;\n' +
                            'import org.springframework.web.bind.annotation.*;\n' +
                            'import org.springframework.web.servlet.support.ServletUriComponentsBuilder;\n' +
                            'import ro.ibrid.lapretbun.domain.Product;\n' +
                            'import ro.ibrid.lapretbun.repository.ProductRepository;\n' +
                            'import ro.ibrid.lapretbun.service.ProductService;\n' +
                            'import ro.ibrid.lapretbun.web.rest.errors.BadRequestAlertException;\n' +
                            'import tech.jhipster.web.util.HeaderUtil;\n' +
                            'import tech.jhipster.web.util.PaginationUtil;\n' +
                            'import tech.jhipster.web.util.ResponseUtil;\n' +
                            '\n' +
                            '/**\n' +
                            ' * REST controller for managing {@link ro.ibrid.lapretbun.domain.Product}.\n' +
                            ' */\n' +
                            '@RestController\n' +
                            '@RequestMapping("/api")\n' +
                            'public class ProductResource {\n' +
                            '\n' +
                            '    private final Logger log = LoggerFactory.getLogger(ProductResource.class);\n' +
                            '\n' +
                            '    private static final String ENTITY_NAME = "product";\n' +
                            '\n' +
                            '    @Value("${jhipster.clientApp.name}")\n' +
                            '    private String applicationName;\n' +
                            '\n' +
                            '    private final ProductService productService;\n' +
                            '\n' +
                            '    private final ProductRepository productRepository;\n' +
                            '\n' +
                            '    public ProductResource(ProductService productService, ProductRepository productRepository) {\n' +
                            '        this.productService = productService;\n' +
                            '        this.productRepository = productRepository;\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code POST  /products} : Create a new product.\n' +
                            '     *\n' +
                            '     * @param product the product to create.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 201 (Created)} and with body the new product, or with status {@code 400 (Bad Request)} if the product has already an ID.\n' +
                            '     * @throws URISyntaxException if the Location URI syntax is incorrect.\n' +
                            '     */\n' +
                            '    @PostMapping("/products")\n' +
                            '    public ResponseEntity<Product> createProduct(@Valid @RequestBody Product product) throws URISyntaxException {\n' +
                            '        log.debug("REST request to save Product : {}", product);\n' +
                            '        if (product.getId() != null) {\n' +
                            '            throw new BadRequestAlertException("A new product cannot already have an ID", ENTITY_NAME, "idexists");\n' +
                            '        }\n' +
                            '        Product result = productService.save(product);\n' +
                            '        return ResponseEntity\n' +
                            '            .created(new URI("/api/products/" + result.getId()))\n' +
                            '            .headers(HeaderUtil.createEntityCreationAlert(applicationName, true, ENTITY_NAME, result.getId().toString()))\n' +
                            '            .body(result);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code PUT  /products/:id} : Updates an existing product.\n' +
                            '     *\n' +
                            '     * @param id the id of the product to save.\n' +
                            '     * @param product the product to update.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and with body the updated product,\n' +
                            '     * or with status {@code 400 (Bad Request)} if the product is not valid,\n' +
                            '     * or with status {@code 500 (Internal Server Error)} if the product couldn\'t be updated.\n' +
                            '     * @throws URISyntaxException if the Location URI syntax is incorrect.\n' +
                            '     */\n' +
                            '    @PutMapping("/products/{id}")\n' +
                            '    public ResponseEntity<Product> updateProduct(\n' +
                            '        @PathVariable(value = "id", required = false) final Long id,\n' +
                            '        @Valid @RequestBody Product product\n' +
                            '    ) throws URISyntaxException {\n' +
                            '        log.debug("REST request to update Product : {}, {}", id, product);\n' +
                            '        if (product.getId() == null) {\n' +
                            '            throw new BadRequestAlertException("Invalid id", ENTITY_NAME, "idnull");\n' +
                            '        }\n' +
                            '        if (!Objects.equals(id, product.getId())) {\n' +
                            '            throw new BadRequestAlertException("Invalid ID", ENTITY_NAME, "idinvalid");\n' +
                            '        }\n' +
                            '\n' +
                            '        if (!productRepository.existsById(id)) {\n' +
                            '            throw new BadRequestAlertException("Entity not found", ENTITY_NAME, "idnotfound");\n' +
                            '        }\n' +
                            '\n' +
                            '        Product result = productService.update(product);\n' +
                            '        return ResponseEntity\n' +
                            '            .ok()\n' +
                            '            .headers(HeaderUtil.createEntityUpdateAlert(applicationName, true, ENTITY_NAME, product.getId().toString()))\n' +
                            '            .body(result);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code PATCH  /products/:id} : Partial updates given fields of an existing product, field will ignore if it is null\n' +
                            '     *\n' +
                            '     * @param id the id of the product to save.\n' +
                            '     * @param product the product to update.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and with body the updated product,\n' +
                            '     * or with status {@code 400 (Bad Request)} if the product is not valid,\n' +
                            '     * or with status {@code 404 (Not Found)} if the product is not found,\n' +
                            '     * or with status {@code 500 (Internal Server Error)} if the product couldn\'t be updated.\n' +
                            '     * @throws URISyntaxException if the Location URI syntax is incorrect.\n' +
                            '     */\n' +
                            '    @PatchMapping(value = "/products/{id}", consumes = { "application/json", "application/merge-patch+json" })\n' +
                            '    public ResponseEntity<Product> partialUpdateProduct(\n' +
                            '        @PathVariable(value = "id", required = false) final Long id,\n' +
                            '        @NotNull @RequestBody Product product\n' +
                            '    ) throws URISyntaxException {\n' +
                            '        log.debug("REST request to partial update Product partially : {}, {}", id, product);\n' +
                            '        if (product.getId() == null) {\n' +
                            '            throw new BadRequestAlertException("Invalid id", ENTITY_NAME, "idnull");\n' +
                            '        }\n' +
                            '        if (!Objects.equals(id, product.getId())) {\n' +
                            '            throw new BadRequestAlertException("Invalid ID", ENTITY_NAME, "idinvalid");\n' +
                            '        }\n' +
                            '\n' +
                            '        if (!productRepository.existsById(id)) {\n' +
                            '            throw new BadRequestAlertException("Entity not found", ENTITY_NAME, "idnotfound");\n' +
                            '        }\n' +
                            '\n' +
                            '        Optional<Product> result = productService.partialUpdate(product);\n' +
                            '\n' +
                            '        return ResponseUtil.wrapOrNotFound(\n' +
                            '            result,\n' +
                            '            HeaderUtil.createEntityUpdateAlert(applicationName, true, ENTITY_NAME, product.getId().toString())\n' +
                            '        );\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code GET  /products} : get all the products.\n' +
                            '     *\n' +
                            '     * @param pageable the pagination information.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and the list of products in body.\n' +
                            '     */\n' +
                            '    @GetMapping("/products")\n' +
                            '    public ResponseEntity<List<Product>> getAllProducts(@org.springdoc.api.annotations.ParameterObject Pageable pageable) {\n' +
                            '        log.debug("REST request to get a page of Products");\n' +
                            '        Page<Product> page = productService.findAll(pageable);\n' +
                            '        HttpHeaders headers = PaginationUtil.generatePaginationHttpHeaders(ServletUriComponentsBuilder.fromCurrentRequest(), page);\n' +
                            '        return ResponseEntity.ok().headers(headers).body(page.getContent());\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code GET  /products/:id} : get the "id" product.\n' +
                            '     *\n' +
                            '     * @param id the id of the product to retrieve.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and with body the product, or with status {@code 404 (Not Found)}.\n' +
                            '     */\n' +
                            '    @GetMapping("/products/{id}")\n' +
                            '    public ResponseEntity<Product> getProduct(@PathVariable Long id) {\n' +
                            '        log.debug("REST request to get Product : {}", id);\n' +
                            '        Optional<Product> product = productService.findOne(id);\n' +
                            '        return ResponseUtil.wrapOrNotFound(product);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code DELETE  /products/:id} : delete the "id" product.\n' +
                            '     *\n' +
                            '     * @param id the id of the product to delete.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 204 (NO_CONTENT)}.\n' +
                            '     */\n' +
                            '    @DeleteMapping("/products/{id}")\n' +
                            '    public ResponseEntity<Void> deleteProduct(@PathVariable Long id) {\n' +
                            '        log.debug("REST request to delete Product : {}", id);\n' +
                            '        productService.delete(id);\n' +
                            '        return ResponseEntity\n' +
                            '            .noContent()\n' +
                            '            .headers(HeaderUtil.createEntityDeletionAlert(applicationName, true, ENTITY_NAME, id.toString()))\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    De exemplu, continutul fisierului <i>ProductCategoryResource.java</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.lapretbun.web.rest;\n' +
                            '\n' +
                            'import java.net.URI;\n' +
                            'import java.net.URISyntaxException;\n' +
                            'import java.util.List;\n' +
                            'import java.util.Objects;\n' +
                            'import java.util.Optional;\n' +
                            'import javax.validation.Valid;\n' +
                            'import javax.validation.constraints.NotNull;\n' +
                            'import org.slf4j.Logger;\n' +
                            'import org.slf4j.LoggerFactory;\n' +
                            'import org.springframework.beans.factory.annotation.Value;\n' +
                            'import org.springframework.http.ResponseEntity;\n' +
                            'import org.springframework.web.bind.annotation.*;\n' +
                            'import ro.ibrid.lapretbun.domain.ProductCategory;\n' +
                            'import ro.ibrid.lapretbun.repository.ProductCategoryRepository;\n' +
                            'import ro.ibrid.lapretbun.service.ProductCategoryService;\n' +
                            'import ro.ibrid.lapretbun.web.rest.errors.BadRequestAlertException;\n' +
                            'import tech.jhipster.web.util.HeaderUtil;\n' +
                            'import tech.jhipster.web.util.ResponseUtil;\n' +
                            '\n' +
                            '/**\n' +
                            ' * REST controller for managing {@link ro.ibrid.lapretbun.domain.ProductCategory}.\n' +
                            ' */\n' +
                            '@RestController\n' +
                            '@RequestMapping("/api")\n' +
                            'public class ProductCategoryResource {\n' +
                            '\n' +
                            '    private final Logger log = LoggerFactory.getLogger(ProductCategoryResource.class);\n' +
                            '\n' +
                            '    private static final String ENTITY_NAME = "productCategory";\n' +
                            '\n' +
                            '    @Value("${jhipster.clientApp.name}")\n' +
                            '    private String applicationName;\n' +
                            '\n' +
                            '    private final ProductCategoryService productCategoryService;\n' +
                            '\n' +
                            '    private final ProductCategoryRepository productCategoryRepository;\n' +
                            '\n' +
                            '    public ProductCategoryResource(ProductCategoryService productCategoryService, ProductCategoryRepository productCategoryRepository) {\n' +
                            '        this.productCategoryService = productCategoryService;\n' +
                            '        this.productCategoryRepository = productCategoryRepository;\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code POST  /product-categories} : Create a new productCategory.\n' +
                            '     *\n' +
                            '     * @param productCategory the productCategory to create.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 201 (Created)} and with body the new productCategory, or with status {@code 400 (Bad Request)} if the productCategory has already an ID.\n' +
                            '     * @throws URISyntaxException if the Location URI syntax is incorrect.\n' +
                            '     */\n' +
                            '    @PostMapping("/product-categories")\n' +
                            '    public ResponseEntity<ProductCategory> createProductCategory(@Valid @RequestBody ProductCategory productCategory)\n' +
                            '        throws URISyntaxException {\n' +
                            '        log.debug("REST request to save ProductCategory : {}", productCategory);\n' +
                            '        if (productCategory.getId() != null) {\n' +
                            '            throw new BadRequestAlertException("A new productCategory cannot already have an ID", ENTITY_NAME, "idexists");\n' +
                            '        }\n' +
                            '        ProductCategory result = productCategoryService.save(productCategory);\n' +
                            '        return ResponseEntity\n' +
                            '            .created(new URI("/api/product-categories/" + result.getId()))\n' +
                            '            .headers(HeaderUtil.createEntityCreationAlert(applicationName, true, ENTITY_NAME, result.getId().toString()))\n' +
                            '            .body(result);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code PUT  /product-categories/:id} : Updates an existing productCategory.\n' +
                            '     *\n' +
                            '     * @param id the id of the productCategory to save.\n' +
                            '     * @param productCategory the productCategory to update.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and with body the updated productCategory,\n' +
                            '     * or with status {@code 400 (Bad Request)} if the productCategory is not valid,\n' +
                            '     * or with status {@code 500 (Internal Server Error)} if the productCategory couldn\'t be updated.\n' +
                            '     * @throws URISyntaxException if the Location URI syntax is incorrect.\n' +
                            '     */\n' +
                            '    @PutMapping("/product-categories/{id}")\n' +
                            '    public ResponseEntity<ProductCategory> updateProductCategory(\n' +
                            '        @PathVariable(value = "id", required = false) final Long id,\n' +
                            '        @Valid @RequestBody ProductCategory productCategory\n' +
                            '    ) throws URISyntaxException {\n' +
                            '        log.debug("REST request to update ProductCategory : {}, {}", id, productCategory);\n' +
                            '        if (productCategory.getId() == null) {\n' +
                            '            throw new BadRequestAlertException("Invalid id", ENTITY_NAME, "idnull");\n' +
                            '        }\n' +
                            '        if (!Objects.equals(id, productCategory.getId())) {\n' +
                            '            throw new BadRequestAlertException("Invalid ID", ENTITY_NAME, "idinvalid");\n' +
                            '        }\n' +
                            '\n' +
                            '        if (!productCategoryRepository.existsById(id)) {\n' +
                            '            throw new BadRequestAlertException("Entity not found", ENTITY_NAME, "idnotfound");\n' +
                            '        }\n' +
                            '\n' +
                            '        ProductCategory result = productCategoryService.update(productCategory);\n' +
                            '        return ResponseEntity\n' +
                            '            .ok()\n' +
                            '            .headers(HeaderUtil.createEntityUpdateAlert(applicationName, true, ENTITY_NAME, productCategory.getId().toString()))\n' +
                            '            .body(result);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code PATCH  /product-categories/:id} : Partial updates given fields of an existing productCategory, field will ignore if it is null\n' +
                            '     *\n' +
                            '     * @param id the id of the productCategory to save.\n' +
                            '     * @param productCategory the productCategory to update.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and with body the updated productCategory,\n' +
                            '     * or with status {@code 400 (Bad Request)} if the productCategory is not valid,\n' +
                            '     * or with status {@code 404 (Not Found)} if the productCategory is not found,\n' +
                            '     * or with status {@code 500 (Internal Server Error)} if the productCategory couldn\'t be updated.\n' +
                            '     * @throws URISyntaxException if the Location URI syntax is incorrect.\n' +
                            '     */\n' +
                            '    @PatchMapping(value = "/product-categories/{id}", consumes = { "application/json", "application/merge-patch+json" })\n' +
                            '    public ResponseEntity<ProductCategory> partialUpdateProductCategory(\n' +
                            '        @PathVariable(value = "id", required = false) final Long id,\n' +
                            '        @NotNull @RequestBody ProductCategory productCategory\n' +
                            '    ) throws URISyntaxException {\n' +
                            '        log.debug("REST request to partial update ProductCategory partially : {}, {}", id, productCategory);\n' +
                            '        if (productCategory.getId() == null) {\n' +
                            '            throw new BadRequestAlertException("Invalid id", ENTITY_NAME, "idnull");\n' +
                            '        }\n' +
                            '        if (!Objects.equals(id, productCategory.getId())) {\n' +
                            '            throw new BadRequestAlertException("Invalid ID", ENTITY_NAME, "idinvalid");\n' +
                            '        }\n' +
                            '\n' +
                            '        if (!productCategoryRepository.existsById(id)) {\n' +
                            '            throw new BadRequestAlertException("Entity not found", ENTITY_NAME, "idnotfound");\n' +
                            '        }\n' +
                            '\n' +
                            '        Optional<ProductCategory> result = productCategoryService.partialUpdate(productCategory);\n' +
                            '\n' +
                            '        return ResponseUtil.wrapOrNotFound(\n' +
                            '            result,\n' +
                            '            HeaderUtil.createEntityUpdateAlert(applicationName, true, ENTITY_NAME, productCategory.getId().toString())\n' +
                            '        );\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code GET  /product-categories} : get all the productCategories.\n' +
                            '     *\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and the list of productCategories in body.\n' +
                            '     */\n' +
                            '    @GetMapping("/product-categories")\n' +
                            '    public List<ProductCategory> getAllProductCategories() {\n' +
                            '        log.debug("REST request to get all ProductCategories");\n' +
                            '        return productCategoryService.findAll();\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code GET  /product-categories/:id} : get the "id" productCategory.\n' +
                            '     *\n' +
                            '     * @param id the id of the productCategory to retrieve.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 200 (OK)} and with body the productCategory, or with status {@code 404 (Not Found)}.\n' +
                            '     */\n' +
                            '    @GetMapping("/product-categories/{id}")\n' +
                            '    public ResponseEntity<ProductCategory> getProductCategory(@PathVariable Long id) {\n' +
                            '        log.debug("REST request to get ProductCategory : {}", id);\n' +
                            '        Optional<ProductCategory> productCategory = productCategoryService.findOne(id);\n' +
                            '        return ResponseUtil.wrapOrNotFound(productCategory);\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * {@code DELETE  /product-categories/:id} : delete the "id" productCategory.\n' +
                            '     *\n' +
                            '     * @param id the id of the productCategory to delete.\n' +
                            '     * @return the {@link ResponseEntity} with status {@code 204 (NO_CONTENT)}.\n' +
                            '     */\n' +
                            '    @DeleteMapping("/product-categories/{id}")\n' +
                            '    public ResponseEntity<Void> deleteProductCategory(@PathVariable Long id) {\n' +
                            '        log.debug("REST request to delete ProductCategory : {}", id);\n' +
                            '        productCategoryService.delete(id);\n' +
                            '        return ResponseEntity\n' +
                            '            .noContent()\n' +
                            '            .headers(HeaderUtil.createEntityDeletionAlert(applicationName, true, ENTITY_NAME, id.toString()))\n' +
                            '            .build();\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratedCodeResourceJdlJHipsterContent;