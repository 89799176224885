import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class BackupRestoreDbContent extends BaseContentPage  {

    constructor(props) {
        super(props, "db-backup-restore", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Backup & Restore</b>
                    <br/>
                    <br/>
                    Sa presupunem ca avem:
                    <ul>
                        <li>
                            username: user
                        </li>
                        <li>
                            password: pass
                        </li>
                        <li>
                            database: db
                        </li>
                    </ul>

                    <hr/>
                    <b>1. Backup/Dump</b>
                    <br/>
                    <br/>

                    Pentru MySQL:
                    <SyntaxHighlighter>
                        {'mysqldump -u user -ppass db > backup.sql'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>
                            nu exista spatiu intre -p si parola
                        </li>
                    </ul>

                    Pentru PostgreSQL:
                    <SyntaxHighlighter>
                        {'pg_dump -d db -U user > backup.sql'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Restore</b>
                    <br/>
                    <br/>

                    Pentru MySQL:
                    <SyntaxHighlighter>
                        {'mysql -u user -ppass db < backup.sql'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>
                            nu exista spatiu intre -p si parola
                        </li>
                    </ul>

                    Pentru PostgreSQL:
                    <SyntaxHighlighter>
                        {'psql -U user -d db < backup.sql'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter>
                        {'psql -U user -d db -f backup.sql'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BackupRestoreDbContent;