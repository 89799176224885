import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class RewritesRedirectsNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-rewrites-redirects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Rescriere</b>
                    <br/>
                    <br/>

                    Sintaxa rescriere:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'rewrite pattern URI;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'rewrite ^/user/\\w+ /buna;'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\trewrite ^user/\\w+ /buna;\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t}\n'}
                    </SyntaxHighlighter>

                    Daca vom scrie:
                    <br/>
                    http://IP/user/ion
                    <br/>
                    se va afisa "buna"
                    <br/>
                    si nu se schimba in browser url;

                    <br/>
                    <br/>
                    Din expresia regulata (<i>^user/\w+</i>) putem prelua parti, folosind paranteze patrate si nume de variabile ((\w+) {"->"} $1):

                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'rewrite ^user/(\\w+) /buna/$1;'}
                    </SyntaxHighlighter>
                    <br/>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\trewrite ^user/(\\w+) /buna/$1;\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna/ion {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna ion";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca scriem http://IP/user/ion {"=>"} se rescrie in http://IP/buna/ion  {"=>"} "buna ion"
                    <br/>
                    Daca scriem http://IP/user/ana {"=>"} se rescrie in http://IP/buna/ana  {"=>"} se potriveste cu prefixul /buna  {"=>"} "buna"

                    <br/>
                    <br/>

                    Pot exista rewrite-uri "inlatuite". Sa presupunem ca url-ul nostru este:
                    <br/>
                    <i>http://IP/user/ion</i>
                    <br/>
                    acesta se potriveste cu prima rescriere:
                    <br/>
                    <b>rewrite ^user/(\w+) /buna/$1;</b>
                    <br/>
                    si devine <i>/buna/ion</i>;
                    <br/>
                    apoi se reevevalueza si se da peste o doua potrivire de <i>rewrite</i>
                    <br/>
                    <b>rewrite ^/buna/ion /poza.png;</b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\trewrite ^user/(\\w+) /buna/$1;\n' +
                            '\t\t\n' +
                            '\t\trewrite ^/buna/ion /poza.png;\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna/ion {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna ion";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Acum, daca scriem http://IP/user/ion {"=>"} se rescrie in http://IP/buna/ion  {"=>"} /poza.png;

                    <br/>
                    <br/>

                    Parametrul <b>last</b> specifica in cadrul unui <b>rewrite</b> ca dupa el nu se mai face alte reevaluare/rescriere:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\trewrite ^user/(\\w+) /buna/$1 last;\n' +
                            '\t\t\n' +
                            '\t\trewrite ^/buna/ion /poza.png;\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /buna/ion {\n' +
                            '\t\t\n' +
                            '\t\t\treturn 200 "buna ion";\n' +
                            '\t\t\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Acum, daca scriem http://IP/user/ion {"=>"} se rescrie in http://IP/buna/ion  {"=>"} "buna ion" (nu mai se intampla si rescrierea in <b>rewrite ^/buna/ion /poza.png;</b>)

                    <hr/>

                    <b>2. Redirect</b>
                    <br/>
                    <br/>

                    Sintaxa redirect - schimba si url (din bara de cautare a browser-ului ):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'return status URI;'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>status poate fi 307: redirect temporal</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'return 307 /some/path;'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'location /logo {\n' +
                            '\treturn 307 /img.png;\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RewritesRedirectsNginxContent;