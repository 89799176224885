import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Anexa4GenericAlgorithms extends BaseContentPage {

    constructor(props) {
        super(props, "anexa4-genetic-algorithms", IndexContent);
    }

    render() {

        return (
            <div className="home appendix">
                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify"}>
                    Un algoritm genetic este un soi de căutare euristică inspirată din teoria evoluției naturale a lui
                    Charles Darwin.
                    Algoritmul reflectă procesul de selecție naturală în care cei mai <b>potriviti
                    indivizi</b> sunt <b>selectați</b> pentru <b>reproducere</b> pentru a produce <b>urmași</b> pentru
                    generatiile următoare.
                    <br/>
                    <br/>
                    Procesul de selecție naturală:
                    <ul>
                        <li>începe cu selectarea celor mai potriviti indivizi dintr-o populație: parintii</li>
                        <li>parintii produc urmasi care le mostenesc caracteristicile (urmasii vor fi mai buni, de
                            obicei, decat parintii si vor avea sanse mai mari sa supravietuiesca )
                        </li>
                        <li>urmasii se vor adauga generatiei urmatoare</li>
                    </ul>
                    Procesul de mai sus continuă să se itereze și intr-un final se gaseste o generație cu cei mai
                    potriviti indivizi.
                    <br/>
                    <br/>
                    Etapele unui agloritm genetic:
                    <ul>
                        <li>Generare populație inițială</li>
                        <li>Funcția de fitness</li>
                        <li>Selecţie</li>
                        <li>Crossover</li>
                        <li>Mutaţie</li>
                    </ul>

                    <b>Populatia initiala</b>
                    <br/>
                    Procesul incepe cu o multime de <b>indivizi</b>. Aceasta multime de indivizi se
                    numeste <b>populatie</b>.
                    Un individ este caracterizat de niste parametrii (variabile) numiti <b>gene</b>.
                    Genele sunt unite intr-un sir pentru a forma un <b>cromozom</b> (solutie).
                    De fapt, un individ este identificat prin cromozomul lui.
                    <br/>
                    <i>exemplu:</i>
                    <br/>
                    Presupunem urmatoarea populatie cu 4 indivizi:
                    <table>
                        <tr>
                            <td>Individ 1:</td>
                            <td>
                                <table>
                                    <tr>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>0</td>
                                        <td>1</td>
                                        <td className={"content-dpup"} style={{"border": "3px solid green"}}>1</td>
                                    </tr>
                                </table>
                            </td>
                            <td>gena</td>
                        </tr>
                        <tr>
                            <td>Individ 2:</td>
                            <td>
                                <table className={"content-dpup"} style={{"border": "3px solid green"}}>
                                    <tr>
                                        <td>0</td>
                                        <td>1</td>
                                        <td>0</td>
                                        <td>1</td>
                                        <td>1</td>
                                    </tr>
                                </table>
                            </td>
                            <td>cromozom</td>
                        </tr>
                        <tr>
                            <td>Individ 3:</td>
                            <td>
                                <table className={"content-dpup"} style={{"border": "3px solid green"}}>
                                    <tr>
                                        <td>0</td>
                                        <td>1</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>1</td>
                                    </tr>
                                </table>
                            </td>
                            <td>cromozom</td>
                        </tr>
                        <tr>
                            <td>Individ 4:</td>
                            <td>
                                <table>
                                    <tr>
                                        <td>1</td>
                                        <td>1</td>
                                        <td className={"content-dpup"} style={{"border": "3px solid green"}}>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                </table>
                            </td>
                            <td>gena</td>
                        </tr>
                    </table>

                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Functia de potrivire/fitness</b>
                    <br/>
                    Funcția de fitness calculează <b>cât de potrivit</b> este un individ, atribuind un <b>scor de fitness</b> fiecarui individ.
                    Pe baza acestui scor de fitness se selecteaza un individ pentru reproducere.
                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Selectia</b>
                    <br/>
                    Se selectează două perechi de indivizi (părinți) pe baza scorurilor lor de fitness.
                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Crossover</b>
                    <br/>
                    Pentru fiecare pereche de părinți care trebuie împerecheați, se alege la întâmplare un punct de încrucișare din interiorul genelor.
                    Urmasii sunt creați prin schimbul de gene ale părinților între ei până la atingerea punctului de încrucișare.
                    <br/>
                    <i>exemplu:</i>
                    <br/>

                    <table>
                        <tr>
                            <td>Individ 1:</td>
                            <td>
                                <table>
                                    <tr>
                                        <td className={"content-dpup"}>1</td>
                                        <td className={"content-dpup"}>1</td>
                                        <td className={"content-dpup"}>0</td>
                                        <td className={"content-green"} style={{"border-left":"3px solid red"}}>0</td>
                                        <td className={"content-green"}>1</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>Individ 2:</td>
                            <td>
                                <table>
                                    <tr>
                                        <td className={"content-green"}>0</td>
                                        <td className={"content-green"}>1</td>
                                        <td className={"content-green"}>0</td>
                                        <td className={"content-dpup"} style={{"border-left":"3px solid red"}}>1</td>
                                        <td className={"content-dpup"}>1</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td>Individ nou 1:</td>
                            <td>
                                <table>
                                    <tr className={"content-dpup"} >
                                        <td>1</td>
                                        <td>1</td>
                                        <td>0</td>
                                        <td style={{"border-left":"3px solid red"}}>1</td>
                                        <td>1</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td>Individ nou 2:</td>
                            <td>
                                <table>
                                    <tr className={"content-green"}>
                                        <td>0</td>
                                        <td>1</td>
                                        <td>0</td>
                                        <td style={{"border-left":"3px solid red"}}>0</td>
                                        <td>1</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                    </table>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Mutatia</b>
                    <br/>
                    Pentru unii urmasi, unele gene pot suferi mutatii cu o probabitate mica aleatoare.
                    <br/>
                    Mutația are loc pentru a menține <b>diversitatea</b> în cadrul populației și pentru a <b>preveni convergența prematură</b>.

                    <br/>
                    <i>exemplu:</i>
                    <br/>

                    <table>
                        <tr>
                            <td>Individ 1:</td>
                            <td>
                                <table>
                                    <tr>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>0</td>
                                        <td>1</td>
                                        <td>1</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td>Individ 1 (dupa mutatie):</td>
                            <td>
                                <table>
                                    <tr>
                                        <td>1</td>
                                        <td className={"content-dpup"}>0</td>
                                        <td>0</td>
                                        <td>1</td>
                                        <td>1</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                    </table>

                </div>

                <br/>

                <div className={"text-justify"}>
                    <b>Algoritm</b>

                    <ul>
                        <li>
                            genereaza populatie initiala
                        </li>
                        <li>
                            calculeaza functie fitness
                        </li>
                        <li>
                            <li>repeta</li>

                            <ul>
                                <li>
                                    selectie
                                </li>
                                <li>
                                    crossover
                                </li>
                                <li>
                                    mutatie
                                </li>
                                <li>
                                    calculeaza functie fitness
                                </li>
                            </ul>

                            <li>pana cand o populatie converge</li>
                        </li>

                    </ul>
                    <br/>
                </div>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <a href={"https://towardsdatascience.com/introduction-to-genetic-algorithms-including-example-code-e396e98d8bf3"}>
                                Introduction to Genetic Algorithms — Including Example Code
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Anexa4GenericAlgorithms;