import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ComplexDataTypesJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-complex-data-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Tipuri de date complexe:
                    <ul>
                        <li>obiect (ca structura cu inregistrari)</li>
                        <li>arrays (matrice, multime, lista)</li>
                    </ul>

                    <hr/>
                    <b>1. Obiect</b>
                    <br/>
                    <br/>
                    Un obiect poate fi vazut ca structura cu inregistrari.
                    O înregistrare este o colecție de câmpuri denumite. Fiecare câmp are propriul nume (sau cheie) și valoare atribuită.
                    Aceste câmpuri sunt (de obicei) numite <i>proprietăți</i>.
                    <br/>
                    Înregistrarile permit stocarea mai multor valori de diferite tipuri într-un singur loc.
                    <br/>
                    Pentru a creea un obiect se pot folosi acoladele:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let obj = {}\n' +
                        'console.log(typeof obj); // object'}
                    </SyntaxHighlighter>
                    In exemplu de mai sus s-a creat un obiect gol.
                    <br/>
                    <br/>
                    La definirea unui obiect, proprietățile sunt separate prin virgule.
                    O proprietate poate fi accesata cu notația cu ".".
                    Această notație necesită ca numele obiectului (un literal sau numele unei variabile care conține obiectul) să fie urmat de un punct, urmat din nou de numele câmpului (cheie).

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let obj = {\n' +
                        '    nume: "ion",\n' +
                        '    varsta: 34\n' +
                        '};\n' +
                        '\n' +
                        'console.log(obj.nume); // ion\n' +
                        'console.log(obj.varsta); //34'}
                    </SyntaxHighlighter>


                    <b>Observatie:</b>
                    <br/>
                    Dacă în timpul unei încercări de a modifica o proprietate nu găsește cheia pe care o specificăm, <i>se va creea</i>.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let obj = {\n' +
                        '    nume: "ion",\n' +
                        '    varsta: 34\n' +
                        '};\n' +
                        '\n' +
                        'obj.prenume="vasile"\n' +
                        '\n' +
                        'console.log(obj.prenume); // vasile'}
                    </SyntaxHighlighter>

                    Pentru a sterge o proprietate se foloseste operatorul <b>delete</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let obj = {\n' +
                        '    nume: "ion",\n' +
                        '    varsta: 34\n' +
                        '};\n' +
                        '\n' +
                        'obj.prenume="vasile";\n' +
                        'delete obj.nume;\n' +
                        '\n' +
                        'console.log(obj.nume); // undefined\n' +
                        'console.log(obj.prenume); // vasile'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Array</b>
                    <br/>
                    <br/>

                    O matrice (un array) este un tip de date complex care poate fi folosit pentru a stoca o colecție de date.
                    Datele stocate (valorile) pot fi de orice tip.
                    <br/>
                    Diferența dintre obiect si matrice este că într-o matrice stocăm doar valori, fără numele asociate (adică cheile).
                    <br/>
                    Accesarea unui element dintr-o matrice se face prin pozitie / index. Indicele incepe de la 0.
                    <br/>
                    Pentru a creea un array se pot folosi parantezele patrate ([]):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let array = [];\n' +
                        'console.log(typeof array); // object'}
                    </SyntaxHighlighter>
                    In exemplu de mai sus s-a creat un array gol.
                    <br/>
                    Accesarea unui element dintr-o lista se face folosind parantele patrate si index-ul elementului <b>[index]</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let a = [1,2,3]\n' +
                        'console.log(a[1]); // 2\n' +
                        'console.log(a[100]); // undefined\n'}
                    </SyntaxHighlighter>
                    Daca se incearca accesarea unui element care nu exista, se va returna <i>undefined</i>.
                    <br/>
                    De pot creea un array cu <i>goluri</i> si cu elemente de diferite tipuri (chiar si alt array):

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let a = [];\n' +
                        'a[0]=1;\n' +
                        'a[2]=false;\n' +
                        'console.log(a[0]); // 1\n' +
                        'console.log(a[1]); // undefined\n' +
                        'console.log(a[2]); // false'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let a = [[1,2],[3],[4,5]];\n' +
                        '\n' +
                        'console.log(a[1][0]) //3'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Proprietati</b>
                    <br/>
                    <ul>
                        <li>
                            <b>length</b>: este folosită pentru a obține informații despre lungimea (numărul de elemente) matricei (inclusiv pozițiile goale dintre elementele existente).
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let orase = ["Kiev", "Odesa"];\n' +
                        '\n' +
                        'console.log(orase);// ["Kiev", "Odesa"]\n' +
                        'console.log(orase.length);// 2\n' +
                        'orase[5]="Liov";\n' +
                        'console.log(orase); // ["Kiev","Odesa",undefined,undefined,undefined,"Liov"]\n' +
                        'console.log(orase.length);// 6\n' +
                        '\n' +
                        'orase[-1]="Moscova";\n' +
                        'console.log(orase); // ["Kiev","Odesa",undefined,undefined,undefined,"Liov"]\n' +
                        'console.log(orase.length);// 6'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Metode</b>
                    <br/>

                    <ul>
                        <li>
                            <b>indexof(element)</b>: returneaza indexul unui element dintr-o matrice/lista, si -1, daca elementul nu este gasit;
                            dacă există mai mult de un element cu aceeași valoare în matrice/lista, este returnat indexul primului element.

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa"];\n' +
                                'console.log(orase.indexOf("Odesa"));//1\n' +
                                'console.log(orase.indexOf("Moscova"));//-1'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>push(element)</b>: adauga elementul dat ca argument la sfârșitul matricii/listei
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa"];\n' +
                                'console.log(orase.push("Liov"));\n' +
                                'console.log(orase);//["Kiev","Odesa","Liov"]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>unshift(element)</b>:  adauga elementul dat ca argument la inceputul matricii/listei
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa"];\n' +
                                'console.log(orase.unshift("Liov"));\n' +
                                'console.log(orase);//["Liov","Kiev","Odesa"]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>pop()</b>: sterge ultimul element din matrice/lista, returnand elementul sters
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa"];\n' +
                                'let e = orase.pop();\n' +
                                '\n' +
                                'console.log(e); // Odesa\n' +
                                'console.log(orase);//["Kiev"]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>shift()</b>: sterge primul element din matrice/lista, returnand elementul sters
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa"];\n' +
                                'let e = orase.shift();\n' +
                                '\n' +
                                'console.log(e); // Kiev\n' +
                                'console.log(orase);//["Odesa"]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>reverse()</b>: inverseaza matricea/lista
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa"];\n' +
                                'orase.reverse();\n' +
                                '\n' +
                                'console.log(orase);//["Odesa", "Kiev"]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>concat(matrice)</b>: creează o nouă matrice prin atașarea elementelor din matricea dată ca argument la elementele matricei originale;
                            metoda nu modifică nici matricea originală, nici matricea specificată ca argument.
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase1 = ["Kiev", "Odesa"];\n' +
                                'let orase2 = ["Liov"];\n' +
                                'let orase = orase1.concat(orase2);\n' +
                                '\n' +
                                'console.log(orase);//["Kiev","Odesa","Liov"]'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>slice(startIndex, [endIndex])</b>: permite să creați o nouă matrice din elementele:
                            <ul>
                                <li>de la pozitia 'startIndex' pana la sfarsitul matricei originale, daca startIndex {">="} 0 si ultimul argument lipseste (endIndex)</li>
                                <li>de la pozitia 'startIndex' pana la pozitia 'endIndex' a matricei originale, daca startIndex {">="} 0 si endIndex {">="} 0</li>
                            </ul>

                            <b>slice(startIndex, n)</b>: permite să creați o nouă matrice din elementele:
                            <ul>
                                <li>de la pozitia 'startIndex', pana la sfarsitul matricei orginale, dar fara ultimele n elemente, daca startIndex {">="} 0 si n este negativ</li>
                            </ul>

                            <b>slice(n)</b>: permite să creați o nouă matrice din:
                            <ul>
                                <li>ultimele n elemente, cu n negativ</li>
                            </ul>

                            <b>slice(n1,n2)</b>: permite să creați o nouă matrice din:
                            <ul>
                                <li>ultimele n1 elemente, dar fara n2 elemente, cu n1,n2 negative</li>
                            </ul>

                            apelarea metodei nu afectează matricea originală

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let orase = ["Kiev", "Odesa","Liov"];\n' +
                                '\n' +
                                'console.log(orase.slice(2));//["Liov"]\n' +
                                'console.log(orase.slice(1,3));//["Odesa","Liov"]\n' +
                                'console.log(orase.slice(1,-1));//["Odesa"]\n' +
                                'console.log(orase.slice(-1));//["Liov"]\n' +
                                'console.log(orase.slice(-2,-1));//["Odesa"]'}
                            </SyntaxHighlighter>
                        </li>


                    </ul>

                    <hr/>
                    <b>3. Operatorul instanceof</b>
                    <br/>
                    <br/>

                    Operatorul instanceof este un operator cu două argumente, care necesită specificarea variabilei și a clasei de obiecte testate.
                    <br/>
                    În cazul nostru, clasa este Array. Operatorul returnează true sau false, în funcție de rezultatul testului.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let orase = ["Kiev", "Odesa"];\n' +
                        '\n' +
                        'console.log(typeof orase);// object\n' +
                        'console.log(typeof orase[0]);// object\n' +
                        'console.log(orase instanceof Array);// true\n' +
                        'console.log(orase[0] instanceof Array);// false\n' +
                        'console.log(orase[0] instanceof String);// false\n' +
                        'console.log(typeof orase[0]==="string");// true'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ComplexDataTypesJavaScriptContent;