import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MapGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-map", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Map</b>
                    <br/>
                    <br/>

                    Intr-o mapa, toate cheile trebuie sa fie de acelasi tip, si valorile trebuie sa fie de acelasi tip.

                    <br/>
                    <br/>
                    Exista mai multe metode de definire a unui mape:
                    <SyntaxHighlighter>
                        {'	colors:=map[string]string{\n		"black":"#000000",\n		"white":"#ffffff",\n	}\n	\n	var colors1 map[string]string\n	colors2:=make(map[string]string)\n'}
                    </SyntaxHighlighter>

                    Operatii:
                    <ul>
                        <li>accesare:
                            <SyntaxHighlighter>
                                {'colors["black"]'}
                            </SyntaxHighlighter>
                        </li>
                        <li>modificare:
                            <SyntaxHighlighter>
                                {'colors["black"]="#xxxx"'}
                            </SyntaxHighlighter>
                        </li>
                        <li>stergere:
                            <SyntaxHighlighter>
                                {'delete(colors, "black")'}
                            </SyntaxHighlighter>
                        </li>
                        <li>iterare:
                            <SyntaxHighlighter>
                                {'for k, v := range colors {\n		' +
                                    'fmt.Println(k, " => ", v)\n	' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MapGoContent;