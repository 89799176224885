import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../books/IndexContent";

class MathBooksContent extends BaseContentPage  {

    constructor(props) {
        super(props, "books-math", IndexContent);

        this.state = {
            ...this.state,
          //  filtredBooks: [],
            search:''
        };
    }

    books = [
        {
            id:1,
            title:"De la Podurile din Königsberg la social media. Teoria grafurilor si retelele complexe",
            publisher:"Editura Litera",
            year:2022,
            authors:["Javier Galeano","Juan Manuel Pastor"],
            pages:144,
            language: "romana",
            ISBN: "978-606-33-8283-3",
            img:"teoria-grafurilor-mim.jpg"
        },
        {
            id: 2,
            title: "Dincolo de Euclid. Geometrii alternative",
            publisher:"Editura Litera",
            year:2022,
            authors:["Joan-Vicenç Gómez i Urgellés"],
            pages:144,
            language: "romana",
            ISBN: "978-606-33-8205-5",
            img:"euclid-mim.jpg"
        },
        {
            id: 3,
            title: "Dincolo de Euclid. Geometrii alternative",
            publisher:"Editura Litera",
            year:2022,
            authors:["Diego Alonso Roa"],
            pages:144,
            language: "romana",
            ISBN: "978-606-33-8286-4",
            img:"logaritmi-mim.jpg"
        }

    ]

    handleSearch(event){
        let search = event.target.value;

        // let filtredBooks = ITBooksContent.books.filter((item) =>
        //     item.title.toLowerCase().includes(search.toLowerCase())
        // );
        //
        // this.setState({filtredBooks: filtredBooks});

        this.setState({search: search});
    }

    render() {

        this.state.filtredBooks = this.books;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)} />
                    </label>
                    &nbsp;
                    <span>Numar total carti: {this.books.length}</span>
                </div>

                <hr/>

                <div className={"text-justify important"}>

                    {this.state.filtredBooks
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a=>a.toLowerCase().includes(s));
                                return okTitle || okPublisher || okAuthors;
                            }
                        )
                        .map(function(item, index){
                        return <div className="row">

                            {/*<div className="col-sm-4">*/}
                            {/*    <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}*/}
                            {/*         src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>*/}

                            {/*</div>*/}

                            <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index+1}</span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250} rowSpan={"7"}>
                                            <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}
                                                    src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>
                                        </td>

                                        <td width={250}>
                                            <b>Titlu</b>
                                        </td>
                                        <td>
                                            <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Editura</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.publisher}}/></td>
                                    </tr>
                                    <tr>
                                        <td><b>An</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Autori</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Numar pagini</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.pages}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Limba</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>ISBN</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.ISBN}}/></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>;

                    })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MathBooksContent;