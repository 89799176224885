import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import IndexContent from "./IndexContent";
import BaseContentPage from "../BaseContentPage";

class BitsOperatorsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-bits-operators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>   14. Python: Operatori pe biti</b>

                    <br/>
                    <br/>
                    Operatori pe biti:
                    <ul>
                        <li><b>& (si/conjunctie)</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(13 & 151)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'5'}
                            </SyntaxHighlighter>

                            <i>Exercitiu:</i>
                            <br/>

                            Să calculăm 13 & 151.
                            <br/>
                            Reprezentarea lui 13 este 0000000000001101. <br/>
                            Reprezentarea lui 151 este 0000000010010111
                            <br/>
                            0000000000001101 &<br/>
                            0000000010010111<br/>

                            Se obține:
                            <br/>
                            0000000000000101, adică 5
                            <br/>
                            Deci: 13 & 151 == 5
                            <hr/>
                        </li>
                        <li><b>| (sau/disjunctie)</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(13 | 151)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'159'}
                            </SyntaxHighlighter>

                            <i>Exercitiu:</i>
                            <br/>

                            Să calculăm 13 | 151.
                            <br/>
                            Reprezentarea lui 13 este 0000000000001101. <br/>
                            Reprezentarea lui 151 este 0000000010010111
                            <br/>
                            0000000000001101 |<br/>
                            0000000010010111<br/>

                            Se obține:
                            <br/>
                            0000000010011111, adică 159
                            <br/>
                            Deci: 13 | 151 = 159
                            <hr/>
                        </li>
                        <li><b>~ (negatie)</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(~5)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'-6'}
                            </SyntaxHighlighter>

                            <i>Exercitiu:</i>
                            <br/>
                            ~ 133 = -134
                            <br/>
                            Reprezentarea lui 133 este 0000000010000101. Prin complementare se obține 1111111101111010. Aceasta este reprezentarea în memorie a lui -134.
                            <br/>
                            Pentru a verifica, îl reprezentăm conform celor de mai sus pe -134:
                            <ul>
                                <li>reprezentarea lui 134 este 0000000010000110</li>
                                <li>prin complementare se obține 1111111101111001</li>
                                <li>adunăm 1 și obținem 1111111101111010</li>
                            </ul>
                            <hr/>

                        </li>

                        <li><b>^ (or exclusiv)</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(13 ^ 151)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'154'}
                            </SyntaxHighlighter>

                            <i>Exercitiu:</i>
                            <br/>

                            Să calculăm 13 ^ 151.
                            <br/>
                            Reprezentarea lui 13 este 0000000000001101. <br/>
                            Reprezentarea lui 151 este 0000000010010111
                            <br/>
                            0000000000001101 ^<br/>
                            0000000010010111<br/>

                            Se obține:
                            <br/>
                            0000000010011010, adică 154
                            <br/>
                            Deci: 13 ^ 151 = 154
                            <hr/>
                        </li>

                        <li><b>&lt;&lt; (deplasare spre stânga / binary left shift)</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'valoare << biti'}
                            </SyntaxHighlighter>
                            deplasarea unei valori cu un bit la stânga corespunde astfel înmulțirii acesteia cu 2
                            <br/>
                            (exemplu: 010 &lt;&lt; 1 = 100, adica 2 * 2 = 4 (2 = 010, in baza 2, 4 = 100 in baza 2))
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("2 in baza 2, 010=",0b010)\n' +
                                'print("4 in baza 2, 100=",0b100)\n' +
                                'print("010 << 1 = ", 0b010 << 1)'}
                            </SyntaxHighlighter>
                            Rezultatul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'2 in baza 2, 010= 2\n' +
                                '4 in baza 2, 100= 4\n' +
                                '010 << 1 =  4'}
                            </SyntaxHighlighter>

                            <i>Exercitiu:</i><br/>
                            Să calculăm 13 &lt;&lt; 3.
                            <br/>

                            Reprezentarea lui 13 este 0000000000001101. Deplasând toți biții spre stânga cu 3 poziții se obține: 0000000001101000, adică 104.
                            <br/>
                            Să observăm că 104 este egal cu 13 * 2^3.

                            <br/><br/>
                            În general <b>n &lt;&lt; k este n * 2^k</b>.<br/>
                            Pentru a calcula <b>2^n</b> putem folosi operația  <b>1 &lt;&lt; n</b>.
                            <hr/>
                        </li>
                        <li><b>{">>"} (deplasare spre dreapta  / binary right shift)</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'valoare >> biti'}
                            </SyntaxHighlighter>
                            deplasarea unei valori cu un bit la dreapta corespunde împărțiri la 2 (doar că bitul din dreapta este pierdut).
                            <br/>
                            (exemplu: 100 {">>"} 1 = 010, adica 4 / 2 = 2 (2 = 010, in baza 2, 4 = 100 in baza 2))

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("4 in baza 2, 100=",0b100)\n' +
                                'print("2 in baza 2, 010=",0b010)\n' +
                                'print("100 >> 1 = ", 0b100 >> 1)'}
                            </SyntaxHighlighter>
                            Rezultatul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'4 in baza 2, 100= 4\n' +
                                '2 in baza 2, 010= 2\n' +
                                '100 >> 1 =  2'}
                            </SyntaxHighlighter>
                            <i>Exercitiu:</i><br/>
                            Să calculăm 133 {">>"} 3.
                            <br/>
                            Reprezentarea lui 133 este 0000000010000101. Deplasând toți biții spre dreapta cu 3 poziții se obține: 0000000000010000 adică 16.
                            <br/>
                            Să observăm că 16 este egal cu 133 / 23.
                            <br/> <br/>
                            <b>În general n {">>"} k este n / 2k</b>

                        </li>
                    </ul>

                    <hr/>

                    <b>Forme abreviate:</b>
                    <ul>
                        <li><tt>x = x & y ----- x &= y</tt></li>
                        <li><tt>x = x | y ----- x |= y</tt></li>
                        <li><tt>x = x ^ y ----- x ^= y</tt></li>
                    </ul>

                    <hr/>

                    <b>Operatii pe biti:</b>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                A
                            </th>
                            <th>
                                B
                            </th>
                            <th>
                                A & B
                            </th>
                            <th>
                                A | B
                            </th>
                            <th>
                                A ^ B
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                0
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                                0
                            </td>
                        </tr>
                        <tr>
                            <td>
                                0
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                               1
                            </td>
                            <td>
                                1
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                1
                            </td>
                        </tr>

                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                0
                            </td>
                        </tr>


                        </tbody>
                    </table>

                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                A
                            </th>

                            <th>
                                ~ A
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                0
                            </td>
                            <td>
                                1
                            </td>

                        </tr>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                0
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    Simplificat:
                    <ul>
                        <li>& necesită exact doi 1 pentru a furniza 1 ca rezultat; echivalent: <b>A*B</b> sau <b>min(A,B)</b></li>
                        <li>| necesită cel puțin un 1 pentru a furniza 1 ca rezultat; echivalent cu: <b>max(A,B)</b></li>
                        <li>^ necesită exact un 1 pentru a furniza 1 ca rezultat</li>
                    </ul>

                    <hr/>
                    Fie x un numar intreg. Atunci, avem:
                    <ul>
                        <li>x & 1 = x</li>
                        <li>x & 0 = 0</li>

                        <li>x | 1 = 1</li>
                        <li>x | 0 = x</li>

                        <li>x ^ 1 = ~x</li>
                        <li>x ^ 0 = x</li>
                    </ul>
                    <hr/>
                    Sa presupunem ca avem o valoare. Si aceea valoare, este formata din biti. Putem sa vedem ca fiecare bit este o <b>stare</b>.
                    <br/>
                    Sa zicem ca avem valorea 5, in binar fiind reprezentat ca 101.
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                Numar
                            </th>
                            <th colspan="4">
                                Pozitie bit (in binar 5 este 101)
                            </th>
                        </tr>
                        <tr>
                            <th>
                              Valoare
                            </th>
                            <th>
                                3
                            </th>
                            <th>
                                2
                            </th>
                            <th>
                                1
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                5
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                0
                            </td>
                            <td>
                                1
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    Si acum, daca pe pozitia 3 din reprezentarea binara a numarului nostru este 1, atunci sistemul care verifica asta, va porni un cantecel in limba franceza.
                    <br/>
                    <b>1. Verificare bit (cu &):</b><br/>
                    Acum, sa invatam sistemul/programul sa faca verificarea de care am zis mai sus.
                    <br/>
                    In primul rand creem o masca cu valoare egala cu 2^(3-1), adica 4 (in binar 100):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'masca=4 # vrem sa verificam bitul de pe pozitia 3\n' +
                        '\n' +
                        'numarul_nostru = 5 # 101\n' +
                        'print(numarul_nostru & masca) # va returna va valoare masca, ceea ce e ok\n' +
                        '\n' +
                        'alt_numar = 3 # 011\n' +
                        'print(alt_numar & masca) # va returna 0, ceea ce nu e ok\n'}
                    </SyntaxHighlighter>

                    <b>2. Setare bit (cu |):</b><br/>
                    Acum, sa invatam sistemul/programul sa seteze valoarea 1 pe bitul de pe pozitia 3.
                    <br/>
                    Folosind aceeasi masca, cu valoare egala cu 2^(3-1), adica 4 (in binar 100):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'masca=4 # vrem sa verificam bitul de pe pozitia 3\n' +
                        '\n' +
                        'alt_numar = 3 # 011\n' +
                        'print(alt_numar & masca) # va returna 0, ceea ce nu e ok\n' +
                        '\n' +
                        'set_1 = alt_numar | masca\n' +
                        'print(set_1) # 7 '}
                    </SyntaxHighlighter>
                    In exemplul de mai sus, avem 3, adica 011 in binar. Dupa setarea bitului 1 pe pozitia 3 vom avea valoarea 7, adica 111.
                    <br/>
                    <br/>
                    <b>3. Resetare bit (cu & si ~ pe masca):</b><br/>
                    Acum, sa invatam sistemul/programul sa reseteze valoarea 1 pe bitul de pe pozitia 3.
                    <br/>
                    Folosind aceeasi masca, cu valoare egala cu 2^(3-1), adica 4 (in binar 100):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'masca=4 # vrem sa verificam bitul de pe pozitia 3\n' +
                        '\n' +
                        'numarul_nostru = 5 # 101\n' +
                        'print(numarul_nostru & ~masca) # 001, deci 1'}
                    </SyntaxHighlighter>

                    <b>4. Negarea bit (cu ^ - adica, daca e 0 sa devina 1 si daca e 1 sa devina 0):</b><br/>
                    Acum, sa invatam sistemul/programul sa reseteze valoarea 1 pe bitul de pe pozitia 3.
                    <br/>
                    Folosind aceeasi masca, cu valoare egala cu 2^(3-1), adica 4 (in binar 100):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'masca=4 # vrem sa verificam bitul de pe pozitia 3\n' +
                        '\n' +
                        'numarul_nostru = 5 # 101\n' +
                        'print(numarul_nostru ^ masca) # 001, deci 1'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://www.pbinfo.ro/articole/62/operatii-pe-biti"}>Operații pe biți</a>
                            </div>
                        </li>
                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BitsOperatorsPythonContent;