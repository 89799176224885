import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class LinksNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-links", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Link-uri si resurse utile</b>


                    <ul>
                        <li>
                            <a target={"_blank"} href={"http://nginx.org/en/docs/"}>nginx documentation</a>
                            <a target={"_blank"} href={"https://github.com/fcambus/nginx-resources"}>Nginx Resources</a>
                            <a target={"_blank"} href={"https://www.nginx.com/resources/wiki/start/topics/tutorials/config_pitfalls/"}>Pitfalls and Common Mistakes</a>
                            <a target={"_blank"} href={"https://docs.digitalocean.com/"}>digitalocean</a>
                            <a target={"_blank"} href={"https://wordpress.org/support/article/nginx/"}>Nginx</a>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LinksNginxContent;