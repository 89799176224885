import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class StringIIPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-string-ii", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>String-urile sunt secvente. String-urile sunt imutabile</b>.

                    <hr/>
                    <b>1. Iteratii</b>
                    <br/>
                    Nu sunt liste, dar pot fi tratate ca si cum ar fi. In acest sens, putem <b>itera</b>, folosindu-ne de index:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'string = \'curs practic\'\n' +
                        '\n' +
                        'for i in range(len(string)):\n' +
                        '    print(string[i], end=\' \') # c u r s   p r a c t i c '}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'string = \'curs practic\'\n' +
                        '\n' +
                        'for i in string:\n' +
                        '    print(i, end=\' \') # c u r s   p r a c t i c '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Slice</b>
                    <br/>
                    De asemenea functioneaza <b>slice</b>-urile ([de_la=0:pana_la=len(string):pas=1]):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'string = "abdefg"\n' +
                        '\n' +
                        'print(string[1:3]) # bd\n' +
                        'print(string[2:]) # efg\n' +
                        'print(string[:2]) # ab\n' +
                        'print(string[3:-1]) # ef\n' +
                        'print(string[-3:4]) # e\n' +
                        'print(string[::2]) # adf\n' +
                        'print(string[1::2])# beg'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>
                    Inversarea sirului se poate si in urmatorul mod, folosind slice: <b>[::-1]</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("abc"[::-1]) # cba'}
                    </SyntaxHighlighter>

                    <b>3. Operatori in / not in</b>
                    <br/>
                    Pe String-uri se pot folosi operatori de apartenenta (in/not in):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'text="abcde"\n' +
                        'print("b" in text) # True\n' +
                        'print("cd" in text) # True\n' +
                        'print("ac" in text) # False\n' +
                        'print("x" not in text) # True'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Functiile min()/max()</b>
                    <br/>
                        Cele doua functii aplicate pe un string, returneaza minimul/maximul caracterului (luandu-se in considerare valoarea numerica):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(min(\'abc\')) # a\n' +
                        'print(max(\'abc\')) # c\n' +
                        'print(max(\'Aabc\')) # c\n' +
                        'print(min(\'Zabc\')) # Z'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Metoda index()</b>
                    <br/>
                    Metoda index() returneaza primul index gasit de la care incepe subsirul in cadrul sirului (string-ului). Daca nu este gasit se arunca exceptia ValueError:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("abc".index("a")) # 0\n' +
                        'print("abc".index("b")) # 1\n' +
                        'print("abca".index("a")) # 0\n' +
                        'print("abc".index("A")) # ValueError: substring not found'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Functia list()</b>
                    <br/>
                    Functia list() creaza o lista avand ca elemente toate caracterele al sirului dat ca argument:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(list("abc")) # [\'a\', \'b\', \'c\']'}
                    </SyntaxHighlighter>

                    <b>6. Functia count()</b>
                    <br/>

                    Retureaza de cate ori apare un element in cadrul unui sir:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print("abcabcda".count("a")) # 3\n' +
                        'print(\'abc\'.count("z")) # 0\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Metode pe siruti de caractere / string-uri</b>
                    <br/>
                    <ul>
                        <li>
                            <b>capitalize():</b> creează un șir nou, cu urmatoarele reguli:
                            <ul>
                                <li>
                                    dacă primul caracter din șir este o literă (notă: primul caracter este un element cu indice egal cu 0, nu doar primul caracter vizibil), acesta va fi convertit în majuscule
                                </li>
                                <li>
                                    toate literele rămase din șir vor fi convertite în litere mici
                                </li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("KJ".capitalize()) # Kj\n' +
                                'print(\'kj\'.capitalize()) # Kj\n' +
                                'print(\' kj\'.capitalize()) # kj'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>title():</b> creează un șir nou, tranformand toate caracterele in litere mici, in afara de primul caracter al fiecarui cuvant pe care il transforma in litera mare:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("albA ca zapaDa".title()) # Alba Ca Zapada'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>swapcase():</b> creează un șir nou, tranformand toate literele mari in litere mici si literele mici in litere mari:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("albA ca zapaDa".swapcase()) # ALBa CA ZAPAdA'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>lower(): creează un șir nou, transformand toate caracterele in litere mici:
                                <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                    {'print("Alba Ca Zapada".lower()) # alba ca zapada'}
                                </SyntaxHighlighter>
                            </b>
                        </li>

                        <li>
                            <b>upper(): creează un șir nou, transforma nd toate caracterele in litere mari:
                                <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                    {'print("Alba Ca Zapada".upper()) # ALBA CA ZAPADA'}
                                </SyntaxHighlighter>
                            </b>
                        </li>

                        <li>
                            <b>endswith(end)</b>: returneaza True, daca sirul se termina cu subsirul <i>end</i>, si False altfel:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("abc".endswith("bc")) # True\n' +
                                'print("abc".endswith("z")) # False\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>startswith(start)</b>: returneaza True, daca sirul se termina cu subsirul <i>start</i>, si False altfel:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("abc".startswith("ab")) # True\n' +
                                'print("abc".startswith("z")) # False\n'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>find(substring, start_index=0, sfarsit_index=len(sir))</b>: returneaza indexul de la care incepe subsirul <i>substring</i> in sir, cautarea incepand cu indexul <i>start_index</i> si terminand cu indexul <i>sfarsit_index</i>; daca nu gaseste subsirul in sir, returneaza -1:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'sir = \'abcda\'\n' +
                                'print(sir.find(\'bc\')) # 1\n' +
                                'print(sir.find(\'da\')) # 3\n' +
                                'print(sir.find(\'daa\')) # -1\n' +
                                'print(sir.find(\'a\',1)) # 4\n' +
                                'print(sir.find(\'a\',10)) # -1'}
                            </SyntaxHighlighter>
                            <b>Observatie</b>:
                            <br/>
                            Metoda <b>find()</b> nu ar trebuie folosita dacă se doreste doar dacă un singur caracter apare într-un șir - operatorul <b>in</b> este semnificativ mai rapid.
                            <br/>  <br/>
                        </li>

                        <li>
                            <b>rfind(substring, start_index=0, sfarsit_index=len(sir))</b>: similar cu find(), doar ca atunci ca se cauta, se cauta de la sfarsit

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("albastrul e nou alb".rfind("alb")) # 16\n' +
                                'print("albastrul e nou alb".rfind("alb", 19)) # -1\n' +
                                'print("albastrul e nou alb".rfind("alb", 3, 9)) # -1'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>isalnum()</b>: verifică dacă șirul conține doar cifre și returnează True sau False în funcție de rezultat (atentie, spartiu nu este caracter alfanumeric)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("abc123".isalnum()) # True\n' +
                                'print("a b c".isalnum()) # False\n' +
                                'print("a@bc".isalnum()) # False\n' +
                                'print("123".isalnum()) # True'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>isalpha()</b>: verifică dacă șirul conține doar caractere alfabetice (litere) și returnează True sau False în funcție de rezultat
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("Abc".isalpha()) # True\n' +
                                'print("Abc1".isalpha()) # False'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>isdigit()</b>: verifică dacă șirul conține doar cifre sau caractere alfabetice (litere) și returnează True sau False în funcție de rezultat
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("123".isdigit()) # True\n' +
                                'print("1E".isdigit()) # False'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>islower()</b>: verifică dacă toate litere din sir sunt litere mici și returnează True sau False în funcție de rezultat
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("abc.".islower()) # True\n' +
                                'print("abc.1".islower()) # True\n' +
                                'print("abc".islower()) # True\n' +
                                'print("abcD".islower()) # False'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>isspace()</b>: verifică dacă toate caracterele din sir sunt spatii și returnează True sau False în funcție de rezultat
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(" .".isspace()) # False\n' +
                                'print("  ".isspace()) # True\n' +
                                'print("ABC".isspace()) # False\n'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>isupper()</b>: verifică dacă toate literele din sir sunt litere mari și returnează True sau False în funcție de rezultat
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("ABC.".isupper()) # True\n' +
                                'print("ABC.1".isupper()) # True\n' +
                                'print("ABC".isupper()) # True\n' +
                                'print("Aw".isupper()) # False'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>center(w,fill=' ')</b>: creează un șir nou, incercand sa-l centreze (astfel incat la final sa avem un sir de lungime <i>w</i>, in care sirul original este centrat), punand in fata si dupa, caracterul <i>spatiu</i> (in mod default) sau ce caracter este definit in al doilea parametru:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(\'[\' + \'alfa\'.center(2) + \']\') # [alfa]\n' +
                                'print(\'[\' + \'alfa\'.center(4) + \']\')  # [alfa]\n' +
                                'print(\'[\' + \'alfa\'.center(6) + \']\')  # [ alfa ]\n' +
                                'print(\'[\' + \'alfa\'.center(6,"=") + \']\')  # [=alfa=]\n' +
                                'print(\'[\' + \'alfa\'.center(7,"=") + \']\')  # [==alfa=]\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>join(lista)</b>: creaza un nou sir concatenand elementele din lista separandu-ule prin sirul pe care se apeleaza metoda:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(",".join(["kj", "are", "mare"])) # kj,are,mare\n' +
                                '\n' +
                                'print("".join(["kj", "are", "mare"])) # kjaremare'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>replace(subsir_vechi, subsir_nou, max_inlocuiri)</b>: creaza un nou sir inlocurind toate aparitiile (daca nu este specificat <i>max_inlocuiri</i>) sau un numar maxim de aparitii (<i>max_inlocuiri</i>) a lui <i>subsir_vechi</i> cu <i>subsir_nou</i>:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("Aka invata Python".replace("Aka","KJ")) # KJ invata Python\n' +
                                'print("Aka invata Python".replace("a","i")) # Aki inviti Python\n' +
                                'print("Aka invata Python".replace("","x")) # xAxkxax xixnxvxaxtxax xPxyxtxhxoxnx\n' +
                                'print("Aka invata Python".replace("","x",2)) # xAxka invata Python\n' +
                                'print("Aka invata Python".replace("Python","")) # Aka invata\n' +
                                'print("Aka invata Python".replace("Python","Germana")) # Aka invata Germana'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>lstrip(caractere=' ')</b>: returnează un șir nou, creat din cel original prin eliminarea tuturor:
                            <ul>
                                <li>
                                    spațiilor albe de început, daca se apeleaza fara parametri sau parametrul este chiar ' '
                                </li>
                                <li>
                                    caracterelor date ca parametru de la inceptul sirului
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("www.google.com".lstrip("wg.")) # oogle.com\n' +
                                'print("  com".lstrip()) # com\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>rstrip(caractere=' ')</b>: returnează un șir nou, creat din cel original prin eliminarea tuturor:
                            <ul>
                                <li>
                                    spațiilor albe de la sfarsti, daca se apeleaza fara parametri sau parametrul este chiar ' '
                                </li>
                                <li>
                                    caracterelor date ca parametru de la sfarsitul sirului
                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("www.google.com".rstrip(".mco")) # oogle.com\n' +
                                'print("www.google.com".rstrip(".com")) # oogle.com\n' +
                                'print("  com  ".rstrip()) # com'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b> strip()</b>: combina lstrip() si rstrip()

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("[" + "   manji si cai   ".strip() + "]") # [manji si cai]'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>split():</b> desparte șirul și construiește o listă cu toate subșirurile detectate (metoda presupune că subșirurile sunt delimitate de spații albe, iar spațiile nu iau parte la operație și nu sunt copiate în lista rezultată)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print("Ochi\\ncare nu se vad chiar nu exista".split()) # [\'Ochi\', \'care\', \'nu\', \'se\', \'vad\', \'chiar\', \'nu\', \'exista\']'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>

                    <b>8. Operatori de comparatie</b>
                    <ul>
                        <li>==</li>
                        <li>!=</li>
                        <li>{">"}</li>
                        <li>{">="}</li>
                        <li>&lt;</li>
                        <li>&lt;=</li>
                    </ul>

                    Comparatiile se fac:
                    <ul>
                        <li> comparând primul caracter diferit din ambele șiruri (ex: 'b'{">"}'a')</li>
                        <li> șirul mai lung este considerat mai mare (ex: 'abc'{">"}'ab' )</li>
                        <li> literele mari sunt considerate mai mici decât literele mici (ex: 'a'{">"}'B')</li>
                    </ul>

                    <b>Observatie:</b>
                    <br/>
                    Compararea șirurilor de caractere cu numere este în general o idee proastă (se pot folosi doar operatorii == si !=; daca se folosesc ceilalti se va obtine eroare):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(\'23\' == 23) # False\n' +
                        'print(\'23\' != 23) # True\n' +
                        'print(\'23\' == 2) # False\n' +
                        'print(\'23\' != 2) # True\n' +
                        'print(\'23\' > 23) # TypeError: \'>\' not supported between instances of \'str\' and \'int\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Sortare</b>
                    <br/>
                    Exista 2 metode de sortare:
                    <ul>
                        <li>
                            <b>functia sorted()</b>: creaza o noua lista
                        </li>
                        <li>
                            <b>metoda sort()</b>: nu creeaza o noua lista (sortare interna)
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'list = [\'java\', \'c++\', \'python\', \'rust\']\n' +
                        'sort_list = sorted(list)\n' +
                        '\n' +
                        'print(list) # [\'java\', \'c++\', \'python\', \'rust\']\n' +
                        'print(sort_list) # [\'c++\', \'java\', \'python\', \'rust\']\n' +
                        '\n' +
                        '\n' +
                        '# Demonstrating the sort() method:\n' +
                        'list = [\'java\', \'c++\', \'python\', \'rust\']\n' +
                        'list.sort()\n' +
                        '\n' +
                        'print(list) # [\'c++\', \'java\', \'python\', \'rust\']'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Conversii</b>
                    <ul>
                        <li><b>str()</b>: transforma un numar intr-un sir de caractere</li>
                        <li><b>int()</b>: transforma un sir de caractere intr-un numar intreg, daca e posibil (daca nu se poate se arunca o eroare de tipul ValueError )</li>
                        <li><b>float()</b>: transforma un sir de caractere intr-un numar flotant, daca e posibil (daca nu se poate se arunca o eroare de tipul ValueError )</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(str("1")) # 1\n' +
                        'print(int("2")) # 2\n' +
                        'print(float("1.2")) # 1.2\n' +
                        'print(int("1.2")) # ValueError: invalid literal for int() with base 10: \'1.2\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Observatii</b>
                    <ul>
                        <li>Nu se poate sterge un caracter dintr-un String, in felul urmator:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'text = "abc"\n' +
                                '\n' +
                                'del text[1] # TypeError: \'str\' object doesn\'t support item deletion'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Se poate sterge tot string-ul:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'text = "abc"\n' +
                                '\n' +
                                'del text'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Nu se poate adauga/inseara caractere, in felul urmator (pentru ca nu are aceste metode definite, dar se poate folosi operatorul '+' ):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'text = "abc"\n' +
                                '\n' +
                                'text.append("D") # AttributeError: \'str\' object has no attribute \'append\'\n' +
                                'text.insert(0,"E") # AttributeError: \'str\' object has no attribute \'insert\''}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>
                    <b>Joaca: LED</b>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'p = [\n' +
                        '        "#### ## ## ####", # 0 \n' +
                        '        "  #  #  #  #  #", # 1\n' +
                        '        "###  #####  ###", # 2\n' +
                        '        "###  ####  ####", # 3\n' +
                        '        "# ## ####  #  #", # 4\n' +
                        '        "####  ###  ####", # 5\n' +
                        '        "####  #### ####", # 6\n' +
                        '        "###  #  #  #  #", # 7\n' +
                        '        "#### ##### ####", # 8\n' +
                        '        "#### ####  ####", # 9\n' +
                        ']\n' +
                        '\n' +
                        'input = "0123456789"\n' +
                        '\n' +
                        'for l in range(0,5):\n' +
                        '    for cc in input:\n' +
                        '        c = int(cc)\n' +
                        '        print(p[c][l*3:l*3+3], end=" ")\n' +
                        '    print() '}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringIIPythonContent;