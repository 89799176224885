import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class OfRxJsReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-rxjs-of", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. RxJs: of</b>
                    <br/>
                    <br/>

                    Folosim acest operator pentru a realiza un Observabil care emite imediat valoarea (sau valorile) pe care le-am transmis ca intrare

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'// RxJS v6+\n' +
                            'import { of } from \'rxjs\';\n' +
                            '//emits any number of provided values in sequence\n' +
                            'const source = of(1, 2, 3, 4, 5);\n' +
                            '//output: 1,2,3,4,5\n' +
                            'const subscribe = source.subscribe(val => console.log(val));'}
                    </SyntaxHighlighter>

                    Poate fi folosit în mai multe scopuri; probabil cea mai comună este de a mock-ui răspunsul unui serviciu web:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'getVehicleInfo(vehiclePlate: string): Observable<CarInfo> {\n' +
                            '  return of({ model: "Ford Puma" , color: "Desert Island Blue", isCar: true });\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://medium.com/@andreacaruso093_54767/10-rxjs-operators-which-i-use-daily-as-an-angular-developer-b9af79120876"}>
                               10 RxJS operators which I use daily as an Angular developer
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OfRxJsReactContent;