import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class InheritanceMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-inheritance", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Mostenirea</b>:
                    <br/>
                    <br/>

                    Orice fisier implicit mostenerste un parinte. Parintele completeaza fisierul nostru <i>pom.xml</i>.
                    Ca sa vedem fisierul final (rezultat):
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:effective-pom'}
                    </SyntaxHighlighter>

                    Pentru a specifica ca un pom mosteneste un alt <i>pom.xml</i> se folosete tag-ul <b>parent</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'    <parent>\n' +
                            '        <artifactId>ro.maven.learn-root</artifactId>\n' +
                            '        <groupId>maven-project-learn</groupId>\n' +
                            '        <version>1.0-SNAPSHOT</version>\n' +
                            '    </parent>'}
                    </SyntaxHighlighter>
                    in plus, in <i>pom.xml</i>-ul copil nu mai este nevoie de:
                    <ul>
                        <li>
                            groupId
                        </li>
                        <li>
                            version
                        </li>
                    </ul>
                    pentru ca se mostenesc de la parinte.

                    <br/>
                    <br/>

                    Pentru a <i>instala</i> un artefact in <i>repository</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn install'}
                    </SyntaxHighlighter>

                    Pentru a evita probleme / erori, este nevoie ca ca in <i>pom.xml</i>-ul parinte, packaging=pom:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<packaging>pom</packaging>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InheritanceMavenContent;