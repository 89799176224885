import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ExportImportJsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-export-import-js", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Export</b>
                    <br/>
                    <br/>

                    Se poate exporta:
                    <ul>
                        <li>
                            variabila
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'export let zi = ["Luni", "Marti"]'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            constanta:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'export const PI=3.14'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            functie:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'export function x(){\n' +
                                    'return 3.14;\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            clasa:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export class User{\n' +
                                    '   constructor(name){\n' +
                                    '       this.name=name;\n' +
                                    '   }\n' +
                                    '' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Observatie:
                    <ul>
                        <li>Nu este nevoie de un punct și virgulă la sfârșitul lui <b>export class</b> și <b>export function</b></li>
                    </ul>

                    Exemplu de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'//say.js\n' +
                            'function sayHi(user) {\n' +
                            '  alert(`Hello, ${user}!`);\n' +
                            '}\n' +
                            '\n' +
                            'function sayBye(user) {\n' +
                            '  alert(`Bye, ${user}!`);\n' +
                            '}\n' +
                            '\n' +
                            'export {sayHi, sayBye}; '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// main.js \n' +
                            'import {sayHi, sayBye} from \'./say.js\';\n' +
                            '\n' +
                            'sayHi(\'John\'); // Hello, John!\n' +
                            'sayBye(\'John\'); // Bye, John!'}
                    </SyntaxHighlighter>

                    sau dacă sunt multe de importat, putem importa totul ca obiect folosind <b>import * as [obiect]</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// main.js\n' +
                            'import * as say from \'./say.js\';\n' +
                            '\n' +
                            'say.sayHi(\'John\');\n' +
                            'say.sayBye(\'John\');'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {sayHi as hi, sayBye as bye} from \'./say.js\';\n' +
                            '\n' +
                            'hi(\'John\'); // Hello, John!\n' +
                            'bye(\'John\'); // Bye, John!'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <ul>
                        <li>In JavaScript trebuie pusa si extensia <b>.js</b> la import</li>
                    </ul>

                    <hr/>

                    Exemplu <b>export as</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export {sayHi as hi, sayBye as bye};'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import * as say from \'./say.js\';\n' +
                            '\n' +
                            'say.hi(\'John\'); // Hello, John!\n' +
                            'say.bye(\'John\'); // Bye, John!'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. export default</b>
                    <br/>
                    <br/>

                    Modulele oferă o sintaxă specială <b>export default</b> (exportarea implicită) pentru a face ca modul „un lucru pe modul” să arate mai bine:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default class User { // just add "default"\n' +
                            '  constructor(name) {\n' +
                            '    this.name = name;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Mod folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import User from \'./user.js\'; // NU {User}, doar User, sau se poate da chiar alt nume \n' +
                            '\n' +
                            'new User(\'John\');'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import Utilizator from \'./user.js\';' +
                            '\n' +
                            'new Utilizator(\'John\');'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            import are nevoie de acolade pentru exporturile cu nume și nu are nevoie de ele pentru cea implicită
                        </li>
                    </ul>

                    Export numit:
                    <ul>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export class User {...}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'import {User} from ...'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Export implicit/default:
                    <ul>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'export default class User {...}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'import User from ...'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. cuvântul cheie default</b>
                    <br/>
                    <br/>

                    În unele situații, cuvântul cheie <b>default</b> este folosit pentru a face referire la exportul implicit/default:
                    <SyntaxHighlighter>
                        {'function sayHi(user) {\n' +
                            '  alert(`Hello, ${user}!`);\n' +
                            '}\n' +
                            '\n' +
                            '// same as if we added "export default" before the function\n' +
                            'export {sayHi as default};'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Aplicare module in html</b>
                    <br/>
                    <br/>
                    Sa prespunem ca avem un fisier <i>main.js</i>  care foloseste mecanisumul de import/export.
                    <br/>
                    Pentru a aplica acest fisier in pagina HTML, trebuie declarat scriptul ca <b>module</b> prin intermediul atributului <b>type</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<script type="module" src="main.js"></script>'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<script type="module">\n' +
                            '  /* JavaScript module code here */\n' +
                            '</script>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExportImportJsContent;