import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class LedFadeArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-led-fade", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LED + Fade</b>
                    <br/>
                    <br/>

                    Este similar cu clipirea unui LED, dar se foloseste un pin capabil de PWM (Pulse Width Modulation).
                    <br/>
                    PWM, (Pulse Width Modulation) este cel mai eficient mod de a controla circuitele analogice folosind ieșirile numerice, prin modificarea duratei și frecvenței semnalului.
                    <br/>
                    Pin capabili de PWM sunt marcati pa Arduino cu ~ sau - sau / si sunt: 3, 5, 6, 9, 10, 11.

                    <hr/>
                    Un pin capabil de PWM se comporta similar cu pin analog, dar poate lua o valoare intre 0 si 255 (un pin analog ia o valore intre 0 si 1024)
                    <br/>
                    Pentru un leg valoare 0 ar fi stins, iar 255 ar fi aprins complet.

                    <hr/>
                    Cod:
                    <SyntaxHighlighter>
                        {'void setup() {\n' +
                            '  // put your setup code here, to run once:\n' +
                            '  pinMode(9, OUTPUT);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '   for (brightness = 0; brightness <= 255; brightness += 5) {\n' +
                            '    analogWrite(9, brightness);\n' +
                            '    delay(30); // Wait for 30 millisecond(s)\n' +
                            '  }\n' +
                            '  for (brightness = 255; brightness >= 0; brightness -= 5) {\n' +
                            '    analogWrite(9, brightness);\n' +
                            '    delay(30); // Wait for 30 millisecond(s)\n' +
                            '  }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca e sa continuam exemplul precedent:
                    <SyntaxHighlighter>
                        {'// C++ code\n' +
                            '//\n' +
                            'int brightness = 0;\n' +
                            '\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  pinMode(9, OUTPUT);\n' +
                            '  pinMode(2, INPUT);\n' +
                            '  Serial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '  \n' +
                            '  int button = digitalRead(2);\n' +
                            '  Serial.println(button);\n' +
                            '  if (button ==HIGH ){\n' +
                            '    //digitalWrite(9, HIGH);\n' +
                            '\n' +
                            '     for (brightness = 0; brightness <= 255; brightness += 5) {\n' +
                            '        analogWrite(9, brightness);\n' +
                            '        delay(30); // Wait for 30 millisecond(s)\n' +
                            '     }\n' +
                            '     \n' +
                            '     for (brightness = 255; brightness >= 0; brightness -= 5) {\n' +
                            '       analogWrite(9, brightness);\n' +
                            '       delay(30); // Wait for 30 millisecond(s)\n' +
                            '     }\n' +
                            '\n' +
                            '  }else{\n' +
                            '    digitalWrite(9, LOW);\n' +
                            '  }\n' +
                            '  \n' +
                            '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=yBgMJssXqHY&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=5&ab_channel=ScienceBuddies"}>
                                How to Use a Button with an Arduino (Lesson #5)
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"} href={"https://www.projecthub.in/how-to-use-a-push-button-with-arduino/"}>How To Use A Push Button With Arduino</a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LedFadeArduinoContent;