import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class NXQLNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-nxql", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    In Nuxeo, orice este de tip <b>Document</b>.
                    <br/>
                    <br/>

                    1. Pentru a obtine toate documentele (acest query va returna si versiunile fiecarui document, dar si fisierele care sunt in cosul de gunoi):
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from Document'}
                    </SyntaxHighlighter>
                    Pentru a filtra sa nu mai aduca si versiunile fiecarui document si fisierele care sunt in cosul de gunoi:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from Document where ecm:isVersion = 0 AND ecm:isTrashed = 0'}
                    </SyntaxHighlighter>

                    2. Pentru a obtine un document de nu anumit tip (<i>CerereConcediu</i> este un tip de document):
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from CerereConcediu'}
                    </SyntaxHighlighter>

                    3. Pentru a obtine documentele de tip File:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from File'}
                    </SyntaxHighlighter>
                    Daca <i>CerereConcediu</i> extinde <i>File</i>, atunci vor fi returnate si documentele de tip <i>CerereConcediu</i>

                    <br/>
                    <br/>

                    4. Pentru a obtine documentele de tip <i>CerereConcediu</i>, care sunt in folderul <i>CERERI_DE_CONCEDIU</i> :
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from CerereConcediu where ecm:path STARTSWITH \'default-domain/cereri/CERERI_DE_CONCEDIU\''}
                    </SyntaxHighlighter>

                    5. Pentru a obtine documentele de tip <i>File</i>, unde al 3-lea contributor este KJ:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from File where dc:contributors/2="KJ"'}
                    </SyntaxHighlighter>

                    6. Pentru a obtine documentele de tip  <i>CerereConcediu</i>, unde in lista de proiecte exista proiecte care nu necesita preluare:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from CerereConcediu where proiecte/*/proiect/necesitaPrelucare=0'}
                    </SyntaxHighlighter>

                    7. Pentru a obtine documentele de tip  <i>File</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'select * from Document where ecm:primarType=\'File\''}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    Nu se pot face JOIN-uri! (din motive de performanta)

                    <hr/>
                    Mai multe: <a href={"https://doc.nuxeo.com/nxdoc/nxql/"}>aici</a>!

                </div>


                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NXQLNuxeoContent;