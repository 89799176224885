import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class PullDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-pull", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker pull / docker image pull</b>

                    <br/>
                    <br/>

                    Pentru a descarca o imagine de pe Docker Hub sau repository-ul remote pe repository-ul local:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull [imagine]'}
                    </SyntaxHighlighter>

                    Este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image pull [imagine]'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull nginx:latest'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'latest: Pulling from library/nginx\n' +
                            '3f4ca61aafcd: Pull complete\n' +
                            '50c68654b16f: Pull complete\n' +
                            '3ed295c083ec: Pull complete\n' +
                            '40b838968eea: Pull complete\n' +
                            '88d3ab68332d: Pull complete\n' +
                            '5f63362a3fa3: Pull complete\n' +
                            'Digest: sha256:0047b729188a15da49380d9506d65959cce6d40291ccfb4e039f5dc7efd33286\n' +
                            'Status: Downloaded newer image for nginx:latest\n' +
                            'docker.io/library/nginx:latest\n'}
                    </SyntaxHighlighter>

                    Pentru a downloada toate variantele unei imagini se foloseste optiune <b>--all-tags</b>.
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker pull --all-tags nginx'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PullDockerContent;