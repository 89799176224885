import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class AWSDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-aws", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare cont AWS </b>
                    <br/>
                    <br/>

                    Creare cont:
                    <a target={"_blank"} href={"https://aws.amazon.com/"}>https://aws.amazon.com/</a>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img.png'}/>
                    </div>

                    Se apasa pe butonul <b>Complete Sign Up</b>.

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_1.png'}/>
                    </div>

                    Se apasa pe butonul <b>Create a new AWS account</b>.

                    <br/>
                    Apoi se completeaza adresa de email si numele contului AWS:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_3.png'}/>
                    </div>
                    Si se apasa <b>Verify email address</b>.
                    <br/>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_2.png'}/>
                    </div>

                    Se completeaza campul <b>Verification code</b> cu codul primit pe email:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_4.png'}/>
                    </div>
                    apoi se trece prin cativa pasi in care se completeaza:
                    <ul>
                        <li>detalii despre utilizator</li>
                        <li>detalii despre card</li>
                    </ul>

                    apoi se selecteaza <b>Root user</b> {"->"} se introduce adresa de email {"->"} <b>Next</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_5.png'}/>
                    </div>

                    <hr/>
                    <b>2. Creare aplicatie Docker </b>
                    <br/>
                    <br/>

                    dupa logare, de obicei vom fi redictat in ecranul <b>Console Home</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_6.png'}/>
                    </div>
                    In zona de cautare, introducem <b>elastic beanstalk</b>; selectam si vom fi redirectati:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_7.png'}/>
                    </div>
                    Apasam pe butonul <b>Create Application</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_8.png'}/>
                    </div>
                    si introducem:
                    <ul>
                        <li>Application name: <i>docker-react</i> (numele aplicatiei nostre; sau orice alt nume)</li>
                        <li>Platform: <b>Docker</b></li>
                        <li>Platform branch: Docker running on 65bit Amazon Linux 2 (se precompleteaza automat)</li>
                        <li>Platform version: 3.5.0 (Recommended)  (se precompleteaza automat)</li>
                        <li>Application code: bifat <b>Sample application</b> (implicit selectata)</li>
                    </ul>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_9.png'}/>
                    </div>
                    Se apasa pe butonul <b>Create application</b>.
                    <br/>
                    se creaza mediul:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_10.png'}/>
                    </div>
                    dupa ce se creeaza mediul: <b>Environments</b>, va apare la <b>Health</b>: <b>OK</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_11.png'}/>
                    </div>

                    Apasand din tabelul cu toate mediile  <b>Environments</b> pe link-ul din coloana <b>Environment name</b> (Dockerreact-env):

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_13.png'}/>
                    </div>


                    Iar aplicatia se poate fi accesata, dand click url-ul corespunzator coloanei <b>URL</b>: <i>http://dockerreact-env.eba-mvqtbrpm.eu-central-1.elasticbeanstalk.com/</i>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_12.png'}/>
                    </div>

                    <hr/>
                    <b>3. Travis/GitHub Actions cu AWS</b>
                    <br/>
                    <br/>
                    Pentru Travis, modificam scriptul <b>.travis.yml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo: required\n' +
                            'language: generic\n' +
                            '\n' +
                            'services:\n' +
                            '  - docker\n' +
                            '\n' +
                            'before_install:\n' +
                            '  - docker build -t iulianbuzdugan/docker-react -f Dockerfile.dev .\n' +
                            '\n' +
                            'script:\n' +
                            '  - docker run -e CI=true iulianbuzdugan/docker-react npm run test\n' +
                            '\n' +
                            'deploy:\n' +
                            '  provider: elasticbeanstalk\n' +
                            '  region: \'eu-central-1\'\n' +
                            '  app: \'docker-react\'\n' +
                            '  env: \'Dockerreact-env\'\n' +
                            '  bucket_name: \'elasticbeanstalk-eu-central-1-284870421764\'\n' +
                            '  bucket_path: \'docker-root\'\n' +
                            '  on:\n' +
                            '    branch: master\n'}
                    </SyntaxHighlighter>

                    Pentru GitHub Actions, modificam scriptul .github/workflows/<b>deploy.yaml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'name: Deploy Frontend\n' +
                            'on:\n' +
                            '  push:\n' +
                            '    branches:\n' +
                            '      - master\n' +
                            '\n' +
                            'jobs:\n' +
                            '  build:\n' +
                            '    runs-on: ubuntu-latest\n' +
                            '    steps:\n' +
                            '      - uses: actions/checkout@v2\n' +
                            '      - run: docker login -u ${{ secrets.DOCKER_USERNAME }} -p ${{ secrets.DOCKER_PASSWORD }}\n' +
                            '      - run: docker build -t iulianbuzdugan/docker-react -f Dockerfile.dev .\n' +
                            '      - run: docker run -e CI=true iulianbuzdugan/docker-react npm test\n' +

                            '\n' +
                            '      - name: Generate deployment package\n' +
                            '        run: zip -r deploy.zip . -x \'*.git*\'\n' +
                            '\n' +
                            '      - name: Deploy to EB\n' +
                            '        uses: einaregilsson/beanstalk-deploy@v20\n' +
                            '        with:\n' +
                            '          aws_access_key: ${{ secrets.AWS_ACCESS_KEY_ID }}\n' +
                            '          aws_secret_key: ${{ secrets.AWS_SECRET_ACCESS_KEY }}\n' +
                            '          application_name: docker-react\n' +
                            '          environment_name: Dockerreact-env\n' +
                            '          version_label: ${{ github.sha }}\n' +
                            '          region: eu-central-1\n' +
                            '          deployment_package: deploy.zip'}
                    </SyntaxHighlighter>
                    ** dupa ce se creaza si user-ul AWS, se adauga si pe GitHub in tab-ul proictului <i>docker-react</i>: <b>Settings {"->"} Secrets {"->"} New repository secret</b>:
                    <ul>
                        <li>AWS_ACCESS_KEY_ID: AKIAUEU4ZVUCPZLBOEO4</li>
                        <li>AWS_SECRET_ACCESS_KEY: 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</li>
                    </ul>

                    Sectiunea care face legatura dintre Travis/GitHub Actions si AWS este <b>deploy</b>.
                    Completam aceasta sectiune in felul urmator:
                    <ul>
                        <li>
                            <b>provider</b>: <b>elasticbeanstalk</b>
                        </li>
                        <li>
                            <b>region</b>: <i>eu-central-1</i>
                            <br/>
                            (din coloana <b>URL</b>, se poate lua link-ul <i>Dockerreact-env.eba-mvqtbrpm.<b>eu-central-1</b>.elasticbeanstalk.com</i>)
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_14.png'}/>
                            </div>
                        </li>
                        <li>
                            <b>app</b>: <i>docker-react</i>
                            <br/>
                            (din coloana <b>Application name</b>)
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_15.png'}/>
                            </div>
                        </li>
                        <li>
                            <b>env</b>: <i>Dockerreact-env</i>
                            <br/>
                            (din coloana <b>Environment name</b>)
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_16.png'}/>
                            </div>
                        </li>
                        <li>
                            <b>bucket_name</b>: <i>elasticbeanstalk-eu-central-1-284870421764</i>

                            <br/>
                            <br/>
                            pentru a gasit numele, se cauta s3 in zonca de cautare:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_17.png'}/>
                            </div>

                            apoi:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_18.png'}/>
                            </div>
                            apoi:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_19.png'}/>
                            </div>

                        </li>
                        <li>
                            <b>bucket_path</b>: <i>docker-root</i>
                            <br/>
                            in mod implicit e numele aplicatiei (<b>app</b>): <b>docker-react</b>
                        </li>
                        <li>
                            <b>on/branch</b>: <i>master</i>
                            <br/>
                            cand se comite pe branch-ul <i>master</i> se intampla parte de <i>deploy</i>
                        </li>

                    </ul>

                    <hr/>
                    <b>5. Creare user AWS</b>
                    <br/>
                    <br/>

                    Se creeaza un user folosit de Travis/Github Actions:
                    <ul>
                        <li>
                            pentru aceasta ce cauta in zona de cautare: <i>iam</i>
                            <br/>
                            se alege <b>IAM - Manage acces to AWS resources</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_20.png'}/>
                            </div>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_21.png'}/>
                            </div>

                            Din sectiunea <b>Users</b> se apasa pe butonul <b>Add users</b>:

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_22.png'}/>
                            </div>
                            se completeaza:
                            <ul>
                                <li>User name: docker-react-travis-cli</li>
                                <li>Access type: Programmatic access</li>
                            </ul>
                            Se apasa pe butonul <b>Next Persmissions</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_23.png'}/>
                            </div>

                            apoi, se selecteaza <b>Attach existing policies directly</b> si se alege <b>AdministratorAccess-AWSElasticBeanstalk</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_24.png'}/>
                            </div>
                            se da Next: Tags

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_25.png'}/>
                            </div>

                            se da Next: Review

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_26.png'}/>
                            </div>

                            se apasa <b>Create user</b>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_27.png'}/>
                            </div>

                            se retine:
                            <ul>
                                <li>
                                    User: docker-react-travis-cli
                                </li>
                                <li>
                                    Access Key ID: AKIAUEU4ZVUCPZLBOEO4
                                </li>
                                <li>
                                    Secret Access Key: jlabTek6woKkVVYd+veoDK8TMIwBUrhd98WAEI06
                                </li>
                            </ul>
                            <b>Observatie</b>
                            <br/>
                            Dacă pierdeți sau uitați cheia secretă, nu o puteți recupera. În schimb, creați o nouă cheie de acces și faceți vechea cheie inactivă.

                        </li>
                    </ul>

                    <hr/>
                    <b>5. Adaugare credentiale user AWS in Travis</b>
                    <br/>
                    <br/>

                    Se urmeaza urmatorii pasi:
                    <ul>

                        <li>
                            Se merge in Dashboard-ul Travis: <a target={"_blank"} href={"https://app.travis-ci.com/dashboard"}>https://app.travis-ci.com/dashboard</a>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_28.png'}/>
                            </div>

                        </li>
                        <li>
                            Se selectaza Settings:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:250}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_29.png'}/>
                            </div>

                        </li>

                        <li>
                            In sectiunea <b>Environment Variabiles</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/docker-aws/img_30.png'}/>
                            </div>
                            se definesc:
                            <ul>
                                <li>
                                    AWS_ACCESS_KEY: AKIAUEU4ZVUCPZLBOEO4
                                </li>
                                <li>
                                    AWS_SECRET_KEY: jlabTek6woKkVVYd+veoDK8TMIwBUrhd98WAEI06
                                </li>
                            </ul>

                        </li>
                    </ul>

                    Se completeaza fisierul <b>.travis.yml</b> cu ultimele 2 linii:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo: required\n' +
                            'language: generic\n' +
                            '\n' +
                            'services:\n' +
                            '  - docker\n' +
                            '\n' +
                            'before_install:\n' +
                            '  - docker build -t iulianbuzdugan/docker-react -f Dockerfile.dev .\n' +
                            '\n' +
                            'script:\n' +
                            '  - docker run -e CI=true iulianbuzdugan/docker-react npm run test\n' +
                            '\n' +
                            'deploy:\n' +
                            '  provider: elasticbeanstalk\n' +
                            '  region: \'eu-central-1\'\n' +
                            '  app: \'docker-react\'\n' +
                            '  env: \'Dockerreact-env\'\n' +
                            '  bucket_name: \'elasticbeanstalk-eu-central-1-284870421764\'\n' +
                            '  bucket_path: \'docker-root\'\n' +
                            '  on:\n' +
                            '    branch: master\n' +
                            '  access_key_id: $AWS_ACCESS_KEY\n' +
                            '  secret_access_key: $AWS_SECRET_KEY\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Expunere port</b>
                    <br/>
                    <br/>

                    In fisierul <b>Dockerfile</b> se adauga <b>EXPOSE 80</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'FROM node:16-alpine as builder\n' +
                            'WORKDIR \'/app\'\n' +
                            'COPY package.json .\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'RUN npm run build\n' +
                            '\n' +
                            'FROM nginx\n' +
                            'EXPOSE 80\n' +
                            'COPY --from=builder /app/build /usr/share/nginx/html'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Build complet</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git commit -m "travis aws"'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>

                    Pe Travis:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_31.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_32.png'}/>
                    </div>

                    Pe AWS:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_34.png'}/>
                    </div>

                    Cu Travis:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_35.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_36.png'}/>
                    </div>

                    Cu GitHub Actions:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_37.png'}/>
                    </div>

                    <hr/>
                    <b>8. Buckets</b>
                    <br/>
                    <br/>

                    Avand in vedere ca am facut deploy cu Travis si cu GitHub Actions, avem:
                    <ul>
                        <li>docker-react (pt GitHub Actions)</li>
                        <li>docker-root (pt Travis; e definit si in fisierul <i>.travis.yml</i> {"=>"}  <i>bucket_path</i>)</li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_38.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_39.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/docker-aws/img_40.png'}/>
                    </div>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AWSDockerContent;