import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"typescript-root", url:"#", title:"Typescript", subtitle:""},

        {id:"typescript-install", url:"/typescript/install", title:"Instalare", subtitle:"", parent:"typescript-root"},
        {id:"typescript-types", url:"/typescript/types", title:"Tipuri", subtitle:"", parent:"typescript-root"},
        {id:"typescript-objects", url:"/typescript/objects", title:"Obiecte", subtitle:"", parent:"typescript-root"},
        {id:"typescript-classes", url:"/typescript/classes", title:"Clase", subtitle:"", parent:"typescript-root"},
        {id:"typescript-generics", url:"/typescript/generics", title:"Generice", subtitle:"", parent:"typescript-root"},
        {id:"typescript-decorators", url:"/typescript/decorators", title:"Decoratori", subtitle:"", parent:"typescript-root"},

        {id:"typescript-pick", url:"/typescript/pick", title:"Pick", subtitle:"", parent:"typescript-root"},
        {id:"typescript-omit", url:"/typescript/omit", title:"Omit", subtitle:"", parent:"typescript-root"},

    ]

    static indexUrl = "/typescript/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Typescript
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;