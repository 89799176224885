import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class UserwayIntegrationLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-userway", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Accesibilitate: Userway</b>

                    <br/>
                    <br/>
                    Fragment de cod:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<div class="fixed-icon" id="user-accessibility-icon">\n' +
                            '        <script>\n' +
                            '            (function(d){\n' +
                            '                var s = d.createElement("script");\n' +
                            '                s.setAttribute("data-position", 5);\n' +
                            '                s.setAttribute("data-size", "large");\n' +
                            '                s.setAttribute("data-color", "#004C55");\n' +
                            '                s.setAttribute("data-type", "2");\n' +
                            '                s.setAttribute("data-account", "9bypO3Ua9N");\n' +
                            '                s.setAttribute("src", "https://cdn.userway.org/widget.js");\n' +
                            '                (d.body || d.head).appendChild(s);})(document)\n' +
                            '        </script>\n' +
                            '        <noscript>\n' +
                            '            Please ensure Javascript is enabled for purposes of\n' +
                            '            <a href="https://userway.org">website accessibility</a>\n' +
                            '        </noscript>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    Fie <b>portal_normal.ftl</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                            '\n' +
                            '<#include init />\n' +
                            '\n' +
                            '<html class="${root_css_class}" dir="<@liferay.language key="lang.dir" />" lang="${w3c_language_id}">\n' +
                            '\n' +
                            '<head>\n' +
                            '    <title>${html_title}</title>\n' +
                            '\n' +
                            '    <meta content="initial-scale=1.0, width=device-width" name="viewport"/>\n' +
                            '\n' +
                            '    <@liferay_util["include"] page=top_head_include />\n' +
                            '</head>\n' +
                            '\n' +
                            '<body class="${css_class} ${addStyle}">\n' +
                            '\n' +
                            '<@liferay_ui["quick-access"] contentId="#main-content" />\n' +
                            '\n' +
                            '<@liferay_util["include"] page=body_top_include />\n' +
                            '\n' +
                            '<#if !isInfochiosk>\n' +
                            '    <div class="fixed-icon" id="user-accessibility-icon">\n' +
                            '        <script>\n' +
                            '            (function(d){\n' +
                            '                var s = d.createElement("script");\n' +
                            '                s.setAttribute("data-position", 5);\n' +
                            '                s.setAttribute("data-size", "large");\n' +
                            '                s.setAttribute("data-color", "#004C55");\n' +
                            '                s.setAttribute("data-type", "2");\n' +
                            '                s.setAttribute("data-account", "9bypO3Ua9N");\n' +
                            '                s.setAttribute("src", "https://cdn.userway.org/widget.js");\n' +
                            '                (d.body || d.head).appendChild(s);})(document)\n' +
                            '        </script>\n' +
                            '        <noscript>\n' +
                            '            Please ensure Javascript is enabled for purposes of\n' +
                            '            <a href="https://userway.org">website accessibility</a>\n' +
                            '        </noscript>\n' +
                            '    </div>\n' +
                            '\n' +
                            '    <div class="fixed-icon" id="back-to-top-icon">\n' +
                            '        <img src="${images_folder}/svg/scroll_to_top_icon.svg" alt="Back to top"/>\n' +
                            '    </div>\n' +
                            '\n' +
                            '<#--    <script type="text/javascript" src=https://chat.ibrid.ro/webchat.js async clientkey=e79f32dd8982d4adcab9 projectid=3 site_language="ro"></script>-->\n' +
                            '\n' +
                            '    <script type="text/javascript">\n' +
                            '\n' +
                            '        let src = \'https://chat.ibrid.ro/webchat.js\';\n' +
                            '        let clientKey = \'KEY\';\n' +
                            '        let projectID = \'3\';\n' +
                            '        let siteLanguage = \'ro\';\n' +
                            '\n' +
                            '        window.onload = function () {\n' +
                            '            buildChatBot();\n' +
                            '        }\n' +
                            '\n' +
                            '\n' +
                            '        function buildChatBot(){\n' +
                            '            var script = document.createElement(\'script\');\n' +
                            '            script.setAttribute("type", "text/javascript");\n' +
                            '            script.setAttribute("src", src);\n' +
                            '            script.setAttribute("clientkey", clientKey);\n' +
                            '            script.setAttribute("projectid", projectID);\n' +
                            '            script.setAttribute("site_language", siteLanguage);\n' +
                            '            document.head.appendChild(script);\n' +
                            '\n' +
                            '        }\n' +
                            '    </script>\n' +
                            '\n' +
                            '</#if>\n' +
                            '\n' +
                            '<div class="d-flex flex-column min-vh-100">\n' +
                            '    <@liferay.control_menu />\n' +
                            '\n' +
                            '    <div class="d-flex flex-column flex-fill position-relative" id="wrapper">\n' +
                            '        <section class="${portal_content_css_class} flex-fill" id="content">\n' +
                            '            <h2 class="sr-only">${htmlUtil.escape(the_title)}</h2>\n' +
                            '\n' +
                            '            <#if selectable>\n' +
                            '                <@liferay_util["include"] page=content_include />\n' +
                            '            <#else>\n' +
                            '                ${portletDisplay.recycle()}\n' +
                            '\n' +
                            '                ${portletDisplay.setTitle(the_title)}\n' +
                            '\n' +
                            '                <@liferay_theme["wrap-portlet"] page="portlet.ftl">\n' +
                            '                    <@liferay_util["include"] page=content_include />\n' +
                            '                </@>\n' +
                            '            </#if>\n' +
                            '        </section>\n' +
                            '    </div>\n' +
                            '</div>\n' +
                            '\n' +
                            '<script src="${javascript_folder}/vendor/jquery.ihavecookies.min.js" type="text/javascript"></script>\n' +
                            '<script src="${javascript_folder}/custom.js" defer></script>\n' +
                            '\n' +
                            '<@liferay_util["include"] page=body_bottom_include />\n' +
                            '\n' +
                            '<@liferay_util["include"] page=bottom_include />\n' +
                            '\n' +
                            '</body>\n' +
                            '\n' +
                            '</html>'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://liferay.dev/de/blogs/-/blogs/fronting-liferay-tomcat-with-apache-httpd-daemon-revisted"}>*/}
                    {/*                Fronting Liferay Tomcat with Apache HTTPd daemon Revisted*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UserwayIntegrationLiferayContent;