import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class DynamicComponentsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-dynamic-components", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Componente dinamice: alegerea tipului în timpul execuției</b>

                    Nu puteți utiliza o expresie generală ca tip de element React.
                    Dacă doriți să utilizați o expresie generală pentru a indica tipul elementului, <b>mai întâi atribuiți-o unei variabile cu majuscule</b>.
                    <br/>
                    Acest lucru apare adesea atunci când doriți să randați o componentă diferită bazată pe o recuzită/props:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React from \'react\';\n' +
                            'import { PhotoStory, VideoStory } from \'./stories\';\n' +
                            '\n' +
                            'const components = {\n' +
                            '  photo: PhotoStory,\n' +
                            '  video: VideoStory\n' +
                            '};\n' +
                            '\n' +
                            'function Story(props) {\n' +
                            '  // Wrong! JSX type can\'t be an expression.\n' +
                            '  return <components[props.storyType] story={props.story} />;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Modul corect:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React from \'react\';\n' +
                            'import { PhotoStory, VideoStory } from \'./stories\';\n' +
                            '\n' +
                            'const components = {\n' +
                            '  photo: PhotoStory,\n' +
                            '  video: VideoStory\n' +
                            '};\n' +
                            '\n' +
                            'function Story(props) {\n' +
                            '  // Correct! JSX type can be a capitalized variable.\n' +
                            '  const SpecificStory = components[props.storyType];\n' +
                            '  return <SpecificStory story={props.story} />;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemplu 1</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const menu = [\n' +
                            '  { title: \'Home\', icon: HomeIcon },\n' +
                            '  { title: \'Notifications\', icon: BellIcon },\n' +
                            '  { title: \'Profile\', icon: UserIcon },\n' +
                            ']\n' +
                            '\n' +
                            'const Icon = (props) => {\n' +
                            '  const { icon } = props\n' +
                            '  const TheIcon = icon\n' +
                            '  return <TheIcon {...props} />\n' +
                            '}\n' +
                            '\n' +
                            '...\n' +
                            '\n' +
                            '<Icon icon={item.icon} />'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Echivalente</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// Componenta Dummy returneaza un  \'div\'\n' +
                            'const Dummy = () => <div>Testing</div>\n' +
                            '\n' +
                            '// Este echivalent cu: React.createElement cu \'div\'\n' +
                            'const SomeComponent = () => React.createElement(\'div\', {}, \'Testing\')'}
                    </SyntaxHighlighter>

                    si
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const SomeComponent = () => <Foo>Testing</Foo>\n' +
                            'const SomeComponent = () => React.createElement(Foo, {}, \'Testing\')'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>Exemplu 2</b>
                    <br/>
                    <br/>

                    In exemplul de mai jos se foloseste TypeScript:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, { FunctionComponent, ReactNode } from \'react\';\n' +
                            '\n' +
                            'interface Props {\n' +
                            '  type: \'h1\' | \'h2\' | \'h3\' | \'h4\' | \'h5\' | \'h6\';\n' +
                            '  children: ReactNode;\n' +
                            '}\n' +
                            '\n' +
                            'const Heading: FunctionComponent<Props> = ({\n' +
                            '  type,\n' +
                            '  children,\n' +
                            '}) => {\n' +
                            '  const Component = type;\n' +
                            '\n' +
                            '  return (\n' +
                            '    <Component>\n' +
                            '      {children}\n' +
                            '    </Component>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>
                    sau mai simplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const Heading: FunctionComponent<Props> = ({\n' +
                            '  type: Component,\n' +
                            '  children,\n' +
                            '}) => {\n' +
                            '  return (\n' +
                            '    <Component>\n' +
                            '      {children}\n' +
                            '    </Component>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fara TypeScript:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const Heading = ({\n' +
                            '  Component,\n' +
                            '  children,\n' +
                            '}) => {\n' +
                            '  return (\n' +
                            '    <Component>\n' +
                            '      {children}\n' +
                            '    </Component>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DynamicComponentsReactContent;