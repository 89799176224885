import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ExceptionsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-exceptions", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Exceptii</b>
                    <br/> <br/>

                    Tratarea exeptiilor se face prin termediul a doua cuvinte cheie <b>try</b> si <b>except</b>.
                    Acestea deliminteaza doua blocuri/ramuri de cod:
                    <ul>
                        <li>blocul <b>try</b>: codul unde poate poate o eroare (exceptie); daca apare o eroare, atunci se arunca o exceptie;</li>
                        <li>blocul <b>except</b>: codul unde se prinde exceptia si se gestioneaza exceptia</li>
                    </ul>

                    Orice parte a codului plasată între <b>try</b> și <b>except</b> este executată într-un mod foarte special
                    – orice eroare care apare aici nu va opri execuția programului.
                    În schimb, controlul va sări imediat la prima linie situată după cuvântul cheie <b>except</b> și nicio altă parte a ramurii <b>try</b> nu mai este executată;
                    <br/>
                    <br/>
                    Codul din ramura <b>except</b> este activat numai atunci când a fost întâlnită o excepție în blocul <b>try</b>. Nu există nicio modalitate de a ajunge acolo prin alte mijloace;

                    <br/>
                    <br/>
                    Dupa ce blocul <b>try</b> sau <b>except</b> este executat cu succes, controlul revine la calea normală de execuție.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '\t# codul unde poate poate o eroare (exceptie);\n' +
                        '    # daca apare o eroare, atunci se arunca o exceptie; \n' +
                        'except:\n' +
                        '\t# codul unde se prinde exceptia\n' +
                        '\t# si se gestioneaza exceptia'}
                    </SyntaxHighlighter>

                    Pot exista mai multe ramuri <b>except</b> pentru fiecare tip de exceptie (anticipata) care poate fi aruncata:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '\t# codul unde poate poate o eroare (exceptie);\n' +
                        '    # daca apare o eroare, atunci se arunca o exceptie; \n' +
                        'except Eroare_1:\n' +
                        '\t# codul gestionare eroare de tip Eroare_1\n' +
                        'except Eroare_2\n' +
                        '\t# codul gestionare eroare de tip Eroare_1\n' +
                        'except Eroare_N\n' +
                        '\t# codul gestionare eroare de tip Eroare_N\n' +
                        '\t'}
                    </SyntaxHighlighter>

                    La aruncarea unei exceptii doar una dintre toate ramurile <b>except</b> poate intercepta controlul (in functie de tipul exceptiei aruncat) – restul de ramuri <b>except</b> rămânand inactive.
                    <br/>
                    <br/>
                    Niciuna dintre excepții nu poate fi specificată de mai multe ori.
                    <br/>
                    Nu pot exista mai multe ramuri <i>fara tip de eroare</i> (in cadrul unui bloc try-except).
                    <br/>
                    Daca nici o ramuara <b>except</b> nu prinde exceptia (adica exista doar ramuri <b>except</b> <i>cu tip de eroare</i> si nu exista ramura <b>except</b> <i>fara tip de eroare</i>) atunci nu se trateaza exceptia si se arunca exceptia.
                    <hr/>
                    <b>2. Ramura de eroare implicita</b>
                    <br/> <br/>

                    O ramura <b>except</b> fara specificarea unui tip de eroare (adica <b>except:</b>) este o ramura de eroare implicita.
                    Deci, atunci când se ridică o excepție și nu există nicio ramură <b>except</b> <b>dedicată</b> acestei excepții, atunci aceasta exceptie va fi gestionată de ramura implicită.
                    <br/>
                    <b>Ramura de eroare implicita trebuie sa fie ultima</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '\t# codul unde poate poate o eroare (exceptie);\n' +
                        '    # daca apare o eroare, atunci se arunca o exceptie; \n' +
                        'except Eroare_1:\n' +
                        '\t# codul gestionare eroare de tip Eroare_1\n' +
                        'except Eroare_2\n' +
                        '\t# codul gestionare eroare de tip Eroare_1\n' +
                        'except Eroare_N\n' +
                        '\t# codul gestionare eroare de tip Eroare_N\n' +
                        'except:\n' +
                        '    # codul gestionare eroare implicita/anonima'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Categorii de erori</b>
                    <br/>
                    Exista doua categorii de erori:
                    <ul>
                        <li>erori de sintaxa (erori de parsare): apar atunci când analizatorul dă peste o instructiune incorectă.</li>
                        <li>exceptii: erori detectate in timpul executiei codului (de exemplu ZeroDivisionError, cand incerca sa se imparta ceva la 0)</li>
                    </ul>
                    <hr/>
                    <b>4. Tipuri de erori</b>
                    <br/>
                    Tipuri de erori:
                    <ul>
                        <li><b>ZeroDivisionError</b>: apare cand se incerca efectuarea unei operatii si divizorul este zero (/, %,, //)</li>
                        <li><b>ValueError</b>: apare când o funcție (cum ar fi int() sau float()) primește un argument cu o valoarea nepotrivita</li>
                        <li><b>TypeError</b>: apare când se incerca aplicarea unor date al căror tip nu poate fi acceptat (ex: lista[0.5], indexul unei liste nu poate fi de tip float)</li>
                        <li><b>AttributeError</b>: apare cand se apeleaza o metodă care nu există</li>
                        <li><b>SyntaxError</b>: apare când se ajunge la o linie de cod care încalcă gramatica Python</li>
                        <li><b>KeyboardInterrupt</b>: apare cand când utilizatorul apasă tasta de întrerupere (CTRL-C or Delete)</li>
                        <li><b>IndexError</b>: apare cand se incerca sa se acceseze un element dintr-o lista pe baza unui index si indexul depaseste lungimea listei

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'list = []\n' +
                                'list[0] # IndexError: list index out of range'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>5. Ierarhie de exceptii</b>
                    <br/>

                    Python 3 definește 63 de excepții încorporate și toate formează o ierarhie arborescenta:
                    <ul>
                        <li>
                            <b>BaseException</b>
                            <br/>
                            <i>cea mai generală exceptie (toate celelalte excepții sunt incluse în aceasta);
                                se poate spune că următoarele două ramuri <b>except</b> sunt echivalente: <b>except:</b> și <b>except BaseException:</b>
                            </i>
                            <ul>
                                <li>
                                    <b>SystemExit</b>
                                </li>
                                <li><b>Exception</b>
                                    <ul>
                                        <li>
                                            <b>ValueError</b>
                                        </li>
                                        <li><b>LookupError</b>
                                            <br/>
                                            <i>excepție abstractă care include toate excepțiile cauzate de erori rezultate din <b>referințe nevalide</b> la diferite colecții (liste, dicționare, tupluri etc.)</i>
                                            <ul>
                                                <li><b>IndexError</b>
                                                    <br/>
                                                    <i>excepție aruncata când se incerca accesarea unui element inexistent al unei secvențe (de exemplu, un element al unei liste)</i>
                                                </li>
                                                <li>
                                                    <b>KeyError</b>
                                                    <br/>
                                                    <i>excepție aruncata când se incerca accesarea unui element inexistent al unei colecții (de exemplu, elementul unui dicționar)</i>
                                                </li>
                                            </ul>

                                        </li>
                                        <li><b>ArithmeticError</b>
                                            <br/>
                                            <i>exceptie abstractă care include toate excepțiile cauzate de operații aritmetice precum diviziunea la zero sau domeniul nevalid al unui argument</i>
                                            <ul>
                                                <li>
                                                    <b>ZeroDivisionError</b>
                                                    <br/>
                                                </li>
                                                <li>
                                                    <b>OverflowError</b>
                                                    <br/>
                                                    <i>oxcepție aruncata atunci când o operațiune produce un <b>număr prea mare</b> pentru a fi stocat cu succes</i>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <b>AssertionError</b>
                                            <br/>
                                            <i>excepție generată de instrucțiunea <b>assert</b> atunci când argumentul său este evaluat la <b>False, None, 0 sau un șir gol</b></i>
                                        </li>
                                        <li>
                                            <b>MemoryError</b>
                                            <br/>
                                            <i>o excepție aruncata atunci când o operațiune nu poate fi finalizată din cauza lipsei de memorie liberă</i>
                                        </li>

                                        <li>
                                            <b>StandardError</b>
                                            <ul>
                                                <li><b>ImportError</b>
                                                    <br/>
                                                    <i>excepție aruncata atunci când o operațiune de import eșuează (<b>import</b> xxx)</i>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </li>
                                <li>
                                    <b>KeyboardInterrupt</b>
                                    <br/>
                                    <i>o excepție aruncata atunci când utilizatorul folosește o comandă de la tastatură concepută pentru a opri execuția unui program (Ctrl-C în majoritatea sistemelor de operare);
                                        dacă gestionarea acestei excepții nu duce la terminarea programului, programul își continuă execuția.</i>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <b>Observatie</b>:
                    <br/>
                    Cand se arunca o exceptie, aceasta este prinsa <b>in prima</b> ramura <b>except</b> care se potriveste, iar aceasta ramura care se potrivește nu trebuie să specifice exact aceeași excepție - este <b>suficient ca excepția să fie mai generală</b> (mai abstractă) decât cea ridicată (adica e exeptie de tip <i>ZeroDivisionError</i> poate fi prinsa si de <i>ArithmeticError</i>).

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    y = 1 / 0\n' +
                        'except ZeroDivisionError:\n' +
                        '    print("Impartire Zero!") # Pe aici\n' +
                        'except ArithmeticError:\n' +
                        '    print("Problema aritmetica!")\n' +
                        '\n' +
                        'print("Gata.")\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    y = 1 / 0\n' +
                        'except ArithmeticError:\n' +
                        '    print("Problema aritmetica!") # pe aici\n' +
                        'except ZeroDivisionError:\n' +
                        '    print("Impartire Zero!") \n' +
                        '\n' +
                        'print("Gata.")'}
                    </SyntaxHighlighter>

                    Deci:
                    <ul>
                        <li>ordinea ramurilor conteaza!</li>
                        <li>exceptiile mai generale nu trebuie puse inaintea celor mai concrete; (acest lucru îl va face pe acesta din urmă inaccesibil și inutil;  în plus, va face codul dvs. dezordonat și inconsecvent; in plus, Python nu va genera niciun mesaj de eroare cu privire la această problemă)</li>
                    </ul>

                    <hr/>
                    <b>6. Mai multe exceptii gestionate la fel</b>
                    <br/>
                    Daca se vrea ca mai multe exceptii sa fie gestionate in acelasi mod se poate folosi urmatoare sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    :\n' +
                        'except (tip_exceptie_1, tip_exceptie_2, ... , tip_exceptie_N):\n' +
                        '    :'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Ramura else</b>
                    <br/>
                    Ramura <b>else</b> este executata <b>doar daca nu s-a aruncat nici o eroare</b> in blocul try-except:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    # cod unde poate poate o eroare (exceptie);\n' +
                        'except:\n' +
                        '    # cod gestionare eroare\n' +
                        'else:\n' +
                        '    # cod executat daca nu s-a aruncat vreo exceptie'}
                    </SyntaxHighlighter>
                    Aceasta ramura <b>trebuie pusa dupa ramurile except</b>.

                    <br/>
                    <br/>

                    Exemplu (va afisa 'fu' ok!"):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    pass\n' +
                        'except:\n' +
                        '    print ("eroare")\n' +
                        'else:\n' +
                        '    print("fu\' ok!")\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Ramura finally</b>
                    <br/>

                    Ramura <b>finally</b> (finally este cuvant cheie) trebuie sa fie <b>ultima ramura blocul try-except</b> (dupa si else:).
                    <br/>
                    Ramura <b>finally</b> este întotdeauna executata (chiar daca s-a generat o exepetie sau nu, indiferent dacă aceasta a fost gestionată sau nu)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    # cod unde poate poate o eroare (exceptie);\n' +
                        'except:\n' +
                        '    # cod gestionare eroare\n' +
                        'finally:\n' +
                        '    # cod executat intotdeauna'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def imparte(n):\n' +
                        '    try:\n' +
                        '        n = 1 / n\n' +
                        '    except ZeroDivisionError:\n' +
                        '        print("Impartire imposibila")\n' +
                        '        n = None\n' +
                        '    else:\n' +
                        '        print("Fu\' ok")\n' +
                        '    finally:\n' +
                        '        print("Gata")\n' +
                        '        return n\n' +
                        '\n' +
                        'print(imparte(5))\n' +
                        'print(imparte(0))'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Fu\' ok\n' +
                        'Gata\n' +
                        '0.2\n' +
                        'Impartire imposibila\n' +
                        'Gata\n' +
                        'None'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Comanda raise</b>
                    <br/>
                    Comanda <b>raise</b> <i>tip_exceptie</i> (<b>raise</b> este cuvant cheie) arunca o exceptie de tipul <i>tip_exceptie</i> (ca și cum ar fi fost aruncata într-un mod normal (natural)).
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'raise tip_exceptie'}
                    </SyntaxHighlighter>

                    In acest fel:
                    <ul>
                        <li>se poate simula aruncarea unei exceptie reala (de exemplu, pentru a vă testa strategia de gestionare a unei exceptii)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie(n):\n' +
                                '    raise ZeroDivisionError\n' +
                                '\n' +
                                'try:\n' +
                                '    functie(0)\n' +
                                'except ArithmeticError:\n' +
                                '    print("Eroare")'}
                            </SyntaxHighlighter>

                        </li>
                        <li>se poate trata partial o exceptie intr-un loc si in alta parte se poate continuarea tratarii exceptiei

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie():\n' +
                                '    try:\n' +
                                '        a = 1/0\n' +
                                '    except:\n' +
                                '        print("Nu vreau sa fac nimic aici")\n' +
                                '        raise ZeroDivisionError\n' +
                                '\n' +
                                'try:\n' +
                                '    functie()\n' +
                                'except ArithmeticError:\n' +
                                '    print("Eroare")\n'}
                            </SyntaxHighlighter>
                            Se va afisa:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'Nu vreau sa fac nimic aici\n' +
                                'Eroare'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Comanda <b>raise</b> se poate folosi si <i>tipul de exceptie</i>, dar trebuie folosit <b>doar</b> intr-o ramura <b>except</b> <i>fara tip de exceptie</i>.
                    In plus, <b>instrucțiunea va ridica din nou aceeași tip de excepție ca cea gestionată în prezent.</b>.

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie():\n' +
                        '    try:\n' +
                        '        return 1 / 0\n' +
                        '    except:\n' +
                        '        print("Nu, nu aici!")\n' +
                        '        raise\n' +
                        '\n' +
                        '\n' +
                        'try:\n' +
                        '    functie()\n' +
                        'except ArithmeticError:\n' +
                        '    print("Eroare")'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>10. Cuvantul cheie: assert</b>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'assert expresie'}
                    </SyntaxHighlighter>

                    Instructiunea <b>assert</b> functioneaza in felul urmator:
                    <ul>
                        <li>se evalueaza expresia</li>
                        <li>daca rezultatul expresiei este:
                            <ul>
                                <li>True</li>
                                <li>valoare numerica diferita de 0</li>
                                <li>sir nevid</li>
                                <li>orice alta valoare diferita de None</li>
                            </ul>
                            atunci <b>nu face nimic</b>
                            <br/>
                            altfel atunca o exceptie de tipul <b>AssertionError</b>
                        </li>
                    </ul>

                    Aceasta instructiune se poate folosi pentru a proteja unele părți critice ale codului de date invalide.

                    <hr/>
                    <b>11. Debugger</b>
                    <br/>

                    Un <b>debugger</b> este un program specializat care poate controla modul în care este executat un programul.
                    Folosind <b>debugger-ul</b> se poate:
                    <ul>
                        <li>executa codul linie cu linie</li>
                        <li>inspecta stările variabilelor</li>
                        <li>modifica valorile variabilelor (fara a modifica codul sursa)</li>
                        <li>opri execuția programului când anumite condiții sunt sau nu îndeplinite</li>
                        <li>etc</li>
                    </ul>

                    <hr/>
                    <b>12. Functia type()</b>
                    <br/>
                    Verificare daca o anumita valoare este de un anumit tip:
                    <ul>
                        <li>type(value) is <i>type</i>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'print(type(7) is int) # va afisa True'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExceptionsPythonContent;