import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class ModelTypeScriptJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-interfaces-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: Interfete TypeScript pentru entitati</b>
                    <br/>
                    <br/>

                    In directorul <b>src/main/webapp</b>, apoi <b>app/entities</b>

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>product/product.model.ts</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import { IProductCategory } from \'app/entities/product-category/product-category.model\';\n' +
                            'import { Size } from \'app/entities/enumerations/size.model\';\n' +
                            '\n' +
                            'export interface IProduct {\n' +
                            '  id: number;\n' +
                            '  name?: string | null;\n' +
                            '  description?: string | null;\n' +
                            '  price?: number | null;\n' +
                            '  sizeProduct?: Size | null;\n' +
                            '  image?: string | null;\n' +
                            '  imageContentType?: string | null;\n' +
                            '  productCategory?: Pick<IProductCategory, \'id\'> | null;\n' +
                            '}\n' +
                            '\n' +
                            'export type NewProduct = Omit<IProduct, \'id\'> & { id: null };\n'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'Pick<Type, Keys>'}
                            </SyntaxHighlighter>

                            Folosind <b>Pick</b>, putem spune <b>TypeScript</b> doar să aleagă acelea Keys dintre cele existente Type(care ar putea fi <b>interface</b> sau <b>type</b>).
                            <br/>

                            Acest lucru ne permite să construim un nou tip din utilizarea <b>Pick</b>, dar să folosim numai proprietățile relevante (ignorând celelalte):

                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'@Injectable({\n' +
                                    '  providedIn: \'root\',\n' +
                                    '})\n' +
                                    'export class AuthService {\n' +
                                    '  constructor() {}\n' +
                                    '\n' +
                                    '  async create({ name, email, password }: any) {...}\n' +
                                    '\n' +
                                    '  async login({ email, password }: any) {...}\n' +
                                    '\n' +
                                    '  async resetPassword({ email }: any) {...}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'export interface Credentials {\n' +
                                    '  name: string;\n' +
                                    '  email: string;\n' +
                                    '  password: string;\n' +
                                    '}\n' +
                                    '\n' +
                                    '@Injectable({\n' +
                                    '  providedIn: \'root\',\n' +
                                    '})\n' +
                                    'export class AuthService {\n' +
                                    '  constructor() {}\n' +
                                    '\n' +
                                    '  async create({ name, email, password }: Credentials) {...}\n' +
                                    '\n' +
                                    '  async login({ email, password }: Pick<Credentials, \'email\' | \'password\'>) {...}\n' +
                                    '\n' +
                                    '  async resetPassword({ email }: Pick<Credentials, \'email\'>) {...}\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            Într-un tip mapat, cum ar fi Pick, această |sintaxă este mai mult „și” în loc de „sau”.

                            <br/>
                            Alt exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'interface Todo {\n' +
                                    '  title: string;\n' +
                                    '  description: string;\n' +
                                    '  completed: boolean;\n' +
                                    '}\n' +
                                    ' \n' +
                                    'type TodoPreview = Pick<Todo, "title" | "completed">;\n' +
                                    ' \n' +
                                    'const todo: TodoPreview = {\n' +
                                    '  title: "Clean room",\n' +
                                    '  completed: false,\n' +
                                    '};\n' +
                                    ' \n' +
                                    'todo;'}
                            </SyntaxHighlighter>

                            Mai multe detalii:
                            <a href={"https://ultimatecourses.com/blog/using-typescript-pick-mapped-type"}>
                                Using 'Pick' in TypeScript to simplify Types and Interfaces
                            </a>
                        </li>
                    </ul>


                    De exemplu, continutul fisierului <i>/product-category/product-category.model.ts</i>,
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'export interface IProductCategory {\n' +
                            '  id: number;\n' +
                            '  name?: string | null;\n' +
                            '  description?: string | null;\n' +
                            '}\n' +
                            '\n' +
                            'export type NewProductCategory = Omit<IProductCategory, \'id\'> & { id: null };\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ModelTypeScriptJdlJHipsterContent;