import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DataTypesJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-data-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Tipuri de date:
                    <ul>
                        <li>primive sau simple (numere, siruri de caractere)</li>
                        <li>complexe sau compuse (liste, matricii, obiecte)</li>
                    </ul>

                    Literalii sunt o modalitate de a definii valori (date) în codul programului (exemplu: "masina" - liberal care reprezinta un sir de caractere, 1.41 - literal care reprezinta un numar)

                    <hr/>
                    <b>1. Operatorul typeof</b>
                    <br/>
                    <br/>

                    Operatorul <b>typeof</b> este un operator unar care returneaza un sir de caractere care reprezinta tipul de date al argumentului dat. Argumentul poate fi un literal sau o variabila.
                    <br/>
                    Valorile posibile care pot fi returnate:
                    <ul>
                        <li>
                            undefined
                        </li>
                        <li>
                            object
                        </li>
                        <li>
                            boolean
                        </li>
                        <li>
                            number
                        </li>
                        <li>
                            bigint
                        </li>
                        <li>
                            string
                        </li>
                        <li>
                            symbol
                        </li>
                        <li>
                            function
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'console.log(typeof "A"); // string\n' +
                        'console.log(typeof 4); // number\n' +
                        'console.log(typeof false); // boolean\n'+
                        'console.log(typeof x); // undefined\n'+
                        'console.log(typeof {}); // object\n' +
                        'let a = 7.5;\n' +
                        'console.log(typeof a); //number'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Tipuri de date primitive</b>
                    <br/>
                    <br/>

                    Tipuri de date primitive (sunt tipuri de date ale căror valori sunt atomice, adica valorile sunt indivizibile):
                    <ul>
                        <li><b>Boolean</b>: tip de date logic, putând să ia una din valorile: <b>true/false</b>
                        <br/>
                            un nume de variabile de tip Boolean este recomandat sa incepa cu <i>is</i>
                        </li>
                        <li><b>Number</b>: tip numeric reprezentand numere reale (zecimale, fractii) si numere intregi;
                            <br/>
                            valorile întregi ar trebui limitate la intervalul de la -(253 – 1) la (253 – 1).
                            <br/>
                            De obicei, numele sunt prezentate in forma zecimala, dar daca este precedat de un prefix, il putem prezenta in forma:
                            <ul>
                                <li>hexazecimala, cu prefix <b>0x</b></li>
                                <li>octala, cu prefix <b>0o</b></li>
                                <li>binara, cu prefix <b>0b</b></li>
                            </ul>
                            Un numar poate fi scris si in forma exponențială.
                            <ul>
                                <li>in loc de 5000, putem scrie 5e3</li>
                                <li>in loc de 0,0724, putem scrie 724e-4</li>
                            </ul>
                            JavaScript mai folosete 3 valori speciale:
                            <ul>
                                <li><b>Infinity</b></li>
                                <li><b>-Infinity</b></li>
                                <li><b>NaN</b>: reprezinta o notificare că o acțiune aritmetică (sau funcție matematică) nu a putut fi efectuată deoarece argumentul fie nu este un număr, fie nu poate fi convertit într-un număr</li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let a = 10; // decimal - implicit \n' +
                                'let b = 0x10; // hexadecimal \n' +
                                'let c = 0o10; // octal \n' +
                                'let d = 0b10; // binary \n' +
                                ' \n' +
                                'console.log(a); // -> 10 \n' +
                                'console.log(b); // -> 16 \n' +
                                'console.log(c); // -> 8 \n' +
                                'console.log(d); // -> 2 \n' +
                                '\n' +
                                'let x = 5e3;\n' +
                                'let y = 723e-4;\n' +
                                'console.log(x); // 5000\n' +
                                'console.log(y); // 0.0723'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let a = 1 / 0;\n' +
                                'let b = -Infinity;\n' +
                                '\n' +
                                'console.log(a); // -> Infinity\n' +
                                'console.log(b); // -> -Infinity\n' +
                                'console.log(typeof a); // -> number\n' +
                                'console.log(typeof b); // -> number\n' +
                                '\n' +
                                'let s = "string";\n' +
                                'let n = s * 5;\n' +
                                'console.log(n); // -> NaN\n' +
                                'console.log(typeof n); // -> number'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>BigInt</b>: permite folosirea de numere întregi de aproape orice lungime (gestionarea de numere întregi mult mai mari)

                            <br/>
                            cu BigInt se pot folosi operații matematice în același mod ca pe Numbers, dar există o diferență la <i>împărțire</i>.
                            (deoarece BigInt este un tip întreg, rezultatul impartirii va fi rotunjit în jos la cel mai apropiat număr întreg)
                            <br/>
                            Literale BigInt sunt numere cu sufixul <b>n</b>.
                            <br/>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let big1 = 1234567890000000000000n;\n' +
                                'let big2 = 1n;\n' +
                                '\n' +
                                'console.log(big1); // 1234567890000000000000n\n' +
                                'console.log(typeof big1); // bigint\n' +
                                '\n' +
                                'console.log(big2);  // 1\n' +
                                'console.log(5n / 3n);  // 1\n'}
                            </SyntaxHighlighter>

                            Restrictii:
                            <ul>
                                <li>nu se pot mixa operatii pe tipul BigInt si Number (ex: 64n+2), va rezulta o eroare: <i>Uncaught TypeError: Cannot mix BigInt and other types, use explicit conversions</i></li>
                                <li>BigInt nu are valori echivalente pentru Infinity sau NaN.
                                    <br/>
                                    (daca pentru cazul tipului Number, împărțirea la 0 rezulta Infinity sau încercarea de a efectua o acțiune aritmetică asupra unei valori care nu este un număr rezulta NaN,
                                    în cazul tipului BigInt, astfel de acțiuni vor genera o eroare; de exemplu: 4n/0n va arunca exceptia <i>Uncaught RangeError: Division by zero</i>)</li>
                            </ul>
                        </li>
                        <li><b>String</b>: secvență de caractere
                            <br/>
                            <ul>
                                <li>este un tip primitiv <i>imutabil</i>, insemnand ca atunci când vrem să schimbăm șirul, în realitate, creăm un șir nou</li>
                                <li>se pot folosi ghilimele simple sau ghilimele duble</li>
                                <li>dacă se folosesc ghilimele duble pentru a marca un șir, se pot plasa ghilimele simple în interiorul șirului și vor fi tratate ca si caractere obișnuite</li>
                                <li>dacă se folosesc ghilimele simple pentru a marca un șir, se pot plasa ghilimele duble în interiorul șirului și vor fi tratate ca si caractere obișnuite</li>
                                <li>dacă se doreste folosirea ghilimelelor simple sau duble în interiorul șirului, se poate utiliza caracterul de escape – backslash (ex: "Capitala \"Roma\" este in Italia" / C:\\Windows)</li>
                            </ul>
                            <b>Observatie</b>
                            <br/>
                            Cand intre striguri se folosesc operatorii -, *, / se incerca in mod automat conversia string-urilor intr-un numar; daca conversia nu se reuseste se obtine NaN.
                            <br/>
                            <b>Operatii:</b>
                            <ul>
                                <li>concatenarea (+)</li>
                            </ul>

                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let x = "40" - \'5\'; // conversie automata\n' +
                                'let y = "40" + \'5\'; // concatenare\n' +
                                'let z = "40" - \'5a\'; // concatenare\n' +
                                'console.log(x); // 35\n' +
                                'console.log(y); // 405\n' +
                                'console.log(z); // NaN'}
                            </SyntaxHighlighter>

                            <b>Interpolarea șirurilor</b> (introdus in 2015) permite folosirea unui  șir de caractere ca un șablon, în care puteți plasa valori în locurile selectate, cum ar fi cele luate din variabile.
                            Un astfel de literal este creat folosind '`' (sau accente grave) în loc de ghilimele.
                            Locurile în care sunt introduse valorile sunt marcate cu paranteze precedate de semnul $.

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let tara = "Romania";\n' +
                                'let t = ` ${tara} este o tara`;\n' +
                                'console.log(t) // Romania este o tara'}
                            </SyntaxHighlighter>

                            <b>Autoboxing</b>: conversie automata de la o primitiva la un obiect corespondent; se intampla in momentul in care se apeleaza o metoda de pe obiectul corespondent.

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let oras = "Bucuresti";\n' +
                                'let l = oras.length; // conversie automata; datorita faptului ca se apleaza "length" \n' +
                                'console.log(l); // 9'}
                            </SyntaxHighlighter>

                            Interpretorul convertește temporar valoarea 'oras' într-un obiect; acest obiect are proprietatea 'length'; se efectueaza operatia; si la finalizarea operatiei, interpretorul sterge obiectul temporar.

                            <br/>
                            <br/>
                            Metode si proprietati:
                            <ul>
                                <li><b>length</b>: proprietate care returnează numărul de caractere dintr-un șir;</li>
                                <li><b>charAt(index)</b>: metoda care returnează caracterul la poziția „index” din șir (indexurile încep de la 0);</li>
                                <li><b>slice(beginIndex, [optional] endIndex)</b>:
                                    metoda care returnează un șir nou care este creat din caracterele dintre beginIndex (inclus) și endIndex (exclus);
                                    <br/>
                                    dacă endIndex este omis, atunci noul șir este de la beginIndex până la sfârșitul șirului;
                                </li>
                                <li><b>split(separator, [optional] limit)</b>:

                                    metoda care împarte șirul în subșiruri ori de câte ori se găsește un separator în acel șir și returnează o matrice a acelor subșiruri;
                                    <br/>
                                    limit (opțional) - limitează numărul de subșiruri adăugate în listă

                                </li>
                            </ul>

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'let lng = "java python javascript"\n' +
                                'console.log(lng.length); // 22\n' +
                                'console.log(lng.charAt(2)); // v\n' +
                                'console.log(lng.slice(2,4)); // va\n' +
                                'console.log(lng.split(" ")); // java,python,javascript'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>Symbol</b>: a fost adaugat in 2005;
                            nu are nicio valoare literală și poate fi creat doar folosind o funcție specială de constructor;
                            <br/>
                            simbolurile sunt o formă de identificare care este garantată a fi unică.
                        </li>
                        <li><b>undefined</b>: poate avea o singura valoare: <b>undefined</b>; reprezinta valoarea implicita a unei variabile dupa declarare, daca nu este initializata
                        <br/>
                            se poate atribui valoare <i>undefined</i> oricarei variabile, dar acest lucru trebuie evitat;
                            <br/>
                            pentru a marca ca o variabila nu are nici o valoare ar trebui folosita valoarea <b>null</b>.
                        </li>
                    </ul>
                    Valoarea <b>null</b>:
                    <ul>
                        <li>este o primitivă tratată ca un tip separat; tipul caruia ii apartine este asociata cu tipurile complexe, cum ar fi obiectele</li>
                        <li>este folosita pentru a indica o variabila nu contine nimic</li>
                        <li>typeof null va retuna object

                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'console.log(typeof null); // object'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>Extra</b>
                    <br/>
                    <br/>
                    Obiectul <i>console</i> are multe alte metode în afară de metoda <i>log()</i> cum ar fi <i>time()</i> și <i>timeEnd</i> (care pot fi folosite pentru a măsura timpul).
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DataTypesJavaScriptContent;