import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ConfigFilek8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-config-file", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Fisiere de configurare</b>
                    <br/>
                    <br/>

                    Fisiere de configurare:
                    <ul>
                        <li>
                            sunt scrise in <b>YAML</b>
                        </li>
                        <li>
                            sunt folosite pentru a crea obiecte (de tip: Pod, Service, Deployment, StatefulSet, ReplicaController, etc)
                        </li>
                        <li>
                            obiectele se folosesc pentru diverse scopuri:
                            <ul>
                                <li>
                                    rularea unui container (se foloseste un obiect de tip Pod)
                                </li>
                                <li>
                                    monitorizarea unui container
                                </li>
                                <li>
                                    setare retea (se foloseste un obiect de tip Service)
                                </li>
                                <li>
                                    etc
                                </li>
                            </ul>
                        </li>
                        <li>
                            se specifica tipul de obiect: <b>kind</b>: Pod
                        </li>
                        <li>
                            se specifica versiunea: <b>apiVersion</b>: v1
                            <br/>
                            fiecare versiune de API defineste un set diferit de obiect care pot fi folosite;
                            <br/>
                            de exemplu:
                            <ul>
                                <li>
                                    apiVersion: v1 {"=>"} se pot folosi urmatoarele obiecte:
                                    <ul>
                                        <li>Pod</li>
                                        <li>Event</li>
                                        <li>Namespace</li>
                                        <li>EndPoints</li>
                                        <li>configMap</li>
                                        <li>componentStatus</li>
                                    </ul>
                                </li>
                                <li>
                                    apiVersion: apps/v1 {"=>"} se pot folosi urmatoarele obiecte:
                                    <ul>
                                        <li>controllerRevision</li>
                                        <li>StatefulSet</li>
                                    </ul>
                                </li>
                            </ul>
                            deci, pe baza <b>kind</b> se decide ce versiune de API se foloseste.
                        </li>
                    </ul>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: client-pod\n' +
                            '  labels:\n' +
                            '    component: web\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '    - name: client\n' +
                            '      image: iulianbuzdugan/multi-client\n' +
                            '      ports:\n' +
                            '        - containerPort: 3000'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: client-node-port\n' +
                            'spec:\n' +
                            '  type: NodePort\n' +
                            '  port:\n' +
                            '    - ports: 3050\n' +
                            '      targetPort: 3000\n' +
                            '      nodePort: 31515\n' +
                            '  selector:\n' +
                            '    component: web'}
                    </SyntaxHighlighter>

                    Cand se porneste <b>minicube</b> se creeaza o <b>masina virtuala</b> pe calculator.<br/>
                    Ne vom referi la aceasta masina virtuala care ruleaza pe calculator ca fiind un <b>node</b>.<br/>
                    Acest nod, este folosit de <b>K8s</b>, pentru a rula diverse <b>obiecte</b>.<br/>
                    Un exemplu de obiect este un <b>Pod</b>, folosit pentru <i>rularea unui container</i>.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube start'}
                    </SyntaxHighlighter>
                    Un pod este un <b>grup de  containere</b> (poate fi si un singur container, de exemplu: nginx) care au acelasi scop.

                    <br/>
                    Diferenta dintre un multi-container si un pod este ca intr-un multi-container, daca un container crapa, celelalte continua sa functioneze.
                    <br/>
                    Intr-un pod legaturile dintre continere, sunt foarte stranse.

                    <hr/>
                    <b>1.1. kind: Pod</b>
                    <br/>
                    <br/>
                    Ruleaza unul sau mai multe containere strans legate intre ele.
                    <br/>
                    <br/>
                    In exemplul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'metadata:\n' +
                            '  name: client-pod\n' +
                            '  labels:\n' +
                            '    component: web\n'}
                    </SyntaxHighlighter>
                    In sectiunea <b>metadata</b>:
                    <ul>
                        <li>
                            <b>name</b>: specifica numele pod-ului
                        </li>
                        <li>
                            <b>labels</b>: etichete strans legate de alte fisiere de configurare;
                            <br/>
                            sunt de tipul <i>cheie</i>:<i>valoare</i> (de exemplu: <i>component: web</i>)
                            <br/>
                            cheile si valorile sunt alese arbitrar, cum se doreste (poate fi de exemplu, <i>tier: frontend</i>)
                            <br/>
                            <br/>
                            referirea la face prin <b>Label Selector System</b>, atributul <b>selector</b> (exemplu din obiectul de tip Service):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'  selector:\n' +
                                    '    component: web'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    In exemplul de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'spec:\n' +
                            '  containers:\n' +
                            '    - name: client\n' +
                            '      image: iulianbuzdugan/multi-client\n' +
                            '      ports:\n' +
                            '        - containerPort: 3000'}
                    </SyntaxHighlighter>
                    avem un singur container:
                    <ul>
                        <li>
                            cu numele <i>client</i> (specificat prin atributul <b>name</b>)
                            <br/>
                            (numele specificat prin atributul <b>name</b> (<i>client</i>) poate fi referit intre containere dintr-un pod)
                        </li>
                        <li>
                            creat pe baza imaginii <i>iulianbuzdugan/multi-client</i> (specificat prin atributul <b>image</b>)
                        </li>
                        <li>
                            care este expus in exterior prin portul <i>3000</i> (specificat prin atributul <b>containerPort</b> din <i>ports</i>)
                            <br/>
                            (dar nu si in interior-ul pod-ului - acest lucru se configureaza prin intermediul unui alt fisier/alt obiect de tip <i>Service</i>).
                        </li>
                    </ul>

                    <hr/>
                    <b>1.2. kind: Service</b>
                    <br/>
                    <br/>
                    Seteaza reteaua in K8s cluster
                    <br/>
                    <br/>
                    Subtipuri (definit prin atributul <b>type</b> din <b>spec</b>):
                    <ul>
                        <li><b>ClusterIP</b> ( expune o multime de pod-uri catre alte obiecte din <b>cluster</b>) </li>
                        <li><b>NodePort</b> (expune un container in afara - doar pentru dezvoltare, nu si pentru productie)</li>
                        <li><b>LoadBalancer</b></li>
                        <li><b>Ingress</b></li>
                    </ul>
                    Prin intermediul sectiunii <b>ports</b> se specifica:

                    <ul>
                        <li><b>port</b>: portul prin care poate fi accesat podul in multi-client pod; portul din care alt pod poate vedea pod-ul selectat (ex: 3050)</li>
                        <li><b>targetPorts</b>:
                            tot ce vine din lumea de afara sa fie redirectat pe acest port
                            <br/>
                            este identic cu portul din obiectul Pod expus prin intermediul atributului <i>containerPort</i> (ex: 3000)
                        </li>
                        <li><b>nodePort</b>: portul expus lumii exerioare; poate avea valori intre 30000 si 32767
                            <br/>
                            portul prin intermediul caruia poate fi accesa pod-ul dintr-un browser (lumea exterioara);
                            <br/>
                            daca nu se specfica un port; se va genera in mod aleator unul cu o valoare cuprinsa intre 30000 si 32767
                        </li>
                    </ul>

                    Configurarea de mai sus se refera la alt obiect.
                    <br/>
                    Referirea la alt obiect se face prin <b>Label Selector System</b> atributul <b>selector</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'  selector:\n' +
                            '    component: web'}
                    </SyntaxHighlighter>
                    Adica se refera la orice obiect care are perechea <i>cheie:valoare</i> {"=>"} <i>component: web</i>
                    <br/>
                    Deci, serviciul <i>NodePort</i> foloseste <b>selector</b> pentru ca gasi fiecare obiect cu un label de tipul <i>cheie:valoare</i> si expune portul 3000 lumii exterioare.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Service\n' +
                            'metadata:\n' +
                            '  name: client-node-port\n' +
                            'spec:\n' +
                            '  type: NodePort\n' +
                            '  ports:\n' +
                            '    - port: 3050 # portul utilizat pentru apelarea aplicatiei din lumea de afara containerului \n' +
                            '      targetPort: 3000 # portul din interiorul containerului, pe care asculta aplicatia\n' +
                            '      nodePort: 31515\n' +
                            '      # protocol: \'TCP\' # TCP este in mod implicit, si se poate omite\n' +
                            '  selector:\n' +
                            '    component: web'}
                    </SyntaxHighlighter>

                    Existenta unui obiect de tip <b>Service</b> este justificata de faptul ca IP-ul unui pod se poate modifica in mod dinamic.
                    <br/>
                    Si obiectul de tip <b>Service</b> rezolva aceasta problema legand <b>nodePort</b> de portul generat in mod dinamic.
                    <br/>
                    Pentru a afisa IP-ul unui pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -o wide'}
                    </SyntaxHighlighter>


                    <b>Observatie</b>
                    <br/>
                    Cum o sa se vada in sectiunea <b>1.3. kind: Deployment</b>, pentru selectarea obiectelor cu care sa se conecteze obiectul definit,
                    se foloseste <b>selector/matchLabels</b>, pentru ca obiectul de tip Deployment este mai nou!
                    <br/>
                    In cazul obiectelor de tip Service selectia se face folosind doar <b>selector</b> (istoric, este mai vechi aparut in k8s!).
                    <br/>
                    In plus, poate sa selecteze si daca se potriveste doar cu una din chei! (in caz ca o resursa are mai multe chei definite la labels)

                    <hr/>
                    <b>1.3. kind: Deployment</b>
                    <br/>
                    <br/>

                    In momentul in care se modifica in cadrul unui fisier de configurare care defineste un obiect de tip <i>Pod</i> atributul <i>image</i>,
                    si apoi se executa <i>kubectl apply</i>, in mod automat se va relecta schimbarea.
                    <br/>
                    Dar, daca schimbam, se exemplu, atributul <i>containerPort</i>, atunci se va obtine eroare, pentru ca nu toate atributele pot fi schimbate folosind acest tip de obiect.
                    <br/>
                    In schimb, se poate folosi un alt tip de obiect: <b>Deployment</b>.
                    <br/>
                    Diferente intre <b>Pod</b> si <b>Deployment</b>:
                    <ul>
                        <li>
                            pod: ruleaza un singur set de containere
                            <br/>
                            deployment: ruleaza un set identic de pod-uri, pe baza unui template
                        </li>
                        <li>
                            pod: folosit in dev (cand nu se fac schimbari), rar folosit in prod
                            <br/>
                            deployment: folosit pentru dev si prod
                        </li>
                        <li>
                            -
                            <br/>
                            deployment: monitorizeaza starea fiecarui pod, actualizand-ul daca e necesar
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: client-deployment\n' +
                            'spec:\n' +
                            '  replicas: 1\n' +
                            '  # selecteaza obiectele pe baza potrivirii cu labels (conditia de potrivire e cu AND)\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      component: web\n' +
                            '      # tier: backend\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        # cheie: valoare (pot fi mai multe); se folosesc in selector\\matchLabels (vezi mai sus) \n' +
                            '        component: web\n' +
                            '        # tier: backend\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: client\n' +
                            '          image: iulianbuzdugan/multi-client\n' +
                            '          ports:\n' +
                            '            - containerPort: 3000\n'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>
                    <br/>
                    Un <b>selector</b> identifica/seleteaza resurse (exemplu: pod) care vor fi controlate sau conectate cu obiectul definit (in cazul de mai sus, un obiect de tipul Deployment).
                    <br/>
                    In exemplul, de mai sus, se selecteaza resursele/pod-utile care au eticheta/label: <i>component: web</i>.

                    <hr/>
                    <b>1.4. kind: Secrets</b>
                    <br/>
                    <br/>

                    Scopul acestui tip de obiect este de stoca in mod securizat informatii intr-un cluster (de exemplu: parola de conectare la o baza de date, SSH key, API key, etc).
                    <br/>
                    Aceste chei se vor genera, in mod imperativ (nu se vor scrie in fisiere de configurare):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic <secret-name> --from-literal key=value'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            create {"=>"} comanda imperativa de a creea un obiect
                        </li>
                        <li>
                            secret {"=>"} tipul de obiect ce se va creea
                        </li>
                        <li>
                            <b>generic</b>{"=>"} tipul de secret (alte tipuri: <b>docker-registry</b>+<b>tls</b>)
                        </li>
                        <li>
                            secret-name {"=>"} numele secret-ului, care va fi referit apoi in fisierul de configurare pod
                        </li>
                        <li>
                            -- from-literal {"=>"} pentru a adauga informatii legate de sectet in comanda direct, cu cheie=valoare (in opozitie cu citirea dintr-un fisier <i>from</i> .)
                        </li>
                        <li>
                            key = value {"=>"} pereche chei valoare pentru informatiile legate de secret
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic pgpassword --from-literal PGPASWORD=1234password'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'secret/pgpassword created'}
                    </SyntaxHighlighter>

                    Pentru a afisa secretele:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME         TYPE     DATA   AGE\n' +
                            'pgpassword   Opaque   1      30s'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    Pe mediul de productie trebuie rulata din nou comanda de mai sus!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ConfigFilek8sContent;