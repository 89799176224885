import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../cv/IndexContent";

import {saveAs} from 'file-saver';
import Auth from "../../security/Auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons/faEyeSlash";


class CertificatesCVContent extends BaseContentPage {

    auth = new Auth();

    constructor(props) {
        super(props, "certificates-cv", IndexContent);
    }

    render() {

        let isAuth = this.auth.isAuthenticated();

        const downloadImage1 = () => {
            saveAs('/img/certificari/UC-27d5ab8e-0ca1-43c6-94d4-db0df39ae14d.jpg', 'UC-27d5ab8e-0ca1-43c6-94d4-db0df39ae14d.jpg')
        }

        const downloadPDF1 = () => {
            saveAs('/img/certificari/UC-27d5ab8e-0ca1-43c6-94d4-db0df39ae14d.pdf', 'UC-27d5ab8e-0ca1-43c6-94d4-db0df39ae14d.pdf')
        }

        const downloadImage2 = () => {
            saveAs('/img/certificari/UC-6b6e939b-624c-4146-86df-fe670f74f10f.jpg', 'UC-6b6e939b-624c-4146-86df-fe670f74f10f.jpg')
        }

        const downloadPDF2 = () => {
            saveAs('/img/certificari/UC-6b6e939b-624c-4146-86df-fe670f74f10f.pdf', 'UC-6b6e939b-624c-4146-86df-fe670f74f10f.pdf')
        }

        const downloadImage3 = () => {
            saveAs('/img/certificari/UC-fa665951-13d8-4fc2-8c8c-0797919b3a03.jpg', 'UC-fa665951-13d8-4fc2-8c8c-0797919b3a03.jpg')
        }

        const downloadPDF3 = () => {
            saveAs('/img/certificari/UC-fa665951-13d8-4fc2-8c8c-0797919b3a03.pdf', 'UC-fa665951-13d8-4fc2-8c8c-0797919b3a03.pdf')
        }

        const downloadImage4 = () => {
            saveAs('/img/certificari/UC-9afa1778-b9c8-4ee8-8fa2-0c3d0f07ab82.jpg', 'UC-9afa1778-b9c8-4ee8-8fa2-0c3d0f07ab82.jpg')
        }

        const downloadPDF4 = () => {
            saveAs('/img/certificari/UC-9afa1778-b9c8-4ee8-8fa2-0c3d0f07ab82.pdf', 'UC-9afa1778-b9c8-4ee8-8fa2-0c3d0f07ab82.pdf')
        }

        const downloadImage5 = () => {
            saveAs('/img/certificari/UC-f6c98d51-e974-4a11-b8f9-dc9062bf4a23.jpg', 'UC-f6c98d51-e974-4a11-b8f9-dc9062bf4a23.jpg')
        }

        const downloadPDF5 = () => {
            saveAs('/img/certificari/UC-f6c98d51-e974-4a11-b8f9-dc9062bf4a23.pdf', 'UC-f6c98d51-e974-4a11-b8f9-dc9062bf4a23.pdf')
        }

        const downloadImage6 = () => {
            saveAs('/img/certificari/UC-cae04f67-624c-4fd8-926a-71c2e9abcba1.jpg', 'UC-cae04f67-624c-4fd8-926a-71c2e9abcba1.jpg')
        }

        const downloadPDF6 = () => {
            saveAs('/img/certificari/UC-cae04f67-624c-4fd8-926a-71c2e9abcba1.pdf', 'UC-cae04f67-624c-4fd8-926a-71c2e9abcba1.pdf')
        }

        const downloadImage7 = () => {
            saveAs('/img/certificari/UC-5a6fc11e-6b14-4439-b147-c69e3b3b2cda.jpg', 'UC-5a6fc11e-6b14-4439-b147-c69e3b3b2cda.jpg')
        }

        const downloadPDF7 = () => {
            saveAs('/img/certificari/UC-5a6fc11e-6b14-4439-b147-c69e3b3b2cda.pdf', 'UC-5a6fc11e-6b14-4439-b147-c69e3b3b2cda.pdf')
        }

        const downloadImage8 = () => {
            saveAs('/img/certificari/UC-527d1ec5-c3c4-4752-b94d-3ae9edf623fd.jpg', 'UC-527d1ec5-c3c4-4752-b94d-3ae9edf623fd.jpg')
        }

        const downloadPDF8 = () => {
            saveAs('/img/certificari/UC-527d1ec5-c3c4-4752-b94d-3ae9edf623fd.pdf', 'UC-527d1ec5-c3c4-4752-b94d-3ae9edf623fd.pdf')
        }

        const downloadImage9 = () => {
            saveAs('/img/certificari/UC-85750f37-23d0-4ff7-b3c6-e6e6a9a5c3d0.jpg', 'UC-85750f37-23d0-4ff7-b3c6-e6e6a9a5c3d0.jpg')
        }

        const downloadPDF9 = () => {
            saveAs('/img/certificari/UC-85750f37-23d0-4ff7-b3c6-e6e6a9a5c3d0.pdf', 'UC-85750f37-23d0-4ff7-b3c6-e6e6a9a5c3d0.pdf')
        }

        const downloadImage10 = () => {
            saveAs('/img/certificari/UC-b4bed149-a9ee-433b-b172-7a5919eb75a6.jpg', 'UC-b4bed149-a9ee-433b-b172-7a5919eb75a6.jpg')
        }

        const downloadPDF10 = () => {
            saveAs('/img/certificari/UC-b4bed149-a9ee-433b-b172-7a5919eb75a6.pdf', 'UC-b4bed149-a9ee-433b-b172-7a5919eb75a6.pdf')
        }

        const downloadImage11 = () => {
            saveAs('/img/certificari/UC-1717277c-e2ae-492b-be3a-31a527bc32a2.jpg', 'UC-1717277c-e2ae-492b-be3a-31a527bc32a2.jpg')
        }

        const downloadPDF11 = () => {
            saveAs('/img/certificari/UC-1717277c-e2ae-492b-be3a-31a527bc32a2.pdf', 'UC-1717277c-e2ae-492b-be3a-31a527bc32a2.pdf')
        }

        const downloadImage12 = () => {
            saveAs('/img/certificari/UC-44a75ac2-5afb-48dd-ad2c-f1f7c00dc5be.jpg', 'UC-44a75ac2-5afb-48dd-ad2c-f1f7c00dc5be.jpg')
        }

        const downloadPDF12 = () => {
            saveAs('/img/certificari/UC-44a75ac2-5afb-48dd-ad2c-f1f7c00dc5be.pdf', 'UC-44a75ac2-5afb-48dd-ad2c-f1f7c00dc5be.pdf')
        }

        const downloadPDF13 = () => {
            saveAs('/img/certificari/certificate_2wKA.dt4W.fW3u.pdf', 'certificate_2wKA.dt4W.fW3u.pdf')
        }

        const downloadPDF14 = () => {
            saveAs('/img/certificari/LiferayDXP7.3Back-EndDeveloperExam.pdf', 'LiferayDXP7.3Back-EndDeveloperExam.pdf')
        }

        const downloadPDF15 = () => {
            saveAs('/img/certificari/certificate_48Bz.CRXb.Sd2H.pdf', 'certificate_48Bz.CRXb.Sd2H.pdf')
        }

        const downloadPDF16 = () => {
            saveAs('/img/certificari/certificate_qxRR.OtCh.8Qjh.pdf', 'certificate_qxRR.OtCh.8Qjh.pdf')
        }

        const downloadPDF17 = () => {
            saveAs('/img/certificari/1Z0-805-Updade-to-Java-SE-7-Programmer-Certificate.pdf', '1Z0-805-Updade-to-Java-SE-7-Programmer-Certificate.pdf')
        }

        const downloadPDF18 = () => {
            saveAs('/img/certificari/1Z0-850-Java-SE-5-and-6-Certified-Associate-Certificate.pdf', '1Z0-850-Java-SE-5-and-6-Certified-Associate-Certificate.pdf')
        }

        const downloadPDF19 = () => {
            saveAs('/img/certificari/1Z0-851-Java-Standard-Edition-6-Programmer-Certificate.pdf', '1Z0-851-Java-Standard-Edition-6-Programmer-Certificate.pdf')
        }

        const downloadPDF20 = () => {
            saveAs('/img/certificari/1Z0-899-Java-Platform-EE-6-Web-Component-Developer-Certified-Expert-Certificate.pdf', '1Z0-899-Java-Platform-EE-6-Web-Component-Developer-Certified-Expert-Certificate.pdf')
        }

        const downloadPDF21 = () => {
            saveAs('/img/certificari/Cert001.pdf', 'Cert001.pdf')
        }

        const downloadImage22 = () => {
            saveAs('/img/certificari/UC-f0b7b7ce-6a7b-4b79-b7d1-db52622bbde6.jpg', 'UC-f0b7b7ce-6a7b-4b79-b7d1-db52622bbde6.jpg')
        }

        const downloadPDF22 = () => {
            saveAs('/img/certificari/UC-f0b7b7ce-6a7b-4b79-b7d1-db52622bbde6.pdf', 'UC-f0b7b7ce-6a7b-4b79-b7d1-db52622bbde6.pdf')
        }

        const downloadImage23 = () => {
            saveAs('/img/certificari/UC-8f2bd766-c07d-4476-bbb2-adc49311de38.jpg', 'UC-8f2bd766-c07d-4476-bbb2-adc49311de38.jpg')
        }

        const downloadPDF23 = () => {
            saveAs('/img/certificari/UC-8f2bd766-c07d-4476-bbb2-adc49311de38.pdf', 'UC-8f2bd766-c07d-4476-bbb2-adc49311de38.pdf')
        }

        const downloadImage24 = () => {
            saveAs('/img/certificari/UC-82295eba-bc62-4c8a-838d-f87d5d7d1502.jpg', 'UC-82295eba-bc62-4c8a-838d-f87d5d7d1502.jpg')
        }

        const downloadPDF24 = () => {
            saveAs('/img/certificari/UC-82295eba-bc62-4c8a-838d-f87d5d7d1502.pdf', 'UC-82295eba-bc62-4c8a-838d-f87d5d7d1502.pdf')
        }

        const downloadImage25 = () => {
            saveAs('/img/certificari/UC-96ed45c3-5363-4c8d-9d30-1ccec68fbbc4.jpg', 'UC-96ed45c3-5363-4c8d-9d30-1ccec68fbbc4.jpg')
        }

        const downloadPDF25 = () => {
            saveAs('/img/certificari/UC-96ed45c3-5363-4c8d-9d30-1ccec68fbbc4.pdf', 'UC-96ed45c3-5363-4c8d-9d30-1ccec68fbbc4.pdf')
        }

        const downloadImage26 = () => {
            saveAs('/img/certificari/UC-708d1635-215a-4998-b73c-510ccd412cb4.jpg', 'UC-708d1635-215a-4998-b73c-510ccd412cb4.jpg')
        }

        const downloadPDF27 = () => {
            saveAs('/img/certificari/UC-708d1635-215a-4998-b73c-510ccd412cb4.pdf', 'UC-708d1635-215a-4998-b73c-510ccd412cb4.pdf')
        }

        const downloadImage28 = () => {
            saveAs('/img/certificari/UC-c94861fd-631b-46c5-9946-fb4e61a91d43.jpg', 'UC-c94861fd-631b-46c5-9946-fb4e61a91d43.jpg')
        }

        const downloadPDF29 = () => {
            saveAs('/img/certificari/UC-c94861fd-631b-46c5-9946-fb4e61a91d43.pdf', 'UC-c94861fd-631b-46c5-9946-fb4e61a91d43.pdf')
        }

        const downloadImage30 = () => {
            saveAs('/img/certificari/UC-07ebc675-d288-4bfc-8113-2e0b5c568533.jpg', 'UC-07ebc675-d288-4bfc-8113-2e0b5c568533.jpg')
        }

        const downloadPDF31 = () => {
            saveAs('/img/certificari/UC-07ebc675-d288-4bfc-8113-2e0b5c568533.pdf', 'UC-07ebc675-d288-4bfc-8113-2e0b5c568533.pdf')
        }

        const downloadImage32 = () => {
            saveAs('/img/certificari/UC-61eb50f3-a9f3-42b1-9242-932c615f9d69.jpg', 'UC-61eb50f3-a9f3-42b1-9242-932c615f9d69.jpg')
        }

        const downloadPDF33 = () => {
            saveAs('/img/certificari/UC-61eb50f3-a9f3-42b1-9242-932c615f9d69.pdf', 'UC-61eb50f3-a9f3-42b1-9242-932c615f9d69.pdf')
        }

        const downloadImage34 = () => {
            saveAs('/img/certificari/UC-ef0ff1ed-c338-40c3-aefc-944bae12f4ed.jpg', 'UC-ef0ff1ed-c338-40c3-aefc-944bae12f4ed.jpg')
        }

        const downloadPDF35 = () => {
            saveAs('/img/certificari/UC-ef0ff1ed-c338-40c3-aefc-944bae12f4ed.pdf', 'UC-ef0ff1ed-c338-40c3-aefc-944bae12f4ed.pdf')
        }

        const downloadImage36 = () => {
            saveAs('/img/certificari/UC-e2b7886a-850b-4129-8c4c-97c8156b4a98.jpg', 'UC-e2b7886a-850b-4129-8c4c-97c8156b4a98.jpg')
        }

        const downloadPDF37 = () => {
            saveAs('/img/certificari/UC-e2b7886a-850b-4129-8c4c-97c8156b4a98.pdf', 'UC-e2b7886a-850b-4129-8c4c-97c8156b4a98.pdf')
        }

        const downloadImage38 = () => {
            saveAs('/img/certificari/UC-d68b1acb-3c59-43e8-8c9a-c8a04d450025.jpg', 'UC-d68b1acb-3c59-43e8-8c9a-c8a04d450025.jpg')
        }

        const downloadPDF39 = () => {
            saveAs('/img/certificari/UC-d68b1acb-3c59-43e8-8c9a-c8a04d450025.pdf', 'UC-d68b1acb-3c59-43e8-8c9a-c8a04d450025.pdf')
        }

        const downloadImage40 = () => {
            saveAs('/img/certificari/UC-2dfcc6cd-c7bf-4506-8bf1-3392fccf6c70.jpg', 'UC-2dfcc6cd-c7bf-4506-8bf1-3392fccf6c70.jpg')
        }

        const downloadPDF41 = () => {
            saveAs('/img/certificari/UC-2dfcc6cd-c7bf-4506-8bf1-3392fccf6c70.pdf', 'UC-2dfcc6cd-c7bf-4506-8bf1-3392fccf6c70.pdf')
        }

        const downloadPDF42 = () => {
            saveAs('/img/certificari/bcp57bed_1673433997134.pdf', 'bcp57bed_1673433997134.pdf')
        }

        const downloadPDF43 = () => {
            saveAs('/img/certificari/Course-Certificate_Udemy-Labs-Online-Kubernetes-Lab-for-Beginners-Hands-On_Iulian.pdf.pdf', 'Course-Certificate_Udemy-Labs-Online-Kubernetes-Lab-for-Beginners-Hands-On_Iulian.pdf.pdf')
        }


        const downloadImage44 = () => {
            saveAs('/img/certificari/UC-edbef837-b5c7-4d11-a870-5121df34511c.jpg', 'UC-edbef837-b5c7-4d11-a870-5121df34511c.jpg')
        }

        const downloadPDF45 = () => {
            saveAs('/img/certificari/UC-edbef837-b5c7-4d11-a870-5121df34511c.pdf', 'UC-edbef837-b5c7-4d11-a870-5121df34511c.pdf')
        }

        const downloadImage46 = () => {
            saveAs('/img/certificari/UC-0a6581d0-379d-4136-9d09-2e7e06e1b5f6.jpg', 'UC-0a6581d0-379d-4136-9d09-2e7e06e1b5f6.jpg')
        }

        const downloadPDF47 = () => {
            saveAs('/img/certificari/UC-0a6581d0-379d-4136-9d09-2e7e06e1b5f6.pdf', 'UC-0a6581d0-379d-4136-9d09-2e7e06e1b5f6.pdf')
        }

        const downloadImage48 = () => {
            saveAs('/img/certificari/UC-ef408fcd-3e76-4f22-891c-223ea6ab3b9f.jpg', 'UC-ef408fcd-3e76-4f22-891c-223ea6ab3b9f.jpg')
        }

        const downloadPDF49 = () => {
            saveAs('/img/certificari/UC-ef408fcd-3e76-4f22-891c-223ea6ab3b9f.pdf', 'UC-ef408fcd-3e76-4f22-891c-223ea6ab3b9f.pdf')
        }

        const downloadPDF50 = () => {
            saveAs('/img/certificari/certificate_of_completion_react.js.pdf', 'certificate_of_completion_react.js.pdf')
        }

        const downloadPDF51 = () => {
            saveAs('/img/certificari/react-certification.pdf', 'react-certification.pdf')
        }

        const downloadImage52 = () => {
            saveAs('/img/certificari/UC-691eb418-ec46-449b-9502-0a126b0c54fa.jpg', 'UC-691eb418-ec46-449b-9502-0a126b0c54fa.jpg')
        }

        const downloadPDF53 = () => {
            saveAs('/img/certificari/UC-691eb418-ec46-449b-9502-0a126b0c54fa.pdf', 'UC-691eb418-ec46-449b-9502-0a126b0c54fa.pdf')
        }

        const downloadImage54 = () => {
            saveAs('/img/certificari/UC-f5efd04b-4c21-4d9f-9906-81470a581c2b.jpg', 'UC-f5efd04b-4c21-4d9f-9906-81470a581c2b.jpg')
        }

        const downloadPDF55 = () => {
            saveAs('/img/certificari/UC-f5efd04b-4c21-4d9f-9906-81470a581c2b.pdf', 'UC-f5efd04b-4c21-4d9f-9906-81470a581c2b.pdf')
        }

        const downloadImage56 = () => {
            saveAs('/img/certificari/UC-e4280d03-6ced-48dc-b3e7-c61f122ca03a.jpg', 'UC-e4280d03-6ced-48dc-b3e7-c61f122ca03a.jpg')
        }

        const downloadPDF57 = () => {
            saveAs('/img/certificari/UC-e4280d03-6ced-48dc-b3e7-c61f122ca03a.pdf', 'UC-e4280d03-6ced-48dc-b3e7-c61f122ca03a.pdf')
        }

        const downloadImage58 = () => {
            saveAs('/img/certificari/UC-b7092436-7d20-401a-a827-3df9c0bb7bb6.jpg', 'UC-b7092436-7d20-401a-a827-3df9c0bb7bb6.jpg')
        }

        const downloadPDF59 = () => {
            saveAs('/img/certificari/UC-b7092436-7d20-401a-a827-3df9c0bb7bb6.pdf', 'UC-b7092436-7d20-401a-a827-3df9c0bb7bb6.pdf')
        }

        const downloadImage60 = () => {
            saveAs('/img/certificari/UC-3b6813a8-ac0b-45df-b46a-43361d6bb4a9.jpg', 'UC-3b6813a8-ac0b-45df-b46a-43361d6bb4a9.jpg')
        }

        const downloadPDF61 = () => {
            saveAs('/img/certificari/UC-3b6813a8-ac0b-45df-b46a-43361d6bb4a9.pdf', 'UC-3b6813a8-ac0b-45df-b46a-43361d6bb4a9.pdf')
        }

        const downloadImage62 = () => {
            saveAs('/img/certificari/UC-6c96a1d2-9287-47c8-8550-de57d75d86f9.jpg', 'UC-6c96a1d2-9287-47c8-8550-de57d75d86f9.jpg')
        }

        const downloadPDF63 = () => {
            saveAs('/img/certificari/UC-6c96a1d2-9287-47c8-8550-de57d75d86f9.pdf', 'UC-6c96a1d2-9287-47c8-8550-de57d75d86f9.pdf')
        }

        const downloadImage64 = () => {
            saveAs('/img/certificari/UC-bae3e09c-15dd-46d3-b96a-31ea5a6c9726.jpg', 'UC-bae3e09c-15dd-46d3-b96a-31ea5a6c9726.jpg')
        }

        const downloadPDF65 = () => {
            saveAs('/img/certificari/UC-bae3e09c-15dd-46d3-b96a-31ea5a6c9726.pdf', 'UC-bae3e09c-15dd-46d3-b96a-31ea5a6c9726.pdf')
        }

        const downloadImage66 = () => {
            saveAs('/img/certificari/UC-413d07ea-a392-4be4-ae9c-aafb397ef9bd.jpg', 'UC-413d07ea-a392-4be4-ae9c-aafb397ef9bd.jpg')
        }

        const downloadPDF67 = () => {
            saveAs('/img/certificari/UC-413d07ea-a392-4be4-ae9c-aafb397ef9bd.pdf', 'UC-413d07ea-a392-4be4-ae9c-aafb397ef9bd.pdf')
        }

        const downloadImage68 = () => {
            saveAs('/img/certificari/UC-284f5712-1fd2-4c93-b618-d6d91f96195c.jpg', 'UC-284f5712-1fd2-4c93-b618-d6d91f96195c.jpg')
        }

        const downloadPDF69 = () => {
            saveAs('/img/certificari/UC-284f5712-1fd2-4c93-b618-d6d91f96195c.pdf', 'UC-284f5712-1fd2-4c93-b618-d6d91f96195c.pdf')
        }

        const downloadImage70 = () => {
            saveAs('/img/certificari/UC-6666b7f2-6cdf-42aa-894a-3ca708af6661.jpg', 'UC-6666b7f2-6cdf-42aa-894a-3ca708af6661.jpg')
        }

        const downloadPDF71 = () => {
            saveAs('/img/certificari/UC-6666b7f2-6cdf-42aa-894a-3ca708af6661.pdf', 'UC-6666b7f2-6cdf-42aa-894a-3ca708af6661.pdf')
        }

        const downloadImage72 = () => {
            saveAs('/img/certificari/UC-45433298-0b0d-473f-aaee-72d03f5d23f1.jpg', 'UC-45433298-0b0d-473f-aaee-72d03f5d23f1.jpg')
        }

        const downloadPDF73 = () => {
            saveAs('/img/certificari/UC-45433298-0b0d-473f-aaee-72d03f5d23f1.pdf', 'UC-45433298-0b0d-473f-aaee-72d03f5d23f1.pdf')
        }

        const downloadImage74 = () => {
            saveAs('/img/certificari/UC-a84e18df-312f-4156-9ace-984d620d46b5.jpg', 'UC-a84e18df-312f-4156-9ace-984d620d46b5.jpg')
        }

        const downloadPDF75 = () => {
            saveAs('/img/certificari/UC-a84e18df-312f-4156-9ace-984d620d46b5.pdf', 'UC-a84e18df-312f-4156-9ace-984d620d46b5.pdf')
        }

        const downloadPDF76 = () => {
            saveAs('/img/certificari/iulian-daniel-buzdugan-0e33466d-1c58-4b1a-8823-e06201a5e11c-certificate.pdf', 'iulian-daniel-buzdugan-0e33466d-1c58-4b1a-8823-e06201a5e11c-certificate.pdf')
        }

        const downloadPDF77 = () => {
            saveAs('/img/certificari/UC-57e58b03-9338-428f-90c2-b483b518fb79.pdf', 'UC-57e58b03-9338-428f-90c2-b483b518fb79.pdf')
        }

        const downloadImage78 = () => {
            saveAs('/img/certificari/UC-57e58b03-9338-428f-90c2-b483b518fb79.jpg', 'UC-57e58b03-9338-428f-90c2-b483b518fb79.jpg')
        }

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Certificate IT</b>
                    <ul>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF76}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">16.01.2023</span>&nbsp;&nbsp;
                            Certified Kubernetes Application Developer (CKAD)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF51}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">11.05.2023</span>&nbsp;&nbsp;
                            React Developer Certified - Level 1
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF50}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">10.05.2023</span>&nbsp;&nbsp;
                            React.js Certification
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF42}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">11.01.2023 - 11.01.2025</span>&nbsp;&nbsp;
                            Docker Certified Associate
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF14}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">22.04.2022</span>&nbsp;&nbsp;
                            Liferay DXP 7.3 Back-End Developer
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF13}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">05.04.2022</span>&nbsp;&nbsp;
                            JSE - Certified Entry-Level JavaScript Programmer, JSE-40-01
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF16}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">30.11.2021</span>&nbsp;&nbsp;
                            PCAP - Certified Associate in Python Programmer, PCAP-31-03
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF15}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">15.11.2021</span>&nbsp;&nbsp;
                            PCEP - Certified Entry-Level in Python Programmer, PCEP-30-01 [NP]
                        </li>
                        <li>
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF17}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">17.04.2014</span>&nbsp;&nbsp;
                            Oracle Certified Professional, Java SE 7 Programmer,
                            1Z0-805-Updade-to-Java-SE-7-Programmer-Certificate
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF21}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> :<span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">28.03.2013</span>&nbsp;&nbsp;
                            Programming in HTML5 with JavaScript and CSS3 - 70-480
                        </li>
                        <li>
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF20}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">28.06.2012</span>&nbsp;&nbsp;
                            Oracle Certified Expert, Java Platform, EE 6 Web Component Developer, 1Z0-899
                        </li>
                        <li>
                            <span className="badge badge-warning">31.05.2012</span>&nbsp;&nbsp;
                            Core Spring 3.0 Certification, CoreSpringV3.0
                        </li>
                        <li>
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF19}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">27.09.2011</span>&nbsp;&nbsp;
                            Oracle Certified Professional, Java SE 6 Programmer, 1Z0-851, 27.09.2011
                        </li>
                        <li>
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF18}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">17.08.2011</span>&nbsp;&nbsp;
                            Oracle Certified Associate, Java SE 5/SE 6, 1Z0-850, 17.08.2011
                        </li>
                    </ul>

                    <b>Certificate de absolvire Foto-Video</b>
                    <ul>
                        <li>
                            <span className="badge badge-warning">11.08.2022</span>&nbsp;&nbsp;
                            Editor Imagine
                        </li>
                        <li>
                            <span className="badge badge-warning">21.02.2022</span>&nbsp;&nbsp;
                            Operator Imagine (cod COR: 352124)
                        </li>
                        <li>
                            <span className="badge badge-warning">20.07.2018</span>&nbsp;&nbsp;
                            Fotograf (cod COR: 343101)
                        </li>
                    </ul>

                    <b>Certificate de finalizare curs IT</b>

                    <ul>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage78}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF77}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">14.03.2024</span>&nbsp;&nbsp;
                            Arduino For Beginners - 2024 Complete Course (Udemy, Instructor: Edouard Renard ;15h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage74}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF75}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">21.12.2023</span>&nbsp;&nbsp;
                            Kubernetes Certified Application Developer (CKAD) with Tests (Udemy, Instructor:Mumshad Mannambeth, KodeKloud Training, Vijin Palazhi - Support ;15.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage72}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF73}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">19.12.2023</span>&nbsp;&nbsp;
                            YAML Zero to Master (Udemy, Instructor:Eazy Bytes, Madan Reddy - Support ;3h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage70}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF71}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">16.12.2023</span>&nbsp;&nbsp;
                            GitLab CI: Pipelines, CI/CD and DevOps for Beginners (Udemy, Instructor: Valentin Despa, Valentin Despa - Support ;5.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage68}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF69}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">13.12.2023</span>&nbsp;&nbsp;
                            Jira for Beginners - Detailed Course to Get Started in Jira (Udemy, Instructor: Simon Sez IT ;4h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage66}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF67}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">26.08.2023</span>&nbsp;&nbsp;
                            DevOps Project CI/CD Git GITLAB Jenkins Nodejs React (Udemy, Instructor: Arbaz Md ;4h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage64}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF65}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">21.08.2023</span>&nbsp;&nbsp;
                            Go: The Complete Developer's Guide (Golang) (Udemy, Instructor: Stephen Grider ;9h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage62}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF63}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">20.08.2023</span>&nbsp;&nbsp;
                            Ultimate Rust Crash Course (Udemy, Instructor: Nathan Stocks;3h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage60}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF61}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">15.08.2023</span>&nbsp;&nbsp;
                            Grafana (Udemy, Instructor: Sean Bradley;6.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage58}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF59}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">13.08.2023</span>&nbsp;&nbsp;
                            The Complete Course of Grafana 2023 (Udemy, Instructor: Laureano Boira Gual;8h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage56}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF57}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">11.08.2023</span>&nbsp;&nbsp;
                            Grafana from Beginner to Advanced (3rd Edition) (Udemy, Instructor: Aref Karimi; 5.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage54}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF55}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">08.06.2023</span>&nbsp;&nbsp;
                            Angular - The Complete Guide (2023 Edition) (Udemy, Instructor: Maximilian Schwarzmüller; 36.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage52}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF53}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">04.06.2023</span>&nbsp;&nbsp;
                            Complete Angular Developer in 2023: Zero to Mastery (Udemy, Instructor: Andrei Neagoie, Luis Ramirez Jr; 27.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage48}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF49}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">21.04.2023</span>&nbsp;&nbsp;
                            Next.js & React - The Complete Guide (incl. Two Paths!) (Udemy, Instructor: Maximilian Schwarzmüller; 25h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage46}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF47}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">21.04.2023</span>&nbsp;&nbsp;
                            React, Next.js and Laravel: A Rapid Guide - Advanced (Udemy, Instructor: Antonio Papa; 8h)
                        </li>


                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage44}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF45}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">23.01.2023</span>&nbsp;&nbsp;
                            Kubernetes for the Absolute Beginners - Hands-on (Udemy, Instructor: Mumshad Mannambeth, KodeKloud Training; 6h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF43}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">22.01.2023</span>&nbsp;&nbsp;
                            Udemy Labs - Online Kubernetes Lab for Beginners - Hands On (KodeKloud, Instructor: Mumshad Mannambeth)
                        </li>


                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage40}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF41}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">07.01.2023</span>&nbsp;&nbsp;
                            Docker Certified Associate 2023 (Udemy, Instructor: Zeal Vora, Harsh Selani; 19.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage38}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF39}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">29.12.2022</span>&nbsp;&nbsp;
                            Docker Certified Associate Exam Prep Course (Udemy, Instructor: Cerulean Canvas; 10h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage36}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF37}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">26.12.2022</span>&nbsp;&nbsp;
                            Kubernetes and Docker: The Container Masterclass (Udemy, Instructor: Cerulean Canvas; 7.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage34}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF35}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">24.12.2022</span>&nbsp;&nbsp;
                            Docker & Kubernetes: The Practical Guide [2023 Edition] (Udemy, Instructor: Maximilian Schwarzmüller; 23.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage32}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF33}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">17.12.2022</span>&nbsp;&nbsp;
                            Docker & Kubernetes: The Complete Practical Guide (Udemy, Instructor: Bogdan Stashchuk; 21.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage30}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF31}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">17.12.2022</span>&nbsp;&nbsp;
                            Kubernetes for Beginners (Udemy, Instructor: Bogdan Stashchuk; 3h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage28}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF29}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">14.12.2022</span>&nbsp;&nbsp;
                            The Practical Linux Guide for Beginners (Udemy, Instructor: Bogdan Stashchuk; 6h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage26}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF27}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">13.11.2022</span>&nbsp;&nbsp;
                            Docker and Kubernetes: The Complete Guide (Udemy, Instructor: Stephen Grider; 22h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage25}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF25}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">20.10.2022</span>&nbsp;&nbsp;
                            Introduction to Apache FreeMarker (Udemy, Instructor: Parsiphal David Coletta; 38min)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <a className={"btn btn-info btn-sm small-text"} target={"_blank"}
                                       href={"https://graphacademy.neo4j.com/u/abbd627c-9374-4d78-a9c5-c33243d05044/neo4j-fundamentals/"}>link</a>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">09.10.2022</span>&nbsp;&nbsp;
                            Neo4j Fundamentals (GraphAcademy; 1h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage24}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF24}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">05.10.2022</span>&nbsp;&nbsp;
                            Invata GIT si GitHub [Română] 2022 (Udemy, Instructor: Radu Popescu; 1.5h)
                        </li>

                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage23}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF23}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">04.10.2022</span>&nbsp;&nbsp;
                            Keycloak : Single Sign On with Spring Boot & Spring Security (Udemy, Instructor: Infybuzzz
                            Learning; 3h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"}
                                            onClick={downloadImage22}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"}
                                            onClick={downloadPDF22}>PDF</button>
                                    &nbsp;&nbsp;
                                   </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">01.10.2022</span>&nbsp;&nbsp;
                            Microservices with Java Spring Boot and Spring Cloud (Udemy, Instructor: Infybuzz Learning;
                            4h)&nbsp;&nbsp;
                        </li>
                        <li>
                            <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage3}>JPG</button>
                            &nbsp;
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF3}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">21.09.2022</span>&nbsp;&nbsp;
                            NGINX Fundamentals: High Performance Servers from Scratch (Udemy, Instructor: Ray Viljoen;
                            4h)&nbsp;&nbsp;
                        </li>
                        <li>
                            <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage2}>JPG</button>
                            &nbsp;
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF2}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">17.09.2022</span>&nbsp;&nbsp;
                            Apache Maven - The complete course (Udemy, Instructor: Kiril Anastasov; 4.5h)
                        </li>

                        <li>
                            <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage1}>JPG</button>
                            &nbsp;
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF1}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">13.09.2022</span>&nbsp;&nbsp;
                            Apache Tomcat Server from Beginners to Advanced (Udemy, Instructor: Amit Kumar; 8h 9min)
                        </li>
                        <li>
                            <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage4}>JPG</button>
                            &nbsp;
                            <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF4}>PDF</button>
                            &nbsp;&nbsp;
                            <span className="badge badge-warning">06.09.2022</span>&nbsp;&nbsp;
                            IntelliJ: The perfect Java IDE in 2022 (Udemy, Instructor: Programming Made Easy; 1.5h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage6}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF6}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">21.04.2022</span>&nbsp;&nbsp;
                            Gradle for java developers (Udemy, Instructor: Bharath Thippireddy; 4.5h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage7}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF7}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">20.04.2022</span>&nbsp;&nbsp;
                            The Gradle Masterclass (Udemy, Instructor: Bruce E. Hilton; 6h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage5}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF5}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">07.04.2022</span>&nbsp;&nbsp;
                            Gradle Fundamentals (Udemy, Instructor: Jimmy H.; 3.5h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage8}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF8}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">01.09.2021</span>&nbsp;&nbsp;
                            Liferay for Beginners Create Your Website Without Coding (Udemy, Instructors: Vikas Kumar
                            Jha, Poonam Jha; 2.5h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage9}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF9}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">30.08.2021</span>&nbsp;&nbsp;
                            Liferay 7.0 CE and CMS Fundamentals - Crash Course (Udemy, Instructor: Uğurcan Çetin; 4h)
                        </li>
                        <li>
                            <span className="badge badge-warning">30.04.2014</span>&nbsp;&nbsp;
                            Programming Mobile Applications for Android Handheld Systems (Coursera, Profesor: Adam
                            Porter)
                        </li>
                    </ul>

                    <b>Certificate de finalizare curs Foto-Video</b>
                    <ul>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage12}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF12}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">11.05.2021</span>&nbsp;&nbsp;
                            Professional Retouching Course in Photoshop (Udemy, Instructor:Marcin Mikus; 9h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage11}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF11}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }

                            <span className="badge badge-warning">24.04.2021</span>&nbsp;&nbsp;
                            Creative techniques & ideas in nude art photography (Udemy, Instructor: Alin Panaite; 5.5h)
                        </li>
                        <li>
                            {isAuth ?
                                <span>
                                    <button className={"btn btn-dark btn-sm small-text"} onClick={downloadImage10}>JPG</button>
                                    &nbsp;
                                    <button className={"btn btn-info btn-sm small-text"} onClick={downloadPDF10}>PDF</button>
                                    &nbsp;&nbsp;
                                </span> : <span>
                                    <FontAwesomeIcon icon={faEyeSlash}/>&nbsp;
                                </span>
                            }
                            <span className="badge badge-warning">21.04.2021</span>&nbsp;&nbsp;
                            Curs complet de editare în lightroom de la A la Z (Udemy, Instructor: Alin Panaite; 7.5h)
                        </li>
                    </ul>

                    <hr/>
                    <b> Credly: <a target={"_blank"}
                                   href={"https://www.credly.com/users/iulian-daniel-buzdugan"}>badges</a></b>
                    <br/>

                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/ckad-certified-kubernetes-application-developer.png'}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/pcap-31-xx.png'}/>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/bronze_1_small.png'}/>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/jse_badge.png'}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/Oracle-Certification-badge_OC-Professional600X600.png'}/>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/Oracle-Certification-badge_OC-CertifiedExpert.png'}/>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/Oracle-Certification-badge_OC-Associate600X600.png'}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 175}} className={"rounded mx-auto d-block"}
                                         src={process.env.PUBLIC_URL + '/img/certificari/badges/Programming_in_HTML5_with_JavaScript_and_Css3-01.png'}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CertificatesCVContent;