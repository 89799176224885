import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class TopLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-top", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa in timp real procesele și informații care însoțesc acele procese, cum ar fi utilizarea memoriei, utilizarea procesorului etc:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'top'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'top - 21:41:13 up 56 days, 42 min,  1 user,  load average: 2,11, 1,74, 1,88\n' +
                            'Tasks: 348 total,   2 running, 346 sleeping,   0 stopped,   0 zombie\n' +
                            '%Cpu(s): 10,5 us, 13,3 sy,  0,0 ni, 48,5 id, 27,4 wa,  0,0 hi,  0,3 si,  0,0 st\n' +
                            'MiB Mem :   5933,9 total,    133,8 free,   1686,1 used,   4114,0 buff/cache\n' +
                            'MiB Swap:   6046,0 total,   5535,6 free,    510,4 used.   3663,6 avail Mem\n' +
                            '\n' +
                            '    PID USER      PR  NI    VIRT    RES    SHR S  %CPU  %MEM     TIME+ COMMAND\n' +
                            '3570334 root      20   0 1938008  65972  24012 S   4,6   1,1   1525:44 kubelet\n' +
                            '3569252 root      20   0 1117372 234780  26980 S   4,0   3,9   1945:55 kube-apiserver\n' +
                            '3568249 root      20   0 2005908  57244  11788 S   3,6   0,9   2468:28 dockerd\n' +
                            '3570166 root      20   0  826928  55864  17460 S   2,6   0,9 968:21.13 kube-controller'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TopLinuxContent;