import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class PsDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ps", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Comanda docker ps</b>
                    <br/>
                    <br/>

                    Afiseaza toate containerele care ruleaza:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'CONTAINER ID   IMAGE     COMMAND   CREATED   STATUS    PORTS     NAMES'}
                    </SyntaxHighlighter>
                    Deci nu avem nici un container care ruleaza.
                    <br/>
                    Este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls'}
                    </SyntaxHighlighter>

                    Daca vom rula:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run busybox ping google.com'}
                    </SyntaxHighlighter>
                    (pentru a opri procesul, dupa un timp de rulare: CTRL+C)

                    si vom da din noua comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE     COMMAND             CREATED          STATUS         PORTS     NAMES\n' +
                            '93d90e6b4bd3   busybox   "ping google.com"   17 seconds ago   Up 6 seconds             eloquent_merkle'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>CONTAINER ID: id container</li>
                        <li>IMAGE: nume imagine</li>
                        <li>COMMAND: comanda (ex: "ping google.com")</li>
                        <li>CREATED: de cand ruleaza/cand a fost creat containerul (ex: 17 seconds ago)</li>
                        <li>STATUS: status si de cand e in acest status (ex: Up 6 seconds)</li>
                        <li>PORTS: porturile care au fost deschise pentru acces din afara</li>
                        <li>NAMES: nume generat aleator prin care se identifica containerul (ex: eloquent_merkle)</li>
                    </ul>

                    Pentru a afisa doar ID-urile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -q'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls -q'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'5a4cdd28b1fe\n' +
                            '213e8cf4e499\n' +
                            'a3bef0b0cc6e\n' +
                            'c69ac6bb47d8\n' +
                            'bf43181baaa1\n' +
                            '05c4d660062b\n' +
                            '1de013bc0797\n' +
                            'aa07e6c09d06\n' +
                            'cdb7c25cd6fa\n' +
                            'af1aadadb6c7\n' +
                            '4ff5b2b10044\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afisa toate containerele create (care nu neaparat ruleaza):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -a'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps --all'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE         COMMAND             CREATED          STATUS                          PORTS     NAMES\n' +
                            '93d90e6b4bd3   busybox       "ping google.com"   7 minutes ago    Exited (0) About a minute ago             eloquent_merkle\n' +
                            '16fd0d6b2de3   busybox       "ls"                19 minutes ago   Exited (0) 19 minutes ago                 amazing_rubin\n' +
                            '1639dcaf21b8   hello-world   "/hello"            2 hours ago      Exited (0) 2 hours ago                    wonderful_golick\n' +
                            '253d580f94f6   hello-world   "/hello"            3 hours ago      Exited (0) 3 hours ago                    sharp_davinci'}
                    </SyntaxHighlighter>

                    Pentru a afisa doar ID-urile (pentru continere oprite):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps -aq'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls -aq'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PsDockerContent;