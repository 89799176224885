import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FTPClientJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-ftp-client", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>


                <div className={"text-justify important"}>

                    In gradle:
                    <SyntaxHighlighter>
                        {'\t// https://mvnrepository.com/artifact/commons-net/commons-net\n' +
                            '\timplementation \'commons-net:commons-net:3.9.0\''}
                    </SyntaxHighlighter>
                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import org.apache.commons.net.PrintCommandListener;\n' +
                            'import org.apache.commons.net.ftp.FTPClient;\n' +
                            'import org.apache.commons.net.ftp.FTPFile;\n' +
                            'import org.apache.commons.net.ftp.FTPReply;\n' +
                            '\n' +
                            'import java.io.IOException;\n' +
                            'import java.io.PrintWriter;\n' +
                            'import java.time.Duration;\n' +
                            'import java.util.Arrays;\n' +
                            'import java.util.Collection;\n' +
                            'import java.util.stream.Collectors;\n' +
                            '\n' +
                            'public class FtpClient {\n' +
                            '\n' +
                            '    private static final Log log = LogFactoryUtil.getLog(SftpServiceImpl.class);\n' +
                            '\n' +
                            '    private String server = "XX.XX.XX.XX";\n' +
                            '    private int port = 22;\n' +
                            '    private String user = "user";\n' +
                            '    private String password = "password";\n' +
                            '    private FTPClient ftp;\n' +
                            '\n' +

                            '    public boolean open() {\n' +
                            '        close();\n' +
                            '\n' +
                            '        ftp = new FTPClient();\n' +
                            '\n' +
                            '        log.debug("Connecting and logging in to FTP server.");\n' +
                            '\n' +
                            '        ftp.enterLocalPassiveMode();\n' +
                            '        boolean loggedIn = false;\n' +
                            '        try {\n' +
                            '            ftp.connect(server);\n' +
                            '            ftp.enterLocalPassiveMode(); // FOARTE IMPORTANT (fara nu se poate lista sau efectua ok alte operatii)\n' +
                            '          //  ftp.enterLocalActiveMode();\n' +
                            '            loggedIn = ftp.login(user, password);\n' +
                            '            if (loggedIn) {\n' +
                            '                ftp.setControlKeepAliveTimeout(Duration.ofMinutes(5));\n' +
                            '            } else {\n' +
                            '                log.error("Failed login to FTP server");\n' +
                            '                ftp.logout();\n' +
                            '                ftp.disconnect();\n' +
                            '            }\n' +
                            '        } catch (Exception e) {\n' +
                            '            log.error(e.getMessage());\n' +
                            '        }\n' +
                            '        return loggedIn;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void close() {\n' +
                            '        if (ftp != null) {\n' +
                            '            try {\n' +
                            '                if (ftp.isConnected()) {\n' +
                            '                    ftp.logout();\n' +
                            '                    ftp.disconnect();\n' +
                            '                }\n' +
                            '            } catch (Exception e) {\n' +
                            '                log.error(e.getMessage(), e);\n' +
                            '            }\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    public Collection<String> listFiles(String path) throws IOException {\n' +
                            '        FTPFile[] files = ftp.listFiles(path);\n' +
                            '        return Arrays.stream(files)\n' +
                            '                .map(FTPFile::getName)\n' +
                            '                .collect(Collectors.toList());\n' +
                            '    }\n' +
                            '\n' +
                            '    public FTPFile[] listDirectories(String path) throws IOException {\n' +
                            '        // FTPReply\n' +
                            '        FTPFile[] files = ftp.listDirectories();\n' +
                            '        log.error("***");\n' +
                            '        log.error("Current directory: " +ftp.printWorkingDirectory());\n' +
                            '        log.error("Number of directories: " +files.length);\n' +
                            '\n' +
                            '        for(FTPFile f:files){\n' +
                            '            log.error(f.getName());\n' +
                            '        }\n' +
                            '        return files;\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Setup SFTP Server Ubuntu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Setup SFTP Server Ubuntu\n' +
                            '=======================\n' +
                            'ssh kj@192.168.1.7\n' +
                            '\n' +
                            'sudo apt update\n' +
                            'sudo apt install vsftpd\n' +
                            'sudo systemctl start vsftpd\n' +
                            'sudo systemctl enable vsftpd\n' +
                            'sudo systemctl status vsftpd\n' +
                            '\n' +
                            '# backup la fisierul de configurare (optional)\n' +
                            'sudo cp /etc/vsftpd.conf /etc/vsftpd.conf_default\n' +
                            'sau\n' +
                            'sudo cp /etc/vsftpd.conf /etc/vsftpd.conf.bak\n' +
                            '\n' +
                            '# nano /etc/vsftpd.conf\n' +
                            'listen=NO\n' +
                            'listen_ipv6=YES\n' +
                            'anonymous_enable=NO\n' +
                            'local_enable=YES\n' +
                            'write_enable=YES\n' +
                            'local_umask=022\n' +
                            'dirmessage_enable=YES\n' +
                            'use_localtime=YES\n' +
                            'xferlog_enable=YES\n' +
                            'connect_from_port_20=YES\n' +
                            'chroot_local_user=YES\n' +
                            'secure_chroot_dir=/var/run/vsftpd/empty\n' +
                            'pam_service_name=vsftpd\n' +
                            'pasv_enable=Yes\n' +
                            'pasv_min_port=10000\n' +
                            'pasv_max_port=11000\n' +
                            'user_sub_token=$USER\n' +
                            'local_root=/home/$USER/ftp\n' +
                            'userlist_enable=YES\n' +
                            'userlist_file=/etc/vsftpduserlist.conf\n' +
                            'userlist_deny=NO\n' +
                            '\n' +
                            '# restart serviciu\n' +
                            'sudo systemctl restart vsftpd\n' +
                            'sudo systemctl status vsftpd\n' +
                            '\n' +
                            '# creare utilizator\n' +
                            'sudo useradd -m kj_ftp\n' +
                            '\n' +
                            '# setare parola\n' +
                            'sudo passwd kj_ftp\n' +
                            '\n' +
                            '# creare directore si drepturi\n' +
                            'sudo mkdir home/kj_ftp/ftp\n' +
                            'sudo chown nobody:nogroup /home/kj_ftp/ftp\n' +
                            'sudo chmod a-w /home/kj_ftp/ftp\n' +
                            '\n' +
                            'sudo mkdir /home/kj_ftp/ftp/test\n' +
                            'sudo chown kj_ftp:kj_ftp /home/kj_ftp/ftp/test\n' +
                            '\n' +
                            '# adaugare utilizator in lista de user FTP:\n' +
                            'sudo nano /etc/vsftpduserlist.conf\n' +
                            'kj_ftp\n' +
                            '\n' +
                            '# restart serviciu\n' +
                            'sudo systemctl restart vsftpd\n' +
                            'sudo systemctl status vsftpd\n' +
                            '\n' +
                            '# creare certificat de securitate\n' +
                            'sudo mkdir /etc/certs\n' +
                            'sudo openssl req -x509 -nodes -days 365 -newkey rsa:2048 -keyout /etc/certs/vsftpd.pem -out /etc/certs/vsftpd.pem\n' +
                            '\n' +
                            '# adaugare in:\n' +
                            'sudo nano /etc/vsftpd.conf\n' +
                            '\n' +
                            'rsa_cert_file=/etc/certs/vsftpd.pem\n' +
                            'rsa_private_key_file=/etc/certs/vsftpd.pem\n' +
                            'ssl_enable=YES\n' +
                            'allow_anon_ssl=NO\n' +
                            'force_local_data_ssl=YES\n' +
                            'force_local_logins_ssl=YES\n' +
                            'ssl_tlsv1=YES\n' +
                            'ssl_sslv2=NO\n' +
                            'ssl_sslv3=NO\n' +
                            'require_ssl_reuse=NO\n' +
                            'ssl_ciphers=HIGH\n' +
                            '\n' +
                            '# restart serviciu\n' +
                            'sudo systemctl restart vsftpd\n' +
                            'sudo systemctl status vsftpd\n' +
                            '\n' +
                            '# deschidere porturi 20 si 21\n' +
                            'sudo ufw allow 20/tcp\n' +
                            'sudo ufw allow 21/tcp\n' +
                            '\n' +
                            '# conectare la un server FTP: (din interiorul masinii virtuale)\n' +
                            'sudo ftp 192.168.1.7\n' +
                            '\n' +
                            'Connected to 192.168.1.7.\n' +
                            '220 (vsFTPd 3.0.5)\n' +
                            'Name (192.168.1.7:kj): kj_ftp\n' +
                            '331 Please specify the password.\n' +
                            'Password: kj_ftp\n' +
                            '230 Login successful.\n' +
                            'Remote system type is UNIX.\n' +
                            'Using binary mode to transfer files.\n' +
                            '\n' +
                            '# pentru a iesi dintr-o sesiune FTP:\n' +
                            'quit\n' +
                            '#sau\n' +
                            'bye\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*    */}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FTPClientJavaContent;