import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ValidationCustomFieldLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-validation-custom-field", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Validare custom field</b>
                    <br/>
                    <br/>

                    Se implementeaza <b>DDMFormFieldValueValidator</b>, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.custom.field.dynamic.data.mapping.form.field.type.cnp;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.DDMFormFieldValueValidationException;\n' +
                            'import com.liferay.dynamic.data.mapping.form.field.type.DDMFormFieldValueValidator;\n' +
                            'import com.liferay.dynamic.data.mapping.model.DDMForm;\n' +
                            'import com.liferay.dynamic.data.mapping.model.DDMFormField;\n' +
                            'import com.liferay.dynamic.data.mapping.model.Value;\n' +
                            'import com.liferay.portal.kernel.json.JSONFactory;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.util.Validator;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            '\n' +
                            'import java.util.Locale;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true, property = "ddm.form.field.type.name=cnp",\n' +
                            '        service = DDMFormFieldValueValidator.class\n' +
                            ')\n' +
                            'public class CnpDDMFormFieldValueValidator implements DDMFormFieldValueValidator{\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    protected JSONFactory jsonFactory;\n' +
                            '\n' +
                            '    private static final Log _log = LogFactoryUtil.getLog(\n' +
                            '            CnpDDMFormFieldValueValidator.class);\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void validate(DDMFormField ddmFormField, Value value) throws DDMFormFieldValueValidationException {\n' +
                            '\n' +
                            '        DDMForm ddmForm = ddmFormField.getDDMForm();\n' +
                            '\n' +
                            '        Locale defaultLocale = ddmForm.getDefaultLocale();\n' +
                            '\n' +
                            '        String valueString = value.getString(defaultLocale);\n' +
                            '\n' +
                            '        validateCnpValue(ddmFormField, defaultLocale, valueString);\n' +
                            '    }\n' +
                            '\n' +
                            '    private void validateCnpValue(DDMFormField ddmFormField, Locale defaultLocale, String valueString) throws DDMFormFieldValueValidationException {\n' +
                            '\n' +
                            '        if (Validator.isNotNull(valueString)) {\n' +
                            '\n' +
                            '            if (!isValidCNP(valueString)) {\n' +
                            '                throw new DDMFormFieldValueValidationException(String.format("CNP trebuie sa aiba 13 caractere \\"%s\\"", ddmFormField.getName()));\n' +
                            '            }\n' +
                            '        }\n' +
                            '        else if (ddmFormField.isRequired()) {\n' +
                            '            throw new DDMFormFieldValueValidationException(String.format("CNP nu poate fi necompletat \\"%s\\"", ddmFormField.getName()));\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    private boolean isValidCNP(String cnp) {\n' +
                            '\n' +
                            '        int[] CONTROL_NUMBERS = {2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9};\n' +
                            '\n' +
                            '        if (cnp == null || cnp.length() != 13 || !cnp.matches("\\\\d+")) {\n' +
                            '            return false;\n' +
                            '        }\n' +
                            '\n' +
                            '        // Calculăm și verificăm cifra de control\n' +
                            '        int controlSum = 0;\n' +
                            '        for (int i = 0; i < 12; i++) {\n' +
                            '            controlSum += Character.digit(cnp.charAt(i), 10) * CONTROL_NUMBERS[i];\n' +
                            '        }\n' +
                            '\n' +
                            '        int controlDigit = controlSum % 11;\n' +
                            '        if (controlDigit == 10) {\n' +
                            '            controlDigit = 1;\n' +
                            '        }\n' +
                            '\n' +
                            '        return controlDigit == Character.digit(cnp.charAt(12), 10);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Vezi aici:
                    <a target={"_blank"} href={"https://github.com/liferay/liferay-portal/blob/7.2.0-ga1/modules/apps/dynamic-data-mapping/dynamic-data-mapping-form-field-type/src/main/java/com/liferay/dynamic/data/mapping/form/field/type/date/internal/DateDDMFormFieldTypeSettings.java"}>
                        DateDDMFormFieldTypeSettings.java
                    </a>

                </div>




                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default ValidationCustomFieldLiferayContent;