import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class ObservableAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-observable", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Observable</b>
                    <br/>
                    <br/>

                    <b>interval</b> - Creează un observabil care emite numere secvențiale la fiecare interval de timp specificat

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {interval, Subscription} from "rxjs";'}
                    </SyntaxHighlighter>

                    Emite numere secvențiale la fiecare 2 secunde:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'  private sub: Subscription;\n' +
                            '\n' +
                            '  constructor() {\n' +
                            '    this.sub = interval(2000).subscribe(c=>{\n' +
                            '      console.log(c);\n' +
                            '    })\n' +
                            '  }\n' +
                            '  \n' +
                            '  ngOnDestroy(): void {\n' +
                            '    this.sub.unsubscribe();\n' +
                            '  }\n'}
                    </SyntaxHighlighter>

                    <br/>

                    Creare <b>Observable</b> personalizat:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {Observable} from "rxjs";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' const customInterval = new Observable(observer => {\n' +
                            '      let count = 0;\n' +
                            '      setInterval(() => {\n' +
                            '        observer.next(count);\n' +
                            '        count++\n' +
                            '      }, 2000)\n' +
                            '    });\n' +
                            '\n' +
                            '    this.sub = customInterval.subscribe((c:any) => {\n' +
                            '      console.log(c);\n' +
                            '    })'}
                    </SyntaxHighlighter>

                    Obiectul <i>observer</i> este de tip <b>Observer</b> si are metodele:
                    <ul>
                        <li><b>next</b>: trimite date</li>
                        <li><b>error</b>: trimite o eroare; daca se arunca o eroare atunci nu se mai emit date;</li>
                        <li><b>complete</b>: nu trimite date</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' const customInterval = new Observable(observer => {\n' +
                            '      let count = 0;\n' +
                            '      setInterval(() => {\n' +
                            '        observer.next(count);\n' +
                            '        count++;\n' +
                            '        if (count===2){\n' +
                            '          observer.complete(); // gata\n' +
                            '        }\n' +
                            '        if (count>3){\n' +
                            '          observer.error(new Error("prea mult!"))\n' +
                            '        }\n' +
                            '      }, 2000)\n' +
                            '    });\n' +
                            '\n' +
                            '    this.sub = customInterval.subscribe(\n' +
                            '      data => {\n' +
                            '        console.log(data);\n' +
                            '      },\n' +
                            '      error => {\n' +
                            '        console.log(error);\n' +
                            '      },\n' +
                            '      () =>{\n' +
                            '        console.log("gata");\n' +
                            '      }\n' +
                            '    )'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Operatori</b>
                    <br/>
                    <br/>
                    Tipuri de operatori:
                    <ul>
                        <li>pipeable:
                           este o funcție care ia un observabil ca intrare și returnează un alt observabil.
                            Este o operațiune pură: observabilul anterior rămâne nemodificat.

                            <br/>
                            Funcția <b>pipe()</b> ia drept argumente funcțiile pe care doriți să le combinați și returnează o nouă funcție care,
                            atunci când este executată rulează funcțiile compuse în secvență.
                        </li>
                        <li>creare:
                            este o funcție de sine stătătoare pentru a crea un nou Observabil
                            <br/>
                            De exemplu: <b>of</b>(1, 2, 3) creează un observabil care va emite 1, 2 și 3, unul imediat după altul:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'import { of, map } from \'rxjs\';\n' +
                                    '\n' +
                                    'of(1, 2, 3)\n' +
                                    '  .pipe(map((x) => x * x))\n' +
                                    '  .subscribe((v) => console.log(`value: ${v}`));\n' +
                                    '\n' +
                                    '// Logs:\n' +
                                    '// value: 1\n' +
                                    '// value: 4\n' +
                                    '// value: 9'}
                            </SyntaxHighlighter>
                            Alt operator de creatie: <b>interval()</b>
                        </li>
                    </ul>

                    Operatori <b>pipeable</b>:
                    <ul>
                        <li><b>filter()</b></li>
                        <li><b>map()</b></li>
                    </ul>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' const customInterval = new Observable(observer => {\n' +
                            '      let count = 0;\n' +
                            '      setInterval(() => {\n' +
                            '        observer.next(count);\n' +
                            '        count++\n' +
                            '      }, 1000)\n' +
                            '    });\n' +
                            '\n' +
                            '    this.sub = customInterval\n' +
                            '      .pipe(\n' +
                            '        filter(data=>{\n' +
                            '          return Number(data) % 2 === 0;\n' +
                            '        }),\n' +
                            '        map(data => {\n' +
                            '          return data+" KJ";\n' +
                            '        })\n' +
                            '      )\n' +
                            '      .subscribe(\n' +
                            '      data => {\n' +
                            '        console.log(data);\n' +
                            '      }\n' +
                            '    )'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Subject</b>
                    <br/>
                    <br/>

                    In Servicii unde se foloseste <b>EventEmitter</b>:

                    <ul>
                        <li> EventEmitter {"->"} Subject
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'bookCategoryChanged = new EventEmitter<BookCategory>();'}
                            </SyntaxHighlighter>
                            se poate inlocui cu <b>Subject</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'bookCategoryChanged = new Subject<BookCategory>();'}
                            </SyntaxHighlighter>
                        </li>

                        <li> emit() {"->"} next()
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'this.bookCategoryChanged.emit(this.bookCategory);'}
                            </SyntaxHighlighter>
                            se poate inlocui cu <b>Subject</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'this.bookCategoryChanged.next(this.bookCategory);'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { EventEmitter, Injectable } from \'@angular/core\';\n' +
                            'import { BookCategory } from \'../interfaces/book-category\';\n' +
                            'import {Subject} from "rxjs";\n' +
                            '\n' +
                            '@Injectable({ providedIn: \'root\' })\n' +
                            'export class BookService {\n' +
                            '  bookCategory?: BookCategory;\n' +
                            '\n' +
                            '  //bookCategoryChanged = new EventEmitter<BookCategory>();\n' +
                            '  bookCategoryChanged = new Subject<BookCategory>();\n' +
                            '\n' +
                            '  setBookCategory(bookCategory: BookCategory) {\n' +
                            '    this.bookCategory = bookCategory;\n' +
                            '    //this.bookCategoryChanged.emit(this.bookCategory);\n' +
                            '    this.bookCategoryChanged.next(this.bookCategory);\n' +
                            '  }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ObservableAngularContent;