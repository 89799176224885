import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class LoadbancingSessionTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-loadbalancing-session", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LoadBalancing. Gestiunea sesiunii</b>
                    <br/>
                    <br/>
                    O problema care poate apare in LoadBalancing este legata de <b>consistenta sesiunii</b>.
                    <br/>
                    O sesiune are un ID, de exemplu: 3B2222F534532432FSDFS454D.
                    <br/>
                    Cand exista un LoadBalancer, ID-ul de sesiune are urmatoarea forma: <i>3B2222F534532432FSDFS454D.tomcat1</i>.
                    <br/>
                    Doua instante de Tomcat, nu share-uiesc valorile dintr-o sesiune (nu se replica pe ambele noduri, spus cu vorbe sofisticate).
                    <br/>
                    Pentru a rezolva problema de mai sus, exista 2 abordari:
                    <ul>
                        <li>stickey sessions: daca avem un client C1, loadbalancer-ul va redirecta mereu pe acceasi instanta de Tomcat T1 (pe acelasi nod); avand sesiunea S1
                            <br/>
                            problema care apare aici este ca daca masina cu instanta de Tomcat T1 moare, atunci se sterge si sesiunea S1 si toate datele sunt pierdute;
                        </li>
                        <li>session replication: sesiunea este replicata pe toate instantele (asta are ca efect negativ, o intarziere in raspunsul unei cereri)
                            <br/>
                            sesiunea in tomcat poate fi gestionata de:
                            <ul>
                                <li><b>StandardManager</b>: se replica tot obiectul; rareori folosit, pentru ca creeaza o congestie a traficului in retea</li>
                                <li><b>PersitentManager</b>: sesiunea este salvata intr-un fisier sau intr-o baza de date; mai putin folosit</li>
                                <li><b>DeltaManager</b>: similar cu <i>StandardManager</i>; diferenta fiind ca replica doar diferentele in toate nodurile si nu tot obiectul;
                                    <br/>
                                    creaza mai putin congestie a traficului si performate mai bune
                                </li>
                                <li><b>BackupManager</b>: simular cu <i>DeltaManager</i>, dar in loc sa replice diferentele in toate nodurile, le replica in 1 sau 2 noduri</li>
                            </ul>

                        </li>
                    </ul>

                    <b>2. LoadBalancing. Gestiunea sesiunii de tip stickey cu Nginx</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>pe instanta tomcat, care reprezinta LoadBalancer-ul (<i>192.168.0.5</i> ), se editeaza fisierul <b>nginx.conf</b> din <b>[nginx]\conf</b>; se adauga <b>ip_hash;</b>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'http {\n' +
                                    '\n' +
                                    '\tupstream tomcatservers{\n' +
                                    '\t\n' +
                                    '\t\tserver 192.168.0.10:8080;\n' +
                                    '\t\tserver 192.168.0.11:8080;\n' +
                                    '\t\tip_hash;\n' +
                                    '\t\t\n' +
                                    '\t}...'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <b>3. LoadBalancing. Gestiunea sesiunii de tip stickey cu Apache HTTP</b>
                    <br/>
                    <br/>
                    Cu Apache HTTP, functioneaza in mod implicit <b>stickey sessions</b>!

                    <b>4. Routing</b>
                    <br/>
                    <br/>
                    Tipuri de comunicare in retea:
                    <ul>
                        <li>unicast: 1-to-1 {"=>"} comunicarea sa intempla intre 2 noduri; 1 (sursa) trimite informatii - 1 primeste informatii</li>
                        <li>broadcast: 1-to-Many {"=>"} 1 (sursa) trimite infomatii - toate nodurile primesc informatia</li>
                        <li>multicast: Many-to0Many {"=>"} doar nodurile care s-au subscris la un IP multicast vor primi informatii de la sursa;
                            <br/>
                            este similar cu modelul <i>producer-subscriber</i>;
                            <br/>
                            daca un nod publica o informatie, aceasta informatie este publicata la un multicast IP si nodurile subscrise (care asculta la acel IP) receptioneaza o copie a informatiei;
                            <br/>
                        </li>
                    </ul>

                    <b>5. LoadBalancing. Gestiunea sesiunii de tip replicare</b>
                    <br/>
                    <br/>

                    Pentru Nginx, se editeaza <b>[nginx]\conf\nginx.conf</b> (se sterge <b>ip_hash;</b>, comparativ cu ce e mai sus)

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'http {\n' +
                            '\n' +
                            '\tupstream tomcatservers{\n' +
                            '\t\n' +
                            '\t\tserver 192.168.0.10:8080;\n' +
                            '\t\tserver 192.168.0.10:8080;\n' +
                            '\t\t\n' +
                            '\t}...'}
                    </SyntaxHighlighter>

                    Pentru Apache, nu se face nimic.

                    Apoi, pentru fiecare instanta de Tomcat (192.168.0.10, 192.168.0.11) se editeaza fisierul <b>[tomcat]\conf\server.xml</b>.
                    Se adauga urmatoare sectiune (tip de replicare DeltaManager), in interiorul tag-ului <b>Engine</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Cluster className="org.apache.catalina.ha.tcp.SimpleTcpCluster" channelSendOptions="8">\n' +
                            '\n' +
                            '          <Manager className="org.apache.catalina.ha.session.DeltaManager"\n' +
                            '                   expireSessionsOnShutdown="false"\n' +
                            '                   notifyListenersOnReplication="true"/>\n' +
                            '\n' +
                            '          <Channel className="org.apache.catalina.tribes.group.GroupChannel">\n' +
                            '            <Membership className="org.apache.catalina.tribes.membership.McastService"\n' +
                            '                        address="228.0.0.4"\n' +
                            '                        port="45564"\n' +
                            '                        frequency="500"\n' +
                            '                        dropTime="3000"/>\n' +
                            '            <Receiver className="org.apache.catalina.tribes.transport.nio.NioReceiver"\n' +
                            '                      address="auto"\n' +
                            '                      port="4000"\n' +
                            '                      autoBind="100"\n' +
                            '                      selectorTimeout="5000"\n' +
                            '                      maxThreads="6"/>\n' +
                            '\n' +
                            '            <Sender className="org.apache.catalina.tribes.transport.ReplicationTransmitter">\n' +
                            '              <Transport className="org.apache.catalina.tribes.transport.nio.PooledParallelSender"/>\n' +
                            '            </Sender>\n' +
                            '\n' +
                            '            <Interceptor className="org.apache.catalina.tribes.group.interceptors.TcpFailureDetector"/>\n' +
                            '            <Interceptor className="org.apache.catalina.tribes.group.interceptors.MessageDispatchInterceptor"/>\n' +
                            '          </Channel>\n' +
                            '\n' +
                            '          <Valve className="org.apache.catalina.ha.tcp.ReplicationValve" filter=""/>\n' +
                            '          <Valve className="org.apache.catalina.ha.session.JvmRouteBinderValve"/>\n' +
                            '          <ClusterListener className="org.apache.catalina.ha.session.ClusterSessionListener"/>\n' +
                            ' </Cluster>'}
                    </SyntaxHighlighter>

                    Apoi, pentru fiecare instanta de Tomcat (192.168.0.10, 192.168.0.11) ,
                    in aplicatia dorita sa fie distribuita, <b>[tomcat]\webapps\[aplicatie]\WEB-INF\web.xml</b> se adauga urmatorul tag in <b>web-app</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<distributable/>'}
                    </SyntaxHighlighter>
                    In acest mod, se specifica ca aplicatia este distruibuita, iar schimbarile sesiuni se replica in tot cluster-ul.

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>
                               https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LoadbancingSessionTomcatContent;