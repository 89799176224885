import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DFlagsDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-d-flag", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker run: flag -d (detach/background)</b>

                    <br/>
                    <br/>

                    Flag-ul <b>-d</b> sau <d>--detach</d> inseamna ca containerul va rula in <i>mod detasat</i>, adica in background

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DFlagsDockerContent;