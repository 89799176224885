import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class RenderHtmlFormLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-render-html-form", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Metoda generare html randat pe baza unui <b>DDMFormInstance</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private String getRenderedForm(DDMFormInstance ddmFormInstance, RenderRequest renderRequest, RenderResponse renderResponse){\n' +
                        '\t\ttry {\n' +
                        '\t\t\tDDMStructureVersion ddmStructureVersion = ddmFormInstance.getStructure().getLatestStructureVersion();\n' +
                        '\t\t\tDDMForm ddmForm = ddmStructureVersion.getDDMForm();\n' +
                        '\t\t\tDDMFormLayout ddmFormLayout = ddmStructureVersion.getDDMFormLayout();\n' +
                        '\t\t\tDDMFormValues ddmFormValues = ddmFormValuesFactory.create(renderRequest, ddmForm);\n' +
                        '\n' +
                        '\t\t\tDDMFormRenderingContext ddmFormRenderingContext = new DDMFormRenderingContext();\n' +
                        '\t\t\tddmFormRenderingContext.setContainerId("ddmform".concat(StringUtil.randomString()));\n' +
                        '\t\t\tddmFormRenderingContext.setDDMFormValues(ddmFormValues);\n' +
                        '\t\t\tddmFormRenderingContext.setHttpServletRequest(PortalUtil.getHttpServletRequest(renderRequest));\n' +
                        '\t\t\tddmFormRenderingContext.setHttpServletResponse(PortalUtil.getHttpServletResponse(renderResponse));\n' +
                        '\t\t\tddmFormRenderingContext.setLocale(PortalUtil.getLocale(renderRequest));\n' +
                        '\t\t\tddmFormRenderingContext.setPortletNamespace(renderResponse.getNamespace());\n' +
                        '\t\t\tddmFormRenderingContext.setViewMode(true);\n' +
                        '\t\t\tddmFormRenderingContext.setReadOnly(false);\n' +
                        '\n' +
                        '\t\t\treturn ddmFormRenderer.render(ddmForm, ddmFormLayout, ddmFormRenderingContext);\n' +
                        '\n' +
                        '\t\t} catch (PortalException e) {\n' +
                        '\t\t\te.printStackTrace();\n' +
                        '\t\t}\n' +
                        '\t\treturn null;\n' +
                        '\t}'}
                    </SyntaxHighlighter>

                    Metoda obtinere <b>DDMFormInstance</b> pe baza unui nume:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'\tprivate DDMFormInstance getDDMFormInstance(String formName){\n' +
                        '\t\t// ia structura (DDMStructure) dupa nume (formName = "Prima forma")\n' +
                        '\t\tDynamicQuery dynamicQuery = ddmStructureLocalService.dynamicQuery();\n' +
                        '\t\tString find="%<Name language-id=\\"" + LocaleUtil.getDefault() + "\\">" + formName + "</Name>%";\n' +
                        '\t\tdynamicQuery.add(PropertyFactoryUtil.forName("name").like(find));\n' +
                        '\t\tList<DDMStructure> ddmStructureList = ddmStructureLocalService.dynamicQuery(dynamicQuery);\n' +
                        '\n' +
                        '\t\tDDMStructure ddmStructure = ddmStructureList.get(0);\n' +
                        '\n' +
                        '\t\t// se obtine DDMFormInstance\n' +
                        '\t\tdynamicQuery = ddmFormInstanceLocalService.dynamicQuery();\n' +
                        '\t\tdynamicQuery.add(PropertyFactoryUtil.forName("structureId").eq(ddmStructure.getStructureId()));\n' +
                        '\t\tList<DDMFormInstance> formInstances = ddmFormInstanceLocalService.dynamicQuery(dynamicQuery);\n' +
                        '\n' +
                        '\t\tDDMFormInstance ddmFormInstance = formInstances.get(0);\n' +
                        '\n' +
                        '\t\treturn ddmFormInstance;\n' +
                        '\t}'}
                    </SyntaxHighlighter>

                    Daca avem <i>ddmFormInstanceId</i>, un DDMFormInstance se poate obtine asa:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'DDMFormInstance dtmFormInstance = DDMFormInstanceLocalServiceUtil.fetchDDMFormInstance(ddmFormInstanceId)'}
                    </SyntaxHighlighter>

                    Bun. Punem String-ul html intr-un atribut al obiectului de tip <b>RenderRequest</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'renderRequest.setAttribute("html", html);'}
                    </SyntaxHighlighter>

                    In JSP:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'${html}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RenderHtmlFormLiferayContent;