import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class OptionsJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-options-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Optiuni JDL</b>
                    <br/>
                    <br/>

                    Optiunile se pot definii folosind urmatoarea sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<OPTION> <ENTITIES | * | all> [with <VALUE>] [except <ENTITIES>]'}
                    </SyntaxHighlighter>

                    unde optiunile pot fi:
                    <ul>
                        <li>
                            <b>service</b>:
                            Implicit, JHipster generează clase de resurse REST care apelează direct depozitele de entități. Aceasta este cea mai simplă opțiune,
                            dar în scenariile din lumea reală, este posibil să avem nevoie de un nivel de servicii pentru a gestiona logica de afaceri.
                            <br/>
                            Această opțiune ne permite să creăm un strat de service (<b>@Service</b>).
                            <br/>
                            Valori posibile:
                            <ul>
                                <li>
                                    serviceClass
                                </li>
                                <li>
                                    serviceImpl: creaza interfata si implementarea
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>dto</b>:
                            În mod implicit, obiectele de domeniu sunt utilizate direct în punctele finale REST care sunt create,
                            ceea ce poate să nu fie de dorit în unele situații și este posibil să doriți să utilizați un DTO intermediar pentru a avea mai mult control.
                            <br/>
                            JHipster ne permite să generăm stratul DTO folosind Mapstruct ( http://mapstruct.org/ ),
                            o bibliotecă de preprocesoare de adnotări care generează automat clasele DTO.
                            <br/>
                            Este recomandabil să utilizați un nivel de serviciu atunci când utilizați DTO. O posibilă valoare este mapstruct.
                            <br/>
                            <a target={"_blank"} href={"http://www.jhipster.tech/using-dtos/"}>http://www.jhipster.tech/using-dtos</a>
                        </li>

                        <li>
                            <b>filter</b>:
                            Această opțiune permite să activăm capabilitățile de filtrare bazate pe JPA pentru entitate.
                            Acest lucru funcționează numai atunci când este utilizat un nivel de serviciu.
                            <br/>
                            Mai multe detalii:
                            <a target={"_blank"} href={"http://www.jhipster.tech/entities-filtering"}>http://www.jhipster.tech/entities-filtering</a>
                        </li>
                        <li>
                            <b>paginate</b>:
                            Această opțiune ne permite să activăm paginarea pentru o entitate.
                            Acest lucru permite paginarea pe stratul de resurse și, de asemenea, implementează o opțiune de paginare pe partea clientului.
                            Valorile posibile sunt:
                            <ul>
                                <li>
                                    no
                                </li>
                                <li>
                                    pagination
                                </li>
                                <li>
                                    infinite-scroll
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>skipClient/ skipServer</b>: Aceste opțiuni ne permit fie să sărim codul de pe partea client, fie pe partea de server în timpul generării.
                        </li>
                        <li>
                            <b>readOnly</b>:  Aceasta creează o entitate numai pentru citire.
                        </li>
                        <li>
                            <b>noFluentMethod</b>: Aceasta ne permite să dezactivăm seterii în stil Fluent-API pentru obiectele de domeniu ale entității generate.
                        </li>
                        <li>
                            <b>angularSuffix</b>: Această opțiune ne permite să specificăm un sufix pentru folderul și numele claselor în codul de interfață.
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@service(serviceImpl)\n' +
                            'entity A\n' +
                            'entity B\n' +
                            '...\n' +
                            'entity Z\n' +
                            '\n' +
                            'dto * with mapstruct\n' +
                            'service B with serviceClass\n' +
                            'paginate * with pagination except B, C\n' +
                            'paginate B, C with infinite-scroll\n' +
                            'filter A, B'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemple</b>
                    <br/>
                    <br/>
                    exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'service * with serviceClass\n' +
                            'paginate Product, Customer, ProductOrder, Invoice, Shipment, OrderItem with pagination'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OptionsJdlJHipsterContent;