import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TimeModulePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-time-module", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Modulul time</b>
                    <br/>
                    <hr/>

                    <b>1. Clasa struct_time</b>
                    <br/>
                    <br/>
                    Modul <b>time</b> are clasa <b>struct_time</b>, cu urmatoarele atribute:
                    <ul>
                        <li>tm_year: specifică anul</li>
                        <li>tm_mon: specifică luna (valoarea de la 1 la 12)</li>
                        <li>tm_mday: specifică ziua lunii (valoare de la 1 la 31)</li>
                        <li>tm_hour: specifică ora (valoarea de la 0 la 23)</li>
                        <li>tm_min: specifică minutul (valoare de la 0 la 59)</li>
                        <li>tm_sec: specifică a doua (valoare de la 0 la 61)</li>
                        <li>tm_wday: specifică ziua săptămânii (valoare de la 0 la 6)</li>
                        <li>tm_yday: specifică ziua anului (valoarea de la 1 la 366)</li>
                        <li>tm_isdst: specifică dacă se aplică ora de vară (1 – da, 0 – nu, -1 – nu se știe)</li>
                        <li>tm_zone: specifică numele fusului orar (valoarea într-o formă prescurtată)</li>
                        <li>tm_gmtoff: specifică decalajul la est de UTC (valoarea în secunde)</li>
                    </ul>

                    Clasa <b>struct_time</b> permite accesul la valori folosind indecși.
                    Indexul 0 returnează valoarea în tm_year, în timp ce 8 returnează valoarea în tm_isdst.
                    Excepțiile sunt <b>tm_zone</b> și <b>tm_gmoff</b>, care nu pot fi accesate folosind indecși.

                    <hr/>
                    <b>2. Functii time</b>
                    <br/>
                    <br/>
                    Functii:
                    <ul>
                        <li><b>sleep(numar_secunde)</b>: suspendă execuția programului pentru un număr de secunde dat</li>
                        <li><b>ctime()</b>: convertește timpul curent într-un șir</li>
                        <li><b>ctime(timestamp)</b>: convertește timpul în secunde (timestamp) de la 1 ianuarie 1970 (epoca Unix) într-un șir

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import time\n' +
                                '\n' +
                                'timestamp = 1442810187 \n' +
                                '\n' +
                                'print(time.ctime(timestamp)) # Mon Sep 21 04:36:27 2015\n' +
                                'print(time.ctime()) # Sat Nov 27 13:59:05 2021'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>gmtime()</b>: converteste timestamp într-un obiect de tip <b>struct_time</b> în UTC (tm_isdst = 0 mereu)</li>
                        <li><b>localtime()</b>: converteste timestamp într-un obiect de tip <b>struct_time</b> ora locală

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import time\n' +
                                '\n' +
                                'timestamp = 1442810187\n' +
                                'print(time.gmtime(timestamp)) # time.struct_time(tm_year=2015, tm_mon=9, tm_mday=21, tm_hour=4, tm_min=36, tm_sec=27, tm_wday=0, tm_yday=264, tm_isdst=0)\n' +
                                'print(time.localtime(timestamp)) # time.struct_time(tm_year=2015, tm_mon=9, tm_mday=21, tm_hour=4, tm_min=36, tm_sec=27, tm_wday=0, tm_yday=264, tm_isdst=0)'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>asctime(struct_time_sau_tuplu = time.localtime() )</b>: convertește un obiect <b>struct_time</b> sau un <b>tuplu</b> într-un <b>șir</b>; fara parametrii, va fi folosită funcția <b>localtime()</b> </li>
                        <li><b>mktime(struct_time_sau_tuplu)</b>: convertește un obiect <b>struct_time</b> sau un <b>tuplu</b> (care exprimă ora locală) în <b>timestamp</b> (numărul de secunde de la epoca Unix)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'import time\n' +
                                '\n' +
                                'timestamp = 1442810187 \n' +
                                'st = time.gmtime(timestamp)\n' +
                                '\n' +
                                'print(st) # time.struct_time(tm_year=2015, tm_mon=9, tm_mday=21, tm_hour=4, tm_min=36, tm_sec=27, tm_wday=0, tm_yday=264, tm_isdst=0)\n' +
                                'print(time.asctime(st)) # Mon Sep 21 04:36:27 2015\n' +
                                'print(time.asctime()) # Sat Nov 27 14:28:24 2021\n' +
                                'print(time.mktime((2015, 9, 21, 4, 36, 27, 0, 264, 0))) # 1442810187.0\n'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <b>3. Formatare</b>
                    <br/>
                    <br/>

                    <b>3.1 Metoda strftime()</b>
                    <br/>
                    <br/>

                    Pentru formatare se poate folosi metoda <b>strftime()</b> care ia:
                    <ul>
                        <li>un argument sub forma unui șir de caractere care specifică <b>formatul</b> sub forma unor <b>directive</b></li>
                        <li>opțional, un argument  reprezentand un obiect de tipul <b>struct_time</b> sau un <b>tuplu</b> (in lipsa acestui argument se ia ora locală curentă)</li>
                    </ul>

                    O <b>directivă</b> este un șir format din caracterul % (procent) și o literă mică sau mare:
                    <ul>
                        <li>%Y – returnează anul cu secol ca număr zecimal</li>
                        <li>%y – returnează anul fara secol ca număr zecimal</li>
                        <li>%m – returnează luna ca număr zecimal cu adaugare de 0 in fata daca e nevoie (ex: 01)</li>
                        <li>%B - returnează luna ca nume complet</li>
                        <li>%d – returnează ziua ca număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                        <li>%H - returnează ora ca un număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                        <li>%M – returnează minutul ca număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                        <li>%S – returnează sedunda ca număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import time\n' +
                        '\n' +
                        'timestamp = 1442810187\n' +
                        'st = time.gmtime(timestamp)\n' +
                        '\n' +
                        'print(time.strftime("%d/%m/%Y %H:%M:%S", st)) # 21/09/2015 04:36:27\n' +
                        'print(time.strftime("%d/%m/%Y %H:%M:%S")) # 27/11/2021 15:23:43'}
                    </SyntaxHighlighter>

                    <b>3.2 Metoda strptime()</b>
                    <br/>
                    <br/>

                    Daca vrem sa convertim un sir de caractere si vrem sa il transformam intr-un obiect de tip <b>struct_time</b> se poate folosi metoda <b>strptime()</b>, care primeste ca argumente:
                    <ul>
                        <li>un <b>șir de caractere</b> care reprezintă data și ora</li>
                        <li><b>formatul</b> șirului de caractere care reprezintă data și ora</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import time\n' +
                        'print(time.strptime("07/01/1984 19:23:00", "%d/%m/%Y %H:%M:%S")) # time.struct_time(tm_year=1984, tm_mon=1, tm_mday=7, tm_hour=19, tm_min=23, tm_sec=0, tm_wday=5, tm_yday=7, tm_isdst=-1)\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TimeModulePythonContent;