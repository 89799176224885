import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class UptimeLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-uptime", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa de cand ruleaza de sistemul de operare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uptime'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {' 22:46:25 up 47 days,  1:47,  2 users,  load average: 1,13, 1,80, 1,75'}
                    </SyntaxHighlighter>

                    Pentru a afisa help-ul pentru comanda <i>uptime</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uptime --help'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Usage:\n' +
                            ' uptime [options]\n' +
                            '\n' +
                            'Options:\n' +
                            ' -p, --pretty   show uptime in pretty format\n' +
                            ' -h, --help     display this help and exit\n' +
                            ' -s, --since    system up since\n' +
                            ' -V, --version  output version information and exit\n' +
                            '\n' +
                            'For more details see uptime(1).'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'uptime -p'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'up 6 weeks, 5 days, 1 hour, 49 minutes'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UptimeLinuxContent;