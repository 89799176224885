import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CustomFileUploadAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-custom-file-upload", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Customizare File Upload</b>
                    <br/>
                    <br/>

                    In html:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<input type="file" class="file-input"  (change)="onFileSelected($event)" #fileUpload>\n' +
                            '\n' +
                            '<div class="file-upload">\n' +
                            '   <button class="btn btn-info" type="button" id="importFile" (click)="fileUpload.click()">Import date</button>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    In css:
                    <SyntaxHighlighter showLineNumbers={true} language="css" style={androidstudio}>
                        {'.file-input {\n' +
                            '  display: none;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In ts:
                    <SyntaxHighlighter showLineNumbers={true} language="css" style={androidstudio}>
                        {
                            '@Component({\n' +
                            '  selector: \'file-upload\',\n' +
                            '  templateUrl: "file-upload.component.html",\n' +
                            '  styleUrls: ["file-upload.component.scss"]\n' +
                            '})\n' +
                            'export class FileUploadComponent {\n' +
                            '\n' +
                            '    fileName = \'\';\n' +
                            '\n' +
                            '    constructor(private http: HttpClient) {}\n' +
                            '\n' +
                            '    onFileSelected(event) {\n' +
                            '\n' +
                            '        const file:File = event.target.files[0];\n' +
                            '\n' +
                            '        if (file) {\n' +
                            '\n' +
                            '            this.fileName = file.name;\n' +
                            '\n' +
                            '            const formData = new FormData();\n' +
                            '\n' +
                            '            formData.append("import-file", file);\n' +
                            '\n' +
                            '            const upload$ = this.http.post("/api/import-upload", formData);\n' +
                            '\n' +
                            '            upload$.subscribe();\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    <hr/>

                    Mai multe detalii:
                    <ul>
                        <li>
                            <a target={"_blank"} href={"https://blog.angular-university.io/angular-file-upload/"}>https://blog.angular-university.io/angular-file-upload/</a>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomFileUploadAngularContent;