import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DirectoryEntriesToCustomObjectNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-directory-entries-to-custom-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'public  <T> List<T> getNomenclatorDTOs(String nomenclator, Class<T> clazz) {\n' +
                        '    DirectoryEntries directoryEntries = sivadocIntegrationService.getDirectoryEntries(nomenclator);\n' +
                        '    List<DirectoryEntry> directoryEntryList = directoryEntries.getDirectoryEntries();\n' +
                        '\n' +
                        '    List<T> list = new ArrayList<>();\n' +
                        '    List<Field> linkedList = getFields(clazz);\n' +
                        '\n' +
                        '    List<String> fieldNames = getFieldNames(linkedList);\n' +
                        '\n' +
                        '    for(DirectoryEntry d:directoryEntryList) {\n' +
                        '\n' +
                        '        Map<String, Object> map = d.getProperties();\n' +
                        '\n' +
                        '        try {\n' +
                        '            T o = clazz.getDeclaredConstructor().newInstance();\n' +
                        '\n' +
                        '            for(String fieldName: fieldNames){\n' +
                        '                Field field = getField(linkedList,fieldName);\n' +
                        '                if (field!=null) {\n' +
                        '                    field.setAccessible(true);\n' +
                        '                    Class type = field.getType();\n' +
                        '                    if (type==String.class){\n' +
                        '                        if (map.get(fieldName)!=null) {\n' +
                        '                            field.set(o, (String) map.get(fieldName));\n' +
                        '                        }\n' +
                        '                    }\n' +
                        '                    if (type==Long.class) {\n' +
                        '                        if (map.get(fieldName)!=null) {\n' +
                        '                            field.set(o, Long.parseLong((String) map.get(fieldName)));\n' +
                        '                        }\n' +
                        '                    }\n' +
                        '                }\n' +
                        '            }\n' +
                        '\n' +
                        '            list.add(o);\n' +
                        '        } catch (InstantiationException e) {\n' +
                        '            e.printStackTrace();\n' +
                        '        } catch (IllegalAccessException e) {\n' +
                        '            e.printStackTrace();\n' +
                        '        } catch (InvocationTargetException e) {\n' +
                        '            e.printStackTrace();\n' +
                        '        } catch (NoSuchMethodException e) {\n' +
                        '            e.printStackTrace();\n' +
                        '        }\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '    return list;\n' +
                        '}\n' +
                        'private <T> List<Field> getFields(Class<T> clazz){\n' +
                        '    try {\n' +
                        '        T o = clazz.getDeclaredConstructor().newInstance();\n' +
                        '        List<Field> linkedList = new LinkedList<Field>();\n' +
                        '\n' +
                        '        return getAllFields(linkedList, o.getClass());\n' +
                        '\n' +
                        '    } catch (InstantiationException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    } catch (IllegalAccessException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    } catch (InvocationTargetException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    } catch (NoSuchMethodException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    }\n' +
                        '    return null;\n' +
                        '}\n' +
                        '\n' +
                        'private <T> List<String> getFieldNames(List<Field> linkedList){\n' +
                        '    List<String> list = new ArrayList<>();\n' +
                        '\n' +
                        '    for(Field field:linkedList){\n' +
                        '        list.add(field.getName());\n' +
                        '    }\n' +
                        '\n' +
                        '    return list;\n' +
                        '}\n' +
                        '\n' +
                        'private <T> List<String> getFieldNames(Class<T> clazz){\n' +
                        '    List<String> list = new ArrayList<>();\n' +
                        '    try {\n' +
                        '        T o = clazz.getDeclaredConstructor().newInstance();\n' +
                        '        List<Field> linkedList = new LinkedList<Field>();\n' +
                        '\n' +
                        '        linkedList = getAllFields(linkedList, o.getClass());\n' +
                        '        for(Field field:linkedList){\n' +
                        '            list.add(field.getName());\n' +
                        '        }\n' +
                        '\n' +
                        '    } catch (InstantiationException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    } catch (IllegalAccessException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    } catch (InvocationTargetException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    } catch (NoSuchMethodException e) {\n' +
                        '        e.printStackTrace();\n' +
                        '    }\n' +
                        '    return list;\n' +
                        '}\n' +
                        '\n' +
                        'private Field getField(List<Field> fields, String name) {\n' +
                        '   for(Field f:fields){\n' +
                        '       if (f.getName().equals(name)){\n' +
                        '           return f;\n' +
                        '       }\n' +
                        '   }\n' +
                        '   return null;\n' +
                        '}\n' +
                        '\n' +
                        'private List<Field> getAllFields(List<Field> fields, Class<?> type) {\n' +
                        '    fields.addAll(Arrays.asList(type.getDeclaredFields()));\n' +
                        '\n' +
                        '    if (type.getSuperclass() != null) {\n' +
                        '        getAllFields(fields, type.getSuperclass());\n' +
                        '    }\n' +
                        '\n' +
                        '    return fields;\n' +
                        '}'}
                    </SyntaxHighlighter>



                </div>

                {/*<br/>*/}
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*        <div>*/}
                {/*            <a href={"https://doc.nuxeo.com/nxdoc/acls/"}>*/}
                {/*                ACLs*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DirectoryEntriesToCustomObjectNuxeoContent;