import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ExpandoBridgeLiferayContent  extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-expando-bridge", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Pentru a seta/citi un camp custom (personalizat) programatic din Java se poate folosi <b>ExpandoBridge</b>.
                    Fiecare model cu suport pentru campuri cusotm are metoda:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public ExpandoBridge getExpandoBridge ()'}
                    </SyntaxHighlighter>
                    Aceasta metoda este mostenita de la:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'com.liferay.portal.kernel.model.ClassedModel'}
                    </SyntaxHighlighter>

                    Cu ExpandoBridge puteți:
                    <ul>
                        <li>creea un camp custom:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public void addAttribute(String name) throws PortalException'}
                            </SyntaxHighlighter>
                        </li>
                        <li>seta un camp custom:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public void setAttribute(String name, Serializable value)'}
                            </SyntaxHighlighter>
                        </li>
                        <li>citi un camp custom:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public Serializable getAttribute(String name)'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>
                    Urmatoare clasa Helper (utilitara) încapsulează logica de gestionare a câmpurilor personalizate:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class ExpandoHelper {\n' +
                        '\n' +
                        '    private static final Log _log = LogFactoryUtil.getLog(ExpandoHelper.class);\n' +
                        '    private static final boolean SECURED = false;\n' +
                        '\n' +
                        '    public static void setCustomField(ClassedModel model, String fieldName, int fieldType, Serializable fieldValue) {\n' +
                        '        try {\n' +
                        '            if (!model.getExpandoBridge().hasAttribute(fieldName)) {\n' +
                        '                model.getExpandoBridge().addAttribute(fieldName, fieldType, SECURED);\n' +
                        '            }\n' +
                        '            model.getExpandoBridge().setAttribute(fieldName, fieldValue, SECURED);\n' +
                        '        } catch (Exception e) {\n' +
                        '            _log.error("Can not set custom field, cause: " + e.getMessage());\n' +
                        '        }\n' +
                        '    }\n' +
                        '\n' +
                        '    public static Serializable getCustomFiled(ClassedModel model, String filedName, Serializable defaultValue) {\n' +
                        '        try {\n' +
                        '            return model.getExpandoBridge().getAttribute(filedName, SECURED);\n' +
                        '        } catch (Exception e) {\n' +
                        '            _log.warn("Can not get custom field, return default one: " + defaultValue);\n' +
                        '            return defaultValue;\n' +
                        '        }\n' +
                        '    }\n' +
                        '\n' +
                        '    public static String getCustomFiled(ClassedModel model, String filedName) {\n' +
                        '        Serializable customFiled = getCustomFiled(model, filedName, null);\n' +
                        '        return customFiled != null ? customFiled.toString() : null;\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>
                    Folosind clasa se mai sus, se poate :
                    <ul>
                        <li>seta un camp custom: ExpandoHelper.setCustomField(user, "limbaj", ExpandoColumnConstants.STRING, "Java")</li>
                        <li>citi un camp custom: String hobby = ExpandoHelper.getCustomFiled(user, "limbaj")</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://lifedev-solutions.blogspot.com/2019/11/custom-fields-management-with.html"}>
                                    https://lifedev-solutions.blogspot.com/2019/11/custom-fields-management-with.html
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExpandoBridgeLiferayContent;