import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StaticClassMethodPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-static-class-method", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Exista:
                    <ul>
                        <li>metode de instanta</li>
                        <li>metode de clasa:
                            <ul>
                                <li>
                                    sunt adnotate cu decoratorul <b>@classmethod</b>
                                </li>
                                <li>
                                    primul parametru al metodei clasei este <i>cls</i> (conventie), care este folosit pentru a se referi la <i>metodele clasei și atributele clasei</i>.
                                </li>
                                <li>
                                    <i>exemplu:</i>
                                        <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                            {'class BazaDeDate:\n' +
                                            '    __conexiuni = 0\n' +
                                            '\n' +
                                            '    def __init__(self ):\n' +
                                            '        BazaDeDate.__conexiuni +=1\n' +
                                            '\n' +
                                            '    @classmethod\n' +
                                            '    def getNumarConexiuni(cls):\n' +
                                            '        return cls.__conexiuni\n' +
                                            '\n' +
                                            'print(BazaDeDate.getNumarConexiuni())\n' +
                                            '\n' +
                                            'd1 = BazaDeDate()\n' +
                                            'print(BazaDeDate.getNumarConexiuni())\n' +
                                            '\n' +
                                            'd2 = BazaDeDate()\n' +
                                            'print(d2.getNumarConexiuni())'}
                                        </SyntaxHighlighter>

                                </li>
                                <li>
                                    poate fi folosită ca o modalitate alternativă de a crea obiecte:

                                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                        {'class Lebada:\n' +
                                        '    def __init__(self):\n' +
                                        '        pass\n' +
                                        '\n' +
                                        '    @classmethod\n' +
                                        '    def getInstance(cls):\n' +
                                        '        return cls()\n' +
                                        '\n' +
                                        'l1 = Lebada()\n' +
                                        'l2 = Lebada.getInstance()'}
                                    </SyntaxHighlighter>

                                </li>
                            </ul>
                        </li>

                        <li>metode statice:
                            <ul>
                                <li>
                                    sunt adnotate cu decoratorul <b>@staticmethod</b>
                                </li>
                                <li>nu necesită (și nu se așteaptă!) un parametru care să indice obiectul clasei sau clasa în sine pentru a-și executa codul (metode utilizare)</li>
                                <li>nu au capacitatea de a modifica starea obiectelor sau claselor, deoarece le lipsesc parametrii care ar permite acest lucru</li>

                                <li> <i>exemplu:</i>
                                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                        {'class Buletin:\n' +
                                        '  \n' +
                                        '    @staticmethod\n' +
                                        '    def validareCnp(cnp):\n' +
                                        '        if len(cnp) == 13:\n' +
                                        '            return True\n' +
                                        '        else:\n' +
                                        '            return False\n' +
                                        '\n' +
                                        'print(Buletin.validareCnp(\'1234567890123\')) # True'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StaticClassMethodPythonContent;