import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class MapsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-maps", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un <b>Map</b> se foloseste de o cheie pentru a identifica valorile.

                    <br/>
                    <br/>
                    Metode:
                    <ul>
                        <li><b>clear()</b>: sterge toate elementele din colectie</li>
                        <li><b>isEmpty()</b>: verifica daca colectia este goala</li>
                        <li><b>size()</b>: returneaza dimensiunea colectiei</li>
                        <li><b>V get(Object key)</b>: returneaza valoarea asociata cu cheia; daca nu exista cheia in colectie returneaza <i>null</i></li>
                        <li><b>V getOrDefault(Object key, V valoareImplicita)</b>: returneaza valoarea asociata cu cheia; daca nu exista cheia in colectie returneaza <i>valoareImplicita</i></li>
                        <li><b>V put(K key, V value)</b>: adauga/inlocuieste parechea cheie/valoare; returneaza valoare de dinainte sau <i>null</i></li>
                        <li><b>V remove(Object key)</b>: sterge si returneaza valoarea asciata cu cheia; returneaza <i>null</i> daca nu gaseste cheia in colectie</li>
                        <li><b>boolean containsKey(Object key)</b>: returneaza daca exista cheia in colectie</li>
                        <li><b>boolean containsValue(Object value)</b>: returneaza daca exista valoarea in colectie</li>
                        <li><b>{"Set<K> keySet()"}</b>: returneaza un <b>Set</b> cu toate cheile </li>
                        <li><b>{"Collection<V> values()"}</b>: returneaza un obiect de tip <b>Collection</b> cu toate valorile</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MapsJavaContent;