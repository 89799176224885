import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class CmdResumeK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-cmd-resume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Comenzi utile</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run [nume-pod] --image=[nume-imagine]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -o wide'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe pod [nume-pod]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe deployment [nume-deployment]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get nodes'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get all'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get rs'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get replicaset'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl version'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f [nume-fisier.yml]'}
                    </SyntaxHighlighter>

                    Util pentru a inregistra motiviul pentru care s-a rulat comanda (<b>kubectl rollout history</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f [nume-fisier.yml] --record'}
                    </SyntaxHighlighter>
                    (in plus se va vedea si daca se ruleaza comanda <b>kubectl describe</b> in sectiunea <b>Annotations</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f [nume-fisier.yml]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run redis --image=redis --dry-run=client -o yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run redis --image=redis --dry-run=client -o yaml > redis.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pod redis -o yaml > redis.yaml'}
                    </SyntaxHighlighter>

                    <hr/>
                    Rollout Status
                    <br/>
                    In mod implicit stategia de rollout este <b>RollingUpdate</b>.
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout status deployment.apps/myapps-deployment'}
                    </SyntaxHighlighter>
                    O alta stategie este <b>Recreate</b>:
                    <SyntaxHighlighter>
                        {' strategy:\n' +
                            '    type: Recreate      <------ If this not specified it will be RollingUpdate.'}
                    </SyntaxHighlighter>
                    Daca in urma unei actualizari, se intampla sa nu mearga ceva cum trebuie (de exmeplu, se incerca sa se foloseasca o imagine care nu exista),
                    atunci se vor folosi tot vechile imagini; nu se sterg vechile imagini pana cand nu pornesc cum trebuie cele noi;
                    <hr/>

                    Rollout History:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout history deployment.apps/myapps-deployment'}
                    </SyntaxHighlighter>

                    Rollout Undo:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout unde deployment.apps/myapps-deployment'}
                    </SyntaxHighlighter>
                    (daca avem 3 revizi: 1,2,3; dupa undo vom avea: 1,3,4; revizia 2 devine revizia 4)

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl edit deployment myapps-deployment --record'}
                    </SyntaxHighlighter>
                    Pentru a salva si iesi din editor <b>:wq!</b>
                    <br/>
                    <b>Observatie</b>: Dupa salvare si iesire din editor in mod automat se aplica schimbarile!

                    <br/>
                    <br/>
                    Echibalenta la:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl edit deployment/my-nginx'}
                    </SyntaxHighlighter>

                    este:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployment my-nginx -o yaml > /tmp/nginx.yaml\n' +
                            'vi /tmp/nginx.yaml\n' +
                            '# do some edit, and then save the file\n' +
                            '\n' +
                            'kubectl apply -f /tmp/nginx.yaml\n' +
                            '# deployment.apps/my-nginx configured\n' +
                            '\n' +
                            'rm /tmp/nginx.yaml'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set image deployment myapp-deployment nginx=nginx:1.8-perl --record'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Scalare</b>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl replace -f replicaset-definition.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl scale --replicas=6 -f replicaset-definition.yml'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl scale --replicas=6 -f replicaset myapp-replicaset'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl explain replicaset'}
                    </SyntaxHighlighter>

                    <hr/>
                    La definirea unui <b>ReplicaSet</b>, in sectiunea <b>spec</b>, <b>template</b> este obligatoriu (se defineste un pod), pentru a stii cum sa creeze pod-uri.
                    Daca exista deja podurile dorite, nu se mai creeaza altele (in momentul in care se creaaza <b>ReplicaSet</b>). <br/>
                    S-ar putea spune ca e de ajuns sa existe doar sectiunea <b>selector</b> care sa selecteze pod-urile dorite. <br/>
                    Dar, daca se sterg toate pod-urile, ReplicaSet trebuie sa stie sa creeze pod-urile dorite. Din acest modiv  <b>template</b> este obligatoriu.

                    <br/>
                    Daca vrem sa apelam un serviciu din alt namespace:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'[service-name].[namespace].[subdomeniu-servciu][domeniu]'}
                    </SyntaxHighlighter>
                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'db-service.dev.svc.cluster.local'}
                    </SyntaxHighlighter>
                    unde <i>[domeniu]=cluster.local</i>


                    <hr/>
                    Pentru a afisa pod-urile din alt namespace (in mod implicit, se afiseaza cele din <i>default</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --namespace=kube-system'}
                    </SyntaxHighlighter>
                    Exista urmatoarele namespace-uri:
                    <ul>
                        <li>default</li>
                        <li>kube-system</li>
                        <li>kube-public</li>
                    </ul>
                    Pentru a creea un pod intr-un anumit namespace:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f pod-definition.yml --namespace=dev'}
                    </SyntaxHighlighter>
                    Namespace poate fi specificat si in fisierul <i>pod-definition.yml</i>, in sectiunea <i>metadata</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'namespace: dev'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run redis --image=redis -n=[namespace]'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run redis --image=redis --namespace=[namespace]'}
                    </SyntaxHighlighter>

                    Creare namespace:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Namespace\n' +
                            'metadata:\n' +
                            '  name: dev'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create namespace dev'}
                    </SyntaxHighlighter>

                    Pentru a schimba namespace-ul implicit (de exemplu, default) la altul (de exemplu: dev):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl config set-context $(kubectl config current-context) --namespace=dev'}
                    </SyntaxHighlighter>
                    Pentru a afisa toate pod-urile din toate namespace-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods --all-namespaces'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods -A'}
                    </SyntaxHighlighter>

                    <hr/>
                    Resource Quota:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: ResourceQuota\n' +
                            'metadata: \n' +
                            '  name: compute-quota\n' +
                            '  namespace: dev\n' +
                            'spec: \n' +
                            '  hard: \n' +
                            '    pods: "10"\n' +
                            '    requests.cpu: "4"\n' +
                            '    limits.cpu: "10"\n' +
                            '    limits.memory: 10Gi'}
                    </SyntaxHighlighter>

                    <hr/>

                    Generare POD Manifest YAML file (-o yaml). Nu il creaza(--dry-run):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx --dry-run=client -o yaml'}
                    </SyntaxHighlighter>

                    Generare Deployment (-o yaml). Nu il creeeaza:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment nginx --image=nginx --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment nginx --image=nginx --dry-run=client -o yaml > nginx-deployment.yaml'}
                    </SyntaxHighlighter>

                    Generare Service:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose pod redis --port=6379 --name redis-service --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    (va folosi automat etichetele podului ca selectoare)
                    <br/>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create service clusterip redis --tcp=6379:6379 --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    (Acest lucru nu va folosi etichetele pod-urilor ca selectoare, ci va presupune selectori ca app=redis. Nu puteți trece selectoare ca opțiune.
                    Deci nu funcționează foarte bine dacă pod-ul dvs. are un set de etichete diferit. Așa că generați fișierul și modificați selectoarele înainte de a crea serviciul)

                    <br/>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose pod nginx --port=80 --name nginx-service --type=NodePort --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    <b>va folosi automat etichetele podului ca selectoare, dar nu puteți specifica portul nodului.</b>
                    <br/>
                    Trebuie să generați un fișier de definiție și apoi să adăugați manual portul nodului înainte de a crea serviciul cu podul

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create service nodeport nginx --tcp=80:80 --node-port=30080 --dry-run=client -o yaml'}
                    </SyntaxHighlighter>
                    <b>nu va folosi etichetele pod-urilor ca selectoare!</b>

                    <br/>

                    Observatii:
                    <br/>
                    Ambele comenzi de mai sus au propriile provocări.
                    În timp ce unul dintre ele nu poate accepta un selector, celălalt nu poate accepta un port de nod.
                    Aș recomanda să utilizați comanda `kubectl expose`.
                    Dacă trebuie să specificați node port generați un fișier de definiție folosind aceeași comandă și introduceți manual node port  înainte de a crea serviciul.

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run custom-nginx --image=nginx --port=8080'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run custom-nginx --image=nginx --port=8080 --expose=true'}
                    </SyntaxHighlighter>

                    <hr/>
                    Echivalente:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'FROM ubuntu\n' +
                            'ENTRYPOINT ["sleep"]\n' +
                            'CMD ["5"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata: \n' +
                            '  name: ubuntu-pod\n' +
                            'spec: \n' +
                            '  containers: \n' +
                            '    - name: ubuntu\n' +
                            '      image: ubuntu\n' +
                            '      command: ["sleep"]\n' +
                            '      args: ["5"]'}
                    </SyntaxHighlighter>

                    <hr/>
                    Editarea unui pod:
                    <br/>
                    Se edita doar urmatoarele proprietati:
                    <ul>
                        <li>spec.containers[*].image</li>
                        <li>spec.initContainers[*].image</li>
                        <li>spec.tolerations</li>
                        <li>spec.activeDeadlineSeconds</li>
                    </ul>
                    Daca se vrea editarea altor proprietati, se sterge podul si se creeaza altul.

                    <hr/>

                    Cu Deployments puteți edita cu ușurință orice câmp/proprietate a șablonului POD.
                    Deoarece șablonul pod este un copil al specificației de implementare, cu fiecare modificare
                    implementarea va șterge automat și va crea un nou pod cu noile modificări.

                    <br/>
                    Deci, pentru editata o proprietate a unei părți POD a unei implementări, se ruleaza comanda

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl edit deployment my-deployment'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl replace --force -f /tmp/kubectl-edit-43423.yaml'}
                    </SyntaxHighlighter>

                    <hr/>
                    Comenzi si Argumente:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx -- [arg1] [arg2] ... [argN]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx --command -- [arg1] [arg2] ... [argN]'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run webapp-green --image=kodekloud/webapp-color -- --color green'}
                    </SyntaxHighlighter>

                    <hr/>
                    Variabile de mediu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'env: \n' +
                            '  - name: COLOR\n' +
                            '    value: red'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'env: \n' +
                            '  - name: COLOR\n' +
                            '    valueFrom: \n' +
                            '      configMapKeyRef: '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'env: \n' +
                            '  - name: COLOR\n' +
                            '    valueFrom: \n' +
                            '      secretKeyRef: '}
                    </SyntaxHighlighter>

                    <hr/>
                    ConfigMap:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create configmap app-config --from-literal=COLOR=red'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create configmap app-config --from-file=config.properties'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: ConfigMap\n' +
                            'metadata: \n' +
                            '  name: app-config\n' +
                            'data: \n' +
                            '  COLOR: red\n' +
                            '  MODE: prod'
                        }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get configmaps'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe configmaps'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'envFrom: \n' +
                            '  - configMapRef: \n' +
                            '      name: app-config'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'env: \n' +
                            '  - name: COLOR\n' +
                            '    valueFrom: \n' +
                            '      configMapKeyRef: \n' +
                            '        name: app-config\n' +
                            '        key: COLOR'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'volumes: \n' +
                            '- name: app-config-volume\n' +
                            '  configMap:' +
                            '    name: app-config'}
                    </SyntaxHighlighter>


                    <hr/>
                    Secrete:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic app-secret --from-literal=PASS=red'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create secret generic app-secret --from-file=secrets.properties'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Secret\n' +
                            'metadata: \n' +
                            '  name: app-secreet\n' +
                            'data: \n' +
                            '  PASS: [red-in-base64]\n' +
                            '  USER: [root-in-base64]'
                        }
                    </SyntaxHighlighter>

                    Pentru a transforma un string in base64:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo -n "red" | base64'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo -n "root" | base64'}
                    </SyntaxHighlighter>
                    Pentru decodare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'echo -n "cm9vdA==" | base64 -decode'}
                    </SyntaxHighlighter>
                    (va afisa root)



                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secrets'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe secrets'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get secret app-secret -o yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'envFrom: \n' +
                            '  - secretRef: \n' +
                            '      name: app-secret'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'env: \n' +
                            '  - name: PASS\n' +
                            '    valueFrom: \n' +
                            '      secretKeyRef: \n' +
                            '        name: app-secret\n' +
                            '        key: PASS'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'volumes: \n' +
                            '- name: app-secret-volume\n' +
                            '  secret:' +
                            '    name: app-secret'}
                    </SyntaxHighlighter>

                    Secretele sunt tinute ca fisiere; pentru fiecare cheie se creaza un fisier cu numele cheii si avand ca si continut valoare.

                    <br/>
                    <br/>
                    Observatii:
                    <ul>
                        <li>secretele nu sunt criptate; doar encodate</li>
                        <li>secretele nu sunt pastrate cripatate in <b>etcd</b> in mod implicit
                            <br/>
                            trebuie activata criptarea cand e "in repaus"
                        </li>
                        <li>oricine poate creea pod-uri/ deployment-uri in aceasi namesapce poate accesa secretele;
                            <br/>
                            trebuie facute configurari pentru a reduce din privilegii - RBAC
                        </li>
                        <li>
                            trebuie luat in considerare pastrarea secretelor in diversi provider: AWS, Azure, GPC, Vault
                        </li>
                    </ul>
                    Modul in care K8s gestioneaza secretele:
                    <ul>
                        <li>
                            Un secret este trimis la un nod numai dacă un pod de pe acel nod îl solicită.
                        </li>
                        <li>
                            Kubelet stochează secretul într-un tmpfs, astfel încât secretul să nu fie scris pe disc.
                        </li>
                        <li>
                            Odată ce Pod-ul care depinde de secret este șters, kubelet va șterge și copia locală a datelor secrete.
                        </li>
                    </ul>
                    Există și alte modalități mai bune de a gestiona datele sensibile, cum ar fi parolele în Kubernetes,
                    cum ar fi utilizarea unor instrumente precum Helm Secrets, HashiCorp Vault!
                    <br/>
                    <br/>
                    Secretele codifică datele în format base64.
                    Oricine are secretul codificat base64 îl poate decoda cu ușurință. Ca atare, secretele pot fi considerate ca nu foarte sigure.
                    <br/>
                    Sunt mai sigure decât stocarea în text simplu. Secretele nu sunt criptate, deci nu este mai sigur în acest sens.
                    <br/>
                    Cele mai bune practici:
                    <ul>
                        <li>Nu se înregistrează fișierele de definiție a obiectelor secrete în depozitele de cod sursă.</li>
                        <li>Activarea criptării în repaus pentru secrete, astfel încât acestea să fie stocate criptate în ETCD. </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CmdResumeK8sContent;