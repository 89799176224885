import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ApplicationStartupEventsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-application-startup-events", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Unul dintre punctele de extensie cel mai frecvent utilizat in Liferay este evenimentul de pornire a aplicației.
                    <hr/>
                    <b>Problema: Actualizare campurile personalizate</b>
                    <br/>
                    Putem defini un eveniment pentru a ne asigura că avem câmpurile personalizate actualizate (folosind API-ul Expando de la Liferay ).
                    <br/>
                    API-ul Expando de la Liferay poate fi folosit în nenumărate moduri:
                    <ul>
                        <li>se pot adăuga câmpuri personalizate</li>
                        <li>se pot edita valorile campurilor personalizate pe diferite modele Liferay</li>
                    </ul>

                    O problema vine cu configurarea câmpurilor personalizate.
                    Când se creeaza un mediu nou sau se face upgrade la o versiune mai nouă a aplicației trebuie să vă asigurați întotdeauna că atributele personalizate necesare sunt adăugate instanței dvs. de portal.
                    <br/>

                    Acest lucru se poate:
                    <ul>
                        <li>
                            manual: dar urmărirea modificărilor și a atributelor necesare este o sarcină care este atât plictisitoare, cât și predispusă la erori.
                        </li>
                        <li>programatic</li>
                    </ul>

                    Generic:
                    <br/>
                    In fisierul de proprietati: <b>portal-ext.properties</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'application.startup.events=com.liferay.portal.events.AppStartupAction'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package com.liferay.portal.events;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.events.SimpleAction;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author Brian Wing Shun Chan\n' +
                        ' */\n' +
                        'public class AppStartupAction extends SimpleAction {\n' +
                        '\n' +
                        '\t@Override\n' +
                        '\tpublic void run(String[] ids) {\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    In mod particular, pentru problema noastra:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.liferay.vanilla.apps.events;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.events.ActionException;\n' +
                        'import com.liferay.portal.kernel.events.LifecycleAction;\n' +
                        'import com.liferay.portal.kernel.events.SimpleAction;\n' +
                        'import com.liferay.portal.kernel.log.Log;\n' +
                        'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "key=application.startup.events"\n' +
                        '        },\n' +
                        '        service = LifecycleAction.class\n' +
                        ')\n' +
                        'public class VanillaAppStartupAction extends SimpleAction {\n' +
                        '\n' +
                        '    private static Log _log = LogFactoryUtil.getLog(VanillaAppStartupAction.class);\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void run(String[] ids) throws ActionException {\n' +
                        '\n' +
                        '        System.out.println("VanillaAppStartupAction");\n' +
                        '        _log.info("VanillaAppStartupAction");\n' +
                        '\n' +
                        '        // adaugare custom field\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Fisierul <b>build.gradle</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompile group: "com.liferay.portal", name: "com.liferay.portal.kernel"\n' +
                        '\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '\tcompileOnly group: "org.osgi", name: "org.osgi.service.component.annotations", version: "1.3.0"\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Deploy:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'PS D:\\work\\liferay-vanilla\\modules\\apps\\app-events> ../../../gradlew.bat deploy\n' +
                        '\n' +
                        '> Task :modules:apps:app-events:deploy\n' +
                        'Files of project \':modules:apps:app-events\' deployed to D:\\servers\\liferay-ce-portal-7.3.6-ga7\\osgi\\modules\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 7s\n' +
                        '4 actionable tasks: 4 executed\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://liferay.dev/blogs/-/blogs/tip-of-the-day-application-startup-events"}>
                                    Tip of the Day - Application Startup Events
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://liferay.dev/blogs/-/blogs/tip-of-the-day-extend-liferay-models-with-custom-fields"}>
                                    Tip of the Day - Extend Liferay Models With Custom Fields
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ApplicationStartupEventsLiferayContent;