import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class NanoNasContent extends BaseContentPage {

    constructor(props) {
        super(props, "nas-nano", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Instalare nano pe NAS</b>:
                    <br/>
                    <br/>

                    Package Center {"->"} All Package {"->"} Settings {"->"} Package Source {"->"} Add:
                    <ul>
                        <li>
                            Name: SynoCommunity
                        </li>
                        <li>
                            Location: https://packages.synocommunity.com/
                        </li>
                    </ul>
                    Dupa asta va apare, in stanga, si sectiunea <b>Community</b>.
                    <br/>
                    Se instaleaza: <b>SynoCli File Tools</b>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://think.unblog.ch/en/how-to-install-nano-on-synology-nas/"}>
                                    HOW TO INSTALL NANO ON SYNOLOGY NAS
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NanoNasContent;