import React, {useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

const App = () => {
    const [count, setCount] = useState(0);
    const [todos, setTodos] = useState([]);

    const increment = () => {
        setCount((c) => c + 1);
    };
    const addTodo = () => {
        setTodos((t) => [...t, "New Todo"]);
    };

    return (
        <>
            <Todos todos={todos} addTodo={addTodo} />
            <hr />
            <div>
                Count: {count}
                <button onClick={increment}>+</button>
            </div>
        </>
    );
};

const Todos = ({ todos, addTodo }) => {
    console.log("child render");
    return (
        <>
            <h2>My Todos</h2>
            {todos.map((todo, index) => {
                return <p key={index}>{todo}</p>;
            })}
            <button onClick={addTodo}>Add Todo</button>
        </>
    );
};

class UseCallbackReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-usecallback", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hooks: useCallback </b>
                    <br/>
                    <br/>

                    Hook-ul <b>useCallback</b> returnează o funcție callback memorizat.
                    <br/>
                    <br/>

                    Se poate vedea ca memorarea în cache a unei valori, astfel încât să nu fie nevoie să fie recalculată.
                    <br/>
                    Acest lucru permite izolarea funcțiile care consumă mult resurse, astfel încât acestea să nu ruleze automat la fiecare randare.

                    <br/>
                    <br/>

                    Hook-ul <b>useCallback</b>  rulează numai când una dintre dependențele sale se actualizează.

                    <br/>
                    <br/>

                    Acest lucru poate îmbunătăți performanța.

                    <br/>
                    <br/>

                    Hook-ul <b>useCallback</b> si <b>useMemo</b> sunt similare.
                    Principala diferență este că <b>useMemo</b> returnează o valoare memorată și <b>useCallback</b> returnează o funcție memorată.


                    <hr/>
                    <b>Problemă</b>:
                    <br/>
                    <br/>
                    Un motiv de utilizare <b>useCallback</b> este acela de a preveni redarea unei componente cu excepția cazului în care elementele de recuzită s-au schimbat.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            'import Todos from "./Todos";\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '  const [todos, setTodos] = useState([]);\n' +
                            '\n' +
                            '  const increment = () => {\n' +
                            '    setCount((c) => c + 1);\n' +
                            '  };\n' +
                            '  const addTodo = () => {\n' +
                            '    setTodos((t) => [...t, "New Todo"]);\n' +
                            '  };\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <Todos todos={todos} addTodo={addTodo} />\n' +
                            '      <hr />\n' +
                            '      <div>\n' +
                            '        Count: {count}\n' +
                            '        <button onClick={increment}>+</button>\n' +
                            '      </div>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '};'}
                    </SyntaxHighlighter>
                    Todos.js:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { memo } from "react";\n' +
                            '\n' +
                            'const Todos = ({ todos, addTodo }) => {\n' +
                            '  console.log("child render");\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h2>My Todos</h2>\n' +
                            '      {todos.map((todo, index) => {\n' +
                            '        return <p key={index}>{todo}</p>;\n' +
                            '      })}\n' +
                            '      <button onClick={addTodo}>Add Todo</button>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '};\n' +
                            'export default memo(Todos);'}
                    </SyntaxHighlighter>

                    <App/>

                    Daca se face clic pe butonul + se observa că componenta Todos se redă din nou chiar și atunci când todos nu se schimbă.

                    <br/>
                    <br/>

                    De ce nu funcționează asta?
                    <br/>

                    Folosim memo, deci componenta Todos nu ar trebui să fie redată din nou, deoarece nici starea todos,
                    nici functia addTodo nu se schimbă atunci când numărul este incrementat.
                    <br/>
                    <br/>

                    Acest lucru se datorează a ceva numit <b>egalitate referențială</b>.
                    <br/>

                    <b style={{color:'red'}}>De fiecare dată când o componentă este redată din nou, funcțiile sale sunt recreate</b>.
                    <br/>

                    Din această cauză, functia addTodo s-a schimbat de fapt!

                    <hr/>
                    <b>Solutia</b>:
                    <br/>
                    <br/>
                    Pentru a remedia acest lucru, se foloste <b>useCallback</b> pentru a preveni recrearea funcției dacă nu este necesar.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useState, useCallback } from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            'import Todos from "./Todos";\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  const [count, setCount] = useState(0);\n' +
                            '  const [todos, setTodos] = useState([]);\n' +
                            '\n' +
                            '  const increment = () => {\n' +
                            '    setCount((c) => c + 1);\n' +
                            '  };\n' +
                            '  const addTodo = useCallback(() => {\n' +
                            '    setTodos((t) => [...t, "New Todo"]);\n' +
                            '  }, [todos]);\n' +
                            '\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <Todos todos={todos} addTodo={addTodo} />\n' +
                            '      <hr />\n' +
                            '      <div>\n' +
                            '        Count: {count}\n' +
                            '        <button onClick={increment}>+</button>\n' +
                            '      </div>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '};\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'mport { memo } from "react";\n' +
                            '\n' +
                            'const Todos = ({ todos, addTodo }) => {\n' +
                            '  console.log("child render");\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <h2>My Todos</h2>\n' +
                            '      {todos.map((todo, index) => {\n' +
                            '        return <p key={index}>{todo}</p>;\n' +
                            '      })}\n' +
                            '      <button onClick={addTodo}>Add Todo</button>\n' +
                            '    </>\n' +
                            '  );\n' +
                            '};\n' +
                            '\n' +
                            'export default memo(Todos);'}
                    </SyntaxHighlighter>

                    Acum, Todoscomponenta se va reda numai când <i>todos</i> se va schimba.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseCallbackReactContent;