import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex5ProdDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex5-prod", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 5 (complex) - PROD</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir complex'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd complex'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Modulul worker</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM node:alpine\n' +
                            'WORKDIR "/app"\n' +
                            'COPY ./package.json ./\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'CMD ["npm", "run", "start"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd ..'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Modulul server</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM node:alpine\n' +
                            'WORKDIR "/app"\n' +
                            'COPY ./package.json ./\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'CMD ["npm", "run", "start"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd ..'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Modulul client</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM node:16-alpine as builder\n' +
                            '\n' +
                            'USER node\n' +
                            '\n' +
                            'RUN mkdir -p /home/node/app\n' +
                            'WORKDIR /home/node/app\n' +
                            '\n' +
                            'COPY --chown=node:node ./package.json ./\n' +
                            '\n' +
                            'RUN npm install\n' +
                            '\n' +
                            'RUN mkdir node_modules/.cache && chmod -R 777 node_modules/.cache\n' +
                            '\n' +
                            'COPY --chown=node:node . .\n' +
                            '\n' +
                            'RUN npm run build\n' +
                            '\n' +
                            'FROM nginx\n' +
                            'EXPOSE 3000\n' +
                            'COPY ./nginx/default.conf /etc/nginx/conf.d/default.conf\n' +
                            'COPY --from=builder /home/node/app/build /usr/share/nginx/html'}
                    </SyntaxHighlighter>

                    Tot in modulul <i>client</i>, vom crea un folder <i>nginx</i> si inauntrul lui fisierul <b>default.conf</b> cu urmatorul continut

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano default.conf'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'server {\n' +
                            '  listen 3000;\n' +
                            '\n' +
                            '  location / {\n' +
                            '    root /usr/share/nginx/html;\n' +
                            '    index index.html index.htm;\n' +
                            '    try_files $uri $uri/ /index.html;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd..'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd..'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Modulul nginx</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM nginx\n' +
                            'COPY ./default.conf /etc/nginx/conf.d/default.conf'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd..'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Stergere teste</b>
                    <br/>
                    <br/>

                    Se sterg testele pentru nu a crapa build-ul.
                    <br/>
                    <br/>
                    Se editeaza fisierul <b>App.test.js</b> din <i>client/src</i>:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd client'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd src'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano App.test.js'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'import { render, screen } from \'@testing-library/react\';\n' +
                            'import App from \'./App\';\n' +
                            '\n' +
                            'test(\'renders learn react link\', () => {});\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Fisierul .travis.yml</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano .travis.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo: required\n' +
                            'language: generic\n' +
                            '\n' +
                            'services:\n' +
                            '  - docker\n' +
                            '\n' +
                            'before_install:\n' +
                            '  - docker build -t iulianbuzdugan/react-test -f ./client/Dockerfile.dev ./client\n' +
                            '\n' +
                            'script:\n' +
                            '  - docker run -e CI=true iulianbuzdugan/react-test npm test\n' +
                            '\n' +
                            'after_success:\n' +
                            '  - docker build -t iulianbuzdugan/multi-client ./client\n' +
                            '  - docker build -t iulianbuzdugan/multi-nginx ./nginx\n' +
                            '  - docker build -t iulianbuzdugan/multi-server ./server\n' +
                            '  - docker build -t iulianbuzdugan/multi-worker ./worker\n' +
                            '  # Log in to the docker CLI\n' +
                            '  - echo "$DOCKER_PASSWORD" | docker login -u "$DOCKER_ID" --password-stdin\n' +
                            '  # Take those images and push them to docker hub\n' +
                            '  - docker push iulianbuzdugan/multi-client\n' +
                            '  - docker push iulianbuzdugan/multi-nginx\n' +
                            '  - docker push iulianbuzdugan/multi-server\n' +
                            '  - docker push iulianbuzdugan/multi-worker'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Commit proiect pe GitHub</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git init'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git commit -m "initial commit" '}
                    </SyntaxHighlighter>

                    Din <b>GitHub</b> se creeaza un nou repository: <i>multi-docker</i>

                    <br/>
                    <br/>
                    Se copiaza SSH link: <i>git@github.com:letyournailsgrownow/multi-docker.git</i>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git remote add origin git@github.com:letyournailsgrownow/multi-docker.git'}
                    </SyntaxHighlighter>

                    verificare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git remote -v'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>9. Tavis</b>
                    <br/>
                    <br/>

                    Fiind logat in Tavis {"=>"} Settings {"=>"} se apasa pe butonul <b>Sync account</b>.
                    <br/>
                    Apoi <b>Manage repositories on GitHub</b>, apoi dupa ce ne logam in GitHub, din sectiunea <b>Repository access</b>,
                    selectam <b>Only select repositories</b> si selectam <i>multi-docker</i>.
                    <br/>
                    Apasam <b>Approve and install</b>

                    <br/>
                    <br/>
                    Inpoi in Travis. Fiind selectat repository-ul <i>multi-docker</i>, mergem la <b>More options</b> {"=>"} <b>Settings</b> {"=>"} sectiunea <b>Environment Variables</b>:
                    <ul>
                        <li>DOCKER_ID:iulianbuzdugan</li>
                        <li>DOCKER_PASSWORD: parola din DockerHub</li>
                    </ul>

                    La fiecare push pe master se va rula scriptul Travis si pe DockerHub se vor pune 4 imagini.

                    <hr/>
                    <b>10. GitHub Actions</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>
                            se sterge fisierul <b>.travis.yml</b>
                        </li>
                        <li>
                            in directorul radacina al proiectului, se creaza directorul <b>.github</b>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'mkdir .github'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            in directorul <b>.github</b>, se creaza directorul <b>workflows</b>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'mkdir workflows'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            in directorul <b>workflows</b>, se creaza fisierul <b>deploy.yaml</b>, cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'nano deploy.yaml'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'name: Deploy Frontend\n' +
                                    'on:\n' +
                                    '  push:\n' +
                                    '    branches:\n' +
                                    '      - master\n' +
                                    '\n' +
                                    'jobs:\n' +
                                    '  build:\n' +
                                    '    runs-on: ubuntu-latest\n' +
                                    '    steps:\n' +
                                    '      - uses: actions/checkout@v2\n' +
                                    '      - run: docker login -u ${{ secrets.DOCKER_USERNAME }} -p ${{ secrets.DOCKER_PASSWORD }}\n' +
                                    '      - run: docker build -t iulianbuzdugan/react-test -f ./client/Dockerfile.dev ./client\n' +
                                    '      - run: docker run -e CI=true iulianbuzdugan/react-test npm test\n' +
                                    '\n' +
                                    '      - run: docker build -t iulianbuzdugan/multi-client-hga ./client\n' +
                                    '      - run: docker build -t iulianbuzdugan/multi-nginx-hga ./nginx\n' +
                                    '      - run: docker build -t iulianbuzdugan/multi-server-hga ./server \n' +
                                    '      - run: docker build -t iulianbuzdugan/multi-worker-hga ./worker\n' +
                                    ' \n' +
                                    '      - run: docker push iulianbuzdugan/multi-client-hga\n' +
                                    '      - run: docker push iulianbuzdugan/multi-nginx-hga\n' +
                                    '      - run: docker push iulianbuzdugan/multi-server-hga\n' +
                                    '      - run: docker push iulianbuzdugan/multi-worker-hga'
                                }
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    In plus, trebuie specificate in starile de la GitHub, urmatoarele proprietati:
                    <ul>
                        <li>
                            <b>DOCKER_USERNAME</b> (username de pe <b>dockerhub</b>: iulianbuzdugan)
                        </li>
                        <li>
                            <b>DOCKER_PASSWORD</b> (parola de pe <b>dockerhub</b>)
                        </li>

                        din <b>Settings</b> {"=>"} <b>Secrets</b> {"=>"} <b>New repository secret</b>.

                    </ul>

                    Se adauga noul fisier pe git
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git commit -m "added github actions file"'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex5ProdDockerContent;