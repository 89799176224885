import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class EnvLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-env", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa variabile de  mediu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'env'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'SHELL=/bin/bash\n' +
                            'LC_ADDRESS=ro_RO.UTF-8\n' +
                            'LC_NAME=ro_RO.UTF-8\n' +
                            'LC_MONETARY=ro_RO.UTF-8\n' +
                            'PWD=/home/kj\n' +
                            'LOGNAME=kj'}
                    </SyntaxHighlighter>

                    Unele variabile de mediu se creeaza la logarea utilizatorului!
                    <hr/>

                    Pentru a afisa o variabila de mediu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'echo $SHELL'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'/bin/bash'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a adauga o noua variabila de mediu se foloseste comanda: <b>export</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'export MY_VAR=kj'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge variabila de mediu se foloseste comanda: <b>unset</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'unset MY_VAR'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EnvLinuxContent;