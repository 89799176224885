import {useRoutes} from "react-router-dom";
import React from "react";
import JHipsterHomeContent from "./IndexContent";
import PrivateRoute from "../../security/PrivateRoute";
import InstallJHipsterContent from "./InstallJHipsterContent";
import JdlStudioJHipsterContent from "./JdlStudioJHipsterContent";
import EntityJdlJHipsterContent from "./EntityJdlJHipsterContent";
import RelationshipJdlJHipsterContent from "./RelationshipJdlJHipsterContent";
import OptionsJdlJHipsterContent from "./OptionsJdlJHipsterContent";
import GeneratedCodeJdlJHipsterContent from "./GeneratedCodeJdlJHipsterContent";
import GeneratedCodeDomainJdlJHipsterContent from "./GeneratedCodeDomainJdlJHipsterContent";
import GeneratedCodeLiquidbaseJdlJHipsterContent from "./GeneratedCodeLiquidbaseJdlJHipsterContent";
import GeneratedCodeRepositoryJdlJHipsterContent from "./GeneratedCodeRepositoryJdlJHipsterContent";
import GeneratedCodeServiceJdlJHipsterContent from "./GeneratedCodeServiceJdlJHipsterContent";
import GeneratedCodeResourceJdlJHipsterContent from "./GeneratedCodeResourceJdlJHipsterContent";
import ModelTypeScriptJdlJHipsterContent from "./ModelTypeScriptJdlJHipsterContent";
import ExportExcelJHipsterContent from "./ExportExcelJHipsterContent";
import ImportExcelJHipsterContent from "./ImportExcelJHipsterContent";
import LdapAuthentificationHipsterContent from "./LdapAuthentificationHipsterContent";
import MultiDataSourceJHipsterContent from "./MultiDataSourceJHipsterContent";

{/*  JHipster */}
// <Route path="/jhipster/index" element={<JHipsterHomeContent/>} exact/>
// <Route element={<PrivateRoutes/>}>
//     <Route path="/jhipster/install" element={<InstallJHipsterContent/>} exact/>
//     <Route path="/jhipster/jdl-studio" element={<JdlStudioJHipsterContent/>} exact/>
//     <Route path="/jhipster/entity-jdl" element={<EntityJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/relationship-jdl" element={<RelationshipJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/options-jdl" element={<OptionsJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-jhipster-folder-jdl" element={<GeneratedCodeJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-domain-jdl" element={<GeneratedCodeDomainJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-liquidbase-jdl" element={<GeneratedCodeLiquidbaseJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-repository-jdl" element={<GeneratedCodeRepositoryJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-service-jdl" element={<GeneratedCodeServiceJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-resource-jdl" element={<GeneratedCodeResourceJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/generated-code-interfaces-jdl" element={<ModelTypeScriptJdlJHipsterContent/>} exact/>
//     <Route path="/jhipster/export-excel" element={<ExportExcelJHipsterContent/>} exact/>
//     <Route path="/jhipster/import-excel" element={<ImportExcelJHipsterContent/>} exact/>
// </Route>


export default function Router() {
    let element = useRoutes([
        {path: "/jhipster/index", element:<JHipsterHomeContent/> },
        {path: "/jhipster/install", element: <PrivateRoute element={<InstallJHipsterContent/>} />},
        {path: "/jhipster/jdl-studio", element: <PrivateRoute element={<JdlStudioJHipsterContent/>} />},
        {path: "/jhipster/entity-jdl", element: <PrivateRoute element={<EntityJdlJHipsterContent/>} />},
        {path: "/jhipster/relationship-jdl", element: <PrivateRoute element={<RelationshipJdlJHipsterContent/>} />},
        {path: "/jhipster/options-jdl", element: <PrivateRoute element={<OptionsJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-jhipster-folder-jdl", element: <PrivateRoute element={<GeneratedCodeJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-domain-jdl", element: <PrivateRoute element={<GeneratedCodeDomainJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-liquidbase-jdl", element: <PrivateRoute element={<GeneratedCodeLiquidbaseJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-repository-jdl", element: <PrivateRoute element={<GeneratedCodeRepositoryJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-service-jdl", element: <PrivateRoute element={<GeneratedCodeServiceJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-resource-jdl", element: <PrivateRoute element={<GeneratedCodeResourceJdlJHipsterContent/>} />},
        {path: "/jhipster/generated-code-interfaces-jdl", element: <PrivateRoute element={<ModelTypeScriptJdlJHipsterContent/>} />},
        {path: "/jhipster/export-excel", element: <PrivateRoute element={<ExportExcelJHipsterContent/>} />},
        {path: "/jhipster/import-excel", element: <PrivateRoute element={<ImportExcelJHipsterContent/>} />},
        {path: "/jhipster/ldap-auth", element: <PrivateRoute element={<LdapAuthentificationHipsterContent/>} />},
        {path: "/jhipster/multi-datasources", element: <PrivateRoute element={<MultiDataSourceJHipsterContent/>} />},

    ]);

    return element;
}