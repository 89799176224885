import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class InspectDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-inspect", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker inspect</b>

                    <br/>
                    <br/>

                    Afisare detalii/informatii despre un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker inspect nume_container'}
                    </SyntaxHighlighter>

                    Este echivalent cu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect nume_container'}
                    </SyntaxHighlighter>

                    Pentru filtrare informatii se foloseste optiunea <b>--format</b>.
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect --format=\'{{range .NetworkSettings.Networks}}{{.IPAddress}}{{end}}\' a3bef0b0cc6e'}
                    </SyntaxHighlighter>
                    Se poate afisa:
                    <SyntaxHighlighter>
                        {'172.23.0.3'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect --format=\'{{json .NetworkSettings.Networks}}\' a3bef0b0cc6e'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InspectDockerContent;