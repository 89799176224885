import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class PepPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-pep", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    PEP (Python Enhancement Proposals) este un docoment online, care conturează standardele limbajului Python și oferă informații despre modificări și procese legate de Python.

                    <br/>
                    <br/>
                    PEP 0 -- Index of Python Enhancement Proposals (PEPs).
                    <br/>
                    <br/>
                    Alte PEP:
                    <ul>
                        <li>PEP 1 - Scopul și orientările PEP:  oferă informații despre scopul PEP-urilor, tipurile acestora și introduce linii directoare generale</li>
                        <li>PEP 8 – Ghid de stil pentru codul Python: oferă convenții și prezintă cele mai bune practici pentru a scrie cod in Python</li>
                        <li>PEP 20 – Zenul lui Python: prezintă o listă de principii pentru proiectarea lui Python</li>
                        <li>PEP 257 – Docstring Conventions: ofera un ghid pentru convențiile și semantica asociate cu docstring-urile Python</li>
                    </ul>

                    <hr/>
                    <b>
                        1. PEP 1 – PEP Purpose and Guidelines
                    </b>
                    <br/>
                    <br/>
                    Dacă o nouă caracteristică este planificată să fie adăugată la Python, aceasta va fi detaliată într-un PEP împreună cu specificațiile tehnice și justificarea implementării acesteia.

                    <br/>
                    <br/>

                    Există trei tipuri diferite de PEP:

                    <ul>
                        <li>PEP-uri de urmărire a standardelor: descriu noile caracteristici și implementări ale limbajului</li>
                        <li>PEP-uri informaționale:  descriu problemele de design Python, precum și oferă îndrumări și informații comunității Python</li>
                        <li>Procese PEP: descriu diferite procese care gravitează în jurul Python (de exemplu, propun modificări, oferă recomandări, specifică anumite proceduri)</li>
                    </ul>

                    PEP-urile se adresează în primul rând dezvoltatorilor Python și membrilor comunității Python.
                    Acestea sunt păstrate ca fișiere text într-un repository și pot fi accesate online la: <a href={"https://www.python.org/dev/peps/"}>https://www.python.org/dev/peps/</a>

                    <br/>
                    <br/>

                    In PEP 1 – Scopul și orientările sunt descrise:
                    <ul>
                        <li>
                            formatele, șabloanele PEP și procesul de trimitere (inclusiv raportarea erorilor și trimiterea actualizărilor)
                        </li>
                        <li>etapele ulterioare: revizuire, rezoluție și întreținere</li>
                    </ul>

                    PEP 1 definește:

                    <ul>
                        <li>Consiliul director al Python: comitet format din cinci persoane și autoritățile finale care acceptă sau resping PEP</li>
                        <li>Dezvoltatorii de bază ai Python: grupul de voluntari care gestionează Python</li>
                        <li>BDFL al lui Python, adică Guido van Rossum, creatorul original al lui Python (dictator binevoitor pentru viață al proiectului până în 2018, când a demisionat din procesul de luare a deciziilor)</li>
                    </ul>

                    <hr/>
                    <b>2. PEP 20 – The Zen of Python</b>
                    <br/>
                    <br/>

                    Zenul lui Python este o colecție de 19 aforisme, care reflectă filozofia din spatele Python, principiile care il guverneaza și designul.
                    <br/>
                    <br/>

                    Tim Peters, un contribuitor major de multă vreme la limbajul de programare Python și comunitatea Python, a scris acest poem de 19 linii pe lista de corespondență Python în 1999 și a devenit intrarea #20 în Propunerile de îmbunătățire Python în 2004.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import this'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'The Zen of Python, by Tim Peters\n' +
                        '\n' +
                        'Beautiful is better than ugly.\n' +
                        'Explicit is better than implicit.\n' +
                        'Simple is better than complex.\n' +
                        'Complex is better than complicated.\n' +
                        'Flat is better than nested.\n' +
                        'Sparse is better than dense.\n' +
                        'Readability counts.\n' +
                        'Special cases aren\'t special enough to break the rules.\n' +
                        'Although practicality beats purity.\n' +
                        'Errors should never pass silently.\n' +
                        'Unless explicitly silenced.\n' +
                        'In the face of ambiguity, refuse the temptation to guess.\n' +
                        'There should be one-- and preferably only one --obvious way to do it.\n' +
                        'Although that way may not be obvious at first unless you\'re Dutch.\n' +
                        'Now is better than never.\n' +
                        'Although never is often better than *right* now.\n' +
                        'If the implementation is hard to explain, it\'s a bad idea.\n' +
                        'If the implementation is easy to explain, it may be a good idea.\n' +
                        'Namespaces are one honking great idea -- let\'s do more of those!'}
                    </SyntaxHighlighter>

                    Traducere Google Translate:
                    <SyntaxHighlighter>
                        {'Frumos este mai bun decât urât.\n' +
                        'Explicit este mai bine decât implicit.\n' +
                        'Simplu este mai bine decât complex.\n' +
                        'Complex este mai bine decât complicat.\n' +
                        'Flat este mai bine decât imbricat.\n' +
                        'Rară este mai bine decât dens.\n' +
                        'Lizibilitatea contează.\n' +
                        'Cazurile speciale nu sunt suficient de speciale pentru a încălca regulile.\n' +
                        'Deși caracterul practic bate puritatea.\n' +
                        'Erorile nu ar trebui să treacă niciodată în tăcere.\n' +
                        'Cu excepția cazului în care este redus la tăcere în mod explicit.\n' +
                        'În fața ambiguității, refuzați tentația de a ghici.\n' +
                        'Ar trebui să existe o singură modalitate evidentă de a face acest lucru.\n' +
                        'Deși acest lucru poate să nu fie evident la început decât dacă ești olandez.\n' +
                        'Acum este mai bine decât niciodată.\n' +
                        'Deși niciodată nu este adesea mai bun decât * chiar * acum.\n' +
                        'Dacă implementarea este greu de explicat, este o idee proastă.\n' +
                        'Dacă implementarea este ușor de explicat, poate fi o idee bună.\n' +
                        'Spațiile de nume sunt o idee grozavă - hai să facem mai multe dintre ele!'}
                    </SyntaxHighlighter>

                    <b>2.1. Beautiful is better than ugly</b>
                    <br/>
                    Adica:
                    <ul>
                        <li>codul trebuie sa fie lizibil sau usor de citit;
                            <br/>
                            exemple:
                            <ul>
                                <li>o lungime maximă de linie de 79 de caractere</li>
                                <li>convenții de denumire a variabilelor</li>
                                <li> plasarea declarațiilor pe linii separate</li>
                            </ul>
                        </li>
                    </ul>

                    <b>2.2. Explicit is better than implicit</b>
                    <br/>
                    Adica:
                    <ul>
                        <li>codul trebuie sa fie lizibil sau usor de citit si explicit;
                            <br/>
                            exemple:
                            <ul>
                                <li>nume explicative pentru variabile si functii</li>
                                <li>adăugarea de mai multă claritate importurilor sau argumentelor funcției</li>
                                <li>comentarii</li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu (pe ramura de nu):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from fruct import *\n' +
                        'mar(1,2)'}
                    </SyntaxHighlighter>

                    Exemplu (pe ramura de da):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from fruct import pere, mere\n' +
                        'mar(pret=1, greutate=2)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.3. Simple is better than complex</b>
                    <br/>
                    Solutia mai simpla este preferata in fata uneia complicate.
                    Dacă trebuie să implementați o soluție mai complexă, împărțiți problemele în părți mai mici, mai simple.


                    <hr/>
                    <b>2.4. Complex is better than complicated</b>
                    <br/>
                    Cand nu se poate folosi o solutie simpla, atunci trebuie utilizate solutii complexe (trebuie evitate lipsa de claritate, intelegerea gresita), spargand problema in parti separate bine definite.

                    <hr/>
                    <b>2.5. Flat is better than nested</b>
                    <br/>

                    Un cod imbricat este deficil de inteles si urmarit (maxim 2-3 niveluri de imbricare).

                    <hr/>
                    <b>2.6. Sparse is better than dense</b>
                    <br/>

                    Adica:
                    <ul>
                        <li>a nu scrie mai mult de o instructiune pe o linie</li>
                        <li>a nu scrie instructiuni prea lungi</li>
                    </ul>

                    Modalitati de a scrie cod aerisit si lizibil:
                    <ul>
                        <li>reducere imbricare</li>
                        <li>reduceți densitatea</li>
                    </ul>

                    Asa nu:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'if x == 2: print("Bau!")'}
                    </SyntaxHighlighter>

                    Asa da:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'if x == 2:\n' +
                        '   print("Bau!")'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.7. Lizibilitatea contează</b>
                    <br/>

                    "Codul este citit mai des decât este scris" (Guido Van Rossum).

                    <br/>

                    Deci trebuie date:
                    <ul>
                        <li>nume semnificative variabilelor, funcțiilor, modulelor și claselor</li>
                        <li>stilarea corectă a blocurilor de cod</li>
                        <li>utilizarea comentariilor acolo unde este necesar</li>
                        <li>păstrarea codu îngrijit și elegant </li>
                    </ul>


                    <hr/>
                    <b>2.8. Cazurile speciale nu sunt suficient de speciale pentru a încălca regulile...</b>
                    <br/>

                    Acest lucru se refera la consecvența, conformarea cu standardele și convenții (exemplu: daca toata lumea foloseste <i>self</i> ar fi bine sa se pastreze conventia).


                    <hr/>
                    <b>2.9. ...Deși, caracterul practic bate puritatea</b>
                    <br/>

                    De exemplu:
                    <ul>
                        <li>dacă trebuie să scrieți o linie lungă de cod de 85 de caractere, deoarece împărțirea lui în două linii separate afectează lizibilitatea, atunci e ok. </li>
                        <li> dacă trebuie să păstrați compatibilitatea cu codul scris anterior și să utilizați CamelCase în loc de snake_case, atunci e ok</li>
                    </ul>

                    <hr/>
                    <b>2.10. Erorile nu ar trebui să treacă niciodată în tăcere...</b>
                    <br/>

                    Daca fragment de cod poate arunca o exceptie aceasta trebuie gestionata.
                    Emiterea unei excepții vă atrage atenția asupra problemei și vă oferă informații importante despre ce s-a întâmplat și de ce.
                    Daca nu se gestioneaza cum trebuie arucarea unei erori e posibil sa se ajunga la rezultate imprevizibile, neașteptate și nedorite.
                    Un programator trebuie să se gândească la toate contextele posibile (sau cel puțin cât mai multe dintre ele) în care poate apărea o excepție si sa ofere o solutie / un remediu la erorile gestionate.

                    <hr/>
                    <b>2.11. În fața ambiguității, refuzați tentația de a ghici</b>
                    <br/>

                    Acest lucru inseamna sa ai incredere limitata in codul scris si sa fie testat (eroarile gasite tarziu sunt costisitoare si consumatoare de timp).
                    In plus, numele variabilelelor trebuie se se auto-comenteaze și trebuie lăsate comentarii acolo unde este necesar.

                    <hr/>
                    <b>2.12. Ar trebui să existe o singură modalitate evidentă de a face un lucru</b>
                    <br/>

                    Trebuie sa fim capabili să cădem de acord asupra modului cel mai bun de a atinge un anumit obiectiv:
                    <ul>
                        <li>a folosi standardele și convențiile de utilizare a limbajului</li>
                        <li>fiecare funcție, fiecare clasă, fiecare metodă - fiecare entitate - ar trebui să aibă o singură responsabilitate coerentă</li>
                    </ul>

                    <hr/>
                    <b>2.13. Acum este mai bine decât niciodată</b>
                    <br/>

                    Trebuie gasit un echilibru intre:
                    <ul>
                        <li>nu exista un lucru perfect</li>
                        <li>nu există niciodată un moment bun pentru nimic – întotdeauna există unele „dar” și „dacă” care vă spun să așteptați mai mult și să întârziați lucrurile</li>
                        <li>un program trebuie lansat când este cu adevărat gata, nu când pare gata</li>
                        <li>daca incercati sa faceti un program perfect, s-ar putea sa nu fie gata nicioadata</li>
                        <li>codul poate fi rafinat, optimizat, refactorizat, testat</li>
                    </ul>

                    <hr/>
                    <b>2.14. Dacă implementarea este greu de explicat, este o idee proastă</b>
                    <br/>

                    Ce poate fi explicat în cuvinte poate fi tradus în cod și de multe ori transformat si intr-un program. Simplitatea și minimalismul sunt cheile.
                    Chiar dacă ceva este ușor de explicat, nu înseamnă că este bun. Este mai ușor să judeci, asa ca atunci cand exista indoili, inca o pereche de ochi sau o parere poate ajuta.


                    <hr/>
                    <b>2.15. Spațiile de nume sunt o idee grozavă – hai să facem mai multe dintre ele!</b>
                    <br/>

                    Python oferă un mecanism de spațiu de nume, organizat, pentru a gestiona disponibilitatea identificatorilor pe care doriți să-i folosiți și pentru a evita conflictele cu numele deja existente.

                    <br/>
                    Un spațiu de nume, in general, este „o mapare de la nume la obiecte” implementată în Python sub forma unui dicționar.
                    Acest lucru inseamna ca ori de câte ori definiți o variabilă, Python „își amintește” două lucruri: identificatorul variabilei și valoarea pe care i-o transmiteți.

                    <br/>

                    Python le adaugă implicit la un dicționar intern care se află într-un anumit domeniu, adică regiunea unui program Python în care spațiile de nume sunt accesibile.
                    Dacă doriți să accesați acea variabilă, Python îi caută numele în dicționar și returnează valoarea care i-a fost transmisă.
                    Dacă variabila nu există și, prin urmare, nu este găsită, atunci ridică excepția NameError.

                    <br/>
                    Folosirea cuvântului cheie <b>global</b> înaintea unei variabile globale în interiorul funcției este un mecanism care vă permite să modificați acea variabilă, chiar dacă se află într-un domeniu diferit (practică proastă).

                    <br/>
                    Pentru a face codul mai clar si mai lizibil ar trebuie folosite spatiile de nume.

                    Asa da:
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'from fructe import mar\n' +
                        'mar.getCuloare()'}
                    </SyntaxHighlighter>

                    Asa nu (metoda getCuloare() e de la mar, e la par?):
                    <SyntaxHighlighter language="python" style={androidstudio}>
                        {'from fructe import mar,par\n' +
                        'getCuloare()\n' +
                        ''}
                    </SyntaxHighlighter>

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP2 | Best practices and Standardization (Professional 2/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PepPythonContent;