import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class WorkerAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-worker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Worker</b>
                    <br/>
                    <br/>

                    Serviile worker adauga capabilitati offline, avand posibilitate de configurare a datelor cache-uite.
                    <br/>
                    <br/>

                    Instalare si generare/completare aplicatie cu funtionalitate de worker:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @angular/pwa'}
                    </SyntaxHighlighter>

                    Generare aplicatie pentru prod
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng build --prod'}
                    </SyntaxHighlighter>
                    Aplicatia se va genera in directorul <b>dist</b>.

                    <br/>
                    <br/>
                    Pentru a rula aplicatia este nevoie de un server web (de exemplu, node.js).
                    <br/>
                    Pentru a instala o varianta light de node.js, putem instala <i>http-server</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install -g http-server'}
                    </SyntaxHighlighter>

                    Apoi, mergem in directorul aplicatiei:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'cd dist/nume_aplicatie'}
                    </SyntaxHighlighter>
                    Se ruleaza:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'http-server -p 8081'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WorkerAngularContent;