import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InstallGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>Instalare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'https://go.dev/doc/install'}
                    </SyntaxHighlighter>

                    Dupa instalare se poate verifica:
                    <SyntaxHighlighter>
                        {'go version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'go version go1.21.0 windows/amd64'}
                    </SyntaxHighlighter>

                    <hr/>
                    Se pot instala mai multe versiuni. De exemplu:
                    <SyntaxHighlighter>
                        {'go install golang.org/dl/go1.18.3@latest\n' +
                            'go: downloading golang.org/dl v0.0.0-20230818220345-55c644201171'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter>
                        {'go1.18.3 version'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallGoContent;