import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex3DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex3", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Exercitiul 3 (frontend)</b>
                    <br/>

                    <hr/>
                    <b>1. Rulare pe mediul de dev</b>
                    <br/>
                    <br/>

                    Sa presupunem ca avem o aplicatie <i>React</i> in directorul <i>frontend</i>.

                    <br/>
                    Vom crea urmatorul fisier <b>Dockerfile.dev</b>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM node:16-alpine\n' +
                            '\n' +
                            'WORKDIR \'/app\'\n' +
                            '\n' +
                            'COPY package.json .\n' +
                            'RUN npm install\n' +
                            '\n' +
                            'RUN mkdir node_modules/.cache && chmod -R 777 node_modules/.cache\n' +
                            'COPY . .\n' +
                            '\n' +
                            'CMD ["npm", "run", "start"]'}
                    </SyntaxHighlighter>

                    apoi, creem urmatorul fisier <b>.dockerignore</b> cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'package-lock.json\n' +
                            'node_modules\n' +
                            'yarn.lock'}
                    </SyntaxHighlighter>

                    construim imaginea folosind <b>Dockerfile.dev</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t iulianbuzdugan/frontend -f Dockerfile.dev .'}
                    </SyntaxHighlighter>
                    pormin containerul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -p 3000:3000 iulianbuzdugan/frontend'}
                    </SyntaxHighlighter>

                    se poate afisa:
                    <SyntaxHighlighter>
                        {'\n' +
                            '> frontend@0.1.0 start\n' +
                            '> react-scripts start\n' +
                            '\n' +
                            '(node:26) [DEP_WEBPACK_DEV_SERVER_ON_AFTER_SETUP_MIDDLEWARE] DeprecationWarning: \'onAfterSetupMiddleware\' option is deprecated. Please use the \'setupMiddlewares\' option.\n' +
                            '(Use `node --trace-deprecation ...` to show where the warning was created)\n' +
                            '(node:26) [DEP_WEBPACK_DEV_SERVER_ON_BEFORE_SETUP_MIDDLEWARE] DeprecationWarning: \'onBeforeSetupMiddleware\' option is deprecated. Please use the \'setupMiddlewares\' option.\n' +
                            'Starting the development server...\n' +
                            '\n' +
                            'Compiled successfully!\n' +
                            '\n' +
                            'You can now view frontend in the browser.\n' +
                            '\n' +
                            '  Local:            http://localhost:3000\n' +
                            '  On Your Network:  http://172.17.0.6:3000\n' +
                            '\n' +
                            'Note that the development build is not optimized.\n' +
                            'To create a production build, use npm run build.\n' +
                            '\n' +
                            'webpack compiled successfully\n' +
                            'Compiling...\n' +
                            'Compiled successfully!\n' +
                            'webpack compiled successfully'}
                    </SyntaxHighlighter>

                    pentru verificare vom folosi ip-ul masinii pe care este docker-ul (192.168.1.8) si nu ip-ul container-ului:
                    <br/>
                    http://192.168.1.8:3000/

                    <hr/>

                    <b>2. Optimizari</b>
                    <br/>
                    <br/>

                    De exemplu, modificam in sursa si nu vrem sa se faca build la imagine de fiecare data, la fiecare modificare pe care o facem.
                    <br/>
                    <br/>
                    Si atunci putem specifica ca anumite directoare/fisiere din container sa fie de fapt referinte catre directoare/fisiere din afara container-ului,
                    (din locul de unde se copiaza fisierele/directoarele in container); (de ex: -v $pwd:/app):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -v ${PWD}:/app -v /app/node_modules -p 3000:3000 iulianbuzdugan/frontend'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -v $(pwd):/app -v /app/node_modules -p 3000:3000 iulianbuzdugan/frontend'}
                    </SyntaxHighlighter>
                    De asemenea se pot specifica exceptii, sa nu se faca referinta (ex: -v /app/node_modules).
                    <br/>
                    Dirctorul node_modules se poate sterge de masina din afara containerului, pentru ca oricum se genereaza in container (<i>rm -r node_modules/</i>)

                    verificare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'http://192.168.1.8:3001/'}
                    </SyntaxHighlighter>
                    Apoi modificam fisierul <b>frontend/src/App.js</b> (din afara containerului) si dam refresh la browser (F5).

                    <br/>
                    <br/>
                    <b>Atentie!</b> Asa nu va functiona:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -v ${pwd}:/app -v /app/node_modules -p 3000:3000 iulianbuzdugan/frontend'}
                    </SyntaxHighlighter>
                    <hr/>
                    Pentru a simplica comanda lunga de mai sus, putem scrie un fisier <b>docker-compose.yml</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  web:\n' +
                            '    build: .\n' +
                            '    ports:\n' +
                            '      - \'3000:3000\'\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - .:/app'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>numele serviciului <i>web</i> poate fi orice (react-app, kj-app, etc)</li>
                        <li>build . {"=>"} inseamna ca imaginea se creeaza pe baza Dockerfile</li>
                    </ul>
                    dar, pentru ca noi folosim <b>Dockerfile.dev</b> nu va functiona comanda <b>docker compose up</b>.
                    <br/>
                    Asa ca modificam:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  web:\n' +
                            '    build:\n' +
                            '      context: .\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '    ports:\n' +
                            '      - \'3000:3000\'\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - .:/app\n'}
                    </SyntaxHighlighter>

                    apoi, creare imagine si container:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up'}
                    </SyntaxHighlighter>

                    apoi, pentru a verifica: http://192.168.1.8:3000/ + modificat src\App.js + refresh browser!

                    <hr/>
                    <b>3. Rulare teste</b>
                    <br/>
                    <br/>
                    <b>3.1. Abordarea 1: docker exec</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it nume_imagine npm run test'}
                    </SyntaxHighlighter>

                    rulam:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE                      COMMAND                  CREATED          STATUS          PORTS                                       NAMES\n' +
                            '62fe91c8574e   frontend-web               "docker-entrypoint.s…"   13 minutes ago   Up 12 minutes   0.0.0.0:3000->3000/tcp, :::3000->3000/tcp   frontend-web-1\n' +
                            '3173c3ca9a72   redis                      "docker-entrypoint.s…"   31 hours ago     Up 31 hours     6379/tcp                                    visits-redis-server-1\n' +
                            '8c512a800109   iulianbuzdugan/websimple   "docker-entrypoint.s…"   47 hours ago     Up 47 hours     0.0.0.0:8080->8080/tcp, :::8080->8080/tcp   practical_hermann\n' +
                            'b49aa28688b2   72dbce97252a               "redis-server"           2 days ago       Up 2 days                                                   vibrant_albattani\n' +
                            '22ad4a67809e   alpine                     "sh"                     2 days ago       Up 2 days                                                   objective_roentgen\n' +
                            '49058fc16cb6   redis                      "docker-entrypoint.s…"   3 days ago       Up 3 days       6379/tcp                                    mystifying_bhabha'}
                    </SyntaxHighlighter>
                    exemplu pentru rularea testelor:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it frontend-web npm run test'}
                    </SyntaxHighlighter>

                    pentru a putea modifica fisierul de teste si a se reflecta in container, exista 2 abordari:
                    <ul>
                        <li>
                            <i>docker run</i> cu specificarea volumelor -v
                        </li>
                        <li>
                            daca imaginea a fost creata cu <i>docker compose up</i>, atunci:

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker exec -it containe_id npm run test'}
                            </SyntaxHighlighter>

                            exemplu:
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'docker exec -it 62fe91c8574e npm run test'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <b>Abordarea 2: docker compose</b>
                    <br/>
                    <br/>
                    Modificam:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  web:\n' +
                            '    build:\n' +
                            '      context: .\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '    ports:\n' +
                            '      - \'3000:3000\'\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - .:/app\n' +
                            '  tests:\n' +
                            '    build:\n' +
                            '      context: .\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - .:/app\n' +
                            '    command: ["npm", "run", "test"]'}
                    </SyntaxHighlighter>

                    apoi:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up --build'}
                    </SyntaxHighlighter>
                    Aceasta abordare, nu permite reluare testelor (sau manipularea testelor folosind tastele: p, t, q, Enter;
                    in prima abordare se poate, dar trebuie sa stim container id).
                    <br/>
                    Daca se modifica ceva in teste, se reexecuta testele.

                    <hr/>
                    <b>4. Rulare pe mediul de productie</b>
                    <br/>
                    <br/>

                    Se creaza fisierul <b>Dockerfile</b> cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM node:16-alpine as builder\n' +
                            'WORKDIR \'/app\'\n' +
                            'COPY package.json .\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'RUN npm run build\n' +
                            '\n' +
                            'FROM nginx\n' +
                            'COPY --from=builder /app/build /usr/share/nginx/html'}
                    </SyntaxHighlighter>

                    pornim containerul pe baza imaginii <i>4799d3ec4036</i> (sa zicem ca asta e numele imaginii create):

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'docker run -p 8181:80 4799d3ec4036'}
                    </SyntaxHighlighter>

                    test:
                    <SyntaxHighlighter>
                        {'http://192.168.1.8:8181/'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex3DockerContent;