import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ServiceLiferay74Ubuntu20Content extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-service-liferay-74-ubuntu-20", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Creare fisier de serviciu</b>
                    <br/>
                    <br/>

                    Se creaza un fisier <b>liferay.service</b>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo nano /etc/systemd/system/liferay.service'}
                    </SyntaxHighlighter>

                    cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'[Unit]\n' +
                            'Description=Liferay 7.4 Service\n' +
                            'After=network.target\n' +
                            '\n' +
                            '[Service]\n' +
                            'Type=forking\n' +
                            'User=liferay   # Schimbați cu utilizatorul sub care rulează Liferay\n' +
                            'Group=liferay  # Schimbați cu grupul sub care rulează Liferay\n' +
                            'ExecStart=/opt/liferay-7.4/tomcat-9.0.17/bin/startup.sh\n' +
                            'ExecStop=/opt/liferay-7.4/tomcat-9.0.17/bin/shutdown.sh\n' +
                            'Restart=always\n' +
                            'RestartSec=30\n' +
                            '\n' +
                            '[Install]\n' +
                            'WantedBy=multi-user.target'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Verificare permisiuni</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo chmod 664 /etc/systemd/system/liferay.service'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Actualizare lista de servicii systemd</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl daemon-reload'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>4. Creare link simbolic</b>
                    <br/>
                    <br/>

                    Trebuie creat un simbolic link pentru java la <b>/usr/bin/java</b>, pentru ca pe sistemul Unix/Linux, <b>processBuilder</b> cauta java in <b>/usr/bin/java</b>:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo ln -sf /usr/lib/jvm/jdk-11/bin/java /usr/bin/java'}
                    </SyntaxHighlighter>

                    Gasire locatie java:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'which java'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'/usr/lib/jvm/jdk-11/bin/java'}
                    </SyntaxHighlighter>

                    Verificare simbolic link:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls -l /usr/bin/java'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'lrwxrwxrwx 1 root root 28 Jul 28 21:06 /usr/bin/java -> /usr/lib/jvm/jdk-11/bin/java'}
                    </SyntaxHighlighter>

                    <b>Poveste</b>
                    <br/>
                    Când se porneste <b>Liferay 7.3</b>, se creeaza 2 procese Java diferite care rulează (în timp ce în versiunile mai vechi era creat doar primul):
                    <ul>
                        <li>
                            <b>org.apache.catalina.startup.Bootstrap</b>
                        </li>
                        <li>
                            <b>com.liferay.petra.process.local.LocalProcessLauncher</b> (acesta are nevoie de <b>processBuilder</b>, care cauta <i>java</i> in <b>/usr/bin/java</b>;
                            de accea este nevoie de crearea unui link simbolic de la locatia hard/fizica la locatia soft/simbolica)
                            <br/>
                            Daca nu exista creat link-ul simbolic va crapa cu:
                            <br/>
                            <i>Cannot run program "java": error=2, No such file or directory</i>
                        </li>
                    </ul>

                    <hr/>
                    <b>5. Pornirea serviciului</b>
                    <br/>
                    <br/>

                    Pornire serviciu:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl start liferay'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Activarea serviciului</b>
                    <br/>
                    <br/>

                    Activare serviciu:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl enable liferay'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Vertificare stare serviciu</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo systemctl status liferay'}
                    </SyntaxHighlighter>

                    <hr/>
                    Trebuie ca utilizatorul/grupul sa se potriveasca cu instalarea Liferay.
                    <br/>
                    Există mai multe moduri prin care puteți afla utilizatorul sub care rulează Liferay pe un sistem Linux.
                    Iată câteva metode:
                    <ul>
                        <li>
                            comanda <b>ps</b>:
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ps aux | grep liferay'}
                            </SyntaxHighlighter>
                            va afișa toate procesele care conțin cuvântul "liferay".
                            Utilizatorul sub care rulează Liferay va fi afișat în coloana "USER"
                        </li>
                        <li>
                            comanda <b>pgrep</b>:
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'pgrep liferay'}
                            </SyntaxHighlighter>
                            cu aceasta comanda puteți obține ID-urile proceselor Liferay și apoi utilizați comanda ps pentru a afișa informații detaliate despre aceste procese, inclusiv utilizatorul.
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ps -p <PID> -o user'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            comanda <b>ps -U</b>:
                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'ps -U <username>'}
                            </SyntaxHighlighter>
                            pentru a afla toate procesele asociate cu un anumit utilizator/username
                        </li>
                    </ul>

                    Pentru a afla in ce grup de gaseste un utilizator:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'groups <username>'}
                    </SyntaxHighlighter>

                    Pentru a afla grupurile propriului utilizator:
                    <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'groups'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*       <li>*/}
                {/*           <a target={"_blank"} href={"https://linuxhint.com/install_oracle_jdk11_ubuntu/"}>*/}
                {/*               Install Oracle JDK 11 on Ubuntu*/}
                {/*           </a>*/}
                {/*       </li>*/}

                {/*        <li>*/}
                {/*            <a target={"_blank"} href={"https://computingforgeeks.com/how-to-install-java-11-on-ubuntu-linux/"}>*/}
                {/*                Install Java 11 on Ubuntu 22.04*/}
                {/*            </a>*/}
                {/*        </li>*/}

                {/*        <li>*/}
                {/*            <a target={"_blank"} href={"https://www.cherryservers.com/blog/how-to-install-java-on-ubuntu"}>*/}
                {/*                How to Install Java on Ubuntu 22.04 | Step-by-Step Tutorial*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceLiferay74Ubuntu20Content;