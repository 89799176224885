import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class HardSoftLinksLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-hard-soft-links", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Cand se sterge unul dintre link-urile <b>hard</b>, celelalte vor pointa catre <b>inode</b>-ul specific (fisier sau director).
                    <br/>
                    Link-urile <b>hard</b> au acceasi dimensiune si bat in acelasi <b>inode</b>.
                    <br/>
                    <br/>
                    Daca se sterge destinatia unui link <b>soft</b>, la accesarea <i>scurtaturii</i> se va optiune o eroare: <i>No such file or directory</i>.
                    <br/>

                    Pentru a afisa detalii legate de <b>inode</b>, pentru a decoperii <b>hard link</b>-uri, se foloseste flag-ul <b>i</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -li'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a creea un soft link:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ln -s nume-fisier nume-fisier-soft-link'}
                    </SyntaxHighlighter>

                    Pentru a creea un hard link:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ln nume-fisier nume-fisier-soft-link'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HardSoftLinksLinuxContent;