import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NodeAffinityK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-node-affinity", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Node Affinity (afinitate catre un nod pe baza de eticheta)</b>
                    <br/>
                    <br/>

                    Un nod master poate avea etichete (cheie-valoare). Si se pot adauga noi etichete (de exemplu: type = k8s-workload).
                    Pe baza acestei etichete, un pod poate fi programat pe acel nod sau nu.
                    <br/>
                    <br/>

                    Pentru a eticheta un nod (pentru ca trebuie etichetat inainte sa fie creat pod-ul):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes <nume-nod> <cheie>=<valoare>'}
                    </SyntaxHighlighter>

                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl label nodes node-kj size=Large'}
                    </SyntaxHighlighter>

                    <hr/>

                    Comparativ cu <b>nodeSelector</b>, folosind <b>affinity/nodeAffinity</b> se pot folosi expresii mai complicate.

                    <hr/>
                    <b>Node Affinity</b> poate fi:
                    <ul>
                        <li>hard {"=>"} requiredDuringSchedulingIgnoredDuringExecution:
                            <ul>
                                <li>incerca sa programeze pod-ul pe un nod care indeplineste conditiile, dar daca nu exista un astfel de nod, va ajunge in starea <i>pending</i></li>
                                <li>
                                    (daca se modifica specificatiile nodului, nu afecteaza pod-urile nodurile care deja exista pe acel nod, chiar daca nu mai respecta conditiile)
                                </li>
                            </ul>
                        </li>
                        <li>soft {"=>"} preferredDuringSchedulingIgnoredDuringExecution
                            <ul>
                                <li>incerca sa programeze pod-ul pe un nod care indeplineste conditiile, dar daca nu exista un astfel de nod, oricum il programeaza</li>
                                <li>
                                    (daca se modifica specificatiile nodului, nu afecteaza pod-urile nodurile care deja exista pe acel nod, chiar daca nu mai respecta conditiile)
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu de folosire <b>affinity</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: busybox-manager # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  affinity:\n' +
                            '    nodeAffinity:\n' +
                            '      requiredDuringSchedulingIgnoredDuringExecution\n' +
                            '        nodeSelectorTerms:\n' +
                            '        - matchExpressions:\n' +
                            '          - key: size\n' +
                            '            operator: In\n' +
                            '            values:\n' +
                            '            - Large\n' +
                            '            - Medium\n' +
                            '  containers:\n' +
                            '  - name: busybox-container\n' +
                            '    image: busybox\n' +
                            ''}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: busybox-manager # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  affinity:\n' +
                            '    nodeAffinity:\n' +
                            '      requiredDuringSchedulingIgnoredDuringExecution\n' +
                            '        nodeSelectorTerms:\n' +
                            '        - matchExpressions:\n' +
                            '          - key: size\n' +
                            '            operator: NotIn\n' +
                            '            values:\n' +
                            '            - Small\n' +
                            '  containers:\n' +
                            '  - name: busybox-container\n' +
                            '    image: busybox\n' +
                            ''}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: busybox-manager # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  affinity:\n' +
                            '    nodeAffinity:\n' +
                            '      requiredDuringSchedulingIgnoredDuringExecution\n' +
                            '        nodeSelectorTerms:\n' +
                            '        - matchExpressions:\n' +
                            '          - key: size\n' +
                            '            operator: Exists\n' +
                            '  containers:\n' +
                            '  - name: busybox-container\n' +
                            '    image: busybox\n' +
                            ''}
                    </SyntaxHighlighter>

                    Alt exemplu (se poate pune doar pe nodul <i>control-plane</i> daca exista):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: red\n' +
                            'spec:\n' +
                            '  replicas: 2\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      run: nginx\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        run: nginx\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '      - image: nginx\n' +
                            '        imagePullPolicy: Always\n' +
                            '        name: nginx\n' +
                            '      affinity:\n' +
                            '        nodeAffinity:\n' +
                            '          requiredDuringSchedulingIgnoredDuringExecution:\n' +
                            '            nodeSelectorTerms:\n' +
                            '            - matchExpressions:\n' +
                            '              - key: node-role.kubernetes.io/control-plane\n' +
                            '                operator: Exists'}
                    </SyntaxHighlighter>

                    Operatori:
                    <ul>
                        <li>In</li>
                        <li>NotIn</li>
                        <li>Exists</li>
                    </ul>

                    <hr/>

                    Alt exemplu de folosire <b>affinity</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: busybox-manager # nume pod\n' +
                            '\n' +
                            'spec:\n' +
                            '  affinity:\n' +
                            '    nodeAffinity:\n' +
                            '      requiredDuringSchedulingIgnoredDuringExecution\n' +
                            '        nodeSelectorTerms:\n' +
                            '        - matchExpressions:\n' +
                            '          - key: type\n' +
                            '            operator: In\n' +
                            '            values:\n' +
                            '            - k8s-workload\n' +
                            '  containers:\n' +
                            '  - name: busybox-container\n' +
                            '    image: busybox\n' +
                            ''}
                    </SyntaxHighlighter>
                    Podul va putea fi programat:
                    <ul>
                        <li>
                            doar pe noduri cu eticheta <i>type = k8s-workload</i>,
                            <br/>
                            daca <i>node affinity</i> este <i>prefferedDuringSchedulingIgnoringDuringExecution</i>; daca nu exista, il pune in pending
                        </li>
                        <li>
                            pe un nod care indeplineste conditiile, dar daca nu exista un astfel de nod, oricum il programeaza,
                            <br/>
                            daca <i>node affinity</i> este <i>preferredDuringSchedulingIgnoredDuringExecution</i>,
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NodeAffinityK8sContent;