import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"rpi-root", url:"#", title:"Raspberry Pi", subtitle:""},
        {id:"rpi-audio", url:"/rpi/audio", title:"Audio", subtitle:"", parent:"rpi-root"},
        {id:"rpi-speech-recognition", url:"/rpi/speech-recognition", title:"STT: SpeechRecognition in Python", subtitle:"", parent:"rpi-root"},
        {id:"rpi-fast-whisper", url:"/rpi/fast-whisper", title:"STT: fast-whisper in Python", subtitle:"", parent:"rpi-root"},

        {id:"rpi-util", url:"/rpi/util", title:"Util", subtitle:"", parent:"rpi-root"},

    ]

    static indexUrl = "/llm/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Raspberry PI
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;