import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class EmailVerificationAuthLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-email-verification-auth", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare email</b>
                    <br/>
                    <br/>

                    Configurarile de mail se gasesc in fisierul <b>config/mail.php</b>.
                    <br/>
                    In fisierul de configurare <b>.env</b>, se definesc variabile de mediu (folosite in <b>config/mail.php</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'MAIL_MAILER=smtp\n' +
                            'MAIL_HOST=mai.zzz.ro\n' +
                            'MAIL_PORT=465\n' +
                            'MAIL_USERNAME=inregistrare@zzz.ro\n' +
                            'MAIL_PASSWORD==zz!R)ohBjr!i\n' +
                            'MAIL_ENCRYPTION=ssl\n' +
                            'MAIL_FROM_ADDRESS=inregistrare@zzz.ro\n' +
                            'MAIL_FROM_NAME="${APP_NAME}"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Activare Email Verification in Model</b>
                    <br/>
                    <br/>
                    Pentru a activa <i>Email Verification</i>, trebuie ca modul <b>app/Models/User.php</b> sa implementeze:
                    <br/>
                    <b>Illuminate\Contracts\Auth\MustVerifyEmail</b>

                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'class User extends Authenticatable implements MustVerifyEmail\n' +
                            '{\n' +
                            '    use HasApiTokens, HasFactory, Notifiable;'}
                    </SyntaxHighlighter>



                    <hr/>
                    <b>3. Personalizare continut email</b>
                    <br/>
                    <br/>

                    Lavarel permite modificarea continutului pentru email-ul de verificare.
                    In <b>App\Providers\AuthServiceProvider</b> este nevoie ca in metoda <b>boot()</b> sa apelam metoda <b>toMailUsing()</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {' VerifyEmail::toMailUsing(function ($notifiable, $url) {\n' +
                            '            return (new MailMessage())\n' +
                            '                ->subject(\'Verificare Adresa de Email\')\n' +
                            '                ->markdown(\'emails.verify\',\n' +
                            '                    [\n' +
                            '                        \'notifiable\' => $notifiable,\n' +
                            '                        \'url\' => $url\n' +
                            '                    ]\n' +
                            '                );\n' +
                            '        });'}
                    </SyntaxHighlighter>

                    In <b>/resources/views</b> se va crea directorul <b>emails</b> si in acesta fisierul <b>verify.blade.php</b> cu urmatorul contintut:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'@component(\'mail::message\')\n' +
                            '# Salut {{ $notifiable->name }}\n' +
                            '\n' +
                            'Vă rugăm să faceți clic pe butonul de mai jos pentru a vă verifica adresa de e-mail.\n' +
                            '\n' +
                            '@component(\'mail::button\', [\'url\' => $url])\n' +
                            'Verificare adresa de email\n' +
                            '@endcomponent\n' +
                            '\n' +
                            'Dacă nu ați creat un cont, nu este necesară nicio acțiune suplimentară.\n' +
                            '\n' +
                            'Cu respect,\n' +
                            '{{ config(\'app.name\') }}\n' +
                            '\n' +
                            '@component(\'mail::subcopy\')\n' +
                            'Dacă întâmpinați probleme la a face clic pe butonul "Verificare adresa de email" copiați și inserați adresa URL de mai jos în browserul dvs.: {{ $url }}\n' +
                            '@endcomponent\n' +
                            '\n' +
                            '@endcomponent'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EmailVerificationAuthLaravelContent;