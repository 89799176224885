import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CreateRepositoryGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-create-repository", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Comanda: git init</b>
                    <br/>
                    <br/>

                    Cream un director care va reprezenta directorul proiectului <i>git-vanilla</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir git-vanilla'}
                    </SyntaxHighlighter>

                    Schimbam directorul curent astfel incat sa fie nou directorul creat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd git-vanilla'}
                    </SyntaxHighlighter>

                    Pentru ca acest director sa fie gestionat de GIT, executam comanda <b>git init</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git init'}
                    </SyntaxHighlighter>

                    Rezultatul actiunii de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Initialized empty Git repository in D:/work/git-vanilla/.git/'}
                    </SyntaxHighlighter>
                    Se creeaza un director <b>.git</b>.

                    <hr/>

                    Acum, putem sa va legati la un repository extern (de exemplu Bitbucket):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add origin https://letyournailsgrow@bitbucket.org/letyournailsgrow/liferay-react.git\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push -u origin master'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Shaumik Daityari, Jump Start GIT, Sitepoint, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateRepositoryGitContent;