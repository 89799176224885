import React, {useEffect, useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

const Parent = () => {

    let [bookCategory, setBookCategory] = useState("IT");

    useEffect(() => {
        const timer = setTimeout(() => {
            setBookCategory("IT:"+ new Date().toString())
        }, 1000);
        return () => clearTimeout(timer);
    }, [bookCategory]);

    return (
        <Child category={bookCategory} />
    )
}

const Child = ({category}) => {
    console.log("render");
    return (
        <div>{category}</div>
    );
};

class UpdateParentChildAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-update-parent-child", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Anuntare copil in urma unui eveniment al parintelui</b>
                    <br/>
                    <br/>

                    In clasa printe exista obiectul care se dorecte trimis copilului, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'bookCategory: BookCategory = {\n' +
                            '    name: \'IT\',\n' +
                            '};'}
                    </SyntaxHighlighter>
                    In template Html clasei parinte:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'<child [category]="bookCategory"></child>'}
                    </SyntaxHighlighter>
                    (in react:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'<child category="{bookCategory}"></child>'}
                    </SyntaxHighlighter>
                    )
                    <b>Observatie</b>:
                    <ul>
                        <li>daca vrem sa trimite doar un string putem sa scriem (fara paranteze []):
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'<child numeCategorie="KJ-CATEGORY"></child>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    In clasa copil exista un obiect de tip adnotat cu <b>@Input()</b>
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Input()\n' +
                            'category: BookCategory = {};'}
                    </SyntaxHighlighter>
                    Iar <i>category</i> va putea fi folosit in template-ul Html clasei copil.

                    <hr/>
                    Daca in template Html clasei parinte, vrem sa scriem:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'<child [myCategory]="bookCategory"></child>'}
                    </SyntaxHighlighter>
                    atunci putem sa ne folosim de <b>alias</b> (nume in interiorul directivei <b>@Input</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@Input("myCategory")\n' +
                            'category: BookCategory = {};'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>nu se recomanda folosirea alias-urilor pentru @Input</li>
                    </ul>

                    Pentru a anunta copilul ca s-a modificat obiectul trimis initial copilului, se foloseste metoda <b>ngOnChanges()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'ngOnChanges(changes: SimpleChanges) {\n' +
                            '  alert(changes.searchCategory);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Componenta trebuie sa implementeze interfata <b>OnChanges</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'interface OnChanges {\n' +
                            '  ngOnChanges(changes: SimpleChanges): void\n' +
                            '}'}
                    </SyntaxHighlighter>


                    <hr/>
                    In React:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, {useState} from "react";\n\n' +
                            'const Parent = () => {\n' +
                            '\n' +
                            '    let [bookCategory, setBookCategory] = useState("IT");\n'+
                            '\n' +
                            '    return (\n' +
                            '        <Child category={bookCategory} />\n' +
                            '    )\n' +
                            '}\n' +
                            '\n' +
                            'const Child = ({category}) => {\n' +
                            '    return (\n' +
                            '        <div>{category}</div>\n' +
                            '    );\n' +
                            '};\n'}
                    </SyntaxHighlighter>

                    Sa prespunem ca parintele modifica in mod constant <i>bookCategory</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const Parent = () => {\n' +
                            '\n' +
                            '    let [bookCategory, setBookCategory] = useState("IT");\n' +
                            '\n' +
                            '    useEffect(() => {\n' +
                            '        const timer = setTimeout(() => {\n' +
                            '            setBookCategory("IT:"+ new Date().toString())\n' +
                            '        }, 1000);\n' +
                            '        return () => clearTimeout(timer);\n' +
                            '    }, [bookCategory]);\n' +
                            '\n' +
                            '    return (\n' +
                            '        <Child category={bookCategory} />\n' +
                            '    )\n' +
                            '}\n' +
                            '\n' +
                            'const Child = ({category}) => {\n' +
                            '    return (\n' +
                            '        <div>{category}</div>\n' +
                            '    );\n' +
                            '};'}
                    </SyntaxHighlighter>

                    <b>useEffect</b> e un hook care se apleaza, in acest caz, la crearea componentei si de fiecare data cand se modifica bookCategory
                    (la fiecare secunda); ceea ce determina randarea componentei si a copiilor lui.
                    <br/>
                    Componentele React se <i>refac</i> (fac render) pe ei înșiși și pe toți copiii lor atunci când starea este actualizată

                    <br/>
                    <br/>

                    Rezultatul rularii exemplului de mai sus:
                    <Parent/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UpdateParentChildAngularContent;