import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DialogsJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-dialogs", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Casetele de dialog sunt părți integrante ale browserelor web si sunt ferestre modale (când caseta de dialog este afișată, nu este posibil să interacționați cu pagina web în sine până când această casetă de dialog este închisă).
                    <br/>
                    Tipuri de casete dialog:
                    <ul>
                        <li><b>alert(mesaj)</b> / <b>window.alert(mesaj)</b>:
                            <br/>
                            permite afisare unui mesaj (observatie: alert("text 1", "text 2"); {"=>"} va afisa doar "text 1")</li>
                        <li><b>confirm(mesaj)</b> / <b>window.confirm(mesaj)</b>:
                            <br/>
                            afisaza un mesaj optional si 2 butoane: OK si Cancel;
                            <br/>
                            caseta de confirmare returnează true pentru apasarea butonului OK, si false in caz contrar</li>
                        <li><b>prompt(mesaj, valoareImplicita)</b> / <b>window.prompt(mesaj, valoareImplicita)</b>:
                            <br/>
                            afiseaza un <i>mesaj</i> optional, un input box care poate avea valoarea (optional) <i>valoareImplicita</i> si 2 butoane: OK și Cancel;
                            <br/>
                            caseta de prompt returnează sirul de caractere scris in input box, daca se apasa pe butonul OK; si valoarea <i>null</i> in caz contar
                        </li>

                    </ul>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DialogsJavaScriptContent;