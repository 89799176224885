import {useRoutes} from "react-router-dom";
import React from "react";
import HelloWorldRustContent from "./HelloWorldRustContent";
import IndexContent from "../rust/IndexContent";
import VariablesRustContent from "./VariablesRustContent";
import ModuleRustContent from "./ModuleRustContent";
import FunctionsRustContent from "./FunctionsRustContent";
import TypesRustContent from "./TypesRustContent";
import ControlFlowRustContent from "./ControlFlowRustContent";
import StringsRustContent from "./StringsRustContent";
import OwnershipRustContent from "./OwnershipRustContent";
import StructsRustContent from "./StructsRustContent";
import TraitsRustContent from "./TraitsRustContent";
import CollectionsRustContent from "./CollectionsRustContent";
import EnumsRustContent from "./EnumsRustContent";
import ClosuresRustContent from "./ClosuresRustContent";
import ThreadsRustContent from "./ThreadsRustContent";

export default function Router() {
    let element = useRoutes([

        {path: "/rust/index", element: <IndexContent/>},
        {path: "/rust/hello-world", element: <HelloWorldRustContent/>},
        {path: "/rust/variables", element: <VariablesRustContent/>},
        {path: "/rust/functions", element: <FunctionsRustContent/>},
        {path: "/rust/module", element: <ModuleRustContent/>},
        {path: "/rust/types", element: <TypesRustContent/>},
        {path: "/rust/control-flow", element: <ControlFlowRustContent/>},
        {path: "/rust/strings", element: <StringsRustContent/>},
        {path: "/rust/ownership", element: <OwnershipRustContent/>},
        {path: "/rust/structs", element: <StructsRustContent/>},
        {path: "/rust/traits", element: <TraitsRustContent/>},
        {path: "/rust/collections", element: <CollectionsRustContent/>},
        {path: "/rust/enums", element: <EnumsRustContent/>},
        {path: "/rust/closures", element: <ClosuresRustContent/>},
        {path: "/rust/threads", element: <ThreadsRustContent/>},

    ]);

    return element;
}