import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavaPJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-scripting", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>
                        1. Javap
                    </b>
                    <br/>
                    <br/>

                    Fie clasa:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloWorld { \n' +
                            '    public static void main(String[] args) { \n' +
                            '        System.out.println("Hello, World!");\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap HelloWorld.class'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Compiled from "HelloWorld.java"\n' +
                            'public class HelloWorld {\n' +
                            '  public HelloWorld();\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>javap -s</b>
                    <br/>
                    <br/>
                    Afiseaza signaturile de tip intern:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap -s HelloWorld.class'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Compiled from "HelloWorld.java"\n' +
                            'public class HelloWorld {\n' +
                            '  public HelloWorld();\n' +
                            '    descriptor: ()V\n' +
                            '\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    descriptor: ([Ljava/lang/String;)V\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>javap -c</b>
                    <br/>
                    <br/>
                    Afiseaza codul codul dezasamblat:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap -c HelloWorld.class'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Compiled from "HelloWorld.java"\n' +
                            'public class HelloWorld {\n' +
                            '  public HelloWorld();\n' +
                            '    Code:\n' +
                            '       0: aload_0\n' +
                            '       1: invokespecial #1                  // Method java/lang/Object."<init>":()V\n' +
                            '       4: return\n' +
                            '\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    Code:\n' +
                            '       0: getstatic     #7                  // Field java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '       3: ldc           #13                 // String Hello, World!\n' +
                            '       5: invokevirtual #15                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '       8: return\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>javap -v</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap -v HelloWorld.class'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Classfile /D:/work-resources/learn/java/HelloWorld.class\n' +
                            '  Last modified 13 Jun 2023; size 427 bytes\n' +
                            '  SHA-256 checksum e48463237cb426b0f9ef6df68fd6b355e5473e6abd424bc61c7cdbda36884b25\n' +
                            '  Compiled from "HelloWorld.java"\n' +
                            'public class HelloWorld\n' +
                            '  minor version: 0\n' +
                            '  major version: 61\n' +
                            '  flags: (0x0021) ACC_PUBLIC, ACC_SUPER\n' +
                            '  this_class: #21                         // HelloWorld\n' +
                            '  super_class: #2                         // java/lang/Object\n' +
                            '  interfaces: 0, fields: 0, methods: 2, attributes: 1\n' +
                            'Constant pool:\n' +
                            '   #1 = Methodref          #2.#3          // java/lang/Object."<init>":()V\n' +
                            '   #2 = Class              #4             // java/lang/Object\n' +
                            '   #3 = NameAndType        #5:#6          // "<init>":()V\n' +
                            '   #4 = Utf8               java/lang/Object\n' +
                            '   #5 = Utf8               <init>\n' +
                            '   #6 = Utf8               ()V\n' +
                            '   #7 = Fieldref           #8.#9          // java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '   #8 = Class              #10            // java/lang/System\n' +
                            '   #9 = NameAndType        #11:#12        // out:Ljava/io/PrintStream;\n' +
                            '  #10 = Utf8               java/lang/System\n' +
                            '  #11 = Utf8               out\n' +
                            '  #12 = Utf8               Ljava/io/PrintStream;\n' +
                            '  #13 = String             #14            // Hello, World!\n' +
                            '  #14 = Utf8               Hello, World!\n' +
                            '  #15 = Methodref          #16.#17        // java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '  #16 = Class              #18            // java/io/PrintStream\n' +
                            '  #17 = NameAndType        #19:#20        // println:(Ljava/lang/String;)V\n' +
                            '  #18 = Utf8               java/io/PrintStream\n' +
                            '  #19 = Utf8               println\n' +
                            '  #20 = Utf8               (Ljava/lang/String;)V\n' +
                            '  #21 = Class              #22            // HelloWorld\n' +
                            '  #22 = Utf8               HelloWorld\n' +
                            '  #23 = Utf8               Code\n' +
                            '  #24 = Utf8               LineNumberTable\n' +
                            '  #25 = Utf8               main\n' +
                            '  #26 = Utf8               ([Ljava/lang/String;)V\n' +
                            '  #27 = Utf8               SourceFile\n' +
                            '  #28 = Utf8               HelloWorld.java\n' +
                            '{\n' +
                            '  public HelloWorld();\n' +
                            '    descriptor: ()V\n' +
                            '    flags: (0x0001) ACC_PUBLIC\n' +
                            '    Code:\n' +
                            '      stack=1, locals=1, args_size=1\n' +
                            '         0: aload_0\n' +
                            '         1: invokespecial #1                  // Method java/lang/Object."<init>":()V\n' +
                            '         4: return\n' +
                            '      LineNumberTable:\n' +
                            '        line 1: 0\n' +
                            '\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    descriptor: ([Ljava/lang/String;)V\n' +
                            '    flags: (0x0009) ACC_PUBLIC, ACC_STATIC\n' +
                            '    Code:\n' +
                            '      stack=2, locals=1, args_size=1\n' +
                            '         0: getstatic     #7                  // Field java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '         3: ldc           #13                 // String Hello, World!\n' +
                            '         5: invokevirtual #15                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '         8: return\n' +
                            '      LineNumberTable:\n' +
                            '        line 3: 0\n' +
                            '        line 4: 8\n' +
                            '}\n' +
                            'SourceFile: "HelloWorld.java"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Afisare campuri private: -p</b>
                    <br/>
                    <br/>

                    Fie clasa
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloWorld { \n' +
                            '\tprivate int x=1;\n' +
                            '    public static void main(String[] args) { \n' +
                            '        System.out.println("Hello, World!");\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap -c HelloWorld.class'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Compiled from "HelloWorld.java"\n' +
                            'public class HelloWorld {\n' +
                            '  public HelloWorld();\n' +
                            '    Code:\n' +
                            '       0: aload_0\n' +
                            '       1: invokespecial #1                  // Method java/lang/Object."<init>":()V\n' +
                            '       4: aload_0\n' +
                            '       5: iconst_1\n' +
                            '       6: putfield      #7                  // Field x:I\n' +
                            '       9: return\n' +
                            '\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    Code:\n' +
                            '       0: getstatic     #13                 // Field java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '       3: ldc           #19                 // String Hello, World!\n' +
                            '       5: invokevirtual #21                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '       8: return\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a afisa si campurile private:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap -p HelloWorld.class'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Compiled from "HelloWorld.java"\n' +
                            'public class HelloWorld {\n' +
                            '  private int x;\n' +
                            '  public HelloWorld();\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se pot combina flagurile:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javap -c -p HelloWorld.class'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloWorld {\n' +
                            '  private int x;\n' +
                            '\n' +
                            '  public HelloWorld();\n' +
                            '    Code:\n' +
                            '       0: aload_0\n' +
                            '       1: invokespecial #1                  // Method java/lang/Object."<init>":()V\n' +
                            '       4: aload_0\n' +
                            '       5: iconst_1\n' +
                            '       6: putfield      #7                  // Field x:I\n' +
                            '       9: return\n' +
                            '\n' +
                            '  public static void main(java.lang.String[]);\n' +
                            '    Code:\n' +
                            '       0: getstatic     #13                 // Field java/lang/System.out:Ljava/io/PrintStream;\n' +
                            '       3: ldc           #19                 // String Hello, World!\n' +
                            '       5: invokevirtual #21                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V\n' +
                            '       8: return\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>
                            apelarea contructorului din Object <b>invokespecial</b>:
                            <SyntaxHighlighter>
                                {'1: invokespecial #1                  // Method java/lang/Object."<init>":()V\n'}
                            </SyntaxHighlighter>
                            Invocarea constructorului necesită o țintă, care este furnizată de instructiunea <b>aload_0</b>;
                            <br/>
                            Aceasta încarcă o referință (o adresă)
                        </li>
                        <li>
                            apelarea unui metode, <b>invokevirtual</b>:
                            <SyntaxHighlighter>
                                {' 5: invokevirtual #21                 // Method java/io/PrintStream.println:(Ljava/lang/String;)V'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Opcoduri de incarcare/stocare:
                    <ul>
                        <li>load - incarca o valoare (primitiva sau referinta) dintr-o variabila locala in stiva:
                            <br/>[] {"->"} [val]</li>
                        <li>ldc - incarca o constanta din pool in stiva:  <br/>[] {"->"} [val]</li>
                        <li>store - stocheaza o valoare (primiva sau referinta)  intr-o variabila locala, eliminand-o din stiva:  <br/>[val] {"->"}[]</li>
                        <li>dup - dubleaza referinta care se afla in varful stivei: <br/>[val] {"->"} [val] [val]</li>
                        <li>getfield - ia valoarea din campul unui obiect: <br/> [obj] {"->"} [val]</li>
                        <li>putfield - pune valoarea in campul unui obiect:  <br/>[obj, val] {"->"} []</li>
                        <li>getstatic - ia valoarea unui camp static:  <br/>[] {"->"} [val]</li>
                        <li>putstatic - pune valoarea intr-un camp static: <br/> [val] {"->"} []</li>

                        <li>new - aloca memorie pentru o noua instanta si plaseaza o referinta la aceasta in partea de sus a stivei</li>
                        <li>return - return</li>
                    </ul>
                    Opcoduri aritmetice:
                    <ul>
                        <li>add</li>
                        <li>
                            sub
                        </li>
                        <li>div</li>
                        <li>mul</li>
                        <li>
                            (cast)
                        </li>
                    </ul>

                    Opcoduri de controlul fluxului:
                    <ul>
                        <li>
                            if
                        </li>
                        <li>
                            goto
                        </li>
                        <li>
                            tableswitch
                        </li>
                        <li>
                            lookupswitch
                        </li>
                    </ul>

                    Opcoduri de invocare:
                    <ul>
                        <li>
                            invokestatic: pentru apelarea unei metode statice
                            <SyntaxHighlighter>
                                {'long time = System.currentTimeMillis();'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            invokevirtual: pentru apelarea unei metode 'normale', de instanta
                            <br/>
                            ex:
                            <SyntaxHighlighter>
                                {'HashMap<String, String> m = new new HashMap<>();\n' +
                                    'm.put("a","B")'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            invokeinterface: pentru apelarea unei metode interfata
                            <br/>
                            ex:
                            <SyntaxHighlighter>
                                {' Map<String, String> m = new new HashMap<>();\n' +
                                    'm.put("a","B")'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            invokespecial: pentru apelarea unei metode 'speciale', cum ar fi un constructor;
                            <SyntaxHighlighter>
                                {'HashMap<String, String> hm = new HashMap<>();'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            invokedynamic: pentru invocare dinamica
                        </li>
                    </ul>

                    Alte opcoduri:
                    <ul>
                        <li>new - aloca memorie pentru o noua instanta si plaseaza o referinta la aceasta in partea de sus a stivei</li>
                        <li>
                            monitorenter - pentru un bloc sincronizat; blocheaza obiectul
                        </li>
                        <li>
                            monitorexit - pentru un bloc sincronizat; deblocheaza obiectul
                        </li>
                        <li>return - return</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://openjdk.org/jeps/445"}>*/}
                    {/*            JEP 445: Unnamed Classes and Instance Main Methods (Preview)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaPJavaContent;