import React from 'react';

class Footer extends React.Component {

    render() {

        const REACT_VERSION = React.version;

        return (
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-lg-offset-2 col-md-12 col-md-offset-1">
                            <p className="copyright">
                                {/*Copyright &copy; Dopamina 2020 ({process.env.REACT_APP_ENV}) - <span className={"d-none"}>28.01.2021</span> 02.03.2023*/}
                                Copyright &copy; Dopamina 2020 - revision: <span className={"d-none"}>28.01.2021</span> 02.03.2023
                                / <span>React version: {REACT_VERSION}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;