import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class StandaloneComponentsAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-standalone-components", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Componente standalone</b>
                    <br/>
                    <br/>

                    Incepand cu Angular 14, au fost adaugate componentele standalone (de sine statatoare).
                    <br/>
                    O componenta standalone, nu face partea din nici un modul <b>NgModule</b> &nbsp;
                    si daca e in proprietatea <b>declarations</b> trebuie scos, pentru ca altfel ca se va arunca eroare,
                    si eventual mutat in proprietatea <b>imports</b> (daca componenta standalone este folosita in componente de modul).

                    <br/>
                    <br/>

                    Poate fi utilizata cu:
                    <ul>
                        <li>alte componente independente
                            <br/>
                            Puteți utiliza o componentă de sine stătătoare într-o altă componentă de sine stătătoare
                            trecând-o in propritatea <b>imports</b> a acelei componente de sine stătătoare:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'@Component({\n' +
                                    '  selector: \'app-product\',\n' +
                                    '  standalone: true,\n' +
                                    '  imports: [CommonModule, LoginComponent], //<!-- AICI (acum ProductComponent poate folosi LoginComponent)\n' +
                                    '  templateUrl: \'./product.component.html\',\n' +
                                    '  styleUrls: [\'./product.component.css\']\n' +
                                    '})\n' +
                                    'export class ProductComponent implements OnInit {..}'}
                            </SyntaxHighlighter>
                            In plus, daca foloseste o componenta de modul, trebuie adaugata si aceasta in proprietea <b>imports</b>!
                        </li>
                        <li>alte componente de modul, care nu sunt indepenendete:
                            <br/>
                            Trebuie declarat in proprietatea <b>imports</b> din <b>@NgModule</b> (si nu <b>imports</b> din componenta care foloseste componenta independenta)
                        </li>
                    </ul>

                    In <b>@Component</b> se declara folosind <b>standalone: true</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng g c login --standalone'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, OnInit } from \'@angular/core\';\n' +
                            'import { CommonModule } from \'@angular/common\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'app-login\',\n' +
                            '  standalone: true, // <!--- AICI\n' +
                            '  imports: [CommonModule],\n' +
                            '  templateUrl: \'./login.component.html\',\n' +
                            '  styleUrls: [\'./login.component.css\']\n' +
                            '})\n' +
                            'export class LoginComponent implements OnInit {\n' +
                            '\n' +
                            '  constructor() { }\n' +
                            '\n' +
                            '  ngOnInit(): void {\n' +
                            '  }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pe langa componente standalone se pot creea si:
                    <ul>
                        <li>directive standalone / autonome
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng g d credit-card --standalone'}
                            </SyntaxHighlighter>
                        </li>
                        <li>pipe-uri standalone / independnete:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng g p search --standalone'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'import { Pipe, PipeTransform } from \'@angular/core\';\n' +
                                    '\n' +
                                    '@Pipe({\n' +
                                    '  name: \'search\',\n' +
                                    '  standalone: true\n' +
                                    '})\n' +
                                    'export class SearchPipe implements PipeTransform {\n' +
                                    '\n' +
                                    '  transform(value: unknown, ...args: unknown[]): unknown {\n' +
                                    '    return null;\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <br/>

                    <hr/>

                    Ar trebui să utilizate componente standalone autonome în toate scenariile?
                    <ul>
                        <li>
                            Probabil <b>Nu</b>
                            <br/>
                            În special pentru aplicații mari, foarte complexe, NgModules reduce cantitatea de cod standard (importuri etc.) care trebuie scris.
                            <br/>
                            NgModules poate ajuta la structurarea injecției de dependență, la gruparea funcțiilor și multe altele.
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Componentă autonomă de bootstrapping</b>
                    <br/>
                    <br/>

                    Angular 14 permite să porniți întreaga aplicație folosind o componentă independentă:
                    <ul>
                        <li>
                            În <b>main.ts</b>, importați:
                            <ul>
                                <li>
                                    componenta standalone/autonomă care urmează să fie <i>bootstrap</i>
                                </li>
                                <li>
                                    <b>bootstrApapplication</b>
                                </li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'import {bootstrapApplication} from \'@angular/platform-browser\';\n' +
                                    'import { ProductComponent } from \'./app/product/product.component\'; //<!-- compomenta standalone bootstrap'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            apoi:
                            <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'bootstrapApplication(ProductComponent,{\n' +
                                    '  providers:[]\n' +
                                    '});'}
                            </SyntaxHighlighter>
                            in loc de:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'platformBrowserDynamic()\n' +
                                    '  .bootstrapModule(AppModule, { preserveWhitespaces: true })\n' +
                                    '  // eslint-disable-next-line no-console\n' +
                                    '  .then(() => console.log(\'Application started\'))\n' +
                                    '  .catch(err => console.error(err));'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            apoi, in <b>index.html</b>, se inlocuieste <i>app-root</i> cu <i>app-product</i>:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'<body>\n' +
                                    '  <!-- <app-root></app-root> -->\n' +
                                    '  <app-product></app-product>\n' +
                                    '</body>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Rutare cu componentă autonomă (importProvidersFrom)</b>
                    <br/>
                    <br/>

                    Fie <i>app-routing.ts</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Routes } from "@angular/router";\n' +
                            'import { HomeComponent } from "./home/home.component";\n' +
                            '\n' +
                            'export const APP_ROUTES: Routes = [\n' +
                            '    {\n' +
                            '        path: \'\',\n' +
                            '        pathMatch: \'full\',\n' +
                            '        redirectTo: \'home\'\n' +
                            '    },\n' +
                            '    {\n' +
                            '        path: \'home\',\n' +
                            '        component: HomeComponent\n' +
                            '    }\n' +
                            '];'}
                    </SyntaxHighlighter>
                    Apoi in <i> main.ts </i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { enableProdMode, importProvidersFrom, inject } from \'@angular/core\';\n' +
                            'import {bootstrapApplication} from \'@angular/platform-browser\';\n' +
                            'import { environment } from \'./environments/environment\';\n' +
                            'import { AppComponent } from \'./app/app.component\';\n' +
                            'import { RouterModule } from \'@angular/router\';\n' +
                            'import { APP_ROUTES } from \'./app/app-routing\';'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent,{\n' +
                            '  providers: [\n' +
                            '    importProvidersFrom(RouterModule.forRoot(APP_ROUTES))]\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Încărcarea leneșă a unei componente autonome</b>
                    <br/>
                    <br/>

                    Incarcare lazy pentru o componneta standalone /autonoma (se foloseste <b>loadComponent</b>):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{\n' +
                            '        path: \'product\',\n' +
                            '        loadComponent: () => import(\'./product/product.component\')\n' +
                            '            .then(m => m.ProductComponent)\n' +
                            '  }'}
                    </SyntaxHighlighter>

                    sau, daca avem un fisier in care declaram routele (de exemplu <i>admin.route</i>, in variabila <i>ADMIN_ROUTES</i>):

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{\n' +
                            '        path: \'admin\', loadChildren: () => import(\'./admin/admin.route\')\n' +
                            '            .then(mod => mod.ADMIN_ROUTES)\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Configurarea Dependency Injection</b>
                    <br/>
                    <br/>

                    In sectiunea <b>providers</b> se pot declara serviciile, daca nu sunt declarate cu <b>providedIn:'root'</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'bootstrapApplication(AppComponent,{\n' +
                            '  providers: [\n' +
                            '    MyService,\n' +
                            '    {provide:AppService,useClass:AppService},\n' +
                            '    {provide:BACKEND_URL,useValue:"abc.com"},\n' +
                            '    importProvidersFrom(RouterModule.forRoot(APP_ROUTES))]\n' +
                            '});'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>
                               Angular 14—Introducing Standalone Components
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StandaloneComponentsAngularContent;