import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LinkUrlWebContentLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-init-with-si", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Initializare Portal cu Site Initializare</b>

                    <ul>
                        <li>1. oprire server portal Liferay</li>
                        <li>2. stergere baza de date postgresql</li>
                        <li>3. creare baza de date postgresql</li>
                        <li>4. stergere server portal Liferay</li>
                        <li>5. creare server portal Liferay - initBundle</li>
                        <li>6. copiere portal-setup-wizard.properties</li>
                        <li>7. deploy tema</li>
                        <li>8. start server Liferay # pentru initializare (se initilizeaza cu site-ul welcome)</li>
                        <li>9. stop server Liferay</li>
                        <li>10. deploy aplicatie (+ site initalizer)</li>
                        <li>11. start server Liferay</li>
                        <li>12. activare feature
                            <br/>
                            <b>Control Panel → Instance Settings →Platform → Feature Flags</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_13.png'}/>
                            </div>
                            Activare <b>LPS-165482</b> din tab-ul <b>Developer</b>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_14.png'}/>
                            </div>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_15.png'}/>
                            </div>
                        </li>
                        <li>13. sincronizare</li>
                        <li>14. activare JQuery</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://liferay.dev/de/blogs/-/blogs/fronting-liferay-tomcat-with-apache-httpd-daemon-revisted"}>*/}
                    {/*                Fronting Liferay Tomcat with Apache HTTPd daemon Revisted*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LinkUrlWebContentLiferayContent;