import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DBLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-db", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Despre baza de date Liferay</b>
                    <br/>
                    <br/>


                    Numele de tabele din baza de date Liferay nu se corelează întotdeauna direct cu numele front-end pentru conținutul Liferay:
                    <ul>
                        <li>
                            Web Content -> JournalArticle  (și alte tabele care încep cu journal)
                        </li>
                        <li>
                            Documents and Media -> DLFileEntry (și alte tabele care încep cu DL)
                        </li>
                        <li>
                            Forms  -> DDM (există multe tabele DDM diferite și majoritatea au o anumită relație cu Formulare; cu toate acestea, orice structuri de conținut web și șabloane sunt stocate și în DDMStructure)
                        </li>
                        <li>
                            Sites -> Group_
                        </li>
                        <li>
                            Pages -> Layout
                        </li>
                        <li>
                            portlet/widget preferences -> portletPreferences
                        </li>
                    </ul>

                    În Liferay, nu se folosesc chei străine în baza de date, dar exista identificatori care determină ce date sunt legate de alte tabele.
                    <br/>
                    În majoritatea tabelelor de entități, există întotdeauna două coloane prezente:
                    <ul>
                        <li><b>classNameId</b></li>
                        <li><b>classPK</b></li>
                    </ul>

                    <b>ClassNameId</b> reprezintă o valoare în tabelul <b>className_</b> și poate ajuta la restrângerea entității asociate dintr-un alt tabel. <br/>
                    <b>ClassPK</b> reprezintă identificatorul principal al celuilalt tabel.
                    <br/>
                    <br/>

                    De exemplu, un <i>classPK</i> din tabelul <b>AssetEntry</b> cu un <i>classNameId</i> care face referire la valoarea <i>com.liferay.journal.model.JournalArticle</i>
                    va folosi coloana resourcePrimKey pentru a tabelului <b>JournalArticle</b>.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/es/blogs/-/blogs/the-liferay-database-schema"}>
                                The Liferay Database Schema
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"} href={"https://liferay.dev/es/blogs/-/blogs/understanding-liferay-s-database?_com_liferay_blogs_web_portlet_BlogsPortlet_redirectToLastFriendlyURL=false"}>
                                Understanding Liferay's Database
                            </a>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default DBLiferayContent;