import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class PiperPythonRi5TtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-piper-python-pi5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. piper - Python cu PI 5</b>
                    <br/>
                    <br/>

                    Vezi: 1.2. Piper - Raspberry Pi 5!

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# pip install playsound\n' +
                            '\n' +
                            'import subprocess\n' +
                            'import os\n' +
                            '#from playsound import playsound\n' +
                            '\n' +
                            'model_name="/home/iulianb/tools/piper/models/ro/mihai/ro_RO-mihai-medium.onnx"\n' +
                            'piper_path="/home/iulianb/tools/piper/piper"\n' +
                            '\n' +
                            'text = "Tranzistoare, tranzistoare ce ai facut la rezistoare?"\n' +
                            'file = "/home/iulianb/tools/piper/tests/speech-piper.wav"\n' +
                            '\n' +
                            'os.chdir(piper_path)\n' +
                            '     \n' +
                            'command = f\'echo "{text}" | ./piper --model {model_name} --output_file {file}\'\n' +
                            'result =subprocess.run(command, shell=True)\n' +
                            '\n' +
                            'if result.returncode == 0:\n' +
                            '    print("TTS a fost generat cu success")\n' +
                            '\n' +
                            '    # playsound(file)\n' +
                            '    os.system("aplay " + file)\n' +
                            '\n' +
                            'else:\n' +
                            '    print(f"Eroare: {result.returncode}")'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PiperPythonRi5TtsContent;