import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CnnContent extends BaseContentPage {

    constructor(props) {
        super(props, "cnn", IndexContent);
    }

    render() {
        return (
            <div className="home cnn">
                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify"}>
                    <b>3. Rețele neurale convulutionale (CNN)</b>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <b>1. Introducere</b>
                    <p>Chiar din primii anii ai Inteligentei Artificiale (IA), prin anii 50, cercetatorii au incercat sa creeze un sistem care sa
                    inteleaga datele vizuale. Adica daca sistemului ii se da o poza, acesta sa distinga daca in poza este un mar sau un melc, sau ce obiecte se regasesc in poza.</p>
                    <p>Acest interes, a devenit in anii urmatori un domeniu nou de studiu, numit <b>Computer Vision</b>.</p>

                    <p>În 2012, Computer Vision a facut un pas important, cand un grup de cercetatori Universitatea din Toronto a dezvoltat un model AI care a depășit cu mult cei mai buni algoritmi de recunoaștere a imaginii.
                        Acest model / sistem IA a devenit cunoscut sub numele de <b>AlexNet</b> (dupa numele principalului sau creator Alex Krizhevsky).
                        Acest sistem a câștigat concursul de viziune computerizată ImageNet 2012 cu o precizie uimitoare de 85%.
                        Al doilea clasat in concurs a obținut un procent de 74% la test.
                    </p>

                    <p>
                        În centrul rețelei AlexNet se afla <b>rețelele neuronale convoluționale</b>, un tip special de rețea neuronală care imită aproximativ viziunea umană.
                        De-a lungul anilor CNN-urile au devenit o parte foarte importantă a multor aplicații de viziune computerizată.
                    </p>

                    <p>
                        În învățarea profundă (Deep Learning), o rețea neuronală convoluțională (CNN / ConvNet) este o clasă de rețele neuronale profunde,
                        aplicată cel mai frecvent pentru a analiza imagini vizuale.
                        De obicei, când ne gândim la o rețea neuronală, ne gândim la multiplicările matricei, dar nu este cazul ConvNet.
                        ConvNet folosește o tehnică specială numită <b>Convolution</b>.
                        In matematică, convoluția este o operație matematică pe două funcții care produce o a treia funcție
                        care exprimă modul în care forma uneia este modificată de cealaltă.
                    </p>

                </div>

                <br/>

                <div className={"text-justify important"}>
                    <b>2. Componente</b>
                    <br/>
                    CNN e format, în general, din 2 componente:
                    <ul>
                       <li><b>componenta de extragere a trăsaturilor din imagini</b></li>
                       <li><b>componenta complet conectata (full connected)</b></li>
                    </ul>

                    <b>2.1. Componenta de extragere a trăsaturilor</b>
                    <br/>
                    Aceasta componenta este compusă din mai multe straturi (layere) de tipul:
                    <ul>
                        <li><b>strat de convolutie</b></li>
                        <li><b>strat de agregare (pooling)</b></li>
                    </ul>
                    <b>Stratul de convolutie</b> realizeaza convolutia dintre <b>imaginea de intrarea</b> si un <b>filtru (kernel)</b>.
                    Un filtru este o matrice patrata de mici dimensiuni (exemplu: 3x3, 5x5).
                    Straturile de convolutie produc una sau mai multe <b>feature maps</b>, adica imagini care contin anumite trasaturi/caracteristici
                    ale imaginii de intrare.
                    Valorile filtrelor cu care se face convolutia in cadrul unui strat constituie <b>ponderile</b> conexiunilor din acel strat.
                    La antrenarea retelei, aceste ponderi se modifica pentru a minimiza eroarea dintre rezultatul de la iesire dorit si cel obtinut.
                    <br/><br/>
                    <b>Stratul de agregare (pooling)</b> realizeaza o reducere a dimensiunilor feature map-urilor de la intrare.
                    De exemplu, daca operatia de agregare are dimensiune 2x2 este aplicata pe un featrure map de dimensiune 64x64 va rezulta
                    un feature map de dimensiune 32x32.
                    <br/>
                    Metode de agregare:
                    <ul>
                        <li><b>max pooling</b> (se ia maximul din fiecare grup de nxn, de obicei 2x2)</li>
                        <li><b>sum pooling</b> (se ia suma din fiecare grup de nxn, de obicei 2x2)</li>
                        <li><b>average pooling</b> (se ia media din fiecare grup de nxn, de obicei 2x2)</li>
                    </ul>

                    Straturile de convolutie si agregare sunt supuse unor functii de activare cu rolul de a aaigura comportamentul neliniar al retelei.
                    Ca functie de agregare, de cele mai multe ori se foloseste <b>ReLU (Rectified Liniar Unit)</b>:
                    <div className={"padding-50"}>
                        <MathComponent tex={String.raw`f(x)=max(0,x)`}/>
                    </div>

                    <b>2.2. Componenta complet conectata (full connected)</b>
                    <br/>
                    Aceasta componenta realizeaza clasificare propriu-zisa, fiind o retea neuronala clasica, primind ca date de intrare feature map-uri
                    si la iesirea Z carei se aplica functia de activare <b>softmax</b>:
                    <MathComponent tex={String.raw`softmax(z_i)={{exp(z_i)} \over {\sum_j exp(z_j)}}`}/>.
                    Iesirea acestei componente este un vector de probabilitati cu
                    un numar de componente egal cu numarul de clase. Fiecare componenta a vectorului reprezinta probablitatea ca imaginea de la intrare
                    sa se incadreze in clasa corespunzatoare.

                    <br/>

                </div>
                <br/>
                <div className={"text-justify important"}>
                    <b>3. Limitari</b>
                    <br/>
                    CNN-urile (instruite pe ImageNet și alte seturi de date populare) nu reușesc:
                    <ul>
                        <li>să detecteze obiecte atunci când le văd în condiții de iluminare diferite și din unghiuri noi.
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>

                        <li>
                            <div>
                                <a href={" https://www.analyticsvidhya.com/blog/2021/05/convolutional-neural-networks-cnn/"}>Introduction to Convolutional Neural Networks (CNN)
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/@pavisj/convolutions-and-backpropagations-46026a8f5d2c"}>Convolutions and Backpropagations
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.jefkine.com/general/2016/09/05/backpropagation-in-convolutional-neural-networks/"}>Backpropagation In Convolutional Neural Networks
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://betterexplained.com/articles/intuitive-convolution/"}>https://betterexplained.com/articles/intuitive-convolution/
                                </a>
                            </div>
                        </li>

                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CnnContent;