import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";

class LinksContent extends BaseContentPage  {

    constructor(props) {
        super(props, "util-links", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Link-uri utile:
                    <ul>
                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@AlexanderObregon/implementing-server-sent-events-sse-with-spring-cbf283171aef"}>
                                Implementing Server-Sent Events (SSE) with Spring
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/codimis/what-is-server-sent-event-sse-and-how-to-use-it-in-java-spring-boot-7f4ffa828882"}>
                                What is Server Sent Event(SSE) and How to Use It in Java Spring Boot?
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@shubhkotnala/implementing-custom-annotation-in-springboot-b776eb2e77ae"}>
                                Implementing Custom Annotation in SpringBoot
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@knowledge.cafe/monitor-your-spring-application-performance-with-the-performancemonitorinterceptor-a-ee5a95b8b85a"}>
                                Monitor Your Spring Application Performance with the PerformanceMonitorInterceptor: A Comprehensive Guide
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://nameishari.medium.com/you-dont-know-transactional-annotation-do-you-5210c84ac41f"}>
                                You don’t know @Transactional annotation, Do you?
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/spring-boot/spring-security-role-based-implementation-with-spring-boot-3-0-2d59fa5a851b"}>
                                Spring Security role based Authentication & Authorization Implementation with Spring Boot 3.0
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@AlexanderObregon/spring-boot-and-docker-an-introduction-to-the-dockerimage-annotation-2eadd72d45f9"}>
                                Spring Boot and Docker: An Introduction to the @DockerImage Annotation
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@igorreshetnev/observing-rest-api-performance-with-springboot-prometheus-grafana-and-gatling-local-test-fee7ab393546"}>
                                Observing REST API Performance with SpringBoot, Prometheus, Grafana, and Gatling: Local Test Environment Setup
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://dip-mazumder.medium.com/how-i-improved-database-performance-hibernate-caching-in-spring-boot-c1fcc83d0945"}>
                                How I Improved Database Performance: Hibernate Caching in Spring Boot
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://realabishan.medium.com/implementing-email-services-in-a-spring-boot-application-3dd25f209991"}>
                                Implementing Email Services in a Spring Boot Application
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@dixitsatish34/spring-boot-how-to-implement-generic-webclient-for-all-ms-52b2c335efab"}>
                                Spring Boot: How to implement Generic WebClient for All MS
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/thedevproject/unlock-the-power-of-jakarta-validation-with-spring-boot-boost-your-java-development-skills-c2ea676f1157"}>
                                Unlock the Power of Jakarta Validation with Spring Boot: A Must-Have Skill for Modern Java Developers
                            </a>
                        </li>

                        <li>
                            (#Spring):
                            <a target={"_blank"} href={"https://medium.com/@aedemirsen/what-is-spring-boot-request-interceptor-and-how-to-use-it-7fd85f3df7f7"}>
                                What is Spring Boot Request Interceptor and How to Use It?
                            </a>
                        </li>

                        https://medium.com/@aedemirsen/what-is-spring-boot-request-interceptor-and-how-to-use-it-7fd85f3df7f7

                        <li>
                            (#Java):
                            <a target={"_blank"} href={"https://medium.com/@prabhashdilhanakmeemana/how-can-we-analyze-java-memory-issues-java-lang-outofmemoryerror-cf41d3643ba5"}>
                                How can we analyze JAVA memory issues (java.lang.OutOfMemoryError:Java heap space) using ECLIPSE MAT and Jprofiler: I
                            </a>
                        </li>

                        <li>
                            (#React):
                            <a target={"_blank"} href={"https://medium.com/@yazh.bis/reacts-useeffect-challenge-are-you-up-for-it-3afabecdbeec"}>
                                React’s useEffect Challenge: Are You Up for It?
                            </a>
                        </li>

                        <li>
                            (#React):
                            <a target={"_blank"} href={"https://javascript.plainenglish.io/decoupling-react-components-with-an-event-bus-9d86170e09d7"}>
                                Decoupling React Components with an Event Bus
                            </a>
                        </li>

                        <li>
                            (#React):
                            <a target={"_blank"} href={"https://blog.stackademic.com/mastering-react-design-patterns-creating-a-tabs-component-77b0fbca90e9"}>
                                Mastering React Design Patterns: Creating a Tabs Component
                            </a>
                        </li>

                        <li>
                            (#React):
                            <a target={"_blank"} href={"https://tomaszs2.medium.com/react-released-new-typescript-documentation-d87e9cfe0454"}>
                                React Released New Typescript Documentation
                            </a>
                        </li>

                        <li>
                            (#Angular):
                            <a target={"_blank"} href={"https://medium.com/@gurunadhpukkalla/implementing-microfrontends-in-angular-15-35c4d2307e36"}>
                                Implementing MicroFrontends in Angular 15
                            </a>
                        </li>

                        <li>
                            (#Angular):
                            <a target={"_blank"} href={"https://medium.com/@amankumar.sharma/stop-using-shared-module-in-angular-2b4ac217fbb2"}>
                                Stop using Shared Module in Angular
                            </a>
                        </li>

                        <li>
                            (#JavaScript):
                            <a target={"_blank"} href={"https://javascript.plainenglish.io/25-killer-javascript-one-liners-thatll-make-you-look-like-a-pro-d43f08529404"}>
                                25 Killer JavaScript One-Liners That’ll Make You Look Like a Pro
                            </a>
                        </li>

                        <li>
                            (#JavaScript):
                            <a target={"_blank"} href={"https://hsnice16.medium.com/send-large-files-from-frontend-to-the-backend-2f5f2414cc7c"}>
                                Send large files from frontend to the backend
                            </a>
                        </li>

                        <li>
                            (#CSS):
                            <a target={"_blank"} href={"https://javascript.plainenglish.io/10-modern-css-layouts-with-one-line-a059846c2140"}>
                                10 Modern CSS Layouts With One Line
                            </a>
                        </li>

                        <li>
                            (#k8s):
                            <a target={"_blank"} href={"https://klaushofrichter.medium.com/minikube-and-lets-encrypt-6e407aabb8ac"}>
                                Minikube and Let’s Encrypt
                            </a>
                        </li>


                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LinksContent;