import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ImageInspectDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-images-inspect", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker inspect</b>

                    <br/>
                    <br/>

                    Afisare detalii despre o imagine, afiseaza toate informatiile asociate cu imaginea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect [imagine]'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect nginx:latest'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Id": "sha256:1403e55ab369cd1c8039c34e6b4d47ca40bbde39c371254c7cba14756f472f52",\n' +
                            '        "RepoTags": [\n' +
                            '            "nginx:1",\n' +
                            '            "nginx:latest"\n' +
                            '        ],\n' +
                            '        "RepoDigests": [\n' +
                            '            "nginx@sha256:0047b729188a15da49380d9506d65959cce6d40291ccfb4e039f5dc7efd33286"\n' +
                            '        ],\n' +
                            '        "Parent": "",\n' +
                            '        "Comment": "",\n' +
                            '        "Created": "2022-12-21T11:28:36.032076444Z",\n' +
                            '        "Container": "998db7a2435fd5f47cc2066317b9c502c84c6d386ee02e932fcae89b956d64d1",\n' +
                            '        "ContainerConfig": {\n' +
                            '            "Hostname": "998db7a2435f",\n' +
                            '.......\n'}
                    </SyntaxHighlighter>

                    Se afiseaza informatii legate de:
                    <ul>
                        <li>
                            image ID
                        </li>
                        <li>
                            Repository Digest
                        </li>
                        <li>
                            Image building timestamp
                        </li>
                        <li>
                            data de creare
                        </li>
                        <li>
                            Command
                        </li>
                        <li>
                            variabile de mediu
                        </li>
                        <li>
                            arhitectura
                        </li>
                        <li>
                            OS
                        </li>
                        <li>
                            dimensiune
                        </li>
                    </ul>

                    <br/>
                    <br/>

                    Pentru a filtra informatia de mai sus:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect --format "{{.Id}}" nginx:latest'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'sha256:1403e55ab369cd1c8039c34e6b4d47ca40bbde39c371254c7cba14756f472f52'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect --format "{{.RepoTags}}:{{.RepoDigests}}" nginx:latest'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'[nginx:1 nginx:latest]:[nginx@sha256:0047b729188a15da49380d9506d65959cce6d40291ccfb4e039f5dc7efd33286]'}
                    </SyntaxHighlighter>

                    Pentru a salva in format json anumite informatii:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect --format "{{json .RepoTags}}" nginx:latest > detalii-nginx.txt'}
                    </SyntaxHighlighter>
                    atunci, fisierul <i>detalii-nginx.txt</i> va contine:
                    <SyntaxHighlighter>
                        {'["nginx:1","nginx:latest"]'}
                    </SyntaxHighlighter>

                    <hr/>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect --format "{{.ContainerConfig}}" nginx:latest'}
                    </SyntaxHighlighter>
                    se vor afisa doar valorile asociate la cheile json-ului:
                    <SyntaxHighlighter>
                        {'{998db7a2435f   false false false map[80/tcp:{}] false false false [PATH=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin NGINX_VERSION=1.23.3 NJS_VERSION=0.7.9 PKG_RELEASE=1~bullseye] [/bin/sh -c #(nop)  CMD ["nginx" "-g" "daemon off;"]] <nil> false sha256:54be6ffbe9af301b35b9af81ecfae9044801d34e735798a0607839ba6a10ff23 map[]  [/docker-entrypoint.sh] false  [] map[maintainer:NGINX Docker Maintainers <docker-maint@nginx.com>] SIGQUIT <nil> []} '}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect --format "{{json .ContainerConfig}}" nginx:latest'}
                    </SyntaxHighlighter>
                    se vor afisa in format json (cheie+valori):
                    <SyntaxHighlighter>
                        {'{"Hostname":"998db7a2435f","Domainname":"","User":"","AttachStdin":false,"AttachStdout":false,"AttachStderr":false,"ExposedPorts":{"80/tcp":{}},"Tty":false,"OpenStdin":false,"StdinOnce":false,"Env":["PATH=/usr/local/sbin:/usr/local/\n' +
                            'bin:/usr/sbin:/usr/bin:/sbin:/bin","NGINX_VERSION=1.23.3","NJS_VERSION=0.7.9","PKG_RELEASE=1~bullseye"],"Cmd":["/bin/sh","-c","#(nop) ","CMD [\\"nginx\\" \\"-g\\" \\"daemon off;\\"]"],"Image":"sha256:54be6ffbe9af301b35b9af81ecfae9044801d34e735798a0607839ba6a10ff23","Volumes":null,"WorkingDir":"","Entrypoint":["/docker-entrypoint.sh"],"OnBuild":null,"Labels":{"maintainer":"NGINX Docker Maintainers <docker-maint@nginx.com>"},"StopSignal":"SIGQUIT"}\n'}
                    </SyntaxHighlighter>

                    Exemplu 3:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker image inspect --format "{{.ContainerConfig.Hostname}}" nginx:latest'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'998db7a2435f'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImageInspectDockerContent;