import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class NLogVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-nlog", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <br/>

                    <b>Specificatii N-Log</b>
                    <br/>
                    <br/>

                    Curba N-Log este proiectata pentru a permite utilizarea deplină a intervalului dinamic al senzorului într-un flux de lucru profesional de film.
                    <br/>
                    Curba N-Log:
                    <ul>
                        <li>echilibrează umbrele și luminile (pastreaza detaliile in umbre si lumina; extinde gama de efecte disponibile prin gradarea culorilor;
                            <br/>
                            pentru Z6 extinde pana la 12 stopuri din intervalul dinamic (Dinamic Range) al senzorului de imagine al camerei;
                            <br/>
                            ochiul uman poate distinge pana la 20 - 24 - 30 stopuri
                        </li>
                        <li>este optimizata pentru 10 biți la înregistrarea unui film</li>
                    </ul>

                    Curba N-Log:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-50.png'}/>
                    </div>
                    Funcția de la N-Log la reflectanță este următoarea:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if (x < 452){\n' +
                        '    y = Math.pow(x/650, 3) − 0.0075; // y = (x/650)^3 −0.0075\n'+
                        '}else{\n' +
                        '    y = Math.exp((x−619)/150); // y = exp[(x−619)/150]\n'+
                        '}'}
                    </SyntaxHighlighter>

                    Funcția de la reflectanta la N-Log este următoarea:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if (x < 0.328){\n' +
                        '    x = 650 * Math.pow(y + 0.0075,1/3)); //  x = 650 * (y + 0.0075)^(1/3)\n'+
                        '}else{\n' +
                        '    x = 150 * Math.log(y) + 619; // x = 150*log(y)+619\n'+
                        '}'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>x este valoarea codului N-Log pe 10 biți</li>
                        <li>y este reflectanța ( Stop 0 {"=>"} y = 0,18 )</li>
                    </ul>

                    Urmatorul tabel afiseaza gamutul si punctul alb pentru N-log:

                    <table>
                        <thead>
                            <tr>
                                <th>Punctul</th>
                                <th>x (din cromacitatea CIE xy)</th>
                                <th>y (din cromacitatea CIE xy)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Punctul alb</td>
                                <td>0.3127</td>
                                <td>0.3290</td>
                            </tr>
                            <tr>
                                <td>Rosu primar</td>
                                <td>0.708</td>
                                <td>0.292</td>
                            </tr>
                            <tr>
                                <td>Verde primar</td>
                                <td>0.170</td>
                                <td>0.797</td>
                            </tr>
                            <tr>
                                <td>Punctul albastru</td>
                                <td>0.131</td>
                                <td>0.046</td>
                            </tr>
                        </tbody>
                    </table>

                    Observatii:
                    <ul>
                        <li>punctul alb pentru N-log peste D65</li>
                        <li>gamutul peste N-log este la fel cu ITU-R <b>BT.2020</b> (wide color gamut)</li>
                    </ul>

                    BT.2020 vs Rec.709:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-51.png'}/>
                    </div>

                    <hr/>

                    Pentru a putea inregistra N-Log, camera Nikon Z6 trebuie conectata la un recorder carea accepta videoclipuri pe 10 biti, cum ar fi Atomos Ninja V,
                    deoarece camera nu poate inregistra N-Log pe 10 biti direct pe cardul din camera.
                    <br/>
                    <br/>
                    Functia <b>View Assist</b> pe <b>ON</b> permite verificarea focalizarii si expunerii in timpul filmarii si vizualizarea imaginilor inregistrate pe LCD-ul camerei.
                    <br/>
                    <br/>
                    N-Log de fotografiere ISO nativ Z6 este de 800.

                    <hr/>

                    <b>HDMI: Atomos Ninja V HDMI și N-LOG</b>
                    <br/>

                    <ul>
                        <li>OUTPUT RESOLUTION = 2160p</li>
                        <li>EXTERNAL RECORDING CONTROL = ON</li>
                        <li>DATA DEPTH = 10 BIT</li>
                        <li>NLOG = ON  (nu se poate inregistra pe cadul intern)</li>
                        <li>VIEW ASSIST = ON</li>
                    </ul>

                    Alte detalii:
                    <ul>
                        <li><b>H.264 / MPEG-4 Part 10 sau Advanced Video Coding (AVC) </b> (mov sau mp4), 8 biti, 4:2:0</li>
                        <li><b>H.264 / MPEG-4 Part 10 sau Advanced Video Coding (AVC) </b> (mov sau mp4), 8 biti, 4:2:2 (extern)</li>
                        <li>10 biti, 4:2:2, doar cu n-log (extern)</li>
                        <li>maximum rate: 144mbs</li>
                    </ul>

                    <hr/>
                    Dezavantaje N-log:
                    <ul>
                        <li>pe Z6 se pierde 10% din imagine (factor de crop 1.1x) pentru: 3840 × 2160 30p, 3840 × 2160 25p, 3840 × 2160 24p</li>
                        <li>nu se poate filma la urmatoarele dimnsiuni de cadre si cadenta ratelor: 1920 × 1080 120p, 1920 × 1080 100p, 1920 × 1080 slow-mo</li>
                        <li>focusul se face mai greu, pentru ca imaginile nu mai sunt asa contrastante</li>
                    </ul>
                    <hr/>

                    <b>Viteza obturatorului</b>:  Regula generală este că <i>vitezele obturatorului</i> trebuie să fie <b>dublu</b> față de <i>rata de cadre</i> (fps) selectată:
                    <ul>
                        <li>25fps {"=>"} 1/50</li>
                        <li>50fps {"=>"} 1/100</li>
                        <li>100fps {"=>"} 1/200</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://www.pyromuffin.com/2018/07/how-to-do-nothing-in-hdr.html"}>How to do nothing in HDR</a>
                            </div>
                        </li>
                       <li>
                           <div>
                               <a href={"https://wolfcrow.com/how-to-expose-n-log-for-the-nikon-z6/"}>How to Expose N-Log for the Nikon Z6</a><br/>
                           </div>
                       </li>
                        <li>
                            <div>
                                <a href={"https://www.provideocoalition.com/the-not-so-technical-guide-to-s-log-and-log-gamma-curves/"}>The Not-So-Technical Guide to S-Log and Log Gamma Curves</a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://site.brid.tv/h-264-vs-h-265-what-they-are-and-which-one-is-better/"}>H.264 vs. H.265: What They Are and Which One Is Better</a>
                            </div>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NLogVideografieContent;