import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class RedirectLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-redirect", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Redirect catre alta pagina din cadrul aceluiasi portlet</b>
                    <br/>
                    <br/>

                    Problema: vrem din <i>view.jsp</i> sa mergem in pagina <i>edit.jsp</i>.

                    <hr/>
                    Fie in <i>view.jsp</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<!-- permite redirect intre alta pagina din portlet -->\n' +
                            '<portlet:renderURL var="createEditRender">\n' +
                            '    <portlet:param name="mvcRenderCommandName" value="/add-or-edit"/>\n' +
                            '</portlet:renderURL>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<a class="btn btn-primary btn-sm" href="${createEditRender}">\n' +
                            '   <clay:icon symbol="plus" />\n' +
                            '   Adauga\n' +
                            '</a>'}
                    </SyntaxHighlighter>

                    Fie MVCRenderCommand:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.nomenclator.portlet;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCRenderCommand;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.nomenclator.constants.IbridNomenclatorPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.PortletException;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.portlet.RenderResponse;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + IbridNomenclatorPortletKeys.IBRIDNOMENCLATOR,\n' +
                            '                "mvc.command.name=/add-or-edit"\n' +
                            '        }\n' +
                            ')\n' +
                            'public class CreateEditRenderMvcCommand implements MVCRenderCommand {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                            '        return "/edit.jsp";\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RedirectLiferayContent;