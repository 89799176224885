import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GoRoutinesGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-go-routines", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Rutine Go</b>
                    <br/>
                    <br/>

                    Daca vrem ca o functie sa ruleze pe un alt fir de executie, se foloseste cuvantul cheie <b>go</b>:
                    <SyntaxHighlighter>
                        {'go functie()'}
                    </SyntaxHighlighter>

                    Pot exista:
                    <ul>
                        <li>
                            main routine (creat la pornirea programului)
                        </li>
                        <li>
                            copil 1 go routine (creat cu <b>go</b>)
                        </li>
                        <li>
                            copil 2 go routine (creat cu <b>go</b>)
                        </li>
                        <li>
                            copil N go routine (creat cu <b>go</b>)
                        </li>
                    </ul>
                    Cand <i>main routine</i> se termina, se opreste si programul chiar daca exista routine copil in executie!

                    <hr/>
                    <b>2. Channels/Canale</b>
                    <br/>
                    <br/>

                    Singurul mod in care rutine pot comunica intre ele este prin intermediul cananelor.

                    <br/>
                    Creare canal care poate transmite/primi (comunica) mesaje de tip string
                    <SyntaxHighlighter>
                        {'c:=make(chan string)'}
                    </SyntaxHighlighter>

                    Apoi functia care se doreste sa foloseasca canalul, il va primi ca parametru:
                    <SyntaxHighlighter>
                        {'func functie(c chan string){..}'}
                    </SyntaxHighlighter>

                    Trimierea canalului:
                    <SyntaxHighlighter>
                        {'go functie(c)'}
                    </SyntaxHighlighter>

                    Pentru a trimite o valoare intr-un canal:
                    <SyntaxHighlighter>
                        {'c<-"mesaj"'}
                    </SyntaxHighlighter>

                    Pentru a astepta o valoare dintr-un canal:
                    <SyntaxHighlighter>
                        {'v<-c'}
                    </SyntaxHighlighter>

                    Se poate scrie si asa (asteapta si printeaza cand primesti un mesaj de la canal; dupa ce se primeste mesaj executia programului continua):
                    <SyntaxHighlighter>
                        {'fmt.Println(<-c)'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'package main\n' +
                            '\n' +
                            'import (\n' +
                            '\t"fmt"\n' +
                            '\t"net/http"\n' +
                            ')\n' +
                            '\n' +
                            'func main() {\n' +
                            '\tlinks := []string{\n' +
                            '\t\t"http://google.com",\n' +
                            '\t\t"http://facebook.com",\n' +
                            '\t\t"http://stackoverflow.com",\n' +
                            '\t\t"http://golang.org",\n' +
                            '\t\t"http://amazon.com",\n' +
                            '\t}\n' +
                            '\n' +
                            '\tc := make(chan string)\n' +
                            '\n' +
                            '\tfor _, link := range links {\n' +
                            '\t\tgo checkLink(link, c)\n' +
                            '\t}\n' +
                            '\tfor i := 0; i < len(links); i++ {\n' +
                            '\t\tfmt.Println(<-c)\n' +
                            '\t}\n' +
                            '}\n' +
                            '\n' +
                            'func checkLink(link string, c chan string) {\n' +
                            '\t_, err := http.Get(link)\n' +
                            '\tif err != nil {\n' +
                            '\t\tfmt.Println(link, "might be down!")\n' +
                            '\t\tc <- "Might be down I think"\n' +
                            '\t\treturn\n' +
                            '\t}\n' +
                            '\n' +
                            '\tfmt.Println(link, "is up!")\n' +
                            '\tc <- "Yep its up"\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Un loop infinit:
                    <SyntaxHighlighter>
                        {'for { }'}
                    </SyntaxHighlighter>

                    Sa prespunem ca in canal se trimite link-ul si vrem sa se repete la infinit verificarea:
                    <SyntaxHighlighter>
                        {'for {\n' +
                            ' go checkLink(<-c, c)' +
                            '}'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter>
                        {'for l:= range c {\n' +
                            ' go checkLink(l, c)' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Functii literale</b>
                    <br/>
                    <br/>

                    Functiile literale sunt functii anonime (Javascript sau PHP) sau lambda (Python).

                    <SyntaxHighlighter>
                        {'func(){...}()'}
                    </SyntaxHighlighter>

                    Pentru a adauga o pauza intre afisari:
                    <SyntaxHighlighter>
                        {'for l:= range c {\n' +
                            '   go func(link string){\n' +
                            '      time.Sleep(5*time.Second)\n' +
                            '      checLink(link, c)\n' +
                            '   }(l);// trebuie sa trimitem in parametru o copie a lui l; pentru ca l este out of scope in functia anonima/lamda/literala\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Urmatorul program nu se va sfarsii pentru ca se asteata ca ceva sa primeasca valoare trimisa in canal:
                    <SyntaxHighlighter>
                        {'package main\n' +
                            ' \n' +
                            'func main() {\n' +
                            '     c := make(chan string)\n' +
                            '     c <- "Hi there!"\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GoRoutinesGoContent;