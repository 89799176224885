import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ThreadsRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-threads", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Threads</b>
                    <br/>
                    <br/>

                    Pentru a crea un thread, se apeleaza functia <b>thread::spawn</b> funcția și se transmite un closure.
                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'use std::thread;\n' +
                            'use std::time::Duration;\n' +
                            '\n' +
                            'fn main() {\n' +
                            '    thread::spawn(|| {\n' +
                            '        for i in 1..10 {\n' +
                            '            println!("hi number {} from the spawned thread!", i);\n' +
                            '            thread::sleep(Duration::from_millis(1));\n' +
                            '        }\n' +
                            '    });\n' +
                            '\n' +
                            '    for i in 1..5 {\n' +
                            '        println!("hi number {} from the main thread!", i);\n' +
                            '        thread::sleep(Duration::from_millis(1));\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Nu există nicio garanție că firul nou va ajunge sa ruleze complet!
                    <br/>
                    Motivul este că nu există nicio garanție cu privire la ordinea în care rulează firele!
                    <br/>
                    Daca firul principal (main) se intrerupe va si firul nou!
                    <br/>
                    Putem rezolva problema ca firul generat să nu ruleze sau să nu ruleze complet,
                    se retine intr-o variabila ce returneaza functia <b>thread::spawn</b>.
                    <br/>
                    Functia <b>thread::spawn</b> returneaza un <b>JoinHandle</b>.
                    <br/>
                    Daca apelam metoda <b>join</b> (a <b>JoinHandle</b>) va aștepta ca firul să se termine!

                    <SyntaxHighlighter>
                        {'use std::thread;\n' +
                            'use std::time::Duration;\n' +
                            '\n' +
                            'fn main() {\n' +
                            '    let handle = thread::spawn(|| {\n' +
                            '        for i in 1..10 {\n' +
                            '            println!("hi number {} from the spawned thread!", i);\n' +
                            '            thread::sleep(Duration::from_millis(1));\n' +
                            '        }\n' +
                            '    });\n' +
                            '\n' +
                            '    for i in 1..5 {\n' +
                            '        println!("hi number {} from the main thread!", i);\n' +
                            '        thread::sleep(Duration::from_millis(1));\n' +
                            '    }\n' +
                            '\n' +
                            '    handle.join().unwrap();\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca mutăm handle.join() înainte de for, firul principal va aștepta ca firul generat să se termine și apoi va rula for,
                    astfel încât ieșirea nu va mai fi intercalat
                    <SyntaxHighlighter>
                        {'use std::thread;\n' +
                            'use std::time::Duration;\n' +
                            '\n' +
                            'fn main() {\n' +
                            '    let handle = thread::spawn(|| {\n' +
                            '        for i in 1..10 {\n' +
                            '            println!("hi number {} from the spawned thread!", i);\n' +
                            '            thread::sleep(Duration::from_millis(1));\n' +
                            '        }\n' +
                            '    });\n' +
                            '\n' +
                            '    handle.join().unwrap();\n' +
                            '\n' +
                            '    for i in 1..5 {\n' +
                            '        println!("hi number {} from the main thread!", i);\n' +
                            '        thread::sleep(Duration::from_millis(1));\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    move este adesea folosită alături, thread::spawn deoarece permite să folosim datele dintr-un fir într-un alt fir:

                    <SyntaxHighlighter>
                        {'use std::thread;\n' +
                            '\n' +
                            'fn main() {\n' +
                            '    let v = vec![1, 2, 3];\n' +
                            '\n' +
                            '    let handle = thread::spawn(move || {\n' +
                            '        println!("Here\'s a vector: {:?}", v);\n' +
                            '    });\n' +
                            '\n' +
                            '    handle.join().unwrap();\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ThreadsRustContent;