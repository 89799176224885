import React, {useState} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

const Componenta = ()=>{

    const onSave = () => {
        alert("onSave")
    }

    return(
        <button onClick={onSave}>Salveaza</button>
    )

}


const Componenta2 = ()=>{

    let [text, setText] = useState("")

    const update = (e) => {
        setText(e.target.value);
    }

    return(
       <>
           <input onChange={update} type="text" class="form-control"/>
           {text}
       </>

    )

}

class EventBindingAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-event-binding", IndexContent);
    }

    onSave = () => {
        alert("onSave")
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Event binding / Legarea evenimentului</b>
                    <br/>
                    <br/>

                    Pentru legarea la un eveniment se foloseste sintaxa speciala Angular, <b>paranteze rotunde</b> (<i>numeEveniment</i>)="<i>functie()</i>":
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<button (click)="onSave()">Salveaza</button>'}
                    </SyntaxHighlighter>
                    iar in clasa componenta avem:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\n' +
                            'import { Component } from \'@angular/core\';    \n' +
                            '@Component({    \n' +
                            '  selector: \'app-root\',    \n' +
                            '  templateUrl: \'./app.component.html\',    \n' +
                            '  styleUrls: [\'./app.component.css\']    \n' +
                            '})    \n' +
                            'export class AppComponent {    \n' +
                            '  onSave(event) {\n' +
                            '    console.log(event.toElement.value);\n' +
                            '  }    \n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a trimite data cu event binding, se foloseste <b>$event</b> in html template:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'  <input (input)="update($event)" type="text" class="form-control">\n' +
                            '  {{text}}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'  text=\'\';\n' +
                            '  update(event: Event){\n' +
                            '    this.text = (<HTMLInputElement>event.target).value;\n' +
                            '  }\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    In React, nu se foloseste nici un fel de sintaxa speciala, in afara de JSX, de exemplu:

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'const Componenta = ()=>{\n' +
                            '\n' +
                            '    const onSave = () => {\n' +
                            '        alert("onSave")\n' +
                            '    }\n' +
                            '\n' +
                            '    return(\n' +
                            '        <button onClick={onSave}>Salveaza</button>\n' +
                            '    )\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <Componenta/>

                    <br/>

                    In React nu exista constrangerea <b>$event</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'const Componenta = ()=>{\n' +
                            '\n' +
                            '    let [text, setText] = useState("")\n' +
                            '\n' +
                            '    const update = (e) => {\n' +
                            '        setText(e.target.value);\n' +
                            '    }\n' +
                            '\n' +
                            '    return(\n' +
                            '       <>\n' +
                            '           <input onChange={update} type="text" class="form-control"/>\n' +
                            '           {text}\n' +
                            '       </>\n' +
                            '\n' +
                            '    )\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Rulare:
                    <Componenta2/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EventBindingAngularContent;