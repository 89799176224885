import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TypesRustContent extends BaseContentPage {

    constructor(props) {
        super(props, "rust-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Tipuri de date</b>
                    <br/>
                    <br/>

                    Tipuri de date integer:
                    <table>
                        <thead>
                        <tr>
                            <th>Cu semn</th>
                            <th>Fara semn semn</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>i8</td>
                            <td>u8</td>
                        </tr>
                        <tr>
                            <td>i16</td>
                            <td>u16</td>
                        </tr>
                        <tr>
                            <td>i32 (implcit)</td>
                            <td>u32</td>
                        </tr>
                        <tr>
                            <td>i64</td>
                            <td>u64</td>
                        </tr>
                        <tr>
                            <td>i128</td>
                            <td>u128</td>
                        </tr>

                        <tr>
                            <td>isize</td>
                            <td>usize</td>
                        </tr>
                        </tbody>
                    </table>

                    Un integer poate fi specificat:
                    <ul>
                        <li>decimal: 100000 sau 1_000_000 (_ sunt ignorate)</li>
                        <li>hex: 0x</li>
                        <li>octal: 0o</li>
                        <li>binar: 0b</li>
                        <li>byte (u8): b'A'</li>
                    </ul>

                    Tipuri de date float:
                    <ul>
                        <li>f32</li>
                        <li>f64</li>
                    </ul>

                    Tipuri de date boolean:
                    <ul>
                        <li>bool (cu valorile: true, false)</li>
                    </ul>

                    Tipuri de date caracter:
                    <ul>
                        <li>char ('b'); se reprezeinta pe 4 bytes (32 biti)</li>
                    </ul>

                    Tipuri compuse:
                    <ul>
                        <li>
                            <b>tuple</b>:
                            <br/>
                            exemplu:
                            <SyntaxHighlighter>
                                {'let info = (1, 3.4, 7);'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                                {'let info: (u8, f64, i32) = (1, 3.4, 7);'}
                            </SyntaxHighlighter>

                            accesare:
                            <SyntaxHighlighter>
                                {'let a = info.0;\n' +
                                    'let b = info.1;\n' +
                                    'let c = info.2;'}
                            </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter>
                                {'let (a,b,c) = info;'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>array</b>:
                            <br/>
                            de exemplu:
                            <SyntaxHighlighter>
                                {'let info = [1, 2, 3];'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter>
                                {'let info = [0; 3];// [valoare; de cate ori]'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter>
                                {'let info: [u8; 3] = [1,2,3];// [valoare; de cate ori]'}
                            </SyntaxHighlighter>

                            Accesare:
                            <SyntaxHighlighter>
                                {'info[0]'}
                            </SyntaxHighlighter>

                            {/*Observatie:*/}
                            {/*<ul>*/}
                            {/*    <li>array-urile sunt limitate la dimensiune de 32</li>*/}
                            {/*</ul>*/}

                        </li>
                        <li>
                            <b>Vec</b>:
                            <br/>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TypesRustContent;