import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WiringBeansContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-stereotypes", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>Service</b>
                    <br/>
                    <br/>
                    <i>Definitie</i>:
                    Un obiect care implementeaza un <b>use case</b> (caz de utilizare) se numeste <b>service</b> (servciu).
                    <br/>
                    <br/>
                    <i>Conventie</i>:
                    Numele claselor care reprezinta un service se termina cu <b>Service</b> (exemplu: <i>PoemService</i>).
                    <br/>
                    Aceste clase sunt create intr-un pachet cu numele <i>services</i>.
                    <br/>
                    <br/>
                    <i>Stereotip</i>:
                    In framework-ul Spring, clasa care reprezinta un service se adnoteaza cu <b>@Service</b>.

                    <br/>
                    <br/>
                    <i>Exemplu</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.services;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Service;\n' +
                        '\n' +
                        '@Service\n' +
                        'public class PoemService {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>Repository / DAO</b>
                    <br/>
                    <br/>
                    <i>Definitie</i>:
                    Un obiect care lucreaza direct cu baza de date este numit <b>repository</b>. Aceste obiecte mai sunt referite si ca <b>DOA (data access objects)</b>.

                    <br/>
                    <br/>
                    <i>Conventie</i>:
                    Numele claselor care reprezinta un repository se termina cu <b>Repository</b> (exemplu: <i>PoemRepository</i>).
                    <br/>
                    Aceste clase sunt create intr-un pachet cu numele <i>repositories</i>.
                    <br/>
                    <br/>
                    <i>Stereotip</i>:
                    In framework-ul Spring, clasa care reprezinta un repository se adnoteaza cu <b>@Repository</b>.

                    <br/>
                    <br/>
                    <i>Exemplu</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.repositories;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Repository;\n' +
                        '\n' +
                        '@Repository\n' +
                        'public class PoemRepository {\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Proxy</b>
                    <br/>
                    <br/>
                    <i>Definitie</i>:
                    Un obiect care comunica cu exteriorul aplicatiei se numeste <b>proxy</b>

                    <br/>
                    <br/>
                    <i>Conventie</i>:
                    Numele claselor care reprezinta un proxy se termina cu <b>Proxy</b> (exemplu: <i>PoemProxy</i>).
                    <br/>
                    Aceste clase sunt create intr-un pachet cu numele <i>proxies</i>.
                    <br/>
                    <br/>
                    <i>Stereotip</i>:
                    In framework-ul Spring, nu exista un sterotip special, deci clasa care reprezinta un proxy poate adnota cu <b>@Component</b>.

                    <hr/>
                    <b>Model</b>
                    <br/>
                    <br/>
                    <i>Definitie</i>:
                    Un obiect care modeleaza structuri de date se numeste <b>model</b> (sunt POJO-uri).

                    <br/>
                    <br/>
                    <i>Conventie</i>:
                    <br/>
                    Aceste clase sunt create intr-un pachet cu numele <i>model</i>.
                    <br/>
                    <br/>
                    <i>Stereotip</i>:
                    In framework-ul Spring, nu exista stereotip special, deci clasa care reprezinta un model se adnoteaza cu <b>@Component</b> (doar daca e nevoie sa exista in contextul Spring).

                    <br/>
                    <br/>
                    <i>Exemplu</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.model;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Component;\n' +
                        '\n' +
                        '@Component\n' +
                        'public class Poet {\n' +
                        '\n' +
                        '    private String nume;\n' +
                        '\n' +
                        '    public String getNume() {\n' +
                        '        return nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setNume(String nume) {\n' +
                        '        this.nume = nume;\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Un obiect trebuie gestionat de framework-ul Spring (sau care trebuie sa existe in contextul Spring) daca:
                    <ul>
                        <li>are o dependenta care trebuie injectata din context (cu @Autowired, de exemplu)</li>
                        <li>este o depenendenta (service, respository, de exemplu)</li>
                    </ul>
                    De exemplu, o instanta de tipul <i>Poet</i> nu e necesar sa existe in context (daca exista, se adauga complexitate care nu-i necesara).

                    <br/>
                    <br/>
                    <b>Observatii:</b>
                    <ul>
                        <li>
                            adnotarile sterotipe se adauga pe implementari, si nu intefete (nu are sens sa fie adaugate pe interfete sau clase abstracte pentru ca aceste nu pot fi instantiate; se poate, dar nu e util)
                        </li>
                        <li>
                            daca in contextul de Spring exista mai multe bean-uri, iar aceste bean-uri sunt instante a unor implementari a aceleiasi inferfete, atunci se poate gestiona acest lucru folosind:
                            <ul>
                                <li>
                                    adnotarea <b>@Primary</b> (pentru a marca una din ele ca fiind primara):

                                    <br/>
                                    Exemplu:
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.repositories;\n' +
                                        '\n' +
                                        'public interface PoemRepository {\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.repositories.impl;\n' +
                                        '\n' +
                                        'import org.springframework.stereotype.Repository;\n' +
                                        'import ro.ibedaria.spring.context.repositories.PoemRepository;\n' +
                                        '\n' +
                                        '@Repository\n' +
                                        'public class TextPoemRepository implements PoemRepository {\n' +
                                        '\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.repositories.impl;\n' +
                                        '\n' +
                                        'import org.springframework.context.annotation.Primary;\n' +
                                        'import org.springframework.stereotype.Repository;\n' +
                                        'import ro.ibedaria.spring.context.repositories.PoemRepository;\n' +
                                        '\n' +
                                        '@Repository\n' +
                                        '@Primary\n' +
                                        'public class DbPoemRepository implements PoemRepository {\n' +
                                        '\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    adnontarea <b>Qualifier</b> (pentru a da un nume de bean pentru fiecare implementare)

                                    <br/>
                                    Exemplu:
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.repositories;\n' +
                                        '\n' +
                                        'public interface PoemRepository {\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.repositories.impl;\n' +
                                        '\n' +
                                        'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                                        'import org.springframework.stereotype.Repository;\n' +
                                        'import ro.ibedaria.spring.context.repositories.PoemRepository;\n' +
                                        '\n' +
                                        '@Repository\n' +
                                        '@Qualifier("Text")\n' +
                                        'public class TextPoemRepository implements PoemRepository {\n' +
                                        '\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.repositories.impl;\n' +
                                        '\n' +
                                        'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                                        'import org.springframework.stereotype.Repository;\n' +
                                        'import ro.ibedaria.spring.context.repositories.PoemRepository;\n' +
                                        '\n' +
                                        '@Repository\n' +
                                        '@Qualifier("Db")\n' +
                                        'public class DbPoemRepository implements PoemRepository {\n' +
                                        '\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>

                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'package ro.ibedaria.spring.context.services;\n' +
                                        '\n' +
                                        'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                                        'import org.springframework.stereotype.Service;\n' +
                                        'import ro.ibedaria.spring.context.repositories.PoemRepository;\n' +
                                        '\n' +
                                        '@Service\n' +
                                        'public class PoemService {\n' +
                                        '\n' +
                                        '    private final PoemRepository poemRepository;\n' +
                                        '\n' +
                                        '    // fara Qualifier se obtine eroarea:\n' +
                                        '    // No qualifying bean of type \'ro.ibedaria.spring.context.repositories.PoemRepository\' available:\n' +
                                        '    // expected single matching bean but found 2: dbPoemRepository,textPoemRepository\n' +
                                        '    public PoemService(@Qualifier("Text") PoemRepository poemRepository){\n' +
                                        '        this.poemRepository=poemRepository;\n' +
                                        '    }\n' +
                                        '\n' +
                                        '}\n'}
                                    </SyntaxHighlighter>

                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default WiringBeansContent;