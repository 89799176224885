import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class RollingRollbackK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-rolling-rollout", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Creare deployment (myapp-deployment) cu inregistrarea reviziei (trebuie pus flag-ul <b>--record</b>)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f deployment.yaml --record'}
                    </SyntaxHighlighter>
                    (in mod implicit coloana <b>change-cause</b> este goala;
                    cu flag-ul <b>--record</b> salveaza comanda utilizata pentru a creea / actualiza o noua revizie)

                    <br/>
                    <br/>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create deployment kj-nginx-test --image=nginx --replicas 3'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'deployment.apps/kj-nginx-test created'}
                    </SyntaxHighlighter>

                    Afisare status rollout:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout status deployment/kj-nginx-test'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'deployment "kj-nginx-test" successfully rolled out'}
                    </SyntaxHighlighter>

                    Afisare istoric:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout history deployment/kj-nginx-test'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'deployment.apps/kj-nginx-test \n' +
                            'REVISION  CHANGE-CAUSE\n' +
                            '1         <none>'}
                    </SyntaxHighlighter>

                    Modificare imagine:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl set image deployment/kj-nginx-test nginx=nginx:1.12-perl --record'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'Flag --record has been deprecated, --record will be removed in the future\n' +
                            'deployment.apps/kj-nginx-test image updated'}
                    </SyntaxHighlighter>

                    Afisare istoric, din nou:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout history deployment/kj-nginx-test'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'REVISION  CHANGE-CAUSE\n' +
                            '1         <none>\n' +
                            '2         kubectl set image deployment/kj-nginx-test nginx=nginx:1.12-perl --record=true\n'}
                    </SyntaxHighlighter>

                    Undo la revizia 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout undo deployment/kj-nginx-test'}
                    </SyntaxHighlighter>

                    Afisare istoric, din nou:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl rollout history deployment/kj-nginx-test'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'REVISION  CHANGE-CAUSE\n' +
                            '2         kubectl set image deployment/kj-nginx-test nginx=nginx:1.12-perl --record=true\n' +
                            '3         <none>\n'}
                    </SyntaxHighlighter>
                    Trecand la revizia 1, se creaza o noua revizie, numarul 3 care e de fapt revizia 1.

                    <hr/>
                    Daca se incerca sa se faca update la deployment (de exemplu, la o alta versiunea de imagine) si se obtine eroare, atunci in mod implicit va ramane la vechea versiune.

                    <hr/>
                    Strategi de deployment (<b>StrategyType</b>):
                    <ul>
                        <li><b>Recreate</b>: se opresc toate instantele aplicatiei si apoi se pornesc altele noi (problema cu aceasta abordare este ca aplicata este jos o perioada de timp)</li>
                        <li>
                            <b>RollingUpdate</b>:
                            se opreste o parte dintre instante si se pornesc altele in loc (este strategia implicita de deployment)
                            <br/>
                            Daca modificam in yml, versiunea imaginii si apoi:
                            <SyntaxHighlighter>
                                {'kubectl apply -f deployment-definition.yml'}
                            </SyntaxHighlighter>
                            O noua revizie este creata.
                            <br/>
                            Sau:
                            <SyntaxHighlighter>
                                {'kubectl set image deployment/myapp-deployment nginx-container=nginx:1.9.1'}
                            </SyntaxHighlighter>
                            (comanda se mai sus nu actualizeaza si fisierul deployment-definition.yml)
                        </li>
                    </ul>

                    Alte strategii de deployment (pe langa Recreate si Rolling Update ):
                    <ul>
                        <li>
                            Blue / Green (versiunea veche se numeste Blue, versounea noua e Green)
                            <br/>
                            <ul>
                                <li>
                                    avem o aplicatie v1; cu service care bate in v1
                                </li>
                                <li>
                                    se pune o noua versiune de aplicatie cu alta alt label de versiune (de exemplu v2)
                                </li>
                                <li>
                                    schimbam in service sa bata in v2
                                </li>
                            </ul>

                        </li>
                        <li>
                            Canary
                            <ul>
                                <li>o parte din trafic este redirectat catre noua versiune</li>
                                <li>dupa ce vedem ca e ok, trecem la noua versiune</li>
                            </ul>
                            <ul>
                                <li>
                                    avem un deployment - primary, cu aplicatia v1, 5 noduri, service care bate in v1
                                </li>
                                <li>
                                    cream un nou deployment - canary
                                </li>
                                <li>cream un nou label (ex: app:frontend) pe care il asociem la ambele deployment-uri</li>
                                <li>
                                    service va bate in noul label
                                </li>
                                <li>
                                    reducem numarul de pod-uri pentru deployment - canary la 1
                                </li>
                                <li>
                                    daca totul e ok, setam cat trebuie numarul de pod-uri
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    Exercitii:
                    <SyntaxHighlighter>
                        {'kubectl get deploy'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl describe deploy frontend'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl get service'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl describe service frontend-service'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'kubectl scale deployment --replicas=1 frontend-v2'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'kubectl delete deployment frontend'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RollingRollbackK8sContent;