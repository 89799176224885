import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class PsLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-ps", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa/lista de procese care ruleaza (utilizatorul curent):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'   PID TTY          TIME CMD\n' +
                            ' 445666 pts/0    00:00:00 bash\n' +
                            ' 445876 pts/0    00:00:00 ps'}
                    </SyntaxHighlighter>
                    <hr/>
                    Pentru a afisa toate procesele (nu doar pentru utilizatorul curent):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps -e'}
                    </SyntaxHighlighter>

                    Pentru a filtra un anumit proces:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ps aux | grep java'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'kj        658733  0.0  0.0  20740  2684 pts/0    S+   22:37   0:00 grep --color=auto java'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PsLinuxContent;