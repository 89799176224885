import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class GzipLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-gzip", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Pentru a creea o arhiva cu un fisier sau mai multe fisiere, cu compresie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'gzip [fisier]'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>-c : creaza o noua arhiva</li>
                        <li>-x : dezarhiveaza o arhiva</li>
                        <li>-t : afiaza lista de fisiere din arhiva</li>
                    </ul>

                    Exemplu 1: arhivare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'gzip arhiva.tar'}
                    </SyntaxHighlighter>
                    comanda de mai sus arhiveaza fisierul <i>arhiva.tar</i> in <i>arhiva.tar.gz</i> si sterge apoi <i>arhiva.tar</i>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GzipLinuxContent;