import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OopVariablesPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-oop-variables", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Variabile de instanta</b>
                    <br/>
                    <br/>

                    Aceste variabile exista doar <b>in contextul unui obiect</b> - create si adaugate la un obiect, <b>in orice moment al vietii obiectului</b>:
                    <ul>
                        <li>în timpul inițializării obiectului, efectuată prin metoda <b>__init__</b></li>
                        <li>mai târziu în orice moment al vieții obiectului: in interiorul clasei sau in afara clasei</li>
                    </ul>
                    <b>Orice proprietate (variabila de instanta) existentă poate fi eliminată în orice moment</b>.
                    <br/>
                    <br/>
                    Deci, un obiect de un anumit tip, poate avea proprietati diferite.

                    <br/>
                    <br/>
                    Fiecare obiect, are proprietatea build-in <b>__dict__</b> care afiseaza continutul fiecarui obiect (ce <b>variabile de instanta</b> are).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def __init__(self, value):\n' +
                        '        self.prop_1 = value\n' +
                        '\n' +
                        'a1 = A(100)\n' +
                        'a2 = A(200)\n' +
                        '\n' +
                        'a1.prop_2 = \'variabila la obiect\'\n' +
                        '\n' +
                        'print(\'a1:\', a1.__dict__) # a1: {\'prop_1\': 100, \'prop_2\': \'variabila la obiect\'}\n' +
                        'print(\'a2:\', a2.__dict__) # a2: {\'prop_1\': 200}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Variabile de clasa</b>
                    <br/>
                    <br/>

                    Variabilele de clasă sunt definite în construcția clasei si sunt disponibile inainte de creerea vreunei instante de clasa.
                    <br/>
                    <br/>
                    O variabilă de clasă:
                    <ul>
                        <li>este o proprietate de clasă care <b>există într-o singură copie</b> și este stocată în afara oricărei instanțe de clasă</li>
                        <li>toate <b>variabilele clasei sunt partajate de toate instanțele clasei</b> (pentru ca apartine clasei in sine); si deci va aceesi valoare pentru fiecare instanta</li>
                        <li>nu este afisara de proprietatea obiectului <b>__dict__</b> (pentru ca este definita in afara obiectului)</li>
                    </ul>

                    Fiecare clasa, are proprietatea build-in <b>__dict__</b> care afiseaza continutul fiecarei clase (ce <b>variabile de clasa</b> are si ce <b>metode</b> are).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    prop = \'variabila de clasa share-uita\'\n' +
                        '\n' +
                        'print(A.prop) # variabila de clasa share-uita\n' +
                        'print(A.__dict__) # {\'__module__\': \'__main__\', \'prop\': \'variabila de clasa share-uita\', \'__dict__\': <attribute \'__dict__\' of \'A\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'A\' objects>, \'__doc__\': None}\n' +
                        '\n' +
                        'a = A()\n' +
                        '\n' +
                        'print(a.prop) # variabila de clasa share-uita\n' +
                        'print(a.__dict__) # {}'}
                    </SyntaxHighlighter>

                    De asemenea, o variabila de clasa:
                    <ul>
                        <li>se poate creea si din afara clasei (ca si in cazul unei variabile de instanta)
                            <br/>
                            dar trebuie <b>creat prin intermediul numelui de clasa</b>, si nu prin intermediul obiectului - in caz contrar, se va creea o variabila de instanta, si nu de clasa

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'class A:\n' +
                                '    prop = \'prop-class\'\n' +
                                '\n' +
                                'a1 = A()\n' +
                                'a2 = A()\n' +
                                '\n' +
                                'print(A.prop," ", a1.prop) # prop-class   prop-class\n' +
                                'print(A.prop," ",a2.prop) # prop-class   prop-class\n' +
                                '\n' +
                                'a1.prop=\'prop-obj\'\n' +
                                '\n' +
                                'print(A.prop," ",a1.prop) # prop-class   prop-obj\n' +
                                'print(A.prop," ",a2.prop) # prop-class   prop-class\n' +
                                '\n' +
                                'print(a1.__dict__) # {\'prop\': \'prop-obj\'}\n' +
                                'print(a2.__dict__) # {} \n' +
                                '\n' +
                                'print(A.__dict__) # {\'__module__\': \'__main__\', \'prop\': \'prop-class\', \'__dict__\': <attribute \'__dict__\' of \'A\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'A\' objects>, \'__doc__\': None}\n'}
                            </SyntaxHighlighter>

                        </li>
                        <li>se poate sterge oricand</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    pass\n' +
                        '    \n' +
                        'A.prop_class=1\n' +
                        '\n' +
                        'print(A.__dict__) # {\'__module__\': \'__main__\', \'prop\': \'variabila de clasa share-uita\', \'__dict__\': <attribute \'__dict__\' of \'A\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'A\' objects>, \'__doc__\': None}\n' +
                        'print(A.prop_class) # 1\n' +
                        '\n' +
                        'a = A()\n' +
                        '\n' +
                        'print(a.__dict__) # {}\n' +
                        'print(a.prop_class) # 1\n' +
                        '\n' +
                        'del A.prop_class\n' +
                        '\n' +
                        'print(A.__dict__) # {\'__module__\': \'__main__\', \'__dict__\': <attribute \'__dict__\' of \'A\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'A\' objects>, \'__doc__\': None}'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>O variabla de instanta poate fi si o functie</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def test():\n' +
                        '    print("test")\n' +
                        '\n' +
                        'class A:\n' +
                        '    prop = \'variabila de clasa share-uita\'\n' +
                        '\n' +
                        'a = A()\n' +
                        '\n' +
                        'a.metoda = test\n' +
                        'a.metoda() # test\n' +
                        '\n' +
                        'print(a.prop) # variabila de clasa share-uita\n' +
                        'print(a.__dict__) # {\'metoda\': <function test at 0x7f8fae2a4050>}\n' +
                        '\n' +
                        'print(A.prop) # variabila de clasa share-uita\n' +
                        'print(A.__dict__) # {\'__module__\': \'__main__\', \'prop\': \'variabila de clasa share-uita\', \'__dict__\': <attribute \'__dict__\' of \'A\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'A\' objects>, \'__doc__\': None}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Extra</b>
                    <br/>
                    <br/>

                    Ce e de remarcat la exemplul de mai jos este forma de iteratie a lui <b>for</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Catel:\n' +
                        '    def __init__(self, nume):\n' +
                        '        self.nume=nume\n' +
                        '\n' +
                        'c1 = Catel("Labus")\n' +
                        'c2 = Catel("Azorel")\n' +
                        '\n' +
                        'for catel in c1,c2:\n' +
                        '    print(catel.nume, end=\' \') # Labus, Azorel'}
                    </SyntaxHighlighter>
                    De fapt se itereaza printr-un tublu, chiar daca lipesc paranteleze rotunde (ne putem da seama dupa virgula ,). Deci se poate scrie si asa:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Catel:\n' +
                        '    def __init__(self, nume):\n' +
                        '        self.nume=nume\n' +
                        '\n' +
                        'c1 = Catel("Labus")\n' +
                        'c2 = Catel("Azorel")\n' +
                        '\n' +
                        'for catel in (c1,c2):\n' +
                        '    print(catel.nume, end=\' \') # Labus, Azorel'}
                    </SyntaxHighlighter>

                    In schimb, daca scriem asa, vom obtine o eroare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Catel:\n' +
                        '    def __init__(self, nume):\n' +
                        '        self.nume=nume\n' +
                        '\n' +
                        'c1 = Catel("Labus")\n' +
                        'c2 = Catel("Azorel")\n' +
                        '\n' +
                        'for catel in c1:\n # TypeError: \'Catel\' object is not iterable' +
                        '    print(catel.nume, end=\' \')'}
                    </SyntaxHighlighter>

                    Dar e ok, daca punem virgula:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Catel:\n' +
                        '    def __init__(self, nume):\n' +
                        '        self.nume=nume\n' +
                        '\n' +
                        'c1 = Catel("Labus")\n' +
                        'c2 = Catel("Azorel")\n' +
                        '\n' +
                        'for catel in c1,:\n # TypeError: \'Catel\' object is not iterable' +
                        '    print(catel.nume, end=\' \') # Labus '}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OopVariablesPythonContent;