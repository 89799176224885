import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class AddingDynamicModulesNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-adding-dynamic-modules", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Adaugare dinamica de module</b>

                    <br/>
                    <br/>

                    Module statice sunt deja incarcate, iar cele dinamce sunt cele care se pot incarca selectiv.
                    <br/>
                    Dupa ce aduga un modul, trebuie rebuild nginx din sursa.

                    <br/>
                    <br/>

                    Afisare versiune Nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nginx -V'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'\n' +
                            'nginx version: nginx/1.23.1\n' +
                            'built by gcc 11.2.0 (Ubuntu 11.2.0-19ubuntu1)\n' +
                            'built with OpenSSL 3.0.2 15 Mar 2022\n' +
                            'TLS SNI support enabled\n' +
                            'configure arguments: --sbin-path=/usr/bin/nginx --conf-path=/etc/nginx/nginx.conf --error-log-path=/var/log/nginx/error.log --http-log-path=/var/log/access.log --with-pcre --pid-path=/var/run/nginx.pid --with-http_ssl_module\n' +
                            ''}
                    </SyntaxHighlighter>
                    Se retine <b>configure arguments</b>.

                    Pentru a afisa module dinamice (comanda se ruleaza din directorul unde a fost dezarhivata sursa de nginx):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./configure --help | grep dynamic'}
                    </SyntaxHighlighter>

                    Vom alege modul dinamic: <b>--with-http_image_filter_module-dynamic</b>

                    <br/>

                    Pentru <b>--with-http_image_filter_module-dynamic</b> este nevoie de rulat inainte:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get install libgd-dev'}
                    </SyntaxHighlighter>
                    Se ruleaza <b>/configure</b> cu vechile configurari + noua configurare <b>--with-http_image_filter_module-dynamic</b> + <b> --modules-path=/etc/nginx/modules</b>:
                    <SyntaxHighlighter>
                        {'./configure --sbin-path=/usr/bin/nginx --conf-path=/etc/nginx/nginx.conf --error-log-path=/var/log/nginx/error.log --http-log-path=/var/log/access.log --with-pcre --pid-path=/var/run/nginx.pid --with-http_ssl_module --with-http_image_filter_module-dynamic --modules-path=/etc/nginx/modules'}
                    </SyntaxHighlighter>

                    Compilare configurari:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'make'}
                    </SyntaxHighlighter>

                   Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'make install'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>

                    Pentru a testa noul modul, se adauga in <b>nginx.conf</b>, in contexul <i>main</i>:

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'load_module modules/ngx_http_image_filter_module.so;'}
                    </SyntaxHighlighter>

                    si apoi:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'    location = /thumb.png {\n' +
                            '      image_filter rotate 180;\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'systemctl reload nginx'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AddingDynamicModulesNginxContent;