import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnnContent extends BaseContentPage {

    constructor(props) {
        super(props, "ann", IndexContent);
    }

    render() {
        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    O <b>rețea neuronală artificială</b> (artificial neural network (ANN)  este un sistem de procesare a informației,
                    inspirat din rețelele neurale biologice și bazat pe urmtătoarele ipoteze:
                    <ul>
                        <li>
                            procesarea informației este efectuată de unități de prelucrare numite <b>neuroni</b> (sau unități cognitive sau noduri)
                        </li>
                        <li>
                            <i>semnale</i> sunt transmise între neuroni prin intermediul legăturilor dintre neuroni (numite <b>conexiuni</b> sau muchii sau sinapse)
                        </li>
                        <li>
                            fiecare <i>conexiune</i> are asociată o <b>pondere</b>, care într-o rețea neurală tipică multiplică semnalul transmis;
                            această pondere reprezintă informația care va fi folosită de rețeaua neurală pentru a rezolva o problemă
                        </li>
                        <li>
                            fiecare <i>neuron</i> aplică o <b>functie de activare</b> (de obicei neliniară) la <b>intrarea sa netă</b> (suma ponderată a semnalelor de intrare) pentru a determina semnalul de ieșire
                        </li>
                    </ul>

                    O retea neuronală este caracterizată de:
                    <ul>
                        <li>
                            arhitectură (modelul de conectare dintre neuroni)
                        </li>
                        <li>
                            antrenare/învățare/algoritm (metoda de a determina ponderile pe legăturile dintre neuroni)
                        </li>
                        <li>
                            funcția de activare
                        </li>
                    </ul>
                    Fiecare neuron are o funcție de activare care se aplică datelor de intrare (semnalor) primite. De obicei,
                    un neuron trimite rezultatul funcție de activare (ca un semnal) către mai mulți neuroni.
                    Rezultatul funcției de activare se mai numește <b>activare</b>.

                </div>
                <br/>
                <div className={"text-justify"}>
                    <div className={"d-flex flex-row"}>
                        <div className={""}>
                            <b>Exemplu:</b><br/>
                            Să presupunem că avem neuronul Y.<br/>
                            Neuronul Y primește semnale de la neuronii<br/>
                            <div className={"padding50"}>
                                X<sub>1</sub>, X<sub>1</sub> și X<sub>3</sub>.<br/>
                            </div>
                            Fiecare neuron X<sub>1</sub>, X<sub>1</sub> și X<sub>3</sub> are următoarele activări (semnale de ieșire)<br/>
                            <div className={"padding50"}>
                                x<sub>1</sub>, x<sub>1</sub> și x<sub>3</sub><br/>
                            </div>
                            Fiecare conexiune de la neuronii X<sub>1</sub>, X<sub>1</sub> și X<sub>3</sub> la Y are următoarele ponderi:<br/>
                            <div className={"padding50"}>
                                w<sub>1</sub>, w<sub>1</sub> și w<sub>3</sub><br/>
                            </div>
                            Atunci intrarea netă y<sub>in</sub> este:
                            <div className={"padding-50"}>
                                <MathComponent tex={String.raw`y_{in}=w_1x_1 + w_2x_2 + w_3x_3`}/>
                            </div>
                            Notam cu f funcția de activare a neuronului Y:
                            <div className={"padding-50"}>
                                <MathComponent tex={String.raw`y=f(y_{in})`}/>
                            </div>
                            unde <i>f</i> poate fi, de exemplu, functia sigmoid
                            <div className={"padding-50"}>
                                <MathComponent tex={String.raw`f(x)={{1} \over {1+exp(-x)}}`}/>
                            </div>
                            unde functia <i>exp</i>
                            <div className={"padding-50"}>
                                <MathComponent tex={String.raw`exp(-x)=e^{-x}`}/>
                            </div>
                            unde e=2.718281828...
                            <br/>
                            Rezultatul functie f(x) este <b>activarea</b> neuronului.
                        </div>
                        <div style={{padding:50}}>
                            <img alt={""} style={{width:250}} className={"rounded mx-auto d-block"}
                                 src={process.env.PUBLIC_URL + '/img/ann_1.png'}/>
                        </div>
                    </div>
                </div>

                <br/>

                <div className={"text-justify important"}>
                    O rețea neuronală este formată din mai mulți neuroni interconectați și organizați pe mai multe straturi (layere):
                    <ul>
                        <li>
                            stratul de neuroni care primește datele de intrare (<b>input layer</b>)
                        </li>
                        <li>
                            straturi de neuroni (intermediari/ascunsi) care ajută la prelucrarea înformației (<b>hidden layers</b>)
                        </li>
                        <li>
                            stratul de neuroni care oferă răspunsul la problemă (<b>output layers</b>)
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    Mai jos e un exemplu de rețea neurală artificială simplă:
                    <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                         src={process.env.PUBLIC_URL + '/img/ann_2.png'}/>
                </div>

                <br/>
                <div className={"text-justify important"}>
                    În funcție numărul de layere, rețele pot fi clasificate ca:
                    <ul>
                        <li>
                            rețele cu un sigur strat de neuroni (exemplu: percepronul, rețele Hopfield)
                        </li>
                        <li>
                            rețele cu mai multe straturi de neuroni sau multiplayere
                        </li>
                    </ul>
                    <b>Observație:</b> În determinarea numărului de layere, input layer nu se pune la socoteală pentru ca în acest layer nu se efectueaza nici un calcul.
                    <br/>
                    Rețele pot fi în funcție de directia conexiunilor:
                    <ul>
                        <li>
                            <b>feedforward</b> (exista conexiuni unidirectionale doar de la un stratul curent la urmatorul strat, ca în exemplul de mai sus)
                        </li>
                        <li>
                            <b>recurente</b> (exista conexiuni care se întorc la un strat anterior sau iar în stratul de unde a plecat)
                        </li>
                    </ul>
                    <br/>
                    De asemenea, pot exista rețele:
                    <ul>
                        <li>
                            <b>complet conectate</b> (fiecare neuron este conectat cu restul de neuroni / în sens mai restrictiv: fiecare neuron din stratul curent este conectat cu toți neuronii din stratul următor)
                        </li>
                        <li>
                            <b>parțial conectate</b> (exista neuroni care nu sunt conectati cu toti ceilalți neuroni / în sens mai restrictiv: există neuroni din statul curent care nu sunt conectati cu toți neuronii din stratul următor)
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    Mai jos e un exemplu de rețea neurală artificială simplă cu <i>n</i> neuroni in input layer, <i>p</i> neuroni în hidden layer și <i>m</i> neuroni în output layer.
                    Pe fiecare conexiune este trecută si ponderea:
                    <ul>
                        <li>w<sub>ij</sub> pentru conexiunile dintre neuronii dintre input layer si hidden layer</li>
                        <li>v<sub>ij</sub> pentru conexiunile dintre hidden layer si outputlayer)</li>
                    </ul>
                    <img alt={""} style={{width:450}} className={"rounded mx-auto d-block"}
                         src={process.env.PUBLIC_URL + '/img/ann_3.png'}/>
                </div>

                <br/>
                <div className={"text-justify important"}>
                    Notăm cu W <b>matricea ponderilor</b>:
                      <MathComponent tex={String.raw`W={\{w_{ij}\}}`}/>
                    ponderea de la neuronul X<sub>i</sub> la neuronul Y<sub>j</sub> (uneori, se mai folosește și convenția înversă, în sensul că
                    w<sub>ij</sub> semnifică ponderea de la neuronul  Y<sub>j</sub> la neuronul X<sub>i</sub>).

                    <br/>
                    <br/>
                    Notăm cu b<sub>j</sub> <b>biasul</b> pentru neuronul Y<sub>j</sub>. Biasul actionează ca o pondere pe o conexiune de la un "neuron" fictiv cu ieșirea constantă 1.
                    <br/>
                    Bias-ul poate fi văzut ca o metodă de înlocuire a pragului cu o pondere (ajustabilă).
                    <br/>
                    <br/>
                    Un neuron Y<sub>j</sub>, având intrările x<sub>1</sub>, x<sub>2</sub> ,..., x<sub>n</sub>:
                    <ul>
                        <li>
                            sumarizează inputul ponderat
                            <MathComponent tex={String.raw`sum_j=b_j+\sum_{i}^{n} x_i w_{ij}`}/>
                        </li>

                        <li>
                            aplică o funcție de activare pentru a obține ieșirea neuronului
                            <MathComponent tex={String.raw`y_j=f(sum_j)`}/>
                            rezulatul y<sub>j</sub> este <b>activarea</b> neuronului
                        </li>
                    </ul>
                    Pentru neuronii din input layer, functia de activare este functia identitate
                        <MathComponent tex={String.raw`f(x)=x`}/>

                    <b>Observație:</b> De obicei se foloseste acceasi funcție de activare pentru toți neuronii aceluiași layer, dar nu este o regulă.
                    <br/>
                    <br/>
                    Alte funcții de activare:
                    <ul>
                        <li>
                            funcția pas (step) cu pragul &theta;:
                            <MathComponent
                                tex={String.raw`f(x) = \Bigg\{\begin{eqnarray} 1, x \ge \theta  \\ 0, x \lt \theta  \end{eqnarray}`}/>
                        </li>
                        <li>
                            funcția pas (step) fără prag (dacă se folosește bias în loc de prag):
                            <MathComponent
                                tex={String.raw`f(x) = \Bigg\{\begin{eqnarray} 1, x \ge 0  \\ 0, x \lt 0  \end{eqnarray}`}/>
                        </li>

                        <li>
                            funcția pas bipolar:
                            <MathComponent
                                tex={String.raw`f(x) = \Bigg\{\begin{eqnarray} 1, x \gt 0 \\ 0, x=0  \\ -1, x \lt 0  \end{eqnarray}`}/>
                        </li>

                        <li>
                            funcția sigmoid binar:
                            <MathComponent tex={String.raw`f(x)={{1} \over {1+exp(-x)}}`}/>
                        </li>
                        <li>
                            funcția sigmoid bipolar:
                            <MathComponent tex={String.raw`f(x)={{1-exp(-x)} \over {1+exp(-x)}}`}/>
                        </li>
                        <li>
                            funcția hiperbolic tangent:
                            <MathComponent tex={String.raw`f(x)={{1-exp(-2x)} \over {1+exp(-2x)}}`}/>
                        </li>
                    </ul>
                </div>

                <br/>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson
                        </li>
                        <li>
                            <div>
                                Ivan Nunes da Silva, Danilo Hernane Spatti , Rogerio Andrade Flauzino, Luisa Helena Bartocci Liboni, Silas Franco dos Reis Alves. 2017. Artificial Neural Networks - A Practical Course
                            </div>
                        </li>
                        <li>
                            <a href={"https://www.tutorialspoint.com/artificial_neural_network/index.htm"} target={"_blank"}>
                                https://www.tutorialspoint.com/artificial_neural_network/index.htm
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnnContent;