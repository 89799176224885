import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class CreateUserDbContent extends BaseContentPage  {

    constructor(props) {
        super(props, "db-create-user", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Creare utilizator si acordare drepturi</b>
                    <br/>
                    <br/>

                    Pentru <b>MySQL</b>:
                    <SyntaxHighlighter>
                        {'CREATE USER \'user\'@\'%\' IDENTIFIED BY \'parola\';'}
                    </SyntaxHighlighter>

                    Acordare drepturi:
                    <SyntaxHighlighter>
                        {'GRANT ALL PRIVILEGES IN db.* TO \'user\'@\'%\';'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            db - numele bazei de date
                        </li>
                    </ul>

                    <SyntaxHighlighter>
                        {'FLUSH PRIVILEGES;'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru <b>PostgreSQL</b>:
                    <SyntaxHighlighter>
                        {'CREATE USER user WITH PASSWORD \'parola\';'}
                    </SyntaxHighlighter>

                    Acordare drepturi:
                    <SyntaxHighlighter>
                        {'GRANT ALL PRIVILEGES ON DATABASE db TO user;'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            db - numele bazei de date
                        </li>
                    </ul>
                 
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateUserDbContent;