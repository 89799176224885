import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DatetimeModulePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-datetime-module", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Modulul datetime</b>
                    <br/>
                    <hr/>
                    <b>1. Clasa date</b>
                    <br/>
                    <br/>
                    Modul <b>datetime</b> are clasa numita <b>date</b> (reprezintă o dată formată din an, lună și zi) cu urmatoarele atribute (<b>read-only</b>):
                    <ul>
                        <li><b>year</b> (an)</li>
                        <li><b>month</b> (luna) </li>
                        <li><b>day</b> (zi)</li>
                    </ul>

                    Metode:
                    <ul>
                        <li>constructor: <b>date(an, luna, zi)</b> (ex: date(2017,11,21)), cu urmatoarele constrangeri:
                            <ul>
                                <li>an: trebuie sa fie intre constantele [MINYEAR, MAXYEAR] = [1, 9999]</li>
                                <li>luna: trebuie sa fie intre [1, 12]</li>
                                <li>zi: trebuie sa fie intre [1, ultima zi a lunii]</li>
                            </ul>
                        </li>
                        <li><b>today()</b>: returnează un obiect de tip <b>date</b> reprezentând data locală curentă</li>
                        <li>
                            <b>fromtimestamp(timestamp)</b>: un obiect de tip <b>date</b> dintr-un timestamp (numarul de secunde scurs de la date de 1 ianuarie 1970 00:00:00 pana in prezent)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import date\n' +
                                'import time\n' +
                                '\n' +
                                'timestamp = time.time() # metoda time() este folosita pentru a obtine timestamp\n' +
                                'print("Timestamp:", timestamp) # ceva de genul: Timestamp: 1638019533.9982285\n' +
                                '\n' +
                                'd = date.fromtimestamp(timestamp)\n' +
                                'print("Data:", d) # Data: 2021-11-27'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>fromisoformat(stringISO)</b>: un obiect de tip <b>date</b> dintr-un string in format ISO 8601 (YYYY-MM-DD):

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import date\n' +
                                '\n' +
                                'd = date.fromisoformat(\'1984-11-13\')\n' +
                                'print(d) # 1984-11-13'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            <b>replace(year=an_nou, month=luna_noua, day=zi_noua)</b>: returnează un obiect cu data schimbată, pe baza parametrilor primiti (care sunt obtionali)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import date\n' +
                                '\n' +
                                'd = date(1990, 2, 7)\n' +
                                'print(d)\n' +
                                '\n' +
                                'd = d.replace(year=1984, month=1, day=10)\n' +
                                'print(d)\n'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            <b>weekday()</b>: returneaza ziua saptamanii (0 e luni):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import date\n' +
                                '\n' +
                                'd = date.today()\n' +
                                'print(d.weekday()) # poate 5, adica sambata\n'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <b>isoweekday()</b>: returneaza ziua saptamanii (1 e luni):
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import date\n' +
                                '\n' +
                                'd = date.today()\n' +
                                'print(d.isoweekday()) # poate 6, adica sambata\n'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import date\n' +
                        '\n' +
                        'today = date.today()\n' +
                        '\n' +
                        'print("Azi:", today) # 2021-11-27\n' +
                        'print("An:", today.year) # An: 2021\n' +
                        'print("Luna:", today.month) # Luna: 11\n' +
                        'print("Zi:", today.day) # Zi: 27\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Clasa time</b>
                    <br/>
                    <br/>

                    Modul <b>datetime</b> are clasa numita <b>time</b> (reprezintă un timp/ora) cu urmatoarele atribute:
                    <ul>
                        <li><b>hour</b> (ora)</li>
                        <li><b>minute</b> (minutul): trebuie </li>
                        <li><b>second</b> (secunda)</li>
                        <li><b>microsecond</b> (microsecunda)</li>
                        <li><b>tzinfo</b> (microsecunda)</li>
                        <li><b>fold</b> (microsecunda)</li>
                    </ul>

                    Metode:
                    <ul>
                        <li>constructor: <b>time(ora, minut, secunda, microsecunda, tzinfo, fold)</b> (ex: time(15, 41, 10, 1)), cu urmatoarele constrangeri:
                            <ul>
                                <li>ora: trebuie sa fie intre [0,23]</li>
                                <li>minut: trebuie sa fie intre [0, 59]</li>
                                <li>secunda: trebuie sa fie intre [0,59]</li>
                                <li>microsecunda: trebuie sa fie intre [0, 1000000]</li>
                                <li>tzinfo: trebuie sa fie sublasa <b>tzinfo</b> or <b>None</b> (implicit), asociat cu time zones</li>
                                <li>fold: trebuie sa fie 0 (implicit) sau 1,  asociat cu wall times (Wall time = timpul scurs pe un cronometru)</li>

                            </ul>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import time\n' +
                        '\n' +
                        't = time(15, 41, 10, 1)\n' +
                        '\n' +
                        'print("Timp:", t) # 15:41:10.000001\n' +
                        'print("Ora:", t.hour) # Ora: 15\n' +
                        'print("Minut:", t.minute) # Minut: 41\n' +
                        'print("Secunda:", t.second) # Secunda: 10\n' +
                        'print("Microsecunda:", t.microsecond) # Microsecunda: 1\n' +
                        'print("Tzinfo:", t.tzinfo) # None, parametru asociat cu time zones\n' +
                        'print("Fold:", t.fold) # 0, parametru asociat cu wall times (Wall time = timpul scurs pe un cronometru)'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Clasa datetime</b>
                    <br/>
                    <br/>

                    Modul <b>datetime</b> are clasa numita <b>datetime</b> (reprezintă un data si timpul).

                    Metode:
                    <ul>
                        <li>constructor: <b>datetime(an, luna, zi, ora, minut, secunda, microsecunda, tzinfo, fold)</b>, cu urmatoarele constrangeri:
                            <ul>
                                <li>an: trebuie sa fie intre constantele [MINYEAR, MAXYEAR] = [1, 9999]</li>
                                <li>luna: trebuie sa fie intre [1, 12]</li>
                                <li>zi: trebuie sa fie intre [1, ultima zi a lunii]</li>
                                <li>ora: trebuie sa fie intre [0,23]</li>
                                <li>minut: trebuie sa fie intre [0, 59]</li>
                                <li>secunda: trebuie sa fie intre [0,59]</li>
                                <li>microsecunda: trebuie sa fie intre [0, 1000000]</li>
                                <li>tzinfo: trebuie sa fie sublasa <b>tzinfo</b> or <b>None</b> (implicit), asociat cu time zones</li>
                                <li>fold: trebuie sa fie 0 (implicit) sau 1,  asociat cu wall times (Wall time = timpul scurs pe un cronometru)</li>
                            </ul>
                        </li>
                        <li>
                            <b>date()</b>: returneaza un obiect de tip <b>date</b>
                            <b>time()</b>: returneaza un obiect de tip <b>time</b>
                            <b>today()</b>: returnează data și ora locală curentă cu atributul <b>tzinfo=None</b>
                            <b>now()</b>: returnează data și ora actuală ( ca metoda today(), cu excepția cazului în care îi transmitem argumentul opțional tz - obiect al subclasei <b>tzinfo</b>)
                            <b>utcnow()</b>: returnează data și ora UTC curentă cu atributul <b>tzinfo=None</b>
                            <b>timestamp()</b>: returnează o valoare flotantă care exprimă numărul de secunde scurse între data și ora indicate de obiectul de tip <b>datetime</b> și 1 ianuarie 1970, 00:00:00 (UTC)
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import datetime\n' +
                        '\n' +
                        'dt = datetime(1984, 6, 4, 7, 59)\n' +
                        ' \n' +
                        'print("Datetime:", dt) # Datetime: 1984-06-04 07:59:00\n' +
                        'print("Date:", dt.date()) # Date: 1984-06-04\n' +
                        'print("Time:", dt.time()) # Time: 07:59:00\n' +
                        'print("Today:", datetime.today()) # Today: 2021-11-27 14:49:33.040837\n' +
                        'print("Now:", datetime.now()) # Now: 2021-11-27 14:49:33.042076\n' +
                        'print("Utcnow:", datetime.utcnow()) # Utcnow: 2021-11-27 14:49:33.042285\n' +
                        'print("Timestamp:", dt.timestamp()) # Timestamp: 1601823300.0'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Formatare</b>
                    <br/>
                    <br/>

                    <b>4.1. Metoda strftime()</b>
                    <br/>
                    <br/>

                    Toate clasele de modul <b>datetime</b> au o metodă numită <b>strftime</b>.
                    Metoda <b>strftime()</b> ia un singur argument sub forma unui șir de caractere care specifică <b>formatul</b> sub forma unor directive.

                    O directivă este un șir format din caracterul % (procent) și o literă mică sau mare:
                    <ul>
                        <li>%Y – returnează anul cu secol ca număr zecimal</li>
                        <li>%y – returnează anul fara secol ca număr zecimal</li>
                        <li>%m – returnează luna ca număr zecimal cu adaugare de 0 in fata daca e nevoie (ex: 01)</li>
                        <li>%B - returnează luna ca nume complet</li>
                        <li>%d – returnează ziua ca număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                        <li>%H - returnează ora ca un număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                        <li>%M – returnează minutul ca număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                        <li>%S – returnează sedunda ca număr zecimal cu adaugare de 0 in fata daca e nevoie</li>
                    </ul>

                    Exemplul 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import date\n' +
                        '\n' +
                        'd = date.today()\n' +
                        'print(d.strftime(\'%d - %m - %Y\')) # 27 - 11 - 2021\n'}
                    </SyntaxHighlighter>

                    Exemplul 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import time\n' +
                        'from datetime import datetime\n' +
                        '\n' +
                        't = time(14, 53) \n' +
                        'print(t.strftime("%H:%M:%S")) # 14:53:00\n' +
                        'print(t.strftime("%Y/%m/%d  %H:%M:%S")) # 1900/01/01  14:53:00\n' +
                        '\n' +
                        'dt = datetime(2010, 11, 21, 11, 33)\n' +
                        'print(dt.strftime("%y/%B/%d %H:%M:%S")) # 10/November/21 11:33:00'}
                    </SyntaxHighlighter>

                    Exercitiu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import datetime\n' +
                        '\n' +
                        'd = datetime.strptime("November 21, 2021 , 13:23:00","%B %d, %Y , %H:%M:%S" ) \n' +
                        '\n' +
                        'print(d.strftime("%Y/%m/%d %H:%M:%S")) # 2021/11/21 13:23:00\n' +
                        'print(d.strftime("%y/%B/%d %H:%M:%S %p")) # 21/November/21 13:23:00 PM\n' +
                        'print(d.strftime("%a, %Y %b %d")) # Sun, 2021 Nov 21\n' +
                        'print(d.strftime("%A, %Y %B %d")) # Sunday, 2021 November 21\n' +
                        '\n' +
                        'print("Ziua din saptamana:",d.strftime("%w")) # Ziua din saptamana: 0\n' +
                        'print("Ziua din an:",d.strftime("%-j")) # Ziua din an: 325\n' +
                        'print("Saptamana din an:",d.strftime("%W")) # Saptamana din an: 46'}
                    </SyntaxHighlighter>
                    <br/>

                    <b>4.2. Metoda strptime()</b>
                    <br/>
                    <br/>

                    Metoda <b>strptime(sir_de_caractere, format)</b> creeaza un obiect de tipul <b>datetime</b> dintr-un <b>șir</b> care reprezintă o dată și o oră, <b>specificand formatul</b> in care este dat.

                    <br/>
                    Dacă formatul pe care îl specificați nu se potrivește cu data și ora din șir, va genera o valoare <b>ValueError</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'from datetime import datetime\n' +
                        'print(datetime.strptime("1984/07/01 19:23:00", "%Y/%m/%d %H:%M:%S")) # 1984-07-01 19:23:00'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Operatii pe tipul <b>timedelta</b></b>
                    <br/>
                    <br/>

                    Un obiect de tipul <b>timedelta</b> se poate creea in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'timedelta(days = 0, seconds = 0, microseconds = 0, milliseconds = 0, minutes = 0, hours = 0,  weeks = 0)'}
                    </SyntaxHighlighter>

                    Operatii:
                    <ul>
                        <li><b>scaderea cu un alt obiect de tip </b><b>timedelta</b>
                            <br/>

                            In momentul in care se scad (operator: <b>-</b>) doua obiecte de tip <b>datetime</b> sau <b>date</b> din modulul <b>datetime</b> se obtine un obiect de tipul <b>timedelta</b>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import date\n' +
                                'from datetime import datetime\n' +
                                '\n' +
                                'd1 = date(2022, 10, 3)\n' +
                                'd2 = date(2019, 10, 3)\n' +
                                '\n' +
                                'print(d1 - d2) # 1096 days, 0:00:00\n' +
                                '\n' +
                                'dt1 = datetime(2022, 11, 21, 0, 0, 0)\n' +
                                'dt2 = datetime(2019, 11, 21, 13, 43, 0) # 1095 days, 10:17:00\n' +
                                '\n' +
                                'print(dt1 - dt2)\n'}
                            </SyntaxHighlighter>

                            Alt exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import datetime\n' +
                                '\n' +
                                'dt1 = datetime(2022, 11, 21, 12, 11, 0)\n' +
                                'dt2 = datetime(2022, 11, 21, 0, 0, 0) # 12:11:00\n' +
                                '\n' +
                                'print(dt1 - dt2) # 12:11:00'}
                            </SyntaxHighlighter>

                            Argumentele ar trebui să fie numere întregi sau numere în virgulă mobilă și pot fi fie pozitive, fie negative.
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import timedelta\n' +
                                '\n' +
                                'delta = timedelta(weeks=1, days=2, hours=4)\n' +
                                '\n' +
                                'print("Zile:", delta.days) # Zile: 9\n' +
                                'print("Secunde:", delta.seconds) # Secunde: 14400\n' +
                                'print("Microsecunde:", delta.microseconds) # Microsecunde: 0\n' +
                                '\n' +
                                'print("Delta:", delta) # Delta: 9 days, 4:00:00'}
                            </SyntaxHighlighter>

                            Obiectul de tip <b>timedelta</b> stochează argumentele transmise în timpul creării sale in felul urmator:
                            <ul>
                                <li>săptămânile sunt convertite in zile</li>
                                <li>orele si minutele sunt convertite in secunde</li>
                                <li>milisecunde sunt convertite in microsecunde</li>
                            </ul>
                            Deci, obiectul de tip <b>timedelta</b> stochează numai zile, secunde și microsecunde în interior.
                            <br/>
                            <br/>
                        </li>
                        <li><b>inmultirea cu un scalar</b> ( * numar):

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import timedelta\n' +
                                'from datetime import date\n' +
                                'from datetime import datetime\n' +
                                '\n' +
                                'delta = timedelta(weeks=2, days=2, hours=2)\n' +
                                'print(delta) # 16 days, 2:00:00\n' +
                                '\n' +
                                'delta2 = delta * 2\n' +
                                'print(delta2) # 32 days, 4:00:00\n' +
                                '\n' +
                                'delta3 = delta * 2.5\n' +
                                'print(delta3) # 40 days, 5:00:00'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>adunarea cu un alt obiect de tip <b>timedelta</b></b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'from datetime import timedelta\n' +
                                'from datetime import date\n' +
                                'from datetime import datetime\n' +
                                '\n' +
                                'delta = timedelta(weeks=2, days=2, hours=2)\n' +
                                'print(delta) # 16 days, 2:00:00\n' +
                                '\n' +
                                'delta2 = delta + delta\n' +
                                'print(delta2) # 32 days, 4:00:00'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DatetimeModulePythonContent;