import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class HistoryLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-history", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa istoricul comenzilor executate:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'history'}
                    </SyntaxHighlighter>

                    Pentru a copia un intreg director, se foloseste flag-ul <b>-r</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'1059  cd learn/\n' +
                            ' 1060  ls\n' +
                            ' 1061  cd linux/\n' +
                            ' 1062  ls\n' +
                            ' 1063  rm -r a\n' +
                            ' 1064  ls\n' +
                            ' 1065  nano a.txt\n' +
                            ' 1066  history\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HistoryLinuxContent;