import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class ArpProtocolNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-arp-protocol", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Protocolul ARP</b>
                    <br/>
                    <br/>

                    <b>ARP (Address Resolution Protocol)</b> este folosit pentru a converti adresa Internet Protocol (IP) în corespondenţa sa fizică numită  MAC (Media Access Control).
                    <br/>

                    Adresa fizică odată aflată este inclusă într-o tabelă aflată în memoria RAM a calculatorului sursă.

                    Fiecare calculator din reţea are propria tabelă ARP în care înregistrează adresele MAC şi IP ale gateway-ului, precum şi propriile sale adrese MAC şi IP.
                    <br/>
                    Când vrea să transmită ceva în reţea, fiecare calculator apelează la aceste informaţii.

                    <br/>
                    <br/>
                    Afisare tabela ARP:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'arp -a'}
                    </SyntaxHighlighter>

                    Fragment:
                    <SyntaxHighlighter>
                        {'Interface: 192.168.1.3 --- 0x15\n' +
                            '  Internet Address      Physical Address      Type\n' +
                            '  192.168.1.1           fc-a6-cd-46-98-98     dynamic\n' +
                            '  192.168.1.4           7c-50-79-2c-21-49     dynamic\n' +
                            '  192.168.1.5           0c-8b-fd-92-63-19     dynamic\n' +
                            '  192.168.1.233         30-f7-72-76-70-79     dynamic\n' +
                            '  192.168.1.255         ff-ff-ff-ff-ff-ff     static\n' +
                            '  224.0.0.22            01-00-5e-00-00-16     static\n' +
                            '  224.0.0.251           01-00-5e-00-00-fb     static\n' +
                            '  224.0.0.252           01-00-5e-00-00-fc     static\n' +
                            '  239.255.255.250       01-00-5e-7f-ff-fa     static'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>192.168.1.3 - laptopul curent</li>
                        <li>192.168.1.1 - e router-ul</li>
                        <li>192.168.1.4 - e L304038</li>
                        <li>192.168.1.5 - e alt laptop</li>
                        <li>192.168.1.233 - e imprimanta</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <a target={"_blank"} href={"http://ctptc-airinei.ro/catinfo/iacSimo/html/arp.html"}>Protocolul ARP</a>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ArpProtocolNasContent;