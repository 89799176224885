import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class RoutingAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-routing", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Routing</b>
                    <br/>
                    <br/>


                    Declarare si definire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { RouterModule, Routes } from \'@angular/router\';'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const appRoutes: Routes = [\n' +
                            '   { path: \'app1\', component: App1 },\n' +
                            '   { path: \'app2\', component: App2 },\n' +
                            '   { path: \'user/:id\', component: App2 },\n' +
                            '   { path: \'\',   redirectTo: \'/about\', pathMatch: \'full\' },\n' +
                            '   { path: \'**\', component: PageNotFoundComponent }\n' +
                            '];'}
                    </SyntaxHighlighter>

                    Apoi in <i>app.module.ts</i>, adnotarea <b>@NgModule</b>, in sectiune de <b>imports</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'RouterModule.forRoot(appRoutes)'}
                    </SyntaxHighlighter>

                    Apoi se foloseste directiva:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<router-outlet></router-outlet>'}
                    </SyntaxHighlighter>

                    Navigarea se face cu ajutorul <b>routerLink</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<a routerLink="/app1">Product</a>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alternativ, putem creea un fisier <i>app</i><b>-routing.module.ts</b> in care se definesc rutele pentru modul <i>app</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { NgModule } from \'@angular/core\';\n' +
                            'import { Routes, RouterModule } from \'@angular/router\';\n' +
                            ' \n' +
                            'const routes: Routes = [];\n' +
                            ' \n' +
                            '@NgModule({\n' +
                            '  imports: [RouterModule.forRoot(routes)],\n' +
                            '  exports: [RouterModule]\n' +
                            '})\n' +
                            'export class AppRoutingModule { }'}
                    </SyntaxHighlighter>

                    Si apoi acesta se importa in <i>app</i><b>.module.ts</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'import { BrowserModule } from \'@angular/platform-browser\';\n' +
                            'import { NgModule } from \'@angular/core\';\n' +
                            ' \n' +
                            'import { AppRoutingModule } from \'./app-routing.module\';\n' +
                            'import { AppComponent } from \'./app.component\';\n' +
                            ' \n' +
                            'import { AdminModule} from \'./admin/admin.module\';\n' +
                            ' \n' +
                            '@NgModule({\n' +
                            '  declarations: [\n' +
                            '    AppComponent\n' +
                            '  ],\n' +
                            '  imports: [\n' +
                            '    BrowserModule,\n' +
                            '    AppRoutingModule, // <-- AICI\n' +
                            '    AdminModule\n' +
                            '  ],\n' +
                            '  providers: [],\n' +
                            '  bootstrap: [AppComponent]\n' +
                            '})\n' +
                            'export class AppModule { }'
                        }
                    </SyntaxHighlighter>


                    <hr/>
                    Putem adaua o clasa unui element HTML in functie de ruta activa:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<a routerLink=\'home\' routerLinkActive=\'active\'>Home</a>'}
                    </SyntaxHighlighter>
                    sau daca vrem ca link-ul sa fie exact (adica sa fie activ pentru "/", dar nu si pentru "/a"):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<div routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"></div>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Navigare programatica:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component } from \'@angular/core\';\n' +
                            'import { Router } from \'@angular/router\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  ...\n' +
                            '})\n' +
                            'export class LoginComponent {\n' +
                            '\n' +
                            '  constructor(private router: Router){}\n' +
                            '\n' +
                            '  navigate(){\n' +
                            '    this.router.navigate([\'/detail\']); // <--- AICI : array\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Navigare programatica relativa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { ActivatedRoute } from \'@angular/router\'\n' +
                            '...\n' +
                            'constructor(private activatedRoute: ActivatedRoute) {}'}
                    </SyntaxHighlighter>
                    apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.router.navigate([\'detail\'], {relativeTo: this.activatedRoute});'}
                    </SyntaxHighlighter>

                    <hr/>
                    Se poate si folosind metoda <b>navigateByUrl</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'await this.router.navigationByUrl(\'/\')'}
                    </SyntaxHighlighter>

                    <hr/>
                    Incarcare lazy a rutelor:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { RouterModule } from \'@angular/router\';\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  imports: [\n' +
                            '  ...\n' +
                            '  RouterModule.forRoot([\n' +
                            '    { path: \'\', redirectTo: \'login\', pathMatch: \'full\' },\n' +
                            '    { path: \'login\', loadChildren: () => import(\'./login/login.module\').then(m => m.LoginModule) },\n' +
                            '    { path: \'detail\', loadChildren: () => import(\'./detail/detail.module\').then(m => m.DetailModule) }\n' +
                            '  ])\n' +
                            '  ],\n' +
                            '})'}
                    </SyntaxHighlighter>
                    Observatii:
                    <ul>
                        <li>rutele incarcate layz sunt relative la parinte, adica vor contine si calea parintelui</li>
                        <li>un modul incercat lazy nu trebuie declarat in al modul, pentru ca se obtine eroare</li>
                        <li>in versiunile mai vechi se permitea sa se scrie: loadChildren: "./detail/detail.module#DetailModule" </li>
                    </ul>

                    <hr/>
                    Shared URLs:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const routes: Routes = [\n' +
                            '  {\n' +
                            '    path: \'dashboard\',\n' +
                            '    component: DashboardMainPage,\n' +
                            '  },\n' +
                            '  {\n' +
                            '    path: \'dashboard/stats\',\n' +
                            '    component: DashboardStatsPage,\n' +
                            '  },\n' +
                            '];'}
                    </SyntaxHighlighter>

                    <hr/>
                    Nested Routes:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const routes: Routes = [\n' +
                            '  {\n' +
                            '    path: \'dashboard\',\n' +
                            '    component: DashboardRouterOutlet,\n' +
                            '    children: [\n' +
                            '      {\n' +
                            '        path: \'\',\n' +
                            '        component: DashboardMainPage,\n' +
                            '      },\n' +
                            '      {\n' +
                            '        path: \'stats\',\n' +
                            '        component: DashboardStatsPage,\n' +
                            '      },\n' +
                            '    ],\n' +
                            '  },\n' +
                            '];'}
                    </SyntaxHighlighter>

                    <hr/>
                    Rute cu parametrii:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const appRoutes: Routes = [\n' +
                            '   { path: \'user/:id\', component: UserComponent },\n' +
                            '];'}
                    </SyntaxHighlighter>
                    apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, OnInit } from \'@angular/core\';\n' +
                            'import { ActivatedRoute } from \'@angular/router\';\n' +
                            '@Component({\n' +
                            '  ...\n'+
                            '})\n' +
                            'export class UserComponent implements OnInit {\n' +
                            '  \n' +

                            '  constructor(private route: ActivatedRoute) { }\n\n' +
                            '' +
                            '  ngOnInit(): void {\n' +
                            '    let id = this.route.snapshot.params[\'id\'];\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const appRoutes: Routes = [\n' +
                            '   { path: \'user/:id/:name\', component: UserComponent },\n' +
                            '];'}
                    </SyntaxHighlighter>
                    atunci <i>name</i> se obtine:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let name = this.route.snapshot.params[\'name\']'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a actualiza UI-ul daca se schimba parametrii rutei:
                    (daca suntem deja in pagina si avem un link catre pagina curenta, dar cu parametrii diferiti,
                    atunci in URL din bara browser-ului se va modifica, dar nu si pagina;
                    Angular nu detecteaza modificarea unei stari si nu face render;
                    <br/>
                    pentru a detecta aceasta modificare vom folosi <b>Subscription</b>;
                    <br/>
                    vom avea grija ca la distrugerea componentei sa fie distrusa si obiectul de tip <i>Subscription</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component, OnInit, OnDestroy } from \'@angular/core\';\n' +
                            'import { ActivatedRoute } from \'@angular/router\';\n' +
                            'import { Subscription } from \'rxjs/Subscription\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'product-details\',\n' +
                            '  template: `\n' +
                            '    <div>\n' +
                            '      Utilizator: {{id}}\n' +
                            '    </div>\n' +
                            '  `,\n' +
                            '})\n' +
                            'export class UserPage implements OnInit, OnDestroy {\n' +
                            '  id: number;\n' +
                            '  private sub: Subscription;\n' +
                            '\n' +
                            '  constructor(private route: ActivatedRoute) {}\n' +
                            '\n' +
                            '  ngOnInit() {\n' +
                            '    this.sub = this.route.params.subscribe(params => {\n' +
                            '       this.id = +params[\'id\']; // (+) converts string \'id\' to a number\n' +
                            '\n' +
                            '    });\n' +
                            '  }\n' +
                            '\n' +
                            '  ngOnDestroy() {\n' +
                            '    this.sub.unsubscribe();\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Query params (?a=b) & fragment (#aaa)</b>
                    <br/>
                    <br/>
                    Programatic: /server/5/edit?admin=1#fragment=loading

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.router.navigate(["server", 5, "edit", {queryParams:{admin:1}}, fragment:"loading"])'}
                    </SyntaxHighlighter>

                    Link: /server/5/edit?admin=1#fragment=loading
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<a [routerLink]="[\'/server\',5,\'edit\']" [queryParams]="{admin:\'1\'}" fragment="loading">Link</a>'}
                    </SyntaxHighlighter>

                    Acesare date din Query params & fragment:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.route.snapshot.queryParams'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.route.snapshot.fragment'}
                    </SyntaxHighlighter>
                    sau (varianta mai buna):
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.route.queryParams.subscribe(....)'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.route.fragment.subscribe(....)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Rute incuibarite sau cu copii</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export const routes: Routes = [\n' +
                            '  { path: \'product\', component: Product,\n' +
                            '    children: [\n' +
                            '      { path: \':id\', component: ProductDetails },\n' +
                            '      { path: \':id/edit\', component: EditProduct }\n' +
                            '    ]\n' +
                            '  }\n' +
                            '];'}
                    </SyntaxHighlighter>

                    In Product, ar trebuie sa existe, in template HTML:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<router-outlet></router-outlet>  '}
                    </SyntaxHighlighter>
                    care va gestiona routarea catre copii.

                    <br/>
                    <br/>
                    Navigare relativa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'this.router.navigate(["edit"],{relativeTo: this.route, queryParamsHandling: "preserve")'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Rute redirect</b>
                    <br/>
                    <br/>
                    Se foloseste <b>redirectTo</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{path: "/ceva", redirectTo: "/not-found"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Rute wildcard (**)</b>
                    <br/>
                    <br/>

                    Se foloseste <b>redirectTo</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{path: "**", redirectTo: "/not-found"'}
                    </SyntaxHighlighter>
                    Observatie:
                    <ul>
                        <li>
                            trebuie sa fie ultima ruta definita
                        </li>
                    </ul>

                    <b>În mod implicit, Angular potrivește căile după prefix</b>.
                    Angular verifică dacă calea introdusă în URL începe cu calea specificată în ruta.
                    Desigur, fiecare cale începe cu ''.
                    <br/>

                    Pentru a schimba acest comportament, trebuie modificata strategia de potrivire in <b>full</b>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{ path: \'\', redirectTo: \'/somewhere-else\', pathMatch: \'full\' } '}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Externalizarea configuratiei de rute</b>
                    <br/>
                    <br/>

                    Se creaza un nou fisier <i>app-</i><b>routing.module.ts</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { NgModule } from \'@angular/core\';\n' +
                            'import { Routes, RouterModule } from \'@angular/router\';\n' +
                            '\n' +
                            'import { PageNotFoundComponent } from \'./page-not-found/page-not-found.component\';\n' +
                            'import { EditServerComponent } from \'./servers/edit-server/edit-server.component\';\n' +
                            'import { ServerComponent } from \'./servers/server/server.component\';\n' +
                            'import { ServersComponent } from \'./servers/servers.component\';\n' +
                            'import { UserComponent } from \'./users/user/user.component\';\n' +
                            'import { UsersComponent } from \'./users/users.component\';\n' +
                            'import { HomeComponent } from \'./home/home.component\';\n' +
                            'import { ErrorPageComponent } from \'./error-page/error-page.component\';\n' +

                            '\n' +
                            'const appRoutes: Routes = [\n' +
                            '  { path: \'\', component: HomeComponent },\n' +
                            '  { path: \'users\', component: UsersComponent, children: [\n' +
                            '      { path: \':id/:name\', component: UserComponent }\n' +
                            '  ] }, \n' +

                            '  {\n' +
                            '    path: \'servers\',\n' +
                            '    children: [\n' +
                            '      { path: \':id\', component: ServerComponent},\n' +
                            '      { path: \':id/edit\', component: EditServerComponent }\n' +
                            '  ] },\n' +
                            '  { path: \'not-found\', component: ErrorPageComponent },\n' +
                            '  { path: \'**\', redirectTo: \'/not-found\' }\n' +
                            '];\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  imports: [\n' +
                            '    RouterModule.forRoot(appRoutes)\n' +
                            '  ],\n' +
                            '  exports: [RouterModule]\n' +
                            '})\n' +
                            'export class AppRoutingModule {\n' +
                            '\n' +
                            '}'}

                    </SyntaxHighlighter>

                    Ce este de retinut este <b>exports</b> din <b>@NgModule</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'exports: [RouterModule]'}
                    </SyntaxHighlighter>

                    Apoi, in <i>app.module.ts</i> in sectiunea de <b>imports</b> din <b>NgModule</b>, trebuie adaugat si:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'imports: [AppRoutingModule]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Generare modul de routing</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng g module modululmeu --routing'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'CREATE src/main/webapp/app/modululmeu/modululmeu-routing.module.ts (253 bytes)\n' +
                            'CREATE src/main/webapp/app/modululmeu/modululmeu.module.ts (296 bytes)\n'}
                    </SyntaxHighlighter>

                    cu (modululmeu.module.ts):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { NgModule } from \'@angular/core\';\n' +
                            'import { CommonModule } from \'@angular/common\';\n' +
                            '\n' +
                            'import { ModululmeuRoutingModule } from \'./modululmeu-routing.module\';\n' +
                            '\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  declarations: [],\n' +
                            '  imports: [\n' +
                            '    CommonModule,\n' +
                            '    ModululmeuRoutingModule\n' +
                            '  ]\n' +
                            '})\n' +
                            'export class ModululmeuModule { }\n'}
                    </SyntaxHighlighter>
                    cu (modululmeu-routing.module.ts):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { NgModule } from \'@angular/core\';\n' +
                            'import { RouterModule, Routes } from \'@angular/router\';\n' +
                            '\n' +
                            'const routes: Routes = [];\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  imports: [RouterModule.forChild(routes)],\n' +
                            '  exports: [RouterModule]\n' +
                            '})\n' +
                            'export class ModululmeuRoutingModule { }\n'}
                    </SyntaxHighlighter>
                    De remarcat:
                    <SyntaxHighlighter>
                        {'RouterModule.forChild(routes)'}
                    </SyntaxHighlighter>
                    este pentru a înregistra rute imbricate într-un modul lazy-loaded;
                    {/*<SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>*/}
                    {/*    {'RouterModule.forRoot(appRoutes)'}*/}
                    {/*</SyntaxHighlighter>*/}

                    <br/>

                    In <i>app.module.ts</i> trebuie importat:
                    <SyntaxHighlighter>
                        {'imports: [BrowserModule, AppRoutingModule, ModululmeuModule],'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RoutingAngularContent;