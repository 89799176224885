import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class BuffersTimeoutsNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-buffers-timeouts", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Buffers (memoria tampon) & Timeout</b>

                    <br/>
                    <br/>

                    Aceste configurari nu sunt atat de dependente de server, cat de natura cererii care se face serverului.
                    <br/>
                    <br/>
                    <b>Buffering</b> este un proces de a citi informatia din RAM inainte de a o scrie catre destinatie.
                    <br/>
                    <br/>
                    De exemplu, nginx receptioneaza o cerere ceea ce inseamana ca <i>citeste din portul TCP</i> si scrie datele in RAM;
                    Daca buffer-ul este prea mic, atunci o parte din date sunt scrise pe HDD.
                    <br/>
                    <br/>
                    Atunci cand nginx raspunde de exemplu cu un fisier static, <i>citeste de pe disc</i> si scrie datele in RAM;
                    si trimite datele catre client din memorie.
                    <br/>
                    <br/>
                    <b>Timeout</b> inseamana o limita pentru un anumit eveniment.
                    <br/>
                    <br/>
                    De exemplu, daca se face o cerere de la client, aceasta cerere este oprita dupa un numar de secunde pentru a preveni blocarea serverului.

                    <br/>
                    <br/>

                    Unitati de masura pentru dimensiuni:
                    <ul>
                        <li>
                            (nespecificat): inseamna bytes (ex: 10)
                        </li>
                        <li>
                            k/K: kilobytes (ex: 10k / 10K)
                        </li>
                        <li>
                            m/M: megabytes (ex: 10m / 10M)
                        </li>
                    </ul>

                    Unitati de masura pentru timp:
                    <ul>
                        <li>
                            (nespecificat): insesmna milisecunde (ex: 10)
                        </li>
                        <li>
                            s: secunde (ex: 10s)
                        </li>
                        <li>
                            m: minute (ex: 10m)
                        </li>
                        <li>
                           h: ore (ex: 10h)
                        </li>
                        <li>
                            d: zile (ex: 10d)
                        </li>
                    </ul>

                    Directive:
                    <ul>
                        <li><b>client_body_buffer_size</b>: cata memorie este alocata pentru a tine in buffer datele dintr-un POST venit de la client;
                            <br/>
                            exemplu: client_body_buffer_size 10K;
                            <br/>
                            daca se specifica o diminesiune prea mare se va aloca si irosi memoria de pe server;
                            <br/>
                            daca este prea mica aceasta dimensiune atunci nginx va scrie o parte pe disk, ceea ce inseamna foarte mult timp pierdut;
                        </li>
                        <li><b>client_max_body_size</b>: nu se accept cereri POST mai mari 8M; daca e prea mare se va raspunde cu: <b>413 "Request Entity too Large"</b>
                            <br/>
                            exemplu: client_max_body_size 8m;
                        </li>

                        <li><b>client_header_buffer_size</b>:
                            setează dimensiunea memoriei tampon (buffer) pentru citirea antetului (headr) din cererea (request) clientului.
                            Pentru majoritatea solicitărilor, un buffer de 1K octeți este suficient.
                            <br/>
                            exemplu: client_header_buffer_size 1k;
                        </li>

                        <li><b>client_body_timeout</b>: timpul maxim pentru primirea body-ului de la client;
                            <br/>
                            acest timp nu se refera la timpul pe care il ia pentru transmiterea intregului body request, ci timpul dintre doua citiri consecutive.
                        </li>
                        <li><b>client_header_timeout</b>: timpul maxim pentru primirea headr-ului de la client</li>

                        <li><b>keepalive_timeout</b>: timpul maxim pentru a menține o conexiune deschisă de catre nginx (util, de exemplu la transmitere de fisiere mai mari)</li>

                        <li><b>send_timeout</b>: timpul maxim pentru acceptarea/primirea unui răspuns de către client</li>

                        <li><b>sendfile on</b>: se omite memoria tampon pentru fișierele statice; se trimit direct</li>

                        <li><b>tcp_nopush on;</b>: optimizeaza trimiterea pachetelor de fișiere</li>

                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'user www-data;\n' +
                            '\n' +
                            'worker_processes auto;\n' +
                            '\n' +
                            'events {\n' +
                            '  worker_connections 1024;\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  # Buffer size for POST submissions\n' +
                            '  client_body_buffer_size 10K;\n' +
                            '  client_max_body_size 8m;\n' +
                            '\n' +
                            '  # Buffer size for Headers\n' +
                            '  client_header_buffer_size 1k;\n' +
                            '\n' +
                            '  # Max time to receive client headers/body\n' +
                            '  client_body_timeout 12;\n' +
                            '  client_header_timeout 12;\n' +
                            '\n' +
                            '  # Max time to keep a connection open for\n' +
                            '  keepalive_timeout 15;\n' +
                            '\n' +
                            '  # Max time for the client accept/receive a response\n' +
                            '  send_timeout 10;\n' +
                            '\n' +
                            '  # Skip buffering for static files\n' +
                            '  sendfile on;\n' +
                            '\n' +
                            '  # Optimise sendfile packets\n' +
                            '  tcp_nopush on;\n' +
                            '\n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 80;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '\n' +
                            '    root /sites/demo;\n' +
                            '\n' +
                            '    index index.php index.html;\n' +
                            '\n' +
                            '    location / {\n' +
                            '      try_files $uri $uri/ =404;\n' +
                            '    }\n' +
                            '\n' +
                            '    location ~\\.php$ {\n' +
                            '      # Pass php requests to the php-fpm service (fastcgi)\n' +
                            '      include fastcgi.conf;\n' +
                            '      fastcgi_pass unix:/run/php/php7.1-fpm.sock;\n' +
                            '    }\n' +
                            '\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BuffersTimeoutsNginxContent;