import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class TaintsTolerationsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-taints-tolerations", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Noduri taint (alterate). Poduri tolerate</b>
                    <br/>
                    <br/>

                    Un nod poate fi <i>marcat/alterat</i> si nici un pod nu va mai pus pe acel nod.
                    Cu exceptia pod-urilor <i>tolerate</i>
                    <br/>
                    Marcare/alterare/tainted nod:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes node-name key=value:taint-effect'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <b>taint-effect</b> = ce se intampla cu POD-urile care nu sunt TOLERATE de acest taint;
                            <br/>
                            poate avea urmatoarele valori:
                            <ul>
                                <li>
                                    NoSchedule: nu este planificat pe acest nod
                                </li>
                                <li>
                                    PreferNoSchedule: sistemul va incerca sa nu-l puna pe acest nod, dar nu este garantat acest lucru
                                </li>
                                <li>
                                    NoExecute: noile pod-uri nu vor planificate pe acest nod, iar vechile pod-uri, daca exista vor fi evacuate daca nu sunt tolerate de nod;
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu taint nod:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint nodes nod1 culoare=verde:NoExecute'}
                    </SyntaxHighlighter>

                    Exemplu toleratie pod:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: \n' +
                            'kind: Pod\n' +
                            'metadata: \n' +
                            '  name: myapp-pod\n' +
                            'spec: \n' +
                            '  containers: \n' +
                            '  - name: nginx-container\n' +
                            '    image: nginx\n' +
                            '  tolerations: \n' +
                            '  - key: "culoare"\n' +
                            '    operator: "Equals"\n' +
                            '    value: "verde"\n' +
                            '    effect: "NoExecute"'}
                    </SyntaxHighlighter>

                    Daca un pod are toleratie, asta inseamna ca poate fi pus pe un nod cu taint care accepta aceasta toleratie, dar si pe orice alt nod care nu este taint.

                    <br/>
                    Nodul master este taint:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe node kubemaster | grep Taint'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Taints: node-role.kubernetes.io/master:NoSchedule'}
                    </SyntaxHighlighter>

                    <hr/>
                    Stergere taint de pe un nod:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl taint node controlplane node-role.kubernetes.io/control-plane:NoSchedule-'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TaintsTolerationsK8sContent;