import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class UltrasonicDistanceArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-ultrasonic-distance", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Senzori de distanta</b>
                    <br/>
                    <br/>

                    Exista mai multe tipuri de senzori:
                    <ul>
                        <li>senzori bazati pe ultrasunete</li>
                        <li>senzori bazati pe reflexia unei raze de lumina infrarosie</li>
                        <li>senzori de tip laser</li>
                    </ul>

                    <hr/>
                    <b>2. Senzori ultrasonici</b>
                    <br/>
                    <br/>

                    Senzorii de distanta determina distanta dintre senzor si un obiect din fata lor.
                    <br/>
                    Se bazeaza pe emiterea de ultasunete si masurarea timpului necesar sa se intoarca ecoul.
                    <br/>
                    <br/>

                    Senzori ultrasonici:
                    <ul>
                        <ul>
                            PING -
                            <br/>
                            Senzorul are 3 pini:
                            <ul>
                                <li>
                                    GND - conectarea la masa (se conecteaza la un pin de tip GND)
                                </li>
                                <li>
                                    5V (se conecteaza la un pin de 5V)
                                </li>
                                <li>
                                    SIV - semnal (se conecteaza la orice pin digital);
                                    <br/>
                                    Se foloseste ca trigger si pentru citirea semnalului (HC-SR04 - are pini separati)
                                </li>
                            </ul>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_3.png'}/>
                            </div>

                        </ul>
                        <li>
                            HC-SR04 - poate determina distanta fata de obiecte situate intre 2cm si 400cm, folosind unde ultrasonice cu frecenta de 40kHz
                            la un unghi de maxim 15 grade, cu o precizie de 3mm.

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_10.png'}/>
                            </div>

                            <br/>
                            Dimenziunile senzorului sunt de 45mm x 20 mm x 15 mm.
                            <br/>
                            Senzorul are nevoie de functionare de un curent cu intensitatea de 15mA.
                            <br/>
                            Senzorul are 4 pini:
                            <ul>
                                <li>
                                    alimentare (Vcc)
                                </li>
                                <li>
                                    initierea semnalului (Trig)
                                </li>
                                <li>
                                    receptarea raspunului (Echo)
                                </li>
                                <li>
                                    conectarea la masa (GND)
                                </li>
                            </ul>
                            Se trimite un semnal folosind pimul Trig (e.g.: Trigger), acesta avand durata de 10 microsedunde.
                            <br/>
                            Se astapta raspunsul pe pinul Echo.
                            <br/>
                            Durata dintre cele 2 evenimente T, va fi folosita pentru masurarea distantei d ( d = T / 58)

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_4.png'}/>
                            </div>
                            Fiecare ochisor este marcat cu:
                            <ul>
                                <li>
                                    T - transmitter (trasmitator)
                                </li>
                                <li>
                                    R - receiver (receptor)
                                </li>
                            </ul>

                        </li>

                    </ul>


                    Cod:
                    <SyntaxHighlighter>
                        {'const int trigPin=7;\n' +
                            'const int echoPin=8; \n' +
                            '\n' +
                            'void setup() {\n' +
                            '  Serial.begin(9600);\n' +
                            '\n' +
                            '  pinMode(trigPin, OUTPUT);\n' +
                            '  pinMode(echoPin, INPUT);\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '\n' +
                            '  // se trimite un impus de 10 microsedunde\n' +
                            '  digitalWrite(trigPin, LOW); // se trimite un scurt impuls LOW, pentru a se asigura ca avam un HIGH curat\n' +
                            '  delayMicroseconds(2);\n' +
                            '  digitalWrite(trigPin, HIGH);\n' +
                            '  delayMicroseconds(10);\n' +
                            '  digitalWrite(trigPin, LOW);\n' +
                            '\n' +
                            '  // se calculeaza distanta\n' +
                            '  long durata = pulseIn(echoPin, HIGH);\n' +
                            '\n' +
                            '  long distanta = toCentimeters(durata);\n' +
                            '\n' +
                            '  Serial.println(distanta);\n' +
                            '  delay(100);\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'long toInches(long microseconds){\n' +
                            '  // viteza sunetului este de 1130 feet per secunde\n' +
                            '  // sau 73.746 microsecunde pet inch\n' +
                            '  // se imparte la 2 pentru ca distanta e parcursa de 2 ori: \n' +
                            '  // de la senzor de la obstacol + de la obstacol la senzor\n' +
                            '  return microseconds / 74 / 2; \n' +
                            '}\n' +
                            '\n' +
                            'long toCentimeters(long microseconds){\n' +
                            '  // viteza sunetului este de 340 m/s \n' +
                            '  // sau 29 microsedunde per centimetru\n' +
                            '  // se imparte la 2 pentru ca distanta e parcursa de 2 ori: \n' +
                            '  // de la senzor de la obstacol + de la obstacol la senzor\n' +
                            '  return microseconds / 29 / 2;\n' +
                            '}\n' +
                            '\n'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            functia <b>pulseIn</b> - detecteaza un puls pe un pin si returneaza timpul scurs de la emiterea acestuia de catre pinul Trigger, exprimat in microsecunde.
                        </li>
                    </ul>

                    Cod (fara functia <b>pulseIn()</b> - acesta functie blocheaza executia altor instructiuni; se vor folosi intreruperi):
                    <SyntaxHighlighter>
                        {'#define ECHO_PIN 3\n' +
                            '#define TRIGGER_PIN 4\n' +
                            '\n' +
                            'unsigned long lastTimeUltrasonicTrigger = millis();\n' +
                            'unsigned long ultrasonicTriggerDelay = 60;\n' +
                            '\n' +
                            'volatile unsigned long pulseInTimeBegin;\n' +
                            'volatile unsigned long pulseInTimeEnd;\n' +
                            'volatile bool newDistanceAvailable = false;\n' +
                            '\n' +
                            'double previousDistance = 400.0;\n' +
                            '\n' +
                            'void triggerUltrasonicSensor()\n' +
                            '{\n' +
                            '  digitalWrite(TRIGGER_PIN, LOW);\n' +
                            '  delayMicroseconds(2);\n' +
                            '  digitalWrite(TRIGGER_PIN, HIGH);\n' +
                            '  delayMicroseconds(10);\n' +
                            '  digitalWrite(TRIGGER_PIN, LOW);\n' +
                            '}\n' +
                            '\n' +
                            'double getUltrasonicDistance()\n' +
                            '{\n' +
                            '  double durationMicros = pulseInTimeEnd - pulseInTimeBegin;\n' +
                            '  double distance = durationMicros / 58.0; // cm (148.0: inches)\n' +
                            '  if (distance > 400.0) { // ignora distantele mai mari de 400 cm, (vezi: specificatiile senzorului)\n' +
                            '    return previousDistance;\n' +
                            '  }\n' +
                            '  distance = previousDistance * 0.7 + distance * 0.3; // filtru complementar\n' +
                            '  previousDistance = distance;\n' +
                            '  return distance;\n' +
                            '}\n' +
                            '\n' +
                            'void echoPinInterrupt()\n' +
                            '{\n' +
                            '  if (digitalRead(ECHO_PIN) == HIGH) { // start measuring\n' +
                            '    pulseInTimeBegin = micros();\n' +
                            '  }\n' +
                            '  else { // stop measuring\n' +
                            '    pulseInTimeEnd = micros();\n' +
                            '    newDistanceAvailable = true;\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  Serial.begin(115200);\n' +
                            '  pinMode(ECHO_PIN, INPUT);\n' +
                            '  pinMode(TRIGGER_PIN, OUTPUT);\n' +
                            '\n' +
                            '\n' +
                            '  attachInterrupt(digitalPinToInterrupt(ECHO_PIN),\n' +
                            '                  echoPinInterrupt,\n' +
                            '                  CHANGE);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  unsigned long timeNow = millis();\n' +
                            '  \n' +
                            '  if (timeNow - lastTimeUltrasonicTrigger > ultrasonicTriggerDelay) {\n' +
                            '    lastTimeUltrasonicTrigger += ultrasonicTriggerDelay;\n' +
                            '    triggerUltrasonicSensor();\n' +
                            '  }\n' +
                            '\n' +
                            '  if (newDistanceAvailable) {\n' +
                            '    newDistanceAvailable = false;\n' +
                            '    double distance = getUltrasonicDistance();\n' +
                            '  \n' +
                            '    Serial.println(distance);\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=zACmjwvbils&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=8&ab_channel=ScienceBuddies"}>
                                PING Ultrasonic Distance Sensor and Arduino (Lesson #8)
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=n-gJ00GTsNg&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=9&ab_channel=ScienceBuddies"}>
                                HC-SR04 Ultrasonic Distance Sensor and Arduino (Lesson #9)
                            </a>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UltrasonicDistanceArduinoContent;