import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MetaprogrammingPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-metaprogramming", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Introducere</b>
                    <br/>
                    <br/>

                    Metaprogramarea este o tehnică de programare în care programele de calculator au capacitatea de a <i>modifica codul propriu sau ale altor programe</i>.
                    <br/>
                    <br/>
                    Exemple de metaprogramare:
                    <ul>
                        <li>implementare decoratori</li>
                        <li>suprascriere operatorii</li>
                        <li>conceptul de metaclasă</li>
                    </ul>

                    O <b>metaclasă</b> este o clasă ale cărei instanțe sunt clase.

                    <br/>
                    <br/>
                    O clasă obișnuită definește comportamentul unui obiect, o metaclasă permite personalizarea instanțierii clasei.

                    <br/>
                    <br/>

                    Funcționalitatea unei metaclasei coincide parțial cu cea a decoratorilor de clasă, dar:

                    <ul>
                        <li>decoratorii <i>leagă</i> numele funcțiilor sau claselor decorate <i>de noi obiecte</i> apelabile;
                            decoratorii de clasa sunt aplicați când clasele sunt instanțiate
                        </li>
                        <li>metaclasele redirecționează procesul de instantiere a claselor către logica definita în metaclase;
                            metaclasele sunt aplicabile atunci cand definitiile claselor sunt citite pentru a creea clase (deci, inainte de instantierea clasei)
                        </li>
                    </ul>

                    Cazurile de utilizare tipice pentru metaclase:

                    <ul>
                        <li>logare</li>
                        <li>adăugarea automată de noi metode</li>
                        <li>adăugarea automată de noi variabile</li>
                        <li>verificarea interfeței</li>
                        <li>înregistrarea claselor la momentul creării</li>
                        <li>cand se doreste schimbarea automata a claselor, iar decoratorii nu sunt eficienti</li>
                    </ul>

                    In Python, orice este un obiect și fiecare obiect are un anumit tip asociat cu el. Pentru a obține tipul unui obiect, se poate utiliza funcția <b>type()</b>.

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Soare:\n' +
                        '    pass\n' +
                        '\n' +
                        'print(type(10))       # <class \'int\'>\n' +
                        'print(type([1,2,3]))  # <class \'list\'>\n' +
                        'print(type(Soare()))  # <class \'__main__.Soare\'>\n' +
                        'print(type(Soare))    # <class \'type\'>\n' +
                        '\n' +
                        'for t in (int, list, type):\n' +
                        '    print(type(t))    # <class \'type\'>'}
                    </SyntaxHighlighter>

                    Deci:
                    <ul>
                        <li>metadatele sunt folosite pentru a creea clase (clasa este o instanta de metadata)</li>
                        <li>clasele sunt folosite pentru a creea obiecte (obiectul este o instanta de clasa)</li>
                        <li>metadata este de tip <b>type</b></li>
                        <li><b>type</b> este o <b>clasă</b> care generează clase definite de un programator</li>
                        <li>metaclasele sunt subclase ale clasei de <b>type</b></li>
                    </ul>

                    Atribute speciale:
                    <ul>
                        <li><b>__nume__</b>: (pentru clase) contine nume clasei</li>
                        <li><b>__class__</b>: (pentru clase si obiecte) contine informații despre clasa căreia îi aparține o instanță de clasă; identic cu apelarea functiei <b>type(obiect)</b> cu un singur argument</li>
                        <li><b>__baze__</b>: (pentru clase) tuplu care conține informații despre clasele de bază ale unei clase</li>
                        <li><b>__dict__</b>: (pentru clase si obiecte) contine un dictionar (sau alt tip de mapare) cu atributele obiectului</li>

                    </ul>

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Soare:\n' +
                        '    pass\n' +
                        '\n' +
                        's =  Soare()\n' +
                        'print( Soare.__name__)  # Soare\n' +
                        'print( Soare.__class__) # <class \'type\'>\n' +
                        'print( type(Soare))     # <class \'type\'>\n' +
                        'print( Soare.__bases__) # (<class \'object\'>,)\n' +
                        'print( Soare.__dict__)  # {\'__module__\': \'__main__\', \'__dict__\': <attribute \'__dict__\' of \'Soare\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'Soare\' objects>, \'__doc__\': None}\n' +
                        '\n' +
                        'print( s.__class__)     # <class \'__main__.Soare\'>\n' +
                        'print( type(s))         # <class \'__main__.Soare\'>\n' +
                        'print( s.__dict__)      # {}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Creare clase folosind functia type()</b>
                    <br/>
                    <br/>
                    Pentru a creea o clasa noua se poate folosi functia <b>type(nume_clasa, tuplu_clase_de_baza, dictionar)</b> cu urmatoarele 3 argumente:
                    <ul>
                        <li>nume_clasa; această valoare va fi retinuta in atributul <b>__name__</b> al clasei</li>
                        <li>tuplu_clase_de_baza: specifică un tuplu cu clasele de bază ( atributul clasei <b>__bases__</b> va prelua aceasta valoare)</li>
                        <li>dictionar: specifică un dicționar de spațiu de nume care conține definiții de metode si variabile pentru corpul clasei ( atributul clasei <b>__dict__</b> va prelua aceasta valoare)</li>
                    </ul>


                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'Soare = type(\'Soare\', (), {})\n' +
                        '\n' +
                        'print(Soare.__name__)  # Soare\n' +
                        'print(Soare.__class__) # <class \'type\'>\n' +
                        'print(Soare.__bases__) # (<class \'object\'>,)\n' +
                        'print(Soare.__dict__)  # {\'__module__\': \'__main__\', \'__dict__\': <attribute \'__dict__\' of \'Soare\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'Soare\' objects>, \'__doc__\': None}\n'}
                    </SyntaxHighlighter>

                    Functia <b>type()</b> este responsabila cu:
                    <ul>
                        <li>apelarea metodei <b>__call__()</b> la crearea instanței de clasă; aceasta metoda apeleaza alte 2 metode:
                            <ul>
                                <li>metoda <b>__new__()</b> responsabila cu crearea instanței de clasă în memoria computerului;
                                    <br/>
                                    această metodă este rulată înainte de __init__()</li>
                                <li>metoda <b>__init__()</b>, responsabila cu inițializarea obiectului</li>
                            </ul>
                        </li>
                    </ul>

                    Metaclasele implementează de obicei aceste două metode (<b>__init__</b>, <b>__new__</b>), preluând controlul asupra procesului de creare și inițializare a unei noi instanțe de clasă.

                    <br/><br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'def saFieZiua(self):\n' +
                        '    print("Ziua")\n' +
                        '    \n' +
                        'Soare = type(\'Soare\', (), {\'cu_dinti\':True, \'saFieZiua\': saFieZiua})\n' +
                        '\n' +
                        '\n' +
                        'soare = Soare()\n' +
                        'print(soare.cu_dinti) # True\n' +
                        'soare.saFieZiua()     # Ziua'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Creare metaclasa</b>
                    <br/>
                    <br/>

                    O metaclasa este o clasa care extinde tipul <b>type</b>.
                    Pentru a creea o metaclasa trebuie:
                    <ul>
                        <li>sa extinda tipul <b>type</b></li>
                        <li>sa aiba propria definitie a metodei <b>__new__()</b> care va apela metoda <b>__new__()</b> din clasa parinte (super().__new__(mcs, name, bases, dictionary));
                            <br/>
                            <i>mcs</i> - se refera la clasa (e doar o conventie)
                            <br/>
                            metoda <b>__new__</b> va returna clasa (de fapt o instanta de tip clasa)
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class MyMeta(type):\n' +
                        '    def __new__(mcs, name, bases, dictionary):\n' +
                        '        obj = super().__new__(mcs, name, bases, dictionary)\n' +
                        '        obj.atribut = \'atribut meta\'\n' +
                        '        return obj\n' +
                        '\n' +
                        'class MyClass(metaclass=MyMeta):\n' +
                        '    pass\n' +
                        '\n' +
                        'print(MyClass.__dict__) # {\'__module__\': \'__main__\', \'__dict__\': <attribute \'__dict__\' of \'MyClass\' objects>, \'__weakref__\': <attribute \'__weakref__\' of \'MyClass\' objects>, \'__doc__\': None, \'atribut\': \'atribut meta\'}\n'}
                    </SyntaxHighlighter>

                    Pentru a folosi metaclasa pentru a creea o clasa, trebuie:
                    <ul>
                        <li>specificat <b>metaclass=NumeMetaclasa</b> in definitia clasei;
                            <br/>
                            (daca se scrie doar class MyClass(MyMeta): (fara <b>metaclass</b>), atunci valoarea returnata de <b>__dict__</b> va fi: '__module__': '__main__', '__doc__': None pentru ca se considera extindere a unei clase obisnuite si nu o specificare de constuirea a clasei pe baza unei metaclase)
                        </li>
                    </ul>

                    Daca ne uitam la valoarea returnata de <b>__dict__</b> vom regasi atributul definit in metadata (atribut cu valoare 'atribut meta').

                    <br/>
                    <br/>
                    Metaclasele pot controla procesul de instanțiere a clasei și pot ajusta clasele create pentru a se conforma cu niste reguli (de exemplu, sa aiba o metoda in mod implicit; daca in clasa definita de programator nu exista definita o anumita metoda, care ar trebuie sa exista).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def buna(self):\n' +
                        '    print(\'buna!\')\n' +
                        '\n' +
                        'class MyMeta(type):\n' +
                        '    def __new__(mcs, name, bases, dictionary):\n' +
                        '        if \'buna\' not in dictionary:\n' +
                        '            dictionary[\'buna\'] = buna\n' +
                        '        obj = super().__new__(mcs, name, bases, dictionary)\n' +
                        '        return obj\n' +
                        '\n' +
                        'class C1(metaclass=MyMeta):\n' +
                        '    pass\n' +
                        '\n' +
                        'class C2(metaclass=MyMeta):\n' +
                        '    def buna(self):\n' +
                        '        print(\'neata!\')\n' +
                        '\n' +
                        'c1 = C1()\n' +
                        'c1.buna() # buna!\n' +
                        'c2 = C2()\n' +
                        'c2.buna() # neata!\n'}
                    </SyntaxHighlighter>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MetaprogrammingPythonContent;