import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class NavigationThemeContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-navigation-theme", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Vom modifica <b>navigarea</b> aplicatiei folosindu-ne de SCSS:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/anatomy-theme-3.png'}/>
                    </div>

                    <SyntaxHighlighter  showLineNumbers={true} language="scss" style={androidstudio}>
                        {'$base-color: #2d3142;\n' +
                        '$a-color:#ef8354;\n' +
                        '$a-color-hover:#ef783a;\n' +
                        '\n' +
                        '$nav-bg:#f5dfbb;\n'+
                        '\n'+
                        '#navigation{\n' +
                        '\n' +
                        '    margin-right: -15px;\n' +
                        '    margin-left:-15px;\n' +
                        '    background-color:$nav-bg;\n' +
                        '    height: 56px;\n' +
                        '\n' +
                        '    ul {\n' +
                        '      list-style-type: none;\n' +
                        '    }\n' +
                        '\n' +
                        '    li {\n' +
                        '      float: left;\n' +
                        '    }\n' +
                        '\n' +
                        '    li a {\n' +
                        '      display: block;\n' +
                        '      color: $a-color;\n' +
                        '      text-align: center;\n' +
                        '      padding: 16px;\n' +
                        '      text-decoration: none;\n' +
                        '      background: $nav-bg;\n' +
                        '    }\n' +
                        '\n' +
                        '    li a:hover {\n' +
                        '      color:white;\n' +
                        '      background-color: $a-color-hover\n' +
                        '    }\n' +
                        '\n' +
                        '    li.selected a {\n' +
                        '      color:white;\n' +
                        '      background-color: #ef651c\n' +
                        '    }\n' +
                        '\n' +
                        '    ul ul{\n' +
                        '      z-index: 1000;\n' +
                        '      display: none;\n' +
                        '      position:absolute;\n' +
                        '    }\n' +
                        '\n' +
                        '    ul li {\n' +
                        '      display: block;\n' +
                        '      position:relative;\n' +
                        '    }\n' +
                        '\n' +
                        '    ul li:hover ul {\n' +
                        '      display: block;\n' +
                        '\n' +
                        '      background: $nav-bg;\n' +
                        '      box-shadow: 0px 3px 6px #00000029;\n' +
                        '\n' +
                        '      position: absolute;\n' +
                        '      padding: 0px;\n' +
                        '    }\n' +
                        '\n' +
                        '    ul li:hover ul li a{\n' +
                        '      display: block;\n' +
                        '    }\n' +
                        '\n' +
                        '    ul li:hover ul li{\n' +
                        '      width: 100%;\n' +
                        '      min-width: 200px;\n' +
                        '    }\n' +
                        '\n' +
                        '    ul li:hover ul li a:hover{\n' +
                        '      display: block;\n' +
                        '      background-color: $a-color-hover;\n' +
                        '    }\n' +
                        '\n' +
                        '  }'}
                    </SyntaxHighlighter>


                    Pentru elementul <b>ul</b> vom adauga clasa <b>container</b> in fisierul template <b>navigation.ftl</b> (comparativ cu fisierul original generat):
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<nav class="${nav_css_class}" id="navigation" role="navigation">\n' +
                        '\t<ul aria-label="<@liferay.language key="site-pages" />" role="menubar" class="container">\n' +
                        '\t\t<#list nav_items as nav_item>\n' +
                        '\t\t\t<#assign\n' +
                        '\t\t\t\tnav_item_attr_has_popup = ""\n' +
                        '\t\t\t\tnav_item_css_class = ""\n' +
                        '\t\t\t\tnav_item_layout = nav_item.getLayout()\n' +
                        '\t\t\t/>\n' +
                        '\n' +
                        '\t\t\t<#if nav_item.isSelected()>\n' +
                        '\t\t\t\t<#assign\n' +
                        '\t\t\t\t\tnav_item_attr_has_popup = "aria-haspopup=\'true\'"\n' +
                        '\t\t\t\t\tnav_item_css_class = "selected"\n' +
                        '\t\t\t\t/>\n' +
                        '\t\t\t</#if>\n' +
                        '\n' +
                        '\t\t\t<li class="${nav_item_css_class}" id="layout_${nav_item.getLayoutId()}" role="presentation">\n' +
                        '\t\t\t\t<a aria-labelledby="layout_${nav_item.getLayoutId()}" ${nav_item_attr_has_popup} href="${nav_item.getURL()}" ${nav_item.getTarget()} role="menuitem"><span><@liferay_theme["layout-icon"] layout=nav_item_layout /> ${nav_item.getName()}</span></a>\n' +
                        '\n' +
                        '\t\t\t\t<#if nav_item.hasChildren()>\n' +
                        '\t\t\t\t\t<ul class="child-menu" role="menu">\n' +
                        '\t\t\t\t\t\t<#list nav_item.getChildren() as nav_child>\n' +
                        '\t\t\t\t\t\t\t<#assign\n' +
                        '\t\t\t\t\t\t\t\tnav_child_css_class = ""\n' +
                        '\t\t\t\t\t\t\t/>\n' +
                        '\n' +
                        '\t\t\t\t\t\t\t<#if nav_item.isSelected()>\n' +
                        '\t\t\t\t\t\t\t\t<#assign\n' +
                        '\t\t\t\t\t\t\t\t\tnav_child_css_class = "selected"\n' +
                        '\t\t\t\t\t\t\t\t/>\n' +
                        '\t\t\t\t\t\t\t</#if>\n' +
                        '\n' +
                        '\t\t\t\t\t\t\t<li class="${nav_child_css_class}" id="layout_${nav_child.getLayoutId()}" role="presentation">\n' +
                        '\t\t\t\t\t\t\t\t<a aria-labelledby="layout_${nav_child.getLayoutId()}" href="${nav_child.getURL()}" ${nav_child.getTarget()} role="menuitem">${nav_child.getName()}</a>\n' +
                        '\t\t\t\t\t\t\t</li>\n' +
                        '\t\t\t\t\t\t</#list>\n' +
                        '\t\t\t\t\t</ul>\n' +
                        '\t\t\t\t</#if>\n' +
                        '\t\t\t</li>\n' +
                        '\t\t</#list>\n' +
                        '\t</ul>\n' +
                        '</nav>'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360028833812-Theme-Anatomy-Reference-Guide"}>
                                    Theme Anatomy Reference Guide
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NavigationThemeContent;