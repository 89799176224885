import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";

class CurlContent extends BaseContentPage  {

    constructor(props) {
        super(props, "windows-curl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru cmd se foloseste ^ pentru a putea scrie pe mai multe randuri.
                    <br/>
                    Pentru powershell se foloseste ' pentru a putea scrie pe mai multe randuri.
                    <br/>
                    Pentru unix se foloseste \ pentru a putea scrie pe mai multe randuri.
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'curl --user Administrator:Administrator ^\n' +
                            '    -X POST http://URL:PORT/link ^\n' +
                            '     -H "Content-Type: application/json" ^\n' +
                            '     -d @date.json'}
                    </SyntaxHighlighter>

                    unde fisierul <i>date.json</i> are urmatorul continut:
                    <SyntaxHighlighter>
                        {'{"params":{\n' +
                            '  "destinationFolder":"x",\n' +
                            '  "fileName":"y",\n' +
                            '  "importType": "z",\n' +
                            '  "sheetIndex":0\n' +
                            '},\n' +
                            '  "context":{}\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CurlContent;