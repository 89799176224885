import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class StatefulSetsK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-stateful-sets", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Stateful Sets</b>
                    <br/>
                    <br/>

                    Sa presupunem ca avem o baza de date MySQL si vrem <i>high availability</i>.
                    Prin urmare vom vrea sa avem inca 2 copii a baze de date MySQL.<br/>
                    Exista mai multe moduri de a face lucrul asta.<br/>

                    <br/>
                    Un mod:
                    <ul>
                        <li>
                            setam baza de date <i>master</i> si apoi 2 baze de date <i>slave</i>: slave-1 si slave-2
                        </li>
                       <li>
                           clonam datele din <i>master</i> pe <i>slave-1</i>
                       </li>
                        <li>
                            activam <i>replicarea continua</i> de pe <i>master</i> pe <i>slave-1</i>
                        </li>
                        <li>
                            asteptam sa ca <i>slave-1</i> sa fie <i>ready</i>
                        </li>
                        <li>
                            clonam datele de pe <i>slave-1</i> pe <i>slave-2</i>
                        </li>
                        <li>
                            activam <i>replicarea continua</i> de pe <i>master</i> pe <i>slave-2</i>
                        </li>
                        <li>
                            configuram Master Address pe slave (MASTER_HOST=master)
                        </li>
                    </ul>

                    MASTER_HOST trebuie sa fie cumva static, pentru a se putea referi cele 2 replici (slave-1 si slave-2)

                    <br/>
                    Cu Stateful Set, fata de un Deployment:
                    <ul>
                        <li>
                            pod-urile se creaza secvential (se creaza pod 1 - master - intai, apoi pod 2 si pod 3)
                        </li>
                        <li>
                            un index unic, care porneste de la 0 (0 - pentru primul pod, 1 - pentru al doilea pod)
                        </li>

                        <li>
                            un nume (de exemplu: mysql, combinat cu index: mysql-0, mysql-1, mysql-2) - deci nu am nume aleatoare
                            <br/>
                            MASTER_HOST = mysql-0
                        </li>
                        <li>
                            la recreare pastreaza numele
                        </li>
                    </ul>

                    De defineste ca un <b>Deployment</b>, doar ca in loc de <b>Deployment</b> se scrie:
                    <SyntaxHighlighter>
                        {'kind: StatefulSet'}
                    </SyntaxHighlighter>
                    In plus, este nevoie si de <b>serviceName</b>, in sectiunea <b>spec</b>:
                    <SyntaxHighlighter>
                        {'serviceName: mysql-h'}
                    </SyntaxHighlighter>

                    Daca se scaleaza in sus, vor apare: mysql-3, mysql-4, mysql-5..
                    <br/>
                    Daca se scaleaza in jos, se vor sterge de la coada: mysql-5, mysql-4, mysql-3..
                    <br/>
                    Acesta este modul implicit de funtionare, dar  se poate specifica alta poltica de gestiune a podurilor:
                    <SyntaxHighlighter>
                        {'podManagementPolicy: Parallel'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exercitii:
                    <SyntaxHighlighter>
                        {'k get sc\n' +
                            '\n' +
                            '# un sc care nu suporta dynamic volume provisioning are in nume: "no-provisioner"\n' +
                            '\n' +
                            'k describe sc local-storage\n' +
                            'k get pvc\n' +
                            '\n' +
                            'k nano pvc.yaml\n' +
                            '\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'apiVersion: v1\n' +
                            'metadata:\n' +
                            '  name: local-pvc\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '  - ReadWriteOnce\n' +
                            '  storageClassName: local-storage\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 500Mi\n' +
                            '\t  \n' +
                            'k create -f pvc.yaml\n' +
                            '\n' +
                            'kubectl describe pvc local-pvc\n' +
                            '\n' +
                            'kubectl run nginx --image=nginx:alpine --dry-run=client -o yaml > nginx.yaml\n' +
                            'k create -f nginx.yaml\n' +
                            '\n' +
                            'nano sc.yaml\n' +
                            'apiVersion: storage.k8s.io/v1\n' +
                            'kind: StorageClass\n' +
                            'metadata:\n' +
                            '  name: delayed-volume-sc\n' +
                            'provisioner: kubernetes.io/no-provisioner\n' +
                            'volumeBindingMode: WaitForFirstConsumer'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StatefulSetsK8sContent;