import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Tips5DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tips5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Tips - Networking</b>

                    1. <b>Network Drivers</b>
                    <br/>
                    <ul>
                        <li><b>Bride</b> {"=>"} bridge este default network driver pentru Docker</li>
                        <li><b>Host</b> {"=>"} acest driver sparge izolarea dintre docker host si containerele docker pentru a se folosi reteau host din mod direct</li>
                        <li><b>None</b> {"=>"} decuplare completa de la retea; nu are configurat nici un IP si nu are acces la al reteau externa</li>
                        <li><b>User Defined Bridge</b></li>
                        <li><b>Overlay</b> {"=>"} default pentru Swarm
                            <ul>
                                <li>
                                    permite comunicarea dintre containere
                                </li>
                                <li>
                                    comunicarea poate fi criptata, folosind optiunea <b>--opt ecripted</b>
                                </li>
                            </ul>
                            nu este nevoie de a se creee o retea overlay intre noduri, pentru ca se creeaza in mod automat cand unul din aceste noduri porneste sa ruleze un task de serviciu de care are nevoie.
                        </li>
                    </ul>
                    Legacy Link {"=>"} (--link) {"=>"} nu ar trebui folosit, fiind o mostenire;

                    <hr/>

                    2. <b>Publicare</b>
                    <br/>
                    <ul>
                        <li>
                            Publish List (-p) {"=>"} va publica o lista de porturi definite [-p 80:80]
                        </li>
                        <li>Publish All (-p) {"=>"} va publica pe un port aleator (sub 32768)
                        </li>
                    </ul>

                    3. <b>Configrare docker pentru DNS extern</b>
                    <br/>
                    Configurarea DNS, Docker Container, o ia din <b>/etc/resolv.conf</b>
                    <br/>
                    Setarile DNS pentru containere pot fi customizate in fisierul: <b>daemon,json</b>:
                    <SyntaxHighlighter>
                        {'{"dns":["8.8.8.8","172.31.0.2"]}'}
                    </SyntaxHighlighter>

                    4. <b>Inspectare retea</b>
                    <br/>
                    <SyntaxHighlighter>
                        {'docker network inspect [nume-retea]'}
                    </SyntaxHighlighter>

                    
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips5DockerContent;