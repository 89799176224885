import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../elasticsearch/IndexContent";

class ConnectingClusterElasticsearchContent extends BaseContentPage  {

    constructor(props) {
        super(props, "elasticsearch-connecting-cluster", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Deschidere port in VirtualBox</b>
                    <br/>
                    <br/>
                    Din VirtualBox, se selecteaza masina virtuala <i>ElasticSearch</i> {"=>"} <b>Settings</b> {"=>"} <b>Network</b> {"=>"} Sectiunea <b>Advanced</b> {"=>"} click pe <b>Port Forwarding</b>.
                    <br/>
                    De aici se adauga o noua regula:
                    <ul>
                        <li>Name: SSH</li>
                        <li>Protocol: TCP</li>
                        <li>Host IP: 127.0.0.1</li>
                        <li>Host Port: 22</li>
                        <li>Guest IP:</li>
                        <li>Guest Port:22</li>
                    </ul>
                    +
                    <ul>
                        <li>Name: ElasticSearch</li>
                        <li>Protocol: TCP</li>
                        <li>Host IP: 127.0.0.1</li>
                        <li>Host Port: 9200</li>
                        <li>Guest IP:</li>
                        <li>Guest Port:9200</li>
                    </ul>
                    +
                    <ul>
                        <li>Name: Kibana</li>
                        <li>Protocol: TCP</li>
                        <li>Host IP: 127.0.0.1</li>
                        <li>Host Port: 5601</li>
                        <li>Guest IP:</li>
                        <li>Guest Port:5601</li>
                    </ul>

                    <hr/>
                    <b>2. Instalare Putty</b>
                    <br/>
                    <br/>

                    <b>Putty</b> se poate descarca de pe site-ul:
                    <br/>
                    <a target={"_blank"} href={"https://www.putty.org/"}>www.putty.org</a>

                    <br/>
                    <br/>
                    De deschide <i>Putty</i>. Din sectiunea <b>Session</b>:
                    <ul>
                        <li>Host Name (or IP address): 127.0.0.1</li>
                        <li>Port: 22</li>
                        <li>Connection Type: SSH</li>
                        <li>Saved Sessions: ElasticSearch</li>
                        <li>Save</li>
                    </ul>
                    Apoi se selecteaza <i>ElasticSearch</i> {"=>"} <b>Open</b>


                    <hr/>
                    <b>3. Instalare OpenSSH Server in Ubuntu</b>
                    <br/>
                    <br/>

                    Din terminal, se intaleaza <b>openssl-server</b> (pentru a ne putea conecta remote de exemplu de pe o masina windows):

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get install openssh-server'}
                    </SyntaxHighlighter>

                    Pornire automata:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl enable ssh'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Creare utilizator</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo adduser student'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo usermod -aG sudo student'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Conectare prin Putty la masina virtuala Ubuntu</b>
                    <br/>
                    <br/>

                    Dupa ce s-a deschis <i>Putty</i> se introduce la login: <i>student</i> si parola.
                    <br/>
                    Verificare:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'pwd'}
                    </SyntaxHighlighter>
                    Se va afisa: <i>/home/student</i>

                    <hr/>
                    <b>Bonus: Cum sa functioneze copy-paste din host in Ubuntu</b>
                    <br/>
                    <br/>

                    Tutorial Youtube:<br/>
                    <a target={"_blank"} href={"https://www.youtube.com/watch?v=zdkl16oAS1k&ab_channel=ProgrammingKnowledge2"}>How Install VirtualBox Guest Additions on Ubuntu 22.04 Guest / virtual machine</a>

                    <br/>
                    <br/>
                    sau:
                    <br/>
                    <br/>
                    <b>Pas 1. Se intaleaza pachetele necesare</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install linux-headers-$(uname -r) build-essential dkms'}
                    </SyntaxHighlighter>

                    <b>Pas 2. Se instaleaza Guest Additions folosind VirtualBox:</b>
                    <br/>

                    <b>VirtualBox {"=>"} Devices {"=>"} Insert Guest Additions CD image</b>
                    <br/>
                    In partea din stanga, pe bara va aparea montat un CD (iconita unui CD). Daca se apasa pe ea, se va deschide o noua fereastra cu continutul cd-ului.
                    <br/>
                    Cu asta fereastra deschisa sa da click dreapta {"=>"} din meniul contextal {"=>"} <b>Open in Terminal</b>

                    Pentru a instala:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'./autorun.sh'}
                    </SyntaxHighlighter>
                    Se scrie parola, pentru a incepe instalarea. Dupa ce instaleaza, se apasa ENTER.
                    <br/>
                    <br/>
                    <b>Pas 3. Restare masina virtuala Ubuntu</b>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ConnectingClusterElasticsearchContent;