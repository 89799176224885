import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class ApiNextReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-api", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. API</b>
                    <br/>
                    <br/>

                    Se creeaza un director numit <b>api</b> in directorul <b>pages</b>.
                    <br/>
                    Apoi, se creeaza un fisier javascript. Nume acestui fisier face parte de ruta.
                    Acest fisier va contine cel putin o functie cu signatura:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' default function handler(req, res) {..}'}
                    </SyntaxHighlighter>

                    De exemplu, daca avem fisierul numit <i>user.js</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default function handler(req, res) {\n' +
                            '  res.status(200).json({ name: \'John Doe\' })\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Acesta va putea fi apelat:
                    <ul>
                        <li>/api/user</li>
                    </ul>

                    Se poate determina metoda HTTP:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default function handler(req, res) {\n' +
                            '  if (req.method === \'POST\') {\n' +
                            '    // Process a POST request\n' +
                            '  } else {\n' +
                            '    // Handle any other HTTP method\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Un parametru se poate obtine:
                    <ul>
                        <li>
                            <b>req.query.</b>numeParametru
                        </li>
                    </ul>
                    Se poate seta un header ca raspuns:
                    <ul>
                        <li>
                            res.setHeader('Allow', ['GET', 'PUT'])
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ApiNextReactContent;