import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CookiesIntegrationLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-cookies", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Cookies: jquery.ihavecookies.min.js</b>

                    <br/>
                    <br/>
                    Fragment de cod:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<script src="${javascript_folder}/vendor/jquery.ihavecookies.min.js" type="text/javascript"></script>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'$(document).ready(function () {\n' +
                            '    $(\'body\').ihavecookies({\n' +
                            '        title: \'\',\n' +
                            '        message: Liferay.Language.get(\'theme.site.cookie.message\'),\n' +
                            '        link: \'/politica-cookies\',\n' +
                            '        moreInfoLabel: Liferay.Language.get(\'theme.site.cookie.moreInfoLabel\'),\n' +
                            '        acceptBtnLabel: Liferay.Language.get(\'theme.site.cookie.acceptBtnLabel\'),\n' +
                            '        advancedBtnLabel: Liferay.Language.get(\'theme.site.cookie.advancedBtnLabel\'),\n' +
                            '        cookieTypesTitle: Liferay.Language.get(\'theme.site.cookie.cookieTypesTitle\'),\n' +
                            '        fixedCookieTypeLabel: Liferay.Language.get(\'theme.site.cookie.fixedCookieTypeLabel\'),\n' +
                            '        fixedCookieTypeDesc: Liferay.Language.get(\'theme.site.cookie.fixedCookieTypeDesc\'),\n' +
                            '        cookieTypes: [\n' +
                            '            {\n' +
                            '                type: Liferay.Language.get(\'theme.site.cookie.cookieTypes.statistic-type\'),\n' +
                            '                value: \'analytics\',\n' +
                            '                description: \'\'\n' +
                            '            }\n' +
                            '        ],\n' +
                            '        onAccept: function () {\n' +
                            '            loadGoogleAnalytics();\n' +
                            '        }\n' +
                            '    });\n' +
                            '    loadGoogleAnalytics();\n' +
                            '});'}
                    </SyntaxHighlighter>

                    Fie <b>portal_normal.ftl</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                            '\n' +
                            '<#include init />\n' +
                            '\n' +
                            '<html class="${root_css_class}" dir="<@liferay.language key="lang.dir" />" lang="${w3c_language_id}">\n' +
                            '\n' +
                            '<head>\n' +
                            '    <title>${html_title}</title>\n' +
                            '\n' +
                            '    <meta content="initial-scale=1.0, width=device-width" name="viewport"/>\n' +
                            '\n' +
                            '    <@liferay_util["include"] page=top_head_include />\n' +
                            '</head>\n' +
                            '\n' +
                            '<body class="${css_class} ${addStyle}">\n' +
                            '\n' +
                            '<@liferay_ui["quick-access"] contentId="#main-content" />\n' +
                            '\n' +
                            '<@liferay_util["include"] page=body_top_include />\n' +
                            '\n' +
                            '<#if !isInfochiosk>\n' +
                            '    <div class="fixed-icon" id="user-accessibility-icon">\n' +
                            '        <script>\n' +
                            '            (function(d){\n' +
                            '                var s = d.createElement("script");\n' +
                            '                s.setAttribute("data-position", 5);\n' +
                            '                s.setAttribute("data-size", "large");\n' +
                            '                s.setAttribute("data-color", "#004C55");\n' +
                            '                s.setAttribute("data-type", "2");\n' +
                            '                s.setAttribute("data-account", "9bypO3Ua9N");\n' +
                            '                s.setAttribute("src", "https://cdn.userway.org/widget.js");\n' +
                            '                (d.body || d.head).appendChild(s);})(document)\n' +
                            '        </script>\n' +
                            '        <noscript>\n' +
                            '            Please ensure Javascript is enabled for purposes of\n' +
                            '            <a href="https://userway.org">website accessibility</a>\n' +
                            '        </noscript>\n' +
                            '    </div>\n' +
                            '\n' +
                            '    <div class="fixed-icon" id="back-to-top-icon">\n' +
                            '        <img src="${images_folder}/svg/scroll_to_top_icon.svg" alt="Back to top"/>\n' +
                            '    </div>\n' +
                            '\n' +
                            '<#--    <script type="text/javascript" src=https://chat.ibrid.ro/webchat.js async clientkey=e79f32dd8982d4adcab9 projectid=3 site_language="ro"></script>-->\n' +
                            '\n' +
                            '    <script type="text/javascript">\n' +
                            '\n' +
                            '        let src = \'https://chat.ibrid.ro/webchat.js\';\n' +
                            '        let clientKey = \'KEY\';\n' +
                            '        let projectID = \'3\';\n' +
                            '        let siteLanguage = \'ro\';\n' +
                            '\n' +
                            '        window.onload = function () {\n' +
                            '            buildChatBot();\n' +
                            '        }\n' +
                            '\n' +
                            '\n' +
                            '        function buildChatBot(){\n' +
                            '            var script = document.createElement(\'script\');\n' +
                            '            script.setAttribute("type", "text/javascript");\n' +
                            '            script.setAttribute("src", src);\n' +
                            '            script.setAttribute("clientkey", clientKey);\n' +
                            '            script.setAttribute("projectid", projectID);\n' +
                            '            script.setAttribute("site_language", siteLanguage);\n' +
                            '            document.head.appendChild(script);\n' +
                            '\n' +
                            '        }\n' +
                            '    </script>\n' +
                            '\n' +
                            '</#if>\n' +
                            '\n' +
                            '<div class="d-flex flex-column min-vh-100">\n' +
                            '    <@liferay.control_menu />\n' +
                            '\n' +
                            '    <div class="d-flex flex-column flex-fill position-relative" id="wrapper">\n' +
                            '        <section class="${portal_content_css_class} flex-fill" id="content">\n' +
                            '            <h2 class="sr-only">${htmlUtil.escape(the_title)}</h2>\n' +
                            '\n' +
                            '            <#if selectable>\n' +
                            '                <@liferay_util["include"] page=content_include />\n' +
                            '            <#else>\n' +
                            '                ${portletDisplay.recycle()}\n' +
                            '\n' +
                            '                ${portletDisplay.setTitle(the_title)}\n' +
                            '\n' +
                            '                <@liferay_theme["wrap-portlet"] page="portlet.ftl">\n' +
                            '                    <@liferay_util["include"] page=content_include />\n' +
                            '                </@>\n' +
                            '            </#if>\n' +
                            '        </section>\n' +
                            '    </div>\n' +
                            '</div>\n' +
                            '\n' +
                            '<script src="${javascript_folder}/vendor/jquery.ihavecookies.min.js" type="text/javascript"></script>\n' +
                            '<script src="${javascript_folder}/custom.js" defer></script>\n' +
                            '\n' +
                            '<@liferay_util["include"] page=body_bottom_include />\n' +
                            '\n' +
                            '<@liferay_util["include"] page=bottom_include />\n' +
                            '\n' +
                            '</body>\n' +
                            '\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    si <i>custom.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'window.onscroll = function (e) {\n' +
                            '    var topScrolled = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;\n' +
                            '    if (topScrolled > 125) {\n' +
                            '        document.getElementById(\'header-wrapper\').parentNode.parentNode.classList.add(\'scrolled\');\n' +
                            '        document.getElementById(\'back-to-top-icon\').classList.add(\'scrolled\');\n' +
                            '    } else {\n' +
                            '        document.getElementById(\'header-wrapper\').parentNode.parentNode.classList.remove(\'scrolled\');\n' +
                            '        document.getElementById(\'back-to-top-icon\').classList.remove(\'scrolled\');\n' +
                            '\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'var backToTopButton = document.getElementById("back-to-top-icon");\n' +
                            '\n' +
                            'if (backToTopButton) {\n' +
                            '    backToTopButton.addEventListener("click", function () {\n' +
                            '        document.body.scrollTop = 0;\n' +
                            '        document.documentElement.scrollTop = 0;\n' +
                            '    });\n' +
                            '}\n' +
                            '\n' +
                            'function loadGoogleAnalytics() {\n' +
                            '    if ($.fn.ihavecookies == null || $.fn.ihavecookies.cookie() == null ||\n' +
                            '        window.google_analytics_id == null || window.google_analytics_id.length === 0) {\n' +
                            '        return;\n' +
                            '    }\n' +
                            '    let analyticsEnabled = false;\n' +
                            '    let allowedCookies = $.fn.ihavecookies.cookie();\n' +
                            '    for (let i = 0; i < allowedCookies.length; i++) {\n' +
                            '        if ("analytics" === allowedCookies[i]) {\n' +
                            '            analyticsEnabled = true;\n' +
                            '        }\n' +
                            '    }\n' +
                            '    if (!analyticsEnabled) {\n' +
                            '        return;\n' +
                            '    }\n' +
                            '    let newScript = document.createElement("script");\n' +
                            '    newScript.type = "text/javascript";\n' +
                            '    newScript.setAttribute("async", "true");\n' +
                            '    newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + window.google_analytics_id);\n' +
                            '    document.documentElement.firstChild.appendChild(newScript);\n' +
                            '\n' +
                            '    window.dataLayer = window.dataLayer || [];\n' +
                            '    function gtag(){dataLayer.push(arguments);}\n' +
                            '    gtag(\'js\', new Date());\n' +
                            '\n' +
                            '    gtag(\'config\', window.google_analytics_id);\n' +
                            '}\n' +
                            '\n' +
                            '$(document).ready(function () {\n' +
                            '    $(\'body\').ihavecookies({\n' +
                            '        title: \'\',\n' +
                            '        message: Liferay.Language.get(\'theme.site.cookie.message\'),\n' +
                            '        link: \'/politica-cookies\',\n' +
                            '        moreInfoLabel: Liferay.Language.get(\'theme.site.cookie.moreInfoLabel\'),\n' +
                            '        acceptBtnLabel: Liferay.Language.get(\'theme.site.cookie.acceptBtnLabel\'),\n' +
                            '        advancedBtnLabel: Liferay.Language.get(\'theme.site.cookie.advancedBtnLabel\'),\n' +
                            '        cookieTypesTitle: Liferay.Language.get(\'theme.site.cookie.cookieTypesTitle\'),\n' +
                            '        fixedCookieTypeLabel: Liferay.Language.get(\'theme.site.cookie.fixedCookieTypeLabel\'),\n' +
                            '        fixedCookieTypeDesc: Liferay.Language.get(\'theme.site.cookie.fixedCookieTypeDesc\'),\n' +
                            '        cookieTypes: [\n' +
                            '            {\n' +
                            '                type: Liferay.Language.get(\'theme.site.cookie.cookieTypes.statistic-type\'),\n' +
                            '                value: \'analytics\',\n' +
                            '                description: \'\'\n' +
                            '            }\n' +
                            '        ],\n' +
                            '        onAccept: function () {\n' +
                            '            loadGoogleAnalytics();\n' +
                            '        }\n' +
                            '    });\n' +
                            '    loadGoogleAnalytics();\n' +
                            '});'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://liferay.dev/de/blogs/-/blogs/fronting-liferay-tomcat-with-apache-httpd-daemon-revisted"}>*/}
                    {/*                Fronting Liferay Tomcat with Apache HTTPd daemon Revisted*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CookiesIntegrationLiferayContent;