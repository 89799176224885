import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";

class UtilTtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-util", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Link-uri utile <b>Coqui TTS</b>
                    <ul>
                        <li>
                            git: https://github.com/coqui-ai/TTS
                        </li>
                        <li>
                            demo: https://huggingface.co/spaces/mms-meta/MMS
                        </li>
                    </ul>

                    Link-uri utile <b>Suno Bark</b>:
                    <ul>
                        <li>git: https://github.com/suno-ai/bark</li>
                        <li>demo: https://app.suno.ai/</li>
                    </ul>

                    Link-uri utile <b>Tortoise TTS</b>:
                    <ul>
                        <li>
                            https://huggingface.co/jbetker/tortoise-tts-v2
                        </li>
                    </ul>

                    Link-uri utile <b>Piper</b>:
                    <ul>
                        Git:
                        <li>
                            https://github.com/rhasspy/piper
                        </li>

                        Demo online:
                        <li>
                            https://rhasspy.github.io/piper-samples/
                        </li>

                        Download zip pentru Windows/Linux:
                        <li>
                            https://github.com/rhasspy/piper/releases
                        </li>

                        Modele:
                        <li>
                            https://huggingface.co/rhasspy
                            <ul>
                                <li>
                                    RO / Mihai: https://huggingface.co/rhasspy/piper-voices/tree/main/ro/ro_RO/mihai/medium
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Link-uri utile pentru <b>VALL-E X</b> (limitat la 3 limbi: engleze, chineza, japoneza):
                    <ul>
                        <li>
                            https://github.com/Plachtaa/VALL-E-X
                        </li>
                    </ul>

                    Next?
                    <ul>
                        <li>
                            https://github.com/SYSTRAN/faster-whisper
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UtilTtsContent;