import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class EventsLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-events", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Generare clasa de evenimente</b>
                    <br/>
                    <br/>
                    Pentru a genera o clasa de evenimente, se da comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:event CategoryUpdatedEvent'}
                    </SyntaxHighlighter>

                    Se va genera:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Events;\n' +
                            '\n' +
                            'use Illuminate\\Broadcasting\\Channel;\n' +
                            'use Illuminate\\Broadcasting\\InteractsWithSockets;\n' +
                            'use Illuminate\\Broadcasting\\PresenceChannel;\n' +
                            'use Illuminate\\Broadcasting\\PrivateChannel;\n' +
                            'use Illuminate\\Contracts\\Broadcasting\\ShouldBroadcast;\n' +
                            'use Illuminate\\Foundation\\Events\\Dispatchable;\n' +
                            'use Illuminate\\Queue\\SerializesModels;\n' +
                            '\n' +
                            'class CategoryUpdatedEvent\n' +
                            '{\n' +
                            '    use Dispatchable, InteractsWithSockets, SerializesModels;\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Create a new event instance.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function __construct()\n' +
                            '    {\n' +
                            '        //\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Get the channels the event should broadcast on.\n' +
                            '     *\n' +
                            '     * @return \\Illuminate\\Broadcasting\\Channel|array\n' +
                            '     */\n' +
                            '    public function broadcastOn()\n' +
                            '    {\n' +
                            '        return new PrivateChannel(\'channel-name\');\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Pentru a arunca un eveniment:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'event(new CategoryUpdatedEvent();'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'event(\'CategoryUpdatedEvent\')'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Generare clasa ascultator</b>
                    <br/>
                    <br/>

                    Pentru a genera un ascultator/listener:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan make:listener CategoryUpdatedListener'}
                    </SyntaxHighlighter>

                    Se va genera:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Listeners;\n' +
                            '\n' +
                            'use Illuminate\\Contracts\\Queue\\ShouldQueue;\n' +
                            'use Illuminate\\Queue\\InteractsWithQueue;\n' +
                            '\n' +
                            'class CategoryUpdatedListener\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Create the event listener.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function __construct()\n' +
                            '    {\n' +
                            '        //\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Handle the event.\n' +
                            '     *\n' +
                            '     * @param  object  $event\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function handle($event)\n' +
                            '    {\n' +
                            '        //\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Actualizare EventServiceProvider</b>
                    <br/>
                    <br/>

                    Se actualizeaza clasa EventServiceProvider, cu urmatoarele linii:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'CategoryUpdatedEvent::class=>[\n' +
                            '     CategoryUpdatedListener::class\n' +
                            ']'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Providers;\n' +
                            '\n' +
                            'use App\\Events\\CategoryUpdatedEvent;\n' +
                            'use App\\Listeners\\CategoryUpdatedListener;\n' +
                            'use Illuminate\\Auth\\Events\\Registered;\n' +
                            'use Illuminate\\Auth\\Listeners\\SendEmailVerificationNotification;\n' +
                            'use Illuminate\\Foundation\\Support\\Providers\\EventServiceProvider as ServiceProvider;\n' +
                            'use Illuminate\\Support\\Facades\\Event;\n' +
                            '\n' +
                            'class EventServiceProvider extends ServiceProvider\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * The event listener mappings for the application.\n' +
                            '     *\n' +
                            '     * @var array<class-string, array<int, class-string>>\n' +
                            '     */\n' +
                            '    protected $listen = [\n' +
                            '        Registered::class => [\n' +
                            '            SendEmailVerificationNotification::class,\n' +
                            '        ],\n' +
                            '        CategoryUpdatedEvent::class=>[\n' +
                            '            CategoryUpdatedListener::class\n' +
                            '        ]\n' +
                            '    ];\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Register any events for your application.\n' +
                            '     *\n' +
                            '     * @return void\n' +
                            '     */\n' +
                            '    public function boot()\n' +
                            '    {\n' +
                            '        //\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EventsLaravelContent;