import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class SearchDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-search", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker search</b>

                    <br/>
                    <br/>

                    Pentru ca cauta o imagine pe Docker Hub, se foloseste <b>docker search</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search --help\n'}
                    </SyntaxHighlighter>

                    va afisa:
                    <SyntaxHighlighter>
                        {'\n' +
                            'Usage:  docker search [OPTIONS] TERM\n' +
                            '\n' +
                            'Search the Docker Hub for images\n' +
                            '\n' +
                            'Options:\n' +
                            '  -f, --filter filter   Filter output based on conditions provided\n' +
                            '      --format string   Pretty-print search using a Go template\n' +
                            '      --limit int       Max number of search results (default 25)\n' +
                            '      --no-trunc        Don\'t truncate output\n'}
                    </SyntaxHighlighter>

                    de exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search python'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search python:3.8.16'}
                    </SyntaxHighlighter>

                    Coloane afisate:
                    <ul>
                        <li>image repository</li>
                        <li>image description</li>
                        <li>official tag</li>
                        <li>image stars</li>
                    </ul>

                    <hr/>

                    <b>1.1. Filtrare</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search --filter "is-official=true" python'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME      DESCRIPTION                                     STARS     OFFICIAL   AUTOMATED\n' +
                            'python    Python is an interpreted, interactive, objec…   8244      [OK]\n' +
                            'pypy      PyPy is a fast, compliant alternative implem…   350       [OK]\n' +
                            'hylang    Hy is a Lisp dialect that translates express…   53        [OK]\n'}
                    </SyntaxHighlighter>

                    Filtrare primele 7 rezultate:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search --limit 7 mysql'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search mysql --limit 7'}
                    </SyntaxHighlighter>

                    Filtrare primele 7 rezultate, fara truncarea rezultatului (de obicei a descrierii):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search --limit 7 --no-trunc mysql'}
                    </SyntaxHighlighter>

                    Alt exemplu de filtrare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search --filter "is-official=true" --filter "stars=30" python'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>1.2. Formatare</b>
                    <br/>
                    <br/>

                    Formatare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker search --format "table {{.Name}}\t{{.Description}}\t{{.IsOfficial}}" python'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                                     DESCRIPTION                                     OFFICIAL\n' +
                            'python                                   Python is an interpreted, interactive, objec…   [OK]\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exercitiu:
                    Fisierul <i>Dockerfile</i>:
                    <SyntaxHighlighter>
                        {'FROM python\n' +
                            '\n' +
                            'WORKDIR /app\n' +
                            '\n' +
                            'COPY app.py .\n' +
                            '\n' +
                            'RUN pip install numpy\n' +
                            '\n' +
                            'CMD ["python", "app.py"] \n'}
                    </SyntaxHighlighter>
                    Fisierul <i>app.py</i>:
                    <SyntaxHighlighter>
                        {'import numpy as np\n' +
                            '\n' +
                            'a = np.sqrt(16)\n' +
                            '\n' +
                            'print(f\'The square root of 16 is {a}\')'}
                    </SyntaxHighlighter>

                    Build:
                    <SyntaxHighlighter>
                        {'docker build -t python-app .'}
                    </SyntaxHighlighter>

                    Run:
                    <SyntaxHighlighter>
                        {'docker run python-app'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SearchDockerContent;