import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class LifecycleGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-lifecycle", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Sunt 3 faze:
                    <ul>
                        <li>faza de intializare</li>
                        <li>faza de configurare (creaza task-urile; configureaza task-urile)</li>
                        <li>faza de executie (executa task-urile)</li>
                    </ul>

                    Fisierul de script determina faza si catre ce obiect de deleaga si se aiba acces:
                    <ul>
                        <li><b>init.gradle (sau <i>xxx.gradle</i>)</b>: in faza de initializare, deleaga catre un obiect de tip <b>Gradle</b></li>
                        <li><b>settings.gradle</b>: in faza de initializare, deleaga catre un obiect de tip <b>Settings</b></li>
                        <li><b>build.gradle</b>: in faza de configurare, deleaga catre un obiect de tip <b>Project</b> (un proiect fiind o colectie de Task-uri; un task are o colectie de actiuni - Action)</li>
                    </ul>

                    <b>Obiectul Project are acces la obiectul Gradle</b> (deci se poate accesa proprietati sau metode din obiectul Gradle: <b>project.gradle</b> sau <b>gradle</b>).<br/>
                    De exemplu, in <i>build.gradle</i> se poate scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println "${project.gradle.gradleVersion}"'}
                    </SyntaxHighlighter>

                    sau direct:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println "$gradle.gradleVersion"'}
                    </SyntaxHighlighter>

                    Din <i>init.gradle</i> se poate scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println "$gradleVersion"'}
                    </SyntaxHighlighter>

                    <b>Obiectul Settings are acces la obiectul Gradle</b>.

                    <br/>
                    Din <i>settins.gradle</i> se poate scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println "$settings.gradle.gradleVersion"'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sa presupunem ca avem urmatoarele fisiere:
                    <ul>
                        <li>GRADLE_HOME/init.d/init.gradle</li>
                        <li>GRADLE_HOME/init.d/a.gradle</li>
                        <li>GRADLE_HOME/init.d/z.gradle</li>
                        <li>settings.gradle</li>
                        <li>build.gradle</li>
                    </ul>
                    Ordinea de rulare va fi (scripurile din GRADLE_HOME/init.d se ruleaza in ordine alfabetica):
                    <ul>
                        <li>GRADLE_HOME/init.d/a.gradle</li>
                        <li>GRADLE_HOME/init.d/init.gradle</li>
                        <li>GRADLE_HOME/init.d/z.gradle</li>
                        <li>settings.gradle</li>
                        <li>build.gradle</li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LifecycleGradleContent;