import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class ImportExcelJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-import-excel", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Import Excel</b>
                    <br/>
                    <br/>

                    <hr/>

                    <b>1. Backend</b>
                    <br/>
                    <br/>

                    In POM:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<!-- https://mvnrepository.com/artifact/org.dhatim/fastexcel-reader -->\n' +
                            '<dependency>\n' +
                            '    <groupId>org.dhatim</groupId>\n' +
                            '    <artifactId>fastexcel-reader</artifactId>\n' +
                            '    <version>0.15.3</version>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    In Resources/REST:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@PostMapping(value = "/import-data/import/{id}", consumes = {"multipart/form-data"})\n' +
                            '@Operation(summary = "Upload a single File")\n' +
                            'public ResponseEntity<DataResult> imporData(@PathVariable(value = "id", required = false) final String id,\n' +
                            '                                                                           @RequestPart MultipartFile file)\n' +
                            '    throws URISyntaxException, IOException {\n' +
                            '    log.debug("REST request to import ImportData : {}", file);\n' +
                            '\n' +
                            '    DataResult result = new DataResult();\n' +
                            '    int total = 0;\n' +
                            '    result.setTotal(total);\n' +
                            '\n' +
                            '    ListImportData> importDataList = new ArrayList<>();\n' +
                            '\n' +
                            '    if (file.isEmpty()) {\n' +
                            '        return new ResponseEntity(result, HttpStatus.OK);\n' +
                            '    } else {\n' +
                            '        InputStream inputStream = file.getInputStream();\n' +
                            '\n' +
                            '        try (ReadableWorkbook wb = new ReadableWorkbook(inputStream)) {\n' +
                            '\n' +
                            '            Sheet sheet = wb.getFirstSheet();\n' +
                            '\n' +
                            '            try (Stream<Row> rows = sheet.openStream()) {\n' +
                            '\n' +
                            '                Iterator<Row> it = rows.iterator();\n' +
                            '\n' +
                            '                while (it.hasNext()) {\n' +
                            '\n' +
                            '                    Row row = it.next();\n' +
                            '\n' +
                            '                    if (total==0){\n' +
                            '                        //header\n' +
                            '                    }else {\n' +
                            '                        ImportData importData = new ImportData();\n' +
                            '                        importData.setDatatype(row.getCell(0).getText());\n' +
                            '                        importData.setContent(row.getCell(1).getText());\n' +
                            '\n' +
                            '                        importDataList.add(importData);\n' +
                            '                    }\n' +
                            '\n' +
                            '                    total++;\n' +
                            '                }\n' +
                            '            }\n' +
                            '        }\n' +
                            '\n' +
                            '        importDataRepository.truncateTable();\n' +
                            '        if (importDataList.size()>0){\n' +
                            '            importDataRepository.saveAll(importDataList);\n' +
                            '        }\n' +
                            '\n' +
                            '        result.setTotal(total);\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    In Repository:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Modifying\n' +
                            '@Transactional\n' +
                            '@Query(value = "TRUNCATE TABLE importdata", nativeQuery = true)\n' +
                            'void truncateTable();'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Frontend</b>
                    <br/>
                    <br/>

                    In HTML template:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'<div class="mt-1 actions-list d-flex justify-content-between" *ngIf="cfg != null && cfg.dateType">\n' +
                            '  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />\n' +
                            '\n' +
                            '  <div class="file-upload">\n' +
                            '    <button class="btn btn-dark" type="button" id="importFile" (click)="fileUpload.click()">Import tipuri date</button>\n' +
                            '  </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>
                    si:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'<div\n' +
                            '  class="modal"\n' +
                            '  tabindex="-1"\n' +
                            '  role="dialog"\n' +
                            '  [ngStyle]="{\'display\':displayStyle}">\n' +
                            '  <div class="modal-dialog" role="document">\n' +
                            '    <div class="modal-content">\n' +
                            '      <div class="modal-header">\n' +
                            '        <h4 class="modal-title">Import</h4>\n' +
                            '      </div>\n' +
                            '      <div class="modal-body">\n' +
                            '        <p [innerHtml]="message"></p>\n' +
                            '      </div>\n' +
                            '      <div class="modal-footer">\n' +
                            '        <button type="button" class="btn btn-dark"\n' +
                            '                (click)="closePopup()">\n' +
                            '          Inchide\n' +
                            '        </button>\n' +
                            '      </div>\n' +
                            '    </div>\n' +
                            '  </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>


                    In TS:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'@ViewChild(\'fileUpload\')\n' +
                            'fileUpload!: ElementRef;\n' +
                            '\n' +
                            'fileName = \'\';\n' +
                            'message = \'\';'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'onFileSelected(event: any) {\n' +
                            '  const file: File = event.target.files[0];\n' +
                            '\n' +
                            '  if (file) {\n' +
                            '    this.fileName = file.name;\n' +
                            '\n' +
                            '    const formData = new FormData();\n' +
                            '    formData.append(\'file\', file);\n' +
                            '\n' +
                            '    let upload = this.uDDImportSearchDataService.import(formData);\n' +
                            '    upload.subscribe((response) => {\n' +
                            '\n' +
                            '      let message = "S-au importat <b>"+(response.total>0?response.total-1:0)+" inregistrari</b> din fisierul <b>" + this.fileName  +" </b>.";\n' +
                            '      this.openPopup(message);\n' +
                            '    });\n' +
                            '\n' +
                            '    this.fileUpload.nativeElement.value = "";\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            'displayStyle = "none";\n' +
                            '\n' +
                            'openPopup(message:string) {\n' +
                            '  this.displayStyle = "block";\n' +
                            '  this.message = message;\n' +
                            '}\n' +
                            '\n' +
                            'closePopup() {\n' +
                            '  this.displayStyle = "none";\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In Service:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'import(formData: FormData): Observable<any> {\n' +
                            '  const current = new Date();\n' +
                            '  const timestamp = current.getTime();\n' +
                            '  return this.http.post(`${this.resourceUrl}/import/${timestamp}`, formData,{ responseType: \'json\' });\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://blog.ippon.tech/create-a-simple-file-storage-through-database-with-jhipster/"}>
                               Create a simple file storage through database with JHipster
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImportExcelJHipsterContent;