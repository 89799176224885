import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ZoneSystemVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-zone-system", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Zone System este o tehnică fotografică pentru determinarea expunerii și dezvoltării optime a filmului, formulată de Ansel Adams și Fred Archer (în jurul anului 1939– 40).

                    <table className={"text-center"}>
                        <thead>
                            <tr>
                                <th>Zona</th>
                                <th>Descriere</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:'#000000', color: '#fff'}}>O</td>
                                <td>negru pur - fără detaliu</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#1a1a1a', color: '#fff'}}>I</td>
                                <td>aproape de negru, cu o tonalitate ușoară, dar fara textura</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#333', color: '#fff'}}>II</td>
                                <td>negru cu textura; fiind partea cea mai întunecată a imaginii în care exista mici detalii sau textura</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#4d4d4d', color: '#fff'}}>III</td>
                                <td>materiale întunecate, cantitați medii de texturi</td>
                            </tr>


                            <tr>
                                <td style={{backgroundColor:'#666', color: '#fff'}}>IV</td>
                                <td>frunziș mediu întunecat, piatră întunecată sau umbre în peisaj</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#808080', color: '#fff'}}>V</td>
                                <td>gri 18%: piele închisă la culoare, lemn îmbătrânit,  cer senin de nord aproape de nivelul mării
                                </td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#999999', color: '#000'}}>VI</td>
                                <td>piele caucaziană medie; piatră în lumină; umbre pe zăpadă în peisaje luminate de soare</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#b3b3b3', color: '#000'}}>VII</td>
                                <td>piele foarte deschisă la culoare; umbre difuze în zăpadă</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#ccc', color: '#000'}}>VIII</td>
                                <td>alb cu textura, fiind partea cea mai deschisa a imaginii in care exista mici detalii sau textura (zapada cu textura)</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#e6e6e6', color: '#000'}}>IX</td>
                                <td>tonuri minime deschise, fara textura (zapada stralucitoare)</td>
                            </tr>

                            <tr>
                                <td style={{backgroundColor:'#fff', color: '#000'}}>X</td>
                                <td>alb pur - surse de lumina (soare, bec) si suprafete lucioase/reflectante</td>
                            </tr>

                        </tbody>
                    </table>

                    Despre Gri 18%:
                    <ul>
                        <li>jumătatea distanței dintre alb și negru</li>
                        <li>reflectă o cantitate <i>medie</i> de lumină, care este de aproximativ 18%</li>
                        <li>punctul de mijloc dintre alb și negru pe o curbă logaritmică / exponențială</li>
                        <li>media în ceea ce privește luminozitatea scenei</li>
                        <li>o imagine a unui card gri de 18% ar trebui să dezvăluie o histogramă cu lățimea de un pixel în centrul histogramei</li>
                        <li>există și alte tonuri care au aceeași valoare relativă ca și 18% gri:
                            <ul>
                                <li>cladiri din caramida rosie</li>
                                <li>iarba verde-deschisa dintr-un parc</li>
                            </ul>
                         </li>
                        <li>
                            un card gri cu 18% reflectă înapoi 18% din lumina care îl lovește - și este de fapt media geometrică dintre hârtia albă (95% reflectorizant) și cerneala neagră (3,5% reflectorizant):
                            <br/>
                            media geometrica = √(95*3.5) = √(332.5) = 18.2
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://en.wikipedia.org/wiki/Zone_System"}>Zone System</a><br/>
                           </div>
                       </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ZoneSystemVideografieContent;