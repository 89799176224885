import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class SlotAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-slot", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Slot</b>
                    <br/>
                    <br/>

                    Definirea/declareara unui slot:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<p numeSlot>Titlul meu</p>'}
                    </SyntaxHighlighter>

                    Injectarea unui slot se face folosind <b>select</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<ng-content select="[numeSlot]"></ng-content>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component } from \'@angular/core\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'app-kj-multislot\',\n' +
                            '  template: `\n' +
                            '    <h2>Multi-slot content projection</h2>\n' +
                            '\n' +
                            '    Default:\n' +
                            '    <ng-content></ng-content>\n' +
                            '\n' +
                            '    Question:\n' +
                            '    <ng-content select="[question]"></ng-content>\n' +
                            '  `\n' +
                            '})\n' +
                            'export class KjMultislotComponent {}'}
                    </SyntaxHighlighter>

                    Mod de folosire:
                    <SyntaxHighlighter  showLineNumbers={true} language="html" style={androidstudio}>
                        {'<app-kj-multislot>\n' +
                            '  <p question>\n' +
                            '    Is content projection cool? Noop\n' +
                            '  </p>\n' +
                            '  <p>Let\'s learn about content projection! Yap, dar degeaba!</p>\n' +
                            '</app-kj-multislot>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SlotAngularContent;