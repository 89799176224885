import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class PropertiesFileGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-properties-file", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Gradle permite definirea de proprietati in fisierul <b>gradle.properties</b> sub forma de perechi: cheie=valoare.
                    <br/>
                    Aceste proprietati sunt vizibile in fisierele <b>settings.gradle</b> si <b>build.gradle</b>.
                    <br/>
                    Acest fisier poate fi in:
                    <ul>
                        <li>directorul radacina a proiectului</li>
                        <li>directorul GRADLE_USER_HOME</li>
                        <li>system.properties, daca se foloseste din linie de comanda <b>-Dgradle.user.home</b></li>
                    </ul>
                    <br/>
                    Fie fisierul <b>gradle.properties</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'numarNorocos=13'}
                    </SyntaxHighlighter>

                    Daca se incerca accesare unei proprietati care nu exista (nu este definita) se va arunca o exceptie.
                    Pentru a evita acest lucru se poate folosi metoda: <b>hasProperty()</b> (se poate folosi si pentru a verifica daca este definita si o metoda)

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'println "${project.numarNorocos}"\n' +
                        'println "${project.hasProperty(\'numarNorocos\')}"\n' +
                        'println "${project.hasProperty(\'nrNorocos\')}"'}
                    </SyntaxHighlighter>

                    O alta metoda de seta o proprietatea e din linie de comanda, folosid flag-ul <b>-Pcheie=valoare</b>.
                    De exemplu, daca executam scriptul folosind comanda<b>gradle build -Px=a</b> se va afisa <i>ok:X</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'if (hasProperty("x")){\n' +
                        '\tprintln "ok:X"\n' +
                        '}else{\n' +
                        '\tprintln "nok:X"\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PropertiesFileGradleContent;