import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ProfilerJProfilerJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-profiler-jprofiler", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Profiler: JProfiler</b>
                    <br/>
                    <br/>

                    JProfiler oferă interfețe pentru vizualizarea performanței sistemului, a utilizării memoriei, a potențialelor scurgeri de memorie și a profilării firelor.
                    <br/>
                    Acest produs necesită achiziționarea unei licențe , dar oferă și o încercare gratuită.

                    <hr/>
                    <b>2. Instalare/Descarcare</b>
                    <br/>
                    <br/>

                    Descarcare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'https://www.ej-technologies.com/products/jprofiler/overview.html'}
                    </SyntaxHighlighter>
                    Dupa descarcare, se instaleaza.



                    <hr/>
                    <b>2. Analiza</b>
                    <br/>
                    <br/>
                    Se apasa pe butonul <b>Start Center</b>, apoi din fereastra pop-up se alege <b>Quick Attach</b> si se selecteaza procesul se se doreste profilat.
                    <br/>
                    Apoi se apasa butonul <b>Start</b>.
                    <br/>
                    Apoi, trebuie sa selectam intre:
                    <ul>
                        <li>Instrumentation (echivalent cu profilarea din VisualVM)</li>
                        <li>Sampling</li>
                    </ul>

                    De exemplu, daca vrem sa investigam conexiunile la baza de date putem alege <b>Instrumentation</b>.
                    <br/>
                    Apoi, din meniul din stanga putem alege <b>Database</b> si apoi <b>JDBC</b>.
                    <br/>
                    Apoi, din meniul din dreapta se apasa pe butonul de start <b>Record JDBC</b>.
                    <br/>
                    Putem vedeam in sectiunea:
                    <ul>
                        <li><b>Connections</b> - conexiunile</li>
                        <li><b>Connections Leaks</b> - (scurgerile de conexiuni) conexiunile deschise</li>
                    </ul>
                    Pentru urmarirea stivei, putem selecta din meniul din stanga: <b>CPU Views</b>, apoi <b>Call Tree</b> si apoi din dreapta <b>Record CPU Record</b>

                    <br/>
                    <br/>
                    Cu urmarirea stivei, din <b>Connections Leaks</b> putem observa <i>stack trace</i>-ul!

                    <hr/>
                    <b>Afisare grafic apel: Show Call Graph</b>
                    <br/>
                    <br/>
                    Pentru urmarirea stivei, putem selecta din meniul din stanga: <b>CPU Views</b>, apoi <b>Call Tree</b> si apoi din dreapta <b>Record CPU Record</b>.
                    <br/>
                    Apoi, pentru o reprezentare grafica a apelurilor, se face click drepta pe o linie si din meniu se alege <b>Show Call Graph</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/java/img.png'}/>
                    </div>

                    <b>Show Call Graph</b> se concentrează pe relația dintre obiecte și apelurile de metodă.

                    <hr/>
                    <b>Afisare grafic apel: Show Flame Graph</b>
                    <br/>
                    <br/>

                    Vizualizarile de tipul <b>Show Flame Graph</b> sunt cele mai utile în identificarea potențialelor latențe.

                    <br/>
                    <br/>
                    Pentru urmarirea stivei, putem selecta din meniul din stanga: <b>CPU Views</b>, apoi <b>Call Tree</b> si apoi din dreapta <b>Record CPU Record</b>.
                    <br/>
                    Apoi, din meniul de sus, se apasa <b>Analyze</b>, apoi <b>Show Flame Graph</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/java/img_1.png'}/>
                    </div>

                    Pentru a putea fi urmarit mai usor, graficul poate fi colorat. Acest lucru se poate realiza:
                    <br/>
                    CPU Views, apoi Flame Graph, apoi <b>Colorize</b> (din meniul de sus):
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/java/img_2.png'}/>
                    </div>

                    Graficul coloarat:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/java/img_3.png'}/>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://medium.com/@davethomas_9528/writing-hello-world-in-java-byte-code-34f75428e0ad"}>*/}
                    {/*            Writing Hello World in Java byte code*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ProfilerJProfilerJavaContent;