import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class PipeAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-pipe", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Pipe</b>
                    <br/>
                    <br/>

                    Pipe sunt functii care transforma valori (valoarea originala ramane neschimbata)


                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ "akautzu" | titlecase }}'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ "akautzu" | uppercase }}'}
                    </SyntaxHighlighter>
                    care e echivalent cu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ "akautzu".toUpperCase() }}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Parametrii Pipe</b>
                    <br/>
                    <br/>

                    Parametrii se dau despartii de ":"
                    <br/>
                    <br/>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ myDate | date:\'MMMM\' }}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ myDate | date:\'fullDate\' | uppercase }}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ myNumber |  currency }}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ myNumber |  currency:\'JPY\' }}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ myNumber |  number:\'1.2-5\' }}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Generare Pipe</b>
                    <br/>
                    <br/>

                    Pentru a genera un <b>pipe</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng g pipe <nume_pipe>'}
                    </SyntaxHighlighter>

                    Observatii:
                    <ul>
                        <li>
                            o clasa Pipe este adnotata cu <b>@Pipe</b>
                        </li>
                        <li>
                            o clasa Pipe implementeaza interfata <b>PipeTransform</b>
                        </li>
                        <li>
                            o clasa Pipe trebuie sa implementeze metoda:
                            <SyntaxHighlighter>
                                {'transform(value: unknown, ...args: unknown[]): unknown'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { Pipe, PipeTransform } from \'@angular/core\';\n' +
                            '\n' +
                            '@Pipe({name: \'reverse\'})\n' +
                            '\n' +
                            'export class ReversePipe implements PipeTransform {\n' +
                            '\n' +
                            '  transform(value: unknown, ...args: unknown[]): unknown {\n' +
                            '\n' +
                            '    return value.split(\'\').reverse().join(\'\');\n' +
                            '\n' +
                            '  }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi, va putea fi folosit:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ "string" | reverse}}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Pipe pure/impure</b>
                    <br/>
                    <br/>

                    Pipe-urile poti fi:
                    <ul>
                        <li>pure (asa sunt in mod implicit; nu se reapeleaza daca nu s-a modificat obiectul pe care se aplica <i>pipe</i>)
                        </li>
                        <li>impure: se reapleleaza de fiecare data cand s-a modificat ceva in pagina.
                            <SyntaxHighlighter>
                                {'@Pipe({name: \'reverse\', pure:false})'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>4. Pipe async</b>
                    <br/>
                    <br/>

                    Ar putea ca un obiect sa fie un <i>Promise</i> (deci asincron). Daca vrem sa il afisam, se va afisa ca este un obiect.
                    Pentru a afisa valoare data de acest Promise (cand o veni aceasta valoare) putem folosi pipe-ul <b>async</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{ obiectPromise | async}}'}
                    </SyntaxHighlighter>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PipeAngularContent;