import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class PunctuatieVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-punctuatie", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                
                <div className={"text-justify important"}>


                    <b>Semnele de punctuatie</b>:
                    <ul>
                        <li>separa secventele</li>
                        <li>sunt pauze in naratiune</li>
                        <li>scoate in evidenta sau accentueaza</li>
                    </ul>

                    Metode:
                    <ul>
                        <li>
                            <b>titluri/titles</b>
                            <br/>
                            ofera informatii legate de locul actiunii, data, ora sau alte detalii;
                            <br/>
                            pot aparea peste o imagine sau pe un fundal de aceeasi culoare (de exemplu negru)
                        </li>
                        <li>
                            <b>zona intunecata</b>
                            <br/>
                            camera paranomeaza sau ajunge intr-o zona intunecatata.
                        </li>
                        <li>
                            <b>ecranul negru</b>
                        </li>

                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                              <a href={"https://www.youtube.com/watch?v=jQOXKoMHOE0&ab_channel=anon9u98"}>The Hour of the Furnaces (1968)</a>
                           </div>
                       </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PunctuatieVideografieContent;