import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerfileCopyDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dockerfile-copy", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Dockerfile: COPY</b>

                    <br/>
                    <br/>

                    Aceasta comanda este utila pentru a copia fisiere de pe masina locala in container.
                    <br/>
                    Sintaxa
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'COPY caleMasinaLocala caleContainer'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>caleMasinaLocala: calea catre directorul de unde se copiaza (de pe magina locala) relativ la contextul <i>build</i></li>
                        <li>caleContainer: calea unde se copiaza (inauntrul containerului)</li>
                    </ul>

                    exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'COPY ./ ./'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerfileCopyDockerContent;