import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FactoryFunctionsJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-factory-functions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    O clasa, in JavaScript se poate defini in 3 moduri:
                    <ul>
                        <li>clase</li>
                        <li>modul clasic / function factory</li>
                        <li>modul ES (ESM):
                            <ul>
                                <li>
                                    1. nu există nicio funcție de împachetare pentru a defini un modul;
                                    <br/>
                                    contextul de împachetare este un fișier
                                    <br/>
                                    sunt bazate pe fisiere (un fisier, un modul)
                                </li>
                                <li>
                                    2. nu interacționați cu „API”-ul unui modul în mod explicit, ci mai degrabă utilizați cuvântul cheie <b>export</b>
                                    pentru a adăuga o variabilă sau o metodă la definiția sa publică API.
                                    <br/>
                                    Dacă ceva este definit într-un modul, dar nu este exportat , atunci rămâne ascuns (la fel ca în cazul modulelor clasice )
                                </li>
                                <li>
                                    3. ESM-urile sunt singletoane în sensul că există o singură instanță, prin cuvantul cheie <b>import</b>;
                                    <br/>
                                    toate celelalte "import"-uri primesc  doar o referință la aceeași instanță unică.
                                    <br/>
                                    <b>Observatie:</b>
                                    <br/>
                                    Dacă modulul dvs. trebuie să accepte mai multe instanțieri, trebuie să furnizați o funcție de fabrică în stil modul clasic în definiția dvs. ESM în acest scop.
                                </li>
                            </ul>

                        </li>
                    </ul>

                    <hr/>

                    Un <b>Factory function</b> este o functie care returneaza un nou obiect.
                    <br/>
                    Este un design pattern util pentru a crea multiple instanțe de obiecte cu aceleași proprietăți și metode, dar fără a folosi prototipurile sau clasele.
                    Factory functions permit o mai mare flexibilitate, mai ales când vrei să creezi obiecte cu comportamente dinamice.

                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'function createPersoana(nume, varsta) {\n' +
                            '  return {\n' +
                            '    nume: nume,\n' +
                            '    varsta: varsta,\n' +
                            '    salut: function() {\n' +
                            '      console.log(`Salut! Numele meu este ${this.nume} și am ${this.varsta} ani.`);\n' +
                            '    },\n' +
                            '    imbatrani: function() {\n' +
                            '      this.varsta += 1;\n' +
                            '      console.log(`${this.nume} a împlinit acum ${this.varsta} ani!`);\n' +
                            '    }\n' +
                            '  };\n' +
                            '}\n' +
                            '\n' +
                            '// Crearea obiectelor folosind factory function\n' +
                            'const persoana1 = createPersoana(\'Andrei\', 30);\n' +
                            'const persoana2 = createPersoana(\'Maria\', 25);\n' +
                            '\n' +
                            'persoana1.salut(); // Output: Salut! Numele meu este Andrei și am 30 ani.\n' +
                            'persoana2.salut(); // Output: Salut! Numele meu este Maria și am 25 ani.\n' +
                            '\n' +
                            'persoana1.imbatrani(); // Output: Andrei a împlinit acum 31 ani!'
                        }
                    </SyntaxHighlighter>

                    Factory function cu funcții private:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function createPersoana(nume, varsta) {\n' +
                            '  let greutate = 70; // variabilă privată\n' +
                            '  \n' +
                            '  return {\n' +
                            '    nume: nume,\n' +
                            '    varsta: varsta,\n' +
                            '    salut: function() {\n' +
                            '      console.log(`Salut! Numele meu este ${this.nume} și am ${this.varsta} ani.`);\n' +
                            '    },\n' +
                            '    imbatrani: function() {\n' +
                            '      this.varsta += 1;\n' +
                            '      console.log(`${this.nume} a împlinit acum ${this.varsta} ani!`);\n' +
                            '    },\n' +
                            '    afiseazaGreutatea: function() {\n' +
                            '      console.log(`Greutatea lui ${this.nume} este ${greutate} kg.`);\n' +
                            '    },\n' +
                            '    modificaGreutatea: function(nouaGreutate) {\n' +
                            '      greutate = nouaGreutate;\n' +
                            '      console.log(`${this.nume} are acum ${greutate} kg.`);\n' +
                            '    }\n' +
                            '  };\n' +
                            '}\n' +
                            '\n' +
                            'const persoana = createPersoana(\'Andrei\', 30);\n' +
                            'persoana.afiseazaGreutatea(); // Output: Greutatea lui Andrei este 70 kg.\n' +
                            'persoana.modificaGreutatea(75); // Output: Andrei are acum 75 kg.'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu clasa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'class Book extends Publication {\n' +
                            '    constructor(bookDetails) {\n' +
                            '        super(\n' +
                            '            bookDetails.title,\n' +
                            '            bookDetails.author,\n' +
                            '            bookDetails.publishedOn\n' +
                            '        );\n' +
                            '        this.publisher = bookDetails.publisher;\n' +
                            '        this.ISBN = bookDetails.ISBN;\n' +
                            '    }\n' +
                            '\n' +
                            '    print() {\n' +
                            '        super.print();\n' +
                            '        console.log(`\n' +
                            '            Publisher: ${ this.publisher }\n' +
                            '            ISBN: ${ this.ISBN }\n' +
                            '        `);\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'class BlogPost extends Publication {\n' +
                            '    constructor(title,author,pubDate,URL) {\n' +
                            '        super(title,author,pubDate);\n' +
                            '        this.URL = URL;\n' +
                            '    }\n' +
                            '\n' +
                            '    print() {\n' +
                            '        super.print();\n' +
                            '        console.log(this.URL);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Scris ca <b>factory function</b> / <b>modul clasic</b> , exemplu de mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function Publication(title,author,pubDate) {\n' +
                            '    var publicAPI = {\n' +
                            '        print() {\n' +
                            '            console.log(`\n' +
                            '                Title: ${ title }\n' +
                            '                By: ${ author }\n' +
                            '                ${ pubDate }\n' +
                            '            `);\n' +
                            '        }\n' +
                            '    };\n' +
                            '\n' +
                            '    return publicAPI;\n' +
                            '}\n' +
                            '\n' +
                            'function Book(bookDetails) {\n' +
                            '    var pub = Publication(\n' +
                            '        bookDetails.title,\n' +
                            '        bookDetails.author,\n' +
                            '        bookDetails.publishedOn\n' +
                            '    );\n' +
                            '\n' +
                            '    var publicAPI = {\n' +
                            '        print() {\n' +
                            '            pub.print();\n' +
                            '            console.log(`\n' +
                            '                Publisher: ${ bookDetails.publisher }\n' +
                            '                ISBN: ${ bookDetails.ISBN }\n' +
                            '            `);\n' +
                            '        }\n' +
                            '    };\n' +
                            '\n' +
                            '    return publicAPI;\n' +
                            '}\n' +
                            '\n' +
                            'function BlogPost(title,author,pubDate,URL) {\n' +
                            '    var pub = Publication(title,author,pubDate);\n' +
                            '\n' +
                            '    var publicAPI = {\n' +
                            '        print() {\n' +
                            '            pub.print();\n' +
                            '            console.log(URL);\n' +
                            '        }\n' +
                            '    };\n' +
                            '\n' +
                            '    return publicAPI;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Factory Function vs IIFE</b>
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            Scop: O <b>Factory Function</b> este o funcție care creează și returnează un obiect.
                            Aceasta este utilizată pentru a genera mai multe instanțe de obiecte fără a folosi <b>class</b> sau <b>new</b>.
                            Factory functions sunt o alternativă la clase sau la constructori în JavaScript.
                            <br/>
                            Utilizare: Se folosește atunci când vrei să creezi și să returnezi obiecte multiple, fiecare cu propriile proprietăți și metode.

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'function createPerson(name, age) {\n' +
                                    '    return {\n' +
                                    '        name: name,\n' +
                                    '        age: age,\n' +
                                    '        greet: function() {\n' +
                                    '            console.log("Hello, my name is " + this.name);\n' +
                                    '        }\n' +
                                    '    };\n' +
                                    '}\n' +
                                    '\n' +
                                    'let person1 = createPerson("John", 30);\n' +
                                    'let person2 = createPerson("Jane", 25);\n' +
                                    '\n' +
                                    'person1.greet(); // Output: Hello, my name is John\n' +
                                    'person2.greet(); // Output: Hello, my name is Jane'}
                            </SyntaxHighlighter>
                            Factory Function: Nu se invocă automat, ci trebuie să o apelezi explicit pentru a crea obiecte. Factory functions sunt folosite pentru a genera multiple instanțe de obiecte.
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'let obj = createPerson("John", 30); // Apel explicit al funcției'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Scop: Un IIFE este o funcție care este definită și executată imediat după ce este creată. Este utilizată pentru a izola variabilele și codul, protejând astfel spațiul global de nume.
                            <br/>
                            Utilizare: Se folosește atunci când ai nevoie să rulezi un cod imediat și să păstrezi variabilele într-un spațiu izolat, pentru a nu polua spațiul global.
                            <br/>
                            IIFE: Se auto-invocă imediat după ce este definită. Nu este apelată separat.
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'(function() {\n' +
                                    '    console.log("IIFE invoked immediately!");\n' +
                                    '})(); // Auto-invocare'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FactoryFunctionsJavaScriptContent;