import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class DestructuringReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-destructuring", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>



                    <b>1. Destructurare pe array []</b>
                    <br/>
                    <br/>

                    Destructurarea ajuta la asocierea elementelor dintr-un array in variabile diferite.

                    <br/>
                    <br/>
                    Exemplu (in parametrii p1, p2, p3 se retin valorile din z: z[0], z[1], z[2]):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const z = ["a",1, "b"]\n' +
                            'const [p1, p2, p3 ] = z;\n' +
                            '\n' +
                            'console.log(p1, p2, p3);'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'"a", 1, "b"'}
                    </SyntaxHighlighter>



                    <b>2. Destructurare pe obiect </b>
                    <br/>
                    <br/>

                    Destructurarea ajuta la asocierea proprietatilor dintr-un obiect in variabile diferite, dar care trebuie sa corespunda cu numele proprietatilor.
                    <br/>
                    Se pot asocia si alte nume, folosind <i>nume_proprietate</i>:<i>nume_variabila</i>.

                    <br/>
                    <br/>
                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const p = {\n' +
                            '\tname: "kj",\n' +
                            '  age: 1,\n' +
                            '  car: "red"\n' +
                            '}\n' +
                            '\n' +
                            'const {name, age, car}= p;\n' +
                            '\n' +
                            'console.log(name, age, car)'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'"kj", 1, "red"'}
                    </SyntaxHighlighter>

                    Exemplu 2 ( cu asignare de nume noi ):
                    <SyntaxHighlighter howLineNumbers={true} language="html" style={androidstudio}>
                        {'const p = {\n' +
                            '\tname: "kj",\n' +
                            '  age: 1,\n' +
                            '  car: "red"\n' +
                            '}\n' +
                            '\n' +
                            'const {name:nume, age:ani, car:masina} = p;\n' +
                            '\n' +
                            'console.log(nume, ani, masina)'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DestructuringReactContent;