import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class DfLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-df", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa detalii legate de spatiul liber de pe disk-uri (hdd, ssd, etc):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'df'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Filesystem     1K-blocks     Used Available Use% Mounted on\n' +
                            'tmpfs             607640     2448    605192   1% /run\n' +
                            '/dev/sda3      514416928 44342968 443869596  10% /\n' +
                            'tmpfs            3038180       28   3038152   1% /dev/shm\n' +
                            'tmpfs               5120        0      5120   0% /run/lock\n' +
                            '/dev/sda2         524252     5364    518888   2% /boot/efi\n' +
                            'tmpfs             607636       80    607556   1% /run/user/127\n' +
                            'tmpfs             607636       64    607572   1% /run/user/1000'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DfLinuxContent;