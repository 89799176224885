import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class EMSJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-ems", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    O clasa, in JavaScript se poate defini in 3 moduri:
                    <ul>
                        <li>clase</li>
                        <li>modul clasic / function factory</li>
                        <li>modul ES (ESM):</li>
                    </ul>

                    <hr/>

                    <ul>
                        <li>
                            1. nu există nicio funcție de împachetare pentru a defini un modul;
                            <br/>
                            contextul de împachetare este un fișier
                            <br/>
                            sunt bazate pe fisiere (un fisier, un modul)
                        </li>
                        <li>
                            2. nu interacționați cu „API”-ul unui modul în mod explicit, ci mai degrabă utilizați cuvântul cheie <b>export</b>
                            pentru a adăuga o variabilă sau o metodă la definiția sa publică API.
                            <br/>
                            Dacă ceva este definit într-un modul, dar nu este exportat , atunci rămâne ascuns (la fel ca în cazul modulelor clasice )
                        </li>
                        <li>
                            3. ESM-urile sunt singletoane în sensul că există o singură instanță, prin cuvantul cheie <b>import</b>;
                            <br/>
                            toate celelalte "import"-uri primesc doar o referință la aceeași instanță unică.
                            <br/>
                            <b>Observatie:</b>
                            <br/>
                            Dacă modulul dvs. trebuie să accepte mai multe instanțieri, trebuie să furnizați o funcție de fabrică în stil modul clasic în definiția dvs. ESM în
                            acest scop.
                        </li>
                    </ul>


                    <br/>
                    Exemplu (publication.js):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'function printDetails(title,author,pubDate) {\n' +
                            '    console.log(`\n' +
                            '        Title: ${ title }\n' +
                            '        By: ${ author }\n' +
                            '        ${ pubDate }\n' +
                            '    `);\n' +
                            '}\n' +
                            '\n' +
                            'export function create(title,author,pubDate) {\n' +
                            '    var publicAPI = {\n' +
                            '        print() {\n' +
                            '            printDetails(title,author,pubDate);\n' +
                            '        }\n' +
                            '    };\n' +
                            '\n' +
                            '    return publicAPI;\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    Exemplu (blogpost.js):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'import { create as createPub } from "publication.js";\n' +
                            '\n' +
                            'function printDetails(pub,URL) {\n' +
                            '    pub.print();\n' +
                            '    console.log(URL);\n' +
                            '}\n' +
                            '\n' +
                            'export function create(title,author,pubDate,URL) {\n' +
                            '    var pub = createPub(title,author,pubDate);\n' +
                            '\n' +
                            '    var publicAPI = {\n' +
                            '        print() {\n' +
                            '            printDetails(pub,URL);\n' +
                            '        }\n' +
                            '    };\n' +
                            '\n' +
                            '    return publicAPI;\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                    Exemplu (main.js):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'import { create as newBlogPost } from "blogpost.js";\n' +
                            '\n' +
                            'var forAgainstLet = newBlogPost(\n' +
                            '    "For and against let",\n' +
                            '    "Kyle Simpson",\n' +
                            '    "October 27, 2014",\n' +
                            '    "https://davidwalsh.name/for-and-against-let"\n' +
                            ');\n' +
                            '\n' +
                            'forAgainstLet.print();\n' +
                            '// Title: For and against let\n' +
                            '// By: Kyle Simpson\n' +
                            '// October 27, 2014\n' +
                            '// https://davidwalsh.name/for-and-against-let'
                        }
                    </SyntaxHighlighter>


                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EMSJavaScriptContent;