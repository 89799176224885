import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class TagImageDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-tag-image", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Nume/Tag pentru o imagine</b>

                    <br/>
                    <br/>

                    Cand se creaza o imagine dintr-un Dockerfile se poate specifica un nume/tag.
                    <br/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t nume .'}
                    </SyntaxHighlighter>
                    Conventia pentru nume este:
                    <ul>
                        <li>dockerId/projectName:version</li>
                    </ul>
                    De exemplu: iulianbuzdugan/redis:latest

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker build -t iulianbuzdugan/redis:latest .'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'Sending build context to Docker daemon  2.048kB\n' +
                            'Step 1/4 : FROM alpine\n' +
                            ' ---> 9c6f07244728\n' +
                            'Step 2/4 : RUN apk add --update redis\n' +
                            ' ---> Using cache\n' +
                            ' ---> 69133d5fb34e\n' +
                            'Step 3/4 : RUN apk add --update gcc\n' +
                            ' ---> Using cache\n' +
                            ' ---> 895c3853ce52\n' +
                            'Step 4/4 : CMD ["redis-server"]\n' +
                            ' ---> Using cache\n' +
                            ' ---> 2ca7526999e8\n' +
                            'Successfully built 2ca7526999e8\n' +
                            'Successfully tagged iulianbuzdugan/redis:latest'}
                    </SyntaxHighlighter>
                    acum, folosind tag-ul <i>iulianbuzdugan/redis:latest</i> putem construi imaginea si o executa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run iulianbuzdugan/redis:latest'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run iulianbuzdugan/redis'}
                    </SyntaxHighlighter>
                    daca se omite versiunea, implicit va fi <i>latest</i>

                    <br/>
                    Pentru a iesi din container se apasa CTRL+C.


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TagImageDockerContent;