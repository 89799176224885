import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Tips1NuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-anexa-tips1", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    1. Pentru previzualizare pe documentele Office în asociere cu <b>pdftohtml</b> se foloseste <b>LibreOffice</b>.

                    <hr/>

                    2. Pentru a genera miniaturi (thumbnails) ale documentelor in asociere cu <b>Ghostscript</b> se foloseste <b>ImageMagick</b>.

                    <hr/>

                    3. Pentru a genera miniaturi(thumbnails) de documente RAW in asociere cu <b>Ghostscript</b> si <b>ImageMagick</b> se foloseste <b>UFRaw</b> (Unidentified Flying Raw).

                    <hr/>

                    4. Addon-ul <b>Nuxeo Binary Metadata</b> are nevoie de <b>ExifTool</b>.

                    <hr/>

                    5. Pentru incarca a aloca incarcarea (load) între nodurile Nuxeo se foloseste <b>load balancers</b>.

                    <hr/>

                    6. Pentru a utiliza planificare Quartz trebuie configurat mediul <b>Clustered Nuxeo</b> (Nuxeo in cluster).

                    <hr/>

                    7. Sistemul se ocupă de propagarea invalidărilor între toate nodurile clusterelor <b>Nuxeo Cluster system</b>.

                    <hr/>

                    8. Inlocuirea adresei URL implicite de conectare la pornire este permisa prin intermdiul: <b>NUXEO_CONNECT_URL</b>.

                    <hr/>

                    9. Pentru a evita ca utilizatorii să acceseze direct serverul Nuxeo se foloseste <b>Reverse proxy</b>.

                    <br/>
                    vezi: Nuxeo Reference Architecture - Prerequisites

                    <hr/>

                    10. Elasticsearch este folosit pentru a scuti baza de date de cele mai costisitoare operațiuni:
                    <ul>
                        <li>
                            păstrează indici pe documente pentru a îmbunătăți performanța de căutare, pe volume foarte mari.
                        </li>
                        <li>
                            acesta stochează jurnalul de audit al documentului.
                            Deoarece fiecare operațiune asupra unui document în Nuxeo este stocate în posibile scopuri de audit,
                            tabelul corespunzător ar crește foarte rapid și poate ajunge la milioane de tupluri atunci când este stocat în baza de date.
                            Folosind Elasticsearch, aceasta este nu mai este o problema.
                        </li>
                        <li>
                            se scalează pe orizontală și oferă performanță constantă chiar și cu dimensiunea conținutului în creștere.
                        </li>
                    </ul>

                    <hr/>

                    11. Livrat cu implementările <b>Kafka</b> și aduce mai mult rezistență și o capacitate de distribuție mai mare este <b>Nuxeo Stream</b>.

                    <hr/>

                    12. Pentru <b>WorkManager, Key Value Store</b> și <b>serviciu PubSub</b> se poate folosi <b>Redis</b>.

                    <hr/>

                    13. Pentru trimite mesaje instant către toate nodurile Nuxeo, în principal pentru invalidarea cache-ului se foloseste serviciul <b>PubSub</b>.

                    <hr/>

                    14. Scriptul care activează diverse opțiuni și comenzi este <b>Nuxeoctl</b>.

                    <hr/>

                    15. Pluggable astfel încât să poată fi adaptat la diferite implementări medii și cazuri de utilizare este <b>Nuxeo</b>.

                    <hr/>

                    16. Pentru a obține o estimare a volumului de utilizare pentru imagini și videoclipuri, ar trebui să se țină cont de următoarele trei criterii:
                    <ul>
                        <li>Volumul fișierului: de obicei, 1MB-5MB per fișier, cu excepția cazului în care gestionați în principal videoclipurile</li>
                        <li>Miniaturi (thumbnails) generate: în funcție de dimensiunea imaginii originale, miniaturile vor fi între 10% și 100% din dimensiunea fișierului original</li>
                        <li>Volumul textului: 1% din volumul fișierului</li>
                    </ul>

                    <hr/>
                    17. Compus în mare parte din proprietăți și are puține fișiere atașate este <b>continut structurat</b> (Structured Content).

                    <hr/>
                    18. Add-on-ul <b>nuxeo-multi-tenants</b> oferă un sistem de izolare multi-tenant care adaugă platformei Nuxeo:
                    <ul>
                        <li>o noțiune configurabilă de tenant (chirias): în mod implicit, un tenant este un domeniu</li>
                        <li>o izolare a utilizatorilor la nivel de tenant: cu excepția administratorilor principali, utilizatorilor și grupuri și definite și gestionate la nivel de tenant</li>
                        <li>o izolare a datelor la nivel de tenant: documentele sunt izolate pentru fiecare tenant folosind o politică de securitate și ACL-uri.</li>
                    </ul>

                    <hr/>
                    19. Platforma de aplicații container realizată de <b>RedHat</b> este <b>Openshift</b>.

                    <hr/>
                    20. WorkManager se ocupă de joburi asincrone:
                    <ul>
                        <li>programeaza job-uri și le stocheaza în cozi</li>
                        <li>atributie sloturi de execuție la cozi</li>
                        <li>Execută job-urile (sarcinile)</li>
                    </ul>

                    <hr/>

                    21. Implementare bazată pe <b>Redis</b> a <b>Transient Store</b> este <b>RedisTransientStore</b>.

                    <hr/>

                    22. Platforma Nuxeo citește proprietățile de configurare pe care le puteți seta din:
                    <ul>
                        <li>fisierul <b>nuxeo.conf</b></li>
                        <li>prin contribuția la Serviciul de configurare (Configuration Service)</li>
                        <li>din tab-ul <b>Setup</b> din <b>Admin Center</b> (Centrul de administrare) (dacă este instalată interfața de utilizare <b>Nuxeo JSF</b>)</li>
                    </ul>

                    <hr/>

                    23. Aplicația care integrează un sistem de șabloane de configurare pentru a ușura configurarea și întreținerea fișierelor de configurare este <b>Nuxeo applications</b>.

                    <hr/>

                    24. NU: Șabloanele Nuxeo vă permit să oferiți un anumit nivel de configurare orice mediu Nuxeo din <b>nuxeo.conf</b>.

                    <hr/>

                    25. La configurarea unei proprietăți cu o valoare criptată, variabilele utilizate în șabloanele de configurare vor fi înlocuite cu:
                    <ul>
                        <li>valoarea criptată în șabloanele text și fișierele de proprietăți</li>
                        <li>variabila originală din șabloanele FreeMarker</li>
                    </ul>

                    <hr/>

                    26. Motorul de stocare implicit pentru documentele Nuxeo este <b>VCS (Visible Content Store)</b>.

                    <br/>
                    vezi: video <i>Nuxeo Architecture - Document Metadata</i>
                    <br/>
                    Exista 2 implementari pentru Document Metadata:
                    <ul>
                        <li>
                            VCS (Visible Content Store): datele sunt stocate in RDBMS (PostgreSQL, SQL Server, Oracle)
                        </li>
                        <li>
                            DBS (Document Base Store): datele sunt stocate pe un sistem MongoBD (una dintre avantaje: performata ridicata)
                        </li>
                    </ul>

                    <hr/>

                    27. Nuxeo este capabil să se recupereze din conexiuni pierdute folosind o excepție de sortare, al cărui rol este de a determina care erori sunt fatale:
                    <ul>
                        <li>dacă conexiunea este detectată ca nevalidă de către driver cu 10 secunde pauză</li>
                        <li>dacă codul de eroare returnat este considerat FATAL, conexiunea este distrusa din pool</li>
                        <li>in caz contrar conexiunea se păstrează în pool și va fi refolosită ulterior</li>
                    </ul>

                    <hr/>

                    28. Registrul de comenzi pe care le puteți înregistra în Shell: <b>Namespaces</b>.

                    <hr/>

                    29. Un segment URL care începe sa transforme intrarea resursă astfel încât să returneze o altă resursă este <b>WebAdapter</b>.

                    <hr/>

                    30. Specificația <b>OASIS</b> pentru managementul conținutului interoperabilitate este <b>CMIS</b>.

                    <hr/>

                    31. Configurat pentru <b>REST API v1</b> și <b>Automation Server</b> este <b>Marshalling service</b>.

                    <hr/>

                    32. Un marshalling JSON flexibil este oferit de <b>Nuxeo Platform</b>.

                    <hr/>

                    33. <b>Nuxeo Authentication</b> se bazează pe standardul <b>JAAS</b>. Autentificare infrastructura se bazează pe două componente principale:
                    <ul>
                        <li>JAAS Login Module: <b>NuxeoLoginModule</b></li>
                        <li>Web Filter: <b>NuxeoAuthenticationFilter</b></li>
                    </ul>

                    34. Gestionate prin <b>UserManagerService</b> care se ocupă de indirecte către directoarele de utilizatori și grupuri este: <b>Users</b> si <b>groups</b>.

                    <hr/>

                    35. Unul dintre filtrele de nivel superior din stiva Nuxeo Web Filter este <b>NuxeoAuthenticationFilter</b>.

                    <hr/>
                    36. Un serviciu Nuxeo care expune acțiunile comune pe care le faci pe o aplicatie Nuxeo ca operații atomice este <b>Content Automation</b>.

                    <hr/>

                    37. Framework-ul care oferă o modalitate ușoară de a obține urma ultimei execuții a unui lanț de automatizare este <b>Nuxeo Platform framework</b>.

                    <hr/>

                    38. Ce nu este implementat în serverul și clientul Automation si numai documentele și blob-urile pot fi manipulate: <b>Automatic marshalling</b>.

                    <hr/>

                    39. Functiile fara stare folosite in MVEL sau scripuri Javascript Automation: <b>Automation Helpers</b>

                    <hr/>

                    40. Definit conceptual folosind un grafic este un <b>Workflow</b>.

                    <hr/>

                    41. Documentul <b>DocumentRoute</b> și <b>RouteNodes</b>  sunt utilizate de motorul workflow pentru a:
                    <ul>
                        <li>citi și stoca fluxul de lucru (workflow) și variabilele nodului</li>
                        <li>citi definiția graficului cu tranzițiile dintre noduri</li>
                        <li>persista starea fluxului de lucru</li>
                    </ul>

                    <hr/>
                    42. Pași automati sau noduri de tip task: <b>Workflow nodes</b>

                    <hr/>

                    43. Capabili să creeze o configurație consistentă datorită verificărilor în timp real,
                    capabili sa avertizeaze asupra oricăror erori sau puncte de configurare lipsă și
                    utilizează o completare automată sistem pentru a ghida prin activitatea de implementare este <b>designeri de soluții</b> (solution designers).


                    <hr/>

                    44. Instrumentul care este o modalitate sigură și automată de a crea un echivalent Web UI configuration bazată pe configurația existentă a interfeței de utilizare JSF: <b>JSF UI to Web UI migration tool</b>.

                    <hr/>

                    45. Rezultatele apar într-o ramură indicată în lista Migrare anterioară (Previous Migration):
                    <ul>
                        <li>pentru a verifica rezultatul migrării, accesați Source Control și Branch Management</li>
                        <li>pentru a comuta la noul Branch, se apasa pe Checkout.</li>
                    </ul>

                    46. <b>Branch Management</b> ofera o caracteristică de colaborare care este inspirat din sistemele distribuite de control al reviziilor: <b>Nuxeo Studio</b>.

                    <hr/>

                    47. Când există o dependență, proiectul copil va moșteni majoritatea aspectelor a proiectului dependent, inclusiv:
                    <ul>
                        <li>Configurația laterală a Studio Modeler și orice registre adăugate manual</li>
                        <li>Orice date și traduceri care fac parte dintr-un proiect Studio</li>
                        <li>Studio Designer front-end și asset-urile de branding</li>
                    </ul>

                    <hr/>

                    48. <b>Dependintele</b> se adaugă recursiv, de la nivelul cel mai profund la cel mai înalt, și de la stânga la dreapta, în ordinea în care sunt adăugate.

                    <hr/>

                    49. Dacă se doreste să păstrarea tab-ului de căutare fațete din stânga în tab-ul meniului principal al spațiului de lucru:
                    <ul>
                        <li>in Studio, verificați pachetul țintă <b>Faceted search</b> in seciunea <b>Application Definition</b>
                            Indicatorul <b>Faceted search</b> este disponibil și verificat pe vizualizări de conținut.
                        </li>
                        <li>in instanța Nuxeo Platform, se instaleaza add-on-ul <b>Faceted search</b></li>
                    </ul>

                    50. <b>Nuxeo Studio Community Cookbook</b> este un depozit GitHub care conține contribuții și monstre de cod Studio care pot fi reutilizate liber în proiectul Nuxeo Studio.

                    <hr/>
                    <hr/>

                    A1. Audit Service API:
                    <ul>
                        <li>AuditReader (citire date din audit)</li>
                        <li>AuditLogger (adaugare date in audit)</li>
                        <li>AuditAdmin (monitorizare date audit)</li>
                    </ul>

                    <hr/>

                    A2. Motive pentru audit:
                    <ul>
                        <li>tehnice</li>
                        <li>securitate</li>
                        <li>business</li>
                        <li>legale</li>
                    </ul>

                    <hr/>

                    A3. Audit:
                    <ul>
                        <li>sincron: integrat intr-o tranzactie</li>
                        <li>asincron: folosit seperarat de o tranzactie</li>
                    </ul>

                    <hr/>

                    A4. Document Indexes sunt folosite pentru a efectua queries Nuxeo.

                    <hr/>

                    A5. Nuxeo Platform se bazeaza pe Elasticsearch pentru gestionarea index-ilor.

                    <hr/>

                    A6. Streams sunt utilizate in:
                    <ul>
                        <li>scriere audit</li>
                        <li>StreamWorkManager</li>
                        <li>Stream PubSub</li>
                        <li>Framework-ul Bulk Action</li>
                    </ul>

                    <hr/>

                    A7. Key/Value Store pentru a gestiona job-uri asincrone:
                    <ul>
                        <li>conversie imagini</li>
                        <li>
                            extragere fulltext
                        </li>
                        <li>
                            global atomic locks
                        </li>
                        <li>
                            global transient metadata
                        </li>
                    </ul>

                    <hr/>

                    A8. Transient Data - pentru a stoca temporar blob-uri si parametrii asociati:
                    <ul>
                        <li>
                            Batch uploads
                        </li>
                        <li>
                            conversii
                        </li>
                        <li>
                            BatchHandler
                        </li>
                    </ul>

                    <hr/>

                    A9. Load balancer:
                    <ul>
                        <li>sesiuni sticky (sesiune persistente; sesiune intre un utilizator si un server; load balancer-ul gaseste un mod de a identifica utilizatorul spre al redirecta spre acelasi server pe durata unei sesiuni)</li>
                        <li>query-uri balansate intre nodurile Nuxeo</li>
                        <li>redirectare query-uri de pe un anumit HTTP catre noduri specifice</li>
                    </ul>

                    <hr/>

                    A10. Reverse proxy: Pentru a evita utilizatorilor acesarea Nuxeo in mod direct.

                    <hr/>

                    A11. Numar minim de noduri ElasticSearch: 3.

                    <hr/>

                    A12. Streams:
                    <ul>
                        <li>procese asincrone</li>
                        <li>implementare Kafka</li>
                        <li>minim 2 noduri de Kafka</li>
                    </ul>

                    <hr/>

                    A13. Key/Value Store:
                    <ul>
                        <li>
                            implementare MongoDB
                        </li>
                        <li>
                            minim 2 noduri mongoDB
                        </li>
                    </ul>

                    <hr/>

                    A14. Componente pe baza de implementare Kafka
                    <ul>
                        <li>Work Manager</li>
                        <li>Nuxeo Stream/Bulk Service</li>
                        <li>Pub/Sub Service</li>
                    </ul>

                    <hr/>
                    A15. Alternativa nerecomandata la Kafka: Redis (dar, Bulk Service nu se poate folosi).

                    <hr/>
                    A16. Permisiunile se bazeaza pe ACL (Access Control Lists)

                    <hr/>
                    A17. Nuxeo Security Service {"->"} Nuxeo Security Policy:
                    <ul>
                        <li>CheckPermission: suprascrie sau completeaza securitatea bazata pe ACL, garanteaza sau restrictioneaza accesul pe baza documentului sau atributele utilizatorului </li>
                        <li>QueryTransformer: suprascrie search security filtering / adauga restrictii la query-uri NXQL </li>
                    </ul>

                    <hr/>
                    A18. Metrici DR (Disaster Recovery):
                    <ul>
                        <li>RTO: Recovery Time Objective (de exemplu, timpul necesar de a-si reveni aplicatia de la dezastru, cu backup restore)</li>
                        <li>RPO: Recovery Point Objective (de exemplu, reprezinta momentele de tip cand se fac backup-uri)</li>
                    </ul>

                    <hr/>
                    A19. Fisierul <b>nuxeo.conf</b> este in: <b>/NUXEO_HOME/bin</b> (locatia se poate schimba setand variabila de mediu <b>NUXEO_CONF</b> / pe productie e recomandat sa fie intr-o alta locatie diferinta de /NUXEO_HOME/bin).

                    <hr/>

                    A20. Fisierul <b>nuxeo.conf</b> poate fi editat:
                    <ul>
                        <li>manual (pastrand inacte sectiunile cu DO DOT EDIT BETWEEN BEGIN AND END)</li>
                        <li>comanda: <b>nuxeoctl config</b> (cea mai buna solutie)</li>
                    </ul>

                    <hr/>

                    A21. <b>Nuxeo Package</b> este calea cea mai usoara si recomandata pentru:
                    <ul>
                        <li>impachetarea (packaging)</li>
                        <li>distribuirea (distributing)</li>
                        <li>instalarea (installing)</li>
                    </ul>
                    unui: <i>addon</i>, <i>plugin</i>, <i>bundle</i>, configuratii, set de plugin-uri, etc.

                    <hr/>

                    A22. Nuxeo Packages contine un <b>Manifest</b>:
                    <ul>
                        <li>proprietatea <n>target platform</n>: verifica ca versiunea de pe server sa fie in conformitate cu versiunea din paakage</li>
                    </ul>

                    <hr/>

                    A23. <b>Meta-Package</b> este un Nuxeo Package gol cu dependinte catre alte package-uri.

                    <hr/>

                    A24. <b>nuxeocrl</b> este Nuxeo (server) constroller.

                    <hr/>

                    A25. Pentru cripta parametrii Nuxeo: <b>nuxeoctl config --encrypt</b>


                    <hr/>

                    A26. Urmatoarea comanda: <b>nuxeoctl config some.sensitive.property someValue --encrypt -q</b>
                    cripteaza in <b>nuxeo.conf</b> proprietatea <i>some.sensitive.property </i> cu valoarea <i>someValue</i> in modul <i>quiet</i>

                    <hr/>
                    A27. Comanzile rulata inatai cand se contacteaza suportul:
                    <ul>
                        <li><b>nuxeoctl showconf</b></li>
                        <li><b>nuxeoctl mp-show</b>: pentru detalii pe un pachet</li>
                    </ul>

                    <hr/>
                    A28. Comenzi pentru a porni serverul: <b>nuxectrl [start|startbg|console|restart|restartbg]</b>

                    <hr/>
                    A29. <b>nuxeoctl logs</b> comenzile sale echivalente java în <b>nuxeoctl.log</b>

                    <hr/>

                    A30. <b>nuxeoctrl help</b>: comanda echivalenta in java:  <b>java -jar bin/nuxeo-launcher.jar help -Dnuxeo.home="cale/catre/server"</b>

                    <hr/>

                    A31. Nu este corect rularea comenzii <b>nuxeoctl</b> ca <i>root</i>.
                    <br/>
                    <b>nuxeoctl</b> trebuie rulata cu user-ul serverului, de exemplu <i>nuxeo</i>.
                    <br/>
                    De obicei, un script de serviciu: /etc/init.d/nuxeo va face switch la utilizator inainte de apelulul comenzii <b>nuxeoctl</b> cu proprietatile: NUXEO_CONF si NUXEO_HOME.,


                    <hr/>
                    A32. Aplicatiile Nuxeo integreaza un <i>sistem sablon / template de configurare</i> pentru configurarea usora si mentenanta fisierelor de configurare.
                    <br/>
                    Nuxeo vine cu template-uri default care ofera configurari de baza de date, dar template-urile pot fi folosite pentru orice scop de configurare.
                    <br/>
                    Configurarile de template-uri se gasesc in sub-foldere din: <b>NUXEO_HOME/templates</b>.

                    <hr/>
                    A33. Se pot create template-uri custom dar nu trebuie editate template-uri Nuxeo.

                    <hr/>
                    A34. Portul bazei de date poate fi schimbat din <b>nuxeo.conf</b>.
                    Pentru schimbarea valorii implicite, atunci trebuie creat un template custom si setata valoarea default in <b>nuxeo.defaults</b>.

                    <hr/>
                    A35. <b>nuxeo.template.include</b> este o proprietate folosita in configurarea template-urilor pentru a <i>seta mosternirea intre template-uri</i>.


                    <hr/>
                    A36. Template-uri custom ar trebui pastrate in alta locatie, diferita de ierarhia sistemului de fisiere a server-ului.

                    <hr/>
                    A37. Un ACL este modelul principal pentru gestiunea securitatii, bazat pe sistemul ACL (Access Control List).
                    <br/>
                    Un ACL este compus din ACE-uri.
                    <br/>
                    Fiecare ACE este un triplet: utilizator sau grup, permisiune sau grup de permisiuni, Grant sau deny.

                    <hr/>
                    A38. <b>Security Model</b> se poate baza o anumita valoare dintr-o proprietate, folosind <b>Security Policies</b> (de exemplu,
                    documentele cu pret {">"} X sunt accesibile doar anumitor utilizatori din grupul Y).

                    <hr/>
                    A39. Componente din arhitectura Nuxeo trebuie configurate pentru a imbunatatii securitatea:
                    <ul>
                        <li>Tomcat Web Server</li>
                        <li>Apache Web Server</li>
                        <li>locatia fisierelor de configurare Nuxeo</li>
                        <li>locatia unde se pastreaza fisierele binare</li>
                        <li>locatia fisirelor de congigurare a bazei de date</li>
                        <li>locatia unde se pastreaza index-ul</li>
                    </ul>

                    <hr/>
                    A40. Utilizatori si grupurile sunt gestionati componenta <b>UserManager</b> (pasteaza datele in <b>Directories</b>).
                    Un <b>Directorries</b> poate fi:
                    <ul>
                        <li>implementare SQL (baza de date RDBMS)</li>
                        <li>implementare DBS</li>
                        <li>implementare LDAP</li>
                        <li>implementare Nuxeo Platform repository (Utilizatorii si Grupurile sunt atunci 'documente')</li>
                        <li>implemntare custom pentru apel la distanta de servicii web</li>
                    </ul>

                    <hr/>
                    A41. Un lat de autentificare (authentication chain) este compus din plugin-uri, configurate in <b>extensii XML</b>.
                    <br/>
                    Nuxeo Platform va utiliza plugin-urile in ordinea in care sunt inlantuite pana cand unul este cu succes.

                    <hr/>
                    A42. Recomandari pentru mediul de producitie:
                    <ul>
                        <li>Mutarea configurarilor, directoarelor datea si log in afara Nuxeo</li>
                        <li>definirea de variabile de mediu</li>
                        <li>schimbarea bazei de date embedded</li>
                        <li>setup Nuxeo cluster arhitecture pentru a oferi o disponibilitate ridicata</li>
                    </ul>

                    <hr/>
                    A43. Procedura pentru backup; ordinea:
                    <ul>
                        baza de date date trebuie <i>backup</i> la inceput, fi apoi celelalte resurse de pastrare a datelor (filesytem, remote buckets, etc)
                    </ul>

                    <hr/>
                    A44. Posibile politici de crearea a vocalbularelor:
                    <ul>
                        <li>Don't override existing values</li>
                        <li>Always</li>
                        <li>Never</li>
                    </ul>

                    <hr/>
                    A45. Diferenta dintre CSV importat si CSV referentiat:
                    <ul>
                        <li>fisierul CSV importat ajuta la initializarea vocabularului cu valori</li>
                        <li>fisierul CSV referentiat nu este nicidata 'parsed' din Studio</li>
                    </ul>

                    <hr/>
                    A46. Fluxu pentru un document sters din Nuxeo Web UI:
                    <ul>
                        <li>ajunge in folder-ul <b>trash</b></li>
                        <li>documentul este sters permanent din <b>trash</b> view</li>
                        <li>proprietatile documentului sunt sterse din baza de date</li>
                        <li>binarele nu sunt sterge, putand fi reutlizate in alte documente</li>
                    </ul>

                    <hr/>
                    A47. Fisiere de log generate in mod implicit:
                    <ul>
                        <li>nuxeoctl.log: logheaza activatea din NuxeoCtl</li>
                        <li>console.log: logheaza ce este scris in console (stdout)</li>
                        <li>server.log: log-urile server-ului.</li>
                        <li>nuxeo-error.log: erorile arucate de utilizator</li>
                        <li>stderr.log: logheaza erorile scrise in console (stderr)</li>
                        <li>classloader.log: erorile de class loading din Catalina</li>
                        <li>tomcat.log: log-urile in tomcat</li>
                    </ul>

                    <hr/>

                    A48. Nuxeo Stream in Nuxeo Platform:
                    <ul>
                        <li>Bulk Action Framework</li>
                        <li>Stream Audit Writer</li>
                        <li>Nuxeo Workers</li>
                        <li>PubSub Service</li>
                    </ul>

                    <hr/>
                    A49. Nuxeo Stream:
                    <ul>
                        <li>pentru a contrui o arhitectura: fully resilient, distribuita si fault-tolerant:
                            <ul>
                                <li>manipulare documente foarte mari, si in caz de eroare</li>
                                <li>scalare orizontala, oferind toate necesare monitorizarii</li>
                            </ul>
                        </li>
                    </ul>

                    A50. Link/Video Kafka:
                    <ul>
                        <li>
                            <a href={"https://www.youtube.com/watch?v=UEg40Te8pnE&ab_channel=Devoxx"}>Introduction to Apache Kafka by James Ward (2017)</a>
                        </li>
                        <li>
                            <a href={"https://www.youtube.com/playlist?list=PLt1SIbA8guusxiHz9bveV-UHs_biWFegU"}>Apache Kafka for Beginners (2019)</a>
                        </li>
                        <li>
                            <a href={"https://towardsdatascience.com/overview-of-ui-tools-for-monitoring-and-management-of-apache-kafka-clusters-8c383f897e80"}>
                                Overview of UI Tools for Monitoring and Management of Apache Kafka Clusters
                            </a>
                            UI Monitoring Tools pentru Apache Kafka
                            <ul>
                                <li>AKHQ sau KafkaHQ</li>
                                <li>Kafdrop</li>
                                <li>UI for Apache Kafka</li>
                            </ul>

                        </li>
                    </ul>

                </div>


                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Tips1NuxeoContent;