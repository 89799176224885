import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class ConnectDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "connect-docker", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Conectare la container</b>
                    <br/>
                    <br/>
                    Pentru conectarea la un container se foloseste comanda <b>docker container run</b> cu flag-urile:
                    <ul>
                        <li><b>interactive</b>: pentru a specifica ca se doreste conectarea la container</li>
                        <li><b>tty</b>: pentru a specfica ca se doreste conectarea la o terminalul din container</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container run --interactive --tty <container>'}
                    </SyntaxHighlighter>

                    Comenzi care se pot folosi in terminal:
                    <ul>
                        <li>date</li>
                        <li>hostname</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'/ # date\n' +
                        'Sun Feb 20 13:44:04 UTC 2022\n' +
                        '/ # hostname\n' +
                        '351ada097acf'}
                    </SyntaxHighlighter>

                    Daca listam containerele, folosind comanda <b>docker container ls</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container ls\n' +
                        'CONTAINER ID   IMAGE         COMMAND     CREATED          STATUS          PORTS     NAMES\n' +
                        '351ada097acf   diamol/base   "/bin/sh"   59 seconds ago   Up 59 seconds             optimistic_hopper'}
                    </SyntaxHighlighter>

                    Vom observa ca <b>hostname</b> este identic cu <b>CONTAINER ID</b> (de exemplu: 351ada097acf).

                    <br/>
                    <br/>
                    Docker atributie un ID generat aleator pentru fiecare container creat si acest ID este folosit ca hostname.

                    <br/>
                    <br/>
                    Pentru a iesi din sesiunea de terminal, se foloseste comanda <b>exit</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> exit'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.1. Afisare procese in container</b>
                    <br/>
                    Pentru afisare proceselor care ruleaza pe un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container top <containerID>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container top 351ada097acf\n' +
                        'UID                 PID                 PPID                C                   STIME               TTY                 TIME                CMD\n' +
                        'root                1560                1539                0                   14:45               ?                   00:00:00            /bin/sh'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.2. Afisare log-uri din container</b>
                    <br/>
                    Pentru afisare log-uri dintr-un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container logs <containerID>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container logs 351ada097acf\n' +
                        '/ # date\n' +
                        'Sun Feb 20 13:44:04 UTC 2022\n' +
                        '/ # hostname\n' +
                        '351ada097acf\n' +
                        '/ # exit'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.3. Afisare detalii despre container</b>
                    <br/>
                    Pentru afisare detalii despre container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container inspect <containerID>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container inspect 351ada097acf\n' +
                        '[\n' +
                        '    {\n' +
                        '        "Id": "351ada097acfdbd7d5b55b47f0fe9da7f1446edb19e6b1e1e9e9ca634ad0c8f8",\n' +
                        '        "Created": "2022-02-20T13:43:51.2847782Z",\n' +
                        '        "Path": "/bin/sh",\n' +
                        '        "Args": [],\n' +
                        '        "State": {\n' +
                        '            "Status": "exited",\n' +
                        '            "Running": false,\n' +
                        '            "Paused": false,\n' +
                        '            "Restarting": false,\n' +
                        '            "OOMKilled": false,\n' +
                        '            "Dead": false,\n' +
                        '            "Pid": 0,\n' +
                        '            "ExitCode": 0,\n' +
                        '            "Error": "",\n' +
                        '            "StartedAt": "2022-02-20T13:43:51.700723Z",\n' +
                        '            "FinishedAt": "2022-02-20T15:44:28.7461386Z"\n' +
                        '        },\n' +
                        '........'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.4. Afisare toate containerele</b>
                    <br/>
                    Pentru afisare toate continerele se folosete flag-ul <b>all</b> (in lipsa acestului flag, se afiseza doar containerele care ruleaza).
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'PS> docker container ls -all'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container ls -all\n' +
                        'CONTAINER ID   IMAGE         COMMAND     CREATED       STATUS                     PORTS     NAMES\n' +
                        '351ada097acf   diamol/base   "/bin/sh"   2 hours ago   Exited (0) 9 minutes ago             optimistic_hopper'}
                    </SyntaxHighlighter>

                    In exemplul de mai sus, s-a afisat un container cu status-ul Exited.

                    <br/>
                    <br/>
                    <i>Observatii</i>:
                    <ul>
                        <li>containerele ruleaza doar cat ruleaza aplicatiile din interiorul containerului; (un container interactiv se termina cand se iese din aplicatia terminal)</li>
                        <li>containerele nu dispar, dupa ce se opresc, ele continua sa existe, putand fi pornite din nou</li>
                        <li>containerele in starea exist continua sa ocupe spatiu pe disk</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                Learn Docker in a month of lunches - Elton Stoneman, Manning, 2020
                            </div>
                        </li>

                        <li>
                            <div>
                                Docker Demystified - Saibal Ghosh, BPB, 2021
                            </div>
                        </li>

                        <li>
                            <div>

                                <a href={"https://ocw.cs.pub.ro/courses/idp/laboratoare/01"}>Laboratorul 01 - Docker</a>

                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ConnectDockerContent;