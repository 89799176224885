import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InstallLambdasJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-lambdas", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Expresiile lambda au fost introduse in Java 8.

                    <br/>
                    <br/>

                    O <b>expresie lambda</b> (sau <b>lambdas</b>) poate fi vazută ca o <b>metodă fără nume</b>, fiind un scurt bloc de cod care preai parametrii si returneaza o valoare.

                    <br/>
                    Daca e un singur parametru se poate scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Tip parametru -> {return expresie}'}
                    </SyntaxHighlighter>
                    sau mai simplificat:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'parametru -> expresie'}
                    </SyntaxHighlighter>

                    Daca are mai multi parametrii, atunci se folosesc <b>paranteze rotunde</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'(parametru_1, parametru_2) -> expresie'}
                    </SyntaxHighlighter>

                    Expresiile sunt limitate. Acestea trebuie să returneze imediat o valoare și nu pot conține variabile, atribuiri sau instrucțiuni precum if sau for.
                    Pentru a face operații mai complexe, blocul de cod trebuie sa fie intre <b>acolade</b>.
                    Dacă expresia lambda trebuie să returneze o valoare, atunci blocul de cod ar trebui să aibă o instrucțiunea <b>return</b>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'(parametru_1, parametru_2) -> {bloc_de_cod}'}
                    </SyntaxHighlighter>


                    Expresiile Lambda pot fi <b>stocate în variabile doar dacă tipul variabilei este o interfață care are o singură metodă</b>.
                    <br/>
                    O interfata care are o singura metoda abstracta se numeste <b>interfata functionala</b>.

                    <br/>
                    <br/>
                    <i>Exemplu 1:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public interface StringFunction {\n' +
                        '\n' +
                        '    String show(String text);\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class LambdaTest {\n' +
                        '\n' +
                        '   public static void main(String[] args) {\n' +
                        '\n' +
                        '       StringFunction sf = (t) -> "Ai scris: "+t;\n' +
                        '       // echivalent cu: \n' +
                        '       StringFunction sf = (t) -> {return "Ai scris: "+t;}; // TREBUIE PUS ;\n' +
                        '\n' +
                        '       System.out.println(sf.show("text")); // Ai scris:text\n' +
                        '\n' +
                        '   }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <i>Exemplu 2:</i>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public interface StringFunction2 {\n' +
                        '\n' +
                        '    void show(String text);\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class LambdaTest2 {\n' +
                        '\n' +
                        '   public static void main(String[] args) {\n' +
                        '\n' +
                        '       StringFunction2 sf = (t) -> System.out.println("Ai scris:"+ t);\n' +
                        '\n' +
                        '       sf.show("test"); // Ai scris:text\n' +
                        '\n' +
                        '   }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Interfate functionale:
                    <ul>
                        <li>Predicate:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public interface Predicate<T> {\n' +
                                '\n' +
                                '    boolean test(T t);\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>

                        <li>Consumer:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public interface Consumer<T> {\n' +
                                '\n' +
                                '    void accept(T t);\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Daca relua <i>Exemplul 2</i>, putem renunta la interfata <i>StringFunction2</i> si folosi interfata <b>Predicate</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'import java.util.function.Consumer;\n' +
                                '\n' +
                                'public class LambdaTest2a {\n' +
                                '\n' +
                                '   public static void main(String[] args) {\n' +
                                '\n' +
                                '       Consumer sf = (t) -> System.out.println("Ai scris:"+ t);\n' +
                                '       sf.accept("test");\n' +
                                '\n' +
                                '   }\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>

                        <li>Supplier:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public interface Supplier<T> {\n' +
                                '\n' +
                                '    T get();\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>


                        <li>Comparator:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public interface Comparator<T> {\n' +
                                '\n' +
                                '    int compare(T t1, T t2);\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    Metode:
                    <ul>
                        <li><b>removeIf(Predicate p)</b>: pe List si Set (nu Map)</li>
                        <li><b>sort(Comprator p)</b>: pe List (nu Set, nu Map)</li>
                        <li><b>forEach(Consumer p)</b>: pe List, Set, Map</li>
                    </ul>
                    Reguli de accessare a variabilelor din interiorul corpulului unei functii lambda:
                    <ul>
                        <li>variabila de instanta: da</li>
                        <li>variabila statica: da</li>
                        <li>varibila locala: doar daca e <b>efectiv finala</b> (o variabila este efectiv finala, pana la prima atribuire sau daca marcata ca fiind <b>final</b>; <b>obiectele sau valorile primitive sunt efectiv finale dacă nu le modificăm valorile după inițializare</b>)</li>
                        <li>parametru de metoda: doar daca e <b>efectiv finala</b></li>
                        <li>parametru lambda: da</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallLambdasJavaContent;