import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class GrepLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-grep", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Filtrare rezultatul unei comezi dupa o expresie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls | grep <expresie-filtru>'}
                    </SyntaxHighlighter>

                    Exemplu 1: se filtreaza fisierele care contin in nume <b>a.</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls | grep a\\.'}
                    </SyntaxHighlighter>

                    Exemplu 2: afisare doar linii care contin un anumit text:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cat test.txt | grep 1'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'grep 1 test.txt'}
                    </SyntaxHighlighter>

                    Exemplu 3: afisare doar linii care incep cu -:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l | grep ^-'}
                    </SyntaxHighlighter>

                    Exemplu 4: afisare doar linii care se termina cu <i>txt</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l | grep txt$'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GrepLinuxContent;