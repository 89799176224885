import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class RelationalAndLogicOperatorsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-relational-and-logic-operators", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>  10. Python: Operatori relationali si logici</b>

                    <br/>
                    <br/>
                    Operatori relationali:
                    <ul>
                        <li>== (egalitate)</li>
                        <li>!= (diferit)</li>
                        <li>{">"} (mai mare)</li>
                        <li>{">="} (mai mare sau egal)</li>
                        <li> &lt; (mai mic)</li>
                        <li> &lt;= (mai mic sau egal)</li>
                    </ul>
                    Operatorii relationali sunt evaluati de la stanga la dreapta (<b>left-sided binding</b>).

                    <hr/>
                    Operatori logici:
                    <ul>
                        <li><b>and</b> (conjunctie): operator binar cu o prioritate mai mică decât cea exprimată de operatorii de comparare (relationali)
                            <br/>
                            de exemplu, daca avem expresia: a==1 and b==2, <b>and</b> are prioritate mai mica decat operatorii relationali, in sensul ca se evalueaza a==1, apoi b==2, si abia apoi se efectueaza <b>and</b>
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>A</th>
                                    <th>B</th>
                                    <th>A and B</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>True</td>
                                        <td>True</td>
                                        <td>True</td>
                                    </tr>
                                    <tr>
                                        <td>True</td>
                                        <td>False</td>
                                        <td>False</td>
                                    </tr>
                                    <tr>
                                        <td>False</td>
                                        <td>True</td>
                                        <td>False</td>
                                    </tr>

                                    <tr>
                                        <td>False</td>
                                        <td>False</td>
                                        <td>False</td>
                                    </tr>

                                </tbody>
                            </table>

                            Exemplu 1:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=4\n' +
                                'b=10\n' +
                                'c=a and b\n' +
                                'print(c)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'10'}
                            </SyntaxHighlighter>
                            <hr/>
                            Exemplu 2:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=4\n' +
                                'b=10\n' +
                                'c=b and a\n' +
                                'print(c)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'4'}
                            </SyntaxHighlighter>
                            <hr/>
                            Exemplu 3:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=0\n' +
                                'b=10\n' +
                                'c=a and b\n' +
                                'print(c)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'0'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>or</b> (disjunctie): operator binar cu o prioritate mai mică decât <b>and</b>, adica se evalueza <b>and</b> mai intai
                            de exemplu: a==1 and b==2 or c==3 este echivalent cu (a==1 and b==2) or c==3
                            (<b>prioritate mai mica, adica se evalueaza mai tarziu</b>)
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>A</th>
                                    <th>B</th>
                                    <th>A or B</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>True</td>
                                    <td>True</td>
                                    <td>True</td>
                                </tr>
                                <tr>
                                    <td>True</td>
                                    <td>False</td>
                                    <td>True</td>
                                </tr>
                                <tr>
                                    <td>False</td>
                                    <td>True</td>
                                    <td>True</td>
                                </tr>

                                <tr>
                                    <td>False</td>
                                    <td>False</td>
                                    <td>False</td>
                                </tr>

                                </tbody>
                            </table>

                            Exemplu 1:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=4\n' +
                                'b=10\n' +
                                'c=a or b\n' +
                                'print(c)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'4'}
                            </SyntaxHighlighter>
                            <hr/>
                            Exemplu 2:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=4\n' +
                                'b=10\n' +
                                'c=b or a\n' +
                                'print(c)'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'10'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>not</b> (negatie): prioritatea este foarte mare: la fel ca + și - (unare)
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>A</th>
                                    <th>not A</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>True</td>
                                    <td>False</td>
                                </tr>
                                <tr>
                                    <td>False</td>
                                    <td>True</td>
                                </tr>

                                </tbody>
                            </table>

                            Exemplu 1:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=0\n' +
                                'print(not(a))'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'True'}
                            </SyntaxHighlighter>
                            <hr/>

                            Exemplu 2:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=1\n' +
                                'print(not(a))'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'False'}
                            </SyntaxHighlighter>

                            Exemplu 3:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'a=12\n' +
                                'print(not(a))'}
                            </SyntaxHighlighter>
                            Rezultat:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'False'}
                            </SyntaxHighlighter>
                            <hr/>
                        </li>

                    </ul>

                    <hr/>
                    <b>Legile De Morgan</b>:
                    <ul>
                        <li>
                            not (p and q) == (not p) or (not q)
                        </li>
                        <li>
                            not (p or q) == (not p) and (not q)
                        </li>
                    </ul>
                    <hr/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'print(2==2)\n' +
                        'print(2==2.)\n' +
                        'print(2!=3)\n'}
                    </SyntaxHighlighter>

                    Rezultatul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'True\nTrue\nTrue'}
                    </SyntaxHighlighter>

                    <hr/>
                    Actualizare tabel de prioritati:
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                prioritate
                            </th>
                            <th>
                                operatori
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>
                                1
                            </th>
                            <th>
                                +,- (operatori binari)
                            </th>
                        </tr>
                        <tr>
                            <th>
                                2
                            </th>
                            <th>
                                **
                            </th>
                        </tr>
                        <tr>
                            <th>
                                3
                            </th>
                            <th>
                                *,/,//,%
                            </th>
                        </tr>
                        <tr>
                            <th>
                                4
                            </th>
                            <th>
                                +,- (operatori unari)
                            </th>
                        </tr>

                        <tr>
                            <th>
                               5
                            </th>
                            <th>
                                &lt;, &lt;=, {">"}, {">="}
                            </th>
                        </tr>

                        <tr>
                            <th>
                                6
                            </th>
                            <th>
                                ==, !=
                            </th>
                        </tr>

                        </tbody>
                    </table>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RelationalAndLogicOperatorsPythonContent;