import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class ExternalizeApplicationPropertiesTomcatSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-externalize-application-properties-tomcat", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Externalizare fisier application.properties in Tomcat</b>

                    <br/>
                    <br/>
                    Se editeaza <b>setenv.sh</b>

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'export spring_config_location=/<PATH_TO_CONF_DIR>/application.properties'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'export spring_config_location=/app/conf/application.properties'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExternalizeApplicationPropertiesTomcatSpringBootContent;