import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../design-patterns/IndexContent";

class SimpleFactoryContent extends BaseContentPage {

    constructor(props) {
        super(props, "simple-factory", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Simple Factory Pattern</b>: crează obiecte pe baza unei interfete comune, fără a expune clientului cum se face acest lucru.
                    <br/>
                    Când folosim acest pattern, ar trebui să avem în minte următoarele 3 lucruri:
                    <ol>
                        <li>
                            Acest pattern este apelat în mod direct de clasa care vrea să creeze un obiect (clasa care apelază se numeşte <b>client</b>, în sensul că cere/vrea ceva).
                            <br/>
                            Deci, Simple Factory este <i>apelat în mod direct de catre client</i>.
                            În exemplu de mai jos, acest lucru este identificat prin următoarele linii de cod (clientu-ul apelează metoda <b>create(...)</b>):
                            <br/>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'CarFactory carFactory = new CarFactory();\n' +
                                'Car daciaCar = carFactory.create("Dacia");\n' +
                                'Car volvoCar = carFactory.create("Volvo");'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            Simple Factory crează obiectul; singurul lucrul de notat este ca toate obiectele ce pot fi create au aceeaşi clasă părinte sau implementează aceaşi interfaţă:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public interface Car {\n' +
                                '}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public class DaciaCar implements Car{\n' +
                                '}'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public class VolvoCar implements Car{\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            Simple Factory returnează obiectul; întrucât clientul nu ştie ce obiect se va returna, clientul totuşi trebuie să se aştepte la un tip care se potriveste cu clasa parinte sau interfaţa declarată ca tip returnat de metoda din simple factory; în exemplul de mai jos, CarFactory va creea doar obiecte de tip Car:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public class CarFactory {\n' +
                                '\n' +
                                '    public Car create(String name){\n' +
                                '        if("Dacia".equals(name)){\n' +
                                '            return new DaciaCar(); // Dacia implementeaza Car\n' +
                                '        } else if ("Volvo".equals(name)){\n' +
                                '            return new VolvoCar(); // Volvo implementeaza Car\n' +
                                '        }\n' +
                                '        return null;\n' +
                                '    }\n' +
                                '\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ol>

                    Simplificat cele 3 lucruri de mai sus se pot exprima astfel:
                    <ol>
                        <li>Clientul apelează în mod direct Simple Factory : Client → SimpleFactory</li>
                        <li>Simple factory crează obiectul; toate obiectele create au aceeşi clasă de bază sau implementează aceeaşi interfaţă</li>
                        <li>Simple factory returneaza obiectul : SimpleFactory → Client</li>
                    </ol>

                    <br/>
                    <i>Observaţie</i>: Simple Factory nu este un design pattern oficial.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SimpleFactoryContent;