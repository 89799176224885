import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Mock2DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-mock2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Which process’s output will be available on terminal as the standard output of the docker attach command?</b>
                    <ul>
                        <li>
                            ENTRYPOINT/CMD
                        </li>
                    </ul>

                    <hr/>
                    <b>2. A worker node of your swarm cluster is overloaded due to multiple scheduled services. What can you do to prevent any further services from getting scheduled on it?</b>
                    <ul>
                        <li>
                            docker node update --availability pause [worker-name]
                        </li>
                    </ul>

                    <hr/>
                    <b>3. How worker machines are identified as in Kubernetes?
                    </b>
                    <ul>
                        <li>
                            Node
                        </li>
                    </ul>

                    <hr/>
                    <b>4. Which Docker command is not associated with Docker Volumes?
                    </b>
                    <ul>
                        <li>
                            docker volume delete
                        </li>
                        <li>
                            docker volume backup
                        </li>
                    </ul>

                    <hr/>
                    <b>5. You want to have a containerized PHP-MySQL multi-tier application deployed via Kubernetes. Which of the following choices of services is a standard practice that can fulfil the application requirements without the threat of leaking unnecessary data?</b>
                    <ul>
                        <li>
                            NodePort PHP, ClusterIP MySQL
                        </li>
                    </ul>

                    <hr/>
                    <b>6. You want to run an isolated alpine Linux container which is not connected to any default or user-defined Docker Network. Which command will you use?</b>
                    <ul>
                        <li>
                            docker run --rm -itd --network none --name alpine-net alpine:latest
                        </li>
                    </ul>

                    <hr/>
                    <b>7. Which Docker network is responsible for communication between DTR components running on different nodes?</b>
                    <ul>
                        <li>
                            dtr-ol
                        </li>
                    </ul>

                    <hr/>
                    <b>8. You have a running busybox container in development environment which is connected to the default bridge network. For testing purposes, testing team has connected the busybox container to a user-defined bridge network called "test-bridge". Can you tell that how many endpoints will be attached to the busybox container?</b>
                    <ul>
                        <li>
                            2
                        </li>
                    </ul>

                    <hr/>
                    <b>9. Which Linux Kernel features does Docker use to create and isolate containers ?</b>
                    <ul>
                        <li>
                            cgroups
                        </li>
                        <li>
                            Namespace
                        </li>
                        <li>
                            UnionFS
                        </li>
                    </ul>

                    <hr/>
                    <b>10. Which kubectl command will you use to find information such as what node and IP address a pod is on?</b>
                    <ul>
                        <li>
                            kubectl describe pod [pod-name]
                        </li>
                    </ul>

                    <hr/>
                    <b>11. Which software architecture does Kubernetes itself fall into?</b>
                    <ul>
                        <li>
                            Microservice
                        </li>
                    </ul>

                    <hr/>
                    <b>12. What is the role of <b>Dispatcher</b> in Swarm?
                    </b>
                    <ul>
                        <li>
                            To assign tasks on swarm nodes.
                        </li>
                    </ul>

                    <hr/>
                    <b>13. Which command will you used to stream real-time events of Docker Daemon?
                    </b>
                    <ul>
                        <li>
                            docker system top
                        </li>
                    </ul>

                    <hr/>
                    <b>14. What is the default extension of Dockerfile</b>
                    <ul>
                        <li>
                            None
                        </li>
                    </ul>


                    <hr/>
                    <b>15. What is the role of Allocator in Swarm?
                    </b>
                    <ul>
                        <li>
                            To allocate IP addresses to tasks.
                        </li>
                    </ul>

                    <hr/>
                    <b>16. You have specified multiple WORKDIR instructions in the Dockerfile what is the result WORKDIR?</b>
                    <ul>
                        <li>
                            /y/z
                        </li>
                    </ul>

                    <hr/>
                    <b>17. Which of the following should be used to store cached data in Docker?
                    </b>
                    <ul>
                        <li>
                            tmpfs
                        </li>
                    </ul>
                    <hr/>
                    <b>18. Which Linux kernel feature does Docker use to limit the  resources used by Docker containers?</b>
                    <ul>
                        <li>
                            cgroups
                        </li>
                    </ul>

                    <hr/>
                    <b>19. Which of the following Dockerfile instructions will configure a starting point for the executable container?</b>
                    <ul>
                        <li>
                            ENTRYPOINT
                        </li>
                    </ul>

                    <hr/>
                    <b>20. On Linux distributions, from whom does containers inherit the default DNS settings?</b>
                    <ul>
                        <li>
                            Docker Host
                        </li>
                    </ul>

                    <hr/>
                    <b>21. Following is a YAML configuration of a standard Kubernetes pod. Choose the correct response to fill between the square brackets of command sub-field under containers.</b>
                    <ul>
                        <li>
                            'sh', '-c', 'echo "Hello" && sleep 3600'
                        </li>
                    </ul>

                    <hr/>
                    <b>22. Which Kubernetes component exposes Kubernetes API to all instances in the cluster and is the "front-end" for the Kubernetes Control Plane?</b>
                    <ul>
                        <li>
                            kube-apiserver
                        </li>
                    </ul>

                    <hr/>
                    <b>23. What is the default container format for Docker Container?
                    </b>
                    <ul>
                        <li>
                            libcontainer
                        </li>
                    </ul>

                    <hr/>
                    <b>24. Which Kubernetes process allows network communication to Pod from network sessions inside or outside of the cluster?</b>
                    <ul>
                        <li>
                            kube-proxy
                        </li>
                    </ul>

                    <hr/>
                    <b>25. How do we list out Docker images which are stored on a Docker host?</b>
                    <ul>
                        <li>
                            docker images
                        </li>
                        <li>
                            docker image ls
                        </li>
                    </ul>


                    <hr/>
                    <b>26.
                        Arrange the following events of pod startup in the appropriate order.
                    </b>
                    <ul>
                        <li>
                            Pod Scheduled {'->'} ClusterIP network endpoint enabled {"->"} Volume initialized {"->"} Init Container Started {"->"} Other Containers Started
                        </li>
                    </ul>

                    <hr/>
                    <b>27. Which command is used to show the disk usage of docker on Docker host?
                    </b>
                    <ul>
                        <li>
                            docker system df
                        </li>
                    </ul>

                    <hr/>
                    <b>28. What is the default network driver for Docker containers?</b>
                    <ul>
                        <li>
                            bridge
                        </li>
                    </ul>

                    <hr/>
                    <b>29. Which of the following is the smallest deployable unit in Kubernetes?</b>
                    <ul>
                        <li>
                            Pod
                        </li>
                    </ul>

                    <hr/>
                    <b>30.
                        Which of the following commands is used to create a MySQL version 5.4 database container?</b>
                    <ul>
                        <li>
                            docker create --name database mysql:5.4
                        </li>
                    </ul>

                    <hr/>
                    <b>31. What is the scope of unnamed volumes in Docker?
                    </b>
                    <ul>
                        <li>
                            Docker Host
                        </li>
                    </ul>

                    <hr/>
                    <b>32. Which of the following statements are correct for docker image?
                    </b>
                    <ul>
                        <li>
                            All the layers of docker image are read-only + All the layers of the docker image are delta of the previous layer
                        </li>
                    </ul>

                    <hr/>
                    <b>33. You want to create a docker container which has ubuntu as its base image. You want this container to perform 3 different tasks representing 3 different CMD instructions: CMD-1, CMD-2, and CMD-3, respectively. You write all these instructions sequentially in a Dockerfile and build the Docker image. Now, Which CMD instruction will be executed when the ubuntu container starts running?</b>
                    <ul>
                        <li>
                            CMD-3
                        </li>
                    </ul>

                    <hr/>
                    <b>34. Which kubectl command will you use to list all pods in the ceruleancanvas namespace on master node?</b>
                    <ul>
                        <li>
                            kubectl get pods --namespace=ceruleancanvas
                        </li>
                    </ul>

                    <hr/>
                    <b>35. You have containerized a webserver and you want to access it using the same IP as your host. Which native Docker Network driver will you use?</b>
                    <ul>
                        <li>
                            host
                        </li>
                    </ul>

                    <hr/>
                    <b>36. What will be the output of the following docker exec command? docker exec -it container-utuntu "sudo apt-get-update"</b>
                    <ul>
                        <li>
                            The command will return an error of unsupported chained or quoted commands.
                        </li>
                    </ul>

                    <hr/>
                    <b>37. What is the purpose of a Storage Driver?
                    </b>
                    <ul>
                        <li>
                            To handle the interaction between Docker Image layers.
                        </li>
                        <li>
                            To write data in container's writable layer
                        </li>
                    </ul>

                    <hr/>
                    <b>38. Where do manager nodes store the current swarm state and manager logs on Docker host? </b>
                    <ul>
                        <li>
                            /var/lib/docker/swarm
                        </li>
                    </ul>

                    <hr/>
                    <b>39. You have been asked to migrate your company's event blog in a containerized environment. As per the given requirement, you need to create a 20-node swarm cluster which will be running a swarm service serving the front-end of blog using WordPress and backend using MySQL. You also need to make sure that at least 1 MySQL task must be running on each node of the cluster. Which of the docker swarm commands will you use to fulfil the given task?</b>
                    <ul>
                        <li>
                            docker service create --name mySQL --mode global mysql, docker service create --name wordpress --replicas=20 wordpress
                        </li>
                    </ul>

                    <hr/>
                    <b>40. Which of the following Docker features allows users to implement Roll Based Access Control?</b>
                    <ul>
                        <li>
                            Universal Control Plane / Mirantis Kubernetes Engine
                        </li>
                    </ul>

                    <hr/>
                    <b>41. Which command will you use to create a new Docker Image to persist filesystem changes of a container? </b>
                    <ul>
                        <li>
                            docker commit
                        </li>
                    </ul>

                    <hr/>
                    <b>42. Which of the following Dockerfile instruction informs Docker about the port on which container is listening?</b>
                    <ul>
                        <li>
                            EXPOSE
                        </li>
                    </ul>

                    <hr/>
                    <b>43. Which K8s component is used to run and manage node controller, replication controller, endpoints controller, and service account and token controller processes? </b>
                    <ul>
                        <li>
                            kube-controller-manager
                        </li>
                    </ul>

                    <hr/>
                    <b>44. What are the node types in Docker's Swarm Mode?</b>
                    <ul>
                        <li>
                            manager
                        </li>
                        <li>
                            worker
                        </li>
                    </ul>

                    <hr/>
                    <b>45. Which command will you use to promote a worker node to manager node?
                    </b>
                    <ul>
                        <li>
                            docker node promote
                        </li>
                    </ul>

                    <hr/>
                    <b>46. A Grant is made up of
                    </b>
                    <ul>
                        <li>
                            Subject
                        </li>
                        <li>
                            Role
                        </li>
                        <li>
                            Resource Set
                        </li>
                    </ul>

                    <hr/>
                    <b>47. What is the scope of overlay network in Docker?
                    </b>
                    <ul>
                        <li>
                            swarm
                        </li>
                    </ul>

                    <hr/>
                    <b>48. What is the role of Executor in Swarm?

                    </b>
                    <ul>
                        <li>
                            To execute tasks assigned to worker nodes.
                        </li>
                    </ul>

                    <hr/>
                    <b>49. Which of the following is used to dynamically provision Persistent Volumes in Kubernetes cluster?

                    </b>
                    <ul>
                        <li>
                            Storage Class
                        </li>
                    </ul>

                    <hr/>
                    <b>49. Which command will you use to join a swarm cluster as a worker node?

                    </b>
                    <ul>
                        <li>
                            docker swarm join --token [worker-join-token]
                        </li>
                    </ul>

                    <hr/>
                    <b>50. How do you check the information about the current storage driver of Docker?

                    </b>
                    <ul>
                        <li>
                            docker system info
                        </li>
                    </ul>

                    <hr/>
                    <b>51. Which of the following is the most used pod implementation in Kubernetes?

                    </b>
                    <ul>
                        <li>
                            One container per Pod
                        </li>
                    </ul>

                    <hr/>
                    <b>52. You are asked to print the list of all containers (running and stopped) in a tabular format having three columns for Container ID, Container name, and their status, respectively. Which combination of a Docker command and its flags will you use?

                    </b>
                    <ul>
                        <li>
                            <SyntaxHighlighter>
                                {'docker ps --format "table {{.ID}}\\t{{.Names}}\\t{{.Status}}"'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <hr/>
                    <b>53. Which networks are created when you initialize a swarm cluster or join a Docker host to an existing swarm ?
                    </b>
                    <ul>
                        <li>
                            ingress
                        </li>
                    </ul>

                    <hr/>
                    <b>54. What is the purpose of Job in Kubernetes?

                    </b>
                    <ul>
                        <li>
                            To create a certain number of pods to successfully complete a job.
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a target={"_blank"} href={"https://medium.com/bb-tutorials-and-thoughts/250-practice-questions-for-the-dca-exam-84f3b9e8f5ce"}>
                                    250 Practice Questions for the DCA Exam
                                </a>
                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Mock2DockerContent;