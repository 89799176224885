import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class ModuleAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-module", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere in module</b>
                    <br/>
                    <br/>
                    Angular, nu cauta, in mod automat, dupa componente, directive, servicii, pipe-uri, etc in fisierele proiectului.
                    <br/>
                    Din acest motiv, ele trebuie declarate explicit.
                    <br/>
                    Fiecare proiect trebuie sa aiba cel putin un modul (<i>app-module</i>):
                    <ul>
                        <li>Angular analizeaza NgModules pentru a intelege aplicatia</li>
                        <li>Modulele Angular defineste toate tipurile de <i>building blocks</i> folosite: componente, directive, servicii, etc</li>
                        <li>o aplicatie are nevoie de cel putin un modul (AppModule), dar poate fi split-uri in mai multe module</li>
                        <li>un feature (ex: FormsModule) este incarcat doar daca este nevoie de el</li>
                    </ul>

                    Un modul este adnotat cu <b>@NgModule</b>, iar o clasa modul se termina in <b>Module</b> (conventie).
                    <br/>
                    Prin intermediul aceste adnotari:
                    <ul>
                        <li>
                            in sectiunea <b>declarations:[...]</b>:
                            <br/>
                            declaram toate <i>componentele, directivele, pipe-uri</i> folosite in aplicatie
                        </li>
                        <li>
                            in sectiunea <b>imports:[...]</b>:
                            <br/>
                            se importa toate celelalte <i>module</i> folosite in aplicatie;
                            <br/>
                            este util pentru split-urilea/spargerea in mai multe module a aplicatiei
                            <br/>
                            exemple de alte module: BrowserModule, FormsModule, ReactiveFormsModule, HttpClientModule, AppRoutingModule, etc
                        </li>
                        <li>
                            in sectiunea <b>providers:[...]</b>:
                            <br/>
                            declaram toate <i>serviciile</i> folosite in aplicatie
                        </li>
                        <li>
                            in sectiunea <b>boostrap:[...]</b>:
                            <br/>
                            se declara componenta care trebuie sa fie disponibila in <i>index.html</i>
                        </li>
                        <li>
                            in sectiunea <b>entryComponents:[...]</b>:
                            <br/>
                            se declara componentele care se creaza in mod dinamic, programatic
                        </li>

                        <li>
                            in sectiunea <b>exports:[...]</b>:
                            <br/>
                            se declara <i>componentele, module</i> care se exporta
                        </li>
                    </ul>
                    Module nu comunica unele cu altele!
                    <br/>
                    Deci, daca avem un modul de tip feature (modul care nu e AppModule) trebuie:

                    <ul>
                        <li>
                            sa importe toate module folosite:
                            <ul>
                                <li>RouterModule (daca il foloseste)</li>
                                <li>RectiveFormsModule (daca il foloseste)</li>
                                <li>CommonModule ( pentru ca nu se poate importa <b>BrowserModule</b>): si doar daca nu e importat indirect dintr-un module de tip share</li>
                            </ul>
                        </li>
                        <li>serviciile care sunt in AppModule nu trebuie importate din nou (decat daca se supracriu)</li>
                        <li>toate componentele/directivele/pipe-urile declareate se vor cam regasi si in sectiunea de <b>exports</b> (daca e nevoie)</li>
                    </ul>

                    In modulul de tip feature se poate muta si caile de routing.
                    <br/>
                    De exemplu, putem declara rutele si exporta in <i>HOME_ROUTE</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import { Route } from \'@angular/router\';\n' +
                            '\n' +
                            'import { HomeComponent } from \'./home.component\';\n' +
                            '\n' +
                            'export const HOME_ROUTE: Route = {\n' +
                            '  path: \'\',\n' +
                            '  component: HomeComponent,\n' +
                            '  data: {\n' +
                            '    pageTitle: \'home.title\',\n' +
                            '  },\n' +
                            '};\n'}
                    </SyntaxHighlighter>
                    Apoi in sectiunea <b>imports</b>, adaugam: <b>RouterModule.forChild([HOME_ROUTE])</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@NgModule({\n' +
                            '  imports: [SharedModule, RouterModule.forChild([HOME_ROUTE])],\n' +
                            '  declarations: [HomeComponent],\n' +
                            '})\n' +
                            'export class HomeModule {}'}
                    </SyntaxHighlighter>
                    De retinut, ca folosim <b>RouterModule.forChild()</b> si nu ca routing-ul pentru <i>app module</i> <b>RouterModule.forRoot</b>.


                    <hr/>
                    <b>2. Creare modul</b>
                    <br/>
                    <br/>

                    Un modul poate generat cu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng generate module User'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ng generate module shared'}
                    </SyntaxHighlighter>

                    Daca intr-un modul se creeaza o componenta si se vrea sa fie folosita in alt modul,
                    atunci trebuie declarata aceasta componenta, in mod explicit.
                    <br/>
                    Acest lucru in sectiunea <b>exports</b> a modului de care apartine componenta.

                    <hr/>
                    <b>3. Incarcare lazy a modulului</b>
                    <br/>
                    <br/>

                    In primul rand trebuie sterse referintele din <i>app module</i> daca exista si apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{\n' +
                            '  path:\'\',\n' +
                            '  loadChildren: (async ()=> await import("./mymodule/mymodule.module")).MyModule\n' +
                            '}'}
                    </SyntaxHighlighter>


                    Incarcare lazy a rutelor:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { RouterModule } from \'@angular/router\';\n' +
                            '\n' +
                            '@NgModule({\n' +
                            '  imports: [\n' +
                            '  ...\n' +
                            '  RouterModule.forRoot([\n' +
                            '    { path: \'\', redirectTo: \'login\', pathMatch: \'full\' },\n' +
                            '    { path: \'login\', loadChildren: () => import(\'./login/login.module\').then(m => m.LoginModule) },\n' +
                            '    { path: \'detail\', loadChildren: () => import(\'./detail/detail.module\').then(m => m.DetailModule) }\n' +
                            '  ])\n' +
                            '  ],\n' +
                            '})'}
                    </SyntaxHighlighter>
                    Observatii:
                    <ul>
                        <li>rutele incarcate layz sunt relative la parinte, adica vor contine si calea parintelui</li>
                        <li>un modul incercat lazy nu trebuie declarat in al modul, pentru ca se obtine eroare</li>
                        <li>in versiunile mai vechi se permitea sa se scrie: loadChildren: "./detail/detail.module#DetailModule" </li>
                    </ul>

                    <hr/>
                    <b>3. Preincarcare lazy a modulului</b>
                    <br/>
                    <br/>

                    Preincarcare se face cu optiunea <b>preloadingStrategy</b> care poate lua valorile:
                    <ul>
                        <li>
                            <b>PreloadAllModules</b>
                        </li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@NgModule({\n' +
                            '  imports: [SharedModule, RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})],\n' +
                            '  declarations: [HomeComponent],\n' +
                            '})\n' +
                            'export class HomeModule {}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Module si servicii</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>AppModule: serviciile sunt fi accesate/accesibile/disponibile in intreaga aplicatie (se foloseste root injector)
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'@Injectable({\n' +
                                    '  providedIn: \'root\'\n' +
                                    '})\n' +
                                    'class LoginService {}'}
                            </SyntaxHighlighter>
                            Intreaba aplicatie foloseste acceasi instanta a serviciului
                            (asta daca nu e adaugat printre provideri unui modul-lazy si
                            in acest caz vom avea 2 instante: unul pentru componentele eager si unul pentru componenta lazy
                            ; <b>trebuie folosit cu grija acest comportament, pentru a evita confuzii si comportamente nedorite</b>;
                            <br/> serviciile in lazy-module declarate se recreaza, creaza alta instanta fata cea din componentele eager).

                            <br/>
                            In mod normal, servciile se declara in AppModule, si doar cu un motiv extrem de intemeiat in alta parte!


                            <br/>
                            Acelasi efect e daca stergem adnotaera <b>@Injectable</b> si adaugam serviciul in <b>@NgModule</b>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                             {'@NgModule({\n' +
                                 '  declarations: [LoginServiceComponent]\n' +
                                 '  providers: [LoginService]\n' +
                                 '})\n' +
                                 'class LoginServiceModule {}'}
                            </SyntaxHighlighter>

                        </li>
                        <li>AppComponent (sau alte componente): serviciile sunt fi accesate/accesibile/disponibile in arborele componentei ((se foloseste component-specific injector))</li>
                        <li>Eager-loaded Module: serviciile sunt fi accesate/accesibile/disponibile in intreaga aplicatie (se foloseste root injector)</li>
                        <li>Lazy-loaded Module: serviciile sunt fi accesate/accesibile/disponibile in module incercate (se foloseste child injector)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a  target={"_blank"} href={"https://blog.bitsrc.io/dynamic-components-in-angular-9ddc346e2742"}>
                               Dynamic Components in Angular 16
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ModuleAngularContent;