import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerIgnoreContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dockerignore", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Fisierul .dockerignore</b>

                    <br/>
                    <br/>

                    Daca vrem ca la copiere sa se ignore anumite fisiere/directoare, o putem face folosind creand un fisier cu numele <b>.dockerignore</b>,
                    in care sa specificam ce fisiere si directorare trebuie ignorate in procesul de copiere

                    Exemplu de fisier <b>.dockerignore</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'package-lock.json\n' +
                            'node_modules\n' +
                            'yarn.lock'}
                    </SyntaxHighlighter>

                    Pentru a afisa in comanda <b>ls</b> si fisierele ascunse se foloseste flag-ul <b>-a</b> (ls -a).

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerIgnoreContent;