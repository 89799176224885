import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class GitServerNasContent extends BaseContentPage {

    constructor(props) {
        super(props, "nas-git-server", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Git Server</b>
                    <br/>
                    <br/>

                    Pentru a creea un repository Git:
                    <ul>
                        <li>logare in DSM cu un count care are drepturi de administrator</li>
                        <li><b>Control Panel {">"} Terminal & SNMP {">"} Terminal</b> {"->"} activare <b>SSH service</b></li>
                        <li><b>Control Panel {">"} Shared Folder </b> {"->"} creare folder share-uit pentru repositori-urile Git (de exemplu: <i>git</i>)</li>
                        <li>de pe PC conectare SSH la Synology NAS (fie putty / cmd):

                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'ssh admin@192.168.1.255 -p 22'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd /volume1/git'}
                            </SyntaxHighlighter>
                        </li>

                        <li>

                            În mod convențional, depozitele inițializate cu <b>--bare</b> se termină în <b>.git</b>.
                            <br/>
                            De exemplu, versiunea <b>bare</b> a unui repository numit <i>my-project</i> ar trebui să fie stocată într-un repository numit <i>my-project.git</i>.

                            <br/>

                            Indicatorul <b>--bare</b> creează un depozit care nu are un director de lucru,
                            ceea ce face imposibilă editarea fișierelor și apoi sa se commita modificările în acest depozit.
                            <br/>
                            Se poate face:<b>git push</b> sau <b>git pull</b>, dar nu si <b>commit</b> in mod direct.
                            <br/>
                            <i>Repositoriurle centrale</i> ar trebuie sa fie intotdeauna create ca <b>repository</b> de tip <b>bare</b>,
                            deoarece împingerea ramurilor într-un depozit/repository non-bare are potențialul de a suprascrie modificările.

                            Gândiți-vă --barela o modalitate de a marca un depozit ca o facilitate de stocare, spre deosebire de un mediu de dezvoltare.
                            <br/>

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'git init --bare test-project.git'}
                            </SyntaxHighlighter>

                            Se va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'/volume1/git$ git init --bare test-project.git\n' +
                                    'hint: Using \'master\' as the name for the initial branch. This default branch name\n' +
                                    'hint: is subject to change. To configure the initial branch name to use in all\n' +
                                    'hint: of your new repositories, which will suppress this warning, call:\n' +
                                    'hint:\n' +
                                    'hint:   git config --global init.defaultBranch <name>\n' +
                                    'hint:\n' +
                                    'hint: Names commonly chosen instead of \'master\' are \'main\', \'trunk\' and\n' +
                                    'hint: \'development\'. The just-created branch can be renamed via this command:\n' +
                                    'hint:\n' +
                                    'hint:   git branch -m <name>\n' +
                                    'Initialized empty Git repository in /volume1/git/test-project.git/'}
                            </SyntaxHighlighter>


                        </li>
                    </ul>

                    <hr/>
                    <b>2. Git Client</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git clone ssh://admin@192.168.1.255:/volume1/git/test-project.git'}
                    </SyntaxHighlighter>

                    se va afisa:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'Cloning into \'test-project\'...\n' +
                            'admin@192.168.1.255\'s password:\n' +
                            'warning: You appear to have cloned an empty repository.'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd test-project'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'echo "Citeste" > readme.txt'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'>git status\n' +
                            'On branch master\n' +
                            '\n' +
                            'No commits yet\n' +
                            '\n' +
                            'Untracked files:\n' +
                            '  (use "git add <file>..." to include in what will be committed)\n' +
                            '        readme.txt\n' +
                            '\n' +
                            'nothing added to commit but untracked files present (use "git add" to track)'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git add readme.txt'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git status\n' +
                            'On branch master\n' +
                            '\n' +
                            'No commits yet\n' +
                            '\n' +
                            'Changes to be committed:\n' +
                            '  (use "git rm --cached <file>..." to unstage)\n' +
                            '        new file:   readme.txt'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git commit -m "Primul commit"\n' +
                            '[master (root-commit) e673e72] Primul commit\n' +
                            ' 1 file changed, 1 insertion(+)\n' +
                            ' create mode 100644 readme.txt'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git push\n' +
                            'adminkj@192.168.1.7\'s password:\n' +
                            'Enumerating objects: 3, done.\n' +
                            'Counting objects: 100% (3/3), done.\n' +
                            'Writing objects: 100% (3/3), 235 bytes | 235.00 KiB/s, done.\n' +
                            'Total 3 (delta 0), reused 0 (delta 0), pack-reused 0\n' +
                            'To ssh://192.168.1.7:/volume1/git/test-project.git\n' +
                            ' * [new branch]      master -> master'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afla daca esti <b>root</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'id -u'}
                    </SyntaxHighlighter>
                    Daca esti root va returna 0.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"}
                               href={"https://www.youtube.com/watch?v=Z7WXiYni4GA&ab_channel=AppliedProjectsEngineering"}>
                                Git Server Synology NAS Setup and use with Visual Studio
                            </a>

                        </li>

                        <li>

                            <a target={"_blank"} href={"https://kb.synology.com/en-sg/DSM/help/Git/git?version=7"}>Git Server</a>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://gr8t.medium.com/synology-diskstation-dsm-7-setting-up-git-and-ssh-for-password-less-use-4a85ac8c0fd2"}>
                                    Synology DiskStation (NAS) DSM 7: Setting Up Git and SSH For Password-less Use
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://gist.github.com/walkerjeffd/374750c366605cd5123d"}>
                                    Configure Synology NAS as Git Server
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GitServerNasContent;