import React from "react";

import IndexContent from "./IndexContent"
import BaseContentPage from "../BaseContentPage";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class PipPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-pip", IndexContent);
    }

    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>PIP</b>
                    <br/> <br/>
                    Un repository/depozit (<b>repo</b>) conceput pentru a colecta și partaja codul Python gratuit exista si functioneaza sub numele de <a href={"https://pypi.org/"}><b>PyPI</b></a> (<b>Python Package Index</b>), mentinut de <b>Packaging Working Group</b>, parte a <b>Python Software Foundation</b>.
                    PyPI este referit uneori prin <b>Cheese Shop</b>.
                    <br/>
                    Pentru a folosi acest repository avem nevoie de o aplicatie numita <b>pip</b> (pip installs packages). Aceasta aplicatie (<b>tool</b>) este o aplicatie de consola (deci, poate fi folosita in <b>cmd</b>).
                    Unele distributii de Python vin cu el, altele nu.

                    Pentru a verifica:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'pip -version'}
                    </SyntaxHighlighter>

                    sau

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'pip3 -version'}
                    </SyntaxHighlighter>

                    Daca, nu aveti noroc, sau daca aveti ghinion sau pur si simplu aveti o zi proasta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'\'pip\' is not recognized as an internal or external command,\n' +
                        'operable program or batch file.'}
                    </SyntaxHighlighter>

                    Atunci, trebuie, adaugat in ENV, Path:
                    <ul>
                        <li>[PYTHON_HOME]\Scripts\ (ex: C:\Program Files\Python3\Scripts\)</li>
                        <li>[PYTHON_HOME]\ (ex: C:\Program Files\Python3\)</li>
                    </ul>
                    Redeschideti un alt <b>cmd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'pip --version\n' +
                        'pip 20.3.1 from c:\\tools\\python\\python39\\lib\\site-packages\\pip (python 3.9)'}
                    </SyntaxHighlighter>
                    Si am spart ghinionul!

                    <hr/>
                    Comenzi pip:
                    <ul>
                        <li>pip help: afiseaza ce comenezi pot fi folosite</li>
                        <li>pip help [comanda]: afiseaza modul de folosire a unei comenzi</li>
                        <li>pip install [nume_pachet]/ pip --user [nume_user_OS] [nume_pachet]: instalare pachet (daca se omite --user, se presupune ca sunteti administrator)</li>
                        <li>pip install -U [nume_pachet] : actualizare pachet</li>
                        <li>pip install [nume_pachet]==[versiune_pachet]: instalare o anumita versiune a unui pachet (se foloseste: <b>==</b>) </li>
                        <li>pip uninstall [nume_pachet]: dezinstalare pachet</li>
                        <li>pip list: listeaza ce pachete sunt deja instalate</li>
                        <li>pip show [nume_pachet]:
                            <ul>
                                <li>ofera informatii despre un pachet <b>instalat</b></li>
                                <li>ofera informatii despre dependențele unui pachet instalat</li>
                            </ul>

                        </li>
                        <li>pip search [text_cautat]: cautare pachete care contin textul [text_cautat]</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PipPythonContent;