import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ServiceNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-service", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    De exemplu avem modulul <i>myproject</i>.
                    Se creeaza un fisier XML: <i>org.mycompany.myproject-service.xml</i>, cu urmatorul continut:

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<component name="org.mycompany.myproject.my-contract-service">\n' +
                        '\n' +
                        '</component>'}
                    </SyntaxHighlighter>

                    Apoi se creeaza o interfata:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'interface MyContractService{...}'}
                    </SyntaxHighlighter>

                    Apoi, se creeaza implementarea interfetei <i>MyService</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package org.mycompany.myproject.api;\n' +
                        '\n' +
                        'public class MyContractServiceImpl extends DefaultComponent implements MyContractService {\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Se completeaza fisierul XML:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<component name="org.mycompany.myproject.my-contract-service">\n' +
                        '\n' +
                        '  <documentation>\n' +
                        '    This component can be documented here, HTML tags are accepted.\n' +
                        '  </documentation>\n' +
                        '  <implementation class="org.mycompany.myproject.MyContractServiceImpl" />\n' +
                        '  <service>\n' +
                        '    <provide interface="org.mycompany.myproject.api.MyContractService" />\n' +
                        '  </service>\n' +
                        '\n' +
                        '</component>'}
                    </SyntaxHighlighter>

                    Pentru a ne asigura ca o anumita componenta porneste dupa alta componenta, se implementeaza metoda: <b>getApplicationStartedOrder()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class MyComponent extends DefaultComponent {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public int getApplicationStartedOrder() {\n' +
                        '        return 700;\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a obtine un serviciu se foloseste metoda: <b>Framework.getService()</b>
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'SecurityService securityService = Framework.getService(SecurityService.class)'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'MyContractService myConsteactService = Framework.getService(MyContractService.class)'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a suprascrie o componenta:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<component name="org.mycompany.myproject.my-big-contract-service">\n' +
                        '\n' +
                        '  <require>org.mycompany.myproject.my-contract-service</require>\n' +
                        '  <implementation class="org.mycompany.myproject.MyBigContractServiceImpl" />\n' +
                        '  <service>\n' +
                        '    <provide interface="org.mycompany.myproject.api.MyBigContractService" />\n' +
                        '  </service>\n' +
                        '\n' +
                        '</component>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a genera un serviciu:

                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'yo nuxeo service'}
                    </SyntaxHighlighter>

                    Cerinte pentru a functiona comanda de mai sus:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'npm install -g yo generator-nuxeo'}
                    </SyntaxHighlighter>

                    Rezultat rulare comanda <b>yo nuxeo service</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'yo nuxeo service\n' +
                        '\n' +
                        'dxxxxxxxxxxc    oxxo       lxxx lkkl       ;kkk\n' +
                        'dxxxxxxxxxxxd;  oxxo       lxxx lkkkx:.  ,dkkkx\n' +
                        'dxxc       lxxo oxxo       lxxx  "okkkkokkkkd,\n' +
                        'dxxc       lxxo oxxo       lxxx    .dkkkkkk.                  Welcome to\n' +
                        'dxxc       lxxo oxxo       lxxx   ,dkkkkkkkk,               Nuxeo Generator\n' +
                        'dxxc       lxxo "oxxcccccccdxxx ,kkkkx" "okkkk,\n' +
                        'loo;       :ooc   "cooooooooool xkko       ckko\n' +
                        '\n' +
                        ':cc,       ;cc;                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        '"cxxoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '   xoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '\n' +
                        'lkkl       ;kkk oxxxxxxxxxxxxxo xooooooooooo,\n' +
                        'lkkkx:.  ,dkkkx oxxxxxxxxxxxxxo lxxxxxxxxxxxxb;\n' +
                        ' "okkkkokkkkd,  oxxo            lxxd       :xxx\n' +
                        '   .dkkkkkk.    oxxxxxxxxxxxxxo lxxd       :xxx\n' +
                        '  ,dkkkkkkkk,   oxxo            cxxd       :xxx\n' +
                        ',kkkkx" "okkkk, oxxxxxxxxxxxxxo  "oxxxxxxxxxxxx\n' +
                        'xkko       ckko oxxxxxxxxxxxxxo    :xxxxxxxxxxx\n' +
                        '\n' +
                        '\n' +
                        '     info Installation of single-module is skipped.\n' +
                        '     info Your target Nuxeo version is: 10.10\n' +
                        '     info You will be prompted for generation of:\n' +
                        '     info   nuxeo-test-core: service\n' +
                        '\n' +
                        '   create Generate Module: nuxeo-test-core\n' +
                        '\n' +
                        '   create Generating Service\n' +
                        '     info   Parameters: Service package, Service interface name\n' +
                        '? Service package: org.mycompany.myproject\n' +
                        '? Service interface name: MyContractService\n' +
                        '   create Writing Service\n' +
                        '     info Maven dependency: org.nuxeo.runtime:nuxeo-runtime\n' +
                        '     info Maven dependency: org.nuxeo.runtime:nuxeo-runtime-test:::test\n' +
                        '     info Maven dependency: org.nuxeo.ecm.platform:nuxeo-platform-test:::test\n' +
                        '    force nuxeo-test-core\\pom.xml\n' +
                        '    force nuxeo-test-core\\src\\main\\resources\\META-INF\\MANIFEST.MF\n' +
                        '   create nuxeo-test-core\\src\\main\\java\\org\\mycompany\\myproject\\MyContractService.java\n' +
                        '   create nuxeo-test-core\\src\\main\\java\\org\\mycompany\\myproject\\MyContractServiceImpl.java\n' +
                        '   create nuxeo-test-core\\src\\test\\java\\org\\mycompany\\myproject\\TestMyContractService.java\n' +
                        '   create nuxeo-test-core\\src\\main\\resources\\OSGI-INF\\mycontractservice-service.xml\n' +
                        '   create Your project is ready!\n' +
                        '     info You can start editing code or you can continue with calling another generator (yo nuxeo [<generator>..])'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://doc.nuxeo.com/nxdoc/how-to-create-a-service/#declare-bundle-contribution"}>
                                HOWTO: Create a Service
                            </a>
                        </div>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServiceNuxeoContent;