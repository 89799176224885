import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ScriptingJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-scripting", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>
                        1. JEP 330: Java ca interpretor
                    </b>
                    <br/>
                    <br/>

                    Fie programul clasic <i>Hello, World!</i> in Java:
                    <br/>
                    In Windows, putem sa il cream din linie de comanda:
                    <SyntaxHighlighter>
                        {'wsl touch HelloWorld.java'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloWorld { \n' +
                            '    public static void main(String[] args) { \n' +
                            '        System.out.println("Hello, World!");\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In mod clasic, fisierul Java se compileaza:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javac HelloWorld.java'}
                    </SyntaxHighlighter>
                    Se va genera un fisier <i>HelloWorld.class</i>
                    <br/>
                    Si apoi se ruleaza:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java HelloWorld'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a rula/executa direct clasa HelloWorld, fara generare de fisier class (se compileaza in memorie):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java HelloWorld.java'}
                    </SyntaxHighlighter>

                    Limitari:
                    <ul>
                        <li>
                            este limitat la codul din  singurul fișier sursă
                        </li>
                    </ul>

                    <b>
                        2. JEP 330: Java ca limbaj de scripting
                    </b>
                    <br/>
                    <br/>

                    Cream un fisier hello (fara extensia <i>java</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'wsl touch hello'}
                    </SyntaxHighlighter>

                    La inceputul fisierul adaugam <b>shebang</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'#!/usr/bin/java --source 11'}
                    </SyntaxHighlighter>

                    deci, fisierul <i>hello</i> va avea urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'#!/usr/bin/java --source 11\n' +
                            '\n' +
                            'public class HelloWorld { \n' +
                            '    public static void main(String[] args) { \n' +
                            '        System.out.println("Hello, World!");\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    se poate rula:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java --source 11 hello'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://openjdk.org/jeps/445"}>*/}
                    {/*            JEP 445: Unnamed Classes and Instance Main Methods (Preview)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ScriptingJavaContent;