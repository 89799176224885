import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class SpringSecurityKeyclaokJavaSpring extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-keycloak-spring-security", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Schitare proiect</b>
                    <br/>
                    <br/>

                    Fie <i>build.gradle</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'org.springframework.boot\' version \'2.7.4\'\n' +
                            '    id \'io.spring.dependency-management\' version \'1.0.14.RELEASE\'\n' +
                            '    id \'java\'\n' +
                            '}\n' +
                            '\n' +
                            'group = \'ro.ibrid.learn\'\n' +
                            'version = \'0.0.1-SNAPSHOT\'\n' +
                            'sourceCompatibility = \'11\'\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-data-jpa\'\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-security\'\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-thymeleaf\'\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-web\'\n' +
                            '    implementation \'org.thymeleaf.extras:thymeleaf-extras-springsecurity5\'\n' +
                            '    runtimeOnly \'org.postgresql:postgresql\'\n' +
                            '    testImplementation \'org.springframework.boot:spring-boot-starter-test\'\n' +
                            '    testImplementation \'org.springframework.security:spring-security-test\'\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\') {\n' +
                            '    useJUnitPlatform()\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Fie <i>LearnKeycloakSpringApplication</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.app;\n' +
                            '\n' +
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            'import org.springframework.boot.autoconfigure.domain.EntityScan;\n' +
                            'import org.springframework.context.annotation.ComponentScan;\n' +
                            'import org.springframework.data.jpa.repository.config.EnableJpaRepositories;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            '@ComponentScan({"ro.ibrid.learn.keycloak.spring.controller", "ro.ibrid.learn.keycloak.spring.service", "ro.ibrid.learn.keycloak.spring.security"})\n' +
                            '@EntityScan("ro.ibrid.learn.keycloak.spring.entity")\n' +
                            '@EnableJpaRepositories("ro.ibrid.learn.keycloak.spring.repository")\n' +
                            'public class LearnKeycloakSpringApplication {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        SpringApplication.run(LearnKeycloakSpringApplication.class, args);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Si <i>application.properties</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'spring.jpa.database=POSTGRESQL\n' +
                            'spring.datasource.url=jdbc:postgresql://192.168.1.8:5432/university\n' +
                            'spring.datasource.username=postgres\n' +
                            'spring.datasource.password=postgres\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Creare tabele in baza de date</b>
                    <br/>
                    <br/>

                    Se creaza 2 tabele:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'CREATE TABLE roles (\n' +
                            '  id SERIAL NOT NULL PRIMARY KEY,\n' +
                            '  role varchar(45) NOT NULL,\n' +
                            '  user_id bigint DEFAULT NULL\n' +
                            ')'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'CREATE TABLE users (\n' +
                            '  id SERIAL NOT NULL PRIMARY KEY,\n' +
                            '  first_name varchar(45) DEFAULT NULL,\n' +
                            '  last_name varchar(45) DEFAULT NULL,\n' +
                            '  email varchar(45) NOT NULL,\n' +
                            '  username varchar(45) NOT NULL,\n' +
                            '  password varchar(300) NOT NULL\n' +
                            ');'}
                    </SyntaxHighlighter>

                    inserare date:
                    <SyntaxHighlighter showLineNumbers={true} language="sql" style={androidstudio}>
                        {'-- parola: admin\n' +
                            'insert into users values (1,\'ion\',\'ion\',\'ion@ion\',\'ion\',\'$2a$10$bYVZU3/kmMGr5z3ksGF9HutDE5l2o3Nw96R515oOAaR9MepQudEfG\');\n' +
                            'insert into users values (2,\'vasile\',\'vasile\',\'vasile@vasile\',\'vasile\',\'$2a$10$bYVZU3/kmMGr5z3ksGF9HutDE5l2o3Nw96R515oOAaR9MepQudEfG\');\n' +
                            '\n' +
                            'insert into roles values (1, \'profesor\',1);\n' +
                            'insert into roles values (2, \'student\',2);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Entitati</b>
                    <br/>
                    <br/>

                    Clasa <i>User</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.entity;\n' +
                            '\n' +
                            'import java.util.Set;\n' +
                            '\n' +
                            'import javax.persistence.Column;\n' +
                            'import javax.persistence.Entity;\n' +
                            'import javax.persistence.FetchType;\n' +
                            'import javax.persistence.GeneratedValue;\n' +
                            'import javax.persistence.Id;\n' +
                            'import javax.persistence.OneToMany;\n' +
                            'import javax.persistence.Table;\n' +
                            '\n' +
                            '@Table(name = "users")\n' +
                            '@Entity\n' +
                            'public class User {\n' +
                            '\n' +
                            '\t@Id\n' +
                            '\t@GeneratedValue\n' +
                            '\t@Column(name = "id")\n' +
                            '\tprivate Long id;\n' +
                            '\n' +
                            '\t@Column(name = "first_name")\n' +
                            '\tprivate String firstName;\n' +
                            '\n' +
                            '\t@Column(name = "last_name")\n' +
                            '\tprivate String lastName;\n' +
                            '\n' +
                            '\t@Column(name = "email")\n' +
                            '\tprivate String email;\n' +
                            '\n' +
                            '\t@Column(name = "username")\n' +
                            '\tprivate String username;\n' +
                            '\n' +
                            '\t@Column(name = "password")\n' +
                            '\tprivate String password;\n' +
                            '\n' +
                            '\t@OneToMany(mappedBy = "user", fetch = FetchType.EAGER)\n' +
                            '\tprivate Set<Roles> roles;\n' +
                            '\n' +
                            '\tpublic Long getId() {\n' +
                            '\t\treturn id;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setId(Long id) {\n' +
                            '\t\tthis.id = id;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getFirstName() {\n' +
                            '\t\treturn firstName;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setFirstName(String firstName) {\n' +
                            '\t\tthis.firstName = firstName;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getLastName() {\n' +
                            '\t\treturn lastName;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setLastName(String lastName) {\n' +
                            '\t\tthis.lastName = lastName;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getEmail() {\n' +
                            '\t\treturn email;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setEmail(String email) {\n' +
                            '\t\tthis.email = email;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getUsername() {\n' +
                            '\t\treturn username;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setUsername(String username) {\n' +
                            '\t\tthis.username = username;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getPassword() {\n' +
                            '\t\treturn password;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setPassword(String password) {\n' +
                            '\t\tthis.password = password;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic Set<Roles> getRoles() {\n' +
                            '\t\treturn roles;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setRoles(Set<Roles> roles) {\n' +
                            '\t\tthis.roles = roles;\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Clasa <i>Roles</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.entity;\n' +
                            '\n' +
                            'import javax.persistence.Column;\n' +
                            'import javax.persistence.Entity;\n' +
                            'import javax.persistence.GeneratedValue;\n' +
                            'import javax.persistence.Id;\n' +
                            'import javax.persistence.JoinColumn;\n' +
                            'import javax.persistence.ManyToOne;\n' +
                            'import javax.persistence.Table;\n' +
                            '\n' +
                            '@Table(name = "roles")\n' +
                            '@Entity\n' +
                            'public class Roles {\n' +
                            '\n' +
                            '\t@Id\n' +
                            '\t@GeneratedValue\n' +
                            '\t@Column(name = "id")\n' +
                            '\tprivate Long id;\n' +
                            '\n' +
                            '\t@Column(name = "role")\n' +
                            '\tprivate String role;\n' +
                            '\n' +
                            '\t@ManyToOne\n' +
                            '\t@JoinColumn(name = "user_id")\n' +
                            '\tprivate User user;\n' +
                            '\n' +
                            '\tpublic Long getId() {\n' +
                            '\t\treturn id;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setId(Long id) {\n' +
                            '\t\tthis.id = id;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic String getRole() {\n' +
                            '\t\treturn role;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setRole(String role) {\n' +
                            '\t\tthis.role = role;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic User getUser() {\n' +
                            '\t\treturn user;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void setUser(User user) {\n' +
                            '\t\tthis.user = user;\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Repositories</b>
                    <br/>
                    <br/>

                    <i>UserRepository</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.repository;\n' +
                            '\n' +
                            'import org.springframework.data.jpa.repository.JpaRepository;\n' +
                            'import org.springframework.stereotype.Repository;\n' +
                            'import ro.ibrid.learn.keycloak.spring.entity.User;\n' +
                            '\n' +
                            '@Repository\n' +
                            'public interface UserRepository extends JpaRepository<User, Long> {\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <i>RolesRepository</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.repository;\n' +
                            '\n' +
                            'import org.springframework.data.jpa.repository.JpaRepository;\n' +
                            'import org.springframework.stereotype.Repository;\n' +
                            'import ro.ibrid.learn.keycloak.spring.entity.Roles;\n' +
                            '\n' +
                            '@Repository\n' +
                            'public interface RolesRepository extends JpaRepository<Roles, Long> {\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Pagina login si home</b>
                    <br/>
                    <br/>

                    Pagina html (resources\tempaltes\login.html):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                            '<html lang="en">\n' +
                            '<head>\n' +
                            '    <meta charset="utf-8">\n' +
                            '    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">\n' +
                            '    <meta name="description" content="">\n' +
                            '    <meta name="author" content="">\n' +
                            '    <title>Please sign in</title>\n' +
                            '    <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css" rel="stylesheet"\n' +
                            '          integrity="sha384-/Y6pD6FV/Vv2HJnA6t+vslU6fwYXjCFtcEpHbNJ0lyAFsXTsjBbfaDjzALeQsN6M" crossorigin="anonymous">\n' +
                            '    <link href="https://getbootstrap.com/docs/4.0/examples/signin/signin.css" rel="stylesheet" crossorigin="anonymous"/>\n' +
                            '</head>\n' +
                            '<body>\n' +
                            '<div class="container">\n' +
                            '    <form class="form-signin" method="post" action="/login">\n' +
                            '        <h2 class="form-signin-heading">Please sign in</h2>\n' +
                            '        <p>\n' +
                            '            <label for="username" class="sr-only">Username</label>\n' +
                            '            <input type="text" id="username" name="username" class="form-control" placeholder="Username" required\n' +
                            '                   autofocus>\n' +
                            '        </p>\n' +
                            '        <p>\n' +
                            '            <label for="password" class="sr-only">Password</label>\n' +
                            '            <input type="password" id="password" name="password" class="form-control" placeholder="Password" required>\n' +
                            '        </p>\n' +
                            '        <input name="_csrf" type="hidden" value="dba82f5c-a21c-4d91-8a84-609139faf669"/>\n' +
                            '        <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>\n' +
                            '    </form>\n' +
                            '</div>\n' +
                            '</body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    Pagina home (resources\tempaltes\home.html):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                            '<html lang="en">\n' +
                            '<head>\n' +
                            '    <meta charset="utf-8">\n' +
                            '    <meta name="viewport"\n' +
                            '          content="width=device-width, initial-scale=1, shrink-to-fit=no">\n' +
                            '    <meta name="description" content="">\n' +
                            '    <meta name="author" content="">\n' +
                            '    <title>Home</title>\n' +
                            '    <link\n' +
                            '            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css"\n' +
                            '            rel="stylesheet"\n' +
                            '            integrity="sha384-/Y6pD6FV/Vv2HJnA6t+vslU6fwYXjCFtcEpHbNJ0lyAFsXTsjBbfaDjzALeQsN6M"\n' +
                            '            crossorigin="anonymous">\n' +
                            '    <link\n' +
                            '            href="https://getbootstrap.com/docs/4.0/examples/signin/signin.css"\n' +
                            '            rel="stylesheet" crossorigin="anonymous"/>\n' +
                            '</head>\n' +
                            '<body>\n' +
                            '<div class="container">\n' +
                            '    <h2 class="form-signin-heading">\n' +
                            '        Welcome <span th:text="${#authentication.getPrincipal().getUsername()}"></span>\n' +
                            '    </h2>\n' +
                            '</div>\n' +
                            '</body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    Controller:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.controller;\n' +
                            '\n' +
                            'import org.springframework.stereotype.Controller;\n' +
                            'import org.springframework.web.bind.annotation.GetMapping;\n' +
                            'import org.springframework.web.servlet.ModelAndView;\n' +
                            '\n' +
                            '@Controller\n' +
                            'public class StudentController {\n' +
                            '\n' +
                            '    @GetMapping(value = {"/", "/login"})\n' +
                            '    public ModelAndView login() {\n' +
                            '        ModelAndView modelAndView = new ModelAndView("login");\n' +
                            '        return modelAndView;\n' +
                            '    }\n' +
                            '\n' +
                            '    @GetMapping("/home")\n' +
                            '    public ModelAndView home() {\n' +
                            '        ModelAndView modelAndView = new ModelAndView("home");\n' +
                            '        return modelAndView;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Configurare Spring Security</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.security;\n' +
                            '\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.security.config.annotation.web.builders.HttpSecurity;\n' +
                            'import org.springframework.security.config.annotation.web.configuration.EnableWebSecurity;\n' +
                            'import org.springframework.security.config.annotation.web.configuration.WebSecurityConfigurerAdapter;\n' +
                            '\n' +
                            '@Configuration\n' +
                            '@EnableWebSecurity\n' +
                            'public class SpringSecurityConfig extends WebSecurityConfigurerAdapter {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void configure(HttpSecurity http) throws Exception {\n' +
                            '        http.authorizeRequests().antMatchers("/", "/login").permitAll()\n' +
                            '                .antMatchers("/home").authenticated()\n' +
                            '                .and().csrf().disable()\n' +
                            '                .formLogin().loginPage("/login")\n' +
                            '                .defaultSuccessUrl("/home")\n' +
                            '                .usernameParameter("username")\n' +
                            '                .passwordParameter("password");\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Implementare UserDetailsService</b>
                    <br/>
                    <br/>

                    In <i>UserRespository</i> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public User findByUsername(String username);'}
                    </SyntaxHighlighter>

                    Se creaza <i>UserService</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.service;\n' +
                            '\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import ro.ibrid.learn.keycloak.spring.entity.User;\n' +
                            'import ro.ibrid.learn.keycloak.spring.repository.UserRepository;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class UserService {\n' +
                            '\n' +
                            '\t@Autowired\n' +
                            '\tUserRepository userRepository;\n' +
                            '\t\n' +
                            '\tpublic User getUserByUsername(String username) {\n' +
                            '\t\treturn userRepository.findByUsername(username);\n' +
                            '\t}\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Se implementeaza <i>UserDetailsService</i>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.security;\n' +
                            '\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.security.core.authority.SimpleGrantedAuthority;\n' +
                            'import org.springframework.security.core.userdetails.UserDetails;\n' +
                            'import org.springframework.security.core.userdetails.UserDetailsService;\n' +
                            'import org.springframework.security.core.userdetails.UsernameNotFoundException;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import ro.ibrid.learn.keycloak.spring.entity.Roles;\n' +
                            'import ro.ibrid.learn.keycloak.spring.entity.User;\n' +
                            'import ro.ibrid.learn.keycloak.spring.service.UserService;\n' +
                            '\n' +
                            'import java.util.HashSet;\n' +
                            'import java.util.Set;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class UserDetailsServiceImpl implements UserDetailsService {\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    UserService userService;\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public UserDetails loadUserByUsername(String username) throws UsernameNotFoundException {\n' +
                            '\n' +
                            '        User user = userService.getUserByUsername(username);\n' +
                            '\n' +
                            '        if (user == null) {\n' +
                            '            throw new UsernameNotFoundException("User not found");\n' +
                            '        }\n' +
                            '\n' +
                            '        Set<SimpleGrantedAuthority> authorities = new HashSet<>();\n' +
                            '\n' +
                            '        for (Roles role : user.getRoles()) {\n' +
                            '            authorities.add(new SimpleGrantedAuthority(role.getRole()));\n' +
                            '        }\n' +
                            '\n' +
                            '        return new org.springframework.security.core.userdetails\n' +
                            '                .User(user.getUsername(), user.getPassword(), authorities);\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Se adauga in <i>SpringSecurityConfig</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Autowired\n' +
                            '\tUserDetailsServiceImpl userDetailsServiceImpl;\n' +
                            '\t\n' +
                            '\t@Override\n' +
                            '\tprotected void configure(AuthenticationManagerBuilder auth) throws Exception {\n' +
                            '\t\tauth.userDetailsService(userDetailsServiceImpl);\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Refactorizare: Spring Security fără WebSecurityConfigurerAdapter</b>
                    <br/>
                    <br/>

                    În Spring Security 5.7.0-M2 s-a renunțat la <i>WebSecurityConfigurerAdapter</i>,
                    deoarece încurajăm să se treacă către o configurație de securitate bazată pe componente.

                    <br/>

                    În Spring Security 5.4 s-a introdus capacitatea de a configura <b>HttpSecurity</b> prin crearea unui bean <b>SecurityFilterChain</b>.
                    <br/>
                    <br/>
                    Prin urmare, se poate renunta la clasa <b>SpringSecurityConfig</b>.
                    <br/>
                    <br/>
                    Si apoi se creeaza urmatoarea clasa de configurare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.learn.keycloak.spring.security;\n' +
                            '\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.security.authentication.dao.DaoAuthenticationProvider;\n' +
                            'import org.springframework.security.config.annotation.web.builders.HttpSecurity;\n' +
                            'import org.springframework.security.core.userdetails.UserDetailsService;\n' +
                            'import org.springframework.security.crypto.bcrypt.BCryptPasswordEncoder;\n' +
                            'import org.springframework.security.crypto.password.PasswordEncoder;\n' +
                            'import org.springframework.security.web.SecurityFilterChain;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class SecurityConfiguration {\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    public SecurityFilterChain filterChain(HttpSecurity http) throws Exception {\n' +
                            '        http.authorizeRequests().antMatchers("/", "/login").permitAll()\n' +
                            '                .antMatchers("/home").authenticated()\n' +
                            '                .and().csrf().disable()\n' +
                            '                .formLogin().loginPage("/login")\n' +
                            '                .defaultSuccessUrl("/home")\n' +
                            '                .usernameParameter("username")\n' +
                            '                .passwordParameter("password");\n' +
                            '\n' +
                            '        http.authenticationProvider(authenticationProvider());\n' +
                            '\n' +
                            '        return http.build();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    public UserDetailsService userDetailsService() {\n' +
                            '        return new UserDetailsServiceImpl();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    public PasswordEncoder passwordEncoder() {\n' +
                            '        return new BCryptPasswordEncoder();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    public DaoAuthenticationProvider authenticationProvider() {\n' +
                            '        DaoAuthenticationProvider authProvider = new DaoAuthenticationProvider();\n' +
                            '\n' +
                            '        authProvider.setUserDetailsService(userDetailsService());\n' +
                            '        authProvider.setPasswordEncoder(passwordEncoder());\n' +
                            '\n' +
                            '        return authProvider;\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    Mai multe informatii:
                    <ul>
                        <li>
                            <a  target={"_blank"} href={"https://spring.io/blog/2022/02/21/spring-security-without-the-websecurityconfigureradapter"}>
                                Spring Security without the WebSecurityConfigurerAdapter
                            </a>
                        </li>
                        <li>
                            <a  target={"_blank"} href={"https://www.codejava.net/frameworks/spring-boot/fix-websecurityconfigureradapter-deprecated"}>
                                Spring Security - How to Fix WebSecurityConfigurerAdapter Deprecated
                            </a>
                        </li>
                    </ul>

                    <hr/>
                    <b>9. Autorizare</b>
                    <br/>
                    <br/>

                    Se adauga adnotarea <i>LearnKeycloakSpringApplication</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@EnableGlobalMethodSecurity(prePostEnabled = true)'}
                    </SyntaxHighlighter>

                    Pentru testare se creaza pagina html (resources\tempaltes\login.html):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                            '<html lang="en">\n' +
                            '<head>\n' +
                            '    <meta charset="utf-8">\n' +
                            '    <meta name="viewport"\n' +
                            '          content="width=device-width, initial-scale=1, shrink-to-fit=no">\n' +
                            '    <meta name="description" content="">\n' +
                            '    <meta name="author" content="">\n' +
                            '    <title>Home</title>\n' +
                            '    <link\n' +
                            '            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css"\n' +
                            '            rel="stylesheet"\n' +
                            '            integrity="sha384-/Y6pD6FV/Vv2HJnA6t+vslU6fwYXjCFtcEpHbNJ0lyAFsXTsjBbfaDjzALeQsN6M"\n' +
                            '            crossorigin="anonymous">\n' +
                            '    <link\n' +
                            '            href="https://getbootstrap.com/docs/4.0/examples/signin/signin.css"\n' +
                            '            rel="stylesheet" crossorigin="anonymous"/>\n' +
                            '</head>\n' +
                            '<body>\n' +
                            '<div class="container">\n' +
                            '    <h2 class="form-signin-heading">\n' +
                            '        Pagina de administrare!\n' +
                            '    </h2>\n' +
                            '</div>\n' +
                            '</body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    Si vrem ca pagina aceasta sa fie acesibila doar utilizatorilor cu rolul <i>profesor</i>.
                    <br/>
                    Adaugam in clasa <i>StudentController</i> urmatoare metoda adnotata cu <b>@PreAuthorize</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'    @GetMapping("/admin")\n' +
                            '    @PreAuthorize("hasAnyAuthority(\'profesor\')")\n' +
                            '    public ModelAndView admin() {\n' +
                            '        ModelAndView modelAndView = new ModelAndView("admin");\n' +
                            '        return modelAndView;\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>10. Pagina de acces interzis</b>
                    <br/>
                    <br/>

                    In metoda: <i>public SecurityFilterChain filterChain(HttpSecurity http)</i> din <i>SecurityConfiguration</i> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'.and().exceptionHandling().accessDeniedPage("/access-denied")'}
                    </SyntaxHighlighter>

                    Apoi se creaza o pagina html in <i>\resources\templates\access-denied.html</i> care va contine un mesaj corespunzator.

                    <br/>
                    <br/>
                    Apoi de defineste intr-un controller:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {' @GetMapping("/access-denied")\n' +
                            '    public ModelAndView accessDenied() {\n' +
                            '        ModelAndView modelAndView = new ModelAndView("access-denied");\n' +
                            '        return modelAndView;\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>11. Pagina de delogare</b>
                    <br/>
                    <br/>

                    In metoda: <i>public SecurityFilterChain filterChain(HttpSecurity http)</i> din <i>SecurityConfiguration</i> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'.and().logout().logoutUrl("/logout").logoutSuccessUrl("/login");'}
                    </SyntaxHighlighter>

                    Iar pe aplicatia web delogare se va realiza accesand link-ul: <b>/logout</b>.
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<a href="/logout">Logout</a>'}
                    </SyntaxHighlighter>

                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default SpringSecurityKeyclaokJavaSpring;