import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class TracerouteLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-traceroute", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Daca nu exista, se poate instala astfel:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install traceroute'}
                    </SyntaxHighlighter>

                    Comanda <b>traceroute</b> este utilizată pentru a determina calea dintre două conexiuni.
                    O conexiune la un alt dispozitiv va trebui să treacă prin mai multe routere.
                    Comanda <b>traceroute</b> va returna numele sau adresele IP ale tuturor routerelor dintre două dispozitive.
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'traceroute google.com'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'traceroute to google.com (142.251.39.78), 30 hops max, 60 byte packets\n' +
                            ' 1  _gateway (192.168.1.1)  0.638 ms  0.715 ms  0.603 ms\n' +
                            ' 2  StamAcasa.rdsnet.ro (10.0.0.1)  3.216 ms  3.454 ms  3.706 ms\n' +
                            ' 3  TotulVaFiBine.rdsnet.ro (10.30.1.209)  4.060 ms  4.235 ms  4.567 ms\n' +
                            ' 4  10.220.178.148 (10.220.178.148)  52.441 ms 10.220.177.110 (10.220.177.110)  52.825 ms  52.693 ms\n' +
                            ' 5  209.85.168.182 (209.85.168.182)  18.011 ms * *\n' +
                            ' 6  * * 74.125.242.225 (74.125.242.225)  15.273 ms\n' +
                            ' 7  142.251.228.31 (142.251.228.31)  15.403 ms 142.251.228.33 (142.251.228.33)  17.007 ms  17.054 ms\n' +
                            ' 8  bud02s39-in-f14.1e100.net (142.251.39.78)  16.543 ms  14.882 ms  15.164 ms\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TracerouteLinuxContent;