import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class UseHistoryReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-usehistory", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hooks: useHistory </b>
                    <br/>
                    <br/>


                    Hook-ul <b>useHistory</b> oferă acces la <i>history</i> pe care o puteți utiliza pentru a naviga intre pagini.
                    <br/>
                    <br/>
                    Redirect la pagina <i>/home</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { useHistory } from "react-router-dom";\n' +
                            '\n' +
                            'function HomeButton() {\n' +
                            '  let history = useHistory();\n' +
                            '\n' +
                            '  function handleClick() {\n' +
                            '    history.push("/home");\n' +
                            '  }\n' +
                            '\n' +
                            '  return (\n' +
                            '    <button type="button" onClick={handleClick}>\n' +
                            '      Go home\n' +
                            '    </button>\n' +
                            '  );\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UseHistoryReactContent;