import {useRoutes} from "react-router-dom";
import IndexContent from "./IndexContent";
import React from "react";
import PrivateRoute from "../../security/PrivateRoute";
import UtilLlmContent from "./UtilLlmContent";
import OllamaRi5LlmContent from "./OllamaRi5LlmContent";
import IntroLlmContent from "./IntroLlmContent";
import WordEmbeddingLlmContent from "./WordEmbeddingLlmContent";


export default function Router() {
    let element = useRoutes([
        {path: "/llm/index", element: <IndexContent/>},
        {path: "/llm/intro", element: <PrivateRoute element={<IntroLlmContent/>}/>},
        {path: "/llm/ollama-pi5", element: <PrivateRoute element={<OllamaRi5LlmContent/>}/>},
        {path: "/llm/util", element: <PrivateRoute element={<UtilLlmContent/>}/>},
        {path: "/llm/word-embedding", element: <PrivateRoute element={<WordEmbeddingLlmContent/>}/>},

    ]);

    return element;
}