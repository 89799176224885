import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class ResourcesK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-resources", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Resurse</b>
                    <br/>
                    <br/>

                    Se poate specifica de ce resurse (memory, cpu) are nevoie un container pentru a rula (sectiunea <b>resources</b>):
                    <ul>
                        <li>requests - cerinte minime</li>
                        <li>limits - limita cerintelor</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - image: nginx\n' +
                            '    name: nginx\n' +
                            '    resources:\n' +
                            '      requests: \n' +
                            '        memory: "1Gi"\n' +
                            '        cpu: 1\n' +
                            '      limits: \n' +
                            '        memory: "2Gi"\n' +
                            '        cpu: 2'}
                    </SyntaxHighlighter>

                    Pentru a seta valori implicite de memorie:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: LimitRange\n' +
                            'metadata:\n' +
                            '  name: mem-limit-range\n' +
                            'spec:\n' +
                            '  limits:\n' +
                            '  - default:\n' +
                            '      memory: 512Mi\n' +
                            '    defaultRequest:\n' +
                            '      memory: 256Mi\n' +
                            '    type: Container'}
                    </SyntaxHighlighter>

                    Pentru a seta valori implicite de cpu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: LimitRange\n' +
                            'metadata:\n' +
                            '  name: cpu-limit-range\n' +
                            'spec:\n' +
                            '  limits:\n' +
                            '  - default:\n' +
                            '      cpu: 1\n' +
                            '    defaultRequest:\n' +
                            '      cpu: 0.5\n' +
                            '    type: Container'}
                    </SyntaxHighlighter>

                    Un container poate folosi memorie peste limita, dar daca face acest lucru in mod contant, va fi oprit!
                    <br/>
                    Un container nu poate folosi CPU peste limita!

                    <hr/>

                    Se poate specifica ca un pod sa ruleze pe un anumit nod (se foloseste <b>nodeSelector</b> si <b>kubernetes.io/hostname</b>: nume-nod)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: nginx\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: nginx\n' +
                            '    image: nginx\n' +
                            '  nodeSelector:\n' +
                            '    kubernetes.io/hostname: worker'}
                    </SyntaxHighlighter>

                    <hr/>

                    Dacă un Pod folosește mai mult CPU decât este permis, acesta NU va fi evacuat.
                    <br/>
                    Dacă un Pod încearcă să folosească mai multă memorie decât are nodul, va fi evacuat.
                    <br/>
                    Dacă un Pod folosește mai mult decât spec.containers[].resources.limits.ephemeral-storage, va fie evacuat!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ResourcesK8sContent;