import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InstallPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Instalare</b>

                    <ol>
                        <li> Se downloadeaza de pe site-ul oficial Python, din <a href={"https://www.python.org/downloads/windows/"}>sectiunea de download</a>, versiunea Windows pe 64-biti:
                            Download Windows installer (64-bit).
                        </li>
                        <li>
                            Se intaleaza executabilul downloadat.
                        </li>
                    </ol>

                    <hr/>
                    <b>Pornire IDLE (Integrated Development and Learning Environment)</b>
                    <br/>
                    <br/>
                    Din urmatoarea locatie: <b>[PYTHON-HOME]\Python39\Lib\idlelib</b><br/>
                    se ruleaza: <b>idle.bat</b>
                    <br/>
                    Va apare urmatorul ecran:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/python-1.png'}/>
                    </div>

                    <hr/>
                    Se ruleaza primul program:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1+2'}
                    </SyntaxHighlighter>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/python/python-2.png'}/>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallPythonContent;