import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nuxeo/IndexContent";

class BuildNuxeoVersionContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-build-nuxeo-version", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    Ne dorim sa face build la o anumita versiune de <b>Nuxeo</b>, de exemplu <b>release-10.10-HF37</b>.
                    <br/>

                    <b>I. Prerechizite:</b>
                    <ol>
                        <li>
                            se instaleaza <b>nvm</b> pentru gestiunea versiunilor de <b>node</b>
                            <ul>
                                <li>
                                    se descarca de <a
                                    href={"https://github.com/coreybutler/nvm-windows/releases"}>aici</a>: <b>nvm-setup.zip</b>
                                </li>
                                <li>
                                    se dezarhiveaza <b>nvm-setup.zip</b>
                                </li>
                                <li>
                                    se instaleaza <b>nvm-setup.exe</b>
                                </li>
                                <li>
                                    verificare:
                                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                        {'nvm version\n' +
                                        '1.1.8'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    afisare versiuni <b>node js</b> disponibile de instalat:
                                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                        {'nvm list available'}
                                    </SyntaxHighlighter>
                                    Rezultatul comenzii de mai sus:
                                    <SyntaxHighlighter showLineNumbers={false} language="text" style={androidstudio}>
                                        {'|   CURRENT    |     LTS      |  OLD STABLE  | OLD UNSTABLE |\n' +
                                        '|--------------|--------------|--------------|--------------|\n' +
                                        '|    17.0.1    |   16.13.0    |   0.12.18    |   0.11.16    |\n' +
                                        '|    17.0.0    |   14.18.1    |   0.12.17    |   0.11.15    |\n' +
                                        '|   16.12.0    |   14.18.0    |   0.12.16    |   0.11.14    |\n' +
                                        '|   16.11.1    |   14.17.6    |   0.12.15    |   0.11.13    |\n' +
                                        '|   16.11.0    |   14.17.5    |   0.12.14    |   0.11.12    |\n' +
                                        '|   16.10.0    |   14.17.4    |   0.12.13    |   0.11.11    |\n' +
                                        '|    16.9.1    |   14.17.3    |   0.12.12    |   0.11.10    |\n' +
                                        '|    16.9.0    |   14.17.2    |   0.12.11    |    0.11.9    |\n' +
                                        '|    16.8.0    |   14.17.1    |   0.12.10    |    0.11.8    |\n' +
                                        '|    16.7.0    |   14.17.0    |    0.12.9    |    0.11.7    |\n' +
                                        '|    16.6.2    |   14.16.1    |    0.12.8    |    0.11.6    |\n' +
                                        '|    16.6.1    |   14.16.0    |    0.12.7    |    0.11.5    |\n' +
                                        '|    16.6.0    |   14.15.5    |    0.12.6    |    0.11.4    |\n' +
                                        '|    16.5.0    |   14.15.4    |    0.12.5    |    0.11.3    |\n' +
                                        '|    16.4.2    |   14.15.3    |    0.12.4    |    0.11.2    |\n' +
                                        '|    16.4.1    |   14.15.2    |    0.12.3    |    0.11.1    |\n' +
                                        '|    16.4.0    |   14.15.1    |    0.12.2    |    0.11.0    |\n' +
                                        '|    16.3.0    |   14.15.0    |    0.12.1    |    0.9.12    |\n' +
                                        '|    16.2.0    |   12.22.7    |    0.12.0    |    0.9.11    |\n' +
                                        '|    16.1.0    |   12.22.6    |   0.10.48    |    0.9.10    |\n' +
                                        '\n' +
                                        'This is a partial list. For a complete list, visit https://nodejs.org/download/releases'}
                                    </SyntaxHighlighter>
                                </li>

                                <li>
                                    instalare versiune noua de <b>node</b>:
                                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                        {'nvm install 10.23.0'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    afisare versiuni instalate:
                                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                        {'nvm list'}
                                    </SyntaxHighlighter>
                                    Rezultatul comenzii de mai sus:
                                    <SyntaxHighlighter showLineNumbers={false} language="text" style={androidstudio}>
                                        {'  * 14.4.0 (Currently using 64-bit executable)\n' +
                                        '    10.23.0'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    schimbare versiune <b>node</b> (folosind un Command Prompt deschis ca <b>administrator</b>, altfel se obtine urmatoarea eroare: <i>exit status 1: You do not have sufficient privilege to perform this operation.</i>)
                                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                        {'nvm use 10.23.0'}
                                    </SyntaxHighlighter>

                                    Rezultatul comenzii de mai sus:
                                    <SyntaxHighlighter showLineNumbers={false} language="text" style={androidstudio}>
                                        {'Now using node v10.23.0 (64-bit)'}
                                    </SyntaxHighlighter>

                                    Verificare:
                                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                        {'nvm list\n' +
                                        '\n' +
                                        '    14.4.0\n' +
                                        '  * 10.23.0 (Currently using 64-bit executable)'}
                                    </SyntaxHighlighter>
                                </li>

                            </ul>
                        </li>
                        <li>
                            se instaleaza <b>Node</b> (minim v6.5.0, verificat pe <del>v14.4.0</del> - nu merge - verificat pe <b>v10.23.0</b>) si se verifica versiunea:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'node -v\n' +
                                'v14.4.0'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se instaleaza <b>npm</b> si se verifica versiunea (verificat pe <del>6.14.5</del> - nu merge - <b>6.14.8</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'npm install npm\n' +
                                'npm -v\n' +
                                '6.14.5'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se instaleaza (daca nu sunt
                            instalate): <b>yo</b>, <b>grunt-cli</b>, <b>gulp</b> si <b>bower</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'npm -g install yo\n' +
                                'npm -g install grunt-cli\n' +
                                'npm -g install gulp\n' +
                                'npm -g install bower'}
                            </SyntaxHighlighter>
                            sau toate o data (daca nu e nici unul instalat):
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'npm -g install yo grunt-cli gulp bower'}
                            </SyntaxHighlighter>

                            verificare versiune <b>yo</b> (verificat pe <b>4.3.0</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'yo --version\n' +
                                '4.3.0'}
                            </SyntaxHighlighter>

                            la instalare <b>grunt-cli</b> se afiseaza versiunea (verificat pe <b>1.4.3</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'+ grunt-cli@1.4.3\n' +
                                'added 58 packages from 71 contributors in 12.158s'}
                            </SyntaxHighlighter>

                            la instalare <b>gulp</b> (verificat pe <b>4.0.2</b>)
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'+ gulp@4.0.2\n' +
                                'updated 1 package in 43.011s'}
                            </SyntaxHighlighter>

                            verificare versiune <b>gulp</b> :
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'gulp -v\n' +
                                'CLI version: 2.3.0\n' +
                                'Local version: Unknown'}
                            </SyntaxHighlighter>

                            la instalare <b>bower</b> se afiseaza versiunea (verificat pe <b>1.8.12</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'+ bower@1.8.12\n' +
                                'added 1 package from 1 contributor in 3.749s'}
                            </SyntaxHighlighter>

                            verificare versiune <b>bower</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'bower -v\n' +
                                '1.8.12'}
                            </SyntaxHighlighter>

                        </li>

                        <li>
                            se instaleaza <b>Python 2.7</b>
                        </li>

                    </ol>

                    <b>II. Build versiune Nuxeo:</b>
                    <ol>
                        <li>
                            se descarca din repository nuxeo proiectul:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'git clone https://github.com/nuxeo/nuxeo.git'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se verifica ce tag-uri are proiectul nuxeo:<br/>
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'git tag'}
                            </SyntaxHighlighter>

                            se verifica ce tag-uri are proiectul nuxeo cu anumit pattern:<br/>
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'git tag -l "release-10.10-HF3*"'}
                            </SyntaxHighlighter>

                            <b>Observatie</b>: daca sunt prea multe tag-uri se iese apasand tasta <b>q</b>

                        </li>

                        <li>
                            se ia versiunea <b>release-10.10-HF37</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'git checkout release-10.10-HF37'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se verifica ca suntem branch-ul <b>release-10.10-HF37</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'D:\\work\\nuxeo\\nuxeo>git branch\n' +
                                '* (HEAD detached at release-10.10-HF37)\n' +
                                '  10.10\n' +
                                '  fix-NXP-29170-user-creation-with-keycloak-10.10\n' +
                                '  master'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            se ruleaza scriptul python cu Python 2.7:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'C:\\tools\\Pyhton\\Python27\\python.exe clone.py release-10.10-HF37 -a'}
                            </SyntaxHighlighter>
                            sau daca suntem pe branch-ul <b>master</b>
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'python.exe clone.py master -a'}
                            </SyntaxHighlighter>
                        </li>


                        <li>
                            se ruleaza in radacina proiectului:<br/>
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'mvn -DskipTests install -Paddons,distrib'}
                            </SyntaxHighlighter>

                            daca build-ul esueaza se poate relua de unde a ramas (de exemplu, crapa la <b>modul
                            nuxeo-preview-core</b> cu <b>Cannot run program "bower.cmd"</b>):
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'mvn -DskipTests install -Paddons,distrib -rf :nuxeo-preview-core'}
                            </SyntaxHighlighter>

                            (daca build-ul a esuat din motivul de mai sus, verifica ca ai instalat prerechizitele)
                        </li>

                    </ol>
                    <hr/>
                    <b>Probleme</b>
                    <ul>
                        <li>
                            <b>:nuxeo-api-playground</b>
                            <br/>
                            se comenteaza modulul si dependintele catre acest modul din <b>pom.xml</b> din <b>\addons</b>:

                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'<module>nuxeo-api-playground</module>'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'<dependency>\n' +
                                '   <groupId>org.nuxeo.ecm.platform</groupId>\n' +
                                '   <artifactId>nuxeo-api-playground</artifactId>\n' +
                                '   <version>${nuxeo.playground.version}</version>\n' +
                                '</dependency>'}
                            </SyntaxHighlighter>

                            apoi, se continua build-ul:
                            <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                                {'mvn -DskipTests install -Paddons,distrib -rf :nuxeo-web-ui-itests'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://doc.nuxeo.com/nxdoc/setting-up-your-javascript-environment/"}>
                                    Setting up Your JavaScript Environment
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://blog.logrocket.com/switching-between-node-versions-during-development/"}>
                                    Switching between Node versions during development
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );

    }
}

export default BuildNuxeoVersionContent;