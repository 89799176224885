import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class BindJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-bind", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Rolul metodei <b>bind()</b> este de <b>crea o noua functie</b>, pe baza unei functii care are un context (this),
                    prin legarea functiei de un obiect.
                    <br/>

                    Exemplu: fie functia <i>f</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function f(x) { return this.x + y} '}
                    </SyntaxHighlighter>
                    se creaza obiectul:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let obj = {x: 3}'}
                    </SyntaxHighlighter>
                    se creaza o nou functie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            'let newF = f.bind(obj);'
                        }
                    </SyntaxHighlighter>
                    apeleare:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'newF(5)'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Metoda bind() nu are sens și niciun efect atunci când este aplicată pe funcții săgeată (arrow functions).
                            Acest lucru se datorează faptului că funcțiile săgeată nu au propriul context this.
                            Ele își "moștenesc" automat contextul this din mediul (contextul lexical) în care sunt definite.
                        </li>
                    </ul>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BindJavaScriptContent;