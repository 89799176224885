import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class LoggingNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-logging", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Logare</b>

                    <br/>
                    <br/>
                    Tipuri de fisiere log:
                    <ul>
                        <li>error log:
                            <ul>
                                <li> logheza erorile aparute pe server</li>
                                <li> eroare se sintaxa in nginx.conf</li>
                            </ul>
                            </li>
                        <li>access log: logheaza toate cererile venite pe server</li>
                    </ul>

                    Cand s-a configurat nginx s-a specificat calea catre cele 2 tipuri de fisiere:
                    <ul>
                        <li>--error-log-path=/var/log/nginx/error.log</li>
                        <li>--http-log-path=/var/log/access.log</li>
                    </ul>

                    Pentru a sterge continutul la aceste 2 fisiere:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd /var/log/nginx\n' +
                            'echo \'\' > access.log\n' +
                            'echo \'\' > error.log'}
                    </SyntaxHighlighter>

                    Se pot creea fisere de log custom, folosind:
                    <ul>
                        <li>error_log</li>
                        <li>access_log</li>
                    </ul>

                    Sintaxa <b>error_log</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'error_log file [level]'}
                    </SyntaxHighlighter>

                    Sintaxa <b>access_log</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'access_log path [format] [gzip] [flush] [if]\n' +
                            'access_log off'}
                    </SyntaxHighlighter>

                    Exemplu de a loga mesaje de tip access pentru resursa de la locatia <i>/secure</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'location /secure {\n' +
                            '\n' +
                            '\taccess_log /var/log/nginx/secure.access.log;\n' +
                            '\treturn 200 "ok";\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a dezactiva logarea: <b>access_log off;</b>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'location /secure {\n' +
                            '\n' +
                            '\taccess_log off;\n' +
                            '\treturn 200 "ok";\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LoggingNginxContent;