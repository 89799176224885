import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class LsLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-ls", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a afisa/lista fisierele si directoarele din directorul curent de lucru:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'Desktop    kubectl               Music                 nginx-1.23.1         Public     Videos\n' +
                            'Documents  learn                 nginx-1.13.10         nginx-1.23.1.tar.gz  snap\n' +
                            'Downloads  minikube-linux-amd64  nginx-1.13.10.tar.gz  Pictures             Templates'}
                    </SyntaxHighlighter>
                    <hr/>
                    Pentru a afisa si fisierele si directoarele ascunse:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -a'}
                    </SyntaxHighlighter>

                    Pentru a afisa detalii legate de fisiere si directoare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -l'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'total 120916\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Desktop\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Documents\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Downloads\n' +
                            '-rw-rw-r--  1 kj kj 45015040 oct 28 00:24 kubectl\n' +
                            'drwxrwxr-x 10 kj kj     4096 oct 31 19:28 learn\n' +
                            '-rw-rw-r--  1 kj kj 76629407 oct 27 23:57 minikube-linux-amd64\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Music\n' +
                            'drwxr-xr-x  9 kj kj     4096 sep 18 02:57 nginx-1.13.10\n' +
                            '-rw-rw-r--  1 kj kj  1014863 mar 20  2018 nginx-1.13.10.tar.gz\n' +
                            'drwxr-xr-x  9 kj kj     4096 sep 18 13:27 nginx-1.23.1\n' +
                            '-rw-rw-r--  1 kj kj  1104352 iul 19 17:30 nginx-1.23.1.tar.gz\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Pictures\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Public\n' +
                            'drwx------  5 kj kj     4096 sep 21 09:29 snap\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Templates\n' +
                            'drwxr-xr-x  2 kj kj     4096 sep 11 01:09 Videos'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            (ex: <i>drwxr-xr-x</i>) afiseaza tipul si permisiunile
                            Tipul:
                            <ul>
                                <li>- : fisier</li>
                                <li>d : director</li>
                                <li>l : link</li>
                            </ul>

                            Permisiuni:
                            <ul>
                                <li>w: drept de scriere (drept de redenumire, creare, stergere fisiere)</li>
                                <li>x: drept de executie (permite comanda cd si acceare fisiere)</li>
                                <li>r: drept de citire (listare fisiere dintr-un director)</li>
                                <li>-: fara drepturi</li>
                            </ul>
                            Permisiunile sunt grupate in 3:
                            <ul>
                                permisiuni pentru utilizatorul care le-a creat [flag-urile 2-4]
                            </ul>
                            <li>
                                permisiuni pentru root [flag-urile 5-7]
                            </li>
                            <li>
                                permisiuni alti utilizatori care nu sunt in grup  [flag-urile 8-10]
                            </li>
                        </li>
                        <li>
                            (ex: 10) :numarul de link-uri catre acest fisier/director
                        </li>
                        <li>
                            username
                        </li>
                        <li>
                            grupul
                        </li>
                        <li>
                            dimensiune director/fisier
                        </li>
                        <li>
                            data la care a fost creeat
                        </li>
                        <li>
                            nume director/fisier
                        </li>
                    </ul>

                    Pentru a afisa detalii legate de fisiere si directoare, dar cu afisarea dimensiuni mai lizibila:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -lh'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'total 119M\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Desktop\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Documents\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Downloads\n' +
                            '-rw-rw-r--  1 kj kj  43M oct 28 00:24 kubectl\n' +
                            'drwxrwxr-x 10 kj kj 4,0K oct 31 19:28 learn\n' +
                            '-rw-rw-r--  1 kj kj  74M oct 27 23:57 minikube-linux-amd64\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Music\n' +
                            'drwxr-xr-x  9 kj kj 4,0K sep 18 02:57 nginx-1.13.10\n' +
                            '-rw-rw-r--  1 kj kj 992K mar 20  2018 nginx-1.13.10.tar.gz\n' +
                            'drwxr-xr-x  9 kj kj 4,0K sep 18 13:27 nginx-1.23.1\n' +
                            '-rw-rw-r--  1 kj kj 1,1M iul 19 17:30 nginx-1.23.1.tar.gz\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Pictures\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Public\n' +
                            'drwx------  5 kj kj 4,0K sep 21 09:29 snap\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Templates\n' +
                            'drwxr-xr-x  2 kj kj 4,0K sep 11 01:09 Videos'}
                    </SyntaxHighlighter>

                    Pentru a afisa fisierele si directoarele din directorul curent cu afisarea tipului:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -F'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Desktop/    Downloads/  learn/                Music/          nginx-1.13.10.tar.gz  nginx-1.23.1.tar.gz  Public/  Templates/\n' +
                            'Documents/  kubectl     minikube-linux-amd64  nginx-1.13.10/  nginx-1.23.1/         Pictures/            snap/    Videos/'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            / - director
                        </li>
                        <li>
                            @ - link
                        </li>
                    </ul>

                    <hr/>

                    Pentru a afisa detalii legate de <b>inode</b>, pentru a decoperii <b>hard link</b>-uri, se foloseste flag-ul <b>i</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls -li'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Sortare</b>
                    <br/>
                    <br/>
                    Pentru sortare se pot folosi flag-urile:
                    <ul>
                        <li>
                            -S: sortare dupa dimensiune:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'ls -lS'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'total 20\n' +
                                    'drwxrwxr-x 2 kj kj 4096 dec 13 08:06 test\n' +
                                    '-rw-rw-r-- 1 kj kj  385 dec 13 13:26 arhiva.tar.gz\n' +
                                    '-rw-rw-r-- 1 kj kj  184 dec 12 17:50 a.txt\n' +
                                    '-rw-rw-r-- 1 kj kj   90 dec 12 19:47 a.txt.save\n' +
                                    '-rw-rw-r-- 1 kj kj    4 dec 13 01:19 test.txt\n' +
                                    '-rw-rw-r-- 1 kj kj    0 dec 13 08:10 xx\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            --sort: sortare dupa dimensiune, nume sau data crearii:
                            -S: sortare dupa dimensiune:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'ls -l --sort=time'}
                            </SyntaxHighlighter>
                            poate afisa:
                            <SyntaxHighlighter>
                                {'total 20\n' +
                                    '-rw-rw-r-- 1 kj kj  385 dec 13 13:26 arhiva.tar.gz\n' +
                                    '-rw-rw-r-- 1 kj kj    0 dec 13 08:10 xx\n' +
                                    'drwxrwxr-x 2 kj kj 4096 dec 13 08:06 test\n' +
                                    '-rw-rw-r-- 1 kj kj    4 dec 13 01:19 test.txt\n' +
                                    '-rw-rw-r-- 1 kj kj   90 dec 12 19:47 a.txt.save\n' +
                                    '-rw-rw-r-- 1 kj kj  184 dec 12 17:50 a.txt\n'}
                            </SyntaxHighlighter>
                            Valorile valide pentru sort:
                            <ul>
                                <li>
                                    none
                                </li>
                                <li>
                                    time
                                </li>
                                <li>
                                    size
                                </li>
                                <li>
                                    extension
                                </li>
                                <li>
                                    version
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LsLinuxContent;