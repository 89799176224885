import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ReduxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-redux", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Introducere</b>
                    <br/>
                    <br/>

                    <b>Redux</b> este o bibliotecă pentru gestionarea stării globale a aplicației.
                    <br/>
                    <br/>

                    <b>Redux Toolkit</b> este abordarea oficială recomandată pentru scrierea logicii Redux.
                    <br/>
                    Acesta cuprinde nucleul Redux și conține pachete și funcții esențiale pentru construirea unei aplicații Redux.
                    <br/>
                    Redux Toolkit integrează cele mai bune practici, simplifică majoritatea sarcinilor Redux, previne greșelile obișnuite și facilitează scrierea aplicațiilor Redux.

                    <br/>
                    <br/>
                    Modul recomandat de a porni aplicații noi cu React și Redux este utilizarea șablonului oficial Redux+JS sau șablonului Redux+TS pentru Create React App , care profită de integrarea Redux Toolkit și React Redux cu componentele React.
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'# Redux + Plain JS template\n' +
                            'npx create-react-app my-app --template redux\n' +
                            '\n' +
                            '# Redux + TypeScript template\n' +
                            'npx create-react-app my-app --template redux-typescript'}
                    </SyntaxHighlighter>
                    <hr/>

                    <b>2. Instalare</b>
                    <br/>
                    <br/>

                    Instalare Redux in cadrul unui proiect React:
                    <ul>
                        <li><b>redux</b></li>
                        <li><b>react-redux</b></li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install redux react-redux'}
                    </SyntaxHighlighter>

                    Instalare: <b>@reduxjs/toolkit</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install @reduxjs/toolkit'}
                    </SyntaxHighlighter>

                    Instalare completa cu extensii:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install redux react-redux @reduxjs/toolkit next-redux-wrapper redux-thunk redux-persist redux-devtools-extension'}
                    </SyntaxHighlighter>

                    Mai multe detalii: <a target={"_blank"} href={"https://redux.js.org/"}>https://redux.js.org/</a>

                    <hr/>
                    <b>3.  Mod de utilizare cu redux</b>
                    <br/>
                    <br/>

                    Întreaga stare globală a aplicației dvs. este stocată într-un arbore de obiecte în interiorul unui singur <b>store</b>.
                    <br/>
                    Singura modalitate de a schimba arborele de stare este de a crea o <b>action</b>,
                    un obiect care descrie ceea ce se întâmplă și de a-l trimite/<b>dispatch</b> la <b>store</b>.
                    <br/>

                    Pentru a specifica modul în care starea este actualizată ca răspuns la o acțiune,
                    se scriu <b>funcții reducătoare pure</b> (functii <b>reducer</b>) care calculează o stare nouă pe baza stării vechi și a acțiunii.
                    <br/>
                    <br/>
                    3.1. Vom creea un director <b>store</b> (in radacine proiectului) si in interior fisierul <b>store.js</b> cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { createStore } from \'redux\'\n\n' +
                            '// create reducer\n' +
                            'const countReducer = (state = 0, action) => {\n' +
                            '   if (action.type==="SUBSTRACT"){\n' +
                            '       return state - 1;\n' +
                            '   }\n' +
                            '   if (action.type==="ADD"){\n' +
                            '       return state + 1;\n' +
                            '   }\n' +
                            '   if (action.type==="RESET"){\n' +
                            '       return state = 0;\n' +
                            '   }\n' +
                            '   return state;\n' +
                            '}\n' +
                            '\n' +
                            '// creare store\n' +
                            'const store = createStore(countReducer);\n' +
                            '\n' +
                            'export default store;'}
                    </SyntaxHighlighter>

                    Un <b>reducer</b> este o funcție care primește curenta statea un obiect action, si decide cum să actualizeze starea dacă este necesar și returnează noua stare:
                    <br/>
                    (state, action) {"=>"} newState.
                    <br/>
                    <br/>
                    Un <b>reductor</b> poate fi vazut ca la un ascultător de evenimente care gestionează evenimente în funcție de tipul de acțiune (eveniment) primit.


                    <hr/>
                    3.2. <b>Provider</b> pentru aplicatie:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { Provider } from \'react-redux\';'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { store } from \'./store/store\';'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Provider store={store}>\n' +
                            '  <App/>\n' +
                            '</Provider>'}
                    </SyntaxHighlighter>

                    <hr/>
                    3.3. Accesare date stocate de <b>Redux</b> (<b>useSelector</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { useSelector } from \'react-redux\';'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const count = useSelector(state=>state);'}
                    </SyntaxHighlighter>

                    <hr/>
                    3.4. Actiuni (<b>useDispatch</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { useDispatch } from \'react-redux\';'}
                    </SyntaxHighlighter>
                    si apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const dispatch = useDispatch();'}
                    </SyntaxHighlighter>

                    apelare actiune:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'dispatch({\n' +
                            '  type: "ADD\n"' +
                            '})'}
                    </SyntaxHighlighter>

                    <hr/>
                    3.5. Payload (se poate da si alt nume)
                    <br/>
                    <br/>
                    Se pot trimite extra informatii, care vor putea fi accesate prin <i>action</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'dispatch({\n' +
                            '  type: "ADD",\n' +
                            '  payload: 5\n' +
                            '})'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { createStore } from \'redux\''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'function counterReducer(state = { value: 0 }, action) {\n' +
                            '  switch (action.type) {\n' +
                            '    case \'counter/incremented\':\n' +
                            '      return { value: state.value + 1 }\n' +
                            '    case \'counter/decremented\':\n' +
                            '      return { value: state.value - 1 }\n' +
                            '    default:\n' +
                            '      return state\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se creeaza un <b>store</b> Redux pentru a pastra starea aplicatiei (primeste ca parameter o functie <b>reducer</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let store = createStore(counterReducer)'}
                    </SyntaxHighlighter>

                    Metoda <b>subscribe()</b> se poate folosi pentru a actualiza UI cand se schimba starea:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'store.subscribe(() => console.log(store.getState()))'}
                    </SyntaxHighlighter>

                    Singurul mod de a schimba o stare interna este de a trimite o actiune:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'store.dispatch({ type: \'counter/incremented\' })\n' +
                            '// {value: 1}\n' +
                            'store.dispatch({ type: \'counter/incremented\' })\n' +
                            '// {value: 2}\n' +
                            'store.dispatch({ type: \'counter/decremented\' })\n' +
                            '// {value: 1}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Combinare functiie de tip reducer</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { combineReducers } from \'redux\''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const reducers = combineReducers({\n' +
                            '   count: countReducer,\n' +
                            '   auth: authReducer.\n' +
                            '})\n'}
                    </SyntaxHighlighter>

                    apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const count = useSelector(state=>state.count);'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const auth = useSelector(state=>state.auth);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Exemplu de utilizare cu @reduxjs/toolkit</b>
                    <br/>
                    <br/>

                    <b>Redux Toolkit</b> simplifică procesul de scriere a logicii Redux și de configurare a magazinului. Cu Redux Toolkit, aceeași logică arată astfel:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { createSlice, configureStore } from \'@reduxjs/toolkit\'\n' +
                            '\n' +
                            'const counterSlice = createSlice({\n' +
                            '  name: \'counter\',\n' +
                            '  initialState: {\n' +
                            '    value: 0\n' +
                            '  },\n' +
                            '  reducers: {\n' +
                            '    incremented: state => {\n' +
                            '      state.value += 1\n' +
                            '    },\n' +
                            '    decremented: state => {\n' +
                            '      state.value -= 1\n' +
                            '    }\n' +
                            '  }\n' +
                            '})\n' +
                            '\n' +
                            'export const { incremented, decremented } = counterSlice.actions\n' +
                            '\n' +
                            'const store = configureStore({\n' +
                            '  reducer: counterSlice.reducer\n' +
                            '})\n' +
                            '\n' +
                            '// Can still subscribe to the store\n' +
                            'store.subscribe(() => console.log(store.getState()))\n' +
                            '\n' +
                            '// Still pass action objects to `dispatch`, but they\'re created for us\n' +
                            'store.dispatch(incremented())\n' +
                            '// {value: 1}\n' +
                            'store.dispatch(incremented())\n' +
                            '// {value: 2}\n' +
                            'store.dispatch(decremented())\n' +
                            '// {value: 1}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Exemplu de utilizare cu @reduxjs/toolkit cu persistare redux-persist</b>
                    <br/>
                    <br/>

                    Instalare <b>redux-persist</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install redux-persist'}
                    </SyntaxHighlighter>

                    Utilizarea de bază implică adăugarea <b>persistReducer</b> și <b>persistStore</b> in configurare.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'// configureStore.js\n' +
                            '\n' +
                            'import { createStore } from \'redux\'\n' +
                            'import { persistStore, persistReducer } from \'redux-persist\'\n' +
                            'import storage from \'redux-persist/lib/storage\' // defaults to localStorage for web\n' +
                            '\n' +
                            'import rootReducer from \'./reducers\'\n' +
                            '\n' +
                            'const persistConfig = {\n' +
                            '  key: \'root\',\n' +
                            '  storage,\n' +
                            '}\n' +
                            '\n' +
                            'const persistedReducer = persistReducer(persistConfig, rootReducer)\n' +
                            '\n' +
                            'export default () => {\n' +
                            '  let store = createStore(persistedReducer)\n' +
                            '  let persistor = persistStore(store)\n' +
                            '  return { store, persistor }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Folosind React, componenta rădăcină trebuie impachetata cu PersistGate.
                    Acest lucru întârzie redarea interfeței de utilizare a aplicației dvs. până când starea dvs. persistentă este preluată și salvată în redux:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { PersistGate } from \'redux-persist/integration/react\'\n' +
                            '\n' +
                            '// ... normal setup, create store and persistor, import components etc.\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  return (\n' +
                            '    <Provider store={store}>\n' +
                            '      <PersistGate loading={null} persistor={persistor}>\n' +
                            '        <RootComponent />\n' +
                            '      </PersistGate>\n' +
                            '    </Provider>\n' +
                            '  );\n' +
                            '};'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>7. Exemplu de utilizare cu @reduxjs/toolkit cu persistare redux-persist si redux-thunk</b>
                    <br/>
                    <br/>

                    Instalare <b>redux-thunk</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install redux-thunk'}
                    </SyntaxHighlighter>

                    Cuvântul „thunk” este un termen de programare care înseamnă „o bucată de cod care face ceva întârziere”.
                    <br/>
                    În loc să executăm ceva logică acum, putem scrie un corp de funcție sau un cod care poate fi folosit pentru a efectua munca mai târziu.
                    <br/>
                    În special pentru Redux, „thunks” sunt un model de scriere a funcțiilor cu o logică în interior care poate interacționa cu un store Redux prin intermediul metodelor:
                    <b>dispatch()</b> și <b>getState()</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const thunkFunction = (dispatch, getState) => {\n' +
                            '  // logic here that can dispatch actions or read state\n' +
                            '}\n' +
                            '\n' +
                            'store.dispatch(thunkFunction)'}
                    </SyntaxHighlighter>

                    <br/>
                    <br/>

                    Creare slice (pentru cart):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {createSlice} from "@reduxjs/toolkit";\n' +
                            '\n' +
                            'export const cartSlice = createSlice({\n' +
                            '    name: "cart",\n' +
                            '    initialState: [],\n' +
                            '    // initialState: "hello",\n' +
                            '    reducers: {}\n' +
                            '})\n' +
                            '\n' +
                            '//export const {} = cartSlice.actions;\n' +
                            '\n' +
                            'export default cartSlice.reducer;\n'}
                    </SyntaxHighlighter>

                    Creare store:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import { configureStore } from "@reduxjs/toolkit";\n' +
                            'import { combineReducers} from "redux";\n' +
                            'import thunk from "redux-thunk";\n' +
                            'import storage from "redux-persist/lib/storage";\n' +
                            'import  {persistReducer} from "redux-persist";\n' +
                            '\n' +
                            'import cart from "./cartSlice";\n' +
                            '\n' +
                            'const reducers = combineReducers({cart});\n' +
                            '\n' +
                            'const config = {\n' +
                            '    key: "root",\n' +
                            '    storage\n' +
                            '}\n' +
                            '\n' +
                            'const reducer = persistReducer(config, reducers);\n' +
                            '\n' +
                            'const store = configureStore({\n' +
                            '    reducer: reducer,\n' +
                            '    devTools: process.env.NODE_ENV !== "production",\n' +
                            '    middleware: [thunk]\n' +
                            '})\n' +
                            '\n' +
                            'export default store;\n'}
                    </SyntaxHighlighter>

                    Se poate combina mai multe functii reducer:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const reducers = combineReducers({cart});'}
                    </SyntaxHighlighter>

                    Pentru a creea o functie reducer persistata:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const reducer = persistReducer(config, reducers);'}
                    </SyntaxHighlighter>

                    Pentru a creea <i>store</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const store = configureStore({\n' +
                            '    reducer: reducer,\n' +
                            '    devTools: process.env.NODE_ENV !== "production",\n' +
                            '    middleware: [thunk]\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Middleware extinde abilitățile store-ului și vă permite să scrieți o logică <b>asincronă</b> care interacționează cu store.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReduxContent;