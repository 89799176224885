import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PortletConfigurationContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-configuration", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    Să presupunem ca vrem ca un portlet sa efectueze anumite operatii in functie de anumit parametru.
                    De exemplu, sa se afiseze un anumit continut in functie de un topic setat in ecranul de <b>Configuration</b> al portletului.
                    In plus, sa stabilim si numarul de articole pe pagina.
                    <br/>

                    Pentru inceput, vom creea o interfata <b>ThemeTestConfiguration</b>, adnotata cu <b>@Meta.OCD</b>, avand ca <b>id numele intreg a interfetei de configurare (cu tot cu numele pachetului)</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration;\n' +
                        '\n' +
                        'import aQute.bnd.annotation.metatype.Meta;\n' +
                        '\n' +
                        '@Meta.OCD(id = "ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration.ThemeTestConfiguration")\n' +
                        'public interface ThemeTestConfiguration {\n' +
                        '\n' +
                        '    @Meta.AD(\n' +
                        '            deflt = "java",\n' +
                        '            required = false\n' +
                        '    )\n' +
                        '    public String topic();\n' +
                        '\n' +
                        '    @Meta.AD(\n' +
                        '            deflt = "java|python|javascript",\n' +
                        '            required = false\n' +
                        '    )\n' +
                        '    public String[] validTopic();\n' +
                        '\n' +
                        '    @Meta.AD(\n' +
                        '            deflt = "10",\n' +
                        '            required = false)\n' +
                        '    public int itemsPerPage();\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    In fisierul <b>gradle.build</b> trebuie adaugat dependintele pentru cele doua adnotari folosite mai sus: <b>Meta.OCD</b> si <b>Meta.AD</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '    compile group: "biz.aQute.bnd", name: "biz.aQute.bndlib", version: "3.1.0"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Functioneaza si cu urmatoarele configurari in <b>gradle.build</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '\n' +
                        '\tcssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.0.2"\n' +
                        '}'}
                    </SyntaxHighlighter>


                    Iar in fisierul <b>bnd.bnd</b> trebuie adaugat:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'-metatype: *'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="dnd" style={androidstudio}>
                        {'Bundle-Name: theme-test\n' +
                        'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.apps.themetest\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Export-Package: ro.letyournailsgrow.liferay.vanilla.apps.themetest.constants\n' +
                        '-metatype: *\n'}
                    </SyntaxHighlighter>

                    Contantele portretului se vor defini in clasa <b>ThemeTestPortletKeys</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.themetest.constants;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        'public class ThemeTestPortletKeys {\n' +
                        '\n' +
                        '\tpublic static final String THEMETEST_PORTLET = "ro_letyournailsgrow_liferay_vanilla_apps_themetest_ThemeTestPortlet";\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Inima portretului este clasa <b>ThemeTestPortlet</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.themetest.portlet;\n' +
                        '\n' +
                        'import com.liferay.portal.configuration.metatype.bnd.util.ConfigurableUtil;\n' +
                        'import org.osgi.service.component.annotations.Activate;\n' +
                        'import org.osgi.service.component.annotations.Modified;\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration.ThemeTestConfiguration;\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.themetest.constants.ThemeTestPortletKeys;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                        '\n' +
                        'import javax.portlet.Portlet;\n' +
                        'import javax.portlet.PortletException;\n' +
                        'import javax.portlet.RenderRequest;\n' +
                        'import javax.portlet.RenderResponse;\n' +
                        '\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        '\n' +
                        'import java.io.IOException;\n' +
                        'import java.util.Map;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '    configurationPid = "ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration.ThemeTestConfiguration",\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=ThemeTest",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.name=" + ThemeTestPortletKeys.THEMETEST_PORTLET,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class ThemeTestPortlet extends MVCPortlet {\n' +
                        '\n' +
                        '\tprivate volatile ThemeTestConfiguration themeTestConfiguration;\n' +
                        '\n' +
                        '\t@Override\n' +
                        '\tpublic void doView(RenderRequest renderRequest,\n' +
                        '\t\t\t\t\t   RenderResponse renderResponse) throws IOException, PortletException {\n' +
                        '\n' +
                        '\t\trenderRequest.setAttribute(ThemeTestConfiguration.class.getName(), themeTestConfiguration);\n' +
                        '\t\tsuper.doView(renderRequest, renderResponse);\n' +
                        '\t}\n' +
                        '\n' +
                        '\t@Activate\n' +
                        '\t@Modified\n' +
                        '\tprotected void activate(Map<Object, Object> properties) {\n' +
                        '\t\tthemeTestConfiguration = ConfigurableUtil.createConfigurable(ThemeTestConfiguration.class, properties);\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Clasa care gestioneaza configurarile portletului <b>ThemeTestConfigurationAction</b> extinde <b>DefaultConfigurationAction</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.apps.themetest.action;\n' +
                        '\n' +
                        'import com.liferay.portal.configuration.metatype.bnd.util.ConfigurableUtil;\n' +
                        'import com.liferay.portal.kernel.portlet.ConfigurationAction;\n' +
                        'import com.liferay.portal.kernel.portlet.DefaultConfigurationAction;\n' +
                        'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                        'import org.osgi.service.component.annotations.Activate;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.ConfigurationPolicy;\n' +
                        'import org.osgi.service.component.annotations.Modified;\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration.ThemeTestConfiguration;\n' +
                        'import ro.letyournailsgrow.liferay.vanilla.apps.themetest.constants.ThemeTestPortletKeys;\n' +
                        '\n' +
                        'import javax.portlet.ActionRequest;\n' +
                        'import javax.portlet.ActionResponse;\n' +
                        'import javax.portlet.PortletConfig;\n' +
                        'import javax.servlet.http.HttpServletRequest;\n' +
                        'import javax.servlet.http.HttpServletResponse;\n' +
                        'import java.util.Map;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        configurationPid = "ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration.ThemeTestConfiguration",\n' +
                        '        configurationPolicy = ConfigurationPolicy.OPTIONAL,\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "javax.portlet.name=" + ThemeTestPortletKeys.THEMETEST_PORTLET,\n' +
                        '        },\n' +
                        '        service = ConfigurationAction.class\n' +
                        ')\n' +
                        'public class ThemeTestConfigurationAction extends DefaultConfigurationAction {\n' +
                        '\n' +
                        '    private volatile ThemeTestConfiguration themeTestConfiguration;\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void processAction(\n' +
                        '            PortletConfig portletConfig, ActionRequest actionRequest,\n' +
                        '            ActionResponse actionResponse)\n' +
                        '            throws Exception {\n' +
                        '\n' +
                        '        String topic = ParamUtil.getString(actionRequest, "topic");\n' +
                        '        setPreference(actionRequest, "topic", topic);\n' +
                        '\n' +
                        '        String itemsPerPage = ParamUtil.getString(actionRequest, "itemsPerPage");\n' +
                        '        setPreference(actionRequest, "itemsPerPage", itemsPerPage);\n' +
                        '\n' +
                        '        super.processAction(portletConfig, actionRequest, actionResponse);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void include(\n' +
                        '            PortletConfig portletConfig, HttpServletRequest httpServletRequest,\n' +
                        '            HttpServletResponse httpServletResponse) throws Exception {\n' +
                        '\n' +
                        '        httpServletRequest.setAttribute(ThemeTestConfiguration.class.getName(), themeTestConfiguration);\n' +
                        '\n' +
                        '        super.include(portletConfig, httpServletRequest, httpServletResponse);\n' +
                        '    }\n' +
                        '\n' +
                        '    @Activate\n' +
                        '    @Modified\n' +
                        '    protected void activate(Map<Object, Object> properties) {\n' +
                        '        themeTestConfiguration = ConfigurableUtil.createConfigurable(ThemeTestConfiguration.class, properties);\n' +
                        '    }\n' +
                        '\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Resursele proiectului <b>\resources\content\Language.properties</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="properties" style={androidstudio}>
                        {'javax.portlet.title.ro_letyournailsgrow_liferay_vanilla_apps_themetest_ThemeTestPortlet=Theme Test\n' +
                        'themetest.caption=Hello from ThemeTest!'}
                    </SyntaxHighlighter>

                    Fisierul CSS <b>\resources\META-INF\resources\css\main.css</b>

                    <SyntaxHighlighter  showLineNumbers={true} language="css" style={androidstudio}>
                        {'.theme-test {\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>\resources\META-INF\resources\init.jsp</b>
                    <SyntaxHighlighter showLineNumbers={true} language="jsp" style={androidstudio}>
                        {'<%@ page import="ro.letyournailsgrow.liferay.vanilla.apps.themetest.configuration.ThemeTestConfiguration" %>\n' +
                        '<%@ page import="com.liferay.petra.string.StringPool" %>\n' +
                        '<%@ page import="com.liferay.portal.kernel.util.Validator" %>\n' +
                        '<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                        '\n' +
                        '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                        '\n' +
                        '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %><%@\n' +
                        'taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %><%@\n' +
                        'taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %><%@\n' +
                        'taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                        '\n' +
                        '<liferay-theme:defineObjects />\n' +
                        '\n' +
                        '<portlet:defineObjects />\n' +
                        '\n' +
                        '<%\n' +
                        '    ThemeTestConfiguration themeTestConfiguration =\n' +
                        '            (ThemeTestConfiguration)\n' +
                        '                    renderRequest.getAttribute(ThemeTestConfiguration.class.getName());\n' +
                        '\n' +
                        '    String topic = StringPool.BLANK;\n' +
                        '    int itemsPerPage = 10;\n' +
                        '    String [] topicList = new String[]{};\n' +
                        '\n' +
                        '    if (Validator.isNotNull(themeTestConfiguration)) {\n' +
                        '        topicList = portletPreferences.getValues("validTopic", themeTestConfiguration.validTopic());\n' +
                        '        topic = portletPreferences.getValue("topic", themeTestConfiguration.topic());\n' +
                        '        itemsPerPage = Integer.parseInt(portletPreferences.getValue("itemsPerPage", themeTestConfiguration.itemsPerPage()+""));\n' +
                        '    }\n' +
                        '%>'}
                    </SyntaxHighlighter>

                    Fisierul <b>\resources\META-INF\resources\view.jsp</b>
                    <SyntaxHighlighter showLineNumbers={true} language="jsp" style={androidstudio}>
                        {'<%@ page import="com.liferay.portal.kernel.util.Validator" %>\n' +
                        '<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<p>\n' +
                        '\t<b><liferay-ui:message key="themetest.caption"/></b>\n' +
                        '\n' +
                        '\t<%\n' +
                        '\t\tboolean noConfig = Validator.isNull(topic);\n' +
                        '\t%>\n' +
                        '\n' +
                        '\t<c:choose>\n' +
                        '\t\t<c:when test="<%= noConfig %>">\n' +
                        '\t\t\t<p>\n' +
                        '\t\t\t\tSetati un topic in sectiunea de configurarea a portletului.\n' +
                        '\t\t\t</p>\n' +
                        '\t\t</c:when>\n' +
                        '\n' +
                        '\t\t<c:otherwise>\n' +
                        '\t\t\t<p style="color: <%= topic %>">\n' +
                        '\t\t\t\tTopic: <%= topic %>!\n' +
                        '\t\t\t</p>\n' +
                        '\n' +
                        '\t\t\tArticole pe pagina: <%= itemsPerPage %>!\n' +
                        '\n' +
                        '\t\t</c:otherwise>\n' +
                        '\t</c:choose>\n' +
                        '\n' +
                        '</p>'}
                    </SyntaxHighlighter>

                    Interfata de configurare a portletului este definit in fisierul <b>\resources\META-INF\resources\configuration.jsp</b>
                    <SyntaxHighlighter showLineNumbers={true} language="jsp" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n' +
                        '\n' +
                        '<%@ page import="com.liferay.portal.kernel.util.Constants" %>\n' +
                        '\n' +
                        '<liferay-portlet:actionURL portletConfiguration="<%= true %>"\n' +
                        '                           var="configurationActionURL" />\n' +
                        '\n' +
                        '<liferay-portlet:renderURL portletConfiguration="<%= true %>"\n' +
                        '                           var="configurationRenderURL" />\n' +
                        '\n' +
                        '<aui:form action="<%= configurationActionURL %>" method="post" name="fm">\n' +
                        '\n' +
                        '    <aui:input name="<%= Constants.CMD %>" type="hidden"\n' +
                        '               value="<%= Constants.UPDATE %>" />\n' +
                        '\n' +
                        '    <aui:input name="redirect" type="hidden"\n' +
                        '               value="<%= configurationRenderURL %>" />\n' +
                        '\n' +
                        '<%--    <aui:fieldset>--%>\n' +
                        '\n' +
                        '<%--        <aui:select name="topic" label="Topic" value="<%= topic %>">--%>\n' +
                        '<%--            <aui:option value="java">Java</aui:option>--%>\n' +
                        '<%--            <aui:option value="python">Python</aui:option>--%>\n' +
                        '<%--            <aui:option value="javascript">Javacript</aui:option>--%>\n' +
                        '<%--        </aui:select>--%>\n' +
                        '\n' +
                        '<%--    </aui:fieldset>--%>\n' +
                        '\n' +
                        '    <aui:fieldset>\n' +
                        '\n' +
                        '        <aui:select name="topic" label="Topic" value="<%= topic %>">\n' +
                        '\n' +
                        '            <c:forEach items="<%= topicList %>" var="option">\n' +
                        '                <aui:option value="${option}" selected="${option==topic ? true : false }">${option}</aui:option>\n' +
                        '            </c:forEach>\n' +
                        '\n' +
                        '        </aui:select>\n' +
                        '\n' +
                        '    </aui:fieldset>\n' +
                        '\n' +
                        '    <aui:fieldset>\n' +
                        '\n' +
                        '        <aui:select name="itemsPerPage" label="Items Per Page"\n' +
                        '                    value="<%= itemsPerPage %>">\n' +
                        '            <aui:option value="10">10</aui:option>\n' +
                        '            <aui:option value="25">25</aui:option>\n' +
                        '            <aui:option value="50">50</aui:option>\n' +
                        '        </aui:select>\n' +
                        '\n' +
                        '    </aui:fieldset>\n' +
                        '\n' +
                        '    <aui:button-row>\n' +
                        '        <aui:button type="submit"></aui:button>\n' +
                        '    </aui:button-row>\n' +
                        '\n' +
                        '</aui:form>'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360017882392-Implementing-Configuration-Actions"}>
                                    Implementing Configuration Actions
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PortletConfigurationContent;