import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerComposeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-compose-yml", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Docker Compose: docker-compose.yml</b>

                    <br/>
                    <br/>

                    Docker Compose e un CLI, folosit pentru a porni mai multe containere Docker in acelasi timp.
                    <br/>
                    In plus, automatizeaza anumite argumente trimite catre <i>docker run</i>.

                    <br/>
                    Se foloseste un fisier <b>docker-compose.yml</b>.
                    <br/>
                    <br/>
                    De exemplu, in fisierul <i>docker-compose.yml</i> putem specifica:
                    <ul>
                        <li>
                            vreau sa se porneasca urmatoarele 2 containere:
                            <ul>
                                <li>
                                    redis-server
                                    <ul>
                                        <li>creaza-l folosind imaginea redis</li>
                                    </ul>
                                </li>
                                <li>
                                    node-app:
                                    <ul>
                                        <li>creaza-l folosind Dockerfile</li>
                                        <li>mapeaza portul 8081 la 8081</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Fisierul <i>docker-compose.yml</i>, va arata cam asa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  redis-server:\n' +
                            '    image: \'redis\'\n' +
                            '  node-app:\n' +
                            '    build: .\n' +
                            '    ports:\n' +
                            '      - \'4001:8081\'\n'}
                    </SyntaxHighlighter>

                    Creand servicii/containere de tip service in acest mod, folosind <i>docker-compose.yml</i>, aceste containere pot comunica intre ele.
                    Sunt vazute in aceeasi retea.
                    <br/>
                    Declarea porturilor <i>4001:8081</i> este pentru a putea fi vazut containerul din afara; cele 2 intre ele oricum se vad in mod implicit
                    (fiind declarate ca containere de tip service in <i>docker-compose.yml</i>)

                    <br/>
                    <br/>
                    Cand node-app va incerca sa se conecte la redis, ii va specifica:
                    <ul>
                        <li>
                            host: <i>redis-server</i> (in loc URL; deci numele serviciului)
                        </li>
                    </ul>

                    <b>Observatie:</b>
                    <br/>
                    Linia <b>build: .</b> specifica ca va folosi pentru build fisierul <b>Dockerfile</b> din directorul curent.


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerComposeContent;