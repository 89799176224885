import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OwnershipRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-ownership", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Ownership: intelegerea proprietatii</b>
                    <br/>
                    <br/>

                    Reguli de proprietate:
                    <ul>
                        <li>1. fiecare valoare are un proprietar (ownership)</li>
                        <li>2. nu poate exista decat un sigur proprietar la un moment da</li>
                        <li>3. cand proprietarul este din domeniul de aplicare (out of scope), valoarea este elimintata (dropped)</li>
                    </ul>

                    Memoria este eliberata automat odată ce variabila care o deține iese din domeniul de aplicare.
                    <br/>
                    Când o variabilă iese din domeniul de aplicare, Rust apelează o funcție specială numita <b>drop</b>.

                    <hr/>
                    Urmatorul cod, ca arunca o eroare, deoarece Rust împiedică să utilizați referința invalidată.
                    <br/>
                    După linia let s2 = s1;, Rust consideră ca s1 nu mai este valabilă.
                    <br/>
                    Prin urmare, Rust nu trebuie să elibereze nimic atunci când s1 iese din domeniul de aplicare.
                    <SyntaxHighlighter>
                        {'let s1 = String::from("hello");\n' +
                            'let s2 = s1; // s1 este invalidat!\n' +
                            '\n' +
                            'println!("{}, world!", s1);'}
                    </SyntaxHighlighter>

                    <hr/>
                    Dacă vrem să copiem în profunzime datele heap din String, nu doar datele stivei, putem folosi o metodă comună numită <b>clone</b>.

                    <SyntaxHighlighter>
                        {'let s1 = String::from("hello");\n' +
                            'let s2 = s1.clone();\n' +
                            '\n' +
                            'println!("s1 = {}, s2 = {}", s1, s2);'}
                    </SyntaxHighlighter>

                    <hr/>
                    In schimb, x si y sunt valabili:
                    <SyntaxHighlighter>
                        {'let x = 5;\n' +
                            'let y = x;\n' +
                            '\n' +
                            'println!("x = {}, y = {}", x, y);'}
                    </SyntaxHighlighter>

                    Tipurile primive precum numerele întregi care au o dimensiune cunoscută la momentul compilării sunt stocate în întregime pe stivă,
                    astfel încât copiile valorilor reale se realizează rapid.
                    <br/>
                    Asta înseamnă că nu există niciun motiv pentru care am dori să împiedicăm x să fie valid după ce creăm variabila y.

                    <hr/>
                    Transmiterii unei valori unei funcții este similară cu cea atunci când se atribuie o valoare unei variabile:
                    <SyntaxHighlighter>
                        {'let s = String::from("hello");  // s comes into scope\n' +
                            '\n' +
                            'my_function(s); // s nu mai e valid, dupa apelul functiei'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Referinte: &</b>
                    <br/>
                    <br/>

                    Fara referinta (vrem sa calculam lungimea unui sir de caractere; dupa apelul functiei, s1 nu mai e valabil)
                    <SyntaxHighlighter>
                        {'fn main() {\n' +
                            '    let s1 = String::from("hello");\n' +
                            '\n' +
                            '    let (s2, len) = calculate_length(s1);\n' +
                            '\n' +
                            '    println!("The length of \'{}\' is {}.", s2, len);\n' +
                            '}\n' +
                            '\n' +
                            'fn calculate_length(s: String) -> (String, usize) {\n' +
                            '    let length = s.len(); // len() returns the length of a String\n' +
                            '\n' +
                            '    (s, length)\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Cu referinta:
                    <SyntaxHighlighter>
                        {'fn main() {\n' +
                            '    let s1 = String::from("hello");\n' +
                            '\n' +
                            '    let len = calculate_length(&s1);\n' +
                            '\n' +
                            '    println!("The length of \'{}\' is {}.", s1, len);\n' +
                            '}\n' +
                            '\n' +
                            'fn calculate_length(s: &String) -> usize {\n' +
                            '    s.len()\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Referințele permit să vă referiți la o anumită valoare fără a vă apropia de ea!
                    <br/>
                    Sintaxa &s1 permite să creăm o referință care se referă la valoarea, s1 dar nu o deține.
                    Deoarece nu o deține, valoarea către care indică nu va fi abandonată/stersa/invalidata atunci când referința nu mai este utilizată.
                    <br/>
                    Semnătura funcției & indică faptul că tipul parametrului este o referință: <i>calculate_length(s: &String) </i>
                    <br/>
                    Opusul referințelor prin utilizarea & este <b>dereferencing</b>, care se realizează cu <b>operatorul dereference *</b>.

                    <br/>
                    <br/>
                    Așa cum variabilele sunt imuabile în mod implicit, la fel și referințele. Nu avem voie să modificăm ceva la care avem o referință.
                    <br/>
                    Urmatorul cod va arunca eroare:
                    <SyntaxHighlighter>
                        {'fn main() {\n' +
                            '    let s = String::from("hello");\n' +
                            '\n' +
                            '    change(&s);\n' +
                            '}\n' +
                            '\n' +
                            'fn change(some_string: &String) {\n' +
                            '    some_string.push_str(", world");\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a functiona, trebuie sa facem referinta mutabila <b>&mut</b>:
                    <SyntaxHighlighter>
                        {'fn main() {\n' +
                            '    let mut s = String::from("hello");\n' +
                            '\n' +
                            '    change(&mut s);\n' +
                            '}\n' +
                            '\n' +
                            'fn change(some_string: &mut String) {\n' +
                            '    some_string.push_str(", world");\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Referințele modificabile au o restricție mare: <b>dacă aveți o referință mutabilă la o valoare, nu puteți avea alte referințe la acea valoare!</b>
                    <br/>
                    Urmatorul cod va arunca eroare:
                    <SyntaxHighlighter>
                        {'    let mut s = String::from("hello");\n' +
                            '\n' +
                            '    let r1 = &mut s;\n' +
                            '    let r2 = &mut s;\n' +
                            '\n' +
                            '    println!("{}, {}", r1, r2);'}
                    </SyntaxHighlighter>

                    Urmatorul cod va arunca eroare (<b>nu putem avea o referință mutabilă în timp ce avem una imuabilă la aceeași valoare</b> si <b>sunt permise mai multe referințe imuabile,</b>):
                    <SyntaxHighlighter>
                        {'    let mut s = String::from("hello");\n' +
                            '\n' +
                            '    let r1 = &s; // no problem\n' +
                            '    let r2 = &s; // no problem\n' +
                            '    let r3 = &mut s; // BIG PROBLEM\n' +
                            '\n' +
                            '    println!("{}, {}, and {}", r1, r2, r3);'}
                    </SyntaxHighlighter>

                    Domeniul de aplicare al unei referințe începe de unde este introdusă și continuă până la ultima dată când referința este utilizată.
                    De exemplu, urmatorul cod se va compila deoarece ultima utilizare a referințelor imuabile, println!, are loc înainte ca referința mutabilă să fie introdusă:

                    <SyntaxHighlighter>
                        {'    let mut s = String::from("hello");\n' +
                            '\n' +
                            '    let r1 = &s; // no problem\n' +
                            '    let r2 = &s; // no problem\n' +
                            '    println!("{} and {}", r1, r2);\n' +
                            '    // variables r1 and r2 will not be used after this point\n' +
                            '\n' +
                            '    let r3 = &mut s; // no problem\n' +
                            '    println!("{}", r3);'}
                    </SyntaxHighlighter>

                    Domeniile referințelor imuabile r1 și r2 se termină după println! locul în care au fost utilizate ultima dată,
                    adică înainte ca referința mutabilă r3 să fie creată.
                    <br/>
                    Aceste domenii nu se suprapun, deci acest cod este permis: compilatorul poate spune că referința nu mai este utilizată într-un punct înainte de sfârșitul domeniului.

                    <hr/>
                    Urmatorul cod va arunca eroare:
                    <SyntaxHighlighter>
                        {'fn main() {\n' +
                            '    let reference_to_nothing = dangle();\n' +
                            '}\n' +
                            '\n' +
                            'fn dangle() -> &String {\n' +
                            '    let s = String::from("hello");\n' +
                            '\n' +
                            '    &s\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Deoarece s este creat în interiorul dangle, când codul de dangle este terminat, s va fi dealocat.
                    Dar am încercat să returnăm o referință la el. Aceasta înseamnă că această referință ar indica un String invalid!

                    <br/>
                    Cod valid (proprietatea este mutată și nimic nu este dealocat.):
                    <SyntaxHighlighter>
                        {'fn no_dangle() -> String {\n' +
                            '    let s = String::from("hello");\n' +
                            '\n' +
                            '    s\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Regulile de referință</b>
                    <br/>
                    <br/>

                    Reguli:
                    <ul>
                        <li>În orice moment, puteți avea fie o referință mutabilă , fie orice număr de referințe imuabile.</li>
                        <li>Referințele trebuie să fie întotdeauna valide.</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OwnershipRustContent;