import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ClassesJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-classes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Declararea unei clase</b>
                    <br/>
                    <br/>

                    Declararea unei clase are urmatoarea forma:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'[modificator de access] [specificator] class NumeClasa extends ParinteClasa { \n'+
                        '   // corp clasa\n'+
                        '}'}
                    </SyntaxHighlighter>

                    Modificatori de acces:
                    <ul>
                        <li><b>public</b>: clasa poate fi apelata de orice clasa</li>
                        <li>
                            <b><i>(default)</i>: clasa poate fi accessata doar de clase clase din acelasi pachet</b>
                        </li>
                    </ul>

                    O clasa poate fi:
                    <ul>
                        <li><b>top-level</b>: nu este in interiorul altei clase</li>
                        <li><b>inner</b>: o clasa in interiorul altei clase (clasele inner au si modificatorii de acces: private, protected)</li>
                    </ul>

                    Cuvantul cheie <b>this</b> se refera la instanta curenta si prin intermediul lui se pot accesa membri de instanta ai clasei (si membri mosteniti).
                    <br/>
                    Cuvantul cheie <b>super</b> se refera la instanta curenta si prin intermediul lui se pot accesa <b>doar membri mosteniti</b> (<b>exclude orice membru din clasa curenta</b>).

                    <hr/>
                    <b>2. Constructori</b>
                    <br/>
                    <br/>
                    Un constructor este o metoda speciala care:
                    <ul>
                        <li>are numele identic cu al clasei</li>
                        <li>nu returneaza nici o valoare (de fapt nu se specifica vrun tip de returnare)</li>
                        <li>este apeleat cand se creaza o instanta, prin apelarea operatorului <i>new</i></li>
                        <li>este creata automat (<i>constructor implicit</i>), daca nu este declarata explicit:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public class Clasa {\n' +
                                '    \n' +
                                '    public Clasa(){\n' +
                                '        super();\n' +
                                '    }\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    Pot exista mai multi constructori cu signaturi diferite. Din interiorul unui constructor se poate apela alt contructor folosind <b>this(<i>parametrii</i>)</b>.
                    Reguli <b>this()</b>:
                    <ul>
                        <li>trebuie sa fie <b>prima instructiune</b> din constructor</li>
                        <li>nu trebuie sa se autoapeleze sau sa creeze cicluri infinite</li>
                    </ul>

                    Dintr-un constructor se pot apela explict constructorii parintelui folosind <b>super(<i>parametri</i>)</b>.
                    Reguli <b>super()</b>:
                    <ul>
                        <li>trebuie sa fie <b>prima instructiune</b> din constructor</li>
                        <li>refera parintele direct</li>
                    </ul>

                    Alte reguli:
                    <ul>
                        <li>
                            daca prima instructiune a unui constructor nu este <b>this(<i>parametri</i>)</b> sau <b>super(<i>parametri</i>)</b>, atunci compilatorul va insera <b>super()</b> ca prima instructiune a constructorului.
                        </li>
                        <li>
                            daca se apleaza <b>this()</b> sau <b>super()</b> dupa prima instructiune, se va aruca exceptie
                        </li>
                        <li>
                            daca clasa parinte nu are un constructor fara parametri, atunci fiecare constructor al subclaselor trebuie sa incepa explict cu <b>this()</b> sau <b>super(); in caz contrar se va arunca exceptie</b>
                        </li>
                        <li>
                            o clasa cu constructor <b>private</b> nu poate fi extinsa
                        </li>
                        <li>
                            o clasa <b>final</b> nu poate fi extinsa
                        </li>
                        <li>
                            toate variabilele de instanta declarate ca fiind <b>final</b> trebuie sa i se asigneze o valoare, si <b>numai o data</b>, pana la terminarea de executat a constructorului; deci se poate asigna unde
                            <ul>
                                <li>unde este declarat</li>
                                <li>intr-un bloc de initializare</li>
                                <li>in constructor</li>
                            </ul>
                            asignare inseamna si atribuirea valorii <b>null</b>!

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public class Clasa {\n' +
                                '\n' +
                                '    final Integer x = null; // prima asignare\n' +
                                '    \n' +
                                '    public Clasa(){\n' +
                                '        x = 1; // eroare, pentru se considera a doua asingare\n' +
                                '    }\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Ordinea initializarii</b>
                    <br/>
                    <br/>

                    In momentul in care se creeaza o instanta a unei clase, ordinea in care se executa initializarile:
                    <ul>
                        <li>
                            o singura data, doar la crearea primei instante:
                            <ul>
                                <li>se initializeaza toate variabile statice, pornind de la prima superclasa, in ordinea in care apar</li>
                                <li>se proceseaza toate blocurile statice (de clasa), pornind de la prima superclasa, in ordinea in care apar</li>
                            </ul>
                        </li>
                       <li>
                           la fiecare creare de instanta noua:
                           <ul>
                               <li>daca exista o superclasa Y a lui X, atunci Y se initializeza intai</li>
                               <li>
                                   se initializeaza toate variabile de instanta, in ordinea in care apar
                               </li>
                               <li>
                                   se proceseaza toate blocurile de initializare (de instanta), in ordinea in care apar
                               </li>
                           </ul>

                       </li>

                        <li>se apelezeza constuctorul, apeland in cascada construtorii superclaselor</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Parinte {\n' +
                        '\n' +
                        '    private static int a = 1;\n' +
                        '    private static int b = 2;\n' +
                        '\n' +
                        '    private int c = 3;\n' +
                        '\n' +
                        '    {\n' +
                        '        System.out.println("c="+c);\n' +
                        '    }\n' +
                        '\n' +
                        '    static {\n' +
                        '        System.out.println("a="+a);\n' +
                        '    }\n' +
                        '\n' +
                        '    static {\n' +
                        '        System.out.println("b="+b);\n' +
                        '    }\n' +
                        '\n' +
                        '    public Parinte(){\n' +
                        '        System.out.println("parinte");\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Copil extends Parinte{\n' +
                        '\n' +
                        '    private static int x = 1;\n' +
                        '    private static int y = 2;\n' +
                        '\n' +
                        '    private int z = 3;\n' +
                        '\n' +
                        '    {\n' +
                        '        System.out.println("z="+z);\n' +
                        '    }\n' +
                        '\n' +
                        '    static {\n' +
                        '        System.out.println("y="+y);\n' +
                        '    }\n' +
                        '\n' +
                        '    static {\n' +
                        '        System.out.println("x="+x);\n' +
                        '    }\n' +
                        '\n' +
                        '    public Copil(){\n' +
                        '        System.out.println("copil");\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Test {\n' +
                        '\n' +
                        '    public static void main(String[] args) {\n' +
                        '\n' +
                        '        new Copil();\n' +
                        '\n' +
                        '        /* Rezultat:\n' +
                        '\n' +
                        '            a=1\n' +
                        '            b=2\n' +
                        '            y=2\n' +
                        '            x=1\n' +
                        '            c=3\n' +
                        '            parinte\n' +
                        '            z=3\n' +
                        '            copil\n' +
                        '\n' +
                        '         */\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClassesJavaContent;