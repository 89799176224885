import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class CanDeactivateAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-can-deactivate", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Guard: CanDeactivate</b>
                    <br/>
                    <br/>


                    Guard-ul de tip <i>CanDeactivate</i> notifica utilizatorii, dacă aceștia închid accidental pagina în mijlocul unei completări de date, de exemplu trimiterea unui formular.

                    <br/>
                    <br/>
                    Fie <i>can-deactivate.guard.ts</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { CanDeactivate } from \'@angular/router\';\n' +
                            'import { Observable } from \'rxjs\';\n' +
                            'import { Injectable } from \'@angular/core\';\n' +
                            '\n' +
                            'export interface CanComponentDeactivate {\n' +
                            '    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;\n' +
                            '}\n' +
                            '\n' +
                            '@Injectable({providedIn: \'root\'})\n' +
                            'export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {\n' +
                            '\n' +
                            '    canDeactivate(component: CanComponentDeactivate) {\n' +
                            '        return component.canDeactivate();\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In definirea rutelor:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const appRoutes: Routes = [\n' +
                            '  { path: \'contact\',\n' +
                            '   component: ContactComponent,\n' +
                            '   canDeactivate: [CanDeactivateGuard]\n' +
                            '  },\n' +
                            '];'}
                    </SyntaxHighlighter>

                    Exemplu de folosire:
                    <br/>
                    Fie <i>contact.component.html</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<form (submit)="onSubmit()">\n' +
                            '    <input placeholder="name" name="name" [(ngModel)]="user.name" />\n' +
                            '    <input placeholder="email" name="email" [(ngModel)]="user.email" />\n' +
                            '    <button type="submit">Submit</button>\n' +
                            '</form>'}
                    </SyntaxHighlighter>

                    Fie <i>contact.component.ts</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Component } from \'@angular/core\';\n' +
                            'import { Observable } from \'rxjs\';\n' +
                            'import { CanComponentDeactivate } from \'../can-deactivate.guard\';\n' +
                            '\n' +
                            '@Component({\n' +
                            '  selector: \'app-contact\',\n' +
                            '  templateUrl: \'./contact.component.html\',\n' +
                            '  styleUrls: [\'./contact.component.css\']\n' +
                            '})\n' +
                            '\n' +
                            'export class ContactComponent implements CanComponentDeactivate {\n' +
                            '  user = {\n' +
                            '    name: \'\',\n' +
                            '    email: \'\'\n' +
                            '  };\n' +
                            '\n' +
                            '  saved = false;\n' +
                            '\n' +
                            '  onSubmit() {\n' +
                            '    console.log(this.user.name, this.user.email);\n' +
                            '    this.saved = true;\n' +
                            '  }\n' +
                            '  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {\n' +
                            '    if ((this.user.name.length > 0 || this.user.email.length > 0)\n' +
                            '        && !this.saved) {\n' +
                            '      return confirm(\'Schimbarile nu sunt salvate! Esti sigur ca vrei sa parasesti pagina?\');\n' +
                            '    }\n' +
                            '    return true;\n' +
                            '  }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CanDeactivateAngularContent;