import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ApacheSSLLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-apache-ssl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Apache HTTPd cu SSL in fata ca proxy in fata unui Liferay</b>

                    <br/>
                    <br/>
                    Problema:
                    <ul>
                        <li>avem un server Liferay pornit pe http</li>
                        <li>avem un server ca proxy (Apache HTTPd) cu SSL</li>
                    </ul>

                    Pentru
                    <SyntaxHighlighter>
                        {'include-and-override=portal-developer.properties\n' +
                            'virtual.hosts.valid.hosts=localhost,127.0.0.1,83.103.89.182,172.16.15.100,punctunic.primarie.ro\n' +
                            '\n' +
                            'web.server.http.port=-1\n' +
                            'web.server.https.port=-1\n' +
                            '\n' +
                            'web.server.forwarded.host.enabled=true\n' +
                            'web.server.forwarded.host.header=X-Forwarded-Host\n' +
                            '\n' +
                            'web.server.forwarded.port.enabled=true\n' +
                            'web.server.forwarded.port.header=X-Forwarded-Port\n' +
                            '\n' +
                            'web.server.forwarded.protocol.enabled=true\n' +
                            'web.server.forwarded.protocol.header=X-Forwarded-Proto'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://liferay.dev/de/blogs/-/blogs/fronting-liferay-tomcat-with-apache-httpd-daemon-revisted"}>
                                    Fronting Liferay Tomcat with Apache HTTPd daemon Revisted
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ApacheSSLLiferayContent;