import React from "react";

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username:"",
            password:""
        };

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    submitLogin(e) {

        const formData = new FormData();

        formData.append('username', this.state.username);
        formData.append('password', this.state.password);

        fetch(process.env.REACT_APP_DOPAMINA_DATA_API_URL + '/public/auth/login', {
            method: 'POST',
            body:formData
        })
            .then(response => response.json())
            .then(jsondata => {

                if (jsondata.email && jsondata.jwt) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                   // localStorage.setItem('currentUser', JSON.stringify(jsondata.email));
                    localStorage.setItem("ACCESS_TOKEN_NAME", JSON.stringify(jsondata.jwt));
                    window.location.replace("/")
                }
            });
    }

    render() {
        return (
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5 login-form">

                            <div className="card-body text-left">
                                <h5 className="card-title">Autentificare</h5>

                                <form className="form-signin">

                                    <div className="input-group mt-2">
                                        {/*<label htmlFor="inputEmail">adresa email</label>*/}

                                        <input type="text" id="inputEmail"
                                               value={this.state.username} onChange={this.handleUsernameChange}
                                               className="form-control" placeholder="adresa email" required/>

                                        <div className="invalid-feedback">
                                            <div>Username is required</div>
                                        </div>
                                    </div>

                                    <div className="input-group mt-2">
                                        {/*<label htmlFor="password">parola</label>*/}
                                        <input type="password" id="password"
                                               value={this.state.password} onChange={this.handlePasswordChange}
                                               placeholder="parola" className="form-control"/>

                                        <div className="invalid-feedback">
                                            <div>Password is required</div>
                                        </div>
                                    </div>

                                    <div className="form-group mt-2">
                                        <button type={"button"}
                                                onClick={this.submitLogin.bind(this)}
                                                className="btn btn-lg btn-primary btn-block">Logare</button>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default LoginPage;