import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class CronJobObjectK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-cronjob-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Obiectul CronJob</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'vim cronjob.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: batch/v1\n' +
                            'kind: CronJob\n' +
                            'metadata:\n' +
                            '  name: sleepy\n' +
                            'spec:\n' +
                            '  schedule: "*/2 * * * *"\n' +
                            '  jobTemplate: \n' +
                            '    spec: \n' +
                            '      template:\n' +
                            '        spec:\n' +
                            '          containers:\n' +
                            '          - name: resting\n' +
                            '            image: busybox\n' +
                            '            command: ["/bin/sleep"]\n' +
                            '            args: ["3"]\n' +
                            '          restartPolicy: Never'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl create -f cronjob.yaml'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'job.batch/sleepy created'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get cronjobs'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME     SCHEDULE      SUSPEND   ACTIVE   LAST SCHEDULE   AGE\n' +
                            'sleepy   */2 * * * *   False     0        <none>          39s'}
                    </SyntaxHighlighter>

                    Dupa un timp (2 minute), job-urile vor porni
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {
                            'NAME              COMPLETIONS   DURATION   AGE\n' +
                            'sleepy-27935756   0/1           3s         3s\n'
                        }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get cronjobs'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'NAME     SCHEDULE      SUSPEND   ACTIVE   LAST SCHEDULE   AGE\n' +
                            'sleepy   */2 * * * *   False     0        68s             2m35s\n'}
                    </SyntaxHighlighter>


                    Apeland la momente diferite de timp:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get jobs'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME              COMPLETIONS   DURATION   AGE\n' +
                            'sleepy-27935756   1/1           8s         105s'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter>
                        {'NAME              COMPLETIONS   DURATION   AGE\n' +
                            'sleepy-27935756   1/1           8s         2m24s\n' +
                            'sleepy-27935758   1/1           10s        24s\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a ne asigura ca un job nu ruleaza mai mult de 10 secunde se foloseste <b>activeDeadlineSeconds</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: batch/v1\n' +
                            'kind: CronJob\n' +
                            'metadata:\n' +
                            '  name: sleepy\n' +
                            'spec:\n' +
                            '  schedule: "*/2 * * * *"\n' +
                            '  jobTemplate: \n' +
                            '    spec: \n' +
                            '      template:\n' +
                            '        spec:\n' +
                            '          activeDeadlineSeconds: 10\n'+
                            '          containers:\n' +
                            '          - name: resting\n' +
                            '            image: busybox\n' +
                            '            command: ["/bin/sleep"]\n' +
                            '            args: ["3"]\n' +
                            '          restartPolicy: Never'}
                    </SyntaxHighlighter>

                    Pentru a sterge:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete cronjobs.batch sleepy'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'cronjob.batch "sleepy" deleted'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CronJobObjectK8sContent;