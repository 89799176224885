import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FunctionsJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-functions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Definirea unei functii</b>
                    <br/>
                    <br/>
                    O functie este o bucata de cod, avand un nume (sau nu), care poate primi parametri (optional) si poate returna un rezultat.
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function nume_functie(parametri){\n' +
                        '   instructiuni\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Apelarea unei functii</b>
                    <br/>
                    <br/>
                    La definirea unei funcții, instrucțiunile conținute în funcție nu sunt executate. Pentru a executa funcția, trebuie <i>apelata</i>, folosind numele acesteia.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'nume_functie(argumente)'}
                    </SyntaxHighlighter>

                    Functii ar trebuie declarate inainte de a fi apelate (la inceptul codului), dar nu este obligatoriu:
                    declarațiile de funcții sunt mutate automat în partea de sus a scopului, astfel încât să le putem folosi înainte de declarație, atâta timp cât sunt în acelasi scop.

                    <hr/>
                    <b>3. Variabile locale</b>
                    <br/>
                    <br/>
                    In interiorul unei functii se pot declara variabile. Aceste sunt variabile locale, fiind vizibile doar in interiorul functiei.
                    <br/>
                    Variabilele declarate cu cuvântul cheie <b>let</b> sunt locale în interiorul blocului de cod (adică în intervalul limitat de acolade),
                    în timp ce variabilele declarate cu cuvântul cheie <b>var</b> sunt locale în interiorul intregii functii.

                    <hr/>
                    <b>4. Instructiunea return</b>
                    <br/>
                    <br/>

                    Instructiunea return este utila atunci cand:
                    <ul>
                        <li>se vrea iesirea (fortata) dintr-o functie (cu sau fara returnarea unui valori); instructiunile de dupa vor fi ignorate</li>
                        <li>se vrea returnarea unei valori</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function da(){\n' +
                            'return true;\n'+
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Parametrii</b>
                    <br/>
                    <br/>
                    Parametrii unei functii:
                    <ul>
                        <li>sunt optionali</li>
                        <li>apar în declarația acesteia</li>
                        <li>sunt nume separate prin virgule, plasate în paranteze după numele funcției</li>
                    </ul>

                    Fiecare parametru din interiorul unei funcții va fi tratat ca o <i>variabilă locală</i>.
                    O funcție a cărei definiție specifică parametrii trebuie invocată tinand cont de parametri si ordinea acestora.
                    <br/>
                    Valorile date în timpul unui apel se numesc <b>argumente</b>.
                    Argumentele sunt separate prin virgule și trebuie trecute în aceeași ordine ca parametrii definiți în declarația funcției.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function aduna(a, b) {\n' +
                        '     return a + b;\n' +
                        '}\n' +
                        'console.log(aduna(1,3));//4'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Umbrire / Shadowing</b>
                    <br/>
                    <br/>

                    O variabila locala/parametru va umbri o variabila globala cu acelasi nume (nu se va folosi variabila globala).

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let a = 7;\n' +
                        'function aduna(a, b) {\n' +
                        '     return a + b;\n' +
                        '}\n' +
                        'console.log(aduna(1,3));//4'}
                    </SyntaxHighlighter>
                    Parametrul functiei aduna <i>a</i> va umbri variabila globala <i>a</i>.

                    <hr/>
                    <b>7. Recursivitate</b>
                    <br/>
                    <br/>

                    O functie care se autoapeleaza se numeste <i>functie recursiva</i>.
                    La scrierea unei functii recursive trebuie sa fim atenti la conditia de oprire si la numarul de autoapeluri.

                    <hr/>
                    <b>7. Functiile ca membrii de prima clasă</b>
                    <br/>
                    <br/>

                    În JavaScript, funcțiile sunt membri de primă clasă<br/>
                    Funcțiile pot fi stocate în variabile sau transmise ca argumente altor funcții.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function aduna(a, b) {\n' +
                        '     return a + b;\n' +
                        '}\n' +
                        '\n' +
                        'let s = aduna(1,2);\n' +
                        'let f = aduna;\n' +
                        '\n' +
                        'console.log(typeof s);//number\n' +
                        'console.log(typeof f);//function'}
                    </SyntaxHighlighter>
                    Variabila <i>f</i> va retine o referinta catre functia <i>aduna</i> si se poate apela functia <i>aduna</i> prin intermediul variabilei <i>f</i> (<i>f</i> devine un alias pentru functia <i>aduna</i>):

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'console.log(f(2,4)); //6'}
                    </SyntaxHighlighter>
                    Asignarea unei functii la o variabila nu presupune si apelarea functiei.
                    <br/>
                    <br/>
                    O functie poate fi transmisa ca argument altei functii:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function aduna(a, b) {\n' +
                        '     return a + b;\n' +
                        '}\n' +
                        '\n' +
                        'function executa(f, a, b){\n' +
                        '  return f(a,b);\n' +
                        '}\n' +
                        '\n' +
                        'console.log(executa(aduna,1,2));//3'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Functii anonime</b>
                    <br/>
                    <br/>

                    Se poate atribui unei variabile direct o functie in 2 moduri:
                    <br/>
                    prin <i>functie expresie</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let variabila = function nume_functie(){...}'}
                    </SyntaxHighlighter>
                    sau prin <i>functie anonima</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let variabila = function(){...}'}
                    </SyntaxHighlighter>
                    O functie anonima poate sa nu fie atribuita unei variabile, ci creata direct (si fara nume) ca argument pentru o alta functie.
                    <br/>
                    <br/>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function aduna(a, b) {\n' +
                        '     return a + b;\n' +
                        '}\n' +
                        '\n' +
                        'let inmulteste = function(a, b){\n' +
                        '     return a * b;\n' +
                        '}\n' +
                        '\n' +
                        'function executa(f, a, b){\n' +
                        '  return f(a,b);\n' +
                        '}\n' +
                        '\n' +
                        'console.log(executa(aduna,1,2));//3\n' +
                        'console.log(executa(inmulteste,3,2));//6\n' +
                        'console.log(executa(function(a,b){\n' +
                        '  return a-b;\n' +
                        '},3,21));//-18'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Functii callback / Apel invers</b>
                    <br/>
                    <br/>
                    Functia C se numeste <i>callback</i>, daca functia C este transmisa ca argument altei funcții F, iar functia F apeleaza efectiv functia C.
                    <br/>
                    In exemplul de mai sus, functiile <i>aduna</i> sau <i>inmulteste</i> apelate din functia <i>executa</i>, sunt functii <i>callback</i>.
                    <br/>
                    <br/>
                    O functie functie callback poate fi:
                    <ul>
                        <li>sincrona (ca in exemplele de mai sus): executata în momentul apelului</li>
                        <li>asincrona
                            <br/>
                            pe partea client (care rulează într-un browser), JavaScriptt este limitat la programarea bazată pe evenimente, adică răspunsul asincron la anumite evenimente.
                            <br/>
                            Un eveniment poate fi:
                            <ul>
                                <li>un semnal trimis de un cronometru</li>
                                <li>o acțiune a utilizatorului (de exemplu: click pe un element din interfata sau apasarea unei taste)</li>
                                <li>informații la primirea datelor de la server</li>
                            </ul>

                            Functii asicrone:
                            <ul>
                                <li><b>setTimeout()</b>
                                    <br/>
                                    primeste ca argumente: o functie callback si timpul exprimat în milisecunde
                                    <br/>
                                    functia callback este executată după ce trece timpul specificat; (între timp, următoarea instrucțiune de program (plasată în cod după setTimeout) va fi executată)

                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'function asicron() {\n' +
                                        '     console.log("asicron");\n' +
                                        '}\n' +
                                        '\n' +
                                        'setTimeout(asicron,1000);\n' +
                                        'console.log(\'Gata!\');'}
                                    </SyntaxHighlighter>
                                </li>

                                <li><b>setInterval()</b>
                                    <br/>
                                    primeste ca argumente: o functie callback si timpul exprimat în milisecunde
                                    <br/>
                                    functia callback este executată cu intarzierea determinata de timpul dat ca argument, dar periodic, repetandu-se la intervale fixe (determinat de timpul dat ca argument)

                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'function asicron() {\n' +
                                        '     console.log("asicron");\n' +
                                        '}\n' +
                                        '\n' +
                                        'setInterval(asicron,1000);\n' +
                                        'console.log(\'Gata!\');'}
                                    </SyntaxHighlighter>
                                    Functia <i>asicron()</i> de mai sus se apela la nesfarsit.
                                    <br/>
                                    Funcția setInterval returnează un identificator în timpul apelului, care poate fi folosit pentru a elimina cronometrul folosit în ea (și, în consecință, pentru a opri apelul funcției callback),
                                    folosind metoda <b>clearInterval(identificatorFunctieInterval)</b>:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'function asicron() {\n' +
                                        '     console.log("asicron");\n' +
                                        '}\n' +
                                        '\n' +
                                        'let id = setInterval(asicron,1000);\n' +
                                        'console.log(\'Gata!\');\n' +
                                        '\n' +
                                        'setTimeout(function(){\n' +
                                        '  clearInterval(id);\n' +
                                        '},5000);'}
                                    </SyntaxHighlighter>
                                    Acum functia <i>asincron()</i> se va apela de 5 ori.
                                </li>

                                <li>
                                    <b>addEventListener()</b>
                                    <br/>
                                    obiectul <i>window</i> (asociat cu pagina web din browser) are metoda <i>addEventListener()</i>.
                                    <br/>

                                    Această funcție permite intregistrarea unui actiuni (functie callback) ce trebuie efectuata la un eveniment legat de fereastră (de exemplu, click).
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'window.addEventListener("click", function() {\n' +
                                        '    console.log("bau!");\n' +
                                        '});'}
                                    </SyntaxHighlighter>

                                </li>

                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>10. Functii arrow (sageti)</b>
                    <br/>
                    <br/>

                    O funcție arrow este o formă mai scurtă a unei funcții expresie.
                    <br/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'(parametri)=>{\n' +
                        '   bloc_instructiuni\n' +
                        '}'}
                    </SyntaxHighlighter>
                    O funcției arrow este compusă din:
                    <ul>
                        <li>aranteze care conțin de la zero la mai mulți parametri (dacă este prezent exact un parametru, parantezele pot fi omise)</li>
                        <li>o săgeată care arată astfel {"=>"}</li>
                        <li>corpul funcției, care poate fi înconjurat de paranteze dacă corpul este mai lung</li>
                        <li>dacă o funcție săgeată are o singură instrucțiune și returnează valoarea ei, putem omite cuvântul cheie return, deoarece acesta va fi adăugat implicit.</li>
                    </ul>

                    Sa presupunem ca avem functia expresie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let aduna = function(a, b) {\n' +
                        '    return a + b;\n' +
                        '}\n' +
                        'console.log(aduna(1, 2)); // -> 3'}
                    </SyntaxHighlighter>
                    Aceasta poate fi rescrisa, folosind functia sageta :
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let aduna = (a, b) => {\n' +
                        '    return a + b;\n' +
                        '}\n' +
                        'console.log(aduna(1, 2)); // -> 3'}
                    </SyntaxHighlighter>
                    Sau chiar mai simplificat, avand in vedere ca exista o singura instructiune:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let aduna = (a, b) => a + b;\n' +
                        '\n' +
                        'console.log(aduna(1, 2)); // -> 3'}
                    </SyntaxHighlighter>

                    <hr/>
                    Un exemplu de utilizare a <i>funcțiilor săgeată</i> este metoda <b>forEach</b>, disponibilă în datele de tip Array.
                    Această metodă ia ca argument o funcție, care va fi apelată de fiecare dată, pentru fiecare element al matricei.
                    Aceasta functie trebuie sa aiba cel putin un parametru, care va fi tratate ca elementul curent vizitat al matricii/vectorului.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let v = [\'A\', \'B\', \'C\'];\n' +
                        'function show(element) {\n' +
                        '    console.log(element);\n' +
                        '}\n' +
                        'v.forEach(show); // -> A,B,C'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionsJavaScriptContent;