import {useRoutes} from "react-router-dom";
import IndexContent from "./IndexContent";
import React from "react";
import PiperWindowsTtsContent from "./PiperWindowsTtsContent";
import UtilTtsContent from "./UtilTtsContent";
import PrivateRoute from "../../security/PrivateRoute";
import PiperRi5TtsContent from "./PiperRi5TtsContent";
import EspeakRi5TtsContent from "./EspeakRi5TtsContent";
import GTTSRi5TtsContent from "./GTTSRi5TtsContent";
import Pyttsx3Ri5TtsContent from "./Pyttsx3Ri5TtsContent";
import PiperPythonRi5TtsContent from "./PiperPythonRi5TtsContent";


export default function Router() {
    let element = useRoutes([
        {path: "/tts/index", element: <IndexContent/>},
        {path: "/tts/piper-windows", element: <PrivateRoute element={<PiperWindowsTtsContent/>}/>},
        {path: "/tts/piper-pi5", element: <PrivateRoute element={<PiperRi5TtsContent/>}/>},
        {path: "/tts/espeak-pi5", element: <PrivateRoute element={<EspeakRi5TtsContent/>}/>},
        {path: "/tts/gtts-pi5", element: <PrivateRoute element={<GTTSRi5TtsContent/>}/>},
        {path: "/tts/pyttsx3-pi5", element: <PrivateRoute element={<Pyttsx3Ri5TtsContent/>}/>},
        {path: "/tts/piper-python-pi5", element: <PrivateRoute element={<PiperPythonRi5TtsContent/>}/>},
        {path: "/tts/util", element: <PrivateRoute element={<UtilTtsContent/>}/>},

    ]);

    return element;
}