import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ClayManagementToolbarLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-clay-management-toolbar", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Cautare in Search Container (clay:management-toolbar)</b>
                    <br/>
                    <br/>

                    Pentru input-ul de cautare (search) se poate folosi <b>clay:management-toolbar</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'  <clay:management-toolbar cssClass="nomenclator-management-bar"\n' +
                            '                             clearResultsURL="<%= ibridRepositoryToolbarDisplayContext.getClearResultsURL() %>"\n' +
                            '                             searchActionURL="<%= ibridRepositoryToolbarDisplayContext.getSearchActionURL() %>"\n' +
                            '                             searchContainerId="nomenclatorSearch"\n' +
                            '                             searchFormName="fm"\n' +
                            '                             selectable="<%= false %>"\n' +
                            '                             itemsTotal="<%= mySearchContainer.getTotal() %>"\n' +
                            '                             showSearch="<%= true %>"\n' +
                            '                             sortingOrder="<%= mySearchContainer.getOrderByType() %>"\n' +
                            '                             sortingURL=""\n' +
                            '    />'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <b>searchContainerId</b> = id-ul componentei <b>liferay-ui:search-container</b>
                        </li>
                        <li>
                            <b>searchFormName</b> = valoare de la <b>name</b> al tag-ului <b>aui:form</b>
                        </li>
                    </ul>

                    Filtrare efectiva se face prin intermediul metodei:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'RepositoryMetadataLocalServiceUtil.searchRepositoryMetadata(keywords, mySearchContainer.getStart(), mySearchContainer.getEnd())'}
                    </SyntaxHighlighter>
                    definite custom:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                            '\tpublic List<RepositoryMetadata> searchRepositoryMetadata(String keywords, int start, int end) {\n' +
                            '\t\tDynamicQuery dQ = DynamicQueryFactoryUtil.forClass(RepositoryMetadata.class, getClass().getClassLoader());\n' +
                            '\n' +
                            '\t\tif (keywords != null && !keywords.isEmpty()) {\n' +
                            '\t\t\tCriterion c1 = RestrictionsFactoryUtil.ilike("nume", "%" + keywords + "%");\n' +
                            '\t\t\tCriterion c2 = RestrictionsFactoryUtil.ilike("cnp", "%" + keywords + "%");\n' +
                            '\n' +
                            '\t\t\tCriterion cOr1 = RestrictionsFactoryUtil.or(c1, c2);\n' +
                            '\n' +
                            '\t\t\tLong an = null;\n' +
                            '\t\t\ttry{\n' +
                            '\t\t\t\tan = Long.parseLong(keywords);\n' +
                            '\t\t\t}catch (Exception e){\n' +
                            '\t\t\t\t//e.printStackTrace();\n' +
                            '\t\t\t}\n' +
                            '\t\t\tif (an!=null) {\n' +
                            '\t\t\t\tCriterion c3 = RestrictionsFactoryUtil.eq("an", an);\n' +
                            '\t\t\t\tCriterion cOr2 = RestrictionsFactoryUtil.or(cOr1, c3);\n' +
                            '\t\t\t\tdQ.add(cOr2);\n' +
                            '\t\t\t}else{\n' +
                            '\t\t\t\tdQ.add(cOr1);\n' +
                            '\t\t\t}\n' +
                            '\n' +
                            '\t\t}\n' +
                            '\n' +
                            '\t\treturn repositoryMetadataPersistence.findWithDynamicQuery(dQ, start, end);\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Codul complet (functioneza si cautare si paginarea):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ page import="com.liferay.portal.kernel.dao.search.SearchContainer" %>\n' +
                            '<%@ page import="com.liferay.portal.kernel.language.LanguageUtil" %>\n' +
                            '<%@ page import="com.liferay.portal.kernel.util.ParamUtil" %>\n' +
                            '<%@ page import="ro.ibrid.repository.portlet.IbridRepositoryToolbarDisplayContext" %>\n' +
                            '<%@ page import="ro.ibrid.repository.servicebuilder.model.RepositoryMetadata" %>\n' +
                            '<%@ page import="ro.ibrid.repository.servicebuilder.service.RepositoryMetadataLocalServiceUtil" %>\n' +
                            '<%@ page import="java.util.List" %>\n' +
                            '<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<liferay-portlet:renderURL varImpl="iteratorURL"/>\n' +
                            '<liferay-portlet:renderURL varImpl="portletURL"/>\n' +
                            '\n' +
                            '<%\n' +
                            '    String keywords = ParamUtil.getString(request, "keywords", "");\n' +
                            '    \n' +
                            '    SearchContainer<RepositoryMetadata> mySearchContainer = new SearchContainer<RepositoryMetadata>(renderRequest, null, null,\n' +
                            '            SearchContainer.DEFAULT_CUR_PARAM, SearchContainer.DEFAULT_DELTA, iteratorURL, null, "");\n' +
                            '   \n' +
                            '    mySearchContainer.setResultsAndTotal(RepositoryMetadataLocalServiceUtil.searchRepositoryMetadata(keywords, mySearchContainer.getStart(), mySearchContainer.getEnd()));\n' +
                            '\n' +
                            '    mySearchContainer.setEmptyResultsMessage(LanguageUtil.get(request, "label.noResults"));\n' +
                            '\n' +
                            '    IbridRepositoryToolbarDisplayContext ibridRepositoryToolbarDisplayContext = new IbridRepositoryToolbarDisplayContext(renderRequest, renderResponse);\n' +
                            '\n' +
                            '%>\n' +
                            '\n' +
                            '<div class="ibrid-repository search-container">\n' +
                            '\n' +
                            '    <clay:management-toolbar cssClass="nomenclator-management-bar"\n' +
                            '                             clearResultsURL="<%= ibridRepositoryToolbarDisplayContext.getClearResultsURL() %>"\n' +
                            '                             searchActionURL="<%= ibridRepositoryToolbarDisplayContext.getSearchActionURL() %>"\n' +
                            '                             searchContainerId="nomenclatorSearch"\n' +
                            '                             searchFormName="fm"\n' +
                            '                             selectable="<%= false %>"\n' +
                            '                             itemsTotal="<%= mySearchContainer.getTotal() %>"\n' +
                            '                             showSearch="<%= true %>"\n' +
                            '                             sortingOrder="<%= mySearchContainer.getOrderByType() %>"\n' +
                            '                             sortingURL=""\n' +
                            '    />\n' +
                            '\n' +
                            '    <aui:form action="<%=portletURL.toString()%>" cssClass="container-fluid container-fluid-max-xl container-form-view"\n' +
                            '              method="post" name="fm">\n' +
                            '\n' +
                            '        <liferay-ui:search-container id="nomenclatorSearch" searchContainer="<%=mySearchContainer%>"\n' +
                            '                                     iteratorURL="<%=iteratorURL%>">\n' +
                            '            <liferay-ui:search-container-results results="<%=mySearchContainer.getResults()%>"/>\n' +
                            '            <liferay-ui:search-container-row\n' +
                            '                    className="ro.ibrid.repository.servicebuilder.model.RepositoryMetadata"\n' +
                            '                    keyProperty="requestTypeId" modelVar="entry" indexVar="indexNumber">\n' +
                            '\n' +
                            '                <liferay-ui:search-container-column-text name="#" cssClass="index-width"\n' +
                            '                                                         value="<%=String.valueOf(searchContainer.getStart()+1+indexNumber)%>"/>\n' +
                            '\n' +
                            '                <liferay-ui:search-container-column-text property="numePrenume" name="Nume"/>\n' +
                            '                <liferay-ui:search-container-column-text property="cnp" name="CNP"/>\n' +
                            '                <liferay-ui:search-container-column-text property="an" name="An emitere document"/>\n' +
                            '\n' +
                            '\n' +
                            '                <liferay-ui:search-container-column-text name="Descarcare">\n' +
                            '\n' +
                            '                    <portlet:resourceURL var="downloadResourceURL">\n' +
                            '                        <portlet:param name="documentId" value="<%=entry.getRepositoryMetadataId()+""%>"/>\n' +
                            '                    </portlet:resourceURL>\n' +
                            '\n' +
                            '                    <div class="custom-actions" >\n' +
                            '                        <aui:button href="${downloadResourceURL}"\n' +
                            '                                    title=\'Descarcare\'\n' +
                            '                                    cssClass="btn-info btn-align" icon="icon-download icon-1x text-white" iconAlign="right"\n' +
                            '                                    value="Descarca document"/>\n' +
                            '                    </div>\n' +
                            '\n' +
                            '                </liferay-ui:search-container-column-text>\n' +
                            '\n' +
                            '            </liferay-ui:search-container-row>\n' +
                            '            <liferay-ui:search-iterator markupView="lexicon"/>\n' +
                            '        </liferay-ui:search-container>\n' +
                            '    </aui:form>\n' +
                            '\n' +
                            '</div>\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>*/}
                    {/*                Writing a Form Storage Adapter*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClayManagementToolbarLiferayContent;