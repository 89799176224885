import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class GenerateReactCLIReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-generate-react-cli", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. generate-react-cli</b>
                    <br/>
                    <br/>

                    React, in mod implicit nu are! (pentru ca e prea simplu 🤯 ).
                    <br/>
                    Dar, se poate folosi de exemplu: <a target={"_blank"} href={"https://www.npmjs.com/package/generate-react-cli"}>Generate React CLI</a>!

                    <br/>
                    <br/>
                    Instalare <b>generate-react-cli</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm i generate-react-cli'}
                    </SyntaxHighlighter>

                    Generare componenta:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npx generate-react-cli component Box'}
                    </SyntaxHighlighter>

                    Structura generata:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'|-- /src\n' +
                            '    |-- /components\n' +
                            '        |-- /Box\n' +
                            '            |-- Box.js\n' +
                            '            |-- Box.css\n' +
                            '            |-- Box.test.js'}
                    </SyntaxHighlighter>

                    Acesta poate genera componente folosind IA!!!
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npx generate-react-cli@alpha c Counter -d "Create a counter component that increments by one when I click on the increment button"'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GenerateReactCLIReactContent;