import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../elasticsearch/IndexContent";

class IntroElasticsearchContent extends BaseContentPage  {

    constructor(props) {
        super(props, "elasticsearch-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Concepte</b>:
                    <br/>
                    <br/>

                    Un <b>document</b> este o <b>structura JSON cu un ID unic si un tip</b>.
                    <br/>
                    Indecsi imbunatatesc cautarea, contin:
                    <ul>
                        <li>
                            <b>indexcsi inversi (inverted indices)</b>
                        </li>
                        <li>
                            <b>mapari (mappings)</b> care definesc schema pentru date
                        </li>
                    </ul>

                    Un index poate fi vazut ca o tabela, iar un document ca un rand dintr-o tabela, iar un cluster o baza de date.

                    <hr/>

                    <b>2. Index invers (inverted index)</b>
                    <br/>
                    <br/>
                    De exemplu, daca avem:
                    <ul>
                        <li>documentul 1 cu urmatorul continut: "Space: The final frontier." </li>
                        <li>documentul 2 cu urmatorul continut: "He's bad, he's number one. He's the space cowboy" </li>
                    </ul>
                    Atunci inverted index, este o mapa de genul:
                    <ul>
                        <li>space: 1,2 (adica apare in documentul 1 si 2)</li>
                        <li>the: 1,2</li>
                        <li>final: 1 (adica apare doar in documentul 1)</li>
                        <li>frontier: 1</li>
                        <li>he: 2</li>
                        <li>bad: 2</li>
                        <li>......</li>
                    </ul>
                    In practica, pentru fiecare cuvand nu se retine doar documentul in care apare, dar si alte detalii, precum pozitia in document.

                    Pentru normalizare:
                    <ul>
                        <li>
                            Fiecare document este spart in termeni individuali/cuvinte (ex: space, the, final).
                        </li>
                        <li>
                            Fiecare cuvant este transformat in litere mici (<i>lowercase</i>).
                        </li>
                    </ul>


                    <hr/>

                    <b>3. TF-IFD</b>
                    <br/>
                    <br/>

                    <b>TF-IFD</b> inseamna <b>Term Frequency * Inverse Document Frequency</b>.
                    <br/>
                    <b>Term Frequency</b> = cat de des apare un termen intr-un document dat.
                    <b>Document Frequency</b> = cat de des apare un termen in toate documentele.

                    <b>Term Frequency / Document Frequency</b> masoara <b>relevanta</b> unui termen intr-un document (cat de special este un termen intr-un document).
                    <br/>
                    Raspunsul unui cautari pentru un termen, de obicei, se da in functie de <i>relevanta</i> acelui termen.

                    <hr/>

                    <b>4. Despre schema</b>
                    <br/>
                    <br/>

                    Schema unui document este definit de index (de fapt de tipul de document asociat cu index-ul).
                    <br/>
                    Index-ul, fiind vazut ca o tabela, defineste campurile individuale si tipurile de date asociate campurilor.

                    <hr/>

                    <b>5. Primary Shards. Replicas</b>
                    <br/>
                    <br/>

                    Daca avem 5 primary shards si 3 replicas, vom avea 20 Shards in total (5x3=15+5).

                    {/*<SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>*/}
                    {/*    {''}*/}
                    {/*</SyntaxHighlighter>*/}


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroElasticsearchContent;