import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AbstractClassPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-abstract-class", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    O <b>clasa abastracta</b> este un model pentru alte clase, ca un fel de contract intre cel care proiecteaza clasa si programator.
                    <br/><br/>
                    Cel care proiecteaza clasa stabileste cerintele metodelor fara implementare. O metoda fara implementare se numeste <b>metoda abstracta</b>.
                    Programatorul implementeaza metodele ( adica furnizeaza <i>implementare (carne)</i> pentru metodele abstracte).
                    Deci, <i>furnizeaza definitiile metodelor prin suprascrierea declaratiilor metodelor primite de la proiectantul clasei</i>.

                    <br/><br/>
                    <i>Contractul</i> va <b>asigura</b> ca o clasa copil construita pe baza unui clase abstracte va avea metodele concrete (implementate) <b>impuse</b> de clasa abastracta.

                    <br/>  <br/>

                    O clasa care contine una sau mai multe metode abstracte, se numeste <b>clasa abstracta</b>. O clasa abastracta poate contine si clase concrete (implementate).

                    <br/>  <br/>

                    Existenta claselor abastracte este justificata prin polimorfism (subclasele neabstracte <b>trebuie</b> sa furnizeze implementarii proprii pentru metodele abastracte, folosind nume de metode declarate in contract).

                    <br/>  <br/>

                    O metodă abstractă este o metodă care are o declarație, dar nu are nicio implementare.

                    <br/>  <br/>

                    O clasa abastracta <b>nu se poate instantia</b> si are nevoie de subclase pentru a furniza implementări metodele abstracte declarate în clasele abstracte.

                    <br/>  <br/>

                    Pentru a defini metode abstracte este nevoie de:
                    <ul>
                        <li>
                            import modul: <b>abc</b> (Abstract Base Classes - ABC )
                        </li>
                        <li>
                            clasa de bază să moștenească clasa <b>ABC</b>
                        </li>
                        <li>
                            folosirea decoratorului: <b>@abstractmethod</b> (pentru a marca ca o metoda este abstracta)
                        </li>
                    </ul>


                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import abc\n' +
                        '\n' +
                        'class ClasaAbastracta(abc.ABC):\n' +
                        '    @abc.abstractmethod\n' +
                        '    def metodaAbstracta(self):\n' +
                        '        pass\n' +
                        '\n' +
                        '\n' +
                        'ca = ClasaAbastracta()\n' +
                        'ca.metodaAbstracta()\n'}
                    </SyntaxHighlighter>

                    Eroarea obtinuta, daca se incerca instantierea unei clase abstracte:
                    <SyntaxHighlighter>
                        {'Traceback (most recent call last):\n' +
                        '  File "main.py", line 9, in <module>\n' +
                        '    ca = ClasaAbastracta()\n' +
                        'TypeError: Can\'t instantiate abstract class ClasaAbastracta with abstract methods metodaAbstracta'}
                    </SyntaxHighlighter>

                    Daca extindem ClasaAbstracta si uitam sa implementam metoda abstracta vom obtine acelasi rezultat (eroare de tip TypeError).

                    <br/><br/>
                    O moștenire multiplă din clase abstracte trebui să suprascrie toate metodele abstracte moștenite din superclasele sale.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AbstractClassPythonContent;