import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class UserDefinedBridgeNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-user-defined-bridge", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>
                        1. Creare retea de tip user-defined bridge
                    </b>
                    <br/>
                    <br/>

                    Exista diferente intre retea bridge si reteaua user-defined bridge:
                    <ul>
                        <li>
                            user-defined bridge ofera o mai multa izolare si interoperabilitate intre aplicatiile de tip container
                        </li>
                        <li>
                            user-defined bridge ofera <b>rezolutie DNS automata</b> intre containere
                        </li>
                        <li>
                            containere pot fi atasate/detasate de la o retea user-defined bridge <i>on the fly</i>
                        </li>
                        <li>
                            fiecare retea user-defined bridge creaza un bridge configurabil
                        </li>
                        <li>
                            containerele conectate in reteaua bridge partajeaza variabile de mediu
                        </li>
                    </ul>

                    Creare retea user-defined bridge:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --driver=bridge kj-net-bridge'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create kj-net-bridge'}
                    </SyntaxHighlighter>
                    (pentru ca driver=bridge, in mod implicit)

                    <br/>
                    <br/>

                    Inspectare noua retea creata:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect kj-net-bridge'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "kj-net-bridge",\n' +
                            '        "Id": "b219f7a8e79c5e70cfedb33c9634643fb584d6247b2deeb2ffd4f7bc08ae59bb",\n' +
                            '        "Created": "2022-12-30T22:49:20.907134602+02:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": {},\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.26.0.0/16",\n' +
                            '                    "Gateway": "172.26.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {},\n' +
                            '        "Options": {},\n' +
                            '        "Labels": {}\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>
                    Gateway este 172.17.0.1.

                    <hr/>
                    Daca rulam din noua pe masina gazada:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ifconfig'}
                    </SyntaxHighlighter>
                    vom regasi:
                    <SyntaxHighlighter>
                        {'br-b219f7a8e79c: flags=4099<UP,BROADCAST,MULTICAST>  mtu 1500\n' +
                            '        inet 172.26.0.1  netmask 255.255.0.0  broadcast 172.26.255.255\n' +
                            '        ether 02:42:86:f9:ea:fa  txqueuelen 0  (Ethernet)\n' +
                            '        RX packets 0  bytes 0 (0.0 B)\n' +
                            '        RX errors 0  dropped 0  overruns 0  frame 0\n' +
                            '        TX packets 0  bytes 0 (0.0 B)\n' +
                            '        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0\n'}
                    </SyntaxHighlighter>
                    Deci <i>br-b219f7a8e79c</i> are inet 172.26.0.1.

                    <hr/>
                    Ca o observatie, toate retele de tipul <b>veth</b>69af4bf sunt asociate cu un container:
                    <SyntaxHighlighter>
                        {'veth69af4bf: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500\n' +
                            '        inet6 fe80::600b:15ff:fe15:26cb  prefixlen 64  scopeid 0x20<link>\n' +
                            '        ether 62:0b:15:15:26:cb  txqueuelen 0  (Ethernet)\n' +
                            '        RX packets 7710  bytes 593021 (593.0 KB)\n' +
                            '        RX errors 0  dropped 0  overruns 0  frame 0\n' +
                            '        TX packets 18193  bytes 26353294 (26.3 MB)\n' +
                            '        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0'}
                    </SyntaxHighlighter>

                    Ca sa pornim un container direct conectat la o retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-bridge --network=kj-net-bridge busybox'}
                    </SyntaxHighlighter>

                    Inspectare retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect kj-net-bridge'}
                    </SyntaxHighlighter>

                    Vom gasi un container conectat, cu numele <i>busybox-bridge</i>:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "kj-net-bridge",\n' +
                            '        "Id": "b219f7a8e79c5e70cfedb33c9634643fb584d6247b2deeb2ffd4f7bc08ae59bb",\n' +
                            '        "Created": "2022-12-30T22:49:20.907134602+02:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": {},\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.26.0.0/16",\n' +
                            '                    "Gateway": "172.26.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {\n' +
                            '            "b7e5f265c880cf0e1a3a04766376f8eaa344cf589fed7a871ff93df29742ab94": {\n' +
                            '                "Name": "busybox-bridge",\n' +
                            '                "EndpointID": "96bf00d93fcaeb5b7e2eec10ce6c8c9ade780c620d25fff5241093d24e918445",\n' +
                            '                "MacAddress": "02:42:ac:1a:00:02",\n' +
                            '                "IPv4Address": "172.26.0.2/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n' +
                            '        "Options": {},\n' +
                            '        "Labels": {}\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a testa rezolutia DNS, mai pornim un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-bridge-2 --network=kj-net-bridge busybox'}
                    </SyntaxHighlighter>

                    deci:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps | grep busybox-bridge'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'9e84024d31c4   busybox                               "sh"                     59 seconds ago   Up 44 seconds                                                                                                                            \n' +
                            '              busybox-bridge-2\n' +
                            'b7e5f265c880   busybox                               "sh"                     5 minutes ago    Up 4 minutes                                                                                                                             \n' +
                            '              busybox-bridge\n'}
                    </SyntaxHighlighter>

                    ne conectam la <i>busybox-bridge</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container exec -it busybox-bridge sh'}
                    </SyntaxHighlighter>
                    si testam cu ping:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ping busybox-bridge-2'}
                    </SyntaxHighlighter>
                    care va afisa ceva de genul:
                    <SyntaxHighlighter>
                        {'PING busybox-bridge-2 (172.26.0.3): 56 data bytes\n' +
                            '64 bytes from 172.26.0.3: seq=0 ttl=64 time=0.174 ms\n' +
                            '64 bytes from 172.26.0.3: seq=1 ttl=64 time=0.132 ms'}
                    </SyntaxHighlighter>

                    <hr/>
                    Ne putem deconecta de la retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network disconnect kj-net-bridge busybox-bridge'}
                    </SyntaxHighlighter>
                    Dupa ce ne deconectam, nu o sa mai fie in nici o retea.
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UserDefinedBridgeNetworkDockerContent;