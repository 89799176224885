import {useRoutes} from "react-router-dom";
import React from "react";
import LinuxHomeContent from "./IndexContent";
import DateLinuxContent from "./DateLinuxContent";
import DfLinuxContent from "./DfLinuxContent";
import FreeLinuxContent from "./FreeLinuxContent";
import PwdLinuxContent from "./PwdLinuxContent";
import LsLinuxContent from "./LsLinuxContent";
import CdLinuxContent from "./CdLinuxContent";
import OSReleaseLinuxContent from "./OSReleaseLinuxContent";
import LsbReleaseLinuxContent from "./LsbReleaseLinuxContent";
import UnameLinuxContent from "./UnameLinuxContent";
import HostnameLinuxContent from "./HostnameLinuxContent";
import EnvLinuxContent from "./EnvLinuxContent";
import CleanLinuxContent from "./CleanLinuxContent";
import UptimeLinuxContent from "./UptimeLinuxContent";
import WLinuxContent from "./WLinuxContent";
import EchoLinuxContent from "./EchoLinuxContent";
import ManLinuxContent from "./ManLinuxContent";
import WhichLinuxContent from "./WhichLinuxContent";
import MkdirLinuxContent from "./MkdirLinuxContent";
import RmLinuxContent from "./RmLinuxContent";
import PsLinuxContent from "./PsLinuxContent";
import TopLinuxContent from "./TopLinuxContent";
import KillLinuxContent from "./KillLinuxContent";
import HtopLinuxContent from "./HtopLinuxContent";
import StreamLinuxContent from "./StreamLinuxContent";
import PipingLinuxContent from "./PipingLinuxContent";
import CatLinuxContent from "./CatLinuxContent";
import VimLinuxContent from "./VimLinuxContent";
import NanoLinuxContent from "./NanoLinuxContent";
import CpLinuxContent from "./CpLinuxContent";
import HistoryLinuxContent from "./HistoryLinuxContent";
import MvLinuxContent from "./MvLinuxContent";
import HeadLinuxContent from "./HeadLinuxContent";
import TailLinuxContent from "./TailLinuxContent";
import MoreLinuxContent from "./MoreLinuxContent";
import GrepLinuxContent from "./GrepLinuxContent";
import HardSoftLinksLinuxContent from "./HardSoftLinksLinuxContent";
import LnLinuxContent from "./LnLinuxContent";
import FindLinuxContent from "./FindLinuxContent";
import XargLinuxContent from "./XargLinuxContent";
import TarLinuxContent from "./TarLinuxContent";
import GzipLinuxContent from "./GzipLinuxContent";
import FileLinuxContent from "./FileLinuxContent";
import SortLinuxContent from "./SortLinuxContent";
import AliasLinuxContent from "./AliasLinuxContent";
import DuLinuxContent from "./DuLinuxContent";
import WhoamiLinuxContent from "./WhoamiLinuxContent";
import UseraddLinuxContent from "./UseraddLinuxContent";
import AdduserLinuxContent from "./AdduserLinuxContent";
import SuLinuxContent from "./SuLinuxContent";
import PasswdLinuxContent from "./PasswdLinuxContent";
import UserdelLinuxContent from "./UserdelLinuxContent";
import CatEtcPasswdLinuxContent from "./CatEtcPasswdLinuxContent";
import ChownLinuxContent from "./ChownLinuxContent";
import ChmodLinuxContent from "./ChmodLinuxContent";
import ExportLinuxContent from "./ExportLinuxContent";
import ScriptLinuxContent from "./ScriptLinuxContent";
import PingLinuxContent from "./PingLinuxContent";
import IpLinuxContent from "./IpLinuxContent";
import IfconfigLinuxContent from "./IfconfigLinuxContent";
import NslookupLinuxContent from "./NslookupLinuxContent";
import SshLinuxContent from "./SshLinuxContent";
import TracerouteLinuxContent from "./TracerouteLinuxContent";
import WhoisLinuxContent from "./WhoisLinuxContent";
import CurlLinuxContent from "./CurlLinuxContent";
import WgetLinuxContent from "./WgetLinuxContent";
import UnsetLinuxContent from "./UnsetLinuxContent";
import HostnamectlLinuxContent from "./HostnamectlLinuxContent";
import NetStatLinuxContent from "./NetStatLinuxContent";
import TestSpeedNetLinuxContent from "./TestSpeedNetLinuxContent";

/*
  <Route path="/linux/index" element={<LinuxHomeContent/>} exact/>
        <Route path="/linux/date" element={<DateLinuxContent/>} exact/>
        <Route path="/linux/df" element={<DfLinuxContent/>} exact/>
        <Route path="/linux/free" element={<FreeLinuxContent/>} exact/>
        <Route path="/linux/pwd" element={<PwdLinuxContent/>} exact/>
        <Route path="/linux/ls" element={<LsLinuxContent/>} exact/>
        <Route path="/linux/cd" element={<CdLinuxContent/>} exact/>
        <Route path="/linux/os-release" element={<OSReleaseLinuxContent/>} exact/>
        <Route path="/linux/lsb-release" element={<LsbReleaseLinuxContent/>} exact/>
        <Route path="/linux/uname" element={<UnameLinuxContent/>} exact/>
        <Route path="/linux/hostname" element={<HostnameLinuxContent/>} exact/>
        <Route path="/linux/env" element={<EnvLinuxContent/>} exact/>
        <Route path="/linux/clean" element={<CleanLinuxContent/>} exact/>
        <Route path="/linux/uptime" element={<UptimeLinuxContent/>} exact/>
        <Route path="/linux/w" element={<WLinuxContent/>} exact/>
        <Route path="/linux/echo" element={<EchoLinuxContent/>} exact/>
        <Route path="/linux/man" element={<ManLinuxContent/>} exact/>
        <Route path="/linux/which" element={<WhichLinuxContent/>} exact/>
        <Route path="/linux/mkdir" element={<MkdirLinuxContent/>} exact/>
        <Route path="/linux/rm" element={<RmLinuxContent/>} exact/>
        <Route path="/linux/ps" element={<PsLinuxContent/>} exact/>
        <Route path="/linux/top" element={<TopLinuxContent/>} exact/>
        <Route path="/linux/kill" element={<KillLinuxContent/>} exact/>
        <Route path="/linux/htop" element={<HtopLinuxContent/>} exact/>
        <Route path="/linux/stream" element={<StreamLinuxContent/>} exact/>
        <Route path="/linux/piping" element={<PipingLinuxContent/>} exact/>
        <Route path="/linux/cat" element={<CatLinuxContent/>} exact/>
        <Route path="/linux/vim" element={<VimLinuxContent/>} exact/>
        <Route path="/linux/nano" element={<NanoLinuxContent/>} exact/>
        <Route path="/linux/cp" element={<CpLinuxContent/>} exact/>
        <Route path="/linux/history" element={<HistoryLinuxContent/>} exact/>
        <Route path="/linux/mv" element={<MvLinuxContent/>} exact/>
        <Route path="/linux/head" element={<HeadLinuxContent/>} exact/>
        <Route path="/linux/tail" element={<TailLinuxContent/>} exact/>
        <Route path="/linux/more" element={<MoreLinuxContent/>} exact/>
        <Route path="/linux/grep" element={<GrepLinuxContent/>} exact/>
        <Route path="/linux/hard-soft-links" element={<HardSoftLinksLinuxContent/>} exact/>
        <Route path="/linux/ln" element={<LnLinuxContent/>} exact/>
        <Route path="/linux/find" element={<FindLinuxContent/>} exact/>
        <Route path="/linux/xarg" element={<XargLinuxContent/>} exact/>
        <Route path="/linux/tar" element={<TarLinuxContent/>} exact/>
        <Route path="/linux/gzip" element={<GzipLinuxContent/>} exact/>
        <Route path="/linux/file" element={<FileLinuxContent/>} exact/>
        <Route path="/linux/sort" element={<SortLinuxContent/>} exact/>
        <Route path="/linux/alias" element={<AliasLinuxContent/>} exact/>
        <Route path="/linux/du" element={<DuLinuxContent/>} exact/>
        <Route path="/linux/whoami" element={<WhoamiLinuxContent/>} exact/>
        <Route path="/linux/useradd" element={<UseraddLinuxContent/>} exact/>
        <Route path="/linux/adduser" element={<AdduserLinuxContent/>} exact/>
        <Route path="/linux/su" element={<SuLinuxContent/>} exact/>
        <Route path="/linux/passwd" element={<PasswdLinuxContent/>} exact/>
        <Route path="/linux/userdel" element={<UserdelLinuxContent/>} exact/>
        <Route path="/linux/cat-etc-passwd" element={<CatEtcPasswdLinuxContent/>} exact/>
        <Route path="/linux/chown" element={<ChownLinuxContent/>} exact/>
        <Route path="/linux/chmod" element={<ChmodLinuxContent/>} exact/>
        <Route path="/linux/export" element={<ExportLinuxContent/>} exact/>
        <Route path="/linux/script" element={<ScriptLinuxContent/>} exact/>
        <Route path="/linux/ping" element={<PingLinuxContent/>} exact/>
        <Route path="/linux/ip" element={<IpLinuxContent/>} exact/>
        <Route path="/linux/ifconfig" element={<IfconfigLinuxContent/>} exact/>
        <Route path="/linux/nslookup" element={<NslookupLinuxContent/>} exact/>
        <Route path="/linux/ssh" element={<SshLinuxContent/>} exact/>
        <Route path="/linux/traceroute" element={<TracerouteLinuxContent/>} exact/>
        <Route path="/linux/whois" element={<WhoisLinuxContent/>} exact/>
        <Route path="/linux/curl" element={<CurlLinuxContent/>} exact/>
        <Route path="/linux/wget" element={<WgetLinuxContent/>} exact/>
        <Route path="/linux/unset" element={<UnsetLinuxContent/>} exact/>
        <Route path="/linux/hostnamectl" element={<HostnamectlLinuxContent/>} exact/>
        <Route path="/linux/netstat" element={<NetStatLinuxContent/>} exact/>
        <Route path="/linux/test-speed-net" element={<TestSpeedNetLinuxContent/>} exact/>
 */
export default function Router() {
    let element = useRoutes([
        {path: "/linux/index", element: <LinuxHomeContent/>},
        {path: "/linux/date", element: <DateLinuxContent/>},
        {path: "/linux/df", element: <DfLinuxContent/>},
        {path: "/linux/free", element: <FreeLinuxContent/>},
        {path: "/linux/pwd", element: <PwdLinuxContent/>},
        {path: "/linux/ls", element: <LsLinuxContent/>},
        {path: "/linux/cd", element: <CdLinuxContent/>},
        {path: "/linux/os-release", element: <OSReleaseLinuxContent/>},
        {path: "/linux/lsb-release", element: <LsbReleaseLinuxContent/>},
        {path: "/linux/uname", element: <UnameLinuxContent/>},
        {path: "/linux/hostname", element: <HostnameLinuxContent/>},
        {path: "/linux/env", element: <EnvLinuxContent/>},
        {path: "/linux/clean", element: <CleanLinuxContent/>},
        {path: "/linux/uptime", element: <UptimeLinuxContent/>},
        {path: "/linux/w", element: <WLinuxContent/>},
        {path: "/linux/echo", element: <EchoLinuxContent/>},
        {path: "/linux/man", element: <ManLinuxContent/>},
        {path: "/linux/which", element: <WhichLinuxContent/>},
        {path: "/linux/mkdir", element: <MkdirLinuxContent/>},
        {path: "/linux/rm", element: <RmLinuxContent/>},
        {path: "/linux/ps", element: <PsLinuxContent/>},
        {path: "/linux/top", element: <TopLinuxContent/>},
        {path: "/linux/kill", element: <KillLinuxContent/>},
        {path: "/linux/htop", element: <HtopLinuxContent/>},
        {path: "/linux/stream", element: <StreamLinuxContent/>},
        {path: "/linux/piping", element: <PipingLinuxContent/>},
        {path: "/linux/cat", element: <CatLinuxContent/>},
        {path: "/linux/vim", element: <VimLinuxContent/>},
        {path: "/linux/nano", element: <NanoLinuxContent/>},
        {path: "/linux/cp", element: <CpLinuxContent/>},
        {path: "/linux/history", element: <HistoryLinuxContent/>},
        {path: "/linux/mv", element: <MvLinuxContent/>},
        {path: "/linux/head", element: <HeadLinuxContent/>},
        {path: "/linux/tail", element: <TailLinuxContent/>},
        {path: "/linux/more", element: <MoreLinuxContent/>},
        {path: "/linux/grep", element: <GrepLinuxContent/>},
        {path: "/linux/hard-soft-links", element: <HardSoftLinksLinuxContent/>},
        {path: "/linux/ln", element: <LnLinuxContent/>},
        {path: "/linux/find", element: <FindLinuxContent/>},
        {path: "/linux/xarg", element: <XargLinuxContent/>},
        {path: "/linux/tar", element: <TarLinuxContent/>},
        {path: "/linux/gzip", element: <GzipLinuxContent/>},
        {path: "/linux/file", element: <FileLinuxContent/>},
        {path: "/linux/sort", element: <SortLinuxContent/>},
        {path: "/linux/alias", element: <AliasLinuxContent/>},
        {path: "/linux/du", element: <DuLinuxContent/>},
        {path: "/linux/whoami", element: <WhoamiLinuxContent/>},
        {path: "/linux/useradd", element: <UseraddLinuxContent/>},
        {path: "/linux/adduser", element: <AdduserLinuxContent/>},
        {path: "/linux/su", element: <SuLinuxContent/>},
        {path: "/linux/passwd", element: <PasswdLinuxContent/>},
        {path: "/linux/userdel", element: <UserdelLinuxContent/>},
        {path: "/linux/cat-etc-passwd", element: <CatEtcPasswdLinuxContent/>},
        {path: "/linux/chown", element: <ChownLinuxContent/>},
        {path: "/linux/chmod", element: <ChmodLinuxContent/>},
        {path: "/linux/export", element: <ExportLinuxContent/>},
        {path: "/linux/script", element: <ScriptLinuxContent/>},
        {path: "/linux/ping", element: <PingLinuxContent/>},
        {path: "/linux/ip", element: <IpLinuxContent/>},
        {path: "/linux/ifconfig", element: <IfconfigLinuxContent/>},
        {path: "/linux/nslookup", element: <NslookupLinuxContent/>},
        {path: "/linux/ssh", element: <SshLinuxContent/>},
        {path: "/linux/traceroute", element: <TracerouteLinuxContent/>},
        {path: "/linux/whois", element: <WhoisLinuxContent/>},
        {path: "/linux/curl", element: <CurlLinuxContent/>},
        {path: "/linux/wget", element: <WgetLinuxContent/>},
        {path: "/linux/unset", element: <UnsetLinuxContent/>},
        {path: "/linux/hostnamectl", element: <HostnamectlLinuxContent/>},
        {path: "/linux/netstat", element: <NetStatLinuxContent/>},
        {path: "/linux/test-speed-net", element: <TestSpeedNetLinuxContent/>},
    ]);

    return element;
}