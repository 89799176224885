import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class FastCGICacheNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-fastcgi-cache", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. FastCGI Cache</b>

                    <br/>
                    <br/>

                    FastCGI Cache / Micro Cache este un cache pe partea de server care permite patrarea raspunsurilor venite la un limbaj dinamic.
                    In acest mod se minimezeaza procesarea limbajului pe partea de server.

                    <br/>

                    De exemplu, daca clientul face o cere catre nginx si trebuie procesata de php de exemplu:
                    <ul>
                        <li>
                            nginx trimte cererea catre PHP
                        </li>
                        <li>
                            PHP poate sa interactioneze cu o baza de date pentru a oferi raspunsul.
                            Apoi PHP compune raspunsul, de cele mai multe ori in format HTML.
                        </li>
                        <li>PHP trimite raspunsul catre nginx</li>
                        <li>nginx cache-ieste raspunsul</li>
                        <li>nginx trimite raspunsul catre client</li>
                    </ul>

                    Urmatoarea data cand nginx primeste o acceasi cerere, cu acceasi parametrii poate lua raspinsul din cache.
                    Fara sa mai apeleze la PHP.

                    <br/>
                    <br/>

                    <b>Observatie 1</b>:
                    <br/>
                    Pentru a stii daca o resursa a fost servita din cache sau nu se poate folosi variabila: <b>$upstream_cache_status</b>.
                    <br/>
                    Daca vedem in header raspunsului:
                    <ul>
                        <li>
                            <b>X-Cache: HIT</b> inseamna ca resursa a fost returnata din cache.
                        </li>
                        <li>
                            <b>X-Cache: MISS</b> inseamna ca resursa NU a fost returnata din cache;
                            <br/>
                            pagina care este candidată pentru stocarea în cache pentru ca nu a fost găsită în cache;
                        </li>

                        <li>
                            <b>X-Cache: BYPASS</b> inseamna ca pagina a fost indicată să nu fie memorată în cache.
                        </li>

                        <li>
                            <b>X-Cache: STALE</b>: pagină stocată în cache care a expirat și a fost vizitată. NginX va actualiza conținutul din cache.
                        </li>
                    </ul>

                    <b>Observatie 2</b>:
                    <ul>
                        <li>
                            <b>fastcgi_cache_bypass</b> - Definește condițiile în care răspunsul nu va fi preluat dintr-un cache;
                            Dacă cel puțin o valoare a parametrilor nu este goală și nu este egală cu „0”, atunci răspunsul nu va fi preluat din cache
                        </li>
                        <li>
                            <b>fastcgi_no_cache</b> - definește condițiile în care răspunsul nu va fi salvat într-un cache;
                            Dacă cel puțin o valoare a parametrilor nu este goală și nu este egală cu „0”, atunci răspunsul nu va fi salvat
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'user www-data;\n' +
                            '\n' +
                            'worker_processes auto;\n' +
                            '\n' +
                            'events {\n' +
                            '  worker_connections 1024;\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  # configurare microcache (fastcgi)\n' +
                            '  # fastcgi_cache_path: ' +
                            '  # => /tmp/nginx_cache = calea catre directorul unde se scriu fisierele cache\n' +
                            '  # => levels=1:2 => salveaza fisierele cache intr-o structura mai optimizata pentru cautarea intr-un numar mare de fisiere cache\n' +
                            '  # => keys_zone=ZONE_1:100m => nume key, poate fi ZONE_1 sau orice alt nume KJ_1, urmat de dimensiunea zonei de cache 100m\n' +
                            '  # => inactive=60m => specifica cat timp sa fie pastrat un fisier de cache de ultima accesare\n' +
                            '  fastcgi_cache_path /tmp/nginx_cache levels=1:2 keys_zone=ZONE_1:100m inactive=60m;\n' +
                            '  \n' +
                            '  # fastcgi_cache_key: determina formatul unei intrari in cache:\n' +
                            '  # => $scheme: http sau https\n' +
                            '  # => $request_method: get, post, etc\n' +
                            '  # => $host: domain\n' +
                            '  # => $request_uri: URI\n' +
                            '  # deci o intrare poate arata: http://GETdomain.com/blog/article, dar hash-uit: 2FDE0841CB86FE4606A83D64B6BF3D29' +
                            '  # 2FDE0841CB86FE4606A83D64B6BF3D29 va numele unui fisier de cache\n' +
                            '  fastcgi_cache_key "$scheme$request_method$host$request_uri";\n\n' +
                            '  # pentru a specifica daca o resursa a fost servita din cache sau nu se poate folosi variabila $upstream_cache_status\n' +
                            '  add_header X-Cache $upstream_cache_status;\n' +
                            '\n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 80;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '\n' +
                            '    root /sites/demo;\n' +
                            '\n' +
                            '    index index.php index.html;\n' +
                            '\n' +
                            '    # Cache by default\n' +
                            '    set $no_cache 0;\n' +
                            '\n' +
                            '    # Check for cache bypass\n' +
                            '    if ($arg_skipcache = 1) {\n' +
                            '      set $no_cache 1;\n' +
                            '    }\n' +
                            '\n' +
                            '    location / {\n' +
                            '      try_files $uri $uri/ =404;\n' +
                            '    }\n' +
                            '\n' +
                            '    location ~\\.php$ {\n' +
                            '      # Pass php requests to the php-fpm service (fastcgi)\n' +
                            '      include fastcgi.conf;\n' +
                            '      fastcgi_pass unix:/run/php/php7.1-fpm.sock;\n' +
                            '\n' +
                            '      # Activare cache\n' +
                            '      fastcgi_cache ZONE_1;\n' +
                            '      fastcgi_cache_valid 200 60m; # se specifica ca e valid pentru cod = 200, si e valid 60 minute\n' +
                            '      fastcgi_cache_valid 404 10m; # se specifica ca e valid pentru cod = 404, si e valid 10 minute\n' +
                            '      # daca $no_cache = 1 nu se mai ia resursa din cache\n' +
                            '      fastcgi_cache_bypass $no_cache;\n' +
                            '      # daca $no_cache = 1 nu se mai salveaza raspunsul in cache\n' +
                            '      fastcgi_no_cache $no_cache;\n' +
                            '    }\n' +
                            '\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Nivelul <b>levels=1:2</b> permite ca in directorul cu fisiere cache sa se pastreze intr-o structura de genul:

                    <ul>
                        <li>
                            <b><i>2</i></b> (1 caracter din numele fisierului cache; ultimul caracter)
                            <ul>
                                <li>
                                    <b>13</b> (2 caractere din numele fisierului cache; penultimul si antepenultimul caracter)
                                   <ul>
                                       <li>
                                           32mdgldjgdfjg55t4390ti4t4<b>13</b>
                                           <b><i>2</i></b>
                                       </li>
                                   </ul>
                                </li>

                                <li>
                                    <b>19</b>
                                    <ul>
                                        <li>
                                            e6ldfgk5o96igmd4ff90ti4t4<b>19</b>
                                            <b><i>2</i></b>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Testare cu <b>Apache Bench</b> (HTTP server benchmarking tool)</b>

                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get install apache2-utils'}
                    </SyntaxHighlighter>
                    sau CentOS
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'yum install httpd-tools'}
                    </SyntaxHighlighter>

                    Verificare ca s-a instalat:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ab'}
                    </SyntaxHighlighter>

                    Testare ca functioneaza URL:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl http://IP'}
                    </SyntaxHighlighter>

                    Testare URL (100 de cereri, in locuri de catre 10):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ab -n 100 -c 10  http://IP'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>n = numarul de cereri (100)</li>
                        <li>c = concurentialitatea conexiunii (10) </li>
                    </ul>
                    Rularea comenzii, va afisa o serie de statistici:
                    <ul>
                        <li>Requests per seconds</li>
                        <li>Time per request </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FastCGICacheNginxContent;