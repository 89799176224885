import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ElasticsearchLiferayContent extends BaseContentPage{

    constructor(props) {
        super(props, "liferay-elasticsearch", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    La pornirea server-ului, in log-uri se regaseste urmatoare informatie legata de Elasticsearch:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'2022-03-07 21:30:15.350 WARN  [main][SidecarManager:111] Liferay automatically starts a child process of Elasticsearch named sidecar for convenient development and demonstration purposes. Do NOT use sidecar in production. Refer to the documentation for details on the limitations of sidecar and instructions on configuring a remote Elasticsearch connection in the Control Panel.\n' +
                        '2022-03-07 21:30:28.483 INFO  [main][Sidecar:119] Sidecar Elasticsearch liferay started at 127.0.0.1:9201'}
                    </SyntaxHighlighter>

                    Aceasta specifica faptul ca Liferay a pornit automat un proces secundar al <b>Elasticsearch</b> numit <i>sidecar</i> pentru dezvoltare convenabilă și scopuri demonstrative.
                    <br/>
                    Si se recomanda ca in productie sa nu se foloseasca <i>sidecar</i>.
                    <br/>
                    Si de asemenea, indeamna la consulatarea documentatiei despre limitarile <i>slidecar</i> si consultarea instructiunilor legate de configurarea unei conexiuni <b>Elasticsearch</b> la distanță din <b>Panoul de control</b>.
                    <br/>
                    <br/>

                    Tot din log-uri, aflam ca <b>Sidecar Elasticsearch</b> a pornit pe portul 9201: <b>127.0.0.1:9201</b>. Accesand aceasta adresa din browser:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'{\n' +
                        '  "name" : "liferay",\n' +
                        '  "cluster_name" : "LiferayElasticsearchCluster",\n' +
                        '  "cluster_uuid" : "WMtbCQqGRQuhFynmVI2wUQ",\n' +
                        '  "version" : {\n' +
                        '    "number" : "7.9.0",\n' +
                        '    "build_flavor" : "unknown",\n' +
                        '    "build_type" : "unknown",\n' +
                        '    "build_hash" : "a479a2a7fce0389512d6a9361301708b92dff667",\n' +
                        '    "build_date" : "2020-08-11T21:36:48.204330Z",\n' +
                        '    "build_snapshot" : false,\n' +
                        '    "lucene_version" : "8.6.0",\n' +
                        '    "minimum_wire_compatibility_version" : "6.8.0",\n' +
                        '    "minimum_index_compatibility_version" : "6.0.0-beta1"\n' +
                        '  },\n' +
                        '  "tagline" : "You Know, for Search"\n' +
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*   */}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ElasticsearchLiferayContent;