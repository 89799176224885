import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlDeleteContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-delete", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl delete</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectl delete</b> este folosita pentru a sterge un obiect pe baza fisierului de configurare cu care a fost creat:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete -f <fisier-configurare>'}
                    </SyntaxHighlighter>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete -f client-pod.yaml'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'pod "client-pod" deleted'}
                    </SyntaxHighlighter>

                    O alta forma a comenzii <b>kubectl delete</b> prespune numele unui pod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete <tip-obiect> <nume-obiect>'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get deployments'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'NAME                READY   UP-TO-DATE   AVAILABLE   AGE\n' +
                            'client-deployment   1/1     1            1           28h'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge un deployment:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete deployment client-deployment'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu se sters un secret:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete secret pgpassword'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'secret "pgpassword" deleted'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a sterge un pod cu numele <i>nginx</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete pod nginx'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge un serviciu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete service nginx-deployment'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge tot (pod-uri, servicii, deployment):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete all --all'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a sterge toate pod-urile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete pods --all'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sterge dupa label:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete [obiect] -l [cheie]=[valoare]'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl delete deployments, services -l group=example'}
                    </SyntaxHighlighter>
                    unde label este declarat in <b>\metadata\labels</b>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlDeleteContent;