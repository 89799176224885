import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ReactLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-react", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <ul>
                        <li>
                            Setare director unde se doreste crearea modulului React:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {' cd D:\\work\\liferay-vanilla\\modules\\apps'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Instalare <b>yo</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'npm install -g yo'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Instalare <b>generator-liferay-js</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'npm install -g generator-liferay-js'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Generarea modul React:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'yo liferay-js'}
                            </SyntaxHighlighter>

                            Rezultatul comenzii:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'PS D:\\work\\liferay-vanilla\\modules\\apps> yo liferay-js\n' +
                                '? What type of project do you want to create? React Widget\n' +
                                '? What name shall I give to the folder hosting your project? react-form\n' +
                                '? What is the human readable description of your project? React Form\n' +
                                '? Do you want to add localization support? Yes\n' +
                                '? Do you want to add configuration support?\n' +
                                '\n' +
                                '  � Needs Liferay DXP/Portal CE 7.1 with JS Portlet Extender 1.1.0 or\n' +
                                '     Liferay DXP/Portal CE 7.2+.\n' +
                                '\n' +
                                ' Yes\n' +
                                '? Under which category should your widget be listed? react-form\n' +
                                '? Do you have a local installation of Liferay for development? Yes\n' +
                                '? Where is your local installation of Liferay placed? D:\\work\\liferay-vanilla\\bundles\n' +
                                '? Do you want to generate sample code? Yes\n' +
                                '   create package.json\n' +
                                '   create README.md\n' +
                                '   create .gitignore\n' +
                                '   create .npmbuildrc\n' +
                                '   create .npmbundlerrc\n' +
                                '   create .npmignore\n' +
                                '   create assets\\.placeholder\n' +
                                '   create features\\localization\\Language.properties\n' +
                                '   create features\\configuration.json\n' +
                                '   create assets\\css\\styles.css\n' +
                                '   create .babelrc\n' +
                                '   create src\\index.js\n' +
                                '   create src\\AppComponent.js\n'}
                            </SyntaxHighlighter>

                            <hr/>

                            Daca se obtine o eroare de genul: <i>yo.ps1 cannot be loaded because running scripts is disabled on this system</i>,
                            atunci se deschide consola <b>PowerShell</b> ca <i>Administrator</i> si se ruleaza comanda: <b>Set-ExecutionPolicy RemoteSigned</b>,
                            raspunzand <b>Y</b> pentru a confirma schimbarea de politica.
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'Set-ExecutionPolicy RemoteSigned\n' +
                                '\n' +
                                'Execution Policy Change\n' +
                                'The execution policy helps protect you from scripts that you do not trust. Changing the execution policy might expose\n' +
                                'you to the security risks described in the about_Execution_Policies help topic at\n' +
                                'https:/go.microsoft.com/fwlink/?LinkID=135170. Do you want to change the execution policy?\n' +
                                '[Y] Yes  [A] Yes to All  [N] No  [L] No to All  [S] Suspend  [?] Help (default is "N"): Y'}
                            </SyntaxHighlighter>
                        </li>

                        <li>Build modul:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'npm run deploy'}
                            </SyntaxHighlighter>
                        </li>

                        <li>Instalare Clay

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'npm install @clayui/alert @clayui/autocomplete @clayui/badge @clayui/breadcrumb @clayui/button @clayui/card @clayui/charts @clayui/color-picker  @clayui/data-provider @clayui/date-picker @clayui/drop-down @clayui/empty-state @clayui/form @clayui/icon @clayui/label @clayui/link @clayui/list @clayui/loading-indicator @clayui/management-toolbar @clayui/modal @clayui/multi-select @clayui/multi-step-nav @clayui/nav @clayui/navigation-bar @clayui/pagination @clayui/pagination-bar @clayui/panel @clayui/popover  @clayui/progress-bar @clayui/shared @clayui/slider @clayui/sticker @clayui/table @clayui/tabs @clayui/time-picker @clayui/tooltip @clayui/upper-toolbar'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'import React from \'react\';\n' +
                                'import ReactDOM from \'react-dom\';\n' +
                                'import ClayLabel from \'@clayui/label\';\n' +
                                '\n' +
                                'export default class extends React.Component {\n' +
                                '\trender() {\n' +
                                '\t\treturn (\n' +
                                '\t\t\t<div>\n' +
                                '\t\t\t\t<ClayLabel displayType="warning">Exemplu React</ClayLabel>\n' +
                                '\t\t\t</div>\n' +
                                '\t\t);\n' +
                                '\t}\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <hr/>
                    <b>React + i18n</b>
                    <br/>
                    <br/>

                    In componenta React:
                    <SyntaxHighlighter>
                        {'const Liferay = window.Liferay;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                       {'<span>{Liferay.Language.get(\'app.config.title\')}</span>'}
                    </SyntaxHighlighter>

                    In fisierul de proprietati (\modules\app-config\src\main\resources\content\Language.properties):
                    <SyntaxHighlighter>
                        {'app.config.title = App Configuration'}
                    </SyntaxHighlighter>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://www.linkedin.com/pulse/how-build-react-component-liferay-dxp-paul-towers/"}>
                                    How To Build & Deploy A React Component in Liferay DXP
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://liferay.dev/blogs/-/blogs/liferay-react-portlets"}>
                                    Liferay React Portlets
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.inexture.com/react-application-using-liferay/"}>
                                    Developing a React Application using Liferay
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.xtivia.com/blog/building-liferay-js-generated-react-app-typescript-material-ui/"}>
                                    Building a Liferay JS Generated React App that uses Typescript & Material UI
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReactLiferayContent;