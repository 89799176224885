import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class ChownLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-chown", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a schimba proprietarul unui fisier, de exemplu de la <i>root</i> la <i>iulian</i>
                    (comanda trebuie executata logat cu <i>root</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chown iulian fisier.txt'}
                    </SyntaxHighlighter>

                    Pentru a schimba si grupul:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chown iulian:iulian fisier.txt'}
                    </SyntaxHighlighter>

                    Similar se face si schimbarea si pentru un director.
                    Implicit se modifica proprietarul doar pentru director, dar nu si pentru fisiere continute in acel director.
                    <br/>
                    Pentru a schimba propritarul si pentru fisiere continute in director se foloseste flag-ul <b>-R</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'chown -R iulian:iulian director'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChownLinuxContent;