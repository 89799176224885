import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DataAccessLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-data-access", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.repository.service.impl;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.dao.jdbc.DataAccess;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.util.StringBundler;\n' +
                            'import ro.ibrid.repository.dto.MetadataDto;\n' +
                            'import ro.ibrid.repository.dto.MetadataWrapper;\n' +
                            '\n' +
                            'import java.sql.Connection;\n' +
                            'import java.sql.PreparedStatement;\n' +
                            'import java.sql.ResultSet;\n' +
                            'import java.sql.SQLException;\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            'public class BulkInsertUtil {\n' +
                            '\n' +
                            '    public static int SIZE = 5000; //50 - 48 secunde; 100 - 34 secunde; 1000 - 28 secunde; 5000 - 46 secunde\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(BulkInsertUtil.class);\n' +
                            '\n' +
                            '    private static Connection connection = null;\n' +
                            '    private static PreparedStatement preparedStatement = null;\n' +
                            '\n' +
                            '    private static PreparedStatement ps;\n' +
                            '\n' +
                            '    private static String query = "SELECT repositorymetadataid, documentname, documentpath FROM ibrid_repositorymetadata WHERE documentname = ? and documentpath = ?";\n' +
                            '\n' +
                            '    private static final String SQL_DELETE_ALL = "delete from ibrid_repositorymetadata where ibrid_repositorymetadata.repositorymetadataid>0;";\n' +
                            '    private static String sql = null;\n' +
                            '\n' +
                            '    public static void bulkInsertData(List<MetadataWrapper> list) {\n' +
                            '\n' +
                            '        try {\n' +
                            '            String sql = getTempleteSQL(list.size());\n' +
                            '            if (preparedStatement == null || preparedStatement.isClosed() || list.size() != SIZE) {\n' +
                            '                preparedStatement = connection.prepareStatement(sql);\n' +
                            '            }\n' +
                            '\n' +
                            '            // Setarea parametrilor pentru fiecare rând\n' +
                            '            int parameterIndex = 1;\n' +
                            '\n' +
                            '            for (MetadataWrapper metadataWrapper : list) {\n' +

                            '\n' +
                            '                MetadataDto metadataDto = metadataWrapper.getMetadataDto();\n' +
                            '\n' +
                            '                preparedStatement.setLong(parameterIndex++, metadataWrapper.getId()); // createdate\n' +
                            '                preparedStatement.setDate(parameterIndex++, new java.sql.Date(System.currentTimeMillis())); // createdate\n' +
                            '                preparedStatement.setDate(parameterIndex++, new java.sql.Date(System.currentTimeMillis())); // modifieddate\n' +
                            '                preparedStatement.setString(parameterIndex++, metadataDto.getNumePrenume()); //  numeprenume,\n' +
                            '                preparedStatement.setString(parameterIndex++, metadataDto.getCnp());// cnp\n' +
                            '                preparedStatement.setString(parameterIndex++, metadataDto.getStradaImobil());// stradaimobil\n' +
                            '                preparedStatement.setString(parameterIndex++, metadataDto.getNumarImobil());// numarimobil\n' +
                            '                preparedStatement.setLong(parameterIndex++, metadataDto.getAn() != null ? metadataDto.getAn() : 0);// an\n' +
                            '                preparedStatement.setString(parameterIndex++, metadataWrapper.getFile());// documentname\n' +
                            '                preparedStatement.setDate(parameterIndex++, null); // documentcreatedate\n' +
                            '                preparedStatement.setDate(parameterIndex++, new java.sql.Date(metadataWrapper.getModTime().getTime())); // metadatacreatedate\n' +
                            '                preparedStatement.setDate(parameterIndex++, new java.sql.Date(System.currentTimeMillis()));// scandate\n' +
                            '                preparedStatement.setString(parameterIndex++, metadataWrapper.getPath());// documentpath\n' +
                            '            }\n' +
                            '\n' +
                            '            preparedStatement.executeUpdate();\n' +
                            '\n' +
                            '        } catch (Exception e) {\n' +
                            '            System.out.println(e);\n' +
                            '        }\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    public static void init() {\n' +
                            '        try {\n' +
                            '            if (connection == null || connection.isClosed()) {\n' +
                            '                try {\n' +
                            '                    connection = DataAccess.getConnection();\n' +
                            '                } catch (SQLException e) {\n' +
                            '                    _log.error("Eroare la inserarea în masă în baza de date", e);\n' +
                            '                } finally {\n' +
                            '                    //DataAccess.cleanUp(connection, preparedStatement);\n' +
                            '                }\n' +
                            '            }\n' +
                            '        } catch (SQLException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    private static boolean exists(String docName, String docPath) {\n' +
                            '\n' +
                            '        try {\n' +
                            '            if (ps == null) {\n' +
                            '                ps = connection.prepareStatement(query);\n' +
                            '            }\n' +
                            '            ps.setString(1, docName);\n' +
                            '            ps.setString(2, docPath);\n' +
                            '            ResultSet rs = ps.executeQuery();\n' +
                            '            while (rs.next()) {\n' +
                            '                return true;\n' +
                            '            }\n' +
                            '        } catch (SQLException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '\n' +
                            '        return false;\n' +
                            '    }\n' +
                            '\n' +
                            '    public static void deleteAll() {\n' +
                            '        PreparedStatement ps = null;\n' +
                            '        try {\n' +
                            '            PreparedStatement preparedStatement = connection.prepareStatement(SQL_DELETE_ALL);\n' +
                            '            int row = preparedStatement.executeUpdate();\n' +
                            '            System.out.println("S-au sters <" + row + "> de inregistrari!");\n' +
                            '        } catch (SQLException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    public static void cleanUp() {\n' +
                            '        DataAccess.cleanUp(connection, preparedStatement);\n' +
                            '    }\n' +
                            '\n' +
                            '    private static String getTempleteSQL(int size) {\n' +
                            '\n' +
                            '        if (sql == null || size != SIZE) {\n' +
                            '            StringBundler sb = new StringBundler();\n' +
                            '\n' +
                            '            // Construiți o instrucțiune SQL pentru inserarea bulk\n' +
                            '            sb.append("INSERT INTO ibrid_repositorymetadata (repositorymetadataid, groupid, companyid, userid, username, createdate, modifieddate," +\n' +
                            '                    " numeprenume, cnp, stradaimobil, numarimobil, an, documentname, documentcreatedate, metadatacreatedate, scandate, documentpath ) VALUES ");\n' +
                            '\n' +
                            '            for (int k = 0; k < size; k++) {\n' +
                            '                sb.append("(?, 0, 0, 0, null, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?), "); // Adăugați aici valorile pentru fiecare rând\n' +
                            '            }\n' +
                            '\n' +
                            '            sql = sb.toString();\n' +
                            '            sql = replaceLast(sql, ",", ";");\n' +
                            '        }\n' +
                            '        return sql;\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    public static String replaceLast(String text, String regex, String replacement) {\n' +
                            '        return text.replaceFirst("(?s)" + regex + "(?!.*?" + regex + ")", replacement);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default DataAccessLiferayContent;