import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class BridgeNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-bridge", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>
                        1. Creare retea de tip bridge
                    </b>
                    <br/>
                    <br/>

                    O retea <b>bridge</b> foloseste un software <b>bridge</b> care permite containerelor contectate in aceasi retea <b>brige</b> sa comunice intre ele,
                    fiind insa <i>izolate</i> de alte containere care nu sunt in aceasta retea <b>bridge</b>.

                    <hr/>
                    Daca afisam retelele de pe masina gazda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ifconfig'}
                    </SyntaxHighlighter>
                    vom vedea ca una dintre retele este <b>docker0</b> si are IP-ul 172.17.0.1:
                    <SyntaxHighlighter>
                        {'docker0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500\n' +
                            '        inet 172.17.0.1  netmask 255.255.0.0  broadcast 172.17.255.255\n' +
                            '        inet6 fe80::42:11ff:fed3:e327  prefixlen 64  scopeid 0x20<link>\n' +
                            '        ether 02:42:11:d3:e3:27  txqueuelen 0  (Ethernet)\n' +
                            '        RX packets 87625  bytes 10025728 (10.0 MB)\n' +
                            '        RX errors 0  dropped 0  overruns 0  frame 0\n' +
                            '        TX packets 172849  bytes 743312977 (743.3 MB)\n' +
                            '        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0\n'}
                    </SyntaxHighlighter>
                    (de retinut, docker0 are IP-ul 172.17.0.1)

                    <hr/>

                    Daca vrem sa vedem mai multe informati legate de reteau <i>bridge</i>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect  bridge'}
                    </SyntaxHighlighter>
                    vom gasi (IPAM = IP Address Management:
                    <SyntaxHighlighter>
                        {' "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": null,\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.17.0.0/16",\n' +
                            '                    "Gateway": "172.17.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n'}
                    </SyntaxHighlighter>
                    deci <b>Gateway</b> = 172.17.0.1 (docker0 {"=>"} functioneaza ca un <b>gateway</b>)

                    <br/>
                    <br/>
                    In plus, in sectiunea <b>Containers</b> vom regasi si toate containerele (4 la numar) conectate la retea:
                    <SyntaxHighlighter>
                        {' "Containers": {\n' +
                            '            "213e8cf4e499e2207e29bc9940ed8d328b86ae53517983ba1de70a89ae858a36": {\n' +
                            '                "Name": "fervent_driscoll",\n' +
                            '                "EndpointID": "7ec8390f7e5a7d4db5adaa2b319bf7c8e3f607bbdc1677a7173813dc85c374d8",\n' +
                            '                "MacAddress": "02:42:ac:11:00:03",\n' +
                            '                "IPv4Address": "172.17.0.3/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "3e77615d2ab373fea378f79723fe03438686375094e9d935cbd0de9bfa3bdf05": {\n' +
                            '                "Name": "alpha-ubuntu",\n' +
                            '                "EndpointID": "6a97d89bff8a7ddf3ed16b2d3af0977486a383efffbf08e6a9a5bf7db1c7af2e",\n' +
                            '                "MacAddress": "02:42:ac:11:00:05",\n' +
                            '                "IPv4Address": "172.17.0.5/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "4ff5b2b1004427cb772fbaac644715a60a4789e89158856076f66ac77f6f858c": {\n' +
                            '                "Name": "wizardly_kowalevski",\n' +
                            '                "EndpointID": "114386d324456aca68405b12ff23e757d32c044559f0088c4db4aaeb6af3c6ba",\n' +
                            '                "MacAddress": "02:42:ac:11:00:02",\n' +
                            '                "IPv4Address": "172.17.0.2/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            },\n' +
                            '            "5a4cdd28b1fe32ae9e455c516daecd776c98ecb4cf7afaec11f1b7b8793de874": {\n' +
                            '                "Name": "loving_pasteur",\n' +
                            '                "EndpointID": "486c556cfab4717aec34e79e783c5eab8c31c98ff26f6cefb778b91230fcd436",\n' +
                            '                "MacAddress": "02:42:ac:11:00:04",\n' +
                            '                "IPv4Address": "172.17.0.4/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n'}
                    </SyntaxHighlighter>
                    <hr/>
                    Pentru a gasi IP-ul de la containerul cu numele <i>alpha-ubuntu</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {' docker container inspect --format="{{.NetworkSettings.Networks.bridge.IPAddress}}"  '}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'172.17.0.5'}
                    </SyntaxHighlighter>
                    deci e la fel cu cel afisat in sectiunea <b>Containers</b> de mai sus.

                    <hr/>

                    Alternativ, putem sa ne conectam la container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it alpha-ubuntu bash'}
                    </SyntaxHighlighter>
                    si de aici sa rulam:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'hostname -i'}
                    </SyntaxHighlighter>
                    va afisa tot:
                    <SyntaxHighlighter>
                        {'172.17.0.5'}
                    </SyntaxHighlighter>
                    apoi putem sa instalam in container <b>net-tools</b>:
                    <SyntaxHighlighter>
                        {'pt-get update -y'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'apt-get install -y net-tools'}
                    </SyntaxHighlighter>

                    Apoi, putem da comanda <b>route -n</b> pentru a verifica ca traficul trece prin Gateway (172.17.0.1):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'route -n'}
                    </SyntaxHighlighter>
                    va afisa
                    <SyntaxHighlighter>
                        {' route -n\n' +
                            'Kernel IP routing table\n' +
                            'Destination     Gateway         Genmask         Flags Metric Ref    Use Iface\n' +
                            '0.0.0.0         172.17.0.1      0.0.0.0         UG    0      0        0 eth0\n' +
                            '172.17.0.0      0.0.0.0         255.255.0.0     U     0      0        0 eth0\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a gasi Gateway IP-ul de la containerul cu numele <i>alpha-ubuntu</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {' docker container inspect --format="{{.NetworkSettings.Networks.bridge.Gateway}}" alpha-ubuntu'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'172.17.0.1'}
                    </SyntaxHighlighter>


                    <hr/>

                    Pentru a face ca o aplicatie/container sa se conecteze la pornire la reteaua <b>bridge</b>:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-bridge --network=bridge busybox'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-bridge  busybox'}
                    </SyntaxHighlighter>
                    (pentru in mod implicit, daca nu se specifica reteaua, este conectata la reteaua <b>bridge</b>)

                    <hr/>
                    Exista o metoda, prin care un container poate fi link-uit altui container: <i>legacy container links</i>,
                    prin folosirea optiunii <i>--link [numeCelalaltContainer]:[nume]</i>
                    <br/>
                    Aceasta metoda <b>nu este recomanda, nu va fi posibil eliminata din Docker</b>.
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --name busybox-l1  busybox'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -id --link busybox-l1:L1 --name busybox-l2  busybox'}
                    </SyntaxHighlighter>
                    daca ne conectam la <i>busybox-l2</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it busybox-l2 sh'}
                    </SyntaxHighlighter>
                    va raspunde la comenziile de ping:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ping busybox-l1'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ping L1'}
                    </SyntaxHighlighter>





                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BridgeNetworkDockerContent;