import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class Anexa3MonteCarloContent extends BaseContentPage {

    constructor(props) {
        super(props, "anexa3-monte-carlo", IndexContent);
    }

    render() {

        return (
            <div className="home appendix">
                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify"}>
                    <div className={"text-justify important"}>
                        Metoda Monte Carlo, numită așa în 1946 de către matematicianul Stanislaw Ulam, este cunoscuta si sub numele de:
                        <ul>
                            <li>metoda prelevarilor aleatoare (random sampling)</li>
                            <li>simulare statistica</li>
                        </ul>

                        Metoda Monte Carlo reprezintă orice metoda care rezolva o problema prin:
                        <ul>
                            <li><b>generarea de numere aleatoare</b> (random numbers) </li>
                            <li><b>observarea acelei parti dintre numere care satisfac <i>o proprietate sau mai multe</i></b></li>
                        </ul>
                    </div>
                    <br/>
                    <div className={"text-justify"}>
                        <b>Exemplu: Estimarea numarului PI</b>
                        <br/><br/>
                        Consideram un cerc cu raza egala cu 1 (r=1) si patătratul circumscris cercului (deci o latura are lungimea 2, l=2r).
                        <br/>
                        Aria cercului este:
                        <MathComponent tex={String.raw`A_{cerc}=\pi r^2`}/>
                        Aria pătratului este:
                        <MathComponent tex={String.raw`A_{patrat}=(2r)^2=4r^2`}/>
                        Daca alegem aleator puncte (<b>generarea</b>) din interiorul patratului, unde din ele vor fi și in interiorul cercului.
                        Mai exact, daca alegem punctul (x,y), atunci acesta este in interioarul cercului daca:
                        <MathComponent tex={String.raw`x^2+y^2 \le 1`}/>
                        Deci conditia de mai sus reprezinta <b>observarea acelei parti dintre numere care satisfac <i>o proprietate sau mai multe</i></b>.
                        <br/>
                        Probabilitatea ca un punct sa fie in interiorul cercului este:
                        <MathComponent tex={String.raw`P={puncte \space din \space cerc \over puncte \space din \space patrat} = {\pi r^2 \over 4r^2} = {\pi \over 4} `}/>
                        Probabilitatea ca generand <b>n</b> puncte, <b>m</b> dintre acestea vor fi in interiorul cercului, impreuna cu legea numerelor mari:
                        <MathComponent tex={String.raw`m \approx {\pi n \over 4} `}/>
                        Prin urmare, PI este:
                        <MathComponent tex={String.raw`\pi  \approx {4m \over n} `}/>
                    </div>
                    <br/>
                    <div className={"text-justify"}>
                        <b>Algoritm</b>
                        <br/>
                        <ul>
                            <li>
                                n=[numar mare]
                            </li>
                            <li>
                                m=0
                            </li>
                            <li>
                                for i = 1..n
                                <ul>
                                    <li>x = numar aleator intre [-1,1]</li>
                                    <li>y = numar aleator intre [-1,1]</li>
                                    <li><MathComponent tex={String.raw`if \space x^2+y^2 \le 1 \space then \space m=m+1`}/></li>
                                </ul>
                            </li>
                            <li>
                                <MathComponent tex={String.raw`\pi  \approx {4m \over n} `}/>
                            </li>
                        </ul>
                    </div>
                </div>

                <br/>

                <div className={"text-justify important"}>
                    Algoritmii Monte Carlo:
                    <ul>
                        <li>gasesc o solutie a problemei, dar este garantat faptul ca e si corecta (e o solutie aproximativa)</li>
                        <li>pentru o solutie corecta a problemei timpul = &infin;</li>
                        <li>probabilitatea ca solutia sa fie corecta creste o data cu timpul de rezolvare</li>
                        <li>solutia gasita intr-un timp acceptabil este aproape sigur corecta</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>

                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Anexa3MonteCarloContent;