import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class IterationDataSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-iterating-data", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Iterare date in Spring Data JPA</b>
                    <br/>
                    <br/>

                    Ideea consta in iterarea paginata: trebuie definita o dimensiune si apoi se executa mai multe interograri.
                    Astfel, se pot procesa entitatile in locutir mai mici, pentru a evita incarcarea unor cantitati mari de date in memorie.

                    <br/>
                    Exista 2 metode:
                    <ul>
                        <li><b>Slice</b>:
                            Obiectul Slice permite să procesăm primul lot de entități;
                        <br/>
                            expune o metodă <b>hasNext()</b> care ne permite să verificăm dacă lotul pe care îl procesăm este ultimul din setul de rezultate;
                            <br/>
                            putem trece la urmatorul lot (slice) cu ajutorul metodei <b>nextPageable()</b>.
                            <br/>
                            Această metodă returnează obiectul <b>Pageable necesar</b> pentru solicitarea următoarei felii.
                            Prin urmare, putem prelua toate datele, felie cu felie (bucata cu bucata),
                            cu o combinație a celor două metode în interiorul unei bucle while:
                            <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                                {'void processStudentsByFirstName(String firstName) {\n' +
                                    '    Slice<Student> slice = repository.findAllByFirstName(firstName, PageRequest.of(0, BATCH_SIZE));\n' +
                                    '    List<Student> studentsInBatch = slice.getContent();\n' +
                                    '    studentsInBatch.forEach(emailService::sendEmailToStudent);\n' +
                                    '\n' +
                                    '    while(slice.hasNext()) {\n' +
                                    '        slice = repository.findAllByFirstName(firstName, slice.nextPageable());\n' +
                                    '        slice.get().forEach(emailService::sendEmailToStudent);\n' +
                                    '    }\n' +
                                    '}'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>Page</b>:
                            Interfața <b>Page</b> extinde <b>Slice</b> adăugând alte două metode:
                            <ul>
                                <li>
                                    <b>getTotalPages()</b>
                                </li>
                                <li>
                                    <b>getTotalElements()</b>
                                </li>
                            </ul>

                            <b>Page</b> este adesea folosit ca tip de returnare atunci când datele paginate sunt solicitate prin intr-o interfata UI.
                            Astfel, se va ști exact câte rânduri au mai rămas și de câte solicitări suplimentare vor fi necesare, pentru paginare.
                            <br/>
                            Din acest motiv, <b>Page</b> are ca rezultat interogări suplimentare care numără rândurile care îndeplinesc criteriile.
                            (se va face un <i>select count()</i> in plus).

                            <br/>
                            În consecință, ar trebui să folosim <b>Page</b> ca tip de returnare numai dacă trebuie să cunoaștem numărul total de entități.

                        </li>
                    </ul>

                    <hr/>
                    Mai exista o metoda: <b>Stream</b>.
                    <br/>
                    <br/>
                    Spring Data JPA ne permite, de asemenea, să transmitem datele din setul de rezultate.
                    <b>Vom procesa entitățile una câte una, fără a le încărca în memorie toate în același timp</b>.
                    <br/>
                    Cu toate acestea, va trebui să:
                    <ul>
                        <li>
                            închidem manual fluxul creat de Spring Data JPA, cu un bloc <b>try-with-resource</b>
                        </li>
                        <li>
                            să încapsulăm interogarea într-o tranzacție numai în citire (<b>@Transactional(readOnly = true)</b>)
                        </li>
                        <li>
                            chiar dacă procesăm rândurile unul câte unul, trebuie să ne asigurăm că contextul de persistență nu păstrează referința la toate entitățile;
                            <br/>
                            Putem realiza acest lucru prin detașarea manuală a entităților înainte de a consuma fluxul (<b>entityManager::detach</b>)
                        </li>
                    </ul>

                    <SyntaxHighlighter  showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'private final EntityManager entityManager;\n' +
                            '\n' +
                            '@Transactional(readOnly = true)\n' +
                            'public void processStudentsByFirstNameUsingStreams(String firstName) {\n' +
                            '    try (Stream<Student> students = repository.findAllByFirstName(firstName)) {\n' +
                            '        students.peek(entityManager::detach)\n' +
                            '            .forEach(emailService::sendEmailToStudent);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://www.baeldung.com/spring-data-jpa-iterate-large-result-sets"}>
                               Patterns for Iterating Over Large Result Sets With Spring Data JPA
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IterationDataSpringBootContent;