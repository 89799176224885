import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class HoistingJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-hoisting", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    Functiile normale sunt ridicate (hoisting):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'greeting();\n' +
                            '// Hello!\n' +
                            '\n' +
                            'function greeting() {\n' +
                            '    console.log("Hello!");\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Functiile din expresii nu sunt ridicate (hoisting):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'greeting();\n' +
                            '// TypeError\n' +
                            '\n' +
                            'var greeting = function greeting() {\n' +
                            '    console.log("Hello!");\n' +
                            '};'}
                    </SyntaxHighlighter>

                    <hr/>
                    Urmatorul fragment de cod:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'greeting = "Hello!";\n' +
                            'console.log(greeting);\n' +
                            '// Hello!\n' +
                            '\n' +
                            'var greeting = "Howdy!";'}
                    </SyntaxHighlighter>

                    Devine cumva prin hoisting:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var greeting;           // hoisted declaration\n' +
                            'greeting = "Hello!";    // the original line 1\n' +
                            'console.log(greeting);  // Hello!\n' +
                            'greeting = "Howdy!";    // `var` is gone!'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'studentName = "Suzy";\n' +
                            'greeting();\n' +
                            '// Hello Suzy!\n' +
                            '\n' +
                            'function greeting() {\n' +
                            '    console.log(`Hello ${ studentName }!`);\n' +
                            '}\n' +
                            'var studentName;'}
                    </SyntaxHighlighter>
                    devine cumva:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'function greeting() {\n' +
                            '    console.log(`Hello ${ studentName }!`);\n' +
                            '}\n' +
                            'var studentName;\n' +
                            '\n' +
                            'studentName = "Suzy";\n' +
                            'greeting();\n' +
                            '// Hello Suzy!'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <ul>
                        <li>declarațiile de funcții sunt ridicate mai întâi, apoi variabilele sunt ridicate imediat după toate funcțiile</li>
                    </ul>

                    <hr/>
                    Redeclarare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Frank";\n' +
                            'console.log(studentName);\n' +
                            '// Frank\n' +
                            '\n' +
                            'var studentName;\n' +
                            'console.log(studentName);   // ???'}
                    </SyntaxHighlighter>

                    Prin hoisting cumva devine:
                    <SyntaxHighlighter>
                        {'var studentName;\n' +
                            'var studentName;    // clearly a pointless no-op!\n' +
                            '\n' +
                            'studentName = "Frank";\n' +
                            'console.log(studentName);\n' +
                            '// Frank\n' +
                            '\n' +
                            'console.log(studentName);\n' +
                            '// Frank'}
                    </SyntaxHighlighter>

                    Se obtine eroare in cazurile:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let studentName = "Frank";\n' +
                            '\n' +
                            'console.log(studentName);\n' +
                            '\n' +
                            'let studentName = "Suzy";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Frank";\n' +
                            '\n' +
                            'let studentName = "Suzy";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let studentName = "Frank";\n' +
                            '\n' +
                            'var studentName = "Suzy";'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Singura modalitate de a „redeclara” o variabilă este utilizarea varpentru toate (două sau mai multe) declarații ale acesteia
                        </li>
                    </ul>

                    <hr/>
                    <b>Toate regulile de aplicare (inclusiv „redeclararea” letvariabilelor create) sunt aplicate pentru fiecare instanță de domeniu.
                        Cu alte cuvinte, de fiecare dată când se introduce un domeniu în timpul execuției, totul se resetează.</b>
                    <br/>
                    De aceea functioneza redeclararea (let) in:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var keepGoing = true;\n' +
                            'while (keepGoing) {\n' +
                            '    let value = Math.random();\n' +
                            '    if (value > 0.5) {\n' +
                            '        keepGoing = false;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fie acum:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var keepGoing = true;\n' +
                            'while (keepGoing) {\n' +
                            '    var value = Math.random();\n' +
                            '    if (value > 0.5) {\n' +
                            '        keepGoing = false;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Este value„redeclarat” aici, mai ales că știm varcă o permite? Nu. Deoarece var nu este tratată ca o declarație de blocare a domeniului de aplicare
                    , se atașează la domeniul global. Deci, există o singură variabilă value, în același domeniu ca keepGoing(sfera globală, în acest caz).
                    Nici aici  o „redeclarare”!

                    <br/>
                    exemplu pt const (nu e redeclarare):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var keepGoing = true;\n' +
                            'while (keepGoing) {\n' +
                            '    // ooo, a shiny constant!\n' +
                            '    const value = Math.random();\n' +
                            '    if (value > 0.5) {\n' +
                            '        keepGoing = false;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                    dar:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (const i = 0; i < 3; i++) {\n' +
                            '    // oops, this is going to fail with\n' +
                            '    // a Type Error after the first iteration\n' +
                            '}'}
                    </SyntaxHighlighter>
                    pentru ca:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{\n' +
                            '    // a fictional variable for illustration\n' +
                            '    const $$i = 0;\n' +
                            '\n' +
                            '    for ( ; $$i < 3; $$i++) {\n' +
                            '        // here\'s our actual loop `i`!\n' +
                            '        const i = $$i;\n' +
                            '        // ..\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <br/>
                    O modalitate de a menține totul clar este să vă amintiți că var, let, și cuvintele cheie const
                    sunt eliminate efectiv din cod în momentul în care acesta începe să se execute.
                    Ele sunt gestionate în întregime de compilator.

                    <hr/>
                    <b>Variabile neinițializate (aka, TDZ)</b>
                    <br/>
                    <br/>
                    Cu vardeclarații, variabila este pusa în partea de sus a domeniului său de aplicare.
                    Dar este, de asemenea, inițializată automat la valoare undefined, astfel încât variabila să poată fi utilizată în întregul domeniu.

                    Cu toate acestea, let și const nu sunt chiar aceleași în acest sens.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'console.log(studentName);\n' +
                            '// ReferenceError\n' +
                            '\n' +
                            'let studentName = "Suzy";'}
                    </SyntaxHighlighter>
                    Acest mesaj de eroare este destul de indicativ pentru ceea ce este greșit: studentName există pe linia 1, dar nu a fost inițializat, deci nu poate fi folosit încă

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'studentName = "Suzy";   // let\'s try to initialize it!\n' +
                            '// ReferenceError\n' +
                            '\n' +
                            'console.log(studentName);\n' +
                            '\n' +
                            'let studentName;'}
                    </SyntaxHighlighter>
                    Încă primim ReferenceError, dar acum pe prima linie în care încercăm să-i atribuim (aka, inițializați!) această așa-numită variabilă „neinițializată” studentName.
                    <br/>
                    Întrebarea adevărată este cum inițializam o variabilă neinițializată?
                    Pentru let/ const, singura modalitate de a face acest lucru este cu o atribuire atașată unei declarații de declarație.

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let studentName = "Suzy";\n' +
                            'console.log(studentName);   // Suzy'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// ..\n' +
                            '\n' +
                            'let studentName;\n' +
                            '// or:\n' +
                            '// let studentName = undefined;\n' +
                            '\n' +
                            '// ..\n' +
                            '\n' +
                            'studentName = "Suzy";\n' +
                            '\n' +
                            'console.log(studentName);\n' +
                            '// Suzy'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Termenul inventat de TC39 pentru a se referi la această perioadă de timp de la intrarea unui domeniu până la care are loc auto-inițializarea variabilei este: Temporal Dead Zone (TDZ).
                        </li>
                        <li>
                            TDZ este fereastra de timp în care o variabilă există, dar este încă neinițializată și, prin urmare, nu poate fi accesată în niciun fel.
                        </li>
                        <li>
                            var are și din punct de vedere tehnic un TDZ, dar are lungimea zero și, prin urmare, nu poate fi observat pentru programele noastre! Doar let și const au un TDZ observabil.
                        </li>
                        <li>
                            TDZ se referă într-adevăr la timp, nu la poziția în cod:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {
                                    'askQuestion();\n' +
                                    '// ReferenceError\n' +
                                    '\n' +
                                    'let studentName = "Suzy";\n' +
                                    '\n' +
                                    'function askQuestion() {\n' +
                                    '    console.log(`${ studentName }, do you know?`);\n' +
                                    '}'
                                }
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Există o concepție greșită comună că TDZ înseamnă let și const nu se ridica. Cu siguranță se ridică.
                            <br/>
                            Diferența reală este că declarațiile let/ const nu se inițializează automat la începutul domeniului, așa cum var se face.
                            Dezbaterea este atunci dacă auto-inițializarea face parte din ridicare sau nu?
                            <br/>
                            Auto-înregistrarea unei variabile în partea de sus a domeniului de aplicare (adică, ceea ce eu numesc „histing”)
                            și auto-inițializarea în partea de sus a domeniului de aplicare (la undefined) sunt operații distincte
                            și nu ar trebui să fie grupate sub un singur termenul de „ridicare”
                        </li>
                    </ul>

                    Fie:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Kyle";\n' +
                            '\n' +
                            '{\n' +
                            '    console.log(studentName);\n' +
                            '    // ???\n' +
                            '    let studentName = "Suzy";\n' +
                            '\n' +
                            '    console.log(studentName);\n' +
                            '    // Suzy\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Se va aruna eroare! Pentru ca let studentName  s-a ridicat în partea de sus a blocului, si cumva vom avea:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'var studentName = "Kyle";\n' +
                            '\n' +
                            '{\n' +
                            '    let studentName; // neinitializat\n' +
                            '    console.log(studentName);\n' +
                            '    let studentName = "Suzy";\n' +
                            '\n' +
                            '    console.log(studentName);\n' +
                            '    // Suzy\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Let si const își ridică declarațiile în partea de sus a domeniului lor, dar, spre deosebire de var,
                    ele amână auto-inițializarea variabilelor până în momentul în secvențierea codului în care a apărut declarația originală.

                    <hr/>
                    Sfat: pune întotdeauna declarațiile let și const la inceputul oricărui domeniu.
                    Reduceți fereastra TDZ la lungime zero (sau aproape de zero) .

                    <hr/>
                    Recomandari:
                    <ul>
                        <li>
                            Dacă o declarație aparține unui domeniu de aplicare bloc, utilizați let.
                        </li>
                        <li>
                            Dacă aparține domeniului de aplicare al funcției, utilizați var
                        </li>
                        <li>
                            intr-o bucla, variabila i ar trebuie sa fie declarata cu let
                        </li>
                    </ul>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HoistingJavaScriptContent;