import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class OverlayNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-overlay", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>
                        1. Creare retea de tip overlay
                    </b>
                    <br/>
                    <br/>


                    Driver-ul de retea overlay creaza o retea distribuita intre mai multe masini gazda Docker daemon.
                    <br/>
                    O retea overlay permite containerelor conectate sa comunice in mod securizat.
                    <br/>
                    Daca avem o aplicatie distribuita pe mai multe noduri, pentru a face a aceasta sa comunice internoduri,
                    nu mai este suficienta o retea de tip <i>bridge</i>, ci este nevoie de tip <i>overlay</i>
                    <br/>
                    <br/>
                    Pentru o retea de tip overlay, containerele sunt imprastiate mai oe mai mule noduri.
                    Daca conatainerele comunica intre ele, este recomandat sa se securizeze comunicarea.
                    Pentru a activa criptarea, ca se creaza o retea de tip overlay, se foloseste optiunea <b>--opt encrypted</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --opt encrypted --driver overlay kj-overlay-net'}
                    </SyntaxHighlighter>
                    Cand se activeaza criptarea:
                    <ul>
                        <li>Docker creaza tunele IPSEc intre toate nodurile pe care sunt exista task-uri planificare pentru serviciile atasate la reteau de tip overlay</li>
                        <li>tunelele folosesc algoritmul AES in GCM mod si mangager-ul nodurilor roteste in mod automat aceste chei la fiecare 12 ore</li>
                        <li>pe Windows nu este suportata criptarea pe o retea de tip overlay; daca un nod pe Windows incerca sa se contecteze la o retea de tip overlay,
                        nu se arunca nici o eroare, dar nodul nu va fi capabil sa comunice</li>
                    </ul>


                    <br/>
                    <br/>
                    Afisare retele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'NETWORK ID     NAME              DRIVER    SCOPE\n' +
                            'b94ae5149555   bridge            bridge    local\n' +
                            '30eac62e784a   docker_gwbridge   bridge    local\n' +
                            'e15b1da0de59   host              host      local\n' +
                            's95fge3etl7j   ingress           overlay   swarm\n' +
                            '1d440607efc6   none              null      local'}
                    </SyntaxHighlighter>

                    Inspectare retea <i>ingress</i>, de tip <i>overlay</i>, cu scopul <i>swarm</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect ingress'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "ingress",\n' +
                            '        "Id": "s95fge3etl7jutsi0mns8y46a",\n' +
                            '        "Created": "2023-01-02T13:11:40.169015462+02:00",\n' +
                            '        "Scope": "swarm",\n' +
                            '        "Driver": "overlay",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": null,\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "10.0.0.0/24",\n' +
                            '                    "Gateway": "10.0.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": true,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {\n' +
                            '            "ingress-sbox": {\n' +
                            '                "Name": "ingress-endpoint",\n' +
                            '                "EndpointID": "48f6488f86f5a4ce7f0b07b5cbd567ea63210d64e0ffb7214c28fd59b092efd3",\n' +
                            '                "MacAddress": "02:42:0a:00:00:02",\n' +
                            '                "IPv4Address": "10.0.0.2/24",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n' +
                            '        "Options": {\n' +
                            '            "com.docker.network.driver.overlay.vxlanid_list": "4096"\n' +
                            '        },\n' +
                            '        "Labels": {},\n' +
                            '        "Peers": [\n' +
                            '            {\n' +
                            '                "Name": "aa15ccd9e4c6",\n' +
                            '                "IP": "192.168.1.10"\n' +
                            '            },\n' +
                            '            {\n' +
                            '                "Name": "65350d916441",\n' +
                            '                "IP": "192.168.1.6"\n' +
                            '            }\n' +
                            '        ]\n' +
                            '    }\n' +
                            ']\n'}
                    </SyntaxHighlighter>
                    observam:
                    <SyntaxHighlighter>
                        {'"Peers": [\n' +
                            '            {\n' +
                            '                "Name": "aa15ccd9e4c6",\n' +
                            '                "IP": "192.168.1.10"\n' +
                            '            },\n' +
                            '            {\n' +
                            '                "Name": "65350d916441",\n' +
                            '                "IP": "192.168.1.6"\n' +
                            '            }\n' +
                            '        ]\n'}
                    </SyntaxHighlighter>
                    Sunt cele 2 noduri; pentru a verifica:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect nas-ubuntu-manager --format "{{ .Status.Addr  }}"'}
                    </SyntaxHighlighter>
                    {"=>"} 192.168.1.10
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker node inspect nas-ubuntu-kj --format "{{ .Status.Addr  }}"'}
                    </SyntaxHighlighter>
                    {"=>"} 192.168.1.6
                    <hr/>

                    Afisam toate containerele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service ps  web-server'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'ID             NAME           IMAGE          NODE                 DESIRED STATE   CURRENT STATE            ERROR     PORTS\n' +
                            'rmu94epn9t3n   web-server.1   nginx:latest   nas-ubuntu-kj        Running         Running 52 seconds ago\n' +
                            'go2q0tnr2wrl   web-server.2   nginx:latest   nas-ubuntu-manager   Running         Running 51 seconds ago\n' +
                            'ybl7wggqz1li   web-server.3   nginx:latest   nas-ubuntu-kj        Running         Running 52 seconds ago\n' +
                            'q5yemacozi7i   web-server.4   nginx:latest   nas-ubuntu-manager   Running         Running 51 seconds ago'}
                    </SyntaxHighlighter>

                    fiind pe nodul <i>nas-ubuntu-manager</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE          COMMAND                  CREATED         STATUS         PORTS     NAMES\n' +
                            'fb350b5bc044   nginx:latest   "/docker-entrypoint.…"   5 minutes ago   Up 5 minutes   80/tcp    web-server.4.q5yemacozi7i03r4fuyouasy8\n' +
                            'e8bb6549950d   nginx:latest   "/docker-entrypoint.…"   5 minutes ago   Up 5 minutes   80/tcp    web-server.2.go2q0tnr2wrl6aqitbah9mtwo\n'}
                    </SyntaxHighlighter>

                    inspectam de exemplu containerul <i>fb350b5bc044</i> de pe nodul <i>nas-ubuntu-manager</i> pentru a obtine IP-ul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect fb350b5bc044 | grep IPAddress'}
                    </SyntaxHighlighter>
                    {"=>"} 10.0.0.8

                    <br/>
                    <br/>
                    fiind pe nodul <i>nas-ubuntu-kj</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker ps'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'CONTAINER ID   IMAGE           COMMAND                  CREATED          STATUS                          PORTS     NAMES\n' +
                            '5c706fa20245   nginx:latest    "/docker-entrypoint.…"   10 minutes ago   Up 10 minutes                   80/tcp    web-server.1.rmu94epn9t3n12m60f3yufsmr\n' +
                            'a735e528227d   nginx:latest    "/docker-entrypoint.…"   10 minutes ago   Up 10 minutes                   80/tcp    web-server.3.ybl7wggqz1licsthhxgpoi15r\n'}
                    </SyntaxHighlighter>

                    inspectam de exemplu containerul <i>5c706fa20245</i> de pe nodul <i>nas-ubuntu-kj</i> pentru a obtine IP-ul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container inspect 5c706fa20245 | grep IPAddress'}
                    </SyntaxHighlighter>
                    {"=>"} 10.0.0.5
                    <br/>
                    <br/>

                    Ne conectam la containerul <i>5c706fa20245 sh</i> (care are IP-ul 10.0.0.5):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker exec -it 5c706fa20245 bash'}
                    </SyntaxHighlighter>
                    Si facem ping la 10.0.0.8, care este pe alt nod:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ping 10.0.0.8'}
                    </SyntaxHighlighter>
                    <hr/>
                    Daca nu exista comanda <b>ping</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'apt-get update\n' +
                            'apt-get install iputils-ping'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a creea o retea de tip overlay (trebuie creata de pe nodul manager):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --driver overlay kj-overlay-net'}
                    </SyntaxHighlighter>
                    Daca se incerca crearea de pe un nod worker:
                    <SyntaxHighlighter>
                        {'Error response from daemon: Cannot create a multi-host network from a worker node. Please create the network from a manager node.'}
                    </SyntaxHighlighter>

                    Pentru a creea o retea cu criptarea activata, se foloseste optiunea <b>--opt encrypted</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create --opt encrypted --driver overlay kj-overlay-net'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls'}
                    </SyntaxHighlighter>
                    vom avea:
                    <SyntaxHighlighter>
                        {'NETWORK ID     NAME              DRIVER    SCOPE\n' +
                            'b94ae5149555   bridge            bridge    local\n' +
                            '30eac62e784a   docker_gwbridge   bridge    local\n' +
                            'e15b1da0de59   host              host      local\n' +
                            's95fge3etl7j   ingress           overlay   swarm\n' +
                            '2wy7iv846y2z   kj-overlay-net    overlay   swarm\n' +
                            '1d440607efc6   none              null      local\n'}
                    </SyntaxHighlighter>

                    La creare unui serviciu se poate specifica noua retea:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker service create --name web-server-2 --network kj-overlay-net --replicas 3 nginx'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'tt2qk7n4emsqb931nx6tosxxs\n' +
                            'overall progress: 3 out of 3 tasks\n' +
                            '1/3: running   [==================================================>]\n' +
                            '2/3: running   [==================================================>]\n' +
                            '3/3: running   [==================================================>]\n' +
                            'verify: Service converged\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OverlayNetworkDockerContent;