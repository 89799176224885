import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class ViteReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-vite", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Migrare de la CRA () la Vite</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>1.1. Instalare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm install --save-dev vite @vitejs/plugin-react vite-tsconfig-paths vite-plugin-svgr'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Creare fișier de configurare Vite <b>vite.config.ts</b> in rădăcina proiectului</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'\n' +
                            'import { defineConfig } from \'vite\';\n' +
                            'import react from \'@vitejs/plugin-react\';\n' +
                            'import viteTsconfigPaths from \'vite-tsconfig-paths\';\n' +
                            'import svgrPlugin from \'vite-plugin-svgr\';\n' +
                            '\n' +
                            '// https://vitejs.dev/config/\n' +
                            'export default defineConfig({\n' +
                            '  plugins: [react(), viteTsconfigPaths(), svgrPlugin()],\n' +
                            '});'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. Mutare index.html din /public in radacina proictului</b>
                    <br/>
                    <br/>


                    <hr/>
                    <b>1.4. Actualizare index.html</b>
                    <br/>
                    <br/>
                    Adresele URL sunt tratate puțin diferit în Vite, așa că va trebui să eliminăm toate referințele la %PUBLIC_URL%. De exemplu:

                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// Inainte\n' +
                            '<link rel="icon" href="%PUBLIC_URL%/favicon.ico" />\n' +
                            '\n' +
                            '// Dupa\n' +
                            '<link rel="icon" href="/favicon.ico" />'}
                    </SyntaxHighlighter>

                    Trebuie să adăugăm un punct de intrare elementului <b>body</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<noscript>You need to enable JavaScript to run this app.</noscript>\n' +
                            '<div id="root"></div>\n' +
                            '<!-- Add entry point 👇 -->\n' +
                            '<script type="module" src="/src/index.tsx"></script> <!-- pt typescript-->\n' +
                            '<script type="module" src="/src/index.js"></script> <!-- pt javascript -->'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.5. Actualizare tsconfig.json</b>
                    <br/>
                    <br/>

                    Principalele lucruri ce trebuie actualizate în fișier <b>tsconfig.json</b> sunt:
                    <ul>
                        <li>target</li>
                        <li>
                            lib
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{\n' +
                            '  "compilerOptions": {\n' +
                            '    "target": "ESNext",\n' +
                            '    "lib": ["dom", "dom.iterable", "esnext"],\n' +
                            '    "types": ["vite/client", "vite-plugin-svgr/client"],\n' +
                            '    "allowJs": false,\n' +
                            '    "skipLibCheck": false,\n' +
                            '    "esModuleInterop": false,\n' +
                            '    "allowSyntheticDefaultImports": true,\n' +
                            '    "strict": true,\n' +
                            '    "forceConsistentCasingInFileNames": true,\n' +
                            '    "noFallthroughCasesInSwitch": true,\n' +
                            '    "module": "ESNext",\n' +
                            '    "moduleResolution": "node",\n' +
                            '    "resolveJsonModule": true,\n' +
                            '    "isolatedModules": true,\n' +
                            '    "noEmit": true,\n' +
                            '    "jsx": "react-jsx"\n' +
                            '  },\n' +
                            '  "include": ["src"]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.6. Creare fisier: vite-env.d.ts</b>
                    <br/>
                    <br/>
                    Daca se foloseste Typescript, trebuie creat un fisier <b>vite-env.d.ts</b> in <b>/src</b>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'/// <reference types="vite/client" />'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.7. Stergere CRA</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm uninstall react-scripts'}
                    </SyntaxHighlighter>

                    Daca exista, se poate sterge si fisierul <b>react-app-env.d.ts</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'react-app-env.d.ts'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.8. Actualizare scripturi în package.json</b>
                    <br/>
                    <br/>

                    Deoarece am eliminat react-scripts, acum trebuie să actualizăm scripturile din interior package.json pentru a face referire vite în schimb:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'"scripts": {\n' +
                            '  "start": "vite",\n' +
                            '  "build": "tsc && vite build",\n' +
                            '  "serve": "vite preview"\n' +
                            '},\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.8. Actualizare scripturi în package.json</b>
                    <br/>
                    <br/>

                    Pornire:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm start'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Schimbare port</b>
                    <br/>
                    <br/>
                    Dacă trebuie să schimbați numărul de port din valoarea implicită 3000, specificați astfel:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'// vite.config.ts\n' +
                            '\n' +
                            'export default defineConfig({\n' +
                            '  ...\n' +
                            '  server: {\n' +
                            '    port: 4000,\n' +
                            '  },\n' +
                            '});\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Deschidere automata a aplicației la pornirea serverului</b>
                    <br/>
                    <br/>

                    La CRA deschide automat aplicația în browser la pornirea serverului. Vite are și această opțiune, dar nu implicita:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'export default defineConfig({\n' +
                            '  ...\n' +
                            '  server: {\n' +
                            '    open: true,\n' +
                            '  },\n' +
                            '});'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://cathalmacdonnacha.com/migrating-from-create-react-app-cra-to-vite"}>
                               Migrating from Create React App (CRA) to Vite
                           </a>
                       </li>
                        {/*<li>*/}
                        {/*    <a target={"_blank"} href={"https://codecraft.tv/courses/angular/unit-testing/asynchronous/"}>*/}
                        {/*        Testing Asynchronous Code*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ViteReactContent;