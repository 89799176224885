import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ModulesJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-modules", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Modulul (cunoscut oficial sub numele de Java Platform Module System - JPMS) a fost introdus in Java 9, fiind un nivel de abastractizare deasupra pachetelor.

                    <br/>
                    Un modul este <b>un grup de pachete și resurse (xml-uri, imagini, etc) strâns legate împreună</b> prin intemediul unui <b>fișier descriptor</b>.

                    <br/>
                    Cand creeam un modul, in fisierul descriptor, putem specifica:
                    <ul>
                        <li>numele modului nostru</li>
                        <li>dependintele: o lista de alte module de care depinde modulul nostru</li>
                        <li>pachetele publice: o lista cu toate pachetele care vor putea fi accesate din afara modulului nostru</li>
                        <li>serviciile oferite: putem oferi implmentarii de servicii care pot fi consumate de alte module</li>
                        <li>serviciile consumate: permite modulului nostru sa fie consumator al unui serviciu</li>
                        <li>permisiuni de reflectie: permite in mod explicit altor clase sa foloseasca reflectarea pentru accesarea membrilor privati ai unui pachet</li>
                    </ul>

                    Un modul are un singur fisier <b>module-info.java</b> si mai multe pachete.

                    <br/>
                    Regulile de denumirea a modulelor sunt similare cu felul in care se denumesc pachetele ( punctele . sunt permise, liniutele - nu).

                    <br/>
                    In cadrul unui modul:
                    <ul>
                        <li>implicit, toate pachetele sunt private</li>
                        <li>implicit, nu se poate folosi reflexia pe clasele importate in alt modul</li>
                    </ul>

                    Tipuri de module:
                    <ul>
                        <li><b>module de sistem</b>: module Java SE si JDK (module enumerate cand rulam comanda <b>java --list-modules</b>)
                            <div className={"max-height-300"}>
                                <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'java --list-modules\n' +
                                'java.base@11\n' +
                                'java.compiler@11\n' +
                                'java.datatransfer@11\n' +
                                'java.desktop@11\n' +
                                'java.instrument@11\n' +
                                'java.logging@11\n' +
                                'java.management@11\n' +
                                'java.management.rmi@11\n' +
                                'java.naming@11\n' +
                                'java.net.http@11\n' +
                                'java.prefs@11\n' +
                                'java.rmi@11\n' +
                                'java.scripting@11\n' +
                                'java.se@11\n' +
                                'java.security.jgss@11\n' +
                                'java.security.sasl@11\n' +
                                'java.smartcardio@11\n' +
                                'java.sql@11\n' +
                                'java.sql.rowset@11\n' +
                                'java.transaction.xa@11\n' +
                                'java.xml@11\n' +
                                'java.xml.crypto@11\n' +
                                'jdk.accessibility@11\n' +
                                'jdk.aot@11\n' +
                                'jdk.attach@11\n' +
                                'jdk.charsets@11\n' +
                                'jdk.compiler@11\n' +
                                'jdk.crypto.cryptoki@11\n' +
                                'jdk.crypto.ec@11\n' +
                                'jdk.crypto.mscapi@11\n' +
                                'jdk.dynalink@11\n' +
                                'jdk.editpad@11\n' +
                                'jdk.hotspot.agent@11\n' +
                                'jdk.httpserver@11\n' +
                                'jdk.internal.ed@11\n' +
                                'jdk.internal.jvmstat@11\n' +
                                'jdk.internal.le@11\n' +
                                'jdk.internal.opt@11\n' +
                                'jdk.internal.vm.ci@11\n' +
                                'jdk.internal.vm.compiler@11\n' +
                                'jdk.internal.vm.compiler.management@11\n' +
                                'jdk.jartool@11\n' +
                                'jdk.javadoc@11\n' +
                                'jdk.jcmd@11\n' +
                                'jdk.jconsole@11\n' +
                                'jdk.jdeps@11\n' +
                                'jdk.jdi@11\n' +
                                'jdk.jdwp.agent@11\n' +
                                'jdk.jfr@11\n' +
                                'jdk.jlink@11\n' +
                                'jdk.jshell@11\n' +
                                'jdk.jsobject@11\n' +
                                'jdk.jstatd@11\n' +
                                'jdk.localedata@11\n' +
                                'jdk.management@11\n' +
                                'jdk.management.agent@11\n' +
                                'jdk.management.jfr@11\n' +
                                'jdk.naming.dns@11\n' +
                                'jdk.naming.rmi@11\n' +
                                'jdk.net@11\n' +
                                'jdk.pack@11\n' +
                                'jdk.rmic@11\n' +
                                'jdk.scripting.nashorn@11\n' +
                                'jdk.scripting.nashorn.shell@11\n' +
                                'jdk.sctp@11\n' +
                                'jdk.security.auth@11\n' +
                                'jdk.security.jgss@11\n' +
                                'jdk.unsupported@11\n' +
                                'jdk.unsupported.desktop@11\n' +
                                'jdk.xml.dom@11\n' +
                                'jdk.zipfs@11'}
                            </SyntaxHighlighter>
                            </div>
                        </li>
                        <li><b>module de aplicatie</b>: module difinite in fisierul <b>module-info.class</b> (inclus in JAR)
                        </li>
                        <li><b>module automate</b>: putem include module neoficiale, adaugand fisiere JAR la calea modulului;

                        </li>
                        <li><b>modul fara nume</b>: pentru compatibilitatea cu codul Java mai vechi (can o clasa sau un JAR este incarcat in afara unui modul, acesta este adaugat la modulul fara nume )</li>
                    </ul>

                    Declarare modul:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'module numeModul {\n' +
                            '// toate directivele sunt optionale\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sa prespunem ca avem urmatoarea ierarhie:
                    <SyntaxHighlighter>
                        {'src\n' +
                            '  └── main\n' +
                            '       └── java\n' +
                            '            └── ro.discovery\n' +
                            '                 ├── ro\n' +
                            '                 │      └── discovery\n' +
                            '                 │              ├── internal\n' +
                            '                 │              │      ├── AttachOutput.java\n' +
                            '                 │              │      └── PlainAttachOutput.java\n' +
                            '                 │              ├── VMIntrospector.java\n' +
                            '                 │              └── Discovery.java\n' +
                            '                 └── module-info.java'}
                    </SyntaxHighlighter>

                    Fisierul <i>module-info.java</i> poate avea urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'module ro.discovery {\n' +
                            '  exports ro.discovery;\n' +
                            ' \n' +
                            '  requires java.instrument;\n' +
                            '  requires jdk.attach;\n' +
                            '  requires jdk.internal.jvmstat;\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Se folosesc urmatoare cuvinte cheie al descriptorului (nu si in sursele Java):
                    <ul>
                        <li>
                            module
                        </li>
                        <li>
                            exports: asteapta ca argument un <i>nume de pachet</i>:
                            <br/>
                            <SyntaxHighlighter>
                                {'exports ro.discovery'}
                            </SyntaxHighlighter>
                            pachetul <i>ro.discovery.internal</i> nu va fi exportat!
                        </li>
                        <li>
                            requires: declară o dependență a modulului curent si asteapta ca argument un <i>nume de modul</i>:
                            <SyntaxHighlighter>
                                {'requires java.instrument;'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            open
                        </li>
                        <li>
                            opens
                        </li>
                        <li>
                            transitive: pentru preluarea dependintelor tranzitive (trebuie minimizata utilizare tranzitivitatii)
                        </li>
                        <li>
                            to
                        </li>
                        <li>
                            uses
                        </li>
                        <li>
                            provides
                        </li>
                        <li>
                            with
                        </li>
                    </ul>

                    Pentru a afisa detalii despre un modul in Windows (PowerShell):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'jmod describe $env:JAVA_HOME/jmods/java.base.jmod'}
                    </SyntaxHighlighter>

                    Modul <b>java.base</b> este întotdeauna o dependență implicită a fiecărui modul, deci nu trebuie să fie declarate explicit în <b>module-info.java</b>!

                    <hr/>
                    Compilarea unui modul:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javac -d out ro.discovery/module-info.java ro.discovery/ro/discovery/*.java ro.discovery/ro/discovery/*/*.java'}
                    </SyntaxHighlighter>

                    <hr/>
                    Executia unui aplicatii modulare
                    <br/>
                    <br/>
                    Pana la aparitia modulelor, existau 2 metode de a pornit o aplicatie Java:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java -cp classes ro.Hello'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java -jar my-app.jar'}
                    </SyntaxHighlighter>

                    Acum, se poate lansa si in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java --module-path mods -m my.module/my.module.Main'}
                    </SyntaxHighlighter>

                    <hr/>
                    Comutatoare din linia de comanda pentru module:
                    <ul>
                        <li>
                            list-modules: listeaza toate modulele
                        </li>
                        <li>
                            module-path: precizeaza unul sau mai multe directoare care contin modulul
                        </li>
                        <li>
                            add-reads: adauga <b>requires</b> suplimentar la rezolutie
                        </li>
                        <li>
                            add-exports: adauga un export (<b>exports</b> )suplimentar la compilare
                        </li>
                        <li>
                            add-opens: activeaza access reflectiv in timpul rularii
                        </li>
                        <li>
                            add-modules: adauga lista de module la setul implicit
                        </li>
                        <li>
                            illegal-access=permit|warn|deny: schimbar de reguli de access la reflexie
                        </li>
                    </ul>

                    <hr/>
                    Afisare dependinte pentru o clasa:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'jdeps HelloWorld.class'}
                    </SyntaxHighlighter>
                    Poate afisa:
                    <SyntaxHighlighter>
                        {'HelloWorld.class -> java.base\n' +
                            '   <unnamed>                                          -> java.io                                            java.base\n' +
                            '   <unnamed>                                          -> java.lang                                          java.base'}
                    </SyntaxHighlighter>

                    <hr/>
                    Construirea unui JAR cu versiuni multiple:

                    <SyntaxHighlighter>
                        {'.\n' +
                            '└── src\n' +
                            '     ├── main\n' +
                            '     │      └── java\n' +
                            '     │              └── wgjd2ed\n' +
                            '     │                      ├── Main.java\n' +
                            '     │                      └── GetPID.java\n' +
                            '     └── versions\n' +
                            '          └── 11\n' +
                            '               └── java\n' +
                            '                    └── wgjd2ed\n' +
                            '                         └── GetPID.java'}
                    </SyntaxHighlighter>

                    Se compileaza pentru Java 8:
                    <SyntaxHighlighter>
                        {'javac --release 8 -d out src/main/java/wgjd2ed/*.java'}
                    </SyntaxHighlighter>

                    Se compileaza pentru Java 11:
                    <SyntaxHighlighter>
                        {'javac --release 11 -d out-11 versions/11/java/wgjd2ed/GetPID.java'}
                    </SyntaxHighlighter>

                    Generarea JAR si fisier <b>MANIFEST.MF</b>:
                    <SyntaxHighlighter>
                        {'$ jar --create --release 11 \\\n' +
                            '      --file pid.jar --main-class=wgjd2ed.Main\n' +
                            '      -C out/ . \\\n' +
                            '      -C out-11/ .'}
                    </SyntaxHighlighter>

                    Rulare:
                    <SyntaxHighlighter>
                        {'java -jar pid.jar'}
                    </SyntaxHighlighter>

                    <hr/>
                    JLink
                    <SyntaxHighlighter>
                        {'$ jlink --module-path $JAVA_HOME/jmods/:out  --output bundle/ --add-modules\n' +
                            '  wgjd.discovery'}
                    </SyntaxHighlighter>

                    Limitari:
                    <ul>
                        <li>
                            Va funcționa numai cu o aplicație cu dependențe complet modulare.
                        </li>
                        <li>
                            Nu funcționează cu cod nemodular.
                        </li>
                        <li>
                            Nici măcar modulele automate nu sunt suficiente.
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.baeldung.com/java-9-modularity"}>A Guide to Java 9 Modularity</a>
                            </div>
                        </li>
                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ModulesJavaContent;