import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class StringsJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-strings", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Un obiect de tip String:
                    <ul>
                        <li>
                            stocheaza un sir de caractere
                        </li>
                        <li>
                            este imutabil (nu este permisa modificarea acestuia)
                        </li>
                    </ul>

                    Opeator:
                    <ul>
                        <li><b>+</b>: concatenare</li>
                    </ul>

                    Metode:
                    <ul>
                        <li><b>length()</b> : returneaza lungimea sirului de caractere</li>
                        <li><b>charAt(index)</b>: returneaza caracterul de pe pozitia <i>index</i>; daca indexul este in afara intervalului permis se arunca exceptia <b>java.lang.StringIndexOutOfBoundsExceptions</b></li>
                        <li><b>indexOf()</b>: returneaza pozitia de la care incepe un caracter sau un subsir de caractere in cadrul unui sir de caractere; returneaza -1, daca nu il gaseste;
                            <br/>
                            Signaturi:
                            <ul>
                                <li>
                                    int indexOf(int ch)
                                </li>
                                <li>
                                    int indexOf(int ch, int fromIndex)
                                </li>
                                <li>
                                    int indexOf(String str)
                                </li>
                                <li>
                                    int indexOf(String str, int fromIndex)
                                </li>
                            </ul>
                        </li>
                        <li><b>substring()</b>: returneaza un substring incepand cu pozitia data de primul parametrul; si poate fi limit de al doilea parametru
                            <br/>
                            Signaturi:
                            <ul>
                                <li>
                                    String substring(int beginIndex)
                                </li>
                                <li>
                                    String substring(int beginIndex, int endIndex)
                                </li>

                            </ul>
                        </li>
                        <li>
                            <b>toLowerCase()</b>: returneaza un nou sir de caractere, format din caracterele obiectului pe care se apleleaza, transformate in litere mici
                        </li>
                        <li>
                            <b>toUpperCase()</b>: returneaza un nou sir de caractere, format din caracterele obiectului pe care se apleleaza, transformate in litere mari
                        </li>
                        <li>
                            <b>equals()</b>: verifica daca doua siruri de caractere contin aceleasi caractere si in aceeasi ordine
                            <br/>
                            Signatura:
                            <ul>
                                <li>boolean equals(Object obj)</li>
                            </ul>
                        </li>
                        <li>
                            <b>equalsIgnoreCase()</b>: verifica daca doua siruri de caractere contin aceleasi caractere si in aceeasi ordine, ignorand insa, daca sunt scrise cu litere mari sau litere mici
                            Signatura:
                            <ul>
                                <li>boolean equalsIgnoreCase(String str)</li>
                            </ul>
                        </li>
                        <li>
                            <b>startWith()</b>: verifica daca sirul de caractere incepe cu un prefix dat ca parametru
                            Signatura:
                            <ul>
                                <li>boolean startWith(String prefix)</li>
                            </ul>
                        </li>
                        <li>
                            <b>endWith()</b>: verifica daca sirul de caractere se termina cu un sufix dat ca parametru
                            Signatura:
                            <ul>
                                <li>boolean endWith(String sufix)</li>
                            </ul>
                        </li>
                        <li>
                            <b>replace()</b>: cauta si inlocuiete un caracter/subsir cu alt caracter/subsir
                            Signatura:
                            <ul>
                                <li>String replace(char oldChar, char newChar)</li>
                                <li>String replace(CharSequence target, CharSequence replacement)</li>
                            </ul>
                        </li>
                        <li>
                            <b>contains()</b>: similar cu <i>string.indexOf(substring)!=-1</i>

                            Signatura:
                            <ul>
                                <li>boolean contains(CharSequence substring)</li>

                            </ul>

                        </li>
                        <li>
                            <b>trim()</b>: returneaza un nou sir de caractere, din care au fost sterse caracterele albe de la inceputul si sfarsitul sirului de caractere
                        </li>
                        <li>
                            <b>strip()</b>: similara cu trim(), dar suporta Unicode
                        </li>
                        <li>
                            <b>stripLeading()</b>: returneaza un nou sir de caractere, din care au fost sterse caracterele albe de la inceputul sirului de caractere
                        </li>
                        <li>
                            <b>stripTrailing()</b>: returneaza un nou sir de caractere, din care au fost sterse caracterele albe de la sfarsitul sirului de caractere
                        </li>
                        <li>
                            <b>intern()</b>: returneaza valoarea din string pool (daca exista); daca nu exista, o adauga in string pool.
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StringsJavaContent;