import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateClientRolesKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-client-roles", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Creare roluri de client</b>
                    <ul>
                        <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                        <li>se face click pe <b>Clients</b> (meniul din stanga):</li>
                        <li>se alege un client (de exemplu, clientul <b>liferay-portal-client</b> ) din lista cu clienti (tab <b>Lookup</b>)</li>
                        <li>se alege tab-ul <b>Roles</b> si apoi se apasa butonul dreapta <b>Add Role</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-23.png'}/>
                            </div>
                        </li>
                        <li>se completeaza formularul de adaugare rol si se apasa apoi butonul <b>Save</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-24.png'}/>
                            </div>
                        </li>
                        <li>putem adauga oricate roluri; pentru testare mai adaugam un rol <b>administrator</b>; in final vom aveam 2 roluri:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-25.png'}/>
                            </div>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateClientRolesKeycloakContent;