import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class ServerPushNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-server-push", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Server Push</b>

                    <br/>
                    <br/>

                    In mod normal daca avem un fisier html (index.html) care are referinte catre un css (style.css) si o poza (thumb.png), se vor face 3 request-uri pe server (3 conexiuni).
                    <br/>
                    Cu HTTP2, se va folosi 1 singura conexiune.
                    <br/>
                    <br/>

                    Cu Server Push putem trimite css (style.css) si poza (thumb.png) odata cu fisier html (index.html).

                    Pentru a testa nu se va folosi un browser, ci <b>nghttp2</b>. Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'apt-get install nghttp2-client'}
                    </SyntaxHighlighter>
                    Testare (se aduce doar index.html, fara resurse):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nghttp -nys https://IP/index.html'}
                    </SyntaxHighlighter>

                    Testare (se aduce doar index.html + resurse: css, png):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nghttp -nysa https://IP/index.html'}
                    </SyntaxHighlighter>

                    Dar daca vom scrie in <b>nginx.conf</b> (<b>nu se specifica resursa, ci cererea catre resursa</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'location = /index.html {\n' +
                            '      http2_push /style.css;\n' +
                            '      http2_push /thumb.png;\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Acum, daca vom scrie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nghttp -nys https://IP/index.html'}
                    </SyntaxHighlighter>
                    Se vor aduce toate cele 3 resurse (resursele "push" sunt marcate cu "*")

                    <br/>
                    <br/>

                    Exemplu complet:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'user www-data;\n' +
                            '\n' +
                            'worker_processes auto;\n' +
                            '\n' +
                            'events {\n' +
                            '  worker_connections 1024;\n' +
                            '}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '  include mime.types;\n' +
                            '\n' +
                            '  server {\n' +
                            '\n' +
                            '    listen 443 ssl http2;\n' +
                            '    server_name 167.99.93.26;\n' +
                            '\n' +
                            '    root /sites/demo;\n' +
                            '\n' +
                            '    index index.php index.html;\n' +
                            '\n' +
                            '    ssl_certificate /etc/nginx/ssl/self.crt;\n' +
                            '    ssl_certificate_key /etc/nginx/ssl/self.key;\n' +
                            '\n' +
                            '    location = /index.html {\n' +
                            '      http2_push /style.css;\n' +
                            '      http2_push /thumb.png;\n' +
                            '    }\n' +
                            '\n' +
                            '    location / {\n' +
                            '      try_files $uri $uri/ =404;\n' +
                            '    }\n' +
                            '\n' +
                            '    location ~\\.php$ {\n' +
                            '      # Pass php requests to the php-fpm service (fastcgi)\n' +
                            '      include fastcgi.conf;\n' +
                            '      fastcgi_pass unix:/run/php/php7.1-fpm.sock;\n' +
                            '    }\n' +
                            '\n' +
                            '  }\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServerPushNginxContent;