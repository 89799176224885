import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TwoFactorAuthenticationNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-two-factor-authentication", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Instalare</b>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'nuxeoctl mp-install nuxeo-duoweb-authentication'}
                    </SyntaxHighlighter>

                    <b>Configurare</b>
                    <br/>
                    Conectare la: <b>https://signup.duo.com</b>
                    <br/>
                    Creare chei conform documentatiei: <b>https://duo.com/docs/duoweb</b>
                    <br/>
                    Edirare fisier XML <b>NUXEO_HOME/templates/duoweb-authentication/config/duoweb-authentication-config.xml</b> adaugand cheile configurate conform documentatiei.


                </div>

                {/*<br/>*/}
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*        <div>*/}
                {/*            <a href={"https://doc.nuxeo.com/nxdoc/acls/"}>*/}
                {/*                ACLs*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TwoFactorAuthenticationNuxeoContent;