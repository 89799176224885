import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class JestReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-jest", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Testare cu Jest </b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm install --save-dev react-test-renderer'}
                    </SyntaxHighlighter>

                    Rulare teste
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm test'}
                    </SyntaxHighlighter>

                    sau (cu <b>--testMatch </b> se schimba paternul dupa care cauta jest fisierele de rest):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'jest --testMatch \'**/react.test.js\''}
                    </SyntaxHighlighter>

                    In mod implicit, cauta dupa:
                    <ul>
                        <li>
                            *.test.js
                        </li>
                        <li>
                            *.spec.js
                        </li>
                    </ul>

                    Fie fisierul <i>react.test.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'test(\'1+1=2\', () =>{\n' +
                            '    expect(1+2).toBe(3);\n' +
                            '})'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/rxjs-operators-forkjoin-zip-combinelatest-withlatestfrom"}>*/}
                    {/*           Înțelegerea operatorilor RxJS: forkJoin, zip, combineLatest și withLatestFrom*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JestReactContent;