import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class SpringBootGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-spring-boot", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Adaugare plugin-urile necesare:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins{\n' +
                        '\tid "org.springframework.boot" version "2.6.4"\n' +
                        '\tid "io.spring.dependency-management" version "1.0.11.RELEASE"\n' +
                        '\tid "java"\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Task-uri:
                    <ul>
                        {/*<li><b>buildJar</b> (din plugin-ul <i>org.springframework.boot</i>)</li>*/}
                        <li><b>bootRun</b> (din plugin-ul <i>org.springframework.boot</i>): porneste aplicatia Spring Boot (fata build)</li>
                        <li><b>bootJar</b>: construieste un Jar executabil pentru o aplicatie Spring Boot (fara executare teste)
                            <br/>
                            Apoi jar-ul rezultat poate fi folosit astfel pentru a pornit aplicatia Spring Boot:
                            <SyntaxHighlighter>
                                {'java -jar build/libs/demo.jar'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Plugin-ul <i>io.spring.dependency-management</i> gestioneaza ce versiune este nevoie pentru a anumita librarie de spring (ex: <i>spring-boot-starter-web</i>);
                    astfel ca nu mai este nevoie de specificarea versiunii in sectiunea de dependinte:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies{\n' +
                        '\timplementation "org.springframework.boot:spring-boot-starter-web"\n' +
                        '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpringBootGradleContent;