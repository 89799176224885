import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class UpgradeProcessesLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-upgrade-processes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Sa presupunem ca lucram cu <b>Liferay Forms</b> si avem prepopulam niste campuri.

                    <hr/>

                    <b>Upgrade Processes</b>
                    <br/>
                    <br/>

                    Dupa ce se executa un Upgrade Processes pentru a trece de la o versiune la alta versiune cu succes
                    acesta <b>nu se mai executa inca o data</b>!
                    <br/>
                    De exemplu:
                    <SyntaxHighlighter>
                        {'registry.register("0.0.0", "1.0.0", new DummyUpgradeStep());\n' +
                            'registry.register("1.0.0", "1.1.0", new UpdateDefaultCompanyTypeSettingsUpgradeProcess(company.getCompanyId(), groupLocalService));\n' +
                            'registry.register("1.1.0", "1.2.0", new AddIndexDocumentNameUpgradeProcess());\n' +
                            'registry.register("1.2.0", "1.3.0", new AddIndexDocumentPathUpgradeProcess());\n' +
                            'registry.register("1.3.0", "1.4.0", new AddIndexDocumentPathUpgradeProcess());'}
                    </SyntaxHighlighter>
                    Daca s-a trecut de la versiunea 1.2.0 -> 1.3.0, si am gresit ceva in AddIndexDocumentPathUpgradeProcess, aceasta nu se mai executa inca o data!
                    <br/>
                    Dar putem sa facem o nou versiun 1.3.0 -> 1.4.0, pentru a rula varianta corecta pentru AddIndexDocumentPathUpgradeProcess!

                    <hr/>

                    <b>1. Fisierul <b>bnd.bnd</b></b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: Ibrid Upgrade\n' +
                            'Bundle-SymbolicName: ro.ibrid.upgrade\n' +
                            'Bundle-Version: 1.0.0'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Dacă <b>Liferay-Require-SchemaVersion</b> nu este specificat in antet, Liferay DXP consideră că <b>Bundle-Version</b>
                            este valoarea pentru versiunea schemei bazei de date

                            <SyntaxHighlighter>
                                {'Liferay-Require-SchemaVersion: 1.1'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Fisierul <b>build.gradle</b></b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '    compileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca e nevoie se adauga si urmatoarea dependenta:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'compile group: "com.liferay", name: "com.liferay.portal.upgrade.api", version: "2.0.3"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Implementare <b>UpgradeProcess</b></b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class MyUpgradeSchemaClass extends UpgradeProcess {\n' +
                            '  ...\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Se implementeaza metoda:
                    <ul>
                        <li><b>doUpgrade()</b></li>
                    </ul>
                    Exemplu (<b>runSQLTemplateString</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class UpgradeSchema extends UpgradeProcess {\n' +
                            '\n' +
                            '  @Override\n' +
                            '  protected void doUpgrade() throws Exception {\n' +
                            '      String template = StringUtil.read(\n' +
                            '          UpgradeSchema.class.getResourceAsStream("dependencies/update.sql"));\n' +
                            '\n' +
                            '      runSQLTemplateString(template, false, false);\n' +
                            '\n' +
                            '      upgrade(UpgradeMVCCVersion.class);\n' +
                            '\n' +
                            '      alter(\n' +
                            '          JournalArticleTable.class,\n' +
                            '          new AlterColumnName(\n' +
                            '              "structureId", "DDMStructureKey VARCHAR(75) null"),\n' +
                            '          new AlterColumnName(\n' +
                            '              "templateId", "DDMTemplateKey VARCHAR(75) null"),\n' +
                            '          new AlterColumnType("description", "TEXT null"));\n' +
                            '\n' +
                            '      alter(\n' +
                            '          JournalFeedTable.class,\n' +
                            '          new AlterColumnName("structureId", "DDMStructureKey TEXT null"),\n' +
                            '          new AlterColumnName("templateId", "DDMTemplateKey TEXT null"),\n' +
                            '          new AlterColumnName(\n' +
                            '              "rendererTemplateId", "DDMRendererTemplateKey TEXT null"),\n' +
                            '          new AlterColumnType("targetPortletId", "VARCHAR(200) null"));\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu (<b>alter</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class UpgradeCalendarBooking extends UpgradeProcess {\n' +
                            '\n' +
                            '        @Override\n' +
                            '        protected void doUpgrade() throws Exception {\n' +
                            '                alter(\n' +
                            '                        CalendarBookingTable.class,\n' +
                            '                        new AlterColumnType("description", "TEXT null"));\n' +
                            '        }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu (<b>runSQL</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import java.sql.SQLException;\n' +
                            '\n' +
                            'public class CustomUpgradeProcess extends UpgradeProcess {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doUpgrade() throws Exception {\n' +
                            '        // Adaugă un index pe coloana dorită în tabela ta\n' +
                            '        try {\n' +
                            '            // CREATE INDEX idx_documentname_ibrid_repositorymetadata\n' +
                            '            // ON ibrid_repositorymetadata (documentname);\n'+
                            '            runSQL("CREATE INDEX IF NOT EXISTS idx_nume_tabel ON nume_tabel (nume_coloana);");\n' +
                            '        } catch (SQLException sqle) {\n' +
                            '            throw new RuntimeException(sqle);\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import com.liferay.portal.kernel.util.LoggingTimer;\n' +
                            '\n' +
                            'import java.sql.SQLException;\n' +
                            '\n' +
                            'public class AddPollIdColumnUpgradeProcess extends UpgradeProcess {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doUpgrade() throws Exception {\n' +
                            '        try (LoggingTimer loggingTimer = new LoggingTimer()) {\n' +
                            '            if (!hasColumn("ibrid_form", "pollId")) {\n' +
                            '                runSQL("ALTER TABLE ibrid_form ADD COLUMN pollId LONG");\n' +
                            '            }\n' +
                            '        } catch (SQLException sqle) {\n' +
                            '            throw new RuntimeException(sqle);\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu (schimbare pentru o compania cu FriendlyURL = "/guest"  proprietatea <i>siteInitializerKey=ro.ibrid.site.initializer</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.upgrade.v_1_1_0;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.model.Group;\n' +
                            'import com.liferay.portal.kernel.service.GroupLocalService;\n' +
                            'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import com.liferay.portal.kernel.util.UnicodeProperties;\n' +
                            'import com.liferay.portal.kernel.util.Validator;\n' +
                            '\n' +
                            'public class UpdateDefaultCompanyTypeSettingsUpgradeProcess extends UpgradeProcess {\n' +
                            '\n' +
                            '    private static final String GUEST_GROUP_FRIENDLY_URL = "/guest";\n' +
                            '    private static final String PROPERTY_NAME = "siteInitializerKey";\n' +
                            '    private static final String PGL_SITE_INITIALIZER_KEY = "ro.ibrid.site.initializer";\n' +
                            '\n' +
                            '\n' +
                            '    private final long companyId;\n' +
                            '    private final GroupLocalService groupLocalService;\n' +
                            '\n' +
                            '\n' +
                            '    public UpdateDefaultCompanyTypeSettingsUpgradeProcess(long companyId, GroupLocalService groupLocalService) {\n' +
                            '        this.companyId = companyId;\n' +
                            '        this.groupLocalService = groupLocalService;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doUpgrade() throws Exception {\n' +
                            '\n' +
                            '        Group guestGroup = groupLocalService.fetchFriendlyURLGroup(companyId, GUEST_GROUP_FRIENDLY_URL);\n' +
                            '        if (Validator.isNotNull(guestGroup)) {\n' +
                            '            UnicodeProperties typeSettingsUnicodeProperties = guestGroup.getTypeSettingsProperties();\n' +
                            '\n' +
                            '            typeSettingsUnicodeProperties.setProperty(PROPERTY_NAME, PGL_SITE_INITIALIZER_KEY);\n' +
                            '            groupLocalService.updateGroup(guestGroup.getGroupId(), typeSettingsUnicodeProperties.toString());\n' +
                            '        }\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Implementare <b>UpgradeStepRegistrator</b></b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package com.liferay.mycustommodule.upgrade;\n' +
                            '\n' +
                            'import com.liferay.portal.upgrade.registry.UpgradeStepRegistrator;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '@Component(immediate = true, service = UpgradeStepRegistrator.class)\n' +
                            'public class MyCustomModuleUpgrade implements UpgradeStepRegistrator {\n' +
                            '\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.upgrade;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.model.Company;\n' +
                            'import com.liferay.portal.kernel.service.CompanyLocalService;\n' +
                            'import com.liferay.portal.kernel.service.GroupLocalService;\n' +
                            'import com.liferay.portal.kernel.upgrade.DummyUpgradeStep;\n' +
                            'import com.liferay.portal.upgrade.registry.UpgradeStepRegistrator;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.ibrid.upgrade.v_1_1_0.UpdateDefaultCompanyTypeSettingsUpgradeProcess;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        service = UpgradeStepRegistrator.class\n' +
                            ')\n' +
                            'public class IbridUpgradeStepRegistrator implements UpgradeStepRegistrator {\n' +
                            '    @Override\n' +
                            '    public void register(Registry registry) {\n' +
                            '        Company company = companyLocalService.getCompanies().get(0);\n' +
                            '\n' +
                            '        registry.register("0.0.0", "1.0.0", new DummyUpgradeStep());\n' +
                            '        registry.register("1.0.0", "1.1.0", new UpdateDefaultCompanyTypeSettingsUpgradeProcess(company.getCompanyId(), groupLocalService));\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private CompanyLocalService companyLocalService;\n' +
                            '    @Reference\n' +
                            '    private GroupLocalService groupLocalService;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Insert + actualizare counter</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.request.servicebuilder.internal.upgrade.registry;\n' +
                            '\n' +
                            'import com.liferay.portal.upgrade.registry.UpgradeStepRegistrator;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.request.servicebuilder.internal.upgrade.v_1_1_0.PopulateRequestTypeUpgradeProcess;\n' +
                            'import ro.ibrid.request.servicebuilder.internal.upgrade.v_1_1_1.CounterRequestTypeUpgradeProcess;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        service = UpgradeStepRegistrator.class\n' +
                            ')\n' +
                            'public class NomUpgradeStepRegistrator implements UpgradeStepRegistrator {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void register(Registry registry) {\n' +
                            '        registry.register("1.0.0", "1.1.0", new PopulateRequestTypeUpgradeProcess());\n' +
                            '        registry.register("1.1.0", "1.1.1", new CounterRequestTypeUpgradeProcess());\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.request.servicebuilder.internal.upgrade.v_1_1_0;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import com.liferay.portal.kernel.util.StringUtil;\n' +
                            '\n' +
                            'import java.io.InputStream;\n' +
                            '\n' +
                            'public class PopulateRequestTypeUpgradeProcess extends UpgradeProcess {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doUpgrade() throws Exception {\n' +
                            '\n' +
                            '        String sqlFile = "/META-INF/sql/insert/ibrid_requesttype.sql";\n' +
                            '        InputStream inputStream = PopulateRequestTypeUpgradeProcess.class.getResourceAsStream(sqlFile);\n' +
                            '        String template = StringUtil.read(inputStream);\n' +
                            '        runSQLTemplateString(template, false);\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    si
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.request.servicebuilder.internal.upgrade.v_1_1_1;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import com.liferay.portal.kernel.util.StringUtil;\n' +
                            '\n' +
                            'import java.io.InputStream;\n' +
                            '\n' +
                            'public class CounterRequestTypeUpgradeProcess extends UpgradeProcess {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doUpgrade() throws Exception {\n' +
                            '\n' +
                            '        String sqlFile = "/META-INF/sql/insert/counter_ibrid_requesttype.sql";\n' +
                            '        InputStream inputStream = CounterRequestTypeUpgradeProcess.class.getResourceAsStream(sqlFile);\n' +
                            '        String template = StringUtil.read(inputStream);\n' +
                            '        runSQLTemplateString(template, false);\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierele:
                    <ul>
                        <li>
                            ibdid_requesttype.sql
                        </li>
                        <li>
                            counter_ibrid_requesttype.sql
                        </li>
                    </ul>

                    se gasesc in <b>/resources//META-INF/sql/insert/</b>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a target={"_blank"} href={"https://help.liferay.com/hc/en-us/articles/360031165731-Introduction-to-Upgrade-Processes"}>
                                    Introduction to Upgrade Processes
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UpgradeProcessesLiferayContent;