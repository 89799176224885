import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

// class ClassComponent extends React.Component{
//     componentDidMount() {
//         // implementare logica
//     }
//     render() {
//         return <h1>Hello World</h1>
//     }
// }
//
// const FunComponent = () => {
//     useEffect (()=>{
//         // implementare logica
//     },[])
//     return <h1>Hello World</h1>
// }

// class ClassComponent extends React.Component{
//     componentDidMount() {
//         if (this.props.john != prevProps.john){
//             // implementare logica de update cand se modifica props
//         }
//     }
//     render() {
//         return <h1>Hello World</h1>
//     }
// }
//
// const FunComponent = ({props}) => {
//     useEffect (()=>{
//         // implementare logica
//     },[john])
//     return <h1>Hello World</h1>
// }

// class ClassComponent extends React.Component{
//     componentWillUnmount() {
//         // implementare logica de autodistrugere: muhahaha
//     }
//     render() {
//         return <h1>Hello World</h1>
//     }
// }
//
// const FunComponent = () => {
//     useEffect (()=>{
//
//         return () => {
//             // implementare logica de autodistrugere: muhahaha
//         }
//     },[])
//     return <h1>Hello World</h1>
// }

class LifecycleAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-inject-content", IndexContent);
    }

    number = 7;

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Lifecycle</b>
                    <br/>
                    <br/>


                    Lifecycle:
                    <ul>
                        <li>
                            <b>constructor</b>: această metodă rulează o singură dată pe toată durata de viață a componentei
                        </li>
                        <li>
                            <b>ngOnChanges</b>: această metodă se apeleaza cand se setează sau resetează proprietățile de intrare legate (<b>@Input</b>),
                            este apelată înainte <b>ngOnInit()</b> dacă componenta are intrări legate și când input-ul se modifică
                        </li>
                        <li>
                            <b>ngOnInit</b>: această metodă este rulată o singură dată pe întreaga durată de viață a componentei după prima rulare <b>ngOnChanges</b>
                            <br/>
                            se apeleaza dupa ce proprietatile au fost initializate, dar inainte de a fi randat
                        </li>
                        <li>
                            <b>ngDoCheck</b>: această metodă este apelată imediat după <b>ngOnChanges</b> sau ori de câte ori există modificări de date în template
                            (în mod <b>changeDetection.default</b>)
                            sau ori de câte ori este apelata <b>changeDetectionRef.markForCheck</b> (în mod <b>changeDetection.onPush</b>)
                        </li>
                        <li>
                            <b>ngAfterContentInit</b>: această metodă rulează o singură dată pe întreaga durată de viață a componentei după prima <b>ngDoCheck</b>;
                            <br/>
                            se apeleaza dupa ce content-ul (ng-content) a fost proiectat in view
                        </li>
                        <li>
                            <b>ngAfterContentChecked</b>: această metodă se apleaza mai întâi după <b>ngAfterContentInit</b> și apoi după fiecare <b>ngDoCheck</b>
                        </li>
                        <li>
                            <b>ngAfterViewInit</b>: această metodă rulează o singură dată pe întreaga durată de viață a componentei după prima <b>ngAfterContentChecked</b>;
                            <br/>
                            apelat dupa ce componenta si copii lui au fost initializati (randati);
                            <br/>
                            apelat dupa <b>randare</b>
                        </li>
                        <li>
                            <b>ngAfterViewChecked</b>: această metodă se numește mai întâi după <b>ngAfterViewInit</b> și apoi după fiecare <b>ngAfterContentChecked</b>
                        </li>
                        <li>
                            <b>ngOnDestroy</b>: această metodă rulează o singură dată pe întreaga durată de viață a componentei înainte ca Angular să distrugă componenta.
                        </li>

                    </ul>

                    Observatie:
                    <ul>
                        <li>
                            <i>view</i> e componenta parinte; <i>content</i> e o componenta copil (ng-content)
                        </li>
                    </ul>


                    <hr/>
                    <b>2. React (componente bazate pe clase)</b>
                    <br/>
                    <br/>

                    In <b>React</b>, lifecycle pentru componente bazate pe clase:
                    <br/>
                    Faza de initializare:
                    <ul>
                        <li>
                            <b>constructor</b>:  apelat o dată când componenta este creată.
                        </li>
                        <li>
                            <b>getDerivedStateFromProps</b> această metodă este apelată înainte ca elementele să fie redate în DOM.
                        </li>
                        <li>
                            <b>render</b>:  această metodă este chemată pentru a reda elemente în DOM.
                        </li>
                        <li>
                            <b>componentDidMount</b>: această metodă este apelată după ce componenta este redată în DOM.
                        </li>
                    </ul>
                    Faza de actualizare (când starea sau elementele de recuzită/props-urile se schimbă):
                    <ul>
                        <li>
                            <b>getDerivedStateFromProps</b>: această metodă este apelată când componenta este actualizată, dar înainte ca DOM-ul să fie actualizat.
                        </li>
                        <li>
                            <b>shouldComponentUpdate</b>: această metodă vă permite să rulați logica de comparație pentru a verifica dacă ar trebui să vă actualizați DOM.
                        </li>
                        <li>
                            <b>render</b> această metodă este chemată pentru a actualiza elementele din DOM.
                        </li>
                        <li>
                            <b>getSnapshotBeforeUpdate</b>:
                            această metodă vă permite să accesați starea și elementele de recuzită înainte de actualizarea acestui ciclu.
                            <br/>
                            Această metodă trebuie să meargă mână în mână cu <b>componentDidUpdate</b>.
                        </li>
                        <li>
                            <b>componentDidUpdate</b>: această metodă este apelată după ce componenta este actualizată în DOM.
                        </li>
                    </ul>

                    Faza de demontare:
                    <ul>
                        <li>
                            <b>componentWillUnmount</b>: această metodă este apelată o dată înainte ca React să elimine această componentă din DOM
                        </li>
                    </ul>

                    <hr/>
                    <b>3. React (componente bazate pe functii)</b>
                    <br/>
                    <br/>

                    In <b>React</b>, lifecycle bazate pe functii, are 2 functii hook (<b>useState()</b> si <b>useEffect</b>):
                    <ul>
                        <li>
                            <b>useState</b> :
                            această metodă are 2 părți, primul element al matricei aparține atribuirii de inițializare
                            și al doilea element al matricei aparține actualizării primului element al matricei.
                        </li>
                        <li>
                            <b>a) useEffect()</b>: implementarea cu paranteze drepte la sfârșit, [],  va fi apelată o singură dată la inițializare:

                                <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                    {'class ClassComponent extends React.Component{\n' +
                                        '    componentDidMount() {\n' +
                                        '        // implementare logica\n' +
                                        '    }\n' +
                                        '    render() {\n' +
                                        '        return <h1>Hello World</h1>\n' +
                                        '    }\n' +
                                        '}\n' +
                                        '\n' +
                                        'const FunComponent = () => {\n' +
                                        '    useEffect (()=>{\n' +
                                        '        // implementare logica\n' +
                                        '    },[])\n' +
                                        '    return <h1>Hello World</h1>\n' +
                                        '}'}
                                </SyntaxHighlighter>

                            <b>b) useEffect()</b>: implementarea fără paranteze pătrate la sfârșit va rula codul:
                            <ul>
                                <li>
                                    ori de câte ori vreo stare sau elemente de recuzită (props) se schimbă în componentă.
                                </li>
                            </ul>
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'class ClassComponent extends React.Component{\n' +
                                    '    componentDidMount() {\n' +
                                    '        // implementare logica\n' +
                                    '    }\n' +
                                    '    render() {\n' +
                                    '        return <h1>Hello World</h1>\n' +
                                    '    }\n' +
                                    '}\n' +
                                    '\n' +
                                    'const FunComponent = () => {\n' +
                                    '    useEffect (()=>{\n' +
                                    '        // implementare logica\n' +
                                    '    })\n' +
                                    '    return <h1>Hello World</h1>\n' +
                                    '}'}
                            </SyntaxHighlighter>

                            <b>c) useEffect()</b>: implementarea cu variabila între paranteze pătrate se va rula numai atunci când variabila a fost modificată sau actualizată.

                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'class ClassComponent extends React.Component{\n' +
                                    '    componentDidMount() {\n' +
                                    '        if (this.props.john != prevProps.john){\n' +
                                    '            // implementare logica de update cand se modifica props\n' +
                                    '        }\n' +
                                    '    }\n' +
                                    '    render() {\n' +
                                    '        return <h1>Hello World</h1>\n' +
                                    '    }\n' +
                                    '}\n' +
                                    '\n' +
                                    'const FunComponent = ({props}) => {\n' +
                                    '    useEffect (()=>{\n' +
                                    '        // implementare logica\n' +
                                    '    },[john])\n' +
                                    '    return <h1>Hello World</h1>\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>

                        <b>d) useEffect() </b>:  funcția return callback va rula codul de curățare înainte ca componenta să fie eliminată din DOM.
                        <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                            {'class ClassComponent extends React.Component{\n' +
                                '    componentWillUnmount() {\n' +
                                '        // implementare logica de autodistrugere: muhahaha\n' +
                                '    }\n' +
                                '    render() {\n' +
                                '        return <h1>Hello World</h1>\n' +
                                '    }\n' +
                                '}\n' +
                                '\n' +
                                'const FunComponent = () => {\n' +
                                '    useEffect (()=>{\n' +
                                '        \n' +
                                '        return () => {\n' +
                                '            // implementare logica de autodistrugere: muhahaha\n' +
                                '        }\n' +
                                '    },[])\n' +
                                '    return <h1>Hello World</h1>\n' +
                                '}'}
                        </SyntaxHighlighter>
                    </ul>

                    <hr/>
                    Pentru mai multe detalii:
                    <a target={"_blank"} href={"https://javascript.plainenglish.io/angular-v-s-react-lifecycle-methods-4a9cd3b58891"}>
                        Angular vs. React — Lifecycle Methods
                    </a>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LifecycleAngularContent;