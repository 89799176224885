import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class CustomPluginMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-custom-plugin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Crearea propriului plugin (custom plugin)</b>:
                    <br/>
                    <br/>

                    Generare schelet plugin, folosind <i>maven-archetype-mojo</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate -DgroupId=ro.maven.learn -DartifactId=MavenCustomPlugin -DarchetypeArtifactId=maven-archetype-mojo -DarchetypeGroupId=org.apache.maven.archetypes'}
                    </SyntaxHighlighter>

                    Pentru a face proiectul importabil in Eclipse:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn eclipse:eclipse'}
                    </SyntaxHighlighter>

                    Pentru IntelliJ se importa proiectul.

                    <br/>

                    In proiectul generat, in <i>pom.xml</i>, se adauga urmtoarele linii (pentru a nu avea eroare la compilare):
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<properties>\n' +
                            '    <maven.compiler.source>6</maven.compiler.source>\n' +
                            '    <maven.compiler.target>6</maven.compiler.target>\n' +
                            '    <project.build.sourceEncoding>UTF-8</project.build.sourceEncoding>\n' +
                            '</properties>'}
                    </SyntaxHighlighter>

                    Apoi se instaleaza noul plugin in repository:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn clean install'}
                    </SyntaxHighlighter>

                    Pentru a afla detalii despre plugin:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:describe -Dplugin=ro.maven.learn:MavenCustomPlugin'}
                    </SyntaxHighlighter>
                    sau (daca se obtine eroare la comanda de mai sus)
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn help:describe "-Dplugin=ro.maven.learn:MavenCustomPlugin"'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'[INFO] Scanning for projects...\n' +
                            '[INFO]\n' +
                            '[INFO] ------------------< org.apache.maven:standalone-pom >-------------------\n' +
                            '[INFO] Building Maven Stub Project (No POM) 1\n' +
                            '[INFO] --------------------------------[ pom ]---------------------------------\n' +
                            '[INFO]\n' +
                            '[INFO] --- maven-help-plugin:3.2.0:describe (default-cli) @ standalone-pom ---\n' +
                            '[INFO] ro.maven.learn:MavenCustomPlugin:1.0\n' +
                            '\n' +
                            'Name: MavenCustomPlugin Maven Mojo\n' +
                            'Description: (no description available)\n' +
                            'Group Id: ro.maven.learn\n' +
                            'Artifact Id: MavenCustomPlugin\n' +
                            'Version: 1.0\n' +
                            'Goal Prefix: MavenCustomPlugin\n' +
                            '\n' +
                            'This plugin has 1 goal:\n' +
                            '\n' +
                            'MavenCustomPlugin:touch\n' +
                            '  Description: Goal which touches a timestamp file.\n' +
                            '\n' +
                            'For more information, run \'mvn help:describe [...] -Ddetail\'\n' +
                            '\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] BUILD SUCCESS\n' +
                            '[INFO] ------------------------------------------------------------------------\n' +
                            '[INFO] Total time:  0.752 s\n' +
                            '[INFO] Finished at: 2022-09-16T08:21:15+03:00\n' +
                            '[INFO] ------------------------------------------------------------------------'}
                    </SyntaxHighlighter>

                    Pentru a executa plugin-ul (din alt proiect maven, de exemplu):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'mvn ro.maven.learn:MavenCustomPlugin:touch'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li><i>ro.maven.learn</i>: este numele groupId-ului pentru plugin (definit mai sus: -DgroupId=ro.maven.learn)</li>
                        <li><i>MavenCustomPlugin</i>: este numele artifactId-ului pentru plugin (definit mai sus: -DartifactId=MavenCustomPlugin)</li>
                        <li><i>touch</i>: este numele goal-ului (este claa sursa Java in comentariu la inceputul clasei: <b>@goal touch</b>)
                            <br/>
                            daca modificam din <b>@goal touch</b> in <b>@goal touch-kj</b>, atunci comanda se va rescrie asa:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'mvn ro.maven.learn:MavenCustomPlugin:touch-kj'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Fisierul generat de plugin:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import org.apache.maven.plugin.AbstractMojo;\n' +
                            'import org.apache.maven.plugin.MojoExecutionException;\n' +
                            '\n' +
                            'import java.io.File;\n' +
                            'import java.io.FileWriter;\n' +
                            'import java.io.IOException;\n' +
                            '\n' +
                            '/**\n' +
                            ' * Goal which touches a timestamp file.\n' +
                            ' *\n' +
                            ' * @goal touch\n' +
                            ' * @phase process-sources\n' +
                            ' */\n' +
                            'public class MyMojo extends AbstractMojo {\n' +
                            '    /**\n' +
                            '     * Location of the file.\n' +
                            '     *\n' +
                            '     * @parameter expression="${project.build.directory}"\n' +
                            '     * @required\n' +
                            '     */\n' +
                            '    private File outputDirectory;\n' +
                            '\n' +
                            '    public void execute() throws MojoExecutionException {\n' +
                            '        File f = outputDirectory;\n' +
                            '\n' +
                            '        if (!f.exists()) {\n' +
                            '            f.mkdirs();\n' +
                            '        }\n' +
                            '\n' +
                            '        File touch = new File(f, "touch.txt");\n' +
                            '\n' +
                            '        FileWriter w = null;\n' +
                            '        try {\n' +
                            '            w = new FileWriter(touch);\n' +
                            '\n' +
                            '            w.write("touch.txt");\n' +
                            '        } catch (IOException e) {\n' +
                            '            throw new MojoExecutionException("Error creating file " + touch, e);\n' +
                            '        } finally {\n' +
                            '            if (w != null) {\n' +
                            '                try {\n' +
                            '                    w.close();\n' +
                            '                } catch (IOException e) {\n' +
                            '                    // ignore\n' +
                            '                }\n' +
                            '            }\n' +
                            '        }\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomPluginMavenContent;