import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class NecognitronContent extends BaseContentPage {

    constructor(props) {
        super(props, "neocognitron", IndexContent);
    }

    render() {
        return (
            <div className="home cnn">
                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify"}>
                    <b>2. Neocognitronul</b>
                </div>
                <br/>

                <div className={"text-justify important"}>

                    Neocognitronul a fost propus in anii 1980, de catre Dr. Kunihiko Fukushima, care a fost inspirat de
                    modelul lui Hubel & Wiesel din 1959, fiind
                    o extensie a congnitronului [Fukushima, 1975].
                    <br/>
                    <br/>
                    <b>Arhitectura</b>
                    <br/>
                    Neocognitronul a fost proiectat pentru a recunoaste caracterele scrise de mana, mai specific cifrele
                    de la 0 la 9.
                    Reteaua isi propunea sa fie insensibila la variatiile de pozitie si stil in care o cifra este
                    scrisa.
                    <br/>
                    Arhitectura unui neocognitron consta din mai multe straturi (layere).
                    Neuronii din cadrul fiecărui strat sunt dispusi în matrici patrate.
                    Un neuron dintr-un strat primeste semnale de la numar limitat de neuronii din stratul precedent si
                    trimite semnnale la doar cativa neuroni din urmatorul strat.
                    <br/><br/>
                    Neuronii stratului de intrare sunt aranjati intr-o matrice patrata de 19 x 19 (poate fi vazuta ca
                    imagine de 19x19 pixeli). <br/>
                    Urmatorul strat e format dintr-un vector cu 12 elemente, fiecare element fiind de fapt o matrice de
                    19x19 neuroni (deci are 12 matrici de neuroni).
                    <br/>
                    In general dimensiunea matricilor descreste de la input layer catre output layer (ajungand la un
                    vector de 10 elemente, fiecare element continand un 1 neuron - matrice 1x1, sau altfel spus 10
                    matrici de neuroni, fiecare matrice avand dimensiunea 1x1).
                    <br/>
                    Straturile sunt aranjate in pereche, un S-layer este urmat de un C-layer:
                    <ul>
                        <li>
                            S-layerele sunt antrenate sa recunoasca un exemplu sau un grup de exemple (prin intermediul
                            S-cells, reprezentand pasul de feature extraction)
                        </li>
                        <li>
                            C-layerele combina rezultate furnizate de S-layere si micsoreaza numarul de neuroni din
                            fiecare matrice (prin intermediul C-cells, reprezentand pasul de pooling).
                        </li>
                    </ul>
                    Arhitectura unui neocognitron:
                    <table>
                        <tr>
                            <th>#</th>
                            <th>Layer</th>
                            <th>Numar de matrici</th>
                            <th>Dimensiune matrice</th>
                            <th>Dimensiune 'window'</th>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td>Input</td>
                            <td>1</td>
                            <td>19x19</td>
                            <td>3x3</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>S1</td>
                            <td>12</td>
                            <td>19x19</td>
                            <td>5x5</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>C1</td>
                            <td>8</td>
                            <td>11x11</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>S2</td>
                            <td>38</td>
                            <td>11x11</td>
                            <td>5x5</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>C2</td>
                            <td>22</td>
                            <td>7x7</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>S3</td>
                            <td>32</td>
                            <td>7x7</td>
                            <td>3x3</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>C3</td>
                            <td>30</td>
                            <td>7x7</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>S4</td>
                            <td>16</td>
                            <td>3x3</td>
                            <td>5x5</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>C4</td>
                            <td>10</td>
                            <td>1x1</td>
                            <td>-</td>
                        </tr>
                    </table>
                    <br/>
                    Sa presupunem ca avem ca intrare urmatoarea imagine (deocamdata nu conteaza iesirea fiecarui neuron,
                    asa ca il vom reprezenta printr-o bulina, fiecare celula din tabel reprezinta un neuron):
                    <table className={"cnnimage"}>
                        {
                            Array.from(Array(10).keys()).map(ii => {
                                return <tr>
                                    {
                                        Array.from(Array(10).keys()).map(jj => {
                                            return <td>
                                                &bull;
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </table>

                    <br/>
                    <b>Input Layer &rarr; S1</b>
                    <br/>
                    <br/>

                    Stratul S1 este format din 12 matrici de neuroni, fiecare matrice avand dimensiunea 19x19.
                    <MathComponent tex={String.raw`S1=\{S1^1,S1^2,...,S1^{12}\}`}/>
                    Consideram:
                    <MathComponent tex={String.raw`S1_{ij}^k`}/>
                    ca fiind neuronul <b>ij</b> din matricea a <b>k</b>-a din stratul (S1).
                    <br/><br/>
                    Fiecare neuron din S1 primeste semnale de la <b>3 x 3</b> neuroni din stratul de intrare (input
                    layer).
                    Pentru neuronul (am omis k-ul, pentru ca e indentic pentru fiecare matrice),:
                    <MathComponent tex={String.raw`S1_{ij}`}/>
                    primim semnale de urmatorii neuronii din stratul de intrare:
                    <table>
                        <tr>
                            <td>
                                <MathComponent tex={String.raw`U_{i-1,j-1}`}/>
                            </td>
                            <td>
                                <MathComponent tex={String.raw`U_{i-1,j}`}/>
                            </td>
                            <td>
                                <MathComponent tex={String.raw`U_{i-1,j+1}`}/>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <MathComponent tex={String.raw`U_{i,j-1}`}/>
                            </td>
                            <td>
                                <MathComponent tex={String.raw`U_{i,j}`}/>
                            </td>
                            <td>
                                <MathComponent tex={String.raw`U_{i,j+1}`}/>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <MathComponent tex={String.raw`U_{i+1,j-1}`}/>
                            </td>
                            <td>
                                <MathComponent tex={String.raw`U_{i+1,j}`}/>
                            </td>
                            <td>
                                <MathComponent tex={String.raw`U_{i+1,j+1}`}/>
                            </td>
                        </tr>

                    </table>

                    <b>Observatie:</b>
                    <br/>
                    Daca i=1 sau i=19 sau j=1 sau j=19, vom considera matrici de 2x2 si nu 3x3.
                    <br/>
                    <table>
                        <tr>
                            <td>
                                De exemplu daca i=1, j=1, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(10).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(10).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 2 && jj < 2) ? "content-change" : ""}>
                                                            &bull;
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>
                            </td>
                            <td>
                                De exemplu daca i=1, j=2, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(10).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(10).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 2 && jj >= 0 && jj < 3) ? "content-change" : ""}>
                                                            &bull;
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                De exemplu daca i=1, j=3, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(10).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(10).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 2 && jj > 0 && jj < 4) ? "content-change" : ""}>
                                                            &bull;
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>

                            </td>
                            <td>
                                De exemplu daca i=5, j=5, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(10).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(10).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii > 2 && ii < 6 && jj > 2 && jj < 6) ? "content-change" : ""}>
                                                            &bull;
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>

                            </td>
                        </tr>
                    </table>
                    <br/>
                    Exemple de antrenare pentru stratul S1:
                    <table className={"text-center"}>
                        <tr>
                            <td>S1<sup>1</sup></td>
                            <td>S1<sup>2</sup></td>
                            <td>S1<sup>3</sup></td>
                            <td>S1<sup>4</sup></td>
                            <td>S1<sup>5</sup></td>
                            <td>S1<sup>6</sup></td>
                        </tr>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td className={"content-1"}></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <table className={"text-center"}>
                        <tr>
                            <td>S1<sup>6</sup></td>
                            <td>S1<sup>7</sup></td>
                            <td>S1<sup>8</sup></td>
                            <td>S1<sup>9</sup></td>
                            <td>S1<sup>10</sup></td>
                            <td>S1<sup>11</sup></td>
                        </tr>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td className={"content-1"}></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={"content-1"}></td>
                                        <td className={"content-1"}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                    <br/>
                    <b>S1 &rarr; C1</b>
                    <br/>
                    <br/>
                    Stratul C1 este format din 8 matrici de neuroni, fiecare matrice avand dimensiunea 11x11.
                    <MathComponent tex={String.raw`C1=\{C1^1,C1^2,...,C1^{8}\}`}/>
                    Fiecare C1 primeste semnale de la <b>5x5</b> neuroni din S1, in felul urmator:
                    <table>
                        <tr>
                            <th colSpan={2}>Conexiuni de la S1 &rarr; C1</th>
                        </tr>
                        <tr>
                            <td>S1<sup>1</sup></td>
                            <td>C1<sup>1</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>2</sup>,S1<sup>3</sup></td>
                            <td>C1<sup>2</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>4</sup></td>
                            <td>C1<sup>3</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>5</sup>,S1<sup>6</sup></td>
                            <td>C1<sup>4</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>7</sup></td>
                            <td>C1<sup>5</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>8</sup>,S1<sup>9</sup></td>
                            <td>C1<sup>6</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>10</sup></td>
                            <td>C1<sup>7</sup></td>
                        </tr>
                        <tr>
                            <td>S1<sup>11</sup>,S1<sup>12</sup></td>
                            <td>C1<sup>8</sup></td>
                        </tr>
                    </table>

                    <br/>
                    <b>S2 &rarr; C2</b>
                    <br/>
                    <br/>
                    Stratul C2 este format din 22 matrici de neuroni, fiecare matrice avand dimensiunea 7x7.
                    <MathComponent tex={String.raw`C2=\{C2^1,C2^2,...,C2^{22}\}`}/>
                    Fiecare C2 primeste semnale de la <b>5x5</b> neuroni din S2, in felul urmator:
                    <table>
                        <tr>
                            <th colSpan={2}>Conexiuni de la S2 &rarr; C2</th>
                        </tr>
                        <tr>
                            <td>S2<sup>1</sup>,S2<sup>2</sup>,S2<sup>3</sup></td>
                            <td>C2<sup>1</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>2</sup>,S2<sup>3</sup>,S2<sup>4</sup></td>
                            <td>C2<sup>2</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>4</sup></td>
                            <td>C2<sup>3</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>6</sup>,S2<sup>7</sup>,S3<sup>8</sup></td>
                            <td>C2<sup>4</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>7</sup>,S2<sup>8</sup>,S2<sup>9</sup></td>
                            <td>C2<sup>5</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>10</sup></td>
                            <td>C2<sup>6</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>11</sup>,S2<sup>12</sup></td>
                            <td>C2<sup>7</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>13</sup>,S2<sup>14</sup></td>
                            <td>C2<sup>8</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>15</sup>,S2<sup>16</sup></td>
                            <td>C2<sup>9</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>17</sup>,S2<sup>18</sup></td>
                            <td>C2<sup>10</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>19</sup></td>
                            <td>C2<sup>11</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>20</sup>,S2<sup>21</sup></td>
                            <td>C2<sup>12</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>22</sup>,S2<sup>23</sup>,S2<sup>24</sup></td>
                            <td>C2<sup>13</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>25</sup></td>
                            <td>C2<sup>14</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>26</sup></td>
                            <td>C2<sup>15</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>27</sup>,S2<sup>28</sup>,S2<sup>29</sup></td>
                            <td>C2<sup>16</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>30</sup>,S2<sup>31</sup></td>
                            <td>C2<sup>17</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>32</sup></td>
                            <td>C2<sup>18</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>33</sup></td>
                            <td>C2<sup>19</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>34</sup></td>
                            <td>C2<sup>20</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>35</sup>,S2<sup>36</sup></td>
                            <td>C2<sup>21</sup></td>
                        </tr>
                        <tr>
                            <td>S2<sup>37</sup>,S2<sup>38</sup></td>
                            <td>C2<sup>22</sup></td>
                        </tr>
                    </table>

                    Exemplu:
                    <table>
                        <tr>
                            <td>
                                De exemplu daca i=1, j=1, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(19).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(19).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 3 && jj <3) ? "content-change" : ""}>
                                                            <span className={ii==0 && jj==0?'cell-mark':'' }>&bull;</span>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>
                            </td>
                            <td>
                                De exemplu daca i=1, j=2, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(19).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(19).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 3 && jj >= 0 && jj < 5) ? "content-change" : ""}>
                                                            <span className={ii==0 && jj==2?'cell-mark':'' }>&bull;</span>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                De exemplu daca i=1, j=3, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(19).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(19).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 3 && jj >1 && jj < 7) ? "content-change" : ""}>
                                                            <span className={ii==0 && jj==4?'cell-mark':'' }>&bull;</span>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>

                            </td>
                            <td>
                                De exemplu daca i=1, j=4, avem urmatori neuroni marcati:
                                <table className={"cnnimage"}>
                                    {
                                        Array.from(Array(19).keys()).map(ii => {
                                            return <tr>
                                                {
                                                    Array.from(Array(19).keys()).map((jj, jIndex) => {
                                                        return <td
                                                            className={(ii < 3 && jj > 3 && jj < 9) ? "content-change" : ""}>
                                                            <span className={ii==0 && jj==6?'cell-mark':'' }>&bull;</span>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </table>

                            </td>
                        </tr>
                    </table>

                    <br/>
                    <b>S3 &rarr; C3</b>
                    <br/>
                    <br/>
                    Stratul C3 este format din 30 matrici de neuroni, fiecare matrice avand dimensiunea 7x7.
                    <MathComponent tex={String.raw`C3=\{C3^1,C3^2,...,C3^{30}\}`}/>
                    Fiecare C3 primeste semnale de la <b>3x3</b> neuroni din S3.
                    <br/>
                    <br/>
                    <b>S4 &rarr; C4</b>
                    <br/>
                    <br/>
                    Stratul C4 este format din 10 matrici de neuroni, fiecare matrice avand dimensiunea 1x1.
                    <MathComponent tex={String.raw`C4=\{C4^1,C4^2,...,C4^{10}\}`}/>
                    Fiecare C4 primeste semnale de la <b>5x5</b> neuroni din S3, in felul urmator:
                    <table>
                        <tr>
                            <th colSpan={2}>Conexiuni de la S4 &rarr; C4</th>
                        </tr>
                        <tr>
                            <td>S4<sup>1</sup>,S4<sup>1</sup></td>
                            <td>C4<sup>1</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>3</sup>,S2<sup>4</sup></td>
                            <td>C4<sup>2</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>5</sup></td>
                            <td>C4<sup>3</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>6</sup>,S4<sup>7</sup></td>
                            <td>C4<sup>4</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>8</sup>,S4<sup>9</sup></td>
                            <td>C4<sup>5</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>10</sup></td>
                            <td>C4<sup>6</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>11</sup>,S4<sup>12</sup></td>
                            <td>C4<sup>7</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>13</sup></td>
                            <td>C4<sup>8</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>14</sup></td>
                            <td>C4<sup>9</sup></td>
                        </tr>
                        <tr>
                            <td>S4<sup>15</sup>,S4<sup>16</sup></td>
                            <td>C4<sup>10</sup></td>
                        </tr>
                    </table>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            <div>
                                <a href={"https://glassboxmedicine.com/2019/04/13/a-short-history-of-convolutional-neural-networks/"}>The
                                    History of Convolutional Neural Networks</a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=Bh5uPyerI1M&ab_channel=AIPRIKEN"}>02.Kunihiko
                                    Fukushima: Artificial Vision by Deep CNN Neocognitron</a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=pKPTNKdGlKU&ab_channel=TheComputingBrain"}>NB3014
                                    - Lecture 5 - Part 2 - Neocognitron - Convolutions - Tensorflow Playground</a>
                            </div>
                        </li>
                        <li>
                            <div>Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And
                                Applications, Pearson
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NecognitronContent;