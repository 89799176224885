import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ImportGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-import", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Import</b>
                    <br/>
                    <br/>

                    Pentru a importa un singur pachet:
                    <SyntaxHighlighter>
                        {'import "fmt"'}
                    </SyntaxHighlighter>

                    Pentru a importa mai multe pachete:
                    <SyntaxHighlighter>
                        {'import (\n' +
                            '  "fmt" \n' +
                            '  "strings"\n' +
                            ')'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImportGoContent;