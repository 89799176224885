import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DataProviderLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-data-provider", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Sa presupunem ca lucram cu <b>Liferay Forms</b> si avem o <b>lista/select</b> pe care vrem s-o populam pe baza unui serviciu web.

                    <hr/>

                    <b>1. Structura de date</b>
                    <br/>
                    <br/>
                     Sa prespunem ca structura de date returnata este reprezentata de clasa <i>NomenclatorResponse</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {
                        'import java.io.Serializable;\n' +
                        '\n' +
                        'public class NomenclatorResponse implements Serializable {\n' +
                        '\n' +
                        '    private String id;\n' +
                        '\n' +
                        '    private String label;\n' +
                        '\n' +
                        '    public NomenclatorResponse(String id, String label){\n' +
                        '        this.id=id;\n' +
                        '        this.label = label;\n' +
                        '    }\n' +
                        '\n' +
                        '    public String getId() {\n' +
                        '        return id;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setId(String id) {\n' +
                        '        this.id = id;\n' +
                        '    }\n' +
                        '\n' +
                        '    public String getLabel() {\n' +
                        '        return label;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setLabel(String label) {\n' +
                        '        this.label = label;\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Serviciul Web</b>
                    <br/>
                    <br/>
                    Serviciul web:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.mail.kernel.model.MailMessage;\n' +
                        'import com.liferay.mail.kernel.service.MailServiceUtil;\n' +
                        'import com.liferay.portal.kernel.exception.PortalException;\n' +
                        'import com.liferay.portal.kernel.log.Log;\n' +
                        'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                        'import com.liferay.portal.kernel.model.User;\n' +
                        'import com.liferay.portal.kernel.util.PortalUtil;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        'import org.osgi.service.jaxrs.whiteboard.JaxrsWhiteboardConstants;\n' +
                        '\n' +
                        '\n' +
                        'import javax.mail.internet.AddressException;\n' +
                        'import javax.mail.internet.InternetAddress;\n' +
                        'import javax.portlet.PortletRequest;\n' +
                        'import javax.servlet.http.HttpServletRequest;\n' +
                        'import javax.ws.rs.DefaultValue;\n' +
                        'import javax.ws.rs.QueryParam;\n' +
                        'import javax.ws.rs.Consumes;\n' +
                        'import javax.ws.rs.GET;\n' +
                        'import javax.ws.rs.POST;\n' +
                        'import javax.ws.rs.Path;\n' +
                        'import javax.ws.rs.PathParam;\n' +
                        'import javax.ws.rs.Produces;\n' +
                        'import javax.ws.rs.core.Application;\n' +
                        'import java.util.*;\n' +
                        'import javax.ws.rs.core.Context;\n' +
                        'import javax.ws.rs.core.MediaType;\n' +
                        'import com.fasterxml.jackson.jaxrs.json.JacksonJsonProvider;\n' +
                        '\n' +
                        'import com.fasterxml.jackson.core.JsonProcessingException;\n' +
                        'import com.fasterxml.jackson.databind.JsonNode;\n' +
                        'import com.fasterxml.jackson.databind.ObjectMapper;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                JaxrsWhiteboardConstants.JAX_RS_APPLICATION_BASE + "=/nomenclator-rest",\n' +
                        '                JaxrsWhiteboardConstants.JAX_RS_NAME + "=Nomenclator.Rest"\n' +
                        '        }, service = Application.class)\n' +
                        'public class NomenclatorRest extends Application {\n' +
                        '\n' +
                        '    private static final Log log = LogFactoryUtil.getLog(AnrscRest.class);\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public Set<Object> getSingletons() {\n' +
                        '        return Collections.<Object>singleton(this);\n' +
                        '    }\n' +
                        '\n' +
                        '    /*\n' +
                        '            http://localhost:8082/o/nomenclator-rest/tari\n' +
                        '     */\n' +
                        '    @GET\n' +
                        '    @Produces(MediaType.APPLICATION_JSON + ";charset=UTF-8")\n' +
                        '    @Path("/tari")\n' +
                        '    public String getNomenclator( @Context HttpServletRequest request)\n' +
                        '            throws Exception {\n' +
                        '\n' +
                        '     \n' +
                        '        List<NomenclatorResponse> response = new ArrayList<>();\n' +
                        '        response.add( new NomenclatorResponse("1", "Romania"));\n' +
                        '        response.add( new NomenclatorResponse("2", "Grecia"));\n' +
                        '        response.add( new NomenclatorResponse("3", "Franta"));\n' +
                        '     \n' +
                        '        ObjectMapper mapper = new ObjectMapper();\n' +
                        '        return mapper.writeValueAsString(response);\n' +
                        '    }\n' +
                        '\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Activarea accesului la date în rețeaua locală</b>
                    <br/>
                    <br/>
                    In mod implicit, utilizatorii nu pot configura furnizorii de date pentru a utiliza adrese URL în rețeaua locală.
                    Acest lucru este bine pentru securitatea într-un mediu de producție, dar face testarea mai dificilă.

                    <br/>
                    Pentru a activa accesul la rețeaua locală de la furnizorii de date:
                    <ul>
                        <li>Navigați la Panoul de control → Configurare → Setări sistem</li>
                        <li>Faceți clic pe Furnizori de date (sub Conținut și date)</li>
                        <li>Bifați caseta de selectare Acces rețea locală </li>
                    </ul>

                    <hr/>
                    <b>4. Adaugarea unui data provider REST</b>
                    <br/>
                    <br/>

                    Din meniul din stanga:
                    <ul>
                        <li>
                            Content & Data {"->"} Forms {"->"} tab <b>Data Providers</b> {"->"} Se apasa pe butonul cu [+]

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/c_14.png'}/>
                            </div>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/c_15.png'}/>
                            </div>

                        </li>
                        <li>
                            Introduceți aceste date:

                            <ul>
                                <li>
                                    Name: Tarile mele
                                </li>
                                <li>
                                    URL: http://localhost:8080/o/nomenclator-rest/tari
                                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                        {'\'@Component(\\n\' +\n' +
                                        ' \'        immediate = true,\\n\' +\n' +
                                        ' \'        property = {\\n\' +\n' +
                                        ' \'                JaxrsWhiteboardConstants.JAX_RS_APPLICATION_BASE + "=/nomenclator-rest",\\n\' +\n' +
                                        ' \'                JaxrsWhiteboardConstants.JAX_RS_NAME + "=Nomenclator.Rest"\\n\' +\n' +
                                        ' \'        }, service = Application.class)\\n\' +'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    User name: admin@dopamina.ro
                                </li>
                                <li>
                                    Password: test
                                </li>
                                <li>
                                    Timeout: 1000
                                </li>

                                <li>
                                    Sectiunea OUTPUTS:
                                    <ul>
                                        <li>Label: Tari</li>
                                        <li>Path: $..label; $..id</li>
                                        <li>Type: List</li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_17.png'}/>
                    </div>


                    <hr/>
                    <b>5. Configurare camp de tip Select from List sa foloseasca un Data Provider</b>
                    <br/>
                    <br/>

                    Pentru a configura un camp de tip <b>Select from List</b> sa foloseasca un <b>Data Provider</b>.
                    <ul>
                        <li>Create list: <b>from Data Provider</b></li>
                        <li>Choose a Data Provider: <i>Tarile mele</i></li>
                        <li>Choose a Output Provider: Tari</li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_16.png'}/>
                    </div>

                    <hr/>
                    <b>Rezolvare problema: Error:URL must not be a local network</b>
                    <br/>
                    <br/>

                    Activarea accesului la date în rețeaua locală: în mod implicit, nu puteți configura furnizorii de date să utilizeze adrese URL în rețeaua locală.
                    <br/>
                    Aceasta este o valoare implicită bună pentru securitate într-un mediu de producție, dar face testarea mai dificilă.
                    <br/>
                    Pentru a activa accesul la rețeaua locală de la furnizorii de date, accesați:
                    <SyntaxHighlighter>
                        {'Panou de control → Configurare → Setări sistem → Furnizori de date (sub Conținut și date) și activați Acces la rețea locală'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter>
                        {' Control Panel → Configuration → System Settings → Data Providers (under Content & Data), and enable Local Network Access.'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/dxp/latest/en/process-automation/forms/data-providers/using-the-rest-data-provider-to-populate-form-options.html"}>
                                    Using the REST Data Provider to Populate Form Options
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DataProviderLiferayContent;