import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class PiperRi5TtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-piper-pi5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Site: https://github.com/rhasspy/piper
                    <br/>
                    Video: https://www.youtube.com/watch?v=rjq5eZoWWSo

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cat /proc/cpuinfo'
                           }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {       '...\n' +
                            'Model           : Raspberry Pi 5 Model B Rev 1.0\n' +
                            '...' }
                    </SyntaxHighlighter>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            'mkdir piper\n' +
                            'cd piper\n' +
                            'wget https://github.com/rhasspy/piper/releases/download/v1.2.0/piper_arm64.tar.gz\n' +
                            'tar -xvzf piper_arm64.tar.gz' }
                    </SyntaxHighlighter>

                    Descarcare model:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir models\n' +
                            'cd models\n' +
                            'mkdir ro\n' +
                            'cd ro\n' +
                            'mkdir mihai\n' +
                            'cd mihai\n' +
                            'cd /home/iulianb/tools/piper/models/ro/mihai\n' +
                            'wget https://huggingface.co/rhasspy/piper-voices/resolve/v1.0.0/ro/ro_RO/mihai/medium/ro_RO-mihai-medium.onnx\n' +
                            'wget https://huggingface.co/rhasspy/piper-voices/resolve/v1.0.0/ro/ro_RO/mihai/medium/ro_RO-mihai-medium.onnx.json'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd /home/iulianb/tools/piper/piper\n' +
                            'echo \'Salut! Primul test\' | ./piper --model /home/iulianb/tools/piper/models/ro/mihai/ro_RO-mihai-medium.onnx --output_file /home/iulianb/tools/piper/tests/test-1.wav'
                          }
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'[2024-02-04 10:45:10.517] [piper] [info] Loaded voice in 0.442660123 second(s)\n' +
                            '[2024-02-04 10:45:10.518] [piper] [info] Initialized piper\n' +
                            '/home/iulianb/tools/piper/tests/test-1.wav\n' +
                            '[2024-02-04 10:45:10.733] [piper] [info] Real-time factor: 0.16639791886393232 (infer=0.20864261500000003 sec, audio=1.253877551020408 sec)\n' +
                            '[2024-02-04 10:45:10.733] [piper] [info] Terminated piper'}
                    </SyntaxHighlighter>

                    <hr/>

                    Link-uri utile Piper:
                    <ul>
                        Git:
                        <li>
                            https://github.com/rhasspy/piper
                        </li>

                        Demo online:
                        <li>
                            https://rhasspy.github.io/piper-samples/
                        </li>

                        Download zip pentru Windows/Linux:
                        <li>
                            https://github.com/rhasspy/piper/releases
                        </li>

                        Modele:
                        <li>
                            https://huggingface.co/rhasspy
                            <ul>
                                <li>
                                    RO / Mihai: https://huggingface.co/rhasspy/piper-voices/tree/main/ro/ro_RO/mihai/medium
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PiperRi5TtsContent;