import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MathJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-math", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Metode ale clase Math:
                    <br/>
                    <br/>
                    <b>1. min()</b>: returneaza valoarea minima
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'double min(double a, double b)\n' +
                        'float min(float a, float b)\n' +
                        'int min(int a, int b)\n' +
                        'long max(long a, long b)'}
                    </SyntaxHighlighter>

                    <b>2. max()</b>: returneaza valoarea maximia
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'double max(double a, double b)\n' +
                        'float max(float a, float b)\n' +
                        'int max(int a, int b)\n' +
                        'long max(long a, long b)'}
                    </SyntaxHighlighter>

                    <b>3. round()</b>: rotunjire la cel mai aproape numar intreg
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'long round(double a)\n' +
                        'int round(float a)'}
                    </SyntaxHighlighter>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Math.round(1.2); // 1\n' +
                        'Math.round(1.5); // 2'}
                    </SyntaxHighlighter>

                    <b>4. pow()</b>: ridicare la putere
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'double pow(double baza, double exponent)'}
                    </SyntaxHighlighter>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Math.pow(5,2); // 25\n' +
                        'Math.pow(3,3); // 27'}
                    </SyntaxHighlighter>

                    <b>5. random()</b>: generare numar aleator intre [0,1)
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'double random()'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MathJavaContent;