import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavaSpringSecurityEx05Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-security-ex-05", IndexContent);
    }

    render() {

        return (
            <div className="home index">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify content important"}>
                    <i>Aplicatie:</i> <b>security</b>
                    <br/>
                    <i>Modul:</i> <b>security-04</b>
                    <br/>
                    <i>Descriere actiune:</i> Construirea unui proiect cu securitate pe baza implementarilor <b>InMemoryUserDetailsManager</b>
                    si <b>NoOpPasswordEncoder</b> pornind de la modulul <b>security-02</b>, folosind <b>WebSecurityConfigurerAdapter</b>
                    <br/>
                    <i>IDE folosit</i>: Intellij IDEA
                    <hr/>

                    1. In fisierul <b>pom.xml</b> (in radacina proiectului) se adauga in cadrul sectiunii <b>project\modules</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<module>security-04</module>'}
                    </SyntaxHighlighter>

                    2. Clasele <b>HelloController</b> si <b>Security04Application</b> (continut identic cu Security02Application) se pot copia, pastrand aceeasi structura a organizarii de directoare si de pachete
                    (redenumind <i>exemplu02</i> cu <i>exemplu04</i> in numele de pachete).
                    <br/>
                    <br/>
                    3.   Apoi, vom creea pachetul:
                    <div className={"padding50"}>
                        <b>ro.letyournailsgrow.security.exemplu04.config</b>
                    </div>

                    <br/>
                    4. Se va creea clasa <b>SecurityConfig</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.security.exemplu04.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        'import org.springframework.security.config.annotation.authentication.builders.AuthenticationManagerBuilder;\n' +
                        'import org.springframework.security.config.annotation.web.configuration.WebSecurityConfigurerAdapter;\n' +
                        'import org.springframework.security.core.userdetails.User;\n' +
                        'import org.springframework.security.core.userdetails.UserDetails;\n' +
                        'import org.springframework.security.crypto.password.NoOpPasswordEncoder;\n' +
                        'import org.springframework.security.provisioning.InMemoryUserDetailsManager;\n' +
                        '\n' +
                        '@Configuration\n' +
                        'public class SecurityConfig extends WebSecurityConfigurerAdapter {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected void configure(AuthenticationManagerBuilder auth) throws Exception {\n' +
                        '        InMemoryUserDetailsManager userDetailsService = new InMemoryUserDetailsManager();\n' +
                        '\n' +
                        '        UserDetails user = User.withUsername("iulian")\n' +
                        '                .password("123")\n' +
                        '                .authorities("read")\n' +
                        '                .build();\n' +
                        '\n' +
                        '        userDetailsService.createUser(user);\n' +
                        '\n' +
                        '        auth.userDetailsService(userDetailsService).passwordEncoder(NoOpPasswordEncoder.getInstance());\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    5. Pentru a putea apelata metoda <i>hello()</i>  din clasa <i>HelloController</i>,

                    Comanda care trebuie executata este:
                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8080/hello\n' +
                        'Authorization: Basic iulian 123'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b> De remarcat ca se poate pune orice parola, pentru este acceptata orice parola datorita implementarii folosite <b>NoOpPasswordEncoder</b>.

                    <hr/>
                    <b>STOP</b>

                    </div>

                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default JavaSpringSecurityEx05Content;