import {useRoutes} from "react-router-dom";
import BooksHomeContent from "./IndexContent";
import React from "react";
import ITBooksContent from "./ITBooksContent";
import VideoBooksContent from "./VideoBooksContent";
import PhotoBooksContent from "./PhotoBooksContent";
import ArtBooksContent from "./ArtBooksContent";
import MathBooksContent from "./MathBooksContent";

export default function Router() {
    let element = useRoutes([
        {path: "/books/index", element: <BooksHomeContent/>},
        {path: "/books/it", element: <ITBooksContent/>},
        {path: "/books/video", element: <VideoBooksContent/>},
        {path: "/books/photo", element: <PhotoBooksContent/>},
        {path: "/books/art", element: <ArtBooksContent/>},
        {path: "/books/math", element: <MathBooksContent/>},
    ]);

    return element;
}