import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class NanoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-sftp", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Setup SFT Server Ubuntu</b>
                    <br/>
                    <br/>

                    # actualizare lista de pachete
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt-get update'}
                    </SyntaxHighlighter>

                    <hr/>
                    # instalare SSH
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo apt install ssh'}
                    </SyntaxHighlighter>

                    <hr/>
                    # modificare /etc/ssh/sshd_config
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo nano /etc/ssh/sshd_config'}
                    </SyntaxHighlighter>


                    # se adauga urmatoare linii:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'Match group sftp\n' +
                            'ChrootDirectory /home\n' +
                            'X11Forwarding no\n' +
                            'AllowTcpForwarding no\n' +
                            'ForceCommand internal-sftp'}
                    </SyntaxHighlighter>

                    # Configurația de mai sus permite grupului de utilizatori "sftp" să-și acceseze directoarele de acasă prin SFTP.
                    <br/>
                    Cu toate acestea, nu este permis accesul cu shell-ul SSH normal.

                    <hr/>
                    # restart ssh
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo systemctl restart ssh'}
                    </SyntaxHighlighter>

                    <hr/>
                    # creare un grup de utilizatori SFTP: "sftp"
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo addgroup sftp'}
                    </SyntaxHighlighter>

                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Adding group `sftp\' (GID 1003) ...\n' +
                            'Done.p'}
                    </SyntaxHighlighter>

                    <hr/>
                    # creare un nou utilizator SFTP: "sftp_user" + "sftp_kj"
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo useradd -m sftp_user -g sftp'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo useradd -m sftp_kj -g sftp'}
                    </SyntaxHighlighter>

                    <hr/>
                    # setare parole pentru utilizatori creati mai sus
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo passwd sftp_user'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo passwd sftp_kj'}
                    </SyntaxHighlighter>

                    <hr/>
                    # acrodare permisiuni
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo chmod 700 /home/sftp_user/'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo chmod 700 /home/sftp_kj/'}
                    </SyntaxHighlighter>

                    <hr/>
                    # logare cu sftp_kj
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'su sftp_kj'}
                    </SyntaxHighlighter>

                    <hr/>
                    # creare fisiere de test:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd /home/sftp_kj'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nano test1.txt'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nano test2.txt'}
                    </SyntaxHighlighter>

                    <hr/>
                    # conectare pe acceasi masina:
                    sftp sftp_kj@127.0.0.1
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sftp sftp_kj@127.0.0.1'}
                    </SyntaxHighlighter>

                    <hr/>
                    # conectare de pe o alta masina (Windows) pe masina pe care este serverul SFTP:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sftp sftp_kj@192.168.1.7'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NanoContent;