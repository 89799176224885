import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"videografie", url:"#", title:"Introducere", subtitle:""},
        {id:"videografie-contrast", url:"/videografie/contrast", title:"Tonalitate si contrast", subtitle:"", parent:"videografie"},
        {id:"videografie-expunere", url:"/videografie/expunere", title:"Expunere", subtitle:"", parent:"videografie"},
        {id:"videografie-obiective", url:"/videografie/obiective", title:"Obiective si perspective", subtitle:"", parent:"videografie"},
        {id:"videografie-filtre", url:"/videografie/filtre", title:"Filtre", subtitle:"", parent:"videografie"},
        {id:"videografie-incadrari", url:"/videografie/incadrari", title:"Planuri si incadrari", subtitle:"", parent:"videografie"},
        {id:"videografie-puncte-de-statie", url:"/videografie/puncte-de-statie", title:"Puncte de statie", subtitle:"", parent:"videografie"},
        {id:"videografie-zone-system", url:"/videografie/zone-system", title:"Zone System", subtitle:"", parent:"videografie"},
        {id:"videografie-comparatie-luminanta-vs-iluminare", url:"/videografie/comparatie-luminanta-vs-iluminare", title:"Luminanta si iluminare", subtitle:"", parent:"videografie"},
        {id:"videografie-spatiu-culoare", url:"/videografie/spatiu-culoare", title:"Spatiu de culoare", subtitle:"", parent:"videografie"},
        {id:"videografie-nlog", url:"/videografie/nlog", title:"N-Log", subtitle:"", parent:"videografie"},
        {id:"videografie-color-sampling", url:"/videografie/color-sampling", title:"Color sampling", subtitle:"", parent:"videografie"},
        {id:"videografie-histograma", url:"/videografie/histograma", title:"Histograma", subtitle:"", parent:"videografie"},
        {id:"videografie-zebra", url:"/videografie/zebra", title:"Zebra", subtitle:"", parent:"videografie"},
        {id:"videografie-focus", url:"/videografie/focus", title:"Focalizare", subtitle:"", parent:"videografie"},
        {id:"videografie-move-camera", url:"/videografie/move-camera", title:"Miscarea camerei", subtitle:"", parent:"videografie"},
        {id:"videografie-iluminare", url:"/videografie/iluminare", title:"Iluminare", subtitle:"", parent:"videografie"},
        {id:"videografie-punctuatie", url:"/videografie/punctuatie", title:"Semne de punctuatie", subtitle:"", parent:"videografie"},

        {id:"premiere-pro", url:"#", title:"Premiere Pro", subtitle:""},
        {id:"premiere-pro-introducere", url:"/videografie/premiere-pro/introducere", title:"Introducere", subtitle:"", parent:"premiere-pro"},
        {id:"premiere-pro-organizer", url:"/videografie/premiere-pro/organizer", title:"Organizarea fisierelor media", subtitle:"", parent:"premiere-pro"},
        {id:"premiere-pro-shortcut", url:"/videografie/premiere-pro/shortcut", title:"Shortcut-uri", subtitle:"", parent:"premiere-pro"},
        {id:"premiere-pro-add-subtitle", url:"/videografie/premiere-pro/add-subtitle", title:"Adaugare subtitrare", subtitle:"", parent:"premiere-pro"},
        {id:"premiere-pro-recapitulare", url:"/videografie/premiere-pro/recapitulare", title:"Recapitulare", subtitle:"", parent:"premiere-pro"},

        {id:"videografie-anexe", url:"#", title:"Anexe", subtitle:""},
        {id:"videografie-istorie", url:"/videografie/istorie", title:"Un pic de date istorice", subtitle:"", parent:"videografie-anexe"},
        {id:"videografie-utils", url:"/videografie/utils", title:"Link-uri utile", subtitle:"", parent:"videografie-anexe"},
        {id:"videografie-echipament", url:"/videografie/echipament", title:"Echipament", subtitle:"", parent:"videografie-anexe"},
        {id:"videografie-z6-settings", url:"/videografie/z6-settings", title:"Setari Z6 pentru videografie", subtitle:"", parent:"videografie-anexe"},
        {id:"videografie-recapitulare", url:"/videografie/recapitulare", title:"Recapitulare", subtitle:"", parent:"videografie-anexe"},
    ]

    static indexUrl = "/videografie/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Videografie
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;