import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class LivenessProbeK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-liveness-probe", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Liveness Probe</b>
                    <br/>
                    <br/>

                    Sa presupunem ca avem un replica set sau un deployment cu multiple pod-uri si un serviciu care serveste trafic catre toate podurile.
                    <br/>
                    Daca exista 2 pod-uri care servesc deja utilizatori, si pornim un alt pod si acestui pod ii ia cateva minute sa pornesca si sa fie gata sa returneze trafic,
                    fara o configurarea corecta a <b>livenessProbe</b>, serviciul va incerca imediat sa obtina trafic la pod-ul care inca nu e pregatit.
                    <br/>
                    Ceea ce inseamna ca unii utilizatori vor optiune o eroare.
                    <br/>
                    Cu o configurare corecta <b>livenessProbe</b>, serviciul va folosi doar podurile vechi pana cand noul pod este pregatit.

                    <hr/>

                    Pentru a specifica cum K8s sa verifica ca un container peste pornit se foloseste <b>livenessProbe</b>.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'containers:\n' +
                            '  - name: kj-node\n' +
                            '    image: iulianbuzdugan/kj-node\n' +
                            '    livenessProbe:\n' +
                            '      httpGet:\n' +
                            '        path: /\n' +
                            '        port: 8080\n' +
                            '    periodSeconds: 10\n' +
                            '    initialDelaySeconds: 5'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'livenessProbe:\n' +
                            '  tcpSocket:\n' +
                            '    port: 8080\n' +
                            '  initialDelaySeconds: 15\n' +
                            '  periodSeconds: 20 '}
                    </SyntaxHighlighter>

                    <hr/>
                    Sa presupunem ca in Docker rulam o imagine, de exemplu nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run nginx'}
                    </SyntaxHighlighter>
                    Aceasta porneste si si serveste utilizatori.
                    Dar din varii motive, crapa si procesul nginx se termina. Containerul se termina si el.
                    <br/>
                    Putem vedea acest lucru din status:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker ps -a'}
                    </SyntaxHighlighter>
                    Avand in vedere in Docker nu este un <i>orchestration engine</i>, containerul ramane mort.
                    Si utilizatori nu vor putea fi serviti pana cand containerul nu este pornit manual din nou.
                    <hr/>
                    Sa presupunem ca in Kuberntes rulam o imagine, de exemplu nginx:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl run nginx --image=nginx'}
                    </SyntaxHighlighter>

                    De fiecare daca cand crapa, Kubernetes incerca sa restarteze containerul pentru a restabili serviciul.
                    Se poate vedea de cate ori s-a restartat containerul, utilizand comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pods'}
                    </SyntaxHighlighter>
                    si verificand pe coloana <b>RESTARTS</b>.

                    <hr/>
                    Ce se intampla daca crapa aplicatia, dar containerul ramane viu?
                    De exemplu, exista un bug in aplicatie si ramane intr-un loop infinit.
                    Utilizatori nu sunt serviti din aceasta cauza. Desi containerul este viu. <br/>
                    In acest caz, containerul trebuie restartat sau distrus, pentru ca un nou container sa porneasca.
                    <br/>
                    In acest scenariu, <b>liveness probe</b> ne poate ajuta.
                    Un liveness probe poate fi configurat pe un container ca periodic sa testeze daca aplicatia din interiorul containerului este <i>sanatoasa</i>.
                    <br/>
                    Daca testul crapa, containerul este considerat <i>nesanatos</i> si este distrus si recreat.
                    <br/>
                    Ca dezvoltator, putem defini, ce inseamna ca o aplicatie este <i>sanatoasa</i>.
                    <ul>
                        <li>
                            HTTP test: in cazul unei aplicatii web (de exemplu: /api/healthy)
                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {'livenessProbe:\n' +
                                    '  httpGet:\n' +
                                    '    path: /api/healthy\n' +
                                    '    port: 8080'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            TCP test: in cazul unei baze de date, pentru ca vedea daca de exemplu TCP Socket asculta.

                            <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                                {'livenessProbe:\n' +
                                    '  tcpSocket:\n' +
                                    '    port: 8080'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            executa o comanda pentru a efecuta un test

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'livenessProbe: \n' +
                                    '  exec:\n' +
                                    '    command:\n' +
                                    '    - cat\n' +
                                    '    - /tmp/is_healthy\n'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    Daca stim ca aplicatia porneste in aproximativ 10 secunde, se poate folosi optiunea <b>initialDelaySeconds</b>, pentru a verifica dupa 10 secunde.
                    <br/>
                    Putem specifica dupa cate secunde sa reincerce, dupa ce testul nu a reusit, folosind optiunea <b>periodSeconds</b>.
                    <br/>
                    In mod implicit, se incerca de 3 ori. Pentru a modifica acest comportament, se foloseste optiunea <b>failureThreshold</b>.
                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'livenessProbe:\n' +
                            '  tcpSocket:\n' +
                            '    port: 8080\n' +
                            '  initialDelaySeconds: 5\n' +
                            '  periodSeconds: 10\n' +
                            '  failureThreshold: 8'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LivenessProbeK8sContent;