import React from "react";
import {Link} from "react-router-dom";

class TocHelper {

    static display(content){

        return <div className={"text-justify content toc"}>

            <ol>
                {content.filter(itemParent => itemParent.url==="#").map(function(itemParent, index){
                    return <li key={ itemParent.id }>
                        {itemParent.title}

                        {itemParent.subtitle!=="" && <span> - <span dangerouslySetInnerHTML={{ __html: itemParent.subtitle }} /> </span>}

                        <ol>
                            {content.filter(item => item.parent===itemParent.id).map(function(item, index){
                                return <li key={ item.id }>
                                    <Link to={item.url}>{item.title}</Link> <span dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                                </li>;
                            })}
                        </ol>

                    </li>;
                })}
            </ol>
        </div>

    }

}

export default TocHelper;
