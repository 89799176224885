import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CustomDataProviderLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-custom-data-provider", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Sa presupunem ca lucram cu <b>Liferay Forms</b> si avem prepopulam niste campuri.

                    <hr/>

                    <b>0. Custom Data Provider</b>
                    <br/>
                    <br/>

                    Câmpurile de formulare Liferay pot fi completate folosind un furnizor de date (<b>Data Provider</b>).
                    <br/>
                    Există un furnizor de date REST care oferă o modalitate flexibilă de a consuma date dintr-un serviciu REST.
                    <br/>
                    <br/>

                    <hr/>

                    1. Implementare <b>DDMDataProvider</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                            '\tproperty = "ddm.data.provider.type=b4d8", service = DDMDataProvider.class\n' +
                            ')\n' +
                            'public class B4D8DDMDataProvider implements DDMDataProvider {\n' +
                            '\t@Override\n' +
                            '\tpublic DDMDataProviderResponse getData(\n' +
                            '\t\t\tDDMDataProviderRequest ddmDataProviderRequest)\n' +
                            '\t\tthrows DDMDataProviderException {\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic Class<?> getSettings() {\n' +
                            '\t}\n' +
                            '\n'}
                    </SyntaxHighlighter>
                    Trebuie implementate 2 metode:
                    <ul>
                        <li>
                            getData
                        </li>
                        <li>
                            getSettings
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.forms.data.provider;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.data.provider.*;\n' +
                            'import com.liferay.dynamic.data.mapping.data.provider.settings.DDMDataProviderSettingsProvider;\n' +
                            'import com.liferay.dynamic.data.mapping.model.DDMDataProviderInstance;\n' +
                            'import com.liferay.dynamic.data.mapping.service.DDMDataProviderInstanceService;\n' +
                            'import com.liferay.portal.kernel.json.JSONObject;\n' +
                            'import com.liferay.portal.kernel.util.Validator;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.ibrid.forms.data.provider.builder.UserInfoBuilder;\n' +
                            'import ro.ibrid.forms.data.provider.dto.DefaultValues;\n' +
                            '\n' +
                            'import java.text.DateFormat;\n' +
                            'import java.text.SimpleDateFormat;\n' +
                            'import java.util.Date;\n' +
                            'import java.util.Optional;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true, property = "ddm.data.provider.type=data-provider.user-info",\n' +
                            '        service = DDMDataProvider.class\n' +
                            ')\n' +
                            'public class DDMCurrentUserInfoDataProvider implements DDMDataProvider {\n' +
                            '\n' +
                            '    private static final String DATE_FORMAT = "yyyy-MM-dd";\n' +
                            '    private static final String TYPE_TEXT = "text";\n' +
                            '    private static final String TYPE_NUMBER = "number";\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    protected UserInfoBuilder userInfoBuilder;\n' +
                            '    @Reference\n' +
                            '    protected DDMDataProviderInstanceService ddmDataProviderInstanceService;\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    protected DDMDataProviderInstanceSettings ddmDataProviderInstanceSettings;\n' +
                            '\n' +
                            '    @Reference(target = "(ddm.data.provider.type=data-provider.user-info)")\n' +
                            '    protected DDMDataProviderSettingsProvider ddmDataProviderSettingsProvider;\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public DDMDataProviderResponse getData(DDMDataProviderRequest ddmDataProviderRequest) throws DDMDataProviderException {\n' +
                            '        try {\n' +
                            '            return doInvoke(ddmDataProviderRequest);\n' +
                            '        } catch (Exception exception) {\n' +
                            '            throw new DDMDataProviderException(exception);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Class<?> getSettings() {\n' +
                            '        return ddmDataProviderSettingsProvider.getSettings();\n' +
                            '    }\n' +
                            '\n' +
                            '    protected DDMDataProviderResponse doInvoke(DDMDataProviderRequest ddmDataProviderRequest) throws Exception {\n' +
                            '\n' +
                            '        Optional<DDMDataProviderInstance> ddmDataProviderInstance = fetchDDMDataProviderInstance(ddmDataProviderRequest.getDDMDataProviderId());\n' +
                            '\n' +
                            '        DDMCurrentUserInfoDataProviderSettings ddmCurrentUserInfoDataProviderSettings = ddmDataProviderInstanceSettings.getSettings(ddmDataProviderInstance.get(),\n' +
                            '                DDMCurrentUserInfoDataProviderSettings.class);\n' +
                            '\n' +
                            '        // PermissionChecker permissionChecker = PermissionThreadLocal.getPermissionChecker();\n' +
                            '        // long currentUserId = permissionChecker.getUserId();\n' +
                            '        // UserInformation currentUserInfo = userInformationLocalService.getUserInformationByUserId(currentUserId);\n' +
                            '\n' +
                            '        DefaultValues defaultValues = new DefaultValues();\n' +
                            '\n' +
                            '        JSONObject currentUserJSONObject = userInfoBuilder.buildUserInfo(defaultValues);\n' +
                            '\n' +
                            '        return createDDMDataProviderResponse(ddmCurrentUserInfoDataProviderSettings, currentUserJSONObject);\n' +
                            '    }\n' +
                            '\n' +
                            '    /*\n' +
                            '    http://www.java2s.com/example/java-src/pkg/com/liferay/dynamic/data/mapping/data/provider/internal/ddmdataproviderinvokerimpl-a36aa.html\n' +
                            '     */\n' +
                            '    protected Optional<DDMDataProviderInstance> fetchDDMDataProviderInstance(String ddmDataProviderInstanceId) throws Exception {\n' +
                            '\n' +
                            '        DDMDataProviderInstance ddmDataProviderInstance = ddmDataProviderInstanceService.fetchDataProviderInstanceByUuid(ddmDataProviderInstanceId);\n' +
                            '\n' +
                            '        if ((ddmDataProviderInstance == null) && Validator.isNumber(ddmDataProviderInstanceId)) {\n' +
                            '\n' +
                            '            ddmDataProviderInstance = ddmDataProviderInstanceService.fetchDataProviderInstance(Long.parseLong(ddmDataProviderInstanceId));\n' +
                            '        }\n' +
                            '\n' +
                            '        return Optional.ofNullable(ddmDataProviderInstance);\n' +
                            '    }\n' +
                            '\n' +
                            '    protected DDMDataProviderResponse createDDMDataProviderResponse(DDMCurrentUserInfoDataProviderSettings ddmCurrentUserInfoDataProviderSettings,\n' +
                            '                                                                    JSONObject defaultValues) throws Exception {\n' +
                            '\n' +
                            '        DDMDataProviderResponse.Builder builder = DDMDataProviderResponse.Builder.newBuilder();\n' +
                            '\n' +
                            '        for (DDMDataProviderOutputParametersSettings outputParameterSettings : ddmCurrentUserInfoDataProviderSettings.outputParameters()) {\n' +
                            '\n' +
                            '            String outputParameterId = outputParameterSettings.outputParameterId();\n' +
                            '            String outputParameterPath = outputParameterSettings.outputParameterPath();\n' +
                            '            String outputParameterType = outputParameterSettings.outputParameterType();\n' +
                            '\n' +
                            '            if (TYPE_TEXT.equals(outputParameterType)) {\n' +
                            '\n' +
                            '                String paramValue = defaultValues.getString(outputParameterPath);\n' +
                            '                Object param = defaultValues.get(outputParameterPath);\n' +
                            '                if (param instanceof Date) {\n' +
                            '                    DateFormat df = new SimpleDateFormat(DATE_FORMAT);\n' +
                            '                    paramValue = df.format((Date) param);\n' +
                            '                }\n' +
                            '                builder = builder.withOutput(outputParameterId, paramValue);\n' +
                            '\n' +
                            '            } else if (TYPE_NUMBER.equals(outputParameterType)) {\n' +
                            '\n' +
                            '                Long number = defaultValues.getLong(outputParameterPath);\n' +
                            '                builder = builder.withOutput(outputParameterId, number);\n' +
                            '            }\n' +
                            '        }\n' +
                            '\n' +
                            '        return builder.build();\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.forms.data.provider.dto;\n' +
                            '\n' +
                            'public class DefaultValues {\n' +
                            '\n' +
                            '    public static String NUME = "ion";\n' +
                            '\n' +
                            '    public static String CNP = "123";\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.forms.data.provider.builder;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.json.JSONObject;\n' +
                            'import ro.ibrid.forms.data.provider.dto.DefaultValues;\n' +
                            '\n' +
                            'public interface UserInfoBuilder {\n' +
                            '\n' +
                            '    String NAME = "name";\n' +
                            '    String CNP = "cnp";\n' +
                            '\n' +
                            '    JSONObject buildUserInfo(DefaultValues defaultValues);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.forms.data.provider.builder.impl;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.json.JSONFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.json.JSONObject;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.forms.data.provider.builder.UserInfoBuilder;\n' +
                            'import ro.ibrid.forms.data.provider.dto.DefaultValues;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        service = UserInfoBuilder.class\n' +
                            ')\n' +
                            'public class UserInfoBuilderImpl implements UserInfoBuilder {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public JSONObject buildUserInfo(DefaultValues defaultValues) {\n' +
                            '        JSONObject userInfoJson = JSONFactoryUtil.createJSONObject();\n' +
                            '        try {\n' +
                            '            userInfoJson.put(NAME, DefaultValues.NUME);\n' +
                            '\n' +
                            '            userInfoJson.put(CNP, DefaultValues.CNP);\n' +
                            '\n' +
                            '        } catch (Exception e) {\n' +
                            '            _log.error(e.getMessage(), e);\n' +
                            '        }\n' +
                            '        return userInfoJson;\n' +
                            '    }\n' +
                            '\n' +
                            '    private static final Log _log = LogFactoryUtil.getLog(UserInfoBuilderImpl.class);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    2. Definire <i>Settings</i> cu <b>DDMDataProviderSettings</b>.
                    <br/>
                    <br/>
                    Declarația de clasă și corpul determină ce câmpuri sunt disponibile. Nu sunt necesare setări suplimentare, așa că corpul clasei este gol.
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.forms.data.provider;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.annotations.DDMForm;\n' +
                            'import com.liferay.dynamic.data.mapping.annotations.DDMFormLayout;\n' +
                            'import com.liferay.dynamic.data.mapping.annotations.DDMFormLayoutColumn;\n' +
                            'import com.liferay.dynamic.data.mapping.annotations.DDMFormLayoutPage;\n' +
                            'import com.liferay.dynamic.data.mapping.annotations.DDMFormLayoutRow;\n' +
                            'import com.liferay.dynamic.data.mapping.data.provider.DDMDataProviderParameterSettings;\n' +
                            '\n' +
                            '@DDMForm\n' +
                            '@DDMFormLayout(\n' +
                            '        {\n' +
                            '                @DDMFormLayoutPage(\n' +
                            '                        {\n' +
                            '                                @DDMFormLayoutRow(\n' +
                            '                                        {\n' +
                            '                                                @DDMFormLayoutColumn(\n' +
                            '                                                        size = 12, value = "outputParameters"\n' +
                            '                                                )\n' +
                            '                                        }\n' +
                            '                                )\n' +
                            '                        }\n' +
                            '                )\n' +
                            '        }\n' +
                            ')\n' +
                            'public interface DDMCurrentUserInfoDataProviderSettings extends DDMDataProviderParameterSettings {\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    3. Implementare <b>DDMDataProviderSettingsProvider</b>
                    <br/>
                    <br/>
                    Trebuie implementata metoda:
                    <ul>
                        <li>
                            <b>getSettings()</b>: care returneaza un obiect de tipul <b>DDMDataProviderSettings</b> / <b>DDMDataProviderParameterSettings</b>
                        </li>
                    </ul>


                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.forms.data.provider.settings;\n' +
                            '\n' +
                            'import com.liferay.dynamic.data.mapping.data.provider.settings.DDMDataProviderSettingsProvider;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.forms.data.provider.DDMCurrentUserInfoDataProviderSettings;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true, property = "ddm.data.provider.type=data-provider.user-info",\n' +
                            '        service = DDMDataProviderSettingsProvider.class\n' +
                            ')\n' +
                            'public class DDMCurrentUserDataProviderSettingsProvider implements DDMDataProviderSettingsProvider {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Class<?> getSettings() {\n' +
                            '        return DDMCurrentUserInfoDataProviderSettings.class;\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Interfata <b>DDMDataProviderSettingsProvider</b> oferita de Liferay:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package com.liferay.dynamic.data.mapping.data.provider.settings;\n' +
                            '\n' +
                            'import org.osgi.annotation.versioning.ProviderType;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author Jonathan McCann\n' +
                            ' */\n' +
                            '@ProviderType\n' +
                            'public interface DDMDataProviderSettingsProvider {\n' +
                            '\n' +
                            '\tpublic Class<?> getSettings();\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Fisier <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '    compileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Fisier <b>bnd.bnd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: Liferay Dynamic Data Mapping Data Provider Custom Implementation\n' +
                            'Bundle-SymbolicName: com.liferay.dynamic.data.mapping.data.provider.custom\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Web-ContextPath: /dynamic-data-mapping-data-provider-custom\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    Dupa deploy, se poate configura un Data Provider de tip <i>data-provider.user-info</i>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_40.png'}/>
                    </div>

                    Pe creare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_41.png'}/>
                    </div>

                    Pe vizualizare lista cu data providers:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_42.png'}/>
                    </div>

                    Pe editare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_43.png'}/>
                    </div>

                    Permisiuni pe data provider:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_48.png'}/>
                    </div>

                    Pe forma (nu functioneaza!!):
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_44.png'}/>
                    </div>

                    Reguli:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_45.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_46.png'}/>
                    </div>

                    Permisiuni pe forma:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_47.png'}/>
                    </div>

                    Vizualizare prepopulare
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_49.png'}/>
                    </div>

                    <hr/>
                    <b>Mapare Data Provider ~ Form ~ Reguli </b>
                    <br/>
                    <br/>
                    In Data Provider:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_52.png'}/>
                    </div>

                    Pe forma:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_51.png'}/>
                    </div>

                    Pe reguli:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_50.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a target={"_blank"} href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-custom-data-provider"}>
                                    Writing a Custom Data Provider
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CustomDataProviderLiferayContent;