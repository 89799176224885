import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InstallGrafana10Ubuntu20Content extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-install-grafana-10-ubuntu-20", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana</b>
                    <br/>
                    <br/>

                    Site: <a target={"_blank"} href={"https://grafana.com/"}>https://grafana.com/</a>
                    <br/>
                    Site: <a target={"_blank"} href={" https://grafana.com/grafana/download?pg=get&plcmt=selfmanaged-box1-cta1"}>Download</a>

                    <hr/>
                    <b>Instalare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get install -y adduser libfontconfig1'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'wget https://dl.grafana.com/enterprise/release/grafana-enterprise_10.0.3_amd64.deb'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo dpkg -i grafana-enterprise_10.0.3_amd64.deb'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Selecting previously unselected package grafana-enterprise.\n' +
                            '(Reading database ... 77578 files and directories currently installed.)\n' +
                            'Preparing to unpack grafana-enterprise_10.0.3_amd64.deb ...\n' +
                            'Unpacking grafana-enterprise (10.0.3) ...\n' +
                            'Setting up grafana-enterprise (10.0.3) ...\n' +
                            'Adding system user `grafana\' (UID 115) ...\n' +
                            'Adding new user `grafana\' (UID 115) with group `grafana\' ...\n' +
                            'Not creating home directory `/usr/share/grafana\'.\n' +
                            '### NOT starting on installation, please execute the following statements to configure grafana to start automatically using systemd\n' +
                            ' sudo /bin/systemctl daemon-reload\n' +
                            ' sudo /bin/systemctl enable grafana-server\n' +
                            '### You can start grafana-server by executing\n' +
                            ' sudo /bin/systemctl start grafana-server'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Grafana ca serviciu</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl daemon-reload'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl enable grafana-server'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo /bin/systemctl start grafana-server'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Verificare</b>
                    <br/>
                    <br/>

                    URL: <b>http://localhost:3000</b>

                    <br/>
                    Logare cu:
                    <ul>
                        <li>
                            Email or username: admin
                        </li>
                        <li>
                            Password: admin
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallGrafana10Ubuntu20Content;