import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ModuleRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-module", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Module</b>
                    <br/>
                    <br/>

                    Fie urmatoarea structura de fisiere:
                    <ul>
                        <li>
                            hello
                            <ul>
                                <li>
                                    Cargo.toml
                                </li>
                                <li>
                                    src
                                    <ul>
                                        <li>lib.rs</li>
                                        <li>main.rs</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Fie <i>lib.rs</i> cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'fn square(x: i32) -> i32 {\n' +
                            '    x * x\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fie <i>main.rs</i> cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'fn main() {\n' +
                            '    hello::square(2); // eroare\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a functiona trebuie ca functia din lib sa fie declarata publica <b>pub</b> (implicit este privata)
                    <SyntaxHighlighter>
                        {'pub fn square(x: i32) -> i32 {\n' +
                            '    x * x\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se poate folosi <b>use</b>:
                    <SyntaxHighlighter>
                        {'use hello::square;' +
                            '\n' +
                            'fn main() {\n' +
                            '    square(2); // eroare\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca sunt mai multe functii:
                    <SyntaxHighlighter>
                        {'use hello::{square, print_x, print_y};'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ModuleRustContent;