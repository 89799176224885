import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

const changeUrl = () =>{
    window.history.pushState(null, "", "pushstate#kj");
}

class PushStateJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-pushstate", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. history.pushState</b>
                    <br/>
                    <br/>

                    Metoda <b>history.pushState()</b>history.pushState()permite să adăugați o intrare la stiva de istoric al sesiunilor browserului web.
                    <br/>
                    Sintaxa metodei <b>pushState()</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'history.pushState(state, title, [,url])'}
                    </SyntaxHighlighter>
                    cu parametrii:
                    <ul>
                        <li>state : state este un obiect serializabil; cand se navigheaza catre o nou stare se atunca evenimentul <b>popstate</b></li>
                        <li>title: cele mai multe browsere ignora aceasta proprietate; se poate folosi proprietatea <i>document.title</i> in schimb</li>
                        <li>url: cand se seteaza un nou url, browser-ul nu va incarca acel url</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'window.history.pushState(null, "KJ - in titluri", "pushstate#kj");'}
                    </SyntaxHighlighter>

                    <button onClick={changeUrl}>Apasa</button>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                <a target={"_blank"} href={"https://www.javascripttutorial.net/web-apis/javascript-history-pushstate/"}>JavaScript History pushState</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PushStateJavaScriptContent;