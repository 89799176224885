import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DockerComposeUpContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-compose-up", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker compose up </b>
                    <br/>
                    <br/>
                    Folosind Docker Compose, putem vedea urmatoarele echivalente:
                    <ul>
                        <li><b>docker run image {"=>"} docker compose up</b>
                            <br/>
                            nu se specifica imaginea in instructiunea <i>docker compose up</i> pentru ca imaginea este declarata in <i>docker-compose.yml</i>
                        </li>
                        <li><b>docker build . + docker run image {"=>"} docker compose up --build</b></li>
                    </ul>
                    Deci:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up'}
                    </SyntaxHighlighter>

                    Pentru a rula in background / in mod detasat:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up -d'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DockerComposeUpContent;