import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class FlagHelpDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-help", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker --help</b>

                    <br/>
                    <br/>

                    Pentru a afisa detalii despre o comanda:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker comanda --help'}
                    </SyntaxHighlighter>

                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker rm --help'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter >
                        {
                            'Usage:  docker rm [OPTIONS] CONTAINER [CONTAINER...]\n' +
                            '\n' +
                            'Remove one or more containers\n' +
                            '\n' +
                            'Options:\n' +
                            '  -f, --force     Force the removal of a running container (uses SIGKILL)\n' +
                            '  -l, --link      Remove the specified link\n' +
                            '  -v, --volumes   Remove anonymous volumes associated with the container'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FlagHelpDockerContent;