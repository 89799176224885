import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InstallLiferay74Ubuntu20Content extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-install-liferay-74-ubuntu-20", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Resurse necesare</b>:
                    <ul>
                        <li>
                            Procesor (CPU):
                            <ul>
                                <li>
                                    Minim: 2 nuclee fizice sau virtuale
                                </li>
                                <li>
                                    Recomandat: 4 nuclee fizice sau virtuale sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Memorie (RAM):
                            <ul>
                                <li>
                                    Minim: 4 GB RAM
                                </li>
                                <li>
                                    Recomandat: 8 GB RAM sau mai mult
                                </li>
                            </ul>
                        </li>
                        <li>
                            Stocare/spatiu pe disk:
                            <ul>
                                <li>
                                    Minim: 10 GB spațiu liber pe disc
                                </li>
                                <li>
                                    Recomandat: 20 GB sau mai mult (în funcție de dimensiunea datelor și a conținutului)
                                </li>
                            </ul>
                        </li>
                    </ul>
                    Mai multe detalii:
                    <a target={"_blank"} href={"https://www.liferay.com/documents/10182/1645493/Liferay+Portal+EE+Reference+Architecture+%26+Hardware+Requirements/7f618f87-ca55-4e39-ba21-b3faadbca206?version=1.3"}>Liferay Portal EE Reference Architecture & Hardware
                        Requirements</a>

                    <b>1. Instalare 7zip</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt update && sudo apt install --assume-yes p7zip-full'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Dezarhivare kit</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /home/kj/kit'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo 7z x liferay-ce-portal-tomcat-7.4.3.86-ga86-20230725000823814.7z -o/opt'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Permisiuni</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo chown -R $USER:$USER /opt/liferay-ce-portal-7.4.3.86-ga86'}
                    </SyntaxHighlighter>
                    <hr/>

                    <hr/>
                    <b>4. Adaugare JAVA_HOME in setenv.sh</b>
                    <br/>
                    <br/>
                    Adaugare JAVA_HOME in <b>>setenv.sh</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.86-ga86/tomcat-9.0.75/bin'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano setenv.sh'}
                    </SyntaxHighlighter>
                    se adauga:
                    <SyntaxHighlighter>
                        {'JAVA_HOME=/usr/lib/jvm/jdk-11'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Adaugare permsiuni sa fie vazut serverul de pe alta masina</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.86-ga86/'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano portal-ext.properties'}
                    </SyntaxHighlighter>

                    se adauga:
                    <SyntaxHighlighter>
                        {'include-and-override=portal-developer.properties\n' +
                            'virtual.hosts.valid.hosts=localhost,127.0.0.1,101.129.150.106'}
                    </SyntaxHighlighter>
                    unde 101.129.150.106, este IP masini care va accesa serverul Liferay

                    <hr/>
                    <b>6. Pornire Liferay</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.86-ga86/tomcat-9.0.75/bin'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sh ./startup.sh'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Oprire Liferay</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /opt/liferay-ce-portal-7.4.3.86-ga86/tomcat-9.0.75/bin'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'./shutdown.sh'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Verificare procese</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ps -ea | grep java'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>9. Verificare log</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'tail -f /opt/liferay-ce/tomcat-8.0.32/logs/catalina.out'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*       <li>*/}
                {/*           <a target={"_blank"} href={"https://linuxhint.com/install_oracle_jdk11_ubuntu/"}>*/}
                {/*               Install Oracle JDK 11 on Ubuntu*/}
                {/*           </a>*/}
                {/*       </li>*/}

                {/*        <li>*/}
                {/*            <a target={"_blank"} href={"https://computingforgeeks.com/how-to-install-java-11-on-ubuntu-linux/"}>*/}
                {/*                Install Java 11 on Ubuntu 22.04*/}
                {/*            </a>*/}
                {/*        </li>*/}

                {/*        <li>*/}
                {/*            <a target={"_blank"} href={"https://www.cherryservers.com/blog/how-to-install-java-on-ubuntu"}>*/}
                {/*                How to Install Java on Ubuntu 22.04 | Step-by-Step Tutorial*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallLiferay74Ubuntu20Content;