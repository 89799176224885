import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class MVCActionCommandPortletContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-portlet-mvc-action-command", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    Liferay permite, prin intermediul claselor care implementeaza <b>MVCActionCommand</b>, definirea metodelor care se ocupa de o anumita acțiune a portlet-ului în clase separate (de exemplu, actiunea de salvare).
                    <br/>
                    Fiecare adresă URL de acțiune din JSP-urile portlet-ului apelează apoi clasa de acțiune corespunzătoare.
                    <br/>
                    <br/>

                    Clasa *MVCActionCommand trebuie să aibă adnotarea <b>@Component</b>, unde se vor seta proprietatile:
                    <ul>
                        <li><b>javax.portlet.name</b>: avand ca valoare ID-ul intern al portlet-ului</li>
                        <li><b>mvc.command.name</b>: avand valoare, valoarea proprietății <b>name</b> din actionURL-ul JSP:
                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<portlet:actionURL name = "save/config" var="saveConfigURL"/>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Pentru a face acest lucru avem nevoie de:
                    <ul>
                        <li>o implementare a interfeței <b>MVCActionCommand</b> sau sa se extinda clasa <b>BaseMVCActionCommand</b>:

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.letyournailsgrow.liferay.vanilla.apps.helloworld.portlet;\n' +
                                '\n' +
                                'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                                'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                                'import ro.letyournailsgrow.liferay.vanilla.apps.helloworld.constants.HelloWorldPortletKeys;\n' +
                                '\n' +
                                'import org.osgi.service.component.annotations.Component;\n' +
                                '\n' +
                                'import javax.portlet.ActionRequest;\n' +
                                'import javax.portlet.ActionResponse;\n' +
                                '\n' +
                                '@Component(\n' +
                                '        immediate = true,\n' +
                                '        property = {\n' +
                                '                "javax.portlet.name=" + HelloWorldPortletKeys.HELLOWORLD,\n' +
                                '                "mvc.command.name=/save/config"\n' +
                                '        },\n' +
                                '        service = MVCActionCommand.class\n' +
                                ')\n' +
                                'public class SaveMVCActionCommand extends BaseMVCActionCommand {\n' +
                                '    \n' +
                                '    @Override\n' +
                                '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                                '        \n' +
                                '    }\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                            Componenta trebuie să publice, de asemenea, un serviciu <b>MVCActionCommand.class</b> pe runtime OSGi:

                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'service = MVCActionCommand.class'}
                            </SyntaxHighlighter>

                            In clasa *MVCActionCommand, trebuie suprascrisa metoda <b>doProcessAction()</b> a clasei BaseMVCActionCommand, avand signatura:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception'}
                            </SyntaxHighlighter>


                            O comandă poate fi utilizată de un portlet, după cum arată exemplul de mai sus.
                            <br/>
                            O comandă poate fi utilizată insa si pentru mai multe portleturi adăugând mai multe intrări <b>javax.portlet.name</b> în lista de proprietăți.
                            De asemenea, mai multe comenzi pot invoca clasa de comandă MVC adăugând mai multe intrări <b>mvc.command.name</b>.

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Component(\n' +
                                '    immediate = true,\n' +
                                '    property = {\n' +
                                '       "javax.portlet.name=" + HelloWorldPortletKeys.HELLO_MY_WORLD,\n' +
                                '       "javax.portlet.name=" + HelloWorldPortletKeys.HELLO_WORLD,\n' +
                                '       "mvc.command.name=/hello/edit_super_entry",\n' +
                                '       "mvc.command.name=/hello/edit_entry"\n' +
                                '    },\n' +
                                '    service = MVCRenderCommand.class\n' +
                                ')'}
                            </SyntaxHighlighter>

                        </li>
                        <li>adresa URL de actiune:

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<portlet:actionURL> '}
                            </SyntaxHighlighter>

                            Pentru a invoca comanda de randare MVC de la adresa URL de randare, trebuie specificat parametrul <b>mvcRenderCommandName</b> cu aceeași valoare ca și proprietatea din Component <b>mvc.command.name</b>

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<portlet:actionURL name = "/save/config" var="saveConfigURL"/>'}
                            </SyntaxHighlighter>

                            Pentru a folosi url-ul de actiune:

                            <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<a href="${saveConfigURL}">Salveaza configurare</a>'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>




                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>
                                    MVC Render Command
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MVCActionCommandPortletContent;