import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class GeneratedCodeLiquidbaseJdlJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-generated-code-liquidbase-jdl", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Cod generat JDL: Liquidbase</b>
                    <br/>
                    <br/>

                    In directorul <b>src/main/resources/config/liquibase/changelog</b> se gasesc fisierele XML:
                    <SyntaxHighlighter>
                        {
                            '00000000000000_initial_schema.xml\n' +
                            '20230506210803_added_entity_constraints_Product.xml\n' +
                            '20230506210803_added_entity_Product.xml\n' +
                            '20230506210804_added_entity_ProductCategory.xml\n' +
                            '20230506210805_added_entity_constraints_Customer.xml\n' +
                            '20230506210805_added_entity_Customer.xml\n' +
                            '20230506210806_added_entity_constraints_ProductOrder.xml\n' +
                            '20230506210806_added_entity_ProductOrder.xml\n' +
                            '20230506210807_added_entity_constraints_OrderItem.xml\n' +
                            '20230506210807_added_entity_OrderItem.xml\n' +
                            '20230506210808_added_entity_constraints_Invoice.xml\n' +
                            '20230506210808_added_entity_Invoice.xml\n' +
                            '20230506210809_added_entity_constraints_Shipment.xml\n' +
                            '20230506210809_added_entity_Shipment.xml'
                        }
                    </SyntaxHighlighter>

                    Modificarile sunt aplicate automat bazei de date atunci când reîncărcăm sau pornim aplicația.

                    <br/>
                    <br/>

                    De exemplu, continutul fisierului <i>20230506210803_added_entity_Product.xml</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="utf-8"?>\n' +
                            '<databaseChangeLog\n' +
                            '    xmlns="http://www.liquibase.org/xml/ns/dbchangelog"\n' +
                            '    xmlns:ext="http://www.liquibase.org/xml/ns/dbchangelog-ext"\n' +
                            '    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '    xsi:schemaLocation="http://www.liquibase.org/xml/ns/dbchangelog http://www.liquibase.org/xml/ns/dbchangelog/dbchangelog-latest.xsd\n' +
                            '                        http://www.liquibase.org/xml/ns/dbchangelog-ext http://www.liquibase.org/xml/ns/dbchangelog/dbchangelog-ext.xsd">\n' +
                            '\n' +
                            '    <!--\n' +
                            '        Added the entity Product.\n' +
                            '    -->\n' +
                            '    <changeSet id="20230506210803-1" author="jhipster">\n' +
                            '        <createTable tableName="product" remarks="Product sold by the Online store">\n' +
                            '            <column name="id" type="bigint">\n' +
                            '                <constraints primaryKey="true" nullable="false"/>\n' +
                            '            </column>\n' +
                            '            <column name="name" type="varchar(255)">\n' +
                            '                <constraints nullable="false" />\n' +
                            '            </column>\n' +
                            '            <column name="description" type="varchar(255)">\n' +
                            '                <constraints nullable="true" />\n' +
                            '            </column>\n' +
                            '            <column name="price" type="decimal(21,2)">\n' +
                            '                <constraints nullable="false" />\n' +
                            '            </column>\n' +
                            '            <column name="size_product" type="varchar(255)">\n' +
                            '                <constraints nullable="false" />\n' +
                            '            </column>\n' +
                            '            <column name="image" type="${blobType}">\n' +
                            '                <constraints nullable="true" />\n' +
                            '            </column>\n' +
                            '            <column name="image_content_type" type="varchar(255)">\n' +
                            '                <constraints nullable="true" />\n' +
                            '            </column>\n' +
                            '            <column name="product_category_id" type="bigint">\n' +
                            '                <constraints nullable="true" />\n' +
                            '            </column>\n' +
                            '            <!-- jhipster-needle-liquibase-add-column - JHipster will add columns here -->\n' +
                            '        </createTable>\n' +
                            '    </changeSet>\n' +
                            '\n' +
                            '    <!-- jhipster-needle-liquibase-add-changeset - JHipster will add changesets here -->\n' +
                            '\n' +
                            '    <!--\n' +
                            '        Load sample data generated with Faker.js\n' +
                            '        - This data can be easily edited using a CSV editor (or even MS Excel) and\n' +
                            '          is located in the \'src/main/resources/config/liquibase/fake-data\' directory\n' +
                            '        - By default this data is applied when running with the JHipster \'dev\' profile.\n' +
                            '          This can be customized by adding or removing \'faker\' in the \'spring.liquibase.contexts\'\n' +
                            '          Spring Boot configuration key.\n' +
                            '    -->\n' +
                            '    <changeSet id="20230506210803-1-data" author="jhipster" context="faker">\n' +
                            '        <loadData\n' +
                            '                  file="config/liquibase/fake-data/product.csv"\n' +
                            '                  separator=";"\n' +
                            '                  tableName="product"\n' +
                            '                  usePreparedStatements="true">\n' +
                            '            <column name="id" type="numeric"/>\n' +
                            '            <column name="name" type="string"/>\n' +
                            '            <column name="description" type="string"/>\n' +
                            '            <column name="price" type="numeric"/>\n' +
                            '            <column name="size_product" type="string"/>\n' +
                            '            <column name="image" type="blob"/>\n' +
                            '            <column name="image_content_type" type="string"/>\n' +
                            '            <!-- jhipster-needle-liquibase-add-loadcolumn - JHipster (and/or extensions) can add load columns here -->\n' +
                            '        </loadData>\n' +
                            '    </changeSet>\n' +
                            '</databaseChangeLog>\n'}
                    </SyntaxHighlighter>

                    De exemplu, continutul fisierului <i>20230506210803_added_entity_constraints_Product.xml</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="utf-8"?>\n' +
                            '<databaseChangeLog\n' +
                            '    xmlns="http://www.liquibase.org/xml/ns/dbchangelog"\n' +
                            '    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '    xsi:schemaLocation="http://www.liquibase.org/xml/ns/dbchangelog http://www.liquibase.org/xml/ns/dbchangelog/dbchangelog-latest.xsd">\n' +
                            '    <!--\n' +
                            '        Added the constraints for entity Product.\n' +
                            '    -->\n' +
                            '    <changeSet id="20230506210803-2" author="jhipster">\n' +
                            '\n' +
                            '        <addForeignKeyConstraint baseColumnNames="product_category_id"\n' +
                            '                                 baseTableName="product"\n' +
                            '                                 constraintName="fk_product__product_category_id"\n' +
                            '                                 referencedColumnNames="id"\n' +
                            '                                 referencedTableName="product_category"/>\n' +
                            '    </changeSet>\n' +
                            '</databaseChangeLog>\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    De exemplu, continutul fisierului <i>20230506210804_added_entity_ProductCategory.xml</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0" encoding="utf-8"?>\n' +
                            '<databaseChangeLog\n' +
                            '    xmlns="http://www.liquibase.org/xml/ns/dbchangelog"\n' +
                            '    xmlns:ext="http://www.liquibase.org/xml/ns/dbchangelog-ext"\n' +
                            '    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
                            '    xsi:schemaLocation="http://www.liquibase.org/xml/ns/dbchangelog http://www.liquibase.org/xml/ns/dbchangelog/dbchangelog-latest.xsd\n' +
                            '                        http://www.liquibase.org/xml/ns/dbchangelog-ext http://www.liquibase.org/xml/ns/dbchangelog/dbchangelog-ext.xsd">\n' +
                            '\n' +
                            '    <!--\n' +
                            '        Added the entity ProductCategory.\n' +
                            '    -->\n' +
                            '    <changeSet id="20230506210804-1" author="jhipster">\n' +
                            '        <createTable tableName="product_category">\n' +
                            '            <column name="id" type="bigint">\n' +
                            '                <constraints primaryKey="true" nullable="false"/>\n' +
                            '            </column>\n' +
                            '            <column name="name" type="varchar(255)">\n' +
                            '                <constraints nullable="false" />\n' +
                            '            </column>\n' +
                            '            <column name="description" type="varchar(255)">\n' +
                            '                <constraints nullable="true" />\n' +
                            '            </column>\n' +
                            '            <!-- jhipster-needle-liquibase-add-column - JHipster will add columns here -->\n' +
                            '        </createTable>\n' +
                            '    </changeSet>\n' +
                            '\n' +
                            '    <!-- jhipster-needle-liquibase-add-changeset - JHipster will add changesets here -->\n' +
                            '\n' +
                            '    <!--\n' +
                            '        Load sample data generated with Faker.js\n' +
                            '        - This data can be easily edited using a CSV editor (or even MS Excel) and\n' +
                            '          is located in the \'src/main/resources/config/liquibase/fake-data\' directory\n' +
                            '        - By default this data is applied when running with the JHipster \'dev\' profile.\n' +
                            '          This can be customized by adding or removing \'faker\' in the \'spring.liquibase.contexts\'\n' +
                            '          Spring Boot configuration key.\n' +
                            '    -->\n' +
                            '    <changeSet id="20230506210804-1-data" author="jhipster" context="faker">\n' +
                            '        <loadData\n' +
                            '                  file="config/liquibase/fake-data/product_category.csv"\n' +
                            '                  separator=";"\n' +
                            '                  tableName="product_category"\n' +
                            '                  usePreparedStatements="true">\n' +
                            '            <column name="id" type="numeric"/>\n' +
                            '            <column name="name" type="string"/>\n' +
                            '            <column name="description" type="string"/>\n' +
                            '            <!-- jhipster-needle-liquibase-add-loadcolumn - JHipster (and/or extensions) can add load columns here -->\n' +
                            '        </loadData>\n' +
                            '    </changeSet>\n' +
                            '</databaseChangeLog>\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GeneratedCodeLiquidbaseJdlJHipsterContent;