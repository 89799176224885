import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DocumentsNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-documents", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    <b>1. Creare</b>:
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'coreSession.createDocumentModel(parentPath,name, typeName)'}
                    </SyntaxHighlighter>
                    Exemplu:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'DocumentModel doc = coreSession.createDocumentModel("/","my-file", "File");\n' +
                        'doc = coreSession.createDocument(doc);\n' +
                        'coreSession.save()'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'coreSession.createDocument(model)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Actualizare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'coreSession.saveDocument(docModel)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Returnare document</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'IdRef docRef = new IdRef(doc.getId());\n' +
                        'coreSession.getDocument(docRef);'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'PathRef docRef = new PathRef(doc.getPathAsString());\n' +
                        'coreSession.getDocument(docRef);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Query</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'coreSession.query(query)'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'DocumentModelList docs= coreSession.query("SELECT * FROM File")'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Permisiuni</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'coreSession.hasPermission(principal, docRef, persmission)'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Setare proprietati</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'doc.setPropertyValue(xpath,value)'}
                    </SyntaxHighlighter>

                    Exemplu:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'DocumentModel doc = coreSession.createDocumentModel("/","my-file", "File");\n\n' +
                        'doc.setPropertyValue("dc:title","Documentul meu")\n\n'+
                        'doc = coreSession.createDocument(doc); // CREARE\n' +
                        'coreSession.save();\n\n'+
                        'doc.setPropertyValue("dc:description","foarte frumos");\n\n'+
                        'doc = coreSession.saveDocument(doc); // UPDATE\n'+
                        'coreSession.save();\n\n'+
                        'doc.setPropertyValue("contract:startDate", new GregorianCalendar());\n\n' +
                        'Map<String,Serializable> customer = new HashMap<String, Serializable>();\n'+
                        'customer.put("firstName","Ion");\n'+
                        'customer.put("lastName","Vasile");\n\n'+
                        'List<Map<String,Serializable>> customerList = new List<HashMap<String, Serializable>>();\n'+
                        'customerList.add(customer);\n'+
                        'doc.setPropertyValue("contract:customers",(Serializable)customerList.add(customer););\n\n'+
                        'doc = coreSession.saveDocument(doc); // UPDATE\n'+
                        'coreSession.save();\n\n'+

                        'Property contractCustomers = doc.getProperty("contract:customers");\n'+
                        'Property firstCustomer = contractCustomers.get(0);\n'+
                        'Serializable firstCustomerLastName = firstCustomer.getValue("lastName");'
                        }
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Document Adapter</b>
                    <br/>
                    <br/>
                    Se creaza un wrapper pentru un document de un anumit tip (de exemplu, <i>ContractAdapter</i>, pentru document de tip Contract);

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'ContractAdapter contract = doc.getAdapter(ContractAdapter.class);'}
                    </SyntaxHighlighter>

                    Un Adapter se poate genera folosind <b>yo nuxeo adapter</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'yo nuxeo adapter'}
                    </SyntaxHighlighter>

                    Cerinte pentru a functiona comanda de mai sus:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'npm install -g yo generator-nuxeo'}
                    </SyntaxHighlighter>

                    Rezultat pentru generarea adapter-ului:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'yo nuxeo adapter\n' +
                        '\n' +
                        'dxxxxxxxxxxc    oxxo       lxxx lkkl       ;kkk\n' +
                        'dxxxxxxxxxxxd;  oxxo       lxxx lkkkx:.  ,dkkkx\n' +
                        'dxxc       lxxo oxxo       lxxx  "okkkkokkkkd,\n' +
                        'dxxc       lxxo oxxo       lxxx    .dkkkkkk.                  Welcome to\n' +
                        'dxxc       lxxo oxxo       lxxx   ,dkkkkkkkk,               Nuxeo Generator\n' +
                        'dxxc       lxxo "oxxcccccccdxxx ,kkkkx" "okkkk,\n' +
                        'loo;       :ooc   "cooooooooool xkko       ckko\n' +
                        '\n' +
                        ':cc,       ;cc;                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        'dxxc       lxxo                 oxxxxxxxxxxxxxo\n' +
                        'dxxc       lxxo                 oxxo\n' +
                        '"cxxoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '   xoooooooxxxo                 oxxxxxxxxxxxxxo\n' +
                        '\n' +
                        'lkkl       ;kkk oxxxxxxxxxxxxxo xooooooooooo,\n' +
                        'lkkkx:.  ,dkkkx oxxxxxxxxxxxxxo lxxxxxxxxxxxxb;\n' +
                        ' "okkkkokkkkd,  oxxo            lxxd       :xxx\n' +
                        '   .dkkkkkk.    oxxxxxxxxxxxxxo lxxd       :xxx\n' +
                        '  ,dkkkkkkkk,   oxxo            cxxd       :xxx\n' +
                        ',kkkkx" "okkkk, oxxxxxxxxxxxxxo  "oxxxxxxxxxxxx\n' +
                        'xkko       ckko oxxxxxxxxxxxxxo    :xxxxxxxxxxx\n' +
                        '\n' +
                        '\n' +
                        '     info Installation of single-module is skipped.\n' +
                        '     info Your target Nuxeo version is: 10.10\n' +
                        '     info You will be prompted for generation of:\n' +
                        '     info   nuxeo-test-core: adapter\n' +
                        '\n' +
                        '   create Generate Module: nuxeo-test-core\n' +
                        '\n' +
                        '   create Generating Adapter\n' +
                        '     info   Parameters: Document adapter package, Document type\n' +
                        '? Document Adapter Package: org.mycompany.myproject\n' +
                        '? Document Type ID: File\n' +
                        '   create Writing Adapter\n' +
                        '     info Maven dependency: org.nuxeo.ecm.core:nuxeo-core-api\n' +
                        '     info Maven dependency: org.nuxeo.runtime:nuxeo-runtime\n' +
                        '     info Maven dependency: org.nuxeo.ecm.core:nuxeo-core-test:::test\n' +
                        '    force nuxeo-test-core\\pom.xml\n' +
                        '    force nuxeo-test-core\\src\\main\\resources\\META-INF\\MANIFEST.MF\n' +
                        '   create nuxeo-test-core\\src\\main\\java\\org\\mycompany\\myproject\\FileAdapter.java\n' +
                        '   create nuxeo-test-core\\src\\main\\java\\org\\mycompany\\myproject\\FileAdapterFactory.java\n' +
                        '   create nuxeo-test-core\\src\\test\\java\\org\\mycompany\\myproject\\TestFileAdapter.java\n' +
                        '   create nuxeo-test-core\\src\\main\\resources\\OSGI-INF\\org.mycompany.myprojectfile-adapter-contrib.xml\n' +
                        '   create Your project is ready!\n' +
                        '     info You can start editing code or you can continue with calling another generator (yo nuxeo [<generator>..])'}
                    </SyntaxHighlighter>

                    Exemplu fisier generat <i>FileAdapter</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package org.mycompany.myproject;\n' +
                        '\n' +
                        'import org.nuxeo.ecm.core.api.CoreSession;\n' +
                        'import org.nuxeo.ecm.core.api.DocumentModel;\n' +
                        'import org.nuxeo.ecm.core.api.DocumentRef;\n' +
                        '\n' +
                        '/**\n' +
                        ' *\n' +
                        ' */\n' +
                        'public class FileAdapter {\n' +
                        '  protected DocumentModel doc;\n' +
                        '\n' +
                        '  protected String titleXpath = "dc:title";\n' +
                        '  protected String descriptionXpath = "dc:description";\n' +
                        '\n' +
                        '  public FileAdapter(DocumentModel doc) {\n' +
                        '    this.doc = doc;\n' +
                        '  }\n' +
                        '\n' +
                        '  // Basic methods\n' +
                        '  //\n' +
                        '  // Note that we voluntarily expose only a subset of the DocumentModel API in this adapter.\n' +
                        '  // You may wish to complete it without exposing everything!\n' +
                        '  // For instance to avoid letting people change the document state using your adapter,\n' +
                        '  // because this would be handled through workflows / buttons / events in your application.\n' +
                        '  //\n' +
                        '\n' +
                        '  public void save() {\n' +
                        '    CoreSession session = doc.getCoreSession();\n' +
                        '    doc = session.saveDocument(doc);\n' +
                        '  }\n' +
                        '\n' +
                        '  public DocumentRef getParentRef() {\n' +
                        '    return doc.getParentRef();\n' +
                        '  }\n' +
                        '\n' +
                        '  // Technical properties retrieval\n' +
                        '  public String getId() {\n' +
                        '    return doc.getId();\n' +
                        '  }\n' +
                        '\n' +
                        '  public String getName() {\n' +
                        '    return doc.getName();\n' +
                        '  }\n' +
                        '\n' +
                        '  public String getPath() {\n' +
                        '    return doc.getPathAsString();\n' +
                        '  }\n' +
                        '\n' +
                        '  public String getState() {\n' +
                        '    return doc.getCurrentLifeCycleState();\n' +
                        '  }\n' +
                        '\n' +
                        '  // Metadata get / set\n' +
                        '  public String getTitle() {\n' +
                        '    return doc.getTitle();\n' +
                        '  }\n' +
                        '\n' +
                        '  public void setTitle(String value) {\n' +
                        '    doc.setPropertyValue(titleXpath, value);\n' +
                        '  }\n' +
                        '\n' +
                        '  public String getDescription() {\n' +
                        '    return (String) doc.getPropertyValue(descriptionXpath);\n' +
                        '  }\n' +
                        '\n' +
                        '  public void setDescription(String value) {\n' +
                        '    doc.setPropertyValue(descriptionXpath, value);\n' +
                        '  }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Exemplu fisier generat <i>FileAdapterFactory</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package org.mycompany.myproject;\n' +
                        '\n' +
                        'import org.nuxeo.ecm.core.api.DocumentModel;\n' +
                        'import org.nuxeo.ecm.core.api.adapter.DocumentAdapterFactory;\n' +
                        '\n' +
                        'public class FileAdapterFactory implements DocumentAdapterFactory {\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public Object getAdapter(DocumentModel doc, Class<?> itf) {\n' +
                        '        if ("File".equals(doc.getType()) && doc.hasSchema("dublincore")){\n' +
                        '            return new FileAdapter(doc);\n' +
                        '        }else{\n' +
                        '            return null;\n' +
                        '        }\n' +
                        '    }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Exemplu fisier generat <i>TestFileAdapter</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package org.mycompany.myproject;\n' +
                        '\n' +
                        'import javax.inject.Inject;\n' +
                        '\n' +
                        'import org.junit.Assert;\n' +
                        'import org.junit.Test;\n' +
                        'import org.junit.runner.RunWith;\n' +
                        'import org.nuxeo.ecm.core.api.CoreSession;\n' +
                        'import org.nuxeo.ecm.core.api.DocumentModel;\n' +
                        'import org.nuxeo.ecm.core.test.CoreFeature;\n' +
                        'import org.nuxeo.runtime.test.runner.Deploy;\n' +
                        'import org.nuxeo.runtime.test.runner.Features;\n' +
                        'import org.nuxeo.runtime.test.runner.FeaturesRunner;\n' +
                        'import org.mycompany.myproject.FileAdapter;\n' +
                        '\n' +
                        '@RunWith(FeaturesRunner.class)\n' +
                        '@Features(CoreFeature.class)\n' +
                        '@Deploy({"ro.dopamina.operation.kj-operation"})\n' +
                        'public class TestFileAdapter {\n' +
                        '  @Inject\n' +
                        '  CoreSession session;\n' +
                        '\n' +
                        '  @Test\n' +
                        '  public void shouldCallTheAdapter() {\n' +
                        '    String doctype = "File";\n' +
                        '    String testTitle = "My Adapter Title";\n' +
                        '\n' +
                        '    DocumentModel doc = session.createDocumentModel("/", "test-adapter", doctype);\n' +
                        '    FileAdapter adapter = doc.getAdapter(FileAdapter.class);\n' +
                        '    adapter.setTitle(testTitle);\n' +
                        '    Assert.assertEquals("Document title does not match when using the adapter", testTitle, adapter.getTitle());\n' +
                        '  }\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Fisierul generat <i>org.mycompany.myprojectfile-adapter-contrib.xml</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<component name="org.mycompany.myproject.adapters.file">\n' +
                        '\n' +
                        '  <extension target="org.nuxeo.ecm.core.api.DocumentAdapterService" point="adapters">\n' +
                        '    <adapter class="org.mycompany.myproject.FileAdapter" factory="org.mycompany.myproject.FileAdapterFactory"/>\n' +
                        '  </extension>\n' +
                        '\n' +
                        '</component>\n'}
                    </SyntaxHighlighter>

                    Fisierul generat <i>MANIFEST.MF</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'Bundle-ActivationPolicy: lazy\n' +
                        'Bundle-ClassPath: .\n' +
                        'Manifest-Version: 1.0\n' +
                        'Bundle-Vendor: ro.dopamina.operation\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Bundle-Name: kj-operation\n' +
                        'Bundle-ManifestVersion: 2\n' +
                        'Bundle-SymbolicName: ro.dopamina.operation.kj-operation;singleton=true\n' +
                        'Nuxeo-Component: OSGI-INF/kjoperation-operation-contrib.xml,\n' +
                        ' OSGI-INF/mycontractservice-service.xml,\n' +
                        ' OSGI-INF/org.mycompany.myprojectfile-adapter-contrib.xml\n'}
                    </SyntaxHighlighter>
                </div>

                {/*<br/>*/}
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}
                {/*        <div>*/}
                {/*            <a href={"https://doc.nuxeo.com/nxdoc/acls/"}>*/}
                {/*                ACLs*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </ol>*/}
                {/*</div>*/}

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DocumentsNuxeoContent;