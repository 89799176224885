import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class IntroGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-intro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Verificare versiune</b>
                    <br/>
                    <b>gradle -v</b>
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'gradle -v\n' +
                        '\n' +
                        '------------------------------------------------------------\n' +
                        'Gradle 6.4.1\n' +
                        '------------------------------------------------------------\n' +
                        '\n' +
                        'Build time:   2020-05-15 19:43:40 UTC\n' +
                        'Revision:     1a04183c502614b5c80e33d603074e0b4a2777c5\n' +
                        '\n' +
                        'Kotlin:       1.3.71\n' +
                        'Groovy:       2.5.10\n' +
                        'Ant:          Apache Ant(TM) version 1.10.7 compiled on September 1 2019\n' +
                        'JVM:          11 (Oracle Corporation 11+28)\n' +
                        'OS:           Windows 10 10.0 amd64'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Hei, buna!</b>
                    <br/>
                    Creem un fisier cu numele <b>build.gradle</b>, cu urmatorul continut:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task hello {\n' +
                        '\tdoLast{\n' +
                        '\t\tprintln \'Hei, buna!\'\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Scriptul de mai sus, specifica un task (sarcina) numit "hello". Pentru a executa task-ul "hello", scriem in linie de comanda:
                    <br/>
                    <b>gradle hello</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle hello\n' +
                        '\n' +
                        '> Task :hello\n' +
                        'hello, world\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '1 actionable task: 1 executed'}
                    </SyntaxHighlighter>

                    Pentru a nu mai afisa detalii legate de durata ce numele task-ului executat, scriem in linie de comanda:
                    <br/>
                    <b>gradle -q hello</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle -q hello\n' +
                        'hello, world'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Observatie</b>:
                    <br/>
                    Operatorul (left shift) <b>&lt;&lt;</b> a fost depreciat in Gradle 4.x si sters in Gradle 5.0, find inlocuit cu <b>doLast</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task myTask << {...}'}
                    </SyntaxHighlighter>
                    devine:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'task myTask {\n' +
                        '    doLast {\n' +
                        '        ...\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Build file</b>
                    <br/>

                    Daca avem alt nume decat <b>build.gradle</b> (numele implicit) din linie de comanda putem specifica explcit numele fisierului:
                    <br/>
                    <b>gradle -b <i>nume-fisier</i></b>
                    <br/>
                    sau
                    <br/>
                    <b>gradle --build-file <i>nume-fisier</i></b>

                    <br/>
                    Daca avem fisierul <i>build-2.gradle</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle -b build-2.gradle -q hello'}
                    </SyntaxHighlighter>

                    <b>Observatie</b>:
                    <br/>
                    Optiunile, din linia de comanda:
                    <ul>
                        <li> -c, --settings-file : pentru a specifica locatia pentru fisierul settings</li>
                        <li> -b, --build-file: pentru a specifica locatia pentru fisierul build</li>
                    </ul>
                    sunt <i>deprecated</i>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IntroGradleContent;