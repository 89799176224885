import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InheritancePythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-mro", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> MRO (Ordinea de rezoluție a metodei)</b>
                    <br/>
                    <br/>
                    Ordinea de rezoluție a metodei definește calea de căutare a clasei folosită pentru a căuta metoda potrivită de utilizat în clasele cu moștenire multiplă.
                    <br/>
                    <br/>


                    <b>Incepand cu versiune Python 2.3</b>, algoritmul MRO s-a schimbat, dar este folosit doar in cazul <b>claselor noi</b>, in caz contrar se foloseste algoritmul vechi MRO.

                    O clasa noua este o clasa unde primul parinte mosteneste clasa <b>object</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A(object):\n' +
                        '    pass'}
                    </SyntaxHighlighter>

                    Deci, daca avem urmatoarea ierarhie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A :\n' +
                        '    pass\n' +
                        'class B :\n' +
                        '    pass\n' +
                        'class C(A, B) :\n' +
                        '    pass'}
                    </SyntaxHighlighter>
                    Atunci o instanta de tip C va folosi vechiul algoritm MRO (pentru ca niciuna dintre clasele mostenite nu este derivată din clasa rădăcină <b>object</b> Python).
                    <br/>

                    Deci, daca avem urmatoarea ierarhie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A(object) :\n' +
                        '    pass\n' +
                        'class B(object) :\n' +
                        '    pass\n' +
                        'class C(A, B) :\n' +
                        '    pass'}
                    </SyntaxHighlighter>
                    Atunci o instanta de tip C va folosi noul algoritm MRO.

                    <br/>
                    <br/>

                    <b>Începand cu versiunea Python 3</b>, fiecare clasă moștenește <b>implicit</b> clasa <b>object</b>. Deci noul algoritm va fi folosit în ambele cazuri de mai sus cu Python 3.

                    <hr/>
                    <b>1. Vechiul algoritm MRO</b>
                    <br/>
                    <br/>

                    Pornim de la urmatorul exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def metoda(self):\n' +
                        '        print("A")\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    def metoda(self):\n' +
                        '        print("B")\n' +
                        '\n' +
                        'class C(A):\n' +
                        '    def metoda(self):\n' +
                        '        print("C")\n' +
                        '\n' +
                        'class D(B,C):\n' +
                        '    def metoda(self):\n' +
                        '        print("D")\n' +
                        '\n' +
                        'd = D()\n' +
                        'd.metoda() # D'}
                    </SyntaxHighlighter>

                    Acum, stergem/comentam metoda <i>metoda()</i> din clasa <i>D</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def metoda(self):\n' +
                        '        print("A")\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    def metoda(self):\n' +
                        '        print("B")\n' +
                        '\n' +
                        'class C(A):\n' +
                        '    def metoda(self):\n' +
                        '        print("C")\n' +
                        '\n' +
                        'class D(B,C):\n' +
                        '    pass\n' +
                        '\n' +
                        'd = D()\n' +
                        'd.metoda() # B'}
                    </SyntaxHighlighter>

                    Acum, stergem/comentam metoda <i>metoda()</i> din clasa <i>B</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class A:\n' +
                        '    def metoda(self):\n' +
                        '        print("A")\n' +
                        '\n' +
                        'class B(A):\n' +
                        '    pass\n' +
                        '\n' +
                        'class C(A):\n' +
                        '    def metoda(self):\n' +
                        '        print("C")\n' +
                        '\n' +
                        'class D(B,C):\n' +
                        '    pass\n' +
                        '\n' +
                        'd = D()\n' +
                        'd.metoda() # A, (incepand cu versiunea Python 3.0, se afiseaza C )'}
                    </SyntaxHighlighter>

                    Atunci când o clasă moștenește mai multe clase, Python <b>construiește o listă de clase</b> si o foloseste atunci cand trebuie să gaseasca <b>ce metodă trebuie apelată</b> atunci când este invocată prin intermediul unei instanțe.

                    <br/>
                    <br/>
                    Algoritmul este o parcurgere arborescenta (mai intai in adancime, ci apoi de la stanga la dreapta):
                    <ul>
                        <li>1. se uita daca metoda exista in clasa de instanta</li>
                        <li>2. daca nu o gaseste, se uita in primul parinte, apoi in parintele parintelui si asa mai departe</li>
                        <li>3. daca nu o gaseste, se uita daca clasa curenta mosteneste de la alte clase pana la instanta alti parinti</li>
                    </ul>

                    Deci, în exemplul nostru, calea de căutare a algoritmului este: D, B, A, C, A.
                    <br/>
                    Si cum o clasă nu poate apărea de două ori în calea de căutare, versiunea finală este D, B, A, C:
                    <ul>
                        <li>se cauta in <b>D</b></li>
                        <li>daca nu e gasit, se cauta in <b>B</b></li>
                        <li>daca nu e gasit, se cauta in <b>A</b> (primul parinte a lui B)</li>
                        <li>daca nu e gasit, se revine in B, nu gaseste alti parinti</li>
                        <li>daca nu e gasit, se revine in D, gaseste alt parinte <b>C</b> (C are parinte A, dar nu se ia in considerare pentru ca a mai trecut pe acolo)</li>
                    </ul>

                    <hr/>
                    <b>2. Noul algoritm MRO</b>
                    <br/>
                    <br/>

                    <b>Liniarizarea C3</b>
                    <br/>
                    Linializarea C3 este un algoritm folosit  pentru a obține ordinea în care metodele ar trebui să fie moștenite în prezența moștenirii multiple.
                    Rezultatul liniarizării superclasei C3 este un MRO determinist.
                    <br/><br/>
                    Algoritm pentru o clasa C:
                    <ul>
                        <li>
                            <b>L[C] = [C] + merge( L[P1] + L[P1] + ... + L[Pn] + [P1, P2,...,Pn])</b>
                            <br/>
                            (îmbinare unică (merge) a <b>liniarizărilor părinților lui C</b> și o <b>listă a părinților</b>; lista de părinți păstrează ordinea de prioritate locală a claselor părinte directe)
                            <br/>
                        </li>

                        <li>
                            <b>cat timp (toate listele nu goale)</b> (procesul de fuzionare / liniarizare)
                            <ul>
                                <li>
                                    <b>1. selectare element (cap bun)</b>:
                                    selectarea primului cap al listelor care nu apare în coada (toate elementele unei liste, cu excepția primei) a niciuna dintre liste
                                    <br/>
                                    (ex: [A,B,C], [D,A] - nu poate fi cap, pentru ca apare in lista [D,A] si nu este cap)
                                </li>

                                <li>
                                    <b>2. atasare element (cap bun)</b>:
                                    atasarea la lista de ieșire (rezultat)
                                </li>

                                <li>
                                    <b>3. stergere element (cap bun)</b>:
                                    elementul selectat este eliminat din toate listele în care apare ca un cap
                                </li>
                            </ul>
                            <br/>
                        </li>

                        <li>
                            <b>observatii</b>:
                            <ul>
                                <li>dacă la un moment dat nu poate fi selectat niciun cap bun (capetele tuturor listelor rămase apar în orice coadă a listelor),
                                    atunci îmbinarea este imposibil de calculat din cauza ordonărilor inconsecvente a dependențelor în ierarhia de moștenire și a lipsei de liniarizare</li>
                                <li>un cap bun poate apărea ca prim element în mai multe liste în același timp, dar este interzis să apară oriunde altundeva</li>
                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class O: pass\n' +
                        '\n' +
                        'class A(O): pass\n' +
                        '\n' +
                        'class B(O): pass\n' +
                        '\n' +
                        'class C(O): pass\n' +
                        '\n' +
                        'class D(O): pass\n' +
                        '\n' +
                        'class E(O): pass\n' +
                        '\n' +
                        'class K1(A, B, C): pass\n' +
                        '\n' +
                        'class K2(D, B, E): pass\n' +
                        '\n' +
                        'class K3(D, A): pass\n' +
                        '\n' +
                        'class Z(K1, K2, K3): pass\n' +
                        '\n' +
                        'print(Z.mro()) # [<class \'__main__.Z\'>, <class \'__main__.K1\'>, <class \'__main__.K2\'>, <class \'__main__.K3\'>, <class \'__main__.D\'>, <class \'__main__.A\'>, <class \'__main__.B\'>, <class \'__main__.C\'>, <class \'__main__.E\'>, <class \'__main__.O\'> <class \'object\'>]\n' +
                        '               # Z, K1, K2, K3, D, A, B, C, E, O, object'}
                    </SyntaxHighlighter>

                    Sa calculam liniarizarile:
                    <ul>
                        <li>
                            <pre>L[O] = [O] (nu are parinti)</pre>
                        </li>
                        <li>
                            <pre>
                                 L[A] = <br/>
                                      = [A] + merge(L[O] + [O])<br/>
                                      = [A] + merge([O]+[O])<br/>
                                      = [A] + [O]<br/>
                                      = [A,O]<br/>
                            </pre>

                        </li>
                        <li>
                            <pre>L[B] = [A,O]</pre>
                        </li>
                        <li>
                            <pre>L[C] = [A,O]</pre>
                        </li>
                        <li>
                            <pre>L[D] = [A,O]</pre>
                        </li>
                        <li>
                            <pre>L[E] = [A,O]</pre>
                        </li>
                        <li>
                            <pre>
                                 L[K1] = <br/>
                                      = [K1] + merge(L[A] + L[B] + L[C] + [A,B,C])<br/>
                                      = [K1] + merge([A,O] + [B,O] + [C,O] + [A,B,C])<br/>
                                        (selectam clasa A ca fiind cap bun; apare ca si cap in prima si in ultima lista; apoi o stergem din liste si o adaugam la lista finala) <br/>
                                      = [K1, A] + merge([O] + [B, O] + [C,O] + [B,C])<br/>
                                        selectam clasa B ca fiind un cap bun; B nu este cap bun, pentru ca apare ultimul in lista [C,O]<br/>
                                      = [K1, A, B] + merge([O] + [O] + [C,O] + [C])<br/>
                                      = [K1, A, B, C] + merge([O] + [O] + [O])<br/>
                                      = [K1, A, B, C] + merge([O] + [O] + [O])<br/>
                                      = [K1, A, B, C, O]<br/>
                            </pre>

                        </li>

                        <li>
                            <pre>
                                 L[K1] = <br/>
                                      = [K1] + merge(L[A] + L[B] + L[C] + [A,B,C])<br/>
                                      = [K1] + merge([A,O] + [B,O] + [C,O] + [A,B,C])<br/>
                                        (selectam clasa A ca fiind cap bun; apare ca si cap in prima si in ultima lista; apoi o stergem din liste si o adaugam la lista finala) <br/>
                                      = [K1, A] + merge([O] + [B, O] + [C,O] + [B,C])<br/>
                                        selectam clasa B ca fiind un cap bun; B nu este cap bun, pentru ca apare ultimul in lista [C,O]<br/>
                                      = [K1, A, B] + merge([O] + [O] + [C,O] + [C])<br/>
                                      = [K1, A, B, C] + merge([O] + [O] + [O])<br/>
                                      = [K1, A, B, C] + merge([O] + [O] + [O])<br/>
                                      = [K1, A, B, C, O]<br/>
                            </pre>

                        </li>


                        <li>
                            <pre>
                                 L[K2] = <br/>
                                      = [K2] + merge(L(D), L(B), L(E), [D, B, E])<br/>
                                      = [K2] + merge([D, O], [B, O], [E, O], [D, B, E])  (selectez D)<br/>
                                      = [K2, D] + merge([O], [B, O], [E, O], [B, E])     (selectez B)<br/>
                                      = [K2, D, B] + merge([O], [O], [E, O], [E])        (selectez E)<br/>
                                      = [K2, D, B, E] + merge([O], [O], [O])             (selectez O)<br/>
                                      = [K2, D, B, E, O]            <br/>
                            </pre>

                        </li>

                        <li>
                            <pre>
                                 L[K3] = <br/>
                                      = [K3] + merge(L(D), L(A), [D, A])<br/>
                                      = [K3] + merge([D, O], [A, O], [D, A])             (selectez D)<br/>
                                      = [K3, D] + merge([O], [A, O], [A])                (selectez A)<br/>
                                      = [K3, D, A] + merge([O], [O])                     (selectez O)<br/>
                                      = [K3, D, A, O]            <br/>
                            </pre>

                        </li>

                        <li>
                            <pre>
                                 L(Z) = <br/>
                                    = [Z] + merge(L(K1), L(K2), L(K3), [K1, K2, K3])                                <br/>
                                    = [Z] + merge([K1, A, B, C, O], [K2, D, B, E, O], [K3, D, A, O], [K1, K2, K3])   (selectez K1)<br/>
                                    = [Z, K1] + merge([A, B, C, O], [K2, D, B, E, O], [K3, D, A, O], [K2, K3])       (selectez K2, nu se poate alege A, pt ca:[K3, D, A, O])<br/>
                                    = [Z, K1, K2] + merge([A, B, C, O], [D, B, E, O], [K3, D, A, O], [K3])           (selectez K3), nu se poate alege A, si nici D<br/>
                                    = [Z, K1, K2, K3] + merge([A, B, C, O], [D, B, E, O], [D, A, O])                 (selectez D), nu se poate alege A<br/>
                                    = [Z, K1, K2, K3, D] + merge([A, B, C, O], [B, E, O], [A, O])                    (selectez A)<br/>
                                    = [Z, K1, K2, K3, D, A] + merge([B, C, O], [B, E, O], [O])                       (selectez B)<br/>
                                    = [Z, K1, K2, K3, D, A, B] + merge([C, O], [E, O], [O])                          (selectez C)<br/>
                                    = [Z, K1, K2, K3, D, A, B, C] + merge([O], [E, O], [O])                          (selectez E)<br/>
                                    = [Z, K1, K2, K3, D, A, B, C, E] + merge([O], [O], [O])                          (selectez O)<br/>
                                    = [Z, K1, K2, K3, D, A, B, C, E, O]
                            </pre>

                        </li>

                    </ul>

                    <hr/>
                    Exemplu 2 (care va arunca o exceptie):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class X:\n' +
                        '    def metoda(self):\n' +
                        '        print("X")\n' +
                        '\n' +
                        'class Y:\n' +
                        '     def metoda(self):\n' +
                        '        print("Y")\n' +
                        '        \n' +
                        'class A(X,Y):\n' +
                        '    def metoda(self):\n' +
                        '        print("A")\n' +
                        '\n' +
                        'class B(Y,X):\n' +
                        '     def metoda(self):\n' +
                        '        print("A")\n' +
                        '\n' +
                        'class C(A,B):\n' +
                        '    def metoda(self):\n' +
                        '        print("C")\n' +
                        '\n' +
                        'c = C()\n' +
                        'c.metoda() # TypeError: Cannot create a consistent method resolution\n' +
                        '           # order (MRO) for bases X, Y'}
                    </SyntaxHighlighter>

                    Sa calculam liniarizarile:
                    <ul>
                        <li>
                            <pre>L[X] = [X] (nu are parinti)</pre>
                        </li>
                        <li>
                            <pre>L[Y] = [X] (nu are parinti)</pre>
                        </li>
                        <li>
                            <pre>
                                L[A] = <br/>
                                    = [A] + merge(L[X]+L[Y]+[X,Y])  <br/>
                                    = [A] + merge([X]+[Y]+[X,Y])  <br/>
                                    = [A,X] + merge([Y]+[Y]) <br/>
                                    = [A,X,Y]
                            </pre>
                        </li>

                        <li>
                            <pre>
                                L[B] = <br/>
                                    = [A] + merge(L[Y]+L[X]+[Y,X])  <br/>
                                    = [A] + merge([Y]+[X]+[Y,X])  <br/>
                                    = [A,Y] + merge([X]+[X]) <br/>
                                    = [A,Y,X]
                            </pre>
                        </li>

                        <li>
                            <pre>
                                L[C] = <br/>
                                    = [C] + merge(L[A]+L[B]+[A,B])        <br/>
                                    = [C] + merge([A,X,Y]+[A,Y,X]+[A,B])  (selectez A)<br/>
                                    = [C,A] + merge([X,Y]+[Y,X]+[B])      (selectez B, nu pot selecta X, Y)<br/>
                                    = [C,A,B] + merge([X,Y]+[Y,X])        (nu pot sa selectez nimic, <b>EROARE!</b>)
                            </pre>
                        </li>

                    </ul>

                    <hr/>
                    Exemplu 3 (care va arunca o exceptie):

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Top:\n' +
                        '    pass\n' +
                        '\n' +
                        'class Middle(Top):\n' +
                        '    pass\n' +
                        '\n' +
                        'class Bottom(Top, Middle):\n' +
                        '    pass\n' +
                        '\n' +
                        '# TypeError: Cannot create a consistent method resolution\n' +
                        '# order (MRO) for bases Top, Middle'}
                    </SyntaxHighlighter>

                    Sa calculam liniarizarile:
                    <ul>
                        <li>
                            <pre>L[Top] = [Top] (nu are parinti)</pre>
                        </li>

                        <li>
                            <pre>
                                L[Middle] = [Middle] + merge(L[Top] + [Top]) <br/>
                                          = [Middle, Top]
                            </pre>
                        </li>

                        <li>
                            <pre>
                                L[Bottom] = [Bottom] + merge(L[Top] + L[Middle] + [Top, Middle]) <br/>
                                          = [Bottom] + merge( [Top] + [Middle, Top] + [Top, Middle] (nu pot sa selectez nimic, <b>EROARE!</b>)
                            </pre>
                        </li>

                    </ul>

                    <hr/>
                    Exemplu 4

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class Top:\n' +
                        '    pass\n' +
                        '\n' +
                        'class Middle(Top):\n' +
                        '    pass\n' +
                        '\n' +
                        'class Bottom(Middle, Top):\n' +
                        '    pass\n' }
                    </SyntaxHighlighter>

                    Sa calculam liniarizarile:
                    <ul>
                        <li>
                            <pre>L[Top] = [Top] (nu are parinti)</pre>
                        </li>

                        <li>
                            <pre>
                                L[Middle] = [Middle] + merge(L[Top] + [Top]) <br/>
                                          = [Middle, Top]
                            </pre>
                        </li>

                        <li>
                            <pre>
                                L[Bottom] = [Bottom] + merge( L[Middle] + L[Top] + [Middle, Top] ) <br/>
                                          = [Bottom] + merge( [Middle, Top] + [Top] + [Middle, Top] )  (selectez Middle)<br/>
                                          = [Bottom, Middle] + merge( [Top] + [Top] + [Top])  <br/>
                                          = [Bottom, Middle, Top]  <br/>
                            </pre>
                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://makina-corpus.com/python/python-tutorial-understanding-python-mro-class-search-path"}>Python Tutorial: Understanding Python MRO - Class search path</a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://en.wikipedia.org/wiki/C3_linearization"}>C3 linearization</a>
                            </div>
                        </li>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InheritancePythonContent;