import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class ProjectObjectGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-project-object", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    Pentru fiecare proiect, Gradle creaza o instanta <b>org.gradle.api.Project</b> si il asociaza cu scriptul de build. Acest lucru, permite ca script-ul de build sa aiba acces la proprietatile si metodele API-ului definit de org.gradle.api.Project.

                    <br/>
                    <br/>
                    Sa presupunem ca avem urmatorul script <b>build.gradle</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'plugins {\n' +
                        '    // Apply the application plugin to add support for building a CLI application in Java.\n' +
                        '    id \'application\'\n' +
                        '}\n' +
                        '\n' +
                        'repositories {\n' +
                        '    // Use Maven Central for resolving dependencies.\n' +
                        '    mavenCentral()\n' +
                        '}\n' +
                        '\n' +
                        'dependencies {\n' +
                        '    // Use JUnit test framework.\n' +
                        '    testImplementation \'junit:junit:4.13.2\'\n' +
                        '\n' +
                        '    // This dependency is used by the application.\n' +
                        '    implementation \'com.google.guava:guava:30.1.1-jre\'\n' +
                        '}\n' +
                        '\n' +
                        'application {\n' +
                        '    // Define the main class for the application.\n' +
                        '    mainClass = \'demo.App\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    In scriptul de mai sus: <b>repositories</b>, <b>dependencies</b> , <b>application</b> sunt metode ale clasei Project, care primesc ca parametru un <i>closure</i>.
                    Mai mult decat atat, avem obiectul <i>project</i> prin itermediul caruia putem apela metodele de mai sus.
                    <br/>
                    <br/>
                    Deci se poate scrie si asa:

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'plugins ({\n' +
                        '    // Apply the application plugin to add support for building a CLI application in Java.\n' +
                        '    id \'application\'\n' +
                        '})\n' +
                        '\n' +
                        'project.repositories ({\n' +
                        '    // Use Maven Central for resolving dependencies.\n' +
                        '    mavenCentral()\n' +
                        '})\n' +
                        '\n' +
                        'project.dependencies ({\n' +
                        '    // Use JUnit test framework.\n' +
                        '    testImplementation \'junit:junit:4.13.2\'\n' +
                        '\n' +
                        '    // This dependency is used by the application.\n' +
                        '    implementation \'com.google.guava:guava:30.1.1-jre\'\n' +
                        '})\n' +
                        '\n' +
                        'project.application ({\n' +
                        '    // Define the main class for the application.\n' +
                        '    mainClass = \'demo.App\'\n' +
                        '})'}
                    </SyntaxHighlighter>

                    <i>Observatie</i>:
                    <ul>
                        <li><b>plugins</b> nu este o metoda! (in versiunele mai vechi de Gradle exista metoda <b>apply</b> - apply plugin - utilizata pentru a aplica un plugin la proiect)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ProjectObjectGradleContent;