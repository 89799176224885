import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class NetworkingDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-networking", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Introducere in Networking</b>
                    <br/>
                    <br/>

                    Daca avem 2 containere, aceste pot comunica intre ele prin IP.
                    In mod implicit, cele 2 containere nu pot comunica prin hostname sau numele container.
                    <br/>
                    <br/>
                    In mod implicit se foloseste reteaua <b>brige</b>.

                    <hr/>
                    <b>2. Listare retele</b>
                    <br/>
                    <br/>

                    Pentru a afisa lista de retele disponibile.
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network ls'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'NETWORK ID     NAME               DRIVER    SCOPE\n' +
                            '2be808aad381   bridge             bridge    local\n' +
                            '7c8e7915edf6   complex_default    bridge    local\n' +
                            '14875372bd61   frontend_default   bridge    local\n' +
                            '8472c2b25bc0   host               host      local\n' +
                            '4d4450cdfa4d   minikube           bridge    local\n' +
                            '7138c1675165   none               null      local\n' +
                            '5d4729895d48   visits_default     bridge    local'}
                    </SyntaxHighlighter>
                    Reteaua <b>brige</b> nu permite cele 2 containere sa comunice prin hostname sau numele de container.
                    <br/>
                    Dar se poate, folosind o retea <i>custom</i>.

                    <hr/>
                    <b>3. Crearea unei retele noi</b>
                    <br/>
                    <br/>

                    Pentru a creea o reatea <i>custom</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network create kj-net'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Inspectarea unei retele</b>
                    <br/>
                    <br/>

                    Inspectarea unei retele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker network inspect kj-net'}
                    </SyntaxHighlighter>
                    Se poate afisa:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "Name": "kj-net",\n' +
                            '        "Id": "1a096f16a145118a054353acc9e8a0536544e461d567ee72a08a55643ac472fd",\n' +
                            '        "Created": "2022-11-28T20:25:21.403415797+02:00",\n' +
                            '        "Scope": "local",\n' +
                            '        "Driver": "bridge",\n' +
                            '        "EnableIPv6": false,\n' +
                            '        "IPAM": {\n' +
                            '            "Driver": "default",\n' +
                            '            "Options": {},\n' +
                            '            "Config": [\n' +
                            '                {\n' +
                            '                    "Subnet": "172.22.0.0/16",\n' +
                            '                    "Gateway": "172.22.0.1"\n' +
                            '                }\n' +
                            '            ]\n' +
                            '        },\n' +
                            '        "Internal": false,\n' +
                            '        "Attachable": false,\n' +
                            '        "Ingress": false,\n' +
                            '        "ConfigFrom": {\n' +
                            '            "Network": ""\n' +
                            '        },\n' +
                            '        "ConfigOnly": false,\n' +
                            '        "Containers": {\n' +
                            '            "49bbe76a0de3a97eccda5aa9cf6f2d1e393a641a308d9fedfa41df40ad7aaf57": {\n' +
                            '                "Name": "b2",\n' +
                            '                "EndpointID": "bda23d25dd22869dc98dad03a3a6a7dff79252025f813bd4dd4b136da741a618",\n' +
                            '                "MacAddress": "02:42:ac:16:00:03",\n' +
                            '                "IPv4Address": "172.22.0.3/16",\n' +
                            '                "IPv6Address": ""\n' +
                            '            }\n' +
                            '        },\n' +
                            '        "Options": {},\n' +
                            '        "Labels": {}\n' +
                            '    }\n' +
                            ']'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Testare</b>
                    <br/>
                    <br/>

                    Sa cream 2 containere care folosesc reteaua <i>kj-net</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it --network kj-net --name b1 busybox'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it --network kj-net --name b2 busybox'}
                    </SyntaxHighlighter>

                    Din container <i>b1</i>:
                    <SyntaxHighlighter>
                        {'/ # hostname -i\n' +
                            '172.22.0.2'}
                    </SyntaxHighlighter>

                    Din container <i>b2</i>:
                    <SyntaxHighlighter >
                        {'/ # hostname -i\n' +
                            '172.22.0.3'}
                    </SyntaxHighlighter>

                    Din container <i>b1</i>:
                    <SyntaxHighlighter>
                        {'/ # ping 172.22.0.3\n' +
                            'ping b2'}
                    </SyntaxHighlighter>

                    Din container <i>b2</i>:
                    <SyntaxHighlighter>
                        {'/ # ping 172.22.0.2\n' +
                            'ping b1'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NetworkingDockerContent;