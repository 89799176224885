import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nginx/IndexContent";

class TryFilesNamedLocationsNginxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nginx-try-files-named-locations", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Directiva try_files</b>

                    <br/>
                    <br/>

                    Directiva <b>try_files path1 path2 final;</b> incerca pana gaseste o resursa (fie root /sites/demo):
                    <ul>
                        <li>path1 relativ la <b>root</b> (/sites/demo/path1)</li>
                        <li>path2 relativ la <b>root</b> (/sites/demo/root)</li>
                        <li> rescrie final (numai ultimul argument poate fi tradus intr-o rescriere)</li>
                    </ul>

                    <br/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'server{\n' +
                            '\ttry_files path1 path2 final;' +
                            '\tlocation URI{\n' +
                            '\t\ttry_files path1 path2 final;\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\t\n' +
                            '\t\ttry_files /logo.png /hello;\n' +
                            '\t\t\n' +
                            '\t\tlocation /hello {\n' +
                            '\t\t\treturn 200 "hello";\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca se scrie <i>http://IP/</i>, atunci:
                    <ul>
                        <li>se incerca gasirea <i>/site/demo/logo.png</i>; daca il gseste se opreste aici;
                            <br/>

                            deci, daca exista <i>/site/demo/logo.png</i> atunci orice se scrie in URL (http://IP/logo sau http://IP/chat)  va bate in aceasta resursa
                        </li>
                        <li>daca nu il gaseste insearca <i>/hello</i></li>
                    </ul>

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'events {}\n' +
                            '\n' +
                            'http {\n' +
                            '\n' +
                            '\tinclude mine.types;\n' +
                            '\t\n' +
                            '\tserver {\n' +
                            '\t\tlisten 80;\n' +
                            '\t\t\n' +
                            '\t\tserver_name 192.168.1.0;\n' +
                            '\t\t\n' +
                            '\t\troot /sites/demo;\n' +
                            '\t\t\n' +
                            '\t\ttry_files $uri /logo.png /hello /friendly_404\n' +
                            '\t\t\n' +
                            '\t\tlocation /friendly_404 {\n' +
                            '\t\t\treturn 404 "Scuze"\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t\tlocation /hello {\n' +
                            '\t\t\treturn 200 "hello";\n' +
                            '\t\t}\n' +
                            '\t\t\n' +
                            '\t}\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Deci daca se va scrie http://IP//nimic:
                    <ul>
                        <li>sites/demo/nimic (relativ la root) - nu exista</li>
                        <li>sites/demo/logo.png - nu exista</li>
                        <li>sites/demo/hello - nu exista relativ la root; desi exista ca locatie</li>
                        <li>/friendly_404 - fiind ultimul argument se poate rescrie</li>
                    </ul>

                    <hr/>

                    <b>2. Numirea locatiilor</b>

                    <br/>
                    <br/>

                    Numirea locatiilor se face folosind simbolul <b>@</b>.

                    Exemplu:

                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'\t\ttry_files $uri /logo.png /hello @friendly_404\n' +
                            '\t\t\n' +
                            '\t\tlocation @friendly_404 {\n' +
                            '\t\t\treturn 404 "Scuze"\n' +
                            '\t\t}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TryFilesNamedLocationsNginxContent;