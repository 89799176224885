import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class OverviewPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-overview", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b> Generalitati</b>
                    <hr/>
                    <b>Limbaj</b>
                    <br/><br/>
                    Un limbaj (natural sau masina) este construit pe urmatoarele elemente:
                    <ul>
                        <li>
                            <b>alfabet</b>: o multime de simboluri folosite pentru a construi cuvintele unei limbi <br/>
                            <i>exemple:</i>
                            <ul>
                                <li>alfabet latin: a,d,z, etc</li>
                                <li>alfabet chiliric: Ж, И, Њ, etc</li>
                                <li>alfabet grecesc: Δ, Θ, Σ, etc</li>
                            </ul>
                        </li>
                        <li>
                            <b>lexic</b> (sau dictionar): o multimea de cuvinte a unei limbi <br/>
                            <i>exemple:</i>
                            <ul>
                                <li>casa, masina, purcel, etc: pentru limba romana</li>
                                <li>hourse, car, pig, etc: pentru limba engleza</li>
                            </ul>
                        </li>
                        <li>
                            <b>sintaxa</b> un set de reguli folosite pentru a determina daca o un sir de cuvinte formeaza o propozitie sau fraza valida<br/>
                            <i>exemple:</i>
                            <ul>
                                de exemplu, daca avem urmatoarele reguli:
                                <ul>
                                    <li>[propozitie] {"=>"} [subiect] [verb] [complement]</li>
                                    <li> [subiect] {"=>"} [articol] [substantiv]</li>
                                    <li> [complement] {"=>"} [articol] [substantiv]</li>
                                    <li> [articol] {"=>"} un | o </li>
                                    <li> [substantiv] {"=>"} arici | pruna </li>
                                    <li> [verb] {"=>"} mananca </li>
                                </ul>
                                atunci:
                                <ul>
                                    <li>un arici mananca o pruna (e valida)</li>
                                    <li>o pruna mananca un arici (e valida)</li>
                                    <li>un pruna mananca o arici (e valida)</li>
                                    <li>mananca arici o pruna (<b>nu</b> e valida)</li>
                                </ul>
                            </ul>
                        </li>
                        <li>
                            <b>semantica</b> un set de reguli folosite pentru a determina daca o fraza sau propozitie are sens<br/>
                            <i>exemple:</i>
                            <ul>
                                <li>un arici mananca o pruna (are sens)</li>
                                <li>o pruna mananca un arici (nu are sens)</li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>
                    <b>Limbaj de progrmare</b>
                    <br/><br/>
                    Un limbaj de programare este construit pe aceleasi elemente ca mai sus (alfabet, lexic, sintaxa, semantica).
                    Prin intermediul unui limbaj de programare se poate incepe un <i>dialog</i> cu un calculator.
                    Dialogul, in viata de zi cu zi, este de obicei o cerere si un raspuns, de genul:
                    <ul>
                        <li>- Cat e ceasul?</li>
                        <li>- 2</li>
                        <li>- Cum e afara?</li>
                        <li>- Ploua</li>
                    </ul>
                    Dialogul este creat din necesitatea de a vrea ceva ( de afla cat e ceasul sau cum e afara). Si cand vrei ceva, ceri.
                    <br/> <br/>
                    Folosind, un limbaj de programare, putem traduce un <b>dialog</b> intr-un <b>program</b> care sa ne furnizeze un <b>raspuns</b> / efectueze <b>actiune</b>.
                    Un program este format dintr-o lista de instructiuni.
                    <ul>
                        <li>O <b>lista de instuctiuni</b> (<b>eng. instruction list - IL</b>) este o multime de comenzi (cunoscute).</li>
                    </ul>
                    Deci, printr-un program, noi dam (sau scriem) comenzi si asteptam un raspuns.
                    <br/>  <br/>
                    Un program poate fi scris in:
                    <ul>
                        <li><b>cod masina</b>, adica intr-un un limbaj de programare de nivel jos (low-level), inteles, de obicei, doar de calculator</li>
                        <li><b>cod sursă</b>, adica intr-un limbaj de programare de nivel inalt (high-level), inteles, de obicie, doar de programator</li>
                    </ul>
                    Fișierul care conține codul sursă se numește <b>fișier sursă</b>.

                    <br/><br/>
                    Există două moduri diferite de a transforma un program dintr-un <b>limbaj de programare de nivel înalt</b> {"=>"} <b>limbaj mașină</b>:

                    <table className={"table"}>
                        <thead>
                            <tr>
                                <th>
                                    intereptare
                                </th>
                                <th>
                                    compilare
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    programul sursă este tradus <b>de fiecare dată</b> când trebuie rulat / executat

                                </td>
                                <td>
                                    programul sursă este tradus <b>o dată</b> (acest lucru trebuie repetat de fiecare dată când modificați codul sursă) si se obtine un fișier care contine <b>codul masina</b> (de exemplu, un fișier .exe)
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    codul se poate rula <b>imediat</b> ce se finalizeaza - nu există faze suplimentare de traducere
                                </td>
                                <td>
                                    <b>este posibil să nu puteți rula codul imediat</b> după efectuarea unei modificări
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    codul este <b>stocat folosind limbajul de programare</b>, <b>nu limbajul mașinii</b>
                                </td>
                                <td>
                                    codul este <b>stocat folosind limbajul mașinii</b>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <b>poate fi rulat pe computere cu diferite limbaje de mașină</b>;
                                    deci nu trebuie compilat codul separat pentru fiecare arhitectură diferită
                                </td>
                                <td>
                                    pentru <b>fiecare platforma hardware este nevoie de un compilator</b> pe care sa fie rulat codul
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    programul care efectuează acest tip de transformare se numește <b>interpretor</b>
                                </td>
                                <td>
                                    programul care realizează această traducere se numește <b>compilator / traducător</b>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    nu se poate distribui doar codul sursă; este <b>nevoie de interpretor</b> pentru a-l executa
                                </td>
                                <td>
                                    se poate distribui doar codul masina (exemplu, .exe); <b>nu este nevoie de compilator</b> pentru a-l executa
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    interpretarea este mai <b>lenta</b> decat compilarea
                                </td>
                                <td>
                                    compilarea este mai <b>rapida</b> decat interpretarea
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    -
                                </td>
                                <td>
                                    compilarea poate fi un proces <b>consumator de timp</b>
                                    <br/>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    -
                                </td>
                                <td>
                                    pentru ca limbajul mașinii este foarte greu de descifrat rezulta că <b>propriile invenții și trucuri de programare probabil rămân secrete</b>
                                </td>
                            </tr>


                        </tbody>


                    </table>

                    {/*<ul>*/}
                    {/*    <li>intereptare*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                traduce programul sursă <b>de fiecare dată</b> când trebuie rulat / executat*/}
                    {/*                <br/>*/}
                    {/*                codul se poate rula <b>imediat</b> ce se finalizeaza - nu există faze suplimentare de traducere*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                codul este <b>stocat folosind limbajul de programare</b>, <b>nu limbajul mașinii</b> -*/}
                    {/*                asta înseamnă că <b>poate fi rulat pe computere cu diferite limbaje de mașină</b>;*/}
                    {/*                deci nu trebuie compilat codul separat pentru fiecare arhitectură diferită*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                programul care efectuează acest tip de transformare se numește <b>interpretor</b>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                nu se poate distribui doar codul sursă așa cum este, este <b>nevoie de interpretor pentru a-l executa</b>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                interpretarea este mai <b>lenta</b> decat compilarea*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </li>*/}
                    {/*    <li>compilare*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                programul sursă este tradus <b>o dată</b> (acest lucru trebuie repetat de fiecare dată când modificați codul sursă) prin obținerea unui fișier care contine cod masina (de exemplu, un fișier .exe)*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                codul este <b>convertit direct in cod masina</b> executat de procesor (sau o masina virtuala)*/}
                    {/*                <br/>*/}
                    {/*                altfel spus, codul este <b>stocat folosind limbajul mașinii</b>*/}
                    {/*                <br/>*/}
                    {/*                pentru ca limbajul mașinii este foarte greu de descifrat rezulta că <b>propriile invenții și trucuri de programare probabil rămân secrete</b>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                programul care realizează această traducere se numește <b>compilator sau traducător</b>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                doar utilizatorul care compileaza trebuie să aibă compilatorul - utilizatorul final poate folosi codul fără compilator*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                compilarea este mai <b>rapida</b> decat interpretarea*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                compilarea poate fi un proces <b>consumator de timp</b> - este posibil să nu puteți rula codul imediat după efectuarea unei modificări*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                pentru fiecare platforma hardware este nevoie de un compilator pe care sa fie rulat codul*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}

                    <hr/>
                    <b>Limbajul de programare Python</b>
                    <br/> <br/>
                    Numele limbajului de programare Python provine dintr-un vechi serial de schițe de comedie de televiziune de la BBC numit <b>Circul zburător al lui Monty Python</b>. Acest limbaj a fost creat de <b>Guido van Rossum</b>, născut în 1956 în Haarlem (Olanda):

                    <br/>
                    <i>În decembrie 1989, căutam un proiect de programare „hobby” care să mă țină ocupat în timpul săptămânii din preajma Crăciunului</i> - Guido van Rossum
                    <br/>
                    <br/>
                    Versiuni ale Pyhton
                    <ul>
                        <li><b>CPython</b> : implemantare (clasica) Python in C</li>
                        <li><b>Cython</b>: traduce cod Python in C</li>
                        <li><b>Jython</b> : implemantare Python in Java</li>
                        <li><b>PyPy</b> : mediu Python scris într-un limbaj asemănător Python numit <b>RPython (Restricted Python)</b> (codul sursă al lui PyPy nu este rulat în modul de interpretare, ci este tradus în limbajul de programare C și apoi executat separat)</li>
                    </ul>

                    Deci, Python este:
                    <ul>
                        <li>un <b> limbaj de nivel inalt</b></li>
                        <li>este <b>gratis, open-source, multiplatforma</b></li>
                        <li>interpretat prin intermediul unui <b>interpretor</b> (un program care executa direct intructiuni scrise intr-un limbaj de programare)</li>
                        <li>poate fi folosit pentru crearea si executarea de teste pentru aplicatii</li>
                    </ul>

                    Python, poate fi folosit in tot felul de proiecte, mai putin:
                    <ul>
                        <li>aplicatii pe device-uri mobile</li>
                        <li>low-level programming (<i>close to metal</i>): de exemplu daca vrei sa implementezi un driver</li>
                    </ul>

                    Dictionar:
                    <ul>
                        <li><b>script</b> = fisier text care contine instructiunie care reprezinta un program Python</li>
                        <li><b>consola</b> = un interpretor in linie de comanda care interactioneaza cu Sistemul de Operare si executa comenzi Python si scripturi </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OverviewPythonContent;