import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class AngularJsonAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-angular-json", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Despre angular.json</b>
                    <br/>
                    <br/>

                    Exemplu fragment din <i>angular.json</i>
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'"projects": {\n' +
                            '    "lapretbun": {\n' +
                            '      "projectType": "application",\n' +
                            '      "schematics": {\n' +
                            '        "@schematics/angular:component": {\n' +
                            '          "style": "scss"\n' +
                            '        },\n' +
                            '        "@schematics/angular:application": {\n' +
                            '          "strict": true\n' +
                            '        }\n' +
                            '      },\n' +
                            '      "root": "",\n' +
                            '      "sourceRoot": "src/main/webapp",\n' +
                            '      "prefix": "jhi",'}
                    </SyntaxHighlighter>

                    <ul>
                        <li>
                            pot exista mai multe proiecte/aplicatii in sectiunea de <b>projects</b>, in cazul de mai sus exista unul, cu numele <i>lapretbun</i>;
                            se poate genera un nou proiect cu:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng generate application nume-proiect'}
                            </SyntaxHighlighter>
                            (pot exista mai multe proiecte/aplicatii intr-un singur director);
                            <br/>
                            apoi se poate porni:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng serve --proiect=nume-proiect'}
                            </SyntaxHighlighter>
                            Avand mai multe proiecte, se poate specifica care este cel implicit/default:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'"defaultProject":"nume-proiect"'}
                            </SyntaxHighlighter>
                            <hr/>
                            Daca exista un proiect, noul proiect se va genera in <b>projects/nume-nou-proiect</b>; o structura destul de urata.
                            <br/>
                            Dar pentru a creea o structura mai ok, se poate face in felul urmator:
                            <ul>
                                <li>
                                    se creaza un proiect, fara aplicatie:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'ng new project-root --create-application=false'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    se genereaza prima aplicatie/proiect:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'ng generate application backend'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    se genereaza a doua aplicatie/proiect:
                                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                        {'ng generate application frontend'}
                                    </SyntaxHighlighter>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>projectType</b> poate fi:
                            <ul>
                                <li>
                                    application
                                </li>
                                <li>
                                    library
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>sourceRoot</b>: specifica calea catre sursele proiectelui; implicit este <i>src</i>
                        </li>
                        <li>
                            <b>prefix</b>: specifica un prefix pentru componentele din proiect; implicit este <i>app</i>
                        </li>
                        <li>
                            <b>arhitect</b>: se specifica tool-uri pentru: build, serve, test cu diversele lor setari/optiuni;
                            <br/>
                            Fragment pentru build:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'"build": {\n' +
                                    '          "builder": "@angular-builders/custom-webpack:browser",\n' +
                                    '          "options": {\n' +
                                    '            "customWebpackConfig": {\n' +
                                    '              "path": "./webpack/webpack.custom.js"\n' +
                                    '            },\n' +
                                    '            "outputPath": "target/classes/static/",// <-- de obicei este: dist/nume-proiect\n' +
                                    '            "index": "src/main/webapp/index.html", // <-- de obicei este: src/index.html\n' +
                                    '            "main": "src/main/webapp/main.ts",// <-- de obicei este: src/main.ts\n' +
                                    '            "polyfills": "src/main/webapp/polyfills.ts"// <-- de obicei este: src/polyfills.ts,\n' +
                                    '            "tsConfig": "tsconfig.app.json",\n' +
                                    '            "inlineStyleLanguage": "scss",\n' +
                                    '            "assets": [\n' +
                                    '              "src/main/webapp/content",\n' +
                                    '              "src/main/webapp/favicon.ico",\n' +
                                    '              "src/main/webapp/manifest.webapp",\n' +
                                    '              "src/main/webapp/robots.txt"\n' +
                                    '            ],\n' +
                                    '            "styles": ["src/main/webapp/content/scss/vendor.scss", "src/main/webapp/content/scss/global.scss"],\n' +
                                    '            "scripts": []\n' +
                                    '          },'}
                            </SyntaxHighlighter>

                            Exemplu pentru serve:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {' "serve": {\n' +
                                    '          "builder": "@angular-builders/custom-webpack:dev-server",\n' +
                                    '          "options": {\n' +
                                    '            "browserTarget": "lapretbun:build:development",\n' +
                                    '            "port": 4200\n' +
                                    '          },\n' +
                                    '          "configurations": {\n' +
                                    '            "production": {\n' +
                                    '              "browserTarget": "lapretbun:build:production"\n' +
                                    '            },\n' +
                                    '            "development": {\n' +
                                    '              "browserTarget": "lapretbun:build:development"\n' +
                                    '            }\n' +
                                    '          },\n' +
                                    '          "defaultConfiguration": "development"\n' +
                                    '        },'}
                            </SyntaxHighlighter>

                            Exemplu pentru test:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {' "test": {\n' +
                                    '          "builder": "@angular-builders/jest:run",\n' +
                                    '          "options": {\n' +
                                    '            "configPath": "jest.conf.js"\n' +
                                    '          }\n' +
                                    '        }'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            se poate specifica ca un anumit fisier sa fie inlocuit cu altul de exemplu, pentru prod:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'"configurations": {\n' +
                                    '  "development": { … },\n' +
                                    '  "production": {\n' +
                                    '    "fileReplacements": [\n' +
                                    '      {\n' +
                                    '        "replace": "src/environments/environment.ts",\n' +
                                    '        "with": "src/environments/environment.staging.ts"\n' +
                                    '      }\n' +
                                    '    ]\n' +
                                    '  }\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            si apoi se poate face build cu o anumita configuratie, de exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                                {'ng build --configuration=production'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AngularJsonAngularContent;