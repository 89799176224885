import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LiferayFormsDDMStorageAdapterLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-liferay-forms-ddm-storage-adapter", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Scrierea unui adaptor de stocare a formularelor</b>
                    <br/>
                    <br/>

                    Un <b>DDMStorageAdapter</b> este un serviciu care furnizeza logica pentru stocarea intrărilor de formular.
                    <br/>

                    Proprietatea <b>ddm.storage.adapter.type</b> ofera un identificator unic de serviciu inregistrat pentru a putea fi referit din alte servicii:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference(target = "(ddm.storage.adapter.type=ibrid-ddm-storage-adapter)")\n' +
                            'private DDMStorageAdapter ibridDDMStorageAdapter;'}
                    </SyntaxHighlighter>

                    Identificatorul <b>ibrid-ddm-storage-adapter</b> reprezinta si o cheie.
                    Aceasta cheie este stocata in fisierul de proprietati <i>src/main/resources/content/Language.properties</i> cu valoare:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-ddm-storage-adapter=Ibrid Dynamic Data Mapping Storage Adapter'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                            '\t\tproperty = "ddm.storage.adapter.type=ibrid-ddm-storage-adapter",\n' +
                            '\t\tservice = DDMStorageAdapter.class\n' +
                            ')\n' +
                            'public class IbridDDMStorageAdapter implements DDMStorageAdapter {...}'}
                    </SyntaxHighlighter>

                    Interfața <b>DDMStorageAdapter</b> necesită trei metode pentru a gestiona operațiunile CRUD pe înregistrările de formular:
                    <ul>
                        <li>
                            delete
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public DDMStorageAdapterDeleteResponse delete(\n' +
                                    '        DDMStorageAdapterDeleteRequest ddmStorageAdapterDeleteRequest)\n' +
                                    '    throws StorageException;'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            get
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public DDMStorageAdapterGetResponse get(\n' +
                                    '        DDMStorageAdapterGetRequest ddmStorageAdapterGetRequest)\n' +
                                    '    throws StorageException;'}
                            </SyntaxHighlighter>
                        </li>
                        <b>
                            save
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'public DDMStorageAdapterSaveResponse save(\n' +
                                    '        DDMStorageAdapterSaveRequest ddmStorageAdapterSaveRequest)\n' +
                                    '    throws StorageException;'}
                            </SyntaxHighlighter>
                        </b>
                    </ul>

                    În mod implicit, formularele sunt stocate ca JSON în baza de date Liferay.

                    <hr/>
                    <b>2. Exemplu (stocare in fisiere)</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>2.1.  Declarare dependinte de servicii</b>
                    <br/>
                    <br/>

                    Declarare dependinte de servicii (stocarea in fisiere va folosi două servicii implementate de Liferay într-un container OSGi):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Reference\n' +
                            'private DDMContentLocalService _ddmContentLocalService;\n' +
                            '\n' +
                            '@Reference\n' +
                            'private DDMFormValuesSerializerTracker _ddmFormValuesSerializerTracker;'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.2. Creare logger</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private static final Log _log = LogFactoryUtil.getLog(IbridDDMStorageAdapter.class);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.3. Implementați metoda delete() / ștergerea fișier</b>
                    <br/>
                    <br/>

                    Calea unde sunt tinute fisierele:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private static final String _PATHNAME = "/opt/liferay/form-records";'}
                    </SyntaxHighlighter>

                    Metoda de stergere fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private void _deleteFile(long fileId) {\n' +
                            '    File file = new File(_PATHNAME + "/" + fileId);\n' +
                            '\n' +
                            '    file.delete();\n' +
                            '\n' +
                            '    if (_log.isWarnEnabled()) {\n' +
                            '     _log.warn("Deleted file with the ID " + fileId);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'long fileId = ddmStorageAdapterDeleteRequest.getPrimaryKey();\n' +
                            '\n' +
                            ' _deleteFile(fileId);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.4. Implementați metoda get() fișier</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private void _getFile(long fileId) throws IOException {\n' +
                            '\t\ttry {\n' +
                            '\t\t\tif (_log.isWarnEnabled()) {\n' +
                            '\t\t\t\t_log.warn(\n' +
                            '\t\t\t\t\t"Reading the file with the ID " + fileId + ": " +\n' +
                            '\t\t\t\t\t\tFileUtil.read(_PATHNAME + "/" + fileId));\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t\tcatch (IOException e) {\n' +
                            '\t\t\tthrow new IOException(e);\n' +
                            '\t\t}\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'long fileId = ddmStorageAdapterGetRequest.getPrimaryKey();\n' +
                            '\n' +
                            '_getFile(fileId);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.5. Implementați metoda save() fișier</b>
                    <br/>
                    <br/>

                    Actiunea de salvare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private void _saveFile(long fileId, DDMFormValues formValues)\n' +
                            '    throws IOException {\n' +
                            '\n' +
                            '    try {\n' +
                            '        String serializedDDMFormValues = _serialize(formValues);\n' +
                            '\n' +
                            '        File abstractFile = new File(String.valueOf(fileId));\n' +
                            '\n' +
                            '        FileUtil.write(\n' +
                            '            _PATHNAME, abstractFile.getName(), serializedDDMFormValues);\n' +
                            '\n' +
                            '        if (_log.isWarnEnabled()) {\n' +
                            '            _log.warn("Saved a file with the ID" + fileId);\n' +
                            '        }\n' +
                            '    }\n' +
                            '    catch (IOException e) {\n' +
                            '        throw new IOException(e);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Conversie obiectul de tip <b>DDMFormValues</b> (com.liferay.dynamic.data.mapping.storage.DDMFormValues) in JSON:
                    <SyntaxHighlighter>
                        {'private String _serialize(DDMFormValues ddmFormValues) {\n' +
                            '\t\tDDMFormValuesSerializer ddmFormValuesSerializer =\n' +
                            '\t\t\t_ddmFormValuesSerializerTracker.getDDMFormValuesSerializer("json");\n' +
                            '\n' +
                            '\t\tDDMFormValuesSerializerSerializeRequest.Builder builder =\n' +
                            '\t\t\tDDMFormValuesSerializerSerializeRequest.Builder.newBuilder(\n' +
                            '\t\t\t\tddmFormValues);\n' +
                            '\n' +
                            '\t\tDDMFormValuesSerializerSerializeResponse\n' +
                            '\t\t\tddmFormValuesSerializerSerializeResponse =\n' +
                            '\t\t\t\tddmFormValuesSerializer.serialize(builder.build());\n' +
                            '\n' +
                            '\t\treturn ddmFormValuesSerializerSerializeResponse.getContent();\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    Metoda save():
                    <SyntaxHighlighter>
                        {'DDMStorageAdapterSaveResponse defaultStorageAdapterSaveResponse =\n' +
                            '     _defaultStorageAdapter.save(ddmStorageAdapterSaveRequest);\n' +
                            '\n' +
                            ' long fileId = defaultStorageAdapterSaveResponse.getPrimaryKey();\n' +
                            '\n' +
                            ' _saveFile(fileId, ddmStorageAdapterSaveRequest.getDDMFormValues());\n' +
                            '\n' +
                            ' return defaultStorageAdapterSaveResponse;'}
                    </SyntaxHighlighter>

                    <hr/>
                    Prin implementarea unui DDMStorageAdapter, puteți salva înregistrările formularelor în orice format de stocare doriți.

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/w/dxp/process-automation/forms/developer-guide/writing-a-form-storage-adapter"}>
                                    Writing a Form Storage Adapter
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LiferayFormsDDMStorageAdapterLiferayContent;