import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PrimitiveTypesJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-primitive-types", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <table>
                        <thead>
                            <tr>
                                <th>Cuvant cheie</th>
                                <th>Tip</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>boolean</td>
                                <td>true/false</td>
                            </tr>

                            <tr>
                                <td>byte</td>
                                <td>8-biti [-128, 127]</td>
                            </tr>
                            <tr>
                                <td>short</td>
                                <td>16-biti [-32.768, 32,767]</td>
                            </tr>
                            <tr>
                                <td>int</td>
                                <td>32-biti</td>
                            </tr>

                            <tr>
                                <td>long</td>
                                <td>64-biti</td>
                            </tr>

                            <tr>
                                <td>float</td>
                                <td>32-biti</td>
                            </tr>

                            <tr>
                                <td>double</td>
                                <td>64-biti</td>
                            </tr>

                            <tr>
                                <td>char</td>
                                <td>16-biti [0, 65.535]</td>
                            </tr>

                        </tbody>
                    </table>

                    Un <b>numar intreg</b> scris in cod este un <b>literal</b>. In mod implicit, Java presupune ca s-a definit o valoare de tip <b>int</b>.
                    <br/>
                    Deci, daca se va scrie 3123456789, atunci vom obtine o eroare de compilare, pentru ca acest numar nu poate fi stocat intr-un tip <b>int</b>.
                    <br/>
                    Pentru a specifica ca vreti sa stocati un numar intr-un <b>long</b> atunci trebuie adaugat la sfarsitul numarului:
                    <ul>
                        <li><b>L</b>: 12L sau 3123456789L</li>
                        <li><b>l</b>: 12l sau 3123456789l</li>
                    </ul>

                    Un <b>numar zecimal</b> scris in cod este <b>literal</b>. In mod implicit, Java presupune ca s-a definit o valoare de tip <b>double</b>.

                    <br/>
                    Pentru a specifica ca vreti sa stocati un numar zecimal intr-un <b>float</b> atunci trebuie adaugat la sfarsitul numarului:
                    <ul>
                        <li><b>F</b>: 12.1F, 4F sau 3123456789F</li>
                        <li><b>f</b>: 12.1f, 4f sau 3123456789f</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'float a=1.3; // e incorect: error: incompatible types: possible lossy conversion from double to float; \n // 1.3 e interpretat ca fiind double, iar double e prea mare pentru float'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'float a=1; // e corect: un int incape intr-un float'}
                    </SyntaxHighlighter>

                    <hr/>

                    Java permite specificare unui numar in diferite baze:
                    <ul>
                        <li><b>octal</b> (se folosesc doar cifrele 0-7):<br/>
                            <b>se pune prefixul 0</b> (exemplu: 017)
                        </li>
                        <li><b>hexazecimal</b> (se folosesc cifrele 0-9 si literele A-F / a-f):<br/>
                            <b>se pune prefixul 0x</b> (exemplu: 0X17, 0x18, 0XFf)
                        </li>
                        <li><b>binar</b> (se folosesc doar cifrele 0 si 1):<br/>
                            <b>se pune prefixul 0b</b> (exemplu: 0b10, 0B01)
                        </li>
                    </ul>
                    Pentru lizibilitate se poate folosi <b>_</b> (liniuta de subliniere / underscore), cu urmatoarele reguli:
                    <ul>
                        <li>nu trebuie sa apara la inceputul numarului (exemplu invalid: _1.0)</li>
                        <li>nu trebuie sa apara la sfarsitul numarului (exemplu invalid: 1.0_)</li>
                        <li>nu trebuie sa apara fix inainte de punct (exemplu invalid: 1_.0)</li>
                        <li>nu trebuie sa apara imediat dupa punct (exemplu invalid: 1._0)</li>
                    </ul>
                    Exemple valid: 1______2, 1_00.900_00
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PrimitiveTypesJavaContent;