import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class GroovyGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-groovy", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Bau</b>
                    <br/>
                    <br/>

                    Cream packetul <i>ro.ibedaria.groovy.vanilla</i> si aici fisierul <i>App.groovy</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'package ro.ibedaria.groovy.vanilla;\n' +
                        '\n' +
                        'System.out.println("Bau");'}
                    </SyntaxHighlighter>
                    Si acum sa simplificam:
                    <ul>
                        <li>se poate renunta la ';'</li>
                        <li>se poate renunta la parantezele necesare in apelul unei functii '(' si ')'</li>
                        <li><i>System.out</i> este importat static implicit</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'package ro.ibedaria.groovy.vanilla\n' +
                        '\n' +
                        'println "Bau"'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Tipuri de date</b>
                    <br/>
                    <br/>
                    O variabila este definita folosind cuvantul cheie <b>def</b> (similar cu <i>var</i>):

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def a = 1\n' +
                        'println a'}
                    </SyntaxHighlighter>

                    Pentru a afisa tipul unei variabile se foloseste metoda <b>class</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def a = 1\n' +
                        'println a\n' +
                        'println a.class // class java.lang.Integer'}
                    </SyntaxHighlighter>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def t1 = 1.2\n' +
                        'float t2 = 2.3\n' +
                        'def t3 = 1.2f\n' +
                        'def t4 = 1.2d\n' +
                        'def t5 = "A"\n' +
                        '\n' +
                        'println t1.class // class java.math.BigDecimal\n' +
                        'println t2.class // class java.lang.Float\n' +
                        'println t3.class // class java.lang.Float\n' +
                        'println t4.class // class java.lang.Double\n' +
                        'println t5.class // class java.lang.String'}
                    </SyntaxHighlighter>

                    Definirea unei variabile se poate face si fara a specifica tipul sau <b>def</b>.
                    <br/>
                    De asemenea, exista 2 tipuri de a defini un sir de caractere:
                    <ul>
                        <li>folosind '</li>
                        <li>folosind " : in acest caz se pot folosi <i>placehodere</i> (si putem folosi nume de variabile)</li>
                        <li>folosind """: pentru a definii un sir de caractere pe mai multe linii</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'a=\'a\'\n' +
                        'b="a"\n' +
                        'println a.class // class java.lang.String\n' +
                        'println b.class // class java.lang.String\n' +
                        '\n' +
                        'c = \'kj\'\n' +
                        'println "hei ${c}"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Expresii regulate</b>
                    <br/>
                    <br/>

                    O expresie regulata este scrisa intre '/' si '/', iar pentru a testa ca un sir de caractere respecta regula scrisa prin intermediul expresiei regulate se foloseste operator <b>==~</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'numberPatern = /[0-9]*/\n' +
                        'println "123"==~ numberPatern // true\n' +
                        'println "123a"==~ numberPatern // false'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Closures / Functii anonime</b>
                    <br/>
                    <br/>

                    Putem defini o functie anonima similar cu exemplul de mai jos:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'x={\n' +
                        '    println "bau"\n' +
                        '}\n' +
                        '\n' +
                        'x.call()'}
                    </SyntaxHighlighter>

                    Pentru a trimite un parametru:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'x={ n->\n' +
                        '    println "bau ${n}"\n' +
                        '}\n' +
                        '\n' +
                        'x.call(\'kj\')'}
                    </SyntaxHighlighter>

                    Daca nu se trimite parametrul se va aruca exceptie; dar se poate definii o valoare <b>default</b>  (implicita) pentru parametru:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'x={ n=\'KJ\'->\n' +
                        '    println "bau ${n}"\n' +
                        '}\n' +
                        '\n' +
                        'x.call()'}
                    </SyntaxHighlighter>

                    Exemple:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'3.times {x->print x}\n' +
                        '3.times {print it}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Colectii</b>
                    <br/>
                    <br/>

                    Pentru a defini o colectie se folosesc parantelezele patrate: []
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'l1 = [1,2,3]\n' +
                        'LinkedList l2 = [1,2,3]\n' +
                        '\n' +
                        'println l1.class // class java.util.ArrayList\n' +
                        'println l2.class // class java.util.LinkedList'}
                    </SyntaxHighlighter>

                    Operatii:
                    <ul>
                        <li>adaugare element: l &lt;&lt; 3 </li>
                        <li>concatenare liste: l1 + l2 / l1 + [3,4,4]</li>
                        <li>stergere elemente din lista: l1 - [1,2]</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'a = [1,2,3]\n' +
                        'b = [4,5,6]\n' +
                        'c = [2,3]\n' +
                        'println a+b // [1, 2, 3, 4, 5, 6]\n' +
                        'println a-c // [1]\n' +
                        'println a << 10 // [1, 2, 3, 10]'}
                    </SyntaxHighlighter>

                    Iterarea printr-o lista se poate efectua cu ajutorul metodei <b>each()</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'c.each {print it} // 2,3'}
                    </SyntaxHighlighter>

                    Pentru a itera elemente in ordine inversa, se foloseste metoda <b>reverseEach()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'c.reverseEach {print it+","} // 3,2'}
                    </SyntaxHighlighter>
                    Pentru a itera prin toate permutarile colectiei, se foloseste metoda <b>eachPermutation</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'c.eachPermutation {print it+","}'}
                    </SyntaxHighlighter>

                    Alt exemplu (in procesul de interpolarea unui sir de caractere in sectiunea definita de $ se pot defini nu doar variabile, dar si expresii sau apeluri de metode ):
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'a = [1,2,3]\n' +
                        'println "a = ${a+1}" // a = [1, 2, 3, 1]'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Multimi (set) si mape</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'// crearea multime\n' +
                        'set = [\'a\',\'b\',\'c\',\'a\'] as Set\n' +
                        'println set\n' +
                        'println set.class // class java.util.LinkedHashSet\n' +
                        '\n' +
                        '// creare mapa\n' +
                        'map = [romania:\'bucuresti\', franta:\'paris\']\n' +
                        'println map\n' +
                        '\n' +
                        '// parcurgere map\n' +
                        'map.each {k,v->println k+" = "+v}\n' +
                        '\n' +
                        '// accesare element din map\n' +
                        'println map.romania\n' +
                        'println map.get(\'romania\')\n' +
                        'println map[\'romania\']\n' +
                        '\n' +
                        '// adauga noua valoare\n' +
                        'map.italia=\'roma\''}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Metode</b>
                    <br/>
                    <br/>

                    Pornim de la definirea urmatoarei metode:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'int add(int x, int y){\n' +
                        '    return x+y\n' +
                        '}\n' +
                        '\n' +
                        'println add(1,2)'}
                    </SyntaxHighlighter>
                    Metoda de mai sus, se poate scrie, simplificat si in urmatorul mod:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def add(x,y){\n' +
                        '    x+y\n' +
                        '}\n' +
                        '\n' +
                        'println add(1,2)'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def add(x,y){\n' +
                        '    x+y\n' +
                        '}\n' +
                        '\n' +
                        'r = add 1,2\n' +
                        'println r'}
                    </SyntaxHighlighter>
                    De observat:
                    <ul>
                        <li>nu este nevoie de cuvantul cheie <b>return</b>; se returneaza ultima expresie dintr-o metoda </li>
                    </ul>

                    Se pot seta valori implicite pentru parametrii unei metode:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def add(x=3,y){\n' +
                        '    x+y\n' +
                        '}\n' +
                        '\n' +
                        'r = add 5\n' +
                        'println r // 8'}
                    </SyntaxHighlighter>

                    Se pot definii metode cu numar variabil de parametrii, folosind <b>Map</b>:gra
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'def metoda(Map p){\n' +
                        '    println p.x\n' +
                        '    println p.y\n' +
                        '}\n' +
                        '\n' +
                        'metoda([x:1,y:2])\n' +
                        'metoda(x:4,y:3)\n' +
                        'metoda x:7,y:8'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>8. Clase si obiecte</b>
                    <br/>
                    <br/>

                    Intern, pentru fiecare camp, Groovy genereaza set-eri si get-eri si constructor care permite initializarea campurilor:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'class A{\n' +
                        '    def x,y\n' +
                        '}\n' +
                        '\n' +
                        'a = new A()\n' +
                        'b = new A(x:1, y:2)\n' +
                        '\n' +
                        'println b.x + " " + b.y'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GroovyGradleContent;