import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class NextjsAppContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-create-app", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Creare aplicatie Next.js</b>
                    <br/>
                    <br/>

                    Se creaza o aplicatie numita <i>frontend</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npx create-next-app@latest frontend --use-npm --example "https://github.com/vercel/next-learn/tree/master/basics/learn-starter"'}
                    </SyntaxHighlighter>

                    Pornire:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'cd frontend\n' +
                            'npm run dev'}
                    </SyntaxHighlighter>
                    Din pacate, fata de react, nu porneste pe alt port, daca portul 3000 este ocupat de alta aplicatie.
                    <br/>
                    Pentru a schimba portul exista 2 posibilitati:
                    <ul>
                        <li>
                            schimbare port in <i>package.json</i>:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'"scripts": {\n' +
                                    '  "dev": "next -p 3002",\n' +
                                    '  "build": "next build",\n' +
                                    '  "start": "next start -p 3002",\n' +
                                    '},'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            varianta recomanda, la pornirea serverului, prin specificarea portului:
                            <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                {'npm run dev -- -p 3002'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NextjsAppContent;