import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../text-to-speech/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class Pyttsx3Ri5TtsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tts-pyttsx3-pi5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. pyttsx3 - Python cu PI 5</b>
                    <br/>
                    <br/>

                    Vezi: espeak si espeak-ng - Raspberry Pi 5, pentru a intelege <i>mb-ro1</i>!

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'\n' +
                            'Iulian-Daniel Buzdugan <buzdugan.iulian@gmail.com>\n' +
                            'Sat, 10 Feb, 23:40 (21 minutes ago)\n' +
                            'to me\n' +
                            '\n' +
                            '# 1. se instaleaza librarie python\n' +
                            '# pip install pyttsx3  --break-system-packages\n' +
                            '\n' +
                            'import pyttsx3\n' +
                            'engine = pyttsx3.init()\n' +
                            '#engine.setProperty(\'voice\', \'ro\')\n' +
                            'engine.setProperty(\'voice\', \'mb-ro1\')\n' +
                            '# setez viteza de vorbire\n' +
                            'engine.setProperty(\'rate\', 100)\n' +
                            '\n' +
                            'engine.say("Tranzistoare, tranzistoare ce ai facut la rezistoare?")\n' +
                            'engine.runAndWait()'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Pyttsx3Ri5TtsContent;