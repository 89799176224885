import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"laravel-root", url:"#", title:"Laravel", subtitle:""},

        {id:"laravel-login", url:"/laravel/login", title:"Login", subtitle:"", parent:"laravel-root"},
        {id:"laravel-logout", url:"/laravel/logout", title:"Logout", subtitle:"", parent:"laravel-root"},
        {id:"laravel-scopes", url:"/laravel/scopes", title:"Scopuri", subtitle:"", parent:"laravel-root"},
        {id:"laravel-transactions", url:"/laravel/transactions", title:"Trazactii", subtitle:"", parent:"laravel-root"},
        {id:"laravel-email-verification-auth", url:"/laravel/email-verification-auth", title:"Autentificare: Email Verification", subtitle:"", parent:"laravel-root"},

        {id:"laravel-gen-root", url:"#", title:"Comenzi de generare", subtitle:""},
        {id:"laravel-migration", url:"/laravel/migration", title:"php artisan make:migration & php artisan migrate", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-model", url:"/laravel/model", title:"php artisan make:model", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-controller", url:"/laravel/controller", title:"php artisan make:controller", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-seeder", url:"/laravel/seeder", title:"Generare date fake: php artisan make:factory & php artisan make:seeder & php artisan db:seed", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-resources", url:"/laravel/resources", title:"Generare clasa transformare date: php artisan make:resource", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-events", url:"/laravel/events", title:"Generare clasa de evenimente/ascultator: php artisan make:event & php artisan make:listener", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-command", url:"/laravel/command", title:"Generare clasa de tip comanda: php artisan make:command", subtitle:"", parent:"laravel-gen-root"},
        {id:"laravel-ide-models", url:"/laravel/ide-models", title:"IDE Helper Generator: php artisan ide:models", subtitle:"", parent:"laravel-gen-root"}
    ]

    static indexUrl = "/laravel/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Laravel
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;