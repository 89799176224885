import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IfPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-if-statement", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>  11. Python: Instructiunea if/else</b>

                    <br/>
                    <br/>
                    Instructiunea <b>if</b> poate avea forma:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if conditie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn'}
                    </SyntaxHighlighter>
                    sau (daca exista o singura instructiune; regula valabila si pentru formele de mai jos):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if conditie:instructiune'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if conditie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn\n' +
                        'else:\n' +
                        '    instructiune_F1\n' +
                        '    instructiune_F2\n' +
                        '    ...\n' +
                        '    instructiune_Fn'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'if conditie:\n' +
                        '    instructiune_T1\n' +
                        '    instructiune_T2\n' +
                        '    ...\n' +
                        '    instructiune_Tn\n' +
                        'elif conditie_2\n' +
                        '    instructiune_C2_1\n' +
                        '    instructiune_C2_2\n' +
                        '    ...\n' +
                        '    instructiune_C2_n\n' +
                        'elif conditie_3\n' +
                        '    instructiune_C3_1\n' +
                        '    instructiune_C3_2\n' +
                        '    ...\n' +
                        '    instructiune_C3_n\n' +
                        '...    \n' +
                        'else:\n' +
                        '    instructiune_F1\n' +
                        '    instructiune_F2\n' +
                        '    ...\n' +
                        '    instructiune_Fn'}
                    </SyntaxHighlighter>

                    unde <b>conditie</b> este o expresie logica evaluata:
                    <ul>
                        <li>True (daca este <b>True sau valoarea este diferita de 0</b>)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'if True:\n' +
                                '   print("1")\n' +
                                '   \n' +
                                'if 1:\n' +
                                '   print("2")\n' +
                                '   \n' +
                                'if 2:\n' +
                                '   print("3")'}
                            </SyntaxHighlighter>
                            va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'1\n' +
                                '2\n' +
                                '3'}
                            </SyntaxHighlighter>
                        </li>
                        <li>False (daca este <b>False sau valoarea este 0</b>)
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'if False:\n' +
                                '   print("1")\n' +
                                'else:\n' +
                                '   print("1 F")    \n' +
                                '   \n' +
                                'if 0:\n' +
                                '   print("2")\n' +
                                'else:\n' +
                                '   print("2 F") '}
                            </SyntaxHighlighter>
                            va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'1 F\n' +
                                '2 F'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <b>Observatii / Reguli</b>
                    <ul>
                        <li>trebuie ca ":" sa fie urmat de o linie noua
                            <br/>
                            exista totusi o exceptie, daca urmeaza o singura instructiune, asa cum am vazut mai sus:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'if True:print("A")'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'if False:print("A")\n' +
                                'else: print("B")'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'if False:print("A")\n' +
                                'elif True: print("B")'}
                            </SyntaxHighlighter>

                        </li>
                        <li>trebuie sa existe cel putin o instructiune</li>
                        <li>trebuie sa se pastreze aceeasi identare pentru toate instructiunile (recomandare: 4 spatii sau un tab)</li>
                        <li>trebuie ca <b>else</b> sa fie ultimul daca exista <b>elif</b></li>
                        <li><b>else</b> poate fi <b>optional</b></li>
                        <li>daca exista <b>elif</b>-uri, se opreste la primul care indeplineste conditia
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'x=100\n' +
                                'if (x>100):\n' +
                                '    print(x)\n' +
                                'elif x>10:\n' +
                                '    print("1")\n' +
                                'elif x>20:\n' +
                                '    print("2")'}
                            </SyntaxHighlighter>
                            va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'1'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IfPythonContent;