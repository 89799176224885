import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class VirtualHostsTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-virtualhosts", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare virtual host-uri</b>
                    <br/>
                    <br/>
                    In [tomcat]<b>/webapps</b> (sau oriunde) se creaza urmatoarele 3 directoare:
                    <ul>
                        <li>kj1-webapps</li>
                        <li>kj2-webapps</li>
                        <li>kj3-webapps</li>
                    </ul>

                    In fiecare director se va creea un alt director:
                    <ul>
                        <li>ROOT</li>
                    </ul>
                    In directorul <b>ROOT</b> se pune aplicatia.
                    <br/>
                    In cazul nostru, un fisier html <i>index.html</i> cu urmatorul continut, adaptat pentru fiecare site:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<html>\n' +
                            '<body>\n' +
                            'kj1\n' +
                            '</body>\n' +
                            '</html>'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>2. Configurare virtual host-uri in server.xml</b>
                    <br/>
                    <br/>
                    La acest pas se mapeaza folderele create mai sus cu domeniul:
                    <ul>
                        <li>kj1-webapps {"->"} www.kj1.ro</li>
                        <li>kj2-webapps {"->"} www.kj2.ro</li>
                        <li>kj3-webapps {"->"} www.kj3.ro</li>
                    </ul>

                    Se modifica <b>[tomcat]\conf\server.xml</b>, adaugand urmatoarele linii in sectiunea <b>Server/Service/Engine</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<Host name="www.kj1.ro"  appBase="webapps/kj1-webapps"\n' +
                            '            unpackWARs="true" autoDeploy="true"/>\n' +
                            '\t\t\n' +
                            '<Host name="www.kj2.ro"  appBase="webapps/kj2-webapps"\n' +
                            '            unpackWARs="true" autoDeploy="true"/>\n' +
                            '\t\t\t\n' +
                            '<Host name="www.kj3.ro"  appBase="webapps/kj3-webapps"\n' +
                            '            unpackWARs="true" autoDeploy="true"/>\t'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Setarii DNS</b>
                    <br/>
                    <br/>

                    Inainte ca browser-ul sa caute in DNS-ul extern, cauta in DNS-ul local:
                    <ul>
                        <li>pe Windows: <b>C:\Windows\System32\drivers\etc\host</b></li>
                        <li>pe Linux: <b>/etc/host</b></li>
                    </ul>

                    <br/>
                    Se editeaza ca administator urmatoarul fisier din Windows <b>C:\Windows\System32\drivers\etc\host</b>, adaugand urmatoarele linii:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'\t127.0.0.1       www.kj1.ro\n' +
                            '\t127.0.0.1       www.kj2.ro\n' +
                            '\t127.0.0.1       www.kj3.ro'}
                    </SyntaxHighlighter>

                    Cand in browser se va scrie de exemplu <b>www.kj1.ro</b>, acesta va fi tradus in <b>127.0.0.1</b> (masina locala).
                    <br/>
                    Apoi, Tomcat pe baza host-ului <b>www.kj1.ro</b> va rula aplicatia care se gaseste in <b>webapps/kj1-webapps</b>.

                    <br/>
                    <br/>
                    Verificare in browser:
                    <ul>
                        <li>http://www.kj1.ro:8580/</li>
                        <li>http://www.kj2.ro:8580/</li>
                        <li>http://www.kj3.ro:8580/</li>
                    </ul>

                    <hr/>
                    <b>4. Setarii DNS in lumea reala</b>
                    <br/>
                    <br/>

                    Pentru a verifica propagarea DNS-ului (care poate dura si 2 zile):
                    <ul>
                        <li>
                            <li><b><a target={"_blank"} href={"https://dnschecker.org/"}>https://dnschecker.org/</a> </b></li>
                        </li>
                    </ul>

                    In functie de site-ul care se ocupa cu gestionarea domenilor, de obicei se leaga IP de doua intrari:
                    <ul>
                        <li>@ {"->"} IP</li>
                        <li>www {"->"} IP</li>
                    </ul>

                    Pentru a obtine IP-ul:
                    <ul>
                        <li>
                            <li><b><a target={"_blank"} href={"https://www.myip.com/"}>https://www.myip.com/</a> </b></li>
                            <li>pentru a afla IP-ul public: <b><a target={"_blank"} href={"https://www.ipaddress.com/"}>https://www.ipaddress.com/</a> </b></li>
                        </li>
                    </ul>
                    IP-ul public poate fi:
                    <ul>
                        <li>static</li>
                        <li>dinamic ( <b>dyndns</b> - permite să găzduiți un site pe un server cu IP dinamic)</li>
                    </ul>

                    Pentru a afla daca IP este static sau dinamic pe windows, din <b>cmd</b> se executa comanda:
                    <ul>
                        <li>
                            <b>ipconfig /all</b>
                            <br/>
                            Dacă scrie <b>Yes</b> lângă <b>DHCP Enabled</b>, sistemul dumneavoastră are o adresă IP dinamică.
                            <br/>
                            Dacă scrie <b>No</b>, dispozitivul are o adresă IP statică.
                        </li>
                    </ul>

                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>
                               https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VirtualHostsTomcatContent;