import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../books/IndexContent";

class VideoBooksContent extends BaseContentPage  {

    constructor(props) {
        super(props, "books-video", IndexContent);

        this.state = {
            ...this.state,
            //  filtredBooks: [],
            search:''
        };
    }

    static books = [
        {
            id:1,
            title:"The Cool Stuff in Premiere Pro",
            publisher:"Apress",
            year:2017,
            authors:["Jarle Leirpoll","Dylan Osborn","Paul Murphy","Andy Edwards"],
            pages:1225,
            language: "engleza",
            ISBN: "978-1484228890",
            img:"the-cool-stuff-in-premiere-pro.jpg"
        },

        {
            id:2,
            title:"Adobe Premiere Pro Classroom in a Book (2020 release)",
            publisher:"Pearson Education",
            year:2020,
            authors:["Maxim Jago"],
            pages:480,
            language: "engleza",
            ISBN: "0136602207",
            img:"22654094_adobe-premiere-pro-classroom-in-a-book.jpg"
        },

        {
            id:3,
            title:"Getting Started with DaVinci Resolve 17",
            publisher:"Anodyne Press",
            year:2021,
            authors:["Henry J. James"],
            pages:304,
            language: "engleza",
            ISBN: "194502836X",
            img:"36648949o.jpg"
        },

        {
            id:4,
            title:"Avid Media Composer | First",
            publisher:"Rowman & Littlefield",
            year:2021,
            authors:["Brad McCrystal"],
            pages:262,
            language: "engleza",
            ISBN: "1538143828",
            img:"28257295o.jpg"
        },

        {
            id:5,
            title:"Film Editing",
            publisher:"Bloomsbury Publishing",
            year:2021,
            authors:["Julie Lambden"],
            pages:232,
            language: "engleza",
            ISBN: "147425490X",
            img:"20913487_film-editing-emotion-performance-and-story.jpg"
        },

        {
            id:6,
            title:"Gramatica filmului",
            publisher:"Oscar Print",
            year:2013,
            authors:["Daniel Arijon"],
            pages:624,
            language: "romana",
            ISBN: "9789736683534",
            img:"gramatica-filmului.png"
        }

    ]

    handleSearch(event){
        let search = event.target.value;

        // let filtredBooks = ITBooksContent.books.filter((item) =>
        //     item.title.toLowerCase().includes(search.toLowerCase())
        // );
        //
        // this.setState({filtredBooks: filtredBooks});

        this.setState({search: search});
    }

    render() {

        this.state.filtredBooks = VideoBooksContent.books;

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>
                    <label htmlFor="search">
                        Cautare:&nbsp;
                        <input id="search" type="text" onChange={this.handleSearch.bind(this)} />
                    </label>
                    &nbsp;
                    <span>Numar total carti: {VideoBooksContent.books.length}</span>
                </div>

                <hr/>

                <br/>
                <div className={"text-justify important"}>

                    {VideoBooksContent.books
                        .filter(
                            item => {
                                let s = this.state.search.toLowerCase();
                                let okTitle = item.title.toLowerCase().includes(s);
                                let okPublisher = item.publisher.toLowerCase().includes(s);
                                let okAuthors = item.authors.some(a=>a.toLowerCase().includes(s));
                                return okTitle || okPublisher;
                            }
                        )
                        .map(function(item, index){
                        return <div className="row">

                            {/*<div className="col-sm-4">*/}
                            {/*    <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}*/}
                            {/*         src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>*/}

                            {/*</div>*/}

                            <div className="col-sm-12">

                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index+1}</span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250} rowSpan={"7"}>
                                            <img alt={""} style={{width:220}} className={"rounded mx-auto d-block"}
                                                    src={process.env.PUBLIC_URL + '/img/books/'+item.img}/>
                                        </td>

                                        <td width={250}>
                                            <b>Titlu</b>
                                        </td>
                                        <td>
                                            <span className="card-text" dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Editura</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.publisher}}/></td>
                                    </tr>
                                    <tr>
                                        <td><b>An</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.year}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Autori</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.authors}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Numar pagini</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.pages}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>Limba</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.language}}/></td>
                                    </tr>

                                    <tr>
                                        <td><b>ISBN</b></td>
                                        <td><span className="card-text" dangerouslySetInnerHTML={{__html: item.ISBN}}/></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>;

                    })}

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VideoBooksContent;