import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class PluginsGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-plugins", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Plugin-uri adauga noi task-uri (de exemplu, task-ul de compilare Java), conventii

                    <hr/>

                    Plugin-urile sunt de 2 tipuri:
                    <ul>
                        <li><b>script plugin</b>
                            <br/>
                            Aceste plugin-uri se pot regasi local sau remote pe un server.
                            <br/>
                            Pentru a importa/include un fisier de script local:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'apply from: "build-file-name.gradle"'}
                            </SyntaxHighlighter>

                            Pentru a importa/include un fisier de script de pe un server remote:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'apply from: "http://server.com//path//plugin-name"'}
                            </SyntaxHighlighter>

                            <hr/>
                            <i>Exemplu:</i>
                            <br/>
                            Fie fisierul script <i>build-plugin-1.gradle</i>, cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'task pluginATask { \n' +
                                '\tprintln name\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                            Fie fisierul script <i>build-plugin.gradle</i>, cu urmatorul continut (importam scriptul <i>build-plugin-1.gradle</i>):
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'apply from: \'build-plugin-1.gradle\''}
                            </SyntaxHighlighter>

                            Daca rulam:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'gradle -b build-plugin.gradle  -q\n' +
                                'pluginATask'}
                            </SyntaxHighlighter>

                        </li>
                        <li><b>binary plugin</b>
                            <br/>
                            Pentru a importa un plugin binar:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'apply plugin: "plugin-id"'}
                            </SyntaxHighlighter>

                            <hr/>
                            Exemple de plugin-id: java, war, jetty, base
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'apply plugin: "java"'}
                            </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '    id \'java\'\n' +
                                '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PluginsGradleContent;