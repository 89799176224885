import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class RecapitulareVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-recapitulare", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Plantatie</b>

                    <br/>

                    Reprezinta o schemă cu următoarele elemente:
                    <ul>
                        <li>elemente mari de decor (pentru a stii unde sunt ușile, ferestrele, surse de lumina prin incapere - daca e nevoie de lumina artificiala sau e suficienta lumina naturala)</li>
                        <li>personaje</li>
                        <li>directii de miscare</li>
                        <li>pozitionarea aparatului (punctul de statie al aparatului)</li>
                    </ul>

                    <br/>
                    <b>2. Directorul de fotografie</b>
                    <br/>
                    Acesta:
                    <ul>
                        <li>poate sa filmeze sau nu</li>
                        <li>are ca responsabilitate: camera si lumina</li>
                    </ul>

                    <b>3. Operator</b>
                    <br/>

                    Persoana care filmeaza se numeste <b>operator</b>

                    <br/>
                    <br/>
                    <b>4. Pozitionarea standard a camerei</b>
                    <br/>
                    Pozititionarea standar a camerei este la <i>nivelul ochilor</i> (linia de sus a gridului).
                    <br/>
                    Alte pozitionari:
                    <ul>
                        <li>plonjat</li>
                        <li>contraplonajat</li>
                    </ul>

                    <b>5. Incadraturi</b>
                    <ul>
                        <li>PA - plan ansamblu (plan de introducerea a contextului)</li>
                        <li>PG - plan general (plan de introducerea a personaului in context)</li>
                        <li>PI - plan intreg (plan cu personajul intreg)</li>
                        <li>PAM - plan american (plan cu taietura de la partea superioara a gambei si pana dupa cap)</li>
                        <li>PM - plan mediu (plan cu taietura de la mijlocul corpului, nivelul talieri si pana dupa cap)</li>
                        <li>PP - prim  plan (plan cu taietuura de la umeri la cap)</li>
                        <li>GP - gros plan (plan de la barbie la frunte)</li>
                        <li>PD - plan detaliu (detaliu pe ochi, mana, etc)</li>
                    </ul>

                    <b>6. Distanta focala</b>
                    <br/>
                    Distanta focala este distanta fizica de la <i>planul senzorului</i> (senzorul aparatului de fotografiat) pana la <i>centrul optic al obiectivului</i>.
                    <br/>
                    Exemple:
                    <ul>
                        <li>distanta focala foarte scurta: 16mm</li>
                    </ul>


                    <b>7. Timp standard expunere la video: 1/50</b>
                    <br/>
                    Timpul standar de expunere la vide este <b>1/50</b> (25fps)
                    <br/>

                    <br/>
                    <b>8. Cadru secventa</b>
                    <br/>
                    Cadru care nu are taietura (ex:<a href={"https://www.youtube.com/watch?v=PECz8C7m_Yo&ab_channel=StefReviews"}>Arca ruseasca</a> )
                    <br/>
                    <br/>
                    <b>9. Decupaj regizoral</b>
                    <br/>

                    <ul>
                        <li>numarul cadrului (1,2,3, etc)</li>
                        <li>incadratura</li>
                        <li>miscare de aparat</li>
                        <li>descrierea actiunii</li>
                        <li>dialog / sunat</li>
                    </ul>

                    <b>10. Principalul spatiu de culoare: Reg.709</b>
                    <br/>
                    Principalul spatiu de culoare este <b>Reg.709</b>
                    <br/>
                    Alte spatii de culoare (<a href={"https://www.benq.com/en-ap/business/resource/trends/understanding-color-gamut.html"}>detalii</a> ):
                    <ul>
                        <li>Reg2020</li>
                        <li>DCI-P3</li>
                    </ul>

                    <b>11. Panoramare</b>
                    <br/>
                    Panoramare (axul orizonatal):
                    <ul>
                        <li>miscare de la stanga la dreapta</li>
                        <li>miscare de la drapta la stanga</li>
                    </ul>

                    <b>12. ISO</b>
                    <br/>
                    <ul>
                        <li>creste luminizitatea</li>
                        <li>creste zgomotul (noise)</li>
                    </ul>
                    Nu are legatura cu sensibilitatea senzorului.
                    <br/>
                    <br/>

                    <b>13. Filtru ND</b>
                    <br/>
                    Au capacitatea de a întuneca între 1 și 15 stopuri de lumină / blocheaza lumina.
                    <br/>
                    <br/>

                    <b>14. Expunerea in centru</b>
                    <br/>
                    Expunerea in centru reprezinta griul 18%.

                    <br/>
                    Exponometrul aparatului de fotografiat măsoară lumina care vine de la subiect prin lentilă și apoi folosește aceste informații pentru a seta parametrii de expunere astfel incât să obțină o expunere medie de 18% gri.
                    <br/>
                    Aparatul setează controalele pentru a obține o expunere echivalentă cu 18% gri.

                    <br/>
                    <br/>

                    <b>15. Profunzimea campului</b>
                    <br/>
                    Profunsimea campului este determinata de:
                    <ul>
                        <li>diafragma</li>
                        <li>distanta pana la subiect</li>
                        <li>distanta focala</li>
                    </ul>

                    <b>16. Blenda</b>
                    <br/>
                    Blenda este folosita pentru a:
                    <ul>
                        <li>intoarce lumina (reflexa)</li>
                        <li>absoarbe din lumina</li>
                    </ul>

                    <b>17. Key light</b>
                    <br/>
                    Key light este lumina principala, folosita pentur a evidenția forma și dimensiunea subiectului.
                    <br/>
                    Alte lumini:
                    <ul>
                        <li>fill light (lumina de umplere;  raportul dintre lumina cheie și lumina de umplere este de 2:1; aceasta înseamnă că lumina cheie ar trebui să fie de două ori mai puternică decât cea de umplere. )</li>
                        <li>back light (lumina de fundal, folositaa apentru a extrage cea de-a treia dimensiune)</li>
                        <li>rim light
                            (este o lumină de contur este <b>plasată în spatele unui subiect</b> care expune conturul sau marginea subiectului cu lumină;
                            <br/>
                            această iluminare evidențiază contururile unui subiect și creează un efect dramatic și misterios. )
                        </li>
                    </ul>

                    <b>18. Lumina dura</b>
                    <br/>
                    Lumina dura / hard light = lumina de la sursa de lumina
                    <br/>
                    <br/>

                    <b>19. Lumina soft</b>
                    <br/>
                    Soft light = lumina filtrata sau reflextata
                    <br/>
                    <br/>

                    <b>20. Temperatura de culoare a soarelui</b>
                    <br/>
                    Temperatura de culoare a soareluiL: 5600k
                    <br/>
                    <br/>

                    <b>21. Cercul/Roata culorilor</b>
                    <br/>
                    Culori complentare:

                    <ul>
                        <li>verde {"->"} rosu</li>
                        <li>albastru {"->"} orange</li>
                        <li>violet {"->"} galben</li>
                    </ul>
                    culori calde: rosu, organge, galben.
                    <br/>
                    culori reci: verde, albastru, violet.
                    <br/>
                    <br/>


                    <b>22. Gama dinamica</b>
                    <br/>

                    Gama dinamica definește diferența dintre valoarea minimă și maximă a luminozității pe care o poate înregistra/capta un suport media.
                    <br/>
                    <br/>

                    <b>23. RGB</b>
                    <br/>
                    RGB este un model de culoare aditiv (RGB = Red, Green, Blue),  în care culorile albastru, roșu și verde sunt amestecate în diferite moduri pentru a produce o gamă largă de culori.
                    <br/>
                    <br/>

                    <b>24. Geluri / filtre corectie</b>
                    <br/>
                    Gelurile sunt folosite pentru conversia culorilor.
                    <br/>
                    <ul>
                        <li>CTB = Color Temperature Blue</li>
                        <li>CTO = Color Temperature Orange</li>
                    </ul>

                    <b>25. Portretul</b>
                    <br/>

                    Portret / Plan mediu {"=>"} 50mm
                    <br/>
                    <br/>

                    <b>26. Filtre de difuzie</b>
                    <br/>

                    Soft effects
                    <br/>
                    <br/>

                    <b>27. Obiective</b>
                    <br/>

                   <ul>
                       <li>fish eye: 8mm (distorie mare/focala scurta)</li>
                       <li>ultrawide: sub 24mm</li>
                       <li>wide: 24 - 35mm</li>
                       <li>standard: 35 - 70mm</li>
                       <li>tele / zoom: 70-300mm </li>
                       <li>supertele: peste 300mm</li>
                   </ul>

                    <b>28. Lavaliera</b>
                    <br/>
                    Microfon pentru voce.
                    <br/>
                    <br/>

                    <b>29. Profil de culoare</b>
                    <br/>
                    Flat:
                    <ul>
                        <li>contrast de culoare:minim </li>
                    </ul>

                    <b>30. Histograma</b>
                    <br/>
                    Histograma este un instrument care ne arata cum este informatia pe sensor, de la cele mai intunecate zone (stanga), la cele mai luminoase (dreapta)
                    si numarul de pixeli alocati zonei respective de jos in sus.
                    <br/>
                    Gri 18% ar trebui sa fie in mijlocul histogramei.
                    <br/>
                    Expunerea ar fi in mijloc in zona de Midtones.
                    <br/>
                    <br/>

                    <b>31. Setari Export Premiere Pro</b>
                    <br/>
                    Export:
                    <ul>
                        <li>codec: H.264</li>
                        <li>FullHD: 1920x1080</li>
                    </ul>

                    <b>32. Sunete si voce</b>
                    <br/>
                    <ul>
                        <li>sunete: -12 -18dB</li>
                        <li>voce: -3 -6dB</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.premiumbeat.com/blog/cut-clips-premiere-pro-tools/"}>The 3 Easiest Ways to Cut Clips in Adobe Premiere Pro CC</a><br/>
                           </div>
                       </li>
                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        /!*<a href={"https://www.studiobinder.com/blog/deep-focus-shot-camera-movement-angle/"}>Deep Focus Shot: Creative Examples of Camera Movements & Angles</a>*!/*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RecapitulareVideografieContent;