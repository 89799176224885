import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DtrMsrDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-dtr", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Docker Trusted Registry (DTR)/ Mirantis Secure Registry (MSR)</b>

                    <br/>
                    <br/>

                    DTR este similar cu DockerHub (Google Container Registry, self-host Registry), dar privat. Se poate creea repository-uri noi (publice sau private).
                    Se pot face setari legate de:
                    <ul>
                        <li>imutabilitate: tag-urile pot fi imutabile, nu se pot suprascrie sau din contra;
                            <br/>
                            in mod implicit, utilizatori cu acces de scriere si citirre la repository pot face push la acelasi tag de mai multe ori.
                            <br/>
                            dar se poate configura, ca DTR sa nu permita sa se faca push la o imagine cu acelasi tag (pentru a evita suprascrierile nedorite);
                            <br/>
                            Pentru face repository-ul imutabil:
                            <br/>
                            <b>Respositoies {"->"} (se alege un repository) {"->"} Settings {"->"} General {"->"} Immutability = On</b>
                        </li>
                        <li>scanare la push sau nu</li>
                    </ul>

                    Tot din DTR, se pot adauga noi membri, adica utilizatori, la organizatie.

                    {/*<br/>*/}

                    {/*DTR se poate instala pe orice nod de tip manager/worker.*/}

                    <br/>
                    <br/>
                    Caracteristici:
                    <ul>
                        <li>scanarea securitatii</li>
                        <li>semnarea imaginii</li>
                        <li>built-in controll access</li>
                        <li>cacharea imaginilor</li>
                    </ul>
                    DTR este o aplicatie container care ruleaza pe un Docker UCP cluster.
                    <br/>
                    Pentru a instala DTR, toate nodurile:
                    <ul>
                        <li>trebuie sa fie un worker node gestionat de UCP/MKE</li>
                        <li>trebuie sa aiba un hostname fix</li>
                    </ul>

                    <hr/>

                    <b>1.1. Instalare DTR pe un nod UCP</b>
                    <br/>
                    <br/>
                    Admin Settings {"->"} Docker Trusted Registry :
                    <ul>
                        <li>
                            DTR external URL (daca avem)
                        </li>
                        <li>
                            UCP Node {"->"} se selecteaza pe ce nod va fi instalat DTR
                        </li>
                        <li>
                            se alege daca se selecteze:
                            <ul>
                                <li>
                                    [-] assign a DTR replica ID
                                </li>
                                <li>
                                    [X] disable TLS verification for UCP
                                </li>
                                <li>
                                    [-] use a PEM-encoded TLS CA certificate for UCP
                                </li>
                            </ul>
                        </li>
                    </ul>
                    In urma selectiilor facute mai sus, se va genera o comanda prin care se poate instala DTR.
                    <br/>
                    Se intra pe masina worker si se ruleaza comanda generata, ceva de genul:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run --rm -it docker/dtr:2.7.5 install \\\n' +
                            '--ucp-node worker \\\n' +
                            '--ucp-url https://10.128.15.198/ \\\n' +
                            '--ucp-username kj \\\n' +
                            '--ucp-insecure-tls \\\n' +
                            '--dtr-external-url https://10.128.15.199'}
                    </SyntaxHighlighter>
                    sau pt MSR:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run -it --rm \\\n' +
                            '  mirantis/dtr:2.9.10 install \\\n' +
                            '  --dtr-external-url <msr.example.com> \\\n' +
                            '  --ucp-node <mke-node-name> \\\n' +
                            '  --ucp-username admin \\\n' +
                            '  --ucp-url <mke-url>'}
                    </SyntaxHighlighter>
                    La instalare se va genera <b>Replica ID</b>.

                    <br/>
                    Din interfata UCP:  Admin Settings {"->"} Docker Trusted Registry {"->"} vom vedea IP-ul pe care este instalat DTR.

                    <br/>
                    Pentru a ne loga in aplicatia DTR, vom folosi acelasi credentiale ca la UCP si IP-ul afisat in sectiunea <i>Admin Settings {"->"} Docker Trusted Registry</i>.

                    <hr/>

                    <b>1.2. Dezinstalare MSR (UP) pe un nod UCP</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run -it --rm \\\n' +
                            '  mirantis/dtr:2.9.10 destroy \\\n' +
                            '  --ucp-insecure-tls'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.3. Docker Content Trust (DCT)</b>
                    <br/>
                    <br/>
                    Docker Content Trust (DCT)
                    <ul>
                        <li>
                            locatie: Admin Settings {"->"} Docker Content Trust;
                            <br/>
                            aici se poate bifa: <b>Run only signed images</b>;
                            <br/>
                            dupa bifare se poate alege/adauga:
                            <ul>
                                <li>
                                    <b>Team</b>
                                </li>
                            </ul>
                            dupa daca vom vrea sa adaugam o noua imagine nesemnata (din <b>Shared Resources {"->"} Images {"->"} Pull Images</b>) va fi blocata
                        </li>
                        <li>
                            adauga un alt layer de securitate la MKE
                        </li>
                        <li>
                            blocheaza orice imagine care nu este semmnata de echipa dorita
                        </li>
                    </ul>

                    <hr/>
                    <b>1.4. Backing up. Restoring UCP</b>
                    <br/>
                    <br/>

                    Intram intr-un nod. Creeam un director unde sa se faca back-up-ul.

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir backup'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sudo chmod 777 /home/kj/backup/'}
                    </SyntaxHighlighter>

                    Din interfata UCP {"->"} Admin Settings {"->"} Backup {"->"} New Backup {"->"} Configure Backup
                    <ul>
                        <li>File system location {"=>"} <i>/home/kj/backup/</i></li>
                        <li>File name {"=>"} <i>backup.rar</i></li>
                        <li>[X] INCLUDE LOGS</li>
                        <li>
                            [-] ENCRYPT BACKUP
                        </li>
                        <li>
                            Passphases
                        </li>
                    </ul>
                    Se apasa pe butonul <b>Backup Now</b>.
                    <br/>
                    Dupa apasarea butonului, daca navigam in <i> /home/kj/backup/</i> vom regasi 2 fisiere:
                    <ul>
                        <li>backup.log {"=>"} care va contine informatii privind actiunea de backup, cand a inceput, cand s-a sfarsit, daca s-a desfasurat cu succes</li>
                        <li>backup.rar {"=>"} backup-ul propriu zis</li>
                    </ul>

                    Imaginea <b>mirantis/ucp</b> permite comenzi de genul:
                    <ul>
                        <li>install</li>
                        <li>uninstall</li>
                        <li>restore</li>
                        <li>backup</li>
                        <li>upgrade</li>
                    </ul>

                    Uninstall UCP:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run --rm -it \\\n' +
                            '-v /var/run/docker.sock:/var/run/docker/sock \\\n' +
                            'mirantis/ucp uninstall-ucp --interactive'}
                    </SyntaxHighlighter>

                    Restore UCP:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run --rm --interactive \\\n' +
                            '-v /var/run/docker.sock:/var/run/docker/sock \\\n' +
                            'mirantis/ucp restore < backup.rar'}
                    </SyntaxHighlighter>

                    Urmatori parametrii pot fi restaurati dintr-un UCP backup:
                    <ul>
                        <li>nodes</li>
                        <li>swarm workloads</li>
                        <li>kubernetes workloads</li>
                        <li>RBAC grants</li>
                    </ul>

                    Urmatori parametrii NU pot fi restaurati dintr-un UCP backup:
                    <ul>
                        <li>resources utilization stats</li>
                    </ul>

                    <hr/>
                    <b>1.5. Configurare Trusted CA si pushing imagini in MSR</b>
                    <br/>
                    <br/>

                    Pasi de urmat:
                    <ul>
                        <li>
                            descarcare certificat
                        </li>
                        <li>
                            actualizare lista de certificate
                        </li>
                        <li>
                            restartare docker
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# Download the MSR CA certificate\n' +
                            'sudo curl -k https://<msr-domain-name>/ca -o /usr/local/share/ca-certificates/<msr-domain-name>.crt\n' +
                            '# Refresh the list of certificates to trust\n' +
                            'sudo update-ca-certificates\n' +
                            '# Restart the Docker daemon\n' +
                            'sudo service docker restart'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker tag busybox:latest example.com/admin/webserver:v1\n' +
                            'docker push example.com/admin/webserver:v1'}
                    </SyntaxHighlighter>

                    Mai multe aici: <a target={"_blank"} href={"https://docs.mirantis.com/msr/2.9/ops/access-msr/configure-your-mcr.html"}>Configure your Mirantis Container Runtime</a>

                    <hr/>
                    <b>1.6. Backup MSR (DTR)</b>
                    <br/>
                    <br/>

                    Comanda de backup cu are ca efect downtime pentru DTR, deci se poate face frecvent fara a afecta utilizatorii.
                    <br/>

                    Observatii:
                    <ul>
                        <li>Această comandă creează doar copii de rezervă ale configurațiilor și metadate ale imaginii.
                            <br/>
                            Nu face backup pentru utilizatori și organizații. Utilizatorii și organizațiile pot fi salvate în timpul unei copii de rezervă MKE
                            <br/>
                            De asemenea, nu face copii de rezervă pentru imaginile Docker stocate în registry
                        </li>
                    </ul>

                    Comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run -i --rm mirantis/dtr \\\n' +
                            '    backup [command options] > backup.tar'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run -i --rm --log-driver none mirantis/dtr:2.9.10 \\\n' +
                            '    backup --ucp-ca "$(cat ca.pem)" --existing-replica-id 5eb9459a7832 > backup.tar'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            Utilizați un certificat CA TLS codificat PEM pentru MKE.
                            <br/>
                            Descărcați certificatul CA MKE TLS de la https://[mke-url]/ca, și folosit <b>--ucp-ca "$(cat ca.pem)</b>"
                        </li>
                    </ul>

                    <hr/>
                    <b>1.7. Backup imagini MSR (DTR)</b>
                    <br/>
                    <br/>

                    DTR/MSR suporta mai multe surse de backend pentru a stoca imaginile.
                    <br/>
                    Depinzand de backend-ul folosit, procedura de backup pentru imagini difera.
                    <br/>
                    Pentru volumele locale, se poate face backup la volumul asociat cu registrul DTR.
                    De exemplu, in DTR se poate verifica unde se tin imaginile: System {"=>"} Storage {"=>"} Filesystem {"=>"} Volume (de exemplu).
                    <br/>
                    Storage type:
                    <ul>
                        <li>Filesystem</li>
                        <li>Cloud</li>
                    </ul>
                    Pentru Filesystem (Local), se poate seta:
                    <ul>
                        <li>NFS</li>
                        <li>Bind Mount</li>
                        <li>Volume</li>
                    </ul>
                    Pentru Cloud (Storage Provider), se poate seta:
                    <ul>
                        <li>
                            AWS S3
                        </li>
                        <li>
                            Azure
                        </li>
                        <li>Cloud Cloud</li>
                    </ul>

                    In dtr se afiseaza numele volumului. Deci, calea catre locatia unde se gasesc imaginile stocate este:
                    <br/>
                    /var/lib/docker/volumes/[nume-volum]
                    <br/>
                    Se poate face o arhiva la directorul [nume-volume]:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'tar -czvf backup.tar.gz [nume-volume]'}
                    </SyntaxHighlighter>

                    <hr/>
                    In mod implicit, DTR stocheaza imaginile pe sistemul de fisiere (filesystem) la nodului pe care ruleaza, dar poate fi configurat sa se utilizeze un  backend centralizat de stocare.
                    <br/>
                    Se poate configura DTR sa foloseasca un backend extern de stocare, pentru a imbunatatii performanta sau disponibilitatea (high availability)

                    <hr/>
                    <b>1.8. Routing Mesh</b>
                    <br/>
                    <br/>

                    Routing Mesh permite fiecarui nod din swarm sa accepte conexiuni pe porturile conectate pentru orice serviciu care ruleaza in swarm,
                    chiar daca nu exista nici un task care ruleaza pe nod.
                    <br/>
                    De exemplu:
                    <ul>
                        <li>avem 3 noduri, pe 2 noduri (nod 1 si nod 2) avem aplicatia;
                            daca se apeleaza aplicatia de pe nodul 3 (care nu contine aplicatia) atunci cererea va fi redirectata fie catre nod 1, fie catre nod 2.
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker create service --name webserver --publish published=8080, target 80 --replicas 1 nginx'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>published {"=>"} publica portul in toate nodurile din cluster</li>
                    </ul>


                    <hr/>
                    <b>1.9. High Availability (disponibilitate ridicata) MSR (DTR)</b>
                    <br/>
                    <br/>

                    DTR este proiectat sa se scaleze orizontal pe masura ce creste utilizarea.
                    Se pot adauga noi replici pentru a face DTM scalabil, la cerere, pentru o disponibilitate ridicata.

                    <br/>
                    Toate replicile DTR ruleaza acelasi set de servicii, iar modificarile configuratiei sunt propagate automat replicilor.
                    <br/>
                    Daca exista o singura replica DTR, se poate folosi local filesystem pentru a stoca imaginile Docker.
                    <br/>
                    Daca exista mai multe replicit DTR, pentru disponibilitate ridicata, este nevoie ca toate replicile sa foloseasca acelasi backend de stocare.

                    <br/>
                    Join DTR Replica (adăugare replică noua la un cluster MSR existent)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run -it -rm docker/dtr join --ucp-insecure-tls'}
                    </SyntaxHighlighter>
                    sau pt MSR:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'docker run -it --rm \\\n' +
                            '  mirantis/dtr:2.8.13 join \\\n' +
                            '  --ucp-node <mke-node-name> \\\n' +
                            '  --ucp-insecure-tls'}
                    </SyntaxHighlighter>
                    Această comandă creează o replică a unui MSR existent pe un nod gestionat de Mirantis Kubernetes Engine (MKE).
                    <br/>
                    Pentru a seta MSR pentru disponibilitate ridicată, creați 3, 5 sau 7 replici ale MSR.

                    <hr/>
                    DTR expune cateva endpointr-uri pentru a verifica daca o replica este ok (sanatoasa) sau nu:
                    <ul>
                        <li>
                            /_ping {"=>"} verifica daca replica DTR este sanatoasa si returneaza un json ca raspuns;
                            <br/>
                            exemplu:
                            <SyntaxHighlighter>
                                {'curl -k https://142.93.213.134/_ping'}
                            </SyntaxHighlighter>
                            <SyntaxHighlighter>
                            {'{"Error":"", "Healthy":true}'}
                        </SyntaxHighlighter>
                            <br/>
                            este folositoare pentru load balancing dau alte task-uri automate de health check (verificare sanatatii)
                        </li>
                        <li>
                            /api/v0/meta/cluster_status {"=>"} returneaza informatii extensise despre toate replicile DTR;
                            <br/>
                            exemplu:
                            <SyntaxHighlighter>
                                {'curl -ksl -u admin:88DBJTWmxMCL6J9KpfgpGBq5UvsMfxL1 https://142.93.213.134/api/v0/meta/cluster_status'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Pentru a avea disponibilitate ridicata pe UCP si DTR, trebuie un minim de:
                    <ul>
                        <li>3 noduri dedicate pentru UCP</li>
                        <li>3 noduri dedicate pentru DTR</li>
                    </ul>
                    Daca se adauga prea multe replicit la cluster poate conduce la o degradare a performantei, pentru ca datele trebuie replicate la toate replicile.
                    <br/>
                    Daca o replica crapa, numarul de toleranta (failures toleranted) al clusterului scade. Nu trebuie lasata o replica offline prea mult timp.


                    <hr/>
                    <b>1.10. DTR - Caching</b>
                    <br/>
                    <br/>

                    Cu cat suntem mai departe de locatia geografica in care instalat DTR cu atat mai mult pierdem pentru a face push si pull la imagini
                    (pentru ca fisierele transferate trebuie sa parcurga distante lungi si prin mai multe retele).
                    <br/>
                    Pentru a reduce timpul de pull la o imagine, se poate implementa cache DTR mai aproape de utilizatori din punct de vedere geografic.
                    <br/>
                    Cache-urile sunt transparente pentru utilizator (utilizatorii facand push si pull de pe andresa URL a DTR-ului).
                    DTR verifica daca utilizatorii sunt autorizati pentru a face pull la imagine si face redirect la cerere catre cache.

                    <br/>
                    <b>UCP agent</b> nu este necesar sa fie instalat pe nodul pe care este <b>DTR cache</b>.

                    <hr/>
                    <b>1.11. Setare orchestrator in UCP</b>
                    <br/>
                    <br/>

                    UPC suporta urmatorii orchestratori:
                    <ul>
                        <li>Swarm</li>
                        <li>Kubernetes</li>
                    </ul>
                    Cand se adauga un nod in cluster, workload-urile nodului sunt gestionati in mod implicit de un orchestrator: fie Docker Swarm, fie Kubernates.
                    <br/>
                    Cand se instaleaza Docker Enterprise, noile noduri sunt gestionate de Docker Swarm, dar acest lucru se poate schimba la Kubernetes din setari:
                    <b>Admin Settings {"->"} Orchestration {"->"} Scheduler {"->"} Set Orchestrator Type for new nodes {"->"} [x] Swarm / [-] Kubernetes</b>

                    <br/>
                    <br/>
                    Se poate schimba orchestratorul curent pentru orice nod care este intr-un cluster. Tipul de orchestrator:
                    <ul>
                        <li>Kubernetes</li>
                        <li>Swarm</li>
                        <li>Mixed {"=>"} permite workload-uri sa fie programate de Kubernetes si Swarm pe acelasi nod
                            <br/>
                            Nu este recomandat pentru mediul de productie
                        </li>
                    </ul>
                    Cand se schimba tipyl de orchestrator pentru un node, workload-urile existente sunt evacuate/evicted, si nu sunt migrate catre noul orchestrator in mod automat.

                    <br/>
                    Pentru a schimba tipul de orchestrator de la Swarm la Kubernestes:
                    <SyntaxHighlighter>
                        {'docker node update --label-add com.docker.ucp.orchestrator.kuberneste=true [node-id]'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DtrMsrDockerContent;