import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class InstallJava11Ubuntu20Content extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-install-java-11-ubuntu-20", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>0. Verificare</b>
                    <br/>
                    <br/>
                    Se verifica ca nu exista java instalat pe masina:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'java -version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Command \'java\' not found, but can be installed with:\n' +
                            'sudo apt install openjdk-11-jre-headless  # version 11.0.19+7~us1-0ubuntu1~22.04.1, or\n' +
                            'sudo apt install default-jre              # version 2:1.11-72build2\n' +
                            'sudo apt install openjdk-17-jre-headless  # version 17.0.7+7~us1-0ubuntu1~22.04.2\n' +
                            'sudo apt install openjdk-18-jre-headless  # version 18.0.2+9-2~22.04\n' +
                            'sudo apt install openjdk-19-jre-headless  # version 19.0.2+7-0ubuntu3~22.04\n' +
                            'sudo apt install openjdk-8-jre-headless   # version 8u372-ga~us1-0ubuntu1~22.04'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>1. Descarcare kit-ul de pe site-ul de la Oracle</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'jdk-11.0.14_linux-x64_bin.deb'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Mutare kit masina cu Ubuntu</b>
                    <br/>
                    <br/>

                    Se pune pe masina in locatia:

                    <SyntaxHighlighter>
                        {'/home/kj/kit'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Instalare</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd /home/kj/kit'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt-get update'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo apt --fix-broken install'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo dpkg -i jdk-11.0.14_linux-x64_bin.deb'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Configurare</b>
                    <br/>
                    <br/>

                    Fișierul pachetului Oracle JDK 11 <b>deb</b> instalează Oracle JDK 11 în directorul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'/usr/lib/jvm'}
                    </SyntaxHighlighter>

                    Dar, nu este în PATH în mod implicit.

                    <br/>
                    <br/>
                    Verificare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ls /usr/lib/jvm/'}
                    </SyntaxHighlighter>
                    va afisa:
                    <SyntaxHighlighter>
                        {'jdk-11'}
                    </SyntaxHighlighter>


                    Se creaza un nou fișier:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'/etc/profile.d/jdk11.sh'}
                    </SyntaxHighlighter>
                    /etc/profile.d/jdk11.sh
                    cu următoarea comandă:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo nano /etc/profile.d/jdk11.sh'}
                    </SyntaxHighlighter>
                    sudo nano /etc/profile.d/jdk11.sh
                    si se adauga randurile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'export JAVA_HOME="/usr/lib/jvm/jdk-11"\n' +
                            'export PATH="$PATH:${JAVA_HOME}/bin"'}
                    </SyntaxHighlighter>

                    Apoi, se ruleaza comanda <b>source</b>, pentru a putea fi luate configurarile:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'source /etc/profile.d/jdk.sh'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Verificare</b>


                    Se verifica ca s-a instalat cum trebuie java pe masina:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'java -version'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'java version "11.0.14" 2022-01-18 LTS\n' +
                            'Java(TM) SE Runtime Environment 18.9 (build 11.0.14+8-LTS-263)\n' +
                            'Java HotSpot(TM) 64-Bit Server VM 18.9 (build 11.0.14+8-LTS-263, mixed mode)'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://linuxhint.com/install_oracle_jdk11_ubuntu/"}>
                               Install Oracle JDK 11 on Ubuntu
                           </a>
                       </li>

                        <li>
                            <a target={"_blank"} href={"https://computingforgeeks.com/how-to-install-java-11-on-ubuntu-linux/"}>
                                Install Java 11 on Ubuntu 22.04
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://www.cherryservers.com/blog/how-to-install-java-on-ubuntu"}>
                                How to Install Java on Ubuntu 22.04 | Step-by-Step Tutorial
                            </a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallJava11Ubuntu20Content;