import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class DnsNetworkDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-network-dns", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>
                        1. DNS
                    </b>
                    <br/>
                    <br/>

                    Docker se foloseste de fisierul <b>/etc/resolv.conf</b> pentru a rezolva DNS.

                    <br/>
                    Pe local exista fisierul: <b>cat /run/systemd/resolve/resolv.conf</b>, care poate avea urmatorul continut:
                    <SyntaxHighlighter>
                        {'# This is /run/systemd/resolve/resolv.conf managed by man:systemd-resolved(8).\n' +
                            '# Do not edit.\n' +
                            '#\n' +
                            '# This file might be symlinked as /etc/resolv.conf. If you\'re looking at\n' +
                            '# /etc/resolv.conf and seeing this text, you have followed the symlink.\n' +
                            '#\n' +
                            '# This is a dynamic resolv.conf file for connecting local clients directly to\n' +
                            '# all known uplink DNS servers. This file lists all configured search domains.\n' +
                            '#\n' +
                            '# Third party programs should typically not access this file directly, but only\n' +
                            '# through the symlink at /etc/resolv.conf. To manage man:resolv.conf(5) in a\n' +
                            '# different way, replace this symlink by a static file or a different symlink.\n' +
                            '#\n' +
                            '# See man:systemd-resolved.service(8) for details about the supported modes of\n' +
                            '# operation for /etc/resolv.conf.\n' +
                            '\n' +
                            'nameserver 192.168.1.1\n' +
                            'nameserver 2a02:2f0c:8000:3::1\n' +
                            'nameserver 2a02:2f0c:8000:8::1\n' +
                            'search local\n'}
                    </SyntaxHighlighter>

                    Intr-un container:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it busybox cat /etc/resolv.conf'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'# This is /run/systemd/resolve/resolv.conf managed by man:systemd-resolved(8).\n' +
                            '# Do not edit.\n' +
                            '#\n' +
                            '# This file might be symlinked as /etc/resolv.conf. If you\'re looking at\n' +
                            '# /etc/resolv.conf and seeing this text, you have followed the symlink.\n' +
                            '#\n' +
                            '# This is a dynamic resolv.conf file for connecting local clients directly to\n' +
                            '# all known uplink DNS servers. This file lists all configured search domains.\n' +
                            '#\n' +
                            '# Third party programs should typically not access this file directly, but only\n' +
                            '# through the symlink at /etc/resolv.conf. To manage man:resolv.conf(5) in a\n' +
                            '# different way, replace this symlink by a static file or a different symlink.\n' +
                            '#\n' +
                            '# See man:systemd-resolved.service(8) for details about the supported modes of\n' +
                            '# operation for /etc/resolv.conf.\n' +
                            '\n' +
                            'nameserver 192.168.1.1\n' +
                            'search local\n'}
                    </SyntaxHighlighter>

                    La pornirea containerului se poate seta DNS prin optiunea <b>--dns</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker run -it --dns 1.1.1.1  busybox cat /etc/resolv.conf'}
                    </SyntaxHighlighter>
                    acum va afisa:
                    <SyntaxHighlighter>
                        {'search local\n' +
                            'nameserver 1.1.1.1\n'}
                    </SyntaxHighlighter>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DnsNetworkDockerContent;