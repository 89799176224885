import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class UnitTestingNuxeoContent extends BaseContentPage {

    constructor(props) {
        super(props, "nuxeo-unit-testing", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    In Maven se adauga dependinta:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<dependency>\n' +
                        '  <groupId>org.nuxeo.runtime</groupId>\n' +
                        '  <artifactId>nuxeo-runtime-test</artifactId>\n' +
                        '  <scope>test</scope>\n' +
                        '</dependency>'}
                    </SyntaxHighlighter>

                    Se creaza clasa de test:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@RunWith(FeaturesRunner.class)\n' +
                        '@Features(RuntimeFeature.class)\n' +
                        'public class MyTest {\n' +
                        '  ...\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Test
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Test\n' +
                        'public void isNuxeoStarted(){\n' +
                            '\tAssert.assertNotNull("Runtime is not available", Framework.getRuntime())\n'+
                        '}'}
                    </SyntaxHighlighter>

                    Daca avem <b>src/main/resources/META-INF/MANIFEST.MF</b> la <b>Bundle-SymbolicName</b> = ro.dopomina.my-module
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@RunWith(FeaturesRunner.class)\n' +
                        '@Features(RuntimeFeature.class)\n' +
                        '@Deploy("ro.dopomina.my-module")\n' +
                        'public class MyTest {\n' +
                        '  ...\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Daca avem fisierul <b>src/main/resources/OSGI-INF/</b><i>org.mycompany.myproject-service.xml</i> cu urmatorul continut:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<component name="org.mycompany.myproject.MyService">\n' +
                        '\n' +
                        '  <documentation>\n' +
                        '    This component can be documented here, HTML tags are accepted.\n' +
                        '  </documentation>\n' +
                        '  <implementation class="org.mycompany.myproject.MyComponent" />\n' +
                        '  <service>\n' +
                        '    <provide interface="org.mycompany.myproject.api.MyService" />\n' +
                        '  </service>\n' +
                        '\n' +
                        '</component>'}
                    </SyntaxHighlighter>
                    Fisierul de mai sus trebuie sa fie referit si din <b>src/main/resources/META-INF/MANIFEST.MF</b> in sectiunea <b>Nuxeo-Component</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'Nuxeo-Component:src/main/resources/OSGI-INF/org.mycompany.myproject-service.xml'}
                    </SyntaxHighlighter>

                    Atunci se poate testa:
                    Test
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Test\n' +
                        'public void isComponentLoaded(){\n' +
                        '\tFramework runtime =  Framework.getRuntime())\n' +
                        '\tAssert.assertNotNull(runtime.getComponent("org.mycompany.myproject.MyService"))'+
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Manipulare de documente</b>
                    <br/>
                    Daca se testeaza manipularea de documente, atunci e nevoie de: <b>@Features(CoreFeature.class)</b>, si nu de <b>@Features(RuntimeFeature.class)</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@RunWith(FeaturesRunner.class)\n' +
                        '@Features(CoreFeature.class)\n' +
                        'public class MyTest {\n' +
                        '  ...\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Apoi e nevoie de <b>CoreSession</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@RunWith(FeaturesRunner.class)\n' +
                        '@Features(CoreFeature.class)\n' +
                        'public class MyTest {\n' +
                        '\t@Inject\n' +
                        '\tprivate CoreSession coreSession;\n' +
                        '}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://doc.nuxeo.com/corg/unit-testing/"}>
                                Unit Testing
                            </a>
                        </div>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UnitTestingNuxeoContent;