import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavascriptLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-javascript", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    In clasa portlet se adauga proprietatea:
                    <SyntaxHighlighter>
                        {'com.liferay.portlet.footer-portlet-javascript'}
                    </SyntaxHighlighter>
                    De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'com.liferay.portlet.footer-portlet-javascript=/js/workflow-page.js'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.footer-portlet-javascript=/js/workflow-page.js",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=WorkflowPage",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.name=" + WorkflowPagePortletKeys.WORKFLOWPAGE,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class WorkflowPagePortlet extends MVCPortlet {..}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavascriptLiferayContent;