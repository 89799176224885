import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class Volumek8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-volume", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Volume</b>
                    <br/>
                    <br/>

                    Tipuri de volume:
                    <ul>
                        <li>(Kubernates) Volume - in pod </li>
                        <li>Persistent Volume - in afara podurilor</li>
                        <li>Persistent Volume Claim - publicarea optiunilor de persistent volume (static/dinamic)</li>
                    </ul>

                    <hr/>
                    <b>1.1. Kubernates Volume</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>in terminologia containerelor {"=>"} mecanism prin care un container poate accesa un filesystem din afata lui</li>
                        <li>in terminologia k8s {"=>"} un obiect care permite unui container sa stocheze date la nivel de pod</li>
                    </ul>

                    Daca avem un Pod si in interiorul lui un container si un volum:
                    <ul>
                        <li>daca crapa container-ul, volumul e ok</li>
                        <li>daca crapa Pod-ul, volumul este distrus si el (impresuna, desigur cu container-ul)</li>
                    </ul>
                    {/*Prin urmare un volum este potrivit pentru a pastra datele unui baze de date in k8s.*/}

                    Exemple de 3 tipuri de volume:
                    <ul>
                        <li>emptyDir</li>
                        <li>hostPath</li>
                        <li>csi (Container Storage Interface)</li>
                    </ul>

                    Un exemplu de volum la nivel de pod este: <b>emptyDir</b>.
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: story-deployment\n' +
                            'spec: \n' +
                            '  replicas: 1\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: story\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: story\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:1\n' +
                            '          volumeMounts:\n' +
                            '            - mountPath: /app/story # cale din container\n' +
                            '              name: story-volume # nume volum, definit mai jos in sectiunea "volumes"\n' +
                            '      volumes:\n' +
                            '        - name: story-volume\n' +
                            '          emptyDir: {} # tip volum'}
                    </SyntaxHighlighter>
                    O problema, cu abordarea de mai sus, este cand adaugam noi replici, pentru volumul de tip <b>emptyDir</b> este per pod!

                    <br/>
                    <br/>

                    O posibila rezolvare la problema de mai sus e folosirea altui tip de volum, de exemplu: <b>hostPath</b>.
                    Acest tip de volum stocheaza datele pe masina host (deci, cumva e per node, nu per pod).
                    <br/>
                    Dar, va exista problema de persistare daca exista mai multe noduri pe masini diferite.
                    <br/>
                    Observatie: <b>hostPath</b> functioneaza ok doar intr-un mediu cu 1 singur nod (minikube).
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: story-deployment\n' +
                            'spec: \n' +
                            '  replicas: 2\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: story\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: story\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:1\n' +
                            '          volumeMounts:\n' +
                            '            - mountPath: /app/story\n' +
                            '              name: story-volume\n' +
                            '      volumes:\n' +
                            '        - name: story-volume\n' +
                            '          hostPath:\n' +
                            '            path: /data\n' +
                            '            type: DirectoryOrCreate'}
                    </SyntaxHighlighter>
                    Daca exista 2 noduri de exemplu, va fi creat doar pe unul dintre noduri! Si nici macar nu vom stii pe care dintre noduri a fost creat.
                    Pentru ca gestiunea este facuta de K8s.
                    <br/>
                    Chiar daca ar fi creat pe fiecare nod, ar avea date diferite pentru fiecare nod in parte.

                    <hr/>
                    <b>1.2. Persistent Volume</b>
                    <br/>
                    <br/>

                    Persistent Volume e in afara unui pod (este independent de pod si nod).

                    <br/>
                    Exemplu (se defineste un obiect de tip <b>PersistentVolume</b>, volum de tip <b>hostPath</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolume\n' +
                            'metadata:\n' +
                            '  name: host-pv\n' +
                            'spec:\n' +
                            '  capacity: \n' +
                            '    storage: 1Gi\n' +
                            '  volumeMode: Filesystem\n' +
                            '  storageClassName: standard\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  hostPath:\n' +
                            '    path: /data\n' +
                            '    type: DirectoryOrCreate'}
                    </SyntaxHighlighter>
                    Tipuri de Access Mode:
                    <ul>
                        <li>ReadWriteOnce: volumul poate fi montat ca citire-scriere de un singur nod. Modul de acces ReadWriteOnce încă poate permite mai multor pod-uri să acceseze volumul atunci când pod-urile rulează pe același nod</li>
                        <li>ReadOnlyMany: volumul poate fi montat doar pentru citire de mai multe noduri</li>
                        <li>ReadWriteMany: volumul poate fi montat ca citire-scriere de mai multe noduri.</li>
                    </ul>

                    <hr/>
                    <b>1.3. Persistent Volume Claim</b>
                    <br/>
                    <br/>

                    Reprezinta publicarea optiunilor de persistent volume (static/dinamic).

                    <br/>
                    Pentru a publica optiuni volume persistente se folosesc obiecte de tip <b>PersistentVolumeClaim</b> in fisiere de configurare.

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  name: database-persistent-volume-claim\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 2Gi'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: v1\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'metadata:\n' +
                            '  name: host-pvc\n' +
                            'spec:\n' +
                            '  volumeName: host-pv\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  storageClassName: standard\n' +
                            '  resources:\n' +
                            '    requests: \n' +
                            '      storage: 1Gi'}
                    </SyntaxHighlighter>

                    Access Modes:
                    <ul>
                        <li>
                            <b>ReadWriteOnce</b>: poate fi utilizat de un singur nod
                        </li>
                        <li>
                            <b>ReadOnlyMany</b>: poate fi citit de mai multe noduri
                        </li>
                        <li>
                            <b>ReadWriteMany</b>: poate fi citit/scris de mai multe noduri
                        </li>
                    </ul>

                    Pentru a afla mai multe detalii despre stocare:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get storageclass'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                 PROVISIONER                RECLAIMPOLICY   VOLUMEBINDINGMODE   ALLOWVOLUMEEXPANSION   AGE\n' +
                            'standard (default)   k8s.io/minikube-hostpath   Delete          Immediate           false                  4d23h'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe storageclass'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Name:            standard\n' +
                            'IsDefaultClass:  Yes\n' +
                            'Annotations:     kubectl.kubernetes.io/last-applied-configuration={"apiVersion":"storage.k8s.io/v1","kind":"StorageClass","metadata":{"annotations":{"storageclass.kubernetes.io/is-default-class":"true"},"labels":{"addonmanager.kubernetes.io/mode":"EnsureExists"},"name":"standard"},"provisioner":"k8s.io/minikube-hostpath"}\n' +
                            ',storageclass.kubernetes.io/is-default-class=true\n' +
                            'Provisioner:           k8s.io/minikube-hostpath\n' +
                            'Parameters:            <none>\n' +
                            'AllowVolumeExpansion:  <unset>\n' +
                            'MountOptions:          <none>\n' +
                            'ReclaimPolicy:         Delete\n' +
                            'VolumeBindingMode:     Immediate\n' +
                            'Events:                <none>'}
                    </SyntaxHighlighter>

                    In mod <i>standard</i> se aloca spatiu pe hard-disk-ul calculatorului.
                    <br/>
                    In cloud se foloseste un <b>Cloud Provider</b>:
                    <ul>
                        <li>
                            Google Cloud Persistent Disk
                        </li>
                        <li>
                            Azure File
                        </li>
                        <li>
                            Azure Disk
                        </li>
                        <li>
                            AWS Block Store
                        </li>
                    </ul>
                    In fisierul de configurare, se specifica providerul prin atributul <b>storageClassName</b>.
                    <br/>
                    Mai multe detalii:
                    <a target={"_blank"} href={"https://kubernetes.io/docs/concepts/storage/storage-classes/"}>
                        https://kubernetes.io/docs/concepts/storage/storage-classes/
                    </a>
                    <br/>

                    Afisare persistent volume:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pv'}
                    </SyntaxHighlighter>

                    Afisare persistent volume claim:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get pvc'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu de folosire intr-un obiect de tip <b>Deployement</b> a unui <b>persistentVolumeClaim</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'apiVersion: apps/v1\n' +
                            'kind: Deployment\n' +
                            'metadata:\n' +
                            '  name: story-deployment\n' +
                            'spec: \n' +
                            '  replicas: 2\n' +
                            '  selector:\n' +
                            '    matchLabels:\n' +
                            '      app: story\n' +
                            '  template:\n' +
                            '    metadata:\n' +
                            '      labels:\n' +
                            '        app: story\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: story\n' +
                            '          image: iulianbuzdugan/kub-data-demo:1\n' +
                            '          volumeMounts:\n' +
                            '            - mountPath: /app/story\n' +
                            '              name: story-volume\n' +
                            '      volumes:\n' +
                            '        - name: story-volume\n' +
                            '          persistentVolumeClaim:\n' +
                            '            claimName: host-pvc'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exercitii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'alias k=kubectl # alias pentru comanda kubectl\n' +
                            'k get pods\n' +
                            'k exec webapp -- cat /log/app.log # vizualizare log-uri\n' +
                            'k describe pod webapp\n' +
                            '\n' +
                            'k edit pod webapp\n' +
                            'sau\n' +
                            'k get po webapp -o yaml > webapp.yaml\n' +
                            '\n' +
                            'k replace --force -f [nume_fisier.yaml]\n' +
                            'sau\n' +
                            'kubectl replace -f webapp.yaml --force\n' +
                            '\n' +
                            'kubectl delete po webapp\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: webapp\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: event-simulator\n' +
                            '    image: kodekloud/event-simulator\n' +
                            '    env:\n' +
                            '    - name: LOG_HANDLERS\n' +
                            '      value: file\n' +
                            '    volumeMounts:\n' +
                            '    - mountPath: /log\n' +
                            '      name: log-volume\n' +
                            '\n' +
                            '  volumes:\n' +
                            '  - name: log-volume\n' +
                            '    hostPath:\n' +
                            '      # directory location on host\n' +
                            '      path: /var/log/webapp\n' +
                            '      # this field is optional\n' +
                            '      type: Directory\n' +
                            '\t  \n' +
                            'vi pv.yaml\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: PersistentVolume\n' +
                            'metadata:\n' +
                            '  name: pv-log\n' +
                            'spec:\n' +
                            '  persistentVolumeReclaimPolicy: Retain\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteMany\n' +
                            '  capacity:\n' +
                            '    storage: 100Mi\n' +
                            '  hostPath:\n' +
                            '    path: /pv/log\n' +
                            '\t\n' +
                            'k create -f pv.yaml\n' +
                            '\n' +
                            'kind: PersistentVolumeClaim\n' +
                            'apiVersion: v1\n' +
                            'metadata:\n' +
                            '  name: claim-log-1\n' +
                            'spec:\n' +
                            '  accessModes:\n' +
                            '    - ReadWriteOnce\n' +
                            '  resources:\n' +
                            '    requests:\n' +
                            '      storage: 50Mi\n' +
                            '\t  \n' +
                            'k get pv\n' +
                            '\n' +
                            'vi pvc.yaml\n' +
                            'k create -f pvc.yaml\n' +
                            '\n' +
                            'k replace --force -f pvc.yaml \n' +
                            '\n' +
                            'k delete pvc claim-log-1\n' +
                            'k get pvc\n' +
                            'k describe pvc claim-log-1\n' +
                            '\n' +
                            'k delete pod webapp\n' +
                            'k get pvc\n' +
                            'k get pv\n' +
                            '\n' +
                            'apiVersion: v1\n' +
                            'kind: Pod\n' +
                            'metadata:\n' +
                            '  name: webapp\n' +
                            'spec:\n' +
                            '  containers:\n' +
                            '  - name: event-simulator\n' +
                            '    image: kodekloud/event-simulator\n' +
                            '    env:\n' +
                            '    - name: LOG_HANDLERS\n' +
                            '      value: file\n' +
                            '    volumeMounts:\n' +
                            '    - mountPath: /log\n' +
                            '      name: log-volume\n' +
                            '\n' +
                            '  volumes:\n' +
                            '  - name: log-volume\n' +
                            '    persistentVolumeClaim:\n' +
                            '      claimName: claim-log-1'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Volumek8sContent;