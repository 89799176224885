import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../java-spring/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WiringBeansContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-wiring-beans", IndexContent);
    }

    render() {


        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    Relatiile intre bean-uri se poate realiza in doua moduri:
                    <ul>
                        <li><b>wiring</b>: cand bean-urile se leaga apelând direct metodele care le creează (între metodele adnotate cu <b>@Bean</b>)</li>
                        <li><b>auto-wiring</b>: cand Spring furnizeaza o valoare folosindu-se de parametrii de metoda (metode adnotate cu <b>@Bean</b>)</li>
                        <li><b>dependecy injection (DI)</b>: cand se foloseste adnotarea <b>@Autowired</b></li>
                    </ul>

                    <hr/>
                    <b>1. Wiring folosind <i>apelul direct</i> al metodei între metodele adnotate cu @Bean</b>
                    <br/>
                    <br/>
                    Pentru a face acest lucru trebuie:
                    <ul>
                        <li>In <b>pom.xml</b> sa avem dependinta:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<dependency>\n' +
                                '   <groupId>org.springframework</groupId>\n' +
                                '   <artifactId>spring-context</artifactId>\n' +
                                '   <version>5.3.10</version>\n' +
                                '</dependency>'}
                            </SyntaxHighlighter>

                        </li>

                    </ul>

                    Definim bean-ul <i>Poet</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.model;\n' +
                        '\n' +
                        'public class Poet {\n' +
                        '\n' +
                        '    private String nume;\n' +
                        '\n' +
                        '    public String getNume() {\n' +
                        '        return nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setNume(String nume) {\n' +
                        '        this.nume = nume;\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Definim bean-ul <i>Poem</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.model;\n' +
                        '\n' +
                        'public class Poem {\n' +
                        '\n' +
                        '    private String titlu;\n' +
                        '\n' +
                        '    private Poet poet;\n' +
                        '\n' +
                        '    public String getTitlu() {\n' +
                        '        return titlu;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setTitlu(String titlu) {\n' +
                        '        this.titlu = titlu;\n' +
                        '    }\n' +
                        '\n' +
                        '    public Poet getPoet() {\n' +
                        '        return poet;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setPoet(Poet poet) {\n' +
                        '        this.poet = poet;\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Adaugare bean-uri in context si creare legatura intre ele:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.Bean;\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        'import ro.ibedaria.spring.context.model.Poem;\n' +
                        'import ro.ibedaria.spring.context.model.Poet;\n' +
                        '\n' +
                        '@Configuration\n' +
                        'public class AppConfig {\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public Poet poet(){\n' +
                        '        Poet poet = new Poet();\n' +
                        '        poet.setNume("Nichita Stanescu");\n' +
                        '        return poet;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public Poem poem(){\n' +
                        '        Poem poem = new Poem();\n' +
                        '        poem.setTitlu("Lectia despre patrat");\n' +
                        '        poem.setPoet(poet());\n' +
                        '        return poem;\n' +
                        '    }\n' +
                        '\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Test:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.AnnotationConfigApplicationContext;\n' +
                        'import ro.ibedaria.spring.context.config.AppConfig;\n' +
                        'import ro.ibedaria.spring.context.model.Melc;\n' +
                        'import ro.ibedaria.spring.context.model.Poem;\n' +
                        '\n' +
                        'public class SpringContextApp {\n' +
                        '\n' +
                        '    public static void main(String args[]){\n' +
                        '\n' +
                        '        AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(AppConfig.class);\n' +
                        '\n' +
                        '        Poem poem = context.getBean( Poem.class);\n' +
                        '        System.out.println(poem.getTitlu() +" de " + poem.getPoet().getNume()); //Lectia despre patrat de Nichita Stanescu\n' +
                        '        \n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Rezultatul este o relatie de tipul <b>has-A</b> (poezia are un poet, in sensul ca apartine unui poet) intre doua bean-uri.

                    <br/>
                    <br/>
                    <b>Observatie:</b>

                    <ul>
                        <li>In exemplu de mai sus, se creeaza un singur obiect de tip <i>Poet</i>. Cand se apleeza metoda <i>poet()</i> (adnotata cu @Bean) din metoda <i>poem()</i> (adnotata cu @Bean):

                            <ul>
                                <li>
                                    daca bean-ul <i>poet</i> exista in context, Spring returneaza bean-ul existent, fara sa mai apeleze efectiv metoda <i>poet()</i>
                                </li>
                                <li>
                                    daca bean-ul <i>poet</i> nu exista in context, Spring creaza bean-ul (adica apeleaza metoda <i>poet()</i>) si returneaza valorea returnata de metoda
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Wiring folosind <i>parametrii</i> metodelelor adnotate cu @Bean</b>
                    <br/>
                    <br/>

                    Aceasta abordare este mai flexibila (decat folosind apelul direct al metodei) pentru ca bean-ul referit poate fi definit fie:
                    <ul>
                        <li>prin adnotarea @Bean</li>
                        <li>folosind o adnotare stereotip</li>
                    </ul>

                    Avand in vedere ca sigurele modificari sunt in zona de adaugare bean-uri in context si creare legatura intre ele, vom prezenta doar clasa de configurare:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.Bean;\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        'import ro.ibedaria.spring.context.model.Poem;\n' +
                        'import ro.ibedaria.spring.context.model.Poet;\n' +
                        '\n' +
                        '@Configuration\n' +
                        'public class AppConfig {\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public Poet poet(){\n' +
                        '        Poet poet = new Poet();\n' +
                        '        poet.setNume("Nichita Stanescu");\n' +
                        '        return poet;\n' +
                        '    }\n' +
                        '\n' +
                        '    @Bean\n' +
                        '    public Poem poem(Poet poet){ // Spring injecteaza bean-ul poet in acest parametru\n' +
                        '        Poem poem = new Poem();\n' +
                        '        poem.setTitlu("Lectia despre patrat");\n' +
                        '        poem.setPoet(poet);\n' +
                        '        return poem;\n' +
                        '    }\n' +
                        '\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    DI este o tehnica de setare a unei valori (de catre framework) intr-un parametru.
                    <br/>
                    DI este o aplicare a principiului IoC (IoC presounea ca framework-ul controleaza excutia aplicatiei).

                    <hr/>
                    <b>3. Injectarea bean-urilor folosind adnotarea @Autowired</b>
                    <br/>
                    <br/>

                    Exista 3 moduri de folosire a adnotarii @Autowired:
                    <ul>
                        <li>injectarea valorii intr-un camp al clasei
                            <br/>
                            de evitat in codul de productie pentru ca:
                            <ul>
                                <li>nu exista optiunea de a marca campul ca fiind <b>final</b></li>
                                <li>este mai dificil de gestionat valoarea la initializare</li>
                            </ul>
                        </li>
                        <li>injectarea valorii prin intermediul parametrilor contructorului (recomandat)
                            <br/>
                            incepand cu versiunea 4.3, daca exista un sigur constructor in clasa, atunci se poate omite adnotarea <b>@Autowired</b> pe constructor
                            <br/>
                            setter-ul daca campul injectat este marcat cu final, nu are sens
                            <br/>
                            permite crearea de instante imutabile, pentru ca exista optiunea de a marca campul ca fiind <b>final</b>
                        </li>
                        <li>injectarea valorii prin intermediul setter-ului
                            <br/>
                            de evitat pentru ca:
                            <ul>
                                <li>nu exista optiunea de a marca campul ca fiind <b>final</b></li>
                                <li>ingreuneaza scrierea testelor</li>
                            </ul>
                        </li>
                    </ul>

                    Pentru a face acest lucru trebuie:
                    <ul>
                        <li>In <b>pom.xml</b> sa avem dependinta:

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<dependency>\n' +
                                '   <groupId>org.springframework</groupId>\n' +
                                '   <artifactId>spring-context</artifactId>\n' +
                                '   <version>5.3.10</version>\n' +
                                '</dependency>'}
                            </SyntaxHighlighter>

                        </li>

                    </ul>

                    Definita clasa de configurare (adnotata cu <b>@Configuration)</b>. Acesta clasa o vom adnota si cu <b>@ComponentScan</b> pentru a specifica unde sa caute clasele sterotip:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.config;\n' +
                        '\n' +
                        'import org.springframework.context.annotation.ComponentScan;\n' +
                        'import org.springframework.context.annotation.Configuration;\n' +
                        '\n' +
                        '@Configuration\n' +
                        '@ComponentScan(basePackages = "ro.ibedaria")\n' +
                        'public class AppConfig {\n' +
                        '    \n' +
                        '}'}
                    </SyntaxHighlighter>


                    Definim bean-ul <i>Poet</i>, adnotat cu un stereotip (de exemplu: @Component):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibedaria.spring.context.model;\n' +
                        '\n' +
                        'import org.springframework.stereotype.Component;\n' +
                        '\n' +
                        '@Component\n' +
                        'public class Poet {\n' +
                        '\n' +
                        '    private String nume;\n' +
                        '\n' +
                        '    public String getNume() {\n' +
                        '        return nume;\n' +
                        '    }\n' +
                        '\n' +
                        '    public void setNume(String nume) {\n' +
                        '        this.nume = nume;\n' +
                        '    }\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Definim bean-ul <i>Poem</i>, adnotat cu un stereotip (de exemplu: @Component), folosind:
                    <ul>
                        <li>injectare camp (avertisment IntelliJ: <i>Field injection is not recommended</i>):

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.context.model;\n' +
                                '\n' +
                                'import org.springframework.beans.factory.annotation.Autowired;\n' +
                                'import org.springframework.stereotype.Component;\n' +
                                '\n' +
                                '@Component\n' +
                                'public class Poem {\n' +
                                '\n' +
                                '    private String titlu;\n' +
                                '\n' +
                                '    @Autowired // injectare camp \n' +
                                '    private Poet poet;\n' +
                                '\n' +
                                '    public String getTitlu() {\n' +
                                '        return titlu;\n' +
                                '    }\n' +
                                '\n' +
                                '    public void setTitlu(String titlu) {\n' +
                                '        this.titlu = titlu;\n' +
                                '    }\n' +
                                '\n' +
                                '    public Poet getPoet() {\n' +
                                '        return poet;\n' +
                                '    }\n' +
                                '\n' +
                                '    public void setPoet(Poet poet) {\n' +
                                '        this.poet = poet;\n' +
                                '    }\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>injectare prin intermediul constructorului (incepand cu versiunea 4.3, daca exista un sigur constructor in clasa, atunci se poate omite adnotarea <b>@Autowired</b> pe constructor):

                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.context.model;\n' +
                                '\n' +
                                'import org.springframework.beans.factory.annotation.Autowired;\n' +
                                'import org.springframework.stereotype.Component;\n' +
                                '\n' +
                                '@Component\n' +
                                'public class Poem {\n' +
                                '\n' +
                                '    private String titlu;\n' +
                                '    private final Poet poet; // obiectul poet e marcat ca fiind final\n' +
                                '\n' +
                                '    @Autowired // injectare prin intermediul contructorului\n' +
                                '    public Poem(Poet poet){ \n' +
                                '        this.poet = poet;\n' +
                                '    }\n' +
                                '\n' +
                                '    public String getTitlu() {\n' +
                                '        return titlu;\n' +
                                '    }\n' +
                                '\n' +
                                '    public void setTitlu(String titlu) {\n' +
                                '        this.titlu = titlu;\n' +
                                '    }\n' +
                                '\n' +
                                '    public Poet getPoet() {\n' +
                                '        return poet;\n' +
                                '    }\n' +
                                '}\n'}
                            </SyntaxHighlighter>

                        </li>

                        <li>injectare prin intermediul setter-ului:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'package ro.ibedaria.spring.context.model;\n' +
                                '\n' +
                                'import org.springframework.beans.factory.annotation.Autowired;\n' +
                                'import org.springframework.stereotype.Component;\n' +
                                '\n' +
                                '@Component\n' +
                                'public class Poem {\n' +
                                '\n' +
                                '    private String titlu;\n' +
                                '\n' +
                                '    private Poet poet;\n' +
                                '\n' +
                                '    public String getTitlu() {\n' +
                                '        return titlu;\n' +
                                '    }\n' +
                                '\n' +
                                '    public void setTitlu(String titlu) {\n' +
                                '        this.titlu = titlu;\n' +
                                '    }\n' +
                                '\n' +
                                '    public Poet getPoet() {\n' +
                                '        return poet;\n' +
                                '    }\n' +
                                '\n' +
                                '    @Autowired // injectare prin intermediul setter-ului\n' +
                                '    public void setPoet(Poet poet) {\n' +
                                '        this.poet = poet;\n' +
                                '    }\n' +
                                '}\n'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <hr/>
                    <b>Observatii</b>
                    <ul>
                        <li>Bean-uile definite in clasa de configurare (adnotata cu <b>@Configuration</b>) <b>suprascriu</b> bean-urile definite prin intermediul adnotarilor de stereotip</li>
                        <li>trebuie evitate dependintele/referintelele circulare</li>
                        <li>cand trebuie injectata o valoare intr-un parametru sau camp de clasa si exista mai multe bean-uri de acelasi tip, atunci Spring alege:
                            <ul>
                                <li><b>bean-ul cu numele identic cu numele parametrului</b></li>
                                <li>
                                    daca nu exista, atunci:
                                    <ul>
                                        <li>bean-ul marcat ca fiind primar (<b>@Primary</b>)</li>
                                        <li>bean-ul declarat prin intermediul adnotarii <b>@Qualifer</b> (recomandat pentru ca este: vizibil, mai putin predispus la probleme in urma refactorizarii codului)

                                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                                {'@Bean\n' +
                                                'public Poem poem(@Qualifier("poet") Poet poet){ // Spring injecteaza bean-ul poet in acest parametru\n' +
                                                '    Poem poem = new Poem();\n' +
                                                '    poem.setTitlu("Lectia despre patrat");\n' +
                                                '    poem.setPoet(poet);\n' +
                                                '    return poem;\n' +
                                                '}'}
                                            </SyntaxHighlighter>

                                            sau:

                                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                                {'@Autowired // injectare prin intermediul contructorului\n' +
                                                'public Poem(@Qualifier("poet") Poet poet){\n' +
                                                '    this.poet = poet;\n' +
                                                '}'}
                                            </SyntaxHighlighter>
                                        </li>
                                    </ul>
                                </li>
                               <li>
                                  altfel, se arunca eroare
                               </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>Spring Start Here - Laurentiu Spilca (Manning, 2021)</div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default WiringBeansContent;