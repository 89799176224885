import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../laravel/IndexContent";

class LoginLaravelContent extends BaseContentPage  {

    constructor(props) {
        super(props, "laravel-login", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Login</b>
                    <br/>
                    <br/>


                    Pentru a verificca daca utilizatorul curent este autentificat, se foloseste metoda <b>Auth::check()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'use Illuminate\\Support\\Facades\\Auth;\n' +
                            ' \n' +
                            'if (Auth::check()) {\n' +
                            '    // The user is logged in...\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Autentificarea/logarea se face folosind metoda <b>Auth::attempt()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            ' \n' +
                            'namespace App\\Http\\Controllers;\n' +
                            ' \n' +
                            'use Illuminate\\Http\\Request;\n' +
                            'use Illuminate\\Http\\RedirectResponse;\n' +
                            'use Illuminate\\Support\\Facades\\Auth;\n' +
                            ' \n' +
                            'class LoginController extends Controller\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Handle an authentication attempt.\n' +
                            '     */\n' +
                            '    public function authenticate(Request $request): RedirectResponse\n' +
                            '    {\n' +
                            '        $credentials = $request->validate([\n' +
                            '            \'email\' => [\'required\', \'email\'],\n' +
                            '            \'password\' => [\'required\'],\n' +
                            '        ]);\n' +
                            ' \n' +
                            '        if (Auth::attempt($credentials)) {\n' +
                            '            $request->session()->regenerate();\n' +
                            ' \n' +
                            '            return redirect()->intended(\'dashboard\');\n' +
                            '        }\n' +
                            ' \n' +
                            '        return back()->withErrors([\n' +
                            '            \'email\' => \'The provided credentials do not match our records.\',\n' +
                            '        ])->onlyInput(\'email\');\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Se pot specifica conditii suplimentare:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'if (Auth::attempt([\'email\' => $email, \'password\' => $password, \'active\' => 1])) {\n' +
                            '    // Authentication was successful...\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sa presupunem ca in <b>/app/Http/Controllers</b> avem AuthController.php:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Http\\Controllers;\n' +
                            '\n' +
                            'use App\\Http\\Requests\\RegisterRequest;\n' +
                            'use App\\User;\n' +
                            'use Illuminate\\Http\\Request;\n' +
                            'use Illuminate\\Support\\Facades\\Auth;\n' +
                            'use Illuminate\\Support\\Facades\\Hash;\n' +
                            'use Symfony\\Component\\HttpFoundation\\Response;\n' +
                            '\n' +
                            'class AuthController extends Controller\n' +
                            '{\n' +
                            '    public function login(Request $request)\n' +
                            '    {\n' +
                            '        if (Auth::attempt($request->only(\'email\', \'password\'))) {\n' +
                            '            $user = Auth::user();\n' +
                            '\n' +
                            '            $token = $user->createToken(\'admin\')->accessToken;\n' +
                            '\n' +
                            '            return [\n' +
                            '                \'token\' => $token,\n' +
                            '            ];\n' +
                            '        }\n' +
                            '\n' +
                            '        return response([\n' +
                            '            \'error\' => \'Invalid Credentials!\',\n' +
                            '        ], Response::HTTP_UNAUTHORIZED);\n' +
                            '    }\n' +
                            '\n' +
                            '    public function register(RegisterRequest $request)\n' +
                            '    {\n' +
                            '        $user = User::create(\n' +
                            '            $request->only(\'first_name\', \'last_name\', \'email\')\n' +
                            '            + [\n' +
                            '                \'password\' => Hash::make($request->input(\'password\')),\n' +
                            '                \'is_admin\' => 1,\n' +
                            '            ]\n' +
                            '        );\n' +
                            '\n' +
                            '        return response($user, Response::HTTP_CREATED);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru a genera <b>RegisterRequest</b> in <b>/app/Http/Requests</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"php artisan make:request RegisterRequest"}
                    </SyntaxHighlighter>

                    Vom porni de la schetul generat si vom completa metoda <b>rules()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Http\\Requests;\n' +
                            '\n' +
                            'use Illuminate\\Foundation\\Http\\FormRequest;\n' +
                            '\n' +
                            'class RegisterRequest extends FormRequest\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Determine if the user is authorized to make this request.\n' +
                            '     *\n' +
                            '     * @return bool\n' +
                            '     */\n' +
                            '    public function authorize()\n' +
                            '    {\n' +
                            '        return true;\n' +
                            '    }\n' +
                            '\n' +
                            '    /**\n' +
                            '     * Get the validation rules that apply to the request.\n' +
                            '     *\n' +
                            '     * @return array\n' +
                            '     */\n' +
                            '    public function rules()\n' +
                            '    {\n' +
                            '        return [\n' +
                            '            \'first_name\' => \'required\',\n' +
                            '            \'last_name\' => \'required\',\n' +
                            '            \'email\' => \'required|email\',\n' +
                            '            \'password\' => \'required\',\n' +
                            '            \'password_confirm\' => \'required|same:password\',\n' +
                            '        ];\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru generarea de token se utilizeaza <b><a target={"_blank"} href={"https://laravel.com/docs/10.x/sanctum"}>Laravel Sanctum</a></b>.
                    <br/>
                    Pentru a instala <b>laravel/sanctum</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"composer require laravel/sanctum"}
                    </SyntaxHighlighter>
                    Apoi, trebuie publicat fișierele de configurare și migrare Sanctum folosind comanda <b>vendor:publish</b> Artisan.
                    Fișierul de configurare va fi plasat în directorul sanctumaplicației dvs .:config:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'php artisan vendor:publish --provider="Laravel\\Sanctum\\SanctumServiceProvider"'}
                    </SyntaxHighlighter>
                    Pentru a finaliza integrarea cu <b>Laravel Sanctum</b>, trebuie sa rulam migrarea bazei de date
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"php artisan migrate"}
                    </SyntaxHighlighter>

                    În continuare, dacă intenționați să utilizați Sanctum pentru a autentifica un SPA,
                    ar trebui să adăugați middleware-ul Sanctum la grupul de middleware <b>api</b> din <b>app/Http/Kernel.php</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"'api' => [\n" +
                            "    \\Laravel\\Sanctum\\Http\\Middleware\\EnsureFrontendRequestsAreStateful::class,\n" +
                            "    \\Illuminate\\Routing\\Middleware\\ThrottleRequests::class.':api',\n" +
                            "    \\Illuminate\\Routing\\Middleware\\SubstituteBindings::class,\n" +
                            "],"}
                    </SyntaxHighlighter>

                    Apoi trebuie adaugat <b>HasApiTokens</b> pentru clasa <b>User</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'use Laravel\\Sanctum\\HasApiTokens;\n' +
                            ' \n' +
                            'class User extends Authenticatable\n' +
                            '{\n' +
                            '    use HasApiTokens, HasFactory, Notifiable;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Generarea de token cu numele 'admin':
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'$token = $user->createToken(\'admin\')->accessToken;'}
                    </SyntaxHighlighter>

                    sau, generarea de token cu numele 'token':
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'$jwtToken = $user->createToken(\'token\')->planTextToken;'}
                    </SyntaxHighlighter>

                    Putem pune acest token intr-un cookie:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'$cookie = cookie(\'jwt\', $jwtToken, 60*24)  // 1 zi'}
                    </SyntaxHighlighter>

                    Putem trimite acest cookie cu raspundul dat clientului:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'return response([ "message"=> "success"])->withCookie($cookie); '}
                    </SyntaxHighlighter>

                    In plus, in <b>config/cors.php</b>, trebuie modificat:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'\'supports_credentials\' => true,'}
                    </SyntaxHighlighter>

                    <hr/>

                    In <b>/routes/api.php</b> putem defini ruta:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"Route::prefix('admin')->group(function(){\n" +
                            "  Route::post('register', [AuthController::class, 'register'])\n" +
                            "  Route::post('login', [AuthController::class, 'login'])\n" +
                            "})"}
                    </SyntaxHighlighter>
                    Deci vom avea putea accesa urmatoarele cai:
                    <ul>
                        <li>/admin/register {"->"} apleaza metoda 'register()' din clasa AuthController</li>
                        <li>/admin/login {"->"} apleaza metoda 'login()' din clasa AuthController</li>
                    </ul>

                    <hr/>
                    <b>Utilizator autentificat prin cookie</b>
                    <br/>
                    <br/>
                    In clasa AuthController, se adauga metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'    public function user(Request $request)\n' +
                            '    {\n' +
                            '        return $request->user;\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    Apoi, in <b>/routes/api.php</b> putem defini ruta pentru admin/user, care poate fi accesata, doar daca utilizatorul este autentificat:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {"Route::prefix('admin')->group(function(){\n" +
                            "  Route::post('register', [AuthController::class, 'register'])\n" +
                            "  Route::post('login', [AuthController::class, 'login'])\n" +
                            "  Route::middleware(['auth:sanctum'])->group(){\n" +
                            "       Route::get('user', [AuthController::class, 'user'])\n" +
                            "  });\n" +
                            "})"}
                    </SyntaxHighlighter>
                    Pentru a functiona cererea (in header) trebuie:
                    <ul>
                        <li>Accept: application/json</li>
                        <li>Authorization: Bearer [token]</li>
                    </ul>

                    Pentru a evita sa punem token-ul in header, ci sa-l ia din cookie, trebuie sa modificam in app/Http/Middleware:
                    <SyntaxHighlighter showLineNumbers={true} language="php" style={androidstudio}>
                        {'<?php\n' +
                            '\n' +
                            'namespace App\\Http\\Middleware;\n' +
                            '\n' +
                            'use Illuminate\\Auth\\Middleware\\Authenticate as Middleware;\n' +
                            '\n' +
                            'class Authenticate extends Middleware\n' +
                            '{\n' +
                            '    /**\n' +
                            '     * Get the path the user should be redirected to when they are not authenticated.\n' +
                            '     *\n' +
                            '     * @param  \\Illuminate\\Http\\Request  $request\n' +
                            '     * @return string|null\n' +
                            '     */\n' +
                            '    protected function redirectTo($request)\n' +
                            '    {\n' +
                            '        if (! $request->expectsJson()) {\n' +
                            '            return route(\'login\');\n' +
                            '        }\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    adaugand metoda:
                    <SyntaxHighlighter  showLineNumbers={true} language="php" style={androidstudio}>
                        {'public function handle($request, Closure $next, ...$guards)\n' +
                            '{\n' +
                            '   if ($jwt = $request->cookie(\'jwt\')) {\n' +
                            '       $request->headers->set(\'Authorization\', \'Bearer \'.$jwt\')\n' +
                            '   }\n' +
                            '   $this->authenticate($request, $guards);\n' +
                            '\n' +
                            '   return $next($request);\n' +
                            '}'}
                    </SyntaxHighlighter>
                    cu
                    <SyntaxHighlighter  showLineNumbers={true} language="php" style={androidstudio}>
                        {'use Closure'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LoginLaravelContent;