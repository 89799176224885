import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ExceptionAdvancedPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-exception-advanced", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Atunci cand se creeaza o exceptie proprie, atunci aceasta trebuie sa exista <b>BaseException</b> sau o <b>subclasa a acesteia</b>.

                    <hr/>

                    <b>1. Atributele unui obiect de tip exceptie</b>
                    <br/>
                    <br/>

                    Clauza <b>except</b> permite specificarea unei veriabile dupa numele exceptiei:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'except Exception as variabila'}
                    </SyntaxHighlighter>

                    Această variabilă este legată de o instanță de excepție si poate avea urmatoarele atribute:
                    <ul>
                        <li>
                            <b>args</b>: stocheaza argumentele instantei de exceptie
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    raise Exception("test","10/20/2021")\n' +
                        '\n' +
                        'except Exception as e:\n' +
                        '    print(e.args) # (\'test\', \'10/20/2021\')\n'}
                    </SyntaxHighlighter>


                    Un obiect de tip <b>ImportError</b> poate avea in plus urmatoarele atribute:
                    <ul>
                        <li>
                            <b>name</b>: numele modulului care s-a incercat importat
                        </li>
                        <li>
                            <b>path</b>: calea către fișierul care a declanșat excepția ( poate fi None)
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    import ab123\n' +
                        '\n' +
                        'except ImportError as e:\n' +
                        '    print(e.args) # ("No module named \'ab123\'",)\n' +
                        '    print(e.name) # ab123\n' +
                        '    print(e.path) # None'}
                    </SyntaxHighlighter>

                    Excepția <b>UnicodeError</b> este declanșată atunci când apare o eroare de codificare sau decodare legată de Unicode. Este o subclasă a ValueError.
                    Atribute:
                    <ul>
                        <li><b>encoding</b>: numele codificării care a generat eroarea</li>
                        <li><b>reason</b>: șir care descrie eroarea de codec specifica</li>
                        <li><b>object</b>: obiectul pe care codecul a încercat să îl codifice sau să îl decodeze</li>
                        <li><b>start</b>: primul index de date invalid din obiect</li>
                        <li><b>end</b>: indexul după ultimele date invalide din obiect.</li>
                    </ul>

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'try:\n' +
                        '    b\'\x801\'.decode("utf-8")\n' +
                        'except UnicodeError as e:\n' +
                        '    print(e) # \'utf-8\' codec can\'t decode byte 0x80 in position 0: invalid start byte\n' +
                        '    print(e.encoding) # utf-8\n' +
                        '    print(e.reason) # invalid start byte\n' +
                        '    print(e.object) # b\'\x801\'\n' +
                        '    print(e.start)  # 0\n' +
                        '    print(e.end)    # 1'}
                    </SyntaxHighlighter>



                    <hr/>

                    <b>2. Exceptii inlantuite</b>
                    <br/>
                    <br/>

                    Exceptiile inlantuite vin cu urmatoarele atribute:
                    <ul>
                        <li><b>__context__</b>: pentru exceptiile lantuite implicite</li>
                        <li><b>__cause__</b>: pentru exceptiile lantuite explicite</li>
                    </ul>
                    Atributele de mai sus pastreaza o referinta la obiectul exceptie original pentru o procesare ulterioara.

                    <br/>
                    <br/>
                    In plus, se imbogateste sintaxa <b>raise</b> cu <b>from</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'raise Eroare from variabilaEroareSursa'}
                    </SyntaxHighlighter>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [\'a\']\n' +
                        '\n' +
                        'try:\n' +
                        '    print(lista[13]) # se arunca prima exceptie\n' +
                        'except Exception as e:\n' +
                        '    print(0 / 0) # se arunca a doua exceptie'}
                    </SyntaxHighlighter>

                    Rezultat, erori inlantuite implicit:
                    <SyntaxHighlighter>
                        {'Traceback (most recent call last):\n' +
                        '  File "main.py", line 4, in <module>\n' +
                        '    print(lista[13])\n' +
                        'IndexError: list index out of range\n' +
                        '\n' +
                        'During handling of the above exception, another exception occurred:\n' +
                        '\n' +
                        'Traceback (most recent call last):\n' +
                        '  File "main.py", line 6, in <module>\n' +
                        '    print(0 / 0)\n' +
                        'ZeroDivisionError: division by zero'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'lista = [\'a\']\n' +
                        '\n' +
                        'try:\n' +
                        '    print(lista[13]) # se arunca prima exceptie\n' +
                        'except Exception as e:\n' +
                        '    try:\n' +
                        '        print(1 / 0) # se arunca a doua exceptie\n' +
                        '    except ZeroDivisionError as f:\n' +
                        '        print(\'exceptia interioara (f):\', f) # exceptia interioara (f): division by zero\n' +
                        '        print(\'exceptie exterioara (e):\', e) # exceptie exterioara (e): list index out of range\n' +
                        '        print(\'f.__context__:\', f.__context__) # f.__context__: list index out of range\n' +
                        '        print(\'f.__context__ is e:\', f.__context__ is e) # f.__context__ is e: True'}
                    </SyntaxHighlighter>


                    Uneori, vrem sa <b>convertim</b> un tip explicit de obiect excepție într-un alt tip de obiect excepție în momentul în care are loc a doua excepție.

                    Exemplu:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class ExceptiaMea(Exception):\n' +
                        '    pass\n' +
                        '\n' +
                        'lista = [\'a\']\n' +
                        '\n' +
                        'try:\n' +
                        '    print(lista[13]) # se arunca prima exceptie\n' +
                        'except Exception as e:\n' +
                        '    raise ExceptiaMea() from e'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter>
                        {'Traceback (most recent call last):\n' +
                        '  File "main.py", line 7, in <module>\n' +
                        '    print(lista[13]) # se arunca prima exceptie\n' +
                        'IndexError: list index out of range\n' +
                        '\n' +
                        'The above exception was the direct cause of the following exception:\n' +
                        '\n' +
                        'Traceback (most recent call last):\n' +
                        '  File "main.py", line 9, in <module>\n' +
                        '    raise ExceptiaMea() from e\n' +
                        '__main__.ExceptiaMea'}
                    </SyntaxHighlighter>

                    Daca am scris instructiunea <b>raise ExceptiaMea() from e</b> asa: <b>raise ExceptiaMea()</b>, atunci eroarea ar fi fost:
                    <SyntaxHighlighter showLineNumbers={true} >
                        {'Traceback (most recent call last):\n' +
                        '  File "main.py", line 7, in <module>\n' +
                        '    print(lista[13]) # se arunca prima exceptie\n' +
                        'IndexError: list index out of range\n' +
                        '\n' +
                        'During handling of the above exception, another exception occurred:\n' +
                        '\n' +
                        'Traceback (most recent call last):\n' +
                        '  File "main.py", line 9, in <module>\n' +
                        '    raise ExceptiaMea()\n' +
                        '__main__.ExceptiaMea'}
                    </SyntaxHighlighter>

                    Textul <i>The above exception was the direct cause of the following exception</i> indica inlanutirea exceptiilor.

                    <br/>
                    <br/>

                    Exemplu 3:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'class ExceptiaMea(Exception):\n' +
                        '    pass\n' +
                        '\n' +
                        'lista = [\'a\']\n' +
                        '\n' +
                        'def functie():\n' +
                        '    lista = [\'a\']\n' +
                        '    try:\n' +
                        '        print(lista[13]) # se arunca prima exceptie\n' +
                        '    except Exception as e:\n' +
                        '        raise ExceptiaMea("Exceptie") from e\n' +
                        '\n' +
                        'try:\n' +
                        '    functie()\n' +
                        'except ExceptiaMea as e:    \n' +
                        '    print(\'Exceptie: "{}", cauzata de "{}"\'.format(e, e.__cause__)) # Exceptie: "Exceptie", cauzata de "list index out of range"'}
                    </SyntaxHighlighter>

                    Daca se sterge <b>from e</b> din <b>raise ExceptiaMea("Exceptie") from e</b>, atunci se va afisa: <i>Exceptie: "Exceptie", cauzata de "None"</i>.

                    <hr/>

                    <b>3. Traceback</b>
                    <br/> <br/>

                    Fiecare obiect excepție deține un atribut <b>__traceback__</b> care un obiect de tip <b>traceback</b>.
                    <br/>
                    Aceasta clasa permite afisarea excptiilor inlantuite, folosind:
                    <ul>
                        metoda <li>format_tb()</li>
                        functia <li>print_tb()</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import traceback\n' +
                        '\n' +
                        'class ExceptiaMea(Exception):\n' +
                        '    pass\n' +
                        '\n' +
                        'lista = [\'a\']\n' +
                        '\n' +
                        'def functie():\n' +
                        '    lista = [\'a\']\n' +
                        '    try:\n' +
                        '        print(lista[13]) # se arunca prima exceptie\n' +
                        '    except Exception as e:\n' +
                        '        raise ExceptiaMea("Exceptie") from e\n' +
                        '\n' +
                        'try:\n' +
                        '    functie()\n' +
                        'except ExceptiaMea as e:    \n' +
                        '    print(e.__traceback__) # <traceback object at 0x7f6f3eba7be0> \n' +
                        '    print(type(e.__traceback__)) # <class \'traceback\'> \n' +
                        '    detalii = traceback.format_tb(e.__traceback__)\n' +
                        '    print("-------")\n' +
                        '    print("\\n".join(detalii))\n' +
                        '    print("-------")\n' +
                        'print(\'Gata\')\n'}
                    </SyntaxHighlighter>

                    Rezultat:
                    <SyntaxHighlighter>
                        {'<traceback object at 0x7f4be741c370>\n' +
                        '<class \'traceback\'>\n' +
                        '-------\n' +
                        '  File "main.py", line 16, in <module>\n' +
                        '    functie()\n' +
                        '\n' +
                        '  File "main.py", line 13, in functie\n' +
                        '    raise ExceptiaMea("Exceptie") from e\n' +
                        '\n' +
                        '-------\n' +
                        'Gata'}
                    </SyntaxHighlighter>
                </div>


                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ExceptionAdvancedPythonContent;