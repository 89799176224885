import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex5AWSDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex5-aws", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 5 (complex) - PROD - AWS</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir complex'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd complex'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. docker-compose.yml</b>
                    <br/>
                    <br/>

                    <b>Observatie!</b>
                    <br/>
                    Nu o sa folosim <b>Dockerrun.aws.json</b>!
                    <br/>
                    Noua platformă EBS nu va mai folosi un fișier Dockerrun.aws.json pentru implementare și, în schimb, va căuta un fișier docker-compose.yml în rădăcina proiectului.

                    <br/>
                    <br/>

                    Redenumim fisierul <b>docker-compose.yml</b> in <b>docker-compose-dev.yml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'mv docker-compose.yml docker-compose-dev.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  postgres:\n' +
                            '    image: \'postgres:latest\'\n' +
                            '    environment:\n' +
                            '      - POSTGRES_PASSWORD=postgres_password\n' +
                            '  redis:\n' +
                            '    image: \'redis:latest\'\n' +
                            '  nginx:\n' +
                            '    depends_on:\n' +
                            '      - api\n' +
                            '      - client\n' +
                            '    restart: always\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./nginx\n' +
                            '    ports:\n' +
                            '      - \'3050:80\'\n' +
                            '  api:\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./server\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - ./server:/app\n' +
                            '    environment:\n' +
                            '      - REDIS_HOST=redis\n' +
                            '      - REDIS_PORT=6379\n' +
                            '      - PGUSER=postgres\n' +
                            '      - PGHOST=postgres\n' +
                            '      - PGDATABASE=postgres\n' +
                            '      - PGPASSWORD=postgres_password\n' +
                            '      - PGPORT=5432\n' +
                            '  client:\n' +
                            '    environment:\n' +
                            '      - WDS_SOCKET_PORT=0\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./client\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - ./client:/app\n' +
                            '  worker:\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./worker\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - ./worker:/app\n' +
                            '    environment:\n' +
                            '      - REDIS_HOST=redis\n' +
                            '      - REDIS_PORT=6379\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nano docker-compose.yml'}
                    </SyntaxHighlighter>

                    Fisierul <b>docker-compose.yml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'version: "3"\n' +
                            'services:\n' +
                            '  client:\n' +
                            '    image: "iulianbuzdugan/multi-client"\n' +
                            '    mem_limit: 128m\n' +
                            '    hostname: client\n' +
                            '  server:\n' +
                            '    image: "iulianbuzdugan/multi-server"\n' +
                            '    mem_limit: 128m\n' +
                            '    hostname: api\n' +
                            '    environment:\n' +
                            '      - REDIS_HOST=$REDIS_HOST\n' +
                            '      - REDIS_PORT=$REDIS_PORT\n' +
                            '      - PGUSER=$PGUSER\n' +
                            '      - PGHOST=$PGHOST\n' +
                            '      - PGDATABASE=$PGDATABASE\n' +
                            '      - PGPASSWORD=$PGPASSWORD\n' +
                            '      - PGPORT=$PGPORT\n' +
                            '  worker:\n' +
                            '    image: "iulianbuzdugan/multi-worker"\n' +
                            '    mem_limit: 128m\n' +
                            '    hostname: worker\n' +
                            '    environment:\n' +
                            '      - REDIS_HOST=$REDIS_HOST\n' +
                            '      - REDIS_PORT=$REDIS_PORT\n' +
                            '  nginx:\n' +
                            '    image: "iulianbuzdugan/multi-nginx"\n' +
                            '    mem_limit: 128m\n' +
                            '    hostname: nginx\n' +
                            '    ports:\n' +
                            '      - "80:80"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Configurare AWS</b>
                    <br/>
                    <br/>

                    Creare aplicatie/mediu
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>Elastic Beanstalk</b>.
                        </li>
                        <li>
                            Elastic Beanstalk {"->"} Environmments {"->"} Create new environment;
                        </li>
                        <li>
                            se completeaza:
                            <ul>
                                <li>Application name: multi-docker</li>
                                <li>Environmment name: Multidocker-env</li>
                                <li>Platform: Docker</li>
                                <li>Platform branch: <b>Docker running on 64bit Amazon Linux 2</b></li>
                            </ul>
                            se apasa butonul <b>Create environment</b>
                        </li>
                    </ul>

                    <hr/>
                    Crearea RDS (Relational Database Service) - Baza de date: Postgresql
                    <ul>
                        <li>
                            <li>
                                <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>RDS</b>.
                            </li>
                            <li>
                                Amazon RDS {"->"} Dashboard {"->"} <b>Create database</b>
                            </li>
                            <li>
                                se selectaza PostresSQL si versiunea PostresSQL 12.9-R1
                            </li>
                            <li>
                                in sectiunea <i>Templates</i> se selecteaza <i>Free trier</i>
                            </li>
                            <li>
                                in sectiunea <i>Settings</i>:
                                <ul>
                                    <li>
                                        DB instance identifier: multi-docker-postgres
                                    </li>
                                    <li>
                                        Master username: postgres
                                    </li>
                                    <li>
                                        Master password: postgrespassword
                                    </li>
                                </ul>
                            </li>
                            <li>
                                in sectiunea <i>Connectivity</i>:
                                <ul>
                                    <li>
                                        Virtual private cloud (VPC): Default VPC
                                    </li>
                                </ul>
                            </li>

                            <li>
                                in sectiunea <i>Additional configuration</i>:
                                <ul>
                                    <li>
                                        Initial database name: fibvalues
                                    </li>
                                </ul>
                            </li>

                            <li>
                                se apasa pe butonul <b>Create database</b>
                            </li>

                        </li>
                    </ul>

                    <hr/>
                    Creare ElastiCache Redis
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>ElastiCache</b>.
                        </li>
                        <li>
                            Amazon ElastiCache {"->"} Resources {"->"} Redis clusters
                        </li>
                        <li>
                            se apasa pe butonul <b>Create Redis cluster</b>
                        </li>
                        <li>
                            <b>Cluster Mode trbuie sa fie DISABLED</b>
                        </li>

                        <li>
                            in sectiunea <i>Cluster info</i>:
                            <ul>
                                <li>
                                    Name: multi-docker-redis
                                </li>
                            </ul>
                        </li>

                        <li>
                            in sectiunea <i>Cluster settings</i>:
                            <ul>
                                <li>
                                    Node type: cache.t2.micro
                                </li>
                                <li>
                                    Number of Replicas: 0 (se ignora mesajul <i>Multi-AZ can not be enabled when the number of replicas is set to 0. Select one or more replicas to enable Multi-AZ.</i>)
                                </li>
                            </ul>
                        </li>

                        <li>
                            in sectiunea <i>Subnet group settings</i>:
                            <ul>
                                <li>
                                    Node type: cache.t2.micro
                                </li>
                                <li>
                                    Number of Replicas: 0 (se ignora mesajul <i>Multi-AZ can not be enabled when the number of replicas is set to 0. Select one or more replicas to enable Multi-AZ.</i>)
                                </li>
                            </ul>
                        </li>

                        <li>
                            in sectiunea <i>Subnet group settings</i>:
                            <ul>
                                <li>
                                    selectam <i>Create a new subnet group</i>
                                </li>
                                <li>
                                    Name: redis-group
                                </li>
                            </ul>
                        </li>

                        <li>
                            Next
                        </li>
                        <li>
                            Next
                        </li>
                        <li>
                            se apasa pe butonul <b>Create</b>
                        </li>

                    </ul>

                    <hr/>
                    Creare Custom Security Group

                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>VPC</b>.
                        </li>
                        <li>
                            Security (din meniul din stanga) {"->"} <b>Security Groups</b>
                        </li>
                        <li>
                            se apasa pe butonul <b>Create security group</b>
                        </li>
                        <li>
                            se introduce:

                            <ul>
                                <li>
                                    Security group name: multi-docker
                                </li>
                                <li>
                                    Description: multi-docker
                                </li>
                                <li>
                                    VPC: Default VPC
                                </li>
                            </ul>
                        </li>
                        <li>
                            se apasa butonul <b> Create Security Group</b>
                        </li>
                    </ul>
                    In tabul <b>Inbound roles</b> {"->"} se apasa pe butonul <b>Edit inbound rules</b>:
                    <ul>
                        <li>
                            se apasa pe butonul <b>Add rule</b>
                        </li>
                        <li>
                            tabelul care apare se editeaza inline:
                            <ul>
                                <li>
                                    Port range: 5432-6379
                                </li>
                                <li>
                                    Source: Custom + se selecteaza din urmatorul camp input, grupul selectat mai devreme (ceva care incepe cu <i>sg</i> si contine <i>multi-docker</i>)
                                </li>
                            </ul>
                        </li>
                        <li>
                            se apasa butonul <i>Save Rule</i>
                        </li>
                    </ul>

                    <hr/>

                    Aplicare grupul de securitate la ElastiCache:
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>ElastiCache</b>.
                        </li>
                        <li>
                            Resources {"->"} Redis clusters
                        </li>
                        <li>
                            se selecteaza <i>multi-docker-redis</i> {"->"} <b>Actions {"->"} Modify</b>
                        </li>
                        <li>
                            din sectinea <b>Security</b> / Selected security groups {"->"} se apasa pe butonul <b>Manage</b>
                        </li>
                        <li>
                            se cauta <i>multi-docker</i> {"->"} se selecteaza {"->"} se apasa pe butonul <b>Choose</b>
                        </li>
                        <li>
                            se apasa pe butonul <b>Preview Changes</b>
                        </li>
                        <li>
                            se apasa pe butonul <b>Modify</b>
                        </li>
                    </ul>

                    <hr/>

                    Aplicare grupul de securitate la RDS:
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>RDS</b>.
                        </li>
                        <li>
                            Databases
                        </li>
                        <li>
                            se selecteaza <i>multi-docker-postgres</i> {"->"} <b>Modify</b>
                        </li>
                        <li>
                            din sectinea <b>Connectivity</b> {"->"} <b>Security group</b>
                        </li>
                        <li>
                            se cauta <i>multi-docker</i> {"->"} se selecteaza  <i>multi-docker</i>
                        </li>
                        <li>
                            se apasa pe butonul <b>Continue</b>
                        </li>
                        <li>
                            se apasa pe butonul <b>Modify DB instance</b>
                        </li>
                    </ul>

                    <hr/>
                    Aplicare grupul de securitate la Elastic Beanstalk:
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>Elastic Beanstalk</b>.
                        </li>
                        <li>
                            Environments
                        </li>
                        <li>
                            se face click pe <i>MultiDocker-env</i>
                        </li>
                        <li>
                            din sectinea <b>Multidocker-env</b> (din stanga) <b>Configuration</b>
                        </li>
                        <li>
                            din linia <b>Instances</b> se apasa pe butonul <b>Edit</b>
                        </li>
                        <li>
                            din sectiunea <b>EC2 security groups</b>
                        </li>
                        <li>
                            se cauta <i>multi-docker</i> {"->"} se selecteaza  <i>multi-docker</i>
                        </li>
                        <li>
                            se apasa pe butonul <b>Apply</b>
                        </li>
                        <li>
                            se apasa pe butonul <b>Confirm</b>
                        </li>
                    </ul>

                    <hr/>
                    Setare variabile de mediu
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>Elastic Beanstalk</b>.
                        </li>
                        <li>
                            Environments
                        </li>
                        <li>
                            se face click pe <i>MultiDocker-env</i>
                        </li>
                        <li>
                            din sectinea <b>Multidocker-env</b> (din stanga) <b>Configuration</b>
                        </li>

                        <li>
                            se apasa pe butonul <b>Edit</b> din dreptul categoriei <b>Software</b>
                        </li>

                        <li>
                            in sectiunea <b>Environment properties</b>:
                            <ul>
                                <li>
                                    REDIS_HOST: multi-docker-redis.5xmiv6.ng.0001.euc1.cache.amazonaws.com
                                    <br/>
                                    (ElastiCache {"->"} Redis clusters  {"->"} multi-docker-redis {"->"} Cluster details {"->"} Primary endpoint - se ia fara port)
                                </li>
                                <li>
                                    REDIS_PORT: 6379
                                </li>
                                <li>
                                    PGUSER: postgres
                                </li>
                                <li>
                                    PGPASSWORD: postgrespassword
                                </li>
                                <li>
                                    PGHOST: multi-docker-postgres.cbqetqti8eab.eu-central-1.rds.amazonaws.com
                                    <br/>
                                    (RDS {"->"} Databases {"->"} multi-docker-postgres {"->"} Connectivity & security {"->"} Endpoint)
                                </li>
                                <li>
                                    PGDATABASE: fibvalues
                                </li>
                                <li>
                                    PGPORT: 5432
                                </li>
                            </ul>
                            se apasa butonul <b>Apply</b>
                        </li>
                    </ul>

                    <hr/>
                    Crearea nou utilizator IAM
                    <ul>
                        <li>
                            <b>AWS Management Console</b> {"->"} (din casuta de cautare servicii, etc) {"->"} se scrie <b>IAM</b>.
                        </li>
                        <li>
                            Access management {'{"->"}'} Users {"->"} Add users
                        </li>
                        <li>
                            <ul>
                                <li>User Name: docker-multi-travis-ci</li>
                                <li>se selecteaza <b>Programmatic Access</b></li>
                                <li>Next: Persmissions</li>
                                <li>se apasa pe butonul <b>Attach Existing Policies Directly</b></li>
                                <li>se selecteaza <b>AdministratorAccess-AWSElasticBeanstalk</b></li>
                                <li>Next:Tags</li>
                                <li>Next:Review</li>
                                <li>Create user</li>
                            </ul>
                            se va crea un utilzator avand:
                            <ul>
                                <li>
                                    Access key ID:AKIAUEU4ZVUCGA2V6U7R
                                    Secret access key: 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
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>4. AWS cu Travis</b>
                    <br/>
                    <br/>

                    Adaugare AWS_ACCESS_KEY si AWS_SECRET_KEY in aplicatia Travis:
                    <ul>
                        <li>
                            in Dashboard-ul din Travis, se selecteaza repository-ul <i>multi-docker</i>
                        </li>
                        <li>
                            <b>More options {"->"} Settings</b>:
                        </li>
                        <li>
                            se adauga in sectiunea <b>Environment Variables</b>:
                            <ul>
                                <li>
                                    AWS_ACCESS_KEY: AKIAUEU4ZVUCGA2V6U7R
                                </li>
                                <li>
                                    AWS_SECRET_KEY: uvjKbuEywkEuegMgKzSCm2hN2tXmj7V6w17WA0/H
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Modificare fisier <b>.travis.yml</b>:
                    <ul>
                        <li>
                            region: eu-central-1 (se poate lua din <i>URL</i> de la Elastic Beanstalk Environments)
                        </li>
                        <li>
                            app: multi-docker
                        </li>
                        <li>
                            env: MultiDocker-env
                        </li>
                        <li>
                            bucket_name: elasticbeanstalk-eu-central-1-284870421764 (se poate lua din <i>Name</i> de la Amazon S3 {"->"} Buckets)
                        </li>
                        <li>
                            bucket_path: docker-multi
                        </li>
                        <li>
                            access_key_id: $AWS_ACCESS_KEY
                        </li>
                        <li>
                            secret_access_key: $AWS_SECRET_KEY
                        </li>
                    </ul>
                    Deci, in fisierul <b>.travis.yml</b> se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'deploy:\n' +
                            '  provider: elasticbeanstalk\n' +
                            '  region: \'eu-central-1\'\n' +
                            '  app: \'multi-docker\'\n' +
                            '  env: \'MultiDocker-env\'\n' +
                            '  bucket_name: \'elasticbeanstalk-eu-central-1-284870421764\'\n' +
                            '  bucket_path: \'docker-multi\'\n' +
                            '  on:\n' +
                            '    branch: master\n' +
                            '  access_key_id: $AWS_ACCESS_KEY\n' +
                            '  secret_access_key: $AWS_SECRET_KEY'}
                    </SyntaxHighlighter>

                    Disierul <b>.travis.yml</b> complet:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sudo: required\n' +
                            'language: generic\n' +
                            '\n' +
                            'services:\n' +
                            '  - docker\n' +
                            '\n' +
                            'before_install:\n' +
                            '  - docker build -t iulianbuzdugan/react-test -f ./client/Dockerfile.dev ./client\n' +
                            '\n' +
                            'script:\n' +
                            '  - docker run -e CI=true iulianbuzdugan/react-test npm test\n' +
                            '\n' +
                            'after_success:\n' +
                            '  - docker build -t iulianbuzdugan/multi-client ./client\n' +
                            '  - docker build -t iulianbuzdugan/multi-nginx ./nginx\n' +
                            '  - docker build -t iulianbuzdugan/multi-server ./server\n' +
                            '  - docker build -t iulianbuzdugan/multi-worker ./worker\n' +
                            '  # Log in to the docker CLI\n' +
                            '  - echo "$DOCKER_PASSWORD" | docker login -u "$DOCKER_ID" --password-stdin\n' +
                            '  # Take those images and push them to Docker Hub\n' +
                            '  - docker push iulianbuzdugan/multi-client\n' +
                            '  - docker push iulianbuzdugan/multi-nginx\n' +
                            '  - docker push iulianbuzdugan/multi-server\n' +
                            '  - docker push iulianbuzdugan/multi-worker\n' +
                            '\n' +
                            'deploy:\n' +
                            '  provider: elasticbeanstalk\n' +
                            '  region: \'eu-central-1\'\n' +
                            '  app: \'multi-docker\'\n' +
                            '  env: \'MultiDocker-env\'\n' +
                            '  bucket_name: \'elasticbeanstalk-eu-central-1-284870421764\'\n' +
                            '  bucket_path: \'docker-multi\'\n' +
                            '  on:\n' +
                            '    branch: master\n' +
                            '  access_key_id: $AWS_ACCESS_KEY\n' +
                            '  secret_access_key: $AWS_SECRET_KEY'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git add.'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git commit -m "testing deployment"'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git push origin main'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. AWS cu GitHub Actions</b>
                    <br/>
                    <br/>

                    Se redenumeste fisierul <b>.travis.yml</b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'mv .travis.yml old.travis.yml'}
                    </SyntaxHighlighter>

                    Vom avea fisierul <b>deploy.yaml</b> (din <b>.github/workflows</b>), cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'name: Deploy Frontend\n' +
                            'on:\n' +
                            '  push:\n' +
                            '    branches:\n' +
                            '      - master\n' +
                            '\n' +
                            'jobs:\n' +
                            '  build:\n' +
                            '    runs-on: ubuntu-latest\n' +
                            '    steps:\n' +
                            '      - uses: actions/checkout@v2\n' +
                            '      - run: docker login -u ${{ secrets.DOCKER_USERNAME }} -p ${{ secrets.DOCKER_PASSWORD }}\n' +
                            '      - run: docker build -t iulianbuzdugan/react-test -f ./client/Dockerfile.dev ./client\n' +
                            '      - run: docker run -e CI=true iulianbuzdugan/docker-react npm test\n' +
                            '\n' +
                            '      - run: docker build -t iulianbuzdugan/multi-client-hga ./client\n' +
                            '      - run: docker build -t iulianbuzdugan/multi-nginx-hga ./nginx\n' +
                            '      - run: docker build -t iulianbuzdugan/multi-server-hga ./server \n' +
                            '      - run: docker build -t iulianbuzdugan/multi-worker-hga ./worker\n' +
                            ' \n' +
                            '      - run: docker push iulianbuzdugan/multi-client-hga\n' +
                            '      - run: docker push iulianbuzdugan/multi-nginx-hga\n' +
                            '      - run: docker push iulianbuzdugan/multi-server-hga\n' +
                            '      - run: docker push iulianbuzdugan/multi-worker-hga'}
                    </SyntaxHighlighter>
                    si completam sa fie:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'name: Deploy Frontend\n' +
                            'on:\n' +
                            '  push:\n' +
                            '    branches:\n' +
                            '      - master\n' +
                            '\n' +
                            'jobs:\n' +
                            '  build:\n' +
                            '    runs-on: ubuntu-latest\n' +
                            '    steps:\n' +
                            '      - uses: actions/checkout@v2\n' +
                            '      - run: docker login -u ${{ secrets.DOCKER_USERNAME }} -p ${{ secrets.DOCKER_PASSWORD }}\n' +
                            '      - run: docker build -t iulianbuzdugan/react-test -f ./client/Dockerfile.dev ./client\n' +
                            '      - run: docker run -e CI=true iulianbuzdugan/react-test npm test\n' +
                            '\n' +
                            '      - run: docker build -t iulianbuzdugan/multi-client-hga ./client\n' +
                            '      - run: docker build -t iulianbuzdugan/multi-nginx-hga ./nginx\n' +
                            '      - run: docker build -t iulianbuzdugan/multi-server-hga ./server \n' +
                            '      - run: docker build -t iulianbuzdugan/multi-worker-hga ./worker\n' +
                            ' \n' +
                            '      - run: docker push iulianbuzdugan/multi-client-hga\n' +
                            '      - run: docker push iulianbuzdugan/multi-nginx-hga\n' +
                            '      - run: docker push iulianbuzdugan/multi-server-hga\n' +
                            '      - run: docker push iulianbuzdugan/multi-worker-hga\n' +
                            '\n' +
                            '      - name: Generate deployment package\n' +
                            '        run: zip -r deploy.zip . -x \'*.git*\'\n' +
                            '\n' +
                            '      - name: Deploy to EB\n' +
                            '        uses: einaregilsson/beanstalk-deploy@v20\n' +
                            '        with:\n' +
                            '          aws_access_key: ${{ secrets.AWS_ACCESS_KEY }}\n' +
                            '          aws_secret_key: ${{ secrets.AWS_SECRET_KEY }}\n' +
                            '          application_name: multi-docker\n' +
                            '          environment_name: MultiDocker-env\n' +
                            '          version_label: ${{ github.sha }}\n' +
                            '          region: eu-central-1\n' +
                            '          deployment_package: deploy.zip'}
                    </SyntaxHighlighter>

                    Pe GitHub in tab-ul proictului multi-docker: <b>Settings {"->"} Secrets {"->"} New repository secret</b>:
                    <ul>
                        <li>
                            AWS_ACCESS_KEY: AKIAUEU4ZVUCGA2V6U7R
                        </li>
                        <li>
                            AWS_SECRET_KEY: uvjKbuEywkEuegMgKzSCm2hN2tXmj7V6w17WA0/H
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex5AWSDockerContent;