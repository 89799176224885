import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class FormAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-form", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Form</b>
                    <br/>
                    <br/>

                    Abordari:
                    <ul>
                        <li>TD: Template-Driven</li>
                        <li>Reactive</li>
                    </ul>

                    <hr/>
                    <b>1.1. Abordarea TD (FormsModule)</b>
                    <br/>
                    <br/>

                    In <i>.module.ts</i> se importa <b>FormsModule</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'imports: [ FormsModule ]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {FormsModule} from \'@angular/forms\''}
                    </SyntaxHighlighter>

                    Pentru a specifica ca un inpus sa fie gestionat de un form gestionat de angular trebuie puse atributele:
                    <ul>
                        <li>
                            <b>ngModel</b> (atribut-directiva)
                        </li>
                        <li>
                            <b>name</b> (atribut-html)
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" name="username" ngModel>'}
                    </SyntaxHighlighter>

                    Putem tine datele / valorile din formular si intr-o structura:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'credentiale = {\n' +
                            '   username: \'\', \n' +
                            '   password: \'\' \n' +
                            '}'}
                    </SyntaxHighlighter>

                    si atunci pentru a sincroniza modificarile facute in forma cu structura construita mai sus:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" name="username" ([ngModel)]=""credentiale.username">'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a obtine o referinta la formular pentru a avea acces la proprietati se foloseste <i>variabila template (#)</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<form  #loginForm>...</form>'}
                    </SyntaxHighlighter>
                    in acest mod putem aceea acces de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{loginForm.autocomplete}}'}
                    </SyntaxHighlighter>

                    Pentru a ne lega direct la NgForm:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<form  #loginForm="ngForm">...</form>'}
                    </SyntaxHighlighter>

                    in acest mod putem vedea daca forma este valida, de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'{{loginForm.valid}}'}
                    </SyntaxHighlighter>
                    sau dezactiva butonul de submit daca forma nu este valida:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<button type="submit" [disabled]="loginForm.invalid">Trimite</button>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru a obtine datele introduse in formular, pe submit, se foloseste <b>ngSubmit</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<form (ngSubmit)="onSubmit(f)" #f="ngForm">...</form>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'onSubmit(form:NgForm){...}'}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {NgForm} from \'@angular/forms\''}
                    </SyntaxHighlighter>

                    Valorile din forma vor putea fi obtinute (<i>form.value</i>):

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'onSubmit(form:NgForm){\n' +
                            '  console.log(form.value)\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Alternativ:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<form (ngSubmit)="onSubmit()" #f="ngForm">...</form>'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'@ViewChild(f) myForm:NgForm;\n' +
                            '\n' +
                            'onSubmit(){\n' +
                            '  console.log(this.myForm.value)\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Validatori html:
                    <ul>
                        <li>required</li>
                        <li>email</li>
                        <li>pattern</li>
                    </ul>

                    Pentru a verifica ca o forma este valida se poate din obiectul <b>NgForm</b>, proprietatea <b>valid</b>.
                    <br/>
                    In mod automat, angular adauga clase pe input, de exemplu: ng-dirty, ng-invalid, ng-touched (a trecut pe acolo si a schimbat in input sau nu).
                    <br/>

                    Validarea HTML5, în mod implicit, Angular o dezactivează. Pentru activare trebuie adaugat <b>ngNativeValidate</b>.

                    <br/>
                    Dezactivare buton de submit, daca forma nu e valida:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<button type="submit" [disabled]="!f.valid">Trimite</button>'}
                    </SyntaxHighlighter>

                    Afisare mesaje de eroare in urma validarii unui input (se foloseste <b>ngModel</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="email" name="email" required email #email="ngModel" ngModel>\n' +
                            '<span class="help-block" *ngIf="!email.valid && email.touched">Introduceti un email valid!</span>'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="email" name="email" required email #email="ngModel" ngModel>\n' +
                            '<span class="help-block" *ngIf="email.errors && email.touched && email.dirty">Introduceti un email valid!</span>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Valori default:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<select id="intrebare" name="intrebare" [ngModel]="intrebareImplicita">\n' +
                            '  <option value="1">Intrebare 1?</option>\n' +
                            '  <option value="2">Intrebare 2?</option>\n' +
                            '</select>'}
                    </SyntaxHighlighter>
                    unde in ts e declarat: <i>intrebareImplicita=2</i>

                    <br/>
                    <br/>
                    Sau <b>setValue()</b> (suprascrie valorile):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.setValue({\n' +
                            '  intrebare: \'2\', \n' +
                            '  email:\'kj@email.com\', \n' +
                            '  username:\'kj\'\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Sau <b>.form.patchValue()</b> (defineste valori doar pentru cele specificate):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.form.patchValue({\n' +
                            '  username:\'kj\'\n' +
                            '})'}
                    </SyntaxHighlighter>

                    <hr/>
                    Two-way binding:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<textarea name="intrebare" [(ngModel)]="answer">\n' +
                            'Reafisare intrebare: {{answer}'}
                    </SyntaxHighlighter>
                    unde in ts e declarat: <i>answer=''</i>

                    <hr/>
                    Input-urile pot fi grupate, cu ajutorul unui element html (div, de exemplu) cu atributul <b>ngModelGroup="numeGrup"</b>!

                    <hr/>
                    Resetare forma <b>.reset()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.reset()'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1.2. Abordarea Reactiva (ReactiveFormsModule)</b>
                    <br/>
                    <br/>

                    In <i>.module.ts</i> se importa <b>FormsModule</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'imports: [ ReactiveFormsModule ]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'import {ReactiveFormsModule} from \'@angular/forms\''}
                    </SyntaxHighlighter>

                    Crearea programatica a formularului:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'myForm: FormGroup;\n' +
                            '\n' +
                            'ngOnInit(){\n' +
                            '  this.myForm  = new FormGroup({\n' +
                            '     \'username\':new FormControl(null),\n' +
                            '     \'email\':new FormControl(null),\n' +
                            '     \'sex\':new FormControl(\'masculin\'),\n' +
                            '  })\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pentru lega obiectul de mai sus de formularul HTML (<b>[formGroup]</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<form [formGroup]="myForm" (ngSubmit)="onSubmit()" >...</form>'}
                    </SyntaxHighlighter>

                    Pentru a lega un input de un element din obiectul definit mai sus (<b>formControlName</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username" ngModel>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'onSubmit(){\n' +
                            '  console.log(myForm.value)\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Diferenta dintre <b>(submit)</b> si <b>(ngSubmit)</b> este ca (ngSubmit) previne comportamentul implicit al actiunii de submit
                    (trimitere date si refresh pagina)

                    <hr/>
                    Adaugare validatori:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'myForm: FormGroup;\n' +
                            '\n' +
                            'ngOnInit(){\n' +
                            '  this.myForm  = new FormGroup({\n' +
                            '     \'username\':new FormControl(null, Validators.required),\n' +
                            '     \'email\':new FormControl(null, [Validators.required, Validators.email]),\n' +
                            '     \'sex\':new FormControl(\'masculin\'),\n' +
                            '  })\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Afisare mesaje de eroare in urma validarii unui input:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username">\n' +
                            '<span class="help-block" *ngIf="!myForm.get(\'username\').valid && myForm.get(\'username\').touched">Username este obligatoriu!</span>'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username">\n' +
                            '<span  *ngIf="myForm.controls.username.errors?.required">Username este obligatoriu!</span>'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username">\n' +
                            '<span  *ngIf="myForm.controls.username.errors?.required && myForm.controls.username.touched && myForm.controls.username.dirty">\n' +
                            'Username este obligatoriu!\n' +
                            '</span>'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username">\n' +
                            '<span  *ngIf="myForm.controls.username.errors?.minlength">\n' +
                            'Lungimea actuala este {{myForm.controls.username.errors?.minlength.actualLength}}!\n' +
                            'Lungimea minima este {{myForm.controls.username.errors?.minlength.required Length}}!\n' +
                            '</span>'}
                    </SyntaxHighlighter>

                    Proprietati:
                    <ul>
                        <li>
                            valid: campul de tip input este valid
                        </li>
                        <li>
                            invalid: campul de tip input este invalid
                        </li>
                        <li>
                            disabled: ignora validarea pe camp
                        </li>
                        <li>
                            touched: campul a fost atins (pe blur event)
                        </li>
                        <li>
                            untouched: campul nu a fost atins
                        </li>
                        <li>
                            pristine: (curat; impecabil) valoarea campului nu s-a modificat
                        </li>
                        <li>
                            dirty: valoarea campului s-a modificat
                        </li>
                    </ul>
                    Validatori:
                    <ul>
                        <li>required</li>
                        <li>minlength</li>
                        <li>email</li>
                        <li>min</li>
                        <li>max</li>
                        <li>pattern [vezi: https://regexr.com/]</li>
                    </ul>

                    <hr/>
                    Input marking: <b>ngx-mask</b>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm i ngx-mask'}
                    </SyntaxHighlighter>

                    In module.ts, in sectiunea <b>imports</b> se importa: <b>NgxMaskDirective</b>.
                    <br/>
                    In module.ts, in sectiunea <b>providers</b> se adauga: <b>[provideEnvironmentNgxMask]</b>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" [dropSpecialCharacters]="false" mask="000-000.00" />'}
                    </SyntaxHighlighter>
                    (dropSpecialCharacters = false, nu sterge caracterele speciale cum ar fi - sau . din exemplul de mai sus)

                    <hr/>
                    Dezactivare buton de submit, daca forma nu e valida (<b>FormGroup</b> are proprietatate <b>valid</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<button type="submit" [disabled]="!myForm.valid">Trimite</button>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Grupare controale:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'myForm: FormGroup;\n' +
                            '\n' +
                            'ngOnInit(){\n' +
                            '  this.myForm  = new FormGroup({\n' +
                            '     \'userData\':{\n' +
                            '        \'username\':new FormControl(null, Validators.required),\n' +
                            '        \'email\':new FormControl(null, [Validators.required, Validators.email]),\n' +
                            '     }\n' +
                            '     \'sex\':new FormControl(\'masculin\'),\n' +
                            '  })\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In HTML template input-urile grupate, trebuie sa fie in interiorul unui element html, de exemplu <i>div</i>,
                    cu atributul <b>formGroupName</b>="userData".
                    <br/>
                    In plus:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username">\n' +
                            '<span class="help-block" *ngIf="!myForm.get(\'userData.username\').valid && myForm.get(\'userData.username\').touched">Username este obligatoriu!</span>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Adaugare dinamic contraole
                    <br/>
                    <br/>
                    De exemplu,vrem sa adaugam o lista de hobby-uri (ne vom folosi de <b>FormArray</b>):

                    <SyntaxHighlighter>
                        {'hobbies: new FormArray([])'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'myForm: FormGroup;\n' +
                            '\n' +
                            'ngOnInit(){\n' +
                            '  this.myForm  = new FormGroup({\n' +
                            '     \'userData\':{\n' +
                            '        \'username\':new FormControl(null, Validators.required),\n' +
                            '        \'email\':new FormControl(null, [Validators.required, Validators.email]),\n' +
                            '     }\n' +
                            '     \'sex\':new FormControl(\'masculin\'),\n' +
                            '     \'hobbies: new FormArray([])\'' +
                            '  })\n' +
                            '}'}
                    </SyntaxHighlighter>

                    {/*return (this.signupForm.get('hobbies') as FormArray).controls;*/}

                    Fie metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'onAddHobby(){\n' +
                            'const control =  new FormControl(null, Validators.required),\n' +
                            '(this.signupForm.get(\'hobbies\') as FormArray).push(control);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In HTML template, ne vom folosi de <b>formArrayName</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<div formArrayName="hobbies">\n' +
                            '\t<button type="button" (click)="onAddHobby()">Adauga hobby</button>\n' +
                            '\t<div *ngFor="let hobbyControl of myForm.get(\'hobbies\').controls; let i= index">\n' +
                            '\t\t<input type="text" [formControlName]="i">\n' +
                            '\t</div>\t\n' +
                            '</div>\t\t\t'}
                    </SyntaxHighlighter>

                    <hr/>

                    Validare personalizata:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'numeInterzis(control: FormControl): {[s: string]: boolean}{\n' +
                            '\n' +
                            '\tif (control.value==="kj"){\n' +
                            '\t\treturn {\'numeleEsteInterzis\': true}\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\treturn null;// daca validarea e ok, trebuie sa se returneze null !!!\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu de folosire:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' \'username\':new FormControl(null, [Validators.required, this.numeInterzis.bind(this)]),'}
                    </SyntaxHighlighter>

                    In HTML template
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="text" id="username" formControlName="username">\n' +
                            '<span class="help-block" *ngIf="!myForm.get(\'userData.username\').valid && myForm.get(\'userData.username\').touched">\n' +
                            '  <span *ngIf="myform.get(\'userData.username\').errors[\'required\']">Username este obligatoriu!</span>\n' +
                            '  <span *ngIf="myform.get(\'userData.username\').errors[\'numeleEsteInterzis\']">Username este invalid!</span>\n' +
                            '</span>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Validare asincrona:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'emailInterzis(control: FormControl): Promise<any> | Observable<any>{\n' +
                            '\n' +
                            '\tconst promise = new Promise<any>(\n' +
                            '\t\t(resolve, reject)=>{\n' +
                            '\t\t\tsetTimeout(()=>{\n' +
                            '\t\t\t\tif (control.value=\'test@test.ro\'){\n' +
                            '\t\t\t\t\tresolve( {\'emailEsteInterzis\': true})\n' +
                            '\t\t\t\t}else{\n' +
                            '\t\t\t\t\tresolve( null ); // daca validarea e ok, trebuie sa se returneze null !!!\n' +
                            '\t\t\t\t}\n' +
                            '\t\t\t},1500);\n' +
                            '\t\t}\n' +
                            '\t)\n' +
                            '\n' +
                            '\treturn promise; \n' +
                            '\t\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi se adauga si validatorul asincron (e urmatorul parametru):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {' \'email\':new FormControl(null, [Validators.required, Validators.email], this.emailInterzis),'}
                    </SyntaxHighlighter>
                    Pe parcursul validari, fiind asincron, campul cu validare asincrona va avea si atributul <b>ng-pending</b>.

                    <hr/>

                    Observare modificari de valoare in forma:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.valueChanges.subscribe(\n' +
                            '   (value) => console.log(value)' +
                            ')'}
                    </SyntaxHighlighter>

                    Observare modificari de stare in forma (valid, invalid, pending):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.statusChanges.subscribe(\n' +
                            '   (status) => console.log(status)' +
                            ')'}
                    </SyntaxHighlighter>

                    <hr/>

                    Setare valori:
                    <br/>

                    Cu <b>setValue()</b> (suprascrie valorile):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.setValue({\n' +
                            '     \'userData\':{\n' +
                            '        \'username\':\'KJ\',\n' +
                            '        \'email\':\'test@test.ro\',\n' +
                            '     }\n' +
                            '     \'sex\':\'masculin\',\n' +
                            '     \'hobbies:[]\n' +
                            '})'}
                    </SyntaxHighlighter>

                    Sau <b>.form.patchValue()</b> (defineste valori doar pentru cele specificate):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.form.patchValue({\n' +
                            '     \'userData\':{\n' +
                            '        \'username\':\'KJ2\',\n' +
                            '     }\n' +
                            '})'}
                    </SyntaxHighlighter>

                    <hr/>
                    Resetare forma <b>.reset()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'this.myForm.reset()'}
                    </SyntaxHighlighter>

                    <hr/>
                    Rezolvare problema:
                    <i>Can't bind to 'ngModelOptions' since it isn't a known property of 'input'</i>:

                    <br/>
                    Se foloseste <b>[ngModelOptions]</b>
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'[ngModelOptions]="{standalone: true}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'<input type="checkbox" class="select-all checkbox" name="select-all"\n' +
                            '                    [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"\n' +
                            '                    [ngModelOptions]="{standalone: true}"/>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FormAngularContent;