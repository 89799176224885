import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class NamespaceJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-namespace", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' // namespace, not module\n' +
                            'var Utils = {\n' +
                            '    cancelEvt(evt) {\n' +
                            '        evt.preventDefault();\n' +
                            '        evt.stopPropagation();\n' +
                            '        evt.stopImmediatePropagation();\n' +
                            '    },\n' +
                            '    isValidEmail(email) {\n' +
                            '        return /[^@]+@[^@.]+\\.[^@.]+/.test(email);\n' +
                            '    }'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NamespaceJavaScriptContent;