import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ArgsPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-ii-args", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Functiile:
                    <ul>
                        <li>pot avea 0 argumente</li>
                        <li>pot avea un <b>număr fix</b> de argumente fără excluderi, în ordinea data de definiția funcției</li>
                        <li>pot exista <b>valori implicite</b> pentru unii parametrii (deci nu trebuie să trecem toate argumentele); parametrii cu valori implicite sunt prezentați ca parametri de tip cuvinte cheie</li>
                        <li>putem trece argumente în orice ordine dacă atribuim cuvinte cheie tuturor valorilor argumentelor; în caz contrar, cele poziționale trebuie sa fie primele in lista de argumente</li>
                    </ul>

                    Functiile pot accepta orice un număr arbitrar de argumente poziționale și argumente cheie, folosind:
                    <ul>
                        <li>idendificatori speciali, numiti: <b>*args</b> si <b>**kwargs</b></li>
                    </ul>

                    Observatii:
                    <ul>
                        <li>
                            cei doi identificatori speciali ( *args și **kwargs) trebuie sa fie ultimii doi parametri într-o definiție a funcției
                            (exluzand parametrii dati prin cuvinte cheie sau parametrii implciti);

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie1( *args, a=1, b, **kwargs):\n' +
                                '    print(args) # (1, \'2\', 3)\n' +
                                '    print(a) # 1\n' +
                                '    print(b) # 4\n' +
                                '    print(kwargs) # {\'argument1\': 4, \'argument2\': \'5\'}\n' +
                                '    \n' +
                                '\n' +
                                'functie1(1, \'2\', 3 , b=4, argument1=4, argument2=\'5\')'}
                            </SyntaxHighlighter>

                            sau:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie1( *args, b, a=1, **kwargs):\n' +
                                '    print(args) # (1, \'2\', 3)\n' +
                                '    print(a) # 1\n' +
                                '    print(b) # 4\n' +
                                '    print(kwargs) # {\'argument1\': 4, \'argument2\': \'5\'}\n' +
                                '    \n' +
                                '\n' +
                                'functie1(1, \'2\', 3 , b=4, argument1=4, argument2=\'5\')'}
                            </SyntaxHighlighter>

                            sau:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie1( *args, b, a, **kwargs):\n' +
                                '    print(args) # (1, \'2\', 3)\n' +
                                '    print(a) # 5\n' +
                                '    print(b) # 6\n' +
                                '    print(kwargs) # {\'z\': 4, \'c\': 7, \'argument1\': 8, \'argument2\': \'9\'}\n' +
                                '    \n' +
                                '\n' +
                                'functie1(1, \'2\', 3, z=4 , a=5, b=6, c=7, argument1=8, argument2=\'9\')'}
                            </SyntaxHighlighter>

                            sau:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'def functie1( y, *args, b, a, **kwargs):\n' +
                                '    print(y) #  1\n' +
                                '    print(args) # (1, \'2\', 3)\n' +
                                '    print(a) # 5\n' +
                                '    print(b) # 6\n' +
                                '    print(kwargs) # {\'z\': 4, \'c\': 7, \'argument1\': 8, \'argument2\': \'9\'}\n' +
                                '    \n' +
                                '\n' +
                                'functie1(1, \'2\', 3, z=4 , a=5, b=6, c=7, argument1=8, argument2=\'9\')'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            numele lor pot schimbate, <i>args</i> si <i>kwargs</i> fiind doar o <b>conventie</b>; dar <b>trebuie sa menținem ordinea parametrilor și a asteriscurilor</b>
                        </li>
                        <li>
                            cei doi parametri speciali sunt responsabili pentru gestionarea oricărui număr de argumente suplimentare (plasate în continuare după argumentele așteptate) transmise unei funcții apelate
                        </li>
                        <li>
                            <b>*args (argumente poziționale suplimentare) </b> – se referă la un tuplu al tuturor argumentelor poziționale suplimentare, care nu sunt așteptate în mod explicit,
                            deci argumentele transmise fără cuvinte cheie și transmise imediat după argumentele așteptate;
                        </li>
                        <li>
                            <b>**kwargs (argumente cuvinte cheie suplimentare)</b> – se referă la un dicționar cu toate argumentele neașteptate care au fost transmise sub formă de perechi cuvânt-cheie=valoare
                        </li>
                    </ul>

                    Exemplu 1:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(a, b, *args, **kwargs):\n' +
                        '    print(a, type(a)) # 7 <class \'int\'>\n' +
                        '    print(b, type(b)) # asteptare <class \'str\'>\n' +
                        '    print(args, type(args)) # (14, 77, \'a\') <class \'tuple\'>\n' +
                        '    print(kwargs, type(kwargs)) # {\'argument1\': 52, \'argument2\': \'76\'} <class \'dict\'>\n' +
                        '\n' +
                        '\n' +
                        'functie(7, \'asteptare\', 14, 77, \'a\', argument1=52, argument2=\'76\')\n'}
                    </SyntaxHighlighter>

                    Observatie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(a, b, **args, *kwargs): # SyntaxError: invalid syntax\n' +
                        '   pass'}
                    </SyntaxHighlighter>

                    Pentru a trimite argumentele de tipul *arg si **kwargs primite de o functie catre alta functie, <b>trebuie sa pastram asterixurile</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(a, b, *args, **kwargs):\n' +
                        '    print(a, type(a)) # 7 <class \'int\'>\n' +
                        '    print(b, type(b)) # asteptare <class \'str\'>\n' +
                        '    \n' +
                        '    functie2(*args,**kwargs)\n' +
                        '\n' +
                        'def functie2(*args, **kwargs):\n' +
                        '    print(args, type(args)) # (14, 77, \'a\') <class \'tuple\'>\n' +
                        '    print(kwargs, type(kwargs)) # {\'argument1\': 52, \'argument2\': \'76\'} <class \'dict\'>\n' +
                        '\n' +
                        '\n' +
                        'functie(7, \'asteptare\', 14, 77, \'a\', argument1=52, argument2=\'76\')'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(a, b, *args ):\n' +
                        '    print(a, b, args) # 11 210 (140, 620, 310)\n' +
                        '    print(a, b, *args) # 11 210 140 620 310\n' +
                        '\n' +
                        '\n' +
                        'functie(11, \'210\', 140, 620, 310)'}
                    </SyntaxHighlighter>

                    Observatie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(a, b, *args): # TypeError: functie() got an unexpected keyword argument \'x\'\n' +
                        '   pass\n' +
                        '\n' +
                        'functie(11, \'210\', 140, 620, 310, x=\'a\')'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    Daca renuntam sa mai puntem * (asterix-urile), atunci in parametrul <i>*args</i> al functei <i>functie2()</i> se va regasi atat tuplul, cat si dictionarul,
                    deoarece se presupune că ar trebui să gestioneze toate argumentele poziționale (niciunul dintre ele nu are cuvinte cheie).
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie(a, b, *args, **kwargs):\n' +
                        '    print(a, type(a)) # 7 <class \'int\'>\n' +
                        '    print(b, type(b)) # asteptare <class \'str\'>\n' +
                        '    \n' +
                        '    functie2(args, kwargs)\n' +
                        '\n' +
                        'def functie2(*args, **kwargs):\n' +
                        '    print(args, type(args)) # (((14, 77, \'a\'), {\'argument1\': 52, \'argument2\': \'76\'}) <class \'tuple\'>\n' +
                        '    print(kwargs, type(kwargs)) # {} <class \'dict\'>\n' +
                        '\n' +
                        '\n' +
                        'functie(7, \'asteptare\', 14, 77, \'a\', argument1=52, argument2=\'76\')'}
                    </SyntaxHighlighter>

                    Exemplu 3:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'def functie1(a, b, *args, c=20, **kwargs):\n' +
                        '    functie2(c, *args, **kwargs)\n' +
                        '    \n' +
                        'def functie2(my_c, *my_args, **my_kwargs):\n' +
                        '    print(\'my_c:\', my_c) # my_c: 5\n' +
                        '    print(\'my_args:\', my_args) # my_args: (3, 4)\n' +
                        '    print(\'my_kwargs\', my_kwargs) # my_kwargs {\'argument1\': 6, \'argument2\': \'7\'}\n' +
                        '    \n' +
                        'functie1(1, \'2\', 3, 4, c=5, argument1=6, argument2=\'7\')'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>

                              <a href={"https://edube.org/"}>PCPP1 | Advanced Perspective of Classes and Object-Oriented Programming in Python (Professional 1/5) [BETA]</a>

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ArgsPythonContent;