import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CommentPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-comments", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div>
                    <i>Chiar şi pe drum drept, şarpele tot în serpentine merge.</i> (Lucian Blaga)
                    <br/>
                    <br/>
                </div>

                <div className={"text-justify important"}>

                    <b> Python: Comentarii</b>
                    <br/>
                    <br/>
                    Moduri de a pune un comentariu:
                    <ul>
                        <li>comentariu pe o linie:
                            <ul>
                                <li>
                                    pe o linie fara cod Python: prefixat de <b>#</b> (<b>linia 1</b>)
                                </li>
                                <li>
                                    pe o linie cu cod Python: prefixat de <b>#</b> (<b>linia 4</b>); tot ce este dupa # este interpretat ca fiind comentariu, chiar daca apar fragmente de cod Python (<b>linia 6</b>)
                                </li>
                            </ul>
                        </li>
                        <li>comentarii pe mai multe linii:
                            <ul>
                                <li>prefixand fiecare linie de comentariu cu <b>#</b> (<b>liniile 8,9,10</b>)</li>
                                <li>prefixand inceputul de comentariu cu 3 apostroafe <b>'''</b> si terminand tot cu <b>'''</b>  (<b>liniile 13 - 17</b>)</li>
                                <li>prefixand inceputul de comentariu cu 3 ghilimele <b>"""</b> si terminand tot cu <b>"""</b>  (<b>liniile 20 - 23</b> sau <b>liniile 26 - 29</b>)</li>
                            </ul>
                        </li>
                    </ul>

                    <i>Exemple:</i>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# primul comentariu\n' +
                        'print("mai sus e primul meu comentariu in Python!")\n' +
                        '\n' +
                        'print("al doilea sta de-a dreapta mea") # al doilea cometariu\n' +
                        '\n' +
                        'print("al treila comentariu") # print("al treila comentariu")\n' +
                        '\n' +
                        '# un elefant\n' +
                        '# doi elefanti\n' +
                        '# trei elefanti\n' +
                        'print("comentariu despre elefanti")\n' +
                        '\n' +
                        '\'\'\'\n' +
                        'al cincilea comentariu\n' +
                        'il scriu pe mai multe \n' +
                        'randuri\n' +
                        '\'\'\'\n' +
                        'print("comentariu e mai sus. hi!")\n' +
                        '\n' +
                        '"""\n' +
                        'al saselea comentariu\n' +
                        'pe mai multe randuri\n' +
                        '"""\n' +
                        'print("nu te uita la mine. vezi mai sus!")\n' +
                        '\n' +
                        '""" bau\n' +
                        'al saptelea comentariu\n' +
                        'pe mai multe randuri\n' +
                        'bau """\n' +
                        'print("gata pentru azi")'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CommentPythonContent;