import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MultipleRemoteRepositoryGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-multiple-remote-repository", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Introducere</b>
                    <br/>
                    <br/>

                    Pentru a afisa toate repository-urile declarate remote pentru un proiect (gestinat de Git):
                    git remote -v
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote -v'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git (fetch)\n' +
                            'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git (push)'}
                    </SyntaxHighlighter>
                    În mod implicit, la clonarea unui depozit/repository va seta adresa URL a acelui depozit ca/cu numele de <b>origin</b>.

                    <br/>
                    <br/>
                    Pentru a afisa adresa URL pentru remote-ul cu numele <b>origin</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote get-url origin'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git'}
                    </SyntaxHighlighter>

                    Pentru a verifica, daca un nume (de exemplu: <i>nasstream</i>) este eligibil de a fi asociat cu o noua adresa URL de repository:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote get-url nasstream'}
                    </SyntaxHighlighter>
                    Daca e disponibil se va afisa:
                    <SyntaxHighlighter>
                        {'fatal: No such remote \'nasstream\''}
                    </SyntaxHighlighter>

                    Pentru a adauga un nou remote (de exemplu cu numele <i>nasstream</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add nasstream REMOTE-URL-2'}
                    </SyntaxHighlighter>

                    Pentru a sterge din lista de repository-uri un remote (de exemplu: <i>nasstream</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote remove nasstream'}
                    </SyntaxHighlighter>
                    Pentru a schimba adresa URL remote (pentru de exemplu <i>nasstream</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote set-url nasstream NEW-REMOTE-URL'}
                    </SyntaxHighlighter>

                    Exemplu (daca se schimba url-ul/IP-ul):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote set-url --push  all ssh://adminkj@192.168.1.11:227/volume1/git/dopamina.git ssh://adminkj@192.168.1.7:227/volume1/git/dopamina.git'}
                    </SyntaxHighlighter>

                    Exemplu (daca se schimba url-ul/IP-ul) [din 192.168.1.11 {"=>"} in 192.168.1.2]:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote set-url --push nasstream ssh://adminkj@192.168.1.2:227/volume1/git/dopamina.git'}
                    </SyntaxHighlighter>

                    Pentru a afisa mai multe informatii despre un remote:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote show origin'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote show nasstream'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Exercitiu practic</b>
                    <br/>
                    <br/>

                    Sa prespunem ca avem un proiect pe <b>bitbucket</b>:
                    <SyntaxHighlighter>
                        {
                            'https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git'
                        }
                    </SyntaxHighlighter>
                    Si il avem clonat local.
                    <br/>
                    Acum vrem sa mai adauga un remote repository (pe github, gitlab, NAS, etc).
                    <br/>
                    <br/>
                    <b>2.1. Configurare pe server</b>
                    <br/>
                    <br/>
                    Vom face acest repository pe un NAS:
                    <SyntaxHighlighter   showLineNumbers={true} language="python" style={androidstudio}>
                        {'ssh admin@192.168.1.255 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'cd /volume1/git'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git init --bare dopamina.git'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {'hint: Using \'master\' as the name for the initial branch. This default branch name\n' +
                            'hint: is subject to change. To configure the initial branch name to use in all\n' +
                            'hint: of your new repositories, which will suppress this warning, call:\n' +
                            'hint:\n' +
                            'hint:   git config --global init.defaultBranch <name>\n' +
                            'hint:\n' +
                            'hint: Names commonly chosen instead of \'master\' are \'main\', \'trunk\' and\n' +
                            'hint: \'development\'. The just-created branch can be renamed via this command:\n' +
                            'hint:\n' +
                            'hint:   git branch -m <name>\n' +
                            'Initialized empty Git repository in /volume1/git/dopamina.git/'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>2.1. Configurare local</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add nasstream ssh://admin@192.168.1.177:/volume1/git/dopamina.git'}
                    </SyntaxHighlighter>

                    Verificare:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote -v\n' +
                            'nasstream       ssh://admin@192.168.1.177:/volume1/git/dopamina.git (fetch)\n' +
                            'nasstream       ssh://admin@192.168.1.177:/volume1/git/dopamina.git (push)\n' +
                            'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git (fetch)\n' +
                            'origin  https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git (push)'}
                    </SyntaxHighlighter>

                    Pentru a copia pe <i>nasstream</i> repository-ul local:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push nasstream master'}
                    </SyntaxHighlighter>
                    Se va afisa:
                    <SyntaxHighlighter>
                        {' git push nasstream master\n' +
                            'admin@192.168.1.177\'s password: \n' +
                            'Enumerating objects: 13123, done.\n' +
                            'Counting objects: 100% (13123/13123), done.\n' +
                            'Delta compression using up to 12 threads\n' +
                            'Compressing objects: 100% (4203/4203), done.\n' +
                            'Writing objects: 100% (13123/13123), 104.87 MiB | 5.72 MiB/s, done.\n' +
                            'Total 13123 (delta 10373), reused 11340 (delta 8879), pack-reused 0\n' +
                            'remote: Resolving deltas: 100% (10373/10373), done.\n' +
                            'To ssh://192.168.1.177:/volume1/git/dopamina.git\n' +
                            ' * [new branch]      master -> master\n'}
                    </SyntaxHighlighter>


                    <br/>
                    <b>2.2. Sincronizare repository-uri</b>
                    <br/>
                    <br/>

                    In acest moment, pentru sincronizare, trebuie sa facem 2 push-uri separate:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push nasstream master'}
                    </SyntaxHighlighter>
                    Dar, aceste repository-uri se pot <i>grupa</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add all https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git\n' +
                            'git remote set-url --add --push all https://letyournailsgrow@bitbucket.org/letyournailsgrow/dopamina.git\n' +
                            'git remote set-url --add --push all ssh://admin@192.168.1.177:/volume1/git/dopamina.git'}
                    </SyntaxHighlighter>
                    Si de acum se poate scrie si asa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push all master'}
                    </SyntaxHighlighter>
                    sau (fara grupare):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push origin master && git push nasstream master'}
                    </SyntaxHighlighter>

                    Se va afisa:
                    <SyntaxHighlighter>
                        {' git push all master\n' +
                            'Enumerating objects: 15, done.\n' +
                            'Counting objects: 100% (15/15), done.\n' +
                            'Delta compression using up to 12 threads\n' +
                            'Compressing objects: 100% (8/8), done.\n' +
                            'Writing objects: 100% (8/8), 1.11 KiB | 1.11 MiB/s, done.\n' +
                            'Total 8 (delta 7), reused 0 (delta 0), pack-reused 0\n' +
                            'To https://bitbucket.org/letyournailsgrow/dopamina.git\n' +
                            '   585b1b2..4f95c97  master -> master\n' +
                            'adminkj@192.168.1.7\'s password: \n' +
                            'Enumerating objects: 15, done.\n' +
                            'Counting objects: 100% (15/15), done.\n' +
                            'Delta compression using up to 12 threads\n' +
                            'Compressing objects: 100% (8/8), done.\n' +
                            'Writing objects: 100% (8/8), 1.11 KiB | 1.11 MiB/s, done.\n' +
                            'Total 8 (delta 7), reused 0 (delta 0), pack-reused 0\n' +
                            'To ssh://192.168.1.177:/volume1/git/dopamina.git\n' +
                            '   585b1b2..4f95c97  master -> master\n'}
                    </SyntaxHighlighter>

                    Din Intellij IDEA 2022:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:775}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/git/img.png'}/>
                    </div>

                    Dupa care se da din meniu: <b>Git {"->"} Fetch</b>.

                    <hr/>
                    <b>3. Extra: local remote</b>
                    <br/>
                    <br/>

                    Se poate creea un repository remote loca de tip bare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'mkdir localRemote\n' +
                            'cd localRemote\n' +
                            'git init --bare'}
                    </SyntaxHighlighter>
                    Aceste local remote se poate adauga la lista remote repository-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote add local [calea catre directorul localRemote]'}
                    </SyntaxHighlighter>
                    Se poate verifica ca s-a adaugat:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'git remote -v'}
                    </SyntaxHighlighter>
                    Se poate face push:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git push local master'}
                    </SyntaxHighlighter>
                    Se poate clona:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git clone [calea catre directorul localRemote]'}
                    </SyntaxHighlighter>

                    {/*//  git clone ssh://admin@192.168.1.255:/volume1/git/test-project.git */}
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                <a target={"_blank"} href={"https://dev.to/eugenedorfling/how-to-manage-multiple-remote-repositories-with-git-terminal-ffp"}>How to manage multiple remote repositories with git (terminal)</a>

                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://jigarius.com/blog/multiple-git-remote-repositories"}>Working with Git remotes and pushing to multiple Git repositories</a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://medium.com/@_oleksii_/how-to-synchronize-two-remote-git-repositories-e63b78892901"}>
                                    How to Synchronize Two Remote Git Repositories.
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://opensource.com/article/20/11/multiple-git-repositories"}>
                                    Keep track of multiple Git remote repositories
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://www.codebales.com/how-synchronize-two-remote-git-repositories"}>
                                    How to synchronize two remote Git repositories
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://vhudyma-blog.eu/git-push-to-multiple-repositories/"}>
                                    Git Push To Multiple Repositories
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://mmikowski.github.io/git-cross-origin/"}>
                                    Using git with multiple remote repositories
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://howto.lintel.in/git-how-to-push-code-to-multiple-remotes-simultaneously/"}>
                                    GIT – How to push code to multiple remotes simultaneously
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultipleRemoteRepositoryGitContent;