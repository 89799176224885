import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ZebraVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-zebra", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Un alt mod de a regla expunerea in timpul fotografierii sau a filmarii, in mod special, este prin afisarea unui model in dungi peste zonele cu o luminozitate specificata (<b>Model Zebra</b>).
                    <br/>

                    Pornind de la Zone System, zonele cu tonuri ale pielei sunt V, VI si VII:

                    <table className={"text-center"}>
                        <thead>
                        <tr>
                            <th>Zona</th>
                            <th>Descriere</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{backgroundColor:'#808080', color: '#fff'}}>V</td>
                            <td>gri 18%: piele închisă la culoare, lemn îmbătrânit,  cer senin de nord aproape de nivelul mării
                            </td>
                        </tr>

                        <tr>
                            <td style={{backgroundColor:'#999999', color: '#000'}}>VI</td>
                            <td>piele caucaziană medie; piatră în lumină; umbre pe zăpadă în peisaje luminate de soare</td>
                        </tr>

                        <tr>
                            <td style={{backgroundColor:'#b3b3b3', color: '#000'}}>VII</td>
                            <td>piele foarte deschisă la culoare; umbre difuze în zăpadă</td>
                        </tr>

                        </tbody>
                    </table>

                    <b>IRE</b> (Institutul de Ingineri Radio / Institute of Radio Enginners) este un mijloc de măsurare a luminozității ca procent relativ din luminozitatea totala.
                    Negrul absolut fiind egal cu 0 IRE, iar albul maxim cu 100 IRE.

                    <br/>
                    <br/>
                    Pentru o expunere corecta a subiectului <b>trebuie ca subiectul sa aiba suficienta lumina pe fata</b>. De obicei, pentru  <b>persoanele cu piele deschisa (caucaziena), valoarea expunerii este de 70 IRE</b>, adică zona VI dacă utilizați <b>sistemul de zone</b>.
                    <br/>
                    Daca o zona este mai luminoasa decat pragul IRE (pragul Zebra) setat pe camera, atunci zona va apare cu un model cu dungi, insemnand ca aceea zona este supraexpusa.

                    <br/>
                    Expuneri corecte:
                    <ul>
                        <li>70 IRE - piele caucaziană</li>
                        <li>60 IRE - piele un pic mai închisă (asiatică, latino)</li>
                        <li>50 IRE - piele închisă (persoane de culoare)</li>
                    </ul>


                    O schimbare de 20% a IRE este echivalent cu 1 stop de expunere: <b>20 IRE = 1 stop de expunere</b>.

                    <hr/>

                    Pe Nikon Z6, pentru a afisa modulul Zebra:
                    <ul>
                        <li><b>Custom Setting Menu {"->"} g Movie {"->"} g6 Highlight display</b>;
                            <br/>
                            daca nu se poate activa - <i>This options is not available at current settings or in the camera's current state.</i>,
                            atunci trebuie dezactivat:
                            <br/><i>Focus Peaking</i>, adica <b>Custom Setting Menu {"->"} d Shooting/display {"->"} d10 Peaking highlights</b></li>
                    </ul>
                    Optiunea <b>Highlight display</b> este utila in mod special pentru <i>videografie</i>.

                    Nikon Z6, nu foloseste IRE, ci nivelul <i>nivelul RGB</i>.

                    <br/>
                    Pentru a regla pragul de luminozitate (valorile IRE de mai jos presupun <b>N-Log</b>; in plus sunt estimari; pentru ca oficial Nikon a publicat doar pentru gri mediu (18%) la 35 IRE):
                    <ul>
                        <li>
                            <b>Custom Setting Menu {"->"} g Movie {"->"} g6 Highlight display {"->"} Highlight display threshold</b>
                            <ul>
                                <li>255 ~ 109 IRE (indica alb pur, imaginea este supraexpusa/arsa) </li>
                                <li>248 ~ 105 IRE <b>valoare implicita</b>, cand incep sa se piarda detaliile</li>
                                <li>235 ~ 100 IRE </li>
                                <li>225 ~ 95 IRE </li>
                                <li>213 ~ 90 IRE </li>
                                <li>202 ~ 85 IRE - evidențierea tonului pielii (N-Log)</li>
                                <li>191 ~ 80 IRE - indicator pentru piele expusa corect (N-Log)</li>
                                <li>180 ~ 75 IRE - maxim pentru toate tonurile de piele din toate profilurile camerei (N-Log)</li>
                            </ul>
                        </li>
                    </ul>

                    Daca nu se filmeaza N-Log, se recomanda setari intre 213 si 235


                    <hr/>

                    <b>Observatie</b>:
                    <br/>
                    Aparatul Nikon Z6 <b>nu permite, in mod film, să fie activate în același timp</b>:
                    <ul>
                        <li> <i>Modul Zebra</i> (<b>Custom Setting Menu {"->"} g Movie {"->"} g6 Highlight display</b>)</li>
                        <li> <i>Focus Peaking</i> (<b>Custom Setting Menu {"->"} d Shooting/display {"->"} d10 Peaking highlights</b>)</li>
                    </ul>

                    <hr/>
                    Setari film:
                    <ul>
                        <li>Movie Shoting Menu {"->"} <b>Frame size/frame rate: 1920x1080; 25p</b> </li>
                        <li>Movie Shoting Menu {"->"} ISO sensitivity settings {"->"}  <b>Auto ISO control (mode M): OFF</b></li>
                        <li>Movie Shoting Menu {"->"} ISO sensitivity settings {"->"}  <b>ISO sensitivity (mode M): 100</b> (de preferat sub 800)</li>
                        <li>Movie Shoting Menu {"->"} White balance:  K</li>
                        <li>-</li>
                        <li>Movie Shoting Menu {'->'} Choose image area: FX</li>
                        <li>Movie Shoting Menu {'->'} Movie quality: HIGH</li>
                        <li>Movie Shoting Menu {'->'} Movie file type: MOV</li>
                        <li>Movie Shoting Menu {'->'} Set Picture Color: Flat / Monochrome</li>
                        <li>Movie Shoting Menu {'->'} Metering: Matrix metering</li>
                        <li>Movie Shoting Menu {'->'} Focus Mode: AF-F Full-time AF</li>
                        <li>Movie Shoting Menu {'->'} AF-area mode: Auto-area AF</li>
                    </ul>

                    <hr/>
                    Pentru fotografii:
                    <ul>
                        <li>Playback Menu {'->'} Playblack options {'->'} Highlights</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://wolfcrow.com/how-to-expose-n-log-for-the-nikon-z6/"}>How to Expose N-Log for the Nikon Z6</a>
                            </div>
                        </li>
                       <li>
                           <div>
                               <a href={"https://herbholderphotography.com/cinemachecklist1"}>Lista de verificare a cinematografiei NIKON Z6 și Z7</a><br/>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://wolfcrow.com/important-quirks-and-features-of-the-nikon-z6-for-cinematography/"}>Important Quirks and Features of the Nikon Z6 for Cinematography</a><br/>
                           </div>
                       </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ZebraVideografieContent;