import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class MonitorDebugK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-monitor-debug", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Monitor & Debug Aplicatii</b>
                    <br/>
                    <br/>

                    Se pot monitoriza:
                    <ul>
                        <li>numarul de noduri dintr-un cluster</li>
                        <li>cate dintre noduri sunt sanatoase</li>
                        <li>metrici de performata per nod: CPU, memory, network, utilizarea spatiu stocare</li>
                        <li>numarul de poduri</li>
                        <li>metrici de performata per pod: CPU, memory consumate</li>
                    </ul>

                    Solutii pentru monitorizare acestor metrici de mai sus, stocarea lor si analizarea acestor date (Kubernetes nu vine cu una implicita):
                    <ul>
                        <li>Metrics Server</li>
                        <li>Prometheus</li>
                        <li>The Elastic Stack</li>
                        <li>Datadog</li>
                        <li>dynatrace</li>
                    </ul>
                    In continuare, vom discuta doar de <b>Metrics Server</b>.
                    Poate exista  un singur <b>Metrics Server</b> per cluster Kubernetes.
                    <br/>
                    <b>Metrics Server</b>:
                    <ul>
                        <li>primeste metrici de la fiecare nod si pod</li>
                        <li>agresa datele</li>
                        <li>stocheaza datele in memorie (este o solutie In-Memory, nu stocheaza pe disc)</li>
                        <li>stocand in memorie, nu se poate obtine date istorice de performanta</li>
                    </ul>
                    Modul in care obtine datele:
                    <ul>
                        <li>Kubernetes ruleaza un agent pe fiecare nod: <b>Kubelet</b> (este reponsabil cu primirea comenzolor de la serverul Kubernetes API master si rularea pod-urilor pe noduri)</li>
                        <li>Kubelet are o subcomponenta: cAdvisor / Container Advisor (este responsabil cu primirea metricilor de performata de la pod si expunerea lor printr-un Kubelet API, pentru a face aceste metrici disponibile pentru Metrics Server)</li>
                    </ul>

                    Folosind minikube, se pot activa metricile:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'minikube addons enable metrics-server'}
                    </SyntaxHighlighter>

                    Folosind alte medii, diferite de minikube:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl apply -f https://github.com/kubernetes-sigs/metrics-server/releases/latest/download/components.yaml'}
                    </SyntaxHighlighter>
                    Comanda de mai sus, deploy un set de pod-uri, servicii si roluri pentru a activa Metrics Server.

                    <hr/>
                    Pentru a afisa metrici per node (<b>Metrics Server</b>):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl top node'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                 CPU(cores)   CPU%   MEMORY(bytes)   MEMORY%   \n' +
                            'nas-ubuntu-kj        112m         5%     4170Mi          71%\n' +
                            'nas-ubuntu-manager   351m         35%    3061Mi          80%\n'}
                    </SyntaxHighlighter>

                    În cazul meu, am întâlnit această eroare:
                    <SyntaxHighlighter>
                        {'Error from server (ServiceUnavailable): the server is currently unable to handle the request (get pods.metrics.k8s.io)\n'}
                    </SyntaxHighlighter>
                    ceea ce înseamnă că serviciul de monitorizare nu este încă disponibil.
                    <br/>
                    Puteți investiga acest lucru și puteți vedea dacă serverul de metrici este instalat corect sau nu cu următoarea comandă:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get apiservices | grep metrics'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'v1beta1.metrics.k8s.io                 kube-system/metrics-server   False (MissingEndpoints)   11m'}
                    </SyntaxHighlighter>

                    Serverul nu este disponibil.<br/>
                    {/*<br/>*/}
                    {/*Se editeaza:*/}
                    {/*<SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>*/}
                    {/*    {'kubectl edit deployments.apps -n kube-system metrics-server'}*/}
                    {/*</SyntaxHighlighter>*/}
                    {/*adaugand:*/}
                    {/*<SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>*/}
                    {/*    {'--kubelet-insecure-tls=true\n' +*/}
                    {/*        '--requestheader-client-ca-file'}*/}
                    {/*</SyntaxHighlighter>*/}

                    {/*<SyntaxHighlighter>*/}
                    {/*    {' spec:\n' +*/}
                    {/*        '      containers:\n' +*/}
                    {/*        '      - args:\n' +*/}
                    {/*        '        - --cert-dir=/tmp\n' +*/}
                    {/*        '        - --secure-port=4443\n' +*/}
                    {/*        '        - --kubelet-preferred-address-types=InternalIP,ExternalIP,Hostname\n' +*/}
                    {/*        '        - --kubelet-use-node-status-port\n' +*/}
                    {/*        '        - --metric-resolution=15s\n' +*/}
                    {/*        '        - --kubelet-insecure-tls=true\n' +*/}
                    {/*        '        - --requestheader-client-ca-file\n'}*/}
                    {/*</SyntaxHighlighter>*/}

                    <hr/>
                    <b>Rezolvare</b>:
                    <br/>
                    <br/>
                    Se creaza un <b>patch</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'nano metrics_patch.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'spec:\n' +
                            '  template:\n' +
                            '    spec:\n' +
                            '      containers:\n' +
                            '        - name: metrics-server\n' +
                            '          command:\n' +
                            '            - /metrics-server\n' +
                            '            - --metric-resolution=30s\n' +
                            '            - --kubelet-insecure-tls=true\n' +
                            '            - --kubelet-preferred-address-types=InternalIP,Hostname,InternalDNS,ExternalDNS,ExternalIP'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl patch deployment metrics-server --patch-file metrics_patch.yaml -n kube-system'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a afisa metrici per pod (<b>Metrics Server</b>):
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl top pod'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                   CPU(cores)   MEMORY(bytes)   \n' +
                            'init-tester            0m           2Mi\n' +
                            'kj-556ff96998-4gm7x    0m           2Mi\n' +
                            'kj-556ff96998-klbh2    0m           2Mi\n' +
                            'nx-76f4798f45-fnq5l    0m           1Mi\n' +
                            'nx-76f4798f45-gcp5w    0m           1Mi\n' +
                            'nx-76f4798f45-mpcn7    0m           1Mi\n' +
                            'nx-76f4798f45-q9h9l    0m           1Mi\n' +
                            'nx2-689f9ccbff-jsxgz   0m           2Mi\n' +
                            'nx2-689f9ccbff-rcnpd   0m           2Mi\n' +
                            'nx2-689f9ccbff-x6w6j   0m           2Mi\n' +
                            'nx2-689f9ccbff-zxkhp   0m           1Mi\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MonitorDebugK8sContent;