import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../angular/IndexContent";

class UniversalAngularContent extends BaseContentPage {

    constructor(props) {
        super(props, "angular-universal", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>1. Angular Universal</b>
                    <br/>
                    <br/>

                    Angular Universal rezolva din problema SEO. O aplicatie Single-Page Application nu poate fi, in mod obisnuit, indexata cu trebuie de un motor de cautare.
                    <hr/>
                    <br/>
                    Instalare si convertire aplicatie in Universal App:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'ng add @nguniversal/express-engine'}
                    </SyntaxHighlighter>

                    Build:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm run build:ssr'}
                    </SyntaxHighlighter>
                    Se va genera in directorul <b>dist</b>, subdirectoarele:
                    <ul>
                        <li>browser</li>
                        <li>server</li>
                    </ul>
                    Pe masina server trebuie puse:
                    <ul>
                        <li>directorul dist</li>
                        <li>
                            angular.json
                        </li>
                        <li>
                            package.json
                        </li>
                    </ul>
                    Apoi, pe masina server, trebuie rulata comanda: <b>npm install</b>
                    <br/>
                    Apoi, pe masina server, trebuie rulata comanda: <b>npm run serve:ssr</b>

                    Rulare in mod <i>dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'npm run dev:ssr'}
                    </SyntaxHighlighter>

                    Dar, Angular Universal, fiind o aplicatie <b>node.js</b> care foloseste framework-ul <b>Express</b>,
                    inseamna ca ca avem nevoie de un server cu <b>node.js</b>

                    <hr/>

                    Cu Angular Universal se poate construi aplicatii full stack cu backend REST API.


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.telerik.com/blogs/angular-14-introducing-standalone-components"}>*/}
                    {/*           Angular 14—Introducing Standalone Components*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default UniversalAngularContent;