import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class WorkspaceGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-workspace", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Creare workspace (Windows)</b>
                    <br/>
                    <br/>
                    Fie urmatoarea locatie:
                    <SyntaxHighlighter>
                        {'C:\\work\\go'}
                    </SyntaxHighlighter>

                    Aici, trebuie create urmatoarele 3 directoare:
                    <ul>
                        <li><b>/src</b>: locatia codului sursa Go (de exemplu: .go)</li>
                        <li><b>/pkg</b>: locatia codului pachetelor compilate (de exemplu: .a)</li>
                        <li>
                            <b>/bin</b>: locatia programelor executabile compilate si construite cu Go
                        </li>
                    </ul>

                    Variabila <b>GOPATH</b> va indica directorul de mai sus: <i>C:\work\go</i> sau <i>D:\work\vanilla-go</i>.
                    <br/>
                    Pot exista mai multe locatii <b>GOPATH</b>. Pentru separare:
                    <ul>
                        <li>in Windows, se foloseste ;</li>
                        <li>pe Linux, se foloseste : </li>
                    </ul>

                    Se poate verifica cu urmatoarea comanda, ce variabile de mediu folosete <b>go</b>:
                    <SyntaxHighlighter>
                        {'go env'}
                    </SyntaxHighlighter>
                    poate afisa (fragment):
                    <SyntaxHighlighter>
                        {'set GOROOT=D:\\Program Files\\Go\n' +
                            'set GOPATH=C:\\Users\\buzdu\\go'}
                    </SyntaxHighlighter>

                    Setare Variabile de mediu:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/go/img.png'}/>
                    </div>

                    Testare:
                    <SyntaxHighlighter>
                        {'go get github.com/golang/example/hello'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Dacă GOPATH conține mai multe intrări, <b>get</b> îl folosește pe primul; in cazul de mai sus: C:\\Users\buzdu\\go
                        </li>
                    </ul>

                    <SyntaxHighlighter>
                        {'./bin/hello.exe'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'Hello, world!'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WorkspaceGoContent;