import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GitLabCIContent extends BaseContentPage {

    constructor(props) {
        super(props, "gitlab-ci", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Exemplu 1:

                    <SyntaxHighlighter>
                        {'# numele acestui fisier trebuie sa fie ".gitlab-ci.yml"\n' +
                            '# acest fisier se pune in radacina proiectului\n' +
                            '\n' +
                            '# exemplu simplu:\n' +
                            '#first job: # numele job-ului: first job\n' +
                            '#  script:\n' +
                            '#    - echo "Hello world"\n' +
                            '\n' +
                            '# daca exista mai multe job-uri, acestea se executa in paralel\n' +
                            '# dar, uneori este necesara definirea unei ordini, de exemplu etapa de testare sa se execute dupa etapa de \'build\'\n' +
                            '# pentru acest lucru exista directiva: "stages"\n' +
                            '\n' +
                            '# directiva "stages" permite declarerea de etape ("stage") si ordinea in care sunt executate\n' +
                            'stages:\n' +
                            '    - build\n' +
                            '    - test\n' +
                            '\n' +
                            '# se defineste un job cu numele "construire-muiere"\n' +
                            '# apoi, se defineste a carei etape corespunde acest job, ex: stage: build \n' +
                            'construire-muiere: \n' +
                            '    stage: build \n' +
                            '    script: # un job contine cel putin un script\n' +
                            '        - mkdir build # se creaza un director numit "build"\n' +
                            '        - cd build # se navigheaza in directorul "build"\n' +
                            '        - touch muiere.txt # se creaza un fisier cu numele "muiere.txt"\n' +
                            '        - echo "cap" >> muiere.txt # se adauga textul "cap" in fisierul "muiere.txt"\n' +
                            '        - echo "picioare" >> muiere.txt\n' +
                            '    # dupa fiecare rulare a job-ului, toate artefactele generate sunt sterse (fisierul  muiere.txt va fi sters, si deci job-ul de testare va esua)    \n' +
                            '    # pentru a se pastra artefactele generate in directorul /build se foloseste "artifacts":\n' +
                            '    artifacts: \n' +
                            '       paths:\n' +
                            '           - build/\n' +
                            '\n' +
                            '# se defineste un job cu numele "testare-muiere"\n' +
                            'testare-muiere:\n' +
                            '    stage: test\n' +
                            '    script:\n' +
                            '        - test -f build/muiere.txt # verific daca exista fisierul creat\n' +
                            '        - cd build\n' +
                            '        - grep "cap" muiere.txt # verific daca exista cap la muiere.txt\n' +
                            '\n' +
                            '# observatie 1:\n' +
                            '# În GitLab CI/CD, dacă aveți mai multe job-uri definite cu același stage, ordinea în care acestea se vor executa este în general  determinată de ordinea în care sunt specificate în fișierul .gitlab-ci.yml. Job-urile sunt executate în ordinea în care sunt definite în fișierul de configurare CI/CD, de la sus la jos.\n' +
                            '\n' +
                            '# observatie 2:\n' +
                            '# Gitlab CI va atribui automat job-urile etapei de „test”, chiar dacă nu a fost definită nicio etapă de testare.    \n' +
                            '\n' +
                            '# observatie 3:\n' +
                            '# Gitlab Runner este instrumentul responsabil pentru rularea efectivă a comenzilor definite într-un job\n' +
                            '# Comenzile definite în interiorul unui job sunt executate pe un Gitlab Runner'}
                    </SyntaxHighlighter>

                    Exemplu 2:
                    <SyntaxHighlighter>
                        {'build website:\n' +
                            '  image: node:lts\n' +
                            '  script:\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby build\n' +
                            '  artifacts:\n' +
                            '    path:\n' +
                            '      - ./public'}
                    </SyntaxHighlighter>

                    Exemplu 3 (job-urile 'test artifact' si 'test website' vor rula in paralel):
                    <SyntaxHighlighter>
                        {'stages:\n' +
                            '  - build\n' +
                            '  - test\n' +
                            '\n' +
                            'build website:\n' +
                            '  stage: build\n' +
                            '  image: node\n' +
                            '  script:\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby build\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - ./public\n' +
                            '\n' +
                            'test artifact:\n' +
                            '  image: alpine\n' +
                            '  stage: test\n' +
                            '  script:\n' +
                            '    - grep -q "Gatsby" ./public/index.html\n' +
                            '\n' +
                            'test website:\n' +
                            '  image: node\n' +
                            '  stage: test\n' +
                            '  script:\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby serve # va rula la nesfarsit; bine, cat e configurat timeout-ul; de obicei maxim 1 ora; procesul trebuie pornit in background;\n' +
                            '    - curl "http://localhost:9000" | tac | tac | grep -q "Gatsby"'}
                    </SyntaxHighlighter>

                    Pentru a porni un proces in background, se foloseste &:
                    <SyntaxHighlighter>
                        {'gatsby serve &'}
                    </SyntaxHighlighter>

                    Se poate astepta un pic pornirea procesului, de exemplu 3 secunde:
                    <SyntaxHighlighter>
                        {'sleep 3'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>Pot exista mai multe pipeline care pot rula in paralel</li>
                        <li>imagine (image: node) se poate defini si global, la inceputul fisierului, pentru a evita sa se repete la fiecare job</li>
                        <li>un job se poate dezactiva daca se pune . in fata numelui job-lui (ex: .test website)</li>
                    </ul>

                    {/*<hr/>*/}
                    {/*Pentru a publica un site static gratis in cloud se poate folosi <b>https://surge.sh/</b>.*/}

                    {/*<br/>*/}
                    {/*Pentru a instala <b>surge</b>:*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'npm install --global surge'}*/}
                    {/*</SyntaxHighlighter>*/}
                    {/*Deploy proiect (care se gaseste local pe:  ~/KJ/Desktop/my-project/ ) pe surge (pe http://my-project.surge.sh):*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'surge\n' +*/}
                    {/*        '  project: ~/KJ/Desktop/my-project/\n' +*/}
                    {/*        '   domain: my-project.surge.sh\n' +*/}
                    {/*        '   upload: [============]\n' +*/}
                    {/*        'Success! Published and running at my-project.surge.sh'}*/}
                    {/*</SyntaxHighlighter>*/}

                    <hr/>
                    Exemplu 4 (java):
                    <SyntaxHighlighter>
                        {'stages:\n' +
                            '  - build\n' +
                            '  - test\n' +
                            '\n' +
                            'build:\n' +
                            '  stage: build\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  script:\n' +
                            '    - ./gradlew build\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - ./build/libs/\n' +
                            '\n' +
                            'smoke test:\n' +
                            '  stage: test\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  before_script:\n' +
                            '    - apk --no-cache add curl\n' +
                            '  script:\n' +
                            '    - java -jar ./build/libs/cars-api.jar &\n' +
                            '    - sleep 30\n' +
                            '    - curl http://localhost:5000/actuator/health | grep "UP"'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu 5 (java-AWS):
                    <SyntaxHighlighter>
                        {'variables:\n' +
                            '  ARTIFACT_NAME: cars-api-v$CI_PIPELINE_IID.jar\n' +
                            '  APP_NAME: cars-api\n' +
                            '\n' +
                            'stages:\n' +
                            '  - build\n' +
                            '  - test\n' +
                            '  - deploy\n' +
                            '\n' +
                            'build:\n' +
                            '  stage: build\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  script:\n' +
                            '    - sed -i "s/CI_PIPELINE_IID/$CI_PIPELINE_IID/" ./src/main/resources/application.yml\n' +
                            '    - sed -i "s/CI_COMMIT_SHORT_SHA/$CI_COMMIT_SHORT_SHA/" ./src/main/resources/application.yml\n' +
                            '    - sed -i "s/CI_COMMIT_BRANCH/$CI_COMMIT_BRANCH/" ./src/main/resources/application.yml\n' +
                            '    - ./gradlew build\n' +
                            '    - mv ./build/libs/cars-api.jar ./build/libs/$ARTIFACT_NAME\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - ./build/libs/\n' +
                            '\n' +
                            'smoke test:\n' +
                            '  stage: test\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  before_script:\n' +
                            '    - apk --no-cache add curl\n' +
                            '  script:\n' +
                            '    - java -jar ./build/libs/$ARTIFACT_NAME &\n' +
                            '    - sleep 30\n' +
                            '    - curl http://localhost:5000/actuator/health | grep "UP"\n' +
                            '\n' +
                            'deploy:\n' +
                            '  stage: deploy\n' +
                            '  image:\n' +
                            '    name: banst/awscli\n' +
                            '    entrypoint: [""]\n' +
                            '  before_script:\n' +
                            '    - apk --no-cache add curl\n' +
                            '    - apk --no-cache add jq\n' +
                            '  script:\n' +
                            '    - aws configure set region us-east-1\n' +
                            '    - aws s3 cp ./build/libs/$ARTIFACT_NAME s3://$S3_BUCKET/$ARTIFACT_NAME\n' +
                            '    - aws elasticbeanstalk create-application-version --application-name $APP_NAME --version-label $CI_PIPELINE_IID --source-bundle S3Bucket=$S3_BUCKET,S3Key=$ARTIFACT_NAME\n' +
                            '    - CNAME=$(aws elasticbeanstalk update-environment --application-name $APP_NAME --environment-name "production" --version-label=$CI_PIPELINE_IID | jq \'.CNAME\' --raw-output)\n' +
                            '    - sleep 45\n' +
                            '    - curl http://$CNAME/actuator/info | grep $CI_PIPELINE_IID\n' +
                            '    - curl http://$CNAME/actuator/health | grep "UP"'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu (testare calitate cod - PMD, teste unitare, testare folosind Postman, publicare pagina cu rezultatele testelor):
                    <SyntaxHighlighter>
                        {'variables:\n' +
                            '  ARTIFACT_NAME: cars-api-v$CI_PIPELINE_IID.jar\n' +
                            '  APP_NAME: cars-api\n' +
                            '\n' +
                            'stages:\n' +
                            '  - build\n' +
                            '  - test\n' +
                            '  - deploy\n' +
                            '  - post deploy\n' +
                            '  - publishing\n' +
                            '\n' +
                            'build:\n' +
                            '  stage: build\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  script:\n' +
                            '    - sed -i "s/CI_PIPELINE_IID/$CI_PIPELINE_IID/" ./src/main/resources/application.yml\n' +
                            '    - sed -i "s/CI_COMMIT_SHORT_SHA/$CI_COMMIT_SHORT_SHA/" ./src/main/resources/application.yml\n' +
                            '    - sed -i "s/CI_COMMIT_BRANCH/$CI_COMMIT_BRANCH/" ./src/main/resources/application.yml\n' +
                            '    - ./gradlew build\n' +
                            '    - mv ./build/libs/cars-api.jar ./build/libs/$ARTIFACT_NAME\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - ./build/libs/\n' +
                            '\n' +
                            'smoke test:\n' +
                            '  stage: test\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  before_script:\n' +
                            '    - apk --no-cache add curl\n' +
                            '  script:\n' +
                            '    - java -jar ./build/libs/$ARTIFACT_NAME &\n' +
                            '    - sleep 30\n' +
                            '    - curl http://localhost:5000/actuator/health | grep "UP"\n' +
                            '\n' +
                            'code quality:\n' +
                            '  stage: test\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  script:\n' +
                            '    - ./gradlew pmdMain pmdTest\n' +
                            '  artifacts:\n' +
                            '    when: always\n' +
                            '    paths:\n' +
                            '      - build/reports/pmd\n' +
                            '\n' +
                            'unit tests:\n' +
                            '  stage: test\n' +
                            '  image: openjdk:12-alpine\n' +
                            '  script:\n' +
                            '    - ./gradlew test\n' +
                            '  artifacts:\n' +
                            '    when: always\n' +
                            '    paths:\n' +
                            '      - build/reports/tests\n' +
                            '    reports:\n' +
                            '      junit: build/test-results/test/*.xml\n' +
                            '\n' +
                            'deploy:\n' +
                            '  stage: deploy\n' +
                            '  image:\n' +
                            '    name: banst/awscli\n' +
                            '    entrypoint: [""]\n' +
                            '  before_script:\n' +
                            '    - apk --no-cache add curl\n' +
                            '    - apk --no-cache add jq\n' +
                            '  script:\n' +
                            '    - aws configure set region us-east-1\n' +
                            '    - aws s3 cp ./build/libs/$ARTIFACT_NAME s3://$S3_BUCKET/$ARTIFACT_NAME\n' +
                            '    - aws elasticbeanstalk create-application-version --application-name $APP_NAME --version-label $CI_PIPELINE_IID --source-bundle S3Bucket=$S3_BUCKET,S3Key=$ARTIFACT_NAME\n' +
                            '    - CNAME=$(aws elasticbeanstalk update-environment --application-name $APP_NAME --environment-name "production" --version-label=$CI_PIPELINE_IID | jq \'.CNAME\' --raw-output)\n' +
                            '    - sleep 45\n' +
                            '    - curl http://$CNAME/actuator/info | grep $CI_PIPELINE_IID\n' +
                            '    - curl http://$CNAME/actuator/health | grep "UP"\n' +
                            '\n' +
                            'api testing:\n' +
                            '  stage: post deploy\n' +
                            '  image:\n' +
                            '    name: vdespa/newman\n' +
                            '    entrypoint: [""]\n' +
                            '  script:\n' +
                            '    - newman --version\n' +
                            '    - newman run "Cars API.postman_collection.json" --environment Production.postman_environment.json --reporters cli,htmlextra,junit --reporter-htmlextra-export "newman/report.html" --reporter-junit-export "newman/report.xml"\n' +
                            '  artifacts:\n' +
                            '    when: always\n' +
                            '    paths:\n' +
                            '      - newman/\n' +
                            '    reports:\n' +
                            '      junit: newman/report.xml\n' +
                            '\n' +
                            'pages:\n' +
                            '  stage: publishing\n' +
                            '  script:\n' +
                            '    - mkdir public\n' +
                            '    - mv newman/report.html public/index.html\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - public'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exemplu (definire variabile - variabiles; mediu - environment; rulare manuala - when: manual; allow_failure: false ):

                    <SyntaxHighlighter>
                        {'image: node:10\n' +
                            '\n' +
                            'stages:\n' +
                            '  - build\n' +
                            '  - test\n' +
                            '  - deploy staging\n' +
                            '  - deploy production\n' +
                            '  - production tests\n' +
                            '\n' +
                            'cache:\n' +
                            '  key: ${CI_COMMIT_REF_SLUG}\n' +
                            '  paths:\n' +
                            '    - node_modules/\n' +
                            '\n' +
                            'variables:\n' +
                            '  STAGING_DOMAIN: instazone-staging.surge.sh\n' +
                            '  PRODUCTION_DOMAIN: instazone.surge.sh\n' +
                            '\n' +
                            'build website:\n' +
                            '  stage: build\n' +
                            '  script:\n' +
                            '    - echo $CI_COMMIT_SHORT_SHA\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby build\n' +
                            '    - sed -i "s/%%VERSION%%/$CI_COMMIT_SHORT_SHA/" ./public/index.html\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - ./public\n' +
                            '\n' +
                            'test artifact:\n' +
                            '  image: alpine\n' +
                            '  stage: test\n' +
                            '  script:\n' +
                            '    - grep -q "Gatsby" ./public/index.html\n' +
                            '\n' +
                            'test website:\n' +
                            '  stage: test\n' +
                            '  script:\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby serve &\n' +
                            '    - sleep 3\n' +
                            '    - curl "http://localhost:9000" | tac | tac | grep -q "Gatsby"\n' +
                            '\n' +
                            'deploy staging: \n' +
                            '  stage: deploy staging\n' +
                            '  environment:\n' +
                            '    name: staging\n' +
                            '    url: http://$STAGING_DOMAIN\n' +
                            '  script:\n' +
                            '    - npm install --global surge\n' +
                            '    - surge --project ./public --domain $STAGING_DOMAIN\n' +
                            '\n' +
                            'deploy production: \n' +
                            '  stage: deploy production\n' +
                            '  environment:\n' +
                            '    name: production\n' +
                            '    url: $PRODUCTION_DOMAIN\n' +
                            '  when: manual\n' +
                            '  allow_failure: false\n' +
                            '  script:\n' +
                            '    - npm install --global surge\n' +
                            '    - surge --project ./public --domain $PRODUCTION_DOMAIN\n' +
                            '\n' +
                            'production tests:\n' +
                            '  image: alpine\n' +
                            '  stage: production tests\n' +
                            '  script:\n' +
                            '    - apk add --no-cache curl\n' +
                            '    - curl -s "https://$PRODUCTION_DOMAIN" | grep -q "Hi people"\n' +
                            '    - curl -s "https://$PRODUCTION_DOMAIN" | grep -q "$CI_COMMIT_SHORT_SHA"'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca vrem ca un job sa ruleze doar daca s-a modificat ceva pe un anumit branch (de exemplu: master), in cadrul job-ului se adauga:
                    <SyntaxHighlighter>
                        {'only:\n' +
                            '  - master'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca vrem sa se apeleze un job cand se intampla ceva, de exemplu s-a facut merge request si vrem sa distrugem un mediu
                    (on_stop):
                    <SyntaxHighlighter>
                        {"deploy review:\n" +
                            "  stage: deploy review\n" +
                            "  only:\n" +
                            "    - merge_requests\n" +
                            "  environment:\n" +
                            "      name: review/$CI_COMMIT_REF_NAME\n" +
                            "      url: https://instazone-$CI_ENVIRONMENT_SLUG.surge.sh\n" +
                            "      on_stop: stop review"}
                    </SyntaxHighlighter>

                    Tot exemplul:
                    <SyntaxHighlighter>
                        {'image: node:10\n' +
                            '\n' +
                            'stages:\n' +
                            '  - build\n' +
                            '  - test\n' +
                            '  - deploy review\n' +
                            '  - deploy staging\n' +
                            '  - deploy production\n' +
                            '  - production tests\n' +
                            '\n' +
                            'cache:\n' +
                            '  key: ${CI_COMMIT_REF_SLUG}\n' +
                            '  paths:\n' +
                            '    - node_modules/\n' +
                            '\n' +
                            'variables:\n' +
                            '  STAGING_DOMAIN: instazone-staging.surge.sh\n' +
                            '  PRODUCTION_DOMAIN: instazone.surge.sh\n' +
                            '\n' +
                            'build website:\n' +
                            '  stage: build\n' +
                            '  only:\n' +
                            '    - master\n' +
                            '    - merge_requests\n' +
                            '  script:\n' +
                            '    - echo $CI_COMMIT_SHORT_SHA\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby build\n' +
                            '    - sed -i "s/%%VERSION%%/$CI_COMMIT_SHORT_SHA/" ./public/index.html\n' +
                            '  artifacts:\n' +
                            '    paths:\n' +
                            '      - ./public\n' +
                            '\n' +
                            'test artifact:\n' +
                            '  image: alpine\n' +
                            '  stage: test\n' +
                            '  only:\n' +
                            '    - master\n' +
                            '    - merge_requests\n' +
                            '  script:\n' +
                            '    - grep -q "Gatsby" ./public/index.html\n' +
                            '\n' +
                            'test website:\n' +
                            '  stage: test\n' +
                            '  only:\n' +
                            '    - master\n' +
                            '    - merge_requests\n' +
                            '  script:\n' +
                            '    - npm install\n' +
                            '    - npm install -g gatsby-cli\n' +
                            '    - gatsby serve &\n' +
                            '    - sleep 3\n' +
                            '    - curl "http://localhost:9000" | tac | tac | grep -q "Gatsby"\n' +
                            '\n' +
                            'deploy review:\n' +
                            '  stage: deploy review\n' +
                            '  only:\n' +
                            '    - merge_requests\n' +
                            '  environment:\n' +
                            '      name: review/$CI_COMMIT_REF_NAME\n' +
                            '      url: https://instazone-$CI_ENVIRONMENT_SLUG.surge.sh\n' +
                            '      on_stop: stop review\n' +
                            '  script:\n' +
                            '    - npm install --global surge\n' +
                            '    - surge --project ./public --domain instazone-$CI_ENVIRONMENT_SLUG.surge.sh\n' +
                            '\n' +
                            'stop review:\n' +
                            '  stage: deploy review\n' +
                            '  only:\n' +
                            '    - merge_requests\n' +
                            '  variables:\n' +
                            '    GIT_STRATEGY: none\n' +
                            '  script:\n' +
                            '    - npm install --global surge\n' +
                            '    - surge teardown instazone-$CI_ENVIRONMENT_SLUG.surge.sh\n' +
                            '  when: manual\n' +
                            '  environment:\n' +
                            '    name: review/$CI_COMMIT_REF_NAME\n' +
                            '    action: stop\n' +
                            '\n' +
                            'deploy staging: \n' +
                            '  stage: deploy staging\n' +
                            '  environment:\n' +
                            '    name: staging\n' +
                            '    url: http://$STAGING_DOMAIN\n' +
                            '  only:\n' +
                            '    - master  \n' +
                            '  script:\n' +
                            '    - npm install --global surge\n' +
                            '    - surge --project ./public --domain $STAGING_DOMAIN\n' +
                            '\n' +
                            'deploy production: \n' +
                            '  stage: deploy production\n' +
                            '  environment:\n' +
                            '    name: production\n' +
                            '    url: $PRODUCTION_DOMAIN\n' +
                            '  only:\n' +
                            '    - master    \n' +
                            '  script:\n' +
                            '    - npm install --global surge\n' +
                            '    - surge --project ./public --domain $PRODUCTION_DOMAIN\n' +
                            '\n' +
                            'production tests:\n' +
                            '  image: alpine\n' +
                            '  stage: production tests\n' +
                            '  only:\n' +
                            '    - master  \n' +
                            '  script:\n' +
                            '    - apk add --no-cache curl\n' +
                            '    - curl -s "https://$PRODUCTION_DOMAIN" | grep -q "Hi people"\n' +
                            '    - curl -s "https://$PRODUCTION_DOMAIN" | grep -q "$CI_COMMIT_SHORT_SHA"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>before_script si after_script</b>
                    <br/>
                    <ul>
                        <li>
                            before_script:
                            <SyntaxHighlighter>
                                {
                                    'before_script:\n' +
                                    '  echo "Start deploy..."'
                                }
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            after_script
                            <SyntaxHighlighter>
                                {
                                    'after_script:\n' +
                                    '  echo "End deploy..."'
                                }
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        {/*<li>*/}
                        {/*    <div>*/}

                        {/*        Shaumik Daityari, Jump Start GIT, Sitepoint, 2020*/}

                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GitLabCIContent;