import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlExposeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-expose", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl expose</b>
                    <br/>
                    <br/>

                    Expunere deployment (se creeza un serviciu de tip <b>ClusterIP</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl expose deployment nginx-deployment --port=8080 --target-port=80'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'service/nginx-deployment exposed'}
                    </SyntaxHighlighter>

                    Afisam serviciile:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get services'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl get svc'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'NAME                          TYPE        CLUSTER-IP       EXTERNAL-IP   PORT(S)    AGE\n' +
                            'nginx-deployment              ClusterIP   10.100.9.236     <none>        8080/TCP   32s'}
                    </SyntaxHighlighter>

                    IP-urile pod-urilor incepe cu 172, in timp ce reteaua cluster incepe cu 10.
                    <br/>
                    Acest IP <i>10.100.9.236</i> se poate folosi pentru a ne conecta la oricare pod din deployment-ul cu numele <i>nginx-deployment</i>.
                    <br/>
                    ClusterIP este disponibil doar in interiorul cluster-ului, nu si in afara acestuia.
                    <ul>
                        <li>
                            curl 10.100.9.236:8080 - nu va functiona din afara cluster-ului Kubernetes
                        </li>
                        <li>
                            daca ne conectam intai la cluster-ul kubernetes:
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'minikube ssh'}
                            </SyntaxHighlighter>
                            atunci
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'curl 10.100.9.236:8080'}
                            </SyntaxHighlighter>
                            va functiona ok; nu ne putem da seama care dintre pod-cele 5 uri a raspuns, dar unul dintre ele a raspuns:)
                        </li>
                    </ul>
                    putem afla mai multe detalii legate de serviciu expus:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe service nginx-deployment'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Name:              nginx-deployment\n' +
                            'Namespace:         default\n' +
                            'Labels:            app=nginx-deployment\n' +
                            'Annotations:       <none>\n' +
                            'Selector:          app=nginx-deployment\n' +
                            'Type:              ClusterIP\n' +
                            'IP Family Policy:  SingleStack\n' +
                            'IP Families:       IPv4\n' +
                            'IP:                10.100.9.236\n' +
                            'IPs:               10.100.9.236\n' +
                            'Port:              <unset>  8080/TCP\n' +
                            'TargetPort:        80/TCP\n' +
                            'Endpoints:         172.17.0.17:80,172.17.0.18:80,172.17.0.19:80 + 2 more...\n' +
                            'Session Affinity:  None\n' +
                            'Events:            <none>\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlExposeContent;