import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class MultiModuleMavenContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-multi-module", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Multi module</b>:
                    <br/>
                    <br/>

                    Generam 3 proiecte folosind:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate'}
                    </SyntaxHighlighter>
                    avand:
                    <ul>
                        <li>
                            acelasi <i>groupId</i>
                        </li>
                        <li>
                            <i>artifactId</i>-uri diferite (care sa reflecte ca 1 din ele este parintele, iar celelalte 2 sunt copii)
                            <br/>
                            De exemplu:
                            <ul>
                                <li>root-module</li>
                                <li>first-module</li>
                                <li>second-module</li>
                            </ul>
                        </li>
                    </ul>

                    In <b>pom.xml</b>-ul din <i>root-module</i> modificam din:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<packaging>jar</packaging>'}
                    </SyntaxHighlighter>
                    in
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<packaging>pom</packaging>'}
                    </SyntaxHighlighter>
                    Si adaugam:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<modules>\n' +
                            '\t<module>first-module</module>\n' +
                            '\t<module>second-module</module>\n' +
                            '</modules>'}
                    </SyntaxHighlighter>

                    sau, daca module sunt la acelasi nivel:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<modules>\n' +
                            '\t<module>../first-module</module>\n' +
                            '\t<module>../second-module</module>\n' +
                            '</modules>'}
                    </SyntaxHighlighter>

                    Acum, in momentul in care executam o comanda pe modulul root (de exemplu, <i>mvn compile</i> sau <i>mvn install</i>),
                    aceasta comanda se va executa si pe copii acestuia.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultiModuleMavenContent;