import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaPLOLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-plo", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. PortalLanguageOverride (PLO)</b>
                    <br/>
                    <br/>

                    Cheie suprascrise (adaugare cheie noua manual din Language Override, modificare cheie manual din Language Override)
                    se tin baza de date in tabela <b>ploentry</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'SELECT * FROM public.ploentry\n' +
                            'ORDER BY ploentryid ASC '}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'"mvccversion","ploentryid","companyid","userid","createdate","modifieddate","key_","languageid","value"\n' +
                            '"0","33403","86163949708218","20122","2024-09-01 08:33:15.2","2024-09-01 08:33:15.2","agnes.btn.save","en_US","Salveaza [LO]"\n'}
                    </SyntaxHighlighter>


                    Din UI:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x2.png'}/>
                    </div>

                    Se apasa Edit:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x3.png'}/>
                    </div>

                    Se modifica:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x4.png'}/>
                    </div>

                    Dupa ce se salveaza:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x5.png'}/>
                    </div>

                    Apoi, in ecranul unde se foloseste aceea cheie, se actualizeaza in mod automat, si fara restart de server eticheta:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x6.png'}/>
                    </div>

                    <hr/>
                    <b>Anexa: Global Resource Bundle</b>

                    <br/>
                    <br/>

                    build.gradle:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies {\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "release.portal.api", version: "7.4.3.120-ga120"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Bundle-Name: agnes-global-resource-bundle\n' +
                            'Bundle-SymbolicName: ro.agnes.global.resource.bundle\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Export-Package: ro.agnes.global.resource.bundle\n'}
                    </SyntaxHighlighter>

                    ResourceBundeAPI:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.global.resource.bundle;\n' +
                            '\n' +
                            'public interface ResourceBundeAPI {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ResourceBundleService:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.global.resource.bundle.impl;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import org.osgi.service.component.annotations.Activate;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.agnes.global.resource.bundle.ResourceBundeAPI;\n' +
                            '\n' +
                            '@Component(service = ResourceBundeAPI.class)\n' +
                            'public class ResourceBundleService implements ResourceBundeAPI {\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    public void activate() {\n' +
                            '        _log.debug("Activating ResourceBundleService");\n' +
                            '    }\n' +
                            '\n' +
                            '    private static final Log _log = LogFactoryUtil.getLog(ResourceBundleService.class);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    EnglishResourceBundle:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.global.resource.bundle;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.language.UTF8Control;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            'import java.util.Enumeration;\n' +
                            'import java.util.ResourceBundle;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {"language.id=en_US"},\n' +
                            '        service = ResourceBundle.class\n' +
                            ')\n' +
                            'public class EnglishResourceBundle extends ResourceBundle {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected Object handleGetObject(String key) {\n' +
                            '        return _resourceBundle.getObject(key);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Enumeration<String> getKeys() {\n' +
                            '        return _resourceBundle.getKeys();\n' +
                            '    }\n' +
                            '\n' +
                            '    private final ResourceBundle _resourceBundle = ResourceBundle.getBundle("content.Language_en_US", UTF8Control.INSTANCE);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    resources/content/Language_en_US.properties:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'agnes.book.add = Adauga carte\n' +
                            'agnes.btn.save = Salveaza\n' +
                            'agnes.book.edit = Editeaza\n' +
                            '\n' +
                            'agnes.book.title = Titlu\n' +
                            'agnes.book.description = Descriere\n' +
                            'agnes.book.publicationYear = Anul publicarii\n' +
                            'agnes.book.actions = Actiuni'}
                    </SyntaxHighlighter>

                    <hr/>

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.global.resource.bundle;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.cache.PortalCache;\n' +
                            'import com.liferay.portal.kernel.cache.PortalCacheHelperUtil;\n' +
                            'import com.liferay.portal.kernel.cache.PortalCacheManagerNames;\n' +
                            'import com.liferay.portal.kernel.dao.orm.QueryUtil;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.language.override.model.PLOEntry;\n' +
                            'import com.liferay.portal.language.override.service.PLOEntryLocalServiceUtil;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.agnes.service.builder.model.AgnesLabels;\n' +
                            'import ro.agnes.service.builder.service.AgnesLabelsLocalServiceUtil;\n' +
                            '\n' +
                            'import java.util.*;\n' +
                            'import java.util.stream.Collectors;\n' +
                            '\n' +
                            '//@Component(\n' +
                            '//        property = {"language.id=en_US"},\n' +
                            '//        service = ResourceBundle.class\n' +
                            '//)\n' +
                            'public class DatabaseResourceBundle extends ResourceBundle{\n' +
                            '\n' +
                            '    private Locale defaultLocale = Locale.ENGLISH;\n' +
                            '\n' +
                            '    private Locale locale;\n' +
                            '\n' +
                            '    public DatabaseResourceBundle() {\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    public DatabaseResourceBundle(Locale locale) {\n' +
                            '        this.locale = locale;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected Object handleGetObject(String key) {\n' +
                            '        String translation = getTranslationFromDatabaseOrFallback(key, _getLocale());\n' +
                            '        return translation;\n' +
                            '    }\n' +
                            '\n' +
                            '    private String _getLocale() {\n' +
                            '        if (this.locale != null) {\n' +
                            '            return this.locale.getLanguage();\n' +
                            '        }\n' +
                            '        Locale l = getLocale();\n' +
                            '        if (l!=null){\n' +
                            '            return l.getLanguage();\n' +
                            '        }\n' +
                            '        return defaultLocale.getLanguage();\n' +
                            '    }\n' +
                            '\n' +
                            '    private String getTranslationFromDatabaseOrFallback(String key, String locale) {\n' +
                            '        PortalCache<String, AgnesLabels> portalCache = PortalCacheHelperUtil.getPortalCache(PortalCacheManagerNames.MULTI_VM, getClass().getName());\n' +
                            '        Locale labelLocale = new Locale(locale);\n' +
                            '        String label;\n' +
                            '        AgnesLabels labels = portalCache == null ? null : portalCache.get(key);\n' +
                            '        // put in cache\n' +
                            '        if (labels == null) {\n' +
                            '            labels = AgnesLabelsLocalServiceUtil.fetchBycode(key);\n' +
                            '            if (labels != null && portalCache != null) {\n' +
                            '                portalCache.put(key, labels);\n' +
                            '            }\n' +
                            '        }\n' +
                            '        if (labels != null) {\n' +
                            '            label = labels.getLabel(labelLocale);\n' +
                            '            if (label == null || label.isEmpty()) {\n' +
                            '                //default fallback\n' +
                            '                label = labels.getLabel(defaultLocale);\n' +
                            '            }\n' +
                            '            return label;\n' +
                            '        } else {\n' +
                            '            log.error("Label does not exist for key: " + key);\n' +
                            '            return key;\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Enumeration<String> getKeys() {\n' +
                            '\n' +
                            '\n' +
                            '//        List<PLOEntry> ploEntryList = PLOEntryLocalServiceUtil.getPLOEntries(86163949708218L, "en_US");\n' +
                            '//        for (PLOEntry entry : ploEntryList) {\n' +
                            '//            if (entry.getKey().equals("agnes2.test")){\n' +
                            '//                entry.setValue("KJ");\n' +
                            '//            }\n' +
                            '//            PLOEntryLocalServiceUtil.updatePLOEntry(entry);\n' +
                            '//        }\n' +
                            '\n' +
                            '\n' +
                            '        return Collections.enumeration(AgnesLabelsLocalServiceUtil.getAgnesLabelses(QueryUtil.ALL_POS, QueryUtil.ALL_POS)\n' +
                            '                .stream()\n' +
                            '                .map(label -> label.getCode())\n' +
                            '                .collect(Collectors.toList()));\n' +
                            '    }\n' +
                            '\n' +
                            '    private static Log log = LogFactoryUtil.getLog(DatabaseResourceBundle.class);\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private ResourceBundeAPI resourceBundeAPI;\n' +
                            '\n' +
                            '//    @Reference\n' +
                            '//    private PLOEntryLocalService ploEntryLocalService;\n' +
                            '//\n' +
                            '//    @Reference\n' +
                            '//    private PLOEntryPersistence ploEntryPersistence;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.global.resource.bundle;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.language.LanguageUtil;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.util.HashMapDictionaryBuilder;\n' +
                            'import org.osgi.framework.BundleContext;\n' +
                            'import org.osgi.service.component.annotations.Activate;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            'import java.util.Locale;\n' +
                            'import java.util.Map;\n' +
                            'import java.util.ResourceBundle;\n' +
                            'import java.util.Set;\n' +
                            '\n' +
                            '@Component\n' +
                            'public class DatabaseResourceBundleActivator {\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    public void activate(BundleContext bundleContext, Map<String, Object> properties) {\n' +
                            '\n' +
                            '        Set<Locale> availableLocales = LanguageUtil.getAvailableLocales();\n' +
                            '\n' +
                            '        for (Locale locale : availableLocales) {\n' +
                            '\n' +
                            '           bundleContext.registerService(ResourceBundle.class,\n' +
                            '                   new DatabaseResourceBundle(locale),\n' +
                            '                   HashMapDictionaryBuilder.<String, Object>put(\n' +
                            '                         "language.id", locale.getLanguage()\n' +
                            '                    ).build());\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(DatabaseResourceBundleActivator.class);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaPLOLiferayContent;