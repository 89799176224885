import {useRoutes} from "react-router-dom";
import React from "react";
import AngularHomeContent from "./IndexContent";
import PrivateRoute from "../../security/PrivateRoute";
import InstallAngularContent from "./InstallAngularContent";
import CreateProiectAngularContent from "./CreateProiectAngularContent";
import CreateComponentAngularContent from "./CreateComponentAngularContent";
import CustomFileUploadAngularContent from "./CustomFileUploadAngularContent";
import UpdateChildParentAngularContent from "./UpdateChildParentAngularContent";
import UpdateParentChildAngularContent from "./UpdateParentChildAngularContent";
import StringInterpolationAngularContent from "./StringInterpolationAngularContent";
import PropertyBindingAngularContent from "./PropertyBindingAngularContent";
import EventBindingAngularContent from "./EventBindingAngularContent";
import TwoWayBindingAngularContent from "./TwoWayBindingAngularContent";
import DirectivesAngularContent from "./DirectivesAngularContent";
import LocalReferencesAngularContent from "./LocalReferencesAngularContent";
import InjectContentAngularContent from "./InjectContentAngularContent";
import LifecycleAngularContent from "./LifecycleAngularContent";
import ServicesAngularContent from "./ServicesAngularContent";
import RoutingAngularContent from "./RoutingAngularContent";
import CanDeactivateAngularContent from "./CanDeactivateAngularContent";
import CanActivateAngularContent from "./CanActivateAngularContent";
import DataToRouteAngularContent from "./DataToRouteAngularContent";
import PipeAngularContent from "./PipeAngularContent";
import ObservableAngularContent from "./ObservableAngularContent";
import FormAngularContent from "./FormAngularContent";
import ModuleAngularContent from "./ModuleAngularContent";
import SlotAngularContent from "./SlotAngularContent";
import HttpAngularContent from "./HttpAngularContent";
import SelectAngularContent from "./SelectAngularContent";
import NgSelectAngularContent from "./NgSelectAngularContent";
import NgRxAngularContent from "./NgRxAngularContent";
import NgRxEffectsAngularContent from "./NgRxEffectsAngularContent";
import DeployAngularContent from "./DeployAngularContent";
import SubjectAngularContent from "./SubjectAngularContent";
import CustomValidatorAngularContent from "./CustomValidatorAngularContent";
import TestsAngularContent from "./TestsAngularContent";
import CypressAngularContent from "./CypressAngularContent";
import DynamicComponentsAngularContent from "./DynamicComponentsAngularContent";
import StandaloneComponentsAngularContent from "./StandaloneComponentsAngularContent";
import SignalsAngularContent from "./SignalsAngularContent";
import UniversalAngularContent from "./UniversalAngularContent";
import WorkerAngularContent from "./WorkerAngularContent";
import CliAngularContent from "./CliAngularContent";
import AngularJsonAngularContent from "./AngularJsonAngularContent";
import BrowserslistAngularContent from "./BrowserslistAngularContent";
import MultiProjectsAngularContent from "./MultiProjectsAngularContent";
import TapRxJsAngularContent from "./TapRxJsAngularContent";
import OfRxJsAngularContent from "./OfRxJsAngularContent";
import FunctionInjectAngularContent from "./FunctionInjectAngularContent";
import NgRxNewAngularContent from "./NgRxNewAngularContent";
import NgRxEffectsNewAngularContent from "./NgRxEffectsNewAngularContent";

/*
<Route path="/angular/index" element={<AngularHomeContent/>} />
<Route element={<PrivateRoutes/>}>
    <Route path="/angular/install" element={<InstallAngularContent/>} />
    <Route path="/angular/create-project" element={<CreateProiectAngularContent/>} />
    <Route path="/angular/create-component" element={<CreateComponentAngularContent/>} />
    <Route path="/angular/custom-file-upload" element={<CustomFileUploadAngularContent/>} />
    <Route path="/angular/update-child-parent" element={<UpdateChildParentAngularContent/>} />
    <Route path="/angular/update-parent-child" element={<UpdateParentChildAngularContent/>} />
    <Route path="/angular/string-interpolation" element={<StringInterpolationAngularContent/>} />
    <Route path="/angular/property-binding" element={<PropertyBindingAngularContent/>} />
    <Route path="/angular/event-binding" element={<EventBindingAngularContent/>} />
    <Route path="/angular/two-way-binding" element={<TwoWayBindingAngularContent/>} />
    <Route path="/angular/directives" element={<DirectivesAngularContent/>} />
    <Route path="/angular/local-references" element={<LocalReferencesAngularContent/>} />
    <Route path="/angular/inject-content" element={<InjectContentAngularContent/>} />
    <Route path="/angular/lifecycle" element={<LifecycleAngularContent/>} />
    <Route path="/angular/services" element={<ServicesAngularContent/>} />
    <Route path="/angular/routing" element={<RoutingAngularContent/>} />
    <Route path="/angular/can-deactivate" element={<CanDeactivateAngularContent/>} />
    <Route path="/angular/can-activate" element={<CanActivateAngularContent/>} />
    <Route path="/angular/data-to-route" element={<DataToRouteAngularContent/>} />
    <Route path="/angular/pipe" element={<PipeAngularContent/>} />
    <Route path="/angular/observable" element={<ObservableAngularContent/>} />
    <Route path="/angular/form" element={<FormAngularContent/>} />
    <Route path="/angular/module" element={<ModuleAngularContent/>} />
    <Route path="/angular/slot" element={<SlotAngularContent/>} />
    <Route path="/angular/http" element={<HttpAngularContent/>} />
    <Route path="/angular/select" element={<SelectAngularContent/>} />
    <Route path="/angular/ng-select" element={<NgSelectAngularContent/>} />
</Route>
 */
export default function Router() {
    let element = useRoutes([
        {path: "/angular/index", element: <AngularHomeContent/>},
        {path: "/angular/install", element: <PrivateRoute element={<InstallAngularContent/>}/>},
        {path: "/angular/create-project", element: <PrivateRoute element={<CreateProiectAngularContent/>} />},
        {path: "/angular/create-component", element: <PrivateRoute element={<CreateComponentAngularContent/>} />},
        {path: "/angular/custom-file-upload", element: <PrivateRoute element={<CustomFileUploadAngularContent/>} />},
        {path: "/angular/update-child-parent", element: <PrivateRoute element={<UpdateChildParentAngularContent/>} />},
        {path: "/angular/update-parent-child", element: <PrivateRoute element={<UpdateParentChildAngularContent/>} />},
        {path: "/angular/string-interpolation", element: <PrivateRoute element={<StringInterpolationAngularContent/>} />},
        {path: "/angular/property-binding", element: <PrivateRoute element={<PropertyBindingAngularContent/>} />},
        {path: "/angular/event-binding", element: <PrivateRoute element={<EventBindingAngularContent/>} />},
        {path: "/angular/two-way-binding", element: <PrivateRoute element={<TwoWayBindingAngularContent/>} />},
        {path: "/angular/directives", element: <PrivateRoute element={<DirectivesAngularContent/>} />},
        {path: "/angular/local-references", element: <PrivateRoute element={<LocalReferencesAngularContent/>} />},
        {path: "/angular/inject-content", element: <PrivateRoute element={<InjectContentAngularContent/>} />},
        {path: "/angular/lifecycle", element: <PrivateRoute element={<LifecycleAngularContent/>} />},
        {path: "/angular/services", element: <PrivateRoute element={<ServicesAngularContent/>} />},
        {path: "/angular/routing", element: <PrivateRoute element={<RoutingAngularContent/>} />},
        {path: "/angular/can-deactivate", element: <PrivateRoute element={<CanDeactivateAngularContent/>} />},
        {path: "/angular/can-activate", element: <PrivateRoute element={<CanActivateAngularContent/>} />},
        {path: "/angular/data-to-route", element: <PrivateRoute element={<DataToRouteAngularContent/>} />},
        {path: "/angular/pipe", element: <PrivateRoute element={<PipeAngularContent/>} />},
        {path: "/angular/observable", element: <PrivateRoute element={<ObservableAngularContent/>} />},
        {path: "/angular/form", element: <PrivateRoute element={<FormAngularContent/>} />},
        {path: "/angular/module", element: <PrivateRoute element={<ModuleAngularContent/>} />},
        {path: "/angular/slot", element: <PrivateRoute element={<SlotAngularContent/>} />},
        {path: "/angular/http", element: <PrivateRoute element={<HttpAngularContent/>} />},
        {path: "/angular/select", element: <PrivateRoute element={<SelectAngularContent/>} />},
        {path: "/angular/ng-select", element: <PrivateRoute element={<NgSelectAngularContent/>} />},
        {path: "/angular/ngrx", element: <PrivateRoute element={<NgRxAngularContent/>} />},
        {path: "/angular/ngrx-effects", element: <PrivateRoute element={<NgRxEffectsAngularContent/>} />},
        {path: "/angular/deploy", element: <PrivateRoute element={<DeployAngularContent/>} />},
        {path: "/angular/subject", element: <PrivateRoute element={<SubjectAngularContent/>} />},
        {path: "/angular/custom-validator", element: <PrivateRoute element={<CustomValidatorAngularContent/>} />},
        {path: "/angular/tests", element: <PrivateRoute element={<TestsAngularContent/>} />},
        {path: "/angular/cypress", element: <PrivateRoute element={<CypressAngularContent/>} />},
        {path: "/angular/dynamic-components", element: <PrivateRoute element={<DynamicComponentsAngularContent/>} />},
        {path: "/angular/standalone-components", element: <PrivateRoute element={<StandaloneComponentsAngularContent/>} />},
        {path: "/angular/signals", element: <PrivateRoute element={<SignalsAngularContent/>} />},
        {path: "/angular/universal", element: <PrivateRoute element={<UniversalAngularContent/>} />},
        {path: "/angular/worker", element: <PrivateRoute element={<WorkerAngularContent/>} />},
        {path: "/angular/cli", element: <PrivateRoute element={<CliAngularContent/>} />},
        {path: "/angular/angular-json", element: <PrivateRoute element={<AngularJsonAngularContent/>} />},
        {path: "/angular/browserslist", element: <PrivateRoute element={<BrowserslistAngularContent/>} />},
        {path: "/angular/multi-projects", element: <PrivateRoute element={<MultiProjectsAngularContent/>} />},
        {path: "/angular/rxjs-tap", element: <PrivateRoute element={<TapRxJsAngularContent/>} />},
        {path: "/angular/rxjs-of", element: <PrivateRoute element={<OfRxJsAngularContent/>} />},
        {path: "/angular/function-inject", element: <PrivateRoute element={<FunctionInjectAngularContent/>} />},
        {path: "/angular/ngrx-new", element: <PrivateRoute element={<NgRxNewAngularContent/>} />},
        {path: "/angular/ngrx-effects-new", element: <PrivateRoute element={<NgRxEffectsNewAngularContent/>} />},
    ]);

    return element;
}