export default class Util{

    // random number bettween -1,1
    static random(){
        return (Math.random() - 0.5) * 2;
    }

    static map(value, inputBegin, inputEnd, outputBegin, outputEnd) {
        const r = (value - inputBegin) / (inputEnd - inputBegin);
        return outputBegin + r * (outputEnd - outputBegin);
    }

    static randomNormal() {
        let v1 = 2 * Math.random() - 1;
        let v2 = 2 * Math.random() - 1;
        let s = v1*v1 + v2*v2;

        while (s > 1) {
            v1 = 2 * Math.random() - 1;
            v2 = 2 * Math.random() - 1;
            s = v1*v1 + v2*v2;
        }

        return v1 * (Math.sqrt(-2*Math.log(s)/s));
    }

    static randomGaussian(mean,variance) {
        return (Math.sqrt(variance)*Util.randomNormal() + mean);
    }

}