import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PreopulationFieldsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-prepopulation-fields", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'    @Reference\n' +
                            '    private DDMFormValuesFactory _ddmFormValuesFactory;\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String renderForm(DDMFormInstance formInstance, PortletRequest portletRequest, PortletResponse portletResponse, boolean readOnlyForm)\n' +
                            '            throws FormRenderException {\n' +
                            '\n' +
                            '        DDMForm ddmForm = null;\n' +
                            '        DDMFormLayout ddmFormLayout = null;\n' +
                            '        ThemeDisplay themeDisplay = (ThemeDisplay) portletRequest.getAttribute(WebKeys.THEME_DISPLAY);\n' +
                            '\n' +
                            '        try {\n' +
                            '            DDMStructureVersion ddmStructureVersion = formInstance.getStructure().getLatestStructureVersion();\n' +
                            '            ddmForm = ddmStructureVersion.getDDMForm();\n' +
                            '\n' +
                            '            List<DDMFormField> fieldsList = ddmForm.getDDMFormFields();\n' +
                            '\n' +
                            '            boolean mustSignedIn = themeDisplay.isSignedIn();\n' +
                            '\n' +
                            '            //if (mustSignedIn) {\n' +
                            '                //long userId = themeDisplay.getUserId();\n' +
                            '                prePopulation(fieldsList, portletRequest, getContextValues());\n' +
                            '           // }\n' +
                            '\n' +
                            '\n' +
                            '            ddmFormLayout = ddmStructureVersion.getDDMFormLayout();\n' +
                            '\n' +
                            '        } catch (PortalException e) {\n' +
                            '            log.error("Error retrieving form structure and layout: " + e.getMessage(), e);\n' +
                            '        }\n' +
                            '\n' +
                            '        if (ddmForm == null || ddmFormLayout == null) {\n' +
                            '            throw new FormRenderException("Cannot render form. ddmForm or ddmFormLayout is empty.");\n' +
                            '        }\n' +
                            '\n' +
                            '\n' +
                            '        DDMFormRenderingContext ddmFormRenderingContext = new DDMFormRenderingContext();\n' +
                            '        ddmFormRenderingContext.setContainerId("ddmForm".concat(StringUtil.randomString()));\n' +
                            '        ddmFormRenderingContext.setDDMFormValues(_ddmFormValuesFactory.create(portletRequest, ddmForm));\n' +
                            '\n' +
                            '        ddmFormRenderingContext.setHttpServletRequest(PortalUtil.getHttpServletRequest(portletRequest));\n' +
                            '        ddmFormRenderingContext.setHttpServletResponse(PortalUtil.getHttpServletResponse(portletResponse));\n' +
                            '        ddmFormRenderingContext.setLocale(PortalUtil.getLocale(portletRequest));\n' +
                            '        ddmFormRenderingContext.setPortletNamespace(portletResponse.getNamespace());\n' +
                            '        ddmFormRenderingContext.setViewMode(true);\n' +
                            '        ddmFormRenderingContext.setGroupId(themeDisplay.getSiteGroupId());\n' +
                            '        ddmFormRenderingContext.setSharedURL(true);\n' +
                            '\n' +
                            '        ddmFormRenderingContext.addProperty("formInstanceId", formInstance.getFormInstanceId());\n' +
                            '        //ddmFormRenderingContext.setReadOnly(readOnlyForm);          \n' +
                            '\n' +
                            '        return generateFormHTML(ddmForm, ddmFormLayout, ddmFormRenderingContext);\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    private Map<String, Object> getContextValues() {\n' +
                            '        Map<String, Object> contextValues = new HashMap<>();\n' +
                            '        contextValues.put("Text22380010", "KJ");\n' +
                            '        return contextValues;\n' +
                            '    }\n' +
                            '\n' +
                            '    private void prePopulation(List<DDMFormField> fieldsList, PortletRequest portletRequest, Map<String, Object> contextValues) {\n' +
                            '        for (DDMFormField ddmFormField : fieldsList) {\n' +
                            '\n' +
                            '            String fieldReferece = ddmFormField.getFieldReference().toString();\n' +
                            '\n' +
                            '            LocalizedValue lV = new LocalizedValue(portletRequest.getLocale());\n' +
                            '            lV.addString(portletRequest.getLocale(), (String) contextValues.get(fieldReferece));\n' +
                            '            //Populate field\n' +
                            '            ddmFormField.setPredefinedValue(lV);\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Pentru a gestiona si campurile incuibarite:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private void prePopulation(List<DDMFormField> fieldsList, PortletRequest portletRequest, Map<String, Object> contextValues) {\n' +
                            '\t\tfor (DDMFormField ddmFormField : fieldsList) {\n' +
                            '\t\t\tList<DDMFormField> nestedDDMFormField = ddmFormField.getNestedDDMFormFields();\n' +
                            '\t\t\tif (nestedDDMFormField!=null){\n' +
                            '\t\t\t\tprePopulation(nestedDDMFormField, portletRequest, contextValues);\n' +
                            '\t\t\t}\n' +
                            '\t\t\tString fieldReferece = ddmFormField.getFieldReference().toString();\n' +
                            '\t\t\tLocalizedValue lV = new LocalizedValue(portletRequest.getLocale());\n' +
                            '\t\t\tString value = (String) contextValues.get(fieldReferece);\n' +
                            '\t\t\tif (value!=null) {\n' +
                            '\t\t\t\tlV.addString(portletRequest.getLocale(), value);\n' +
                            '\t\t\t\tddmFormField.setPredefinedValue(lV);\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t}'}
                    </SyntaxHighlighter>

                    Un camp poate fi setat ca readonly:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ddmFormField.setReadOnly(Boolean.TRUE);'}
                    </SyntaxHighlighter>

                    Unui camp i se poate seta vizibilitatea:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'private void setFieldVisibility(DDMForm ddmForm, String fieldName, String condition, boolean visible) {\n' +
                            '        if (ddmForm == null) {\n' +
                            '            return;\n' +
                            '        }\n' +
                            '        if (ddmForm.getDDMFormRules() == null) {\n' +
                            '            ddmForm.setDDMFormRules(new ArrayList<>());\n' +
                            '        }\n' +
                            '\n' +
                            '        DDMFormRule visibilityFieldRule = new DDMFormRule();\n' +
                            '\n' +
                            '        String action = "setVisible(\'" + fieldName + "\', " + visible + ")";\n' +
                            '        visibilityFieldRule.setActions(Collections.singletonList(action));\n' +
                            '\n' +
                            '        visibilityFieldRule.setCondition(condition);\n' +
                            '        visibilityFieldRule.setEnabled(true);\n' +
                            '\n' +
                            '        ddmForm.getDDMFormRules().add(visibilityFieldRule);\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    Exemplu setare vizibilitate:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'setFieldVisibility(ddmForm, FIELD_DOCUMENT_TYPE, "true", false);'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PreopulationFieldsLiferayContent;