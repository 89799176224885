import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../tomcat/IndexContent";

class ClusteringTomcatContent extends BaseContentPage  {

    constructor(props) {
        super(props, "tomcat-clustering", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Vertical cluster</b>
                    <br/>
                    <br/>

                    <b>Vertical cluster inseamna rularea a N instante de Tomcat (cu aceeasi aplicatie) pe acceasi masina.</b>.

                    <br/>
                    In mare, se pornesc N instante de Tomcat pe porturi diferite.

                    <br/>
                    <br/>
                    Vom creaza 3 instante de Tomcat.
                    <br/>
                    Avem server-ul de baza: <i>apache-tomcat-10.0.23</i>.
                    <br/>
                    <br/>
                    <b>1.1. Creare instante</b>
                    <br/>
                    <br/>
                    Aici vom creea folderul: <i>apache-instances</i>.
                    <br/>

                    In interiorul <i>apache-instances</i> vom creea 3 foldere:
                    <ul>
                        <li>instance1</li>
                        <li>instance2</li>
                        <li>instance3</li>
                    </ul>
                    si in interiorul fiecaruia vom copia urmatoarele foldere din serverul de baza <i>apache-tomcat-10.0.23</i>:
                    <ul>
                        <li>conf</li>
                        <li>logs</li>
                        <li>temp</li>
                        <li>webapps</li>
                        <li>work</li>
                    </ul>


                    <b>1.2. Schimbare porturi</b>
                    <br/>
                    <br/>

                    Pentru fiecare instanta, in <b>conf/server.xml</b> se schimba porturile pentru:
                    <ul>
                        <li>
                            SHUTDOWN

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Server port="8605" shutdown="SHUTDOWN">'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Server port="8705" shutdown="SHUTDOWN">'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Server port="8805" shutdown="SHUTDOWN">'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            Connector / protocol="HTTP/1.1"

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector port="8680" protocol="HTTP/1.1"\n' +
                                    '               connectionTimeout="20000"\n' +
                                    '               redirectPort="8443" />'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector port="8780" protocol="HTTP/1.1"\n' +
                                    '               connectionTimeout="20000"\n' +
                                    '               redirectPort="8443" />'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector port="8880" protocol="HTTP/1.1"\n' +
                                    '               connectionTimeout="20000"\n' +
                                    '               redirectPort="8443" />'}
                            </SyntaxHighlighter>

                        </li>
                        <li>

                            Connector / protocol="AJP/1.3" (daca este decomentat)

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector protocol="AJP/1.3"\n' +
                                    '               address="::1"\n' +
                                    '               port="8609"\n' +
                                    '               redirectPort="8443" />'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector protocol="AJP/1.3"\n' +
                                    '               address="::1"\n' +
                                    '               port="8709"\n' +
                                    '               redirectPort="8443" />'}
                            </SyntaxHighlighter>

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'<Connector protocol="AJP/1.3"\n' +
                                    '               address="::1"\n' +
                                    '               port="8809"\n' +
                                    '               redirectPort="8443" />'}
                            </SyntaxHighlighter>

                        </li>
                    </ul>

                    <b>1.3. Creare scripturi</b>
                    <br/>
                    <br/>
                    La acelasi nivel cu <i>apache-instances</i> vom creea folderul: <i>scripts</i>.
                    <br/>
                    Aici vom creea 6 scripturi (de pornire si oprire cele 3 instante):
                    <ul>
                        <li>startup-instance1.bat
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    'set CATALINA_BASE=D:\\servers\\apache-instances\\instance1\n' +
                                    '\n' +
                                    'cd %CATALINA_HOME%\\bin\n' +
                                    'startup.bat'}
                            </SyntaxHighlighter>

                        </li>
                        <li>startup-instance2.bat

                                <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                    {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                        'set CATALINA_BASE=D:\\servers\\apache-instances\\instance2\n' +
                                        '\n' +
                                        'cd %CATALINA_HOME%\\bin\n' +
                                        'startup.bat'}
                                </SyntaxHighlighter>


                        </li>
                        <li>startup-instance3.bat

                                <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                    {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                        'set CATALINA_BASE=D:\\servers\\apache-instances\\instance3\n' +
                                        '\n' +
                                        'cd %CATALINA_HOME%\\bin\n' +
                                        'startup.bat'}
                                </SyntaxHighlighter>


                        </li>

                        <li>shutdown-instance1.bat
                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    'set CATALINA_BASE=D:\\servers\\apache-instances\\instance1\n' +
                                    '\n' +
                                    'cd %CATALINA_HOME%\\bin\n' +
                                    'shutdown.bat'}
                            </SyntaxHighlighter>
                        </li>
                        <li>shutdown-instance2.bat

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    'set CATALINA_BASE=D:\\servers\\apache-instances\\instance2\n' +
                                    '\n' +
                                    'cd %CATALINA_HOME%\\bin\n' +
                                    'shutdown.bat'}
                            </SyntaxHighlighter>

                        </li>
                        <li>shutdown-instance3.bat

                            <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                                {'set CATALINA_HOME=D:\\servers\\apache-tomcat-10.0.23\n' +
                                    'set CATALINA_BASE=D:\\servers\\apache-instances\\instance3\n' +
                                    '\n' +
                                    'cd %CATALINA_HOME%\\bin\n' +
                                    'shutdown.bat'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <b>1.4. Verificare</b>
                    <br/>
                    <br/>
                    Se verifica in browser:
                    <ul>
                        <li>http://localhost:8680</li>
                        <li>http://localhost:8780</li>
                        <li>http://localhost:8880</li>
                    </ul>

                    <b>1.5. Extra</b>
                    <br/>
                    <br/>

                    Pe Linux <i>startup-instance1.sh</i> ar arata asa:
                    <SyntaxHighlighter showLineNumbers={true} language="bash" style={androidstudio}>
                        {'#\'/bin/bash\n' +
                            'export CATALINA_HOME=/usr/binaries/servers/apache-tomcat-10.0.23\n' +
                            'export CATALINA_BASE=/usr/binaries/servers/apache-instances/instance3\n' +
                            '\n' +
                            'cd $CATALINA_HOME%/bin\n' +
                            './startup.sh'}
                    </SyntaxHighlighter>

                   Comenzi utile in Linux:
                    <ul>
                        <li>cd</li>
                        <li>ls -l</li>
                        <li>cd ..</li>
                        <li>cp -avr sursa destinatie (copiere recusriva)</li>
                        <li>rm -rf director (stergere recusriva)</li>
                        <li>nano fisier (editare fisier)</li>
                        <li>mkdir director (creare director)</li>
                    </ul>

                </div>


                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/"}>*/}
                    {/*           https://blogman.ro/tutorial-cum-sa-ti-faci-propriul-hosting-acasa/*/}
                    {/*       </a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClusteringTomcatContent;