import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"python-gui-root", url:"#", title:"Programarea GUI", subtitle:""},

        {id:"python-gui-intro", url:"/python-gui/intro", title:"Introducere in programarea GUI", subtitle:"", parent:"python-gui-root"},
        {id:"python-gui-tkinter-intro", url:"/python-gui/tkinter-intro", title:"Introducere in TkInter", subtitle:"", parent:"python-gui-root"},
    ]

    static indexUrl = "/python-gui/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Tips & Tricks
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;