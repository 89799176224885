import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";


class DependencyInjectionReactContent extends BaseContentPage {

    constructor(props) {
        super(props, "react-dependency-injection", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Dependinta injectiva </b>
                    <br/>
                    <br/>


                    Componentele React nu ar trebui să conțină logica (business).

                    <br/>
                    Pentru a realiza in React injecția de dependență se pot urma urmatorii în 3 pași:
                    <ul>
                        <li>creare „container” în care să se pastreze dependența</li>
                        <li>
                            crearea unui hook, de exemplu numit <b>useInject</b> pentru a obtine dependenta
                        </li>
                        <li>
                            folosire hook (<b>useInject</b>) in componente
                        </li>
                    </ul>

                    Pentru React exista librarii de Ioc (Inversare controlului):
                    <ul>
                        <li>
                            InversifyJS
                        </li>
                        <li>
                            Awilix
                        </li>
                        <li>
                            BottleJS
                        </li>
                    </ul>

                    Scris de la 0 ar arata cam asa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, { createContext, useContext } from \'react\';\n' +
                            '\n' +
                            '// Create a new context for the container\n' +
                            'const ContainerContext = createContext();\n' +
                            '\n' +
                            '// Define a component that provides the container to its children\n' +
                            'const ContainerProvider = ({ container, children }) => {\n' +
                            '  return <ContainerContext.Provider value={container}>{children}</ContainerContext.Provider>;\n' +
                            '};\n' +
                            '\n' +
                            '// Define a hook to access the container from within a component\n' +
                            'const useContainer = () => {\n' +
                            '  const container = useContext(ContainerContext);\n' +
                            '  if (!container) {\n' +
                            '    throw new Error(\'Container not found. Make sure to wrap your components with a ContainerProvider.\');\n' +
                            '  }\n' +
                            '  return container;\n' +
                            '};\n' +
                            '\n' +
                            '// Define a hook to inject dependencies from the container\n' +
                            'const useInject = (identifier) => {\n' +
                            '  const container = useContainer();\n' +
                            '  return container.resolve(identifier);\n' +
                            '};\n' +
                            '\n' +
                            '// Example usage:\n' +
                            'const MyService = () => {\n' +
                            '  return { foo: \'bar\' };\n' +
                            '};\n' +
                            '\n' +
                            'const MyComponent = () => {\n' +
                            '  const myService = useInject(\'myService\');\n' +
                            '  return <div>{myService.foo}</div>; // Output: \'bar\'\n' +
                            '};\n' +
                            '\n' +
                            'const container = {\n' +
                            '  registry: {\n' +
                            '    myService: MyService()\n' +
                            '  },\n' +
                            '  resolve(identifier) {\n' +
                            '    if (!this.registry.hasOwnProperty(identifier)) {\n' +
                            '      throw new Error(`Object with identifier ${identifier} not found in container`);\n' +
                            '    }\n' +
                            '    return this.registry[identifier];\n' +
                            '  }\n' +
                            '};\n' +
                            '\n' +
                            'const App = () => {\n' +
                            '  return (\n' +
                            '    <ContainerProvider container={container}>\n' +
                            '      <MyComponent />\n' +
                            '    </ContainerProvider>\n' +
                            '  );\n' +
                            '};'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://itnext.io/dependency-injection-in-react-6fa51488509f"}>
                               Dependency injection in React
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DependencyInjectionReactContent;