import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class SystemEventsDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-info", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker system events</b>

                    <br/>
                    <br/>

                    Afiseaza evenimente în timp real de pe server:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker system events'}
                    </SyntaxHighlighter>
                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker system events --since 2019-03-20'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'2023-01-02T17:26:39.530907085+02:00 network connect 7c8e7915edf6d9b1405528366fcf7391dc86d28095b7d74544afcfff459d3c60 (container=bf43181baaa1217c89bb4468f166b6beb951e793838d5f8585abf07a3102c65d, name=complex_default, type=bridge)\n' +
                            '2023-01-02T17:26:40.037419816+02:00 container start bf43181baaa1217c89bb4468f166b6beb951e793838d5f8585abf07a3102c65d (com.docker.compose.config-hash=f026061d0446be4618e79b68da0c22832a07a7cec68216aea42d57d4899850e7, com.docker.compos\n' +
                            'e.container-number=1, com.docker.compose.depends_on=client:service_started,api:service_started, com.docker.compose.image=sha256:257d4dbb64b88795437dfa8a77af1fed2dd3ad0688f5e25ea7f5a658c63154cd, com.docker.compose.oneoff=False, com.d\n' +
                            'ocker.compose.project=complex, com.docker.compose.project.config_files=/home/kj/learn/complex/docker-compose.yml, com.docker.compose.project.working_dir=/home/kj/learn/complex, com.docker.compose.service=nginx, com.docker.compose.version=2.10.2, image=complex-nginx, maintainer=NGINX Docker Maintainers <docker-maint@nginx.com>, name=complex-nginx-1)\n' +
                            '2023-01-02T17:26:40.134308345+02:00 container die bf43181baaa1217c89bb4468f166b6beb951e793838d5f8585abf07a3102c65d (com.docker.compose.config-hash=f026061d0446be4618e79b68da0c22832a07a7cec68216aea42d57d4899850e7, com.docker.compose.\n' +
                            'container-number=1, com.docker.compose.depends_on=client:service_started,api:service_started, com.docker.compose.image=sha256:257d4dbb64b88795437dfa8a77af1fed2dd3ad0688f5e25ea7f5a658c63154cd, com.docker.compose.oneoff=False, com.doc\n' +
                            'ker.compose.project=complex, com.docker.compose.project.config_files=/home/kj/learn/complex/docker-compose.yml, com.docker.compose.project.working_dir=/home/kj/learn/complex, com.docker.compose.service=nginx, com.docker.compose.version=2.10.2, exitCode=1, image=complex-nginx, maintainer=NGINX Docker Maintainers <docker-maint@nginx.com>, name=complex-nginx-1)\n' +
                            '2023-01-02T17:26:40.342848462+02:00 network disconnect 7c8e7915edf6d9b1405528366fcf7391dc86d28095b7d74544afcfff459d3c60 (container=bf43181baaa1217c89bb4468f166b6beb951e793838d5f8585abf07a3102c65d, name=complex_default, type=bridge)'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SystemEventsDockerContent;