import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../spring-boot/IndexContent";

class SpecificationExistsSpringBootContent extends BaseContentPage  {

    constructor(props) {
        super(props, "spring-boot-specification-exists", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Specification: exists</b>

                    <br/>
                    <br/>

                    Sa prespunem ca avem o carte (Book).
                    <br/>
                    Aceasta carte are tag-uri (Tag).
                    <br/>
                    Vrem sa facem o interogare de tipul:
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'select * from Carte c\n' +
                            '  where\n' +
                            '    exists (select * from Tag t where t.carte_id=c.id and t.tag like "%sf%")'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Specification<Carte> specification = Specification.where(null);\n' +
                            'specification = specification.and((root, query, criteriaBuilder) -> {\n' +
                            '    Subquery<Tag> tagSubquery = query.subquery(Tag.class);\n' +
                            '    Root<Tag> tagRoot = tagSubquery.from(Tag.class);\n' +
                            '\n' +
                            '    tagSubquery.select(tagRoot) //subquery\n' +
                            '        .where(\n' +
                            '            criteriaBuilder.and(\n' +
                            '                criteriaBuilder.equal( // t.carte_id=c.id\n' +
                            '                    tagRoot.get(Tag_.carte),\n' +
                            '                    root.get(Carte_.id)\n' +
                            '                ),\n' +
                            '                criteriaBuilder.like( // t.tag like "%sf%"\n' +
                            '                    tagRoot.get(Tag_.tag),\n' +
                            '                    "%sf%"\n' +
                            '                )\n' +
                            '            )\n' +
                            '        );\n' +
                            '\n' +
                            '    return criteriaBuilder.exists(tagSubquery);\n' +
                            '});'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpecificationExistsSpringBootContent;