import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class LinksK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-links", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Link-uri utile:
                    <ol>
                        <li>
                            <a href={"https://tush-tr.medium.com/kubernetes-networking-visualized-6256bd80120e"}>Kubernetes Networking Visualized</a>
                        </li>

                        <li>
                            <a href={"https://sunnykkc13.medium.com/deep-dive-into-kubernetes-238258c9a536"}>Deep Dive into Kubernetes</a>
                        </li>

                        <li>
                            <a href={"https://myhistoryfeed.medium.com/best-practices-for-kubernetes-in-2023-bd0aaada1f72"}>Best Practices for Kubernetes in 2023</a>
                        </li>

                        <li>
                            <a href={"https://pilotudesh.medium.com/pass-cka-in-2-weeks-928e84144f00"}>Pass Certified Kubernetes Administrator (CKA) in 2 weeks</a>
                        </li>
                    </ol>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LinksK8sContent;