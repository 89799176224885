import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";


class KeycloakIntro2JavaSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-keycloak-intro-2-spring-security", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Realm</b>
                    <br/>
                    <br/>

                    Un realm poate fi vazut ca o organizatie, in care se gestioneaza utilizatorii, rolurile, etc.

                    <br/>
                    Exemplu: <i>ibrid</i>

                    <hr/>
                    <b>2. Client</b>
                    <br/>
                    <br/>

                    Un client este o aplicatie care se poate conecta la Keycloak.

                    <br/>
                    Exemplu:
                    <ul>
                        <li>Client ID: student-app</li>
                        <li>Access-Type: confidential</li>
                        <li>Valid Redirect URIs: http://localhost:8081/*
                             <br/>
                            aplicatia noastra String Boot este pornita aici: http://localhost:8081/
                        </li>
                    </ul>
                    Dupa ce se da Save, in tabul <b>Credentials</b> se va genera un <b>Secret</b> (ex: f6d32d5c-2013-42f1-8642-290fc8600516).
                    <br/>
                    Acest <i>Secret</i> va fi folosit in aplicatia Spring Boot.

                    <br/>
                    <br/>
                    <i>Pentru fiecare aplicatie se creeaza un client.</i>


                </div>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://medium.com/keycloak/running-keycloak-using-postgresql-database-d8edc8d22a16"}>
                                    Running Keycloak using PostgreSQL database
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default KeycloakIntro2JavaSpringContent;