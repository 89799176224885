import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class MultiProjectsGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-multi-projects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Sa presupunem ca proiectul e format din mai multe proiecte: <i>proiect-api</i> si <i>proiect-impl</i>.
                    <br/>
                    In radacina proiectului vom avea fisierul de configurarea <b>settings.gradle</b> (intr-un multi-proiect; acest fisier este obligatoriu) prin intermediul căruia vom seta proprietatile comune pentru toate modulele,
                    cum ar fi:
                    <ul>
                        <li>numele proiectului (proprietate: <b>rootProject.name</b>)</li>
                        <li>modulele incluse (proprietate: <b>include</b>)</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'rootProject.name = Proiect\n' +
                        'include ":proiect-api", ":proiect-impl"'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Sectiunile subprojects/allprojects</b>
                    <br/>
                    <br/>

                    In fisierul comun <b>build.gradle</b> din radacina multi-proiectului, se poate seta configurarile comune pentru toate <b>sub-modulele</b> in sectiunea <b>subprojects</b>/<b>allprojects</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'subprojects{\n' +
                        '\tapply plugin: \'java\'// si nu cu plugins{ id \'java\'}\n' +
                        '\t\n' +
                        '\tgroup = \'ro.ibedaria\'\n' +
                        '\tversion  = \'1.0\'\n' +
                        '\t\n' +
                        '\tsourceCompatibility = 1.8\n' +
                        '\ttargetCompatibility = 1.8\n' +
                        '\t\n' +
                        '\trepositories{\n' +
                        '\t\tmavenCenter()\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>
                    <b>Observatie 1:</b>
                    <br/>
                    In sectiunea <b>subprojects</b> /<b>allproject</b> includerea unui plugin se face folosind sintaxa:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'apply plugin: \'java\' // si nu cu plugins{ id \'java\'}'}
                    </SyntaxHighlighter>


                    <b>Observatie 2:</b>
                    <br/>
                    Daca se doreste starea configurarilor comun pentru toate <b>sub-modulele</b> + proiectul curent se foloseste sectiunea <b>allprojects</b>.
                    <br/>
                    In aceasta sectiune <b>subprojects</b> sau <b>allprojects</b> se pot defini task-uri comune pentru submodule (+ proiectul/modul curent daca e sectiunea <b>allprojects</b>).
                    <br/>
                    <br/>
                    De exemplu, daca avem task-ul <i>displayName</i> definit in sectiunea <b>subprojects</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'subprojects{\n' +
                        '\n' +
                        '\ttask displayName{\n' +
                        '\t\tdoLast(){\n' +
                        '\t\t\tprintln project.name\n' +
                        '\t\t}\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Si rulam comanda: <b>gradle displayName</b>, atunci task-ul <i>displayName</i> se va executa pentru fiecare submodul.
                    Acesta este accesibil si in proiectul radacina/curent, pentru ca aici este definit, dar nu se va executa si pentru proiectul radacina.
                    <br/>

                    De exemplu, daca avem task-ul <i>displayName</i> definit in sectiunea <b>allprojects</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'allprojects{\n' +
                        '\n' +
                        '\ttask displayName{\n' +
                        '\t\tdoLast(){\n' +
                        '\t\t\tprintln project.name\n' +
                        '\t\t}\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Si rulam comanda: <b>gradle displayName</b>, atunci task-ul <i>displayName</i> se va executa pentru fiecare submodul + proiectul/modul radacina/curent.


                    <br/>
                    <br/>
                    <b>Observatie 3:</b>
                    <br/>
                    Daca in sectiunea <b>subprojects</b> / <b>allproiect</b> se adauga proprietatea <b>version = '1.0'</b> acest lucru se va reflecta in actiunea de build.
                    Artefactele rezultate vor avea concatenat la nume si versiunea (ex: api-1.0.jar).

                    <hr/>

                    <b>2. Sectiunea project</b>
                    <br/>
                    <br/>

                    Fiecare proiect se poate configura individual in prin intermediul sectunii <b>project</b>:

                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'subprojects{\n' +
                        '\tapply plugin: \'java\'\n' +
                        '\t\n' +
                        '\tgroup = \'ro.ibedaria\'\n' +
                        '\tversion  = \'1.0\'\n' +
                        '\t\n' +
                        '\tsourceCompatibility = 1.8\n' +
                        '\ttargetCompatibility = 1.8\n' +
                        '\t\n' +
                        '\trepositories{\n' +
                        '\t\tmavenCenter()\n' +
                        '\t}\n' +
                        '\t\t\n' +
                        '}\n' +
                        '\n' +
                        'project(":proiect-api"){\n' +
                        '\n' +
                        '\tdependencies{\n' +
                        '\t\timplementation \'org.apache.commons:commons-math3:3.2\'\n' +
                        '\t}\n' +
                        '\t\n' +
                        '}\n' +
                        '\n' +
                        'project(":proiect-impl"){\n' +
                        '\n' +
                        '\tdependencies{\n' +
                        '\t\timplementation project(":proiect-api")\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Task projects</b>
                    <br/>
                    <br/>
                    Fiecare modul, poate avea propriul fisier de build <b>build.gradle</b>.

                    Pentru a afisa modulele/proiectele proiectului se poate folosi task-ul <b>projects</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle projects'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Subproiecte cu fisiere de build care au nume diferite de build.gradle</b>
                    <br/>
                    <br/>

                    Se poate ca fiecare modul sa aiba fisierul script <b>build.gradle</b> cu alt nume (de exemplu <i>nume_modul.gradle</i>); pentru a rezolva aceasta problema, scriind in <b>settings.gradle</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\n' +
                        'rootProject.children.each{ subproject ->\n' +
                        '\tsubproject.buildFieldName = ${subproject.name}.gradle\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Dependendinte intre module/proiecte</b>
                    <br/>
                    <br/>
                    Daca proiectul <i>proiect-impl</i> depinde de proiectul <i>proiect-api</i>, atunci in fisierul de build al proiectului  <i>proiect-impl</i>, trebuie sa existe:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'dependencies{\n' +
                        '\timplementation project(\':proiect-api\')\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Rulare task doar pentru un anumit modul/proiect</b>
                    <br/>
                    <br/>
                    Daca vrem sa rulam un task (de exemplu <i>clean</i>) doar pe un anumit modul (de exemplu <i>project-api</i>), se ruleaza comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle :project-api:clean'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Afisare dependinte pentru un anumit modul/proiect</b>
                    <br/>
                    <br/>
                    Daca vrem sa afisam dependintelele pentru un anumit modul, se ruleaza comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle project-api:dependencies'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a href={"https://medium.com/mindorks/implementation-vs-api-in-gradle-3-0-494c817a6fa"}>Implementation Vs Api in Android Gradle plugin 3.0</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MultiProjectsGradleContent;