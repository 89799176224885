import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ForJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-for", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. for</b>
                    <br/>
                    <br/>

                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (initializare; conditie; incrementare) {\n' +
                        '    bloc_instructiuni\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Cat timp este indeplinita <i>conditie</i> se executa blocul de instructiuni.
                    <br/>
                    Observatii:
                    <ul>
                        <li>toate cele trei declaratii: <i>initializare, conditie; incrementare</i> sunt optionale </li>
                        <li>declaratia <i>initializare</i> se executa o singura data</li>
                        <li>daca declaratia <i>conditie</i> lipseste, se evalueaza la valoarea <i>true</i></li>
                        <li>declaratia <i>incrmentare</i> se executa dupa <i>bloc_instructiuni</i></li>
                    </ul>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (let i = 0; i < 10; i++) {\n' +
                        '    console.log(i);\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. for of</b>
                    <br/>
                    <br/>
                    Esti util pentru a parcurge o structura repetitiva (array).
                    <br/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (variabila of structura_repetitiva) {\n' +
                        '    bloc_instructiuni\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let vector = [11, 130, 5];\n' +
                        'let suma = 0;\n' +
                        'for (let nr of vector) {\n' +
                        '    suma += nr;\n' +
                        '}\n' +
                        'console.log(suma); // 146'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. for in</b>
                    <br/>
                    <br/>
                    Este util pentru a parcurge campurile unui obiect.
                    Iterează prin toate câmpurile obiectului, plasând numele acestor câmpuri (sau chei) în variabilă.
                    <br/>
                    Sintaxa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'for (camp in obiect) {\n' +
                        '    bloc_instructiuni\n' +
                        '}'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'let tara = {\n' +
                        '    capitala: "Bucuresti",\n' +
                        '    populatie: "17.5",\n' +
                        '   \n' +
                        '};\n' +
                        '\n' +
                        'for (let camp in tara) {\n' +
                        '    console.log(camp+" = "+tara[camp]);\n' +
                        '};'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Instructiunea break</b>
                    <br/>
                    <br/>
                    Instrucțiunea break este folosită pentru a termina execuția unei bucle sau a unei instrucțiuni switch. Se sare la prima instrucțiune de după acea buclă sau switch.

                    <hr/>
                    <b>5. Instructiunea continue</b>
                    <br/>
                    <br/>
                    Instructiunea continue sare dupa ultima instructiune (din blocul de instructiuni) dintr-o bucla (while/for), iar ciclul se reia de la inceput.

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ForJavaScriptContent;