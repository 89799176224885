import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class Ex5DockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-ex5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Exercitiul 5 (complex) - DEV </b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.6 -p 22'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd learn'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir complex'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd complex'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Modulul worker</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir worker'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd worker'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano package.json'}
                    </SyntaxHighlighter>

                    Fisierul <i>package.json</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "dependencies": {\n' +
                            '    "nodemon": "1.18.3",\n' +
                            '    "redis": "2.8.0"\n' +
                            '  },\n' +
                            '  "scripts": {\n' +
                            '    "start": "node index.js",\n' +
                            '    "dev": "nodemon"\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano keys.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>keys.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'module.exports = {\n' +
                            '  redisHost: process.env.REDIS_HOST,\n' +
                            '  redisPort: process.env.REDIS_PORT,\n' +
                            '};'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano index.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>index.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const keys = require(\'./keys\');\n' +
                            'const redis = require(\'redis\');\n' +
                            '\n' +
                            'const redisClient = redis.createClient({\n' +
                            '  host: keys.redisHost,\n' +
                            '  port: keys.redisPort,\n' +
                            '  retry_strategy: () => 1000,\n' +
                            '});\n' +
                            'const sub = redisClient.duplicate();\n' +
                            '\n' +
                            'function fib(index) {\n' +
                            '  if (index < 2) return 1;\n' +
                            '  return fib(index - 1) + fib(index - 2);\n' +
                            '}\n' +
                            '\n' +
                            'sub.on(\'message\', (channel, message) => {\n' +
                            '  redisClient.hset(\'values\', message, fib(parseInt(message)));\n' +
                            '});\n' +
                            'sub.subscribe(\'insert\');'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile.dev'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM node:alpine\n' +
                            'WORKDIR "/app"\n' +
                            'COPY ./package.json ./\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'CMD ["npm", "run", "dev"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'ls\n' +
                            'Dockerfile.dev  index.js  keys.js  package.json'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd ..'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3. Modulul server</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir server'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd server'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano package.json'}
                    </SyntaxHighlighter>

                    Fisierul <i>package.json</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'{\n' +
                            '  "dependencies": {\n' +
                            '    "express": "4.16.3",\n' +
                            '    "pg": "8.0.3",\n' +
                            '    "redis": "2.8.0",\n' +
                            '    "cors": "2.8.4",\n' +
                            '    "nodemon": "1.18.3",\n' +
                            '    "body-parser": "*"\n' +
                            '  },\n' +
                            '  "scripts": {\n' +
                            '    "dev": "nodemon",\n' +
                            '    "start": "node index.js"\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano keys.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>keys.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'module.exports = {\n' +
                            '  redisHost: process.env.REDIS_HOST,\n' +
                            '  redisPort: process.env.REDIS_PORT,\n' +
                            '  pgUser: process.env.PGUSER,\n' +
                            '  pgHost: process.env.PGHOST,\n' +
                            '  pgDatabase: process.env.PGDATABASE,\n' +
                            '  pgPassword: process.env.PGPASSWORD,\n' +
                            '  pgPort: process.env.PGPORT,\n' +
                            '};'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano index.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>index.js</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const keys = require("./keys");\n' +
                            '\n' +
                            '// Express App Setup\n' +
                            'const express = require("express");\n' +
                            'const bodyParser = require("body-parser");\n' +
                            'const cors = require("cors");\n' +
                            '\n' +
                            'const app = express();\n' +
                            'app.use(cors());\n' +
                            'app.use(bodyParser.json());\n' +
                            '\n' +
                            '// Postgres Client Setup\n' +
                            'const { Pool } = require("pg");\n' +
                            'const pgClient = new Pool({\n' +
                            '  user: keys.pgUser,\n' +
                            '  host: keys.pgHost,\n' +
                            '  database: keys.pgDatabase,\n' +
                            '  password: keys.pgPassword,\n' +
                            '  port: keys.pgPort,\n' +
                            '});\n' +
                            '\n' +
                            'pgClient.on("connect", (client) => {\n' +
                            '  client\n' +
                            '    .query("CREATE TABLE IF NOT EXISTS values (number INT)")\n' +
                            '    .catch((err) => console.error(err));\n' +
                            '});\n' +
                            '\n' +
                            '// Redis Client Setup\n' +
                            'const redis = require("redis");\n' +
                            'const redisClient = redis.createClient({\n' +
                            '  host: keys.redisHost,\n' +
                            '  port: keys.redisPort,\n' +
                            '  retry_strategy: () => 1000,\n' +
                            '});\n' +
                            'const redisPublisher = redisClient.duplicate();\n' +
                            '\n' +
                            '// Express route handlers\n' +
                            '\n' +
                            'app.get("/", (req, res) => {\n' +
                            '  res.send("Hi");\n' +
                            '});\n' +
                            '\n' +
                            'app.get("/values/all", async (req, res) => {\n' +
                            '  const values = await pgClient.query("SELECT * from values");\n' +
                            '\n' +
                            '  res.send(values.rows);\n' +
                            '});\n' +
                            '\n' +
                            'app.get("/values/current", async (req, res) => {\n' +
                            '  redisClient.hgetall("values", (err, values) => {\n' +
                            '    res.send(values);\n' +
                            '  });\n' +
                            '});\n' +
                            '\n' +
                            'app.post("/values", async (req, res) => {\n' +
                            '  const index = req.body.index;\n' +
                            '\n' +
                            '  if (parseInt(index) > 40) {\n' +
                            '    return res.status(422).send("Index too high");\n' +
                            '  }\n' +
                            '\n' +
                            '  redisClient.hset("values", index, "Nothing yet!");\n' +
                            '  redisPublisher.publish("insert", index);\n' +
                            '  pgClient.query("INSERT INTO values(number) VALUES($1)", [index]);\n' +
                            '\n' +
                            '  res.send({ working: true });\n' +
                            '});\n' +
                            '\n' +
                            'app.listen(5000, (err) => {\n' +
                            '  console.log("Listening");\n' +
                            '});\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile.dev'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM node:16-alpine\n' +
                            'WORKDIR "/app"\n' +
                            'COPY ./package.json ./\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'CMD ["npm", "run", "dev"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'ls\n' +
                            'Dockerfile.dev  index.js  keys.js  package.json'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd ..'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Modulul client</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'npx create-react-app client'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd client'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd src'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano OtherPage.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>OtherPage.js</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'import React from \'react\';\n' +
                            'import { Link } from \'react-router-dom\';\n' +
                            '\n' +
                            'const otherPage =() => {\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      Im some other page!\n' +
                            '      <Link to="/">Go back home</Link>\n' +
                            '    </div>\n' +
                            '  );\n' +
                            '};\n' +
                            '\n' +
                            'export default otherPage;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Fib.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>Fib.js</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'import React, { Component } from \'react\';\n' +
                            'import axios from \'axios\';\n' +
                            '\n' +
                            'class Fib extends Component {\n' +
                            '  state = {\n' +
                            '    seenIndexes: [],\n' +
                            '    values: {},\n' +
                            '    index: \'\',\n' +
                            '  };\n' +
                            '\n' +
                            '  componentDidMount() {\n' +
                            '    this.fetchValues();\n' +
                            '    this.fetchIndexes();\n' +
                            '  }\n' +
                            '\n' +
                            '  async fetchValues() {\n' +
                            '    const values = await axios.get(\'/api/values/current\');\n' +
                            '    this.setState({ values: values.data });\n' +
                            '  }\n' +
                            '\n' +
                            '  async fetchIndexes() {\n' +
                            '    const seenIndexes = await axios.get(\'/api/values/all\');\n' +
                            '    this.setState({\n' +
                            '      seenIndexes: seenIndexes.data,\n' +
                            '    });\n' +
                            '  }\n' +
                            '\n' +
                            '  handleSubmit = async (event) => {\n' +
                            '    event.preventDefault();\n' +
                            '\n' +
                            '    await axios.post(\'/api/values\', {\n' +
                            '      index: this.state.index,\n' +
                            '    });\n' +
                            '    this.setState({ index: \'\' });\n' +
                            '  };\n' +
                            '\n' +
                            '  renderSeenIndexes() {\n' +
                            '    return this.state.seenIndexes.map(({ number }) => number).join(\', \');\n' +
                            '  }\n' +
                            '\n' +
                            '  renderValues() {\n' +
                            '    const entries = [];\n' +
                            '\n' +
                            '    for (let key in this.state.values) {\n' +
                            '      entries.push(\n' +
                            '        <div key={key}>\n' +
                            '          For index {key} I calculated {this.state.values[key]}\n' +
                            '        </div>\n' +
                            '      );\n' +
                            '    }\n' +
                            '\n' +
                            '    return entries;\n' +
                            '  }\n' +
                            '\n' +
                            '  render() {\n' +
                            '    return (\n' +
                            '      <div>\n' +
                            '        <form onSubmit={this.handleSubmit}>\n' +
                            '          <label>Enter your index:</label>\n' +
                            '          <input\n' +
                            '            value={this.state.index}\n' +
                            '            onChange={(event) => this.setState({ index: event.target.value })}\n' +
                            '          />\n' +
                            '          <button>Submit</button>\n' +
                            '        </form>\n' +
                            '\n' +
                            '        <h3>Indexes I have seen:</h3>\n' +
                            '        {this.renderSeenIndexes()}\n' +
                            '\n' +
                            '        <h3>Calculated Values:</h3>\n' +
                            '        {this.renderValues()}\n' +
                            '      </div>\n' +
                            '    );\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            'export default Fib;\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano App.js'}
                    </SyntaxHighlighter>

                    Fisierul <i>App.js</i> va arata asa:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'import React from \'react\';\n' +
                            'import logo from \'./logo.svg\';\n' +
                            'import \'./App.css\';\n' +
                            'import { BrowserRouter as Router, Route, Link } from \'react-router-dom\';\n' +
                            'import OtherPage from \'./OtherPage\';\n' +
                            'import Fib from \'./Fib\';\n' +
                            '\n' +
                            'function App() {\n' +
                            '  return (\n' +
                            '    <Router>\n' +
                            '      <div className="App">\n' +
                            '        <header className="App-header">\n' +
                            '          <img src={logo} className="App-logo" alt="logo" />\n' +
                            '          <a\n' +
                            '            className="App-link"\n' +
                            '            href="https://reactjs.org"\n' +
                            '            target="_blank"\n' +
                            '            rel="noopener noreferrer"\n' +
                            '          >\n' +
                            '            Learn React\n' +
                            '          </a>\n' +
                            '          <Link to="/">Home</Link>\n' +
                            '          <Link to="/otherpage">Other Page</Link>\n' +
                            '        </header>\n' +
                            '        <div>\n' +
                            '          <Route exact path="/" component={Fib} />\n' +
                            '          <Route path="/otherpage" component={OtherPage} />\n' +
                            '        </div>\n' +
                            '      </div>\n' +
                            '    </Router>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'export default App;\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano cd..'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano package.json'}
                    </SyntaxHighlighter>

                    In fisierul <i>package.json</i> se adauga dependintele:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'    "react-router-dom": "4.3.1",\n' +
                            '    "axios": "0.18.0"'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile.dev'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM node:16-alpine\n' +
                            'WORKDIR \'/app\'\n' +
                            'COPY ./package.json ./\n' +
                            'RUN npm install\n' +
                            'COPY . .\n' +
                            'CMD ["npm", "run", "start"]'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd..'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Modulul nginx</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'mkdir nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd nginx'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano default.conf'}
                    </SyntaxHighlighter>

                    Fisierul <i>default.conf</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'upstream client {\n' +
                            '  server client:3000;\n' +
                            '}\n' +
                            '\n' +
                            'upstream api {\n' +
                            '  server api:5000;\n' +
                            '}\n' +
                            '\n' +
                            'server {\n' +
                            '  listen 80;\n' +
                            '\n' +
                            '  location / {\n' +
                            '    proxy_pass http://client;\n' +
                            '  }\n' +
                            '\n' +
                            '  location /ws {\n' +
                            '      proxy_pass http://client;\n' +
                            '      proxy_http_version 1.1;\n' +
                            '      proxy_set_header Upgrade $http_upgrade;\n' +
                            '      proxy_set_header Connection "Upgrade";\n' +
                            '  }\n' +
                            '\n' +
                            '  location /api {\n' +
                            '    rewrite /api/(.*) /$1 break;\n' +
                            '    proxy_pass http://api;\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano Dockerfile.dev'}
                    </SyntaxHighlighter>

                    Fisierul <i>Dockerfile.dev</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'FROM nginx\n' +
                            'COPY ./default.conf /etc/nginx/conf.d/default.conf'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'ls\n' +
                            'default.conf  Dockerfile.dev'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd..'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>7. Fisierul docker-compose.yml</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'cd..'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'nano docker-compose.yml'}
                    </SyntaxHighlighter>

                    Fisierul <i>docker-compose.yml</i>:

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'version: \'3\'\n' +
                            'services:\n' +
                            '  postgres:\n' +
                            '    image: \'postgres:latest\'\n' +
                            '    environment:\n' +
                            '      - POSTGRES_PASSWORD=postgres_password\n' +
                            '  redis:\n' +
                            '    image: \'redis:latest\'\n' +
                            '  nginx:\n' +
                            '    depends_on:\n' +
                            '      - api\n' +
                            '      - client\n' +
                            '    restart: always\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./nginx\n' +
                            '    ports:\n' +
                            '      - \'3050:80\'\n' +
                            '  api:\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./server\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - ./server:/app\n' +
                            '    environment:\n' +
                            '      - REDIS_HOST=redis\n' +
                            '      - REDIS_PORT=6379\n' +
                            '      - PGUSER=postgres\n' +
                            '      - PGHOST=postgres\n' +
                            '      - PGDATABASE=postgres\n' +
                            '      - PGPASSWORD=postgres_password\n' +
                            '      - PGPORT=5432\n' +
                            '  client:\n' +
                            '    environment:\n' +
                            '      - WDS_SOCKET_PORT=0\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./client\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - ./client:/app\n' +
                            '  worker:\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./worker\n' +
                            '    volumes:\n' +
                            '      - /app/node_modules\n' +
                            '      - ./worker:/app\n' +
                            '    environment:\n' +
                            '      - REDIS_HOST=redis\n' +
                            '      - REDIS_PORT=6379\n'}
                    </SyntaxHighlighter>

                    testare:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker compose up --build'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'http://192.168.1.6:3050/'}
                    </SyntaxHighlighter>

                    Daca se obtine eroarea:
                    <br/>
                    <i>[eslint] EACCES: permission denied, mkdir '/app/node_modules/.cache'</i>
                    <br/>
                    atunci:
                    <br/>
                    <br/>

                    Fisierul <b>Dockerfile.dev</b>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'FROM node:16-alpine\n' +
                            '\n' +
                            'USER node\n' +
                            '\n' +
                            'RUN mkdir -p /home/node/app\n' +
                            'WORKDIR /home/node/app\n' +
                            '\n' +
                            'COPY --chown=node:node ./package.json ./\n' +
                            '\n' +
                            'RUN npm install\n' +
                            '\n' +
                            'RUN mkdir node_modules/.cache && chmod -R 777 node_modules/.cache\n' +
                            '\n' +
                            'COPY --chown=node:node . .\n' +
                            '\n' +
                            'CMD ["npm", "run", "start"]'}
                    </SyntaxHighlighter>

                    Si in <b>docker compose</b>:
                    <SyntaxHighlighter>
                        {' client:\n' +
                            '    environment:\n' +
                            '      - WDS_SOCKET_PORT=0\n' +
                            '    build:\n' +
                            '      dockerfile: Dockerfile.dev\n' +
                            '      context: ./client\n' +
                            '    volumes:\n' +
                            '      - /home/node/app/node_modules\n' +
                            '      - ./client:/home/node/app'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Ex5DockerContent;