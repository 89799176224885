import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

const AppTest = (props) =>{
    console.log(props.auto)
    return (
        <a>test:{""+props.auto}</a>
    )
}

class PropsReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-props", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Props (fara valoare = true)</b>
                    <br/>
                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'\n' +
                            'const AppTest = (props) =>{\n' +
                            '    console.log(props.auto)\n' +
                            '    return (\n' +
                            '        <a>test: {""+props.auto}</a>\n' +
                            '    )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <AppTest auto/>



                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PropsReactContent;