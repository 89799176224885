import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class JavaApplicationPluginGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-java-application-plugin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Plugin Application</b>
                    <br/>
                    <br/>

                    Plugin-ul <b>application</b> extinde plugin-ul <b>java</b>.

                    <br/>
                    <br/>

                    Plugin-ul Java poate produce:
                    <ul>
                        <li>librarie</li>
                        <li>aplicatie executabila</li>
                    </ul>
                    Task-uri pentru aplicatiile executabile:
                    <ul>
                        <li><b>run</b>: executa clasa Main a programului</li>
                        <li><b>installDist</b>: genereaza aplicatia (in 'build\install')</li>
                        <li><b>distTar</b>: genereaza aplicatia ca TAR (in 'build\distributions')</li>
                        <li><b>distZip</b>: genereaza aplicatia ca ZIP (in 'build\distributions')</li>
                    </ul>

                    Pentru a folosi task-urile de mai sus, e nevoie sa fie importat plugin-ul <b>application</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins{\n' +
                        '   id "application"\n'+
                        '}\n'}
                    </SyntaxHighlighter>

                    Modificam scriptul:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'plugins{\n' +
                        '\t/*id \'java\'*/\n' +
                        '\tid \'application\'\n' +
                        '}\n' +
                        '\n' +
                        'version="1.0.0"\n' +
                        '\n' +
                        'jar{\n' +
                        '\tarchiveBaseName = "hello"\n' +
                        '\t//baseName = "$project.name-kj"\n' +
                        '\tmanifest{\n' +
                        '\t\tattributes(\n' +
                        '\t\t\t\'Main-Class\':\'HelloMain\',\n' +
                        '\t\t\t\'Implementation-Title\':project.name,\n' +
                        '\t\t\t\'Developer\':\'KJ\'\n' +
                        '\t\t)\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'java{\n' +
                        '\tsourceCompatibility = JavaVersion.VERSION_11\n' +
                        '\ttargetCompatibility = JavaVersion.VERSION_11\n' +
                        '}\n' +
                        '\n' +
                        'compileJava{\n' +
                        '\toptions.compilerArgs << \'-Werror\'\n' +
                        '}\n' +
                        '\n' +
                        'application{\n' +
                        '\tmainClass=\'HelloMain\'\n' +
                        '\tapplicationName = \'hello-app\'\n' +
                        '\t// executableDir = \'exe\' -> pentru a schimba numele unde se genereaza executabilul; implicit e \'bin\'\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    Pentru a include si dependintele in task-ul <b>jar</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="groovy" style={androidstudio}>
                        {'from {\n' +
                        '\t\tproject.configurations.runtimeClasspath.collect{File file -> project.zipTree(file)}\n' +
                        '\t}'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>2. Task-ul run</b>
                    <br/>
                    <br/>

                    Fara Gradle, pentru a rula aplicatia folosim comanda: <b>java -jar <i>nume-aplicatie.jar</i></b>.
                    <hr/>

                    Pentru a rula aplicatia, folosim task-ul <b>run</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle run -b build-java.gradle --console=verbose\n' +
                        '> Task :compileJava UP-TO-DATE\n' +
                        '> Task :processResources UP-TO-DATE\n' +
                        '> Task :classes UP-TO-DATE\n' +
                        '\n' +
                        '> Task :run\n' +
                        'Hello!\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '3 actionable tasks: 1 executed, 2 up-to-date'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Task-ul installDist</b>
                    <br/>
                    <br/>

                    Pentru a genera aplicatia, folosim task-ul <b>installDist</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle installDist -b build-java.gradle --console=verbose\n' +
                        '> Task :compileJava UP-TO-DATE\n' +
                        '> Task :processResources UP-TO-DATE\n' +
                        '> Task :classes UP-TO-DATE\n' +
                        '> Task :jar UP-TO-DATE\n' +
                        '> Task :startScripts UP-TO-DATE\n' +
                        '> Task :installDist'}
                    </SyntaxHighlighter>
                    Aplicatia se generaza in 'build\install'.
                    <br/>
                    In acest director se va genera directorul <i>hello-app</i> (setat prin proprietatea <b>applicationName</b> a task-ului <b>application</b>).
                    <br/>
                    In directorul <i>hello-app</i> se vor genera alte 2 directore:
                    <ul>
                        <li><i>bin</i> cu: <i>hello-app.bat</i> si hello-app; executand fisierul <i>hello-app.bat</i> se va afisa in consola mesajul 'Hello!'
                            <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'build\\install\\hello-app\\bin>hello-app.bat\n' +
                                'Hello!'}
                            </SyntaxHighlighter>
                        </li>
                        <li><i>lib</i> cu: hello-1.0.0.jar</li>
                    </ul>

                    <hr/>
                    <b>4. Task-ul distZip/distTar</b>
                    <br/>
                    <br/>

                    Pentru a genera aplicatia ca distributie ZIP si TAR:
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle distTar distZip -b build-java.gradle --console=verbose\n' +
                        '> Task :compileJava UP-TO-DATE\n' +
                        '> Task :processResources UP-TO-DATE\n' +
                        '> Task :classes UP-TO-DATE\n' +
                        '> Task :jar UP-TO-DATE\n' +
                        '> Task :startScripts UP-TO-DATE\n' +
                        '> Task :distTar\n' +
                        '> Task :distZip'}
                    </SyntaxHighlighter>
                    In directorul 'build\distributions' se genereaza:
                    <ul>
                        <li>hello-app-1.0.0.tar</li>
                        <li>hello-app-1.0.0.zip</li>
                    </ul>

                    <hr/>
                    <b>5. Rezumat task-uri</b>
                    <br/>
                    <br/>
                    Task-uri disponbile:
                    <ul>
                        <li>clean</li>
                        <li>compileJava</li>
                        <li>test</li>
                        <li>jar</li>
                        <li>distZip</li>
                        <li>distTar</li>
                        <li>installDist</li>
                        <li>run</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaApplicationPluginGradleContent;