import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class WorkflowLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-workflow", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    Crearea Workflow Handler:
                    <br/>
                    1. Se creeaza o clasă Component care se extinde <b>BaseWorkflowHandler</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(immediate = true, service = WorkflowHandler.class)\n' +
                        'public class FooEntityWorkflowHandler extends BaseWorkflowHandler<FooEntity>'}
                    </SyntaxHighlighter>

                    2. Cele 3 metode de implementat:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                        'public String getClassName() {\n' +
                        '    return FooEntity.class.getName();\n' +
                        '}\n' +
                        '\n' +
                        '@Override\n' +
                        'public String getType(Locale locale) {\n' +
                        '    return ResourceActionsUtil.getModelResource(locale, getClassName());\n' +
                        '}\n' +
                        '\n' +
                        '@Override\n' +
                        'public FooEntity updateStatus(int status, Map<String, Serializable> workflowContext) throws PortalException {\n' +
                        '... }'}
                    </SyntaxHighlighter>
                    Exemplu implementare pentru <i>updateStatus()</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Override\n' +
                        'public FooEntity updateStatus(\n' +
                        '        int status, Map<String, Serializable> workflowContext)\n' +
                        '    throws PortalException {\n' +
                        '\n' +
                        '    long userId = GetterUtil.getLong(\n' +
                        '        (String)workflowContext.get(WorkflowConstants.CONTEXT_USER_ID));\n' +
                        '    long classPK = GetterUtil.getLong(\n' +
                        '        (String)workflowContext.get(\n' +
                        '            WorkflowConstants.CONTEXT_ENTRY_CLASS_PK));\n' +
                        '\n' +
                        '    ServiceContext serviceContext = (ServiceContext)workflowContext.get(\n' +
                        '        WorkflowConstants.CONTEXT_SERVICE_CONTEXT);\n' +
                        '\n' +
                        '    return _fooEntityLocalService.updateStatus(\n' +
                        '        userId, classPK, status, serviceContext);\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    In layer-ul de service, Service Builder este utilizat pentru a creea campuri in tabelele bazei de date.
                    <br/>
                    1. Trebuie adaugate urmatoarele campuri:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<column name="status" type="int" />\n' +
                        '<column name="statusByUserId" type="long" />\n' +
                        '<column name="statusByUserName" type="String" />\n' +
                        '<column name="statusDate" type="Date" />'}
                    </SyntaxHighlighter>
                    2. Oriunde se seteaza campurile pe entitate, se seteaza status workflow ca draft si restul campurilor (in clasa de implementarea a  local service <i>-LocalServiceImpl</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'fooEntity.setStatus(WorkflowConstants.STATUS_DRAFT);\n' +
                        'fooEntity.setStatusByUserId(userId);\n' +
                        'fooEntity.setStatusByUserName(user.getFullName());\n' +
                        'fooEntity.setStatusDate(serviceContext.getModifiedDate(null));'}
                    </SyntaxHighlighter>
                    3. La sfârșitul oricărei metode care adaugă o nouă entitate la baza de date, apelați serviciul de flux de lucru pentru a activa trimiterea entității în backend-ul fluxului de lucru
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'WorkflowHandlerRegistryUtil.startWorkflowInstance(\n' +
                        '    fooEntity.getCompanyId(), fooEntity.getGroupId(), fooEntity.getUserId(),\n' +
                        '    FooEntity.class.getName(), fooEntity.getPrimaryKey(), fooEntity,\n' +
                        '    serviceContext);'}
                    </SyntaxHighlighter>

                    4. Implementați metoda <b>updateStatus</b> care trebuie apelată în gestionarea fluxului de lucru. În final, persistă entitatea actualizată în baza de date.
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'fooEntity.setStatus(status);\n' +
                        'fooEntity.setStatusByUserId(user.getUserId());\n' +
                        'fooEntity.setStatusByUserName(user.getFullName());\n' +
                        'fooEntity.setStatusDate(serviceContext.getModifiedDate(now));\n' +
                        '\n' +
                        'fooEntityPersistence.update(fooEntity);'}
                    </SyntaxHighlighter>
                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Indexable(type = IndexableType.REINDEX)\n' +
                        'public FooEntity updateStatus(\n' +
                        '    long userId, long fooEntityId, int status, ServiceContext serviceContext\n' +
                        ') throws PortalException, SystemException {\n' +
                        '\n' +
                        '\t\tUser user = userLocalService.getUser(userId);\n' +
                        '\t\tFooEntity fooEntity = getFooEntity(fooEntityId);\n' +
                        '\n' +
                        '\t\tfooEntity.setStatus(status);\n' +
                        '\t\tfooEntity.setStatusByUserId(userId);\n' +
                        '\t\tfooEntity.setStatusByUserName(user.getFullName());\n' +
                        '\t\tfooEntity.setStatusDate(new Date());\n' +
                        '\n' +
                        '\t\tfooEntityPersistence.update(fooEntity);\n' +
                        '\n' +
                        '\t\tif (status == WorkflowConstants.STATUS_APPROVED) {\n' +
                        '\t\t\tassetEntryLocalService.updateVisible(\n' +
                        '          FooEntity.class.getName(), fooEntityId, true);\n' +
                        '\t\t}\n' +
                        '    else {\n' +
                        '\t\t\tassetEntryLocalService.updateVisible(\n' +
                        '          FooEntity.class.getName(), fooEntityId, false);\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\treturn fooEntity;\n' +
                        '  }'}
                    </SyntaxHighlighter>
                    5. Se apeleaza metoda <b>deleteWorkflowInstanceLinks()</b> în metoda <b>deleteEntity()</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'workflowInstanceLinkLocalService.deleteWorkflowInstanceLinks(\n' +
                        '    fooEntity.getCompanyId(), fooEntity.getGroupId(),\n' +
                        '    FooEntity.class.getName(), fooEntity.getFooEntityId());'}
                    </SyntaxHighlighter>

                    Pentru a obține injectarea/referinta <b>WorkflowInstanceLocalService</b>  în  <i>*LocalServiceBaseImpl</i>,
                    astfel încât să puteți apela metodele sale în LocalServiceImpl, adăugați acest lucru la declarația dvs. de entitate în <b>service.xml</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<reference entity="WorkflowInstanceLink" package-path="com.liferay.portal" />'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemplu 1</b>: Afișați numai entitățile aprobate

                    <br/>

                    1. Adaugare finder in <b>service.xml</b> pentru a fi generat de Service Builder:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<finder name="G_S" return-type="Collection">\n' +
                        '    <finder-column name="groupId"></finder-column>\n' +
                        '    <finder-column name="status"></finder-column>\n' +
                        '</finder>'}
                    </SyntaxHighlighter>

                    2. Getter, in layer-ul de service, care utilizeaza noul <i>finder</i> (G_S):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public List<FooEntity> getFooEntities(long groupId, int status)\n' +
                        '    throws SystemException {\n' +
                        '\n' +
                        '    return fooEntityPersistence.findByG_S(\n' +
                        '        groupId, WorkflowConstants.STATUS_APPROVED);\n' +
                        '}'}
                    </SyntaxHighlighter>

                    3. Actualizare JSP, sa foloseasca metoda <i>getFooEntities()</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<liferay-ui:search-container-results\n' +
                        '    results="<%= FooEntityLocalServiceUtil.getFooEntities(\n' +
                        '        scopeGroupId, fooEntityId(), Workflowconstants.STATUS_APPROVED,\n' +
                        '        searchContainer.getStart(), searchContainer.getEnd()) %>"\n' +
                        '/>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Exemplu 2</b>: Afișarea starea fluxului de lucru:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<aui:workflow-status markupView="lexicon" showIcon="<%= false %>" showLabel="<%= false %>" status="<%= fooEntity.getStatus() %>" />'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360029046731-Liferay-s-Workflow-Framework"}>
                                    Liferay's Workflow Framework
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WorkflowLiferayContent;