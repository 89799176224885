import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class SortLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-sort", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a sorta continutul unui fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sort'}
                    </SyntaxHighlighter>

                    Fie fisierul <i>animale.txt</i> cu urmatorul continut:
                    <SyntaxHighlighter>
                        {'gasca\n' +
                            'caine\n' +
                            'rata\n' +
                            'pisica'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sort animale.txt'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'caine\n' +
                            'gasca\n' +
                            'pisica\n' +
                            'rata\n'}
                    </SyntaxHighlighter>

                    Fisierul <i>animale.txt</i> ramane nemodificat!

                    <hr/>
                    Pentru a afisa in ordine inversa:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sort -r animale.txt'}
                    </SyntaxHighlighter>

                    se va afisa:
                    <SyntaxHighlighter>
                        {'rata\n' +
                            'pisica\n' +
                            'gasca\n' +
                            'caine\n '}
                    </SyntaxHighlighter>


                    <hr/>
                    Pentru a salva intr-un fisier:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sort -o animale-sortate.txt animale.txt'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a salva sorta un fisier care contine numere:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sort -n numere.txt'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a elimina duplicate:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'sort -nu numere.txt'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SortLinuxContent;