import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class WorkflowPageLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-workflow-page", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Se adauga <i>FirstWorkflowPage</i> in <b>service.xml</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<entity local-service="true" name="FirstWorkflowPage" remote-service="true" uuid="true">\n' +
                        '\n' +
                        '\t\t<!-- PK fields -->\n' +
                        '\t\t<column name="id" primary="true" type="long" />\n' +
                        '\n' +
                        '\t\t<!-- Group instance -->\n' +
                        '\t\t<column name="groupId" type="long" />\n' +
                        '\n' +
                        '\t\t<!-- Audit fields -->\n' +
                        '\t\t<column name="companyId" type="long" />\n' +
                        '\t\t<column name="userId" type="long" />\n' +
                        '\t\t<column name="userName" type="String" />\n' +
                        '\t\t<column name="createDate" type="Date" />\n' +
                        '\t\t<column name="modifiedDate" type="Date" />\n' +
                        '\n' +
                        '\t\t<!-- Other fields -->\n' +
                        '\t\t<column name="status" type="String" />\n' +
                        '\t\t<column name="page1" type="String" />\n' +
                        '\t\t<column name="page2" type="String" />\n' +
                        '\t\t<column name="page3" type="String" />\n' +
                        '\n' +
                        '\t</entity>'}
                    </SyntaxHighlighter>

                    Se modifica in <b>portlet-model-hints.xml</b>, adaugand:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<hint name="max-length">100000</hint>'}
                    </SyntaxHighlighter>
                    acolo unde este nevoie:
                    <SyntaxHighlighter  showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<model name="ro.ibedaria.liferay.vanilla.data.model.FirstWorkflowPage">\n' +
                        '\t\t<field name="uuid" type="String" />\n' +
                        '\t\t<field name="id" type="long" />\n' +
                        '\t\t<field name="groupId" type="long" />\n' +
                        '\t\t<field name="companyId" type="long" />\n' +
                        '\t\t<field name="userId" type="long" />\n' +
                        '\t\t<field name="userName" type="String" />\n' +
                        '\t\t<field name="createDate" type="Date" />\n' +
                        '\t\t<field name="modifiedDate" type="Date" />\n' +
                        '\t\t<field name="status" type="String" />\n' +
                        '\t\t<field name="page1" type="String">\n' +
                        '\t\t\t<hint name="max-length">100000</hint>\n' +
                        '\t\t</field>\n' +
                        '\t\t<field name="page2" type="String">\n' +
                        '\t\t\t<hint name="max-length">100000</hint>\n' +
                        '\t\t</field>\n' +
                        '\t\t<field name="page3" type="String">\n' +
                        '\t\t\t<hint name="max-length">100000</hint>\n' +
                        '\t\t</field>\n' +
                        '\t</model>'}
                    </SyntaxHighlighter>


                    Se executa <b>buildService</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'D:\\work\\liferay-vanilla\\modules\\data\\data-service> ..\\..\\..\\gradlew buildService'}
                    </SyntaxHighlighter>

                    <hr/>

                    Cream fisierul <b>workflow-page.js</b> in (modules/apps/workflow-page/src/main/resources/META-INF/resources/js/workflow-page.js):

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'$(document).ready(function(){\n' +
                        '\n' +
                        '    function toNextPage(){\n' +
                        '        let currentFormSection = $(this).parent().closest("fieldset");\n' +
                        '        let nextFormSection = currentFormSection.next();\n' +
                        '\n' +
                        '        let index = $("fieldset").index(nextFormSection);\n' +
                        '        console.log(index);\n' +
                        '\n' +
                        '        $("#progressbar li").eq(index-1).addClass("active");\n' +
                        '\n' +
                        '\n' +
                        '        nextFormSection.show();\n' +
                        '\n' +
                        '        currentFormSection.animate({opacity: 0}, {\n' +
                        '            step: function(now) {\n' +
                        '\n' +
                        '                opacity = 1 - now;\n' +
                        '\n' +
                        '                currentFormSection.css({\n' +
                        '                    \'display\': \'none\',\n' +
                        '                    \'position\': \'relative\'\n' +
                        '                });\n' +
                        '                nextFormSection.css({\'opacity\': opacity});\n' +
                        '            },\n' +
                        '            duration: 600\n' +
                        '        });\n' +
                        '    }\n' +
                        '\n' +
                        '    function toPreviousPage(){\n' +
                        '        let currentFormSection = $(this).parent().closest("fieldset");\n' +
                        '        let previousFormSection = currentFormSection.prev();\n' +
                        '\n' +
                        '        $("#progressbar li").eq($("fieldset").index(currentFormSection)-1).removeClass("active");\n' +
                        '\n' +
                        '        previousFormSection.show();\n' +
                        '\n' +
                        '        currentFormSection.animate({opacity: 0}, {\n' +
                        '            step: function(now) {\n' +
                        '\n' +
                        '                opacity = 1 - now;\n' +
                        '\n' +
                        '                currentFormSection.css({\n' +
                        '                    \'display\': \'none\',\n' +
                        '                    \'position\': \'relative\'\n' +
                        '                });\n' +
                        '                previousFormSection.css({\'opacity\': opacity});\n' +
                        '            },\n' +
                        '            duration: 600\n' +
                        '        });\n' +
                        '    }\n' +
                        '\n' +
                        '    $(".next").click(function(){\n' +
                        '        toNextPage.bind(this)();\n' +
                        '    });\n' +
                        '\n' +
                        '    $(".previous").click(function(){\n' +
                        '        toPreviousPage.bind(this)();\n' +
                        '    });\n' +
                        '\n' +
                        '});'}
                    </SyntaxHighlighter>

                    In clasa portlet <i>WorkflowPagePortlet</i>, se adauga proprietatea:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'com.liferay.portlet.footer-portlet-javascript=/js/workflow-page.js'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.footer-portlet-javascript=/js/workflow-page.js",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=WorkflowPage",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.name=" + WorkflowPagePortletKeys.WORKFLOWPAGE,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class WorkflowPagePortlet extends MVCPortlet {..}'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default WorkflowPageLiferayContent;