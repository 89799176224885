import React from "react";

import Util from "../../module/util/Util.js";
import Trainer from "../../module/util/Trainer.js";
import PerceptronDraw from "../../module/draw/nn/PerceptronDraw.js";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class PerceptronContent extends BaseContentPage {

    constructor(props) {
        super(props, "perceptron", IndexContent);
    }

    generateInput(numberPoints = 50){

        let dataset = [];

        for( let i = 0; i < numberPoints; i++){

            let x = Util.random(-1,1);
            let y = Util.random(-1,1);

            dataset.push(new Trainer(x,y));
        }

        return dataset;
    }

    componentDidMount() {
        let padding = 100;
        let width =  700;
        let height = 700;
        let r = 10;

        this.perceptronTest = new PerceptronDraw(2, width,height,r,padding);

        this.state = {
            trainingEpoch : 0,
            maxEpoch : 0,
            numberPoints : 50,
            learningRate: 0.0001
        }

        this.draw();
    }

    draw(){
        let input = this.generateInput(this.state.numberPoints);
        this.perceptronTest.setDataSet(input);
        this.perceptronTest.draw();

        this.setState({
            trainingEpoch: this.perceptronTest.epoch,
            maxEpoch: this.perceptronTest.maxEpoch,
            learningRate: this.perceptronTest.learningRate
        });

    }

    render() {
        return (
            <div className="home index-page ann">

                {this.title()}
                {this.navigator()}

                <div className="toolbar d-flex justify-content-md-center">

                    <button type="button" className="btn btn-info" onClick={() => this.draw()}>
                        Test again
                    </button>

                    <span className="badge badge-pill badge-dark ml-5 p-2">
                            Training Epoch:
                            <span className={this.state.trainingEpoch===this.state.maxEpoch?
                                     "badge badge-pill badge-danger m-1 p-1":
                                     "badge badge-pill badge-success m-1 p-1"}>
                                {this.state.trainingEpoch}
                            </span>

                    </span>

                    <span className="badge badge-pill badge-dark ml-1 p-2">
                            Max Epoch:
                            <span className="badge badge-pill badge-warning m-1 p-1">{this.state.maxEpoch}</span>
                    </span>

                    <span className="badge badge-pill badge-dark ml-1 p-2">
                            Learning rate:
                            <span className="badge badge-pill badge-warning m-1 p-1">{this.state.learningRate}</span>
                    </span>

                    <span className="badge badge-pill badge-dark ml-1 p-2">
                            Number Of Points:
                            <span className="badge badge-pill badge-warning m-1 p-1">{this.state.numberPoints}</span>
                    </span>

                </div>

                <div id="container" className="svg-container">
                </div>

                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }

}

export default PerceptronContent;