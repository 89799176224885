import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../rust/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ClosuresRustContent extends BaseContentPage  {

    constructor(props) {
        super(props, "rust-closures", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Closures</b>
                    <br/>
                    <br/>

                    O <b>closures</b> este o functie anonima:

                    <SyntaxHighlighter>
                        {'|x, y| { x + y }'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'let add = |x, y| { x + y };'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'add(1,2); // returneaza 3'}
                    </SyntaxHighlighter>

                    <hr/>
                    <SyntaxHighlighter>
                        {'|x:i32|->i32 { x + y }'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'let add = |a,b| a+b;\n' +
                            'let rez = add(1,2);\n' +
                            'println!("{}",rez);'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'let num = 5;\n' +
                            'let plus_num = |x: i32| x + num;'}
                    </SyntaxHighlighter>

                    Exemplu cu eroare (nu poate fi borrow/imprumat de 2 ori):
                    <SyntaxHighlighter>
                        {'let mut num = 5;\n' +
                            'let plus_num = |x: i32| x + num;\n' +
                            '\n' +
                            'let y = &mut num; // eroare: num nu poate fi borrow/imprumat inca o data pentru mai este deja imprumuat in closures'}
                    </SyntaxHighlighter>

                    Exemplu: (rezolvare problema de mai sus)
                    <SyntaxHighlighter>
                        {'let mut num = 5;\n' +
                            '{\n' +
                            '    let plus_num = |x: i32| x + num;\n' +
                            '\n' +
                            '} // `plus_num` goes out of scope; borrow of `num` ends.\n' +
                            '\n' +
                            'let y = &mut num;'}
                    </SyntaxHighlighter>

                    Exemplu, cu eroare (nums se transmite la closures ca la o functie):
                    <SyntaxHighlighter>
                        {'let nums = vec![1, 2, 3];\n' +
                            '\n' +
                            'let takes_nums = || nums;\n' +
                            '\n' +
                            'println!("{:?}", nums);'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Move</b>
                    <br/>
                    <br/>

                    Fără <b>move</b> closure este legată de cadrul stivei care a creat-o, în timp ce cu <b>move</b> closure este autonomă!

                    <br/>
                    <br/>
                    Exemplu (fara move):
                    <SyntaxHighlighter>
                        {'let mut num = 5;\n' +
                            '\n' +
                            '{\n' +
                            '    let mut add_num = |x: i32| num += x;\n' +
                            '\n' +
                            '    add_num(5);\n' +
                            '}// num va avea valoare 10'}
                    </SyntaxHighlighter>

                    Exemplu (cu move):
                    <SyntaxHighlighter>
                        {'let mut num = 5;\n' +
                            '\n' +
                            '{\n' +
                            '    let mut add_num = move |x: i32| num += x;\n' +
                            '\n' +
                            '    add_num(5);\n' +
                            '} // num va avea valoare 5'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClosuresRustContent;