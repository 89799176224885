import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../linux/IndexContent";

class StreamLinuxContent extends BaseContentPage  {

    constructor(props) {
        super(props, "linux-stream", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Fiecare proces are asociate 3 stream-uri:
                    <ul>
                        <li>STDIN (tastatura)</li>
                        <li>STDOUT (monitor)</li>
                        <li>STDIN (monitor)</li>
                    </ul>
                    Fiecare stream are asociat un numar:
                    <ul>
                        <li>0 - STDIN (0&lt;)</li>
                        <li>1 - STDOUT ({"1> sau doar >"})</li>
                        <li>2 - STDIN ({"2>"})</li>
                    </ul>
                    Daca vrem sa redirectam un stream catre un fisier, vom specifica tipul de stream si <b>&lt;</b>  (pt stdin) si <b>&gt;</b> (pt stdout / stderr); de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls 1> stdout.txt 2> stderr.txt'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cat 0< stdout.txt'}
                    </SyntaxHighlighter>

                    Pentru a adauga intr-un fisier deja existent, se foloseste <b>&gt;&gt;</b>; de exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'ls 1>> stdout.txt 2>> stderr.txt'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StreamLinuxContent;