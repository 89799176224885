import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class JavaSpringSecurityEx03Content extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-security-ex-03", IndexContent);
    }

    render() {

        return (
            <div className="home index">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify content important"}>
                    <i>Aplicatie:</i> <b>security</b>
                    <br/>
                    <i>Modul:</i> <b>security-02</b>
                    <br/>
                    <i>Descriere actiune:</i> Construirea unui proiect cu securitate implicita pornind de la modulul <b>security-01</b>
                    <br/>
                    <i>IDE folosit</i>: Intellij IDEA
                    <hr/>

                    1. In fisierul <b>pom.xml</b> (in radacina proiectului) se adauga in cadrul sectiunii <b>project\modules</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<module>security-02</module>'}
                    </SyntaxHighlighter>

                    2. In fisierul <b>pom.xml</b> (al modului) se adauga in cadrul sectiunii <b>project</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'   <dependencies>\n' +
                        '        <dependency>\n' +
                        '            <groupId>org.springframework.boot</groupId>\n' +
                        '            <artifactId>spring-boot-starter-security</artifactId>\n' +
                        '        </dependency>\n' +
                        '    </dependencies>'}
                    </SyntaxHighlighter>

                    3. Clasele <b>HelloController</b> si <b>Security02Application</b> (continut identic cu Security01Application) se pot copia, pastrand aceeasi structura a organizarii de directoare si de pachete

                    4. La pornirea serverului se vor afisa urmatoarele log-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        {'Connected to the target VM, address: \'127.0.0.1:53698\', transport: \'socket\'\n' +
                        '\n' +
                        '  .   ____          _            __ _ _\n' +
                        ' /\\\\ / ___\'_ __ _ _(_)_ __  __ _ \\ \\ \\ \\\n' +
                        '( ( )\\___ | \'_ | \'_| | \'_ \\/ _` | \\ \\ \\ \\\n' +
                        ' \\\\/  ___)| |_)| | | | | || (_| |  ) ) ) )\n' +
                        '  \'  |____| .__|_| |_|_| |_\\__, | / / / /\n' +
                        ' =========|_|==============|___/=/_/_/_/\n' +
                        ' :: Spring Boot ::                (v2.5.5)\n' +
                        '\n' +
                        '2021-10-22 21:57:04.434  INFO 23176 --- [           main] r.l.s.exemplu02.Security02Application    : Starting Security02Application using Java 1.8.0_301 on desktop-lenovo-y540 with PID 23176 (D:\\work\\security\\security-02\\target\\classes started by iulianb in D:\\work\\security)\n' +
                        '2021-10-22 21:57:04.436  INFO 23176 --- [           main] r.l.s.exemplu02.Security02Application    : No active profile set, falling back to default profiles: default\n' +
                        '2021-10-22 21:57:05.127  INFO 23176 --- [           main] o.s.b.w.embedded.tomcat.TomcatWebServer  : Tomcat initialized with port(s): 8080 (http)\n' +
                        '2021-10-22 21:57:05.132  INFO 23176 --- [           main] o.apache.catalina.core.StandardService   : Starting service [Tomcat]\n' +
                        '2021-10-22 21:57:05.132  INFO 23176 --- [           main] org.apache.catalina.core.StandardEngine  : Starting Servlet engine: [Apache Tomcat/9.0.53]\n' +
                        '2021-10-22 21:57:05.192  INFO 23176 --- [           main] o.a.c.c.C.[Tomcat].[localhost].[/]       : Initializing Spring embedded WebApplicationContext\n' +
                        '2021-10-22 21:57:05.192  INFO 23176 --- [           main] w.s.c.ServletWebServerApplicationContext : Root WebApplicationContext: initialization completed in 721 ms\n' +
                        '2021-10-22 21:57:05.451  INFO 23176 --- [           main] .s.s.UserDetailsServiceAutoConfiguration : \n' +
                        '\n' +
                        'Using generated security password: 54b34ce4-f65b-4a14-9bbb-535489106f78\n' +
                        '\n' +
                        '2021-10-22 21:57:05.525  INFO 23176 --- [           main] o.s.s.web.DefaultSecurityFilterChain     : Will secure any request with [org.springframework.security.web.context.request.async.WebAsyncManagerIntegrationFilter@2aa7399c, org.springframework.security.web.context.SecurityContextPersistenceFilter@106d77da, org.springframework.security.web.header.HeaderWriterFilter@7b66322e, org.springframework.security.web.csrf.CsrfFilter@506a1372, org.springframework.security.web.authentication.logout.LogoutFilter@5853ca50, org.springframework.security.web.authentication.UsernamePasswordAuthenticationFilter@35ee466f, org.springframework.security.web.authentication.ui.DefaultLoginPageGeneratingFilter@65e0b505, org.springframework.security.web.authentication.ui.DefaultLogoutPageGeneratingFilter@11eed657, org.springframework.security.web.authentication.www.BasicAuthenticationFilter@2be95d31, org.springframework.security.web.savedrequest.RequestCacheAwareFilter@5114b7c7, org.springframework.security.web.servletapi.SecurityContextHolderAwareRequestFilter@f324455, org.springframework.security.web.authentication.AnonymousAuthenticationFilter@708f018e, org.springframework.security.web.session.SessionManagementFilter@24534cb0, org.springframework.security.web.access.ExceptionTranslationFilter@642413d4, org.springframework.security.web.access.intercept.FilterSecurityInterceptor@521bb1a4]\n' +
                        '2021-10-22 21:57:05.568  INFO 23176 --- [           main] o.s.b.w.embedded.tomcat.TomcatWebServer  : Tomcat started on port(s): 8080 (http) with context path \'\'\n' +
                        '2021-10-22 21:57:05.575  INFO 23176 --- [           main] r.l.s.exemplu02.Security02Application    : Started Security02Application in 1.482 seconds (JVM running for 2.363)\n' +
                        '2021-10-22 21:57:39.838  INFO 23176 --- [nio-8080-exec-1] o.a.c.c.C.[Tomcat].[localhost].[/]       : Initializing Spring DispatcherServlet \'dispatcherServlet\'\n' +
                        '2021-10-22 21:57:39.838  INFO 23176 --- [nio-8080-exec-1] o.s.web.servlet.DispatcherServlet        : Initializing Servlet \'dispatcherServlet\'\n' +
                        '2021-10-22 21:57:39.839  INFO 23176 --- [nio-8080-exec-1] o.s.web.servlet.DispatcherServlet        : Completed initialization in 1 ms\n'}
                    </SyntaxHighlighter>

                    De retinut, din logurile de mai sus este linia 20:
                    <SyntaxHighlighter showLineNumbers={true} startingLineNumber={20} language="text" style={androidstudio}>
                        {'Using generated security password: 54b34ce4-f65b-4a14-9bbb-535489106f78'}
                    </SyntaxHighlighter>

                    5. Daca se executa comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="text" style={androidstudio}>
                        http://localhost:8080/hello
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'GET http://localhost:8080/hello\n' +
                        '\n' +
                        'HTTP/1.1 401 \n' +
                        'Set-Cookie: JSESSIONID=BAB82E9D20C746C9A9B7F969D6D02CC9; Path=/; HttpOnly\n' +
                        'WWW-Authenticate: Basic realm="Realm"\n' +
                        'X-Content-Type-Options: nosniff\n' +
                        'X-XSS-Protection: 1; mode=block\n' +
                        'Cache-Control: no-cache, no-store, max-age=0, must-revalidate\n' +
                        'Pragma: no-cache\n' +
                        'Expires: 0\n' +
                        'X-Frame-Options: DENY\n' +
                        'Content-Type: application/json\n' +
                        'Transfer-Encoding: chunked\n' +
                        'Date: Fri, 22 Oct 2021 19:42:06 GMT\n' +
                        'Keep-Alive: timeout=60\n' +
                        'Connection: keep-alive\n' +
                        '\n' +
                        '{\n' +
                        '  "timestamp": "2021-10-22T19:42:06.998+00:00",\n' +
                        '  "status": 401,\n' +
                        '  "error": "Unauthorized",\n' +
                        '  "path": "/hello"\n' +
                        '}\n' +
                        '\n' +
                        'Response code: 401; Time: 15ms; Content length: 97 bytes\n' +
                        ''}
                    </SyntaxHighlighter>

                    6. Pentru a putea apelata metoda de mai sus, este nevoie sa fie specificat numele utilizatorului si parola acestuia.
                    <br/>
                    In mod impicit, numele utilizatorului este: <b>user</b>, iar parola este cea afisata la pornirea serverului:<br/>
                    <SyntaxHighlighter showLineNumbers={true} startingLineNumber={20} language="text" style={androidstudio}>
                        {'Using generated security password: 54b34ce4-f65b-4a14-9bbb-535489106f78'}
                    </SyntaxHighlighter>

                    Comanda care trebuie executata este:
                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                        {'GET http://localhost:8080/hello\n' +
                        'Authorization: Basic user 54b34ce4-f65b-4a14-9bbb-535489106f78'}
                    </SyntaxHighlighter>

                    Rezultatul comenzii de mai sus este:
                    <SyntaxHighlighter showLineNumbers={true} language="http" style={androidstudio}>
                    {'GET http://localhost:8080/hello\n' +
                    '\n' +
                    'HTTP/1.1 200 \n' +
                    'Set-Cookie: JSESSIONID=A71FDB6EBA9E8AC1CC5F2A9BEC6E514F; Path=/; HttpOnly\n' +
                    'X-Content-Type-Options: nosniff\n' +
                    'X-XSS-Protection: 1; mode=block\n' +
                    'Cache-Control: no-cache, no-store, max-age=0, must-revalidate\n' +
                    'Pragma: no-cache\n' +
                    'Expires: 0\n' +
                    'X-Frame-Options: DENY\n' +
                    'Content-Type: text/plain;charset=UTF-8\n' +
                    'Content-Length: 5\n' +
                    'Date: Fri, 22 Oct 2021 19:56:06 GMT\n' +
                    'Keep-Alive: timeout=60\n' +
                    'Connection: keep-alive\n' +
                    '\n' +
                    'Hello\n' +
                    '\n' +
                    'Response code: 200; Time: 243ms; Content length: 5 bytes'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>STOP</b>
                    </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default JavaSpringSecurityEx03Content;