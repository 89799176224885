import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class VariablesJavaScriptContent extends BaseContentPage {

    constructor(props) {
        super(props, "javascript-variables", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>1. Variabile</b>
                    <br/>
                    <br/>

                    Variabilele ne permit sa stocam valori, pe care putem eventua modifica.
                    <br/>
                    Cand se declară o variabila de fapt se rezerva un nume.

                    <br/>
                    <br/>
                    Numele variabilelor in JavaScript trebuie sa respecte niste reguli:
                    <ul>
                        <li>trebuie sa inceapa cu o litera sau _ (underscore) sau $ (dolar)</li>
                        <li>poate include cifre, dar nu se poate incepe cu o cifra</li>
                        <li>nu trebuie sa fie un nume rezervat (keyword):<br/>
                            <table>
                                <thead>
                                <tr>
                                    <th colSpan={5}>Cuvinte cheie</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>abstact</td>
                                    <td>arguments</td>
                                    <td>await</td>
                                    <td>boolean</td>
                                    <td>break</td>
                                </tr>

                                <tr>
                                    <td>byte</td>
                                    <td>case</td>
                                    <td>catch</td>
                                    <td>char</td>
                                    <td>class</td>
                                </tr>

                                <tr>
                                    <td>const</td>
                                    <td>continue</td>
                                    <td>debugger</td>
                                    <td>default</td>
                                    <td>delete</td>
                                </tr>

                                <tr>
                                    <td>do</td>
                                    <td>double</td>
                                    <td>else</td>
                                    <td>enum</td>
                                    <td>eval</td>
                                </tr>

                                <tr>
                                    <td>export</td>
                                    <td>extends</td>
                                    <td>false</td>
                                    <td>final</td>
                                    <td>finally</td>
                                </tr>

                                <tr>
                                    <td>float</td>
                                    <td>for</td>
                                    <td>function</td>
                                    <td>goto</td>
                                    <td>if</td>
                                </tr>

                                <tr>
                                    <td>implements</td>
                                    <td>import</td>
                                    <td>in</td>
                                    <td>instanceof</td>
                                    <td>int</td>
                                </tr>

                                <tr>
                                    <td>interface</td>
                                    <td>let</td>
                                    <td>long</td>
                                    <td>native</td>
                                    <td>new</td>
                                </tr>

                                <tr>
                                    <td>null</td>
                                    <td>package</td>
                                    <td>private</td>
                                    <td>protected</td>
                                    <td>public</td>
                                </tr>

                                <tr>
                                    <td>return</td>
                                    <td>short</td>
                                    <td>static</td>
                                    <td>super</td>
                                    <td>switch</td>
                                </tr>

                                <tr>
                                    <td>synchronized</td>
                                    <td>this</td>
                                    <td>throw</td>
                                    <td>throws</td>
                                    <td>transient</td>
                                </tr>


                                <tr>
                                    <td>true</td>
                                    <td>try</td>
                                    <td>typeof</td>
                                    <td>var</td>
                                    <td>void</td>
                                </tr>

                                <tr>
                                    <td>volatile</td>
                                    <td>while</td>
                                    <td>with</td>
                                    <td>yield</td>
                                    <td></td>
                                </tr>

                                </tbody>
                            </table>


                        </li>
                    </ul>

                    Pentru variabile care se pot modifica se folosesc cuvintele cheie <b>var</b> si <b>let</b> (recomandat), iar pentru constante <b>const</b>.
                    <br/>
                    <br/>
                    Observatii:
                    <ul>
                        <li>o variabila care nu are atribuita nici o valoare este <b>undefined</b></li>
                        <li>accesarea unei variable nedeclarate, va avea ca rezultat aruncarea exceptiei <b>ReferenceError</b></li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'var x=1;\n' +
                        'console.log(x); //  undefined\n' +
                        'console.log(y); // Uncaught ReferenceError: weight is not defined'}
                    </SyntaxHighlighter>

                    Orice declaratie, instructiune se termina cu <i>; (punct si virgula)</i>.

                    <br/>
                    <br/>

                    <b>Initializarea</b> unei variabile reprezinta atribuirea unui valori unei variabile, folosind (=).
                    <br/>
                    Inainte de prima folosirea a unei variabile (citire/modificare), este necesara atribuirea unei valori.
                    <br/>
                    <br/>
                    Diferenta intre let si var:

                    <table>
                        <thead>
                            <tr>
                                <th width="50%">
                                    let
                                </th>
                                <th width="50%">
                                    var
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="50%">nu permite redeclarea unei variabile cu același nume (este generată o eroare)

                                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                        {'let y=1;\n' +
                                        'let y=2; // Uncaught SyntaxError: Identifier \'y\' has already been declared\n' +
                                        'console.log(y);'}
                                    </SyntaxHighlighter>

                                </td>
                                <td>permite redeclararea unei variabilă (ceea ce poate duce la erori în execuția programului)

                                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                                        {'var x=1;\n' +
                                        'var x=2;\n' +
                                        'console.log(x); //2'}
                                    </SyntaxHighlighter>

                                </td>
                            </tr>

                            <tr>
                                <td width="50%">
                                    variabila globala - in afara unui bloc de cod
                                    <br/>
                                    variabila locala - in interiorul unui bloc de cod
                                </td>
                                <td>
                                    variabila globala - in afara unui bloc de cod
                                    <br/>
                                    variabila globala - in interiorul unui bloc de cod (exceptie bloc asociat unei functii)
                                    <br/>
                                    variabila locala - in interiorul unei functii
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    Variabilele in JavaScript sunt netipizate (untyped) sau altfel spus: slab (weakly) si dinamic tipizate (dynamically typed).
                    Acest lucru inseamna ca nu se controleaza ce valoare (ce tip de date) se stocheaza intr-o variabila.
                    <br/>
                    Tipul de date determină apartenența unei date date la un anumit set care împărtășește aceleași proprietăți și asupra căruia puteți efectua aceleași operații.
                    <br/>
                    JavaScript permite pentru o variabila schimbarea tipului de date de-al lungul existentei acesteia:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let x=1;\n' +
                        'x="buna";'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Constante</b>
                    <br/>
                    <br/>
                    Cuvântul cheie <b>const</b> este folosit pentru a declara containere similare cu variabilele. Astfel de containere se numesc constante.
                    Constantele sunt folosite pentru a stoca anumite valori, dar odată ce valorile au fost introduse în ele în timpul inițializării,
                    acestea nu mai pot fi modificate.
                    <br/>
                    Aceasta înseamnă că acest tip de container trebuie <i>declarat și inițializat simultan</i>.
                    Daca acest lucru nu se intampla, se va atunca exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const x; // ncaught SyntaxError: Missing initializer in const declaration'}
                    </SyntaxHighlighter>

                    Daca se incearca modificarea valorii unei constante, se va arunca de asemenea o exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const x=1;\n' +
                        'x=2;//Uncaught TypeError: Assignment to constant variable.'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Folosirea "use strict"</b>
                    <br/>
                    <br/>
                    JavaScript a suferit schimbari majore in 2009 si 2015. Pentru a forta interpretorul JavaScript sa se comporte conform standardelor JavaScript moderne,
                    trebuie adaugat la inceptului codului <b>use strict</b> (determina interpretul să se ocupe de restul codului folosind modul strict):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'"use strict";'}
                    </SyntaxHighlighter>

                    Fara declaratia "use strict" se poate omite cuvantul cheie "var" in declararea variabilelor, si se poate scrie in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'x=1;'}
                    </SyntaxHighlighter>

                    Cu declaratia "use strict" se va arunca exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'"use strict";\n' +
                        'x=1; // Uncaught ReferenceError: x is not defined'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Scopul</b>
                    <br/>
                    <br/>
                    Codul unui program in JavaScript poate fi separat in blocuri, folosind acolade.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let x = 1;\n' +
                        '{\n' +
                        '    x = x + 1;\n' +
                        '}\n' +
                        'console.log(x);//2'}
                    </SyntaxHighlighter>
                    De obicei, blocurile sunt folosite in legatura cu instructiunile repetitive sau conditionate.
                    In exemplul de mai sus, blocul este facut doar ca exemplificare, nu are sens in programarea reala.

                    <br/>
                    Scopul sau domeniul de vizibilitate a unei variabile se limiteaza la blocul la care este definit:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let x=1;\n' +
                        '{\n' +
                        '    let y = 2;\n' +
                        '    console.log(y); // 2\n' +
                        '}\n' +
                        'console.log(x); // 1\n' +
                        'console.log(y); // Uncaught ReferenceError: y is not defined'}
                    </SyntaxHighlighter>

                    Reguli:
                    <ul>
                        <li>o variabila declarata cu <b>let</b> si <b>const</b> <i>in afara unui bloc de cod</i> este o <b>variabila/constanta globala</b>, adica va fi vizibila pe tot parcursul programului, în afara blocurilor, în interiorul blocurilor, în funcții, etc.
                        </li>
                        <li>
                            o variabila declarata cu <b>let</b> si <b>const</b> <i>in interiorul unui bloc de cod</i> este o <b>variabila/constanta locala</b> va fi vizibila doar in interiorul blocului.
                        </li>
                        <li>o variaibla declara cu <b>var</b> <i>in afara unui bloc de cod</i> este o <b>variabila globala</b></li>
                        <li>o variaibla declara cu <b>var</b> <i>in interiorul unui bloc de cod, neasociat unei functii</i> este o <b>variabila globala</b></li>
                        <li>o variaibla declara cu <b>var</b> <i>in interiorul unui bloc de cod asociat unei functii</i> este o <b>variabila locala</b></li>
                    </ul>

                    <hr/>
                    <b>5. Umbrirea variabilelor (Shadowing)</b>
                    <br/>
                    <br/>
                    Umbrirea variabilelor se refera la faptul ca se permite declararea unei variabile globale și a unei variabile locale cu același nume.
                    <br/>
                    Variabila globală este ascunsă în spatele celei locale, deci nu avem acces la ea în acest domeniu local.

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let x = 1;\n' +
                        '{\n' +
                        '    let x = 2;\n' +
                        '    console.log(x); // 2\n' +
                        '}\n' +
                        'console.log(x); // 1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Ridicarea variabilelor (Hoisting)</b>
                    <br/>
                    <br/>

                    Interpretorul JavaScript scanează programul înainte de a-l rula pentru a gasi:
                    <ul>
                        <li>erori în sintaxa acestuia</li>
                        <li>toate declarațiile de variabile și le mută la începutul intervalului în care au fost declarate:

                            <ul>
                                <li>
                                    la începutul programului dacă sunt globale
                                </li>
                                <li>
                                    la începutul blocului dacă este o declarație locală let
                                </li>
                                <li>
                                    la începutul funcției dacă este o declarație locală var
                                </li>
                            </ul>
                        </li>
                    </ul>

                    Exemplu 1: Aruncare exceptie:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'console.log(y); // Uncaught ReferenceError: y is not defined'}
                    </SyntaxHighlighter>

                    Exemplu 2: Ridica doar declarearea, nu si initializarea:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'console.log(y); // undefined\n' +
                        'var y=1;'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    Ridicarea funcționează puțin diferit cu declarațiile let și const:

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'console.log(y); // Uncaught ReferenceError: Cannot access \'y\' before initialization\n' +
                        'let y=1;'}
                    </SyntaxHighlighter>

                    sau

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'{\n' +
                        '    console.log(y); // Uncaught ReferenceError: Cannot access \'y\' before initialization\n' +
                        '    let y=1;\n' +
                        '}'}
                    </SyntaxHighlighter>


                    Dar:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'let y;\n' +
                        'console.log(y); // undefined'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                {/*<div className={"text-justify"}>*/}
                {/*    <b>Referinte:</b><br/>*/}
                {/*    <ol>*/}

                {/*        <li>*/}
                {/*            <div>*/}

                {/*                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020*/}

                {/*            </div>*/}
                {/*        </li>*/}

                {/*    </ol>*/}
                {/*</div>*/}
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VariablesJavaScriptContent;