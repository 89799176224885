import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../next/IndexContent";

class RoutingNextContent extends BaseContentPage  {

    constructor(props) {
        super(props, "next-routing", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Routing in Next</b>
                    <br/>
                    <br/>

                    In Next routarea se bazeaza pe numele fisierelor, iar fisierul trebuie sa contina o <b>componenta React exportabila in mod implicit</b>.
                    <br/>
                    De exemplu, daca avem fisierul <i>teatru.js</i> (cu o componenta React exportabila in mod implicit) se poate accesa:
                    <i>http://localhost:3002/teatru</i>

                    <br/>
                    <br/>
                    Daca vrem de exemplu sa afisam detalii despre un anumit teatru, dand ca parametru numele teatrului:
                    <br/>
                    <i>http://localhost:3002/teatru/tnb</i>
                    <br/>
                    atunci putem folosi routarea dinamica.
                    <br/>
                    Pentru aceasta:
                    <ul>
                        <li>creeam un director cu numele <i>teatru</i></li>
                        <li>mutam fisierul <i>teatru.js</i> in directorul <i>teatru</i></li>
                        <li>redenumim fisierul <i>teatru.js</i> in <i>index.js</i></li>
                        <li>pentru a accepta parametrii, redenumim <i>index.js</i> in <i>[nume].js</i></li>
                        <li>pentru a obtine parametrii folosim <b>useRouter()</b></li>
                        <li>
                            pentru a accepta un numar variabil de parametrii (ex: /x/y/x) se creaza un fisier cu numele <i>[...parametrii].js</i>
                            <br/>
                            apoi in router.query.parametrii sub forma de array se vor regasi parametrii: ["x","y","z"]
                        </li>
                    </ul>

                    Exemplu (link: http://localhost:3002/product/car):
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import {useRouter} from "next/router";\n' +
                            '\n' +
                            'const Product = () =>{\n' +
                            '\n' +
                            '    const router = useRouter();\n' +
                            '    return (\n' +
                            '        <div>Product {router.query.id}</div>\n' +
                            '    )\n' +
                            '}\n' +
                            '\n' +
                            'export default Product;'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Diferenta intre <b>a</b> si <b>Link</b></b>
                    <br/>
                    <br/>

                    Folosind <b>a</b> se va face reincarca pagina:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<a href="/dopamina-ui/static">acasa</a>'}
                    </SyntaxHighlighter>

                    Folosind <b>Link</b> se va face nu se reincarca pagina, ci se comuta intre componente/pagini:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Link href="/dopamina-ui/static">acasa</Link>'}
                    </SyntaxHighlighter>
                    cu
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'import Link from "next/link";'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Moduri de folosire Link</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Link href="/client/{id}">acasa</Link>'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'<Link href={{pathname: "/client/[id]", query: {id: id}}}>acasa</Link>'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Routare programatica</b>
                    <br/>
                    <br/>

                    Redirect programatic:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const router = useRouter();\n' +
                            'router.push("/client")'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'const router = useRouter();\n' +
                            'router.push({pathname: "/client/[id]", query: {id: id})'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Routare catre pagina 404</b>
                    <br/>
                    <br/>

                    Trebuie creata o pagina de tip componenta in <b>/pages</b> cu numele <b>404.js</b>!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RoutingNextContent;