import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [
        {id:"python-ii-oop", url:"#", title:"Notiuni avansate de clase si OOP", subtitle:""},

        {id:"python-ii-oop-introduction", url:"/python-ii/oop-introduction", title:"OOP: Introducere", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-oop-variables", url:"/python-ii/oop-variables", title:"OOP: Variabile", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-oop-core-syntax", url:"/python-ii/oop-core-syntax", title:"Metode magice", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-oop-inheritance-polymorphism", url:"/python-ii/oop-inheritance-polymorphism", title:"Mostenire si polimorfism", subtitle:"", parent: "python-ii-oop"},

        {id:"python-ii-args", url:"/python-ii/args", title:"Numar variabil de argumente", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-decorators", url:"/python-ii/decorators", title:"Decoratori de functii pe baza de functii", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-decorators-ii", url:"/python-ii/decorators-ii", title:"Decoratori de functii pe baza de clase", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-decorators-iii", url:"/python-ii/decorators-iii", title:"Decoratori de clase", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-static-class-method", url:"/python-ii/static-class-method", title:"Metode de clasa si metode statice", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-abstract-class", url:"/python-ii/abstract-class", title:"Clase abstracte", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-oop-encapsulation", url:"/python-ii/oop-encapsulation", title:"OOP: Incapsulare (getter, setter, deleter)", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-exception-advanced", url:"/python-ii/exception-advanced", title:"Exceptii: exceptii inlantuite", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-with", url:"/python-ii/with", title:"Instuctiunea with", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-object-persistence", url:"/python-ii/object-persistence", title:"Persitarea obiectelor", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-serialization", url:"/python-ii/serialization", title:"Serializare", subtitle:"", parent: "python-ii-oop"},
        {id:"python-ii-metaprogramming", url:"/python-ii/metaprogramming", title:"Metaprogramare", subtitle:"", parent: "python-ii-oop"},

        {id:"python-ii-pep-parent", url:"#", title:"PEP", subtitle:""},

        {id:"python-ii-pep", url:"/python-ii/pep", title:"PEP: PEP 1, PEP 20", subtitle:"", parent: "python-ii-pep-parent"},
        {id:"python-ii-pep-8", url:"/python-ii/pep-8", title:"PEP 8: Conventii de a scrie cod", subtitle:"", parent: "python-ii-pep-parent"},
        {id:"python-ii-pep-257", url:"/python-ii/pep-257", title:"PEP 257: Conventii docstring", subtitle:"", parent: "python-ii-pep-parent"},
    ];

    static indexUrl = "/python-ii/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Python: notiuni mai avansate
                </div>

                <div>
                    <i>oamenii sunt mai veninoşi decât şerpi</i> (John Steinbeck - La răsărit de Eden)
                    <br/>
                    <br/>
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;