import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class KubectlDescribeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-kubectl-describe", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Kubectl describe</b>
                    <br/>
                    <br/>

                    Comanda CLI <b>kubectrl describe</b> este folosita pentru a afisa detalii/informatii legate de obiectele care ruleaza pe un nod
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe <object-type> <object-name>'}
                    </SyntaxHighlighter>
                    <ul>
                        <li>
                            object-type: specifica tipul de obiect pentru care vrem sa obtinem informatii (de exemplu: <i>pods</i> | <i>pod</i>)
                        </li>
                        <li>
                            object-name: numele obiectului; este optional
                        </li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe pod client-pod'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'Name:             client-pod\n' +
                            'Namespace:        default\n' +
                            'Priority:         0\n' +
                            'Service Account:  default\n' +
                            'Node:             minikube/192.168.49.2\n' +
                            'Start Time:       Sun, 30 Oct 2022 09:46:03 +0200\n' +
                            'Labels:           component=web\n' +
                            'Annotations:      <none>\n' +
                            'Status:           Running\n' +
                            'IP:               172.17.0.3\n' +
                            'IPs:\n' +
                            '  IP:  172.17.0.3\n' +
                            'Containers:\n' +
                            '  client:\n' +
                            '    Container ID:   docker://b3de5a7a8c5a4cb0dbe6e85b05096996f4a712001de5383e989875dce6d27570\n' +
                            '    Image:          iulianbuzdugan/multi-client\n' +
                            '    Image ID:       docker-pullable://iulianbuzdugan/multi-client@sha256:afe696beabcdb4bc6daf1bab36399b219ac75cfb55eae3082c3c1d1f2f10f263\n' +
                            '    Port:           3000/TCP\n' +
                            '    Host Port:      0/TCP\n' +
                            '    State:          Running\n' +
                            '      Started:      Sun, 30 Oct 2022 09:47:32 +0200\n' +
                            '    Ready:          True\n' +
                            '    Restart Count:  0\n' +
                            '    Environment:    <none>\n' +
                            '    Mounts:\n' +
                            '      /var/run/secrets/kubernetes.io/serviceaccount from kube-api-access-wgzl8 (ro)\n' +
                            'Conditions:\n' +
                            '  Type              Status\n' +
                            '  Initialized       True\n' +
                            '  Ready             True\n' +
                            '  ContainersReady   True\n' +
                            '  PodScheduled      True\n' +
                            'Volumes:\n' +
                            '  kube-api-access-wgzl8:\n' +
                            '    Type:                    Projected (a volume that contains injected data from multiple sources)\n' +
                            '    TokenExpirationSeconds:  3607\n' +
                            '    ConfigMapName:           kube-root-ca.crt\n' +
                            '    ConfigMapOptional:       <nil>\n' +
                            '    DownwardAPI:             true\n' +
                            'QoS Class:                   BestEffort\n' +
                            'Node-Selectors:              <none>\n' +
                            'Tolerations:                 node.kubernetes.io/not-ready:NoExecute op=Exists for 300s\n' +
                            '                             node.kubernetes.io/unreachable:NoExecute op=Exists for 300s\n' +
                            'Events:                      <none>'}
                    </SyntaxHighlighter>

                    <hr/>

                    Alt exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe storageclass'}
                    </SyntaxHighlighter>

                    Alt exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe certificates'}
                    </SyntaxHighlighter>

                    Alt exemplu, in care se descriu toate pod-urile:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'kubectl describe pod'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default KubectlDescribeContent;