import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class Routing6ReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-routing-6", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Introducere in Routing</b>
                    <br/>
                    <br/>

                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="html" style={androidstudio}>
                        {'npm i -D react-router-dom@latest'}
                    </SyntaxHighlighter>


                    Exemplu:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import ReactDOM from "react-dom/client";\n' +
                            'import { BrowserRouter, Routes, Route } from "react-router-dom";\n' +
                            'import Layout from "./pages/Layout";\n' +
                            'import Home from "./pages/Home";\n' +
                            'import Blogs from "./pages/Blogs";\n' +
                            'import Contact from "./pages/Contact";\n' +
                            'import NoPage from "./pages/NoPage";\n' +
                            '\n' +
                            'export default function App() {\n' +
                            '  return (\n' +
                            '    <BrowserRouter>\n' +
                            '      <Routes>\n' +
                            '        <Route path="/" element={<Layout />}>\n' +
                            '            <Route index element={<Home />} />\n' +
                            '            <Route path="blogs" element={<Blogs />} />\n' +
                            '            <Route path="contact" element={<Contact />} />\n' +
                            '            <Route path="*" element={<NoPage />} />\n' +
                            '        </Route>\n' +
                            '      </Routes>\n' +
                            '    </BrowserRouter>\n' +
                            '  );\n' +
                            '}\n' +
                            '\n' +
                            'const root = ReactDOM.createRoot(document.getElementById(\'root\'));\n' +
                            'root.render(<App />);'}
                    </SyntaxHighlighter>

                    <b>Route</b>-ele pot fi imbricate. Prima calea este / și redă componenta <i>Layout</i>.
                    <br/>
                    <b>Route</b>-ele imbricate moștenesc și se adaugă la ruta părinte.<br/>

                    Deci, Deci calea <i>blogs</i> este combinată cu părintele și devine <i>/blogs</i>.

                    <br/>
                    Ruta pentru componenta <i>Home</i> Home nu are o cale, dar are atributul <b>index</b>.
                    Aceasta specifică că ruta implicită este ruta părinte, adica <i>/</i>.

                    <br/>
                    <br/>

                    Daca <b>path</b>="*", atunci aceasta va acționa ca o soluție generală pentru orice adrese URL nedefinite.
                    Acest lucru este potrivit de exemplu pentru o pagină de eroare 404.

                    <hr/>
                    Layout.js poate arata cam asa:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { Outlet, Link } from "react-router-dom";\n' +
                            '\n' +
                            'const Layout = () => {\n' +
                            '  return (\n' +
                            '    <>\n' +
                            '      <nav>\n' +
                            '        <ul>\n' +
                            '          <li>\n' +
                            '            <Link to="/">Home</Link>\n' +
                            '          </li>\n' +
                            '          <li>\n' +
                            '            <Link to="/blogs">Blogs</Link>\n' +
                            '          </li>\n' +
                            '          <li>\n' +
                            '            <Link to="/contact">Contact</Link>\n' +
                            '          </li>\n' +
                            '        </ul>\n' +
                            '      </nav>\n' +
                            '\n' +
                            '      <Outlet />\n' +
                            '    </>\n' +
                            '  )\n' +
                            '};\n' +
                            '\n' +
                            'export default Layout;'}
                    </SyntaxHighlighter>

                    <ul>
                        <li>
                            <b>Outlet</b> {"=>"} randeaza calea selectata
                        </li>
                        <li>
                            <b>Link</b> {"=>"} este folosit pentru a seta o adresa URL și pentru a ține evidența istoricului de navigare;
                            <br/>
                            De fiecare dată când ne ducem catre o cale internă, vom folosi <b>Link</b> în loc de <b>a href=""</b>
                        </li>
                    </ul>

                    <hr/>
                    Comparativ, in versiunea 5, ca exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'//v5\n' +
                            '<Switch>\n' +
                            '      <Route exact path="/" component={Home} />\n' +
                            '      <Route path="/about" component={About} />\n' +
                            '</Switch>\n' +
                            '//v6\n' +
                            '<Routes>\n' +
                            '      <Route path="/" element={<Home />} />\n' +
                            '      <Route path="/about" element={<About />} />\n' +
                            '</Routes>'}
                    </SyntaxHighlighter>
                    In versiunea 6 s-a schimbat:
                    <ul>
                        <li>
                            <b>Switch</b> cu <b>Routes</b>
                        </li>
                        <li>
                            <b>component</b> cu <b>element</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>2. createBrowserRouter</b>
                    <br/>
                    <br/>
                    Element html:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React from "react";\n' +
                            'import ReactDOM from "react-dom/client";\n' +
                            'import {\n' +
                            '  createBrowserRouter,\n' +
                            '  RouterProvider,\n' +
                            '} from "react-router-dom";\n' +
                            'import "./index.css";\n' +
                            '\n' +
                            'const router = createBrowserRouter([\n' +
                            '  {\n' +
                            '    path: "/",\n' +
                            '    element: <div>Hello world!</div>,\n' +
                            '  },\n' +
                            ']);\n' +
                            '\n' +
                            'ReactDOM.createRoot(document.getElementById("root")).render(\n' +
                            '  <React.StrictMode>\n' +
                            '    <RouterProvider router={router} />\n' +
                            '  </React.StrictMode>\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Element componenta:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'\n' +
                            'import Root from "./routes/root";\n' +
                            '\n' +
                            'const router = createBrowserRouter([\n' +
                            '  {\n' +
                            '    path: "/",\n' +
                            '    element: <Root />,\n' +
                            '  },\n' +
                            ']);\n' +
                            '\n' +
                            'ReactDOM.createRoot(document.getElementById("root")).render(\n' +
                            '  <React.StrictMode>\n' +
                            '    <RouterProvider router={router} />\n' +
                            '  </React.StrictMode>\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Element componenta cu element de eroare:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {' \n' +
                            'import ErrorPage from "./error-page";\n' +
                            '\n' +
                            'const router = createBrowserRouter([\n' +
                            '  {\n' +
                            '    path: "/",\n' +
                            '    element: <Root />,\n' +
                            '    errorElement: <ErrorPage />,\n' +
                            '  },\n' +
                            ']);\n' +
                            '\n' +
                            'ReactDOM.createRoot(document.getElementById("root")).render(\n' +
                            '  <React.StrictMode>\n' +
                            '    <RouterProvider router={router} />\n' +
                            '  </React.StrictMode>\n' +
                            ');'}
                    </SyntaxHighlighter>

                    Ruta cu parametru:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'  {\n' +
                            '    path: "contacts/:contactId",\n' +
                            '    element: <Contact />,\n' +
                            '  },'}
                    </SyntaxHighlighter>

                    Rute incuibarite:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'const router = createBrowserRouter([\n' +
                            '  {\n' +
                            '    path: "/",\n' +
                            '    element: <Root />,\n' +
                            '    errorElement: <ErrorPage />,\n' +
                            '    children: [\n' +
                            '      {\n' +
                            '        path: "contacts/:contactId",\n' +
                            '        element: <Contact />,\n' +
                            '      },\n' +
                            '    ],\n' +
                            '  },\n' +
                            ']);'}
                    </SyntaxHighlighter>

                    JSX route:
                    <SyntaxHighlighter  showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {\n' +
                            '  createRoutesFromElements,\n' +
                            '  createBrowserRouter,\n' +
                            '} from "react-router-dom";\n' +
                            '\n' +
                            'const router = createBrowserRouter(\n' +
                            '  createRoutesFromElements(\n' +
                            '    <Route\n' +
                            '      path="/"\n' +
                            '      element={<Root />}\n' +
                            '      errorElement={<ErrorPage />}\n' +
                            '    >\n' +
                            '      <Route errorElement={<ErrorPage />}>\n' +
                            '        <Route index element={<Index />} />\n' +
                            '        <Route\n' +
                            '          path="contacts/:contactId"\n' +
                            '          element={<Contact />}\n' +
                            '        />\n' +
                            '        <Route\n' +
                            '          path="contacts/:contactId/edit"\n' +
                            '          element={<EditContact />}\n' +
                            '        />\n' +
                            '        <Route\n' +
                            '          path="contacts/:contactId/destroy"\n' +
                            '        />\n' +
                            '      </Route>\n' +
                            '    </Route>\n' +
                            '  )\n' +
                            ');'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Hook: useRoutes</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import * as React from "react";\n' +
                            'import { useRoutes } from "react-router-dom";\n' +
                            '\n' +
                            'function App() {\n' +
                            '  let element = useRoutes([\n' +
                            '    {\n' +
                            '      path: "/",\n' +
                            '      element: <Dashboard />,\n' +
                            '      children: [\n' +
                            '        {\n' +
                            '          path: "messages",\n' +
                            '          element: <DashboardMessages />,\n' +
                            '        },\n' +
                            '        { path: "tasks", element: <DashboardTasks /> },\n' +
                            '      ],\n' +
                            '    },\n' +
                            '    { path: "team", element: <AboutPage /> },\n' +
                            '  ]);\n' +
                            '\n' +
                            '  return element;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useRoutes } from \'react-router-dom\'\n' +
                            'const routes = useRoutes([\n' +
                            '  { path: \'/\', element: <Home /> },\n' +
                            '  {\n' +
                            '    path: \'/invoices\',\n' +
                            '    element: <Invoices />,\n' +
                            '    children: [\n' +
                            '      { path: \':id\', element: <Invoice /> },\n' +
                            '      { path: \'/pending\', element: <Pending /> },\n' +
                            '      { path: \'/complete\', element: <Complete /> },\n' +
                            '    ]\n' +
                            '  },\n' +
                            '  {\n' +
                            '    path: \'/users\',\n' +
                            '    element: <Users />,\n' +
                            '    children: [\n' +
                            '      { path: \':id\', element: <Profile /> },\n' +
                            '      { path: \'/settings\', element: <Settings /> },\n' +
                            '    ]\n' +
                            '  }\n' +
                            '])\n' +
                            'export default function App () {\n' +
                            '  return (\n' +
                            '    <div>\n' +
                            '      <Navbar />\n' +
                            '      {routes}\n' +
                            '    </div>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Rute dinamice</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<Routes>\n' +
                            '  <Route path="/" element={<Home />} />\n' +
                            '  <Route path="/books" element={<BookList />} />\n' +
                            '  <Route path="/books/:id" element={<Book />} />\n' +
                            '</Routes>'}
                    </SyntaxHighlighter>

                    Pentru a obtine parametrul <i>id</i> se foloseste hook-ul: <b>useParams()</b> :
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import { useParams } from "react-router-dom"\n' +
                            '\n' +
                            'export function Book() {\n' +
                            '  const { id } = useParams()\n' +
                            '\n' +
                            '  return (\n' +
                            '    <h1>Book {id}</h1>\n' +
                            '  )\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Prioritatea rutelor</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'<Routes>\n' +
                            '  <Route path="/" element={<Home />} />\n' +
                            '  <Route path="/books" element={<BookList />} />\n' +
                            '  <Route path="/books/:id" element={<Book />} />\n' +
                            '  <Route path="/books/new" element={<NewBook />} />\n' +
                            '</Routes>'}
                    </SyntaxHighlighter>

                    Prioritare sunt rutele <b>mai specifice</b>, in cazul de mai sus <i>/books/new</i> este mai specific decat <i>/books/:id</i>

                    <hr/>
                    <b>6. Rute private (o idee)</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import React, {useEffect, useState} from \'react\';\n' +
                            'import {Navigate} from "react-router-dom";\n' +
                            'import Auth from "./Auth";\n' +
                            '\n' +
                            'const PrivateRoute = ({ element }) => {\n' +
                            '\n' +
                            '    const [state, setState] = useState(\'loading\');\n' +
                            '\n' +
                            '    useEffect(() => {\n' +
                            '        (async function() {\n' +
                            '            try {\n' +
                            '                /* Update effect logic to track correct state */\n' +
                            '                const isUserLogged = await Auth.isAuthenticated();\n' +
                            '                setState(isUserLogged ? \'loggedin\' : \'redirect\');\n' +
                            '            }\n' +
                            '            catch {\n' +
                            '                setState(\'redirect\');\n' +
                            '            }\n' +
                            '        })();\n' +
                            '    }, []);\n' +
                            '\n' +
                            '    if(state === \'loading\') {\n' +
                            '        return <div>Loading..</div>\n' +
                            '    }\n' +
                            '\n' +
                            '    return state === \'loggedin\'\n' +
                            '            ? element\n' +
                            '            : <Navigate to=\'/access-denied\' />\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'export default PrivateRoute;'}
                    </SyntaxHighlighter>

                    Apoi se creeaza un fisier pentru rute (de exemplu <i>typescript.router.js</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import {useRoutes} from "react-router-dom";\n' +
                            'import React from "react";\n' +
                            'import TypescriptHomeContent from "./IndexContent";\n' +
                            'import PickTypescriptContent from "./PickTypescriptContent";\n' +
                            'import OmitTypescriptContent from "./OmitTypescriptContent";\n' +
                            'import InstallTypescriptContent from "./InstallTypescriptContent";\n' +
                            'import PrivateRoute from "../../security/PrivateRoute";\n' +
                            '\n' +
                            'export default function Router() {\n' +
                            '    let element = useRoutes([\n' +
                            '        {path: "/typescript/index", element: <TypescriptHomeContent/>},\n' +
                            '        {path: "/typescript/pick", element: <PrivateRoute element={<PickTypescriptContent/>}/>},\n' +
                            '        {path: "/typescript/omit", element: <PrivateRoute element={<OmitTypescriptContent/>}/>},\n' +
                            '        {path: "/typescript/install", element: <PrivateRoute element={<InstallTypescriptContent/>}/>},\n' +
                            '    ]);\n' +
                            '\n' +
                            '    return element;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Apoi in fisierul App, unde probabil se definesc toate rutele:

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'import TypeScriptRouters from "./page/typescript/typescript.router";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {'{/* typescript */}\n' +
                            '<TypeScriptRouters/>'}
                    </SyntaxHighlighter>

                    si astfel putem sa definim rutele in fisiere separate:
                    <SyntaxHighlighter showLineNumbers={true} language="javascript" style={androidstudio}>
                        {
                            '{/* util */}\n' +
                            '<UtilRouters/>\n' +
                            '\n' +
                            '{/* typescript */}\n' +
                            '<TypeScriptRouters/>\n' +
                            '\n' +
                            '<Routes>\n' +
                            '   <Route path="/" element={<SiteHomeContent/>}/>\n' +
                            '   <Route path="/home" element={<SiteHomeContent/>}/>\n' +
                            '</Routes>'
                        }
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://nextjs.org/learn/foundations/from-javascript-to-react/getting-started-with-react"}>De la JavaScript la React</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Routing6ReactContent;