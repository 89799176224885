import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class StartDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-start", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. docker start / docker container start</b>

                    <br/>
                    <br/>

                    Ruleaza un container dintr-o imagine pe baza id-ului de container; optional de poate pune flag-ul <b>-a</b> pentru a afisa rezultatul rularii in consola:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker start [-a] id_container'}
                    </SyntaxHighlighter>

                    sau
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container start [-a] id_container'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker start -a 64dd23a269a48ed42f48669ea100e5bbc05f3d99494bf16a6d5c93d9577b068f'}
                    </SyntaxHighlighter>

                    Un container poate fi pornit din starea:
                    <ul>
                        <li>existed</li>
                        <li>created</li>
                    </ul>

                    <b>Observatie</b>
                    <br/>
                    Daca un container a fost creat/rulat cu o anumita comanda, cand se porneste/reporneste nu se poate schimba comanda initiala.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StartDockerContent;