import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AppConfigJspLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-app-config-jsp", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    ro.ibrid.app.config.jsp.application.list.ConfigurationPanelApp
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.application.list;\n' +
                            '\n' +
                            'import com.liferay.application.list.BasePanelApp;\n' +
                            'import com.liferay.application.list.PanelApp;\n' +
                            'import com.liferay.application.list.constants.PanelCategoryKeys;\n' +
                            'import com.liferay.portal.kernel.model.Portlet;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.ibrid.app.config.jsp.constants.AppConfigPortletKeys;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "panel.app.order:Integer=101",\n' +
                            '                "panel.category.key=" + PanelCategoryKeys.CONTROL_PANEL_CONFIGURATION\n' +
                            '        },\n' +
                            '        service = PanelApp.class\n' +
                            ')\n' +
                            'public class ConfigurationPanelApp extends BasePanelApp {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Portlet getPortlet() {\n' +
                            '        return _portlet;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getPortletId() {\n' +
                            '        return AppConfigPortletKeys.APPCONFIG;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference(\n' +
                            '            target = "(javax.portlet.name=" + AppConfigPortletKeys.APPCONFIG + ")"\n' +
                            '    )\n' +
                            '    private Portlet _portlet;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.constants.AppConfigPortletKeys
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.constants;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author IulianB\n' +
                            ' */\n' +
                            'public class AppConfigPortletKeys {\n' +
                            '\n' +
                            '\tpublic static final String APPCONFIG = "ro_ibrid_app_config_jsp_AppConfigPortlet";\n' +
                            '\n' +
                            '\tpublic static final String CONFIG_VALUE_PREFIX = "parameter-value-";\n' +
                            '\tpublic static final String RESET_PARAM = "reset";\n' +
                            '\n' +
                            '\tpublic static final String KEY_UPDATE_SUCCESS = "update.success";\n' +
                            '\tpublic static final String KEY_UPDATE_ERROR = "update.error";\n' +
                            '\tpublic static final String KEY_RESET_SUCCESS = "reset.success";\n' +
                            '\tpublic static final String KEY_RESET_ERROR = "reset.error";\n' +
                            '}'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.constants.ConfigParamKeys
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.constants;\n' +
                            '\n' +
                            'public enum ConfigParamKeys {\n' +
                            '\n' +
                            '    MY_URL("https://dopamina.ro/");\n' +
                            '\n' +
                            '    String defaultValue;\n' +
                            '\n' +
                            '    ConfigParamKeys(String defaultValue){\n' +
                            '        this.defaultValue = defaultValue;\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getDefaultValue() {\n' +
                            '        return defaultValue;\n' +
                            '    }\n' +
                            '\n' +
                            '    public void setDefaultValue(String defaultValue) {\n' +
                            '        this.defaultValue = defaultValue;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.constants.ConfigParamType:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.constants;\n' +
                            '\n' +
                            'public enum ConfigParamType {\n' +
                            '\n' +
                            '    STRING,\n' +
                            '    NUMBER,\n' +
                            '    DATE,\n' +
                            '    BOOLEAN;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.portlet.AppConfigPortlet:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.portlet;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.exception.SystemException;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.model.PersistedModel;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                            'import com.liferay.portal.kernel.servlet.SessionErrors;\n' +
                            'import com.liferay.portal.kernel.servlet.SessionMessages;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.ibrid.app.config.jsp.constants.AppConfigPortletKeys;\n' +
                            'import ro.ibrid.app.config.jsp.service.ConfigurationResources;\n' +
                            'import ro.ibrid.app.servicebuilder.model.ConfigParam;\n' +
                            'import ro.ibrid.app.servicebuilder.service.ConfigParamLocalService;\n' +
                            '\n' +
                            'import javax.portlet.*;\n' +
                            'import java.io.IOException;\n' +
                            'import java.util.HashMap;\n' +
                            'import java.util.List;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author IulianB\n' +
                            ' */\n' +
                            '@Component(\n' +
                            '\tproperty = {\n' +
                            '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                            '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                            '\t\t"javax.portlet.display-name=AppConfig",\n' +
                            '\t\t"javax.portlet.init-param.template-path=/",\n' +
                            '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                            '\t\t"javax.portlet.name=" + AppConfigPortletKeys.APPCONFIG,\n' +
                            '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                            '\t    "javax.portlet.security-role-ref=administrator,power-user,user"\n' +
                            '\t},\n' +
                            '\tservice = Portlet.class\n' +
                            ')\n' +
                            'public class AppConfigPortlet extends MVCPortlet {\n' +
                            '\n' +
                            '\tprivate static final Log log = LogFactoryUtil.getLog(AppConfigPortlet.class);\n' +
                            '\n' +
                            '\n' +
                            '\t@Reference\n' +
                            '\tprivate ConfigParamLocalService configParamLocalService;\n' +
                            '\n' +
                            '\t@Reference\n' +
                            '\tprivate ConfigurationResources configurationResources;\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic void render(RenderRequest renderRequest, RenderResponse renderResponse) throws IOException, PortletException {\n' +
                            '\t\tresetDifferences();\n' +
                            '\t\tsuper.render(renderRequest, renderResponse);\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic void updateConfig(ActionRequest request, ActionResponse response) {\n' +
                            '\t\tif (log.isDebugEnabled()) {\n' +
                            '\t\t\tlog.debug("AppConfigPortlet processAction");\n' +
                            '\t\t}\n' +
                            '\t\tMap<String, String> codeValues = new HashMap<>();\n' +
                            '\n' +
                            '\t\tfor (Map.Entry<String, String[]> parameter : request.getParameterMap().entrySet()) {\n' +
                            '\t\t\tif (parameter.getKey().startsWith(AppConfigPortletKeys.CONFIG_VALUE_PREFIX)) {\n' +
                            '\t\t\t\tcodeValues.put(\n' +
                            '\t\t\t\t\t\tparameter.getKey().substring(AppConfigPortletKeys.CONFIG_VALUE_PREFIX.length(), parameter.getKey().length()),\n' +
                            '\t\t\t\t\t\tparameter.getValue()[0]);\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\n' +
                            '\t\ttry {\n' +
                            '\t\t\tcodeValues.forEach((code, value) -> {\n' +
                            '\t\t\t\tConfigParam configParam = configurationResources.getConfigParamByCode(code);\n' +
                            '\t\t\t\tif (configParam != null) {\n' +
                            '\t\t\t\t\tconfigParam.setValue(value);\n' +
                            '\t\t\t\t\tconfigParamLocalService.updateConfigParam(configParam);\n' +
                            '\t\t\t\t}\n' +
                            '\t\t\t});\n' +
                            '\t\t\tSessionMessages.add(request, AppConfigPortletKeys.KEY_UPDATE_SUCCESS);\n' +
                            '\t\t} catch (Exception e) {\n' +
                            '\t\t\tlog.error(e.getMessage(), e);\n' +
                            '\t\t\tSessionErrors.add(request, AppConfigPortletKeys.KEY_UPDATE_ERROR);\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '\tprivate void resetDifferences() throws SystemException {\n' +
                            '\t\t// get the latest values from the database\n' +
                            '\t\tList<ConfigParam> databaseValues = configParamLocalService.getConfigParams(-1, -1);\n' +
                            '\n' +
                            '\t\t// get the default values from memory\n' +
                            '\t\tList<ConfigParam> defaultValues = configurationResources.getDefaultValues();\n' +
                            '\n' +
                            '\t\t// add missing values to database\n' +
                            '\t\tfor (PersistedModel defaultValue : defaultValues) {\n' +
                            '\t\t\tif (!databaseValues.contains(defaultValue)) {\n' +
                            '\t\t\t\tconfigParamLocalService.addConfigParam((ConfigParam)defaultValue);\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.service.CommonConfigResources
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.service;\n' +
                            '\n' +
                            'import ro.ibrid.app.servicebuilder.model.ConfigParam;\n' +
                            '\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            'public interface CommonConfigResources<T> {\n' +
                            '\n' +
                            '    String getValue(String key);\n' +
                            '\n' +
                            '    List<T> getDefaultValues();\n' +
                            '\n' +
                            '    String getDefaultValue(String key);\n' +
                            '\n' +
                            '    ConfigParam getConfigParamByCode(String code);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.service.ConfigurationResources
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.service;\n' +
                            '\n' +
                            '\n' +
                            'import ro.ibrid.app.servicebuilder.model.ConfigParam;\n' +
                            '\n' +
                            'public interface ConfigurationResources extends CommonConfigResources<ConfigParam> {\n' +
                            '    \n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ro.ibrid.app.config.jsp.service.impl.ConfigurationResourcesImpl
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.app.config.jsp.service.impl;\n' +
                            '\n' +
                            'import com.liferay.counter.kernel.service.CounterLocalServiceUtil;\n' +
                            'import com.liferay.portal.kernel.dao.orm.DynamicQuery;\n' +
                            'import com.liferay.portal.kernel.dao.orm.DynamicQueryFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.dao.orm.RestrictionsFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.service.RoleLocalServiceUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.ibrid.app.config.jsp.constants.ConfigParamKeys;\n' +
                            'import ro.ibrid.app.config.jsp.constants.ConfigParamType;\n' +
                            'import ro.ibrid.app.config.jsp.service.ConfigurationResources;\n' +
                            'import ro.ibrid.app.servicebuilder.model.ConfigParam;\n' +
                            'import ro.ibrid.app.servicebuilder.service.ConfigParamLocalServiceUtil;\n' +
                            '\n' +
                            'import java.util.ArrayList;\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            '@Component(immediate = true, property = {}, service = ConfigurationResources.class)\n' +
                            'public class ConfigurationResourcesImpl implements ConfigurationResources {\n' +
                            '\n' +
                            '\tprivate static Log log = LogFactoryUtil.getLog(ConfigurationResourcesImpl.class);\n' +
                            '\n' +
                            '\tprivate boolean initAlreadyPerformed;\n' +
                            '\n' +
                            '\tpublic ConfigurationResourcesImpl() {\n' +
                            '\t\tinitAlreadyPerformed = false;\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getValue(String key) {\n' +
                            '\t\tinitDefaultValues();\n' +
                            '\t\ttry {\n' +
                            '\t\t\treturn getConfigParamByCode(key).getValue();\n' +
                            '\t\t} catch (Exception e) {\n' +
                            '\t\t\tlog.error("For key " + key + ": " + e.getMessage(), e);\n' +
                            '\t\t}\n' +
                            '\t\treturn getDefaultValue(key);\n' +
                            '\t}\n' +
                            '\n' +
                            '\tpublic ConfigParam getConfigParamByCode(String code){\n' +
                            '\t\tDynamicQuery dQ = DynamicQueryFactoryUtil.forClass(ConfigParam.class, getClass().getClassLoader());\n' +
                            '\t\tdQ.add(RestrictionsFactoryUtil.eq("code",code));\n' +
                            '\n' +
                            '\t\tList<ConfigParam> configParamList = ConfigParamLocalServiceUtil.dynamicQuery(dQ);\n' +
                            '\n' +
                            '\t\tif (configParamList!=null){\n' +
                            '\t\t\tif (configParamList.size()>0){\n' +
                            '\t\t\t\treturn configParamList.get(0);\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t\treturn null;\n' +
                            '\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic List<ConfigParam> getDefaultValues() {\n' +
                            '\t\tList<ConfigParam> configParams = new ArrayList<>();\n' +
                            '\n' +
                            '\t\tfor(ConfigParamKeys configParamKey: ConfigParamKeys.values()) {\n' +
                            '\t\t\tconfigParams.add(getConfigParam(configParamKey));\n' +
                            '\t\t}\n' +
                            '\n' +
                            '\t\treturn configParams;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tprivate ConfigParam getConfigParam(ConfigParamKeys configParamKeys) {\n' +
                            '\t\tConfigParam configParam = getConfigParamByCode(configParamKeys.name());\n' +
                            '\t\tif (configParam==null){\n' +
                            '\t\t\tconfigParam = ConfigParamLocalServiceUtil.createConfigParam(CounterLocalServiceUtil.increment());\n' +
                            '\t\t\tconfigParam.setCode(configParamKeys.name());\n' +
                            '\t\t\tconfigParam.setValue(configParamKeys.getDefaultValue());\n' +
                            '\t\t\tconfigParam.setParamType(ConfigParamType.STRING.name());\n' +
                            '\t\t\tconfigParam.setDescription("");\n' +
                            '\t\t}\n' +
                            '\n' +
                            '\t\treturn configParam;\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getDefaultValue(String key) {\n' +
                            '\t\tfor (ConfigParam configParam : getDefaultValues()) {\n' +
                            '\t\t\tif (configParam.getCode().equals(key)) {\n' +
                            '\t\t\t\treturn configParam.getValue();\n' +
                            '\t\t\t}\n' +
                            '\t\t}\n' +
                            '\t\treturn null;\n' +
                            '\t}\n' +
                            '\n' +
                            '\tprivate void initDefaultValues() {\n' +
                            '\t\tif (initAlreadyPerformed) {\n' +
                            '\t\t\treturn;\n' +
                            '\t\t}\n' +
                            '\n' +
                            '\t\ttry {\n' +
                            '\t\t\tint count = ConfigParamLocalServiceUtil.getConfigParamsCount();\n' +
                            '\t\t\tif (count == 0) {\n' +
                            '\t\t\t\tfor (ConfigParam configParam : getDefaultValues()) {\n' +
                            '\t\t\t\t\tConfigParamLocalServiceUtil.addConfigParam(configParam);\n' +
                            '\t\t\t\t}\n' +
                            '\t\t\t}\n' +
                            '\t\t\tinitAlreadyPerformed = true;\n' +
                            '\t\t} catch (Exception e) {\n' +
                            '\t\t\tlog.error("Could not init default configuration parameters (and will use hardcoded backup parameters): "\n' +
                            '\t\t\t\t\t+ e.getMessage(), e);\n' +
                            '\t\t}\n' +
                            '\t}\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    init.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                            '\n' +
                            '<liferay-theme:defineObjects />\n' +
                            '\n' +
                            '<portlet:defineObjects />'}
                    </SyntaxHighlighter>

                    view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ page import="com.liferay.portal.kernel.util.Constants" %>\n' +
                            '<%@ page import="com.liferay.portal.kernel.util.ParamUtil" %>\n' +
                            '<%@ page import="ro.ibrid.app.config.jsp.constants.AppConfigPortletKeys" %>\n' +
                            '<%@ page import="ro.ibrid.app.servicebuilder.service.ConfigParamLocalServiceUtil" %>\n' +
                            '<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %>\n' +
                            '<%@ taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %>\n' +
                            '<%@ taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %>\n' +
                            '<%@ taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                            '\n' +
                            '<liferay-ui:success key="<%= AppConfigPortletKeys.KEY_UPDATE_SUCCESS %>"\n' +
                            '                    message="ro.simibridavi.app.config.Configuration.message.update.success"/>\n' +
                            '<liferay-ui:error key="<%= AppConfigPortletKeys.KEY_UPDATE_ERROR %>"\n' +
                            '                  message="ro.ibrid.app.config.Configuration.message.update.error"/>\n' +
                            '<liferay-ui:success key="<%= AppConfigPortletKeys.KEY_RESET_SUCCESS %>"\n' +
                            '                    message="ro.ibrid.app.config.Configuration.message.reset.success"/>\n' +
                            '<liferay-ui:error key="<%= AppConfigPortletKeys.KEY_RESET_ERROR %>"\n' +
                            '                  message="ro.ibrid.app.config.Configuration.message.reset.error"/>\n' +
                            '\n' +
                            '<portlet:actionURL name="updateConfig" var="configurationURL"/>\n' +
                            '\n' +
                            '<%\n' +
                            '    String redirect = ParamUtil.getString(request, "redirect");\n' +
                            '%>\n' +
                            '\n' +
                            '<div class="container">\n' +
                            '    <aui:form action="${configurationURL}" method="post" name="fm">\n' +
                            '        <aui:input name="<%= Constants.CMD %>" type="hidden" value="<%= Constants.UPDATE %>"/>\n' +
                            '        <aui:input name="redirect" type="hidden" value="<%= redirect %>"/>\n' +
                            '\n' +
                            '        <aui:fieldset>\n' +
                            '\n' +
                            '            <liferay-ui:search-container\n' +
                            '                    headerNames="\n' +
                            '\t\t\t\t\t\tro.ibrid.app.config.Configuration.code,\n' +
                            '\t\t\t\t\t\tro.ibrid.app.config.Configuration.parameterType,\n' +
                            '\t\t\t\t\t\tro.ibrid.app.config.Configuration.value,\n' +
                            '\t\t\t\t\t\tro.ibrid.app.config.Configuration.description"\n' +
                            '                    delta="40" total="<%=ConfigParamLocalServiceUtil.getConfigParamsCount()%>">\n' +
                            '\n' +
                            '                <liferay-ui:search-container-results\n' +
                            '                        results="<%=ConfigParamLocalServiceUtil.getConfigParams(searchContainer.getStart(), searchContainer.getEnd())%>"\n' +
                            '                />\n' +
                            '\n' +
                            '                <liferay-ui:search-container-row\n' +
                            '                        className="ro.ibrid.app.servicebuilder.model.ConfigParam"\n' +
                            '                        keyProperty="code"\n' +
                            '                        modelVar="curConfigParam">\n' +
                            '\n' +
                            '                    <liferay-ui:search-container-column-text name="ro.ibrid.app.config.Configuration.code"\n' +
                            '                                                             property="code" cssClass="configurationCodeColumn"/>\n' +
                            '\n' +
                            '                    <%--                    <liferay-ui:search-container-column-text name="ro.ibrid.app.config.Configuration.parameterType"--%>\n' +
                            '                    <%--                                                             property="parameterType"--%>\n' +
                            '                    <%--                                                             cssClass="configurationTypeColumn"/>--%>\n' +
                            '\n' +
                            '                    <liferay-ui:search-container-column-text name="ro.ibrid.app.config.Configuration.value">\n' +
                            '                        <aui:input id="<%=AppConfigPortletKeys.CONFIG_VALUE_PREFIX + curConfigParam.getCode()%>"\n' +
                            '                                   name="<%=AppConfigPortletKeys.CONFIG_VALUE_PREFIX + curConfigParam.getCode()%>"\n' +
                            '                                   value="${curConfigParam.getValue()}"\n' +
                            '                                   size="100"\n' +
                            '                                   maxlength="4000"\n' +
                            '                                   cssClass="configurationValueColumn"\n' +
                            '                                   label=""/>\n' +
                            '                    </liferay-ui:search-container-column-text>\n' +
                            '\n' +
                            '                    <%--                    <liferay-ui:search-container-column-text name="ro.ibrid.app.config.Configuration.description"--%>\n' +
                            '                    <%--                                                             property="description"--%>\n' +
                            '                    <%--                                                             cssClass="configurationDescriptionColumn"--%>\n' +
                            '                    <%--                                                             translate="true"/>--%>\n' +
                            '\n' +
                            '                </liferay-ui:search-container-row>\n' +
                            '\n' +
                            '                <liferay-ui:search-iterator paginate="true"/>\n' +
                            '\n' +
                            '            </liferay-ui:search-container>\n' +
                            '\n' +
                            '        </aui:fieldset>\n' +
                            '\n' +
                            '        <p id="separator"></p>\n' +
                            '        <div class="d-flex flex-row-reverse" style="background: #eee;">\n' +
                            '\n' +
                            '            <div class="p-2">\n' +
                            '                <aui:button-row>\n' +
                            '                    <aui:input type="hidden" id="<%=AppConfigPortletKeys.RESET_PARAM%>"\n' +
                            '                               name="<%=AppConfigPortletKeys.RESET_PARAM%>" value="false"/>\n' +
                            '                    <aui:button type="submit" value="ro.ibrid.app.config.Configuration.submit"/>\n' +
                            '                </aui:button-row>\n' +
                            '            </div>\n' +
                            '\n' +
                            '        </div>\n' +
                            '\n' +
                            '    </aui:form>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    Language.properties:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'javax.portlet.title.ro_ibrid_app_config_jsp_AppConfigPortlet= App Config (JSP)\n' +
                            'appconfig.caption=Hello from AppConfig!\n' +
                            '\n' +
                            'ro.ibrid.app.config.Configuration.configurationPageTitle=Configuration Options\n' +
                            'ro.ibrid.app.config.Configuration.code=Code\n' +
                            'ro.ibrid.app.config.Configuration.parameterType=Type\n' +
                            'ro.ibrid.app.config.Configuration.value=Value\n' +
                            'ro.ibrid.app.config.Configuration.description=Description\n' +
                            'ro.ibrid.app.config.Configuration.message.update.success=The configuration parameters were successfully updated\n' +
                            'ro.ibrid.app.config.Configuration.message.update.error=The configuration parameters could not be updated. Please try again.\n' +
                            'ro.ibrid.app.config.Configuration.message.reset.success=The configuration parameters were successfully reset\n' +
                            'ro.ibrid.app.config.Configuration.message.reset.error=The configuration parameters could not be reset. Please try again.\n' +
                            'ro.ibrid.app.config.Configuration.submit=Save\n' +
                            'ro.ibrid.app.config.Configuration.noConfigParameters=No configuration parameters defined.'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: ibrid-app-config-jsp\n' +
                            'Bundle-SymbolicName: ro.ibrid.app.config.jsp\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Export-Package: ro.ibrid.app.config.jsp.constants\n' +
                            'Web-ContextPath: /ibrid-app-config-jsp'}
                    </SyntaxHighlighter>

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '\n' +
                            '\tcompile project(":modules:ibrid-app-servicebuilder:ibrid-app-servicebuilder-api")\n' +
                            '\n' +
                            '\tcompileOnly group: "org.osgi", name: "org.osgi.service.component.annotations", version: "1.3.0"\n' +
                            '\tcompileOnly group: "org.osgi", name: "org.osgi.service.jaxrs", version: "1.0.0"\n' +
                            '\t\n' +
                            '\tcssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.1.0"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    service.xml:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                            '<!DOCTYPE service-builder PUBLIC "-//Liferay//DTD Service Builder 7.4.0//EN" "http://www.liferay.com/dtd/liferay-service-builder_7_4_0.dtd">\n' +
                            '\n' +
                            '<service-builder dependency-injector="ds" package-path="ro.ibrid.app.servicebuilder">\n' +
                            '\n' +
                            '\t<author>IBRID</author>\n' +
                            '\t<namespace>ibrid_portal</namespace>\n' +
                            '\n' +
                            '\t<entity name="ConfigParam" local-service="true" remote-service="false">\n' +
                            '\n' +
                            '\t\t<!-- PK fields -->\n' +
                            '\t\t<column name="id" primary="true" type="long" />\n' +
                            '\n' +
                            '\t\t<column name="code" type="String" />\n' +
                            '\t\t<column name="value" type="String" />\n' +
                            '\t\t<column name="description" type="String" />\n' +
                            '\t\t<column name="paramType" type="String" />\n' +
                            '\n' +
                            '\t</entity>\n' +
                            '</service-builder>'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca din Control Panel nu se afiseaza portletul, atunci se poate incerca:
                    <ul>
                        <li>
                            Componenta de tip MVCPortlet:
                            <SyntaxHighlighter>
                                {' "com.liferay.portlet.instanceable=false",'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Componenta de tip BasePanelApp:
                            <SyntaxHighlighter>
                                {'   immediate = true,'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AppConfigJspLiferayContent;