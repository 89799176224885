import {useRoutes} from "react-router-dom";
import IndexContent from "./IndexContent";
import React from "react";
import PrivateRoute from "../../security/PrivateRoute";
import UtilRpiContent from "./UtilRpiContent";
import AudioRpiContent from "./AudioRpiContent";
import SpeechRecognitionRpiContent from "./SpeechRecognitionRpiContent";
import FastWhisperRpiContent from "./FastWhisperRpiContent";


export default function Router() {
    let element = useRoutes([
        {path: "/rpi/index", element: <IndexContent/>},
        {path: "/rpi/audio", element: <PrivateRoute element={<AudioRpiContent/>}/>},
        {path: "/rpi/speech-recognition", element: <PrivateRoute element={<SpeechRecognitionRpiContent/>}/>},
        {path: "/rpi/fast-whisper", element: <PrivateRoute element={<FastWhisperRpiContent/>}/>},
        {path: "/rpi/util", element: <PrivateRoute element={<UtilRpiContent/>}/>},

    ]);

    return element;
}