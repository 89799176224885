import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class ChromeContent extends BaseContentPage  {

    constructor(props) {
        super(props, "util-chrome", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Pornire Chrome cu un anumit user-agent si o pagina web implicita</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'"C:\\Program Files\\Google\\Chrome\\Application\\chrome.exe" --user-agent="infokj" --new-windows https://scenic.ro --kiosk'}
                    </SyntaxHighlighter>

                    Fiecare parametru are următoarea semnificație:
                    <ul>
                        <li>
                            "C:\Program Files\Google\Chrome\Application\chrome.exe":
                            <br/>
                            Aceasta este calea completă către executabilul Google Chrome pe sistemul Windows. Această cale indică unde este instalat Google Chrome și este folosită pentru a lansa aplicația.
                        </li>
                        <li>
                            --user-agent="infochiosk":
                            <br/>
                            Acest parametru setează un user-agent specific pentru sesiunea de navigare. Un user-agent este un șir de caractere trimis de browser către servere pentru a le spune ce tip de browser
                            și sistem de operare este folosit. În acest caz, user-agent-ul este setat la "infochiosk", indicând un profil specific,
                            poate pentru a simula un anumit tip de dispozitiv sau pentru a permite accesul special la site-uri care recunosc acest user-agent.
                        </li>
                        <li>
                            --new-windows:
                            Acest parametru deschide fiecare tab nou într-o fereastră separată,
                            în loc să folosească taburile standard. În versiuni mai noi de Chrome,
                            acest parametru este rar folosit, fiind înlocuit cu alte metode de control al comportamentului ferestrelor.
                        </li>
                        <li>
                            https:/scenic.ro/
                            Aceasta este pagina web implicită care va fi deschisă la pornirea Chrome. În acest caz, este o adresă URL specifică
                        </li>
                        <li>
                            --kiosk:
                            Acest parametru pune browser-ul în modul "kiosk". În modul kiosk, Google Chrome va rula pe tot ecranul (full screen)
                            și va ascunde toate elementele de interfață (barele de instrumente, tab-urile etc.), transformând browser-ul într-o aplicație dedicată.
                            Este util pentru standuri interactive, puncte de informare, sau alte medii în care interacțiunea utilizatorului trebuie limitată.
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChromeContent;