import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class JavaPluginGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-java-plugin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Import Java Plugin</b>
                    <br/>
                    <br/>

                    Import Java Plugin:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'apply plugin: \'java\''}
                    </SyntaxHighlighter>

                    sau

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins{\n' +
                            '   id "java"\n'+
                        '}\n'}
                    </SyntaxHighlighter>

                    Acest plugin vine cu task-uri si proprietati utile in procesul de <i>build</i>.
                    <hr/>
                    Sa presupunem ca avem scriptul <i>build-gradle.build</i>, cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins {\n' +
                        '    id \'java\'\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a afisa taskuri disponibile (care vin datorita importului plug-in-ului <i>java</i>): <b>gradle tasks -b build-gradle.build</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle tasks -b build-java.gradle\n' +
                        '\n' +
                        '> Task :tasks\n' +
                        '\n' +
                        '------------------------------------------------------------\n' +
                        'Tasks runnable from root project\n' +
                        '------------------------------------------------------------\n' +
                        '\n' +
                        'Build tasks\n' +
                        '-----------\n' +
                        'assemble - Assembles the outputs of this project.\n' +
                        'build - Assembles and tests this project.\n' +
                        'buildDependents - Assembles and tests this project and all projects that depend on it.\n' +
                        'buildNeeded - Assembles and tests this project and all projects it depends on.\n' +
                        'classes - Assembles main classes.\n' +
                        'clean - Deletes the build directory.\n' +
                        'jar - Assembles a jar archive containing the main classes.\n' +
                        'testClasses - Assembles test classes.\n' +
                        '\n' +
                        'Build Setup tasks\n' +
                        '-----------------\n' +
                        'init - Initializes a new Gradle build.\n' +
                        'wrapper - Generates Gradle wrapper files.\n' +
                        '\n' +
                        'Documentation tasks\n' +
                        '-------------------\n' +
                        'javadoc - Generates Javadoc API documentation for the main source code.\n' +
                        '\n' +
                        'Help tasks\n' +
                        '----------\n' +
                        'buildEnvironment - Displays all buildscript dependencies declared in root project \'gradle\'.\n' +
                        'components - Displays the components produced by root project \'gradle\'. [incubating]\n' +
                        'dependencies - Displays all dependencies declared in root project \'gradle\'.\n' +
                        'dependencyInsight - Displays the insight into a specific dependency in root project \'gradle\'.\n' +
                        'dependentComponents - Displays the dependent components of components in root project \'gradle\'. [incubating]\n' +
                        'help - Displays a help message.\n' +
                        'model - Displays the configuration model of root project \'gradle\'. [incubating]\n' +
                        'outgoingVariants - Displays the outgoing variants of root project \'gradle\'.\n' +
                        'projects - Displays the sub-projects of root project \'gradle\'.\n' +
                        'properties - Displays the properties of root project \'gradle\'.\n' +
                        'tasks - Displays the tasks runnable from root project \'gradle\'.\n' +
                        '\n' +
                        'Verification tasks\n' +
                        '------------------\n' +
                        'check - Runs all checks.\n' +
                        'test - Runs the unit tests.\n' +
                        '\n' +
                        'Rules\n' +
                        '-----\n' +
                        'Pattern: clean<TaskName>: Cleans the output files of a task.\n' +
                        'Pattern: build<ConfigurationName>: Assembles the artifacts of a configuration.\n' +
                        'Pattern: upload<ConfigurationName>: Assembles and uploads the artifacts belonging to a configuration.\n' +
                        '\n' +
                        'To see all tasks and more detail, run gradle tasks --all\n' +
                        '\n' +
                        'To see more detail about a task, run gradle help --task <task>\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '1 actionable task: 1 executed'}
                    </SyntaxHighlighter>

                    <b>2. Structura unui proiect Java</b>
                    <br/>
                    <br/>
                    Structura:
                    <ul>
                        <li>src/main/java</li>
                        <li>src/main/resources</li>
                        <li>src/test/java</li>
                        <li>src/test/resources</li>
                    </ul>

                    <b>3. Build proiect Java / Task-ul build</b>
                    <br/>
                    <br/>

                    Presupunem ca vom crea clasa <i>HelloMain</i> in <i>src/main/java</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class HelloMain{\n' +
                        '\t\n' +
                        '\tpublic static void main(String[] args){\n' +
                        '\t\t\n' +
                        '\t\tSystem.out.println("Hello!");\n' +
                        '\t\t\n' +
                        '\t}\n' +
                        '\t\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a face build, vom executa urmatoare comanda gradle: <b>gradle build</b> -b build-gradle.build.

                    <br/>
                    In urma executiei, se va genera:
                    <ul>
                        <li><b>build/classes/..</b>: va contine fisierele *.class compilate;
                            <br/>
                            exemplu: :build/classes/java/main/HelloMain.class;
                        </li>
                        <li><b>build/libs</b>/..: va contine fisierul JAR generat;
                            <br/>
                            exemplu: gradle.jar; jar-ul se numeste gradle.jar, pentru ca proiectul se gaseste intr-un folder numit <i>gradle</i>;
                            <br/>
                            putem schimba acest nume: plugin-ul <i>java</i> vine cu task-ul <b>jar</b>

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'plugins {\n' +
                                '    id \'java\'\n' +
                                '}\n' +
                                '\n' +
                                'jar{\n' +
                                '\tarchiveFileName = "hello.jar"\n' +
                                '}'}
                            </SyntaxHighlighter>

                            sau

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'// apply plugin: \'java\'\n' +
                                'plugins {\n' +
                                '    id \'java\'\n' +
                                '}\n' +
                                '\n' +
                                'jar{\n' +
                                '\tarchiveBaseName = "hello"\n' +
                                '}'}
                            </SyntaxHighlighter>

                        </li>
                        <li>build/generated/...</li>
                        <li>build/tmp/...</li>
                    </ul>

                    Pentru a afisa ce task-uri se ruleaza pentru a face <b>build</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle build -b build-java.gradle --console=verbose\n' +
                        '> Task :compileJava\n' +
                        '> Task :processResources UP-TO-DATE\n' +
                        '> Task :classes\n' +
                        '> Task :jar\n' +
                        '> Task :assemble\n' +
                        '> Task :compileTestJava NO-SOURCE\n' +
                        '> Task :processTestResources NO-SOURCE\n' +
                        '> Task :testClasses UP-TO-DATE\n' +
                        '> Task :test NO-SOURCE\n' +
                        '> Task :check UP-TO-DATE\n' +
                        '> Task :build'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3.1. Task-ul clean</b>
                    <br/>
                    <br/>
                    Sterge directorul <b>build</b>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle clean -b build-java.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3.2. Task-ul jar</b>
                    <br/>
                    <br/>

                    Task-ul <b>jar</b> genereaza fisierul <b>jar</b> in <b>build/libs</b> si permite adaugarea de informatii in fisierul <b>MANIFEST.MF</b>.
                    <br/>
                    Implicit, continutul acestui fisier arata in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Manifest-Version: 1.0'}
                    </SyntaxHighlighter>

                    Modificam <i>build-gradle.build</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'// apply plugin: \'java\'\n' +
                        'plugins {\n' +
                        '    id \'java\'\n' +
                        '}\n' +
                        '\n' +
                        'jar{\n' +
                        '\t//archiveFileName = "hello.jar"\n' +
                        '\tarchiveBaseName = "hello"\n' +
                        '\tmanifest{\n' +
                        '\t\tattributes(\n' +
                        '\t\t\t\'Main-Class\':\'HelloMain\',\n' +
                        '\t\t\t\'Implementation-Title\':project.name,\n' +
                        '\t\t\t\'Developer\':\'KJ\'\n' +
                        '\t\t)\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Dupa build, fisierul <b>MANIFEST.MF</b>, va avea acum, urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Manifest-Version: 1.0\n' +
                        'Main-Class: HelloMain\n' +
                        'Implementation-Title: gradle\n' +
                        'Developer: KJ'}
                    </SyntaxHighlighter>

                    Daca adaugam proprietatea <b>version</b> pentru proiect, acesta va fi pus si la numele jar-ului generat (se va genera: 'hello-1.0.0'):
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins{\n' +
                        '\tid \'java\'\n' +
                        '}\n' +
                        '\n' +
                        'version="1.0.0"\n' +
                        '\n' +
                        'jar{\n' +
                        '\tarchiveBaseName = "hello"\n' +
                        '\tmanifest{\n' +
                        '\t\tattributes(\n' +
                        '\t\t\t\'Main-Class\':\'HelloMain\',\n' +
                        '\t\t\t\'Implementation-Title\':project.name,\n' +
                        '\t\t\t\'Developer\':\'KJ\'\n' +
                        '\t\t)\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'java{\n' +
                        '\tsourceCompatibility = JavaVersion.VERSION_11\n' +
                        '\ttargetCompatibility = JavaVersion.VERSION_11\n' +
                        '}\n' +
                        '\n' +
                        'compileJava{\n' +
                        '\toptions.compilerArgs << \'-Werror\'\n' +
                        '}\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3.3. Task-ul javadoc</b>
                    <br/>
                    <br/>
                    Acest task genereaza Javadocs.
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle javadoc -b build-java.gradle'}
                    </SyntaxHighlighter>
                    Acest task va genera un site in: <b>build\docs\javadoc</b>.

                    <hr/>
                    <b>3.3. Task-ul compileJava</b>
                    <br/>
                    <br/>
                    Codul sursa din <b>src/main/java</b> este compilat de task-ul <b>compileJava</b> si fisiere *.class sunt puse in <b>build/classes/..</b>.

                    <hr/>
                    <b>3.4. Task-ul processResources</b>
                    <br/>
                    <br/>
                    Copiază resursele din <b>src/main/resources</b> în directorul țintă <b>build\resources\main</b> (eventual procesându-le).

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {' gradle processResources -b build-java.gradle'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>3.4. Task-ul classes</b>
                    <br/>
                    <br/>
                    Executa urmatoarele task-uri:
                    <ul>
                        <li>compileJava</li>
                        <li>processResources</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'gradle classes -b build-java.gradle --console=verbose\n' +
                        '> Task :compileJava UP-TO-DATE\n' +
                        '> Task :processResources UP-TO-DATE\n' +
                        '> Task :classes UP-TO-DATE\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 1s\n' +
                        '2 actionable tasks: 2 up-to-date'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Setari compilare</b>
                    <br/>
                    <br/>
                    Pentru a avea mai mult control la procesul de compilare, se poate seta versiune compatibila de Java, precum si alte optuni de compilare.

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'plugins{\n' +
                        '\tid \'java\'\n' +
                        '}\n' +
                        '\n' +
                        'jar{\n' +
                        '\tarchiveFileName = "hello.jar"\n' +
                        '\tmanifest{\n' +
                        '\t\tattributes(\n' +
                        '\t\t\t\'Main-Class\':\'HelloMain\',\n' +
                        '\t\t\t\'Implementation-Title\':project.name,\n' +
                        '\t\t\t\'Developer\':\'KJ\'\n' +
                        '\t\t)\n' +
                        '\t}\n' +
                        '}\n' +
                        '\n' +
                        'java{\n' +
                        '\tsourceCompatibility = JavaVersion.VERSION_11\n' +
                        '\ttargetCompatibility = JavaVersion.VERSION_11\n' +
                        '}\n' +
                        '\n' +
                        'compileJava{\n' +
                        '\toptions.compilerArgs << \'-Werror\'\n' +
                        '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Setari structura proiect</b>
                    <br/>
                    <br/>
                    Gradle vine cu notiunea de <b>SourceSet</b>, care reprezinta o colectie de fisiere sursa.
                    In mod implicit exista 2 colectii:
                    <ul>
                        <li>main (src/main/java)</li>
                        <li>test (src/test/java)</li>
                    </ul>
                    Valorile default a acestor 2 colectii se pot modifica:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'sourceSets{\n' +
                        '\n' +
                        '\tmain{\n' +
                        '\t\tjava {\n' +
                        '\t\t\tsrcDir \'source/java\'\n' +
                        '\t\t}\n' +
                        '\t}\n' +
                        '\n' +
                        '\ttest{\n' +
                        '\t\n' +
                        '\t\tjava {\n' +
                        '\t\t\tsrcDirs =[\'source/test\',\'source/integration\']\n' +
                        '\t\t}\n' +
                        '\t\t\n' +
                        '\t}\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Pentru a schimba locatia unde se generaza clasele se foloseste configurarea: <b>destinationDirectory.set(file('out/bin'))</b>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'sourceSets{\n' +
                        '\n' +
                        '\tmain{\n' +
                        '\t\tjava{\n' +
                        '\t\t\tdestinationDirectory.set(file(\'out/bin\'))\n' +
                        '\t\t}\n' +
                        '\t}\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>6. Task compileTestJava</b>
                    <br/>
                    <br/>

                    Pentru a compila testele unui proiect Java se foloseste task-ul <b>compileTestJava</b>

                    <hr/>
                    <b>7. Task test</b>
                    <br/>
                    <br/>
                    Pentru rularea testelor dintr-un proiect Java se foloseste task-ul <b>test</b>.
                    <br/>
                    Pentru a functiona, mai e nevoie de configurarea task-ului in fisierul de build:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'test {\n' +
                        '   useJUnitPlatform()\n'+
                        '}'}
                    </SyntaxHighlighter>

                    Dupa rularea testelor, se genereaza un raport HTML in <b>build/reports</b>, dar si in format XML.

                    <br/>
                    <br/>

                    Pentru afisarea in consola a detaliilor legate de rularea testelor:

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'test {\n' +
                        '   useJUnitPlatform()\n'+
                        '   testLogging{\n'+
                        '       events "started", "skipped", "failed"\n'+
                        '   }\n'+
                        '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JavaPluginGradleContent;