import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class LedButtonArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-led-button", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LED + PushButton</b>
                    <br/>
                    <br/>

                    Un <b>PushButton</b> are in general 4 pini, marcati cu 1,2,3,4:
                    <ul>
                        <li>
                            pinii 1 si 3 sunt conectati
                        </li>
                        <li>
                            pinii 2 si 4 sunt conectati
                        </li>
                    </ul>


                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_1.png'}/>
                    </div>

                    Simplificat un circuit cu un LED care poate fi aprins prin aparea butonului, poate arata asa:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 650}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/led-button-2.png'}/>
                    </div>
                    <hr/>


                    Schema:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/led-button.png'}/>
                    </div>

                    Sau:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img.png'}/>
                    </div>

                    Avem:
                    <ul>
                        <li>
                            Placa Arduino Uno
                        </li>
                        <li>
                            LED Rosu
                        </li>
                        <li>
                            PushButton
                        </li>
                        <li>
                            Rezistor - 220 Ω
                        </li>
                        <li>
                            Rezistor - 10 kΩ
                        </li>
                    </ul>

                    Cod:
                    <SyntaxHighlighter>
                        {'// C++ code\n' +
                            '//\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  pinMode(13, OUTPUT);\n' +
                            '  pinMode(2, INPUT);\n' +
                            '  Serial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '  \n' +
                            '  int button = digitalRead(2);\n' +
                            '  Serial.println(button);\n' +
                            '  if (button ==HIGH ){\n' +
                            '    digitalWrite(13, HIGH);\n' +
                            '  }else{\n' +
                            '    digitalWrite(13, LOW);\n' +
                            '  }\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=yBgMJssXqHY&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=5&ab_channel=ScienceBuddies"}>
                                How to Use a Button with an Arduino (Lesson #5)
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"} href={"https://www.projecthub.in/how-to-use-a-push-button-with-arduino/"}>How To Use A Push Button With Arduino</a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LedButtonArduinoContent;