import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../maven/IndexContent";

class GenerateProjectsContent extends BaseContentPage  {

    constructor(props) {
        super(props, "maven-generate-projects", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Generare proiecte pe baza unui unei template</b>:
                    <br/>
                    <br/>

                    Pentru a genera, se utilizeaza urmatoarea comanda:
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn archetype:generate'}
                    </SyntaxHighlighter>
                    Se va afisa o lista cu toate templatele/arhetipurile pe care le putem folosi.
                    <br/>
                    Si va trebuie sa alegem una (de obicei ne sugereaza una):
                    <br/>
                    <i>Choose a number or apply filter (format: [groupId:]artifactId, case sensitive contains): 1947</i>
                    <br/>
                    ( unde <i> 1947: remote {"->"} org.apache.maven.archetypes:maven-archetype-quickstart (An archetype which contains a sample Maven project.)</i>).
                    <br/>
                    Apoi alegem:
                    <ul>
                        <li>
                            groupId: ro.maven.learn
                        </li>
                        <li>
                            artifactId: maven-project-learn
                        </li>
                        <li>
                            version: 1.0-SNAPSHOT
                        </li>
                        <li>
                            package: ro.maven.learn
                        </li>
                    </ul>

                    Se va genera un proiect simplu Maven (cu structura implicita).

                    <hr/>

                    Pentru a importa proiectul generat in eclipse
                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'mvn eclipse:eclipse'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GenerateProjectsContent;