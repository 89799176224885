import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class VariablesPythonContent extends BaseContentPage {

    constructor(props) {
        super(props, "python-variables", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Variabile</b>

                    <br/>
                    <br/>
                    Varabilele sunt ca niste recipiente care retin valori de diferite tipuri. De exemplu, o sticla poate contine lapte sau suc.
                    Din aceasta perspectiva, <i>sticla</i> este numele recipientului, iar continutul (<i>laptele, sucul</i>) poate fi vazut ca valoarea recipentului
                    (una e valoarea sau pretul laptelui, una este pretul sucului).
                    <br/>
                    Numele variabilelor in Python trebuie sa respecte niste reguli:
                    <ul>
                        <li>trebuie sa contina doar litere mici (a-z), litere mari (A-Z) si _ (underscore) - si nu doar litere/caractere latine (adica se poate folosi de exemplu: û)</li>
                        <li>trebuie sa inceapa cu o litera sau _ (underscore)</li>
                        <li>nu trebuie sa fie un nume rezervat (keyword):<br/>
                            <b>'False', 'None', 'True', 'and', 'as', 'assert', 'break', 'class', 'continue', 'def', 'del', 'elif', 'else', 'except', 'finally', 'for', 'from', 'global', 'if', 'import', 'in', 'is', 'lambda', 'nonlocal', 'not', 'or', 'pass', 'raise', 'return', 'try', 'while', 'with', 'yield'</b>
                        </li>
                    </ul>
                    Python este un <b>limbaj tipizat dinamic (dynamically-typed language)</b> (nu e nevoie de declarearea unui variabile; unei variabile <b>se asigneaza</b> o valoare):
                    <ul>
                        <li><b>nume_variabila = valoare_variabila</b></li>
                        <li><b>nume_variabila = expresie</b></li>
                    </ul>
                    <b>Observatie:</b>
                    <ul>
                        <li>nu este permisa folosirea unei variable care nu exista, nu i-a fost asignata o valoare</li>
                    </ul>

                    Numele variabilelor in Python sunt case-sensitive (de exemplu, variabile <i>rezultat</i> si <i>Rezultat</i> sunt vazute ca 2 variabile diferite).

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rezultat=72\n' +
                        'Rezultat=31\n' +
                        '_=1\n' +
                        'û=2\n' +
                        'print(rezultat)\n' +
                        'print(Rezultat)'}
                    </SyntaxHighlighter>
                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'72\n' +
                        '31\n' +
                        '1\n' +
                        '2'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Conventii de nume</b>:
                    <ul>
                        <li>numele de variabile ar fi bine sa inceapa contina doar litere mici, iar cuvintele sa fie despartite pentru liziblitate prin _ (underscore): cal, cal_de_lupta</li>
                        <li>numele de functii ar trebuie sa respecte acelasi conventii ca numele de variabile</li>
                        <li>se poate folosi si conventia ca in loc de _ sa se puna fiecare cuvant al variabilei cu litera mare, in afara de primul cuvant: cal, calDeLupta</li>
                    </ul>

                    Se poate asigna si o expresie. De exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'text1 =  "a_" + str(1)\n' +
                        'text2 = "a_" + "57"\n' +
                        'x = 2 + 7' +
                        'print(text1)\n' +
                        'print(text2)\n' +
                        'print(x)'}
                    </SyntaxHighlighter>

                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a_1\n' +
                        'a_57\n' +
                        '10'}
                    </SyntaxHighlighter>

                    <b>Observatie:</b>
                    <br/>
                    Concatenarea cu string-uri se face cu operatorul "+" (si toti operanzii <b>trebuie</b> sa fie de tip string, de aceea am convertit 1, folosind functie <b>str()</b> la string)

                    Exemplu 2:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'sarpe=300;\n' +
                        'eva=1;\n' +
                        'adam=0;\n' +
                        'total_mere = sarpe + eva + adam;\n' +
                        'print("Sarpe",sarpe,"/Eva",eva,"/Adam",adam, sep=\'=\')\n' +
                        'print("Numarul total de mere:",total_mere)'}
                    </SyntaxHighlighter>
                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Sarpe=300=/Eva=1=/Adam=0\n' +
                        'Numarul total de mere: 301'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Operatori shortcut</b> (sau <b>operatori de asignare compusi / compound assignment operators</b>):
                    <ul>
                        <li>nume_variabila = nume_variabila <b>operator</b> expresie</li>
                        este echivalent cu:
                        <li>nume_variabila <b>operator</b>= expresie</li>
                    </ul>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'y = 5\n' +
                        'y = y + 2 # y +=2\n' +
                        'print (y)\n' +
                        '\n' +
                        'x = 5\n' +
                        'x +=2 # x = x+ 2\n' +
                        'print (x)'}
                    </SyntaxHighlighter>

                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'7\n'+
                        '7'}
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a = 10\n' +
                        'b = 5\n' +
                        'a /= 2 * b\n' +
                        'print(a)'}
                    </SyntaxHighlighter>

                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'1.0'}
                    </SyntaxHighlighter>
                    Explicatie (<b>se evalueaza expresia din drepta intai</b>):
                    <ul>
                        <li>a /= 2 * b</li>
                        e echivalent cu
                        <li>a = a / (2 * b)</li>
                        <li>a = a / 10</li>
                        <li>a = 1.0</li>
                    </ul>

                    <hr/>

                    Daca vrem sa interchimbam doua variabile, in Python putem face in felul urmator:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a=1\n' +
                        'b=2\n' +
                        '\n' +
                        'a,b=b,a\n' +
                        '\n' +
                        'print(a,b)'}
                    </SyntaxHighlighter>
                    Rezultatul va fi:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'2 1'}
                    </SyntaxHighlighter>

                    De fapt, se poate scrie si asa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'a=1\n' +
                        'b=2\n' +
                        '\n' +
                        '(a,b)=(b,a)\n' +
                        '\n' +
                        'print(a,b)'}
                    </SyntaxHighlighter>

                    <b>Explicatie:</b>
                    <br/>
                    In exemplu de mai sus, s-a utilizat împachetarea tuplelor și despachetarea secvenței.
                    <br/>
                    <b>Tuplurile pot fi construite în mai multe moduri, dintre care unul este prin separarea elementelor tuplu folosind virgule.</b>
                    <br/>
                    Mai mult, <b>Python evaluează partea dreaptă a unei sarcini înainte de partea stângă.</b><br/>
                    Deci, prin separarea variabilelor cu virgule în partea dreaptă a declarației, variabilele sunt împachetate într-un tuplu și despachetate prin plasarea aceluiași număr de variabile țintă separate prin virgulă în partea stângă.
                    <br/>
                    Această metodă de schimbare și permutare a variabilelor poate fi utilizată pentru mai mult de două variabile, atâta timp cât același număr de variabile se află pe ambele părți ale declarației.


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>

                                Laviniu Aurelian Bădulescu, Limbajul Python - un curs practic, Editura Sitech, Craiova, 2020

                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VariablesPythonContent;