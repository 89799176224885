import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateRealmRolesKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-realm-roles", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                            <b>Creare roluri de realm</b>
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Roles</b> (meniul din stanga) si fiind pe tab-ul <b>Realm roles</b> se apasa pe butonul <b>Add Role</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-27.png'}/>
                                    </div>
                                </li>
                                <li>se completeaza formularul de adaugare rol si se apasa apoi butonul <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-28.png'}/>
                                    </div>
                                </li>
                                <li>dupa salvare, din tabul <b>Details</b> se activeaza (ON) <b>Composite Roles</b>, apoi
                                    din <b>Client Roles</b> se selecteaza <b>liferay-portal-client</b>, apoi se selecteaza rolul <b>administator</b> si se apasa butonul <b>Add selected</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-29.png'}/>
                                    </div>
                                </li>
                                <li>similar, vom face si rolul de realm <b>utilizator-realm</b>; ca in final in tabelul de <b>Realm Roles</b> sa existe si cele doua roluri de realm create mai sus: <b>administrator-realm</b> si <b>utilizator-realm</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-30.png'}/>
                                    </div>
                                </li>
                            </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateRealmRolesKeycloakContent;