import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../typescript/IndexContent";

class ClassesTypescriptContent extends BaseContentPage  {

    constructor(props) {
        super(props, "typescript-classes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Clase</b>
                    <br/>
                    <br/>

                    O clasa este creata folosind <b>class</b> si poate implementa o interfata:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'interface IConfig{\n' +
                            '    name: string;\n' +
                            '    isAdmin?: boolean\n' +
                            '    getStatus?: () =>void\n' +
                            '}\n' +
                            '\n' +
                            'class MyConfig implements IConfig{\n' +
                            '\n' +
                            '    // isAdmin: boolean;\n' +
                            '    // name: string;\n' +
                            '\n' +
                            '    isAdmin;\n' +
                            '    name;\n' +
                            '\n' +
                            '    getStatus(): void {\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Simplificat se poate scrie si:
                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'class MyConfig implements IConfig {\n' +
                            '\n' +
                            '    constructor(public isAdmin, public name) {\n' +
                            '    }\n' +
                            '\n' +
                            '    getStatus(): void {\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Unele metode pot fi private; pentru acest lucru se foloseste cuvantul cheie <b>private</b>; in mod implicit, metodele si proprietatile sunt publice.

                    <SyntaxHighlighter showLineNumbers={true} language="typescript" style={androidstudio}>
                        {'class MyConfig implements IConfig {\n' +
                            '\n' +
                            '    constructor(public isAdmin, public name) {\n' +
                            '    }\n' +
                            '\n' +
                            '    private setValue(): void {\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClassesTypescriptContent;