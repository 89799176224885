import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class IstorieVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-istorie", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1500 - Camera obscura</b> este o copie mecanică a ochiului. Camera obscura era formată dintr-o cutie paralelipipedică cu un interior de culoare neagră (globul ocular), cu o deschidere foarte mică.
                    <br/>
                    Principiile camerei obscure erau cunoscute încă din antichitate. In 1500, <i>Leonardo da Vinci</i> (1452-1519), descoperă și definește că formarea imaginii într-o cameră obscură se datorează în primul rând proprietății razelor de lumină de a se propaga în linie dreaptă și tocmai acestui fapt, imaginea este răsturnată.

                    <br/>
                    <br/>

                    <b>1644 - Lantena magica</b> este un aparat timpuriu de proiecție a imaginilor, care utilizează imagini pictate, desenate, fotografii și/sau diapozitive aplicate pe plăci transparente, de obicei din sticlă, utilizând una sau mai multe lentile și o sursă de lumină.

                    <br/>Prima proiectie, cu public platitor, e mentionata in fiind din anul 1644, prezentata de <i>Athanasius Kircher</i> (1601-1680), iezuit german.

                    <br/>
                    <br/>

                    <b>1806 - Camera lucida</b> este un dispozitiv optic folosit ca ajutor pentru desen de către artiști și microscopi. Camera lucida efectuează o suprapunere optică a subiectului vizionat pe suprafața pe care artistul desenează. Artistul vede atât scena, cât și suprafața desenului simultan, ca într-o <i>dublă expunere fotografică</i>.
                    <br/>
                    Camera lucida a fost brevetata în 1806 de către chimistul englez <i>William Hyde Wollaston</i> (1766-1828).

                    <br/>
                    <br/>

                    <b>1822 - Heliografie</b> este un procedeu fotografic inventat de Joseph Nicéphore Niépce (1765 – 1833).

                    <br/>
                    <br/>

                    <b>1825 - Taumatropul</b> este un disc cu cate o imagine pe fiecare parte a discului; discul are atașat două bucăți de sfoară, iar când cele doua bucati de sfoara sunt răsucite rapid între degete, cele două imagini par să se amestece într-una singură datorită <i>persistenței vederii</i>.
                    <br/>
                    Taumatropul a fost inventat de John Ayrton Paris (1785 - 1856).

                    <br/>
                    <br/>

                    <b>1827 - Vedere de la fereastra de la Le Gras</b> prima fotografie (realizata de Joseph Nicéphore Niépce), cu expunere intre 6 si 12 ore.

                    <br/>
                    <br/>

                    <b>1831 - Fantascop / <a href={"https://www.youtube.com/watch?v=UqwkdlwmHig&ab_channel=kleshaproduction"} target={"_blank"}>Phenakistiscop</a> </b> construit de Joseph Plateau ( 1801 – 1883), este primul aparat care a realizat sinteza unei miscari pornind de la imagini elementare succesive (primul instrument de animație care a creat o iluzie fluidă a mișcării)
                    <br/>
                    Aparatul consta dintr-un disc de carton avand pe circumferinta o serie de gauri si purtand figuri pictate pe fata interioara; rotindu-se in jurul centrului sau in fata unei oglinzi, privind prin deschizaturi, figurile vazute prin reflexie pe suprafata oglinzii, in loc sa se confunde, se anima executand miscari care le sunt proprii.

                    <br/>
                    <br/>
                    <b>1831 - <a href={"https://www.youtube.com/watch?v=CaNJFMfpcng&ab_channel=magicalmediamuseum"} target={"_blank"}>Stroboscop</a></b>, construit de Simon Ritter von Stamper (1792 - 1864), este un aparat similar cu Fantascopul.

                    <br/>
                    <br/>
                    <b>1835 - <a href={"https://www.youtube.com/watch?v=KeQrW9BA_T8&ab_channel=J%C3%BAliaS.F"}>Zootrop</a></b>, construit de William George Horner (1786 - 1837), este un aparat similat cu Fantoscopul, doar ca folosea un cilidru care se rotea; acesta era innegrit, deschis in partea de sus, inchis in partea de jos, iar pe la lateral fante echidistante

                    <br/>
                    <br/>

                    <b>1837 - Dagherotipul</b> este o placa de argint pe care se aplica un procedeu fotografic, derivat din heligrafie, inventat de Louis-Jacques-Mandé Daguerre (1787–1851);  Joseph Nicéphore Niépce si Daguerre au lucrat o perioada impreuna; Daguerre propune o solutie de iod, sensibila la lumina si fixabila cu o sare de mercur; timpul de expunere se reduce la 15-30 minute (de la 6-12 ore).

                    <br/>
                    <br/>
                    <b>1838 - <a href={"https://www.youtube.com/watch?v=AN34QUEayzQ&ab_channel=sibgrapi2011vf"} target={"_blank"}>Stereoscopul</a> </b> este un aparat optic care da iluzia reliefului datorita a doua imagini inregistrate sub un unghi usor diferit; a fost inventat de Sir Charles Wheatstone (1802 - 1875).

                    <br/>
                    <br/>
                    <b>1839 - Calotipul / Talbotype </b> este o tehnica fotografica, care permitea imprimarea pe o hartie fotosensibila, reducand timpul de expunere la 1 minut.
                    <br/>
                    Calotipia a fost inventata de <i>William Henry Fox Talbot</i> (1800 - 1877).

                    <br/>
                    <br/>
                    <b>1840 - Primul obiectiv fotografic</b> realizat de <i>Peter Wilhelm Friedrich von Voigtländer</i> (1812 - 1878)

                    <br/>
                    <br/>
                    <b>1841 - Primele portrete fotografice</b>, sunt realizata de Antoine Claudet (1797-1867), unul dintre primii fotografi comerciali ai Angliei;
                    <br/>
                    Fotografia de portret a fost posibila datorita reducerii timpului de expunere la un minut.

                    <br/>
                    <br/>
                    <b>1843 - Primul instantaneu fotografi</b> (Vue Prise du Pont Neuf a Paris), in 30 de secunde, facut de Macario.

                    <br/>
                    <br/>
                    <b>1847 - Imprimari fotografice pe sticla</b>, de Abel Niepce de Saint-Victor (1805 - 1870), nepotul lui Joseph Nicéphore Niépce.

                    <br/>
                    <br/>
                    <b>1849 - Quinetoscop</b>, de Achille Leon Quinet (1831 - 1900) - breveteaza o camera binoculara pentru a fotografia in steroscopie imaginile.

                    <br/>
                    <br/>
                    <b>1850 - Hyalotipul</b> brevetat de Frederic si William Langenheim, consta in scoaterea copiilor de pe placile de stila pentru a le proiecta luminos pe o suprafata clara.

                    <br/>
                    <br/>
                    <b>1852 - Polyorama</b> brevetat de Francois Sequin (1803 - 1872), este un proiector care amelioreaza imaginile animate.

                    <br/>
                    <br/>
                    <b>1853 - Kinestiscopul</b> inventat de Franz von Uchatius (1811- 1881), este un proiector pentru imaginile animate pe un ecran.

                    <br/>
                    <br/>
                    <b>1858 - Primele fotografi aeriene dintr-un balon</b> de Felix Tournachon, zis Nadar (1820-1910)

                    <br/>
                    <br/>
                    <b>1870 - Phasmatop</b> inventat de Henry Renno Heyl (1842 - 1919), este un proiector pentru imaginile animate pe un ecran (varianta imbunatatita a Kinestiscopului - 1853)

                    <br/>
                    <br/>
                    <b>1876 - Praxinoscopul</b> inventat de Emile Reynaud (1844 - 1918), este un aparat de proiectie (avand la baza Fantascopul - 1831).

                    <br/>
                    <br/>
                    <b>1880 - Lentile anamorfotice</b> de Ernst Abbe (1840 - 1905), fondatorul firmei Zeiss din Jena.

                    <br/>
                    <br/>
                    <b>1882 - Lamposcopul</b> inventat de Emile Reynaud (1844 - 1918), este un aparat de proiectie.

                    <br/>
                    <br/>
                    <b>1884 - Hartie fotografica pentru camera Kodak</b> - inventat de George Eastman (1854 - 1932).

                    <br/>
                    <br/>
                    <b>1888 - Aparatul Kodak</b> e lansat de George Eastman, cu slogatul: "Apasati pe buton, noi facem restul"

                    <br/>
                    <br/>
                    <b>1888 - Cronophotograful cu banda mobila</b> este un aparat de filmat cu obiectiv multiplu

                    <br/>
                    <br/>
                    <b>1888 - Filmul pe pelicula cu dubla perforatie laterala</b> inventat de Thomas Alva Edison.

                    <br/>
                    <br/>
                    <b>1889 - Tachyscopul</b> inventat de Ottomar Anschutz, este un aparat electric care da posibilitatea sa se vada o serie de imagini succesive

                    <br/>
                    <br/>
                    <b>1892 - Cinematograf</b> brevetat de Leon Bouly, era un aparat cinematofradic instantaneu

                    <br/>
                    <br/>
                    <b>1893 - Prima fotografie submarina</b>  de Louis Bontan

                    <br/>
                    <br/>
                    <b>1894 - Filmul La Sortie des Usines Lumiere</b>  de Louis Lumiere

                    <br/>
                    <br/>
                    <b>1894 - Cinematograf</b> aparat brevetat de Anoine Lumiere si fiii sai - Auguste si Louis; (fara sa stie ce Cinematograful lui Leon Bouly)
                    <br/>acest aparat realiza 50 de cadre pe secunde

                    <br/>
                    <br/>
                    <b>1894 - Biograf</b> este o camera-aparat de inregistrare, brevetata de R.W. Paul

                    <br/>
                    <br/>
                    <b>1897 - Efecte speciale</b> inventate de Georges Melies.

                    <br/>
                    <br/>
                    <b>1897 - Cineorama</b> lansat de Grimoin-Sanson, este un proiector panoramic

                    <br/>
                    <br/>
                    <b>1900 - Inregistrai sonore pe pelicula</b> de Duddell si Ruhmer

                    <br/>
                    <br/>
                    <b>1904 - Filmul sonor</b> prezentat de Oscar Messter

                    <br/>
                    <br/>
                    <b>1904 - Pathecolor</b> formula colorii filmelor alb/negru

                    <br/>
                    <br/>
                    <b>1906 - Masina de inregistrare simultatana a imaginilor si sunetului</b> prezentata de Eugene-Augustin Lauste (1857-1935)

                    <br/>
                    <br/>
                    <b>1908 - Filmul gofrat</b>: film in culori

                    <br/>
                    <br/>
                    <b>1908 - Vivafonul</b> aparat de proiectie pentru sincronizarea sunet-imagine, prezentat de Cecil Hepworth

                    <br/>
                    <br/>
                    <b>1909 - Primul film color</b>, prezentat de R. Berthon

                    <br/>
                    <br/>
                    <b>1909 - Camera Parvo</b>

                    <br/>
                    <br/>
                    <b>1909 - Aparatul Pathe</b>

                    <br/>
                    <br/>
                    <b>1911 - Film panoramic</b> brevetat de Filoteo Albetini, pe pelicul a de 70mm

                    <br/>
                    <br/>
                    <b>1922 - Pelicula de 16mm</b>

                    <br/>
                    <br/>
                    <b>1925 - 24fps</b> - viteza de proiectie a filmelor sonore este fixata de Thedore Case.

                    <br/>
                    <br/>
                    <b>1926 - Film de 35mm neinflamabil</b> - lansat de firma Pathe-Cinema

                    <br/>
                    <br/>
                    <b>1934 - Primul film cinematografic subacvatic</b> - realizat de Le Prieur

                    <br/>
                    <br/>
                    <b>1935 - Kodachrome </b> - film negativ multistrat

                    <br/>
                    <br/>
                    <b>1943 - Kodacolor </b>

                    <br/>
                    <br/>
                    <b>1948 - Filmfonographul </b>: aparat care poate inregistra si sa reproduca sunetul pe benzi magnetice de 35mm

                    <br/>
                    <br/>
                    <b>1950 - Eastman color </b>

                    <br/>
                    <br/>
                    <b>1956 - Videorecorderul </b>

                    <br/>
                    <br/>
                    <b>1956 - Videomagnetofonul </b>

                    <br/>
                    <br/>
                    <b>1957 - Prima animatie asistata de calculator </b>

                    <br/>
                    <br/>
                    <b>1961 - Transmie TV via satelit </b>

                    <br/>
                    <br/>
                    <b>1980 - CD (Compact Disc) </b> : cd audio


                    <br/>
                    <br/>
                    <b>1984 - CD-ROM </b>

                    <br/>
                    <br/>
                    <b>1991 - JPEG </b> : standar pentru compresia imaginilor statice
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://www.youtube.com/watch?v=KeQrW9BA_T8&ab_channel=J%C3%BAliaS.F"}>Zootrop</a>
                        </li>
                        <li>
                            <a href={"https://www.youtube.com/watch?v=2rzwdRqsuVM&ab_channel=Howcast"}>How to Create a Phenakistoscope</a>
                        </li>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=UqwkdlwmHig&ab_channel=kleshaproduction"}>PHENAKISTOSCOPE - Tribute to Joseph Plateau</a>
                            </div>
                        </li>
                       <li>
                           <div>
                               <a href={"https://ro.wikipedia.org/wiki/Lantern%C4%83_magic%C4%83"}>Lanternă magică</a>
                           </div>
                       </li>
                       <li>
                           <div>
                               <a href={"https://www.youtube.com/watch?v=aM5oNJZdqDk&ab_channel=mkmuseum"}>The Mysteriouse Magic Lantern</a><br/>
                           </div>
                       </li>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=w1XkqtzLfKo&ab_channel=NationalFilmandSoundArchiveofAustralia%28NFSA%29"}>Lanterna Magica: A Pageant of Illusions</a>
                            </div>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IstorieVideografieContent;