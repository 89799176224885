import {useRoutes} from "react-router-dom";
import React from "react";
import JavaHomeContent from "./IndexContent";
import LambdasJavaContent from "./LambdasJavaContent";
import CommentsJavaContent from "./CommentsJavaContent";
import PrimitiveTypesJavaContent from "./PrimitiveTypesJavaContent";
import VarJavaContent from "./VarJavaContent";
import IdentifiersJavaContent from "./IdentifiersJavaContent";
import NumericPromotionRulesJavaContent from "./NumericPromotionRulesJavaContent";
import OperatorsJavaContent from "./OperatorsJavaContent";
import DecisionsJavaContent from "./DecisionsJavaContent";
import StringsJavaContent from "./StringsJavaContent";
import StringBuilderJavaContent from "./StringBuilderJavaContent";
import StringPoolJavaContent from "./StringPoolJavaContent";
import ArraysJavaContent from "./ArraysJavaContent";
import ArrayListJavaContent from "./ArrayListJavaContent";
import SetsJavaContent from "./SetsJavaContent";
import MapsJavaContent from "./MapsJavaContent";
import MathJavaContent from "./MathJavaContent";
import ModulesJavaContent from "./ModulesJavaContent";
import MethodsJavaContent from "./MethodsJavaContent";
import ClassesJavaContent from "./ClassesJavaContent";
import UnnamedClassesJavaContent from "./UnnamedClassesJavaContent";
import ScriptingJavaContent from "./ScriptingJavaContent";
import JavaPJavaContent from "./JavaPJavaContent";
import ByteCodeJavaContent from "./ByteCodeJavaContent";
import JcmdJavaContent from "./JcmdJavaContent";
import DebugJavaContent from "./DebugJavaContent";
import ProfilerVisualVMJavaContent from "./ProfilerVisualVMJavaContent";
import ProfilerJProfilerJavaContent from "./ProfilerJProfilerJavaContent";
import ThreadDumpJavaContent from "./ThreadDumpJavaContent";
import HeadDumpJavaContent from "./HeadDumpJavaContent";
import AbstractVsInterfacesJavaContent from "./AbstractVsInterfacesJavaContent";
import FTPClientJavaContent from "./FTPClientJavaContent";
/*
<Route path="/java/index" element={<JavaHomeContent/>} exact/>
<Route path="/java/lambdas" element={<LambdasJavaContent/>} exact/>
<Route path="/java/comments" element={<CommentsJavaContent/>} exact/>
<Route path="/java/primitive-types" element={<PrimitiveTypesJavaContent/>} exact/>
<Route path="/java/var" element={<VarJavaContent/>} exact/>
<Route path="/java/identifiers" element={<IdentifiersJavaContent/>} exact/>
<Route path="/java/numeric-promotion-rules" element={<NumericPromotionRulesJavaContent/>} exact/>
<Route path="/java/operators" element={<OperatorsJavaContent/>} exact/>
<Route path="/java/decisions" element={<DecisionsJavaContent/>} exact/>
<Route path="/java/strings" element={<StringsJavaContent/>} exact/>
<Route path="/java/stringbuilder" element={<StringBuilderJavaContent/>} exact/>
<Route path="/java/string-pool" element={<StringPoolJavaContent/>} exact/>
<Route path="/java/arrays" element={<ArraysJavaContent/>} exact/>
<Route path="/java/arraylist" element={<ArrayListJavaContent/>} exact/>
<Route path="/java/sets" element={<SetsJavaContent/>} exact/>
<Route path="/java/maps" element={<MapsJavaContent/>} exact/>
<Route path="/java/math" element={<MathJavaContent/>} exact/>
<Route path="/java/modules" element={<ModulesJavaContent/>} exact/>
<Route path="/java/methods" element={<MethodsJavaContent/>} exact/>
<Route path="/java/classes" element={<ClassesJavaContent/>} exact/>
*/
export default function Router() {
    let element = useRoutes([
        {path: "/java/index", element:<JavaHomeContent/>},
        {path: "/java/lambdas", element:<LambdasJavaContent/> },
        {path: "/java/comments", element:<CommentsJavaContent/> },
        {path: "/java/primitive-types", element:<PrimitiveTypesJavaContent/> },
        {path: "/java/var", element:<VarJavaContent/> },
        {path: "/java/identifiers", element:<IdentifiersJavaContent/> },
        {path: "/java/numeric-promotion-rules", element:<NumericPromotionRulesJavaContent/> },
        {path: "/java/operators", element:<OperatorsJavaContent/> },
        {path: "/java/decisions", element:<DecisionsJavaContent/> },
        {path: "/java/strings", element:<StringsJavaContent/> },
        {path: "/java/stringbuilder", element:<StringBuilderJavaContent/> },
        {path: "/java/string-pool", element:<StringPoolJavaContent/> },
        {path: "/java/arrays", element:<ArraysJavaContent/> },
        {path: "/java/arraylist", element:<ArrayListJavaContent/> },
        {path: "/java/sets", element:<SetsJavaContent/> },
        {path: "/java/maps", element:<MapsJavaContent/> },
        {path: "/java/math", element:<MathJavaContent/> },
        {path: "/java/modules", element:<ModulesJavaContent/> },
        {path: "/java/methods", element:<MethodsJavaContent/> },
        {path: "/java/classes", element:<ClassesJavaContent/> },

        {path: "/java/unnamed-classes", element:<UnnamedClassesJavaContent/> },
        {path: "/java/scripting", element:<ScriptingJavaContent/> },
        {path: "/java/javap", element:<JavaPJavaContent/> },
        {path: "/java/jcmd", element:<JcmdJavaContent/> },
        {path: "/java/bytecode", element:<ByteCodeJavaContent/> },
        {path: "/java/debug", element:<DebugJavaContent/> },
        {path: "/java/profiler-visual-vm", element:<ProfilerVisualVMJavaContent/> },
        {path: "/java/profiler-jprofiler", element:<ProfilerJProfilerJavaContent/> },
        {path: "/java/thread-dump", element:<ThreadDumpJavaContent/> },
        {path: "/java/head-dump", element:<HeadDumpJavaContent/> },

        {path: "/java/abstract-vs-interfaces", element: <AbstractVsInterfacesJavaContent/>},

        {path: "/java/ftp-client", element: <FTPClientJavaContent/>}
    ]);

    return element;
}