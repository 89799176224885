import './App.css';
import Navbar from "./common/Navbar";
import {Route, Routes} from 'react-router-dom';
import SiteHomeContent from "./page/IndexContent";
import AnnHomeContent from "./page/ann/IndexContent";
import NuxeoHomeContent from "./page/nuxeo/IndexContent";
import JavaCryptographyHomeContent from "./page/java-cryptography/IndexContent";
import DesignPatternsHomeContent from "./page/design-patterns/IndexContent"
import KeycloakHomeContent from "./page/keycloak/IndexContent"
import JavaSpringBootHomeContent from "./page/java-spring/IndexContent"
import PythonHomeContent from "./page/python/IndexContent"
import PythonIIHomeContent from "./page/python-ii/IndexContent"
import PythonGUIHomeContent from "./page/python-gui/IndexContent"
import CompilerHomeContent from "./page/compiler/IndexContent"
import GitHomeContent from "./page/git/IndexContent"
import GradleHomeContent from "./page/gradle/IndexContent"
import ElasticsearchHomeContent from "./page/elasticsearch/IndexContent"
import TomcatHomeContent from "./page/tomcat/IndexContent";
import CvHomeContent from "./page/cv/IndexContent";
import MavenHomeContent from "./page/maven/IndexContent";
import NasHomeContent from "./page/nas/IndexContent";
import NginxHomeContent from "./page/nginx/IndexContent";
import LaravelHomeContent from './page/laravel/IndexContent'
import NextHomeContent from './page/next/IndexContent'

import PerceptronContent from "./page/ann/PerceptronContent";
import HandwrittenContent from "./page/ann/HandwrittenContent";
import AccessDeniedContent from "./page/AccessDeniedContent";
import HopfieldContent from "./page/ann/HopfieldContent";
import KohonenContent from "./page/ann/KohonenContent";
import BoltzmannContent from "./page/ann/BoltzmannContent";
import AnnContent from "./page/ann/AnnContent";
import ArtContent from "./page/ann/ArtContent";
import McCullochPittsContent from "./page/ann/McCullochPittsContent";
import PerceptronTheoryContent from "./page/ann/PerceptronTheoryContent";
import LearningRuleContent from "./page/ann/LearningRuleContent";
import AdalineContent from "./page/ann/AdalineContent";
import Art1Content from "./page/ann/Art1Content";
import Art1Ex1Content from "./page/ann/Art1Ex1Content";
import {Helmet} from "react-helmet";
import BackpropagationContent from "./page/ann/BackpropagationContent";
import Anexa1ProbabilityContent from "./page/ann/Anexa1ProbabilityContent";
import Anexa2MarkovContent from "./page/ann/Anexa2MarkovContent";
import Articol1Content from "./page/ann/Articol1Content";
import RadialBasisFunctionContent from "./page/ann/RadialBasisFunctionContent";
import Anexa3MonteCarloContent from "./page/ann/Anexa3MonteCarloContent";
import Anexa4GenericAlgorithms from "./page/ann/Anexa4GenericAlgorithms";
import GansContent from "./page/ann/GansContent";
import CnnContent from "./page/ann/CnnContent";
import NecognitronContent from "./page/ann/NecognitronContent";
import CnnIntroContent from "./page/ann/CnnIntroContent";
import AnexaGibbsSamplingContent from "./page/ann/AnexaGibbsSamplingContent";
import ProbabilityHiddenContent from "./page/ann/ProbabilityHiddenContent";
import InstallNuxeoContent from "./page/nuxeo/InstallNuxeoContent";
import ConfigNuxeoContent from "./page/nuxeo/ConfigNuxeoContent";
import AnexaSoftmaxContent from "./page/ann/AnexaSoftmaxContent";
import LoginPage from "./page/login/LoginPage";

import JavaCryptographyEx01Content from "./page/java-cryptography/JavaCryptographyEx01Content";
import JavaCryptographyEx02Content from "./page/java-cryptography/JavaCryptographyEx02Content";
import JavaCryptographyEx03Content from "./page/java-cryptography/JavaCryptographyEx03Content";
import React from "react";
import SimpleFactoryContent from "./page/design-patterns/SimpleFactoryContent";
import KeycloakIntegrationNuxeoContent from "./page/nuxeo/KeycloakIntegrationNuxeoContent";
import BuildNuxeoVersionContent from "./page/nuxeo/BuildNuxeoVersionContent";
import InstallKeycloakContent from "./page/keycloak/InstallKeycloakContent";
import CreateUserAdminKeycloakContent from "./page/keycloak/CreateUserAdminKeycloakContent";
import CreateRealmKeycloakContent from "./page/keycloak/CreateRealmKeycloakContent";
import CreateUserKeycloakContent from "./page/keycloak/CreateUserKeycloakContent";
import CreateClientKeycloakContent from "./page/keycloak/CreateClientKeycloakContent";
import CreateClientRolesKeycloakContent from "./page/keycloak/CreateClientRolesKeycloakContent";
import AssignClientRolesToUserKeycloakContent from "./page/keycloak/AssignClientRolesToUserKeycloakContent";
import CreateRealmRolesKeycloakContent from "./page/keycloak/CreateRealmRolesKeycloakContent";
import AssignRealmRolesToUserKeycloakContent from "./page/keycloak/AssignRealmRolesToUserKeycloakContent";
import ConfigIdentityProviderKeycloakContent from "./page/keycloak/ConfigIdentityProviderKeycloakContent";
import GenerateTokenKeycloakContent from "./page/keycloak/GenerateTokenKeycloakContent";
import CreateClientScopesKeycloakContent from "./page/keycloak/CreateClientScopesKeycloakContent";
import AssignClientScopesToClientKeycloakContent from "./page/keycloak/AssignClientScopesToClientKeycloakContent";
import ClientScopesMappersKeycloakContent from "./page/keycloak/ClientScopesMappersKeycloakContent";
import ConditionalOnPropertyAnnotationContent from "./page/java-spring/ConditionalOnPropertyAnnotationContent";
import CommentPythonContent from "./page/python/CommentPythonContent";
import InstallPythonContent from "./page/python/InstallPythonContent";
import OverviewPythonContent from "./page/python/OverviewPythonContent";
import PrintFunctionPythonContent from "./page/python/PrintFunctionPythonContent";
import LiteralsPythonContent from "./page/python/LiteralsPythonContent";
import OperatorsPythonContent from "./page/python/OperatorsPythonContent";
import VariablesPythonContent from "./page/python/VariablesPythonContent";
import InputFunctionPythonContent from "./page/python/InputFunctionPythonContent";
import StringPythonContent from "./page/python/StringPythonContent";
import RelationalAndLogicOperatorsPythonContent from "./page/python/RelationalAndLogicOperatorsPythonContent";
import IfPythonContent from "./page/python/IfPythonContent";
import WhilePythonContent from "./page/python/WhilePythonContent";
import ForPythonContent from "./page/python/ForPythonContent";
import BitsOperatorsPythonContent from "./page/python/BitsOperatorsPythonContent";
import ListPythonContent from "./page/python/ListPythonContent";
import FunctionsPythonContent from "./page/python/FunctionsPythonContent";
import ExceptionsPythonContent from "./page/python/ExceptionsPythonContent";
import TupleAndDictionariesPythonContent from "./page/python/TupleAndDictionariesPythonContent";
import ModulesPythonContent from "./page/python/ModulesPythonContent";
import PackagesPythonContent from "./page/python/PackagesPythonContent";
import PipPythonContent from "./page/python/PipPythonContent";
import StringIIPythonContent from "./page/python/StringIIPythonContent";
import ClassesPythonContent from "./page/python/ClassesPythonContent";
import InheritancePythonContent from "./page/python/InheritancePythonContent";
import MroPythonContent from "./page/python/MroPythonContent";
import ExceptionsIIPythonContent from "./page/python/ExceptionsIIPythonContent";
import GeneratorsPythonContent from "./page/python/GeneratorsPythonContent";
import ClosuresPythonContent from "./page/python/ClosuresPythonContent";
import LambdasPythonContent from "./page/python/LambdasPythonContent";
import FileStreamsPythonContent from "./page/python/FileStreamsPythonContent";
import ProcessingFilesPythonContent from "./page/python/ProcessingFilesPythonContent";
import OsModulePythonContent from "./page/python/OsModulePythonContent";
import DatetimeModulePythonContent from "./page/python/DatetimeModulePythonContent";
import TimeModulePythonContent from "./page/python/TimeModulePythonContent";
import CalendarModulePythonContent from "./page/python/CalendarModulePythonContent";
import OopIntroductionPythonContent from "./page/python-ii/OopIntroductionPythonContent";
import OopVariablesPythonContent from "./page/python-ii/OopVariablesPythonContent";
import OopCoreSyntaxPythonContent from "./page/python-ii/OopCoreSyntaxPythonContent";
import OopInheritancePolymorphismPythonContent from "./page/python-ii/OopInheritancePolymorphismPythonContent";
import ArgsPythonContent from "./page/python-ii/ArgsPythonContent";
import DecoratorsPythonContent from "./page/python-ii/DecoratorsPythonContent";
import DecoratorsIIPythonContent from "./page/python-ii/DecoratorsIIPythonContent";
import DecoratorsIIIPythonContent from "./page/python-ii/DecoratorsIIIPythonContent";
import StaticClassMethodPythonContent from "./page/python-ii/StaticClassMethodPythonContent";
import AbstractClassPythonContent from "./page/python-ii/AbstractClassPythonContent";
import OopEncapsulationPythonContent from "./page/python-ii/OopEncapsulationPythonContent";
import ExceptionAdvancedPythonContent from "./page/python-ii/ExceptionAdvancedPythonContent";
import ObjectPersistencePythonContent from "./page/python-ii/ObjectPersistencePythonContent";
import SerializationPythonContent from "./page/python-ii/SerializationPythonContent";
import WithPythonContent from "./page/python-ii/WithPythonContent";
import MetaprogrammingPythonContent from "./page/python-ii/MetaprogrammingPythonContent";
import PepPythonContent from "./page/python-ii/PepPythonContent";
import Pep8PythonContent from "./page/python-ii/Pep8PythonContent";
import Pep257PythonContent from "./page/python-ii/Pep257PythonContent";
import SpringContextContent from "./page/java-spring/SpringContextContent";
import WiringBeansContent from "./page/java-spring/WiringBeansContent";
import StereotypesSpringContent from "./page/java-spring/StereotypesSpringContent";
import ScopesSpringContent from "./page/java-spring/ScopesSpringContent";
import IntroductionPythonGUIContent from "./page/python-gui/IntroductionPythonGUIContent";
import TkInterPythonGUIContent from "./page/python-gui/TkInterPythonGUIContent";
import AopSpringContent from "./page/java-spring/AopSpringContent";
import LexicalAnalysisContent from "./page/compiler/LexicalAnalysisContent";
import SpringBootContent from "./page/java-spring/SpringBootContent";
import SpringMVCContent from "./page/java-spring/SpringMVCContent";
import WebScopesSpringContent from "./page/java-spring/WebScopesSpringContent";
import IoCSpringContent from "./page/java-spring/IoCSpringContent";
import TranslatorsContent from "./page/compiler/TranslatorsContent";
import OverviewGitContent from "./page/git/OverviewGitContent";
import IntroGradleContent from "./page/gradle/IntroGradleContent";
import ProjectsGradleContent from "./page/gradle/ProjectsGradleContent";
import TasksGradleContent from "./page/gradle/TasksGradleContent";
import PluginsGradleContent from "./page/gradle/PluginsGradleContent";
import JavaPluginGradleContent from "./page/gradle/JavaPluginGradleContent";
import DependencyManagementGradleContent from "./page/gradle/DependencyManagementGradleContent";
import JavaApplicationPluginGradleContent from "./page/gradle/JavaApplicationPluginGradleContent";
import WarPluginGradleContent from "./page/gradle/WarPluginGradleContent";
import WrapperGradleContent from "./page/gradle/WrapperGradleContent";
import CustomPluginGradleContent from "./page/gradle/CustomPluginGradleContent";
import GradleInitGradleContent from "./page/gradle/GradleInitGradleContent";
import DaemonGradleContent from "./page/gradle/DaemonGradleContent";
import GroovyGradleContent from "./page/gradle/GroovyGradleContent";
import ProjectObjectGradleContent from "./page/gradle/ProjectObjectGradleContent";
import LifecycleGradleContent from "./page/gradle/LifecycleGradleContent";
import PropertiesFileGradleContent from "./page/gradle/PropertiesFileGradleContent";
import OtherPluginGradleContent from "./page/gradle/OtherPluginGradleContent";
import MultiProjectsGradleContent from "./page/gradle/MultiProjectsGradleContent";
import JenkinsGradleContent from "./page/gradle/JenkinsGradleContent";
import SpringBootGradleContent from "./page/gradle/SpringBootGradleContent";
import CreateRepositoryGitContent from "./page/git/CreateRepositoryGitContent";
import StatusGitContent from "./page/git/StatusGitContent";
import AddGitContent from "./page/git/AddGitContent";
import CommitGitContent from "./page/git/CommitGitContent";
import DiffGitContent from "./page/git/DiffGitContent";
import LogGitContent from "./page/git/LogGitContent";
import ShowGitContent from "./page/git/ShowGitContent";
import CreateUserAttributesKeycloakContent from "./page/keycloak/CreateUserAttributesKeycloakContent";
import IgnoreGitContent from "./page/git/IgnoreGitContent";
import RemoteRepositoryGitContent from "./page/git/RemoteRepositoryGitContent";
import BranchGitContent from "./page/git/BranchGitContent";
import CloneGitContent from "./page/git/CloneGitContent";
import NXQLNuxeoContent from "./page/nuxeo/NXQLNuxeoContent";
import CoreSessionNuxeoContent from "./page/nuxeo/CoreSessionNuxeoContent";
import AdobeCCConnectorNuxeoContent from "./page/nuxeo/AdobeCCConnectorNuxeoContent";
import GoogleStorageNuxeoContent from "./page/nuxeo/GoogleStorageNuxeoContent";
import Tips1NuxeoContent from "./page/nuxeo/Tips1NuxeoContent";
import SecurityNuxeoContent from "./page/nuxeo/SecurityNuxeoContent";
import TwoFactorAuthenticationNuxeoContent from "./page/nuxeo/TwoFactorAuthenticationNuxeoContent";
import NuxeoCLINuxeoContent from "./page/nuxeo/NuxeoCLINuxeoContent";
import ServiceNuxeoContent from "./page/nuxeo/ServiceNuxeoContent";
import UnitTestingNuxeoContent from "./page/nuxeo/UnitTestingNuxeoContent";
import DocumentsNuxeoContent from "./page/nuxeo/DocumentsNuxeoContent";
import ServiceExtensionPointsNuxeoContent from "./page/nuxeo/ServiceExtensionPointsNuxeoContent";
import DirectoryEntriesToCustomObjectNuxeoContent from "./page/nuxeo/DirectoryEntriesToCustomObjectNuxeoContent";
import InstallElasticsearchContent from "./page/elasticsearch/InstallElasticsearchContent";
import IntroElasticsearchContent from "./page/elasticsearch/IntroElasticsearchContent";
import ConnectingClusterElasticsearchContent from "./page/elasticsearch/ConnectingClusterElasticsearchContent";
import MappingElasticsearchContent from "./page/elasticsearch/MappingElasticsearchContent";
import InsertElasticsearchContent from "./page/elasticsearch/InsertElasticsearchContent";
import IntroTomcatContent from "./page/tomcat/IntroTomcatContent";
import CertificatesCVContent from "./page/cv/CertificatesCVContent";
import SSLTomcatContent from "./page/tomcat/SSLTomcatContent";
import VirtualHostsTomcatContent from "./page/tomcat/VirtualHostsTomcatContent";
import ClusteringTomcatContent from "./page/tomcat/ClusteringTomcatContent";
import IntroMavenContent from "./page/maven/IntroMavenContent";
import LoadbancingNginxTomcatContent from "./page/tomcat/LoadbancingNginxTomcatContent";
import LoadbancingApacheTomcatContent from "./page/tomcat/LoadbancingApacheTomcatContent";
import ErrorPageTomcatContent from "./page/tomcat/ErrorPageTomcatContent";
import LogsTomcatContent from "./page/tomcat/LogsTomcatContent";
import LoadbancingSessionTomcatContent from "./page/tomcat/LoadbancingSessionTomcatContent";
import IssuesNasContent from "./page/nas/IssuesNasContent";
import IntroNasContent from "./page/nas/IntroNasContent";
import JndiTomcatContent from "./page/tomcat/JndiTomcatContent";
import ValvesTomcatContent from "./page/tomcat/ValvesTomcatContent";
import RealmsTomcatContent from "./page/tomcat/RealmsTomcatContent";
import SSHNasContent from "./page/nas/SSHNasContent";
import RestoreVMMNasContent from "./page/nas/RestoreVMMNasContent";
import OptimizationTomcatContent from "./page/tomcat/OptimizationTomcatContent";
import TipsTricksTomcatContent from "./page/tomcat/TipsTricksTomcatContent";
import InheritanceMavenContent from "./page/maven/InheritanceMavenContent";
import ProfilesMavenContent from "./page/maven/ProfilesMavenContent";
import GenerateProjectsContent from "./page/maven/GenerateProjectsContent";
import DependencyManagementMavenContent from "./page/maven/DependencyManagementMavenContent";
import LifecyclePluginsMavenContent from "./page/maven/LifecyclePluginsMavenContent";
import PluginsMavenContent from "./page/maven/PluginsMavenContent";
import CustomPluginMavenContent from "./page/maven/CustomPluginMavenContent";
import BuildArchetypeMavenContent from "./page/maven/BuildArchetypeMavenContent";
import MultiModuleMavenContent from "./page/maven/MultiModuleMavenContent";
import DeployWebApplicationsMavenContent from "./page/maven/DeployWebApplicationsMavenContent";
import M2EclipsePluginMavenContent from "./page/maven/M2EclipsePluginMavenContent";
import AccessRouterNasContent from "./page/nas/AccessRouterNasContent";
import InstallNginxContent from "./page/nginx/InstallNginxContent";
import ArpProtocolNasContent from "./page/nas/ArpProtocolNasContent";
import VirtualHostNginxContent from "./page/nginx/VirtualHostNginxContent";
import RouterNasContent from "./page/nas/RouterNasContent";
import SshVmNasContent from "./page/nas/SshVmNasContent";
import SpeedTestNasContent from "./page/nas/SpeedTestNasContent";
import LinksNasContent from "./page/nas/LinksNasContent";
import SynologyMailServerNasContent from "./page/nas/SynologyMailServerNasContent";
import WanLanIsolateNasContent from "./page/nas/WanLanIsolateNasContent";
import PortForwardNasContent from "./page/nas/PortForwardNasContent";
import LoggingNginxContent from "./page/nginx/LoggingNginxContent";
import TryFilesNamedLocationsNginxContent from "./page/nginx/TryFilesNamedLocationsNginxContent";
import RewritesRedirectsNginxContent from "./page/nginx/RewritesRedirectsNginxContent";
import VariablesNginxContent from "./page/nginx/VariablesNginxContent";
import LocationBlocksNginxContent from "./page/nginx/LocationBlocksNginxContent";
import InheritinceDirectiveTypesNginxContent from "./page/nginx/InheritinceDirectiveTypesNginxContent";
import PhpNginxContent from "./page/nginx/PhpNginxContent";
import WorkerProcessesNginxContent from "./page/nginx/WorkerProcessesNginxContent";
import BuffersTimeoutsNginxContent from "./page/nginx/BuffersTimeoutsNginxContent";
import AddingDynamicModulesNginxContent from "./page/nginx/AddingDynamicModulesNginxContent";
import Http2NginxContent from "./page/nginx/Http2NginxContent";
import ServerPushNginxContent from "./page/nginx/ServerPushNginxContent";
import HeadersExpiresNginxContent from "./page/nginx/HeadersExpiresNginxContent";
import CompressedResponsesNginxContent from "./page/nginx/CompressedResponsesNginxContent";
import FastCGICacheNginxContent from "./page/nginx/FastCGICacheNginxContent";
import HttpsNginxContent from "./page/nginx/HttpsNginxContent";
import RateLimitingNginxContent from "./page/nginx/RateLimitingNginxContent";
import BasicAuthNginxContent from "./page/nginx/BasicAuthNginxContent";
import HardeningNginxContent from "./page/nginx/HardeningNginxContent";
import SSLCertificatesNginxContent from "./page/nginx/SSLCertificatesNginxContent";
import ReverseProxyNginxContent from "./page/nginx/ReverseProxyNginxContent";
import LoadBalancerNginxContent from "./page/nginx/LoadBalancerNginxContent";
import LinksNginxContent from "./page/nginx/LinksNginxContent";
import GitServerNasContent from "./page/nas/GitServerNasContent";
import InitFromExistingProjectGitContent from "./page/git/InitFromExistingProjectGitContent";
import LocalRepoToRemoteRepoContent from "./page/git/LocalRepoToRemoteRepoContent";
import MultipleRemoteRepositoryGitContent from "./page/git/MultipleRemoteRepositoryGitContent";
import GitServerSSHAccessNasContent from "./page/nas/GitServerSSHAccessNasContent";
import NamecheapNasContent from "./page/nas/NamecheapNasContent";
import NanoNasContent from "./page/nas/NanoNasContent";
import PostgreSqlVmNasContent from "./page/nas/PostgreSqlVmNasContent";
import WebClientSpringContent from "./page/java-spring/WebClientSpringContent";
import OpenFeignSpringContent from "./page/java-spring/OpenFeignSpringContent";
import EurekaServerSpringContent from "./page/java-spring/EurekaServerSpringContent";
import CloudBalancerSpringContent from "./page/java-spring/CloudBalancerSpringContent";
import CloudApiGatewaySpringContent from "./page/java-spring/CloudApiGatewaySpringContent";
import Resilience4jSpringContent from "./page/java-spring/Resilience4jSpringContent";
import SleuthZipkinSpringContent from "./page/java-spring/SleuthZipkinSpringContent";
import ServerConfigSpringContent from "./page/java-spring/ServerConfigSpringContent";
import SpringSecurityKeyclaokJavaSpring from "./page/java-spring/SpringSecurityKeyclaokJavaSpring";
import KeycloakIntroJavaSpringContent from "./page/java-spring/KeycloakIntroJavaSpringContent";
import KeycloakIntro2JavaSpringContent from "./page/java-spring/KeycloakIntro2JavaSpringContent";
import KeycloakSpringContent from "./page/java-spring/KeycloakSpringContent";
import ConfigGitContent from "./page/git/ConfigGitContent";
import RevParseHeadGitContent from "./page/git/RevParseHeadGitContent";
import DockerElasticsearchContent from "./page/elasticsearch/DockerElasticsearchContent";
import NextjsAppContent from "./page/next/NextjsAppContent";
import UpdateNextReactContent from "./page/next/UpdateNextReactContent";
import RoutingNextContent from "./page/next/RoutingNextContent";
import DocumentNextContent from "./page/next/DocumentNextContent";
import AppNextContent from "./page/next/AppNextContent";
import GetStaticPropsReactContent from "./page/next/GetStaticPropsReactContent";
import GetStaticPathsReactContent from "./page/next/GetStaticPathsReactContent";
import HeadNextReactContent from "./page/next/HeadNextReactContent";
import EnvNextReactContent from "./page/next/EnvNextReactContent";
import EmailVerificationAuthLaravelContent from "./page/laravel/EmailVerificationAuthLaravelContent";
import MigrationLaravelContent from "./page/laravel/MigrationLaravelContent";
import GetServerSidePropsReactContent from "./page/next/GetServerSidePropsReactContent";
import NextConfigJsReactContent from "./page/next/NextConfigJsReactContent";
import LoginLaravelContent from "./page/laravel/LoginLaravelContent";
import LogoutLaravelContent from "./page/laravel/LogoutLaravelContent";
import ScopesLaravelContent from "./page/laravel/ScopesLaravelContent";
import ModelLaravelContent from "./page/laravel/ModelLaravelContent";
import ControllerLaravelContent from "./page/laravel/ControllerLaravelContent";
import SeederLaravelContent from "./page/laravel/SeederLaravelContent";
import ResourcesLaravelContent from "./page/laravel/ResourcesLaravelContent";
import EventsLaravelContent from "./page/laravel/EventsLaravelContent";
import CommandLaravelContent from "./page/laravel/CommandLaravelContent";
import TransactionsLaravelContent from "./page/laravel/TransactionsLaravelContent";
import IdeModelsLaravelContent from "./page/laravel/IdeModelsLaravelContent";
import ApiNextReactContent from "./page/next/ApiNextReactContent";
import Footer from "./common/Footer";
import PrivateRoutes from "./security/PrivateRoutes";

import JavaSpringSecurityRouters from "./page/java-spring-security/java-spring-security.router";
import MusicRouters from "./page/music/music.router";
import UtilRouters from "./page/util/util.router";
import TypeScriptRouters from "./page/typescript/typescript.router";
import VideografieRouters from "./page/videografie/videografie.router";
import AngularRouters from "./page/angular/angular.router";
import LiferayRouters from "./page/liferay/liferay.router";
import LinuxRouters from "./page/linux/linux.router";
import DockerRouters from "./page/docker/docker.router";
import ReactRouters from "./page/react/react.router";
import KubernetesRouters from "./page/kubernetes/kubernetes.router";
import SpringBootRouters from "./page/spring-boot/springboot.router";
import JHipsterRouters from "./page/jhipster/jhipster.router";
import JavascriptRouters from "./page/javascript/javascript.router";
import JavaRouters from "./page/java/java.router";
import BooksRouters from "./page/books/books.router";

import GoRouters from "./page/go/go.router";
import RustRouters from "./page/rust/rust.router";
import GrafanaRouters from "./page/grafana/grafana.router";
import CopiedDirectoryBranchGitContent from "./page/git/CopiedDirectoryBranchGitContent";
import GitLabCIContent from "./page/git/GitLabCIContent";

import ArduinoRouters from "./page/arduino/arduino.router"

import TTSRouters from "./page/text-to-speech/tts.router"
import LLMRouters from "./page/llm/llm.router"
import RpiRouters from "./page/raspberrypi/rpi.router"
import RobotsRouters from "./page/robots/robots.router"

function App() {
    return (

        <div className="App">

            <Helmet>
                <title>Dopamina</title>
            </Helmet>

            <Navbar/>

            <main role="main" className="container">


                {/* java-spring-security */}
                <JavaSpringSecurityRouters/>

                {/* music */}
                <MusicRouters/>

                {/* util */}
                <UtilRouters/>

                {/* typescript */}
                <TypeScriptRouters/>

                { /* videografie */}
                <VideografieRouters/>

                { /* angular */}
                <AngularRouters/>

                {/*liferay*/}
                <LiferayRouters/>

                {/* linux */}
                <LinuxRouters/>

                {/*docker*/}
                <DockerRouters/>

                {/*react*/}
                <ReactRouters/>

                {/*kubernetes*/}
                <KubernetesRouters/>

                {/*Spring Boot*/}
                <SpringBootRouters/>

                {/* JHipster*/}
                <JHipsterRouters/>

                {/* Javascript */}
                <JavascriptRouters/>

                {/* Java */}
                <JavaRouters/>

                {/*books*/}
                <BooksRouters/>

                <GoRouters/>
                <RustRouters/>
                <GrafanaRouters/>

                <ArduinoRouters/>

                <TTSRouters/>

                <LLMRouters/>

                <RpiRouters/>

                <RobotsRouters/>

                <Routes>

                    <Route path="/" element={<SiteHomeContent/>}/>
                    <Route path="/home" element={<SiteHomeContent/>}/>
                    <Route path="/access-denied" element={<AccessDeniedContent/>}/>
                    <Route path="/login" element={<LoginPage/>}/>

                    <Route path="/cv/index" element={<CvHomeContent/>} exact/>
                    <Route path="/cv/certificates" element={<CertificatesCVContent/>} exact/>

                    {/*java-cryptography*/}
                    <Route path="/java-cryptography/index" element={<JavaCryptographyHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/java-cryptography/ex-01" element={<JavaCryptographyEx01Content/>} exact/>
                        <Route path="/java-cryptography/ex-02" element={<JavaCryptographyEx02Content/>} exact/>
                        <Route path="/java-cryptography/ex-03" element={<JavaCryptographyEx03Content/>} exact/>
                    </Route>

                    {/*keycloak*/}
                    <Route path="/keycloak/index" element={<KeycloakHomeContent/>} exact/>
                    <Route path="/keycloak/install" element={<InstallKeycloakContent/>} exact/>
                    <Route path="/keycloak/create-user-admin" element={<CreateUserAdminKeycloakContent/>} exact/>
                    <Route path="/keycloak/create-realm" element={<CreateRealmKeycloakContent/>} exact/>
                    <Route path="/keycloak/create-user" element={<CreateUserKeycloakContent/>} exact/>
                    <Route path="/keycloak/create-client" element={<CreateClientKeycloakContent/>} exact/>
                    <Route path="/keycloak/create-client-roles" element={<CreateClientRolesKeycloakContent/>} exact/>
                    <Route path="/keycloak/assign-client-roles-to-user" element={<AssignClientRolesToUserKeycloakContent/>} exact/>
                    <Route path="/keycloak/create-realm-roles" element={<CreateRealmRolesKeycloakContent/>} exact/>
                    <Route path="/keycloak/assign-realm-roles-to-user" element={<AssignRealmRolesToUserKeycloakContent/>} exact/>

                    <Route element={<PrivateRoutes/>}>
                        <Route path="/keycloak/config-identity-provider" element={<ConfigIdentityProviderKeycloakContent/>} exact/>
                        <Route path="/keycloak/create-client-scopes" element={<CreateClientScopesKeycloakContent/>} exact/>
                        <Route path="/keycloak/assign-client-scopes-to-client" element={<AssignClientScopesToClientKeycloakContent/>} exact/>
                        <Route path="/keycloak/client-scopes-mappers" element={<ClientScopesMappersKeycloakContent/>} exact/>
                        <Route path="/keycloak/create-user-attributes" element={<CreateUserAttributesKeycloakContent/>} exact/>
                        <Route path="/keycloak/generate-token" element={<GenerateTokenKeycloakContent/>} exact/>
                    </Route>

                    {/*java-spring*/}
                    <Route path="/java-spring/index" element={<JavaSpringBootHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/java-spring/ioc" element={<IoCSpringContent/>} exact/>
                        <Route path="/java-spring/context" element={<SpringContextContent/>} exact/>
                        <Route path="/java-spring/wiring-beans" element={<WiringBeansContent/>} exact/>
                        <Route path="/java-spring/stereotypes" element={<StereotypesSpringContent/>} exact/>
                        <Route path="/java-spring/scopes" element={<ScopesSpringContent/>} exact/>
                        <Route path="/java-spring/aop" element={<AopSpringContent/>} exact/>
                        <Route path="/java-spring/spring-boot" element={<SpringBootContent/>} exact/>
                        <Route path="/java-spring/spring-mvc" element={<SpringMVCContent/>} exact/>
                        <Route path="/java-spring/web-scopes" element={<WebScopesSpringContent/>} exact/>
                        <Route path="/java-spring/conditionalonproperty-annotation" element={<ConditionalOnPropertyAnnotationContent/>} exact/>
                        <Route path="/java-spring/webclient" element={<WebClientSpringContent/>} exact/>
                        <Route path="/java-spring/open-feign" element={<OpenFeignSpringContent/>} exact/>
                        <Route path="/java-spring/eureka-server" element={<EurekaServerSpringContent/>} exact/>
                        <Route path="/java-spring/cloud-balancer" element={<CloudBalancerSpringContent/>} exact/>
                        <Route path="/java-spring/cloud-api-gateway" element={<CloudApiGatewaySpringContent/>} exact/>
                        <Route path="/java-spring/resilience4j" element={<Resilience4jSpringContent/>} exact/>
                        <Route path="/java-spring/sleuth-zipkin" element={<SleuthZipkinSpringContent/>} exact/>
                        <Route path="/java-spring/server-config" element={<ServerConfigSpringContent/>} exact/>
                        <Route path="/java-spring/keycloak-spring-security" element={<SpringSecurityKeyclaokJavaSpring/>} exact/>
                        <Route path="/java-spring/keycloak-intro-spring-security" element={<KeycloakIntroJavaSpringContent/>} exact/>
                        <Route path="/java-spring/keycloak-intro-2-spring-security" element={<KeycloakIntro2JavaSpringContent/>} exact/>
                        <Route path="/java-spring/keycloak-spring-boot" element={<KeycloakSpringContent/>} exact/>
                    </Route>



                    {/*git*/}
                    <Route path="/git/index" element={<GitHomeContent/>} exact/>
                    <Route path="/git/overview" element={<OverviewGitContent/>} exact/>
                    <Route path="/git/create-repository" element={<CreateRepositoryGitContent/>} exact/>
                    <Route path="/git/status" element={<StatusGitContent/>} exact/>
                    <Route path="/git/add" element={<AddGitContent/>} exact/>

                    <Route element={<PrivateRoutes/>}>
                        <Route path="/git/commit" element={<CommitGitContent/>} exact/>
                        <Route path="/git/diff" element={<DiffGitContent/>} exact/>
                        <Route path="/git/log" element={<LogGitContent/>} exact/>
                        <Route path="/git/show" element={<ShowGitContent/>} exact/>
                        <Route path="/git/ignore" element={<IgnoreGitContent/>} exact/>
                        <Route path="/git/remote-repository" element={<RemoteRepositoryGitContent/>} exact/>
                        <Route path="/git/branch" element={<BranchGitContent/>} exact/>
                        <Route path="/git/clone" element={<CloneGitContent/>} exact/>
                        <Route path="/git/config" element={<ConfigGitContent/>} exact/>
                        <Route path="/git/create-repository-existing-project" element={<InitFromExistingProjectGitContent/>} exact/>
                        <Route path="/git/local-repo-to-remote-repo" element={<LocalRepoToRemoteRepoContent/>} exact/>
                        <Route path="/git/multiple-remote-repository" element={<MultipleRemoteRepositoryGitContent/>} exact/>
                        <Route path="/git/rev-parse-head" element={<RevParseHeadGitContent/>} exact/>
                        <Route path="/git/copied-directory-branch" element={<CopiedDirectoryBranchGitContent/>} exact/>
                        <Route path="/gitlab/ci" element={<GitLabCIContent/>} exact/>
                    </Route>

                    {/*gradle*/}
                    <Route path="/gradle/index" element={<GradleHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/gradle/intro" element={<IntroGradleContent/>} exact/>
                        <Route path="/gradle/lifecycle" element={<LifecycleGradleContent/>} exact/>
                        <Route path="/gradle/properties-file" element={<PropertiesFileGradleContent/>} exact/>
                        <Route path="/gradle/projects" element={<ProjectsGradleContent/>} exact/>
                        <Route path="/gradle/tasks" element={<TasksGradleContent/>} exact/>
                        <Route path="/gradle/plugins" element={<PluginsGradleContent/>} exact/>
                        <Route path="/gradle/java-plugin" element={<JavaPluginGradleContent/>} exact/>
                        <Route path="/gradle/java-application-plugin" element={<JavaApplicationPluginGradleContent/>} exact/>
                        <Route path="/gradle/war-plugin" element={<WarPluginGradleContent/>} exact/>
                        <Route path="/gradle/custom-plugin" element={<CustomPluginGradleContent/>} exact/>
                        <Route path="/gradle/dependency-management" element={<DependencyManagementGradleContent/>} exact/>
                        <Route path="/gradle/wrapper" element={<WrapperGradleContent/>} exact/>
                        <Route path="/gradle/gradle-init" element={<GradleInitGradleContent/>} exact/>
                        <Route path="/gradle/daemon" element={<DaemonGradleContent/>} exact/>
                        <Route path="/gradle/groovy" element={<GroovyGradleContent/>} exact/>
                        <Route path="/gradle/project-object" element={<ProjectObjectGradleContent/>} exact/>
                        <Route path="/gradle/other-plugins" element={<OtherPluginGradleContent/>} exact/>
                        <Route path="/gradle/multi-projects" element={<MultiProjectsGradleContent/>} exact/>
                        <Route path="/gradle/jenkins" element={<JenkinsGradleContent/>} exact/>
                        <Route path="/gradle/spring-boot" element={<SpringBootGradleContent/>} exact/>
                    </Route>

                    {/*/python-ii*/}
                    <Route path="/python/index" element={<PythonHomeContent/>} exact/>
                    <Route path="/python/install" element={<InstallPythonContent/>} exact/>
                    <Route path="/python/overview" element={<OverviewPythonContent/>} exact/>
                    <Route path="/python/print-function" element={<PrintFunctionPythonContent/>} exact/>
                    <Route path="/python/literals" element={<LiteralsPythonContent/>} exact/>
                    <Route path="/python/comments" element={<CommentPythonContent/>} exact/>
                    <Route path="/python/operators" element={<OperatorsPythonContent/>} exact/>
                    <Route path="/python/variables" element={<VariablesPythonContent/>} exact/>
                    <Route path="/python/input-function" element={<InputFunctionPythonContent/>} exact/>
                    <Route path="/python/string" element={<StringPythonContent/>} exact/>
                    <Route path="/python/relational-and-logic-operators" element={<RelationalAndLogicOperatorsPythonContent/>} exact/>
                    <Route path="/python/if-statement" element={<IfPythonContent/>} exact/>
                    <Route path="/python/while-statement" element={<WhilePythonContent/>} exact/>
                    <Route path="/python/for-statement" element={<ForPythonContent/>} exact/>
                    <Route path="/python/bits-operators" element={<BitsOperatorsPythonContent/>} exact/>
                    <Route path="/python/lists" element={<ListPythonContent/>} exact/>
                    <Route path="/python/functions" element={<FunctionsPythonContent/>} exact/>

                    <Route element={<PrivateRoutes/>}>
                        <Route path="/python/tuples-and-dictionaries" element={<TupleAndDictionariesPythonContent/>} exact/>
                        <Route path="/python/exceptions" element={<ExceptionsPythonContent/>} exact/>
                        <Route path="/python/modules" element={<ModulesPythonContent/>} exact/>
                        <Route path="/python/packages" element={<PackagesPythonContent/>} exact/>
                        <Route path="/python/pip" element={<PipPythonContent/>} exact/>
                        <Route path="/python/string-ii" element={<StringIIPythonContent/>} exact/>
                        <Route path="/python/classes" element={<ClassesPythonContent/>} exact/>
                        <Route path="/python/inheritance" element={<InheritancePythonContent/>} exact/>
                        <Route path="/python/mro" element={<MroPythonContent/>} exact/>
                        <Route path="/python/exceptions-ii" element={<ExceptionsIIPythonContent/>} exact/>
                        <Route path="/python/generators" element={<GeneratorsPythonContent/>} exact/>
                        <Route path="/python/lambdas" element={<LambdasPythonContent/>} exact/>
                        <Route path="/python/closures" element={<ClosuresPythonContent/>} exact/>
                        <Route path="/python/file-streams" element={<FileStreamsPythonContent/>} exact/>
                        <Route path="/python/processing-files" element={<ProcessingFilesPythonContent/>} exact/>
                        <Route path="/python/os-module" element={<OsModulePythonContent/>} exact/>
                        <Route path="/python/datetime-module" element={<DatetimeModulePythonContent/>} exact/>
                        <Route path="/python/time-module" element={<TimeModulePythonContent/>} exact/>
                        <Route path="/python/calendar-module" element={<CalendarModulePythonContent/>} exact/>
                    </Route>

                    {/*/python-ii*/}
                    <Route path="/python-ii/index" element={<PythonIIHomeContent/>} exact/>
                    <Route path="/python-ii/oop-introduction" element={<OopIntroductionPythonContent/>} exact/>
                    <Route path="/python-ii/oop-variables" element={<OopVariablesPythonContent/>} exact/>
                    <Route path="/python-ii/oop-core-syntax" element={<OopCoreSyntaxPythonContent/>} exact/>
                    <Route path="/python-ii/oop-inheritance-polymorphism" element={<OopInheritancePolymorphismPythonContent/>} exact/>
                    <Route path="/python-ii/args" element={<ArgsPythonContent/>} exact/>
                    <Route path="/python-ii/decorators" element={<DecoratorsPythonContent/>} exact/>
                    <Route path="/python-ii/decorators-ii" element={<DecoratorsIIPythonContent/>} exact/>
                    <Route path="/python-ii/decorators-iii" element={<DecoratorsIIIPythonContent/>} exact/>
                    <Route path="/python-ii/static-class-method" element={<StaticClassMethodPythonContent/>} exact/>
                    <Route path="/python-ii/abstract-class" element={<AbstractClassPythonContent/>} exact/>
                    <Route path="/python-ii/oop-encapsulation" element={<OopEncapsulationPythonContent/>} exact/>
                    <Route path="/python-ii/exception-advanced" element={<ExceptionAdvancedPythonContent/>} exact/>
                    <Route path="/python-ii/object-persistence" element={<ObjectPersistencePythonContent/>} exact/>
                    <Route path="/python-ii/serialization" element={<SerializationPythonContent/>} exact/>
                    <Route path="/python-ii/with" element={<WithPythonContent/>} exact/>
                    <Route path="/python-ii/metaprogramming" element={<MetaprogrammingPythonContent/>} exact/>
                    <Route path="/python-ii/pep" element={<PepPythonContent/>} exact/>
                    <Route path="/python-ii/pep-8" element={<Pep8PythonContent/>} exact/>
                    <Route path="/python-ii/pep-257" element={<Pep257PythonContent/>} exact/>

                    {/*/python-gui*/}
                    <Route path="/python-gui/index" element={<PythonGUIHomeContent/>} exact/>
                    <Route path="/python-gui/intro" element={<IntroductionPythonGUIContent/>} exact/>
                    <Route path="/python-gui/tkinter-intro" element={<TkInterPythonGUIContent/>} exact/>

                    {/*design-patterns*/}
                    <Route path="/design-patterns/index" element={<DesignPatternsHomeContent/>} exact/>
                    <Route path="/design-patterns/simple-factory" element={<SimpleFactoryContent/>} exact/>

                    {/*elasticsearch*/}
                    <Route path="/elasticsearch/index" element={<ElasticsearchHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/elasticsearch/install" element={<InstallElasticsearchContent/>} exact/>
                        <Route path="/elasticsearch/intro" element={<IntroElasticsearchContent/>} exact/>
                        <Route path="/elasticsearch/connecting-cluster" element={<ConnectingClusterElasticsearchContent/>} exact/>
                        <Route path="/elasticsearch/mapping" element={<MappingElasticsearchContent/>} exact/>
                        <Route path="/elasticsearch/insert" element={<InsertElasticsearchContent/>} exact/>
                        <Route path="/elasticsearch/docker" element={<DockerElasticsearchContent/>} exact/>
                    </Route>

                    {/*compiler*/}
                    <Route path="/compiler/index" element={<CompilerHomeContent/>} exact/>
                    <Route path="/compiler/lexical-analysis" element={<LexicalAnalysisContent/>} exact/>
                    <Route path="/compiler/translators" element={<TranslatorsContent/>} exact/>

                    {/*next*/}
                    <Route path="/next/index" element={<NextHomeContent/>} exact/>
                    <Route path="/next/create-app" element={<NextjsAppContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/next/getserversideprops" element={<GetServerSidePropsReactContent/>} exact/>
                        <Route path="/next/nextconfigjs" element={<NextConfigJsReactContent/>} exact/>
                        <Route path="/next/getstaticprops" element={<GetStaticPropsReactContent/>} exact/>
                        <Route path="/next/getstaticpaths" element={<GetStaticPathsReactContent/>} exact/>
                        <Route path="/next/head" element={<HeadNextReactContent/>} exact/>
                        <Route path="/next/update" element={<UpdateNextReactContent/>} exact/>
                        <Route path="/next/routing" element={<RoutingNextContent/>} exact/>
                        <Route path="/next/document" element={<DocumentNextContent/>} exact/>
                        <Route path="/next/app" element={<AppNextContent/>} exact/>
                        <Route path="/next/env" element={<EnvNextReactContent/>} exact/>
                        <Route path="/next/api" element={<ApiNextReactContent/>} exact/>
                    </Route>

                    {/*laravel*/}
                    <Route path="/laravel/index" element={<LaravelHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/laravel/email-verification-auth" element={<EmailVerificationAuthLaravelContent/>} exact/>
                        <Route path="/laravel/migration" element={<MigrationLaravelContent/>} exact/>
                        <Route path="/laravel/login" element={<LoginLaravelContent/>} exact/>
                        <Route path="/laravel/logout" element={<LogoutLaravelContent/>} exact/>
                        <Route path="/laravel/scopes" element={<ScopesLaravelContent/>} exact/>
                        <Route path="/laravel/model" element={<ModelLaravelContent/>} exact/>
                        <Route path="/laravel/controller" element={<ControllerLaravelContent/>} exact/>
                        <Route path="/laravel/seeder" element={<SeederLaravelContent/>} exact/>
                        <Route path="/laravel/resources" element={<ResourcesLaravelContent/>} exact/>
                        <Route path="/laravel/events" element={<EventsLaravelContent/>} exact/>
                        <Route path="/laravel/command" element={<CommandLaravelContent/>} exact/>
                        <Route path="/laravel/transactions" element={<TransactionsLaravelContent/>} exact/>
                        <Route path="/laravel/ide-models" element={<IdeModelsLaravelContent/>} exact/>
                    </Route>

                    {/*nuxeo*/}
                    <Route path="/nuxeo/index" element={<NuxeoHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/nuxeo/install" element={<InstallNuxeoContent/>} exact/>
                        <Route path="/nuxeo/config" element={<ConfigNuxeoContent/>} exact/>
                        <Route path="/nuxeo/build-nuxeo-version" element={<BuildNuxeoVersionContent/>} exact/>
                        <Route path="/nuxeo/keycloak-integration" element={<KeycloakIntegrationNuxeoContent/>} exact/>
                        <Route path="/nuxeo/nxql" element={<NXQLNuxeoContent/>} exact/>
                        <Route path="/nuxeo/coresession" element={<CoreSessionNuxeoContent/>} exact/>
                        <Route path="/nuxeo/adobe-cc-connector" element={<AdobeCCConnectorNuxeoContent/>} exact/>
                        <Route path="/nuxeo/google-storage" element={<GoogleStorageNuxeoContent/>} exact/>
                        <Route path="/nuxeo/anexa-tips1" element={<Tips1NuxeoContent/>} exact/>
                        <Route path="/nuxeo/security" element={<SecurityNuxeoContent/>} exact/>
                        <Route path="/nuxeo/two-factor-authentication" element={<TwoFactorAuthenticationNuxeoContent/>} exact/>
                        <Route path="/nuxeo/nuxeo-cli" element={<NuxeoCLINuxeoContent/>} exact/>
                        <Route path="/nuxeo/service" element={<ServiceNuxeoContent/>} exact/>
                        <Route path="/nuxeo/unit-testing" element={<UnitTestingNuxeoContent/>} exact/>
                        <Route path="/nuxeo/documents" element={<DocumentsNuxeoContent/>} exact/>
                        <Route path="/nuxeo/service-extension-points" element={<ServiceExtensionPointsNuxeoContent/>} exact/>
                        <Route path="/nuxeo/directory-entries-to-custom-object" element={<DirectoryEntriesToCustomObjectNuxeoContent/>} exact/>
                    </Route>

                    {/* Tomcat */}
                    <Route path="/tomcat/index" element={<TomcatHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/tomcat/index" element={<TomcatHomeContent/>} exact/>
                        <Route path="/tomcat/intro" element={<IntroTomcatContent/>} exact/>
                        <Route path="/tomcat/ssl" element={<SSLTomcatContent/>} exact/>
                        <Route path="/tomcat/virtualhosts" element={<VirtualHostsTomcatContent/>} exact/>
                        <Route path="/tomcat/clustering" element={<ClusteringTomcatContent/>} exact/>
                        <Route path="/tomcat/loadbalancing-nginx" element={<LoadbancingNginxTomcatContent/>} exact/>
                        <Route path="/tomcat/loadbalancing-apache" element={<LoadbancingApacheTomcatContent/>} exact/>
                        <Route path="/tomcat/loadbalancing-session" element={<LoadbancingSessionTomcatContent/>} exact/>
                        <Route path="/tomcat/error-page" element={<ErrorPageTomcatContent/>} exact/>
                        <Route path="/tomcat/logs" element={<LogsTomcatContent/>} exact/>
                        <Route path="/tomcat/jndi" element={<JndiTomcatContent/>} exact/>
                        <Route path="/tomcat/valves" element={<ValvesTomcatContent/>} exact/>
                        <Route path="/tomcat/realms" element={<RealmsTomcatContent/>} exact/>
                        <Route path="/tomcat/optimization" element={<OptimizationTomcatContent/>} exact/>
                        <Route path="/tomcat/tips-tricks" element={<TipsTricksTomcatContent/>} exact/>
                    </Route>

                    {/*Maven*/}
                    <Route path="/maven/index" element={<MavenHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/maven/intro" element={<IntroMavenContent/>} exact/>
                        <Route path="/maven/inheritance" element={<InheritanceMavenContent/>} exact/>
                        <Route path="/maven/profiles" element={<ProfilesMavenContent/>} exact/>
                        <Route path="/maven/generate-projects" element={<GenerateProjectsContent/>} exact/>
                        <Route path="/maven/dependency-management" element={<DependencyManagementMavenContent/>} exact/>
                        <Route path="/maven/lifecycle-plugins" element={<LifecyclePluginsMavenContent/>} exact/>
                        <Route path="/maven/plugins" element={<PluginsMavenContent/>} exact/>
                        <Route path="/maven/custom-plugin" element={<CustomPluginMavenContent/>} exact/>
                        <Route path="/maven/build-archetype" element={<BuildArchetypeMavenContent/>} exact/>
                        <Route path="/maven/multi-module" element={<MultiModuleMavenContent/>} exact/>
                        <Route path="/maven/deploy-web-applications" element={<DeployWebApplicationsMavenContent/>} exact/>
                        <Route path="/maven/m2-eclipse-plugin" element={<M2EclipsePluginMavenContent/>} exact/>
                    </Route>

                    {/*Nas*/}
                    <Route path="/nas/index" element={<NasHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/nas/intro" element={<IntroNasContent/>} exact/>
                        <Route path="/nas/issues" element={<IssuesNasContent/>} exact/>
                        <Route path="/nas/ssh" element={<SSHNasContent/>} exact/>
                        <Route path="/nas/restore-vmm" element={<RestoreVMMNasContent/>} exact/>
                        <Route path="/nas/access-router" element={<AccessRouterNasContent/>} exact/>
                        <Route path="/nas/arp-protocol" element={<ArpProtocolNasContent/>} exact/>
                        <Route path="/nas/router" element={<RouterNasContent/>} exact/>
                        <Route path="/nas/ssh-vm" element={<SshVmNasContent/>} exact/>
                        <Route path="/nas/speed-test" element={<SpeedTestNasContent/>} exact/>
                        <Route path="/nas/links" element={<LinksNasContent/>} exact/>
                        <Route path="/nas/synology-mail-server" element={<SynologyMailServerNasContent/>} exact/>
                        <Route path="/nas/wan-lan-isolate" element={<WanLanIsolateNasContent/>} exact/>
                        <Route path="/nas/port-forwarding" element={<PortForwardNasContent/>} exact/>
                        <Route path="/nas/git-server" element={<GitServerNasContent/>} exact/>
                        <Route path="/nas/git-server-ssh-access" element={<GitServerSSHAccessNasContent/>} exact/>
                        <Route path="/nas/namecheap" element={<NamecheapNasContent/>} exact/>
                        <Route path="/nas/nano" element={<NanoNasContent/>} exact/>
                        <Route path="/nas/postgresql-vm" element={<PostgreSqlVmNasContent/>} exact/>
                    </Route>

                    {/*nginx*/}
                    <Route path="/nginx/index" element={<NginxHomeContent/>} exact/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/nginx/index" element={<NginxHomeContent/>} exact/>
                        <Route path="/nginx/install" element={<InstallNginxContent/>} exact/>
                        <Route path="/nginx/virtual-host" element={<VirtualHostNginxContent/>} exact/>
                        <Route path="/nginx/location-blocks" element={<LocationBlocksNginxContent/>} exact/>
                        <Route path="/nginx/variables" element={<VariablesNginxContent/>} exact/>
                        <Route path="/nginx/rewrites-redirects" element={<RewritesRedirectsNginxContent/>} exact/>
                        <Route path="/nginx/try-files-named-locations" element={<TryFilesNamedLocationsNginxContent/>} exact/>
                        <Route path="/nginx/logging" element={<LoggingNginxContent/>} exact/>
                        <Route path="/nginx/inheritince-directive-types" element={<InheritinceDirectiveTypesNginxContent/>} exact/>
                        <Route path="/nginx/php" element={<PhpNginxContent/>} exact/>
                        <Route path="/nginx/worker-processes" element={<WorkerProcessesNginxContent/>} exact/>
                        <Route path="/nginx/buffers-timeouts" element={<BuffersTimeoutsNginxContent/>} exact/>
                        <Route path="/nginx/adding-dynamic-modules" element={<AddingDynamicModulesNginxContent/>} exact/>
                        <Route path="/nginx/headers-expires" element={<HeadersExpiresNginxContent/>} exact/>
                        <Route path="/nginx/compressed-responses" element={<CompressedResponsesNginxContent/>} exact/>
                        <Route path="/nginx/fastcgi-cache" element={<FastCGICacheNginxContent/>} exact/>
                        <Route path="/nginx/http2" element={<Http2NginxContent/>} exact/>
                        <Route path="/nginx/server-push" element={<ServerPushNginxContent/>} exact/>
                        <Route path="/nginx/https" element={<HttpsNginxContent/>} exact/>
                        <Route path="/nginx/rate-limiting" element={<RateLimitingNginxContent/>} exact/>
                        <Route path="/nginx/basic-auth" element={<BasicAuthNginxContent/>} exact/>
                        <Route path="/nginx/hardening" element={<HardeningNginxContent/>} exact/>
                        <Route path="/nginx/ssl-certificates" element={<SSLCertificatesNginxContent/>} exact/>
                        <Route path="/nginx/reverse-proxy" element={<ReverseProxyNginxContent/>} exact/>
                        <Route path="/nginx/load-balancer" element={<LoadBalancerNginxContent/>} exact/>
                        <Route path="/nginx/links" element={<LinksNginxContent/>} exact/>
                    </Route>

                    <Route element={<PrivateRoutes/>}>
                        <Route path="/perceptron" element={<PerceptronContent/>} exact/>
                        <Route path="/handwritten" element={<HandwrittenContent/>} exact/>
                    </Route>

                    {/*IA*/}
                    <Route path="/ann/index" element={<AnnHomeContent/>} exact/>
                    <Route path="/ann" element={<AnnContent/>} exact/>
                    <Route path="/mcculloch-pitts" element={<McCullochPittsContent/>} exact/>
                    <Route path="/learning-rules" element={<LearningRuleContent/>} exact/>
                    <Route path="/perceptron-theory" element={<PerceptronTheoryContent/>} exact/>
                    <Route path="/adaline" element={<AdalineContent/>} exact/>
                    <Route path="/backpropagation" element={<BackpropagationContent/>} exact/>
                    <Route path="/art" element={<ArtContent/>} exact/>
                    <Route path="/art1" element={<Art1Content/>} exact/>
                    <Route path="/art1ex1" element={<Art1Ex1Content/>} exact/>
                    <Route path="/boltzmann" element={<BoltzmannContent/>} exact/>
                    <Route path="/hopfield" element={<HopfieldContent/>} exact/>
                    <Route path="/kohonen" element={<KohonenContent/>} exact/>
                    <Route path="/rbnn" element={<RadialBasisFunctionContent/>} exact/>
                    <Route path="/gans" element={<GansContent/>} exact/>
                    <Route path="/cnn" element={<CnnContent/>} exact/>
                    <Route path="/cnn-intro" element={<CnnIntroContent/>} exact/>
                    <Route path="/neocognitron" element={<NecognitronContent/>} exact/>
                    <Route path="/anexa1-probability" element={<Anexa1ProbabilityContent/>} exact/>
                    <Route path="/anexa2-markov" element={<Anexa2MarkovContent/>} exact/>
                    <Route path="/anexa3-monte-carlo" element={<Anexa3MonteCarloContent/>} exact/>
                    <Route path="/anexa4-genetic-algorithms" element={<Anexa4GenericAlgorithms/>} exact/>
                    <Route path="/anexa-gibbs-sampling" element={<AnexaGibbsSamplingContent/>} exact/>
                    <Route path="/anexa-softmax" element={<AnexaSoftmaxContent/>} exact/>
                    <Route path="/articol1" element={<Articol1Content/>} exact/>
                    <Route path="/hidden/probability" element={<ProbabilityHiddenContent/>} exact/>


                </Routes>

            </main>

            <Footer/>

        </div>
    );
}

export default App;