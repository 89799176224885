import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../gradle/IndexContent";

class JenkinsGradleContent extends BaseContentPage  {

    constructor(props) {
        super(props, "gradle-jenkins", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. Instalare si pornire</b>

                    <br/>
                    <br/>
                    <ul>
                        <li>se descarca de aici: <a href={"https://www.jenkins.io/"}>aici</a> </li>
                        <li>se instaleaza (pe windows fisierul de instalare se numeste: jenkins.msi; se poate instala: C:\tools\Jenkins\)</li>
                        <li>verificare instalare: http://localhost:8080</li>
                    </ul>

                    <hr/>
                    <b>2. Configurare</b>
                    <br/>
                    <br/>

                    Din <b>Dashboard {"->"} Manage Jenkins {"->"} Global Tool Configuration</b> : sectiunea Gradle.
                    <br/>
                    Se apasa pe butonul <b>Add Gradle</b>; se alege un nume (ex: GRADLE) si se seteaza GRADLE_HOME (ex: C:\tools\gradle-7.4)

                    <hr/>
                    <b>3. Creare job</b>
                    <br/>
                    <br/>
                    Din <b>Dashboard {"->"} New Item {"->"} se alege un nume (java-ibedaria); {"->"} se selecteaza <i>Freestyle project</i> {"->"} OK</b>
                    <br/>
                    Din nou ecran {"->"} <b>Tab Build{"->"} sectiunea Build {"->"} Invoke Gradle Script</b>

                    <div style={{padding:10}}>
                        <img  alt={""}  style={{width:350}} className={"rounded mx-auto d-block"}
                              src={process.env.PUBLIC_URL + '/img/gradle/gradle-1.png'}/>
                    </div>

                    Apoi se selecteaza <b>Invoke Gradle script</b> si:

                    <ul>
                        <li>la <b>Gradle version</b> se alege <b>GRADLE</b>.</li>
                        <li>la <b>Tasks</b> se scrie <b>clean build</b>.</li>
                    </ul>

                    <div style={{padding:10}}>
                        <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                              src={process.env.PUBLIC_URL + '/img/gradle/gradle-2.png'}/>
                    </div>

                    Apoi se apasa pe butonul <b>Advance</b> si in forma care apare se completeaza campul <b>Build File</b> (ex: D:\work-resources\gradle\proiect-java\app\build.gradle)

                    <hr/>
                    <b>4. Build</b>
                    <br/>
                    <br/>

                    Din <b>Dashboard {"->"} Build now</b>

                    <br/>
                    Se poate verifica se s-a intamplat in build apasand pe meniul contextul <b>Console Output</b>:
                    <div style={{padding:10}}>
                        <img  alt={""}  style={{width:550}} className={"rounded mx-auto d-block"}
                              src={process.env.PUBLIC_URL + '/img/gradle/gradle-3.png'}/>
                    </div>

                    Se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'Started by user admin\n' +
                        'Running as SYSTEM\n' +
                        'Building in workspace C:\\ProgramData\\Jenkins\\.jenkins\\workspace\\java-ibedaria\n' +
                        '[Gradle] - Launching build.\n' +
                        '[java-ibedaria] $ cmd.exe /C "C:\\tools\\gradle-7.4\\bin\\gradle.bat clean build -b D:\\work-resources\\gradle\\proiect-java\\app\\build.gradle && exit %%ERRORLEVEL%%"\n' +
                        'Starting a Gradle Daemon, 1 incompatible and 1 stopped Daemons could not be reused, use --status for details\n' +
                        'kj:a\n' +
                        'kj:init\n' +
                        'z\n' +
                        '\n' +
                        '> Configure project :app\n' +
                        'app\n' +
                        'app\n' +
                        'Proiect Java\n' +
                        '1.0\n' +
                        ':app\n' +
                        'D:\\work-resources\\gradle\\proiect-java\\app\n' +
                        'ABC\n' +
                        'ABC\n' +
                        '\n' +
                        '> Task :app:clean\n' +
                        '> Task :app:compileJava\n' +
                        '> Task :app:processResources NO-SOURCE\n' +
                        '> Task :app:classes\n' +
                        '> Task :app:jar\n' +
                        '> Task :app:startScripts\n' +
                        '> Task :app:distTar\n' +
                        '> Task :app:distZip\n' +
                        '> Task :app:assemble\n' +
                        '> Task :app:compileTestJava\n' +
                        '> Task :app:processTestResources NO-SOURCE\n' +
                        '> Task :app:testClasses\n' +
                        '> Task :app:test\n' +
                        '> Task :app:check\n' +
                        '> Task :app:build\n' +
                        '\n' +
                        'Deprecated Gradle features were used in this build, making it incompatible with Gradle 8.0.\n' +
                        '\n' +
                        'You can use \'--warning-mode all\' to show the individual deprecation warnings and determine if they come from your own scripts or plugins.\n' +
                        '\n' +
                        'See https://docs.gradle.org/7.4/userguide/command_line_interface.html#sec:command_line_warnings\n' +
                        '\n' +
                        'BUILD SUCCESSFUL in 15s\n' +
                        '8 actionable tasks: 8 executed\n' +
                        'Build step \'Invoke Gradle script\' changed build result to SUCCESS\n' +
                        'Finished: SUCCESS'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default JenkinsGradleContent;