import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../jhipster/IndexContent";

class InstallJHipsterContent extends BaseContentPage  {

    constructor(props) {
        super(props, "jhipster-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Instalare</b>
                    <br/>
                    <br/>

                    Prerechizite:
                    <ul>
                        <li>Java 11
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'java -version\n' +
                                    'java version "17.0.6" 2023-01-17 LTS\n' +
                                    'Java(TM) SE Runtime Environment (build 17.0.6+9-LTS-190)\n' +
                                    'Java HotSpot(TM) 64-Bit Server VM (build 17.0.6+9-LTS-190, mixed mode, sharing)'}
                            </SyntaxHighlighter>
                        </li>
                        <li>Git
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'git --version\n' +
                                    'git version 2.26.2.windows.1\n'}
                            </SyntaxHighlighter>
                        </li>
                        <li>Node.js:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'node -v\n' +
                                    'v18.15.0'}
                            </SyntaxHighlighter>

                            <b>npm</b> este instalat automat o data cu <b>Node.js</b>
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'npm -v\n' +
                                    '6.14.8'}
                            </SyntaxHighlighter>
                        </li>

                        <li>Docker (optional):
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'docker -v\n' +
                                    'Docker version 20.10.24, build 297e128'}
                            </SyntaxHighlighter>
                            Docker Compose:
                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'docker-compose -v\n' +
                                    'Docker Compose version v2.17.2'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    Instalarea JHipster CLI folosind NPM:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm install -g generator-jhipster'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'npm WARN deprecated querystring@0.2.0: The querystring API is considered Legacy. new code should use the URLSearchParams API instead.\n' +
                            'npm WARN deprecated request@2.88.2: request has been deprecated, see https://github.com/request/request/issues/3142\n' +
                            'npm WARN deprecated har-validator@5.1.5: this library is no longer supported\n' +
                            'npm WARN deprecated uuid@3.4.0: Please upgrade  to version 7 or higher.  Older versions may use Math.random() in certain circumstances, which is known to be problematic.  See https://v8.dev/blog/math-random for details.\n' +
                            'npm WARN deprecated @npmcli/move-file@1.1.2: This functionality has been moved to @npmcli/fs\n' +
                            'npm WARN deprecated readdir-scoped-modules@1.1.0: This functionality has been moved to @npmcli/fs\n' +
                            'npm WARN deprecated @npmcli/move-file@2.0.1: This functionality has been moved to @npmcli/fs\n' +
                            'c:\\Users\\buzdu\\.npm-packages\\jhipster -> c:\\Users\\buzdu\\.npm-packages\\node_modules\\generator-jhipster\\cli\\jhipster.js\n' +
                            '+ generator-jhipster@7.9.3\n' +
                            'added 645 packages from 436 contributors in 81.984s'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'jhipster --version'}
                    </SyntaxHighlighter>

                    daca afiseaza:
                    <SyntaxHighlighter>
                        {'INFO! Using bundled JHipster\n' +
                            'node:internal/modules/cjs/loader:571\n' +
                            '      throw e;\n' +
                            '      ^\n' +
                            '\n' +
                            'Error [ERR_PACKAGE_PATH_NOT_EXPORTED]: Package subpath \'./lib/util/namespace\' is not defined by "exports" in C:\\Users\\buzdu\\.npm-packages\\node_modules\\generator-jhipster\\node_modules\\yeoman-environment\\package.json\n' +
                            '    at new NodeError (node:internal/errors:399:5)\n' +
                            '    at exportsNotFound (node:internal/modules/esm/resolve:361:10)\n' +
                            '    at packageExportsResolve (node:internal/modules/esm/resolve:697:9)\n' +
                            '    at resolveExports (node:internal/modules/cjs/loader:565:36)\n' +
                            '    at Module._findPath (node:internal/modules/cjs/loader:634:31)\n' +
                            '    at Module._resolveFilename (node:internal/modules/cjs/loader:1061:27)\n' +
                            '    at Module._load (node:internal/modules/cjs/loader:920:27)\n' +
                            '    at Module.require (node:internal/modules/cjs/loader:1141:19)\n' +
                            '    at require (node:internal/modules/cjs/helpers:110:18)\n' +
                            '    at Object.<anonymous> (C:\\Users\\buzdu\\.npm-packages\\node_modules\\generator-jhipster\\utils\\blueprint.js:19:25) {\n' +
                            '  code: \'ERR_PACKAGE_PATH_NOT_EXPORTED\'\n' +
                            '}\n' +
                            '\n' +
                            'Node.js v18.15.0'}
                    </SyntaxHighlighter>

                    Pana se rezolva problema, ca workaround, se poate folosi <b>Node.js</b> 16.20:

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nvm install 16'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'nvm use 16.20.0\n' +
                            'Now using node v16.20.0 (64-bit)'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'jhipster --version'}
                    </SyntaxHighlighter>
                    poate afisa:
                    <SyntaxHighlighter>
                        {'INFO! Using bundled JHipster\n' +
                            '7.9.3'}
                    </SyntaxHighlighter>

                    apoi, se ruleaza comanda:
                    <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'jhipster'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'INFO! Using bundled JHipster\n' +
                            '(node:5536) [DEP0148] DeprecationWarning: Use of deprecated folder mapping "./lib/util/" in the "exports" field module resolution of the package at C:\\Users\\buzdu\\.npm-packages\\node_modules\\generator-jhipster\\node_modules\\yeoman-environment\\package.json.\n' +
                            'Update this package.json to use a subpath pattern like "./lib/util/*".\n' +
                            '(Use `node --trace-deprecation ...` to show where the warning was created)\n' +
                            '\n' +
                            '        ██╗ ██╗   ██╗ ████████╗ ███████╗   ██████╗ ████████╗ ████████╗ ███████╗\n' +
                            '        ██║ ██║   ██║ ╚══██╔══╝ ██╔═══██╗ ██╔════╝ ╚══██╔══╝ ██╔═════╝ ██╔═══██╗\n' +
                            '        ██║ ████████║    ██║    ███████╔╝ ╚█████╗     ██║    ██████╗   ███████╔╝\n' +
                            '  ██╗   ██║ ██╔═══██║    ██║    ██╔════╝   ╚═══██╗    ██║    ██╔═══╝   ██╔══██║\n' +
                            '  ╚██████╔╝ ██║   ██║ ████████╗ ██║       ██████╔╝    ██║    ████████╗ ██║  ╚██╗\n' +
                            '   ╚═════╝  ╚═╝   ╚═╝ ╚═══════╝ ╚═╝       ╚═════╝     ╚═╝    ╚═══════╝ ╚═╝   ╚═╝\n' +
                            '                            https://www.jhipster.tech\n' +
                            'Welcome to JHipster v7.9.3\n' +
                            '\n' +
                            'Application files will be generated in folder: D:\\work\\jhipster\\jhipster-app\n' +
                            ' _______________________________________________________________________________________________________________\n' +
                            '\n' +
                            '  Documentation for creating an application is at https://www.jhipster.tech/creating-an-app/\n' +
                            '  If you find JHipster useful, consider sponsoring the project at https://opencollective.com/generator-jhipster\n' +
                            ' _______________________________________________________________________________________________________________\n' +
                            '\n' +
                            '? May JHipster anonymously report usage statistics to improve the tool over time? (Y/n)'}
                    </SyntaxHighlighter>

                    Apoi se fac urmatoarele alegeri:
                    <SyntaxHighlighter>
                        {'? Which *type* of application would you like to create? Monolithic application (recommended for simple projects)\n' +
                            '? What is the base name of your application? lapretbun\n' +
                            '? Do you want to make it reactive with Spring WebFlux? No\n' +
                            '? What is your default Java package name? ro.ibrid.lapretbun\n' +
                            '? Which *type* of authentication would you like to use? JWT authentication (stateless, with a token)\n' +
                            '? Which *type* of database would you like to use? SQL (H2, PostgreSQL, MySQL, MariaDB, Oracle, MSSQL)\n' +
                            '? Which *production* database would you like to use? PostgreSQL\n' +
                            '? Which *development* database would you like to use? H2 with disk-based persistence\n' +
                            '? Which cache do you want to use? (Spring cache abstraction) Ehcache (local cache, for a single node)\n' +
                            '? Do you want to use Hibernate 2nd level cache? Yes\n' +
                            '? Would you like to use Maven or Gradle for building the backend? Maven\n' +
                            '? Do you want to use the JHipster Registry to configure, monitor and scale your application? No\n' +
                            '? Which other technologies would you like to use?\n' +
                            '? Which *Framework* would you like to use for the client? Angular\n' +
                            '? Do you want to generate the admin UI? Yes\n' +
                            '? Would you like to use a Bootswatch theme (https://bootswatch.com/)? Default JHipster\n' +
                            '? Would you like to enable internationalization support? Yes\n' +
                            '? Please choose the native language of the application English\n' +
                            '? Please choose additional languages to install Romanian\n' +
                            '? Besides JUnit and Jest, which testing frameworks would you like to use? Cypress\n' +
                            '? Would you like to install other generators from the JHipster Marketplace? No\n' +
                            '? Would you like to generate code coverage for Cypress tests? [Experimental] Yes\n' +
                            '? Would you like to audit Cypress tests? Yes\n' +
                            'Generating 2,048 bit RSA key pair and self-signed certificate (SHA256withRSA) with a validity of 99,999 days\n' +
                            '        for: CN=Java Hipster, OU=Development, O=ro.ibrid.lapretbun, L=, ST=, C='}
                    </SyntaxHighlighter>

                    Apoi, ca incepe generarea aplicatiei si la sfarsit va afisa:
                    <SyntaxHighlighter>
                        {'Server application generated successfully.\n' +
                            '\n' +
                            'Run your Spring Boot application:\n' +
                            './mvnw (mvnw if using Windows Command Prompt)\n' +
                            '\n' +
                            'Client application generated successfully.\n' +
                            '\n' +
                            'Start your Webpack development server with:\n' +
                            ' npm start\n' +
                            '\n' +
                            '\n' +
                            '> lapretbun@0.0.1-SNAPSHOT clean-www D:\\work\\jhipster\\jhipster-app\n' +
                            '> rimraf target/classes/static/app/{src,target/}\n' +
                            '\n' +
                            'Congratulations, JHipster execution is complete!\n' +
                            'Sponsored with ❤️  by @oktadev.'}
                    </SyntaxHighlighter>

                    Pentru a porni:
                    <ul>
                        <li>aplicatia String Boot:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'mvnw'}
                            </SyntaxHighlighter>

                            la sfarsit, se afiseaza URL prin care poate fi accesata aplicatia Spring Boot:
                            <SyntaxHighlighter>
                                {'----------------------------------------------------------\n' +
                                    '\tApplication \'lapretbun\' is running! Access URLs:\n' +
                                    '\tLocal: \t\thttp://localhost:8081/\n' +
                                    '\tExternal: \thttp://169.254.67.162:8081/\n' +
                                    '\tProfile(s): \t[dev, api-docs]\n' +
                                    '---------------------------------------------------------- '}
                            </SyntaxHighlighter>
                            si ne putem loga cu:
                            <ul>
                                <li>username: admin</li>
                                <li>password: admin</li>
                            </ul>
                        </li>

                        <li>aplicatia Angular:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'npm start'}
                            </SyntaxHighlighter>

                            la sfarsit, se afiseaza URL prin care se poate fi accesata aplicatia Angular:
                            <SyntaxHighlighter>
                                {'Build at: 2023-05-04T13:54:08.345Z - Hash: af3e80120cc5c918 - Time: 18289ms\n' +
                                    '\n' +
                                    '** Angular Live Development Server is listening on localhost:4200, open your browser on http://localhost:4200/ **\n' +
                                    '\n' +
                                    '\n' +
                                    '√ Compiled successfully.\n' +
                                    '[Browsersync] Proxying: http://localhost:4200\n' +
                                    '[Browsersync] Access URLs:\n' +
                                    ' ---------------------------------------\n' +
                                    '       Local: http://localhost:9000\n' +
                                    '    External: http://169.254.231.67:9000\n' +
                                    ' ---------------------------------------\n' +
                                    '          UI: http://localhost:3001\n' +
                                    ' UI External: http://localhost:3001\n' +
                                    ' ---------------------------------------\n'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                    {/*<hr/>*/}
                    {/*Schimbare baza de date din H2 -> PostgreSQL:*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*In <i>application-dev.yml</i>, putem schimba baza de date in PostgreSQL*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'  datasource:\n' +*/}
                    {/*        '    type: com.zaxxer.hikari.HikariDataSource\n' +*/}
                    {/*        '    url: jdbc:postgresql://localhost:5432/lapretbun\n' +*/}
                    {/*        '    username: lapretbun\n' +*/}
                    {/*        '    password: lapretbun\n' +*/}
                    {/*        '    hikari:\n' +*/}
                    {/*        '      poolName: Hikari\n' +*/}
                    {/*        '      auto-commit: false\n' +*/}
                    {/*        '  jpa:\n' +*/}
                    {/*        '    database-platform: tech.jhipster.domain.util.FixedPostgreSQL10Dialect'}*/}
                    {/*</SyntaxHighlighter>*/}
                    {/*si se sterge <i>,.h2.server.properties</i>:*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {' additional-exclude: static/**,.h2.server.properties'}*/}
                    {/*</SyntaxHighlighter>*/}

                    {/*In plus, in <b>pom.xml</b>, in profilul <i>dev</i> (se incluieste peste tot, si in sectiunea de liquidbase):*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'<!--                <dependency>-->\n' +*/}
                    {/*        '<!--                    <groupId>com.h2database</groupId>-->\n' +*/}
                    {/*        '<!--                    <artifactId>h2</artifactId>-->\n' +*/}
                    {/*        '<!--                </dependency>-->\n' +*/}
                    {/*        '                <dependency>\n' +*/}
                    {/*        '                    <groupId>org.postgresql</groupId>\n' +*/}
                    {/*        '                    <artifactId>postgresql</artifactId>\n' +*/}
                    {/*        '                </dependency>'}*/}
                    {/*</SyntaxHighlighter>*/}
                    {/*Apoi:*/}
                    {/*<SyntaxHighlighter>*/}
                    {/*    {'mvn clean install'}*/}
                    {/*</SyntaxHighlighter>*/}

                    {/*Apoi se creeaza un utilizator de baza de date cu toate drepturi:*/}
                    {/*<ul>*/}
                    {/*    <li>username: lapretbun</li>*/}
                    {/*    <li>password: lapretbun</li>*/}
                    {/*</ul>*/}
                    {/*Si o baza de date cu numele: password*/}

                    <hr/>
                    Se poate da:
                    <ul>
                        <li>
                            <i>mvn clean install</i> cu rulare teste:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                  {'mvn clean install'}
                             </SyntaxHighlighter>
                            la sfarsit poate afisa:
                            <SyntaxHighlighter>
                                {'[INFO] ------------------------------------------------------------------------\n' +
                                    '[INFO] BUILD SUCCESS\n' +
                                    '[INFO] ------------------------------------------------------------------------\n' +
                                    '[INFO] Total time:  04:26 min\n' +
                                    '[INFO] Finished at: 2023-05-04T15:05:10+03:00\n' +
                                    '[INFO] ------------------------------------------------------------------------'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <hr/>
                    Optional, daca vrem sa folosim ultima versiune de <b>generator-jhipster</b>, clonam cu <b>git</b> proiectul <b>generator-jhipster</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'git clone https://github.com/jhipster/generator-jhipster.git'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'Cloning into \'generator-jhipster\'...\n' +
                            'remote: Enumerating objects: 439795, done.\n' +
                            'remote: Counting objects: 100% (3401/3401), done.\n' +
                            'remote: Compressing objects: 100% (1529/1529), done.\n' +
                            'remote: Total 439795 (delta 2074), reused 2893 (delta 1722), pack-reused 436394Receiving objects: 100% (439795/439795), Receiving objects MiB/s\n' +
                            ': 100% (439795/439795), 137.86 MiB | 4.17 MiB/s, done.\n' +
                            'Resolving deltas: 100% (285774/285774), done.\n' +
                            'Updating files: 100% (3556/3556), done.'}
                    </SyntaxHighlighter>
                    apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'cd generator-jhipster'}
                    </SyntaxHighlighter>
                    apoi (pentru a crea o legătură simbolică din acest folder în aplicația instalată global în global node_modules):
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'npm link'}
                    </SyntaxHighlighter>
                    apoi:
                    <ul>
                        <li>
                            se ruleaza comenzile JHipster, folosind versiunea pe care ați clonat-o în loc de versiunea pe care ați instalat-o.
                        </li>
                    </ul>
                    apoi:
                    <ul>
                        <li>npm link generator-jhipster</li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallJHipsterContent;