import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ChangingLanguageTranslationsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-changing-language-translations", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Schimbarea traducerilor de limbă</b>
                    <br/>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_27.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://learn.liferay.com/w/dxp/system-administration/configuring-liferay/changing-language-translations"}>
                                    Schimbarea traducerilor de limbă
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChangingLanguageTranslationsLiferayContent;