import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../go/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ControlFlowGoContent extends BaseContentPage  {

    constructor(props) {
        super(props, "go-control-flow", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Instructiuni de control</b>

                    <hr/>
                    <b>1. if</b>
                    <br/>
                    <br/>

                    <hr/>
                    <b>2. for</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter>
                        {'	s := []string{"a", "b", "c"}\n	s = append(s, "d")\n\n	for index, item := range s {\n		fmt.Println(index, item)\n	}\n\n	fmt.Println(s) // va afisa [a b c d]'}
                    </SyntaxHighlighter>

                    <hr/>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ControlFlowGoContent;