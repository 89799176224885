import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class RestartPoliciesDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-restart-policies", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Politici de restart </b>
                    <br/>
                    <br/>

                    In mod implicit, containerele Docker nu se restarteaca cand se termina (exit) sau cand docker demon este restartat.
                    <br/>
                    Docker ofera polici de restart pentru a controla daca un container sa porteasca automat cand se termina / exit sau cand Docker se restarteaza (ex: <i>systemctrl restart docker</i>)
                    <br/>
                    Politici de restart:
                    <ul>
                        <li>
                            <b>no</b>: optiunea implicita, containerul nu se restarteaza daca acesta se opreste sau crapa (<i>no</i> se pune cu ghilimele in Docker Compose; pentru ca no simplu in YAML are semnificatia de false)
                        </li>
                        <li>
                            <b>always</b>: containerul incerca sa se restarteze, indiferent de motivul pentru care s-a oprit
                        </li>
                        <li>
                            <b>on-failuire</b>: containerul se restarteaza doar daca se opreste un cu cod de eroare (<i>error code</i>), adica exit code diferit de 0.
                        </li>
                        <li>
                            <b>unless-stopped</b>: containerul reporneste întotdeauna, dacă nu este oprit forțat (de un dezvoltator);
                            <br/>
                            daca Docker este restartat, se restarteaza in mod automat si containerul (util mai ales in mediul de productie)
                        </li>
                    </ul>

                    Exemplu de cod din <i>docker run</i> (se foloseste optiunea <b>--restart</b>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'docker container run -d --restart unless-stopped nginx'}
                    </SyntaxHighlighter>

                    Exemplu de cod din <i>docker-compose.yml</i>:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'restart:always'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RestartPoliciesDockerContent;