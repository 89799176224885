import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CopiedDirectoryBranchGitContent extends BaseContentPage {

    constructor(props) {
        super(props, "git-copied-directory-branch", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    <b>Copiat director dintr-un branch in alt branch</b>
                    <br/>
                    <br/>

                    # checkout pe branch-ul unde se vrea sa se copie (branch-ul destinatie)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout branch-destinatie'}
                    </SyntaxHighlighter>

                    # copieaza de branch-ul "master", folderul "modules/app-test" # checkout pe branch-ul unde se vrea sa se copie (branch-ul destinatie)
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git checkout master -- modules/app-test'}
                    </SyntaxHighlighter>

                    #verificare status
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'git status'}
                    </SyntaxHighlighter>

                    Apoi se face commit!

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}

                    {/*            Shaumik Daityari, Jump Start GIT, Sitepoint, 2020*/}

                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CopiedDirectoryBranchGitContent;