import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class Z6SettingsVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-z6-settings", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Histograma</b>
                    <br/>
                    <br/>

                    Pe Nikon Z6, pentru a afisa histograma:
                    <ul>
                        <li><b>pe vizor</b> (electronic viewfinder / EVF ):
                            <br/>
                            se apasa butonul <b>DISP</b> pana cand apare Historgrama in dreapta-jos (cu ochiul la vizor, in timp ce se priveste prin vizor)
                        </li>
                        <li><b>in Live View</b>:
                            <br/>
                            se apasa butonul <b>DISP</b> pana cand apare Historgrama in dreapta-jos (cu ochii pe LiveView, nu privind prin vizor)
                        </li>
                    </ul>

                    <hr/>

                    <b>2. Highlight display / Zebra</b>
                    <br/>
                    <br/>

                    Pe Nikon Z6, pentru a afisa modulul Zebra:
                    <ul>
                        <li><b>Custom Setting Menu {"->"} g Movie {"->"} g6 Highlight display</b>;
                            <br/>
                            daca nu se poate activa - <i>This options is not available at current settings or in the camera's current state.</i>,
                            atunci trebuie dezactivat:
                            <br/><i>Focus Peaking</i>, adica <b>Custom Setting Menu {"->"} d Shooting/display {"->"} d10 Peaking highlights</b></li>
                    </ul>
                    Optiunea <b>Highlight display</b> este utila in mod special pentru <i>videografie</i>.

                    Nikon Z6, nu foloseste IRE, ci nivelul <i>nivelul RGB</i>.

                    <br/>
                    Pentru a regla pragul de luminozitate (valorile IRE de mai jos presupun <b>N-Log</b>; in plus sunt estimari; pentru ca oficial Nikon a publicat doar pentru gri mediu (18%) la 35 IRE):
                    <ul>
                        <li>
                            <b>Custom Setting Menu {"->"} g Movie {"->"} g6 Highlight display {"->"} Highlight display threshold</b>
                            <ul>
                                <li>255 ~ 109 IRE (indica alb pur, imaginea este supraexpusa/arsa) </li>
                                <li>248 ~ 105 IRE <b>valoare implicita</b>, cand incep sa se piarda detaliile</li>
                                <li>235 ~ 100 IRE </li>
                                <li>225 ~ 95 IRE </li>
                                <li>213 ~ 90 IRE </li>
                                <li>202 ~ 85 IRE - evidențierea tonului pielii (N-Log)</li>
                                <li>191 ~ 80 IRE - indicator pentru piele expusa corect (N-Log)</li>
                                <li>180 ~ 75 IRE - maxim pentru toate tonurile de piele din toate profilurile camerei (N-Log)</li>
                            </ul>
                        </li>
                    </ul>

                    Daca nu se filmeaza N-Log, se recomanda setari intre 213 si 235

                    <hr/>

                    <b>Observatie</b>:
                    <br/>
                    Aparatul Nikon Z6 <b>nu permite, in mod film, să fie activate în același timp</b>:
                    <ul>
                        <li> <i>Modul Zebra</i> (<b>Custom Setting Menu {"->"} g Movie {"->"} g6 Highlight display</b>)</li>
                        <li> <i>Focus Peaking</i> (<b>Custom Setting Menu {"->"} d Shooting/display {"->"} d10 Peaking highlights</b>)</li>
                    </ul>

                    <hr/>
                    Setari film:
                    <ul>
                        <li>Movie Shoting Menu {"->"} <b>Frame size/frame rate: 1920x1080; 25p</b> </li>
                        <li>Movie Shoting Menu {"->"} ISO sensitivity settings {"->"}  <b>Auto ISO control (mode M): OFF</b></li>
                        <li>Movie Shoting Menu {"->"} ISO sensitivity settings {"->"}  <b>ISO sensitivity (mode M): 100</b> (de preferat sub 800)</li>
                        <li>Movie Shoting Menu {"->"} White balance:  K</li>
                        <li>-</li>
                        <li>Movie Shoting Menu {'->'} Choose image area: FX</li>
                        <li>Movie Shoting Menu {'->'} Movie quality: HIGH</li>
                        <li>Movie Shoting Menu {'->'} Movie file type: MOV</li>
                        <li>Movie Shoting Menu {'->'} Set Picture Color: Flat / Monochrome</li>
                        <li>Movie Shoting Menu {'->'} Metering: Matrix metering</li>
                        <li>Movie Shoting Menu {'->'} Focus Mode: AF-F Full-time AF</li>
                        <li>Movie Shoting Menu {'->'} AF-area mode: Auto-area AF</li>
                    </ul>

                    <hr/>
                    Pentru fotografii:
                    <ul>
                        <li>Playback Menu {'->'} Playblack options {'->'} Highlights</li>
                    </ul>

                    <hr/>
                    <b>3. Spatii de culoare</b>
                    <br/>
                    <br/>
                    Spatii de culoare:
                    <ul>
                        <li>Rec.709</li>
                        <li>sRGB (destul de similar cu Rec.709)</li>
                    </ul>

                    <hr/>
                    <b>4. Fișier de tip container digital</b>
                    <br/>
                    <br/>

                    Un <i>fișier de tip container digital</i> este un fisier care conține:
                    <ul>
                        <li>date video comprimate</li>
                        <li>metadate: alte date asociate necesare pentru redarea videoclipului (poate contine imagini, subtitrari)</li>
                    </ul>

                    MPG vs MOV:
                    <ul>
                        <li>MP4 este un <i>fișier de tip container digital</i> pentru videoclipuri și alte conținuturi multimedia, fiind un standard international.
                            <br/>
                            sunt de obicei mai comprimate și mai mici ca dimensiune
                        </li>
                        <li>
                            MOV este un <i>fișier de tip container digital</i> pentru videoclipuri și alte conținuturi multimedia, dezvoltat de Apple.
                            <br/>
                            sunt de obicei mai bune calitativ și mai mari ca dimensiune.
                        </li>
                        <li>Fisierele MP4 si fisierele MOV sunt doar un înveliș în jurul videoclipului, nu videoclipul în sine</li>
                        <li>Fișierele MP4 si fisierele MOV sunt codificate cu codecul MPEG-4</li>
                    </ul>

                    H264 vs ProRes:
                    <ul>
                        <li>
                            <b>H.264 / H264 </b> este un codec de achiziție (camera) și de distribuție (streaming web).
                            <ul>
                                <li>
                                    lansat in 2003
                                </li>
                                <li>este un codec pe 8 biți</li>
                                <li>
                                    este foarte comprimat, ceea ce înseamnă că fișierele sunt mici, necesită mai multă procesare, deci GPU este mai suprasolicitat
                                </li>
                                <li>
                                    comprimă un grup de imagini pentru a forma o arhitectură complexă care, deși reduce dimensiunea totală a fișierului, solicita mai mult GPU pentru a decomprima și procesa în timpul editării
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>ProRes</b> este un codec mai putin comprimat
                            <ul>
                                <li>dezvoltat de Apple si lansat in 2007</li>
                                <li>este un codec pe 10 biți, deci mai multe culori </li>
                                <li>fiind mai putin comprimat, GPU-ul este supraincarcat mai putin</li>
                                <li>fiecare cadru de filmare este comprimat individual (I-frame)</li>
                            </ul>
                        </li>
                    </ul>


                    <hr/>

                    <b>5. N-Log</b>
                    <br/>
                    <br/>

                    Pentru a putea inregistra N-Log, camera Nikon Z6 trebuie conectata la un recorder carea accepta videoclipuri pe 10 biti, cum ar fi Atomos Ninja V,
                    deoarece camera nu poate inregistra N-Log pe 10 biti direct pe cardul din camera.
                    <br/>
                    <br/>
                    Functia <b>View Assist</b> pe <b>ON</b> permite verificarea focalizarii si expunerii in timpul filmarii si vizualizarea imaginilor inregistrate pe LCD-ul camerei.
                    <br/>
                    <br/>
                    N-Log de fotografiere ISO nativ Z6 este de 800.

                    <hr/>

                    <b>HDMI: Atomos Ninja V HDMI și N-LOG</b>
                    <br/>

                    <ul>
                        <li>OUTPUT RESOLUTION = 2160p</li>
                        <li>EXTERNAL RECORDING CONTROL = ON</li>
                        <li>DATA DEPTH = 10 BIT</li>
                        <li>NLOG = ON  (nu se poate inregistra pe cadul intern)</li>
                        <li>VIEW ASSIST = ON</li>
                    </ul>

                    Alte detalii:
                    <ul>
                        <li><b>H.264 / MPEG-4 Part 10 sau Advanced Video Coding (AVC) </b> (mov sau mp4), 8 biti, 4:2:0</li>
                        <li><b>H.264 / MPEG-4 Part 10 sau Advanced Video Coding (AVC) </b> (mov sau mp4), 8 biti, 4:2:2 (extern)</li>
                        <li>10 biti, 4:2:2, doar cu n-log (extern)</li>
                        <li>maximum rate: 144mbs</li>
                    </ul>

                    <hr/>
                    Dezavantaje N-log:
                    <ul>
                        <li>pe Z6 se pierde 10% din imagine (factor de crop 1.1x) pentru: 3840 × 2160 30p, 3840 × 2160 25p, 3840 × 2160 24p</li>
                        <li>nu se poate filma la urmatoarele dimnsiuni de cadre si cadenta ratelor: 1920 × 1080 120p, 1920 × 1080 100p, 1920 × 1080 slow-mo</li>
                        <li>focusul se face mai greu, pentru ca imaginile nu mai sunt asa contrastante</li>
                    </ul>

                    <hr/>

                    Nikon are pentru profilul sau plat N-Log <b>3D Look Up Table (LUT) / N-Log 3D LUT</b>.
                    Acesta preia gama N-Log (functia de transfer) si gamut-ul si le converteste in spatiul de culoare Rec. 709.


                    <hr/>

                    <b>6. Viteza obturatorului</b>
                    <br/>
                    <br/>

                    Regula generală este că <i>vitezele obturatorului</i> trebuie să fie <b>dublu</b> față de <i>rata de cadre</i> (fps) selectată:
                    <ul>
                        <li>25fps {"=>"} 1/50</li>
                        <li>50fps {"=>"} 1/100</li>
                        <li>100fps {"=>"} 1/200</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.richardlackey.com/what-is-a-video-color-space/"}>What Is A Video Color Space?</a><br/>
                           </div>
                       </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default Z6SettingsVideografieContent;